import { useEffect, useState } from "react";

import { fetchWithAuthentication } from "src/components/services/image-service";

// Custom hook to fetch image as blob
export const useFetchImage = (url: string) => {
  const [imageSrc, setImageSrc] = useState<string>();
  const [imageBlob, setImageBlob] = useState<Blob>();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetchWithAuthentication(url);
        const blob = await response.blob();
        const localUrl = URL.createObjectURL(blob);
        setImageBlob(blob);
        setImageSrc(localUrl);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [url]);

  return { imageSrc, imageBlob };
};
