function hashCode(str: String) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: any) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
}

export function convert(str: String) {
  const hex = '#' + intToRGB(hashCode(str));
  return { light: `${hex}33`, dark: hex }; 
}
