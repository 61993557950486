import React from 'react';
import { ActivityIndicator } from 'react-native';
import { colors } from 'src/styles/theme/styles';

interface propType {
  size?: number | 'large' | 'small';
  color?: string;
}

export default function LoaderSpinner(props: propType) {
  const { size = 'large', color = 'primary' } = props;
  let colour = colors.primaryColor;
  switch(color) {
    case 'secondary':
      colour = colors.darkOrange;
      break;
    case 'tertiary':
      colour = colors.white;
      break;
  }
  return <ActivityIndicator
    size={size}
    color={colour}
  />
}
