import React, { Fragment, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useI18n } from 'src/i18n/hooks';
import { height, isIOS, isWeb } from 'src/constant/device';
import MobileHeader from 'src/components/hoc/MobileHeader';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { graphqlQuery } from 'src/graphql/util';
import { slotsWiseAttendance, slotsWiseUserAttendance } from 'src/graphql/attendance';
import { colors } from 'src/styles/theme/styles';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import ListNative from 'src/components/atoms/AttendanceList/index.native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import navigationStrings from 'src/navigation/navigationStrings';
import AttendanceNotConfigured from '../AttendanceNotConfigured';
import DateTimePicker from 'src/components/atoms/DateTime/DateTimePicker.native';
import { DT } from 'src/constant/dateTime';
import { endOfDay, endOfMonth, endOfWeek, format, parse, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { AttendanceData, AttendanceListData, SelectedDatesState } from 'src/components/molecules/Attendance/AttendanceTypes';
import { DurationTypeValue, getDurationLabel, getEndDateFunction, getStartDateFunction } from './helpers';

export default function StudentAndClassSlotsAttendanceComponent(props) {
  const { route } = props;
  const {
    batch,
    division,
    attendanceType,
    audienceType,
    selectedDurationType,
    selectedStudentInfo,
    slotInfo,
  } = route?.params;
  const { t } = useI18n();
  const timezone = DT.TIMEZONE;
  const currentDate: Date = new Date();

  const [totalPresents, setTotalPresents] = useState<number>(0);
  const [totalAttendance, setTotalAttendance] = useState<number>(0);
  const [currentSlotWiseAttendanceDataList, setcurrentSlotWiseAttendanceDataList] = useState<AttendanceData[] | null>(null);
  const [dates, setDates] = useState<SelectedDatesState | null>(null);
  const [dateString, setdateString] = useState<string>(format(currentDate, DT.MONTH_NAME_YEAR));

  function namingSlotsInfo() {
    const weeklyOrMonthly = t(getDurationLabel(selectedDurationType?.value));
    let slotsName = '';
    if (attendanceType.value === 0) {
      slotsName = t('fullDay.label');
    } else if (attendanceType.value === 1) {
      slotsName = slotInfo ? slotInfo.slotname : t('slots.label');
    } else {
      slotsName = slotInfo ? slotInfo.slotname : t('subjects.label');
    }
    return weeklyOrMonthly + ' - ' + slotsName;
  }

  const dateChangeHandler = (changedDate: string | Date) => {
    if (!selectedDurationType || selectedDurationType.value === 1) {
      // Case for Duration type === MONTHLY; type of param changedDate == js Date
      const newDate = format(changedDate, DT.DATE_FORMAT_SLASH, currentDate);
      const endDate = format(endOfMonth(changedDate), DT.DATE_FORMAT_SLASH);

      setDates((prev) => {
        if (prev) {
          if (prev.startDate !== newDate || prev.endDate !== endDate) {
            setcurrentSlotWiseAttendanceDataList(null);
          }
        }

        return {
          startDate: newDate,
          endDate: endDate,
          currentDate: newDate,
        };
      });

      const parsedNewDate = parse(newDate, DT.DATE_FORMAT_SLASH, currentDate);
      setdateString(format(parsedNewDate, DT.MONTH_NAME_YEAR));
    } else if (selectedDurationType?.value === 2) {
      const newDate = format(startOfDay(changedDate), DT.DATE_FORMAT_SLASH);
      const endDate = format(endOfDay(changedDate), DT.DATE_FORMAT_SLASH);

      setDates((prev) => {
        if (prev) {
          if (prev.startDate !== newDate || prev.endDate !== endDate) {
            setcurrentSlotWiseAttendanceDataList(null);
          }
        }

        return {
          startDate: newDate,
          endDate: endDate,
          currentDate: newDate,
        };
      });

      const parsedNewDate = parse(newDate, DT.DATE_FORMAT_SLASH, currentDate);
      setdateString(format(parsedNewDate, DT.DATE_ABBREVIATED_MONTH_YEAR));
    } else {
      // Case for Duration type === WEEKLY; type of param changedDate == string type with - 
      const splittedDate = changedDate.split(' - ');
      setDates((prev) => {
        if (prev) {
          if (prev.startDate !== splittedDate[0] || prev.endDate !== splittedDate[1]) {
            setcurrentSlotWiseAttendanceDataList(null);
          }
        }

        return {
          startDate: splittedDate[0],
          endDate: splittedDate[1],
          currentDate: splittedDate[0],
        };
      });
      const parsedStartDate = parse(splittedDate[0], DT.DATE_FORMAT_SLASH, currentDate);
      const parsedEndDate = parse(splittedDate[1], DT.DATE_FORMAT_SLASH, currentDate);
      setdateString(getFormattedDateString(parsedStartDate, parsedEndDate));
    }
  };

  function getFormattedDateString(startDate: Date, endDate: Date): string {
    return `${format(startDate, DT.DATE_ABBREVIATED_MONTH_YEAR)} - ${format(endDate, DT.DATE_ABBREVIATED_MONTH_YEAR)}`
  }

  function setSlotWiseData(list: AttendanceListData[]) {
    const arr: AttendanceData[] = [];
    let presentCount = 0;
    let totalAttendanceCount = 0;

    list.forEach((attendanceInfo: AttendanceListData) => {
      presentCount += attendanceInfo.present;
      totalAttendanceCount += attendanceInfo.total;
      arr.push({
        id: attendanceInfo.slot,
        heading: attendanceInfo.slotName,
        attend: attendanceInfo.present,
        total: attendanceInfo.total,
        percentage: ((attendanceInfo.present / attendanceInfo.total) * 100).toFixed(0),
      });
    });

    arr.sort((a, b) => (a.heading ?? "").localeCompare(b.heading ?? ""));

    setTotalAttendance(totalAttendanceCount);
    setTotalPresents(presentCount);
    setcurrentSlotWiseAttendanceDataList(arr);
  }

  async function getSlotsAttendanceData() {
    if (batch && division.length > 0 && dates) {
      const payload = {
        batch: batch?.value,
        class: batch?.class?.id,
        division: division,
        start: dates.startDate,
        end: dates.endDate,
        timezone,
      };
      setTotalAttendance(0);
      setTotalPresents(0);
      const { loading, data } = await graphqlQuery(
        selectedStudentInfo ? slotsWiseUserAttendance : slotsWiseAttendance,
        {
          payload: selectedStudentInfo ? { ...payload, user: selectedStudentInfo.value } : payload,
        },
        true,
      );
      if (
        !loading &&
        (selectedStudentInfo ? data?.slotWiseForUser[0]?.data?.length : data?.slotWise?.length)
      ) {
        setSlotWiseData(selectedStudentInfo ? data?.slotWiseForUser[0]?.data : data?.slotWise);
      } else {
        setcurrentSlotWiseAttendanceDataList([]);
      }
    }
  }

  useEffect(() => {
    const selectedDurationTypeValue = DurationTypeValue[selectedDurationType?.value];
    const startFunction = getStartDateFunction(selectedDurationTypeValue);
    const endFunction = getEndDateFunction(selectedDurationTypeValue);

    setDates(() => {
      const startdateAccordingToDurationType = format(startFunction(currentDate), DT.DATE_FORMAT_SLASH);
      const enddateAccordingToDurationType = format(endFunction(currentDate), DT.DATE_FORMAT_SLASH);
      const parsedStartDate = parse(startdateAccordingToDurationType, DT.DATE_FORMAT_SLASH, currentDate);
      const parsedEndDate = parse(enddateAccordingToDurationType, DT.DATE_FORMAT_SLASH, currentDate);
      const parsedCurrentDate = parse(startdateAccordingToDurationType, DT.DATE_FORMAT_SLASH, currentDate);
      setdateString(
        selectedDurationTypeValue === 'week'
          ? getFormattedDateString(parsedStartDate, parsedEndDate)
          : selectedDurationTypeValue === 'month'
            ? format(parsedCurrentDate, DT.MONTH_NAME_YEAR)
            : format(parsedStartDate, DT.DATE_ABBREVIATED_MONTH_YEAR)
      );
      return {
        startDate: startdateAccordingToDurationType,
        endDate: enddateAccordingToDurationType,
        currentDate: startdateAccordingToDurationType,
      };
    });
  }, [selectedDurationType]);

  useEffect(() => {
    getSlotsAttendanceData();
  }, [selectedDurationType, audienceType, selectedStudentInfo, dates]);
  return (
    <>
      <Fragment>
        <MobileHeader label={t('history.label')} backIcon={true} {...props} />
        <View style={styles.container}>
          {!dates ? (
            <View style={styles.spinnerView}>
              <LoaderSpinner />
            </View>
          ) : (
            <View>
              <View style={[styles.webWrapper]}>
                <View style={{ marginBottom: 16 }}>
                  <HeaderFive
                    lines={1}
                    value={
                      selectedStudentInfo
                        ? selectedStudentInfo.label
                        : batch?.label + ' - ' + batch?.class?.alias + ' - ' + division
                    }
                    color={colors.primaryText}
                    fontWeight={'400'}
                  />
                  {selectedStudentInfo !== null && (
                    <NormalTextSelect
                      lines={1}
                      value={batch?.label + ' - ' + batch?.class?.alias + ' - ' + division}
                      fontWeight={'600'}
                      color={colors.tertiaryText}
                    />
                  )}
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 24 }}>
                  <AnimatedCircularProgress
                    style={{ marginRight: 16 }}
                    size={80}
                    width={3}
                    fill={(totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) * 100}
                    tintColor="#2B78CA"
                    backgroundColor="transparent">
                    {fill => (
                      <View
                        style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <SmallTextAtom
                          value={t('present.label')}
                          color={colors.primary}
                          fontWeight={'600'}
                        />
                        <MediumTextSelect
                          value={`${(
                            (totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) *
                            100
                          ).toFixed(0)}%`}
                          color={colors.primary}
                          fontWeight={'600'}
                          lineHeight={2.4}
                        />
                      </View>
                    )}
                  </AnimatedCircularProgress>
                  <View
                    style={{
                      flexDirection: 'column',
                      flex: 1,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        paddingRight: 5,
                      }}>
                      <MediumTextSelect
                        lines={1}
                        value={namingSlotsInfo()}
                        color={colors.primaryText}
                        fontWeight={'600'}
                        lineHeight={2.4}
                      />
                    </View>
                    <View style={styles.calendarIcon}>
                      {/* selectedDurationType?.value === 0 ==> Week Picker will open Date picker and auto infer start and end dates */}
                      <DateTimePicker
                        dateTimePickerTitle={t('form.duration.label')}
                        dateTimeMode={
                          selectedDurationType?.value === 0 || selectedDurationType?.value === 2
                            ? 'date'
                            : 'monthyear'
                        }
                        standalonePicker={true}
                        disableFuture={true}
                        onDateTimeChangeCallback={(selectedDate: Date) => {
                          if (selectedDurationType?.value === 0) {
                            // Week Picker
                            const weekRange = `${format(
                              startOfWeek(selectedDate),
                              DT.DATE_FORMAT_SLASH,
                            )} - ${format(endOfWeek(selectedDate), DT.DATE_FORMAT_SLASH)}`;
                            dateChangeHandler(weekRange);
                          } else {
                            // month picker
                            dateChangeHandler(selectedDate);
                          }
                        }}
                      />
                      <NormalTextSelect
                        value={dateString}
                        fontWeight={'600'}
                        color={colors.tertiaryText}
                        style={[
                          styles.monthDisplayText,
                          {
                            marginTop:
                              selectedDurationType?.value === 0 || selectedDurationType?.value === 2
                                ? isIOS
                                  ? -2
                                  : 2
                                : isIOS
                                  ? 2
                                  : -26,
                          },
                        ]}
                      />
                    </View>
                  </View>
                </View>

                {!currentSlotWiseAttendanceDataList ? (
                  <View style={styles.spinnerView}>
                    <LoaderSpinner />
                  </View>
                ) : currentSlotWiseAttendanceDataList.length === 0 ? (
                  <AttendanceNotConfigured topPosition="20%" type={1} />
                ) : (
                  <ListNative
                    listData={currentSlotWiseAttendanceDataList}
                    navigation={props.navigation}
                    totalPresents={totalPresents}
                    totalAttendance={totalAttendance}
                    batch={batch}
                    division={division}
                    attendanceType={attendanceType}
                    audienceType={audienceType}
                    selectedDurationType={selectedDurationType}
                    selectedStudentInfo={selectedStudentInfo}
                    shouldNavigate={true}
                    routeName={navigationStrings.ATTENDANCE_HISTORY}
                    datesinfo={dates}
                    type={1}
                    listContainerHeightAndroid={selectedStudentInfo ? height - 285 : height - 265}
                    listContainerHeightIos={selectedStudentInfo ? height - 295 : height - 270}
                  />
                )}
              </View>
            </View>
          )}
        </View>
      </Fragment>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: '100%',
  },
  webWrapper: {
    margin: 24,
    height: isIOS ? height - 184 : height - 60,
  },
  spinnerView: {
    width: isWeb ? 450 : 'auto',
    height: isWeb ? 547 : height - 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  calendarIcon: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  monthDisplayText: {
    marginLeft: 8,
  },
});
