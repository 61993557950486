/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { colors, fonts } from 'src/styles/theme/styles';
import './employee.css';
import { useI18n } from 'src/i18n/hooks';
import { TouchableOpacity, View, Text } from 'react-native';
import { useThemeSystem } from 'src/contexts/theme-context';
import { height } from 'src/constant/device';
import { useHistory } from 'react-router-dom';
import { compareDesc } from "date-fns";

interface propType {
  data: any[];
  page: number;
  limit: number;
}
const show = true;
const trow = false;
const current = null;

export default function ConcessionsTable(props: propType) {
  const { data, page, limit } = props;
  let sortedData;
  if (data.length > 0) {
    sortedData = [...data];
    sortedData.sort(function (a: any, b: any) {
      return compareDesc(a.concessions[0].createdAt, b.concessions[0].createdAt);
    });
  } else {
    sortedData = data;
  }

  const history = useHistory();

  const columns = [
    { label: 'concessionsName.label' },
    { label: 'feesComposition.label' },
    { label: 'totalConcession.label' },
    // { label: 'actions.label', textAlign: 'right' },
  ];
  const { t } = useI18n();

  const { theme } = useThemeSystem();

  return (
    <div>
      <div id="app" style={{ border: '1px solid transparent' }}>
        <table className="tb" style={{ backgroundColor: theme?.table?.backgroundColor }}>
          <tr
            style={{
              backgroundColor: theme?.table?.header?.backgroundColor,
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}>
            {!!show && <th className="firstCol" />}

            {columns.map((e: any, idx) => (
              <th
                key={`emp-table-header-${idx}`}
                style={{ color: theme?.table?.header?.color, textAlign: e?.textAlign }}>
                {t(e.label).toUpperCase()}
              </th>
            ))}
          </tr>
          <tbody>
            {sortedData &&
              sortedData?.length > 0 &&
              sortedData?.map((consessionInfo: any, i: number) => (
                <React.Fragment key={consessionInfo.id}>
                  <tr
                    style={{
                      backgroundColor: trow && current === i ? '#EAF2FA' : 'transparent',
                      ...parentBorderRight(trow, current, i),
                      ...parentBorderLeft(trow, current, i),
                      ...commonBorder(trow, current, i),
                      width: '5%',
                    }}>
                    <td style={{ width: '2%' }} />

                    <td
                      onClick={() => {
                        history.push({
                          pathname: `/payments/concession_details/${consessionInfo.id}`,
                          state: {
                            page,
                            limit,
                          },
                        });
                      }}
                      style={{ color: colors.primaryColor, width: '32%', cursor: 'pointer' }}
                      className={'collapse'}>
                      {consessionInfo.concessions[0]?.name}
                    </td>
                    <td style={{ color: theme?.table?.color, width: '40%' }} className={'collapse'}>
                      {consessionInfo.concessions[0].feeBook?.compositionName}
                    </td>

                    <td
                      style={{
                        color: theme?.table?.color,
                        width: '24%',
                      }}
                      className={'collapse'}>
                      {consessionInfo?.concessions.reduce(
                        (acc: number, curr: any) => acc + curr.amount,
                        0,
                      )}
                    </td>
                    {/* <td style={{ textAlign: 'right' }} className={'collapse'}>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}>
                        <TouchableOpacity
                          onPress={() => {
                            //
                          }}
                          style={{
                            alignSelf: 'flex-end',
                          }}>
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            color={theme?.table?.editIconColor}
                            style={{
                              marginRight: 8,
                              cursor: 'pointer',
                            }}
                          />
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() => {
                            //
                          }}
                          style={{
                            alignSelf: 'flex-end',
                          }}>
                          <FontAwesomeIcon
                            icon={faPencil}
                            color={theme?.table?.editIconColor}
                            style={{
                              marginRight: 8,
                              cursor: 'pointer',
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                    </td> */}
                    <td style={{ width: '2%' }}></td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
        {sortedData && sortedData?.length === 0 && (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              height: height - 368,
              borderBottomWidth: 1,
              borderBottomColor: colors.borderGrey,
            }}>
            <Text style={{ fontFamily: fonts.regular }}>No records found</Text>
          </View>
        )}
      </div>
    </div>
  );
}

const parentBorderRight = (t, current, i) => {
  return {
    borderRight:
      t && current === i ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

const parentBorderLeft = (t, current, i) => {
  return {
    borderLeft:
      t && current === i ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

const commonBorder = (t, current, i) => {
  return {
    borderBottom:
      t && current === i
        ? `2px solid ${colors.tableDropdwonBorder}`
        : `1px solid ${colors.lighterBorder}`,
    borderTop:
      t && current === i
        ? `2px solid ${colors.tableDropdwonBorder}`
        : `1px solid ${colors.lighterBorder}`,
  };
};
