import React from 'react'
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import { Icon } from 'src/components/atoms/Icon/Icon';
import HeaderFour from 'src/components/atoms/Text/HeaderFour';
import { height } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { useHistory } from 'src/routes/routing';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components'

export default function LinkClosed() {
  const { t } = useI18n();
  const history = useHistory();
  return (
    <ContainerWrapper>
      <Icon name='link-closed' />
      <AdmissionNotFoundText>
        {t('admissionNotFound.text').toUpperCase()}
      </AdmissionNotFoundText>
      <HeaderFour value={t('admissionClosed.info.text')} color={colors.secondaryText} />
      <OutlineButton
        onPress={() => history.push('/dashboard')}
        label={t('goHome.text').toUpperCase()}
        style={{ height: 48, marginTop: 56 }}
      />
    </ContainerWrapper>
  )
}

const ContainerWrapper = styled.div`
  background-color: ${colors.white};
  height: ${height};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AdmissionNotFoundText = styled.text`
  margin-top: 55px;
  font-family: ${fonts.regular};
  font-size: 60px;
  font-weight: 600;
  line-height: 72px;
  color: ${colors.primaryText};
`;