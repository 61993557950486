/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { faFileArchive, faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faCaretUp, faDownload, faUserLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import SmallButton from 'src/components/atoms/Button/SmallButton.web';
import { colors } from 'src/styles/theme/styles';
import './institute.css';
import { useI18n } from 'src/i18n/hooks';
import { TouchableOpacity, View } from 'react-native';
import { useThemeSystem } from 'src/contexts/theme-context';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { Tooltip } from '@mui/material';
import { downloadFileRestAPI } from 'src/utils/utility';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { SUCCESS } from 'src/constant';

export default function InstituteTable(props) {
  const {
    data,
    childName = 'group',
    handleApprove,
    handleEdit,
    handleModule,
    handleDelete,
    handleAdd,
    handleChildEdit,
    handleChildDelete,
    handleModuleEdit,
  } = props;
  const columns = [
    { label: 'groupInstituteName.label' },
    { label: 'instituteType.label' },
    { label: 'description.label' },
    { label: 'edbaDomain.label' },
  ];
  const [trow, setT] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const { t } = useI18n();

  const show = data?.find((o) => o?.group?.length >= 0);
  const { theme } = useThemeSystem();
  let { setAlertDetails } = useAlertSystem();

  function handleDownloadReport(id: string, downloadUrl: string, fileName: string) {
    if (id) {
      downloadFileRestAPI(downloadUrl, fileName);
      setAlertDetails({ message: t("file-download-start.text"), level: SUCCESS });
    }
  }

  const tdStyle = {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 17,
    paddingBottom: 18,
  };

  const actionStyle = {
    paddingTop: 15,
    paddingBottom: 16,
  };

  return (
    <div>
      <div id="app" style={{ border: '1px solid transparent' }}>
        <table className="tb" style={{ backgroundColor: theme?.table?.backgroundColor }}>
          <tr
            style={{
              backgroundColor: theme?.table?.header?.backgroundColor,
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}>
            {!!show && <th className="firstCol" />}
            {columns.map((e) => (
              <th style={{ color: theme?.table?.header?.color }} key={e.label}>
                {t(e.label)}
              </th>
            ))}
            <th style={{ textAlign: 'right', color: theme?.table?.header?.color }}>ACTIONS</th>
          </tr>
          <tbody>
            {data &&
              data?.length > 0 &&
              data?.map((d: any, i: number) => (
                <React.Fragment key={`${d?.type}-${i}`}>
                  <tr
                    style={{
                      backgroundColor: trow && current === i ? '#EAF2FA' : 'transparent',
                      ...parentBorderRight(trow, current, i),
                      ...parentBorderLeft(trow, current, i),
                      ...commonBorder(trow, current, i),
                    }}>
                    {!!show && d?.type === 'GROUP' ? (
                      <td style={tdStyle}>
                        {trow && current === i ? (
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            onClick={() => {
                              setT(false);
                              setCurrent(null);
                            }}
                            color={colors.secondaryText}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            onClick={() => {
                              setT(true);
                              setCurrent(i);
                            }}
                            color={colors.secondaryText}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </td>
                    ) : (
                      <td style={tdStyle} />
                    )}

                    <td style={{ ...tdStyle, width: '28%' }}>
                      <NormaltextAtom value={d.name} color={theme?.table?.color} />
                    </td>
                    <td style={{ ...tdStyle, width: '28%' }}>
                      <NormaltextAtom value={d.type} color={theme?.table?.color} />
                    </td>
                    <td style={{ ...tdStyle, width: '28%' }}>
                      <NormaltextAtom value={d.description} color={theme?.table?.color} />
                    </td>
                    <td style={{ ...tdStyle, width: '28%' }}>
                      <NormaltextAtom value={d.domain} color={theme?.table?.color} />
                    </td>
                    {d?.status === 'APPROVED' ? (
                      <td style={{ textAlign: 'right', paddingTop: 20, paddingBottom: 19 }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}>
                          <Tooltip title="Download Users Report">
                            <TouchableOpacity
                              onPress={() => handleDownloadReport(d?.id, `students/export/${d?.id}`, 'institute.zip')}
                              style={{
                                alignSelf: 'flex-end',
                              }}>
                              <FontAwesomeIcon
                                icon={faFileArchive}
                                color={theme?.table?.editIconColor}
                                style={{
                                  marginRight: d?.type === 'GROUP' ? 13 : 8,
                                  cursor: 'pointer',
                                }}
                              />
                            </TouchableOpacity>
                          </Tooltip>
                          <Tooltip title="Download Usage Report">
                            <TouchableOpacity
                              onPress={() => handleDownloadReport(d?.id, `report/usage/${d?.id}`, 'usage_report.xlsx')}
                              style={{
                                alignSelf: 'flex-end',
                              }}>
                              <FontAwesomeIcon
                                icon={faFileArchive}
                                color={theme?.table?.editIconColor}
                                style={{
                                  marginRight: d?.type === 'GROUP' ? 13 : 8,
                                  cursor: 'pointer',
                                }}
                              />
                            </TouchableOpacity>
                          </Tooltip>
                          <Tooltip title="Institute Modules">
                            <TouchableOpacity
                              onPress={() => handleModule(d)}
                              style={{
                                alignSelf: 'flex-end',
                              }}>
                              <FontAwesomeIcon
                                icon={faUserLock}
                                color={theme?.table?.editIconColor}
                                style={{
                                  marginRight: d?.type === 'GROUP' ? 13 : 8,
                                  cursor: 'pointer',
                                }}
                              />
                            </TouchableOpacity>
                          </Tooltip>
                          <Tooltip title="Edit Institute">
                            <TouchableOpacity
                              onPress={() => handleEdit(d)}
                              style={{
                                alignSelf: 'flex-end',
                              }}>
                              <FontAwesomeIcon
                                icon={faPencil}
                                color={theme?.table?.editIconColor}
                                style={{
                                  marginRight: d?.type === 'GROUP' ? 13 : 8,
                                  cursor: 'pointer',
                                }}
                              />
                            </TouchableOpacity>
                          </Tooltip>
                          {d?.type !== 'GROUP' && (
                            <Tooltip title="Delete Institute">
                              <TouchableOpacity onPress={() => handleDelete(d)}>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  color={theme?.table?.deleteIconColor}
                                  style={{ cursor: 'pointer' }}
                                />
                              </TouchableOpacity>
                            </Tooltip>
                          )}
                        </View>
                      </td>
                    ) : (
                      <td className="approve" style={actionStyle}>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end',
                          }}>
                          <SmallButton
                            label="Approve"
                            onPress={() => handleApprove(d)}
                            lineHeight={16}
                          />
                        </div>
                      </td>
                    )}
                  </tr>

                  {trow && current === i && d?.[childName] && d?.[childName]?.length !== 0 && (
                    <>
                      {d?.[childName]?.map((nestd, inner) => (
                        <tr
                          style={{
                            ...childBorderLeft(trow, current, i),
                            ...childBorderRight(trow, current, i),
                            color: theme?.table?.color,
                          }}>
                          <td
                            style={{
                              ...parentBorderBottom(trow, current, i, inner, data),
                              color: theme?.table?.color,
                            }}
                          />
                          <td
                            style={{
                              ...parentBorderBottom(trow, current, i, inner, data),
                              color: theme?.table?.color,
                            }}>
                            {nestd.name}
                          </td>
                          <td
                            style={{
                              ...parentBorderBottom(trow, current, i, inner, data),
                              color: theme?.table?.color,
                            }}>
                            {nestd.type}
                          </td>
                          <td
                            style={{
                              ...parentBorderBottom(trow, current, i, inner, data),
                              color: theme?.table?.color,
                            }}>
                            {nestd.description}
                          </td>
                          <td
                            style={{
                              ...parentBorderBottom(trow, current, i, inner, data),
                              color: theme?.table?.color,
                            }}>
                            {nestd.domain}
                          </td>
                          <td
                            style={{
                              textAlign: 'right',
                              ...parentBorderBottom(trow, current, i, inner, data),
                            }}>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                              }}>
                              <Tooltip title="Download Users Report">
                                <TouchableOpacity onPress={() => handleDownloadReport(nestd?.id, `students/export/${nestd?.id}`, 'institute.zip')}>
                                  <FontAwesomeIcon
                                    icon={faFileArchive}
                                    color={theme?.table?.editIconColor}
                                    style={{ marginRight: 8, cursor: 'pointer' }}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                              <Tooltip title="Download Usage Report">
                                <TouchableOpacity onPress={() => handleDownloadReport(nestd?.id, `report/usage/${nestd?.id}`, 'usage_report.xlsx')}>
                                  <FontAwesomeIcon
                                    icon={faFileArchive}
                                    color={theme?.table?.editIconColor}
                                    style={{ marginRight: 8, cursor: 'pointer' }}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                              <Tooltip title="Modules">
                                <TouchableOpacity onPress={() => handleModuleEdit(nestd, d)}>
                                  <FontAwesomeIcon
                                    icon={faUserLock}
                                    color={theme?.table?.editIconColor}
                                    style={{ marginRight: 8, cursor: 'pointer' }}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                              <Tooltip title="Edit Institute">
                                <TouchableOpacity onPress={() => handleChildEdit(nestd, d)}>
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    color={theme?.table?.editIconColor}
                                    style={{ marginRight: 8, cursor: 'pointer' }}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                              <Tooltip title="Delete Institute">
                                <TouchableOpacity onPress={() => handleChildDelete(nestd, d)}>
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    color={theme?.table?.deleteIconColor}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            </View>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                  {trow && current === i && d?.[childName] && d?.[childName]?.length === 0 && (
                    <tr
                      style={{
                        ...childBorderBottom(trow, current, i),
                        ...childBorderLeft(trow, current, i),
                        ...childBorderRight(trow, current, i),
                      }}>
                      <td />
                      <td
                        style={{
                          ...childBorderBottom(trow, current, i),
                        }}
                      />
                      <td
                        style={{
                          ...childBorderBottom(trow, current, i),
                        }}
                      />
                      <div
                        style={{
                          padding: '12px 8px',
                          display: 'flex',
                          flex: 1,
                          justifyContent: 'center',
                        }}>
                        <SecondaryBtn
                          label={t('addInstitute.text')}
                          style={{ height: 32 }}
                          onPress={() => handleAdd(d)}
                        />
                      </div>
                      <td
                        style={{
                          ...childBorderBottom(trow, current, i),
                        }}
                      />
                      <td
                        style={{
                          ...childBorderBottom(trow, current, i),
                        }}
                      />
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const childBorderBottom = (trow, current, i) => {
  return {
    borderBottom: trow ? `1px solid ${colors.tableDropdwonBorder}` : '',
  };
};

const childBorderRight = (trow, current, i) => {
  return {
    borderRight: trow ? `1px solid ${colors.tableDropdwonBorder}` : '1px solid transparent',
  };
};

const childBorderLeft = (t, current, i) => {
  return {
    borderLeft: t ? `1px solid ${colors.tableDropdwonBorder}` : '1px solid transparent',
  };
};

const parentBorderBottom = (t, current, i, inner, data) => {
  return {
    borderBottom:
      t && current === i && inner === data[i]?.group?.length - 1
        ? `1px solid ${colors.tableDropdwonBorder}`
        : `1px solid ${colors.lighterBorder}`,
  };
};

const parentBorderRight = (t, current, i) => {
  return {
    borderRight:
      t && current === i ? `1px solid ${colors.tableDropdwonBorder}` : '1px solid transparent',
  };
};

const parentBorderLeft = (t, current, i) => {
  return {
    borderLeft:
      t && current === i ? `1px solid ${colors.tableDropdwonBorder}` : '1px solid transparent',
  };
};

const commonBorder = (t, current, i) => {
  if (t && current === i) {
    return {
      borderTop: `1px solid ${colors.tableDropdwonBorder}`,
      borderBottom: `1px solid ${colors.tableDropdwonBorder}`,
    };
  } else {
    return {
      borderBottom: `1px solid ${colors.lighterBorder}`,
      borderTop: `1px solid ${colors.lighterBorder}`,
    };
  }
};
