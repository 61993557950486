import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import React from 'react';
import { TableCell } from '@mui/material';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components';

interface GradeCellProps {
  width: number;
  value: string;
}
interface RemarksCellProps {
  value: string;
}

export function HeaderCell(props: GradeCellProps) {
  const { width, value } = props;
  return (
    <TopLeftCell width={width} align={'center'}>
      <TableColHead value={value} />
    </TopLeftCell>
  );
}

export function DescriptionCell(props: GradeCellProps) {
  const { width, value } = props;
  return (
    <TopLeftCell width={width} align={'center'}>
      <NormaltextAtom value={value || "N/A"} fontSize={1.5} />
    </TopLeftCell>
  );
}
export function TotalMarksCell(props: GradeCellProps) {
  const { width, value } = props;
  return (
    <TotalMarksTopLeftCell width={width} align={'left'}>
      <NormalTextSelect value={value} fontWeight={'600'} />
    </TotalMarksTopLeftCell>
  );
}

export function RemarksHeaderCell(props: RemarksCellProps) {
  const { value } = props;
  return (
    <TopLeftCell align={'center'}>
      <TableColHead value={value} />
    </TopLeftCell>
  );
}
export function RemarksDescriptionCell(props: RemarksCellProps) {
  const { value } = props;
  return (
    <TopLeftCell align={'center'}>
      <NormaltextAtom value={value || "N/A"} fontSize={1.5} />
    </TopLeftCell>
  );
}

const TopLeftCell = styled(TableCell)`
  border-width: 1;
  border-color: ${colors.deviderGrey};
  border-style: solid;
  border-bottom-style: 'none';
  border-right-style: 'none';
  padding-top: 9;
  padding-bottom: 9;
  height: 36;
`;

const TotalMarksTopLeftCell = styled(TableCell)`
  border-width: 1;
  border-color: ${colors.deviderGrey};
  border-style: solid;
  border-bottom-style: none;
  border-right-style: none;
  padding-top: 7.75;
  padding-bottom: 7.75;
  height: 36;
`;