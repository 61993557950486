import { colors, fontWeight } from "src/styles/theme/styles";

import { DT } from "src/constant/dateTime";
import DownloadFile from "src/components/atoms/DownloadFile/index.web";
import { FileCategory } from "src/constant";
import HeaderFour from "src/components/atoms/Text/HeaderFour";
import HeaderSix from "src/components/atoms/Text/HeaderSix";
import HyperLinkText from "src/components/atoms/Text/HyperLinkText";
import ImageGrid from "../ImageGrid/ImageGrid.web";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import React from "react";
import SmallTextAtom from "src/components/atoms/Text/SmallTextAtom";
import { eventModalHelpers } from "./helpers/EventModalHelpers";
import { getAnnouncementsQuery } from "src/graphql/communication/news-notices";
import { splitFileTypes } from "src/utils/fileUtils";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";
import { useParams } from "src/routes/routing.web";
import { useQuery } from "@apollo/client";

type Props = { eventId?: string };

export default function EventModalContent(props: Props) {
  const { t } = useI18n();
  const params: Record<string, string> = useParams();
  const eventId = props?.eventId ?? params?.eventId;

  const eventFilter = {
    type: { eq: FileCategory.Event },
    id: { eq: eventId },
  };

  const { loading, data } = useQuery(getAnnouncementsQuery, {
    variables: { filters: eventFilter, timezone: DT.TIMEZONE },
    fetchPolicy: "network-only",
  });

  const eventInformation = data?.getAnnouncements?.data[0] ?? {};

  const { mdate, mstartTime, mendTime } = eventInformation;
  const formattedEventDT = eventInformation
    ? getFormattedDate(mdate, mstartTime, mendTime)
    : "";

  const { images, attachments } = splitFileTypes(eventInformation?.attachments);

  return (
    <>
      {!loading ? (
        <Container>
          <HeaderWrapper>
            <HeaderFour
              value={eventInformation?.title ?? ""}
              color={colors.secondaryText}
              fontWeight={fontWeight[400]}
              lines={4}
            />
            <SmallTextAtom
              value={formattedEventDT}
              color={colors.tertiaryText}
              fontWeight={fontWeight[400]}
            />
          </HeaderWrapper>

          {!!eventInformation?.body && (
            <HyperLinkText text={eventInformation?.body ?? ""} />
          )}

          {Array.isArray(images) && images.length > 0 && (
            <ImageGrid data={images} />
          )}

          {Array.isArray(attachments) && attachments.length > 0 && (
            <AttachmentsWrapper>
              <HeaderSix
                value={t("attachments.label")}
                fontWeight={fontWeight[400]}
              />
              <AttachmentFiles>
                {attachments.map((fileData, index) => (
                  <DownloadFile
                    fileData={fileData as any}
                    key={`eventAttachment${index}`}
                    width={480}
                  />
                ))}
              </AttachmentFiles>
            </AttachmentsWrapper>
          )}
        </Container>
      ) : (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      )}
    </>
  );
}

const { getFormattedDate } = eventModalHelpers;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const AttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
`;

const AttachmentFiles = styled.div``;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
