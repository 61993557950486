import {
  BasicStudentDataType,
  changeDivisionInputStateType,
} from "src/components/organism/Courses/BatchStudents/BatchStudentsHelpers";
import React, { useMemo, useState } from "react";
import { NoRecordsFound, TableCells, TableDiv, TableRows } from "./TableAtom";

import { DropdownOptions, DropdownOptionsType } from "src/types";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import Search from "src/components/atoms/SearchBar/index.web";
import SearchDropdown from "src/components/atoms/AutoComplete/index";
import { TableBody } from "@mui/material";
import styled from "styled-components";
import useTable from "./UseTable";
import { properCase } from "src/utils/utility";

type PropType = {
  currentDivison: string;
  loading: boolean;
  tableData?: BasicStudentDataType[] | null;
  availableDivisions: DropdownOptionsType[];
  inputState: Map<string, changeDivisionInputStateType>;
  setInputState: React.Dispatch<
    React.SetStateAction<Map<string, changeDivisionInputStateType>>
  >;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const headCells = [
  {
    id: "name",
    label: "name.label",
    align: "left",
  },
  {
    id: "gender",
    label: "gender.label",
    align: "left",
  },
  {
    id: "rollNo",
    label: "rollno.label",
    align: "left",
  },
  {
    id: "division",
    label: "division.label",
    align: "right",
  },
];

type TRow = BasicStudentDataType & {
  division: DropdownOptions
}

const ChangeDivisionTable: React.FC<PropType> = React.memo((props) => {
  const {
    currentDivison,
    tableData,
    availableDivisions,
    inputState,
    setInputState,
    searchValue,
    setSearchValue,
    loading
  } = props;
  const { TblContainer, TblHead } = useTable(tableData, headCells);

  const currentDivOption = {
    label: currentDivison,
    value: currentDivison,
  }

  const [rowData, setRowData] = useState<TRow[]>([])

  useMemo(() => {
    if (tableData && tableData?.length > 0) {
      const rows = tableData.map((student) => ({
        id: student.id,
        fullName: student.fullName ?? "-",
        gender: student.gender ?? "-",
        rollNo: student.rollNo ?? "-",
        division: currentDivOption,
      }))
      setRowData(rows)
    }
  }, [tableData]);

  // Function to update division value in the map
  const handleChange = (newValue: DropdownOptionsType, item: BasicStudentDataType) => {
    setInputState((prevMap) => {
      const updatedMap = new Map(prevMap);
      updatedMap.set(item.id, { id: item.id, division: newValue });
      return updatedMap;
    });
  };

  // Function to fetch division value from map based on id
  const getDivisionByStudentId = (id: string): DropdownOptionsType | null => {
    const studentEntry = inputState.get(id);
    return studentEntry ? studentEntry.division : currentDivOption;
  };

  function handleSearch(searchString: string): void {
    setSearchValue(searchString);
  }

  return (
    <>
      <Search
        id="changeDivisionSearch"
        handleChange={handleSearch}
        value={searchValue}
        width={"50%"}
      />
      {!loading ? (
        <TableDiv>
          <TblContainer tabledata={tableData}>
            <TblHead />
            {rowData?.length > 0 ? (
              <TableBody>
                {rowData?.map((item: BasicStudentDataType) => (
                  <TableRows key={item.id}>
                    <TableCells value={properCase(item.fullName)} />
                    <TableCells value={item?.gender || '-'} />
                    <TableCells value={item?.rollNo || '-'} />
                    <TableCells align={'right'}>
                      <DivisionContainer>
                        <SearchDropdown
                          options={availableDivisions}
                          onChange={(e, newValue: any) => handleChange(newValue, item)}
                          value={getDivisionByStudentId(item.id)}
                          width={200}
                        />
                      </DivisionContainer>
                    </TableCells>
                  </TableRows>
                ))}
              </TableBody>
            ) : (
              <NoRecordsFound colspan={4} maxHeight={47.3} />
            )}
          </TblContainer>
        </TableDiv>
      ) : (
        <LoaderContainer>
          <LoaderSpinner />
        </LoaderContainer>
      )}
    </>
  );
});

const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DivisionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default ChangeDivisionTable;
