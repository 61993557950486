import { colors } from 'src/styles/theme/styles.ts';

export function getPromotionStatusColor(status: string) {
  switch (status) {
    case 'ELIGIBLE':
      return colors.yellow;
    case 'NOT_ELIGIBLE':
      return colors.red;
    case 'PROMOTED':
      return colors.green;
    default:
      return colors.primaryText;
  }
}

export function getResultStatusColor(status: string) {
  switch (status) {
    case 'DRAFT':
      return colors.blue;
    case 'PUBLISHED':
      return colors.green;
    case 'BLOCK':
      return colors.red;
    default:
      return colors.primaryText;
  }
}

export const ResultStatusLabel = {
  BLOCK: 'BLOCKED',
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
} as any;

export interface ResolutionObject {
  grace?: number;
  graceName?: string;
  count?: number;
  marksheets: {
    marks: {
      id: string;
      creditGrades: number;
      creditsEarned: number;
      sgpa: number;
      cgpa: number;
      average: number;
      totalMarks: number;
      marksObtained: number;
      grade: string;
      user: {
        id: string;
        fullName: string;
        academic: {
          rollNo: string;
        };
      };
    };
    subjectMarks: {
      marks: {
        id: string;
        creditsEarned: number;
        passingMarks: number;
        marksObtained: number;
        totalMarks: number;
        grade: string;
        gradePoint: number;
        subject: {
          id: string;
          name: string;
          code: string;
        };
      };
      testMarks: {
        marks: {
          marksObtained: number;
          grace: number;
        };
        test: {
          id: string;
          name: string;
          passingMarks: number;
          totalMarks: number;
        };
      }[];
    }[];
  }[];
}

export type TRevalData = {
  id: string;
  name: string;
  dueDate: string;
  status: string;
};

export type TAtktData = {
  id: string;
  name: string;
  baseExam: string;
  status: string;
  dueDate: string;
};

export type TPaymentDetails = {
  amount: String;
  custContact: String;
  custEmail: String;
  merchId: String;
  token: String;
  txnId: String;
};

export const headCells2 = [
  {
    id: 'studentName',
    label: 'studentName.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: '150px' },
  },
  {
    id: 'rollNo',
    label: 'rollno.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'rollNo',
    isSorted: false,
    style: { minWidth: '70px' },
  },
  {
    id: 'average',
    label: 'average.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '50px', paddingRight: 80 },
  },
  {
    id: 'status',
    label: 'status.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: '100px' },
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '50px' },
  },
];

export const headCells1 = [
  {
    id: 'studentName',
    label: 'studentName.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: '150px' },
  },
  {
    id: 'rollNo',
    label: 'rollno.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'rollNo',
    isSorted: false,
    style: { minWidth: '70px' },
  },
  {
    id: 'creditsEarned',
    label: 'credits-earned.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '110px' },
  },
  {
    id: 'creditGrade',
    label: 'credit-grade.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '100px' },
  },
  {
    id: 'sgpa',
    label: 'SGPA (Grade)',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '80px' },
  },
  {
    id: 'cgpa',
    label: 'cgpa.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '50px', paddingRight: 80 },
  },
  {
    id: 'status',
    label: 'status.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: '100px' },
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '50px' },
  },
];
