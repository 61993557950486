import { FieldValues, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  useBlockApplication,
  useRejectApplication,
} from "src/graphql/admission/application";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { TAdmissionDetailFormState as TForm } from "src/components/organism/Admission/AdmissionDetail/AdmissionDetailTypes";
import { TFormProps } from "src/types";
import formJSON from "src/form-json/admission/Applications/reject-block-application-form.json";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";

type Props = TFormProps<
  TForm<{ applicationId: string; action: "REJECT" | "BLOCK" }>
>;

const RejectBlockApplicationForm = (props: Props) => {
  const { form, onSuccess, onError, onClose } = props;
  const { applicationId, action } = form.data!;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { rejectApplication } = useRejectApplication();
  const { blockApplication } = useBlockApplication();

  const label =
    action === "REJECT" ? "rejectApplication.text1" : "blockApplication.text1";

  const [elements, setElements] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { fields } = elements ?? {};

  useEffect(() => {
    formJSON[0].fields[FM.remarks].textHead =
      action === "REJECT" ? "rejectionReason.text" : "blockReason.text";
    setElements({ ...formJSON[0] });
    return () => {
      formJSON[0].fields[FM.remarks].textHead = "";
    };
  }, [action]);

  const onSubmitFn = async (data: FieldValues) => {
    if (data) {
      setLoading(true);
      try {
        const mutation =
          action === "REJECT" ? rejectApplication : blockApplication;
        const response = await mutation({
          variables: {
            id: applicationId,
            ...(data?.remarks ? { remarks: data?.remarks } : {}),
          },
        });
        if (response?.data) {
          onSuccess(
            action === "REJECT"
              ? t("rejectApplication.text")
              : t("blockApplication.text")
          );
        }
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      modalVisible={true}
      Headerpopup={t(label)}
      width={600}
      handleSave={handleSubmit(onSubmitFn)}
      addEditButtonLabel={action === "REJECT" ? "reject.label" : "block.label"}
    >
      <Container>
        <HeadingWrapper>
          <MediumTextSelect
            value={
              action === "REJECT"
                ? t("rejectApplication.text2")
                : t("blockApplication.text2")
            }
          />
        </HeadingWrapper>
        {fields &&
          fields.map((field: any) => (
            // @ts-ignore
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
            />
          ))}
      </Container>
    </NormalModal>
  );
};

const FM = {
  remarks: 0,
};

const Container = styled.div`
  height: 192px;
`;

const HeadingWrapper = styled.div`
  margin-bottom: 16px;
`;

export default RejectBlockApplicationForm;
