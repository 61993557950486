import { NoRecordsFound, TableCells, TableDiv, TableRows } from 'src/components/molecules/Table/TableAtom';

import Chip from 'src/components/atoms/Chip';
import React from 'react';
import { TableBody } from '@mui/material';
import styled from 'styled-components/native';
import useTable from 'src/components/molecules/Table/UseTable';

export default function ExamsNestedTable(props: any) {
  const { data } = props;

  let maxHeight;
  let row = sortSubjectsByOrder([...data?.tests]) || [];
  let totalCount = data?.tests?.length;

  function sortSubjectsByOrder(subjects: any) {
    return subjects.sort((a: { order: number; }, b: { order: number; }) => a?.order - b?.order);
  }

  const { TblContainer, recordsAfterPagingAndSorting } = useTable(
    row,
    null,
    maxHeight,
    totalCount,
  );

  function clipLongText(name: any): string {
    // if text is too long then truncate and add ellipsis
    // else return unchanged
    return (name?.length > 35) ? (name.substring(0, 33) + '...') : name;
  }

  return (
    <ParentContainer>
      <TableDiv>
        <TblContainer height={'100%'}>
          <>
            {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                  <>
                    <TableRows key={i} style={{ width: '2.5%'}}>
                      {/* Intentionally blank to cover column space for subjects & test names */}
                      <TableCells style={{ width: '25%', paddingLeft: '42.73px' }} />
                      <TableCells value={item?.subject?.name} style={{ width: '30%' }} />
                      <TableCells value={item?.order} align={'right'} />
                      <TableCells
                        value={
                          item.tests?.length > 0 &&
                          item.tests?.map((chip: any, nos: any) => (
                            <>
                              <div
                                style={{
                                  display: 'inline-block',
                                  marginBottom: nos === item.tests?.length - 1 ? 0 : 4,
                                  marginRight: 15,
                                }}>
                                <Chip label={clipLongText(chip?.name)} close={false} />
                              </div>
                            </>
                          ))
                        }
                        style={{ width: '30%' }}
                      />
                      {/* Intentionally blank to cover column space for actions */}
                      <TableCells style={{ minWidth: '5%', }} />
                    </TableRows>
                  </>
              ))}
              </TableBody>
            ) : (
              <NoRecordsFound colspan={4} />
            )}
          </>
        </TblContainer>
      </TableDiv>
    </ParentContainer>
  );
}

const ParentContainer = styled.View`
  display: flex;
  padding-bottom: 24px;
  padding-top: 10px;
  border-color: "rgba(43, 120, 202, 0.3)";
  border-width: 2px;
`;