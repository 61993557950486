import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { isNative } from 'src/constant/device';
import MediumText from 'src/components/atoms/Text/MediumText';
import { Icon } from 'src/components/atoms/Icon/Icon';

interface IProps {
  text1: string;
  text2?: string;
  icon: any;
  type?: "default" | "custom";
}

const NoData = ({ text1 = '', text2, icon, type = 'default' }: IProps) => {
  return (
    <Container>
      {type === 'default' ? (
        <FontAwesomeIcon
          icon={icon || faTimes}
          size={40}
          color={colors.tertiaryText}
          style={{ marginBottom: 6 }}
        />
      ) : (
        <IconContainer>
          <Icon name={icon} size={40} color={colors.tertiaryText} />
        </IconContainer>
      )}

      <TextContainer>
        {
          isNative ?
            <MediumText value={text1} color={colors.secondaryText} />
            :
            <HeaderThree value={text1} color={colors.secondaryText} />
        }

      </TextContainer>

      {text2 ? (
        <TextContainer2>
          {
            isNative ?
              <MediumText value={text1} color={colors.secondaryText} />
              :
              <HeaderThree value={text2} color={colors.tertiaryText} lineHeight={24} />
          }
        </TextContainer2>
      ) : null}
    </Container>
  );
};

const Container = styled.View`
  position: relative;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.View`
  margin: 2px 0px;
`;

const TextContainer2 = styled.View`
  margin: 16px 0px 2px 0px;
`;

const IconContainer = styled.View`
  margin-bottom: 6
`;

export default NoData;
