import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import formJSON from '../../../form-json/reports/reports.json';
import Element from '../../molecules/Forms/ApplicationElement.web';
import styled from 'styled-components';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { graphqlQuery } from 'src/graphql/util';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { mergeBatchClassLabel } from 'src/utils/utility';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form/dist/types';
import { fieldOptions } from './helpers';
import { getFilteredAcademicYears } from 'src/components/services';
import { format, startOfToday } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { useI18n } from 'src/i18n/hooks';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { colors, fonts } from 'src/styles/theme/styles';
import {
  getReportStatusQuery,
  useCreateAdmissionCancellationReport,
  useCreateDisabledStudentsReport,
} from 'src/graphql/reports/index';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ERROR, SUCCESS } from 'src/constant';
import ERROR_MSG from 'src/constant/error';
import { ADD_SUCCESS } from 'src/constant/message';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { Icon } from 'src/components/atoms/Icon/Icon';
import OutlineButtonWeb from 'src/components/atoms/Button/OutlineButton.web';
import { Tooltip } from '@mui/material';

interface Props {
  control: Control<FieldValues, object>;
  errors: { [x: string]: any };
  setValue: UseFormSetValue<FieldValues>;
  reportType: string;
  handleSubmit: any;
  reportDetail: { status: string; createdAt: Date } | null;
  setReportDetail: Dispatch<SetStateAction<{ status: string; createdAt: Date } | null>>;
  closeReportModal: () => void;
  handleDownloadUserReport: (formData: any) => void;
  instituteId?: string;
}

export default function UserReportForm(props: Props) {
  const [elements, setElements] = useState<any>({});
  const [canClick, setCanClick] = useState<boolean>(true);
  const [academicYear, setAcademicYear] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const { fields }: any = elements ?? {};
  const {
    control,
    errors,
    setValue,
    reportType,
    instituteId,
    handleSubmit,
    reportDetail,
    setReportDetail,
    closeReportModal,
    handleDownloadUserReport,
  } = props;
  const regenReport = Boolean(
    reportType == 'Admission Cancellation Report' || reportType == 'Disabled Students Report',
  );
  const currentDate = format(startOfToday(), DT.DATE_FORMAT_SLASH);
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();

  const { createAdmissionCancellationReport } = useCreateAdmissionCancellationReport();
  const { createDisabledStudentsReport } = useCreateDisabledStudentsReport();

  useEffect(() => {
    return () => {
      clearDropdown([
        { idx: 0, visibility: false },
        { idx: 1, visibility: false },
        { idx: 2, visibility: false },
        { idx: 3, visibility: false },
      ]);
    };
  }, []);

  useEffect(() => {
    loadFunction();
  }, [reportType]);

  async function loadFunction() {
    await loadBatches();
    await loadAcademicYears();
    handleReportType(reportType);
  }

  async function loadBatches() {
    let { data: allBatches, loading } = await graphqlQuery(getBatchQuery, { limit: 0 });
    if (!loading && allBatches?.batches?.data) {
      let options = mergeBatchClassLabel(allBatches?.batches?.data);
      formJSON[0].fields[0].option = options;
    }
  }

  async function loadAcademicYears() {
    let param = {
      limit: 0,
    } as any;

    const academicYearResponse = await getFilteredAcademicYears(param);
    if (academicYearResponse?.options) {
      formJSON[0].fields[1].option = academicYearResponse.options;
    }
  }

  function clearDropdown(fields: fieldOptions[]) {
    const newData = { ...formJSON[0] };
    fields.forEach((item: fieldOptions) => {
      newData.fields[item?.idx].fieldVisible = item.visibility;
    });
    setElements(newData);
  }

  async function handleReportType(reportType: string) {
    const newData = { ...formJSON[0] };
    switch (reportType) {
      case 'User Report':
        clearDropdown([
          { idx: 0, visibility: true },
          { idx: 1, visibility: false },
          { idx: 2, visibility: false },
          { idx: 3, visibility: false },
        ]);
        break;
      case 'Gender & Caste Report':
        clearDropdown([
          { idx: 0, visibility: false },
          { idx: 1, visibility: true },
          { idx: 2, visibility: false },
          { idx: 3, visibility: true },
        ]);
        break;
      case 'Attendance Report':
        clearDropdown([
          { idx: 0, visibility: false },
          { idx: 1, visibility: true },
          { idx: 2, visibility: true },
          { idx: 3, visibility: false },
        ]);
        break;
      case 'Admission Cancellation Report':
      case 'Disabled Students Report':
        clearDropdown([
          { idx: 0, visibility: false },
          { idx: 1, visibility: true },
          { idx: 2, visibility: false },
          { idx: 3, visibility: false },
        ]);
        break;
      default:
        clearDropdown([
          { idx: 0, visibility: false },
          { idx: 1, visibility: false },
          { idx: 2, visibility: false },
          { idx: 3, visibility: false },
        ]);
    }
    setElements(newData);
  }

  async function handleCustomSelect(id: string, data: any) {
    if (regenReport) {
      setLoading(true);
      setAcademicYear('');
      setReportDetail(null);
      if (data?.value) {
        setAcademicYear(data.value);
        const { data: reportStatusData, loading } = await graphqlQuery(getReportStatusQuery, {
          academicYearId: data.value,
          instituteId: instituteId,
          type:
            reportType == 'Admission Cancellation Report'
              ? 'CANCEL_ADMISSION_USAGE_REPORT'
              : 'HANDICAP_REPORT',
        });
        if (!loading) {
          setLoading(loading);
          if (reportStatusData) setReportDetail(reportStatusData?.getLatestUsageReportStatus);
        }
      }
    }
  }

  async function handleGenerateReport(formData: any) {
    setCanClick(false);
    try {
      const response = await createAdmissionCancellationReport({
        variables: {
          academicYearId: formData?.academicYear?.value,
          instituteId: instituteId,
        },
      });
      if (response?.data?.generateAdmissionCancelReport?.status === 'success') {
        closeReportModal();
        setAlertDetails({ message: ADD_SUCCESS.ADMISSION_CANCELLATION_REPORT, level: SUCCESS });
        setCanClick(true);
      } else {
        throw new Error(
          response?.data?.generateAdmissionCancelReport?.message || ERROR_MSG.GENERIC_ERROR,
        );
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  async function handleGenerateDisabledStudentsReport(formData: any) {
    setCanClick(false);
    try {
      const response = await createDisabledStudentsReport({
        variables: {
          academicYearId: formData?.academicYear?.value,
          instituteId: instituteId,
        },
      });
      if (response?.data?.generateHandicapReport?.status === 'success') {
        closeReportModal();
        setAlertDetails({ message: ADD_SUCCESS.DISABLED_STUDENTS_REPORT, level: SUCCESS });
        setCanClick(true);
      } else {
        throw new Error(response?.data?.generateHandicapReport?.message || ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  return fields ? (
    <>
      <ContainerWrapper>
        <InfoWrapper>
          <NormaltextAtom value={t('form.reportType.label')} fontSize={1.2} lineHeight={1.6} />
          <MediumTextSelect
            value={reportType}
            color={colors.primaryText}
            fontFamily={fonts.semibold}
          />
        </InfoWrapper>
        {fields.map((field: any, i: number) => (
          //@ts-ignore
          <Element
            key={`report${i}`}
            field={field}
            control={control}
            errors={errors}
            dropdownWidth={480}
            handleCustomSelect={handleCustomSelect}
            maxDropdownOptionsHeight={200}
          />
        ))}

        {regenReport && academicYear && !loading && (
          <GenerateReportWrapper>
            {(!reportDetail?.status || reportDetail?.status === 'FAILED') && (
              <GenerateContentWrapper>
                <MediumTextSelect value={t('generateReport.message.text')} />
                <SecondaryBtn
                  onPress={handleSubmit(
                    reportType == 'Admission Cancellation Report'
                      ? handleGenerateReport
                      : handleGenerateDisabledStudentsReport,
                  )}
                  label={t('generate.label')}
                  width={92}
                  canLoad={!canClick}
                />
              </GenerateContentWrapper>
            )}
            {(reportDetail?.status === 'COMPLETED' || reportDetail?.status == 'INITIATED') && (
              <DownloadContentWrapper>
                <SmallTextAtom value={reportType} />
                <DownloadContainer>
                  <Icon name="spreadsheet" />
                  <NormalTextSelect value={'Report.xls'} />
                  <Tooltip title={t('download.label')}>
                    <DownloadIcon
                      onClick={handleSubmit(handleDownloadUserReport)}>
                      <Icon name="download" />
                    </DownloadIcon>
                  </Tooltip>
                </DownloadContainer>

                <RegenerateContainer>
                  <GenerateDetailsWrapper>
                    <SmallTextAtom value={t('generatedOn.label')} />
                    <MediumTextSelect
                      value={format(reportDetail?.createdAt, DT.DATE_TIME_SLASH_12_HOUR)}
                      fontFamily={fonts.semibold}
                      color={colors.primaryText}
                    />
                  </GenerateDetailsWrapper>
                  <OutlineButtonWeb
                    text={t('regenerate.label')}
                    cb={handleSubmit(
                      reportType == 'Admission Cancellation Report'
                      ? handleGenerateReport
                      : handleGenerateDisabledStudentsReport,
                    )}
                  />
                </RegenerateContainer>
              </DownloadContentWrapper>
            )}
          </GenerateReportWrapper>
        )}
      </ContainerWrapper>
    </>
  ) : (
    <SpinnerWrapper regenReport={regenReport}>
      <LoaderSpinner />
    </SpinnerWrapper>
  );
}

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
`;

const SpinnerWrapper = styled.div<{ regenReport: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 482px;
  height: ${({ regenReport }) => (regenReport ? 254 : 404)};
`;

const InfoWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

const GenerateReportWrapper = styled.div`
  width: 100%;
`;

const GenerateContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
`;

const DownloadContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 6px;
`;

const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const RegenerateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
`;

const GenerateDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DownloadIcon = styled.div`
  cursor: pointer;
`;
