import { streams, scoreType } from './dd-options';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import dataTypes from '@data-driven-forms/react-form-renderer/data-types';
import { regexObject } from 'src/constant/regex';
import { isObject } from 'src/utils/utility';
import { allDocumentsType, FormDDOptions, FormSchema, ViewMode } from 'src/components/molecules/Forms/types';
import { getComponentType } from './util';
import { FormOptions } from '@data-driven-forms/react-form-renderer/renderer-context';
import { DropdownOptionsType } from 'src/types';

export const EducationDetailsTableColumns = [
  {
    "id": "indicatorBadge",
    "name": ""
  },
  {
    "id": "course",
    "name": "course.label",
  },
  {
    "id": "university",
    "name": "university.label",
  },
  {
    "id": "institution",
    "name": "institute.label",
  },
  {
    "id": "paassingYear",
    "name": "passingYear.label",
  },
  {
    "id": "gradeObtained",
    "name": "gradeNMarks.label",
  },
  {
    "id": "documents",
    "name": "form.documents.label",
  },
  {
    "id": "actions",
    "name": "actions.label",
  },
];

export const education = (
  viewMode: ViewMode,
  formDDOptions?: FormDDOptions,
  isInstituteAdmin?: boolean,
): FormSchema => ({
  name: 'educationDetails',
  addNewDetailsPrompt: 'addEducationDetailsOptional.text',
  label: 'addEducationDetails.text',
  viewMode,
  listOptions: {
    iconName: 'education',
    deleteMessageTitleLabel: 'deleteAcademicDetails.text',
    deleteMessageLabel: 'deleteAcademicDetails.text1',
    columns: EducationDetailsTableColumns,
  },
  headerData: {
    title: 'educationDetails.label',
    skip: true,
    icon: require('src/assets/common/headerOnboardingIcons/v1/academic-3x.png'),
    progress: 0.6,
  },
  helpers: (detail: any) => ({
    title: `${detail?.course || "-"}`,
    subtitle: detail?.institution || '',
    content1: detail?.paassingYear ? `Passing Year - ${detail?.paassingYear}` : "",
    content2: detail?.gradeObtained
      ? `Grade - ${detail.gradeObtained}`
      : detail?.marksObtained
        ? `Marks - ${detail.marksObtained} / ${detail.totalMarks}`
        : ""
  }),
  fields: [
    {
      name: 'basic-education-details',
      label: 'educationDetails.label',
      component: "three-column-custom-sub-form",
      fields: [
        {
          name: 'course',
          label: 'course.required.label',
          isRequired: true,
          component: getComponentType(viewMode, 'custom-text-field'),
          autoCapitalize: "words",
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.course.required',
            },
          ] : [],
        },
        {
          name: 'institution',
          label: 'form.institute.required.label',
          isRequired: true,
          type: 'text',
          component: getComponentType(viewMode, 'custom-text-field'),
          autoCapitalize: "words",
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.instituteName.required',
            },
          ] : [],
        },
        {
          name: 'university',
          label: 'form.board.required.label',
          component: getComponentType(viewMode, 'custom-text-field'),
          autoCapitalize: "words",
          isRequired: true,
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.boardName.required',
            },
          ] : [],
        },
        {
          name: 'udiseNo',
          label: 'form.udiseId.required.label',
          component: getComponentType(viewMode, 'custom-text-field'),
          isRequired: true,
          type: 'text',
          condition: {
            when: "qualificationRef.level",
            is: 10
          },
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.udise.required',
            },
            {
              type: validatorTypes.PATTERN,
              pattern: regexObject.INTGER_ONLY,
              message: 'error.udiseId.invalid.text'
            },
          ] : [
            {
              type: validatorTypes.PATTERN,
              pattern: regexObject.INTGER_ONLY,
              message: 'error.udiseId.invalid.text'
            },
          ],
        },
        {
          name: "blank1",
          label: "",
          component: "none",
          ignoreInProfileView: true,
          condition: {
            when: "qualificationRef.level",
            is: 10
          },
        },
        {
          name: "blank2",
          label: "",
          component: "none",
          ignoreInProfileView: true,
          condition: {
            when: "qualificationRef.level",
            is: 10
          },
        },
        {
          name: 'streams',
          label: 'form.streams.label',
          majorSubjectField: "majorSubjectMarks",
          options: formDDOptions?.streams || [],
          component: getComponentType(viewMode, "streamSelection"),
          isRequired: true,
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.streams.required',
            },
          ] : [],
        },
        {
          name: 'paassingYear',
          label: 'form.passingYear.required.label',
          component: getComponentType(viewMode, 'date-time-picker'),
          dateTimeMode: "year",
          isRequired: true,
          dataType: dataTypes.INTEGER,
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.passingYear.required',
            },
          ] : [],
          disableFuture: true,
        },
        {
          name: 'passedInFirstAttempt',
          label: 'form.passedInFirstAttempt.label',
          component: getComponentType(viewMode, 'checkbox'),
          displayCentered: true,
          marginTopOffset: -8,
          maxHeight: 80,
          resolveProps: (_props: any, _field: any, formOptions: any) => (
            formOptions.getState().values.passedInFirstAttempt ? { isChecked: true } : { isChecked: false }
          ),
        },
        {
          name: "scoreType",
          label: 'form.scoreType.required.label',
          isRequired: true,
          component: viewMode == ViewMode.READ ? 'none' : 'select',
          options: scoreType,
          ignoreField: true,
          ignoreInProfileView: true,
          resolveProps: (props: any, field: any, formOptions: FormOptions) => {
            const { values } = formOptions.getState();
            if (values && isObject(values) && !field.input.value) {
              if (values.totalMarks || values.marksObtained) {
                formOptions.change("scoreType", "marks");
              } else if (values.gradeObtained) {
                formOptions.change("scoreType", "grades");
              }
            }
          },
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.scoreType.required',
            },
          ] : [],
        },
        {
          name: 'marksObtained',
          label: 'form.totalMarksObtained.required.label',
          component: getComponentType(viewMode, 'custom-text-field'),
          isRequired: true,
          type: 'number',
          dataType: dataTypes.INTEGER,
          condition: {
            when: "scoreType",
            is: "marks"
          },
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.marksObtained.required',
            },
            {
              type: validatorTypes.PATTERN,
              pattern: regexObject.INTGER_ONLY,
              message: 'error.marksObtained.invalid',
            },
            {
              type: "marks"
            }
          ] : [
            {
              type: validatorTypes.PATTERN,
              pattern: regexObject.INTGER_ONLY,
              message: 'error.marksObtained.invalid',
            },
            {
              type: "marks"
            }
          ]
        },
        {
          name: 'totalMarks',
          label: 'total-marks.required.label',
          component: getComponentType(viewMode, 'custom-text-field'),
          isRequired: false,
          type: "number",
          dataType: dataTypes.INTEGER,
          condition: {
            when: "scoreType",
            is: "marks",
          },
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.totalMarks.required',
            },
            {
              type: validatorTypes.PATTERN,
              pattern: regexObject.INTGER_ONLY,
              message: 'error.totalMarks.invalid',
            }
          ] : [
            {
              type: validatorTypes.PATTERN,
              pattern: regexObject.INTGER_ONLY,
              message: 'error.totalMarks.invalid',
            }
          ]
        },
        {
          name: 'gradeObtained',
          label: 'form.gradesObtained.required.label',
          component: getComponentType(viewMode, 'custom-text-field'),
          type: "text",
          autoCapitalize: "words",
          isRequired: true,
          condition: {
            when: "scoreType",
            is: "grades"
          },
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.gradeObtained.required',
            },
          ] : [],
        },
        {
          name: 'gradeNMarks',
          label: 'gradeNMarks.label',
          component: viewMode == ViewMode.READ ? 'fieldview' : 'none',
          type: "text",
          ignoreField: true,
          resolveProps: (props: any, fields: any, formOptions: FormOptions) => {
            const { values } = formOptions.getState();
            if (values && isObject(values)) {
              if (values.totalMarks && values.marksObtained) {
                formOptions.change("gradeNMarks", `${values.marksObtained} / ${values.totalMarks}`);
              } else if (values.gradeObtained) {
                formOptions.change("gradeNMarks", values.gradeObtained);
              }
            }
            return props;
          },
        },
      ],
    },
    {
      name: "majorSubjectMarksDetails",
      label: "",
      component: "none",
      fields: [
        {
          name: "majorSubjectMarks",
          label: "major-subjects.label",
          component: "none",
        },
      ],
    },
    {
      name: 'educationDocument',
      label: "",
      component: "three-column-custom-sub-form",
      fields: [
        {
          name: "documents",
          category: "Education",
          valueField: 'allDocument',
          label: "form.documents.label",
          type: 'dynamic-file-input',
          component: getComponentType(viewMode, "multi-doc-input"),
          threeColumnForm: true,
        }
      ],
    },
  ]
})