import { DefaultUserSelection, SchemaNameUserDocumentCategoryMapper } from 'src/constant';
import { isWeb } from 'src/constant/device';
import { documentTypesQuery } from 'src/graphql/onboarding/form-content';
import { graphqlQuery } from 'src/graphql/util';

const getSizeInMB = (bytes: number) => (bytes / (1024 * 1024)).toFixed(2);

export const fileSizeValidator = ({ maxSize }: any) => {
    return (value: any) => {
        if (value && value[0]?.size > maxSize) {
            return `file size exceeds ${getSizeInMB(maxSize)} MB`;
        }
    };
};

const allowedtypes = [
    'jpeg',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/*',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const fileTypeValidator = () => {
    return (value: any) => {

        if (isWeb) {
            if (value && value.document && value.document?.type && !allowedtypes.includes(value.document?.type)) {
                return `Invalid file type`;
            } else if (value && value[0]?.type && !allowedtypes.includes(value[0].type)) {
                return `Invalid file type`;
            }
        } else {
            if (value && value.document && value.document[0]?.type && !allowedtypes.includes(value.document[0]?.type)) {
                return `Invalid file type`;
            } else if (value && value[0]?.type && !allowedtypes.includes(value[0].type)) {
                return `Invalid file type`;
            }
        }

    };
};


export const imageSizeValidator = ({ maxSize }: any) => {
    return (value: any) => {
        if (isWeb) {
            if (value && value.document && value.document.size && value.document.size > maxSize) {
                return `file size exceeds ${getSizeInMB(maxSize)} MB`;
            }
        } else {
            if (value && value.document && value.document[0]?.size > maxSize) {
                return `file size exceeds ${getSizeInMB(maxSize)} MB`;
            } else if (value && value[0]?.size > maxSize) {
                return `file size exceeds ${getSizeInMB(maxSize)} MB`;
            }
        }
    };
};

export const marksValidator = () => {
    return (value: number, allValues: any) => {
        if (value > allValues.totalMarks) {
            return "error.marksObtained.message";
        }
    }
}

export const contactValidator = () => {
    return (value: number, allValues: any) => {
        if (value && allValues?.mobileNumber && value === allValues.mobileNumber) {
            return "error.emergencyContactNumber.same";
        }
    }
}

const getAllDocuments = async (schemaName: string) => {
  const { data } = await graphqlQuery(documentTypesQuery, {
    filters: {
      defaultUserSelection: { eq: DefaultUserSelection.ALLOWED },
      category: { eq: SchemaNameUserDocumentCategoryMapper[schemaName] },
    },
  });
  const allDocument = data?.documentTypes?.data?.map((item: any) => ({
    document: null,
    type: item,
  }));
  return allDocument;
};

const validateDocuments = (allDocuments: any, value: any, valueField?: string, label?: string) => {
  let errFields: string[] = [];
  allDocuments?.forEach((item: any) => {
    const documents = valueField ? value?.[`${valueField}`]?.documents : value?.documents;
    const values = documents?.filter(
      (doc: any) =>
        item?.type?.typeId === doc?.typeRef ||
        (item?.document?.id && item?.document?.id === doc?.id),
    );
    if ((!values || (values && values.length === 0)) && item?.type?.defaultUserSelectionRequired) {
      errFields.push(`${item?.type?.label?.split('*')[0]} ${label ? `(${label})` : ''}`);
    }
  });
  return errFields;
};

export const documentValidator = async (value: any, schemaName: string): Promise<string> => {
  const allDocumentTypes = await getAllDocuments(schemaName);
  let errFields: string[] = [];

  if (schemaName === 'parentDetails') {
    const fatherDetailAllDocument = value?.fatherDetail?.allDocument || allDocumentTypes;
    const motherDetailAllDocument = value?.motherDetail?.allDocument || allDocumentTypes;

    const fatherDetailErrFields = validateDocuments(fatherDetailAllDocument, value, 'fatherDetail', 'Father');
    const motherDetailErrFields = validateDocuments(motherDetailAllDocument, value, 'motherDetail', 'Mother');

    errFields = [...fatherDetailErrFields, ...motherDetailErrFields];
  } else {
    const allDocument = value?.allDocument || allDocumentTypes;
    errFields = validateDocuments(allDocument, value);
  }
  return errFields?.length > 0 ? errFields.join(', ').concat(' is required') : '';
};
