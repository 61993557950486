import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';

export const addQualification = (
  setStreams: Function,
  setDocuments: Function,
  tableData: Array<string>,
  streams: Array<string>,
  documents: Array<string>,
  isEdit: boolean,
  documentOptions: Array<string>,
  qualificationNameOptions: Array<string>,
  searchHandleChange: Function,
  searchDocumentHandleChange: Function,
  isDataAvailable: boolean,
  setIsDocumentsDataAvailable: boolean,
) => ({
  name: 'add-qualification',
  label: 'addQualification.label',
  viewMode: 1,
  fields: [
    {
      name: 'qualificationName',
      label: 'qualification.required.label',
      component: 'custom-select',
      options: qualificationNameOptions,
      isRequired: true,
      disabled: isEdit,
      handleChange: (formOptions: any, newValue: { value: string; }) => {},
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.qualifications.required',
        },
      ],
    },
    {
      name: 'none',
      component: 'none',
    },
    {
      name: 'searchStreams',
      id: 'searchStreams',
      header: 'selectPreviousStreams.text',
      label: 'Search Streams',
      width: 350,
      component: 'search',
      handleChange: (e: string) => searchHandleChange(e),
    },
    {
      component: 'none',
      name: 'none',
    },
    {
      name: 'streams',
      component: 'input-chips-table',
      inputProperty: 'stream',
      inputValProperty: 'majorSubjects',
      inputHeader: 'major-subjects.label',
      maxHeight: 272,
      height: 272,
      tableData: tableData,
      setData: setStreams,
      data: streams,
      subscribeToValues: true,
      isEdit: isEdit,
      isDataAvailable: isDataAvailable
    },
    {
      name: 'searchDocuments',
      id: 'searchDocuments',
      header: 'selectDocuments.text',
      label: 'Search Documents',
      width: 350,
      component: 'search',
      handleChange: (e: string) => searchDocumentHandleChange(e),
    },
    {
      component: 'none',
      name: 'none',
    },
    {
      name: 'documents',
      headCells: [
        {
          id: 'checkbox',
          label: '',
          align: 'left',
          style: { width: '6%' }
        },
        {
          id: 'documentName',
          label: 'documentName.label',
          align: 'left',
          style: { width: '60%' }
        },
        {
          id: 'markMandatory',
          label: 'markMandatory.label',
          align: 'left',
          style: { textAlign: 'center' }
        },
      ],
      component: 'document-selection-table',
      inputProperty: 'documentType',
      inputValProperty: 'majorSubjects',
      maxHeight: 272,
      height: 272,
      tableData: documentOptions,
      setData: setDocuments,
      data: documents,
      subscribeToValues: true,
      isEdit: isEdit,
      isDataAvailable: setIsDocumentsDataAvailable
    },
  ],
});
