import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { readCacheQuery, writeCacheQuery } from '../util';
import { createDeleteData, createSearchFilter } from '../../components/services/index';
import { getSubjectGroupFilter } from 'src/components/services/filters';

const addSubjectGroupMutation = gql`
  mutation ($payload: CreateSubjectGroupInput!) {
    createSubjectGroup(payload: $payload) {
      id
      name
      semester
      class {
        id
        alias
      }
      course {
        id
        alias
        university{
          id
          name
          alias
        }
      }
      subjects {
        id
        name
      }
    }
  }
`;

const updateSubjectGroupMutation = gql`
  mutation ($payload: UpdateSubjectGroupInput!) {
    updateSubjectGroup(payload: $payload) {
      id
      name
      semester
      class {
        id
        alias
      }
      course {
        id
        alias
        university{
          id
          name
          alias
        }
      }
      subjects {
        id
        name
      }
    }
  }
`;

const deleteSubjectGroupMutation = gql`
  mutation ($id: String!) {
    deleteSubjectGroup(id: $id) {
      status
      message
    }
  }
`;

// U can use this also for all limit = 0 skip = 0
const getSubjectGroupQuery = gql`
  query ($limit: Int, $skip: Int, $filters: SubjectGroupFilterInput) {
    subjectGroups(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
        semester
        rollNoPrefix
        batch {
          id
          name
          alias
          divisionDetails {
            division
            seats
          }
        }
        class {
          id
          alias
        }
        course {
          id
          alias
          university{
            id
            name
            alias
          }
        }
        subjects {
          id
          name
        }
        divisionDetails {
          division
          orderNo
        }
      }
    }
  }
`;

const getSubjectGroupOptionsQuery = gql`
  query ($limit: Int, $skip: Int, $filters: SubjectGroupFilterInput) {
    subjectGroups(limit: $limit, skip: $skip, filters: $filters) {
      data {
        value: id
        label: name
      }
    }
  }
`;

const getModifiedSubjectGroupsQuery = gql`
  query ($limit: Int, $skip: Int, $filters: SubjectGroupFilterInput) {
    subjectGroups(limit: $limit, skip: $skip, filters: $filters) {
      data {
        value: id
        label: name
      }
    }
  }
`;

const getSubjectGroups = gql`
  query ($limit: Int, $skip: Int, $filters: SubjectGroupFilterInput) {
    subjectGroups(limit: $limit, skip: $skip, filters: $filters) {
      data {
        id
        name
      }
    }
  }
`;

const getSubjectGroupById = gql`
  query ($id: String!) {
    subjectGroup(id: $id) {
      subjects {
        value: id
        label: alias
      }
    }
  }
`;

export function useMutateSubjectGroup(queries: any) {
  const [mutate, { error }] = useMutation(queries);
  return { mutate, error };
}

export function useQuerySubjectGroup() {
  const [query, { called, error, loading, data }] = useLazyQuery(getSubjectGroupQuery, { fetchPolicy: 'network-only' });
  return { query, data, error, loading };
}

export function useQuerySubjectGroupOptions() {
  const [query, { data }] = useLazyQuery(getSubjectGroupOptionsQuery, { fetchPolicy: 'network-only' });
  return { query, data, };
}

export function useAddSubjectGroup(page: number, searchValue: string, rowLimit: number) {
  let ErrorOccur = false;

  const [addSubjectGroup, { error: addSubjectGroupError, loading, data: addSubjectGroupData }] =
    useMutation(addSubjectGroupMutation, {
      update: (cache, { data: { createSubjectGroup } }) => {
        //read
        let filters = getSubjectGroupFilter(searchValue);
        let queryVariable = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
        let readQueryData = readCacheQuery(cache, getSubjectGroupQuery, queryVariable);
        let existingSubjectGroup = readQueryData.subjectGroups;
        if (!existingSubjectGroup.data) {
          ErrorOccur = true;
          return readQueryData;
        }
        //write
        let mergeArray = [createSubjectGroup, ...existingSubjectGroup.data];

        let finaldata = {
          data: mergeArray,
          hasNextPage :  readQueryData?.subjectGroups?.hasNextPage,
          totalCount :  (readQueryData?.subjectGroups?.totalCount) + 1,
          __typename : readQueryData?.subjectGroups?.__typename
        }

        let writeQueryData = writeCacheQuery(cache, getSubjectGroupQuery, queryVariable, {
          subjectGroups: finaldata,
        });

        if (!writeQueryData.__ref) {
          ErrorOccur = true;
          return writeQueryData;
        }
      },
    });
  return { addSubjectGroup, addSubjectGroupData, addSubjectGroupError, ErrorOccur };
}

export function useUpdateSubject() {
  const [updateSubjectGroup, { error: updateSubjectGroupError, data: updateSubjectGroupData }] =
    useMutation(updateSubjectGroupMutation);
  return { updateSubjectGroup, updateSubjectGroupData, updateSubjectGroupError };
}

export function useDeleteSubjectGroup(page: number, searchValue:string, rowLimit:number, subjectGroupId:string) {
  const [deleteSubjectGroup, { error: deleteSubjectGroupError, data: deleteSubjectGroupData }] =
    useMutation(deleteSubjectGroupMutation, {
      update: (cache, { data: { deleteSubjectGroup } }) => {
        let queryData;
        let filters = getSubjectGroupFilter(searchValue);
        let queryVariable = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
        //read
        try {
          queryData = readCacheQuery(cache, getSubjectGroupQuery, queryVariable);
        } catch (e) {
          //console.log(e.messgae);
        }
        let subjectGroup= queryData?.subjectGroups;
        let message = deleteSubjectGroup.message;
        // let subjectGroupId = message.replace("Successfully deleted subject group for ", "");
        let dataToUpdate = createDeleteData(subjectGroup,subjectGroupId);
        //write
        try {
          writeCacheQuery(
            cache,
            getSubjectGroupQuery,
            queryVariable,
            {
              subjectGroups: dataToUpdate
            },
          );
        } catch (e) {
          //console.log('error', e.messgae);
        }
      },
    });

  return { deleteSubjectGroup, deleteSubjectGroupError, deleteSubjectGroupData };
}

export {
  getSubjectGroupQuery, addSubjectGroupMutation, updateSubjectGroupMutation as updateCourseMutation,
  getModifiedSubjectGroupsQuery, getSubjectGroupById, getSubjectGroups
};
