import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import formJSON from '../../../form-json/academics/subject-groups.json';
import Element from '../Forms/ApplicationElement.web';
import { useQuery } from '@apollo/client';
import { autoCompleteLimit, AUTOCOMPLETE_MODULE } from '../../../constant';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import {
  getFilteredCourses,
  getFilteredClasses,
  getFilteredSubjects,
} from 'src/components/services';
import { getBatchByIdQuery, getBatchQuery } from 'src/graphql/academics/batch';
import { graphqlQuery } from 'src/graphql/util';
import { compareAndSort, mergeBatchClassLabel } from 'src/utils/utility';
import styled from 'styled-components';
import { semesterOptions } from './dd-options';
import { Grid } from '@mui/material';

interface SubjectGroupFormProps {
  control?: any;
  errors?: any;
  setSubjectIds?: any;
  selectedSubjects?: any;
  getValues?: any;
  setValue?: any;
  isEditModal?: boolean;
  editSubjectGroupData?: any;
  setBatchData?: any;
  batchData?: any;
  setDivision: Function;
  division: Array<Object>;
}

interface SubjectType {
  name: string;
  code: string;
}

export default function SubjectGroupForm(props: SubjectGroupFormProps) {
  const {
    setSubjectIds,
    selectedSubjects,
    getValues,
    setValue,
    isEditModal,
    editSubjectGroupData,
    setBatchData,
    batchData,
    setDivision,
    division,
  } = props;
  const [elements, setElements] = useState<any>({});
  const [checkedArray, setCheckArray] = useState(selectedSubjects);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectProxyList, setSubjectProxyList] = useState([]);
  const [divisionProxyList, setDivisionProxyList] = useState([]);
  const [divisionCheckedArray, setDivisionCheckedArray] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [divisionSearchData, setDivisionSearchData] = useState('');
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const [isDivisionDataAvailable, setIsDivisionDataAvailable] = useState<boolean>(false);
  const { fields }: any = elements ?? {};
  const allBatches = useQuery(getBatchQuery, {
    variables: { limit: 0, skip: 0 },
  });
  const batchDependentFieldIdx = [6, 7, 8, 9, 10, 11];

  useEffect(() => {
    formJSON[0].fields[3].option = semesterOptions;
    if (isEditModal) {
      loadEditData();
    } else {
      let getdata = allBatches.data;
      if (allBatches.loading == false) {
        loadBatches(getdata.batches.data);
      }
    }
  }, [allBatches.data]);

  async function lodBasicData(classId: string) {
    loadSubject(classId);
  }

  async function loadSubject(classId: string) {
    let filters = {
      class: {
        eq: classId,
      },
    };
    let subjectsResponse = await getFilteredSubjects({ filters: filters, limit: 0 });
    loadSubjects(subjectsResponse?.options);
  }

  async function loadEditData() {
    if (isEditModal) {
      formJSON[0].fields[0].required = false;
      setElements(formJSON[0]);
      lodBasicData(editSubjectGroupData?.class?.id);
      loadDivisions(editSubjectGroupData?.batch?.divisionDetails);
      let presentSubject = getPresentSubjects(editSubjectGroupData.subjects);
      let presentDivision = editSubjectGroupData?.divisionDetails?.map((item: any) => item?.division);
      resetFormField();
      setCheckArray(presentSubject);
      setSubjectIds(presentSubject);
      setDivisionCheckedArray(presentDivision);
    }
  }

  function resetFormField() {
    if (editSubjectGroupData?.batch) {
      setValue('batch', { label: editSubjectGroupData?.batch?.name });
    }
    setValue('group_name', editSubjectGroupData?.label);
    setValue('course', editSubjectGroupData?.course?.alias);
    setValue('class', editSubjectGroupData?.class?.alias);
    if (editSubjectGroupData?.semester) {
      setValue('semester', {
        label: editSubjectGroupData?.semester,
        value: editSubjectGroupData?.semester,
      });
    }
    if (editSubjectGroupData?.rollNoPrefix) {
      setValue('roll_no_prefix', editSubjectGroupData.rollNoPrefix);
    }
  }

  function loadBatches(data: any) {
    let options = mergeBatchClassLabel(data);
    setElements({});
    formJSON[0].fields[0].option = options;
    setElements(formJSON[0]);
  }

  function getSubjectNamesWithCodeArray(data: SubjectType[]) {
    return data.map((item) => ({
      ...item,
      name: `${item.name} (${item.code})`,
    }));
  }

  function loadSubjects(subjectArray: SubjectType[]) {
    const SubjectNamesWithCodeArray = getSubjectNamesWithCodeArray(subjectArray);
    setSubjectList(SubjectNamesWithCodeArray);
    setSubjectProxyList(SubjectNamesWithCodeArray);
    setIsDataAvailable(true);
  }

  function loadDivisions(divisionArray: any) {
    let division = divisionArray?.map((item: any) => {
      let editObject = {};
      if (isEditModal && editSubjectGroupData?.divisionDetails && editSubjectGroupData?.divisionDetails?.length > 0) {
        editObject = editSubjectGroupData?.divisionDetails?.find(
          (editObject: any) => editObject?.division === item?.division,
        );
      }
      const isEditObject = Boolean(editObject && Object.keys(editObject)?.length > 0);
      return {
        id: item?.division,
        name: item?.division,
        checked: isEditObject,
        orderNo: isEditObject ? editObject?.orderNo : 0,
      };
    });
    if (isEditModal && division && division.length > 1) {
      division = division?.sort((a: any, b: any) => compareAndSort(a, b, 'orderNo'));
    }
    setDivision(division);
    setDivisionProxyList(division);
    setIsDivisionDataAvailable(true);
  }

  function getPresentSubjects(subjects: any) {
    let subjectIds = [] as any;
    subjects.forEach(function (subject: any) {
      subjectIds.push(subject.id);
    });
    return subjectIds;
  }

  async function searchSelectScroll(queryVariable: any, type: string) {
    let param = {
      limit: autoCompleteLimit,
      skip: queryVariable * autoCompleteLimit,
    } as any;

    if (type === AUTOCOMPLETE_MODULE.COURSE) {
      let courseResponse = getFilteredCourses(param);
      return courseResponse;
    }

    if (type === AUTOCOMPLETE_MODULE.CLASS) {
      let filters = {} as any;
      let course = getValues(AUTOCOMPLETE_MODULE.COURSE);
      filters['course'] = { eq: course?.value };
      param['filters'] = filters;
      let classResponse = await getFilteredClasses(param);
      return classResponse;
    }
  }

  async function searchSelectOnchange(queryVariable: any, type: string) {
    let filters = {
      alias: {
        regex: queryVariable,
      },
    } as any;

    if (type === AUTOCOMPLETE_MODULE.COURSE) {
      let courseRespose = await getFilteredCourses({ filters: filters });
      return courseRespose;
    }

    if (type === AUTOCOMPLETE_MODULE.CLASS) {
      let course = getValues(AUTOCOMPLETE_MODULE.COURSE);
      filters['course'] = { eq: course?.value };
      let classRespose = await getFilteredClasses({ filters: filters });
      return classRespose;
    }
  }

  async function searchSubjectOnchange(queryVariable: any) {
    setSearchData(queryVariable);
    let classData = isEditModal ? editSubjectGroupData?.class?.id : batchData?.class?.id;
    let subjectLength = subjectProxyList.length;
    if (queryVariable.length > 0 && classData && subjectLength > 0) {
      let param = {
        limit: 0,
        skip: 0,
        filters: { class: { eq: classData }, name: { regex: queryVariable } },
      };

      let subjectsResponse = await getFilteredSubjects(param);
      setSubjectList(getSubjectNamesWithCodeArray(subjectsResponse?.options));
    } else {
      setSubjectList(subjectProxyList);
    }
  }

  async function searchDivisionOnchange(queryVariable: any) {
    setDivisionSearchData(queryVariable);
    if (queryVariable.length > 0 && divisionProxyList.length > 0) {
      let divisionResponse = division
        ?.filter((item: any) => item?.name?.includes(queryVariable))
        .map((res: any) => ({ id: res?.name, name: res?.name, checked: res?.checked }));
      setDivision(divisionResponse);
    } else {
      setDivision(divisionProxyList);
    }
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      loadSubjects([]);
      loadDivisions([]);
      setIsDataAvailable(false);
      setIsDivisionDataAvailable(false);
      if (data) {
        const { data: batchData } = await graphqlQuery(
          getBatchByIdQuery,
          { id: data.value },
          false
        )
        setBatchData(batchData?.batch);
        setValue(AUTOCOMPLETE_MODULE.COURSE, batchData?.batch?.class?.course?.alias);

        let filters = {
          class: {
            eq: batchData?.batch?.class?.id,
          },
        };
        let subjectsResponse = await getFilteredSubjects({ filters: filters, limit: 0 });
        loadSubjects(subjectsResponse?.options);
        loadDivisions(batchData?.batch?.divisionDetails);
      } else {
        setBatchData({});
      }
    }
  }

  const handleChecked = (item) => {
    if (checkedArray.includes(item)) {
      const index = checkedArray.indexOf(item);
      if (index > -1) {
        checkedArray.splice(index, 1);
      }
    } else {
      checkedArray.push(item);
    }
    setCheckArray(checkedArray);
    setSubjectIds(checkedArray);
  };

  const handleCheckedDivision = (item: string) => {
    const checkboxArray = [...division];
    const index = checkboxArray.findIndex((check: any) => check?.name === item);
    checkboxArray[index]['checked'] = !checkboxArray[index]['checked'];
    setDivision(checkboxArray);
    const checkedDiv = checkboxArray.filter((item: any) => item?.checked).map((res: any) => res?.name);
    setDivisionCheckedArray(checkedDiv);
  }

  return (
    <View>
      <View style={[styles.container]}>
      <Grid container>
        {fields ? (
          fields.map((field: any, i: number) => (
            batchDependentFieldIdx.includes(i) && (!batchData || Object.keys(batchData).length === 0) && !isEditModal ? <></> :
            (
              <CustomGrid item md={i === 8 || i === 11 ? 12 : 6} key={i}>
              <Element
                  key={`subGoup${i}`}
                  field={field}
                  control={props.control}
                  errors={props.errors}
                  checked={i === 8 ? checkedArray : divisionCheckedArray}
                  handleCheck={i === 8 ? handleChecked : handleCheckedDivision}
                  checkboxTableData={i === 8 ? subjectList : division}
                  checkboxTableMaxHeight={350}
                  searchBoxOnChange={i === 6 ? searchSubjectOnchange : searchDivisionOnchange}
                  handleCustomSelect={handleCustomSelect}
                  searchData={i === 6 ? searchData : divisionSearchData}
                  isCustomDisable={field.isCustomDisable && isEditModal}
                  isDataAvailable={i === 8 ? isDataAvailable : isDivisionDataAvailable}
                  checkboxTableHeight={'auto'}
                  setCheckBoxTableData={setDivision}
                />
              </CustomGrid>
            )
          ))
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
        </Grid>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'space-between',
    width: '100%',
  },

  spinnerView: { width: 750, height: 547, justifyContent: 'center', alignItems: 'center' },
});

const CustomGrid = styled(Grid)`
  &:nth-child(even) {
    padding-left: 16px;
  }
`;
