import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import inviteInstituteAdmin from '../../../form-json/control-panels/invite-institute-admin.json';
import inviteEmployee from '../../../form-json/control-panels/invite-employee.json';
import inviteEdbaAdminJSON from '../../../form-json/control-panels/invite-edba-admin.json';
import Element from '../Forms/ApplicationElement';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

export default function InviteUserForm(props: any) {
  const { userType, onlyAdmin } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    loadJsonForm();
  }, []);

  function loadJsonForm() {
    if (userType == 2) {
      if (onlyAdmin) {
        setElements(inviteInstituteAdmin[0]);
      } else {
        setElements(inviteEmployee[0]);
      }
    } else {
      setElements(inviteEdbaAdminJSON[0]);
    }
  }

  return (
    <View>
      <View>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View>
              <Element key={i} field={field} control={props.control} errors={props.errors} />
            </View>
          ))
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
});
