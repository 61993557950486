import { AUTOCOMPLETE_MODULE, INSTITUTEGROUP, INSTITUTE_ALL_OPTIONS, INSTITUTE_PREFIX, MANUAL, NO_GROUP_OPTIONS, instituteOptions } from 'src/constant';
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors, fontWeight } from 'src/styles/theme/styles';
import { debounce, getFilteredInstitute } from 'src/components/services';

import ERROR_MSG from 'src/constant/error';
import Element from '../Forms/ApplicationElement.web';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import InstituteCollegeSchool from '../../../form-json/institutes/Institute-college-school.json';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { getInstituteGroupQuery } from 'src/graphql/institutes';
import institueGroupJSON from '../../../form-json/institutes/institutes-groups.json';
import { useI18n } from 'src/i18n/hooks';
import { useQuery } from '@apollo/client';

interface AddEditInstituteProps {
  control: any;
  errors: any;
  reset: any;
  isEditModal: boolean;
  setIsTypeGroup: Function;
  setValue: any;
  clearErrors: any;
  setError: any;
  trigger: Function;
  editInstituteData: Object;
  addForGroup: boolean;
  groupData: Object;
  isSubmitting: any;
}

function AddEditInstituteForm(props: AddEditInstituteProps) {
  const {
    control,
    errors,
    reset,
    isEditModal,
    setIsTypeGroup,
    setValue,
    addForGroup,
    groupData,
    editInstituteData,
    setError,
    clearErrors,
    isSubmitting,
  } = props;

  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};
  const { t } = useI18n();

  let groupFilter = { type: { eq: 'Group' } };
  let allInstituteGroups = {} as any;
  if (editInstituteData?.type !== INSTITUTEGROUP || !isEditModal) {
    allInstituteGroups = useQuery(getInstituteGroupQuery, {
      variables: { limit: 0, skip: 0, filters: groupFilter },
    });
  }

  useEffect(() => {
    if (editInstituteData?.type !== INSTITUTEGROUP || !isEditModal) {
      if (allInstituteGroups.loading == false) {
        InstituteCollegeSchool[0].fields[1].option = allInstituteGroups?.data?.institutes?.data;
        setElements(InstituteCollegeSchool[0]);
      }
    }
  }, [allInstituteGroups.data]);

  useEffect(() => {
    if (addForGroup && groupData.id) {
      InstituteCollegeSchool[0].fields[0].option = NO_GROUP_OPTIONS;
      InstituteCollegeSchool[0].fields[1].disabled = true;
      resetAddForGroup();
    } else {
      InstituteCollegeSchool[0].fields[0].option = INSTITUTE_ALL_OPTIONS;
      InstituteCollegeSchool[0].fields[1].disabled = false;
    }
  }, [addForGroup]);

  useEffect(() => {
    if (isEditModal) {
      if (editInstituteData?.type === INSTITUTEGROUP) {
        institueGroupJSON[0].fields[0].disabled = true;
        institueGroupJSON[0].fields[10].disabled = true;
        institueGroupJSON[0].fields[11].disabled = true;
        setElements(institueGroupJSON[0]);
      } else {
        InstituteCollegeSchool[0].fields[0].disabled = true;
        InstituteCollegeSchool[0].fields[10].disabled = true;
        InstituteCollegeSchool[0].fields[11].disabled = true;
      }
      LoadEditForm(editInstituteData);
    }

    return () => {
      institueGroupJSON[0].fields[0].disabled = false;
      institueGroupJSON[0].fields[8].disabled = false;
      institueGroupJSON[0].fields[9].disabled = false;
      InstituteCollegeSchool[0].fields[0].disabled = false;
      InstituteCollegeSchool[0].fields[10].disabled = false;
      InstituteCollegeSchool[0].fields[11].disabled = false;
    }
  }, []);

  function LoadEditForm(instituteData: any) {
    let instituteType = instituteData.type;
    let type = instituteType === INSTITUTEGROUP ? INSTITUTE_PREFIX.GROUP : '';
    let formaData = {} as any;

    //Set
    formaData['type'] = instituteOptions[instituteType];
    formaData[`${type}name`] = instituteData.name;
    formaData[`${type}description`] = instituteData?.description;
    formaData[`${type}firstName`] = instituteData?.contactDetails?.firstName;
    formaData[`${type}lastName`] = instituteData?.contactDetails?.lastName;
    formaData[`${type}email`] = instituteData?.email;
    formaData[`${type}mobileNumber`] = instituteData?.mobileNumber;
    formaData[`${type}domain`] = instituteData.domain;
    formaData[`${type}website`] = instituteData.website;
    formaData[`${type}location`] = instituteData?.addressDetails?.city;
    formaData[`${type}university`] = instituteData.university;
    formaData[`${type}udiseId`] = instituteData?.udise;
    formaData[`${type}role`] = instituteData?.role;
    if (groupData.value) {
      formaData[`${type}parent`] = groupData;
    }

    reset(formaData);
  }

  function resetAddForGroup() {
    reset({ parent: { label: groupData.name, value: groupData.id } });
  }

  function resetGroupForm() {
    reset({
      firstName: '',
      lastName: '',
      website: '',
      name: '',
      description: '',
      email: '',
      mobileNumber: '',
      location: '',
      role: '',
      domain: '',
      udiseId: '',
    });
  }

  function resetSchoolCollegeForm() {
    reset({
      ig_firstName: '',
      ig_lastName: '',
      ig_website: '',
      ig_name: '',
      ig_description: '',
      ig_email: '',
      ig_mobileNumber: '',
      ig_location: '',
      ig_role: '',
      ig_domain: '',
      ig_udiseId: '',
    });
  }

  function setFormToGroup(data: any) {
    resetGroupForm();
    setElements(institueGroupJSON[0]);
    setIsTypeGroup(true)
    setValue(AUTOCOMPLETE_MODULE.INSTITUTE_TYPE, data)
  }

  function setFormToSchoolCollege(data: any) {
    resetSchoolCollegeForm();
    reset({ parent: null });
    setElements(InstituteCollegeSchool[0]);
    setIsTypeGroup(false);
    if (addForGroup && groupData.id) {
      resetAddForGroup();
    }
    setValue(AUTOCOMPLETE_MODULE.INSTITUTE_TYPE, data);
  }

  function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.INSTITUTE_TYPE) {
      if (data.value === INSTITUTEGROUP) {
        setFormToGroup(data)
      } else {
        setFormToSchoolCollege(data)
      }
    }
  }

  async function checkDomain(domain: any) {
    if (isSubmitting?.current) return;
    let domainFilter = {
      domain: { eq: domain },
    } as any;

    if (domain.length > 0) {
      let param = {
        limit: 0,
        skip: 0,
        filters: domainFilter,
      };

      let instituteResponse = await getFilteredInstitute(param);
      if (instituteResponse?.options) {
        let data = instituteResponse?.options;
        if (data && data.length > 0) {
          if (isEditModal) {
            let instID = data[0]?.value;
            if (instID !== editInstituteData?.id) {
              setError('ig_domain', { type: MANUAL, message: ERROR_MSG.DOMAIN_ALREADY_EXIST });
              setError('domain', { type: MANUAL, message: ERROR_MSG.DOMAIN_ALREADY_EXIST });
            }
          } else {
            setError('ig_domain', { type: MANUAL, message: ERROR_MSG.DOMAIN_ALREADY_EXIST });
            setError('domain', { type: MANUAL, message: ERROR_MSG.DOMAIN_ALREADY_EXIST });
          }
        } else {
          clearErrors('ig_domain');
          clearErrors('domain');
        }
      } else {
        clearErrors('ig_domain');
        clearErrors('domain');
      }
    }
  }

  const handleCustomOnChange = debounce((domain: any) => checkDomain(domain));

  return (
    <View>
      <View style={[styles.container]}>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View style={{ width: 350 }} key={`ele${i}`}>
              {field?.id !== 'header' ? (
                <Element
                  key={`s${i}`}
                  field={field}
                  control={control}
                  errors={errors}
                  handleCustomSelect={handleCustomSelect}
                  handleCustomOnChange={handleCustomOnChange}
                />
              ) : (
                <View style={[styles.headView]}>
                  <HeaderFive
                    value={t(field?.head)}
                    fontWeight={fontWeight[600]}
                    color={colors.primaryText}
                  />
                </View>
              )}
            </View>
          ))
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </View>
    </View>
  )
}

export default AddEditInstituteForm

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'space-between',
    width: 724,
  },
  headView: { marginBottom: 16 },
  spinnerView: { width: 750, height: 547, justifyContent: 'center', alignItems: 'center' },
});