/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import { BackHandler, StyleSheet, View } from 'react-native';
import { debounce, set } from 'lodash';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useI18n } from 'src/i18n/hooks';
import { useHeaderTitle } from 'src/contexts/header-context';
import NormalModal from 'src/components/atoms/Modals/Normal/index';
import NewsForm from 'src/components/molecules/Communication/NewsForm';
import { useForm } from 'react-hook-form';
import {
  AUDIENCE_TYPE,
  ANNOUNCEMENT_TYPE,
  USER_TYPE,
  ERROR,
  SUCCESS,
  EMPLOYEE_TYPES,
  FILTERS,
  NEWS_LIMIT,
  NOTICE_LIMIT,
  FileCategory,
  NON_TEACHING_EMPLOYEE_TYPE,
} from 'src/constant/index';
import {
  useAddAnnouncementsMutation,
  getAnnouncementsQuery,
  useDeleteAnnoucementMutation,
} from 'src/graphql/communication/news-notices';
import { format } from 'date-fns'
import { useQuery } from '@apollo/client';
import NoticeForm from 'src/components/molecules/Communication/NoticesForm';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { height, isIOS, isNative, isWeb, width } from 'src/constant/device';
import { breakpoints, colors, fonts, mobileWeb } from 'src/styles/theme/styles';
import MobileHeader from 'src/components/hoc/MobileHeader';
import NewsCard from 'src/components/molecules/NewsCard';
import NoticeCard from 'src/components/molecules/NoticeCard';
import SwipeSwitch from 'src/components/atoms/Switch/Swipe';
import NewsModalContent from './News/ModalContent';
import ERROR_MSG from 'src/constant/error';
import AddButton from 'src/components/atoms/AddButton';
import { faCommentAltDots, faNewspaper } from '@fortawesome/pro-light-svg-icons';
import { NoNewsNotices } from 'src/components/molecules/NoNewsNotices';
import { ADD_SUCCESS, DELETE_SUCCESS } from 'src/constant/message';
import Listing from 'src/components/molecules/CommunicationListing';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import Search from 'src/components/atoms/SearchBar/index';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  useHistory,
} from 'src/routes/routing';
import navigationStrings from 'src/navigation/navigationStrings';
import { createSearchFilter } from 'src/components/services';
import { validation } from 'src/constant/validation';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import DeleteModal from 'src/components/atoms/Modals/Delete/index';
import { createDocumentObject } from 'src/utils/utility';
import AlertModalNative from 'src/components/molecules/AlertModal/AlertModalNative';
import { CustomReactNativeFile } from 'src/components/molecules/FileUpload/type';
import AudienceWrapper from 'src/components/molecules/Audience/AudienceWrapper';
import { showNativeError, showNativeSuccess } from 'src/components/molecules/NotificationWrapper';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { DT } from 'src/constant/dateTime';
import AbstractFlashList from 'src/components/atoms/FlashList';
import { SD } from 'src/constant/standardDimensions';

function NewsNotices(props: any) {
  var totalNews = 0;
  var totalNotices = 0;
  const { t } = useI18n();
  const history = useHistory();
  const { setHeading } = useHeaderTitle();
  const [newsModal, handleNewsModal] = useState(false);
  const [newsOverlay, setOverlay] = useState(false);
  const [noticesModal, handleNoticesModal] = useState(false);
  const { alertState, setAlertDetails } = useAlertSystem();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    setError,
  } = useForm();

  let staticParam = {
    params: {
      communicationType: '',
      newsnoticeId: '',
    },
  };

  const location = isWeb ? useLocation() : '';
  const match = isWeb ? useRouteMatch() : '';
  const { params } = isNative ? props?.route : staticParam;
  const { communicationType, newsnoticeId } = params;
  const [searchText, setSearchText] = useState('');
  const [canClick, setCanClick] = useState<boolean>(true);
  const [fileData, setFileData] = useState<any>({});
  const [annoucementWay, setAnnoucementWay] = useState<boolean>(false);
  const [annoucementType, setAnnoucementType] = useState<string>('');
  const [newsDetail, setNewsDetail] = useState<any>({});
  const [noticeDetail, setNoticeDetail] = useState<any>({});
  const [newsIndex, setNewsIndex] = useState<number>(0);
  const [noticeIndex, setNoticeIndex] = useState<number>(0);
  const { mutate: createAnnoucemnt } = useAddAnnouncementsMutation(annoucementType);
  const [fileObjects, setFileObjects] = useState<any>({});
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const currentUserObject = userInformation();
  const { userType, userDetail } = currentUserObject;
  const isInstituteAdmin = userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN);
  const isNews = location && location.pathname.includes('news');
  const announcementTab = communicationType === 'news' ? 1 : 2;
  const isDesktop = width > breakpoints.md; //desk
  const [communicationTab, setSelectTab] = useState<string>('news');
  const [audienceType, setAudienceType] = useState(null);

  const isEmployee: boolean = userType === USER_TYPE.EMPLOYEE;

  const isNonteachingAccount: boolean =
    Array.isArray(userDetail?.type) &&
    userDetail.type.length > 0 &&
    userDetail.type.every((tp) => tp === NON_TEACHING_EMPLOYEE_TYPE);

  const allowNoticeAddition: boolean = isEmployee && !isNonteachingAccount;

  const searchFilters = [
    {
      title: {
        regex: '',
      },
    },
    {
      body: {
        regex: '',
      },
    },
  ];

  const newsFilter = {
    type: {
      eq: ANNOUNCEMENT_TYPE[0],
    },
    or: searchFilters,
  };

  const noticesFilter = {
    type: {
      eq: ANNOUNCEMENT_TYPE[1],
    },
    or: searchFilters,
  };

  const {
    loading: newsLoading,
    data: news,
    refetch: newsRefetch,
    fetchMore: fetchNewsMore,
  } = useQuery(getAnnouncementsQuery, {
    variables: { limit: NEWS_LIMIT, skip: 0, filters: newsFilter, timezone: DT.TIMEZONE },
  });
  const {
    loading: noticesLoading,
    data: notices,
    refetch: noticesRefetch,
    fetchMore: fetchNoticeMore,
  } = useQuery(getAnnouncementsQuery, {
    variables: {
      limit: NOTICE_LIMIT,
      skip: 0,
      filters: noticesFilter,
      timezone: DT.TIMEZONE,
    },
  });
  totalNews = news?.getAnnouncements?.totalCount;
  totalNotices = notices?.getAnnouncements?.totalCount;

  const newsRef = useRef<any>(null);
  const noticeRef = useRef<any>(null);
  const [refreshing, setRefreshing] = React.useState(false);
  const [toggleAdd, setAdd] = useState<any>(isWeb);
  const [newsSkip, setNewsSkip] = useState<number>(0);
  const [noticeSkip, setNoticeSkip] = useState<number>(0);
  const userInfo = userInformation();
  let instituteId = userInfo?.userDetail?.institute;
  let userId = userInfo?.userId;
  let webIndex = isNews ? newsIndex : noticeIndex;
  let nativeIndex = communicationType === 'news' ? newsIndex : noticeIndex;
  let webId = isNews ? newsDetail?.id : noticeDetail?.id;
  let nativeId = communicationType === 'news' ? newsDetail?.id : noticeDetail?.id;
  let webType = isNews ? ANNOUNCEMENT_TYPE[0] : ANNOUNCEMENT_TYPE[1];
  let nativeType = communicationType === 'news' ? ANNOUNCEMENT_TYPE[0] : ANNOUNCEMENT_TYPE[1];
  const { announcementDeletion } = useDeleteAnnoucementMutation(
    isWeb ? webIndex : nativeIndex,
    searchText,
    isWeb ? webId : nativeId,
    isWeb ? webType : nativeType,
  );

  const backActionHandler = () => {
    if (noticesModal || newsOverlay) {
      closeModal();
    } else {
    }
    return true;
  };

  useEffect(() => {
    setTitle();
  }, [isNews]);

  useEffect(() => {
    handleModal();
  }, [newsnoticeId]);

  useEffect(() => {
    if (
      !isWeb &&
      !noticesLoading &&
      isEmployee &&
      notices?.getAnnouncements?.data.length === 0
    ) {
      setAdd(true);
    }
  }, [noticesLoading]);

  function handleModal() {
    if (newsnoticeId && isNative) {
      setOverlay(true);
    } else {
      setOverlay(false);
    }
  }

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', backActionHandler);
    return () => BackHandler.removeEventListener('hardwareBackPress', backActionHandler);
  }, []);

  function closeDetailModal() {
    setOverlay(false);
    props.navigation.navigate(navigationStrings.NATIVE_TABBAR_NEWS_NOTICES, {
      communicationType: communicationType,
    });
  }

  async function deleteNews(newsData: any, idx: number) {
    setNewsDetail(newsData);
    setNewsIndex(idx);
    setDeleteOverlay(true);
  }

  async function deleteNotices(noticeData: any, idx: number) {
    setNoticeDetail(noticeData);
    setNoticeIndex(idx);
    setDeleteOverlay(true);
  }

  const refetchNewsNotice = async (announcementType: string) => {
    if (announcementType === ANNOUNCEMENT_TYPE[0]) {
      await newsRefetch({
        limit: NEWS_LIMIT,
        skip: 0,
        filters: newsFilter,
        timezone: DT.TIMEZONE,
      });
    } else {
      await noticesRefetch({
        limit: NOTICE_LIMIT,
        skip: 0,
        filters: noticesFilter,
        timezone: DT.TIMEZONE,
      });
    }
  };

  const handleDeleteAnnoucement = async (annoucementID = '' as string) => {
    setCanClick(false);
    let type = communicationType === 'news' ? ANNOUNCEMENT_TYPE[0] : ANNOUNCEMENT_TYPE[1];
    let annoucId = annoucementID;
    if (isWeb) {
      annoucId = isNews ? newsDetail?.id : noticeDetail?.id;
      type = isNews ? ANNOUNCEMENT_TYPE[0] : ANNOUNCEMENT_TYPE[1];
    }

    try {
      let deleteRespopnse = await announcementDeletion({ variables: { id: annoucId } });
      if (deleteRespopnse) {
        await refetchNewsNotice(type);
        setDeleteOverlay(false);
        showSuccess(DELETE_SUCCESS[`${type}`])
      }
    } catch (error) {
      showError(error.message);
    }
    setCanClick(true);
  };

  function showSuccess(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: SUCCESS });
    } else {
      showNativeSuccess(message);
    }
  }
  function showError(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: ERROR });
    } else {
      showNativeError(message)
    }
  }

  function onRefresh() {
    setRefreshing(true);
    nativeRefresh();
  }

  async function nativeRefresh() {
    let limit = communicationTab === 'news' ? NEWS_LIMIT : NOTICE_LIMIT;
    let classesFilter = {
      limit: limit,
      skip: 0,
      filters: {
        type: {
          eq: communicationTab === 'news' ? ANNOUNCEMENT_TYPE[0] : ANNOUNCEMENT_TYPE[1],
        },
        or: searchFilters,
      },
      timezone: DT.TIMEZONE,
    };

    if (communicationTab === 'news') {
      newsRefetch(classesFilter);
    } else {
      noticesRefetch(classesFilter);
    }
    setAdd(true);
    setRefreshing(false);
  }

  function headingSetter(heading: string) {
    setHeading([
      {
        text: t(heading),
        url: '',
      },
    ]);
  }

  function setTitle() {
    if (isWeb) {
      headingSetter(isNews ? 'news.label' : 'notices.label');
    }
  }

  const closeModal = () => {
    handleNewsModal(false);
    resetAllField();
    resetForm();
  };

  function resetForm() {
    setValue('title', '');
    setValue('body', '');
    setValue('contentImage', {});
    setValue('attachments', null);

    clearErrors();
  }

  function resetAllField() {
    resetForm();
    handleNoticesModal(false);
    setFileObjects({});
    setFileData({});
    setAnnoucementWay(false);
  }

  const handleFilesAttach = (id: any) => {
    return (files: CustomReactNativeFile[]) => {
      if (files?.length > 0) {
        let file: any = files[0];
        setValue(id, file);
        maintainFileObject(file, id);
        clearErrors('contentImage');
      }
    };
  };

  function maintainFileObject(data: object, id: string) {
    let fileobj = { ...fileObjects };
    fileobj[id] = data;
    setFileObjects(fileobj);
  }

  const handleFileRemove = (id: string) => {
    return (file: CustomReactNativeFile) => {
      setValue(id, {});
      maintainFileObject({}, id);
    };
  };

  async function handleCreateAnnouncement(formData: any, audience: any) {
    setCanClick(false);
    if (isEmployee) {
      if (formData?.contentImage) {
        formData['contentImage'] = await createDocumentObject(
          formData?.contentImage,
          FileCategory.News,
        );
      } else {
        setError('contentImage', { message: t(validation.NEWS_IMAGE_REQUIRED) });
        setCanClick(true);
        return;
      }

      if (formData?.attachments) {
        formData['attachments'] = await createDocumentObject(
          formData?.attachments,
          FileCategory.News,
        );
      } else {
        formData['attachments'] = [];
      }

      if (userType === USER_TYPE.EDBA_ADMIN) {
        formData['audienceType'] = AUDIENCE_TYPE.ALL;
        formData['audience'] = [];
      }

      if (isEmployee) {
        formData['audienceType'] = audienceType;
        formData['audience'] = audience;
      }

      if (canClick) {
        try {
          addAnnoucemnt(formData, ANNOUNCEMENT_TYPE[0]);
        } catch (error) {
          console.log("error in handleCreateAnnouncement ", error);
          setCanClick(true)
        }
      }
    } else {
      console.log(ERROR_MSG.NO_PERMISSON);
    }
  }

  async function addAnnoucemnt(payload: any, type: string) {
    setCanClick(false);
    let successMsz = '';
    if (type === ANNOUNCEMENT_TYPE[0]) {
      successMsz = ADD_SUCCESS.NEWS;
    } else {
      successMsz = ADD_SUCCESS.NOTICE;
    }

    try {
      payload['type'] = type;
      payload['date'] = format(new Date(), DT.DATE_FORMAT_SLASH);
      const annoucementResponse = await createAnnoucemnt({
        variables: { payload: payload },
        refetchQueries: [
          {
            query: getAnnouncementsQuery,
            variables: {
              limit: NEWS_LIMIT,
              skip: 0,
              filters: {
                type: {
                  eq: type,
                },
                or: searchFilters,
              },
            },
          },
        ],
      });

      if (annoucementResponse.data) {
        const data = annoucementResponse.data;
        if (data.createAnnouncement) {
          showSuccess(successMsz);
          closeModal();
          setCanClick(true);
          await refetchNewsNotice(type);
        }
      }
    } catch (error: any) {
      setCanClick(true);
      showError(error.message);
    }
  }

  async function handleCreateNotice(formData: any, audience: any) {
    delete formData.undefined;
    if (formData?.contentImage) {
      formData['contentImage'] = await createDocumentObject(
        formData?.contentImage,
        FileCategory.Notice,
      );
    }

    if (formData?.attachments) {
      formData['attachments'] = await createDocumentObject(
        formData?.attachments,
        FileCategory.Notice,
      );
    }

    formData['audienceType'] = audienceType;
    formData['audience'] = audience;

    if (canClick) {
      addAnnoucemnt(formData, ANNOUNCEMENT_TYPE[1]);
      setCanClick(false);
    }
  }

  function showNewsDetails(newsData: any) {
    if (!isWeb) {
      props.navigation.navigate(navigationStrings.NATIVE_TABBAR_NEWS_NOTICES, {
        communicationType: 'news',
        newsnoticeId: newsData.id,
      });
    } else {
      history.push(`/communication/news/${newsData.id}/detail`);
    }
  }

  function showNotices(noticesData: any) {
    if (!isWeb) {
      props.navigation.navigate(navigationStrings.NATIVE_TABBAR_NEWS_NOTICES, {
        communicationType: 'notice',
        newsnoticeId: noticesData.id,
      });
    } else {
      history.push(`/communication/notices/${noticesData.id}/detail`);
    }
  }

  function openNoticeModal() {
    hideAlert();
    handleNoticesModal(true);
    setAnnoucementType(ANNOUNCEMENT_TYPE[1]);
  }

  function openNewsModal() {
    hideAlert();
    handleNewsModal(true);
    setAnnoucementType(ANNOUNCEMENT_TYPE[0]);
  }

  function handleSwitching(t: number) {
    let passParam = t === 1 ? 'news' : 'notice';
    setSelectTab(passParam);
    setAdd(false);
    props.navigation.navigate(navigationStrings.NATIVE_TABBAR_NEWS_NOTICES, {
      communicationType: passParam,
    });
  }

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  function changeTabs(tab: any) {
    let limit = tab === 1 ? NEWS_LIMIT : NOTICE_LIMIT;
    let classesFilter = {
      limit: limit,
      skip: 0,
      filters: {
        type: {
          eq: tab === 1 ? ANNOUNCEMENT_TYPE[0] : ANNOUNCEMENT_TYPE[1],
        },
        or: searchFilters,
      },
      timezone: DT.TIMEZONE,
    };
    setSearchText('');

    if (tab === 1) {
      newsRefetch(classesFilter);
      if (noticeRef.current) {
        noticeRef?.current.scrollToIndex({ animated: true, index: 0 });
      }
      history.push(`${match.url}/news`);
    } else {
      noticesRefetch(classesFilter);
      if (newsRef.current) {
        newsRef?.current.scrollToIndex({ animated: true, index: 0 });
      }
      history.push(`${match.url}/notices`);
    }
  }

  async function handleSearch(searchText: string, type: number) {
    let limitType = type === 1 ? NEWS_LIMIT : NOTICE_LIMIT;
    let skipType = type === 1 ? newsSkip : noticeSkip;

    let filters = [
      {
        field: 'title',
        type: FILTERS.REGEX,
        value: searchText,
      },
      {
        field: 'body',
        type: FILTERS.REGEX,
        value: searchText,
      },
    ];

    let classesFilter = createSearchFilter(limitType, skipType, filters);

    set(classesFilter, ['filters', 'type'], {
      eq: type === 1 ? ANNOUNCEMENT_TYPE[0] : ANNOUNCEMENT_TYPE[1],
    });

    classesFilter.timezone = DT.TIMEZONE;
    try {
      setRefreshing(true);
      if (type === 1) {
        setNewsSkip(0);
        await newsRefetch(classesFilter);
      } else {
        setNoticeSkip(0);
        await noticesRefetch(classesFilter);
      }
    } catch (error) {
      console.log('Error while refetching data');
    }
  }

  const debouncedSearch = useCallback(debounce(handleSearch, 500), []);

  function handleNewsPagination() {
    if (totalNews > news?.getAnnouncements?.data.length) {
      setFetchMoreLoading(true);
      handlePagination(getAnnouncementsQuery, NEWS_LIMIT, newsSkip + NEWS_LIMIT, fetchNewsMore, 1);
      setNewsSkip(newsSkip + NEWS_LIMIT);
    }
  }

  function handleNoticePagination() {
    if (totalNotices > notices?.getAnnouncements?.data.length) {
      setFetchMoreLoading(true);
      handlePagination(
        getAnnouncementsQuery,
        NOTICE_LIMIT,
        noticeSkip + NOTICE_LIMIT,
        fetchNoticeMore,
        2,
      );
      setNoticeSkip(noticeSkip + NOTICE_LIMIT);
    }
  }

  function handlePagination(
    paginateQuery: any,
    annoucementLimit: number,
    annoucementSkip: number,
    fetchFunction: any,
    type: number,
  ) {
    let filters = [
      {
        field: 'title',
        type: FILTERS.REGEX,
        value: searchText,
      },
      {
        field: 'body',
        type: FILTERS.REGEX,
        value: searchText,
      },
    ];

    let annoucemFilter = createSearchFilter(annoucementLimit, annoucementSkip, filters);
    set(annoucemFilter, ['filters', 'type'], {
      eq: type === 1 ? ANNOUNCEMENT_TYPE[0] : ANNOUNCEMENT_TYPE[1],
    });

    try {
      fetchFunction({
        query: paginateQuery,
        variables: annoucemFilter,
        updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
          setFetchMoreLoading(fetchMoreResult.getAnnouncements.data ? false : true);
          return {
            getAnnouncements: {
              hasNextPage: fetchMoreResult.getAnnouncements.hasNextPage,
              data: [
                ...previousResult.getAnnouncements.data,
                ...fetchMoreResult.getAnnouncements.data,
              ],
              totalCount: fetchMoreResult.getAnnouncements.totalCount,
              __typename: fetchMoreResult.getAnnouncements.__typename,
            },
          };
        },
      });
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const noticesAudiences = [
    AUDIENCE_TYPE.BATCH,
    AUDIENCE_TYPE.GROUP,
    AUDIENCE_TYPE.STUDENTS,
  ]

  if (isInstituteAdmin) {
    noticesAudiences.push(AUDIENCE_TYPE.INSTITUTE)
  }

  return (
    <Fragment>
      {isWeb && width > breakpoints.md ? (
        <Fragment>
          {!newsLoading && !noticesLoading ? (
            <LoadContentWrapper>
              {/* Desktop web */}
              <ModalTabs
                onSelectSwitch={t => {
                  changeTabs(t);
                }}
                option1={t('news.label')}
                option2={t('notices.label')}
                selectionMode={isNews ? 1 : 2}
                roundCorner={false}
                style={[styles.tabStyle]}
                onChange={changeTabs}
                tabswidth={100}
              />
              <Switch>
                <Route path={`${match.url}/news`}>
                  <>
                    {/* SEARCH BAR */}
                    {news?.getAnnouncements?.data?.length > 0 ||
                      (news?.getAnnouncements?.data?.length < 1 && searchText !== '') ||
                      (news?.getAnnouncements?.data?.length < 1 && refreshing) ? (
                      <View style={styles.flexRow}>
                        <View style={styles.leftPart}>
                          <Search
                            id="Search"
                            handleChange={async e => {
                              // setSearchOn(true);
                              setSearchText(e);
                              await debouncedSearch(e, 1);
                            }}
                            value={searchText}
                            label={t('searchNews.text')}
                          />
                        </View>
                        <View style={styles.rightPart}>
                          {isInstituteAdmin && (
                            <SecondaryBtn
                              label={t(isNews ? 'addNews.text' : 'addNotice.text')}
                              onPress={isNews ? openNewsModal : openNoticeModal}
                              secondary={false}
                            />
                          )}
                        </View>
                      </View>
                    ) : null}

                    {/* NO SEARCH DATA */}
                    {news?.getAnnouncements?.data?.length < 1 && searchText !== '' && (
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}>
                        <NormaltextAtom value={t('noResultFound.text')} />
                      </View>
                    )}

                    {news?.getAnnouncements?.data?.length > 0 && (
                      <>
                        <AbstractFlashList
                          data={news?.getAnnouncements?.data}
                          onEndReachedThreshold={0.5}
                          onEndReached={handleNewsPagination}
                          ref={newsRef}
                          estimatedItemSize={120}>
                          {({ item, index }) => (
                            <NewsCard
                              data={item}
                              onPress={showNewsDetails}
                              canDelete={isInstituteAdmin}
                              deleteAction={deleteNews}
                              indexKey={index}
                            />
                          )}
                        </AbstractFlashList>
                        {fetchMoreLoading ? (
                          <View style={styles.bottomSpinner}>
                            <LoaderSpinner />
                          </View>
                        ) : null}
                      </>
                    )}
                  </>

                  {/* NO WHOLE DATA */}
                  {searchText === '' && !refreshing && news?.getAnnouncements?.data?.length < 1 && (
                    <>
                      {
                        <View style={styles.noDataContainer}>
                          <NoNewsNotices icon={faNewspaper} label={t('noNews.text')} />
                          {isEmployee && (
                            <View style={{ position: 'relative' }}>
                              {isNews && isInstituteAdmin && (
                                <AddButton
                                  style={{ width: 113 }}
                                  label={t('addNew.text')}
                                  onPress={openNewsModal}
                                />
                              )}
                            </View>
                          )}
                        </View>
                      }
                    </>
                  )}
                </Route>

                <Route path={`${match.url}/notices`}>
                  <>
                    {/* SEARCH BAR */}
                    {notices?.getAnnouncements?.data?.length > 0 ||
                      (notices?.getAnnouncements?.data?.length < 1 && searchText !== '') ||
                      (notices?.getAnnouncements?.data?.length < 1 && refreshing) ? (
                      <View style={styles.flexRow}>
                        <View style={styles.leftPart}>
                          <Search
                            id="Search"
                            handleChange={async e => {
                              setSearchText(e);
                              await debouncedSearch(e, 2);
                            }}
                            value={searchText}
                            label={t('searchNotice.text')}
                          />
                        </View>
                        <View style={styles.rightPart}>
                          {allowNoticeAddition && (
                            <SecondaryBtn
                              label={t('addNotice.text')}
                              onPress={openNoticeModal}
                              secondary={false}
                            />
                          )}
                        </View>
                      </View>
                    ) : null}

                    {/* NO SEARCH DATA */}
                    {notices?.getAnnouncements?.data?.length < 1 && searchText !== '' && (
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}>
                        <NormaltextAtom value={t('noResultFound.text')} />
                      </View>
                    )}

                    {/* LIST*/}
                    {notices?.getAnnouncements?.data?.length > 0 && (
                      <>
                        <AbstractFlashList
                          data={notices?.getAnnouncements?.data}
                          onEndReachedThreshold={0.5}
                          onEndReached={handleNoticePagination}
                          ref={noticeRef}
                          estimatedItemSize={104}>
                          {({ item, index }) => (
                            <NoticeCard
                              indexKey={index}
                              isSwipable={true}
                              data={item}
                              onPress={showNotices}
                              canDelete={isEmployee}
                              deleteAction={deleteNotices}
                              createdBy={userId}
                            />
                          )}
                        </AbstractFlashList>
                        {fetchMoreLoading ? (
                          <View style={styles.bottomSpinner}>
                            <LoaderSpinner />
                          </View>
                        ) : null}
                      </>
                    )}
                  </>

                  {/* NO WHOLE DATA */}

                  {searchText === '' &&
                    !refreshing &&
                    notices?.getAnnouncements?.data?.length < 1 && (
                      <View style={styles.noDataContainer}>
                        <NoNewsNotices icon={faCommentAltDots} label={t('noNotice.text')} />
                        {allowNoticeAddition && (
                          <View style={{ position: 'relative' }}>
                            <AddButton
                              style={{ width: 113 }}
                              label={t('addNew.text')}
                              onPress={openNoticeModal}
                            />
                          </View>
                        )}
                      </View>
                    )}
                </Route>
                <Route exact path={`${match.url}`}>
                  <Redirect to={`${match.url}/news`} />
                </Route>
              </Switch>
            </LoadContentWrapper>
          ) : (
            <View
              style={[
                styles.spineerView,
                { backgroundColor: isDesktop ? colors.bluishGreyBackground : colors.white },
              ]}>
              <LoaderSpinner />
            </View>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <MobileHeader label={t('communication.label')} backIcon={false} {...props} />

          {!newsLoading && !noticesLoading ? (
            <View style={[styles.webWrapper]}>
              <View style={[styles.switchView]}>
                <SwipeSwitch
                  onSelectSwitch={isWeb ? t => changeTabs(t) : t => handleSwitching(t)}
                  option1={t('news.label')}
                  option2={t('notices.label')}
                  selectionMode={isWeb ? (isNews ? 1 : 2) : announcementTab}
                  onChange={isWeb ? t => changeTabs(t) : t => handleSwitching(t)}
                />
              </View>

              {isNative && (
                <View
                  testID={'CommunicationView'}
                  style={{
                    marginBottom:
                      toggleAdd && communicationType === 'notice' && isEmployee
                        ? isIOS
                          ? height > 900
                            ? '49%'
                            : '42%'
                          : '67%'
                        : isIOS
                          ? height > 900
                            ? '22%'
                            : '10%'
                          : height > 810
                            ? '30%'
                            : '36%',
                  }}>
                  {communicationType === 'notice' && allowNoticeAddition && toggleAdd && (
                    <View style={{ marginTop: 24, paddingHorizontal: 24 }}>
                      <AddButton
                        testID={'addNoticeBtn'}
                        onPress={openNoticeModal}
                        label={t('addNotice.text')}
                        color={colors.primaryText}
                      />
                    </View>
                  )}

                  {communicationType === 'notice' && (
                    <>
                      <Listing
                        estimatedItemSize={124}
                        passRef={noticeRef}
                        onEndReached={handleNoticePagination}
                        onEndReachedThreshold={0.5}
                        Comp={NoticeCard}
                        arrayList={notices?.getAnnouncements?.data}
                        icon={faCommentAltDots}
                        label={t('noNotice.text')}
                        onPress={showNotices}
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        deleteAction={deleteNotices}
                        createdBy={userId}
                        canDelete={isEmployee}
                        isSwipable={true}
                        setShowAdd={setAdd}
                        fetchMoreLoading={fetchMoreLoading}
                        heightOffset={isIOS ? 262 : 200}
                      />

                      <AlertModalNative
                        title={t('deleteNotice.text')}
                        message1={t('deleteNotice.warning')}
                        onCancel={() => setDeleteOverlay(false)}
                        onConfirm={() => handleDeleteAnnoucement(noticeDetail.id)}
                        showModal={deleteOverlay}
                        confirmButtonColor={colors.errorColor}
                        confirmButtonText={t('delete.label')}
                      />
                    </>
                  )}

                  {communicationType === 'news' && isEmployee && isInstituteAdmin && toggleAdd && (
                    <View style={{ marginTop: 24, paddingHorizontal: 24 }}>
                      <AddButton
                        testID={'addNewseBtn'}
                        onPress={openNewsModal}
                        label={t('addNews.text')}
                        color={colors.primaryText}
                      />
                    </View>
                  )}

                  {communicationType === 'news' && (
                    <>
                      <Listing
                        estimatedItemSize={100}
                        passRef={newsRef}
                        onEndReached={handleNewsPagination}
                        onEndReachedThreshold={0.5}
                        Comp={NewsCard}
                        arrayList={news?.getAnnouncements?.data}
                        icon={faNewspaper}
                        label={t('noNews.text')}
                        onPress={showNewsDetails}
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                        deleteAction={deleteNews}
                        createdBy={userId}
                        isSwipable={true}
                        canDelete={isInstituteAdmin}
                        setShowAdd={setAdd}
                        fetchMoreLoading={fetchMoreLoading}
                        heightOffset={isIOS ? 262 : 200}
                      />
                      <AlertModalNative
                        title={t('deleteNews.text')}
                        message1={t('deleteNews.warning')}
                        onCancel={() => setDeleteOverlay(false)}
                        onConfirm={() => handleDeleteAnnoucement(newsDetail.id)}
                        showModal={deleteOverlay}
                        confirmButtonColor={colors.errorColor}
                        confirmButtonText={t('delete.label')}
                      />
                    </>
                  )}

                  {/* </ScrollView> */}
                </View>
              )}
            </View>
          ) : (
            <View style={[styles.spineerView]}>
              <LoaderSpinner />
            </View>
          )}
        </Fragment>
      )}

      {isWeb && (
        <DeleteModal
          isSubmitting={!canClick}
          setModalVisible={() => setDeleteOverlay(false)}
          modalVisible={deleteOverlay}
          handleSave={handleDeleteAnnoucement}
          Headerpopup={isNews ? 'deleteNews.text' : 'deleteNotice.text'}>
          {t(isNews ? 'deleteNews.warning' : 'deleteNotice.warning')}
        </DeleteModal>
      )}

      {/* MODAL START*/}
      <NormalModal
        isSubmitting={!canClick}
        infoModal={true}
        setModalVisible={closeDetailModal}
        modalVisible={newsOverlay}
        Headerpopup={
          communicationType === 'news'
            ? t('news.label')
            : t('notices.label')
        }
        width={SD.primaryInfoModal.width}
        height={SD.primaryInfoModal.height}
        maxWidth={"lg"}
        lineHeight={32}
      >
        <NewsModalContent type={communicationType === 'news' ? 'NEWS' : 'NOTICE'} {...props} />
      </NormalModal>

      <AudienceWrapper
        isSubmitting={!canClick}
        onAlert={alertState}
        setModalVisible={closeModal}
        modalVisible={newsModal}
        handleSave={handleCreateAnnouncement}
        handleSubmit={handleSubmit}
        Headerpopup={t('addNews.text')}
        width={578}
        maxWidth={'md'}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="next.label"
        lineHeight={32}
        audienceType={audienceType}
        setAudienceType={setAudienceType}
        types={[AUDIENCE_TYPE.INSTITUTE]}
        parentType={'NEWS'}
        setError={setError}>
        <NewsForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          reset={reset}
          setError={setError}
          clearErrors={clearErrors}
          fileObjects={fileObjects}
          handleFilesAttach={handleFilesAttach}
          handleFileRemove={handleFileRemove}
          setAnnoucementWay={setAnnoucementWay}
          annoucementWay={annoucementWay}
        />
      </AudienceWrapper>

      <AudienceWrapper
        isSubmitting={!canClick}
        onAlert={alertState}
        setModalVisible={closeModal}
        modalVisible={noticesModal}
        handleSave={handleCreateNotice}
        handleSubmit={handleSubmit}
        audienceType={audienceType}
        setAudienceType={setAudienceType}
        Headerpopup={t('addNotice.text')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={'next.label'}
        width={578}
        lineHeight={32}
        types={noticesAudiences}
        parentType={'NOTICES'}>
        <NoticeForm
          control={control}
          errors={errors}
          fileObjects={fileObjects}
          handleFilesAttach={handleFilesAttach}
          handleFileRemove={handleFileRemove}
          setError={setError}
          clearErrors={clearErrors}
          setAnnoucementWay={setAnnoucementWay}
          annoucementWay={annoucementWay}
        />
      </AudienceWrapper>
    </Fragment>
  );
}

export default NewsNotices;

const styles = StyleSheet.create({
  tabStyle: {
    top: 0,
    left: 0,
    backgroundColor: 'white',
    marginBottom: 24,
  },

  switchView: {
    marginBottom: 12.5,
    paddingHorizontal: 24,
  },
  webWrapper: {
    backgroundColor: colors.white,
    paddingTop: 24,
    marginBottom: 72,
    height: isIOS ? height - 140 : isWeb && mobileWeb ? height - 210 : height - 62,
  },
  flexRow: {
    flexDirection: 'row',
    marginBottom: 8,
  },

  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },

  rightPart: {
    flex: 3,
    alignItems: 'flex-end',
  },
  noDataContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  spineerView: {
    backgroundColor: colors.white,
    height: height - 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomSpinner: {
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  }
});
