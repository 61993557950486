import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import StackNavigator from 'src/navigation/StackNavigator';
import { height } from 'src/constant/device';
import { Image } from 'react-native';
import CustomDrawer from 'src/navigation/CustomDrawer';
import { useSidebarSystem } from 'src/contexts/sidebar-context';

const Drawer = createDrawerNavigator();
function MainDrawer(prop: any) {
  const { togglevalue } = useSidebarSystem();

  return (
    <Drawer.Navigator
      drawerContent={(props) => <CustomDrawer authCallback={prop.authCallback} {...props} />}
      screenOptions={{
        swipeEnabled: togglevalue.toggle === 1 ? true : false,
        gestureEnabled: togglevalue.toggle === 1 ? true : false,
        headerShown: false,
        drawerContentContainerStyle: {
          backgroundColor: '#2B78CA',
          height: height,
        },
        drawerActiveTintColor: 'white',
        drawerActiveBackgroundColor: 'transparent',
      }}>
      <Drawer.Screen
        name="Profile"
        component={StackNavigator}
        options={{
          swipeEnabled: togglevalue.toggle === 1 ? true : false,
          gestureEnabled: togglevalue.toggle === 1 ? true : false,
          drawerIcon: () => {
            return (
              <Image source={require('src/assets/drawerIcons/menu/menu/icon/menu/home.png')} />
            );
          },
        }}
      />
    </Drawer.Navigator>
  );
}

export default MainDrawer;
