import React from 'react';
import { colors, fonts } from '../../../styles/theme/styles';
import styled from 'styled-components/native';

interface props {
  value: any;
  color?: string;
  fontWeight?: any;
  lines?: number;
  lineHeight?: number;
}

export default function ExtraSmallTextAtom(props: props) {
  const {
    value = '',
    color = colors.secondaryText,
    lines,
  } = props;

  const Text = styled.Text`
    color: ${color};
    font-style: normal;
    fontFamily: ${fonts.regular};
    font-size: 10px;
    line-height: 14px;
  `;

  return (
    <Text
      allowFontScaling={false}
      numberOfLines={lines}>
      {value}
    </Text>
  );
}

