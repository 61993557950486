import { DropdownOptions } from 'src/types';

export interface CustomFee {
  amount: string;
  id: string;
  merchant: DropdownOptions;
  name: string;
  payerType: string;
  status: string;
}

export interface CustomFeePayer {
  amount: string;
  id: string;
  code: string;
  codeStatus: string;
  email: string;
  mailSentCount: string;
  mobileNumber: string;
  name: string;
  status: string;
  uniqueId: string;
  payments: string[];
}

export enum LINK_STATUS {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}
