import React, { useEffect, useState } from 'react'
import { View } from 'react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import styled from 'styled-components/native';
import messageFormJSON from '../../../form-json/message/reply-message.json';
import Element from '../Forms/ApplicationElement';

export default function DirectMessageComposer(props: any) {
  const { data, control, errors, setError, clearErrors, setValue } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    if (data) {
      loadBasicData();
      setElements(messageFormJSON[0]);
    }
  }, []);

  function loadBasicData() {
    setValue('to', data?.sender?.userName);
    setValue('subject', `Re: ${data?.subject}`);
  }

  return (
    <React.Fragment>
      {fields ? (
        fields.map((field: any, i: number) => (
          <View key={`form${i}`}>
            <Element
              key={i}
              field={field}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
            />
          </View>
        ))
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </React.Fragment>
  )
}

const SpinnerView = styled.View`
  width: 450px;
  height: 547px;
  justify-content: center;
  align-items: center;
`;
