import React from 'react';
import Svg, { Path } from "react-native-svg";

interface Props {
    size?: number;
    color?: string;
}
export default function SvgComponent(props: Props) {
    return (
        <Svg
            width="80"
            height="70"
            viewBox="0 0 80 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M72.5 15H55V3.75C55 1.67812 53.3219 0 51.25 0H28.75C26.6781 0 25 1.67812 25 3.75V15H7.5C3.35781 15 0 18.3578 0 22.5V62.5C0 66.6422 3.35781 70 7.5 70H72.5C76.6422 70 80 66.6422 80 62.5V22.5C80 18.3578 76.6422 15 72.5 15ZM30 5H50V15H30V5ZM75 62.5C75 63.8781 73.8781 65 72.5 65H7.5C6.12188 65 5 63.8781 5 62.5V40H30V46.25C30 48.3203 31.6797 50 33.75 50H46.25C48.3203 50 50 48.3203 50 46.25V40H75V62.5ZM35 45V40H45V45H35ZM75 35H5V22.5C5 21.1219 6.12188 20 7.5 20H72.5C73.8781 20 75 21.1219 75 22.5V35Z"
                fill="black"
                fill-opacity="0.4"
            />
        </Svg>
    )
}
