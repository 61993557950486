import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props:any) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M10.0086 9.00215V10.002H11.0084V9.00215H10.0086ZM12.0082 6.00273H11.0084V7.00254H12.0082V6.00273ZM10.0086 7.00254V8.00234H11.0084V7.00254H10.0086ZM12.0082 8.00234H11.0084V9.00215H12.0082V8.00234ZM17.5571 7.0619L14.9358 4.44054C14.6546 4.15934 14.2734 4 13.8766 4H7.49971C6.67174 4.00312 6 4.67487 6 5.50283V18.5003C6 19.3283 6.67174 20 7.49971 20H16.498C17.3259 20 17.9977 19.3283 17.9977 18.5003V8.12419C17.9977 7.7274 17.8383 7.3431 17.5571 7.0619ZM13.9984 5.62468L16.3761 8.00234H13.9984V5.62468ZM16.498 18.5003H7.49971V5.50283H9.98985V6.00273H10.9897V5.50283H12.4987V8.7522C12.4987 9.16774 12.833 9.50205 13.2486 9.50205H16.498V18.5003ZM12.0676 12.3046C12.0332 12.1297 11.8801 12.0016 11.6989 12.0016H11.0084V11.0018H10.0086V12.0016L9.39309 15.0353C9.18688 16.0508 9.96173 17.0006 10.999 17.0006C12.0332 17.0006 12.808 16.057 12.6081 15.0447L12.0676 12.3046ZM11.0084 16.1914C10.4491 16.1914 9.9961 15.8133 9.9961 15.3478C9.9961 14.8823 10.4491 14.5042 11.0084 14.5042C11.5677 14.5042 12.0207 14.8823 12.0207 15.3478C12.0207 15.8133 11.5677 16.1914 11.0084 16.1914ZM12.0082 10.002H11.0084V11.0018H12.0082V10.002Z"
        fill="black"
        fillOpacity="0.6"
      />
    </Svg>
  )
}

export default SvgComponent
