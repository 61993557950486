import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getGradesQuery = gql`
  query(
    $filters: GradeFilterInput
    $limit: Int
    $skip: Int
    $sort: GradeSortInput
    $lookup: LookupFilter
  ) {
    grades(
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
      lookup: $lookup
    ) {
      data {
        id
        name
        university {
          id
          name
        }
        grades {
          gradePoint
          max
          min
          name
          remarks
        }
      }
      hasNextPage
      totalCount
    }
  }
`;

export function useGetGrades() {
  const [getGrades, { loading: gradesLoading, data: gradesData, error: gradesError, refetch: refetchGrades }] =
    useLazyQuery(getGradesQuery, { fetchPolicy: 'network-only' });
  return { getGrades, gradesData, gradesError, gradesLoading, refetchGrades };
}

export const createGradeMutation = gql`
  mutation($payload: CreateGradeInput!) {
    createGrade(payload: $payload) {
      id
    }
  }
`;

export function useCreateGrade() {
  const [createGrade, { data: createGradeData, error: createGradeError }] = useMutation(createGradeMutation);
  return { createGrade, createGradeData, createGradeError };
}

export const cloneGradesMutation = gql`
  mutation($from: String!, $to: [String!]!) {
    cloneGrade(from: $from, to: $to) {
      id
      batch {
        id
        name
      }
      grades {
        name
        min
        max
        remarks
        gradePoint
      }
    }
  }
`;

export function useCloneGrades() {
  const [cloneGrades, { data: clonedGradesData, error: clonedGradesError }] = useMutation(cloneGradesMutation);
  return { cloneGrades, clonedGradesData, clonedGradesError };
}

export const getGradeByIdQuery = gql`
  query(
    $id: String!
    $limit: Int
    $skip: Int
    $sort: GradeSortInput
  ) {
    grade(
      id: $id
      limit: $limit
      skip: $skip
      sort: $sort
    ) {
      id
      name
      grades {
        gradePoint
        max
        min
        name
        remarks
      }
    }
  }
`;

export function useGetGradeById() {
  const [getGradeById, { loading: gradeByIdLoading, data: gradeByIdData, error: gradeByIdError, refetch: refetchGradeById }] =
    useLazyQuery(getGradeByIdQuery, { fetchPolicy: 'network-only' });
  return { getGradeById, gradeByIdData, gradeByIdError, gradeByIdLoading, refetchGradeById };
}

export const createGradeDetailsMutation = gql`
  mutation($payload: AddGradeInput!) {
    addGradeDetails(payload: $payload) {
      id
    }
  }
`;

export function useCreateGradeDetails() {
  const [createGradeDetails, { data: createGradeDetailsData, error: createGradeDetailsError }] = useMutation(createGradeDetailsMutation);
  return { createGradeDetails, createGradeDetailsData, createGradeDetailsError };
}