import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getTestDetailsQuery = gql`
  query(
    $filters: ClassTestDetailFilterInput
    $limit: Int
    $skip: Int
    $sort: ClassTestDetailSortInput
    $lookup: LookupFilter
  ) {
    classTestDetails(
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
      lookup: $lookup
    ) {
      data {
        id
        type
        markingType
        considerInEvaluation
        batch {
          id
          name
        }
        name
        subject {
          id
          name
        }
        passingMarks
        totalMarks
        creditPoint
      }
      totalCount
    }
  }
`;

export function useGetTestDetails() {
  const [getTestDetails, { loading: testDetailsLoading, data: testDetailsData, error: testDetailsError, refetch: refetchTestDetails }] =
    useLazyQuery(getTestDetailsQuery, { fetchPolicy: 'network-only' });
  return { getTestDetails, testDetailsData, testDetailsError, testDetailsLoading, refetchTestDetails };
}

export const createTestMutation = gql`
  mutation($payload: ClassTestDetailInput!) {
    createClassTestDetail(payload: $payload) {
      id
      batch {
        id
        name
      }
      name
      subject {
        id
        name
      }
      totalMarks
      passingMarks
    }
  }
`;

export function useCreateTest() {
  const [createTest, { data: createTestData, error: createTestError }] = useMutation(createTestMutation);
  return { createTest, createTestData, createTestError };
}

export const cloneTestMutation = gql`
  mutation($batch: String!, $ctdIds: [String!]!, $subjectIds: [String!]!) {
    cloneClassTestDetail(batch: $batch, ctdIds: $ctdIds, subjectIds: $subjectIds) {
      id
      batch {
        id
        name
      }
      passingMarks
      totalMarks
      subject {
        id
        name
      }
    }
  }
`;

export function useCloneTests() {
  const [cloneTests, { data: clonedTestsData, error: clonedTestsError }] = useMutation(cloneTestMutation);
  return { cloneTests, clonedTestsData, clonedTestsError };
}

export const updateTestDetailsMutation = gql`
  mutation($payload: UpdateTestDetailInput!) {
    updateClassTestDetail(payload: $payload) {
      id
      batch {
        id
        name
      }
      name
      subject {
        id
        name
      }
      totalMarks
      passingMarks
      createdAt
      updatedAt
      date
    }
  }
`;

export function useUpdateTestDetails() {
  const [updateTestDetails, { data: updatedTestDetailsData, error: updatedTestErrors }] = useMutation(updateTestDetailsMutation);
  return { updateTestDetails, updatedTestDetailsData, updatedTestErrors };
}

const deleteTestMutation = gql`
  mutation ($id: String!) {
    deleteTest(id: $id)
  }
`;

export function useDeleteTest() {
  const [deleteTest, { data: deleteTestData, error: deleteTestError }] =
    useMutation(deleteTestMutation);
  return { deleteTest, deleteTestData, deleteTestError };
}