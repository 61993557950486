import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { TableBody } from '@mui/material';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import { useI18n } from 'src/i18n/hooks';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  useHistory,
  useParams,
} from 'src/routes/routing';
import styled from 'styled-components/native';
import Search from 'src/components/atoms/SearchBar/index.web';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useQuery } from '@apollo/client';
import { getStudentSummary, useQueryBatchSummary } from 'src/graphql/payment';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { getBatchByIdQuery } from 'src/graphql/academics/batch';
import { useHeaderTitle } from 'src/contexts/header-context';
import { createSearchFilter, debounce } from 'src/components/services';
import { getStudentFilter } from 'src/components/services/filters';
import { useFilter } from 'src/contexts/filter-context';
import { AUTOCOMPLETE_MODULE, limit  } from 'src/constant';
import { height } from 'src/constant/device';
import { properCase } from 'src/utils/utility';

const headCellsPending = [
  {
    id: 'batch_name',
    label: 'studentName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'division',
    label: 'division.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'subject_group',
    label: 'subjectGroup.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'total_amount',
    label: 'totalAmount.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'concession',
    label: 'concession.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'payable_amount',
    label: 'payableAmount.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'payment_recieved',
    label: 'paymentReceived.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'pending_amount',
    label: 'pendingAmount.label',
    align: 'right',
    disableSorting: true,
  },
];

const headCellsPaid = [
  {
    id: 'batch_name',
    label: 'studentName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'division',
    label: 'division.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'subject_group',
    label: 'subjectGroup.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'total_amount',
    label: 'totalAmount.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'concession',
    label: 'concession.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'expected_amount',
    label: 'payableAmount.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'payment_recieved',
    label: 'paymentReceived.label',
    align: 'right',
    disableSorting: true,
  },
];

const PaymentDetail = () => {
  const { setHeading } = useHeaderTitle();
  const { t } = useI18n();
  const history = useHistory();
  const match = useRouteMatch();
  const isPending = location && location.pathname.includes('pending');
  let searchValue = '' as string;
  const { filters, setFilter } = useFilter();
  const [locationKeys, setLocationKeys] = useState([]);
  const [tab, setTab] = useState<any>(1);

  const maxHeight = height - 375;
  const paginationHeightOffset = 24;

  const { batchId }: any = useParams();
  
  const rowDefaultLimit: number = limit;
  const [page, setPage] = useState<number>(1); // this is same as currentPage in other tables
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  const [searching, setSearching] = useState(searchValue);
  const { data: batchData } = useQuery(getBatchByIdQuery, {
    variables: {
      id: batchId,
    }
  })

  const {
    query: getSummary,
    data: studentSummary,
    loading: studentSummaryLoading,
  } = useQueryBatchSummary(getStudentSummary, 'network-only');
  
  const row = studentSummary?.getStudentSummary?.data;
  const totalCount:number = studentSummary?.getStudentSummary?.totalCount;

  if (filters && filters?.student?.on) {
    searchValue = filters?.student?.search;
  }

  useEffect(() => {
    if (location?.pathname?.includes('pending')) {
      setTab(1);
      const num = location?.pathname?.split("/").pop()
      setPage(num);
    }
    
    if (location?.pathname?.includes('paid')) {
      setTab(2);
      const num = location?.pathname?.split("/").pop()
      setPage(num);
    }
  }, [])

  useEffect(() => {
    setTitle();
  }, [batchData]);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'POP') {
        setPage(parseInt(history.location.pathname.match(/\d+$/)[0], 10));
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    handleFetch();
  }, [page, searchValue, batchId, isPending, locationKeys]);

  useEffect(() => {
    handleChangeTabs();
  }, [tab]);

  useEffect(() => {
    setSearching(searchValue);
  }, [searchValue]);

  function handleFetch() {
    const filters = getStudentFilter(searchValue);
    let studentFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    if (searchValue) {
      studentFilter = {
        filter: {
          ...studentFilter['filters'],
        }, limit, skip: (page - 1) * rowLimit
      }
    }
    studentFilter = { batch: batchId, ...studentFilter, status: isPending ? 'PENDING': 'PAID' };
    getSummary({ variables: studentFilter });
  }

  async function setTitle() {
    setHeading([
      {
        text: t('overview.label'),
        url: '/payments/overview/limit/50/page/1',
      },
      {
        text: batchData?.batch?.academicYear?.alias || '--',
        url: `/payments/overview/${batchData?.batch?.academicYear?.id}/limit/${rowLimit}/page/1`,
      },
      {
        text: batchData?.batch?.name.toUpperCase(),
      }
    ]);
  }

  function changeTabs(tab: any) {
    setPage(1);
    setTab(tab);
  }

  function handleChangeTabs() {
    setFilter(null);
    if (tab === 1) {
      history.push(`${match.url}/pending/limit/${rowLimit}/page/${page}`);
    } else {
      history.push(`${match.url}/paid/limit/${rowLimit}/page/${page}`);
    }
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    isPending ? headCellsPending : headCellsPaid,
    maxHeight,
    totalCount,
    page,
    null,
    null,
    false,
    true,
    null,
    paginationHeightOffset,
  );

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.STUDENT]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchStudent(student: string) {
    let isClear = student?.length === 0;
    persistSearch(!isClear, student);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchStudent(q), 500),
    [],
  );

  const handleSearch = (student: string) => {
    setSearching(student);
    delayedQuery(student);
  };

  const getTableCellValues = (value: string | number) => {
    if ((value) || (typeof value === 'number' && value === 0)) {
      return typeof value === 'number' ? `\u20B9 ${value.toLocaleString()}` : value;
    }
    return 'N/A';
  }

  return (
    <>
      <LoadContentWrapper>
        <ModalTabs
          onSelectSwitch={(t) => {
            changeTabs(t);
          }}
          option1={t('pending.label')}
          option2={t('paid.label')}
          selectionMode={isPending ? 1 : 2}
          roundCorner={false}
          style={[styles.tabStyle]}
          onChange={changeTabs}
          tabswidth={100}
        />
        <Switch>
          <Route path={`${match.url}/pending/limit/${rowLimit}/page/${page}`}>
            <SearchWrapper>
              <Search
                id="studentSearch"
                handleChange={handleSearch}
                value={searching || ''}
                label={'Search'}
              />
            </SearchWrapper>
            <TableWrapper>
              <TblContainer>
                <TblHead dynamicHeadCells={headCellsPending} />
                {!studentSummaryLoading ? (
                  <>
                    {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 && (
                      <TableBody>
                        {recordsAfterPagingAndSorting()?.map((item: any) => (
                          <TableRows key={item.studentId}>
                            <TableCells
                              value={getTableCellValues(item?.fullName ? properCase(item.fullName) : 'N/A')}
                            />
                            <TableCells value={getTableCellValues(item?.division)} />
                            <TableCells value={getTableCellValues(item?.subjectGroup)} />
                            <TableCells
                              value={getTableCellValues(item?.totalFee)}
                              textStyle={{
                                justifyContent: 'flex-end',
                              }}
                            />
                            <TableCells
                              value={getTableCellValues(item?.totalConcession)}
                              textStyle={{
                                justifyContent: 'flex-end',
                              }}
                            />
                            <TableCells
                              value={getTableCellValues(item?.totalPayable)}
                              textStyle={{
                                justifyContent: 'flex-end',
                              }}
                            />
                            <TableCells
                              value={getTableCellValues(item?.totalPaid)}
                              textStyle={{
                                justifyContent: 'flex-end',
                              }}
                            />
                            <TableCells
                              value={getTableCellValues(item?.totalPending)}
                              textStyle={{
                                justifyContent: 'flex-end',
                              }}
                            />
                          </TableRows>
                        ))}
                      </TableBody>
                    )}
                    {recordsAfterPagingAndSorting() &&
                      recordsAfterPagingAndSorting()?.length === 0 &&
                      !studentSummaryLoading && (
                        <NoRecordsFound
                          colspan={8}
                          maxHeight={maxHeight - paginationHeightOffset}
                        />
                      )}
                  </>
                ) : (
                  <SpinnerView>
                    <LoaderSpinner />
                  </SpinnerView>
                )}
              </TblContainer>
              {totalCount && totalCount > 0  ? (
                <Pagination
                  pathName={`payments/overview/batch/${batchId}/pending`}
                  total={totalCount}
                  page={page}
                  rowLimit={rowLimit}
                  setRowLimit={setRowLimit}
                  setCurrentPage={setPage}
                  hidePagination={totalCount <= rowLimit}
                />
              ) : null}
            </TableWrapper>
          </Route>
          <Route path={`${match.url}/paid/limit/${rowLimit}/page/${page}`}>
            <>
              <SearchWrapper>
                <Search
                  id="studentSearch"
                  handleChange={handleSearch}
                  value={searching || ''}
                  label={'Search'}
                />
              </SearchWrapper>
              <TableWrapper>
                <TblContainer>
                  <TblHead dynamicHeadCells={headCellsPaid} />
                  {!studentSummaryLoading ? (
                    <>
                      {recordsAfterPagingAndSorting() &&
                        recordsAfterPagingAndSorting()?.length > 0 && (
                          <TableBody>
                            {recordsAfterPagingAndSorting()?.map((item: any) => (
                              <TableRows key={item.studentId}>
                                <TableCells value={getTableCellValues(item?.fullName ? properCase(item.fullName) : 'N/A')} />
                                <TableCells value={getTableCellValues(item?.division)} />
                                <TableCells value={getTableCellValues(item?.subjectGroup)} />
                                <TableCells
                                  value={getTableCellValues(item?.totalFee)}
                                  textStyle={{
                                    justifyContent: 'flex-end',
                                  }}
                                />
                                <TableCells
                                  value={getTableCellValues(item?.totalConcession)}
                                  textStyle={{
                                    justifyContent: 'flex-end',
                                  }}
                                />
                                <TableCells
                                  value={getTableCellValues(item?.totalPayable)}
                                  textStyle={{
                                    justifyContent: 'flex-end',
                                  }}
                                />
                                <TableCells
                                  value={getTableCellValues(item?.totalPaid)}
                                  textStyle={{
                                    justifyContent: 'flex-end',
                                  }}
                                />
                              </TableRows>
                            ))}
                          </TableBody>
                        )}
                      {recordsAfterPagingAndSorting() &&
                        recordsAfterPagingAndSorting()?.length === 0 &&
                        !studentSummaryLoading && (
                          <NoRecordsFound
                            colspan={7}
                            maxHeight={maxHeight - paginationHeightOffset}
                          />
                        )}
                    </>
                  ) : (
                    <SpinnerView>
                      <LoaderSpinner />
                    </SpinnerView>
                  )}
                </TblContainer>
                {totalCount && totalCount > 0 ? (
                  <Pagination
                    pathName={`payments/overview/batch/${batchId}/paid`}
                    total={totalCount}
                    page={page}
                    rowLimit={rowLimit}
                    setRowLimit={setRowLimit}
                    hidePagination={totalCount <= rowLimit}
                    setCurrentPage={setPage}
                  />
                ) : null}
              </TableWrapper>
            </>
          </Route>
          <Route exact path={`${match.url}`}>
            <Redirect to={`${match.url}/pending/limit/${rowLimit}/page/${page}`} />
          </Route>
        </Switch>
      </LoadContentWrapper>
    </>
  );
};

const styles = StyleSheet.create({
  tabStyle: {
    top: 0,
    left: 0,
    backgroundColor: 'white',
    marginBottom: 24,
  },
});

const SearchWrapper = styled.View`
  width: 350px;
`;

const TableWrapper = styled.View``;

const SpinnerView = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;

export default PaymentDetail;
