import React, { useCallback, useEffect, useState } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import ClassForm from 'src/components/molecules/Academics/ClassForm.web';
import { useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import {
  getClassesQuery,
  useAddClass,
  useUpdateClass,
  useDeleteClass,
  useUpdateInstituteClass,
} from 'src/graphql/academics/classes';
import { AUTOCOMPLETE_MODULE, limit, ERROR, SUCCESS, USER_TYPE } from 'src/constant/index';
import { useI18n } from 'src/i18n/hooks';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, createSortFilter, debounce } from 'src/components/services';
import { getClassFilter } from 'src/components/services/filters';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { userInformation } from 'src/utils/manageState';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { Tooltip } from '@mui/material';
import styled from 'styled-components/native';
import Filter from 'src/components/molecules/Filter/index.web';
import { height, width } from 'src/constant/device';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

const maxHeight = 440;
const headCells1 = [
  {
    id: 'class_name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'alias',
    isSorted: false,
  },
  {
    id: 'course_alias',
    label: 'course.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: 'actions',
    align: 'right',
    disableSorting: true,
  },
];

export default function Classes() {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm();
  const [canClick, setCanClick] = useState(true);
  let searchValue = '' as string;
  let row;
  const { page = 1, dataLimit = limit }: any = useParams();

  const rowDefaultLimit = parseInt(dataLimit) ?? 50;
  const [currentPage, setCurrentPage] = useState(parseInt(page));
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  const currentUserObject = userInformation();
  const { userType } = currentUserObject;
  const { filters, setFilter } = useFilter();
  const [modalState, handleModal] = useState(false);
  const [editClassData, setClassData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [headCells, setHeadCells] = useState(headCells1);
  const [filterHeight, setFilterHeight] = useState(0);
  const [currFilter, setCurrFilter] = useState<any>();
  const [totalCount, setTotalCount] = useState(null);

  if (filters && filters?.class?.on) {
    searchValue = filters?.class?.search;
  }
  const { setAlertDetails } = useAlertSystem();
  const { mutate: updateClasses } = useUpdateClass();
  const { updateInstituteClass } = useUpdateInstituteClass();
  const { setHeading } = useHeaderTitle();
  const [searching, setSearchingClass] = useState(searchValue);
  const [getClasses, { data: classData, refetch: refetchClass, loading: classDataLoading }] =
    useLazyQuery(getClassesQuery, {
      fetchPolicy: 'network-only',
    });

  row = classData?.classes?.data;
  const { mutate: addClasses } = useAddClass(currFilter);
  const { deleteClass } = useDeleteClass(page, searchValue, rowLimit, editClassData?.id);

  const filterItems = [
    { id: 1, label: 'Class Name', type: 'string', localField: 'alias', filterType: 'filter' },
    {
      id: 2,
      label: 'Course Alias',
      type: 'string',
      localField: 'course',
      propName: 'alias', // lookup will need propName
      filterType: 'lookup',
    },
  ];

  useEffect(() => {
    const count = classData?.classes?.totalCount;
    if (count) {
      setTotalCount(count);
    }
  }, [classData?.classes?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setSearchingClass(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setTitle();
  }, []);

  function setTitle() {
    setHeading([
      {
        text: t('classes.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, headCells, rowLimit]);

  const handleFilters = (filter: any) => {
    const classFilter = {
      limit: rowLimit,
      skip: (page - 1) * rowLimit,
      ...filter,
    };
    setCurrFilter(classFilter);
    getClasses({ variables: classFilter });
  };

  function handleFetch() {
    let filters = getClassFilter(searchValue);

    const sortCriteria = createSortFilter(headCells);

    let classFilter = createSearchFilter(
      rowLimit,
      (currentPage - 1) * rowLimit,
      filters,
      sortCriteria,
    );
    setCurrFilter(classFilter);
    getClasses({ variables: classFilter });
  }

  function handleRefetch() {
    let filters = getClassFilter(searchValue);

    const sortCriteria = createSortFilter(headCells);

    let classFilter = createSearchFilter(
      rowLimit,
      (currentPage - 1) * rowLimit,
      filters,
      sortCriteria,
    );
    setCurrFilter(classFilter);
    refetchClass({ variables: classFilter });
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
  );

  const editClassModal = (selectedClass: any) => {
    setClassData(selectedClass);
    hideAlert();
    setIsEditModal(true);
    handleModal(true);
  };

  const deleteClassModal = (selectedClass: any) => {
    setClassData(selectedClass);
    setDeleteOverlay(true);
  };

  const addClassModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  const closeModal = () => {
    handleModal(false);
    resetForm();
  };

  function resetForm() {
    setValue('class_code', '');
    setValue('class_name', '');
    setValue('class_alias', '');
    setValue('course', null);
    setValue('university', null);
    setValue('qualification', null);
    clearErrors();
  }

  const handleAddClass = async (formdata: any) => {
    setCanClick(false);
    let classObject = {
      course: formdata.course?.value,
      code: formdata.class_code,
      name: formdata.class_name,
      alias: formdata.class_alias,
    };

    if (formdata?.qualification) {
      classObject['qualificationRef'] = formdata.qualification?.value;
    }

    try {
      let addClassData = await addClasses({
        variables: { payload: classObject },
      });
      if (addClassData.data) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.CLASS, level: SUCCESS });
        handleRefetch();
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateClass = async (editdata: any) => {
    setCanClick(false);
    if (userType === USER_TYPE.EDBA_ADMIN) {
      adminClassUpdation(editdata);
    } else {
      instituteClassUpdation(editdata);
    }
  };

  async function adminClassUpdation(editdata: any) {
    let classObject = {
      id: editClassData?.id,
      name: editdata?.class_name,
      course: editdata?.course?.value,
      code: editdata?.class_code,
      alias: editdata?.class_alias,
    };

    if (editdata?.qualification) {
      classObject['qualificationRef'] = editdata.qualification?.value;
    }

    try {
      const updateClassData = await updateClasses({
        variables: { payload: classObject },
      });
      if (updateClassData?.data) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.CLASS, level: SUCCESS });
        handleRefetch();
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      console.log(e.message);
    }
    setCanClick(true);
  }

  async function instituteClassUpdation(editdata: any) {
    let classObject = {
      moduleId: editClassData?.id,
      name: editdata?.class_name,
      alias: editdata?.class_alias,
    };

    try {
      const updateClassData = await updateInstituteClass({
        variables: { payload: classObject },
      });
      if (updateClassData?.data) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.CLASS, level: SUCCESS });
        handleRefetch();
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
    setCanClick(true);
  }

  const handleDeleteClass = async () => {
    setCanClick(false);
    try {
      let deleteResponse = await deleteClass({ variables: { id: editClassData?.id } });
      if (deleteResponse?.data?.deleteClass?.status === 'success') {
        setDeleteOverlay(false);
        setAlertDetails({ message: DELETE_SUCCESS.CLASS, level: SUCCESS });
        handleRefetch();
      } else {
        throw new Error(
          deleteResponse?.data?.deleteClass?.message || t('error.deleteClass.message'),
        );
      }
    } catch (error: any) {
      setAlertDetails({ message: error.message, level: ERROR });
      setDeleteOverlay(false);
    }
    setCanClick(true);
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.CLASS]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchClasses(searchClass: string) {
    let isClear = searchClass?.length === 0;
    persistSearch(!isClear, searchClass);
  }

  const delayedQuery = useCallback(
    debounce(q => searchClasses(q), 500),
    [],
  );
  const handleSearch = (cls: string) => {
    setSearchingClass(cls);
    delayedQuery(cls);
  };

  return (
    <>
      <LoadContentWrapper>
        <FlexRowView isEdbaAdmin={userType === USER_TYPE.EDBA_ADMIN}>
          <SearchWrapper>
            <Search
              handleChange={handleSearch}
              id="classSearch"
              value={searching}
              label={t('searchClass.text')}
              height={32}
              width={280}
            />
          </SearchWrapper>

          <FilterWrapper
            filterHeight={filterHeight}
            isEdbaAdmin={userType === USER_TYPE.EDBA_ADMIN}>
            <Filter
              filterItems={filterItems}
              handleFilters={handleFilters}
              setFilterHeight={setFilterHeight}
              searchValue={searchValue}
            />
          </FilterWrapper>

          {userType === USER_TYPE.EDBA_ADMIN ? (
            <AddClassWrapper>
              <SecondaryBtn label={t('addClass.text')} onPress={addClassModal} />
            </AddClassWrapper>
          ) : null}
        </FlexRowView>
        <TblContainer height={'100%'}>
          <TblHead setHeadCells={setHeadCells} />
          <>
            {!classDataLoading ? (
              <>
                {recordsAfterPagingAndSorting()?.length > 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                      <TableRows key={i}>
                        <TableCells
                          value={`${item?.alias} (${item?.name})`}
                          style={{ width: '35%' }}
                        />
                        <TableCells value={item?.course?.alias} style={{ width: '25%' }} />

                        <TableActions align="right" style={{ width: '15%' }}>
                          <TableActionView>
                            <View>
                              <Tooltip title="Edit Class">
                                <TouchableOpacity onPress={() => editClassModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    color={theme?.table?.editIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            </View>
                            {userType === USER_TYPE.EDBA_ADMIN ? (
                              <IconView>
                                <Tooltip title="Delete Class">
                                  <TouchableOpacity onPress={() => deleteClassModal(item)}>
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      color={theme?.table?.deleteIconColor}
                                    />
                                  </TouchableOpacity>
                                </Tooltip>
                              </IconView>
                            ) : null}
                          </TableActionView>
                        </TableActions>
                      </TableRows>
                    ))}
                  </TableBody>
                ) : (
                  <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
                )}
              </>
            ) : (
              <SpinnerWrapper>
                <LoaderSpinner />
              </SpinnerWrapper>
            )}
          </>
        </TblContainer>
        <Pagination
          pathName={'academics/classes'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          hidePagination={!!(totalCount && totalCount <= rowLimit)}
        />
      </LoadContentWrapper>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={() => setDeleteOverlay(false)}
        modalVisible={deleteOverlay}
        handleSave={handleDeleteClass}
        Headerpopup="deleteClass.text">
        {t('deleteClass.warning')}
      </DeleteModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        modalVisible={modalState}
        width={578}
        onAlert={null}
        handleSave={handleSubmit(isEditModal ? handleUpdateClass : handleAddClass)}
        Headerpopup={isEditModal ? t('editClass.text') : t('addClass.text')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}>
        <ClassForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          isEditModal={isEditModal}
          editClassData={isEditModal ? editClassData : {}}
          reset={reset}
          getValues={getValues}
          setValue={setValue}
        />
      </NormalModal>
    </>
  );
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${props => (props.isEdbaAdmin ? 'space-between' : 'flex-start')};
  z-index: 10;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  margin-right: 8px;
`;

const FilterWrapper = styled.View`
  left: 0;
  flex: 1;
  align-items: flex-start;
  height: ${props => props.filterHeight * 32}px;
  width: ${props => (props.isEdbaAdmin ? (width > 1700 ? 75 : 72) : width > 1700 ? 80 : 80)}%;
`;

const AddClassWrapper = styled.View`
  flex: 1;
  align-items: flex-end;
  max-width: 140px;
  min-width: 112px;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const IconView = styled.View`
  margin-left: 13px;
`;

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
