/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import { breakpoints, colors, fontWeight } from 'src/styles/theme/styles';
import { StyleSheet, TouchableOpacity, View, Image } from 'react-native';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import MediumText from 'src/components/atoms/Text/MediumText';
import { getStorageFunction } from 'src/components/services/storage-service';
import { getProtectedImage } from 'src/components/services/image-service';
import ImageAtom from 'src/components/atoms/ImageAtom';
import { KEYS } from 'src/constant/key';
import { isNative, isWeb, width } from 'src/constant/device';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import { cacheImageObject } from 'src/graphql/reactive-variables/cache-image';
import styled from 'styled-components/native';
import { useThemeSystem } from 'src/contexts/theme-context';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { NewsDefault } from 'src/assets/common/Icons';
import { useTheme } from 'styled-components';
import { Tooltip } from '@mui/material';
import SwipeCard from '../SwipableCard';
import { format } from 'date-fns';
import { DT, parseToDate } from 'src/constant/dateTime';

export default function NewsCard(props: any) {
  const {
    data,
    onPress,
    deleteAction,
    indexKey,
    canDelete = false,
    isSwipable = true,
    createdBy,
  } = props;
  const [binaryImage, setBinaryImage] = useState<any>('');
  const [tokenData, setToken] = useState<any>('');

  const parsedDateObj = parseToDate(data.mcreatedAt);
  const date = parsedDateObj && format(parsedDateObj, DT.DATE_ABBREVIATED_MONTH_YEAR);
  const time = parsedDateObj && format(parsedDateObj, DT.TIME_12_HOUR);
  
  let imageUrl = data?.contentImage?.documentUrl || 'blank';

  let token = '' as any;
  let isDesktop = width > breakpoints.md;
  const { theme } = useThemeSystem();
  const { rem }: any = useTheme();

  useEffect(() => {
    displayProtectedImage();
  }, [data]);

  useEffect(() => {
    loadToken();
  }, []);

  async function loadToken() {
    token = await getStorageFunction(KEYS.ACCESS_TOKEN);
    setToken(`Bearer ${token}`);
  }

  async function displayProtectedImage() {
    if (isWeb) {
      let previous: any = cacheImageObject();
      if (previous[data.id]) {
        setBinaryImage(previous[data.id]);
      } else {
        let objectUrl = await getProtectedImage(imageUrl);
        setBinaryImage(objectUrl);
        previous[data.id] = objectUrl;
        cacheImageObject(previous);
      }
    }
  }

  const TouchableOpacityList = styled.TouchableHighlight`
    display: flex;
    flex-direction: row;
    padding-right: ${isDesktop ? '0px' : '24px'};
    padding-left: ${isDesktop ? '0px' : '24px'};
    padding-top: ${isDesktop ? '24px' : '11.5px'};
    border-bottom-color: ${colors.inputBorder};
    border-bottom-width: ${isDesktop ? '1px' : '0px'};
    padding-bottom: ${isDesktop ? '24px' : '15.75px'};
    width: ${isDesktop ? '60%' : '100%'};
  `;

  return (
    <>
      <SwipeCard
        key={data?.id}
        deleteItem={() => deleteAction(data, indexKey)}
        isSwipable={isSwipable
          && canDelete
          && data?.createdBy?.id === createdBy
        }
        testID={`deleteNewsCard-${indexKey}`}
      >
        <TouchableOpacityList
          testID={`newsCard-${indexKey}`}
          activeOpacity={0.5}
          underlayColor={isDesktop ? colors.white : colors.inputBorder}
          delayPressIn={50}
          onPress={(e) => onPress(data)}>
          <>
            <View style={[styles.imgView]}>
              {isNative ? (
                imageUrl && tokenData ? (
                  <>
                    {imageUrl !== 'blank' ? (
                      <ImageAtom
                        source={{
                          uri: imageUrl,
                          headers: { authorization: tokenData },
                        }}
                        style={[styles.img]}
                        resizeMode="cover"
                      />
                    ) : (
                      <Image
                        style={[styles.img]}
                        source={require('src/assets/not-found/news-default.png')}
                      />
                    )}
                  </>
                ) : (
                  <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    <LoaderSpinner size={'small'} />
                  </View>
                )
              ) : null}

              {isWeb ? (
                binaryImage ? (
                  <ImageAtom
                    source={{ uri: binaryImage }}
                    style={[styles.img]}
                    mode="cover"
                    defaultImage={<NewsDefault rem={rem} style={{ borderRadius: 8 }} />}
                  />
                ) : (
                  <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    <LoaderSpinner size={'small'} />
                  </View>
                )
              ) : null}
            </View>
            <View style={[styles.right]}>
              <View style={styles.headerView}>
                <View>
                  <SmallTextAtom
                    value={date || ''}
                    color={colors.primaryColor}
                    fontWeight={fontWeight[600]}
                    lineHeight={16}
                  />
                </View>
                <View style={styles.icon}>
                  <FontAwesomeIcon
                    icon={faCircle}
                    size={6}
                    color={colors.primaryColor}
                    fontWeight={fontWeight[600]}
                  />
                </View>
                <View>
                  <SmallTextAtom
                    value={time || ''}
                    color={colors.primaryColor}
                    fontWeight={fontWeight[600]}
                    lineHeight={16}
                  />
                </View>

                {isWeb && canDelete && (
                  <View style={{ right: 0, marginLeft: 'auto' }}>
                    <Tooltip title="Delete News">
                      <TouchableOpacity onPress={(e) => deleteAction(data, indexKey)}>
                        <FontAwesomeIcon icon={faTrashAlt} color={theme?.table?.editIconColor} />
                      </TouchableOpacity>
                    </Tooltip>
                  </View>
                )}
              </View>

              <View>
                <MediumText
                  value={data?.title || ''}
                  color={colors.secondaryText}
                  fontWeight={fontWeight[500]}
                  lineHeight={2.4}
                  lines={2}
                  align={'left'}
                />
              </View>
            </View>
          </>
        </TouchableOpacityList>
      </SwipeCard>
      {!isDesktop && <Horizontal />}
    </>
  );
}

const Horizontal = styled.View`
  border-bottom-color: ${colors.inputBorder};
  border-bottom-width: 1px;
  margin-left: 24px;
  margin-right: 24px;
`;

const styles = StyleSheet.create({
  imgView: {
    width: 92,
    height: 72,
    borderRadius: 8,
  },
  right: {
    flex: 1,
    alignSelf: 'flex-start',
    marginLeft: 14,
  },
  headerView: { flexDirection: 'row', marginVertical: 4, width: '100%' },
  img: { width: 92, height: 72, borderRadius: 8 },
  icon: { alignSelf: 'center', marginHorizontal: 8 },
});
