import { MutableRefObject } from 'react';

export const onDragStartHandle = (index: number, e: any, dragItem: MutableRefObject<any>) => {
  dragItem.current = index;
};

export const onDragEndHandle = (
  e: any,
  dragItem: MutableRefObject<any>,
  dragOverItem: MutableRefObject<any>,
  data: any,
  setData: Function,
) => {
  const copyListItems = [...data];
  const dragItemContent = copyListItems[dragItem.current];
  copyListItems.splice(dragItem.current, 1);
  copyListItems.splice(dragOverItem.current, 0, dragItemContent);
  dragItem.current = null;
  dragOverItem.current = null;
  setData(copyListItems);
};

export const onDragOverHandle = (index: number, e: any, dragOverItem: MutableRefObject<any>) => {
  e.preventDefault();
  dragOverItem.current = index;
};

export function preventDrag(e: any) {
  e.preventDefault();
  e.stopPropagation();
}
