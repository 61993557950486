import { Keyboard, StyleSheet, TouchableWithoutFeedback } from "react-native";
import React, { useEffect, useState } from "react";
import { isIOS, width } from "src/constant/device";

import AvoidKeyPad from "src/components/atoms/Wrapper/AvoidKey";
import Element from "src/components/molecules/Forms/ApplicationElement";
import FlashMessage from "react-native-flash-message";
import HeaderThree from "src/components/atoms/Text/HeaderThree";
import NormalTextAtom from "src/components/atoms/Text/NormalTextAtom";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import SmallTextAtom from "src/components/atoms/Text/SmallTextAtom";
import { colors, fontWeight } from "src/styles/theme/styles";
import navigationStrings from "src/navigation/navigationStrings";
import registrationFormJSON from "src/form-json/registration/registration.json";
import { showNativeError } from "src/components/molecules/NotificationWrapper";
import styled from "styled-components/native";
import { useApplicantSignup } from "src/graphql/signup/applicant";
import { useForm } from "react-hook-form";
import { useI18n } from "src/i18n/hooks";

interface Props {
  navigation?: any;
}

interface FormData {
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
}

export default function ApplicantSignup(props: Props) {
  const { t } = useI18n();
  const { navigation } = props;
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm();
  const { signUpApplicant } = useApplicantSignup();

  const [canClick, setCanClick] = useState<boolean>(true);
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    setElements(registrationFormJSON[0]);
  }, []);

  function navigateToApplicantSignIn() {
    navigation.navigate(navigationStrings.USER_LOGIN);
  }

  async function signUpApplicantFn(formData: FormData) {
    try {
      setCanClick(false);
      formData.mobileNumber = parseFloat(formData.mobileNumber);
      const response = await signUpApplicant({
        variables: { payload: formData },
      });

      if (response && response.data) {
        const data = response.data;
        if (
          data.applicantSignUp &&
          data.applicantSignUp.status &&
          data.applicantSignUp.status === "success"
        ) {
          setCanClick(true);
          navigation.navigate(navigationStrings.REDIRECTION_SCREEN, { completedStep: "registration_complete" })
        }
      }
    } catch (error) {
      showNativeError(error?.message);
      setCanClick(true);
    }
  }

  return (
    <>
      <FlashMessage position="top" />
      <AvoidKeyPad extraHeight={180} style={styles.AvoidKeyPadStyles}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <Container>
            <PageAreaContainer>
              <SignUpWrapper>
                <CenterView>
                  <NativeEDBALogo
                    source={require("src/assets/logo/new-logo-4x.png")}
                  />
                </CenterView>

                <BackgroundImage
                  source={require("src/assets/login-background-4x.png")}
                  resizeMode="contain"
                />

                <CenterView style={styles.CenterViewIOS}>
                  <HeaderThree
                    value={"Hello Applicant!"}
                    lineHeight={36}
                    fontWeight={isIOS ? fontWeight["600"] : fontWeight['bold']}
                  />
                </CenterView>

                <CenterView>
                  <NormalTextAtom
                    style={styles.TextCenter}
                    value={t("applicant.signup.desc")}
                  />
                </CenterView>

                <FormWrapper>
                  {fields &&
                    fields.map((field: any, i: number) => (
                      <ElemWrapper key={`form${i}`}>
                        <Element
                          key={i}
                          field={field}
                          control={control}
                          errors={errors}
                          setError={setError}
                          clearErrors={clearErrors}
                        />
                      </ElemWrapper>
                    ))}
                </FormWrapper>

                <SecondaryBtn
                  canLoad={!canClick}
                  disabled={isSubmitting}
                  label={isSubmitting ? "Signing Up..." : "Sign Up"}
                  onPress={handleSubmit(signUpApplicantFn)}
                  style={styles.SignUpBtn}
                  lineHeight={2}
                />
              </SignUpWrapper>
              <ApplicantSignUpWrapper onPress={navigateToApplicantSignIn}>
                <SmallTextAtom
                  value={t("back-to-sign-in.text")}
                  color={colors.primary}
                  fontWeight={fontWeight[isIOS ? "600" : "bold"]}
                />
              </ApplicantSignUpWrapper>
            </PageAreaContainer>
          </Container>
        </TouchableWithoutFeedback>
      </AvoidKeyPad>
    </>
  );
}

const styles = StyleSheet.create({
  AvoidKeyPadStyles: {
    height: "90%",
    flex: 1,
    backgroundColor: colors.white,
  },
  SignUpBtn: { height: 48, borderRadius: 32 },
  CenterViewIOS: {
    marginBottom: 8,
    marginTop: -24,
  },
  TextCenter: { textAlign: "center" },
});

const ElemWrapper = styled.View``;

const FormWrapper = styled.View`
  margin-top: 12px;
`;

const SignUpWrapper = styled.View`
  background-color: ${colors.white};
  border-radius: 3px;
  padding: 24px 85px 32px 85px;
`;

const Container = styled.View`
  flex: 1;
  align-items: center;
  background-color: ${colors.white};
`;

const CenterView = styled.View`
  justify-content: center;
  align-items: center;
`;

const PageAreaContainer = styled.View`
  justify-content: center;
  flex-grow: 1;
  border-color: #666666;
  width: 520px;
  height: 578px;
  background-color: ${isIOS ? colors.white : "transparent"};
`;

const NativeEDBALogo = styled.Image`
  height: 40px;
  width: 112px;
`;

const BackgroundImage = styled.Image`
  height: 180px;
  width: ${width > 400 ? 450 : "120%"};
  margin-top: 5%;
  margin-left: ${width > 400 ? "-10%" : "-10%"};
`;

const ApplicantSignUpWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-self: center;
`;
