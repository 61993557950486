import React, { useEffect, useState } from 'react';
import { colors, fonts } from 'src/styles/theme/styles';

import AttendanceTableGradeCard from './AttendanceTableGradeCard';
import GradeRangeDetailsTable from './GradeRangeDetailsTable';
import HeaderFour from 'src/components/atoms/Text/HeaderFour';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import TableFourGradeCard from './TableFourGradeCard';
import TableOneGradeCard from './TableOneGradeCard';
import TableSignatureCard from './TableSignatureCard';
import TableThreeGradeCard from './TableThreeGradeCard';
import TableTwoGradeCard from './TableTwoGradeCard';
import styled from 'styled-components/native';
import { useGetGradeCardResults } from 'src/graphql/assessment/assessment-results';
import { useGetGrades } from 'src/graphql/assessment/assessment-grades';

export default function GradeCardModal(props: any) {
  const { studentIdArray, selectedBatchId, selectedClassId, selectedDivision, selectedExamId, totalStudentCount } = props;
  const { getGrades, gradesData, gradesError, gradesLoading } = useGetGrades();

  // Get Grade Range Details
  useEffect(() => {
    getGradeDetails();
  }, [selectedBatchId]);

  function getGradeDetails() {
    if (selectedBatchId) {
      getGrades({
        variables: {
          "filters": {
            "batch": {
              "eq": selectedBatchId
            }
          }
        }
      });
    }
  }

  return (
    studentIdArray.map((currentStudentId: string, index: number, array: any) => {
      const [data, setData] = useState<any>();
      const [additionalSubjectRows, setAdditionalSubjectRows] = useState<number>(0);
      const { getGradeCardResults, loadingGradeCardResults, dataGradeCardResults, errorGradeCardResults } = useGetGradeCardResults();

      useEffect(() => {
        if (dataGradeCardResults) {
          setData(dataGradeCardResults?.examMarks?.data[index]);
        };
      }, [dataGradeCardResults]);

      useEffect(() => {
        onLoadFunction();
      }, [currentStudentId]);

      function onLoadFunction() {
        getGradeCardResults({
          variables: {
            "batch": selectedBatchId,
            "exam": selectedExamId,
            "division": selectedDivision,
            "filters": {
              "user": {
                "eq": currentStudentId
              }
            }
          }
        });
      }

      if (data) {
        return (
          <>
            <ParentContainer>
              <InfoBlockContainer>
                <HeaderFour value={`ANNUAL RESULT FOR ACADEMIC YEAR ${data?.batch.academicYear.from}-${data?.batch.academicYear.to}`} fontWeight={'400'} align={'center'} />
              </InfoBlockContainer>

              <ParentTableContainer>
                <UpperContainer>
                  <SectionOne>
                    <SectionOneUpper>
                      <TableOneGradeCard data={data} totalStudentCount={totalStudentCount} />
                      <TableTwoGradeCard data={data} />
                    </SectionOneUpper>
                    <SectionOneLower>
                      <TableThreeGradeCard data={data} setAdditionalSubjectRows={setAdditionalSubjectRows} />
                      <TableFourGradeCard data={data} additionalSubjectRows={additionalSubjectRows} />
                    </SectionOneLower>
                  </SectionOne>
                  <AttendanceTableGradeCard data={data} additionalSubjectRows={additionalSubjectRows} />
                </UpperContainer>
                <TableSignatureCard />
                <GradeRangeDetailsTable data={gradesData?.grades?.data[0]?.grades} />
              </ParentTableContainer>
            </ParentContainer >
            {/* No Need to display Divider Line if only one student grade card is generated */}
            {studentIdArray?.length > 1 ? <HorizontalRule /> : null}
          </>
        );
      } else {
        return (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        );
      }
    })
  );
}

const ParentContainer = styled.View`
  margin: 25px 0px 75px 0px;
  height: 772px;
 `;

const HorizontalRule = styled.View`
  height: 2px;
  margin-bottom: 50px;
  backgroundColor: ${colors.deviderGrey};
 `;

const UpperContainer = styled.View`
  flex-direction: row;
 `;

const SectionOne = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 950px;
 `;

const SectionOneUpper = styled.View`
  display: flex;
  flex-direction: row;
 `;

const SectionOneLower = styled.View`
  display: flex;
  flex-direction: row;
 `;

const InfoBlockContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
 `;

const ParentTableContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
 `;

const SpinnerView = styled.View`
  width: 100%;
  height: 547px;
  justify-content: center;
  align-items: center;
`;
