import { gql, useLazyQuery, useMutation } from '@apollo/client';

export const getReportStatusQuery = gql`
  query ($academicYearId: String!, $instituteId: String!, $type: RequestType!) {
    getLatestUsageReportStatus(
      academicYearId: $academicYearId
      instituteId: $instituteId
      type: $type
    ) {
      createdAt
      status
    }
  }
`;

export const createAdmissionCancellationReportMutation = gql`
  mutation ($academicYearId: String!, $instituteId: String!) {
    generateAdmissionCancelReport(academicYearId: $academicYearId, instituteId: $instituteId) {
      message
      status
    }
  }
`;

export const createDisabledStudentsReportMutation = gql`
  mutation ($academicYearId: String!, $instituteId: String!) {
    generateHandicapReport(academicYearId: $academicYearId, instituteId: $instituteId) {
      message
      status
    }
  }
`;

export function useGetReportStatus() {
  const [getReportStatus, { data, loading }] = useLazyQuery(getReportStatusQuery, {
    fetchPolicy: 'network-only',
  });
  return { getReportStatus, data, loading };
}

export function useCreateAdmissionCancellationReport() {
  const [
    createAdmissionCancellationReport,
    { data: createAdmissionCancellationReportData, error: createAdmissionCancellationReportError },
  ] = useMutation(createAdmissionCancellationReportMutation);
  return {
    createAdmissionCancellationReport,
    createAdmissionCancellationReportData,
    createAdmissionCancellationReportError,
  };
}

export function useCreateDisabledStudentsReport() {
  const [
    createDisabledStudentsReport,
    { data: createDisabledStudentsReportData, error: createDisabledStudentsReportError },
  ] = useMutation(createDisabledStudentsReportMutation);
  return {
    createDisabledStudentsReport,
    createDisabledStudentsReportData,
    createDisabledStudentsReportError,
  };
}
