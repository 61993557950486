import { DT, formatDate } from "src/constant/dateTime";

const getFormattedDate = (
  mdate: string,
  mstartTime: string,
  mendTime: string
): string => {
  const eventDate = mdate
    ? formatDate(
      mdate,
      DT.DATE_TIME_FORMAT_WITH_TIMEZONE,
      DT.DATE_ABBREVIATED_MONTH_YEAR
    )
    : "";
  const startTiming = mstartTime
    ? formatDate(mstartTime, DT.TIME_24_HOUR, DT.TIME_12_HOUR)
    : "";
  const endTiming = mendTime
    ? formatDate(mendTime, DT.TIME_24_HOUR, DT.TIME_12_HOUR)
    : "";

  return `${eventDate} | ${startTiming} to ${endTiming}`;
};

export const eventModalHelpers = {
  getFormattedDate,
};
