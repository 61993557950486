import {
  DatePickerProps,
  DesktopDatePicker,
  TimePicker,
  TimePickerProps,
} from "@mui/x-date-pickers";
import {
  DateTimeFormats,
  WebDatePickerProps,
  WebDateTimeMode,
  getMUIDatePickerViews,
} from "./helpers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import SmallTextAtom from "../Text/SmallTextAtom";
import { colors } from "src/styles/theme/styles";
import styled from "styled-components";

type PickerProps = DatePickerProps<Date> | TimePickerProps<Date>;
type PickerComponent = typeof DesktopDatePicker | typeof TimePicker;

const ComponentMap: Record<WebDateTimeMode, PickerComponent> = {
  date: DesktopDatePicker,
  year: DesktopDatePicker,
  monthyear: DesktopDatePicker,
  time: TimePicker,
};

function DateTimePicker(props: WebDatePickerProps) {
  const {
    initialValue,
    dateTimeMode = "date",
    minDate,
    maxDate,
    dateTimePickerTitle,
    containerWidth = "100%",
    containerHeight = "40px",
    onDateTimeChangeCallback,
    disabled = false,
    disableFuture = false,
    disablePast = false,
    standalonePicker = false,
    forceOpenAtBottom = true,
    customFormat,
  } = props;

  const commonProps: PickerProps = {
    value: initialValue,
    closeOnSelect: true,
    disabled: disabled,
    fixedWeekNumber: 6, // Each month occupies consistent height
    format: customFormat ?? DateTimeFormats[dateTimeMode],
    onChange: (selectedDate: Date | null) => {
      if (onDateTimeChangeCallback && selectedDate) {
        onDateTimeChangeCallback(selectedDate);
      }
    },
    sx: { width: containerWidth, height: containerHeight },
    slotProps: slotProps(disabled, standalonePicker, forceOpenAtBottom) as any,
  };

  const PickerComponent = ComponentMap[dateTimeMode] as any;
  let specificProps: Partial<PickerProps> = {};

  if (dateTimeMode === "time") {
    specificProps = {
      ampm: true,
      views: ["hours", "minutes"],
    };
  } else {
    specificProps = {
      minDate: minDate,
      maxDate: maxDate,
      views: getMUIDatePickerViews(dateTimeMode),
      disableFuture: disableFuture,
      disablePast: disablePast,
    };
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container
        containerWidth={containerWidth}
        containerHeight={containerHeight}
      >
        {!standalonePicker && (
          <FieldTitleWrapper>
            <SmallTextAtom
              value={dateTimePickerTitle}
              color={colors.secondaryText}
            />
          </FieldTitleWrapper>
        )}
        <PickerComponent {...commonProps} {...specificProps} />
      </Container>
    </LocalizationProvider>
  );
}

const slotProps = (
  fieldDisabled: boolean = false,
  standalonePicker: boolean = false,
  forceOpenAtBottom: boolean = true
) => ({
  textField: {
    fullWidth: true,
    disabled: true,
    placeholder: "",
    variant: standalonePicker ? "standard" : "outlined",
    sx: {
      // Disable manual keyboard input.
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: fieldDisabled
          ? colors.tertiaryText
          : colors.primaryText,
        padding: "16.5px 14px 16.5px 8px",
        display: standalonePicker && "none",
      },
    },
  },
  popper: {
    sx: { height: forceOpenAtBottom ? 0 : "auto" }, // Height 0 is necessary to prevent the popper from opening at the top of the screen on lower resolutions.
    // MUI Popper Style override https://github.com/mui/material-ui/issues/21661#issuecomment-1198077932
  },
  inputAdornment: {
    sx: standalonePicker ? { margin: 0 } : {},
  },
  openPickerButton: {
    sx: standalonePicker ? { padding: 0 } : {},
  },
});

const Container = styled.div<{
  containerWidth: number | string;
  containerHeight: number | string;
}>`
  width: ${({ containerWidth }) =>
    typeof containerWidth === "number"
      ? `${containerWidth}px`
      : containerWidth};
  & .MuiOutlinedInput-root {
    height: ${(props) => props.containerHeight};
    width: ${(props) => props.containerWidth};
  }
  & .MuiInputBase-root.Mui-disabled:before {
    border-bottom: none;
  }
`;

const FieldTitleWrapper = styled.div`
  margin-bottom: 4px;
`;

export default DateTimePicker;
