import React from 'react';
import { TouchableOpacity } from 'react-native';
import HeaderFour from 'src/components/atoms/Text/HeaderFour';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts, fontWeight } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import ReactHtmlParser from 'react-html-parser';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { Text } from 'react-native';
import { isIOS, isWeb, width } from 'src/constant/device';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import MediumText from 'src/components/atoms/Text/MediumText';
import RenderHtml from 'react-native-render-html';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { userInformation } from 'src/utils/manageState';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';
interface MessageViewerProps {
  data: any;
  setType: Function;
  isInbox: boolean;
}

export default function MessageViewer(props: MessageViewerProps) {
  const { t } = useI18n();
  const { data, setType, isInbox } = props;
  const parsedDate = data?.createdAt && parse(data?.createdAt, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date());

  const currentUserObject = userInformation();
  const { userName } = currentUserObject;
  return (
    <React.Fragment>
      <Container>
        {data ? (
          <React.Fragment>
            <SubjectWrapper>
              {isWeb ? (
                <HeaderFour value={data?.subject} color={colors.secondaryText} />
              ) : (
                <HeaderSix value={data?.subject} />
              )}
              {isInbox && isWeb && (
                <ReplyWrapper>
                  <TouchableOpacity onPress={() => setType('reply')}>
                    <Text>
                      <IconWrapper>
                        <Icon name={'reply'} />
                      </IconWrapper>
                      <NormalTextSelect value={t('reply.label')} color={colors.secondaryText} />
                    </Text>
                  </TouchableOpacity>
                </ReplyWrapper>
              )}
            </SubjectWrapper>
            <FromWrapper>
              {isWeb ? (
                <MediumTextSelect
                  value={isInbox ? data?.sender?.userName : userName}
                  color={colors.secondaryText}
                  fontFamily={fonts.semibold}
                />
              ): (
                <UserWrapper>
                  <SmallTextAtom
                    value={isInbox ? data?.sender?.userName : userName}
                    fontWeight={fontWeight[600]}
                    fontFamily={fonts.bold}
                    lines={2}
                  />
                </UserWrapper>
              )}
              {isWeb ? (
                <MediumText
                  value={parsedDate && format(parsedDate, DT.DATE_TIME_FORMAT_12_HOUR)}
                  color={colors.tertiaryText}
                  lineHeight={2.4}
                />
              ) : (
                <SmallTextAtom
                  value={parsedDate && format(parsedDate, DT.DATE_ABBREVIATED_MONTH_YEAR)}
                  color={colors.tertiaryText}
                />
              )}
            </FromWrapper>
            <HeaderWrapper>
              <SmallTextAtom value={isInbox ? 'To: ' + userName : 
                'To: ' + data?.audienceLabels.map(
                (label: string, index: number) => {
                  return (
                    label?.label +
                    (index !== data?.audienceLabels.length - 1 ? ', ' : '')
                  );
                },
              )} />
            </HeaderWrapper>
            {isWeb ? (
              <ContentWrapper>{ReactHtmlParser(data?.body)}</ContentWrapper>
            ) : (
              <RenderHtml
                source={{
                  html:
                    `<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"></head><body>
                    <div
                      style="
                        color: ${colors.secondaryText};
                        font-family: ${fonts.regular};
                        letter-spacing: 0.01em;
                        line-height: 22px;
                        font-size: ${isIOS ? '18px' : '15px'}"` +
                    data?.body +
                    `</div>
                  </body></html>`,
                }}
                contentWidth={width - 48}
                baseStyle={{
                  marginTop: 16
                }}
              />
            )}
          </React.Fragment>
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )}
      </Container>
    </React.Fragment>
  );
}

const Container = styled.View``;

const SubjectWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ReplyWrapper = styled.View``;

const IconWrapper = styled.Text`
  margin-right: 8px;
`;

const FromWrapper = styled.View`
  margin-top: 24px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const HeaderWrapper = styled.View`
  margin-top: ${isWeb ? '20px' : 0};
`;

const ContentWrapper = styled.View`
  margin-top: 8px;
  font-family: ${fonts.regular};
  color: ${colors.secondaryText};
  font-size: 15px;
`;

const SpinnerView = styled.View`
  height: 418px;
  justify-content: center;
  align-items: center;
`;

const UserWrapper = styled.View`
  flex: 1;
`;