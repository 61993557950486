import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState, useRef } from 'react';
import { View, TouchableOpacity, Dimensions, ActivityIndicator, ViewStyle } from 'react-native';
import Modal from 'react-native-modal';
import { colors, fonts, fontWeight } from 'src/styles/theme/styles';
import HeaderFive from '../../Text/HeaderFive';
import NormalTextSelect from '../../Text/NormalTextSelect';
import { useI18n } from 'src/i18n/hooks';
import PropTypes from 'prop-types';
import SmallTextAtom from '../../Text/SmallTextAtom';
import ModalClose from '../../ModalClose';
import AvoidKeyPad from '../../Wrapper/AvoidKey';
import { height } from 'src/constant/device';
import styled from 'styled-components/native';
import FlashMessage from 'react-native-flash-message';

interface Props {
  children: any;
  modalVisible: boolean;
  setModalVisible: () => void;
  Headerpopup: any;
  handleSave?: any;
  addEditButtonLabel?: string;
  assignment?: boolean;
  color?: string;
  assignStatus?: boolean;
  style?: object;
  isSubmitting?: boolean;
  hideSubmit?: boolean;
  paymentMode?: boolean;
  message?: Object;
  showCloseIcon?: boolean;
  modalHeight?: number;
  scrollableContent?: boolean;
}

const NormalModal = (props: Props) => {
  const {
    children,
    modalVisible,
    setModalVisible,
    Headerpopup,
    handleSave,
    addEditButtonLabel = '',
    assignment = false,
    color = colors.primaryColor,
    assignStatus = true,
    isSubmitting,
    style,
    hideSubmit = true,
    paymentMode = false,
    message,
    showCloseIcon = false,
    modalHeight,
    scrollableContent = true,
  } = props;
  const { t } = useI18n();
  const modalFlash = useRef();

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {});
    return () => subscription?.remove();
  });

  useEffect(() => {
    if (message && Object.keys(message).length > 0) {
      modalFlash?.current?.showMessage(message);
    }
  }, [message]);
  
  return (
    <View>
      <Modal
        onBackButtonPress={setModalVisible}
        coverScreen={true}
        isVisible={modalVisible}
        onBackdropPress={setModalVisible}
        onSwipeComplete={setModalVisible}
        swipeDirection={'down'}
        style={{ margin: 0 }}
        propagateSwipe={true}
        backdropColor={'rgba(0,0,0,0.35)'}
      >
        <CenteredView>
          <ModalView style={style} modalHeight={modalHeight}>
            {height > 701 && <ModalClose />}
            <HeaderView>
              {/* to show close button for smaller devices */}
              {!showCloseIcon && (height > 600 && height < 700) || paymentMode ? (
                <HeaderLeftCloseTouchable onPress={setModalVisible}>
                  <FontAwesomeIcon icon={faTimes} size={20} color={colors.secondaryText} />
                </HeaderLeftCloseTouchable>
              ) : (
                <BlankHeaderLeftView />
              )}
              {Headerpopup && (
                <HeaderTitleView hideSubmit={hideSubmit}>
                  <HeaderFive
                    value={Headerpopup}
                    color={colors.secondaryText}
                    fontWeight={fontWeight[600]}
                  />
                </HeaderTitleView>
              )}

              {!isSubmitting && hideSubmit && (
                <HeaderRightContentTouchable onPress={handleSave} disabled={isSubmitting}>
                  {assignment ? (
                    <SmallTextAtom
                      value={t(addEditButtonLabel)}
                      color={assignStatus ? colors.green : colors.blue}
                    />
                  ) : (
                    <NormalTextSelect
                      value={t(addEditButtonLabel)}
                      color={color}
                      fontFamily={fonts.bold}
                      fontWeight={600}
                    />
                  )}
                </HeaderRightContentTouchable>
              )}

              {isSubmitting && (
                <HeaderRightContentView>
                  <ActivityIndicator
                    animating={isSubmitting}
                    size="small"
                    color={colors.primaryColor}
                  />
                </HeaderRightContentView>
              )}

              {showCloseIcon && (
                <RightCloseTouchable onPress={setModalVisible}>
                  <FontAwesomeIcon icon={faTimes} size={24} color={colors.secondaryText} />
                </RightCloseTouchable>
              )}
            </HeaderView>
            {scrollableContent ? (
              <AvoidKeyPad
                extraHeight={280}
                style={styles.contentStyle}>
                <ContentScrollView showsVerticalScrollIndicator={false}>
                  <TouchableOpacity activeOpacity={1}>{children}</TouchableOpacity>
                </ContentScrollView>
              </AvoidKeyPad>
            ) : (
              <View style={styles.contentStyle}>
                <ContentView>
                  <TouchableOpacity activeOpacity={1}>{children}</TouchableOpacity>
                </ContentView>
              </View>
            )}
          </ModalView>
        </CenteredView>
        <FlashMessage ref={modalFlash} />
      </Modal>
    </View>
  );
};

NormalModal.propTypes = {
  children: PropTypes.node.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
};

const CenteredView = styled.View`
  flex: 1;
  margin-top: ${height > 600 && height < 700 ? '8%' : '32%'};
`;

const ModalView = styled.View<{ modalHeight: number }>`
  flex: 1;
  background-color: ${colors.white};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 0 24px;
  padding-bottom: 24px;
  ${({ modalHeight }) =>
    modalHeight && `
    position: absolute;
    height: ${modalHeight};
    bottom: 0;
    left: 0;
    right: 0;
  `}
`;

const HeaderView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${height > 701 ? 0 : 15};
`;

const HeaderTitleView = styled.View<{ hideSubmit: boolean }>`
  flex: 1;
  align-items: center;
  margin-right: ${({ hideSubmit }) => hideSubmit ? 0 : 70};
`;

const HeaderLeftCloseTouchable = styled.TouchableOpacity`
  padding: 5px;
  margin-right: 40px;
`;

const BlankHeaderLeftView = styled.View`
  flex: 0.4;
`;

const HeaderRightContentView = styled.View`
  align-items: flex-end;
  flex: 0.4;
  justify-content: center;
`;

const HeaderRightContentTouchable = styled.TouchableOpacity`
  align-items: flex-end;
  flex: 0.4;
  justify-content: center;
`;

const RightCloseTouchable = styled.TouchableOpacity`
  align-items: flex-end;
`;

const ContentScrollView = styled.ScrollView`
  margin-top: 24px;
`;

const ContentView = styled.View`
  margin-top: 24px;
`;

const styles: Record<string, ViewStyle> = {
  contentStyle: {
    flex: 1,
    height: '90%',
  }
}
export default NormalModal;
