import { gql, useLazyQuery, useMutation } from '@apollo/client';

export const createStudentGroupQuery = gql`
  mutation ($payload: CreateStudentGroupInput!) {
    createStudentGroup(payload: $payload) {
      id
      name
    }
  }
`;

export const studentGroupsQuery = gql`
  query ($limit: Int, $skip: Int, $filters: StudentGroupFilterInput) {
    studentGroups(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        id
        name
        batch{
          id
          name
        }
        division
        students{
          id
          firstName
          lastName
        }
        subjects{
          value: id
          label: alias
        }
      }
    }
  }
`;

export function useGetStudentGroups() {
  const [getStudentGroups, { loading, data: groupsData, error: groupsError, refetch: refetchStudentGroups }] =
    useLazyQuery(studentGroupsQuery, { fetchPolicy: 'network-only' });
  return { getStudentGroups, groupsData, groupsError, loading, refetchStudentGroups };
}

export function useCreateStudentGroup() {
  const [createStudentGroup, { data: studentGroupData, error: studentGroupError }] =
    useMutation(createStudentGroupQuery);
  return { createStudentGroup, studentGroupData, studentGroupError };
}