import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { Provider as PaperProvider } from 'react-native-paper';
import {
  getStorageFunction,
  setStorageFunction,
  flushStorageFunction,
} from 'src/components/services/storageService';
import { darkTheme } from 'src/styles/theme/dark-theme';
import { lightTheme } from 'src/styles/theme/light-theme';
import { makeVar } from '@apollo/client';
import { usePersistStorage } from 'react-native-use-persist-storage';

interface ThemeContextData {
  changeTheme(name: any): void;
  theme: any;
  restored: boolean;
}

// const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

// const ThemeProvider: React.FC = ({ children }) => {
//   const [theme, setTheme] = useState();
//   // const [count, setCount] = useCounterState(lightTheme);
//   const [count, setCount] = usePersistStorage('edbaTheme', {});

//   // useEffect(() => {
//   //   currentTheme();
//   // }, []);

//   const currentTheme = async () => {
//     const activeTheme = await getStorageFunction('edbaTheme');
//     if (count.header) {
//       setTheme(count);
//     } else setTheme(lightTheme);
//   };

//   currentTheme();

//   const changeTheme = useCallback(
//     async (themeName) => {
//       flushStorageFunction('edbaTheme');
//       // setTheme(themeName);
//       // setStorageFunction('edbaTheme', JSON.stringify(themeName));
//     },
//     [theme],
//   );
//   return <ThemeContext.Provider value={{ changeTheme, theme }}>{children}</ThemeContext.Provider>;
// };

// const useThemeSystem = (): ThemeContextData => {
//   const context = useContext(ThemeContext);
//   return context;
// };

// export { ThemeProvider, useThemeSystem };

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

const ThemeProvider: React.FC = ({ children }) => {
  const [theme, changeTheme, restored] = usePersistStorage('edbaTheme', lightTheme);
  return (
    <ThemeContext.Provider value={{ changeTheme, theme, restored }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useThemeSystem = (): ThemeContextData => {
  const context = useContext(ThemeContext);
  return context;
};

export { ThemeProvider, useThemeSystem };
