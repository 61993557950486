import React, { Fragment, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useI18n } from 'src/i18n/hooks';
import { height, isIOS, isWeb } from 'src/constant/device';
import MobileHeader from 'src/components/hoc/MobileHeader';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { graphqlQuery } from 'src/graphql/util';
import {
  attendanceSlots,
  dayWiseAttendence,
  getStudentInfo,
  slotsWiseUserAttendance,
} from 'src/graphql/attendance';
import { colors } from 'src/styles/theme/styles';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import ListNative from 'src/components/atoms/AttendanceList/index.native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import navigationStrings from 'src/navigation/navigationStrings';
import { userInformation } from 'src/utils/manageState';
import { useQuery } from '@apollo/client';
import AttendanceNotConfigured from '../AttendanceNotConfigured';
import DateTimePicker from 'src/components/atoms/DateTime/DateTimePicker.native';
import { DT } from 'src/constant/dateTime';
import { format, startOfMonth, endOfMonth, parse } from 'date-fns';
import { properCase } from 'src/utils/utility';
import styled from 'styled-components/native';
import { generateMarkedDatesObject } from 'src/components/atoms/Calendars/CalendarHelpers';
import { audienceTypeOptions, filterAbsentDates } from 'src/components/molecules/Attendance/History/AttendanceHelpers';
import LeaveCalendar from 'src/components/atoms/Calendars/LeaveCalendar';
import { AttendanceData, AttendanceListData, DayWiseAttendanceListData, SelectedDatesState } from 'src/components/molecules/Attendance/AttendanceTypes';

export default function StudentAttendanceNative(props) {
  const { navigation } = props;
  const timezone = DT.TIMEZONE;

  const { t } = useI18n();
  const today = new Date();
  const todaysDate = format(today, DT.DATE_FORMAT_SLASH);
  const startdate = format(startOfMonth(today), DT.DATE_FORMAT_SLASH);
  const enddate = format(endOfMonth(today), DT.DATE_FORMAT_SLASH);

  const selectedDurationType = null;

  const attendanceTypeOptions = [
    { value: 0, label: t('fullDay.label') },
    { value: 1, label: t('slots.label') },
    { value: 2, label: t('subjects.label') },
  ];
  const audienceType = audienceTypeOptions[0];
  const currentUserObject = userInformation();
  const { userId } = currentUserObject;

  const [studentInfo, setStudentInfo] = useState<{
    batch: any;
    classId: string;
    className: string;
    division: string;
    fullName: string;
    userId: string;
  } | null>(null);

  const [dates, setDates] = useState<SelectedDatesState>({
    startDate: startdate,
    endDate: enddate,
    currentDate: todaysDate,
  });

  const [dateString, setdateString] = useState<string>(format(today, DT.MONTH_NAME_YEAR));
  const [isAttendanceSettingConfigured, setisAttendanceSettingConfigured] = useState<boolean>(true);
  const [attendanceType, setAttendenceType] = useState<{ value: number; label: string } | null>(null);
  const [totalPresents, setTotalPresents] = useState<number>(0);
  const [totalAttendance, setTotalAttendance] = useState<number>(0);
  const [currentSlotWiseAttendanceDataList, setcurrentSlotWiseAttendanceDataList] = useState<AttendanceData[] | null>(null);
  const [calendarData, setCalendarData] = useState<null | []>(null);

  const { data: slotsData, loading: slotsDataloading } = useQuery(attendanceSlots, {
    fetchPolicy: 'network-only',
  });

  function namingSlotsInfo() {
    const weeklyOrMonthly = t('monthly.label');
    let slotsName = '';
    if (!attendanceType) {
      return '';
    } else if (attendanceType.value === 0) {
      slotsName = t('fullDay.label');
    } else if (attendanceType.value === 1) {
      // removed "Slots" & "Subjects" coz of redundant text in heading
      //   slotsName = t('slots.label');
      slotsName = '';
    } else {
      //   slotsName = t('subjects.label');
      slotsName = '';
    }
    // removed "Slots" & "Subjects" coz of redundant text in heading
    return weeklyOrMonthly + `${attendanceType.value === 0 ? ' - ' + slotsName : ''}`;
  }

  function setSlotWiseData(list: AttendanceListData[]) {
    const arr: AttendanceData[] = [];
    let presentCount = 0;
    let totalAttendanceCount = 0;

    list.forEach((attendanceInfo: any) => {
      presentCount += attendanceInfo.present;
      totalAttendanceCount += attendanceInfo.total;
      arr.push({
        id: attendanceInfo.slot,
        heading: attendanceInfo.slotName,
        attend: attendanceInfo.present,
        total: attendanceInfo.total,
        percentage: ((attendanceInfo.present / attendanceInfo.total) * 100).toFixed(0),
      });
    });

    arr.sort((a, b) => (a.heading ?? "").localeCompare(b.heading ?? ""));

    setTotalAttendance(totalAttendanceCount ? totalAttendanceCount : 1);
    setTotalPresents(presentCount);
    setcurrentSlotWiseAttendanceDataList(arr);
  }

  async function getSlotsAttendanceData() {
    if (studentInfo) {
      const payload = {
        batch: studentInfo?.batch.value,
        class: studentInfo?.classId,
        division: studentInfo.division,
        start: dates.startDate,
        end: dates.endDate,
        user: studentInfo.userId,
        timezone,
      };

      setTotalAttendance(0);
      setTotalPresents(0);
      const { loading, data } = await graphqlQuery(
        slotsWiseUserAttendance,
        {
          payload,
        },
        true,
      );
      if (!loading && data?.slotWiseForUser[0]?.data?.length) {
        setSlotWiseData(data?.slotWiseForUser[0]?.data);
      } else {
        setcurrentSlotWiseAttendanceDataList([]);
      }
    }
  }

  async function getStudentsData() {
    const { loading: fetchStudentsDataLoading, data: studentsData } = await graphqlQuery(
      getStudentInfo,
      {
        studentId: userId,
      },
      true,
    );
    if (!fetchStudentsDataLoading && studentsData && !studentInfo) {
      const classId = studentsData.instituteStudent.academic.class.id;
      const division = studentsData.instituteStudent.academic.division;
      const batch = studentsData.instituteStudent.academic.batch;
      setStudentInfo({
        batch: {
          value: batch.id,
          label: batch.name,
          class: {
            id: classId,
            name: studentsData.instituteStudent.academic.class.name,
            alias: studentsData.instituteStudent.academic.class.alias,
          },
        },
        classId,
        division,
        className: studentsData.instituteStudent.academic.class.name,
        fullName: properCase(studentsData?.instituteStudent?.personalDetails?.fullName),
        userId: userId,
      });
    }
  }

  function setDayWiseAttendanceData(list: DayWiseAttendanceListData[]) {
    let globalPresentsCount = 0;
    let globalTotalAttendanceCount = 0;

    if (list.length > 0) {
      list.forEach((element: DayWiseAttendanceListData) => {
        if (element.data.length > 0) {
          globalPresentsCount += element.present;
          globalTotalAttendanceCount += element.total;
        }
      });
    }
    setTotalAttendance(globalTotalAttendanceCount ? globalTotalAttendanceCount : 1);
    setTotalPresents(globalPresentsCount);
  }

  async function getDayWiseAttendaceData() {
    if (studentInfo) {
      const payload = {
        batch: studentInfo.batch.value,
        class: studentInfo.classId,
        division: studentInfo.division,
        start: dates.startDate,
        end: dates.endDate,
        slot: 'DAY',
        user: studentInfo.userId,
        timezone,
      };

      setTotalAttendance(0);
      setTotalPresents(0);

      const { loading, data: currentAttendanceData } = await graphqlQuery(
        dayWiseAttendence,
        {
          payload,
        },
        true,
      );
      if (!loading && currentAttendanceData?.dayWiseAttendance?.length) {
        setCalendarData(currentAttendanceData?.dayWiseAttendance);
        setDayWiseAttendanceData(currentAttendanceData?.dayWiseAttendance);
      } else {
        setCalendarData([]);
        setDayWiseAttendanceData([]);
      }
    }
  }

  function setSlotsData() {
    if (!slotsDataloading && slotsData) {
      if (!slotsData?.institute.settings) {
        setisAttendanceSettingConfigured(false);
      } else {
        if (slotsData?.institute.settings.attendanceType === 'DAY_WISE') {
          setAttendenceType(attendanceTypeOptions[0]);
        } else if (slotsData?.institute.settings.attendanceType === 'SLOT_WISE') {
          setAttendenceType(attendanceTypeOptions[1]);
        } else {
          setAttendenceType(attendanceTypeOptions[2]);
        }
        setisAttendanceSettingConfigured(true);
      }
    }
  }

  const dateChangeHandler = (date: Date) => {
    const newDate = format(date, DT.DATE_FORMAT_SLASH);
    const endDate = format(endOfMonth(date), DT.DATE_FORMAT_SLASH);

    setDates((prev) => {
      if (prev.startDate !== newDate || prev.endDate !== endDate) {
        if (attendanceType?.value === 0) {
          setCalendarData(null);
        } else {
          setcurrentSlotWiseAttendanceDataList(null);
        }
      }

      return {
        ...prev,
        startDate: newDate,
        endDate: endDate,
        currentDate: newDate,
      };
    });

    setdateString(format(date, DT.MONTH_NAME_YEAR));
  };

  function noDataHandler() {
    if (!attendanceType) {
      return false;
    }

    if (attendanceType.value == 0) {
      if (calendarData.length == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (currentSlotWiseAttendanceDataList.length == 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  useEffect(() => {
    setSlotsData();
  }, [slotsDataloading]);

  useEffect(() => {
    if (userId && !studentInfo) {
      getStudentsData();
    }
  }, [userId, dates]);

  useEffect(() => {
    if (attendanceType) {
      if (attendanceType.value === 0) {
        getDayWiseAttendaceData();
        setcurrentSlotWiseAttendanceDataList([]);
      } else {
        getSlotsAttendanceData();
        setCalendarData([]);
      }
    }
  }, [studentInfo, attendanceType, dates]);

  if (!isAttendanceSettingConfigured) {
    return (
      <Fragment>
        <MobileHeader
          label={t('attendance.label')}
          backIcon={false}
          {...props}
        />
        <AttendanceNotConfigured type={0} />
      </Fragment>
    );
  }
  return (
    <>
      <Fragment>
        <MobileHeader
          label={t('attendance.label')}
          backIcon={false}
          {...props}
        />
        <View style={styles.container}>
          {!attendanceType ? (
            <View style={styles.spinnerView}>
              <LoaderSpinner />
            </View>
          ) : (
            <View style={[styles.webWrapper]}>
              <View style={styles.flexCenterView}>
                <AnimatedCircularProgress
                  style={{ marginRight: 16 }}
                  size={80}
                  width={3}
                  fill={(totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) * 100}
                  tintColor="#2B78CA"
                  backgroundColor="transparent">
                  {(fill) => (
                    <View
                      style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <SmallTextAtom
                        value={t('present.label')}
                        color={colors.primary}
                        fontWeight={'600'}
                      />
                      <MediumTextSelect
                        value={`${(
                          (totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) *
                          100
                        ).toFixed(0)}%`}
                        color={colors.primary}
                        fontWeight={'600'}
                        lineHeight={2.4}
                      />
                    </View>
                  )}
                </AnimatedCircularProgress>
                <View
                  style={{
                    flexDirection: 'column',
                    flex: 1,
                  }}>
                  <View
                    style={styles.attendanceTypeStyle}>
                    <MediumTextSelect
                      lines={1}
                      value={namingSlotsInfo()}
                      color={colors.primaryText}
                      fontWeight={'600'}
                      lineHeight={2.4}
                    />
                  </View>
                  <View style={styles.flexCenterView}>
                    <DateTimePicker
                      dateTimePickerTitle={t("form.duration.label")}
                      dateTimeMode={"monthyear"}
                      standalonePicker={true}
                      onDateTimeChangeCallback={(selectedDate: Date) => {
                        dateChangeHandler(selectedDate)
                      }}
                    />
                    <NormalTextSelect
                      value={dateString}
                      fontWeight={'600'}
                      color={colors.tertiaryText}
                      style={styles.monthDisplayText}
                    />
                  </View>
                </View>
              </View>

              {!calendarData || !currentSlotWiseAttendanceDataList || !studentInfo ? (
                <View style={styles.spinnerView}>
                  <LoaderSpinner />
                </View>
              ) : noDataHandler() ? (
                <AttendanceNotConfigured topPosition="20%" type={1} />
              ) : (
                <CalendarContainer>
                  {attendanceType.value === 0 &&
                    calendarData &&
                    calendarData?.length === 1 &&
                    calendarData[0]?.data &&
                    dates?.startDate ? (
                    <LeaveCalendarWrapper>
                      <LeaveCalendar
                        initialDate={parse(dates.startDate, DT.DATE_FORMAT_SLASH, new Date())}
                        markedDates={generateMarkedDatesObject(
                          filterAbsentDates(calendarData[0].data)
                        )}
                      />
                    </LeaveCalendarWrapper>
                  ) : (
                    <ListNative
                      batch={studentInfo.batch}
                      listData={currentSlotWiseAttendanceDataList}
                      navigation={navigation}
                      totalPresents={totalPresents}
                      totalAttendance={totalAttendance}
                      classInfo={{ value: studentInfo?.classId, label: studentInfo?.className }}
                      division={studentInfo?.division}
                      attendanceType={attendanceType}
                      audienceType={audienceType}
                      selectedDurationType={selectedDurationType}
                      selectedStudentInfo={{
                        value: studentInfo?.userId,
                        label: properCase(studentInfo?.fullName),
                      }}
                      shouldNavigate={true}
                      routeName={navigationStrings.ATTENDANCE_HISTORY}
                      datesinfo={dates}
                      type={1}
                      listContainerHeightAndroid={height - 225}
                      listContainerHeightIos={height - 235}
                    />
                  )}
                </CalendarContainer>
              )}
            </View>
          )}
        </View>
      </Fragment>
    </>
  );
}

const LeaveCalendarWrapper = styled.View``;

const CalendarContainer = styled.View`
  margin-top: 16px;
`;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: '100%'
  },
  webWrapper: {
    margin: 24,
    height: isIOS ? height - 184 : height - 60,
  },
  spinnerView: {
    width: isWeb ? 450 : 'auto',
    height: isWeb ? 547 : height - 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexCenterView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  monthDisplayText: {
    marginLeft: 8,
    marginTop: isIOS ? 2 : -26,
  },
  attendanceTypeStyle: {
    flexDirection: 'row',
    paddingRight: 4,
    paddingTop: isIOS ? 0 : 8
  }
});
