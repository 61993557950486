import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import EditPaymentStatusForm from '../../../../form-json/payment/edit-payment-staus.json';
import Element from '../../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components/native';
import { PAYMENT_MODE_LABEL, PaymentMode } from 'src/constant';
import { paymentModeOptions } from './options';
import { CustomReactNativeFile } from '../../FileUpload/type';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { checkFields, resetPaymentFormValues } from './utility';

const EditPaymentForm = (props: any) => {
  const {
    control,
    errors,
    reset,
    setValue,
    clearErrors,
    totalPayableAmount,
    paymentModes,
    isEdit = false,
    editPaymentDetails,
  } = props;
  const [elements, setElements] = useState<any>({});
  const [dropDownValue, setDropDownValue] = useState('');
  const [fileObjects, setFileObjects] = useState<any>({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    return () => {
      setDropDownValue('');
      reset();
      EditPaymentStatusForm[0].fields[0].option = paymentModeOptions;
      EditPaymentStatusForm[0].fields[9].textHead = 'Bank*';
      EditPaymentStatusForm[0].fields[9].required = true;
      EditPaymentStatusForm[0].fields[9].required_error = 'BANK_NAME_REQUIRED';
      EditPaymentStatusForm[0].fields[1].disabled = false;
    };
  }, []);

  useEffect(() => {
    if (paymentModes && paymentModes?.length > 0) {
      const modes = paymentModes?.map((item: string) => {
        return {
          label: PAYMENT_MODE_LABEL[item],
          value: item,
        };
      });
      EditPaymentStatusForm[0].fields[0].option = modes;
    } else {
      EditPaymentStatusForm[0].fields[0].option = paymentModeOptions;
    }

    if (isEdit && editPaymentDetails) {
      handleEditFormValues();
    } else {
      setElements(EditPaymentStatusForm[0]);
    }
  }, [paymentModes]);

  function handleEditFormValues() {
    const mode = editPaymentDetails?.mode;
    const paymentDetails = editPaymentDetails?.detail;
    setDropDownValue(mode);
    resetValues(mode);

    setValue('mode', {
      label: PAYMENT_MODE_LABEL[mode],
      value: mode,
    });
    setValue('date', format(new Date(paymentDetails?.paymentDate), DT.DATE_FORMAT_SLASH));

    if (mode !== PaymentMode.CASH) {
      if (paymentDetails?.payerBank) setValue('bank_name', paymentDetails.payerBank);
    }

    if (totalPayableAmount) setValue('payment_amount', totalPayableAmount);

    if (
      paymentDetails?.transactionDoc &&
      Object.keys(paymentDetails.transactionDoc)?.length > 0
    ) {
      setValue('payment_proof', paymentDetails.transactionDoc);
      maintainFileObject(paymentDetails.transactionDoc, 'payment_proof');
    }

    if (mode !== PaymentMode.ONLINE && paymentDetails?.note) {
      setValue('note', paymentDetails.note);
    }

    switch (mode) {
      case PaymentMode.CHEQUE:
        if (paymentDetails?.payId) setValue('cheque_number', parseInt(paymentDetails.payId));
        break;
      case PaymentMode.DRAFT:
        if (paymentDetails?.payId) setValue('draft_number', parseInt(paymentDetails.payId));
        break;
      case PaymentMode.OFFLINE_NEFT:
        if (paymentDetails?.payId) setValue('neft_transaction_id', paymentDetails.payId);
        break;
      case PaymentMode.LOAN:
        if (paymentDetails?.payId) setValue('loan_account_id', paymentDetails.payId);
        break;
      case PaymentMode.DONATION:
        if (paymentDetails?.payId) setValue('transaction_id', paymentDetails.payId);
        if (paymentDetails?.donatedBy) setValue('donated_by', paymentDetails.donatedBy);
        if (paymentDetails?.donatedTo) setValue('donated_to', paymentDetails.donatedTo);
        break;
    }
  }

  function resetValues(mode: string) {
    setElements({});
    setFileObjects({});
    clearErrors();

    if (mode === PaymentMode.DONATION) {
      EditPaymentStatusForm[0].fields[9].textHead = 'Bank';
      EditPaymentStatusForm[0].fields[9].required = false;
    } else if (mode === PaymentMode.LOAN) {
      EditPaymentStatusForm[0].fields[9].textHead = 'Lender Name*';
      EditPaymentStatusForm[0].fields[9].required = true;
      EditPaymentStatusForm[0].fields[9].required_error = 'LENDER_NAME_REQUIRED';
    } else {
      EditPaymentStatusForm[0].fields[9].textHead = 'Bank*';
      EditPaymentStatusForm[0].fields[9].required = true;
      EditPaymentStatusForm[0].fields[9].required_error = 'BANK_NAME_REQUIRED';
    }
    if (isEdit) EditPaymentStatusForm[0].fields[1].disabled = false;
    setElements(EditPaymentStatusForm[0]);
  }

  const handleCustomSelect = (id: string, data: any) => {
    setDropDownValue(data?.value);
    resetValues(data?.value);
    resetPaymentFormValues(setValue);
    if (data?.value === PaymentMode.ONLINE) {
      setValue('payment_amount', '');
    } else {
      setValue('payment_amount', totalPayableAmount);
    }
  };

  const handleFilesAttach = (id: any) => {
    return (files: CustomReactNativeFile[]) => {
      if (files?.length > 0) {
        let file: any = files[0];
        setValue(id, file);
        maintainFileObject(file, id);
        clearErrors('contentImage');
      }
    };
  };

  function maintainFileObject(data: object, id: string) {
    let fileobj = { ...fileObjects };
    fileobj[id] = data;
    setFileObjects(fileobj);
  }

  const handleFileRemove = (id: string) => {
    return (file: CustomReactNativeFile) => {
      setValue(id, {});
      maintainFileObject({}, id);
    };
  };

  return (
    <View>
      {fields ? (
        fields.map(
          (field: any, i: number) =>
            (field?.id === 'mode' || checkFields(field?.id, dropDownValue, true)) && (
              <ElementView>
                <Element
                  key={i}
                  field={field}
                  control={control}
                  errors={errors}
                  handleCustomSelect={handleCustomSelect}
                  file={fileObjects[field.id]}
                  handleFilesAttach={handleFilesAttach(field.id)}
                  handleFileRemove={handleFileRemove(field.id)}
                  fileWidth={444}
                />
              </ElementView>
            ),
        )
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </View>
  );
};

const SpinnerView = styled.View`
  width: 450px;
  height: 547px;
  justify-content: center;
  align-items: center;
`;

const ElementView = styled.View`
  display: flex;
`;

export default EditPaymentForm;
