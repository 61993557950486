import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../../styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { FormViewerPropType } from '../types';
import { width } from 'src/constant/device';
import HeaderFour from 'src/components/atoms/Text/HeaderFour';

const FormViewer: React.FC<FormViewerPropType> = ({
  schema,
  isOnboarding,
  inModalView,
  paddingRight = 0
}) => {
  const { renderForm } = useFormApi();
  const { t } = useI18n();

  return (
    <>
      <FormBlock isModelView={inModalView} width={width} paddingRight={paddingRight}>
        {
          (!inModalView && isOnboarding && schema.name !== 'otherDetails') &&
          <FormHeading>
            {schema && <HeaderFour value={t(schema.label)} />}
          </FormHeading>
        }
        {
          schema && schema?.fields?.map((field) => {
            return renderForm([field])
          })
        }
      </FormBlock>
      {
        isOnboarding && schema && schema?.outerFields?.map((field) => {
          return renderForm([field])
        })
      }
    </>
  )
}

export default FormViewer;

const FormBlock = styled.div<{ paddingRight: number; isModelView?: boolean; width?: number; }>`
  width: ${({ isModelView, width }) =>
    width && breakpoints && width <= breakpoints.sm ? '100%' : !!isModelView ? '100%' : '70%'};
  padding-right: ${({ paddingRight }) => `${paddingRight}px`};
  box-sizing: border-box;
  @media all and (max-width: 991px) {
    padding-right: 0;
    order: 2;
  }
`;

const FormHeading = styled.div`
  margin-bottom: 24px;
`;