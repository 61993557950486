import React, { useEffect, useState } from 'react';
import CheckBox from '@react-native-community/checkbox';
import MediumText from '../Text/MediumText';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import { isIOS } from 'src/constant/device';
import styled from 'styled-components/native';

export default function Check(props: any) {
  const { onChange, checked, header, bottomM = 24, same = true, marginTopOffset = 0, testID, displayCentered } = props;
  const [isChecked, setIsChecked] = useState(checked);
  const [updateStyle, setUpdateStyle] = useState({
    update: false,
    value: { transform: [{ scaleX: isIOS ? 0.8 : 1 }, { scaleY: isIOS ? 0.8 : 1 }] },
  });
  const { t } = useI18n();

  function handleCheckbox() {
    setIsChecked((prev: any) => {
      onChange(!prev);
      return !prev;
    });
  }

  useEffect(()=>{
    setIsChecked(checked);
  },[checked])

  return (
    <ContainerView displayCentered={displayCentered}>
      <CheckBox
        testID={testID}
        disabled={false}
        value={same ? isChecked : checked}
        onValueChange={() => {
          handleCheckbox();
          setUpdateStyle({ ...updateStyle, update: true });
        }}
        style={{ ...updateStyle.value, marginTop: isIOS ? 2 : -2 - marginTopOffset }}
        // tintColors is Android Only Prop
        tintColors={{ true: colors.primaryColor }}
        // iOS Only Props
        onFillColor={colors.primaryColor}
        onCheckColor={colors.white}
        animationDuration={0.3}
        boxType="square"
      />
      {header && (
        <HeadView>
          <MediumText value={t(header)} />
        </HeadView>
      )}
    </ContainerView>
  );
}

const ContainerView = styled.View<{ displayCentered: boolean }>`
  flex-direction: row;
  margin-left: 2px;
  margin-bottom: ${({ displayCentered }) => displayCentered ? "24px" : "0px"};
`;

const HeadView = styled.View`
  align-self: center;
  margin-left: 8px;
`;
