/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { useQuery } from '@apollo/client';
import React, { Fragment, useEffect, useState } from 'react';
import { View } from 'react-native';
import { BarChart, Grid, YAxis } from 'react-native-svg-charts';
import { getGradesQuery } from 'src/graphql/grades/grades';
import SectionHeading from '../SectionHeading';

const axesSvg = { fontSize: 9, fill: 'black' };

export const PerformanceAnalysis = ({ studentId }: any) => {
  const [localGrades, setGrades] = useState<any>([]);
  const [yAxisValue, setYAxisValue] = useState<any>([]);
  const { data: grades, loading: gradeLoading } = useQuery<any>(getGradesQuery, {
    variables: {
      query: {
        by: 'YEAR_WISE',
        student: studentId,
      },
    },
  });

  useEffect(() => {
    if (grades !== undefined && grades.averageGrades.length > 0) {
      grades.averageGrades[0].forEach((g: any) => {
        const newgObject: any = {};
        newgObject.value = g.average;
        newgObject.svg = {
          fill: 'rgba(153, 86, 200, 1)',
        };
        setYAxisValue([...yAxisValue, g.average]);
        setGrades([...localGrades, newgObject]);
      });
    }
  }, [grades]);

  return (
    <Fragment>
      <SectionHeading textLeft={'Overall Progress'} marginBottom={0} marginTop={20} />
      {!gradeLoading && localGrades.length > 0 && (
        <View
          style={{
            marginTop: 20,
            height: 160,
            width: '95%',
            flexDirection: 'row',
            marginBottom: 50,
          }}>
          <YAxis
            data={yAxisValue}
            style={{
              flexGrow: 0,
              height: 180,
            }}
            svg={axesSvg}
            contentInset={{ top: 20, bottom: 3 }}
            formatLabel={(value) => value}
            numberOfTicks={5}
          />

          <View style={{ flex: 1, marginLeft: 5, bottom: 0 }}>
            <BarChart
              style={{
                height: 180,
                borderLeftWidth: 1,
                borderLeftColor: '#4C6EF5',
                borderBottomWidth: 1,
                borderBottomColor: '#4C6EF5',
              }}
              data={localGrades}
              gridMin={0}
              numberOfTicks={5}
              spacingInner={0.5}
              spacingOuter={0.6}
              svg={{ fill: 'rgba(134, 65, 244, 0.8)' }}
              yAccessor={({ item }) => item.value}
              contentInset={{ top: 20, bottom: 0 }}>
              <Grid
                svg={{
                  fill: 'red',
                }}
                direction={'HORIZONTAL'}
              />
            </BarChart>
          </View>
        </View>
      )}
    </Fragment>
  );
};
