import { UserType } from 'src/constant';

export const getUpdateApi = (userType: UserType) => {
  switch (userType) {
    case UserType.INSTITUTE_EMPLOYEE:
      return 'updateInstituteEmployeeDetail';
    case UserType.INSTITUTE_STUDENT:
      return 'updateInstituteStudentDetail';
  }
};

export const isFormValuesEmpty = (formSchemaName: string, values: any) => {
  switch (formSchemaName) {
    case 'parentDetails':
      return (
        (!values?.fatherDetail && !values?.motherDetail) ||
        (parentDetailFields.every(prop => values?.fatherDetail?.[`${prop}`] == null) &&
          parentDetailFields.every(prop => values?.motherDetail?.[`${prop}`] == null))
      );
    case 'educationDetails':
      return educationDetailFields.every(prop => values[prop] == null);
    case 'otherDetails':
      return otherDetailFields.every(prop => values[prop] == null);
  }
};

const parentDetailFields = [
  'aadhaarId',
  'annualIncome',
  'dob',
  'documents',
  'email',
  'mobileNumber',
  'name',
  'occupation',
  'officeAddress',
  'qualification',
];

const educationDetailFields = [
  'course',
  'institution',
  'marksObtained',
  'paassingYear',
  'passedInFirstAttempt',
  'scoreType',
  'streams',
  'totalMarks',
  'university',
  'gradeObtained',
];

const otherDetailFields = [
  'aadhaarId',
  'abcId',
  'caste',
  'category',
  'documents',
  'isPhysicallyChallenged',
  'physicallyChallengedDescription',
  'languageSpoken',
  'motherTongue',
  'nationality',
  'religion',
  'saralId',
];
