import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { breakpoints, colors } from 'src/styles/theme/styles';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { isWeb, width } from 'src/constant/device';
import SmallTextAtom from '../Text/SmallTextAtom';

interface propType {
  onPress: () => void;
  label: string;
  style?: object;
  color?: string;
  testID?: string;
}

export default function AddButton(props: propType) {
  const { onPress, label = 'Add', style, color = colors.white, testID } = props;
  return isWeb && width > breakpoints.md ? (
    <TouchableOpacity onPress={onPress} activeOpacity={1}>
      <View style={[styles.container, style]}>
        <View style={{ alignSelf: 'center' }}>
          <FontAwesomeIcon icon={faPlus} color={colors.white} size={12} />
        </View>
        <View style={{ marginLeft: 8, alignSelf: 'center' }}>
          <NormaltextAtom value={label} color={color} />
        </View>
      </View>
    </TouchableOpacity>
  ) : (
    <TouchableOpacity onPress={onPress} activeOpacity={1} testID={testID}>
      <View style={[styles.view]}>
        <View style={styles.nativeWrapper}>
          <FontAwesomeIcon icon={faPlus} color={colors.secondaryText} size={14} />
          <View style={{ marginTop: 6 }}>
            <SmallTextAtom value={label} color={colors.primaryText} />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: colors.primary,
    borderColor: colors.checkRadioOpt,
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: 'row',
  },
  view: {
    height: 80,
    borderWidth: 2,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: colors.lighterBorder,
    borderRadius: 4,
  },
  nativeWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
