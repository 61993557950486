import { gql, useMutation } from '@apollo/client';

const submitChangesForReviewMutation = gql`
  mutation {
    submitChangeInDetailForReview {
      message
      status
    }
  }
`;

export function useSubmitChangesForReview() {
  const [submitForReview] = useMutation(submitChangesForReviewMutation);
  return { submitForReview };
}

const approveChangesMutation = gql`
  mutation($detail: UserDetailDraftInput!) {
    approveChangeInDetail(detail: $detail) {
      message
      status
    }
  }
`;

export function useApproveChanges() {
  const [approveChanges] = useMutation(approveChangesMutation);
  return { approveChanges };
}

const approveAllChangesMutation = gql`
  mutation($detail: UserInput!) {
    approveAllChangeInDetail(detail: $detail) {
      message
      status
    }
  }
`;

export function useApproveAllChanges() {
  const [approveAllChanges] = useMutation(approveAllChangesMutation);
  return { approveAllChanges };
}

const rejectChangesMutation = gql`
  mutation($detail: UserDetailDraftInput!) {
    rejectChangeInDetail(detail: $detail) {
      message
      status
    }
  }
`;

export function useRejectChanges() {
  const [rejectChanges] = useMutation(rejectChangesMutation);
  return { rejectChanges };
}
