import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  faBan,
  faCheckCircle,
  faClipboardCheck,
  faExchange,
  faFileExport,
  faHandHoldingUsd,
  faListAlt,
  faTimesCircle,
  faFileInvoiceDollar,
  faEnvelope,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { ClickAwayListener, TableBody, Tooltip } from '@mui/material';
import { TouchableOpacity } from 'react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import {
  ExpandCollapseCell,
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  AdmissionStatus,
  AdmissionStatusDropdownOptions,
  APPLICANT_TYPE_ENUM,
  ApplicationStatus,
  ApplicationStatusLabel,
  AUTOCOMPLETE_MODULE,
  ERROR,
  limit,
  SEARCH_TIME,
  SUCCESS,
} from 'src/constant';
import { height } from 'src/constant/device';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useI18n } from 'src/i18n/hooks';
import { useHistory, useParams } from 'src/routes/routing.web';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { useFilter } from 'src/contexts/filter-context';
import { debounce } from 'src/components/services';
import { Icon } from 'src/components/atoms/Icon/Icon';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useQuery } from '@apollo/client';
import {
  getAdmissionByIdQuery,
  useGenerateAdmissionReports,
  useTransferApplication,
  useSendCustomEmail,
} from 'src/graphql/admission';
import {
  useGetApplications,
  useQualifyApplications,
  useRequestPayment,
  useSchedulePayment,
  useUnBlockApplication,
  useImportApplicant,
  useCompleteAdmission,
  useCancelAdmission,
  useApplyConcession,
  useImportCAPApplicants,
  useGenerateMeritList,
  useImportInhouseApplicants,
  useInviteCAPApplicants,
  useReconcileAdmissionPayments,
  useUpdateScrollRefNo,
  useCancelAdmissionWithCharges,
  useApplyFeeComposition,
  useInviteDirectApplicants,
  useImportDirectApplicants,
  lastApplicationReconcilePaymentRequest,
} from 'src/graphql/admission/application';
import { CellExpand } from 'src/components/molecules/Table/index.web';
import {
  checkRemark,
  clipLongText,
  createPaymentDetailObject,
  downloadFileRestAPI,
  getCellStyle,
  properCase,
} from 'src/utils/utility';
import { ADD_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import ApplicationPayableListing from '../Applications/ApplicationPayableListing';
import { useForm } from 'react-hook-form';
import EditPaymentForm from 'src/components/molecules/Payment/Institute/EditPaymentForm';
import ERROR_MSG from 'src/constant/error';
import { useCreateAdmissionPayment } from 'src/graphql/applications/applications';
import {
  faCaretDown,
  faDownload,
  faUpload,
  faUserPlus,
  faUsersClass,
  faFilePlus,
} from '@fortawesome/pro-solid-svg-icons';
import { DropdownOptions, DropdownOptionsType } from 'src/types';
import { graphqlQuery } from 'src/graphql/util';
import { getApplicationFeeBookQuery, useQueryFetchConcessions } from 'src/graphql/payment';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import {
  EditIcon,
  InfoSquareIcon,
  OverflowMenuIcon,
  SettingsIcon,
  TransferIcon,
  ViewFileIcon,
} from 'src/components/atoms/ActionIcons';
import SelectConcessionForm from 'src/components/molecules/Admission/SelectConcessionForm';
import { ConcessionOptionsType, documentObject, paymentFormData, paymentPayload } from './types';
import {
  CancelAdmissionFormValues,
  InviteCapApplicantFormValues,
  InviteDirectApplicantFormValues,
} from 'src/components/molecules/Admission/types';
import { FeeDetail } from '../Payment/types';
import Badge from 'src/components/atoms/Badge';
import BlockRejectionReason from 'src/components/molecules/Admission/BlockRejectionReason';
import ButtonGrp from 'src/components/atoms/ButtonGroup/index.web';
import Filter from 'src/components/molecules/Filter/index.web';
import MultiChipsDisplay from 'src/components/atoms/Chip/MultiChipsDisplay';
import { APPLICATION_STATUS_ENUM, ApplicationStatusLabelKeyVal } from './filter-options';
import SearchInviteCapApplicantForm from 'src/components/molecules/Admission/SearchInviteCapApplicantForm';
import Chip from 'src/components/atoms/Chip';
import { getLastUpdatedDateTime } from './helpers';
import NestedApplicantDetail from './NestedApplicantDetail.web';
import { getPaymentStatusColor } from './helpers';
import CancelAdmissionForm from 'src/components/molecules/Admission/CancelAdmissionForm';
import EditScrollRefForm from 'src/components/molecules/Admission/EditScrollRefForm';
import TransferForm from 'src/components/molecules/Admission/TransferForm';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import ChangeFeeCollectionForm from 'src/components/molecules/Admission/ChangeFeeCollectionForm';
import { ApplicationItemType } from '../Applications/types';
import ExportReportForm from 'src/components/molecules/Payment/Institute/ExportReportForm';
import { faFileExport as faFileExportSolid } from '@fortawesome/pro-solid-svg-icons';
import MessageComposer from 'src/components/molecules/Message/MessageComposer';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { format, isBefore } from 'date-fns';
import { DT, parseToDate } from 'src/constant/dateTime';
import PaymentDetailsModal from './PaymentDetailsModal';
import DirectApplicantInviteForm from 'src/components/molecules/Admission/DirectApplicantInviteForm';
import ImportDirectApplicationsForm from 'src/components/molecules/Admission/ImportDirectApplicationsForm';
import ReconcilePaymentForm from 'src/components/molecules/Admission/ReconcilePaymentForm';
import { ReconcilePaymentData } from '../Courses/BatchStudents/types';
import { resetPaymentFormValues } from 'src/components/molecules/Payment/Institute/utility';
import { ApplicationDetailsAdmission } from '../Applications/ApplicationDetailsAdmission';
import { AdmissionForm } from 'src/components/molecules/Admission/AdmissionForm/index.web';
import { useGenerateLottery, useGetLotteryDetails } from 'src/graphql/admission/lottery';
import { LotteryHelpers } from './Lottery/LotteryHelpers';
import { defaultPagingURLSegment } from 'src/components/molecules/Navigation/SidebarManifest';
import {
  TAdmissionConfig,
  TAdmissionDetailFormState as TForm,
} from './AdmissionDetail/AdmissionDetailTypes';
import { AdmissionLinks } from 'src/components/molecules/Admission/AdmissionDetailComponents/Links/AdmissionLinks.web';
import AcceptApplicationForm from 'src/components/molecules/Admission/AdmissionDetailComponents/AcceptApplication/AcceptApplicationForm.web';
import RejectBlockApplicationForm from 'src/components/molecules/Admission/AdmissionDetailComponents/RejectBlockApplication/RejectBlockApplicationForm.web';
import PartialFeePayment, {
  TPartialFeePaymentData,
} from '../Payment/PartialFeePayment/PartialFeePayment.web';
import ManageRounds from 'src/components/molecules/Admission/AdmissionDetailComponents/Rounds/index.web';
import AssignDivisionForm from 'src/components/molecules/Admission/AdmissionDetailComponents/AssignDivisionForm/index.web';

const headCells1 = [
  {
    id: 'applicant_name',
    label: 'applicantName.label',
    align: 'left',
    disableSorting: true,
    style: {
      minWidth: 160,
      paddingLeft: '51px',
    },
  },
  {
    id: 'scrollRefNo',
    label: 'Scroll',
    align: 'right',
    disableSorting: true,
    style: {
      minWidth: 64,
    },
  },
  {
    id: 'application_id',
    label: 'applicationId.label',
    align: 'left',
    disableSorting: true,
    style: {
      minWidth: 160,
    },
  },
  {
    id: 'status',
    label: 'status.label',
    align: 'left',
    disableSorting: true,
    style: {
      minWidth: 208,
    },
  },
  {
    id: 'payment_status',
    label: 'paymentStatus.label',
    align: 'left',
    disableSorting: true,
    style: {
      minWidth: 176,
    },
  },
  {
    id: 'documents',
    label: 'documents.label',
    align: 'center',
    disableSorting: true,
    style: {
      minWidth: 104,
    },
  },
  {
    id: 'lastUpdated',
    label: 'last-updated.label',
    align: 'right',
    disableSorting: true,
    style: {
      minWidth: 160,
    },
  },
  {
    id: 'actions',
    label: 'actions.label',
    align: 'right',
    disableSorting: true,
    style: {
      minWidth: 125,
    },
  },
];

const capHeadCell = {
  id: 'cap_id',
  label: 'cap-id.label',
  align: 'left',
  disableSorting: true,
  style: {
    minWidth: 160,
  },
};

const filterOptionsStatus = [
  { id: 1, label: 'includes', type: 'string', filteringCriteria: 'in' },
  { id: 2, label: 'not includes', type: 'string', filteringCriteria: 'nin' },
];

const filterItems = [
  {
    id: 1,
    label: 'Applicant Type',
    type: 'string',
    localField: 'applicantType',
    filterType: 'filter',
    dynamicCheckboxes: true,
    filterOptions: filterOptionsStatus,
    acceptableCheckboxValues: APPLICANT_TYPE_ENUM,
  },
  {
    id: 2,
    label: 'Applicant Status',
    type: 'string',
    localField: 'status',
    filterType: 'filter',
    dynamicCheckboxes: true,
    filterOptions: filterOptionsStatus,
    acceptableCheckboxValues: APPLICATION_STATUS_ENUM,
  },
  {
    id: 3,
    label: 'Merit',
    type: 'string',
    localField: 'qualifiedRound',
    propName: 'name',
    filterType: 'lookup',
  },
  {
    id: 4,
    label: 'Quota',
    type: 'string',
    localField: 'quota',
    propName: 'name',
    filterType: 'lookup',
  },
  {
    id: 5,
    label: 'Applications',
    type: 'dateRange',
    localField: 'updatedAt',
    filterType: 'filter',
  },
];

const { showRegenerateLabel, LotteryStatus } = LotteryHelpers;

export default function AdmissionDetail() {
  const { theme } = useThemeSystem();
  const history = useHistory();
  const params = useParams<{ admissionId: string }>();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { filters, setFilter } = useFilter();
  const admissionId = params?.admissionId;
  let row;

  let searchValue = '' as string;
  const { page = 1, dataLimit = limit }: any = useParams();

  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const [totalCount, setTotalCount] = useState(null);

  if (filters && filters?.applicant?.on) {
    searchValue = filters?.applicant?.search;
  }

  // Use this as Primary Form state while refactoring
  const [form, setForm] = useState<TForm>({ state: 'CLOSE' });

  const [trow, setT] = useState<boolean>(false);
  const [current, setCurrent] = useState<number | null>(null);
  const [searching, setSearchingApplicant] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [canClick, setCanClick] = useState(true);
  const [editScrollRefModal, handleEditScrollRefModal] = useState<boolean>(false);
  const [transferApplModal, handleTransferApplModal] = useState<boolean>(false);
  const [sendMessageModal, handleSendMessageModal] = useState<boolean>(false);
  const [subjGrpsModalState, setSubjGrpsModalState] = useState<boolean>(false);
  const [qualModalState, setQualModalState] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState('');
  const [makePaymentModal, setMakePaymentModal] = useState<boolean>(false);
  const [paymentModalState, handlePaymentModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentFeeDetails, setCurrentFeeDetails] = useState<FeeDetail>();
  const [concessionModal, handleConcessionModal] = useState<boolean>(false);
  const [remarkModal, setRemarkModal] = useState<boolean>(false);
  const [concessionDataAvailable, setConcessionDataAvailable] = useState<boolean>(false);
  const [concessionOptions, setConcessionOptions] = useState<ConcessionOptionsType[]>();
  const concessionCompositionId = useRef<string>('');
  const [referrerViewModal, setReferrerViewModalModal] = useState<boolean>(false);
  const [dropDown, setDropDown] = useState<boolean>(false);
  const [currFilter, setCurrFilter] = useState<any>();
  const [filterHeight, setFilterHeight] = useState(0);
  const [inviteCapApplicant, setInviteCapApplicant] = useState<boolean>(false);
  const [inviteDirectApplicant, setInviteDirectApplicant] = useState<boolean>(false);
  const [partialPayment, setPartialPayment] = useState<number | null>(null);
  const [cancelAdmissionModal, setCancelAdmissionModal] = useState<boolean>(false);
  const [disableCancelModal, setDisableCancelModal] = useState<boolean>(false);
  const [paymentRefundModal, setPaymentRefundModal] = useState<boolean>(false);
  const [refundAmount, setRefundAmount] = useState<number>(0);
  const [cancellationAmount, setCancellationAmount] = useState<number | null>(null);
  const [completeRefundModalState, setCompleteRefundModalState] = useState<boolean>(false);
  const [changeFeeCollectionModal, setChangeFeeCollectionModal] = useState<boolean>(false);
  const [cancellationCharge, setCancellationCharge] = useState<number | null>(null);
  const [exportReportModal, setExportReportModal] = useState<boolean>(false);
  const [schedulePaymentModal, setSchedulePaymentModal] = useState<boolean>(false);
  const OverflowMenuDivRef = useRef<HTMLDivElement | null>(null);
  const actionIcons = useRef({});
  const remarkRef = useRef(null);
  const referrer = useRef(null);
  const infoDivRef = useRef(null);
  const [paymentIconsDropDown, setPaymentIconsDropDown] = useState<boolean>(false);
  const [admissionIconsDropdown, setAdmissionIconsDropdown] = useState<boolean>(false);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState<boolean>(false);
  const [paymentIds, setPaymentIds] = useState<string[]>([]);
  const [importDirectApplicantsModal, setImportDirectApplicantsModal] = useState<boolean>(false);
  const [importAttachment, handleImportAttachment] = useState<{
    documentName?: String;
    document?: File | undefined;
  }>({});
  const [reconcilePaymentModal, setReconcilePaymentModal] = useState<boolean>(false);
  const [confirmSelection, setConfirmSelection] = useState<boolean>(false);
  const [reconcilePaymentData, setReconcilePaymentData] = useState<ReconcilePaymentData | null>(
    null,
  );
  const [editPaymentModal, setEditPaymentModal] = useState<boolean>(false);
  const [viewApplication, setViewApplication] = useState<boolean>(false);
  let { setAlertDetails } = useAlertSystem();
  const {
    data: admissionData,
    refetch,
    loading: admissionDataLoading,
  } = useQuery(getAdmissionByIdQuery, {
    variables: { id: admissionId },
    fetchPolicy: 'network-only',
  });

  const { getApplications, applicationsData, applicationsLoading, refetchApplications } =
    useGetApplications();
  const { unblockApplication } = useUnBlockApplication();
  const { requestPayment } = useRequestPayment();
  const { schedulePayment } = useSchedulePayment();
  const { createAdmissionPayment } = useCreateAdmissionPayment();
  const { completeAdmission } = useCompleteAdmission();
  const { qualifyApplications } = useQualifyApplications();
  const { importApplicants } = useImportApplicant();
  const { importCAPApplicants } = useImportCAPApplicants();
  const { importInhouseApplicants } = useImportInhouseApplicants();
  const { importDirectApplicants } = useImportDirectApplicants();
  const { cancelAdmission } = useCancelAdmission();
  const { applyConcession } = useApplyConcession();
  const {
    query: getConcessions,
    data: concessionsCompositionData,
    loading,
  } = useQueryFetchConcessions();
  const { generateMeritList } = useGenerateMeritList();
  const { inviteCAPApplicants } = useInviteCAPApplicants();
  const { inviteDirectApplicants } = useInviteDirectApplicants();
  const { reconcileAdmissionPayment } = useReconcileAdmissionPayments();
  const { updateApplicationScrollRefNo } = useUpdateScrollRefNo();
  const { transferApplication } = useTransferApplication();
  const { sendCustomEmail } = useSendCustomEmail();
  const { cancelAdmissionWithCharges } = useCancelAdmissionWithCharges();
  const { applyFeeComposition } = useApplyFeeComposition();
  const { generateAdmissionReports, generateAdmissionReportsError } = useGenerateAdmissionReports();
  const { getLotteryDetails, lotteryData, lotteryRefetch } = useGetLotteryDetails();
  const { generateLottery } = useGenerateLottery();

  row = applicationsData?.applications?.data;
  const admissionDetail = admissionData?.admission;
  const isCAPTypeAllowed: boolean =
    admissionDetail?.allowedApplicantType &&
    admissionDetail.allowedApplicantType?.includes(APPLICANT_TYPE_ENUM[1]);
  const isDirectTypeAllowed: boolean =
    admissionDetail?.allowedApplicantType &&
    admissionDetail.allowedApplicantType?.includes(APPLICANT_TYPE_ENUM[0]);
  const isINHOUSETypeAllowed: boolean = !!admissionDetail?.inHouseApplicantAllowed;
  const isFilterPresent =
    currFilter && currFilter?.filters && Object.keys(currFilter?.filters).length > 0;
  const isAdmissionOpen = admissionDetail && admissionDetail?.status == AdmissionStatus.OPEN;
  const isPublicAdmission: boolean = !!admissionData?.admission?.publicAvailability;

  const isLotteryAdmission: boolean =
    Array.isArray(admissionDetail?.allowedApplicantType) &&
    admissionDetail.allowedApplicantType?.includes(APPLICANT_TYPE_ENUM[3]);

  const admissionHasApplications: boolean = !!(totalCount && totalCount > 0);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  const {
    handleSubmit: handleSubmitCAP,
    control: controlCAP,
    formState: { errors: errorsCAP },
    reset: resetCAP,
    setValue: setValueCap,
    clearErrors: clearErrorsCap,
    setError: setErrorCap,
    getValues: getValuesCap,
    watch: watchValuesCap,
  } = useForm({ mode: 'all' });

  const {
    handleSubmit: handleSubmitDIRECT,
    control: controlDIRECT,
    formState: { errors: errorsDIRECT },
    setValue: setValueDIRECT,
    clearErrors: clearErrorsDIRECT,
  } = useForm();

  const {
    handleSubmit: handleSubmitCancelAdmission,
    control: controlCancelAdmission,
    formState: { errors: errorsCancelAdmission },
    reset: resetCancelAdmission,
    setValue: setValueCancelAdmission,
    clearErrors: clearErrorsCancelAdmission,
    setError: setErrorCancelAdmission,
    getValues: getValuesCancelAdmission,
  } = useForm();

  const {
    handleSubmit: handleSubmitMessage,
    control: controlMessage,
    formState: { errors: errorsMessage },
    clearErrors: clearErrorsMessage,
    setError: setErrorMessage,
    setValue: setValueMessage,
  } = useForm();

  const {
    handleSubmit: handleSubmitScroll,
    control: controlScroll,
    formState: { errors: errorsScroll },
    setValue: setValueScroll,
  } = useForm();

  const {
    handleSubmit: handleSubmitTransfer,
    control: controlTransfer,
    formState: { errors: errorsTransfer },
    setValue: setValueTransfer,
  } = useForm();

  const {
    handleSubmit: handleSubmitImportDirectApplications,
    control: controlImportDirectApplications,
    formState: { errors: errorsImportDirectApplications },
    reset: resetImportDirectApplications,
  } = useForm();

  const handleClickOutside = event => {
    if (
      dropDown &&
      OverflowMenuDivRef.current &&
      !OverflowMenuDivRef.current.contains(event.target)
    ) {
      setDropDown(false);
    }
  };

  useMemo(() => {
    if (isLotteryAdmission) {
      getLotteryDetails({ variables: { admissionId } });
    }
  }, [isLotteryAdmission]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropDown]);

  const generateLotteryFn = async () => {
    try {
      const response = await generateLottery({
        variables: { admissionId },
      });
      if (response && response?.data?.generateLottery) {
        setAlertDetails({
          message: showRegenerateLabel(lotteryData)
            ? 'Lottery regenerated successfully'
            : 'Lottery generated successfully',
          level: SUCCESS,
        });
        if (refetch) refetch();
      }
      if (lotteryRefetch) lotteryRefetch({ fetchPolicy: 'network-only' });
    } catch (error: any) {
      setAlertDetails({ message: error?.message, level: ERROR });
    }
  };

  const handleOverflowMenuDivRef = (ref: HTMLDivElement | null): void => {
    OverflowMenuDivRef.current = ref;
  };

  const closePaymentModal = () => {
    resetPaymentFormValues(setValue);
    handlePaymentModal(false);
    clearErrors();
    setCurrentItem(null);
    setPartialPayment(null);
    setCancellationCharge(null);
  };

  function closeReconcilePaymentModal() {
    setReconcilePaymentModal(false);
    setReconcilePaymentData(null);
    setCurrentId('');
    setConfirmSelection(false);
  }

  function referrerOnPress(item) {
    referrer.current = item?.qualifyingReferer || '-';
    setReferrerViewModalModal(true);
  }

  async function handleReconcileAdmissionPayment() {
    setCanClick(false);
    try {
      const response = await reconcileAdmissionPayment({ variables: { applicationId: currentId } });
      if (response?.data?.reconcileAdmissionPayments?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.RECONCILE_PAYMENT, level: SUCCESS });
        if (refetchApplications) refetchApplications();
      } else {
        throw new Error(response?.data?.reconcileAdmissionPayments?.message);
      }
    } catch (error: any) {
      setAlertDetails({ message: error.message, level: ERROR });
    }
    closeReconcilePaymentModal();
    setCanClick(true);
  }

  async function handleMakeAdmissionPayment(formData: paymentFormData) {
    setCanClick(false);
    try {
      let payload: paymentPayload = {
        applicationId: currentItem?.id,
        paymentDetail: {
          mode: formData?.mode?.value,
        },
      };

      payload = await createPaymentDetailObject(formData, payload);
      const response = await createAdmissionPayment({ variables: { ...payload } });
      if (refetchApplications) refetchApplications();

      if (!response.hasOwnProperty('errors')) {
        setAlertDetails({ message: UPDATE_SUCCESS.PAYMENT_STATUS, level: SUCCESS });
      } else {
        setAlertDetails({ message: ERROR_MSG.PAYMENT_UNSUCCESSFUL, level: ERROR });
      }
      closePaymentModal();
    } catch (error: any) {
      setAlertDetails({
        message: ERROR_MSG.PAYMENT_UNSUCCESSFUL + ': ' + error.message,
        level: ERROR,
      });
      setCanClick(true);
    }
    setCanClick(true);
  }

  useEffect(() => {
    setTitle();
    return () => {
      setFilter(null);
    };
  }, []);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    if (applicationsData) {
      setTotalCount(applicationsData?.applications?.totalCount);
    }
  }, [applicationsData]);

  const conditionalCapHeadCells = useMemo(() => {
    let updatedHeadCells = [...headCells1];
    if (isCAPTypeAllowed) {
      updatedHeadCells.splice(3, 0, capHeadCell);
    }
    return updatedHeadCells;
  }, [isCAPTypeAllowed]);

  useEffect(() => {
    if (admissionDetail) {
      setHeadCells(conditionalCapHeadCells);
    }
    setTitle();
  }, [admissionDetail, conditionalCapHeadCells]);

  useEffect(() => {
    if (admissionId) {
      handleFetch();
    }
  }, [currentPage, searchValue, admissionId, rowLimit]);

  useEffect(() => {
    if (concessionsCompositionData) {
      setConcessionOptions(concessionsCompositionData.getConcessionCompositions?.data);
      setConcessionDataAvailable(true);
    }
  }, [concessionsCompositionData]);

  useEffect(() => {
    setSearchingApplicant(searchValue);
  }, [searchValue]);

  const fetchConcessions = (subjectGroupId: string) => {
    getConcessions({
      variables: {
        subGroup: subjectGroupId,
      },
    });
  };

  function resetAndClearCapApplicantForm() {
    resetCAPForm();
    clearErrorsCap();
  }

  function resetAndClearDirectApplicantForm() {
    resetDirectForm();
    clearErrorsDIRECT();
  }

  function resetDirectForm() {
    setValueDIRECT('name', '');
    setValueDIRECT('emailId', '');
    setValueDIRECT('capId', '');
    setValueDIRECT('scrollRefNo', '');
    setValueDIRECT('subjectGroup', null);
  }

  function resetCAPForm() {
    setValueCap('name', '');
    setValueCap('emailId', '');
    setValueCap('capId', '');
    setValueCap('scrollRefNo', '');
    setValueCap('subjectGroup', null);
  }

  const handleCancelAdmissionModal = (item: any) => {
    setCurrentItem(item);
    hideAlert();
    setCancelAdmissionModal(true);
  };

  function closeCancelAdmissionModal() {
    resetCancelAdmission({});
    clearErrorsCancelAdmission();
    setCurrentItem(null);
    setCancelAdmissionModal(false);
    setDisableCancelModal(false);
    setCancellationAmount(null);
  }

  const handleChangeFeeCollectionModal = (item: any) => {
    setCurrentItem(item);
    hideAlert();
    setChangeFeeCollectionModal(true);
  };

  const handleChangeFeeCollection = async (formValues: any) => {
    setCanClick(false);
    try {
      const payload = {
        applicationId: currentItem?.id,
        compositionId: formValues?.feeComposition?.value,
      };

      const response = await applyFeeComposition({
        variables: payload,
      });
      if (response?.data?.applyFeeComposition) {
        closeChangeFeeCollectionModal();
        setCanClick(true);
        if (refetchApplications) refetchApplications();
        setAlertDetails({ message: UPDATE_SUCCESS.FEE_COLLECTION, level: SUCCESS });
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  function closeChangeFeeCollectionModal() {
    setCurrentItem(null);
    setChangeFeeCollectionModal(false);
  }

  const assignDivisionModal = () => {
    setForm({
      state: 'OPEN',
      step: 'AssignDivisionForm',
      data: { batchID, divisions },
    });
  };

  const handleImportApplicants = e => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      };
      e.target.value = null;
      importApplicantsFile(fileObject);
    }
  };

  const handleImportCAPApplicants = e => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      };
      e.target.value = null;
      importCAPApplicantsFile(fileObject);
    }
  };

  const handleImportDirectApplicationsModal = () => {
    setDropDown(false);
    setImportDirectApplicantsModal(true);
  };

  function closeImportDirectApplicationsModal() {
    setImportDirectApplicantsModal(false);
    handleImportAttachment({});
    resetImportDirectApplications({});
  }

  const handleImportInhouseApplicants = e => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      };
      e.target.value = null;
      importInhouseApplicantsFile(fileObject);
    }
  };

  const importApplicantsFile = async (fileObject: documentObject) => {
    if (fileObject && fileObject.document && admissionId) {
      let importResponse = await importApplicants({
        variables: {
          file: fileObject.document,
          admissionId,
        },
      });

      if (importResponse?.data?.importApplications) {
        const { status, message } = importResponse?.data?.importApplications;
        if (status == 'success') {
          setAlertDetails({
            message: message,
            level: SUCCESS,
          });
          if (refetchApplications) refetchApplications();
        }
        if (status == 'failure') {
          setAlertDetails({
            message: message,
            level: ERROR,
            expireInMs: 6000,
          });
        }
      }
      closeDropdown();
    }
  };

  const importCAPApplicantsFile = async (fileObject: documentObject) => {
    if (fileObject && fileObject.document && admissionId) {
      let importResponse = await importCAPApplicants({
        variables: {
          file: fileObject.document,
          admissionId,
        },
      });

      if (importResponse?.data?.importCAPApplicationIds) {
        const { status, message } = importResponse?.data?.importCAPApplicationIds;
        if (status == 'success') {
          setAlertDetails({
            message: message,
            level: SUCCESS,
          });
          if (refetchApplications) refetchApplications();
        }
        if (status == 'failure') {
          setAlertDetails({
            message: message,
            level: ERROR,
            expireInMs: 6000,
          });
        }
      }
      closeDropdown();
    }
  };

  const importInhouseApplicantsFile = async (fileObject: documentObject) => {
    if (fileObject && fileObject.document && admissionId) {
      let importResponse = await importInhouseApplicants({
        variables: {
          file: fileObject.document,
          admissionId,
        },
      });

      if (importResponse?.data?.importInHouseApplicationIds) {
        const { status, message } = importResponse?.data?.importInHouseApplicationIds;
        if (status == 'success') {
          setAlertDetails({
            message: message,
            level: SUCCESS,
          });
          if (refetchApplications) refetchApplications();
        }
        if (status == 'failure') {
          setAlertDetails({
            message: message,
            level: ERROR,
            expireInMs: 6000,
          });
        }
      }
      closeDropdown();
    }
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  function setTitle() {
    !admissionDataLoading &&
      setHeading([
        {
          text: t('admission.label'),
          url: '/admissions/limit/50/page/1',
        },
        {
          text: admissionDetail?.name,
          url: '',
        },
      ]);
  }

  function handleFetch() {
    getApplications({
      variables: {
        filters: currFilter?.filters,
        limit: rowLimit,
        skip: (currentPage - 1) * rowLimit,
        admission: admissionId,
        ...(searchValue ? { applicantName: searchValue } : {}),
      },
    });
  }

  const handleFilters = (filter: any) => {
    const applicationFilter = {
      limit: rowLimit,
      skip: (currentPage - 1) * rowLimit,
      admission: admissionId,
      ...filter,
    };
    if (searchValue) applicationFilter['applicantName'] = searchValue;
    if (applicationFilter?.filters) {
      applicationFilter?.filters?.and?.forEach((item: any, index: number) => {
        let status;
        if (item?.or[0]?.status) {
          status = item?.or?.map((a: any) => {
            const statusKey = Object.keys(a?.status)[0];
            return {
              status: {
                [statusKey]: a?.status[`${statusKey}`]?.map(
                  (val: any) => ApplicationStatusLabelKeyVal[val],
                ),
              },
            };
          });
          applicationFilter.filters.and[index].or = status;
        }
      });
    }
    setCurrFilter(applicationFilter);
    getApplications({ variables: applicationFilter });
  };

  const meritDate = admissionDetail?.activeRound?.meritDate;
  const lastDate = parseToDate(admissionDetail?.lastDate);
  const showMeritButton = totalCount && isBefore(meritDate, new Date());

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    currentPage,
  );

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.APPLICANT]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchApplicant(applicant: string) {
    let isClear = applicant?.length === 0;
    persistSearch(!isClear, applicant);
  }

  const delayedQuery = useCallback(
    debounce(q => searchApplicant(q), SEARCH_TIME),
    [],
  );
  const handleSearch = (applicant: string) => {
    setSearchingApplicant(applicant);
    delayedQuery(applicant);
  };

  const returnTitle = (icon: any) => {
    let title = '' as string;
    let requestApi;
    let message = '' as string;
    switch (icon) {
      case 'ban':
        title = 'Block';
        message = 'blockApplication.text';
        break;
      case 'check-circle':
        title = 'Accept';
        message = 'acceptApplication.text';
        break;
      case 'times-circle':
        title = 'Reject';
        message = 'rejectApplication.text';
        break;
      case 'qualify-application':
        title = 'Admit Application';
        message = 'admitApplicationSuccess.text';
        break;
      case 'unblock':
        title = 'Unblock';
        requestApi = unblockApplication;
        message = 'unblockApplication.text';
        break;
      case 'file-export':
        title = 'Export Application';
        requestApi = exportApplication;
        message = 'exportApplication.text';
        break;
      case 'hand-holding-usd':
        title = 'Request Payment';
        requestApi = requestPayment;
        message = 'requestPayment.text';
        break;
      case 'schedule-payment':
        title = 'Schedule Payment';
        break;
      case 'payment-details':
        title = 'Make Payment';
        break;
      case 'clipboard-check':
        title = 'Complete Admission';
        requestApi = completeAdmission;
        message = 'completeAdmission.text';
        break;
      case 'cancel-admission':
        title = 'Cancel Admission';
        break;
      case 'concession':
        title = 'Concession';
        message = 'concessionGiven.text';
        break;
      case 'exchange':
        title = 'Transfer Admission';
        break;
      case 'partial-payment':
        title = 'Add Partial Payment';
        break;
      case 'file-invoice-dollar':
        title = 'Change Fee Collection';
    }
    return { title, requestApi, message };
  };

  const createIconObject = (iconDetails: any) => {
    const iconData = iconDetails.map((item: any) => {
      const iconActionAndTitle = returnTitle(
        item?.icon?.iconName ? item?.icon?.iconName : item?.icon,
      );
      return {
        icon: item?.icon,
        title: iconActionAndTitle?.title,
        requestApi: iconActionAndTitle?.requestApi,
        message: iconActionAndTitle?.message,
        type: item?.type,
      };
    });
    return iconData;
  };

  const dynamicColorAndIcon = (
    status: string,
    isPartialPayment: boolean = false,
    currentItem: ApplicationItemType,
  ) => {
    let color: string = '';
    let actions: any = [];
    switch (status) {
      case 'INCOMPLETE':
      case 'APPLICATION_FEE_PENDING':
        color = colors.yellow;
        break;
      case 'SUBMITTED':
        color = colors.blue;
        actions = createIconObject([
          { icon: faCheckCircle, type: 'default' },
          { icon: faTimesCircle, type: 'default' },
          { icon: faBan, type: 'default' },
          { icon: faFileExport, type: 'default' },
        ]);
        break;
      case 'BLOCKED':
        color = colors.red;
        actions = createIconObject([
          { icon: 'unblock', type: 'default' },
          { icon: faFileExport, type: 'default' },
        ]);
        break;
      case 'VERIFIED':
      case 'CANCELLATION_CHARGES_PAID':
        color = colors.green;
        actions = createIconObject([{ icon: faFileExport, type: 'default' }]);
        break;
      case 'AUTO_VERIFIED':
        color = colors.green;
        actions = createIconObject([
          { icon: faTimesCircle, type: 'default' },
          { icon: faHandHoldingUsd, type: 'payment' },
          { icon: faFileExport, type: 'default' },
        ]);
        break;
      case 'PAYMENT_AWAITED':
        color = colors.blue;
        actions = createIconObject([
          { icon: faTimesCircle, type: 'default' },
          { icon: 'partial-payment', type: 'payment' },
          { icon: 'concession', type: 'payment' },
          { icon: 'schedule-payment', type: 'payment' },
          { icon: 'payment-details', type: 'payment' },
          { icon: 'cancel-admission', type: 'admission' },
          { icon: faFileExport, type: 'default' },
          { icon: faFileInvoiceDollar, type: 'payment' },
        ]);
        break;
      case 'PARTIAL_PAYMENT_COMPLETED':
      case 'PAYMENT_COMPLETED':
        color = colors.green;
        actions = createIconObject([
          { icon: faClipboardCheck, type: 'admission' },
          { icon: faFileExport, type: 'default' },
        ]);
        break;
      case 'PAYMENT_POSTPONED':
        color = colors.green;
        actions = createIconObject([
          { icon: faHandHoldingUsd, type: 'payment' },
          { icon: faClipboardCheck, type: 'admission' },
          { icon: faFileExport, type: 'default' },
        ]);
        break;
      case 'TRANSFERED':
        color = colors.blue;
        actions = createIconObject([{ icon: faFileExport, type: 'default' }]);
        break;
      case 'ADMISSION_COMPLETED':
        color = colors.green;
        actions = isPartialPayment
          ? createIconObject([
              { icon: 'partial-payment', type: 'payment' },
              { icon: 'payment-details', type: 'payment' },
              { icon: 'cancel-admission', type: 'admission' },
              { icon: faFileExport, type: 'default' },
              { icon: faExchange, type: 'admission' },
            ])
          : createIconObject([
              { icon: 'cancel-admission', type: 'admission' },
              { icon: faFileExport, type: 'default' },
              { icon: faExchange, type: 'admission' },
            ]);
        break;
      case 'ADMISSION_CANCELLATION':
        color = colors.red;
        actions = createIconObject([
          { icon: 'payment-details', type: 'payment' },
          { icon: faFileExport, type: 'default' },
        ]);
      case 'REJECTED':
      case 'CANCELLED':
      case 'PRE_ADMISSION_CANCELLATION':
      case 'POST_ADMISSION_CANCELLATION':
        color = colors.red;
    }

    const defaultIcons = actions.filter((item: any) => item?.type === 'default');
    const paymentIcons = actions.filter((item: any) => item?.type === 'payment');
    const admissionIcons = actions.filter((item: any) => item?.type === 'admission');
    if (paymentIcons && paymentIcons?.length === 1) defaultIcons.push(paymentIcons[0]);
    if (admissionIcons && admissionIcons?.length === 1) defaultIcons.push(admissionIcons[0]);

    actionIcons.current[currentItem?.id] = {
      defaultIcons: defaultIcons,
      paymentIcons: paymentIcons.length > 1 ? paymentIcons : [],
      admissionIcons: admissionIcons.length > 1 ? admissionIcons : [],
    };
    return color;
  };

  const handleApplicationAction = async (
    id: string,
    requestApi: any,
    message: string,
    closeModal?: Function,
  ) => {
    setCanClick(false);
    try {
      const payload = { id: id };
      const response = await requestApi({
        variables: payload,
      });
      if (response?.data) {
        if (refetchApplications) refetchApplications();
        if (closeModal) closeModal();
        setAlertDetails({ message: t(message), level: SUCCESS });
        setCanClick(true);
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  function exportVerifiedApplicants() {
    if (admissionId) {
      downloadFileRestAPI(
        `applications/export/${admissionId}/VERIFIED`,
        `verified_applications.xlsx`,
      );
      setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
    }
    setDropDown(false);
  }

  const exportApplicationsTemplate = (url: string, fileName: string) => {
    if (admissionId) {
      downloadFileRestAPI(url, fileName);
      setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
    }
    setDropDown(false);
  };

  function exportMeritList() {
    if (admissionId) {
      downloadFileRestAPI(`admission/export/meritlist/${admissionId}`, 'merit_list.zip');
      setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
    }
  }

  function exportApplication(param: { variables: { id: String } }) {
    if (param?.variables?.id) {
      downloadFileRestAPI(`applications/exportzip/${param.variables.id}`, `application.zip`);
      setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
    }
  }

  async function handleGenerateAdmissionReports() {
    if (admissionId) {
      closeExportReportModal();
      setCanClick(false);
      try {
        const response = await generateAdmissionReports({
          variables: { admissionId: admissionId },
        });

        if (response?.data?.generateAdmissionReports?.status === 'success') {
          setAlertDetails({ message: ADD_SUCCESS.REPORT_GENERATION, level: SUCCESS });
          if (refetch) refetch();
          setCanClick(true);
        } else {
          throw new Error(
            response?.data?.generateAdmissionReports?.message || ERROR_MSG.GENERIC_ERROR,
          );
        }
      } catch (e: any) {
        setAlertDetails({ message: e.message, level: ERROR });
        setCanClick(true);
      }
    }
  }

  const fetchDocuments = (item: any) => {
    const documents = item?.allDocument?.map((i: any) => ({
      name: i?.document?.name,
      fileType: i?.document?.fileType,
      ...(i?.type?.name || i?.document?.alias
        ? {
            type: `${i?.type?.name ? clipLongText(i.type.name, 20) : ''} ${
              i?.document?.alias ? `(${i.document.alias})` : ''
            }`,
          }
        : {}),
      uri: i?.document?.uri,
    }));
    if (item?.applicantType === APPLICANT_TYPE_ENUM[1]) {
      documents.unshift({
        ...item?.cap?.document,
        type: 'CAP Form',
      });
    }
    return documents;
  };

  function makePayment(): void {
    setMakePaymentModal(false);
    handlePaymentModal(true);
  }

  async function handleMakePayment(item: any, type: string) {
    setCurrentItem(item);
    const feeBookData = await graphqlQuery(
      getApplicationFeeBookQuery,
      { applicationId: item?.id },
      false,
    );
    setCurrentFeeDetails(feeBookData?.data?.getApplicationFeeBook);
    if (type === 'make-payment') {
      if (item?.fees?.installmentPayable) {
        setPartialPayment(item?.fees?.installmentPayable);
      }
      if (item?.fees?.cancellationFeeCharges) {
        setCancellationCharge(item?.fees?.cancellationFeeCharges);
      }
      setMakePaymentModal(true);
    } else {
      setForm({
        state: 'OPEN',
        step: 'PartialPaymentForm',
        data: {
          currentItem: item,
          currentFeeDetails: feeBookData?.data?.getApplicationFeeBook,
        },
      });
    }
  }

  function closeMakePaymentModal() {
    setCurrentItem(null);
    setMakePaymentModal(false);
    setPartialPayment(null);
    setCancellationCharge(null);
  }

  function handleAcceptApplication(applicationId: string) {
    setForm({
      state: 'OPEN',
      step: 'AcceptApplicationForm',
      data: { applicationId },
    });
  }

  function handleRejectBlockApplication(applicationId: string, action: 'REJECT' | 'BLOCK') {
    setForm({
      state: 'OPEN',
      step: 'RejectBlockApplicationForm',
      data: { applicationId, action },
    });
  }

  function closeRemarkModal() {
    setRemarkModal(false);
    remarkRef.current = null;
  }

  function giveConcession(item: {
    fees?: { concession?: string };
    subjectGroup: { id: string };
    id: string;
  }) {
    if (item?.fees?.concession && concessionCompositionId) {
      concessionCompositionId.current = item.fees.concession;
    }
    setCurrentId(item.id);
    setConcessionDataAvailable(false);
    fetchConcessions(item.subjectGroup.id);
    handleConcessionModal(true);
  }

  function closeClearConcessionValues() {
    handleConcessionModal(false);
    concessionCompositionId.current = '';
    setCurrentId('');
  }

  function selectFeeCompositionOnSubmit() {
    if (concessionCompositionId.current) {
      handleGiveConcession(concessionCompositionId.current, currentId);
    } else {
      setAlertDetails({
        message: t('error.concession.required'),
        level: ERROR,
      });
    }
  }

  async function handleGiveConcession(concessionCompositionId: string, applicationId: string) {
    try {
      const response = await applyConcession({
        variables: {
          applicationId: applicationId,
          compositionId: concessionCompositionId,
        },
      });
      if (response?.data) {
        setAlertDetails({ message: t('payment.concession-given'), level: SUCCESS });
        if (refetchApplications) refetchApplications();
        closeClearConcessionValues();
      }
    } catch (error) {
      setAlertDetails({ message: error?.message, level: ERROR });
      setCanClick(true);
    }
  }

  function closeReferrerModal() {
    setReferrerViewModalModal(false);
    referrer.current = null;
  }

  function getIconFn(item, icon) {
    switch (icon?.title) {
      case 'Reject':
        handleRejectBlockApplication(item.id, 'REJECT');
        break;
      case 'Block':
        handleRejectBlockApplication(item.id, 'BLOCK');
        break;
      case 'Make Payment':
        handleMakePayment(item, 'make-payment');
        break;
      case 'Concession':
        giveConcession(item);
        break;
      case 'Accept':
        handleAcceptApplication(item.id);
        break;
      case 'Add Partial Payment':
        handleMakePayment(item, 'partial-payment');
        break;
      case 'Cancel Admission':
        handleCancelAdmissionModal(item);
        break;
      case 'Change Fee Collection':
        handleChangeFeeCollectionModal(item);
        break;
      case 'Transfer Admission':
        transferApplicationFn(item);
        break;
      case 'Schedule Payment':
        handleSchedulePaymentModal(item?.id);
        break;
      default:
        handleApplicationAction(item?.id, icon?.requestApi, icon?.message);
        break;
    }
  }

  function closeSchedulePaymentModal() {
    setCurrentId('');
    setSchedulePaymentModal(false);
  }

  function handleSchedulePaymentModal(id: string) {
    setCurrentId(id);
    setSchedulePaymentModal(true);
  }

  async function handleApplyMerit() {
    try {
      if (admissionId) {
        const response = await generateMeritList({
          variables: {
            id: admissionId,
          },
          refetchQueries: [
            {
              query: getAdmissionByIdQuery,
              variables: {
                id: admissionId,
              },
            },
          ],
        });
        if (response?.data?.generateMeritListAndApply) {
          setAlertDetails({
            message: t('meritApplied.text'),
            level: SUCCESS,
          });
          if (refetchApplications) refetchApplications();
        } else {
          throw new Error(ERROR_MSG.GENERIC_ERROR);
        }
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
  }

  async function handleInviteCapApplicant(formValues: InviteCapApplicantFormValues) {
    setCanClick(false);
    try {
      const inviteCapApplicantObject = {
        capId: formValues?.capId,
        name: formValues?.name,
        email: formValues?.emailId,
        subjectGroup: formValues?.subjectGroup?.value,
        scrollRefNo: formValues?.scrollRefNo,
        admission: admissionData.admission.id,
      };
      const response = await inviteCAPApplicants({
        variables: { payload: inviteCapApplicantObject },
      });
      if (response?.data?.sendApplicationFillRequest?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.INVITE_CAP_APPLICANT, level: SUCCESS });
        resetAndClearCapApplicantForm();
        setDropDown(false);
      } else {
        throw new Error(response?.data?.sendApplicationFillRequest?.message);
      }
      setCanClick(true);
    } catch (e: any) {
      setAlertDetails({ message: e?.message, level: ERROR });
      setCanClick(true);
    }
  }
  async function handleInviteDirectApplicant(formValues: InviteDirectApplicantFormValues) {
    setCanClick(false);
    try {
      const inviteDirectApplicantObject = {
        name: formValues?.name,
        email: formValues?.emailId,
        subjectGroup: formValues?.subjectGroup?.value,
        admission: admissionData.admission.id,
      } as any;

      if (formValues?.capId) {
        inviteDirectApplicantObject['capId'] = formValues.capId;
      }
      if (formValues?.scrollRefNo) {
        inviteDirectApplicantObject['scrollRefNo'] = formValues.scrollRefNo;
      }
      const response = await inviteDirectApplicants({
        variables: { payload: inviteDirectApplicantObject },
      });
      if (response?.data?.inviteDirectApplication?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.INVITE_DIRECT_APPLICANT, level: SUCCESS });
        resetAndClearDirectApplicantForm();
        setDropDown(false);
        setInviteDirectApplicant(false);
      } else {
        throw new Error(response?.data?.inviteDirectApplication?.message);
      }
      setCanClick(true);
    } catch (e: any) {
      resetAndClearDirectApplicantForm();
      setAlertDetails({ message: e?.message, level: ERROR });
      setCanClick(true);
      setInviteDirectApplicant(false);
    }
  }

  function closeDropdown() {
    setDropDown(false);
  }

  async function handleCancelAdmission(formValues: CancelAdmissionFormValues) {
    setCanClick(false);
    try {
      const payload = {
        applicationId: currentItem?.id,
        amount: cancellationAmount,
      };

      const response = await cancelAdmissionWithCharges({
        variables: payload,
      });
      if (response?.data?.cancelAdmissionWithCharges) {
        setCanClick(true);
        closeCancelAdmissionModal();
        setAlertDetails({
          message:
            currentItem?.status === ApplicationStatus.ADMISSION_COMPLETED
              ? t('cancelAdmission.text')
              : t('cancelAdmissionInitiated.text'),
          level: SUCCESS,
        });
        if (refetchApplications) refetchApplications();
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  function editScrollNoFn(item: any) {
    setCurrentItem(item);
    handleEditScrollRefModal(true);
  }

  function closeScrollNoFn() {
    setCurrentItem(null);
    handleEditScrollRefModal(false);
  }

  function transferApplicationFn(item: any) {
    setCurrentItem(item);
    handleTransferApplModal(true);
  }

  function closeTransferApplicationFn() {
    setCurrentItem(null);
    handleTransferApplModal(false);
  }

  function sendMessageFn() {
    handleSendMessageModal(true);
  }
  function closeSendMessageFn() {
    setValueMessage('recipient', null);
    setValueMessage('status', null);
    setValueMessage('subject', '');
    setValueMessage('message', '');
    handleSendMessageModal(false);
  }

  async function handleSendMessageOnSubmit(formData: any) {
    setCanClick(false);
    try {
      let payload = {
        admissionId: admissionData.admission.id,
        mailBody: formData.message,
        mailSubject: formData.subject,
        subjectGroup: subjectGroupOptions.map(s => {
          return s.value;
        }),
      };
      if (formData?.status && formData.status?.length > 0) {
        payload['havingStatus'] = formData.status.map(s => {
          return s.value;
        });
      }

      const response = await sendCustomEmail({
        variables: payload,
      });

      if (response?.data && response.data?.sendCustomMail?.status === SUCCESS) {
        setAlertDetails({
          message: response.data?.sendCustomMail?.message,
          level: SUCCESS,
        });
        if (refetchApplications) refetchApplications();
        closeSendMessageFn();
        setCanClick(true);
      }
    } catch (error) {
      setCanClick(true);
      setAlertDetails({ message: error?.message, level: ERROR });
      closeSendMessageFn();
    }
  }

  async function handleScrollEdit(data: { scrollRefNo: string }) {
    try {
      if (data?.scrollRefNo) {
        const payload = {
          applicationId: currentItem?.id,
          scrollRefNo: data?.scrollRefNo,
        };

        const response = await updateApplicationScrollRefNo({
          variables: payload,
        });
        if (response?.data?.updateScrollRefNo?.status === 'success') {
          setAlertDetails({ message: UPDATE_SUCCESS.SCROLL_REF, level: SUCCESS });
          if (refetchApplications) refetchApplications();
          closeScrollNoFn();
          setCanClick(true);
        } else {
          throw new Error(response?.data?.updateScrollRefNo?.message);
        }
      }
    } catch (error) {
      setAlertDetails({ message: error?.message, level: ERROR });
      closeScrollNoFn();
    }
  }

  async function handleTransferOnSubmit(data: any) {
    setCanClick(false);
    try {
      if (currentItem) {
        const payload = {
          applicationId: currentItem.id,
          toAdmision: data?.admission?.value,
          toSubjectGroup: data?.subjectGroup?.value,
        };

        const response = await transferApplication({
          variables: payload,
        });
        if (response?.data?.transferAdmission) {
          setAlertDetails({ message: UPDATE_SUCCESS.TRANSFER_ADMISSION, level: SUCCESS });
          if (refetchApplications) refetchApplications();
          closeTransferApplicationFn();
          setCanClick(true);
        }
      }
    } catch (error) {
      setCanClick(true);
      setAlertDetails({ message: error?.message, level: ERROR });
      closeTransferApplicationFn();
    }
  }

  function closePaymentRefundModal() {
    setPaymentRefundModal(false);
    setRefundAmount(0);
  }

  async function handleReconcilePaymentModal(id: string) {
    setCurrentId(id);
    const { data, loading } = await graphqlQuery(lastApplicationReconcilePaymentRequest, {
      applicationId: id,
    });
    if (!loading && data?.getApplicationLastPaymentReconcileRequest) {
      setReconcilePaymentData(data.getApplicationLastPaymentReconcileRequest);
    }
    setReconcilePaymentModal(true);
  }

  function getActionIconDropdownOptions(type: string, currentItem: ApplicationItemType) {
    let actionIconOptions = actionIcons.current[currentItem?.id][`${type}`]?.map((item: any) => ({
      key: item?.title,
      value: item?.title,
      prefixIcon: item?.icon,
      onPress: () => {
        closeActionIconDropdown();
        getIconFn(currentItem, item);
      },
      customIcon: !item?.icon?.iconName,
      customStyle: true,
    }));

    if (
      type === 'paymentIcons' &&
      currentItem?.paymentFullStatus?.status &&
      currentItem.paymentFullStatus.status !== 'NA'
    ) {
      actionIconOptions = [
        ...actionIconOptions,
        {
          key: 'reconcile-payment',
          value: t('paymentReconcile.label'),
          onPress: () => handleReconcilePaymentModal(currentItem?.id),
          prefixIcon: 'reconcile-payment',
          customIcon: true,
        },
      ];
    }
    return actionIconOptions;
  }

  function closeActionIconDropdown() {
    setPaymentIconsDropDown(false);
    setAdmissionIconsDropdown(false);
    setCurrentId('');
  }

  function closeExportReportModal() {
    setExportReportModal(false);
  }

  function handleExportReport() {
    setDropDown(false);
    setExportReportModal(true);
  }

  const subjectGroupOptions: DropdownOptions[] =
    admissionDetail?.subjectGroups?.map(
      (subGroup: { subjectGroup: DropdownOptions }) => subGroup.subjectGroup,
    ) ?? [];

  const handlePaymentDetailsModal = async (ids: string[]) => {
    setPaymentDetailsModal(true);
    setPaymentIds(ids);
  };

  function closePaymentDetailsModal() {
    setPaymentDetailsModal(false);
    setPaymentIds([]);
  }

  const handleDirectApplicationsUpload = e => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      };
      handleImportAttachment(fileObject);
      e.target.value = null;
    }
  };

  const handleImportDirectApplications = async (formValues: {
    subjectGroup: DropdownOptionsType;
  }) => {
    setCanClick(false);
    try {
      if (importAttachment && importAttachment.document) {
        let importResponse = await importDirectApplicants({
          variables: {
            file: importAttachment.document,
            admission: admissionId,
            subjectGroup: formValues?.subjectGroup?.value,
          },
        });

        if (importResponse?.data?.importDirectApplications) {
          const { status, message } = importResponse?.data?.importDirectApplications;
          if (status == 'success') {
            setAlertDetails({
              message: message,
              level: SUCCESS,
            });
            if (refetchApplications) refetchApplications();
          }
          if (status == 'failure') {
            setAlertDetails({
              message: message,
              level: ERROR,
            });
          }
        }
        setCanClick(true);
        closeImportDirectApplicationsModal();
      } else {
        throw new Error(t('error.file.required'));
      }
    } catch (e: any) {
      setAlertDetails({ level: ERROR, message: e.message });
      setCanClick(true);
    }
  };

  function closeViewApplication() {
    setViewApplication(false);
    setCurrentId('');
  }

  const handleViewApplicationModal = (applicationId: string) => {
    setViewApplication(true);
    setCurrentId(applicationId);
  };

  function onAdmissionEditSuccess(message: string) {
    setAlertDetails({ message, level: SUCCESS });
    if (refetch) refetch();
    onCloseForm();
  }

  function onAdmissionEditError(message: string) {
    setAlertDetails({ message, level: ERROR });
    onCloseForm();
  }

  const admissionFormOnOpen = () => {
    setForm({
      state: 'OPEN',
      step: 'AdmissionForm',
    });
  };

  const onCloseForm = () => {
    setForm({ state: 'CLOSE' });
  };

  const onSuccess = (message: string): void => {
    setAlertDetails({ message, level: SUCCESS });
    if (refetchApplications) refetchApplications();
    onCloseForm();
  };

  const onError = (message: string): void => {
    setAlertDetails({ message, level: ERROR });
    onCloseForm();
  };

  const allowedApplicantTypes = admissionDetail?.allowedApplicantType ?? [];
  const batchID = admissionDetail?.batch?.value ?? '';
  const classID = admissionDetail?.class?.value ?? '';
  const courseID = admissionDetail?.class?.course?.value ?? '';
  const universityID = admissionDetail?.class?.course?.university?.value ?? '';
  const divisions = (admissionDetail?.batch?.divisionDetails ?? []).map(
    (e: { division: string }) => e.division,
  );

  const admissionConfigDetails: TAdmissionConfig = {
    admission: admissionId,
    applicantTypes: allowedApplicantTypes,
    batch: batchID,
    class: classID,
    course: courseID,
    university: universityID,
    subjectGroupOptions: subjectGroupOptions,
  };

  return (
    <>
      <LoadContentWrapper style={styles.loadContent}>
        {admissionDetail && Object.keys(admissionDetail).length > 0 ? (
          <>
            <InfoView ref={infoDivRef}>
              <InfoWrapper>
                <Row1>
                  <RowItem>
                    <NormalTextSelect
                      value={t('batch.label')}
                      fontWeight={600}
                      fontFamily={fonts.semibold}
                    />
                    <NormalTextSelect value={admissionDetail?.batch?.label || '-'} lines={2} />
                  </RowItem>
                  <RowItem>
                    <NormalTextSelect
                      value={t('lastAdmissionDate.label')}
                      fontWeight={600}
                      fontFamily={fonts.semibold}
                    />
                    <NormalTextSelect
                      value={lastDate ? format(lastDate, DT.DATE_FORMAT_SLASH) : '-'}
                    />
                  </RowItem>
                  <RowItem>
                    <NormalTextSelect
                      value={t('totalSeatsAvailable.label')}
                      fontWeight={600}
                      fontFamily={fonts.semibold}
                    />
                    <NormalTextSelect
                      value={
                        `${admissionDetail?.availableSeats} / ${admissionDetail?.totalSeats}` || '-'
                      }
                    />
                  </RowItem>
                  <RowItem>
                    <NormalTextSelect
                      value={t('totalApplications.label')}
                      fontWeight={600}
                      fontFamily={fonts.semibold}
                    />
                    <NormalTextSelect value={totalCount || '-'} />
                  </RowItem>
                </Row1>
                <Row2>
                  <ChipArrayWrapper maxWidth={'50%'} marginRight={'24px'}>
                    <NormalTextSelect
                      value={t('subjectGroups.label')}
                      fontWeight={600}
                      fontFamily={fonts.semibold}
                    />
                    <ChipsScroll>
                      {admissionDetail?.subjectGroups?.[0] && (
                        <Chip
                          label={admissionDetail.subjectGroups[0]?.subjectGroup?.label}
                          close={false}
                          height={24}
                          chipFontSize={1.2}
                        />
                      )}
                      {admissionDetail?.subjectGroups?.length > 1 && (
                        <ShowMore onPress={() => setSubjGrpsModalState(true)}>
                          <NormaltextAtom value={'... Show more'} color={colors.primary} />
                        </ShowMore>
                      )}
                    </ChipsScroll>
                  </ChipArrayWrapper>
                  {admissionDetail?.criteria && (
                    <ChipArrayWrapper maxWidth={'50%'}>
                      <NormalTextSelect
                        value={t('qualifications.label')}
                        fontWeight={600}
                        fontFamily={fonts.semibold}
                      />
                      <ChipsScroll>
                        {admissionDetail?.criteria?.qualifications?.[0] && (
                          <Chip
                            label={admissionDetail.criteria.qualifications[0]?.name?.name}
                            close={false}
                            height={24}
                            chipFontSize={1.2}
                          />
                        )}
                        {admissionDetail?.criteria?.qualifications?.length > 1 && (
                          <ShowMore onPress={() => setQualModalState(true)}>
                            <NormaltextAtom value={'... Show more'} color={colors.primary} />
                          </ShowMore>
                        )}
                      </ChipsScroll>
                    </ChipArrayWrapper>
                  )}
                </Row2>
                <Row2>
                  <RowItem>
                    <NormalTextSelect
                      value={t('status.label')}
                      fontWeight={600}
                      fontFamily={fonts.semibold}
                    />
                    <NormalTextSelect
                      value={admissionDetail?.status || '-'}
                      color={isAdmissionOpen ? colors.green : colors.errorColor}
                    />
                  </RowItem>
                  <RowItem>
                    <RoundView>
                      <NormalTextSelect
                        value={t('activeRound.label')}
                        fontWeight={600}
                        fontFamily={fonts.semibold}
                        style={{ marginRight: 15 }}
                      />
                      <TouchableOpacity
                        onPress={() => setForm({ state: 'OPEN', step: 'ManageRounds' })}>
                        <Icon name="manage-admission" />
                      </TouchableOpacity>
                    </RoundView>
                    <NormalTextSelect
                      value={admissionDetail?.activeRound?.name || '-'}
                      color={colors.primaryColor}
                    />
                  </RowItem>
                </Row2>
              </InfoWrapper>
              <ButtonView>
                <ManageView>
                  <SettingsIcon tooltipTitle={t('manage.label')} onPress={admissionFormOnOpen} />
                  {isAdmissionOpen && <AdmissionLinks admissionConfig={admissionConfigDetails} />}
                </ManageView>
              </ButtonView>
            </InfoView>
            <>
              <HeaderView>
                <SearchWrapper>
                  <Search
                    id="applicantSearch"
                    handleChange={handleSearch}
                    value={searching}
                    label={t('search-applications.text')}
                    width="100%"
                  />
                </SearchWrapper>
                {infoDivRef?.current?.clientHeight ? (
                  <FilterWrapper isFilterPresent={isFilterPresent} filterHeight={filterHeight}>
                    <Filter
                      filterItems={filterItems}
                      handleFilters={handleFilters}
                      setFilterHeight={setFilterHeight}
                      searchValue={searchValue}
                      academicYear={''}
                      admission={true}
                      additionalHeight={infoDivRef?.current?.clientHeight}
                    />
                  </FilterWrapper>
                ) : null}

                <ButtonView>
                  {isAdmissionOpen && (
                    <ApplicantTypeWrapper>
                      {isLotteryAdmission ? (
                        admissionHasApplications && (
                          <>
                            {lotteryData &&
                              lotteryData?.lottery?.status !== LotteryStatus.PUBLISHED && (
                                <SecondaryBtn
                                  onPress={generateLotteryFn}
                                  label={
                                    showRegenerateLabel(lotteryData)
                                      ? 'Regenerate Lottery'
                                      : 'Generate Lottery'
                                  }
                                />
                              )}
                            <OutlineButton
                              onPress={() => {
                                if (lotteryData?.lottery?.id) {
                                  history.push(
                                    `/lottery/${admissionId}/${lotteryData.lottery.id}/limit/${dataLimit}/page/1`,
                                  );
                                }
                              }}
                              label={'View Lottery'}
                              style={styles.headerOutlineButtonStyle}
                              customDisabled={!showRegenerateLabel(lotteryData)}
                            />
                          </>
                        )
                      ) : (
                        <>
                          {showMeritButton ? (
                            <>
                              <Tooltip
                                placement="top"
                                title={
                                  admissionDetail?.activeRound?.meritGenerated
                                    ? t('alreadyGenerated.text')
                                    : ''
                                }>
                                <span>
                                  <SecondaryBtn
                                    onPress={handleApplyMerit}
                                    label={t('applyMerit.label')}
                                    customDisabled={admissionDetail?.activeRound?.meritGenerated}
                                  />
                                </span>
                              </Tooltip>
                              <OutlineButton
                                onPress={exportMeritList}
                                label={t('viewMerit.label')}
                                style={styles.headerOutlineButtonStyle}
                              />
                            </>
                          ) : isCAPTypeAllowed ? (
                            <>
                              <SecondaryBtn
                                onPress={() => setInviteCapApplicant(true)}
                                label={t('inviteCapApplicant.text')}
                              />
                              <OutlineButton
                                onPress={() =>
                                  history.push(
                                    `/cap-invitations/${admissionId}/limit/${dataLimit}/page/1`,
                                  )
                                }
                                label={t('view-cap-invites.label')}
                                style={styles.headerOutlineButtonStyle}
                              />
                            </>
                          ) : isDirectTypeAllowed ? (
                            <>
                              <SecondaryBtn
                                onPress={() => setInviteDirectApplicant(true)}
                                label={t('inviteDirectApplicant.text')}
                              />
                              <OutlineButton
                                onPress={() =>
                                  history.push(
                                    `/direct-invitations/${admissionId}/limit/${dataLimit}/page/1`,
                                  )
                                }
                                label={t('viewDirectInvites.label')}
                                style={styles.headerOutlineButtonStyle}
                              />
                            </>
                          ) : isINHOUSETypeAllowed ? (
                            <>
                              <input
                                style={{ display: 'none' }}
                                id="inhouseTypeFileInput"
                                multiple
                                type="file"
                                accept=".xls,.xlsx"
                                onChange={handleImportInhouseApplicants}
                              />

                              <label htmlFor="inhouseTypeFileInput">
                                <SecondaryBtn
                                  onPress={null}
                                  label={t('upload.inhouse.applicants.text')}
                                />
                              </label>
                              <OutlineButton
                                onPress={() =>
                                  exportApplicationsTemplate(
                                    `admission/template/inhouse/${admissionId}`,
                                    'inhouse_template.xlsx',
                                  )
                                }
                                label={t('download.inhouse.applicant.list.text')}
                                style={styles.headerOutlineButtonStyle}
                              />
                            </>
                          ) : (
                            admissionHasApplications && (
                              <>
                                <SecondaryBtn
                                  onPress={assignDivisionModal}
                                  label={t('assignClassDivision.text')}
                                />
                                {admissionDetail?.reportRequest &&
                                admissionDetail?.reportRequest?.status === 'COMPLETED' ? (
                                  <OutlineButton
                                    onPress={() => setExportReportModal(true)}
                                    label={t('exportReport.label')}
                                    style={styles.headerOutlineButtonStyle}
                                  />
                                ) : (
                                  <OutlineButton
                                    onPress={handleGenerateAdmissionReports}
                                    label={t('generateReport.label')}
                                    style={styles.headerOutlineButtonStyle}
                                  />
                                )}
                              </>
                            )
                          )}
                        </>
                      )}
                      {Boolean(
                        isPublicAdmission ||
                          isCAPTypeAllowed ||
                          isDirectTypeAllowed ||
                          (isINHOUSETypeAllowed && admissionHasApplications) ||
                          showMeritButton,
                      ) && (
                        <OverflowMenuIcon
                          onPress={() => {
                            setDropDown(!dropDown);
                          }}
                        />
                      )}
                      {dropDown && (
                        <ButtonGrp
                          handleRef={handleOverflowMenuDivRef}
                          width={
                            isPublicAdmission ||
                            isDirectTypeAllowed ||
                            isCAPTypeAllowed ||
                            (isINHOUSETypeAllowed && showMeritButton)
                              ? 330
                              : 200
                          }
                          top={36}
                          buttonData={[
                            ...(isCAPTypeAllowed
                              ? [
                                  {
                                    key: 'import-cap-applicants',
                                    value: t('upload.cap.applicants.text'),
                                    onPress: closeDropdown,
                                    onInputPress: handleImportCAPApplicants,
                                    typeInput: true,
                                    prefixIcon: faUpload,
                                  },
                                  {
                                    key: 'download-cap-applicants',
                                    value: t('download.cap.applicant.list.text'),
                                    onPress: () =>
                                      exportApplicationsTemplate(
                                        `admission/template/caplist/${admissionId}`,
                                        'caplist_template.xlsx',
                                      ),
                                    prefixIcon: faDownload,
                                  },
                                ]
                              : []),
                            ...(isCAPTypeAllowed && showMeritButton
                              ? [
                                  {
                                    key: 'invite-cap-applicant',
                                    value: t('inviteCapApplicant.text'),
                                    onPress: () => {
                                      setDropDown(false);
                                      setInviteCapApplicant(true);
                                    },
                                    prefixIcon: faUserPlus,
                                  },
                                  {
                                    key: 'view-cap-invites',
                                    value: t('view-cap-invites.label'),
                                    onPress: () =>
                                      history.push(
                                        `/cap-invitations/${admissionId}/limit/${dataLimit}/page/1`,
                                      ),
                                    prefixIcon: faListAlt,
                                  },
                                ]
                              : []),
                            ...(isDirectTypeAllowed
                              ? [
                                  {
                                    key: 'import-direct-applications',
                                    value: t('uploadDirectApplications.text'),
                                    onPress: () => handleImportDirectApplicationsModal(),
                                    prefixIcon: faUpload,
                                  },
                                  {
                                    key: 'download-direct-applications',
                                    value: t('downloadDirectApplicationsTemplate.text'),
                                    onPress: () =>
                                      exportApplicationsTemplate(
                                        'admission/template/direct',
                                        'directlist_template.xlsx',
                                      ),
                                    prefixIcon: faDownload,
                                  },
                                ]
                              : []),
                            ...((isDirectTypeAllowed && showMeritButton) ||
                            (isDirectTypeAllowed && isCAPTypeAllowed)
                              ? [
                                  {
                                    key: 'invite-direct-applicant',
                                    value: t('inviteDirectApplicant.text'),
                                    onPress: () => {
                                      setDropDown(false);
                                      setInviteDirectApplicant(true);
                                    },
                                    prefixIcon: faUserPlus,
                                  },
                                  {
                                    key: 'view-direct-invites',
                                    value: t('viewDirectInvites.label'),
                                    onPress: () =>
                                      history.push(
                                        `/direct-invitations/${admissionId}/limit/${dataLimit}/page/1`,
                                      ),
                                    prefixIcon: faListAlt,
                                  },
                                ]
                              : []),
                            ...((isINHOUSETypeAllowed && showMeritButton) ||
                            (isINHOUSETypeAllowed && (isCAPTypeAllowed || isDirectTypeAllowed))
                              ? [
                                  {
                                    key: 'import-inhouse-applicants',
                                    value: t('upload.inhouse.applicants.text'),
                                    onPress: closeDropdown,
                                    onInputPress: handleImportInhouseApplicants,
                                    typeInput: true,
                                    prefixIcon: faUpload,
                                  },
                                  {
                                    key: 'download-inhouse-appliants',
                                    value: t('download.inhouse.applicant.list.text'),
                                    onPress: () =>
                                      exportApplicationsTemplate(
                                        `admission/template/inhouse/${admissionId}`,
                                        'inhouse_template.xlsx',
                                      ),
                                    prefixIcon: faDownload,
                                  },
                                ]
                              : []),
                            ...(showMeritButton ||
                            (totalCount &&
                              totalCount > 0 &&
                              (isCAPTypeAllowed || isDirectTypeAllowed || isINHOUSETypeAllowed))
                              ? [
                                  {
                                    key: 'assign-class-divisions',
                                    value: t('assignClassDivision.text'),
                                    onPress: () => assignDivisionModal(),
                                    prefixIcon: faUsersClass,
                                  },
                                  ...(!admissionDetail?.lastReportRequest?.status ||
                                  admissionDetail?.lastReportRequest?.status === 'FAILED'
                                    ? [
                                        {
                                          key: 'generate-report',
                                          value: t('generateReport.label'),
                                          onPress: () => handleGenerateAdmissionReports(),
                                          prefixIcon: faFilePlus,
                                        },
                                      ]
                                    : [
                                        {
                                          key: 'export-report',
                                          value: t('exportReport.label'),
                                          onPress: () => {
                                            setDropDown(false);
                                            setExportReportModal(true);
                                            if (refetch) refetch();
                                          },
                                          prefixIcon: faFileExportSolid,
                                        },
                                      ]),
                                ]
                              : []),
                            ...(isPublicAdmission
                              ? [
                                  {
                                    key: 'publicInvites',
                                    value: t('publicInvitations.label'),
                                    onPress: () =>
                                      history.push(
                                        `/public-invitations/${admissionId}/${defaultPagingURLSegment}`,
                                      ),
                                    prefixIcon: faUserPlus,
                                  },
                                ]
                              : []),
                            ...(totalCount && totalCount > 0
                              ? [
                                  {
                                    key: 'send-custom-email',
                                    value: t('sendMessage.text'),
                                    onPress: () => sendMessageFn(),
                                    prefixIcon: faEnvelope,
                                  },
                                ]
                              : []),
                          ]}
                        />
                      )}
                    </ApplicantTypeWrapper>
                  )}
                  {Boolean(
                    admissionDetail?.status === AdmissionStatus.CLOSE && admissionHasApplications,
                  ) && (
                    <ApplicantTypeWrapper>
                      <>
                        <input
                          style={{ display: 'none' }}
                          id="meritApplicantInputType"
                          multiple
                          type="file"
                          accept=".xls,.xlsx"
                          onChange={handleImportApplicants}
                        />

                        <label htmlFor="meritApplicantInputType">
                          <SecondaryBtn onPress={null} label={t('uploadMeritApplicants.text')} />
                        </label>
                        <OutlineButton
                          onPress={exportVerifiedApplicants}
                          label={t('downloadVerifiedApplicants.text')}
                          style={styles.headerOutlineButtonStyle}
                        />
                      </>
                      <OverflowMenuIcon
                        onPress={() => {
                          setDropDown(!dropDown);
                        }}
                      />
                      {dropDown && (
                        <ButtonGrp
                          handleRef={handleOverflowMenuDivRef}
                          width={200}
                          top={36}
                          buttonData={[
                            {
                              key: 'send-custom-email',
                              value: t('sendMessage.text'),
                              onPress: () => sendMessageFn(),
                              prefixIcon: faEnvelope,
                            },
                            {
                              key: 'assign-class-divisions',
                              value: t('assignClassDivision.text'),
                              onPress: () => assignDivisionModal(),
                              prefixIcon: faUsersClass,
                            },
                          ]}
                        />
                      )}
                    </ApplicantTypeWrapper>
                  )}
                </ButtonView>
              </HeaderView>
              {!applicationsLoading ? (
                <TableDiv customStyle={{ zIndex: -1, marginTop: 0 }}>
                  <TblContainer
                    height={totalCount <= rowLimit ? height - 464 : height - 480}
                    overflowX={'scroll'}>
                    {recordsAfterPagingAndSorting() &&
                      recordsAfterPagingAndSorting()?.length > 0 && (
                        <>
                          <TblHead
                            setHeadCells={setHeadCells}
                            dynamicHeadCells={headCells}
                            style={styles.tableHeadCells}
                          />
                          <TableBody>
                            {recordsAfterPagingAndSorting()?.map((item: any, index: number) => (
                              <>
                                <TableRows key={index}>
                                  <ExpandCollapseCell
                                    trow={trow}
                                    current={current}
                                    i={index}
                                    style={{
                                      ...getCellStyle({
                                        trow,
                                        current,
                                        id: index,
                                        isFirstCell: true,
                                        isLastCell: false,
                                        itemId: item?.id,
                                      }),
                                    }}
                                    textValue={properCase(
                                      item?.user?.personalDetails?.fullName || '-',
                                    )}
                                    CollapseFn={() => {
                                      setT(false);
                                      setCurrent(null);
                                    }}
                                    ExpandFn={() => {
                                      setT(true);
                                      setCurrent(index);
                                    }}
                                  />
                                  <TableCells
                                    value={item?.scrollRefNo || '-'}
                                    align={'right'}
                                    style={{
                                      ...getCellStyle({
                                        trow,
                                        current,
                                        id: index,
                                        isFirstCell: false,
                                        isLastCell: false,
                                        itemId: item?.id,
                                      }),
                                    }}
                                  />

                                  <TableCells
                                    value={item?.applicationId}
                                    style={{
                                      ...getCellStyle({
                                        trow,
                                        current,
                                        id: index,
                                        isFirstCell: false,
                                        isLastCell: false,
                                        itemId: item?.id,
                                      }),
                                    }}
                                  />

                                  {isCAPTypeAllowed && (
                                    <TableCells
                                      value={item?.cap?.capId || '-'}
                                      style={{
                                        ...getCellStyle({
                                          trow,
                                          current,
                                          id: index,
                                          isFirstCell: false,
                                          isLastCell: false,
                                          itemId: item?.id,
                                        }),
                                      }}
                                    />
                                  )}

                                  {/* Status */}
                                  {[
                                    ApplicationStatusLabel.BLOCKED,
                                    ApplicationStatusLabel.REJECTED,
                                  ].includes(item.status) &&
                                  checkRemark(item?.statusActivity, item.status, remarkRef)
                                    ?.exist ? (
                                    <TableCells
                                      style={{
                                        ...getCellStyle({
                                          trow,
                                          current,
                                          id: index,
                                          isFirstCell: false,
                                          isLastCell: false,
                                          itemId: item?.id,
                                        }),
                                      }}>
                                      <QuotaWrapper>
                                        <FlexColumnWrapper>
                                          <NormaltextAtom
                                            value={
                                              ApplicationStatusLabel[item?.status] || item?.status
                                            }
                                            color={dynamicColorAndIcon(
                                              item?.status,
                                              item?.fees?.installmentsPending,
                                              item,
                                            )}
                                          />
                                          {item?.reApplied && (
                                            <BadgeWrapper>
                                              <Badge
                                                value={t('reApplied.label')}
                                                color={colors.yellow}
                                              />
                                            </BadgeWrapper>
                                          )}
                                        </FlexColumnWrapper>
                                        <ActionIconWrapper>
                                          <InfoSquareIcon
                                            onPress={() => setRemarkModal(true)}
                                            tooltipTitle={t(remarkRef?.current?.title)}
                                          />
                                        </ActionIconWrapper>
                                      </QuotaWrapper>
                                    </TableCells>
                                  ) : (
                                    <TableCells
                                      style={{
                                        ...getCellStyle({
                                          trow,
                                          current,
                                          id: index,
                                          isFirstCell: false,
                                          isLastCell: false,
                                          itemId: item?.id,
                                        }),
                                      }}>
                                      <FlexColumnWrapper>
                                        <NormaltextAtom
                                          value={
                                            ApplicationStatusLabel[item?.status] || item?.status
                                          }
                                          color={dynamicColorAndIcon(
                                            item?.status,
                                            item?.fees?.installmentsPending,
                                            item,
                                          )}
                                        />
                                        <QuotaWrapper>
                                          {item?.reApplied && (
                                            <BadgeWrapper>
                                              <Badge
                                                value={t('reApplied.label')}
                                                color={colors.yellow}
                                              />
                                            </BadgeWrapper>
                                          )}
                                          {item?.fees?.installmentsPending && (
                                            <BadgeWrapper reApplied={item?.reApplied}>
                                              <Badge
                                                value={t('partial.label')}
                                                color={colors.green}
                                              />
                                            </BadgeWrapper>
                                          )}
                                        </QuotaWrapper>
                                      </FlexColumnWrapper>
                                    </TableCells>
                                  )}

                                  <TableCells
                                    style={{
                                      ...getCellStyle({
                                        trow,
                                        current,
                                        id: index,
                                        isFirstCell: false,
                                        isLastCell: false,
                                        itemId: item?.id,
                                      }),
                                    }}>
                                    <FlexColumnWrapper gap={2}>
                                      <ApplicantTypeWrapper>
                                        <NormaltextAtom
                                          value={item?.paymentFullStatus?.status || '-'}
                                          color={getPaymentStatusColor(
                                            item?.paymentFullStatus?.status,
                                          )}
                                        />
                                        {item?.paymentFullStatus?.status &&
                                          item.paymentFullStatus.status !== 'NA' && (
                                            <PaymentDetailIconWrapper>
                                              <InfoSquareIcon
                                                onPress={() =>
                                                  handlePaymentDetailsModal(item?.paymentDetails)
                                                }
                                                tooltipTitle={t('paymentDetails.label')}
                                              />
                                            </PaymentDetailIconWrapper>
                                          )}
                                      </ApplicantTypeWrapper>
                                      {item?.paymentFullStatus?.status &&
                                        item?.paymentFullStatus?.status !== 'NA' && (
                                          <NormaltextAtom
                                            color={colors.primaryText}
                                            value={`${t('attempts.label')}: ${item
                                              ?.paymentFullStatus?.attempts}`}
                                          />
                                        )}
                                    </FlexColumnWrapper>
                                  </TableCells>

                                  {/* Documents */}
                                  <TableCells
                                    style={{
                                      ...getCellStyle({
                                        trow,
                                        current,
                                        id: index,
                                        isFirstCell: false,
                                        isLastCell: false,
                                        itemId: item?.id,
                                      }),
                                    }}>
                                    {item?.allDocument?.length ? (
                                      <CellExpand
                                        width={160}
                                        align={'center'}
                                        files={fetchDocuments(item)}
                                        showDownloadIcon={false}
                                        customStyle={{ boxShadow: 'none', maxHeight: 200 }}
                                        boxStyle={{ paddingLeft: 0, paddingRight: 0 }}
                                        lines={2}
                                      />
                                    ) : (
                                      <DocumentIcon>
                                        <NormalTextSelect value={'-'} />
                                      </DocumentIcon>
                                    )}
                                  </TableCells>

                                  <TableCells
                                    value={getLastUpdatedDateTime(item?.statusActivity)}
                                    align={'right'}
                                    style={{
                                      ...getCellStyle({
                                        trow,
                                        current,
                                        id: index,
                                        isFirstCell: false,
                                        isLastCell: false,
                                        itemId: item?.id,
                                      }),
                                    }}
                                  />

                                  {/* Actions */}
                                  <TableCells
                                    style={{
                                      ...styles.actionTableCell,
                                      ...getCellStyle({
                                        trow,
                                        current,
                                        id: index,
                                        isFirstCell: false,
                                        isLastCell: true,
                                        itemId: item?.id,
                                      }),
                                    }}>
                                    <TableActionView>
                                      <IconWrapper>
                                        <ViewFileIcon
                                          tooltipTitle={t('view-application.label')}
                                          onPress={() => handleViewApplicationModal(item?.id)}
                                        />
                                      </IconWrapper>
                                      {isAdmissionOpen &&
                                        actionIcons.current[item?.id]?.defaultIcons?.map(
                                          (icon: any, i: number) => {
                                            return (
                                              <IconView>
                                                <Tooltip title={icon?.title}>
                                                  <TouchableOpacity
                                                    onPress={() => {
                                                      if (
                                                        paymentIconsDropDown ||
                                                        admissionIconsDropdown
                                                      ) {
                                                        closeActionIconDropdown();
                                                      }
                                                      getIconFn(item, icon);
                                                    }}>
                                                    {icon?.icon?.iconName ? (
                                                      <ApplicationActionIcon>
                                                        <FontAwesomeIcon
                                                          icon={icon?.icon}
                                                          color={theme?.table?.editIconColor}
                                                          size={16}
                                                        />
                                                      </ApplicationActionIcon>
                                                    ) : (
                                                      <Icon name={icon?.icon} />
                                                    )}
                                                  </TouchableOpacity>
                                                </Tooltip>
                                              </IconView>
                                            );
                                          },
                                        )}
                                      {isAdmissionOpen &&
                                        actionIcons.current[item?.id]?.paymentIcons?.length > 0 && (
                                          <ClickAwayListener
                                            onClickAway={() => setPaymentIconsDropDown(false)}>
                                            <IconView>
                                              <DropdownIconWrapper
                                                onPress={() => {
                                                  setAdmissionIconsDropdown(false);
                                                  setPaymentIconsDropDown(!paymentIconsDropDown);
                                                  setCurrentId(item?.id);
                                                }}>
                                                <Icon name="payment-details" />
                                                <FontAwesomeIcon
                                                  icon={faCaretDown}
                                                  color={colors.secondaryText}
                                                />
                                              </DropdownIconWrapper>
                                            </IconView>
                                          </ClickAwayListener>
                                        )}
                                      {isAdmissionOpen &&
                                        actionIcons.current[item?.id]?.admissionIcons?.length >
                                          0 && (
                                          <ClickAwayListener
                                            onClickAway={() => setAdmissionIconsDropdown(false)}>
                                            <IconView>
                                              <DropdownIconWrapper
                                                onPress={() => {
                                                  setPaymentIconsDropDown(false);
                                                  setAdmissionIconsDropdown(
                                                    !admissionIconsDropdown,
                                                  );
                                                  setCurrentId(item?.id);
                                                }}>
                                                <Icon name="admission-dropdown" />
                                                <FontAwesomeIcon
                                                  icon={faCaretDown}
                                                  color={colors.secondaryText}
                                                />
                                              </DropdownIconWrapper>
                                            </IconView>
                                          </ClickAwayListener>
                                        )}
                                      {item?.status === ApplicationStatus.ADMISSION_COMPLETED &&
                                        !isAdmissionOpen && (
                                          <IconWrapper>
                                            <TransferIcon
                                              onPress={() => transferApplicationFn(item)}
                                              tooltipTitle={t('transfer-admission.label')}
                                            />
                                          </IconWrapper>
                                        )}
                                      {actionIcons?.current[item?.id]?.paymentIcons?.length === 0 &&
                                        item?.paymentFullStatus?.status &&
                                        item.paymentFullStatus.status !== 'NA' && (
                                          <Tooltip title={t('paymentReconcile.label')}>
                                            <CompleteRefundIcon
                                              onPress={() => {
                                                if (
                                                  paymentIconsDropDown ||
                                                  admissionIconsDropdown
                                                ) {
                                                  closeActionIconDropdown();
                                                }
                                                handleReconcilePaymentModal(item?.id);
                                              }}>
                                              <Icon name="reconcile-payment" />
                                            </CompleteRefundIcon>
                                          </Tooltip>
                                        )}
                                      <EditIconWrapper>
                                        <EditIcon
                                          onPress={() => {
                                            if (paymentIconsDropDown || admissionIconsDropdown) {
                                              closeActionIconDropdown();
                                            }
                                            editScrollNoFn(item);
                                          }}
                                          tooltipTitle={t('edit.scrollref.label')}
                                        />
                                      </EditIconWrapper>
                                    </TableActionView>
                                    {paymentIconsDropDown && item?.id === currentId && (
                                      <ButtonGrp
                                        width={225}
                                        top={
                                          item?.reApplied || item?.fees?.installmentsPending
                                            ? 54
                                            : 48
                                        }
                                        right={
                                          actionIcons.current[item?.id]?.admissionIcons?.length > 0
                                            ? 86
                                            : 40
                                        }
                                        buttonData={getActionIconDropdownOptions(
                                          'paymentIcons',
                                          item,
                                        )}
                                      />
                                    )}
                                    {admissionIconsDropdown && item?.id === currentId && (
                                      <ButtonGrp
                                        width={225}
                                        top={
                                          item?.reApplied || item?.fees?.installmentsPending
                                            ? 54
                                            : 48
                                        }
                                        right={40}
                                        buttonData={getActionIconDropdownOptions(
                                          'admissionIcons',
                                          item,
                                        )}
                                      />
                                    )}
                                  </TableCells>
                                </TableRows>
                                {trow && current === index && (
                                  <TableRows>
                                    <TableCells
                                      colspan={isCAPTypeAllowed ? 9 : 8}
                                      style={styles.nestedTableStyle}>
                                      <NestedApplicantDetail
                                        item={item}
                                        referrerOnPress={referrerOnPress}
                                      />
                                    </TableCells>
                                  </TableRows>
                                )}
                              </>
                            ))}
                          </TableBody>
                        </>
                      )}
                    {recordsAfterPagingAndSorting() &&
                      recordsAfterPagingAndSorting()?.length === 0 &&
                      !applicationsLoading && (
                        <NoRecordsFound
                          maxHeight={height - 480}
                          colspan={0}
                          icon={'application'}
                          label={'noApplicationsFound.text'}
                        />
                      )}
                  </TblContainer>
                  <Pagination
                    pathName={`admissions/${admissionId}/detail`}
                    total={totalCount}
                    page={currentPage}
                    setCurrentPage={setCurrentPage}
                    rowLimit={rowLimit}
                    setRowLimit={setRowLimit}
                    hidePagination={!!(totalCount && totalCount <= rowLimit)}
                  />
                </TableDiv>
              ) : (
                <SpinnerView>
                  <LoaderSpinner size="large" />
                </SpinnerView>
              )}
            </>
          </>
        ) : (
          <SpinnerView>
            <LoaderSpinner size="large" />
          </SpinnerView>
        )}

        {/* Manage / Edit Admission Form */}
        {form.state === 'OPEN' && form.step === 'AdmissionForm' && admissionDetail?.id && (
          <AdmissionForm
            form={{ state: 'OPEN', mode: 'EDIT', admissionID: admissionDetail.id }}
            onSuccess={onAdmissionEditSuccess}
            onError={onAdmissionEditError}
            onClose={onCloseForm}
          />
        )}

        {/* Accept Application Form */}
        {form.state === 'OPEN' && form.step === 'AcceptApplicationForm' && (
          <AcceptApplicationForm
            form={form}
            onSuccess={onSuccess}
            onError={onError}
            onClose={onCloseForm}
            reservations={admissionDetail?.criteria?.reservationCriteria?.reservations ?? []}
          />
        )}

        {/* Reject / Block Application Form */}
        {form.state === 'OPEN' && form.step === 'RejectBlockApplicationForm' && (
          <RejectBlockApplicationForm
            form={form}
            onSuccess={onSuccess}
            onError={onError}
            onClose={onCloseForm}
          />
        )}

        {/* Partial Fee Payment Form */}
        {form.state === 'OPEN' && form.step === 'PartialPaymentForm' && form.data && (
          <PartialFeePayment
            paymentFor={'admission_application'}
            form={form as TForm & { data: TPartialFeePaymentData }}
            onSuccess={onSuccess}
            onError={onError}
            onClose={onCloseForm}
          />
        )}

        {/* Manage Rounds */}
        {form.state === 'OPEN' && form.step === 'ManageRounds' && (
          <ManageRounds
            onCloseListing={onCloseForm}
            refetch={refetch}
            admissionDetail={admissionDetail}
          />
        )}

        {/* Assign Division Form */}
        {form.state === 'OPEN' && form.step === 'AssignDivisionForm' && (
          <AssignDivisionForm
            form={form}
            onSuccess={onSuccess}
            onError={onError}
            onClose={onCloseForm}
          />
        )}

        <NormalModal
          onAlert={null}
          setModalVisible={closeMakePaymentModal}
          Headerpopup={t('payment.make-payment')}
          modalVisible={makePaymentModal}
          handleSave={makePayment}
          cancelButtonLabel={'cancel.label'}
          addEditButtonLabel={'payment.proceed-payment'}
          width={700}
          contentStyles={{ paddingTop: 0 }}
          maxWidth={'md'}
          disabled={!currentFeeDetails?.feeBook || currentFeeDetails?.feeBook?.length === 0}>
          <ApplicationPayableListing
            data={currentItem}
            feeBooks={currentFeeDetails?.feeBook}
            totalPayable={currentFeeDetails?.totalPayable}
            partialPayment={partialPayment}
            cancellationCharge={cancellationCharge}
          />
        </NormalModal>

        <NormalModal
          setModalVisible={() => setSubjGrpsModalState(false)}
          Headerpopup={t('subjectGroups.label')}
          modalVisible={subjGrpsModalState}
          handleSave={() => {}}
          showFooter={false}
          width={544}>
          <MultiChipsWrapper>
            <MultiChipsDisplay
              chipsArray={admissionDetail?.subjectGroups.map(
                (subGroup: { subjectGroup: { label: string } }) => {
                  return subGroup?.subjectGroup?.label;
                },
              )}
            />
          </MultiChipsWrapper>
        </NormalModal>

        <NormalModal
          setModalVisible={() => setQualModalState(false)}
          Headerpopup={t('qualifications.label')}
          modalVisible={qualModalState}
          handleSave={() => {}}
          showFooter={false}
          maxWidth={'sm'}>
          <MultiChipsWrapper>
            <MultiChipsDisplay
              chipsArray={
                admissionDetail?.criteria
                  ? admissionDetail.criteria?.qualifications.map(qualification => {
                      return qualification?.name?.name;
                    })
                  : []
              }
            />
          </MultiChipsWrapper>
        </NormalModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closePaymentModal}
          modalVisible={paymentModalState}
          handleSave={handleSubmit(handleMakeAdmissionPayment)}
          Headerpopup={t('payment.make-payment')}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel="save.label"
          lineHeight={32}
          height={512}>
          <EditPaymentForm
            control={control}
            errors={errors}
            reset={reset}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            totalPayableAmount={
              cancellationCharge
                ? cancellationCharge
                : partialPayment
                  ? partialPayment
                  : currentFeeDetails?.totalPayable
            }
          />
        </NormalModal>

        <NormalModal
          setModalVisible={closeRemarkModal}
          modalVisible={remarkModal}
          infoModal={true}
          Headerpopup={t(remarkRef?.current?.title)}
          preventRedirectOnClose={true}
          handleSave={() => null}>
          <BlockRejectionReason data={remarkRef?.current} />
        </NormalModal>

        <NormalModal
          setModalVisible={closeReferrerModal}
          modalVisible={referrerViewModal}
          infoModal={true}
          width={440}
          Headerpopup={t('referrer.label')}
          preventRedirectOnClose={true}
          handleSave={() => null}>
          <NormalTextSelect
            value={referrer?.current}
            fontFamily={fonts.regular}
            fontsize={1.8}
            lineHeight={3}
          />
        </NormalModal>

        <NormalModal
          setModalVisible={closeClearConcessionValues}
          modalVisible={concessionModal}
          Headerpopup={t('selectFeeConcession.label')}
          height={644}
          maxWidth={'md'}
          showFooter={false}
          hideCloseIcon={true}
          handleSave={() => null}>
          <SelectConcessionForm
            onSubmit={selectFeeCompositionOnSubmit}
            closeModal={closeClearConcessionValues}
            feeConcessionOptions={concessionOptions}
            concessionCompositionId={concessionCompositionId}
            concessionDataAvailable={concessionDataAvailable}
          />
        </NormalModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeScrollNoFn}
          modalVisible={editScrollRefModal}
          Headerpopup={t('edit.scrollref.label')}
          handleSave={handleSubmitScroll(handleScrollEdit)}
          addEditButtonLabel={'save.label'}
          width={540}>
          <EditScrollRefForm
            control={controlScroll}
            errors={errorsScroll}
            setValue={setValueScroll}
            currentScrollRefNo={currentItem?.scrollRefNo || ''}
          />
        </NormalModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeTransferApplicationFn}
          modalVisible={transferApplModal}
          Headerpopup={t('transfer-admission.label')}
          handleSave={handleSubmitTransfer(handleTransferOnSubmit)}
          addEditButtonLabel={'transfer.label'}
          width={540}>
          <TransferForm
            control={controlTransfer}
            errors={errorsTransfer}
            setValue={setValueTransfer}
          />
        </NormalModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeSendMessageFn}
          modalVisible={sendMessageModal}
          Headerpopup={t('sendMessage.text')}
          handleSave={handleSubmitMessage(handleSendMessageOnSubmit)}
          addEditButtonLabel={'send.label'}
          width={788}
          maxWidth={'md'}>
          <MessageComposer
            control={controlMessage}
            errors={errorsMessage}
            setError={setErrorMessage}
            clearErrors={clearErrorsMessage}
            recepientOptions={subjectGroupOptions}
            statusOptions={AdmissionStatusDropdownOptions}
          />
        </NormalModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={() => {
            resetAndClearCapApplicantForm();
            setInviteCapApplicant(false);
          }}
          width={578}
          modalVisible={inviteCapApplicant}
          Headerpopup={t('inviteCapApplicant.text')}
          handleSave={handleSubmitCAP(handleInviteCapApplicant)}
          addEditButtonLabel={'invite.label'}>
          <SearchInviteCapApplicantForm
            control={controlCAP}
            errors={errorsCAP}
            reset={resetCAP}
            setValue={setValueCap}
            getValues={getValuesCap}
            clearErrors={clearErrorsCap}
            setError={setErrorCap}
            watch={watchValuesCap}
            admissionId={admissionDetail?.id}
            subjectGroupOptions={subjectGroupOptions}
          />
        </NormalModal>
        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={() => {
            resetAndClearDirectApplicantForm();
            setInviteDirectApplicant(false);
          }}
          modalVisible={inviteDirectApplicant}
          Headerpopup={t('inviteDirectApplicant.text')}
          handleSave={handleSubmitDIRECT(handleInviteDirectApplicant)}
          addEditButtonLabel={'invite.label'}
          width={640}>
          <DirectApplicantInviteForm
            control={controlDIRECT}
            errors={errorsDIRECT}
            setValue={setValueDIRECT}
            subjectGroupOptions={subjectGroupOptions}
          />
        </NormalModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeCancelAdmissionModal}
          modalVisible={cancelAdmissionModal}
          handleSave={handleSubmitCancelAdmission(handleCancelAdmission)}
          Headerpopup={t('cancelAdmission.label')}
          width={544}
          hideCloseIcon={true}
          addEditButtonLabel={'proceed.label'}
          disabled={disableCancelModal}>
          <CancelAdmissionForm
            control={controlCancelAdmission}
            errors={errorsCancelAdmission}
            reset={resetCancelAdmission}
            setValue={setValueCancelAdmission}
            getValues={getValuesCancelAdmission}
            clearErrors={clearErrorsCancelAdmission}
            setError={setErrorCancelAdmission}
            applicationDetail={currentItem}
            setDisableCancelModal={setDisableCancelModal}
            disableCancelModal={disableCancelModal}
            cancellationAmount={cancellationAmount}
            setCancellationAmount={setCancellationAmount}
          />
        </NormalModal>

        <NormalModal
          setModalVisible={closePaymentRefundModal}
          modalVisible={paymentRefundModal}
          infoModal={true}
          width={440}
          Headerpopup={t('refundAmount.label')}
          preventRedirectOnClose={true}
          handleSave={() => null}>
          <MediumTextSelect
            value={`Refund amount of \u20B9 ${refundAmount} is currently in process.`}
          />
        </NormalModal>

        <NormalModal
          setModalVisible={closePaymentDetailsModal}
          modalVisible={paymentDetailsModal}
          infoModal={true}
          width={720}
          height={580}
          maxWidth='md'
          Headerpopup={t('paymentDetails.label')}
          preventRedirectOnClose={true}
          handleSave={() => null}
          visibility={!editPaymentModal && !completeRefundModalState}>
          <PaymentDetailsModal
            paymentIds={paymentIds}
            setEditPaymentModal={setEditPaymentModal}
            editPaymentModal={editPaymentModal}
            completeRefundModalState={completeRefundModalState}
            setCompleteRefundModalState={setCompleteRefundModalState}
          />
        </NormalModal>

        <NormalModal
          setModalVisible={closeChangeFeeCollectionModal}
          modalVisible={changeFeeCollectionModal}
          Headerpopup={t('changeFeeCollection.label')}
          width={414}
          showFooter={false}
          hideCloseIcon={true}
          handleSave={() => null}>
          <ChangeFeeCollectionForm
            closeModal={closeChangeFeeCollectionModal}
            onSubmit={handleChangeFeeCollection}
            isSubmitting={!canClick}
            applicationDetail={currentItem}
          />
        </NormalModal>

        <NormalModal
          showFooter={false}
          hideCloseIcon={false}
          isSubmitting={!canClick}
          setModalVisible={closeExportReportModal}
          modalVisible={exportReportModal}
          handleSave={() => {}}
          Headerpopup={t('exportReport.label')}
          width={544}
          hideSubmit={false}>
          <ExportReportForm
            currentReport={admissionDetail?.reportRequest}
            previousReport={admissionDetail?.lastReportRequest}
            handleRegenerateReport={handleGenerateAdmissionReports}
            admissionId={admissionId}
          />
        </NormalModal>

        <DeleteModal
          isSubmitting={!canClick}
          setModalVisible={closeSchedulePaymentModal}
          modalVisible={schedulePaymentModal}
          handleSave={() =>
            handleApplicationAction(
              currentId,
              schedulePayment,
              'schedulePayment.text',
              closeSchedulePaymentModal,
            )
          }
          deleteButtonColor={colors.primaryColor}
          deleteButtonText={'done.label'}
          Headerpopup={t('schedulePayment.label')}>
          {t('schedulePayment.message.text')}
        </DeleteModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeImportDirectApplicationsModal}
          Headerpopup={t('uploadDirectApplications.text')}
          modalVisible={importDirectApplicantsModal}
          handleSave={handleSubmitImportDirectApplications(handleImportDirectApplications)}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel="import.label"
          height={250}>
          <ImportDirectApplicationsForm
            control={controlImportDirectApplications}
            errors={errorsImportDirectApplications}
            subjectGroups={admissionDetail?.subjectGroups}
            handleFileUpload={handleDirectApplicationsUpload}
            importAttachment={importAttachment}
          />
        </NormalModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeReconcilePaymentModal}
          modalVisible={reconcilePaymentModal}
          handleSave={handleReconcileAdmissionPayment}
          Headerpopup={t('paymentReconcile.label')}
          width={544}
          hideCloseIcon={true}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel="proceed.label"
          disabled={!confirmSelection}>
          <ReconcilePaymentForm
            confirmSelection={confirmSelection}
            setConfirmSelection={setConfirmSelection}
            reconcilePaymentData={reconcilePaymentData}
          />
        </NormalModal>

        <NormalModal
          setModalVisible={closeViewApplication}
          modalVisible={viewApplication}
          infoModal={true}
          maxWidth={'lg'}
          height={0.7 * height}
          preventRedirectOnClose={true}
          Headerpopup={t('application.label')}
          handleSave={() => {}}>
          <ApplicationDetailsAdmission applicationId={currentId} />
        </NormalModal>
      </LoadContentWrapper>
    </>
  );
}

const styles = {
  loadContent: { minHeight: 592, height: height - 124 },
  nestedTableStyle: { padding: 0 },
  tableHeadCells: { position: 'relative', zIndex: 3 },
  actionTableCell: { position: 'relative' },
  headerOutlineButtonStyle: { marginLeft: 16, marginRight: 6, height: 30 },
};

const IconWrapper = styled.View`
  width: 24px;
  margin-right: 6px;
  align-items: center;
`;

const InfoWrapper = styled.View`
  margin-bottom: 24px;
  flex: 1;
`;

const Row1 = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Row2 = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

const RowItem = styled.View`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-right: 8px;
`;

const ChipArrayWrapper = styled.View<{ maxWidth: string; marginRight?: string }>`
  display: flex;
  flex-direction: column;
  min-width: 176px;
  max-width: ${({ maxWidth }) => maxWidth};
  margin-right: ${({ marginRight }) => marginRight};
`;

const ChipsScroll = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
  margin-top: 8px;
`;

const ShowMore = styled.TouchableOpacity``;

const SpinnerView = styled.View`
  height: 547px;
  justify-content: center;
  align-items: center;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const IconView = styled.View<{ marginRight?: boolean }>`
  margin-right: 6px;
  display: flex;
  flex-direction: row;
`;

const DocumentIcon = styled.View`
  align-items: center;
`;

const HeaderView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 10;
  flex-wrap: nowrap;
`;

const ButtonView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ManageView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 12px;
`;

const InfoView = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-height: 208px;
`;

const RoundView = styled.View`
  display: flex;
  flex-direction: row;
`;

const ApplicantTypeWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QuotaWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const FlexColumnWrapper = styled.View<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : 0)};
`;

const ActionIconWrapper = styled.View`
  margin-left: 8px;
  padding-bottom: 2px;
`;

const BadgeWrapper = styled.View<{ reApplied?: boolean }>`
  display: flex;
  align-items: flex-start;
  margin-top: 4px;
  max-width: 88px;
  margin-left: ${({ reApplied }) => (reApplied ? '8px' : '0px')};
`;

const ApplicationActionIcon = styled.View`
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
`;

const MultiChipsWrapper = styled.View`
  margin-bottom: 24px;
`;

const FilterWrapper = styled.View<{ isFilterPresent: boolean; filterHeight: number }>`
  left: 0;
  flex: 1;
  align-items: flex-start;
  height: ${props => (props.isFilterPresent ? props.filterHeight * 32 : 0)}px;
  max-height: 60px;
  width: 100%;
  margin-right: 8px;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  max-width: 200px;
  margin-right: 8px;
`;

const EditIconWrapper = styled(IconWrapper)`
  margin-right: 0px;
`;

const CompleteRefundIcon = styled.TouchableOpacity`
  width: 24px;
  margin-right: 6px;
  align-items: center;
`;

const DropdownIconWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PaymentDetailIconWrapper = styled(ActionIconWrapper)`
  padding-bottom: 0px;
`;
