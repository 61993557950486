import { colors, fonts } from "../../../styles/theme/styles";
import {
  faFileCertificate,
  faUniversity,
} from "@fortawesome/pro-regular-svg-icons";
import {
  softenColor,
  stringToHexColor,
} from "src/components/organism/Schedule/scheduleHelpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MediumTextSelect from "../Text/MediumTextSelect";
import NormaltextAtom from "../Text/NormalTextAtom";
import React from "react";
import { properCase } from "src/utils/utility";
import styled from "styled-components";

export const InstituteItem = (props: InstituteItem) => {
  const uniqueColor = stringToHexColor(props?.id ?? `${props.index}`);
  return (
    <ItemContainer isLastItem={props.index === props.length - 1}>
      <Icon bgColor={softenColor(uniqueColor)}>
        <FontAwesomeIcon icon={faUniversity} color={uniqueColor} />
      </Icon>
      <Content>
        <MediumTextSelect value={props.name} />
        <NormaltextAtom
          value={props.location}
          fontSize={1.6}
          fontFamily={fonts.light}
        />
      </Content>
    </ItemContainer>
  );
};

export const CourseItem = (props: CourseItem) => {
  const uniqueColor = stringToHexColor(props?.id ?? `${props.index}`);
  return (
    <ItemContainer isLastItem={props.index === props.length - 1}>
      <Icon bgColor={softenColor(uniqueColor)}>
        <FontAwesomeIcon icon={faFileCertificate} color={uniqueColor} />
      </Icon>
      <Content>
        <MediumTextSelect value={props.name} />
        <NormaltextAtom
          value={`${props.duration} ${properCase(props.durationType)}`}
          fontSize={1.6}
          fontFamily={fonts.light}
        />
      </Content>
    </ItemContainer>
  );
};

const ItemContainer = styled.div<{ isLastItem: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => theme.rem(0.1)} solid ${colors.inputBorder};
  padding: ${({ theme }) => theme.rem([2.1, 2, 2.1])} 0;
  box-sizing: border-box;
  width: 100%;
  border-bottom: ${({ isLastItem }) => (isLastItem ? "none" : "")};
`;

const Icon = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 50%;
  padding: ${({ theme }) => theme.rem(0.5)};
  width: ${({ theme }) => theme.rem(3.2)};
  height: ${({ theme }) => theme.rem(3.2)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.rem(1.5)};
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.rem(1.7)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

interface InstituteItem {
  id: string;
  name: string;
  location?: string;
  index: number;
  length: number;
}

interface CourseItem {
  id: string;
  name: string;
  duration: string;
  durationType: string;
  index: number;
  length: number;
}
