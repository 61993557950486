import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 24 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00331 8.43809C6.94332 9.49808 6.34783 10.9357 6.34783 12.4348C6.34783 13.9338 6.94332 15.3715 8.00331 16.4315C9.0633 17.4915 10.5009 18.087 12 18.087C13.499 18.087 14.9367 17.4915 15.9967 16.4315C17.0567 15.3715 17.6522 13.9338 17.6522 12.4348C17.6522 10.9357 17.0567 9.49808 15.9967 8.43809C14.9367 7.3781 13.499 6.78261 12 6.78261C10.5009 6.78261 9.0633 7.3781 8.00331 8.43809ZM12 16.6174C14.3086 16.6174 16.1804 14.7434 16.1777 12.4348C16.175 10.1289 14.3059 8.262 12 8.262C9.69408 8.262 7.82502 10.1289 7.82231 12.4348C7.81959 14.7434 9.69137 16.6174 12 16.6174Z"
        fill="black"
        fill-opacity="0.6"
      />
      <Path
        d="M12.4592 10.0459V9.73234H12.4614C12.4614 9.4696 12.2473 9.25544 11.9845 9.25544C11.7218 9.25544 11.5076 9.4696 11.5076 9.73234V10.0878C11.373 10.1187 11.2427 10.1607 11.1213 10.2137C10.7238 10.3859 10.3485 10.706 10.2602 11.1873C10.1675 11.6973 10.386 12.1566 10.8166 12.4326C11.1252 12.6303 11.5343 12.7468 11.865 12.841L11.8808 12.8454C11.9316 12.8609 11.9801 12.8741 12.0309 12.8874C12.2936 12.958 12.5696 13.0331 12.7926 13.1855C13.0532 13.3621 12.9273 13.6778 12.6624 13.7838C12.4659 13.8633 12.1656 13.8964 11.7902 13.8412C11.5341 13.7993 11.278 13.7131 11.0285 13.6292C10.9733 13.6116 10.9181 13.5917 10.8629 13.574C10.6134 13.4901 10.3441 13.627 10.2602 13.8765C10.1763 14.126 10.3132 14.3976 10.5627 14.4793C10.874 14.5808 11.1875 14.6824 11.5076 14.7553V15.1394C11.5076 15.4022 11.7218 15.6163 11.9845 15.6163C12.2473 15.6163 12.4614 15.4022 12.4614 15.1394V14.8038C12.6579 14.7818 12.8456 14.7376 13.0178 14.667C13.4307 14.4992 13.7751 14.1746 13.8657 13.68C13.9606 13.1678 13.7597 12.6909 13.3314 12.3972C13.0024 12.172 12.563 12.0462 12.2186 11.949C12.1678 11.9358 12.117 11.9203 12.064 11.9071L12.0514 11.9037C11.8035 11.8363 11.5438 11.7657 11.331 11.6289C11.055 11.4523 11.2559 11.1962 11.4988 11.0902C11.7704 10.9732 12.1082 10.9467 12.3996 11.0019C12.5564 11.0328 12.7109 11.0747 12.8655 11.1189C12.9008 11.1277 12.9339 11.1388 12.9693 11.1476C13.2232 11.216 13.4859 11.0681 13.5566 10.8142C13.6272 10.5603 13.4771 10.2976 13.2232 10.2269C13.1812 10.2137 13.1415 10.2026 13.0995 10.1916C12.8876 10.132 12.6734 10.0723 12.4592 10.0459Z"
        fill="black"
        fill-opacity="0.6"
      />
      <Path
        d="M21.7816 5.98984C22.0728 5.70689 22.0728 5.24737 21.7816 4.96442L18.9243 2.21221C18.6331 1.92926 18.1603 1.92926 17.8691 2.21221C17.5779 2.49516 17.5779 2.95468 17.8691 3.23763L19.457 4.75391H3.61495C3.20266 4.75391 2.86957 5.0776 2.86957 5.47826C2.86957 5.87892 3.20266 6.20261 3.61495 6.20261H19.4546L17.8714 7.71889C17.5803 8.00184 17.5803 8.46136 17.8714 8.74431C18.1626 9.02726 18.6354 9.02726 18.9266 8.74431L21.784 5.9921L21.7816 5.98984Z"
        fill="black"
        fill-opacity="0.6"
      />
      <Path
        d="M2.21837 19.9029C1.92721 19.6199 1.92721 19.1604 2.21837 18.8775L5.07572 16.1253C5.36689 15.8423 5.83974 15.8423 6.13091 16.1253C6.42207 16.4082 6.42207 16.8677 6.13091 17.1507L4.54304 18.6669H20.3851C20.7973 18.6669 21.1304 18.9906 21.1304 19.3913C21.1304 19.792 20.7973 20.1157 20.3851 20.1157H4.54537L6.12858 21.6319C6.41974 21.9149 6.41974 22.3744 6.12858 22.6574C5.83741 22.9403 5.36456 22.9403 5.0734 22.6574L2.21605 19.9051L2.21837 19.9029Z"
        fill="black"
        fill-opacity="0.6"
      />
    </Svg>
  );
}

export default SvgComponent;
