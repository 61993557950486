import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import styled from 'styled-components/native';
import EditPaymentStatusForm from '../../../../form-json/payment/payment-mode.json';
import Element from '../../Forms/ApplicationElement';
import QRCode from 'react-qr-code';
import { isMobile, isWeb } from 'src/constant/device';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import LargeButtonText from 'src/components/atoms/Text/LargeButtonText';
import { colors } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { PAYMENT_MODE_LABEL, PaymentMode } from 'src/constant';
import { checkFields } from '../Institute/utility';

const PaymentModal = (props: any) => {
  const {
    isSubmitting,
    control,
    errors,
    reset,
    data,
    showQR,
    setValue,
    handleMakePayment,
    setMode,
    clearErrors,
    totalPayableAmount,
    paymentModes
  } = props;
  const { t } = useI18n();
  const [elements, setElements] = useState<any>({});
  const [dropDownValue, setDropDownValue] = useState('');
  const { fields }: any = elements ?? {};

  useEffect(() => {
    return () => {
      setDropDownValue('');
      if (reset) reset();
    };
  }, []);

  useEffect(() => {
    if (paymentModes && paymentModes?.length > 0) {
      const modes = paymentModes?.map((item: string) => {
        return {
          label: PAYMENT_MODE_LABEL[item],
          value: item,
        }
      })
      EditPaymentStatusForm[0].fields[0].option = modes;
    }
    setElements(EditPaymentStatusForm[0]);
  }, [EditPaymentStatusForm[0]]);

  function setAmount() {
    setValue('payment_amount', totalPayableAmount.toString());
  }

  const handleCustomSelect = (id: string, data: any) => {
    setValue('cheque_number', '');
    setValue('draft_number', '');
    setValue('neft_transaction_id', '');
    setValue('bank_name', '');
    setValue('date', '');
    clearErrors();
    if (isWeb) {
      setDropDownValue(data?.value);
      setMode(data?.value);
      setAmount();
    } else {
      setDropDownValue(data);
      setAmount();
    }
  };

    return (
        <View>
          {showQR ? (
            <ContainerView>
              <QRCode value={JSON.stringify(data)} size={233} />
              <TextView>
                <LargeButtonText
                  value={t("show-qr.text")}
                  color={colors.primaryText}
                  textAlign={'center'}
                  lineHeight={2.4} />
              </TextView>
            </ContainerView>
          ) : (
            <View>
              {fields ? (
                fields.map((field: any, i: number) => {
                  return (
                    <View key={`paymentf${i}`} testID={'paymentModes'}>
                      {(field?.id === 'mode' || checkFields(field?.id, dropDownValue, false)) && (
                        <Element
                          key={i}
                          field={field}
                          control={control}
                          errors={errors}
                          handleCustomSelect={handleCustomSelect}
                        />
                      )}
                    </View>
                  )})
                ) : (
                <SpinnerView>
                  <LoaderSpinner />
                </SpinnerView>
                )}
          {isMobile && handleMakePayment && <SecondaryBtn onPress={handleMakePayment} label={'Proceed'} canLoad={isSubmitting} />}
            </View>
          )}
      </View>
    )
}

const SpinnerView = styled.View`
  width: 370px;
  height: 547px;
  justify-content: center;
  align-items: center;
`;

const TextView = styled.View`
  margin-top: ${isWeb ? 40 : 70}px
`;

const ContainerView = styled.View`
  height: 512;
  justify-content: center;
  align-items: center;
`;

export default PaymentModal;