import { FieldValues, UseFormSetValue } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { TableCells, TableRows } from "../../Table/TableAtom";

import { DropdownOptions } from "src/types";
import { ERROR } from "src/constant";
import FeeCompositionSelection from "../../Fee/FeeCompositionSelection/FeeCompositionSelection.web";
import Input from "src/components/atoms/Input/input.web";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import SmallTextAtom from "src/components/atoms/Text/SmallTextAtom";
import { TableBody } from "@mui/material";
import TriggerSelector from "src/components/atoms/Button/TriggerSelector";
import { TsubjectGroupsWithFeeSeatData } from "./types";
import { colors } from "src/styles/theme/styles";
import { feeSeatAllocationFormHelper as helpers } from "./AdmissionFormHelpers";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useI18n } from "src/i18n/hooks";
import useTable from "../../Table/UseTable";

type Props = {
  onClose: () => void;
  goBack: () => void;
  handleSave: () => void;
  editMode: boolean;
  modalTitle: string;
  subjectGroups: TsubjectGroupsWithFeeSeatData;
  totalSeatsAvailability: string;
  setValue: UseFormSetValue<FieldValues>;
};

type TDetail = {
  label: string;
  value: string;
  seats: number;
  fee?: DropdownOptions;
  hasError: boolean;
};

type feeSeatAllocMap = Map<string, TDetail>;

const FeeSeatAllocation = (props: Props) => {
  const {
    onClose,
    goBack,
    handleSave,
    editMode,
    modalTitle,
    subjectGroups,
    totalSeatsAvailability,
    setValue,
  } = props;
  const totalSeats = parseInt(totalSeatsAvailability);
  const { setAlertDetails } = useAlertSystem();
  const { t } = useI18n();
  const [headCells, setHeadCells] = useState(
    helpers.feeSeatAllocationTableHeaders
  );
  const [loading, setLoading] = useState<boolean>(!editMode);
  const [triggeredSubjGrp, setTriggeredSubjGrp] = useState<string | null>(null);
  const [feeSeatMap, setFeeSeatMap] = useState<feeSeatAllocMap>();

  const { TblContainer, TblHead } = useTable(null, headCells);

  useEffect(() => {
    const copyMap = new Map(feeSeatMap);
    if (!editMode) {
      subjectGroups.map((subGrp, index) => {
        copyMap.set(subGrp.value, {
          label: subGrp.label,
          value: subGrp.value,
          fee: subGrp?.fee,
          hasError: false,
          seats: index === 0 ? totalSeats : 0,
        });
      });
      setFeeSeatMap(copyMap);
      setLoading(false);
    } else {
      const sumSeats = helpers.sumSeats(subjectGroups);
      const firstFieldSeats = totalSeats - sumSeats;
      subjectGroups.map((subGrp, index) => {
        const seats =
          index === 0
            ? Math.max(subGrp.seats + firstFieldSeats, 0)
            : subGrp.seats;

        copyMap.set(subGrp.value, {
          label: subGrp.label,
          value: subGrp.value,
          fee: subGrp?.fee,
          hasError: false,
          seats,
        });
      });
      setFeeSeatMap(copyMap);
      setLoading(false);
    }
  }, [editMode, totalSeatsAvailability]);

  const onSeatChange = (subGrp: TDetail, inputVal: number) => {
    const firstField = feeSeatMap!.get(subjectGroups[0].value); // non-null assertion
    const validatedInput = Number.isInteger(inputVal) ? inputVal : 0;
    const copyMap = new Map(feeSeatMap);

    copyMap.set(subGrp.value, {
      hasError: false,
      label: subGrp.label,
      value: subGrp.value,
      fee: subGrp?.fee,
      seats: validatedInput,
    });

    const updatedFirstFieldSeats = Math.max(
      totalSeats +
      firstField!.seats -
      helpers.sumSeats(Array.from(copyMap.values())),
      0
    );

    copyMap.set(firstField!.value, {
      hasError: false,
      label: firstField!.label,
      value: firstField!.value,
      fee: firstField?.fee,
      seats: updatedFirstFieldSeats,
    });
    setFeeSeatMap(copyMap);
  };

  const onFeeCompDelete = (subGrp: TDetail) => {
    const copyMap = new Map(feeSeatMap);
    copyMap.set(subGrp.value, {
      hasError: subGrp.hasError,
      label: subGrp.label,
      value: subGrp.value,
      fee: undefined,
      seats: subGrp.seats,
    });
    setFeeSeatMap(copyMap);
  };

  const onCompositionSelect = (selectedFeeComp: DropdownOptions | null) => {
    if (feeSeatMap && triggeredSubjGrp && selectedFeeComp?.value) {
      const prev = feeSeatMap.get(triggeredSubjGrp);
      const copyMap = new Map(feeSeatMap);
      copyMap.set(triggeredSubjGrp, {
        hasError: prev!.hasError,
        label: prev!.label,
        value: prev!.value,
        fee: selectedFeeComp,
        seats: prev!.seats,
      });
      setFeeSeatMap(copyMap);
      setTriggeredSubjGrp(null);
    }
  };

  const onSubmitFn = () => {
    const sum = helpers.sumSeats(Array.from(feeSeatMap!.values()));
    if (sum > totalSeats) {
      setAlertDetails({ message: t("error.totalSeats.text"), level: ERROR });
      return;
    }

    const copyMap = new Map(feeSeatMap);
    let errorDetected: boolean = false;
    feeSeatMap!.forEach((value, key) => {
      if (value.seats === 0) {
        copyMap.set(key, {
          label: value.label,
          value: value.value,
          seats: value.seats,
          fee: value?.fee,
          hasError: true,
        });
        errorDetected = true;
      }
    });
    if (errorDetected) {
      setFeeSeatMap(copyMap);
      return;
    }

    const mappedArray = Array.from(copyMap.values());
    if (mappedArray.every((obj) => !obj.hasError)) {
      const formValue = mappedArray.map((subjGrp) => {
        return {
          ...(subjGrp?.fee && subjGrp.fee?.value
            ? { fee: subjGrp.fee.value }
            : {}),
          seats: subjGrp.seats,
          subjectGroup: subjGrp.value,
        };
      });
      setValue("subjectGroups", formValue);
      handleSave();
    }
  };

  return (
    <>
      <NormalModal
        modalVisible={true}
        height={560}
        maxWidth={"lg"}
        setModalVisible={onClose}
        Headerpopup={modalTitle}
        addEditButtonLabel={"save.label"}
        cancelButtonLabel={"previous.label"}
        onCancelButtonClick={goBack}
        handleSave={onSubmitFn}
        visibility={!triggeredSubjGrp}
      >
        <>
          {!loading ? (
            <TblContainer>
              <TblHead setHeadCells={setHeadCells} />
              <>
                <TableBody>
                  {feeSeatMap &&
                    subjectGroups?.map((subGrp, index) => {
                      const detail = feeSeatMap.get(subGrp.value);
                      return (
                        detail && (
                          <TableRows key={detail.value}>
                            <TableCells value={detail.label ?? "-"} />
                            <TableCells>
                              <TriggerSelector
                                inputContainerWidth={"100%"}
                                selectedValueLabel={detail?.fee?.value ?? ""}
                                displayText={
                                  detail?.fee?.label ??
                                  t("selectFeeComposition.label")
                                }
                                textColor={colors.secondaryText}
                                onPress={() => {
                                  setTriggeredSubjGrp(detail.value);
                                }}
                                onDelete={() => onFeeCompDelete(detail)}
                              />
                            </TableCells>
                            <TableCells style={styles.noPadding}>
                              <Input
                                id={detail.value}
                                width={150}
                                value={detail.seats}
                                disabled={index === 0}
                                setValue={(e: { target: { value: string } }) =>
                                  onSeatChange(detail, parseInt(e.target.value))
                                }
                              />
                              <Error>
                                <SmallTextAtom
                                  value={
                                    detail.hasError
                                      ? t("error.seatAvailability.required")
                                      : "\u{200B}"
                                  }
                                  color={colors.errorColor}
                                />
                              </Error>
                            </TableCells>
                          </TableRows>
                        )
                      );
                    })}
                </TableBody>
              </>
            </TblContainer>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </NormalModal>

      {!!triggeredSubjGrp && (
        <FeeCompositionSelection
          onClose={onClose}
          goBack={() => setTriggeredSubjGrp(null)}
          onSubmit={onCompositionSelect}
          initialValue={feeSeatMap!.get(triggeredSubjGrp)?.fee?.value}
          subjectGroupID={triggeredSubjGrp}
        />
      )}
    </>
  );
};

const Error = styled.div`
  margin-top: 4px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const styles = {
  noPadding: { paddingBottom: 0 },
};

export default FeeSeatAllocation;
