export const paymentModeOptions = [
  {
    label: 'Cash',
    value: 'CASH',
  },
  {
    label: 'Cheque',
    value: 'CHEQUE',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'NEFT',
    value: 'OFFLINE_NEFT',
  },
  {
    label: 'Loan',
    value: 'LOAN',
  },
  {
    label: 'Donation',
    value: 'DONATION',
  },
] as any;
