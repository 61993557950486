import { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components';
import React from 'react';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';

/** This sub form is used to render 2-column form */
export const CustomSubForm = (props: UseFieldApiConfig) => {
  const {
    fields,
    label,
    subscribeToValues
  } = props
  const { renderForm } = useFormApi();

  const { t } = useI18n();
  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <>
          {label && (
            <HeaderWrapper>
              <HeaderFive value={t(label)} color={colors.primaryText} />
            </HeaderWrapper>
          )}
          <FieldSection>
            {fields.map((field: any) => (
              renderForm([field])
            ))}
          </FieldSection>
        </>
      )}
    </FormSpy>
  )
}

const FieldSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 6px;
  margin-bottom: 20px;
`;

const HeaderWrapper = styled.div`
  padding-bottom: 24px;
`;
