import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useI18n } from 'src/i18n/hooks';
import QuickEditJSON from 'src/form-json/employees/employee-quick-edit.json';
import Element from 'src/components/molecules/Forms/ApplicationElement';
import { useForm } from 'react-hook-form';
import { useAssignAdminRoleToEmployee, useUpdateInstituteEmployee } from 'src/graphql/onboarding/employee';
import { UPDATE_SUCCESS } from 'src/constant/message';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { EMPLOYEE_TYPES, ERROR, SUCCESS } from 'src/constant';
import ToggleSwitch from 'src/components/atoms/Switch/Toggle.web';
import ERROR_MSG from 'src/constant/error';
import MediumText from 'src/components/atoms/Text/MediumText';
import { userInformation } from 'src/utils/manageState';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { colors, fonts } from 'src/styles/theme/styles';

export default function EmplooyeeDetailsQuickEditForm(props: any) {
    const { t } = useI18n();
    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();
    const { employeeData, handleFetch, handleRefetch } = props;
    const fields = QuickEditJSON[0].fields;
    const { updateInstituteEmployeeDetail } = useUpdateInstituteEmployee();
    const { setAlertDetails } = useAlertSystem();
    const [isViewMode, setIsViewMode] = useState<boolean>(true);
    const { assignAdminRoleToEmployee } = useAssignAdminRoleToEmployee();
    const currentUserObject = userInformation();
    const isInstituteAdmin = currentUserObject?.userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN);

    useEffect(() => {
        loadBasicdata();
    }, [isViewMode]);

    function loadBasicdata() {
        // Pre-populate values in form
        setValue('employeeId', employeeData?.employeeId);
        setValue('type',
            (employeeData?.type.indexOf('TEACHING') > -1) ?
                {
                    "label": "Teaching",
                    "value": "TEACHING"
                } :
                {
                    "label": "Non Teaching",
                    "value": "NON_TEACHING"
                }
        );
    }

    const updateEmployeeInfo = async (updatedFormValues: any) => {
        const newValue = {};
        let updateRes: any;

        // is new emp id changed
        if (updatedFormValues?.employeeId && (updatedFormValues?.employeeId != employeeData?.employeeId)) {
            newValue.employeeId = updatedFormValues.employeeId
        }

        // is emp type changed
        if (updatedFormValues?.type?.value && !(employeeData.type.indexOf(updatedFormValues.type.value) > -1)) {
            newValue.type = updatedFormValues.type.value
        }
        // ID key is added only if some other value is passed to be updated
        if (!(Object.keys(newValue).length === 0)) {
            newValue.id = employeeData.id;
            console.log(newValue)
            updateRes = await updateInstituteEmployeeDetail({ variables: { payload: newValue } });
            if (updateRes?.data?.updateInstituteEmployeeDetail?.status === 'success') {
                setAlertDetails({ message: UPDATE_SUCCESS.PROFILE, level: SUCCESS });
                handleFetch();
            } else {
                setAlertDetails({ message: updateRes?.data?.updateInstituteEmployeeDetail?.message, level: ERROR });
            }
        }
        setIsViewMode(true)
        setValue('employeeId', "");
        setValue('type', "")
        return updateRes;
    }

    async function handleAssignAdminToEmployee(switchState: boolean, id: string) {
      try {
        const response = await assignAdminRoleToEmployee({
          variables: { userId: id, assign: switchState },
        });
        if (response?.data?.assingAdminRoleToEmployee?.status === 'success') {
          setAlertDetails({
            message: switchState
              ? UPDATE_SUCCESS.ASSIGN_ADMIN_ROLE
              : UPDATE_SUCCESS.REVOKE_ADMIN_ROLE,
            level: SUCCESS,
          });
          handleRefetch();
        } else {
          throw new Error(response?.data?.assingAdminRoleToEmployee?.message || ERROR_MSG.GENERIC_ERROR);
        }
      } catch (e: any) {
        setAlertDetails({ message: e.message, level: ERROR });
      }
    }

    const DisplayFields = [
        {
            label: t("email.label"),
            value: employeeData?.email
        },
        {
            label: t("accountId.label"),
            value: employeeData?.accountId
        },
        {
            label: t("employeeType.label"),
            value: employeeData?.type.join(', ')
        },
    ];

    const InfoItem = ({label, value}: { label: string, value: string }) => {
        return (
            <ItemWrapper>
                <NormaltextAtom value={label} />
                <MediumTextSelect
                    value={value || "-"}
                    color={colors.primaryText}
                    fontFamily={fonts.regular} />
            </ItemWrapper>
        )
    }

    return (
        <NestedTableContainer>
            {isViewMode ?
                <Container>
                    <EmployeeDetail>
                        {
                            DisplayFields.map((field: { label: string, value: string }) => ((
                                <InfoItem label={field.label} value={field.value} />
                            )))
                        }
                    </EmployeeDetail>
                    <BtnWrapper>
                        <SecondaryBtn
                            onPress={() => { setIsViewMode(false) }}
                            label={t("editDetails.label")}
                            lines={1}
                            style={buttonHeight}
                        />
                        {Boolean(isInstituteAdmin && currentUserObject?.userId !== employeeData?.id) && (
                            <SwitchWrapper>
                                <ToggleSwitch
                                    enabled={employeeData?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN)}
                                    onChange={(e) => handleAssignAdminToEmployee(e.target.checked, employeeData?.id)}
                                />
                                <MediumText value={t('admin.label')} align="right" />
                            </SwitchWrapper>
                        )}
                    </BtnWrapper>
                </Container>
                :
                <EditModeContainer>
                    <FormFieldWrapper>
                        {fields?.map((field: any, i: number) => (
                            <FormField key={`emp${i}`}>
                                <Element
                                    key={`emp${i}`}
                                    field={field}
                                    control={control}
                                    errors={errors}
                                    setValue={setValue}
                                    getValues={getValues}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                    dynamicValidation={true}
                                    isCustomDisable={field.isCustomDisable}
                                />
                            </FormField>
                        )
                        )}
                    </FormFieldWrapper>
                    <ButtonContainer>
                        <SecondaryBtn
                            onPress={handleSubmit(updateEmployeeInfo)}
                            label={t("update.label")}
                            lines={1}
                            style={buttonHeight}
                        />
                        <OutlineButton
                            onPress={() => {
                                setIsViewMode(true)
                                setValue('employeeId', "");
                                setValue('type', "")
                            }}
                            label={t("cancel.label")}
                            style={buttonHeight}
                        />
                    </ButtonContainer>
                </EditModeContainer>
            }
        </NestedTableContainer>
    )
}

const itemWidth = "32%";
const buttonHeight = { height: 32 };

const NestedTableContainer = styled.div`
  padding: 16px;
`;

const FormField = styled.div`
  width: 250px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const EditModeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmployeeDetail = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: 90%;
`;

const BtnWrapper = styled.div`
  flex-direction: column;
  align-items: flex-start;
`;

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const SwitchWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${itemWidth};
`;