import React from 'react';
import { ActivityIndicator } from 'react-native';
import { colors } from 'src/styles/theme/styles';

export default function LoaderSpinner(props: any) {
  const { primary = true } = props;
  return <ActivityIndicator
    size="small"
    color={primary ? colors.primaryColor : colors.white}
    style={props.styles}
  />;
}


