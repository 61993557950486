import { Button, Grid } from "@mui/material";
import { ERROR, SUCCESS } from "src/constant";
import {
  PublicApplicationDocType,
  publicApplicationHelpers as helpers,
} from "./AdmissionLinkHelpers";
import React, { useEffect, useState } from "react";
import { colors, fonts } from "src/styles/theme/styles";
import { isMobile, isTablet } from "src/constant/device";
import {
  useCreateApplicationByPublicURL,
  useGetPublicAdmissionDetails,
} from "src/graphql/admission";

import { CustomReactNativeFile } from "src/components/molecules/FileUpload/type";
import { DropdownOptions } from "src/types";
import Element from "src/components/molecules/Forms/ApplicationElement.web";
import HeaderOne from "src/components/atoms/Text/HeaderOne";
import HeaderTwo from "src/components/atoms/Text/HeaderTwo";
import { Icon } from "src/components/atoms/Icon/Icon";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import { createDocumentObject } from "src/utils/utility";
import formJSON from "src/form-json/admission/PublicAdmission/PublicAdmissionApply.json";
import { states } from "src/form-json/user-details/dd-options";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useForm } from "react-hook-form";
import { useParams } from "src/routes/routing.web";
import { AllowedPlace } from "src/components/molecules/Admission/AdmissionForm/AdmissionFormHelpers";
import HeaderFive from "src/components/atoms/Text/HeaderFive";
import { useI18n } from "src/i18n/hooks";

const { fieldMap: FM } = helpers;

export default function AdmissionApply() {
  const { t } = useI18n();
  const { admissionId }: { admissionId: string } = useParams();
  const { getPublicAdmissionDetails, loading, data, error } =
    useGetPublicAdmissionDetails();
  const { createApplicationByPublicURL } = useCreateApplicationByPublicURL();
  const { setAlertDetails } = useAlertSystem();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [fileObjects, setFileObjects] = useState<any>({});
  const admDetail = data?.publicAdmissionDetail ?? {};
  const docTypes: PublicApplicationDocType[] =
    admDetail?.requiredDocuments ?? [];
  const reqDetails = admDetail?.requiredDetails ?? [];

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    // Necessary for setValue / clearing to work
    if (formJSON?.[0] && formJSON[0]?.fields) {
      formJSON[0].fields.forEach((element) => {
        if (element.id && element.type) {
          setValue(
            element.id,
            ["number", "string"].includes(element.type) ? "" : null
          );
        }
      });
    }

    if (admissionId) {
      getPublicAdmissionDetails({
        variables: {
          id: admissionId,
        },
      });
    }
  }, [admissionId]);

  useEffect(() => {
    if (!loading && data?.publicAdmissionDetail) {
      formJSON[0].fields[FM.state].option = states;

      const subGrpOptions = admDetail?.subjectGroups?.map(
        (e: { subjectGroup: DropdownOptions }) => e?.subjectGroup
      );
      formJSON[0].fields[FM.subjectGroup].option = subGrpOptions;
      if (subGrpOptions?.length === 1) {
        setValue("subjectGroup", subGrpOptions[0]);
      }
      formJSON[0].fields[FM.subjectGroup].fieldVisible =
        subGrpOptions?.length > 1;

      formJSON[0].fields[FM.dateOfBirth].dateTimePickerMaxDate =
        helpers.checkAndFormatDate(admDetail?.toDOB);
      formJSON[0].fields[FM.dateOfBirth].dateTimePickerMinDate =
        helpers.checkAndFormatDate(admDetail?.fromDOB);

      getDocumentsData();
    }
    return () => {
      formJSON[0].fields[FM.dateOfBirth].dateTimePickerMinDate = "";
      formJSON[0].fields[FM.dateOfBirth].dateTimePickerMaxDate = "";
      formJSON[0].fields[FM.subjectGroup].option = [];
      toggleSiblingsFields(false);
    };
  }, [loading, data]);

  async function getDocumentsData() {
    let newData: any = { ...formJSON[0] };
    if (data?.publicAdmissionDetail?.requiredDocuments) {
      const documents = data.publicAdmissionDetail.requiredDocuments
        .filter((item: any) => item?.allowedPlace === AllowedPlace.PUBLIC_LINK)
        .map((res: any) => ({
          id: `${res?.docType?.id}_DOC`,
          typeId: res?.docType?.id,
          category: res?.docType?.category,
          textHead: `${res?.docType?.name}${res?.required ? '*' : ''}`,
          type: 'file',
          required: res?.required,
          ...(res?.required
            ? { required_error: `${res?.docType?.name?.split('*')[0]} is required` }
            : {}),
          multiple: false,
          compact: true,
        }));

      if (documents && documents.length) {
        newData.fields = [...newData.fields, ...documents];
      }
    }
    setElements(newData);
  }

  function toggleSiblingsFields(visibility: boolean) {
    [FM.siblingClass, FM.siblingGRNumber].forEach((element) => {
      formJSON[0].fields[element].fieldVisible = visibility;
    });
    getDocumentsData();
  }

  function handleCustomSelect(id: string, data: any) {
    switch (id) {
      case "siblings":
        toggleSiblingsFields(!!data);
        break;

      default:
        break;
    }
  }

  const handleFilesAttach = (id: any) => {
    return (files: CustomReactNativeFile[]) => {
      if (files?.length > 0) {
        let file: any = files[0];
        setValue(id, file);
        maintainFileObject(file, id);
        clearErrors(id);
      }
    };
  };

  function maintainFileObject(data: object, id: string) {
    let fileobj = { ...fileObjects };
    fileobj[id] = data;
    setFileObjects(fileobj);
  }

  const handleFileRemove = (id: string) => {
    return (file: CustomReactNativeFile) => {
      setValue(id, {});
      maintainFileObject({}, id);
    };
  };

  async function onSubmitFn(formData: any) {
    if (formData) {
      setIsSubmitting(true);
      try {
        let documents = [];

        for (let key of Object.keys(formData)) {
          if (key.endsWith('_DOC') && formData[key]) {
            const element = elements?.fields?.find((item: any) => item?.id === key);

            if (element) {
              const req_doc = await createDocumentObject(
                formData[key],
                element?.category,
                '',
                element?.typeId,
              );
              documents.push(req_doc);
            }
          }
        }

        const payload = {
          admissionId: admissionId,
          email: formData.email,
          firstName: formData.firstName,
          ...(formData.middleName ? { middleName: formData.middleName } : {}),
          lastName: formData.lastName,
          mobileNumber: parseFloat(formData.contactNumber),
          subjectGroup: formData.subjectGroup.value,
          address: {
            houseNo: formData.houseNo,
            area: formData.area,
            city: formData.city,
            state: formData.state.value,
            zipcode: formData.zipcode,
            ...(formData.street ? { street: formData.street } : {}),
          },
          ...(formData.siblings
            ? {
              class: formData.siblingClass,
              grNo: formData.siblingGRNumber,
            }
            : {}),
          ...(reqDetails.includes("DOB") ? { dob: formData.DOB } : {}),
          ...(reqDetails.includes("AADHAAR_ID")
            ? {
              aadhaarId: formData.AADHAAR_ID,
              aadhaarBelongsTo: formData.aadhaarBelongsTo.value,
            }
            : {}),
          ...(documents && documents.length ? { documents } : {}),
        };

        const response = await createApplicationByPublicURL({
          variables: { payload },
        });

        if (response?.data) {
          const SuccessMessage = `We have received your request! An invitation will be sent to your email address: ${formData.email} shortly. In order to complete the registration procedure, please verify your email. Thank you!`;
          setAlertDetails({
            message: SuccessMessage,
            level: SUCCESS,
            expireInMs: 10000,
          });
          setValue("siblings", false);
          toggleSiblingsFields(false);
          reset();
          setFileObjects({});
          setIsSubmitting(false);
        }
      } catch (error: any) {
        console.log(error?.message);
        setAlertDetails({
          message: error?.message,
          level: ERROR,
        });
        setIsSubmitting(false);
      }
    }
  }

  function showField(fieldID: string): boolean {
    const matchID = helpers.aadhaarFields.includes(fieldID)
      ? "AADHAAR_ID"
      : fieldID;
    return (
      !helpers.conditionalFields.includes(matchID) ||
      reqDetails.includes(matchID)
    );
  }

  return (
    <div>
      <IconWrapper container>
        {isTablet ? (
          <Icon name={"edba-logo-light"} size={40} />
        ) : (
          <Icon name={"edba-logo"} size={40} />
        )}
      </IconWrapper>
      {!loading && data ? (
        <>
          <BgWrapper container justifyContent="center">
            <BannerContentWrap container spacing={{ md: 2, sm: 2, xs: 0 }}>
              <BannerContent item lg={8} md={10} sm={9}>
                <HeaderOne
                  value="Admissions"
                  fontSize={isTablet ? "30px" : "80px"}
                  color={colors.white}
                  textAlign={isTablet ? "center" : "left"}
                />
                <HeaderTwo
                  value="Open for Registration"
                  fontSize={isTablet ? "16px" : "30px"}
                  color={colors.lightOrange}
                  textAlign={isTablet ? "center" : "left"}
                />
              </BannerContent>
              <BannerImage item lg={4} md={2} sm={3}>
                <Icon name={"admission-link-banner-img"} />
              </BannerImage>
            </BannerContentWrap>
          </BgWrapper>
          <BodyWrapper container justifyContent="start" alignItems="flex-start">
            <FormContainer item lg={9} md={8} sm={8} xs={12}>
              <FormWrapper container spacing={3}>
                <HeaderWrapper item md={12} sm={12} xs={12}>
                  <HeaderOne
                    value={`Admission for ${admDetail?.class?.name}`}
                    fontSize={isTablet ? "24px" : "28px"}
                  />
                </HeaderWrapper>
                {fields &&
                  fields.map(
                    (field: any, i: number) => 
                      showField(field.id) && (
                        <>
                          {i === 22 && (
                            <DocumentsHeaderWrapper>
                              <HeaderFive value={t('documents.label')} />
                            </DocumentsHeaderWrapper>
                          )}
                          <FieldWrapper item md={6} sm={6} xs={12}>
                            {/* @ts-ignore */}
                            <Element
                              key={`f${i}`}
                              field={field}
                              control={control}
                              errors={errors}
                              setError={setError}
                              handleCustomSelect={handleCustomSelect}
                              file={fileObjects[field.id]}
                              handleFilesAttach={handleFilesAttach(field.id)}
                              handleFileRemove={handleFileRemove(field.id)}
                              fileWidth={"100%"}
                            />
                          </FieldWrapper>
                        </>
                      )
                  )}
              </FormWrapper>
              <HeaderWrapper item lg={2} md={3} sm={6} xs={12}>
                <CustomButton
                  onClick={handleSubmit(onSubmitFn)}
                  disabled={isSubmitting}
                  variant="contained"
                  size={isTablet ? "large" : "medium"}
                  sx={{
                    height: isTablet ? "48px" : "40px",
                    borderRadius: isMobile ? 8 : "4px",
                    width: "100%",
                  }}
                >
                  Submit
                </CustomButton>
              </HeaderWrapper>
            </FormContainer>
          </BodyWrapper>
        </>
      ) : (
        <NoAdmissionLinksWrapper>
          {loading ? (
            <LoaderSpinner />
          ) : error ? (
            <HeaderTwo value={"Invalid Admission Link"} textAlign={"center"} />
          ) : (
            <></>
          )}
        </NoAdmissionLinksWrapper>
      )}
      <Footer>
        <NormaltextAtom
          value={`Copyright © ${new Date().getFullYear()} EDBA Software Pvt Ltd. All Rights Reserved.`}
        />

        <FooterRightSectionWrapper>
          <PrivacyPolicyLink href={"https://edba.io/privacy-policy"} variant="text">
            Privacy Policy
          </PrivacyPolicyLink>
        </FooterRightSectionWrapper>
      </Footer>
    </div>
  );
}

const BgWrapper = styled(Grid)`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(43, 120, 202, 0.8) 0%,
    #2b78ca 100%
  );
  min-height: 240px;
  @media (max-width: 991px) {
    background: #2b78ca;
    min-height: 130px;
  }
`;

const BannerContentWrap = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 175px;
  position: relative;
  overflow: hidden;
  @media (max-width: 991px) {
    padding: 0;
    justify-content: center;
  }
`;

const BannerContent = styled(Grid)`
  display: flex;
  flex-direction: column !important;
  text-transform: uppercase;
`;

const BannerImage = styled(Grid)`
  position: absolute;
  top: 33px;
  right: 175px;
  @media (max-width: 991px) {
    display: none;
  }
`;

const BodyWrapper = styled(Grid)`
  background-color: ${colors.white};
  padding: 30px 175px;
  @media (max-width: 991px) {
    padding: 30px 25px;
  }
`;

const FormWrapper = styled(Grid)`
  margin-bottom: 30px;
`;

const FormContainer = styled(Grid)``;

const FieldWrapper = styled(Grid)`
  margin-bottom: -24px !important;
`;

const HeaderWrapper = styled(Grid)``;

const CustomButton = styled(Button)``;

const NoAdmissionLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const IconWrapper = styled(Grid)`
  background-color: ${colors.white};
  padding: 20px;
  padding-left: 120px;
  @media (max-width: 991px) {
    background-color: #2b78ca;
    padding: 20px;
    justify-content: center;
  }
`;

const Footer = styled(Grid)`
  padding: 28px 120px;
  background-color: ${colors.white};
  font-family: ${fonts.regular};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    display: block;
    padding: 20px;
    text-align: center;
  }
`;

const FooterRightSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    display: block;
  }
`;

const PrivacyPolicyLink = styled(Button)`
  font-size: 16px !important;
  color: ${colors.primaryText} !important;
  font-family: ${fonts.regular};
  line-height: 24px;
  text-decoration: none;
  margin-right: 20px;
  text-transform: capitalize !important;
  @media (max-width: 991px) {
    font-size: 14px !important;
  }
`;

const DocumentsHeaderWrapper = styled.div`
  width: 100%;
  margin: 24px 0px -8px 24px;
`;