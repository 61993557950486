export {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useLocation,
    Redirect,
    useHistory,
    withRouter,
    useRouteMatch
  } from 'react-router-dom';

export { useNavigate } from "react-router-dom-v5-compat";
