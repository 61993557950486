export type TrevalChipData = {
  label: string;
  amountPerSubject: number;
  value: number;
  applicationFee: number;
  amountPerSoftCopy: number;
};

export type Toptions = {
  label: string;
  value: string;
};

export type TatktChipData = {
  max?: number;
  feeAmount: number;
  min: number;
  applicationFee: number;
};


export const revalHeadcells = [
  {
    id: 'casteCategory',
    label: 'Caste Category',
    align: 'left',
  },
  {
    id: 'revalFees',
    label: 'Revaluation fees',
    align: 'left',
  },
  {
    id: 'applicationFee',
    label: 'Application fees',
    align: 'left',
  },
  {
    id: 'softCopyFees',
    label: 'Fees for Photo Copy',
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Action',
  },
];


export const ATKTHeadcells = [
  {
    id: 'casteCategory',
    label: 'Subject Range',
    align: 'left',
  },
  {
    id: 'revalFees',
    label: 'ATKT fees',
    align: 'left',
  },
  {
    id: 'applicationFee',
    label: 'Application fees',
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Action',
  },
];

export const HandleKeyDown = (e:any,allowDecimalInput: boolean) => {
  console.log("run")
  const { key, target } = e;
  const currentValue = parseInt(target?.value) || 0;
  console.log(currentValue)
  if (key === '-' || (key === 'ArrowDown' && (currentValue <= 0 || target.value === ''))) {
    e.preventDefault();
  } else if (key === 'ArrowDown' && currentValue === 0) {
    e.preventDefault();
  } else if ((['e', 'E', '-', '+'].includes(key) || (!allowDecimalInput && key === '.')) && !/^\d$/.test(key)) {
    e.preventDefault();
  }
};