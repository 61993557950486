import { AUTOCOMPLETE_MODULE, ERROR, SUCCESS, limit } from "src/constant";
import {
  documentsListing as Item,
  documentsListingServerResponse as TContent,
  documentsFormState as TForm,
  AdminDocumentsHelpers as helpers,
} from "./DocumentsHelpers";
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from "src/components/molecules/Table/TableAtom";
import React, { useCallback, useEffect, useState } from "react";
import { createSearchFilter, debounce } from "src/components/services";

import DocumentsForm from "src/components/molecules/Admission/Admin/DocumentsForm.web";
import { EditIcon } from "src/components/atoms/ActionIcons";
import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import Pagination from "src/components/atoms/Pagination/Paginations.web";
import Search from "src/components/atoms/SearchBar/index.web";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { TableBody } from "@mui/material";
import { getCommonNameSearchFilter } from "src/components/services/filters";
import { height } from "src/constant/device";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useFilter } from "src/contexts/filter-context";
import { useGetDocuments } from "src/graphql/admission/document-type";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { useParams } from "src/routes/routing.web";
import useTable from "src/components/molecules/Table/UseTable";

const FILTER_MODULE = AUTOCOMPLETE_MODULE.DOCUMENTS;
const DEBOUNCE_DELAY = 500;

const { documentsHeadCells } = helpers;

export default function Documents() {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { page = 1, dataLimit }: any = useParams();

  let searchValue = "" as string;
  if (filters && filters?.[FILTER_MODULE]?.on) {
    searchValue = filters?.[FILTER_MODULE]?.search;
  }
  const [currentPage, setCurrentPage] = useState<number>(parseInt(page));
  const [rowLimit, setRowLimit] = useState(parseInt(dataLimit) ?? limit);
  const [form, setForm] = useState<TForm>();
  const [content, setContent] = useState<TContent>();
  const [searching, setSearching] = useState(searchValue);
  const [headCells, setHeadCells] = useState(documentsHeadCells);
  const { setAlertDetails } = useAlertSystem();
  const { getDocuments, loading, data, refetch } = useGetDocuments();

  useEffect(() => {
    setHeading([{ text: t("admissionDocuments.label"), url: "" }]);
  }, []);

  useEffect(() => {
    setSearching(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  function handleFetch() {
    const filters = getCommonNameSearchFilter(searchValue);
    const documentFilter = createSearchFilter(
      rowLimit,
      (page - 1) * rowLimit,
      filters
    );
    getDocuments({ variables: documentFilter });
  }

  useEffect(() => {
    if (data?.documentTypes) {
      setContent({
        data: data?.documentTypes?.data ?? [],
        totalCount: data?.documentTypes?.totalCount ?? 0,
      });
    }
  }, [data?.documentTypes]);

  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    content?.data,
    headCells
  );

  const delayedQuery = useCallback(
    debounce((q: string) => {
      const shouldPersist = q.trim() !== "";
      const filter = { [FILTER_MODULE]: { on: shouldPersist, search: q } };
      setFilter(filter);
    }, DEBOUNCE_DELAY),
    []
  );

  const handleSearch = (qualification: string) => {
    setSearching(qualification);
    delayedQuery(qualification);
  };

  const triggerForm = (item?: Item) => {
    setForm({
      state: "OPEN",
      ...(item ? { mode: "EDIT", data: item } : { mode: "ADD" }),
    });
  };

  const onSuccess = (message: string): void => {
    setAlertDetails({ message, level: SUCCESS });
    if (refetch) refetch();
    onClose();
  };

  const onError = (message: string): void => {
    setAlertDetails({ message, level: ERROR });
    onClose();
  };

  const onClose = (): void => {
    setForm({ state: "CLOSE", mode: "ADD" });
  };

  return (
    <LoadContentWrapper>
      <Section>
        <Search
          id="documentsSearch"
          handleChange={handleSearch}
          value={searching}
          label={t("searchDocuments.text")}
        />
        <SecondaryBtn
          label={t("addDocument.text")}
          onPress={() => triggerForm()}
          lines={1}
        />
      </Section>
      <TblContainer height={"100%"}>
        <TblHead setHeadCells={setHeadCells} />
        <>
          {!loading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item: Item) => (
                    <TableRows key={item.id}>
                      <TableCells value={item?.name ?? "-"} />
                      <TableCells value={item?.alias ?? "-"} />

                      <TableActions align="right">
                        <TableActionsWrapper>
                          <EditIcon
                            tooltipTitle={t("editDocument.text")}
                            onPress={() => triggerForm(item)}
                          />
                        </TableActionsWrapper>
                      </TableActions>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={3} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>

      {!!content?.totalCount && !loading && (
        <Pagination
          pathName={"admission/documents"}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          total={content.totalCount}
          hidePagination={content.totalCount <= rowLimit}
        />
      )}
      {form && form.state === "OPEN" && (
        <DocumentsForm
          form={form}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
        />
      )}
    </LoadContentWrapper>
  );
}

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const TableActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 12px;
`;
