import React, { useState } from "react";
import { TFormProps, TFormState } from "src/types";

import { ADD_SUCCESS } from "src/constant/message";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import PartialPaymentListing from "../../Admission/PartialPaymentListing";
import { useCreatePartialPayment } from "src/graphql/admission/application";
import { useCreatePartialPaymentForStudent } from "src/graphql/payment";
import { useForm } from "react-hook-form";
import { useI18n } from "src/i18n/hooks";

export type TPartialFeePaymentData = {
  currentFeeDetails: {
    feeBook: any;
    totalPayable: number;
    totalPending: number;
  };
  currentItem: {
    id: string;
    fees?: { installmentPayable: number };
  };
};

type TForm = {
  state: TFormState;
  data: TPartialFeePaymentData;
};

type Props = TFormProps<TForm> & {
  paymentFor: "admission_application" | "student";
  batchId?: string;
};

const PartialFeePayment = (props: Props) => {
  const { form, onSuccess, onError, onClose, paymentFor, batchId } = props;
  const { currentFeeDetails, currentItem } = form.data;

  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: { amount: currentItem?.fees?.installmentPayable ?? "" },
  });

  const { createPartialPayment } = useCreatePartialPayment();
  const { createPartialPaymentForStudent } =
    useCreatePartialPaymentForStudent();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmitFn = async ({ amount }: { amount: string }) => {
    if (amount) {
      setLoading(true);
      try {
        const partialPaymentAmount = parseInt(amount);
        if (partialPaymentAmount > currentFeeDetails.totalPayable) {
          throw new Error(t("partialPayableAmount.error.text"));
        }

        switch (paymentFor) {
          case "admission_application":
            const response_appl = await createPartialPayment({
              variables: {
                applicationId: currentItem?.id,
                amount: partialPaymentAmount,
              },
            });
            if (response_appl?.data?.createPartialPayment) {
              onSuccess(ADD_SUCCESS.PARTIAL_PAYMENT);
              setLoading(false);
            }
            break;

          case "student":
            const response_student = await createPartialPaymentForStudent({
              variables: {
                amount: partialPaymentAmount,
                studentId: currentItem?.id,
                batchId: batchId,
              },
            });
            if (response_student?.data?.createPartialPaymentForStudent) {
              onSuccess(ADD_SUCCESS.PARTIAL_PAYMENT);
              setLoading(false);
            }
            break;
        }
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      Headerpopup={t("addPartialPayment.label")}
      modalVisible={true}
      // @ts-ignore | "amount" key in FormData (FieldValues) will always be string type. Type inference false positive is due to "amount" key being number during prefill (passed to defaultValues in useForm)
      handleSave={handleSubmit(onSubmitFn)}
      addEditButtonLabel={"save.label"}
      width={788}
      maxWidth={"md"}
    >
      <PartialPaymentListing
        feeBooks={currentFeeDetails?.feeBook}
        totalPayable={currentFeeDetails?.totalPayable}
        totalPending={currentFeeDetails?.totalPending}
        control={control}
        errors={errors}
      />
    </NormalModal>
  );
};

export default PartialFeePayment;
