import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import assignSubjects from '../../../form-json/employees/assign-subjects.json';
import Element from '../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useI18n } from 'src/i18n/hooks';
import { useQuery } from '@apollo/client';
import { autoCompleteLimit, AUTOCOMPLETE_MODULE, ERROR } from 'src/constant';
import { getFilteredSubjects } from 'src/components/services';
import { getModifiedSubjectsQuery } from 'src/graphql/academics/subjects';
import Chip from 'src/components/atoms/Chip';
import { colors } from 'src/styles/theme/styles';
import { getBatchQuery, getFilteredBatch } from 'src/graphql/academics/batch';
import { mergeBatchClassLabel } from 'src/utils/utility';
import { useAlertSystem } from 'src/contexts/web-alert-context';

export default function AssignSubjects(props: any) {
  const {
    setValue,
    getValues,
    assignSubject,
    setAssignSubject,
    batchSelected,
    setBatch,
    assignNewSubjects,
    setAssignNewSubjects,
    removeSubject,
    setRemoveSubject
  } = props;
  const { t } = useI18n();
  const [elements, setElements] = useState<any>({});
  const [selectedBatch, setSelectedBatch] = useState<any>({});
  const [allSubjects, setAllSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [division, setDivision] = useState({});
  const [searchData, setSearchData] = useState('');
  const [clearField, setClearField] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  let years = [] as any;
  let { setAlertDetails } = useAlertSystem();

  const allBatch = useQuery(getBatchQuery, {
    variables: { limit: autoCompleteLimit, skip: 0 },
  });

  useEffect(() => {
    setValue(AUTOCOMPLETE_MODULE.DIVISION, null);
    return () => {
      assignSubjects[0].fields[1].option = [];
    }
  }, []);

  useEffect(() => {
    onLoadFunction();
  }, [allBatch.data, years]);

  useEffect(() => {
    loadSelectedSubjects();
  }, [assignSubject, division]);

  useEffect(() => {
    if (division && division?.label && division?.value) {
      getSubjects(selectedBatch?.class?.id);
    }
  }, [division]);

  function loadSelectedSubjects() {
    if (assignSubject && assignSubject.length > 0 && division && division?.value) {
      let subjects = assignSubject.filter(item => item?.division === division?.value).map(obj => obj?.subject);
      setSelectedSubjects(subjects);
    } else {
      setSelectedSubjects([]);
    }
  }

  function onLoadFunction() {
    if (allBatch.loading === false) {
      const getdata = mergeBatchClassLabel(allBatch.data?.batches?.data);
      assignSubjects[0].fields[0].option = getdata;
      setElements(assignSubjects[0]);
    }
  }

  function loadSubjects(subjectArray: any) {
    let subjects = [] as any;
    subjectArray.forEach((item: any) => {
      subjects.push({
        name: item?.label,
        id: item?.value
      })
    })
    setAllSubjects(subjects);
  }

  function loadDivisions(divisionArray: any) {
    let options = [] as any;
    divisionArray.forEach(function (item: any, index: number) {
      options.push({
        value: item.toString(),
        label: item.toString(),
      });
    });
    setElements({});
    assignSubjects[0].fields[1].option = options;
    setElements(assignSubjects[0]);
  }

  async function getSubjects(classId: string) {
    const filters = {
      class: {
        eq: classId,
      },
    };
    const subjectsResponse = await getFilteredSubjects(
      { filters: filters, limit: 0 },
      getModifiedSubjectsQuery,
    );
    loadSubjects(subjectsResponse?.options);
    setIsDataAvailable(true)
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      setIsDataAvailable(false)
      // Clear form options on batch Change
      loadDivisions([]);
      loadSubjects([]);
      if (data && selectedBatch?.value !== data.value) {
        setClearField(false);
        setValue(AUTOCOMPLETE_MODULE.DIVISION, null);
        setDivision({});
        setAllSubjects([]);
        setBatch(data?.alias);
        setSelectedBatch(data);
        batchSelected(data);
        loadDivisions(data?.divisionDetails?.map((det) => det.division));
      } else if (selectedBatch?.value !== data?.value) {
        setValue(AUTOCOMPLETE_MODULE.DIVISION, null);
        resetForm();
      }
    }

    if (id === AUTOCOMPLETE_MODULE.DIVISION) {
      setIsDataAvailable(false)
      if (data && data?.value !== division?.value) {
        setClearField(false);
        setDivision(data);
        setSelectedSubjects([]);
      } else if (data?.value !== division?.value) {
        resetForm();
      }
    }
  }

  function resetForm() {
    setDivision({});
    setAllSubjects([]);
    setSelectedSubjects([]);
    setClearField(true);
  }

  async function searchSelectOnchange(queryVariable: any, type: string) {
    let filters = {
      alias: {
        regex: queryVariable,
      },
    } as any;

    if (type === AUTOCOMPLETE_MODULE.BATCH) {
      let batches = await getFilteredBatch({ filters: filters });
      return batches;
    }
  }

  async function searchSelectScroll(queryVariable: any, type: string) {
    let param = {
      limit: autoCompleteLimit,
      skip: queryVariable * autoCompleteLimit,
    } as any;

    if (type === AUTOCOMPLETE_MODULE.BATCH) {
      let batches = getFilteredBatch(param);
      return batches;
    }
  }

  const handleCheck = (item: string) => {
    const divisionField = getValues(AUTOCOMPLETE_MODULE.DIVISION);
    if (divisionField && divisionField.label && divisionField.value) {
      const index = assignSubject.findIndex((audience: { division: any; subject: string; }) => 
      audience?.division === division?.value && audience?.subject === item);
      if (index > -1) {
        removeAudience(index);
      } else {
        addSubjects(item);
      }
    } else {
      setAlertDetails({ message: t('division.required.text'), level: ERROR });
    }
  }

  function addSubjects(item: string) {
    const classAlias = selectedBatch?.class?.alias;
    const division = getValues(AUTOCOMPLETE_MODULE.DIVISION);
    const batch = getValues(AUTOCOMPLETE_MODULE.BATCH);
    if (selectedBatch && division?.value && item && batch?.value) {
      const divisionId = division?.value;
      const subjectId = item;
      const isPresent = filterAssignArray(selectedBatch, divisionId, subjectId);
      const subjectAlias = allSubjects.filter(item => item?.id ===  subjectId);

      let existingSubjects = [];
      if (assignSubject && assignSubject.length > 0) {
        existingSubjects = assignSubject
      }
      let existingAddSubjects = [];
      if (assignNewSubjects && assignNewSubjects.length > 0) {
        existingAddSubjects = assignNewSubjects
      }

      if (isPresent.length < 1) {
        const subjects = [...existingSubjects];
        const newsubjects = [...existingAddSubjects];
        const chipObject = {
          text: `${classAlias} ${division?.label} : ${subjectAlias[0]?.name}`,
          class: selectedBatch.class.id,
          division: divisionId,
          subject: subjectId,
          batch: selectedBatch,
        };
        subjects.push(chipObject);
        setAssignSubject(subjects);
        const addRemovedOne = removeSubject ? removeSubject.findIndex((newSub: any) => {
          return newSub.batch == chipObject.batch &&
            newSub.class == chipObject.class &&
            newSub.division == chipObject.division &&
            newSub.subject == chipObject.subject;
        }) : -1;
        if (addRemovedOne == -1) {
          newsubjects.push(chipObject)
          setAssignNewSubjects(newsubjects);
        } else {
          const removedsubs = [...removeSubject];
          removedsubs.splice(addRemovedOne, 1);
          setRemoveSubject(removedsubs)
        }
      }
    }
  }

  function removeAudience(idx: any) {
    const subjects = [...assignSubject];
    let removed: any = subjects.splice(idx, 1);
    removed = removed[0];
    setAssignSubject(subjects);
    let removedSubjects = [];
    if (removeSubject && removeSubject.length > 0) {
      removedSubjects = removeSubject
    }
    const removedNewOne = assignNewSubjects ? assignNewSubjects.findIndex((newSub: any) => {
      return newSub.batch == removed.batch &&
        newSub.class == removed.class &&
        newSub.division == removed.division &&
        newSub.subject == removed.subject;
    }) : -1;
    if (removedNewOne == -1) {
      removedSubjects.push(removed);
      setRemoveSubject(removedSubjects);
    } else {
      const newsubjects = [...assignNewSubjects];
      newsubjects.splice(removedNewOne, 1);
      setAssignNewSubjects(newsubjects)
    }
  }

  function filterAssignArray(batch: any, division: string, subjectId: string) {
    const filterData = assignSubject.filter(function (value: any) {
      return (
        value?.class === batch.class.id &&
        value?.division === division &&
        value?.subject === subjectId &&
        value?.batch.value === batch.value
      );
    });
    return filterData;
  }

  async function searchCourseOnchange(queryVariable: any) {
    setSearchData(queryVariable);
    const classId = selectedBatch?.class?.id;
    if (queryVariable.length > 0 && classId && allSubjects.length > 0) {
      let param = {
        limit: 0,
        skip: 0,
        filters: { alias: { eq: queryVariable }, class: { eq: classId }},
      };

      const subjectResponse = await getFilteredSubjects(param, getModifiedSubjectsQuery);
      loadSubjects(subjectResponse?.options);
    } else {
      getSubjects(classId);
    }
  }

  return (
    <View>
      <View style={styles.containerView}>
        {elements?.fields ? (
          elements?.fields.map((field: any, i: number) => (
            <View key={`assign-subject-fields-${i}`}>
              <Element
                key={i}
                field={field}
                control={props.control}
                errors={props.errors}
                searchSelectScroll={searchSelectScroll}
                searchSelectOnchange={searchSelectOnchange}
                handleCustomSelect={handleCustomSelect}
                checkboxTableData={allSubjects}
                checked={selectedSubjects}
                handleCheck={handleCheck}
                checkboxTableHeight={'auto'}
                checkboxTableMaxHeight={280}
                manualSelection={false}
                searchBoxOnChange={searchCourseOnchange}
                searchData={searchData}
                isDataAvailable={isDataAvailable}
              />
            </View>
          ))
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </View>
      <View>
        {clearField ? (assignSubject && (
          <View style={[styles.chipWrapper]}>
            {assignSubject?.map((subject: any, index: number) => (
              <View style={[styles.chipView]} key={`${subject?.text}-${index}`}>
                <Chip label={subject?.text} onPress={() => removeAudience(index)} />
              </View>
            ))}
          </View>
        )) : (
          assignSubject && division?.value && (
            <View style={[styles.chipWrapper]}>
            {assignSubject?.map((subject: any, index: number) => (
              <View style={[styles.chipView]} key={`${subject?.text}-${index}`}>
                <Chip label={subject?.text} onPress={() => removeAudience(index)} />
              </View>
            ))}
            </View>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containerView: {
    borderWidth: 1,
    borderColor: colors.borderColor,
    borderStyle: 'dashed',
    borderRadius: 4,
    padding: 8
  },
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
  chipWrapper: { flexDirection: 'row', flexWrap: 'wrap', marginTop: 16 },
  chipView: { marginRight: 8, marginBottom: 8 },
  headView: { marginBottom: 16 },
  borderView: {
    borderWidth: 2,
    borderStyle: 'dashed',
    borderRadius: 4,
    padding: 8,
    borderColor: colors.lighterBorder,
  },
});
