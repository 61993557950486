import DatePicker, { DatePickerProps } from "react-native-date-picker";
import {
  NativeDatePickerProps,
  NativeDateTimeMode,
  getFormattedDate,
} from "./helpers";
import React, { useState } from "react";
import { colors, fonts } from "src/styles/theme/styles";

import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import MonthYearPickerNative from "./MonthYearPickerNative";
import SmallTextAtom from "../Text/SmallTextAtom";
import { TouchableWithoutFeedback } from "react-native";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { isIOS } from "src/constant/device";
import styled from "styled-components/native";

type PickerComponent = typeof DatePicker | typeof MonthYearPickerNative;
const ComponentMap: Record<NativeDateTimeMode, PickerComponent> = {
  date: DatePicker,
  time: DatePicker,
  datetime: DatePicker,
  monthyear: MonthYearPickerNative,
};

function DateTimePicker(props: NativeDatePickerProps) {
  const {
    initialValue,
    dateTimeMode = "date",
    minDate,
    maxDate,
    dateTimePickerTitle,
    confirmButtonText = "Done",
    cancelButtonText = "Cancel",
    containerWidth = "100%",
    containerHeight = "56px",
    onDateTimeChangeCallback,
    disabled = false,
    disableFuture = false,
    disablePast = false,
    testID,
    standalonePicker = false,
  } = props;

  const [date, setDate] = useState<Date | undefined>();
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

  const MonthYearComponentProps = {
    onChangeCallback: onDateTimeChangeCallback,
    initialValue: initialValue,
    header: dateTimePickerTitle,
  };

  const DatePickerComponentProps: DatePickerProps = {
    modal: true,
    theme: "light",
    androidVariant: "nativeAndroid",
    open: isDatePickerVisible,
    date: date || initialValue || new Date(),
    mode: dateTimeMode,
    title: dateTimePickerTitle,
    confirmText: confirmButtonText,
    cancelText: cancelButtonText,
    minimumDate: disablePast ? new Date() : minDate,
    maximumDate: disableFuture ? new Date() : maxDate,
    onConfirm: (selectedDate: Date) => {
      setDatePickerVisibility(false);
      setDate(selectedDate);
      if (onDateTimeChangeCallback) {
        onDateTimeChangeCallback(selectedDate);
      }
    },
    onCancel: () => {
      setDatePickerVisibility(false);
      if (date && setDate) setDate(undefined); // Cleanup required on close
    },
  };

  const componentProps =
    dateTimeMode === "monthyear"
      ? MonthYearComponentProps
      : DatePickerComponentProps;

  const PickerComponent = ComponentMap[dateTimeMode];

  switch (standalonePicker) {
    case true:
      return dateTimeMode !== "monthyear" ? (
        <>
          <IconWrapper
            onPress={() => setDatePickerVisibility((prev: boolean) => !prev)}
          >
            <CalendarIcon disabled={disabled} />
          </IconWrapper>
          {isDatePickerVisible && <PickerComponent {...componentProps} />}
        </>
      ) : (
        // monthyear picker has to use its default trigger
        <PickerComponent {...componentProps} />
      );
    case false:
    default:
      return (
        <TouchableWithoutFeedback
          onPress={() => setDatePickerVisibility(true)}
          disabled={disabled}
          testID={testID}
        >
          <Container width={containerWidth} height={containerHeight}>
            {date || initialValue ? (
              <TextContainer>
                <ActiveTitle>{dateTimePickerTitle}</ActiveTitle>
                <DateValue disabled={disabled}>
                  {getFormattedDate(date || initialValue, dateTimeMode)}
                </DateValue>
              </TextContainer>
            ) : (
              <SmallTextAtom
                value={dateTimePickerTitle}
                color={colors.tertiaryText}
              />
            )}

            <PickerComponent {...componentProps} />
            <CalendarIcon disabled={disabled} />
          </Container>
        </TouchableWithoutFeedback>
      );
  }
}

export default DateTimePicker;

const CalendarIcon = ({ disabled = false }) => {
  return (
    <FontAwesomeIcon
      icon={faCalendar}
      color={disabled ? colors.tertiaryText : colors.secondaryText}
    />
  );
};

const Container = styled.View<{
  width: number | string;
  height: number | string;
}>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.inputBorder};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
`;

const DateValue = styled.Text<{ disabled: boolean }>`
  font-family: ${fonts.regular};
  font-size: 16px;
  line-height: 24px;
  color: ${({ disabled }) =>
    !!disabled ? colors.tertiaryText : colors.primaryText};
  margin-top: ${isIOS ? 2 : 4}px;
`;

const ActiveTitle = styled.Text`
  font-family: ${fonts.regular};
  font-size: 12px;
  line-height: 16px;
  color: ${colors.tertiaryText};
`;

const TextContainer = styled.View``;

const IconWrapper = styled.TouchableOpacity``;
