import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props: any) {
  const scale = props?.size || 1;
  return (
    <Svg
      width={16 * scale}
      height={17 * scale}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M11.7494 0.664083C12.6344 -0.221361 14.0704 -0.221361 14.9553 0.664083L15.3363 1.04576C16.2212 1.9313 16.2212 3.36699 15.3363 4.2519L9.12621 10.4652C8.85224 10.736 8.51528 10.9344 8.14368 11.0414L4.99454 11.9421C4.73001 12.0177 4.44659 11.9421 4.25134 11.7217C4.05924 11.5548 3.98366 11.2713 4.05924 11.0068L4.9599 7.85767C5.06697 7.48607 5.26536 7.14911 5.53619 6.87513L11.7494 0.664083ZM13.8594 1.7329C13.5917 1.43783 13.113 1.43783 12.817 1.7329L11.9006 2.64899L13.3524 4.10074L14.2688 3.156C14.5648 2.88832 14.5648 2.40965 14.2688 2.11458L13.8594 1.7329ZM6.4148 8.27335L5.88575 10.1156L7.72799 9.58654C7.85396 9.5519 7.96418 9.48577 8.0555 9.39445L12.2848 5.1683L10.833 3.71655L6.6069 7.94584C6.51557 8.03717 6.44944 8.14739 6.4148 8.27335ZM6.29828 1.89319C6.71712 1.89319 7.05408 2.23173 7.05408 2.64899C7.05408 3.06782 6.71712 3.40478 6.29828 3.40478H2.77124C2.0756 3.40478 1.51159 3.96848 1.51159 4.66444V13.2301C1.51159 13.9261 2.0756 14.4898 2.77124 14.4898H11.3369C12.0329 14.4898 12.5966 13.9261 12.5966 13.2301V9.70306C12.5966 9.28423 12.9335 8.94727 13.3524 8.94727C13.7712 8.94727 14.1082 9.28423 14.1082 9.70306V13.2301C14.1082 14.7606 12.8674 16.0013 11.3369 16.0013H2.77124C1.24076 16.0013 0 14.7606 0 13.2301V4.66444C0 3.13395 1.24076 1.89319 2.77124 1.89319H6.29828Z"
        fill="black"
        fill-opacity="0.6"
      />
    </Svg>
  );
}

export default SvgComponent;
