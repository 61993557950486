import ConcessionRadioSelectTable from "../../Table/ConcessionRadioSelectTable";
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import React from "react";
import { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";

export default function ConcessionRadioSelectTbl(props: UseFieldApiConfig) {
  const { subscribeToValues, tableData, concessionCompositionId, isDataAvailable } =
    props;
  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <ConcessionRadioSelectTable
          tableData={tableData}
          concessionCompositionIdRef={concessionCompositionId}
          isDataAvailable={isDataAvailable}
        />
      )}
    </FormSpy>
  );
}
