import {
  BaseSidebarManifest,
  sidebarManifest,
} from "src/components/molecules/Navigation/SidebarManifest";
import { USER_TYPE, UserType } from "src/constant";

import { UserInformation } from "src/utils/manageState";

// Function to filter sidebar routes based on user information and allowed modules
const filterRoutes = (
  userInfo: UserInformation,
  allowedModules: string[]
): BaseSidebarManifest[] => {
  // Recursive helper function to filter routes based on conditions
  const filterRecursive = (
    arr: BaseSidebarManifest[],
    userType: UserType,
    type: string[] = [] // Type detail for USER_TYPE.EMPLOYEE
  ): BaseSidebarManifest[] => {
    return arr
      .filter((route: BaseSidebarManifest) => {
        // Filter 1: Module Check
        // Remove routes that are not part of the 'default' module
        // or not in the list of allowed modules
        if (
          route.module !== "default" &&
          !allowedModules.includes(route.module) &&
          userType !== USER_TYPE.EDBA_ADMIN
        ) {
          return false;
        }

        // Filter 2: Auth Check
        // Remove routes that are not authorized for the given user type
        if (!route.auth.includes(userType)) {
          return false;
        }

        // Filter 3: Sub-Auth Check (Employee Only)
        // For USER_TYPE.EMPLOYEE, only include routes where
        // the subAuth field has at least one type that matches
        // the userDetail.type
        if (userType === USER_TYPE.EMPLOYEE && route.subAuth) {
          return route.subAuth.some((sub) => type.includes(sub));
        }

        return true;
      })
      .map((route: BaseSidebarManifest) => {
        // Child Route Check:
        // If the route has child routes, recursively filter them
        // before including them in the result
        if ("children" in route && Array.isArray(route.children)) {
          const children = route.children as BaseSidebarManifest[];
          return {
            ...route,
            children: filterRecursive(children, userType, type),
          };
        }
        return route;
      });
  };

  // Entry point for recursive filter function
  // Fall back to an empty array if userInfo.userDetail.type is undefined
  return filterRecursive(
    sidebarManifest,
    userInfo.userType,
    userInfo?.userDetail?.type ?? []
  );
};

export default filterRoutes;
