import React, { CSSProperties, ReactNode } from "react";

import styled from "styled-components";

type Props = {
  children: ReactNode;
  style?: CSSProperties;
};

const ShadowBox = (props: Props) => {
  return <ShadowBoxDiv style={props?.style}>{props.children}</ShadowBoxDiv>;
};

export default ShadowBox;

const ShadowBoxDiv = styled.div`
  background-color: #fff;
  border-radius: ${(props) => props.theme.rem(1.6)};
  padding: ${(props) => props.theme.rem([1, 0, 1, 3])};
  box-sizing: border-box;
  @media all and (max-width: 767px) {
    background: none;
    box-shadow: none;
    border-radius: 0px;
    padding: ${({ theme }) => theme.rem([2.5, 0])};
  }
`;
