import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import ImageAtom from '../ImageAtom';
import { assigneeObjectType } from 'src/components/organism/Assignments/types';
import { Icon } from 'src/components/atoms/Icon/Icon';

interface propType {
  setStudent: () => void;
  profileUrl: string;
  studentDetail: assigneeObjectType;
  isDisabled?: boolean;
}

export default function UserAvatar(props: propType) {
  const { setStudent, profileUrl, isDisabled = false } = props;
  return (
    <TouchableOpacity style={[styles.container]} onPress={setStudent} disabled={isDisabled}>
      {profileUrl && profileUrl !== 'https://dev-api.edba.io/personal/photo/default' ? (
        <ImageAtom
          source={{
            uri: profileUrl,
          }}
          style={[styles.imgStyle]}
          mode="contain"
        />
      ) : (
        <Icon name="profile-pic" size={40} />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
  imgStyle: {
    height: 40,
    width: 40,
    borderRadius: 50,
  },
});
