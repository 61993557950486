import React from 'react';
import { RemarksDescriptionCell, RemarksHeaderCell } from './GradeCardElements';
import { Table, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import styled from 'styled-components/native';
import { colors, fonts } from 'src/styles/theme/styles';

export default function TableFourGradeCard(props: any) {
  const { additionalSubjectRows } = props;

  return (
    <TableFourContainer>
      <TableContainer>
        <Table sx={{fontFamily: fonts.regular}}>
          {/* Remarks Header */}
          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              <RemarksHeaderCell value={'REMARKS'} />
            </TableRow>
          </TableHead>

          {/* Special Remarks */}
          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              <RemarksHeaderCell value={'SPECIAL REMARKS'} />
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow sx={{height: 72}}>
              <RemarksDescriptionCell value={''} />
            </TableRow>
          </TableBody>

          {/* Hobbies and Interests */}
          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              <RemarksHeaderCell value={'HOBBIES AND INTERESTS'} />
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow sx={{height: 72}}>
              <RemarksDescriptionCell value={''} />
            </TableRow>
          </TableBody>

          {/* Area of Improvement */}
          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              <RemarksHeaderCell value={'AREA OF IMPROVEMENT'} />
            </TableRow>
          </TableHead>

          {/* `\u{200B}` is zero-width space for empty rows*/}
          <TableBody>
            <TableRow 
              sx={{
                '&.MuiTableRow-root': {
                  ...(additionalSubjectRows > 0 ? {} : { height: 108 }),
                },
              }} 
              style={{ height: 36 * (3 + additionalSubjectRows) }}
            >
              <RemarksDescriptionCell value={`\u{200B}`} />
            </TableRow>
          </TableBody>

        </Table>
      </TableContainer>
    </TableFourContainer>
  );
}

const TableFourContainer = styled.View`
  width: 450px;
 `;