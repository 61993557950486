import { TFormMode, TFormState } from "src/types";

import { DT } from "src/constant/dateTime";
import { format } from "date-fns";

export type admissionRoundData = {
  id: string;
  name: string;
  qualifiedApplications: string[];
  lastPayment: number;
  lastSubmission: number;
  meritDate: number;
  meritGenerated: null;
};

export type admissionRoundFormState = {
  state: TFormState;
  mode?: TFormMode;
  data?: admissionRoundData;
};

export const admissionRoundsListingHeadCells = [
  {
    id: "round_name",
    label: "roundName.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "last_submission_date",
    label: "lastSubmissionDate.label",
    align: "right",
    disableSorting: true,
  },
  {
    id: "no_of_Merits",
    label: "noOfMerits.label",
    align: "right",
    disableSorting: true,
  },
  {
    id: "cut_off_percentage",
    label: "cutOffPercentage.label",
    align: "right",
    disableSorting: true,
  },
  {
    id: "action",
    label: "action.label",
    align: "right",
    disableSorting: true,
  },
];

const fieldMap = {
  name: 0,
  lastSubmission: 1,
  meritDate: 2,
  lastPayment: 3,
};

const getDate = (timestamp?: number): string => {
  return timestamp ? format(timestamp, DT.DATE_FORMAT_SLASH) : "";
};

const transformInitialFormData = (data: admissionRoundData) => ({
  name: data?.name ?? "",
  lastSubmission: getDate(data?.lastSubmission),
  meritDate: getDate(data?.meritDate),
  lastPayment: getDate(data?.lastPayment),
});

export const roundsFormHelpers = {
  fieldMap,
  transformInitialFormData,
  getDate,
};
