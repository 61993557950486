import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  const size = props.size || 16;
  return (
    <Svg
      width={size * scale}
      height={size * scale}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M13.4476 18.1685C13.6121 18.0586 13.8054 18 14.0032 18C14.2684 18 14.5227 18.1054 14.7103 18.2929C14.8978 18.4804 15.0032 18.7348 15.0032 19C15.0032 19.1978 14.9445 19.3911 14.8346 19.5556C14.7248 19.72 14.5686 19.8482 14.3859 19.9239C14.2031 19.9996 14.0021 20.0194 13.8081 19.9808C13.6141 19.9422 13.4359 19.847 13.2961 19.7071C13.1562 19.5673 13.061 19.3891 13.0224 19.1951C12.9838 19.0011 13.0036 18.8 13.0793 18.6173C13.155 18.4346 13.2832 18.2784 13.4476 18.1685Z"
        fill="rgba(0, 0, 0, 0.6)"
        fill-opacity="0.6"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.50259 5.5V9.5H9.50299V5.5H5.50259ZM5.00254 4C4.4502 4 4.00244 4.44772 4.00244 5V10C4.00244 10.5523 4.4502 11 5.00254 11H10.003C10.5554 11 11.0031 10.5523 11.0031 10V5C11.0031 4.44772 10.5554 4 10.003 4H5.00254Z"
        fill="rgba(0, 0, 0, 0.6)"
        fill-opacity="0.6"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5033 5.5V9.5H18.5037V5.5H14.5033ZM14.0033 4C13.4509 4 13.0032 4.44772 13.0032 5V10C13.0032 10.5523 13.4509 11 14.0033 11H19.0038C19.5561 11 20.0039 10.5523 20.0039 10V5C20.0039 4.44772 19.5561 4 19.0038 4H14.0033Z"
        fill="rgba(0, 0, 0, 0.6)"
        fill-opacity="0.6"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.50259 14.5V18.5H9.50299V14.5H5.50259ZM5.00254 13C4.4502 13 4.00244 13.4477 4.00244 14V19C4.00244 19.5523 4.4502 20 5.00254 20H10.003C10.5554 20 11.0031 19.5523 11.0031 19V14C11.0031 13.4477 10.5554 13 10.003 13H5.00254Z"
        fill="rgba(0, 0, 0, 0.6)"
        fill-opacity="0.6"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0033 14.6499C14.8653 14.6499 14.7533 14.7618 14.7533 14.8999V15.8594C14.7533 16.2736 14.4175 16.6094 14.0032 16.6094C13.589 16.6094 13.2532 16.2736 13.2532 15.8594V14.8999C13.2532 13.9334 14.0368 13.1499 15.0033 13.1499H16.0034C16.4177 13.1499 16.7535 13.4857 16.7535 13.8999C16.7535 14.3141 16.4177 14.6499 16.0034 14.6499H15.0033Z"
        fill="rgba(0, 0, 0, 0.6)"
        fill-opacity="0.6"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0037 18.1094C18.1418 18.1094 18.2538 17.9974 18.2538 17.8594V13.8999C18.2538 13.4856 18.5896 13.1499 19.0038 13.1499C19.4181 13.1499 19.7539 13.4856 19.7539 13.8999V17.8594C19.7539 18.8259 18.9703 19.6094 18.0037 19.6094H17.0036C16.5894 19.6094 16.2536 19.2736 16.2536 18.8594C16.2536 18.4452 16.5894 18.1094 17.0036 18.1094H18.0037Z"
        fill="rgba(0, 0, 0, 0.6)"
        fill-opacity="0.6"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.00223 1.68359C2.3118 1.68359 1.7521 2.24324 1.7521 2.93359V8.49988C1.7521 8.91409 1.41628 9.24988 1.00203 9.24988C0.587773 9.24988 0.251953 8.91409 0.251953 8.49988V2.93359C0.251953 1.41481 1.48329 0.183594 3.00223 0.183594H8.72541C9.13967 0.183594 9.47549 0.51938 9.47549 0.933594C9.47549 1.34781 9.13967 1.68359 8.72541 1.68359H3.00223ZM14.5308 0.933594C14.5308 0.51938 14.8666 0.183594 15.2809 0.183594H21.004C22.523 0.183594 23.7543 1.41481 23.7543 2.93359V8.49988C23.7543 8.91409 23.4185 9.24988 23.0042 9.24988C22.59 9.24988 22.2542 8.91409 22.2542 8.49988V2.93359C22.2542 2.24324 21.6945 1.68359 21.004 1.68359H15.2809C14.8666 1.68359 14.5308 1.34781 14.5308 0.933594ZM1.00203 14.6836C1.41628 14.6836 1.7521 15.0194 1.7521 15.4336V20.9999C1.7521 21.6902 2.3118 22.2499 3.00223 22.2499H8.72541C9.13967 22.2499 9.47549 22.5857 9.47549 22.9999C9.47549 23.4141 9.13967 23.7499 8.72541 23.7499H3.00223C1.48329 23.7499 0.251953 22.5187 0.251953 20.9999V15.4336C0.251953 15.0194 0.587773 14.6836 1.00203 14.6836ZM23.0042 14.6836C23.4185 14.6836 23.7543 15.0194 23.7543 15.4336V20.9999C23.7543 22.5187 22.523 23.7499 21.004 23.7499H15.2809C14.8666 23.7499 14.5308 23.4141 14.5308 22.9999C14.5308 22.5857 14.8666 22.2499 15.2809 22.2499H21.004C21.6945 22.2499 22.2542 21.6902 22.2542 20.9999V15.4336C22.2542 15.0194 22.59 14.6836 23.0042 14.6836Z"
        fill="rgba(0, 0, 0, 0.6)"
        fill-opacity="0.6"
      />
    </Svg>
  );
}

export default SvgComponent;
