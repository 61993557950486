import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import styled from 'styled-components/native';
import { faUsdCircle } from '@fortawesome/pro-regular-svg-icons';

import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';
import MediumText from 'src/components/atoms/Text/MediumText';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { paymentListInterface } from 'src/components/organism/Payment/HooksAndConstants';
import { height } from 'src/constant/device';
import { getFeeDetailsForParticularStudent } from 'src/graphql/payment';
import { colors, fontWeight } from 'src/styles/theme/styles';
import { NoData } from 'src/components/atoms/NoData';
import { useI18n } from 'src/i18n/hooks';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { properCase } from 'src/utils/utility';

const StudentPaymentStatus = (props) => {
  const [paymentList, setPaymentList] = useState<paymentListInterface[]>([]);
  const { user, batchId, batchAlias, classAlias } = props.route.params;

  const { t } = useI18n();

  const { data, loading, refetch } = useQuery(getFeeDetailsForParticularStudent, {
    variables: {
      user: user.id,
      batch: batchId,
    },
  });

  const paymentData = data?.getStudentFeeBook ?? {};

  const customizingDataFunction = (arrayToBeOptimized: paymentListInterface[]) => {
    const newCustomizedData: paymentListInterface[] = [];
    arrayToBeOptimized.forEach((item: any) => {
      const isPaid = item.status === 'PAID' ? true : false;
      const obj = {
        id: item.groupId,
        groupName: item.groupName,
        amount: item.payable,
        checked: false,
        totalConcessions: item.totalConcessions,
        pending: !isPaid,
        paymentId: item.paymentId,
        fees: item.fees,
        isGroup: item.groupName !== item.fees[0].name ? true : false,
        date: isPaid ? format(new Date(item.paymentDate), DT.DATE_FORMAT_SLASH) : '',
        methodofpayment: isPaid ? item.paymentMode : '',
        receipt: isPaid ? '' : '',
      };

      newCustomizedData.push(obj);
    });

    return newCustomizedData;
  };

  useEffect(() => {
    if (!loading) {
      const paymentData = customizingDataFunction(data?.getStudentFeeBook?.feeBook || []);
      setPaymentList(paymentData);
    }
  }, [loading]);

  const ConcessionComponent = (props: any) => {
    const { concessionArr } = props;
    return concessionArr?.map((concessionInfo: any) => (
      <ConcessionComponentWrapper>
        <ConcessionName>
          <NormaltextAtom lines={1} lineHeight={2} value={concessionInfo.name} fontSize={1.6} />
        </ConcessionName>
        <NormaltextAtom
          lineHeight={2}
          value={`-  \u20B9  ${concessionInfo.amount}`}
          fontSize={1.6}
        />
      </ConcessionComponentWrapper>
    ));
  };

  const GroupComponent = (props: any) => {
    const { groupPaymentsItem } = props;
    return (
      <GrpComponentWrapper>
        <GrpName>
          <NormaltextAtom lines={1} lineHeight={2} value={groupPaymentsItem.name} />
        </GrpName>
        <NormaltextAtom
          lineHeight={2}
          value={` \u20B9  ${groupPaymentsItem.amount}`}
        />
      </GrpComponentWrapper>
    );
  };

  return (
    <>
      <MobileHeader label="Payment" backIcon={true} {...props} />
      <Container>
        <StudentDetails>
          <HeaderSix
            value={properCase(user?.fullName || '-')}
            fontWeight={fontWeight[600]}
          />
          <NormalTextSelect
            value={`${batchAlias || '--'}       ${classAlias || '--'} ${user?.division}`}
            fontWeight={fontWeight[600]}
            color={colors.tertiaryText}
          />
        </StudentDetails>
        <PaymentDetails>
          {!loading ? (
            paymentList && paymentList.length > 0 ? (
              <>
                <NativeTableHeader>
                  <NormaltextAtom value={t('payment.particulars')} />
                  <NormaltextAtom value={t('payment.amount')} />
                </NativeTableHeader>
                <FlatList
                  data={paymentList}
                  showsVerticalScrollIndicator={false}
                  keyExtractor={(item) => item.id}
                  renderItem={({ item }) => (
                    <View>
                      <ItemHeaderWrapper>
                        <ItemHeader>
                          <ModuleNameWrapper>
                            <MediumText
                              lines={1}
                              value={item.groupName}
                              lineHeight={2.4}
                              color={colors.primaryText}
                            />
                          </ModuleNameWrapper>
                          <MediumTextSelect
                            fontWeight={'600'}
                            value={`\u20B9  ${item?.amount}`}
                            color={colors.primaryText}
                          />
                        </ItemHeader>
                      </ItemHeaderWrapper>
                      {item.isGroup ? (
                        <ChildrenItemHeaderWrapper>
                          {item?.fees?.map((ele: any) => (
                            <>
                              <GroupComponent groupPaymentsItem={ele} />
                              {ele?.concessions.length > 0 && (
                                <ConcessionComponent concessionArr={ele.concessions} />
                              )}
                            </>
                          ))}
                        </ChildrenItemHeaderWrapper>
                      ) : (
                        item?.fees && item.fees?.length > 0 && item.fees[0]?.concessions?.length > 0 && (
                          <ChildrenItemHeaderWrapper>
                            <ConcessionComponent concessionArr={item.fees[0].concessions} />
                          </ChildrenItemHeaderWrapper>
                        )
                      )}
                    </View>
                  )}
                />
                <BorderLineWrapper />
                <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={true}>
                  <MediumTextSelect value={t('totalPayable.label')} />
                  <MediumTextSelect value={`\u20B9 ${paymentData?.totalPayable}`} />
                </TotalPaymentAndSelectedPayableAmountWrapper>

                <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={true}>
                  <MediumTextSelect value={t('totalPaid.label')} />
                  <MediumTextSelect value={`\u20B9 ${paymentData?.totalPaid}`} />
                </TotalPaymentAndSelectedPayableAmountWrapper>
              </>
            ) : (
              <NoDataWrapper>
                <NoData label={t('noPaymentData.text')} icon={faUsdCircle} />
              </NoDataWrapper>
            )
          ) : (
            <SpinnerView>
              <LoaderSpinner />
            </SpinnerView>
          )}
        </PaymentDetails>
      </Container >
    </>
  );
};

export default StudentPaymentStatus;

const NativeTableHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const ModuleNameWrapper = styled.View`
  flex: 1;
  align-items: flex-start;
  padding-right: 8px;
  width: 60%;
`;

const BorderLineWrapper = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const TotalPaymentAndSelectedPayableAmountWrapper = styled.View<{ displayBorder: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: ${({ displayBorder }) => displayBorder ? 1 : 0}px;
  border-bottom-color: ${colors.dividerColor};
  padding-vertical: 16px;
`;

const Container = styled.View`
  margin: 24px;
`;

const ConcessionName = styled.View`
  flex: 0.75;
`;

const StudentDetails = styled.View``;

const PaymentDetails = styled.View`
  margin-top: 10px;
`;

const ItemHeaderWrapper = styled.View`
  flex-direction: row;
  margin-bottom: 24px;
  align-items: center;
`;

const CommonStyleRowAndSpaceBetween = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const GrpName = styled.View`
  margin-right: 5;
  flex: 1;  
`;

const GrpComponentWrapper = styled(CommonStyleRowAndSpaceBetween)`
  margin-bottom: 28;
  margin-right: 1;
`;

const ConcessionComponentWrapper = styled(CommonStyleRowAndSpaceBetween)`
  margin-bottom: 35;
  margin-right: 1;
`;

const ItemHeader = styled(CommonStyleRowAndSpaceBetween)`
  flex:1;
  margin-right: 3;
  padding-bottom:3;
`;

const ChildrenItemHeaderWrapper = styled.View`
  padding-left: 30px;
`;

const SpinnerView = styled.View`
  height: ${height - 300}px;
  justify-content: center;
  align-items: center;
`;

const NoDataWrapper = styled.View`
  margin-top: ${height - height / 1.3}px;
`;
