import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { TableBody, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TouchableOpacity } from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import CasteCategoryForm from 'src/components/molecules/Admission/CasteCategoryForm';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { createSearchFilter, debounce } from 'src/components/services';
import { getCommonNameSearchFilter } from 'src/components/services/filters';
import { AUTOCOMPLETE_MODULE, DefaultUserSelection, ERROR, limit, SUCCESS  } from 'src/constant';
import { height } from 'src/constant/device';
import { ADD_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { useFilter } from 'src/contexts/filter-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'src/routes/routing.web';
import styled from 'styled-components/native';
import { useGetCastes, useCreateCaste } from '../../../graphql/admission/caste-category';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

const headCells1 = [
  {
    id: 'caste_name',
    label: 'casteName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'caste_alias',
    label: 'casteAlias.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
  },
];

export default function CasteCategories() {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { theme } = useThemeSystem();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  let searchValue = '' as string;
  let row;
  
  const { page = 1, dataLimit = limit }: any = useParams();
  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const [totalCount, setTotalCount] = useState(null);
  
  if (filters && filters?.caste_category?.on) {
    searchValue = filters?.caste_category?.search;
  }

  const [searching, setSearchingCategory] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [canClick, setCanClick] = useState(true);
  const [modalState, handleModal] = useState(false);
  const [isDefaultOption, setIsDefaultOption] = useState<boolean>(false);
  const [editCategoryData, setCategoryData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  let { setAlertDetails } = useAlertSystem();
  const { getCastes, castesData, castesError, castesLoading, refetchCastes } = useGetCastes();
  const { createCaste, createCasteData, createCasteError } = useCreateCaste();

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    setSearchingCategory(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [page, searchValue]);

  function setTitle() {
    setHeading([
      {
        text: t('casteCategories.label'),
        url: '',
      },
    ]);
  }

  function handleFetch() {
    const filters = getCommonNameSearchFilter(searchValue);
    let casteFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    getCastes({ variables: casteFilter });
  }

  row = castesData?.castes?.data;

  useEffect(() => {
    const count = castesData?.castes?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [castesData?.castes?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);


  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    currentPage,
  );

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.CASTE_CATEGORY]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchCategory(category: string) {
    let isClear = category?.length === 0;
    persistSearch(!isClear, category);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchCategory(q), 500),
    [],
  );

  const handleSearch = (category: string) => {
    setSearchingCategory(category);
    delayedQuery(category);
  };

  const closeModal = () => {
    if (isEditModal) {
      setCategoryData({});
      setIsEditModal(false);
    }
    handleModal(false);
    resetForm();
  };

  function resetForm() {
    setIsDefaultOption(false);
    setValue('category_name', '');
    setValue('category_alias', '');
    clearErrors();
  }

  const editCategoryModal = (selectedCategory: any) => {
    setCategoryData(selectedCategory);
    hideAlert();
    setIsEditModal(true);
    handleModal(true);
  };

  const addCategoryModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  const handleAddEditCategory = async (CategoryData: any) => {
    setCanClick(false);
    try {
      const casteObject = {
        name: CategoryData.category_name,
        alias: CategoryData.category_alias,
        defaultUserSelection: isDefaultOption ? DefaultUserSelection.ALLOWED : DefaultUserSelection.NOT_ALLOWED
      };
      if (isEditModal) casteObject['id'] = editCategoryData?.id;

      const response = await createCaste({
        variables: { payload: casteObject },
      });
      if (response.data) {
        closeModal();
        if (refetchCastes) refetchCastes();
        setAlertDetails({
          message: isEditModal ? UPDATE_SUCCESS.CASTE_CATEGORY : ADD_SUCCESS.CASTE_CATEGORY,
          level: SUCCESS,
        });
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  return (
    <LoadContentWrapper>
      <FlexRowView>
        <SearchWrapper>
          <Search
            id="categoriesSearch"
            handleChange={handleSearch}
            value={searching}
            label={t('searchCategories.text')}
          />
        </SearchWrapper>

        <AddCriteriaWrapper>
          <SecondaryBtn label={t('addCategory.text')} onPress={addCategoryModal} />
        </AddCriteriaWrapper>
      </FlexRowView>
      
      <TblContainer height={'100%'}>
        <TblHead setHeadCells={setHeadCells} />
        <>
          {!castesLoading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                    <TableRows key={i}>
                      <TableCells value={item?.name} />
                      <TableCells value={item?.alias} />

                      <TableActions align="right">
                        <TableActionView>
                          <Tooltip title="Edit Category">
                            <TouchableOpacity onPress={() => editCategoryModal(item)}>
                              <FontAwesomeIcon
                                icon={faPencil}
                                color={theme?.table?.editIconColor}
                              />
                            </TouchableOpacity>
                          </Tooltip>
                        </TableActionView>
                      </TableActions>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>

      <Pagination
        pathName={`admission/caste-categories`}
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        setRowLimit={setRowLimit}
        hidePagination={!!(totalCount && totalCount <= rowLimit)}
      />

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        modalVisible={modalState}
        Headerpopup={isEditModal ? t('editCategory.text') : t('addCategory.text')}
        handleSave={handleSubmit(handleAddEditCategory)}
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}
        width={544}
        height={256}>
        <CasteCategoryForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          isEditModal={isEditModal}
          editCategoryData={editCategoryData}
          reset={reset}
          setIsDefaultOption={setIsDefaultOption}
          isDefaultOption={isDefaultOption}
        />
      </NormalModal>
    </LoadContentWrapper>
  );
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 10;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  max-width: 280px;
  margin-right: 8px;
`;

const AddCriteriaWrapper = styled.View`
  flex: 1;
  align-items: flex-end;
  max-width: 140px;
  min-width: 112px;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
