import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'src/routes/routing';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { limit, UserType } from 'src/constant';
import { userInformation } from 'src/utils/manageState';
import ProfileDetailView from './DetailView.web';
import { useLoadUserDetails } from 'src/utils/customHooks/useLoadUserDetails';
import { ViewMode } from 'src/components/molecules/Forms/types';
import { LoadingOrComponent } from 'src/components/molecules/Loading';
import { properCase } from 'src/utils/utility';

type ProfileViewType = {
  profileUserType?: UserType;
  navigation?: any;
}

export const ProfileView: React.FC<ProfileViewType> = (props) => {
  const { t } = useI18n();
  let location = useLocation();
  const params = useParams();
  let [ userId, setUserId ] = useState<string | undefined>(params.userId)
  let [ userType, setUserType ] = useState<UserType | undefined>(props.profileUserType)
  const step = parseInt(params.step);
  const mode = params.mode;
  let history = useHistory();

  const currentUserObject = userInformation();

  function ensureUserIdAndType() {
    if (params.userId) {
      setUserId(params.userId)
      setUserType(props.profileUserType)
    } else {
      setUserId(currentUserObject.userId);
      setUserType(currentUserObject.userType);
    }
  }

  useEffect(() => {
    ensureUserIdAndType()
  }, []);
  
  useEffect(() => {
    ensureUserIdAndType()
  }, [params.userId, props.profileUserType, currentUserObject]);

  const { setHeading } = useHeaderTitle();
  const { userDetails, formContents, loadUserDetails } = useLoadUserDetails();

  useEffect(() => {  
    if(userId && userType) {
      loadUserDetails({ userId, userType });
    }
  }, [userId, userType])

  let pathname: string = location.pathname;
 
  const handleTabChange = (step: number, tabname: string) => {
    if (pathname.includes('/profile') && userId) {
      history.push(`/profile/${step}/${mode}`);
    } else if (userType === UserType.INSTITUTE_EMPLOYEE) {
      history.push(`/employee/${userId}/${step}/${mode}`);
    } else if (userType === UserType.INSTITUTE_STUDENT) {
      history.push(
        `/student/${userId}/${step}/${mode}`,
      );
    }
  };

  useEffect(() => {
    setTitle();
  }, [userDetails]);

  function setTitle() {
    if (pathname.includes('/profile')) {
      setHeading([
        {
          text: t('profile.label'),
        },
      ]);
    } else {
      setDynamicTitle();
    }
  }

  function setDynamicTitle() {
    const fromStudent = pathname.includes('/student');
    const from = history?.location?.state?.from;
    const customHeading = history?.location?.state?.customHeading || false;
    let heading;
    const employeeName = properCase(userDetails?.personalDetails?.fullName || '-');

    const studentName = properCase(userDetails?.personalDetails?.fullName || '-');

    if (customHeading) {
      heading = customHeading;
    } else if (fromStudent) {
      heading = [
        {
          text: from ? t(`${from}.label`) : t('classrooms.label'),
          url: from ? `/assessment/${from}/limit/${limit}/page/1` : '/classrooms',
        },
        {
          text: t('students.label'),
          url: `/classroom/${userDetails?.academic?.batch?.id}/${userDetails?.academic?.division}/students/limit/${limit}/page/1`,
        },
        {
          text: studentName,
        },
      ];
    } else if (from && from === 'Users') {
      heading = [
        {
          text: t('users.label'),
          url: `/control-panel/users/limit/${limit}/page/1`,
        },
        {
          text: employeeName,
        },
      ];
    } else {
      heading = [
        {
          text: t('employees.label'),
          url: `/employees/limit/${limit}/page/1`,
        },
        {
          text: employeeName,
        },
      ];
    }
    setHeading(heading);
  }

  return (
    <LoadingOrComponent loading={!userId}>
      <ProfileDetailView
        step={step}
        mode={mode}
        userId={userId}
        userType={userType}
        onChangeTab={handleTabChange}
        formContents={formContents}
        navigation={props.navigation}
        viewMode={ViewMode.READ}
      />
    </LoadingOrComponent>
  );
};
