import React, { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import AttendaceChild from './Children/Attendance';
import StudentHistoryChild from './Children/StudentHistory';
import { useHistory } from 'src/routes/routing';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { withRem } from 'src/utils/useRem';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import { height, isWeb } from 'src/constant/device';
import { colors, mobileWeb } from 'src/styles/theme/styles';
import { useThemeSystem } from 'src/contexts/theme-context';

const Attendance = withRem(() => {
  const history = useHistory();
  const { t } = useI18n();
  const { theme } = useThemeSystem();

  const { rem }: any = useTheme();

  const location = useLocation();
  const match = useRouteMatch();
  const isMarkAttendance = location && location.pathname.includes('mark');

  const { setHeading } = useHeaderTitle();

  async function setTitle() {
    setHeading([
      {
        text: t('attendance.label'),
        url: '',
      },
    ]);
  }

  function changeTabs(tab: any) {
    if (tab === 1) {
      history.push(match.url.replace('history', 'mark'));
    } else {
      history.push(match.url.replace('mark', 'history'));
    }
  }

  useEffect(() => {
    setTitle();
  }, []);

  return (
    <>
      <MainWrapper
        style={{
          backgroundColor: theme?.content?.backgroundColor,
          minHeight: isWeb ? (mobileWeb ? height - 100 : height - 190) : height - 140,
          margin: rem(3.2),
          padding: rem([2.4, 3.2]),
        }}>
        <ModalTabs
          roundCorner={false}
          onSelectSwitch={(t) => {
            changeTabs(t);
          }}
          option1={t('markAttendance.text')}
          option2={t('history.label')}
          selectionMode={isMarkAttendance ? 1 : 2}
          style={{
            marginBottom: isMarkAttendance ? rem(2.8) : rem(2.3),
            position: isWeb ? 'sticky' : 'relative',
            top: 0,
            left: 0,
            backgroundColor: 'white',
            zIndex: 1,
          }}
          onChange={changeTabs}
          tabswidth={rem(17.2)}
          tabsheight={rem(3)}
          padding_bottom={rem(1.2)}
        />
        {isMarkAttendance ? <AttendaceChild /> : <StudentHistoryChild />}
      </MainWrapper>
    </>
  );
});
export default Attendance;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-color: ${colors.borderGrey};
  border-radius: 16px;
`;
