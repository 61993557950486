import React, { useState } from 'react';
import { Checkbox } from '@mui/material';
import { StyleSheet, View } from 'react-native';
import MediumText from '../Text/MediumText';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components';

export default function Check(props: any) {
  const { id, onChange, checked = true, header, headerStyle = null, style, direction, same = true, disabled = false } = props;
  const [isChecked, setIsChecked] = useState(checked);
  const { t } = useI18n();

  function handleCheckbox() {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  }

  return (
    <View
      style={[
        styles.container,
        style,
        { justifyContent: direction === 'right' ? 'flex-end' : 'flex-start' },
      ]}>
      <CheckboxRoot
        checked={same ? isChecked : checked}
        onChange={handleCheckbox}
        name={id}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={disabled}
      />
      {header && (
        <View style={[styles.headView, headerStyle]}>
          <MediumText value={t(header)} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row' },
  headView: {
    alignSelf: 'center',
    paddingLeft: 4
  },
});

const CheckboxRoot = styled(Checkbox)`
  ${({ theme, disabled }) => `
    &:hover {
      background-color: transparent !important;
    }
    padding: 0 !important;
    opacity: ${disabled ? 0.3 : 1};
    color: ${theme?.checkbox?.color} !important;
  `}
`;
