/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import { commonBorder, parentBorderLeft, parentBorderRight } from './ExpandTableAtoms';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import useTable from './UseTable';

export default function AttendanceTable(props: any) {
  const { data, onClick, studentsAttendenceInfo, headCells, setHeadCells } = props;
  const { t } = useI18n();

  const { theme } = useThemeSystem();

  const absentString = t('absent.label');
  const presentString = t('present.label');
  const { TblHead } = useTable(null, headCells);

  return (
    <div>
      {studentsAttendenceInfo && (
        <div id="app" style={{ border: '1px solid transparent' }}>
          <table className="tb" style={{ backgroundColor: theme?.table?.backgroundColor }}>
            <TblHead setHeadCells={setHeadCells} style={styles.tableHeader} />
            <tbody>
              {studentsAttendenceInfo &&
                data &&
                data?.length > 0 &&
                data?.map((d: any) => (
                  <React.Fragment>
                    <tr
                      style={{
                        backgroundColor: false ? '#EAF2FA' : 'transparent',
                        ...parentBorderRight(false, 0, 1),
                        ...parentBorderLeft(false, 0, 1),
                        ...commonBorder(false, 0, 1),
                        color: 'red',
                      }}>
                      <td style={{ color: theme?.table?.color, width: '15%', padding: '3px 16px' }}>
                        {d?.id || '-'}
                      </td>
                      <td style={{ color: theme?.table?.color, width: '35%', padding: '3px 16px' }}>
                        {d?.student}
                      </td>
                      <td
                        style={{
                          color: theme?.table?.color,
                          width: '50%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '3px 16px',
                        }}>
                        <FormControl
                          component="fieldset"
                          onClick={(e: any) =>
                            onClick({ selectedValue: e.target.value, user: d?.user })
                          }
                          style={{ width: '100%' }}>
                          <RadioGroup
                            row
                            aria-label="gender"
                            name="row-radio-buttons-group"
                            style={{ width: '100%', justifyContent: 'space-between' }}>
                            <FormControlLabel
                              id={'presentInput'}
                              value="true"
                              checked={studentsAttendenceInfo[d.user] ? true : false}
                              control={<Radio size="small" />}
                              label={presentString}
                            />
                            <FormControlLabel
                              id={'absentInput'}
                              value="false"
                              checked={studentsAttendenceInfo[d.user] === false ? true : false}
                              control={<Radio size="small" />}
                              label={absentString}
                            />
                          </RadioGroup>
                        </FormControl>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

const styles = {
  tableHeader: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
  }
}
