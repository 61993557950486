import React from 'react';
import { colors, fonts } from 'src/styles/theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components/native';
import { isWeb } from 'src/constant/device';
import { formatDistanceToNowStrict } from 'date-fns';

export default function NotificationItem(props: {
  notification: {
    id: string;
    title: string;
    body: string;
    type: string;
    status: string;
    date: Date;
    createdBy: {
      profilePicture: {
        documentUrl: string;
      };
    };
  };
}) {
  const [notifi, setNotifi] = React.useState(props.notification);
  const isNotificationsPage = isWeb && location.pathname.startsWith('/notifications');

  return (
    <RootContainer>
      <Container>
        <BadgeContainer>
          {notifi.status == 'UNREAD' && (
            <FontAwesomeIcon icon={faCircle} size={8} color={colors.blue} />
          )}
        </BadgeContainer>
        <ContentContainer isNotificationsPage={isNotificationsPage}>
          <TitleText numberOfLines={1} ellipsizeMode="tail">
            {notifi.title}
          </TitleText>
          <BodyText numberOfLines={1}>{notifi.body}</BodyText>
          <DateText numberOfLines={1}>{formatDistanceToNowStrict(notifi.date)} ago</DateText>
        </ContentContainer>
      </Container>
    </RootContainer>
  );
}

const RootContainer = styled.View`
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 64px;
  width: 100%;
  border-bottom-width: 1;
  border-bottom-color: ${colors.lighterBorder};
`;

const Container = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-left: 18px;
`;

const BadgeContainer = styled.View`
  padding-top: 8px;
  padding-right: 8px;
`;

const ContentContainer = styled.View<{ isNotificationsPage: boolean }>`
  max-width: ${({ isNotificationsPage }) => (isNotificationsPage ? '90%' : '300px')};
`;

const TitleText = styled.Text`
  font-size: 16px;
  font-weight: 600;
  font-family: ${fonts.medium};
  color: ${colors.secondaryText};
  line-height: 24px;
`;

const BodyText = styled.Text`
  font-size: 14px;
  font-weight: 400;
  font-family: ${fonts.medium};
  color: ${colors.secondaryText};
  line-height: 20px;
`;

const DateText = styled.Text`
  font-size: 12px;
  line-height: 16px;
  font-family: ${fonts.medium};
  color: ${colors.tertiaryText};
  padding-top: 5px;
`;
