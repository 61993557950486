import React, { Dispatch, SetStateAction, useState } from 'react';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { ERROR } from 'src/constant';
import ERROR_MSG from 'src/constant/error';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useGenerateGraceByResolution } from 'src/graphql/assessment/assessment-results';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components';
import GenerateResolutionRadioSelectTable from './GenerateResolutionRadioSelectTable';
import { ResolutionObject } from 'src/components/organism/Assessment/Results/helpers';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { faRedo } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'src/styles/theme/styles';

interface Props {
  division: string;
  examId: string;
  examName: string;
  selectedGrace: ResolutionObject | null;
  setSelectedGrace: Dispatch<SetStateAction<ResolutionObject | null>>;
  marksType: boolean;
}

export default function GenerateResolutionModal(props: Props) {
  const { division, examId, examName, selectedGrace, setSelectedGrace, marksType } = props;
  const { t } = useI18n();
  const { generateGraceByResolution } = useGenerateGraceByResolution();
  const { setAlertDetails } = useAlertSystem();
  const [canClick, setCanClick] = useState<boolean>(true);
  const [graceData, setGraceData] = useState([]);

  async function handleGenerateResolution() {
    setCanClick(false);
    try {
      const response = await generateGraceByResolution({ variables: { division, examId } });
      const graceData = response?.data?.generateGraceByResolution;
      if (graceData && graceData.length) {
        setGraceData(graceData);
        setCanClick(true);
      } else {
        throw new Error(ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  return (
    <>
      {graceData && graceData.length ? (
        <GenerateResolutionRadioSelectTable
          examName={examName}
          graceData={graceData}
          selectedGrace={selectedGrace}
          setData={setSelectedGrace}
          marksType={marksType}
        />
      ) : (
        <GenerateResolutionContainer>
          <HeaderThree
            value={t('applyGraceByResolution.heading')}
            lineHeight={32}
            fontWeight={600}
          />
          <NormaltextAtom
            style={{ marginTop: 9, marginBottom: 9 }}
            value={t('applyGraceByResolution.desc')}
          />
          <GenerateResolutionButton>
            <SecondaryBtn
              icon={faRedo}
              iconColor={colors.white}
              label={t('generate.label')}
              lines={1}
              onPress={handleGenerateResolution}
              canLoad={!canClick}
              style={{ marginTop: '8px' }}
            />
          </GenerateResolutionButton>
        </GenerateResolutionContainer>
      )}
    </>
  );
}

const GenerateResolutionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const GenerateResolutionButton = styled.div`
  margin-top: 8px;
`;
