import React, { useEffect, useState } from 'react';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import formJSON from 'src/form-json/assessment/Results/ATKT-Reval-form.json';
import { FieldValues, FieldErrors } from 'react-hook-form';
import { addDays, format } from 'date-fns';
import { DT } from 'src/constant/dateTime';

type Tprops = {
  control: FieldValues;
  errors: FieldErrors;
};

const ATKTRevalForm = ({ control, errors }: Tprops) => {
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};
  const maxDate = format(addDays(new Date(), 90), DT.DATE_FORMAT_SLASH);

  useEffect(() => {
    formJSON[0].fields[0].dateTimePickerMaxDate = maxDate;
    setElements({ ...formJSON[0] });
  }, []);

  return (
    <>
      {fields &&
        fields.map((field: any) => (
          // @ts-ignore comment
          <Element key={field.id} field={field} control={control} errors={errors} />
        ))}
    </>
  );
};

export default ATKTRevalForm;
