import { ExpandCollapseCell, NoRecordsFound, TableCells, TableDiv, TableRows } from './TableAtom';
import React, { Dispatch, SetStateAction, useState } from 'react';

import RadioStandalone from 'src/components/atoms/Radio/StandaloneRadio/index.web';
import { TableBody } from '@mui/material';
import styled from 'styled-components/native';
import useTable from './UseTable';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';
import { colors } from 'src/styles/theme/styles';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { GradingSystemObject } from 'src/components/organism/Academics/types';
import { getTableValue } from 'src/utils/utility';

interface PropsType {
  tableData: { id: string; name: string }[];
  data: GradingSystemObject | null;
  setData: Dispatch<SetStateAction<GradingSystemObject | null>>;
  isEdit: boolean;
  isDataAvailable: boolean;
}

const headCells1 = [
  {
    id: 'grade',
    label: 'grade.label',
    align: 'left',
    disableSorting: true,
    style: { width: '10%' },
  },
  {
    id: 'lower',
    label: 'lower.label',
    align: 'right',
    disableSorting: true,
    style: { width: '15%' },
  },
  {
    id: 'higher',
    label: 'higher.label',
    align: 'right',
    disableSorting: true,
    style: { width: '17.5%' },
  },
  {
    id: 'gradePoint',
    label: 'grade-point.label',
    align: 'right',
    disableSorting: true,
    style: { width: '17.5%' },
  },
  {
    id: 'remark',
    label: 'remark.label',
    align: 'left',
    disableSorting: true,
    style: { paddingLeft: '15%', width: '25%' },
  },
];

const GradingSystemRadioSelectTable = (props: PropsType) => {
  const { tableData, isDataAvailable, data, setData } = props;
  const [trow, setT] = useState<boolean>(false);
  const [current, setCurrent] = useState<number | null>(null);
  const [headCells, setHeadCells] = useState(headCells1);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    tableData,
    headCells,
    null,
    tableData?.length,
  );

  const radioBtnStyle = { width: 16, padding: 1 };

  function collapseFn() {
    setT(false);
    setCurrent(null);
  }

  async function expandFn(i: number) {
    setT(true);
    setCurrent(i);
  }

  function handleChange(data: any): void {
    setData(data);
  }

  return (
    <TableDiv customStyle={{ marginTop: 0 }}>
      <TblContainer>
        {tableData?.length > 0 ? (
          <TableBody>
            {recordsAfterPagingAndSorting()?.map((gradingSystemData: any, i: number) => (
              <>
                <TableRows key={`e${i}`}>
                  <TableCells style={radioBtnStyle}>
                    <RadioStandalone
                      isChecked={data?.id === gradingSystemData.id}
                      value={gradingSystemData.id}
                      handleChange={(e) => handleChange(gradingSystemData)}
                    />
                  </TableCells>
                  <TableCells>
                    <HeaderSix
                      value={gradingSystemData?.name}
                      color={colors.primaryText}
                      fontWeight={data?.id === gradingSystemData?.id ? 600 : 400}
                    />
                  </TableCells>
                  <ExpandCollapseCell
                    trow={trow}
                    current={current}
                    i={i}
                    classNames={''}
                    textValue={''}
                    CollapseFn={collapseFn}
                    ExpandFn={() => expandFn(i)}
                    size={28}
                    color={colors.primaryColor}
                  />
                </TableRows>
                {trow && current === i && (
                  <TableRows>
                    <TableCells
                      colspan={5}
                      style={{
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom:
                          gradingSystemData?.grades && gradingSystemData?.grades?.length > 0
                            ? 16
                            : 0,
                      }}>
                      <TableDiv customStyle={{ marginTop: 0 }}>
                        <TblContainer overflowX={'auto'} maxHeight={280}>
                          <TblHead setHeadCells={setHeadCells} />
                          {gradingSystemData?.grades && gradingSystemData.grades.length > 0 ? (
                            <TableBody>
                              {gradingSystemData.grades.map((item: any) => (
                                <>
                                  <TableRows>
                                    <TableCells value={getTableValue(item?.name)} />
                                    <TableCells value={getTableValue(item?.min)} align="right" />
                                    <TableCells value={getTableValue(item?.max)} align="right" />
                                    <TableCells value={getTableValue(item?.gradePoint)} align="right" />
                                    <TableCells
                                      value={getTableValue(item?.remarks)}
                                      style={{ paddingLeft: '15%' }}
                                    />
                                  </TableRows>
                                </>
                              ))}
                            </TableBody>
                          ) : (
                            <SpinnerView>
                              <NoRecordsFound colspan={5} maxHeight={56} />
                            </SpinnerView>
                          )}
                        </TblContainer>
                      </TableDiv>
                    </TableCells>
                  </TableRows>
                )}
              </>
            ))}
          </TableBody>
        ) : (
          <SpinnerView>
            {isDataAvailable ? <NoRecordsFound colspan={3} maxHeight={568} /> : <LoaderSpinner />}
          </SpinnerView>
        )}
      </TblContainer>
    </TableDiv>
  );
};

const SpinnerView = styled.View`
  height: 568px;
  display: contents;
`;

export default GradingSystemRadioSelectTable;
