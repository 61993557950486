import {
  NoRecordsFound,
  TableCells,
  TableRows,
} from "src/components/molecules/Table/TableAtom";

import { DropdownOptions } from "src/types";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import React from "react";
import SearchDropdown from "src/components/atoms/AutoComplete/index.web";
import { TStudentUnassignedDiv } from "src/components/molecules/Table/PromoteToDivisionTable";
import { TableBody } from "@mui/material";
import { height } from "src/constant/device";
import styled from "styled-components";
import useTable from "src/components/molecules/Table/UseTable";

const headCells = [
  {
    id: "name",
    label: "name.label",
  },
  {
    id: "gender",
    label: "gender.label",
  },
  {
    id: "division",
    label: "selectDivision.label",
    align: "right",
  },
];

type Props = {
  data: TStudentUnassignedDiv[];
  loading: boolean;
  divisionOptions: DropdownOptions[];
  formData: Record<string, string | null>;
  setFormData: React.Dispatch<
    React.SetStateAction<Record<string, string | null>>
  >;
};

const AssignDivisionTable = (props: Props) => {
  const { data, loading, divisionOptions, formData, setFormData } = props;

  const { TblContainer, TblHead } = useTable(data, headCells);

  const handleChange = (div: DropdownOptions | null, id: string) => {
    setFormData((prev) => ({ ...prev, [id]: div?.value ?? null }));
  };

  const getExistingValue = (id: string) => {
    if (formData?.[id]) {
      return { label: formData[id], value: formData[id] };
    }
  };

  return (
    <TblContainer height={"90%"}>
      <TblHead />
      <>
        {!loading ? (
          <>
            {data?.length > 0 ? (
              <TableBody>
                {data.map((item) => (
                  <TableRows key={item.id}>
                    <TableCells value={item?.fullName ?? "-"} />
                    <TableCells value={item?.gender ?? "-"} />
                    <TableCells align={"right"}>
                      <Wrapper>
                        <SearchDropdown
                          options={divisionOptions}
                          onChange={(
                            _: any,
                            newValue: DropdownOptions | null
                          ) => handleChange(newValue, item.id)}
                          value={getExistingValue(item.id)}
                          width={200}
                        />
                      </Wrapper>
                    </TableCells>
                  </TableRows>
                ))}
              </TableBody>
            ) : (
              <NoRecordsFound colspan={3} maxHeight={0.6 * height} />
            )}
          </>
        ) : (
          <SpinnerWrapper>
            <LoaderSpinner />
          </SpinnerWrapper>
        )}
      </>
    </TblContainer>
  );
};

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default AssignDivisionTable;
