/* eslint-disable react-native/no-unused-styles */
import React, { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import MediumText from 'src/components/atoms/Text/MediumText';
import { getAllowedModules } from 'src/graphql/institutes/module';


export const ModulesList = (props: any) => {
  const {
    allModules,
    institute,
    setAddModuleAccess,
    setRemoveModuleAccess,
    addModuleAccess,
    removeModuleAccess,
    disableSubmit,
   } = props;
  const [modules, setModules] = useState<String[]>([]);

  const {fetchAllowedModules, allowedModules} = getAllowedModules();
  
  useEffect(() => {
    let allMods = allowedModules?.allowedModules?.modules;
    if (allMods) {
      setModules(allMods);
    }
  }, [allowedModules])

  useEffect(() => {
    fetchAllowedModules({ variables: { institute: institute.id } });
  }, [])

  useEffect(() => {
    disableSubmit(allowedModules?.allowedModules?.modules, modules);
  }, [modules]);

  const handleChecked = (item: String) => {
    if (modules.includes(item)) {
      if (addModuleAccess.includes(item)) {
        const index = addModuleAccess.indexOf(item);
        if (index > -1) {
          addModuleAccess.splice(index, 1);
        }
        setAddModuleAccess(addModuleAccess);
      }
      if (removeModuleAccess.includes(item)) {
        const index = removeModuleAccess.indexOf(item);
        if (index > -1) {
          removeModuleAccess.splice(index, 1);
        }
        setRemoveModuleAccess(removeModuleAccess);
      } else {
        removeModuleAccess.push(item)
        setRemoveModuleAccess(removeModuleAccess);
      }
      const index = modules.indexOf(item);
      if (index > -1) {
        const newData = [...modules];
        newData.splice(index, 1);
        setModules(newData);
      }
    } else {
      if (removeModuleAccess.includes(item)) {
        const index = removeModuleAccess.indexOf(item);
        if (index > -1) {
          removeModuleAccess.splice(index, 1);
        }
        setRemoveModuleAccess(removeModuleAccess);
      }
      if (addModuleAccess.includes(item)) {
        const index = addModuleAccess.indexOf(item);
        if (index > -1) {
          addModuleAccess.splice(index, 1);
        }
        setAddModuleAccess(addModuleAccess);
      } else {
        addModuleAccess.push(item)
        setAddModuleAccess(addModuleAccess);
      }
      const newData = [...modules];
      newData.push(item);
      setModules(newData);
    }
  };

  return (
    <SafeAreaView>
      <MediumText value={`Institute: ${institute?.name}`}/>
      <ScrollView >
        <Element
          key={institute?.id}
          field={{
            "id": "dsf",
            "lebel": "SELECT",
            "type": "table",
          }}
          inputWidth={250}
          checked={modules}
          checkboxTableData={allModules}
          handleCheck={handleChecked}
          dropdownWidth={250}
          checkboxTableWidth={725}
          manualSelection={false}
        />
      </ScrollView>
   </SafeAreaView>
  );
};
