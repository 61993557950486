import React from 'react';
import styled from 'styled-components';

interface IProps {
  cb?: () => void;
  text: string;
  style?: any;
  icon?: any;
}

const OutlineButtonWeb = ({ cb, text, style, icon }: IProps) => {
  return (
    <Button onClick={() => cb && cb()} style={style}>
      {text}
      {icon && icon}
    </Button>
  );
};

export default OutlineButtonWeb;

const Button = styled.button`
  border: ${(props) => props.theme.rem(0.1)} solid #2b78ca;
  background-color: #fff;
  color: #2b78ca;
  margin-left: auto;
  border-radius: ${(props) => props.theme.rem(0.4)};
  padding: ${(props) => props.theme.rem([0.7, 1.6])};
  font-size: ${(props) => props.theme.rem(1.4)};
  cursor: pointer;

  @media all and (max-width: 767px) {
    display: none;
  }
`;
