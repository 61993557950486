import { DeleteIcon, EditIcon } from "src/components/atoms/ActionIcons";
import {
  DeleteScheduleProps,
  EditLectureModes,
  EditScheduleProps,
} from "../scheduleTypes";
import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";

import AddEditLectureForm from "../BatchwiseSchedule/AddEditLectureForm";
import DeleteLectureForm from "../BatchwiseSchedule/DeleteLectureForm";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";

export const EditScheduleActions = (props: EditScheduleProps) => {
  const { refetchLectures, details, lectureData, closePopover } = props;
  const { t } = useI18n();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "editLectureMenu",
  });

  const [editLectureModal, setEditLectureModal] = useState<Boolean>(false);
  const [editMode, setEditMode] = useState<EditLectureModes>("EDIT_SINGLE");

  const editLecture = (mode: EditLectureModes) => {
    setEditMode(mode);
    setEditLectureModal(true);
  };

  const closeEditLectureModal = () => {
    setEditLectureModal(false);
  };

  return (
    <>
      <EditTrigger {...bindTrigger(popupState)}>
        <DisableClick>
          <EditIcon />
        </DisableClick>
      </EditTrigger>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            popupState.close();
            editLecture("EDIT_SINGLE");
          }}
        >
          {t("editSingleLecture.text")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            popupState.close();
            editLecture("EDIT_ALL");
          }}
        >
          {t("editAllLectures.text")}
        </MenuItem>
      </Menu>
      {editLectureModal && (
        <AddEditLectureForm
          title={
            editMode === "EDIT_SINGLE"
              ? t("editSingleLecture.text")
              : t("editAllLectures.text")
          }
          closeModal={closeEditLectureModal}
          refetchLectures={refetchLectures}
          closePopover={closePopover}
          mode={editMode}
          details={details}
          lectureData={lectureData}
        />
      )}
    </>
  );
};

export const DeleteScheduleAction = (props: DeleteScheduleProps) => {
  const { refetchLectures, schedule, closePopover } = props;
  const [deleteLectureModal, setDeleteLectureModal] = useState<Boolean>(false);

  const deleteLecture = () => {
    setDeleteLectureModal(true);
  };

  const closeDeleteLectureModal = () => {
    setDeleteLectureModal(false);
  };

  return (
    <>
      <DeleteIcon onPress={deleteLecture} />
      {deleteLectureModal && (
        <DeleteLectureForm
          closeModal={closeDeleteLectureModal}
          refetchLectures={refetchLectures}
          schedule={schedule}
          closePopover={closePopover}
        />
      )}
    </>
  );
};

const EditTrigger = styled.div`
  cursor: pointer;
`;

const DisableClick = styled.div`
  pointer-events: none;
`;
