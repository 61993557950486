import { graphqlQuery } from 'src/graphql/util';
import { getCourseAliasQuery, getInstituteCoursesQuery, searchCourseQuery } from 'src/graphql/academics/courses';
import { searchClassQuery } from 'src/graphql/academics/classes';
import { getAllBoadUniversitiesQuery } from 'src/graphql/academics/board-universities';
import { getAllStreamQuery } from 'src/graphql/academics/streams';
import { getAllInstituteQuery, getInstituteGroupQuery } from 'src/graphql/institutes';
import { searchSubjectQuery } from 'src/graphql/academics/subjects';
import { FILTERS } from 'src/constant';
import { gql } from '@apollo/client';
import { getFeeDetailsQuery, getFilteredFeeCompositionsQuery } from 'src/graphql/payment';
import { studentGroupsQuery } from 'src/graphql/student-group';
import { getModifiedSubjectGroupsQuery, getSubjectGroupById } from 'src/graphql/academics/subject-groups';
import { getMessageByIdQuery } from 'src/graphql/messages';
import { userInformation } from 'src/utils/manageState';
import { getFilteredCriteriasQuery } from 'src/graphql/admission/admission-criteria';
import { getAllReservationsQuery } from 'src/graphql/admission/reservation-group';
import { getOptionsQualificationsQuery } from 'src/graphql/admission/qualification';
import { getAcademicYearQuery } from 'src/graphql/academics/years';
import { getOpenAdmissionsToPromoteQuery } from 'src/graphql/admission';
import { getMerchantListOptionsQuery } from "src/graphql/payment/Merchant/index"
import { DropdownOptionsType } from 'src/types';
import { getSubjectTeachers } from 'src/graphql/courses';
import { getGradesQuery } from 'src/graphql/assessment/assessment-grades';
import { getDocumentsOptionsQuery } from 'src/graphql/admission/document-type';

export function createDeleteData(queryData: any, deleteId: string) {
  let existingData = queryData?.data;
  let updatedList = existingData?.filter((t: any) => t.id !== deleteId);
  let existingCount = queryData?.totalCount;
  let returnObject = {
    data: updatedList,
    totalCount: existingCount - 1,
    __typename: queryData?.__typename,
    hasNextPage: queryData?.hasNextPage,
  };
  return returnObject;
}

export function checkIsOnBoardinDone(status: any) {
  let statusArray = [] as any;
  for (const property in status) {
    let stepStatus = status[property];
    if (!stepStatus) {
      statusArray.push(property);
    }
  }
  return statusArray.length > 0 ? false : true;
}

export async function getFilteredCourses(param: any) {
  let { data: courseModifiedData } = await graphqlQuery(searchCourseQuery, param, false);
  if (courseModifiedData.courses) {
    let courses = courseModifiedData.courses;
    return { options: courses.data, hasNextPage: courses.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getFilteredClasses(param: any) {
  let { data: classModifiedData } = await graphqlQuery(searchClassQuery, param, false);
  if (classModifiedData.classes) {
    let classes = classModifiedData.classes;
    return { options: classes.data, hasNextPage: classes.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getFilteredUniversities(param: any) {
  let { data: boardUniversiesData } = await graphqlQuery(getAllBoadUniversitiesQuery, param, false);
  if (boardUniversiesData.universities) {
    let universities = boardUniversiesData.universities;
    return { options: universities.data, hasNextPage: universities.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getFilteredStreams(param: any) {
  let { data: searchStreamData } = await graphqlQuery(getAllStreamQuery, param, false);
  if (searchStreamData.streams) {
    let streams = searchStreamData.streams;
    return { options: streams.data, hasNextPage: streams.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getFilteredInstitute(param: any) {
  let { data: instititeData } = await graphqlQuery(getInstituteGroupQuery, param, false);
  let options: DropdownOptionsType[] = []
  if (instititeData.institutes) {
    options = instititeData.institutes.data;
  }
  return { options };
}

export async function getFilteredAllInstitute(param: any) {
  let { data: instititeData } = await graphqlQuery(getAllInstituteQuery, param, false);
  let options: DropdownOptionsType[] = []
  if (instititeData.institutes) {
    let institutes = instititeData.institutes.data;
    institutes.forEach((institute: any) => {
      if (institute?.group?.length > 0) {
        institute.group.forEach((ins: any) => {
          options.push({
            label: ins.label,
            value: ins.value,
          });
        })
      } else {
        options.push({
          label: institute.label,
          value: institute.value,
        });
      }
    })
  }
  return { options };
}

export async function getFilteredCourseAlias(param: any) {
  let { data: searchCourseCodeData } = await graphqlQuery(getCourseAliasQuery, param, false);
  if (searchCourseCodeData) {
    let courses = searchCourseCodeData.courses;
    return { options: courses.data };
  } else {
    return { options: [] };
  }
}

export async function getFilteredInstituteCourses(param: any) {
  let { data: searchCourseCodeData } = await graphqlQuery(getInstituteCoursesQuery, param, false);
  if (searchCourseCodeData) {
    let courses = searchCourseCodeData.courses;
    return { options: courses.data };
  } else {
    return { options: [] };
  }
}

export async function getFilteredSubjects(param: any, query = searchSubjectQuery) {
  let { data: subjectData } = await graphqlQuery(query, param, false);
  if (subjectData) {
    let subjects = subjectData.subjects;
    return { options: subjects.data };
  } else {
    return { options: [] };
  }
}

export async function getFilteredReservationGroups(param: any) {
  let { data: reservationGroupData } = await graphqlQuery(getAllReservationsQuery, param, false);
  if (reservationGroupData.reservations) {
    let reservations = reservationGroupData.reservations;
    return { options: reservations.data, hasNextPage: reservations.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getFilteredGradingSystems(param: any) {
  let { data: gradingSystemsData } = await graphqlQuery(getGradesQuery, param, false);
  if (gradingSystemsData?.grades) {
    let gradingSystems = gradingSystemsData.grades;
    return { options: gradingSystems.data, hasNextPage: gradingSystems.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getMessageType(param: any) {
  const currentUserObject = userInformation();
  let { data: messageData } = await graphqlQuery(getMessageByIdQuery, param, false);
  if (messageData) {
    let msgUserName = messageData?.getMessage?.sender?.userName;
    if (msgUserName && msgUserName === currentUserObject?.userName) {
      return 'sent';
    } else {
      return 'inbox';
    }
  } else {
    return 'inbox';
  }
}

export async function getSubjectTeacherOptions(param: any, query = getSubjectTeachers) {
  const { data: teachers } = await graphqlQuery(query, param, false);
  if (teachers) {
    let resp = teachers.instituteEmployees.data;
    return { options: resp };
  } else {
    return { options: [] };
  }
}

export async function getFilteredSubjectsBySubjectGroup(param: any, query = getSubjectGroupById) {
  let { data: subjectData } = await graphqlQuery(query, param, false);
  if (subjectData) {
    let subjects = subjectData.subjectGroup.subjects;
    return { options: subjects };
  } else {
    return { options: [] };
  }
}

export async function getFilteredSubjectGroups(param: any, query = getModifiedSubjectGroupsQuery) {
  param['limit'] = 0;
  let { data: subjectGroupData } = await graphqlQuery(query, param, false);
  if (subjectGroupData) {
    let subjectGroups = subjectGroupData?.subjectGroups;
    return { options: subjectGroups.data };
  } else {
    return { options: [] };
  }
}

export async function getFilteredModules(param: any, query = getFeeDetailsQuery) {
  let { data: moduleData } = await graphqlQuery(query, param, false);
  if (moduleData) {
    let modules = moduleData.getFeeDetails;
    return { options: modules.data };
  } else {
    return { options: [] };
  }
}

export async function getFilteredStudentGroups(param: any) {
  let { data } = await graphqlQuery(studentGroupsQuery, param, false);
  let modules = data.studentGroups;
  let options: any[] = [];
  if (modules?.data) {
    options = modules?.data?.map((dt: any) => {
      return {
        value: dt.id,
        label: dt.name,
      }
    })
    return { options, data: modules?.data };
  } else {
    return { options, data: modules?.data };
  }
}

export async function getFilteredAdmissionCriterias(param: any) {
  let { data: criteriasData } = await graphqlQuery(getFilteredCriteriasQuery, param, false);
  if (criteriasData.admissionCriterias) {
    let admissionCriterias = criteriasData.admissionCriterias;
    return { options: admissionCriterias.data, hasNextPage: admissionCriterias.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getFilteredFeeCompositions(param: any) {
  let { data: feeCompositionData } = await graphqlQuery(getFilteredFeeCompositionsQuery, param, false);
  if (feeCompositionData.getFeeCompositions) {
    let feeComposition = feeCompositionData.getFeeCompositions;
    return { options: feeComposition.data, hasNextPage: feeComposition.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getFilteredQualificationNames(param: any) {
  let { data: qualificationNamesData } = await graphqlQuery(getOptionsQualificationsQuery, param, false);
  if (qualificationNamesData.qualificationNames) {
    let qualificationNames = qualificationNamesData.qualificationNames;
    return { options: qualificationNames.data, hasNextPage: qualificationNames.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getFilteredAcademicYears(param: any) {
  let { data: academicYearsData } = await graphqlQuery(getAcademicYearQuery, param, false);
  if (academicYearsData.AcademicYears) {
    let academicYears = academicYearsData.AcademicYears;
    return { options: academicYears.data, hasNextPage: academicYears.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getFilteredDocumentTypes(param: any) {
  let { data: documentTypesData } = await graphqlQuery(getDocumentsOptionsQuery, param, false);
  if (documentTypesData?.documentTypes) {
    let documentTypes = documentTypesData.documentTypes;
    return { options: documentTypes.data, hasNextPage: documentTypes.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}

export async function getOpenAdmissionsToPromote(currentBatch: string) {
  const respData = await graphqlQuery(
    getOpenAdmissionsToPromoteQuery,
    { currentBatch },
    false
  );
  if (respData?.data && respData.data?.openAdmissionsToPromote) {
    return respData.data.openAdmissionsToPromote;
  }
  return [];
}

export async function getMerchantOptions() {
  const respData = await graphqlQuery(
    getMerchantListOptionsQuery,
    {},
    false
  );
  if (respData?.data?.NTTMerchants) {
    return respData.data.NTTMerchants;
  }
}

export function createListFilter(
  limit: number,
  skip: number,
  filterOn: string,
  filterVal: string,
  filterType = 'eq',
) {
  let param = {
    limit: limit,
    skip: skip,
  } as any;

  let filters = {} as any;
  if (filterVal) {
    filters[filterOn] = {
      [filterType]: filterVal,
    };
    param['filters'] = filters;
  }

  return param;
}

export function debounce(func: any, timeout = 500) {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this as any, args);
    }, timeout);
  };
}

export function createSearchFilter(limit: number, skip: number, filterObject: any, sort?: any) {
  let param = {
    limit: limit,
    skip: skip,
  } as any;

  if (filterObject && filterObject.length > 0) {
    let filters = {
      or: [],
    } as any;

    let filterArray = [] as any;
    filterObject.forEach(function (item: any) {
      let temporaryObject = {} as any;
      temporaryObject[item?.field] = {
        [item.type]: item.value,
      };
      filterArray.push(temporaryObject);
    });

    filters[FILTERS.OR] = filterArray;
    param['filters'] = filters;
  }
  if (sort) {
    param['sort'] = sort;
  }

  return param;
}

export function createSortFilter(headCells: Array<any>) {
  let param = {} as any;
  headCells.map((headCell) => {
    if (headCell?.sort) {
      if (headCell.ascend === true) {
        param[headCell.sortParam] = 'ASCENDING';
      } else if (headCell.ascend === false) {
        param[headCell.sortParam] = 'DESCENDING';
      }
    }
  });
  return param;
}

export async function getClassesDivisions(classId: string) {
  if (classId) {
    const getClassDivision = gql`
      query{
        class(id: "${classId}") {
          divisions
      }
    }`;

    let { loading, data: allDivisions } = await graphqlQuery(getClassDivision, {}, false);
    if (!loading && allDivisions?.class) {
      let classData = allDivisions?.class;
      let divisions = classData?.divisions;

      let options = [] as any;
      divisions.forEach(function (item: any, index: number) {
        options.push({
          value: item,
          label: item,
        });
      });
      return options;
    }
  }
}

async function getDivision(classId: string) {
  if (classId) {
    const getClassDivision = gql`
      query{
        class(id: "${classId}") {
          divisions
      }
    }
    `;

    let { loading, data: allDivisions } = await graphqlQuery(getClassDivision, {});
    if (!loading && allDivisions?.class) {
      let classData = allDivisions?.class;
      let divisions = classData?.divisions;
      // loadDivisions(divisions)
      // loadDivisions(divisions)
    }
  }
}

export function generateArrayOfYears() {
  let nextYears = [] as any;
  nextYears = generateYears();
  nextYears.sort(); // First sort the elements of fruits
  nextYears.reverse();
  let response = generateOptions(nextYears);
  return response;
}

function generateOptions(years: any) {
  let options = [] as any;
  years.forEach(function (item: any) {
    let opt = {
      label: item?.toString(),
      value: item?.toString(),
    };
    options.push(opt);
  });
  return options;
}

function generateYears() {
  //1= next 2= previos
  var max = new Date().getFullYear();
  let options = [] as any;
  for (var i = 1; i < 11; i++) {
    let nextY = max + i;
    let prevY = max - i;
    options.push(nextY);
    options.push(prevY);
  }
  options.push(max);
  return options;
}

export function getExtention(docu: string) {
  let extension;
  try {
    extension = docu.split('.').pop();
    return extension;
  } catch (e) {
    return '';
  }
}

export function getFileName(docu: string) {
  try {
    let fileName = docu.split('.').shift();
    return fileName;
  } catch (e) {
    console.log(e.message);
  }
}

export function filterDoc(docs: any[]) {
  return docs.filter((doc) => doc.document);
}

export function once(fn: any, context: any) {
  var result: any;

  return function () {
    if (fn) {
      result = fn.apply(context || this, arguments);
      fn = null;
    }

    return result;
  };
}
