import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

import { colors, fonts } from 'src/styles/theme/styles';

export default function TableColHead(props) {
  const { value, color = colors.secondaryText } = props;

  const TextWrapper = styled.Text`
    font-size: ${(props) => props.theme.rem(1.2)};
    line-height: ${(props) => props.theme.rem(1.6)};
    font-family: ${fonts.bold};
    text-transform: uppercase;
    font-weight: bold;
  `;

  return (
    <TextWrapper style={{ color }} allowFontScaling={false}>
      {value}
    </TextWrapper>
  );
}

TableColHead.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
};
