import AssignRollNoTable from "../../Table/AssignRollNoTable";
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import React from "react";
import { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";

export default function AssignRollNoTbl(props: UseFieldApiConfig) {
  const {
    subscribeToValues,
    headCells,
    tableData,
    maxHeight,
    height,
    setData,
    checkError,
    isDataAvailable,
  } = props;

  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <AssignRollNoTable
          header={headCells}
          tabledata={tableData}
          maxHeightTable={maxHeight}
          height={height}
          setData={setData}
          checkError={checkError}
          isDataAvailable={isDataAvailable}
        />
      )}
    </FormSpy>
  );
}
