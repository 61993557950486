import React, { useEffect, useState } from 'react';

import styled from 'styled-components/native';
import { barColors, colors, fonts } from 'src/styles/theme/styles';
import { isIOS, width } from 'src/constant/device';
import { format, isWithinInterval, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';

export type UpcomingClassesNativeProps = {
  selectedClass: boolean;
  item: {
    id: string;
    start: string;
    end: string;
    division: string;
    class: {
      name: string;
      code: string;
      alias: String;
      divisions: any;
    };
    subject: {
      name: string;
      color: string;
    };
    blankItem?: boolean;
  };
  firstItem: boolean;
  lastItem: boolean;
};

export const UpcomingClassesNative: React.FC<UpcomingClassesNativeProps> = ({ item, lastItem }) => {
  const [headingColor, setHeadingColor] = useState('');
  const [isOngoingClass, setIsOngoingClass] = useState(false);
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    getStatusOfTimeAndDot();
    const interval = setInterval(() => setTime(Date.now()), 30000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  useEffect(() => {
    setHeadingColor(getRandomColor());
  }, []);

  const currentDate = new Date();
  const classStart = parse(item.start, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, currentDate);
  const classEnd = parse(item.end, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, currentDate);

  const getStatusOfTimeAndDot = () => {
    setIsOngoingClass(isWithinInterval(currentDate, { start: classStart, end: classEnd }));
  };

  const getRandomColor = () => {
    return barColors[Math.floor(Math.random() * barColors.length)];
  };

  const getTruncatedText = (text: string) => {
    if (!text || text.length === 0) return '';
    let characters;
    if (width <= 375) {
      characters = 15;
    } else if (width >= 376 && width <= 400) {
      characters = 17;
    } else if (width >= 401) {
      characters = 18;
    }
    return text.length > characters ? `${text.substring(0, characters)}...` : text;
  };

  return (
    <Container lastItem={lastItem}>
      <UpcomingClassesContainer style={isOngoingClass && { ...currentClassStyle }}>
        <UpcomingClassesHeader
          style={{ color: item.subject?.color || headingColor || barColors[6] }}>
          Class {item?.class && getTruncatedText(
            `${item.class?.alias || item.class?.name} (${item.division})`)}
        </UpcomingClassesHeader>
        <UpcomingClassesName>{item?.subject?.name}</UpcomingClassesName>
        <UpcomingClassesTime>
          {`${format(classStart, 'p')} - ${format(classEnd, 'p')}`}
        </UpcomingClassesTime>
      </UpcomingClassesContainer>
    </Container>
  );
};

const currentClassStyle = {
  shadowColor: '#2b78ca',
  shadowOffset: {
    width: 0,
    height: isIOS ? 2 : 11,
  },
  shadowOpacity: 0.33,
  shadowRadius: 10,
  elevation: 10,
  backgroundColor: '#ffffff',
  borderRadius: 8,
};

const Container = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: ${(props) => (props.lastItem ? '30px' : '0px')};
  border-radius: 8px;
`;

const UpcomingClassesContainer = styled.View`
  flex-direction: column;
  display: flex;
  padding: 12px;
  border-radius: 8px;
  width: ${isIOS ? 100 : 96}%;
  position: relative;
  margin-bottom: 0.5%;
  height: 88px;
  margin-left: ${isIOS ? 0 : 0}%;
  margin-right: ${isIOS ? 0 : 0}%;
`;

const UpcomingClassesHeader = styled.Text`
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  font-family: ${fonts.semibold};
`;
const UpcomingClassesName = styled.Text`
  font-size: ${(props) => props.theme.rem(1.2)};
  font-weight: 400;
  font-family: ${fonts.semibold};
  color: ${colors.secondaryText};
  line-height: 16px;
  margin-top: ${isIOS ? 3 : 3}px;
`;

const UpcomingClassesTime = styled.Text`
  font-size: ${(props) => props.theme.rem(1.2)};
  font-weight: 600;
  color: ${colors.primaryText};
  margin-top: ${isIOS ? 4 : 0}px;
  font-family: ${fonts.semibold};
`;
