import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  const size = props.size || 16;

  return (
    <Svg
      width={size * scale}
      height={size * scale}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M14.9733 15.0278L10.5015 18.7496L6.02959 15.0278C5.16709 15.0653 0.00146484 15.7543 0.00146484 21.7495C0.00146484 22.9917 1.00928 23.9995 2.25146 23.9995H18.7515C19.9937 23.9995 21.0015 22.9917 21.0015 21.7495C21.0015 15.7543 15.8405 15.0653 14.9733 15.0278ZM0.620215 4.68739L0.938965 4.78114V6.5436C0.61084 6.74047 0.376465 7.08265 0.376465 7.49514C0.376465 7.88888 0.59209 8.217 0.896777 8.41856L0.165527 11.3435C0.0858398 11.6669 0.263965 11.9997 0.521777 11.9997H2.48115C2.73896 11.9997 2.91709 11.6669 2.8374 11.3435L2.10615 8.42324C2.41084 8.22169 2.62646 7.89357 2.62646 7.49983C2.62646 7.08734 2.39209 6.74516 2.06396 6.54829V5.11863L4.25303 5.76549C3.93896 6.53423 3.75146 7.36858 3.75146 8.24981C3.75146 11.9763 6.7749 14.9997 10.5015 14.9997C14.228 14.9997 17.2515 11.9763 17.2515 8.24981C17.2515 7.36858 17.064 6.53423 16.7499 5.76549L20.3827 4.68739C21.2077 4.44365 21.2077 3.05618 20.3827 2.81244L11.2937 0.117185C11.0312 0.0374991 10.7687 0 10.5015 0C10.2343 0 9.97178 0.0374991 9.70928 0.117185L0.620215 2.81244C-0.204785 3.05618 -0.204785 4.44365 0.620215 4.68739Z"
        fill="#2B78CA"
      />
    </Svg>
  );
}

export default SvgComponent;
