import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import formJSON from '../../../../../form-json/control-panels/create-payment-module-individual.json';
import groupFormJSON from '../../../../../form-json/control-panels/create-payment-module-group.json';
import Element from '../../../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components/native';
import { moduleOptions } from 'src/constant';
import { useQuery } from '@apollo/client';
import { getFeeDetailGroupQuery } from 'src/graphql/payment';

export default function CreatePaymentModuleForm(props: any) {
  const { reset, errors, isEditModal, editCreateModuleData, setValue } = props;
  const [elements, setElements] = useState({});
  const [searchData] = useState('');
  const [formType, setFormType] = useState(1);
  const { fields }: any = elements ?? {};
  const [feeDetailType, setFeeDetailType] = useState(null);

  let groupFilter = {
    isGroup: {
      eq: true,
    },
  } as any;

  const { data: allFeeDetailGroups } = useQuery(getFeeDetailGroupQuery, {
    variables: { limit: 0, skip: 0, filter: groupFilter },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setValue('fee_detail_type', null)
  }, [feeDetailType]);

  useEffect(() => {
    if (elements === formJSON[0]) {
      let getFeeDetailGroups = allFeeDetailGroups;
      loadFeeDetailGroups(getFeeDetailGroups?.getFeeDetails?.data);
    }
  }, [allFeeDetailGroups]);

  useEffect(() => {
    if (!isEditModal) {
      setElements(formJSON[0]);
    }

    if (isEditModal && editCreateModuleData?.type === 'GROUP') {
      groupFormJSON[0].fields[0].disabled = true;
      setElements(groupFormJSON[0]);
      LoadEditForm(editCreateModuleData);
    }

    if (isEditModal && editCreateModuleData?.type === 'INDIVIDUAL') {
      formJSON[0].fields[0].disabled = true;
      setElements(formJSON[0]);
      LoadEditForm(editCreateModuleData);
    } else {
      formJSON[0].fields[0].disabled = false;
    }
    return () => {
      formJSON[0].fields[4].fieldVisible = false;
    }
  }, []);

  function loadFeeDetailGroups(feeDetailGroups: any) {
    const formToUpdate = { ...formJSON[0] };
    formToUpdate.fields[3].option = feeDetailGroups;
    setElements(formToUpdate);
  }

  function LoadEditForm(moduleData: any) {
    let moduleType = moduleData?.type;
    let formaData = {} as any;

    //Set
    formaData['module_type'] = moduleOptions[moduleType];
    if (moduleType === 'GROUP') {
      formaData['group_name'] = moduleData?.name;
      formaData['description'] = moduleData?.description;
    } else {
      formaData['module_name'] = moduleData?.name;
      formaData['group_name'] = moduleData?.group_name;
      formaData['amount'] = moduleData?.amount;
      formaData['description'] = moduleData?.description;
      formaData['order_number'] = moduleData?.orderNo;
    }
    reset(formaData);
  }

  const handleCustomSelect = async (id: string, data: any) => {
    if (id === 'module_type') {
      if ((data.value === 'GROUP') && formType === 1) {
        resetGroupForm();
        setElements(groupFormJSON[0]);
        setFormType(2);
      } else if ((data.value === 'INDIVIDUAL' && formType === 2)) {
        resetIndividualForm();
        setElements(formJSON[0]);
        setFormType(1);
      }
    }
    if (id === 'group_name') {
      if (data && data?.value) {
        handleOrderNumberField(true);
      } else {
        handleOrderNumberField(false);
      }
    }
  }

  function resetIndividualForm() {
    reset({
      module_name: '',
      group_name: '',
      order_number: '',
      amount: '',
      description: '',
    });
    setFeeDetailType(null);
    setValue('module_type', { label: 'Individual', value: 'INDIVIDUAL' });
    formJSON[0].fields[4].fieldVisible = false;
  }

  function resetGroupForm() {
    reset({
      module_name: '',
      group_name: '',
      description: '',
    });
    setFeeDetailType(null);
    setValue('module_type', { label: 'Group', value: 'GROUP' });
  }

  const handleOrderNumberField = (fieldVisible: boolean) => {
    formJSON[0].fields[4].fieldVisible = fieldVisible;
    const newData = { ...formJSON[0] };
    setElements(newData);
  }

  return (
    <>
      <View>
        {fields ? (
          fields.map((field: any, i: number) => (
            <DataView id={field?.id} key={i}>
              <Element
                key={`createModule${i}`}
                field={field}
                control={props.control}
                errors={errors}
                searchData={searchData}
                handleCustomSelect={handleCustomSelect}
              />
            </DataView>
          ))
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )}
      </View>
    </>
  );
}

const SpinnerView = styled.View`
  width: 750;
  height: 547;
  justify-content: center;
  align-items: center;
`;

const DataView = styled.View<{ id: any }>`
  /* max-width: 480px; */
  margin-bottom: ${({id}) => id === 'module_type' ? 24 : 0};
`;


