import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={16 * scale}
      height={16 * scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M13.498 2h-1.5V.375A.376.376 0 0011.625 0h-.25a.376.376 0 00-.375.375V2h-6V.375A.376.376 0 004.626 0h-.25A.376.376 0 004 .375V2H2.5A1.5 1.5 0 001 3.5v11A1.5 1.5 0 002.5 16h10.998a1.5 1.5 0 001.5-1.5v-11a1.5 1.5 0 00-1.5-1.5zM2.5 3h10.998c.275 0 .5.225.5.5V5H2V3.5c0-.275.225-.5.5-.5zm10.998 12H2.5a.501.501 0 01-.5-.5V6h11.998v8.5c0 .275-.225.5-.5.5zm-7.874-5h-1.25A.376.376 0 014 9.625v-1.25C4 8.169 4.168 8 4.375 8h1.25c.206 0 .374.169.374.375v1.25a.376.376 0 01-.375.375zm3 0h-1.25A.376.376 0 017 9.625v-1.25C7 8.169 7.168 8 7.374 8h1.25C8.83 8 9 8.169 9 8.375v1.25a.376.376 0 01-.375.375zm3 0h-1.25a.376.376 0 01-.375-.375v-1.25c0-.206.169-.375.375-.375h1.25c.206 0 .375.169.375.375v1.25a.376.376 0 01-.375.375zm-3 3h-1.25A.376.376 0 017 12.625v-1.25c0-.206.169-.375.375-.375h1.25c.206 0 .375.169.375.375v1.25a.376.376 0 01-.375.375zm-3 0h-1.25A.376.376 0 014 12.625v-1.25c0-.206.168-.375.375-.375h1.25c.206 0 .374.169.374.375v1.25a.376.376 0 01-.375.375zm6 0h-1.25a.376.376 0 01-.375-.375v-1.25c0-.206.169-.375.375-.375h1.25c.206 0 .375.169.375.375v1.25a.376.376 0 01-.375.375z"
        fill="#000"
        fillOpacity={0.6}
      />
    </Svg>
  );
}

export default SvgComponent;
