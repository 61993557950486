import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { graphqlQuery } from '../util';

const addAcademicYearMutation = gql`
  mutation ($payload: AcademicYearInput!) {
    createAcademicYear(payload: $payload) {
      id
      name
      alias
      from
      to
      status
    }
  }
`;

const updateAcademicYearMutation = gql`
  mutation ($payload: UpdateAcademicYearInput!) {
    updateAcademicYear(payload: $payload) {
      id
      name
      alias
      from
      to
      status
    }
  }
`;

const getAcademicYearQuery = gql`
  query ($filters: AcademicYearFilterInput, $limit: Int, $skip: Int, $sort: AcademicYearSortInput) {
    AcademicYears(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
        alias
        from
        to
        status
      }
    }
  }
`;

const getAcademicYearByIdQuery = gql`
  query ($id: String) {
    AcademicYear(id: $id) {
      id
      name
      alias
      from
      to
      status
    }
  }
`;
export function useMutateAcademicYear(queries: any) {
  const [mutate, { error }] = useMutation(queries);
  return { mutate, error };
}

export function useQueryAcademicYear() {
  const [query, { called, error, loading, data, refetch }] = useLazyQuery(getAcademicYearQuery, {
    fetchPolicy: 'network-only',
  });
  return { query, data, error, refetch, loading };
}

export function useAddAcademicYear(page: number, searchValue: string, rowLimit: number) {
  const ErrorOccur = false;

  const [addAcademicYear, { error: addAcademicYearError, loading, data: addAcademicYearData }] =
    useMutation(addAcademicYearMutation, {
      update: (cache, { data: { createAcademicYear } }) => {
        // toDO
      },
    });
  return { addAcademicYear, addAcademicYearData, addAcademicYearError, ErrorOccur };
}

export function useUpdateAcademicYear() {
  const [updateAcademicYear, { error: updateAcademicYearError, data: updateAcademicYearData }] =
    useMutation(updateAcademicYearMutation);
  return {
    updateAcademicYear: updateAcademicYear,
    updateAcademicYearData,
    updateAcademicYearError,
  };
}

export async function getFilteredAcademicYear(param: any) {
  const { data: filteredAcademicYearData } = await graphqlQuery(getAcademicYearQuery, param, false);
  if (filteredAcademicYearData.batches) {
    const batches = filteredAcademicYearData.batches;
    return { options: batches.data, hasNextPage: batches.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}
export { getAcademicYearQuery, getAcademicYearByIdQuery, addAcademicYearMutation };
