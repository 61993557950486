import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import AddTestFormJSON from 'src/form-json/assessment/assessment-test-add-test-form.json';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { getFilteredSubjects } from 'src/components/services';
import { getModifiedSubjectsNamesQuery } from 'src/graphql/academics/subjects';
import { TestTypeLabel,TestMarksTypeLabel } from 'src/constant';
import { height } from 'src/constant/device';

interface AddEditTestFormProps {
  selectedClassId: string;
  control: any;
  errors: any;
  setValue: any;
  clearErrors: any;
  reset: any;
  isEditModal: boolean;
  currentItem: any;
  evaluation: boolean;
  setEvaluation: any;
  marksType: string;
}

export default function AddEditTestForm(props: AddEditTestFormProps) {
  const {
    selectedClassId,
    control,
    errors,
    setValue,
    clearErrors,
    reset,
    isEditModal,
    currentItem,
    evaluation,
    setEvaluation,
    marksType
  } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    reset();
    if (isEditModal) {
      loadEditData();
    } else {
      if(marksType ==='MARKS'){
        AddTestFormJSON[0].fields[3].fieldVisible = true;
        AddTestFormJSON[0].fields[4].fieldVisible = true;
        setElements({...AddTestFormJSON[0]});
      }else{
        AddTestFormJSON[0].fields[5].fieldVisible = true;
        AddTestFormJSON[0].fields[6].fieldVisible = true;
        setElements({...AddTestFormJSON[0]});
      }
    }
    return () => {
      AddTestFormJSON[0].fields[1].disabled = false;
      AddTestFormJSON[0].fields[3].fieldVisible = false;
      AddTestFormJSON[0].fields[4].fieldVisible = false;
      AddTestFormJSON[0].fields[5].fieldVisible = false;
      AddTestFormJSON[0].fields[6].fieldVisible = false;
    };
  }, []);

  useEffect(() => {
    if (!isEditModal) {
      resetSelect();
      getSubjects(selectedClassId);
    }
  }, [selectedClassId]);

  async function loadEditData() {
    if (isEditModal) {
      setElements({});
      updateFormField();
    }
  }
  function updateFormField() {
    AddTestFormJSON[0].fields[1].disabled = true;
    setElements(AddTestFormJSON[0]);
    setValue('testName', currentItem?.name);
    setValue('selectSubject', {
      label: currentItem?.subject?.name,
      value: currentItem?.subject?.id,
    });
    setValue('selectType', { label: TestTypeLabel[currentItem?.type], value: currentItem?.type });
    setValue('minMarks', currentItem?.passingMarks);
    setValue('maxMarks', currentItem?.totalMarks);
    if(marksType==='MARKS'){
      if(currentItem?.markingType==='MARK'){
        AddTestFormJSON[0].fields[3].fieldVisible = true;
        AddTestFormJSON[0].fields[4].fieldVisible = true;
        AddTestFormJSON[0].fields[5].fieldVisible = true;
        AddTestFormJSON[0].fields[6].fieldVisible = true;
      }
      else {
        AddTestFormJSON[0].fields[3].fieldVisible = true;
        AddTestFormJSON[0].fields[4].fieldVisible = true;
      }
    }
    else{
      AddTestFormJSON[0].fields[5].fieldVisible = true;
      AddTestFormJSON[0].fields[6].fieldVisible = true;
    }
    setValue('selectMarksType', {label: TestMarksTypeLabel[currentItem?.markingType], value: currentItem?.markingType});
  }

  function resetSelect(): void {
    clearErrors('selectSubject');
  }

  function loadSubjects(subjectArray: any) {
    const modifiedData = { ...AddTestFormJSON[0] };
    modifiedData.fields[1].option = subjectArray;
    setElements(modifiedData);
  }

  async function getSubjects(classId: string) {
    let subjectFilter = {
      filters: {
        class: {
          eq: classId,
        },
      },
      limit: 0,
    };
    let subjectsResponse = await getFilteredSubjects(subjectFilter, getModifiedSubjectsNamesQuery);
    loadSubjects(subjectsResponse?.options);
  }

  function handleCustomSelect(id: string, value: any) {
    if (id === 'selectMarksType') {
      const newData = { ...AddTestFormJSON[0] };
      if (value && value.value === 'MARK') {
        newData.fields[5].fieldVisible = true;
        newData.fields[6].fieldVisible = true;
      } else {
        newData.fields[5].fieldVisible = false;
        newData.fields[6].fieldVisible = false;
        setValue('maxMarks', null);
        setValue('minMarks', null);
      }
      setElements(newData);
    }
  }

  const handleEvaluation = () => {
    if (evaluation) {
      setEvaluation(false);
    } else {
      setEvaluation(true);
    }
  };

  return (
    <View style={styles.parentContainer}>
      {fields ? (
        fields.map((field: any, i: number) => (
          <View
            style={[
              styles.inputFieldContainer,
              (field?.id === 'minMarks' ||
                field?.id === 'maxMarks' ||
                field?.id === 'selectMarksType' ||
                field?.id === 'selectEvaluation') &&
                styles.marksFieldContainer,
              field?.id === 'selectEvaluation' && styles.toggleView,
            ]}
            key={`s${i}`}>
            <Element
              key={`selection${i}`}
              field={field}
              control={control}
              errors={errors}
              handleCustomSelect={handleCustomSelect}
              handleSwitch={handleEvaluation}
              switchState={evaluation}
            />
          </View>
        ))
      ) : (
        <View style={styles.spinnerView}>
          <LoaderSpinner />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  parentContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingBottom: 25,
  },
  inputFieldContainer: { width: '100%' },
  marksFieldContainer: { width: '47.5%' },
  spinnerView: { width: '100%', height: 250, justifyContent: 'center', alignItems: 'center' },
  toggleView: {
    marginTop: 24,
    width: '40%',
  },
});
