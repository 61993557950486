import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { graphqlQuery } from '../util';

const addBatchMutation = gql`
  mutation ($payload: BatchInput!) {
    createBatch(payload: $payload) {
      id
      name
      alias
      startDate
      endDate
    }
  }
`;

const updateBatchMutation = gql`
  mutation ($payload: UpdateBatchInput!) {
    updateBatch(payload: $payload) {
      id
      name
      alias
      startDate
      endDate
    }
  }
`;

const deleteBatchMutation = gql`
  mutation ($id: String!) {
    deleteBatch(id: $id)
  }
`;

const getBatchQuery = gql`
  query ($filters: BatchFilterInput, $limit: Int, $skip: Int, $sort: BatchSortInput, $lookup: LookupFilter) {
    batches(filters: $filters, limit: $limit, skip: $skip, sort: $sort, lookup: $lookup) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
        gradingType
        alias
        divisionDetails {
          division
          seats
        }
        class {
          id
          name
          alias
          course {
            id
            alias
            name
            university {
              id
            }
          }
        }
        startDate
        endDate
        grade {
          id
          name
          grades {
            gradePoint
            max
            min
            name
            remarks
          }
        }
        settings {
          gradingType
          id
          grade {
            id
            name
            grades {
              gradePoint
              max
              min
              name
              remarks
            }
          }
          divisionDetails {
            division
            seats
          }
        }
      }
    }
  }
`;

const getBatchByIdQuery = gql`
  query ($id: String) {
    batch(id: $id) {
      id
      name
      alias
      startDate
      endDate
      gradingType
      grade {
        id
        name
        grades {
          gradePoint
          max
          min
          name
          remarks
        }
      }
      academicYear{
        id
        name
        alias
      }
      class {
        id
        name
        alias
        course {
          id
          name
          alias
        }
      }
      divisionDetails {
        division
        seats
      }
    }
  }
`;

const searchBatchQuery = gql`
  query ($limit: Int, $skip: Int, $filters: BatchFilterInput) {
    batches(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
      }
    }
  }
`;

const createBatchSettingsMutation = gql`
  mutation ($payload: UpdateBatchSettingInput!) {
    updateBatchSetting (payload: $payload) {
      id
    }
  }
`;

export const getBatchSettingsQuery = gql`
  query ($id: String!) {
    batchSetting (id: $id) {
      id
      grNoPrefix
      startingGRNo
    }
  }
`;

export function useMutateBatch(queries: any) {
  const [mutate, { error }] = useMutation(queries);
  return { mutate, error };
}

export function useQueryBatch() {
  const [query, { called, error, loading, data, refetch }] = useLazyQuery(getBatchQuery, {
    fetchPolicy: 'network-only',
  });
  return { query, data, error, refetch, loading };
}

export function useAddBatch(page: number, searchValue: string, rowLimit: number) {
  const ErrorOccur = false;

  const [addBatch, { error: addBatchError, loading, data: addBatchData }] = useMutation(
    addBatchMutation,
    {
      update: (cache, { data: { createBatch } }) => {
        // toDO
      },
    },
  );
  return { addBatch, addBatchData, addBatchError, ErrorOccur };
}

export function useUpdateBatch() {
  const [updateBatch, { error: updateBatchError, data: updateBatchData }] =
    useMutation(updateBatchMutation);
  return { updateBatch, updateBatchData, updateBatchError };
}

export function useDeleteBatch() {
  const [deleteBatch, { data: deleteBatchData, error: deleteBatchError }] =
    useMutation(deleteBatchMutation);
  return { deleteBatch, deleteBatchData, deleteBatchError };
}

export async function getFilteredBatch(param: any) {
  const { data: filteredBatchData } = await graphqlQuery(getBatchQuery, param, false);
  if (filteredBatchData.batches) {
    const batches = filteredBatchData.batches;
    return { options: batches.data, hasNextPage: batches.hasNextPage };
  } else {
    return { options: [], hasNextPage: false };
  }
}
export { getBatchQuery, getBatchByIdQuery, addBatchMutation, searchBatchQuery };

const getBatchBreadcrumbQuery = gql`
  query ($id: String) {
    batch(id: $id) {
      alias
      class {
        id
        course {
          alias
        }
      }
    }
  }
`;

export async function getBatchBreadcrumb(batchID: string) {
  const { data } = await graphqlQuery(
    getBatchBreadcrumbQuery,
    { id: batchID },
    false
  );
  if (data && data?.batch) {
    return {
      courseAlias: data.batch.class.course.alias,
      batchAlias: data.batch.alias,
      classId: data.batch.class.id,
    };
  }
}

export function useCreateBatchSettings() {
  const [createBatchSettings, { data: createBatchSettingsData, error: createBatchSettingsError }] =
    useMutation(createBatchSettingsMutation);
  return { createBatchSettings, createBatchSettingsData, createBatchSettingsError };
}

export function useGetBatchSettings() {
  const [getBatchSettings, { loading, data, refetch }] = useLazyQuery(
    getBatchSettingsQuery,
    { fetchPolicy: "network-only" }
  );
  return { getBatchSettings, data, loading, refetch };
}

export function fetchBatchById() {
  const [getBatchByID,{data:batchDataByID,loading:batchloading,error:batcherror}] = useLazyQuery(getBatchByIdQuery, { fetchPolicy: "network-only" });
  return{
    getBatchByID,
    batchDataByID,
    batchloading,
    batcherror
  }
}