import { makeVar, InMemoryCache, gql } from '@apollo/client';
import { UserType } from 'src/constant';

export interface UserInformation {
  email?: string;
  userName?: string;
  userType: UserType;
  userDetail?: UserDetails;
  userId: string;
  language?: string;
}

export interface UserOnboardingState {
  personalDetails: boolean;
  addressDetails: boolean;
  educationDetails: boolean;
  experienceDetails?: boolean;
  otherDetails: boolean;
  parentDetails?: boolean;
}

export interface UserDetails {
  profileCompletion?: UserOnboardingState | boolean;
  institute: string;
  instituteName: string;
  employeeId?: string;
  rollNo?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  photo?: string;
  subjects?: '';
  type?: Array<string>;
  allowProfileEdit: boolean;
  division?: string;
}

export interface EDBA_TOKEN {
  edbaToken?: string;
}

export interface LocalizationKeyValue {
  [key: string]: string;
}

const userInitialValue: UserInformation = {};
const edbaTokenInitialValue: EDBA_TOKEN = { edbaToken: '' };
const keyValueInitialValue: LocalizationKeyValue = {};

// Create the user information var and initialize it with the initial value
export const userInformation = makeVar<UserInformation>(userInitialValue);

export const allowedModulesState = makeVar<string[]>([]);

// Create the auth token var and initialize it with the initial value
export const authToken = makeVar<EDBA_TOKEN>(edbaTokenInitialValue);

export const fcmInitialized = makeVar<boolean>(false);
export const fcmTokenInitialized = makeVar<boolean>(false);
export const fcmWebListenerInitialized = makeVar<boolean>(false);
export const unsubscribeMessageListeners = makeVar<{
  foreground: () => void;
}>({
  foreground: () => {
    return;
  },
});
export const keyValuesVar = makeVar<LocalizationKeyValue>(keyValueInitialValue);

// InMemoryCache
export const cache: InMemoryCache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        userData: {
          read() {
            return userInformation();
          },
        },
        keyValues: {
          read() {
            return keyValuesVar();
          },
        },
      },
    },
  },
});

//
export const GET_USER_INFO = gql`
  query GetUserInfo {
    userData @client {
      id
      userName
    }
  }
`;

export const GET_LOCALIZATION_KEY_VALUE = gql`
  query GetLocalizationKeyValues {
    keyValues @client
  }
`;

export interface CurrentAddress {
  area?: string | null;
  city?: string | null;
  houseNo?: string | null;
  state?: string | null;
  street?: string | null;
  type?: string | null;
  zipcode?: string | null;
}

const currentAddressInitialValue: CurrentAddress = {};
// Create the auth token var and initialize it with the initial value
export const setCurrentAddress = makeVar<CurrentAddress>(currentAddressInitialValue);

export interface RadioBoxParam {
  value: boolean;
}

const currentRadioInitialValue: RadioBoxParam = { value: false };
// Create the auth token var and initialize it with the initial value
export const setCurrentRadioValue = makeVar<RadioBoxParam>(currentRadioInitialValue);

export interface formSubmissionErrorsProps {
  error: any;
}

const formSubmissionErrorsInitalValue: formSubmissionErrorsProps = { error: null };
// Create the auth token var and initialize it with the initial value
export const setFormSubmissionErrors = makeVar<formSubmissionErrorsProps>(
  formSubmissionErrorsInitalValue,
);
