import { gql } from '@apollo/client';

const formDDcontents = gql`
  query ($userType: UserType!) {
    formDDContents(userType: $userType) {
      streams {
        label
        value
      },	
      mediums {
        label
        value
      },	
      jobTypes {
        label
        value
      },	
      categories {
        label
        value
      },	
      religions {
        label
        value
      }
    }
  }
`;

const documentTypesQuery = gql`
  query($filters: DocumentTypeFilterInput!) {
    documentTypes(filters: $filters) {
      totalCount
      data {
        typeId: id
        name
        label: name
        category
        defaultUserSelectionRequired
      }
    }
  }
`;

const qualifiedStreamsQuery = gql`
  query($educationId: String, $filters: StreamFilterInput) {
    qualifiedStreams(educationId: $educationId, filters: $filters) {
      stream {
        label: name
        value: id
      }
      majorSubjects
    }
  }
`;

export { formDDcontents, documentTypesQuery, qualifiedStreamsQuery };