import React from "react";
import { colors } from "src/styles/theme/styles";
import styled from "styled-components/native";

type IndicatorBadgeProps = {
  diameter: number;
  color: string;
};

function IndicatorBadge(props: Partial<IndicatorBadgeProps>) {
  const { diameter = 8, color = colors.lightOrange } = props;

  return <Badge diameter={diameter} color={color} />;
}

const Badge = styled.View<IndicatorBadgeProps>`
  width: ${(props) => props.diameter};
  height: ${(props) => props.diameter};
  background-color: ${(props) => props.color};
  border-radius: ${(props) => props.diameter / 2}px;
`;

export default IndicatorBadge;
