import { useState } from "react";
import { userInformation } from '../../utils/manageState';
import { ERROR, SUCCESS, UserType, USER_TYPE } from '../../constant/index';
import { useProfileDetail } from '../../contexts/profile-detail-context';
import { showMessage } from '../../components/molecules/NotificationWrapper';
import { useUpdateInstituteEmployee } from '../../graphql/onboarding/employee';
import { useUpdateInstituteStudent } from '../../graphql/onboarding/student';
import { isObject } from "../utility";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { isWeb } from "src/constant/device";
import { TemplateType } from "src/components/molecules/Forms/types";
import { useUpdateApplicant } from "src/graphql/onboarding/applicant";
import { useUpdateApplicantionQualification } from "src/graphql/onboarding/application";


export const useUpdateUserDetails = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { updateInstituteEmployeeDetail } = useUpdateInstituteEmployee();
    const { updateInstituteStudentDetail } = useUpdateInstituteStudent();
    const { updateApplicantDetail } = useUpdateApplicant();
    const { updateApplicantQualification } = useUpdateApplicantionQualification();

    const currentUserObject = userInformation();
    const { setAlertDetails } = useAlertSystem();
    const { userProfileDetails: basicInformation, setUserProfileDetails: setBasicInformation } = useProfileDetail();

    const updateUserDetails = async (
        updates: { payload: any, remove?: any },
        templateType: TemplateType,
        userId: string,
        userType: UserType,
        applicationQualification = false,
        inModalView = false,
        isOnboarding = true,
        goToNextStep?: () => void,
        callback?: () => void,
    ) => {
        setIsSubmitting(true);
        const payload = { ...updates.payload };
        payload.id = userId;

        const variables: any = { payload };
        if (updates.remove) {
            const remove = { ...updates.remove };
            remove.id = userId;
            variables.remove = remove;
        }

        try {
            let status, message, response;
            if (applicationQualification) {
                response = await updateApplicantQualification({ variables });
                status = response?.data?.UpdateApplicationQualification?.status;
                message = response?.data?.UpdateApplicationQualification?.message;
            } else {
                switch (userType) {
                    case USER_TYPE.EMPLOYEE:
                        response = await updateInstituteEmployeeDetail({ variables });
                        status = response?.data?.updateInstituteEmployeeDetail?.status;
                        message = response?.data?.updateInstituteEmployeeDetail?.message;
                        break;
                    case USER_TYPE.APPLICANT:
                    case USER_TYPE.EDUCATION_DETAILS:
                        response = await updateApplicantDetail({ variables });
                        status = response?.data?.updateApplicantDetail?.status;
                        message = response?.data?.updateApplicantDetail?.message;
                        break;
                    case USER_TYPE.STUDENT:
                        response = await updateInstituteStudentDetail({ variables });
                        status = response?.data?.updateInstituteStudentDetail?.status;
                        message = response?.data?.updateInstituteStudentDetail?.message;
                        break;
                    default:
                        console.log("Something went wrong");
                        break;
                }
            }


            if (status === 'success') {
                if (isWeb) {
                    setAlertDetails({
                        message: 'Updated successfully',
                        level: SUCCESS,
                    });
                } else {
                    showMessage({
                        message: 'Updated successfully',
                        type: 'success',
                        position: 'top',
                        icon: 'success',
                    });
                    if (callback) callback();
                }

                if (!inModalView && isOnboarding && templateType == TemplateType.DEFAULT && goToNextStep) {
                    goToNextStep();
                }
                setIsSubmitting(false)
            } else if (status === 'failure') {
                if (isWeb) {
                    setAlertDetails({
                        message: message,
                        level: ERROR,
                    });
                } else {
                    showMessage({
                        message: message,
                        type: 'danger',
                        position: 'top',
                        icon: 'danger',
                    });
                    if (callback) callback();
                }
            }
            return response;
        } catch (error) {
            if (isWeb) {
                setAlertDetails({
                    message: error?.message,
                    level: ERROR,
                });
            } else {
                showMessage({
                    message: error?.message,
                    type: 'danger',
                    position: 'top',
                    icon: 'danger',
                });
            }
            setIsSubmitting(false)
        }
    };

    return {
        isSubmitting,
        updateUserDetails
    }
}