import useTable from 'src/components/molecules/Table/UseTable';
import { NoRecordsFound, TableCells, TableRows } from 'src/components/molecules/Table/TableAtom';
import React from 'react';
import { TableBody } from '@mui/material';
import styled from 'styled-components';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { colors, fontWeight } from 'src/styles/theme/styles';
import Chip from 'src/components/atoms/Chip';
import { clipLongText } from 'src/utils/utility';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import {
  TappliedStudents,
  appliedRevalHeadcell,
} from 'src/components/molecules/Assessment/Results/Helpers/helpers';

type Tprops = {
  appliedStudents: TappliedStudents;
  isloading: boolean;
};
type Tsubjects = {
  subject: {
    name: string;
  };
};


export default function RevalAppliedStudent(props: Tprops) {
  const { appliedStudents, isloading } = props;
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    appliedStudents,
    appliedRevalHeadcell,
  );
  return (
    <Container>
      {!isloading ? (
        <TblContainer overflowY="scroll">
          <TblHead />
          <>
            {recordsAfterPagingAndSorting()?.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map(
                  (item: any) => (
                    console.log(item?.softCopySubjects),
                    (
                      <TableRows key={item?.id}>
                        <TableCells value={item?.user?.fullName ?? '-'} />
                        <TableCells style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                          {item?.subjects.map((i: Tsubjects) => (
                            <Chip label={clipLongText(i?.subject?.name, 35)} close={false} />
                          ))}
                        </TableCells>
                        <TableCells>
                          <ChipsWrapper>
                            {item.softCopySubjects && item.softCopySubjects.length > 0 ? (
                              item.softCopySubjects.map(
                                (i: Tsubjects) =>
                                  i.subject && <Chip label={i.subject.name} close={false} />,
                              )
                            ) : (
                              <TextWrapper>
                                <NormaltextAtom
                                  value={'N/A'}
                                  fontWeight={fontWeight[400]}
                                  color={colors.primaryText}
                                />
                              </TextWrapper>
                            )}
                          </ChipsWrapper>
                        </TableCells>
                        <TableCells value={`\u20B9 ${item?.amountToBePaid}` ?? '-'} />
                        <TableCells
                          align={'right'}
                          value={item?.status ?? '-'}
                          color={colors.lightGreen}
                        />
                      </TableRows>
                    )
                  ),
                )}
              </TableBody>
            ) : (
              <NoRecordsFound colspan={6} maxHeight={50} />
            )}
          </>
        </TblContainer>
      ) : (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      )}
    </Container>
  );
}

const SpinnerWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  margin-bottom: 50px;
`;

const ChipsWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const TextWrapper = styled.div`
  display: flex;
  alin-items: center;
  justify-content: center;
  width: 100%;
`;
