import ReservationCritieriaRadioSelectTable from "../../Table/ReservationCriteriaRadioSelectTable";
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import React from "react";
import { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";

export default function ReservationCriteriaRadioSelectTbl(props: UseFieldApiConfig) {
  const {
    subscribeToValues,
    tableData,
    currentId,
    isEdit,
  } = props;
  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <ReservationCritieriaRadioSelectTable
          tableData={tableData}
          currentId={currentId}
          isEdit={isEdit}
        />
      )}
    </FormSpy>
  );
}