import React from 'react';
import { View, RefreshControl, StyleSheet, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components/native';
import { height } from 'src/constant/device';
import { colors } from 'src/styles/theme/styles';
import NoData from '../NoData';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import AbstractFlashList from 'src/components/atoms/FlashList';

interface PropType {
  Comp: React.Component<unknown>;
  arrayList: unknown[];
  onPress: (data: Record<string, unknown>, idx: number) => void;
  iconType?: string;
  icon: IconDefinition;
  label: string;
  onEndReachedThreshold?: number;
  customStyle?: View['props']['style'];
  heightOffset?: number;
  student?: boolean;
  passRef?: React.RefObject<any>;
  deleteAction?: (data: Record<string, unknown>, idx: number) => void;
  isSwipable?: boolean;
  refreshing?: boolean;
  onRefresh?: () => void;
  onEndReached?: () => void;
  setShowAdd?: (val: boolean) => void;
  fetchMoreLoading?: boolean;
  estimatedItemSize: number;
}

export default function Listing(props: PropType) {
  const {
    Comp,
    arrayList,
    iconType,
    icon,
    label,
    onEndReached,
    onEndReachedThreshold,
    passRef,
    refreshing = false,
    onRefresh,
    customStyle,
    setShowAdd,
    fetchMoreLoading = false,
    heightOffset = 0,
    estimatedItemSize
  } = props;

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const currentOffset = e?.nativeEvent?.contentOffset?.y;
    if (currentOffset > 10 && setShowAdd) {
      setShowAdd(false);
    }
  };

  const renderListEmptyComponent = () => (
    <View style={styles.emptyComponent}>
      <NoData icon={icon} text1={label} type={iconType && iconType} />
    </View>
  );

  const renderItem = (item, index) => (
    <View>
      <Comp data={item} key={item?.id} {...props} indexKey={index} />
      {fetchMoreLoading && arrayList.length - 1 === index ? (
        <BottomSpinnerView>
          <LoaderSpinner />
        </BottomSpinnerView>
      ) : null}
    </View>
  );


  return (
    <View style={[customStyle]}>
      <View style={[arrayList.length > 0 ? styles.start : styles.center, { height: height - heightOffset }]}>
        <AbstractFlashList
          refreshControl={
            <RefreshControl
              tintColor={colors.primary}
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }
          onScroll={handleScroll}
          ref={passRef}
          showsVerticalScrollIndicator={false}
          onEndReached={onEndReached}
          onEndReachedThreshold={onEndReachedThreshold}
          removeClippedSubviews={true}
          ListEmptyComponent={renderListEmptyComponent}
          data={arrayList}
          estimatedItemSize={estimatedItemSize}
        >
          {({ item, index }) => renderItem(item, index)}
        </AbstractFlashList>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  start: {
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
  },
  emptyComponent: {
    height: height - 260,
    justifyContent: 'center',
  }
});

const BottomSpinnerView = styled.View`
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
