import { ADD_SUCCESS, UPDATE_SUCCESS } from "src/constant/message";
import {
  ChipData,
  admissionFormHelpers as helpers,
} from "./AdmissionFormHelpers";
import { DropdownOptions, TFormProps } from "src/types";
import { FieldValues, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  admissionFormState as TForm,
  admissionFormStep as TStep,
} from "src/components/molecules/Admission/AdmissionForm/types";

import AdmissionCriteriaSelection from "./AdmissionCriteriaSelection.web";
import AdmissionRequiredDetails from "./AdmissionRequiredDetails.web";
import DocumentsInput from "./DocumentsInput.web";
import Element from "src/components/molecules/Forms/ApplicationElement.web";
import FeeSeatAllocation from "./FeeSeatAllocation.web";
import InstitutesChipArrayInput from "./InstitutesChipArrayInput.web";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { colors } from "src/styles/theme/styles";
import formJSON from "src/form-json/admission/admission-config-form.json";
import styled from "styled-components";
import { useCreateAdmission } from "src/graphql/admission";
import { useI18n } from "src/i18n/hooks";

const { fieldMap: FM } = helpers;

export const AdmissionForm = (props: TFormProps<TForm>) => {
  const { form, onSuccess, onError, onClose } = props;
  const { t } = useI18n();
  const { createAdmission } = useCreateAdmission();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    resetField,
    register,
    clearErrors,
    watch,
  } = useForm();

  const [elements, setElements] = useState<any>({});
  const [step, setStep] = useState<TStep>("initial_config");
  const [loading, setLoading] = useState<boolean>(true);
  const [documentsOptions, setDocumentsOptions] = useState<DropdownOptions[]>(
    []
  );
  const [documentChips, setDocumentChips] = useState<ChipData[]>([]);

  const showInHouseInstitutesInput = !!watch("inHouseApplicantAllowedEC");
  const showRequiredFieldsInput = !!watch("publicLinkEC");
  const showDocumentsInput = !!watch("documentsEC");

  const { fields } = elements ?? {};
  const editMode: boolean = form.mode === "EDIT";

  useEffect(() => {
    setElements({ ...formJSON[0] });
    helpers.instantiateFields(setValue);
  }, []);

  useEffect(() => {
    if (editMode) {
      toggleFieldInteraction(true, helpers.fieldsDisabledInEditMode);
      prefillData();
    } else {
      initialFetch();
    }
    return () => {
      toggleFieldInteraction(false, helpers.fieldsDisabledInEditMode);
      setLoading(true);
      formJSON[0].fields[FM.merchantName].option = [];
      formJSON[0].fields[FM.toDOB].dateTimePickerMinDate = "";
    };
  }, [editMode]);

  const initialConfigOnSubmit = (data: FieldValues) => {
    if (data) {
      setStep("fee_seat_allocation");
    }
  };

  const feeSeatOnSubmit = async () => {
    setLoading(true);
    try {
      const payload = helpers.generatePayload(
        getValues(),
        form?.admissionID,
        documentChips
      );
      const response = await createAdmission({
        variables: { payload },
      });
      if (response.data) {
        onSuccess(editMode ? UPDATE_SUCCESS.ADMISSION : ADD_SUCCESS.ADMISSION);
        setLoading(false);
      }
    } catch (e: any) {
      onError(e?.message);
      setLoading(false);
    }
  };

  const prefillData = async () => {
    if (form?.admissionID) {
      const { loading, merchants, subjectGroups, requiredDocsExist } =
        await helpers.fetchAndFillData(
          form.admissionID,
          setValue,
          setDocumentsOptions,
          setDocumentChips
        );

      const enableApplicationFeeFields = !!getValues("setApplicationFee");
      const enableRequiredDetails = !!getValues("publicLink");
      const enableInHouse = !!getValues("inHouseApplicantAllowed");
      const enableDocuments = !!requiredDocsExist;

      // Reserved Seat (enabled only when applicant type has Lottery)
      const hasLottery = helpers.checkHasLottery(getValues("applicantType"));
      formJSON[0].fields[FM.reservedSeats].disabled = !hasLottery;
      formJSON[0].fields[FM.reservedSeats].required = hasLottery;

      // ApplicationFee
      formJSON[0].fields[FM.merchantName].option = merchants?.data ?? [];
      formJSON[0].fields[FM.merchantName].fieldVisible =
        enableApplicationFeeFields;
      formJSON[0].fields[FM.applicationAmount].fieldVisible =
        enableApplicationFeeFields;
      formJSON[0].fields[FM.subjectGroup].option = subjectGroups;

      // PublicLink
      formJSON[0].fields[FM.publicLinkEC].disabled = !enableRequiredDetails;
      setValue("publicLinkEC", !!enableRequiredDetails);

      // InHouse
      formJSON[0].fields[FM.inHouseApplicantAllowedEC].disabled =
        !enableInHouse;
      setValue("inHouseApplicantAllowedEC", !!enableInHouse);

      formJSON[0].fields[FM.documentsEC].disabled = !enableDocuments;
      setValue("documentsEC", !!enableDocuments);

      const fromDateString = watch("fromDOB");
      if (fromDateString) {
        formJSON[0].fields[FM.toDOB].dateTimePickerMinDate = fromDateString;
      }
      setElements({ ...formJSON[0] });
      setLoading(!!loading);
    }
  };

  const goBack = () => {
    setStep("initial_config");
  };

  const initialFetch = async () => {
    const { universities, merchants, documentTypes } =
      await helpers.fetchStarterFormData();
    formJSON[0].fields[FM.university].option = universities;
    formJSON[0].fields[FM.merchantName].option = merchants;
    setDocumentsOptions(documentTypes);
    setElements({ ...formJSON[0] });
    setLoading(false);
  };

  const clearFields = (indices: number[]) => {
    indices.forEach((index) => {
      // @ts-ignore
      const id = Object.keys(FM).find((key) => FM[key] === index);
      if (id) {
        setValue(id, null);
        formJSON[0].fields[index].option = [];
      }
    });
  };

  const toggleFieldInteraction = (disabled: boolean, indices: number[]) => {
    indices.forEach((index) => {
      formJSON[0].fields[index].disabled = disabled;
    });
    setElements({ ...formJSON[0] });
  };

  const handleCustomSelect = async (id: string, data: any) => {
    switch (id) {
      case "university":
        clearFields([
          FM.course,
          FM.class,
          FM.batch,
          FM.subjectGroup,
          FM.admissionCriteria,
        ]);
        if (data && data?.value) {
          const resp = await helpers.fetchCourseOptions(data.value);
          formJSON[0].fields[FM.course].option = resp?.courses;
        }
        setElements({ ...formJSON[0] });
        break;

      case "course":
        clearFields([
          FM.class,
          FM.batch,
          FM.subjectGroup,
          FM.admissionCriteria,
        ]);
        if (data && data?.value) {
          const resp = await helpers.fetchClassOptions(data.value);
          formJSON[0].fields[FM.class].option = resp?.classes;
        }
        setElements({ ...formJSON[0] });
        break;

      case "class":
        clearFields([FM.batch, FM.subjectGroup, FM.admissionCriteria]);
        if (data && data?.value) {
          const batchResp = await helpers.fetchBatchOptions(data.value);
          const criteriaResp = await helpers.fetchAdmissionCriterias(
            data.value
          );
          formJSON[0].fields[FM.batch].option = batchResp?.batches;
          formJSON[0].fields[FM.admissionCriteria].option =
            criteriaResp?.criterias;
        }
        formJSON[0].fields[FM.admissionCriteria].disabled = !data?.value;
        setElements({ ...formJSON[0] });
        break;

      case "batch":
        clearFields([FM.subjectGroup]);
        if (data && data?.value) {
          const resp = await helpers.fetchSubjectGroupOptions(data.value);
          formJSON[0].fields[FM.subjectGroup].option = resp?.subjectGroups;
        }
        setElements({ ...formJSON[0] });
        break;

      case "applicantType":
        if (data) {
          const hasLottery = helpers.checkHasLottery(data);
          formJSON[0].fields[FM.reservedSeats].disabled = !hasLottery;
          formJSON[0].fields[FM.reservedSeats].required = hasLottery;
          register("reservedSeats", { required: hasLottery });
          if (!hasLottery) {
            clearErrors("reservedSeats");
            setValue("reservedSeats", "");
          }
        } else {
          // applicantType cleared (empty)
          register("reservedSeats", { required: false });
          clearErrors("reservedSeats");
        }
        setElements({ ...formJSON[0] });
        break;

      case "fromDOB":
        if (data) {
          formJSON[0].fields[FM.toDOB].dateTimePickerMinDate = data;
          const toDOB = watch("toDOB");
          if (toDOB && helpers.shouldClearDate(data, toDOB)) {
            setValue("toDOB", null);
          }
          setElements({ ...formJSON[0] });
        }
        break;

      case "admissionCriteria":
        setStep("criteria_selection");
        break;

      case "publicLink":
        formJSON[0].fields[FM.publicLinkEC].disabled = !data;
        setValue("publicLinkEC", !!data);
        setElements({ ...formJSON[0] });
        break;

      case "setApplicationFee":
        setValue("setApplicationFeeEC", !!data);
        ["merchantName", "applicationAmount"].forEach((fieldID) => {
          // @ts-ignore
          const fieldIndex = FM[fieldID];
          formJSON[0].fields[fieldIndex].fieldVisible = data;
          formJSON[0].fields[fieldIndex].required = data;
          if (!data) {
            register(fieldID, { required: false });
            clearErrors(fieldID);
            resetField(fieldID, { keepError: false, defaultValue: null });
          }
        });
        setElements({ ...formJSON[0] });
        break;

      case "documents":
        formJSON[0].fields[FM.documentsEC].disabled = !data;
        setValue("documentsEC", !!data);
        setElements({ ...formJSON[0] });
        break;

      case "inHouseApplicantAllowed":
        formJSON[0].fields[FM.inHouseApplicantAllowedEC].disabled = !data;
        setValue("inHouseApplicantAllowedEC", !!data);
        setElements({ ...formJSON[0] });
        break;

      default:
        break;
    }
  };

  const onCriteriaSubmit = (selectedCriteria: DropdownOptions) => {
    setValue("admissionCriteria", selectedCriteria);
    clearErrors("admissionCriteria");
    goBack();
  };

  const onChangeReqDetails = (reqDetails: string[]): void => {
    setValue("requiredDetails", reqDetails);
  };

  const onChangeInstitutes = (institutes: string[]): void => {
    setValue("inHouseInstitutes", institutes);
  };

  return (
    <>
      <NormalModal
        modalVisible={step === "initial_config"}
        visibility={step !== "criteria_selection"}
        height={560}
        maxWidth={"lg"}
        setModalVisible={onClose}
        Headerpopup={
          editMode ? t("editAdmission.text") : t("addAdmission.text")
        }
        addEditButtonLabel={"next.label"}
        handleSave={handleSubmit(initialConfigOnSubmit)}
        isSubmitting={loading}
      >
        {!loading && fields ? (
          <Container>
            {fields.map((field: any, index: number) => (
              <>
                <FieldWrapper>
                  {/* @ts-ignore */}
                  <Element
                    key={field.id}
                    field={field}
                    control={control}
                    errors={errors}
                    handleCustomSelect={handleCustomSelect}
                    limitTags={1}
                  />
                </FieldWrapper>

                {field.id === "publicLinkEC" && showRequiredFieldsInput && (
                  <AdmissionRequiredDetails
                    onChange={onChangeReqDetails}
                    fieldValue={watch("requiredDetails") ?? []}
                  />
                )}

                {field.id === "documentsEC" && showDocumentsInput && (
                  <DocumentsInput
                    documentsOptions={documentsOptions}
                    documentChips={documentChips}
                    setDocumentChips={setDocumentChips}
                    editMode={editMode}
                  />
                )}
                {helpers.showDividerAfter.includes(index) && <Divider />}
              </>
            ))}
            {showInHouseInstitutesInput && (
              <InstitutesChipArrayInput
                institutes={
                  watch("inHouseInstitutes")?.map(
                    (inst: string | DropdownOptions) =>
                      typeof inst === "string" ? inst : inst?.label
                  ) ?? []
                }
                onChange={onChangeInstitutes}
              />
            )}
          </Container>
        ) : (
          <SpinnerWrapper>
            <LoaderSpinner />
          </SpinnerWrapper>
        )}
      </NormalModal>

      {step === "criteria_selection" && (
        <AdmissionCriteriaSelection
          onClose={onClose}
          goBack={goBack}
          onSubmit={onCriteriaSubmit}
          criterias={elements.fields[FM.admissionCriteria]?.option ?? []}
          initialValue={getValues("admissionCriteria")}
        />
      )}

      {step === "fee_seat_allocation" && (
        <FeeSeatAllocation
          onClose={onClose}
          goBack={goBack}
          editMode={editMode}
          handleSave={feeSeatOnSubmit}
          modalTitle={
            editMode ? t("editAdmission.text") : t("addAdmission.text")
          }
          subjectGroups={helpers.mergeSelectedSubjectGroupAndFeeSeatData({
            selectedSubGrp: watch("subjectGroup"),
            feeSeatData: getValues("subjectGroupsWithFeeSeatData"),
          })}
          totalSeatsAvailability={watch("totalSeatsAvailability")}
          setValue={setValue}
        />
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
`;

const FieldWrapper = styled.div`
  width: 47.5%;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Divider = styled.div`
  background-color: ${colors.borderColor};
  margin-top: -8px;
  margin-bottom: 16px;
  height: 1px;
  width: 100%;
`;
