import { ADD_SUCCESS, UPDATE_SUCCESS } from "src/constant/message";
import { FieldValues, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { qualificationFormState as TForm } from "src/components/organism/Admission/Admin/Qualifications/QualificationHelpers";
import { TFormProps } from "src/types";
import formJSON from "src/form-json/admission/admin/qualification-form.json";
import styled from "styled-components";
import { useCreateQualification } from "src/graphql/admission/qualification";
import { useI18n } from "src/i18n/hooks";

const QualificationForm = (props: TFormProps<TForm>) => {
  const { form, onSuccess, onError, onClose } = props;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: form?.data ? { ...form.data } : {},
  });

  const { createQualification } = useCreateQualification();

  const [elements, setElements] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { fields } = elements ?? {};
  const editMode: boolean = form.mode === "EDIT";

  useEffect(() => {
    setElements({ ...formJSON[0] });
  }, []);

  const onSubmitFn = async (data: FieldValues) => {
    if (data) {
      setLoading(true);
      try {
        const payload = {
          name: data?.name,
          alias: data?.alias,
          level: parseFloat(data?.level),
          ...(form?.data?.id ? { id: form.data.id } : {}),
        };
        const response = await createQualification({
          variables: { payload },
        });
        if (response?.data) {
          onSuccess(
            editMode ? UPDATE_SUCCESS.QUALIFICATION : ADD_SUCCESS.QUALIFICATION
          );
        }
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      modalVisible={true}
      Headerpopup={
        editMode ? t("editQualification.text") : t("addQualification.text")
      }
      width={600}
      handleSave={handleSubmit(onSubmitFn)}
    >
      <Container>
        {fields &&
          fields.map((field: any) => (
            // @ts-ignore
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
            />
          ))}
      </Container>
    </NormalModal>
  );
};

const Container = styled.div`
  height: 288px;
`;

export default QualificationForm;
