import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getAdmissionsQuery = gql`
  query(
    $filters: AdmissionConfigFilterInput
    $limit: Int
    $skip: Int
    $sort: AdmissionConfigSortInput
  ) {
    admissions(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        applicationFeeRequired
        askAdmissionProcessId
        applicationFeeDetail {
          feeAmount
          account {
            label: name
            value: id
          }
        }
        name
        allowedApplicantType
        allowedPaymentModes
        totalSeats
        lastDate
        status
        inHouseApplicantAllowed
        inHouseInstitutes {
          instituteCode
          instituteName
        }
        class {
          value: id
          label: alias
          course {
            value: id
            label: alias
            university {
              value: id
              label: name
            }
          }
        }
        batch {
          value: id
          label: name
        }
        subjectGroups {
          fee {
            value: id
            label: name
          }
          seats
          subjectGroup {
            value: id
            label: name
          }
        }
        criteria {
          value: id
          label: name
          qualifications {
            streams {
              stream {
                id
                name
              }
            }
          }
        }
      }
      hasNextPage
      totalCount
    }
  }
`;

export const getAdmissionByIdQuery = gql`
  query($id: String!) {
    admission(id: $id) {
      id
      name
      publicAvailability
      askAdmissionProcessId
      lastReportRequest {
        createdAt
        status
      }
      reportRequest {
        createdAt
        status
      }
      applicationFeeRequired
      applicationFeeDetail {
        feeAmount
        account {
          label: name
          value: id
        }
      }
      allowedApplicantType
      allowedPaymentModes
      totalSeats
      lastDate
      status
      inHouseApplicantAllowed
      inHouseInstitutes {
        instituteCode
        instituteName
      }
      class {
        value: id
        label: alias
        course {
          value: id
          label: alias
          university {
            value: id
            label: name
          }
        }
      }
      batch {
        value: id
        label: name
        divisionDetails {
          division
        }
      }
      subjectGroups {
        fee {
          value: id
          label: name
        }
        seats
        subjectGroup {
          value: id
          label: name
        }
      }
      criteria {
        value: id
        label: name
        qualifications {
          name {
            id
            name
          }
          streams {
            stream {
              id
              name
            }
          }
        }
        reservationCriteria {
          id
          name
          reservations {
            caste {
              id
              name
              alias
            }
            reservePercentage
          }
        }
      }
      activeRound {
        id
        name
        qualifiedApplications
        lastPayment
        lastSubmission
        meritDate
        meritGenerated
      }
      pastRounds {
        id
        name
        qualifiedApplications
        lastPayment
        lastSubmission
        meritDate
        meritGenerated
      }
      availableSeats
      confirmedSeats {
        subjectGroup
        count
      }
      cutOffs {
        round
        cutOff
        cutOffs {
          category
          cutOff
        }
      }
    }
  }
`;

export const getBasicAdmissionDetailQuery = gql`
  query($id: String!) {
    admission(id: $id) {
      id
      name
      batch {
        value: id
        label: name
      }
      subjectGroups {
        subjectGroup {
          label: name
          value: id
        }
      }
    }
  }
`;

export const createAdmissionMutation = gql`
  mutation ($payload: AdmissionConfigInput!) {
    createAdmissionConfig(payload: $payload) {
      id
      name
    }
  }
`;

export const getOpenAdmissionsToPromoteQuery = gql`
  query($currentBatch: String!) {
    openAdmissionsToPromote(currentBatch: $currentBatch) {
      value: id
      label: name
    }
  }
`;

export const createAdmissionRoundMutation = gql`
  mutation ($payload: AdmissionRoundInput!) {
    createAdmissionRound(payload: $payload) {
      id
      name
    }
  }
`;

export const getAdmissionRoundByIdQuery = gql`
  query ($id: String) {
    admissionRound(id: $id) {
      id
      name
      lastPayment
      lastSubmission
    }
  }
`;

export const getPublicAdmissionLinks = gql`
  query($filters: AdmissionLinkFilterInput) {
    publicAdmissionLinks(filters: $filters) {
      data {
        course
        class
        admission {
          id
          name
        }
      }
      totalCount
    }
  }
`;

const getPublicallyAvailableAdmissionLinksQuery = gql`
  query($instituteId: String!) {
    publicAvailableAdmissions(instituteId: $instituteId) {
      id
      name
      course {
        id
        name
      }
      institute {
        name
      }
    }
  }
`;

export const getAdmissionLinksQuery = gql`
  query (
    $filters: AdmissionLinkFilterInput
    $limit: Int
    $skip: Int
    $sort: AdmissionLinkSortInput
  ) {
    admissionLinks(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        prefix
        requestId
        state
        expiryDate
        university {
          id
          value: name
        }
        course {
          id
          value: alias
        }
        class {
          id
          value: alias
        }
        batch {
          id
          value: alias
        }
        admission {
          id
          value: name
        }
        subjectGroup {
          id
          value: name
        }
        applicantType
      }
      totalCount
    }
  }
`;

export const createAdmissionLinkMutation = gql`
  mutation ($payload: AdmissionLinkInput!) {
    createAdmissionLink(payload: $payload) {
      id
    }
  }
`;

export const getAdmissionLinkByRequestIdQuery = gql`
  query($requestId: String!) {
    admissionLink(requestId: $requestId) {
      id
      prefix
      institute {
        name
      }
      university {
        name
      }
      course {
        alias
      }
      class {
        alias
      }
      batch {
        alias
      }
      subjectGroup {
        id
        name
        subjects {
          id
          name
        }
      }
      admission {
        id
        lastDate
        inHouseApplicantAllowed
        askAdmissionProcessId
        inHouseInstitutes {
          label: instituteName
          value: instituteCode
        }
        criteria {
          qualifications {
            name {
              name
            }
          }
        }
        subjectGroups {
          fee {
            feeBooks {
              amount
            }
          }
          subjectGroup {
            id
          }
        }
        applicationFeeDetail {
          feeAmount
        }
        applicationFeeRequired
        requiredDocuments {
          allowedPlace
          docType {
            id
            name
            category
          }
          required
        }
      }
      applicantType
    }
  }
`;

const deleteAdmissionLinkMutation = gql`
  mutation ($id: String!) {
    deleteAdmissionLink(id: $id) {
      message
      status
    }
  }
`;

export const searchCAPApplicantQuery = gql`
  query($admission: String!, $capId: String!) {
    searchCAPApplicant(admission: $admission, capId: $capId) {
      capId
      email
      name
    }
  }
`;

const admissionSubjectGroupOptionsQuery = gql`
  query {
    admissions(limit: 0, sort: { name: ASCENDING }) {
      data {
        value: id
        label: name
        subjectGroups {
          subjectGroup {
            value: id
            label: name
          }
        }
      }
      totalCount
    }
  }
`;

const transferApplicationMutation = gql`
  mutation(
    $applicationId: String!
    $toAdmision: String!
    $toSubjectGroup: String!
  ) {
    transferAdmission(
      applicationId: $applicationId
      toAdmision: $toAdmision
      toSubjectGroup: $toSubjectGroup
    ) {
      id
    }
  }
`;

const generateAdmissionReportsMutation = gql`
  mutation ($admissionId: String!) {
    generateAdmissionReports (admissionId: $admissionId) {
      message
      status
    }
  }
`;

const sendCustomEmailMutation = gql`
  mutation(
    $admissionId: String!
    $havingStatus: [ApplicationStatus!]
    $mailBody: String!
    $mailSubject: String!
    $subjectGroup: [String!]!
  ) {
    sendCustomMail(
      admissionId: $admissionId
      havingStatus: $havingStatus
      mailBody: $mailBody
      mailSubject: $mailSubject
      subjectGroup: $subjectGroup
    ) {
      message
      status
    }
  }
`;

export const inviteDetailQuery = gql`
  query($inviteCode: String!) {
    inviteDetail(inviteCode: $inviteCode) {
      capId
      applicationId
      applicationStatus
      institute {
        name
      }
      university {
        name
      }
      course {
        alias
      }
      class {
        alias
      }
      batch {
        alias
      }
      subjectGroup {
        id
        name
        subjects {
          id
          name
        }
      }
      admission {
        id
        lastDate
        inHouseApplicantAllowed
        askAdmissionProcessId
        inHouseInstitutes {
          label: instituteName
          value: instituteCode
        }
        criteria {
          qualifications {
            name {
              name
            }
          }
        }
        subjectGroups {
          fee {
            feeBooks {
              amount
            }
          }
          subjectGroup {
            id
          }
        }
        applicationFeeDetail {
          feeAmount
        }
        applicationFeeRequired
      }
      applicationType
      documents {
        document {
          id
          name: documentName
          uri: documentUrl
          type
          category
        }
        requiredDetail {
          allowedPlace
          docType {
            id
            name
            category
          }
          required
        }
      }
    }
  }
`;

export const getAllInvitedDirectApplicantsQuery = gql`
  query ($filters: ApplicantInviteInput!, $limit: Int, $skip: Int, $sort: ApplicantInviteSortInput) {
    applicantInvites(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        capId
        date
        email
        invitedBy {
          personalDetails {
            fullName
          }
        }
        name
        subjectGroup {
          name
        }
        status
      }
      hasNextPage
      totalCount
    }
  }
`;

const publicAdmissionDetailsQuery = gql`
  query($id: String!) {
    publicAdmissionDetail(id: $id) {
      id
      name
      subjectGroups {
        subjectGroup {
          label: name
          value: id
        }
      }
      toDOB
      fromDOB
      requiredDetails
      status
      class {
        name
      }
      requiredDocuments {
        allowedPlace
        docType {
          id
          name
          category
        }
        required
      }
    }
  }
`;

const createApplicationByPublicURLMutation = gql`
  mutation($payload: PublicApplicationInput!) {
    createApplicationByPublicURL(payload: $payload) {
      id
      applicationId
      status
    }
  }
`;

export function useGetBasicAdmissionDetails() {
  const [
    getBasicAdmissionDetails,
    {
      loading: basicAdmissionDetailsLoading,
      data: basicAdmissionDetailsData,
    },
  ] = useLazyQuery(getBasicAdmissionDetailQuery, { fetchPolicy: 'network-only' });
  return { getBasicAdmissionDetails, basicAdmissionDetailsData, basicAdmissionDetailsLoading };
}

export function useGetAdmissionSubjectGroupOptions() {
  const [
    getAdmissionSubjectGroupOptions,
    {
      loading: admissionSubjectGroupOptionsLoading,
      data: admissionSubjectGroupOptionsData,
    },
  ] = useLazyQuery(admissionSubjectGroupOptionsQuery, { fetchPolicy: 'network-only' });
  return { getAdmissionSubjectGroupOptions, admissionSubjectGroupOptionsData, admissionSubjectGroupOptionsLoading };
}

export function useGetAdmissions() {
  const [
    getAdmissions,
    {
      loading: admissionsLoading,
      data: admissionsData,
      error: admissionsError,
      refetch: refetchAdmissions,
    },
  ] = useLazyQuery(getAdmissionsQuery, { fetchPolicy: 'network-only' });
  return { getAdmissions, admissionsData, admissionsError, admissionsLoading, refetchAdmissions };
}

export function useCreateAdmission() {
  const [createAdmission, { data: createAdmissionData, error: createAdmissionError }] =
    useMutation(createAdmissionMutation);
  return { createAdmission, createAdmissionData, createAdmissionError };
}

export function useTransferApplication() {
  const [transferApplication, { data: transferApplicationData, error: transferApplicationError }] =
    useMutation(transferApplicationMutation);
  return { transferApplication, transferApplicationData, transferApplicationError };
}

export function useCreateAdmissionRound() {
  const [createAdmissionRound] = useMutation(createAdmissionRoundMutation);
  return { createAdmissionRound };
}

// Currently Not in use
export function useGetPublicAdmissionLinks() {
  const [
    getAdmissionLinks,
    {
      loading: admissionLinksLoading,
      data: admissionLinksData,
      error: admissionLinksError,
      refetch: refetchAdmissionLinks,
    },
  ] = useLazyQuery(getPublicAdmissionLinks, { fetchPolicy: 'network-only' });
  return { getAdmissionLinks, admissionLinksData, admissionLinksError, admissionLinksLoading, refetchAdmissionLinks };
}

export function useGetPublicallyAvailableAdmissionLinks() {
  const [getPublicallyAvailableAdmissionLinks, { loading, data }] =
    useLazyQuery(getPublicallyAvailableAdmissionLinksQuery, {
      fetchPolicy: "network-only",
    });
  return { getPublicallyAvailableAdmissionLinks, data, loading };
}

export function useGetAdmissionLinks() {
  const [getAdmissionLinks, { loading, data, refetch }] = useLazyQuery(
    getAdmissionLinksQuery,
    { fetchPolicy: "network-only" }
  );
  return { getAdmissionLinks, data, loading, refetch };
}

export function useCreateAdmissionLink() {
  const [
    createAdmissionLink,
    { data: createAdmissionLinkData, error: createAdmissionLinkError },
  ] = useMutation(createAdmissionLinkMutation);
  return { createAdmissionLink, createAdmissionLinkData, createAdmissionLinkError };
}

export function useGetAdmissionLinkByRequestId() {
  const [
    getAdmissionLinkByRequestId,
    {
      loading: admissionLinkLoading,
      data: admissionLinkData,
      error: admissionLinkError,
      refetch: refetchAdmissionLink,
    },
  ] = useLazyQuery(getAdmissionLinkByRequestIdQuery, { fetchPolicy: 'network-only' });
  return { getAdmissionLinkByRequestId, admissionLinkData, admissionLinkError, admissionLinkLoading, refetchAdmissionLink };
}

export function useDeleteAdmissionLink() {
  const [deleteAdmissionLink, { data: deleteAdmissionLinkData, error: deleteAdmissionLinkError }] =
    useMutation(deleteAdmissionLinkMutation);
  return { deleteAdmissionLink, deleteAdmissionLinkData, deleteAdmissionLinkError };
}

export function useGenerateAdmissionReports() {
  const [generateAdmissionReports, { error: generateAdmissionReportsError }] = useMutation(generateAdmissionReportsMutation);
  return { generateAdmissionReports, generateAdmissionReportsError };
}

export function useSendCustomEmail() {
  const [sendCustomEmail, { error: sendCustomEmailError }] = useMutation(sendCustomEmailMutation);
  return { sendCustomEmail, sendCustomEmailError };
};

export function useGetAllInvitedDirectApplicants() {
  const [
    getAllInvitedDirectApplicants,
    {
      loading: allInvitedDirectApplicantsLoading,
      data: allInvitedDirectApplicantsData,
      error: allInvitedDirectApplicantsError,
    },
  ] = useLazyQuery(getAllInvitedDirectApplicantsQuery, {
    fetchPolicy: "network-only",
  });
  return {
    getAllInvitedDirectApplicants,
    allInvitedDirectApplicantsData,
    allInvitedDirectApplicantsError,
    allInvitedDirectApplicantsLoading,
  };
}

export function useGetPublicAdmissionDetails() {
  const [getPublicAdmissionDetails, { loading, data, error }] = useLazyQuery(publicAdmissionDetailsQuery, { fetchPolicy: 'network-only' });
  return { getPublicAdmissionDetails, loading, data, error };
}

export function useCreateApplicationByPublicURL() {
  const [createApplicationByPublicURL] = useMutation(createApplicationByPublicURLMutation);
  return { createApplicationByPublicURL };
};