import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { promoteStudentDirect } from 'src/form-json/assessment/promote-student-direct';
import { promoteStudentAdmission } from 'src/form-json/assessment/promote-student-admission';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import { validatorMapper } from '../../Forms/validatorMapper';
import { componentMapper } from '../../ddfElements';
import ModalSaveAndCancelButtonGroup from '../../ModalSaveAndCancelButtonGroup';
import { getBatchesToPromote } from 'src/graphql/promotions';
import {
  getFilteredAcademicYears,
  getOpenAdmissionsToPromote,
  getFilteredSubjectGroups,
} from 'src/components/services';
import { FormSchema } from '../../Forms/types';
import { mergeBatchClassLabel } from 'src/utils/utility';
import { DropdownOptionsType } from 'src/types';
import FormViewer from '../../Forms/DDFFormViewer/index.web';
import { graphqlQuery } from 'src/graphql/util';
import { getAdmissionByIdQuery } from 'src/graphql/admission';

interface Props {
  onSubmit: (formValues: any) => void;
  closeModal: () => void;
  isSubmitting: boolean;
  currentBatch: string;
  maintainGrNo: boolean;
  setMaintainGrNo: Dispatch<SetStateAction<boolean>>;
}

export default function PromoteStudentForm(props: Props) {
  const { onSubmit, closeModal, isSubmitting, currentBatch, maintainGrNo, setMaintainGrNo } = props;
  const [academicYearOptions, setAcademicYearOptions] = useState<DropdownOptionsType[]>([]);
  const [batchOptions, setBatchOptions] = useState<DropdownOptionsType[]>([]);
  const [subjectGroupOptions, setSubjectGroupOptions] = useState<DropdownOptionsType[]>([]);
  const [admissionConfigOptions, setAdmissionConfigOptions] = useState<DropdownOptionsType[]>([]);
  const [divisionOptions, setDivisionOptions] = useState<DropdownOptionsType[]>([]);
  const [formSchema, setFormSchema] = useState<FormSchema>();
  const [promotionType, setPromotionType] = useState<string>('DIRECT');
  const [initialValues, setInitialValues] = useState({
    promotionType: {
      label: promotionType,
      value: promotionType,
    }
  });

  useEffect(() => {
    if (currentBatch) {
      loadAdmissionConfig();
      loadAcademicYear();
    }
  }, [currentBatch]);

  useEffect(() => {
    if (promotionType === 'DIRECT' || (academicYearOptions && academicYearOptions.length)) {
      directFormSchema();
    } else {
      admissionFormSchema();
    }
  }, [
    academicYearOptions,
    batchOptions,
    subjectGroupOptions,
    admissionConfigOptions,
    divisionOptions,
    maintainGrNo,
  ]);

  function directFormSchema() {
    setFormSchema(
      promoteStudentDirect(
        academicYearOptions,
        batchOptions,
        subjectGroupOptions,
        divisionOptions,
        loadBatch,
        loadSubjectGroup,
        loadDivisions,
        loadSchema,
        maintainGrNo,
        handleMaintainGrNo,
      ),
    );
  }

  function admissionFormSchema() {
    setFormSchema(
      promoteStudentAdmission(
        admissionConfigOptions,
        subjectGroupOptions,
        divisionOptions,
        loadSchema,
        loadAdmissionSubjectGroupsAndDivisions,
        maintainGrNo,
        handleMaintainGrNo,
      ),
    );
  }

  function clearDirectValues() {
    setAcademicYearOptions([]);
    setBatchOptions([]);
    setSubjectGroupOptions([]);
    setDivisionOptions([]);
  }

  const loadSchema = (promotionType: string) => {
    setPromotionType(promotionType);
    if (promotionType === 'DIRECT') {
      loadAcademicYear();
      setSubjectGroupOptions([]);
      setDivisionOptions([]);
      setMaintainGrNo(true);
    } else {
      clearDirectValues();
      setMaintainGrNo(false);
    }
  };

  function createFilter(limit: number, id: string, property: string) {
    let params = {
      limit: limit,
      filters: {
        [property]: {
          eq: id,
        },
      },
    };
    return params;
  }

  async function loadAcademicYear() {
    let param = {
      limit: 0,
    } as any;

    const academicYearResponse = await getFilteredAcademicYears(param);
    setAcademicYearOptions(academicYearResponse?.options);
  }

  async function loadBatch(academicYear: string) {
    const batchResponse = await getBatchesToPromote(currentBatch, academicYear);
    const options = mergeBatchClassLabel(batchResponse?.options);
    setBatchOptions(options);
  }

  async function loadDivisions(batchData: any) {
    const divisions = batchData?.divisionDetails?.map((divisionDetail: any) => ({
      label: divisionDetail?.division,
      value: divisionDetail?.division,
    }));
    setDivisionOptions(divisions);
  }

  async function loadSubjectGroup(batchData: any) {
    let param = createFilter(0, batchData?.value, 'batch');
    let subjectGroupResponse = await getFilteredSubjectGroups(param);
    setSubjectGroupOptions(subjectGroupResponse?.options);
  }

  async function loadAdmissionConfig() {
    const admissionResponse = await getOpenAdmissionsToPromote(currentBatch);
    setAdmissionConfigOptions(admissionResponse);
  }

  async function loadAdmissionSubjectGroupsAndDivisions(admissionId: string) {
    const admissionResponse = await graphqlQuery(getAdmissionByIdQuery, { id: admissionId }, false);
    if (admissionResponse?.data?.admission) {
      const subjectGroups = admissionResponse.data.admission?.subjectGroups?.map((item: any) => item?.subjectGroup);
      const divisions = admissionResponse.data.admission?.batch?.divisionDetails?.map((item: any) => ({ label: item?.division, value: item?.division }));
      setSubjectGroupOptions(subjectGroups);
      setDivisionOptions(divisions || []);
    }
  }

  function handleMaintainGrNo(checked: boolean) {
    setMaintainGrNo(checked);
  }

  const PromoteStudentTemplate = useCallback(() => {
    return (
      <ContainerWrapper>
        <FormWrapper>
          <FormViewer
            schema={formSchema}
            isOnboarding={false}
            inModalView={true}
            paddingRight={0}
          />
        </FormWrapper>
        <ModalSaveAndCancelButtonGroup
          saveLabel={"assign.label"}
          cancelLabel={"cancel.label"}
          onCancel={closeModal}
          isSubmitting={isSubmitting}
        />
      </ContainerWrapper>
    );
  }, [formSchema, isSubmitting]);

  return (
    <>
      {formSchema && (
        <FormRenderer
          componentMapper={componentMapper}
          FormTemplate={PromoteStudentTemplate}
          schema={formSchema}
          onSubmit={onSubmit}
          validatorMapper={validatorMapper}
          initialValues={initialValues}
        />
      )}
    </>
  );
}

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 480px;
  padding-bottom: 24px;
`;

const ElementWrapper = styled.div<{ index: number }>`
  width: 480px;
  margin-bottom: 8px;
`;

const SpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 189px;
  width: 480px;
`;
