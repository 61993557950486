import {
  BooksIcon,
  CourseIcon,
  GroupIcon,
  StudentsIcon,
} from "src/assets/common/Icons";
import {
  DashboardCardContainer as Container,
  TDashboardCard,
  DashboardCardWrapper as Wrapper,
} from "../Common/CommonHelpers";

import DashboardColorBlock from "src/components/molecules/DashboardColorBlock";
import React from "react";
import { colors } from "src/styles/theme/styles";
import { defaultPagingURLSegment } from "src/components/molecules/Navigation/SidebarManifest";
import { useI18n } from "src/i18n/hooks";
import { useNavigate } from "src/routes/routing.web";
import { useTheme } from "styled-components";
import { width } from "src/constant/device";

function generateCards(): TDashboardCard[] {
  return [
    {
      label: "manageCourse.text",
      value: "manageCourse.text1",
      style: { backgroundColor: colors.purple },
      icon: CourseIcon,
      path: "/academics/courses/",
    },
    {
      label: "manageClasses.text",
      value: "manageClasses.text1",
      style: { backgroundColor: colors.primary },
      icon: StudentsIcon,
      path: "/academics/classes/",
    },
    {
      label: "manageSubjects.text",
      value: "manageSubjects.text1",
      style: { backgroundColor: colors.lightCrimson },
      icon: BooksIcon,
      path: "/academics/subjects/",
    },
    {
      label: "manageGroups.text",
      value: "manageGroups.text1",
      style: { backgroundColor: colors.deepSkyBlue },
      icon: GroupIcon,
      path: "/academics/subject-groups/",
    },
  ];
}

export const InstituteDashboardCards = () => {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const navigate = useNavigate();
  const size = width < 1450 ? 2 : 0;

  const cards: TDashboardCard[] = generateCards();

  return (
    <Container>
      {cards?.map((card) => (
        <Wrapper>
          <DashboardColorBlock
            style={card.style}
            heading={t(card.label)}
            desc={t(card.value as string)}
            onClick={() => navigate(`${card.path}${defaultPagingURLSegment}`)}
          >
            <card.icon rem={rem} size={size} />
          </DashboardColorBlock>
        </Wrapper>
      ))}
    </Container>
  );
};
