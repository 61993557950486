import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getAdmittedStudentsQuery = gql`
  query ($admissionId: String!) {
    admittedStudents(admissionId: $admissionId) {
      id
      personalDetails {
        firstName
        lastName
        fullName
      }
      academic {
        id
        division
      }
    }
  }
`;

export const getApplicationsQuery = gql`
  query(
    $applicantName: String
    $admission: String
    $filters: ApplicationFilterInput
    $limit: Int
    $skip: Int
    $sort: ApplicationSortInput
  ) {
    applications(
      applicantName: $applicantName
      admission: $admission
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
    ) {
      data {
        id
        scrollRefNo
        applicantType
        applicationId
        status
        allDocument {
          document {
            id
            name: documentName
            uri: documentUrl
            fileType
            alias
          }
          type {
            id
            name
          }
        }
        paymentFullStatus {
          status
          attempts
          refundAmount
          refundPayId
        }
        paymentDetails
        statusActivity {
          date
          status
          remarks
          user {
            fullName
          }
        }
        user {
          personalDetails {
            email
            firstName
            lastName
            fullName
            mobileNumber
            dob
            gender
            fathersName
            mothersName
          }
          academic {
            division
          }
          otherDetails {
            aadhaarId
            category {
              id
              name
            }
            isPhysicallyChallenged
          }
        }
        reApplied
        inHouseApplied
        cap {
          id
          capId
          capVerified
          document {
            id
            name: documentName
            uri: documentUrl
            fileType
            type
            category
          }
        }
        reqEducation {
          course
          averageMarks
          gradeObtained
        }
        subjectGroup {
          id
          name
        }
        admission {
          applicationFeeDetail {
            feeAmount
          }
          subjectGroups {
            subjectGroup {
              id
              name
            }
            fee {
              id
              name
              feeBooks {
                amount
                compositionId
                compositionName
                fee {
                  amount
                  components {
                    id
                    name
                    amount
                  }
                  id
                  institute
                  isGroup
                  name
                  parent
                  type
                }
                groupId
                groupName
                id
                institute
                name
              }
            }
          }
        }
        qualifiedRound {
          id
          name
        }
        fees {
          cancellationFeeCharges
          concession
          feeComposition
          installmentsPending
          installmentPayable
          installments {
            id
            amount
          }
        }
        qualifyingReferer
        quota {
          id
          alias
          name
        }
      }
      hasNextPage
      totalCount
    }
  }
`;

export const acceptApplicationMutation = gql`
  mutation ($id: String!, $category: String!) {
    acceptApplication(id: $id, category: $category) {
      id
      status
    }
  }
`;

export const rejectApplicationMutation = gql`
  mutation ($id: String!, $remarks: String) {
    rejectApplication(id: $id, remarks: $remarks) {
      id
      status
    }
  }
`;

export const blockApplicationMutation = gql`
  mutation ($id: String!, $remarks: String) {
    blockApplication(id: $id, remarks: $remarks) {
      applicationId
    }
  }
`;

export const unblockApplicationMutation = gql`
  mutation ($id: String!) {
    unblockApplication(id: $id) {
      id
      status
    }
  }
`;

export const assignDivisionMutation = gql`
  mutation ($payload: [RollNoAndDivInput!]!) {
    assignDivNRollNos(payload: $payload) {
      message
      status
    }
  }
`;

export const requestPaymentMutation = gql`
  mutation ($id: String!) {
    requestAdmissionFee(id: $id) {
      id
      status
    }
  }
`;

export const schedulePaymentMutation = gql`
  mutation ($id: String!) {
    comfirmAdmissionAndPostponeFee(id: $id) {
      id
      status
    }
  }
`;

export const qualifyApplicationsMutation = gql`
  mutation($applications: [QualifyAdmissionInput!]!, $round: String!) {
    qualifyApplications(applications: $applications, round: $round) {
      id
      name
    }
  }
`;

export const importApplicantsMutation = gql`
  mutation ($file: Upload!, $admissionId: String!) {
    importApplications(file: $file, admission: $admissionId) {
      status
      message
    }
  }
`;

export const importCAPApplicantsMutation = gql`
  mutation ($file: Upload!, $admissionId: String!) {
    importCAPApplicationIds(file: $file, admission: $admissionId) {
      status
      message
    }
  }
`;

export const importInHouseApplicantsMutation = gql`
  mutation ($file: Upload!, $admissionId: String!) {
    importInHouseApplicationIds(file: $file, admission: $admissionId) {
      status
      message
    }
  }
`;

export const importDirectApplicantsMutation = gql`
  mutation ($file: Upload!, $admission: String!, $subjectGroup: String!) {
    importDirectApplications(file: $file, admission: $admission, subjectGroup: $subjectGroup) {
      status
      message
    }
  }
`;

const completeAdmissionMutation = gql`
  mutation($id: String!) {
    completeAdmission(id: $id) {
      id
    }
  }
`;

const cancelAdmissionMutation = gql`
  mutation($id: String!, $remarks: String, $cancellationCharges: Float, $cancellationFee: String) {
    cancelAdmission(id: $id, remarks: $remarks, cancellationCharges: $cancellationCharges, cancellationFee: $cancellationFee) {
      id
    }
  }
`;

const applyConcessionMutation = gql`
  mutation($applicationId: String!, $compositionId: String!) {
    applyConcession(
      applicationId: $applicationId
      compositionId: $compositionId
    ) {
      id
    }
  }
`;

export const generateMeritListMutation = gql`
  mutation ($id: String!) {
    generateMeritListAndApply(id: $id) {
      list {
        totalCount
      }
    }
  }
`;

const inviteCAPApplicantsMutation = gql`
  mutation ($payload: ApplicationFillRequestInput!) {
    sendApplicationFillRequest(payload: $payload) {
      message
      status
    }
  }
`;

const inviteDirectApplicantMutation = gql`
  mutation($payload: ApplicationFillRequestInput!) {
    inviteDirectApplication(payload: $payload) {
      status
      message
    }
  }
`;

export const createPartialPaymentMutation = gql`
  mutation ($applicationId: String!, $amount: Float!) {
    createPartialPayment(applicationId: $applicationId, amount: $amount) {
      id
      status
    }
  }
`;

export const reconcileAdmissionPaymentsMutation = gql`
  mutation ($applicationId: String!) {
    reconcileAdmissionPayments(applicationId: $applicationId) {
      message
      status
    }
  }
`;

const updateScrollRefNoMutation = gql`
  mutation($applicationId: String!, $scrollRefNo: String!) {
    updateScrollRefNo(applicationId: $applicationId, scrollRefNo: $scrollRefNo) {
      message
      status
    }
  }
`;

export const updateRefundStatusMutation = gql`
  mutation(
    $limit: Int,
    $paymentDetail: CreatePaymentDetailInput,
    $paymentId: String!,
    $skip: Int,
    $sort: PaymentSortInput,
  ) {
    updateRefundStatus(
      limit: $limit,
      paymentDetail: $paymentDetail,
      paymentId: $paymentId,
      skip: $skip,
      sort: $sort,
    ) {
      message
      status
    }
  }
`;

const cancelAdmissionWithChargesMutation = gql`
  mutation($applicationId: String!, $amount: Float!, $remarks: String) {
    cancelAdmissionWithCharges(applicationId: $applicationId, amount: $amount, remarks: $remarks) {
      id
    }
  }
`;

const applyFeeCompositionMutation = gql`
  mutation($applicationId: String!, $compositionId: String!) {
    applyFeeComposition(applicationId: $applicationId, compositionId: $compositionId) {
      id
    }
  }
`;

export const getPaymentsQuery = gql`
  query(
    $filter: PaymentFilterInput
    $limit: Int
    $skip: Int
    $sort: PaymentSortInput
  ) {
    getPayments(
      filter: $filter
      limit: $limit
      skip: $skip
      sort: $sort
    ) {
      data {
        id
        date
        status
        mode
        orderId
        detail {
          note
          amount
          payId
          approvedBy
          payerBank
          transactionDoc {
            id
            name: documentName
            uri: documentUrl
          }
          paymentDate
          donatedBy
          donatedTo
        }
        refund {
          amount
        }
        statusActivity {
          date
          remarks
          status
        }
      }
    }
  }
`;

export const lastApplicationReconcilePaymentRequest = gql`
  query ($applicationId: String!) {
    getApplicationLastPaymentReconcileRequest(applicationId: $applicationId) {
      createdAt
      status
      errMessage
    }
  }
`;

export const getApplicationDetail = gql`
  query ($id: String!) {
    application (id: $id) {
      applicantType
      freeshipApplied
      admission {
        subjectGroups {
          subjectGroup {
            id
            name
          }
          fee {
            feeBooks {
              amount
            }
          }
        }
      }
      subjectGroup {
        id
      }
      status
      statusActivity {
        remarks
        user {
          fullName
        }
      }
      qualifiedRound {
        name
      }
      user {
        personalDetails {
          fullName
          dob
          gender
        }
        otherDetails {
          category {
            name
          }
          isPhysicallyChallenged
          aadhaarId
        }
        parentDetails {
          fatherDetail {
            name
          }
          motherDetail {
            name
          }
        }
      }
      educationDetails {
        educationDetail {
          docTypes {
            id
            name
          }
          documents {
            id
            name: documentName
            uri: documentUrl
            fileType
            type
            category
            typeRef
          }
          passedInFirstAttempt
          id
          allDocument {
            type {
              typeId: id
              name
              label: name
              category
            }
            document {
              id
              category
              name: documentName
              uri: documentUrl
              fileExtension
              fileType
              typeRef
            }
          }
          majorSubjectMarks {
            major
            marks
          }
          course
          gradeObtained
          institution
          university
          udiseNo
          qualificationRef {
            id
            name
            level
          }
          averageMarks
          marksObtained
          totalMarks
          paassingYear
          streams {
            value: id
            label: name
          }
        }
        qualificationName {
          id
          name
          level
        }
      }
    }
  }
`;

export function useGetApplications() {
  const [
    getApplications,
    { loading: applicationsLoading, data: applicationsData, error: applicationsError, refetch: refetchApplications },
  ] = useLazyQuery(getApplicationsQuery, { fetchPolicy: 'network-only' });
  return { getApplications, applicationsData, applicationsError, applicationsLoading, refetchApplications };
}

export function useAcceptApplication() {
  const [acceptApplication, { data: acceptApplicationData, error: acceptApplicationError }] =
    useMutation(acceptApplicationMutation);
  return { acceptApplication, acceptApplicationData, acceptApplicationError };
}

export function useRejectApplication() {
  const [rejectApplication, { data: rejectApplicationData, error: rejectApplicationError }] =
    useMutation(rejectApplicationMutation);
  return { rejectApplication, rejectApplicationData, rejectApplicationError };
}

export function useBlockApplication() {
  const [blockApplication, { data: blockApplicationData, error: blockApplicationError }] =
    useMutation(blockApplicationMutation);
  return { blockApplication, blockApplicationData, blockApplicationError };
}

export function useUnBlockApplication() {
  const [unblockApplication, { data: unblockApplicationData, error: unblockApplicationError }] =
    useMutation(unblockApplicationMutation);
  return { unblockApplication, unblockApplicationData, unblockApplicationError };
}

export function useRequestPayment() {
  const [requestPayment, { data: requestPaymentData, error: requestPaymentError }] =
    useMutation(requestPaymentMutation);
  return { requestPayment, requestPaymentData, requestPaymentError };
}

export function useSchedulePayment() {
  const [schedulePayment, { data: schedulePaymentData, error: schedulePaymentError }] =
    useMutation(schedulePaymentMutation);
  return { schedulePayment, schedulePaymentData, schedulePaymentError };
}

export function useGetAdmittedStudents() {
  const [
    getAdmittedStudents,
    { loading: admittedStudentsLoading, data: admittedStudentsData, error: admittedStudentsError, refetch: refetchAdmittedStudents },
  ] = useLazyQuery(getAdmittedStudentsQuery, { fetchPolicy: 'network-only' });
  return { getAdmittedStudents, admittedStudentsData, admittedStudentsError, admittedStudentsLoading, refetchAdmittedStudents };
}

export function useAssignDivision() {
  const [assignDivision, { data: assignDivisionData, error: assignDivisionError }] =
    useMutation(assignDivisionMutation);
  return { assignDivision, assignDivisionData, assignDivisionError };
}

export function useQualifyApplications() {
  const [qualifyApplications, { data: qualifyApplicationsData, error: qualifyApplicationsError }] =
    useMutation(qualifyApplicationsMutation);
  return { qualifyApplications, qualifyApplicationsData, qualifyApplicationsError };
}

export function useImportApplicant() {
  const [importApplicants, { data: importApplicantData, error: importApplicantError }] = useMutation(importApplicantsMutation);
  return { importApplicants, importApplicantData, importApplicantError };
}

export function useImportCAPApplicants() {
  const [importCAPApplicants, { data: importCAPApplicantData, error: importCAPApplicantError }] = useMutation(importCAPApplicantsMutation);
  return { importCAPApplicants, importCAPApplicantData, importCAPApplicantError };
}

export function useImportInhouseApplicants() {
  const [importInhouseApplicants, { data: importInhouseApplicantData, error: importInhouseApplicantError }] = useMutation(importInHouseApplicantsMutation);
  return { importInhouseApplicants, importInhouseApplicantData, importInhouseApplicantError };
}

export function useImportDirectApplicants() {
  const [importDirectApplicants, { data: importDirectApplicantData, error: importDirectApplicantError }] = useMutation(importDirectApplicantsMutation);
  return { importDirectApplicants, importDirectApplicantData, importDirectApplicantError };
}

export function useCompleteAdmission() {
  const [completeAdmission, { data: completeAdmissionData, error: completeAdmissionError }] = useMutation(completeAdmissionMutation);
  return { completeAdmission, completeAdmissionData, completeAdmissionError };
};

export function useCancelAdmission() {
  const [cancelAdmission, { data: cancelAdmissionData, error: cancelAdmissionError }] = useMutation(cancelAdmissionMutation);
  return { cancelAdmission, cancelAdmissionData, cancelAdmissionError };
};

export function useApplyConcession() {
  const [applyConcession, { data: applyConcessionData, error: applyConcessionError }] = useMutation(applyConcessionMutation);
  return { applyConcession, applyConcessionData, applyConcessionError };
};

export function useGenerateMeritList() {
  const [generateMeritList, { data: generateMeritListData, error: generateMeritListError }] =
    useMutation(generateMeritListMutation);
  return { generateMeritList, generateMeritListData, generateMeritListError };
}

export function useInviteCAPApplicants() {
  const [inviteCAPApplicants, { data: inviteCAPApplicantData, error: inviteCAPApplicantError }] = useMutation(inviteCAPApplicantsMutation);
  return { inviteCAPApplicants, inviteCAPApplicantData, inviteCAPApplicantError };
}

export function useInviteDirectApplicants() {
  const [inviteDirectApplicants, { data: inviteDirectApplicantData, error: inviteDirectApplicantError }] = useMutation(inviteDirectApplicantMutation);
  return { inviteDirectApplicants, inviteDirectApplicantData, inviteDirectApplicantError };
}

export function useCreatePartialPayment() {
  const [createPartialPayment] = useMutation(createPartialPaymentMutation);
  return { createPartialPayment };
}

export function useReconcileAdmissionPayments() {
  const [reconcileAdmissionPayment, { data: reconcileAdmissionPaymentData, error: reconcileAdmissionPaymentError }] =
    useMutation(reconcileAdmissionPaymentsMutation);
  return { reconcileAdmissionPayment, reconcileAdmissionPaymentData, reconcileAdmissionPaymentError };
}

export function useUpdateScrollRefNo() {
  const [
    updateApplicationScrollRefNo,
    {
      data: updateApplicationScrollRefNoData,
      error: updateApplicationScrollRefNoError,
    },
  ] = useMutation(updateScrollRefNoMutation);
  return {
    updateApplicationScrollRefNo,
    updateApplicationScrollRefNoData,
    updateApplicationScrollRefNoError,
  };
}

export function useUpdateRefundStatus() {
  const [updateRefundStatus, { data: updateRefundStatusData, error: updateRefundStatusError }] =
    useMutation(updateRefundStatusMutation);
  return { updateRefundStatus, updateRefundStatusData, updateRefundStatusError };
}

export function useCancelAdmissionWithCharges() {
  const [cancelAdmissionWithCharges, { data: cancelAdmissionWithChargesData, error: cancelAdmissionWithChargesError }] =
    useMutation(cancelAdmissionWithChargesMutation);
  return { cancelAdmissionWithCharges, cancelAdmissionWithChargesData, cancelAdmissionWithChargesError };
};

export function useApplyFeeComposition() {
  const [applyFeeComposition, { data: applyFeeCompositionData, error: applyFeeCompositionError }] =
    useMutation(applyFeeCompositionMutation);
  return { applyFeeComposition, applyFeeCompositionData, applyFeeCompositionError };
}

export function useGetPayments() {
  const [
    getPayments,
    { loading: paymentsLoading, data: paymentsData, error: paymentsError, refetch: refetchPayments },
  ] = useLazyQuery(getPaymentsQuery, { fetchPolicy: 'network-only' });
  return { getPayments, paymentsData, paymentsError, paymentsLoading, refetchPayments };
}

export function useGetApplicationById() {
  const [
    getApplicationById,
    { loading: applicationByIdLoading, data: applicationByIdData, error: applicationByIdError, refetch: refetchApplicationById },
  ] = useLazyQuery(getApplicationDetail, { fetchPolicy: 'network-only' });
  return { getApplicationById, applicationByIdData, applicationByIdError, applicationByIdLoading, refetchApplicationById };
}