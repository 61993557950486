import React from 'react'
import { useI18n } from 'src/i18n/hooks';
import { colors, fontWeight, fonts } from 'src/styles/theme/styles';
import { userInformation } from 'src/utils/manageState';
import styled from 'styled-components';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { getUserDetailForms } from 'src/form-json/user-details';
import { ViewMode } from '../../Forms/types';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
interface StepperLabelsProps {
  step: number;
}
export default function StepperLabels(props: StepperLabelsProps) {

  const { step } = props;
  const currentUserObject = userInformation();
  const { t } = useI18n();
  const userType = currentUserObject?.userType;

  const formSchemas = getUserDetailForms(userType)[ViewMode.EDIT];
  const totalSteps = formSchemas?.length;
  return (
    <StepsBlock>
      {formSchemas.map(({ schema }, i) => (
        <>
          <StepsList key={`stepper_${i}`} totalSteps={totalSteps}>
            <StepCount step={step} id={i}>
              {i < step ? (
                <Icon name='check' />
              ) : (
                i + 1
              )}
            </StepCount>
            <StepLabel>
              <NormaltextAtom
                value={t(schema.label)}
                lines={1}
                fontWeight={fontWeight[step === i ? 600 : 400]}
                color={step === i ? colors.primaryText : colors.secondaryText}
              />
            </StepLabel>
          </StepsList>
        </>
      ))}

    </StepsBlock>
  )
}

interface StepProps {
  step: number;
  id: number;
}
const StepsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 35px;
`;

const StepsList = styled.div<{ totalSteps: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: ${fonts.regular};
  color: ${colors.secondaryText};
  position: relative;
  width: 15%;
  text-align: center;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    left: 65%;
    top: 15px;
    width: ${({ totalSteps }) => totalSteps == 4 ? '156%' : '112%'};
    height: 1px;
    background: ${colors.lighterBorder};
    @media all and (max-width: 991px) {
      left: 71%;
      width: 100%;
    }
  }
`;

const StepCount = styled.text<StepProps>`
  font-size: 0.75rem;
  border-radius: 50%;
  color: #fff;
  background-color: ${({ step, id }) => id <= step ? colors.primary : colors.lighterBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  width: 32px;
  height: 32px;
`;

const StepLabel = styled.div``;
