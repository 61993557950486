import React, { useState, useEffect, useCallback } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import CourseForm from '../../molecules/Academics/CourseForm.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import {
  getCourseSettingsQuery,
  getCoursesQuery,
  useAddCourse,
  useCreateCourseSettings,
  useDeleteCourse,
  useUpdateInstituteCourse,
} from '../../../graphql/academics/courses';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'src/routes/routing.web';
import {
  AUTOCOMPLETE_MODULE,
  DURATION_TYPE,
  EMPLOYEE_TYPES,
  ERROR,
  limit,
  SUCCESS,
  USER_TYPE,
} from '../../../constant';
import { useUpdateCourse } from '../../../graphql/academics/courses';
import { useI18n } from 'src/i18n/hooks';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, createSortFilter, debounce } from 'src/components/services';
import { getCourseFilter } from 'src/components/services/filters';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';
import { Tooltip } from '@mui/material';
import styled from 'styled-components/native';
import Filter from 'src/components/molecules/Filter/index.web';
import { height, width } from 'src/constant/device';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import CourseSettingsForm from 'src/components/molecules/Academics/CourseSettingsForm';
import { RegisterIcon } from 'src/components/atoms/ActionIcons';

const maxHeight = 440;
const headCells1 = [
  {
    id: 'boards/university',
    label: 'boardUniversities.label1',
    align: 'left',
    disableSorting: true,
    sort: false,
    ascend: true,
    isSorted: false,
  },
  {
    id: 'course_name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'alias',
    isSorted: false,
  },
  {
    id: 'stream',
    label: 'stream.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'duration',
    label: 'duration.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: 'actions',
    align: 'right',
    disableSorting: true,
  },
];

export default function Courses() {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const [canClick, setCanClick] = useState(true);
  const [headCells, setHeadCells] = useState(headCells1);
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const { page = 1, dataLimit = limit }: any = useParams();
  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  let searchValue = '' as string;

  const { setHeading } = useHeaderTitle();
  const { filters, setFilter } = useFilter();
  const [modalState, handleModal] = useState(false);
  const [editCourseData, setCourseData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [filterHeight, setFilterHeight] = useState(0);
  const [currFilter, setCurrFilter] = useState<any>();
  const [courseSettingsModal, setCourseSettingsModal] = useState<boolean>(false);
  if (filters && filters?.course?.on) {
    searchValue = filters?.course?.search;
  }

  const { mutate: updateCourses } = useUpdateCourse();
  const { UpdateInstituteCourse } = useUpdateInstituteCourse();

  const [searching, setSearchingCourse] = useState(searchValue);
  const [getCourses, { data: courseData, refetch: refetchCourses, loading: coursesLoading }] =
    useLazyQuery(getCoursesQuery, {
      fetchPolicy: 'network-only',
    });

  const row: any = courseData?.courses?.data;
  const { setAlertDetails } = useAlertSystem();
  const currentUserObject = userInformation();
  const { userType, userDetail } = currentUserObject;
  const { mutate: addCourses } = useAddCourse(currFilter);
  const { deleteCourse } = useDeleteCourse(currentPage, searchValue, rowLimit, editCourseData?.id);
  const { createCourseSettings } = useCreateCourseSettings();
  const [totalCount, setTotalCount] = useState(courseData?.courses?.totalCount ?? null);
  const [courseId, setCourseId] = useState<string>('');

  useEffect(() => {
    const count = courseData?.courses?.totalCount;
    if (count) {
      setTotalCount(count);
    }
  }, [courseData?.courses?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  const filterItems = [
    {
      id: 1,
      label: 'Board/Universities',
      type: 'string',
      localField: 'university',
      propName: 'name', // lookup will need propName
      filterType: 'lookup',
    },
    { id: 2, label: 'Course Name', type: 'string', localField: 'alias', filterType: 'filter' },
    {
      id: 3,
      label: 'Stream',
      type: 'string',
      localField: 'stream',
      propName: 'name', // lookup will need propName
      filterType: 'lookup',
    },
    {
      id: 4,
      label: 'Course Duration',
      type: 'number',
      localField: 'duration',
      filterType: 'filter',
    },
    // { id: 7, label: 'Active', type: 'boolean', filterType: 'filter',localField: 'active' },
    // { id: 8, label: 'Created At', type: 'calendar', filterType: 'filter', localField: 'createdAt' },
  ];

  useEffect(() => {
    setSearchingCourse(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setTitle();
    if (isEditModal && modalState) {
      setFormField();
    }
  }, [modalState]);

  function setTitle() {
    setHeading([
      {
        text: t('courses.label'),
        url: '',
      },
    ]);
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
  );

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, headCells, rowLimit]);

  const handleFilters = (filter: any) => {
    const courseFilter = {
      limit: rowLimit,
      skip: (currentPage - 1) * rowLimit,
      ...filter,
    };
    setCurrFilter(courseFilter);
    getCourses({ variables: courseFilter });
  };

  function handleFetch() {
    let filters = getCourseFilter(searchValue);

    const sortCriteria = createSortFilter(headCells);

    let courseFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters, sortCriteria);
    setCurrFilter(courseFilter);
    getCourses({ variables: courseFilter });
  }

  function handleRefetch() {
    const filtersVal = getCourseFilter(searchValue);

    const sortCriteria = createSortFilter(headCells);

    const courseFilter = createSearchFilter(
      rowLimit,
      (currentPage - 1) * rowLimit,
      filtersVal,
      sortCriteria,
    );
    setCurrFilter(courseFilter);
    refetchCourses({ variables: courseFilter });
  }

  const editCourseModal = (selectedCourse: any) => {
    setCourseData(selectedCourse);
    hideAlert();
    setIsEditModal(true);
    handleModal(true);
  };

  function closeCourseSettings() {
    setCourseSettingsModal(false);
    setCourseId('');
    resetCourseSettingsForm();
    clearErrors();
  }

  function resetCourseSettingsForm() {
    setValue('registration_no_prefix', null);
    setValue('registration_no', null);
  }

  const handleCourseSettings = async (item: any) => {
    setCourseId(item?.id);
    setCourseSettingsModal(true);
  };

  const handleCreateCourseSettings = async (formData: any) => {
    setCanClick(false);
    try {
      if (formData?.registration_no_prefix && !formData?.registration_no) {
        throw new Error('Starting Number is required to accompany prefix');
      }
      const courseSettingsPayload = {
        course: courseId,
        grNoPrefix: formData?.registration_no_prefix || null,
        startingGRNo: parseFloat(formData?.registration_no) || null,
      }
      const response = await createCourseSettings({
        variables: { payload: courseSettingsPayload },
        refetchQueries: [
          {
            query: getCourseSettingsQuery,
            variables: {
              course: courseId,
              institute: userDetail?.institute,
            },
          },
        ],
      });
      if (response?.data?.createCourseSetting) {
        setCanClick(true);
        closeCourseSettings();
        setAlertDetails({ message: ADD_SUCCESS.ASSIGN_REGISTRATION, level: SUCCESS });
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  const deleteCourseModal = (selectedCourse: any) => {
    setCourseData(selectedCourse);
    setDeleteOverlay(true);
  };

  const addCourseModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  const closeModal = () => {
    handleModal(false);
    resetForm();
  };

  function setFormField() {
    let stream = editCourseData?.stream;
    let university = editCourseData?.university;
    setValue('course_code', editCourseData?.code);
    setValue('course_name', editCourseData?.name);
    setValue('course_alias', editCourseData?.alias);
    setValue('duration', editCourseData?.duration);
    setValue('stream', { label: stream?.name, value: stream?.id });
    setValue('university', { label: university?.name, value: university?.id });
    setValue('duration_type', {
      label: DURATION_TYPE[editCourseData?.durationType],
      value: editCourseData?.durationType,
    });
  }

  function resetForm() {
    setValue('course_code', '');
    setValue('course_name', '');
    setValue('course_alias', '');
    setValue('duration', '');
    setValue('stream', null);
    setValue('university', null);
    setValue('duration_type', null);
    clearErrors();
  }

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  interface addCourseData {
    stream: {
      value: string;
    };
    university: {
      value: string;
    };
    course_code: string;
    course_name: string;
    course_alias: string;
    duration: string;
    duration_type: {
      value: string;
    };
  }

  interface editCourseData {
    id: string;
    stream: {
      value: string;
    };
    university: {
      value: string;
    };
    course_code: string;
    course_name: string;
    course_alias: string;
    duration: string;
    duration_type: {
      value: string;
    };
  }

  const handleAddCourse = async (formdata: addCourseData) => {
    setCanClick(false);
    let courseObject = {
      stream: formdata.stream?.value,
      university: formdata.university?.value,
      code: formdata.course_code,
      name: formdata.course_name,
      alias: formdata.course_alias,
      duration: parseInt(formdata?.duration),
      durationType: formdata?.duration_type?.value,
    };

    try {
      let addCourseData = await addCourses({
        variables: { payload: courseObject },
      });
      if (addCourseData.data) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.COURSE, level: SUCCESS });
        handleRefetch();
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateCourse = async (editdata: editCourseData) => {
    setCanClick(false);
    if (userType === USER_TYPE.EDBA_ADMIN) {
      adminCourseUpdation(editdata);
    } else {
      instituteCourseUpdation(editdata);
    }
  };

  async function instituteCourseUpdation(editdata: editCourseData) {
    let courseObject = {
      moduleId: editCourseData?.id,
      name: editdata?.course_name,
      alias: editdata?.course_alias,
    };

    try {
      const updateCourseData = await UpdateInstituteCourse({
        variables: { payload: courseObject },
      });

      if (updateCourseData?.data) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.COURSE, level: SUCCESS });
        handleRefetch();
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  async function adminCourseUpdation(editdata: editCourseData) {
    let courseObject = {
      id: editCourseData?.id,
      name: editdata?.course_name,
      university: editdata?.university?.value,
      stream: editdata?.stream?.value,
      code: editdata?.course_code,
      alias: editdata?.course_alias,
      duration: parseInt(editdata?.duration),
      durationType: editdata?.duration_type?.value,
    };

    try {
      const updateCourseData = await updateCourses({
        variables: { payload: courseObject },
      });

      if (updateCourseData?.data) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.COURSE, level: SUCCESS });
        handleRefetch();
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  const handleDeleteCourse = async () => {
    setCanClick(false);
    try {
      let deleteResponse = await deleteCourse({ variables: { id: editCourseData?.id } });
      if (deleteResponse?.data?.deleteCourse?.status === 'success') {
        setDeleteOverlay(false);
        setAlertDetails({ message: DELETE_SUCCESS.COURSE, level: SUCCESS });
        handleRefetch();
      } else {
        throw new Error(
          deleteResponse?.data?.deleteCourse?.message || t('error.deleteCourse.message'),
        );
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setDeleteOverlay(false);
    }
    setCanClick(true);
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.COURSE]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchCourses(course: string) {
    let isClear = course?.length === 0;
    persistSearch(!isClear, course);
  }

  const delayedQuery = useCallback(
    debounce(q => searchCourses(q), 500),
    [],
  );
  const handleSearch = (course: string) => {
    setSearchingCourse(course);
    delayedQuery(course);
  };

  return (
    <>
      <LoadContentWrapper>
        <FlexRowView isEdbaAdmin={userType === USER_TYPE.EDBA_ADMIN}>
          <SearchWrapper>
            <Search
              id="courseSearch"
              handleChange={handleSearch}
              value={searching}
              label={t('searchCourse.text')}
              height={32}
              width={280}
            />
          </SearchWrapper>

          <FilterWrapper
            filterHeight={filterHeight}
            isEdbaAdmin={userType === USER_TYPE.EDBA_ADMIN}>
            <Filter
              filterItems={filterItems}
              handleFilters={handleFilters}
              setFilterHeight={setFilterHeight}
              searchValue={searchValue}
            />
          </FilterWrapper>

          {userType === USER_TYPE.EDBA_ADMIN ? (
            <AddCourseWrapper>
              <SecondaryBtn label={t('addCourse.text')} onPress={addCourseModal} />
            </AddCourseWrapper>
          ) : null}
        </FlexRowView>

        <TblContainer height={'100%'}>
          <TblHead />
          <>
            {!coursesLoading ? (
              <>
                {recordsAfterPagingAndSorting()?.length > 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                      <TableRows key={i}>
                        <TableCells value={item?.university?.name} />
                        <TableCells value={`${item?.alias} (${item?.name})`} />
                        <TableCells value={item?.stream?.name} />
                        <TableCells
                          value={`${item?.duration} ${DURATION_TYPE[item?.durationType]}`}
                        />
                        <TableActions align="right">
                          <TableActionView>
                            <Tooltip title="Edit Course">
                              <TouchableOpacity onPress={() => editCourseModal(item)}>
                                <FontAwesomeIcon
                                  icon={faPencil}
                                  color={theme?.table?.editIconColor}
                                />
                              </TouchableOpacity>
                            </Tooltip>

                            {userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) && (
                              <RegisterIcon onPress={() => handleCourseSettings(item)} />
                            )}

                            {userType === USER_TYPE.EDBA_ADMIN ? (
                              <IconView>
                                <Tooltip title="Delete Course">
                                  <TouchableOpacity onPress={() => deleteCourseModal(item)}>
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      color={theme?.table?.deleteIconColor}
                                    />
                                  </TouchableOpacity>
                                </Tooltip>
                              </IconView>
                            ) : null}
                          </TableActionView>
                        </TableActions>
                      </TableRows>
                    ))}
                  </TableBody>
                ) : (
                  <NoRecordsFound
                    colspan={7}
                    maxHeight={0.6 * height}
                  />
                )}
              </>
            ) : (
              <SpinnerWrapper>
                <LoaderSpinner />
              </SpinnerWrapper>
            )}
          </>
        </TblContainer>

        <Pagination
          pathName={'academics/courses'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          hidePagination={totalCount <= rowLimit}
        />
      </LoadContentWrapper>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={() => setDeleteOverlay(false)}
        modalVisible={deleteOverlay}
        handleSave={handleDeleteCourse}
        Headerpopup="deleteCourse.text">
        {t('deleteCourse.warning')}
      </DeleteModal>

      <NormalModal
        isSubmitting={!canClick}
        width={578}
        setModalVisible={closeModal}
        onAlert={null}
        modalVisible={modalState}
        handleSave={handleSubmit(isEditModal ? handleUpdateCourse : handleAddCourse)}
        Headerpopup={isEditModal ? t('editCourse.text') : t('addCourse.text')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}>
        <CourseForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          editCourseData={isEditModal ? editCourseData : {}}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        width={578}
        setModalVisible={closeCourseSettings}
        modalVisible={courseSettingsModal}
        handleSave={handleSubmit(handleCreateCourseSettings)}
        Headerpopup={t('assignRegistration.label')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={'save.label'}
        contentStyles={{ minHeight: 188, paddingBottom: 24 }}>
        <CourseSettingsForm
          control={control}
          errors={errors}
          setValue={setValue}
          watch={watch}
          type={'course'}
          id={courseId}
        />
      </NormalModal>
    </>
  );
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${props => (props.isEdbaAdmin ? 'space-between' : 'flex-start')};
  z-index: 10;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  margin-right: 8px;
`;

const FilterWrapper = styled.View`
  left: 0;
  flex: 1;
  align-items: flex-start;
  height: ${props => props.filterHeight * 32}px;
  width: ${props => (props.isEdbaAdmin ? (width > 1700 ? 75 : 72) : width > 1700 ? 80 : 80)}%;
`;

const AddCourseWrapper = styled.View`
  flex: 1;
  align-items: flex-end;
  max-width: 140px;
  min-width: 112px;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 13px;
`;

const IconView = styled.View`
  margin-left: 13px;
`;
const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
