import { gql, useLazyQuery } from '@apollo/client';

export const getLogsQuery = gql`
  query (
    $filters: ActivityAuditFilterInput
    $limit: Int
    $skip: Int
    $sort: ActivityAuditSortInput
  ) {
    ActivityAudits(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        requestId
        user {
          personalDetails {
            fullName
          }
        }
        action
        status
        timestamp
        device
        module
      }
      hasNextPage
      totalCount
    }
  }
`;

export function useGetLogs() {
  const [
    getLogs,
    { loading: logsLoading, data: logsData, error: logsError, refetch: refetchLogs },
  ] = useLazyQuery(getLogsQuery, { fetchPolicy: 'network-only' });
  return { getLogs, logsData, logsError, logsLoading, refetchLogs };
}
