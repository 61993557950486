import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  PaymentDetailsData,
  PaymentStatusActivity,
  paymentFormData,
  paymentPayload,
} from './types';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import styled from 'styled-components/native';
import { getPaymentStatusColor } from './helpers';
import { useI18n } from 'src/i18n/hooks';
import { colors, fontWeight, fonts } from 'src/styles/theme/styles';
import { ExpandCollapseCell } from 'src/components/molecules/Table/TableAtom';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { TableCell, TableRow } from '@mui/material';
import TimelineWeb from 'src/components/atoms/Timeline/Timeline';
import { format } from 'date-fns';
import { ERROR, PAYMENT_MODE_LABEL, PaymentMode, PaymentStatusLabel, SUCCESS } from 'src/constant';
import CreateInfoItem from 'src/components/molecules/TextComponents/InfoItem';
import { DT } from 'src/constant/dateTime';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import EditPaymentForm from 'src/components/molecules/Payment/Institute/EditPaymentForm';
import { useForm } from 'react-hook-form';
import { createPaymentDetailObject } from 'src/utils/utility';
import ERROR_MSG from 'src/constant/error';
import { REFUND_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { useEditPaymentDetails, useRefundPayment } from 'src/graphql/payment';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useGetPayments, useUpdateRefundStatus } from 'src/graphql/admission/application';
import { resetPaymentFormValues } from 'src/components/molecules/Payment/Institute/utility';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';

interface Props {
  paymentIds: string[];
  setEditPaymentModal: Dispatch<SetStateAction<boolean>>;
  editPaymentModal: boolean;
  completeRefundModalState: boolean;
  setCompleteRefundModalState: Dispatch<SetStateAction<boolean>>;
}

interface LabelValue {
  label: string;
  value?: string;
  labelColor?: string;
}

const LabelValue = (props: LabelValue) => {
  const { label, value, labelColor } = props;
  return (
    <LabelValueWrapper>
      <MediumTextSelect
        value={`${label}:  `}
        color={labelColor ? labelColor : colors.primaryText}
      />
      {value && <MediumTextSelect value={value} />}
    </LabelValueWrapper>
  );
};

export default function PaymentDetailsModal(props: Props) {
  const {
    paymentIds,
    editPaymentModal,
    setEditPaymentModal,
    completeRefundModalState,
    setCompleteRefundModalState,
  } = props;
  const { t } = useI18n();
  let { setAlertDetails } = useAlertSystem();
  const [trow, setT] = useState<boolean>(false);
  const [current, setCurrent] = useState<number | null>(null);
  const [canClick, setCanClick] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [paymentDetailsData, setPaymentDetailsData] = useState<PaymentDetailsData[]>([]);
  const [refundConfirmationModal, setRefundConfirmatioModal] = useState<boolean>(false);
  const refundStatus = ['REFUND_IN_PROCESS', 'REFUND_COMPLETED', 'REFUND_CANCELLED'];
  const { getPayments, paymentsData, paymentsLoading, refetchPayments } = useGetPayments();
  const { editPaymentDetails } = useEditPaymentDetails();
  const { refundPayment } = useRefundPayment();
  const { updateRefundStatus } = useUpdateRefundStatus();

  useEffect(() => {
    if (paymentIds && paymentIds.length > 0) {
      const param = {
        limit: 0,
        filter: {
          id: { in: paymentIds },
        },
      };
      getPayments({ variables: param });
    }
  }, [paymentIds]);

  useEffect(() => {
    if (paymentsData && paymentsData?.getPayments?.data) {
      setPaymentDetailsData(paymentsData.getPayments.data);
    }
  }, [paymentsData]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
    setError,
  } = useForm();

  function collapseFn() {
    setT(false);
    setCurrent(null);
  }

  function expandFn(i: number) {
    setT(true);
    setCurrent(i);
  }

  const createStatusContent = (statusActivity: PaymentStatusActivity[]) => {
    return statusActivity.map((item: PaymentStatusActivity) => {
      const color = getPaymentStatusColor(PaymentStatusLabel[item?.status]);
      return {
        color,
        renderContent: (
          <ItemWrapper>
            <NormalTextSelect
              value={PaymentStatusLabel[item?.status].toUpperCase()}
              color={color}
              fontFamily={fonts.semibold}
              lineHeight={1.78}
            />
            <NormalTextSelect value={format(new Date(item?.date), DT.DATE_TIME_FORMAT_12_HOUR)} />
            <NormalTextSelect value={item?.remarks} color={colors.secondaryText} />
          </ItemWrapper>
        ),
      };
    });
  };

  const closePaymentModal = () => {
    resetPaymentFormValues(setValue);
    setEditPaymentModal(false);
    clearErrors();
    setCurrentItem(null);
  };

  const handleEditPaymentModal = (item: any) => {
    setCurrentItem(item);
    setEditPaymentModal(true);
  };

  async function handleEditAdmissionPayment(formData: paymentFormData) {
    setCanClick(false);
    try {
      let payload: paymentPayload = {
        paymentId: currentItem?.id,
        paymentDetail: {
          mode: formData?.mode?.value,
        },
      };

      payload = await createPaymentDetailObject(formData, payload, true, true);
      const response = await editPaymentDetails({ variables: payload });

      if (response?.data?.updatePaymentDetails) {
        if (refetchPayments) refetchPayments();
        setAlertDetails({ message: UPDATE_SUCCESS.PAYMENT, level: SUCCESS });
        closePaymentModal();
        setCanClick(true);
      }
    } catch (error: any) {
      setAlertDetails({
        message: ERROR_MSG.PAYMENT_UNSUCCESSFUL + ': ' + error.message,
        level: ERROR,
      });
    }
    setCanClick(true);
  }

  function handleRefundConfirmationModal(item: any) {
    setCurrentItem(item);
    setRefundConfirmatioModal(true);
  }

  function closeRefundConfirmationModal() {
    setCurrentItem(null);
    setRefundConfirmatioModal(false);
  }

  async function handleRefundPayment() {
    setCanClick(false);
    try {
      const response = await refundPayment({ variables: { paymentId: currentItem?.id } });
      if (response?.data?.refundPayment) {
        if (refetchPayments) refetchPayments();
        setAlertDetails({ message: REFUND_SUCCESS.REFUND_INITIATED, level: SUCCESS });
        setCanClick(true);
        closeRefundConfirmationModal();
      }
    } catch (error: any) {
      setAlertDetails({
        message: error.message,
        level: ERROR,
      });
    }
    setCanClick(true);
  }

  function handleCompleteRefundModal(item: any) {
    setCompleteRefundModalState(true);
    setCurrentItem(item);
  }

  async function handleCompleteRefund(formValues: paymentFormData) {
    setCanClick(false);
    try {
      let payload: paymentPayload = {
        paymentId: currentItem?.id,
        paymentDetail: {
          mode: formValues?.mode?.value,
        },
      };

      payload = await createPaymentDetailObject(formValues, payload);
      const response = await updateRefundStatus({
        variables: payload,
      });
      if (response?.data?.updateRefundStatus?.status === 'success') {
        closeCompleteRefundModal();
        setCanClick(true);
        setAlertDetails({ message: REFUND_SUCCESS.REFUND_COMPLETED, level: SUCCESS });
        if (refetchPayments) refetchPayments();
      } else {
        throw new Error(ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  function closeCompleteRefundModal() {
    resetPaymentFormValues(setValue);
    clearErrors();
    setCompleteRefundModalState(false);
    setCurrentItem(null);
  }

  return (
    <ParentContainer>
      {!paymentsLoading ? (
        <>
          {paymentDetailsData && paymentDetailsData.length > 0 ? (
            paymentDetailsData.map((item: PaymentDetailsData, index: number) => {
              return (
                <>
                  <TableRow key={`e${index}`}>
                    <TableCell style={styles.tableCell}>
                      <ModalTextContainer>
                        <ModalText
                          fontWeight={current === index ? fontWeight[600] : fontWeight[400]}>
                          {`${t('attempt.label', {
                            values: { attemptNo: index + 1 },
                          })} -`}
                        </ModalText>
                        <ModalText
                          color={getPaymentStatusColor(PaymentStatusLabel[item?.status])}
                          fontWeight={current === index ? fontWeight[600] : fontWeight[400]}>
                          {` ${PaymentStatusLabel[item?.status].toUpperCase()}`}
                        </ModalText>
                        <ModalText
                          fontWeight={
                            current === index ? fontWeight[600] : fontWeight[400]
                          }>{` (${format(
                            new Date(item?.date),
                            DT.DATE_TIME_FORMAT_12_HOUR,
                          )})`}</ModalText>
                      </ModalTextContainer>
                    </TableCell>
                    <ExpandCollapseCell
                      trow={trow}
                      current={current}
                      i={index}
                      classNames={''}
                      textValue={''}
                      CollapseFn={collapseFn}
                      ExpandFn={() => expandFn(index)}
                      size={28}
                      style={{ paddingRight: 0 }}
                    />
                  </TableRow>
                  {trow && current === index && (
                    <TableRow>
                      <TableCell colSpan={2} style={styles.nestedTableCell}>
                        <PaymentInfoWrapper>
                          <LabelValueWrapper>
                            <CreateInfoItem
                              label={t('amount.label')}
                              value={
                                refundStatus?.includes(item?.status)
                                  ? item?.refund?.amount
                                    ? `\u20B9 ${item.refund.amount}`
                                    : '-'
                                  : item?.detail?.amount
                                  ? `\u20B9 ${item.detail.amount}`
                                  : '-'
                              }
                              width={173}
                            />
                            <CreateInfoItem
                              label={t('mode.label')}
                              value={PAYMENT_MODE_LABEL[item?.mode]}
                              width={173}
                            />
                            <CreateInfoItem
                              label={t('orderId.label')}
                              value={item?.orderId}
                              width={173}
                            />
                          </LabelValueWrapper>
                          {item?.status === 'COMPLETED' && (
                            <>
                              <LabelValueWrapper>
                                <CreateInfoItem
                                  label={t('approveBy.label')}
                                  value={item?.detail?.approvedBy}
                                  width={173}
                                />
                                <CreateInfoItem
                                  label={t('transactionId.label')}
                                  value={item?.detail?.payId}
                                  width={173}
                                />
                              </LabelValueWrapper>
                              <LabelValueWrapper gap={16}>
                                {item?.mode !== PaymentMode.DIGITAL && (
                                  <EditActionWrapper>
                                    <SecondaryBtn
                                      onPress={() => handleEditPaymentModal(item)}
                                      label={t('editPayment.label')}
                                    />
                                  </EditActionWrapper>
                                )}
                                <EditActionWrapper>
                                  <SecondaryBtn
                                    onPress={() => handleRefundConfirmationModal(item)}
                                    label={t('refundPayment.label')}
                                    width={148}
                                  />
                                </EditActionWrapper>
                              </LabelValueWrapper>
                            </>
                          )}
                          {item?.status === 'REFUND_IN_PROCESS' && (
                            <LabelValueWrapper>
                              <EditActionWrapper>
                                <SecondaryBtn
                                  onPress={() => handleCompleteRefundModal(item)}
                                  label={t('completeRefund.label')}
                                  canLoad={!canClick}
                                />
                              </EditActionWrapper>
                            </LabelValueWrapper>
                          )}
                        </PaymentInfoWrapper>
                        {item?.statusActivity && item.statusActivity.length > 0 && (
                          <TimelineWrapper>
                            <TimelineWeb timelineData={createStatusContent(item.statusActivity)} />
                          </TimelineWrapper>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })
          ) : (
            <SpinnerView>
              <FontAwesomeIcon icon={faExclamationCircle} color={colors.secondaryText} size={48} />
              <ErrorMessageView>
                <MediumTextSelect
                  value={t('noDataAvailable.message1.text')}
                  color={colors.primaryText}
                  fontSize={2}
                />
                <MediumTextSelect value={t('noDataAvailable.message2.text')} />
              </ErrorMessageView>
            </SpinnerView>
          )}
        </>
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closePaymentModal}
        modalVisible={editPaymentModal}
        handleSave={handleSubmit(handleEditAdmissionPayment)}
        Headerpopup={t('editPayment.label')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="save.label"
        lineHeight={32}
        height={512}>
        <EditPaymentForm
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          totalPayableAmount={currentItem?.detail?.amount}
          isEdit={true}
          editPaymentDetails={currentItem}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeCompleteRefundModal}
        modalVisible={completeRefundModalState}
        handleSave={handleSubmit(handleCompleteRefund)}
        Headerpopup={t('completeRefund.label')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="save.label"
        width={720}
        lineHeight={32}
        height={512}>
        <EditPaymentForm
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          totalPayableAmount={Math.ceil(currentItem?.refund?.amount)}
          paymentModes={[
            PaymentMode.CASH,
            PaymentMode.CHEQUE,
            PaymentMode.DRAFT,
            PaymentMode.ONLINE,
            PaymentMode.OFFLINE_NEFT,
          ]}
        />
      </NormalModal>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={closeRefundConfirmationModal}
        modalVisible={refundConfirmationModal}
        handleSave={handleRefundPayment}
        deleteButtonColor={colors.primaryColor}
        deleteButtonText={'confirm.label'}
        Headerpopup={t('refundPayment.label')}>
          {t('refundPayment.warning.text')}
      </DeleteModal>
    </ParentContainer>
  );
}

const styles = {
  tableCell: {
    width: 680,
    paddingLeft: 0,
  },
  nestedTableCell: {
    width: 680,
    paddingLeft: 0,
    paddingRight: 0,
  },
};

const ParentContainer = styled.View`
  max-height: 580px;
`;

const LabelValueWrapper = styled.View<{ gap?: number }>`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props?.gap || 8}px;
`;

const ItemWrapper = styled.View`
  display: flex;
  flex-direction: column;
`;

const ModalText = styled.Text<{ color: string; fontWeight: string }>`
  font-size: 16px;
  line-height: 24px;
  font-family: ${fonts.regular};
  color: ${(props) => (props?.color ? props.color : colors.primaryText)};
  font-weight: ${(props) => `${props?.fontWeight}`};
`;

const ModalTextContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

const PaymentInfoWrapper = styled.View`
  display: flex;
  gap: 16px;
`;

const TimelineWrapper = styled.View`
  margin-top: 24px;
`;

const EditActionWrapper = styled.View`
  align-self: center;
`;

const SpinnerView = styled.View`
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorMessageView = styled.View`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;
