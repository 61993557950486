import React from 'react';
import { Linking, StatusBar, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { WebView } from 'react-native-webview';
import { showNativeError, showNativeSuccess } from 'src/components/molecules/NotificationWrapper';
import config from 'src/config/config.json';
import { isIOS } from 'src/constant/device';
import ERROR_MSG from 'src/constant/error';
import { useI18n } from 'src/i18n/hooks';

interface ApplicantionFeePaymentProps {
  navigation: any;
  route: {
    params: {
      htmlPage: any;
      callback: Function;
    };
  };
}

export default function ApplicationFeePayment(props: ApplicantionFeePaymentProps) {
  const INJECT_JS =
    'window.ReactNativeWebView.postMessage(document.getElementsByTagName("h5")[0].innerHTML)';
  const htmlPage = props.route.params.htmlPage;
  const callback = props.route.params.callback;
  const { t } = useI18n();
  const insets = useSafeAreaInsets();
  const statusBarHeight = isIOS ? insets.top : StatusBar.currentHeight;

  const makePayment = (data: any) => {
    let splitStr = data.split('|');
    if (splitStr[1].includes('encData')) {
      let splitEncData = splitStr[1].split('=');
      if (splitEncData[1] === 'cancelTransaction') {
        props.navigation.goBack();
      } else {
        const body = {
          encData: splitStr[1].split('=')[1],
          merchId: splitStr[2].split('=')[1],
          fCode: splitStr[3].split('=')[1],
        };
        fetch(`${config.Server.url.replace('/graphql', '')}/ndps/payment/native/verify`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        }).then((response) => response.json())
          .then((responseData) => {
            if (responseData) {
              if (responseData?.status === 'success') {
                props.navigation.goBack();
                callback();
                showNativeSuccess(t('fees.paid.text'));
              } else {
                errorHandling(ERROR_MSG.GENERIC_ERROR);
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            errorHandling(ERROR_MSG.GENERIC_ERROR);
          });
      }
    }
  };

  function errorHandling(errorMessage: any) {
    props.navigation.goBack();
    const message = typeof errorMessage === 'string' ? errorMessage : ERROR_MSG.GENERIC_ERROR;
    showNativeError(message);
  }

  return (
    <View style={styles(statusBarHeight).container}>
      <StatusBar barStyle="dark-content" />
      <WebView
        style={styles(statusBarHeight).webViewContainer}
        originWhitelist={['https://*', 'upi://*']}
        source={{ html: htmlPage }}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        startInLoadingState={true}
        onShouldStartLoadWithRequest={(request) => {
          let url = request.url;
          if (url.startsWith('upi:')) {
            Linking.openURL(url).catch((e) => {
              errorHandling('Error occured !! \nkindly check if you have upi apps installed or not !');
            });
            return false;
          }
          return true;
        }}
        onMessage={(event) => {
          if (
            event.nativeEvent.data.includes('encData') &&
            event.nativeEvent.url.indexOf('mobilesdk/param') > -1
          ) {
            makePayment(event.nativeEvent.data);
          }
        }}
        injectedJavaScript={INJECT_JS}
        onError={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          errorHandling(nativeEvent);
        }}
      />
    </View>
  );
}

const styles = (statusBarHeight?: number | null) => StyleSheet.create({
  container: {
    flex: 1,
    marginTop: statusBarHeight || 0,
  },
  webViewContainer: {
    flex: 1,
  },
});
