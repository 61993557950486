import * as React from 'react';
import Svg, { Defs, G, Path, Rect } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 24 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G clip-path="url(#clip0_5719_59934)">
        <Path
          d="M14.4546 0L20.4546 6L17.4546 6.54545L14.4546 6L13.9092 3L14.4546 0Z"
          fill="#188038"
        />
        <Path
          d="M14.4545 6V0H4.63636C3.73227 0 3 0.732273 3 1.63636V22.3636C3 23.2677 3.73227 24 4.63636 24H18.8182C19.7223 24 20.4545 23.2677 20.4545 22.3636V6H14.4545Z"
          fill="#34A853"
        />
        <Path
          d="M6.27271 9.27344V17.1825H17.1818V9.27344H6.27271ZM11.0454 15.8189H7.63634V13.9098H11.0454V15.8189ZM11.0454 12.5462H7.63634V10.6371H11.0454V12.5462ZM15.8182 15.8189H12.4091V13.9098H15.8182V15.8189ZM15.8182 12.5462H12.4091V10.6371H15.8182V12.5462Z"
          fill="white"
        />
      </G>
      <Defs>
        <clipPath id="clip0_5719_59934">
          <Rect width="17.4545" height="24" fill="white" transform="translate(3)" />
        </clipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
