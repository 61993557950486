import React, { useEffect, useRef, useState } from 'react';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import 'react-native-gesture-handler';
import linking from 'src/navigation/linking';
import { StyleSheet, StatusBar, Text, View, ImageBackground } from 'react-native';
import AuthNavigator from './AuthNavigator';
import MainDrawer from './Drawer';
import { isAuthenticated } from 'src/graphql/common';
import { onUserInfoChange } from 'src/i18n/apply-language';
import { colors } from 'src/styles/theme/styles';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import { userInformation } from 'src/utils/manageState';
import { useI18n } from 'src/i18n/hooks';
import OnBoardingNavigator from './onBoardingNavigator';
import FlashMessage from 'react-native-flash-message';
import { isIOS } from 'src/constant/device';
import { graphqlQuery } from 'src/graphql/util';
import { getLanguageKeyValueLastUpdateAt } from 'src/graphql/localization/key-values';
import { allowedModulesQuery } from 'src/graphql/institutes/module';
import { onboardingVisited, setAllowedModules, setCurrentUserOnboardingVisited, setOnboardingVisited } from 'src/components/services/storage-service';
import { getProfileSteps } from 'src/form-json/user-details';
import { isObject } from 'lodash';

type RouteProps = {
  theme: any;
};

const Routes: React.FC<RouteProps> = ({ theme }) => {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [onboardingStep, setOnboardingStep] = useState<number>(-1);
  const [onboarding, setOnboarding] = useState<boolean>(false);
  const { i18n } = useI18n();

  useEffect(() => {
    disabledScale();
  }, []);

  function disabledScale() {
    if (Text.defaultProps == null) Text.defaultProps = {};
    Text.defaultProps.allowFontScaling = false;
  }

  function handleAuthCallback(flag: boolean) {
    setAuthenticated(flag);
    setLoaded(!flag);
  }

  let [authenticated, setAuthenticated] = useState<boolean>(false);
  (async () => {
    try {
      authenticated = await isAuthenticated();
      console.log('Auth', authenticated);
      setAuthenticated(authenticated);
      if (authenticated == false) {
        setLoaded(true);
      }
    } catch (e) {
      setAuthenticated(false);
    }
  })();

  useEffect(() => {
    if (authenticated === true) {
      const userInfo = userInformation();
      Promise.all([
        graphqlQuery(allowedModulesQuery, {}),
        graphqlQuery(getLanguageKeyValueLastUpdateAt, {}),
        onboardingVisited()
      ]).then(([modules, lang, isOnboardingVisited]) => {
        setAllowedModules(modules?.data?.allowedModules?.modules);
        onUserInfoChange(i18n, userInfo, lang?.data?.getLanguageKeyValuesLastUpdatedAt, () => {
          const profileCompletion = userInfo?.userDetail?.profileCompletion;
          if (!profileCompletion || typeof profileCompletion == 'object') {
            if (!isOnboardingVisited) {
              let profileSteps = getProfileSteps(userInfo.userType);
              let incompleteStep = profileCompletion ? profileSteps.findIndex((stepName) => !profileCompletion[stepName]) : 0;
              if (incompleteStep !== -1) {
                console.log("incompleteStep", incompleteStep, profileCompletion);
                setOnboardingStep(incompleteStep);
                setOnboarding(true);
              }
            }
          }
          if (profileCompletion === true) {
            setCurrentUserOnboardingVisited(profileCompletion)
          }
          setLoaded(true);
        });
      })
    }
  }, [authenticated]);

  const onFinishCallback = () => {
    setOnboarding(false);
    setCurrentUserOnboardingVisited(true)
  };

  return (
    <NavigationContainer
      theme={theme}
      linking={linking}
      ref={navigationRef}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute();
        let { params }: any = currentRouteName;
        if (previousRouteName !== currentRouteName) {
          StatusBar.setBarStyle(params?.statusbar || 'light-content');
        }
      }}>
      {loaded ? (
        authenticated ? (
          onboarding ? (
            <OnBoardingNavigator step={onboardingStep} onFinishCallback={onFinishCallback} />
          ) : (
            <MainDrawer authCallback={handleAuthCallback} />
          )
        ) : (
          <AuthNavigator authCallback={handleAuthCallback} />
        )
      ) : (
        <ImageBackground
          source={require('src/assets/onboarding-screen/sign-up.png')}
          style={styles.root}>
          <LoaderSpinner color="tertiary" />
        </ImageBackground>
      )}
      <FlashMessage position="top" style={!isIOS && { paddingTop: 35 }} />
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: colors.primary,
    resizeMode: 'cover',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Routes;
