import {
  DashboardCardContainer as Container,
  TDashboardCard,
  DashboardCardWrapper as Wrapper,
} from "../Common/CommonHelpers";
import {
  HourglassIcon,
  InstituteIcon,
  StudentClassIcon,
  StudentGradIcon,
} from "../../../../assets/common/Icons";
import React, { useMemo, useState } from "react";

import DashboardColorBlock from "src/components/molecules/DashboardColorBlock";
import { colors } from "src/styles/theme/styles";
import { getInstitutesCountQuery } from "src/graphql/institutes";
import { useI18n } from "src/i18n/hooks";
import { useQuery } from "@apollo/client";
import { useTheme } from "styled-components";
import { width } from "src/constant/device";

function generateCards(count?: Record<string, number>): TDashboardCard[] {
  return [
    {
      label: "totalStudents.label",
      value: count?.students,
      style: { backgroundColor: colors.purple },
      icon: StudentGradIcon,
    },
    {
      label: "totalInstitutes.label",
      value: count?.institutes,
      style: { backgroundColor: colors.primary },
      icon: InstituteIcon,
    },
    {
      label: "newInstitutes.label",
      value: count?.newInstitutes,
      style: { backgroundColor: colors.lightCrimson },
      icon: StudentClassIcon,
    },
    {
      label: "instituteApplicants.label",
      value: count?.pendingInstitutes,
      style: { backgroundColor: colors.deepSkyBlue },
      icon: HourglassIcon,
    },
  ];
}

export const AdminDashboardCards = () => {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const { data } = useQuery(getInstitutesCountQuery, {
    variables: {},
  });
  const [cards, setCards] = useState<TDashboardCard[]>();
  const size = width < 1450 ? 2 : 0;
  useMemo(() => {
    setCards(generateCards(data?.instituteCounts));
  }, [data?.instituteCounts]);

  return (
    <Container>
      {cards?.map((card) => (
        <Wrapper>
          <DashboardColorBlock
            style={card.style}
            heading={t(card.label)}
            desc={card?.value?.toString() ?? ""}
            type={"admin"}
          >
            <card.icon rem={rem} size={size} />
          </DashboardColorBlock>
        </Wrapper>
      ))}
    </Container>
  );
};
