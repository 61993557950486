import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components';
import DropDown from 'src/components/atoms/DropDown/index.web';
import React, { useEffect } from 'react';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';

export const Select = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    meta: { error },
    options,
    setInitialValue,
    subscribeToValues,
    isDisabled,
    handleChange,
  } = useFieldApi(props);
  const formOptions = useFormApi();
  const { getState } = useFormApi();
  const { submitFailed } = getState();
  const { value, onChange } = input;
  const { t } = useI18n();

  useEffect(() => {
    if (setInitialValue) {
      setInitialValue(props, formOptions);
    }
  }, [])
  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <InputContainer>
          <DropDown
            value={value}
            header={t(label)}
            options={options}
            handleChange={(value: any) => {
              onChange(value);
              handleChange && handleChange(props, formOptions);
            }}
            disabled={isDisabled}
          />
          <ErrorMsg>{submitFailed && t(error)}</ErrorMsg>
        </InputContainer>
      )}
    </FormSpy>
  )
}
/*Styled - components*/

const InputContainer = styled.div`
  margin-bottom: 0;
`

const ErrorMsg = styled.div`
  font-family: ${fonts.regular};
  color: ${colors.errorColor};
  margin-top: 5px;
  font-size: 12px;
  min-height: 14px;
`;