import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props:any) {
  return (
    <Svg
      width={50}
      height={80}
      viewBox="0 0 50 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M42.5 0h-35C3.36 0 0 3.36 0 7.5v65C0 76.64 3.36 80 7.5 80h35c4.14 0 7.5-3.36 7.5-7.5v-65C50 3.36 46.64 0 42.5 0zM25 75c-2.766 0-5-2.234-5-5s2.234-5 5-5 5 2.234 5 5-2.234 5-5 5zm17.5-16.875A1.88 1.88 0 0140.625 60H9.375A1.88 1.88 0 017.5 58.125V9.375A1.88 1.88 0 019.375 7.5h31.25A1.88 1.88 0 0142.5 9.375v48.75z"
        fill="#2B78CA"
      />
      <Path
        d="M17.857 32.714a2.854 2.854 0 002.857-2.857A2.854 2.854 0 0017.857 27 2.854 2.854 0 0015 29.857a2.854 2.854 0 002.857 2.857zM32.143 27a2.854 2.854 0 00-2.857 2.857 2.854 2.854 0 002.857 2.857A2.854 2.854 0 0035 29.857 2.854 2.854 0 0032.143 27zM25 38.429c-3.59 0-6.964 1.58-9.268 4.339a2.14 2.14 0 00.277 3.018c.91.75 2.259.634 3.018-.277a7.767 7.767 0 0111.946 0 2.15 2.15 0 003.018.277 2.148 2.148 0 00.277-3.018A12.039 12.039 0 0025 38.428z"
        fill="#FF9000"
      />
    </Svg>
  )
}

export default SvgComponent
