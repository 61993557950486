import * as React from "react";

import Svg, { Path } from "react-native-svg";

function SvgComponent(props: any) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M19.3076 13.6694C18.4429 12.9372 17.3838 13.2217 16.8524 13.6208L15.1753 14.8838H15.0815C15.1022 13.8305 14.2578 13 13.1856 13H9.18585C8.397 13 7.61926 13.2611 7.00262 13.7333L6.21433 14.3372H4.66663C4.29998 14.336 4 14.636 4 15.0027C4 15.369 4.29998 15.669 4.66663 15.669L6.66653 15.6674L7.81258 14.7946C8.20145 14.4963 8.68754 14.3366 9.18779 14.3366H13.1876C13.962 14.3366 13.9931 15.4541 13.1563 15.4541H11.4941C11.2857 15.4541 11.1121 15.6241 11.1121 15.8357C11.1121 16.0473 11.2858 16.221 11.4941 16.221H15.2328C15.5025 16.221 15.765 16.1332 15.9805 15.9711L17.6821 14.6906C17.9113 14.5206 18.2515 14.5067 18.4704 14.6906C18.7515 14.9266 18.7308 15.3325 18.446 15.5442L15.6517 17.6402C15.4364 17.7998 15.1759 17.89 14.9051 17.89H4.66663C4.29998 17.8886 4 18.1886 4 18.5553C4 18.9219 4.29998 19.2219 4.66663 19.2219H14.9106C15.4661 19.2219 16.0044 19.0415 16.4455 18.7083L19.2453 16.602C19.7073 16.2551 19.9814 15.7276 19.9989 15.1585C20.0186 14.5888 19.7714 14.0444 19.3076 13.6694Z"
        fill="black"
        fill-opacity="0.6"
      />
      <Path
        d="M14.8287 5.99648C15.0565 5.76866 15.0565 5.39869 14.8287 5.17087C14.6009 4.94304 14.2309 4.94304 14.0031 5.17087L8.17087 11.0031C7.94304 11.2309 7.94304 11.6009 8.17087 11.8287C8.39869 12.0565 8.76866 12.0565 8.99648 11.8287L14.8287 5.99648ZM10.3342 6.16781C10.3342 5.85845 10.2114 5.56176 9.9926 5.34301C9.77385 5.12426 9.47716 5.00137 9.16781 5.00137C8.85845 5.00137 8.56176 5.12426 8.34301 5.34301C8.12426 5.56176 8.00137 5.85845 8.00137 6.16781C8.00137 6.47716 8.12426 6.77385 8.34301 6.9926C8.56176 7.21135 8.85845 7.33424 9.16781 7.33424C9.47716 7.33424 9.77385 7.21135 9.9926 6.9926C10.2114 6.77385 10.3342 6.47716 10.3342 6.16781ZM15 10.8336C15 10.5242 14.8771 10.2275 14.6584 10.0088C14.4396 9.79001 14.1429 9.66712 13.8336 9.66712C13.5242 9.66712 13.2275 9.79001 13.0088 10.0088C12.79 10.2275 12.6671 10.5242 12.6671 10.8336C12.6671 11.1429 12.79 11.4396 13.0088 11.6584C13.2275 11.8771 13.5242 12 13.8336 12C14.1429 12 14.4396 11.8771 14.6584 11.6584C14.8771 11.4396 15 11.1429 15 10.8336Z"
        fill="black"
        fill-opacity="0.6"
      />
    </Svg>
  );
}

export default SvgComponent;
