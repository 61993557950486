import {
  DeleteScheduleAction,
  EditScheduleActions,
} from "./SchedulePopoverActions";
import {
  getDurationTimeString,
  getFormattedLectureDate,
  isEventInFuture,
} from "../scheduleHelpers";

import { CloseIcon } from "src/components/atoms/ActionIcons";
import { DT } from "src/constant/dateTime";
import HeaderThree from "src/components/atoms/Text/HeaderThree";
import NormalTextSelect from "src/components/atoms/Text/NormalTextSelect";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import Popover from "@mui/material/Popover";
import { PopoverComponentProps } from "src/components/organism/Schedule/scheduleTypes";
import React from "react";
import { format } from "date-fns";
import styled from "styled-components";

const PopoverComponent = (props: PopoverComponentProps) => {
  const {
    anchorElement,
    onClose,
    showActions,
    event,
    refetchLectures,
    details,
  } = props;
  return (
    <Popover
      open={Boolean(anchorElement)}
      anchorEl={anchorElement}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {event && (
        <Container>
          <Controls>
            {showActions && details && event?.resource && refetchLectures && (
              <>
                <EditScheduleActions
                  refetchLectures={refetchLectures}
                  details={details}
                  lectureData={event.resource}
                  closePopover={onClose}
                />
                {isEventInFuture(event.start as Date) && (
                  <DeleteScheduleAction
                    refetchLectures={refetchLectures}
                    schedule={{
                      from: format(event.start as Date, DT.DATE_FORMAT_SLASH), // selected event's date
                      to: getFormattedLectureDate(event.resource.validTill),
                      id: event.resource.id,
                    }}
                    closePopover={onClose}
                  />
                )}
              </>
            )}
            <CloseIcon onPress={onClose} />
          </Controls>
          <Details>
            <HeaderThree
              value={event.resource.subject?.name || "-"}
              numberOfLines={3}
              align={"left"}
              fontSize={18}
              lineHeight={22}
            />
            <NormalTextSelect
              value={event.resource.lecturer?.fullName || "-"}
              lines={3}
            />
            <NormaltextAtom
              value={getDurationTimeString(
                event.resource?.startTime,
                event.resource?.endTime
              )}
            />
            <NormaltextAtom
              value={`${event.resource?.batch?.alias ?? "-"} / ${event.resource?.division ?? "-"
                }`}
              lines={3}
            />
            {!!event.resource?.detail?.description && (
              <NormaltextAtom
                value={event.resource.detail.description}
                lineHeight={2}
              />
            )}
          </Details>
        </Container>
      )}
    </Popover>
  );
};

const Container = styled.div`
  width: 320px;
  height: 180px;
  padding: 12px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default PopoverComponent;
