import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { colors, fonts } from '../../../styles/theme/styles';
import AttendanceNotConfigured from 'src/components/organism/Attendance/AttendanceNotConfigured';
import HeaderThree from '../Text/HeaderThree';
import SmallTextAtom from '../Text/SmallTextAtom';
import { useI18n } from 'src/i18n/hooks';
import { CircularProgressbarStyles } from 'react-circular-progressbar/dist/types';

const List = (props: any) => {
  const { t } = useI18n();

  const { type = 0 } = props;

  const { data, pagination = false, slotSelector = null, selectedSlot = null } = props;
  const [pageSize, setPageSize] = useState(7);
  const [pageNumber, setpageNumber] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  function paginate(array: any, page_size: any, page_number: any) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  useEffect(() => {
    setPageData(1);
  }, [pagination, data]);
  const setPageData = (page: number) => {
    let resposnedata = paginate(data, pageSize, page);
    setPaginatedData(resposnedata);
  };

  function selectedSlotHandler(id: string) {
    if (!selectedSlot) {
      return false;
    } else if (selectedSlot?.slotId === id) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <ListWrapper>
      {!pagination && data?.length > 0 ? (
        data.map((item: any, index: number) => (
          <AttendanceListSec
            key={`AttendanceListSec${index}`}
            onClick={() => {
              if (slotSelector) {
                slotSelector(item);
              }
            }}
            style={selectedSlotHandler(item.id) ? { backgroundColor: colors.greyBackground } : {}}>
            <AttendanceListLeft>
              <AttendanceListHeading>{`${index + 1}.  ${item.heading}`}</AttendanceListHeading>
              <AttendClassInfo>
                {`${item.attend}/${item.total}`}{' '}
                {type === 0
                  ? t('classesAttended.text')
                  : t('classAttendance.label')}
              </AttendClassInfo>
            </AttendanceListLeft>
            <AttendanceListRight>
              <ProgressBarWrapper>
                <CircularProgressbarWithChildren
                  styles={ComponentProps.ProgressBarStyles}
                  value={parseInt(item.percentage, 10)}>
                  <SmallTextAtom
                    value={`${item.percentage}%`}
                    fontWeight={'400'}
                    color={colors.secondaryText}
                  />
                </CircularProgressbarWithChildren>
              </ProgressBarWrapper>
            </AttendanceListRight>
          </AttendanceListSec>
        ))
      ) : (
        <div style={{ height: 250 }}>
          <AttendanceNotConfigured topPosition="35%" type={1} />
        </div>
      )}

      {pagination &&
        paginatedData?.length > 0 &&
        paginatedData.map((item: any, index: number) => (
          <AttendanceListSec
            key={`AttendanceListSecPagination${index}`}
            style={index === 1 ? { backgroundColor: colors.greyBackground } : {}}>
            <AttendanceListLeft>
              <AttendanceListHeading>{`${index + 1}.  ${item.heading}`}</AttendanceListHeading>
              <AttendClassInfo>{`${item.attend}/${item.total}`} classes attended</AttendClassInfo>
            </AttendanceListLeft>
            <AttendanceListRight>
              <ProgressBarWrapper>
                <CircularProgressbarWithChildren
                  styles={ComponentProps.ProgressBarStyles}
                  value={item.percentage}>
                  <HeaderThree
                    value={`${item.percentage}%`}
                    lineHeight={32}
                    fontWeight={'600'}
                    color={colors.primaryColor}
                  />
                </CircularProgressbarWithChildren>
              </ProgressBarWrapper>
            </AttendanceListRight>
          </AttendanceListSec>
        ))}
      {pagination && (
        <button
          onClick={() => {
            setpageNumber((p) => p + 1);
            setPageData(pageNumber);
          }}>
          next
        </button>
      )}
    </ListWrapper>
  );
};
export default List;

type ComponentPropsType = {
  ProgressBarStyles: CircularProgressbarStyles;
}

const ComponentProps: ComponentPropsType = {
  ProgressBarStyles: buildStyles({
    rotation: 0,
    strokeLinecap: 'butt',
    textSize: '33px',
    pathTransitionDuration: 0.5,
    pathColor: colors.primary,
    textColor: colors.secondaryText,
    trailColor: colors.white,
    backgroundColor: colors.progressBarBackground,
  })
}

const AttendanceListLeft = styled.div`
  overflow: hidden;
`;
const AttendanceListHeading = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: ${fonts.light};
  color: ${colors.primaryText};
  padding-bottom: 5px;
`;
const AttendClassInfo = styled.div`
  font-size: 12px;
  font-family: ${fonts.light};
  color: ${colors.tertiaryText};
`;
const AttendanceListRight = styled.div``;

const ProgressBarWrapper = styled.div`
  width: 40px;  
`;

const ListWrapper = styled.div``;

const AttendanceListSec = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.modalClose};
  padding: 20px 16px;
  align-items: center;
  cursor: pointer;
`;
