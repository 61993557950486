import {
  getKeyValuesQuery,
  getLanguageKeyValueLastUpdateAt,
} from 'src/graphql/localization/key-values';
import { useI18n } from './hooks';
import {
  flushAll,
  getStorageFunction,
  setAllowedModules,
  setStorageFunction,
} from 'src/components/services/storage-service';
import { KEYS } from 'src/constant/key';
import { keyValuesVar, UserInformation, userInformation, allowedModulesState } from 'src/utils/manageState';
import { graphqlQuery } from 'src/graphql/util';
import React, { useEffect, useState } from 'react';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import { Text, View, StyleSheet } from 'react-native';
import { Provider, Portal, Dialog } from 'react-native-paper';
import { Dialog as WebDialog } from '@mui/material';
import { isWeb } from 'src/constant/device';
import transEN from './en/translation.json';
import { allowedModulesQuery } from 'src/graphql/institutes/module'
import { UserType } from 'src/constant';

const apply = (
  keyValueData: any,
  i18n: any,
  userInf: UserInformation,
  xcallback: () => void,
  onError?: (err: any) => void,
) => {
  try {
    if (keyValueData && keyValueData?.localizationKeyValues?.data && userInf.language) {
      const defaultTrans: any = transEN;
      let translationValues: any = {};
      keyValueData?.localizationKeyValues?.data.forEach((keyValue: any) => {
        translationValues[keyValue['key']['name']] = keyValue['value']
          ? keyValue['value']
          : keyValue['defaultValue'];
        delete defaultTrans[keyValue.key.name];
      });
      translationValues = { ...translationValues, ...defaultTrans };
      setStorageFunction(KEYS.LANGUAGE, userInf.language);
      setStorageFunction(KEYS.TRANSLATION_KEY_VALUE, JSON.stringify(translationValues));
      setStorageFunction(KEYS.TRANSLATION_KEY_VALUE_TS, `${new Date().getTime()}`);
      keyValuesVar(translationValues);
      i18n.addResources(userInf.language, 'translation', translationValues);
      i18n.changeLanguage(userInf.language);
      if (xcallback) xcallback();
    }
  } catch (e) {
    // console.log("error in applying settings", e)
    if (onError) onError(e);
    flushAll();
  }
};

export const onUserInfoChange = (
  i18n: any,
  userInf: UserInformation,
  lastUpdatedAt: number,
  xcallback: () => void,
  onError?: (err: any) => void,
) => {
  if (userInf.language) {
    Promise.all([
      getStorageFunction(KEYS.LANGUAGE),
      getStorageFunction(KEYS.TRANSLATION_KEY_VALUE),
      getStorageFunction(KEYS.TRANSLATION_KEY_VALUE_TS),
    ]).then(([langLocal, keyValues, keyValuests]) => {
      if (keyValues) {
        keyValues = JSON.parse(keyValues as any);
      }
      if (
        !langLocal ||
        langLocal !== userInf.language ||
        !keyValues ||
        Object.keys(keyValues).length === 0 ||
        !keyValuests ||
        (keyValuests && lastUpdatedAt && parseInt(keyValuests) < lastUpdatedAt)
      ) {
        graphqlQuery(getKeyValuesQuery, { language: userInf.language, limit: 0 }).then(
          ({ data }) => {
            apply(data, i18n, userInf, xcallback, onError);
          },
        );
      } else {
        //   console.log("Language already applied", langLocal, userInf.language)
        keyValuesVar(keyValues as any);
        i18n.addResources(userInf.language, 'translation', keyValues);
        i18n.changeLanguage(userInf.language);
        if (xcallback) xcallback();
      }
    });
  }
};

function ApplyLanguage(props: any): JSX.Element {
  const { callback, visible = true } = props;
  const userInfo = userInformation();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [pageMessage, setPageMessage] = useState<string>('Applying Settings...');
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const { i18n } = useI18n();
  useEffect(() => {
    if (userInfo.userType && userInfo.userType != UserType.APPLICANT) {
      Promise.all([
        graphqlQuery(allowedModulesQuery, {}),
        graphqlQuery(getLanguageKeyValueLastUpdateAt, {})
      ]).then(([modules, lang]) => {
        setAllowedModules(modules?.data?.allowedModules?.modules);
        onUserInfoChange(
          i18n,
          userInfo,
          lang?.data?.getLanguageKeyValuesLastUpdatedAt,
          () => {
            setLoaded(true);
            if (callback) callback();
          },
          (err: any) => {
            setShowLoader(false);
            setPageMessage('Failed to apply settings, please login again');
            console.log('error in apply language', err);
          },
        );
      })
    } else {
      setLoaded(true);
      if (callback) callback();
    }
  }, [userInfo]);

  const wrraper = (node: React.ReactNode) => {
    return isWeb ? (
      <WebDialog open={!loaded}>{node}</WebDialog>
    ) : (
      <Provider>
        <Portal>
          <Dialog visible={!loaded}>{node}</Dialog>
        </Portal>
      </Provider>
    );
  };

  return !loaded && visible ? (
    wrraper(
      <View>
        <View style={{ paddingTop: 18 }}>{showLoader && <LoaderSpinner color="secondary" />}</View>
        <Text style={styles.text}>{pageMessage}</Text>
      </View>,
    )
  ) : (
    <></>
  );
}

const styles = StyleSheet.create({
  text: {
    padding: 16,
    textAlign: 'center',
  },
});

export default ApplyLanguage;
