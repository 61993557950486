import { TableBody, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Paginations from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import {
  TableActions,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { createSearchFilter, debounce } from 'src/components/services';
import { getCommonNameSearchFilter } from 'src/components/services/filters';
import { AUTOCOMPLETE_MODULE, ERROR, PaymentStatusLabel, SUCCESS, limit } from 'src/constant';
import { height } from 'src/constant/device';
import { useFilter } from 'src/contexts/filter-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { useHistory, useParams } from 'src/routes/routing.web';
import styled from 'styled-components/native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCopy, faLink, faSync, faUnlink } from '@fortawesome/pro-regular-svg-icons';
import { TouchableOpacity, View } from 'react-native';
import { useThemeSystem } from 'src/contexts/theme-context';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { getPaymentStatusColor } from 'src/components/organism/Admission/helpers';
import { colors } from 'src/styles/theme/styles';
import { EditIcon, InfoSquareIcon } from 'src/components/atoms/ActionIcons';
import { graphqlQuery } from 'src/graphql/util';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import PaymentDetailsModal from 'src/components/organism/Admission/PaymentDetailsModal';
import {
  getCustomFeePaymentReconcileRequestQuery,
  useGetCustomFeePayers,
  useGetCustomFee,
  useReconcileCustomFeePayment,
  useImportPayerInFee,
  useBulkReconcileCustomFeePayment,
  useResendMailToCustomFeePayer,
  useExpireFeePayerInvite,
  useReactivateFeePayerInvite,
  useEditCustomFeePayer,
} from 'src/graphql/payment';
import { downloadFileRestAPI, properCase } from 'src/utils/utility';
import { ReconcilePaymentData } from 'src/components/organism/Courses/BatchStudents/types';
import { Icon } from 'src/components/atoms/Icon/Icon';
import ReconcilePaymentForm from 'src/components/molecules/Admission/ReconcilePaymentForm';
import { ADD_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { FieldValues, useForm } from 'react-hook-form';
import { attachmentType } from 'src/components/organism/Assignments/types';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import ERROR_MSG from 'src/constant/error';
import { CustomFeePayer, LINK_STATUS } from './helpers';
import EditPayerDetailsForm from 'src/components/molecules/OtherPayment/EditPayerDetailsForm.web';

const headCells1 = [
  {
    id: 'name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 150 },
  },
  {
    id: 'email',
    label: 'email.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 208 },
  },
  {
    id: 'uniqueId',
    label: 'uniqueId.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 80 },
  },
  {
    id: 'mobileNumber',
    label: 'mobileNumber.label',
    align: 'right',
    disableSorting: true,
    style: { paddingRight: 60, minWidth: 100 },
  },
  {
    id: 'amount',
    label: 'amount.label',
    align: 'right',
    disableSorting: true,
    style: { paddingRight: 60, minWidth: 100 },
  },
  {
    id: 'payment',
    label: 'payment.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 150 },
  },
  {
    id: 'emailStatus',
    label: 'emailStatus.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 100 },
  },
  {
    id: 'linkStatus',
    label: 'linkStatus.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 100 },
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: 76 },
  },
];

export default function OtherPaymentDetail() {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { theme } = useThemeSystem();
  const [importModalState, handleImportModal] = useState<boolean>(false);
  const [importAttachment, handleImportAttachment] = useState<{
    documentName: String;
    document?: File | undefined;
  }>({ documentName: 'NA' });
  const [completeRefundModalState, setCompleteRefundModalState] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm();
  const {
    getCustomFee,
    customFeeData,
    customFeeLoading
  } = useGetCustomFee();
  const {
    importPayerInFee,
    importPayerInFeeData
  } = useImportPayerInFee();
  const {
    getCustomFeePayers,
    customFeePayersData,
    customFeePayersLoading,
    refetchCustomFeePayers,
  } = useGetCustomFeePayers();
  const { reconcileCustomFeePayment } = useReconcileCustomFeePayment();
  const { bulkReconcileCustomFeePayment } = useBulkReconcileCustomFeePayment();
  const { resendMailToCustomFeePayer } = useResendMailToCustomFeePayer();
  const { expireFeePayerInvite } = useExpireFeePayerInvite();
  const { reactivateFeePayerInvite } = useReactivateFeePayerInvite();
  const { editCustomFeePayer } = useEditCustomFeePayer();
  const history = useHistory();
  const { setAlertDetails } = useAlertSystem();

  let searchValue = '' as string;
  let row;
  let rowLimit = 0;
  let { page = 1, dataLimit = limit, paymentId }: any = useParams();
  rowLimit = parseInt(dataLimit);
  if (filters && filters?.user?.on) {
    searchValue = filters?.user?.search;
  }
  const feeName = history?.location?.state?.feeName;

  const [searching, setSearching] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [paymentDetailsModal, setPaymentDetailsModal] = useState<boolean>(false);
  const [paymentIds, setPaymentIds] = useState<string[]>([]);
  const [reconcilePaymentModal, setReconcilePaymentModal] = useState<boolean>(false);
  const [reconcilePaymentData, setReconcilePaymentData] = useState<ReconcilePaymentData | null>(
    null,
  );
  const [currentCode, setCurrentCode] = useState<string>('');
  const [canClick, setCanClick] = useState(true);
  const [confirmSelection, setConfirmSelection] = useState<boolean>(false);
  const [editPaymentModal, setEditPaymentModal] = useState<boolean>(false);
  const [bulkReconcilePaymentModal, setBulkReconcilePaymentModal] = useState<boolean>(false);
  const [emailConfirmationModal, setEmailConfirmationModal] = useState<boolean>(false);
  const [linkConfirmationModal, setLinkConfirmationModal] = useState<{
    state: boolean,
    type: string,
  }>({ state: false, type: '' });
  const [currentItem, setCurrentItem] = useState<CustomFeePayer | null>(null);
  const [editPayerDetailsModal, setEditPayerDetailsModal] = useState<boolean>(false);

  useEffect(() => {
    if (feeName) {
      setHeading([
        {
          text: t('otherPayment.label'),
          url: `/control-panel/other-payment/limit/${rowLimit}/page/1`,
        },
        {
          text: feeName,
          url: '',
        },
      ]);
    }
  }, [feeName]);

  useEffect(() => {
    setSearching(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (paymentId) {
      handleFetch();
    }
  }, [paymentId, page, searchValue]);

  function handleFetch() {
    getCustomFee({ variables: { id: paymentId }})
    const filters = getCommonNameSearchFilter(searchValue);
    let customFeePayerFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    customFeePayerFilter['customFee'] = paymentId;
    getCustomFeePayers({ variables: customFeePayerFilter });
  }

  const closeImportModal = () => {
    handleImportModal(false);
    handleImportAttachment({
      document: undefined,
      documentName: 'NA',
    });
  };

  let customFee = customFeeData?.customFee;
  row = customFeePayersData?.customFeePayers?.data;
  const totalCount = customFeePayersData?.customFeePayers?.totalCount;

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    page,
  );

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.USER]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchAudience(searchValue: string) {
    let isClear = searchValue?.length === 0;
    persistSearch(!isClear, searchValue);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchAudience(q), 500),
    [],
  );

  const handleSearch = (searchValue: string) => {
    setSearching(searchValue);
    delayedQuery(searchValue);
  };

  const handlePaymentDetailsModal = async (ids: string[]) => {
    setPaymentDetailsModal(true);
    setPaymentIds(ids);
  };

  function closePaymentDetailsModal() {
    setPaymentDetailsModal(false);
    setPaymentIds([]);
  }

  const handleReconcilePaymentModal = async (code: string) => {
    setCurrentCode(code);
    const { loading, data } = await graphqlQuery(getCustomFeePaymentReconcileRequestQuery, {
      code: code,
    });
    if (!loading && data?.getCustomFeePaymentReconcileRequest) {
      setReconcilePaymentData(data.getCustomFeePaymentReconcileRequest);
    }
    setReconcilePaymentModal(true);
  };

  function closeReconcilePaymentModal() {
    setReconcilePaymentModal(false);
    setReconcilePaymentData(null);
    setCurrentCode('');
    setConfirmSelection(false);
  }

  async function handleReconcileStudentPayment() {
    setCanClick(false);
    try {
      const response = await reconcileCustomFeePayment({ variables: { code: currentCode } });
      if (response?.data?.reconcileCustomFeePayment?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.RECONCILE_PAYMENT, level: SUCCESS });
        if (refetchCustomFeePayers) refetchCustomFeePayers();
      } else {
        throw new Error(response?.data?.reconcileCustomFeePayment?.message);
      }
    } catch (error: any) {
      setAlertDetails({ message: error.message, level: ERROR });
    }
    closeReconcilePaymentModal();
    setCanClick(true);
  }

  function exportReport(url: string, fileName: string) {
    downloadFileRestAPI(url, fileName, (err) => {
      console.log('err', err);
      setAlertDetails({ message: err.message || 'Failed to download', level: ERROR });
    });
    setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
  }

  const importPayers = async () => {
    setCanClick(false);
    try {
      let importPayer = await importPayerInFee({
        variables: { feeId: paymentId, file: importAttachment.document },
      });

      if (importPayer?.data?.importPayerInFee) {
        let level = SUCCESS;
        let message = `Imported, Successfully`;
        let expireInMs = 6000;
        setAlertDetails({ message, level, expireInMs });
        setCanClick(true);
        if (refetchCustomFeePayers) refetchCustomFeePayers();
      }
      if (importPayer?.errors) {
        let level = ERROR;
        let message = `Failed to import`;
        let expireInMs = 6000;
        setAlertDetails({ message, level, expireInMs });
        setCanClick(true);
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR, expireInMs: 15000 });
      setCanClick(true);
    }
    closeImportModal();
  };

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      } as attachmentType;
      handleImportAttachment(fileObject);
      handleImportModal(true);
      e.target.value = null;
    }
  };

  async function handleBulkReconcileStudentPayment() {
    setCanClick(false);
    try {
      const response = await bulkReconcileCustomFeePayment({ variables: { customFeeId: paymentId } });
      if (response?.data?.bulkReconcileCustomFeePayment?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.RECONCILE_PAYMENT, level: SUCCESS });
        if (refetchCustomFeePayers) refetchCustomFeePayers();
        setBulkReconcilePaymentModal(false)
      } else {
        throw new Error(response?.data?.bulkReconcileCustomFeePayment?.message);
      }
    } catch (error: any) {
      setAlertDetails({ message: error.message, level: ERROR });
    }
    setCanClick(true);
  }

  function handleEmailConfirmationModal(item: CustomFeePayer) {
    setCurrentItem(item);
    setEmailConfirmationModal(true);
  }

  function closeEmailConfirmationModal() {
    setCurrentItem(null);
    setEmailConfirmationModal(false);
  }

  async function handleResendEmail() {
    setCanClick(false);
    try {
      const response = await resendMailToCustomFeePayer({ variables: { feePayerId: currentItem?.id } });
      if (response?.data?.reSendMailToCustomFeePayer?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.RESEND_EMAIL, level: SUCCESS });
        setCanClick(true);
        closeEmailConfirmationModal();
        if (refetchCustomFeePayers) refetchCustomFeePayers();
      } else {
        throw new Error(
          response?.data?.reSendMailToCustomFeePayer?.message || ERROR_MSG.GENERIC_ERROR,
        );
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  function getEmailStatus(emailCount: string) {
    const ec: number = parseInt(emailCount);
    let emailStatus: string = '';
    switch (true) {
      case !ec || ec === 0:
        emailStatus = 'NOT SENT';
        break;
      case ec === 1:
        emailStatus = 'SENT';
        break;
      case ec > 1:
        emailStatus = `SENT (${ec})`;
        break;
    }
    return emailStatus;
  }

  function handleLinkConfirmationModal(item: CustomFeePayer, type: string) {
    setCurrentItem(item);
    setLinkConfirmationModal({
      state: true,
      type,
    });
  }

  function closeLinkConfirmationModal() {
    setCurrentItem(null);
    setLinkConfirmationModal({
      state: false,
      type: '',
    });
  }

  async function handleDeactivateLink() {
    setCanClick(false);
    try {
      const response = await expireFeePayerInvite({ variables: { payerId: currentItem?.id } });
      if (response?.data?.expireFeePayerInvite?.status === 'success') {
        setAlertDetails({ message: UPDATE_SUCCESS.DEACTIVATE_LINK, level: SUCCESS });
        setCanClick(true);
        closeLinkConfirmationModal();
        if (refetchCustomFeePayers) refetchCustomFeePayers();
      } else {
        throw new Error(response?.data?.expireFeePayerInvite?.message || ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  async function handleActivateLink() {
    setCanClick(false);
    try {
      const response = await reactivateFeePayerInvite({ variables: { payerId: currentItem?.id } });
      if (response?.data?.reActivateFeePayerInvite?.status === 'success') {
        setAlertDetails({ message: UPDATE_SUCCESS.ACTIVATE_LINK, level: SUCCESS });
        setCanClick(true);
        closeLinkConfirmationModal();
        if (refetchCustomFeePayers) refetchCustomFeePayers();
      } else {
        throw new Error(response?.data?.reActivateFeePayerInvite?.message || ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  function closeEditPayerDetails() {
    setEditPayerDetailsModal(false);
    setCurrentItem(null);
    clearErrors();
    reset({});
  }

  const handleEditPayerDetailsModal = (currentItem: CustomFeePayer) => {
    setCurrentItem(currentItem);
    setEditPayerDetailsModal(true);
  }

  const handleEditPayerDetails = async (formData: FieldValues) => {
    setCanClick(false);
    const isEmpty = Object.values(formData).every(x => x === null || x === '');
    if (!isEmpty) {
      try {
        const payload = {
          id: currentItem?.id,
        };
  
        if (formData) {
          Object.keys(formData).forEach((key: string) => {
            if (formData[key]) {
              payload[`${key}`] =
                key === 'mobileNumber' || key === 'amount'
                  ? parseFloat(formData[key])
                  : formData[key];
            }
          });
        }
  
        const response = await editCustomFeePayer({ variables: { payload } });
        if (response?.data?.updateCustomFeePayer) {
          setAlertDetails({ message: UPDATE_SUCCESS.PAYER_DETAILS, level: SUCCESS });
          setCanClick(true);
          closeEditPayerDetails();
          if (refetchCustomFeePayers) refetchCustomFeePayers();
        } else {
          throw new Error(ERROR_MSG.GENERIC_ERROR);
        }
      } catch (e: any) {
        setAlertDetails({ message: e.message, level: ERROR });
        setCanClick(true);
      }
    } else {
      closeEditPayerDetails();
    }
  };

  return (
    <>
      <LoadContentWrapper>
        <FlexRowView>
          <SearchWrapper>
            <Search
              id="audienceSearch"
              handleChange={handleSearch}
              value={searching}
              label={t('search.label')}
            />
          </SearchWrapper>

          <AddCriteriaWrapper>
            {recordsAfterPagingAndSorting() &&
              recordsAfterPagingAndSorting()?.length > 0 &&
              (customFee?.payerType == 'FILE_IMPORTED' ? (
                <OutlineButton
                  label={t('reconcilePayment.label')}
                  onPress={() => setBulkReconcilePaymentModal(!bulkReconcilePaymentModal)}
                  style={{ height: 30 }}
                />
              ) : (
                <SecondaryBtn
                  onPress={() => setBulkReconcilePaymentModal(!bulkReconcilePaymentModal)}
                  label={t('reconcilePayment.label')}
                />
              ))}
            {
              (!customFeeLoading && customFee?.payerType == "FILE_IMPORTED") && 
              <>
                  <input
                    style={{ display: 'none' }}
                    id="importPayers"
                    multiple
                    type="file"
                    accept=".csv,.xls,.xlsx"
                    onChange={handleFileUpload}
                  />
                  <label htmlFor="importPayers">
                    <SecondaryBtn label={t('importPayer.label')} onPress={() => {}} /> 
                  </label>
              </>
            }
          </AddCriteriaWrapper>
        </FlexRowView>

        <TableDiv>
          <TblContainer
            height={totalCount <= rowLimit ? height - 270 : height - 285}
            overflowX={'auto'}>
            <TblHead setHeadCells={setHeadCells} />
            {!customFeePayersLoading ? (
              <>
                {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 && (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                      <TableRows key={i}>
                        <TableCells
                          value={item?.name || 'N/A'}
                          lines={2}
                          style={{ maxWidth: 150 }}
                        />
                        <TableCells
                          value={item?.email || 'N/A'}
                          lines={2}
                          style={{ maxWidth: 208 }}
                        />
                        <TableCells value={item?.uniqueId || 'N/A'} />
                        <TableCells
                          value={item?.mobileNumber || 'N/A'}
                          align={'right'}
                          style={{ paddingRight: 60 }}
                        />
                        <TableCells
                          value={
                            item?.amount || item?.amount === 0 ? `\u20B9 ${item?.amount}` : 'N/A'
                          }
                          align={'right'}
                          style={{ paddingRight: 60 }}
                        />
                        <TableCells>
                          <FlexColumnWrapper>
                            <ApplicantTypeWrapper>
                              <NormaltextAtom
                                value={PaymentStatusLabel[item?.status] || t('pending.label')}
                                color={
                                  item?.status
                                    ? getPaymentStatusColor(PaymentStatusLabel[item?.status])
                                    : colors.yellow
                                }
                              />
                              {item?.payments && item?.payments?.length > 0 && (
                                <PaymentDetailIconWrapper>
                                  <InfoSquareIcon
                                    onPress={() => handlePaymentDetailsModal(item?.payments)}
                                    tooltipTitle={t('paymentDetails.label')}
                                  />
                                </PaymentDetailIconWrapper>
                              )}
                            </ApplicantTypeWrapper>
                            {item?.payments && item?.payments?.length > 0 && (
                              <NormaltextAtom
                                color={colors.primaryText}
                                value={`${t('attempts.label')}: ${item?.payments?.length}`}
                              />
                            )}
                          </FlexColumnWrapper>
                        </TableCells>
                        <TableCells value={getEmailStatus(item?.mailSentCount)} />
                        <TableCells
                          value={item?.codeStatus || 'N/A'}
                          color={
                            item?.codeStatus
                              ? item.codeStatus === LINK_STATUS.ACTIVE
                                ? colors.green
                                : colors.errorColor
                              : colors.primaryText
                          }
                        />
                        <TableActions align="right">
                          <TableActionView>
                            {item?.codeStatus ? (
                              item.codeStatus === LINK_STATUS.ACTIVE ? (
                                <Tooltip title={t('deactivateLink.label')}>
                                  <TouchableOpacity
                                    onPress={() => handleLinkConfirmationModal(item, 'deactivate')}>
                                    <FontAwesomeIcon
                                      icon={faUnlink}
                                      color={theme?.table?.editIconColor}
                                    />
                                  </TouchableOpacity>
                                </Tooltip>
                              ) : (
                                <Tooltip title={t('activateLink.label')}>
                                  <TouchableOpacity
                                    onPress={() => handleLinkConfirmationModal(item, 'activate')}>
                                    <FontAwesomeIcon
                                      icon={faLink}
                                      color={theme?.table?.editIconColor}
                                    />
                                  </TouchableOpacity>
                                </Tooltip>  
                              )
                            ) : (
                              <></>
                            )}
                            {!PaymentStatusLabel[item?.status] && (
                              <Tooltip title={t('resendEmail.label')}>
                                <TouchableOpacity
                                  onPress={() => handleEmailConfirmationModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faSync}
                                    color={theme?.table?.editIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            )}
                            {item?.payments &&
                              item?.payments.length > 0 &&
                              (item?.status === 'COMPLETED' ? (
                                <Tooltip title="Download Fee Receipt">
                                  <TouchableOpacity
                                    onPress={() =>
                                      exportReport(
                                        `invoice/customfee/${item?.code}`,
                                        'FeeReceipt.pdf',
                                      )
                                    }>
                                    <FontAwesomeIcon
                                      icon={faDownload}
                                      color={theme?.table?.editIconColor}
                                    />
                                  </TouchableOpacity>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Reconcile Payment">
                                  <TouchableOpacity
                                    onPress={() => {
                                      handleReconcilePaymentModal(item?.code);
                                    }}>
                                    <Icon name="reconcile-payment" />
                                  </TouchableOpacity>
                                </Tooltip>
                              ))}

                            <Tooltip title="Copy Link">
                              <TouchableOpacity
                                onPress={() => {
                                  navigator.clipboard.writeText(
                                    `${window.location.origin}/payments/other.html?code=${item?.code}`,
                                  );
                                }}>
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  color={theme?.table?.editIconColor}
                                />
                              </TouchableOpacity>
                            </Tooltip>

                            <EditIcon
                              tooltipTitle={t('editPayerDetails.label')}
                              onPress={() => handleEditPayerDetailsModal(item)}
                            />
                          </TableActionView>
                        </TableActions>
                      </TableRows>
                    ))}
                  </TableBody>
                )}
                {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length === 0 && (
                  <TableRows style={{ height: 0.56 * height }}>
                    <TableCells colspan={5} align="center" style={{ borderBottom: 'none' }}>
                      <NormalTextSelect value={'No records found'} />
                    </TableCells>
                  </TableRows>
                )}
              </>
            ) : (
              <SpinnerView>
                <LoaderSpinner />
              </SpinnerView>
            )}
          </TblContainer>
          <Paginations
            pathName={`other-payment/${paymentId}`}
            count={Math.ceil(totalCount / rowLimit)}
            total={totalCount}
            page={page}
            rowLimit={rowLimit}
            hidePagination={totalCount <= rowLimit}
          />
        </TableDiv>

        <NormalModal
          setModalVisible={closePaymentDetailsModal}
          modalVisible={paymentDetailsModal}
          infoModal={true}
          width={720}
          height={580}
          maxWidth='lg'
          Headerpopup={t('paymentDetails.label')}
          preventRedirectOnClose={true}
          handleSave={() => null}
          visibility={!editPaymentModal && !completeRefundModalState}>
          <PaymentDetailsModal
            paymentIds={paymentIds}
            setEditPaymentModal={setEditPaymentModal}
            editPaymentModal={editPaymentModal}
            completeRefundModalState={completeRefundModalState}
            setCompleteRefundModalState={setCompleteRefundModalState}
          />
        </NormalModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeReconcilePaymentModal}
          modalVisible={reconcilePaymentModal}
          handleSave={handleReconcileStudentPayment}
          Headerpopup={t('paymentReconcile.label')}
          width={544}
          hideCloseIcon={true}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel="proceed.label"
          disabled={!confirmSelection}>
          <ReconcilePaymentForm
            confirmSelection={confirmSelection}
            setConfirmSelection={setConfirmSelection}
            reconcilePaymentData={reconcilePaymentData}
          />
        </NormalModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeImportModal}
          Headerpopup={t('importPayer.label')}
          modalVisible={importModalState}
          handleSave={handleSubmit(importPayers)}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel={'importUsers.text'}>
          <View style={{ paddingBottom: 24 }}>
            <NormalTextSelect fontsize={1.8} value={importAttachment.documentName} />
          </View>
        </NormalModal>

        <DeleteModal
          isSubmitting={!canClick}
          setModalVisible={() => setBulkReconcilePaymentModal(false)}
          modalVisible={bulkReconcilePaymentModal}
          handleSave={handleBulkReconcileStudentPayment}
          deleteButtonColor={colors.primaryColor}
          deleteButtonText={'confirm.label'}
          Headerpopup={t('reconcilePayment.label')}>
            {t('form.bulkReconcilePayment.warning')}
        </DeleteModal>

        <DeleteModal
          isSubmitting={!canClick}
          modalVisible={emailConfirmationModal}
          setModalVisible={closeEmailConfirmationModal}
          Headerpopup={t('resendEmail.label')}
          width={420}
          handleSave={handleResendEmail}
          deleteButtonColor={colors.primaryColor}
          deleteButtonText={'confirm.label'}>
            {t('resendEmailPayer.confirmation.text', { values: { payer: currentItem?.name } })}
        </DeleteModal>

        <DeleteModal
          isSubmitting={!canClick}
          modalVisible={linkConfirmationModal?.state}
          setModalVisible={closeLinkConfirmationModal}
          Headerpopup={
            linkConfirmationModal?.type === 'deactivate'
              ? t('deactivateLink.label')
              : t('activateLink.label')
          }
          width={420}
          handleSave={
            linkConfirmationModal?.type === 'deactivate' ? handleDeactivateLink : handleActivateLink
          }
          deleteButtonColor={colors.primaryColor}
          deleteButtonText={'confirm.label'}>
          {linkConfirmationModal?.type === 'expire'
            ? t('deactivateLink.confirmation.text', { values: { payer: currentItem?.name } })
            : t('activateLink.confirmation.text', { values: { payer: currentItem?.name } })}
        </DeleteModal>

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeEditPayerDetails}
          modalVisible={editPayerDetailsModal}
          handleSave={handleSubmit(handleEditPayerDetails)}
          Headerpopup={t('editPayerDetails.label')}
          width={544}
          hideCloseIcon={true}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel="save.label">
            <EditPayerDetailsForm
              control={control}
              errors={errors}
              setValue={setValue}
              editData={currentItem} />
        </NormalModal>
      </LoadContentWrapper>
    </>
  );
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 10;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  max-width: 280px;
  margin-right: 8px;
`;

const SpinnerView = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12;
`;

const FlexColumnWrapper = styled.View`
  display: flex;
  flex-direction: column;
  gap: 2;
`;

const ApplicantTypeWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PaymentDetailIconWrapper = styled.View`
  margin-left: 8px;
  padding-bottom: 0px;
`;

const AddCriteriaWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;
