import React, { useEffect, useState } from "react";

import { ADD_SUCCESS } from "src/constant/message";
import AssignDivisionTable from "./AssignDivisionTable.web";
import { ERROR } from "src/constant";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import Search from "src/components/atoms/SearchBar/index.web";
import { TAdmissionDetailFormState as TForm } from "src/components/organism/Admission/AdmissionDetail/AdmissionDetailTypes";
import { TFormProps } from "src/types";
import { createSearchFilter } from "src/components/services";
import { getStudentFilter } from "src/components/services/filters";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useAssignDivision } from "src/graphql/admission/application";
import { useGetUnassignedDivStudents } from "src/graphql/classes";
import { useI18n } from "src/i18n/hooks";

type Props = TFormProps<TForm<{ batchID: string; divisions: string[] }>>;

const AssignDivisionForm = (props: Props) => {
  const { form, onSuccess, onError, onClose } = props;
  const { batchID, divisions } = form.data!;
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();
  const {
    getUnassignedDivStudents,
    data,
    loading: dataLoading,
  } = useGetUnassignedDivStudents();
  const { assignDivision } = useAssignDivision();

  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, string | null>>({});
  const [searchQuery, setSearchQuery] = useState<string>("");

  const divisionOptions = divisions.map((d) => ({ label: d, value: d }));

  useEffect(() => {
    handleFetch();
  }, [searchQuery]);

  function handleFetch() {
    if (batchID) {
      let filters = getStudentFilter(searchQuery);
      const searchFilter = createSearchFilter(0, 0, filters);

      const studentFilter = createQueryParameter(searchFilter);
      getUnassignedDivStudents({
        variables: studentFilter,
      });
    }
  }

  function createQueryParameter(searchFilter: any) {
    let studentFilters = {} as any;
    const divisionFilter = [
      { division: { exists: false } },
      { division: { eq: null } },
    ];
    if (searchFilter.filters) {
      studentFilters = {
        limit: 0,
        filters: {
          batch: { eq: batchID },
          and: [{ or: divisionFilter }, searchFilter.filters],
        },
      };
    } else {
      studentFilters = {
        limit: 0,
        filters: {
          batch: { eq: batchID },
          or: divisionFilter,
        },
      };
    }
    return studentFilters;
  }

  const onSubmitFn = async () => {
    if (data) {
      setLoading(true);
      try {
        if (Object.keys(formData).length > 0) {
          const payload = Object.entries(formData)
            .filter(([_, value]) => value !== null)
            .map(([key, value]) => ({ id: key, division: value }));
          const response = await assignDivision({ variables: { payload } });
          if (response?.data?.assignDivNRollNos?.status === "success") {
            onSuccess(ADD_SUCCESS.ASSIGN_DIVISION);
          }
        } else {
          setAlertDetails({
            message: t("error.division.required"),
            level: ERROR,
          });
        }
        setLoading(false);
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      modalVisible={true}
      Headerpopup={t("assignDivisions.text")}
      maxWidth={"md"}
      handleSave={onSubmitFn}
      addEditButtonLabel={"assign.label"}
    >
      <Container>
        <SearchWrapper>
          <Search
            id="studentSearchDiv"
            handleChange={(q: string) => setSearchQuery(q)}
            value={searchQuery}
            label={t("search.label")}
          />
        </SearchWrapper>
        <AssignDivisionTable
          data={data?.instituteStudents?.data ?? []}
          loading={dataLoading}
          divisionOptions={divisionOptions}
          formData={formData}
          setFormData={setFormData}
        />
      </Container>
    </NormalModal>
  );
};

export default AssignDivisionForm;

const Container = styled.div`
  height: 480px;
`;

const SearchWrapper = styled.div`
  max-width: 300px;
  margin-bottom: 8px;
`;
