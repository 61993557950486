import React, { useCallback, useEffect, useState } from 'react';
import { ApplicationItemType } from 'src/components/organism/Applications/types';
import { getFilteredFeeCompositions } from 'src/components/services';
import ModalSaveAndCancelButtonGroup from '../ModalSaveAndCancelButtonGroup';
import { FormSchema } from '../Forms/types';
import { changeFeeCollection } from 'src/form-json/admission/change-fee-collection';
import { DropdownOptionsType } from 'src/types';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import { componentMapper } from '../ddfElements';
import validatorMapper from '@data-driven-forms/react-form-renderer/validator-mapper/validator-mapper';
import FormViewer from '../Forms/DDFFormViewer/index.web';
import styled from 'styled-components';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

interface Props {
  closeModal: () => void;
  onSubmit: (formValues: any) => void;
  isSubmitting: boolean;
  applicationDetail: ApplicationItemType | null;
}

export default function ChangeFeeCollectionForm(props: Props) {
  const { onSubmit, closeModal, isSubmitting, applicationDetail } = props;
  const [feeCompositionOptions, setFeeCompositionOptions] = useState<DropdownOptionsType[]>([]);
  const [initialValues, setInitialValues] = useState({});
  const formSchema: FormSchema = changeFeeCollection(feeCompositionOptions);

  useEffect(() => {
    if (applicationDetail && Object.keys(applicationDetail).length > 0) {
      handleFetchFeeComps();
    }
  }, [applicationDetail]);

  async function handleFetchFeeComps() {
    const feeComps = await getFilteredFeeCompositions({
      limit: 0,
      subGroup: applicationDetail?.subjectGroup?.id,
    });
    
    const currentFeeComp = feeComps?.options?.find(
      (item: any) => item?.value === applicationDetail?.fees?.feeComposition,
    );
    setInitialValues({ feeComposition: currentFeeComp });
    setFeeCompositionOptions(feeComps?.options);
  }

  const ChangeFeeCollectionTemplate = useCallback(() => {
    return (
      <>
        {feeCompositionOptions && feeCompositionOptions?.length > 0 ? (
          <FormWrapper>
            <FormViewer schema={formSchema} isOnboarding={false} inModalView={true} />
          </FormWrapper>
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )}

        <ModalSaveAndCancelButtonGroup onCancel={closeModal} isSubmitting={isSubmitting} />
      </>
    );
  }, [isSubmitting, formSchema]);

  return (
    <>
      {formSchema && (
        <FormRenderer
          componentMapper={componentMapper}
          FormTemplate={ChangeFeeCollectionTemplate}
          schema={formSchema}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validatorMapper={validatorMapper}
        />
      )}
    </>
  );
}

const FormWrapper = styled.div`
  min-height: 144px;
`;

const SpinnerView = styled.div`
  height: 144px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
