import React from 'react';
import styled from 'styled-components/native';

import { colors, fonts } from 'src/styles/theme/styles';

export default function HeaderSix(props: any) {
  const { value = '', color = colors.secondaryText, fontWeight } = props;

  return (
    <Text style={{ fontWeight, color }} allowFontScaling={false}>
      {value}
    </Text>
  );
}

const Text = styled.Text`
  font-family: ${fonts.regular};
  font-size: ${({ theme }) => theme.rem(2)};
  line-height: ${({ theme }) => theme.rem(2.4)};
  color: ${colors.primaryText};
`;
