import { ADD_SUCCESS, GRADE_ERROR, UPDATE_SUCCESS } from 'src/constant/message';
import { DownloadFileIcon, EditIcon } from 'src/components/atoms/ActionIcons';
import { AUTOCOMPLETE_MODULE, ERROR, FILTERS, SEARCH_TIME, SUCCESS, limit } from 'src/constant';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import styled, { useTheme } from 'styled-components';
import {
  useCreateExamMarks,
  useGetExamSubjectMarks,
  useImportExamMarks,
} from 'src/graphql/assessment/assessment-marks';
import { useHistory, useParams } from 'src/routes/routing.web';

import AddEditMarksForm from 'src/components/molecules/Assessment/Marks/AddEditMarksForm';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import MarksSelectionForm from 'src/components/molecules/Assessment/MarksSelectionForm';
import MediumText from 'src/components/atoms/Text/MediumText';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { ClickAwayListener, TableBody } from '@mui/material';
import { attachmentType } from '../../Assignments/types';
import { colors } from 'src/styles/theme/styles';
import {
  downloadFileRestAPI,
  focusNextElement,
  getTableValue,
  properCase,
} from 'src/utils/utility';
import { height } from 'src/constant/device';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useForm } from 'react-hook-form';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import useTable from 'src/components/molecules/Table/UseTable';
import SplitButton from 'src/components/atoms/SplitButton/index.web';
import { ViewMode } from 'src/components/molecules/Forms/types';
import Input from 'src/components/atoms/Input/input.web';
import {
  StudentMarks,
  TestData,
  headCells1,
  creditActionHeadCells,
  marksActionHeadCells,
  generateRegexFromArray,
} from './helpers';
import { createSearchFilter, createSortFilter, debounce } from 'src/components/services';
import { useFilter } from 'src/contexts/filter-context';
import Search from 'src/components/atoms/SearchBar/index.web';
import { regexObject } from 'src/constant/regex';

export default function Marks() {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const { setAlertDetails } = useAlertSystem();
  const { setHeading } = useHeaderTitle();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm();
  const history = useHistory();
  const { filters, setFilter } = useFilter();
  let searchValue = '' as string;
  const [headCells, setHeadCells] = useState(headCells1);
  const [dropDown, setDropDown] = useState(false);
  const [marksType, setMarksType] = useState<boolean>(false);
  const [modalState, handleModal] = useState<boolean>(false);
  const [deleteMarksModalState, handleDeleteMarksModal] = useState<boolean>(false);
  const [selectedBatchId, setSelectedBatchId] = useState<string>('');
  const [selectedClassId, setSelectedClassId] = useState<string>('');
  const [selectedClassAlias, setSelectedClassAlias] = useState<string>('');
  const [selectedDivision, setSelectedDivision] = useState<string>('');
  const [selectedExamId, setSelectedExamId] = useState<string>('');
  const [selectedSubjectId, setSelectedSubjectId] = useState<string>('');
  const [allFormValues, setAllFormValues] = useState<boolean>(false);
  const [selectedTestsArray, setSelectedTestsArray] = useState<[{}]>([{}]);
  const [row, setRow] = useState<any>();
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [canClick, setCanClick] = useState(true);
  const [addMarksTestWiseDataObj, setAddMarksTestWiseDataObj] = useState<any>({});
  const [testIds, setTestIds] = useState<TestData[]>([]);
  const [allowedGrades, setAllowedGrades] = useState<string[]>([]);
  const [importModalState, handleImportModal] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [isMarkType, setIsMarkType] = useState<boolean>(false);
  const [importAttachment, handleImportAttachment] = useState<{
    documentName: String;
    document?: File | undefined;
  }>({ documentName: 'NA' });
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.READ);
  const [studentMarks, setStudentMarks] = useState(new Map<string, StudentMarks>());
  const [searching, setSearchingStudent] = useState(searchValue);
  const inputRef = React.useRef([]);
  let width = window.innerWidth;

  const {
    getExamSubjectMarks,
    loadingExamSubjectMarks,
    dataExamSubjectMarks,
    errorExamSubjectMarks,
    refetchExamSubjectMarks,
  } = useGetExamSubjectMarks();
  const { createExamMarks, createExamMarksData, createExamMarksError } = useCreateExamMarks();
  const { importExamMarks, importExamMarksData, importExamMarksError } = useImportExamMarks();

  const styles = StyleSheet.create({
    downloadTemplateBtn: { height: 36, marginRight: 16, paddingHorizontal: 8 },
    downloadTemplateView: { flexDirection: 'row', justifyContent: 'space-between' },
    templateText: { paddingLeft: 5 },
    importContainer: { display: 'flex', flexDirection: 'row' },
    importMarksBtn: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      paddingRight: 8,
      height: 36,
    },
    importMarksDropdown: {
      width: 28,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      backgroundColor: colors.primary,
      marginLeft: 1,
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      height: 36,
    },
    actionBtn: { height: 36 },
    assignRollNoBtn: { height: 36, alignSelf: 'flex-end' },
    loadContent: { minHeight: 592, height: height - 124 },
  });

  const {
    page = 1,
    dataLimit = limit,
    urlBatchId = null,
    urlDivision = null,
    urlExamId = null,
    urlSubjectId = null,
  }: any = useParams();
  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  let maxHeight = height - 430;
  const paginationHeightOffset = Boolean(totalCount && totalCount >= rowLimit) ? 140 : 135;
  const rowsPerPageOptions = [100];

  if (filters && filters?.marks_students?.on) {
    searchValue = filters?.marks_students?.search;
  }

  useEffect(() => {
    setSearchingStudent(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (urlBatchId && urlExamId && urlDivision && urlSubjectId) {
      handleFetch(urlExamId, urlSubjectId, urlDivision);
    }
  }, [urlBatchId, urlExamId, urlSubjectId, urlDivision, headCells, searchValue, page]);

  useEffect(() => {
    const examSubjectMarksData = dataExamSubjectMarks?.examSubjectMarks;
    setRow(examSubjectMarksData?.data);
    setTotalCount(examSubjectMarksData?.totalCount);
    if (examSubjectMarksData?.data && examSubjectMarksData.data.length > 0) {
      setMarks(examSubjectMarksData.data);
    }
  }, [dataExamSubjectMarks]);

  // Set header title text
  useEffect(() => {
    setHeading([{ text: t('marks.label'), url: '' }]);
  }, []);

  useEffect(() => {
    if (allFormValues) {
      handleFetch(selectedExamId, selectedSubjectId, selectedDivision);
    }
  }, [currentPage, rowLimit]);

  function setMarks(data: any) {
    const studentMarks = new Map();
    data?.forEach((item: any) => {
      if (item?.marks?.marks && item.marks.marks.length > 0) {
        item.marks.marks.forEach((mark: any) => {
          if (!studentMarks.get(item?.user?.id)?.[`${mark?.test?.id}`]) {
            studentMarks.set(item?.user?.id, {
              ...studentMarks.get(item?.user?.id),
              [`${mark?.test?.id}`]: {
                test: mark?.test?.id,
                marksObtained: mark?.marksObtained,
              },
            });
          }
        });
      }
    });
    setStudentMarks(studentMarks);
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    null,
    maxHeight,
    totalCount,
    currentPage,
    null,
    null,
    false,
    false,
    null,
    paginationHeightOffset,
  );

  function addToUrl(
    currentBatchId: string,
    currentDivision: string,
    currentExamId: string,
    currentSubjectId: string,
  ) {
    const updatedPage = 1; // Initialize to first page
    setCurrentPage(updatedPage);
    history.push(
      `/assessment/marks/${currentBatchId}/${currentDivision}/${currentExamId}/${currentSubjectId}/limit/${dataLimit}/page/${updatedPage}`,
    );
  }

  function handleFetch(examId: string, subjectId: string, division: string) {
    const sortCriteria = createSortFilter(headCells);
    getExamSubjectMarks({
      variables: makeExamMarksVariable(examId, subjectId, division, sortCriteria),
    });
  }


  const closeModal = () => {
    handleModal(false);
    setValue('studentName', null);
    setValue('studentId', null);
    clearErrors();
  };

  const retrieveValues = (formData: any) => {
    closeDropdown();
    setFilter(null);
    let testsArray = formData?.subject?.testsArray;
    setSelectedTestsArray(testsArray);

    let temp_dynamicHeadcells = [] as any;
    const tests: TestData[] = [];
    if (isMarkType) {
      setMarksType(true);
        testsArray.forEach(function (item: any) {
            tests.push({
                id: item?.id,
                totalMarks: item?.totalMarks,
                marksType: item?.markingType,
            });
            const labelSuffix = item?.markingType === 'MARK' ? `(${item?.passingMarks}/${item?.totalMarks})` : '';
            temp_dynamicHeadcells.push({
                id: item?.id,
                label: `${item?.name}${labelSuffix}`,
                align: 'right',
                disableSorting: true,
                style: { minWidth: '200px' }
            });
        });

        if (formData?.batch?.type === "MARKS" || formData?.batch?.settings?.gradingType === "MARKS") {
            setHeadCells([...headCells1, ...temp_dynamicHeadcells, ...marksActionHeadCells]);
           !allowedGrades && setAlertDetails({ message: GRADE_ERROR.NOT_FOUND, level: ERROR });
        } else {
            setHeadCells([...headCells1, ...temp_dynamicHeadcells, ...creditActionHeadCells]);
        }
    } else {
      setMarksType(false);
        testsArray.forEach(function (item: any) {
            tests.push({
                id: item?.id,
                totalMarks: item?.totalMarks,
                marksType: item?.markingType,
            });
            const labelSuffix = `(${item?.passingMarks}/${item?.totalMarks})`;
            temp_dynamicHeadcells.push({
                id: item?.id,
                label: `${item?.name}${labelSuffix}`,
                align: 'right',
                disableSorting: true,
                style: { minWidth: '200px' }
            });
        });
        setHeadCells([...headCells1, ...temp_dynamicHeadcells, ...creditActionHeadCells]);
    }
    setTestIds(tests);
    setSelectedBatchId(formData?.batch?.value);
    setSelectedClassId(formData?.batch?.class?.id);
    setSelectedClassAlias(formData?.batch?.class?.alias);
    setSelectedDivision(formData?.division.value);
    setSelectedExamId(formData?.exams?.value);
    setSelectedSubjectId(formData?.subject?.value);
    setAllFormValues(true);
    handleFetch(formData?.exams?.value, formData?.subject?.value, formData?.division.value);

    // add parameter id to history
    addToUrl(
        formData.batch.value,
        formData?.division.value,
        formData?.exams?.value,
        formData?.subject?.value,
    );
};


  function makeExamMarksVariable(
    examId = selectedExamId,
    subjectId = selectedSubjectId,
    division = selectedDivision,
    sortCriteria?: any,
  ) {
    let filters = [] as any;
    if (searchValue) {
      filters = [
        {
          field: 'fullName',
          type: FILTERS.REGEX,
          value: searchValue.trim(),
        },
      ];
    }

    let marksFilter = {
      ...createSearchFilter(rowLimit, (page - 1) * rowLimit, filters),
      exam: examId,
      subjectId: subjectId,
      division: division,
      ...(sortCriteria ? { sort: sortCriteria } : {}),
    };
    return marksFilter;
  }

  async function createOrUpdateExamMarks(data: any) {
    try {
      // ensure all marks values entered
      if (Object.keys(addMarksTestWiseDataObj).length > 0) {
        setCanClick(false);
        let testMarkInputArray = [];
        // transform data to required format
        for (const [key, val] of Object.entries(addMarksTestWiseDataObj)) {
          if (val) {
            testMarkInputArray.push({
              marksObtained: val,
              test: key,
            });
          }
        }

        try {
          let marksObject = {
            examId: urlExamId,
            inputs: [
              {
                user: data?.studentName?.value,
                testMarks: testMarkInputArray,
              },
            ],
          };

          let marksData = await createExamMarks({
            variables: { payload: marksObject },
          });

          // API Response for add/edit
          if (marksData?.data) {
            setAlertDetails({
              message: isEditModal ? UPDATE_SUCCESS.MARKS : ADD_SUCCESS.MARKS,
              level: SUCCESS,
            });
            closeModal();
            if (refetchExamSubjectMarks) refetchExamSubjectMarks(makeExamMarksVariable());
          }
          setCanClick(true);
        } catch (e: any) {
          setAlertDetails({ message: e.message, level: ERROR });
          setCanClick(true);
        }
      } else {
        // ensure all marks are entered
        throw new Error(t('error.marks.required'));
      }
    } catch (e) {
      setAlertDetails({ message: e?.message, level: ERROR });
    }
  }

  const handleImportMarksFileUpload = e => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      } as attachmentType;
      handleImportAttachment(fileObject);
      e.target.value = null;
      handleImportModal(true);
    }
  };

  const closeImportModal = () => {
    handleImportModal(false);
    handleImportAttachment({
      document: undefined,
      documentName: 'NA',
    });
  };

  async function ensureSelection(condition: any, callback: any) {
    if (selectedBatchId && selectedDivision && selectedExamId && selectedSubjectId && condition()) {
      return callback();
    } else {
      setAlertDetails({
        message: t('marks-form-selections-promp.label'),
        level: ERROR,
      });
    }
  }

  const importMarks = async () => {
    setCanClick(false);
    try {
      ensureSelection(
        () => importAttachment.document && true,
        async () => {
          let importResponse = await importExamMarks({
            variables: {
              file: importAttachment.document,
              batch: selectedBatchId,
              division: selectedDivision,
              exam: selectedExamId,
              subject: selectedSubjectId,
            },
          });

          if (importResponse?.data?.importMarks) {
            const { status, message } = importResponse?.data?.importMarks;
            if (status == 'success') {
              setAlertDetails({
                message: t('import-marks.success.label'),
                level: SUCCESS,
              });
              if (refetchExamSubjectMarks) refetchExamSubjectMarks(makeExamMarksVariable());
            }
            if (status == 'failure') {
              setAlertDetails({
                message: message || t('error.file.invalid'),
                level: ERROR,
              });
            }
          }
          closeImportModal();
          setCanClick(true);
        },
      );
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  function downloadTemplateFile() {
    ensureSelection(
      () => true,
      () => {
        downloadFileRestAPI(
          `exam-marks/template/${selectedBatchId}/${selectedDivision}/${selectedExamId}/${selectedSubjectId}`,
          `marks.xlsx`,
        );
      },
    );
  }

  function viewStudent(studentId: string) {
    history.push(`/student/${studentId}/0/view`, { from: 'marks' });
  }

  function deleteMarks() {
    console.log('Function not implemented.');
  }

  const handleEditMarks = (item: any) => {
    setCurrentItem(item);
    setIsEditModal(true);
    handleModal(true);
  };
  const handleDeleteMarks = (item: any) => {
    setCurrentItem(item);
    handleDeleteMarksModal(true);
  };

  //split button menu actions
  const handleOnClick = () => {
    setDropDown(false);
  };

  const handleOnArrowClick = () => {
    setDropDown(!dropDown);
  };
  //split button menu actions ends
  const assignMarksFn = async () => {
    closeDropdown();
    try {
      if (studentMarks && studentMarks.size > 0) {
        const payload = {
          examId: urlExamId,
          inputs: Array.from(studentMarks, ([name, value]) => ({
            user: name,
            testMarks: Object.keys(value)
              .filter((key: string) => {
                if (value[key]?.marksObtained) {
                  return true;
                }
                return false;
              })
              .map((key: string) => value[key]),
          })),
        };
        if (payload?.inputs[0]?.testMarks && payload.inputs[0].testMarks.length > 0) {
          setCanClick(false);
          let marksData = await createExamMarks({
            variables: { payload: payload },
          });

          if (marksData?.data) {
            setAlertDetails({ message: UPDATE_SUCCESS.ASSIGN_MARKS, level: SUCCESS });
            if (refetchExamSubjectMarks) refetchExamSubjectMarks(makeExamMarksVariable());
            handleCancel();
          }
          setCanClick(true);
        } else {
          throw new Error(t('error.marks.required'));
        }
      } else {
        throw new Error(t('error.marks.required'));
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const checkMinMaxValue = (value: any, totalMarks: number) => {
    const val = parseInt(value);
    if (Number.isInteger(val)) {
      if ((totalMarks || totalMarks === 0) && val > totalMarks) return totalMarks.toString();
    }
    return value;
  };

  const getMarks = (studentId: string, testId: string) => {
    const studentEntry = studentMarks.get(studentId);
    return studentEntry?.[`${testId}`]?.marksObtained ||
      studentEntry?.[`${testId}`]?.marksObtained == 0
      ? studentEntry?.[`${testId}`].marksObtained
      : '';
  };

  function handleCancel() {
    closeDropdown();
    setViewMode(ViewMode.READ);
    setMarks(row);
  }

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.MARKS_STUDENTS]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchUsers(student: string) {
    let isClear = student?.length === 0;
    persistSearch(!isClear, student);
  }

  const delayedQuery = useCallback(
    debounce(q => searchUsers(q), SEARCH_TIME),
    [],
  );

  const handleSearch = (student: string) => {
    setSearchingStudent(student);
    delayedQuery(student);
  };

  function focusElement(i: number, idx: number, e: any) {
    if ((totalCount || totalCount === 0) && (e.key === 'Enter' || e.key === 'Tab')) {
      const rowCount = Math.min(totalCount, rowLimit);
      if (e.key === 'Enter') {
        if (i === rowCount - 1) {
          inputRef.current[`0-${idx + 1}`].focus();
        } else {
          inputRef.current[`${i + 1}-${idx}`].focus();
        }
      } else {
        e.preventDefault();
        if (idx === testIds.length - 1) {
          inputRef.current[`${i + 1}-0`].focus();
        } else {
          inputRef.current[`${i}-${idx + 1}`].focus();
        }
      }
    }
  }

  function handleRowsPerPageOptions() {
    if (totalCount && totalCount >= 125) {
      rowsPerPageOptions.push(125, 150);
    }
  }

  function closeDropdown() {
    setDropDown(false);
  }
  const regex=generateRegexFromArray(allowedGrades);

  return (
    <LoadContentWrapper style={styles.loadContent}>
      <TopRowsWrapper>
        <SelectionWrapper>
          <MarksSelectionForm
            retrieveValues={retrieveValues}
            dataLimit={dataLimit}
            pagePath={'marks'}
            urlBatchId={urlBatchId}
            urlDivision={urlDivision}
            urlExamId={urlExamId}
            urlSubjectId={urlSubjectId}
            setParentBatchId={setSelectedBatchId}
            setParentClassId={setSelectedClassId}
            setParentClassAlias={setSelectedClassAlias}
            setParentDivision={setSelectedDivision}
            setParentExamId={setSelectedExamId}
            setParentSubjectId={setSelectedSubjectId}
            setParentTestIds={setTestIds}
            setParentHeadCells={setHeadCells}
            parentActionHeadCell={marksType ? marksActionHeadCells : creditActionHeadCells}
            parentHeadCells1={headCells1}
            setParentAllFormValuesAvailable={setAllFormValues}
            setParentSelectedTestsArray={setSelectedTestsArray}
            isLoading={loadingExamSubjectMarks}
            setMarksType={setMarksType}
            setAllowedGrades={setAllowedGrades}
            setIsMarkType={setIsMarkType}
          />
        </SelectionWrapper>

        <ActionButtonWrapper width={width}>
          <CloneAddGradeBtnWrapper>
            {/* Dynamic Download Template */}
            <OutlineButton
              onPress={() => {
                downloadTemplateFile();
              }}
              style={styles.downloadTemplateBtn}
              customDisabled={!allFormValues}>
              <View style={styles.downloadTemplateView}>
                <DownloadFileIcon />
                <NormaltextAtom
                  value={'Template'}
                  color={colors.primary}
                  style={styles.templateText}
                />
              </View>
            </OutlineButton>

            <RelativeWrapper>
              <SplitButton
                buttonHeight={36}
                buttonData={{
                  value: t('import-marks.label'),
                  onClick: handleOnClick,
                  onArrowClick: handleOnArrowClick,
                  onChange: handleImportMarksFileUpload,
                }}
                customDisabled={!allFormValues}
                component={'label'}
              />

              {dropDown && (
                <ClickAwayListener onClickAway={closeDropdown}>
                  <InviteButton
                    onClick={() => {
                      handleModal(true);
                      setDropDown(false);
                      setIsEditModal(false);
                    }}>
                    <MediumText value={t('add-marks.label')} color={colors.primary} />
                  </InviteButton>
                </ClickAwayListener>
              )}
            </RelativeWrapper>
          </CloneAddGradeBtnWrapper>

          {viewMode === ViewMode.EDIT ? (
            <BtnWrapper>
              <OutlineButton
                onPress={handleCancel}
                label={t('cancel.label')}
                style={styles.actionBtn}
              />
              <SecondaryBtn
                onPress={assignMarksFn}
                label={t('save.label')}
                style={styles.actionBtn}
                canLoad={!canClick}
                width={62}
              />
            </BtnWrapper>
          ) : (
            <SecondaryBtn
              onPress={() => {
                closeDropdown();
                setViewMode(ViewMode.EDIT);
              }}
              label={t('assignMarks.label')}
              lines={1}
              customDisabled={!recordsAfterPagingAndSorting()?.length}
              style={styles.assignRollNoBtn}
            />
          )}
        </ActionButtonWrapper>
      </TopRowsWrapper>

      {allFormValues && (
        <SearchWrapper width={width}>
          <Search
            id="marksStudentSearch"
            label={t('searchStudents.label')}
            value={searching}
            handleChange={handleSearch}
            height={32}
          />
        </SearchWrapper>
      )}

      <MarksWrapper>
        {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 ? (
          <TblContainer overflowX="scroll">
            <TblHead dynamicHeadCells={headCells} setHeadCells={setHeadCells} />
            <TableBody>
              {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                <TableRows key={`m${i}`}>
                  <TableCells
                    value={item?.user?.fullName ? properCase(item.user.fullName) : '-'}
                    color={item?.user?.fullName ? colors?.primaryColor : colors.primaryText}
                    clickable={item?.user?.fullName}
                    onPress={() => viewStudent(item?.user?.id)}
                    style={{ minWidth: 250 }}
                  />
                  <TableCells
                    value={
                      (item &&
                        item?.user &&
                        item?.user?.academic &&
                        item?.user?.academic?.rollNo) ||
                      '-'
                    }
                    style={{ minWidth: 125 }}
                    align={'left'}
                  />

                  {/* Dynamic Rows here */}
                  {testIds?.map((test: any, idx: number) => {
                    let element = item?.marks?.marks?.find((mrk: any) => mrk.test.id == test?.id);
                    return (
                      <TableCells align="right" style={{ minWidth: 200 }}>
                        {viewMode === ViewMode.READ ? (
                          <>
                            <NormaltextAtom
                              value={element?.marksObtained || '-'}
                              color={
                                Number.isInteger(element?.test?.passingMarks) &&
                                element?.marksObtained
                                  ? Number(element.marksObtained) < element.test.passingMarks
                                    ? colors.errorColor
                                    : colors.primaryText
                                  : colors.primaryText
                              }
                            />
                            <NormaltextAtom
                              value={element?.grace ? ` + ${element.grace}` : ''}
                              color={colors.yellow}
                            />
                          </>
                        ) : (

                          marksType ? (
                            <>
                                {test.marksType === 'MARK' ? (
                                    <InputWrapper>
                                        <Input
                                            width={100}
                                            align={'right'}
                                            setValue={e => {
                                                if (regexObject.MARKS.test(e.target.value)) {
                                                    setStudentMarks(prevMap => {
                                                        const updatedMap = new Map<string, StudentMarks>(prevMap);
                                                        updatedMap.set(item?.user?.id, {
                                                            ...prevMap.get(item?.user?.id),
                                                            [`${test?.id}`]: {
                                                                test: test?.id,
                                                                marksObtained: checkMinMaxValue(
                                                                    e.target.value,
                                                                    test?.totalMarks,
                                                                ),
                                                            },
                                                        });
                                                        return updatedMap;
                                                    });
                                                }
                                            }}
                                            value={getMarks(item?.user?.id, test?.id)}
                                            className="marksInput"
                                            onkeyDown={(e: any) => {
                                                focusElement(i, idx, e);
                                            }}
                                            inputRef={(ref: any) => (inputRef.current[`${i}-${idx}`] = ref)}
                                        />
                                    </InputWrapper>
                                ) : (
                                    <InputWrapper>
                                        <Input
                                            width={100}
                                            align={'right'}
                                            setValue={e => {
                                                if (regex.test(e.target.value)) {
                                                    setStudentMarks(prevMap => {
                                                        const updatedMap = new Map<string, StudentMarks>(prevMap);
                                                        updatedMap.set(item?.user?.id, {
                                                            ...prevMap.get(item?.user?.id),
                                                            [`${test?.id}`]: {
                                                                test: test?.id,
                                                                marksObtained: checkMinMaxValue(
                                                                    e.target.value,
                                                                    test?.totalMarks,
                                                                ),
                                                            },
                                                        });
                                                        return updatedMap;
                                                    });
                                                }
                                            }}
                                            value={getMarks(item?.user?.id, test?.id)}
                                            className="marksInput"
                                            onkeyDown={(e: any) => {
                                                focusElement(i, idx, e);
                                            }}
                                            inputRef={(ref: any) => (inputRef.current[`${i}-${idx}`] = ref)}
                                        />
                                    </InputWrapper>
                                )}
                            </>
                        ) : (
                            <InputWrapper>
                                <Input
                                    width={100}
                                    align={'right'}
                                    setValue={e => {
                                        if (regexObject.MARKS.test(e.target.value)) {
                                            setStudentMarks(prevMap => {
                                                const updatedMap = new Map<string, StudentMarks>(prevMap);
                                                updatedMap.set(item?.user?.id, {
                                                    ...prevMap.get(item?.user?.id),
                                                    [`${test?.id}`]: {
                                                        test: test?.id,
                                                        marksObtained: checkMinMaxValue(
                                                            e.target.value,
                                                            test?.totalMarks,
                                                        ),
                                                    },
                                                });
                                                return updatedMap;
                                            });
                                        } 
                                    }}
                                    value={getMarks(item?.user?.id, test?.id)}
                                    className="marksInput"
                                    onkeyDown={(e: any) => {
                                        focusElement(i, idx, e);
                                    }}
                                    inputRef={(ref: any) => (inputRef.current[`${i}-${idx}`] = ref)}
                                />
                            </InputWrapper>
                        )
                        )
                        }
                      </TableCells>
                    );
                  })}
                  {marksType ? (
                    <TableCells
                      value={
                        Number.isInteger(item?.marks?.average)
                          ? item.marks.average
                          : item?.marks?.average?.toFixed(3) || '-'
                      }
                      align="right"
                      style={{ minWidth: 200 }}
                    />
                  ) : (
                    <>
                      <TableCells
                        value={getTableValue(item?.marks?.creditsEarned, '-')}
                        align="right"
                        style={{ minWidth: 200 }}
                      />
                      <TableCells
                        value={getTableValue(item?.marks?.creditGrades, '-')}
                        align="right"
                        style={{ minWidth: 225, paddingRight: 150 }}
                      />
                      <TableCells value={item?.marks?.grade || '-'} style={{ minWidth: 125 }} />
                    </>
                  )}

                  {/* Actions */}
                  <TableCells align="right" style={{ minWidth: 40 }}>
                    <ActionIcons>
                      <IconView>
                        <EditIcon
                          onPress={() => handleEditMarks(item)}
                          tooltipTitle={t('edit-marks.label')}
                        />
                      </IconView>
                      {/* Temporary Disable of Delete Btn */}
                      {/* <DeleteIcon onPress={() => handleEditMarks(item)} tooltipTitle={t("edit-marks.label")} /> */}
                    </ActionIcons>
                  </TableCells>
                </TableRows>
              ))}
            </TableBody>
          </TblContainer>
        ) : (
          <TblContainer height={maxHeight}>
            {totalCount == 0 && allFormValues ? (
              <NoRecordsFound colspan={8} maxHeight={0.5 * height} />
            ) : (
              ''
            )}
          </TblContainer>
        )}

        {row && row?.length > 0 && (
          <Pagination
            pathName={`assessment/marks/${selectedBatchId}/${selectedDivision}/${selectedExamId}/${selectedSubjectId}`}
            total={totalCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            rowLimit={rowLimit}
            setRowLimit={setRowLimit}
            hidePagination={totalCount <= rowLimit}
            rowsPerPageOptions={rowsPerPageOptions}
            customHandleRowsPerPageOptions={handleRowsPerPageOptions}
          />
        )}
      </MarksWrapper>

      {/* Modals */}
      <NormalModal
        isSubmitting={!canClick}
        onAlert={null}
        setModalVisible={closeModal}
        Headerpopup={isEditModal ? t('edit-marks.label') : t('add-marks.label')}
        modalVisible={modalState}
        height={700}
        width={800}
        maxWidth={'md'}
        handleSave={handleSubmit(createOrUpdateExamMarks)}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'save.label'}>
        <AddEditMarksForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          clearErrors={clearErrors}
          selectedBatchId={selectedBatchId}
          selectedClassId={selectedClassId}
          selectedDivision={selectedDivision}
          selectedExamId={selectedExamId}
          selectedSubjectId={selectedSubjectId}
          inputTableDataObj={addMarksTestWiseDataObj}
          setInputTableDataObj={setAddMarksTestWiseDataObj}
          testsFieldData={selectedTestsArray}
          setTestsFieldData={setSelectedTestsArray}
          isEditModal={isEditModal}
          currentItem={currentItem}
          allowedGrades={allowedGrades}
          batchMarkingType={marksType}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeImportModal}
        Headerpopup={t('import-marks.label')}
        modalVisible={importModalState}
        handleSave={handleSubmit(importMarks)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={'import.label'}>
        {importAttachment.documentName}
      </NormalModal>

      <DeleteModal
        setModalVisible={() => handleDeleteMarksModal(false)}
        modalVisible={deleteMarksModalState}
        handleSave={() => deleteMarks()}
        Headerpopup={t('delete-marks.label')}
        children={`Are you sure you want to delete (Roll No: ${currentItem?.user?.academic
          ?.rollNo}) ${properCase(currentItem?.user?.personalDetails?.fullName)}'s marks?`}
      />
    </LoadContentWrapper>
  );
}

const TopRowsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10;
`;

const SelectionWrapper = styled.div`
  max-width: ${props => props.theme.rem(72.5)};
`;

const CloneAddGradeBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.theme.rem(2)};
`;

const ActionIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const IconView = styled.div`
  margin-right: 13px;
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const MarksWrapper = styled.div`
  margin-top: 25px;
`;

const BtnWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: flex-end;
`;

const ActionButtonWrapper = styled.div<{ width: number }>`
  display: flex;
  flex-direction: ${props => (props?.width < 1366 ? 'row' : 'column')};
  justify-content: ${props => (props?.width < 1366 ? 'space-between' : 'flex-start')};
  align-items: ${props => (props?.width < 1366 ? 'flex-end' : 'stretch')};
  width: ${props => (props?.width < 1366 ? '100%' : 'auto')};
  gap: 44px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const InviteButton = styled.div`
  border-width: 1px;
  border-radius: 4px;
  padding: 14px 36px 14px 16px;
  justify-content: center;
  position: absolute;
  right: 6px;
  background-color: ${colors.white};
  border-color: ${colors.white};
  box-shadow: 0px 0px 4px ${colors.searchShadow};
  align-self: flex-start;
  cursor: pointer;
`;

const SearchWrapper = styled.div<{ width: number }>`
  align-items: flex-start;
  max-width: ${({ theme }) => theme.rem(28.5)};
  margin-right: 8px;
  margin-top: ${props => (props?.width < 1366 ? 24 : 0)}px;
`;
