import React, { useState, useEffect } from 'react';
import generateLeavingCertificateJSON from '../../../form-json/students/generate-leaving-certificate.json';
import Element from '../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import styled from 'styled-components';
import { Control } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { LeavingCertificateData } from 'src/components/organism/Courses/BatchStudents/types';
import { properCase } from 'src/utils/utility';

interface Props {
  control: Control<FieldValues, object>;
  errors: { [x: string]: any };
  setValue: Function;
  leavingCertificateData: LeavingCertificateData;
}

export default function GenerateLeavingCertificate(props: Props) {
  const { control, errors, setValue, leavingCertificateData } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    if (leavingCertificateData?.fullName) {
      setValue('fullName', properCase(leavingCertificateData?.fullName));
    }
    setValue('dateOfLeaving', format(new Date(), DT.DATE_FORMAT_SLASH));
    if (leavingCertificateData?.caste) {
      setValue('caste', leavingCertificateData.caste);
    }
    if (leavingCertificateData?.nationality) {
      setValue('nationality', leavingCertificateData.nationality);
    }
    if (leavingCertificateData?.dateOfAdmission) {
      setValue('dateOfAdmission', leavingCertificateData.dateOfAdmission);
    }
    setElements(generateLeavingCertificateJSON[0]);
  }, []);

  function handleCustomSelect(id: string, data: any) {
    if (id === 'dateOfLeaving') {
      setValue('dateOfAdmission', null);
      const newData = { ...generateLeavingCertificateJSON[0] };
      newData.fields[4].dateTimePickerMaxDate = data;
      setElements(newData);
    }
  }

  return (
    <>
      {fields ? (
        <ContainerWrapper>
          {fields.map((field: any, i: number) => (
            <InputWrapper>
              <Element
                key={i}
                field={field}
                control={control}
                errors={errors}
                handleCustomSelect={handleCustomSelect}
              />
            </InputWrapper>
          ))}
        </ContainerWrapper>
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </>
  );
}

const SpinnerView = styled.div`
  display: flex;
  width: 724px;
  height: 556px;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 47.5%;
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  justify-content: space-between;
`;