import AbstractFlashList from "src/components/atoms/FlashList";
import { ClickAwayListener } from "@mui/material";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import NotificationBell from "src/components/atoms/Notifications/NotificationBell";
import NotificationItem from "src/components/organism/Notifications/NotificationItem";
import React from "react";
import { colors } from "src/styles/theme/styles";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";

type Props = {
  onPress: () => void;
  closeFn: () => void;
  count: number;
  visibility: boolean;
  loading: boolean;
  data: any[];
  notificationOnPress: (notif: any) => void;
  redirectToNotifications: () => void;
};

function Notifications(props: Props) {
  const {
    onPress,
    closeFn,
    count,
    visibility,
    data,
    notificationOnPress,
    loading,
    redirectToNotifications,
  } = props;
  const { t } = useI18n();

  const ListEmptyComponent = (
    <NoNotifications>
      <MediumTextSelect
        value={t("noNotifications.text")}
        color={colors.primaryText}
      />
    </NoNotifications>
  );

  return (
    <ClickAwayListener onClickAway={closeFn}>
      <NotificationWrapper>
        <NotificationBell count={count} onPress={onPress} />
        {visibility && (
          <NotificationContainer>
            <NotificationTitle>
              <MediumTextSelect
                value={t("notifications.label")}
                fontSize={2}
                color={colors.primaryText}
              />
              {!!data?.length && (
                <ClickableDiv onClick={redirectToNotifications}>
                  <MediumTextSelect
                    value={t("viewAll.text")}
                    color={colors.primary}
                  />
                </ClickableDiv>
              )}
            </NotificationTitle>
            {loading ? (
              <LoaderSpinner />
            ) : (
              <AbstractFlashList
                estimatedItemSize={94}
                data={data}
                ListEmptyComponent={ListEmptyComponent}
              >
                {({ item, index }) => {
                  const notif = item as (typeof data)[0];
                  return (
                    <ClickableDiv
                      key={`notif${index}`}
                      onClick={() => notificationOnPress(notif)}
                    >
                      <NotificationItem key={notif.id} notification={notif} />
                    </ClickableDiv>
                  );
                }}
              </AbstractFlashList>
            )}
          </NotificationContainer>
        )}
      </NotificationWrapper>
    </ClickAwayListener>
  );
}

const NotificationWrapper = styled.div`
  margin: 0px 24px;
  position: relative;
`;

const NotificationContainer = styled.div`
  box-shadow: 0px 0px 5px ${colors.searchShadow};
  max-width: 360px;
  min-width: 360px;
  min-height: ${(props) => props.theme.rem(10)};
  padding: 0px;
  position: absolute;
  right: 0px;
  top: ${(props) => props.theme.rem(4)};
  border-radius: ${(props) => props.theme.rem(0.4)};
  z-index: 1500;
  background-color: ${colors.white};
`;

const NotificationTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.rem(1.6)} ${(props) => props.theme.rem(2.5)};
  border-bottom: 1px solid ${colors.lighterBorder};
`;

const ClickableDiv = styled.div`
  cursor: pointer;
`;

const NoNotifications = styled.div`
  padding: ${(props) => props.theme.rem(2.5)} 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Notifications;
