import React, { useEffect, useState } from 'react';
import {
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';

import { TableBody } from '@mui/material';
import styled from 'styled-components/native';
import useTable from 'src/components/molecules/Table/UseTable';
import { getTableValue } from 'src/utils/utility';
import { TblHeadResults } from 'src/components/organism/Assessment/Results/TblHeadResults';

const headCells1 = [
  {
    id: 'subjectCode',
    label: 'subjectCode.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 125 },
  },
  {
    id: 'subjectName',
    label: 'subjectName.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 176 },
  },
];

let marksHeadCells = [
  {
    id: 'maxMarks',
    label: 'maxMarks.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'passingMarks',
    label: 'passingMarks.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'obtainedMarks',
    label: 'obtainedMarks.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'grace',
    label: 'grace.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'finalObtainedMarks',
    label: 'finalObtainedMarks.label',
    align: 'right',
    disableSorting: true,
  },
];

let actionHeadCells2 = [
  {
    id: 'creditsEarned',
    label: 'credits-earned.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 100, textAlign: 'right' },
  },
  {
    id: 'grade',
    label: 'grade.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 70, paddingLeft: 10 },
  },
  {
    id: 'gradePoint',
    label: 'grade-point.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 100, textAlign: 'right' },
  },
];

export default function ViewResultsNestedTable(props: any) {
  const { data, marksType = false } = props;
  const [row, setRow] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>();
  const [headCells, setHeadCells] = useState([
    ...headCells1,
    ...(marksType ? marksHeadCells : actionHeadCells2)
  ]);  
  const [nestedHeadCells, setNestedHeadCells] = useState([]);

  useEffect(() => {
    setRow(data?.marks);
    setTotalCount(data?.marks?.length);

    if (!marksType) {
      if (data?.marks && data.marks.length) {
        let temp_dynamicHeadcells = [] as any;
        let temp_dynamicHeadcells1 = [] as any;
  
        const header = ['Internal', 'External', 'Total Marks'];
        const headCells = ['Max', 'Min', 'Obt', 'Grace', 'Final'];
  
        header.forEach((item: string, idx: number) => {
          temp_dynamicHeadcells.push({
            id: `${item}${idx}`,
            label: item,
            align: 'center',
            disableSorting: true,
            dynamic: true,
            style: {
              minWidth: 150,
            },
          });
  
          headCells.forEach((hc: string, idx: number) => {
            if (item !== 'Total Marks' || (hc !== 'Grace' && hc !== 'Final')) {
              temp_dynamicHeadcells1.push({
                id: `${hc}${idx}`,
                label: hc,
                align: 'center',
                disableSorting: true,
                dynamic: true,
              });
            }
          });
  
          if (idx !== header.length - 1) {
            temp_dynamicHeadcells.push({
              disableSorting: true,
              style: {
                minWidth: 22,
              },
            });
            temp_dynamicHeadcells1.push({
              disableSorting: true,
              style: {
                minWidth: 22,
              },
            });
          }
        });
  
        actionHeadCells2[1].style = { minWidth: 70, paddingLeft: 10 };
        setHeadCells([...headCells1, ...temp_dynamicHeadcells, ...actionHeadCells2]);
  
        setNestedHeadCells(temp_dynamicHeadcells1);
      } else {
        actionHeadCells2[1].style = { minWidth: 70, paddingLeft: 50 };
        setHeadCells([...headCells1, ...actionHeadCells2]);
  
        setNestedHeadCells([]);
      }
    }
  }, [data, marksType]);

  const { TblHead, TblContainer, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
  );

  return (
    <ParentContainer>
      <TableDiv customStyle={styles.tableDiv}>
        <TblContainer overflowX="scroll" maxHeight={540}>
          {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting().length > 0 && !marksType ? (
            <TblHeadResults
              dynamicHeadCells={headCells}
              dynamicHeadCells2={nestedHeadCells}
              checkBox={false}
            />
          ) : (
            <TblHead setHeadCells={setHeadCells}  dynamicHeadCells={headCells} checkBox={false} />
          )}
          <TableBody>
            {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting().length > 0 ? (
              recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                <>
                  <TableRows
                    key={`vrm${i}`}
                    style={{
                      height: 56,
                    }}>
                    <TableCells value={item?.subject?.code || '-'} />
                    <TableCells value={item?.subject?.name || '-'} />

                    {Boolean(nestedHeadCells && nestedHeadCells?.length) && (
                      <>
                        <TableCells
                          value={getTableValue(item.internal?.totalMarks, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item.internal?.passingMarks, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item.internal?.marksObtained, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item.internal?.grace, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item.internal?.finalMarksObtained, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          textStyle={{
                            minWidth: 22,
                          }}
                        />

                        <TableCells
                          value={getTableValue(item.external?.totalMarks, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item.external?.passingMarks, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item.external?.marksObtained, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item.external?.grace, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item.external?.finalMarksObtained, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          textStyle={{
                            minWidth: 22,
                          }}
                        />

                        <TableCells
                          value={getTableValue(item?.totalMarks, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item?.passingMarks, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                        <TableCells
                          value={getTableValue(item?.finalMarksObtained, '-')}
                          textStyle={{
                            justifyContent: 'center',
                          }}
                        />
                      </>
                    )}

                    {marksType ? (
                      <>
                        <TableCells align={'right'} value={getTableValue(item?.averageTotalMarks, '-')} />
                        <TableCells align={'right'} value={getTableValue(item?.averagePassingMarks, '-')} />
                        <TableCells align={'right'} value={getTableValue(item?.average, '-')} />
                        <TableCells align={'right'} value={getTableValue(item?.totalGrace, '-')} />
                        <TableCells align={'right'} value={getTableValue(item?.averageWithGrace, '-')} />
                      </>
                    ) : (
                      <>
                        <TableCells align={'right'} value={getTableValue(item?.creditsEarned, '-')} />
                        <TableCells value={getTableValue(item?.grade, '-')} style={styles.gradeCell} />
                        <TableCells align={'right'} value={getTableValue(item?.gradePoint, '-')} />
                      </>
                    )}
                  </TableRows>
                </>
              ))
            ) : (
              <NoRecordsFound colspan={6} maxHeight={56} borderBottom={true} />
            )}
          </TableBody>
        </TblContainer>
      </TableDiv>
    </ParentContainer>
  );
}

const styles = {
  tableDiv: {
    marginTop: 0,
  },
  gradeCell: {
    paddingLeft: 80,
  },
};

const ParentContainer = styled.View`
  display: flex;
`;
