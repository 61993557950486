import { createTheme } from '@mui/material/styles';
import { Dimensions, PixelRatio } from 'react-native';
import { isWeb, isIOS } from 'src/constant/device';

import { ThemePalette } from '../types';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
export const mobileScaling = PixelRatio.get() > 2 ? 1.2 : 1;

export const lightPallette: ThemePalette = {
  paletteName: 'light',
  palette: {
    background: '#f7f7f8',
    onBackground: '#000014',
    onBackgroundTint: '#3a3a44',
  },
};

export const darkPallette: ThemePalette = {
  paletteName: 'dark',
  palette: {
    background: '#000014',
    onBackground: '#efeff1',
    onBackgroundTint: '#7f7f8b',
  },
};

export const barColors = [
  "rgba(255, 99, 132, 1)",   // Red
  "rgba(255, 159, 64, 1)",  // Orange
  "rgba(75, 192, 192, 1)",  // Teal
  "rgba(153, 102, 255, 1)", // Purple
  "rgba(255, 205, 86, 1)",  // Yellow
  "rgba(54, 162, 235, 1)",  // Blue
  "rgba(255, 99, 97, 1)",   // Salmon
  "rgba(65, 105, 225, 1)",  // Royal Blue
  "rgba(0, 128, 0, 1)",     // Green
  "rgba(255, 140, 0, 1)",   // Dark Orange
  "rgba(218, 165, 32, 1)",  // Golden Rod
  "rgba(106, 90, 205, 1)",  // Slate Blue
  "rgba(72, 209, 204, 1)",  // Medium Turquoise
  "rgba(255, 20, 147, 1)",  // Deep Pink
  "rgba(50, 205, 50, 1)",   // Lime Green
  "rgba(30, 144, 255, 1)",  // Dodger Blue
  "rgba(255, 165, 0, 1)",   // Orange
  "rgba(138, 43, 226, 1)",  // Blue Violet
  "rgba(127, 255, 212, 1)", // Aquamarine
  "rgba(220, 20, 60, 1)",   // Crimson
  "rgba(148, 0, 211, 1)",   // Dark Violet
  "rgba(100, 149, 237, 1)", // Cornflower Blue
  "rgba(124, 252, 0, 1)",   // Lawn Green
  "rgba(255, 215, 0, 1)",   // Gold
  "rgba(186, 85, 211, 1)",  // Medium Orchid
  "rgba(0, 255, 255, 1)",   // Aqua
  "rgba(32, 178, 170, 1)",  // Light Sea Green
  "rgba(0, 255, 127, 1)",   // Spring Green
  "rgba(255, 105, 180, 1)", // Hot Pink
  "rgba(210, 105, 30, 1)",  // Chocolate
  "rgba(0, 191, 255, 1)",   // Deep Sky Blue
  "rgba(60, 179, 113, 1)",  // Medium Sea Green
  "rgba(240, 128, 128, 1)", // Light Coral
  "rgba(199, 21, 133, 1)",  // Medium Violet Red
  "rgba(221, 160, 221, 1)", // Plum
  "rgba(0, 128, 128, 1)",   // Teal
  "rgba(176, 224, 230, 1)", // Powder Blue
  "rgba(255, 127, 80, 1)",  // Coral
  "rgba(64, 224, 208, 1)",  // Turquoise
  "rgba(216, 191, 216, 1)", // Thistle
  "rgba(152, 251, 152, 1)", // Pale Green
  "rgba(178, 34, 34, 1)",   // Firebrick
  "rgba(34, 139, 34, 1)",   // Forest Green
  "rgba(233, 150, 122, 1)", // Dark Salmon
  "rgba(0, 206, 209, 1)",   // Dark Turquoise
  "rgba(107, 142, 35, 1)",  // Olive Drab
  "rgba(128, 0, 0, 1)",     // Maroon
  "rgba(0, 100, 0, 1)",     // Dark Green
  "rgba(112, 128, 144, 1)", // Slate Grey
  "rgba(70, 130, 180, 1)",  // Steel Blue
];

export const colors = {
  primary: '#2B78CA',
  primaryText: 'rgba(0, 0, 0, 0.8)',
  secondaryText: 'rgba(0, 0, 0, 0.6)',
  white: '#FFFFFF',
  gray: '#808080',
  lighterBlue: '#ecf4f8',
  inputBorder: 'rgba(0, 0, 0, 0.1)',
  greyBg: '#F4F4F5',
  lighterBorder: 'rgba(0, 0, 0, 0.2)',
  tertiaryText: 'rgba(0, 0, 0, 0.4)',
  green: '#029D40',
  yellow: '#FF943F',
  blue: '#0066CA',
  lightBlue: '#3E9CFA',
  red: '#D82451',
  black: '#000000',
  peach: '#EA7B7B',
  darkblue: '#3C3C96',
  lightblue: '#7D7BD7',
  checkRadioOpt: 'rgba(0, 102, 202, 0.3)',
  greyBackground: '#F9F9FB',
  bluishGreyBackground: '#F0F0F5',
  themeLeftMenu: 'rgba(0, 0, 0, 0.6)',
  success: '#CCDFD4',
  borderGrey: 'rgba(0, 0, 0, 0.16)',
  background: '#EEEEEE',
  dashboardBg: '#F9F9FB',
  errorColor: '#CF0000',
  primaryColor: '#2B78CA',
  menuSelectionBg: '#EAF2FA',
  menuIconHover: 'rgba(0, 133, 255, 1)',
  tableDropdwonBorder: 'rgba(43, 120, 202, 0.3)',
  modalBg: 'rgba(0,0,0,0.65)',
  dividerColor: 'rgba(0, 0, 0, 0.15)',
  calendarSelectBg: 'rgba(0, 133, 255, 1)',
  calendarRange: 'rgba(0, 133, 255, 0.1)',
  nativeModalBg: 'rgba(0,0,0,0.65)',
  searchShadow: 'rgba(0, 0, 0, 0.25)',
  attachBg: '#F0F3F5',
  classBg: '#E9EFF6',
  classColor: '#2263A2',
  warningColor: '#FFA018',
  modalClose: '#C4C4C4',
  paginationBg: '#EEEEEE',
  languageIcon: 'rgba(0, 0, 0, 0.6)',
  deviderGrey: 'rgba(0, 0, 0, 0.15)',
  darkOrange: '#F15D1E',
  lightOrange: '#FF9000',
  lime: '#FEE216',
  lightGreen: '#97C516',
  darkGreen: '#0F9C4C',
  borderColor: '#D9D9D9',
  pdf: '#FA1200',
  image: '#40B1E4',
  file: '#332737',
  video: '#E0493A',
  csv: '#34A853',
  excel: '#34A853',
  docx: '#2A5697',
  barChartGrid: 'rgba(76, 110, 245, 0.3)',
  noteBlue: '#4C6EF5',
  noteBg: '#EAF2FB',
  progressBarBackground: '#3E98C7',
  purple: '#9956c8',
  lightCrimson: '#EE738E',
  deepSkyBlue: '#0ABFF3',
};

export const assignmentGradeColors = {
  0: colors.errorColor,
  1: colors.darkOrange,
  2: colors.lightOrange,
  3: colors.lime,
  4: colors.lightGreen,
  5: colors.darkGreen,
} as any;

export const fonts = {
  regular: 'SFProRounded-Regular', //400
  ultraLight: 'SFProRounded-Ultralight', //200
  thin: 'SFProRounded-Thin', //100
  black: 'SFProRounded-Black', //900
  bold: 'SFProRounded-Bold', //700
  heavy: 'SFProRounded-Heavy', //000
  light: 'SFProRounded-Light', //300
  medium: 'SFProRounded-Medium', //500
  semibold: 'SFProRounded-Semibold', //600  //700
};

export const fontWeight = {
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  bold: 'bold',
};

export const bold = fontWeight[600];

export const rem = 10;

export const fontSize = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  xxl: 24,
};

export const fontStyles = {
  mediumText: {
    fontSize: fontSize.sm,
    // lineHeight: 24,
    fontFamily: fonts.regular,
    color: colors.primaryText,
  },
  mediumTextSelect: {
    fontSize: fontSize.md,
    fontFamily: fonts.regular,

    // lineHeight: 24,
    fontWeight: 'bold',
    color: colors.primaryText,
  },
  normalText: {
    fontSize: fontSize.sm,
    fontFamily: fonts.regular,

    // lineHeight: 20,
    color: colors.primaryText,
  },
  normalTextSelect: {
    fontSize: fontSize.sm,
    fontFamily: fonts.regular,

    // lineHeight: 20,
    fontWeight: 'bold',
    color: colors.primaryText,
  },
  smallText: {
    fontSize: fontSize.xs,
    lineHeight: 16,
    fontFamily: fonts.regular,

    color: colors.primaryText,
  },
  smallTextSelect: {
    fontSize: fontSize.xs,
    // lineHeight: 16,
    fontWeight: 'bold',
    fontFamily: fonts.regular,

    color: colors.primaryText,
  },
  newsTitle: {
    fontSize: fontSize.md,
    lineHeight: 20,
    fontWeight: 'bold',
    color: colors.primaryText,
    fontFamily: fonts.regular,
  },
  h2: {
    fontSize: 32,
    // lineHeight: 32,
    // fontWeight: 'bold',
    color: colors.primaryText,
    fontFamily: fonts.regular,
  },
  h3: {
    fontSize: fontSize.xxl,
    lineHeight: 32,
    fontWeight: 'bold',
    color: colors.primaryText,
    fontFamily: fonts.regular,
  },
  h5: {
    fontSize: fontSize.xl,
    // lineHeight: 26,
    fontWeight: 'bold',
    color: colors.primaryText,
    fontFamily: fonts.regular,
  },
  h6: {
    fontSize: fontSize.xl,
    // lineHeight: 24,
    fontWeight: 'normal',
    color: colors.primaryText,
    fontFamily: fonts.regular,
  },
};

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 640,
  lg: 1280,
  xl: 1920,
  input: 1440,
  desktop: 1440,
  bigdesktop: 1441,
};

export const stepperFormWidth = 740;
export const nativeContentWidth = height - 250;
export const mediumDevice = width > breakpoints.md;
export const mediumSize = width > breakpoints.md && width <= breakpoints.desktop;

export const largeDevice = width > breakpoints.desktop;
export const mobileWeb = width <= breakpoints.md;
export const teacherAssignment = height - 360;
export const studentAssignment = height - 260;
export const sidebarMargin = 280;

export const textStyles = (fontSize, fontWeight, color) => {
  return {
    fontSize,
    fontWeight,
    color,
  };
};

export const MuiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(','),
  },
});

export const boxShadow = () => {
  if (isWeb && width > breakpoints.md) {
    return { boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.16)' };
  } else {
    return { elevation: 0 };
  }
};

export const nativeShadow = (elevation = 7, shadowRadius = 20) => {
  if (isIOS || isWeb) {
    return {
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.9,
      shadowRadius: shadowRadius,
      shadowColor: colors?.lighterBorder,
    };
  } else return { elevation };
};
