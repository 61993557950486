import { TFormMode, TFormState } from "src/types";

export const qualificationHeadCells = [
  {
    id: "qualification_name",
    label: "qualificationName.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "qualification_alias",
    label: "qualificationAlias.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "qualification_level",
    label: "qualificationLevel.label",
    align: "right",
    disableSorting: true,
    style: { minWidth: "35%" },
  },
  {
    id: "action",
    label: "action.label",
    align: "right",
    disableSorting: true,
  },
];

export type qualificationListing = {
  id: string;
  name: string;
  alias: string;
  level: number;
};

export type qualificationFormState = {
  state: TFormState;
  mode: TFormMode;
  data?: qualificationListing;
};

export type qualificationListingServerResponse = {
  data: qualificationListing[];
  totalCount: number;
};
