import React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from 'src/styles/theme/styles';

export default function ModalClose() {
  return <View style={[styles.container]} />;
}

const styles = StyleSheet.create({
  container: {
    height: 5,
    width: 40,
    marginVertical: 10,
    alignSelf: 'center',
    borderRadius: 5,
    backgroundColor: colors.modalClose,
  },
});
