import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { TableBody } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faLink, faPencil } from '@fortawesome/pro-regular-svg-icons';

import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import { useI18n } from 'src/i18n/hooks';
import { Switch, Route, useRouteMatch, Redirect, useHistory, useParams } from 'src/routes/routing';
import styled from 'styled-components/native';
import Search from 'src/components/atoms/SearchBar/index.web';
import {
  TableActions,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { useThemeSystem } from 'src/contexts/theme-context';
import { getBanksDetails, useLinkAccountMutation, useQueryBankDetails } from 'src/graphql/payment';
import LinkAccount from './LinkAccount';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import { useForm } from 'react-hook-form';
import { getBanksFilter, getCommonNameSearchFilter } from 'src/components/services/filters';
import { createSearchFilter, debounce } from 'src/components/services';
import ERROR_MSG from 'src/constant/error';
import { ADD_SUCCESS } from 'src/constant/message';
import { AUTOCOMPLETE_MODULE, ERROR, limit, LINK_STATUS, SUCCESS } from 'src/constant';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useFilter } from 'src/contexts/filter-context';
import Paginations from 'src/components/atoms/Pagination/Paginations.web';
import { isWeb } from 'src/constant/device';

const headCellsPending = [
  {
    id: 'account_name',
    label: 'accountName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'institute_name',
    label: "instituteName.label",
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: 'actions.label',
    align: 'right',
    disableSorting: true,
  },
];

const headCellsLinked = [
  {
    id: 'account_name',
    label: 'accountName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'institute_name',
    label: 'instituteName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'linked_id',
    label: 'linkedId.label',
    align: 'left',
    disableSorting: true,
  },
];

const AccountsLink = () => {
  const { t } = useI18n();
  const history = useHistory();
  const match = isWeb ? useRouteMatch() : '';
  const { theme } = useThemeSystem();
  const [linkAccountModal, setLinkAccountModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const isSubmitting = useRef(false);
  const [canClick, setCanClick] = useState<boolean>(true);

  let searchValue = '' as string;
  const rowLimit: any = parseInt(50);
  const [page, setPage] = useState<any>(1);
  const [tab, setTab] = useState<any>(1);
  const { filters, setFilter } = useFilter();
  const [locationKeys, setLocationKeys] = useState([]);
  const { alertState, setAlertDetails } = useAlertSystem();
  const { setHeading } = useHeaderTitle();
  const { query: getBanks, data: bankDetailsData, loading } = useQueryBankDetails(getBanksDetails);
  const { mutate: linkAccountMutation } = useLinkAccountMutation();
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const isPending = location && location.pathname.includes('pending');
  const row = bankDetailsData?.bankDetails?.data;
  const totalCount = bankDetailsData?.bankDetails?.totalCount;

  if (filters && filters?.bank_details?.on) {
    searchValue = filters?.bank_details?.search;
  }

  const maxHeight = 440;

  useEffect(() => {
    if (location?.pathname?.includes('pending')) {
      setTab(1);
      const num = location?.pathname?.split("/").pop()
      setPage(num);
    }

    if (location?.pathname?.includes('linked')) {
      setTab(2);
      const num = location?.pathname?.split("/").pop()
      setPage(num);
    }
    setTitle();
  }, []);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'POP') {
        setPage(parseInt(history.location.pathname.match(/\d+$/)[0], 10));
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    handleFetch();
  }, [page, searchValue, isPending, locationKeys]);

  useEffect(() => {
    handleChangeTabs();
  }, [tab])

  function changeTabs(tab: any) {
    setPage(1);
    setTab(tab);
  }

  function handleChangeTabs() {
    if (tab === 1) {
      history.push(`${match.url}/pending/limit/${rowLimit}/page/${page}`);
    } else {
      history.push(`${match.url}/linked/limit/${rowLimit}/page/${page}`);
    }
  }

  async function setTitle() {
    setHeading([
      {
        text: t('accounts.label'),
        url: '',
      },
    ]);
  }

  function handleFetch() {
    const filters = getBanksFilter(searchValue);
    let bankFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    const linkStatus = isPending ? { ne: LINK_STATUS[1] } : { eq: LINK_STATUS[1] };
    if (searchValue) {
      bankFilter = {
        filters: {
          and: {
            name: { regex: searchValue },
            linkStatus,
          }
        }, limit, skip: (page - 1) * rowLimit
      }
    } else {
      bankFilter = { filters: { linkStatus }, ...bankFilter }
    }
    getBanks({ variables: bankFilter });
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    isPending ? headCellsPending : headCellsLinked,
    totalCount,
    page,
    maxHeight,
  );

  const closeModal = () => {
    reset({});
    clearErrors();
    setLinkAccountModal(false);
  };

  const handleLinkAccountModal = (item: any) => {
    setLinkAccountModal(true);
    setCurrentItem(item);
  };

  const linkAccount = async (formData: any) => {
    setCanClick(false);
    isSubmitting.current = true;
    try {
      const payload = {
        id: currentItem?.id,
        razorPayRoute: formData.link_id,
      };

      const response = await linkAccountMutation({
        variables: { payload },
      });

      if (response.data) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.LINK_ACCOUNT, level: SUCCESS });
        handleFetch();
      }
    } catch (e: any) {
      setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
      setCanClick(true);
    }
    isSubmitting.current = false;
    setCanClick(true);
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.BANK_DETAILS]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchAccount(bank_details: string) {
    let isClear = bank_details?.length === 0;
    persistSearch(!isClear, bank_details);
  }

  const handleSearch = debounce((bank_details: string) => {
    searchAccount(bank_details);
  }, 200);

  return (
    <>
      <LoadContentWrapper>
        <ModalTabs
          onSelectSwitch={t => {
            changeTabs(t);
          }}
          option1={t('pendingAccounts.label')}
          option2={t('linkedAccounts.label')}
          selectionMode={isPending ? 1 : 2}
          roundCorner={false}
          style={[styles.tabStyle]}
          onChange={changeTabs}
          tabswidth={100}
        />
        <Switch>
          <Route path={`${match.url}/pending/limit/${rowLimit}/page/${page}`}>
            <SearchWrapper>
              <Search
                id="courseSearch"
                handleChange={handleSearch}
                value={searchValue || ''}
                label={'Search'}
              />
            </SearchWrapper>

            <TableWrapper>
              <TblContainer>
                <TblHead dynamicHeadCells={headCellsPending} />
                <TableBody>
                  {recordsAfterPagingAndSorting ? (
                    recordsAfterPagingAndSorting()?.map((item: any) => (
                      <TableRows key={item.id}>
                        <TableCells value={item.name} />
                        <TableCells value={item.institute.name} />
                        <TableActions align="right">
                          <TableActionWrapper>
                            <TouchableOpacity onPress={() => handleLinkAccountModal(item)}>
                              <FontAwesomeIcon icon={faLink} color={theme?.table?.editIconColor} />
                            </TouchableOpacity>
                          </TableActionWrapper>
                        </TableActions>
                      </TableRows>
                    ))
                  ) : (
                    <TableRows key={'norecords'} style={{ height: maxHeight - 70 }}>
                      <TableCells colspan={3} align="center" style={{ borderBottom: 'none' }}>
                        {t('no-records-found.text')}
                      </TableCells>
                    </TableRows>
                  )}
                </TableBody>
              </TblContainer>
            </TableWrapper>
            {loading && (
              <LoaderSpinner styles={{ position: 'absolute', top: '50%', left: '50%' }} />
            )}
            {!loading && (
              <Paginations
                pathName={'accounts/pending'}
                count={Math.ceil(totalCount / rowLimit)}
                total={totalCount}
                page={page}
                rowLimit={rowLimit}
                hidePagination={totalCount <= rowLimit}
                setPage={setPage}
              />
            )}
          </Route>
          <Route path={`${match.url}/linked/limit/${rowLimit}/page/${page}`}>
            <SearchWrapper>
              <Search
                id="courseSearch"
                handleChange={handleSearch}
                value={searchValue || ''}
                label={'Search'}
              />
            </SearchWrapper>
            <TableWrapper>
              <TblContainer>
                <TblHead dynamicHeadCells={headCellsLinked} />
                <TableBody>
                  {recordsAfterPagingAndSorting ? (
                    recordsAfterPagingAndSorting()?.map((item: any) => (
                      <TableRows key={item.id}>
                        <TableCells value={item.name} />
                        <TableCells value={item.institute.name} />
                        <TableCells value={item.razorpayRoute} />
                      </TableRows>
                    ))
                  ) : (
                    <TableRows key={'norecords'} style={{ height: maxHeight - 70 }}>
                      <TableCells colspan={3} align="center" style={{ borderBottom: 'none' }}>
                        {t('no-records-found.text')}
                      </TableCells>
                    </TableRows>
                  )}
                </TableBody>
              </TblContainer>
            </TableWrapper>
            {loading && (
              <LoaderSpinner styles={{ position: 'absolute', top: '50%', left: '50%' }} />
            )}
            {!loading && (
              <Paginations
                pathName={'accounts/linked'}
                count={Math.ceil(totalCount / rowLimit)}
                total={totalCount}
                page={page}
                rowLimit={rowLimit}
                hidePagination={totalCount <= rowLimit}
                setPage={setPage}
              />
            )}
          </Route>
          <Route exact path={`${match.url}`}>
            <Redirect to={`${match.url}/pending/limit/${rowLimit}/page/${page}`} />
          </Route>
        </Switch>
      </LoadContentWrapper>

      <NormalModal
        onAlert={alertState}
        setModalVisible={closeModal}
        modalVisible={linkAccountModal}
        handleSave={handleSubmit(linkAccount)}
        Headerpopup={'Link Account'}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={t('Save')}
        width={800}
        height={360}
        maxWidth={'lg'}>
        <LinkAccount control={control} errors={errors} data={currentItem} />
      </NormalModal>
    </>
  );
};

const styles = StyleSheet.create({
  tabStyle: {
    top: 0,
    left: 0,
    backgroundColor: 'white',
    marginBottom: 24,
  },
});

const SearchWrapper = styled.View``;

const TableWrapper = styled.View`
  height: 73%;
`;

const TableActionWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const TableActionWrapper2 = styled.View`
  flex-direction: row;
  justify-content: flex-start;
`;

export default AccountsLink;
