import { getStorageFunction } from './storage-service';
import { KEYS } from '../../constant/key';

export async function getProtectedImage(imageUrl: any) {
  try {
    const response = await fetchWithAuthentication(imageUrl);
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    return objectUrl;
  } catch (error) {
    console.log(error.message);
  }
}

export async function fetchWithAuthentication(url: string) {
  const token = await getStorageFunction(KEYS.ACCESS_TOKEN);
  const headers = new Headers();
  headers.set('authorization', `Bearer ${token}`);
  return fetch(url, { headers });
}
