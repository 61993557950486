import React from 'react';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormalTextAtom from 'src/components/atoms/Text/NormalTextAtom';
import ViewResultsNestedTable from './ViewResultsNestedTable';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import { getTableValue, properCase } from 'src/utils/utility';
import HeaderSix from 'src/components/atoms/Text/HeaderFive';
import { userInformation } from 'src/utils/manageState';
import { USER_TYPE } from 'src/constant';
import ViewResultsResolution from './ViewResultsResolution';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';

export default function ViewResultModal(props: any) {
  const { t } = useI18n();
  const { selectedExamName = '', currentItem, type = 'result', marksType } = props;
  const currentUserObject = userInformation();
  const { userType, userDetail } = currentUserObject;
  const userDetails = userType === USER_TYPE.STUDENT ? userDetail : currentItem?.user;
  const fullName =
    userType === USER_TYPE.STUDENT
      ? userDetail?.fullName
      : currentItem?.user?.personalDetails?.fullName;

  return (
    <>
      <HeaderSix value={'Student Details'} color={colors.primaryText} />
      <InfoBlockContainer>
        <InfoBlock>
          <SmallTextAtom value={t('name.label')} fontWeight={'400'} />
          <MediumTextSelect
            value={fullName ? properCase(fullName) : '-'}
            fontWeight={'600'}
            color={colors.primaryText}
          />
        </InfoBlock>
        <InfoBlock>
          <SmallTextAtom value={t('rollno.label')} fontWeight={'400'} />
          <MediumTextSelect
            value={userDetails?.academic?.rollNo || userDetails?.rollNo || '-'}
            fontWeight={'600'}
            color={colors.primaryText}
          />
        </InfoBlock>
        <InfoBlock>
          <SmallTextAtom value={t('studentId.label')} fontWeight={'400'} />
          <MediumTextSelect
            value={userDetails?.accountId || '-'}
            fontWeight={'600'}
            color={colors.primaryText}
          />
        </InfoBlock>
        <InfoBlock>
          <SmallTextAtom value={t('enrollmentNo.label')} fontWeight={'400'} />
          <MediumTextSelect
            value={currentItem?.marksheet?.enrollmentNo || '-'}
            fontWeight={'600'}
            color={colors.primaryText}
          />
        </InfoBlock>
        <InfoBlock>
          <NormalTextAtom value={t('seatNo.label')} fontWeight={'400'} />
          <MediumTextSelect
            value={currentItem?.marksheet?.seatNo || '-'}
            fontWeight={'600'}
            color={colors.primaryText}
          />
        </InfoBlock>
      </InfoBlockContainer>

      <HeaderSix value={'Exam Details'} color={colors.primaryText} />
      <InfoBlockContainer>
        <ExamBlock>
          <SmallTextAtom value={t('examName.label')} fontWeight={'400'} />
          <MediumTextSelect
            value={selectedExamName || currentItem?.marksheet?.exam?.name || '-'}
            fontWeight={'600'}
            color={colors.primaryText}
          />
        </ExamBlock>

        <InfoBlock>
          <SmallTextAtom value={t('marksObtained.label')} fontWeight={'400'} />
          <MarksObtainedContainer>
            <MediumTextSelect
              value={getTableValue(
                marksType
                  ? currentItem?.marksheet?.totalAggregateMarks
                  : currentItem?.marksheet?.finalMarksObtained,
                '-',
              )}
              fontWeight={'600'}
              color={colors.primaryText}
            />
            {Boolean(marksType && currentItem?.marksheet?.totalGraceInNumber) && (
              <NormalTextAtom
                value={` + ${currentItem.marksheet.totalGraceInNumber}`}
                color={colors.yellow} />
            )}
          </MarksObtainedContainer>
        </InfoBlock>

        <InfoBlock>
          <SmallTextAtom value={t('totalMarks.label')} fontWeight={'400'} />
          <MediumTextSelect
            value={getTableValue(
              marksType
                ? currentItem?.marksheet?.averageTotalMarks
                : currentItem?.marksheet?.totalMarks,
              '-',
            )}
            fontWeight={'600'}
            color={colors.primaryText}
          />
        </InfoBlock>

        <InfoBlock>
          <SmallTextAtom value={t('average.label')} fontWeight={'400'} />
          <MediumTextSelect
            value={
              marksType
                ? getTableValue(currentItem?.marksheet?.averageOnAggregateMarks, '-')
                : getTableValue(currentItem?.marksheet?.average, '-')
            }
            fontWeight={'600'}
            color={colors.primaryText}
          />
        </InfoBlock>

        {!marksType && (
          <>
            <InfoBlock>
              <SmallTextAtom value={t('sgpa.label')} fontWeight={'400'} />
              <MediumTextSelect
                value={getTableValue(currentItem?.marksheet?.sgpa, '-')}
                fontWeight={'600'}
                color={colors.primaryText}
              />
            </InfoBlock>

            <InfoBlock>
              <SmallTextAtom value={t('grade.label')} fontWeight={'400'} />
              <MediumTextSelect
                value={currentItem?.marksheet?.grade || '-'}
                fontWeight={'600'}
                color={colors.primaryText}
              />
            </InfoBlock>

            <InfoBlock>
              <SmallTextAtom value={t('cgpa.label')} fontWeight={'400'} />
              <MediumTextSelect
                value={getTableValue(currentItem?.marksheet?.cgpa, '-')}
                fontWeight={'600'}
                color={colors.primaryText}
              />
            </InfoBlock>
          </>
        )}
      </InfoBlockContainer>

      {type === 'result' ? (
        <ViewResultsNestedTable data={currentItem.marksheet} marksType={marksType} />
      ) : (
        <ViewResultsResolution data={currentItem?.marksheet?.subjectMarks} marksType={marksType}/>
      )}
    </>
  );
}

const MarksObtainedContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoBlockContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const InfoBlock = styled.View`
  width: 200px;
`;

const ExamBlock = styled.View`
  min-width: 412px;
  max-width: 412px;
`;