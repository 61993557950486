import React, { useEffect } from 'react';
import styled from 'styled-components/native';

import { Icon } from 'src/components/atoms/Icon/Icon';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { height, isAndroidUserAgent, isIOSUserAgent } from 'src/constant/device';
import { colors } from 'src/styles/theme/styles';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { TouchableOpacity } from 'react-native';

const UnsupportedResolution = () => {
  useEffect(() => {
    launch();
  }, []);

  const launch = () => {
    if (isAndroidUserAgent) {
      window.location.href = 'intent://edba/#Intent;scheme=edba_app;package=com.edba_app;end';
    } else if (isIOSUserAgent) {
      window.location.href = 'edbaapp://';
    }
  }

  const download = () => {
    if (isAndroidUserAgent) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.edba_app';
    } else if (isIOSUserAgent) {
      window.location.href = 'https://apps.apple.com/in/app/edb%C4%81/id1598855380';
    }
  }

  return (
    <Container>
      <LogoView>
        <Icon name="edba-logo" />
      </LogoView>
      <ContentView>
        <TextContainerView>
          <Icon name="unsupported" />
          <TextView>
            <MediumTextSelect
              value={'Screen not supported!!!'}
              color={colors.secondaryText}
              fontSize={2.4}
              fontWeight={'600'}
              lineHeight={3.2}
            />
            <Text2View>
              <MediumTextSelect
                value={'We do not support on small device browser. Please continue with mobile app'}
                color={colors.tertiaryText}
                textAlign={'center'}
                lineHeight={2.4}
              />
            </Text2View>
            {isAndroidUserAgent || isIOSUserAgent ? (
              <SecondaryBtn
                onPress={download}
                label={isAndroidUserAgent ? 'Download from Play Store' : 'Download from App Store'}
                style={{ marginTop: 38 }}
              />
            ) : (
              <>
                <SecondaryBtn
                  onPress={() => window.location.href='https://play.google.com/store/apps/details?id=com.edba_app'}
                  label={'Download from Play Store'}
                  style={{ marginTop: 38 }}
                />
                <SecondaryBtn
                  onPress={() => window.location.href='https://apps.apple.com/in/app/edb%C4%81/id1598855380'}
                  label={'Download from App Store'}
                  style={{ marginTop: 12 }}
                />
              </>
            )}
            <Text3View>
              <MediumTextSelect
                value={'Already Installed Edba? '}
                color={colors.tertiaryText}
                textAlign={'center'}
                lineHeight={2.4}
              />
              <TouchableOpacity onPress={launch}>
                <MediumTextSelect value={'Launch App'} color={colors.primary}/>
              </TouchableOpacity>
            </Text3View>
          </TextView>
        </TextContainerView>
      </ContentView>
    </Container>
  );
};

const Container = styled.View`
  height: 100%;
  flex: 1;
  justify-content: center;
`;

const LogoView = styled.View`
  align-items: center;
  justify-content: center;
`;

const ContentView = styled.View`
  padding-top: 58px;
  align-items: center;
  justify-content: center;
`;

const TextContainerView = styled.View`
  justify-content: center;
  align-items: center;
`;

const TextView = styled.View`
  margin-top: 12px;
  text-align: center;
  align-items: center;
`;

const Text2View = styled.View`
  max-width: 80%;
`;

const Text3View = styled.View`
  flex-direction: row;
  margin-top: 12px;
`;

export default UnsupportedResolution;