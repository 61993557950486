import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { useForm } from 'react-hook-form';
import { useI18n } from 'src/i18n/hooks';
import AddBankDetails from 'src/components/molecules/Payment/Institute/AddBankDetailsForm';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import { validation } from 'src/constant/validation';
import { AUTOCOMPLETE_MODULE, ERROR, FileCategory, limit, MANUAL, SUCCESS  } from 'src/constant';
import { TableDiv } from 'src/components/molecules/Table/TableAtom';
import BankDetailsTable from 'src/components/molecules/Table/BankDetailsTable';
import { height } from 'src/constant/device';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import Search from 'src/components/atoms/SearchBar/index.web';
import { useMutation } from '@apollo/client';
import {
  CreateBankAccountDetails,
  getBanksDetails,
  useQueryBankDetails,
} from 'src/graphql/payment';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ADD_SUCCESS } from 'src/constant/message';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components/native';
import { useHeaderTitle } from 'src/contexts/header-context';
import { getCommonNameSearchFilter } from 'src/components/services/filters';
import { createSearchFilter } from 'src/components/services';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import useSearchFilter from 'src/utils/customHooks/useSearchFilter';
import { CustomReactNativeFile } from 'src/components/molecules/FileUpload/type';
import { createDocumentObject } from 'src/utils/utility';

const resetObj = {
  bank_name: null,
  account_type: null,
  account_number: null,
  ifsc_code: null,
  account_name: null,
  account_email: null,
  beneficiary_name: null,
  confirm_account_number: null,
  attachment: null,
};

export default () => {
  const [editModal, setEditModal] = useState(false);
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();
  const { setHeading } = useHeaderTitle();
  const [fileObject, setFileObject] = useState<any>({});

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
    setValue,
    clearErrors
  } = useForm();

  const { query: getBanks, data: bankDetailsData } = useQueryBankDetails(getBanksDetails);

  const [createLinkingBankAccountDetails] = useMutation(CreateBankAccountDetails);

  const { searchValue, handleSearch, page, rowLimit }: any = useSearchFilter(
    false,
    handleFetch,
    AUTOCOMPLETE_MODULE.BANK_DETAILS,
  );
  
  const rowDefaultLimit =  parseInt(rowLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimitCurrent, setRowLimit] = useState(rowDefaultLimit);
  const [totalCount, setTotalCount] = useState<number|null>(bankDetailsData?.bankDetails?.totalCount ?? null);
  
  useEffect(() => {
    const count = bankDetailsData?.bankDetails?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [bankDetailsData?.bankDetails?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  function setTitle() {
    setHeading([
      {
        text: t('accountDetails.label'),
        url: '',
      },
    ]);
  }

  async function AddBanksInBanksDetailsList(bankdetails: any) {
    if (bankdetails.account_number !== bankdetails.confirm_account_number) {
      setError('confirm_account_number', {
        type: MANUAL,
        message: validation.CONFIRM_ACCOUNT_NUMBER_NOT_MATCH,
      });
    } else {
      const payload: any = {
        bankName: bankdetails.bank_name.value,
        benificiaryName: bankdetails.beneficiary_name,
        accountNumber: parseFloat(bankdetails.account_number),
        confirmAccountNumber: parseFloat(bankdetails.confirm_account_number),
        ifscCode: bankdetails.ifsc_code,
        name: bankdetails.account_name,
        type: bankdetails.account_type.value === 1 ? 'SAVINGS' : 'CURRENT',
      };

      if (bankdetails?.attachment) {
        payload['proofDocuments'] = [await createDocumentObject(
          bankdetails?.attachment,
          FileCategory.Proof,
          'BANK_PROOF'
        )];
      } else {
        payload['proofDocuments'] = [];
        setError('attachment', { message: t(validation.ATTACHMENT_REQUIRED) });
      }

      if (bankdetails.account_email) {
        payload.email = bankdetails.account_email;
      }

      try {
        const accountInfo = await createLinkingBankAccountDetails({
          variables: {
            payload,
          },
        });
        if (accountInfo) {
          getBanks({ variables: { limit: 0 } });
          setAlertDetails({ message: ADD_SUCCESS.BANK_ACCOUNT_SUCCESSFULL, level: SUCCESS });
          setFileObject({});
          setEditModal(false);
          reset(resetObj);
        }
      } catch (err: any) {
        setAlertDetails({ message: err.message, level: ERROR });
      }
    }
  };

  const closeModal = () => {
    setFileObject({});
    reset(resetObj);
    setEditModal(false);
  };

  function handleFetch() {
    const filters = getCommonNameSearchFilter(searchValue);
    const bankFilter = createSearchFilter(rowLimitCurrent, (currentPage - 1) * rowLimitCurrent, filters);
    getBanks({ variables: bankFilter });
  }

  const handleFilesAttach = (id: any) => {
    return (files: CustomReactNativeFile[]) => {
      if (files?.length > 0) {
        let file: any = files[0];
        setValue(id, file);
        maintainFileObject(file, id);
        clearErrors('attachment');
      }
    };
  }

  function maintainFileObject(data: object, id: string) {
    let fileobj = { ...fileObject };
    fileobj[id] = data;
    setFileObject(fileobj);
  }

  const handleFileRemove = (id: string) => {
    return (file: CustomReactNativeFile) => {
      setValue(id, {});
      maintainFileObject({}, id);
    };
  };

  useEffect(() => {
    setTitle();
  }, []);

  return (
    <>
      <LoadContentWrapper>
        <View style={styles.flexRow}>
          <View style={styles.leftPart}>
            <Search
              id="bankSearch"
              value={searchValue || ''}
              handleChange={handleSearch}
              label={t('searchBanks.text')}
            />
          </View>
          <View style={styles.rightPart}>
            <SecondaryBtn
              label={t('addAccount.text')}
              onPress={() => {
                setEditModal((prevState) => !prevState);
              }}
            />
          </View>
        </View>
        {!bankDetailsData ? (
          <LoadSpinnerWrapper>
            <LoaderSpinner />
          </LoadSpinnerWrapper>
        ) : (
          <>
            <TableDiv>
              <div style={{ height: height - 310, overflowX: 'auto' }}>
                <BankDetailsTable data={bankDetailsData?.bankDetails?.data} />
              </div>
            </TableDiv>
          </>
        )}
        <Pagination
          pathName={'payments/account_details'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimitCurrent}
          setRowLimit={setRowLimit}
          hidePagination={!!(totalCount && totalCount <= rowLimitCurrent)}
        />
      </LoadContentWrapper>
      <NormalModal
        setModalVisible={closeModal}
        modalVisible={editModal}
        handleSave={handleSubmit(AddBanksInBanksDetailsList)}
        Headerpopup={t('addAccountDetails.text')}
        width={578}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="save.label"
        lineHeight={32}>
        <AddBankDetails
          control={control}
          errors={errors}
          setError={setError}
          setValue={setValue}
          handleFileRemove={handleFileRemove}
          fileObjects={fileObject}
          handleFilesAttach={handleFilesAttach}
          clearErrors={clearErrors}
        />
      </NormalModal>
    </>
  );
};
const LoadSpinnerWrapper = styled.View`
  justify-content: center;
  align-items: center;
  height: 450px;
`;
const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  rightPart: {
    flex: 3,
    alignItems: 'flex-end',
  },
});
