import React, { useEffect, useState } from "react";

import { DropdownOptionsType } from "src/types";
import Element from "src/components/molecules/Forms/ApplicationElement";
import FormJSON from "src/form-json/admission/direct-invite-form.json";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index";
import styled from "styled-components/native";

interface PropType {
  control: any;
  errors: any;
  setValue: any;
  subjectGroupOptions: DropdownOptionsType[];
}

export default function DirectApplicantInviteForm(props: PropType) {
  const { control, errors, setValue, subjectGroupOptions } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    setValue("subjectGroup", null);
    setElements(FormJSON[0]);
  }, []);

  useEffect(() => {
    if (subjectGroupOptions && subjectGroupOptions?.length > 0) {
      const form = { ...FormJSON[0] };
      form.fields[4].option = subjectGroupOptions;
      setElements(form);
    }
  }, [subjectGroupOptions]);

  return (
    <Container>
      {fields ? (
        fields.map((field: any, i: number) => (
          <ElementWrapper>
            <Element
              key={`e${i}`}
              field={field}
              control={control}
              errors={errors}
            />
          </ElementWrapper>
        ))
      ) : (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      )}
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ElementWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SpinnerWrapper = styled(ElementWrapper)`
  height: 412;
  justify-content: center;
  align-items: center;
`;
