/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect } from 'react';
import formJSON from '../../../form-json/academics/course-settings.json';
import Element from '../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components';
import { Control, FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types';
import Chip from 'src/components/atoms/Chip';
import { useGetCourseSettings } from 'src/graphql/academics/courses';
import { userInformation } from 'src/utils/manageState';
import { useGetBatchSettings } from 'src/graphql/academics/batch';

interface Props {
  control: Control<FieldValues, object>;
  errors: { [x: string]: any };
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  type: string;
  id: string;
}

export default function CourseSettingsForm(props: Props) {
  const { control, setValue, errors, watch, type, id } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};
  const {registration_no_prefix, registration_no} = watch();
  const { getCourseSettings, data: courseSettingsData, loading: courseSettingsLoading } = useGetCourseSettings();
  const { getBatchSettings, data: batchSettingsData, loading: batchSettingsLoading } = useGetBatchSettings();
  const currentUserObject = userInformation();
  const { userDetail } = currentUserObject;

  const data = type == 'course' ? courseSettingsData?.courseSetting : batchSettingsData?.batchSetting;
  const loading = type == 'course' ? courseSettingsLoading : batchSettingsLoading;

  useEffect(() => {
    if (id && type) {
      const apiRequest = type == 'course' ? getCourseSettings : getBatchSettings;
      apiRequest({
        variables: {
          ...(type == 'course' ? { course: id, institute: userDetail?.institute } : {}),
          ...(type == 'batch' ? { id: id } : {}),
        }
      })
    }
  }, [id, type]);

  useEffect(() => {
    if (data) {
      loadEditData();
    }
    setElements(formJSON[0]);
  }, [data]);

  function loadEditData() {
    if (data && Object.keys(data).length > 0) {
      if (data?.grNoPrefix) setValue('registration_no_prefix', data.grNoPrefix);
      if (data?.startingGRNo) setValue('registration_no', data.startingGRNo);
    }
  }

  return (
    <ContainerWrapper>
      {fields && !loading ? (
        <>
          {fields.map((field: any, i: number) => (
            // @ts-ignore
            <Element key={i} field={field} control={control} errors={errors} />
          ))}
          {Boolean(parseFloat(registration_no)) && (
            <Chip
              label={`${registration_no_prefix ? registration_no_prefix : ''}${registration_no}`}
              close={false}
              style={{ alignSelf: 'flex-start' }}
            />
          )}
        </>
      ) : (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      )}
    </ContainerWrapper>
  );
}

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
