import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import SubjectGroupForm from 'src/components/molecules/Academics/SubjectGroupForm.web';
import { useParams } from 'react-router';
import {
  getSubjectGroupQuery,
  useAddSubjectGroup,
  useDeleteSubjectGroup,
  useQuerySubjectGroup,
  useUpdateSubject,
} from 'src/graphql/academics/subject-groups';
import {
  AUTOCOMPLETE_MODULE,
  limit,
  SEARCH_TIME,
  ERROR,
  SUCCESS,
  USER_TYPE,
} from 'src/constant/index';
import { useI18n } from 'src/i18n/hooks';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, debounce } from 'src/components/services';
import { getSubjectGroupFilter } from 'src/components/services/filters';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import ERROR_MSG from 'src/constant/error';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';
import { Tooltip } from '@mui/material';
import { height } from 'src/constant/device';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components/native';


const headCells = [
  {
    id: 'group_name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'subject_assigned',
    label: 'subjectsAssigned.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'batch_name',
    label: 'batch.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'course_alias',
    label: 'course.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'class_alias',
    label: 'class.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: 'actions',
    align: 'right',
    disableSorting: true,
  },
];

const maxHeight = 440;

export default function SubjectGroups() {
  const { theme } = useThemeSystem();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [canClick, setCanClick] = useState(true);
  let row;

  let searchValue = '' as string;
  const { page = 1, dataLimit = limit }: any = useParams();

  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState<number>(rowDefaultLimit);

  const { filters, setFilter } = useFilter();
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;

  const [modalState, handleModal] = useState(false);
  const [editSubjectGroupData, setSubjectGroupData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [subjectsId, setSubjectIds] = useState([]);
  const [batchData, setBatchData] = useState({});
  const [checkedArray] = useState([]);
  const [division, setDivision] = useState<object[]>([]);
  const { setAlertDetails } = useAlertSystem();
  if (filters && filters?.subject_group?.on) {
    searchValue = filters?.subject_group?.search;
  }
  const [searching, setSearchingSubjectGroup] = useState(searchValue);
  const {
    query: getSubjectGroup,
    data: subjectGroupData,
    loading: subjectGroupLoading,
  } = useQuerySubjectGroup();
  row = subjectGroupData?.subjectGroups?.data;

  const { updateSubjectGroup } = useUpdateSubject();
  const { addSubjectGroup, ErrorOccur } = useAddSubjectGroup(page, searchValue, rowLimit);
  const { deleteSubjectGroup } = useDeleteSubjectGroup(
    currentPage,
    searchValue,
    rowLimit,
    editSubjectGroupData?.value,
  );
  const [totalCount, setTotalCount] = useState(subjectGroupData?.subjectGroups?.totalCount ?? null);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    const count = subjectGroupData?.subjectGroups?.totalCount;
    if (count) {
      setTotalCount(count);
    }
  }, [subjectGroupData?.subjectGroups?.totalCount]);

  useEffect(() => {
    setSearchingSubjectGroup(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setTitle();
  }, []);

  function setTitle() {
    setHeading([
      {
        text: t('subjectGroups.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  function handleFetch() {
    let filters = getSubjectGroupFilter(searching);
    let subjectGroupFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);
    getSubjectGroup({ variables: subjectGroupFilter });
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
  );

  const editSubjectGroupModal = (selectedSubjectGroup: any) => {
    setSubjectGroupData(selectedSubjectGroup);
    hideAlert();
    setIsEditModal(true);
    handleModal(true);
  };

  const addSubjectGroupModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  const deleteSubjectGroupModal = (selectedSubjectGroup: any) => {
    setSubjectGroupData(selectedSubjectGroup);
    setDeleteOverlay(true);
  };

  const closeModal = () => {
    handleModal(false);
    if (isEditModal) {
      setIsEditModal(false);
      setSubjectGroupData({});
    }
    resetForm();
  };

  const closeDeleteModal = () => {
    setDeleteOverlay(false);
    setSubjectGroupData({});
  };

  function resetForm() {
    setValue('batch', null);
    setValue('group_name', '');
    setValue('course', null);
    setValue('class', null);
    setValue('semester', null);
    setValue('roll_no_prefix', '');
    setDivision([]);
    setBatchData({});
    clearErrors();
  }

  function createDivisionData() {
    return division
      .filter((item: any) => item?.checked)
      .map((res: any, index: number) => ({
        division: res?.name,
        orderNo: index + 1,
      }));
  }

  const handleAddSubjectGroup = async (subjectGroupData: any) => {
    setCanClick(false);
    try {
      if (subjectsId?.length === 0) {
        throw new Error('Subject is required');
      }
      const divisions = createDivisionData();
      let subjectGroupObject = {
        batch: subjectGroupData.batch.value,
        course: batchData?.class?.course?.id,
        class: batchData?.class?.id,
        subjects: subjectsId,
        name: subjectGroupData.group_name,
        semester: subjectGroupData.semester.value,
      };
      if (divisions.length > 0) subjectGroupObject['divisionDetails'] = divisions;
      if (subjectGroupData?.roll_no_prefix) {
        subjectGroupObject['rollNoPrefix'] = subjectGroupData.roll_no_prefix;
      }
      let getResponse = await addSubjectGroup({ variables: { payload: subjectGroupObject } });
      if (getResponse.data && !ErrorOccur) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.SUBJECT_GROUP, level: SUCCESS });
      } else {
        setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateSubjectGroup = async (subjectGroupData: any) => {
    setCanClick(false);
    try {
      if (subjectsId?.length === 0) {
        throw new Error('Subject is required');
      }
      const divisions = createDivisionData();
      let subjectGroupObject = {
        id: editSubjectGroupData?.value,
        subjects: subjectsId,
        name: subjectGroupData.group_name,
        divisionDetails: divisions,
      };

      if (subjectGroupData?.roll_no_prefix) {
        subjectGroupObject['rollNoPrefix'] = subjectGroupData.roll_no_prefix;
      }

      let respose = await updateSubjectGroup({
        variables: {
          payload: subjectGroupObject,
        },
        refetchQueries: [
          {
            query: getSubjectGroupQuery,
            variables: {
              limit: rowLimit,
              skip: 0,
            },
          },
        ],
      });
      if (respose.data) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.SUBJECT_GROUP, level: SUCCESS });
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  function getSubjectsString(subjects: any) {
    let subjectString = '' as string;
    let subjectIds = [] as any;
    subjects.forEach(function (subject: any) {
      subjectIds.push(subject.name);
    });

    if (subjectIds.length > 0) {
      subjectString = subjectIds.join(', ');
    }
    return subjectString;
  }

  const handleDeleteSubjectGroup = async () => {
    setCanClick(false);
    try {
      const deleteResponse = await deleteSubjectGroup({
        variables: { id: editSubjectGroupData?.value },
      });
      if (deleteResponse.data?.deleteSubjectGroup?.status == SUCCESS) {
        setAlertDetails({ message: DELETE_SUCCESS.SUBJECT_GROUP, level: SUCCESS });
      } else {
        setAlertDetails({
          message: t('error.subject-group-delete-active-students'),
          level: ERROR,
        });
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
    setCanClick(true);
    closeDeleteModal();
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.SUBJECT_GROUP]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchSubjectGroup(subjectGroup: string) {
    let isClear = subjectGroup?.length === 0;
    persistSearch(!isClear, subjectGroup);
  }

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  const delayedQuery = useCallback(
    debounce(q => searchSubjectGroup(q), SEARCH_TIME),
    [],
  );
  const handleSearch = (subject_g: string) => {
    setSearchingSubjectGroup(subject_g);
    delayedQuery(subject_g);
  };

  return (
    <>
      <LoadContentWrapper>
        <View style={styles.flexRow}>
          <View style={styles.leftPart}>
            <Search
              id="searchsubgroup"
              handleChange={handleSearch}
              value={searching}
              label={t('searchSubjectGroups.text')}
            />
          </View>

          {userType === USER_TYPE.EMPLOYEE ? (
            <View style={styles.rightPart}>
              <SecondaryBtn label={t('addGroup.text')} onPress={addSubjectGroupModal} />
            </View>
          ) : null}
        </View>

        <TblContainer height={'100%'}>
          <TblHead />
          <>
            {!subjectGroupLoading ? (
              <>
                {recordsAfterPagingAndSorting()?.length > 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                      <TableRows key={i}>
                        <TableCells value={item?.label} />
                        <TableCells value={getSubjectsString(item.subjects)} />
                        <TableCells value={item?.batch?.name.toUpperCase() || '-'} />
                        <TableCells value={item?.course?.alias} />
                        <TableCells value={item?.class?.alias} />

                        <TableActions align="right">
                          <View style={styles.tableAction}>
                            <View style={styles.iconView}>
                              <Tooltip title="Edit Subject Group">
                                <TouchableOpacity onPress={() => editSubjectGroupModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    color={theme?.table?.editIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            </View>
                            <View>
                              <Tooltip title="Delete Subject Group">
                                <TouchableOpacity onPress={() => deleteSubjectGroupModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    color={theme?.table?.deleteIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            </View>
                          </View>
                        </TableActions>
                      </TableRows>
                    ))}
                  </TableBody>
                ) : (
                  <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
                )}
              </>
            ) : (
              <SpinnerWrapper>
                <LoaderSpinner />
              </SpinnerWrapper>
            )}
          </>
        </TblContainer>

        <Pagination
          pathName={'academics/subject-groups'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          hidePagination={!!(totalCount && totalCount <= rowLimit)}
        />
      </LoadContentWrapper>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={closeDeleteModal}
        modalVisible={deleteOverlay}
        handleSave={handleDeleteSubjectGroup}
        Headerpopup="deleteSubjectGroup.text">
        {t('deleteSubjectGroup.warning')}
      </DeleteModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        onAlert={null}
        modalVisible={modalState}
        Headerpopup={isEditModal ? t('editGroup.text') : t('addGroup.text')}
        handleSave={handleSubmit(isEditModal ? handleUpdateSubjectGroup : handleAddSubjectGroup)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}
        height={540}
        maxWidth={'lg'}>
        <SubjectGroupForm
          setSubjectIds={setSubjectIds}
          setDivision={setDivision}
          division={division}
          control={control}
          errors={errors}
          selectedSubjects={isEditModal ? checkedArray : []}
          getValues={getValues}
          setValue={setValue}
          isEditModal={isEditModal}
          editSubjectGroupData={editSubjectGroupData}
          setBatchData={setBatchData}
          batchData={batchData}
        />
      </NormalModal>
    </>
  );
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  rightPart: {
    flex: 3,
    alignItems: 'flex-end',
  },
  tableAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  iconView: { marginRight: 13 },
});

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
