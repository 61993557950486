import React, { useState, useEffect, useCallback, Fragment, useRef } from 'react';
import { View, StyleSheet, useWindowDimensions, Dimensions, EmitterSubscription } from 'react-native';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useI18n } from 'src/i18n/hooks';
import { useHeaderTitle } from 'src/contexts/header-context';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import NormalModal from 'src/components/atoms/Modals/Normal/index';
import EventForm from 'src/components/molecules/Event/EventForm';
import { useForm } from 'react-hook-form';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import { height, isIOS, isNative, isWeb, width } from 'src/constant/device';
import EventCard from 'src/components/molecules/Event/EventCard';
import EventModalContent from 'src/components/molecules/Event/EventModalContent';
import { breakpoints, colors, mobileWeb } from 'src/styles/theme/styles';
import {
  ANNOUNCEMENT_TYPE,
  AUDIENCE_TYPE,
  EMPLOYEE_TYPES,
  ERROR,
  EVENT_LIMIT,
  FILTERS,
  SUCCESS,
} from 'src/constant';
import { userInformation } from 'src/utils/manageState';
import AddButton from 'src/components/atoms/AddButton';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons';
import MobileHeader from 'src/components/hoc/MobileHeader';
import SwipeSwitch from 'src/components/atoms/Switch/Swipe';
import Listing from 'src/components/molecules/CommunicationListing';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  useHistory,
} from 'src/routes/routing';
import { NoNewsNotices } from 'src/components/molecules/NoNewsNotices';
import navigationStrings from 'src/navigation/navigationStrings';
import { useQuery } from '@apollo/client';
import {
  getAnnouncementsQuery,
  useAddAnnouncementsMutation,
  useDeleteEventMutation,
} from 'src/graphql/communication/news-notices';
import { createSearchFilter, filterDoc } from 'src/components/services';
import { debounce } from 'lodash';
import ERROR_MSG from 'src/constant/error';
import { getStorageFunction } from 'src/components/services/storage-service';
import { KEYS } from 'src/constant/key';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { showNativeError, showNativeSuccess } from 'src/components/molecules/NotificationWrapper';
import styled from 'styled-components/native';
import AlertModalNative from 'src/components/molecules/AlertModal/AlertModalNative';
import { AttachmentType } from 'src/components/molecules/FileUpload/type';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { DT } from 'src/constant/dateTime';
import { format } from 'date-fns';
import { validateTotalSizeOfAttachment } from 'src/utils/utility';
import AbstractFlashList from 'src/components/atoms/FlashList';

let staticParam = {
  params: {
    eventType: '',
    eventId: '',
  },
};

export interface documentObjectType {
  documentName: string;
  document: File;
  type: string;
}

export default function Events(props: any) {
  let totalUpcoming = 0;
  let totalPast = 0;
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  let { alertState, setAlertDetails } = useAlertSystem();
  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
  } = useForm();
  const [searchText, setSearchText] = useState('');
  const [formModal, setFormModal] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [toggleAdd, setAdd] = useState<any>(isWeb);
  const [eventData, setEventData] = useState<any>({});
  const [windowWidth, setwindowWidth] = useState(useWindowDimensions().width);
  const [imageAttachments, setImageAttachments] = useState<AttachmentType[]>([]);
  const [fileAttachments, setFileAttachments] = useState<AttachmentType[]>([]);
  const [removeImages, setRemovedImages] = useState<AttachmentType[]>([]);
  const [tokenData, setToken] = useState<any>('');
  const [eventIndex, setEventIndex] = useState<number | null>(null);
  const currentUserObject = userInformation();
  const [refreshing, setRefreshing] = React.useState(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const isDesktop = width > breakpoints.md;
  const { userDetail } = currentUserObject;
  const isInstituteAdmin = userDetail?.type
    ? userDetail.type.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN)
    : false;
  let instituteId = userDetail?.institute;
  const [upcomingSkip, setUpcomingSkip] = useState<number>(0);
  const [pastSkip, setPastSkip] = useState<number>(0);
  const history = useHistory();
  const { params } = isNative ? props?.route : staticParam;
  const { eventType, eventId } = params;
  const match = isWeb ? useRouteMatch() : '';
  let location = isWeb ? useLocation() : '';
  const isUpcoming = location && location.pathname.includes('upcoming');
  const [canClick, setCanClick] = useState<boolean>(true);
  const { mutate: createAnnoucemnt } = useAddAnnouncementsMutation('EVENT');
  const { eventDeletion } = useDeleteEventMutation(eventIndex, searchText, eventData?.id);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const upcomingRef = useRef<any>(null);
  const pastRef = useRef<any>(null);
  const eventTab = eventType === 'upcoming' ? 1 : 2;
  let searchFilters = [
    {
      title: {
        regex: '',
      },
    },
    {
      body: {
        regex: '',
      },
    },
  ];

  let upcomingFilter = {
    type: {
      eq: ANNOUNCEMENT_TYPE[2],
    },
    date: {
      gte: format(new Date(), DT.DATE_FORMAT_SLASH),
    },
    or: searchFilters,
  };

  let pastFilter = {
    type: {
      eq: ANNOUNCEMENT_TYPE[2],
    },
    date: {
      lt: format(new Date(), DT.DATE_FORMAT_SLASH),
    },
    or: searchFilters,
  };

  const {
    loading: upcomingLoading,
    data: upcomingEvents,
    refetch: upcomingRefetch,
    fetchMore: fetchMoreUpcoming,
  } = useQuery(getAnnouncementsQuery, {
    variables: {
      limit: EVENT_LIMIT,
      skip: 0,
      filters: upcomingFilter,
      timezone: DT.TIMEZONE,
    },
  });

  const {
    loading: pastLoading,
    data: pastEvents,
    refetch: pastRefetch,
    fetchMore: fetchMorePast,
  } = useQuery(getAnnouncementsQuery, {
    variables: { limit: EVENT_LIMIT, skip: 0, filters: pastFilter, timezone: DT.TIMEZONE },
  });

  totalUpcoming = upcomingEvents?.getAnnouncements?.totalCount;
  totalPast = pastEvents?.getAnnouncements?.totalCount;

  useEffect(() => {
    loadToken();
  }, []);

  async function loadToken() {
    let token = await getStorageFunction(KEYS.ACCESS_TOKEN);
    setToken(`Bearer ${token}`);
  }

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    handleModal();
  }, [eventId]);

  function handleModal() {
    if (eventId && isNative) {
      setEventModal(true);
    } else {
      setEventModal(false);
    }
  }

  function setTitle() {
    if (isWeb) {
      setHeading([
        {
          text: t('events.label'),
          url: '',
        },
      ]);
    }
  }

  useEffect(() => {
    const subscription: EmitterSubscription = Dimensions.addEventListener("change", (e) => {
      setwindowWidth(e.window.width);
    });
    return () => subscription.remove();
  }, []);

  useEffect(() => {
    const attachments = [
      ...(imageAttachments?.length > 0 ? imageAttachments : []),
      ...(fileAttachments?.length > 0 ? fileAttachments : []),
    ]
    const allowed = validateTotalSizeOfAttachment(attachments);
    if (!allowed) {
      setCanClick(false);
      showError(t("err.totalFilesize.exceeds"));
    } else {
      setCanClick(true);
    }
  }, [imageAttachments, fileAttachments]);

  const closeFormModal = () => {
    closeFinal();
    closeNative();
    resetForms();
    setCanClick(true);
  };

  function resetForms() {
    setValue('title', '');
    setValue('location', '');
    setValue('date', '');
    setValue('startTime', '');
    setValue('endTime', '');
    setValue('body', '');
    setImageAttachments([]);
    setFileAttachments([]);
    setRemovedImages([]);
  }

  const closeEventModal = () => {
    resetForms();
    closeFinal();
    closeNative();
  };

  function closeNative() {
    if (isNative) {
      props.navigation.navigate(navigationStrings.EVENTS, {
        eventType: params.eventType,
        eventId: '',
      });
    }
  }

  function closeFinal() {
    clearErrors();
    setEventModal(false);
    setFormModal(false);
    setTimeout(() => {
      setEditModal(false);
    }, 300);
  }

  function openFormModal() {
    setEditModal(false);
    setFormModal(true);
  }

  function hideAlert() {
    if (isWeb) {
      setAlertDetails({ message: '', level: '' });
    }
  }

  function openEditModal(eventData: any) {
    if (isWeb) {
      setEventData(eventData);
    } else {
      eventData.persist();
    }
    clearErrors();
    setEditModal(true);
    setFormModal(true);
  }

  function handleCreateEvent(formData: any) {
    if (canClick) {
      setCanClick(false);
      addAnnoucement(formData);
    }
  }

  async function handleUpdateEvent(formData: any) {
    if (canClick) {
      setCanClick(false);
      const imageAttachmentsDoc = filterDoc(imageAttachments);
      const fileAttachmentsDoc = filterDoc(fileAttachments);

      let eventObject = {
        id: eventData?.id,
        type: ANNOUNCEMENT_TYPE[2],
        body: formData?.body,
        attachments: [
          ...(imageAttachmentsDoc && imageAttachmentsDoc?.length > 0 ? imageAttachmentsDoc : []),
          ...(fileAttachmentsDoc && fileAttachmentsDoc?.length > 0 ? fileAttachmentsDoc : []),
        ],
        removeAttachments: removeImages,
      };

      try {
        const annoucementResponse = await createAnnoucemnt({
          variables: { payload: eventObject },
        });
        if (annoucementResponse.data) {
          let data = annoucementResponse.data;
          if (data.createAnnouncement) {
            setCanClick(true);
            closeEventModal();
            handleUpdateSuccess(data.createAnnouncement);
            showSuccess(UPDATE_SUCCESS.EVENT)
          }
        }
      } catch (error) {
        setCanClick(true);
        showError(error.message);
      }
    }
  }

  async function handleUpdateSuccess(data: any) {
    if (!isDesktop) {
      if (isNative) {
        if (eventTab === 1) {
          await upcomingRefetch(upcomingFilter);
        } else {
          await pastRefetch(pastFilter);
        }
        setEditModal(false);
      }
    } else {
      if (isUpcoming) {
        await upcomingRefetch(upcomingFilter);
      } else {
        await pastRefetch(pastFilter);
      }
    }
  }

  async function addAnnoucement(payload: any) {
    try {
      // date and time will be auto formatted in payload
      const imageAttachmentsDoc = filterDoc(imageAttachments);
      const fileAttachmentsDoc = filterDoc(fileAttachments);
      payload['attachments'] = [
        ...(imageAttachmentsDoc && imageAttachmentsDoc?.length > 0 ? imageAttachmentsDoc : []),
        ...(fileAttachmentsDoc && fileAttachmentsDoc?.length > 0 ? fileAttachmentsDoc : []),
      ];
      payload['audienceType'] = AUDIENCE_TYPE.INSTITUTE;
      payload['audience'] = [instituteId];
      payload['type'] = ANNOUNCEMENT_TYPE[2];
      payload['showOnHomePage'] = false;

      const annoucementResponse = await createAnnoucemnt({
        variables: { payload: payload },
      });

      if (annoucementResponse.data) {
        let data = annoucementResponse.data;
        if (data.createAnnouncement) {
          let finalFilter = createEventFilter(searchText, 1, 0);
          let pastFilter = createEventFilter(searchText, 2, 0);
          await upcomingRefetch(finalFilter);
          await pastRefetch(pastFilter);
          setCanClick(true);
          closeEventModal();
          showSuccess(ADD_SUCCESS.EVENT)
        }
      }
    } catch (error) {
      setCanClick(true);
      showError(error.message);
    }
  }

  function showEventDetail(eventD: any) {
    if (isWeb) {
      let paramType = isUpcoming ? 'upcoming' : 'past';
      history.push(`/events/${paramType}/${eventD?.id}/detail`);
    } else {
      setEventData(eventD);
      props.navigation.navigate(navigationStrings.EVENTS, {
        eventType: params.eventType,
        eventId: eventD?.id,
      });
    }
  }

  async function deleteEvent(eventData: any, idx: number) {
    setEventData(eventData);
    setEventIndex(idx);
    setDeleteOverlay(true);
  }

  function handleSwitching(t: number) {
    let passParam = t === 1 ? 'upcoming' : 'past';
    if (t === 2) {
      setAdd(false);
    }
    props.navigation.navigate(navigationStrings.EVENTS, {
      eventType: passParam,
    });
  }

  function showSuccess(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: SUCCESS });
    } else {
      showNativeSuccess(message)
    }
  }

  function showError(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: ERROR });
    } else {
      showNativeError(message)
    }
  }

  async function nativeRefresh() {
    let searchType = eventTab === 1 ? 'gte' : 'lt';
    let eventFilter = {
      filters: {
        type: {
          eq: ANNOUNCEMENT_TYPE[2],
        },
        date: {
          [searchType]: format(new Date(), DT.DATE_FORMAT_SLASH),
        },
      },
      limit: EVENT_LIMIT,
      skip: 0,
    } as any;

    if (eventTab === 1) {
      upcomingRefetch(eventFilter);
    } else {
      pastRefetch(eventFilter);
    }
    setAdd(true);
    setRefreshing(false);
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    nativeRefresh();
  }, []);

  function changeTabs(tab: any) {
    setUpcomingSkip(0);
    setPastSkip(0);
    let searchType = tab === 1 ? 'gte' : 'lt';
    let eventFilter = {
      filters: {
        type: {
          eq: ANNOUNCEMENT_TYPE[2],
        },
        date: {
          [searchType]: format(new Date(), DT.DATE_FORMAT_SLASH),
        },
      },
      limit: EVENT_LIMIT,
      skip: 0,
    } as any;

    setSearchText('');
    if (tab === 1) {
      upcomingRefetch(eventFilter);
      history.push('/events/upcoming');
    } else {
      pastRefetch(eventFilter);
      history.push('/events/past');
    }
  }

  function createEventFilter(passText: string, eventType: number, skipType: number) {
    let searchType = eventType === 1 ? 'gte' : 'lt';

    let filtersArr = [
      {
        field: 'title',
        type: FILTERS.REGEX,
        value: passText,
      },
      {
        field: 'body',
        type: FILTERS.REGEX,
        value: passText,
      },
    ];

    let eventFilter = {
      filters: {
        type: {
          eq: ANNOUNCEMENT_TYPE[2],
        },
        date: {
          [searchType]: format(new Date(), DT.DATE_FORMAT_SLASH),
        },
      },
    } as any;

    let finalFilter = createSearchFilter(EVENT_LIMIT, skipType, filtersArr);
    finalFilter.timezone = DT.TIMEZONE;
    finalFilter['filters'] = { ...finalFilter['filters'], ...eventFilter.filters };
    return finalFilter;
  }

  async function handleSearch(searchText: string, type: number) {
    let skipType = type === 1 ? upcomingSkip : pastSkip;
    let finalFilter = createEventFilter(searchText, type, skipType);

    try {
      setRefreshing(true);
      if (type === 1) {
        setUpcomingSkip(0);
        await upcomingRefetch(finalFilter);
      } else {
        setPastSkip(0);
        await pastRefetch(finalFilter);
      }
    } catch (e) {
      console.log(ERROR_MSG.GENERIC_ERROR);
    }
  }

  const debouncedSearch = useCallback(debounce(handleSearch, 500), []);

  function handleUpcomingEvent() {
    if (totalUpcoming > upcomingEvents?.getAnnouncements?.data.length) {
      setFetchMoreLoading(true);
      handlePagination(getAnnouncementsQuery, upcomingSkip + EVENT_LIMIT, fetchMoreUpcoming, 1);
      setUpcomingSkip(upcomingSkip + EVENT_LIMIT);
    }
  }

  function handlePastEvent() {
    if (totalPast > pastEvents?.getAnnouncements?.data.length) {
      setFetchMoreLoading(true);
      handlePagination(getAnnouncementsQuery, pastSkip + EVENT_LIMIT, fetchMorePast, 2);
      setPastSkip(pastSkip + EVENT_LIMIT);
    }
  }

  function handlePagination(
    paginateQuery: any,
    annoucementSkip: number,
    fetchFunction: any,
    type: number,
  ) {
    let finalFilter = createEventFilter(searchText, type, annoucementSkip);

    try {
      fetchFunction({
        query: paginateQuery,
        variables: finalFilter,
        updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
          setFetchMoreLoading(fetchMoreResult.getAnnouncements.data ? false : true);
          return {
            getAnnouncements: {
              hasNextPage: fetchMoreResult.getAnnouncements.hasNextPage,
              data: [
                ...previousResult.getAnnouncements.data,
                ...fetchMoreResult.getAnnouncements.data,
              ],
              totalCount: fetchMoreResult.getAnnouncements.totalCount,
              __typename: fetchMoreResult.getAnnouncements.__typename,
            },
          };
        },
      });
    } catch (error: any) {
      setFetchMoreLoading(true);
      console.log(error.message);
    }
  }

  const handleDeleteEvent = async (eventID = '' as string) => {
    let eventId = isWeb ? eventData.id : eventID;
    try {
      let deleteRespopnse = await eventDeletion({ variables: { id: eventId } });
      if (deleteRespopnse) {
        await upcomingRefetch(upcomingFilter)
        setDeleteOverlay(false);
        showSuccess(DELETE_SUCCESS.EVENT)
      }
    } catch (error: any) {
      showError(error.message);
    }
    setCanClick(true);
  };

  return (
    <Fragment>
      {isWeb && windowWidth > breakpoints.md ? (
        <Fragment>
          {!upcomingLoading && !pastLoading ? (
            <LoadContentWrapper>
              <ModalTabs
                onSelectSwitch={t => {
                  changeTabs(t);
                }}
                option1={t('upcomingEvents.label')}
                option2={t('pastEvents.label')}
                selectionMode={isUpcoming ? 1 : 2}
                roundCorner={false}
                style={[styles.tabStyle]}
                onChange={changeTabs}
                tabswidth={100}
                margin_top={-5}
              />

              <Switch>
                <Route path={`${match.url}/upcoming`}>
                  <>
                    {/* SEARCH BAR */}
                    {upcomingEvents?.getAnnouncements?.data?.length > 0 ||
                    (upcomingEvents?.getAnnouncements?.data?.length < 1 && searchText !== '') ||
                    (upcomingEvents?.getAnnouncements?.data?.length < 1 && refreshing) ? (
                      <View style={styles.flexRow}>
                        <View style={styles.leftPart}>
                          <Search
                            id="upcomingEventSearch"
                            handleChange={async e => {
                              setSearchText(e);
                              await debouncedSearch(e, 1);
                            }}
                            value={searchText}
                            label={t('searchEvents.text')}
                          />
                        </View>
                        <View style={styles.rightPart}>
                          {isInstituteAdmin && (
                            <SecondaryBtn
                              label={t('addEvent.text')}
                              onPress={openFormModal}
                              secondary={false}
                            />
                          )}
                        </View>
                      </View>
                    ) : null}

                    {/* NO SEARCH DATA */}
                    {upcomingEvents?.getAnnouncements?.data?.length < 1 && searchText !== '' && (
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}>
                        <NormaltextAtom value={t('noResultFound.text')} />
                      </View>
                    )}

                    {/* LIST*/}
                    {upcomingEvents?.getAnnouncements?.data?.length > 0 && (
                      <>
                        <AbstractFlashList
                          estimatedItemSize={129}
                          onEndReached={handleUpcomingEvent}
                          onEndReachedThreshold={0.7}
                          data={upcomingEvents?.getAnnouncements?.data}
                          children={({ item, index }: any) => (
                            <EventCard
                              data={item}
                              editAction={openEditModal}
                              indexKey={index}
                              deleteAction={deleteEvent}
                              onPress={showEventDetail}
                              canEdit={isInstituteAdmin}
                              canDelete={isInstituteAdmin}
                              isSwipable={false}
                            />
                          )}
                          scrollEnabled={true}
                          nestedScrollEnabled={true}
                        />
                        {fetchMoreLoading ? (
                          <BottomSpinnerView>
                            <LoaderSpinner />
                          </BottomSpinnerView>
                        ) : null}
                      </>
                    )}
                  </>
                  {/* NO WHOLE DATA */}
                  {searchText === '' &&
                    !refreshing &&
                    upcomingEvents?.getAnnouncements?.data?.length < 1 && (
                      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <NoNewsNotices icon={faCalendarDay} label={t('noUpcomingEvents.text')} />

                        {isInstituteAdmin && (
                          <View style={{ position: 'relative' }}>
                            <AddButton
                              style={{ width: 113 }}
                              label={t('addNew.text')}
                              onPress={openFormModal}
                            />
                          </View>
                        )}
                      </View>
                    )}
                </Route>

                <Route path={`${match.url}/past`}>
                  <>
                    {/* SEARCH BAR */}
                    {pastEvents?.getAnnouncements?.data?.length > 0 ||
                    (pastEvents?.getAnnouncements?.data?.length < 1 && searchText !== '') ||
                    (pastEvents?.getAnnouncements?.data?.length < 1 && refreshing) ? (
                      <View style={styles.flexRow}>
                        <View style={styles.leftPart}>
                          <Search
                            id="pastEventSearch"
                            handleChange={async e => {
                              setSearchText(e);
                              await debouncedSearch(e, 2);
                            }}
                            value={searchText}
                            label={t('searchEvents.text')}
                          />
                        </View>
                      </View>
                    ) : null}

                    {/* NO SEARCH DATA */}
                    {pastEvents?.getAnnouncements?.data?.length < 1 && searchText !== '' && (
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                        }}>
                        <NormaltextAtom value={t('noResultFound.text')} />
                      </View>
                    )}

                    {/* LIST*/}
                    {pastEvents?.getAnnouncements?.data?.length > 0 && (
                      <>
                        <AbstractFlashList
                          estimatedItemSize={129}
                          onEndReached={handlePastEvent}
                          onEndReachedThreshold={0.7}
                          data={pastEvents?.getAnnouncements?.data}
                          children={({ item }) => (
                            <EventCard
                              data={item}
                              editAction={openEditModal}
                              deleteAction={() => {}}
                              onPress={showEventDetail}
                              canEdit={isInstituteAdmin}
                              canDelete={false}
                              isSwipable={false}
                            />
                          )}
                          scrollEnabled={true}
                          nestedScrollEnabled={true}
                        />
                        {fetchMoreLoading ? (
                          <BottomSpinnerView>
                            <LoaderSpinner />
                          </BottomSpinnerView>
                        ) : null}
                      </>
                    )}

                    {/* LIST*/}
                  </>

                  {/* NO WHOLE DATA */}
                  {searchText === '' &&
                    !refreshing &&
                    pastEvents?.getAnnouncements?.data?.length < 1 && (
                      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <NoNewsNotices icon={faCalendarDay} label={t('noPastEvents.text')} />
                      </View>
                    )}
                </Route>

                <Route exact path={`${match.url}`}>
                  <Redirect to={`${match.url}/upcoming`} />
                </Route>
              </Switch>
            </LoadContentWrapper>
          ) : (
            <View
              style={[
                styles.spineerView,
                { backgroundColor: isDesktop ? colors.bluishGreyBackground : colors.white },
              ]}>
              <LoaderSpinner />
            </View>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <MobileHeader label={t('events.label')} backIcon={false} {...props} />
          {!upcomingLoading && !pastLoading ? (
            <View style={[styles.webWrapper]}>
              <View style={[styles.switchView]}>
                <SwipeSwitch
                  onSelectSwitch={isWeb ? t => changeTabs(t) : t => handleSwitching(t)}
                  option1={t('upcomingEvents.label')}
                  option2={t('pastEvents.label')}
                  selectionMode={isWeb ? (isUpcoming ? 1 : 2) : eventTab}
                  onChange={isWeb ? t => changeTabs(t) : t => handleSwitching(t)}
                />
              </View>

              {isNative && (
                <View testID={'EventView'}>
                  {eventType === 'upcoming' && toggleAdd && isInstituteAdmin && (
                    <View style={{ marginTop: 24, paddingHorizontal: 24, marginBottom: 24 }}>
                      <AddButton
                        onPress={() => {
                          openFormModal();
                          hideAlert();
                        }}
                        testID={'addEventBtn'}
                        label={t('addEvent.text')}
                        color={colors.primaryText}
                      />
                    </View>
                  )}

                  {eventType === 'upcoming' && (
                    <>
                      <Listing
                        estimatedItemSize={128}
                        passRef={upcomingRef}
                        Comp={EventCard}
                        onEndReached={handleUpcomingEvent}
                        onEndReachedThreshold={0.5}
                        arrayList={upcomingEvents?.getAnnouncements?.data}
                        icon={faCalendarDay}
                        label={t('noUpcomingEvents.text')}
                        onPress={showEventDetail}
                        deleteAction={deleteEvent}
                        isSwipable={isInstituteAdmin}
                        onRefresh={onRefresh}
                        setShowAdd={setAdd}
                        fetchMoreLoading={fetchMoreLoading}
                        heightOffset={isIOS ? 182 : 120}
                      />
                      <AlertModalNative
                        title={t('deleteEvent.text')}
                        message1={t('deleteEvent.warning')}
                        onCancel={() => setDeleteOverlay(false)}
                        onConfirm={() => handleDeleteEvent(eventData.id)}
                        showModal={deleteOverlay}
                        confirmButtonColor={colors.errorColor}
                        confirmButtonText={t('delete.label')}
                      />
                    </>
                  )}

                  {eventType === 'past' && (
                    <Listing
                      estimatedItemSize={128}
                      passRef={pastRef}
                      Comp={EventCard}
                      onEndReached={handlePastEvent}
                      onEndReachedThreshold={0.5}
                      arrayList={pastEvents?.getAnnouncements?.data}
                      icon={faCalendarDay}
                      label={t('noPastEvents.text')}
                      onPress={showEventDetail}
                      deleteAction={() => {}}
                      isSwipable={false}
                      fetchMoreLoading={fetchMoreLoading}
                      heightOffset={isIOS ? 182 : 120}
                    />
                  )}
                </View>
              )}
            </View>
          ) : (
            <View style={[styles.spineerView]}>
              <LoaderSpinner />
            </View>
          )}
        </Fragment>
      )}

      {isWeb && (
        <DeleteModal
          isSubmitting={!canClick}
          setModalVisible={() => setDeleteOverlay(false)}
          modalVisible={deleteOverlay}
          handleSave={handleDeleteEvent}
          Headerpopup="deleteEvent.text">
          {t('deleteEvent.warning')}
        </DeleteModal>
      )}

      <NormalModal
        isSubmitting={!canClick}
        onAlert={alertState}
        type="normal"
        setModalVisible={closeFormModal}
        modalVisible={formModal}
        handleSave={handleSubmit(!isEditModal ? handleCreateEvent : handleUpdateEvent)}
        Headerpopup={t(isEditModal ? 'editEvent.text' : 'addEvent.text')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="save.label"
        lineHeight={32}
        width={644}
        height={570}
        maxWidth={'lg'}
        style={{ paddingBottom: 0 }}>
        <EventForm
          control={control}
          errors={errors}
          setValue={setValue}
          imageAttachments={imageAttachments}
          fileAttachments={fileAttachments}
          setImageAttachments={setImageAttachments}
          setFileAttachments={setFileAttachments}
          eventData={eventData}
          isEditModal={isEditModal}
          setRemovedImages={setRemovedImages}
          removeImages={removeImages}
          setError={setError}
          clearErrors={clearErrors}
        />
      </NormalModal>

      {/* native but for edit modal and info Bcz some design things*/}
      {!isWeb && (
        <NormalModal
          isSubmitting={!canClick}
          Headerpopup={t(!isEditModal ? 'event.label' : 'editEvent.text')}
          modalVisible={!isEditModal ? eventModal : formModal}
          setModalVisible={!isEditModal ? closeEventModal : closeFormModal}
          handleSave={!isEditModal ? openEditModal : handleSubmit(handleUpdateEvent)}
          addEditButtonLabel={!isEditModal ? (isInstituteAdmin ? 'edit.label' : '') : 'save.label'}
          lineHeight={32}>
          {!isEditModal ? (
            <EventModalContent {...props} tokenData={tokenData} />
          ) : (
            <EventForm
              tokenData={tokenData}
              control={control}
              errors={errors}
              setValue={setValue}
              imageAttachments={imageAttachments}
              fileAttachments={fileAttachments}
              setImageAttachments={setImageAttachments}
              setFileAttachments={setFileAttachments}
              eventData={eventData}
              isEditModal={isEditModal}
              setRemovedImages={setRemovedImages}
              removeImages={removeImages}
              {...props}
            />
          )}
        </NormalModal>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  webWrapper: {
    backgroundColor: colors.white,
    height: isIOS ? height - 55 : isWeb && mobileWeb ? height : height - 62,
    paddingTop: 24,
    paddingBottom: isIOS ? (height > 900 ? 100 : 72) : 62,
  },
  switchView: {
    marginBottom: 12.5,
    marginHorizontal: 24,
  },
  tabStyle: {
    top: 0,
    left: 0,
    backgroundColor: colors.white,
    marginBottom: 24,
  },
  flexRow: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  rightPart: {
    flex: 3,
    alignItems: 'flex-end',
  },
  spineerView: {
    height: height - 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const BottomSpinnerView = styled.View`
  height: 30px;
  justify-content: center;
  align-items: center;
`;
