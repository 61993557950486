import { FieldApi } from "@data-driven-forms/react-form-renderer/common-types";
import { FormOptions } from "@data-driven-forms/react-form-renderer/renderer-context";
import { isPresentInArray } from "src/utils/utility";
import { ViewMode } from "../../components/molecules/Forms/types";

export const getComponentType = (viewMode: ViewMode, component: string) => {
  if (viewMode == ViewMode.READ) {
    if (component == 'file-upload') {
      return 'fileview';
    }
    if (component == 'multi-doc-input') {
      return 'multifileview';
    }
    return 'fieldview';
  } else {
    return component;
  }
}

export const fileValidationConfig = [
  {
    type: "file-size",
    maxSize: 20 * 1024 * 1024,
    message: "Invalid File Size",
  },
  {
    type: "file-type",
    message: "Invalid File Type",
  }
];

export const resolveOthers = (
  props: any,
  field: FieldApi<any>,
  formOptions: FormOptions,
  viewMode: ViewMode,
) => {
  const { options } = props;
  const { values } = formOptions?.getState();
  if (viewMode == ViewMode.EDIT && values) {
    const value = values[field.input.name];
    if (!isPresentInArray(value, options)) {
      if (value) props.initialValue = "Others"
    } else {
      // If value is given from provide option then make otherField null.
      if (props.otherFieldName && value !== 'Others') {
        formOptions.change(props.otherFieldName, null);
      }
    }
  }
  return props;
}

export const setOtherInitialValue = (
  props: any,
  formOptions: FormOptions,
  fieldName: string,
  otherFieldName: string,
) => {
  const { initialValues, getState, getFieldState } = formOptions;
  const { values } = getState();
  if (values && initialValues && values[fieldName] === 'Others' && !getFieldState(fieldName)?.dirty) {
    formOptions.change(otherFieldName, initialValues[fieldName]);
  }
  return props;
}

export const handleNameChange = (formOptions: any) => {
  const { getState } = formOptions;
  const { values } = getState();
  formOptions.change(
    'fullName',
    `${values.lastName || ''} ${values.firstName || ''} ${values.middleName || ''}`,
  );
}