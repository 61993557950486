import { gql, useLazyQuery, useMutation } from "@apollo/client";

export const generateLotteryMutation = gql`
  mutation ($admissionId: String!) {
    generateLottery(admissionId: $admissionId) {
      id
      status
      regenerationHistory {
        date
        generatedBy
      }
    }
  }
`;

export const publishLotteryMutation = gql`
  mutation ($lotteryId: String!) {
    publishLottery(lotteryId: $lotteryId) {
      id
      status
    }
  }
`;

const getLotteryQuery = gql`
  query ($admissionId: String!) {
    lottery(admissionId: $admissionId) {
      id
      admissionId
      status
      publishedDate
      regenerationHistory {
        date
        generatedBy
      }
    }
  }
`;

const getLotteryApplicationsQuery = gql`
  query (
    $lotteryId: String!
    $applicantName: String
    $limit: Int
    $skip: Int = 0
    $sort: ApplicationSortInput
  ) {
    lotteryApplications(
      lotteryId: $lotteryId
      applicantName: $applicantName
      limit: $limit
      skip: $skip
      sort: $sort
    ) {
      data {
        id
        applicationId
        user {
          id
          fullName
          email
        }
      }
      totalCount
    }
  }
`;

export function useGetLotteryDetails() {
  const [
    getLotteryDetails,
    { loading: lotteryLoading, data: lotteryData, refetch: lotteryRefetch },
  ] = useLazyQuery(getLotteryQuery, {
    fetchPolicy: "network-only",
  });
  return { getLotteryDetails, lotteryData, lotteryLoading, lotteryRefetch };
}

export function useGetLotteryApplications() {
  const [getLotteryApplications, { loading, data, refetch }] = useLazyQuery(
    getLotteryApplicationsQuery,
    {
      fetchPolicy: "network-only",
    }
  );
  return { getLotteryApplications, data, loading, refetch };
}

export function useGenerateLottery() {
  const [generateLottery] = useMutation(generateLotteryMutation);
  return { generateLottery };
}

export function usePublishLottery() {
  const [publishLottery] = useMutation(publishLotteryMutation);
  return { publishLottery };
}
