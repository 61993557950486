import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { CreateAccount } from './types';

const craeteEmpAccountMutation = gql`
  mutation ($payload: SignupUserInput!) {
    acceptInvite(payload: $payload) {
      success
      userType
    }
  }
`;

const employeeUsernameQuery = gql`
  query ($userName: String!) {
    isEmployeeUsernameAvailable(userName: $userName)
  }
`;

const getScheduleQuery = gql`
  query ($input: GetSchedule, $limit: Int) {
    getFullSchedule(input: $input, limit: $limit) {
      data {
        id
        division
        subject {
          id
          name
          color
        }
        class {
          code
          alias
          name
        }
        startTime
        endTime
        start
        end
      }
    }
  }
`;

const getAssignmentPendingCountForStudent = gql`
  query {
    getAssignmentPendingCountForStudent
  }
`;

const getUnreadAssignmentSubmissionCount = gql`
  query {
    getUnreadAssignmentSubmissionCount
  }
`;

export {
  employeeUsernameQuery,
  getScheduleQuery,
  getAssignmentPendingCountForStudent,
  getUnreadAssignmentSubmissionCount,
};

export function useCreateEmployee() {
  const [createEmpAccount, { data: employeeData, error: employeeError }] =
    useMutation(craeteEmpAccountMutation);
  return { createEmpAccount, employeeData, employeeError };
}
