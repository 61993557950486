import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { limit } from '../../constant';
import { readCacheQuery, writeCacheQuery } from '../util';
import { createDeleteData, createSearchFilter } from '../../components/services/index';
import { getInstituteFilter } from 'src/components/services/filters';

const addInstituteMutation = gql`
  mutation ($payload: InstituteAdminInput!) {
    createInstitute(payload: $payload) {
      id
      name
      description
      website
      domain
      addressDetails {
        id
        city
      }
      contactDetails {
        email
        firstName
        lastName
        mobileNumber
      }

      courses {
        id
      }
      university {
        value: id
        label: alias
      }
      type
      status
      group {
        id
        name
        description
        website
        domain
        contactDetails {
          email
          firstName
          lastName
          mobileNumber
        }

        addressDetails {
          id
          city
        }
        courses {
          id
        }
        university {
          value: id
          label: alias
        }
        type
      }
    }
  }
`;

const approveInstituteMutation = gql`
  mutation ($instituteId: String!) {
    approveInstitute(instituteId: $instituteId) {
      id
      status
    }
  }
`;

const applyLanguage = gql`
  mutation ($languageId: String!) {
    applyLanguage(languageId: $languageId) {
      id
      name
    }
  }
`

const updateInstituteMutation = gql`
mutation ($payload: UpdateInstituteInput!) {
  updateInstitute(payload: $payload) {
    id
    name
    description
    website
    domain
    udise
    addressDetails {
      id
      city
    }
    courses {
      id 
    }
    university {
      value: id
      label: alias  
    }
    contactDetails {
      email
      firstName
      lastName
      mobileNumber
    }
    type
    status
    group {
      id
      name
      description
      website
      domain
      addressDetails {
        id
        city 
      }
      courses {
        id       
      }
      university {
        value: id
        label: alias
      }
      contactDetails {
        email
        firstName
        lastName
        mobileNumber
      }
      type
      status
    } 
  }
}
`;

const deleteInstituteMutation = gql`
  mutation ($id: String!) {
    deleteInstitute(id: $id) {
      status
      message
    }
  }
`;

// U can use this also for all limit = 0 skip = 0
const getInstitutesQuery = gql`
  query ($limit: Int, $skip: Int, $filters: InstituteFilterInput) {
    institutes(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        id
        name
        description
        website
        domain
        udise
        role
        email
        mobileNumber
        addressDetails {
          id
          city
        }
        courses {
          id
          name
          code
          alias
          duration
          university {
            id
            name
            alias
          }
        }
        university {
          value: id
          label: name
        }
        contactDetails {
          email
          firstName
          lastName
          mobileNumber
        }
        type
        status
        group {
          id
          name
          email
          mobileNumber
          description
          website
          domain
          udise
          role
          addressDetails {
            id
            city
          }
          courses {
            id
            university {
              id
              name
              alias
            }
          }
          university {
            value: id
            label: alias
          }
          contactDetails {
            email
            firstName
            lastName
            mobileNumber
          }
          type
          status
        }
      }
    }
  }
`;

const getInstituteGroupQuery = gql`
  query ($limit: Int, $skip: Int, $filters: InstituteFilterInput) {
    institutes(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
      }
    }
  }
`;

const getAllInstituteQuery = gql`
  query ($limit: Int, $skip: Int, $filters: InstituteFilterInput) {
    institutes(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
        group {
          value: id
          label: name
        }
      }
    }
  }
`;
const getInstitutesCountQuery = gql`
  query {
    instituteCounts {
      students
      institutes
      newInstitutes
      pendingInstitutes
      employees
    }
  }
`;

const getInstitutesDemographicQuery = gql`
  query {
    institutesDemographic {
      institute {
        name
      }
      students
    }
  }
`;

export {
  getInstitutesDemographicQuery,
  getInstitutesCountQuery,
  getInstitutesQuery,
  getInstituteGroupQuery,
  applyLanguage,
  updateInstituteMutation,
  getAllInstituteQuery
};

export function useQueryInstitutes(fetchPolicy: any = 'network-only') {
  const [
    getInstitutes,
    { called, error: institutesError, loading: institutesLoading, data: institutesData, refetch: refetchInstitutesQuery },
  ] = useLazyQuery(getInstitutesQuery, { fetchPolicy: fetchPolicy });
  return { getInstitutes, institutesData, institutesError, institutesLoading, refetchInstitutesQuery };
}

export function useQueryInstituteFilter() {
  const [getInstituteFilterData, { data: instituteFilterData }] =
    useLazyQuery(getInstituteOptionsQuery);
  return { getInstituteFilterData, instituteFilterData };
}

export function useQueryInstituteGroup() {
  const [getInstituteGroups, { data: instituteGroupsData }] = useLazyQuery(getInstituteOptionsQuery);
  return { getInstituteGroups, instituteGroupsData };
}

export function useUpdateInstitute(currentFilter: any, beforeUpdate: any, afterUpdate: any) {
  let ErrorOccur = false;
  const [updateInstitute, { error: updateInstituteError, data: updateInstituteData }] = useMutation(
    updateInstituteMutation,
    {
      update: (cache, { data: { updateInstitute } }) => {
        console.log("updateInstitute", updateInstitute, beforeUpdate, afterUpdate)
        let queryData;
        if (updateInstitute.type !== 'GROUP') {
          try {
            queryData = readCacheQuery(cache, getInstitutesQuery, currentFilter);
          } catch (e) {
            ErrorOccur = true;
            // console.log("error in reading cache", e);
          }
          if (queryData) {
            const idx = queryData?.institutes?.data.find((dt: any) => dt.id == updateInstitute.id);
            console.log("idx", idx)
            if (idx != -1) {
              queryData.institutes.data[idx] = updateInstitute;
              try {
                writeCacheQuery(
                  cache,
                  getInstitutesQuery,
                  currentFilter,
                  queryData,
                );
              } catch (e) {
                ErrorOccur = true;
                //console.log('error', e.messgae);
              }
            }
          }
        }
      },
    },
  );
  return { updateInstitute, updateInstituteData, updateInstituteError };
}

export function useAddInstitute(
  page: number,
  groupId: string,
  searchValue: string,
  dataLimit: number,
) {
  let ErrorOccur = false;
  const [mutate, { data, error }] = useMutation(addInstituteMutation, {
    update: (cache, { data: { createInstitute } }) => {
      let queryData;
      let instituteList = [];
      let totalCount = 0;

      let filters = getInstituteFilter(searchValue);
      let instituteFilter = createSearchFilter(dataLimit, (page - 1) * dataLimit, filters);
      //read
      try {
        queryData = readCacheQuery(cache, getInstitutesQuery, instituteFilter);
      } catch (e) {
        ErrorOccur = true;
        //console.log(e.messgae);
      }

      //console.log(queryData);
      let instituteData = queryData?.institutes;
      let existingInstitutes = instituteData.data;
      totalCount = instituteData.totalCount;
      let type = createInstitute.type;
      instituteList = [...existingInstitutes];

      if (type === 'GROUP') {
        //working
        if (page == 1) {
          if (existingInstitutes.length >= limit) {
            //existingInstitutes.pop();
            //existingInstitutes.splice(-1,1)
          }
          instituteList = [createInstitute, ...existingInstitutes];
        }
        totalCount = totalCount + 1;
      }

      if (type !== 'GROUP') {
        if (groupId === '') {
          //working
          if (page == 1) {
            // if(existingInstitutes.length >= limit){
            //   existingInstitutes.pop();
            // }
            instituteList = [createInstitute, ...existingInstitutes];
          }
          totalCount = totalCount + 1;
        }

        if (groupId) {
          //working
          let list = [] as any;
          instituteList.forEach((institute: any, index: number) => {
            let currentInstitute = { ...institute };

            if (currentInstitute.id === groupId) {
              currentInstitute['group'] = [createInstitute, ...currentInstitute.group];
            }
            list.push(currentInstitute);
          });
          instituteList = list;
          //console.log(instituteList);
        }
      }

      let lastdata = {
        data: instituteList,
        totalCount: totalCount,
        __typename: instituteData?.__typename,
        hasNextPage: instituteData?.hasNextPage,
      };
      //console.log(lastdata);
      //write
      try {
        writeCacheQuery(cache, getInstitutesQuery, instituteFilter, {
          institutes: lastdata,
        });
      } catch (e) {
        ErrorOccur = true;
        //console.log('error', e.messgae);
      }
    },
  });
  return { mutate, data, error, ErrorOccur };
}

export function useApproveInstitute(page: number) {
  const [mutate, { data, error }] = useMutation(approveInstituteMutation);
  return { mutate, data, error };
}

export function useDeleteInstitute(
  page: number,
  groupId: string,
  searchValue: string,
  dataLimit: number,
) {
  const [deleteInstitute, { error: deleteInstituteError, data: deleteInstituteData }] = useMutation(
    deleteInstituteMutation,
    {
      async update(cache, { data: { deleteInstitute } }) {
        let queryData: any;
        let filters = getInstituteFilter(searchValue);
        let instituteFilter = createSearchFilter(dataLimit, (page - 1) * dataLimit, filters);
        try {
          queryData = readCacheQuery(cache, getInstitutesQuery, instituteFilter);
        } catch (e) {
          //console.log(e.messgae);
        }

        let instituteObject = queryData?.institutes;
        let message = deleteInstitute.message;
        let instituteId = message.replace('Successfully deleted institute for ', '');
        let dataToUpdate;
        let list = [] as any;
        if (groupId !== '') {
          //working

          for (let i = 0; i < instituteObject.data.length; i++) {
            let instituteData = { ...instituteObject.data[i] };
            let updatedList: any;
            if (instituteData.id === groupId) {
              updatedList = instituteData.group.filter((t: any) => {
                return t.id !== instituteId;
              });
              instituteData['group'] = updatedList;
            }

            list.push(instituteData);
          }

          dataToUpdate = {
            data: list,
            totalCount: instituteObject.totalCount,
            __typename: instituteObject?.__typename,
            hasNextPage: instituteObject?.hasNextPage,
          };
          //console.log(dataToUpdate);
        } else {
          //working
          dataToUpdate = createDeleteData(instituteObject, instituteId);
        }

        if (deleteInstitute.status == 'success') {
          //write
          try {
            writeCacheQuery(cache, getInstitutesQuery, instituteFilter, {
              institutes: dataToUpdate,
            });
          } catch (e) {
            //console.log('error', e.messgae);
          }
          return null;
        }
      },
    },
  );

  return { deleteInstitute, deleteInstituteError, deleteInstituteData };
}

export function useMutateInstitute(queries: any) {
  const [mutate, { error }] = useMutation(queries);
  return { mutate, error };
}
