/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import { colors, fonts} from 'src/styles/theme/styles';
import './institute.css';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHistory } from 'react-router';
import { limit } from 'src/constant';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import styled from 'styled-components';
import { getStorageFunction, setStorageFunction } from 'src/components/services/storage-service';
import { KEYS } from 'src/constant/key';
import {
  ExpandCollapseCell,
  NoRecordsFound,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { TableBody, TableCell } from '@mui/material';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { getCellStyle } from 'src/utils/utility';

export default function CoursesTable(props) {
  const { data, prevPage } = props;
  const columns = [
    { label: 'name.label' },
    { label: 'alias.label' },
    { label: 'divisions.label' },
  ];
  const [trow, setT] = useState(false);
  const [current, setCurrent] = useState(null);
  const [headCells, setHeadCells] = useState(columns);

  const { t } = useI18n();
  const history = useHistory();

  const { theme } = useThemeSystem();

  const [cellState, setCellState] = useState<{current: null | number, open: boolean}>({
    open: false,
    current: null ,
  });

   const { TblContainer, TblHead } = useTable(
     data,
     headCells,
    //  [], // Adjust sorting functions as necessary
     null,
     data?.length,
   );

  useEffect(() => {
    handlePageCheck();
  }, [prevPage]);

  async function handlePageCheck() {
    const currentCourseIdx = await getStorageFunction(KEYS.CURRENT_COURSE_IDX);
    if (currentCourseIdx) {
      if (prevPage && (prevPage === 'Students' || prevPage === 'Unassigned')) {
        handleOpen(parseInt(currentCourseIdx));
      } else {
        await setStorageFunction(KEYS.CURRENT_COURSE_IDX, null);
      }
    }
  }

  async function handleClose() {
    setT(false);
    setCurrent(null);
    await setStorageFunction(KEYS.CURRENT_COURSE_IDX, null);
  }

  async function handleOpen(i: number) {
    setT(true);
    setCurrent(i);
    await setStorageFunction(KEYS.CURRENT_COURSE_IDX, i);
  }

  return (
    <Wrapper>
      <TblContainer>
        <TblHead setHeadCells={setHeadCells} />
        {data &&
          data?.length > 0 &&
          data?.map((d: any, i: number) => (
            <>
              <TableBody>
                <TableRows>
                  <ExpandCollapseCell
                    trow={cellState.open}
                    current={cellState.current}
                    i={i}
                    style={{
                      width: '25%',
                      ...getCellStyle({
                        trow,
                        current,
                        id: i,
                        isFirstCell: true,
                        isLastCell: true,
                        itemId: d?.id,
                      }),
                    }}
                    textValue={d?.name}
                    CollapseFn={() => {
                      setT(false);
                      setCurrent(null);
                      setCellState({ open: false, current: null });
                    }}
                    ExpandFn={() => {
                      setT(true);
                      setCurrent(i);
                      setCellState({ open: true, current: i });
                    }}
                    disabled={!d?.classes || d?.classes.length === 0}
                  />
                  <TableCells
                    style={{
                      width: '25%',
                      ...getCellStyle({
                        trow,
                        current,
                        id: i,
                        isFirstCell: false,
                        isLastCell: false,
                        itemId: d?.id,
                      }),
                    }}
                  />
                  <TableCells
                    style={{
                      width: '25%',
                      ...getCellStyle({
                        trow,
                        current,
                        id: i,
                        isFirstCell: false,
                        isLastCell: true,
                        itemId: d?.id,
                      }),
                    }}
                  />
                </TableRows>
              </TableBody>

              {trow && current === i && (
                <TableBody
                  style={{
                    borderColor: 'rgba(43, 120, 202, 0.3)',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                  }}>
                  {d?.classes?.map((nestd: any, inner: number) => (
                    <TableRows>
                      <TableCell style={{ width: '25%' }} />
                      <TableCell style={{ width: '25%' }}>
                        <NormaltextAtom value={nestd?.name} />
                      </TableCell>
                      <TableCell style={{ width: '25%' }}>
                        {nestd?.batches?.map(batch => (
                          <>
                            <BatchName>
                              <NormaltextAtom value={batch?.name} fontFamily={fonts.semibold} fontSize={1.4} />
                            </BatchName>
                            <Batchcontainer>
                              <DivisionContainer>
                                {batch.divisionDetails?.map(detail => (
                                  <>
                                    <DivisionLabel
                                      onClick={() =>
                                        history.push(
                                          `/classroom/${batch.id}/${detail.division}/students/limit/${limit}/page/1`,
                                        )
                                      }
                                      testID={`${batch?.name}-${detail.division}`}>
                                      <NormaltextAtom
                                        value={detail.division}
                                        color={colors.primaryColor}
                                        fontWeight={'normal'}
                                      />
                                    </DivisionLabel>
                                  </>
                                ))}
                              </DivisionContainer>
                              <UnassignedWrapper
                                onClick={() => history.push(`/classroom/${batch?.id}`)}
                                divisionExist={Boolean(
                                  batch?.divisionDetails && batch.divisionDetails.length > 0,
                                )}>
                                <MediumTextSelect
                                  value={t('unassigned.label')}
                                  color={colors.primaryColor}
                                  fontWeight={'normal'}
                                />
                              </UnassignedWrapper>
                            </Batchcontainer>
                          </>
                        ))}
                      </TableCell>
                    </TableRows>
                  ))}
                </TableBody>
              )}
            </>
          ))}
      </TblContainer>
      {data && data?.length === 0 && <NoRecordsFound colspan={3} maxHeight={568} />}
    </Wrapper>
  );
}

const Batchcontainer = styled.div`
  border: 1px solid rgba(43, 120, 202, 0.3);
  height: auto;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  border-radius: 0 0 5px 5px;
`;

const BatchName = styled.div`
  background: rgba(43, 120, 202, 0.05);
  border: 1px solid rgba(43, 120, 202, 0.3);
  border-bottom: 0;
  padding: 4px 10px;
  border-radius: 5px 5px 0 0;
`;

const DivisionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const DivisionLabel = styled.div`
  padding-right: 20px;
  padding-bottom: 8px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  .MuiTable-root {
    border-collapse: collapse;
    table-layout: fixed;
  }
  .MuiTableCell-body {
    vertical-align: top;
    word-break: break-word;
  }
`;

const UnassignedWrapper = styled.div<{ divisionExist: boolean }>`
  width: 100%;
  padding-top: 0;
  cursor: pointer;
`;
