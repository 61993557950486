import { AUTOCOMPLETE_MODULE, ERROR, SUCCESS, limit } from "src/constant";
import {
  admissionListing as Item,
  admissionListingServerResponse as TContent,
} from "./admissionTypes";
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from "src/components/molecules/Table/TableAtom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createSearchFilter, debounce } from "src/components/services";
import { useNavigate, useParams } from "src/routes/routing.web";

import { AdmissionForm } from "src/components/molecules/Admission/AdmissionForm/index.web";
import { EditIcon } from "src/components/atoms/ActionIcons";
import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import OutlineButton from "src/components/atoms/Button/OutlineButtton";
import Paginations from "src/components/atoms/Pagination/Paginations.web";
import Search from "src/components/atoms/SearchBar/index.web";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { admissionFormState as TForm } from "src/components/molecules/Admission/AdmissionForm/types";
import { TableBody } from "@mui/material";
import { colors } from "src/styles/theme/styles";
import { getFormattedLectureDate as dateFormatter } from "../Schedule/scheduleHelpers";
import { defaultPagingURLSegment } from "src/components/molecules/Navigation/SidebarManifest";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { getCommonNameSearchFilter } from "src/components/services/filters";
import { AdmissionListingHeadCells as headCells } from "./AdmissionHelpers";
import { height } from "src/constant/device";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useFilter } from "src/contexts/filter-context";
import { useGetAdmissionsListing } from "src/graphql/admission/admissions";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import useTable from "src/components/molecules/Table/UseTable";
import { userInformation } from "src/utils/manageState";

const FILTER_MODULE = AUTOCOMPLETE_MODULE.ADMISSION;
const DEBOUNCE_DELAY = 500;

export default function Admissions() {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const navigate = useNavigate();
  const { setAlertDetails } = useAlertSystem();
  const instituteID = userInformation()?.userDetail?.institute ?? "";
  let searchValue = "" as string;
  const { page = 1, dataLimit = limit }: any = useParams();
  if (filters && filters?.[FILTER_MODULE]?.on) {
    searchValue = filters?.[FILTER_MODULE]?.search;
  }
  const [currentPage, setCurrentPage] = useState<number>(parseInt(page));
  const [rowLimit, setRowLimit] = useState(parseInt(dataLimit) ?? limit);
  const [form, setForm] = useState<TForm>();
  const [content, setContent] = useState<TContent>();
  const [searching, setSearchingAdmission] = useState(searchValue);

  const { getAdmissionsListing, data, loading, refetch } =
    useGetAdmissionsListing();
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    content?.data,
    headCells
  );

  useEffect(() => {
    setHeading([{ text: t("admissions.label"), url: "" }]);
  }, []);

  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  useMemo(() => {
    if (data?.admissions) {
      setContent({
        data: data?.admissions?.data ?? [],
        totalCount: data?.admissions?.totalCount ?? 0,
      });
    }
  }, [data?.admissions]);

  useEffect(() => {
    setSearchingAdmission(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue]);

  const handleFetch = () => {
    const filters = getCommonNameSearchFilter(searchValue);
    const admissionFilter = createSearchFilter(
      rowLimit,
      (currentPage - 1) * rowLimit,
      filters
    );
    getAdmissionsListing({ variables: admissionFilter });
  };

  const delayedQuery = useCallback(
    debounce((q: string) => {
      const shouldPersist = q.trim() !== "";
      const filter = { [FILTER_MODULE]: { on: shouldPersist, search: q } };
      setFilter(filter);
    }, DEBOUNCE_DELAY),
    []
  );

  const handleSearch = (q: string) => {
    setSearchingAdmission(q);
    delayedQuery(q);
  };

  const triggerAdmission = (admissionID?: string) => {
    setForm({
      state: "OPEN",
      ...(admissionID ? { mode: "EDIT", admissionID } : { mode: "ADD" }),
    });
  };

  const handleRedirect = (item: Item) => {
    navigate(`/admissions/${item.id}/detail/${defaultPagingURLSegment}`);
  };

  const onSuccess = (message: string): void => {
    setAlertDetails({ message, level: SUCCESS });
    if (refetch) refetch();
    onClose();
  };

  const onError = (message: string): void => {
    setAlertDetails({ message, level: ERROR });
    onClose();
  };

  const onClose = (): void => {
    setForm({ state: "CLOSE", mode: "ADD" });
  };

  const copyPublicLink = () => {
    if (instituteID) {
      navigator.clipboard.writeText(
        `${window.location.origin}/admission/links/${instituteID}`
      );
    }
  };

  return (
    <LoadContentWrapper>
      <Section>
        <Search
          id="admissionSearch"
          handleChange={handleSearch}
          value={searching}
          label={t("searchAdmissions.text")}
        />
        <ButtonWrapper>
          <OutlineButton
            label={t("publicLinkCopy.label")}
            onPress={copyPublicLink}
            icon={faCopy}
            iconColor={colors.primary}
            iconSize={14}
          />
          <SecondaryBtn
            label={t("addAdmission.text")}
            onPress={() => triggerAdmission()}
            lines={1}
          />
        </ButtonWrapper>
      </Section>
      <TblContainer height={"100%"}>
        <TblHead />
        <>
          {!loading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item: Item) => (
                    <TableRows key={item.id}>
                      <TableCells
                        value={item.name}
                        color={colors.primaryColor}
                        clickable={true}
                        onPress={() => handleRedirect(item)}
                      />
                      <TableCells value={item.batch?.name ?? "-"} />
                      <TableCells
                        value={item.allowedApplicantType?.join(", ")}
                      />
                      <TableCells value={item.totalSeats} />
                      <TableCells value={dateFormatter(item.lastDate)} />
                      <TableCells
                        value={item?.status}
                        color={
                          item.status === "OPEN" ? colors.green : colors.red
                        }
                      />
                      <TableActions align="right">
                        <TableActionsWrapper>
                          <EditIcon
                            tooltipTitle={t("editAdmission.text")}
                            onPress={() => triggerAdmission(item.id)}
                          />
                        </TableActionsWrapper>
                      </TableActions>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>
      {!!content?.totalCount && !loading && (
        <Paginations
          pathName={"admissions"}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          total={content.totalCount}
          hidePagination={content.totalCount <= rowLimit}
        />
      )}
      {form && form.state === "OPEN" && (
        <AdmissionForm
          form={form}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
        />
      )}
    </LoadContentWrapper>
  );
}

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const TableActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 12px;
`;
