import { DT, parseToDate } from "src/constant/dateTime";

import { format } from "date-fns";

const headCells = [
  {
    id: "name",
    label: "name.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "email",
    label: "email.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "applicantId",
    label: "Applicant ID",
    align: "left",
    disableSorting: true,
  },
];

function showRegenerateLabel(lotteryData?: {
  lottery?: { regenerationHistory?: any[] };
}): boolean {
  const lotteryGenerated: boolean = !!(
    lotteryData?.lottery &&
    Array.isArray(lotteryData.lottery?.regenerationHistory) &&
    lotteryData.lottery.regenerationHistory.length > 0
  );
  return lotteryGenerated;
}

const LotteryStatus = {
  GENERATED: "GENERATED",
  PUBLISHED: "PUBLISHED",
};

const getFormattedPublishedDate = (publishedDate?: string): string => {
  if (publishedDate) {
    const pubDate = parseToDate(publishedDate);
    if (pubDate) return format(pubDate, DT.DATE_TIME_FORMAT_12_HOUR);
  }
  return "-";
};

export const LotteryHelpers = {
  headCells,
  showRegenerateLabel,
  LotteryStatus,
  getFormattedPublishedDate,
};

export type TLotteryListingItem = {
  id: string;
  applicationId: string;
  user: {
    id: string;
    fullName: string;
    email: string;
  };
};
