import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody, TableCell, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import BatchForm from 'src/components/molecules/Academics/BatchForm.web';
import { getBatchSettingsQuery, useAddBatch, useCreateBatchSettings, useDeleteBatch, useQueryBatch, useUpdateBatch } from 'src/graphql/academics/batch';
import {
  limit, ERROR, SUCCESS, USER_TYPE, AUTOCOMPLETE_MODULE, EMPLOYEE_TYPES 
} from 'src/constant/index';
import { useParams } from 'src/routes/routing.web';
import { useI18n } from 'src/i18n/hooks';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, createSortFilter, debounce } from 'src/components/services';
import { getSubjectGroupFilter } from 'src/components/services/filters';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import ERROR_MSG from 'src/constant/error';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';
import styled from 'styled-components/native';
import Filter from 'src/components/molecules/Filter/index.web';
import { height, width } from 'src/constant/device';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { useQuery } from '@apollo/client';
import { getAcademicYearByIdQuery } from 'src/graphql/academics/years';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { isBefore } from 'date-fns';
import { DT, parseToDate } from 'src/constant/dateTime';
import { GradingSystemObject } from './types';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { RegisterIcon } from 'src/components/atoms/ActionIcons';
import CourseSettingsForm from 'src/components/molecules/Academics/CourseSettingsForm';

const headCells1 = [
  {
    id: 'batch_name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'name',
    isSorted: false,
  },
  {
    id: 'batch_alias',
    label: 'alias.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'alias',
    isSorted: false,
  },
  {
    id: 'class',
    label: 'class.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'batch_start',
    label: 'startDate.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'startDate',
    isSorted: false,
  },
  {
    id: 'batch_end',
    label: 'endDate.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'endDate',
    isSorted: false,
  },
  {
    id: 'actions',
    label: 'actions.label',
    align: 'right',
    disableSorting: true,
  },
];

const maxHeight = 440;

export default function BatchWeb() {
  const { theme } = useThemeSystem();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const [canClick, setCanClick] = useState(true);
  let row;
  let searchValue = '' as string;


  const { page = 1, dataLimit = limit, academicId }: any = useParams();

  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  const { filters, setFilter } = useFilter();
  const currentUserObject = userInformation();
  const { userType, userDetail } = currentUserObject;

  const [modalState, handleModal] = useState(false);
  const [editBatchData, setBatchData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [filterHeight, setFilterHeight] = useState(0);

  let { setAlertDetails } = useAlertSystem();
  if (filters && filters?.subject_group?.on) {
    searchValue = filters?.subject_group?.search;
  }
  const [searching, setSearchingSubjectGroup] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [divisionArray, setDivisionArray] = useState([]);
  const [gradingSystemData, setGradingSystemData] = useState<GradingSystemObject | null>(null);
  const [batchId, setBatchId] = useState<string>('');
  const [assignRegistrationModal, setAssignRegistrationModal] = useState<boolean>(false);

  const { query: getBatch, data: batchData, refetch: reFetchBatch, loading:batchDataLoading } = useQueryBatch();
  row = batchData?.batches?.data;
  const [totalCount, setTotalCount] = useState(batchData?.batches?.totalCount ?? null);
  const { addBatch, ErrorOccur } = useAddBatch(currentPage, searchValue, rowLimit);
  const { updateBatch } = useUpdateBatch();
  const { deleteBatch } = useDeleteBatch();
  const { data: academicYearData } = useQuery(getAcademicYearByIdQuery, {
    variables: {
      id: academicId,
    }
  });
  const { createBatchSettings } = useCreateBatchSettings();

  const filterItems = [
    {
      id: 1,
      label: 'Batch Name',
      type: 'string',
      localField: 'name',
      filterType: 'filter',
    },
    {
      id: 2,
      label: 'Batch Alias',
      type: 'string',
      localField: 'alias',
      filterType: 'filter'
    },
    {
      id: 3,
      label: 'Class Alias',
      type: 'string',
      localField: 'class',
      lookupKey: 'classes',
      propName: 'alias', // lookup will need propName
      filterType: 'lookup',
    }
  ];

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);
  
  useEffect(() => {
    const count = batchData?.batches?.totalCount;
      if(count){
        setTotalCount(count);
      }
    }, [batchData?.batches?.totalCount]);

  useEffect(() => {
    setSearchingSubjectGroup(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (academicYearData) setTitle();
  }, [academicYearData]);

  function setTitle() {
    setHeading([
      {
        text: t('academicYears.label'),
        url: '/academics/years/limit/50/page/1',
      },
      {
        text: `${academicYearData?.AcademicYear?.alias} ${t('batches.label')}`,
        url: '',
      },
    ]);
  }

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, headCells, rowLimit]);

  const handleFilters = (filter: any) => {
    const batchFilter = {
      limit: rowLimit,
      skip: (currentPage - 1) * rowLimit,
      ...filter,
    };
    getBatch({ variables: batchFilter });
  };

  function handleFetch() {
    const filtersValue = getSubjectGroupFilter(searchValue);
    const sortCriteria = createSortFilter(headCells);
    const batchFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filtersValue, sortCriteria);
    if (!batchFilter.filters) {
      batchFilter.filters = {};
    }
    batchFilter.filters.academicYear = { eq: academicId };
    getBatch({ variables: batchFilter });
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
  );

  const editBatchModal = (selectedBatch: any) => {
    setBatchData(selectedBatch);
    hideAlert();
    setIsEditModal(true);
    handleModal(true);
  };

  const addBatchGroupModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  const deleteBatchModal = (selectedBatch: any) => {
    setBatchData(selectedBatch);
    hideAlert();
    setDeleteOverlay(true);
  };

  const closeModal = () => {
    handleModal(false);
    resetForm();
    setGradingSystemData(null);
  };

  const closeDeleteModal = () => {
    setDeleteOverlay(false);
    setBatchData({});
  }

  function resetForm() {
    setValue('batch_name', '');
    setValue('batch_alias', null);
    setValue('class', null);
    setValue('batch_start', null);
    setValue('batch_end', null);
    setValue('grading_type', null);
    setValue('registration_no_prefix', '');
    setValue('registration_no', '');
    setValue('registration_no_preference', null);
    setDivisionArray([]);
    clearErrors();
  }

  function dateFormatter(val: string) {
    return parseToDate(val, DT.DATE_FORMAT_SLASH)
  }

  const getBatchPayload = (BatchData: any, isEdit: boolean) => {
    const endDate = dateFormatter(BatchData.batch_end);
    const startDate = dateFormatter(BatchData.batch_start);

    if (endDate && startDate && isBefore(endDate, startDate)) {
      throw new Error(t('error.endDate.invalid'));
    } else {
      let batchObject: any = {
        name: BatchData.batch_name,
        alias: BatchData.batch_alias,
        startDate: BatchData.batch_start,
        endDate: BatchData.batch_end,
        ...(BatchData?.grading_type?.value ? { gradingType: BatchData.grading_type.value } : {}),
        ...(BatchData?.registration_no_prefix
          ? { grNoPrefix: BatchData.registration_no_prefix }
          : {}),
        ...(BatchData?.registration_no
          ? { startingGRNo: parseFloat(BatchData.registration_no) }
          : {}),
        ...{grade: gradingSystemData?.id || null},
      }

      if (isEdit) {
        batchObject['id'] = editBatchData?.value;
        batchObject['divisionDetails'] = divisionArray;
      } else {
        batchObject['class'] = BatchData.class.value;
        batchObject['academicYear'] = academicId;
        if (divisionArray.length > 0) batchObject['divisionDetails'] = divisionArray;
      }
      return batchObject;
    }
  };

  const handleAddBatch = async (BatchData: any) => {
    setCanClick(false);
    try {
      const getResponse = await addBatch({ variables: { payload: getBatchPayload(BatchData, false) } });
      if (getResponse.data && !ErrorOccur) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.BATCH, level: SUCCESS });
        if (reFetchBatch) reFetchBatch();
      } else {
        setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
      }
      setCanClick(true);
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateBatch = async (BatchData: any) => {
    setCanClick(false);
    try {
      const respose = await updateBatch({
        variables: { payload: getBatchPayload(BatchData, true) },
      });
      if (respose.data) {
        closeModal();
        if (reFetchBatch) reFetchBatch();
        setAlertDetails({ message: UPDATE_SUCCESS.BATCH, level: SUCCESS });
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleDeleteBatch = async () => {
    setCanClick(false);
    try {
      const deleteResponse = await deleteBatch({
        variables: { id: editBatchData?.value },
      });
      if (deleteResponse?.data?.deleteBatch) {
        setAlertDetails({ message: DELETE_SUCCESS.BATCH, level: SUCCESS });
        if (reFetchBatch) reFetchBatch();
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
    setCanClick(true);
    closeDeleteModal();
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.SUBJECT_GROUP]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchBatches(batch: string) {
    let isClear = batch?.length === 0;
    persistSearch(!isClear, batch);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchBatches(q), 500),
    [],
  );

  const handleSearch = (batch: string) => {
    setSearchingSubjectGroup(batch);
    delayedQuery(batch);
  };

  function closeAssignRegistrationModal() {
    setAssignRegistrationModal(false);
    setBatchId('');
    resetAssignRegistrationForm();
    clearErrors();
  }

  function resetAssignRegistrationForm() {
    setValue('registration_no_prefix', null);
    setValue('registration_no', null);
  }

  const handleAssignRegistrationModal = async (id: string) => {
    setBatchId(id);
    setAssignRegistrationModal(true);
  };

  const handleAssignRegistration = async (formData: any) => {
    setCanClick(false);
    try {
      if (formData?.registration_no_prefix && !formData?.registration_no) {
        throw new Error('Starting Number is required to accompany prefix');
      }
      const batchSettingsPayload = {
        id: batchId,
        grNoPrefix: formData?.registration_no_prefix || null,
        startingGRNo: parseFloat(formData?.registration_no) || null,
      }
      const response = await createBatchSettings({
        variables: { payload: batchSettingsPayload },
        refetchQueries: [
          {
            query: getBatchSettingsQuery,
            variables: {
              id: batchId,
            },
          },
        ],
      });
      if (response?.data?.updateBatchSetting) {
        setCanClick(true);
        closeAssignRegistrationModal();
        setAlertDetails({ message: ADD_SUCCESS.ASSIGN_REGISTRATION, level: SUCCESS });
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  return (
    <LoadContentWrapper>
      <FlexRowView isEdbaAdmin={userType === USER_TYPE.EDBA_ADMIN}>
        <SearchWrapper>
          <Search
            id="batchSearch"
            handleChange={handleSearch}
            value={searching}
            height={32}
            label={t('searchBatch.text')}
          />
        </SearchWrapper>

        <FilterWrapper filterHeight={filterHeight} isEdbaAdmin={userType === USER_TYPE.EDBA_ADMIN}>
          <Filter
            filterItems={filterItems}
            handleFilters={handleFilters}
            setFilterHeight={setFilterHeight}
            searchValue={searchValue}
            academicYear={academicId}
          />
        </FilterWrapper>

        {userType === USER_TYPE.EMPLOYEE ? (
          <AddCourseWrapper>
            <SecondaryBtn label={t('addBatch.text')} onPress={addBatchGroupModal} />
          </AddCourseWrapper>
        ) : null}
      </FlexRowView>

      <TblContainer height={'100%'}>
        <TblHead setHeadCells={setHeadCells} />
        <>
          {!batchDataLoading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                    <TableRows key={i}>
                      <TableCells value={item?.label} />
                      <TableCells value={item?.alias} />
                      <TableCells value={item?.class?.alias} />
                      <TableCells value={item?.startDate} />
                      <TableCells value={item?.endDate} />

                      <TableActions align="right">
                        <TableActionView>
                          <Tooltip title="Edit Batch">
                            <TouchableOpacity onPress={() => editBatchModal(item)}>
                              <FontAwesomeIcon
                                icon={faPencil}
                                color={theme?.table?.editIconColor}
                              />
                            </TouchableOpacity>
                          </Tooltip>

                          {userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) && (
                            <RegisterIcon onPress={() => handleAssignRegistrationModal(item?.settings?.id)} />
                          )}

                          <View>
                            <Tooltip title="Delete Batch">
                              <TouchableOpacity onPress={() => deleteBatchModal(item)}>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  color={theme?.table?.editIconColor}
                                />
                              </TouchableOpacity>
                            </Tooltip>
                          </View>
                        </TableActionView>
                      </TableActions>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>

      <Pagination
        pathName={`academics/years/${academicId}/batch`}
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        setRowLimit={setRowLimit}
        hidePagination={totalCount <= rowLimit}
      />
      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        onAlert={null}
        modalVisible={modalState}
        Headerpopup={isEditModal ? t('editBatch.text') : t('addBatch.text')}
        handleSave={handleSubmit(isEditModal ? handleUpdateBatch : handleAddBatch)}
        height={644}
        maxWidth={'lg'}
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}>
        <BatchForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          isEditModal={isEditModal}
          editBatchData={editBatchData}
          reset={reset}
          divisionArray={divisionArray}
          setDivisionArray={setDivisionArray}
          setGradingSystemData={setGradingSystemData}
          gradingSystemData={gradingSystemData}
        />
      </NormalModal>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={closeDeleteModal}
        modalVisible={deleteOverlay}
        handleSave={handleDeleteBatch}
        Headerpopup="deleteBatch.text">
        {t('deleteBatch.warning')}
      </DeleteModal>

      <NormalModal
        isSubmitting={!canClick}
        width={578}
        setModalVisible={closeAssignRegistrationModal}
        modalVisible={assignRegistrationModal}
        handleSave={handleSubmit(handleAssignRegistration)}
        Headerpopup={t('assignRegistration.label')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={'save.label'}
        contentStyles={{ minHeight: 188, paddingBottom: 24 }}>
        <CourseSettingsForm
          control={control}
          errors={errors}
          setValue={setValue}
          watch={watch}
          type={'batch'}
          id={batchId}
        />
      </NormalModal>
    </LoadContentWrapper>
  );
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${(props) => (props.isEdbaAdmin ? 'space-between' : 'flex-start')};
  z-index: 10;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  margin-right: 8px;
`;

const FilterWrapper = styled.View`
  left: 0;
  flex: 1;
  align-items: flex-start;
  height: ${(props) => props.filterHeight * 32}px;
  width: ${(props) => (props.isEdbaAdmin ? (width > 1700 ? 75 : 72) : width > 1700 ? 80 : 80)}%;
`;

const AddCourseWrapper = styled.View`
  flex: 1;
  align-items: flex-end;
  max-width: 140px;
  min-width: 112px;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 13px;
`;

const IconView = styled.View`
  margin-right: 13px;
`;

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
