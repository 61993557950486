import { AUTOCOMPLETE_MODULE } from 'src/constant/index';

const json = [
  {
    "name": "Add Subject",
    "fields": [
      {
        "id": "title",
        "lebel": "",
        "textHead": "title.required.label",
        "textFoot": "",
        "type": "string",
        "required": true,
        "required_error": "TITLE_REQUIRED",
        "validation_regex": "",
        "error_msg": "",
        "isCustomDisable": true,
        "disableInputConstraints": true
      },
      {
        "id": "description",
        "lebel": "",
        "textHead": "description.required.label",
        "textFoot": "",
        "type": "string",
        "required": true,
        "required_error": "DESCRIPTION_REQUIRED",
        "validation_regex": "",
        "multiline": true,
        "error_msg": "",
        "disableInputConstraints": true
      },
      {
        "id": "totalMarks",
        "lebel": "",
        "textHead": "marksAssignment.required.label",
        "textFoot": "",
        "type": "number",
        "required": true,
        "required_error": "MARKS_REQUIRED",
        "validation_regex": "INTGER_ONLY",
        "error_msg": "MARKS_INVALID",
        "keyboardType": "number-pad"
      },
      {
        "id": "dueDate",
        "textHead": "dueDate.required.label",
        "type": "date-time-picker",
        "dateTimeMode": "date",
        "disablePast": true,
        "required": true,
        "validation_regex": "",
        "required_error": "DUE_DATE_REQUIRED",
        "error_msg": ""
      },
      {
        "id": "attachmentLabel",
        "textHead": "attachFiles.text",
        "fileHead": "attachedFiles.label"
      },
      {
        "id": "attachment",
        "textHead": "addAttachment.text",
        "type": "file",
        "required": false,
        "multiple": true,
        "required_error": "ATTACHMENT_REQUIRED",
        "documentType": "assignment"
      }
    ]
  }
]

export default json;
