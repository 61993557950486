import { format, isValid, parse } from "date-fns";

import { isWeb } from "./device";

const FALLBACK_TIMEZONE: string = "Asia/Calcutta";

// Firefox often resists browser fingerprinting & returns mock timezone - UTC
const isFirefox: boolean =
  isWeb && navigator?.userAgent?.indexOf("Firefox") !== -1;

const getTimezone = (): string => {
  let timezone: string = FALLBACK_TIMEZONE;
  try {
    if (typeof Intl === "object" && Intl?.DateTimeFormat && !isFirefox) {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  } catch (error) {
    console.error("Error while getting timezone:", error);
  }
  return timezone;
};

export const DT = {
  DATE_ONLY: "dd",
  MONTH_NUMBER: "M",
  MONTH_ABBREV: "MMM",
  MONTH_FULL: "MMMM",
  DAY_OF_WEEK: "eeee",
  DAY_OF_WEEK_ABBREVIATED: "eee",
  ORDINAL_DAY_WITH_FULL_MONTH: "do MMMM",
  ABBREVIATED_MONTH_YEAR: "MMM yyyy",
  MONTH_NAME_YEAR: "MMMM yyyy",
  DATE_ABBREVIATED_MONTH_YEAR: "dd MMM, yyyy",
  DATE_FORMAT_SLASH: "dd/MM/yyyy",
  ISO_DATE: "yyyy-MM-dd",
  YEAR_ONLY: "yyyy",
  TIME_12_HOUR: "hh:mm a",
  TIME_24_HOUR: "HHmm",
  HOUR_12_WITH_PERIOD: "hh a",
  HOUR_24: "HH",
  TIME_24_HOUR_WITH_COLON: "HH:mm",
  DATE_TIME_FORMAT_12_HOUR: "dd MMM yyyy, hh:mm a",
  DATE_TIME_SLASH_12_HOUR: "dd/MM/yyyy hh:mm a",
  DATE_TIME_FORMAT_WITH_TIMEZONE: "dd MMM yyyy, HH:mm XXX",
  WEEK_DATE_TIME_FORMAT: "eee, MMM dd, yyyy, hh:mm a",
  TIMEZONE: getTimezone(),
  RBC_CALENDAR_PARSE_FORMAT: "dd/MM/yyyy HH:mm",
};

export const parseToDate = (
  dateString: string,
  expectedDateStringFormat = DT.DATE_TIME_FORMAT_WITH_TIMEZONE,
  referenceDate = new Date()
) => {
  if (dateString) {
    const parsedDate = parse(
      dateString,
      expectedDateStringFormat,
      referenceDate
    );
    if (isValid(parsedDate)) {
      return parsedDate;
    }
  }
  return undefined;
};

export const formatDate = (
  dateStr: string,
  inputFormat: string,
  outputFormat: string
) => {
  const parsedDate = parse(dateStr, inputFormat, new Date());
  return format(parsedDate, outputFormat);
};
