import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import Element from '../Forms/ApplicationElement';
import attendanceFomJson from '../../../form-json/attendance/attendance-history-form.json';
import { isNative, isWeb } from 'src/constant/device';
import { graphqlQuery } from 'src/graphql/util';
import { getStudentsListQuery } from 'src/graphql/classes';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useI18n } from 'src/i18n/hooks';
import { AUTOCOMPLETE_MODULE } from 'src/constant';
import { mergeBatchClassLabel, properCase } from 'src/utils/utility';

export default function AttendanceHistoryForm(props: any) {
  const {
    errors,
    control,
    batches,
    setValue,
    selectedBatch,
    setselectedBatch,
    setselectedDivision,
    selectedDivision,
    selectedStudentInfo,
    selectedDurationType,
    setselectedDurationType,
    setselectedStudentInfo,
    onPressFunction,
    setAudienceType,
  } = props;
  const [elements, setElements] = useState<any>([]);
  const [showStudentsList, setShowStudentsList] = useState(selectedStudentInfo ? true : false);
  const [showDurationField, setShowDurationField] = useState(selectedDurationType ? true : false);

  const { t } = useI18n();

  useEffect(() => {
    // Reset on tab changes
    loadDivisions([])
    setValue('division', null);
    loadStudents([])
    setValue('students', null);

    if (selectedBatch && selectedDivision !== '') {
      loadDivisions(selectedBatch?.divisionDetails?.map((det) => det.division))
      setValue('batch', selectedBatch)
      setValue('division', { label: selectedDivision, value: selectedDivision })
      if (showStudentsList && selectedStudentInfo) {
        setValue('students', selectedStudentInfo)
      }
    }
  }, [])

  function createStudentsApiPayload(batch: any, division: string) {
    const studentFilter = {
      batch: {
        eq: batch.value,
      },
      class: {
        eq: batch.class.id,
      },
      division: {
        eq: division,
      },
    } as any;

    const param = {
      filters: studentFilter,
      limit: 0
    };
    return param;
  }

  function loadDivisions(divisionArray: any) {
    let options = [] as any;
    if (divisionArray && divisionArray?.length > 0) {
      divisionArray.forEach(function (item: any) {
        options.push({
          value: item,
          label: item,
        });
      });
    }
    attendanceFomJson[0].fields[2].option = options;
    const fields = [...attendanceFomJson[0]?.fields];
    setElements(fields);
  }

  function loadStudents(studentsArray: any) {
    const options = [] as any;
    if (studentsArray.length) {
      studentsArray.forEach(function (studentInfo: any) {
        options.push({
          value: studentInfo.id,
          label: properCase(studentInfo?.fullName),
        });
      });
    }
    attendanceFomJson[0].fields[3].option = options;
    const fields = [...attendanceFomJson[0]?.fields];
    setElements(fields);
  }

  function handleCustomSelectAudienceType(id: string, data: any) {
    const index = isWeb ? data?.value : data;
    if (index) {
      setselectedStudentInfo(null);
      setValue('students', null);
      setShowStudentsList(false);
      setShowDurationField(true);
    } else {
      setselectedDurationType(null);
      setValue('duration', null);
      setShowStudentsList(true);
      setShowDurationField(false);
    }
    setElements(attendanceFomJson[0].fields);
  }

  function handleCustomSelectDivision(id: string, data: any) {
    const division = isWeb ? data?.value : data;
    if (division) {
      setselectedDivision(division);
    } else {
      setselectedDivision("");
      setValue('students', null);
      loadStudents([]);
    }
  }

  async function handleCustomFunction(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      data = isWeb ? data : batches.find((bt: any) => bt.value === data);
      setValue('students', null);
      setValue('division', null);
      setselectedDivision("");
      loadStudents([]);
      loadDivisions([])
      if (data) {
        setselectedBatch(data);
        loadDivisions(data.divisionDetails?.map((det) => det.division));
      }
    }
    if (id === 'audienceType') {
      handleCustomSelectAudienceType(id, data);
      if (setAudienceType) setAudienceType(data?.value);
    } else if (id === 'division') {
      handleCustomSelectDivision(id, data);
    }
  }

  function viewHandler(id: string) {
    if (id == 'students' && !showStudentsList) {
      return false;
    } else if (id == 'duration' && !showDurationField) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (batches && batches.length) {
      let options = mergeBatchClassLabel(batches)
      setElements({});
      attendanceFomJson[0].fields[1].option = options;
      setElements(attendanceFomJson[0].fields);
    }
  }, [batches]);

  useEffect(() => {
    (async () => {
      if (selectedBatch && selectedDivision !== '' && showStudentsList) {
        const { loading, data: studentsData } = await graphqlQuery(
          getStudentsListQuery,
          createStudentsApiPayload(selectedBatch, selectedDivision),
          true,
        );
        if (!loading && studentsData) {
          if (studentsData.instituteStudents.data.length) {
            loadStudents(studentsData.instituteStudents.data);
          } else {
            loadStudents([]);
          }
        }
      }
    })();
  }, [selectedBatch, selectedDivision, showStudentsList]);

  return (
    <View style={isWeb ? styles.parentContainerWeb : styles.parentContainerNative}>
      {elements &&
        elements.map((field: any, i: number) => {
          const result = viewHandler(field?.id);
          if (result) {
            return (
              <View
                key={`attendance-history${i}`}
                style={
                  isNative
                    ? styles.childContainerStyleNative
                    : field?.id === 'audienceType' ||
                      field?.id === 'students' ||
                      field?.id === 'duration'
                      ? styles.firstChildStyle
                      : styles.childContainerStyleWeb
                }>
                {isWeb ? (
                  <Element
                    key={`attendance-history${field?.id}`}
                    field={field}
                    control={control}
                    errors={errors}
                    handleCustomSelect={handleCustomFunction}
                    dropdownWidth={350}
                    width={350}
                  />
                ) : (
                  <Element
                    key={`attendance-history${field?.id}`}
                    field={field}
                    control={control}
                    errors={errors}
                    handleCustomSelect={handleCustomFunction}
                  />
                )}
              </View>
            );
          } else {
            return <View key={`attendance-history${i}`} />;
          }
        })}
      {elements && elements.length > 0 && isNative && (
        <SecondaryBtn
          label={t('next.label')}
          onPress={onPressFunction}
        />
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  parentContainerWeb: {
    zIndex: 1,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  parentContainerNative: {
    flexDirection: 'column',
  },
  childContainerStyleWeb: {
    marginRight: 16,
    minWidth: 350,
  },
  firstChildStyle: {
    marginRight: 16,
    minWidth: 350,
    width: '100%',
  },
  childContainerStyleNative: {
    marginRight: 0,
  },
});