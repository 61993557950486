import React, { useState } from 'react';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { styled } from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { colors, fonts } from 'src/styles/theme/styles';
import Check from 'src/components/atoms/CheckBox/index.native';
import { TsubjectsData } from '../Helpers/helpers';
import { CollapseCaretIcon, ExpandCaretIcon } from 'src/components/atoms/ActionIcons';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
type Tprops = {
  checked: string[];
  setChecked: React.Dispatch<React.SetStateAction<string[]>>;
  resultData: TsubjectsData[];
  softCopyChecked: string[];
  setSoftCopyChecked: React.Dispatch<React.SetStateAction<string[]>>;
  handleSubmit: () => void;
  isLoading: boolean;
};
export default function RevaluationModal(props: Tprops) {
  const {
    checked,
    setChecked,
    resultData,
    softCopyChecked,
    setSoftCopyChecked,
    handleSubmit,
    isLoading,
  } = props;
  const [cellState, setCellState] = useState<any>({
    open: true,
    current: 0,
  });

  const { t } = useI18n();

  const handleCheckAll = () => {
    if (checked.length === 0) {
      let allUsers = [] as any;
      resultData?.forEach((item: any) => {
        allUsers.push(item?.subject?.id);
      });
      setChecked(allUsers);
    } else if (checked.length > 0 && checked.length) {
      let update = [...checked] as any;
      const res = resultData?.filter(x => !checked.includes(x.subject?.id));
      res.forEach((e: any) => {
        update.push(e?.subject?.id);
      });
      setChecked(update);
    } else {
      setChecked([]);
    }
  };

  const handleCheck = (item: string) => {
    const index = checked.findIndex(x => x === item);
    const updated = [...checked];
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push(item);
    }
    setChecked(updated);
  };

  const handleSoftCopyCheck = (item: string) => {
    const index = softCopyChecked.findIndex(x => x === item);
    const updated = [...softCopyChecked];
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push(item);
    }
    setSoftCopyChecked(updated);
  };
  return (
    <MarksReportWrapper>
      <TableWrapper>
        <HeaderFive value={t('marksReport.label')} color={colors.primaryText} />
        <TableHeadWrapper>
          <NormaltextAtom value={t('subject.label')} fontFamily={fonts.semibold} />
          <NormaltextAtom value={t('marks.label')} fontFamily={fonts.semibold} />
        </TableHeadWrapper>
        <TableBodyWrapper showsVerticalScrollIndicator={false}>
          {resultData && resultData.length ? (
            resultData.map((item: any, i: number) => (
              <>
                <TableRowWrapper>
                  <FirstCell>
                    <ActionIconWrapper>
                      <ActionIconDropdownWrapper
                        onPress={() =>
                          setCellState({
                            open: !cellState.open,
                            current: i === cellState?.current ? null : i,
                          })
                        }>
                        {cellState.current === i ? (
                          <CollapseCaretIcon disableClick={true} />
                        ) : (
                          <ExpandCaretIcon disableClick={true} />
                        )}
                      </ActionIconDropdownWrapper>
                      <Check
                        same={false}
                        onChange={() => handleCheck(item.subject.id)}
                        checked={checked.some(checkedItem => checkedItem === item.subject.id)}
                      />
                    </ActionIconWrapper>
                    <MediumTextSelect
                      value={item?.subject?.name || '-'}
                      color={colors.primaryText}
                      fontWeight={'normal'}
                      fontFamily={fonts.semibold}
                      lines={2}
                    />
                  </FirstCell>
                  <FirstCell>
                    <MediumTextSelect
                      value={`${item?.marksObtained} / ${item?.totalMarks}`}
                      color={item?.failedHeads ? colors.red : colors.primaryText}
                      fontWeight={'normal'}
                      fontFamily={fonts.semibold}
                    />
                  </FirstCell>
                </TableRowWrapper>
                <BorderWrapper />
                {cellState.current === i && (
                  <MarksWrapper>
                    <ActionWrapper>
                      <Check
                        same={false}
                        onChange={() => handleSoftCopyCheck(item.id)}
                        checked={softCopyChecked.some(checkedItem => checkedItem === item.id)}
                        disabled={!checked.some(checkedItem => checkedItem === item.subject.id)}
                      />
                    </ActionWrapper>
                    <MediumTextSelect
                      value={`Apply for PhotoCopy`}
                      fontWeight={'normal'}
                      fontFamily={fonts.semibold}
                    />
                  </MarksWrapper>
                )}
                <BorderWrapper />
              </>
            ))
          ) : (
            <MediumTextSelect
              value={t('noResultsFound.text')}
              style={styles.noRecordView}
              textAlign={'center'}
              fontFamily={fonts.semibold}
              fontWeight={'normal'}
              color={colors.primaryText}
            />
          )}
        </TableBodyWrapper>
      </TableWrapper>
      {checked.length > 0 && (
        <ButtonWrapper>
          <SecondaryBtn
            label="Proceed"
            onPress={handleSubmit}
            canLoad={isLoading}
            disabled={checked.length === 0}
          />
        </ButtonWrapper>
      )}
    </MarksReportWrapper>
  );
}

const styles = {
  noRecordView: {
    paddingTop: 16,
  },
};

const TableHeadWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 24px 10px 24px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const MarksReportWrapper = styled.View`
  margin-top: 24px;
  height: 100%;
`;

const TableBodyWrapper = styled.ScrollView`
  height: 100%;
`;

const TableRowWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 57px;
  align-items: center;
  column-gap: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const FirstCell = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  max-width: 70%;
`;

const ActionIconWrapper = styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 2px;
`;
const ActionIconDropdownWrapper = styled.TouchableOpacity`
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
`;
const ActionWrapper = styled.View`
  height: 24px;
  align-items: end;
  justify-content: center;
`;

const BorderWrapper = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const MarksWrapper = styled.View`
  display: flex;
  flex-direction: row;
  padding-left: 28px;
  max-height: 200px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.View`
margin-top:5px;
hegiht: '20%';
margin-bottom: 10px;
`;

const TableWrapper=styled.View`
 max-height: 80%;
`;
