import { gql, useLazyQuery, useMutation } from '@apollo/client';

const getKeyValuesQuery = gql`
  query ($language: String, $limit: Int, $skip: Int, $filters: LocalizationKeyValueFilterInput) {
    localizationKeyValues(language: $language, limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        id: key {
          name
        }
        key {
          id
          name
        }
        value
        defaultValue
        language {
          id
          name
        }
        defaultLanguage {
          name
        }
      }
    }
  }
`;

const getLanguageKeyValueLastUpdateAt = gql`
  query{
    getLanguageKeyValuesLastUpdatedAt
  }
`;

const updateLanguageKeyValuesUpdatedAt = gql`
mutation($id: String!){
	updateLanguageKeyValuesUpdatedAt(id: $id){
    status
  }
}
`;

const updateKeyValueMutation = gql`
  mutation ($payload: LocalizationKeyValueInput!) {
    updateLocalizationKeyValue(payload: $payload) {
      status
      message
    }
  }
`;

export { getKeyValuesQuery, updateKeyValueMutation, getLanguageKeyValueLastUpdateAt, updateLanguageKeyValuesUpdatedAt };

export function useQueryKeyValue() {
  const [query, { loading, data }] = useLazyQuery(getKeyValuesQuery, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });
  return { query, data, loading };
}

export function useMutateKeyValue() {
  const [mutate, { error }] = useMutation(updateKeyValueMutation);
  return { mutate, error };
}
