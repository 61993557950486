import CreateInfoItem, {
  CreateInfoItemInterface,
} from "../../TextComponents/InfoItem";
import { DT } from "src/constant/dateTime";
import DownloadFile from "src/components/atoms/DownloadFile/index.web";
import HeaderFive from "src/components/atoms/Text/HeaderFive";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import React from "react";
import { TPublicInvitee } from "./PublicInvitationsHelpers";
import { colors } from "src/styles/theme/styles";
import { format } from "date-fns";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";

type Props = {
  onClose: () => void;
  item: TPublicInvitee;
};

function PublicApplicantDetails(props: Props) {
  const { onClose, item } = props;
  const { t } = useI18n();
  const docs = Array.isArray(item?.documents) ? item.documents : null;
  const combinedInfo =
    item && item.address
      ? `${item.address.houseNo ?? ''} ${item.address?.street ?? ''} ${item.address.area ?? ''} ${
          item.address.city ?? ''
        } ${item.address.state ?? ''} ${item.address.zipcode ?? ''}`
      : '';

  return (
    <NormalModal
      setModalVisible={onClose}
      Headerpopup={t('applicantDetails.label')}
      modalVisible={true}
      handleSave={() => {}}
      showFooter={false}
      maxWidth={'md'}
      height={584}>
      <Container>
        <InfoItem label={t('fullName.label')} value={item.name ?? '-'} />
        <InfoItem label={t('email.label')} value={item?.email ?? '-'} />
        <InfoItem label={t('form.address.label')} value={combinedInfo ?? '-'} />
        <InfoItem label={t('form.contactNumber.label')} value={item?.mobileNumber ?? '-'} />
        {item?.dob && (
          <InfoItem
            label={t('form.dateOfBirth.label')}
            value={format(new Date(item.dob), DT.DATE_FORMAT_SLASH)}
          />
        )}
        <InfoItem label={t('subjectGroup.label')} value={item?.subjectGroup?.name ?? '-'} />
        <InfoItem
          label={t('submittedAt.label')}
          value={item?.date ? format(new Date(item.date), DT.DATE_TIME_FORMAT_12_HOUR) : '-'}
        />
        <InfoItem
          label={t('status.label')}
          value={
            item?.inviteSentAt
              ? `Invitation sent at ${format(
                  new Date(item.inviteSentAt),
                  DT.DATE_TIME_FORMAT_12_HOUR,
                )}`
              : 'Not invited yet'
          }
          color={item?.inviteSentAt ? colors.green : colors.yellow}
        />
        {item?.aadhaarBelongsTo && (
          <InfoItem label={'Relation'} value={item.aadhaarBelongsTo ?? '-'} />
        )}
        {item?.aadhaarId && (
          <InfoItem label={"Parent's Aadhaar Number"} value={item.aadhaarId ?? '-'} />
        )}
        {item?.siblingData?.class && (
          <InfoItem label={"Sibling's Class"} value={item.siblingData.class} />
        )}
        {item?.siblingData?.grNo && (
          <InfoItem label={"Sibling's G.R. Number"} value={item.siblingData.grNo} />
        )}
      </Container>

      {docs && (
        <>
          <DocumentsHeader>
            <HeaderFive value={t("documents.label")} />
          </DocumentsHeader>
          <DocumentsWrapper>
            {docs.map((fileData, index) => {
              return (
                <DocWrapper>
                  <DownloadFile
                    fileData={fileData}
                    key={`d${index}`}
                    maxChars={40}
                  />
                </DocWrapper>
              );
            })}
          </DocumentsWrapper>
        </>
      )}
    </NormalModal>
  );
}

const InfoItem = (props: CreateInfoItemInterface) => {
  return (
    <ItemWrapper>
      <CreateInfoItem width={"100%"} {...props} />
    </ItemWrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 8px;
  margin-bottom: 24px;
`;

const DocWrapper = styled.div`
  width: 50%;
`;

const DocumentsHeader = styled.div`
  margin: 16px 0 20px;
`;

const ItemWrapper = styled.div`
  width: 47.5%;
`;

export default PublicApplicantDetails;
