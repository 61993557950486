// Legacy Code: This code is not in active use. Mobile view is not currently supported. For use on mobile devices, we redirect to edba native app
import React from 'react';
import { Schedule } from 'src/components/molecules/Schedule';
import { PerformanceAnalysis } from 'src/components/molecules/PerformanceAnalysis';
import styled from 'styled-components';
import { MobileArrowToSeeMore } from 'src/components/molecules/SeeMoreArrow';
import { properCase } from 'src/utils/utility';
import { isMobile, width } from 'src/constant/device';
import { userInformation } from 'src/utils/manageState';
import { useI18n } from 'src/i18n/hooks';
import { useQuery } from '@apollo/client';
import { getAssignmentsQuery } from 'src/graphql/communication/assignments';
import { useNavigate } from 'src/routes/routing.web';

// Legacy Code: NOT IN ACTIVE USE
export function StudentDashboardMobile() {
  if (!isMobile) return null;
  const navigate = useNavigate();
  const currentUserObject = userInformation();
  const { t } = useI18n();

  const { data: pendingAssignmentData } = useQuery(getAssignmentsQuery);
  const pendingAssignments = pendingAssignmentData?.getAssignments?.data;

  const triggerAssignmentList = () => {
    navigate(`/assignments`);
  };

  return (
    <>
      <EmployeeHeaderText>
        {t('hello.label')}
        {properCase(currentUserObject?.userDetail?.fullName || '-')}!
      </EmployeeHeaderText>
      <MobileArrowToSeeMore
        onPress={triggerAssignmentList}
        text={
          pendingAssignments?.length ? `${pendingAssignments.length} assignments` : 'No assignments'
        }
      />
      <Schedule width={width} />
      <PerformanceAnalysis studentId={currentUserObject['userId']} />
    </>
  );
}

const EmployeeHeaderText = styled.div`
  font-size: ${(props) => props.theme.rem(2.4)};
  margin-top: 10px;
  margin-bottom: 24px;
  color: #666666;
  font-family: SFProRounded-Medium;
`;
