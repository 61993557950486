export const KEYS = {
  EMPLOYEE_ONBOARDING: 'employeeOnboardingStatus',
  STUDENT_ONBOARDING: 'studentOnboardingStatus',
  ACCESS_TOKEN: 'access_token',
  ONBOARDING_VISITED: 'onboarding_visited',
  USER_TYPE: 'user_type',
  REFRESH_TOKEN: 'refresh_token',
  USER_INFORMATION: 'edbaUserInformation',
  STEPPER_STEPS: 'stepperSteps',
  USER_INFO: 'userInfo',
  ASSIGNMENT_TOGGLE: 'assignmentToggle',
  SUBMIT_ASSIGNMENT_SWITCH: 'submit-assigned-switch',
  TRANSLATION_KEY_VALUE: 'translationKeyValue',
  TRANSLATION_KEY_VALUE_TS: 'translationKeyValuets',
  FCM_TOKEN_REGISTERED: 'fcm_token_registered',
  FCM_TOKEN: 'fcm_token',
  LANGUAGE: 'language',
  ASSIGNMENT_INDEX: 'assignmentIndex',
  ASSIGNMENT_SEARCH: 'assignmentSearch',
  ACCESS_TOKEN_LIST: 'access_token_list',
  ALLOWED_MODULES: 'allowed_modules',
  CURRENT_COURSE_IDX: 'current_course_idx',
  ACCOUNTS: 'accounts'
};
