export interface TestData {
  id: string;
  totalMarks: number;
  marksType:string;
}

export interface StudentMarks {
  test?: string;
  marksObtained?: number;
}

export const headCells1 = [
  {
    id: 'studentName',
    label: 'studentName.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: '250px' },
  },
  {
    id: 'rollNo',
    label: 'rollno.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'rollNo',
    isSorted: false,
    style: { minWidth: '125px' },
  },
];

export const marksActionHeadCells = [
  {
    id: 'average',
    label: 'average.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '200px' },
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '40px' },
  },
];
export const creditActionHeadCells = [
  {
    id: 'creditsEarned',
    label: 'credits-earned.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '200px' },
  },
  {
    id: 'creditGrade',
    label: 'credit-grade.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '225px', paddingRight: '150px' },
  },
  {
    id: 'grade',
    label: 'grade.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: '125px' },
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '40px' },
  },
];



export function generateRegexFromArray(array: string[]) {
  let regexString = ''; 
  if (array && array.length) {
    const escapedArray = array.map(str => str.replace(/[+\-.*?^${}()|[\]\\]/g, '\\$&'));
    regexString = `^((N(A|)|A(B|)|${escapedArray.join('|')})|$)$`;
  }
  return new RegExp(regexString);
}
