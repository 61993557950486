import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import {
  TGraceData,
  TGraceResolutionDropdownOption as TOption,
  resolutionHelpers,
} from './Helpers/Resolution';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import formJSON from '../../../../form-json/result/result.json';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useI18n } from 'src/i18n/hooks';
import { ResolutionObject } from 'src/components/organism/Assessment/Results/helpers';
import StudentDetailsModal from './StudentDetailsModal';

const { generateGraceByResolutionDropdownOptions: genDropdownOptions } = resolutionHelpers;

type Props = {
  selectedGrace: ResolutionObject | null;
  graceData: TGraceData[];
  setData: Dispatch<SetStateAction<ResolutionObject | null>>;
  examName: string;
  marksType: boolean;
};

type TFormData = {
  resolution: TOption;
};

const GenerateResolutionRadioSelectTable = ({ graceData, selectedGrace, examName, setData, marksType }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TFormData>();
  const { t } = useI18n();

  const [elements, setElements] = useState<any>({});

  const { fields } = elements ?? {};

  useEffect(() => {
    if (graceData) {
      const graceOptions = genDropdownOptions(graceData);
      // @ts-ignore
      formJSON[0].fields[0].option = graceOptions;
      setElements({ ...formJSON[0] });
    }
    return () => {
      formJSON[0].fields[0].option = [];
    };
  }, [graceData]);

  const onSubmit = ({ resolution }: TFormData) => {
    const graceResolutionData: ResolutionObject = {
      grace: resolution.grace,
      graceName: resolution.graceName,
      count: resolution.count,
      marksheets: resolution.marksheets,
    };
    setData(graceResolutionData);
  };

  return (
    <>
      {fields ? (
        <>
          <Wrapper>
            {fields.map((field: any, index: number) => (
              <InputWrapper key={index}>
                {/* @ts-ignore */}
                <Element
                  key={`key${index}`}
                  field={field}
                  control={control}
                  errors={errors}
                  autoCompleteProps={{
                    renderOption: (props: any, option: TOption) => {
                      return (
                        <OptionList {...props}>
                          <span>{option.label}</span>
                          <OptionValue>{`${option.count} ${t(
                            'studentsPassed.label',
                          )}`}</OptionValue>
                        </OptionList>
                      );
                    },
                  }}
                />
              </InputWrapper>
            ))}

            <SecondaryBtn
              onPress={handleSubmit(onSubmit)}
              label={'Show Students'}
              style={styles.btnStyle}
            />
          </Wrapper>

          <StudentDetailsModal
            tableData={selectedGrace?.marksheets}
            isDataAvailable={true}
            examName={examName}
            marksType={marksType}
          />
        </>
      ) : (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

const InputWrapper = styled.div`
  width: 60%;
  margin-right: 10px;
`;

const OptionList = styled.li`
  display: flex;
  justify-content: space-between !important;
  padding: 5px 10px;
  width: 100%;
`;

const OptionValue = styled.span`
  font-size: 10px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 568px; /* Adjust based on your needs */
`;

const styles: Record<string, StyleProp<ViewStyle>> = {
  btnStyle: { height: 40, marginTop: -4 },
};

export default GenerateResolutionRadioSelectTable;
