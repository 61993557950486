import React, { useEffect, useRef, useState } from 'react';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { height } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import navigationStrings from 'src/navigation/navigationStrings';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import LottieView from 'lottie-react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import ERROR_MSG from 'src/constant/error';
import { showNativeError } from 'src/components/molecules/NotificationWrapper';
import { properCase } from 'src/utils/utility';
import { useCreateCustomFeePayment } from 'src/graphql/payment';
import { getPaymentModeLabel } from 'src/components/molecules/Payment/Institute/utility';
import { PAYMENT_MODE_LABEL } from 'src/constant';

interface ResultType {
  customFeeCode: string;
  paymentDetail: {
    mode: string;
    payId?: string;
    payerBank?: string;
    paymentDate?: string;
  };
}

interface propType {
  navigation: any;
  route: {
    params: {
      feeBookData: {
        id: string;
        name: string;
        email: string;
        amount: number;
        customFeeName: string;
      };
      result: ResultType;
    };
  };
}

const AcceptOtherPayment = (props: propType) => {
  const { t } = useI18n();
  const { result, feeBookData } = props?.route?.params;
  const [completed, setCompleted] = useState<boolean>(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const ref = useRef<LottieView>(null);
  const { createCustomFeePayment } = useCreateCustomFeePayment();

  useEffect(() => {
    if (ref.current) {
      ref.current?.play();
    }
  }, [ref.current]);

  const makePayment = async () => {
    setCanClick(false);
    try {
      const payload = {
        code: result?.customFeeCode,
        paymentDetail: {
          mode: result?.paymentDetail?.mode,
        }
      };

      if (result?.paymentDetail?.payId) {
        payload.paymentDetail = result?.paymentDetail;
      }

      const response = await createCustomFeePayment({ variables: payload });
      if (!response.hasOwnProperty('errors') && response?.data?.createCustomFeePayment) {
        setCompleted(true);
      } else {
        showNativeError(ERROR_MSG.PAYMENT_UNSUCCESSFUL);
      }
      setCanClick(true);
    } catch (e: any) {
      showNativeError(ERROR_MSG.PAYMENT_UNSUCCESSFUL + ': ' + e.message);
      setCanClick(true);
    }
  };

  function handlePaymentSuccess() {
    setCompleted(false);
    props.navigation.navigate(navigationStrings.NATIVE_TABBAR_ADMIN);
  }

  const LabelValue = (props: { label: string; value: string | null }) => {
    const { label, value } = props;
    return (
      <LabelValueView>
        <SmallTextAtom value={t(label)} />
        <NormaltextAtom value={value || 'N/A'} color={colors.primaryText} />
      </LabelValueView>
    );
  };

  return (
    <Container>
      <MobileHeader
        label={t('paymentInfo.label')}
        backIcon={!completed}
        navigation={props.navigation}
        style={{ textAlign: 'center' }}
      />
      {!completed ? (
        result && feeBookData ? (
          <>
            <TableWrapper>
              <LabelValue label={'payeeName.label'} value={properCase(feeBookData?.name)} />
              <LabelValue label={'payeeEmail.label'} value={feeBookData?.email} />
              <LabelValue label={'feeName.label'} value={feeBookData?.customFeeName} />
              <LabelValue
                label={'feeAmount.label'}
                value={
                  feeBookData?.amount || feeBookData?.amount == 0
                    ? `\u20B9 ${feeBookData.amount}`
                    : null
                }
              />
              <LabelValue
                label={'paymentMode.label'}
                value={PAYMENT_MODE_LABEL[result?.paymentDetail?.mode]}
              />
              <LabelValue
                label={getPaymentModeLabel(result?.paymentDetail?.mode)}
                value={result?.paymentDetail?.payId ? result?.paymentDetail?.payId : null}
              />
            </TableWrapper>
            <BtnWrapper>
              <SecondaryBtn
                onPress={makePayment}
                label={t('payment.proceed-payment')}
                canLoad={!canClick}
              />
            </BtnWrapper>
          </>
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )
      ) : (
        <LoadingWrapper>
          <LottieWrapper>
            <LottieView
              ref={ref}
              source={require('src/assets/lottie/tickmark.json')}
              autoPlay={false}
              loop={false}
              style={{ height: 100 }}
            />
          </LottieWrapper>
          <TextWrapper>
            <MediumTextSelect
              value={t('paymentSuccessfull.label')}
              color={colors.primaryText}
              fontFamily={fonts.semibold}
            />
          </TextWrapper>
          <ButtonWrapper>
            <SecondaryBtn onPress={handlePaymentSuccess} label={t('done.label')} />
          </ButtonWrapper>
        </LoadingWrapper>
      )}
    </Container>
  );
};

export default AcceptOtherPayment;

const Container = styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

const TableWrapper = styled.SafeAreaView`
  flex: 1;
  margin-horizontal: 24px;
  margin-top: 24px;
`;

const BtnWrapper = styled.View`
  padding-bottom: 24px;
  padding-horizontal: 24px;
`;

const LoadingWrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  margin-top: -24px;
`;

const SpinnerView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  height: ${height - 300}px;
`;

const ButtonWrapper = styled.View`
  position: absolute;
  bottom: 24px;
  width: 100%;
`;

const TextWrapper = styled.View`
  position: absolute;
  top: 60%;
`;

const LabelValueView = styled.View`
  margin-bottom: 16px;
`;

const LottieWrapper = styled.View`
  height: 140px;
  width: 100%;
`;
