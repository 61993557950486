import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import React from 'react';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';

export default function TableSignatureCard() {
  return (
    <SignStampContainer>
      <SignStampView style={{ borderRight: 'none' }}>
        <MediumTextSelect value={"Class Teacher’s Signature"} color={colors.primaryText} />
      </SignStampView>
      <SignStampView style={{ borderRight: 'none' }}>
        <MediumTextSelect value={"Principal’s Signature"} color={colors.primaryText} />
      </SignStampView>
      <SignStampView>
        <MediumTextSelect value={"Institute Stamp"} color={colors.primaryText} />
      </SignStampView>
    </SignStampContainer>
  );
}

const SignStampContainer = styled.View`
  flex-direction: row;
 `;
const SignStampView = styled.View`
  height: 82px;
  width: 385.75px;
  padding-bottom: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid ${colors.deviderGrey};
 `;