import React from 'react';
import styled, { useTheme } from 'styled-components/native';

import { fonts } from 'src/styles/theme/styles';

interface IProps {
  value?: any;
  color?: string;
  font?: any;
  fontWeight?: number;
  children?: React.ReactNode;
}

const DateTextAtom = ({
  value,
  color = '#2b78ca',
  font = `${fonts.semibold}`,
  fontWeight = 600,
  children,
}: IProps) => {
  const { rem }: any = useTheme();

  const Text = styled.Text`
    font-size: ${rem(1.2)};
    color: ${color};
    font-family: ${font};
    font-weight: ${fontWeight};
    margin-bottom: ${rem(0.4)};
  `;
  return (
    <Text allowFontScaling={false}>
      {value && value}
      {children && children}
    </Text>
  );
};

export default DateTextAtom;
