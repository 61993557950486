import useTable from 'src/components/molecules/Table/UseTable';
import { NoRecordsFound, TableCells, TableRows } from 'src/components/molecules/Table/TableAtom';
import React from 'react';
import { TableBody } from '@mui/material';
import styled from 'styled-components';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { colors } from 'src/styles/theme/styles';
import Chip from 'src/components/atoms/Chip';
import { clipLongText } from 'src/utils/utility';
import { appliedAtktHeadcell,TappliedAtktStudents } from 'src/components/molecules/Assessment/Results/Helpers/helpers';
type Tprops = {
  appliedStudents: TappliedAtktStudents;
  isloading: boolean;
};
type Tsubjects = {
  subject: {
    name: string;
  };
};
export default function AtktAppliedStudent(props: Tprops) {
  const { appliedStudents, isloading } = props;
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    appliedStudents,
    appliedAtktHeadcell,
  );
  return (
    <Container>
      {!isloading ? (
        <TblContainer overflowY="scroll">
          <TblHead />
          <>
            {recordsAfterPagingAndSorting()?.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map((item: any) => (
                  <TableRows key={item?.id}>
                    <TableCells value={item?.user?.fullName ?? '-'} />
                    <TableCells style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                      {item?.subjects.map((i: Tsubjects) => (
                        <Chip label={clipLongText(i?.subject?.name, 35)} close={false} />
                      ))}
                    </TableCells>
                    <TableCells value={`\u20B9 ${item?.amountToBePaid}` ?? '-'} />
                    <TableCells
                      align={'right'}
                      value={item?.status ?? '-'}
                      color={colors.lightGreen}
                    />
                  </TableRows>
                ))}
              </TableBody>
            ) : (
              <NoRecordsFound colspan={6} maxHeight={50} />
            )}
          </>
        </TblContainer>
      ) : (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      )}
    </Container>
  );
}

const SpinnerWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  margin-bottom: 50px;
`;
