import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { colors, fontWeight } from 'src/styles/theme/styles';
import SmallTextAtom from '../Text/SmallTextAtom';

interface propsType {
  count: string;
  setStudent: () => void;
}

export default function More(props: propsType) {
  const { count, setStudent } = props;
  return (
    <TouchableOpacity
      activeOpacity={.7}
      style={[styles.container, { backgroundColor: colors.primaryColor }]}
      onPress={setStudent}>
      <SmallTextAtom value={count} color={colors.white} fontWeight={fontWeight[600]} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    borderColor: colors.lighterBorder,
    borderWidth: 1,
  },
});
