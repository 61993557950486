export const moduleOptions = [
  {
    label: 'Academic Detail',
    value: 'AcademicDetail',
  },
  {
    label: 'Academic Year',
    value: 'AcademicYear',
  },
  {
    label: 'Address Detail',
    value: 'AddressDetail',
  },
  {
    label: 'Admission Criteria',
    value: 'AdmissionCriteria',
  },
  {
    label: 'Admission Imported File',
    value: 'AdmissionImportedFile',
  },
  {
    label: 'Admission Link',
    value: 'AdmissionLink',
  },
  {
    label: 'Admission Round',
    value: 'AdmissionRound',
  },
  {
    label: 'Announcement',
    value: 'Announcement',
  },
  {
    label: 'Announcement Docs',
    value: 'AnnouncementDocs',
  },
  {
    label: 'Applicant',
    value: 'Applicant',
  },
  {
    label: 'Application',
    value: 'Application',
  },
  {
    label: 'Assignment',
    value: 'Assignment',
  },
  {
    label: 'Assignment Comments',
    value: 'AssignmentComments',
  },
  {
    label: 'Assignment Doc',
    value: 'AssignmentDoc',
  },
  {
    label: 'Assignment Submission',
    value: 'AssignmentSubmission',
  },
  {
    label: 'Assignment Submission Doc',
    value: 'AssignmentSubmissionDoc',
  },
  {
    label: 'Attendance',
    value: 'Attendance',
  },
  {
    label: 'Auth',
    value: 'Auth',
  },
  {
    label: 'Bank Detail',
    value: 'BankDetail',
  },
  {
    label: 'Batch',
    value: 'Batch',
  },
  {
    label: 'CAP Detail',
    value: 'CAPDetail',
  },
  {
    label: 'Caste Category',
    value: 'CasteCategory',
  },
  {
    label: 'Class',
    value: 'Class',
  },
  {
    label: 'Class Test Detail',
    value: 'ClassTestDetail',
  },
  {
    label: 'Class Test Mark',
    value: 'ClassTestMark',
  },
  {
    label: 'Comment',
    value: 'Comment',
  },
  {
    label: 'Course',
    value: 'Course',
  },
  {
    label: 'Details Draft',
    value: 'DetailsDraft',
  },
  {
    label: 'Document Type',
    value: 'DocumentType',
  },
  {
    label: 'EDBA Modules',
    value: 'EDBAModules',
  },
  {
    label: 'EDBA User',
    value: 'EDBAUser',
  },
  {
    label: 'Edba Message',
    value: 'EdbaMessage',
  },
  {
    label: 'Edba Notification',
    value: 'EdbaNotification',
  },
  {
    label: 'Education Detail',
    value: 'EducationDetail',
  },
  {
    label: 'Exam Detail',
    value: 'ExamDetail',
  },
  {
    label: 'Experience Detail',
    value: 'ExperienceDetail',
  },
  {
    label: 'FCM User Token',
    value: 'FCMUserToken',
  },
  {
    label: 'Fee Detail',
    value: 'FeeDetail',
  },
  {
    label: 'Grade',
    value: 'Grade',
  },
  {
    label: 'IE Schedule Custom Detail',
    value: 'IEScheduleCustomDetail',
  },
  {
    label: 'Institute Module Access',
    value: 'InsModuleAccess',
  },
  {
    label: 'Institute',
    value: 'Institute',
  },
  {
    label: 'Institute Employee',
    value: 'InstituteEmployee',
  },
  {
    label: 'Institute Employee Schedule',
    value: 'InstituteEmployeeSchedule',
  },
  {
    label: 'Institute Role',
    value: 'InstituteRole',
  },
  {
    label: 'Institute Student',
    value: 'InstituteStudent',
  },
  {
    label: 'Localization Institute Academic Details',
    value: 'LocalizationInstituteAcademicDetails',
  },
  {
    label: 'Localization Key Value',
    value: 'LocalizationKeyValue',
  },
  {
    label: 'Localization Language',
    value: 'LocalizationLanguage',
  },
  {
    label: 'NDPS Merchant Account',
    value: 'NDPSMerchantAccount',
  },
  {
    label: 'NTT Merchant Account',
    value: 'NTTMerchantAccount',
  },
  {
    label: 'Other Detail',
    value: 'OtherDetail',
  },
  {
    label: 'Parent Detail',
    value: 'ParentDetail',
  },
  {
    label: 'Payment',
    value: 'Payment',
  },
  {
    label: 'Personal Detail',
    value: 'PersonalDetail',
  },
  {
    label: 'Personal Document',
    value: 'PersonalDocument',
  },
  {
    label: 'Qualification Name',
    value: 'QualificationName',
  },
  {
    label: 'Reports File',
    value: 'ReportsFile',
  },
  {
    label: 'Reports Request',
    value: 'ReportsRequest',
  },
  {
    label: 'Reservations',
    value: 'Reservations',
  },
  {
    label: 'Stream',
    value: 'Stream',
  },
  {
    label: 'Student Group',
    value: 'StudentGroup',
  },
  {
    label: 'Subject',
    value: 'Subject',
  },
  {
    label: 'Subject Group',
    value: 'SubjectGroup',
  },
  {
    label: 'University',
    value: 'University',
  },
  {
    label: 'User Document',
    value: 'UserDocument',
  },
  {
    label: 'Verification',
    value: 'Verification',
  },
];

export const actionOptions = [
  {
    label: 'Create',
    value: 'CREATE',
  },
  {
    label: 'Delete',
    value: 'DELETE',
  },
  {
    label: 'Edit',
    value: 'EDIT',
  },
  {
    label: 'Login',
    value: 'LOGIN',
  },
  {
    label: 'Logout',
    value: 'LOGOUT',
  },
  {
    label: 'Permanent delete',
    value: 'PDELETE',
  },
];

export const userTypeOptions = [
  {
    label: 'Employee',
    value: 'INSTITUTE_EMPLOYEE',
  },
  {
    label: 'Student',
    value: 'INSTITUTE_STUDENT',
  },
];
