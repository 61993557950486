import { ImageList, ImageListItem, ImageListProps } from "@mui/material";

import { TFile as Data } from "src/utils/fileUtils";
import { Dimensions } from "src/constant/standardDimensions";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import React from "react";
import styled from "styled-components";
import { useFetchImage } from "src/utils/imageUtils";

type ImageGridProps = {
  data: Data[];
  containerDimensions?: Dimensions;
  imageDimensions?: Dimensions;
  cols?: ImageListProps["cols"];
  gap?: ImageListProps["gap"];
  rowHeight?: ImageListProps["rowHeight"];
  variant?: ImageListProps["variant"];
};

const ImageGrid = (props: ImageGridProps) => {
  const {
    data = [],
    containerDimensions = fallbackDimensions.container,
    imageDimensions = fallbackDimensions.image,
    cols = 4,
    gap = 4,
    rowHeight,
    variant = "standard",
  } = props;

  const adjustedRowHeight: ImageListProps["rowHeight"] =
    rowHeight ??
    (typeof imageDimensions.height === "number"
      ? imageDimensions.height + gap
      : "auto");

  const imageListStyle: ImageListProps["sx"] = {
    width: containerDimensions.width,
    height: containerDimensions.height,
    margin: 0,
  };

  return (
    <ImageList
      cols={cols}
      gap={gap}
      variant={variant}
      rowHeight={adjustedRowHeight}
      sx={imageListStyle}
    >
      {Array.isArray(data) &&
        data.map((item) => {
          const { imageSrc } = useFetchImage(item.uri);
          return (
            <Item
              key={item.id}
              onClick={(event) => handleImageClick(event, imageSrc)}
            >
              {imageSrc ? (
                <Image
                  src={imageSrc}
                  alt={item.name}
                  loading="lazy"
                  width={imageDimensions.width}
                  height={imageDimensions.height}
                />
              ) : (
                <LoaderSpinner />
              )}
            </Item>
          );
        })}
    </ImageList>
  );
};

export default ImageGrid;

// Opens the image in a new tab after it has completed loading.
const handleImageClick = (
  event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  imageSrc?: string
) => {
  const target = event.target as HTMLImageElement;
  if (!imageSrc || !target.complete) {
    return;
  }
  window.open(imageSrc);
};

const Item = styled(ImageListItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Image = styled.img`
  object-fit: cover;
  object-position: 50% 50%;
`;

const fallbackDimensions: Record<"container" | "image", Dimensions> = {
  container: {
    width: "100%",
    height: "100%",
  },
  image: {
    width: 160,
    height: 160,
  },
};
