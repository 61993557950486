import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import styled from 'styled-components/native';

import MediumText from 'src/components/atoms/Text/MediumText';
import { colors } from 'src/styles/theme/styles';

interface noDatatype {
  icon: any;
  label: any;
}

export function NoData(props: noDatatype) {
  const { label, icon } = props;
  return (
    <Container>
      <IconWrapper>
        <FontAwesomeIcon
          icon={icon}
          size={40}
          color={colors.tertiaryText}
          style={{ marginBottom: 5 }}
        />
      </IconWrapper>
      <TextContainer>
        <MediumText value={label} color={colors.secondaryText} />
      </TextContainer>
    </Container>
  );
}

const Container = styled.View`
  margin-top: 5%;
  margin-bottom: 3%;
`;

const IconWrapper = styled.View`
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.View`
  margin: 1.5% 0;
`;
