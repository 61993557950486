import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  KeyboardAvoidingView,
  StyleSheet,
  View,
  RefreshControl,
  Keyboard,
  PixelRatio,
} from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import CommentCard from 'src/components/atoms/CommentCard';
import Comments from 'src/components/atoms/Comment/index';
import MarksInputElement from 'src/components/atoms/Input/input';
import DownloadFile from 'src/components/atoms/DownloadFile/index';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import UserCard from 'src/components/atoms/UserCard';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { height, isIOS, isNative, isWeb, width } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts, fontWeight } from 'src/styles/theme/styles';
import { attachmentType } from '../types';
import {
  getAssignmentCommentQuery,
  getAssignmentSubmissionsQuery,
  useCreateAssignmentCommentMutation,
  useSetAssignmentGradeMutation,
} from 'src/graphql/communication/assignments';
import { useQuery } from '@apollo/client';
import { useParams } from 'src/routes/routing';
import styled from 'styled-components/native';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { regexObject } from 'src/constant/regex';
import NotificationWrapper, { showMessage } from 'src/components/molecules/NotificationWrapper';
import { UPDATE_SUCCESS } from 'src/constant/message';
import ERROR_MSG from 'src/constant/error';
import { ERROR, SUCCESS } from 'src/constant/index';
import AlertBox from 'src/components/molecules/Alert';
import AvoidKeyPad from 'src/components/atoms/Wrapper/AvoidKey';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';

interface gradePayload {
  marksObtained: number;
  submissionId: string;
}

interface teacherCommentPayload {
  assignment: string;
  comment: string;
  to: string;
}

export default function StudentDetails(props: any) {
  const { route, isUrlBase = true } = props;
  let submissionsDetails = {} as any;
  let assignment_Id: string = '';
  let student_Id: string = '';
  const { t } = useI18n();
  let submitted = false;
  const { createAssignmentComment } = useCreateAssignmentCommentMutation();
  const { setAssignmentGrade } = useSetAssignmentGradeMutation();
  const [refreshing, setRefreshing] = useState(false);

  const { alertState, setAlertDetails } = useAlertSystem();

  const invalidMarksError = t('marksValidation.text');
  const marksRequired = t('error.marks.required');

  if (isWeb) {
    if (!isUrlBase) {
      assignment_Id = props.assignment_Id;
      student_Id = props.studentID;
    } else {
      const { assignmentId, studentId } = useParams();
      assignment_Id = assignmentId;
      student_Id = studentId;
    }
  } else {
    const { assignmentId, studentId } = route?.params;
    assignment_Id = assignmentId;
    student_Id = studentId;
  }

  //call
  if (assignment_Id && student_Id) {
    let submissionFilter = getSubmissionFilter(student_Id, assignment_Id);
    var {
      data: submitAssignment,
      loading: detailsLoading,
      refetch: submissionRefetch,
    } = useQuery(getAssignmentSubmissionsQuery, {
      variables: submissionFilter,
    });
    if (submitAssignment?.getAssignmentSubmissions?.data[0]) {
      submissionsDetails = submitAssignment?.getAssignmentSubmissions?.data[0] || {};
      submitted = true;
    } else {
      submitted = false;
    }
  }

  const [comment, setComment] = useState('');
  const [marks, setMarks] = useState<any>(0);
  const [previousMarks, setPreviousMarks] = useState<any>(0);
  const [marksError, setMarksError] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: assignmentComment,
    loading: assignmentCommentLoading,
    refetch: assignmentCommentRefecth,
  } = useQuery(getAssignmentCommentQuery, {
    variables: {
      assignmentId: assignment_Id,
      to: student_Id,
    },
    fetchPolicy: 'network-only',
  });
  let assigneeInfo = assignmentComment?.getAssignmentComment?.assignee;
  const comments = assignmentComment?.getAssignmentComment?.comments || [];
  const attachments = submissionsDetails?.attachments || [];

  useEffect(() => {
    if (submissionsDetails && submissionsDetails.marksObtained) {
      setMarks(submissionsDetails.marksObtained);
      setPreviousMarks(submissionsDetails.marksObtained);
    }
  }, [submissionsDetails]);

  useEffect(() => {
    if (!assignmentCommentLoading) {
      assigneeInfo = assignmentComment?.getAssignmentComment?.assignee;
    }
  }, [assignmentCommentLoading]);

  useEffect(() => {
    clearAlert();
  }, []);

  function getSubmissionFilter(student_Id: string, assignment_Id: string) {
    let filter = { user: { eq: student_Id } };
    let variables = {
      assignmentId: assignment_Id,
      filters: filter,
    };
    return variables;
  }

  // to reset AlertBox
  const initial = {
    level: '',
    message: '',
  };
  function clearAlert() {
    setAlertDetails(initial);
  }

  async function setStudentsMarks() {
    setIsLoading(true);
    if (!marks) {
      setMarksError(marksRequired);
    } else {
      const result = onChangeMarks(marks);
      if (result.validated) {
        const payload: gradePayload = {
          marksObtained: parseFloat(marks), //need to change
          submissionId: submissionsDetails?.id,
        };

        try {
          let resp = await setAssignmentGrade({ variables: { input: payload } });
          setPreviousMarks(marks);
          if (isWeb) {
            setAlertDetails({ message: UPDATE_SUCCESS.MARKS, level: SUCCESS });
          } else {
            showMessage({
              message: UPDATE_SUCCESS.MARKS,
              type: 'success',
              position: 'top',
              icon: 'success',
            });
          }
        } catch (error) {
          if (isWeb) {
            setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
            console.log(error);
          } else {
            showMessage({
              message: ERROR_MSG.GENERIC_ERROR,
              type: 'danger',
              position: 'top',
              icon: 'danger',
            });
          }
        }
      }
    }
    setInterval(() => {
      setIsLoading(false);
    }, 500);
  }

  function postComment() {
    if (comment) {
      let commentPayload = {
        assignment: assignment_Id,
        comment: comment,
        to: student_Id,
      } as teacherCommentPayload;

      try {
        let response: any = createAssignmentComment({
          variables: { payload: commentPayload },
          refetchQueries: [
            {
              query: getAssignmentCommentQuery,
              variables: {
                assignmentId: assignment_Id,
                to: student_Id,
              },
            },
          ],
        });

        if (response) {
          Keyboard.dismiss();
          setComment('');
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  }

  function nativeRefresh() {
    assignmentCommentRefecth();
    submissionRefetch();
    setRefreshing(false);
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    nativeRefresh();
  }, []);

  const handleComments = (board: string) => {
    setComment(board);
  };

  const onChangeMarks = (mark: number) => {
    const reg = regexObject['INTGER_ONLY'];
    const result = reg.test(mark.toString());
    if (!result) {
      return { validated: false, allowTextChange: false };
    } else if (mark > submissionsDetails?.assignment.totalMarks) {
      setMarksError(invalidMarksError);
      return { validated: false, allowTextChange: true };
    } else {
      setMarksError(null);
      return { validated: true, allowTextChange: true };
    }
  };
  return (
    <NotificationWrapper>
      <Fragment>
        {isNative && <MobileHeader label={t('studentWork.label')} backIcon={true} {...props} />}
        <KeyboardAvoidingView behavior={'padding'} style={{ flex: 1 }}>
          {!detailsLoading && (
            <SafeAreaViewContainer>
              {/* Alert - success/error after updating grade */}
              {alertState?.message !== '' && (
                <AlertBox alert={alertState} clearAlert={clearAlert} />
              )}

              <ScrollViewContainer
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ flexGrow: 1 }}>
                <UserCardView>
                  <UserCard
                    studentDetail={assigneeInfo}
                    isSubmitted={true} // show title
                    asignLable={submitted ? 'Submitted' : 'Assigned'}
                    style={[styles.imgStyle]}
                    border={false}
                  />
                </UserCardView>

                {/* Attach files */}
                {attachments?.length > 0 && (
                  <AttachView>
                    {isWeb ? (
                      <HeaderSix value={t('attachments.label')} color={colors.primaryText} />
                    ) : (
                      <MediumTextSelect
                        value={'Attachments'}
                        color={colors.primaryText}
                        fontWeight={fontWeight[600]}
                        fontFamily={fonts.semibold}
                      />
                    )}
                  </AttachView>
                )}

                <DownloadContainer>
                  {attachments?.map((fileData: attachmentType, index: number) => (
                    <DownloadView key={`dwfile${index}`}>
                      <DownloadFile
                        fileData={fileData}
                        isBoxDownload={true}
                        alignElement={'space-between'} />
                    </DownloadView>
                  ))}
                </DownloadContainer>

                {/* Grade */}
                {submitted && (
                  <>
                    {isWeb && (
                      <View style={{ flexDirection: 'column', width: 350, marginTop: 24 }}>
                        <HeaderWrapper>
                          <HeaderSix value={t('grade.label')} color={colors.primaryText} />
                        </HeaderWrapper>
                        <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                          <MarksInputElement
                            setValue={(mark: any) => {
                              if (!mark.target.value) {
                                setMarksError(marksRequired);
                                setMarks(mark.target.value);
                              } else {
                                const result = onChangeMarks(mark.target.value);
                                if (result.allowTextChange) {
                                  setMarks(mark.target.value);
                                }
                              }
                            }}
                            label=""
                            value={marks}
                            header={t('form.totalMarks.label')}
                            typeInput={'number'}
                            width={100}
                          />
                          <View style={{ marginTop: 20, marginLeft: 8, marginRight: 24 }}>
                            <MediumTextSelect
                              value={'/ ' + submissionsDetails?.assignment.totalMarks.toString()}
                              fontWeight={'600'}
                              color={colors.primaryText}
                            />
                          </View>
                          <SecondaryBtn
                            style={{
                              marginTop: 20,
                              opacity:
                                marksError || marks.toString() === previousMarks.toString()
                                  ? 0.5
                                  : 1,
                            }}
                            secondary={true}
                            label={t('submit.label')}
                            onPress={
                              marksError || marks.toString() === previousMarks.toString()
                                ? null
                                : setStudentsMarks
                            }
                            width={80}
                            canLoad={isLoading}
                            lineHeight={2.4}
                            disabled={
                              marksError || marks.toString() === previousMarks.toString()
                                ? true
                                : false
                            }
                          />
                        </View>
                        {marksError && (
                          <View style={{ marginTop: 4 }}>
                            <SmallTextAtom value={marksError} color={colors.errorColor} />
                          </View>
                        )}
                      </View>
                    )}

                    {isNative && (
                      <>
                      <MediumTextSelect
                        value={t('grade.label')}
                        color={colors.primaryText}
                        fontWeight={fontWeight[600]}
                        fontFamily={fonts.semibold}
                        style={{ marginTop: 16, paddingHorizontal: 24 }}
                      />
                      <InputWrapper
                        style={{ marginTop: 8, marginBottom: 0, flexDirection: 'column' }}>
                        <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                          <View style={{ width: 100 }}>
                            <MarksInputElement
                              setValue={(mark: any) => {
                                if (!mark) {
                                  setMarks(mark);
                                  setMarksError(marksRequired);
                                  // } else if (mark.toString() === previousMarks.toString()) {
                                  //   setMarks(mark);
                                  //   setMarksError(marksRequired);
                                } else {
                                  const result = onChangeMarks(mark);
                                  if (result.allowTextChange) {
                                    setMarks(mark);
                                  }
                                }
                              }}
                              value={marks.toString()}
                              header={t('form.totalMarks.label')}
                              keyboardType={'number-pad'}
                            />
                          </View>
                          <View style={{ marginLeft: 16, marginRight: 24, marginTop: 22 }}>
                            <MediumTextSelect
                              value={'/' + submissionsDetails?.assignment.totalMarks.toString()}
                              fontWeight={fontWeight[600]}
                              fontFamily={fonts.semibold}
                              color={colors.primaryText}
                            />
                          </View>
                          <SecondaryBtn
                            style={{
                              borderRadius: 5,
                              opacity:
                                marksError || marks.toString() === previousMarks.toString()
                                  ? 0.5
                                  : 1,
                              marginTop: 'auto',
                              marginLeft: 'auto',
                              height: 48,
                            }}
                            secondary={true}
                            width={PixelRatio.get() > 2 ? (!isIOS ? 110 : 100) : 100}
                            label={t('submit.label')}
                            onPress={setStudentsMarks}
                            canLoad={isLoading}
                            lineHeight={2.4}
                            disabled={
                              marksError || marks.toString() === previousMarks.toString()
                                ? true
                                : false
                            }
                          />
                        </View>
                        {marksError && (
                          <View style={{ marginTop: 4 }}>
                            <SmallTextAtom value={marksError} color={colors.errorColor} />
                          </View>
                        )}
                      </InputWrapper>
                      </>
                    )}
                  </>
                )}

                {/* Head */}
                <HeadingViewWrapper>
                  {isWeb ? (
                    <HeaderSix value={t('comments.label')} color={colors.primaryText} />
                  ) : (
                    <MediumTextSelect
                      value={t('personalComments.label')}
                      color={colors.primaryText}
                      fontFamily={fonts.semibold}
                      fontWeight={fontWeight[600]}
                      style={{ marginVertical: 8 }}
                    />
                  )}
                </HeadingViewWrapper>

                {/* comments */}
                <>
                  {comments?.map((cmt: any, index: number) => (
                    <CommentView key={`cmt${index}`}>
                      <CommentCard
                        comment={cmt?.comment || ''}
                        time={cmt?.createdAt}
                        commentor={cmt?.from}
                      />
                    </CommentView>
                  ))}
                </>
                {/* input */}
                {!isNative && (
                  <View style={{ marginTop: comments.length > 0 ? 24 : 14 }}>
                    <Comments
                      setValue={handleComments}
                      value={comment}
                      header={t('addComment.text')}
                      postComment={postComment}
                      width={480}
                    />
                  </View>
                )}

                {isNative && (
                  <InputWrapper>
                    <Comments
                      setValue={(cmt) => setComment(cmt)}
                      value={comment}
                      header={t('leaveComment.text')}
                      postComment={postComment}
                    />
                  </InputWrapper>
                )}
              </ScrollViewContainer>
            </SafeAreaViewContainer>
          )}
        </KeyboardAvoidingView>
      </Fragment>
    </NotificationWrapper>
  );
}

const styles = StyleSheet.create({
  imgStyle: {
    height: 44,
    width: 44,
  },
});

const SafeAreaViewContainer = styled.SafeAreaView`
  background-color: ${colors.white};
  height: 100%;
`;

const ScrollViewContainer = styled.ScrollView`
  background-color: ${colors.white};
  margin-bottom: 1px;
`;

const UserCardView = styled.View`
  padding-top: ${isWeb ? '8px' : '24px'};
  padding-left: ${isWeb ? 0 : '18px'};
  padding-right: ${isWeb ? 0 : '24px'};
  margin-bottom: -8px;
`;

const AttachView = styled.View`
  margin-top: ${isWeb ? 32 : 24}px;
  margin-bottom: ${isWeb ? 16 : 8}px;
  padding-horizontal: ${isWeb ? 0 : '24px'};
`;

const DownloadContainer = styled.View`
  max-width: 100%;
`;

const DownloadView = styled.View`
  padding: 0 ${isWeb ? 0 : '24px'};
`;

const HeadingViewWrapper = styled.View`
  margin: ${isWeb ? '40px' : '24px'} ${isWeb ? 0 : '24px'}
    ${isNative ? (isIOS ? '10px' : '5px') : '2px'} ${isWeb ? 0 : '24px'};
`;

const CommentView = styled.View`
  margin: 0 ${isWeb ? 0 : '24px'} ${isNative ? (isIOS ? '8px' : '5px') : 0} ${isWeb ? 0 : '24px'};
`;

const InputWrapper = styled.View`
  width: 100%;
  background-color: ${colors.white};
  padding-left: ${isWeb ? 0 : '24px'};
  padding-right: ${isWeb ? 0 : '24px'};
  margin-bottom: 24px;
`;

const HeaderWrapper = styled.View`
  margin-bottom: 8px;
`;