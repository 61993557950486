import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { breakpoints, colors } from 'src/styles/theme/styles';
import { View, StyleSheet } from 'react-native';
import SmallTextAtom from '../../Text/SmallTextAtom';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components';


export default function InputPassword(props: any) {
  const { setValue, width, notefooter, header, placeholder, disablePaste = false } = props;
  const [p, setP] = React.useState('password');
  const { t } = useI18n();
  const handleDisablePaste = (e) => {
    // When disablePaste prop is passed as true
    // disablePaste={true}
    // Paste will be disabled on the specific Field
    if (disablePaste) {
      e.preventDefault();
    }
  };

  return (
    <View>
      {header && (
        <View style={styles.helperText}>
          <SmallTextAtom value={t(header)} />
        </View>
      )}
      <CustomTextField
        id="standard-basic"
        inputRef={props.inputRef}
        onChange={setValue}
        placeholder={placeholder}
        type={p}
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        onPaste={handleDisablePaste}
        sx= {{
          width: width ? width : null,
        }}
        InputProps={{
          endAdornment: [
            <InputAdornment position="end" key={1}>
              {p === 'password' ? (
                <FontAwesomeIcon
                  icon={faEyeSlash}
                  size={'sm'}
                  onClick={() => setP('text')}
                  color={colors.secondaryText}
                  style={{cursor: 'pointer'}}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faEye}
                  size={'sm'}
                  onClick={() => setP('password')}
                  color={colors.secondaryText}
                  style={{cursor: 'pointer'}}
                />
              )}
            </InputAdornment>,
          ],
        }}
      />
      {notefooter && (
        <View style={styles.bottomText}>
          <SmallTextAtom value={notefooter} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  helperText: {
    marginBottom: 4,
  },
  bottomText: {
    marginTop: 4,
  },
});

const CustomTextField = styled(TextField)`
  .MuiTextField-root {
     @media (max-width: ${breakpoints.md}) {
      width: 100%;
    }
  }
  .MuiOutlinedInput-root {
    & .MuiOutlinedInput-input {
      padding: 10px 10px;
      font-size: 16;
      color: ${colors.primaryText};
    }
    & fieldset {
      border-color: ${colors.tertiaryText};
    }
    &:hover fieldset {
      border-color: ${colors.secondaryText};
    }
    &.Mui-focused fieldset {
      border-color: ${colors.primary};
      border-width: 1;
    }
  }
`;
