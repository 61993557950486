import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import { TableDiv } from 'src/components/molecules/Table/TableAtom';
import { getUsersQuery, useImportEmployee, useInviteEmployee } from '../../../graphql/control-panels/user';
import {
  limit,
  AUTOCOMPLETE_MODULE,
  SEARCH_TIME,
  ERROR,
  SUCCESS,
  EMPLOYEE_TYPES,
  USER_TYPE_ENUM,
  USER_TYPE,
  BROADCAST_TYPE,
} from 'src/constant/index';
import { useHistory, useParams } from 'react-router';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useI18n } from 'src/i18n/hooks';
import { useLazyQuery } from '@apollo/client';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, debounce } from 'src/components/services';
import { getUserFilter } from 'src/components/services/filters';
import AssignSubjects from 'src/components/molecules/Employees/AssignSubjectForm';
import { useRemoveEmployeeDetail, useUpdateInstituteEmployee } from 'src/graphql/onboarding/employee';
import EmployeeTable from 'src/components/molecules/Table/EmployeeTable';
import { height } from 'src/constant/device';
import { ADD_SUCCESS, VERIFICATION_SUCCESS } from 'src/constant/message';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { DropdownOptionsType } from 'src/types';
import InviteUserForm from 'src/components/molecules/ControlPanel/InviteUserForm';
import { userInformation } from 'src/utils/manageState';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useTheme } from 'styled-components';
import { attachmentType } from '../Assignments/types';
import { colors } from 'src/styles/theme/styles';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { useSendInvite, useSendOnboardingReminder } from 'src/graphql/classes';
import MediumText from 'src/components/atoms/Text/MediumText';
import { Tooltip } from '@mui/material';
import ERROR_MSG from 'src/constant/error';
import styled from 'styled-components/native';
import Filter from 'src/components/molecules/Filter/index.web';
import { width } from 'src/constant/device';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { downloadFileRestAPI } from 'src/utils/utility';
import SplitButton from 'src/components/atoms/SplitButton/index.web';

interface InstituteAdminFormData {
  email: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  type: DropdownOptionsType;
  isAdmin: boolean;
}

export default function Employees() {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const styles = classesStyles(rem);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const [canClick, setCanClick] = useState(true);
  const {
    handleSubmit: inviteEmployeeSubmit,
    control: inviteEmployeeControl,
    formState: { errors: inviteEmployeeErrors },
    reset: inviteEmployeeReset,
  } = useForm();

  let row;
  let searchValue = '' as string;
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;
  const { page = 1, dataLimit = limit }: any = useParams();

  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  let history = useHistory();
  const { filters, setFilter } = useFilter();

  const { setHeading } = useHeaderTitle();
  const [employeeData, setEmployeeData] = useState<any>({});
  const [modalState, handleModal] = useState<boolean>(false);
  const [inviteModalState, handleInviteModal] = useState<boolean>(false);
  const [assignSubject, setAssignSubject] = useState<any>([]);
  const [assignNewSubjects, setAssignNewSubjects] = useState<any>([]);
  const [removeSubject, setRemoveSubject] = useState<any>([]);
  const [selectedBatch, setSelectedBatch] = useState<any>({});
  const [importModalState, handleImportModal] = useState<boolean>(false);
  const [importAttachment, handleImportAttachment] = useState<{
    documentName: String;
    document?: File | undefined;
  }>({ documentName: 'NA' });
  const [dropDown, setDropDown] = useState(false);
  const [clearCheckbox, setClearCheckbox] = useState(false);
  let { setAlertDetails } = useAlertSystem();
  const [filterHeight, setFilterHeight] = useState(0);
  if (filters && filters?.employees?.on) {
    searchValue = filters?.employees?.search;
  }
  const [searching, setSearchingEmployee] = useState(searchValue);
  const [batch, setBatch] = useState(null);
  const [showResendButton, setShowResendButton] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [classDetails, setClassDetails] = useState([]);
  const [activeFilters, setActiveFilters] = useState<any>({});
  const [recipientType, setRecipientType] = useState<string>('Selected');
  const [notActiveCount, setNotActiveCount] = useState<number | null>(null);
  const [getUsers, { data: userData, loading, refetch: refetchUsers }] = useLazyQuery(getUsersQuery, {
    fetchPolicy: 'network-only',
  });

  row = userData?.instituteEmployees?.data;
  const paginationHeightOffset = userIds.length === notActiveCount ? 50 : 10;
  const { updateInstituteEmployeeDetail } = useUpdateInstituteEmployee();
  const { removeEmployeeDetail } = useRemoveEmployeeDetail();
  const { inviteInstituteEmployee } = useInviteEmployee();
  const { employeeBulkInvite } = useImportEmployee();
  const { sendInvite } = useSendInvite();
  const { sendOnboardingReminder } = useSendOnboardingReminder();

  const [totalCount, setTotalCount] = useState<number|null>(userData?.instituteEmployees?.totalCount ?? null);
  
  useEffect(() => {
    const count = userData?.instituteEmployees?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [userData?.instituteEmployees?.totalCount]);

  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  const filterOptionsStatus = [
    { id: 1, label: 'includes', type: 'string', filteringCriteria: 'in' },
    { id: 2, label: 'not includes', type: 'string', filteringCriteria: 'nin' },
  ]
  const filterOptionsEmpType = [
    // uncomment and rectify id no./labels to add contains field in filter checkbox
    // { id: 1, label: 'contains', type: 'string', filteringCriteria: 'contains' },
    {
      // id: 2,
      id: 1,
      // label: 'contains any',
      label: 'has any value',
      type: 'string',
      filteringCriteria: 'containsAny'
    },
  ]

  // employee name completion localfield check todo
  const filterItems = [
    {
      id: 1,
      label: 'First Name',
      type: 'string',
      localField: 'firstName',
      filterType: 'filter',
    },
    {
      id: 2,
      label: 'Last Name',
      type: 'string',
      localField: 'lastName',
      filterType: 'filter',
    },
    {
      id: 3,
      label: 'Username',
      type: 'string',
      localField: 'userName',
      filterType: 'filter'
    },
    {
      id: 4,
      label: 'Profile Completion',
      type: 'number',
      localField: 'totalOnboardingCompletion',
      filterType: 'filter'
    },
    {
      id: 5,
      label: 'Status',
      type: 'string',
      localField: 'status',
      filterType: 'filter',
      dynamicCheckboxes: true,
      filterOptions: filterOptionsStatus,
      acceptableCheckboxValues: ["ACTIVE", "INACTIVE", "NOT_VERIFIED", "VERIFICATION_SENT"]
    },
    {
      id: 6,
      label: 'Employee Type',
      type: 'string',
      localField: 'type',
      filterType: 'filter',
      dynamicCheckboxes: true,
      filterOptions: filterOptionsEmpType,
      acceptableCheckboxValues: ["ADMIN", "MANAGEMENT", "NON_TEACHING", "TEACHING"]
    }
  ];

  useEffect(() => {
    setSearchingEmployee(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setTitle();
    return () => {
      setFilter(null);
    }
  }, []);

  function setTitle() {
    setHeading([
      {
        text: t('employees.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  useEffect(() => {
    setSubjects();
  }, [batch]);

  useEffect(() => {
    setClearCheckbox(true);
    if (recipientType === 'All') {
      setRecipientType('Selected');
    }
  }, [currentPage]);

  const setSubjects = () => {
    if (batch && classDetails?.length > 0) {
      let chipsArray = [];
      const chips = createBatchWiseObject(classDetails);
      if (chips.hasOwnProperty(batch)) {
        chipsArray = chips[batch];
      }
      setAssignSubject(chipsArray);
    }
  }

  function createQueryParameter() {
    const teachingFilter = {
      in: [EMPLOYEE_TYPES.TEACHING, EMPLOYEE_TYPES.NON_TEACHING, EMPLOYEE_TYPES.INSTITUTE_ADMIN],
    };
    let filters = getUserFilter(searchValue.trim());
    let userFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    if (userFilter.filters) {
      userFilter['filters']['type'] = teachingFilter;
    } else {
      userFilter['filters'] = {};
      userFilter['filters']['type'] = teachingFilter;
    }
    return userFilter;
  }

  const handleFilters = (filter: any) => {
    const userFilter = {
      limit: rowLimit,
      skip: (currentPage - 1) * rowLimit,
      ...filter,
    };
    setActiveFilters(userFilter);
    getUsers({ variables: userFilter });
  };

  function handleFetch() {
    if (Object.keys(activeFilters).length) {
      getUsers({
        variables: activeFilters,
      });
    } else {
      const userFilter = createQueryParameter();
      getUsers({ variables: userFilter });
    }
  }

  function handleRefetch() {
    if (Object.keys(activeFilters).length) {
      getUsers({
        variables: activeFilters,
      });
    } else {
      const userFilter = createQueryParameter();
      refetchUsers({ variables: userFilter });
    }
  }

  const broadcastMail = async (type: string) => {
    if (userIds.length > 0) {
      try {
        const payload = {
          userType: USER_TYPE_ENUM[1],
          type: BROADCAST_TYPE[type],
          recipient: recipientType,
          userIds: userIds,
        }
        const userFilter = createQueryParameter();
        const inviteResponse = await sendOnboardingReminder({
          variables: payload,
          refetchQueries: [
            {
              query: getUsersQuery,
              variables: userFilter,
            }
          ]
        });
        closeSelectAllSelection();
        if (inviteResponse?.data?.broadcastMail?.status === 'success') {
          setAlertDetails({ message: ADD_SUCCESS.SEND_REMINDER, level: SUCCESS })
        } else {
          setAlertDetails({
            message: type === 'reminder' ? ERROR_MSG.ACCOUNT_NOT_ACTIVE : ERROR_MSG.ACCOUNT_ALREADY_ACTIVE,
            level: ERROR
          })
        }
      } catch (e) {
        setAlertDetails({ message: e.message, level: ERROR })
      }
    }
  }

  const sendInviteEmployee = async (userId: any) => {
    try {
      const userFilter = createQueryParameter();
      const inviteResponse = await sendInvite({
        variables: { invite: { userId, userType: USER_TYPE_ENUM[1] } },
        refetchQueries: [
          {
            query: getUsersQuery,
            variables: userFilter,
          }
        ]
      });
      if (inviteResponse?.data) {
        setAlertDetails({ message: VERIFICATION_SUCCESS.VERIFICATION_SENT_SUCCESS, level: SUCCESS });
      }
    } catch (e) {
      setAlertDetails({ message: e?.message, level: ERROR });
    }
  };

  const closeModal = () => {
    handleModal(false);
    handleInviteModal(false);
    reset({});
    hideAlert();
    inviteEmployeeReset({});
    setAssignSubject([]);
    setAssignNewSubjects([]);
    setRemoveSubject([]);
    setCanClick(true);
    setBatch(null);
    setClassDetails([]);
  };

  const editUser = (employeeId: string) => {
    history.push(`/employee/${employeeId}/0/edit`);
  };

  async function assignSubjects() {
    setCanClick(false);
    if (assignSubject.length > 0) {
      const employeeId = employeeData?.id;

      try {
        const userFilter = createQueryParameter();
        let assignedSubjects, removeSubjects;
        if (assignNewSubjects && assignNewSubjects.length > 0) {
          const addClassDetails = createPayload(assignNewSubjects);
          const classDetailPayload = {
            id: employeeId,
            classDetails: addClassDetails,
          };
          assignedSubjects = await updateInstituteEmployeeDetail({
            variables: { payload: classDetailPayload },
            refetchQueries: [{ query: getUsersQuery, variables: userFilter }],
          });
        }
        if (removeSubject && removeSubject.length > 0) {
          const removeClassDetails = createPayload(removeSubject);
          const classDetailRemove = {
            id: employeeId,
            classDetails: removeClassDetails,
          };
          removeSubjects = await removeEmployeeDetail({
            variables: { remove: classDetailRemove },
            refetchQueries: [{ query: getUsersQuery, variables: userFilter }],
          });
        }
        if (assignedSubjects || removeSubjects) {
          closeModal();
          setAlertDetails({ message: ADD_SUCCESS.ASSIGN_SUBJECT, level: SUCCESS });
        } else {
          closeModal();
        }
      } catch (error) {
        setAlertDetails({ message: error.message, level: ERROR });
      }
    } else {
      setAlertDetails({ message: t('error.subject.required'), level: ERROR });
    }
    setCanClick(true);
  }

  function createPayload(subjects: any[]) {
    const assignArray = [...subjects];
    const payload = new Set<String>();
    assignArray.forEach(function (assign: any) {
      payload.add(
        `${assign?.batch?.value}/${assign?.batch?.class.id}/${assign?.division}/${assign?.subject}`,
      );
    });
    return Array.from(payload);
  }

  function createBatchWiseObject(assignData: any) {
    let class_subject = {} as any;
    assignData?.forEach(function (bk: any, bkid: number) {
      let chips = [] as any;
      let divisionDetails = bk?.divisionDetails;
      let txt = '';

      divisionDetails?.forEach(function (dd: any) {
        dd.subjects?.forEach(function (sb: any) {
          txt = `${bk?.class?.alias} : ${dd.division} : ${sb?.alias}`;
          const chipObject = {
            text: txt,
            class: bk?.class?.id,
            division: dd.division,
            batch: bk?.batch,
            subject: sb.id,
          };
          chips.push(chipObject);
        });
      });

      if (class_subject[bk?.batch?.alias]) {
        let old = class_subject[bk?.batch?.alias];
        let concatedArray = old.concat(chips);
        class_subject[bk?.batch?.alias] = concatedArray;
      } else {
        class_subject[bk?.batch?.alias] = chips;
      }
    });

    return class_subject;
  }

  const closeImportModal = () => {
    handleImportModal(false);
    handleImportAttachment({
      document: undefined,
      documentName: 'NA',
    });
  };

  const importEmployee = async () => {
    setCanClick(false);
    try {
      const userFilter = createQueryParameter();
      let inviteResponse = await employeeBulkInvite({
        variables: { file: importAttachment.document },
        refetchQueries: [
          {
            query: getUsersQuery,
            variables: userFilter,
          }
        ]
      });

      if (inviteResponse?.data?.employeeBulkInvite) {
        const { sent, total, notSent, errors } = inviteResponse?.data?.employeeBulkInvite;
        let level = SUCCESS;
        let message = `Imported, email sent: ${sent.length}, email not sent: ${notSent.length}`;
        let expireInMs = 6000;

        if (errors && errors.length > 0) {
          message += ', ';
          message += errors.join(', ');
          level = ERROR;
          expireInMs = 15000;
        }
        setAlertDetails({ message, level, expireInMs });
        setCanClick(true);
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR, expireInMs: 15000 });
      setCanClick(true);
    }
    closeImportModal();
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.EMPLOYEES]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchEmployees(employee: string) {
    let isClear = employee?.length === 0;
    persistSearch(!isClear, employee);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchEmployees(q), SEARCH_TIME),
    [],
  );
  const handleSearch = (employee: string) => {
    setSearchingEmployee(employee);
    delayedQuery(employee);
  };

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      } as attachmentType;
      handleImportAttachment(fileObject);
      handleImportModal(true);
      e.target.value = null;
    }
  };

  function setCurrentSubject(employeeData: any, chips: any) {
    setEmployeeData(employeeData);
    setAssignSubject(chips);
  }

  const handleEmployeeView = (employeeId: any) => {
    history.push(`/employee/${employeeId}/0/view`);
  };

  const handleEmployeeEdit = (rowData: any) => {
    editUser(rowData?.id);
  };

  function openModal() {
    hideAlert();
    handleModal(true);
  }

  const handlesingleBatch = (rowData: any) => {
    let chipsArray = [];
    let assignData = { ...rowData };
    //parent
    const chips = createBatchWiseObject(rowData?.classDetails);
    if (rowData?.classDetails?.length > 0) {
      assignData['batch'] = rowData?.classDetails?.[0]?.batch;
      chipsArray = chips[rowData?.classDetails?.[0]?.batch?.alias];
    }
    setCurrentSubject(assignData, chipsArray);
    openModal();
  };

  const handleMultipleBatch = (nestedRow: any) => {
    //child
    setClassDetails(nestedRow?.classDetailsObj);
    const chipsArray = [] as any;
    openModal();
    setCurrentSubject(nestedRow, chipsArray);
  };

  const inviteEmployeeModal = () => {
    handleInviteModal(true);
  };

  const inviteUser = async (employeeData: InstituteAdminFormData) => {
    setCanClick(false);
    let filters = getUserFilter(searchValue);
    let userFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);
    const teachingFilter = {
      in: [EMPLOYEE_TYPES.TEACHING, EMPLOYEE_TYPES.NON_TEACHING],
    };
    if (userFilter.filters) {
      userFilter['filters']['type'] = teachingFilter;
    } else {
      userFilter['filters'] = {};
      userFilter['filters']['type'] = teachingFilter;
    }

    let inviteUserData = {
      email: employeeData?.email,
      employeeId: employeeData?.employeeId,
      firstName: employeeData?.firstName,
      lastName: employeeData?.lastName,
      types: [employeeData?.type.value],
      isAdmin: false,
    };

    try {
      let inviteResponse = await inviteInstituteEmployee({
        variables: { payload: inviteUserData },
        refetchQueries: [{ query: getUsersQuery, variables: userFilter }],
      });

      if (inviteResponse?.data?.inviteEmployee) {
        closeModal();
        handleRefetch();
        setAlertDetails({ message: ADD_SUCCESS.INVITE_USER, level: SUCCESS });
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
    setCanClick(true);
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  function closeSelectAllSelection() {
    setClearCheckbox(true);
    setRecipientType('Selected');
  }

  function handleSelectAllSelection() {
    if (recipientType === 'Selected') {
      setRecipientType('All');
    } else {
      setRecipientType('Selected');
      setClearCheckbox(true);
    }
  }

  function exportReport(url: string, fileName: string) {
    downloadFileRestAPI(url, fileName, (err) => {
      setAlertDetails({ message: err.message || 'Failed to download', level: ERROR });
    });
    setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
  }

  const handleOnClick = () => {
    setDropDown(false);
  };

  const handleOnArrowClick = () => {
    if (!showResendButton) {
      setDropDown(!dropDown);
    }
  };

  return (
    <>
      <LoadContentWrapper>
        <View style={styles.flexRow}>
          <View style={styles.leftPart}>
            <Search
              id="empSearch"
              value={searching}
              handleChange={handleSearch}
              label={t('searchEmployees.label')}
            />
            <FilterWrapper
              filterHeight={filterHeight}
              isEdbaAdmin={userType === USER_TYPE.EDBA_ADMIN}>
              <Filter
                filterItems={filterItems}
                handleFilters={handleFilters}
                setFilterHeight={setFilterHeight}
                searchValue={searchValue}
              />
            </FilterWrapper>
          </View>
          <View style={styles.rightPart}>
            {showResendButton ? (
              <View style={styles.rightPart}>
                <View style={styles.rightPadding16}>
                  <Tooltip title="Send reminder for completing profile details">
                    <OutlineButton
                      label={t('sendReminder.text')}
                      onPress={() => broadcastMail('reminder')}
                      style={{ height: 32 }}
                    />
                  </Tooltip>
                </View>
                <View style={styles.rightPadding16}>
                  <Tooltip title="Send verification invite to inactive users">
                    <SecondaryBtn
                      label={t('verifyInvite.text')}
                      onPress={() => broadcastMail('verification')}
                      style={{ height: 32 }}
                    />
                  </Tooltip>
                </View>
              </View>
            ) : (
              <>
                <OutlineButton
                  onPress={() => exportReport('employees/export', 'Employees.xlsx')}
                  style={[styles.marginR16, styles.height32]}>
                  <NormaltextAtom value={t('downloadEmployees.label')} color={colors.primary} />
                </OutlineButton>
                <SplitButton
                  buttonData={{
                    value: t('uploadEmployees.label'),
                    onClick: handleOnClick,
                    onArrowClick: handleOnArrowClick,
                    onChange: handleFileUpload,
                  }}
                  component="label"
                />

                {dropDown && (
                  <InviteButton
                    onPress={() => {
                      setDropDown(false);
                      inviteEmployeeModal();
                    }}>
                    <MediumText value={t('inviteEmployee.text')} color={colors.primary} />
                  </InviteButton>
                )}
              </>
            )}
          </View>
        </View>
        {userIds.length === notActiveCount && (
          <SelectAllWrapper>
            <NormaltextAtom
              value={
                recipientType == 'Selected'
                  ? t('employeesSelected.text', { values: { employeesCount: notActiveCount } })
                  : t('allEmployeesSelected.text')
              }
              color={colors.primaryText}
            />
            <SelectAllText>
              <OutlineButton
                onPress={handleSelectAllSelection}
                label={recipientType == 'Selected' ? t('selectAll.text') : t('clearSelection.text')}
                height={24}
              />
            </SelectAllText>
          </SelectAllWrapper>
        )}
        <View style={{ zIndex: -1 }}>
          <TableDiv>
            <div style={{ height: height - 280 - paginationHeightOffset, overflowX: 'auto' }}>
              <EmployeeTable
                data={row}
                loading={loading}
                tableHeight={height - 335}
                createBatchWiseObject={createBatchWiseObject}
                handleParentAdd={handlesingleBatch}
                handleParentEdit={handleEmployeeEdit}
                handleChildAdd={handleMultipleBatch}
                handleParenView={handleEmployeeView}
                sendInviteEmployee={sendInviteEmployee}
                setShowResendButton={setShowResendButton}
                setUserIds={setUserIds}
                clearCheckbox={clearCheckbox}
                setClearCheckbox={setClearCheckbox}
                handleFetch={handleFetch}
                handleRefetch={handleRefetch}
                setRecipientType={setRecipientType}
                notActiveCount={notActiveCount}
                setNotActiveCount={setNotActiveCount}
              />
            </div>
          </TableDiv>
          <Pagination
            pathName={'employees'}
            total={totalCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            rowLimit={rowLimit}
            setRowLimit={setRowLimit}
            hidePagination={!!(totalCount && totalCount <= rowLimit)}
          />
        </View>
      </LoadContentWrapper>

      <NormalModal
        isSubmitting={!canClick}
        onAlert={null}
        setModalVisible={closeModal}
        Headerpopup={t('assignSubjectsClasses.text')}
        modalVisible={modalState}
        handleSave={assignSubject.length > 0 ? assignSubjects : handleSubmit(assignSubjects)}
        disabledSave={assignSubject.length < 1}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="assign.label"
        width={600}
        maxWidth={'lg'}
        height={766}>
        <AssignSubjects
          closeModal={closeModal}
          control={control}
          errors={errors}
          employeeData={employeeData}
          setValue={setValue}
          getValues={getValues}
          assignSubject={assignSubject}
          setAssignSubject={setAssignSubject}
          assignNewSubjects={assignNewSubjects}
          setAssignNewSubjects={setAssignNewSubjects}
          removeSubject={removeSubject}
          setRemoveSubject={setRemoveSubject}
          batchSelected={setSelectedBatch}
          handleSubmit={handleSubmit}
          reset={reset}
          setBatch={setBatch}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        onAlert={null}
        setModalVisible={closeImportModal}
        Headerpopup={t('inviteUser.text')}
        modalVisible={importModalState}
        handleSave={handleSubmit(importEmployee)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={'importUsers.text'}>
        <View style={{ paddingBottom: 24 }}>
          <NormalTextSelect fontsize={1.8} value={importAttachment.documentName} />
        </View>
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        Headerpopup={t('inviteEmployee.text')}
        width={578}
        height={420}
        modalVisible={inviteModalState}
        onAlert={null}
        handleSave={inviteEmployeeSubmit(inviteUser)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="invite.label">
        <InviteUserForm
          closeModal={closeModal}
          control={inviteEmployeeControl}
          errors={inviteEmployeeErrors}
          onlyAdmin={false}
          userType={userType}
        />
      </NormalModal>
    </>
  );
}

const classesStyles = (rem) =>
  StyleSheet.create({
    flexRow: {
      flexDirection: 'row',
      height: 42,
    },
    leftPart: {
      flexDirection: 'row',
      flex: 1,
      marginRight: 5,
      alignItems: 'flex-start',
    },
    rightPart: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    marginR16: {
      marginRight: rem(1.8),
    },
    marginTop24: {
      marginTop: 24,
    },
    tableAction: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    marginTop4: {
      marginTop: 4,
    },
    rightPadding16: {
      paddingRight: '16px',
    },
    iconView: { marginRight: 13 },
    height32: {
      height: 32
    }
  });

const FilterWrapper = styled.View`
  margin-left: 8px;
  left: 0;
  flex: 1;
  align-items: flex-start;
  height: ${(props) => props.filterHeight * 32}px;
  width: ${(props) => (props.isEdbaAdmin ? (width > 1700 ? 75 : 72) : width > 1700 ? 80 : 80)}%;
`;

const SelectAllWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 32px;
  width: 100%;
  height: 40px;
  border: 1px solid ${colors.noteBlue};
  border-radius: 4px;
  background-color: ${colors.noteBg};
  z-index: 2;
`;

const SelectAllText = styled.View`
  margin-left: 4px;
`;

const InviteButton = styled.TouchableOpacity`
  border-width: 1px;
  border-radius: 4px;
  padding: 14px 36px 14px 16px;
  justify-content: center;
  margin-right: 6px;
  position: absolute;
  margin-top: 32px;
  background-color: ${colors.white};
  border-color: ${colors.white};
  box-shadow: 0px 0px 4px ${colors.searchShadow};
  align-self: flex-start;
`;
