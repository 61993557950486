import { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components';
import React from 'react';
import { useI18n } from 'src/i18n/hooks';

export const ImageContainer = (props: UseFieldApiConfig) => {
  const {
    fields
  } = props
  const { renderForm, schema } = useFormApi();

  const { t } = useI18n();
  return (
    <RightSection>
      {fields.map((field) => (
        <>
          {renderForm([field])}
        </>
      ))}
    </RightSection>
  )
}

/*Styled - components*/

const RightSection = styled.div`
  margin-top: 80px;
  width: 30%;
  height: 280px;
  order: 2;
  @media all and (max-width: 991px) {
    width: 100%;
    order: 1;
    margin-top: 0;
    margin-bottom: 30px;
  }
`;