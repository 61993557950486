import { CollapseCaretIcon, ExpandCaretIcon } from "../ActionIcons";

import React from "react";
import styled from "styled-components";

type Props = {
  disabled: boolean;
  expanded: boolean;
  onChangeCallback: (currentValue: boolean) => void;
};

export const FormExpandCollapse = (props: Props) => {
  const { disabled, expanded, onChangeCallback } = props;

  return (
    <Container>
      <IconWrapper>
        {expanded ? (
          <CollapseCaretIcon
            size={24}
            disabled={disabled}
            onPress={() => onChangeCallback(false)}
          />
        ) : (
          <ExpandCaretIcon
            size={24}
            disabled={disabled}
            onPress={() => onChangeCallback(true)}
          />
        )}
      </IconWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

const IconWrapper = styled.div`
  padding-right: 4px;
`;
