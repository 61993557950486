import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

import { colors, fontWeight } from 'src/styles/theme/styles';

export default function LargeButtonText(props: any) {
  const {
    value = '',
    color = colors.secondaryText,
    fontWeight = 'normal',
    lineHeight = 1.7,
    textAlign,
    fontSize
  } = props;

  const TextWrapper = styled.Text`
    font-size: ${(props) => fontSize ? props.theme.rem(fontSize) : props.theme.rem(1.8)};
    line-height: ${(props) => props.theme.rem(lineHeight)};
  `;

  return (
    <TextWrapper style={{ color: color, fontWeight: fontWeight, textAlign: textAlign && textAlign }} allowFontScaling={false}>
      {value}
    </TextWrapper>
  );
}

LargeButtonText.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
  lineHeight: PropTypes.number,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.oneOf(Object.values(fontWeight)),
};
