import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 24 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M12.75 4.74805C12.75 4.33242 12.4156 3.99805 12 3.99805C11.5844 3.99805 11.25 4.33242 11.25 4.74805V13.1887L8.28125 10.2199C7.9875 9.92617 7.5125 9.92617 7.22187 10.2199C6.93125 10.5137 6.92812 10.9887 7.22187 11.2793L11.4688 15.5293C11.7625 15.823 12.2375 15.823 12.5281 15.5293L16.7812 11.2793C17.075 10.9855 17.075 10.5105 16.7812 10.2199C16.4875 9.9293 16.0125 9.92617 15.7219 10.2199L12.7531 13.1887V4.74805H12.75ZM8.025 13.498H6C4.89688 13.498 4 14.3949 4 15.498V17.998C4 19.1012 4.89688 19.998 6 19.998H18C19.1031 19.998 20 19.1012 20 17.998V15.498C20 14.3949 19.1031 13.498 18 13.498H15.975L14.475 14.998H18C18.275 14.998 18.5 15.223 18.5 15.498V17.998C18.5 18.273 18.275 18.498 18 18.498H6C5.725 18.498 5.5 18.273 5.5 17.998V15.498C5.5 15.223 5.725 14.998 6 14.998H9.525L8.025 13.498ZM17.5 16.748C17.5 16.5491 17.421 16.3584 17.2803 16.2177C17.1397 16.0771 16.9489 15.998 16.75 15.998C16.5511 15.998 16.3603 16.0771 16.2197 16.2177C16.079 16.3584 16 16.5491 16 16.748C16 16.947 16.079 17.1377 16.2197 17.2784C16.3603 17.419 16.5511 17.498 16.75 17.498C16.9489 17.498 17.1397 17.419 17.2803 17.2784C17.421 17.1377 17.5 16.947 17.5 16.748Z"
        fill="black"
        fill-opacity="0.6"
      />
    </Svg>
  );
}

export default SvgComponent;
