import { BaseSidebarManifest, ChildSidebarManifest } from "./SidebarManifest";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import {
  CurrentHoverState,
  DrawerComponentProps,
  DrawerState,
} from "./DrawerTypes";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { SidebarState, useSidebarSystem } from "src/contexts/sidebar-context";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
  UserInformation,
  allowedModulesState,
  userInformation,
} from "src/utils/manageState";
import {
  bindHover,
  bindPopover,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { colors, fonts } from "src/styles/theme/styles";
import { faCaretDown, faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import {
  getAllowedModules,
  getStorageFunction,
  setStorageFunction,
} from "src/components/services/storage-service";

import { Collapse } from "@mui/material";
import Divider from "@mui/material/Divider";
import { EDBALogo } from "src/assets/common/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { Icon } from "src/components/atoms/Icon/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiDrawer from "@mui/material/Drawer";
import { UserType } from "src/constant";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { faUniversity } from "@fortawesome/pro-light-svg-icons";
import filterRoutes from "./FilterRoutes";
import { height } from "src/constant/device";
import { useHistory } from "src/routes/routing.web";
import { useI18n } from "src/i18n/hooks";
import { useTheme } from "styled-components";

const drawerWidth = 280;

export default function DrawerWeb() {
  const { rem }: any = useTheme();
  const { t } = useI18n();
  const history = useHistory();
  const { toggleSidebar, togglevalue } = useSidebarSystem();
  const toggleState = togglevalue.toggle;
  const allowedModules = allowedModulesState();
  const userInfo: UserInformation = userInformation();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "drawerWebPopover",
  });

  const contentRef = useRef<HTMLDivElement>(null);

  const [currentlyAllowedModules, setCurrentlyAllowedModules] =
    useState<string[]>();
  const [drawerHoverActive, setDrawerHoverActive] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [activePopoverItem, setActivePopoverItem] =
    useState<BaseSidebarManifest | null>(null);
  const [currentHover, setCurrentHover] = useState<CurrentHoverState | null>(
    null
  );
  const [filteredRoutes, setFilteredRoutes] = useState<BaseSidebarManifest[]>();
  const [storageHeaderID, setStorageHeaderID] = useState<string>();

  const popoverOpen = Boolean(anchorEl);

  const showInstituteName: boolean =
    userInfo &&
    Object.keys(userInfo)?.length > 0 &&
    [UserType.INSTITUTE_STUDENT, UserType.INSTITUTE_EMPLOYEE].includes(
      userInfo?.userType
    );

  useMemo(() => {
    if (userInfo && Object.keys(userInfo).length && currentlyAllowedModules) {
      setFilteredRoutes(filterRoutes(userInfo, currentlyAllowedModules));
    }
  }, [userInfo, currentlyAllowedModules]);

  useEffect(() => {
    clearStorageHeader();
    getHeading();
  }, []);

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      setIsOverflowing(element.scrollHeight > height);
    }
  }, [drawerHoverActive]);

  useEffect(() => {
    if (allowedModules && allowedModules?.length > 0) {
      setCurrentlyAllowedModules(allowedModules);
    } else {
      fetchAllowedModules();
    }
  }, [allowedModules]);

  const clearStorageHeader = async () => {
    await setStorageFunction("headerTitle", "");
  };

  const getHeading = async () => {
    const storageHeaderIDValue = await getStorageFunction("headerTitle");
    if (storageHeaderIDValue && typeof storageHeaderIDValue === "string") {
      setStorageHeaderID(storageHeaderIDValue);
    }
  };

  const fetchAllowedModules = async () => {
    const modules = await getAllowedModules();
    setCurrentlyAllowedModules(modules);
  };

  const toggleDrawerState = () => {
    popupState.close();
    const newState =
      toggleState === SidebarState.OPEN
        ? SidebarState.CLOSED
        : SidebarState.OPEN;
    toggleSidebar({ toggle: newState });
  };

  const handleDrawerMouseEnter = () => {
    setDrawerHoverActive(true);
  };

  const handleDrawerMouseLeave = () => {
    setDrawerHoverActive(false);
  };

  useEffect(() => {
    // Close the popup if the mouse moves over from the side.
    if (!currentHover && !drawerHoverActive) {
      popupState.close();
    }
  }, [currentHover, drawerHoverActive]);

  const handleItemMouseEnter = (parentID: string, childID: string | null) => {
    setCurrentHover({
      parentID: parentID,
      childID: childID,
    });
  };

  const handleItemMouseLeave = () => {
    setCurrentHover(null);
  };

  const navigateAndSetHeaderID = async (url: string, parentID: string) => {
    // Navigates to the URL associated with the clicked item, if it's not the current URL.
    if (history.location.pathname !== url) {
      history.push(url);
    }
    await setStorageFunction("headerTitle", parentID);
    getHeading();
  };

  const handleItemClick = (item: BaseSidebarManifest, clickedIndex: number) => {
    // Expand child items if currently collapsed and click event targets a different item.
    // Collapse child items if currently expanded and click event targets the same item.
    if (item?.children) {
      setExpanded(
        expanded === null || expanded !== clickedIndex ? clickedIndex : null
      );
    } else if (item && item?.url) {
      navigateAndSetHeaderID(item.url, item.id);
    }
  };

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    item: BaseSidebarManifest
  ) => {
    setAnchorEl(event.currentTarget);
    setActivePopoverItem(item);
  };

  const handlePopoverClose = () => {
    if (anchorEl?.id !== activePopoverItem?.label) {
      setAnchorEl(null);
      setActivePopoverItem(null);
    }
  };

  const redirectToDashboard = () => {
    navigateAndSetHeaderID("/dashboard", "dashboard");
  };

  const componentProps: DrawerComponentProps = {
    drawerPaperProps: {
      sx: { overflowY: drawerHoverActive ? "auto" : "hidden" },
    },
    edbaLogoStyleProps: {
      display: toggleState === SidebarState.OPEN ? "block" : "none",
    },
    toggleIconStyleProps: {
      cursor: "pointer",
      marginRight:
        toggleState === SidebarState.OPEN && drawerHoverActive && isOverflowing
          ? "-6px"
          : 0,
    },
    instituteText: {
      fontSize: 15,
      fontWeight: 600,
      fontFamily: fonts.bold,
      color: colors.primaryText,
      whiteSpace: "normal",
      overflow: "hidden",
    },
    drawerListItemText: {
      fontWeight: 600,
      fontFamily: fonts.bold,
      color: colors.primaryText,
    },
    collapseListItemText: {
      fontFamily: fonts.regular,
      color: colors.secondaryText,
    },
    anchorOrigin: {
      vertical: "center",
      horizontal: "right",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "left",
    },
    tooltipText: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
  };

  return (
    <>
      <Drawer
        variant="permanent"
        open={toggleState === SidebarState.OPEN}
        onMouseEnter={handleDrawerMouseEnter}
        onMouseLeave={handleDrawerMouseLeave}
        PaperProps={componentProps.drawerPaperProps}
      >
        <OverflowContainer ref={contentRef}>
          <DrawerHeader
            open={toggleState === SidebarState.OPEN}
            drawerHoverActive={drawerHoverActive}
            isOverflowing={isOverflowing}
          >
            <Logo onClick={redirectToDashboard}>
              <EDBALogo rem={rem} style={componentProps.edbaLogoStyleProps} />
            </Logo>
            <FontAwesomeIcon
              icon={faBars}
              size={"lg"}
              onClick={toggleDrawerState}
              color={colors.primary}
              style={componentProps.toggleIconStyleProps}
            />
          </DrawerHeader>
          <Divider />
          {showInstituteName && (
            <>
              <InstituteList>
                <DListItem key={"institute-name"} disableGutters disablePadding>
                  <InstituteListItemButton
                    open={toggleState === SidebarState.OPEN}
                  >
                    <DListItemIcon
                      open={toggleState === SidebarState.OPEN}
                      drawerHoverActive={drawerHoverActive}
                      isOverflowing={isOverflowing}
                    >
                      <FontAwesomeIcon icon={faUniversity} size={"lg"} />
                    </DListItemIcon>
                    <InstituteListItemText
                      open={toggleState === SidebarState.OPEN}
                      drawerHoverActive={drawerHoverActive}
                      isOverflowing={isOverflowing}
                      primary={
                        <LightTooltip
                          title={userInfo?.userDetail?.instituteName ?? "--"}
                        >
                          <span style={componentProps.tooltipText}>
                            {userInfo?.userDetail?.instituteName ?? "--"}
                          </span>
                        </LightTooltip>
                      }
                      primaryTypographyProps={componentProps.instituteText}
                    />
                  </InstituteListItemButton>
                </DListItem>
              </InstituteList>
              <Divider />
            </>
          )}
          <List>
            {filteredRoutes &&
              filteredRoutes.map((item: BaseSidebarManifest, index: number) => (
                <DListItem
                  key={item.id}
                  disableGutters
                  disablePadding
                  onMouseEnter={() => handleItemMouseEnter(item.id, null)}
                  onMouseLeave={handleItemMouseLeave}
                  onClick={() => handleItemClick(item, index)}
                >
                  <DListItemButton
                    {...bindHover(popupState)}
                    id={item.label}
                    open={toggleState === SidebarState.OPEN}
                    drawerHoverActive={drawerHoverActive}
                    isOverflowing={isOverflowing}
                    bgColor={
                      toggleState === SidebarState.OPEN &&
                        (currentHover?.parentID === item.id ||
                          storageHeaderID?.includes(item.id))
                        ? colors.menuSelectionBg
                        : colors.white
                    }
                    onMouseEnter={(event: React.MouseEvent<HTMLElement>) =>
                      handlePopoverOpen(event, item)
                    }
                    onMouseLeave={handlePopoverClose}
                    aria-owns={popoverOpen ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                  >
                    <DListItemIcon
                      open={toggleState === SidebarState.OPEN}
                      drawerHoverActive={drawerHoverActive}
                      isOverflowing={isOverflowing}
                    >
                      {typeof item.icon === "string" ? (
                        <Icon
                          name={item.icon}
                          color={
                            currentHover?.parentID === item.id ||
                              storageHeaderID === item.id
                              ? colors.primary
                              : colors.secondaryText
                          }
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={item.icon}
                          size={item.faIconSize}
                          color={
                            currentHover?.parentID === item.id ||
                              storageHeaderID === item.id
                              ? colors.primary
                              : colors.secondaryText
                          }
                        />
                      )}
                    </DListItemIcon>
                    <DListItemText
                      open={toggleState === SidebarState.OPEN}
                      primary={t(item.label)}
                      primaryTypographyProps={
                        expanded === index ||
                          currentHover?.parentID === item.id ||
                          storageHeaderID === item.id
                          ? componentProps.drawerListItemText
                          : {}
                      }
                    />
                    {toggleState === SidebarState.OPEN &&
                      item?.children &&
                      item.children?.length > 0 && (
                        <ECToggle>
                          <FontAwesomeIcon
                            icon={
                              expanded !== null && expanded === index
                                ? faCaretUp
                                : faCaretDown
                            }
                            color={colors.primaryText}
                          />
                        </ECToggle>
                      )}
                  </DListItemButton>
                  <Collapse
                    in={toggleState === SidebarState.OPEN && expanded === index}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.children?.map((child: ChildSidebarManifest) => (
                        <CollapseListItem
                          key={child.id}
                          disablePadding
                          disableGutters
                          onClick={(event) => {
                            event.stopPropagation(); // Prevents the click from bubbling up to the parent list item, which would cause the collapse to close.
                            navigateAndSetHeaderID(
                              child.url,
                              `${item.id}-${child.id}`
                            );
                          }}
                        >
                          <CollapseListItemButton
                            drawerHoverActive={drawerHoverActive}
                            isOverflowing={isOverflowing}
                            isSelected={
                              currentHover?.childID === child.id ||
                              storageHeaderID?.includes(child.id)
                            }
                          >
                            <ListItemText
                              primary={t(child.label)}
                              primaryTypographyProps={
                                componentProps.collapseListItemText
                              }
                            />
                          </CollapseListItemButton>
                        </CollapseListItem>
                      ))}
                    </List>
                  </Collapse>
                </DListItem>
              ))}
          </List>
          <Divider />
        </OverflowContainer>
      </Drawer>
      <StateBoundHoverPopover
        {...bindPopover(popupState)}
        id="mouse-over-popover"
        open={
          toggleState === SidebarState.CLOSED &&
          popoverOpen &&
          popupState.isOpen
        }
        drawerHoverActive={drawerHoverActive}
        isOverflowing={isOverflowing}
        anchorEl={anchorEl}
        anchorOrigin={componentProps.anchorOrigin}
        transformOrigin={componentProps.transformOrigin}
        onClose={handlePopoverClose}
        disableRestoreFocus
        onMouseEnter={() =>
          activePopoverItem
            ? handleItemMouseEnter(activePopoverItem.id, null)
            : {}
        }
        onMouseLeave={() => {
          handleItemMouseLeave();
          popupState.close();
        }}
      >
        {activePopoverItem && (
          <PopoverList>
            <DListItem
              key={activePopoverItem.id}
              disableGutters
              disablePadding
              onClick={() =>
                activePopoverItem?.url
                  ? navigateAndSetHeaderID(
                    activePopoverItem.url,
                    activePopoverItem.id
                  )
                  : {}
              }
            >
              <PopoverHeaderListItemButton
                isParent={!!activePopoverItem?.children}
              >
                <ListItemText
                  primary={t(activePopoverItem.label)}
                  primaryTypographyProps={componentProps.drawerListItemText}
                />
              </PopoverHeaderListItemButton>
            </DListItem>
            {activePopoverItem?.children &&
              activePopoverItem.children.map(
                (popoverChild: ChildSidebarManifest) => (
                  <PopoverChildListItemButton
                    key={popoverChild.id}
                    onMouseEnter={() =>
                      handleItemMouseEnter(
                        activePopoverItem.id,
                        popoverChild.id
                      )
                    }
                    onClick={() =>
                      navigateAndSetHeaderID(
                        popoverChild.url,
                        activePopoverItem.id
                      )
                    }
                  >
                    <ListItemText
                      key={popoverChild.id}
                      primary={t(popoverChild.label)}
                      primaryTypographyProps={
                        componentProps.collapseListItemText
                      }
                    />
                  </PopoverChildListItemButton>
                )
              )}
          </PopoverList>
        )}
      </StateBoundHoverPopover>
    </>
  );
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")<DrawerState>(
  ({ theme, open, drawerHoverActive: hoverActive, isOverflowing }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: open ? "space-between" : "center",
    padding: theme.spacing(2, 2.5),
    position: "sticky",
    top: 0,
    backgroundColor: colors.white,
    zIndex: 2,
    marginRight: !open && hoverActive && isOverflowing ? "-6px" : "0px",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  })
);

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors.white,
    boxShadow: `0px 0px 4px ${colors.searchShadow}`,
    color: colors.gray,
    fontSize: 11,
    padding: "6px 12px",
    lineHeight: 1.3,
  },
}));

const DListItem = styled(ListItem)(() => ({
  display: "block",
  padding: 0,
}));

const DListItemButton = styled(ListItemButton)<
  DrawerState & { bgColor: string }
>(({ drawerHoverActive, isOverflowing, open, bgColor }) => ({
  minHeight: 56,
  justifyContent: open ? "initial" : "center",
  marginTop: "8px",
  marginBottom: "8px",
  marginLeft: open ? "20px" : 0,
  marginRight: open
    ? drawerHoverActive && isOverflowing
      ? "14px"
      : "20px"
    : 0,
  backgroundColor: bgColor,
  borderRadius: open ? "8px" : "0px",
  ":hover": {
    backgroundColor: colors.menuSelectionBg,
  },
}));

const DListItemIcon = styled(ListItemIcon)<DrawerState>(
  ({ theme, open, drawerHoverActive, isOverflowing }) => ({
    minWidth: 0,
    width: "24px",
    marginRight: open
      ? theme.spacing(3)
      : drawerHoverActive && isOverflowing
        ? "-6px"
        : "0px",
    alignItems: "center",
    justifyContent: "center",
  })
);

const DListItemText = styled(ListItemText)<Pick<DrawerState, "open">>(
  ({ open }) => ({
    display: open ? "block" : "none",
    fontFamily: fonts.regular,
    color: colors.secondaryText,
  })
);

const Logo = styled("div")`
  cursor: pointer;
`;

const InstituteList = styled(List)(() => ({
  backgroundColor: colors.bluishGreyBackground,
}));

const InstituteListItemButton = styled(ListItemButton)<
  Pick<DrawerState, "open">
>(({ theme, open }) => ({
  minHeight: 60,
  justifyContent: open ? "initial" : "center",
  padding: theme.spacing(0, 2.5),
  ":hover": {
    backgroundColor: "transparent",
  },
}));

const InstituteListItemText = styled(ListItemText)<DrawerState>(
  ({ open, drawerHoverActive, isOverflowing }) => ({
    display: open ? "block" : "none",
    marginRight: drawerHoverActive && isOverflowing ? "-6px" : "0px",
  })
);

const CollapseListItem = styled(ListItem)`
  padding: 0;
`;

const CollapseListItemButton = styled(ListItemButton)<
  Omit<DrawerState, "open"> & { isSelected?: boolean }
>(({ drawerHoverActive, isOverflowing, isSelected }) => ({
  paddingLeft: "80px",
  marginTop: "4px",
  marginBottom: "4px",
  marginLeft: "20px",
  marginRight: drawerHoverActive && isOverflowing ? "14px" : "20px",
  background: isSelected ? colors.menuSelectionBg : colors.white,
  borderRadius: "8px",
  ":hover": {
    backgroundColor: colors.menuSelectionBg,
  },
}));

const StateBoundHoverPopover = styled(HoverPopover)<DrawerState>(
  ({ drawerHoverActive, isOverflowing }) => ({
    marginLeft: drawerHoverActive && isOverflowing ? "6px" : 0,
  })
);

const PopoverList = styled(List)(() => ({ width: drawerWidth }));

const PopoverHeaderListItemButton = styled(ListItemButton)<{
  isParent: boolean;
}>(({ theme, isParent }) => ({
  minHeight: 48,
  padding: theme.spacing(0, 2),
  pointerEvents: isParent ? "none" : "auto",
  ":hover": {
    backgroundColor: isParent ? colors.white : colors.menuSelectionBg,
  },
}));

const PopoverChildListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  ":hover": {
    backgroundColor: colors.menuSelectionBg,
  },
}));

const ECToggle = styled("div")``;

const OverflowContainer = styled("div")``;
