/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { colors, fonts } from 'src/styles/theme/styles';
import './employee.css';
import { useI18n } from 'src/i18n/hooks';
import { View, Text } from 'react-native';
import { useThemeSystem } from 'src/contexts/theme-context';
import { height } from 'src/constant/device';

interface propType {
  data: any[];
}
const trow = false;
const current = null;
export default function BankDetailsTable(props: propType) {
  const { data } = props;
  const columns = [
    { label: 'accountName.label' },
    { label: 'bankName.label' },
    { label: 'ifsc.label' },
    { label: 'accountNumber.label' },
    { label: 'status.label' },
  ];

  const { t } = useI18n();

  const show = true;
  const { theme } = useThemeSystem();

  return (
    <div>
      <div id="app" style={{ border: '1px solid transparent' }}>
        <table className="tb" style={{ backgroundColor: theme?.table?.backgroundColor }}>
          <tr
            style={{
              backgroundColor: theme?.table?.header?.backgroundColor,
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}>
            {!!show && <th className="firstCol" />}
            {columns.map((e: any, idx) => (
              <th key={`emp-table-header-${idx}`} style={{ color: theme?.table?.header?.color }}>
                {t(e.label).toUpperCase()}
              </th>
            ))}
          </tr>
          <tbody>
            {data &&
              data?.length > 0 &&
              data?.map((bankinfo: any, i: number) => (
                <React.Fragment key={bankinfo.id}>
                  <tr
                    style={{
                      backgroundColor: trow && current === i ? '#EAF2FA' : 'transparent',
                      ...parentBorderRight(trow, current, i),
                      ...parentBorderLeft(trow, current, i),
                      ...commonBorder(trow, current, i),
                      width: '5%',
                    }}>
                    <td style={{ width: '2%' }}></td>
                    <td
                      onClick={() =>
                        // handleParenView(d?.id)
                        {
                          //
                        }
                      }
                      style={{ color: theme?.table?.color, width: '24%', cursor: 'pointer' }}
                      className={'expand'}>
                      {`${bankinfo?.name}`}
                    </td>
                    <td style={{ color: theme?.table?.color, width: '22%' }} className={'expand'}>
                      {bankinfo?.bankName}
                    </td>

                    <td style={{ color: theme?.table?.color, width: '16%' }} className={'expand'}>
                      {bankinfo?.ifscCode}
                    </td>
                    <td style={{ color: theme?.table?.color, width: '17%' }} className={'expand'}>
                      {bankinfo?.accountNumberView}
                    </td>
                    <td style={{ color: theme?.table?.color, width: '16%' }} className={'expand'}>
                      {bankinfo?.linkStatus === 'LINKED' ? 'LINKED' : 'PENDING'}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
        {data && data?.length === 0 && (
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              height: height - 368,
              borderBottomWidth: 1,
              borderBottomColor: colors.borderGrey,
            }}>
            <Text style={{ fontFamily: fonts.regular }}>No records found</Text>
          </View>
        )}
      </div>
    </div>
  );
}

const parentBorderRight = (t, current, i) => {
  return {
    borderRight:
      t && current === i ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

const parentBorderLeft = (t, current, i) => {
  return {
    borderLeft:
      t && current === i ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

const commonBorder = (t, current, i) => {
  return {
    borderBottom:
      t && current === i
        ? `2px solid ${colors.tableDropdwonBorder}`
        : `1px solid ${colors.lighterBorder}`,
    borderTop:
      t && current === i
        ? `2px solid ${colors.tableDropdwonBorder}`
        : `1px solid ${colors.lighterBorder}`,
  };
};
