import React, { useEffect, useState } from "react";

import Element from "src/components/molecules/Forms/ApplicationElement";
import FormJSON from "src/form-json/admission/edit-scroll-ref-form.json";
import styled from "styled-components/native";

function EditScrollRefForm(props: any) {
  const { control, errors, setValue, currentScrollRefNo } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    setValue("scrollRefNo", currentScrollRefNo ?? '');
    setElements(FormJSON[0]);
  }, []);

  return (
    <>
      {fields &&
        fields.map((field: any, i: number) => (
          <FieldWrapper key={`e${i}`}>
            <Element
              key={`e${i}`}
              field={field}
              control={control}
              errors={errors}
            />
          </FieldWrapper>
        ))}
    </>
  );
}

const FieldWrapper = styled.View``;

export default EditScrollRefForm;
