import React, { Dispatch, SetStateAction } from 'react';
import Check from 'src/components/atoms/CheckBox/index.web';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components';
import CreateInfoItem from '../TextComponents/InfoItem';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { colors } from 'src/styles/theme/styles';
import { ReconcilePaymentData } from 'src/components/organism/Courses/BatchStudents/types';

interface Props {
  confirmSelection: boolean;
  setConfirmSelection: Dispatch<SetStateAction<boolean>>;
  reconcilePaymentData: ReconcilePaymentData | null;
}

export default function ReconcilePaymentForm(props: Props) {
  const { confirmSelection, setConfirmSelection, reconcilePaymentData } = props;
  const { t } = useI18n();
  return (
    <>
      <RequestDetailWrapper>
        <MediumTextSelect
          value={t('previousDetails.label')}
          color={colors.primaryText}
          fontSize={1.8}
        />
        <DetailsWrapper>
          <CreateInfoItem
            label={t('requestDate.label')}
            value={
              reconcilePaymentData?.createdAt
                ? format(reconcilePaymentData.createdAt, DT.DATE_TIME_SLASH_12_HOUR)
                : 'N/A'
            }
          />
          <CreateInfoItem label={t('requestStatus.label')} value={reconcilePaymentData?.status || 'N/A'} />
        </DetailsWrapper>

        {reconcilePaymentData?.status === 'FAILED' && reconcilePaymentData?.errMessage && (
          <ErrorMessageWrapper>
            <MediumTextSelect value={`(${reconcilePaymentData.errMessage})`} color={colors.red} />
          </ErrorMessageWrapper>
        )}
      </RequestDetailWrapper>
      <CheckWrapper>
        <Check same={false} checked={confirmSelection} onChange={(e) => setConfirmSelection(e)} />
        <MediumTextSelect value={t('form.reconcilePayment.warning')} style={{ paddingLeft: 8 }} />
      </CheckWrapper>
    </>
  );
}

const RequestDetailWrapper = styled.div``;

const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0px;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
`;

const ErrorMessageWrapper = styled.div`
  margin-top: 8px;
`;