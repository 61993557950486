import { USER_TYPE } from 'src/constant';
import { useI18n } from 'src/i18n/hooks';
import navigationStrings from './navigationStrings';
import { userInformation, UserInformation } from 'src/utils/manageState';
import { faBackpack, faBallotCheck, faCalendarAlt, faCalendarDay, faClipboardUser, faCommentAltDots, faEnvelope, faHomeLgAlt, faPollH } from '@fortawesome/pro-light-svg-icons';
import { colors } from 'src/styles/theme/styles';

export const useDrawer = () => {
  const { t, changeLanguage } = useI18n();
  const currentUserObject = userInformation();
  const routesConfig = [
    {
      label: t('sidebar.native.institute'),
      imageSrc: require('src/assets/drawerIcons/menu/menu/icon/menu/home.png'),
      iconName: 'institute',
      authUser: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      module: 'default',
    },
    {
      label: t('sidebar.native.home'),
      defaultIcon: true,
      iconName: faHomeLgAlt,
      iconSize: 19,
      iconColor: colors.secondaryText,
      url: navigationStrings.NATIVE_TABBAR_DASHBOARD,
      authUser: [USER_TYPE.STUDENT, USER_TYPE.APPLICANT],
      module: 'default',
    },
    {
      label: t('sidebar.native.home'),
      defaultIcon: true,
      iconName: faHomeLgAlt,
      iconSize: 19,
      iconColor: colors.secondaryText,
      url: navigationStrings.NATIVE_TABBAR_DASHBOARD,
      authUser: [USER_TYPE.EMPLOYEE],
      subAuth: ['TEACHING'],
      module: 'default',
    },
    {
      label: t('sidebar.native.admin'),
      imageSrc: require('src/assets/tabbar-icon/icon/profile.png'),
      iconName: 'institute-admin',
      url: navigationStrings.NATIVE_TABBAR_ADMIN,
      authUser: [USER_TYPE.EMPLOYEE],
      subAuth: ['ADMIN'],
      module: 'default',
    },
    {
      label: t('sidebar.native.assignments'),
      defaultIcon: true,
      iconName: faBackpack,
      iconSize: 18,
      iconColor: colors.secondaryText,
      url: navigationStrings.NATIVE_TABBAR_ASSIGNMENTS,
      authUser: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      module: 'default',
      subAuth: ['TEACHING'],
    },
    {
      label: t('sidebar.native.communication'),
      defaultIcon: true,
      iconName: faCommentAltDots,
      iconSize: 18,
      iconColor: colors.secondaryText,
      url: navigationStrings.NATIVE_TABBAR_NEWS_NOTICES,
      authUser: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      module: 'default',
    },
    {
      label: t('sidebar.native.time-table'),
      defaultIcon: true,
      iconName: faCalendarAlt,
      iconSize: 19,
      iconColor: colors.secondaryText,
      url: navigationStrings.NATIVE_TIMETABLE,
      authUser: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      module: 'default',
      subAuth: ['TEACHING'],
    },
    {
      label: t('sidebar.native.attendance'),
      defaultIcon: true,
      iconName: faClipboardUser,
      iconSize: 20,
      iconColor: colors.secondaryText,
      url:
        currentUserObject.userType === 3
          ? navigationStrings.STUDENT_ATTENDANCE
          : navigationStrings.ATTENDANCE,
      authUser: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      module: 'default',
      subAuth: ['TEACHING'],
    },
    {
      label: t('sidebar.native.events'),
      defaultIcon: true,
      iconName: faCalendarDay,
      iconSize: 19,
      iconColor: colors.secondaryText,
      url: navigationStrings.EVENTS,
      authUser: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      module: 'default',
    },
    {
      label: t('payment.label'),
      iconName: 'payment',
      iconSize: 19,
      url: navigationStrings.PAYMENT,
      authUser: [USER_TYPE.STUDENT],
      module: 'Payments',
    },
    {
      label: t('sidebar.native.messages'),
      url: navigationStrings.MESSAGES,
      defaultIcon: true,
      iconName: faEnvelope,
      iconSize: 19,
      iconColor: colors.secondaryText,
      authUser: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
      module: 'default',
    },
    {
      label: t('applications.label'),
      url: navigationStrings.APPLICATION_LISTING,
      customImage: { path: require("src/assets/drawerIcons/menu/menu/icon/menu/application.png"), width: 21, height: 21 },
      authUser: [USER_TYPE.STUDENT],
      module: 'default',
    },
    {
      label: t('results.label'),
      url: navigationStrings.NATIVE_RESULTS_LIST,
      defaultIcon: true,
      iconName: faPollH,
      iconSize: 19,
      iconColor: colors.secondaryText,
      authUser: [USER_TYPE.STUDENT],
      module: 'default',
    },
    // {
    //   label: t('sidebar.native.payment'),
    //   iconName: 'payment',
    //   imageSrc: '',
    //   url: '',
    //   authUser: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
    // },
    // {
    //   label: t('sidebar.native.profile'),
    //   iconName: 'profile',
    //   imageSrc: require('src/assets/drawerIcons/menu/menu/Icon/menu/students.png'),
    //   url: navigationStrings.NATIVE_TABBAR_PROFILE,
    //   authUser: [USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT],
    //   module: '',
    // },
  ];

  return {
    routesConfig,
  };
};
