import { TextInput } from './TextInput/index';
import { Select } from './Select/index';
import { CustomSubForm } from './SubForm/index';
import { ImageContainer } from './ImageContainer/index';
import { Image } from './Image/index';
import { File } from './File';
import { CheckBox } from './CheckBox/index';
import { Empty } from './Empty/index';
import { ThreeColumnCustomSubForm } from './ThreeColumnSubForm/index';
import FieldView from './FieldView/index';
import FileView from './FileView/index';
import { isWeb } from 'src/constant/device';
import CustomSelect from './CustomSelect/index';
import { HeaderTitle } from './Headers';
import Search from './Search/index';
import CasteCategoryTbl from './Table/CasteCategoryTbl';
import AssignRollNoTbl from './Table/AssignRollNoTbl';
import ChipsInputTbl from './Table/ChipsInputTbl';
import { MultiDocInput } from './MultiDocInput/index';
import StreamSelection from './StreamSelection/index';
import DocumentSelectionTbl from './Table/DocumentSelectionTbl';
import TriggerSelection from './TriggerSelection/index.web';
import Toggle from './Toggle'
import ReservationCriteriaRadioSelectTbl from './Table/ReservationCriteriaRadioSelectTbl';
import MultiFileView from './FileView/multi';
import ConcessionRadioSelectTbl from './Table/ConcessionRadioSelectTbl';
import DateTime from './DateTimePicker/index';

export const componentMapper = {
  'custom-text-field': TextInput,
  'select': Select,
  "none": Empty,
  "custom-sub-form": CustomSubForm,
  "three-column-custom-sub-form": isWeb ? ThreeColumnCustomSubForm : CustomSubForm,
  "image-container": ImageContainer,
  "image": Image,
  "file-upload": File,
  'checkbox': CheckBox,
  'fieldview': FieldView,
  'fileview': FileView,
  "toggleSwitch": Toggle,
  'multifileview': MultiFileView,
  'custom-select': CustomSelect,
  'header-title': HeaderTitle,
  'search': Search,
  'assign-rollno-table': AssignRollNoTbl,
  'caste-category-table': CasteCategoryTbl,
  'input-chips-table': ChipsInputTbl,
  "multi-doc-input": MultiDocInput,
  "streamSelection": StreamSelection,
  "document-selection-table": DocumentSelectionTbl,
  "trigger-selection": TriggerSelection,
  "reservation-criteria-radio-select-table": ReservationCriteriaRadioSelectTbl,
  "fee-concession-radio-select-table": ConcessionRadioSelectTbl,
  'date-time-picker': DateTime,
}
