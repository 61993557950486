import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { limit } from '../../constant';
import { readCacheQuery, writeCacheQuery } from '../util';

export const allowedModulesQuery = gql`
    query($institute: String) {
      allowedModules(institute: $institute) {
        modules  
     }
}
`;

export const addModulesToInstituteQuery = gql`
    mutation($institute: String!, $modules: [String!]!) {
      addModules(institute: $institute, modules: $modules) {
        modules  
     }
}
`;
export const removeModulesFromInstituteQuery = gql`
    mutation($institute: String!, $modules: [String!]!) {
      removeModules(institute: $institute, modules: $modules) {
        modules  
     }
}
`;

export function getAllowedModules() {
    const [fetchAllowedModules, { data: allowedModules }] = useLazyQuery(allowedModulesQuery, { fetchPolicy: 'network-only' });
    return { fetchAllowedModules, allowedModules };
}

export function addModuleAccessToInstitute() {
  const [addModuleAccessQ, { error }] = useMutation(addModulesToInstituteQuery);
  return { addModuleAccessQ, error };
}

export function removeModuleAccessfromInstitute() {
  const [removeModuleAccessQ, { error }] = useMutation(removeModulesFromInstituteQuery);
  return { removeModuleAccessQ, error };
}
