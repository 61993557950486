import React, { createContext, useContext } from 'react';
import { usePersistStorage } from 'react-native-use-persist-storage';
import { IUserProfileDetails } from 'src/utils/customHooks/useLoadUserDetails';

interface ProfileDetailContext {
  setUserProfileDetails: (name: any) => void;
  userProfileDetails?: IUserProfileDetails;
}

const ProfileDetailContext = createContext<ProfileDetailContext>({} as ProfileDetailContext);

const ProfileDetailProvider: React.FC = ({ children }) => {
  const [userProfileDetails, setUserProfileDetails] = usePersistStorage('basicInformation', undefined);
  return (
    <ProfileDetailContext.Provider value={{ userProfileDetails, setUserProfileDetails }}>
      {children}
    </ProfileDetailContext.Provider>
  );
};

const useProfileDetail = (): ProfileDetailContext => {
  const context = useContext(ProfileDetailContext);
  return context;
};

export { ProfileDetailProvider, useProfileDetail };
