import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import { useI18n } from 'src/i18n/hooks';
import { useHistory } from 'src/routes/routing';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { colors } from 'src/styles/theme/styles';
import { getApplicationPath } from 'src/utils/utility';

export interface SaveAndNextButtonGroupProp {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  isLastStep: boolean;
  isSubmitting: boolean;
  isOnboarding: boolean;
  handleSubmit: (callback?: Function) => Promise<void>;
  onCancel?: (callback?: Function) => Promise<void>;
  inModalView?: boolean;
  prefix?: string;
  requestId?: string;
  capId?: string;
  inviteCode?: string;
  showSkipLabel: boolean;
}

const SaveAndNextButtonGroup: React.FC<SaveAndNextButtonGroupProp> = (props) => {
  const {
    step,
    setStep,
    isLastStep,
    isSubmitting,
    isOnboarding,
    inModalView,
    onCancel,
    handleSubmit,
    prefix,
    requestId,
    capId,
    inviteCode,
    showSkipLabel
  } = props
  const { t } = useI18n();
  const history = useHistory();

  const goToPrevStep = () => {
    setStep((step: number) => step - 1);
    let path = `/onboarding/${step - 1}`;
    history.push(getApplicationPath(path, prefix, requestId, capId, inviteCode));
  }

  const skipCurrentStep = () => {
    let route = isLastStep ? "/dashboard" : `/onboarding/${step + 1}`;
    history.push(getApplicationPath(route, prefix, requestId, capId, inviteCode));
  };

  const getLabel = () => {
    if (inModalView) {
      return t('save.label')
    }
    const label = isLastStep
      ? t('finish.label')
      : t('next.label')
    return label;
  }
  return (
    <ButtonGroupContainer inModalView={inModalView}>
      {step > 0 && !inModalView &&
        <BtnWrapper>
          <OutlineButton
            label={t('previous.label')}
            onPress={goToPrevStep}
            lineHeight={2}
          />
        </BtnWrapper>
      }

      {showSkipLabel && isOnboarding && !inModalView &&
        <BtnWrapper>
          <OutlineButton
            label={t('skip.label')}
            onPress={skipCurrentStep}
            lineHeight={2}
          />
        </BtnWrapper>
      }

      {inModalView && onCancel &&
        <BtnWrapper>
          <OutlineButton
            label={t('cancel.label')}
            onPress={onCancel}
            lineHeight={2}
          />
        </BtnWrapper>
      }

      <BtnWrapper>
        <SecondaryBtn
          canLoad={isSubmitting}
          disabled={isSubmitting}
          label={getLabel()}
          onPress={handleSubmit}
        />
      </BtnWrapper>
    </ButtonGroupContainer>
  )
}

export default SaveAndNextButtonGroup;

type ButtonGroupContainerProp = {
  inModalView?: boolean;
}

const ButtonGroupContainer = styled.div<ButtonGroupContainerProp>`
  background-color: ${(pr) => pr.inModalView && colors.white};
  position: ${(pr) => pr.inModalView && 'sticky'};
  bottom: ${(pr) => pr.inModalView && '0'};
  display: flex;
  align-items: center;
  margin: ${(pr) => pr.inModalView ? '0px -32px;' : '0px'};
  height: ${(pr) => `${pr.inModalView}` ? "64px" : "100px"};
  justify-content: ${(pr) => pr.inModalView ? 'flex-end' : 'flex-start'};
  border-top: ${(pr) => pr.inModalView ? `1px solid ${colors.dividerColor}` : 'none'};
  @media all and (max-width: 600px) {
    justify-content: space-between;
  }
`;

const BtnWrapper = styled.div`
  margin-right: 16px;
`;
