import { CSSProperties } from "react";
import { height } from "./device";

export type Dimensions = {
  width: CSSProperties["width"];
  height: CSSProperties["height"];
};

// SD -> Standard Dimensions
export const SD = {
  primaryInfoModal: {
    width: 800,
    height: 0.7 * height,
  },
};
