import { AdmissionStatus, ApplicationStatus, ApplicationStatusLabel, limit, PaymentMode, USER_TYPE, SUCCESS, ERROR, SEARCH_TIME, AUTOCOMPLETE_MODULE, APPLICANT_TYPE_ENUM } from 'src/constant';
import { NoRecordsFound, TableCells, TableDiv, TableRows } from 'src/components/molecules/Table/TableAtom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'src/routes/routing.web';

import { ApplicationItemType } from './types'
import ApplicationPayableListing from 'src/components/organism/Applications/ApplicationPayableListing';
import { EditIcon, EducationDetailsIcon, ExportFileIcon, InfoSquareIcon, MakePaymentIcon, ViewFileIcon } from 'src/components/atoms/ActionIcons';
import Filter from 'src/components/molecules/Filter/index.web';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { TableBody, Tooltip } from '@mui/material';
import { getApplicationStatusColor } from 'src/components/atoms/FunctionsList';
import { height } from 'src/constant/device';
import styled from 'styled-components';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useCreateAdmissionFeePayment, useCreateApplicationFeesPayment, useGetApplicationsList } from 'src/graphql/applications/applications';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import useTable from 'src/components/molecules/Table/UseTable';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { graphqlQuery } from 'src/graphql/util';
import { getApplicationFeeBookQuery } from 'src/graphql/payment';
import PaymentModal from 'src/components/molecules/Payment/Student/PaymentModal';
import { useForm } from 'react-hook-form';
import { validation } from 'src/constant/validation';
import { userInformation } from 'src/utils/manageState';
import { checkRemark, createPaymentDetailObject, downloadFileRestAPI } from 'src/utils/utility';
import { DisplayApplicationDetails } from './DisplayApplicationDetails';
import { FeeDetail } from "src/components/organism/Payment/types";
import { Icon } from 'src/components/atoms/Icon/Icon';
import BlockRejectionReason from 'src/components/molecules/Admission/BlockRejectionReason';
import ApplicationFeesFormWeb from './ApplicationFeesFormWeb';
import nttPaymentOptions from 'src/components/molecules/Payment/NTTPaymentOptions';
import { createSearchFilter, debounce } from 'src/components/services';
import { useFilter } from 'src/contexts/filter-context';
import { getApplicationIdSearchFilter } from 'src/components/services/filters';
import { getErrorMessage } from './helpers';
import { paymentFormData, paymentPayload } from '../Admission/types';
import { applicantCheckLimitedAccessQuery } from 'src/graphql/onboarding/applicant';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import AddApplicationForm from "src/components/molecules/Application/AddApplicationForm/index";

const headCells1 = [
  {
    id: 'applicationId',
    label: 'applicationId.label'
  },
  {
    id: 'instituteName',
    label: 'instituteName.label'
  },
  {
    id: 'courseName',
    label: 'courseName.label'
  },
  {
    id: 'className',
    label: 'className.label'
  },
  {
    id: 'applicationStatus',
    label: 'applicationStatus.label'
  },
  {
    id: 'actions',
    label: 'actions.label',
    align: 'right'
  }
];

export default function Applications() {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { setAlertDetails } = useAlertSystem();
  const { page = 1, dataLimit = limit, prefix, requestId, capId, inviteCode }: any = useParams();
  const history = useHistory();
  let searchValue = '' as string;
  const maxHeight = height - 330;
  const paginationHeightOffset = -40;
  const { filters, setFilter } = useFilter();
  if (filters && filters?.application_id?.on) {
    searchValue = filters?.application_id?.search;
  }

  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  const [searching, setSearchingApplicationId] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [row, setRow] = useState();
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [paymentListingModal, setPaymentListingModal] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<ApplicationItemType | null>(null);
  const [currentFeeDetails, setCurrentFeeDetails] = useState<FeeDetail>();
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [currFilter, setCurrFilter] = useState<any>();
  const [remarkModal, setRemarkModal] = useState<boolean>(false);
  const remarkRef = useRef(null);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [makePaymentModal, setMakePaymentModal] = useState<boolean>(false);
  const [viewApplModal, setViewApplModal] = useState<boolean>(false);
  const [showQR, setShowQR] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState<any>();
  const [mode, setMode] = useState<string>('');
  const [addApplicationModal, setAddApplicationModal] = useState<boolean>(false);
  const [applicationFeesModal, setApplicationFeesModal] = useState<boolean>(false);
  const [currentApplication, setCurrentApplication] = useState<ApplicationItemType | null>(null);
  const [partialPayment, setPartialPayment] = useState<number | null>(null);
  const [cancellationCharge, setCancellationCharge] = useState<number | null>(null);
  const currentUserObject = userInformation();
  const { userType, userId } = currentUserObject;
  const location = useLocation();
  const applId: string = location?.state?.applId;
  const applStatus: string = location?.state?.applStatus;
  const isApplicant = userType === USER_TYPE.APPLICANT;

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const resetStatusForm = () => {
    setValue('mode', null);
    setValue('cheque_number', '');
    setValue('draft_number', '');
    setValue('payment_amount', '');
    setValue('neft_transaction_id', '');
  };

  const { data: limitedAccessData, loading: limitedAccessLoading } = useQuery(
    applicantCheckLimitedAccessQuery,
    {
      variables: { id: userId },
      fetchPolicy: "network-only",
    }
  );

  const { getApplicationsList, applicationsListData, applicationsListLoading, applicationsListRefetch } = useGetApplicationsList();
  const { createApplicationFeesPayment, createApplicationFeesPaymentData } = useCreateApplicationFeesPayment();
  const { createAdmissionFeePayment, createAdmissionFeePaymentData } = useCreateAdmissionFeePayment();
  const { displayAtomPayNetzPortal } = nttPaymentOptions();
  const resp = limitedAccessData?.Applicant;

  useEffect(() => {
    setHeading([{ text: t('applications.label'), url: '' }]);
  }, []);

  useEffect(() => {
    if (!limitedAccessLoading && limitedAccessData) {
      if (resp && !resp.isLimitedAccess) {
        if ((inviteCode || (prefix && requestId)) && !location.pathname.includes('detail')) {
          let path = inviteCode
            ? `/application/invite/${inviteCode}`
            : `/application/add/${prefix}/${requestId}${capId ? `/${capId}` : ''}`;
          history.push(path);
        }
      }
    }
  }, [prefix, requestId, inviteCode, limitedAccessLoading, limitedAccessData]);

  useEffect(() => {
    if (applicationsListData) {
      setRow(applicationsListData?.applications?.data);
      setTotalCount(applicationsListData?.applications?.totalCount);
    };
  }, [applicationsListData]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, headCells, searchValue, rowLimit]);

  useEffect(() => {
    if (applId && applStatus && row && row?.length > 0) {
      const currItem = row.find((item: ApplicationItemType) => item?.id === applId);
      if (applStatus === ApplicationStatus.APPLICATION_FEE_PENDING) {
        handleMakeApplicationFeesPaymentModal(currItem);
      } else {
        handleMakePaymentAction(currItem);
      }
    }
  }, [applId, applStatus, row]);

  useEffect(() => {
    setSearchingApplicationId(searchValue);
  }, [searchValue]);

  function handleFetch() {
    const filters = getApplicationIdSearchFilter(searchValue);
    let applicationFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);
    getApplicationsList({ variables: applicationFilter });
  };

  function onPaymentSuccess(): void {
    if (applicationsListRefetch) applicationsListRefetch();
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
    null, null, false, true, null,
    paginationHeightOffset
  );

  const filterOptionsStatus = [
    { id: 1, label: "includes", type: "string", filteringCriteria: "in" },
    { id: 2, label: "not includes", type: "string", filteringCriteria: "nin" },
  ];

  const filterItems = [
    {
      id: 1,
      label: "Application Id",
      type: "string",
      localField: "applicationId",
      filterType: "filter",
    },
    {
      id: 2,
      label: "Institute Name",
      type: "string",
      localField: "institute",
      propName: "name", // lookup will need propName
      filterType: "lookup",
    },
    {
      id: 3,
      label: "Course Name",
      type: "string",
      localField: "alias",
      filterType: "filter",
    },
    {
      id: 4,
      label: "Class Name",
      type: "string",
      localField: "alias",
      filterType: "filter",
    },
    {
      id: 5,
      label: "Status",
      type: "string",
      localField: "status",
      filterType: "filter",
      dynamicCheckboxes: true,
      filterOptions: filterOptionsStatus,
      acceptableCheckboxValues: [
        "PAYMENT_REQUESTED",
        "PAYMENT_DONE",
        "INCOMPLETE",
      ],
    },
  ];

  function exportApplication(applicationId: string) {
    if (applicationId) {
      downloadFileRestAPI(
        `applications/exportzip/${applicationId}`,
        `application.zip`
      );
      setAlertDetails({ message: t("file-download-start.text"), level: SUCCESS });
    }
  }

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.APPLICATION_ID]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchApplicationId(applicationId: string) {
    let isClear = applicationId?.length === 0;
    persistSearch(!isClear, applicationId);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchApplicationId(q), SEARCH_TIME),
    [],
  );

  function handleSearch(applicationId: string): void {
    setSearchingApplicationId(applicationId);
    delayedQuery(applicationId);
  }

  function handleEditQualificationDetails(item: ApplicationItemType) {
    history.push(`/application/${item.id}/qualifications`);
  }

  function handleEditApplication(item: ApplicationItemType) {
    history.push(`/application/edit/${item.id}`);
  }

  function closePaymentListingModal() {
    setPaymentListingModal(false);
    setCurrentItem(null);
    setPartialPayment(null);
    setCancellationCharge(null);
  }

  async function handleMakePaymentAction(item: ApplicationItemType) {
    setCurrentItem(item);
    if (item?.fees?.installmentPayable) {
      setPartialPayment(item?.fees?.installmentPayable);
    }
    if (item?.fees?.cancellationFeeCharges) {
      setCancellationCharge(item?.fees?.cancellationFeeCharges);
    }
    const feeBookData = await graphqlQuery(getApplicationFeeBookQuery, { applicationId: item?.id }, false);
    setCurrentFeeDetails(feeBookData?.data?.getApplicationFeeBook);
    setPaymentListingModal(true);
  }

  const handleMakeApplicationFeesPaymentModal = (item: ApplicationItemType) => {
    setCurrentApplication(item);
    setApplicationFeesModal(true);
  }

  function closeApplicationFeesModal() {
    setApplicationFeesModal(false);
    setCurrentApplication(null);
  }

  const handlePaymentListing = () => {
    setMakePaymentModal(true);
  }

  const handleMakeApplicationFeesPayment = async () => {
    setCanClick(false);
    try {
      const applicationFeesPaymentResponse = await createApplicationFeesPayment({
        variables: {
          applicationId: currentApplication?.id,
        }
      });
      if (applicationFeesPaymentResponse?.data?.createNDPSOrder) {
        const resp = applicationFeesPaymentResponse?.data?.createNDPSOrder;
        closeApplicationFeesModal();
        displayAtomPayNetzPortal(resp);
      }
      setCanClick(true);
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  const handleFilters = (filter: any) => {
    const applicationFilter = {
      limit: rowLimit,
      skip: (currentPage - 1) * rowLimit,
      ...filter,
    };
    setCurrFilter(applicationFilter);
    getApplicationsList({ variables: applicationFilter });
  };

  function closeRemarkModal() {
    setRemarkModal(false);
    remarkRef.current = null;
  };

  const closeModal = () => {
    resetStatusForm();
    reset({});
    clearErrors();
    setPaymentData(null);
    setShowQR(false);
    setMakePaymentModal(false);
    setMode('');
    closePaymentListingModal();
  };

  async function handleApplicantAdmissionFeePayment() {
    setCanClick(false);
    try {
      const admissionFeePaymentResponse = await createAdmissionFeePayment({
        variables: {
          applicationId: currentItem?.id,
        }
      });
      if (admissionFeePaymentResponse?.data?.createAdmissionFeeOrder) {
        const resp = admissionFeePaymentResponse?.data?.createAdmissionFeeOrder;
        setPaymentListingModal(false);
        closeModal();
        displayAtomPayNetzPortal(resp);
      }
      setCanClick(true);
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  const handleMakePayment = async (formData: paymentFormData) => {
    const mode = formData.mode.value;
    if (mode === PaymentMode.ONLINE && currentItem?.id) {
      handleApplicantAdmissionFeePayment();
    } else {
      setCanClick(false);
      let paymentData: paymentPayload = {
        applicationId: currentItem?.id,
        paymentDetail: {
          mode: mode,
        }
      }

      paymentData = await createPaymentDetailObject(formData, paymentData, false);
      setPaymentData(paymentData);
      setShowQR(true);
      setCanClick(true);
    }
  }

  const renderIcons = (item: ApplicationItemType) => {
    const showIcons =
      [ApplicationStatus.INCOMPLETE, ApplicationStatus.REJECTED, ApplicationStatus.APPLICATION_FEE_PENDING].includes(
        item.status
      ) && item?.admission?.status !== AdmissionStatus.CLOSE;

    return (
      <>
        <ViewFileIcon
          tooltipTitle={t("view-application.label")}
          onPress={() => {
            if (isApplicant) {
              history.push(`/dashboard/${item.id}/detail`);
            } else {
              history.push(`/application/list/limit/${limit}/page/1/${item.id}/detail`);
            }
          }}
        />
        {showIcons && (
          <>
            <Tooltip title={t("edit-qualification.label")}>
              <IconWrapper onClick={() => handleEditQualificationDetails(item)}>
                <Icon name="edit-education-detail" size={19} />
              </IconWrapper>
            </Tooltip>
            <EditIcon
              onPress={() => handleEditApplication(item)}
              tooltipTitle={t("edit-application.label")}
            />
          </>
        )}
        {[
          ApplicationStatus.PAYMENT_AWAITED,
          ApplicationStatus.PRE_ADMISSION_CANCELLATION,
          ApplicationStatus.POST_ADMISSION_CANCELLATION,
          ApplicationStatus.ADMISSION_CANCELLATION,
        ].includes(item.status) && (
            <MakePaymentIcon onPress={() => handleMakePaymentAction(item)} />
          )}
        {item.status === ApplicationStatus.APPLICATION_FEE_PENDING && (
          <MakePaymentIcon
            onPress={() => handleMakeApplicationFeesPaymentModal(item)}
            tooltipTitle={t('applicationFee.makePayment.text')}
          />
        )}
        {![
          ApplicationStatus.INCOMPLETE,
          ApplicationStatus.REJECTED,
          ApplicationStatus.CANCELLED,
          ApplicationStatus.APPLICATION_FEE_PENDING,
        ].includes(item.status) && (
            <ExportFileIcon
              onPress={() => exportApplication(item.id)}
              tooltipTitle={t('exportApplication.label')}
            />
          )}
      </>
    );
  };

  const onError = (message: string): void => {
    setAlertDetails({ message: getErrorMessage(message, t), level: ERROR });
  };

  const onClose = () => {
    setAddApplicationModal(false);
  }

  return (
    <LoadContentWrapper>
      <TopSectionWrapper>
        <SearchWrapper>
          <Search
            id="applicantSearch"
            handleChange={handleSearch}
            value={searching}
            label={t('search-application.text')}
          />
        </SearchWrapper>
        {isApplicant && resp && !resp.isLimitedAccess && (
          <SecondaryBtn
            label={t('add-application.label')}
            onPress={() => setAddApplicationModal(true)}
            lines={1}
          />
        )}
      </TopSectionWrapper>

      <TblContainer height={'100%'}>
        <TblHead setHeadCells={setHeadCells} />
        <>
          {!applicationsListLoading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: ApplicationItemType, i: number) => (
                    <TableRows key={`t${i}`}>
                      <TableCells value={item?.applicationId} />
                      <TableCells value={item?.admission?.institute?.name} />
                      <TableCells value={item?.admission?.course?.alias} />
                      <TableCells value={item?.admission?.class?.alias} />
                      {[ApplicationStatusLabel.REJECTED, ApplicationStatusLabel.BLOCKED].includes(
                        item.status,
                      ) && checkRemark(item?.statusActivity, item.status, remarkRef)?.exist ? (
                        <TableCells>
                          <RejectActionWrapper>
                            <NormaltextAtom
                              value={ApplicationStatusLabel[item.status] || item.status}
                              color={getApplicationStatusColor(item.status)}
                            />
                            <ActionIconWrapper>
                              <InfoSquareIcon
                                onPress={() => setRemarkModal(true)}
                                tooltipTitle={t(remarkRef?.current?.title)}
                              />
                            </ActionIconWrapper>
                          </RejectActionWrapper>
                        </TableCells>
                      ) : (
                        <TableCells
                          value={ApplicationStatusLabel[item.status] || item.status}
                          color={getApplicationStatusColor(item.status)}
                        />
                      )}

                      <TableCells align="right" width={'5%'}>
                        <ActionWrapper>{renderIcons(item)}</ActionWrapper>
                      </TableCells>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound
                  colspan={6}
                  maxHeight={0.6 * height}
                  icon={'application'}
                  label={'no-applications-submitted.label'}
                  label2={'add-application-continue.label'}
                />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>

      <Pagination
        pathName={`application/list`}
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        setRowLimit={setRowLimit}
        hidePagination={!!(totalCount && totalCount <= rowLimit)}
      />

      <NormalModal
        visibility={!makePaymentModal}
        setModalVisible={closePaymentListingModal}
        Headerpopup={t('payment.make-payment')}
        modalVisible={paymentListingModal}
        handleSave={handlePaymentListing}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'payment.proceed-payment'}
        width={700}
        maxWidth={'lg'}
        disabled={!currentFeeDetails?.feeBook || currentFeeDetails?.feeBook?.length === 0}>
        <ApplicationPayableListing
          feeBooks={currentFeeDetails?.feeBook}
          totalPayable={currentFeeDetails?.totalPayable}
          partialPayment={partialPayment}
          cancellationCharge={cancellationCharge}
        />
      </NormalModal>

      <NormalModal
        setModalVisible={closeRemarkModal}
        modalVisible={remarkModal}
        infoModal={true}
        Headerpopup={t(remarkRef?.current?.title)}
        preventRedirectOnClose={true}>
        <BlockRejectionReason data={remarkRef?.current} />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        modalVisible={makePaymentModal}
        handleSave={handleSubmit(handleMakePayment)}
        Headerpopup={showQR ? t('qrScan.label') : t('payment.mode')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={
          mode === PaymentMode.ONLINE || mode === '' ? 'Proceed' : t('Generate QR')
        }
        hideSubmit={!showQR}
        width={544}
        height={512}
        maxWidth={'lg'}
        paymentMode={true}>
        <PaymentModal
          isSubmitting={!canClick}
          control={control}
          errors={errors}
          reset={reset}
          data={paymentData}
          showQR={showQR}
          setValue={setValue}
          handleMakePayment={handleSubmit(handleMakePayment)}
          setMode={setMode}
          clearErrors={clearErrors}
          totalPayableAmount={
            cancellationCharge
              ? cancellationCharge
              : partialPayment
                ? partialPayment
                : currentFeeDetails?.totalPayable
          }
          paymentModes={currentItem?.admission?.allowedPaymentModes}
        />
      </NormalModal>

      {/* Add New Application by Invite Code / Prefix + Code */}
      {
        addApplicationModal && (
          <AddApplicationForm onError={onError} onClose={onClose} />
        )
      }

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeApplicationFeesModal}
        modalVisible={applicationFeesModal}
        width={640}
        Headerpopup={t('applicationFee.makePayment.text')}
        preventRedirectOnClose={true}
        hideCloseIcon={true}
        handleSave={handleMakeApplicationFeesPayment}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'payment.proceed-payment'}
        contentStyles={{ paddingTop: 0 }}>
        <ApplicationFeesFormWeb applicationDetail={currentApplication} />
      </NormalModal>
    </LoadContentWrapper>
  );
}

const TopSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 10;
`;

const SearchWrapper = styled.div`
  align-items: flex-start;
  max-width: 280px;
  margin-right: 8px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const RejectActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActionIconWrapper = styled.div`
  padding-left: 4px;
  padding-bottom: 2px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
