import {
  AccountObject,
  configureAccountStorageAndNavigate,
  getAllAccountsFromStorage,
} from "src/components/organism/Login/LoginHelpers";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { DEVICE_TYPE, USER_TYPE, UserType } from "src/constant";
import React, { ReactNode, useEffect, useState } from "react";
import { clipLongText, properCase } from "src/utils/utility";
import {
  flushAll,
  flushStorageFunction,
  getStorageFunction,
} from "src/components/services/storage-service";
import { keyValuesVar, userInformation } from "src/utils/manageState";
import { useHistory, useNavigate } from "src/routes/routing.web";

import AsyncStorage from "@react-native-async-storage/async-storage";
import ButtonGrp from "src/components/atoms/ButtonGroup/index.web";
import { Icon } from "src/components/atoms/Icon/Icon";
import ImageAtom from "src/components/atoms/ImageAtom/index.web";
import { KEYS } from "src/constant/key";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import { apolloClearStore } from "src/graphql/client";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";
import { useLogout } from "src/graphql/logout";

type Props = {
  onPress: () => void;
  closeFn: () => void;
  visibility: boolean;
};

function Profile(props: Props) {
  const { onPress, closeFn, visibility } = props;
  const { t } = useI18n();
  const history = useHistory();
  const navigate = useNavigate();
  const userinfo = userInformation();
  const { userType, userId } = userinfo;
  const { logoutUser } = useLogout();
  const [profilePhoto, setProfilePhoto] = useState<string>("");

  const [storedAccCards, setStoredAccountCards] = useState<AccountObject[]>([]);

  useEffect(() => {
    getAllAccountsFromStorage().then((accountCards) => {
      setStoredAccountCards(accountCards);
    });
  }, []);

  useEffect(() => {
    if (userinfo && userinfo.userDetail && userinfo.userDetail.photo) {
      setProfilePhoto(userinfo.userDetail.photo);
    }
  }, [userinfo]);

  function redirectToProfile() {
    navigate("/profile/0/view");
  }

  function fetchOtherAccounts(): AccountObject[] {
    if (userId && storedAccCards && storedAccCards?.length > 1) {
      return storedAccCards.filter(
        (acc: AccountObject) => acc.userId !== userId
      );
    }
    return [];
  }

  async function handleLogout() {
    try {
      const fcmToken = await getStorageFunction(KEYS.FCM_TOKEN);
      await logoutUser({ variables: { device: DEVICE_TYPE.WEB, fcmToken } });
    } catch (e) {
      console.log("logout failed", e);
    }
    clearGarbage();
    if (userType !== USER_TYPE.APPLICANT) {
      await flushAll();
    }
    // Reset Values on Logout
    await AsyncStorage.clear();
    // @ts-ignore
    userInformation({});
    keyValuesVar({});
    navigate("/login", { replace: true });
  }

  return (
    <ClickAwayListener onClickAway={closeFn}>
      <Tooltip title={t("profile.label")}>
        <ProfileWrapper onClick={onPress}>
          {!!profilePhoto ? (
            <ImageAtom
              source={{ uri: profilePhoto }}
              style={style.img}
              mode="cover"
            />
          ) : (
            <Icon name="profile-pic" size={40} />
          )}
          {visibility && (
            <ButtonGrp
              width={264}
              top={44}
              buttonData={[
                ...(userType !== USER_TYPE.EDBA_ADMIN
                  ? [
                    {
                      key: "profilepage",
                      value: t("profile.label"),
                      onPress: redirectToProfile,
                      prefixIcon: faUser,
                      customStyle: true,
                    },
                  ]
                  : []),
                ...fetchOtherAccounts().map((acc: AccountObject, i: number) => {
                  return {
                    key: `account${i}`,
                    onPress: async () =>
                      configureAccountStorageAndNavigate(acc, history),
                    prefixIcon: true,
                    customIcon: true,
                    imageURI: acc.profilePicture,
                    children: generateProfileCard(acc),
                  };
                }),
                {
                  key: "logout",
                  value: t("sidebar.native.logout"),
                  onPress: handleLogout,
                  prefixIcon: "logout",
                  customIcon: true,
                },
              ]}
            />
          )}
        </ProfileWrapper>
      </Tooltip>
    </ClickAwayListener>
  );
}

function clearGarbage() {
  apolloClearStore();
  flushStorageFunction(KEYS.ACCESS_TOKEN);
  flushStorageFunction(KEYS.USER_INFO);
  flushStorageFunction(KEYS.USER_INFORMATION);
  flushStorageFunction(KEYS.ASSIGNMENT_INDEX);
  flushStorageFunction(KEYS.ASSIGNMENT_SEARCH);
  flushStorageFunction(KEYS.CURRENT_COURSE_IDX);
}

function generateProfileCard(acc: AccountObject): ReactNode {
  return (
    <ProfileCard>
      <CardLabel value={properCase(clipLongText(acc.fullName, 24))} />
      {acc.userType === UserType.INSTITUTE_EMPLOYEE && (
        <CardLabel
          value={clipLongText(`Employee ID: ${acc?.employeeId || "-"}`, 28)}
        />
      )}
      {acc.userType === UserType.INSTITUTE_STUDENT && (
        <CardLabel value={clipLongText(`${acc?.className || "-"}`, 28)} />
      )}
      {acc.userType === UserType.INSTITUTE_STUDENT && acc?.division && (
        <CardLabel
          value={clipLongText(
            `Div: ${acc.division}${acc?.rollNo ? `  Roll: ${acc.rollNo}` : ""}`,
            28
          )}
        />
      )}
    </ProfileCard>
  );
}

const CardLabel = ({ value }: { value: string }) => (
  <NormaltextAtom value={value} lines={1} style={style.label} />
);

const ProfileWrapper = styled.div`
  cursor: pointer;
  position: relative;
`;

const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const style = {
  label: { marginBottom: 4 },
  img: { height: 40, width: 40 },
};

export default Profile;
