import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { colors } from 'src/styles/theme/styles';
import SmallTextAtom from '../Text/SmallTextAtom';

interface Props {
  label: string;
  onPress: () => void;
  lineHeight?: number;
  width?: any;
}

export default function SmallButton(props: Props) {
  const { onPress, label = 'Save', width, lineHeight = 32 } = props;
  return (
    <TouchableOpacity style={[styles.btnView, { width }]} onPress={onPress}>
      <SmallTextAtom value={label} color={colors.white} lineHeight={lineHeight} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  btnView: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 4,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: colors.primary,
  },
});
