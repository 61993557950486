import {
  faBackpack,
  faCommentAltDots,
  faHomeLgAlt,
  faUserAlt,
  faCalendarAlt,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBackpack as assign,
  faCommentAltDots as comm,
  faHomeLgAlt as home,
  faUserAlt as user,
  faCalendarAlt as timetable,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { colors } from 'src/styles/theme/styles';
import { useHistory, useLocation } from 'src/routes/routing';
import { isIOS, isWeb } from 'src/constant/device';
import { EMPLOYEE_TYPES, FOOTER_MENU, USER_TYPE } from 'src/constant';
import { userInformation } from 'src/utils/manageState';

export const MobileFooter = () => {
  const path = useLocation().pathname;
  const activePath = path;

  const [current] = useState(activePath || FOOTER_MENU[0]);
  const history = useHistory();
  let toHide = false;
  const currentUserObject = userInformation();

  const { userType, userDetail } = currentUserObject;
  toHide = userType === USER_TYPE.EMPLOYEE && !userDetail?.type?.includes(EMPLOYEE_TYPES.TEACHING);
  return (
    <View style={[styles.container]}>
      <View style={styles.tabsView}>
        <TouchableOpacity
          onPress={() => {
            history.push('/dashboard');
          }}
          style={styles.tabItems}>
          <FontAwesomeIcon
            icon={path.includes(FOOTER_MENU[0]) ? home : faHomeLgAlt}
            color={path.includes(FOOTER_MENU[0]) ? colors.primaryColor : colors.secondaryText}
            size={27}
          />
        </TouchableOpacity>
        {!toHide ? (
          <TouchableOpacity
            onPress={() => {
              history.push('/assignments/pending');
            }}
            style={styles.tabItems}>
            <FontAwesomeIcon
              icon={path.includes(FOOTER_MENU[1]) ? assign : faBackpack}
              color={path.includes(FOOTER_MENU[1]) ? colors.primaryColor : colors.secondaryText}
              size={21}
            />
          </TouchableOpacity>
        ) : null}

        <TouchableOpacity
          style={styles.tabItems}
          onPress={() => {
            history.push('/communication/news');
          }}>
          <FontAwesomeIcon
            icon={path.includes(FOOTER_MENU[2]) ? comm : faCommentAltDots}
            color={path.includes(FOOTER_MENU[2]) ? colors.primaryColor : colors.secondaryText}
            size={24}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.tabItems}
          onPress={() => {
            history.push('/profile');
          }}>
          <FontAwesomeIcon
            icon={path.includes(FOOTER_MENU[3]) ? user : faUserAlt}
            color={path.includes(FOOTER_MENU[3]) ? colors.primaryColor : colors.secondaryText}
            size={24}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.tabItems}
          onPress={() => {
            history.push('/time-table');
          }}>
          <FontAwesomeIcon
            icon={path.includes(FOOTER_MENU[4]) ? timetable : faCalendarAlt}
            color={path.includes(FOOTER_MENU[4]) ? colors.primaryColor : colors.secondaryText}
            size={24}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    position: isWeb ? 'fixed' : 'absolute',
    bottom: 0,
    height: isIOS ? 72 : 62,
    padding: 10,
    borderBottomWidth: 1,
    borderTopWidth: 1,

    borderColor: colors.lighterBorder,
    backgroundColor: colors.white,
    zIndex: 1,
  },
  tabsView: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: 8,
  },
  tabItems: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
