import { ADD_SUCCESS, UPDATE_SUCCESS } from "src/constant/message";
import { FieldValues, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  TAdmissionLinksForm as TForm,
  AdmissionLinkHelpers as helpers,
} from "./AdmissionLinkHelpers";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { TAdmissionConfig } from "src/components/organism/Admission/AdmissionDetail/AdmissionDetailTypes";
import { TFormProps } from "src/types";
import formJSON from "src/form-json/admission/Links/admission-links-form.json";
import styled from "styled-components";
import { useCreateAdmissionLink } from "src/graphql/admission";
import { useI18n } from "src/i18n/hooks";

type Props = TFormProps<TForm> & { admissionConfig: TAdmissionConfig };

const { transformInitialFormData, fieldMap } = helpers;

const AdmissionLinksForm = (props: Props) => {
  const { form, onSuccess, onError, onClose, admissionConfig } = props;
  const { data: initialValue } = form;
  const {
    subjectGroupOptions,
    admission,
    applicantTypes,
    batch,
    class: classID,
    course,
    university,
  } = admissionConfig;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialValue ? transformInitialFormData(initialValue) : {},
  });

  const { createAdmissionLink } = useCreateAdmissionLink();

  const [elements, setElements] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { fields } = elements ?? {};
  const editMode: boolean = form.mode === "EDIT";

  useEffect(() => {
    toggleFieldInteraction(editMode, [
      fieldMap.subjectGroup,
      fieldMap.prefix,
      fieldMap.applicantType,
    ]);
    formJSON[0].fields[fieldMap.subjectGroup].option =
      subjectGroupOptions as any;
    formJSON[0].fields[fieldMap.applicantType].option = applicantTypes.map(
      (a) => ({ label: a, value: a })
    ) as any;
    setElements({ ...formJSON[0] });
    return () => {
      clearOptions([fieldMap.subjectGroup, fieldMap.applicantType]);
      toggleFieldInteraction(false, [
        fieldMap.subjectGroup,
        fieldMap.prefix,
        fieldMap.applicantType,
      ]);
    };
  }, [editMode, applicantTypes]);

  function toggleFieldInteraction(disabled: boolean, indices: number[]) {
    indices.forEach((index) => {
      formJSON[0].fields[index].disabled = disabled;
    });
  }

  function clearOptions(indices: number[]) {
    indices.forEach((index) => {
      formJSON[0].fields[index].option = [];
    });
  }

  const onSubmitFn = async (formData: FieldValues) => {
    if (formData) {
      setLoading(true);
      try {
        const payload = {
          admission,
          state: "OPEN",
          applicantType: formData.applicantType.value,
          batch,
          university,
          course,
          class: classID,
          expiryDate: formData.expiryDate,
          prefix: formData.prefix,
          subjectGroup: formData.subjectGroup.value,
          ...(initialValue && initialValue?.id ? { id: initialValue.id } : {}),
        };
        const response = await createAdmissionLink({
          variables: { payload },
        });
        if (response && response?.data?.createAdmissionLink) {
          onSuccess(
            editMode
              ? UPDATE_SUCCESS.ADMISSION_LINK
              : ADD_SUCCESS.ADMISSION_LINK
          );
        }
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      modalVisible={true}
      Headerpopup={
        editMode ? t("editAdmissionLink.text") : t("addAdmissionLink.text")
      }
      width={560}
      handleSave={handleSubmit(onSubmitFn)}
    >
      <Container>
        {fields &&
          fields.map((field: any) => (
            // @ts-ignore
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
            />
          ))}
      </Container>
    </NormalModal>
  );
};

const Container = styled.div`
  height: 384px;
`;

export default AdmissionLinksForm;
