import {
  AddEditLectureFormProps,
  ReactBigCalendarEvent,
} from "../scheduleTypes";
import React, { useEffect, useState } from "react";
import { format, isValid } from "date-fns";
import {
  generateWeeklyEvents,
  isEventInFuture,
  makeWeeklyClassScheduleQueryVariable,
} from "../scheduleHelpers";
import { useHistory, useParams } from "src/routes/routing.web";

import AddEditLectureForm from "./AddEditLectureForm";
import { DT } from "src/constant/dateTime";
import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import ModalTabs from "src/components/atoms/Switch/ModalTabs";
import ScheduleTimeline from "src/components/atoms/ScheduleTimeline/index.web";
import ScheduleToolbar from "src/components/organism/Schedule/ScheduleComponents/ScheduleToolbar.web";
import { SlotInfo } from "react-big-calendar";
import { defaultPagingURLSegment } from "src/components/molecules/Navigation/SidebarManifest";
import styled from "styled-components";
import { useGetSchedule } from "src/graphql/schedule";
import { useI18n } from "src/i18n/hooks";
import { useSetHeadingForBatch } from "../../Courses/BatchStudents/BatchStudentsHelpers";

type TimetableProps = {};

export default function BatchwiseTimetable(props: TimetableProps) {
  const { t } = useI18n();
  const history = useHistory();
  const { batchId, division }: Record<string, string> = useParams();
  const { getSchedule, scheduleData, scheduleLoading, scheduleRefetch } =
    useGetSchedule();

  // Sets breadcrumb & returns classId (utilised to prevent additional API call)
  const classId: string = useSetHeadingForBatch(batchId, division);

  const [events, setEvents] = useState<ReactBigCalendarEvent[]>([]);
  const [pickedDate, setPickedDate] = useState<Date>(new Date());
  const [lectureModal, setLectureModal] = useState<Boolean>(false);
  const [lectureFormInitialValues, setLectureFormInitialValues] =
    useState<AddEditLectureFormProps["initialValues"]>();

  useEffect(() => {
    if (pickedDate && isValid(pickedDate)) {
      handleFetch();
    }
  }, [pickedDate]);

  useEffect(() => {
    if (scheduleData && scheduleData?.getFullScheduleByDay?.data) {
      const eventsArray = generateWeeklyEvents(
        scheduleData.getFullScheduleByDay.data
      );
      setEvents(eventsArray);
    }
  }, [scheduleData]);

  const handleFetch = () => {
    getSchedule({
      variables: makeWeeklyClassScheduleQueryVariable(
        pickedDate,
        batchId,
        division
      ),
    });
  };

  const handleRefetch = () => {
    if (scheduleRefetch) {
      scheduleRefetch({ fetchPolicy: "network-only" });
    }
  };

  const changeTabs = (tab: number) => {
    tab === 1 &&
      history.push(
        `/classroom/${batchId}/${division}/students/${defaultPagingURLSegment}`
      );
  };

  const addNewLecture = () => {
    setLectureFormInitialValues({
      startDate: format(new Date(), DT.DATE_FORMAT_SLASH),
    });
    setLectureModal(true);
  };

  const closeLectureModal = () => {
    setLectureModal(false);
  };

  const onSelectSlot = (slotInfo: SlotInfo): void => {
    if (slotInfo.action === "select" && isEventInFuture(slotInfo.start)) {
      setLectureFormInitialValues({
        startDate: format(slotInfo.start, DT.DATE_FORMAT_SLASH),
        startTime: format(slotInfo.start, DT.TIME_24_HOUR_WITH_COLON),
        endTime: format(slotInfo.end, DT.TIME_24_HOUR_WITH_COLON),
      });
      setLectureModal(true);
    }
  };

  return (
    <LoadContentWrapper>
      <ModalWrapper>
        <ModalTabs
          option1={t("students.label")}
          option2={t("timetable.label")}
          selectionMode={2}
          onChange={changeTabs}
        />
      </ModalWrapper>
      <ScheduleToolbar
        buttonProps={{
          onButtonClick: addNewLecture,
          text: "addLecture.text",
        }}
        pickedDate={pickedDate}
        setPickedDate={setPickedDate}
      />
      <ScheduleTimeline
        pickedDate={pickedDate}
        events={events}
        showActions={true}
        refetchLectures={handleRefetch}
        onSelectSlot={onSelectSlot}
        details={{ batchId, division, classId }}
      />
      {lectureModal && (
        <AddEditLectureForm
          mode={"ADD"}
          title={t("addLecture.text")}
          closeModal={closeLectureModal}
          refetchLectures={handleRefetch}
          details={{ batchId, division, classId }}
          initialValues={lectureFormInitialValues}
        />
      )}
    </LoadContentWrapper>
  );
}

const ModalWrapper = styled.div`
  margin-bottom: 16px;
`;
