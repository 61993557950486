import React, { useState, useEffect } from 'react';
import { TextField,  Popper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { colors } from 'src/styles/theme/styles';
import SmallTextAtom from '../Text/SmallTextAtom';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import Check from '../CheckBox/index.web';
import Chip from '../Chip';
import { clipLongText } from 'src/utils/utility';
import styled from 'styled-components';
import { isNative } from 'src/constant/device';

export default function SearchDropdown(props: any) {
  const {
    noOptionsText = 'No Result',
    options = [],
    header = '',
    value,
    onChange,
    label = '',
    defaultLabel = '',
    searchSelectScroll = null,
    searchSelectOnchange = null,
    name = '',
    width,
    height,
    disabled,
    multipleSelection,
    isEdit,
    chipEdit = true,
    notefooter = '',
    limitTags = 2,
    textCount = 22,
    maxHeight,
    autoCompleteProps = {
      disabledPortal: false
    }
  } = props;
  const { theme } = useThemeSystem();
  const [data, setData] = useState([]);

  const [autoCompleteOptions, setAutoCompleteOptions] = useState(options);
  const [pageCount, pageCounter] = useState(1);
  const [isScrollDone, setIsScrollDone] = useState(false);
  const [proxyOptions, setProxyOptions] = useState(options);
  const [defaultL, setDefaultL] = useState(defaultLabel);
  const [labels, setLabels] = useState(label);
  const { t } = useI18n();

  const onDelete = (title: string) => () => {
    let newData = [...data];
    setData((value) => value.filter((v) => v.label !== title));
    newData = newData.filter((item) => item.label !== title);
    onChange(name, newData);
  };

  const handleScroll = async (e: any) => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      let queryData = await searchSelectScroll(pageCount, name);
      if (queryData && queryData.options) {
        let options = queryData.options;
        let newOptions = autoCompleteOptions.concat(options);
        setAutoCompleteOptions(newOptions);
        setProxyOptions(newOptions);
        pageCounter(pageCount + 1);
      }
      if (queryData && !queryData.hasNextPage) {
        setIsScrollDone(true);
      }
    }
  };

  const searchInputOnchange = async (e: any) => {
    let textvalue = e.target.value;
    if (textvalue.length === 0) {
      setAutoCompleteOptions(proxyOptions);
    } else {
      let queryData = await searchSelectOnchange(textvalue, name);
      if (queryData && queryData.options) {
        let options = queryData.options;
        setAutoCompleteOptions(options);
      }
    }
  };

  function inputOnBlur() {
    setAutoCompleteOptions(proxyOptions);
  };

  useEffect(() => {
    // value needed in array for edit for multiple selection
    if (multipleSelection) {
      if (value && value.length > 0) {
        setData(value);
      } else {
        setData([]);
      }
    }
  }, [value]);
  
  useEffect(() => {
    setProxyOptions(options);
    setAutoCompleteOptions(options);
  }, [options]);

  const wrapperStyle:any = {
    opacity: disabled ? 0.5 : 1,
  }

  if(autoCompleteProps?.disablePortal){
    wrapperStyle.zIndex = 'initial' 
  }
  
  return (
    <WrapperStyle>
      {Boolean(header) && (
        <SearchWrapper>
          <SmallTextAtom value={t(header)} color={theme?.searchDropdown?.topLabel} />
        </SearchWrapper>
      )}

      {multipleSelection ? (
        <Autocomplete
          disablePortal={autoCompleteProps?.disablePortal}
          size="small"
          sx={{
            width: width,
            '& .MuiAutocomplete-inputRoot': {
              padding: isNative ? '' : '6px 51px 6px 6px !important',
              '& .MuiAutocomplete-tag': {
                margin: '-3px 3px 2px',
              },
            },
          }}
          PopperComponent={({ style, ...props }) => (
            <Popper
              {...props}
              style={{ ...style, height: 0 }}

              // MUI Popper Style override https://github.com/mui/material-ui/issues/21661#issuecomment-1198077932
            />
          )}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          limitTags={limitTags}
          disabled={disabled}
          multiple={multipleSelection}
          ListboxComponent={listboxProps => (
            <ListboxComponent multiple={multipleSelection}>
              <ul {...listboxProps} onScroll={!isScrollDone ? e => handleScroll(e) : null} />
            </ListboxComponent>
          )}
          ListboxProps={{
            style: {
              maxHeight: maxHeight && maxHeight,
              wordBreak: 'break-word',
              width: width || null,
              height: height || null,
            },
          }}
          id="checkboxes-tags-demo"
          options={searchSelectOnchange ? autoCompleteOptions : options}
          disableCloseOnSelect
          getOptionLabel={option => option.label}
          popupIcon={<FontAwesomeIcon icon={faCaretDown} color={colors.secondaryText} />}
          noOptionsText={noOptionsText}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <CheckWrap>
                  <Check
                    same={false}
                    checked={
                      data?.length > 0 ? data.some((e: any) => e.label === option.label) : false
                    }
                    style={{ marginRight: 8 }}
                    color={'white'}
                  />
                  {option.label}
                </CheckWrap>
              </li>
            );
          }}
          renderInput={params => (
            <TextField
              {...params}
              onChange={e => searchInputOnchange(e)}
              onBlur={inputOnBlur}
              inputRef={props.inputRef}
              variant="outlined"
              label={(defaultL && defaultL) || labels}
              InputLabelProps={{ shrink: false }}
              size="small"
            />
          )}
          renderTags={(tagValue, getTagProps) => {
            return data?.length > 0
              ? data.map((v, index) => (
                  <TagWrapper
                    style={{
                      marginBottom: data?.length === data?.length - 1 ? 0 : 2,
                    }}>
                    <Chip
                      {...getTagProps({ index })}
                      label={clipLongText(v.label, textCount)}
                      onPress={onDelete(v.label)}
                      close={chipEdit}
                      height={24}
                    />
                  </TagWrapper>
                ))
              : null;
          }}
          onChange={(e, newValue) => {
            if (isEdit) {
              const newAdded = newValue[newValue?.length - 1];
              const isFound = newValue?.filter(element => element?.value === newAdded?.value);
              if (isFound && isFound.length > 1) {
                newValue = newValue?.filter(a => a?.value !== newAdded?.value);
              }
            }
            setData(newValue);
            onChange(name, newValue);
          }}
          value={data}
        />
      ) : (
        <>
          <Autocomplete
            disablePortal={autoCompleteProps?.disablePortal}
            size="small"
            sx={{ width: width}}
            PopperComponent={({ style, ...props }) => {
              return <Popper {...props} style={{ ...style, height: 0 }} keepMounted={true} />;
            }}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            disabled={disabled}
            ListboxComponent={listboxProps => (
              <ListboxComponent multiple={multipleSelection}>
                <ul {...listboxProps} onScroll={!isScrollDone ? e => handleScroll(e) : null} />
              </ListboxComponent>
            )}
            ListboxProps={{
              style: {
                maxHeight: maxHeight && maxHeight,
                wordBreak: 'break-word',
              },
            }}
            blurOnSelect={true}
            options={searchSelectOnchange ? autoCompleteOptions : options}
            {...(autoCompleteProps ?? {})}
            getOptionLabel={option => option.label || ''}
            popupIcon={<FontAwesomeIcon icon={faCaretDown} color={colors.secondaryText} />}
            noOptionsText={noOptionsText}
            renderInput={params => (
              <TextField
                {...params}
                onBlur={inputOnBlur}
                inputRef={props.inputRef}
                variant="outlined"
                label={(defaultL && defaultL) || labels}
                InputLabelProps={{ shrink: false }}
                onChange={e => searchInputOnchange(e)}
                size="small"
              />
            )}
            onChange={onChange}
            value={value}
          />
          {notefooter !== '' && (
            <BottomText>
              <SmallTextAtom value={t(notefooter)} color={theme?.input?.bottomLabel} />
            </BottomText>
          )}
        </>
      )}
    </WrapperStyle>
  );
}


const WrapperStyle = styled.div`
  .MuiAutocomplete-root {
    .Mui-disabled {
      cursor: not-allowed;
      .MuiAutocomplete-endAdornment {
        opacity: 0.5;
      }
    }
    .MuiOutlinedInput-root {
      padding: 5px 65px 5px 6px !important;
      font-size: 15px;
    }
  }
`;

const SearchWrapper = styled.div`
  margin-bottom: 4;
`;

const TagWrapper = styled.div`
  display: inline-block;
  margin-left: 4px;
`;

const CheckWrap = styled.div`
  flex: 1; 
  flex-direction: row;
  display: flex;
`;

const BottomText = styled.div`
  margin-top: 4px;
`;

const ListboxComponent = styled.div`
  .MuiAutocomplete-listbox {
    li {
      &:hover {
        background-color: ${(props:any) => props.multiple ? 'rgb(234, 242, 250)' : colors.primaryColor};
        color: ${(props:any) => (props.multiple ? colors.primaryText : colors.white)};
      }
      &:active {
        background-color: ${(props:any) => (props.multiple ? 'rgb(234, 242, 250)' : colors.primaryColor)};
        color: ${(props:any) => (props.multiple ? colors.primaryText : colors.white)};
      }
      &[aria-selected="true"] {
        background-color: ${(props:any) => (props.multiple ? 'rgb(234, 242, 250)' : colors.primaryColor)};
        color: ${(props:any) => (props.multiple ? colors.primaryText : colors.white)};
      }
    }
  }
`;