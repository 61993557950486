import React from 'react';
import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import styled from 'styled-components/native';
import DownloadFile from 'src/components/atoms/DownloadFile/index';
import { isEmpty } from 'lodash';
import { DetailStatusEnum, FileCategory } from 'src/constant';
import { isNative } from 'src/constant/device';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { fonts } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';

const FileView: React.FC<UseFieldApiConfig> = (props: UseFieldApiConfig) => {
  const { t } = useI18n();
  const field = useFieldApi(props);
  let value = field.input.value;
  if (field.fileAtfield) {
    const docField = useFieldApi({ name: field.fileAtfield });
    if (Array.isArray(docField.input.value)) {
      value = docField.input.value.find((doc) => doc.type == field.documentType);
    } else {
      value = docField.input.value;
    }
  }
  const showFieldLabel: boolean =
    isNative && field?.category === FileCategory.Experience && !!field?.label;

  return (
    <ContentContainer>
      {!isEmpty(value) && (
        <DownloadFileWrapper>
          {showFieldLabel && (
            <LabelWrapper>
              <SmallTextAtom
                value={t(field?.label)}
                fontFamily={fonts.regular}
              />
            </LabelWrapper>
          )}
          <DownloadFile
            fileData={value}
            customStyle={{ maxWidth: "80%" }}
            showIndicatorBadge={
              value?.status == DetailStatusEnum.SUBMITTED_FOR_REVIEW
            }
          />
        </DownloadFileWrapper>
      )}
    </ContentContainer>
  );
}

export default FileView;

const ContentContainer = styled.View`
  display: flex;
`;

const LabelWrapper = styled.View`
  padding-bottom: 10px;
`;

const DownloadFileWrapper = styled.View`
  padding-top: 15px;
`;