import { Control, FieldValues } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { clipLongText, mergeBatchClassLabel } from 'src/utils/utility';

import { AUTOCOMPLETE_MODULE } from 'src/constant';
import Chip from 'src/components/atoms/Chip';
import Element from 'src/components/molecules/Forms/ApplicationElement';
import { colors } from 'src/styles/theme/styles';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { getFilteredSubjectGroups } from 'src/components/services';
import { getSubjectGroups } from 'src/graphql/academics/subject-groups';
import groupAudience from 'src/form-json/payment/subject-group-audience.json';
import { isWeb } from 'src/constant/device';
import { useQuery } from '@apollo/client';

interface SubjectGroupAudienceProps {
  audienceData: Array<string>;
  removeAudience: Function;
  handleChecked: Function;
  subjectGroupFormControl: Control<FieldValues, object>;
  subjectGroupFormError: any;
  parentType: string;
  subjectGroupList: Array<string>;
  isEdit: boolean;
}

export default function SubjectGroupAudience(props: SubjectGroupAudienceProps) {
  const {
    subjectGroupFormControl,
    subjectGroupFormError,
    audienceData,
    removeAudience,
    handleChecked,
    parentType,
    subjectGroupList,
    isEdit,
  } = props;

  const [selectedSubjectGroups, setSelectedSubjectGroups] = useState([]);
  const [filteredSubjectGroups, setFilteredSubjectGroups] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const [elements, setElements] = useState<any>({});
  const [batch, setBatch] = useState<any>({});
  const [batches, setBatches] = useState<any>({});
  const [searchData, setSearchData] = useState('');

  const { fields }: any = elements ?? {};
  const allBatches = useQuery(getBatchQuery, {
    variables: { limit: 0 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    return () => {
      groupAudience[0].fields[0].fieldVisible = true;
      groupAudience[0].fields[0].required = true;
      groupAudience[0].fields[0].textHead = "batchClass.required.label";
    }
  }, []);

  useEffect(() => {
    onLoadFunction();
  }, [allBatches.data]);

  useEffect(() => {
    loadSelectedSubjectGroups();
  }, [audienceData]);

  useEffect(() => {
    if (batch?.value) {
      fetchSubjectGroups(batch.value);
    }
  }, [batch]);

  useEffect(() => {
    onloadEditFunction();
  }, [audienceData]);

  function onloadEditFunction() {
    if (isEdit && audienceData?.length > 0) {
      groupAudience[0].fields[0].required = false;
      groupAudience[0].fields[0].textHead = "batchClass.label";
    } else {
      groupAudience[0].fields[0].required = true;
      groupAudience[0].fields[0].textHead = "batchClass.required.label";
    }
  }

  function onLoadFunction() {
    if (allBatches.loading === false) {
      if (parentType === 'CONCESSION' && subjectGroupList.length !== 0) {
        groupAudience[0].fields[0].fieldVisible = false;
        groupAudience[0].fields[0].required = false;
        setFilteredSubjectGroups(subjectGroupList);
      } else {
        groupAudience[0].fields[0].fieldVisible = true;
        groupAudience[0].fields[0].required = true;
      }
      let getdata = [] as any;
      getdata = mergeBatchClassLabel(allBatches.data?.batches?.data);
      groupAudience[0].fields[0].option = getdata;
      setBatches(getdata);
      onloadEditFunction();
      setElements(groupAudience[0]);
    }
  }

  function loadSelectedSubjectGroups() {
    if (audienceData && audienceData.length > 0) {
      const subject_groups = audienceData.map((item: any) => item.audience);
      setSelectedSubjectGroups(subject_groups);
    } else {
      setSelectedSubjectGroups([]);
    }
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      setIsDataAvailable(false);
      data = isWeb ? data : batches.find((bt: any) => bt.value === data);
      setBatch(data);
    }
  }

  async function fetchSubjectGroups(batchID: string) {
    const response = await getFilteredSubjectGroups(
      { filters: { batch: { eq: batchID } } }, getSubjectGroups
    );
    if (response?.options) {
      setFilteredSubjectGroups(response.options);
      setIsDataAvailable(true)
    }
  }

  const handleCheckChild = (item: string) => {
    handleChecked(item, batch, filteredSubjectGroups.find(i => i.id == item));
  };

  async function searchBoxOnChange(data: string) {
    setSearchData(data);
    if (data.length > 0) {
      if (parentType === 'CONCESSION') {
        const response = filteredSubjectGroups.filter((item: any) => item?.name?.toLowerCase()?.includes(data));
        setFilteredSubjectGroups(response);
        setIsDataAvailable(true);
      } else {
        let param = {
          filters: {
            batch: { eq: batch.value },
            name: { regex: data },
          },
        };
        const response = await getFilteredSubjectGroups(param, getSubjectGroups);
        if (response?.options) {
          setFilteredSubjectGroups(response.options);
          setIsDataAvailable(true);
        }
      }
    } else {
      if (parentType === 'CONCESSION') {
        setFilteredSubjectGroups(subjectGroupList);
        setIsDataAvailable(true);
      } else {
        fetchSubjectGroups(batch.value);
      }
    }
  }

  return (
    <>
      <View style={styles.containerView}>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View key={`parentele${i}`}>
              <Element
                field={field}
                control={subjectGroupFormControl}
                errors={subjectGroupFormError}
                handleCustomSelect={handleCustomSelect}
                handleCheck={handleCheckChild}
                checked={selectedSubjectGroups}
                checkboxTableData={filteredSubjectGroups}
                searchMaxWidth={350}
                searchBoxOnChange={searchBoxOnChange}
                searchData={searchData}
                manualSelection={false}
                checkboxTableHeight={'auto'}
                checkboxTableMaxHeight={220}
                isDataAvailable={isDataAvailable}
              />
            </View>
          ))
        ) : (
          <View style={styles.spinnerView} />
        )}
      </View>
      <View style={[styles.chipWrapper, { marginTop: 15 }]}>
        {audienceData && audienceData.length > 0 &&
          audienceData?.map((chip: any, index: number) => (
            <View style={[styles.chipView]} key={`audience${index}`}>
              <Chip
                label={isWeb ? chip?.text : clipLongText(chip?.text, 36)}
                onPress={() => removeAudience(index)}
                borderRadius={isWeb ? 4 : 20}
                chipFontSize={1.2}
              />
            </View>
          ))}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
  containerView: {
    borderWidth: 1,
    borderColor: colors.borderColor,
    borderStyle: 'dashed',
    borderRadius: 4,
    padding: 8
  },
  chipWrapper: { flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 },
  chipView: { marginRight: 8, marginBottom: 8 },
});
