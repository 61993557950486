import { religions, studentIdentifier } from './dd-options';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import dataTypes from '@data-driven-forms/react-form-renderer/data-types';
import { FormSchema, ViewMode } from 'src/components/molecules/Forms/types';
import { getComponentType, resolveOthers, setOtherInitialValue } from './util';
import { FormOptions } from '@data-driven-forms/react-form-renderer/renderer-context';
import { regexObject } from 'src/constant/regex';
import { userInformation } from 'src/utils/manageState';
import { USER_TYPE } from 'src/constant';
import { DropdownOptionsType } from 'src/types';
import { isObject } from 'src/utils/utility';

export const others = (viewMode: ViewMode, categoriesOptions: DropdownOptionsType[] = [], isInstituteAdmin?: boolean): FormSchema => ({
    name: 'otherDetails',
    label: 'otherDetails.label',
    viewMode,
    headerData: {
        title: 'otherDetails.label',
        skip: true,
        icon: require('src/assets/common/headerOnboardingIcons/v1/other-3x.png'),
        progress: 1,
    },
    fields: [
        {
            name: 'religionDetails',
            label: 'otherDetails.label',
            component: "custom-sub-form",
            section: "left",
            fields: [
                {
                    name: 'religion',
                    label: 'form.religion.label',
                    type: 'advancedSelect',
                    options: religions,
                    component: getComponentType(viewMode, "select"),
                    isRequired: false,
                    otherFieldName: "otherReligion",
                    resolveProps: (_props, _field, formOptions) => {
                        return resolveOthers(_props, _field, formOptions, viewMode);
                    },
                },
                {
                    name: 'otherReligion',
                    label: "form.others.text",
                    ignoreField: true,
                    ignoreInProfileView:true,
                    type: 'text',
                    autoCapitalize: "words",
                    component: viewMode == ViewMode.READ ? 'none' : 'custom-text-field',
                    condition: {
                        when: "religion",
                        is: "Others"
                    },
                    validate: [
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.STRING_ONLY,
                            message: 'error.invalidInput'
                        }
                    ],
                    setInitialValue: (props: any, formOptions: FormOptions) => {
                        setOtherInitialValue(props, formOptions, 'religion', "otherReligion")
                    },
                },

                {
                    name: 'category',
                    label: 'form.category.required.label',
                    options: categoriesOptions,
                    component: getComponentType(viewMode, "custom-select"),
                    isRequired: true,
                    validate: !isInstituteAdmin ? [
                        {
                            type: validatorTypes.REQUIRED,
                            message: 'error.category.required',
                        },
                    ] : [],
                },
                {
                    name: 'caste',
                    label: 'form.caste.label',
                    type: 'string',
                    component: getComponentType(viewMode, 'custom-text-field'),
                    autoCapitalize: "words",
                    validate: [
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.STRING_ONLY_WITH_WHITESPACE,
                            message: 'error.invalidInput'
                        }
                    ],
                },
                {
                    name: 'none',
                    component: 'none',
                    condition: {
                        when: "religion",
                        is: 'Others',
                        then: {
                            visible: false
                        },
                        else: {
                            visible: true
                        }
                    },
                },
                {
                    name: 'isPhysicallyChallenged',
                    label: 'form.physicallyChallenged.label',
                    component: getComponentType(viewMode, "select"),
                    dataType: dataTypes.BOOLEAN,
                    options: [
                        {
                            value: true,
                            label: 'Yes'
                        },
                        {
                            value: false,
                            label: 'No'
                        },
                    ],
                },
                {
                    name: 'physicallyChallengedDescription',
                    label: "form.disabilityDetails.required.label",
                    type: 'text',
                    autoCapitalize: "words",
                    component: getComponentType(viewMode, 'custom-text-field'),
                    condition: {
                        when: "isPhysicallyChallenged",
                        is: true,
                    },
                    validate: [
                        {
                            type: validatorTypes.REQUIRED,
                            message: 'error.disabilityDetails.required',
                        },
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.STRING_ONLY_WITH_SPECIAL,
                            message: 'error.invalidInput'
                        }
                    ],
                },
                {
                    name: 'aadhaarId',
                    label: 'aadhaarNumber.required.label',
                    component: getComponentType(viewMode, 'custom-text-field'),
                    isRequired: false,
                    type: "number",
                    dataType: dataTypes.INTEGER,
                    validate: !isInstituteAdmin ? [
                        {
                            type: validatorTypes.REQUIRED,
                            message: 'error.aadhaarNumber.required',
                        },
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.AADHAAR_INVALID,
                            message: 'error.aadhaarNumber.invalid',
                        }
                    ] : [
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.AADHAAR_INVALID,
                            message: 'error.aadhaarNumber.invalid',
                        }
                    ],
                    convertToString: true,
                },
                {
                    name: "studentIdentifier",
                    label: 'studentIdentifier.label',
                    component: getComponentType(viewMode, 'select'),
                    options: studentIdentifier,
                    ignoreField: true,
                    ignoreInProfileView: true,
                    resolveProps: (props: any, field: any, formOptions: FormOptions) => {
                        const { values } = formOptions.getState();
                        if (values && isObject(values) && !field.input.value) {
                          if (values.saralId) {
                            formOptions.change("studentIdentifier", "Saral ID");
                          } else if (values.abcId) {
                            formOptions.change("studentIdentifier", "ABC ID");
                          }
                        }
                    },
                    condition: {
                        when: "studentIdentifier",
                        is: () => {
                            const currentUserType = userInformation()?.userType;
                            return currentUserType === USER_TYPE.STUDENT || currentUserType === USER_TYPE.APPLICANT;
                        }
                    }
                },
                {
                    name: 'saralId',
                    label: 'saralId.label',
                    component: getComponentType(viewMode, 'custom-text-field'),
                    isRequired: false,
                    type: "string",
                    dataType: dataTypes.STRING,
                    validate: [
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.SARAL_ID_INVALID,
                            message: 'error.saralId.invalid',
                        }
                    ],
                    condition: {
                        and: [
                            {
                                when: "studentIdentifier",
                                is: "Saral ID",
                            },
                            {
                                when: "studentIdentifier",
                                is: () => {
                                    const currentUserType = userInformation()?.userType;
                                    return currentUserType === USER_TYPE.STUDENT || currentUserType === USER_TYPE.APPLICANT;
                                }
                            }
                        ]
                    },
                },
                {
                    name: 'abcId',
                    label: 'abcId.label',
                    component: getComponentType(viewMode, 'custom-text-field'),
                    isRequired: false,
                    type: "string",
                    dataType: dataTypes.STRING,
                    validate: [
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.ABC_ID_INVALID,
                            message: 'error.abcId.invalid',
                        }
                    ],
                    condition: {
                        and: [
                            {
                                when: "studentIdentifier",
                                is: "ABC ID",
                            },
                            {
                                when: "studentIdentifier",
                                is: () => {
                                    const currentUserType = userInformation()?.userType;
                                    return currentUserType === USER_TYPE.STUDENT || currentUserType === USER_TYPE.APPLICANT;
                                }
                            }
                        ]
                    },
                },
                {
                    name: 'nationality',
                    label: 'form.nationality.required.label',
                    isRequired: true,
                    component: getComponentType(viewMode, 'custom-text-field'),
                    type: "string",
                    dataType: dataTypes.STRING,
                    validate: !isInstituteAdmin ? [
                        {
                          type: validatorTypes.REQUIRED,
                          message: 'error.nationality.required',
                        },
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.STRING_ONLY,
                            message: 'error.invalidInput'
                        }
                    ] : [
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.STRING_ONLY,
                            message: 'error.invalidInput'
                        }
                    ]
                },
                {
                    name: 'motherTongue',
                    label: 'form.motherTongue.label',
                    isRequired: true,
                    component: getComponentType(viewMode, 'custom-text-field'),
                    type: "string",
                    dataType: dataTypes.STRING,
                    validate: !isInstituteAdmin ? [
                        {
                            type: validatorTypes.REQUIRED,
                            message: 'error.motherTongue.required'
                        },
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.STRING_ONLY,
                            message: 'error.invalidInput'
                        }
                    ] : [
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.STRING_ONLY,
                            message: 'error.invalidInput'
                        }
                    ],
                },
                {
                    name: 'languageSpoken',
                    label: 'form.languageSpoken.label',
                    isRequired: true,
                    component: getComponentType(viewMode, 'custom-text-field'),
                    type: "string",
                    dataType: dataTypes.STRING,
                    validate: !isInstituteAdmin ? [
                        {
                          type: validatorTypes.REQUIRED,
                          message: 'error.languageSpoken.required'
                        },
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.STRING_ONLY_WITH_SPECIAL,
                            message: 'error.invalidInput'
                        }
                    ] : [
                        {
                            type: validatorTypes.PATTERN,
                            pattern: regexObject.STRING_ONLY_WITH_SPECIAL,
                            message: 'error.invalidInput'
                        }
                    ]
                },
            ],
        },
        {
            name: 'otherDocument',
            label: "",
            component: "three-column-custom-sub-form",
            viewMode: viewMode,
            fields: [
              {
                name: "documents",
                valueField: 'allDocument',
                label: "form.documents.label",
                type: 'dynamic-file-input',
                component: getComponentType(viewMode, "multi-doc-input"),
                threeColumnForm: true,
              }
            ],
        },
    ],
});
