import React, { useState, memo } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../../styles/theme/styles';
import Search from 'src/components/atoms/SearchBar/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import DataTable from 'src/components/molecules/Table/index.web';
import { useI18n } from 'src/i18n/hooks';
import { userInformation } from 'src/utils/manageState';
import { Icon } from 'src/components/atoms/Icon/Icon';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { getFilteredRows } from 'src/utils/utility';
import { FormBaseType, ListFormViewerPropType, TemplateType, ViewMode } from '../types';
import UserDetails from '../../UserDetails';
import { DetailStatusEnum } from 'src/constant';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';


const ListFormViewer: React.FC<ListFormViewerPropType> = ({
  step,
  schema,
  isOnboarding,
  userId,
  userType,
  reviewMode,
  formBaseType,
  applicationRef,
  formInitialValues: initialValues,
  setFormInitialValues,
  handleDelete,
  refreshList,
  navigation,
  selectedRecords,
  setSelectedRecords,
  hideAddAndDeleteBtn,
  viewApplication = false,
}) => {
  const currentUserObject = userInformation();

  const [modalState, handleModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [searchText, setSearchText] = React.useState('');
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [deleteRow, setDeleteRow] = useState<string>('');
  const [filRows, setFilRows] = React.useState(initialValues);
  const { t } = useI18n();

  const requestSearch = (searchValue: any) => {
    setSearchText(searchValue);
    let filteredRows = getFilteredRows(searchValue, initialValues)
    setFilRows(filteredRows);
  };

  const closeModal = async () => {
    handleModal(false);
    setOpenEdit(false);
    setFormValues({});
  };

  const closeAndRefresh = async () => {
    closeModal();
    if (refreshList) {
      await refreshList();
    }
  }
  const isEdit = schema.viewMode == ViewMode.EDIT;

  const showCheckBoxes = reviewMode && initialValues?.some(elem => elem?.status === DetailStatusEnum.SUBMITTED_FOR_REVIEW);

  return (
    <>
      {
        initialValues && Array.isArray(initialValues) && initialValues.length > 0 ? (
          <>
            {
              formBaseType != FormBaseType.ApplicationQualification && (
                <HeaderWrapper isOnboarding={isOnboarding}>
                  <HeaderFive
                    value={t(schema.headerData.title)}
                    color={colors.primaryText}
                  />
                </HeaderWrapper>
              )
            }
            <TableTop>
              {!viewApplication && (
                <Search
                  id="courseSearch"
                  value={searchText}
                  label={'Search Details'}
                  handleChange={requestSearch}
                />
              )}
              {
                !hideAddAndDeleteBtn && isEdit &&
                <SecondaryBtn
                  label={`+ ${t('add.label')}`}
                  onPress={() => {
                    handleModal(true);
                  }}
                />
              }
            </TableTop>

            <DataTable
              tableData={filRows}
              columnData={schema?.listOptions?.columns}
              addAction={isEdit}
              setSelectedData={setFormValues}
              setOpenEdit={setOpenEdit}
              handleDeleteEducationExperienceDetail={(row: any) => {
                setDeleteOverlay(true);
                setDeleteRow(row.id);
              }}
              showCheckBox={showCheckBoxes}
              selectedRecords={selectedRecords}
              setSelectedRecords={setSelectedRecords}
              reviewMode={reviewMode}
              hideDeleteBtn={hideAddAndDeleteBtn}
              viewApplication={viewApplication}
            />
          </>
        ) : (
          <AddDetailContainer>
            <IconContainer>
              <Icon name={schema?.listOptions?.iconName} />
            </IconContainer>
            <SubTitle>
              {!reviewMode
                ? t(schema?.addNewDetailsPrompt ?? schema.label)
                : t("no-records-found.text")}
            </SubTitle>
            {isEdit && <SecondaryBtn
              onPress={() => {
                handleModal(true);
              }}
              label={`+ ${t('add.label')}`}
            />
            }
          </AddDetailContainer >
        )
      }
      <NormalModal
        modalVisible={modalState || openEdit}
        setModalVisible={closeModal}
        Headerpopup={t(schema.label)}
        type='onboarding'
        showFooter={false}
        handleSave={() => { }}
        maxWidth={'lg'}
      >
        <UserDetails
          step={step}
          userId={userId}
          userType={userType}
          userDetails={formValues}
          templateType={TemplateType.DEFAULT}
          formBaseType={formBaseType}
          applicationRef={applicationRef}
          navigation={navigation}
          viewMode={ViewMode.EDIT}
          inModalView={true}
          onClose={closeAndRefresh}
          isOnboarding={true}
          reviewMode={reviewMode}
        />
      </NormalModal>
      <DeleteModal
        setModalVisible={() => setDeleteOverlay(false)}
        modalVisible={deleteOverlay}
        handleSave={() =>
          deleteRow && handleDelete && handleDelete(deleteRow)
        }
        Headerpopup={schema?.listOptions?.deleteMessageTitleLabel}
      >
        {t(schema?.listOptions?.deleteMessageLabel || 'delete.label')}
      </DeleteModal>
    </>
  )
}

export default memo(ListFormViewer);

const HeaderWrapper = styled.div<{ isOnboarding: boolean }>`
  margin: ${({ isOnboarding }) => isOnboarding ? '-8px 0px 24px 0px' : '0px 0px 24px 0px'};
`;

const TableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const AddDetailContainer = styled.div`
  margin: 5% auto 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubTitle = styled.text`
  font-size: 24px;
  font-family: ${fonts.semibold};
  color: ${colors.secondaryText};
  margin-bottom: 10px;
`;

const IconContainer = styled.image`
  text-align: center;
  margin-bottom: 25px;
`;