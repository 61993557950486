import React, { useState } from 'react';
import styled from 'styled-components';
import { useTheme } from 'styled-components';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { colors } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import QuickEditJSON from 'src/form-json/students/student-quick-edit.json';
import Element from 'src/components/molecules/Forms/ApplicationElement';
import { useForm } from 'react-hook-form';
import { useUpdateInstituteStudent } from 'src/graphql/onboarding/student';
import { UPDATE_SUCCESS } from 'src/constant/message';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ERROR, SUCCESS } from 'src/constant';
import CreateInfoItem from '../TextComponents/InfoItem';
import { clipLongText, properCase } from 'src/utils/utility';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';

export default function StudentDetailsQuickEditForm(props: any) {
    const { t } = useI18n();
    const { rem }: any = useTheme();
    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
        reset,
    } = useForm();
    const { studentData, batchId, handleFetch, setId, setCurrent } = props;
    const { updateInstituteStudentDetail } = useUpdateInstituteStudent();
    const { setAlertDetails } = useAlertSystem();
    const [elements, setElements] = useState<any>({});
    const [showDivisionPrompt, setShowDivisionPrompt] = useState<boolean>(false);
    const [selectedDivision, setSelectedDivision] = useState<string>('');
    const { fields }: any = elements ?? {};

    function loadBasicdata() {
        // Pre-populate values in form
        setValue('srNo', studentData?.serialNo);
        setValue('grNo', studentData?.grNo);
        setValue('division', { label: studentData?.division, value: studentData?.division });
        setValue('rollNo', studentData?.rollNo);
        setValue('scrollRefNo', studentData?.scrollRefNo);

        if (studentData?.batch && studentData.batch?.divisionDetails && studentData.batch.divisionDetails?.length > 0) {
            const divisionOptions = studentData.batch.divisionDetails.map((item: any) => ({
                label: item?.division,
                value: item?.division
            }));
            QuickEditJSON[0].fields[2].option = divisionOptions;
        }
        setElements(QuickEditJSON[0]);
    }

    const updateStudentInfo = async (data: any) => {
        const newValue = { academicDetail: {} };
        let updateRes: any;

        Object.entries(data).forEach(([key, value]) => {
          if (value && key !== 'srNo') {
            newValue.academicDetail[`${key}`] =
              key == 'division' ? value?.value : key == 'rollNo' ? parseInt(value) : value;
          }
        });
        if (Object.keys(newValue.academicDetail).length > 0) {
          newValue.academicDetail['batch'] = batchId;
        }

        // ID key is added only if some other value is passed to be updated
        if (!(Object.keys(newValue.academicDetail).length === 0)) {
            newValue['id'] = studentData.id;
            updateRes = await updateInstituteStudentDetail({ variables: { payload: newValue } });
            if (updateRes?.data?.updateInstituteStudentDetail?.status === 'success') {
                setAlertDetails({ message: UPDATE_SUCCESS.PROFILE, level: SUCCESS });
            } else {
                setAlertDetails({ message: updateRes?.data?.updateInstituteStudentDetail?.message, level: ERROR });
            }
            handleFetch();
            setCurrent(null);
            setId(studentData.id);
        }
        setIsViewMode(true)
        if (selectedDivision) {
            setSelectedDivision('');
        }
        return updateRes;
    };

    const itemWidth = "33.33%";
    const buttonHeight = { height: 32 };

    const [isViewMode, setIsViewMode] = useState<boolean>(true);

    function handleEditDetails() {
        setIsViewMode(false);
        loadBasicdata();
    }

    function handleCancel() {
        setIsViewMode(true);
        if (selectedDivision) {
            setSelectedDivision('');
        }
        reset({});
    }

    function handleCancelDivisonPromptModal() {
        setValue('division', { label: studentData?.division, value: studentData?.division });
        setShowDivisionPrompt(false);
    }

    function handleCustomSelect(id: string, data: { label: string, value: string }) {
        if (id === 'division') {
            if (data?.value && data.value !== studentData?.division) {
                setShowDivisionPrompt(true);
                setSelectedDivision(data.value);
            }
        }
    }

    const DisplayFields = [
        {
            label: t("subjectGroup.label"),
            value: studentData?.subjectGroup?.name
        },
        {
            label: t("form.srNo.label"),
            value: studentData?.serialNo,
        },
        {
            label: t("form.grNo.label"),
            value: studentData?.grNo,
        },
        {
            label: t("division.label"),
            value: studentData?.division,
        },
        {
            label: t("rollno.label"),
            value: studentData?.rollNo,
        },
        {
            label: t("scrollref.label"),
            value: studentData?.scrollRefNo,
        },
        {
            label: t("email.label"),
            value: clipLongText(studentData?.email, 40),
        }
    ]

    return (
        <NestedTableContainer>
            {isViewMode ?
                <Container>
                    <StudentDetail>
                        {
                            DisplayFields.map(field => ((
                                <CreateInfoItem
                                    label={field.label}
                                    value={field.value}
                                    width={itemWidth}
                                />
                            )))
                        }
                    </StudentDetail>
                    <SecondaryBtn
                        onPress={handleEditDetails}
                        label={t("editDetails.label")}
                        lines={1}
                        style={buttonHeight}
                    />
                </Container>
                :
                <EditModeContainer>
                    <FormFieldWrapper>
                        {fields?.map((field: any, i: number) => {
                            return (
                                !field?.hideVisibility && <FormField>
                                    <Element
                                        key={`stu${i}`}
                                        field={field}
                                        control={control}
                                        errors={errors}
                                        setValue={setValue}
                                        getValues={getValues}
                                        setError={setError}
                                        clearErrors={clearErrors}
                                        dynamicValidation={true}
                                        isCustomDisable={field.isCustomDisable}
                                        disablePaste={field.disablePaste}
                                        handleCustomSelect={handleCustomSelect}
                                    />
                                </FormField>
                            )
                        }
                        )}
                    </FormFieldWrapper>
                    <ButtonContainer>
                        <SecondaryBtn
                            onPress={handleSubmit(updateStudentInfo)}
                            label={t("update.label")}
                            lines={1}
                            style={buttonHeight}
                        />
                        <OutlineButton
                            onPress={handleCancel}
                            label={t("cancel.label")}
                            style={buttonHeight}
                        />
                    </ButtonContainer>
                </EditModeContainer>
            }

            <DeleteModal
                setModalVisible={handleCancelDivisonPromptModal}
                modalVisible={showDivisionPrompt}
                handleSave={() => setShowDivisionPrompt(false)}
                deleteButtonColor={colors.primaryColor}
                deleteButtonText={'confirm.label'}
                Headerpopup={t('changeDivision.label')}>
                {t('changeDivision.warning.text', {
                    values: {
                    studentName: properCase(studentData?.fullName),
                    newDivision: selectedDivision,
                    }
                })}
            </DeleteModal>
        </NestedTableContainer>
    )
}

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 24px;
  margin-bottom: 8px;
`;

const FormField = styled.div`
  width: 250px;
`;

const NestedTableContainer = styled.div`
  padding: 16px;
  border: 2px solid ${colors.tableDropdwonBorder};
`;

const StudentDetail = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 16px;
  width: 90%;
`;

const EditModeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
