import React from 'react';
import { View, StyleSheet } from 'react-native';
import { TextField } from '@mui/material';
import SmallTextAtom from '../Text/SmallTextAtom';
import { colors, fonts, breakpoints } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import styled from 'styled-components';

export default function ColorPickerInput(props: any) {
  const {
    value,
    setValue,
    label,
    onBlur = null,
    id = 'standard-basic',
    header = '',
    notefooter = '',
    width = '100%',
    placeholder,
    multiline = false,
    disabled = false,
    maxRows = 5,
    maxWidth,
    hasError = false,
  } = props;
  const { theme } = useThemeSystem();

  const { t } = useI18n();
  return (
    <View style={{ opacity: disabled ? 0.5 : 1 }}>
      {header !== '' ? (
        <View style={styles.helperText}>
          <SmallTextAtom value={t(header)} color={theme?.input?.topLabel} />
        </View>
      ) : null}
      <View style={{ flexDirection: 'row' }}>
        <CustomColorPicker
          style={{ flex: 1 }}
          type={'search'}
          inputRef={props.inputRef}
          multiline={multiline}
          rows={maxRows}
          id={id}
          label={label}
          value={value}
          onBlur={onBlur}
          onChange={setValue}
          variant="outlined"
          size='small'
          InputLabelProps={{ shrink: false }}
          placeholder={value ? value : placeholder}
          disabled={disabled}
          maxRows={maxRows}
          error={hasError}
          theme={theme}
          sx= {{
            width: width ? width : null,
          }}
        />
        <CustomColorPicker
          style={{ width: 40 }}
          type={'color'}
          inputRef={props.inputRef}
          multiline={multiline}
          rows={maxRows}
          id={id}
          label={label}
          value={value}
          onBlur={onBlur}
          onChange={setValue}
          size='small'
          variant="outlined"
          InputLabelProps={{ shrink: false }}
          placeholder={value ? value : placeholder}
          disabled={disabled}
          maxRows={maxRows}
          error={hasError}
          sx= {{
            width: width ? width : null,
          }}
        />
      </View>
      {notefooter !== '' ? (
        <View style={styles.bottomText}>
          <SmallTextAtom value={t(notefooter)} color={theme?.input?.bottomLabel} />
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  helperText: {
    marginBottom: 4,
  },
  bottomText: {
    marginTop: 4,
  },
});

const CustomColorPicker = styled(TextField)`
  .MuiTextField-root {
     @media (max-width: ${breakpoints.md}) {
      width: 100%;
    }
  }
  .MuiOutlinedInput-root {
      & .MuiOutlinedInput-input {
        font-family: ${fonts.regular};
        height: 24px;
        font-weight: 400;
        padding: 8px;
        font-size: 16px;
        color: ${colors.primaryText};
        line-height: ${(props) => (props?.multiline ? null : '24px')};
      }
      & fieldset {
        border-color: ${(props) => props?.theme?.input?.borderColor};
      }
      &:hover fieldset {
        border-color: ${(props) => props?.theme?.input?.hoverBorder};
      }
      &.Mui-focused fieldset {
        border-color: ${(props) => props?.theme?.input?.activeBorder};
        border-width: 1px;
      }
      &.Mui-disabled fieldset {
        border-color: ${(props) => props?.theme?.input?.disabledBorder};
      }
    }
    & .MuiOutlinedInput-multiline {
      padding: '0px';
    }
`;
