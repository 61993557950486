import React from 'react';
import styled from 'styled-components/native';

import { colors, fonts } from 'src/styles/theme/styles';

export default function HeaderFive(props: any) {
  const { value = '', color = colors.secondaryText, fontWeight, lines, fontFamily = fonts.regular} = props;

  return (
    <HeaderText style={{ fontWeight, color, fontFamily }} allowFontScaling={false} numberOfLines={lines}>
      {value}
    </HeaderText>
  );
}

const HeaderText = styled.Text`
  font-size: ${(props) => props.theme.rem(2)};
  font-weight: 400;
  line-height: ${(props) => props.theme.rem(2.4)};
  color: ${colors.primaryText};
  flex-shrink: 1;
`;