import React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors, fontWeight, fonts } from 'src/styles/theme/styles';
import SmallTextAtom from '../Text/SmallTextAtom';
import { isWeb } from 'src/constant/device';

interface propType {
  name: string;
  classColors?:{
    light: string,
    dark: string
  }
}

export default function ClassAtom(props: propType) {
  const { 
    name,
    classColors
   } = props;
  return (
    <View style={[styles.container,{backgroundColor: classColors?.light || colors.classBg }]}>
      <SmallTextAtom
        value={name}
        color={colors.classColor}
        fontWeight={fontWeight[600]}
        fontFamily={fonts.semibold}
        lineHeight={16}
        lines={1}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.classBg,
    borderRadius: 50,
    paddingHorizontal: 8,
    paddingVertical: 2,
    marginVertical: 4,
    height: isWeb ? 20 : 'auto',
    alignSelf: 'flex-start',
    justifyContent: 'center',
  },
});
