import { TableBody, TableCell, TableRow } from '@mui/material';
import React, { useRef, useState } from 'react';
import { View } from 'react-native';
import useTable from './UseTable';
import Check from 'src/components/atoms/CheckBox/index.web';
import { colors } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faGripLines } from '@fortawesome/pro-solid-svg-icons';
import { onDragEndHandle, onDragOverHandle, onDragStartHandle, preventDrag } from 'src/utils/orderingHelperFunction';
import { clipLongText } from 'src/utils/utility';

export default function CheckboxTable(props: any) {
  const { header, tabledata, checked, handleChecked, width, manualSelection, height, maxHeightTable, isDataAvailable, ordering = false, setTableData, textCount = 35 } = props;
  const { t } = useI18n();
  const maxHeight = 350;
  const [tabledataState] = useState(tabledata);
  const { TblContainer, TblHead } = useTable(tabledataState, header, maxHeight, tabledata);
  const dragItem = useRef<number | null>();
  const dragOverItem = useRef<number | null>();

  return (
    <View style={{ overflow: 'auto', width, marginBottom: 15 }}>
      <TblContainer
        tabledata={tabledata}
        height={height && height}
        maxHeight={maxHeightTable && maxHeightTable}>
        <TblHead />
        <TableBody>
          {tabledata &&
            tabledata.map((item: any, i: number) => (
              <CTableRow key={i}
                draggable = {ordering}
                onDragStart={(e: any) => onDragStartHandle(i, e, dragItem)}
                onDragOver={(e: any) => onDragOverHandle(i, e, dragOverItem)}
                onDragEnd={(e: any) => onDragEndHandle(e, dragItem, dragOverItem, tabledata, setTableData)}
              >
                {ordering ? (
                  <TableCell>
                    <IconView>
                      <FontAwesomeIcon icon={faGripLines} color={colors.secondaryText} />
                    </IconView>
                  </TableCell>
                ) : null}
                <TableCell style={styles.textStyle} draggable onDragStart={(e: any) => preventDrag(e)}>{clipLongText(item?.name, textCount)}</TableCell>
                <TableCell style={styles.textStyle} draggable onDragStart={(e: any) => preventDrag(e)}>
                  <Check
                    id={item?.id}
                    width={100}
                    same={manualSelection}
                    name="courseCheck[]"
                    checked={checked.includes(item?.id)}
                    onChange={() => handleChecked(item.id)}
                    index={i}
                    direction={'right'}
                  />
                </TableCell>
              </CTableRow>
            ))}
          {/* When data is available i.e. form values are selected but no data is found (returned from api) */}
          {isDataAvailable && tabledata.length == 0 &&
            <TableRow key={'no-record'} style={{ height: '48px' }}>
              <TableCell style={styles.textStyle} colSpan={ordering ? 3 : 2} align={'center'}>{t("no-records-found.text")}</TableCell>
            </TableRow>}
        </TableBody>
      </TblContainer>
    </View>
  );
}

const styles = {
  textStyle: {
    padding: '0px 15px',
    fontSize: 15,
    color: colors.primaryText,
    fontWeight: 400,
  },
};

const IconView = styled.View`
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: center;
  cursor: move;
`;

const CTableRow = styled(TableRow)`
  height: 40px;
`;