import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import AddGradesFormJSON from 'src/form-json/assessment/assessment-grades-add-grade-form.json';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

interface AddEditGradesFormProps {
  control: any; errors: any; reset: any; setValue: any; isEditModal: boolean; currentItem: any;
}

export default function AddEditGradesForm(props: AddEditGradesFormProps) {
  const { control, errors, reset, setValue, isEditModal, currentItem } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    reset();
    if (!isEditModal) {
      setElements(AddGradesFormJSON[0]);
    } else if (isEditModal && currentItem && Object.keys(currentItem).length != 0) {
      setElements(AddGradesFormJSON[0]);
      setValue("gradeName", currentItem.name);
      setValue("lowerMarks", currentItem.min);
      setValue("higherMarks", currentItem.max);
      setValue("remark", currentItem.remarks);
      setValue("gradePoint", currentItem.gradePoint);
    }
  }, []);

  return (
    <View style={styles.parentContainer}>
      {fields ? (
        fields.map((field: any, i: number) => (
          <View style={(field?.id == 'lowerMarks' || field?.id == 'higherMarks') ? styles.marksFieldContainer : styles.inputFieldContainer}>
            <Element key={`aeg${i}`} field={field} control={control} errors={errors} />
          </View>
        ))
      ) : (
        <View style={styles.spinnerView}>
          <LoaderSpinner />
        </View>
      )}
    </View>
  );
}


const styles = StyleSheet.create(
  {
    parentContainer: { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', paddingBottom: 25, },
    inputFieldContainer: { width: '100%' },
    marksFieldContainer: { width: '47.5%' },
    spinnerView: { width: '100%', height: 250, justifyContent: 'center', alignItems: 'center' }
  }
);
