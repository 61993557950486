import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props:any) {
  return (
    <Svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 14.967A6.976 6.976 0 011.032 8 6.976 6.976 0 018 1.032 6.976 6.976 0 0114.967 8 6.976 6.976 0 018 14.967zM9.3 7.83l-2.323-.652a.905.905 0 01-.664-.861.91.91 0 01.92-.897h1.45c.362 0 .707.116.988.326a.256.256 0 00.335-.026l.365-.37a.26.26 0 00-.026-.388 2.661 2.661 0 00-1.665-.577h-.164v-1.29a.259.259 0 00-.258-.259h-.516a.259.259 0 00-.258.258v1.29h-.252c-1.074 0-1.951.865-1.951 1.93 0 .858.583 1.62 1.416 1.855l2.322.651c.39.11.665.465.665.861a.91.91 0 01-.92.897H7.313c-.362 0-.707-.116-.987-.326a.256.256 0 00-.336.026l-.364.371a.26.26 0 00.025.387 2.661 2.661 0 001.665.578h.168v1.29c0 .142.116.258.258.258h.516a.259.259 0 00.258-.258v-1.29h.252c1.074 0 1.951-.865 1.951-1.93A1.944 1.944 0 009.3 7.83z"
        fill="#000"
        fillOpacity={0.6}
      />
    </Svg>
  )
}

export default SvgComponent
