import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props:any) {
  return (
    <Svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M14.092 7.387l1.028-.593a.775.775 0 00.36-.873 7.986 7.986 0 00-2.066-3.574.774.774 0 00-.935-.123l-1.025.593a6.33 6.33 0 00-1.062-.614V1.017A.775.775 0 009.817.27a8.051 8.051 0 00-4.127 0 .775.775 0 00-.576.748v1.186c-.372.17-.728.375-1.06.614l-1.026-.593a.774.774 0 00-.935.123A7.986 7.986 0 00.027 5.921c-.091.34.056.698.36.873l1.027.593c-.039.408-.039.818 0 1.226l-1.027.593a.775.775 0 00-.36.873 7.987 7.987 0 002.066 3.574.774.774 0 00.935.123l1.025-.593c.333.239.689.444 1.061.614v1.185a.774.774 0 00.576.75 8.05 8.05 0 004.127 0 .775.775 0 00.575-.75v-1.185c.373-.17.728-.375 1.062-.614l1.025.593a.774.774 0 00.935-.123 7.985 7.985 0 002.066-3.574.775.775 0 00-.36-.873l-1.028-.593c.04-.408.04-.818 0-1.226zM12.393 9.42l1.414.816a6.457 6.457 0 01-1.092 1.888l-1.414-.817c-1.032.884-1.183.97-2.457 1.42v1.633a6.482 6.482 0 01-2.181 0v-1.632c-1.275-.45-1.426-.537-2.457-1.42l-1.414.816A6.457 6.457 0 011.7 10.236l1.414-.816c-.247-1.332-.247-1.507 0-2.84L1.7 5.764a6.457 6.457 0 011.092-1.888l1.414.817c1.032-.884 1.182-.97 2.457-1.42V1.64a6.481 6.481 0 012.181 0v1.632c1.274.45 1.426.537 2.457 1.42l1.414-.816a6.455 6.455 0 011.092 1.888l-1.414.816c.246 1.332.246 1.507 0 2.84zm-4.64-4.517A3.1 3.1 0 004.657 8a3.1 3.1 0 003.096 3.097A3.1 3.1 0 0010.85 8a3.1 3.1 0 00-3.097-3.097zm0 4.645A1.55 1.55 0 016.205 8a1.55 1.55 0 011.548-1.548A1.55 1.55 0 019.302 8a1.55 1.55 0 01-1.549 1.548z"
        fill="#000"
        fillOpacity={0.6}
      />
    </Svg>
  )
}

export default SvgComponent
