import { TableBody } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { height } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import { TableActions, TableCells, TableDiv, TableRows } from './TableAtom';
import useTable from './UseTable';
import RadioStandalone from 'src/components/atoms/Radio/StandaloneRadio/index.web';
import { CriteriaIcon, DeleteIcon, EditIcon } from 'src/components/atoms/ActionIcons';
import { minAvgMarksInputItemType } from 'src/components/organism/Admission/types';
import Check from 'src/components/atoms/CheckBox/index.web';

const headCells = [
  {
    id: 'markMerit',
    label: 'markMeritCount.label',
    align: 'center',
    disableSorting: true,
  },
  {
    id: 'qualification',
    label: 'qualification.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'passedInFirstAttempt',
    label: 'firstAttempt.required.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
  },
];

interface Props {
  tableData: Array<string>;
  setTableData: Function;
  setEditQualificationData: Function;
  setStreams: Function;
  setQualificationModal: Function;
  setIsEditQualificationModal: Function;
  setDocuments: Function;
  reqMarksData?: minAvgMarksInputItemType[];
  setReqMarksData?: Dispatch<SetStateAction<minAvgMarksInputItemType[]>>;
  handleReqMarksModal: Dispatch<SetStateAction<boolean>>;
  reservationGroupData?: { caste: { id: string; name: string } }[];
}

interface StreamItemType {
  stream: {
    id: string;
  };
  description?: string;
  majorSubjects?: string[];
}

export default function CriteriaTable(props: Props) {
  const {
    tableData,
    setTableData,
    setEditQualificationData,
    setStreams,
    setQualificationModal,
    setIsEditQualificationModal,
    setDocuments,
    reqMarksData,
    setReqMarksData,
    handleReqMarksModal,
    reservationGroupData
  } = props;
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(tableData, headCells);
  const { t } = useI18n();
  const radioBtnStyle = { width: 180, padding: 1 };

  const removeObjectAtIndex = (arr, index) => (arr.splice(index, 1), arr);

  function handleDeleteQualification(index: number) {
    const splicedArray = removeObjectAtIndex([...tableData], index);
    setTableData(splicedArray)
  }

  function handleEditQualification(selectedQualificationData: any) {
    setIsEditQualificationModal(true);
    setEditQualificationData(selectedQualificationData);
    const streamData = selectedQualificationData?.streams?.map((item: StreamItemType) => {
      return {
        stream: item?.stream?.id || item?.stream,
        description: item?.description,
        majorSubjects: item?.majorSubjects || [],
      };
    });
    const documentsData = selectedQualificationData?.documentTypes?.map((item: any) => {
      return {
        documentType: item?.documentType?.id || item?.documentType,
        required: item?.required,
      };
    });
    setStreams(streamData);
    setDocuments(documentsData);
    setQualificationModal(true);
  }

  function handleMinReqMarksFn(selectedQualificationData: any) {
    setEditQualificationData(selectedQualificationData);
    const selectedQualCategoryCriteria =
      selectedQualificationData?.categoryCriteria || [];

    const mergedData = reservationGroupData?.map(({ caste }) => {
      const criteria = selectedQualCategoryCriteria.find(
        (c: { category: string; }) => c.category === caste.id
      );
      return {
        category: caste.id,
        minAverage: criteria?.minAverage || 0,
        name: caste.name,
      };
    });

    if (mergedData && setReqMarksData) {
      setReqMarksData(mergedData);
      handleReqMarksModal(true);
    }
  }

  function handleChange(value: string): void {
    const newData = [...tableData];
    const idx = tableData.findIndex((item: any) => item?.name?.value === value);
    if (idx > -1) {
      newData[idx] = { ...newData[idx], required: true };
      if (newData.length > 1) {
        newData.forEach((item: any, i: number) => {
          if (i !== idx) newData[i] = { ...item, required: false };
        });
      }
    }
    setTableData(newData);
  }

  function handleCheck(index: number, fieldIsChecked: boolean) {
    setTableData((prevState) => {
      const temp = [...prevState];
      temp[index] = { ...temp[index] };
      temp[index]["firstAttemptRequired"] = fieldIsChecked;
      return temp;
    });
  }

  return (
    <TableDiv>
      <TblContainer maxHeight={190}>
        <TblHead />
        {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting().length > 0 && (
          <TableBody>
            {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
              <TableRows key={i}>
                <TableCells style={radioBtnStyle} align={'center'}>
                  <RadioStandalone
                    isChecked={item?.required}
                    value={item.id}
                    handleChange={() => handleChange(item?.name?.value)}
                  />
                </TableCells>
                <TableCells value={item?.name?.label} />
                <TableCells>
                  <CheckWrapper>
                    <Check
                      id={item.id}
                      onChange={(fieldIsChecked: boolean) => {
                        handleCheck(i, fieldIsChecked)
                      }}
                      checked={!!(item?.firstAttemptRequired)}
                    />
                  </CheckWrapper>
                </TableCells>

                <TableActions align="right">
                  <TableActionView>
                    <CriteriaIcon
                      tooltipTitle={t("edit-min-avg-marks.label")}
                      onPress={() => handleMinReqMarksFn(item)} />
                    <EditIcon
                      tooltipTitle={t("editQualification.text")}
                      onPress={() => handleEditQualification(item)} />
                    <DeleteIcon
                      tooltipTitle={t("delete.label")}
                      onPress={() => handleDeleteQualification(i)} />
                  </TableActionView>
                </TableActions>
              </TableRows>
            ))}
          </TableBody>
        )}
        {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting().length === 0 && (
          <TableRows style={{ height: 0.5 * height }}>
            <TableCells colspan={6} align="center" style={{ borderBottom: 'none' }}>
              <NormalTextSelect value={'No records found'} />
            </TableCells>
          </TableRows>
        )}
      </TblContainer>
    </TableDiv>
  );
}

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
`;

const CheckWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
