{
  "name": "@dotlottie/common",
  "version": "0.7.11",
  "type": "module",
  "description": "",
  "author": "Afsal <afsal@lottiefiles.com>, Sam Osborne <sam@lottiefiles.com>",
  "license": "MIT",
  "engines": {
    "node": ">18.0.0"
  },
  "module": "dist/index.js",
  "main": "dist/index.js",
  "types": "dist/index.d.ts",
  "files": [
    "dist"
  ],
  "keywords": [],
  "scripts": {
    "build": "tsup",
    "dev": "tsup --watch",
    "lint": "eslint .",
    "type-check": "tsc --noEmit"
  },
  "dependencies": {
    "@dotlottie/dotlottie-js": "^0.7.0",
    "@preact/signals-core": "^1.2.3",
    "howler": "^2.2.3",
    "lottie-web": "^5.12.2",
    "xstate": "^4.38.1"
  },
  "devDependencies": {
    "@lottiefiles/lottie-types": "^1.2.0",
    "@types/howler": "^2.2.8",
    "tsup": "^7.2.0",
    "typescript": "^4.7.4"
  },
  "publishConfig": {
    "access": "public"
  }
}
