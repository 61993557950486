import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useQuery } from '@apollo/client';
import groupAudience from 'src/form-json/communication/group-audience.json';
import Element from 'src/components/molecules/Forms/ApplicationElement';
import { AUTOCOMPLETE_MODULE, ERROR } from 'src/constant';
import { isNative, isWeb } from 'src/constant/device';
import { DropdownOptionsType } from 'src/types';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { useQuerySubjectGroup } from 'src/graphql/academics/subject-groups';
import { colors } from 'src/styles/theme/styles';
import { getFilteredSubjectsBySubjectGroup } from 'src/components/services';
import { clipLongText, mergeBatchClassLabel } from 'src/utils/utility';
import Chip from 'src/components/atoms/Chip';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { showMessage } from '../NotificationWrapper';
import { useI18n } from 'src/i18n/hooks';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form/dist/types';

interface GroupAudienceProps {
  groupFormControl: Control<FieldValues, object>;
  groupFormError: any;
  setGroupFormValue: UseFormSetValue<FieldValues>;
  parentType: string;
  audienceData: Array<string>;
  setAudienceData: Function;
  removeAudience: Function;
  handleChecked: Function;
}

export default function GroupAudience(props: GroupAudienceProps) {
  const {
    groupFormControl,
    groupFormError,
    setGroupFormValue,
    parentType,
    audienceData,
    setAudienceData,
    removeAudience,
    handleChecked
  } = props;

  const [elements, setElements] = useState<any>({});
  const { theme } = useThemeSystem();
  const [batch, setBatch] = useState<any>({});
  const [batches, setBatches] = useState<any>({});
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const [subGroup, setSubGroup] = useState({
    label: '',
    value: ''
  });
  const [filteredDivisions, setFilteredDivisions] = useState([]);
  const [searchData, setSearchData] = useState('');
  const { fields }: any = elements ?? {};
  const allBatches = useQuery(getBatchQuery, {
    variables: { limit: 0 },
    fetchPolicy: 'network-only',
  });
  let { setAlertDetails } = useAlertSystem();
  const { t } = useI18n();

  const { query: subGroupQuery, error, data: subGroups } = useQuerySubjectGroup();

  useEffect(() => {
    setGroupFormValue(AUTOCOMPLETE_MODULE.SUBGROUP, null);
    setGroupFormValue(AUTOCOMPLETE_MODULE.DIVISION, null);
    return () => {
      groupAudience[0].fields[1].option = [];
      groupAudience[0].fields[2].option = [];
      groupAudience[0].fields[2].fieldVisible = true;
      groupAudience[0].fields[2].required = true;
    }
  }, []);

  useEffect(() => {
    onLoadFunction();
  }, [allBatches.data]);

  useEffect(() => {
    if (subGroup && subGroup?.label && subGroup?.value && batch && batch?.class) {
      loadDivisions(batch?.divisionDetails?.map((det) => det.division));
    }
  }, [subGroup]);

  useEffect(() => {
    if (subGroups?.subjectGroups) {
      setTimeout(() => {
        loadSubjectGroup(subGroups.subjectGroups.data);
      });
    }
  }, [subGroups]);

  useEffect(() => {
    loadSelectedDivisions();
  }, [audienceData, subGroup?.value]);

  function onLoadFunction() {
    if (allBatches.loading === false) {
      const getdata = mergeBatchClassLabel(allBatches.data?.batches?.data);
      groupAudience[0].fields[0].option = getdata;
      setBatches(getdata);
      if (parentType && parentType !== 'ASSIGNMENT') {
        groupAudience[0].fields[2].fieldVisible = false;
        groupAudience[0].fields[2].required = false;
      } else {
        groupAudience[0].fields[2].fieldVisible = true;
        groupAudience[0].fields[2].required = true;
      }
      setElements(groupAudience[0]);
    }
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      setIsDataAvailable(false)
      if (parentType === 'ASSIGNMENT') {
        setAudienceData([]);
        setGroupFormValue(AUTOCOMPLETE_MODULE.SUBJECT, null);
      }
      setSearchData('');
      setFilteredDivisions([]);
      setSelectedDivision([]);
      setSubGroup({
        label: '',
        value: ''
      });
      data = isWeb ? data : batches.find((bt: any) => bt.value === data);
      setGroupFormValue(AUTOCOMPLETE_MODULE.SUBGROUP, null);
      setGroupFormValue(AUTOCOMPLETE_MODULE.DIVISION, null);
      setBatch(data);
      if (data?.value) {
        subGroupQuery({ variables: { filters: { batch: { eq: data?.value } }, limit: 0 } });
      }
    }

    if (id === AUTOCOMPLETE_MODULE.SUBGROUP) {
      setIsDataAvailable(false)
      if (parentType === 'ASSIGNMENT') {
        setAudienceData([]);
        setGroupFormValue(AUTOCOMPLETE_MODULE.SUBJECT, null);
      }
      setSelectedDivision([]);
      setFilteredDivisions([]);
      if (isNative) {
        const sg = subGroups?.subjectGroups?.data?.find(
          (sub: any) => sub.value == data
        )
        setSubGroup({
          label: sg?.label,
          value: sg?.value
        });
      } else {
        setSubGroup({
          label: data?.label,
          value: data?.value
        });
      }
      if (parentType === 'ASSIGNMENT') {
        getSubjects(data);
      }
    }
  }

  async function getSubjects(subGroupId: string) {
    const subjectsResponse = await getFilteredSubjectsBySubjectGroup(
      { id: isWeb ? subGroupId?.value : subGroupId }
    )
    loadSubjects(subjectsResponse?.options);
  }

  function loadSubjects(subjectArray: any) {
    const modifiedData = { ...groupAudience[0] };
    modifiedData.fields[2].option = subjectArray;
    setElements(modifiedData);
  }

  function loadDivisions(divisionArray: DropdownOptionsType[]) {
    const division = [] as any;
    divisionArray?.forEach(function (item: any) {
      division.push({
        name: item,
        id: item
      })
    });
    setDivisions(division);
    setFilteredDivisions(division);
    setIsDataAvailable(true);
  }

  function loadSubjectGroup(subGroupArr: any[] = []) {
    const modifiedData = { ...groupAudience[0] };
    modifiedData.fields[1].option = subGroupArr;
    setElements(modifiedData);
  }

  function loadSelectedDivisions() {
    if (audienceData && audienceData.length > 0) {
      let divisions = audienceData.filter((item: any) => {
        const batchId = item.audience.split('/')[1];
        const subGrpId = item.audience.split('/').pop();
        return batchId === batch?.value && subGrpId === subGroup?.value;
      });
      divisions = divisions.map((item: any) => item.audience.split("/")[2]);
      setSelectedDivision(divisions);
    } else {
      setSelectedDivision([]);
    }
  }

  const handleCheckChild = (item: any) => {
    if (subGroup?.label && subGroup?.value) {
      handleChecked(item, batch, subGroup);
    } else {
      if (isWeb) {
        setAlertDetails({
          message: t('selectSubjectGroup.text'),
          level: ERROR,
        });
      } else {
        showMessage({
          message: t('selectSubjectGroup.text'),
          type: 'danger',
          position: 'top',
          icon: 'danger',
        });
      }
    }
  };

  function searchBoxOnChange(data: string) {
    setSearchData(data);
    if (divisions.length) {
      if (!data.length) {
        setFilteredDivisions(divisions);
      } else {
        const updatedData = data.toLowerCase().trim();
        setFilteredDivisions(
          divisions.filter((division: any) => {
            if (division?.name?.toLowerCase().includes(updatedData)) {
              return true;
            }
            return false;
          }),
        );
      }
    }
  }

  return (
    <View>
      <View style={styles.containerView}>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View key={`parentele${i}`}>
              <Element
                key={i}
                field={field}
                control={groupFormControl}
                errors={groupFormError}
                handleCustomSelect={handleCustomSelect}
                searchMaxWidth={350}
                checked={selectedDivision}
                checkboxTableData={filteredDivisions}
                handleCheck={handleCheckChild}
                leftLabel={'DIVISION'}
                searchBoxOnChange={searchBoxOnChange}
                searchData={searchData}
                manualSelection={false}
                checkboxTableHeight={'auto'}
                checkboxTableMaxHeight={220}
                isDataAvailable={isDataAvailable}
              />
            </View>
          ))
        ) : (
          <View style={styles.spinnerView} />
        )}
      </View>
      <View style={[styles.chipWrapper, { marginTop: 15 }]}>
        {audienceData && audienceData.length > 0 &&
          audienceData?.map((chip: any, index: number) => (
            <View style={[styles.chipView]} key={`audience${index}`}>
              <Chip
                label={isWeb ? chip?.text : clipLongText(chip?.text, 36)}
                onPress={() => removeAudience(index)}
                borderRadius={isWeb ? 4 : 20}
                chipFontSize={1.2}
              />
            </View>
          ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
  containerView: {
    borderWidth: 1,
    borderColor: colors.borderColor,
    borderStyle: 'dashed',
    borderRadius: 4,
    padding: 8
  },
  chipWrapper: { flexDirection: 'row', flexWrap: 'wrap' },
  chipView: { marginRight: 8, marginBottom: 8 },
});
