/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ACTION_MODE, USER_TYPE } from 'src/constant';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import { colors } from '../../../styles/theme/styles';
import { LoadingOrComponent } from '../../molecules/Loading';
import { studentForms, employeeForms } from 'src/form-json/user-details/index';
import { userInformation } from 'src/utils/manageState';
import UserDetails from 'src/components/molecules/UserDetails/index';
import { ProfileDetailViewNativePropType } from './types';
import { TemplateType, ViewMode } from 'src/components/molecules/Forms/types';

const ProfileDetailView: React.FC<ProfileDetailViewNativePropType> = (props) => {

  const {
    step,
    viewMode,
    userId,
    userType,
    userDetails,
    formContents,
    navigation,
    formBaseType,
    templateType,
    onClose,
    applicationRef,
  } = props.route.params;
  
  return (
    <>
      <LoadingOrComponent styles={{ height: '100%' }}>
        <UserDetails
          step={step}
          userId={userId}
          userType={userType}
          formContents={formContents}
          navigation={navigation}
          viewMode={viewMode}
          isOnboarding={false}
          formBaseType={formBaseType}
          templateType={templateType}
          userDetails={userDetails}
          onClose={onClose}
          applicationRef={applicationRef}
        />

      </LoadingOrComponent>
    </>
  );
};

export default ProfileDetailView;
