import React, { useEffect, Fragment, useState, useCallback } from 'react';
import { View } from 'react-native';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useGetCourses } from 'src/graphql/courses/index';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useI18n } from 'src/i18n/hooks';
import { useHeaderTitle } from 'src/contexts/header-context';
import { createSearchFilter, debounce } from 'src/components/services';
import { AUTOCOMPLETE_MODULE, FILTERS, SEARCH_TIME } from 'src/constant';
import { useFilter } from 'src/contexts/filter-context';
import CoursesTable from 'src/components/molecules/Table/CoursesTable';
import { height } from 'src/constant/device';
import styled from 'styled-components/native';

export default function InstituteClasses() {
  let row;
  let searchValue = '' as string;
  const { filters, setFilter } = useFilter();

  const { t } = useI18n();
  const { heading, setHeading } = useHeaderTitle();
  const { getInstituteCourses, coursesData, loading } = useGetCourses();
  const [prevPage, setPrevPage] = useState<string>('');
  const [searching, setSearchingCourse] = useState(searchValue);
  row = coursesData?.courses?.data;

  if (filters && filters?.courses?.on) {
    searchValue = filters?.courses?.search;
  }

  function setTitle() {
    setHeading([
      {
        text: t('classrooms.label'),
        url: '',
      },
    ]);
  }

  function handleFetch() {
    let searchFilters = [] as any;
    if (searchValue) {
      searchFilters = [
        {
          field: 'alias',
          type: FILTERS.REGEX,
          value: searchValue,
        },
        {
          field: 'name',
          type: FILTERS.REGEX,
          value: searchValue,
        },
      ];
    }

    const coursesFilter = createSearchFilter(0, 0, searchFilters);
    getInstituteCourses({ variables: coursesFilter });
  }

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.COURSES]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchCourses(data: string) {
    let isClear = data?.length === 0;
    persistSearch(!isClear, data);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchCourses(q), SEARCH_TIME),
    [],
  );

  const handleSearch = (course: string) => {
    setSearchingCourse(course);
    delayedQuery(course);
  };

  useEffect(() => {
    if (heading && heading[0]?.from) {
      setPrevPage(heading[0].from);
    }
    setSearchingCourse(searchValue);
    handleFetch();
  }, [searchValue]);

  useEffect(() => {
    setTitle();
  }, []);

  return (
    <LoadContentWrapper>
      <SearchBarWrapper>
        <SearchBar>
          <Search
            id="courseSearch"
            value={searching}
            label={t('searchClassrooms.text')}
            handleChange={handleSearch}
          />
        </SearchBar>
      </SearchBarWrapper>
      <View>
        {loading ? (
          <Spinner>
            <LoaderSpinner />
          </Spinner>
        ) : (
          <CoursesTableWrapper>
            <CoursesTable data={row} prevPage={prevPage} />
          </CoursesTableWrapper>
        )}
      </View>
    </LoadContentWrapper>
  );
}

const SearchBarWrapper = styled.View`
  flex-direction: row;
`;
const SearchBar = styled.View`
  flex: 3;
  align-items: flex-start;
`;
const Spinner = styled.View`
  width: 100%;
  height: 350px;
  justify-content: center;
  align-items: center;
`;
const CoursesTableWrapper = styled.View`
  height: ${height - 235};
  overflow: auto;
`;
