import RazorpayCheckout from 'react-native-razorpay';
import { showMessage } from '../../molecules/NotificationWrapper';
import { useAlertSystem } from '../../../contexts/web-alert-context';
import ERROR_MSG from '../../../constant/error';
import { ERROR, SUCCESS, UserType, USER_TYPE } from '../../../constant';
import { ADD_SUCCESS } from '../../../constant/message';
import { userInformation } from 'src/utils/manageState';
import { graphqlMutation } from 'src/graphql/util';
import { CreateOrder, verifyPayment } from 'src/graphql/payment';
import { isWeb } from 'src/constant/device';
import { createAdmissionPaymentOrderMutation } from 'src/graphql/applications/applications';
import { Dispatch, SetStateAction } from 'react';
import { paymentListInterface } from './HooksAndConstants';
import { colors } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { properCase } from 'src/utils/utility';

const usePayment = (
  PaymentComplete: () => void,
  userinfo?: { batch: string; user: any; },
  setPaymentList?: Dispatch<SetStateAction<paymentListInterface[]>>,
  paymentFailed?: () => void
) => {
  const { setAlertDetails } = useAlertSystem();
  const currentUserObject = userInformation();
  const { t } = useI18n();

  function showError(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: ERROR });
      setTimeout(() => {
        setAlertDetails({ message: '', level: '' });
      }, 2500);
    } else {
      showMessage({
        message: message,
        type: 'danger',
        position: 'top',
        icon: 'danger',
      });
    }
  }

  const createOrder = async (fees: any, user: UserType = USER_TYPE.STUDENT) => {
    try {
      let resp;
      if (user == UserType.INSTITUTE_STUDENT) {
        resp = await graphqlMutation(CreateOrder, {
          batch: userinfo ? userinfo.batch : '',
          payer: userinfo ? userinfo.user : '',
          fees,
        });
      } else if (user == UserType.APPLICANT) {
        const applId = fees;
        resp = await graphqlMutation(createAdmissionPaymentOrderMutation, {
          applicationId: applId
        });
      }
      const { loading, data: createOrderData } = resp;
      if (!loading && createOrderData) {
        return createOrderData;
      } else if (typeof resp == 'string') {
        showError(resp || t("payment.order-generate-failure"))
      } else {
        showError(t("payment.order-generate-failure"))
      }
    } catch (err: any) {
      showError(t("payment.order-generate-failure"))
    }
  };

  const verifySignature = async (paymentId: string) => {
    try {
      const { loading, data: verifySignatureData } = await graphqlMutation(verifyPayment, {
        paymentId,
      });

      if (!loading && verifySignatureData) {
        return verifySignatureData;
      }
    } catch (error: any) {
      showError(error.message);
    }
  };

  const createRazorpayOptions = (title: string, orderid: string, key: string) => {
    return {
      key,
      name: t("edba.label"),
      // image: '',
      description: title,
      order_id: orderid,
      prefill: {
        name: properCase(currentUserObject?.userDetail?.fullName || '-'),
        email: currentUserObject?.email || '',
      },
      theme: {
        color: colors.primaryColor,
      },
    };
  };

  const onPay = async (paymentObj: any, user: UserType = UserType.INSTITUTE_STUDENT, setModalMessage?: Function, callback?: Function) => {
    const order = await createOrder(user == UserType.APPLICANT ? paymentObj : paymentObj.fees, user);
    if (order) {
      const orderId = user == UserType.APPLICANT ? order.createAdmissionPaymentOrder?.orderId : order.createOrder?.orderId;
      const key = user == UserType.APPLICANT ? order.createAdmissionPaymentOrder?.key : order.createOrder?.key;
      if (orderId && key) {
        RazorpayCheckout.open(
          createRazorpayOptions(paymentObj?.title, orderId, key),
        )
          .then(async (transaction: any) => {
            if (setPaymentList) setPaymentList(null);
            const validSignature = await verifySignature(transaction.razorpay_payment_id);
            if (validSignature) {
              showMessage({
                message: ADD_SUCCESS.PAYMENT_SUCCESSFULL,
                type: 'success',
                position: 'top',
                icon: 'success',
              });
              PaymentComplete();
            } else {
              if (paymentFailed) paymentFailed();
              showMessage({
                message: ERROR_MSG.PAYMENT_UNSUCCESSFUL,
                type: 'danger',
                position: 'top',
                icon: 'danger',
              });
            }
          })
          .catch((err: any) => {
            if (err.code === 2) {
              console.log('Payment cancelled by user');
            } else {
              showMessage({
                message: ERROR_MSG.GENERIC_ERROR,
                type: 'danger',
                position: 'top',
                icon: 'danger',
              });
            }
            console.log('error in payment in native', err);
          });
      }
      if (callback) callback();
    } else {
      if (setModalMessage) {
        setModalMessage({
          message: ERROR_MSG.PAYMENT_PROCESSING_FAILED,
          type: 'danger',
          position: 'top',
          icon: 'danger',
        });
      }
    }
  };

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(user: UserType, paymentObj: any, callback?: () => void) {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
      console.log('Razorpay SDK failed to load');
      return;
    }

    const order = await createOrder(user == UserType.APPLICANT ? paymentObj : paymentObj.fees, user);
    const orderId = user == UserType.INSTITUTE_STUDENT ? order?.createOrder?.orderId : order?.createAdmissionPaymentOrder?.orderId;
    const key = user == UserType.INSTITUTE_STUDENT ? order?.createOrder?.key : order?.createAdmissionPaymentOrder?.key;

    if (orderId && key) {
      const paymentObject = new window.Razorpay({
        ...createRazorpayOptions(paymentObj.title, orderId, key),
        handler: async function (response: any) {
          if (setPaymentList) setPaymentList(null);
          const validSignature = await verifySignature(response.razorpay_payment_id);
          if (validSignature) {
            PaymentComplete();
            setAlertDetails({ message: ADD_SUCCESS.PAYMENT_SUCCESSFULL, level: SUCCESS });
          } else {
            if (paymentFailed) paymentFailed();
            setAlertDetails({ message: ERROR_MSG.PAYMENT_UNSUCCESSFUL, level: ERROR });
          }
        },
      });

      paymentObject.on('payment.failed', function (response: any) {
        if (paymentFailed) paymentFailed();
        setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
      });
      paymentObject.open();
      if (callback) {
        callback();
      }
    } else {
      setAlertDetails({ message: ERROR_MSG.PAYMENT_PROCESSING_FAILED, level: ERROR });
    }
  }

  return { onPay, displayRazorpay };
};

export default usePayment;
