import React, { useEffect } from 'react'
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import styled from "styled-components/native";
import { isWeb } from 'src/constant/device';
import { userInformation } from 'src/utils/manageState';
import { useParams } from 'src/routes/routing';
import { useLoadUserDetails } from 'src/utils/customHooks/useLoadUserDetails'
import UserDetails from 'src/components/molecules/UserDetails/index';
import { colors } from 'src/styles/theme/styles';
import { Icon } from 'src/components/atoms/Icon/Icon';
import HeaderOne from 'src/components/atoms/Text/HeaderOne';
import MediumText from 'src/components/atoms/Text/MediumText';
import { useI18n } from 'src/i18n/hooks';
import { UserType } from 'src/constant';
import { ViewMode } from 'src/components/molecules/Forms/types';
import { getUserTypeTitle } from 'src/utils/utility';


interface OnBoardingNavigatorProps {
  currentstep: number;
  onFinishCallback: () => void;
  navigation: any
}
export default function OnboardingRoot(props: OnBoardingNavigatorProps) {

  const { t } = useI18n();

  const currentUserObject = userInformation();
  const { isLoading, userDetails, formContents, loadUserDetails } = useLoadUserDetails();

  useEffect(() => {
    loadUserDetails();
  }, [currentUserObject.userId])

  const getCurrentStep = () => {
    if (isWeb) {
      const { step } = useParams();
      return parseInt(step);
    } else {
      const {
        step
      } = props;
      return step || 0;
    }
  }

  return (
    <Container>
      {isLoading ? (
        <LoaderSpinnerContainner>
          <LoaderSpinner />
        </LoaderSpinnerContainner>
      ) : (
        <>
          {isWeb ?
            <TextView>
              <MainHeader>
                <HeaderOne value={t('onboarding.text3')} width={'auto'} />
                <Icon name="edba-logo" />
              </MainHeader>
              <MediumText value={`${t("onboarding.text2")} ${t(getUserTypeTitle(currentUserObject.userType))}`} />
            </TextView> :
            <></>
          }
          {
            currentUserObject?.userId && userDetails &&
            <FormContainer>
              <UserDetails
                step={getCurrentStep()}
                showSteps={true}
                userType={currentUserObject.userType}
                userId={currentUserObject.userId}
                formContents={formContents}
                isOnboarding={true}
                viewMode={ViewMode.EDIT}
                navigation={props.navigation && props.navigation}
                onFinishCallback={props.onFinishCallback && props.onFinishCallback}
              />
            </FormContainer>
          }
        </>
      )}
    </Container>
  )
}

const Container = styled.View`
  background-color: ${colors.white};
  height: 100%;
`

const LoaderSpinnerContainner = styled.View`
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const MainHeader = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const TextView = styled.View`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 68px 0px 35px 0px;
`;
const FormContainer = isWeb
  ? styled.View`
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    padding: 0 15px;
    box-sizing: border-box;
  `
  : styled.View`
    height: 100%;
    width: 100%;
  `;
