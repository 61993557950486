import {
  CustomReactNativeFile,
  HandleFilesAttachFuncType,
} from "../FileUpload/type";
import { Grid, Tooltip } from "@mui/material";
import React, { CSSProperties, Ref } from "react";
import { browserCompressImage, validateAttachments } from "src/utils/utility";
import { faTimesCircle, faUpload } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import ImageAtom from "src/components/atoms/ImageAtom/index.web";
import { ImageResizeMode } from "react-native";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import OutlineButtton from "src/components/atoms/Button/OutlineButtton";
import SmallTextAtom from "src/components/atoms/Text/SmallTextAtom";
import { colors } from "src/styles/theme/styles";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";

type Props = {
  name: string;
  viewOnly?: boolean;
  inputRef?: Ref<any>;
  height?: CSSProperties["height"];
  img?: string; // ImageURL
  header?: string;
  multiple?: boolean;
  fieldId?: string;
  allowedType?: string;
  mode?: ImageResizeMode;
  infoMessage?: string;
  onChange?: HandleFilesAttachFuncType;
  handleFileRemove?: (identifier: string) => void;
  setError?: any;
  clearErrors?: any;
};

export default function ImageUpload(props: Props) {
  const {
    img: imageURL,
    onChange,
    name,
    handleFileRemove,
    height = 280,
    header = "",
    viewOnly = false,
    multiple = false,
    fieldId,
    setError,
    clearErrors,
    mode,
    infoMessage = "error.max-size-20",
    allowedType = "image/*",
  } = props;

  const { t } = useI18n();

  const sanitizeFileObject = async (file: any) => {
    file.uri = URL.createObjectURL(file);
    return browserCompressImage(file);
  };

  const handleAttach = (files: CustomReactNativeFile[]) => {
    Promise.all<CustomReactNativeFile>(files.map(sanitizeFileObject)).then(
      (cfiles) => {
        if (
          onChange &&
          validateAttachments(fieldId ?? null, cfiles, setError, clearErrors)
        ) {
          onChange(cfiles);
        }
      }
    );
  };

  const handleChange = (ev: any) => {
    const files: any = Object.values(ev?.target?.files || []);
    handleAttach(files);
  };

  const handleDrag = (ev: any) => {
    const files: any = Object.values(ev?.dataTransfer?.files || []);
    handleAttach(files);
  };

  return (
    <>
      {!!header && (
        <TextWrapper>
          <SmallTextAtom value={t(header)} />
        </TextWrapper>
      )}

      <CustomGrid item height={height} imgExists={!!imageURL}>
        {imageURL ? (
          <ImageAtom
            source={{ uri: imageURL }}
            style={styles.fullImageView}
            mode={mode}
          />
        ) : (
          viewOnly && (
            <ImageAtom
              source={{ uri: "https://dev-api.edba.io/personal/photo/default" }}
              style={styles.fullImageView}
              mode={mode}
            />
          )
        )}

        {!viewOnly && imageURL && (
          <Tooltip title={t("remove.label")}>
            <RemoveIconContainer
              onClick={() => {
                if (handleFileRemove) {
                  handleFileRemove(name);
                }
              }}
            >
              <RemoveIconWrapper>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size={32}
                  color={colors.secondaryText}
                />
              </RemoveIconWrapper>
            </RemoveIconContainer>
          </Tooltip>
        )}

        {!viewOnly && !!imageURL && (
          <ChangeImageWrapper>
            <HiddenInput
              id={name}
              ref={props.inputRef}
              multiple={multiple}
              type="file"
              accept={allowedType}
              onChange={handleChange}
            />
            <label htmlFor={name}>
              <ChangeImageText>
                <NormaltextAtom
                  value={t("changeImage.text")}
                  color={colors.white}
                />
              </ChangeImageText>
            </label>
          </ChangeImageWrapper>
        )}

        {!viewOnly && !imageURL && (
          <UploadInputWrapper
            onDragOver={(ev) => {
              ev.preventDefault();
            }}
            onDrop={handleDrag}
          >
            <FontAwesomeIcon
              icon={faUpload}
              color={colors.secondaryText}
              size={14}
            />
            <SmallTextAtom value={t("dropImage.text")} />
            <SmallTextAtom value={t("or.label")} />
            <>
              <HiddenInput
                id={name}
                ref={props.inputRef}
                multiple={multiple}
                type="file"
                accept={allowedType}
                onChange={handleChange}
              />
              <label htmlFor={name}>
                <OutlineButtton
                  label={t("browseImage.text")}
                  onPress={() => { }}
                />
              </label>
            </>
          </UploadInputWrapper>
        )}
      </CustomGrid>
      {!viewOnly && (
        <TextWrapper>
          <SmallTextAtom value={t(infoMessage)} />
        </TextWrapper>
      )}
    </>
  );
}

const styles: Record<string, CSSProperties> = {
  fullImageView: {
    height: "100%",
    width: "100%",
    margin: "auto",
    marginBottom: 0,
    borderRadius: "inherit",
  },
};

const CustomGrid = styled(Grid) <{ imgExists: boolean }>`
  &.MuiGrid-root {
    max-width: 100% !important;
  }
  -webkit-background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  display: flex;
  max-width: 11;
  position: relative;
  background-color: ${({ imgExists }) =>
    imgExists ? colors.lighterBorder : colors.white};
  border: ${({ imgExists }) =>
    !imgExists ? `1px dashed ${colors.tertiaryText} ` : "0px"};
`;

const TextWrapper = styled.div`
  margin-bottom: 4px;
`;

const RemoveIconContainer = styled.div`
  border-radius: 50px;
  cursor: pointer;
  position: absolute;
  text-align: center;
  height: 40px;
  width: 40px;
  right: -16;
  top: -18;
`;

const RemoveIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  height: 40px;
  width: 40px;
`;

const ChangeImageWrapper = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: ${colors.secondaryText};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: ${colors.white};
  cursor: pointer;
  text-align: center;
`;

const ChangeImageText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  margin: 0px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const UploadInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  row-gap: 8px;
`;
