import React, { useState } from 'react';
import { Image, ImageResizeMode, StyleSheet } from 'react-native';

interface ImageProps {
  source: Object;
  style?: Object;
  mode?: ImageResizeMode;
}

let imageNotFount =
  'https://wallpaperboat.com/wp-content/uploads/2020/12/03/62926/error-404-17.jpg';

let noSource = {
  uri: imageNotFount,
};


export default function ImageAtom(props: ImageProps) {
  const { source, style,mode = "stretch", defaultImage=null} = props;
  const [isFailed, setFailImage] = useState<boolean>(false);

  
  function loadFalBack() {
    setFailImage(true);
  }
  
  return (
    <>
    {!isFailed ? 
    <Image
      {...props}
      source={isFailed ? noSource : source}
      style={[styles.imgStyle, style]}
      onError={() => loadFalBack()}
      resizeMode={mode}
    />: defaultImage}
    </>
  );
}

const styles = StyleSheet.create({
  imgStyle: {
    height: 32,
    width: 32,
    borderRadius: 50,
  },
});
