import React from 'react';
import { colors, fonts } from '../../../styles/theme/styles';
import styled, { useTheme } from 'styled-components/native';
import { isWeb } from 'src/constant/device';

interface props {
  value: any;
  color?: string;
  fontWeight?: string;
  lines?: number;
  lineHeight?: number;
  fontFamily?: string;
  testID?: string;
  width?:string;
}

export default function SmallTextAtom(props: props) {
  const {
    value = '',
    color = colors.secondaryText,
    fontWeight = 'normal',
    fontFamily = fonts.regular,
    lines,
    testID,
    width
  } = props;
  const { rem }: any = useTheme();

  const Text = styled.Text`
    color: ${color};
    font-style: normal;
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    font-size: ${(props) => props.theme.rem(isWeb ? 1.2 : 1.2)};
    line-height: ${(props) => props.theme.rem(isWeb ? 1.6 : 1.6)};
    width:${width};
  `;

  return (
    <Text allowFontScaling={false} numberOfLines={lines} testID={testID}>
      {value}
    </Text>
  );
}
