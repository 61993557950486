import React from 'react';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';

type SectionHeadingProps = {
  textLeft: string;
  textRight?: string | undefined;
  marginBottom?: number;
  marginTop?: number;
};

const SectionHeading: React.FC<SectionHeadingProps> = ({
  textLeft,
  textRight,
  marginBottom = 0,
  marginTop = 0,
}) => {
  return (
    <AnnouncementsTop>
      <CommonHeading marginBottom={marginBottom} marginTop={marginTop}>
        {textLeft}
      </CommonHeading>
      {textRight !== undefined && <AnnouncementsLink>{textRight}</AnnouncementsLink>}
    </AnnouncementsTop>
  );
};

export default SectionHeading;

type CommonHeadingProps = {
  marginBottom?: number;
  marginTop?: number;
};

const AnnouncementsTop = styled.View`
  flex-direction: row;
  align-items: center;
`;

const AnnouncementsLink = styled.Text`
  font-size: 16px;
  font-weight: 600;
  margin-left: auto;
  color: #2263a2;
`;

const CommonHeading = styled.Text<CommonHeadingProps>`
  font-size: 16px;
  font-weight: 600;
  font-family: ${fonts.semibold};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)}px;
  color: ${colors.primaryText}
`;
