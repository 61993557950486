import React, { useEffect, useState } from 'react';
import { userInformation } from 'src/utils/manageState';
import StudentAssignments from 'src/components/organism/Assignments/StudentAssignment';
import TeacherAssignments from 'src/components/organism/Assignments/Teacher';
import { getTokenFromStorage } from 'src/components/services/storage-service';
import { jwtdeCodeToken } from 'src/components/services/jwt-token-service';
import { View } from 'react-native';
import { userDetail } from 'src/graphql/reactive-variables/current-user';

const AssignmentRoot = (props) => {
  const currentUserObject = userInformation();

  const [userType, setUserType] = useState<any>();
  useEffect(() => {
    setUserType(currentUserObject?.userType);
  }, [currentUserObject]);

  useEffect(() => {
    getUserType();
  }, []);

  async function getUserType() {
    const TOKEN = await getTokenFromStorage();
    const loginEmployeeInformation: any = await jwtdeCodeToken(TOKEN);
    userDetail(loginEmployeeInformation?.userType);
  }

  const renderSwitch = (userType: number) => {
    switch (userType || userDetail()) {
      case 2:
        return <TeacherAssignments {...props} />;

      case 3:
        return <StudentAssignments {...props} />;

      default:
        return <View />;
    }
  };

  return <>{renderSwitch(userType)}</>;
};
export default AssignmentRoot;
