import { DropdownOptionsType } from 'src/types';

export const semesterOptions: DropdownOptionsType[] = [
  {
    label: 'N.A',
    value: '0',
  },
  {
    label: 'I',
    value: 'I',
  },
  {
    label: 'II',
    value: 'II',
  },
  {
    label: 'III',
    value: 'III',
  },
  {
    label: 'IV',
    value: 'IV',
  },
  {
    label: 'V',
    value: 'V',
  },
  {
    label: 'VI',
    value: 'VI',
  },
  {
    label: 'VII',
    value: 'VII',
  },
  {
    label: 'VIII',
    value: 'VIII',
  },
  {
    label: 'IX',
    value: 'IX',
  },
  {
    label: 'X',
    value: 'X',
  },
  {
    label: 'XI',
    value: 'XI',
  },
  {
    label: 'XII',
    value: 'XII',
  },
];
