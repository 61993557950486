import * as React from 'react';
import { DataGrid, GridColumns, GridSelectionModel } from '@mui/x-data-grid';
import styled from 'styled-components';
import { useLocation } from 'src/routes/routing';
import DownloadFile from 'src/components/atoms/DownloadFile/index';
import { Box, Paper, Popover, Stack, Tooltip } from '@mui/material';
import { fonts } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import IndicatorBadge from 'src/components/atoms/Badge/IndicatorBadge';
import { DetailStatusEnum } from 'src/constant';
import SmallButton from 'src/components/atoms/Button/SmallButton.web';
import { DeleteIcon, EditIcon } from 'src/components/atoms/ActionIcons';

function NoRowsOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" fontFamily={fonts.regular}>
      No records found
    </Stack>
  );
}

interface DataTableProps {
  addAction: boolean;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<{}>>;
  tableData?: any;
  columnData: any;
  handleDeleteEducationExperienceDetail: (row: any) => void;
  showCheckBox?: boolean;
  selectedRecords?: GridSelectionModel;
  setSelectedRecords?: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  reviewMode?: boolean;
  hideDeleteBtn?: boolean;
  viewApplication?: boolean;
}
const DataTable = (props: DataTableProps) => {
  const { t } = useI18n();
  const {
    tableData,
    columnData,
    handleDeleteEducationExperienceDetail,
    showCheckBox = false,
    selectedRecords,
    setSelectedRecords,
    reviewMode = false,
    hideDeleteBtn = false,
    viewApplication,
  } = props;
  const [columnsData, setColumnsData] = React.useState([]);
  const [rowsData, setRowsData] = React.useState([]);
  let pathname = '';
  const location = useLocation();
  pathname = location.pathname;
  const maxRows: number = viewApplication ? 4 : 5;

  React.useEffect(() => {
    structureData();
  }, [tableData, props.addAction]);

  function isSubmittedForReview(row: any) {
    return reviewMode && row.status === DetailStatusEnum.SUBMITTED_FOR_REVIEW
  }

  const onClick = (data: any) => {
    props.setOpenEdit(true);
    props.setSelectedData(data.row);
  };

  const structureData = () => {
    let colom: GridColumns = showCheckBox
      ? []
      : [
        {
          field: 'blank',
          headerName: '',
          width: 10,
          hide: true
        },
      ];
    let rows: any = [];

    columnData.forEach((key: string, index: number) => {
      if (key.id === 'documents') {
        colom.push({
          field: key.id,
          headerName: t(key.name).toLocaleUpperCase(),
          headerClassName: 'hideRightSeparator',
          renderCell: (cellValues: any) => {
            let files: any[] = [];
            if (cellValues.row.allDocument) {
              cellValues.row.allDocument.forEach((doc: any) => {
                if (doc.document) {
                  let alias;
                  if (doc.type?.name) {
                    alias = doc.type.name;
                  }
                  files.push({ ...doc.document, alias });
                }
              });
            } else {
              files = cellValues.row.documents;
            }
            return files && files.length > 0 ? <CellExpand files={files} /> : <></>;
          },
          width: 120,
          editable: false,
          align: 'center',
        });
      } else if (key.id === 'actions' && !viewApplication) {
        colom.push({
          field: t('actions'),
          width: 120,
          align: 'right',
          headerAlign: 'right',
          headerClassName: 'actionsHeader',
          renderCell: (cellValues: any) => {
            const { row, getValue } = cellValues;
            return (
              <>
                {props.addAction && (
                  <ActionIconsWrapper>
                    {(row.hasOwnProperty('institution') && row.institution === null) ? (
                      <SmallButton
                        label={t("addDetails.text")}
                        onPress={() => onClick({ row })}
                      />
                    ) : (
                      <EditIcon
                        onPress={() => onClick({ row })}
                        tooltipTitle={t("editDetail.label")}
                      />
                    )}

                    {!hideDeleteBtn && (
                      <DeleteIconWrapper>
                        <DeleteIcon
                          onPress={() => handleDeleteEducationExperienceDetail(row)}
                          tooltipTitle={t("deleteDetail.label")}
                        />
                      </DeleteIconWrapper>
                    )}
                  </ActionIconsWrapper>
                )}
              </>
            );
          },
        });
      } else if (key.id === 'indicatorBadge') {
        colom.push({
          field: key.id,
          headerName: '',
          width: 10,
          sortable: false,
          disableColumnMenu: true,
          minWidth: 18,
          headerClassName: 'hideRightSeparator',
          renderCell: (params) => (
            isSubmittedForReview(params?.row) && <IndicatorBadge />
          ),
        })
      } else if (key.id === "gradeObtained") {
        colom.push({
          field: key.id,
          headerName: t(key.name).toLocaleUpperCase(),
          headerClassName: 'hideRightSeparator',
          width: 182,
          renderCell: (cellValues: any) => {
            let r = cellValues?.row;
            let displayText;
            if (r?.marksObtained && r?.totalMarks) {
              displayText = `${r.marksObtained} / ${r.totalMarks}`;
            }
            if (r.gradeObtained) {
              displayText = r.gradeObtained;
            }
            return displayText;
          },
        });
      } else {
        if (!(viewApplication && key.id == 'actions')) {
          colom.push({
            field: key.id,
            headerName: t(key.name).toLocaleUpperCase(),
            headerClassName: 'hideRightSeparator',
            width: 160,
          });
        }
      }
    });

    if (tableData && tableData.length > 0) {
      rows = tableData.flatMap((element: any, i: number) => {
        if (element && element.id) {
          let obj = { ...element };
          return obj;
        }
        return [];
      });
    }
    setColumnsData(colom);
    setRowsData(rows);
  };
  return (
    <div style={{ height: maxRows * 80, width: '100%' }}>
      {columnsData.length > 0 && (
        <DataGrid
          rows={rowsData}
          columns={columnsData}
          pageSize={maxRows}
          rowHeight={55}
          rowsPerPageOptions={[maxRows]}
          components={{
            NoRowsOverlay: NoRowsOverlay,
          }}
          checkboxSelection={showCheckBox}
          isRowSelectable={(params) => isSubmittedForReview(params?.row)}
          disableSelectionOnClick
          onSelectionModelChange={(newSelectionModel) => {
            if (setSelectedRecords) setSelectedRecords(newSelectionModel);
          }}
          selectionModel={selectedRecords}
          disableColumnMenu={true}
          sx={{
              '& .MuiDataGrid-cell': {
                paddingRight: "0 !important"
              },
              '& .hideRightSeparator': {
                  '& > .MuiDataGrid-columnSeparator': {
                    visibility: 'hidden',
                  },
                  // visibility: 'hidden',
              },
              '& .actionsHeader': {
                '& .MuiDataGrid-columnHeaderTitle': {
                  marginRight: '8px',
                },
                '& > .MuiDataGrid-columnSeparator': {
                  visibility: 'hidden',
                },
              },
          }}
        />
      )}
    </div>
  );
};
export default DataTable;

const FileWrapper = styled.div`
`;

interface CellExpandProps {
  width: number;
  align?: string;
  files?: any;
  showDownloadIcon?: boolean;
  customStyle?: any;
  boxStyle?: any;
  lines?: number;
}

export const CellExpand = React.memo(function CellExpand(props: CellExpandProps) {
  const { width, files, align, showDownloadIcon, customStyle, boxStyle, lines } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    return;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Box
      ref={wrapper}
      sx={{
        justifyContent: align ?? 'normal',
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        '& .cellValue': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        cursor: 'pointer',
      }}>
      <>
        <Tooltip title="Attachments">
          <div onClick={handleClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none">
              <path
                d="M4.00859 5.00215V6.00195H5.0084V5.00215H4.00859ZM6.0082 2.00273H5.0084V3.00254H6.0082V2.00273ZM4.00859 3.00254V4.00234H5.0084V3.00254H4.00859ZM6.0082 4.00234H5.0084V5.00215H6.0082V4.00234ZM11.5571 3.0619L8.93576 0.440539C8.65456 0.159344 8.27338 0 7.87659 0H1.49971C0.671744 0.00312439 0 0.674868 0 1.50283V14.5003C0 15.3283 0.671744 16 1.49971 16H10.498C11.3259 16 11.9977 15.3283 11.9977 14.5003V4.12419C11.9977 3.7274 11.8383 3.3431 11.5571 3.0619ZM7.99844 1.62468L10.3761 4.00234H7.99844V1.62468ZM10.498 14.5003H1.49971V1.50283H3.98985V2.00273H4.98965V1.50283H6.49873V4.7522C6.49873 5.16774 6.83304 5.50205 7.24858 5.50205H10.498V14.5003ZM6.06757 8.30463C6.0332 8.12966 5.8801 8.00156 5.69889 8.00156H5.0084V7.00176H4.00859V8.00156L3.39309 11.0353C3.18688 12.0508 3.96173 13.0006 4.99902 13.0006C6.0332 13.0006 6.80805 12.057 6.60808 11.0447L6.06757 8.30463ZM5.0084 12.1914C4.44913 12.1914 3.99609 11.8133 3.99609 11.3478C3.99609 10.8823 4.44913 10.5042 5.0084 10.5042C5.56766 10.5042 6.0207 10.8823 6.0207 11.3478C6.0207 11.8133 5.56766 12.1914 5.0084 12.1914ZM6.0082 6.00195H5.0084V7.00176H6.0082V6.00195Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
          </div>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <Paper elevation={1} style={customStyle && customStyle}>
            <Box width={width} p={2} sx={{ boxSizing: 'border-box' }}>
              <FileWrapper>
                {files.map((file: any) => (
                    <DownloadFile
                      showStyle={false}
                      fileData={{ ...file }}
                      showDownloadIcon={showDownloadIcon}
                      lines={lines}
                    />
                ))}
              </FileWrapper>
            </Box>
          </Paper>
        </Popover>
      </>
    </Box>
  );
});

export function renderCellExpand(params: any) {
  return (
    <CellExpand value={params.value ? params.value.toString() : ''} width={params.colDef.width} />
  );
}

const ActionIconsWrapper = styled.div`
  display: flex;
  margin-right: 16px;
  justify-content: space-between;
`;

const DeleteIconWrapper = styled.div`
  margin-left: 8px;
`;
