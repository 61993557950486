import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import SubjectForm from 'src/components/molecules/Academics/SubjectForm.web';
import { useParams } from 'react-router';
import {
  getSubjectQuery,
  useDeleteSubject,
  useQuerySubject,
  useUpdateSubject,
  useAddSubject,
  useUpdateInstituteSubject,
} from 'src/graphql/academics/subjects';
import {
  AUTOCOMPLETE_MODULE,
  limit,
  SEARCH_TIME,
  ERROR,
  SUCCESS,
  USER_TYPE
} from 'src/constant/index';
import { useI18n } from 'src/i18n/hooks';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, createSortFilter, debounce } from 'src/components/services';
import { getSubjectFilter } from 'src/components/services/filters';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { userInformation } from 'src/utils/manageState';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { Tooltip } from '@mui/material';
import styled from 'styled-components/native';
import Filter from 'src/components/molecules/Filter/index.web';
import { height, width } from 'src/constant/device';
import { clipLongText } from 'src/utils/utility';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

const headCells1 = [
  {
    id: 'subject_name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'alias',
    isSorted: false,
  },
  {
    id: 'class_alias',
    label: 'class.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'course_alias',
    label: 'course.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'credit_points',
    label: 'creditPoints.label',
    align: 'right',
    disableSorting: true,
    style: {
      paddingRight: 30
    }
  },
  {
    id: 'actions',
    label: 'actions',
    align: 'right',
    disableSorting: true,
  },
];

const maxHeight = 440;

export default function Subjects() {
  const { theme } = useThemeSystem();
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    clearErrors,
    setError,
    formState: { errors },
    resetField,
    register,
  } = useForm({
    defaultValues: {
      color: '#000000',
      defaultCreditPoint: null,
    },
  });
  const [canClick, setCanClick] = useState(true);
  let searchValue = '' as string;

  let row;
  const { page = 1, dataLimit = limit }: any = useParams();
  
  const rowDefaultLimit =  parseInt(dataLimit) ?? 0;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);


  const { setHeading } = useHeaderTitle();
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;
  const isEdbaAdmin = userType === USER_TYPE.EDBA_ADMIN;
  const { filters, setFilter } = useFilter();
  const [modalState, handleModal] = useState(false);
  const [editSubjectData, setSubjectData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const { setAlertDetails } = useAlertSystem();
  const [searching, setSearchingSubject] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [filterHeight, setFilterHeight] = useState(0);
  const [currFilter, setCurrFilter] = useState<any>();
  const [creditPointArray, setCreditPointArray] = useState<any>([]);

  const { query: getSubjects, data: subjectData, refetch: refetchSubjects, loading } = useQuerySubject(
    getSubjectQuery,
    'network-only',
  );
 
  const { updateSubject } = useUpdateSubject();
  const { updateInstituteSubject } = useUpdateInstituteSubject();

  row = subjectData?.subjects?.data;
  const [totalCount, setTotalCount] = useState(subjectData?.subjects?.totalCount ?? null);
  
  
  if (filters && filters?.subject?.on) {
    searchValue = filters?.subject?.search;
  }
  const { addSubject, ErrorOccur } = useAddSubject(currFilter);
  const { deleteSubject } = useDeleteSubject(currentPage, searchValue, rowLimit, editSubjectData?.id);

  const filterItems = [
    {
      id: 1,
      label: 'Subject Name',
      type: 'string',
      localField: 'alias',
      filterType: 'filter'
    },
    {
      id: 2,
      label: 'Class Alias',
      type: 'string',
      localField: 'class',
      lookupKey: 'classes',
      propName: 'alias', // lookup will need propName
      filterType: 'lookup',
    },
    {
      id: 2,
      label: 'Course Alias',
      type: 'string',
      localField: 'course',
      propName: 'alias', // lookup will need propName
      filterType: 'lookup',
    }
  ];

useEffect(() => {
  const count = subjectData?.subjects?.totalCount;
  if(count){
    setTotalCount(count);
  }
}, [subjectData?.subjects?.totalCount]);

useEffect(() => {
  setCurrentPage(page);
}, [page]);

useEffect(() => {
  setRowLimit(rowDefaultLimit);
}, [rowDefaultLimit]);

  useEffect(() => {
    if (isEdbaAdmin) {
      if (headCells1[4]?.id !== 'default_credit_point') {
        const headCells = headCells1.splice(4, 0, {
          id: 'default_credit_point',
          label: 'defaultCreditPoint.label',
          align: 'right',
          disableSorting: true,
          style: {
            paddingRight: 30
          }
        });
        headCells1[3].style = { paddingRight: 30 };
        setHeadCells(headCells);
      }
    } else {
      if (headCells1[4]?.id === 'default_credit_point') {
        const headCells = headCells1.splice(4, 1);
        setHeadCells(headCells);
      }
    }
  }, [userType]);

  useEffect(() => {
    setSearchingSubject(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setTitle();
  }, []);

  function setTitle() {
    setHeading([
      {
        text: t('subjects.label'),
        url: '',
      },
    ]);
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
  );

  useEffect(() => {
    handleFetch();
  }, [searchValue, headCells, rowLimit]);

  useEffect(() => {
    changePage();
  }, [currentPage]);

  const handleFilters = (filter: any) => {
    const subjectFilter = {
      limit: rowLimit,
      skip: (currentPage - 1) * rowLimit,
      ...filter,
    };
    setCurrFilter(subjectFilter);
    getSubjects({ variables: subjectFilter });
  };

  function changePage() {
    if (currFilter && Object.keys(currFilter)?.length) {
      getSubjects({
        variables: {
          ...currFilter,
          skip: (currentPage - 1) * rowLimit,
        },
      });
    }
  }

  function handleFetch() {
    const filters = getSubjectFilter(searchValue);
    const sortCriteria = createSortFilter(headCells);
    const subjectFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters, sortCriteria);
    setCurrFilter(subjectFilter);
    getSubjects({ variables: subjectFilter });
  }

  function handleRefetch() {
    let filters = getSubjectFilter(searchValue);

    const sortCriteria = createSortFilter(headCells);

    let subjectFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters, sortCriteria);
    setCurrFilter(subjectFilter);
    refetchSubjects({ variables: subjectFilter });
  }

  const editSubjectModal = (selectedSubject: any) => {
    setSubjectData(selectedSubject);
    hideAlert();
    setIsEditModal(true);
    handleModal(true);
  };

  const deleteSubjectModal = (selectedSubject: any) => {
    setSubjectData(selectedSubject);
    setDeleteOverlay(true);
  };

  const addSubjectModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  const closeModal = () => {
    handleModal(false);
    setCanClick(true);
    resetForm();
    setCreditPointArray([]);
    if (isEditModal) {
      setIsEditModal(false);
      setSubjectData({});
    }
  };

  function resetForm() {
    setValue('university', null);
    setValue('course', null);
    setValue('class', null);
    setValue('code', '');
    setValue('name', '');
    setValue('alias', '');
    setValue('type', null);
    setValue('color', '');
    setValue('semester', null);
    setValue('defaultCreditPoint', null);
    clearErrors();
  }

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  const handleAddSubject = async (subjectData: any) => {
    setCanClick(false);
    try {
      let addSubjectObject = {
        course: subjectData.course?.value,
        class: subjectData.class?.value,
        name: subjectData.name,
        alias: subjectData.alias,
        type: subjectData.type?.value,
        code: subjectData.code,
        color: subjectData.color,
        semester: subjectData.semester.value,
      };
      if (isEdbaAdmin) {
        if (creditPointArray && creditPointArray.length > 0) {
          addSubjectObject['creditPoints'] = creditPointArray;
        }
        if (subjectData?.defaultCreditPoint?.value) {
          addSubjectObject['defaultCreditPoint'] = subjectData.defaultCreditPoint.value;
        }
      }

      let addSubjectResponse = await addSubject({ variables: { payload: addSubjectObject } });
      if (addSubjectResponse.data && !ErrorOccur) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.SUBJECT, level: SUCCESS });
        handleRefetch()
      }
      setCanClick(true);
    } catch (e: any) {
      console.log(e);
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateSubject = async (subjectData: any) => {
    setCanClick(false);
    if (isEdbaAdmin) {
      adminSubjectUpdation(subjectData);
    } else {
      instituteSubjectUpdation(subjectData);
    }
  };

  const adminSubjectUpdation = async (subjectData: any) => {
    try {
      let editSubjectObject = {
        id: editSubjectData?.id,
        course: subjectData.course?.value,
        class: subjectData.class?.value,
        type: subjectData?.type?.value,
        name: subjectData.name,
        alias: subjectData.alias,
        code: subjectData.code,
        color: subjectData.color,
        semester: subjectData.semester.value,
        creditPoints: creditPointArray,
      };

      if (subjectData?.defaultCreditPoint?.value)
      editSubjectObject['defaultCreditPoint'] = subjectData.defaultCreditPoint.value;

      let updateSubjectResponse = await updateSubject({
        variables: { payload: editSubjectObject },
      });
      if (updateSubjectResponse.data) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.SUBJECT, level: SUCCESS });
        handleRefetch()
      }
      setCanClick(true);
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const instituteSubjectUpdation = async (subjectData: any) => {
    try {
      let editSubjectObject = {
        moduleId: editSubjectData?.id,
        name: subjectData.name,
        alias: subjectData.alias,
        color: subjectData.color,
      };

      let updateSubjectResponse = await updateInstituteSubject({
        variables: { payload: editSubjectObject },
      });
      if (updateSubjectResponse.data) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.SUBJECT, level: SUCCESS });
        handleRefetch()
      }
      setCanClick(true);
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleDeleteSubject = async () => {
    setCanClick(false);
    try {
      let deleteResponse = await deleteSubject({ variables: { id: editSubjectData?.id } });
      if (deleteResponse?.data?.deleteSubject?.status === 'success') {
        setDeleteOverlay(false);
        setAlertDetails({ message: DELETE_SUCCESS.SUBJECT, level: SUCCESS });
        handleRefetch()
      } else {
        throw new Error(deleteResponse?.data?.deleteSubject?.message || t('error.deleteSubject.message'));
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setDeleteOverlay(false);
    }
    setCanClick(true);
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.SUBJECT]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchSubjects(subject: string) {
    let isClear = subject?.length === 0;
    persistSearch(!isClear, subject);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchSubjects(q), SEARCH_TIME),
    [],
  );
  const handleSearch = (subject: string) => {
    setSearchingSubject(subject);
    delayedQuery(subject);
  };

  return (
    <>
      <LoadContentWrapper>
        <FlexRowView isEdbaAdmin={isEdbaAdmin}>
          <SearchWrapper>
            <Search
              id="subjectSearch"
              handleChange={handleSearch}
              value={searching}
              height={32}
              label={t('searchSubject.text')}
            />
          </SearchWrapper>

          <FilterWrapper filterHeight={filterHeight} isEdbaAdmin={isEdbaAdmin}>
            <Filter
              filterItems={filterItems}
              handleFilters={handleFilters}
              setFilterHeight={setFilterHeight}
              searchValue={searchValue}
            />
          </FilterWrapper>

          {isEdbaAdmin ? (
            <AddSubjectsWrapper>
              <SecondaryBtn label={t('addSubject.text')} onPress={addSubjectModal} />
            </AddSubjectsWrapper>
          ) : null}
        </FlexRowView>
        <TblContainer height={'100%'}>
          <TblHead setHeadCells={setHeadCells} />
          <>
            {!loading ? (
              <>
                {recordsAfterPagingAndSorting()?.length > 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                      <TableRows key={i}>
                        <TableCells
                          value={clipLongText(`${item?.alias} (${item?.name})`, 45)}
                          style={{ width: '25%' }}
                        />
                        <TableCells
                          value={clipLongText(item?.class?.alias, 24)}
                          style={{ width: isEdbaAdmin ? '15%' : '20%' }}
                        />
                        <TableCells
                          value={clipLongText(item?.course?.alias, 24)}
                          style={{ width: isEdbaAdmin ? '15%' : '20%' }}
                        />
                        <TableCells
                          align="right"
                          style={{ width: '15%', paddingRight: 30 }}
                          value={
                            isEdbaAdmin
                              ? item?.creditPoints && item?.creditPoints.length > 0
                                ? item?.creditPoints.map((creditPoint: any, i: number) => {
                                    return (
                                      creditPoint +
                                      `${i !== item?.creditPoints.length - 1 ? ', ' : ''}`
                                    );
                                  })
                                : '-'
                              : item?.defaultCreditPoint || '-'
                          }
                        />

                        {Boolean(isEdbaAdmin) && (
                          <TableCells
                            align="right"
                            style={{ width: '20%', paddingRight: 30 }}
                            value={item?.defaultCreditPoint || '-'}
                          />
                        )}

                        <TableActions align="right">
                          <TableActionView>
                            <View>
                              <Tooltip title="Edit Subject">
                                <TouchableOpacity onPress={() => editSubjectModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    color={theme?.table?.editIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            </View>
                            {isEdbaAdmin ? (
                              <IconView>
                                <Tooltip title="Delete Subject">
                                  <TouchableOpacity onPress={() => deleteSubjectModal(item)}>
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      color={theme?.table?.deleteIconColor}
                                    />
                                  </TouchableOpacity>
                                </Tooltip>
                              </IconView>
                            ) : null}
                          </TableActionView>
                        </TableActions>
                      </TableRows>
                    ))}
                  </TableBody>
                ) : (
                  <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
                )}
              </>
            ) : (
              <SpinnerWrapper>
                <LoaderSpinner />
              </SpinnerWrapper>
            )}
          </>
        </TblContainer>

        <Pagination
          pathName={'academics/subjects'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          hidePagination={totalCount <= rowLimit}
        />
      </LoadContentWrapper>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={() => setDeleteOverlay(false)}
        modalVisible={deleteOverlay}
        handleSave={handleDeleteSubject}
        Headerpopup="deleteSubject.text">
        {t('deleteSubject.warning')}
      </DeleteModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        modalVisible={modalState}
        onAlert={null}
        width={578}
        Headerpopup={isEditModal ? t('editSubject.text') : t('addSubject.text')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}
        handleSave={handleSubmit(isEditModal ? handleUpdateSubject : handleAddSubject)}>
        <SubjectForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          reset={reset}
          resetField={resetField}
          register={register}
          editSubjectData={editSubjectData}
          isEditModal={isEditModal}
          // hidePagination={totalCount <= rowLimit}
          setError={setError}
          clearErrors={clearErrors}
          setCreditPointArray={setCreditPointArray}
          creditPointArray={creditPointArray}
        />
      </NormalModal>
    </>
  );
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${(props) => (props.isEdbaAdmin ? 'space-between' : 'flex-start')};
  z-index: 10;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  margin-right: 8px;
`;

const FilterWrapper = styled.View`
  left: 0;
  flex: 1;
  align-items: flex-start;
  height: ${(props) => props.filterHeight * 32}px;
  width: ${(props) => (props.isEdbaAdmin ? (width > 1700 ? 75 : 72) : width > 1700 ? 80 : 80)}%;
`;

const AddSubjectsWrapper = styled.View`
  flex: 1;
  align-items: flex-end;
  max-width: 140px;
  min-width: 112px;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const IconView = styled.View`
  margin-left: 13px;
`;

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;