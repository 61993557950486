import ImageResizer from '@bam.tech/react-native-image-resizer';
import imageCompression from 'browser-image-compression';
import { isNative, isWeb } from 'src/constant/device';
import { mediums, othersOption } from 'src/form-json/user-details/dd-options';
import { addOrUpdateTokenListInStorage, getStorageFunction, setRefreshTokenToStorage, setTokenToStorage } from 'src/components/services/storage-service';
import { KEYS } from 'src/constant/key';
import config from 'src/config/config.json';
import { AttachmentType, CustomReactNativeFile } from 'src/components/molecules/FileUpload/type';
import { AUDIENCE_TYPE, FileCategory, DetailStatusEnum, statusArray, UserType, ApplicationStatus, PaymentMode, PAYMENT_PROOF_TYPE, EMPLOYEE_TYPES } from 'src/constant';
import { showMessage } from 'react-native-flash-message';
import ERROR_MSG from 'src/constant/error';
import { isEmpty } from 'lodash';
import { FormField, FormSchema } from 'src/components/molecules/Forms/types';
import { decodeToken, isAuthenticated, setAuthUserData } from 'src/graphql/common';
import { authToken, userInformation } from './manageState';
import { StatusActivity, paymentFormData, paymentPayload } from '../components/organism/Admission/types';
import { AnyObject, DropdownOptionsType } from 'src/types';
import { map as lodashmap } from 'lodash';
import { CSSProperties } from 'react';
import { fetchWithAuthentication } from 'src/components/services/image-service';
import { Dispatch, SetStateAction } from 'react';

// to update onboarding JSON education stream dropdown based on institute type
export const updateOnboardingJSONWithStreams = (formDataJSON: any, instType: string, formContents?: any) => {
  formDataJSON.forEach((item: any) => {
    if (item.key === 'educationDetails') {
      item.fields.forEach((field: any) => {
        if (field?.id === 'basic_education_details') {
          field.fields.forEach((subfield: any) => {
            if (subfield.id === 'streams' && instType === 'SCHOOL') {
              subfield.option = mediums;
              subfield.textHead = 'form.mediums.label';
              subfield.required_error = 'error.mediums.required';
            }
          });
        }
      });
    }
    if (item.key == "parentDetails" && formContents && formContents.occupations) {
      item.fields.forEach((field: any) => {
        field.fields.forEach((subfield: any) => {
          if (subfield.id === 'occupation') {
            subfield.option = formContents.occupations;
          }
        });
      });
    }
  });
};

export const nativeCompressImage = async (file: CustomReactNativeFile) => {
  return new Promise<CustomReactNativeFile>((resolve, reject) => {
    ImageResizer.createResizedImage(file.uri, 1000, 1600, 'JPEG', 90, 0)
      .then((response) => {
        file.uri = response.uri;
        file.size = response.size;
        resolve(file);
      })
      .catch((err) => {
        console.log('Error compressing image:', err);
        reject(null);
      });
  });
};

export const browserCompressImage = async (file: CustomReactNativeFile) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  return new Promise<any>(async (resolve, reject) => {
    try {
      const compressedFile: any = await imageCompression(file as any, options);
      compressedFile.uri = URL.createObjectURL(compressedFile)
      resolve(compressedFile);
    } catch (error) {
      reject(null);
    }
  });
};

// to create documentObject for attachments upload
export const createDocumentObject = async (
  fileData: CustomReactNativeFile,
  category: string,
  type?: string,
  typeRef?: string,
) => {
  if (isEmpty(fileData)) {
    return null;
  }
  let isImage = fileData && fileData.type && imageTypes.includes(fileData.type.toLowerCase());

  const uploadFile: any = isImage
    ? isWeb
      ? await browserCompressImage(fileData)
      : await nativeCompressImage(fileData)
    : fileData;

  const doc: any = {
    category: category,
    documentName: fileData.name,
    document: uploadFile,
  }
  if (typeRef) {
    doc["typeRef"] = typeRef;
  } else {
    doc.type = type;
  };
  return doc;
};

export const sanitizeDocFromAPI = (doc: any) => {
  return new CustomReactNativeFile({
    name: doc.documentName,
    uri: doc.documentUrl,
    size: doc.size,
    type: doc.type,
  });
}

export const trimObj = (obj: any) => {
  if (obj === null || (!Array.isArray(obj) && typeof obj !== 'object')) return obj;
  return Object.keys(obj).reduce(
    (acc: any, key: any) => {
      acc[key] = typeof obj[key] === 'string' ? obj[key].trim() : trimObj(obj[key]);
      return acc;
    },
    Array.isArray(obj) ? [] : {},
  );
};

export const isPresentInArray = (value: any, arr: any) => {
  if (value && Array.isArray(arr)) {
    for (let i = 0; i < arr.length; i++) {
      if (value === arr[i].value) return true;
    }
  }
  return false;
}

export const getLabel = (value: any, arr: any) => {
  if (!isPresentInArray(value, arr)) {
    if (typeof value == 'string') {
      return value;
    } else {
      return value.label;
    }
  };
  for (let i = 0; i < arr.length; i++) {
    if (value === arr[i].value) return arr[i].label;
  }
}

export const populateStreams = (schema: any, instituteType: any, formContents: any) => {
  schema?.fields[0]?.fields?.map((subField: any) => {
    if (subField['name'] === 'streams') {
      if (Array.isArray(formContents?.streams) && formContents.streams.length > 0) {
        let streamMediumsOptions = [...formContents.streams];
        if (instituteType === 'SCHOOL') {
          streamMediumsOptions = [...formContents.mediums];
          subField.label = 'form.mediums.label';
          subField.validate[0].message = 'error.mediums.required';
        }
        streamMediumsOptions.push(othersOption);
        subField['options'] = streamMediumsOptions;
      }
    }
  })
}

export const populateOccupations = (schema: any, formContents: any) => {

  schema?.fields?.map((subFields: any) => {
    subFields.fields.map((field: any) => {
      if (field['name'].includes('occupation')) {
        if (Array.isArray(formContents?.occupations) && formContents.occupations.length > 0) {
          field['options'] = formContents?.occupations;
        }
      }
    })
  })
}

export const isObject = (value: any) => {
  return (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    value !== null
  )
}
export const populateReligions = (schema: any, formContents: any) => {

  schema?.fields?.map((subFields: any) => {
    subFields.fields.map((field: any) => {
      if (field.name == 'religion') {
        if (Array.isArray(formContents?.religions) && formContents.religions.length > 0) {
          let religionOptions = [...formContents?.religions];
          religionOptions.push(othersOption);
          field.options = religionOptions;
        }
      }
    })
  })
}

const getImageObject = (field: any, values: any) => {

  let docuemnt = isWeb ? values[field['name']].document : values[field['name']].document[0];
  const { category, documentType } = field;
  const fileObject = {
    category: category,
    documentName: docuemnt.name,
    document: docuemnt,
    type: documentType
  };

  return fileObject;
}

function convertToTypeString(value: any, convertToString: boolean) {
  return convertToString ? value.toString() : value;
}

function createPayload(
  payload: any = {},
  fieldName: string,
  otherFieldName: string,
  values: any,
  groupBy?: string,
  convertToString: boolean = false,
) {
  let subfieldName;
  if (groupBy) {
    subfieldName = fieldName.replace(`${groupBy}.`, '');
    fieldName = groupBy;
  }
  const value = values && values[fieldName];

  if (
    typeof value != 'boolean' &&
    typeof value != 'number' &&
    isEmpty(value)
  ) {
    return;
  }
  if (typeof value === 'object') {
    if (!payload.hasOwnProperty(fieldName)) {
      payload[fieldName] = {};
      if (value && value.hasOwnProperty('id')) {
        payload[fieldName]['id'] = value['id'];
      }
    }
    createPayload(
      payload[fieldName],
      subfieldName || fieldName,
      otherFieldName,
      value,
      '',
      convertToString,
    );
  } else {
    payload[fieldName] =
      (otherFieldName && convertToTypeString(values[otherFieldName], convertToString)) ||
      convertToTypeString(value, convertToString);
  }
}

function sanitizePayload(payload: any, fields: FormField[], values: any) {
  fields.map((subFormFields) => {
    subFormFields.fields?.map((field) => {
      if (field.type === 'file' && values[field.name]) {
        if (payload.documents) {
          payload.documents.push(values[field.name])
        } else {
          payload.documents = [values[field.name]]
        }
      } else if (field.type === "dynamic-file-input") {
        const documentField = field?.parentType ? values?.[field.parentType]?.documents : values[field?.name];
        if (documentField && documentField.length > 0) {
          if (field?.parentType) {
            payload[field.parentType].documents = documentField.filter((doc: any) => !doc["uri"]);
          } else {
            payload.documents = documentField.filter((doc: any) => !doc["uri"]);
          }
        }
      } else if (field.type === 'image') {
        if (values?.field?.name && values[field['name']]['document']) {
          payload[field['name']] = getImageObject(field, values);
        }
      } else if (field.name === 'majorSubjectMarks') {
        if (values[field.name] || values[field['name']]) {
          payload[field['name']] = values[field.name];
        }
      } else if (field.component === 'custom-select') {
        if (values[field.name]) {
          payload[field['name']] = values[field.name].value ? values[field.name].value : values[field.name];
        }
      } else {
        if (!field.ignoreField) {
          createPayload(
            payload,
            field.name,
            field.otherFieldName,
            values,
            subFormFields.groupBy,
            field?.convertToString,
          );
        }
      }
    })
  })
}

export const getFileObject = (doc) => {
  if (Object.keys(doc)?.length > 0) {
    return {
      category: doc.category,
      document: isWeb ? doc.document : doc.document[0],
      documentName: isWeb ? doc.documentName : doc.document[0].name,
      type: doc.type
    }
  }
}

export const getMultiDocFileObject = (doc) => {
  if (Object.keys(doc)?.length > 0) {
    return {
      category: doc.category,
      document: isWeb ? doc.document : doc.document[0],
      documentName: isWeb ? doc.documentName : doc.document[0].name,
      typeRef: doc.typeRef
    }
  }
}

export function getSanitizedPayload(schema: FormSchema, values: any) {
  let payload: any = {};
  const name = schema.name;
  let fields = schema.fields;
  if (schema.outerFields) {
    sanitizePayload(payload, schema.outerFields, values);
  }
  if (values && values.hasOwnProperty('profilePicture') && values['profilePicture']?.document) {
    payload['profilePicture'] = getFileObject(values['profilePicture'])
  }
  sanitizePayload(payload, schema.fields, values);

  if (values && values.hasOwnProperty('id') && name !== "personalDetails") {
    payload['id'] = values['id'];
  }
  let sanitizedPayload: any = {
    [name]: payload
  };
  return sanitizedPayload;
}

function isJSON(str: string) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export async function downloadFileRestAPI(apiURl: string, fileName: string, item: any = "", errCallback?: (err?: any) => void) {
  let token = await getStorageFunction(KEYS.ACCESS_TOKEN);
  const headers = new Headers();
  headers.set('authorization', `Bearer ${token}`);
  headers.set('mode', 'no-cors');
  headers.set('Access-Control-Allow-Origin', '*');
  if(item !== ""){
     headers.set('body', JSON.stringify(item.academic));
  }
  const baseUrl = config.Server.url.replace('/graphql', '');
  apiURl = `${baseUrl}/${apiURl}`
  fetch(apiURl, { headers })
    .then((response) => {
      if (response.status == 200 || response.status == 201) {
        const resFilename = response.headers.get('filename');
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = `${resFilename || fileName}`;
          a.click();
        });
      } else {
        response?.text().then((err) => {
          console.log(";er", err);
          if (errCallback) {
            errCallback(isJSON(err) ? JSON.parse(err) : err)
          }
        })
      }
    })
    .catch((error) => {
      console.log('error', error);
      if (errCallback) {
        errCallback(error)
      }
    });
}

// assets type was copied from react-native-image-picker/launchImageLibrary
const maxFileSize = 20 * 1024 * 1024;
export const imageTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/*'
] as const;

const allowedtypes = [
  ...imageTypes,
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const validateAttachments = (
  fieldId: string | null,
  assets: CustomReactNativeFile[],
  setError: any,
  clearErrors: any,
  showNativeMessage?: boolean,
  fileTypeAllowed: string = 'file',
): boolean => {
  try {
    let allowed = true;
    const allowedTypes = fileTypeAllowed === 'image' ? imageTypes : allowedtypes;
    if (fieldId) {
      clearErrors && clearErrors(fieldId);
    }
    assets && assets.forEach((asset) => {
      if (asset.size && asset.size > maxFileSize) {
        if (fieldId) {
          console.log("error filesize", fieldId, asset?.size);
          setError && setError(fieldId, { message: ERROR_MSG.FILE_SIZE_EXCEEDS });
        }
        if (showNativeMessage) {
          showMessage({
            message: ERROR_MSG.FILE_SIZE_EXCEEDS,
            type: 'danger',
            position: 'top',
            icon: 'danger',
          })
        }
        allowed = allowed && false;
      }
      if (asset.type && !allowedTypes.includes(asset.type)) {
        if (fieldId) {
          console.log("error filetype", fieldId, asset.type, setError);
          setError && setError(fieldId, { message: ERROR_MSG.INVALID_FILE_TYPE });
        }
        allowed = allowed && false;
      }
    });
    return allowed;
  } catch (err) {
    console.log("Validating err", err);
  }
  return false;
}

export const validateTotalSizeOfAttachment = (assets: CustomReactNativeFile[] | AttachmentType[]): boolean => {
  let flag = false;
  let totalsize = 0;
  assets.forEach((file) => {
    if (file && file?.size) {
      totalsize += file.size;
    }
  })
  flag = totalsize <= (5 * maxFileSize)
  return flag;
}

const escapeRegExp = (value: any) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const getFilteredRows = (searchValue: string, initialValues: any) => {

  const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
  const filteredRows = initialValues?.filter((row: any) => {
    return Object.keys(row).some((field) => {
      return row[field] !== null ? searchRegex.test(row[field].toString()) : false;
    });
  });
  return filteredRows;
};

export const isValidHexColorCode = (code: String) => {
  return code && code.match("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$");
}

export const mergeBatchClassLabel = (data: []) => {
  let options = [] as any;
  data.forEach(function (item: any) {
    options.push({
      ...item,
      label: `${item?.label} / ${item?.class?.alias}`
    });
  });

  return options;
}

export function clipLongText(name: any, limit: number): string {
  // if text is too long then truncate and add ellipsis
  // else return unchanged
  return (parseFloat(name?.length) > limit) ? (name.substring(0, limit - 2) + '...') : name;
}

export function assigneeLabels(data: any) {
  if (data?.assigneeType === AUDIENCE_TYPE.BATCH) {
    if (data?.assigneeLabels?.length > 1) {
      return 'multiple';
    } else return data?.assigneeLabels[0]?.label;
  } else if (data?.assigneeType === AUDIENCE_TYPE.GROUP) {
    if (data?.assigneeLabels?.length > 1) {
      return `${data?.assigneeLabels?.length} groups`;
    } else return data?.assigneeLabels[0]?.label;
  } else if (data?.assigneeType === AUDIENCE_TYPE.STUDENTS) {
    return `${data?.assigneeLabels[0]?.label}`;
  }
}

export async function setAuthData(
  data: { token: string; refresh: string; },
  history: any,
  location: any,
  authCallback?: any,
  prefix?: string,
  requestId?: string,
  capId?: string,
  inviteCode?: string,
) {
  const newData: any = decodeToken(data.token);
  authToken({ edbaToken: data.token });
  await setTokenToStorage(data.token);
  if (isNative) {
    await addOrUpdateTokenListInStorage(
      data.token,
      data.refresh,
    );
  }
  if (isWeb) {
    await setAuthUserData({
      data: newData,
      history: history,
      location: location,
      type: 'login',
      ...(prefix ? { prefix } : {}),
      ...(requestId ? { requestId } : {}),
      ...(capId ? { capId } : {}),
      ...(inviteCode ? { inviteCode } : {}),
    });
  } else {
    await setRefreshTokenToStorage(data.refresh);
    const authenticated = await isAuthenticated();
    authCallback(authenticated);
  }
}

// TODO: refactor this along with method Userdetails#renderDetails.
export const ensureDetail = (detail: any) => {
  if (
    (detail?.status != DetailStatusEnum.VERIFIED || detail?.status != DetailStatusEnum.REJECTED)
    && detail?.draft?.detail
  ) {
    return detail.draft.detail;
  } else {
    return detail;
  }
}

export function getUserTypeTitle(type: UserType) {
  switch (type) {
    case UserType.INSTITUTE_EMPLOYEE:
      return "employee.label";
    case UserType.INSTITUTE_STUDENT:
      return "student.label";
    case UserType.APPLICANT:
      return "applicant.label";
    default:
      return "user.label";
  }
};

export const hasUnverifiedRecord = (element: { status: DetailStatusEnum }) =>
  element?.status && !statusArray.includes(element.status);

export const isRecordSubmittedForReview = (element: { status: DetailStatusEnum }): boolean => {
  return element?.status === DetailStatusEnum.SUBMITTED_FOR_REVIEW;
}

// Moved from fee composition detail page as required in admission fee composition part also
export const createFeeCompositionStructure = (feeCompData: any) => {
  const feeComps = [] as any;
  const createObj = (newItem: any) => {
    return {
      id: newItem.id,
      groupId: newItem.groupId,
      groupName: newItem.groupName,
      amount: newItem.fee.amount,
      orderNo: Math.trunc(newItem?.orderNo),
      groupInfo: [
        {
          id: newItem.id,
          name: newItem.fee.name,
          amount: newItem.fee.amount,
          orderNo: newItem?.fee?.orderNo || 0,
        },
      ],
    };
  };

  const getBatches = (payers: any) => {
    const batches = [] as any;
    payers.forEach((payer: any) => {
      if (!batches.includes(payer.name)) {
        batches.push(payer.name);
      }
    })
    return batches;
  };

    if (feeCompData && feeCompData.length > 0) {
      feeCompData.forEach((composition: any) => {
        if (composition.feeBooks.length === 1) {
          feeComps.push({
            id: composition.id,
            compositionName: composition.feeBooks[0].compositionName,
            batches: getBatches(composition.payers),
            payers: composition.payers,
            totalPayment: composition.feeBooks[0].amount,
            feeBooks: [createObj(composition.feeBooks[0])],
            bankAlias: composition.bankAlias,
          });
        } else {
          let obj = {};
          let miniarr = [];
          let totalPayment = 0;
          composition.feeBooks.forEach((item: any) => {
            if (!obj[item.groupId]) {
              obj[item.groupId] = createObj(item);
            } else {
              obj[item.groupId].amount = obj[item.groupId].amount + item.fee.amount;
              obj[item.groupId].groupInfo.push({
                id: item.id,
                name: item.fee.name,
                amount: item.fee.amount,
                orderNo: item?.fee?.orderNo || 0,
              });
            }
          });
  
          for (let element in obj) {
            totalPayment = totalPayment + obj[element].amount;
            miniarr.push(obj[element]);
          }
          feeComps.push({
            id: composition.id,
            compositionName: composition.feeBooks[0].compositionName,
            batches: getBatches(composition.payers),
            payers: composition.payers,
            totalPayment,
            feeBooks: miniarr,
            bankAlias: composition.bankAlias,
          });
          obj = {};
          miniarr = [];
          totalPayment = 0;
        }
      });
    }

  return feeComps;
}

export const checkRemark = (statusActivity: StatusActivity[], status: string, remark?: any) => {
  const remarkObject = statusActivity
    ?.filter((item: any) => item?.status === status)
    ?.reduce((a, b) => (a?.date > b?.date ? a : b), {});

  if (remarkObject?.remarks) {
    const reason = remarkObject?.remarks;
    const title =
      status === ApplicationStatus.BLOCKED || status === 'BLOCK'
        ? 'blockReason.label'
        : 'rejectionReason.label';

    const data: any = {
      "reason": reason,
      "title": title
    };

    if (status === ApplicationStatus.BLOCKED && remarkObject?.user) {
      const userFullName = remarkObject.user?.fullName
        ? remarkObject.user?.fullName
        : "--";
      data.user = userFullName;
    }

    if (remark) {
      remark.current = data;
    }

    return { exist: true, data };
  }

  return { exist: false, data: null };
};

export function makeVariables(keyVal: AnyObject, values: any, typeFilter: boolean = true) {
  let variables: any = {};
  if (keyVal) {
    Object.keys(keyVal).forEach((key) => {
      const val = keyVal[key];
      if (typeof val == 'string') {
        if (typeFilter) {
          variables[key] = { eq: values[val]?.value || val };
        } else {
          variables[key] = values[val]?.value || val;
        }
      } else {
        variables[key] = makeVariables(val, values);
      }
    });
  }
  return typeFilter
    ? {
        filters: variables,
        limit: 0,
        skip: 0,
      }
    : variables;
}

export const compareAndSort = (a: any, b: any, property: string) => {
  if ( a[property] < b[property] ){
    return -1;
  }
  if ( a[property] > b[property] ){
    return 1;
  }
  return 0;
}

export const sortModuleData = (data: any, property?: string) => {
  if (data?.length > 1) {
    data?.sort((a: any, b: any) => compareAndSort(a, b, 'orderNo'));
  }
  if (property) {
    data?.forEach((item: any) => {
      if (item[`${property}`] && item[`${property}`]?.length > 1) {
        item[`${property}`]?.sort((a: any, b: any) => compareAndSort(a, b, 'orderNo'));
      }
    });
  }
  return data;
};

export const getApplicationPath = (
  path: string,
  prefix?: string,
  requestId?: string,
  capId?: string,
  inviteCode?: string,
) => {
  if (inviteCode || (prefix && requestId)) {
    if (inviteCode) {
      path += `/${inviteCode}`;
    } else {
      path += `/${prefix}/${requestId}`;
      if (capId) path += `/${capId}`;
    }
  }
  return path;
};

export const properCase = (text: string) => {
  return text?.toLowerCase()
    .replace(/_/g, ' ')
    .replace(/(?: |\b)(\w)/g, function (key) {
      return key.toUpperCase();
    }) ?? "-";
};

export const createPaymentDetailObject = async (
  formData: paymentFormData,
  payload: paymentPayload,
  acceptPaymentDoc: boolean = true,
  allowAmount: boolean = false,
) => {
  const currentUserObject = userInformation();
  const isInstituteAdmin = currentUserObject?.userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN);
  const mode = isWeb ? formData?.mode?.value : formData?.mode;

  if (mode !== PaymentMode.ONLINE) {
    payload.paymentDetail['payerBank'] = formData?.bank_name;
    if (mode !== PaymentMode.CASH || (mode === PaymentMode.CASH && isInstituteAdmin)) {
      payload.paymentDetail['paymentDate'] = formData?.date;
    }
    if (allowAmount && formData.payment_amount) {
      payload.paymentDetail['amount'] = parseFloat(formData.payment_amount);
    }
  }

  if (
    mode !== PaymentMode.ONLINE &&
    formData?.payment_proof &&
    Object.keys(formData.payment_proof)?.length > 0 &&
    !formData.payment_proof?.id &&
    acceptPaymentDoc
  ) {
    const transactionDocument = await createDocumentObject(
      formData.payment_proof,
      FileCategory.Payment,
      PAYMENT_PROOF_TYPE,
    );
    payload.paymentDetail['transactionDoc'] = transactionDocument.document;
  }

  if (mode !== PaymentMode.ONLINE && formData?.note) {
    payload.paymentDetail['note'] = formData.note;
  }

  switch (mode) {
    case PaymentMode.CHEQUE:
      payload.paymentDetail['payId'] = formData.cheque_number?.toString();
      payload.paymentDetail['amount'] = parseFloat(formData.payment_amount)
      payload.paymentDetail['status'] = formData.status?.value || 1
      break;
    case PaymentMode.DRAFT:
      payload.paymentDetail['payId'] = formData.draft_number?.toString();
      break;
    case PaymentMode.OFFLINE_NEFT:
      payload.paymentDetail['payId'] = formData.neft_transaction_id;
      payload.paymentDetail['amount'] = parseFloat(formData.payment_amount)
      payload.paymentDetail['status'] = formData.status?.value || 1
      break;
    case PaymentMode.LOAN:
      payload.paymentDetail['payId'] = formData.loan_account_id;
      break;
    case PaymentMode.DONATION:
      payload.paymentDetail['payId'] = formData.transaction_id;
      if (formData?.donated_by) {
        payload.paymentDetail['donatedBy'] = formData.donated_by;
      }
      if (formData?.donated_to) {
        payload.paymentDetail['donatedTo'] = formData.donated_to;
      }
      break;
    case PaymentMode.ONLINE:
      payload.paymentDetail['payId'] = formData.transaction_id;
      break;
  }
  return payload;
};

export function stableSortStrings(
  arr: any[],
  paramName: string,
  sortInAscendingOrder: boolean = true
): any[] {
  const indexedArr = lodashmap(arr, (str, index) => {
    const paramValue = str[paramName];
    return {
      str,
      paramValueAsNumber: Number(paramValue),
      isNumeric: !isNaN(Number(paramValue)),
      index,
    };
  });

  indexedArr.sort((a, b) => {
    if (a.isNumeric && b.isNumeric) {
      return sortInAscendingOrder
        ? a.paramValueAsNumber - b.paramValueAsNumber
        : b.paramValueAsNumber - a.paramValueAsNumber;
    }

    if (!a.isNumeric && !b.isNumeric) {
      return sortInAscendingOrder
        ? String(a.str[paramName]).localeCompare(String(b.str[paramName]))
        : String(b.str[paramName]).localeCompare(String(a.str[paramName]));
    }

    if (a.isNumeric) return -1;
    if (b.isNumeric) return 1;

    return a.index - b.index;
  });

  return lodashmap(indexedArr, "str");
}

type cellStyle = {
  trow: boolean;
  current: any;
  id: number;
  isFirstCell: boolean;
  isLastCell : boolean;
  itemId: number | string;
}

export const getCellStyle = ({
  trow,
  current,
  id,
  isFirstCell = false,
  isLastCell = false,
  itemId
}:cellStyle) => {
  if ((trow && id === itemId) || (trow && current === id)) {
    if(isFirstCell){
      return {
        borderLeft: '2px solid rgba(43, 120, 202, 0.3)',
        borderTop: '2px solid rgba(43, 120, 202, 0.3)',
        backgroundColor: '#EAF2FA',
      } as CSSProperties;
    } 
    if(isLastCell){
      return {
        borderRight: '2px solid rgba(43, 120, 202, 0.3)',
        borderTop: '2px solid rgba(43, 120, 202, 0.3)',
        backgroundColor: '#EAF2FA',
      } as CSSProperties;
    } 
    return {
      borderTop: '2px solid rgba(43, 120, 202, 0.3)',
      backgroundColor: '#EAF2FA',
    }
  }else {
      return ''
  }
}

export const createAttachmentsObject = (
  attachments: CustomReactNativeFile[],
  setImageAttachments: Dispatch<SetStateAction<AttachmentType[]>>,
  setFileAttachments: Dispatch<SetStateAction<AttachmentType[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  tokenData?: string | null,
  category?: string,
) => {
  let tempImages: AttachmentType[] = [];
  let imageAttachments: AttachmentType[] = [];
  let fileAttachments: AttachmentType[] = [];
  let imageAttachmentsCount: number = 0;
  attachments.forEach((item: AttachmentType) => {
    if (item?.fileType && imageTypes.includes(item.fileType)) {
      imageAttachmentsCount += 1;
      if (isWeb) {
        try {
          const response = fetchWithAuthentication(item?.uri);
          response
            .then((response) => response.blob())
            .then((data) => {
              const objectUrl = URL.createObjectURL(data);
              let newImageObj = {
                uri: objectUrl,
                name: item.name,
                category: category,
                id: item?.id,
                fileType: item?.fileType,
              };
              imageAttachments.push(newImageObj);
              if (imageAttachments.length === imageAttachmentsCount) {
                setImageAttachments(imageAttachments);
                setLoading(false);
              }
            });
        } catch (error) {
          console.log('error', item?.id);
        }
      } else {
        if (tokenData) {
          tempImages.push({
            id: item?.id,
            name: item?.name,
            uri: item?.uri,
            headers: { authorization: tokenData },
          });
          if (tempImages.length === imageAttachmentsCount) {
            setLoading(false);
          }
        }
      }
    } else {
      fileAttachments.push({
        ...item,
        category: category,
      });
    }
  });
  setFileAttachments(fileAttachments);
  if (!isWeb) {
    setImageAttachments(tempImages);
  }
  if (imageAttachmentsCount === 0) setLoading(false);
};

export const getTableValue = (value: number | string | null | undefined, nullValue?: string) => {
  if (typeof value === 'number' && value >= 0) {
    return Number.isInteger(value) ? value : value.toFixed(2);
  }
  return value || (nullValue || 'N/A');
}

export function createFilter(limit: number, id: string, property: string) {
  const params = {
    limit: limit,
    filters: { [property]: { eq: id } },
  };
  return params;
}

export function focusNextElement() {
  if (document?.activeElement) {
    const focused = document.activeElement;
    const nodeList = Array.prototype.slice.call(document.getElementsByClassName('marksInput'));
    const nextElement = document.getElementsByClassName("marksInput")[nodeList.indexOf(focused) + 1];
    if (nextElement) {
      nextElement.focus();
    }
  }
}
