import { WatchQueryFetchPolicy, gql, useLazyQuery, useMutation } from '@apollo/client';
import { createDeleteData, createSearchFilter } from 'src/components/services';
import { getLanguageFilter } from 'src/components/services/filters';
import { readCacheQuery, writeCacheQuery } from '../util';

const addLanguageMutation = gql`
  mutation ($payload: LocalizationLanguageInput!) {
    createLanguage(payload: $payload) {
      id
      name
    }
  }
`;

const updateLanguageMutation = gql`
  mutation ($payload: LocalizationLanguageInput!) {
    updateLanguage(payload: $payload) {
      id
      name
    }
  }
`;

const deleteLanguageMutation = gql`
  mutation ($id: String!) {
    deleteLanguage(id: $id) {
      status
      message
    }
  }
`;

const getLanguagesQuery = gql`
  query ($limit: Int, $skip: Int, $filters: LocalizationLanguageFilterInput) {
    languages(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        id
        name
        keyValuesUpdatedAt
      }
    }
  }
`;

const getLanguageQuery = gql`
  query ($id: String!) {
    language(id: $id) {
      id
      name
    }
  }
`;

const CLIENT_SIDE_KEY_VALUES = gql`
  query keyValues($language: String) {
    keyValuesByLanguage @client {
      key {
        name
      }
      value
    }

    localizationKeyValues(language: $language) {
      data {
        key {
          name
        }
        value
      }
    }
  }
`;

const GET_REACTIVE_VARIABLE = gql`
  query getReactiveVariable {
    keyValuesVar @client
  }
`;

export {
  addLanguageMutation,
  updateLanguageMutation,
  deleteLanguageMutation,
  getLanguagesQuery,
  getLanguageQuery,
  CLIENT_SIDE_KEY_VALUES,
  GET_REACTIVE_VARIABLE,
};

export function useGetLanguage() {
  const [getLanguage, { loading, data: languagesData, error: languagesError }] =
    useLazyQuery(getLanguagesQuery);
  return { getLanguage, languagesData, languagesError };
}

export function useMutateLanguage(queries: any) {
  const [mutate, { error }] = useMutation(queries);
  return { mutate, error };
}

export function useQueryLanguage(queries: any, fetchPolicy: WatchQueryFetchPolicy = 'cache-first') {
  const [query, { loading, data, refetch }] = useLazyQuery(queries, {
    fetchPolicy: fetchPolicy,
    nextFetchPolicy: fetchPolicy,
  });
  return { query, data, loading, refetch };
}

export function useDeleteLanguage(page: number, searchValue: string, rowLimit: number, languageId: string) {
  const [deleteLanguage, { error: deleteLanguageError, data: deleteLanguageData }] = useMutation(
    deleteLanguageMutation,
    {
      update(cache, { data: { deleteLanguage } }) {
        let queryData: any;
        let filters = getLanguageFilter(searchValue);
        let languageFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
        try {
          queryData = readCacheQuery(cache, getLanguagesQuery, languageFilter);
        } catch (e) {
          //console.log(e.messgae);
        }

        let languageObject = queryData?.languages;
        let message = deleteLanguage.message;
        // let languageId = message.replace('Successfully deleted language for ', '');
        let dataToUpdate = createDeleteData(languageObject, languageId);
        if (deleteLanguage.status == 'success') {
          //write
          try {
            writeCacheQuery(cache, getLanguagesQuery, languageFilter, {
              languages: dataToUpdate,
            });
          } catch (e) {
            //console.log('error', e.messgae);
          }
          return null;
        }
      },
    },
  );

  return { deleteLanguage, deleteLanguageError, deleteLanguageData };
}
