import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import AddExamFormJSON from 'src/form-json/assessment/assessment-exam-add-exam-form.json';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useGetSubjectWiseClassTestDetails } from 'src/graphql/assessment/assessment-exams';

interface addEditExamFormType {
  selectedBatch: string;
  control: any;
  errors: any;
  checkboxTableData: NestedCheckBoxTableData;
  setCheckboxTableData: Dispatch<SetStateAction<NestedCheckBoxTableData>>;
  reset: any;
  setValue: any;
  isEditModal?: boolean;
  currentItem: any;
}

export default function AddEditExamForm(props: addEditExamFormType) {
  const { selectedBatch, control, errors, checkboxTableData, setCheckboxTableData, reset, setValue, isEditModal = false, currentItem } = props;
  const [elements, setElements] = useState({});
  const [currentItemTestIds, setCurrentItemTestIds] = useState<any>([]);
  const [searchData, setSearchData] = useState('');
  const { fields }: any = elements ?? {};

  const { getSubjectWiseClassTestDetails, SubjectWiseClassTestDetailsData, SubjectWiseClassTestDetailsError, SubjectWiseClassTestDetailsLoading } = useGetSubjectWiseClassTestDetails();

  let checker = (arr: string | any[], target: any[]) => target.every(v => arr.includes(v));

  useEffect(() => {
    setElements(AddExamFormJSON[0]);
    if (!isEditModal) {
      reset();
    } else {
      setValue("examName", currentItem?.name);
      if (currentItem?.tests) {
        let ci_testIds: string[] = [];
        currentItem.tests.forEach((elem: any) => {
          elem.tests.forEach((test) => {
            ci_testIds.push(test.id);
          });
        });
        setCurrentItemTestIds(ci_testIds);
      }
    }
  }, []);

  function makeCheckBoxTableData(sdata: any[]) {
    const data: any[] = [];
    if (!isEditModal) {
      const selected = false;
      sdata?.forEach((dt) => {
        const subData: any[] = [];
        dt?.tests?.forEach((test: any) => {
          subData.push({
            id: test.id,
            label: test.name,
            value: test.id,
            selected,
          });
        });
        data.push({
          id: dt.subject.id,
          label: dt.subject.name,
          value: subData,
          selected,
        });
      });
    }
    else {
      sdata?.forEach((dt) => {
        const subData: any[] = [];
        let allTestIdsInCurrentSubject: any[] = [];

        dt?.tests?.forEach((test: any) => {
          allTestIdsInCurrentSubject.push(test.id);
          subData.push({
            id: test.id,
            label: test.name,
            value: test.id,
            selected: currentItemTestIds.includes(test.id),
          });
        });
        data.push({
          id: dt.subject.id,
          label: dt.subject.name,
          value: subData,
          selected: checker(currentItemTestIds, allTestIdsInCurrentSubject),
          order: getSubjectOrder(dt.subject.id),
        });
      });
    }
    return data;
  }

  useEffect(() => {
    if (SubjectWiseClassTestDetailsData?.subjectWiseClassTestDetails?.data) {
      const data = makeCheckBoxTableData(SubjectWiseClassTestDetailsData?.subjectWiseClassTestDetails?.data);
      setCheckboxTableData(data);
    }
  }, [SubjectWiseClassTestDetailsData, currentItemTestIds]);

  useEffect(() => {
    if (selectedBatch) {
      handleFetch(selectedBatch);
    }
  }, [selectedBatch]);

  function handleFetch(selectedBatch: string) {
    getSubjectWiseClassTestDetails({
      variables: {
        "filters": {
          "batch": {
            "eq": selectedBatch
          }
        }
      }
    });
  }

  async function searchSubjectOnchange(queryVariable: any) {
    setSearchData(queryVariable);
    if (queryVariable.length > 0) {
      let param = {
        variables: {
          "filters": {
            "batch": {
              "eq": selectedBatch
            },
            "name": {
              "regex": queryVariable
            }
          }
        }
      };

      await getSubjectWiseClassTestDetails(param);
    } else {
      handleFetch(selectedBatch);
    }
  }

  const getSubjectOrder = (subjectId: string) => {
    const currentSubject = currentItem?.tests?.find((item: any) => item.subject.id === subjectId);
    return Number.isInteger(currentSubject?.order) ? currentSubject.order : null;
  }

  return (
    <View style={styles.parentContainer}>
      {fields ? (
        fields.map((field: any, i: number) => (
          <View style={styles.inputFieldContainer}>
            <Element
              key={`ae${i}`}
              field={field}
              control={control}
              errors={errors}
              checkboxTableData={checkboxTableData}
              setSelectedCheckboxValues={setCheckboxTableData}
              searchBoxOnChange={searchSubjectOnchange}
              searchData={searchData}
            />
          </View>
        ))
      ) : (
        <View style={styles.spinnerView}>
          <LoaderSpinner />
        </View>
      )}
    </View>
  );
}


const styles = StyleSheet.create(
  {
    parentContainer: { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' },
    inputFieldContainer: { width: '100%' },
    spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' }
  }
);
