import { TFormMode, TFormState } from "src/types";
import { format, parse } from "date-fns";

import { DT } from "src/constant/dateTime";

export type TAdmissionLink = {
  id: string;
  subjectGroup: { id: string; value: string };
  applicantType: string;
  requestId: string;
  prefix: string;
  expiryDate: string;
};

export type TAdmissionLinksForm = {
  state: TFormState;
  mode: TFormMode;
  data?: TAdmissionLink;
};

const transformInitialFormData = (initialValues: TAdmissionLink) => {
  const { expiryDate, subjectGroup, prefix, applicantType } = initialValues;
  const parsed = parse(
    expiryDate,
    DT.DATE_TIME_FORMAT_WITH_TIMEZONE,
    new Date()
  );
  return {
    prefix,
    expiryDate: format(parsed, DT.DATE_FORMAT_SLASH),
    subjectGroup: { label: subjectGroup.value, value: subjectGroup.id },
    applicantType: { label: applicantType, value: applicantType },
  };
};

const fieldMap = {
  subjectGroup: 0,
  applicantType: 1,
  expiryDate: 2,
  prefix: 3,
};

export const AdmissionLinkHelpers = {
  transformInitialFormData,
  fieldMap,
};
