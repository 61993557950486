const linking = {
    prefixes: ['edba://'     /* your linking prefixes */
    ],
    config: {
        screens: {
            NATIVE_DASHBOARD: {
                path: "native_dashboard",
            }
        },
    },
};

export default linking;
