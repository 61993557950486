import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { graphqlQuery } from 'src/graphql/util';
import { mergeBatchClassLabel } from 'src/utils/utility';
import selectionForm from 'src/form-json/assessment/assessment-batch-class-selection.json';
import { useForm } from 'react-hook-form';
import { useHistory } from 'src/routes/routing.web';
import { useI18n } from 'src/i18n/hooks';

interface BatchClassSelectionFormProps {
  retrieveValues: any;
  urlBatchId?: string;
  pagePath: string;
  setParentBatchId: any;
  setParentClassId: any;
  setParentAllFormValuesAvailable: any;
  isLoading: boolean;
  setMarksType?: any;
}

export default function BatchClassSelectionForm(props: BatchClassSelectionFormProps) {
  const { rem }: any = useTheme();
  const [elements, setElements] = useState<any>({});
  const [filteredObject, setFilteredObject] = useState<any>({});
  const { retrieveValues,setMarksType, urlBatchId = null, pagePath, setParentBatchId, setParentClassId, setParentAllFormValuesAvailable, isLoading } = props;
  const { fields }: any = elements || {};
  const { t } = useI18n();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    onLoadFunction();
    // explicit null of batch is required, else batch values will not fill from url
    if (urlBatchId) {
      setValue("batch", null);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(filteredObject).length != 0) {
      setValue("batch", { label: `${filteredObject?.label} / ${filteredObject?.class?.alias}`, value: filteredObject?.value , type: filteredObject.settings.gradingType});
      setParentBatchId(filteredObject.value);
      setParentClassId(filteredObject.class.id);
      // flags (true) that all form values are available. this is required to correctly identify
      // whether or not to display no records found banner
      setParentAllFormValuesAvailable(true);
    }
  }, [filteredObject]);

  function filterByValue(arrayOfObject: any[], searchTerm: string) {
    // function that returns the entire object on which passed search Term exactly matches 
    var result = arrayOfObject.filter(function (v, i) {
      return v.value.indexOf(searchTerm) >= 0;
    });
    if (result.length != 0) {
      // batch id from url is valid i.e filtered result obj is not empty
      setFilteredObject(result[0]);
      setMarksType(result[0].settings.gradingType);
    } else {
      history.replace(pagePath);
      // if no valid result is found i.e invalid batch id in url then reset url
    }
  }

  async function onLoadFunction() {
    let { data: allBatches, loading } = await graphqlQuery(getBatchQuery, { limit: 0 });
    if (!loading && allBatches?.batches?.data) {
      let options = mergeBatchClassLabel(allBatches?.batches?.data);
      setElements({});
      selectionForm[0].fields[0].option = options;
      setElements(selectionForm[0]);

      // if batch id is present in url (passed from parent) then
      // filter out batch object with matching id value
      if (urlBatchId) {
        filterByValue(allBatches?.batches?.data, urlBatchId);
      }
    }
  }

  return (
    (fields ?
      <BatchClassSelectionFormWrapper>
        {
          fields.map((field: any, i: number) => {
            return (
              <FormWrapper
                key={`s${i}`}>
                <Element
                  key={`selection${i}`}
                  field={field}
                  control={control}
                  errors={errors}
                  dynamicValidation={false}
                  isCustomDisable={false}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              </FormWrapper>
            );
          })
        }

        <SecondaryBtn
          canLoad={isLoading}
          label={t('search.label')}
          onPress={handleSubmit(retrieveValues)}
          width={rem(7.9)}
          style={{ height: rem(3.6), marginTop: rem(2) }}
          secondary={false}
        />
      </BatchClassSelectionFormWrapper>
      :
      <FormSpinnerView>
        <LoaderSpinner />
      </FormSpinnerView>
    )
  );
}

const BatchClassSelectionFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FormWrapper = styled.div`
  flex-direction: row;
  z-index: 50;
  width: ${(props) => props.theme.rem(50)};
  margin-right: ${(props) => props.theme.rem(2.4)};
`;

const FormSpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.rem(80)};
  height: 82.63px;
`;
