import { TextField, InputAdornment } from '@mui/material';
import React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { colors, fonts } from 'src/styles/theme/styles';
import SmallTextAtom from '../Text/SmallTextAtom';
import { useThemeSystem } from 'src/contexts/theme-context';
import Svg, { Path } from 'react-native-svg';
import styled from 'styled-components';

interface Props {
  value: any;
  setValue: (cmt: any) => void;
  postComment: () => void;
  header: string;
  width?: number;
}

export default function Comments(props: Props) {
  const { value, setValue, postComment, header, width = 350 } = props;

  const searchInputOnchange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <>
      {header && (
        <View style={styles.helperText}>
          <SmallTextAtom value={header} />
        </View>
      )}

      <View style={styles.container}>
        <CustomInput
          variant="outlined"
          id={'cmt'}
          InputProps={{
            endAdornment: [
              <InputAdornment customWidth={width} position="end" key={0}>
                <TouchableOpacity onPress={postComment}>
                  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <Path
                      d="M0 0L2.325 10.9641L16.5047 12L2.325 13.0359L0 24L24 12L0 0Z"
                      fill={(value?.length > 0) ? colors.primaryColor : colors.tertiaryText}
                    />
                  </Svg>
                </TouchableOpacity>
              </InputAdornment>,
            ],
          }}
          inputProps={{
            style: {
              padding: '6.5px 0px',
            },
          }}
          value={value}
          placeholder={''}
          // classes={{ root: classes.root }}
          onChange={(e) => searchInputOnchange(e)}
        />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  helperText: {
    marginBottom: 4,
  },
});

const CustomInput = styled(TextField)`
.MuiInputAdornment-root {
  width: ${(props: any) => (props.customWidth ? props.customWidth : null)};
}
.MuiOutlinedInput-root {
  height: 40;
  color: ${colors.secondaryText};
  font-family: ${fonts.regular};
  font-size: 15;
  padding: 10;
  background-color: ${(props) => props?.theme?.search?.backgroundColor};
  & .MuiOutlinedInput-input {
    color: ${colors.secondaryText};
  }
  & .MuiInputBase-input {
    color: ${colors.primaryText};
  }
  & fieldset {
    border-color: ${(props) => props?.theme?.search?.borderColor};
  }
  &:hover fieldset {
    border-color: ${(props) => props?.theme?.search?.hoverBorder};
  }
  &.Mui-focused fieldset {
    border-color: ${(props) => props?.theme?.search?.activeborder};
    box-shadow: 0px 0px 5px ${colors.searchShadow};
    border-width: 1;
  }
}
`;
