import { height } from 'src/constant/device';
import { colors } from './styles';

export const lightTheme = {
  paletteName: 'light',
  header: {
    backgroundColor: colors.bluishGreyBackground,
    messageIconColor: colors.secondaryText,
    notifyIconColor: colors.secondaryText,
    titleColor: colors.secondaryText,
    username: colors.secondaryText,
  },
  sidebar: {
    backgroundColor: colors.white,
    menuColor: colors.primary,
    fontSize: 16,
    menu: {
      color: colors.secondaryText,
      backgroundColor: colors.menuSelectionBg,
      menuIconColor: colors.secondaryText,
      arrowIconColor: colors.secondaryText,
      menuHoverColor: colors.menuIconHover,
    },
    logo: {
      backgroundColor: colors.white,
    },
  },
  mainContainer: {
    backgroundColor: colors.bluishGreyBackground,
  },
  content: {
    backgroundColor: colors.white,
    padding: 32,
    maxWidth: 1210,
    height: height - 144,
  },
  table: {
    backgroundColor: colors.white,
    color: colors.primaryText,
    height: height - 314,
    header: {
      backgroundColor: colors.white,
      color: colors.secondaryText,
    },
    editIconColor: colors.secondaryText,
    deleteIconColor: colors.secondaryText,
  },
  search: {
    backgroundColor: colors.white,
    borderColor: colors.lighterBorder,
    hoverBorder: colors.secondaryText,
    activeBorder: colors.primary,
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  switch: {
    thumbColor: colors.primary,
    offSwitchBack: colors.black,
    color: colors.white,
  },
  outlineButton: {
    backgroundColor: colors.white,
    color: colors.primary,
  },
  dialog: {
    backgroundColor: colors.white,
    titleColor: colors.primaryText,
  },
  input: {
    backgroundColor: colors.white,
    borderColor: colors.tertiaryText,
    hoverBorder: colors.primaryText,
    activeBorder: colors.primary,
    disabledBorder: colors.lighterBorder,
    topLabel: colors.secondaryText,
    bottomLabel: colors.secondaryText,
    marginBottom: 24,
    width: 350,
  },
  calendarPicker: {
    backgroundColor: colors.white,
    borderColor: colors.tertiaryText,
    hoverBorder: colors.primaryText,
    activeBorder: colors.primary,
    disabledBorder: colors.lighterBorder,
    topLabel: colors.secondaryText,
    bottomLabel: colors.secondaryText,
    marginBottom: 24,
  },
  searchDropdown: {
    backgroundColor: colors.white,
    borderColor: colors.tertiaryText,
    hoverBorder: colors.primaryText,
    activeBorder: colors.primary,
    disabledBorder: colors.lighterBorder,
    topLabel: colors.secondaryText,
    marginBottom: 24,
  },
  checkbox: {
    color: colors.primary,
  },
  languageIcon: {
    color: colors.languageIcon,
  },
  native: {
    content: {
      width: 235,
    },
  },
  events: {
    card: {
      textColor: colors.white,
    },
    description: {
      textColor: colors.tertiaryText,
    },
  },
};
