import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 24 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
        <Path
          d="M16 6H14.75H14.45C14.2187 4.85938 13.2094 4 12 4C10.7906 4 9.78125 4.85938 9.55 6H9.25H8C6.89688 6 6 6.89688 6 8V18C6 19.1031 6.89688 20 8 20H16C17.1031 20 18 19.1031 18 18V8C18 6.89688 17.1031 6 16 6ZM8.5 7.5V8.25C8.5 8.66563 8.83437 9 9.25 9H12H14.75C15.1656 9 15.5 8.66563 15.5 8.25V7.5H16C16.275 7.5 16.5 7.725 16.5 8V18C16.5 18.275 16.275 18.5 16 18.5H8C7.725 18.5 7.5 18.275 7.5 18V8C7.5 7.725 7.725 7.5 8 7.5H8.5ZM11.25 6.5C11.25 6.30109 11.329 6.11032 11.4697 5.96967C11.6103 5.82902 11.8011 5.75 12 5.75C12.1989 5.75 12.3897 5.82902 12.5303 5.96967C12.671 6.11032 12.75 6.30109 12.75 6.5C12.75 6.69891 12.671 6.88968 12.5303 7.03033C12.3897 7.17098 12.1989 7.25 12 7.25C11.8011 7.25 11.6103 7.17098 11.4697 7.03033C11.329 6.88968 11.25 6.69891 11.25 6.5Z"
          fill="black"
          fill-opacity="0.6"/>
        <Path
          d="M12.8904 13.8929C12.5701 13.8929 12.4161 14.0714 11.8904 14.0714C11.3647 14.0714 11.2118 13.8929 10.8904 13.8929C10.0623 13.8929 9.39038 14.5647 9.39038 15.3929V15.6786C9.39038 15.9743 9.63034 16.2143 9.9261 16.2143H13.8547C14.1504 16.2143 14.3904 15.9743 14.3904 15.6786V15.3929C14.3904 14.5647 13.7185 13.8929 12.8904 13.8929ZM13.3904 15.3H10.3904V15.2143C10.3904 15.07 10.3591 14.89 10.8904 14.89C11.0533 14.89 11.3178 15.07 11.8904 15.07C12.4674 15.07 12.7263 14.89 12.8904 14.89C13.4216 14.89 13.3904 15.07 13.3904 15.2143V15.3ZM11.8904 13.7143C12.7777 13.7143 13.4975 12.9944 13.4975 12.1071C13.4975 11.2199 12.7777 10.5 11.8904 10.5C11.0031 10.5 10.2832 11.2199 10.2832 12.1071C10.2832 12.9944 11.0031 13.7143 11.8904 13.7143ZM11.8904 11.5C12.2265 11.5 12.5016 11.771 12.5004 12.1071C12.4992 12.4421 12.2253 12.71 11.8904 12.71C11.5555 12.71 11.2816 12.4421 11.2804 12.1071C11.2792 11.771 11.5543 11.5 11.8904 11.5Z"
          fill="black"
          fill-opacity="0.6"/>
    </Svg>
  );
}

export default SvgComponent;