import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getQualificationsQuery = gql`
  query ($filters: QualificationNameFilterInput, $limit: Int, $skip: Int, $sort: QualificationNameSortInput) {
    qualificationNames(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        name
        alias
        level
      }
      hasNextPage
      totalCount
    }
  }
`;

export const createQualificationMutation = gql`
  mutation ($payload: QualificationNameInput!) {
    createQualificationName(payload: $payload) {
      id
      name
      alias
      level
    }
  }
`;

export const getOptionsQualificationsQuery = gql`
  query ($filters: QualificationNameFilterInput, $limit: Int, $skip: Int, $sort: QualificationNameSortInput) {
    qualificationNames(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        value: id
        label: name
      }
      hasNextPage
      totalCount
    }
  }
`;

export function useGetQualifications() {
  const [getQualifications, { loading, data, refetch }] = useLazyQuery(getQualificationsQuery, { fetchPolicy: 'network-only' });
  return { getQualifications, loading, data, refetch };
}

export function useCreateQualification() {
  const [createQualification] = useMutation(createQualificationMutation);
  return { createQualification };
}