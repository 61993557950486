import React, { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import styled from 'styled-components';
import { colors } from 'src/styles/theme/styles';

export enum AlertLevel {
  error = 'error',
  success = 'success',
  warn = 'warn',
}

interface AlertType {
  level: string;
  expireInMs?: number;
  message: string;
}

interface Props {
  alert: AlertType;
  clearAlert: () => void;
}

const StyledAlert = styled(MuiAlert)<{ level: string }>`
  && {
    z-index: 99999;
    position: fixed;
    top: 12%;
    align-self: center;
    max-width: 45%;
    min-width: 600px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    padding: 5px 20px 5px 25px;
    border-radius: 5px; 
    box-shadow: 0px 4px 10px ${colors.dividerColor};
    margin: 0 auto;
    left: 0;
    right: 0;

    ${props => props.level === AlertLevel.success && `
      background-color: #E6F5EC;
      color: #0F9C4C;
      border: 1px solid #0F9C4C;
    `}

    ${props => props.level === AlertLevel.error && `
      background-color: #FCEBEC;
      color: #cf0000;
      border: 1px solid #cf0000;
    `}
  }
`;

export default function AlertBox(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [key, setKey] = React.useState(0);

  useEffect(() => {
    if (props.alert && props.alert.message != '') {
      setOpen(true);
    }
    const expireInMs = props?.alert?.expireInMs || 6000

    setTimeout(() => {
      setOpen(false);
    }, expireInMs);
  }, [props.alert]);

  const handleClose = () => {
    closeAlert();
    setKey(key + 1);
  };

  function closeAlert() {
    setOpen(false);
    props.clearAlert();
  }

  return (
    <div key={key}>
      {open && props.alert.message !== '' && (
        <StyledAlert
          icon={false}
          severity={props.alert.level || AlertLevel.error}
          onClose={handleClose}
          level={props.alert.level}
        >
          {props.alert.message}
        </StyledAlert>
      )}
    </div>
  );
}
