import React from 'react';
import { StyleProp, TextStyle } from 'react-native';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';

interface Props {
  value: any;
  color?: string;
  fontWeight?: any;
  lineHeight?: number;
  align?: StyleProp<TextStyle["textAlign"]>;
  fontSize?: number;
  numberOfLines?: number;
}

export default function HeaderThree(props: Props) {
  const {
    value = '',
    color = colors.primaryText,
    fontWeight = 'normal',
    lineHeight = 28,
    align = 'center' as string,
    fontSize,
    numberOfLines = 1,
  } = props;

  return (
    <StyledText
      allowFontScaling={false}
      color={color}
      lineHeight={lineHeight}
      align={align}
      fontWeight={fontWeight}
      fontSize={fontSize}
      numberOfLines={numberOfLines}>
      {value}
    </StyledText>
  );
}

const StyledText = styled.Text<any>`
  font-family: ${fonts.regular};
  text-align: ${(props) => props.align};
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.rem(2.4))};
  line-height: ${(props) => props.lineHeight};
`;
