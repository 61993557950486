export const validation = {
  USER_NAME_REQUIRED: 'error.userName.required',
  USER_NAME_INVALID: 'user.name.text.not-valid',
  ROLL_NO_REQUIRED: 'error.rollNumber.required',
  PASSWORD_REQUIRED: 'Password is required',
  COURSE_DURATION_INVALID: 'course.duration.text.not-valid',
  SUBJECT_TYPE_REQUIRED: 'error.subjectType.required',
  COURSE_DURATION_TYPE_REQUIRED: 'error.durationType.required',
  SUBJECT_COLOR_REQUIRED: 'error.colorCode.required',
  SEMESTER_REQUIRED: 'error.semester.required',
  GROUP_NAME_REQUIRED: 'error.groupName.required',
  COHORT_NAME_REQUIRED: 'error.cohort-name.required',
  INSTITUTE_TYPE_REQUIRED: 'error.instituteType.required',
  INSTITUTE_NAME_REQUIRED: 'error.instituteName.required',
  INSTITUTE_GROUP_NAME_REQUIRED: 'error.instituteName.required',
  INSTITUTE_UDISE_ID_REQUIRED: 'UDISE Id is Required',
  WEBSITE_REQUIRED: 'error.websiteLink.required',
  FIRST_NAME_REQUIRED: 'error.firstName.required',
  FIRST_NAME_INVALID: 'error.firstName.invalid',
  LAST_NAME_REQUIRED: 'error.lastName.required',
  LAST_NAME_INVALID: 'error.lastName.invalid',
  EMAIL_REQUIRED: 'error.email.required',
  EMAIL_INVALID: 'error.email.invalid',
  CONTACT_REQUIRED: 'error.contactNumber.required',
  CONTACT_INVALID: 'error.contactNumber.invalid',
  LOCATION_REQUIRED: 'error.location.required',
  ROLE_IN_INSTITUE_REQUIRED: 'error.role.required',
  BOARD_UNIVERSITY_REQUIRED: 'error.board.required',
  EDBA_DOMAIN_REQUIRED: 'error.domain.required',
  EMPLOYEE_ID_REQUIRED: 'error.employeeId.required',
  EMPLOYEE_TYPE_REQUIRED: 'error.employeeType.required',
  CLASS_REQUIRED: 'error.class.required',
  COURSE_REQUIRED: 'error.course.required',
  COURSE_NAME_REQUIRED: 'error.course-name.required',
  CLASS_NAME_REQUIRED: 'error.class-name.required',
  USERNAME_TAKEN: 'Username already taken !',
  PASSWORD_INVALID:
    'Must Contain 6 Characters, One Uppercase, One Number and one special Character',
  PASSWORD_UNMATCH: 'The passwords do not match',
  EMAIL_UNMATCH: 'Email IDs do not match',
  DOB_REQUIRED: 'Date of Birth is required',
  DATE_INVALID: 'error.date.invalid',
  TIME_INVALID: 'Time is invalid',
  AADHAAR_INVALID: 'Aadhaar number is invalid',
  AADHAAR_REQUIRED: 'Aadhaar number is required',
  ASSIGNEE_REQUIRED: 'assignee type required',
  STDGRP_REQUIRED: 'student group required',
  STUDENT_ID_REQUIRED: 'form.studentId.required.text',
  DIVISION_REQUIRED: 'error.division.required',
  NEWS_TITLE_REQUIRED: 'error.newsTitle.required',
  NEWS_BODY_REQUIRED: 'error.newsContent.required',
  NEWS_IMAGE_REQUIRED: 'error.newsImage.required',
  AUDIENCE_TYPE_REQUIRED: 'error.audienceType.required',
  AUDIENCE_REQUIRED: 'error.audience.required',
  NOTICE_TITLE_REQUIRED: 'error.noticeTitle.required',
  NOTICE_BODY_REQUIRED: 'error.noticeContent.required',
  BATCH_REQUIRED: 'error.batch.required',
  BATCH_INVALID: 'error.batch.invalid',
  SUBJECT_REQUIRED: 'error.subject.required',
  TITLE_REQUIRED: 'error.title.required',
  DESCRIPTION_REQUIRED: 'error.description.required',
  DUE_DATE_REQUIRED: 'error.dueDate.required',
  EXAM_REQUIRED: 'exam-name-required.text',
  EXAM_NAME_REQUIRED: 'exam-name-required.text',
  ATTACHMENT_REQUIRED: 'error.attachment.required',
  NEWS_ATTACHMENT_REQUIRED: 'error.newsAttachment.required',
  WEBSITE_INVALID: 'error.websiteLink.invalid',
  DOMAIN_INVALID: 'error.domain.invalid',
  EVENT_TITLE_REQUIRED: 'error.eventTitle.invalid',
  EVENT_LOCATION_REQUIRED: 'error.location.required',
  EVENT_DESCRIPTION_REQUIRED: 'error.eventDescription.invalid',
  DAY_REQUIRED: 'error.day.required',
  TIME_REQUIRED: 'error.time.message',
  TEACHER_NAME_REQUIRED: 'error.teacherName.message',
  START_TIME_REQUIRED: 'error.startTime.required',
  END_TIME_REQUIRED: 'error.endTime.required',

  VALUE_REQUIRED: 'error.value.required',
  LANGUAGE_NAME_REQUIRED: 'error.languageName.required',
  LANGUAGE_NAME_INVALID: 'error.languageName.invalid',
  SELECT_DIVISION: 'error.division.message',
  SELECT_SUBJECT: 'error.subjects.required',
  SELECT_SLOT: 'error.slot.message',
  SELECT_DURATION: 'error.duration.message',
  STUDENT_REQUIRED: 'student-name.required.text',
  SELECT_NUMBER_SLOT: 'error.slotNumber.message',
  SELECT_ATTENDANCE_TYPE: 'error.attendanceType.message',
  SELECT_AUDIENCE_TYPE: 'error.audienceType.message',

  START_DATE_REQUIRED: 'error.startDate.required',
  END_DATE_REQUIRED: 'error.endDate.required',

  BATCH_START_DATE_REQUIRED: 'error.batchStartDate.required',
  BATCH_NAME_REQUIRED: 'error.batchName.required',
  BATCH_END_DATE_REQUIRED: 'error.batchEndDate.required',

  AYEAR_START_DATE_REQUIRED: 'academic.year.startyear.required',
  AYEAR_NAME_REQUIRED: 'academic.year.name.required',
  AYEAR_END_DATE_REQUIRED: 'academic.year.endyear.required',
  STATUS_REQUIRED: 'error.status.required',

  INVALID_COLOR_CODE: 'error.colorCode.invalid',

  USERTYPE_REQUIRED: 'error.userType.required',
  MARKS_INVALID: 'marksValidation.text',
  MARKS_REQUIRED: 'error.marks.required',

  MODULE_NAME_REQUIRED: 'error.moduleName.required',
  MODULE_TYPE_REQUIRED: 'error.moduleType.required',
  FEE_DETAIL_TYPE_REQUIRED: 'error.feeDetailType.required',
  AMOUNT_REQUIRED: 'error.amount.required',
  AMOUNT_INVALID: 'amount.text.invalid',
  COMPOSITION_NAME_REQUIRED: 'error.compositionName.required',
  ACCOUNT_NAME_REQUIRED: 'error.accountName.required',
  MERCHANT_ACCOUNT_REQUIRED: 'error.merchantAccount.required',
  COURSES_SELECTION_REQUIRED: 'error.courseSelection.required',
  BENEFICIARY_NAME_REQUIRED: 'error.beneficiaryName.required',
  IFSC_REQUIRED: 'error.ifsc.required',
  ACCOUNT_TYPE_REQUIRED: 'error.accountType.required',
  ACCOUNT_NUMBER_REQUIRED: 'error.accountNo.required',
  CONFIRM_ACCOUNT_NUMBER_REQUIRED: 'error.accountConfirmNo.required',
  IFSC_INVALID: 'error.ifsc.invalid',
  ACCOUNT_NUMBER_INVALID: 'error.accountNo.invalid',
  CONFIRM_ACCOUNT_NUMBER_NOT_MATCH: 'error.accountNo.message',
  BANKNAME_REQUIRED: 'error.bankName.required',
  CONCESSION_NAME_REQUIRED: 'error.concessionName.required',
  CONCESSION_AMOUNT_REQUIRED: 'error.concessionAmount.required',
  LINK_ID_REQUIRED: 'error.linkId.required',
  SELECT_FEECOMPOSITION: 'selectOneFeesComposition.text',
  CHEQUE_NUMBER_REQUIRED: 'Cheque Number is required',
  DRAFT_NUMBER_REQUIRED: 'Draft Number is required',
  MODE_REQUIRED: 'Payment mode is required',
  CHEQUE_NUMBER_INVALID: 'error.chequeNo.invalid',
  DRAFT_NUMBER_INVALID: 'error.draftNo.invalid',
  SUBJECTS_REQUIRED: 'error.subjects.required',

  SUBJECT_GROUP_REQUIRED: 'error.subjectGroup.required',
  SERIAL_NO_REQUIRED: 'error.serialNo.required',
  GR_NO_REQUIRED: 'error.grNo.required',

  NAME_REQUIRED: 'error.name.required',
  ALIAS_REQUIRED: 'error.alias.required',
  STREAM_REQUIRED: 'error.stream.required',
  CODE_REQUIRED: 'error.code.required',
  DURATION_REQUIRED: 'error.duration.required',
  GRADE_NAME_REQUIRED: 'grade-name.required.text',
  LOWER_MARKS_REQUIRED: 'lower-marks.required.text',
  HIGHER_MARKS_REQUIRED: 'higher-marks.required.text',
  REMARK_REQUIRED: 'remark.required.text',
  ADD_GRADES_MARKS_INVALID: 'error.lower-greater-than-higher',
  TEST_NAME_REQUIRED: 'test-name.required.text',
  ADD_TEST_MARKS_INVALID: 'error.passing-more-than-total',
  MIN_MARKS_REQUIRED: 'passing-marks.required.text',
  MAX_MARKS_REQUIRED: 'total-marks.required.text',
  CREDITS_REQUIRED: 'credits.required.text',
  GRADE_POINT_REQUIRED: 'grade-point.required.text',

  MESSAGE_REQUIRED: 'error.required.message',
  CREDIT_POINT_REQUIRED: 'error.creditPoint.required',

  APPLICANT_TYPE_REQUIRED: 'applicant-type.required.text',
  DTE_APPLICATION_ID_REQUIRED: 'cap-application-id.required.text',
  DTE_FORM_REQUIRED: 'attach-cap-form.required.text',
  UNIVERSITY_FORM_REQUIRED: 'error.universityForm.required',
  ADMISSION_REQUIRED: 'admission.required.text',
  ADMISSION_LAST_DATE_REQUIRED: 'error.lastAdmissionDate.required',
  ADMISSION_PROCESS_ID_REQUIRED: 'error.admissionProcessId.required',
  TOTAL_SEATS_REQUIRED: 'error.totalSeatsAvailability.required',
  RESERVED_SEATS_REQUIRED: 'error.reservedSeats.required',
  ADMISSION_STATUS_REQUIRED: 'error.admissionStatus.required',
  ALLOWED_APPLICANT_TYPE_REQUIRED: 'error.allowApplicantType.required',
  PASSING_YEAR_REQUIRED: 'error.passingYear.required',
  MAJOR_SUBJECT_MARKS_REQUIRED: 'major-subject-marks.required.text',
  OBTAINED_MARKS_REQUIRED: 'error.marksObtained.required',
  OBTAINED_MARKS_INVALID: 'error.marksObtained.invalid',
  TOTAL_MARKS_INVALID: 'error.totalMarks.invalid',
  OBTAINED_GRADE_REQUIRED: 'error.gradeObtained.required',

  CRITERIA_NAME_REQUIRED: 'error.criteriaName.required',
  CATEGORY_NAME_REQUIRED: 'error.categoryName.required',
  CATEGORY_ALIAS_REQUIRED: 'error.categoryAlias.required',
  QUOTA_REQUIRED: 'selectQuota.required.label',
  CASTE_CATEGORY_REQUIRED: 'error.selectCasteCategory.required',

  UNIVERSITY_APPLICATION_NUMBER: 'error.universityApplicationNumber.required',
  BANK_NAME_REQUIRED: 'error.bankName.required',
  TIME_REQUIRED_ERROR: 'error.time.required',
  DATE_REQUIRED: 'error.date.required',
  MERCHANT_NAME_REQUIRED: "Merchant Name is required",
  REQ_ENC_KEY_REQUIRED: "Request Encryption Key is required",
  REQ_SALT_REQUIRED: "Request Salt is required",
  RES_DEC_KEY_REQUIRED: "Response Decryption Key is required",
  RES_SALT_REQUIRED: "Response Salt is required",
  RES_HASH_KEY_REQUIRED: "Response Hash Key is required",
  REQ_HASH_KEY_REQUIRED: "Request Hash Key is required",
  PROD_ID_REQUIRED: "Production ID is required",
  MERCHANT_ID_REQUIRED: "Merchant ID is required",
  MERCHANT_PASS_REQUIRED: "Merchant Password is required",
  MCC_REQUIRED: "MCC is required",
  ENVIRONMENT_TYPE_REQUIRED: "Environment type is Required",
  CAP_ID_REQUIRED: "error.capId.required",
  ADMISSION_NAME_REQUIRED: "error.admissionName.required",
  QUALIFICATION_NAME_REQUIRED: "error.qualificationName.required",
  QUALIFICATION_ALIAS_REQUIRED: "error.qualificationAlias.required",
  QUALIFICATION_LEVEL_REQUIRED: "error.qualificationLevel.required",
  QUALIFICATION_LEVEL_INVALID: "qualificationLevel.text.invalid",
  ADMISSION_LINK_REQUIRED: "error.admissionLink.required",
  SCROLL_REF_REQUIRED: "error.scrollref.required.label",
  EMAIL_ID_REQUIRED: 'Email ID is required',
  CHARGE_TYPE_REQUIRED: 'Charge Type is required',
  MERCHANT_TRANSACTION_ID_REQUIRED: 'error.merchantTransactionId.required',
  ATOM_TRANSACTION_ID_REQUIRED: 'error.atomTransactionId.required',
  FEE_PERCENTAGE_REQUIRED: 'error.feePercent.required',
  FEE_PERCENTAGE_INVALID: 'error.feePercent.invalid',
  FEE_COMPOSITION_REQUIRED: 'error.feeComposition.required',
  TRANSACTION_ID_REQUIRED: 'error.transactionId.required',
  CANCELLATION_AMOUNT_REQUIRED: 'error.cancellationAmount.required',
  CANCELLATION_AMOUNT_INVALID: 'error.cancellationAmount.invalid',
  RECIPIENT_REQUIRED: "error.recipient.required.label",
  INSTITUTE_REQUIRED: 'error.institute.required',
  UDISE_ID_INVALID: 'error.udiseId.invalid.text',
  FULL_NAME_REQUIRED: 'error.fullName.required',
  GENERAL_CONDUCT_REQUIRED: 'error.generalConduct.required',
  DATE_OF_LEAVING_REQUIRED: 'error.dateOfLeaving.required',
  REASON_FOR_LEAVING_REQUIRED: 'error.reasonforLeaving.required',
  CAP_ID_INVALID: 'error.capId.invalid',
  PAYMENT_RECIPIENT_REQUIRED: 'error.paymentRecipient.required',
  PAYMENT_MODE_REQUIRED: 'error.paymentMode.required',
  NEFT_TRANSACTION_ID_REQUIRED: 'error.neftTransactionId.required',
  LOAN_ACCOUNT_ID_REQUIRED: 'error.loanAccountId.required',
  LENDER_NAME_REQUIRED: 'error.lenderName.required',
  REPORT_TYPE_REQUIRED: 'error.reportType.required',
  DEFAULT_CREDIT_POINT_REQUIRED: 'error.defaultCreditPoint.required',
  ACADEMIC_YEAR_REQUIRED: 'error.academicYear.required',
  ACADEMIC_TYPE_REQUIRED: 'error.academicType.required',
  INVALID_GR_NO: 'error.grNo.invalid',
  INVALID_SR_NO: 'error.srNo.invalid',
  INVALID_REGISTER_NO: 'error.registerNo.invalid',
  TYPE_REQUIRED: 'error.type.required',
  AADHAAR_DOC_REQUIRED: "Aadhaar Card is required",
  BIRTH_CERTIFICATE_REQUIRED: "Birth Certificate is required",
  HOUSE_NUMBER_REQUIRED: "Flat/Building/House number is required",
  AREA_NAME_REQUIRED: "Street/Area Name is required",
  CITY_NAME_REQUIRED: "City Name is required",
  STATE_NAME_REQUIRED: "State is required",
  PINCODE_REQUIRED: "Pin Code is required",
  PINCODE_INVALID: "Pin Code is invalid",
  SEAT_NO_REQUIRED: 'error.seatNo.required',
  INVALID_ENROLLMENT_NO: 'error.enrollmentNo.invalid',
  INVALID_SEAT_NO: 'error.seatNo.invalid',
  RELATION_REQUIRED: "Relation is required",
  EXPIRY_DATE_REQUIRED: "error.expiryDate.required",
  LINK_PREFIX_REQUIRED: "error.linkPrefix.required",
  LINK_PREFIX_INVALID: "error.linkPrefix.invalid",
  DOCUMENT_NAME_REQUIRED: "error.documentName.required",
  DOCUMENT_ALIAS_REQUIRED: "error.documentAlias.required",
  DOCUMENT_CATEGORY_REQUIRED: "error.documentCategory.required",
  INVALID_REGISTRATION_NO: 'error.registrationNo.invalid',
  ROUND_NAME_REQUIRED: "error.roundName.required",
  LAST_SUBMISSION_DATE_REQUIRED: "error.lastSubmissionDate.required",
  MERIT_DATE_REQUIRED: "error.meritDate.required",
  LAST_PAYMENT_DATE_REQUIRED: "error.lastPaymentDate.required",
  DOCUMENT_REQUIRED: 'error.document.required',
  PLACE_ALLOWED_REQUIRED: 'error.placeAllowed.required',
  ATKT_REQUIRED: 'error.atkt.required',
  REVAL_REQUIRED:'error.reval.required',
  MOBILE_NUMBER_REQUIRED: 'error.mobileNumber.required',
  PUBLISH_DATE_REQUIRED: 'error.publishDate.required',
} as any;
