import React from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { colors } from 'src/styles/theme/styles';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';

export const TblHeadResults = (props: any) => {
  const { dynamicHeadCells = null, dynamicHeadCells2 = null, page = 'Results' } = props;
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  return (
    <TableHead style={styles.tableHeader}>
      <TableRow>
        {dynamicHeadCells?.map((headCell: any, i: number) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sx={{
              borderBottom: headCell?.dynamic ? '1px solid rgba(224, 224, 224, 1)' : 'none',
              ...headCell?.style,
            }}
            colSpan={
              headCell?.dynamic
                ? page === 'Grace'
                  ? 5
                  : headCell?.label === 'Total Marks'
                    ? 3
                    : 5
                : 0
            }>
            <TableColHead value={t(headCell.label)} color={theme?.table?.header?.color} />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell />
        <TableCell />
        {page === 'Grace' && (
          <>
            <TableCell />
            <TableCell />
          </>
        )}
        {dynamicHeadCells2?.map((headCell: any, i: number) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sx={headCell?.style ? headCell.style : null}>
            <TableColHead value={t(headCell.label)} color={theme?.table?.header?.color} />
          </TableCell>
        ))}
        <TableCell />
        <TableCell style={styles.gradeCell} />
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

const styles = {
  tableHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
  },
  firstTableCell: {
    width: 16,
    backgroundColor: colors.white,
    borderBottom: 'none',
  },
  gradeCell: {
    paddingLeft: 80,
  },
};
