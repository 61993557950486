import { FieldValues, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import ERROR_MSG from "src/constant/error";
import Element from "src/components/molecules/Forms/ApplicationElement.web";
import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { TBlockResultFormState as TForm } from "./ResultActionsTypes";
import { TFormProps } from "src/types";
import { UPDATE_SUCCESS } from "src/constant/message";
import formJSON from "src/form-json/assessment/Results/block-result-form.json";
import styled from "styled-components";
import { useBlockResult } from "src/graphql/assessment/assessment-results";
import { useI18n } from "src/i18n/hooks";

type Props = TFormProps<TForm<{ examId: string; userId: string }>>;

const BlockResultForm = (props: Props) => {
  const { form, onSuccess, onError, onClose } = props;
  const { examId, userId } = form.data!;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { blockResult } = useBlockResult();

  const [elements, setElements] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { fields } = elements ?? {};

  useEffect(() => {
    setElements({ ...formJSON[0] });
  }, []);

  const onSubmitFn = async (data: FieldValues) => {
    if (data) {
      setLoading(true);
      try {
        const variables = {
          examId,
          userId,
          ...(data?.remarks ? { remarks: data?.remarks } : {}),
        };
        const response = await blockResult({ variables });
        if (response?.data?.blockResultforUser?.status === "success") {
          onSuccess(UPDATE_SUCCESS.BLOCK_RESULT);
        } else {
          throw new Error(
            response?.data?.blockResultforUser?.message ??
            ERROR_MSG.GENERIC_ERROR
          );
        }
        setLoading(false);
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      modalVisible={true}
      Headerpopup={t("blockResult.label")}
      width={600}
      handleSave={handleSubmit(onSubmitFn)}
      addEditButtonLabel={"block.label"}
    >
      <Container>
        <HeadingWrapper>
          <MediumTextSelect value={t("blockResult.message.text")} />
        </HeadingWrapper>
        {fields &&
          fields.map((field: any) => (
            // @ts-ignore
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
            />
          ))}
      </Container>
    </NormalModal>
  );
};

const Container = styled.div`
  height: 192px;
`;

const HeadingWrapper = styled.div`
  margin-bottom: 16px;
`;

export default BlockResultForm;
