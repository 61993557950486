import { NoRecordsFound, TableCells, TableDiv, TableRows } from './TableAtom';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import Check from 'src/components/atoms/CheckBox/index.web';
import { TableBody } from '@mui/material';
import { height } from 'src/constant/device';
import styled from 'styled-components/native';
import useTable from './UseTable';
import ToggleSwitch from 'src/components/atoms/Switch/Toggle';

interface Props {
  header: string;
  tabledata: any[];
  maxHeightTable: number;
  setData: Dispatch<SetStateAction<any>>;
  data: any[];
  inputProperty: string;
  inputValProperty: string;
  inputHeader: string;
  isEdit: boolean;
  isDataAvailable: boolean;
}

export default function DocumentSelectionTable(props: Props) {
  const {
    header,
    tabledata,
    maxHeightTable,
    setData,
    data,
    inputProperty,
    inputValProperty,
    isEdit,
    isDataAvailable,
  } = props;
  const [tabledataState] = useState(tabledata);
  const [checked, setChecked] = useState([]);
  const { TblContainer, TblHead } = useTable(tabledataState, header, null, tabledata);

  useEffect(() => {
    if (isEdit && data && data.length > 0) {
      setChecked(data);
    }
  }, [data]);

  useEffect(() => {
    setData(checked);
  }, [checked]);

  function isChecked(id: string): boolean {
    return Boolean(
      checked && checked.length > 0 && checked.find((item) => item[inputProperty] === id),
    );
  }

  function isCheckedAndMandatory(id: string) {
    return Boolean(
      checked &&
        checked.length > 0 &&
        checked.find((item) => item[inputProperty] === id && item?.required),
    );
  }

  function handleChecked(i: number, id: string) {
    const index = checked.findIndex((x) => x[inputProperty] === id);
    let updated = [...checked];
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push({ [inputProperty]: id, required: false });
    }
    setChecked(updated);
  }

  function onChange(id: string) {
    const index = checked.findIndex((x) => x[inputProperty] === id);
    let updated = [...checked];
    if (index > -1) {
      updated[index] = {
        [inputProperty]: id,
        required: !updated[index]?.required,
      };
    }
    setChecked(updated);
  }

  function getInputVal(id: string) {
    const stream =
      checked && checked.length > 0 && checked.find((item) => item[inputProperty] === id);
    return stream ? stream[inputValProperty] : [];
  }

  function setInputVal(id: string, value: (string | number)[]) {
    const temp = [...checked];
    const currentItem = temp.findIndex((item) => item[inputProperty] === id);
    temp[currentItem][inputValProperty] = value;
    setChecked([...temp]);
  }

  return (
    <TableContainer>
      <TableDiv>
        <TblContainer tabledata={tabledata} maxHeight={maxHeightTable} height={height}>
          <TblHead />
          {tabledata && tabledata?.length > 0 && (
            <TableBody>
              {tabledata?.map((item: any, i: number) => (
                <>
                  <TableRows key={i}>
                    <TableCells style={styles.checkboxCellStyle}>
                      <Check
                        id="document-checkbox"
                        same={false}
                        checked={isChecked(item?.id)}
                        onChange={() => handleChecked(i, item?.id)}
                      />
                    </TableCells>
                    <TableCells value={item?.name} />
                    <TableCells>
                      <SwitchView>
                        <ToggleSwitch
                          enabled={isCheckedAndMandatory(item?.id)}
                          onChange={() => onChange(item?.id)}
                          disabled={!isChecked(item?.id)}
                          style={styles.switchStyle}
                        />
                      </SwitchView>
                    </TableCells>
                  </TableRows>
                </>
              ))}
            </TableBody>
          )}
          {isDataAvailable && tabledata?.length === 0 && (
            <NoRecordsFound colspan={3} maxHeight={224} />
          )}
        </TblContainer>
      </TableDiv>
    </TableContainer>
  );
}

const TableContainer = styled.View<{ width: number | string }>`
  overflow: auto;
  width: ${({ width }) => width};
`;

const SwitchView = styled.View``;

const styles = {
  checkboxCellStyle: { paddingLeft: 16, paddingRight: 0 },
  chipInputStyle: { paddingBottom: 8 },
  switchStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
};
