import { gql, useLazyQuery } from "@apollo/client";

const getScheduleQuery = gql`
  query ($input: GetSchedule) {
    getFullScheduleByDay(limit: 0, input: $input) {
      totalCount
      data {
        date
        schedules {
          id
          start
          startTime
          endTime
          validFrom
          validTill
          detail {
            description
          }
          subject {
            id
            name
            alias
            color
          }
          subjectGroup {
            name
          }
          division
          batch {
            alias
          }
          class {
            alias
          }
          lecturer {
            fullName
          }
          day
        }
      }
    }
  }
`;

export function useGetSchedule() {
  const [
    getSchedule,
    { data: scheduleData, loading: scheduleLoading, refetch: scheduleRefetch },
  ] = useLazyQuery(getScheduleQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });
  return { getSchedule, scheduleData, scheduleLoading, scheduleRefetch };
}
