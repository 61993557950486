import { StyleSheet } from 'react-native';
import { colors, fonts } from 'src/styles/theme/styles';

export const classesStyles = (rem) => StyleSheet.create({
  modalStyle: {
    position: 'sticky',
    top: 0,
    left: 0,
    backgroundColor: 'white',
  },
  leftpart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  rightpart: {
    flex: 3,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rightPadding16: {
    paddingRight: '16px',
  },
  addLecture: {
    // flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 'fit-content',
    marginBottom: rem(3),
    position: 'relative',
    zIndex: 7,
  },
  addLectureNative: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: rem(1.6),
    position: 'relative',
    zIndex: 7,
  },
  calendar: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    maxWidth: 'fit-content',
  },
  nextOrPrevBtn: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.lighterBorder,
    borderRadius: 4,
    maxHeight: rem(4),
    maxWidth: rem(4),
    minHeight: rem(4),
    minWidth: rem(4),
  },
  leftheader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  marginR16: {
    marginRight: rem(1.8),
  },
  marginTop4: {
    marginTop: rem(0.4),
  },
  tableAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconView: { marginRight: 8 },
  rowFlex: { flexDirection: 'row-reverse' },
  leftPadding: { paddingLeft: '16px' },
  height32: {
    height: 32,
  },
  importButtonView: {
    width: 28,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: colors.primary,
    marginLeft: 3,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: 32,
    marginRight: 6,
  },
  dropDown: {
    borderWidth: 1,
    borderRadius: 4,
    paddingLeft: 16,
    paddingRight: 36,
    paddingVertical: 14,
    justifyContent: 'center',
    marginLeft: 16,
    position: 'absolute',
    marginTop: 32,
    backgroundColor: colors.white,
    borderColor: colors.white,
    boxShadow: `0px 0px 4px ${colors.searchShadow}`,
    top: 6,
    right: 30,
  },
  detailsView: {
    flex: 1,
    flexDirection: 'row'
  },
  detailContainer: {
    display: 'flex',
    gap: 8,
    marginTop: 20
  },
  calendarIcon: { marginLeft: rem(1), alignItems: 'center' }
});