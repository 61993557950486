import React, { useEffect, useState } from 'react';
import { colors } from 'src/styles/theme/styles';
import { StyleSheet, Image } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import styled, { useTheme } from 'styled-components';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { getStorageFunction } from 'src/components/services/storage-service';
import { getProtectedImage } from 'src/components/services/image-service';
import { KEYS } from 'src/constant/key';
import { isWeb } from 'src/constant/device';
import DateTextAtom from 'src/components/atoms/Text/DateTextAtom';
import { DT, parseToDate } from 'src/constant/dateTime';
import { format } from 'date-fns';

export const NewsItem = (props: any) => {
  const { newsDetail } = props;
  const { rem }: any = useTheme();
  const styles = newsItemStyle(rem);
  const [binaryImage, setBinaryImage] = useState<any>('');
  const [tokenData, setToken] = useState<any>('');
  const parsedDateObj = parseToDate(newsDetail?.mcreatedAt);
  const date = parsedDateObj && format(parsedDateObj, DT.DATE_ABBREVIATED_MONTH_YEAR);
  const time = parsedDateObj && format(parsedDateObj, DT.TIME_12_HOUR);
  let imageUrl = newsDetail?.contentImage?.documentUrl || '';
  let token = '' as any;
  useEffect(() => {
    displayProtectedImage();
  }, []);

  useEffect(() => {
    loadToken();
  }, []);

  async function loadToken() {
    token = await getStorageFunction(KEYS.ACCESS_TOKEN);
    setToken(`Bearer ${token}`);
  }

  async function displayProtectedImage() {
    let objectUrl = await getProtectedImage(imageUrl);
    setBinaryImage(objectUrl);
  }

  return (
    <ItemContainer>
      <IconHolder>
        <Image
          style={styles.image}
          source={
            isWeb
              ? {
                uri: binaryImage,
              }
              : {
                uri: imageUrl,
                headers: { authorization: tokenData },
              }
          }
        />
      </IconHolder>
      <ContHolder>
        <DateTextAtom>
          {date}
          <FontAwesomeIcon
            icon={faCircle}
            size={4}
            color={colors.primaryColor}
            style={{ marginLeft: rem(0.5), marginRight: rem(0.5), marginBottom: rem(0.2) }}
          />
          {time}
        </DateTextAtom>
        <ItemTitle>{newsDetail.title}</ItemTitle>
      </ContHolder>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-bottom: ${({ theme }) => theme.rem(0.1)} solid ${colors.inputBorder};
  padding: ${({ theme }) => theme.rem([2.4, 0])};
  box-sizing: border-box;
  width: 100%;
`;

const IconHolder = styled.div``;
const ContHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.rem(1.4)};
`;

const ItemTitle = styled.text`
  font-size: ${({ theme }) => theme.rem(1.6)};
  line-height: ${({ theme }) => theme.rem(2)};
  color: ${colors.secondaryText};
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const newsItemStyle = (rem: any) =>
  StyleSheet.create({
    list: {
      shadowColor: '#000',
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.2,
      shadowRadius: 0,
      elevation: 5,
      marginBottom: rem(1.0),
      paddingBottom: rem(1.0),
    },
    date: {
      fontSize: rem(1.2),
      fontWeight: '600',
      color: '#2B78CA',
      marginBottom: rem(1.0),
    },
    name: {
      fontSize: rem(1.6),
      fontWeight: '600',
      color: '#666666',
    },
    desc: {
      fontSize: rem(1.5),
      fontWeight: '400',
      color: '#666666',
      marginBottom: rem(0.5),
    },
    image: {
      width: rem(9),
      height: rem(7.2),
      borderRadius: 8,
    },
    icon: { alignSelf: 'center', marginHorizontal: rem(0.8) },
  });
