import React from 'react';
import MediumText from 'src/components/atoms/Text/MediumText';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components';

interface Props {
  type: string;
  className?: string;
}

export default function InfoModalDetail(props: Props) {
  const { type, className } = props;
  const { t } = useI18n();
  return (
    <ContainerWrapper>
      <MediumTextSelect
        value={type == 'Payment' ? t('feesPaymentPending.header.text') : t('promotion.header.text')}
        fontFamily={fonts.semibold}
      />
      <MessageWrapper>
        {type == 'Payment' ? (
          <>
            <MediumText value={t('feesPaymentPending.message1.text')} lineHeight={2.4} align="left" />
            <MediumText value={'\u200b'} />
            <MediumText value={t('feesPaymentPending.message2.text')} lineHeight={2.4} align="left" />
          </>
        ) : (
          <>
            <MediumText value={t('promotion.message1.text')} lineHeight={2.4} align="left" />
            <MediumText value={'\u200b'} />
            <ModalText>
              <ModalText>To secure your admission and confirm your seat in</ModalText>
              <ModalText style={{ color: colors.primaryText, fontFamily: fonts.semibold }}> {className}</ModalText>
              <ModalText>, please complete the fees payment process.</ModalText>
            </ModalText>
          </>
        )}
      </MessageWrapper>
    </ContainerWrapper>
  );
}

const ContainerWrapper = styled.div`
  min-height: 148px;
`;

const MessageWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;

const ModalText = styled.text`
  font-size: ${(props) => props.theme.rem(1.6)};
  line-height: ${(props) => props.theme.rem(2.4)};
  font-family: ${fonts.regular};
  color: ${colors.secondaryText};
`;