import React, { useEffect, useState } from 'react';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components';
import { useHistory } from 'src/routes/routing';
import { Dimensions, EmitterSubscription, useWindowDimensions } from 'react-native';
import { userInformation } from 'src/utils/manageState';
import { limit, USER_TYPE } from 'src/constant';

export const NotFound = () => {
  const [windowWidth, setwindowWidth] = useState(useWindowDimensions().width);
  const history = useHistory();
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;
  const goToHome = () => {
    history.push(userType == USER_TYPE.APPLICANT ? `/application/list/limit/${limit}/page/1` : '/');
  };

  useEffect(() => {
    const subscription: EmitterSubscription = Dimensions.addEventListener("change", (e) => {
      setwindowWidth(e.window.width);
    });
    return () => subscription.remove();
  }, []);

  return (
    <NotFoundContainer>
      <Icon name={'not-found'} size={windowWidth > 767 ? 213 : 125} />
      <NotFoundText>PAGE NOT FOUND</NotFoundText>
      <HeadingText>The page you are looking for isn't available</HeadingText>
      <HeadingText>Try to search again or use the Go Home button below</HeadingText>
      <Button onClick={goToHome}>
        <ButtonText>GO HOME</ButtonText>
      </Button>
    </NotFoundContainer>
  );
};

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: ${colors.white};
  width: 100%;
  height: 100vh;
`;

const NotFoundText = styled.text`
  margin-top: 83.14px;
  font-size: 60px;
  line-height: 71.6px;
  font-family: ${fonts.semibold};
  text-align: center;
  @media all and (max-width: 767px) {
    margin-top: 24px;
    font-size: 40px;
    line-height: 48px;
  }
`;

const HeadingText = styled.text`
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  font-family: ${fonts.regular};
  color: ${colors.secondaryText};
  @media all and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Button = styled.button`
  height: 48px;
  width: 113px;
  margin-top: 24px;
  border-radius: 4px;
  background-color: #2b78ca;
  border: none;
  color: ${colors.white};
  cursor: pointer;
  @media all and (max-width: 767px) {
    width: 145px;
    height: 48px;
    border-radius: 32px;
  }
`;

const ButtonText = styled.text`
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-family: ${fonts.regular};
  @media all and (max-width: 767px) {
    line-height: 18px;
  }
`;
