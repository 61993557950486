import React from 'react';
import { View, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import NormaltextAtom from '../Text/NormalTextAtom';
import PropTypes from 'prop-types';
import { colors } from 'src/styles/theme/styles';

interface Props {
  onPress: () => void;
  label: string;
  width?: number;
  disabled?: boolean;
  loading?: boolean;
  style?: object;
}

export default function NormalButtton(props: Props) {
  const { onPress, label = 'Save', width, disabled = false, style } = props;
  return (
    <TouchableOpacity
      style={[styles.btnView, { width }, {...style}]}
      disabled={disabled}
      onPress={onPress}
      activeOpacity={1}>
      <View style={{ position: 'relative', flexDirection: 'row' }}>
        <View style={{ position: 'absolute', width: '100%' }}>
          <ActivityIndicator
            animating={disabled}
            size={16}
            color={colors.white}></ActivityIndicator>
        </View>
        <View style={{ opacity: disabled ? 0 : 1 }}>
          <NormaltextAtom value={label} color={colors.white} />
        </View>
      </View>
    </TouchableOpacity>
  );
}

NormalButtton.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  btnView: {
    paddingVertical: 7,
    paddingHorizontal: 16,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.primary,
  },
});
