import { FILTERS, limit } from 'src/constant/index';
import { readCacheQuery, writeCacheQuery } from '../util';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { createDeleteData, createSearchFilter } from '../../components/services/index';
import { getClassFilter } from 'src/components/services/filters';

const addClassMutation = gql`
  mutation ($payload: CreateClassInput!) {
    createClass(payload: $payload) {
      id
      name
      alias
      code
      course {
        id
        alias
        name
        university {
          id
          name
          alias
        }
      }
    }
  }
`;

const updateClassMutation = gql`
  mutation ($payload: UpdateClassInput!) {
    updateClass(payload: $payload) {
      id
      name
      alias
      code
      course {
        id
        alias
        name
        university {
          id
          name
          alias
        }
      }
    }
  }
`;

const updateInstituteClassMutation = gql`
  mutation ($payload: LocalizationAcademicInput!) {
    updateInstituteClass(payload: $payload) {
      id
      name
      alias
    }
  }
`;

const deleteClassMutation = gql`
  mutation ($id: String!) {
    deleteClass(id: $id) {
      status
      message
    }
  }
`;

// U can use this also for all limit = 0 skip = 0
const getClassesQuery = gql`
  query ($limit: Int, $skip: Int, $filters: ClassFilterInput, $sort: ClassSortInput, $lookup: LookupFilter) {
    classes(limit: $limit, skip: $skip, filters: $filters, sort: $sort, lookup: $lookup) {
      hasNextPage
      totalCount
      data {
        id
        name
        alias
        code
        course {
          id
          alias
          name
          university {
            id
            name
            alias
          }
        }
        qualificationRef {
          id
          name
        }
      }
    }
  }
`;

const getModifiedClassesQuery = gql`
  query ($limit: Int, $skip: Int) {
    classes(limit: $limit, skip: $skip) {
      hasNextPage
      totalCount
      data {
        value: id
        label: alias
        name: name
        course {
          university {
            id
          }
        }
      }
    }
  }
`;

const searchClassQuery = gql`
  query ($limit: Int, $skip: Int, $filters: ClassFilterInput) {
    classes(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        value: id
        label: alias
      }
    }
  }
`;

export {
  getClassesQuery,
  addClassMutation,
  getModifiedClassesQuery,
  searchClassQuery,
  updateInstituteClassMutation,
};

export function useModifiedClasses() {
  const [getModifiedClasses, { loading, data: classModifiedData, error: classModifyError }] =
    useLazyQuery(getModifiedClassesQuery);
  return { getModifiedClasses, classModifiedData, classModifyError };
}

export function useSearchClass() {
  const [searchClass, { loading, data: searchClassData, error: searchClassError }] =
    useLazyQuery(searchClassQuery);
  return { searchClass, searchClassData, searchClassError };
}

export function useUpdateClass() {
  const [mutate, { data, error }] = useMutation(updateClassMutation);
  return { mutate, data, error };
}

export function useUpdateInstituteClass() {
  const [updateInstituteClass, { data, error }] = useMutation(updateInstituteClassMutation);
  return { updateInstituteClass, data, error };
}

export function useAddClass(classesFilter: any) {
  const [mutate, { data, error }] = useMutation(addClassMutation, {
    update: (cache, { data: { createClass } }) => {
      let queryData;
      //read
      try {
        queryData = readCacheQuery(cache, getClassesQuery, classesFilter);
      } catch (e) {
        //console.log(e.messgae);
      }
      let existingClasses = queryData?.classes.data;
      let mergeArray = [createClass, ...existingClasses];
      let finaldata = {
        data: mergeArray,
        hasNextPage: queryData?.classes?.hasNextPage,
        totalCount: queryData?.classes?.totalCount + 1,
        __typename: queryData?.classes?.__typename,
      };
      //write
      try {
        writeCacheQuery(cache, getClassesQuery, classesFilter, {
          classes: finaldata,
        });
      } catch (e) {
        //console.log('error', e.messgae);
      }
    },
  });
  return { mutate, data, error };
}

export function useDeleteClass(
  page: number,
  searchValue: string,
  rowLimit: number,
  classId: string,
) {
  const [deleteClass, { error: deleteClassError, data: deleteClassData }] = useMutation(
    deleteClassMutation,
    {
      update: (cache, { data: { deleteClass } }) => {
        let queryData;
        let filters = getClassFilter(searchValue);
        let classesFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
        //read
        try {
          queryData = readCacheQuery(cache, getClassesQuery, classesFilter);
        } catch (e) {
          //console.log(e.messgae);
        }
        let classObject = queryData?.classes;
        let message = deleteClass.message;
        // let classId = message.replace("Successfully deleted class for ", "");
        let dataToUpdate = createDeleteData(classObject, classId);
        //console.log(dataToUpdate)
        //write
        try {
          writeCacheQuery(cache, getClassesQuery, classesFilter, {
            classes: dataToUpdate,
          });
        } catch (e) {
          //console.log('error', e.messgae);
        }
      },
    },
  );

  return { deleteClass, deleteClassError, deleteClassData };
}
