import React, { useEffect, useState } from 'react';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { getTableValue } from 'src/utils/utility';
import BlockRejectionReason from 'src/components/molecules/Admission/BlockRejectionReason';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import { CollapseCaretIcon, ExpandCaretIcon } from 'src/components/atoms/ActionIcons';
import { TCellState } from 'src/types';
import { height, isIOS } from 'src/constant/device';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import NormalModal from 'src/components/atoms/Modals/Normal/index.native';
import RevaluationModal from 'src/components/molecules/Assessment/Results/RevalAtktModal/RevalutionModal.native';
import { showNativeError, showNativeSuccess } from 'src/components/molecules/NotificationWrapper';
import { TRevalData, TPaymentDetails } from 'src/components/organism/Assessment/Results/helpers';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import nttPaymentOptions from 'src/components/molecules/Payment/NTTPaymentOptions';
import {
  useGetATKTAndReval,
  useApplyRevalDetailsForUser,
  useApplyATKTDetailsForUser,
  useGetRevalSubjects,
  useGetUserMarkSheets,
  useGetATKTDetailsForUser,
  useGetRevalDetailsForUser,
} from 'src/graphql/assessment/assessment-results';
import AtktRevalPaymentModal from 'src/components/molecules/Assessment/Results/RevalAtktModal/PaymentModal/AtktRevalPaymentModal';
import AtktModal from 'src/components/molecules/Assessment/Results/RevalAtktModal/AtktModal.native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import NormalTextAtom from 'src/components/atoms/Text/NormalTextAtom';
import Chip from 'src/components/atoms/Chip';
interface infoItemProps {
  label: string;
  value: string | number;
  color?: string;
  rejectionReason?: any | null;
  fontFamily?: string;
  childValue?: number | string;
}
type TAtktData = {
  id: string;
  name: string;
  baseExam: string;
  status: string;
  dueDate: string;
};

export default function ResultDetail(props: any) {
  const resultData = props?.route?.params?.resultData;
  const examId = resultData?.exam?.id;
  const marksType = resultData?.exam?.batch?.settings?.gradingType === 'MARKS';
  const [RevaluationModalState, handleRevaluationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [makePaymentModal, setMakePaymentModal] = useState<boolean>(false);
  const [revalSubjects, setRevalSubjects] = useState<any>([]);
  const [checked, setChecked] = useState<string[]>([]);
  const [atktSubjects, setATKTSubjects] = useState<any>([]);
  const [revalData, setRevalData] = useState<TRevalData>();
  const [ATKTData, setATKTData] = useState<TAtktData>();
  const [ATKTfromData, setATKTFormData] = useState({});
  const [paymentDetails, setPaymentDetails] = useState<TPaymentDetails | any>();
  const [ATKTModalState, handleATKTModal] = useState<boolean>(false);
  const [atktEnabled, setATKTEnabled] = useState<boolean>(false);
  const [revalEnabled, setRevalEnabled] = useState<boolean>(false);
  const [showAtktBtn, setShowAtktBtn] = useState<boolean>(false);
  const [atktbtn, setAtktBtn] = useState<boolean>(resultData.failedHeads > 0);
  const [softCopyChecked, setSoftCopyChecked] = useState<string[]>([]);
  const { displayAtomPayNetzPortalNative } = nttPaymentOptions({
    paymentDetails,
    navigation: props.navigation,
  });
  const { getATKTAndReval, loadingATKTAndReval } = useGetATKTAndReval();
  const { applyRevalDetailsForUser } = useApplyRevalDetailsForUser();
  const { applyATKTDetailsForUser, loadingApplyATKTForUser } = useApplyATKTDetailsForUser();
  const { fetchATKTDetailsForUser, dataGetATKTDetailsForUser } = useGetATKTDetailsForUser();
  const { fetchRevalDetailsForUser, dataGetRevalDetailsForUser } = useGetRevalDetailsForUser();
  const { fetchRevalSubjects, loadingRevalSubjects } = useGetRevalSubjects();
  const { fetchMarkSheets, dataGetMarkSheets, loadingMarkSheets, errorGetMarkSheets } =
    useGetUserMarkSheets();
  const { t } = useI18n();
  const [cellState, setCellState] = useState<TCellState>({
    open: true,
    current: 0,
  });

  useEffect(() => {
    if(examId){
      handleATKTRevalFrom(examId);
      fetchRevalDetailsForUser({
        variables: {
          exam: examId,
        },
      });
    }
  }, [examId]);

  useEffect(() => {
    if (ATKTData?.id) {
      getATKTDetails(ATKTData?.id);
    }
  }, [ATKTData]);

  const getATKTDetails= async(atktId: string)=>{
    const response= await fetchATKTDetailsForUser({
      variables: {
        atktExam: atktId,
      },
    });
    if (
      !response?.data?.getATKTDetailsForUser ||
      response.data.getATKTDetailsForUser.length === 0
    ) {
      setShowAtktBtn(true);
    }
  };

  const InfoItem = (props: infoItemProps) => {
    const { label, value, color = colors.primaryText, rejectionReason, childValue } = props;
    return (
      <InfoItemWrapper>
        <NormalTextSelect value={label} color={colors.secondaryText} lines={1} />
        <MarksObtainedWrapper>
          <MediumTextSelect
            value={value}
            color={color}
            fontWeight={'normal'}
            fontFamily={fonts.semibold}
          />
          {childValue && (
            <NormalTextAtom value={childValue} color={colors.yellow} lineHeight={2.4} />
          )}
        </MarksObtainedWrapper>
        {rejectionReason && <BlockRejectionReason data={rejectionReason} width={'100%'} />}
      </InfoItemWrapper>
    );
  };

  const MarksItem = (props: infoItemProps) => {
    const { label, value, fontFamily = fonts.regular } = props;
    return (
      <MarksItemWrapper>
        <NormalTextSelect
          value={label}
          color={colors.secondaryText}
          lines={1}
          fontFamily={fontFamily}
        />
        <NormalTextSelect value={value} color={colors.secondaryText} fontFamily={fontFamily} />
      </MarksItemWrapper>
    );
  };

  function closeDetailModal() {
    handleRevaluationModal(false);
    handleATKTModal(false);
    setChecked([]);
    setSoftCopyChecked([]);
  }

  const handleATKTRevalFrom = async (examId: string) => {
    try {
      const response = await getATKTAndReval({
        variables: {
          examId: examId,
        },
      });
      if (response?.data?.atktAndReval) {
        const { atktExam, revalForm } = response.data.atktAndReval;
        if (revalForm !== null && revalForm.status === 'COMPLETED' && atktExam !== null) {
          setATKTEnabled(true);
        }
        if (revalForm !== null && atktExam == null && revalForm.status === 'OPEN_FOR_APPLICATION') {
          setRevalEnabled(true);
        }
        ///need to remove this condition
        if (
          revalForm !== null &&
          atktExam !== null &&
          revalForm.status === 'OPEN_FOR_APPLICATION'
        ) {
          setRevalEnabled(true);
          setATKTEnabled(true);
        }
        setRevalData(revalForm);
        setATKTData(atktExam);
      }
    } catch (errors: any) {
      showNativeError(errors?.message);
    }
  };

  const handlefetchRevalsubjects = async () => {
    try {
      const response = await fetchRevalSubjects({
        variables: {
          examId: examId,
        },
      });
      if (response?.data) {
        setRevalSubjects(response?.data);
        handleRevaluationModal(true);
      }
      if (response?.data == null) {
        showNativeError(response?.errors[0]?.message);
      }
    } catch (error: any) {
      showNativeError(error?.message);
    }
  };

  const handleSubmit = () => {
    if (revalData?.id) {
      applyRevalution(revalData.id);
    }
  };

  function closeMakePaymentModal() {
    setMakePaymentModal(false);
    setChecked([]);
    setSoftCopyChecked([]);
  }

  function onPaymentSuccess(): void {
    showNativeSuccess(t('paymentSuccessMessage'));
  }

  async function handleOnlinePayment() {
    try {
      if (paymentDetails) {
        setMakePaymentModal(false);
        handleATKTModal(false);
        handleRevaluationModal(false);
        displayAtomPayNetzPortalNative(paymentDetails, onPaymentSuccess);
      }
    } catch (e: any) {
      showNativeError(e.message);
    }
  }

  const applyRevalution = async (revalFormId: string) => {
    setLoading(true);
    try {
      const response = await applyRevalDetailsForUser({
        variables: {
          formId: revalFormId,
          subjects: checked,
          softCopySubjects: softCopyChecked,
        },
      });
      if (response?.data.createRevalDetailsForUser?.paymentOrder) {
        setPaymentDetails(response?.data.createRevalDetailsForUser?.paymentOrder);
        setMakePaymentModal(true);
      }
    } catch (error: any) {
      showNativeError(error?.message);
    } finally {
      setLoading(false);
    }
  };

  const applyATKT = async (atktExamId: string) => {
    try {
      const response = await applyATKTDetailsForUser({
        variables: {
          atktExamId: atktExamId,
        },
      });
      if (response.data == null) {
        showNativeError(response?.errors[0]?.message);
      }
      if (response?.data?.createATKTDetailsForUser.paymentOrder) {
        setATKTFormData(response?.data?.createATKTDetailsForUser);
        setPaymentDetails(response?.data?.createATKTDetailsForUser.paymentOrder);
        setATKTSubjects(response?.data?.createATKTDetailsForUser?.subjects);
        handleATKTModal(true);
      }
    } catch (error: any) {
      showNativeError(error?.message);
    }
  };

  const handleATKTSubmit = () => {
    setMakePaymentModal(true);
  };

  return (
    <Container>
      <MobileHeader label={t('results.label')} navigation={props.navigation} showEdit={false} />
      <ContentWrapper nestedScrollEnabled={true} contentContainerStyle={styles.containerView}>
        {!loadingATKTAndReval ? (
          <>
            <HeaderFive value={t('examDetails.label')} color={colors.primaryText} />
            <ItemRowWrapper>
              <InfoItem label={t('examName.label')} value={resultData?.exam?.name || '-'} />
            </ItemRowWrapper>

            <ItemRowWrapper>
              <InfoItem
                label={t('marksObtained.label')}
                value={getTableValue(
                  marksType
                  ? resultData?.totalAggregateMarks
                  : resultData?.finalMarksObtained, '-')}
                childValue={marksType && resultData?.totalGraceInNumber ? ` + ${resultData.totalGraceInNumber}` : ''}
              />

              {revalEnabled && revalData && (
                <InfoItem
                  label={'Reval Due Date'}
                  value={format(
                    parse(revalData.dueDate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date()),
                    DT.DATE_ABBREVIATED_MONTH_YEAR,
                  )}
                  color={colors.primaryText}
                />
              )}
              {atktEnabled && ATKTData && (
                <InfoItem
                  label={'ATKT Due Date'}
                  value={format(
                    parse(ATKTData.dueDate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date()),
                    DT.DATE_ABBREVIATED_MONTH_YEAR,
                  )}
                  color={colors.primaryText}
                />
              )}
            </ItemRowWrapper>

            <ItemRowWrapper>
              <InfoItem
                label={t('totalMarks.label')}
                value={getTableValue(
                  marksType
                  ? resultData?.averageTotalMarks
                  : resultData?.totalMarks, '-')}
              />
              <InfoItem
                label={t('average.label')}
                value={getTableValue(
                  marksType
                    ? resultData?.averageOnAggregateMarks
                    : resultData?.average,
                  '-',
                )}
              />
            </ItemRowWrapper>

            {Boolean(!marksType) && (
              <>
                <ItemRowWrapper>
                  <InfoItem label={t('sgpa.label')} value={getTableValue(resultData?.sgpa, '-')} />
                  <InfoItem label={t('grade.label')} value={getTableValue(resultData?.grade, '-')} />
                </ItemRowWrapper>

                <ItemRowWrapper>
                  <InfoItem label={t('cgpa.label')} value={getTableValue(resultData?.cgpa, '-')} />
                </ItemRowWrapper>
              </>
            )}

            {dataGetRevalDetailsForUser?.getRevalDetailsForUser.length > 0 && (
              <>
                <SubjectBlock>
                  <NormalTextAtom value={'Subjects for Reval'} fontWeight={'400'} />
                  <SubjectWarpper>
                  {dataGetRevalDetailsForUser?.getRevalDetailsForUser[0]?.subjects.map((item: any) => {
                      return <Chip label={item?.subject?.name} close={false} />;
                    })}
                  </SubjectWarpper>
                </SubjectBlock>
              </>
            )}

            {dataGetATKTDetailsForUser?.getATKTDetailsForUser.length > 0 && (
              <>
                <SubjectBlock>
                  <NormalTextAtom value={'Subjects for ATKT'} fontWeight={'400'} />
                  <SubjectWarpper>
                    {dataGetATKTDetailsForUser?.getATKTDetailsForUser[0].subjects.map(
                      (item: any) => {
                        return <Chip label={item?.subject?.name} close={false} />;
                      },
                    )}
                  </SubjectWarpper>
                </SubjectBlock>
              </>
            )}

            <MarksReportWrapper>
              <HeaderFive value={t('marksReport.label')} color={colors.primaryText} />
              <TableHeadWrapper>
                <NormaltextAtom value={t('subject.label')} fontFamily={fonts.semibold} />
                <NormaltextAtom value={t('marks.label')} fontFamily={fonts.semibold} />
              </TableHeadWrapper>

              <TableBodyWrapper nestedScrollEnabled={true}>
                {resultData?.marks && resultData.marks.length ? (
                  resultData.marks.map((item: any, i: number) => (
                    <>
                      <TableRowWrapper
                        onPress={() =>
                          setCellState({
                            open: !cellState.open,
                            current: i === cellState?.current ? null : i,
                          })
                        }>
                        <FirstCell>
                          <ActionIconWrapper>
                            {cellState.current === i ? (
                                <CollapseCaretIcon disableClick={true} />
                            ) : (
                              <ExpandCaretIcon disableClick={true} />
                            )}
                          </ActionIconWrapper>
                          <MediumTextSelect
                            value={item?.subject?.name || '-'}
                            color={colors.primaryText}
                            fontWeight={'normal'}
                            fontFamily={fonts.semibold}
                            lines={2}
                          />
                        </FirstCell>
                        <MediumTextSelect
                          value={
                            marksType
                              ? `${item?.average} / ${item?.averageTotalMarks}`
                              : `${item?.marksObtained} / ${item?.totalMarks}`
                          }
                          color={colors.primaryText}
                          fontWeight={'normal'}
                          fontFamily={fonts.semibold}
                        />
                      </TableRowWrapper>

                      {Boolean(cellState.current === i) && (
                        <MarksWrapper nestedScrollEnabled={true}>
                          {!marksType && (
                            <MarksItem
                              label={t('internal.label')}
                              value={`${item?.internal?.finalMarksObtained}/${item?.internal?.totalMarks}`}
                              fontFamily={fonts.semibold}
                            />
                          )}
                          <MarksItem
                            label={t('max.label')}
                            value={getTableValue(
                              marksType
                                ? item?.averageTotalMarks
                                : item?.internal?.totalMarks,
                              '',
                            )}
                          />
                          <MarksItem
                            label={t('min.label')}
                            value={getTableValue(
                              marksType
                                ? item?.averagePassingMarks
                                : item?.internal?.passingMarks,
                              '',
                            )}
                          />
                          <MarksItem
                            label={t('obt.label')}
                            value={getTableValue(
                              marksType
                                ? item?.average
                                : item?.internal?.marksObtained,
                              '',
                            )}
                          />
                          <MarksItem
                            label={t('grace.label')}
                            value={getTableValue(
                              marksType ? item?.totalGrace : item?.internal?.grace,
                              '',
                            )}
                          />
                          <MarksItem
                            label={t('final.label')}
                            value={getTableValue(
                              marksType
                                ? item?.averageWithGrace
                                : item?.internal?.finalMarksObtained,
                              '',
                            )}
                          />

                          {!marksType && (
                            <>
                              <MarksItem
                                label={t('external.label')}
                                value={`${item?.external?.marksObtained}/${item?.internal?.totalMarks}`}
                                fontFamily={fonts.semibold}
                              />
                              <MarksItem
                                label={t('max.label')}
                                value={getTableValue(item?.external?.totalMarks, '')}
                              />
                              <MarksItem
                                label={t('min.label')}
                                value={getTableValue(item?.external?.passingMarks, '')}
                              />
                              <MarksItem
                                label={t('obt.label')}
                                value={getTableValue(item?.external?.marksObtained, '')}
                              />
                              <MarksItem
                                label={t('grace.label')}
                                value={getTableValue(item?.external?.grace, '')}
                              />
                              <MarksItem
                                label={t('final.label')}
                                value={getTableValue(item?.external?.finalMarksObtained, '')}
                              />
    
                              <MarksItem
                                label={t('creditsEarned.label')}
                                value={getTableValue(item?.creditsEarned, '')}
                                fontFamily={fonts.semibold}
                              />
                              <MarksItem
                                label={t('grade.label')}
                                value={getTableValue(item?.grade, '')}
                                fontFamily={fonts.semibold}
                              />
                              <MarksItem
                                label={t('gradePoint.label')}
                                value={getTableValue(item?.gradePoint, '')}
                                fontFamily={fonts.semibold}
                              />
                            
                            </>
                          )}
                        </MarksWrapper>
                      )}
                      <BorderWrapper />
                    </>
                  ))
                ) : (
                  <MediumTextSelect
                    value={t('noResultsFound.text')}
                    style={styles.noRecordView}
                    textAlign={'center'}
                    fontFamily={fonts.semibold}
                    fontWeight={'normal'}
                    color={colors.primaryText}
                  />
                )}
              </TableBodyWrapper>
            </MarksReportWrapper>
          </>
        ) : (
          <LoaderSpinner />
        )}

        <NormalModal
          Headerpopup={t('applyRevaluation.label')}
          modalVisible={RevaluationModalState}
          setModalVisible={closeDetailModal}
          hideSubmit={false}
          scrollableContent={false}>
          <RevaluationModal
            resultData={revalSubjects.requestRevalSubjects}
            checked={checked}
            setChecked={setChecked}
            setSoftCopyChecked={setSoftCopyChecked}
            softCopyChecked={softCopyChecked}
            handleSubmit={handleSubmit}
            isLoading={loading}
          />
          <NormalModal
            setModalVisible={closeMakePaymentModal}
            Headerpopup={t('payment.make-payment')}
            modalVisible={makePaymentModal}
            hideSubmit={false}>
            <AtktRevalPaymentModal
              paymentDetails={paymentDetails}
              handleSubmit={handleOnlinePayment}
              showButton={true}
            />
          </NormalModal>
        </NormalModal>

        <NormalModal
          Headerpopup={t('applyAtkt.label')}
          modalVisible={ATKTModalState}
          setModalVisible={closeDetailModal}
          hideSubmit={false}
          scrollableContent={false}>
          <AtktModal resultData={atktSubjects} handleSubmit={handleATKTSubmit} />
          <NormalModal
            setModalVisible={closeMakePaymentModal}
            Headerpopup={t('payment.make-payment')}
            modalVisible={makePaymentModal}
            hideSubmit={false}>
            <AtktRevalPaymentModal
              paymentDetails={paymentDetails}
              showButton={true}
              handleSubmit={handleOnlinePayment}
            />
          </NormalModal>
        </NormalModal>
      </ContentWrapper>
      <ButtonWrapper>
        {revalEnabled && (
          <SecondaryBtn
            label={t('applyRevaluation.label')}
            onPress={() => {
              handlefetchRevalsubjects();
            }}
            canLoad={loadingRevalSubjects}
          />
        )}
        {atktEnabled && showAtktBtn && atktbtn && (
          <SecondaryBtn
            label={t('applyAtkt.label')}
            onPress={() => {
              if (ATKTData?.id) applyATKT(ATKTData.id);
            }}
            canLoad={loadingApplyATKTForUser}
          />
        )}
      </ButtonWrapper>
    </Container>
  );
}

const styles = {
  containerView: {
    paddingBottom: 60,
  },
  noRecordView: {
    paddingTop: 16,
  },
};

const Container = styled.View`
  background-color: ${colors.white};
  height: 100%;
`;

const ContentWrapper = styled.ScrollView`
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
  height: 90%;
`;

const ItemRowWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`;

const InfoItemWrapper = styled.View`
  min-width: 120px;
`;

const TableHeadWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0px 10px 24px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const MarksReportWrapper = styled.View`
  margin-top: 24px;
`;

const TableBodyWrapper = styled.ScrollView`
  max-height: ${height - (isIOS ? 522 : 504)};
`;

const TableRowWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 57px;
  align-items: center;
  column-gap: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const FirstCell = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  max-width: 70%;
`;

const ActionIconWrapper = styled.View`
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
`;

const BorderWrapper = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const MarksWrapper = styled.ScrollView`
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  max-height: 200px;
`;

const MarksItemWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const ButtonWrapper = styled.View`
  margin-top: 12px;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
`;
const SubjectWarpper = styled.View`
  margin-top: 5px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const SubjectBlock = styled.View`
  margin-top: 16px;
  width: 100%;
`;

const MarksObtainedWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
