import { gql, useLazyQuery, useMutation } from '@apollo/client';

const inviteUserMutation = gql`
  mutation ($payload: InviteInstituteEmployeeInput!) {
    inviteEmployee(payload: $payload)
  }
`;

const inviteEdbaAdminMutation = gql`
  mutation ($invite: InviteEdbaUser!) {
    inviteEdbaUser(invite: $invite) {
      id
      email
      firstName
      lastName
    }
  }
`;

const getEdbaUsersQuery = gql`
  query ($limit: Int, $skip: Int, $filters: EDBAUsersFilterInput) {
    Users(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        id
        userName
        email
        firstName
        lastName
        personalDetails {
          fullName
        }
      }
    }
  }
`;

const getUsersQuery = gql`
  query(
    $limit: Int
    $skip: Int
    $filters: InstituteEmployeeFilterInput
    $lookup: LookupFilter
  ) {
    instituteEmployees(
      limit: $limit
      skip: $skip
      filters: $filters
      lookup: $lookup
    ) {
      hasNextPage
      totalCount
      data {
        id
        userName
        personalDetails {
          firstName
          lastName
          fullName
          status
          draft {
            detail {
              firstName
              lastName
            }
          }
        }
        employeeId
        accountId
        email
        status
        draftTotalOnboardingCompletion
        totalOnboardingCompletion
        type
        owner
        profilePicture {
          documentName
          documentUrl
        }
        verificationStatus {
          expiresAt
          status
          type
          userId
        }
        roles {
          name
        }
        classDetails
        classDetailsObj {
          batch {
            value: id
            label: name
            alias
            class {
              id
              alias
              name
            }
          }
          class {
            id
            alias
            course {
              id
              alias
            }
          }
          divisionDetails {
            division
            subjects {
              id
              name
              alias
            }
          }
        }
      }
    }
  }
`;

const deleteUserMutation = gql`
  mutation ($id: String!) {
    deleteUser(id: $id) {
      status
      message
    }
  }
`;

const importEmployeeMutation = gql`
  mutation ($file: Upload!) {
    employeeBulkInvite(file: $file) {
      sent
      message
      notSent
      errors
      total
    }
  }
`;

export {
  inviteUserMutation, getUsersQuery, getEdbaUsersQuery,
};

export function useInviteEmployee() {
  const [inviteInstituteEmployee, { data: inviteEmployeeData }] = useMutation(inviteUserMutation);
  return { inviteInstituteEmployee, inviteEmployeeData };
}

export function useInviteEdbaAdmin() {
  const [inviteEdbaAdmin, { data: inviteEdbaAdminData }] = useMutation(inviteEdbaAdminMutation);
  return { inviteEdbaAdmin, inviteEdbaAdminData };
}

export function useDeleteUser() {
  const [deleteUser, { data: deleteUserData }] = useMutation(deleteUserMutation);
  return { deleteUser, deleteUserData };
}

export function useImportEmployee() {
  const [employeeBulkInvite, { data: employeeBulkInviteData, error: inviteEmployeeError }] =
    useMutation(importEmployeeMutation);
  return { employeeBulkInvite, employeeBulkInviteData };
}
