/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useForm } from 'react-hook-form';
import formJSON from '../../../form-json/academics/courses.json';
import Element from '../Forms/ApplicationElement.web';
import { useQuery } from '@apollo/client';
import { getAllBoadUniversitiesQuery } from '../../../graphql/academics/board-universities';
import { getAllStreamQuery } from '../../../graphql/academics/streams';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { autoCompleteLimit, AUTOCOMPLETE_MODULE, USER_TYPE } from '../../../constant';
import { getFilteredUniversities, getFilteredStreams } from 'src/components/services';
import { userInformation } from 'src/utils/manageState';
import { Grid } from '@mui/material';
import styled from 'styled-components';

export default function CourseForm(props: any) {
  const { editCourseData } = props;
  const [elements, setElements] = useState({});
  const {
    formState: { errors },
    reset,
  } = useForm();
  const { fields }: any = elements ?? {};
  const allStreams = useQuery(getAllStreamQuery, {
    variables: { limit: autoCompleteLimit, skip: 0 },
  });
  const allBoadUniversities = useQuery(getAllBoadUniversitiesQuery, {
    variables: { limit: autoCompleteLimit, skip: 0 },
  });
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;
  const disableIndex = [0, 1, 2, 5];

  if (userType === USER_TYPE.EMPLOYEE) {
    disableIndex.forEach((item) =>
      item != 5
        ? (formJSON[0].fields[item].disabled = true)
        : ((formJSON[0].fields[5].children[0].disabled = true),
          (formJSON[0].fields[5].children[1].disabled = true)),
    );
  }

  useEffect(() => {
    let getdata = allStreams.data;
    let getUniversitydata = allBoadUniversities.data;
    if (allStreams.loading == false && allBoadUniversities.loading == false) {
      loadStreams(getdata.streams.data);
      loadBoardUniversities(getUniversitydata.universities.data);
    }
    if (editCourseData) {
      setEditData(editCourseData);
    }
  }, [allStreams.data, allBoadUniversities.data]);

  function loadBoardUniversities(boardUniversityArray: any) {
    formJSON[0].fields[1].option = boardUniversityArray;
    setElements(formJSON[0]);
  }

  function loadStreams(streamArray: any) {
    formJSON[0].fields[0].option = streamArray;
    setElements(formJSON[0]);
  }

  function setEditData(data: any) {
    reset({
      course_code: data?.code,
      course_name: data?.name,
      course_alias: data?.alias,
    });
  }
  async function searchSelectScroll(queryVariable: any, type: string) {
    let param = {
      limit: autoCompleteLimit,
      skip: queryVariable * autoCompleteLimit,
    };

    if (type === AUTOCOMPLETE_MODULE.STREAM) {
      let streamRespose = getFilteredStreams(param);
      return streamRespose;
    }

    if (type === AUTOCOMPLETE_MODULE.UNIVERSITY) {
      let universityRespose = getFilteredUniversities(param);
      return universityRespose;
    }
  }

  async function searchSelectOnchange(queryVariable: any, type: string) {
    let filters = {
      name: {
        regex: queryVariable,
      },
    };

    if (type === AUTOCOMPLETE_MODULE.STREAM) {
      let streamRespose = getFilteredStreams({ filters: filters });
      return streamRespose;
    }

    if (type === AUTOCOMPLETE_MODULE.UNIVERSITY) {
      let universityRespose = getFilteredUniversities({ filters: filters });
      return universityRespose;
    }
  }

  return (
    <View>
      <Grid container>
        {fields ? (
          fields.map((field: any, i: number) =>
            field?.id !== 'duration-group' ? (
              <CustomGrid item md={12}>
                <Element
                  key={i}
                  field={field}
                  control={props.control}
                  errors={props.errors}
                  searchSelectScroll={searchSelectScroll}
                  searchSelectOnchange={searchSelectOnchange}
                  dropdownDefault={editCourseData}
                />
              </CustomGrid>
            ) : (
              <>
                {field?.children?.map((child: object) => (
                  <CustomGrid item md={6}
                    style={{
                      paddingLeft: child?.id === 'duration_type' ? 16 : 0,
                      marginTop: child?.id === 'duration_type' ? 19 : 0,
                    }}
                    >
                    <Element
                      key={i}
                      field={child}
                      control={props.control}
                      errors={props.errors}
                      searchSelectScroll={searchSelectScroll}
                      searchSelectOnchange={searchSelectOnchange}
                      dropdownDefault={editCourseData}
                    />
                  </CustomGrid>
                ))}
              </>
            ),
          )
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </Grid>
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
});

const CustomGrid = styled(Grid)`
`;

