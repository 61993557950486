import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function QualifyApplication(props: any) {
  const { size = 24, color = 'black' } = props;
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M15.8505 6.12705L5.72811 16.2494L2.1495 12.6708C1.9801 12.5014 1.70544 12.5014 1.53601 12.6708L1.12705 13.0798C0.957651 13.2492 0.957651 13.5238 1.12705 13.6933L5.42138 17.9876C5.59078 18.157 5.86544 18.157 6.03488 17.9876L16.873 7.14953C17.0423 6.98014 17.0423 6.70547 16.873 6.53604L16.464 6.12705C16.2946 5.95765 16.0199 5.95765 15.8505 6.12705Z"
        fill={color}
        fill-opacity="0.6"/>
      <Path
        d="M21.8505 6.12705L11.7281 16.2494L11 15.5213L10 16.5662L11.4214 17.9876C11.5908 18.157 11.8654 18.157 12.0349 17.9876L22.873 7.14953C23.0424 6.98014 23.0424 6.70547 22.873 6.53604L22.464 6.12705C22.2946 5.95765 22.0199 5.95765 21.8505 6.12705Z"
        fill={color}
        fill-opacity="0.6"/>
    </Svg>
  );
}

export default QualifyApplication;