import React, { MutableRefObject, useCallback } from 'react';

import { DropdownOptionsType } from 'src/types';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import ModalSaveAndCancelButtonGroup from '../ModalSaveAndCancelButtonGroup';
import { componentMapper } from '../ddfElements';
import styled from 'styled-components';
import { validatorMapper } from '../Forms/validatorMapper';
import FormViewer from '../Forms/DDFFormViewer/index.web';

interface SelectReservationCriteriaFormProps {
  onSubmit: () => void;
  closeModal: () => void;
  reservationCriteriaOptions: DropdownOptionsType[];
  resCriteriaId: MutableRefObject<string>;
  isEdit: boolean;
}

const SelectReservationCriteriaForm = (props: SelectReservationCriteriaFormProps) => {
  const {
    onSubmit,
    closeModal,
    reservationCriteriaOptions,
    resCriteriaId,
    isEdit,
  } = props;

  const formSchema = {
    name: 'selectReservationCriteria',
    label: 'selectReservationGroup.label',
    fields: [
      {
        name: 'reservationCriteriaSelect',
        component: 'reservation-criteria-radio-select-table',
        tableData: reservationCriteriaOptions,
        currentId: resCriteriaId,
        isEdit: isEdit,
      },
    ],
  };

  const SelectCriteriaTemplate = useCallback(() => {
    return (
      <FormViewer schema={formSchema} isOnboarding={false} inModalView={true} paddingRight={0} />
    );
  }, [formSchema]);

  return (
    <>
      <FormWrapper>
        {formSchema && (
          <FormRenderer
            componentMapper={componentMapper}
            FormTemplate={SelectCriteriaTemplate}
            schema={formSchema}
            onSubmit={onSubmit}
            validatorMapper={validatorMapper}
          />
        )}
      </FormWrapper>
      <ModalSaveAndCancelButtonGroup
        onCancel={closeModal}
        isSubmitting={false}
        onSubmit={onSubmit}
      />
    </>
  );
};

const FormWrapper = styled.div`
  min-height: 580px;
`;

export default SelectReservationCriteriaForm;
