import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props:any) {
  return (
    <Svg
      width={28}
      height={32}
      viewBox="0 0 28 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M16.75 26h1.5a.75.75 0 00.75-.75v-13.5a.75.75 0 00-.75-.75h-1.5a.75.75 0 00-.75.75v13.5a.75.75 0 00.75.75zM27 5h-5.15l-2.126-3.544A3 3 0 0017.151 0h-6.302a3 3 0 00-2.572 1.456L6.15 5H1a1 1 0 00-1 1v1a1 1 0 001 1h1v21a3 3 0 003 3h18a3 3 0 003-3V8h1a1 1 0 001-1V6a1 1 0 00-1-1zM10.74 3.182A.375.375 0 0111.062 3h5.876a.375.375 0 01.321.182L18.351 5H9.649l1.091-1.818zM23 29H5V8h18v21zM9.75 26h1.5a.75.75 0 00.75-.75v-13.5a.75.75 0 00-.75-.75h-1.5a.75.75 0 00-.75.75v13.5a.75.75 0 00.75.75z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
