import IndicatorBadge from '../Badge/IndicatorBadge';
import React from 'react'
import { fonts } from 'src/styles/theme/styles';
import styled from 'styled-components';

interface ButtonProps {
    label?: string;
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
    onPress?: any;
    showIndicatorBadge: boolean;
}
export default function ButtonWithBorderBottom(props: ButtonProps) {

    const {
        label,
        color,
        backgroundColor,
        borderColor,
        onPress,
        showIndicatorBadge
    } = props;
    return (
        <Button
            color={color}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            onClick={onPress}
        >
            {label}
            {showIndicatorBadge && <IndicatorBadge />}
        </Button>
    )
}

const Button = styled.button<ButtonProps>`
  background: none;
  border: none;
  font-size: 15px;
  font-family: ${fonts.semibold};
  color: ${({ color }) => color};
  padding: 10px 5px;
  margin-right: 17px;
  cursor: pointer;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom: 2px solid ${({ borderColor }) => borderColor} ;
  display: flex;
  flex-direction: row;
`;
