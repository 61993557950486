import React, { useEffect, useRef } from 'react';
import { height, width } from 'src/constant/device';
import styled from 'styled-components/native';

import LottieView from 'lottie-react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { Animated, StyleSheet } from 'react-native';
import { colors } from 'src/styles/theme/styles';

const LoadingView = () => {
  return (
    <LoadingWrapper width={width} height={height}>
      <LottieView source={require('src/assets/lottie/loader.json')} autoPlay loop />
    </LoadingWrapper>
  );
};

export default LoadingView;

export const LoadingOrComponent = ({ loading, children, ...props }: any) => {
  return loading ? <LoaderSpinner {...props} /> : children;
};

type LoadingViewProps = {
  width: number;
  height: number;
};

const LoadingWrapper = styled.View<LoadingViewProps>`
  position: absolute;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  backgroundcolor: ${colors.lighterBorder};
`;

export const SkeletonLoading = ({
  skeleton,
  children,
  loading,
}: {
  skeleton: any[];
  children: any;
  loading: boolean;
}) => {
  const opacity = useRef(new Animated.Value(1));
  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(opacity.current, {
          toValue: 1,
          useNativeDriver: true,
          duration: 500,
        }),
        Animated.timing(opacity.current, {
          toValue: 0.4,
          useNativeDriver: true,
          duration: 800,
        }),
      ]),
    ).start();
  }, [opacity]);

  return loading
    ? skeleton.map(({ key, width, height, styles }) => (
        <Animated.View
          key={key}
          style={[{ width, height, opacity: opacity.current, ...styles }, skeletonLoading.skeleton]}
        />
      ))
    : children;
};

const skeletonLoading = StyleSheet.create({
  skeleton: {
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    borderRadius: 4,
  },
});
