import React, { useEffect, useState } from 'react';
import { ERROR, SUCCESS } from 'src/constant';
import styled from 'styled-components';
import {
  useGetSubjectCredits,
  useCreateBatchSubjectCredits,
} from 'src/graphql/assessment/assessment-credits';
import { useHistory, useParams } from 'src/routes/routing.web';

import BatchClassSelectionForm from 'src/components/molecules/Assessment/BatchClassSelectionForm';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import { getFilteredSubjects } from 'src/components/services';
import { height } from 'src/constant/device';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import CreditsTable from 'src/components/molecules/Table/CreditsTable';
import { UPDATE_SUCCESS } from 'src/constant/message';

const headCells = [
  {
    id: 'subjects',
    label: 'subjects.label',
    align: 'left',
  },
  {
    id: 'credits',
    label: 'credits.label',
    align: 'right',
  },
];

export default function Credits() {
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();
  const { setHeading } = useHeaderTitle();
  const history = useHistory();
  let row;

  const [selectedBatch, setSelectedBatch] = useState<string>('');
  const [selectedClassId, setSelectedClassId] = useState<string>('');
  const [allFormValuesAvailable, setAllFormValuesAvailable] = useState<boolean>(false);
  const [subjectCredits, setSubjectCredits] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [totalSubjects, setTotalSubjects] = useState(null);
  const [options, setOptions] = useState([]);

  const { getSubjectCredits, subjectCreditsData, refetchSubjectCredits, subjectCreditsLoading } = useGetSubjectCredits();
  const { createBatchSubjectCredits } = useCreateBatchSubjectCredits();

  row = subjectCreditsData?.subjectCredits?.data;
  let { urlBatchId = null }: any = useParams();

  useEffect(() => {
    setHeading([{ text: t('credits.label'), url: '' }]);
  }, []);

  useEffect(() => {
    if (selectedClassId) {
      loadSubject(selectedClassId);
    }
  }, [selectedClassId]);

  async function loadSubject(classId: string) {
    let filters = {
      class: {
        eq: classId,
      },
    };

    let subjectsResponse = await getFilteredSubjects({ filters: filters });
    setTotalSubjects(subjectsResponse?.options?.length);
    if (subjectsResponse?.options) {
      setIsDataAvailable(true);
      setOptions(subjectsResponse?.options);
    }
  }

  const createCreditPointOptions = (creditPoints: any) => {
    const options = creditPoints?.map((cp: any) => {
      return {
        label: cp,
        value: cp,
      };
    });
    return options;
  };

  const createTableData = (options: any) => {
    if (options.length > 0) {
      const tabledata = options?.map((item: any) => {
        const data = { ...item };
        if (item?.creditPoints) {
          const creditPoint = createCreditPointOptions(item?.creditPoints);
          data['options'] = creditPoint;
          if (row && row.length > 0) {
            const subject = row[0]?.credits?.find((i: any) => i?.subject?.id === item?.id);
            if (subject) {
              data['credit'] = subject?.credit;
            }
          }
        }
        return data;
      });
      setTableData(tabledata);
    }
  };

  function makeFetchQueryVariable(batchId: string) {
    return {
      filters: {
        batch: {
          eq: batchId,
        },
      },
      limit: 0,
    };
  }

  function handleFetch(currentlySelectedBatch: string) {
    getSubjectCredits({ variables: makeFetchQueryVariable(currentlySelectedBatch) });
  }

  function addToUrl(currentBatchId: string) {
    history.push(`/assessment/credits/${currentBatchId}`);
  }

  const retrieveValues = (formData: any) => {
    clearValues();
    setSelectedBatch(formData.batch.value);
    if (formData?.batch?.class?.id) setSelectedClassId(formData.batch.class.id);
    addToUrl(formData.batch.value);
    loadSubject(formData?.batch?.class?.id || selectedClassId);
  };

  function clearValues() {
    setCheckError(false);
    setTotalSubjects(null);
    setOptions([]);
    setSubjectCredits([]);
    setIsDataAvailable(false);
  }

  async function addEditTestFn(formData: any) {
    setCheckError(true);
    try {
      if (subjectCredits?.length === totalSubjects) {
        const subjectCreditObject = {
          batch: selectedBatch,
          credits: subjectCredits,
        };
        const response = await createBatchSubjectCredits({
          variables: { payload: subjectCreditObject },
        });
        if (response?.data) {
          if (refetchSubjectCredits) refetchSubjectCredits();
          setAlertDetails({ message: UPDATE_SUCCESS.CREDITS, level: SUCCESS });
          setCheckError(false);
        }
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
  }

  return (
    <LoadContentWrapper>
      <TopRowsWrapper>
        <SelectionWrapper>
          <BatchClassSelectionForm
            retrieveValues={retrieveValues}
            urlBatchId={urlBatchId}
            pagePath={'/assessment/credits'}
            setParentBatchId={setSelectedBatch}
            setParentClassId={setSelectedClassId}
            setParentAllFormValuesAvailable={setAllFormValuesAvailable}
            isLoading={Boolean(!isDataAvailable && selectedClassId)}
          />
        </SelectionWrapper>
        {/* Disabled update credit functionality for institute admin temporarily */}
        {/* <OutlineButton
          label={t('update.label')}
          onPress={addEditTestFn}
          customDisabled={!selectedBatch}
          width={80}
          style={{ height: 32, marginTop: 18 }}
        /> */}
      </TopRowsWrapper>

      <TableWrapper>
        <CreditsTable
          height={height - 300}
          maxHeightTable={height - 300}
          header={headCells}
          tabledata={options}
          setData={setSubjectCredits}
          isDataAvailable={isDataAvailable}
          checkError={checkError}

        />
      </TableWrapper>
    </LoadContentWrapper>
  );
}

const TopRowsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10;
`;

const SelectionWrapper = styled.div`
  margin-right: 10px;
`;

const TableWrapper = styled.div`
  z-index: -1;
  width: 549px;
  height: 100%;
`;
