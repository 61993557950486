import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { RefreshControl, ScrollView, TouchableOpacity } from 'react-native';
import { useI18n } from 'src/i18n/hooks';
import { useHeaderTitle } from 'src/contexts/header-context';
import { getNotifications, updateLastSeenNotification } from 'src/graphql/notifications/index';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { height, isIOS, isWeb, width } from 'src/constant/device';
import { breakpoints, colors } from 'src/styles/theme/styles';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { useHistory } from 'src/routes/routing';
import { createSearchFilter, getMessageType } from 'src/components/services';
import NotificationItem from './NotificationItem';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { AUDIENCE_TYPE, USER_TYPE, UserType, limit } from 'src/constant';
import navigationStrings from 'src/navigation/navigationStrings';
import WebLoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import NativeLoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import styled from 'styled-components/native';
import { TouchableHighlight } from 'react-native-gesture-handler';
import NormalModal from 'src/components/atoms/Modals/Normal/index';
import NewsModalContent from '../Communication/News/ModalContent';
import EventModalContent from 'src/components/molecules/Event/EventModalContent';
import { userInformation } from 'src/utils/manageState';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import MessageModalContent from 'src/components/molecules/Message/MessageModalContent';
import { NoData } from 'src/components/atoms/NoData';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { getStorageFunction } from 'src/components/services/storage-service';
import { KEYS } from 'src/constant/key';
import AbstractFlashList from 'src/components/atoms/FlashList';
import { SD } from 'src/constant/standardDimensions';

export function notificationUrl(notif: any, userType?: number) {
  let refID = notif?.referenceId;
  const type = notif?.type;
  const audienceType = notif?.audienceType;
  const userId = notif?.createdBy?.id;
  switch (type) {
    case 'ASSIGNMENT':
      return isWeb
        ? refID
          ? userType && userType === UserType.INSTITUTE_EMPLOYEE
            ? `/assignments/${refID}/detail`
            : `/assignments/pending/${refID}/detail`
          : `/assignments/pending`
        : navigationStrings.NATIVE_ASSIGNMENT_DETAIL;

    case 'ASSIGNMENTCOMMENT':
      if (refID) {
        const newRefId = refID.split('/Comment')[0];
        refID = newRefId;
      }
      return isWeb
        ? refID
          ? userType && userType === UserType.INSTITUTE_EMPLOYEE
            ? audienceType === AUDIENCE_TYPE.ONE_TO_ONE && userId ? `/assignments/${refID}/${userId}/detail` : `/assignments/${refID}/detail`
            : audienceType === AUDIENCE_TYPE.BATCH ? `/assignments/pending/${refID}/discussion` : `/assignments/pending/${refID}/detail`
          : '/assignments/pending'
        : userType && userType === UserType.INSTITUTE_EMPLOYEE
          ? audienceType === AUDIENCE_TYPE.BATCH ? navigationStrings.NATIVE_ASSIGNMENT_DETAIL : navigationStrings.NATIVE_STUDENT_DETAIL
          : (audienceType === AUDIENCE_TYPE.BATCH || audienceType === AUDIENCE_TYPE.ONE_TO_ONE) && navigationStrings.NATIVE_ASSIGNMENT_DETAIL;

    case 'NEWS':
    case 'NOTICE':
      const path = type == 'NEWS' ? 'news' : 'notices';
      return isWeb
        ? refID
          ? `/communication/${path}/${refID}/detail`
          : `/communication/${path}`
        : navigationStrings.NATIVE_TABBAR_NEWS_NOTICES;
    case 'EVENT':
      return isWeb
        ? refID
          ? `/events/upcoming/${refID}/detail`
          : `/events/upcoming`
        : navigationStrings.EVENTS;
    case 'MESSAGE':
      return !isWeb ? navigationStrings.MESSAGES : null;
    case 'APPLICATION':
      return isWeb
        ? userType === USER_TYPE.APPLICANT
          ? `/dashboard/${refID}/detail`
          : `/application/list/limit/50/page/1/${refID}/detail`
        : navigationStrings.APPLICATION_INFORMATION;
    case 'UPDATE':
      return isWeb && refID
        ? notif?.title?.includes('Cancel Admission') || notif?.title?.includes('Handicap Report')
          ? '/dashboard'
          : `/classroom/${refID}/students/limit/${limit}/page/1`
        : null;
    default:
      return '#';
  }
}
export function makeOptions(notif: any, tokenData?: string | null, userType?: number) {
  let options = {};
  switch (notif.type) {
    case 'ASSIGNMENT':
      options = { assignmentId: notif.referenceId };
      break;
    case 'ASSIGNMENTCOMMENT':
      const parts = notif.referenceId.split('/');
      const assignmentId = parts[0];
      options = {
        assignmentId: assignmentId,
        ...(userType === USER_TYPE.EMPLOYEE
          ? notif.audienceType == AUDIENCE_TYPE.ONE_TO_ONE && notif?.createdBy?.id
            ? { studentId: notif.createdBy.id }
            : {}
          : notif.audienceType === AUDIENCE_TYPE.BATCH
            ? { showDiscussionModal: true }
            : {}),
      };
      break;
    case 'NEWS':
    case 'NOTICE':
      options = { communicationType: notif.type.toLowerCase(), newsnoticeId: notif.referenceId };
      break;
    case 'EVENT':
      options = { eventId: notif.referenceId, eventType: 'upcoming', tokenData: tokenData };
      break;
    case 'APPLICATION':
      options = { applId: notif.referenceId };
  }
  return options;
}

export async function makeMessageOptions(notif: any) {
  let messageType = await getMessageType({ messageId: notif.referenceId })
  let options = {
    messageId: notif.referenceId,
    messageType
  };
  return options;
}

export default function Notifications(props) {
  const { t } = useI18n();
  const history = useHistory();
  const userinfo = userInformation();
  const { setHeading } = useHeaderTitle();

  let page = 1;
  let nativeDataLimit: any = limit;

  if (isWeb) {
    const { page: webpage = 1, dataLimit = limit }: any = useParams();
    page = webpage;
    nativeDataLimit = dataLimit;
  }
  const rowDefaultLimit = parseInt(nativeDataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState<number>(rowDefaultLimit);


  const filters = createSearchFilter(rowLimit, (page - 1) * rowLimit, []);


  const {
    data: notifications,
    refetch: refetchNotification,
    loading: notificationLoading,
  } = useQuery(getNotifications, { variables: filters });
  const [lastNotificationSeen, { data: lastSeen, error: lastSeenUpdateError }] = useMutation(
    updateLastSeenNotification,
  );
  const [refreshing, setRefreshing] = React.useState(false);
  const [row, setRow] = React.useState<any>([]);
  const [totalCount, settotalCount] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [messageModal, setMessageModal] = React.useState(false);
  const [modalType, setModalType] = React.useState('');
  const [modalTitle, setModalTitle] = React.useState('');
  const [modalData, setModalData] = React.useState({});
  const [tokenData, setToken] = React.useState<string>('');

  function handleFetch() {
    refetchNotification(filters);
  }

  function nativeRefresh() {
    handleFetch();
    setRefreshing(false);
  }

  function setTitle() {
    setHeading([
      {
        text: t('notifications.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    if (isWeb) {
      setTitle();
    } else {
      loadToken();
    }
    if (!notificationLoading) {
      setRow([]);
      refetchNotification();
    }
  }, []);

  useEffect(() => {
    if (notifications?.getNotifications?.data) {
      const rows = notifications?.getNotifications?.data;
      setRow(rows);
      if (!lastSeen || (rows[0] && rows[0].updatedAt > lastSeen)) {
        lastNotificationSeen();
      }
    }
    if (notifications?.getNotifications?.totalCount) {
      settotalCount(notifications?.getNotifications?.totalCount);
    }
  }, [notifications]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, rowLimit]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    nativeRefresh();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  async function loadToken() {
    let token = await getStorageFunction(KEYS.ACCESS_TOKEN);
    setToken(`Bearer ${token}`);
  }

  return (
    <Fragment>
      {isWeb && width > breakpoints.md ? (
        <LoadContentWrapper>
          <ContentWrapper>
            {notificationLoading ? (
              <SpinnerView>
                <WebLoaderSpinner />
              </SpinnerView>
            ) : row && row?.length > 0 ? (
              <AbstractFlashList estimatedItemSize={94} data={row}>
                {({ item }) => {
                  const notif = item as (typeof row)[0];
                  return (
                    <TouchableOpacity
                      onPress={async () => {
                        if (notif.type === 'MESSAGE') {
                          const { messageId, messageType } = await makeMessageOptions(notif);
                          history.push({
                            pathname: `/messages/${messageType}/limit/${limit}/page/1`,
                            state: {
                              messageId,
                              messageType,
                            },
                          });
                        } else {
                          history.push(notificationUrl(notif, userinfo.userType));
                        }
                      }}>
                      <NotificationItem key={notif.id} notification={notif} />
                    </TouchableOpacity>
                  );
                }}
              </AbstractFlashList>
            ) : (
              <NoDataWrapper>
                <HeaderThree value={t('noNotifications.text')} color={colors.secondaryText} />
              </NoDataWrapper>
            )}
          </ContentWrapper>
          <Pagination
            pathName={'notifications'}
            total={totalCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            rowLimit={rowLimit}
            setRowLimit={setRowLimit}
            hidePagination={!!(totalCount && totalCount <= rowLimit)}
          />
        </LoadContentWrapper>
      ) : (
        <Fragment>
          <MobileHeader label={t('notifications.label')} backIcon={true} {...props} />
          <NativeWrapper>
            <ScrollWrapper>
              <ScrollView
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                  flexGrow: 1,
                  paddingBottom: 60,
                }}>
                {notificationLoading ? (
                  <SpinnerView>
                    <NativeLoaderSpinner />
                  </SpinnerView>
                ) : row && row.length > 0 ? (
                  <AbstractFlashList estimatedItemSize={94} data={row}>
                    {({ item: notif, index }) => {
                      return (
                        <TouchableHighlight
                          underlayColor={colors.inputBorder}
                          onPress={async () => {
                            if (
                              notif.type === 'NEWS' ||
                              notif.type === 'NOTICE' ||
                              notif.type === 'EVENT' ||
                              notif.type === 'MESSAGE'
                            ) {
                              setModalType(notif.type);
                              setModalTitle(notif.type.toLowerCase());
                              if (notif.type !== 'MESSAGE') {
                                setModalData(makeOptions(notif, tokenData));
                                setIsModalOpen(true);
                              } else {
                                setModalData(await makeMessageOptions(notif));
                                setMessageModal(true);
                              }
                            } else {
                              props.navigation.navigate(
                                notificationUrl(notif, userinfo.userType),
                                makeOptions(notif, null, userinfo.userType),
                              );
                            }
                          }}>
                          <NotificationContainer>
                            <NotificationItem key={notif.id} notification={notif} />
                          </NotificationContainer>
                        </TouchableHighlight>
                      );
                    }}
                  </AbstractFlashList>
                ) : (
                  <CenterView>
                    <NoData icon={faBell} label={t('noNotifications.text')} />
                  </CenterView>
                )}
              </ScrollView>
            </ScrollWrapper>
          </NativeWrapper>
        </Fragment>
      )}
      <NormalModal
        isSubmitting={false}
        infoModal={true}
        setModalVisible={closeModal}
        modalVisible={isModalOpen}
        handleSave={() => { }}
        Headerpopup={
          modalType === 'EVENT'
            ? t('event.label')
            : modalTitle === 'news'
              ? t('news.label')
              : t('notices.label')
        }
        width={SD.primaryInfoModal.width}
        height={SD.primaryInfoModal.height}
        maxWidth={"lg"}>
        {modalType === 'EVENT' ? (
          <EventModalContent {...modalData} />
        ) : (
          <NewsModalContent type={modalTitle === 'news' ? 'NEWS' : 'NOTICE'} {...modalData} />
        )}
      </NormalModal>
      {messageModal && (
        <MessageModalContent
          {...modalData}
          navigation={props.navigation}
          setMessageModal={setMessageModal}
        />
      )}
    </Fragment>
  );
}

const ContentWrapper = styled.View`
  width: 100%;
  flex: 1;
`;

const CenterView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const NativeWrapper = styled.View`
  margin-top: 0;
  height: ${isIOS ? height - 90 : height};
  background-color: ${colors.white};
`;


const NotificationContainer = styled.View`
  padding: 0px 24px;
`;

const SpinnerView = styled.View`
  top: 50%;
`;

const ScrollWrapper = styled.View`
  height: 96.5%;
`;

const NoDataWrapper = styled.View`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;
