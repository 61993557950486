import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  const size = props.size || 16;
  return (
    <Svg
      width={size * scale}
      height={size * scale}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M13.7256 19.5843C13.8079 19.5293 13.9045 19.5 14.0034 19.5C14.136 19.5 14.2632 19.5527 14.357 19.6464C14.4507 19.7402 14.5034 19.8674 14.5034 20C14.5034 20.0989 14.4741 20.1956 14.4192 20.2778C14.3642 20.36 14.2861 20.4241 14.1948 20.4619C14.1034 20.4998 14.0029 20.5097 13.9059 20.4904C13.8089 20.4711 13.7198 20.4235 13.6499 20.3536C13.5799 20.2836 13.5323 20.1945 13.513 20.0975C13.4937 20.0006 13.5036 19.9 13.5415 19.8087C13.5793 19.7173 13.6434 19.6392 13.7256 19.5843Z"
        fill="#2B78CA"
        stroke="#2B78CA"
      />
      <Rect x="4.00244" y="5" width="7.0007" height="7" rx="1" fill="#2B78CA" />
      <Rect x="13.0034" y="5" width="7.0007" height="7" rx="1" fill="#2B78CA" />
      <Rect x="4.00244" y="14" width="7.0007" height="7" rx="1" fill="#2B78CA" />
      <Path
        d="M14.0021 16.8594V15.8999C14.0021 15.3476 14.4498 14.8999 15.0021 14.8999H16.0021"
        stroke="#2B78CA"
        stroke-width="2"
        stroke-linecap="round"
      />
      <Path
        d="M19.0021 14.8999V18.8594C19.0021 19.4117 18.5544 19.8594 18.0021 19.8594H17.0021"
        stroke="#2B78CA"
        stroke-width="2"
        stroke-linecap="round"
      />
      <Path
        d="M1.00208 9.49988V3.93359C1.00208 2.82902 1.89751 1.93359 3.00208 1.93359H8.72468"
        stroke="#2B78CA"
        stroke-width="2"
        stroke-linecap="round"
      />
      <Path
        d="M1.00208 16.4337L1.00208 22C1.00208 23.1046 1.89751 24 3.00208 24H8.72468"
        stroke="#2B78CA"
        stroke-width="2"
        stroke-linecap="round"
      />
      <Path
        d="M23.0021 9.49988V3.93359C23.0021 2.82902 22.1066 1.93359 21.0021 1.93359H15.2795"
        stroke="#2B78CA"
        stroke-width="2"
        stroke-linecap="round"
      />
      <Path
        d="M23.0021 16.4337V22C23.0021 23.1046 22.1066 24 21.0021 24H15.2795"
        stroke="#2B78CA"
        stroke-width="2"
        stroke-linecap="round"
      />
    </Svg>
  );
}

export default SvgComponent;
