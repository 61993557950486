import React, { useEffect, useState } from 'react';
import { NoRecordsFound, TableCells, TableRows } from 'src/components/molecules/Table/TableAtom';
import styled from 'styled-components';
import { useParams,useHistory } from 'src/routes/routing.web';
import { DownloadFileIcon, InfoSquareIcon } from 'src/components/atoms/ActionIcons';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { TableBody } from '@mui/material';
import ViewResultModal from 'src/components/molecules/Assessment/Results/ViewResultModal';
import { height } from 'src/constant/device';
import { ERROR, SUCCESS, limit } from 'src/constant';
import { useGetStudentMarkSheets } from 'src/graphql/assessment/assessment-results';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import useTable from 'src/components/molecules/Table/UseTable';
import { checkRemark, downloadFileRestAPI, getTableValue } from 'src/utils/utility';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { colors, fonts } from 'src/styles/theme/styles';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';

const headCells1 = [
  {
    id: 'examName',
    label: 'exam.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: '150px' },
  },
  {
    id: 'creditsEarned',
    label: 'credits-earned.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '100px' },
  },
  {
    id: 'creditGrade',
    label: 'credit-grade.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '100px' },
  },
  {
    id: 'sgpa',
    label: 'SGPA (Grade)',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '60px' },
  },
  {
    id: 'cgpa',
    label: 'cgpa.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '50px', paddingRight: 80 },
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '50px' },
  },
];

export default function StudentResult() {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { setAlertDetails } = useAlertSystem();
  const { userDetail, userId } = userInformation();
  const [headCells, setHeadCells] = useState(headCells1);
  const [row, setRow] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(null);
  const [remarkModal, setRemarkModal] = useState<boolean>(false);
  const [blockReason, setBlockReason] = useState({});
  const history = useHistory();
  const {
    getStudentMarkSheets,
    loadingStudentMarkSheets,
    dataStudentMarkSheets,
    errorStudentMarkSheets,
    refetchStudentMarkSheets,
  } = useGetStudentMarkSheets();

  const { page = 1, dataLimit = limit }: any = useParams();

  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  useEffect(() => {
    handleFetch();
  }, [currentPage, rowLimit]);

  // Set header title text
  useEffect(() => {
    setHeading([{ text: t('results.label'), url: '' }]);
  }, []);

  useEffect(() => {
    if (
      !loadingStudentMarkSheets &&
      dataStudentMarkSheets &&
      dataStudentMarkSheets?.studentMarkSheets?.data
    ) {
      setRow(dataStudentMarkSheets?.studentMarkSheets?.data);
      setTotalCount(dataStudentMarkSheets?.studentMarkSheets?.totalCount);
    }
  }, [loadingStudentMarkSheets, dataStudentMarkSheets]);

  useEffect(() => {
    if (errorStudentMarkSheets) {
      handleNoExam();
    }
  }, [errorStudentMarkSheets]);

  const { TblHead, TblContainer, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    currentPage,
  );

  function handleFetch() {
    getStudentMarkSheets({
      variables: {
        limit: rowLimit,
        skip: (currentPage - 1) * rowLimit,
      },
    });
  }

  function handleNoExam() {
    setRow([]);
    setTotalCount(0);
  }


  function closeRemarkModal() {
    setRemarkModal(false);
    setBlockReason({});
  }

  const exportReport = (url: string, fileName: string) => {
    downloadFileRestAPI(url, fileName, err => {
      setAlertDetails({ message: err?.message || 'Failed to download', level: ERROR });
    });
    setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
  };

  const handleExamView = (item:any) => {
    if(item.status === 'BLOCK') {
      let blockReason = { examName: item?.exam?.name };
      const data = checkRemark(item?.statusActivity, item?.status)?.data;
      if (data?.reason) blockReason['reason'] = data.reason;
      setBlockReason(blockReason);
      setRemarkModal(true);
    }else{
      history.push(`/results/${item.exam?.id}`,{
        examName: item?.exam?.name,
      });
    }
  };

  return (
    <LoadContentWrapper>
      <TblContainer>
        <TblHead />

        {!loadingStudentMarkSheets ? (
          <>
            {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting()?.map((item: any, i: number) => {
                  return (
                    <TableRows key={`tr${i}`}>
                      <TableCells value={item?.exam?.name || 'N/A'} onPress={() => handleExamView(item)} clickable={true} color={ colors.primary}/>
                      <TableCells value={getTableValue(item?.creditsEarned)} align={'right'} />
                      <TableCells value={getTableValue(item?.creditGrades)} align={'right'} />
                      <TableCells
                        value={`${getTableValue(item?.sgpa)} ${
                          item?.grade ? `(${item.grade})${
                            item?.graceAppliedOn && item.graceAppliedOn?.length ? '*' : ''
                          }` : ''
                        }`}
                        align={'right'}
                        color={
                          item?.failedHeads && Number.isInteger(item?.sgpa)
                            ? colors.errorColor
                            : colors.primaryText
                        }
                      />
                      <TableCells
                        value={getTableValue(item?.cgpa)}
                        align={'right'}
                        style={{ paddingRight: 80 }}
                      />

                      {/* Actions */}
                      <TableCells align="right">
                        <ActionIcons>
                          <DownloadFileIcon
                            color={colors.secondaryText}
                            tooltipTitle={t('downloadResult.label')}
                            onPress={() => exportReport(
                              `assesment/${item?.exam?.id}/${userDetail?.division}/${userId}/marksheet`,
                              'Marksheet.pdf'
                            )}
                          />
                        </ActionIcons>
                      </TableCells>
                    </TableRows>
                  );
                })}
              </TableBody>
            ) : (
              <NoRecordsFound colspan={6} maxHeight={0.6 * height} />
            )}
          </>
        ) : (
          <SpinnerWrapper>
            <LoaderSpinner />
          </SpinnerWrapper>
        )}
      </TblContainer>

      {row && row?.length > 0 && (
        <Pagination
          pathName={'results'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          hidePagination={totalCount <= rowLimit}
        />
      )}

      <NormalModal
        setModalVisible={closeRemarkModal}
        modalVisible={remarkModal}
        infoModal={true}
        Headerpopup={t('results.label')}
        maxWidth={'md'}
        preventRedirectOnClose={true}
        handleSave={() => null}>
        <BlockResultWrapper>
          <MediumTextSelect
            value={`Your result for ${blockReason?.examName} is blocked.`}
            color={colors.primaryText}
            fontFamily={fonts.semibold}
            fontSize={1.7}
          />
          <NormalTextSelect
            value={`Reason: ${blockReason?.reason || 'N/A'}`}
            color={colors.secondaryText}
            fontsize={1.7}
          />
        </BlockResultWrapper>
      </NormalModal>
    </LoadContentWrapper>
  );
}

const ActionIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 13px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const BlockResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
