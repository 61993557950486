import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import React from 'react';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';

interface Props {
  isSubmitting: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
  cancelLabel?: string;
  saveLabel?: string;
  height?: number | undefined;
  zIndex?: number;
  saveButtonWidth?: number;
  customDisabled?: boolean;
}

export default function ModalSaveAndCancelButtonGroup(props: Props) {
  const { isSubmitting, onCancel, onSubmit, cancelLabel = 'cancel.label', saveLabel = 'save.label', height, zIndex = 1, saveButtonWidth, customDisabled = false } = props;
  const { t } = useI18n();
  const { handleSubmit } = useFormApi();
  return (
    <ActionWrapper height={height} zIndex={zIndex}>
      <Divider />
      <ButtonContainer>
        <OutlineButton label={t(cancelLabel)} onPress={onCancel} marginRight={17} />
        <SecondaryBtn
          canLoad={isSubmitting}
          disabled={isSubmitting}
          customDisabled={customDisabled}
          label={t(saveLabel)}
          onPress={onSubmit ? onSubmit : handleSubmit}
          width={saveLabel == 'save.label' ? 64 : saveButtonWidth}
        />
      </ButtonContainer>
    </ActionWrapper>
  );
}

const ActionWrapper = styled.View<{ height: number | undefined, zIndex: number }>`
  position: sticky;
  bottom: 0;
  background-color: ${colors.white};
  height: ${({ height }) => height ? height : 'auto'};
  display: grid;
  z-index: ${(props) => props.zIndex};
`;

const Divider = styled.View`
  height: 1px;
  margin: 0px -32px;
  background-color: ${colors.dividerColor};
`;

const ButtonContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 0;
`;