import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import React, { useEffect, useState } from "react";
import {
  TableCells,
  TableDiv,
  TableRows,
} from "src/components/molecules/Table/TableAtom";

import { AttendanceData } from "src/components/molecules/Attendance/AttendanceTypes";
import AttendanceNotConfigured from "src/components/organism/Attendance/AttendanceNotConfigured";
import { CircularProgressbarStyles } from "react-circular-progressbar/dist/types";
import SmallTextAtom from "../Text/SmallTextAtom";
import { TableBody } from "@mui/material";
import { colors } from "../../../styles/theme/styles";
import { stableSortStrings } from "src/utils/utility";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";
import useTable from "src/components/molecules/Table/UseTable";

type PropType = {
  data?: AttendanceData[] | null;
  showStudentLeaveCalendar: (studentId: string) => void;
};

type ComponentPropsType = {
  ProgressBarStyles: CircularProgressbarStyles;
};

const headerCols = [
  {
    id: "rollNo",
    label: "rollno.label",
    align: "left",
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: "rollNo",
    isSorted: true,
  },
  {
    id: "studentName",
    label: "studentName.label",
    align: "left",
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: "heading",
    isSorted: false,
  },
  {
    id: "attendanceDays",
    label: "attendanceDays.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "attendancePercentage",
    label: "attendancePercentage.label",
    align: "left",
    disableSorting: true,
  },
];

export const StudentListAttendanceHistory = ({
  data,
  showStudentLeaveCalendar,
}: PropType) => {
  const { t } = useI18n();
  const [headCells, setHeadCells] = useState(headerCols);
  const [rowData, setRowData] = useState<typeof data>(data);
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    rowData,
    headCells
  );

  useEffect(() => {
    const sortableColumn = headCells.find((col) => col.isSorted);
    if (sortableColumn && rowData) {
      const sortedData = stableSortStrings(
        rowData,
        sortableColumn.sortParam as string,
        sortableColumn.ascend
      );
      setRowData(sortedData);
    }
  }, [headCells]);

  return (
    <Container>
      {Array.isArray(data) && data?.length > 0 ? (
        <TableDiv>
          <TblContainer overflowX="scroll">
            <TblHead setHeadCells={setHeadCells} />
            <TableBody>
              {recordsAfterPagingAndSorting()?.map((item: AttendanceData) => (
                <TableRows
                  key={item.id}
                  onClick={() => showStudentLeaveCalendar(item.id)}
                >
                  <TableCells value={item?.rollNo || "-"} />
                  <TableCells value={item.heading} />
                  <TableCells value={`${item.attend}/${item.total}`} />
                  <TableCells>
                    <AttendanceProgressBar percentage={item.percentage} />
                  </TableCells>
                </TableRows>
              ))}
            </TableBody>
          </TblContainer>
        </TableDiv>
      ) : (
        <AttendanceNotConfiguredWrapper>
          <AttendanceNotConfigured topPosition="35%" type={1} />
        </AttendanceNotConfiguredWrapper>
      )}
    </Container>
  );
};

const AttendanceProgressBar = ({
  percentage,
}: Pick<AttendanceData, "percentage">) => {
  return (
    <ProgressBarWrapper>
      <CircularProgressbarWithChildren
        styles={ComponentProps.ProgressBarStyles}
        value={parseInt(percentage, 10)}
      >
        <SmallTextAtom
          value={`${percentage}%`}
          fontWeight={"400"}
          color={colors.secondaryText}
        />
      </CircularProgressbarWithChildren>
    </ProgressBarWrapper>
  );
};

const ComponentProps: ComponentPropsType = {
  ProgressBarStyles: buildStyles({
    rotation: 0,
    strokeLinecap: "butt",
    textSize: "33px",
    pathTransitionDuration: 0.5,
    pathColor: colors.primary,
    textColor: colors.secondaryText,
    trailColor: colors.white,
    backgroundColor: colors.progressBarBackground,
  }),
};

const ProgressBarWrapper = styled.div`
  width: 40px;
`;

const AttendanceNotConfiguredWrapper = styled.div`
  height: 250px;
`;

const Container = styled.div``;
