import React from 'react';
import { largeDevice, mediumSize } from 'src/styles/theme/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

export const getMultiple = () => {
  if (largeDevice) {
    return 10;
  } else if (mediumSize) {
    return 9;
  }
  return 8;
};

const rem = (size: number | [number]) => {
  const multiple = getMultiple();
  if (Array.isArray(size)) {
    return size.map((s) => `${s * multiple}px`).join(' ');
  }
  return `${size * multiple}px`;
};

export const withRem = (Component: React.FC) => (props: any) => (
    <StyledThemeProvider theme={{rem}}>
        <Component {...props} />
    </StyledThemeProvider>
)