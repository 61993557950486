import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={16 * scale}
      height={16 * scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M12.4997 2H10.9997V0.25C10.9997 0.1125 10.8873 0 10.7498 0H10.2498C10.1123 0 9.99977 0.1125 9.99977 0.25V2H3.99991V0.25C3.99991 0.1125 3.88741 0 3.74991 0H3.24993C3.11243 0 2.99993 0.1125 2.99993 0.25V2H1.49997C0.67186 2 0 2.67188 0 3.5V14.5C0 15.3281 0.67186 16 1.49997 16H12.4997C13.3278 16 13.9997 15.3281 13.9997 14.5V3.5C13.9997 2.67188 13.3278 2 12.4997 2ZM12.9997 14.5C12.9997 14.775 12.7747 15 12.4997 15H1.49997C1.22497 15 0.999977 14.775 0.999977 14.5V6H12.9997V14.5ZM12.9997 5H0.999977V3.5C0.999977 3.225 1.22497 3 1.49997 3H12.4997C12.7747 3 12.9997 3.225 12.9997 3.5V5ZM3.49992 12H6.49985C6.77485 12 6.99984 11.775 6.99984 11.5V8.5C6.99984 8.225 6.77485 8 6.49985 8H3.49992C3.22493 8 2.99993 8.225 2.99993 8.5V11.5C2.99993 11.775 3.22493 12 3.49992 12ZM3.99991 9H5.99986V11H3.99991V9Z"
        fill="#000"
        fillOpacity={0.6}
      />
    </Svg>
  );
}

export default SvgComponent;
