import { DropdownOptionsType } from 'src/types';
import { ViewMode } from 'src/components/molecules/Forms/types';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import { FormOptions } from '@data-driven-forms/react-form-renderer/renderer-context';

export const promoteStudentDirect = (
  academicYearOptions: DropdownOptionsType[],
  batchOptions: DropdownOptionsType[],
  subjectGroupOptions: DropdownOptionsType[],
  divisionOptions: DropdownOptionsType[],
  loadBatch: (academicYearId: string) => void,
  loadSubjectGroup: (batchData: any) => void,
  loadDivisions: (batchData: any) => void,
  loadSchema: (promotionType: string) => void,
  maintainGrNo: boolean,
  handleMaintainGrNo: (checked: boolean) => void,
) => ({
  name: 'promoteStudent',
  label: 'promoteStudent.label',
  viewMode: ViewMode.EDIT,
  fields: [
    {
      name: 'promotionType',
      label: 'selectPromotionType.required.label',
      component: 'custom-select',
      options: promotionTypeOptions,
      handleChange: (formOptions: any, newValue: { value: string }) => {
        if (newValue.value === 'ADMISSION') {
          formOptions.change('academicYear', null);
          formOptions.change('batch', null);
          formOptions.change('subjectGroup', null);
          formOptions.change('division', null);
        }
        loadSchema(newValue.value);
      },
      maxHeight: 200,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.promotionType.required',
        },
      ],
    },
    {
      name: 'academicYear',
      label: 'selectAcademicYear.required.label',
      component: 'custom-select',
      options: academicYearOptions,
      handleChange: (formOptions: any, newValue: { value: string }) => {
        formOptions.change('batch', null);
        formOptions.change('subjectGroup', null);
        formOptions.change('division', null);
        loadBatch(newValue.value);
      },
      maxHeight: 200,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.academicYear.required',
        },
      ],
    },
    {
      name: 'batch',
      label: 'selectBatchClass.required.label',
      component: 'custom-select',
      options: batchOptions,
      handleChange: (formOptions: any, newValue: { value: string }) => {
        formOptions.change('subjectGroup', null);
        formOptions.change('division', null);
        loadDivisions(newValue);
        loadSubjectGroup(newValue);
      },
      maxHeight: 200,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.batch.required',
        },
      ],
    },
    {
      name: 'subjectGroup',
      label: 'selectSubjectGroup.required.label',
      component: 'custom-select',
      options: subjectGroupOptions,
      maxHeight: 200,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.subjectGroup.required',
        },
      ],
    },
    {
      name: 'division',
      label: 'selectDivision.label',
      component: 'custom-select',
      options: divisionOptions,
      maxHeight: 200,
    },
    {
      name: 'maintainGrNo',
      label: 'maintainGrNo.label',
      component: 'checkbox',
      displayCentered: true,
      isChecked: maintainGrNo,
      handleChange: (formOptions: FormOptions, checked: boolean) => handleMaintainGrNo(checked),
    },
  ],
});

const promotionTypeOptions = [
  {
    label: "DIRECT",
    value: "DIRECT"
  },
  {
    label: "ADMISSION",
    value: "ADMISSION"
  }
]