import { ADD_SUCCESS, UPDATE_SUCCESS } from "src/constant/message";
import {
  CreateNTTMerchantPayload,
  MerchantFormData,
  MerchantsDataType,
} from "./types";
import { AUTOCOMPLETE_MODULE, ERROR, SUCCESS, limit } from "src/constant";
import {
  NoRecordsFound,
  TableCells,
  TableRows,
} from "src/components/molecules/Table/TableAtom";
import React, { useCallback, useEffect, useState } from "react";
import {
  useCreateMerchantDetail,
  useGetMerchantDetails,
} from "src/graphql/payment/Merchant";
import { useParams } from 'react-router';
import { EditIcon } from "src/components/atoms/ActionIcons";
import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import MerchantDetailsForm from "src/components/molecules/Payment/NTT/MerchantDetailsForm";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import Search from "src/components/atoms/SearchBar/index.web";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { TableBody } from '@mui/material';
import { height } from "src/constant/device";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useForm } from "react-hook-form";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import useTable from "src/components/molecules/Table/UseTable";
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import { useFilter } from "src/contexts/filter-context";
import { getCommonNameSearchFilter } from "src/components/services/filters";
import { createSearchFilter, debounce } from "src/components/services";

const FILTER_MODULE = AUTOCOMPLETE_MODULE.MERCHANTS;
const DEBOUNCE_DELAY = 500;

const headCells1 = [
  {
    id: "name",
    label: "Merchant Name",
    align: "left",
    disableSorting: true,
  },
  {
    id: "merchId",
    label: "Merchant ID",
    align: "left",
    disableSorting: true,
  },
  {
    id: "institute",
    label: "institute.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "action",
    label: "action.label",
    align: "right",
    disableSorting: true,
  },
];

function Merchants() {
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();
  const { setHeading } = useHeaderTitle();
  const { filters, setFilter } = useFilter();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<MerchantFormData>();

  const maxHeight = height - 375;
  const paginationHeightOffset = -64;

  const [headCells, setHeadCells] = useState(headCells1);
  const [modalState, handleModal] = useState<boolean>(false);
  const [row, setRow] = useState<MerchantsDataType[]>();
  const { page = 1, dataLimit = limit }: any = useParams();
  const rowDefaultLimit = parseInt(dataLimit) ?? limit;

  let searchValue: string = "";
  if (filters && filters?.[FILTER_MODULE]?.on) {
    searchValue = filters?.[FILTER_MODULE]?.search;
  }

  const [currentPage, setCurrentPage] = useState(page);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [currentItem, setCurrentItem] = useState<MerchantsDataType | null>(
    null
  );
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [searching, setSearching] = useState<string>(searchValue);

  const {
    getMerchantDetails,
    MerchantDetailsData,
    MerchantDetailsError,
    MerchantDetailsLoading,
    refetchMerchantDetails,
  } = useGetMerchantDetails();

  const { createMerchantDetail } = useCreateMerchantDetail();

  useEffect(() => {
    setSearching(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [searchValue, currentPage]);

  useEffect(() => {
    if (MerchantDetailsData && MerchantDetailsData?.NTTMerchants) {
      setRow(MerchantDetailsData.NTTMerchants?.data);
      setTotalCount(MerchantDetailsData.NTTMerchants?.totalCount);
    }
  }, [MerchantDetailsData]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    0,
    null,
    null,
    false,
    true,
    null,
    paginationHeightOffset
  );

  useEffect(() => {
    setHeading([{ text: t("ndpsMerchants.label"), url: "" }]);
  }, []);

  async function handleFetch() {
    const filters = getCommonNameSearchFilter(searchValue);
    const merchantFilter = createSearchFilter(
      rowLimit,
      (currentPage - 1) * rowLimit,
      filters
    );
    getMerchantDetails({ variables: merchantFilter });
  }

  function closeModal() {
    setCanClick(true);
    handleModal(false);
  }

  function handleAddMerchant() {
    setCurrentItem(null);
    setIsEditModal(false);
    handleModal(true);
  }

  function handleEditMerchant(item: MerchantsDataType) {
    setCurrentItem(item);
    setIsEditModal(true);
    handleModal(true);
  }

  const handleSearch = (q: string) => {
    setSearching(q);
    delayedQuery(q);
  };

  const delayedQuery = useCallback(
    debounce((q: string) => {
      const shouldPersist = q.trim() !== "";
      const filter = { [FILTER_MODULE]: { on: shouldPersist, search: q } };
      setFilter(filter);
    }, DEBOUNCE_DELAY),
    []
  );

  async function handleAddEditMerchant(formData: MerchantFormData) {
    try {
      setCanClick(false);
      const payloadObj: CreateNTTMerchantPayload = {
        institute: formData.institute.value,
        merchId: formData.merchId,
        merchPassword: formData.merchPassword,
        name: formData.name,
        prodId: formData.prodId,
        reqEncKey: formData.reqEncKey,
        reqSalt: formData.reqEncKey,
        resDecKey: formData.resDecKey,
        resSalt: formData.resSalt,
        reqHashKey: formData.reqHashKey,
        resHashKey: formData.resHashKey,
        env: formData.env.value,
        mcc: formData.mcc,
      };

      if (isEditModal && currentItem) {
        payloadObj.id = currentItem.id;
      }

      const response = await createMerchantDetail({
        variables: {
          payload: payloadObj,
        },
      });
      if (response?.data) {
        setAlertDetails({
          message: isEditModal
            ? UPDATE_SUCCESS.MERCHANT_DETAILS
            : ADD_SUCCESS.MERCHANT_DETAILS,
          level: SUCCESS,
        });
        closeModal();
        if (refetchMerchantDetails) refetchMerchantDetails();
      }
    } catch (error) {
      setAlertDetails({ message: error?.message, level: ERROR });
      closeModal();
    }
  }

  return (
    <LoadContentWrapper>
      <TopWrapper>
        <SearchWrapper>
          <Search
            id="reasonSearch"
            handleChange={handleSearch}
            value={searching}
            label={t('Search Merchants')}
          />
        </SearchWrapper>
        <>
          <SecondaryBtn label={t('addMerchants.label')} onPress={handleAddMerchant} lines={1} />
        </>
      </TopWrapper>
      <TblContainer height={'100%'} overflowX="scroll">
        <TblHead setHeadCells={setHeadCells} />
        <>
          {!MerchantDetailsLoading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: MerchantsDataType) => (
                    <TableRows key={item.id}>
                      <TableCells value={item.name} badge={item.env.label} />
                      <TableCells value={item.merchId} />
                      <TableCells value={item?.institute ? item.institute.label : ''} />
                      <TableCells align={'right'}>
                        <ActionIcons>
                          <EditIcon onPress={() => handleEditMerchant(item)} />
                        </ActionIcons>
                      </TableCells>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>
      <Pagination
        pathName={'ndps/merchants'}
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        setRowLimit={setRowLimit}
        hidePagination={!!(totalCount && totalCount <= rowLimit)}
      />

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        modalVisible={modalState}
        Headerpopup={isEditModal ? t('Edit Merchant Detail') : t('Add Merchant Detail')}
        maxWidth={'lg'}
        handleSave={handleSubmit(handleAddEditMerchant)}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'save.label'}>
        <MerchantDetailsForm
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          isEditModal={isEditModal}
          currentItem={currentItem}
        />
      </NormalModal>
    </LoadContentWrapper>
  );
}

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const TopWrapper = styled(FlexRow)`
  justify-content: space-between;
`;

const ActionIcons = styled(FlexRow)`
  gap: 8px;
  justify-content: flex-end;
`;

const SearchWrapper = styled(FlexRow)`
  align-items: flex-start;
  max-width: 280px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

export default Merchants;
