import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components/native';
import { useTheme } from 'styled-components';
import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Checkbox
} from '@mui/material';
import OutlineButtonWeb from 'src/components/atoms/Button/OutlineButton.web';
import { colors } from 'src/styles/theme/styles';
import Chip from 'src/components/atoms/Chip';
import { useThemeSystem } from 'src/contexts/theme-context';
import SmallButton from 'src/components/atoms/Button/SmallButton.web';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import ArrayInput from '../Input/ArrayInput.web';
import FormControlLabel from '@mui/material/FormControlLabel';
import { clipLongText } from 'src/utils/utility';
import { DT } from 'src/constant/dateTime';
import { format, startOfISOWeek, subDays } from 'date-fns';

const filterOptionsString = [
  { id: 1, label: 'is', type: 'string', filteringCriteria: 'eq' },
  { id: 2, label: 'is not', type: 'string', filteringCriteria: 'ne' },
  { id: 3, label: 'contains', type: 'string', filteringCriteria: 'regex' },
  { id: 4, label: 'has any value', type: 'array', filteringCriteria: 'in' },
];
const filterOptionsNumber = [
  { id: 1, label: 'is equal to', type: 'number', filteringCriteria: 'eq' },
  { id: 2, label: 'is between', type: 'number', subType: 'range', filteringCriteria: 'gte', filteringCriteria2: 'lte' },
  { id: 3, label: 'is greater than', type: 'number', filteringCriteria: 'gt' },
  { id: 4, label: 'is less than', type: 'number', filteringCriteria: 'lt' },
];
const filterOptionsBoolean = [
  { id: 1, label: 'is true', type: 'boolean' },
  { id: 2, label: 'is false', type: 'boolean' },
];
const filteringCriteriaDateRange = ['today', 'yesterday', 'thisWeek'];
const filterOptionsDateRange = [
  { id: 1, label: 'updated today', type: 'boolean', filteringCriteria: filteringCriteriaDateRange[0] },
  { id: 2, label: 'updated since yesterday', type: 'boolean', filteringCriteria: filteringCriteriaDateRange[1] },
  { id: 3, label: 'updated this week', type: 'boolean', filteringCriteria: filteringCriteriaDateRange[2] },
];
const filterOptionCalendar = [{ id: 1, type: 'calendar', label: 'created at' }];
// filterOptions for dynamicCheckboxes will be taken from parent eg classes / employee page

interface IProps {
  filterItems: any[];
  handleFilters: (filters: any) => void;
  setFilterHeight: (height: number) => void;
  searchValue: string;
  academicYear: string;
  admission?: boolean;
  additionalHeight?: number;
}

const Filter = ({ filterItems, handleFilters, setFilterHeight, searchValue, academicYear, admission = false, additionalHeight = 0 }: IProps) => {
  const [filters, setFilters] = useState<any[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filterOptions, setFilterOptions] = useState<any[]>(filterOptionsString);
  const [filterOption, setFilterOption] = useState('');
  const [filterLabel, setFilterLabel] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [filterValue2, setFilterValue2] = useState('');
  const [filterAcceptedValues, setFilterAcceptedValues] = useState<string[]>([]);
  const [isCalendarFilter, setIsCalendarFilter] = useState(false);
  const [isChecked, setIsChecked] = useState(filterAcceptedValues.map((i) => false));
  const [selectedAcceptedValues, setSelectedAcceptedValues] = useState<any[]>([]);
  const [currentlySelectedValues, setCurrentlySelectedValues] = useState<any[]>([]);
  const [showDynamicCheckboxes, setShowDynamicCheckboxes] = useState(false);
  const [indexForHeight, setIndexForHeight] = useState<number>(0);

  let calendarDate = [];

  let { rem }: any = useTheme();
  const { theme } = useThemeSystem();

  useEffect(() => {
    clearFilters();
  }, [searchValue]);

  useEffect(() => {
    let arr = [];
    filterAcceptedValues.forEach(element => {
      arr.push({ [element]: false })
    })
    setSelectedAcceptedValues(arr)
  }, [filterAcceptedValues]);

  const isCheckboxChecked = (index: number, checked: boolean) => {
    setIsChecked((isChecked) => {
      return isChecked.map((c, i) => {
        if (i === index) return checked;
        return c;
      });
    });
  };

  // to set filter values (array) from checkboxes
  useEffect(() => {
    setFilterValue(currentlySelectedValues)
  }, [currentlySelectedValues]);

  const handleCheckedValues = (item: string, clickedTargetValue: boolean) => {
    // to maintain an array with values currently checked or true
    if (clickedTargetValue) {
      setCurrentlySelectedValues([
        ...currentlySelectedValues,
        item
      ]);
    } else {
      var array = [...currentlySelectedValues];
      // make a temporary separate copy of the array
      var index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1);
        setCurrentlySelectedValues(array);
      }
    }
  }

  const removeFilter = (index: number) => {
    let filtersCopy = [...filters];
    filtersCopy.splice(index, 1);
    setFilters(filtersCopy);
    createFilterObj(filtersCopy);
  };

  const setFilterOptionsHelper = (type: string) => {
    //   to set what kind of filter options to show
    if (type === 'string') {
      setIsCalendarFilter(false);
      setFilterOptions(filterOptionsString);
    } else if (type === 'number') {
      setIsCalendarFilter(false);
      setFilterOptions(filterOptionsNumber);
    } else if (type === 'boolean') {
      setIsCalendarFilter(false);
      setFilterOptions(filterOptionsBoolean);
    } else if (type === 'dateRange') {
      setIsCalendarFilter(false);
      setFilterOptions(filterOptionsDateRange);
    } else if (type === 'calendar') {
      setIsCalendarFilter(true);
      setFilterOptions(filterOptionCalendar);
    }
    setShowFilterOptions(true);
  };

  const addFilter = () => {
    if (
      ((Array.isArray(filterValue) || typeof filterValue === "string") &&
        filterValue?.length > 0) ||
      activeItem?.type === "dateRange"
    ) {
      let calendarValue = null;
      if (activeItem.type === 'calendar') {
        calendarValue = calendarDate;
      }
      const filtersCopy = [...filters];
      filtersCopy.push({
        id: `${filterValue}_${filterOption}`,
        label: `${activeItem?.label} ${filterLabel} ${filterValue} ${filterValue2 ? `and ${filterValue2}` : ''
          }`,
        regex1: calendarValue || filterValue,
        localField: activeItem?.localField,
        lookupKey: activeItem?.lookupKey,
        filterType: activeItem?.filterType,
        type: activeItem?.type,
        regex2: filterValue2,
        filterCriteria: filterOption,
        propName: activeItem?.propName || '',
      });
      setFilters(filtersCopy);
      setFilterValue('');
      setFilterValue2('');
      setActiveItem({});
      setFilterOption('');
      setFilterLabel('');
      setShowFilterOptions(false);
      setShowFilter(false);
      calendarDate = [];
      createFilterObj(filtersCopy);
      setFilterAcceptedValues([]);
    }
  };

  const clearFilters = () => {
    setFilters([]);
  };

  function getFormattedDates(filterCriteria: string) {
    let startDate, endDate;
    const today = new Date();
    const currentDate = format(today, DT.DATE_FORMAT_SLASH);

    switch (filterCriteria) {
      case "today":
        startDate = currentDate;
        endDate = currentDate;
        break;
      case "yesterday":
        startDate = format(subDays(today, 1), DT.DATE_FORMAT_SLASH);
        endDate = currentDate;
        break;
      case "thisWeek":
        startDate = format(startOfISOWeek(today), DT.DATE_FORMAT_SLASH);
        endDate = currentDate;
        break;
    }

    return { startDate, endDate };
  }

  // Default / Single query Relation will be AND
  const createFilterObj = (filterArray: any[]) => {

    // different values for same field will always have OR
    // same fields will be clubbed together (nested inside) in an OR
    // Each parent OR field will operate with an AND between each other
    let filterObj: any = {
      filters: { and: [] },
      lookup: {},
    };

    filterArray.forEach((item: any) => {
      //   to set filter method` for query
      let filterCriteria = item.filterCriteria;
      let filterCriteria2 = filterCriteria == 'gte' ? 'lte' : '';

      //   For filter Object
      if (item.filterType === 'filter') {
        if (item.type === 'calendar') {
          // logic to create filter obj for date type filter
          // date values will be available in item.regex1
        } else if (filteringCriteriaDateRange.includes(item?.filterCriteria)) {
          const { startDate, endDate } = getFormattedDates(filterCriteria);
          let isFound: boolean = false;

          filterObj["filters"]["and"].map((element) => {
            element["or"].map((e) => {
              if (e.hasOwnProperty(item.localField)) {
                element["or"].push({
                  [item.localField]: {
                    gte: startDate,
                    lte: endDate,
                  },
                });
                isFound = true;
              }
            });
          });

          !isFound &&
            filterObj["filters"]["and"].push({
              or: [
                {
                  [item.localField]: {
                    gte: startDate,
                    lte: endDate,
                  },
                },
              ],
            });
        } else {
          // if there is only one filter criteria. i.e no between fields (gte/lte)
          if (!filterCriteria2) {
            let isFound: boolean = false;

            // To add filter nested inside the existing OR if found in current query
            filterObj['filters']['and'].map((element) => {
              element["or"].map((e) => {
                if (e.hasOwnProperty(item.localField)) {
                  element["or"].push(
                    {
                      [item.localField]: {
                        [filterCriteria]: item.type === 'number' ? parseInt(item.regex1) : item.regex1,
                      }
                    }
                  );
                  isFound = true;
                }
              });
            })

            // For addition of initial filter or if filter isn't already existing
            !isFound && filterObj['filters']['and'].push({
              "or": [
                {
                  [item.localField]: {
                    [filterCriteria]: item.type === 'number' ? parseInt(item.regex1) : item.regex1,
                  }
                },
              ],
            });
          }
          else {
            // if filterCriteria2 exists eg. number range between
            let isFound: boolean = false;

            // To add filter nested inside the existing OR if found in current query
            filterObj['filters']['and'].map((element) => {
              element["or"].map((e) => {
                if (e.hasOwnProperty(item.localField)) {
                  element["or"].push(
                    {
                      [item.localField]: {
                        [filterCriteria]: parseInt(item.regex1),
                        [filterCriteria2]: parseInt(item.regex2)
                      }
                    }
                  );
                  isFound = true;
                }
              });
            })

            // For addition of initial filter or if filter isn't already existing
            !isFound && filterObj['filters']['and'].push({
              "or": [
                {
                  [item.localField]: {
                    [filterCriteria]: parseInt(item.regex1),
                    [filterCriteria2]: parseInt(item.regex2)
                  }
                },
              ],
            });
          }
        }
      }
      // For Lookups
      else if (item.filterType === 'lookup') {
        // if lookupKey is passed from parent, it will take precedence
        // this is to fix certain fields having a differing key and localfield names

        let found: boolean = false;
        let currentKey = item?.lookupKey || item?.localField

        if ((Object.keys(filterObj?.lookup || {}).length > 0) && (filterObj.lookup.hasOwnProperty(currentKey))) {
          filterObj['lookup'][currentKey]['filter']['or'].push({
            [item.propName]: {
              [filterCriteria]: item.regex1,
            },
          })

          found = true;
        }

        if (!found) {
          filterObj['lookup'][item?.lookupKey || item?.localField] = {
            filter: {
              or: [
                {
                  [item.propName]: {
                    [filterCriteria]: item.regex1,
                  },
                },
              ],
            },
            localField: item.localField,
          };
        }
      }
    });
    if (Object.keys(filterObj['lookup']).length === 0) {
      // delete lookup obj if no lookup filter is added
      delete filterObj.lookup;
    }
    if (filterObj['filters']['and'].length === 0) {
      // delete filter obj if no filter is added in and
      delete filterObj.filters.and;
    }
    if (Object.keys(filterObj?.lookup || {}).length > 0) {
      /*
      OR in lookups (filterObj['lookup'].or) will be true if:
        - there is no existing filter 
          &&
        - there is only one lookup field

      If filter fields exists or lookups have multiple fields in them,
        then we will have OR set to false inside lookups
      */
      filterObj['lookup'].or = !(Object.keys(filterObj['filters']).length) && (Object.keys(filterObj['lookup']).length == 1)
    }
    // To apply filters only for batches in selected academicYear
    if (academicYear) {
      filterObj['filters'].academicYear = {
        ["eq"]: academicYear
      }
    }
    handleFilters(filterObj);
  };

  const renderFilterInputs = (activeOption: any, optionItem: any) => {
    switch (optionItem?.type) {
      case 'string':
        return (
          <>
            {!filterAcceptedValues?.length ?
              <View style={{ marginLeft: 25 }}>
                <input
                  type={optionItem?.type}
                  //   width={'90%'}
                  onChange={(e) => setFilterValue(e.target.value)}
                  autoFocus
                  style={{
                    // lineHeight: '23px',
                    height: '27px',
                    width: '90%',
                    border: `1px solid ${colors.tableDropdwonBorder}`,
                  }}
                />
              </View> :
              null}
          </>
        );

      case 'array':
        return (
          <>
            {!filterAcceptedValues?.length ?
              <View style={{ marginLeft: 25 }}>
                <ArrayInput setFilterValue={setFilterValue} />
                <TextHintWrapper>
                  <SmallTextAtom value="Press 'Enter (↵)' to add filter" />
                </TextHintWrapper>
              </View> :
              null}
          </>
        );

      case 'number':
        if (optionItem?.subType === 'range') {
          return (
            <>
              {!filterAcceptedValues?.length ?
                <View
                  style={{
                    marginLeft: 25,
                    display: 'flex',
                    flexDirection: 'row',
                    width: 170,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <input
                    type={optionItem?.type}
                    //   width={'90%'}
                    onChange={(e) => setFilterValue(e.target.value)}
                    autoFocus
                    style={{
                      // lineHeight: '23px',
                      height: '27px',
                      width: '40%',
                      border: `1px solid ${colors.tableDropdwonBorder}`,
                    }}
                  />
                  <SmallTextAtom value={'and'} color={colors.tertiaryText} />
                  <input
                    type={optionItem?.type}
                    //   width={'90%'}
                    onChange={(e) => setFilterValue2(e.target.value)}
                    style={{
                      // lineHeight: '23px',
                      height: '27px',
                      width: '40%',
                      border: `1px solid ${colors.tableDropdwonBorder}`,
                    }}
                  />
                </View> :
                null}
            </>
          );
        } else {
          return (
            <>
              {!filterAcceptedValues?.length ?
                <View style={{ marginLeft: 25 }}>
                  <input
                    type={optionItem?.type}
                    //   width={'90%'}
                    onChange={(e) => setFilterValue(e.target.value)}
                    autoFocus
                    style={{
                      // lineHeight: '23px',
                      height: '27px',
                      width: '90%',
                      border: `1px solid ${colors.tableDropdwonBorder}`,
                    }}
                  />
                </View> :
                null}
            </>
          );
        }
      default:
        return null;
    }
  };

  return (
    <Container>
      {filters.map((chip, index) => (
        <ChipView key={`filter${index}`}>
          <Chip
            label={chip?.label}
            onPress={() => removeFilter(index)}
            borderRadius={4}
            chipFontSize={1.2}
          />
        </ChipView>
      ))}

      <ClickAwayListener
        onClickAway={() => {
          setShowFilter(false);
          setShowFilterOptions(false);
          setActiveItem({});
        }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <TouchableOpacity
            onPress={() => {
              setShowFilter(!showFilter);
              if (showFilterOptions) setShowFilterOptions(false);
              setActiveItem({});
            }}
            activeOpacity={1}
            ref={(el) => {
              // to detect the position of add button and adjust height - for multiple rows of chips
              // y axis --> 121, 161, 201, 241
              if (!el) return;
              const clientRectY = additionalHeight ? el.getBoundingClientRect().y - additionalHeight : el.getBoundingClientRect().y;
              if (clientRectY > 120 && clientRectY < 150) {
                setFilterHeight(1.25);
              } else if (clientRectY > 150 && clientRectY < 180) {
                setFilterHeight(2.25);
              } else if (clientRectY > 180 && clientRectY < 210) {
                setFilterHeight(3.5);
              } else if (clientRectY > 210) {
                setFilterHeight(4.5);
              }
            }}>
            <OutlineButtonWeb
              text="Add Filter"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 0,
                height: '32px',
                minWidth: 118,
                maxWidth: 118,
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}
              //   cb={() => setShowFilter(true)}
              icon={
                <FontAwesomeIcon
                  icon={faAngleDown}
                  color={colors.primaryColor}
                  size={15}
                  style={{ marginLeft: 10 }}
                />
              }
            />

            <List
              hidden={!showFilter}
              sx={{
                minWidth: '172px',
                maxWidth: '200px',
                background: colors.white,
                zIndex: 1500,
                boxShadow: `0px 0px 5px ${colors.searchShadow}`,
                borderRadius: '2px',
              }}
              //   component="nav"
              aria-label="secondary mailbox folders">
              {filterItems.map((item, index) => (
                <ListItem
                  onMouseEnter={() => {
                    setActiveItem({
                      label: item.label,
                      index,
                      localField: item.localField,
                      lookupKey: item.lookupKey,
                      filterType: item.filterType,
                      type: item.type,
                      propName: item?.propName || '',
                    });
                    // if item (passed from parent) has an array of dynamicCheckbox values,
                    // we replace filterOptions with them
                    if (item.hasOwnProperty('dynamicCheckboxes')) {
                      setShowDynamicCheckboxes(true)
                      setIsCalendarFilter(false);
                      setFilterOptions(item.filterOptions);
                      setShowFilterOptions(true);
                      setFilterAcceptedValues(item.acceptableCheckboxValues)
                    } else {
                      // if no dynamicCheckboxes, we proceed as usual
                      setShowDynamicCheckboxes(false)
                      setFilterOptionsHelper(item.type);
                      setFilterAcceptedValues([])
                    }
                    // Clear out values for checkbox and radio button on mouse exits
                    setCurrentlySelectedValues([]);
                    setFilterOption('');
                    setIndexForHeight(index)
                  }}
                  style={{
                    height: 32,
                    backgroundColor:
                      index === activeItem?.index ? colors.primaryColor : colors.white,
                    color: index === activeItem?.index ? colors.white : colors.primaryText,
                  }}
                  color={colors.primaryColor}
                  selected={index === activeItem?.index}>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </TouchableOpacity>
          <List
            hidden={!showFilterOptions}
            sx={{
              minWidth: isCalendarFilter ? '280px' : '260px',
              left: 0,
              zIndex: 1501,
              boxShadow: `0px 0px 5px ${colors.searchShadow}`,
              background: colors.white,
              borderRadius: '2px',
              minHeight: isCalendarFilter || showDynamicCheckboxes ? '300px' : '192px',
              top: `calc(40px + ${indexForHeight}*32px)`,
            }}
            //   component="nav"
            aria-label="secondary mailbox folders">
            {filterOptions[0]?.type !== 'calendar' ? (
              <>
                {filterOptions.map(item => (
                  <>
                    <ListItem style={{ height: 32 }}>
                      <ListItemIcon style={{ minWidth: 38 }}>
                        <Radio
                          onChange={() => {
                            setFilterOption(item.filteringCriteria);
                            setFilterLabel(item.label);
                          }}
                          checked={filterOption === item.filteringCriteria}
                          size={'small'}
                          color={'primary'} />
                      </ListItemIcon>
                      <ListItemText primary={item.label} />
                    </ListItem>

                    {filterOption === item.filteringCriteria && renderFilterInputs(activeItem, item)}
                  </>
                ))}
                {
                  <>
                    {/* conditionally render checkboxes below radio buttons if passed from parent. Default off */}
                    {showDynamicCheckboxes ?
                      <>
                        <ListItem
                          style={{ height: 32, maxWidth: 240, flexWrap: 'wrap' }}
                          dense={true}>
                          <ListItemIcon style={styles(admission).listItemIcon}>
                            {filterAcceptedValues.map((item, index) => {
                              return (
                                <View>
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        name={item}
                                        value={filterAcceptedValues[index]}
                                        id={item}
                                        size='small'
                                        color='primary'
                                        disabled={!filterOption} // disables checkboxes until radio option is selected
                                        checked={isChecked[index]}
                                        onChange={(e) => {
                                          // to handle MUI checkbox
                                          isCheckboxChecked(index, e.target.checked)
                                          // to maintain state of currently true values to pass in gql query
                                          handleCheckedValues(item, e.target.checked)
                                        }}
                                      />
                                    }
                                    label={clipLongText(filterAcceptedValues[index], 20)}
                                  />
                                </View>
                              )
                            })}
                          </ListItemIcon>

                          {/* for label of end checkbox button button */}
                          {/* <ListItemText primary={item.label} /> */}
                        </ListItem>
                        {/* {filterOption === item.filteringCriteria && renderFilterInputs(activeItem, item)} */}
                      </>
                      :
                      null}
                  </>
                }
              </>
            ) : (
              <></> // Handling calendars using Date Range Strings like "Today", "Yesterday", "This Week etc" (filterOptionsDateRange) in current implementation.
            )}

            <ListItem
              style={{
                height: 32,
                marginLeft: isCalendarFilter ? -85 : -35,
                top: admission ? 'auto' : isCalendarFilter ? 270 : (showDynamicCheckboxes ? 130 : ''),
                paddingTop: isCalendarFilter ? '' : 30,
                paddingBottom: isCalendarFilter ? '' : 20,
                position: admission ? 'absolute' : 'relative',
                bottom: admission ? 0 : 'auto',
              }}>
              <OutlineButtonWeb
                text="Cancel"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 22,
                  maxWidth: 50,
                  fontSize: 12,
                  marginLeft: 50
                }}
                cb={() => {
                  setShowFilter(false);
                  setShowFilterOptions(false);
                  setActiveItem({});
                }}
              />
              <ApplyBtnContainer>
                <SmallButton label="Apply Filter" onPress={addFilter} width={80} />
              </ApplyBtnContainer>
            </ListItem>
          </List>
        </View>
      </ClickAwayListener>
    </Container >
  );
};

export default Filter;

const styles = (admission: boolean) => ({
  listItemIcon: {
    minWidth: 38,
    paddingLeft: 10,
    flexWrap: 'wrap',
    maxWidth: 230,
    maxHeight: admission ? 200 : 'auto',
    overflowX: admission ? 'hidden' : 'visible'
  }
});

const Container = styled.View`
  display: flex;
  flex-direction: row;
  position: absolute;
  flex-wrap: wrap;
`;

const ChipView = styled.View`
  margin-right: 8;
  margin-bottom: 8;
`;

const ApplyBtnContainer = styled.View`
  padding-left: 15px; 
`;

const TextHintWrapper = styled.View`
  padding-top: 8px; 
`;
