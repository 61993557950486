import React from 'react'
import styled from 'styled-components/native'
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';

interface LabelValueViewProps {
  label: string;
  value?: string;
  width?: string | number;
  marginBottom?: string;
  marginTop?: string;
}

export default function LabelValueView(props: LabelValueViewProps) {
  const {
    label,
    value = '-',
    width,
    marginTop,
    marginBottom
  } = props;
  const { t } = useI18n();
  return (
    <>
      {label &&
        <Container width={width} marginTop={marginTop} marginBottom={marginBottom}>
          <LabelView>{t(label)}</LabelView>
          <TextView>{value || '-'}</TextView>
        </Container>
      }
    </>
  )
}

type ContainerProps = {
  width?: string | number,
  marginBottom?: string,
  marginTop?: string
}

const Container = styled.View<ContainerProps>`
  width: ${({ width }) => width || '33.3333%'};
  margin-top: ${({ marginTop }) => marginTop || '0px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '35px'};
`;

const LabelView = styled.Text`
  font-size: 15px;
  line-height: 20px;
  font-family: ${fonts.regular};
  color: ${colors.secondaryText};
  padding-bottom: 3px;
`;

const TextView = styled.Text`
  font-size: 15px;
  line-height: 20px;
  font-family: ${fonts.semibold};
  color: ${colors.primaryText};
`;