import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';

import HeaderSix from 'src/components/atoms/Text/HeaderSix';
import React from 'react';
import { colors, fontWeight } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import { isWeb } from 'src/constant/device';

export const HeaderTitle = (props: UseFieldApiConfig) => {
  const { label, fontWeight: font_weight = 400 } = useFieldApi(props);
  const { t } = useI18n();
  return isWeb ? (
    <Wrapper>
      <HeaderSix
        value={t(label)}
        color={colors.primaryText}
        fontWeight={fontWeight[font_weight]}
      />
    </Wrapper>
  ) : (
    <></>
  )
};

const Wrapper = styled.View`
  margin-bottom: 10px;
`;