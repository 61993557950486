import validatorTypes from "@data-driven-forms/react-form-renderer/validator-types";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { UnAssignedStudent } from "src/components/organism/Enrollment/Promotions/types";

export const assignRollNo = (
  subjectGroupOptions: Array<string>,
  tableData: UnAssignedStudent[],
  setStudentRoll: Dispatch<SetStateAction<any>>,
  checkError: MutableRefObject<boolean>,
  loadStudents: (subjectGroupId: string) => void,
  isDataAvailable: boolean,
) => ({
  name: "assignDivision",
  label: "assignClassDivision.label",
  viewMode: 2,
  fields: [
    {
      name: 'subjectGroup',
      label: 'subjectGroup.required.label',
      component: 'custom-select',
      width: 350,
      options: subjectGroupOptions,
      isRequired: true,
      handleChange: (formOptions: any, newValue: { value: string }) => {
        loadStudents(newValue?.value);
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.subjectGroup.required',
        },
      ],
    },
    {
      name: "students",
      headCells: [
        {
          id: "studentName",
          label: "studentName.label",
          style: { width: "50%" },
        },
        {
          id: "gender",
          label: "gender.label",
          style: { width: "20%" },
        },
        {
          id: "rollNo",
          label: "rollno.label",
          style: { width: "30%", maxWidth: "200px" },
        },
      ],
      height: 495,
      maxHeight: 495,
      component: "assign-rollno-table",
      tableData: tableData,
      setData: setStudentRoll,
      checkError: checkError,
      subscribeToValues: true,
      isDataAvailable: isDataAvailable,
    },
  ],
});
