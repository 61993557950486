import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';


const getNotifications = gql`
  query ($limit: Int, $skip: Int) {
    getNotifications(limit: $limit, skip: $skip) {
      hasNextPage
      totalCount
      data {
        id,
        title,
        body,
        type,
        status,
        updatedAt,
        referenceId,
        date
        audienceType
        createdBy {
          id
          fullName
        }
      }
    }
  }
`;

export const getUnreadNotificationCount = gql`
  query {
    getUnreadNotificationCount
  }
`;

const updateLastSeenNotification = gql`
  mutation {
    updateLastNotificationSeen {
      user,
      lastNotificationSeen
    }
  }
`;

export { getNotifications, updateLastSeenNotification };

export function useNotification() {
  const [getNotificationData, { loading, data: notifications, error: classesError }] = useLazyQuery(getNotifications);
  return { getNotificationData, notifications, classesError, loading };
}

export function useUnReadNotificationCount() {
  const {data: unReadCount, refetch: refetchUnreadCount, loading, error: classesError } = useQuery(getUnreadNotificationCount);
  return { unReadCount, refetchUnreadCount, loading, classesError };
}

export function updateLastNotificationSeen() {
  const [lastNotificationSeen, { data: lastSeen }] = useMutation(updateLastSeenNotification);
  return { lastNotificationSeen, lastSeen };
}