import jwt_decode from 'jwt-decode';
import { userInformation, authToken, fcmTokenInitialized } from 'src/utils/manageState';
import {
  addOrUpdateTokenListInStorage,
  getFullListOfTokens,
  getRefreshTokenFromStorage,
  getStorageFunction,
  getTokenFromStorage,
  setRefreshTokenToStorage,
  setStorageFunction,
  setTokenToStorage,
} from 'src/components/services/storage-service';
import navigationStrings from 'src/navigation/navigationStrings';
import { KEYS } from 'src/constant/key';
import config from '../config/config.json';
import { GetRequestFunction } from 'src/api/ApiHelper';
import { isIOS, isWeb } from 'src/constant/device';
import { graphqlMutation } from './util';
import { refreshAccessToken } from './login/index';
import { getProfileSteps } from 'src/form-json/user-details';
import { getApplicationPath } from 'src/utils/utility';
import { USER_TYPE } from 'src/constant';

/**
 * @decodeToken
 * @param token
 */
export const decodeToken = (token: string) => {
  return jwt_decode(token);
};

type isAuthUserType = {
  history: any;
  isNativeRoute?: boolean;
  props?: any;
};

export const isAuthenticated = async () => {
  const TOKEN = await getTokenFromStorage();
  if (TOKEN) {
    const decoded: any = decodeToken(TOKEN);
    authToken({ edbaToken: TOKEN });
    const userInformationObject = {
      email: decoded.email,
      userName: decoded.userName,
      userType: decoded.userType,
      userDetail: decoded.userDetails,
      userId: decoded.userId,
      language: decoded.language,
    } as any;
    userInformation(userInformationObject);
    return true;
  } else {
    console.log('No Access Token available');
    return false;
  }
};

export const isAuthUser = async ({ history, isNativeRoute = false, props }: isAuthUserType) => {
  const TOKEN = await getTokenFromStorage();

  if (TOKEN) {
    const loginEmployeeInformation: any = decodeToken(TOKEN);
    authToken({ edbaToken: TOKEN });
    if (isNativeRoute) {
      await setAuthUserData({
        data: loginEmployeeInformation,
        history: history,
        isNative: isNativeRoute,
      });
    } else {
      const location = props.location.pathname;
      await setAuthUserData({
        data: loginEmployeeInformation,
        history: history,
        location: location,
      });
    }
  } else {
    return false;
  }
};

type setAuthUserDataProps = {
  data: any;
  history: any;
  isNative?: boolean;
  type?: string;
  location?: any;
  prefix?: string;
  requestId?: string;
  capId?: string;
  inviteCode?: string;
};

export const setAuthUserData = async ({
  data,
  history,
  location,
  type = 'other',
  isNative,
  prefix,
  requestId,
  capId,
  inviteCode,
}: setAuthUserDataProps) => {
  const profileSteps = getProfileSteps(data.userType)
  const userInformationObject = {
    email: data.email,
    userName: data.userName,
    userType: data.userType,
    userDetail: data.userDetails,
    userId: data.userId,
    language: data.language,
  } as any;

  userInformation(userInformationObject);

  if (data.userType === 1) {
    if (type == 'login') {
      history.push('/dashboard');
    } else {
      history.push(location);
    }
    return true;
  }
  if (typeof userInformationObject.userDetail.profileCompletion === 'boolean') {
    if (isNative) {
      history.reset({
        index: 0,
        routes: [{ name: navigationStrings.NATIVE_DASHBOARD }],
      });
    } else {
      if (type === 'login') {
        let path = '/dashboard'
        history.push(
          data.userType === USER_TYPE.APPLICANT
            ? getApplicationPath(path, prefix, requestId, capId, inviteCode)
            : path,
        );
      } else {
        history.push(location);
      }
    }
    return true;
  } else {
    const profileCompletion = userInformationObject?.userDetail?.profileCompletion;
    if (!profileCompletion) {
      if (type == 'login') {
        let path = `/onboarding/${0}`;
        history.push(
          data.userType === USER_TYPE.APPLICANT
          ? getApplicationPath(path, prefix, requestId, capId, inviteCode)
          : path,
        );
      } else {
        history.push(location);
      }
    } else {
      let incompleteStep = profileSteps.findIndex((stepName) => !profileCompletion[stepName]);
      if (incompleteStep != -1) {
        if (type == 'login') {
          let path = `/onboarding/${incompleteStep}`;
          history.push(
            data.userType === USER_TYPE.APPLICANT
            ? getApplicationPath(path, prefix, requestId, capId, inviteCode)
            : path,
          );
        } else {
          if (isNative) {
            history.reset({
              index: 0,
              routes: [
                { name: navigationStrings.NATIVE_ON_BOARDING, params: { step: incompleteStep } },
              ],
            });
          } else {
            history.push(location);
          }
        }
      }
    }
  }
};

export async function registerToken({ fcm, access }) {
  if (fcm) {
    await setStorageFunction(KEYS.FCM_TOKEN, fcm);
  }
  if (!fcm) {
    fcm = await getStorageFunction(KEYS.FCM_TOKEN);
  }
  if (fcm) {
    if (!access) {
      access = await getStorageFunction(KEYS.ACCESS_TOKEN);
    }
    const headers = { authorization: `Bearer ${access}` };
    const baseUrl = config.Server.url.replace('/graphql', '');
    const res = await GetRequestFunction(
      `${baseUrl}/fcm/registertoken/${isWeb ? 'web' : isIOS ? 'ios' : 'app'}/${fcm}`,
      headers,
    );
    console.log('FCM Token Registered X', res);
    fcmTokenInitialized(true);
    return res;
  } else {
    return false;
  }
}

export async function registerTokens() {
 const access_tokens = await getFullListOfTokens()
 const fcm = await getStorageFunction(KEYS.FCM_TOKEN);
 if (access_tokens.length > 0 && fcm) {
   await Promise.all(access_tokens.map((user: any) => {
     return registerToken({ fcm, access: user.token })
   }));
 } else {
   return false;
 }
}

export const ensureFCMTokenPersisted = async (token: string) => {
  const existingToken = await getStorageFunction(KEYS.FCM_TOKEN);
  if (!existingToken) {
    await setStorageFunction(KEYS.FCM_TOKEN, token);
  }
  if (existingToken !== token) {
    registerToken({ fcm: token, access: null });
  }
};

export const checkTokenExpiration = async (TOKEN: any) => {
  const decodedToken: any = await decodeToken(TOKEN);
  if (decodedToken.exp * 1000 < Date.now()) {
    const refreshToken = await getRefreshTokenFromStorage();
    if (refreshToken) {
      const accessToken = await graphqlMutation(refreshAccessToken, { refresh: refreshToken });
      if (accessToken) {
        const token = accessToken.data.refreshAccessToken.token;
        const refreshToken = accessToken.data.refreshAccessToken.refresh;
        authToken({ edbaToken: token });
        await setTokenToStorage(token);
        await addOrUpdateTokenListInStorage(
          token,
          refreshToken
        )
        await setRefreshTokenToStorage(refreshToken);
      }
    }
  }
}
