import React from 'react'
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import SaveAndNextButtonGroup from 'src/components/molecules/SaveAndNextButtonGroup/index.web'
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import FormViewerMapper from './DDFFormViewerMapper';
import styled from 'styled-components';
import { colors } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { RootFormViewerProp, TemplateType, ViewMode, FormHandleSubmit } from './types';
import { useHistory } from 'src/routes/routing.web';
import AlertModalNative from '../AlertModal/AlertModalNative';
import { userInformation } from 'src/utils/manageState';
import { EMPLOYEE_TYPES, USER_TYPE, UserType } from 'src/constant';

const RootFormViewer: React.FC<RootFormViewerProp> = (props: RootFormViewerProp) => {
  const {
    viewMode,
    setViewMode,
    step,
    setStep,
    isLastStep,
    isSubmitting,
    formInitialValues,
    setFormInitialValues,
    handleDelete,
    goToNextStep,
    isOnboarding,
    schema,
    templateType,
    userId,
    userType,
    formBaseType,
    applicationRef,
    reviewMode,
    inModalView,
    onCancel,
    refreshList,
    selectedRecords,
    setSelectedRecords,
    handleAcceptChangesModal,
    handleRejectChangesModal,
    submitForReviewModal,
    handleSubmitForReviewModal,
    submitForReviewFn,
    enableSubmitForReviewBtn,
    showApproveRejectBtn,
    profileNotActive,
    hideAddAndDeleteBtn,
    prefix,
    requestId,
    capId,
    inviteCode,
    viewApplication,
    allowProfileEdit,
  } = props;

  const formOptions = useFormApi();
  const handleSubmit: any = formOptions.handleSubmit;
  const { t } = useI18n();
  const history = useHistory();
  const currentUserObject = userInformation();
  const hideDraftAndSubmit =
    currentUserObject?.userDetail?.type?.includes(
      EMPLOYEE_TYPES.INSTITUTE_ADMIN
    ) || currentUserObject?.userType == USER_TYPE.APPLICANT;

  //get the function returned by handleSubmit
  const saveHandler = async (callback?: any) => {
    let submitHandler: FormHandleSubmit = await handleSubmit();
    if (submitHandler) {
      return submitHandler({ submit: { callback } });
    }
  }

  return (
    <>
      <FormOuter viewMode={viewMode} isOnboarding={isOnboarding} templateType={templateType} inModalView={inModalView}>
        <>
          <FormViewerMapper
            step={step}
            isOnboarding={isOnboarding}
            schema={schema}
            templateType={templateType}
            handleDelete={handleDelete}
            handleSubmit={handleSubmit}
            formInitialValues={formInitialValues}
            setFormInitialValues={setFormInitialValues}
            userId={userId}
            userType={userType}
            reviewMode={reviewMode}
            inModalView={inModalView}
            refreshList={refreshList}
            selectedRecords={selectedRecords}
            setSelectedRecords={setSelectedRecords}
            hideAddAndDeleteBtn={hideAddAndDeleteBtn}
            formBaseType={formBaseType}
            applicationRef={applicationRef}
            viewApplication={viewApplication}
          />
        </>
        <>
          {
            !isOnboarding && viewMode == ViewMode.EDIT && templateType == TemplateType.DEFAULT &&
            <SubmitButtonContainer>
              <BtnWrapper>
                <OutlineButton
                  label={t('cancel.label')}
                  onPress={() => {
                    userId === currentUserObject?.userId
                      ? history.push(`/profile/${step}/view`)
                      : history.push('view');
                  }}
                  width={80}
                />
              </BtnWrapper>
              <BtnWrapper>
                <SecondaryBtn
                  canLoad={isSubmitting}
                  disabled={isSubmitting}
                  label={hideDraftAndSubmit ? t('save.label') : t('save-draft.label')}
                  onPress={saveHandler}
                  width={hideDraftAndSubmit ? 80 : 108}
                />
              </BtnWrapper>
              {
                !hideDraftAndSubmit && (
                  <BtnWrapper>
                    <SecondaryBtn
                      customDisabled={!enableSubmitForReviewBtn}
                      label={t("submit.label")}
                      onPress={() => handleSubmitForReviewModal(true)}
                      width={80}
                    />
                  </BtnWrapper>
                )
              }
            </SubmitButtonContainer>
          }
        </>
      </FormOuter>

      {showApproveRejectBtn &&
        <ButtonContainer>
          <SecondaryBtn
            onPress={() => handleAcceptChangesModal(true)}
            label={t("approve-changes.label")}
            width={160} />
          <SecondaryBtn
            onPress={() => handleRejectChangesModal(true)}
            label={t("reject-changes.label")}
            width={160}
            color={colors.errorColor} />
        </ButtonContainer>
      }

      {isOnboarding && allowProfileEdit && <SaveAndNextButtonGroup
        inModalView={inModalView}
        onCancel={onCancel}
        step={step}
        setStep={setStep}
        isLastStep={isLastStep}
        isSubmitting={isSubmitting}
        isOnboarding={isOnboarding}
        handleSubmit={templateType == TemplateType.LIST ? goToNextStep : saveHandler}
        prefix={prefix}
        requestId={requestId}
        capId={capId}
        inviteCode={inviteCode}
        showSkipLabel={userType !== UserType.APPLICANT}
      />
      }

      <AlertModalNative
        title={t("confirm-submit-for-review.label")}
        message1={t('confirm-submit-for-review.text')}
        onCancel={() => handleSubmitForReviewModal(false)}
        onConfirm={() => submitForReviewFn()}
        showModal={submitForReviewModal}
        confirmButtonText={t('submit.label')}
        showTitleLogo={false}
      />
    </>
  )
}

export default RootFormViewer;

type FormOuterProps = {
  viewMode: ViewMode;
  isOnboarding: boolean;
  inModalView?: boolean;
  templateType: TemplateType;
}

const FormOuter = styled.div<FormOuterProps>`
  display: flex;
  flex-direction: ${(pr) => (pr.isOnboarding && pr.templateType == TemplateType.DEFAULT) ? "row" : "column"};
  box-shadow:${(pr) => (!pr.inModalView && pr.isOnboarding) && `0px 0px 24px ${colors.inputBorder}`};
  border-radius: 16px;
  padding-top: ${(pr) => (!pr.inModalView && pr.isOnboarding) ? 32 : 10}px;
  padding-bottom: ${(pr) => (!pr.inModalView && pr.isOnboarding) ? 40 : 10}px;
  padding-left: ${(pr) => pr.inModalView ? 0 : 24}px;
  padding-right: ${(pr) => pr.inModalView ? 0 : 24}px;
  margin-top: ${(pr) => pr.inModalView ? -16 : 0}px;
  @media all and (max-width: 767px) {
    padding-vertical: ${(pr) => (!pr.inModalView && pr.isOnboarding) ? 15 : 5}px;
    padding-horizontal: 15px;
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 20px 20px;
  max-width: 344px;
`;

const BtnWrapper = styled.div`
  margin-right: 16px;
 `;
