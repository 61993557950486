import React, { Dispatch, SetStateAction } from "react";

import ChipInput from "src/components/atoms/ChipInput.tsx";
import styled from "styled-components";

type Props = {
  institutes: string[];
  onChange: (institutes: string[]) => void;
};

const InstitutesChipArrayInput = (props: Props) => {
  const { institutes, onChange } = props;
  return (
    <Container>
      <ChipInput
        inputId={"inHouseInstitutes"}
        inputHeader={"institute.label"}
        inputWidth={"100%"}
        chipArray={institutes}
        // @ts-ignore
        setChipArray={onChange}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -24px;
  width: 100%;
`;

export default InstitutesChipArrayInput;
