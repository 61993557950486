import { ASSIGNMENT_MODAL_PAGE, USER_TYPE } from "src/constant";
import {
  DashboardEmptyContainer as EmptyContainer,
  DemographicsScrollContainer as ScrollContainer,
} from "src/components/organism/Dashboard/Common/CommonHelpers";
import React, { useState } from "react";

import AssignmentCard from "src/components/molecules/AssignmentCard/Student/Assignment";
import AssignmentModal from "src/components/atoms/Modals/Assignment/index.web";
import { DashboardListing } from "./CommonHelpers";
import { assignmentObjectType } from "../../Assignments/types";
import { faBackpack } from "@fortawesome/pro-light-svg-icons";
import { getAssignmentListQuery } from 'src/graphql/communication/assignments';
import { useI18n } from "src/i18n/hooks";
import { useQuery } from "@apollo/client";
import { userInformation } from "src/utils/manageState";

function DashboardAssignments() {
  const { t } = useI18n();
  const { data, loading } = useQuery(getAssignmentListQuery);
  const currentUserObject = userInformation();
  const [modslState, handleModal] = useState<boolean>(false);
  const [assignmentId, setAssignmentId] = useState<string>();
  const [assignmentPage] = useState(ASSIGNMENT_MODAL_PAGE[0]);

  const ListEmptyComponent = () => (
    <EmptyContainer
      text={t("noNewAssignments.text")}
      icon={faBackpack}
      loading={loading}
    />
  );

  const assignments = data?.getAssignments?.data ?? [];

  function handleAssignment(assignment: assignmentObjectType) {
    setAssignmentId(assignment.id);
    handleModal(true);
  }

  return (
    <DashboardListing
      title={"assignments.label"}
      redirectionURL={"/assignments"}
      allowRedirection={assignments.length > 0}
    >
      <ScrollContainer customHeight={55}>
        {assignments.length > 0 ? (
          assignments.map((assignment: assignmentObjectType) => (
            <AssignmentCard
              key={`assign${assignment?.id}`}
              data={assignment}
              onPress={handleAssignment}
              student={currentUserObject.userType === USER_TYPE.STUDENT}
              isDashboard={true}
              clipText={true}
              isSwipable={false}
            />
          ))
        ) : (
          <ListEmptyComponent />
        )}
      </ScrollContainer>

      <AssignmentModal
        isUrlBase={false}
        assignmentModalPage={assignmentPage}
        assignment_Id={assignmentId}
        setModalVisible={() => handleModal(false)}
        modalVisible={modslState}
        cancelButtonLabel="done.label"
        toggleWork={true}
        Headerpopup={t("assignment.label")}
        setToggleWork={() => { }}
      />
    </DashboardListing>
  );
}

export default DashboardAssignments;
