import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import React from 'react';
import { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import DocumentSelectionTable from '../../Table/DocumentSelectionTable';

export default function DocumentSelectionTbl(props: UseFieldApiConfig) {
  const {
    subscribeToValues,
    headCells,
    tableData,
    maxHeight,
    setData,
    inputProperty,
    inputValProperty,
    inputHeader,
    data,
    isEdit,
    isDataAvailable
  } = props;
  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <DocumentSelectionTable
          header={headCells}
          tabledata={tableData}
          maxHeightTable={maxHeight}
          setData={setData}
          data={data}
          inputProperty={inputProperty}
          inputValProperty={inputValProperty}
          inputHeader={inputHeader}
          isEdit={isEdit}
          isDataAvailable={isDataAvailable}
        />
      )}
    </FormSpy>
  );
}