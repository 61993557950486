import { ActionIcon, IconPropType } from './factory';
import { faCaretDown, faCaretUp, faDownload } from '@fortawesome/pro-solid-svg-icons';
import { faCog, faClipboardCheck, faCopy, faEnvelope, faInfoSquare, faLink, faPencil, faPlus, faTrashAlt, faEllipsisV, faExchange, faCamera, faTimes, faLongArrowRight, faBell, faRegistered, faShareAlt } from '@fortawesome/pro-regular-svg-icons';
import { faFileAlt, faFileExport, faGraduationCap, faMoneyCheckAlt } from '@fortawesome/pro-light-svg-icons';

import React from 'react';
import { colors } from 'src/styles/theme/styles';

export function EditIcon(props: IconPropType) {
  const { tooltipTitle, onPress } = props;
  return (
    <ActionIcon icon={faPencil} onPress={onPress} tooltipTitle={tooltipTitle || ''} />
  );
}

export function DeleteIcon(props: IconPropType) {
  const { tooltipTitle, onPress } = props;
  return (
    <ActionIcon icon={faTrashAlt} onPress={onPress} tooltipTitle={tooltipTitle || ''} />
  );
}

export function CloseIcon(props: IconPropType) {
  const { tooltipTitle, onPress, size = 18 } = props;
  return (
    <ActionIcon icon={faTimes} onPress={onPress} tooltipTitle={tooltipTitle || 'Close'} size={size} />
  );
}

export function InfoSquareIcon(props: IconPropType) {
  const { tooltipTitle, onPress, disabled = false } = props;
  return (
    <ActionIcon
      icon={faInfoSquare}
      onPress={onPress}
      tooltipTitle={tooltipTitle || ''}
      disabled={disabled}
    />
  );
}

export function CollapseCaretIcon(props: IconPropType) {
  const { tooltipTitle, onPress, disabled = false, size, color, disableClick = false } = props;
  return (
    <ActionIcon
      icon={faCaretUp}
      onPress={onPress}
      tooltipTitle={tooltipTitle || 'Collapse'}
      disabled={disabled}
      size={size}
      color={color}
      disableClick={disableClick}
    />
  );
}

export function ExpandCaretIcon(props: IconPropType) {
  const { tooltipTitle, onPress, disabled = false, size, disableClick = false } = props;
  return (
    <ActionIcon
      icon={faCaretDown}
      onPress={onPress}
      tooltipTitle={tooltipTitle || 'Expand'}
      disabled={disabled}
      size={size}
      disableClick={disableClick}
    />
  );
}

export function DownloadFileIcon(props: IconPropType) {
  const { tooltipTitle, onPress = null, color = colors.primaryColor } = props;
  return (
    <ActionIcon icon={faDownload} onPress={onPress} tooltipTitle={tooltipTitle || 'Download'} color={color} />
  );
};

export function ViewFileIcon(props: IconPropType) {
  const { tooltipTitle, onPress = null } = props;
  return (
    <ActionIcon icon={faFileAlt} onPress={onPress} tooltipTitle={tooltipTitle || 'View'} size={18} />
  );
};

export function ExportFileIcon(props: IconPropType) {
  const { tooltipTitle, onPress = null } = props;
  return (
    <ActionIcon icon={faFileExport} onPress={onPress} tooltipTitle={tooltipTitle || 'Export'} size={19} />
  );
};

export function MakePaymentIcon(props: IconPropType) {
  const { tooltipTitle, onPress = null } = props;
  return (
    <ActionIcon icon={faMoneyCheckAlt} onPress={onPress} tooltipTitle={tooltipTitle || 'Make Payment'} size={22} />
  );
};

export function MessageIcon(props: IconPropType) {
  const { tooltipTitle, onPress } = props;
  return (
    <ActionIcon icon={faEnvelope} onPress={onPress} tooltipTitle={tooltipTitle || "Message"} size={24} color={colors.secondaryText} />
  );
};

export function EducationDetailsIcon(props: IconPropType) {
  const { tooltipTitle, onPress } = props;
  return (
    <ActionIcon icon={faGraduationCap} onPress={onPress} tooltipTitle={tooltipTitle || ''} size={24} />
  );
};

export function SettingsIcon(props: IconPropType) {
  const { tooltipTitle, onPress } = props;
  return (
    <ActionIcon icon={faCog} onPress={onPress} tooltipTitle={tooltipTitle || 'Settings'} size={17} />
  );
};

export function AddIcon(props: IconPropType) {
  const { tooltipTitle, onPress, color = colors.primaryText } = props;
  return (
    <ActionIcon icon={faPlus} onPress={onPress} tooltipTitle={tooltipTitle || ''} color={color} />
  );
}

export function LinkIcon(props: IconPropType) {
  const { tooltipTitle, onPress, color = colors.tertiaryText } = props;
  return (
    <ActionIcon icon={faLink} onPress={onPress} tooltipTitle={tooltipTitle || 'Link'} color={color} />
  );
}

export function CopyIcon(props: IconPropType) {
  const { tooltipTitle, onPress, color = colors.tertiaryText } = props;
  return (
    <ActionIcon icon={faCopy} onPress={onPress} tooltipTitle={tooltipTitle || 'Copy'} color={color} />
  );
}

export function CriteriaIcon(props: IconPropType) {
  const { tooltipTitle, onPress } = props;
  return (
    <ActionIcon icon={faClipboardCheck} onPress={onPress} tooltipTitle={tooltipTitle || ''} />
  );
}

export function OverflowMenuIcon(props: IconPropType) {
  const { tooltipTitle, onPress, disabled = false } = props;
  return (
    <ActionIcon
      icon={faEllipsisV}
      onPress={onPress}
      tooltipTitle={tooltipTitle || ''}
      size={24}
      disabled={disabled}
    />
  );
}

export function TransferIcon(props: IconPropType) {
  const { tooltipTitle, onPress } = props;
  return (
    <ActionIcon icon={faExchange} onPress={onPress} tooltipTitle={tooltipTitle || 'Transfer'} />
  );
}

export function CameraIcon(props: IconPropType) {
  const { tooltipTitle, onPress, color = colors.secondaryText, size = 24 } = props;
  return (
    <ActionIcon icon={faCamera} onPress={onPress} size={size} color={color} tooltipTitle={tooltipTitle || ''} />
  );
}

export function ForwardIcon(props: IconPropType) {
  const { tooltipTitle, onPress, color = colors.tertiaryText, size = 28 } = props;
  return (
    <ActionIcon icon={faLongArrowRight} onPress={onPress} size={size} color={color} tooltipTitle={tooltipTitle || ''} />
  );
}

export function NotificationIcon(props: IconPropType) {
  const { tooltipTitle, onPress } = props;
  return (
    <ActionIcon icon={faBell} onPress={onPress} tooltipTitle={tooltipTitle || "Notifications"} size={24} color={colors.secondaryText} />
  );
};

export function RegisterIcon(props: IconPropType) {
  const { tooltipTitle, onPress } = props;
  return (
    <ActionIcon icon={faRegistered} onPress={onPress} tooltipTitle={tooltipTitle || 'Assign Registration'} size={16} />
  );
};

export function ShareIcon(props: IconPropType) {
  const { onPress } = props;
  return (
    <ActionIcon
      icon={faShareAlt}
      onPress={onPress}
      size={24}
      color={colors.primaryColor}
    />
  );
}
