import { TableBody } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Paginations from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import BatchDivisionSelectionForm from 'src/components/molecules/Students/BatchDivisionSelectionForm';
import { TableCells, TableRows } from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { createSortFilter, debounce } from 'src/components/services';
import { AUTOCOMPLETE_MODULE, SEARCH_TIME, limit } from 'src/constant';
import { height } from 'src/constant/device';
import { useFilter } from 'src/contexts/filter-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useSearchStudents } from 'src/graphql/classes';
import { useI18n } from 'src/i18n/hooks';
import { useHistory, useLocation, useParams } from 'src/routes/routing.web';
import { colors } from 'src/styles/theme/styles';
import { properCase } from 'src/utils/utility';
import styled from 'styled-components';

const headerCols = [
  {
    id: 'name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'fullName',
    isSorted: false,
    style: {
      minWidth: 160,
    },
  },
  {
    id: 'id',
    label: 'id.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'email',
    label: 'email.label',
    align: 'left',
    disableSorting: true,
    style: {
      minWidth: 160,
    },
  },
  {
    id: 'mobileNumber',
    label: 'mobileNumber.label',
    align: 'right',
    disableSorting: true,
    style: {
      paddingRight: 40,
      minWidth: 100,
    },
  },
  {
    id: 'course',
    label: 'course.label',
    align: 'left',
    disableSorting: true,
    style: {
      minWidth: 208,
    },
  },
  {
    id: 'class',
    label: 'class.label',
    align: 'left',
    disableSorting: true,
    style: {
      minWidth: 125,
    },
  },
  {
    id: 'batch',
    label: 'batch.label',
    align: 'left',
    disableSorting: true,
    style: {
      minWidth: 160,
    },
  },
  {
    id: 'division',
    label: 'division.label',
    align: 'left',
    disableSorting: true,
    style: {
      paddingRight: 40,
    },
  },
  {
    id: 'status',
    label: 'status.label',
    align: 'left',
    disableSorting: true,
    style: {
      minWidth: 180,
    },
  },
];

export default function Students() {
  const { setHeading } = useHeaderTitle();
  const { t } = useI18n();
  const history = useHistory();
  const { filters, setFilter } = useFilter();
  const location = useLocation();

  let rowLimit = 0;
  let { page = 1, dataLimit = limit, urlBatchId = null, urlDivision = null }: any = useParams();
  rowLimit = parseInt(dataLimit);
  let maxHeight = height - 395;
  let searchValue = '' as string;

  const [headCells, setHeadCells] = useState(headerCols);
  const [selectedBatchId, setSelectedBatchId] = useState<string>('');
  const [selectedDivision, setSelectedDivision] = useState<string>('');
  const [row, setRow] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>();
  const [searching, setSearchingStudent] = useState(searchValue);
  const clearSearch = useRef(true);

  if (filters && filters?.students?.on) {
    searchValue = filters?.students?.search;
  }

  const { searchStudents, searchStudentsData, searchStudentsLoading } = useSearchStudents();
  const searchDataAvailable = Boolean(searchValue && searchValue.length >= 3);
  const paginationHeightOffset = 70;

  useEffect(() => {
    setHeading([{ text: t('students.label'), url: '' }]);
    return () => {
      if (clearSearch.current) setFilter(null);
    };
  }, []);

  useEffect(() => {
    setSearchingStudent(searchValue);
    if (!searchValue) {
      setRow(null);
      setTotalCount(0);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchDataAvailable) {
      handleFetch(urlBatchId, urlDivision);
    }
  }, [urlBatchId, urlDivision, page, headCells, searchValue]);

  useEffect(() => {
    if (searchStudentsData && searchStudentsData?.searchStudents?.data) {
      setRow(searchStudentsData.searchStudents.data);
      setTotalCount(searchStudentsData.searchStudents?.totalCount);
    } else {
      setRow(null);
      setTotalCount(0);
    }
  }, [searchStudentsData]);

  function handleFetch(batchId: string, division: string) {
    searchStudents({
      variables: createQueryParameter(batchId, division),
    });
  }

  const createQueryParameter = (batchId: string, division: string) => {
    const sortCriteria = createSortFilter(headCells);
    let studentFilter = {
      limit: rowLimit,
      skip: (page - 1) * rowLimit,
      sort: sortCriteria,
      search: {
        query: searchValue.trim(),
        ...(batchId ? { batch: batchId } : {}),
        ...(batchId && division ? { division: division } : {}),
      },
    };

    return studentFilter;
  };

  function addToUrl(currentBatchId: string, currentDivision: string) {
    let url = `/students/limit/${dataLimit}/page/${page}`;
    if (currentBatchId) {
      url = currentDivision
        ? `/students/${currentBatchId}/${currentDivision}/limit/${dataLimit}/page/${page}`
        : `/students/${currentBatchId}/limit/${dataLimit}/page/${page}`;
    }
    history.push(url);
  }

  const retrieveValues = (formData: any) => {
    if (searchDataAvailable) {
      if (formData?.batch?.value) {
        setSelectedBatchId(formData.batch.value);
        if (formData?.division?.value) setSelectedDivision(formData.division.value);
      }

      handleFetch(formData?.batch?.value, formData?.division?.value);

      // add batch id to history
      addToUrl(formData?.batch?.value, formData?.division?.value);
    }
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.STUDENTS]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchUsers(student: string) {
    let isClear = student?.length === 0;
    persistSearch(!isClear, student);
  }

  const delayedQuery = useCallback(
    debounce(q => searchUsers(q), SEARCH_TIME),
    [],
  );

  const handleSearch = (student: string) => {
    setSearchingStudent(student);
    delayedQuery(student);
  };

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    page,
    null,
    null,
    false,
    true,
    null,
    paginationHeightOffset,
  );

  return (
    <LoadContentWrapper>
      <SearchWrapper>
        <Search
          id="inviteStudentSearch"
          label={t('searchStudents.label')}
          value={searching}
          handleChange={handleSearch}
          width={324}
          height={36}
          notefooter={'search.note.text'}
        />
      </SearchWrapper>

      <TopRowsWrapper>
        <SelectionWrapper>
          <BatchDivisionSelectionForm
            retrieveValues={retrieveValues}
            dataLimit={dataLimit}
            pagePath={'students'}
            urlBatchId={urlBatchId}
            urlDivision={urlDivision}
            setParentBatchId={setSelectedBatchId}
            setParentDivision={setSelectedDivision}
            isLoading={searchStudentsLoading}
            searchDataAvailable={searchDataAvailable}
          />
        </SelectionWrapper>
      </TopRowsWrapper>

      <TblContainer overflowX={'scroll'}>
        <TblHead setHeadCells={setHeadCells} />
        {!searchStudentsLoading && searchDataAvailable && (
          <>
            {searchDataAvailable &&
              recordsAfterPagingAndSorting() &&
              recordsAfterPagingAndSorting()?.length > 0 && (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: any, i: number) => {
                    return (
                      <TableRows key={`tr${i}`}>
                        <TableCells
                          value={
                            item?.personalDetails?.fullName
                              ? properCase(item.personalDetails.fullName)
                              : 'N/A'
                          }
                          color={colors.primaryColor}
                          clickable={true}
                          onPress={() => {
                            clearSearch.current = false;
                            history.push(`/student/${item?.id}/0/view`, {
                              customHeading: [
                                {
                                  text: t('students.label'),
                                  url: location.pathname,
                                },
                                {
                                  text: properCase(item?.personalDetails?.fullName),
                                },
                              ],
                            });
                          }}
                        />
                        <TableCells value={item?.accountId || 'N/A'} />
                        <TableCells value={item?.personalDetails?.email || 'N/A'} />
                        <TableCells
                          value={item?.personalDetails?.mobileNumber || 'N/A'}
                          align={'right'}
                          style={styles.mobileTableCell}
                        />
                        <TableCells value={item?.academic?.class?.course?.name || 'N/A'} />
                        <TableCells value={item?.academic?.class?.alias || 'N/A'} />
                        <TableCells value={item?.academic?.batch?.name || 'N/A'} />
                        <TableCells
                          value={item?.academic?.division || 'N/A'}
                          clickable={Boolean(item?.academic?.division)}
                          color={
                            item?.academic?.division ? colors.primaryColor : colors.primaryText
                          }
                          style={styles.mobileTableCell}
                          onPress={
                            item?.academic?.division
                              ? () => {
                                  clearSearch.current = false;
                                  history.push(
                                    `/classroom/${item?.academic?.batch?.id}/${item?.academic?.division}/students/limit/${rowLimit}/page/1`,
                                    { from: 'Students' },
                                  );
                                }
                              : null
                          }
                        />
                        <TableCells
                          value={
                            item?.status
                              ? item?.status === 'VERIFICATION_SENT'
                                ? item?.verificationStatus?.expiresAt
                                  ? `Verification expires in ${formatDistanceToNowStrict(
                                      item?.verificationStatus?.expiresAt,
                                    )}`
                                  : 'Verification Expired'
                                : properCase(item.status)
                              : 'N/A'
                          }
                          color={
                            item?.status === 'VERIFICATION_SENT'
                              ? item?.verificationStatus?.expiresAt
                                ? colors.yellow
                                : colors.red
                              : item?.status === 'ACTIVE'
                                ? colors.green
                                : colors.blue
                          }
                        />
                      </TableRows>
                    );
                  })}
                </TableBody>
              )}
            {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length === 0 && (
              <TableRows style={{ height: maxHeight - 50 }}>
                <TableCells colspan={8} align="center" style={{ borderBottom: 'none' }}>
                  {t('no-records-found.text')}
                </TableCells>
              </TableRows>
            )}
          </>
        )}
      </TblContainer>
      
      <Paginations
        pathName={
          selectedBatchId
            ? selectedDivision
              ? `students/${selectedBatchId}/${selectedDivision}`
              : `students/${selectedBatchId}`
            : 'students'
        }
        count={Math.ceil(totalCount / rowLimit)}
        total={totalCount}
        page={page}
        rowLimit={rowLimit}
        hidePagination={totalCount <= rowLimit}
      />
    </LoadContentWrapper>
  );
}

const styles = {
  tableContainer: {
    marginTop: -6,
  },
  mobileTableCell: {
    paddingRight: 40,
  },
};

const TopRowsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  margin-top: 24px;
`;

const SelectionWrapper = styled.div`
  max-width: ${props => props.theme.rem(65)};
`;

const SearchWrapper = styled.div`
  align-items: flex-start;
  max-width: 280px;
`;
