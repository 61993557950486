import React from 'react'

import FlashMessage, { showMessage, hideMessage } from "react-native-flash-message";

interface Props {
    children: React.ReactNode
}
export default (props: Props) => {
    return (
        <>
            {props.children}
            <FlashMessage />
        </>
    )
}

// TODO: use showNativeError and showNativeSuccess instead of showMessage.
// TODO: create one service/context which take of both web and native alerts.
const showNativeError = (message: string, duration?: number) => {
    showMessage({
        message,
        type: 'danger',
        position: 'top',
        icon: 'danger',
        ...(duration ? { duration } : {}),
    });
}

const showNativeSuccess = (message: string) => {
    showMessage({
        message,
        type: 'success',
        icon: 'success',
      });
}

export { showMessage, hideMessage, showNativeError, showNativeSuccess }
