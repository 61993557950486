import React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

const Applicant = () => (
  <Svg height="35" width="34" viewBox="0 0 25 24">
    <Rect
      class="st0"
      fill="#FFFFFF"
      width="25"
      height="24"
    />
    <Path
      class="st1"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#2B78CA"
      d="M3.5,0h18c1,0,2,1,2,2v20c0,1-1,2-2,2h-18c-1,0-2-1-2-2V2C1.5,1,2.5,0,3.5,0z M19.1,14.7H5.6
	c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h13.5c0.4,0,0.7-0.3,0.7-0.7C19.8,15.1,19.5,14.7,19.1,14.7z M19.1,18.6H5.6
	c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h13.5c0.4,0,0.7-0.3,0.7-0.7C19.8,18.9,19.5,18.6,19.1,18.6z M15,5.7
	c0,1.4-1.1,2.5-2.5,2.5S10,7.1,10,5.7c0-1.4,1.1-2.5,2.5-2.5S15,4.3,15,5.7z M13.8,8.7h1c1.2,0,2.2,1,2.2,2.2v0.3
	c0,0.5-0.4,0.8-0.8,0.8H8.9c-0.5,0-0.8-0.4-0.8-0.8V11c0-1.2,1-2.2,2.2-2.2h1C11.6,8.9,12.1,9,12.5,9C13,9,13.4,8.9,13.8,8.7z"
    />
  </Svg>
);

export default Applicant;
