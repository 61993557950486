import AsyncStorage from '@react-native-async-storage/async-storage';
import { UserType } from 'src/constant';
import { isNative, isWeb } from 'src/constant/device';
import ERROR_MSG from 'src/constant/error';
import { KEYS } from 'src/constant/key';
import { apolloClearStore } from 'src/graphql/client';
import { decodeToken } from 'src/graphql/common';
import { allowedModulesState, keyValuesVar, userInformation } from 'src/utils/manageState';
import { AccountsDataType } from '../organism/Login/LoginHelpers';
import { properCase } from 'src/utils/utility';

//set and get email_id and password
export async function getStorageFunction(key: string) {
  try {
    return await AsyncStorage.getItem(key);
  } catch (e) {
    console.log(e);
  }
}

export async function setStorageFunction(key: string, data: any) {
  try {
    await AsyncStorage.setItem(key, data);
  } catch (e) {
    console.log(e);
  }
}

export async function flushStorageFunction(key: string) {
  try {
    await AsyncStorage.removeItem(key);
  } catch (e) {
    console.log(e);
  }
}

export async function flushAll() {
  try {
    const language = await getStorageFunction(KEYS.LANGUAGE);
    const keyValues = await getStorageFunction(KEYS.TRANSLATION_KEY_VALUE);
    const keyValuests = await getStorageFunction(KEYS.TRANSLATION_KEY_VALUE_TS);
    await AsyncStorage.clear();
    await setStorageFunction(KEYS.LANGUAGE, language);
    await setStorageFunction(KEYS.TRANSLATION_KEY_VALUE, keyValues);
    await setStorageFunction(KEYS.TRANSLATION_KEY_VALUE_TS, keyValuests);
  } catch (e) {
    console.log(e);
  }
}

export function clearGarbage() {
  flushStorageFunction(KEYS.ACCOUNTS);
  flushStorageFunction(KEYS.REFRESH_TOKEN);
  flushStorageFunction(KEYS.ACCESS_TOKEN);
  apolloClearStore();
  flushStorageFunction(KEYS.USER_INFO);
  flushStorageFunction(KEYS.USER_INFORMATION);
  flushStorageFunction(KEYS.ASSIGNMENT_TOGGLE);
  flushStorageFunction(KEYS.FCM_TOKEN);
  flushStorageFunction(KEYS.ONBOARDING_VISITED);
  if (isNative) {
    // === Legacy Login === It should be refactored or removed in the future.
    flushStorageFunction(KEYS.ACCESS_TOKEN_LIST);
  }
  keyValuesVar({});
}

export async function getTokenFromStorage() {
  try {
    return await AsyncStorage.getItem(KEYS.ACCESS_TOKEN);
  } catch (e) {
    console.log(e);
  }
}

export async function removeTokenFromStorage() {
  try {
    return await AsyncStorage.removeItem(KEYS.ACCESS_TOKEN);
  } catch (e) {
    console.log(e);
  }
}

export async function setTokenToStorage(token: string) {
  try {
    return await AsyncStorage.setItem(KEYS.ACCESS_TOKEN, token);
  } catch (e) {
    console.log(e);
  }
}

export async function setAccountsToStorage(accountsData: AccountsDataType[]) {
  try {
    return await AsyncStorage.setItem(KEYS.ACCOUNTS, JSON.stringify(accountsData));
  } catch (e) {
    console.log(e);
  }
}

export async function getAccountsFromStorage() {
  // decode with const storedAccounts = JSON.parse(accountsData);
  try {
    return await AsyncStorage.getItem(KEYS.ACCOUNTS);
  } catch (e) {
    console.log(e);
  }
}

export async function setOnboardingVisited(flag: boolean) {
  try {
    return await AsyncStorage.setItem(KEYS.ONBOARDING_VISITED, flag ? 'Yes' : 'No');
  } catch (e) {
    console.log(e);
  }
}

export async function setCurrentUserOnboardingVisited(flag: boolean) {
  try {
    setOnboardingVisited(flag);
    const currentUser = await userInformation();
    const tokens: any[] = await getFullListOfTokens();
    const index = tokens.findIndex((token) => token.userId === currentUser.userId);
    if (index != -1) {
      const userToken = {...tokens[index]};
      userToken.onBoardingVisited = flag;
      tokens[index] = userToken;
    }
    await AsyncStorage.setItem(KEYS.ACCESS_TOKEN_LIST, JSON.stringify(tokens));
  } catch (e) {
    console.log(e);
  }
}

export async function onboardingVisited() {
  try {
    return (await AsyncStorage.getItem(KEYS.ONBOARDING_VISITED)) == 'Yes';
  } catch (e) {
    console.log(e);
  }
}

export async function resetOnboardingVisited() {
  try {
    return await AsyncStorage.removeItem(KEYS.ONBOARDING_VISITED);
  } catch (e) {
    console.log(e);
  }
}


export async function getUserTypeFromStorage() {
  try {
    return await AsyncStorage.getItem(KEYS.USER_TYPE);
  } catch (e) {
    console.log(e);
  }
}

export async function setUserTypeoStorage(user_type: UserType) {
  try {
    return await AsyncStorage.setItem(KEYS.USER_TYPE, user_type.toString());
  } catch (e) {
    console.log(e);
  }
}

export async function getRefreshTokenFromStorage() {
  try {
    return await AsyncStorage.getItem(KEYS.REFRESH_TOKEN);
  } catch (e) {
    console.log(e);
  }
}
export async function setRefreshTokenToStorage(token: string) {
  try {
    return await AsyncStorage.setItem(KEYS.REFRESH_TOKEN, token);
  } catch (e) {
    console.log(e);
  }
}

export async function setAllowedModules(modules: String[]) {
  try {
    allowedModulesState(modules || []);
    return await AsyncStorage.setItem(KEYS.ALLOWED_MODULES, JSON.stringify(modules) || '[]');
  } catch (e) {
    console.log(e);
  }
}

export async function getAllowedModules() {
  try {
    const modules = await AsyncStorage.getItem(KEYS.ALLOWED_MODULES);
    return modules && JSON.parse(modules) || [];
  } catch (e) {
    console.log(e);
  }
}

// === Legacy Login === It should be refactored or removed in the future.
export async function getFullListOfTokens() {
  const res = await AsyncStorage.getItem(KEYS.ACCESS_TOKEN_LIST);
  if (res) {
    return JSON.parse(res);
  }

  return [];
}

export async function getAccessTokenByUser(userId: string) {
  const res = await AsyncStorage.getItem(KEYS.ACCESS_TOKEN_LIST);
  if (res) {
    const list = JSON.parse(res);
    if (Array.isArray(list)) return list.find((token) => token.userId === userId);
  }
  return;
}

export async function addOrUpdateTokenListInStorage(token: string, refresh: string) {
  let decoded: any;
  try {
    decoded = decodeToken(token);
  } catch (err) {
    console.log('Failed to add account', err);
  }
  if (decoded) {
    const access_token = {
      userId: decoded?.userId,
      token,
      refresh,
      profileUri: decoded.userDetails?.photo,
      email: decoded.email,
      name: properCase(decoded?.userDetails?.fullName) || decoded.userName,
    };
    const tokens: any[] = await getFullListOfTokens();

    const index = tokens.findIndex((token) => token.userId === decoded.userId);
    if (index === -1) {
      tokens.push(access_token);
    } else {
      tokens[index] = access_token;
    }
    await AsyncStorage.setItem(KEYS.ACCESS_TOKEN_LIST, JSON.stringify(tokens));
  } else {
    throw new Error(ERROR_MSG.INVALID_TOKEN_ACCOUNT_ADD);
  }
}

// === Legacy Login === It should be refactored or removed in the future. {{START}}
export async function deleteUserTokenFromTokenListInStorage(userId: string) {
  try {
    let tokens: any[] = await getFullListOfTokens();

    tokens = tokens.filter((userinfo: any) => userinfo.userId !== userId);

    console.log(tokens, 'tokens');
    await AsyncStorage.setItem(KEYS.ACCESS_TOKEN_LIST, JSON.stringify(tokens));
    return true;
  } catch (err) {
    console.log('err while addding access token ', err);
    return false;
  }
}
