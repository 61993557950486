import * as React from 'react';
import Svg, { Path, Ellipse } from 'react-native-svg';

function SvgComponent(props: any) {
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M7.28555 0C3.26426 0 0.000766417 2.69861 0.000766417 6.02912C0.000766417 7.40973 0.567674 8.6741 1.50654 9.69007C1.08336 10.835 0.201119 11.8026 0.18689 11.8133C-0.00159571 12.0162 -0.0514278 12.3133 0.0552943 12.5669C0.165508 12.8206 0.409233 12.9858 0.683011 12.9858C2.43297 12.9858 3.78791 12.2394 4.64169 11.6452C5.46701 11.9075 6.35494 12.0582 7.28555 12.0582C11.3097 12.0582 14.5455 9.35876 14.5455 6.02912C14.5455 2.69948 11.3097 0 7.28555 0ZM7.28555 11.1307C6.48158 11.1307 5.68501 11.0075 4.91661 10.7611C4.64579 10.6722 4.3497 10.7188 4.11776 10.8868C3.48881 11.3579 2.45175 11.9098 1.19499 12.0364C1.51089 11.5982 2.04166 10.8625 2.35442 10.0181L2.35809 10.0083C2.47998 9.68166 2.40906 9.31267 2.1751 9.05701C1.35209 8.148 0.912393 7.119 0.912393 6.02912C0.912393 3.21746 3.77255 0.927556 7.26165 0.927556C10.7764 0.927556 13.6109 3.21746 13.6109 6.02912C13.6109 8.84077 10.8003 11.1307 7.28555 11.1307Z"
        fill="#2B78CA"
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.38477 12.8121C6.47393 14.1884 8.14479 15.0689 10.0257 15.0689C10.7888 15.0689 11.5169 14.9176 12.1936 14.6542C12.8937 15.2508 14.0048 16.0001 15.4398 16.0001C15.6643 16.0001 15.8641 15.8342 15.9545 15.5796C16.042 15.325 16.0011 15.0267 15.8466 14.823C15.8349 14.8122 15.1115 13.8408 14.7645 12.6914C15.5343 11.6714 15.9992 10.402 15.9992 9.01598C15.9992 8.44989 15.9225 7.90198 15.779 7.38232C15.6515 7.84437 15.4708 8.28952 15.2426 8.71307C15.2486 8.81331 15.2517 8.9143 15.2517 9.01598C15.2517 10.1102 14.8911 11.1432 14.2162 12.0558C14.0244 12.3125 13.9662 12.6829 14.0662 13.0109L14.0692 13.0207C14.3252 13.867 14.7594 14.6045 15.0186 15.0447L15.0199 15.047C13.9894 14.9198 13.139 14.3658 12.6233 13.8929C12.4331 13.7242 12.1903 13.6774 11.9682 13.7666C11.3381 14.014 10.6849 14.1377 10.0257 14.1377C8.76883 14.1377 7.62179 13.7005 6.7278 12.9733C6.26995 12.9484 5.8217 12.8936 5.38477 12.8121Z"
        fill="#2B78CA"
      />
      <Ellipse cx="4.36351" cy="6.07424" rx="0.727275" ry="0.740741" fill="#2B78CA" />
      <Ellipse cx="7.27269" cy="6.07424" rx="0.727275" ry="0.740741" fill="#2B78CA" />
      <Ellipse cx="10.1819" cy="6.07424" rx="0.727275" ry="0.740741" fill="#2B78CA" />
    </Svg>
  );
}

export default SvgComponent;
