import * as React from "react"
import Svg, { G, Mask, Path } from "react-native-svg"

function SvgComponent(props:any) {
  const { size = 40 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <Path fillRule="evenodd" clipRule="evenodd" d="M40 20.0002C40 31.0458 31.0459 40 20 40C8.95408 40 0 31.0458 0 20.0002C0 8.9545 8.95408 0 20 0C31.0459 0 40 8.9545 40 20.0002Z" fill="white"/>
        <Path d="M39.5 20.0002C39.5 30.7697 30.7698 39.5 20 39.5C9.23022 39.5 0.5 30.7697 0.5 20.0002C0.5 9.23063 9.23023 0.5 20 0.5C30.7698 0.5 39.5 9.23063 39.5 20.0002Z" stroke="black" strokeOpacity="0.2"/>
        <Mask id="mask0_15338_105144" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <Path d="M40 20.0002C40 31.0458 31.0459 40 20 40C8.95408 40 0 31.0458 0 20.0002C0 8.9545 8.95408 0 20 0C31.0459 0 40 8.9545 40 20.0002Z" fill="white"/>
        </Mask>
        <G mask="url(#mask0_15338_105144)">
        <Path d="M20 4.65405C15.205 4.65405 11.3622 8.26522 11.3622 12.7405C11.373 13.2171 11.4086 13.9059 11.4541 14.4865C10.6341 14.8345 10.5743 16.4303 11.2703 18.0703C11.5463 18.7206 11.8978 19.2199 12.2811 19.6324C12.3742 20.0933 12.3498 20.3002 12.4649 20.827C12.786 21.7905 14.0523 23.6789 15.2216 25.2378C15.3363 26.4351 15.4552 27.6433 15.2216 29.0054C11.8522 31.8009 3.17087 33.0033 3.18378 35.8973L3 37V43H20H36.8162L37 37L36.8162 35.8973C36.8024 33.4204 28.1065 31.8994 24.7784 29.0054C24.6437 27.8976 24.618 26.7884 24.6865 25.6973C25.752 24.0529 27.1774 21.9002 27.5351 20.827C27.6637 20.2836 27.7105 19.9166 27.8108 19.4486C28.1301 19.0619 28.4026 18.6245 28.6378 18.0703C29.2991 16.5123 29.2563 15.0251 28.546 14.5784C28.5878 13.9507 28.6194 13.2827 28.6378 12.7405C28.6378 8.26522 24.795 4.65405 20 4.65405Z" fill="#BEBEBE"/>
        </G>
    </Svg>
  )
}

export default SvgComponent