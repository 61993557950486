import { colors, fonts } from "src/styles/theme/styles";
import {
  getFormattedDate,
  getInitialDateTimeValue,
} from "src/components/atoms/DateTime/helpers";
import useFieldApi, {
  UseFieldApiConfig,
} from "@data-driven-forms/react-form-renderer/use-field-api";

import DateTimePicker from "src/components/atoms/DateTime/DateTimePicker.web";
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import React from "react";
import styled from "styled-components/native";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { useI18n } from "src/i18n/hooks";

export default function DateTime(props: UseFieldApiConfig) {
  const {
    input,
    meta: { error },
    label,
    dateTimeMode = "date",
    disableFuture,
    minDate,
    maxDate,
    subscribeToValues,
    disablePast,
  } = useFieldApi(props);

  const { t } = useI18n();
  const { value, onChange, name } = input;
  const { getState } = useFormApi();
  const { submitFailed } = getState();

  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <DTWrapper key={name}>
          <DateTimePicker
            initialValue={value ? getInitialDateTimeValue<true>(value, dateTimeMode) : undefined}
            dateTimePickerTitle={t(label)}
            dateTimeMode={dateTimeMode}
            minDate={minDate ? getInitialDateTimeValue<true>(minDate, dateTimeMode) : undefined}
            maxDate={maxDate ? getInitialDateTimeValue<true>(maxDate, dateTimeMode) : undefined}
            onDateTimeChangeCallback={(selectedDate: Date) => {
              onChange(getFormattedDate(selectedDate, dateTimeMode));
            }}
            disableFuture={disableFuture}
            disablePast={disablePast}
          />
          <ErrorMsg>{submitFailed && t(error)}</ErrorMsg>
        </DTWrapper>
      )}
    </FormSpy>
  );
}

const DTWrapper = styled.View`
  margin-bottom: 10px;
`;

const ErrorMsg = styled.Text`
  font-family: ${fonts.regular};
  color: ${colors.errorColor};
  margin-top: 5px;
  font-size: 12px;
  min-height: 14px;
`;
