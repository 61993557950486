import React, { CSSProperties, useEffect } from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, Breakpoint } from '@mui/material';
import HeaderFour from '../../Text/HeaderFour';
import ModalBaseAction from '../BaseAction';
import { useThemeSystem } from 'src/contexts/theme-context';
import { breakpoints, colors, fontWeight } from 'src/styles/theme/styles';
import AlertBox from 'src/components/molecules/Alert';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { width as deviceWidth } from 'src/constant/device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NormalTextSelect from '../../Text/NormalTextSelect';
import { useI18n } from 'src/i18n/hooks';
import { useHistory, useLocation } from 'src/routes/routing';
import LoaderSpinner from '../../LoaderSpinner/index.web';
import styled from 'styled-components';

interface Props {
  children: any;
  modalVisible: boolean;
  setModalVisible: () => void;
  Headerpopup: any;
  handleSave?: () => void;
  cancelButtonLabel?: string;
  addEditButtonLabel?: string;
  actionButtonLabel?: string;
  dialogAction?: boolean;
  width?: CSSProperties["width"];
  disabledSave?: boolean;
  maxWidth?: Breakpoint;
  height?: number;
  lineHeight?: number;
  marginTop?: number;
  infoModal?: boolean;
  hideCross?: boolean;
  cformRef?: any;
  type?: string;
  isSubmitting?: boolean;
  canLoad?: boolean;
  showFooter?: boolean;
  contentStyles?: any;
  hideSubmit?: boolean;
  timer?: boolean;
  disabled?: boolean;
  preventRedirectOnClose?: boolean;
  visibility?: boolean;
  hideCloseIcon?: boolean;
  hideCancel?: boolean;
  errorMessage?: Object | null;
  setErrorMessage?: React.Dispatch<React.SetStateAction<Object | null>>;
  showActionButton?: boolean;
  handleActionButton?: () => void;
  onCancelButtonClick?: () => void;
}

export default function NormalModal(props: Props) {
  const {
    children,
    modalVisible,
    setModalVisible,
    Headerpopup = '',
    maxWidth,
    handleSave = () => { },
    cancelButtonLabel = 'cancel.label',
    addEditButtonLabel = 'add.label',
    actionButtonLabel = '',
    dialogAction = true,
    width,
    infoModal = false,
    height,
    lineHeight = 32,
    marginTop = 0,
    type,
    cformRef,
    isSubmitting,
    canLoad = false,
    showFooter = true,
    contentStyles = {},
    hideSubmit = true,
    timer,
    disabled = false,
    preventRedirectOnClose = false,
    visibility = true,
    hideCloseIcon = false,
    hideCancel = false,
    errorMessage = null,
    setErrorMessage,
    showActionButton = false,
    handleActionButton,
    onCancelButtonClick,
  } = props;
  const isDesktop = deviceWidth > breakpoints?.md;
  const { theme } = useThemeSystem();
  const { alertState, setAlertDetails } = useAlertSystem();
  const { t } = useI18n();
  const history = useHistory();
  const location = useLocation();
  const isAlertMessage = alertState?.message || errorMessage;

  const initial = {
    level: '',
    message: '',
  };

  useEffect(() => {
    if (modalVisible) {
      clearAlert();
    }
  }, [modalVisible]);

  function clearAlert() {
    setAlertDetails(initial);
    if (setErrorMessage) setErrorMessage(null);
  }

  function closeModal() {
    /* 
      If news, notice or events is opened from notification bell icon panel, then don't replace
      url path. This also prevents an unnecessary redirect across app which happened when you closed the
      modal (infoModal) which was opened from bell icon.

      Redirect is only required (for infoModal) on close when we are on news/notices or events page 
    */
    if (preventRedirectOnClose || !infoModal) {
      if (!isDesktop) {
        setTimeout(() => {
          setModalVisible();
        }, 400);
      } else {
        setModalVisible();
      }
    } else {
      let pathname = location.pathname;
      pathname = pathname.slice(0, pathname.lastIndexOf('/'));
      pathname = pathname.slice(0, pathname.lastIndexOf('/'));
      history.push(pathname);
    }
  }

  return (
    <View>
      <StyledDialog
        // maxWidth={maxWidth ?? (isDesktop ? 'md' : 'sm')}
        maxWidth={maxWidth}
        onClose={closeModal}
        open={modalVisible}
        fullWidth={true}
        sx={{
          width: width || null,
          height: height || null,
          marginTop: marginTop,
        }}
        style={{ borderRadius: 16, visibility: visibility ? 'visible' : 'hidden' }}
        type={type}>
        {Headerpopup !== '' ? (
          <DialogTitle>
            <View style={{ flex: 1, flexDirection: 'row', marginTop: -1, marginBottom: -2 }}>
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: !isDesktop && 'auto',
                }}>
                <HeaderFour
                  value={Headerpopup}
                  color={theme?.dialog?.titleColor}
                  lineHeight={lineHeight}
                  fontWeight={infoModal ? fontWeight[600] : fontWeight[400]}
                />
              </View>

              {!hideCloseIcon && isDesktop && (infoModal || !showFooter) && (
                <Tooltip title="Close">
                  <TouchableOpacity
                    style={{ alignSelf: 'center', marginLeft: 'auto' }}
                    onPress={closeModal}>
                    <FontAwesomeIcon icon={faTimes} color={colors.secondaryText} size={'sm'} />
                  </TouchableOpacity>
                </Tooltip>
              )}

              {!isDesktop && addEditButtonLabel && (
                <TouchableOpacity
                  disabled={canLoad}
                  style={[styleRN.alignRightButton, styleRN.rightButton]}
                  onPress={handleSave}>
                  <>
                    {canLoad ? (
                      <View style={{ paddingVertical: 2 }}>
                        <LoaderSpinner primary={false} />
                      </View>
                    ) : (
                      <NormalTextSelect value={t(addEditButtonLabel)} color={colors.blue} />
                    )}
                  </>
                </TouchableOpacity>
              )}
            </View>
          </DialogTitle>
        ) : null}

        {Boolean(isAlertMessage) && (
          <AlertBox
            alert={alertState?.message ? alertState : errorMessage}
            clearAlert={clearAlert}
          />
        )}
        <DialogContent
          style={{
            height: height || null,
            paddingBottom: infoModal ? 24 : 0,
            ...contentStyles,
          }}
          infoModal={infoModal}>
          {children}
        </DialogContent>

        {dialogAction && isDesktop && showFooter && !infoModal && (
          <DialogActions style={{ padding: 1 }}>
            <ModalBaseAction
              handleCancel={!!onCancelButtonClick ? onCancelButtonClick : setModalVisible}
              cancelButtonLabel={cancelButtonLabel}
              addEditButtonLabel={addEditButtonLabel}
              actionButtonLabel={actionButtonLabel}
              handleSave={handleSave}
              disabledSave={cformRef?.current?.isSubmitting || isSubmitting}
              hideSubmit={hideSubmit}
              hideCancel={hideCancel}
              timer={timer && timer}
              disabled={disabled}
              showActionButton={showActionButton}
              handleActionButton={handleActionButton}
            />
          </DialogActions>
        )}
      </StyledDialog>
    </View>
  );
}

const styleRN = StyleSheet.create({
  rightButton: {
    alignSelf: 'center',
    position: 'absolute',
    right: 0,
  },
  alignRightButton: {
    alignSelf: 'center',
  },
});

const StyledDialog = styled(Dialog) <{ type?: string }>`
  && {
    margin: 0 auto;
    height: 100%;
    .MuiDialog-paperWidthXl {
      width: 1162px;
    }
    .MuiDialog-paperWidthLg {
      width: 788px;
      background-color: rgb(255, 255, 255);
    }
    .MuiDialog-paperWidthMd {
      width: 640px;
    }
    .MuiDialog-paperWidthSm {
      width: 546px;
    }
    .MuiDialogTitle-root {
      padding: 16px 32px;
    }
    .MuiDialogContent-root {
      padding-left: 32px;
      padding-right: 32px;
      overflow-x: hidden;
      padding-top: 5px;
    }
    .MuiDialog-paper {
      border-radius: 16px;
    }
  }
`;

