import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableRow, styled } from '@mui/material';
import Input from 'src/components/atoms/Input/input.web';
import { colors } from 'src/styles/theme/styles';
import useTable from './UseTable';
import { regexObject } from 'src/constant/regex';

export default function InputTable(props: any) {
  const { 
    header,
    tabledata,
    handleCustomOnChange,
    customOnChange,
    setInputTableDataObj,
    dropDownData,
    inputTableDataObj
  } = props;
  let maxHeight;
  const [value, setValue] = useState({});
  const [tabledataState] = useState(tabledata);
  const { TblContainer, TblHead } = useTable(tabledataState, header, maxHeight, tabledata);

  useEffect(() => {
    if (tabledata && tabledata[0].marks) {
      loadMarks();
    }
  }, [tabledata]);

  useEffect(() => {
    setValue({});
  }, [dropDownData]);

  useEffect(() => {
    if (tabledata && tabledata[0].marks) {
      const newObj = Object.assign({}, inputTableDataObj);
      for (const key in newObj) if (key in value) newObj[key] = value[key];
      setInputTableDataObj(newObj);
    } else {
      setInputTableDataObj(value);
    }
  }, [value]);

  function loadMarks() {
    const newData = {};
    tabledata.forEach((item: any) => {
      newData[item?.id] = item?.marks;
    })
    setInputTableDataObj(newData);
  }

  const onChangesetValue=(value,item)=>{
    setValue(prev => ({
      ...prev,
      [item?.id]: value,
    }));
  }

  return (
    <TblContainer tabledata={tabledata}>
      <TblHead />
      <TableBody>
        {tabledata &&
          tabledata.map((item: any, i: number) => (
            <CTableRow key={i}>
              <TableCell style={styles.textStyle}>{item?.name}</TableCell>
              <TableCell style={styles.inputTableStyle}>
                <Input
                  id={i}
                  value={
                    Object.keys(value).length > 0 ? value[item.id] : item?.marks ? item.marks : ''
                  }
                  setValue={e => {
                    handleCustomOnChange(e.target.value, item,onChangesetValue);
                  }}
                />
              </TableCell>
              {
                item.markingType === "MARK" ? <TableCell
                align={'left'}
                style={styles.slashStyle}>{`/ ${item?.totalMarks}`}</TableCell> : 
                <TableCell
                align={'left'}
                style={styles.slashStyle}>{`GRADE`}</TableCell>
              }
              
            </CTableRow>
          ))}
      </TableBody>
    </TblContainer>
  );
}

const styles = {
  textStyle: {
    paddingRight: 50,
    fontSize: 15,
    color: colors.primaryText,
    fontWeight: 400,
    width: 225
  },
  slashStyle: {
    paddingLeft: 15,
    fontSize: 20,
    color: colors.primaryText,
    fontWeight: 400,
    width: 60
  },
  inputTableStyle: {
    width: 150,
    padding: '0px 15px',
    fontSize: 15,
    color: colors.primaryText,
    fontWeight: 400,
  }
};

const CTableRow = styled(TableRow)`
  height: 40px;
`;
