import {
  DemographicLegends,
  DemographicsChartContainer,
  DemographicsWrapper,
  DashboardEmptyContainer as EmptyContainer,
  DemographicsScrollContainer as ScrollContainer,
} from "../Common/CommonHelpers";

import CardTitleBlock from "src/components/molecules/CardTitleBlock";
import Chart from "react-apexcharts";
import React from "react";
import ShadowBox from "src/components/atoms/ShadowBox";
import { barColors } from "src/styles/theme/styles";
import { faUserGraduate } from "@fortawesome/pro-regular-svg-icons";
import { getCourseDemographicQuery } from "src/graphql/academics/courses";
import { useI18n } from "src/i18n/hooks";
import { useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

const useCourseDemographics = () => {
  const { data, loading } = useQuery(getCourseDemographicQuery, {
    variables: {},
  });

  let labelArray: string[] = [];
  let labelArrayEmpty: string[] = [];
  let labelData: string[] = [];
  let labelBarColor: string[] = [];

  if (data) {
    data.courseDemographic
      .slice(-10)
      .forEach((inst: { course: { name: string } }, index: number) => {
        labelArrayEmpty.push("");
        labelArray.push(inst?.course?.name);
        labelData.push((Math.floor(Math.random() * 100) + 1).toString());
        labelBarColor.push(barColors[index]);
      });
  }

  return {
    labelArray,
    labelArrayEmpty,
    labelData,
    labelBarColor,
    loading,
  };
};

function PopularCourseDemographics() {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const { labelArray, labelArrayEmpty, labelData, labelBarColor, loading } =
    useCourseDemographics();

  const courseData = {
    labels: labelArrayEmpty,
    datasets: [
      {
        label: "",
        data: labelData,
        backgroundColor: labelBarColor,
        borderColor: labelBarColor,
        borderWidth: 1,
        barThickness: 16,
      },
    ],
  };
  const verticalSeries = [
    {
      data: labelData,
    },
  ];

  const verticalbarChartOptions = {
    stroke: {
      show: true,
      colors: ["transparent"],
      width: 2,
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    grid: {
      show: false,
      borderColor: "#90A4AE",
    },
    fill: { colors: labelBarColor },
    xaxis: {
      show: false,
      categories: labelArray,
      labels: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      show: true,
      showAlways: true,
      categories: [],
      labels: {
        show: true,
        formatter: (value: any) => {
          return value === 0 ? value : value / 1000 + "k";
        },
        style: {
          colors: ["#a3a3a3"],
          fontSize: rem(1.5),
          fontFamily: "Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
      },
      axisBorder: {
        show: true,
        color: "#cccccc",
        offsetX: 0,
        offsetY: 0,
      },
    },

    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
        distributed: true,
        columnWidth: rem(4.5),
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
      },
    },

    chart: {
      id: "bar",
      toolbar: { show: false },
    },
    tooltip: {
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        return `<div style="padding:4px 6px; max-width: 180px; white-space: initial;"> 
          <span>
            ${w.globals.labels[dataPointIndex]} : 
            ${series[seriesIndex][dataPointIndex]}
          </span> 
        </div>`;
      },
    },
  };

  return (
    <>
      <CardTitleBlock titleText={t("recentCourseDemographics.label")} />
      <ShadowBox>
        <ScrollContainer>
          {courseData && labelData.length > 0 ? (
            <DemographicsWrapper>
              <DemographicsChartContainer>
                <Chart
                  options={verticalbarChartOptions}
                  series={verticalSeries}
                  type="bar"
                  width="100%"
                  height={rem(38)}
                />
              </DemographicsChartContainer>
              <DemographicLegends labelArray={labelArray} />
            </DemographicsWrapper>
          ) : (
            <EmptyContainer
              text={t("noCourseDemographics.text")}
              icon={faUserGraduate}
              loading={loading}
            />
          )}
        </ScrollContainer>
      </ShadowBox>
    </>
  );
}

export default PopularCourseDemographics;
