import useFieldApi, {
  UseFieldApiConfig,
} from "@data-driven-forms/react-form-renderer/use-field-api";

import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import MediumText from "src/components/atoms/Text/MediumText";
import React from "react";
import ToggleSwitch from "src/components/atoms/Switch/Toggle.web";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";

function Toggle(props: UseFieldApiConfig) {
  const { label = '', subscribeToValues, handleChange, enabled, disabled } =
    useFieldApi(props);

  const { t } = useI18n();

  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <Wrapper>
          <ToggleSwitchWrapper>
            <ToggleSwitch
              enabled={enabled}
              onChange={handleChange}
              disabled={disabled}
              style={switchStyle}
              header={label}
            />
          </ToggleSwitchWrapper>
        </Wrapper>
      )}
    </FormSpy>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8 0px;
  justify-content: space-between;
  align-items: center;
`;

// intentional blank div
const ToggleSwitchWrapper = styled.div`
`;

const switchStyle = {
  justifyContent: "flex-start",
  alignItems: "center",
};

export default Toggle;
