import React, { useState, useEffect } from 'react';
import { BackHandler } from 'react-native';
import { Route, useHistory } from './routing.web';
import { getTokenFromStorage, removeTokenFromStorage } from '../components/services/storage-service';
import { keyValuesVar } from 'src/utils/manageState';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useReactiveVar } from '@apollo/client';
import ApplyLanguage from 'src/i18n/apply-language';
import styled from 'styled-components/native';
import jwt_decode from 'jwt-decode';
import { USER_TYPE } from 'src/constant';
import SessionExpiry from 'src/components/organism/Login/SessionExpiry';


export const OnBoardingPrivateRoute = ({ component: Component, ...rest }: any) => {
  const authUser = rest.authUser;
  const userType = rest.userType;
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const keyVal = useReactiveVar(keyValuesVar);

  useEffect(() => {
    if (Object.keys(keyValuesVar()).length > 0) {
      setLoader(false);
    }
  }, [keyVal]);


  useEffect(() => {
    getTokenFromStorage().then((token) => {
      if (token) {
        const decoded: any = jwt_decode(token);
        if (decoded?.exp) {
          const expDate = new Date(decoded.exp * 1000);
          const today = new Date();
          if (today > expDate) {
            setSessionExpired(true);
            removeTokenFromStorage();
          }
        }
      }
    })
  }, [rest.path]);

  useEffect(() => {
    if (userType) {
      if (userType < 1 || !authUser.includes(userType)) {
        history.push('/');
      }
      if (userType === USER_TYPE.APPLICANT) {
        setLoader(false);
      }
    }
  }, [userType]);

  useEffect(() => {
    reditrectToHome();
  }, []);

  const backActionHandler = () => {
    history.goBack();
    return true;
  };

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', backActionHandler);
    return () => BackHandler.removeEventListener('hardwareBackPress', backActionHandler);
  }, []);

  async function reditrectToHome() {
    const token = await getTokenFromStorage();
    if (!token) {
      history.push('/');
    }
  }

  return (
    <>
      {
        !sessionExpired &&
        <>
          <ApplyLanguage />
          {!loader ? (
            <Route {...rest} render={() => <Component />} />
          ) : (
            <SpinnerContainer>
              <LoaderSpinner />
            </SpinnerContainer>
          )}
        </>
      }

      <SessionExpiry isSessionExpired={sessionExpired} />
    </>
  );
};

const SpinnerContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`
const ReloginMessage = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
`
