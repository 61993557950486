/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { breakpoints, colors, fonts, fontWeight } from 'src/styles/theme/styles';
import { PixelRatio, StyleSheet, TouchableOpacity, View } from 'react-native';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faMoneyCheckEdit, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { isNative, isWeb, width } from 'src/constant/device';
import ImageAtom from 'src/components/atoms/ImageAtom';
import { useI18n } from 'src/i18n/hooks';
import { AUDIENCE_TYPE } from 'src/constant';
import ClassAtom from 'src/components/atoms/ClassAtom';
import { assignmentObjectType } from 'src/components/organism/Assignments/types';
import styled from 'styled-components/native';
import { useThemeSystem } from 'src/contexts/theme-context';
import SwipeCard from '../../SwipableCard';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { assigneeLabels, clipLongText, properCase } from 'src/utils/utility';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { DeleteIcon, EditIcon } from 'src/components/atoms/ActionIcons';

interface Props {
  onPress: (assignment: any, idx: number | undefined) => void;
  data: assignmentObjectType;
  student?: boolean;
  assignmentType?: string;
  isDashboard?: boolean;
  indexKey?: number;
  editAction?: any;
  deleteAction?: any;
  isSwipable?: boolean;
  clipText: boolean;
}

export default function AssignmentCard(props: Props) {
  const {
    onPress,
    editAction,
    deleteAction,
    student = true,
    data,
    isDashboard = false,
    indexKey,
    isSwipable = true,
    clipText = false,
  } = props;
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  let division: string = '';
  division = data?.batch?.divisionDetails?.[0]?.division;
  switch(data?.assigneeType) {
    case AUDIENCE_TYPE[1]:
      division = data?.assignees[0]?.split('/')[3];
      break;
    case AUDIENCE_TYPE[2]:
      division = data?.assignees[0]?.split('/')[2];
  }

  const parsedDueDate = data?.mdueDate && parse(data.mdueDate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date())
  const parsedPostDate = data?.mdate && parse(data.mdate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date())

  const dueDate: string = parsedDueDate && format(parsedDueDate, DT.DATE_ABBREVIATED_MONTH_YEAR);
  const postDate: string = parsedPostDate && format(parsedPostDate, DT.DATE_TIME_FORMAT_12_HOUR);

  const submission = data?.submissions > 0;
  const attachment = data?.attachments?.length > 0;
  const attachmentCount = data?.attachments?.length;
  const submissionCount = data?.submissions;
  const isDesktop = width > breakpoints.md;

  const TouchableOpacityList = styled.TouchableHighlight`
    width: ${isDesktop && !isDashboard ? '60%' : '100%'};
    border-bottom-color: ${colors.inputBorder};
    border-bottom-width: ${isDesktop ? '1px' : '0px'};
    padding-top: ${isDesktop ? '24px' : '16px'};
    padding-bottom: ${isDesktop ? '24px' : '16px'};
    padding-right: ${isDesktop ? '0px' : '24px'};
    padding-left: ${isDesktop ? '0px' : '24px'};
  `;

  return (
    <>
      <SwipeCard
        key={data?.id}
        deleteItem={() => deleteAction(data, indexKey)}
        testID={`deleteAssignmentCard-${indexKey}`}
        isSwipable={isSwipable && submissionCount < 1}>
        <TouchableOpacityList
          testID={`assignmentCard-${indexKey}`}
          activeOpacity={0.5}
          underlayColor={isDesktop ? colors.white : colors.inputBorder}
          delayPressIn={50}
          onPress={() => onPress(data, indexKey)}>
          <>
            <HeaderView>
              <SmallTextAtom
                value={`${t('dueOn.label')} ${dueDate || ''}`}
                color={colors.primaryColor}
                fontWeight={fontWeight[600]}
                fontFamily={fonts.semibold}
              />
              {!student && data?.assigneeLabels && (
                <LabelView>
                  <ClassAtom
                    name={
                      clipText
                        ? clipLongText(assigneeLabels(data)?.toString(), isDesktop ? 25 : 20)
                        : assigneeLabels(data)?.toString()
                    }
                  />
                </LabelView>
              )}
            </HeaderView>

            <View
              style={{
                marginTop: student ? 4 : 0,
              }}>
              <MediumTextSelect
                value={data?.subject?.alias || ''}
                color={colors.secondaryText}
                fontWeight={fontWeight[600]}
                fontFamily={fonts.semibold}
                lineHeight={2.4}
              />
            </View>

            <HeaderView>
              <PostedOnContainer
                style={{
                  marginTop: isNative ? (PixelRatio.get() > 2 ? -4 : -3) : -2,
                }}>
                <SmallTextAtom
                  value={`${t('postedOn.label')} ${postDate}`}
                  color={colors.secondaryText}
                  fontWeight={fontWeight[400]}
                />
              </PostedOnContainer>

              {width > breakpoints.md && !student && !isDashboard && (
                <ActionContainer>
                  <IconViewContainer>
                    <EditIcon tooltipTitle={t("editAssignment.text")} onPress={() => editAction(data)} />
                  </IconViewContainer>

                  <View>
                    {submissionCount < 1 && (
                      <DeleteIcon tooltipTitle={t("deleteAssignment.text")} onPress={() => deleteAction(data, indexKey)} />
                    )}
                  </View>
                </ActionContainer>
              )}
            </HeaderView>

            <View style={{ marginVertical: 4 }}>
              <NormaltextAtom
                value={data?.title || ''}
                color={colors.secondaryText}
                fontWeight={fontWeight[400]}
                lineHeight={2.2}
                lines={2}
              />
            </View>
            {student && (
              <AssignorContainer>
                {data?.assignor?.profilePicture?.documentUrl &&
                data?.assignor?.profilePicture?.documentUrl !==
                  'https://dev-api.edba.io/personal/photo/default' ? (
                  <ImageAtom
                    source={{
                      uri: data?.assignor?.profilePicture?.documentUrl,
                    }}
                    style={[styles.imgStyle]}
                  />
                ) : (
                  <Icon name="profile-pic" size={24} />
                )}
                <AssignorNameView>
                  <SmallTextAtom
                    value={properCase(data?.assignor?.personalDetails?.fullName || '-')}
                    color={colors.primaryText}
                  />
                </AssignorNameView>
              </AssignorContainer>
            )}

            {(submission || attachment) && (
              <AttachmentContainer>
                {attachmentCount > 0 && (
                  <AttachmentView>
                    <FontAwesomeIcon icon={faPaperclip} color={colors.red} size={16} />
                    <AttachmentTextView>
                      <SmallTextAtom
                        value={`${attachmentCount} ${t(
                          attachmentCount === 1 ? 'attachment.label' : 'attachments.label',
                        )}`}
                        color={colors.primaryText}
                      />
                    </AttachmentTextView>
                  </AttachmentView>
                )}

                {!student && submissionCount > 0 && (
                  <SubmissionView style={{ marginLeft: attachment ? 16 : 0 }}>
                    <FontAwesomeIcon
                      icon={faMoneyCheckEdit}
                      size={16}
                      color={colors.warningColor}
                    />
                    <AttachmentTextView>
                      <SmallTextAtom
                        value={`${submissionCount} ${t(
                          submissionCount === 1 ? 'submission.label1' : 'submissions.label1',
                        )}`}
                        color={colors.primaryText}
                      />
                    </AttachmentTextView>
                  </SubmissionView>
                )}
              </AttachmentContainer>
            )}
          </>
        </TouchableOpacityList>
        {!isDesktop && <Horizontal />}
      </SwipeCard>
    </>
  );
}

const Horizontal = styled.View`
  border-bottom-color: ${colors.inputBorder};
  border-bottom-width: 1px;
  margin-left: 24px;
  margin-right: 24px;
`;

const ActionContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 4px;
  margin-left: auto;
`;

const IconViewContainer = styled.View`
  margin-right: 13px;
`;

const AssignorContainer = styled.View`
  flex-direction: row;
  margin-top: 2px;
`;

const AssignorNameView = styled.View`
  margin-left: 8px;
  align-self: center;
`;

const AttachmentContainer = styled.View`
  margin-top: 10px;
  flex-direction: row;
`;

const AttachmentTextView = styled.View`
  margin-top: 2px;
  margin-left: 8px;
`;

const HeaderView = styled.View`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const PostedOnContainer = styled.View`
  margin-bottom: ${isNative ? 4 : 0}px;
`;

const SubmissionView = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const AttachmentView = styled.View`
  flex-direction: row;
  align-items: center;
`;

const LabelView = styled.View`
  max-width: 50%;
`;

const styles = StyleSheet.create({
  imgStyle: {
    height: 24,
    width: 24,
    borderRadius: 50,
  },
});
