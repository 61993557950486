import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function ManageAdmission(props: any) {
  const { size = 24, color = '#666666' } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}  
    >
      <Path
        d="M16 6H14.75H14.45C14.2187 4.85938 13.2094 4 12 4C10.7906 4 9.78125 4.85938 9.55 6H9.25H8C6.89688 6 6 6.89688 6 8V18C6 19.1031 6.89688 20 8 20H16C17.1031 20 18 19.1031 18 18V8C18 6.89688 17.1031 6 16 6ZM8.5 7.5V8.25C8.5 8.66563 8.83437 9 9.25 9H12H14.75C15.1656 9 15.5 8.66563 15.5 8.25V7.5H16C16.275 7.5 16.5 7.725 16.5 8V18C16.5 18.275 16.275 18.5 16 18.5H8C7.725 18.5 7.5 18.275 7.5 18V8C7.5 7.725 7.725 7.5 8 7.5H8.5ZM11.25 6.5C11.25 6.30109 11.329 6.11032 11.4697 5.96967C11.6103 5.82902 11.8011 5.75 12 5.75C12.1989 5.75 12.3897 5.82902 12.5303 5.96967C12.671 6.11032 12.75 6.30109 12.75 6.5C12.75 6.69891 12.671 6.88968 12.5303 7.03033C12.3897 7.17098 12.1989 7.25 12 7.25C11.8011 7.25 11.6103 7.17098 11.4697 7.03033C11.329 6.88968 11.25 6.69891 11.25 6.5Z"
        fill="black"
        fill-opacity="0.6"/>
      <Path
        d="M12.9399 13.3008L14.4163 11.8244C14.5279 11.7128 14.5279 11.5324 14.4163 11.4209L13.8799 10.8845C13.7683 10.7729 13.5879 10.7729 13.4764 10.8845L12 12.3608L10.5236 10.8845C10.4121 10.7729 10.2317 10.7729 10.1201 10.8845L9.58367 11.4209C9.47211 11.5324 9.47211 11.7128 9.58367 11.8244L11.0601 13.3008L9.58367 14.7772C9.47211 14.8887 9.47211 15.0691 9.58367 15.1807L10.1201 15.7171C10.2317 15.8287 10.4121 15.8287 10.5236 15.7171L12 14.2407L13.4764 15.7171C13.5879 15.8287 13.7683 15.8287 13.8799 15.7171L14.4163 15.1807C14.5279 15.0691 14.5279 14.8887 14.4163 14.7772L12.9399 13.3008Z"
        fill="black"
        fill-opacity="0.6"/>
    </Svg>
  );
}

export default ManageAdmission;