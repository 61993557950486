import { AUTOCOMPLETE_MODULE, limit } from 'src/constant';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { graphqlQuery } from 'src/graphql/util';
import { mergeBatchClassLabel } from 'src/utils/utility';
import selectionForm from 'src/form-json/assessment/assessment-result-selection.json';
import { useForm } from 'react-hook-form';
import { useHistory } from 'src/routes/routing.web';
import { useI18n } from 'src/i18n/hooks';
import { getModifiedExamDetailsQuery } from 'src/graphql/assessment/assessment-exam-selection-form';
import { TExamItem } from './Results/Helpers/helpers';
interface ResultSelectionFormProps {
  retrieveValues: any;
  dataLimit?: number;
  pagePath: string;
  urlBatchId?: string;
  urlDivision?: string;
  urlExamId?: string;
  setParentBatchId: any;
  setParentClassId?: any;
  setParentExamId?: any;
  setParentClassAlias?: any;
  setParentDivision: any;
  setParentSubjectsIds: any;
  parentHeadCells1: any;
  setParentAllFormValuesAvailable?: any;
  isLoading: boolean;
  setExamName?: any;
  setMarksType?:any;
  setExamDetails: Dispatch<SetStateAction<TExamItem | null>>;
}

export default function ResultSelectionForm(props: ResultSelectionFormProps) {
  const { rem }: any = useTheme();
  const [elements, setElements] = useState<any>({});
  const [filteredBatchObject, setFilteredBatchObject] = useState<any>({});
  const [examsListObject, setExamsListObject] = useState<any>([]);
  const [canFilterExam, setCanFilterExam] = useState<boolean>(false);
  const {
    retrieveValues,
    dataLimit = limit,
    pagePath,
    urlBatchId = '',
    urlDivision = '',
    urlExamId = '',
    setParentBatchId,
    setParentClassId,
    setParentExamId,
    setParentClassAlias,
    setParentDivision,
    setParentAllFormValuesAvailable,
    isLoading,
    setExamName,
    setMarksType,
    setExamDetails
  } = props;
  const { fields }: any = elements;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  useEffect(() => {
    setValue("batch", null);
    setValue("exams", null);
    setValue("division", null);
    onLoadFunction();
  }, []);

  useEffect(() => {
    if (examsListObject.length != 0 && canFilterExam) {
      filterExamByValue(examsListObject, urlExamId);
    }
  }, [examsListObject]);

  useEffect(() => {
    if (Object.keys(filteredBatchObject).length != 0) {
      //  set value for batch and AUTOCOMPLETE_MODULE i.e class alias
      setValue("batch", { label: `${filteredBatchObject?.label} / ${filteredBatchObject?.class?.alias}`, value: filteredBatchObject?.value, gradingType: filteredBatchObject?.gradingType });
      if (urlExamId) setCanFilterExam(true);

      const batchDivisions = filteredBatchObject?.divisionDetails?.map((det) => det.division);
      if (batchDivisions?.includes(urlDivision)) {
        // set other available option values too inside division form
        getDivision(batchDivisions);
        // set current value for division
        if (urlDivision) {
          setValue("division", { label: urlDivision, value: urlDivision });
          setParentDivision(urlDivision);
        }
        // semwise object creation
        setParentAllFormValuesAvailable(true);
      } else {
        // invalid division in url --> reset form and url
        setCanFilterExam(false);
        resetFormAndUrl();
      }

      // set state based on url params
      setParentBatchId(filteredBatchObject.value);
      if (setParentClassId) setParentClassId(filteredBatchObject.class.id);
      if (setParentClassAlias) setParentClassAlias(filteredBatchObject.class.alias);
    }
  }, [filteredBatchObject]);

  function filterExamByValue(arrayOfObject: any[], searchTerm: string) {
    // function that returns the entire object on which passed search Term exactly matches 
    var result = arrayOfObject.filter(function (v, i) {
      return v.value.indexOf(searchTerm) >= 0;
    });
    if (result.length != 0) {
      // exam id from url is valid i.e filtered result obj is not empty
      setValue("exams", { label: result[0].label, value: result[0].value, type: result[0].type });
      setParentExamId(urlExamId);
      setExamName(result[0].label);
    } else {
      resetFormAndUrl();
    }
  }

  function filterBatchByValue(arrayOfObject: any[], searchTerm: string) {
    // function that returns the entire object on which passed search Term exactly matches 
    var result = arrayOfObject.filter(function (v, i) {
      return v.value.indexOf(searchTerm) >= 0;
    });
    if (result.length != 0) {
      // batch id from url is valid i.e filtered result obj is not empty
      setFilteredBatchObject(result[0]);
      getExamsList(urlBatchId);
      setMarksType(result[0].settings.gradingType === 'MARKS');
    } else {
      // invalid batch from url
      resetFormAndUrl();
    }
  }

  async function onLoadFunction() {
    let { data: allBatches, loading } = await graphqlQuery(getBatchQuery, { limit: 0 });
    if (!loading && allBatches?.batches?.data) {
      let options = mergeBatchClassLabel(allBatches?.batches?.data);
      setElements({});
      selectionForm[0].fields[0].option = options;
      setElements(selectionForm[0]);

      // if batch id is present in url (passed from parent) then
      // filter out batch object with matching id value
      if (urlBatchId) {
        filterBatchByValue(allBatches?.batches?.data, urlBatchId);
      }
    }
  }

  async function getDivision(divisions: string[]) {
    let options = [] as any;
    divisions.forEach(function (item: any) {
      options.push({
        value: item.toString(),
        label: item.toString(),
      });
    });
    setElements({});
    selectionForm[0].fields[1].option = options;
    setElements(selectionForm[0]);
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      setCanFilterExam(false);
      setValue(AUTOCOMPLETE_MODULE.DIVISION, null);
      setValue(AUTOCOMPLETE_MODULE.EXAMS, null);
      if (data) {
        getDivision(data?.divisionDetails.map((det) => det.division));
        getExamsList(data?.value);
      } else {
        selectionForm[0].fields[1].option = [];
        selectionForm[0].fields[2].option = [];
      }
      clearErrors('division');
      clearErrors('exams');
    }

    if (id === AUTOCOMPLETE_MODULE.EXAMS) {
      if (data) {
        setExamName(data?.label);
      } else {
        setExamName('');
      }
    }
  }

  async function getExamsList(batchId: string) {
    let filters = {
      "filters": {
        "batch": {
          "eq": batchId
        }
      }
    };
    let { data: examsListData, loading } = await graphqlQuery(getModifiedExamDetailsQuery, filters);
    if (!loading && examsListData?.examDetails?.data) {
      loadExams(examsListData?.examDetails?.data);
    }
  }

  async function loadExams(exams: TExamItem[]) {
    if (urlExamId) {
      const currentExam = exams.find(e => e?.value === urlExamId);
      setExamDetails(currentExam ?? null);
    }
    setExamsListObject(exams);
    selectionForm[0].fields[2].option = exams as any;
    setElements({ ...selectionForm[0] });
  }

  function resetFormAndUrl() {
    // if invalid result --> reset form and url
    setValue('batch', null);
    setValue('exams', null);
    setValue('division', null);
    setExamDetails(null);
    history.replace(`/${pagePath}/limit/${dataLimit}/page/1`);
  }

  return (
    (fields ?
      <ResultSelectionFormWrapper>
        {
          fields && fields.map((field: any, i: number) => {
            return (
              <FormWrapper
                key={`s${i}`}
                style={
                  {
                    width: i == 0 ? rem(29) : i === 1 ? rem(10) : rem(18)
                  }
                }>
                <Element
                  key={`selection${i}`}
                  field={field}
                  control={control}
                  errors={errors}
                  handleCustomSelect={handleCustomSelect}
                  dynamicValidation={false}
                  isCustomDisable={false}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              </FormWrapper>
            );
          })
        }

        <SecondaryBtn
          canLoad={isLoading}
          label={t('search.label')}
          onPress={handleSubmit(retrieveValues)}
          style={{ height: 36, marginTop: -4 }}
          secondary={false}
        />
      </ResultSelectionFormWrapper>
      :
      <FormSpinnerView>
        <LoaderSpinner />
      </FormSpinnerView>
    )
  );
}

const ResultSelectionFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const FormWrapper = styled.div`
  flex-direction: row;
  z-index: 50;
  width: ${(props) => props.theme.rem(25)};
  margin-right: ${(props) => props.theme.rem(1.5)};
`;

const FormSpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.rem(75)};
  height: 81.4px;
`;
