/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { colors, fonts } from 'src/styles/theme/styles';

export const MobileArrowToSeeMore = (props: any) => {
  return (
    <View>
      <Text
        style={{
          fontSize: 20,
          color: colors.secondaryText,
          fontWeight: '400',
          lineHeight: 24,
          fontFamily: fonts.regular,
        }}>
        You've got
      </Text>
      <TouchableOpacity onPress={props.onPress}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 4,
          }}>
          <Text
            style={{
              color: colors.primary,
              fontSize: 32,
              lineHeight: 40,
              fontWeight: '500',
              fontFamily: fonts.semibold,
            }}>
            {props.text}
          </Text>
          <Text>
            <FontAwesomeIcon icon={faLongArrowRight} color={colors.secondaryText} size={30} />
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};
