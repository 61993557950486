import { createSearchFilter } from '.';
import { FILTERS } from '../../constant/index';

export function getUniversityFilter(searchValue: string) {
  let UNIVERSITY_FILTER = [] as any;
  if (searchValue) {
    UNIVERSITY_FILTER = [
      {
        field: 'alias',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return UNIVERSITY_FILTER;
}

export function getStreamFilter(searchValue: string) {
  let STREAM_FILTER = [] as any;
  if (searchValue) {
    STREAM_FILTER = [
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return STREAM_FILTER;
}

export function getCourseFilter(searchValue: string) {
  let COURSE_FILTER = [] as any;
  if (searchValue) {
    COURSE_FILTER = [
      {
        field: 'alias',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return COURSE_FILTER;
}

export function getClassFilter(searchValue: string) {
  let CLASS_FILTER = [] as any;
  if (searchValue) {
    CLASS_FILTER = [
      {
        field: 'alias',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return CLASS_FILTER;
}

export function getSubjectFilter(searchValue: string) {
  let SUBJECT_FILTER = [] as any;
  if (searchValue) {
    SUBJECT_FILTER = [
      {
        field: 'alias',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return SUBJECT_FILTER;
}

export function getSubjectGroupFilter(searchValue: string) {
  let SUBJECT_GROUP_FILTER = [] as any;
  if (searchValue) {
    SUBJECT_GROUP_FILTER = [
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return SUBJECT_GROUP_FILTER;
}

export function getUserFilter(searchValue: string) {
  let USER_FILTER = [] as any;
  if (searchValue) {
    USER_FILTER = [
      {
        field: 'firstName',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'lastName',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'userName',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'email',
        type: FILTERS.REGEX,
        value: searchValue,
      }
    ];
  }
  return USER_FILTER;
}

export function getInstituteFilter(searchValue: string) {
  let INSTITUTE_FILTER = [] as any;
  if (searchValue) {
    INSTITUTE_FILTER = [
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'domain',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return INSTITUTE_FILTER;
}

export function createQueryParameterForStudent(
  batch: string,
  classId: string,
  division: string,
  searchValue: any,
) {
  let studentFilter = {
    filters: {
      batch: {
        eq: batch,
      },
      class: {
        eq: classId,
      },
      division: {
        eq: division,
      },
    }
  } as any;

  let filters = [] as any;
  if (searchValue) {
    filters = [
      {
        field: 'firstName',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'lastName',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];

    let searchFilter = createSearchFilter(0, 0, filters);
    if (searchFilter?.filters) {
      studentFilter.filters = { ...studentFilter.filters, ...searchFilter.filters };
    }
  }

  return studentFilter;
}

export function getLanguageFilter(searchValue: string) {
  let LANGUAGE_FILTER = [] as any;
  if (searchValue) {
    LANGUAGE_FILTER = [
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return LANGUAGE_FILTER;
}

export function getKeyValueFilter(searchValue: string) {
  let KEY_VALUE_FILTER = [] as any;
  if (searchValue) {
    KEY_VALUE_FILTER = [
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return KEY_VALUE_FILTER;
}

export function getCommonNameSearchFilter(searchValue: string) {
  let FILTER = [] as any;
  if (searchValue) {
    FILTER = [
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return FILTER;
}

export function getCompositionNameSearchFilter(searchValue: string) {
  let FILTER = [] as any;
  if (searchValue) {
    FILTER = [
      {
        field: 'compositionName',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return FILTER;
}

export function getBanksFilter(searchValue: string) {
  let BANK_FILTER = [] as any;
  if (searchValue) {
    BANK_FILTER = [
      {
        field: 'name',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return BANK_FILTER;
}

export function getBatchFilter(searchValue: string) {
  let BATCH_FILTER = [] as any;
  if (searchValue) {
    BATCH_FILTER = [
      {
        field: 'batchAlias',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return BATCH_FILTER;
}

export function getStudentFilter(searchValue: string) {
  let STUDENT_FILTER = [] as any;
  if (searchValue) {
    STUDENT_FILTER = [
      {
        field: 'firstName',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'lastName',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return STUDENT_FILTER;
}

export function getApplicationIdSearchFilter(searchValue: string) {
  let FILTER = [] as any;
  if (searchValue) {
    FILTER = [
      {
        field: 'applicationId',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return FILTER;
}

export function getStudentFullNameFilter(searchValue: string) {
  let STUDENT_FILTER = [] as any;
  if (searchValue) {
    STUDENT_FILTER = [
      {
        field: 'fullName',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  }
  return STUDENT_FILTER;
}