import React from 'react';
import LargeButtonText from '../Text/LargeButtonText';
import {
  TouchableOpacity,
  StyleSheet,
  ViewStyle,
  StyleProp,
  View,
  ActivityIndicator,
} from 'react-native';
import NormaltextAtom from '../Text/NormalTextAtom';
import { useThemeSystem } from 'src/contexts/theme-context';
import { isWeb } from 'src/constant/device';
import MediumText from '../Text/MediumText';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

interface props {
  onPress: any;
  label: string;
  width?: number;
  style?: StyleProp<ViewStyle>;
  lineHeight?: number;
  secondary?: boolean;
  disabled?: boolean;
  canLoad?: boolean;
  lines?: number;
  customDisabled?: boolean;
  color?: string;
  icon?: any;
  iconColor?: string;
  fontSize?: number;
}

export default function SecondaryBtn(props: props) {
  const { theme } = useThemeSystem();
  const {
    onPress,
    label = 'Save',
    width,
    style,
    lineHeight = isWeb ? 2 : 3.2,
    secondary = true,
    disabled = false,
    canLoad = false,
    lines,
    customDisabled = false,
    color = theme?.button?.backgroundColor,
    icon,
    iconColor,
    fontSize
  } = props;

  const TouchableButton = styled.TouchableOpacity`
    padding: 6px 16px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    pointer-events: ${(disabled || customDisabled) ? 'none' : 'auto'};
  `;

  const LoadingBtnView = styled.View`
    width: ${width};
  `;

  return isWeb ? (
    <TouchableButton
      activeOpacity={1}
      disabled={canLoad}
      style={[styles.web, { width, backgroundColor: color, opacity: (customDisabled) ? 0.5 : 1 }, style]}
      onPress={onPress}>
      {canLoad ? (
        <LoadingBtnView>
          <ActivityIndicator animating={canLoad} size={18} color={colors.white} />
        </LoadingBtnView>
      ) : (
        <>
          {
            icon
              ? <FontAwesomeIcon icon={icon} color={iconColor} size={12} style={{ marginRight: 8 }} />
              : null
          }
          {secondary ? (
            <MediumText value={label} color={theme?.button?.color} lineHeight={lineHeight} lines={lines} />
          ) : (
            <NormaltextAtom value={label} color={theme?.button?.color} lineHeight={lineHeight} lines={lines} />
          )}
        </>
      )}
    </TouchableButton>
  ) : (
    <TouchableOpacity
      activeOpacity={1}
      disabled={disabled}
      style={[
        styles.btnView,
        styles.native,
        { width, backgroundColor: color, height: 48 },
        style,
      ]}
      onPress={onPress}>
      {canLoad ? (
        <ActivityIndicator animating={canLoad} size="small" color={colors.white} />
      ) : (
        <>
          {
            icon
              ? <FontAwesomeIcon icon={icon} color={iconColor} size={12} style={{ marginRight: 8 }} />
              : null
          }
          <LargeButtonText
            value={label}
            color={theme?.button?.color}
            lineHeight={lineHeight ? lineHeight : 3.2}
            fontSize={fontSize}
          />
        </>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  btnView: {
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  web: {
    display: 'flex',
    flexDirection: 'row',
  },
  native: {
    borderRadius: 32,
  },
});
