import React, { useState } from 'react';
import { colors, fontWeight, fonts } from 'src/styles/theme/styles';
import { height, isIOS, isWeb } from 'src/constant/device';

import MediumText from 'src/components/atoms/Text/MediumText';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import MobileHeader from 'src/components/hoc/MobileHeader';
import NormalModal from 'src/components/atoms/Modals/Normal/index.native';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import PaymentModal from 'src/components/molecules/Payment/Student/PaymentModal';
import { PaymentMode, USER_TYPE } from 'src/constant';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import styled from 'styled-components/native';
import { useForm } from 'react-hook-form';
import { useI18n } from 'src/i18n/hooks';
import { userInformation } from 'src/utils/manageState';
import { validation } from 'src/constant/validation';
import navigationStrings from 'src/navigation/navigationStrings';
import { ConcessionType, FeeBookType } from "src/components/organism/Payment/types"
import { showNativeError } from 'src/components/molecules/NotificationWrapper';
import { useCreateAdmissionFeePayment } from 'src/graphql/applications/applications';
import nttPaymentOptions from 'src/components/molecules/Payment/NTTPaymentOptions';
import { createPaymentDetailObject, properCase } from 'src/utils/utility';
import { paymentFormData, paymentPayload } from '../Admission/types';

interface propType {
  navigation?: any;
  route?: {
    params?: {
      feeBooks?: FeeBookType[];
      totalPayable?: number;
      applicationId: string;
      applicationIdForDisplay?: string;
      paymentModes: string[];
      partialPayment?: number;
      cancellationCharge?: number;
    };
  };
  data?: {
    id: string;
    applicantType: string;
    applicationId: string;
    user: { personalDetails: { firstName: string; lastName: string, fullName: string } };
  } | null;
  feeBooks?: FeeBookType[];
  totalPayable?: number;
  partialPayment?: number | null;
  cancellationCharge?: number | null;
}

const ApplicationPayableListing = (props: propType) => {
  const { data } = props;
  const feeBooks = isWeb ? props?.feeBooks : props?.route?.params?.feeBooks;
  const partialPayment = isWeb ? props?.partialPayment : props?.route?.params?.partialPayment;
  const cancellationCharge = isWeb ? props?.cancellationCharge : props?.route?.params?.cancellationCharge;
  const [makePaymentModal, setMakePaymentModal] = useState<boolean>(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [showQR, setShowQR] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState<any>();
  const [mode, setMode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<Object>({});
  const currentUserObject = userInformation();
  const { userType, userDetail } = currentUserObject;
  const totalPayable = isWeb
    ? props?.totalPayable
    : props?.route?.params?.totalPayable;
  const applId = isWeb ? props.data?.id : props?.route?.params?.applicationId;
  const applicationIdForDisplay = isWeb ? props.data?.applicationId : props?.route?.params?.applicationIdForDisplay;
  const { displayAtomPayNetzPortalNative } = nttPaymentOptions(props);
  const paymentModes = props?.route?.params?.paymentModes;

  const { t } = useI18n();
  const { createAdmissionFeePayment, createAdmissionFeePaymentData } = useCreateAdmissionFeePayment();
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  function sortFeeGroupsByOrderNo(feeGroups: FeeBookType[]): FeeBookType[] {
    return feeGroups.sort((a, b) => a.orderNo - b.orderNo);
  }

  const resetStatusForm = () => {
    setValue("mode", null);
    setValue("cheque_number", "");
    setValue("draft_number", "");
    setValue("payment_amount", "");
    setValue("neft_transaction_id", "");
  };

  const closeModal = () => {
    resetStatusForm();
    reset({});
    clearErrors();
    setPaymentData(null);
    setShowQR(false);
    setMakePaymentModal(false);
    setMode("");
    setErrorMessage({});
  };

  const sortedData = feeBooks ? sortFeeGroupsByOrderNo([...feeBooks]) : [];

  function onPaymentSuccess(): void {
    props.navigation.navigate(navigationStrings.APPLICATION_LISTING, {
      paymentSuccessful: true
    })
  }

  async function handleApplicantAdmissionFeePayment() {
    setCanClick(false);
    try {
      const admissionFeePaymentResponse = await createAdmissionFeePayment({
        variables: {
          applicationId: applId,
        }
      });
      if (admissionFeePaymentResponse?.data?.createAdmissionFeeOrder) {
        const resp = admissionFeePaymentResponse?.data?.createAdmissionFeeOrder;
        closeModal();
        displayAtomPayNetzPortalNative(resp, onPaymentSuccess);
      }
      setCanClick(true);
    } catch (e: any) {
      setErrorMessage({
        message: e.message,
        type: 'danger',
        position: 'top',
        icon: 'danger',
        duration: 600
      });
      setCanClick(true);
    }
  }

  async function handleMakePayment(formData: paymentFormData) {
    const mode = isWeb ? formData.mode.value : formData.mode;
    if (mode === PaymentMode.ONLINE && applId) {
      handleApplicantAdmissionFeePayment();
    } else {
      setCanClick(false);
      let paymentData: paymentPayload = {
        applicationId: applId,
        paymentDetail: {
          mode: mode,
        },
      }

      paymentData = await createPaymentDetailObject(formData, paymentData, false);
      setPaymentData(paymentData);
      setShowQR(true);
      setCanClick(true);
    }
  }

  const showApplicantDetails: boolean = Boolean(
    data && Object.keys(data)?.length > 0
  );

  function isSingularListing(item: FeeBookType): boolean {
    const child = item.fees[0];
    return item.groupName === child.name && item.payable === child.payable && item.orderNo === child.orderNo;
  }

  const TableHead = () => {
    return (
      <TableHeadWrapper>
        <NormaltextAtom
          value={t("payment.particulars")}
          color={colors.secondaryText}
          fontWeight={fontWeight[600]}
        />
        <NormaltextAtom
          value={t("payment.amount")}
          color={colors.secondaryText}
          fontWeight={fontWeight[600]}
        />
      </TableHeadWrapper>
    );
  };

  const TableRows = ({ row }: { row: FeeBookType[] }) => {
    return (
      <TableRowsWrapper showsVerticalScrollIndicator={false}>
        {row.map((item: FeeBookType) => {
          return (
            <>
              <GroupHeaderWrapper>
                <ModuleNameWrapper>
                  <MediumText
                    lines={1}
                    value={item.groupName}
                    lineHeight={2.4}
                    color={colors.primaryText}
                  />
                </ModuleNameWrapper>
                <MediumTextSelect
                  fontFamily={fonts.semibold}
                  value={`\u20B9  ${item.payable}`}
                  color={colors.primaryText}
                />
              </GroupHeaderWrapper>
              {item?.fees &&
                item.fees.length > 0 &&
                item.fees.map((fee) => {
                  return (
                    <>
                      {(item.fees.length > 1 ||
                        item.fees[0].concessions.length > 0 ||
                        !isSingularListing(item)) && (
                          <RowItem>
                            <ModuleNameWrapper>
                              <NormaltextAtom
                                lines={1}
                                lineHeight={2}
                                value={fee.name}
                                fontSize={1.6}
                              />
                            </ModuleNameWrapper>
                            <NormaltextAtom
                              lineHeight={2}
                              value={`\u20B9 ${fee.amount}`}
                              fontSize={1.6}
                            />
                          </RowItem>
                        )}
                      {fee?.concessions?.length > 0 &&
                        fee.concessions.map((c: ConcessionType) => (
                          <>
                            <RowItem>
                              <ModuleNameWrapper>
                                <NormaltextAtom
                                  lines={1}
                                  lineHeight={2}
                                  value={c.name}
                                  fontSize={1.6}
                                />
                              </ModuleNameWrapper>
                              <NormaltextAtom
                                lineHeight={2}
                                value={`- \u20B9 ${c.amount}`}
                                fontSize={1.6}
                              />
                            </RowItem>
                          </>
                        ))}
                    </>
                  )
                })}
            </>
          )
        })}
      </TableRowsWrapper>
    )
  }

  const PaymentTotal = () => {
    return (
      <TotalWrapper>
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={cancellationCharge ? t('courseFee.label') : t('totalPayment.label')}
          color={colors.primaryText}
        />
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={`\u20B9 ${totalPayable}`}
          color={colors.primaryText}
        />
      </TotalWrapper>
    );
  };

  const PartialPayment = () => {
    return (
      <PartialPaymentWrapper>
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={t("partialPayableAmount.label")}
          color={colors.primaryText}
        />
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={`\u20B9 ${partialPayment}`}
          color={colors.primaryText}
        />
      </PartialPaymentWrapper>
    );
  };
  
  const CancellationCharge = () => {
    return (
      <PartialPaymentWrapper>
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={t("cancellationCharge.label")}
          color={colors.primaryText}
        />
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={`\u20B9 ${cancellationCharge}`}
          color={colors.primaryText}
        />
      </PartialPaymentWrapper>
    );
  };

  return isWeb ? (
    <Container showApplicantDetails={showApplicantDetails}>
      {showApplicantDetails && (
        <HeaderWrapper>
          <HeaderItem>
            <HeaderLabel>
              <NormalTextSelect
                color={colors.secondaryText}
                value={t("name.label")}
              />
            </HeaderLabel>
            <NormalTextSelect
              value={properCase(data?.user?.personalDetails?.fullName || '-')}
              fontWeight={600}
              fontFamily={fonts.semibold}
            />
          </HeaderItem>
          <HeaderItem>
            <HeaderLabel>
              <NormalTextSelect
                color={colors.secondaryText}
                value={t("applicantType.label")}
              />
            </HeaderLabel>
            <NormalTextSelect
              value={data?.applicantType}
              fontWeight={600}
              fontFamily={fonts.semibold}
            />
          </HeaderItem>
        </HeaderWrapper>
      )}
      <TableHead />
      <TableBodyWrapper nestedScrollEnabled={true}>
        {sortedData && sortedData?.length > 0 && (
          <>
            <TableRows row={sortedData} />
            <PaymentTotal />
            {partialPayment && !cancellationCharge && (
              <PartialPayment />
            )}
            {cancellationCharge && (
              <CancellationCharge />
            )}
          </>
        )}
      </TableBodyWrapper>
    </Container>
  ) : (
    <NativeContainer>
      <MobileHeader
        label={t("payment.payment-information")}
        navigation={props.navigation}
      />
      <TableWrapper>
        <TableHead />
        <TableBodyWrapper nestedScrollEnabled={true}>
          {sortedData && sortedData?.length > 0 && (
            <>
              <TableRows row={sortedData} />
              <PaymentTotal />
              {partialPayment && !cancellationCharge && (
                <PartialPayment />
              )}
              {cancellationCharge && (
                <CancellationCharge />
              )}
              <BtnWrapper>
                <SecondaryBtn
                  onPress={() => setMakePaymentModal(true)}
                  label={t("payment.proceed-payment")}
                />
              </BtnWrapper>
            </>
          )}
        </TableBodyWrapper>
      </TableWrapper>
      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        modalVisible={makePaymentModal}
        handleSave={handleSubmit(handleMakePayment)}
        Headerpopup={showQR ? t("qrScan.label") : t("payment.mode")}
        addEditButtonLabel={
          mode === PaymentMode.ONLINE || mode === ""
            ? t("payment.proceed.label")
            : t("generate-qr.label")
        }
        hideSubmit={!showQR}
        width={544}
        height={512}
        maxWidth={"md"}
        paymentMode={true}
        message={errorMessage}
      >
        <PaymentModal
          isSubmitting={!canClick}
          control={control}
          errors={errors}
          reset={reset}
          data={paymentData}
          showQR={showQR}
          setValue={setValue}
          setMode={setMode}
          clearErrors={clearErrors}
          totalPayableAmount={
            cancellationCharge ? cancellationCharge : partialPayment ? partialPayment : totalPayable
          }
          paymentModes={paymentModes}
        />
      </NormalModal>
    </NativeContainer>
  );
};

export default ApplicationPayableListing;

const Container = styled.View<{ showApplicantDetails: boolean }>`
  margin-top: ${(pr) => (pr.showApplicantDetails ? "0px;" : "-24px")};
`;

const HeaderWrapper = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

const HeaderItem = styled.View`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const HeaderLabel = styled.View`
  margin-bottom: 3px;
`;

const TableWrapper = styled.SafeAreaView`
  flex: 1;
  margin-horizontal: 24px;
`;

const NativeContainer = styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

const TableRowsWrapper = styled.ScrollView`
  max-height: ${height - (isWeb ? 450 : isIOS ? 322 : 304)};
  padding-horizontal: ${isWeb ? "16px" : "0px"};
`;

const TableHeadWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 8px;
  padding-horizontal: ${isWeb ? "16px" : "0px"};
  margin-bottom: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const TableBodyWrapper = styled.ScrollView``;

const BtnWrapper = styled.View`
  padding-bottom: 24px;
`;

const GroupHeaderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TotalWrapper = styled.View`
  border-top-width: 1px;
  border-top-color: ${colors.dividerColor};
  padding-vertical: ${isWeb ? "16px" : "24px"};
  padding-horizontal: ${isWeb ? "16px" : "0px"};
  flex-direction: row;
  justify-content: space-between;
`;

const RowItem = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-left: 24px;
  margin-bottom: 24px;
`;

const ModuleNameWrapper = styled.View`
  display: flex;
  align-items: flex-start;
  width: ${isWeb ? '80%' : '60%'};
  padding-right: 8px;
`;

const PartialPaymentWrapper = styled(TotalWrapper)`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
  margin-bottom: 24px;
`;