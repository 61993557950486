import { jobTypes } from './dd-options';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import dataTypes from '@data-driven-forms/react-form-renderer/data-types';
import { regexObject } from 'src/constant/regex';
import { FormSchema, ViewMode } from 'src/components/molecules/Forms/types';
import { getComponentType, resolveOthers, setOtherInitialValue } from './util';
import { FormOptions } from '@data-driven-forms/react-form-renderer/renderer-context';

const ListViewcolumns = [
  {
    "id": "indicatorBadge",
    "name": ""
  },
  {
    "id": "employer",
    "name": "CompanyName.label"
  },
  {
    "id": "jobtype",
    "name": "form.jobType.label"
  },
  {
    "id": "designation",
    "name": "form.designation.label"
  },
  {
    "id": "income",
    "name": "income.label"
  },
  {
    "id": "documents",
    "name": "form.documents.label"
  },
  {
    "id": "actions",
    "name": "actions.label"
  }
]

export const experience = (viewMode: ViewMode, isInstituteAdmin?: boolean): FormSchema => ({
  name: 'experienceDetails',
  label: 'addExperienceDetails.text',
  viewMode,
  listOptions: {
    iconName: 'experience',
    deleteMessageTitleLabel: 'experienceDetailsDelete.text',
    deleteMessageLabel: 'experienceDetailsDelete.text1',
    columns: ListViewcolumns,
  },
  headerData: {
    title: 'experienceDetails.label',
    skip: true,
    icon: require('src/assets/common/headerOnboardingIcons/v1/experience-3x.png'),
    progress: 0.8,
  },
  helpers: (detail: any) => ({
    title: `${detail?.employer || "Not Set"}`,
    subtitle: detail?.designation || '',
    content1: `Job Type - ${detail?.jobtype || "Not Set"}`,
    content2: '',
  }),
  fields: [
    {
      name: 'basic-experience-details',
      label: 'experienceDetails.label',
      component: "three-column-custom-sub-form",
      fields: [
        {
          name: 'employer',
          label: 'form.company.label',
          component: getComponentType(viewMode, 'custom-text-field'),
          isRequired: true,
          autoCapitalize: "words",
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.companyName.required',
            },
          ] : [],
        },
        {
          name: 'jobtype',
          label: 'form.jobType.required.label',
          component: getComponentType(viewMode, "select"),
          options: jobTypes,
          isRequired: true,
          otherFieldName: "otherJobType",
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.jobType.required',
            },
          ] : [],
          resolveProps: (props, field, formOptions) => {
            return resolveOthers(props, field, formOptions, viewMode);
          },
        },
        {
          name: 'otherJobType',
          label: "form.others.required.text",
          isRequired: true,
          ignoreField: true,
          ignoreInProfileView: true,
          type: 'text',
          autoCapitalize: "words",
          component: viewMode == ViewMode.READ ? 'none' : 'custom-text-field',
          condition: {
            when: "jobtype",
            is: "Others"
          },
          setInitialValue: (props: any, formOptions: FormOptions) => {
            setOtherInitialValue(props, formOptions, 'jobtype', 'otherJobType');
          },
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.jobType.required',
            },
          ] : [],
        },
        {
          name: 'designation',
          label: 'form.designation.required.label',
          component: getComponentType(viewMode, 'custom-text-field'),
          isRequired: true,
          autoCapitalize: "words",
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.designation.required',
            },
          ] : [],
        },
        {
          name: 'income',
          label: 'form.salary.label',
          component: getComponentType(viewMode, 'custom-text-field'),
          isRequired: false,
          type: "number",
          dataType: dataTypes.INTEGER,
          validate: [
            {
              type: validatorTypes.PATTERN,
              pattern: regexObject.INTGER_ONLY,
              message: 'error.salary.invalid',
            }
          ]
        },
      ],
    },
    {
      name: "headerField",
      component: "header-title",
      label: "documents.label",
      fields: [],
    },
    {
      name: 'employeeDocument',
      label: 'form.documents.label',
      component: "three-column-custom-sub-form",
      fields: [
        {
          name: 'Relieving',
          label: 'form.relievingLetter.label',
          fileAtfield: 'documents',
          component: getComponentType(viewMode, "file-upload"),
          type: 'file',
          isRequired: false,
          required_error: 'error.relievingLetter.required',
          category: 'Experience',
          documentType: 'Relieving',
          validate: [
            { type: "file-size", maxSize: 20 * 1024 * 1024 },
            { type: "file-type" }
          ],
        },
        {
          name: 'Experience',
          label: 'form.experienceLetter.label',
          fileAtfield: 'documents',
          component: getComponentType(viewMode, "file-upload"),
          type: 'file',
          required: false,
          isRequired_error: 'error.experienceLetter.required',
          category: 'Experience',
          documentType: 'Experience',
          validate: [
            { type: "file-size", maxSize: 20 * 1024 * 1024 },
            { type: "file-type" }
          ],
        },
      ],
    },
  ],
})
