import React, { useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import cohortAudience from 'src/form-json/communication/cohort-audience.json';
import Element from 'src/components/molecules/Forms/ApplicationElement';
import { useQuery } from '@apollo/client';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { autoCompleteLimit, AUTOCOMPLETE_MODULE } from 'src/constant';
import { clipLongText, mergeBatchClassLabel } from 'src/utils/utility';
import { studentGroupsQuery } from 'src/graphql/student-group';
import { isWeb } from 'src/constant/device';
import { DropdownOptionsType } from 'src/types';
import { graphqlQuery } from 'src/graphql/util';
import { colors } from 'src/styles/theme/styles';
import Chip from 'src/components/atoms/Chip';
import { useThemeSystem } from 'src/contexts/theme-context';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form/dist/types';

interface CohortProps {
  cohortFormControl: Control<FieldValues, object>;
  cohortFormError: any;
  setCohortFormValue: UseFormSetValue<FieldValues>;
  handleChecked: Function;
  audienceData:Array<string>;
  removeAudience: Function;
  parentType: string;
  setAudienceData: Function;
}

export default function Cohort(props: CohortProps) {
  const {
    cohortFormControl,
    cohortFormError,
    setCohortFormValue,
    handleChecked,
    audienceData,
    removeAudience,
    parentType
  } = props;

  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};
  const [batch, setBatch] = useState<any>({});
  const [batches, setBatches] = useState<any>({});
  const [division, setDivision] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const { theme } = useThemeSystem();

  const allBatches = useQuery(getBatchQuery, {
    variables: { limit: autoCompleteLimit, skip: 0 },
  });

  useEffect(() => {
    onLoadFunction();
  }, [allBatches.data]);

  useEffect(() => {
    setElements(cohortAudience[0]);
  }, []);

  useEffect(() => {
    if (batch && batch.class) {
      setTimeout(() => {
        loadDivisions(batch.divisionDetails?.map((det) => det.division));
      });
    }
  }, [batch]);

  useEffect(() => {
    loadSelectedGroups();
  }, [audienceData]);

  function loadDivisions(divisionArray: DropdownOptionsType[]) {
    setElements({});
    let options = [] as any;
    divisionArray.forEach(function (item: any) {
      options.push({
        value: item,
        label: item,
      });
    });
    cohortAudience[0].fields[1].option = options;
    setElements(cohortAudience[0]);
  }

  function onLoadFunction() {
    if (allBatches.loading === false) {
      const getdata = mergeBatchClassLabel(allBatches.data?.batches?.data);
      cohortAudience[0].fields[0].option = getdata;
      setBatches(getdata);
      setElements(cohortAudience[0]);
    }
  }

  async function selectDivision(data: any) {
    const division = isWeb ? data?.value : data;
    setDivision(division);
    setGroups([]);
    setFilteredGroups([]);
    if (division) {
      let cohortFilter = {
        batch: {
          eq: batch?.value
        },
        division: {
          eq: division
        }
      }
      let { data: studentGroups, loading } = await graphqlQuery(
        studentGroupsQuery,
        { filters: cohortFilter, limit: 0 }
      );
      if (!loading && studentGroups?.studentGroups) {
        let groups = studentGroups?.studentGroups?.data;
        setGroups(groups);
        setFilteredGroups(groups);
        setIsDataAvailable(true)
      }
    } else {
      setGroups([]);
      setFilteredGroups([]);
    }
  }

  async function handleCustomSelect(id: string, data: any) {
      if (id === AUTOCOMPLETE_MODULE.BATCH) {
      setIsDataAvailable(false)
        setDivision(null);
        setFilteredGroups([]);
        setSelectedGroups([]);
        data = isWeb ? data : batches.find((bt: any) => bt.value === data);
        setCohortFormValue(AUTOCOMPLETE_MODULE.DIVISION, null);
        setBatch(data);
      }
  
      if (id === AUTOCOMPLETE_MODULE.DIVISION) {
      setIsDataAvailable(false)
        selectDivision(data);
      }
  }

  const handleCheckChild = (item: string) => {
    handleChecked(item, batch, null, division);
  }

  function loadSelectedGroups() {
    if (audienceData && audienceData?.length > 0) {
      let groups = audienceData.filter((item: any) => {
        const batchId = item.audience.split('/')[1];
        const divisionId = item.audience.split('/')[2];
        return batchId === batch?.value && divisionId === division;
      });
      groups = groups.map((item: any) => item.audience.split("/").pop());
      setSelectedGroups(groups);
    } else {
      setSelectedGroups([]);
    }
  }

  return (
    <>
      <View style={styles.containerView}>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View key={`parentele${i}`}>
              <Element
                key={i}
                field={field}
                control={cohortFormControl}
                errors={cohortFormError}
                handleCustomSelect={handleCustomSelect}
                checked={selectedGroups}
                checkboxTableData={filteredGroups}
                handleCheck={handleCheckChild}
                checkboxTableHeight={'auto'}
                checkboxTableMaxHeight={300}
                leftLabel={'COHORT'}
                manualSelection={false}
                isDataAvailable={isDataAvailable}
              />
            </View>
          ))
        ) : null}
      </View>
      <View style={[styles.chipWrapper, { marginTop: theme?.input?.marginBottom }]}>
        {audienceData && audienceData.length > 0 &&
          audienceData?.map((chip: any, index: number) => (
            <View style={[styles.chipView]} key={`audience${index}`}>
              <Chip
                label={clipLongText(chip?.text, 36)}
                onPress={() => removeAudience(index)}
                borderRadius={isWeb ? 4 : 20}
                chipFontSize={1.2}
              />
            </View>
          ))}
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
  containerView: {
    borderWidth: 1,
    borderColor: colors.borderColor,
    borderStyle: 'dashed',
    borderRadius: 4,
    padding: 8
  },
  chipWrapper: { flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 },
  chipView: { marginRight: 8, marginBottom: 8 },
});
