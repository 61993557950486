import React, { Fragment, useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useI18n } from 'src/i18n/hooks';
import { height, isIOS, isWeb } from 'src/constant/device';
import MobileHeader from 'src/components/hoc/MobileHeader';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { graphqlQuery } from 'src/graphql/util';
import { dayWiseAttendence } from 'src/graphql/attendance';
import ListNative from 'src/components/atoms/AttendanceList/index.native';
import navigationStrings from 'src/navigation/navigationStrings';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import { colors } from 'src/styles/theme/styles';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { userInformation } from 'src/utils/manageState';
import AttendanceNotConfigured from '../AttendanceNotConfigured';
import DateTimePicker from 'src/components/atoms/DateTime/DateTimePicker.native';
import { DT } from 'src/constant/dateTime';
import { endOfDay, endOfMonth, endOfWeek, format, parse, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { properCase, stableSortStrings } from 'src/utils/utility';
import styled from 'styled-components/native';
import LeaveCalendar from 'src/components/atoms/Calendars/LeaveCalendar';
import { generateMarkedDatesObject } from 'src/components/atoms/Calendars/CalendarHelpers';
import { filterAbsentDates } from 'src/components/molecules/Attendance/History/AttendanceHelpers';
import { AttendanceData, DayWiseAttendanceListData, SelectedDatesState } from 'src/components/molecules/Attendance/AttendanceTypes';
import { DurationTypeValue, getDurationLabel, getEndDateFunction, getStartDateFunction } from './helpers';

export default function StudentsForClassAttendanceListComponent(props) {
  const { route, navigation } = props;
  const {
    batch,
    division,
    attendanceType,
    audienceType,
    selectedDurationType,
    selectedStudentInfo,
    slotInfo,
    datesinfo,
  } = route?.params;

  const { t } = useI18n();
  const timezone = DT.TIMEZONE;
  const currentDate: Date = new Date();
  const currentUserObject = userInformation();

  const [currentAttendanceDataList, setCurrentAttendanceDataList] = useState<AttendanceData[] | null | []>(null);
  const [calendarData, setCalendarData] = useState<null | []>(null);
  const [dates, setDates] = useState<SelectedDatesState | null>(null);
  const [dateString, setdateString] = useState<string>(format(currentDate, DT.MONTH_NAME_YEAR));
  const [totalPresents, setTotalPresents] = useState<number>(0);
  const [totalAttendance, setTotalAttendance] = useState<number>(0);

  function namingSlotsInfo() {
    const weeklyOrMonthly = t(getDurationLabel(selectedDurationType?.value));
    let slotsName = '';
    if (attendanceType.value === 0) {
      slotsName = t('fullDay.label');
    } else if (attendanceType.value === 1) {
      slotsName = slotInfo ? slotInfo.slotname : t('slots.label');
    } else {
      slotsName = slotInfo ? slotInfo.slotname : t('subjects.label');
    }
    return weeklyOrMonthly + ' - ' + slotsName;
  }

  const dateChangeHandler = (changedDate: string | Date) => {
    if (!selectedDurationType || selectedDurationType.value === 1) {
      // Case for Duration type === MONTHLY; type of param changedDate == js Date
      const newDate = format(changedDate, DT.DATE_FORMAT_SLASH, currentDate);
      const endDate = format(endOfMonth(changedDate), DT.DATE_FORMAT_SLASH);

      setDates((prev) => {
        if (prev) {
          if (prev.startDate !== newDate || prev.endDate !== endDate) {
            if (selectedStudentInfo) {
              setCalendarData(null);
            } else {
              setCurrentAttendanceDataList(null);
            }
          }
        }

        return {
          startDate: newDate,
          endDate: endDate,
          currentDate: newDate,
        };
      });

      const parsedNewDate = parse(newDate, DT.DATE_FORMAT_SLASH, currentDate);
      setdateString(format(parsedNewDate, DT.MONTH_NAME_YEAR));
    } else if (selectedDurationType?.value === 2) {
      const newDate = format(startOfDay(changedDate), DT.DATE_FORMAT_SLASH);
      const endDate = format(endOfDay(changedDate), DT.DATE_FORMAT_SLASH);

      setDates((prev) => {
        if (prev) {
          if (prev.startDate !== newDate || prev.endDate !== endDate) {
            if (selectedStudentInfo) {
              setCalendarData(null);
            } else {
              setCurrentAttendanceDataList(null);
            }
          }
        }

        return {
          startDate: newDate,
          endDate: endDate,
          currentDate: newDate,
        };
      });

      const parsedNewDate = parse(newDate, DT.DATE_FORMAT_SLASH, currentDate);
      setdateString(format(parsedNewDate, DT.DATE_ABBREVIATED_MONTH_YEAR));
    } else {
      // Case for Duration type === WEEKLY; type of param changedDate == string type with - 
      const splittedDate = changedDate.split(' - ');
      setDates((prev) => {
        if (prev) {
          if (prev.startDate !== splittedDate[0] || prev.endDate !== splittedDate[1]) {
            if (selectedStudentInfo) {
              setCalendarData(null);
            } else {
              setCurrentAttendanceDataList(null);
            }
          }
        }

        return {
          ...prev,
          startDate: splittedDate[0],
          endDate: splittedDate[1],
          currentDate: splittedDate[0],
        };
      });
      const parsedStartDate = parse(splittedDate[0], DT.DATE_FORMAT_SLASH, currentDate);
      const parsedEndDate = parse(splittedDate[1], DT.DATE_FORMAT_SLASH, currentDate);
      setdateString(getFormattedDateString(parsedStartDate, parsedEndDate));
    }
  };

  function setDayWiseAttendanceData(list: DayWiseAttendanceListData[], addDataInCurrentAttendanceDataList: boolean) {
    const arr: AttendanceData[] = [];

    if (list.length > 0) {
      let globalPresentsCount = 0;
      let globalTotalAttendanceCount = 0;

      list.forEach((element: any) => {
        if (element.data.length > 0) {
          if (addDataInCurrentAttendanceDataList) {
            arr.push({
              id: element.user.id,
              rollNo: element?.user?.academic?.rollNo,
              heading: properCase(element?.user?.personalDetails?.fullName),
              attend: element.present,
              total: element.total,
              percentage: ((element.present / element.total) * 100).toFixed(0),
            });
          }
          globalPresentsCount += element.present;
          globalTotalAttendanceCount += element.total;
        }
      });

      setTotalAttendance(globalTotalAttendanceCount);
      setTotalPresents(globalPresentsCount);
      if (addDataInCurrentAttendanceDataList) {
        setCurrentAttendanceDataList(stableSortStrings(arr, 'rollNo'));
      }
    }
  }

  async function getDayWiseAttendaceData() {
    if (slotInfo && batch && division.length > 0 && dates) {
      const payload = {
        batch: batch?.value,
        class: batch?.class?.id,
        division: division,
        start: dates.startDate,
        end: dates.endDate,
        slot: slotInfo?.id,
        timezone,
      };
      setTotalAttendance(0);
      setTotalPresents(0);
      const { loading, data: currentAttendanceData } = await graphqlQuery(
        dayWiseAttendence,
        {
          payload: selectedStudentInfo ? { ...payload, user: selectedStudentInfo.value } : payload,
        },
        true,
      );

      if (!loading && currentAttendanceData?.dayWiseAttendance?.length) {
        if (selectedStudentInfo) {
          setCalendarData(currentAttendanceData?.dayWiseAttendance);
          setDayWiseAttendanceData(currentAttendanceData?.dayWiseAttendance, false);
          setCurrentAttendanceDataList([]);
        } else {
          setDayWiseAttendanceData(currentAttendanceData?.dayWiseAttendance, true);
          setCalendarData([]);
        }
      } else {
        setCalendarData([]);
        setDayWiseAttendanceData([], false);
        setCurrentAttendanceDataList([]);
      }
    }
  }

  function noDataHandler() {
    if (selectedStudentInfo) {
      if (calendarData.length == 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (currentAttendanceDataList.length == 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  function getFormattedDateString(startDate: Date, endDate: Date): string {
    return `${format(startDate, DT.DATE_ABBREVIATED_MONTH_YEAR)} - ${format(endDate, DT.DATE_ABBREVIATED_MONTH_YEAR)}`
  }

  useEffect(() => {
    const selectedDurationTypeValue = DurationTypeValue[selectedDurationType?.value];
    const startFunction = getStartDateFunction(selectedDurationTypeValue);
    const endFunction = getEndDateFunction(selectedDurationTypeValue);
    setDates(() => {
      const startdateAccordingToDurationType = datesinfo
        ? datesinfo.startDate
        : format(startFunction(currentDate), DT.DATE_FORMAT_SLASH);
      const enddateAccordingToDurationType = datesinfo
        ? datesinfo.endDate
        : format(endFunction(currentDate), DT.DATE_FORMAT_SLASH);
      const currentDateAccordingToDurationType = datesinfo
        ? datesinfo.currentDate
        : startdateAccordingToDurationType;

      const parsedStartDate = parse(startdateAccordingToDurationType, DT.DATE_FORMAT_SLASH, currentDate);
      const parsedEndDate = parse(enddateAccordingToDurationType, DT.DATE_FORMAT_SLASH, currentDate);
      const parsedCurrentDate = parse(currentDateAccordingToDurationType, DT.DATE_FORMAT_SLASH, currentDate);
      setdateString(
        selectedDurationTypeValue === 'week'
          ? getFormattedDateString(parsedStartDate, parsedEndDate)
          : selectedDurationTypeValue === 'month'
          ? format(parsedCurrentDate, DT.MONTH_NAME_YEAR)
          : format(parsedStartDate, DT.DATE_ABBREVIATED_MONTH_YEAR)
      );
      return {
        startDate: startdateAccordingToDurationType,
        endDate: enddateAccordingToDurationType,
        currentDate: currentDateAccordingToDurationType,
      };
    });
  }, [selectedDurationType, datesinfo]);

  useEffect(() => {
    getDayWiseAttendaceData();
  }, [selectedDurationType, audienceType, slotInfo, selectedStudentInfo, dates]);

  return (
    <>
      <Fragment>
        <MobileHeader
          label={
            attendanceType.value === 0
              ? t('fullDay.label') + ' ' + t('history.label')
              : attendanceType.value === 2
                ? t('subject.label') + ' ' + t('history.label')
                : t('slot.label') + ' ' + t('history.label')
          }
          backIcon={true}
          {...props}
        />
        <View style={styles.container}>
          {!currentUserObject || !dates ? (
            <View style={styles.spinnerView}>
              <LoaderSpinner />
            </View>
          ) : (
            <View>
              <View style={styles.webWrapper}>
                {currentUserObject.userType === 2 && (
                  <View style={{ marginBottom: 16 }}>
                    <HeaderFive
                      lines={1}
                      value={
                        selectedStudentInfo
                          ? selectedStudentInfo.label
                          : batch?.label + ' - ' + batch?.class?.alias + ' - ' + division
                      }
                      color={colors.primaryText}
                      fontWeight={'400'}
                    />
                    {selectedStudentInfo !== null && (
                      <NormalTextSelect
                        lines={1}
                        value={batch?.label + ' - ' + batch?.class?.alias + ' - ' + division}
                        fontWeight={'600'}
                        color={colors.tertiaryText}
                      />
                    )}
                  </View>
                )}
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <AnimatedCircularProgress
                    style={{ marginRight: 16 }}
                    size={80}
                    width={3}
                    fill={(totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) * 100}
                    tintColor="#2B78CA"
                    backgroundColor="transparent">
                    {(fill) => (
                      <View
                        style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <SmallTextAtom
                          value={t('present.label')}
                          color={colors.primary}
                          fontWeight={'600'}
                        />
                        <MediumTextSelect
                          value={`${(
                            (totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) *
                            100
                          ).toFixed(0)}%`}
                          color={colors.primary}
                          fontWeight={'600'}
                          lineHeight={2.4}
                        />
                      </View>
                    )}
                  </AnimatedCircularProgress>
                  <View
                    style={{
                      flexDirection: 'column',
                      flex: 1,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        paddingRight: 5,
                      }}>
                      <MediumTextSelect
                        lines={1}
                        value={namingSlotsInfo()}
                        color={colors.primaryText}
                        fontWeight={'600'}
                        lineHeight={2.4}
                      />
                    </View>
                    <View style={styles.calendarIcon}>
                      {/* selectedDurationType?.value === 0 ==> Week Picker will open Date picker and auto infer start and end dates */}
                      <DateTimePicker
                        dateTimeMode={
                          selectedDurationType?.value === 0 || selectedDurationType?.value === 2
                            ? 'date'
                            : 'monthyear'
                        }
                        dateTimePickerTitle={t("form.duration.label")}
                        standalonePicker={true}
                        disableFuture={true}
                        onDateTimeChangeCallback={(selectedDate: Date) => {
                          if (selectedDurationType?.value === 0) {
                            // Week Picker
                            const weekRange = `${format(startOfWeek(selectedDate), DT.DATE_FORMAT_SLASH)} - ${format(endOfWeek(selectedDate), DT.DATE_FORMAT_SLASH)}`
                            dateChangeHandler(weekRange);
                          } else {
                            // month picker
                            dateChangeHandler(selectedDate)
                          }
                        }}
                      />
                      <NormalTextSelect
                        value={dateString}
                        fontWeight={'600'}
                        color={colors.tertiaryText}
                        style={[styles.monthDisplayText, { marginTop: selectedDurationType?.value === 0 ||selectedDurationType?.value === 2 ? (isIOS ? -2 : 2) : (isIOS ? 2 : -26) }]}
                      />
                    </View>
                  </View>
                </View>

                {!currentAttendanceDataList || !calendarData ? (
                  <View style={styles.spinnerView}>
                    <LoaderSpinner />
                  </View>
                ) : noDataHandler() ? (
                  <AttendanceNotConfigured topPosition="20%" type={1} />
                ) : (
                  <CalendarContainer>
                    {selectedStudentInfo !== null &&
                      calendarData &&
                      calendarData?.length === 1 &&
                      calendarData[0]?.data &&
                      dates?.startDate ? (
                      <LeaveCalendarWrapper>
                        <LeaveCalendar
                          initialDate={parse(dates.startDate, DT.DATE_FORMAT_SLASH, new Date())}
                          markedDates={generateMarkedDatesObject(
                            filterAbsentDates(calendarData[0].data)
                          )}
                        />
                      </LeaveCalendarWrapper>
                    ) : (
                      <ListNative
                        listData={currentAttendanceDataList}
                        navigation={navigation}
                        totalPresents={totalPresents}
                        totalAttendance={totalAttendance}
                        attendanceType={attendanceType}
                        shouldNavigate={false}
                        routeName={navigationStrings.CLASS_STUDENTS_ATTENDANCE_HISTORY}
                        listContainerHeightAndroid={selectedStudentInfo ? height - 285 : height - 250}
                        listContainerHeightIos={selectedStudentInfo ? height - 295 : height - 270}
                      />
                    )}
                  </CalendarContainer>
                )}
              </View>
            </View>
          )}
        </View>
      </Fragment>
    </>
  );
}

const LeaveCalendarWrapper = styled.View``;

const CalendarContainer = styled.View`
  margin-top: 16px;
`;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: '100%',
  },
  webWrapper: {
    margin: 24,
    height: isIOS ? height - 184 : height - 60,
  },
  spinnerView: {
    width: isWeb ? 450 : 'auto',
    height: isWeb ? 547 : height - 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  calendarIcon: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  monthDisplayText: {
    marginLeft: 8,
  },
});
