/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import Element from '../Forms/ApplicationElement';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
// === Legacy Login === It should be refactored or removed in the future.
// import addAccountForm from '../../../form-json/add-account/add_account.json';
import commonLoginFormJSON from "src/form-json/login/common-login.json";
import { colors } from 'src/styles/theme/styles';
import navigationStrings from 'src/navigation/navigationStrings';
import { useI18n } from 'src/i18n/hooks';
import { validation } from 'src/constant/validation';

interface AddAccountProps {
  errors: any;
  control: any;
  getValues: any;
  clearErrors: any;
  setError: any;
  handleSave: any;
  isSubmitting: boolean;
  navigation: any;
  closeModal: any;
}

export default function AddAccountForm(props: AddAccountProps) {
  const { errors, control, getValues, clearErrors, setError, handleSave, isSubmitting, navigation, closeModal } = props;
  const { t } = useI18n();

  const [elements, setElements] = useState<any>([]);

  useEffect(() => {
    setElements(commonLoginFormJSON[0].fields);
  }, []);

  const forgotPassword = () => {
    let nativePath = navigationStrings.REQUEST_RESET_PASSWORD;
    closeModal();
    navigation.navigate(nativePath);
  };

  const handleCustomSelect = (id: string, data: any) => {
    if (id === "userType" && data != null) {
      clearErrors("userType")
    }
  };

  return (
    <View>
      {elements &&
        elements.map((field: any, i: number) => {
          return (
            <View key={`multi-account${i}`}>
              <Element
                key={`multi-account-element${i}`}
                field={field}
                control={control}
                errors={errors}
                handleCustomSelect={handleCustomSelect}
              />
            </View>
          );
        })}

      <SecondaryBtn label={t('signIn.text')} onPress={handleSave} disabled={isSubmitting} />

      <View style={{ marginTop: 12 }}>
        <TouchableOpacity
          style={{ alignSelf: 'center' }}
          onPress={forgotPassword}>
          <SmallTextAtom
            value={t("forgot-password.text")}
            color={colors.primary}
            fontWeight={'bold'}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}
