import React, { useState } from 'react';
import { TableBody, TableCell, TableRow, styled } from '@mui/material';
import Check from 'src/components/atoms/CheckBox/index.web';
import { View } from 'react-native';
import { colors } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import useTable from './UseTable';

export default function InstituteCoursesCheckboxTable(props: any) {
  const { header, tabledata, checked, handleChecked, width, manualSelection, height, maxHeightTable, isDataAvailable } = props;
  const { t } = useI18n();
  const maxHeight = 350;
  const [tabledataState] = useState(tabledata);
  const { TblContainer, TblHead } = useTable(tabledataState, header, maxHeight, tabledata);

  return (
    <View style={{ overflow: 'auto', width }}>
      <TblContainer
        tabledata={tabledata}
        height={height && height}
        maxHeight={maxHeightTable && maxHeightTable}>
        <TblHead />
        <TableBody>
          {tabledata &&
            tabledata.map((item: any, i: number) => (
              <CTableRow key={i}>
                <TableCell style={styles.textStyle}>{item?.code}</TableCell>
                <TableCell
                  style={
                    styles.textStyle
                  }>{`${item?.alias} - ${item?.name} (${item?.duration} yrs)`}</TableCell>
                <TableCell style={styles.textStyle}>
                  <Check
                    id={item?.id}
                    width={100}
                    same={manualSelection}
                    name="courseCheck[]"
                    checked={checked.includes(item?.id)}
                    onChange={() => handleChecked(item?.id)}
                    index={i}
                    direction={'right'}
                  />
                </TableCell>
              </CTableRow>
            ))}

          {isDataAvailable && tabledata.length == 0 && (
            <TableRow key={'no-record'}>
              <TableCell style={styles.noRecordsStyle} colSpan={3} align={'center'}>
                {t('no-records-found.text')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TblContainer>
    </View>
  );
}

const styles = {
  textStyle: {
    padding: '0px 15px',
    fontSize: 15,
    color: colors.primaryText,
    fontWeight: 400,
  },
  noRecordsStyle: {
    margin: 'auto'
  }
};

const CTableRow = styled(TableRow)`
  height: 40px;
`;