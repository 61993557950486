import { useTranslation } from 'react-i18next';

export const useI18n = () => {
  const { t: xt, i18n } = useTranslation();

  const changeLanguage = (languageId: string) =>
    i18n.changeLanguage(languageId, (err: any, t: any) => {
      if (err) {
        console.log('Cannot change language', err);
      } else {
        console.log('Successfully change language', err);
      }
      t('Language ready'); // -> same as i18next.t
    });

  const t = (key: string, options?: { values: any }): string => {
    if (options?.values) {
      let msg: String = xt(key);
      // key replaced with variable value.
      const variables = msg.match(/[^{\}]+(?=})/g) || [];
      variables.forEach((variable) => {
        msg = msg.replace(`{${variable}}`, options?.values[variable] || '');
      });
      return msg.toString();
    } else {
      return xt(key);
    }
  }

  return {
    changeLanguage,
    i18n,
    t,
  };
};
