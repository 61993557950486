import React from 'react';
import { View, StyleSheet } from 'react-native';
import DateTextAtom from 'src/components/atoms/Text/DateTextAtom';
import styled, { useTheme } from 'styled-components';
import { DT, parseToDate } from 'src/constant/dateTime';
import { format } from 'date-fns';

export const NoticeItem = (props: iNotice) => {
  const parsedDate = parseToDate(props?.date);
  const date = parsedDate && format(parsedDate, DT.DATE_ABBREVIATED_MONTH_YEAR);
  let { rem }: any = useTheme();
  const styles = getStyles(rem);

  return (
    <ItemContainer>
      <View style={styles.list}>
        <View style={styles.date}>
          <DateTextAtom value={date || ''} />
        </View>
        <ItemTitle>{props.title}</ItemTitle>
        <ItemDescription>{props.description}</ItemDescription>
      </View>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(0.6)};
`;
const ItemDescription = styled.text`
  font-size: ${({ theme }) => theme.rem(1.5)};
  margin-bottom: ${({ theme }) => theme.rem(0.5)};
  color: #666;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ItemTitle = styled.div`
  font-size: ${({ theme }) => theme.rem(1.6)};
  color: #666;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const getStyles = (rem: any) =>
  StyleSheet.create({
    list: {
      shadowColor: '#000',
      shadowOffset: { width: 0, height: rem(0.1) },
      shadowOpacity: 0.2,
      shadowRadius: 0,
      elevation: rem(0.5),
      marginBottom: rem(1),
      paddingBottom: rem(1),
    },
    date: {
      fontSize: rem(1.2),
      fontWeight: '600',
      color: '#2B78CA',
      marginBottom: rem(0.2),
    },
  });
interface iNotice {
  title?: string;
  description?: string;
  date?: any;
}
