import React from 'react';
import { breakpoints, colors, fontWeight } from 'src/styles/theme/styles';
import { TouchableOpacity, View } from 'react-native';
import { height, isIOS, isNative, isWeb, width } from 'src/constant/device';
import EventDate from 'src/components/atoms/EventDate';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { useThemeSystem } from 'src/contexts/theme-context';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import SwipeCard from '../SwipableCard';
import styled from 'styled-components/native';
import { Tooltip } from '@mui/material';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';

interface Props {
  onPress: (data: string) => void;
  editAction: (data: any) => void;
  deleteAction: any;
  data: any;
  canEdit: boolean;
  canDelete: boolean;
  isSwipable: boolean;
  indexKey?: number | null;
  size?: 'md' | 'sm';
}

export default function EventCard(props: Props) {
  const {
    data,
    onPress,
    editAction,
    deleteAction,
    canEdit = true,
    canDelete = true,
    isSwipable = true,
    indexKey,
    size = 'md',
  } = props;
  const { theme } = useThemeSystem();
  let isDesktop = width > breakpoints.md;
  let startTime = '';
  let endTime = '';
  if (data.mstartTime) {
    const parsedDateObject = parse(data.mstartTime, DT.TIME_24_HOUR, new Date());
    startTime = format(parsedDateObject, DT.TIME_12_HOUR);
  }
  if (data.mendTime) {
    const parsedDateObject = parse(data.mendTime, DT.TIME_24_HOUR, new Date());
    endTime = format(parsedDateObject, DT.TIME_12_HOUR);
  }

  const TouchableContainer = styled.TouchableHighlight`
    padding: ${({ size }: any) =>
      size == 'sm'
        ? '8px 8px 8px 0'
        : `${isDesktop ? '24px' : indexKey === 0 ? '10px' : '24px'} ${isDesktop ? '0px' : '24px'
        } 24px`};
    flex-direction: row;
    width: ${!isDesktop || size == 'sm' ? '100%' : '60%'};
    border-bottom-width: ${isDesktop ? '1px' : '0px'};
    border-bottom-color: ${colors.inputBorder};
    padding-bottom: ${isDesktop ? '24px' : '24px'};
  `;

  return (
    <>
      <SwipeCard
        key={data?.id}
        deleteItem={() => deleteAction(data, indexKey)}
        isSwipable={isSwipable}
        testID={`deleteEventCard-${indexKey}`}
        >
        <TouchableContainer
          testID={`eventCard-${indexKey}`}
          size={size}
          activeOpacity={0.5}
          underlayColor={isDesktop ? colors.white : colors.inputBorder}
          delayPressIn={50}
          onPress={() => onPress(data)}
          key={data?.id}>
          <>
            <>
              <EventDate fullDate={data.mdate} />
            </>

            <RightViewContainer>
              <MediumTextSelect lines={1} value={data?.title || ''} fontWeight={fontWeight[600]} />
              <TimeViewContainer>
                <NormaltextAtom
                  value={`${startTime} to ${endTime}`}
                  color={theme?.events?.description?.textColor}
                  fontWeight={fontWeight[400]}
                  lineHeight={2}
                />
              </TimeViewContainer>
              <NormaltextAtom
                value={data?.location || ''}
                color={theme?.events?.description?.textColor}
                lineHeight={2}
                style={{ paddingTop: isWeb ? 0 : 8 }}
              />
            </RightViewContainer>

            {width > breakpoints.md && (
              <ActionContainer>
                {canEdit && (
                  <IconViewContainer>
                    <Tooltip title="Edit Event">
                      <TouchableOpacity onPress={canEdit ? (e) => editAction(data) : () => { }}>
                        <FontAwesomeIcon icon={faPencil} color={theme?.table?.editIconColor} />
                      </TouchableOpacity>
                    </Tooltip>
                  </IconViewContainer>
                )}
                {canDelete && (
                  <View>
                    <Tooltip title="Delete Event">
                      <TouchableOpacity
                        onPress={canDelete ? (e) => deleteAction(data, indexKey) : () => { }}>
                        <FontAwesomeIcon icon={faTrashAlt} color={theme?.table?.deleteIconColor} />
                      </TouchableOpacity>
                    </Tooltip>
                  </View>
                )}
              </ActionContainer>
            )}
          </>
        </TouchableContainer>
      </SwipeCard>
      {!isDesktop && <Horizontal />}
    </>
  );
}

const Horizontal = styled.View`
  border-bottom-color: ${colors.inputBorder};
  border-bottom-width: 1px;
  margin-left: 24px;
  margin-right: 24px;
`;

const RightViewContainer = styled.View`
  margin-left: 15px;
  margin-right: 10px;
  padding-top: ${isWeb ? 8 : height > 800 ? 1 : 4}px;
  max-width: 100%;
  flex: 1;
`;

const TimeViewContainer = styled.View`
  margin-top: ${isWeb ? 4 : isNative && !isIOS ? 0 : 1}px;
  height: 20px;
  margin-bottom: ${isNative && !isIOS ? -2 : 2}px;
`;

const ActionContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 4px;
  margin-left: auto;
`;

const IconViewContainer = styled.View`
  margin-right: 13px;
`;
