import { ApplicationStatus, ERROR, SUCCESS, USER_TYPE, limit } from 'src/constant';
import React, { useEffect, useState } from 'react';
import {
  useGetApplicationDetails,
  useSubmitApplication,
} from 'src/graphql/applications/applications';
import { useHistory, useParams } from 'src/routes/routing.web';

import HeaderFour from 'src/components/atoms/Text/HeaderFour';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import UserDetails from 'src/components/molecules/UserDetails';
import { FormBaseType, ViewMode } from 'src/components/molecules/Forms/types';
import { height, isWeb } from 'src/constant/device';
import styled from 'styled-components/native';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { userInformation } from 'src/utils/manageState';
import { showNativeError, showNativeSuccess } from 'src/components/molecules/NotificationWrapper';
import navigationStrings from 'src/navigation/navigationStrings';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';

const EducationDetails = ({
  navigation,
  route,
}: {
  navigation?: any;
  route?: { params: { applId: string } };
}) => {
  const currentUserObject = userInformation();
  const { userId, userType }: any = currentUserObject;
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const history = useHistory();
  const { setAlertDetails } = useAlertSystem();
  const [rowData, setRowData] = useState([]);
  let urlApplId: string;
  if (isWeb) {
    const { urlApplicationId } = useParams<{ urlApplicationId: string }>();
    urlApplId = urlApplicationId;
  } else {
    if (route?.params && route.params?.applId) {
      urlApplId = route.params.applId;
    } else {
      urlApplId = '';
    }
  }
  const [changesAllowed, setChangesAllowed] = useState<boolean>(false);

  const { getApplicationDetails, applicationDetailsData, refetchApplicationDetails, applicationDetailsLoading } =
    useGetApplicationDetails();
  const { submitApplication } = useSubmitApplication();

  useEffect(() => {
    isWeb &&
      setHeading([
        { text: t('application.label'), url: '/application/list/limit/50/page/1' },
        { text: t('qualification-details.label'), url: '' },
      ]);
  }, []);

  useEffect(() => {
    if (urlApplId) {
      handleFetch(urlApplId);
    }
  }, [urlApplId]);

  useEffect(() => {
    if (applicationDetailsData?.application) {
      const { status, educationDetails } = applicationDetailsData.application;
      const mappedEducationDetails = educationDetails.map((e) => e.educationDetail);
      setRowData(mappedEducationDetails);
      setChangesAllowed(
        [ApplicationStatus.INCOMPLETE, ApplicationStatus.REJECTED, ApplicationStatus.APPLICATION_FEE_PENDING].includes(status),
      );
    }
  }, [applicationDetailsData]);

  async function refreshDetails() {
    if (refetchApplicationDetails) refetchApplicationDetails();
  }

  async function handleFetch(urlAppId: string) {
    getApplicationDetails({ variables: { id: urlAppId } });
  }

  function handleCancelOrRedirect(applicationId: string = '', applicationStatus: string = '') {
    if (currentUserObject?.userType === USER_TYPE.STUDENT) {
      history.push(`/application/list/limit/${limit}/page/1`, { applId: applicationId, applStatus: applicationStatus });
    } else {
      history.push('/dashboard', { applId: applicationId, applStatus: applicationStatus });
    }
  }

  async function handleOnSubmit() {
    try {
      const submitApplicationData = await submitApplication({
        variables: { id: urlApplId },
      });
      if (submitApplicationData?.data) {
        const applicationStatus = submitApplicationData?.data?.submitApplication?.status;
        const applicationDetailsToBeSent = applicationStatus === ApplicationStatus.APPLICATION_FEE_PENDING || applicationStatus === ApplicationStatus.PAYMENT_AWAITED;
        if (isWeb) {
          if (applicationStatus !== ApplicationStatus.APPLICATION_FEE_PENDING) setAlertDetails({ message: t('application-submit-success.label'), level: SUCCESS });
          applicationDetailsToBeSent ? handleCancelOrRedirect(urlApplId, applicationStatus) : handleCancelOrRedirect();
        } else {
          if (applicationStatus !== ApplicationStatus.APPLICATION_FEE_PENDING) showNativeSuccess(t('application-submit-success.label'));
          applicationDetailsToBeSent ? navigation.navigate(navigationStrings.APPLICATION_LISTING, { applId: urlApplId, applStatus: applicationStatus }) : navigation.navigate(navigationStrings.APPLICATION_LISTING);
        }
      }
    } catch (error: any) {
      if (isWeb) {
        setAlertDetails({ message: error.message, level: ERROR });
      } else {
        showNativeError(error.message);
      }
    }
  }

  function getQualificationTemplate() {
    return (
      <UserDetails
        step={0}
        userId={userId}
        userType={userType}
        hideAddAndDeleteBtn={true}
        reviewMode={false}
        isOnboarding={false}
        inModalView={true}
        userDetails={rowData}
        applicationRef={urlApplId}
        viewMode={changesAllowed ? ViewMode.EDIT : ViewMode.READ}
        refetch={refreshDetails}
        formBaseType={FormBaseType.ApplicationQualification}
        navigation={!isWeb ? navigation : null}
        onSubmit={!isWeb ? handleOnSubmit : null}
        submitButtonLabel={!isWeb ? t('submit.label') : ''}
      />
    );
  }

  return (
    <>
      {isWeb ? (
        <>
          <LoadContentWrapper style={styles.loadContent}>
            <TitleWrapper>
              <HeaderFour value={t("qualification-details.label")} />
            </TitleWrapper>
            {rowData?.length > 0 && urlApplId ? (
              getQualificationTemplate()
            ) : (
              <SpinnerWrapper>
                {applicationDetailsLoading ? (
                  <LoaderSpinner />
                ) : (
                  <NormaltextAtom
                    value={t("no-records-found.text")}
                    textAlign={"center"}
                  />
                )}
              </SpinnerWrapper>
            )}
          </LoadContentWrapper>
          <BtnWrapper>
            <OutlineButton onPress={() => handleCancelOrRedirect()} label={t('cancel.label')} marginRight={16} />
            <SecondaryBtn onPress={handleOnSubmit} label={t('submit.label')} />
          </BtnWrapper>
        </>
      ) : (
        <>
          {rowData?.length > 0 && urlApplId ? (
            getQualificationTemplate()
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      )}
    </>
  );
};

export default EducationDetails;

const styles = {
  loadContent: { height: height - 184 },
};

const BtnWrapper = styled.View`
  display: flex;
  flex-direction: row;
  margin-left: ${({ theme }) => theme.rem(6.4)};
`;

const TitleWrapper = styled.View`
  margin-bottom: 16px;
`;

const SpinnerWrapper = styled.View`
  height: 100%;
  display: flex;
  justify-content: center;
`;
