import { AUTOCOMPLETE_MODULE, ERROR, SUCCESS, limit } from "src/constant";
import { LotteryHelpers, TLotteryListingItem as TItem } from "./LotteryHelpers";
import {
  NoRecordsFound,
  TableCells,
  TableRows,
} from "src/components/molecules/Table/TableAtom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGenerateLottery,
  useGetLotteryApplications,
  useGetLotteryDetails,
  usePublishLottery,
} from "src/graphql/admission/lottery";

import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import NormalTextSelect from "src/components/atoms/Text/NormalTextSelect";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import OutlineButton from "src/components/atoms/Button/OutlineButtton";
import Pagination from "src/components/atoms/Pagination/Paginations.web";
import Search from "src/components/atoms/SearchBar/index.web";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { TableBody } from "@mui/material";
import { debounce } from "src/components/services";
import { height } from "src/constant/device";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useFilter } from "src/contexts/filter-context";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { useParams } from "src/routes/routing.web";
import useTable from "src/components/molecules/Table/UseTable";

const FILTER_MODULE = AUTOCOMPLETE_MODULE.LOTTERY;
const DEBOUNCE_DELAY = 500;

type TContent = {
  data: TItem[];
  totalCount: number;
};

const { headCells, LotteryStatus, getFormattedPublishedDate } = LotteryHelpers;

const LotteryListing = () => {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { setAlertDetails } = useAlertSystem();
  let searchValue = "" as string;
  const {
    admissionId,
    lotteryId,
    page = 1,
    dataLimit = limit,
  }: any = useParams();
  if (filters && filters?.[FILTER_MODULE]?.on) {
    searchValue = filters?.[FILTER_MODULE]?.search;
  }

  const { getLotteryApplications, data, loading, refetch } =
    useGetLotteryApplications();
  const { getLotteryDetails, lotteryData, lotteryRefetch } =
    useGetLotteryDetails();
  const { generateLottery } = useGenerateLottery();
  const { publishLottery } = usePublishLottery();

  const [searching, setSearchingLottery] = useState(searchValue);
  const [currentPage, setCurrentPage] = useState<number>(parseInt(page));
  const [rowLimit, setRowLimit] = useState(parseInt(dataLimit) ?? limit);
  const [content, setContent] = useState<TContent>();

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    content?.data,
    headCells
  );

  useEffect(() => {
    setHeading([
      {
        text: t("admission.label"),
        url: `/admissions/${admissionId}/detail/limit/${limit}/page/1`,
      },
      { text: "Lottery", url: "" },
    ]);
  }, []);

  useEffect(() => {
    setSearchingLottery(searchValue);
  }, [searchValue]);

  useMemo(() => {
    if (admissionId) getLotteryDetails({ variables: { admissionId } });
  }, [admissionId]);

  useMemo(() => {
    handleFetch();
  }, [lotteryId, currentPage, searchValue]);

  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  useMemo(() => {
    if (data?.lotteryApplications) {
      setContent({
        data: data?.lotteryApplications?.data ?? [],
        totalCount: data?.lotteryApplications?.totalCount ?? 0,
      });
    }
  }, [data?.lotteryApplications?.data]);

  function handleFetch() {
    if (lotteryId) {
      getLotteryApplications({
        variables: {
          lotteryId,
          limit: rowLimit,
          skip: (currentPage - 1) * rowLimit,
          ...(searchValue ? { applicantName: searchValue } : {}),
        },
      });
    }
  }

  const delayedQuery = useCallback(
    debounce((q: string) => {
      const shouldPersist = q.trim() !== "";
      const filter = { [FILTER_MODULE]: { on: shouldPersist, search: q } };
      setFilter(filter);
    }, DEBOUNCE_DELAY),
    []
  );

  const handleSearch = (q: string) => {
    setSearchingLottery(q);
    delayedQuery(q);
  };

  const regenerateLotteryFn = async () => {
    try {
      const response = await generateLottery({
        variables: { admissionId },
      });
      if (response && response?.data?.generateLottery) {
        setAlertDetails({
          message: "Lottery regenerated successfully",
          level: SUCCESS,
        });
        if (refetch) refetch();
        if (lotteryRefetch) lotteryRefetch();
      }
    } catch (error: any) {
      setAlertDetails({ message: error?.message, level: ERROR });
    }
  };

  const publishLotteryFn = async () => {
    if (lotteryId) {
      try {
        const response = await publishLottery({
          variables: { lotteryId },
        });
        if (response && response?.data?.publishLottery) {
          setAlertDetails({
            message: "Lottery published successfully",
            level: SUCCESS,
          });
          if (refetch) refetch();
          if (lotteryRefetch) lotteryRefetch();
        }
      } catch (error: any) {
        setAlertDetails({ message: error?.message, level: ERROR });
      }
    }
  };

  return (
    <LoadContentWrapper>
      <Section>
        <Search
          id="lotterySearch"
          handleChange={handleSearch}
          value={searching}
          label={t("search.label")}
        />
        {lotteryData?.lottery &&
          (lotteryData.lottery?.status !== LotteryStatus.PUBLISHED ? (
            <BtnWrapper>
              <OutlineButton
                label={"Regenerate"}
                onPress={regenerateLotteryFn}
                lines={1}
              />
              <SecondaryBtn
                label={"Publish"}
                onPress={publishLotteryFn}
                lines={1}
              />
            </BtnWrapper>
          ) : (
            <PublishDate>
              <NormaltextAtom value={"Published on:"} />
              <NormalTextSelect
                value={getFormattedPublishedDate(
                  lotteryData.lottery?.publishedDate
                )}
              />
            </PublishDate>
          ))}
      </Section>
      <TblContainer>
        <TblHead />
        <>
          {!loading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item: TItem) => (
                    <TableRows key={item.id}>
                      <TableCells value={item?.user?.fullName ?? "-"} />
                      <TableCells value={item?.user?.email ?? "-"} />
                      <TableCells value={item?.applicationId ?? "-"} />
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={3} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>

      {!!content?.totalCount && !loading && (
        <Pagination
          pathName={`lottery/${admissionId}/${lotteryId}`}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          total={content.totalCount}
          hidePagination={content.totalCount <= rowLimit}
        />
      )}
    </LoadContentWrapper>
  );
};

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  row-gap: 8px;
`;

const BtnWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const PublishDate = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

export default LotteryListing;
