import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  const size = props.size || 16;
  return (
    <Svg
      width={size * scale}
      height={size * scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M11.314 10.018L8.333 12.5l-2.981-2.482c-.575.025-4.019.485-4.019 4.482a1.5 1.5 0 001.5 1.5h11a1.5 1.5 0 001.5-1.5c0-3.997-3.44-4.457-4.019-4.482zM7.833 15h-5a.501.501 0 01-.5-.5c0-3.113 2.628-3.435 2.7-3.447l2.8 2.331V15zm6.5-.5c0 .275-.225.5-.5.5h-5v-1.616l2.8-2.331c.072.012 2.7.334 2.7 3.447zM1.745 3.125l.213.062v1.175a.741.741 0 00-.375.635c0 .262.144.481.347.615l-.488 1.95c-.053.216.066.438.238.438h1.306c.172 0 .29-.222.238-.438l-.488-1.947A.739.739 0 003.083 5a.741.741 0 00-.375-.634v-.954l1.46.432A4.365 4.365 0 003.832 5.5a4.501 4.501 0 009 0c0-.588-.125-1.144-.335-1.656l2.422-.72c.55-.162.55-1.087 0-1.249L8.861.078a1.802 1.802 0 00-1.056 0l-6.06 1.797c-.55.162-.55 1.087 0 1.25zM8.334 9a3.502 3.502 0 01-3.5-3.5c0-.49.116-.947.3-1.369l2.672.794c.462.137.85.062 1.056 0l2.672-.794c.184.422.3.881.3 1.369 0 1.931-1.569 3.5-3.5 3.5zm-.244-7.966c.025-.006.228-.075.488 0L13.514 2.5 8.577 3.966c-.025.006-.229.075-.488 0L3.152 2.5l4.937-1.466z"
        fill="#000"
        fillOpacity={0.6}
      />
    </Svg>
  );
}

export default SvgComponent;
