export const APPLICATION_STATUS_ENUM = [
  'ADMISSION COMPLETED',
  'BLOCKED',
  'CANCELLED',
  'INCOMPLETE',
  'PAYMENT REQUESTED',
  'PAYMENT COMPLETED',
  'PARTIAL PAYMENT',
  'PAYMENT POSTPONED',
  'REJECTED',
  'SUBMITTED',
  'VERIFIED',
  'APPLICATION FEE PENDING',
  'PRE-ADMISSION CANCELLATION',
  'POST-ADMISSION CANCELLATION',
  'CANCELLATION CHARGES PAID',
  'TRANSFERRED',
  'ADMISSION CANCELLATION',
];

export const ApplicationStatusLabelKeyVal = {
  'PARTIAL PAYMENT': 'PARTIAL_PAYMENT_COMPLETED',
  'ADMISSION COMPLETED': 'ADMISSION_COMPLETED',
  'BLOCKED': 'BLOCKED',
  'CANCELLED': 'CANCELLED',
  'INCOMPLETE': 'INCOMPLETE',
  'PAYMENT REQUESTED': 'PAYMENT_AWAITED',
  'PAYMENT COMPLETED': 'PAYMENT_COMPLETED',
  'PAYMENT POSTPONED': 'PAYMENT_POSTPONED',
  'TRANSFERRED': 'TRANSFERED',
  'REJECTED': 'REJECTED',
  'SUBMITTED': 'SUBMITTED',
  'VERIFIED': 'VERIFIED',
  'APPLICATION FEE PENDING': 'APPLICATION_FEE_PENDING',
  'PRE-ADMISSION CANCELLATION': 'PRE_ADMISSION_CANCELLATION',
  'POST-ADMISSION CANCELLATION': 'POST_ADMISSION_CANCELLATION',
  'CANCELLATION CHARGES PAID': 'CANCELLATION_CHARGES_PAID',
  'ADMISSION CANCELLATION': 'ADMISSION_CANCELLATION',
};
