import { AUTOCOMPLETE_MODULE, ERROR, SUCCESS, limit } from "src/constant";
import {
  ApplicantSubmittedDocuments,
  TPublicInvitee as Item,
  publicInvitationsHeadCells as headCells,
} from "./PublicInvitationsHelpers";
import {
  NoRecordsFound,
  TableCells,
  TableRows,
} from "src/components/molecules/Table/TableAtom";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGetPublicApplicantInvites,
  useTriggerPublicApplicantInviteMails,
} from "../../../../graphql/admission/public-invitation";

import Check from "src/components/atoms/CheckBox/index.web";
import ConfirmationModal from "../../Modals/ConfirmationModal.web";
import { InfoSquareIcon } from "src/components/atoms/ActionIcons";
import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import OutlineButton from "src/components/atoms/Button/OutlineButtton";
import Pagination from "src/components/atoms/Pagination/Paginations.web";
import PublicApplicantDetails from "./PublicApplicantDetails.web";
import ScheduleInterview from "./ScheduleInterview.web";
import Search from "src/components/atoms/SearchBar/index.web";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { TableBody } from "@mui/material";
import { admissionNameQuery } from "src/graphql/admission/public-invitation";
import { colors } from "src/styles/theme/styles";
import { debounce } from "src/components/services";
import { defaultPagingURLSegment } from "../../Navigation/SidebarManifest";
import { graphqlQuery } from "src/graphql/util";
import { height } from "src/constant/device";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useFilter } from "src/contexts/filter-context";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { useParams } from "src/routes/routing.web";
import useTable from "src/components/molecules/Table/UseTable";

const FILTER_MODULE = AUTOCOMPLETE_MODULE.PUBLIC_INVITES;
const DEBOUNCE_DELAY = 500;

type TContent = {
  data: Item[];
  totalCount: number;
};

type TDetails = {
  modalState: boolean;
  item: Item | null;
};

type TModalStep = "scheduleInterview" | "inviteConfirmation";

export default function PublicInvitations() {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { urlAdmissionId, page = 1, dataLimit }: any = useParams();

  let searchValue = "" as string;
  if (filters && filters?.[FILTER_MODULE]?.on) {
    searchValue = filters?.[FILTER_MODULE]?.search;
  }
  const [currentPage, setCurrentPage] = useState<number>(parseInt(page));
  const [rowLimit, setRowLimit] = useState(parseInt(dataLimit) ?? limit);
  const [content, setContent] = useState<TContent>();
  const [checked, setChecked] = useState<string[]>([]);
  const [details, setDetails] = useState<TDetails>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [formModal, setFormModal] = useState<TModalStep | null>(null);
  const [searching, setSearching] = useState(searchValue);
  const { setAlertDetails } = useAlertSystem();
  const { getPublicApplicantInvites, data, loading, refetch } =
    useGetPublicApplicantInvites();
  const { triggerInvitation } = useTriggerPublicApplicantInviteMails();

  useEffect(() => {
    if (urlAdmissionId) configureBreadcrumb();
  }, [urlAdmissionId]);

  useEffect(() => {
    setSearching(searchValue);
  }, [searchValue]);

  useMemo(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  async function configureBreadcrumb() {
    const response = await graphqlQuery(
      admissionNameQuery,
      { id: urlAdmissionId },
      false
    );
    const admissionName = response?.data?.admission?.name ?? "";

    if (admissionName) {
      setHeading([
        {
          text: t("admission.label"),
          url: `/admissions/${defaultPagingURLSegment}`,
        },
        {
          text: admissionName,
          url: `/admissions/${urlAdmissionId}/detail/${defaultPagingURLSegment}`,
        },
        { text: t("publicInvitations.label"), url: "" },
      ]);
    }
  }

  function handleFetch() {
    const filter: any = {
      limit: rowLimit,
      skip: (currentPage - 1) * rowLimit,
      filters: {
        admission: { eq: urlAdmissionId },
      },
    };

    if (searchValue) {
      filter.filters["name"] = { regex: searchValue };
      filter.filters["email"] = { regex: searchValue };
    }
    getPublicApplicantInvites({ variables: filter });
  }

  useMemo(() => {
    if (data?.applicantInvites) {
      setContent({
        data: data?.applicantInvites?.data ?? [],
        totalCount: data?.applicantInvites?.totalCount ?? 0,
      });
    }
  }, [data?.applicantInvites]);

  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    content?.data,
    headCells
  );

  const delayedQuery = useCallback(
    debounce((q: string) => {
      const shouldPersist = q.trim() !== "";
      const filter = { [FILTER_MODULE]: { on: shouldPersist, search: q } };
      setFilter(filter);
    }, DEBOUNCE_DELAY),
    []
  );

  const handleSearch = (qualification: string) => {
    setSearching(qualification);
    delayedQuery(qualification);
  };

  const onSuccess = (message: string): void => {
    setAlertDetails({ message, level: SUCCESS });
    if (refetch) refetch();
    setChecked([]);
  };

  const onError = (message: string): void => {
    setAlertDetails({ message, level: ERROR });
    if (refetch) refetch();
  };

  const closeModal = () => {
    setFormModal(null);
  };

  const onInvite = async (): Promise<void> => {
    setSubmitting(true);
    try {
      const payload = {
        admissionId: urlAdmissionId,
        inviteIds: checked,
      };
      const response = await triggerInvitation({
        variables: { payload },
      });
      if (response?.data && response.data.triggerInviteMails) {
        if (response.data.triggerInviteMails?.successfulInvites) {
          onSuccess(
            `Invitation(s) sent. Successful: ${response.data.triggerInviteMails.successfulInvites
            }, Failed: ${response.data.triggerInviteMails?.failedInvites ?? 0}`
          );
        } else {
          onError(
            `${response.data.triggerInviteMails?.failedInvites ?? 0
            } invitation(s) failed`
          );
        }
        setSubmitting(false);
      }
      setFormModal(null);
    } catch (e: any) {
      onError(e?.message);
      setSubmitting(false);
      setFormModal(null);
    }
  };

  const handleCheckAll = (): void => {
    setChecked((prevChecked) => {
      if (
        prevChecked.length === content?.totalCount &&
        prevChecked.length !== 0
      ) {
        return [];
      } else {
        return content?.data.map((item) => item.id) ?? [];
      }
    });
  };

  const handleCheck = (toggledId: string): void => {
    setChecked((prevChecked) => {
      if (prevChecked.includes(toggledId)) {
        return prevChecked.filter((id) => id !== toggledId);
      } else {
        return [...prevChecked, toggledId];
      }
    });
  };

  const getSelectedEmailIDs = (): string[] => {
    if (Array.isArray(content?.data)) {
      return content.data
        .filter((item) => checked.includes(item.id))
        .map((item) => item.email);
    }
    return [];
  };

  return (
    <LoadContentWrapper>
      <Section>
        <Search
          id="publicInvitationsSearch"
          handleChange={handleSearch}
          value={searching}
          label={t('search.label')}
        />
        <ButtonWrapper>
          <OutlineButton
            label={t('scheduleInterview.label')}
            onPress={() => setFormModal('scheduleInterview')}
            customDisabled={checked.length === 0}
            lines={1}
          />
          <SecondaryBtn
            label={t('sendInvite.label')}
            onPress={() => setFormModal('inviteConfirmation')}
            width={132}
            customDisabled={checked.length === 0}
            canLoad={submitting}
          />
        </ButtonWrapper>
      </Section>
      <TblContainer height={'100%'} overflowX={'auto'}>
        <TblHead
          checkBox={true}
          disableCheckBox={loading}
          checked={checked.length === content?.data?.length && checked.length != 0}
          handleCheck={handleCheckAll}
        />
        <>
          {!loading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item: Item, index: any) => (
                    <TableRows key={item.id}>
                      <TableCells width={16}>
                        <Check
                          same={false}
                          onChange={() => handleCheck(item.id)}
                          checked={checked.some(id => id === item.id)}
                        />
                      </TableCells>
                      <TableCells value={item?.name ?? '-'} />
                      <TableCells value={item?.email ?? '-'} />
                      <TableCells value={item?.subjectGroup?.name ?? '-'} />
                      <TableCells
                        value={item?.inviteSentAt ? 'INVITATION SENT' : 'INVITATION PENDING'}
                        color={item?.inviteSentAt ? colors.green : colors.yellow}
                      />
                      <TableCells>
                        <ApplicantSubmittedDocuments
                          item={item}
                        />
                      </TableCells>
                      <TableCells>
                        <InfoIconWrapper>
                          <InfoSquareIcon
                            tooltipTitle={t("applicantDetails.label")}
                            onPress={() =>
                              setDetails({ modalState: true, item })
                            }
                          />
                        </InfoIconWrapper>
                      </TableCells>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={6} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>

      {!!content?.totalCount && !loading && (
        <Pagination
          pathName={`public-invitations/${urlAdmissionId}`}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          total={content.totalCount}
          hidePagination={content.totalCount <= rowLimit}
        />
      )}

      {details?.modalState && details?.item && (
        <PublicApplicantDetails
          onClose={() => setDetails({ modalState: false, item: null })}
          item={details.item}
        />
      )}

      {formModal === 'scheduleInterview' && (
        <ScheduleInterview
          admissionId={urlAdmissionId}
          emailIds={getSelectedEmailIDs()}
          onClose={closeModal}
          onSuccess={(message: string) => {
            onSuccess(message);
            closeModal();
          }}
          onError={(message: string) => {
            onError(message);
            closeModal();
          }}
        />
      )}

      {formModal === 'inviteConfirmation' && (
        <ConfirmationModal
          onClose={closeModal}
          onSubmit={onInvite}
          headerTitle={t('sendInvite.label')}
          bodyText={t('confirmInvite.text')}
          confirmationLabel={'invite.label'}
        />
      )}
    </LoadContentWrapper>
  );
}

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  row-gap: 16px;
  flex-wrap: wrap;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const InfoIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
