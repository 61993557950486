import React from 'react';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { height, isIOS } from 'src/constant/device';
import { styled } from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { colors, fonts } from 'src/styles/theme/styles';
import { TsubjectsData } from '../Helpers/helpers';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';

type Tprops = {
  resultData: TsubjectsData[];
  handleSubmit?: () => void;
};
export default function AtktModal(props: Tprops) {
  const { resultData, handleSubmit } = props;
  const { t } = useI18n();

  return (
    <MarksReportWrapper>
      <TableWrapper>
        <HeaderFive value={t('marksReport.label')} color={colors.primaryText} />
        <TableHeadWrapper>
          <NormaltextAtom value={t('subject.label')} fontFamily={fonts.semibold} />
          <NormaltextAtom value={t('marks.label')} fontFamily={fonts.semibold} />
        </TableHeadWrapper>
        <TableBodyWrapper nestedScrollEnabled={true}>
          {resultData && resultData.length ? (
            resultData.map((item: any, i: number) => (
              <>
                <TableRowWrapper>
                  <FirstCell>
                    <MediumTextSelect
                      value={item?.subject?.name || '-'}
                      color={colors.primaryText}
                      fontWeight={'normal'}
                      fontFamily={fonts.semibold}
                      lines={2}
                    />
                  </FirstCell>
                  <MediumTextSelect
                    value={`${item?.marksObtained} / ${item?.totalMarks}`}
                    color={colors.red}
                    fontWeight={'normal'}
                    fontFamily={fonts.semibold}
                  />
                </TableRowWrapper>
                <BorderWrapper />
              </>
            ))
          ) : (
            <MediumTextSelect
              value={t('noResultsFound.text')}
              style={styles.noRecordView}
              textAlign={'center'}
              fontFamily={fonts.semibold}
              fontWeight={'normal'}
              color={colors.primaryText}
            />
          )}
        </TableBodyWrapper>
      </TableWrapper>

      <ButtonWrapper>
        <SecondaryBtn label="Proceed" onPress={handleSubmit} />
      </ButtonWrapper>
    </MarksReportWrapper>
  );
}

const styles = {
  noRecordView: {
    paddingTop: 16,
  },
};

const TableHeadWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0px 10px 24px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const MarksReportWrapper = styled.View`
  margin-top: 24px;
  height: 100%;
`;

const TableBodyWrapper = styled.ScrollView`
 height: 100%;
`;

const TableRowWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 57px;
  align-items: center;
  column-gap: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const FirstCell = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  max-width: 70%;
`;

const BorderWrapper = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const ButtonWrapper = styled.View`
  margin-top: 5px;
  hegiht: '20%';
  margin-bottom: 10px;
`;

const TableWrapper = styled.View`
  max-height: 80%;
`;
