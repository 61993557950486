import React, { CSSProperties } from 'react';
import styled from 'styled-components/native';

import { colors, fonts } from 'src/styles/theme/styles';

interface Props {
  value: any;
  color?: string;
  fontWeight?: any;
  lineHeight?: number;
  lines?: number;
  fontSize?: number;
  fontFamily?: string;
  style?: any;
  id?: string;
  selectable?: boolean;
  textAlign?: CSSProperties["textAlign"]
}

export default function NormaltextAtom(props: Props) {
  const {
    value = '',
    color = colors.secondaryText,
    fontWeight = 'normal',
    lineHeight = 1.7,
    lines,
    fontSize = 1.5,
    fontFamily = fonts.regular,
    style,
    id,
    selectable = true,
    textAlign
  } = props;

  const TextWrapper = styled.Text`
    font-family: ${fontFamily};
    color: ${color};
    font-weight: ${fontWeight};
    font-size: ${(props) => props.theme.rem(fontSize)};
    line-height: ${(props) => props.theme.rem(lineHeight)};
    text-align: ${textAlign};
  `;

  return (
    <TextWrapper id={id} allowFontScaling={false} numberOfLines={lines} style={style} selectable={selectable}>
      {value}
    </TextWrapper>
  );
}
