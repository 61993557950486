import { DT, formatDate } from "src/constant/dateTime";
import { colors, fontWeight } from "src/styles/theme/styles";

import { Dimensions } from "src/constant/standardDimensions";
import DownloadFile from "src/components/atoms/DownloadFile/index.web";
import { FileCategory } from "src/constant";
import HeaderFour from "src/components/atoms/Text/HeaderFour";
import HeaderSix from "src/components/atoms/Text/HeaderSix";
import HyperLinkText from "src/components/atoms/Text/HyperLinkText";
import ImageGrid from "src/components/molecules/ImageGrid/ImageGrid.web";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import NormalTextSelect from "src/components/atoms/Text/NormalTextSelect";
import React from "react";
import { getAnnouncementsQuery } from "src/graphql/communication/news-notices";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";
import { useParams } from "src/routes/routing.web";
import { useQuery } from "@apollo/client";

type Props = {
  type: FileCategory.News | FileCategory.Notice;
  newsnoticeId?: string;
};

export default function NewsModalContent(props: Props) {
  const { type: communicationType } = props;
  const { t } = useI18n();
  const params: Record<string, string> = useParams();

  const newsnoticeId = props?.newsnoticeId ?? params?.newsnoticeId;
  const newsFilter = {
    type: { eq: FileCategory.News },
    id: { eq: newsnoticeId },
  };
  const noticesFilter = {
    type: { eq: FileCategory.Notice },
    id: { eq: newsnoticeId },
  };

  const { loading, data: resp } = useQuery(getAnnouncementsQuery, {
    variables: {
      filters: communicationType === "NEWS" ? newsFilter : noticesFilter,
      timezone: DT.TIMEZONE,
    },
  });

  const data = resp?.getAnnouncements?.data[0];

  const formattedDT = data?.mcreatedAt
    ? formatDate(
      data.mcreatedAt,
      DT.DATE_TIME_FORMAT_WITH_TIMEZONE,
      DT.DATE_TIME_FORMAT_12_HOUR
    )
    : "";

  const image = data?.contentImage ?? null;
  const attachments = data?.attachments ?? null;
  const content = data?.body ?? null;

  return (
    <>
      {!loading ? (
        <Container>
          <HeaderWrapper>
            <HeaderFour
              value={data?.title ?? ""}
              color={colors.secondaryText}
              fontWeight={fontWeight[400]}
              lines={4}
            />
            <NormalTextSelect
              value={`Posted on ${formattedDT}`}
              color={colors.tertiaryText}
            />
          </HeaderWrapper>

          {!!image && (
            <ImageGrid
              data={[image]}
              imageDimensions={imageDimensions}
              cols={1}
            />
          )}

          {!!content && <HyperLinkText text={content ?? ""} />}

          {Array.isArray(attachments) && attachments.length > 0 && (
            <AttachmentsWrapper>
              <HeaderSix
                value={t("attachments.label")}
                fontWeight={fontWeight[400]}
              />
              <AttachmentFiles>
                {attachments.map((fileData, index) => (
                  <DownloadFile
                    fileData={fileData as any}
                    key={`eventAttachment${index}`}
                    width={480}
                  />
                ))}
              </AttachmentFiles>
            </AttachmentsWrapper>
          )}
        </Container>
      ) : (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      )}
    </>
  );
}

const imageDimensions: Dimensions = { width: 320, height: 240 };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const AttachmentFiles = styled.div``;

const AttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
