import React, { CSSProperties, ReactNode } from "react";
import { barColors, fonts } from "src/styles/theme/styles";

import CardTitleBlock from "src/components/molecules/CardTitleBlock";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { IconProps } from "src/assets/common/Icons";
import IndicatorBadge from "src/components/atoms/Badge/IndicatorBadge";
import { LoadingOrComponent } from "src/components/molecules/Loading";
import NoData from "src/components/molecules/NoData";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import ShadowBox from "src/components/atoms/ShadowBox";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";
import { useNavigate } from "src/routes/routing.web";

export type TDashboardCard = {
  label: string;
  value?: number | string;
  style: CSSProperties;
  icon: ({ rem, size }: IconProps) => JSX.Element;
  path?: string;
};

export const DashboardCardContainer = styled.div`
  display: flex;
  margin: 0 ${({ theme }) => theme.rem(-2)};
  overflow-x: auto;
  -ms-overflow-style: none;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    display: none;
  }
  @media all and (min-width: 992px) and (max-width: 1450px) {
    margin-top: ${({ theme }) => theme.rem(-0.9)};
  }
`;

export const DashboardCardWrapper = styled.div`
  width: 25%;
  display: flex;
  padding: ${({ theme }) => theme.rem([0, 2])};
  box-sizing: border-box;
  @media all and (max-width: 991px) {
    width: 100%;
  }
  @media all and (min-width: 992px) and (max-width: 1450px) {
    width: 25%;
    padding: ${({ theme }) => theme.rem([0, 1.3])};
    margin-bottom: ${({ theme }) => theme.rem(0.5)};
  }
`;

export const DashboardContainer = styled.div`
  padding: 0 ${({ theme }) => theme.rem(3.4)};
  margin-top: ${({ theme }) => theme.rem(3)};
  font-family: ${fonts.regular};
  @media all and (max-width: 1024px) {
    padding: 0 ${({ theme }) => theme.rem(1.5)} 8rem
      ${({ theme }) => theme.rem(1.5)};
  }
`;

type EmptyContainerProps = {
  text: string;
  icon: IconDefinition;
  loading: boolean;
};

export const DashboardEmptyContainer = ({
  text,
  icon,
  loading,
}: EmptyContainerProps) => (
  <EmptyContainer>
    <LoadingOrComponent loading={loading}>
      <NoData text1={text} icon={icon} />
    </LoadingOrComponent>
  </EmptyContainer>
);

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.rem(18)};
  overflow: hidden;
`;

export const DemographicsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(5.4)};
`;

export const DemographicsChartContainer = styled.div`
  width: 70%;
`;

export const DemographicLegends = ({
  labelArray,
}: {
  labelArray: string[];
}) => (
  <LegendsContainer>
    {labelArray.map((label: string, index: number) => (
      <Label>
        <IndicatorBadge color={barColors[index]} diameter={12} />
        <NormaltextAtom value={label} fontSize={1.5} fontFamily={fonts.light} />
      </Label>
    ))}
  </LegendsContainer>
);

const LegendsContainer = styled.div`
  width: 30%;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  gap: 8px;
  margin-top: ${({ theme }) => theme.rem(2.4)};
`;

export const DashboardColOneThird = styled.div`
  width: 33.33%;
  padding: ${({ theme }) => theme.rem([1.5, 3, 1.5, 2.7])};
  box-sizing: border-box;
  margin-bottom: 3rem;
  @media all and (max-width: 991px) {
    width: 100%;
  }
`;

type DashboardListingProps = {
  title: string;
  redirectionURL: string;
  allowRedirection: boolean;
  children: ReactNode;
};

export const DashboardListing = (props: DashboardListingProps) => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { title, redirectionURL, allowRedirection, children } = props;

  return (
    <>
      <CardTitleBlock
        titleText={t(title)}
        showButton={allowRedirection}
        buttonText={t("viewAll.text")}
        cb={() => navigate(redirectionURL)}
      />
      <ShadowBox>{children}</ShadowBox>
    </>
  );
};

export const DashboardMainContainer = styled.div`
  padding: ${({ theme }) => theme.rem([0, 3.4])};
  margin-top: ${({ theme }) => theme.rem(3.2)};
  font-family: ${fonts.regular};
  @media all and (max-width: 767px) {
    padding: ${({ theme }) => theme.rem([0, 2.4, 8, 2.4])};
  }
`;

export const DashboardListingScrollContainer = styled.div<{
  customHeight?: number;
}>`
  overflow-y: scroll;
  height: ${({ theme, customHeight }) => theme.rem(customHeight ?? 42)};
`;

export const DemographicsScrollContainer = styled(
  DashboardListingScrollContainer
).attrs(({ customHeight }) => ({ customHeight }))`
  padding-right: ${({ theme }) => theme.rem(3)};
`;
