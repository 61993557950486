import { APPLICANT_TYPE_ENUM, AUTOCOMPLETE_MODULE, limit  } from 'src/constant';
import {
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import React, { useCallback, useEffect, useState } from 'react';

import CreateInfoItem from 'src/components/molecules/TextComponents/InfoItem';
import { DropdownOptionsType } from 'src/types';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import { TableBody } from '@mui/material';
import { height } from 'src/constant/device';
import styled from 'styled-components';
import {
  useGetAllInvitedDirectApplicants,
  useGetBasicAdmissionDetails,
} from 'src/graphql/admission/index';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'src/routes/routing.web';
import useTable from 'src/components/molecules/Table/UseTable';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { useFilter } from 'src/contexts/filter-context';
import { debounce } from 'src/components/services';
import { properCase } from 'src/utils/utility';

const styles = {
  stringTypeCell: { minWidth: '240px' },
  numberTypeCell: { minWidth: '120px' },
  codeTypeCell: { minWidth: '80px' },
  dateTypeCell: { minWidth: '192px' },
};

const headCells1 = [
  {
    id: 'name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: 'capID',
    label: 'cap-id.label',
    align: 'left',
    disableSorting: true,
    style: styles.numberTypeCell,
  },
  {
    id: 'email',
    label: 'email.label',
    align: 'left',
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: 'subjectGroup',
    label: 'subjectGroup.label',
    align: 'left',
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: 'code',
    label: 'code.label',
    align: 'right',
    disableSorting: true,
    style: styles.codeTypeCell,
  },
  {
    id: 'date',
    label: 'date.label',
    align: 'right',
    disableSorting: true,
    style: styles.dateTypeCell,
  },
  {
    id: 'invitedBy',
    label: 'invitedBy.label',
    align: 'left',
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: 'status',
    label: 'status.label',
    align: 'left',
    disableSorting: true,
    style: styles.stringTypeCell,
  },
];

type DirectInvited = {
  capId?: string;
  date?: number;
  email?: string;
  invitedBy?: any;
  name?: string;
  id?: string;
  subjectGroup?: { name?: string };
  status?: string;
};

type AdmissionDataType = {
  id: string;
  name: string;
  batch: DropdownOptionsType;
  subjectGroups: {
    subjectGroup: DropdownOptionsType;
  }[];
};

function DirectInvitesList() {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { urlAdmissionId, page = 1, dataLimit = limit,  }: any = useParams();
  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const [totalCount, setTotalCount] = useState(null);

  let searchValue = '' as string;
  const maxHeight = height - 375;
  const paginationHeightOffset = 24;

  if (filters && filters?.direct_invite?.on) {
    searchValue = filters?.direct_invite?.search;
  }

  const [headCells, setHeadCells] = useState(headCells1);
  const [searching, setSearching] = useState<string>(searchValue);
  const [row, setRow] = useState<DirectInvited[]>();
  const [admissionData, setAdmissionData] = useState<AdmissionDataType>();
  const {
    getAllInvitedDirectApplicants,
    allInvitedDirectApplicantsData,
    allInvitedDirectApplicantsError,
    allInvitedDirectApplicantsLoading,
  } = useGetAllInvitedDirectApplicants();

  const { getBasicAdmissionDetails, basicAdmissionDetailsData, basicAdmissionDetailsLoading } =
    useGetBasicAdmissionDetails();

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
    null,
    null,
    false,
    true,
    null,
    paginationHeightOffset,
  );

  useEffect(() => {
    if (basicAdmissionDetailsData && basicAdmissionDetailsData?.admission) {
      setAdmissionData(basicAdmissionDetailsData.admission);
    }
  }, [basicAdmissionDetailsData]);

  useEffect(() => {
    if (urlAdmissionId) {
      getBasicAdmissionDetails({ variables: { id: urlAdmissionId } });
      handleFetch();
    }
  }, [urlAdmissionId]);

  useEffect(() => {
    if (urlAdmissionId) {
      setRow([]);
      handleFetch();
    }
  }, [currentPage, searchValue, rowLimit]);

  useEffect(() => {
    if (allInvitedDirectApplicantsData && allInvitedDirectApplicantsData?.applicantInvites?.data) {
      setRow(allInvitedDirectApplicantsData.applicantInvites.data);
      setTotalCount(allInvitedDirectApplicantsData.applicantInvites.totalCount);
    }
  }, [allInvitedDirectApplicantsData]);

  useEffect(() => {
    setSearching(searchValue);
  }, [searchValue]);

  useEffect(() => {
    urlAdmissionId &&
      setHeading([
        {
          text: t('admission.label'),
          url: `/admissions/${urlAdmissionId}/detail/limit/${limit}/page/1`,
        },
        { text: t('directInvites.label'), url: '' },
      ]);
  }, []);

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.DIRECT_INVITE]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchDirectInvites(searchValue: string) {
    let isClear = searchValue?.length === 0;
    persistSearch(!isClear, searchValue);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchDirectInvites(q), 500),
    [],
  );

  function handleSearch(searchValue: string): void {
    setSearching(searchValue);
    delayedQuery(searchValue);
    setCurrentPage(1)
  }

  function handleFetch() {
    const directInviteFilter = {
      limit: rowLimit,
      skip: (currentPage - 1) * rowLimit,
      filters: {
        admission: { eq: urlAdmissionId },
        applicationType: { eq: APPLICANT_TYPE_ENUM[0] },
      },
    };

    if (searchValue) {
      directInviteFilter.filters['name'] = { regex: searchValue };
    }
    getAllInvitedDirectApplicants({ variables: directInviteFilter });
  }

  const itemWidth = '50%';

  return (
    <LoadContentWrapper>
      <InfoWrapper>
        <CreateInfoItem
          label={t('admissionName.label')}
          value={admissionData?.name || '-'}
          width={itemWidth}
        />
        <CreateInfoItem
          label={t('batch.label')}
          value={admissionData ? admissionData?.batch?.label : '-'}
          width={itemWidth}
        />
      </InfoWrapper>
      <TopWrapper>
        <SearchWrapper>
          <Search
            id="directInvitesSearch"
            handleChange={handleSearch}
            value={searching}
            label={t('searchDirectInvites.label')}
          />
        </SearchWrapper>
      </TopWrapper>
      <TableDiv>
        <TblContainer overflowX="scroll">
          <TblHead setHeadCells={setHeadCells} />
          {!allInvitedDirectApplicantsLoading ? (
            <>
              {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 && (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: DirectInvited, index: number) => (
                    <TableRows key={`s${index}`}>
                      <TableCells value={item?.name || 'N/A'} style={styles.stringTypeCell} />
                      <TableCells value={item?.capId || 'N/A'} style={styles.numberTypeCell} />
                      <TableCells value={item?.email || 'N/A'} style={styles.stringTypeCell} />
                      <TableCells
                        value={item?.subjectGroup?.name || 'N/A'}
                        style={styles.stringTypeCell}
                      />
                      <TableCells
                        value={item?.id || 'N/A'}
                        align={'right'}
                        style={styles.codeTypeCell}
                      />
                      <TableCells
                        value={item?.date ? format(item?.date, DT.DATE_TIME_SLASH_12_HOUR) : 'N/A'}
                        align={'right'}
                        style={styles.dateTypeCell}
                      />
                      <TableCells
                        value={
                          item?.invitedBy && item.invitedBy?.personalDetails
                            ? properCase(item.invitedBy.personalDetails?.fullName ?? '-')
                            : 'N/A'
                        }
                        style={styles.stringTypeCell}
                      />
                      <TableCells value={item?.status || 'N/A'} style={styles.stringTypeCell} />
                    </TableRows>
                  ))}
                </TableBody>
              )}
              {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length === 0 && (
                <NoRecordsFound colspan={6} maxHeight={maxHeight - paginationHeightOffset} />
              )}
            </>
          ) : (
            <Spinner>
              <LoaderSpinner />
            </Spinner>
          )}
        </TblContainer>
          <Pagination
            pathName={`direct-invitations/${urlAdmissionId}`}
            total={totalCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            rowLimit={rowLimit}
            setRowLimit={setRowLimit}
            hidePagination={!!(totalCount && totalCount <= rowLimit)}
          />
        {/* )} */}
      </TableDiv>
    </LoadContentWrapper>
  );
}

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const TopWrapper = styled(FlexRow)`
  justify-content: space-between;
`;

const SearchWrapper = styled(FlexRow)`
  align-items: flex-start;
  max-width: 280px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 16px;
  margin-bottom: 16px;
`;

export default DirectInvitesList;
