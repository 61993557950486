import { gql, useMutation } from '@apollo/client';
import { readCacheQuery, writeCacheQuery } from '../util';
import { ANNOUNCEMENT_TYPE, EVENT_LIMIT, FILTERS, NEWS_LIMIT, NOTICE_LIMIT } from 'src/constant';
import { createDeleteData, createSearchFilter } from 'src/components/services'
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';

const getAnnouncementsQuery = gql`
  query ($limit: Int, $skip: Int, $filters: AnnouncementFilter!, $timezone: String) {
    getAnnouncements(limit: $limit, skip: $skip, filters: $filters, timezone: $timezone) {
      hasNextPage
      totalCount
      data {
        id
        body
        contentImage {
          documentUrl
          id
          name: documentName
          uri: documentUrl
          fileType
        }
        audienceType
        audience
        audienceLabels{
          label
        }
        location
        mstartTime
        mendTime
        attachments {
          id
          name: documentName
          uri: documentUrl
          fileType
        }
        title
        type
        mdate
        createdAt
        mcreatedAt
        createdBy{
          id
        }
        timezone
      }
    }
  }
`;

const addAnnouncementsMutation = gql`
  mutation ($payload: AnnouncementInput!) {
    createAnnouncement(payload: $payload) {
      id
      body
      contentImage {
        documentUrl
      }
      location
      mstartTime
      mendTime
      mcreatedAt
      attachments {
        id
        documentName
        documentUrl
      }
      timezone
      title
      type
      mdate
      createdAt
      createdBy{
          id
        }
    }
  }
`;

const getClassDivision = gql`
  query {
    class(filters: $filters) {
      data {
        divisions
      }
    }
  }
`;

const removeAnnouncementsMutation = gql`
  mutation ($id: String!) {
    removeAnnouncement(id: $id) {
      message
      status
    }
  }
`;

export { getAnnouncementsQuery, getClassDivision, removeAnnouncementsMutation };

const deleteAnnouncementDetailMutation = gql`
  mutation ($id: String!) {
    removeAnnouncement(id: $id) {
      status
      message
    }
  }
`;

export function useAddAnnouncementsMutation(annoucementType: string) {
  const [mutate, { data: employeeData, error: employeeError }] =
    useMutation(addAnnouncementsMutation);
  return { mutate, employeeData, employeeError };
}

export function useDeleteAnnouncementsMutation() {
  const [deleteAnnouncement, { error: deleteAnnouncementError }] = useMutation(
    deleteAnnouncementDetailMutation,
  );
  return { deleteAnnouncement, deleteAnnouncementError };
}

function checkIndex(inp: number) {
  for (let j = 1; j < inp; j++) {
    let max = j * EVENT_LIMIT
    let min = max - (EVENT_LIMIT - 1)
    if (min <= inp && inp <= max) {

      return max;
    }
  }
  return 0;
}

function getEventFilter(eventIndex: number, searchValue: string) {

  let basicFilter = {
    type: {
      eq: ANNOUNCEMENT_TYPE[2],
    },
    date: {
      gte: format(new Date(), DT.DATE_FORMAT_SLASH),
    }
  }
  let limit = EVENT_LIMIT;
  let filters = [] as any;
  filters = [
    {
      field: 'title',
      type: FILTERS.REGEX,
      value: searchValue,
    },
    {
      field: 'body',
      type: FILTERS.REGEX,
      value: searchValue,
    },
  ];

  let maxBrack = checkIndex(eventIndex);
  let canSkip = eventIndex == null ? 0 : maxBrack
  let announceFilter = createSearchFilter(limit, canSkip, filters);
  announceFilter['filters'] = { ...announceFilter['filters'], ...basicFilter }
  return announceFilter;
}


function getAnnouncementFilter(eventIndex:number, searchValue:string, typeAnnouncement:string){
  let basicFilter={
    type: {
      eq: typeAnnouncement,
    }
  }
  let limit  = typeAnnouncement? NEWS_LIMIT : NOTICE_LIMIT;
  let filters = [] as any;
    filters = [
      {
        field: 'title',
        type: FILTERS.REGEX,
        value: searchValue,
      },
      {
        field: 'body',
        type: FILTERS.REGEX,
        value: searchValue,
      },
    ];
  
  let maxBrack = checkIndex(eventIndex);
  let canSkip = eventIndex==null? 0 : maxBrack
  let announceFilter = createSearchFilter(limit, canSkip, filters);
  announceFilter['filters'] = {...announceFilter['filters'], ...basicFilter}
  return announceFilter;
}

export function useDeleteAnnoucementMutation(passIndex:number, searchValue:string, announceId:string, typeAnnouncement:string) {
 
  const [announcementDeletion, { error : deleteAnnouncementError }] = useMutation(deleteAnnouncementDetailMutation, {
    update: (cache, { data: { deleteEventData } }) => {
      let queryData;
      let upcomingParam = getAnnouncementFilter(passIndex, searchValue, typeAnnouncement);
      
      //read
      try {
      queryData = readCacheQuery(cache, getAnnouncementsQuery, upcomingParam);
     
      }catch (e) {
        //console.log(e.messgae);
      }
    
      let dataToUpdate = createDeleteData(queryData?.getAnnouncements, announceId)
      // write
        try {
          writeCacheQuery(
            cache,
            getAnnouncementsQuery,
            upcomingParam,
            {
              getAnnouncements: {
                 data : dataToUpdate,
                 hasNextPage : queryData?.getAnnouncements?.hasNextPage,
                 totalCount : queryData?.getAnnouncements?.totalCount - 1,
                 __typename: "PAnnouncementOutput"
              }
            }
          );
        } catch (e) {
          //console.log('error', e.messgae);
        }
    }
  });

  return { announcementDeletion, deleteAnnouncementError };

}


export function useDeleteEventMutation(eventIndex:number|null, searchValue:string, eventId:string) {
  const [eventDeletion, { error : deleteEventError }] = 
    useMutation(deleteAnnouncementDetailMutation, {
      update: (cache, { data: { deleteEventData } }) => {
        let queryData;
        let upcomingParam = getEventFilter(eventIndex, searchValue);
        
        //read
        try {
        queryData = readCacheQuery(cache, getAnnouncementsQuery, upcomingParam);
       
        } catch (e) {
          //console.log(e.messgae);
        }
        let dataToUpdate = createDeleteData(queryData?.getAnnouncements, eventId)
        // write
          try {
            writeCacheQuery(
              cache,
              getAnnouncementsQuery,
              upcomingParam,
              {
                getAnnouncements: {
                   data : dataToUpdate,
                   hasNextPage : queryData?.getAnnouncements?.hasNextPage,
                   totalCount : queryData?.getAnnouncements?.totalCount - 1,
                   __typename: "PAnnouncementOutput"
                }
              }
            );
          } catch (e) {
            //console.log('error', e.messgae);
          }
      }
    });

  return { eventDeletion, deleteEventError };
}