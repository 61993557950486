import React, { useState, useEffect, Fragment } from 'react';
import { View, StyleSheet } from 'react-native';
import eventFormJSON from '../../../form-json/events/events.json';
import Element from '../Forms/ApplicationElement';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import {
  ANNOUNCEMENT_TYPE,
  EVENT_DOCUMENT_TYPE,
  FileCategory,
} from 'src/constant';
import { isWeb, width } from 'src/constant/device';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { breakpoints } from 'src/styles/theme/styles';
import AttachImages from 'src/components/atoms/AttachFiles/AttachImages';
import { graphqlQuery } from 'src/graphql/util';
import { getAnnouncementsQuery } from 'src/graphql/communication/news-notices';
import { useParams } from 'src/routes/routing';
import { useI18n } from 'src/i18n/hooks';
import { AttachmentType, CustomReactNativeFile } from '../FileUpload/type';
import { createAttachmentsObject, createDocumentObject, imageTypes } from 'src/utils/utility';
import { DT } from 'src/constant/dateTime';
import { format, parse } from 'date-fns';
import AttachFiles from 'src/components/atoms/AttachFiles/index';

export interface documentObjectType {
  documentName: string;
  document: File;
  type: string;
  tokenData?: string;
}

const TIME_PICKER = 'time_picker_group';
const DATE_PICKER = 'date';
const isDesktop = width > breakpoints.md;

export default function EventForm(props: any) {
  const {
    imageAttachments,
    setImageAttachments,
    fileAttachments,
    setFileAttachments,
    isEditModal,
    setValue,
    eventData,
    isDynamic = false,
    setRemovedImages,
    removeImages,
    tokenData,
    setError,
    clearErrors,
  } = props;
  const { eventId }: any = isWeb ? useParams() : {};
  const [loading, setLoading] = useState<boolean>(Boolean(isWeb && isEditModal));
  const [elements, setElements] = useState({});
  const { t } = useI18n();
  const { fields }: any = elements ?? {};
  let eventInformation = { ...eventData };

  useEffect(() => {
    setElements(eventFormJSON[0]);
    if (isEditModal) {
      getEventInformation();
    }
  }, []);

  async function getEventInformation() {
    if (isDynamic) {
      let eventFilter = {
        type: {
          eq: ANNOUNCEMENT_TYPE[2],
        },
        id: {
          eq: eventId,
        },
      };
      const { loading, data: announcementData } = await graphqlQuery(
        getAnnouncementsQuery,
        { filters: eventFilter, timezone: DT.TIMEZONE },
        true,
      );
      if (!loading && announcementData) {
        eventInformation = announcementData?.getAnnouncements?.data[0];
        loadBasicdata();
      }
    } else {
      loadBasicdata();
    }
  }

  function loadBasicdata() {
    setValue('title', eventInformation?.title);
    setValue('location', eventInformation?.location);
    setValue('body', eventInformation?.body);
    const parsedDate = parse(eventInformation.mdate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date());
    const parsedStartTime = parse(eventInformation.mstartTime, DT.TIME_24_HOUR, new Date());
    const parsedEndTime = parse(eventInformation.mendTime, DT.TIME_24_HOUR, new Date());
    const timeFormat = isWeb ? DT.TIME_24_HOUR_WITH_COLON : DT.TIME_12_HOUR;
    const startTime = format(parsedStartTime, timeFormat);
    const endTime = format(parsedEndTime, timeFormat);
    const newDate = isWeb ? parsedDate : format(parsedDate, DT.DATE_FORMAT_SLASH);
    setValue('date', newDate);
    setValue('startTime', startTime);
    setValue('endTime', endTime);

    if (eventInformation?.attachments && eventInformation.attachments.length > 0) {
      createAttachmentsObject(
        eventInformation.attachments,
        setImageAttachments,
        setFileAttachments,
        setLoading,
        tokenData,
        FileCategory.Event,
      );
    } else {
      setLoading(false);
    }
  }

  const handleFilesAttach = async (files: CustomReactNativeFile[]) => {
    const newAttachments = await Promise.all(
      files.map((file) => createDocumentObject(file, FileCategory.Event, EVENT_DOCUMENT_TYPE)),
    );
    if (newAttachments) {
      newAttachments.forEach((item: any) => {
        if (imageTypes.includes(item?.document?.type)) {
          setImageAttachments([...imageAttachments, ...newAttachments])
        } else {
          setFileAttachments([...fileAttachments, ...newAttachments]);
        }
      })
    }
  };

  function removeAttachment(idx: any, type: string) {
    let attachmentArray = type === 'image' ? [...imageAttachments] : [...fileAttachments];
    let imageId = attachmentArray[idx].id;
    attachmentArray.splice(idx, 1);
    if (type === 'image') {
      setImageAttachments(attachmentArray);
    } else {
      setFileAttachments(attachmentArray);
    }
    if (isEditModal && imageId) {
      storedRemoveId(imageId);
    }
  }

  function storedRemoveId(idx: any) {
    let removeIdArray = [...removeImages];
    removeIdArray.push(idx);
    setRemovedImages(removeIdArray);
  }

  function zindexHandler(id: string) {
    if (id == DATE_PICKER) {
      return 3;
    } else if (id == TIME_PICKER) {
      return 1;
    } else {
      return -1;
    }
  }

  return (
    <View testID='EventForm'>
      {fields ? (
        fields.map((field: any, i: number) =>
          field?.id !== TIME_PICKER ? (
            <View style={{ zIndex: zindexHandler(field.id) }} key={`form${i}`}>
              {field?.id !== 'attachmentLabel' ? (
                <Element
                  key={i}
                  field={field}
                  isCustomDisable={field.isCustomDisable && isEditModal}
                  control={props.control}
                  errors={props.errors}
                  handleFilesAttach={handleFilesAttach}
                  fileWidth={350}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              ) : (
                <Fragment key={`sub${i}`}>
                  {(imageAttachments?.length > 0 || fileAttachments?.length > 0) && (
                    <View
                      style={{ marginBottom: 8 }}>
                      <SmallTextAtom value={t(field?.textHead)} />
                    </View>
                  )}
                  {!loading ? (
                    <View
                      style={styles.attachWrapper}>
                      {imageAttachments?.map((fileData: AttachmentType, i: number) => {
                        return (
                          <View style={[styles.imageView]} key={`attachload${i}`}>
                            <AttachImages
                              key={`attach${i}`}
                              fileData={fileData}
                              onPress={() => removeAttachment(i, 'image')}
                            />
                          </View>
                        )
                      })}
                      {fileAttachments?.map((fileData: any, i: number) => {
                        return (
                          <View style={[styles.attachView]} key={`attachload${i}`}>
                            <AttachFiles
                              key={`attach${i}`}
                              fileData={fileData}
                              onPress={() => removeAttachment(i, 'file')}
                            />
                          </View>
                        )
                      })}
                    </View>
                  ) : (
                    <View style={[styles.spineerView]}>
                      <LoaderSpinner />
                    </View>
                  )}
                </Fragment>
              )}
            </View>
          ) : (
            // neet loop here
            <View
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              key={`form${i}`}>
              <View style={{ zIndex: zindexHandler(field.id), flex: 1, marginRight: 10 }}>
                <Element
                  key={i}
                  field={field.children[0]}
                  control={props.control}
                  errors={props.errors}
                  handleFilesAttach={handleFilesAttach}
                  isCustomDisable={field.children[0].isCustomDisable && isEditModal}
                />
              </View>

              <View
                style={{
                  zIndex: zindexHandler(field.id),
                  flex: 1,
                }}>
                <Element
                  key={i}
                  field={field.children[1]}
                  control={props.control}
                  errors={props.errors}
                  handleFilesAttach={handleFilesAttach}
                  isCustomDisable={field.children[1].isCustomDisable && isEditModal}
                />
              </View>
            </View>
          ),
        )
      ) : (
        <View style={[styles.spinnerView]}>
          <LoaderSpinner />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
  imageView: { marginRight: isWeb ? 24 : 12, marginBottom: 16 },
  attachView: { marginRight: isWeb ? 24 : 11, marginBottom: isWeb ? 16 : 0 },
  attachWrapper: { flexDirection: 'row', flexWrap: 'wrap' },
  spineerView: {
    height: 120,
    justifyContent: 'center',
    alignItems: 'center',
  },
});