import {
  DashboardEmptyContainer as EmptyContainer,
  DemographicsScrollContainer as ScrollContainer,
} from "src/components/organism/Dashboard/Common/CommonHelpers";

import AbstractFlashList from "src/components/atoms/FlashList";
import CardTitleBlock from "../CardTitleBlock";
import { InstituteItem } from "../../atoms/DashboardItems";
import React from "react";
import ShadowBox from "src/components/atoms/ShadowBox";
import { defaultPagingURLSegment } from "../Navigation/SidebarManifest";
import { faUniversity } from "@fortawesome/pro-regular-svg-icons";
import { getInstitutesOverview } from "src/graphql/dashboard/index";
import { useI18n } from "src/i18n/hooks";
import { useNavigate } from "src/routes/routing.web";
import { useQuery } from "@apollo/client";

type instData = {
  id: string;
  name: string;
  addressDetails: {
    city: string;
  };
};

export const InstituteList = () => {
  const { t } = useI18n();
  const navigate = useNavigate();

  const { data, loading } = useQuery(getInstitutesOverview);

  const institutes: instData[] = data ? data?.institutes?.data : [];

  const triggerInstituteList = () => {
    navigate(`/institutes/${defaultPagingURLSegment}`);
  };

  const ListEmptyComponent = (
    <EmptyContainer
      text={t("noInstitute.text")}
      icon={faUniversity}
      loading={loading}
    />
  );

  return (
    <>
      <CardTitleBlock
        titleText={t("instituteList.label")}
        showButton={institutes.length > 0}
        buttonText={t("viewAll.text")}
        cb={triggerInstituteList}
      />
      <ShadowBox>
        <ScrollContainer>
          <AbstractFlashList<instData>
            estimatedItemSize={81}
            ListEmptyComponent={ListEmptyComponent}
            data={institutes}
          >
            {({ item, index }) => (
              <InstituteItem
                name={item?.name}
                location={item?.addressDetails?.city}
                id={item.id}
                index={index}
                length={institutes.length}
              />
            )}
          </AbstractFlashList>
        </ScrollContainer>
      </ShadowBox>
    </>
  );
};
