import { TableCells, TableRows } from '../../Table/TableAtom';
import React, { useState } from 'react';
import { TableBody } from '@mui/material';
import styled from 'styled-components/native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import useTable from '../../Table/UseTable';
import { height } from 'src/constant/device';
import { getTableValue, properCase } from 'src/utils/utility';
import { InfoSquareIcon } from 'src/components/atoms/ActionIcons';
import { useI18n } from 'src/i18n/hooks';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import ViewResultModal from './ViewResultModal';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faUserGraduate } from '@fortawesome/pro-light-svg-icons';
import { colors } from 'src/styles/theme/styles';

interface PropsType {
  tableData: any;
  isDataAvailable: boolean;
  examName: string;
  marksType: boolean;
}

const headCells1 = [
  {
    id: 'studentName',
    label: 'studentName.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: '150px' },
  },
  {
    id: 'rollNo',
    label: 'rollno.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: '50px' },
  },
];

const marksTypeHeadCells = [
  {
    id: 'average',
    label: 'average.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '100px' },
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '50px' },
  },
];

const gradeTypeHeadCells = [
  {
    id: 'creditsEarned',
    label: 'credits-earned.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '100px' },
  },
  {
    id: 'creditGrade',
    label: 'credit-grade.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '100px' },
  },
  {
    id: 'sgpa',
    label: 'sgpa.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '50px' },
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: '50px' },
  },
];

const StudentDetailsModal = (props: PropsType) => {
  const { tableData, isDataAvailable, examName, marksType } = props;
  const [headCells, setHeadCells] = useState([...headCells1, ...(marksType ? marksTypeHeadCells : gradeTypeHeadCells)]);
  const [viewResultModalState, handleViewResultModal] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const { t } = useI18n();

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    tableData,
    headCells,
    null,
    tableData?.length,
  );

  const handleViewResult = (item: any) => {
    setCurrentItem({
      marksheet: { ...item?.marks, subjectMarks: item?.subjectMarks },
      user: item?.marks?.user,
    });
    handleViewResultModal(true);
  };

  const closeViewResultModal = () => {
    handleViewResultModal(false);
  };

  return (
    <>
      {tableData?.length > 0 ? (
        <TblContainer height={'100%'}>
          <TblHead setHeadCells={setHeadCells} />
          <TableBody>
            {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
              <TableRows key={`e${i}`}>
                <TableCells
                  value={
                    item?.marks?.user?.personalDetails?.fullName
                      ? properCase(item.marks.user.personalDetails.fullName)
                      : 'N/A'
                  }
                />
                <TableCells
                  value={
                    (item &&
                      item?.marks?.user &&
                      item?.marks?.user?.academic &&
                      item?.marks?.user?.academic?.rollNo) ||
                    'N/A'
                  }
                  align={'left'}
                />
                {marksType ? (
                  <TableCells value={getTableValue(item?.marks?.averageOnAggregateMarks)} align={'right'} />
                ) : (
                  <>
                    <TableCells value={getTableValue(item?.marks?.creditsEarned)} align={'right'} />
                    <TableCells value={getTableValue(item?.marks?.creditGrades)} align={'right'} />
                    <TableCells value={getTableValue(item?.marks?.sgpa)} align={'right'} />
                  </>
                )}
                <TableCells align="right">
                  <ActionIcons>
                    <InfoSquareIcon
                      onPress={() => handleViewResult(item)}
                      tooltipTitle={t('view-result.label')}
                    />
                  </ActionIcons>
                </TableCells>
              </TableRows>
            ))}
          </TableBody>
        </TblContainer>
      ) : (
        <SpinnerView>
          {isDataAvailable ? (
            <NoStudentsWrapper>
              <FontAwesomeIcon icon={faUserGraduate} size="3x" color={colors.gray} />
              <HeaderThree
                value={t('noStudentsList.label')}
                color={colors.secondaryText}
                fontWeight={'bold'}
              />
              <NormaltextAtom value={t('noStudentsList.desc')} />
            </NoStudentsWrapper>
          ) : (
            <LoaderSpinner />
          )}
        </SpinnerView>
      )}

      <NormalModal
        setModalVisible={closeViewResultModal}
        Headerpopup={t('results.label')}
        modalVisible={viewResultModalState}
        cancelButtonLabel={'done.label'}
        height={0.7 * height}
        maxWidth={'xl'}
        hideSubmit={false}
        handleSave={() => {}}>
        <ViewResultModal
          closeModal={closeViewResultModal}
          currentItem={currentItem}
          selectedExamName={examName}
          type={'resolution'}
          marksType={marksType}
        />
      </NormalModal>
    </>
  );
};

const NoStudentsWrapper = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: ${0.4 * height};
  gap: 10;
`;

const SpinnerView = styled.View`
  height: 568px;
  display: contents;
`;

const ActionIcons = styled.View`
  display: flex;
  align-items: flex-end;
`;

export default StudentDetailsModal;
