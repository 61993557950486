import React, { useState, Fragment, useEffect, useCallback } from 'react'
import { View } from 'react-native'
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import MobileHeader from 'src/components/hoc/MobileHeader';
import classAudienceJSON from '../../../form-json/students/class-audience.json'; 
import { useForm } from 'react-hook-form';
import Element from '../../molecules/Forms/ApplicationElement';
import { useQuery } from '@apollo/client';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { mergeBatchClassLabel } from 'src/utils/utility';
import { AUTOCOMPLETE_MODULE } from 'src/constant';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import navigationStrings from 'src/navigation/navigationStrings';
import { useFocusEffect } from '@react-navigation/native';

function StudentBatchSelection(props: any) {
    const { t } = useI18n();
    const [elements, setElements] = useState<any>({});
    const [batch, setBatch] = useState<any>({});
    const [batches, setBatches] = useState<any>({});
    const { fields }: any = elements ?? {};

    const {
      handleSubmit,
      control,
      setValue,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm();

    const allBatches = useQuery(getBatchQuery, {
      variables: { limit: 0, skip: 0 },
    });

    useFocusEffect(
      useCallback(() => {
      clearErrors();
    }, []));

    useEffect(() => {
      onLoadFunction();
    }, [allBatches.data]);

    useEffect(() => {
      if (batch && batch.divisionDetails) {
        loadDivisions(batch.divisionDetails?.map((det) => det.division));
      }
    }, [batch]);

    function resetForm() {
      setValue('batch', null);
      setValue('division', null);
    }

    function loadDivisions(divisionArray: any) {
      const division = [] as any;
      divisionArray.forEach(function (item: any) {
        division.push({
          label: item,
          value: item
        })
      });
      classAudienceJSON[0].fields[1].option = division;
      const newData = {...classAudienceJSON[0]};
      setElements(newData);
    }

    function onLoadFunction() {
      if (allBatches.loading === false) {
        let getdata = mergeBatchClassLabel(allBatches.data?.batches?.data);
        classAudienceJSON[0].fields[0].option = getdata;
        setElements(classAudienceJSON[0]);
        setBatches(getdata);
      }
    }

    function handleCustomSelect(id: string, data: any) {
      if (id === AUTOCOMPLETE_MODULE.BATCH) {
        data = batches.find((bt: any) => bt.value === data);
        setValue(AUTOCOMPLETE_MODULE.DIVISION, null);
        setBatch(data);      
      }
    }

    function handleStudents(formData: any) {
      if (formData.batch && formData.division) {
        props.navigation.navigate(navigationStrings.STUDENT_LIST, {
          selectedFormValues: formData,
          batch
        });
      }
    }

    return (
      <Fragment>
        <Container>
          <MobileHeader label={t('students.label')} backIcon={false} {...props} />
          <ClassAudienceWrapper>
            {fields ? (
              fields.map((field: any, i: number) => {
                return <View>
                  <Element
                    key={`class${i}`}
                    field={field}
                    control={control}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    handleCustomSelect={handleCustomSelect}
                  />
                </View>
              })
            ) : null}
            {fields && (
              <SecondaryBtn
                onPress={handleSubmit(handleStudents)}
              lineHeight={2}
                label={t('next.label')} />
            )}
          </ClassAudienceWrapper>
        </Container>
      </Fragment>
    )
}

const Container = styled.View`
  background-color: #ffffff;
  height: 100%;
`;

const ClassAudienceWrapper = styled.View`
  padding: 24px;
`;

export default StudentBatchSelection;