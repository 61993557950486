import { DropdownOptions } from "src/types";

export type TGraceData = {
  grace: number;
  graceName: string;
  count: number;
  marksheets: any[]; // TODO:: FIX THIS TYPE (replace any)
};

export type TGraceResolutionDropdownOption = TGraceData & DropdownOptions;

const generateGraceByResolutionDropdownOptions = (
  graceData: TGraceData[]
): TGraceResolutionDropdownOption[] => {
  if (Array.isArray(graceData)) {
    return graceData.map((g) => ({ ...g, label: g.graceName, value: g.graceName }));
  }
  return [];
};

export const resolutionHelpers = {
  generateGraceByResolutionDropdownOptions,
};
