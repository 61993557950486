import React, { CSSProperties } from "react";
import { SxProps, Theme } from "@mui/material";

import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import NormalTextSelect from "../Text/NormalTextSelect";
import { colors } from "src/styles/theme/styles";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  buttonData: {
    key?: string;
    value: string;
    onClick: () => void;
    onArrowClick: () => void;
    onChange?: (e: any) => void;
  };
  customDisabled?: boolean;
  //MUI button prop to provide form element like label which is important to toggle between file uploader or custom modal
  component?: string;
  buttonHeight?: number;
}

export default function SplitButton({
  buttonData,
  component,
  customDisabled,
  buttonHeight = 32
}: Props) {
  const { value, onClick, onArrowClick, onChange } = buttonData;
  const ComponentProps: SxProps<Theme> = {
    backgroundColor: customDisabled ? colors.primary : "",
    opacity: customDisabled ? 0.5 : 1,
    height: buttonHeight
  };

  return (
    <div>
      <Button
        style={styles.text}
        sx={ComponentProps}
        variant="contained"
        onClick={onClick}
        component={component}
        disabled={customDisabled}
        disableElevation={true}
      >
        <NormalTextSelect value={value} color="white" />
        <input
          style={styles.input}
          multiple
          type="file"
          accept=".csv,.xls,.xlsx"
          onChange={onChange}
        />
      </Button>

      <Button
        aria-label="options"
        aria-controls="split-button-menu"
        aria-haspopup="true"
        onClick={onArrowClick}
        variant="contained"
        disabled={customDisabled}
        disableElevation={true}
        style={styles.importButtonView}
        sx={ComponentProps}
      >
        <FontAwesomeIcon icon={faAngleDown} color={colors.white} />
      </Button>
    </div>
  );
}

const styles = {
  text: {
    textTransform: "none",
    gap: 4,
    paddingTop: 0,
    paddingBottom: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    color: colors.white,
    backgroundColor: colors.primary,
  } as CSSProperties,
  importButtonView: {
    minWidth: 28,
    width: 28,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: 0,
    marginLeft: 1,
    cursor: "pointer",
    backgroundColor: colors.primary,
    marginRight: "6px",
  } as CSSProperties,
  input: {
    display: "none",
  } as CSSProperties,
};
