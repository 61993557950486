import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { StyleSheet, View } from 'react-native';
import SmallTextAtom from '../Text/SmallTextAtom';
import { colors, fonts } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
interface Props {
  handleChange: (e: any) => void;
  id: string;
  label?: string;
  header?: string;
  width?: string | number;
  height?: number;
  value?: any;
  notefooter?: string;
}

export default function Search({
  label = 'Search',
  width = 280,
  height,
  handleChange,
  id,
  header = '',
  value = '',
  notefooter = '',
}: Props) {
  const { theme, changeTheme } = useThemeSystem();
  const { rem }: any = useTheme();
  const { t } = useI18n();

  const searchInputOnchange = (e: any) => {
    handleChange(e.target.value);
  };

  return (
    <>
      {header && (
        <View style={styles.helperText}>
          <SmallTextAtom value={t(header)} />
        </View>
      )}

      <View style={styles.container}>
        <CustomTextField
          variant="outlined"
          id={id}
          InputProps={{
            startAdornment: [
              <CustomIcon position="start" key={0}>
                <FontAwesomeIcon icon={faSearch} size="sm" color={colors.tertiaryText} />
              </CustomIcon>,
            ],
          }}
          inputProps={{
            style: {
              width: width,
              height: height || rem(3.2),
              padding: 0,
            },
          }}
          value={value}
          placeholder={label}
          // classes={{ root: classes.root }}
          onChange={(e) => searchInputOnchange(e)}
        />

        {notefooter !== '' ? (
          <View style={styles.noteFooter}>
            <SmallTextAtom value={t(notefooter)} color={theme?.input?.bottomLabel} />
          </View>
        ) : null}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  helperText: {
    marginBottom: 4,
  },
  noteFooter: {
    marginTop: 4,
  }
});

const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-root {
      color: ${colors.secondaryText};
      font-family: ${fonts.regular};
      font-size: 15px;
      background-color: ${(props : any) => props?.theme?.search?.backgroundColor};
      & .MuiOutlinedInput-input {
        color: ${colors.secondaryText};
      }
      & .MuiInputBase-input {
        color: ${colors.primaryText};
      }
      & fieldset {
        border-color: ${(props: any) => props?.theme?.search?.borderColor};
      }
      &:hover fieldset {
        border-color: ${(props: any) => props?.theme?.search?.hoverBorder};
      }
      &.Mui-focused fieldset {
        border-color: ${(props: any) => props?.theme?.search?.activeborder};
        box-shadow: 0px 0px 5px ${colors.searchShadow};
        border-width: 1;
      }
    }
`;

const CustomIcon = styled(InputAdornment)`
  .MuiInputAdornment-root {
    color: ${colors.secondaryText};
    font-family: ${fonts.regular};
  }
`;
