// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tb {
  border-collapse: collapse;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-align: left;
  padding: 15.5px 8px;
  line-height: 16px;
  font-family: SFProRounded-Bold;
  font-size: 12;
  color: rgba(0, 0, 0, 0.4);
}

tr {
  cursor: initial !important;
}

td {
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  padding: 18.5px 8px;
  line-height: 20px;
  font-family: SFProRounded-Regular;
  font-size: 15;
  cursor: default;
}

.chip-edit .MuiTooltip-tooltip {
  padding: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/Table/institute.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,2CAA2C;AAC7C;;AAEA;EACE,2CAA2C;EAC3C,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,8BAA8B;EAC9B,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,iCAAiC;EACjC,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".tb {\n  border-collapse: collapse;\n  width: 100%;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n}\n\nth {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n  text-align: left;\n  padding: 15.5px 8px;\n  line-height: 16px;\n  font-family: SFProRounded-Bold;\n  font-size: 12;\n  color: rgba(0, 0, 0, 0.4);\n}\n\ntr {\n  cursor: initial !important;\n}\n\ntd {\n  text-align: left;\n  color: rgba(0, 0, 0, 0.8);\n  padding: 18.5px 8px;\n  line-height: 20px;\n  font-family: SFProRounded-Regular;\n  font-size: 15;\n  cursor: default;\n}\n\n.chip-edit .MuiTooltip-tooltip {\n  padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
