import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../styles/theme/styles';
import List from 'src/components/atoms/AttendanceList';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { height } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { graphqlQuery } from 'src/graphql/util';
import {
  dayWiseAttendence,
  slotsWiseAttendance,
  slotsWiseUserAttendance,
} from 'src/graphql/attendance';
import AttendanceNotConfigured from 'src/components/organism/Attendance/AttendanceNotConfigured';
import { DT } from 'src/constant/dateTime';
import LeaveCalendar from 'src/components/atoms/Calendars/LeaveCalendar';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import IndicatorBadge from 'src/components/atoms/Badge/IndicatorBadge';
import { generateMarkedDatesObject } from 'src/components/atoms/Calendars/CalendarHelpers';
import { parse } from 'date-fns';
import { filterAbsentDates } from './AttendanceHelpers';
import { properCase, stableSortStrings } from 'src/utils/utility';
import { AttendanceData, AttendanceListData, DayWiseAttendanceListData, SlotInfo } from '../AttendanceTypes';
import { StudentListAttendanceHistory } from 'src/components/atoms/AttendanceList/StudentListAttendanceHistory';

const StudentAndClassAttendanceHistory = (props: any) => {
  const { t } = useI18n();
  const {
    selectedBatch,
    selectedDivision,
    selectedAudienceType,
    selectedAttendenceType,
    selectedSlot,
    setSelectedSlot,
    selectedDurationType,
    dates,
    selectedStudentInfo,
    setTotalAttendance,
    setTotalPresents,
  } = props;
  const timezone = DT.TIMEZONE;

  const [currentAttendanceDataList, setCurrentAttendanceDataList] = useState<
    AttendanceData[] | null | []
  >(null);
  const [calendarData, setCalendarData] = useState<null | any[]>(null);
  const [slotWiseAttendanceData, setSlotWiseAttendanceData] = useState<
    AttendanceData[] | null
  >(null);
  const [currentAttendanceData, setCurrentAttendanceData] = useState<DayWiseAttendanceListData[] | null>(null);

  function slotSelector(slotinfo: SlotInfo) {
    setSelectedSlot({
      value: slotinfo.id,
      label: slotinfo.heading,
      slotId: slotinfo.id,
    });
  }

  function setDayWiseAttendanceData(list: DayWiseAttendanceListData[]) {
    const arr: AttendanceData[] = [];

    list.forEach((element) => {
      if (element.data.length > 0) {
        arr.push({
          id: element.user.id,
          heading: properCase(element.user?.personalDetails?.fullName),
          rollNo: element.user?.academic?.rollNo,
          attend: element.present,
          total: element.total,
          percentage: ((element.present / element.total) * 100).toFixed(0),
        });
      }
    });

    setCurrentAttendanceDataList(stableSortStrings(arr, 'rollNo'));
  }

  function setSlotWiseData(list: AttendanceListData[]) {
    const arr: AttendanceData[] = [];
    let presentCount = 0;
    let totalAttendanceCount = 0;

    if (list.length > 0) {
      list.forEach((attendanceInfo) => {
        presentCount += attendanceInfo.present;
        totalAttendanceCount += attendanceInfo.total;
        arr.push({
          id: attendanceInfo.slot,
          heading: attendanceInfo.slotName.toString(),
          attend: attendanceInfo.present,
          total: attendanceInfo.total,
          percentage: (
            (attendanceInfo.present / attendanceInfo.total) *
            100
          ).toFixed(0),
        });
      });

      arr.sort((a, b) => (a.heading ?? "").localeCompare(b.heading ?? ""));

      const getFirstSlotInfo = arr[0];
      setSelectedSlot({
        value: getFirstSlotInfo.id,
        label: getFirstSlotInfo.heading,
        slotId: getFirstSlotInfo.id,
      });
    } else {
      if (!selectedSlot) {
        setCalendarData([]);
        setDayWiseAttendanceData([]);
      }
    }
    setTotalAttendance(totalAttendanceCount);
    setTotalPresents(presentCount);
    setSlotWiseAttendanceData(arr);
  }

  async function getDayWiseAttendaceData() {
    if (currentAttendanceDataList) {
      setCurrentAttendanceDataList(null);
    }
    if (calendarData) {
      setCalendarData(null);
    }
    if (selectedSlot && selectedBatch && selectedDivision.length > 0) {
      const payload = {
        batch: selectedBatch?.value,
        class: selectedBatch?.class?.id,
        division: selectedDivision,
        start: dates.startDate,
        end: dates.endDate,
        slot: selectedSlot?.slotId,
        timezone,
      };

      const { loading, data: currentAttendanceData } = await graphqlQuery(
        dayWiseAttendence,
        {
          payload: selectedStudentInfo
            ? { ...payload, user: selectedStudentInfo.value }
            : payload,
        },
        true
      );
      if (!loading && currentAttendanceData?.dayWiseAttendance?.length) {
        setCurrentAttendanceData(currentAttendanceData?.dayWiseAttendance)
        if (selectedStudentInfo) {
          setCalendarData(currentAttendanceData?.dayWiseAttendance);
          setDayWiseAttendanceData([]);
        } else {
          setDayWiseAttendanceData(currentAttendanceData?.dayWiseAttendance);
          setCalendarData([]);
        }
      } else {
        setCalendarData([]);
        setDayWiseAttendanceData([]);
        setCurrentAttendanceData(null);
      }
    }
  }

  async function getSlotsAttendanceData() {
    if (selectedBatch && selectedDivision.length > 0) {
      const payload = {
        batch: selectedBatch?.value,
        class: selectedBatch?.class?.id,
        division: selectedDivision,
        start: dates.startDate,
        end: dates.endDate,
        timezone,
      };

      setTotalAttendance(0);
      setTotalPresents(0);
      if (slotWiseAttendanceData) {
        setSlotWiseAttendanceData(null);
      }
      const { loading, data } = await graphqlQuery(
        selectedStudentInfo ? slotsWiseUserAttendance : slotsWiseAttendance,
        {
          payload: selectedStudentInfo
            ? { ...payload, user: selectedStudentInfo.value }
            : payload,
        },
        true
      );
      if (
        !loading &&
        (selectedStudentInfo
          ? data?.slotWiseForUser[0]?.data?.length
          : data?.slotWise?.length)
      ) {
        setSlotWiseData(
          selectedStudentInfo ? data?.slotWiseForUser[0]?.data : data?.slotWise
        );
      } else {
        setSlotWiseData([]);
      }
    }
  }

  useEffect(() => {
    getSlotsAttendanceData();
  }, [selectedDurationType, selectedAudienceType, selectedStudentInfo, dates]);

  useEffect(() => {
    getDayWiseAttendaceData();
  }, [
    selectedDurationType,
    selectedAudienceType,
    selectedSlot,
    selectedStudentInfo,
    dates,
  ]);

  function showStudentLeaveCalendar(studentId: string): void {
    // For Attendance Audience type == "Class" (1),
    // displays the leave calendar data for the selected student
    if (studentId && Array.isArray(currentAttendanceData)) {
      const studentData = currentAttendanceData?.find((dataItem) => dataItem?.user?.id === studentId);
      if (studentData && studentData?.data) {
        setCalendarData([{
          ["data"]: studentData.data
        }]);
      }
    }
  }

  return (
    <AttendanceHistoryWrapper>
      {selectedAttendenceType.value !== 0 && (
        <AttendanceList>
          <HeaderWrapper>
            <HeaderFive
              value={
                selectedAttendenceType.value === 2
                  ? t("subjects.label")
                  : t("slots.label")
              }
            />
          </HeaderWrapper>
          {!slotWiseAttendanceData ? (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          ) : (
            <ListWrapper>
              <List
                data={slotWiseAttendanceData}
                selectedSlot={selectedSlot}
                slotSelector={slotSelector}
                type={1}
              />
            </ListWrapper>
          )}
        </AttendanceList>
      )}
      {!selectedStudentInfo && selectedAudienceType.value === 1 && (
        <AttendanceList>
          <HeaderWrapper>
            <HeaderFive value={t("students.label")} />
          </HeaderWrapper>
          {!currentAttendanceDataList ? (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          ) : (
            <ListWrapper>
              <StudentListAttendanceHistory
                data={currentAttendanceDataList}
                showStudentLeaveCalendar={showStudentLeaveCalendar}
              />
            </ListWrapper>
          )}
        </AttendanceList>
      )}
      {/* Individual Student Calendar visible for Full day attendance only */}
      {selectedAttendenceType.value === 0 && (
        <Wrapper>
          <TitleRow>
            <HeaderFive value={t("leaveCalendar.label")} />
            <CalendarInfo>
              <IndicatorBadge color={colors.red} diameter={12} />
              <NormaltextAtom value={t("leaves.label")} />
            </CalendarInfo>
          </TitleRow>
          {!calendarData ? (
            <SpinnerWrapper
              height={
                !calendarData || calendarData?.length === 0 ? "325px" : "256px"
              }
            >
              <LoaderSpinner />
            </SpinnerWrapper>
          ) : calendarData.length === 0 ? (
            <AttendanceNotConfiguredContainer>
              <AttendanceNotConfigured topPosition="22.6%" type={1} />
            </AttendanceNotConfiguredContainer>
          ) : (
            calendarData &&
            calendarData?.length === 1 &&
            calendarData[0]?.data &&
            dates?.startDate && (
              <LeaveCalendarWrapper>
                <LeaveCalendar
                  initialDate={parse(
                    dates.startDate,
                    DT.DATE_FORMAT_SLASH,
                    new Date()
                  )}
                  markedDates={generateMarkedDatesObject(
                    filterAbsentDates(calendarData[0].data)
                  )}
                />
              </LeaveCalendarWrapper>
            )
          )}
        </Wrapper>
      )}
    </AttendanceHistoryWrapper>
  );
};
export default StudentAndClassAttendanceHistory;

const AttendanceHistoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
`;

const AttendanceList = styled.div`
  flex: 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding-right: 2rem;
`;

const ListWrapper = styled.div`
  overflow-y: auto;
  height: ${height - 500};
`;

const Wrapper = styled.div`
  flex: 50%;
  max-width: 500px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 24px;
`;

const LeaveCalendarWrapper = styled.div``;

const AttendanceNotConfiguredContainer = styled.div`
  height: 350px;
`;

const CalendarInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const SpinnerWrapper = styled.div<{ height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height = "325px" }) => height};
`;
