/* eslint-disable react-native/no-inline-styles */
import React, { useState, Fragment, useCallback } from 'react';
import { FlatList, RefreshControl, ScrollView, View } from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import MediumText from 'src/components/atoms/Text/MediumText';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import { height, isMobile, isWeb, width } from 'src/constant/device';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { ERROR, PaymentMode } from 'src/constant';
import NormalModal from 'src/components/atoms/Modals/Normal/index';
import PaymentModal from './PaymentModal';
import { useForm } from 'react-hook-form';
import { NoData } from 'src/components/atoms/NoData';
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons';
import { useCreateStudentFeePayment } from 'src/graphql/payment';
import nttPaymentOptions from '../NTTPaymentOptions';
import { paymentFormData, paymentPayload } from 'src/components/organism/Admission/types';
import { createPaymentDetailObject } from 'src/utils/utility';

const UnPaidPaymentList = (props: any) => {
  const { t } = useI18n();
  const { alertState, setAlertDetails } = useAlertSystem();
  const [makePaymentModal, setMakePaymentModal] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [data, setData] = useState<any>();
  const [canClick, setCanClick] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [mode, setMode] = useState('');
  const { createStudentFeePayment, createStudentFeePaymentData } = useCreateStudentFeePayment();
  const { displayAtomPayNetzPortal, displayAtomPayNetzPortalNative } = nttPaymentOptions(props);

  const {
    paymentList,
    totalPending,
    totalPaid,
    totalPayable,
    partialPayment,
    payableAmount,
    MakePayment,
    userInfo,
    refetch,
    modalMessage,
    setModalMessage,
  } = props;

  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    setError,
    formState: { errors },
  } = useForm();

  const resetStatusForm = () => {
    setValue('mode', null);
    setValue('cheque_number', '');
    setValue('draft_number', '');
    setValue('payment_amount', '');
    setValue('neft_transaction_id', '');
  };

  const closeModal = () => {
    resetStatusForm();
    reset({});
    clearErrors();
    setData(null);
    setShowQR(false);
    setMakePaymentModal(false);
    setMode('');
    if (isMobile) setModalMessage({});
  };

  const handleMakePaymentModal = () => {
    setMakePaymentModal(true);
  }

  const ConcessionComponent = (props: any) => {
    const { concessionArr } = props;
    return concessionArr?.map((concessionInfo: any) => (
      <CommonStyleRowAndSpaceBetween
        style={{
          marginBottom: 28,
          marginRight: 1,
        }}>
        <View style={{ marginRight: 5, flex: 1 }}>
          <NormaltextAtom lines={4} lineHeight={2} value={concessionInfo.name} style={{ width: 150 }} />
        </View>
        <NormaltextAtom
          lineHeight={2}
          value={`-  \u20B9  ${concessionInfo.amount}`}
        />
      </CommonStyleRowAndSpaceBetween>
    ));
  };

  const GroupComponent = (props: any) => {
    const { groupPaymentsItem } = props;
    return (
      <CommonStyleRowAndSpaceBetween
        style={{
          marginBottom: 28,
          marginRight: 1,
        }}>
        <View style={{ marginRight: 5, flex: 1 }}>
          <NormaltextAtom lines={1} lineHeight={2} value={groupPaymentsItem.name} />
        </View>
        <NormaltextAtom
          lineHeight={2}
          value={` \u20B9  ${groupPaymentsItem.amount}`}
        />
      </CommonStyleRowAndSpaceBetween>
    );
  };

  async function handleOnlinePayment() {
    setCanClick(false);
    try {
      const studentFeePaymentResponse = await createStudentFeePayment({
        variables: {
          studentId: userInfo?.user,
        }
      });
      if (studentFeePaymentResponse?.data?.generateStudentPaymentOrder) {
        const resp = studentFeePaymentResponse?.data?.generateStudentPaymentOrder;
        reset({});
        setMakePaymentModal(false);
        if (isWeb) {
          displayAtomPayNetzPortal(resp);
        } else {
          displayAtomPayNetzPortalNative(resp, nativeRefresh);
        }
      }
      setCanClick(true);
    } catch (e: any) {
      if (isWeb) {
        setAlertDetails({ message: e.message, level: ERROR });
      } else {
        setModalMessage({
          message: e.message,
          type: 'danger',
          position: 'top',
          icon: 'danger',
          duration: 600
        });
      }
      setCanClick(true);
    }
  }

  const handleMakePayment = async (formData: paymentFormData) => {
    const mode = isWeb ? formData.mode.value : formData.mode;
    if (mode === PaymentMode.ONLINE) {
      handleOnlinePayment();
    } else {
      setCanClick(false);
      let paymentData: paymentPayload = {
        userDetails: {
          userId: userInfo?.user,
          batchId: userInfo?.batch,
        },
        paymentDetail: {
          mode: mode,
        },
      }

      paymentData = await createPaymentDetailObject(formData, paymentData, false);
      setData(paymentData);
      setShowQR(true);
      setCanClick(true);
    }
  }

  const nativeRefresh = () => {
    refetch();
    setRefreshing(false);
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    nativeRefresh();
  }, []);

  return (
    <Fragment>
      <View style={{ height: '100%' }}>
        {paymentList && paymentList.length > 0 ? (
          <>
            {isWeb ? (
              <TableColHeadWrapper>
                <TableColHead value={t('particulars.label')} />
                <TableColHead value={t('amount.label')} />
              </TableColHeadWrapper>
            ) : (
              <NativeTableHeader>
                <NormaltextAtom value={t('payment.particulars')} />
                <NormaltextAtom value={t('payment.amount')} />
              </NativeTableHeader>
            )}
            <ScrollView
              refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
              scrollEnabled={false}
              contentContainerStyle={{
                flex: 1,
              }}>
              <ContentContainer>
                <FlatList
                  data={paymentList}
                  showsVerticalScrollIndicator={false}
                  contentContainerStyle={
                    isWeb
                      ? {
                          flex: 1,
                          marginVertical: 15,
                          maxHeight: height - 550,
                          marginRight: 50,
                        }
                      : {
                          flexGrow: 1,
                          marginVertical: 15,
                        }
                  }
                  keyExtractor={item => item.id}
                  renderItem={({ item }) => (
                    <View>
                      <ItemHeaderWrapper>
                        <CommonStyleRowAndSpaceBetween
                          style={{
                            flex: 1,
                            paddingBottom: isWeb ? 0 : 3,
                            marginRight: 1,
                          }}>
                          <ModuleNameWrapper>
                            <MediumText
                              lines={1}
                              value={item.groupName}
                              lineHeight={2.4}
                              color={colors.primaryText}
                            />
                          </ModuleNameWrapper>
                          <MediumTextSelect
                            fontWeight={isWeb ? 'bold' : '600'}
                            value={`\u20B9  ${item?.amount}`}
                            color={colors.primaryText}
                          />
                        </CommonStyleRowAndSpaceBetween>
                      </ItemHeaderWrapper>
                      {item.isGroup ? (
                        <ChildrenItemHeaderWrapper>
                          {item?.fees?.map((ele: any) => (
                            <>
                              <GroupComponent groupPaymentsItem={ele} />
                              {ele?.concessions.length > 0 && (
                                <ConcessionComponent concessionArr={ele.concessions} />
                              )}
                            </>
                          ))}
                        </ChildrenItemHeaderWrapper>
                      ) : (
                        item?.fees &&
                        item.fees?.length > 0 &&
                        item.fees[0]?.concessions?.length > 0 && (
                          <ChildrenItemHeaderWrapper>
                            <ConcessionComponent concessionArr={item.fees[0].concessions} />
                          </ChildrenItemHeaderWrapper>
                        )
                      )}
                    </View>
                  )}
                />
              </ContentContainer>
              <BorderLineWrapper />
              <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={true}>
                <MediumTextSelect value={t('totalPayable.label')} />
                <MediumTextSelect value={`\u20B9 ${totalPayable}`} />
              </TotalPaymentAndSelectedPayableAmountWrapper>

              <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={true}>
                <MediumTextSelect value={t('totalPaid.label')} />
                <MediumTextSelect value={`\u20B9 ${totalPaid}`} />
              </TotalPaymentAndSelectedPayableAmountWrapper>

              <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={Boolean(partialPayment)}>
                <MediumTextSelect
                  value={t('totalPending.label')}
                  color={Boolean(partialPayment) ? colors.secondaryText : colors.primaryText}
                  fontFamily={Boolean(partialPayment) ? fonts.regular : fonts.semibold}
                />
                <MediumTextSelect
                  value={`\u20B9 ${totalPending}`}
                  color={Boolean(partialPayment) ? colors.secondaryText : colors.primaryText}
                  fontFamily={Boolean(partialPayment) ? fonts.regular : fonts.semibold}
                />
              </TotalPaymentAndSelectedPayableAmountWrapper>

              {Boolean(partialPayment) && (
                <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={false}>
                  <MediumTextSelect
                    value={t('partialPayableAmount.label')}
                    color={colors.primaryText}
                    fontFamily={fonts.semibold}
                  />
                  <MediumTextSelect
                    value={`\u20B9 ${partialPayment}`}
                    color={colors.primaryText}
                    fontFamily={fonts.semibold}
                  />
                </TotalPaymentAndSelectedPayableAmountWrapper>
              )}
              {totalPending > 0 && (
                <MakePaymentButtonWrapper>
                  <SecondaryBtn
                    onPress={handleMakePaymentModal}
                    label={t('makePayment.text')}
                    width={isWeb ? 140 : width - 48}
                    lines={1}
                  />
                </MakePaymentButtonWrapper>
              )}
            </ScrollView>
          </>
        ) : (
          <NoDataWrapper>
            <NoData icon={faAddressBook} label={t('noPendingPayment.text')} />
          </NoDataWrapper>
        )}
      </View>
      {makePaymentModal && (
        <NormalModal
          isSubmitting={isWeb ? !canClick : false}
          onAlert={alertState}
          setModalVisible={closeModal}
          modalVisible={makePaymentModal}
          handleSave={handleSubmit(handleMakePayment)}
          Headerpopup={showQR ? 'QR Scan' : 'Payment Mode'}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel={
            isWeb ? (mode === PaymentMode.ONLINE || mode === '' ? 'Proceed' : t('Generate QR')) : ''
          }
          hideSubmit={showQR || isMobile ? false : true}
          width={544}
          height={512}
          maxWidth={'lg'}
          paymentMode={true}
          message={modalMessage}>
          <PaymentModal
            isSubmitting={!canClick}
            control={control}
            errors={errors}
            totalPayableAmount={partialPayment ? partialPayment : payableAmount}
            data={data}
            showQR={showQR}
            setValue={setValue}
            handleMakePayment={handleSubmit(handleMakePayment)}
            setMode={setMode}
            clearErrors={clearErrors}
          />
        </NormalModal>
      )}
    </Fragment>
  );
};

const ContentContainer = styled.View`
  max-height: ${!isWeb && height - 550};
`;

const TotalPaymentAndSelectedPayableAmountWrapper = styled.View<{ displayBorder: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: ${({ displayBorder }) => displayBorder ? 1 : 0}px;
  border-bottom-color: ${colors.dividerColor};
  padding-vertical: 16px;
  padding-left: ${isWeb ? 35 : 0}px;
  padding-right:  ${isWeb ? 50 : 0}px;
`;

const BorderLineWrapper = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const TableColHeadWrapper = styled.View`
  margin-top: 15px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding-right: 50px;
  padding-left: 35px;
  align-items: center;
  padding-bottom: 13px;
`;

const CommonStyleRowAndSpaceBetween = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const ItemHeaderWrapper = styled.View`
  flex-direction: row;
  margin-bottom: 24px;
  align-items: center;
`;

const ChildrenItemHeaderWrapper = styled.View`
  padding-left: 63px;
`;

const NoDataWrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  top: ${isWeb ? height - 560 : 0};
`;

const NativeTableHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const ModuleNameWrapper = styled.View`
  flex: 1;
  align-items: flex-start;
  padding-left: ${isWeb ? '35px' : '0px'};
  padding-right: 8px;
  width: ${isWeb ? '80%' : '60%'};
`;

const MakePaymentButtonWrapper = styled.View`
  align-items: ${isWeb ? 'flex-end' : 'center'};
`;

export default UnPaidPaymentList;
