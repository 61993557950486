import { useEffect, useState } from 'react';
import { getFeeDetailsForParticularStudent, useQueryFeeBooks } from '../../../graphql/payment';
import { userInformation } from 'src/utils/manageState';
import { compareAndSort, sortModuleData } from 'src/utils/utility';
import { PAYMENT_MODE_LABEL } from 'src/constant';

export interface paymentListInterface {
  batchName?: string;
  id: string;
  groupName: string;
  amount: number;
  checked: boolean;
  totalConcessions: number;
  pending: boolean;
  paymentId: string;
  fees: {
    amount: number;
    name: string;
    id: string;
    payable: number;
    concessions: {
      amount: number;
      name: string;
      id: string;
    }[];
  }[];
  isGroup: boolean;
  date: any;
  methodofpayment: string;
  receipt: string;
}

export const usePaymentDataForStudent = (
  isPaymentPending: boolean,
  selectedPaymentId: string | null,
  useTotalFee: boolean,
) => {
  const [paymentList, setPaymentList] = useState<paymentListInterface[] | null>(null);
  const [totalPending, setTotalPending] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [partialPayment, setPartialPayment] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const currentUserObject = userInformation();
  const [userinfo, setUserinfo] = useState<any>(null);

  const { query: getFeeBooks, data: paymentsData, refetch, loading } = useQueryFeeBooks(getFeeDetailsForParticularStudent);

  useEffect(() => {
    if (currentUserObject?.userId) {
      getFeeBooks({ variables: { user: currentUserObject?.userId } });
    }
  }, [currentUserObject?.userId]);

  function resetPayment() {
    setTotalPending(0);
    setTotalPaid(0);
    setTotalPayable(0);
    setPartialPayment(0);
    setPayableAmount(0);
  }

  const CustomizePaymentData = () => {
    if (paymentsData?.getStudentFeeBook?.feeBook?.length) {
      setUserinfo(() => ({
        user: paymentsData?.getStudentFeeBook.user,
        batch: paymentsData?.getStudentFeeBook.batch,
      }));
      let arr: paymentListInterface[] = [];

      const customizingDataFunction = (feeBooksData: any, idx?: number) => {
        const newCustomizedData: paymentListInterface[] = [];
        let arrayToBeOptimized;
        const index = (idx || idx == 0) && idx !== -1;
        if (index) {
          arrayToBeOptimized = [feeBooksData?.payments[idx]];
        } else {
          arrayToBeOptimized = isPaymentPending ? feeBooksData?.feeBook : feeBooksData?.payments;
        }
        arrayToBeOptimized?.forEach((item: any) => {
          const obj = isPaymentPending ? {
            id: item.groupId,
            groupName: item.groupName,
            amount: item.amount,
            totalConcessions: item.totalConcessions,
            fees:
            item?.fees && item?.fees?.length > 1
              ? item?.fees?.slice().sort((a: any, b: any) => compareAndSort(a, b, 'orderNo'))
              : item?.fees,
            isGroup: item.groupName !== item.fees[0].name ? true : false,
            orderNo: Math.trunc(item?.orderNo),
          } : {
            id: item?.id,
            methodofpayment: PAYMENT_MODE_LABEL[item?.mode],
            batchName: feeBooksData?.batchName,
            date: item?.createdAt,
          };

          if (!isPaymentPending && index) {
            obj['amount'] = item?.detail?.amount;
            obj['transactionId'] = item?.detail?.payId;
            obj['approvedBy'] = item?.detail?.approvedBy;
            obj['orderId'] = item?.orderId;
          }

          newCustomizedData.push(obj);
        });

        return newCustomizedData;
      };

      if (selectedPaymentId) {
        const selectedPaymentIndex = paymentsData?.getStudentFeeBook?.payments?.findIndex(
          (payment: any) => payment?.id === selectedPaymentId,
        );
        arr = customizingDataFunction(paymentsData.getStudentFeeBook, selectedPaymentIndex);
      } else {
        arr = customizingDataFunction(paymentsData.getStudentFeeBook);
      }

      if (!isPaymentPending) {
        arr.sort(function(a: any, b: any) {
          return b?.date - a?.date;
        });
      }

      arr = sortModuleData(arr);
      setPaymentList(arr);
      const paymentData = paymentsData.getStudentFeeBook;
      setTotalPending(paymentData.totalPending);
      setTotalPaid(paymentData.totalPaid);
      setTotalPayable(paymentData.totalPayable);
      setPayableAmount(paymentData?.payableAmount);
      if (paymentData?.fees?.installmentPayable) {
        setPartialPayment(paymentData?.fees?.installmentPayable);
      }
    } else {
      setUserinfo(() => null);
      setPaymentList(null);
      resetPayment();
    }
  };

  useEffect(() => {
    if (paymentsData) {
      CustomizePaymentData();
    }
  }, [paymentsData, isPaymentPending]);
  return {
    refetch,
    paymentList,
    setPaymentList,
    userinfo,
    totalPending,
    totalPaid,
    totalPayable,
    partialPayment,
    payableAmount,
    paymentFailed: CustomizePaymentData,
    loading,
    resetPayment,
  };
};
