/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import { faBooks } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faCaretUp, faSync } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { colors } from 'src/styles/theme/styles';
import './employee.css';
import { useI18n } from 'src/i18n/hooks';
import { TouchableOpacity, View } from 'react-native';
import { useThemeSystem } from 'src/contexts/theme-context';
import Chip from 'src/components/atoms/Chip';
import { TableBody, Tooltip } from '@mui/material';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import useTable from './UseTable';
import { NoRecordsFound, TableCells, TableRows } from './TableAtom';
import styled from 'styled-components/native';
import Check from 'src/components/atoms/CheckBox/index.web';
import EmployeeDetailsQuickEditForm from 'src/components/molecules/Employees/EmployeeQuickEditForm';
import { EditIcon } from 'src/components/atoms/ActionIcons';
import { EMPLOYEE_TYPES } from 'src/constant';
import Badge from 'src/components/atoms/Badge';
import { formatDistanceToNowStrict } from 'date-fns';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import {properCase} from 'src/utils/utility';
import MultiColorProgressBar from 'src/components/atoms/progressiveBar/MultiColorProgressBar';
interface propType {
  data: [];
  loading: boolean;
  tableHeight: number;
  childName?: string;
  handleParentAdd: any;
  handleParentEdit: any;
  handleDelete: any;
  handleChildAdd: any;
  handleChildEdit: any;
  handleChildDelete: any;
  handleApprove: any;
  createBatchWiseObject: any;
  handleParenView: (data: any) => void;
  sendInviteEmployee: (userId: any) => void;
  setShowResendButton: any;
  setUserIds: any;
  clearCheckbox: any;
  setClearCheckbox: any;
  handleFetch: any;
  handleRefetch: Function;
  setRecipientType: Function;
  notActiveCount: number | null;
  setNotActiveCount: Function;
}

const headCells = [
  {
    id: 'batch_name',
    label: 'batch.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'class_subjects',
    label: 'classSubjects.label',
    align: 'left',
    disableSorting: true,
  },
];

export default function EmployeeTable(props: propType) {
  const {
    data,
    loading,
    tableHeight,
    handleParentAdd,
    handleParentEdit,
    handleChildAdd,
    createBatchWiseObject,
    handleParenView,
    sendInviteEmployee,
    setShowResendButton,
    setUserIds,
    clearCheckbox,
    setClearCheckbox,
    handleFetch,
    handleRefetch,
    setRecipientType,
    notActiveCount,
    setNotActiveCount,
  } = props;
  const columns = [
    { label: 'employeeName.label' },
    { label: 'employeeId.label' },
    { label: 'profileCompletion.label' },
    { label: 'userStatus.label' },
    { label: 'actions.label', textAlign: 'right' },
  ];
  const [trow, setT] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [array, setArray] = useState([]);
  const [showCheck, setShowCheck] = useState(false);
  const { t } = useI18n();
  const show = true;
  const { theme } = useThemeSystem();

  const { TblContainer, TblHead } = useTable(
    null,
    headCells,
  );

  useEffect(() => {
    checkStatus();
  }, [data])

  useEffect(() => {
    array.length > 0 ? setShowResendButton(true) : setShowResendButton(false);
    setUserIds(array);
  }, [array])

  useEffect(() => {
    if (clearCheckbox) {
      setArray([]);
    }
  }, [clearCheckbox]);

  const checkStatus = () => {
    if (data && data.length > 0) {
      let count = 0;
      let status = [] as any;
      data.forEach((item: any) => {
        if (item?.totalOnboardingCompletion !== 100 || item?.status !== 'ACTIVE') {
          status.push(item?.totalOnboardingCompletion);
          count = count + 1;
        }
      });

      if (status.length > 0 && count !== 0) {
        setShowCheck(true);
        setNotActiveCount(count);
      } else {
        setShowCheck(false);
      }
    }
  };


  const nested = (sortedData: any, id: string, type: any, data: any) => {
    let rowData = Object.keys(sortedData);
    return (
      <ContainerView>
        <EmployeeDetailsQuickEditForm
          employeeData={data}
          handleFetch={handleFetch}
          handleRefetch={handleRefetch}
        />

        {rowData.length > 0 && (
          <TableWrapper>
            <TblContainer maxHeight={220}>
              <TblHead />
              <TableBody>
                {rowData?.map((innerData, inner) => {
                  return (
                    <TableRows>
                      <TableCells value={innerData} style={{ width: '22%' }} />
                      <TableCells value={sortedData[innerData]?.length > 0 &&
                        sortedData[innerData]?.map((chip: any, nos: any) => (
                          <>
                            <div
                              style={{
                                display: 'inline-block',
                                marginBottom: nos === sortedData[innerData]?.length - 1 ? 0 : 8,
                                marginRight: 15
                              }}>
                              <Chip label={chip?.text} close={false} />
                            </div>
                          </>
                        ))} />
                    </TableRows>
                  )
                })}
              </TableBody>
            </TblContainer>
          </TableWrapper>
        )}
      </ContainerView>
    )
  }

  const checkCompletion = (percent: number) => {
    if (percent >= 0 && percent <= 20) {
      return colors.red;
    } else if (percent >= 21 && percent <= 80) {
      return colors.yellow
    } else return colors.green;
  }

  const handleCheck = (item: any) => {
    setClearCheckbox(false);
    const index = array.findIndex(x => x === item?.id);
    const updated = [...array];
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push(item?.id);
    }
    setArray(updated);
    setRecipientType('Selected');
  }

  const handleCheckAll = () => {
    setClearCheckbox(false);
    if (array.length === 0) {
      let allUsers = [] as any;
      data?.forEach((item: any) => {
        if (item?.totalOnboardingCompletion !== 100 || item?.status !== 'ACTIVE') {
          allUsers.push(item?.id)
        }
      });
      setArray(allUsers);
    } else if (array.length > 0 && array.length !== notActiveCount) {
      let update = [...array] as any;
      const res = data.filter(x => !array.includes(x.id));
      res.forEach((e: any) => {
        if (e?.totalOnboardingCompletion !== 100 || e?.status !== 'ACTIVE') {
          update.push(e?.id)
        }
      })
      setArray(update);
    } else {
      setArray([]);
    }
    setRecipientType('Selected');
  }

  return (
    <div>
      <div id="app" style={{ border: '1px solid transparent' }}>
        <table style={styles.table}>
          <tr
            style={{
              backgroundColor: theme?.table?.header?.backgroundColor,
              position: 'sticky',
              top: 0,
              zIndex: 3,
            }}>
            {showCheck && (
              <th style={{ padding: 8, width: '4%' }}>
                <Check
                  same={false}
                  checked={array.length === notActiveCount}
                  onChange={() => handleCheckAll()}
                />
              </th>
            )}
            {!!show && <th className="firstCol" style={{ width: '5%' }} />}
            {columns.map((e, idx) => (
              <th
                key={`emp-table-header-${idx}`}
                style={{ color: theme?.table?.header?.color, textAlign: e?.textAlign, padding: 8 }}>
                {t(e.label).toUpperCase()}
              </th>
            ))}
          </tr>
          {!loading ? (
            <>
              {data && data?.length > 0 && (
                <tbody>
                  {data?.map((d: any, i: number) => (
                    <React.Fragment key={`emp-table-list-${i}`}>
                      <tr
                        style={{
                          backgroundColor: trow && current === i ? '#EAF2FA' : 'transparent',
                          ...parentBorderRight(trow, current, i),
                          ...parentBorderLeft(trow, current, i),
                          ...commonBorder(trow, current, i),
                          width: '5%',
                          height: 56,
                        }}>
                        {showCheck && (
                          <td
                            style={{ color: theme?.table?.color, width: '4%' }}
                            className={`${d?.classDetails?.length === 1 ? 'expand' : 'collapse'}`}>
                            {(d?.totalOnboardingCompletion !== 100 || d?.status !== 'ACTIVE') && (
                              <Check
                                same={false}
                                onChange={() => handleCheck(d)}
                                checked={array.some((e) => e === d.id)}
                              />
                            )}
                          </td>
                        )}
                        <td
                          className={`${d?.classDetails?.length === 1 ? 'expand' : 'collapse'}`}
                          style={{ width: '5%' }}>
                          {trow && current === i ? (
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              onClick={() => {
                                setT(false);
                                setCurrent(null);
                              }}
                              color={colors.secondaryText}
                              style={{ cursor: 'pointer' }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              onClick={() => {
                                setT(true);
                                setCurrent(i);
                              }}
                              color={colors.secondaryText}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </td>
                        <td
                          onClick={() => handleParenView(d?.id)}
                          style={styles.nameTableCell}
                          className={`${d?.classDetails?.length === 1 ? 'expand' : 'collapse'}`}>
                          {d?.personalDetails?.fullName || '-'}
                          {d?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) && (
                            <BadgeWrapper>
                              <Badge value={t('admin.label')} />
                            </BadgeWrapper>
                          )}
                        </td>

                        <td
                          style={{ color: theme?.table?.color, width: '20%' }}
                          className={`${d?.classDetails?.length === 1 ? 'expand' : 'collapse'}`}>
                          {d?.employeeId ? d?.employeeId : '-'}
                        </td>

                        <td
                          style={{ color: theme?.table?.color, width: '15%' }}
                          className={`${d?.classDetails?.length === 1 ? 'expand' : 'collapse'}`}>
                            <MultiColorProgressBar
                              data={[
                                {
                                  label: 'verifiedDetails.label',
                                  value: d?.totalOnboardingCompletion || 0,
                                  color: colors.primaryColor,
                                },
                                {
                                  label: 'awaitingVerification.label',
                                  value: d?.draftTotalOnboardingCompletionn || 0,
                                  color: colors.yellow,
                                },
                              ]}
                              height={13}
                              width={150}
                              compact={true}
                            />
                        </td>

                        <td
                          style={{ color: theme?.table?.color, width: '20%' }}
                          className={`${d?.classDetails?.length === 1 ? 'expand' : 'collapse'}`}>
                          {d?.status !== 'VERIFICATION_SENT' ? (
                            <NormaltextAtom
                              value={properCase(d?.status)}
                              color={d?.status === 'ACTIVE' ? colors.green : colors.blue}
                            />
                          ) : (
                            <View>
                              <NormaltextAtom
                                value={
                                  d?.verificationStatus?.expiresAt
                                    ? `Verification expires ${formatDistanceToNowStrict(
                                      d?.verificationStatus?.expiresAt,
                                    )}`
                                    : 'Verification Expired'
                                }
                                color={
                                  d?.verificationStatus?.expiresAt ? colors.yellow : colors.red
                                }
                              />
                            </View>
                          )}
                        </td>

                        <td
                          style={{ textAlign: 'right', width: '5%' }}
                          className={`${d?.classDetails?.length === 1 ? 'expand' : 'collapse'}`}>
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}>
                            {d?.status !== 'ACTIVE' && (
                              <Tooltip title="Resend Verification">
                                <TouchableOpacity
                                  style={{
                                    alignSelf: 'flex-end',
                                  }}
                                  onPress={() => {
                                    sendInviteEmployee(d.id);
                                  }}>
                                  <FontAwesomeIcon
                                    icon={faSync}
                                    color={theme?.table?.editIconColor}
                                    style={{
                                      marginRight: d?.type === 'GROUP' ? 13 : 8,
                                      cursor: 'pointer',
                                    }}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            )}

                            {(d?.type.includes('TEACHING') || d?.type.includes('ADMIN')) && (
                              <Tooltip title="Assign Subjects">
                                <TouchableOpacity
                                  onPress={() =>
                                    d?.classDetails?.length === 0
                                      ? handleParentAdd(d)
                                      : handleChildAdd(d)
                                  }>
                                  <FontAwesomeIcon
                                    icon={faBooks}
                                    color={theme?.table?.editIconColor}
                                    style={{
                                      marginRight: d?.type === 'GROUP' ? 13 : 8,
                                      cursor: 'pointer',
                                    }}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            )}

                            <EditIconWrapper type={d?.type}>
                              <EditIcon
                                tooltipTitle={t('editProfile.text')}
                                onPress={() => handleParentEdit(d)}
                              />
                            </EditIconWrapper>
                          </View>
                        </td>
                      </tr>
                      {trow && current === i && (
                        <tr
                          style={{
                            ...childBorderLeft(trow, current, i),
                            ...childBorderRight(trow, current, i),
                            color: theme?.table?.color,
                          }}>
                          <td
                            colSpan={8}
                            style={{
                              borderBottom: `2px solid ${colors.tableDropdwonBorder}`,
                              padding: 0,
                            }}>
                            {nested(createBatchWiseObject(d?.classDetailsObj), d?.id, d?.type, d)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              )}
              {data && data?.length === 0 && <NoRecordsFound colspan={8} maxHeight={tableHeight} />}
            </>
          ) : (
            <SpinnerView>
              <LoaderSpinner />
            </SpinnerView>
          )}
        </table>
      </div>
    </div>
  );
}

const childBorderRight = (trow, current, i) => {
  return {
    borderRight: trow ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

const childBorderLeft = (t, current, i) => {
  return {
    borderLeft: t ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

const parentBorderRight = (t, current, i) => {
  return {
    borderRight:
      t && current === i ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

const parentBorderLeft = (t, current, i) => {
  return {
    borderLeft:
      t && current === i ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

const commonBorder = (t, current, i) => {
  return {
    borderBottom:
      t && current === i
        ? `2px solid ${colors.tableDropdwonBorder}`
        : `1px solid ${colors.lighterBorder}`,
    borderTop:
      t && current === i
        ? `2px solid ${colors.tableDropdwonBorder}`
        : `1px solid ${colors.lighterBorder}`,
  };
};

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  nameTableCell: {
    color: colors.primaryColor,
    width: '90%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }
}

const TableWrapper = styled.View`
`;

const ContainerView = styled.View`
`;

const BadgeWrapper = styled.View`
  height: fit-content;
  margin-left: 8px;
`;

const EditIconWrapper = styled.View<{ type: string }>`
  margin-right: ${({ type }) => type === 'GROUP' ? '13px' : '8px'};
`;

const SpinnerView = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;