import React from 'react';
import styled from 'styled-components/native';

import { colors } from 'src/styles/theme/styles';

interface Props {
  value: any;
  color?: string;
  fontWeight?: any;
  fontSize?: string | number;
  lineHeight?: number;
  textAlign?: string;
}

export default function HeaderTwo(props: Props) {
  const { value = '', textAlign, color = colors.secondaryText, fontWeight = 'normal', fontSize = 32, lineHeight = 4 } = props;

  const style = {
    fontSize: fontSize,
    color: color,
    fontWeight: fontWeight,
    textAlign: textAlign,
  };

  return (
    <HeaderText style={style} allowFontScaling={false} fontSize={fontSize} lineHeight={lineHeight}>
      {value}
    </HeaderText>
  );
}

const HeaderText = styled.Text`
  font-style: normal;
  font-size: ${(props) => props.theme.rem(props?.fontSize)};
  line-height: ${(props) => props.theme.rem(props?.lineHeight)};
`;
