import { ExpandCollapseCell, NoRecordsFound, TableCells, TableDiv, TableRows } from './TableAtom';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Check from 'src/components/atoms/CheckBox/index.web';
import Chip from 'src/components/atoms/Chip';
import ChipInput from 'src/components/atoms/ChipInput.tsx';
import { TableBody } from '@mui/material';
import { colors } from 'src/styles/theme/styles';
import { height } from 'src/constant/device';
import styled from 'styled-components/native';
import useTable from './UseTable';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';

interface Props {
  tabledata: any[];
  maxHeightTable: number;
  setData: Dispatch<SetStateAction<any>>;
  data: any[];
  inputProperty: string;
  inputValProperty: string;
  inputHeader: string;
  isEdit: boolean;
  isDataAvailable: boolean;
}

export default function ChipsInputTable(props: Props) {
  const {
    tabledata,
    maxHeightTable,
    setData,
    data,
    inputProperty,
    inputValProperty,
    inputHeader,
    isEdit,
    isDataAvailable,
  } = props;
  const [tabledataState] = useState(tabledata);
  const [checked, setChecked] = useState([]);
  const [chipArray, setChipArray] = useState<(string | number)[]>([]);
  const [trow, setT] = useState<boolean>(false);
  const [current, setCurrent] = useState<number | null>(null);
  const { TblContainer } = useTable(tabledataState, null, null, tabledata);

  useEffect(() => {
    if (tabledata && current != null && checked?.length > 0) {
      setInputVal(tabledata[current]?.id, chipArray);
    }
  }, [chipArray]);

  useEffect(() => {
    if (isEdit && data && data.length > 0) {
      setChecked(data);
      if (tabledata && tabledata.length) {
        tabledata?.map((tdItem: any, i: number) => {
          data?.map((item: any) => {
            if (tdItem?.id === item?.stream) expandFn(i);
          });
        });
      }
    }
  }, [data, tabledata]);

  useEffect(() => {
    setData(checked);
  }, [checked]);

  useEffect(() => {
    if (current == null) {
      setChipArray([]);
    } else {
      setChipArray(getInputVal(tabledata[current]?.id) || []);
    }
  }, [current]);

  function isChecked(id: string): boolean {
    return Boolean(
      checked && checked.length > 0 && checked.find((item) => item[inputProperty] === id),
    );
  }

  function handleChecked(i: number, id: string) {
    const index = checked.findIndex((x) => x[inputProperty] === id);
    let updated = [...checked];
    if (index > -1) {
      updated.splice(index, 1);
      if (current === i) collapseFn();
    } else {
      updated.push({ [inputProperty]: id });
      expandFn(i);
    }
    setChecked(updated);
  }

  function getInputVal(id: string) {
    const stream =
      checked && checked.length > 0 && checked.find((item) => item[inputProperty] === id);
    return stream ? stream[inputValProperty] : [];
  }

  function setInputVal(id: string, value: (string | number)[]) {
    const temp = [...checked];
    const currentItem = temp.findIndex((item) => item[inputProperty] === id);
    temp[currentItem][inputValProperty] = value;
    setChecked([...temp]);
  }

  function removeChip(chipIndex: number, rowIndex: number, item: any) {
    if (current != null && current == rowIndex) {
      let check = [...chipArray];
      check.splice(chipIndex, 1);
      setChipArray(check);
    } else {
      let currentItemValues = [...getInputVal(item.id)];
      currentItemValues.splice(chipIndex, 1);
      setInputVal(item.id, currentItemValues);
    }
  }

  function collapseFn() {
    setT(false);
    setCurrent(null);
  }

  function expandFn(i: number | null) {
    setT(true);
    setCurrent(i);
  }

  return (
    <TableContainer>
      <TableDiv>
        <TblContainer tabledata={tabledata} maxHeight={maxHeightTable} height={height}>
          {tabledata && tabledata?.length > 0 && (
            <TableBody>
              {tabledata?.map((item: any, i: number) => (
                <>
                  <TableRows key={i}>
                    <TableCells style={styles.checkboxCellStyle} width={24}>
                      <Check
                        same={false}
                        checked={isChecked(item?.id)}
                        onChange={() => handleChecked(i, item?.id)}
                      />
                    </TableCells>
                    <TableCells width={'90%'}>
                      <HeaderSix
                        value={item?.name}
                        color={colors.primaryText}
                        fontWeight={isChecked(item?.id) ? 600 : 400}
                      />
                    </TableCells>
                    <ExpandCollapseCell
                      trow={trow}
                      current={current}
                      i={i}
                      textValue={''}
                      CollapseFn={collapseFn}
                      ExpandFn={() => expandFn(i)}
                      disabled={!isChecked(item?.id)}
                      size={28}
                      color={colors.primaryColor}
                    />
                  </TableRows>
                  {trow && current === i && (
                    <TableRows>
                      <TableCells colspan={4} style={styles.chipInputStyle}>
                        <ChipInput
                          chipArray={chipArray}
                          setChipArray={setChipArray}
                          inputId={item.id}
                          inputHeader={inputHeader}
                          denseStyling={true}
                          hideChipsDisplay={true}
                        />
                        <ChipsDisplayContainer>
                          {getInputVal(item?.id)?.length > 0 &&
                            getInputVal(item?.id).map((chip: string, index: number) => {
                              return (
                                <ChipView>
                                  <Chip label={chip} onPress={() => removeChip(index, i, item)} />
                                </ChipView>
                              );
                            })}
                        </ChipsDisplayContainer>
                      </TableCells>
                    </TableRows>
                  )}
                </>
              ))}
            </TableBody>
          )}
          {isDataAvailable && tabledata?.length === 0 && (
            <NoRecordsFound colspan={4} maxHeight={maxHeightTable} />
          )}
        </TblContainer>
      </TableDiv>
    </TableContainer>
  );
}

const TableContainer = styled.View<{ width: number | string }>`
  overflow: auto;
  width: ${({ width }) => width};
`;

const ChipsDisplayContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 8px;
  margin-top: 8px;
`;

const ChipView = styled.View`
  margin-bottom: 8px;
  margin-right: 8px;
`;

const styles = {
  checkboxCellStyle: { paddingLeft: 16, paddingRight: 0 },
  chipInputStyle: { paddingBottom: 8 },
};
