import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';
import { breakpoints, fontWeight } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import {
  globalAssignmentCommentQuery,
  useCreateGlobalAssignmentCommentMutation,
} from 'src/graphql/communication/assignments';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { isWeb, width } from 'src/constant/device';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import Comments from 'src/components/atoms/Comment/index.web';
import styled from 'styled-components/native';
import CommentCard from 'src/components/atoms/CommentCard';
interface Props {
  assignmentId: string;
}

interface studentCommentPayload {
  assignment: string;
  comment: string;
}

export default function AssignmentModalContent(props: Props) {
  const { assignmentId } = props;

  const isMobile = width < breakpoints.md ? true : false;
  const [comment, setComment] = useState('');
  const { t } = useI18n();
  const { createGlobalAssignmentComment } = useCreateGlobalAssignmentCommentMutation();

  const { loading, data: assignmentComment } = useQuery(globalAssignmentCommentQuery, {
    variables: { assignmentId: assignmentId },
    fetchPolicy: 'network-only',
  });
  const comments = assignmentComment?.getGlobalAssignmentComment?.comments || [];

  function postComment() {
    if (comment) {
      let commentPayload = {
        assignment: assignmentId,
        comment: comment,
      } as studentCommentPayload;
      try {
        let response: any = createGlobalAssignmentComment({
          variables: { payload: commentPayload },
          refetchQueries: [
            { query: globalAssignmentCommentQuery, variables: { assignmentId: assignmentId } },
          ],
        });
        if (response) {
          setComment('');
        }
      } catch (error: any) {
        console.log(error.message);
      }
    }
  }

  const handleComments = (board: string) => {
    setComment(board);
  };

  return !loading ? (
    <View>
      {/* comment*/}
      <CommentWrapper>
        {isMobile ? (
          <MediumTextSelect
            value={t('discussion.label')}
            fontWeight={fontWeight[600]}
          />
        ) : (
          <HeaderSix
            value={t('discussion.label')}
            fontWeight={fontWeight[400]}
          />
        )}

        <CommentView>
          {comments?.map((cmt: any, index: number) => (
            <View key={`cmt${index}${cmt?.time}`} style={{ marginBottom: 24 }}>
              <CommentCard
                comment={cmt?.comment || ''}
                time={cmt?.createdAt}
                commentor={cmt?.from}
              />
            </View>
          ))}
        </CommentView>

        <>
          <Comments
            setValue={handleComments}
            value={comment}
            header={t('addComment.text')}
            postComment={postComment}
            width={600}
          />
        </>
      </CommentWrapper>
    </View>
  ) : (
    <View style={[styles.spineerView]}>
      <LoaderSpinner />
    </View>
  );
}

const styles = StyleSheet.create({
  attachWrapper: { flexDirection: 'row', flexWrap: 'wrap', marginTop: 21 },
  attachView: { marginRight: 24, marginBottom: 24 },
  fileView: { marginBottom: 24, maxWidth: 350 },
  commentView: { marginTop: isWeb ? 16 : 8 },
  postView: { marginVertical: 24 },
  btnStyle: { height: 32, width: 60 },
  marginTop4: {
    marginTop: 4,
  },
  spineerView: {
    height: 450,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const CommentWrapper = styled.View`
  margin-top: ${width > breakpoints.md ? '0px' : '24px'};
`;

const CommentView = styled.View`
  margin-top: ${isWeb ? 16 : 8}px;
`;
