import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size } = props;
  return (
    <Svg width={size * 3.128} height={size} viewBox="0 0 122 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M14.138 39C21.1884 39 25.9382 35.5279 27.0514 30.179L19.7412 29.6911C18.9434 31.8869 16.9025 33.0318 14.2678 33.0318C10.3159 33.0318 7.81114 30.3855 7.81114 26.0876V26.0688H27.2184V23.873C27.2184 14.076 21.3554 9.23388 13.8226 9.23388C5.43625 9.23388 0 15.2584 0 24.1545C0 33.2945 5.36204 39 14.138 39ZM7.81114 21.1141C7.97812 17.8296 10.4458 15.2021 13.9524 15.2021C17.3849 15.2021 19.7598 17.6795 19.7783 21.1141H7.81114Z" fill="white"/>
        <Path d="M42.8778 38.9062C47.4791 38.9062 49.8725 36.2223 50.9672 33.82H51.3012V38.437H59.0938V0H51.2084V14.4514H50.9672C49.9096 12.1054 47.6275 9.23388 42.8592 9.23388C36.6066 9.23388 31.3188 14.1511 31.3188 24.0606C31.3188 33.7074 36.3839 38.9062 42.8778 38.9062ZM45.3825 32.5438C41.5048 32.5438 39.3896 29.0529 39.3896 24.0231C39.3896 19.0308 41.4677 15.5962 45.3825 15.5962C49.2231 15.5962 51.3754 18.8807 51.3754 24.0231C51.3754 29.1655 49.186 32.5438 45.3825 32.5438Z" fill="white"/>
        <Path d="M64.8125 38.4555H72.5975V33.8363H72.9497C74.0433 36.2398 76.4344 38.9249 81.0313 38.9249C87.5188 38.9249 92.5791 33.7236 92.5791 24.0722C92.5791 14.1579 87.2964 9.23834 81.0498 9.23834C76.2861 9.23834 74.0062 12.1112 72.9497 14.4584H72.7087V0H64.8125V38.4555ZM72.5419 24.0347C72.5419 18.8897 74.6921 15.6038 78.529 15.6038C82.44 15.6038 84.516 19.04 84.516 24.0347C84.516 29.0669 82.403 32.5595 78.529 32.5595C74.7291 32.5595 72.5419 29.1796 72.5419 24.0347Z" fill="white"/>
        <Path d="M105.967 39C110.174 39 112.899 37.1411 114.289 34.4559H114.512V38.4555H122V19.0024C122 12.13 116.254 9.23834 109.915 9.23834C103.094 9.23834 98.6079 12.5431 97.5142 17.8007L104.817 18.4015C105.355 16.4863 107.042 15.078 109.878 15.078C112.565 15.078 114.104 16.4487 114.104 18.8146V18.9273C114.104 20.7862 112.158 21.0303 107.208 21.5185C101.574 22.0443 96.5133 23.9596 96.5133 30.4001C96.5133 36.1083 100.536 39 105.967 39ZM119.238 0H101.184V4.33751H119.238V0ZM108.228 33.4795C105.8 33.4795 104.057 32.3341 104.057 30.1372C104.057 27.884 105.892 26.7761 108.673 26.3818C110.397 26.1377 113.214 25.7246 114.159 25.0862V28.1469C114.159 31.17 111.694 33.4795 108.228 33.4795Z" fill="white"/>
    </Svg>
  );
}

export default SvgComponent;
