import {
  AccountObject,
  LoginStyledComponents as LSC,
  LoginComponentsStyles,
  LoginScreenBackgroundNative,
  LoginScreenEdbaLogo,
  LoginScreenHeaderTitles,
  configureAccountStorageAndNavigate,
  getAllAccountsFromStorage,
} from "./LoginHelpers";
import { Keyboard, TouchableWithoutFeedback } from "react-native";
import React, { useEffect, useState } from "react";
import {
  clearGarbage,
  getStorageFunction,
} from "src/components/services/storage-service";
import { isNative, isWeb } from "src/constant/device";

import AvoidKeyPad from "src/components/atoms/Wrapper/AvoidKey";
import { DEVICE_TYPE } from "src/constant";
import FlashMessage from "react-native-flash-message";
import { KEYS } from "src/constant/key";
import LoginCard from "src/components/molecules/Login/LoginCard";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { keyValuesVar } from "src/utils/manageState";
import navigationStrings from "src/navigation/navigationStrings";
import styled from "styled-components/native";
import { useHistory } from "src/routes/routing";
import { useLogout } from "src/graphql/logout";
import { useParams } from "src/routes/routing.web";
import { colors } from "src/styles/theme/styles";

interface Props {
  navigation?: any;
}

export default function AccountSelection(props: Props) {
  const history = useHistory();
  const { navigation } = props;
  const [storedAccCards, setStoredAccountCards] = useState<AccountObject[]>();
  const { logoutUser } = useLogout();
  const params: { prefix?: string, requestId?: string, capId?: string, inviteCode?: string } = isWeb ? useParams() : {};
  const { prefix, requestId, capId, inviteCode } = params;

  // let requestParam: { prefix: string; requestId: string; capId: string };
  // if (isWeb) {
  //   requestParam = useParams();
  // }

  useEffect(() => {
    getAllAccountsFromStorage().then((accountCards) => {
      setStoredAccountCards(accountCards);
    });
  }, []);

  async function handleLogout() {
    try {
      const fcmToken = await getStorageFunction(KEYS.FCM_TOKEN);
      await logoutUser({ variables: { device: DEVICE_TYPE.WEB, fcmToken } });
    } catch (e) {
      console.log("logout failed", e);
    }
    clearGarbage();
    keyValuesVar({});
    history.push("/login");
  }

  async function clickedCard(selectedAccount: AccountObject) {
    configureAccountStorageAndNavigate(
      selectedAccount,
      history,
      null,
      selectedAccount?.userType,
      prefix,
      requestId,
      capId,
      inviteCode,
    );
  }

  return (
    <>
      <FlashMessage position="top" />
      <AvoidKeyPad extraHeight={180} style={LoginComponentsStyles.keypad}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <LSC.Container>
            <LSC.PageAreaContainer>
              <LSC.LoginView>
                <LoginScreenEdbaLogo />
                {isNative && <LoginScreenBackgroundNative />}

                <LoginScreenHeaderTitles
                  signInDescription={
                    "You have multiple users. Select your relevant account."
                  }
                  headerTitle={"Select User!"}
                />

                <CardsContainer>
                  <ScrollContainer>
                    {storedAccCards &&
                      storedAccCards?.length > 0 &&
                      storedAccCards.map((acc: AccountObject, i: number) => {
                        return (
                          <LoginCard
                            index={i}
                            onPress={clickedCard}
                            cardData={acc}
                            lastIndex={storedAccCards.length - 1}
                          />
                        );
                      })}
                  </ScrollContainer>
                </CardsContainer>

                {isWeb && (
                  <SecondaryBtn
                    label={"Logout"}
                    onPress={handleLogout}
                    style={LoginComponentsStyles.webLoginButton}
                  />
                )}
              </LSC.LoginView>
            </LSC.PageAreaContainer>
          </LSC.Container>
        </TouchableWithoutFeedback>
      </AvoidKeyPad>
    </>
  );
}

const ScrollContainer = styled.ScrollView`
  border-width: 1px;
  border-color: ${colors.borderGrey};
  border-radius: 8px;
  width: 350px;
`;

// max-height should be (no. of cards to be shown * height of each card) + 2px (for adjusting border width)
// here we show max 4 cards before a scroll
const CardsContainer = styled.View`
  max-height: 268px;
  margin-top: 32px;
`;
