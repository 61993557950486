import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={20 * scale}
      height={16 * scale}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M7 9C9.48438 9 11.5 6.98438 11.5 4.5C11.5 2.01562 9.48438 0 7 0C4.51562 0 2.5 2.01562 2.5 4.5C2.5 6.98438 4.51562 9 7 9ZM7 1.5C8.65312 1.5 10 2.84687 10 4.5C10 6.15312 8.65312 7.5 7 7.5C5.34688 7.5 4 6.15312 4 4.5C4 2.84687 5.34688 1.5 7 1.5ZM9.8 9.5C8.90312 9.5 8.47188 10 7 10C5.52812 10 5.1 9.5 4.2 9.5C1.88125 9.5 0 11.3813 0 13.7V14.5C0 15.3281 0.671875 16 1.5 16H12.5C13.3281 16 14 15.3281 14 14.5V13.7C14 11.3813 12.1187 9.5 9.8 9.5ZM12.5 14.5H1.5V13.7C1.5 12.2125 2.7125 11 4.2 11C4.65625 11 5.39687 11.5 7 11.5C8.61563 11.5 9.34062 11 9.8 11C11.2875 11 12.5 12.2125 12.5 13.7V14.5ZM19.5 6.75H17.25V4.5C17.25 4.225 17.025 4 16.75 4H16.25C15.975 4 15.75 4.225 15.75 4.5V6.75H13.5C13.225 6.75 13 6.975 13 7.25V7.75C13 8.025 13.225 8.25 13.5 8.25H15.75V10.5C15.75 10.775 15.975 11 16.25 11H16.75C17.025 11 17.25 10.775 17.25 10.5V8.25H19.5C19.775 8.25 20 8.025 20 7.75V7.25C20 6.975 19.775 6.75 19.5 6.75Z"
        fill="black"
        fillOpacity="0.6"
      />
    </Svg>
  );
}

export default SvgComponent;
