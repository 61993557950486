import useFieldApi, {
  UseFieldApiConfig,
} from "@data-driven-forms/react-form-renderer/use-field-api";

import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import React from "react";
import TriggerSelector from "src/components/atoms/Button/TriggerSelector";
import { clipLongText } from "src/utils/utility";
import { colors } from "src/styles/theme/styles";
import { useI18n } from "src/i18n/hooks";

const TriggerSelection = (props: UseFieldApiConfig) => {
  const {
    id,
    label = "",
    input,
    subscribeToValues,
    onPress = () => { },
    placeholderLabel,
    selectedValueLabel,
    width = "100%",
    disabled = false,
    canClick,
    onDelete = () => { },
    textCount = 28,
  } = useFieldApi(props);
  const { value } = input;
  const { t } = useI18n();

  const displayText = disabled
    ? clipLongText(t(value?.label), textCount)
    : clipLongText(selectedValueLabel, textCount) || t(placeholderLabel);

  const textColor = selectedValueLabel
    ? colors.primaryText
    : placeholderLabel
      ? colors.secondaryText
      : colors.tertiaryText;

  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <TriggerSelector
          inputContainerWidth={width}
          headerLabel={t(label)}
          selectedValueLabel={selectedValueLabel}
          displayText={displayText}
          textColor={textColor}
          canClick={canClick}
          disabled={!canClick}
          onPress={disabled ? () => { } : onPress}
          onDelete={onDelete}
        />
      )}
    </FormSpy>
  );
};

export default TriggerSelection;
