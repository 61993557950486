import { ERROR, SUCCESS } from "src/constant";
import React, { useState } from "react";

import AdmissionRoundsListing from "./AdmissionRoundsListing.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { admissionRoundFormState as TForm } from "./AdmissionMeritRoundsHelpers";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useI18n } from "src/i18n/hooks";

type Props = {
  onCloseListing: () => void;
  refetch: () => Promise<any>;
  admissionDetail: any;
};

const ManageRounds = (props: Props) => {
  const { onCloseListing, refetch, admissionDetail } = props;
  const { setAlertDetails } = useAlertSystem();
  const { t } = useI18n();

  const [form, setForm] = useState<TForm>({ state: "CLOSE" });

  const onSuccess = (message: string, close: boolean = true): void => {
    setAlertDetails({ message, level: SUCCESS });
    if (refetch) refetch();
    if (close) onClose();
  };

  const onError = (message: string, close: boolean = true): void => {
    setAlertDetails({ message, level: ERROR });
    if (close) onClose();
  };

  const onClose = () => {
    setForm({ state: "CLOSE" });
  };

  return (
    <NormalModal
      setModalVisible={onCloseListing}
      modalVisible={true}
      infoModal={true}
      preventRedirectOnClose={true}
      Headerpopup={t("manageRounds.label")}
      maxWidth={"md"}
      visibility={form?.state !== "OPEN"}
    >
      <AdmissionRoundsListing
        admissionDetail={admissionDetail}
        onSuccess={onSuccess}
        onError={onError}
        onClose={onClose}
        roundForm={{ form, setForm }}
      />
    </NormalModal>
  );
};

export default ManageRounds;
