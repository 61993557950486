// breakpoints used
// small device below and equal to 1024
// above 1024 and 1440 medium device
// and above 1440 large device

import { Platform, Dimensions, PixelRatio } from 'react-native';
export const isWeb = Platform.OS === 'web';
export const isNative = Platform.OS !== 'web';
export const isIOS = Platform.OS === 'ios';
export const isAndroid = Platform.OS === "android";
export const width = isWeb ? window.innerWidth : Dimensions.get('window').width;
export const height = isWeb ? window.innerHeight : Dimensions.get('window').height;
export const dpi = PixelRatio.get();
export const scale = PixelRatio.getFontScale();
export const isAndroidUserAgent = /Android/i.test(navigator.userAgent);
export const isIOSUserAgent = /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const isMobile = width < 768;
export const isTablet = width < 1024;
