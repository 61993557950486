import { FieldValues, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { Reservation } from "../../types";
import { TAdmissionDetailFormState as TForm } from "src/components/organism/Admission/AdmissionDetail/AdmissionDetailTypes";
import { TFormProps } from "src/types";
import formJSON from "src/form-json/admission/Applications/accept-application-form.json";
import styled from "styled-components";
import { useAcceptApplication } from "src/graphql/admission/application";
import { useI18n } from "src/i18n/hooks";

type Props = TFormProps<TForm<{ applicationId: string }>> & {
  reservations: Reservation[];
};

const AcceptApplicationForm = (props: Props) => {
  const { form, onSuccess, onError, onClose, reservations } = props;
  const { applicationId } = form.data!;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { acceptApplication } = useAcceptApplication();

  const [elements, setElements] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { fields } = elements ?? {};

  useEffect(() => {
    // @ts-ignore
    formJSON[0].fields[FM.category].option =
      createCasteCategoryOptions(reservations);
    setElements({ ...formJSON[0] });
  }, [reservations]);

  const onSubmitFn = async (data: FieldValues) => {
    if (data) {
      setLoading(true);
      try {
        const response = await acceptApplication({
          variables: {
            id: applicationId,
            category: data.category.value,
          },
        });
        if (response?.data?.acceptApplication) {
          onSuccess(t("acceptApplication.text"));
        }
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      modalVisible={true}
      Headerpopup={t("acceptApplication.label")}
      width={600}
      handleSave={handleSubmit(onSubmitFn)}
      addEditButtonLabel={"save.label"}
    >
      <Container>
        {fields &&
          fields.map((field: any) => (
            // @ts-ignore
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
            />
          ))}
      </Container>
    </NormalModal>
  );
};

// fieldMap
const FM = { category: 0 };

const createCasteCategoryOptions = (reservations: Reservation[]) => {
  return reservations.map((r) => ({
    label: r.caste.name,
    value: r.caste.id,
  }));
};

const Container = styled.div`
  height: 156px;
`;

export default AcceptApplicationForm;
