/* eslint-disable react-native/no-inline-styles */
import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { View, useWindowDimensions, Dimensions, EmitterSubscription } from 'react-native';
import AddButton from 'src/components/atoms/AddButton';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import MobileHeader from 'src/components/hoc/MobileHeader';
import AssignmentCard from 'src/components/molecules/AssignmentCard/Student/Assignment';
import AssignmentForm from 'src/components/molecules/Assignment/Teacher/AssignmentForm';
import { height, isIOS, isNative, isWeb, width } from 'src/constant/device';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import {
  getAssignmentListQuery,
  publishAssignmentQuery,
  useCreateAssignmentMutation,
  useDeleteAssignmentMutation,
  useUpdateAssignmentMutation,
} from 'src/graphql/communication/assignments';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ASSIGNMENT_LIMIT,
  AUDIENCEPAGE,
  AUDIENCE_TYPE,
  EMPLOYEE_TYPES,
  ERROR,
  FILTERS,
  SUCCESS,
} from 'src/constant';
import { breakpoints, colors, fonts } from 'src/styles/theme/styles';
import { assignmentDataType, assignmentObjectType } from './types';
import { faBackpack } from '@fortawesome/pro-regular-svg-icons';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { usePersistStorage } from 'react-native-use-persist-storage';
import Listing from 'src/components/molecules/CommunicationListing';
import { KEYS } from 'src/constant/key';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';
import navigationStrings from 'src/navigation/navigationStrings';
import { NoNewsNotices } from 'src/components/molecules/NoNewsNotices';
import Search from 'src/components/atoms/SearchBar/index';
import { useHistory } from 'src/routes/routing';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import styled from 'styled-components/native';
import { debounce } from 'lodash';
import { createSearchFilter, filterDoc } from 'src/components/services';
import DeleteModal from 'src/components/atoms/Modals/Delete/index';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { showNativeError, showNativeSuccess } from 'src/components/molecules/NotificationWrapper';
import AlertModalNative from 'src/components/molecules/AlertModal/AlertModalNative';
import { validateTotalSizeOfAttachment } from 'src/utils/utility';
import { CustomReactNativeFile } from 'src/components/molecules/FileUpload/type';
import AudienceWrapper from 'src/components/molecules/Audience/AudienceWrapper';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import AbstractFlashList from 'src/components/atoms/FlashList';

export default function TeacherAssignments(props: any) {
  var totalAssignment = 0;
  const { t } = useI18n();
  const { alertState, setAlertDetails } = useAlertSystem();
  const [attachments, setAttachments] = useState<CustomReactNativeFile[]>([]);
  const { setHeading } = useHeaderTitle();
  const [assignmentDetails, setAssignmentDetails] = useState<any>({});
  const [publishAssignment] = useLazyQuery(publishAssignmentQuery);
  const [searchText, setSearchText] = useState('');
  const [toggleAdd, setAdd] = useState<boolean>(isWeb);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [pendingSkip, setPendingSkip] = useState<number>(0);
  const [windowWidth, setwindowWidth] = useState(useWindowDimensions().width);
  const [isEditModal, setEditModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [batches, setBatches] = useState<any[]>([]);
  const isDesktop = width > breakpoints.md; //desk
  const [submitAssignedToggle, setSubmitAssignedToggle] = usePersistStorage<any>(
    KEYS.SUBMIT_ASSIGNMENT_SWITCH,
    {},
  );
  let staticParam = {
    params: {
      assignmentType: '',
    },
  };
  const { params } = isNative ? props?.route : staticParam;
  const [refreshing, setRefreshing] = useState(false);
  const currentUserObject = userInformation();

  const { userDetail }: any = currentUserObject;
  const isTeaching = userDetail?.type?.includes(EMPLOYEE_TYPES.TEACHING);
  const history = useHistory();
  const pendingRef = useRef<any>(null);
  const [removeAttach, setRemovedAttachment] = useState<string[]>([]);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [assignmentIndex, setAssignmentIndex] = useState<number | null>(null);
  const [audienceType, setAudienceType] = useState(null);
  const [doRefetch, setDoRefetch] = useState(false);
  const { assignmentDeletion } = useDeleteAssignmentMutation(
    assignmentIndex,
    searchText,
    assignmentDetails?.id,
  );

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  let { createAssignment } = useCreateAssignmentMutation();
  let { assignmentUpdation } = useUpdateAssignmentMutation();
  let assignments: assignmentObjectType[];
  let searchFilters = [
    {
      title: {
        regex: '',
      },
    },
    {
      description: {
        regex: '',
      },
    },
  ];
  const {
    data: assignmentData,
    loading: assignmentsLoading,
    refetch: assignmentRefetch,
    fetchMore: fetchMoreAssignment,
  } = useQuery(getAssignmentListQuery, {
    variables: { limit: ASSIGNMENT_LIMIT, skip: 0, filters: { or: searchFilters } },
    fetchPolicy: 'network-only'
  });
  assignments = assignmentData?.getAssignments?.data;
  totalAssignment = assignmentData?.getAssignments?.totalCount;

  const allBatches = useQuery(getBatchQuery, {
    variables: { limit: 0 },
  });

  useEffect(() => {
    if (!allBatches.loading && allBatches.data) {
      setBatches(allBatches.data.batches.data);
    }
  }, [allBatches.loading, allBatches.data]);

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    // Native :: Refetch details after assignment update/edit
    if (doRefetch) {
      onRefresh()
    }
  }, [doRefetch])

  useEffect(() => {
    if (!isWeb && isTeaching && assignmentData?.getAssignments?.data.length === 0) {
      setAdd(true);
    }
  }, [assignmentsLoading]);

  useEffect(() => {
    const allowed = validateTotalSizeOfAttachment(attachments);
    if (!allowed) {
      setCanClick(false);
      showError(t("err.totalFilesize.exceeds"));
    } else {
      setCanClick(true);
    }
  }, [attachments]);

  function nativeRefresh() {
    setAdd(true);
    assignmentRefetch();
    setPendingSkip(0);
    setRefreshing(false);
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    nativeRefresh();
  }, []);

  function createPayload(assignmentData: any) {
    const payload = {} as any;
    for (const property in assignmentData) {
      if (assignmentData[property]) {
        const keyvalue = assignmentData[property];
        if (property === 'dueDate') {
          let formatedDate: string;
          if (isWeb) {
            formatedDate = keyvalue;
          } else {
            formatedDate = keyvalue;
          }
          payload[property] = keyvalue;
        } else if (property === 'class') {
          payload[property] = assignmentData['batch'].class.id;
        } else if (keyvalue?.value) {
          payload[property] = keyvalue?.value;
        } else {
          payload[property] = keyvalue;
        }
      }
    }

    return payload;
  }

  async function handleEditAssignment(assignmentData: assignmentDataType) {
    try {
      if (canClick) {
        setCanClick(false);
        let finalDate: any;

        let requestPayload = {
          id: assignmentDetails?.id,
          description: assignmentData?.description,
          attachments: filterDoc(attachments),
          removeAttachments: removeAttach,
          totalMarks: parseFloat(assignmentData.totalMarks.toString()),
        } as any;

        if (isWeb) {
          if (typeof assignmentData.dueDate !== 'object') {
            requestPayload['dueDate'] = assignmentData.dueDate;
          }
        } else {
          finalDate = assignmentData.dueDate;
          requestPayload['dueDate'] = finalDate;
        }

        // use for update
        let updateAssignmentData = await assignmentUpdation({
          variables: { payload: requestPayload },
        });
        if (updateAssignmentData?.data?.updateAssignment) {
          closeFormModal();
          handleUpdateSuccess();
          assignmentRefetch();
          setAlertDetails({ message: UPDATE_SUCCESS.ASSIGNMENT, level: SUCCESS });
        }
      }
    } catch (error) {
      setCanClick(true);
      showError(error.message);
    }
  }

  function showError(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: ERROR });
    } else {
      showNativeError(message);
    }
  }

  function showSuccess(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: SUCCESS });
    } else {
      showNativeSuccess(message);
    }
  }

  function handleUpdateSuccess() {
    if (!isDesktop) {
      if (isNative) {
        props.navigation.navigate(navigationStrings.NATIVE_ASSIGNMENT_DETAIL, {
          assignmentId: assignmentDetails?.id,
        });
        setEditModal(false);
      }
    }
    assignmentRefetch();
  }

  async function handleCreateAssignment(assignmentData: assignmentDataType, audience: any, batch?: string) {
    try {
      assignmentData.batch = batch;
      let requestPayload = createPayload(assignmentData);
      requestPayload['attachments'] = attachments;
      requestPayload['assigneeType'] = audienceType;
      requestPayload['assignees'] = audience;
      requestPayload['totalMarks'] = parseFloat(assignmentData.totalMarks.toString());

      if (canClick) {
        setCanClick(false);
        let addAssignmentData = await createAssignment({
          variables: { payload: requestPayload },
        });

        if (addAssignmentData?.data?.createAssignment) {
          let data = addAssignmentData?.data?.createAssignment;
          let assignementId = data?.id;
          publishIt(assignementId);
        }
      }
    } catch (error) {
      setCanClick(true);
      showError(error.message);
    }
  }

  const handleDeleteAssignment = async () => {
    setCanClick(false);
    let assignmentID = assignmentDetails?.id

    try {
      let deleteResponse = await assignmentDeletion({ variables: { id: assignmentID } });
      if (deleteResponse) {
        setDeleteOverlay(false);
        showSuccess(DELETE_SUCCESS.ASSIGNMENT)
      }
    } catch (error: any) {
      showError(error.message);
    }
    setCanClick(true);
  };

  function publishIt(assignementId: string) {
    setFormModal(false);
    try {
      let publishedResponse = publishAssignment({
        variables: {
          id: assignementId,
        },
      });
      closeModal();
      setCanClick(true);
      showSuccess(ADD_SUCCESS.ASSIGNMENT);
      assignmentRefetch();
    } catch (error) {
      setCanClick(true);
      showError(error.message);
    }
  }

  function showAssignmentDetail(assignment: any) {
    if (isWeb) {
      history.push(`/assignments/${assignment?.id}/detail`);
    } else {
      // native:: pass state setter to detect whether to refresh if updated
      // TODO Refactor, current approach not a good way to refresh.
      if (setDoRefetch) setDoRefetch(false)
      props.navigation.navigate(navigationStrings.NATIVE_ASSIGNMENT_DETAIL, {
        assignmentId: assignment?.id,
        setDoRefetch
      });
    }
  }

  function closeNative() {
    if (isNative) {
      props.navigation.navigate(navigationStrings.NATIVE_ASSIGNMENT_DETAIL, {
        assignmentId: '',
      });
    }
  }

  function headingSetter(heading: string) {
    setHeading([
      {
        text: t(heading),
        url: '',
      },
    ]);
  }

  function setTitle() {
    if (isWeb) {
      headingSetter('assignments.label');
    }
  }

  function closeModal() {
    resetForms();
    setAttachments([]);
    setSubmitAssignedToggle(1);
    setFormModal(false);
    setEditModal(false);
  }

  useEffect(() => {
    const subscription: EmitterSubscription = Dimensions.addEventListener("change", (e) => {
      setwindowWidth(e.window.width);
    });
    return () => subscription.remove();
  }, []);

  function hideAlert() {
    if (isWeb) {
      setAlertDetails({ message: '', level: '' });
    }
  }

  async function handleSearch(searchText: string) {
    let filters = [] as any;
    filters = [
      {
        field: 'title',
        type: FILTERS.REGEX,
        value: searchText,
      },
      {
        field: 'description',
        type: FILTERS.REGEX,
        value: searchText,
      },
    ];

    let assignmentFilter = createSearchFilter(ASSIGNMENT_LIMIT, pendingSkip, filters);

    try {
      setRefreshing(true);
      assignmentRefetch(assignmentFilter);
      setPendingSkip(0);
    } catch (error) {
      console.log('Error while refetching data');
    }
  }

  const debouncedSearch = useCallback(debounce(handleSearch, 500), []);

  function handleAssignmentPagination() {
    if (totalAssignment > assignments.length) {
      setFetchMoreLoading(true);
      handlePagination(
        getAssignmentListQuery,
        ASSIGNMENT_LIMIT,
        pendingSkip + ASSIGNMENT_LIMIT,
        fetchMoreAssignment,
      );
      setPendingSkip(pendingSkip + ASSIGNMENT_LIMIT);
    }
  }

  function editAssignment(assignmentData: any) {
    if (isWeb) {
      setAssignmentDetails(assignmentData);
    } else {
      assignmentData.persist();
    }
    clearErrors();
    setEditModal(true);
    setFormModal(true);
  }

  function deleteAssignment(assignmentData: any, idx: number) {
    setAssignmentDetails(assignmentData);
    setAssignmentIndex(idx);
    setDeleteOverlay(true);
  }

  function handlePagination(
    paginateQuery: any,
    assignmentLimit: number,
    assigmentSkip: number,
    fetchFunction: any,
  ) {
    let filters = [
      {
        field: 'title',
        type: FILTERS.REGEX,
        value: searchText,
      },
      {
        field: 'description',
        type: FILTERS.REGEX,
        value: searchText,
      },
    ];

    let assignmentFilter = createSearchFilter(assignmentLimit, assigmentSkip, filters);

    try {
      fetchFunction({
        query: paginateQuery,
        variables: assignmentFilter,
        updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
          setFetchMoreLoading(fetchMoreResult.getAssignments.data ? false : true);
          return {
            getAssignments: {
              hasNextPage: fetchMoreResult.getAssignments.hasNextPage,
              data: [...previousResult.getAssignments.data, ...fetchMoreResult.getAssignments.data],
              totalCount: fetchMoreResult.getAssignments.totalCount,
              __typename: fetchMoreResult.getAssignments.__typename,
            },
          };
        },
      });
    } catch (error: any) {
      console.log(error.message);
    }
  }

  const closeFormModal = () => {
    closeFinal();
    closeNative();
    resetForms();
    setCanClick(true);
  };

  function resetForms() {
    setValue('title', '');
    setValue('description', '');
    setValue('class', '');
    setValue('assignees', null);
    setValue('subject', null);
    setValue('dueDate', '');
    setValue('totalMarks', '');
    setAttachments([]);
    clearErrors();
  }

  function closeFinal() {
    clearErrors();
    setFormModal(false);
    setTimeout(() => {
      setEditModal(false);
    }, 300);
  }

  return (
    <Fragment>
      {isWeb && windowWidth > breakpoints.md ? (
        <Fragment>
          {!assignmentsLoading ? (
            <LoadContentWrapper>
              {/* Desktop web */}
              <>
                {/* SEARCH BAR */}
                {assignments?.length > 0 ||
                (assignments?.length < 1 && searchText !== '') ||
                (assignments?.length < 1 && refreshing) ? (
                  <FlexRowView>
                    <LeftPartView>
                      <Search
                        id="Search"
                        handleChange={async e => {
                          // setSearchOn(true);
                          setSearchText(e);
                          await debouncedSearch(e);
                        }}
                        value={searchText}
                        label={t('searchAssignments.text')}
                      />
                    </LeftPartView>

                    <RightPartView>
                      {isTeaching && (
                        <SecondaryBtn
                          label={t('addAssignment.text')}
                          onPress={() => {
                            setFormModal(true);
                            setEditModal(false);
                            assignmentRefetch();
                            hideAlert();
                          }}
                          secondary={false}
                        />
                      )}
                    </RightPartView>
                  </FlexRowView>
                ) : null}

                {/* NO SEARCH DATA */}
                {assignments?.length < 1 && searchText !== '' && (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}>
                    <NormaltextAtom value={t('noResultFound.text')} />
                  </View>
                )}

                {/* LIST*/}
                {assignments?.length > 0 && (
                  <>
                    <AbstractFlashList
                      ref={pendingRef}
                      onEndReached={handleAssignmentPagination}
                      onEndReachedThreshold={0.8}
                      data={assignments}
                      estimatedItemSize={169}
                      children={({ item, index }: any) => (
                        <AssignmentCard
                          key={`assign${item?.id}`}
                          data={item}
                          indexKey={index}
                          onPress={showAssignmentDetail}
                          student={false}
                          assignmentType={'pending'}
                          editAction={editAssignment}
                          deleteAction={deleteAssignment}
                          isSwipable={false}
                        />
                      )}
                    />
                    {fetchMoreLoading ? (
                      <BottomSpinnerView>
                        <LoaderSpinner />
                      </BottomSpinnerView>
                    ) : null}
                  </>
                )}
              </>

              {/* NO WHOLE DATA */}

              {searchText === '' && !refreshing && assignments?.length < 1 && (
                <NoDataContainer>
                  <NoNewsNotices icon={faBackpack} size={40} label={t('noAssignments.text')} />
                  <View style={{ position: 'relative' }}>
                    <AddButton
                      style={{ width: 113 }}
                      label={t('addNew.text')}
                      onPress={() => {
                        setFormModal(true);
                        setEditModal(false);
                        hideAlert();
                      }}
                    />
                  </View>
                </NoDataContainer>
              )}
            </LoadContentWrapper>
          ) : (
            <SpinnerView
              style={{ backgroundColor: isDesktop ? colors.bluishGreyBackground : colors.white }}>
              <LoaderSpinner />
            </SpinnerView>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <MobileHeader label={t('assignments.label')} backIcon={false} {...props} />
          {!assignmentsLoading ? (
            <WebWrapperView>
              {isNative && (
                <View testID={'AssignmentView'}>
                  {isTeaching && toggleAdd && (
                    <MarginTop24View>
                      <AddButton
                        onPress={() => {
                          setEditModal(false);
                          setFormModal(true);
                          hideAlert();
                        }}
                        label={t('addAssignment.text')}
                        testID={'addAssignmentBtn'}
                        color={colors.primaryText}
                      />
                    </MarginTop24View>
                  )}

                  <Listing
                    estimatedItemSize={176}
                    passRef={pendingRef}
                    onEndReached={handleAssignmentPagination}
                    onEndReachedThreshold={0.5}
                    Comp={AssignmentCard}
                    arrayList={assignments}
                    icon={faBackpack}
                    label={t('noPendingAssignments.text')}
                    onPress={showAssignmentDetail}
                    student={false}
                    onRefresh={onRefresh}
                    refreshing={refreshing}
                    deleteAction={deleteAssignment}
                    setShowAdd={setAdd}
                    fetchMoreLoading={fetchMoreLoading}
                    heightOffset={isIOS ? 182 : 120}
                  />

                  <AlertModalNative
                    title={t('deleteAssignment.text')}
                    message1={t('deleteAssignment.warning')}
                    onCancel={() => setDeleteOverlay(false)}
                    onConfirm={() => handleDeleteAssignment()}
                    showModal={deleteOverlay}
                    confirmButtonColor={colors.errorColor}
                    confirmButtonText={t('delete.label')}
                  />
                </View>
              )}
            </WebWrapperView>
          ) : (
            <SpinnerView>
              <LoaderSpinner />
            </SpinnerView>
          )}
        </Fragment>
      )}

      {isWeb && (
        <DeleteModal
          isSubmitting={!canClick}
          setModalVisible={() => setDeleteOverlay(false)}
          modalVisible={deleteOverlay}
          handleSave={handleDeleteAssignment}
          Headerpopup="deleteAssignment.text">
          {t('deleteAssignment.warning')}
        </DeleteModal>
      )}

      <AudienceWrapper
        isSubmitting={!canClick}
        onAlert={alertState}
        setModalVisible={closeModal}
        Headerpopup={t(isEditModal ? 'editAssignment.text' : 'addAssignment.text')}
        width={840}
        height={480}
        maxWidth={'lg'}
        modalVisible={formModal}
        handleSave={!isEditModal ? handleCreateAssignment : handleEditAssignment}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'save.label' : 'next.label'}
        style={{ paddingBottom: 0 }}
        handleSubmit={handleSubmit}
        setAudienceType={setAudienceType}
        audienceType={audienceType}
        parentType={'ASSIGNMENT'}
        types={[AUDIENCE_TYPE.BATCH]}
        isEditModal={isEditModal}
        editDetails={
          isEditModal
            ? assignmentDetails?.assigneeType === 'STUDENTS'
              ? assignmentDetails?.assigneeList
              : assignmentDetails?.assigneeLabels
            : []
        }>
        <AssignmentForm
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          setAttachments={setAttachments}
          attachments={attachments}
          isEditModal={isEditModal}
          assignmentData={assignmentDetails}
          setError={setError}
          clearErrors={clearErrors}
          removeAttach={removeAttach}
          setRemovedAttachment={setRemovedAttachment}
        />
      </AudienceWrapper>
    </Fragment>
  );
}

const WebWrapperView = styled.View`
  background-color: ${colors.white};
  padding-top: 0px;
  padding-bottom: 0px;
`;

const MarginTop24View = styled.View`
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
`;

const FlexRowView = styled.View`
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 8px;
`;

const LeftPartView = styled.View`
  flex: 3;
  align-items: flex-start;
`;

const RightPartView = styled.View`
  flex: 3;
  align-items: flex-end;
`;

const SpinnerView = styled.View`
  height: ${height - 100}px;
  justify-content: center;
  align-items: center;
`;

const BottomSpinnerView = styled.View`
  height: 50px;
  justify-content: center;
  align-items: center;`;

const NoDataContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;
