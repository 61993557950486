import { ConcessionType, FeeBookType } from "../Payment/types";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { colors, fontWeight, fonts } from "src/styles/theme/styles";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import MediumText from "src/components/atoms/Text/MediumText";
import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import { NoRecordsFound } from "src/components/molecules/Table/TableAtom";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import formJSON from "src/form-json/payment/PartialFeePayment/partial-fee-payment.json";
import { height } from "src/constant/device";
import styled from "styled-components/native";
import { useI18n } from "src/i18n/hooks";

type Props = {
  feeBooks?: FeeBookType[];
  totalPayable?: number;
  totalPending?: number;
  control: Control<any>;
  errors: FieldErrors<FieldValues>;
};

export default function PartialPaymentListing(props: Props) {
  const { feeBooks, totalPayable, totalPending, control, errors } = props;
  const { t } = useI18n();

  const [elements, setElements] = useState<any>({});

  const { fields } = elements ?? {};

  useEffect(() => {
    setElements({ ...formJSON[0] });
  }, []);

  function sortFeeGroupsByOrderNo(feeGroups: FeeBookType[]): FeeBookType[] {
    return feeGroups.sort((a, b) => a.orderNo - b.orderNo);
  }

  function isSingularListing(item: FeeBookType): boolean {
    const child = item.fees[0];
    return (
      item.groupName === child.name &&
      item.payable === child.payable &&
      item.orderNo === child.orderNo
    );
  }

  const sortedData = feeBooks ? sortFeeGroupsByOrderNo([...feeBooks]) : [];

  const TableHead = () => {
    return (
      <TableHeadWrapper>
        <NormaltextAtom
          value={t("payment.particulars")}
          color={colors.secondaryText}
          fontWeight={fontWeight[600]}
        />
        <NormaltextAtom
          value={t("payment.amount")}
          color={colors.secondaryText}
          fontWeight={fontWeight[600]}
        />
      </TableHeadWrapper>
    );
  };

  const TableRows = ({ row }: { row: FeeBookType[] }) => {
    return (
      <TableRowsWrapper showsVerticalScrollIndicator={false}>
        {row.map((item: FeeBookType) => {
          return (
            <>
              <GroupHeaderWrapper>
                <ModuleNameWrapper>
                  <MediumText
                    lines={1}
                    value={item.groupName}
                    lineHeight={2.4}
                    color={colors.primaryText}
                  />
                </ModuleNameWrapper>
                <MediumTextSelect
                  fontFamily={fonts.semibold}
                  value={`\u20B9  ${item.payable}`}
                  color={colors.primaryText}
                />
              </GroupHeaderWrapper>
              {item?.fees &&
                item.fees.length > 0 &&
                item.fees.map((fee) => {
                  return (
                    <>
                      {(item.fees.length > 1 ||
                        item.fees[0].concessions.length > 0 ||
                        !isSingularListing(item)) && (
                          <RowItem>
                            <ModuleNameWrapper>
                              <NormaltextAtom
                                lines={1}
                                lineHeight={2}
                                value={fee.name}
                                fontSize={1.6}
                              />
                            </ModuleNameWrapper>
                            <NormaltextAtom
                              lineHeight={2}
                              value={`\u20B9 ${fee.amount}`}
                              fontSize={1.6}
                            />
                          </RowItem>
                        )}
                      {fee?.concessions?.length > 0 &&
                        fee.concessions.map((c: ConcessionType) => (
                          <>
                            <RowItem>
                              <ModuleNameWrapper>
                                <NormaltextAtom
                                  lines={1}
                                  lineHeight={2}
                                  value={c.name}
                                  fontSize={1.6}
                                />
                              </ModuleNameWrapper>
                              <NormaltextAtom
                                lineHeight={2}
                                value={`- \u20B9 ${c.amount}`}
                                fontSize={1.6}
                              />
                            </RowItem>
                          </>
                        ))}
                    </>
                  );
                })}
            </>
          );
        })}
      </TableRowsWrapper>
    );
  };

  const PaymentTotal = () => {
    return (
      <TotalWrapper>
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={t("totalPayment.label")}
          color={colors.primaryText}
        />
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={`\u20B9 ${totalPayable}`}
          color={colors.primaryText}
        />
      </TotalWrapper>
    );
  };

  const PaymentPendingTotal = () => {
    return (
      <TotalPendingWrapper>
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={t("totalPendingPayment.label")}
          color={colors.primaryText}
        />
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={
            totalPending || totalPending == 0 ? `\u20B9 ${totalPending}` : "-"
          }
          color={colors.primaryText}
        />
      </TotalPendingWrapper>
    );
  };

  return (
    <>
      <Container>
        <TableHead />
        <TableBodyWrapper nestedScrollEnabled={true}>
          {sortedData && sortedData?.length > 0 ? (
            <>
              <TableRows row={sortedData} />
              <PaymentTotal />
              <PaymentPendingTotal />
              <ElementWrapper>
                {fields &&
                  fields?.map((field: any) => (
                    // @ts-ignore
                    <Element
                      key={field.id}
                      field={field}
                      control={control}
                      errors={errors}
                    />
                  ))}
              </ElementWrapper>
            </>
          ) : (
            <NoRecordWrapper>
              <NoRecordsFound colspan={2} maxHeight={height - 450} />
            </NoRecordWrapper>
          )}
        </TableBodyWrapper>
      </Container>
    </>
  );
}

const Container = styled.View``;

const TableRowsWrapper = styled.ScrollView`
  max-height: ${height - 450};
`;

const TableHeadWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const TableBodyWrapper = styled.ScrollView``;

const GroupHeaderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TotalWrapper = styled.View`
  border-top-width: 1px;
  border-top-color: ${colors.dividerColor};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
  padding: 16px 0px;
  flex-direction: row;
  justify-content: space-between;
`;

const RowItem = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-left: 16px;
  margin-bottom: 16px;
`;

const ModuleNameWrapper = styled.View`
  display: flex;
  align-items: flex-start;
  width: 80%;
  padding-right: 8px;
`;

const ElementWrapper = styled.View`
  padding-top: 16px;
  max-width: 480px;
`;

const TotalPendingWrapper = styled(TotalWrapper)`
  border-top-width: none;
`;

const NoRecordWrapper = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
`;
