import { colors, fonts } from "src/styles/theme/styles";

import { Linking } from "react-native";
import React from "react";
import styled from "styled-components/native";

type Props = {
  text: string;
  variant?: "default" | "small";
};

const urlRegex: RegExp = /(https?:\/\/[^\s]+)/g;

const HyperLinkText = (props: Props) => {
  const { text, variant = "default" } = props;
  const segments = text.split(urlRegex);

  return (
    <StyledText variant={variant}>
      {segments.map((segment, index) => {
        if (segment.match(urlRegex)) {
          return (
            <StyledURL
              key={`url${index}`}
              onPress={() => Linking.openURL(segment)}
            >
              {segment}
            </StyledURL>
          );
        } else {
          return segment;
        }
      })}
    </StyledText>
  );
};

const StyledText = styled.Text<{ variant: Props["variant"] }>`
  font-family: ${fonts.regular};
  color: ${colors.secondaryText};
  font-weight: normal;
  font-size: ${({ variant, theme }) =>
    theme.rem(variant === "small" ? 1.2 : 1.5)};
  line-height: ${({ variant, theme }) =>
    theme.rem(variant === "small" ? 1.6 : 2.2)};
`;

const StyledURL = styled.Text`
  color: ${colors.primaryColor};
  text-decoration-line: underline;
`;

export default HyperLinkText;
