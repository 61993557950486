import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from 'date-fns';

export function getDurationLabel(durationTypeValue: number) {
  switch (durationTypeValue) {
    case 0:
      return 'weekly.label';
    case 1:
      return 'monthly.label';
    case 2:
      return 'daily.label';
    default:
      return 'monthly.label';
  }
}

export const DurationTypeValue = {
  0: 'week',
  1: 'month',
  2: 'daily',
} as any;

export function getStartDateFunction(durationTypeValue: string) {
  switch (durationTypeValue) {
    case 'week':
      return startOfWeek;
    case 'month':
      return startOfMonth;
    case 'daily':
      return startOfDay;
    default:
      return startOfMonth;
  }
}

export function getEndDateFunction(durationTypeValue: string) {
  switch (durationTypeValue) {
    case 'week':
      return endOfWeek;
    case 'month':
      return endOfMonth;
    case 'daily':
      return endOfDay;
    default:
      return endOfMonth;
  }
}
