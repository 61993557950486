import React, { useState, useEffect } from 'react';
import Element from '../Forms/ApplicationElement.web';
import attendanceFomJson from '../../../form-json/attendance/attendanceSettings-form.json';
import { isWeb } from 'src/constant/device';
import styled from 'styled-components';
import { colors, fonts } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { useSidebarSystem } from 'src/contexts/sidebar-context';
import Chip from 'src/components/atoms/Chip';

export default function MarkAttendanceSettingsForm(props: any) {
  const {
    errors,
    control,
    setValue,
    selectedAttendenceType,
    numberOfSlots,
    handleFileRemove,
    handleFilesAttach,
    fileObjects,
    studentDetails,
    setStudentDetails,
    employeeDetails,
    setEmployeeDetails,
    watch,
  } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};
  const { t } = useI18n();
  const { togglevalue } = useSidebarSystem();
  let width = window.innerWidth;
  const {grNoPrefix, startingGRNo} = watch();

  const options = [
    { value: 0, label: 'Full Day', type: 'DAY_WISE' },
    { value: 1, label: 'Slots', type: 'SLOT_WISE' },
    { value: 2, label: 'Subjects', type: 'CLASS_WISE' },
  ];

  const [showSlots, setShowSlots] = useState(false);
  const hideFieldsIdx =
    width < 1366 || togglevalue.toggle === 1
      ? showSlots
        ? [2, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40]
        : [2, 12, 16, 19, 22, 25, 28, 31, 34, 37, 40]
      : showSlots
        ? [6, 13]
        : [6, 12];

  async function handleCustomSelect(id: string, data: any) {
    const index = isWeb ? data?.value : data;

    if (index == 1) {
      setShowSlots(true);
    } else {
      setValue('numberofslots', null);
      setShowSlots(false);
    }
  }

  function viewHandler(i: number) {
    if (hideFieldsIdx.includes(i)) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (Object.keys(selectedAttendenceType).length > 0) {
      if (selectedAttendenceType.value === 1 && numberOfSlots > 0) {
        setShowSlots(true);
        setValue('attendanceType', options[selectedAttendenceType.value]);
        setValue('numberofslots', { value: numberOfSlots, label: numberOfSlots.toString() });
      } else {
        setValue('attendanceType', options[selectedAttendenceType.value]);
      }
    }
    setElements(attendanceFomJson[0]);
  }, []);

  function detailsToggleFn(e: any, checked: boolean) {
    const id = e.target.id;
    switch (id) {
      case 'showOnboardingStudent':
        setStudentDetails((prev: any) => ({
          ...prev,
          showOnboarding: checked,
        }));
        break;
      case 'allowProfileEditStudent':
        setStudentDetails((prev: any) => ({
          ...prev,
          allowProfileEdit: checked,
        }));
        break;
      case 'showOnboardingEmployee':
        setEmployeeDetails((prev: any) => ({
          ...prev,
          showOnboarding: checked,
        }));
        break;
      case 'allowProfileEditEmployee':
        setEmployeeDetails((prev: any) => ({
          ...prev,
          allowProfileEdit: checked,
        }));
        break;
    }
  }

  function getToggleValue(id: string) {
    switch (id) {
      case 'showOnboardingStudent':
        return studentDetails.showOnboarding;
      case 'allowProfileEditStudent':
        return studentDetails.allowProfileEdit;
      case 'showOnboardingEmployee':
        return employeeDetails.showOnboarding;
      case 'allowProfileEditEmployee':
        return employeeDetails.allowProfileEdit;
    }
  }

  function getHeading(id: string) {
    if(id){
      switch (id) {
        case 'showOnboardingStudent':
          return 'form.studentSettings.label';
        case 'showOnboardingEmployee':
          return 'form.employeeSettings.label';
        case 'idCardOrientation':
          return 'form.idCard.label';
        case 'leavingCertificateAuthorityIdentityName1':
          return 'form.leavingCertificate.label';
        case 'bonafideCertificateAuthorityIdentityName1':
          return 'form.bonafideCertificate.label';
        case 'feeReceiptAuthorityIdentityName':
          return 'form.feeReceipt.label';
        case 'libraryCardAuthorityIdentityName':
          return 'form.libraryCard.label';
        case 'grNoPrefix':
          return "admission.label";
      }
    }
  }

  return (
    <ContainerWrapper>
      <HeadingWrapper idx={0}>{t('form.generalSettings.label')}</HeadingWrapper>
      <GridWrapper toggle={togglevalue.toggle}>
        {fields && (
            <>
              {fields.map((field: any, i: number) => {
                return (
                  viewHandler(i) && (
                    <>
                      <ElementWrapper
                        key={`settings${i}`}
                        style={{
                          alignSelf: [15, 18, 21, 27, 33].includes(i) ? 'flex-end' : 'auto',
                        }}>
                        {[14, 17, 20, 26, 32, 38, 43, 49, 50].includes(i) && (
                          <HeadingWrapper idx={i}>{t(getHeading(field.id) || '\u200b')}</HeadingWrapper>
                        )}
                        <Element
                          field={field}
                          control={control}
                          errors={errors}
                          file={fileObjects[field.id]}
                          handleFilesAttach={handleFilesAttach(field.id)}
                          handleFileRemove={handleFileRemove}
                          handleCustomSelect={handleCustomSelect}
                          dropdownWidth={350}
                          maxDropdownOptionsHeight={200}
                          elementStyle={{ alignItems: 'center' }}
                          switchState={getToggleValue(field.id)}
                          handleSwitch={detailsToggleFn}
                        />
                      </ElementWrapper>
                    </>
                  )
                );
              })}
              {Boolean(parseFloat(startingGRNo)) && (
                <ChipContainer>
                  <Chip
                    label={`${grNoPrefix ? grNoPrefix : ''}${startingGRNo}`}
                    close={false}
                    style={styles.chip}
                  />
                </ChipContainer>
              )}
            </>
        )}
      </GridWrapper>
    </ContainerWrapper>
  );
}

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GridWrapper = styled.div<{ toggle: number }>`
  display: flex;
  flex-wrap: wrap;
  max-width: ${(props) => (props?.toggle == 1 ? '740px' : '1130px')};
  column-gap: 40px;
`;

const HeadingWrapper = styled.div<{ idx?: number }>`
  font-family: ${fonts.semibold};
  font-size: 21px;
  line-height: 25px;
  color: ${colors.primaryText};
  margin-top: ${(props) => (props?.idx == 0 ? 10.4 : 16)}px;
  margin-bottom: ${(props) => (props?.idx === 14 || props?.idx === 17 ? 24 : 12)}px;
`;

const ElementWrapper = styled.div`
  width: 350px;
`;

const ChipContainer = styled.div`
  width: 100%;
`;

const styles = {
  chip: {
    alignSelf: 'flex-start',
    width: 'fit-content',
  }
}