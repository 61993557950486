import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import Chip from 'src/components/atoms/Chip';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import formJSON from 'src/form-json/admission/documents-input.json';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import { DropdownOptions } from 'src/types';
import styled from 'styled-components';
import { ChipData, placeAllowedOptions } from './AdmissionFormHelpers';

interface Props {
  documentsOptions: DropdownOptions[];
  documentChips: ChipData[];
  setDocumentChips: Dispatch<SetStateAction<ChipData[]>>;
  editMode: boolean;
}

export default function DocumentsInput(props: Props) {
  const { documentsOptions, editMode, documentChips, setDocumentChips } = props;
  const { t } = useI18n();

  const [elements, setElements] = useState<any>({});
  const { fields } = elements ?? {};

  useEffect(() => {
    clearFormValues();
    initialFetch();
  }, []);

  useEffect(() => {
    if (editMode && documentChips && documentChips.length > 0) {
      const docsMap = new Map(documentsOptions.map((dt: any) => [dt.value, dt.label]));
      const docIds = documentChips.map((chip: ChipData) => {
        if (docsMap.get(chip?.docType)) {
          return chip?.docType;
        }
      })
      const set = new Set(docIds);
      const duplicates = docIds.filter(item => !set.delete(item));

      const documentOptions = formJSON[0]?.fields[0]?.option?.filter(
        (item: DropdownOptions) => !duplicates.includes(item?.value),
      );
      { /* @ts-ignore */ }
      formJSON[0].fields[0].option = documentOptions;
    }
  }, [editMode, documentChips]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const initialFetch = async () => {
    // @ts-ignore
    formJSON[0].fields[0].option = documentsOptions;
    setElements(formJSON[0]);
  };

  function clearFormValues() {
    setValue('document', null);
    setValue('placeAllowed', null);
    setValue('documentRequired', false);
    formJSON[0].fields[1].option = [];
  }

  function handleAddDocuments(formData: any) {
    let chipsData: ChipData[] = [...documentChips];
    chipsData.push({
      docType: formData?.document?.value,
      allowedPlace: formData?.placeAllowed?.value,
      required: formData?.documentRequired,
      label: `${formData?.document?.label} (${
        formData?.documentRequired ? 'Mandatory' : 'Optional'
      }) : ${formData?.placeAllowed.label}`,
    });

    const document = chipsData.filter(
      (item: ChipData) => item?.docType === formData?.document?.value,
    );
    if (document && document.length > 1) {
      const documentOptions = formJSON[0]?.fields[0]?.option?.filter(
        (item: DropdownOptions) => item?.value !== formData?.document?.value,
      );
      { /* @ts-ignore */ }
      formJSON[0].fields[0].option = documentOptions;
    }
    setDocumentChips(chipsData);
    clearFormValues();
  }

  function removeChip(idx: number, chip: ChipData) {
    let chipsData = [...documentChips];
    let currentDocumentOptions: any = formJSON[0]?.fields[0]?.option?.slice();
    const removedDocument = documentsOptions.find(
      (item: DropdownOptions) => item?.value === chip?.docType,
    );
    if (removedDocument) {
      currentDocumentOptions?.push(removedDocument);
      formJSON[0].fields[0].option = currentDocumentOptions;
    }
    chipsData.splice(idx, 1);
    setDocumentChips(chipsData);
  }

  function handleCustomSelect(id: string, data: any) {
    if (id === 'document') {
      const newData = { ...formJSON[0] };
      setValue('placeAllowed', null);
      setValue('documentRequired', false);
      if (data?.value) {
        newData.fields[2].fieldVisible = true;
        const document = documentChips.find((item: ChipData) => item?.docType === data?.value);
        if (document) {
          const placeAllowed = placeAllowedOptions.filter(
            (item: DropdownOptions) => item?.value !== document?.allowedPlace,
          );
          { /* @ts-ignore */}
          newData.fields[1].option = placeAllowed;
        } else {
          { /* @ts-ignore */}
          newData.fields[1].option = placeAllowedOptions;
        }
      } else {
        newData.fields[2].fieldVisible = false;
        newData.fields[1].option = [];
      }
      setElements(newData);
    }
  }

  return (
    <>
      {documentChips && documentChips.length > 0 && (
        <ChipsWrapper>
          {documentChips?.map((chip: ChipData, index: number) => (
            <ChipFieldWrapper>
              <Chip label={chip?.label} onPress={() => removeChip(index, chip)} />
            </ChipFieldWrapper>
          ))}
        </ChipsWrapper>
      )}
      {fields && (
        <Container>
          <ElementWrapper>
            {fields.map((field: any) => (
              <>
                <FieldWrapper>
                  {/* @ts-ignore */}
                  <Element
                    key={field.id}
                    field={field}
                    control={control}
                    errors={errors}
                    handleCustomSelect={handleCustomSelect}
                    maxDropdownOptionsHeight={200}
                  />
                </FieldWrapper>
              </>
            ))}
          </ElementWrapper>
          <SecondaryBtn
            onPress={handleSubmit(handleAddDocuments)}
            label={t('add.label')}
            width={60}
          />
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  width: 100%;
  border-style: dashed;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.dividerColor};
  padding: 8px;
  margin-bottom: 24px;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FieldWrapper = styled.div`
  width: 47.5%;
`;

const ChipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
`;

const ChipFieldWrapper = styled.div`
  margin-bottom: 8px;
  margin-right: 8px;
`;
