import { FormBaseType, ViewMode } from 'src/components/molecules/Forms/types';
import { height, width } from 'src/constant/device';

import { ApplicationItemType } from './types';
import CreateInfoItem from 'src/components/molecules/TextComponents/InfoItem';
import HeaderFour from 'src/components/atoms/Text/HeaderFour';
import React, { useEffect, useState } from 'react';
import UserDetails from 'src/components/molecules/UserDetails';
import styled from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import { userInformation } from 'src/utils/manageState';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { ApplicationStatus, ApplicationStatusLabel } from 'src/constant';
import { getApplicationStatusColor } from 'src/components/atoms/FunctionsList';
import { checkRemark, properCase } from 'src/utils/utility';
import { useGetApplicationById } from 'src/graphql/admission/application';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';

interface PropType {
  applicationId: string;
}

export const ApplicationDetailsAdmission = ({ applicationId }: PropType) => {
  const { t } = useI18n();
  const currentUserObject = userInformation();
  const { getApplicationById, applicationByIdData, applicationByIdLoading } =
    useGetApplicationById();

  const [data, setData] = useState<ApplicationItemType | null>(null);
  const { userId, userType }: any = currentUserObject;
  const applId = data?.id;
  const rowData = data?.educationDetails?.map(e => e?.educationDetail) ?? [];
  const itemWidth = 380;

  useEffect(() => {
    if (applicationId) {
      getApplicationById({ variables: { id: applicationId } });
    }
  }, [applicationId]);

  useEffect(() => {
    if (applicationByIdData?.application) {
      setData(applicationByIdData.application);
    }
  }, [applicationByIdData]);

  function getTotalPayableFee(subjectGroups: any, subGrpId?: string) {
    const subjectGroup = subjectGroups?.find((item: any) => item.subjectGroup.id === subGrpId);
    return subjectGroup?.fee?.feeBooks?.reduce((acc, { amount }) => acc + amount, 0) ?? '-';
  }

  return (
    <Container>
      {!applicationByIdLoading ? (
        data ? (
          <>
            <TitleWrapper>
              <HeaderFour value={t('personalDetails.label')} />
            </TitleWrapper>
            <ApplicationDetailsWrapper>
              <CreateInfoItem
                label={t('fullName.label')}
                value={
                  data?.user?.personalDetails?.fullName
                    ? properCase(data.user.personalDetails.fullName)
                    : '-'
                }
                width={itemWidth}
              />
              <CreateInfoItem label={''} value={`\u{200B}`} width={itemWidth} />
              <CreateInfoItem
                label={t('form.fathersName.label')}
                value={data?.user?.parentDetails?.fatherDetail?.name}
                width={itemWidth}
              />
              <CreateInfoItem
                label={t('form.mothersName.label')}
                value={data?.user?.parentDetails?.motherDetail?.name}
                width={itemWidth}
              />
              <CreateInfoItem
                label={t('form.dateOfBirth.label')}
                value={data?.user?.personalDetails?.dob}
                width={itemWidth}
              />
              <CreateInfoItem
                label={t('gender.label')}
                value={data?.user?.personalDetails?.gender}
                width={itemWidth}
              />
              <CreateInfoItem
                label={t('casteCategory.label')}
                value={data?.user?.otherDetails?.category?.name}
                width={itemWidth}
              />
              <CreateInfoItem
                label={t('form.physicallyChallenged.label')}
                value={
                  typeof data?.user?.otherDetails?.isPhysicallyChallenged === 'boolean'
                    ? data?.user?.otherDetails?.isPhysicallyChallenged
                      ? 'Yes'
                      : 'No'
                    : 'N/A'
                }
                width={itemWidth}
              />
              <CreateInfoItem
                label={t('aadhaarNumber.label')}
                value={data?.user?.otherDetails?.aadhaarId}
                width={itemWidth}
              />
              <CreateInfoItem label={''} value={`\u{200B}`} width={itemWidth} />
              <CreateInfoItem
                label={t('applicantType.label')}
                value={data?.applicantType}
                width={itemWidth}
              />
              <CreateInfoItem
                label={t('freeshipApplied.label')}
                value={
                  typeof data?.freeshipApplied === 'boolean'
                    ? data?.freeshipApplied
                      ? 'Yes'
                      : 'No'
                    : '-'
                }
                width={itemWidth}
              />
              <CreateInfoItem
                label={t('feeAmount.label')}
                value={`\u20B9 ${getTotalPayableFee(
                  data?.admission?.subjectGroups,
                  data?.subjectGroup?.id,
                )}`}
                width={itemWidth}
              />
            </ApplicationDetailsWrapper>

            <TitleWrapper>
              <HeaderFour value={t('applicationStatus.label')} />
            </TitleWrapper>
            <ApplicationDetailsWrapper>
              <CreateInfoItem
                label={t('status.label')}
                value={ApplicationStatusLabel[data.status] || data.status}
                color={getApplicationStatusColor(data.status)}
                width={itemWidth}
                description={
                  [ApplicationStatus.REJECTED, ApplicationStatus.BLOCKED].includes(data.status)
                    ? checkRemark(data?.statusActivity, data.status)?.data?.reason
                    : null
                }
              />
              <CreateInfoItem
                label={t('merit.label')}
                value={data?.qualifiedRound?.name}
                width={itemWidth}
              />
            </ApplicationDetailsWrapper>

            <RowGap />

            {Array.isArray(rowData) && rowData.length > 0 && (
              <QualDetailsWrapper>
                <TitleWrapper>
                  <HeaderFour value={t('qualification-details.label')} />
                </TitleWrapper>
                <UserDetails
                  step={0}
                  userId={userId}
                  userType={userType}
                  hideAddAndDeleteBtn={true}
                  reviewMode={false}
                  isOnboarding={false}
                  inModalView={true}
                  userDetails={rowData}
                  applicationRef={applId}
                  viewMode={ViewMode.READ}
                  formBaseType={FormBaseType.ApplicationQualification}
                  viewApplication={true}
                />
              </QualDetailsWrapper>
            )}
          </>
        ) : (
          <SpinnerView>
            <NormaltextAtom value={t('no-records-found.text')} fontSize={1.8} />
          </SpinnerView>
        )
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </Container>
  );
};

const Container = styled.View`
  height: ${0.7 * height - 102};
`;

const QualDetailsWrapper = styled.View`
  padding-bottom: 32px;
`;

const TitleWrapper = styled.View`
  margin-bottom: 16px;
`;

const RowGap = styled.View`
  height: 16px;
`;

const ApplicationDetailsWrapper = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
  column-gap: 8px;
  margin-bottom: 24px;
`;

const SpinnerView = styled.View`
  height: ${0.7 * height};
  display: flex;
  justify-content: center;
  align-items: center;
`;
