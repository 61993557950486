
export const headerCols = [
  {
    // This is to accomodate the dropdown icon caret
    id: 'blank_caret_icon',
    label: '',
    align: 'left',
  },
  {
    id: 'student_name',
    label: 'studentName.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'fullName',
    isSorted: false,
    style: { minWidth: 160 },
  },
  {
    id: 'student_id',
    label: 'studentId.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 160 },
  },
  {
    id: 'roll_no',
    label: 'rollno.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'rollNo',
    isSorted: false,
    style: { minWidth: 72 },
  },
  {
    id: 'onboarding_completion',
    label: 'profileCompletion.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 160 },
  },
  {
    id: 'verif_status',
    label: 'status.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 176 },
  },
  {
    id: 'actions',
    label: 'actions',
    align: 'right',
    disableSorting: true,
    style: { minWidth: 176 },
  },
];
