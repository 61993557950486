import { gql, useLazyQuery } from "@apollo/client";

import { getFeeCompositionsQuery } from "../payment";
import { graphqlQuery } from "../util";

const admissionsListingQuery = gql`
  query (
    $filters: AdmissionConfigFilterInput
    $limit: Int
    $skip: Int
    $sort: AdmissionConfigSortInput
  ) {
    admissions(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      totalCount
      data {
        id
        name
        batch {
          name
        }
        allowedApplicantType
        totalSeats
        lastDate
        status
      }
    }
  }
`;

export function useGetAdmissionsListing() {
  const [getAdmissionsListing, { loading, data, refetch }] = useLazyQuery(
    admissionsListingQuery,
    { fetchPolicy: "network-only" }
  );
  return { getAdmissionsListing, data, loading, refetch };
}

const admissionDetailQuery = gql`
  query ($id: String!) {
    admission(id: $id) {
      id
      name
      university {
        label: name
        value: id
      }
      course {
        label: alias
        value: id
      }
      class {
        label: alias
        value: id
      }
      batch {
        label: name
        value: id
      }
      subjectGroups {
        subjectGroup {
          label: name
          value: id
        }
        seats
        fee {
          label: name
          value: id
        }
      }
      lastDate
      allowedApplicantType
      isLimitedAccess
      totalSeats
      status
      criteria {
        label: name
        value: id
      }
      askAdmissionProcessId
      allowedPaymentModes
      applicationFeeRequired
      applicationFeeDetail {
        account {
          label: name
          value: id
        }
        feeAmount
      }
      inHouseApplicantAllowed
      inHouseInstitutes {
        label: instituteName
        value: instituteCode
      }
      publicAvailability
      reservedSeats
      toDOB
      fromDOB
      requiredDetails
      requiredDocuments {
        allowedPlace
        docType {
          id
          name
        }
        required
      }
    }
  }
`;

export async function getAdmissionDetail(id: string) {
  const resp = await graphqlQuery(admissionDetailQuery, { id }, false);
  if (resp?.data?.admission) {
    return { data: resp.data.admission, loading: resp.loading };
  } else {
    return { data: null, loading: resp?.loading };
  }
}

export function useGetFeeCompositions() {
  const [getFeeCompositions, { data, loading }] = useLazyQuery(
    getFeeCompositionsQuery,
    { fetchPolicy: "network-only" }
  );
  return { getFeeCompositions, data, loading };
}
