import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components';
import React from 'react';
import Check from 'src/components/atoms/CheckBox/index.web';

export const CheckBox = (props: UseFieldApiConfig) => {
  const { handleChange, isChecked, input, label, displayCentered = false, maxHeight = 'none', marginTopOffset = 0, marginBottomOffset = 0 } = useFieldApi(props);

  const formOptions = useFormApi();
  const { values } = formOptions.getState();
  const { onChange } = input;

  return (
    <Wrapper
      displayCentered={displayCentered}
      maxHeight={maxHeight}
      marginTopOffset={marginTopOffset}
      marginBottomOffset={marginBottomOffset}
    >
      <Check
        same={false}
        checked={Boolean(isChecked)}
        header={label}
        onChange={(e) => {
          onChange(e);
          if (handleChange) handleChange(formOptions, e);
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ displayCentered: boolean; maxHeight: number | string; marginTopOffset: number; marginBottomOffset: number }>`
  margin-top: ${({ displayCentered, marginTopOffset }) => marginTopOffset ? marginTopOffset : (displayCentered ? "0px" : "-16px")};
  margin-bottom: ${({ marginBottomOffset }) => marginBottomOffset};
  display: ${({ displayCentered }) => displayCentered && "flex"};
  max-height: ${({ maxHeight }) => maxHeight};
`;
