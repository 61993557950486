import {
  AddEditLectureFormProps,
  EditLectureModes,
  ScheduleDetail,
} from "../scheduleTypes";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import {
  getFilteredSubjectGroups,
  getFilteredSubjectsBySubjectGroup,
  getSubjectTeacherOptions,
} from "src/components/services";

import { getFormattedLectureDate } from "../scheduleHelpers";

export async function fetchSubjectGroups(batchId: string) {
  const response = await getFilteredSubjectGroups({
    filters: { batch: { eq: batchId } },
  });
  return response.options;
}

export async function fetchSubjects(subjectGroupId: string) {
  const response = await getFilteredSubjectsBySubjectGroup({
    id: subjectGroupId,
  });
  return response.options;
}

export async function fetchTeachers({
  batchId,
  classId,
  division,
  subjectId,
}: Record<string, string>) {
  const response = await getSubjectTeacherOptions({
    filters: {
      classDetails: {
        contains: `${batchId}/${classId}/${division}/${subjectId}`,
      },
    },
    limit: 0,
  });
  return response.options;
}

export type LectureFormData = {
  startDate: string;
  endDate: string;
  description?: string;
  day: string[];
  startTime: string;
  endTime: string;
  subjectGroup: SelectedOption;
  subject: SelectedOption;
  teacher: SelectedOption;
};

type SelectedOption = {
  value: string;
  label: string;
};

export const generateLectureFormPayload = (
  mode: AddEditLectureFormProps["mode"],
  details: AddEditLectureFormProps["details"],
  formData: LectureFormData,
  scheduleID?: string
) => {
  // TODO REMOVE THIS CONSOLE
  console.log(formData, mode);
  switch (mode) {
    case "ADD":
      const payloadADD = {
        batch: details.batchId,
        class: details.classId,
        division: details.division,
        validFrom: formData.startDate,
        validTill: formData.endDate,
        daysOfWeek: formData.day,
        startTime: formData.startTime,
        endTime: formData.endTime,
        subjectGroup: formData.subjectGroup.value,
        subject: formData.subject.value,
        lecturer: formData.teacher.value,
        detail: {
          description: formData?.description ?? "",
        },
      };
      return payloadADD;

    case "EDIT_SINGLE":
      const payloadEDIT_SINGLE = {
        date: formData.startDate,
        description: formData?.description ?? "",
        room: "",
        schedule: scheduleID,
      };
      return payloadEDIT_SINGLE;

    case "EDIT_ALL":
      const payloadEDIT_ALL = {
        description: formData?.description ?? "",
        room: "",
        id: scheduleID,
      };
      return payloadEDIT_ALL;

    default:
      return {};
  }
};

const getPrefillOption = (label: string) => ({ label, value: "" });

export const prefillDataEditLecture = (
  mode: EditLectureModes,
  lectureData: ScheduleDetail,
  setValue: UseFormSetValue<FieldValues>
): void => {
  if (mode === "EDIT_SINGLE") {
    setValue("startDate", getFormattedLectureDate(lectureData.start));
    setValue("endDate", getFormattedLectureDate(lectureData.start));
  } else {
    setValue("startDate", getFormattedLectureDate(lectureData.validFrom));
    setValue("endDate", getFormattedLectureDate(lectureData.validTill));
  }
  setValue("description", lectureData?.detail?.description ?? "");
  setValue("day", lectureData.day);
  setValue("startTime", lectureData.startTime);
  setValue("endTime", lectureData.endTime);
  setValue("subject", getPrefillOption(lectureData.subject.alias));
  setValue("teacher", getPrefillOption(lectureData.lecturer.fullName));
};
