
import { ReactNativeFile } from 'apollo-upload-client';

export type CustomReactNativeFileOption = {
  uri: string;
  type: string;
  typeRef?: string;
  name: string;
  size: number;
  extension?: string;
};
export class CustomReactNativeFile extends ReactNativeFile {
  constructor(opt: CustomReactNativeFileOption) {
    super(opt);
    this.size = opt.size;
  }
  id?: string;
  size: number;
  headers?: { authorization?: string };
}

export type CategoryFile = {
  documentType: string;
  name: string;
  type: string;
  document?: CustomReactNativeFile;
}

export type HandleFilesAttachFuncType = (files: CustomReactNativeFile[], names?: String[]) => void;
export type SetFileDataFuncType = (name: String, file: CustomReactNativeFile) => void;
export type RemoveFileFuncType = (file: CustomReactNativeFile) => void;

export type FileUploadProps = {
  header: any;
  fieldId: any;
  name: any;
  file?: CustomReactNativeFile | CategoryFile;
  multiple?: boolean;
  accessImage?: boolean;
  setError?: any;
  clearErrors?: any;
  noCamera?: any;
  handleFilesAttach?: HandleFilesAttachFuncType;
  handleFileRemove?: RemoveFileFuncType;
  style?: any;
  width?: any;
  type?: any;
  inputRef?: any;
  setImage?: any;
  isddf?: boolean;
  compact?: boolean;
  defaultFileName?: string;
  errorMsgText?: string;
  index?: number;
  docFieldRef?: React.RefObject<number>;
  fileTypeAllowed: string;
};

export interface AttachmentType {
  id?: string;
  name?: string;
  uri: string;
  type?: string;
  size?: number;
  fileType?: string;
  category?: string;
  headers?: { authorization?: string };
  document?: CustomReactNativeFile;
}