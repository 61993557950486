/* eslint-disable react-native/no-color-literals */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import formJSON from '../../../../../form-json/control-panels/add-consession.json';
import Element from '../../../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import { commonBorder, parentBorderBottom } from 'src/components/molecules/Table/ExpandTableAtoms';
import { useThemeSystem } from 'src/contexts/theme-context';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { colors } from 'src/styles/theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import Input from 'src/components/atoms/Input/input.web';
import { regexObject } from 'src/constant/regex';
import { getFeeCompositionsQuery } from 'src/graphql/payment';
import { useQuery } from '@apollo/client';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';

const columns = [{ label: 'Module Group / Name' }, { label: 'Amount' }, { label: 'Concession' }];
interface parentFeeBooksInputDataInterface {
  [key: string]: number;
}

interface childFeeBooksInputDataInterface {
  [key: string]: { amount: number; parentid: string; error: boolean };
}

export default function ConcessionForm(props: any) {
  const {
    errors,
    control,
    feeBooks,
    setFeeBooks,
    parentInputData,
    setParentInputData,
    inputData,
    setInputData,
    setStudentsAndBatchObjInfo,
    setTotalConcession,
  } = props;
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const [trow, setT] = useState(false);
  const [current, setCurrent] = useState(null);
  const [searchData, setSearchData] = useState<string>('');
  const [searchedFeeBooks, setSearchedFeeBooks] = useState<any>(null);

  const { data: feeCompositionData } = useQuery(getFeeCompositionsQuery, {
    variables: {
      limit: 0,
    },
  });

  const setInputDatasForSingleAndMultipleFeeBookComponents = (filteredfeeBooks: any | null) => {
    const childFeeBooksInputData: childFeeBooksInputDataInterface =
      {} as childFeeBooksInputDataInterface;
    const parentFeeBooksInputData: parentFeeBooksInputDataInterface =
      {} as parentFeeBooksInputDataInterface;
    const arr = customizeData(filteredfeeBooks);

    if (arr) {
      arr.forEach((element: any) => {
        if (element.groupInfo.length > 0) {
          element.groupInfo.forEach((item: any) => {
            childFeeBooksInputData[item.feebook] = {
              amount: 0,
              parentid: element.groupId,
              error: false,
            };
          });
        }
        parentFeeBooksInputData[element.groupId] = 0;
      });
    }

    setInputData(() => childFeeBooksInputData);
    setParentInputData(() => parentFeeBooksInputData);
    setFeeBooks(() => arr);
    setSearchedFeeBooks(() => arr);
  };

  const setDetails = (payertype: string, filteredData: any, batchinfo?: any) => {
    switch (payertype) {
      case 'BATCH':
        const batchlist: string[] = [];
        filteredData?.[0]?.payers.forEach((element: any) => {
          if (!batchinfo[element.batch]) {
            batchinfo[element.batch] = true;
            batchlist.push(element.batch);
          }
        });
        setStudentsAndBatchObjInfo(() => ({
          students: [],
          batchinfo,
          batchlist,
          payerType: payertype,
        }));
        break;
      case 'INDIVIDUAL':
        setStudentsAndBatchObjInfo(() => ({
          students: filteredData?.[0]?.payers?.map((item: any) => ({
            id: item.user,
            name: item.firstName + ' ' + item.lastName,
          })),
          batchinfo: null,
          batchlist: [],
          payerType: payertype
        }));
        break;
      case 'SUBJECTGROUP':
        setStudentsAndBatchObjInfo(() => ({
          students: [],
          batchinfo: null,
          batchlist: [],
          payerType: payertype,
          subjectGroupsList: filteredData?.[0]?.payerList,
        }));
    }
  }

  const filterFeesComposition = (id: string) => {
    const filteredData = feeCompositionData?.getFeeCompositions?.data?.filter(
      (item: any) => item.id === id,
    );
    const payertype = filteredData?.[0].payerType;
    const batchinfo: { [key: string]: boolean } = {} as { [key: string]: boolean };
    setDetails(payertype, filteredData, batchinfo);
    const filteredfeeBooks = filteredData?.length > 0 ? filteredData[0]?.feeBooks : null;
    setInputDatasForSingleAndMultipleFeeBookComponents(filteredfeeBooks);
  };

  async function handleCustomSelect(id: string, data: any) {
    if (id === 'feecomposition') {
      if (data) {
        filterFeesComposition(data.value);
      } else {
        setFeeBooks(null);
        setSearchedFeeBooks(null);
        setInputData(() => ({} as childFeeBooksInputDataInterface));
        setParentInputData(() => ({} as parentFeeBooksInputDataInterface));
      }
    }
  }

  function customizeData(filteredfeeBooks: any) {
    const arr = [] as any;

    const createObj = (newItem: any) => {
      return {
        groupId: newItem.groupId,
        groupName: newItem.groupName,
        amount: newItem.fee.amount,
        groupInfo: [
          {
            feebook: newItem.id,
            name: newItem.fee.name,
            amount: newItem.fee.amount,
          },
        ],
      };
    };

    const obj: any = {};

    filteredfeeBooks.forEach((feebook: any) => {
      if (!obj[feebook.groupId]) {
        obj[feebook.groupId] = createObj(feebook);
      } else {
        obj[feebook.groupId].amount = obj[feebook.groupId].amount + feebook.fee.amount;
        obj[feebook.groupId].groupInfo.push({
          feebook: feebook.id,
          name: feebook.fee.name,
          amount: feebook.fee.amount,
        });
      }
    });

    for (const element in obj) {
      arr.push(obj[element]);
    }
    return arr;
  }

  const setFeeCompositions = () => {
    if (feeCompositionData?.getFeeCompositions?.data.length > 0) {
      const feesCompositionsList = feeCompositionData?.getFeeCompositions?.data;
      const modifiedFeesCompositionsList = feesCompositionsList.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      formJSON[0].fields[1].option = modifiedFeesCompositionsList;
      setElements({ ...formJSON[0] });
    }
  };

  const searchBoxOnChange = (searchfeebook: string) => {
    if (feeBooks && searchfeebook) {
      const data = feeBooks.filter((feebookinfo: any) =>
        feebookinfo.groupName.toLowerCase().includes(searchfeebook.toLowerCase()),
      );
      if (data.length > 0) {
        setSearchedFeeBooks(() => data);
      } else {
        setSearchedFeeBooks(() => feeBooks);
      }
    } else {
      if (feeBooks) {
        setSearchedFeeBooks(() => feeBooks);
      }
    }
    setSearchData(searchfeebook);
  };

  const handleChildInputValueChange = (value: any, obj: any) => {
    let valuePresent = value ? value : 0;
    const oldValue = inputData[obj.feebook].amount;
    let newValue = parseInt(valuePresent, 10);

    const isErrorForExceededAmount = newValue > obj.amount;

    if (isErrorForExceededAmount) {
      newValue = oldValue;
      valuePresent = oldValue;
    }

    setTotalConcession((prev: number) => {
      return prev - oldValue + newValue;
    });

    setInputData((prev: any) => {
      if (isErrorForExceededAmount) {
        const setAmountExceededError = (feebookid: string) => {
          const timer = setTimeout(() => {
            setInputData((prev: any) => ({
              ...prev,
              [feebookid]: {
                ...prev[feebookid],
                error: false,
              },
            }));
            clearTimeout(timer);
          }, 1400);
        };
        setAmountExceededError(obj.feebook);
      }
      return {
        ...prev,
        [obj.feebook]: {
          ...prev[obj.feebook],
          amount: parseInt(valuePresent, 10),
          error: isErrorForExceededAmount ? true : false,
        },
      };
    });

    const parentId = inputData[obj.feebook].parentid;

    setParentInputData((prev: any) => ({
      ...prev,
      [parentId]: prev[parentId] - oldValue + newValue,
    }));
  };

  const ErrorWraperElement = ({ opacity, error_text = 'some error' }: any) => {
    return (
      <ErrorWrapper style={{ opacity }}>
        <SmallTextAtom value={error_text} color={colors.errorColor} />
      </ErrorWrapper>
    );
  };

  const ChildTdWrapperElement = ({ style = {}, data, children }: any) => {
    return (
      <td
        style={{
          ...handleLastElement(trow, current, data.i, data.index, data.data),
          color: theme?.table?.color,
          paddingTop: 18.5,
          paddingLeft: 8,
          paddingRight: 8,
          paddingBottom: 2.3,
          ...style,
        }}
        className="table-data">
        {children && children}
      </td>
    );
  };

  useEffect(() => {
    setElements(formJSON[0]);
  }, []);

  useEffect(() => {
    if (feeCompositionData) {
      setFeeCompositions();
    }
  }, [feeCompositionData]);

  const handleLastElement = (trow: any, current: any, i: any, index: any, data: any) => {
    if (index === data.length - 1) {
      return { borderBottom: 'none' };
    } else {
      return parentBorderBottom(trow, current, i, index, data);
    }
  };

  const nestedRow = (data: any, trow: boolean, current: number, i: number) => {
    return data?.map((item: any, index: number) => (
      <tr
        style={{
          color: theme?.table?.color,
          paddingBottom: 5,
        }}>
        <ChildTdWrapperElement
          data={{ i, index, data }}
          style={{
            width: '0.5%',
          }}
        />

        <ChildTdWrapperElement
          data={{ i, index, data }}
          style={{
            width: '37.5%',
            paddingLeft: 10,
          }}>
          <div> {item.name}</div>
          <ErrorWraperElement opacity={0} />
        </ChildTdWrapperElement>

        <ChildTdWrapperElement data={{ i, index, data }} style={{ width: '19%' }}>
          <div> {item.amount}</div>
          <ErrorWraperElement opacity={0} />
        </ChildTdWrapperElement>

        <td
          style={{
            ...handleLastElement(trow, current, i, index, data),
            color: theme?.table?.color,
            width: '43%',
            textAlign: 'right',
            paddingTop: 18.5,
            paddingLeft: 8,
            paddingRight: 8,
            paddingBottom: 2.3,
          }}
          className="table-data">
          <Input
            name={item.feebook}
            disabled={false}
            value={inputData[item.feebook].amount}
            maxWidth={150}
            setValue={(e) => handleChildInputValueChange(e.target.value, item)}
            onkeyDown={(e: any) => {
              if (e.key !== 'Backspace') {
                const reg = regexObject['INTGER_ONLY'];
                const result = reg.test(e.key);
                if (!result) {
                  e.preventDefault();
                }
              }
            }}
          />
          <ErrorWraperElement
            opacity={inputData[item.feebook].error ? 1 : 0}
            error_text={inputData[item.feebook].error ? 'Concession can not exceed Amount' : 'xyz'}
          />
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Container>
          <View
            style={{
              height: '400px',
            }}>
            {fields ? (
              fields.map((field: any, i: number) => (
                <View key={i} style={{ marginBottom: field.id === 'feecomposition' ? 24 : 0 }}>
                  <Element
                    key={`assignModule${i}`}
                    field={field}
                    control={control}
                    errors={errors}
                    handleCustomSelect={handleCustomSelect}
                    searchBoxOnChange={searchBoxOnChange}
                    searchData={searchData}
                  />
                </View>
              ))
            ) : (
              <SpinnerView>
                <LoaderSpinner />
              </SpinnerView>
            )}

            {Array.isArray(searchedFeeBooks) && (
              <TableWrapper>
                <table className="tb" style={{ backgroundColor: theme?.table?.backgroundColor }}>
                  <tr
                    style={{
                      backgroundColor: theme?.table?.header?.backgroundColor,
                      top: 0,
                      zIndex: 1,
                      position: 'sticky',
                    }}>
                    <th className="firstCol"></th>
                    {columns.map((e, index) => (
                      <th
                        style={{
                          color: theme?.table?.header?.color,
                          textAlign: index == 3 ? 'right' : 'left',
                          paddingLeft: index == 2 ? 0 : 0,
                        }}>
                        {t(e.label).toUpperCase()}
                      </th>
                    ))}
                  </tr>
                  <tbody>
                    {searchedFeeBooks?.map((feebook: any, i: number) => (
                      <React.Fragment key={feebook.groupId}>
                        <tr
                          style={{
                            backgroundColor: trow && current === i ? '#EAF2FA' : 'transparent',
                            ...commonBorder(trow, current, i),
                            width: '5%',
                            zIndex: -1,
                          }}>
                          {feebook?.groupInfo.length > 0 ? (
                            <td className="collapse" style={{ width: '0.5%' }}>
                              {trow && current === i ? (
                                <FontAwesomeIcon
                                  icon={faCaretUp}
                                  onClick={() => {
                                    setT(false);
                                    setCurrent(null);
                                  }}
                                  color={colors.secondaryText}
                                  style={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCaretDown}
                                  onClick={() => {
                                    setT(true);
                                    setCurrent(i);
                                  }}
                                  color={colors.secondaryText}
                                  style={{ cursor: 'pointer' }}
                                />
                              )}
                            </td>
                          ) : (
                            <td style={{ width: '0.5%' }} />
                          )}
                          <td
                            style={{
                              color: theme?.table?.color,
                              width: '37.5%',
                            }}
                            className="collapse">
                            {feebook?.groupName}
                          </td>
                          <td
                            style={{ color: theme?.table?.color, width: '19%' }}
                            className="collapse">
                            {feebook?.amount}
                          </td>
                          <td
                            style={{
                              color: theme?.table?.color,
                              width: '43%',
                              textAlign: 'right',
                            }}
                            className="collapse">
                            <Input
                              name={feebook?.groupId}
                              disabled={feebook?.groupInfo.length > 0 ? true : false}
                              value={parentInputData[feebook.groupId]}
                              maxWidth={150}
                              setValue={(e: any) => {
                                const valuePresent = e.target.value ? e.target.value : 0;
                                let newValue = parseInt(valuePresent, 10);
                                if (newValue > feebook.amount) {
                                  newValue = parentInputData[feebook.groupId];
                                }
                                setTotalConcession((prev: number) => {
                                  return prev - parentInputData[feebook.groupId] + newValue;
                                });
                                setParentInputData((prev: any) => ({
                                  ...prev,
                                  [feebook.groupId]: newValue,
                                }));
                              }}
                              onkeyDown={(e: any) => {
                                if (e.key !== 'Backspace') {
                                  const reg = regexObject['INTGER_ONLY'];
                                  const result = reg.test(e.key);
                                  if (!result) {
                                    e.preventDefault();
                                  }
                                }
                              }}
                            />
                          </td>
                        </tr>
                        {trow && current === i && feebook?.groupInfo.length > 0 && (
                          <>{nestedRow(feebook?.groupInfo, trow, current, i)}</>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </TableWrapper>
            )}
          </View>
      </Container>
    </>
  );
}

const Container = styled.View`
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
`;

const SpinnerView = styled.View`
  width: 350;
  height: 547;
  justify-content: center;
  align-items: center;
`;

const TableWrapper = styled.View`
  margin-top: 6;
  overflow-y: auto;
`;
const ErrorWrapper = styled.View`
  margin-top: 4;
  text-align: start;
`;