import { limit, SUCCESS } from 'src/constant/index';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { readCacheQuery, writeCacheQuery } from '../util';
import { client } from '../client';
import { createDeleteData, createSearchFilter } from '../../components/services/index';
import { getStreamFilter } from 'src/components/services/filters';
import { IsAny } from 'react-hook-form';

const addStreamMutation = gql`
  mutation ($payload: CreateStreamInput!) {
    createStream(payload: $payload) {
      id
      name
    }
  }
`;

const updateStreamMutation = gql`
  mutation ($payload: UpdateStreamInput!) {
    updateStream(payload: $payload) {
        id
        name
    }
  }
`;

const deleteStreamMutation = gql`
  mutation ($id: String!) {
    deleteStream(id: $id) {
        status
        message
    }
  }
`;

// U can use this also for all limit = 0 skip = 0
const getStreamQuery = gql`
  query ($limit: Int, $skip: Int, $filters:StreamFilterInput) {
    streams(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        id
        name
        defaultUserSelection
      }
    }
  }
`;

const getAllStreamQuery = gql`
  query($limit: Int, $skip: Int, $filters:StreamFilterInput) {
	  streams(limit: $limit, skip: $skip, filters: $filters) {
  		hasNextPage,
    	totalCount,
    	data{
        value : id,
		    label : name
      }
    }
  }
`;

const searchStreamQuery = gql`
  query($filters:StreamFilterInput!) {
    streams(filters: $filters ) {
    	data {
        value : id,
		    label : name
      }
	 }
}
`;


export { getStreamQuery, addStreamMutation, getAllStreamQuery, searchStreamQuery,
  updateStreamMutation, deleteStreamMutation };

export function useGetStream () {
  const [getStream, { loading, data: streamData, error:streamError }] = useLazyQuery(getAllStreamQuery);
  return { getStream, streamData, streamError };
}

export function useSearchStream () {
  const [searchStream, { loading, data: searchStreamData, error:searchBoardUniversiesError }] =
  useLazyQuery(searchStreamQuery);
  return { searchStream, searchStreamData, searchBoardUniversiesError };
}

export function useUpdateStream(queries: any) {
  const [mutate, { error }] = useMutation(queries);
  return { mutate, error };
}

export function useStreamList(fetchPolicy: any = 'cache-first') {
  const [query, { called, error, loading, data, refetch }] = useLazyQuery(getStreamQuery, { fetchPolicy: fetchPolicy });
  return { query, data, loading, error, refetch };
}

export function useDeleteStream(page: number, searchValue:string, rowLimit:number, streamId:string) {
  const [deleteStream, { error : deleteStreamError, data: deleteStreamData }] = 
    useMutation(deleteStreamMutation, {
      update: (cache, { data: { deleteStream } }) => {

        let queryData :any;
        let filters = getStreamFilter(searchValue);
        let streamFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
        try{
            queryData = readCacheQuery(cache, getStreamQuery, streamFilter);
          }catch (e) {
            //console.log(e.messgae);
        }
        let streamObject= queryData?.streams;
        let message = deleteStream.message;
        // let streamId = message.replace("Successfully deleted stream for ", "");
        let dataToUpdate = createDeleteData(streamObject,streamId);

        if(deleteStream.status==SUCCESS)
        { 
          //write
          try {
            writeCacheQuery( cache,
              getStreamQuery,
              streamFilter,
              {
                streams : dataToUpdate
              },
            );
          } catch (e) {
            //console.log('error', e.messgae);
          }
          return null;
        }
      
      }
    });

  return { deleteStream, deleteStreamError, deleteStreamData };
}