import React from 'react';
import { StyleSheet, TouchableOpacity, Image } from 'react-native';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import NormalTextSelect from '../Text/NormalTextSelect';
import NormaltextAtom from '../Text/NormalTextAtom';
import { properCase } from 'src/utils/utility';

interface userinfo {
  userInfo: {
    userId: string;
    profilePicture: string;
    email: string;
    fullName: string;
    token: string;
    refresh: string;
  };
  showOption: boolean;
}

export default function UserCard({ userInfo, showOption = true }: userinfo) {
  const { profilePicture = 'https://dev-api.edba.io/personal/photo/default', email, fullName } = userInfo;
  return (
    <Wrapper>
      <Image style={[styles.imgStype]} source={{ uri: profilePicture }} />
      <NameView style={{ flex: 1 }}>
        <NormalTextSelect value={properCase(fullName || '-')} fontWeight={'600'} color={colors.secondaryText} lines={1} />
        <NormaltextAtom
          value={email}
          fontWeight={'400'}
          color={colors.secondaryText}
          lines={1}
          lineHeight={2}
        />
      </NameView>
      {showOption && (
        <TouchableOpacity
          activeOpacity={1}
          style={{
            justifyContent: 'center',
            paddingHorizontal: 20,
          }}>
          <FontAwesomeIcon icon={faChevronRight} color={colors.secondaryText} size={14} />
        </TouchableOpacity>
      )}
    </Wrapper>
  );
}

const styles = StyleSheet.create({
  imgStype: {
    borderRadius: 40,
    borderWidth: 2,
    overflow: 'hidden',
    borderColor: colors.white,
    height: 50,
    width: 50,
    marginLeft: 2,
    marginRight: 5,
  },
});

const Wrapper = styled.View`
  flex-direction: row;
  margin-bottom: 8px;
  padding-bottom: 10px;
  border-color: ${colors.inputBorder};
  border-bottom-width: 1px;
`;

const NameView = styled.View`
  margin-left: 8px;
  flex-direction: column;
  justify-content: center;
`;
