import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';
import { boxShadow, colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { useHistory } from 'src/routes/routing';
import { useApplicantSignup } from 'src/graphql/signup/applicant';
import { ERROR } from 'src/constant';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import ERROR_MSG from 'src/constant/error';
import RegistrationForm from 'src/components/molecules/Registration/RegistrationForm';
import { useParams } from 'src/routes/routing.web';
import { isWeb } from 'src/constant/device';

export default function ApplicantRegistration() {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm();
  const [canClick, setCanClick] = useState(true);
  const history = useHistory();
  let { setAlertDetails } = useAlertSystem();
  const params: { prefix: string, requestId: string, capId: string } = useParams();
  const { prefix, requestId, capId } = params;

  const { signUpApplicant } = useApplicantSignup();
  let applicantLoginPath = '/applicant/login';
  const registerApplicant = async (registerData: any) => {
    setCanClick(false);
    try {
      registerData.mobileNumber = parseFloat(registerData.mobileNumber);
      if (prefix && requestId) {
        registerData.request = {
          id: requestId,
          prefix
        }
        applicantLoginPath += `/${prefix}/${requestId}`;
      }
      if (capId) {
        registerData.request = {
          id: requestId,
          prefix,
          capId
        }
        applicantLoginPath += `/${capId}`;
      }
      const response = await signUpApplicant({
        variables: { payload: registerData },
      });
      if (response && response.data) {
        const data = response.data;
        if (
          data.applicantSignUp &&
          data.applicantSignUp.status &&
          data.applicantSignUp.status === 'success'
        ) {
          setCanClick(true);
          history.push("/redirection/registration_complete");
        } else {
          throw new Error(ERROR_MSG.REGISTRATION_FAILED);
        }
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  return (
    <Container>
      <PageAreaContainer>
        <View style={styles.loginDiv}>
          <RegistrationForm
            control={control}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            canClick={canClick}
            handleSubmit={handleSubmit}
            registerApplicant={registerApplicant}
            isSubmitting={isSubmitting}
            history={history}
          />
        </View>
      </PageAreaContainer>
    </Container>
  );
}

const styles = StyleSheet.create({
  loginDiv: {
    backgroundColor: colors.white,
    borderRadius: 3,
    paddingTop: 40,
    paddingBottom: 62,
    paddingHorizontal: 85,
    ...boxShadow(),
  },
});

const Container = styled.View`
  flex: 1;
  align-items: center;
  background-color: ${isWeb ? colors.white : "transparent"};
`;

const PageAreaContainer = styled.View`
  justify-content: center;
  flex-grow: 1;
  border-color: ${colors.borderColor};
  width: 520px;
  height: 578px;
  background-color: ${isWeb ? colors.white : "transparent"};
`;
