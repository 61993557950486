import { MessageIcon } from "src/components/atoms/ActionIcons";
import React from "react";

type Props = {
  onPress: () => void;
};

function Messages({ onPress }: Props) {
  return <MessageIcon onPress={onPress} />;
}

export default Messages;
