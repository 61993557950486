import React, { Dispatch, SetStateAction, useState } from 'react'
import styled from 'styled-components';
import ImageUpload from 'src/components/molecules/ImageUpload/index.web';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import { personal } from 'src/form-json/user-details/personal';
import { validatorMapper } from 'src/components/molecules/Forms/validatorMapper';
import { createDocumentObject, ensureDetail, properCase } from 'src/utils/utility';
import { useI18n } from 'src/i18n/hooks';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import { colors, fonts } from 'src/styles/theme/styles';
import { useUpdateUserDetails } from "src/utils/customHooks/useUpdateOnboarding";
import { ERROR, SUCCESS, UserStatus, UserType, USER_TYPE, EMPLOYEE_TYPES } from 'src/constant';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import LabelValueView from 'src/components/molecules/LabelValueView/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { componentMapper } from 'src/components/molecules/ddfElements/index'
import { FormSchema, TemplateType, ViewMode } from '../Forms/types';
import { IUserProfileDetails, useLoadUserDetails } from 'src/utils/customHooks/useLoadUserDetails';
import { useApproveAllChanges } from 'src/graphql/onboarding/profile-verification'
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { UPDATE_SUCCESS } from 'src/constant/message';
import AlertModalNative from 'src/components/molecules/AlertModal/AlertModalNative';
import { useHistory } from 'src/routes/routing.web';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { TouchableOpacity } from 'react-native';
import { Tooltip } from '@mui/material';
import MultiColorProgressBar from 'src/components/atoms/progressiveBar/MultiColorProgressBar';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';


interface InitialFormValuesType {
  viewMode: ViewMode,
  toggleViewMode: () => void,
  userId: string,
  userType: UserType,
  userDetails?: IUserProfileDetails
  reviewMode: boolean
  setSubmittedRecordInTab: Dispatch<SetStateAction<boolean[]>>
  submittedRecordInTab: boolean[];
  allowProfileEdit?: boolean;
}
const ProfileLeftSection: React.FC<InitialFormValuesType> = (props) => {
  const {
    viewMode,
    toggleViewMode,
    userId,
    userType,
    userDetails,
    reviewMode,
    setSubmittedRecordInTab,
    submittedRecordInTab,
    allowProfileEdit,
  } = props;

  const { updateUserDetails } = useUpdateUserDetails();
  const { loadUserDetails } = useLoadUserDetails();
  const { approveAllChanges } = useApproveAllChanges();
  const { t } = useI18n();
  const history = useHistory();
  const { setAlertDetails } = useAlertSystem();
  const [approveAllChangesModal, handleApproveAllChangesModal] = useState<boolean>(false);

  const profileActive = userDetails?.status === UserStatus.ACTIVE;
  const isApplicantType = userType == USER_TYPE.APPLICANT;
  const isInstituteAdmin = userDetails?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN);
  const userDetailForms = personal(ViewMode.READ, false, isInstituteAdmin);

  async function refreshUserDetail() {
    loadUserDetails({ userId, userType });
  }

  async function approveAllChangesFn() {
    try {
      let approveAllChangesData = await approveAllChanges({
        variables: {
          "detail": {
            "userId": userId,
            "userType": UserType[userType]
          }
        }
      })
      if (approveAllChangesData?.data?.approveAllChangeInDetail?.status == SUCCESS) {
        setAlertDetails({ message: UPDATE_SUCCESS.ALL_CHANGES_APPROVED, level: SUCCESS })
        await refreshUserDetail()
        setSubmittedRecordInTab(new Array(5).fill(false));
      } else {
        setAlertDetails({ message: approveAllChangesData?.data?.approveAllChangeInDetail?.message, level: ERROR });
      }
    } catch (error) {
      setAlertDetails({ message: error?.message, level: ERROR });
    }
    handleApproveAllChangesModal(false)
  }

  const ProfilePictureFormTemplate = (templateProps: { schema: FormSchema }) => {
    const { schema } = templateProps;
    const { renderForm } = useFormApi();
    return <ImageBlock>{renderForm([schema?.outerFields[0].fields[0]])}</ImageBlock>
  }

  const ImageContainer = (props: any) => {
    const {
      fields
    } = props
    const { renderForm, schema } = useFormApi();

    const { t } = useI18n();
    return (
      <>
        {fields.map((field: any) => (
          renderForm([field])
        ))}
      </>
    )
  }

  const ImageField = (props: any) => {
    const {
      category,
      documentType,
      input,
      meta: { error }
    } = useFieldApi(props);
    const { getState, change } = useFormApi();
    const formOptions = useFormApi();
    const { values } = getState();
    const { value, name } = input;

    const handleChange = async (files: any, formOptions: any) => {
      const data = files[0];

      const { change } = formOptions;
      const docObj = await createDocumentObject(data, category, documentType)

      const fileObject = { ...docObj }
      fileObject['documentUrl'] = data.uri;

      change(name, fileObject);

      const payload = {
        personalDetails: {
          profilePicture: docObj
        }
      }
      await updateUserDetails(
        { payload },
        TemplateType.DEFAULT,
        userId,
        userType,
      );
    }

    return (
      <ImageWrapper>
        <ImageUpload
          img={value.documentUrl}
          name={name}
          viewOnly={viewMode == ViewMode.READ}
          mode='contain'
          handleFileRemove={() => {
            change(name, null)
            delete values.profilePicture;
          }}
          onChange={(files: any) => {
            handleChange(files, formOptions)
          }}
          infoMessage={'profilePicture.upload.message'}
        />
        <ErrorMsg>{t(error)}</ErrorMsg>
      </ImageWrapper>
    )
  }

  const getAboutTitle = (userType: UserType) => {
    switch (userType) {
      case UserType.INSTITUTE_EMPLOYEE:
        return t('aboutEmployee.label');
      case UserType.APPLICANT:
        return t('aboutApplicant.label');
      case UserType.INSTITUTE_STUDENT:
        return t('aboutStudent.label');
      default:
        return "";
    }
  }

  componentMapper['image'] = ImageField;
  componentMapper['image-container'] = ImageContainer;

  const labelWidth: string = '100%';
  const labelMarginBottom: string = "24px";
  const profileCompletion: number = userDetails?.totalOnboardingCompletion || 0;
  const draftCompletion: number = userDetails?.draftTotalOnboardingCompletion || 0;

  return (
    <>
      <FormRenderer
        componentMapper={componentMapper}
        FormTemplate={ProfilePictureFormTemplate}
        schema={userDetailForms}
        onSubmit={() => { }}
        initialValues={ensureDetail(userDetails?.personalDetails)}
        validatorMapper={validatorMapper}
      />
      <Wrapper>
        {profileCompletion != 100 &&
          <ProgressWrapper>
            <ProfileHeading>
              <MediumTextSelect value={t('profileCompletion.label')} color={colors.primaryText} fontWeight={fonts.semibold} />
            </ProfileHeading>
            <MultiColorProgressBar
              data={[{
                label: 'verifiedDetails.label',
                value: profileCompletion,
                color: colors.primaryColor,
              }, ...(!isApplicantType && !isInstituteAdmin ? [{
                label: 'awaitingVerification.label',
                value: draftCompletion,
                color: colors.yellow,
              }] : [])]}
            />
          </ProgressWrapper>}
        <HeadingRow>
          <TitleView>{getAboutTitle(userType)}</TitleView>
          {
            allowProfileEdit && (
              <Tooltip title={t("editProfile.text")}>
                <TouchableOpacity onPress={toggleViewMode}>
                  <Icon name={"edit-square-icon"} size={1.25} />
                </TouchableOpacity>
              </Tooltip>
            )
          }
        </HeadingRow>

        <DetailsWrapper>
          <LabelValueView
            width={labelWidth}
            marginBottom={labelMarginBottom}
            label={'name.label'}
            value={properCase(userDetails?.personalDetails?.fullName || '-')}
          />
          {!isApplicantType && <LabelValueView
            width={labelWidth}
            marginBottom={labelMarginBottom}
            label={userType === USER_TYPE.EMPLOYEE ?
              'employeeId.label' :
              'studentId.label'}
            value={userType === UserType.INSTITUTE_EMPLOYEE ?
              userDetails?.employeeId :
              userDetails?.accountId}
          />}
          {
            userDetails && userType == UserType.INSTITUTE_EMPLOYEE && (
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'type.label'}
                value={userDetails?.type?.includes('TEACHING') ?
                  'Teaching Staff' :
                  'Non Teaching Staff'}
              />
            )
          }
          {userDetails && userType == UserType.INSTITUTE_STUDENT && (
            <>
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'rollno.label'}
                value={userDetails?.academic?.rollNo}
              />
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'grNo.label'}
                value={userDetails?.academic?.grNo}
              />
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'course.label'}
                value={userDetails?.academic?.class?.course?.name}
              />
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'class.label'}
                value={userDetails?.academic?.class?.name}
              />
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'division.label'}
                value={userDetails?.academic?.division}
              />
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'admissionQuota.label'}
                value={userDetails?.academic?.admissionQuota}
              />
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'dateOfJoining.label'}
                value={userDetails?.doj ? format(userDetails.doj, DT.DATE_FORMAT_SLASH) : '-'}
              />
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'academicSection.label'}
                value={userDetails?.academic?.academicSection}
              />
              <LabelValueView
                width={labelWidth}
                marginBottom={labelMarginBottom}
                label={'house.label'}
                value={userDetails?.academic?.house}
              />
            </>
          )}
        </DetailsWrapper>


        {
          reviewMode && profileActive && submittedRecordInTab?.some((element) => element) && (
            <SecondaryBtn
              onPress={() => handleApproveAllChangesModal(true)}
              label={t("approve-all-changes.label")}
              width={192}
              style={{ marginTop: 8 }}
            />
          )
        }

      </Wrapper>

      <AlertModalNative
        title={t("approve-all-changes.label")}
        message1={t('approve-all-changes.text')}
        onCancel={() => handleApproveAllChangesModal(false)}
        onConfirm={() => approveAllChangesFn()}
        showModal={approveAllChangesModal}
        confirmButtonText={t('save.label')}
        showTitleLogo={false}
        titleTextColor={colors.primaryText}
      />
    </>
  )
}

export default ProfileLeftSection;

const ImageBlock = styled.div`
  width: 100%;
  height: 280px;
`;

const ErrorMsg = styled.div`
  font-family: ${fonts.regular};
  color: ${colors.errorColor};
  margin-top: 5px;
  font-size: 12px;
  min-height: 14px;
`;

const Wrapper = styled.div`
  margin-top: 40px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px 28px;
  box-sizing: border-box;
  min-height: 450px;
  @media all and (max-width: 767px) {
    background: none;
    box-shadow: none;
    border-radius: 0px;
    padding: 25px 0px;
    min-height: auto;
  }
`;

const ProgressWrapper = styled.div`
  padding-bottom: 30px;
`;

const ProfileHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const HeadingRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TitleView = styled.div`
  font-size: 16px;
  font-family: ${fonts.semibold};
  color: ${colors.primaryText};
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const DetailsWrapper = styled.div`
  max-height: 250px;
  overflow-x: auto;
`;