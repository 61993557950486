import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import NormalModal from 'src/components/atoms/Modals/Normal/index';
import { AUDIENCEPAGE, AUDIENCE_SELECTION, AUDIENCE_TYPE, AUDIENCE_TYPE_ARRAY, ERROR } from 'src/constant';
import ClassAudience from './Class';
import GroupAudience from './Group';
import CustomStudentsGroupAudience from './students';
import { StyleSheet, View } from 'react-native';
import Element from '../Forms/ApplicationElement';
import assignAudienceFormJSON from '../../../form-json/communication/assign-audience.json';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import { colors, fonts, fontWeight } from 'src/styles/theme/styles';
import { userInformation } from 'src/utils/manageState';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import Chip from 'src/components/atoms/Chip';
import { isWeb } from 'src/constant/device';
import Cohort from './Cohort';
import ERROR_MSG from 'src/constant/error';
import { validation } from 'src/constant/validation';
import { useI18n } from 'src/i18n/hooks';
import SubjectGroupAudience from './SubjectGroup';
import { properCase } from 'src/utils/utility';

interface AudienceWrapperProps {
  isSubmitting?: boolean;
  onAlert?: object;
  setModalVisible: Function;
  setModal?: Function;
  Headerpopup: string;
  handleSave: Function;
  modalVisible: boolean;
  cancelButtonLabel: string;
  addEditButtonLabel: string;
  height?: number | string;
  style?: object;
  setAudienceType?: Function;
  audienceType?: null | string;
  parentType?: string;
  handleSubmit: Function;
  isEditModal?: boolean;
  editDetails?: any;
  width?: number | string;
  totalConcession?: number;
  batchIds?: Array<string>;
  studentList?: Array<string>;
  subjectGroupList?: Array<string>;
  types?: AUDIENCE_TYPE[];
  children: Element;
  lineHeight?: number;
  maxWidth?: string | number;
  setError?: Function;
  handleNext?: ((formData: any) => void) | null;
}

export default function AudienceWrapper(props: AudienceWrapperProps) {
  const {
    isSubmitting,
    onAlert,
    setModalVisible,
    Headerpopup,
    handleSave,
    modalVisible,
    cancelButtonLabel,
    addEditButtonLabel,
    height,
    style,
    setAudienceType,
    audienceType,
    types = [
      AUDIENCE_TYPE.BATCH,
      AUDIENCE_TYPE.GROUP,
      AUDIENCE_TYPE.STUDENTS,
      AUDIENCE_TYPE.SUBJECTGROUP,
    ],
    parentType = 'DEFAULT',
    handleSubmit,
    isEditModal = false,
    editDetails = [],
    width,
    batchIds = [],
    studentList = [],
    subjectGroupList = [],
    totalConcession,
    setError
  } = props;

  const [audiencePage, setAudiencePage] = useState(0);
  const [assignAudienceModal, setAssignAudienceModal] = useState(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};
  const currentUserObject = userInformation();
  const { userDetail }: any = currentUserObject;
  const instituteId = userDetail?.institute;
  const { setAlertDetails } = useAlertSystem();
  const [parentData, setParentData] = useState({});
  const [audienceData, setAudienceData] = useState<any>([]);
  const [visibility, setVisibility] = useState(true);
  const [modalMessage, setModalMessage] = useState<Object>({});
  const { t } = useI18n();
  const nextClickRef = useRef(false);

  useEffect(() => {
    if (audiencePage !== 0) {
      clearErrors();
    }
  }, [audiencePage]);

  useEffect(() => {
    if (assignAudienceModal) {
      setVisibility(false);
    } else {
      setVisibility(true);
    }
  }, [assignAudienceModal]);

  function loadAudienceType() {
    const option = [];
    for (const type of types) {
      if (AUDIENCE_SELECTION.hasOwnProperty(type)) {
        option.push({
          label: AUDIENCE_SELECTION[type.toString()],
          value: type
        })
      }
    }
    assignAudienceFormJSON[0].fields[0].option = option;
    setElements(assignAudienceFormJSON[0]);
  }

  const {
    handleSubmit: handleAudienceFormSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  const {
    handleSubmit: handleClassFormSubmit,
    control: classFormControl,
    getValues: getClassFormValues,
    setValue: setClassFormValues,
    formState: { errors: classFormError },
    reset: resetClassForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  const {
    handleSubmit: handleStudentFormSubmit,
    control: studentFormControl,
    getValues: getStudentFormValues,
    setValue: setStudentFormValue,
    formState: { errors: studentFormError },
    reset: resetStudentForm,
  } = useForm();

  const {
    handleSubmit: handleSubjectGroupFormSubmit,
    control: subjectGroupFormControl,
    getValues: getSubjectGroupFormValues,
    setValue: setSubjectGrouptFormValue,
    formState: { errors: subjectGroupFormError },
    reset: resetSubjectGroupForm,
  } = useForm();

  const {
    handleSubmit: handleGroupFormSubmit,
    control: groupFormControl,
    getValues: getGroupFormValues,
    setValue: setGroupFormValue,
    formState: { errors: groupFormError },
    reset: resetGroupForm,
  } = useForm();

  const {
    handleSubmit: handleCohortFormSubmit,
    control: cohortFormControl,
    getValues: getCohortFormValues,
    setValue: setCohortFormValue,
    formState: { errors: cohortFormError },
    reset: resetCohortForm,
  } = useForm();

  useEffect(() => {
    if (isEditModal && nextClickRef.current && !['ASSIGNMENT', 'PAYMENT'].includes(parentType)) {
      assignAudienceFormJSON[0].fields[0].disabled = true;
      setValue('audienceType', {
        label: AUDIENCE_SELECTION[editDetails?.payerType],
        value: AUDIENCE_TYPE[editDetails?.payerType],
      });
      setElements(assignAudienceFormJSON[0]);
      setAudienceType(AUDIENCE_TYPE[editDetails?.payerType]);
      handleEditModal(AUDIENCE_TYPE[editDetails?.payerType]);
    }
    return () => {
      assignAudienceFormJSON[0].fields[0].disabled = false;
      setValue('audienceType', null);
    };
  }, [nextClickRef.current, isEditModal]);

  function handleEditModal(audienceType: string) {
    switch (audienceType) {
      case AUDIENCE_TYPE.BATCH:
        setAudiencePage(AUDIENCEPAGE.BATCH);
        let audienceData = [] as any;
        editDetails?.payerDetails?.forEach((item: any) => {
          const data = `${instituteId}/${item?.batch}/${item?.classId}/${item?.division}`;
          const index = audienceData?.findIndex((item: any) => item?.audience === data);
          if (index === -1) {
            audienceData.push({
              audience: data,
              text: `${item?.name} / ${item?.classAlias} : ${item?.division}`
            })
          }
        })
        setAudienceData(audienceData);
        break;
      case AUDIENCE_TYPE.INDIVIDUAL:
        setAudiencePage(AUDIENCEPAGE.STUDENTS);
        const audienceStudents = editDetails?.payerDetails?.map((item: any) => {
          return {
            audience: `${instituteId}/${item?.batch}/${item?.division}/${item?.user}`,
            text: `${item?.name} / ${item?.classAlias} ${item?.division}`,
          }
        })
        setAudienceData(audienceStudents);
        break;
      case AUDIENCE_TYPE.SUBJECTGROUP:
        setAudiencePage(AUDIENCEPAGE.SUBJECTGROUP);
        const audienceSubGrp = editDetails?.payerList?.map((item: any) => {
          return {
            audience: item?.id,
            text: item?.name,
          }
        })
        setAudienceData(audienceSubGrp);
        break;
    }
  }

  function closeModal() {
    setAssignAudienceModal(false);
    setAudiencePage(0);
    setValue('audienceType', null);
    setParentData({});
    nextClickRef.current = false;
    if (audiencePage === AUDIENCEPAGE.BATCH) {
      resetClassForm({});
    }
    
    if (audiencePage === AUDIENCEPAGE.STUDENTS) {
      resetStudentForm({});
    }

    if (audiencePage === AUDIENCEPAGE.GROUP) {
      resetGroupForm({});
    }

    if (audiencePage === AUDIENCEPAGE.COHORT) {
      resetCohortForm({});
    }

    if (audiencePage === AUDIENCEPAGE.SUBJECTGROUP) {
      resetSubjectGroupForm({});
    }

    if (isEditModal || audienceType === AUDIENCE_TYPE_ARRAY[3]) {
      reset({});
    }
  }

  const errorHandlingParent = (formData: any) => {
    if (parentType === 'CONCESSION') {
      if (!totalConcession) {
        setAlertDetails({ message: ERROR_MSG.CONCESSION_AMOUNT_REQUIRED, level: ERROR });
        return true;
      }
    }
    if (parentType === 'NEWS') {
      if (!formData?.contentImage?.uri) {
        setError('contentImage', { message: t(validation.NEWS_IMAGE_REQUIRED) });
        return true;
      }
    }
    return false;
  }

  const handleNext = (parentFormData: any) => {
    setCanClick(false);
    if(!errorHandlingParent(parentFormData)) {
      setParentData(parentFormData);
      setCanClick(true);
      setAssignAudienceModal(true);
      loadAudienceType();
      nextClickRef.current = true;
    }
    setCanClick(true);
  }

  function removeAudience(idx: any) {
    if ([AUDIENCEPAGE.SUBJECTGROUP, AUDIENCEPAGE.BATCH, AUDIENCEPAGE.GROUP].includes(audiencePage) || parentType === 'CONCESSION') {
      let check = [...audienceData];
      check.splice(idx, 1);
      setAudienceData(check);
    } else {
      const proxy = audienceData.filter((item: any) => item.text !== idx);
      setAudienceData(proxy);
    }
  }

  function handleSubmitParent(formData: any, audience: any) {
    if (parentType === 'ASSIGNMENT') {
      const assignmentData = {...parentData};
      assignmentData["subject"] = isWeb ? formData?.subject?.value : formData?.subject;
      const batch = isWeb ? formData?.batch?.value : formData?.batch;
      handleSave(assignmentData, audience, batch);
    } else {
      handleSave(parentData, audience);
    }
  }

  function showError(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: ERROR });
    } else {
      setModalMessage({
        message,
        type: 'danger',
        position: 'top',
        icon: 'danger',
        duration: 400,
      });
    }
  }

  function getBatchAudience(batchData: any) {
    try {
      if (audienceData.length === 0) {
        throw new Error('Division is required');
      }
      let audience = [] as any;
      audienceData.forEach((item: any) => {
        audience.push(item?.audience);
      })
      handleSubmitParent(batchData, audience);
      closeModal();
      setModalVisible(false);
    } catch (e) {
      showError(e.message);
    }
  }

  function getSubjectGroupAudience(subjectGroupData: any) {
    try {
      if (audienceData.length === 0) {
        throw new Error(t(validation.SUBJECT_GROUP_REQUIRED));
      }
      const subjectGroupIds = audienceData.map(item => item.audience)
      handleSubmitParent(subjectGroupData, subjectGroupIds);
      closeModal();
      setModalVisible(false);
    } catch (e) {
      showError(e.message);
    }
  }

  function getStudentAudience(studentData: any) {
    try {
      if (audienceData.length === 0) {
        throw new Error('Student is required');
      }
      let audience = [] as any;
      if (parentType !== 'CONCESSION' || batchIds.length > 0) {
        audienceData.forEach((item: any) => {
          const instituteId = item?.audience.split('/').pop();
          audience.push(instituteId);
        })
      }
      handleSubmitParent(studentData, audience.length > 0 ? audience : audienceData);
      closeModal();
      setModalVisible(false);
    } catch (e) {
      showError(e.message);
    }
  }

  function getGroupAudience(groupData: any) {
    try {
      if (audienceData.length === 0) {
        throw new Error('Division is required');
      }
      let audience = [] as any;
      audienceData.forEach((item: any) => {
        audience.push(item?.audience);
      })
      handleSubmitParent(groupData, audience);
      closeModal();
      setModalVisible(false);
    } catch (e) {
      showError(e.message);
    }
  }

  async function handleCustomSelect(id: string, data: any) {
    let audience = isWeb ? data?.value : data;
    setAudienceType(audience);
    if (audience) {
      changePage(audience);
    } else {
      setAudiencePage(0);
    }

    if (id === 'audienceType') {
      setAudienceData([]);
      if (audience === AUDIENCE_TYPE_ARRAY[1]) {
        resetClassForm();
      } else if (audience === AUDIENCE_TYPE_ARRAY[2]) {
        resetGroupForm();
      } else if (audience === AUDIENCE_TYPE_ARRAY[4]) {
        resetStudentForm();
      } else if (audience === AUDIENCE_TYPE_ARRAY[6]) {
        resetSubjectGroupForm();
      } else {
        resetCohortForm();
      }
    }
  }

  function changePage(type: any) {
    if (type === AUDIENCE_TYPE_ARRAY[1]) {
      setAudiencePage(AUDIENCEPAGE.BATCH);
      maintainSelection(type);
    }
    if (type === AUDIENCE_TYPE_ARRAY[2]) {
      setAudiencePage(AUDIENCEPAGE.GROUP);
      maintainSelection(type);
    }
    if (type === AUDIENCE_TYPE_ARRAY[4]) {
      setAudiencePage(AUDIENCEPAGE.STUDENTS);
      maintainSelection(type);
    }
    if (type === AUDIENCE_TYPE_ARRAY[5]) {
      setAudiencePage(AUDIENCEPAGE.COHORT);
      maintainSelection(type);
    }
    if (type === AUDIENCE_TYPE_ARRAY[3]) {
      setAudiencePage(AUDIENCEPAGE.VIEW);
      maintainSelection(type);
    }
    if (type === AUDIENCE_TYPE_ARRAY[6]) {
      setAudiencePage(AUDIENCEPAGE.SUBJECTGROUP);
      maintainSelection(type);
    }
  }

  function maintainSelection(type: any) {
    setValue('audienceType', type);
  }

  function audienceSubmit(formData: any) {
    if (isEditModal) {
      handleSave(parentData);
      closeModal();
      setParentData({});
      setModalVisible(false);
    } else {
      if (audienceType === AUDIENCE_TYPE_ARRAY[3]) {
        const audience = [instituteId];
        setAudienceData(audience);
        handleSubmitParent(formData, audience);
        closeModal();
        setModalVisible(false);
      }
    }
  }

  function handleChecked(item: string, batch?: any, subGroup?: any, division?: string, cohort?: any) {
    const proxy = [...audienceData];
    let audienceItem = '' as string;
    let text = '' as string;
    switch (audienceType) {
      case 'BATCH':
        audienceItem = `${instituteId}/${batch?.value}/${batch?.class?.id}/${item}`;
        text = `${batch?.label} : ${item}`;
        break;
      case 'GROUP':
        audienceItem = `${instituteId}/${batch?.value}/${item}/${subGroup?.value}`;
        text = `${batch?.label} ${item} : ${subGroup?.label}`;
        break;
      case 'STUDENTS':
        audienceItem = `${instituteId}/${batch?.value}/${division}/${item}`;
        text = `${batch?.label} ${division}`;
        break;
      case 'COHORT':
        audienceItem = `${instituteId}/${batch?.value}/${division}/${item}`;
        text = `${batch?.label} ${division} : ${item}`
      case 'SUBJECTGROUP':
        audienceItem = item;
        text = batch?.label ? `${batch?.label}: ${subGroup.name}` : subGroup.name;
    }
    const index = proxy.findIndex(item => item.audience === audienceItem);
    if (index > -1) {
      proxy.splice(index, 1);
    } else {
      proxy.push({
        audience: audienceItem,
        text,
      });
    }
    setAudienceData(proxy);
  }

  return (
    <React.Fragment>
      <NormalModal
        isSubmitting={!canClick}
        onAlert={onAlert}
        setModalVisible={setModalVisible}
        Headerpopup={Headerpopup}
        modalVisible={modalVisible}
        handleSave={handleSubmit(props?.handleNext ? props.handleNext : handleNext)}
        cancelButtonLabel={cancelButtonLabel}
        addEditButtonLabel={addEditButtonLabel}
        height={height ? height : 480}
        maxWidth={'md'}
        style={style}
        visibility={visibility}>
        {props.children}
        <NormalModal
          isSubmitting={isSubmitting}
          setModalVisible={closeModal}
          modalVisible={assignAudienceModal}
          handleSave={
            audiencePage === 0
              ? handleAudienceFormSubmit(audienceSubmit)
              : audiencePage === AUDIENCEPAGE.BATCH
                ? handleClassFormSubmit(getBatchAudience)
                : audiencePage === AUDIENCEPAGE.STUDENTS
                  ? handleStudentFormSubmit(getStudentAudience)
                  : audiencePage === AUDIENCEPAGE.SUBJECTGROUP
                    ? handleSubjectGroupFormSubmit(getSubjectGroupAudience)
                    : handleGroupFormSubmit(getGroupAudience)
          }
          Headerpopup={Headerpopup}
          cancelButtonLabel={'previous.label'}
          addEditButtonLabel={!isEditModal ? 'add.label' : 'edit.label'}
          height={480}
          lineHeight={32}
          maxWidth={'md'}
          message={modalMessage}>
          {isEditModal && (parentType === 'ASSIGNMENT' || parentType === 'PAYMENT') && (
            <View style={styles.chipWrapper}>
              {isEditModal &&
                editDetails.map((item: any) => {
                  return (
                    <View style={styles.chipView}>
                      <Chip
                        label={
                          parentType === 'ASSIGNMENT'
                            ? item?.label
                              ? item?.label
                              : properCase(item?.fullName || `` || '-')
                            : item?.user
                              ? `${item?.name} / ${item?.classAlias} ${item?.division} : ${item?.firstName} ${item?.lastName}`
                              : item?.name
                        }
                        close={false}
                        borderRadius={isWeb ? 4 : 20}
                        chipFontSize={1.2}
                      />
                    </View>
                  );
                })}
            </View>
          )}

          {fields &&
            (!isEditModal || (isEditModal && !['ASSIGNMENT', 'PAYMENT'].includes(parentType))) &&
            fields.map((field: any, i: number) => (
              <View key={`assignAudiencef${i}`}>
                <Element
                  field={field}
                  control={control}
                  errors={errors}
                  handleCustomSelect={handleCustomSelect}
                />
              </View>
            ))}

          {audiencePage !== 0 && (
            <View style={{ marginBottom: 16 }}>
              <HeaderFive
                value={'Select Target Audience'}
                color={colors.primaryText}
                fontWeight={fontWeight[400]}
                fontFamily={fonts.regular}
              />
            </View>
          )}

          {audiencePage === AUDIENCEPAGE.BATCH ? (
            <ClassAudience
              removeAudience={removeAudience}
              audienceData={audienceData}
              setAudienceData={setAudienceData}
              parentType={parentType}
              control={classFormControl}
              errors={classFormError}
              setValue={setClassFormValues}
              reset={resetClassForm}
              handleChecked={handleChecked}
              batchIds={batchIds}
              isEdit={isEditModal}
            />
          ) : (
            audiencePage === AUDIENCEPAGE.BATCH && <View style={[styles.spinnerView]}></View>
          )}
          {audiencePage === AUDIENCEPAGE.STUDENTS ? (
            <CustomStudentsGroupAudience
              audienceData={audienceData}
              setAudienceData={setAudienceData}
              studentFormControl={studentFormControl}
              studentFormError={studentFormError}
              setStudentFormValue={setStudentFormValue}
              parentType={parentType}
              removeAudience={removeAudience}
              handleChecked={handleChecked}
              studentList={studentList}
              batchIds={batchIds}
              isEdit={isEditModal}
            />
          ) : (
            audiencePage === AUDIENCEPAGE.STUDENTS && <View style={[styles.spinnerView]} />
          )}
          {audiencePage === AUDIENCEPAGE.GROUP ? (
            <GroupAudience
              audienceData={audienceData}
              setAudienceData={setAudienceData}
              groupFormControl={groupFormControl}
              groupFormError={groupFormError}
              setGroupFormValue={setGroupFormValue}
              parentType={parentType}
              removeAudience={removeAudience}
              handleChecked={handleChecked}
            />
          ) : (
            audiencePage === AUDIENCEPAGE.GROUP && <View style={[styles.spinnerView]} />
          )}

          {audiencePage === AUDIENCEPAGE.COHORT ? (
            <Cohort
              cohortFormControl={cohortFormControl}
              cohortFormError={cohortFormError}
              setCohortFormValue={setCohortFormValue}
              handleChecked={handleChecked}
              audienceData={audienceData}
              setAudienceData={setAudienceData}
              removeAudience={removeAudience}
              parentType={parentType}
            />
          ) : (
            audiencePage === AUDIENCEPAGE.COHORT && <View style={[styles.spinnerView]} />
          )}

          {audiencePage === AUDIENCEPAGE.SUBJECTGROUP ? (
            <SubjectGroupAudience
              audienceData={audienceData}
              subjectGroupFormControl={subjectGroupFormControl}
              subjectGroupFormError={subjectGroupFormError}
              removeAudience={removeAudience}
              handleChecked={handleChecked}
              parentType={parentType}
              subjectGroupList={subjectGroupList}
              isEdit={isEditModal}
            />
          ) : (
            audiencePage === AUDIENCEPAGE.SUBJECTGROUP && <View style={[styles.spinnerView]} />
          )}
        </NormalModal>
      </NormalModal>
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
    spinnerView: {
        height: 480,
        justifyContent: 'center',
        alignItems: 'center',
    },
    chipWrapper: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: 16,
    },
    chipView: { marginRight: 8, marginBottom: 8 },
})
