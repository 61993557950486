import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FILTERS, limit } from '../../constant';
import { readCacheQuery, writeCacheQuery } from '../util';
import { createDeleteData, createSearchFilter } from '../../components/services/index';
import { getSubjectFilter } from 'src/components/services/filters';

const addSubjectMutation = gql`
  mutation ($payload: CreateSubjectInput!) {
    createSubject(payload: $payload) {
      id
      name
      code
      alias
      semester
      color
      type
      class {
        id
        alias
      }
      course {
        id
        alias
        university {
          id
          name
          alias
        }
      }
    }
  }
`;

const updateSubjectMutation = gql`
  mutation ($payload: UpdateSubjectInput!) {
    updateSubject(payload: $payload) {
      id
      name
      code
      alias
      semester
      color
      type
      class {
        id
        alias
      }
      course {
        id
        alias
        university {
          id
          name
          alias
        }
      }
    }
  }
`;

const updateInstituteSubjectMutation = gql`
  mutation ($payload: LocalizationAcademicInput!) {
    updateInstituteSubject(payload: $payload) {
      id
      name
      alias
      semester
    }
  }
`;

const deleteSubjectMutation = gql`
  mutation ($id: String!) {
    deleteSubject(id: $id) {
      status
      message
    }
  }
`;

// U can use this also for all limit = 0 skip = 0
const getSubjectQuery = gql`
  query ($limit: Int, $skip: Int, $filters: SubjectFilterInput, $sort: SubjectSortInput, $lookup: LookupFilter) {
    subjects(limit: $limit, skip: $skip, filters: $filters, sort: $sort, lookup: $lookup) {
      hasNextPage
      totalCount
      data {
        id
        name
        code
        alias
        semester
        color
        type
        creditPoints
        defaultCreditPoint
        class {
          id
          alias
        }
        course {
          id
          alias
          university {
            id
            name
            alias
          }
        }
      }
    }
  }
`;

const searchSubjectQuery = gql`
  query($limit: Int, $skip: Int, $filters: SubjectFilterInput) {
    subjects(limit: $limit, skip: $skip, filters: $filters) {
      data {
        id
        name
        creditPoints
        defaultCreditPoint
        code
      }
    }
  }
`;

const getModifiedSubjectsQuery = gql`
  query ($limit: Int, $skip: Int, $filters: SubjectFilterInput) {
    subjects(limit: $limit, skip: $skip, filters: $filters) {
      data {
        value: id
        label: alias
      }
    }
  }
`;

const getModifiedSubjectsNamesQuery = gql`
  query ($limit: Int, $skip: Int, $filters: SubjectFilterInput) {
    subjects(limit: $limit, skip: $skip, filters: $filters) {
      data {
        value: id
        label: name
      }
    }
  }
`;

export {
  getSubjectQuery,
  addSubjectMutation,
  updateSubjectMutation,
  searchSubjectQuery,
  getModifiedSubjectsQuery,
  getModifiedSubjectsNamesQuery
};

export function useMutatesubject(queries: any) {
  const [mutate, { error }] = useMutation(queries);
  return { mutate, error };
}

export function useQuerySubject(queries: any, fetchPolicy: any = 'cache-first') {
  const [query, { called, error, loading, data, refetch }] = useLazyQuery(queries, {
    fetchPolicy: fetchPolicy,
  });
  return { query, data, error, refetch, loading };
}

export function useAddSubject(subjectFilter: any) {
  let ErrorOccur = false;
  const [addSubject, { error: addSubjectError, loading, data: addSubjectData }] = useMutation(
    addSubjectMutation,
    {
      update: (cache, { data: { createSubject } }) => {
        try {
          let readQueryData = readCacheQuery(cache, getSubjectQuery, subjectFilter);
          let existingSubjects = readQueryData.subjects;
          if (!existingSubjects.data) {
            // Throws -- TypeError: Cannot read properties of null (reading 'subjects')
            // if it isn't able to read cache
            // ErrorOccur = true;
            return readQueryData;
          }

          //write
          let mergeArray = [createSubject, ...existingSubjects.data];
          let finaldata = {
            data: mergeArray,
            hasNextPage: readQueryData?.subjects?.hasNextPage,
            totalCount: readQueryData?.subjects?.totalCount + 1,
            __typename: readQueryData?.subjects?.__typename,
          };
          let writeQueryData = writeCacheQuery(cache, getSubjectQuery, subjectFilter, {
            subjects: finaldata,
          });

          if (!writeQueryData.__ref) {
            ErrorOccur = true;
            return writeQueryData;
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
  );
  return { addSubject, addSubjectData, addSubjectError, ErrorOccur };
}

export function useUpdateSubject() {
  const [updateSubject, { error: updateSubjectError, data: updateSubjectData }] =
    useMutation(updateSubjectMutation);
  return { updateSubject, updateSubjectData, updateSubjectError };
}

export function useUpdateInstituteSubject() {
  const [
    updateInstituteSubject,
    { error: updateInstituteSubjectError, data: updateInstituteSubjectData },
  ] = useMutation(updateInstituteSubjectMutation);
  return { updateInstituteSubject, updateInstituteSubjectData, updateInstituteSubjectError };
}

export function useSearchSubject() {
  const [searchSubject, { loading, data: searchSubjectData, error: searchSubjectError }] =
    useLazyQuery(searchSubjectQuery);
  return { searchSubject, searchSubjectData, searchSubjectError };
}

export function useDeleteSubject(
  page: number,
  searchValue: string,
  rowLimit: number,
  subjectId: string,
) {
  const [deleteSubject, { error: deleteSubjectError, data: deleteSubjectData }] = useMutation(
    deleteSubjectMutation,
    {
      update: (cache, { data: { deleteSubject } }) => {
        let queryData;
        let filters = getSubjectFilter(searchValue);
        let subjectFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
        //read
        try {
          queryData = readCacheQuery(cache, getSubjectQuery, subjectFilter);
        } catch (e) {
          //console.log(e.message);
        }

        let subjectObject = queryData?.subjects;
        let message = deleteSubject.message;
        // let subjectId = message.replace("Successfully deleted subject for ", "");
        let dataToUpdate = createDeleteData(subjectObject, subjectId);
        //write
        try {
          writeCacheQuery(cache, getSubjectQuery, subjectFilter, {
            subjects: dataToUpdate,
          });
        } catch (e) {
          //console.log('error', e.message);
        }
      },
    },
  );

  return { deleteSubject, deleteSubjectError, deleteSubjectData };
}
