import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components';
import ImageUpload from 'src/components/molecules/ImageUpload/index.web';
import React, { useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import { createDocumentObject } from 'src/utils/utility';


export const Image = (props: UseFieldApiConfig) => {
  const {
    category,
    documentType,
    input,
    mode,
    infoMessage,
    meta: { error },
  } = useFieldApi(props);
  const { getState, change } = useFormApi();
  const formOptions = useFormApi();
  const { values, submitFailed } = getState();
  const { value, name } = input;
  const { t } = useI18n();
  const [fileErr, setFileErr] = useState<any>();

  const handleChange = async (files: any, formOptions: any) => {
    const data = files[0];
    const { change } = formOptions;
    const docObj = await createDocumentObject(data, category, documentType)

    const fileObject = { ...docObj }
    fileObject['documentUrl'] = data.uri;

    change(name, fileObject);
  }

  const setError = (id: string, error: { message: string }) => {
    if (error.message) {
      setFileErr(error.message);
    }
  }

  return (
    <>
      <ImageUpload
        img={value.documentUrl}
        name={name}
        handleFileRemove={() => {
          change(name, null)
          delete values.profilePicture;
        }}
        onChange={(event: any) => {
          handleChange(event, formOptions)
        }}
        mode={mode}
        infoMessage={infoMessage}
      />
      <ErrorMsg>{submitFailed && error ? t(error) : fileErr}</ErrorMsg>
    </>
  )
}

/*Styled - components*/

const ErrorMsg = styled.div`
  font-family: ${fonts.regular};
  color: ${colors.errorColor};
  margin-top: 5px;
  font-size: 12px;
  min-height: 14px;
`;