import React, { useEffect, useState } from 'react';
import { BottomTabBar, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  Dashboard,
  NewsNotices,
  EmployeeInformation,
  AssignmentRoot,
  TimeTable,
  Users,
  InstituteDashboard,
  StudentBatchSelection,
  AdminStudentList,
  Scanner,
  Admissions
} from './index';
import { Image, StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import { faUserFriends as faUserFriendsRegular } from '@fortawesome/pro-light-svg-icons';
import { useTheme } from 'styled-components';

import navigationStrings from './navigationStrings';
import { allowedModulesState, authToken, userInformation, UserInformation } from 'src/utils/manageState';
import { EMPLOYEE_TYPES, USER_TYPE } from 'src/constant';
import { NativePrivateRoute } from 'src/routes/native-private-route';
import { isIOS } from 'src/constant/device';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { Icon } from 'src/components/atoms/Icon/Icon';

const Tab = createBottomTabNavigator();

function DashboardTabs() {
  const currentUserObject = userInformation();
  const { userType, userDetail } = currentUserObject;
  const { rem } = useTheme();
  //   console.log(currentUserObject, 'currentUserObject', authToken());

  const insAdminIndexStart = 5; // for conditional rendering of icons for ins admin
  const allowedModules = allowedModulesState();

  return (
    <Tab.Navigator
      initialRouteName="Dashboard"
      screenOptions={{
        tabBarStyle: styles.tabContainer,
      }}>
      <Tab.Screen
        name={navigationStrings.NATIVE_TABBAR_DASHBOARD}
        initialParams={{ statusbar: 'dark-content' }}
        options={({ navigation }) => {
          if (userType === USER_TYPE.EMPLOYEE && userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN)) {
            if (
              navigation
                .getState()
                ?.history[1]?.key.includes(navigationStrings.NATIVE_TABBAR_ADMIN) ||
              navigation.getState().index >= insAdminIndexStart
            ) {
              return {
                tabBarButton: () => null,
              };
            }
          }
          return {
            headerShown: false,
            tabBarIcon: ({ focused }) => {
              return (
                <Image
                  source={
                    focused
                      ? require('src/assets/tabbar-icon/filled/teacher/icon/Home-selected.png')
                      : require('src/assets/tabbar-icon/icon/home-tabbar.png')
                  }
                />
              );
            },
            tabBarShowLabel: false,
            tabBarItemStyle: {
              height: isIOS ? '100%' : 62,
            },
          };
        }}>
        {(props) => (
          <NativePrivateRoute
            component={Dashboard}
            authUser={[USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            props={props}
          />
        )}
      </Tab.Screen>
      {userType === 2 &&
        userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) ? (
        <Tab.Screen
          name={navigationStrings.NATIVE_TABBAR_ADMIN}
          component={InstituteDashboard}
          initialParams={{ statusbar: 'dark-content' }}
          options={({ navigation }) => {
            if (userType === USER_TYPE.EMPLOYEE && userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN)) {
              if (
                navigation
                  .getState()
                  ?.history[1]?.key.includes(navigationStrings.NATIVE_TABBAR_ADMIN) ||
                navigation.getState().index >= insAdminIndexStart
              ) {
                return {
                  headerShown: false,
                  tabBarIcon: ({ focused }) => {
                    return (
                      <Icon
                        name={focused ? 'institute-admin-focused' : 'institute-admin'}
                        size={21}
                      />
                    );
                  },
                  tabBarShowLabel: false,
                  tabBarItemStyle: {
                    height: isIOS ? '100%' : 62,
                  },
                };
              }
            }

            return {
              tabBarButton: () => null,
            };
          }}
        />
      ) : null}

      {userType !== USER_TYPE.APPLICANT && (userType !== USER_TYPE.EMPLOYEE || (userType === USER_TYPE.EMPLOYEE && userDetail?.type?.includes(EMPLOYEE_TYPES.TEACHING))) ? (
        <Tab.Screen
          name={navigationStrings.NATIVE_TABBAR_ASSIGNMENTS}
          component={AssignmentRoot}
          initialParams={{ assignmentType: 'pending', statusbar: 'light-content' }} //1-pending 2-submission
          options={({ navigation }) => {
            if (userType === 2 && userDetail?.type?.length > 1) {
              if (
                navigation
                  .getState()
                  ?.history[1]?.key.includes(navigationStrings.NATIVE_TABBAR_ADMIN) ||
                navigation.getState().index >= insAdminIndexStart
              ) {
                return {
                  tabBarButton: () => null,
                };
              }
            }
            return {
              headerShown: false,
              tabBarIcon: ({ focused }) => {
                return (
                  <Image
                    source={
                      focused
                        ? require('src/assets/tabbar-icon/filled/teacher/icon/assignments-selected.png')
                        : require('src/assets/tabbar-icon/icon/assignments-tabbar.png')
                    }
                  />
                );
              },
              tabBarShowLabel: false,
              tabBarItemStyle: {
                height: isIOS ? '100%' : 62,
              },
            };
          }}
        />
      ) : null}

      {userType !== USER_TYPE.APPLICANT && <Tab.Screen
        name={navigationStrings.NATIVE_TABBAR_NEWS_NOTICES}
        component={NewsNotices}
        initialParams={{ communicationType: 'news', newsnoticeId: '', statusbar: 'light-content' }} //1-news 2-notices
        options={({ navigation }) => {
          if (userType === 2 && userDetail?.type?.length > 1) {
            if (
              navigation
                .getState()
                ?.history[1]?.key.includes(navigationStrings.NATIVE_TABBAR_ADMIN) ||
              navigation.getState().index >= insAdminIndexStart
            ) {
              return {
                tabBarButton: () => null,
              };
            }
          } else if (
            userType === 2 &&
            userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) &&
            userDetail?.type?.length === 1
          ) {
            return {
              headerShown: false,
              tabBarButton: () => null,
              tabBarShowLabel: false,
              tabBarItemStyle: {
                height: isIOS ? '100%' : 62,
              },
            };
          }
          return {
            headerShown: false,
            tabBarIcon: ({ focused }) => {
              return (
                <Image
                  source={
                    focused
                      ? require('src/assets/tabbar-icon/filled/teacher/icon/communication-selected.png')
                      : require('src/assets/tabbar-icon/icon/communication-tabbar.png')
                  }
                />
              );
            },
            tabBarShowLabel: false,
            tabBarItemStyle: {
              height: isIOS ? '100%' : 62,
            },
          };
        }}
      />}

      {userType !== USER_TYPE.APPLICANT && (userType !== USER_TYPE.EMPLOYEE || (userType === USER_TYPE.EMPLOYEE && userDetail?.type?.includes(EMPLOYEE_TYPES.TEACHING))) ? (
        <Tab.Screen
          name={navigationStrings.NATIVE_TIMETABLE}
          component={TimeTable}
          initialParams={{ statusbar: 'light-content' }}
          options={({ navigation }) => {
            if (userType === 2 && userDetail?.type?.length > 1) {
              if (
                navigation
                  .getState()
                  ?.history[1]?.key.includes(navigationStrings.NATIVE_TABBAR_ADMIN) ||
                navigation.getState().index >= insAdminIndexStart
              ) {
                return {
                  tabBarButton: () => null,
                };
              }
            }
            return {
              headerShown: false,
              tabBarIcon: ({ focused }) => {
                return (
                  <TimeTableIcon
                    source={
                      focused
                        ? require('src/assets/tabbar-icon/filled/teacher/icon/time-table-selected-2x.png')
                        : require('src/assets/tabbar-icon/icon/time-table-2x.png')
                    }
                  />
                );
              },
              tabBarShowLabel: false,
              tabBarItemStyle: {
                height: isIOS ? '100%' : 62,
              },
            };
          }}
        />
      ) : null}

      {/* Position: 2 Admissions Listing / Invite CAP */}
      {userType === 2 && userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) && allowedModules.includes('Admissions') ? (
        <Tab.Screen
          name={navigationStrings.NATIVE_ADMISSIONS_LIST}
          component={Admissions}
          initialParams={{ statusbar: 'light-content' }}
          options={({ navigation }) => {
            if (userType === 2 && userDetail?.type?.length > 1) {
              if (
                navigation
                  .getState()
                  ?.history[1]?.key.includes(navigationStrings.NATIVE_TABBAR_ADMIN) ||
                navigation.getState().index >= insAdminIndexStart
              ) {
                return {
                  headerShown: false,
                  tabBarTestID: 'admissionsListing',
                  tabBarIcon: ({ focused }) => {
                    return (
                      <Icon
                        name={'admission'}
                        size={26}
                        color={focused ? colors.primaryColor : colors.secondaryText} />
                    );
                  },
                  tabBarShowLabel: false,
                  tabBarItemStyle: {
                    height: isIOS ? '100%' : 62,
                  },
                };
              } else {
                return {
                  // Headers and tabbar labels should always be hidden
                  headerShown: false,
                  tabBarShowLabel: false,
                  tabBarButton: () => null,
                  tabBarTestID: 'admissionsListing'
                };
              }
            }
            return {
              headerShown: false,
              tabBarTestID: 'admissionsListing',
              tabBarIcon: ({ focused }) => {
                return (
                  <Icon
                    name={'admission'}
                    size={26}
                    color={focused ? colors.primaryColor : colors.secondaryText} />
                )
              },
              tabBarShowLabel: false,
              tabBarItemStyle: {
                height: isIOS ? '100%' : 62,
              },
            };
          }}
        />
      ) : null}

      {/* Position: 3 QR Scan */}
      {userType === 2 && userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) && allowedModules.includes('Payments') ? (
        <Tab.Screen
          name={navigationStrings.SCANNER}
          component={Scanner}
          initialParams={{ statusbar: 'light-content' }}
          options={({ navigation }) => {
            if (userType === 2 && userDetail?.type?.length > 1) {
              if (
                navigation
                  .getState()
                  ?.history[1]?.key.includes(navigationStrings.NATIVE_TABBAR_ADMIN) ||
                navigation.getState().index >= insAdminIndexStart
              ) {
                return {
                  headerShown: false,
                  tabBarTestID: 'paymentScanQR',
                  tabBarIcon: ({ focused }) => {
                    return (
                      <Icon name={focused ? 'payment-scan-focused' : 'payment-scan'} size={20} />
                    );
                  },
                  tabBarShowLabel: false,
                  tabBarItemStyle: {
                    height: isIOS ? '100%' : 62,
                  },
                };
              } else {
                return {
                  // Headers and tabbar labels should always be hidden
                  headerShown: false,
                  tabBarShowLabel: false,
                  tabBarButton: () => null,
                  tabBarTestID: 'paymentScanQR'
                };
              }
            }
            return {
              headerShown: false,
              tabBarTestID: 'paymentScanQR',
              tabBarIcon: ({ focused }) => {
                return <Icon name={focused ? 'payment-scan-focused' : 'payment-scan'} size={20} />;
              },
              tabBarShowLabel: false,
              tabBarItemStyle: {
                height: isIOS ? '100%' : 62,
              },
            };
          }}
        />
      ) : null}

      {/* Position: 4 Students Listing */}
      {userType === 2 && userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) ? (
        <Tab.Screen
          name={navigationStrings.NATIVE_TABBAR_STUDENTS}
          component={StudentBatchSelection}
          initialParams={{ statusbar: 'light-content' }}
          options={({ navigation }) => {
            if (userType === 2 && userDetail?.type?.length > 1) {
              if (
                (navigation.getState()?.history[1]?.key.includes(navigationStrings.NATIVE_TABBAR_ADMIN) ||
                  navigation.getState().index >= insAdminIndexStart)
              ) {
                // Only if user is institute admin. This icon will not be rendered if we are on STUDENT_LIST
                // Instead another similar icon will act as STUDENT_LIST in its position
                return {
                  headerShown: false,
                  tabBarIcon: ({ focused }) => {
                    return <Icon name={focused ? 'profile-focused' : 'profile'} size={20} />;
                  },
                  tabBarShowLabel: false,
                  tabBarItemStyle: {
                    height: isIOS ? '100%' : 62,
                  },
                };
              } else {
                return {
                  tabBarButton: () => null,
                };
              }
            }
            return {
              headerShown: false,
              tabBarIcon: ({ focused }) => {
                return <Icon name={focused ? 'profile-focused' : 'profile'} size={20} />;
              },
              tabBarShowLabel: false,
              tabBarItemStyle: {
                height: isIOS ? '100%' : 62,
              },
            };
          }}
        />
      ) : null}
    </Tab.Navigator>
  );
}

export default DashboardTabs;

const styles = StyleSheet.create({
  tabContainer: {
    backgroundColor: colors.white,
    borderColor: colors.lighterBorder,
    height: isIOS ? '8.86%' : '8.86%',
  },
});

const TimeTableIcon = styled.Image`
  height: 24px;
  width: 21px;
`;
