import React, { useEffect, useState } from 'react';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { useForm } from 'react-hook-form';
import { useI18n } from 'src/i18n/hooks';
import { AUDIENCE_TYPE, AUTOCOMPLETE_MODULE, ERROR, SUCCESS  } from 'src/constant';
import ConcessionForm from 'src/components/molecules/Payment/Institute/ControlPanel/ConcessionForm';
import { TableDiv } from 'src/components/molecules/Table/TableAtom';
import { height } from 'src/constant/device';
import ConcessionsTable from 'src/components/molecules/Table/ConcessionsTable';
import { StyleSheet, View, Text } from 'react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components/native';
import Search from 'src/components/atoms/SearchBar/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useMutation } from '@apollo/client';
import {
  createConcessionQuery,
  getConcessionCompositionsQuery,
  useQueryFetchConcessions,
} from 'src/graphql/payment';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ADD_SUCCESS } from 'src/constant/message';
import { useHeaderTitle } from 'src/contexts/header-context';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { getCommonNameSearchFilter } from 'src/components/services/filters';
import { createSearchFilter } from 'src/components/services';
import useSearchFilter from 'src/utils/customHooks/useSearchFilter';
import { fonts } from 'src/styles/theme/styles';
import AudienceWrapper from 'src/components/molecules/Audience/AudienceWrapper';
import { useParams } from 'src/routes/routing.web';

interface parentFeeBooksInputDataInterface {
  [key: string]: number;
}

interface childFeeBooksInputDataInterface {
  [key: string]: { amount: number; parentid: string; error: boolean };
}
interface studentsAndBatchObjInfoInterface {
  students: string[];
  batchinfo: { [key: string]: boolean } | null;
  batchlist: string[] | [];
  payerType: string;
  subjectGroupsList: string[];
}

export default () => {
  const defaultSorting = { "updatedAt": "DESCENDING" }
  const [editModal, setEditModal] = useState(false);
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();

  const { page = 1, dataLimit = limit }: any = useParams();

  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  const [createModuleModal, handleCreateModuleModal] = useState(false);
  const [assignModuleModal, handleAssignModuleModal] = useState(false);
  const [payerType, setPayerType] = useState<string>('');

  const [feeBooks, setFeeBooks] = useState<any | null>(null);
  const [inputData, setInputData] = useState<childFeeBooksInputDataInterface>(
    {} as childFeeBooksInputDataInterface,
  );
  const [parentInputData, setParentInputData] = useState<parentFeeBooksInputDataInterface>(
    {} as parentFeeBooksInputDataInterface,
  );

  const [totalConcession, setTotalConcession] = useState(0);

  const [studentsAndBatchObjInfo, setStudentsAndBatchObjInfo] =
    useState<studentsAndBatchObjInfoInterface | null>(null);
  const [audienceType, setAudienceType] = useState(null);
  const [audienceTypes, setAudienceTypes] = useState<AUDIENCE_TYPE[]>([]);

  const { setHeading } = useHeaderTitle();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
    setValue,
    getValues,
    clearErrors,
  } = useForm();

  const { query: getConcessions, data: concessionsCompositionData, loading } = useQueryFetchConcessions();

  const [createConcessionMutateFunction] = useMutation(createConcessionQuery);
  const { searchValue, handleSearch }: any = useSearchFilter(
    false,
    handleFetch,
    AUTOCOMPLETE_MODULE.CONCESSION,
  );

  const [totalCount, setTotalCount] = useState(concessionsCompositionData?.getConcessionCompositions?.totalCount ?? null);

  useEffect(() => {
    const count = concessionsCompositionData?.getConcessionCompositions?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [concessionsCompositionData?.getConcessionCompositions?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);
  
  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  async function setTitle() {
    setHeading([
      {
        text: t('payment.label'),
        url: '/control-panel/payments',
      },
      {
        text: t('concession.label'),
        url: '',
      },
    ]);
  }

  const closeModal = () => {
    handleCreateModuleModal(false);
    setEditModal(false);
    resetAllField();
    resetForm();
  };

  function resetForm() {
    reset({});
    clearErrors();
  }

  function resetAllField() {
    resetForm();
    handleAssignModuleModal(false);
  }

  async function handleAssignModule(formData: any, audience: any) {
    clearErrors('feecomposition');
    const createConcessionsList = () => {
      const arr: { amount: number; feeBook: string }[] = [];

      for (const key in inputData) {
        if (inputData.hasOwnProperty(key)) {
          const element = inputData[key];
          arr.push({ amount: element.amount, feeBook: key });
        }
      }
      return arr;
    };

    const payload = {
      concessions: createConcessionsList(),
      payerType: audienceType === 'STUDENTS' ? 'INDIVIDUAL' : audienceType,
      payers: audience,
      description: formData.concession_name,
      name: formData.concession_name,
    };

    try {
      const concessionInfo = await createConcessionMutateFunction({
        variables: {
          payload,
        },
        refetchQueries: [
          {
            query: getConcessionCompositionsQuery,
            variables: {
              limit: rowLimit,
              skip: (currentPage - 1) * rowLimit,
              sort: defaultSorting
            },
          },
        ],
      });
      if (concessionInfo) {
        setAlertDetails({ message: ADD_SUCCESS.CONCESSION_SUCCESS, level: SUCCESS });
      }
    } catch (err: any) {
      setAlertDetails({ message: err?.message, level: ERROR });
    }
    closeModal();
    setTimeout(() => {
      setAlertDetails({ message: '', level: '' });
    }, 2500);
  }

  function handleFetch() {
    const filters = getCommonNameSearchFilter(searchValue);
    const concessionFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters, defaultSorting);
    if (concessionFilter['filters']) {
      concessionFilter['filter'] = concessionFilter['filters'];
      delete concessionFilter['filters'];
    }
    getConcessions({ variables: concessionFilter });
  }

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    if (studentsAndBatchObjInfo && studentsAndBatchObjInfo?.payerType) {
      setPayerType(studentsAndBatchObjInfo?.payerType);
    }
  }, [studentsAndBatchObjInfo]);

  useEffect(() => {
    if (payerType == 'BATCH') {
      setAudienceTypes([
        AUDIENCE_TYPE.BATCH,
        AUDIENCE_TYPE.STUDENTS,
      ]);
    }
    if (payerType === 'INDIVIDUAL') {
      setAudienceTypes([
        AUDIENCE_TYPE.STUDENTS,
      ]);
    }
    if (payerType === 'SUBJECTGROUP') {
      setAudienceTypes([
        AUDIENCE_TYPE.SUBJECTGROUP,
      ]);
    }
  }, [payerType]);

  return (
    <>
      <LoadContentWrapper>
        <View style={styles.flexRow}>
          <View style={styles.leftPart}>
            <Search
              id="concessionSearch"
              value={searchValue || ''}
              handleChange={handleSearch}
              label={t('search.label') + ' ' + t('concession.label')}
            />
          </View>
          <View style={styles.rightPart}>
            <SecondaryBtn
              label={t('add.label') + ' ' + t('concession.label')}
              onPress={() => {
                setEditModal(prevState => !prevState);
              }}
            />
          </View>
        </View>
        <View>
          {loading ? (
            <LoadSpinnerWrapper>
              <LoaderSpinner />
            </LoadSpinnerWrapper>
          ) : (
            <View>
              {concessionsCompositionData &&
              concessionsCompositionData?.getConcessionCompositions?.data?.length > 0 ? (
                <>
                  <TableDiv>
                    <div style={{ height: height - 310, overflowX: 'auto' }}>
                      <ConcessionsTable
                        data={concessionsCompositionData?.getConcessionCompositions?.data}
                        page={currentPage}
                        limit={rowLimit}
                      />
                    </div>
                  </TableDiv>
                  <PaginationWrapper>
                    <Pagination
                      pathName={'payments/concession'}
                      total={totalCount}
                      page={currentPage}
                      setCurrentPage={setCurrentPage}
                      rowLimit={rowLimit}
                      setRowLimit={setRowLimit}
                      hidePagination={totalCount <= rowLimit}
                    />
                  </PaginationWrapper>
                </>
              ) : (
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: height - 500,
                  }}>
                  <Text style={{ fontFamily: fonts.regular }}>No records found</Text>
                </View>
              )}
            </View>
          )}
        </View>
      </LoadContentWrapper>

      <AudienceWrapper
        setModalVisible={closeModal}
        modalVisible={editModal}
        handleSave={handleAssignModule}
        handleSubmit={handleSubmit}
        Headerpopup={t('add.label') + ' ' + t('concession.label')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="next.label"
        lineHeight={32}
        width={840}
        height={570}
        maxWidth={'lg'}
        parentType="CONCESSION"
        types={audienceTypes}
        audienceType={audienceType}
        setAudienceType={setAudienceType}
        batchIds={studentsAndBatchObjInfo?.batchlist}
        studentList={studentsAndBatchObjInfo?.students}
        subjectGroupList={studentsAndBatchObjInfo?.subjectGroupsList}
        totalConcession={totalConcession}>
        <ConcessionForm
          control={control}
          errors={errors}
          setFeeBooks={setFeeBooks}
          feeBooks={feeBooks}
          parentInputData={parentInputData}
          setParentInputData={setParentInputData}
          inputData={inputData}
          setInputData={setInputData}
          setStudentsAndBatchObjInfo={setStudentsAndBatchObjInfo}
          setTotalConcession={setTotalConcession}
        />
      </AudienceWrapper>
    </>
  );
};

const LoadSpinnerWrapper = styled.View`
  justify-content: center;
  align-items: center;
  height: 550px;
`;

const PaginationWrapper = styled.View`
  margin-top: 16px;
`;

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  rightPart: {
    flex: 3,
    alignItems: 'flex-end',
  },
});
