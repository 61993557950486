import { useEffect, useState } from 'react';
import { useFilter } from '../../contexts/filter-context';
import { debounce } from 'lodash';
import { limit } from '../../constant';
import { useParams } from 'react-router-dom';

const useSearchFilter = (
  getPageFromStorage = false,
  handleFetch: any,
  autocomplete_module_name: string,
  dependencyArray: any = [],
) => {
  const { filters, setFilter } = useFilter();
  const [searchValue, setSearchValue] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowLimit, setRowLimit] = useState(50);

  const { page: currentpage = 1, dataLimit = 50 }: any = useParams();
  // console.log(useParams(), 'useparams');
  function persistSearch(state: boolean, search: string) {
    const persistFilter = {
      [autocomplete_module_name]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function debounceCallBackFunction(newSearchValue: string) {
    const isClear = newSearchValue?.length === 0;
    persistSearch(!isClear, newSearchValue);
  }

  const handleSearch = debounce(
    (newSearchValue: string) => debounceCallBackFunction(newSearchValue),
    200,
  );

  useEffect(() => {
    if (filters && filters[autocomplete_module_name]?.on) {
      setSearchValue(() => filters[autocomplete_module_name]?.search);
    } else if (filters && filters[autocomplete_module_name]?.on === false) {
      setSearchValue(() => '');
    }
  }, [filters]);

  useEffect(() => {
    handleFetch();
  }, [searchValue, page, ...dependencyArray]);

  useEffect(() => {
    (() => {
      if (getPageFromStorage) {
        setRowLimit(limit);
        const pageNumber = localStorage.getItem('page');
        pageNumber && setPage(parseInt(pageNumber));
      } else {
        setPage(() => currentpage);
        console.log(dataLimit, 'dataLimit In');
        setRowLimit(parseInt(dataLimit));
      }
    })();
  }, []);

  return {
    searchValue,
    handleSearch,
    page,
    rowLimit,
    setPage,
  };
};

export default useSearchFilter;
