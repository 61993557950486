/* eslint-disable react-native/no-inline-styles */
import React, { Fragment, useEffect, useState } from 'react';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { useI18n } from 'src/i18n/hooks';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import { height, isNative, isWeb } from 'src/constant/device';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import SwipeSwitch from 'src/components/atoms/Switch/Swipe';
import { View } from 'react-native';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { useHeaderTitle } from 'src/contexts/header-context';
import UnPaidPaymentList from 'src/components/molecules/Payment/Student/UnPaidPaymentList';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'src/routes/routing';
import navigationStrings from 'src/navigation/navigationStrings';
import PaidPaymentList from 'src/components/molecules/Payment/Student/PaidPaymentList';
import usePayment from '../CustomHookForPaymentGateWay';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner';
import { usePaymentDataForStudent } from '../HooksAndConstants';
import { UserType } from 'src/constant';

const staticParam = {
  params: {
    payment_status: '',
  },
};

export default (props: any) => {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const history = isWeb && useHistory();
  const location = isWeb && useLocation();
  const match = isWeb && useRouteMatch();

  const { params } = isNative ? props?.route : staticParam;
  const { payment_status } = params;
  const isPaymentPending = isWeb
    ? location && location.pathname.includes('pending')
      ? true
      : false
    : payment_status === 'pending'
    ? true
    : false;

  const {
    refetch,
    paymentList,
    setPaymentList,
    userinfo,
    totalPending,
    totalPaid,
    totalPayable,
    partialPayment,
    payableAmount,
    paymentFailed,
    loading,
    resetPayment,
  }: any = usePaymentDataForStudent(isPaymentPending, null, true);

  const [modalMessage, setModalMessage] = useState<Object>({});

  const { onPay, displayRazorpay } = usePayment(
    PaymentComplete,
    userinfo,
    setPaymentList,
    PaymentFailed,
  );

  function changeTabs(tab: any) {
    if ((isPaymentPending && tab !== 1) || (!isPaymentPending && tab !== 2)) {
      setPaymentList(null);
    }
    if (tab === 1) {
      resetPayment();
    }
    if (isWeb && match) {
      if (tab === 1) {
        history.push(match.url.replace('paid', 'pending'));
      } else {
        history.push(match.url.replace('pending', 'paid'));
      }
    } else {
      const passParam = tab === 1 ? 'pending' : 'paid';
      props.navigation.navigate(navigationStrings.PAYMENT, {
        payment_status: passParam,
      });
    }
  }

  function setTitle() {
    setHeading([
      {
        text: t('payment.label'),
        url: '',
      },
    ]);
  }

  function PaymentComplete(payment_status: boolean) {
    refetch();
  }

  function PaymentFailed() {
    paymentFailed();
  }

  const MakePayment = (callback: Function) => {
    let title = '';
    const fees: any = [];
    if (paymentList) {
      paymentList.forEach((item: any) => {
        if (item.checked) {
          fees.push(item.id);
          title += item.groupName + ' ';
        }
      });

      const feesObj = {
        title: title + '- Payment',
        fees,
      };
      if (isWeb) {
        displayRazorpay(UserType.INSTITUTE_STUDENT, feesObj);
      } else {
        onPay(feesObj, UserType.INSTITUTE_STUDENT, setModalMessage, callback);
      }
    }
  };

  useEffect(() => {
    if (isWeb) {
      setTitle();
    }
  }, []);
  return (
    <Fragment>
      {isWeb ? (
        <LoadContentWrapper>
          <ModalTabs
            onSelectSwitch={(t) => {
              changeTabs(t);
            }}
            option1={t('pending.label')}
            option2={t('paid.label')}
            selectionMode={isPaymentPending ? 1 : 2}
            roundCorner={false}
            style={{ top: 0, left: 0, backgroundColor: colors.white, marginBottom: 18 }}
            onChange={changeTabs}
            tabswidth={100}
          />
          {loading ? (
            <LoadSpinnerWrapper>
              <LoaderSpinner />
            </LoadSpinnerWrapper>
          ) : (
            <View style={{ maxWidth: paymentList && paymentList.length > 0 ? 708 : '100%' }}>
              {isPaymentPending ? (
                <UnPaidPaymentList
                  paymentList={paymentList}
                  totalPending={totalPending}
                  totalPaid={totalPaid}
                  totalPayable={totalPayable}
                  partialPayment={partialPayment}
                  payableAmount={payableAmount}
                  MakePayment={MakePayment}
                  userInfo={userinfo}
                />
              ) : (
                <PaidPaymentList paidList={paymentList} />
              )}
            </View>
          )}
        </LoadContentWrapper>
      ) : (
        <Fragment>
          <MobileHeader label={t('payment.label')} backIcon={false} {...props} />
          <NativeWrapper>
            <SwitchView>
              <SwipeSwitch
                onSelectSwitch={(t) => {
                  changeTabs(t);
                }}
                option1={t('pending.label')}
                option2={t('paid.label')}
                selectionMode={isPaymentPending ? 1 : 2}
                onChange={() => {
                  //
                }}
              />
            </SwitchView>

            {loading ? (
              <LoadSpinnerWrapper>
                <LoaderSpinner />
              </LoadSpinnerWrapper>
            ) : isPaymentPending ? (
              <UnPaidPaymentList
                paymentList={paymentList}
                totalPending={totalPending}
                totalPaid={totalPaid}
                totalPayable={totalPayable}
                partialPayment={partialPayment}
                payableAmount={payableAmount}
                MakePayment={MakePayment}
                refetch={refetch}
                userInfo={userinfo}
                modalMessage={modalMessage}
                setModalMessage={setModalMessage}
                navigation={props?.navigation}
              />
            ) : (
              <PaidPaymentList paidList={paymentList} navigation={props.navigation} refetch={refetch} />
            )}
          </NativeWrapper>
        </Fragment>
      )}
    </Fragment>
  );
};

const NativeWrapper = styled.View`
  background-color: ${colors.white};
  padding: 24px;
  flex: 1;
`;
const SwitchView = styled.View`
  margin-bottom: 24px;
`;

const LoadSpinnerWrapper = styled.View`
justify-content: center;
align-items: center;
height: ${isWeb ? 450 : height - 300}px;
`;
