import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getExamMarksQuery = gql`
  query(
    $division: String
    $exam: String!
    $limit: Int
    $skip: Int
    $sort: ExamMarkSortInput
    $subjectId: String
    $userIds: [String!]
  ) {
    examMarks(
      division: $division
      exam: $exam
      limit: $limit
      skip: $skip
      sort: $sort
      subjectId: $subjectId
      userIds: $userIds
    ) {
      data {
        average
        batch {
          id
          name
        }
        exam
        grade {
          name
        }
        marks {
          marksObtained
          test {
            id
            name
            passingMarks
            totalMarks
          }
          grace
        }
        creditGrades
        creditsEarned
        subjectWiseMarks{
          average
        }
        user {
          id
          firstName
          lastName
          userName
          serialNo
          academic {
            rollNo
            grNo
          }
          personalDetails {
            fullName
          }
        }
      }
      totalCount
    }
  }
`;

export function useGetExamMarks() {
  const [getExamMarks, { loading: loadingExamMarks, data: dataExamMarks, error: errorExamMarks, refetch: refetchExamMarks }] =
    useLazyQuery(getExamMarksQuery);
  return { getExamMarks, loadingExamMarks, dataExamMarks, errorExamMarks, refetchExamMarks };
}

export const createExamMarksMutation = gql`
  mutation($payload: ClassTestMarkForExamInput!) {
    createClassTestMark(payload: $payload) {
      id
      grade
      id
      marks: marksObtained
      test {
        id
        name
        passingMarks
        totalMarks
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export function useCreateExamMarks() {
  const [createExamMarks, { data: createExamMarksData, error: createExamMarksError }] = useMutation(createExamMarksMutation);
  return { createExamMarks, createExamMarksData, createExamMarksError };
}

export const importMarksMutation = gql`
  mutation($file: Upload!, $batch: String!, $division: String!, $exam: String!, $subject: String!) {
    importMarks(
      file: $file,
      batch: $batch,
      division: $division,
      exam: $exam,
      subject: $subject,
    ){
      status
      message
    }
  }
`

export function useImportExamMarks() {
  const [importExamMarks, { data: importExamMarksData, error: importExamMarksError }] = useMutation(importMarksMutation);
  return { importExamMarks, importExamMarksData, importExamMarksError };
}

export const getExamSubjectMarksQuery = gql`
  query(
    $division: String!
    $exam: String!
    $filters: InstituteStudentFilterInput
    $limit: Int
    $skip: Int
    $sort: PInstituteStudentSortInput
    $subjectId: String!
  ) {
    examSubjectMarks(
      division: $division
      exam: $exam
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
      subjectId: $subjectId
    ) {
      data {
        marks {
          average
          creditGrades
          creditsEarned
          grade
          marks {
            grade
            marksObtained
            test {
              markingType
              id
              name
              passingMarks
              totalMarks
            }
            grace
          }
        }
        user {
          id
          fullName
          serialNo
          academic {
            rollNo
            grNo
          }
        }
      }
      totalCount
    }
  }
`;

export function useGetExamSubjectMarks() {
  const [getExamSubjectMarks, { loading: loadingExamSubjectMarks, data: dataExamSubjectMarks, error: errorExamSubjectMarks, refetch: refetchExamSubjectMarks }] =
    useLazyQuery(getExamSubjectMarksQuery, { fetchPolicy: 'network-only' });
  return { getExamSubjectMarks, loadingExamSubjectMarks, dataExamSubjectMarks, errorExamSubjectMarks, refetchExamSubjectMarks };
}