import NormalModal from "src/components/atoms/Modals/Normal/index";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import React from "react";
import styled from "styled-components/native";
import { useHistory } from "src/routes/routing";

const SessionExpiry = ({
  isSessionExpired = false,
}: {
  isSessionExpired: boolean;
}) => {
  const history = useHistory();

  const redirectToLogin = () => {
    history.push("/login");
  };

  return (
    <NormalModal
      modalVisible={isSessionExpired}
      setModalVisible={() => { }}
      Headerpopup={"Session Expired"}
      width={480}
      addEditButtonLabel={"Login"}
      handleSave={redirectToLogin}
      hideCancel={true}
    >
      <ReloginMessage>
        <InnerWrapper>
          <NormaltextAtom
            value={`Your session has expired because you have been inactive in your account. Please log in again to proceed.`}
            fontSize={1.8}
            lineHeight={2}
          />
        </InnerWrapper>
        <NormaltextAtom
          value={`You will be redirected to login in 10 secs.`}
          fontSize={1.8}
          lineHeight={2}
        />
      </ReloginMessage>
    </NormalModal>
  );
};

const ReloginMessage = styled.View`
  width: 400px;
  flex-direction: column;
  padding-bottom: 16px;
`;

const InnerWrapper = styled.View`
  margin-bottom: 16px;
`;

export default SessionExpiry;
