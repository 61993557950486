import React, { useEffect, useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import MediumText from '../Text/MediumText';
import Switch from '@mui/material/Switch';
import styled from 'styled-components';
import { colors } from 'src/styles/theme/styles';

export default function ToggleSwitch(props: any) {
  const { onChange, header, style, disabled = false, enabled = false } = props;
  const { t } = useI18n();
  const [isEnabled, setIsEnabled] = useState(enabled);

  function toggleSwitch() {
    setIsEnabled(!isEnabled);
  }

  useEffect(() => {
    setIsEnabled(enabled);
  }, [enabled]);

  return (
    <Container style={style}>
      <CustomSwitch
        checked={isEnabled}
        onChange={onChange ? onChange : toggleSwitch}
        inputProps={{ 'aria-label': 'controlled' }}
        focusVisibleClassName=".Mui-focusVisible" disableRipple {...props}
        disabled={disabled}
        isEnabled={isEnabled}
      />
      {header && (
        <HeadView>
          <MediumText value={t(header)} />
        </HeadView>
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  align-self: auto;
  flex-direction: row;
  display: flex;
`;

const HeadView = styled.div`
  align-self: center;
  margin-left: 10px;
`;

const CustomSwitch = styled(Switch)`
  width: 40px !important;
  height: 24px !important;
  padding: 0px !important;
  border-radius: 16px;
  border: ${(props: any) => (!props.isEnabled || props.disabled ? `2px solid ${colors.lighterBorder} !important` : 0)};
  opacity: ${(props: any) => props.disabled ? 0.5 : 1};
  & .MuiSwitch-switchBase {
    padding: ${(props: any) => (!props.isEnabled || props.disabled ? '2px 0px 0px 3px' : '4px 4px 0px 0px')};
    transition-duration: 300ms;
    & .Mui-checked {
      & + .MuiSwitch-track {
        background-color: ${(props: any) => (!props.isEnabled || props.disabled ? colors.white : colors.primaryColor )};
        opacity: 1 ;
        border: 0;
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }
  }
  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    background-color: ${(props: any) => (!props.isEnabled || props.disabled ? colors.tertiaryText : colors.white)};
  };
  & .MuiSwitch-track {
    border-radius: 26px / 2px;
    background-color: ${(props: any) => (!props.isEnabled || props.disabled ? colors.white : `${colors.primaryColor} !important`)};
    opacity: 1 !important;
    transition: background-color .5s;
  };
`;
