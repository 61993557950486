import React, { useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useHistory, useParams } from 'src/routes/routing';
import { DetailTypeEnum, ERROR, SUCCESS, UserType, USER_TYPE, DefaultUserSelection, DetailStatusEnum, EMPLOYEE_TYPES } from 'src/constant';
import {
  useDeleteEmployeeAcademicDetail,
  useDeleteEmployeeExperienceDetail,
} from 'src/graphql/onboarding/employee';

import {
  useDeleteStudentAcademicDetail,
} from 'src/graphql/onboarding/student';
import { useProfileDetail } from 'src/contexts/profile-detail-context';
import StepperLabels from 'src/components/molecules/onboarding/StepperLabels/index';

import {
  getSanitizedPayload,
  populateStreams,
  populateOccupations,
  populateReligions,
  hasUnverifiedRecord,
  isRecordSubmittedForReview,
} from 'src/utils/utility';

import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import { validatorMapper } from 'src/components/molecules/Forms/validatorMapper'

import { applicationEducationForm, getUserDetailForms, IUserDetailForm, IUserDetailForms } from 'src/form-json/user-details';

import RootFormViewer from 'src/components/molecules/Forms';
import { useUpdateUserDetails } from 'src/utils/customHooks/useUpdateOnboarding';
import { useRefreshAccessToken } from 'src/graphql/login';
import { addOrUpdateTokenListInStorage, getRefreshTokenFromStorage, setRefreshTokenToStorage, setTokenToStorage } from 'src/components/services/storage-service';
import { isAuthenticated } from 'src/graphql/common';
import { isWeb } from 'src/constant/device';
import navigationStrings from 'src/navigation/navigationStrings';
import { useLoadUserDetails, IUserProfileDetails } from 'src/utils/customHooks/useLoadUserDetails';
import { componentMapper } from 'src/components/molecules/ddfElements/index';
import { FormSchema, TemplateType, ViewMode, FormHandleSubmit, qualifiedStreamsType, FormBaseType, FormDDOptions } from 'src/components/molecules/Forms/types';
import { LoadingOrComponent } from '../Loading';
import { GridSelectionModel } from '@mui/x-data-grid';
import AlertModalNative from '../AlertModal/AlertModalNative';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import { useApproveChanges, useRejectChanges, useSubmitChangesForReview } from 'src/graphql/onboarding/profile-verification';
import { UPDATE_SUCCESS } from 'src/constant/message';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import ERROR_MSG from 'src/constant/error';
import { qualifiedStreamsQuery } from 'src/graphql/onboarding/form-content';
import { graphqlQuery } from 'src/graphql/util';
import { DropdownOptionsType } from 'src/types';
import { getCategoryOptionsQuery } from 'src/graphql/admission/caste-category';
import { isEmpty } from 'lodash';
import { documentValidator } from 'src/utils/customValidators';
import { showNativeError } from '../NotificationWrapper';
import { getApplicationPath } from 'src/utils/utility';
import { userInformation } from 'src/utils/manageState';
import { getUpdateApi, isFormValuesEmpty } from './helper';


type UserDetailsPropType = {
  ref?: React.MutableRefObject<any>;
  showSteps?: Boolean,
  viewMode?: ViewMode,
  templateType?: TemplateType,
  formBaseType?: FormBaseType,
  step: number;
  userId: string;
  applicationRef?: string;
  userType: UserType,
  formContents?: FormDDOptions;
  userDetails?: any;
  navigation: any;
  onFinishCallback?: () => void;
  isOnboarding?: boolean;
  reviewMode: boolean;
  submittedRecordInTab: boolean[];
  setSubmittedRecordInTab: Dispatch<SetStateAction<boolean[]>>;
  inModalView?: boolean;
  onClose?: (callback?: any) => Promise<void>
  showSubmitForReviewBtn: () => void;
  refetch?: () => Promise<void>;
  hideAddAndDeleteBtn: Boolean;
  notVerifiedRecordInTab?: boolean[];
  setNotVerifiedRecordInTab?: Dispatch<SetStateAction<boolean[]>>;
  onSubmit?: () => void;
  submitButtonLabel?: string;
  viewApplication?: boolean;
}

type RemoveDocument = {
  [key: string]: { id: string, documents: { id: string }[] }[]
}

function UserDetails(props: UserDetailsPropType) {
  const userType = props?.userType;
  const userId = props?.userId;
  const submittedRecordInTab = props?.submittedRecordInTab;
  const setSubmittedRecordInTab = props?.setSubmittedRecordInTab;
  const isOnboarding = props?.isOnboarding;
  const reviewMode = !!props?.reviewMode;

  const history = useHistory();
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();
  const params: { prefix: string, requestId: string, capId: string, inviteCode: string } = isWeb ? useParams() : {};
  const { prefix, requestId, capId, inviteCode } = params;

  const { isSubmitting, updateUserDetails } = useUpdateUserDetails();
  const { submitForReview } = useSubmitChangesForReview();
  const { deleteEmployeeExperienceDetail } = useDeleteEmployeeExperienceDetail();
  const { deleteEmployeeAcademicDetail } = useDeleteEmployeeAcademicDetail();
  const { deleteStudentAcademicDetail } = useDeleteStudentAcademicDetail(userId);

  const { userDetails, loadUserDetails } = useLoadUserDetails();
  const { approveChanges } = useApproveChanges();
  const { rejectChanges } = useRejectChanges();

  let userDetailForms: IUserDetailForms;
  if (props.formBaseType == FormBaseType.ApplicationQualification) {
    userDetailForms = applicationEducationForm(props.formContents);
  } else {
    userDetailForms = getUserDetailForms(userType, props.formContents, isOnboarding);
  }

  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<IUserProfileDetails | IUserProfileDetails[]>();
  const [step, setStep] = useState<number>(props.step);
  const [formSchema, setFormSchema] = useState<FormSchema>();
  const [templateType, setTemplateType] = useState<TemplateType>(TemplateType.DEFAULT);
  const [viewMode, setViewMode] = useState<ViewMode>(props.viewMode || ViewMode.READ);
  const [selectedRecords, setSelectedRecords] = React.useState<GridSelectionModel>([]);
  const [removedDocs, setRemovedDocs] = useState<RemoveDocument>()
  const [qualStreams, setQualStreams] = useState<qualifiedStreamsType[]>([])
  const [acceptChangesModal, handleAcceptChangesModal] = useState<boolean>(false);
  const [rejectChangesModal, handleRejectChangesModal] = useState<boolean>(false);
  const [submitForReviewModal, handleSubmitForReviewModal] = useState<boolean>(false);
  const [enableSubmitForReviewBtn, setEnableSubmitForReviewBtn] = useState<boolean>(false);
  const [showApproveRejectBtn, setShowApproveRejectBtn] = useState<boolean>(false);
  const [casteCategories, setCasteCategories] = useState<DropdownOptionsType[]>([]);
  const dynamicDocumentsSchema = ['parentDetails', 'educationDetails', 'otherDetails'];

  const { refreshToken } = useRefreshAccessToken();
  const { userProfileDetails } = useProfileDetail();

  function isLastStep() {
    return step === userDetailForms[viewMode].length - 1;
  }

  const isParentDetail = () => !!(formSchema && formSchema.name === "parentDetails");
  const isAddressDetail = () => !!(formSchema && formSchema.name === "addressDetails");
  const currentUserObject = userInformation();
  const isInstituteAdmin =
    currentUserObject?.userType === USER_TYPE.EMPLOYEE &&
    currentUserObject?.userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN);
  const allowProfileEdit =
    currentUserObject?.userType === USER_TYPE.APPLICANT ||
    currentUserObject?.userDetail?.type?.includes('ADMIN') ||
    currentUserObject?.userDetail?.allowProfileEdit;

  const validationFunction = reviewMode ? isRecordSubmittedForReview : hasUnverifiedRecord;

  const shouldShowDraftForDetail = (reviewCondition: boolean, records: any[]) => {
    return reviewCondition && records.some(validationFunction);
  }

  const showDraftForParents = (data) => {
    const records = [data?.fatherDetail, data?.motherDetail];
    return shouldShowDraftForDetail(isParentDetail(), records);
  };

  const showDraftForAddresses = (data) => {
    const records = [data?.current, data?.permanent];
    return shouldShowDraftForDetail(isAddressDetail(), records);
  };

  const showDraftForEduExp = (data: { status: DetailStatusEnum; }[]) => {
    if (templateType !== TemplateType.LIST || !Array.isArray(data)) return false;
    return data && data.some(validationFunction);
  };

  const showDraftForStatus = (data: { status: DetailStatusEnum }) => {
    if (!data?.status) return false;
    return validationFunction(data);
  };

  const showDraft = (data: any) =>
    showDraftForStatus(data) ||
    showDraftForParents(data) ||
    showDraftForAddresses(data) ||
    showDraftForEduExp(data);

  function renderDetails(data: any) {

    if (userType !== USER_TYPE.APPLICANT && showDraft(data)) {
      if (isParentDetail()) {
        return {
          "fatherDetail": data?.fatherDetail?.draft?.detail,
          "motherDetail": data?.motherDetail?.draft?.detail
        }
      } else if (isAddressDetail()) {
        return {
          "current": data?.current?.draft?.detail,
          "isSameAsCurrent": data?.isSameAsCurrent,
          "permanent": data?.permanent?.draft?.detail
        }
      } else if (templateType == TemplateType.LIST) {
        let recordsArray = data.map(elem => {
          if (hasUnverifiedRecord(elem) && elem?.draft) {
            return elem.draft.detail;
          } else {
            return elem;
          }
        })
        return recordsArray;
      }
      else {
        return data?.draft?.detail ? data.draft.detail : data;
      }
    }
    else {
      return data;
    }
  }


  useEffect(() => {
    if (userProfileDetails) {
      setIsLoading(false);
    }
    if (userProfileDetails?.id !== userId) {
      loadUserDetails({ userId, userType })
    }
  }, [userProfileDetails])

  useEffect(() => {
    if (userDetails && formSchema) {
      let updatedDetails = userDetails[formSchema?.name];
      if (props.userDetails) {
        // This use case is for education details when a single one is updated.
        if (Array.isArray(updatedDetails)) {
          const details = updatedDetails.find((det) => det.id == props.userDetails.id);
          if (details) {
            setInitialValues(renderDetails(details));
          }
        }
      } else {
        setInitialValues(renderDetails(updatedDetails));
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      initialValues &&
      formSchema?.name === "educationDetails" &&
      templateType == TemplateType.DEFAULT
    ) {
        getQualifiedStreams(initialValues?.id);
    }
    if (
      initialValues &&
      formSchema?.name === "otherDetails" &&
      templateType == TemplateType.DEFAULT
    ) {
        getCategoryOptions();
    }
  }, [initialValues]);

  useEffect(() => {
    if (!props.userDetails && userProfileDetails && formSchema) {
      const details = userProfileDetails[formSchema?.name];
      if (
        (templateType == TemplateType.LIST && (Array.isArray(details) || !details)) ||
        (templateType == TemplateType.DEFAULT && !Array.isArray(details))
      ) {
        setTimeout(() => {
          setInitialValues(renderDetails(details));
          setIsLoading(false);
        })
      }
    }

    if (isLoading) {
      if ((props.userDetails) || (props.userDetails && props.formBaseType === FormBaseType.ApplicationQualification)) {
        setInitialValues(renderDetails(props.userDetails));
        setIsLoading(false);
      }
    }
  }, [templateType, formSchema])


  useEffect(() => {
    if (!userDetails && props?.userDetails) {
      setInitialValues(renderDetails(props.userDetails))
    }
  }, [props.userDetails])

  useEffect(() => {
    setStep(props.step);
  }, [props.step])

  useEffect(() => {
    if (props.viewMode) {
      setViewMode(props.viewMode);
    }
  }, [props.viewMode])

  useEffect(() => {
    setIsLoading(true);
    const formSchema = userDetailForms[viewMode][step];
    let templateType: TemplateType;
    if (props.templateType) {
      templateType = props.templateType;
    } else {
      templateType = formSchema.templateType;
    }
    setFormSchema(formSchema.schema);
    setTemplateType(templateType);
  }, [viewMode, step])

  useEffect(() => {
    if (props.userDetails && initialValues) {
      setTemplateType(Array.isArray(initialValues) ? TemplateType.LIST : TemplateType.DEFAULT);
    }

    if (initialValues && !Array.isArray(initialValues)) {
      let options: FormDDOptions = {}; 
      if (qualStreams?.length > 0) {
        options.streams = qualStreams;
      }
      if (casteCategories?.length > 0) {
        options.categories = casteCategories;
      }
      if (!isEmpty(options)) {
        let dynamicDocumentsSchema: IUserDetailForm;
        if (props.formBaseType == FormBaseType.ApplicationQualification) {
          dynamicDocumentsSchema = applicationEducationForm(
            options
          )[viewMode][step];
        } else {
          dynamicDocumentsSchema = getUserDetailForms(
            userType,
            options,
            isOnboarding,
          )[viewMode][step];
        }
        setFormSchema(dynamicDocumentsSchema.schema);
      }
    }
  }, [initialValues, qualStreams, casteCategories, viewMode])

  async function getQualifiedStreams(eduId?: string) {
    const payload = eduId ? {
      educationId: eduId
    } : {
      filters: {
        defaultUserSelection: {
          eq: DefaultUserSelection.ALLOWED
        }
      }
    };
    const qualStreams = await graphqlQuery(qualifiedStreamsQuery, payload);
    setQualStreams(qualStreams?.data?.qualifiedStreams);
  }
  async function getCategoryOptions() {
    const categories = await graphqlQuery(getCategoryOptionsQuery, {
      limit: 0, filters: {
        defaultUserSelection: { eq: DefaultUserSelection.ALLOWED },
      }
    });
    setCasteCategories(categories?.data?.castes?.data);
  }
  
  useEffect(() => {
    if (props?.notVerifiedRecordInTab) {
      setEnableSubmitForReviewBtn(props.notVerifiedRecordInTab?.some((element) => element));
    }
  }, [props?.notVerifiedRecordInTab]);

  async function refreshUserDetail() {
    loadUserDetails({ userId, userType });
  }

  useEffect(() => {
    if (allowProfileEdit) {
      setShowApproveRejectBtn(
        props.reviewMode &&
        props?.submittedRecordInTab &&
        props.submittedRecordInTab[step]
      );
    }
  }, [allowProfileEdit, props.reviewMode, props?.submittedRecordInTab, step]);

  const MemoizedFormTemplateWeb = useCallback(() => (
    <RootFormViewer
      step={step}
      setStep={setStep}
      isSubmitting={isSubmitting}
      isLastStep={isLastStep()}
      formInitialValues={initialValues}
      setFormInitialValues={setInitialValues}
      handleDelete={handleDeleteEducationExperienceDetail}
      handleRemoveDoc={handleRemoveDoc}
      goToNextStep={goToNextStep}
      schema={formSchema}
      templateType={templateType}
      viewMode={viewMode}
      setViewMode={setViewMode}
      isOnboarding={props.isOnboarding || false}
      inModalView={props.inModalView}
      onCancel={props.onClose}
      refreshList={props?.refetch ? props.refetch : refreshUserDetail}
      navigation={!isWeb && props.navigation}
      formBaseType={props.formBaseType}
      applicationRef={props.applicationRef}
      userType={props?.userType}
      userId={props?.userId}
      reviewMode={props.reviewMode}
      selectedRecords={selectedRecords}
      setSelectedRecords={setSelectedRecords}
      handleAcceptChangesModal={handleAcceptChangesModal}
      handleRejectChangesModal={handleRejectChangesModal}
      submitForReviewModal={submitForReviewModal}
      handleSubmitForReviewModal={handleSubmitForReviewModal}
      submitForReviewFn={submitForReviewFn}
      enableSubmitForReviewBtn={enableSubmitForReviewBtn}
      showApproveRejectBtn={showApproveRejectBtn}
      hideAddAndDeleteBtn={props.hideAddAndDeleteBtn}
      prefix={prefix}
      requestId={requestId}
      capId={capId}
      inviteCode={inviteCode}
      submitButtonLabel={props?.submitButtonLabel}
      viewApplication={props?.viewApplication}
      onFinishCallback={props?.onFinishCallback}
      allowProfileEdit={allowProfileEdit}
    />
  ), [step, initialValues, isSubmitting, formSchema, viewMode, selectedRecords, submitForReviewModal, enableSubmitForReviewBtn, showApproveRejectBtn, allowProfileEdit])

  async function refreshJwtToken() {
    const refresh = await getRefreshTokenFromStorage();
    if (refresh) {
      const user = await refreshToken({ variables: { refresh } });
      if (user && user.data && user.data.refreshAccessToken && user.data.refreshAccessToken.token) {
        await setTokenToStorage(user.data.refreshAccessToken.token);
        await addOrUpdateTokenListInStorage(
          user.data.refreshAccessToken.token,
          user.data.refreshAccessToken.refresh,
        );
        await setRefreshTokenToStorage(user.data.refreshAccessToken.refresh);
        await isAuthenticated();
      }
    }
  }

  async function handleDeleteEducationExperienceDetail(id?: string) {
    if (id) {
      let deleteResponse;
      let removePayload = {} as any;
      removePayload['id'] = props?.userId || '';
      if (userType == USER_TYPE.EMPLOYEE) {
        if (step == 2) {
          removePayload['educationDetails'] = { id };

          deleteResponse = await deleteEmployeeAcademicDetail({
            variables: { remove: removePayload },
          });
          loadUserDetails({ userId, userType })
        } else if (step == 3) {
          removePayload['experienceDetails'] = { id };

          deleteResponse = await deleteEmployeeExperienceDetail({
            variables: { remove: removePayload },
          });
          loadUserDetails({ userId, userType })
        }
      } else if (userType == 3) {
        if (step == 3) {
          removePayload['educationDetails'] = { id };
          deleteResponse = await deleteStudentAcademicDetail({
            variables: { remove: removePayload },
          });
          loadUserDetails({ userId, userType })
        }
      }
    }
  }

  const goToNextStep = async () => {
    if (isLastStep()) {
      if (isWeb) {
        let path = '/dashboard';
        history.push(
          userType === USER_TYPE.APPLICANT
            ? getApplicationPath(path, prefix, requestId, capId, inviteCode)
            : path,
        );
      } else {
        refreshJwtToken();
        props?.onFinishCallback && props?.onFinishCallback();
      }
    } else {
      if (isWeb) {
        let path = `/onboarding/${step + 1}`;
        history.push(
          userType === USER_TYPE.APPLICANT
            ? getApplicationPath(path, prefix, requestId, capId, inviteCode)
            : path,
        );
      } else {
        props.navigation.push(navigationStrings.NATIVE_ON_BOARDING, {
          step: step + 1,
        })
      }
    }
  }

  const handleRemoveDoc = (
    remove: { detail: string, detailId: string, docId: string },
  ) => {
    const { detail, detailId, docId } = remove;
    let uRemovedDocs = removedDocs;
    if (uRemovedDocs && uRemovedDocs[detail]) {
      const idx = uRemovedDocs[detail].findIndex((det) => det.id == detailId);
      if (idx != -1) {
        uRemovedDocs[detail][idx].documents.push({ id: docId });
      } else {
        uRemovedDocs[detail].push({ id: detailId, documents: [{ id: docId }] });
      }
    } else {
      uRemovedDocs = {
        [detail]: [{
          id: detailId,
          documents: [{ id: docId }],
        }]
      }
    }
    setRemovedDocs(uRemovedDocs);
  }

  const handleSubmit = async (values: any, callback: any) => {
    if (formSchema && values && Object.keys(values).length && !isFormValuesEmpty(formSchema.name, values)) {
      if (!isInstituteAdmin && userType !== USER_TYPE.APPLICANT && dynamicDocumentsSchema.includes(formSchema?.name)) {
        const documentErrMsg = await documentValidator(values, formSchema?.name);
        if (documentErrMsg) {
          if (isWeb) {
            setAlertDetails({ message: documentErrMsg, level: ERROR });
          } else {
            showNativeError(documentErrMsg, 6000);
          }
          return;
        }
      }
      const payload = getSanitizedPayload(formSchema, values);
      const updates: any = { payload };
      if (removedDocs) {
        updates.remove = removedDocs;
      }
      let updateId = userId;
      let applicationQualification = false;
      if (props.formBaseType == FormBaseType.ApplicationQualification && props.applicationRef) {
        updateId = props.applicationRef;
        applicationQualification = true;
      }
      const updateResponse = await updateUserDetails(
        updates,
        templateType,
        updateId,
        userType,
        applicationQualification,
        props.inModalView,
        props.isOnboarding,
        goToNextStep,
        callback,
      );
      
      // Submit for review on onboarding final step
      if ([USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT].includes(userType) && isOnboarding && isLastStep()) {
        const responseApi = getUpdateApi(userType);
        if (updateResponse?.data && updateResponse.data?.[`${responseApi}`]?.status === 'success') {
          try {
            await submitForReview();
          } catch (e: any) {
            console.error('submit for review err', e?.message);
          }
        }
      }

      loadUserDetails({ userId, userType }, true)
      if (props.onClose) {
        props.onClose(callback);
      }
      if (props?.showSubmitForReviewBtn) props?.showSubmitForReviewBtn();
      if (applicationQualification && !isWeb) {
        props?.navigation?.navigate(navigationStrings.EDUCATION_DETAILS, { applId: props?.applicationRef });
      }
      return;
    } else {
      if (props?.onClose) {
        if (isWeb) {
          props.onClose(callback);
        } else {
          props.navigation.goBack();
        }
      } else {
        if (!isOnboarding) {
          if (isWeb) {
            history.push(location.pathname.replace('/edit', '/view'));
          } else {
            setViewMode(ViewMode.READ);
          }
        }
      }
    }
  }

  const onSubmit = async (values: any, formApi: any): Promise<FormHandleSubmit> => {
    return async (params: {
      removeDoc?: { detail: string; detailId: string; docId: string },
      submit?: { callback: Function },
    }) => {
      if (params.removeDoc) {
        handleRemoveDoc(params.removeDoc);
      }
      if (params.submit) {
        handleSubmit(values, params.submit.callback)
      }
    }
  }

  function makeDetailObject() {
    let detailObj = {
      "detail": {
        "detailType": DetailTypeEnum[formSchema.name],
        "userId": props.userId,
        "userType": UserType[userType]
      }
    }
    if (templateType == TemplateType.LIST) {
      const selectedRowData = userDetails[formSchema.name].filter((row) => selectedRecords.includes(row.id));
      const draftIDs = selectedRowData.map(elem => elem.draft.id);
      detailObj.detail.detailIds = draftIDs;
    }
    return detailObj;
  }

  function hideIndicator() {
    let temp = submittedRecordInTab;
    temp[step] = false;
    setSubmittedRecordInTab(temp);
  }

  async function submitForReviewFn() {
    try {
      let submitForReviewData = await submitForReview();
      if (
        submitForReviewData?.data?.submitChangeInDetailForReview?.status ==
        SUCCESS
      ) {
        setAlertDetails({
          message: UPDATE_SUCCESS.CHANGES_SUBMITTED,
          level: SUCCESS,
        });
        await refreshUserDetail();
        if (props?.setNotVerifiedRecordInTab) {
          props.setNotVerifiedRecordInTab(new Array(5).fill(false));
        }
      } else {
        setAlertDetails({
          message: submitForReviewData?.data?.submitChangeInDetailForReview?.message,
          level: ERROR,
        });
      }
    } catch (error) {
      setAlertDetails({ message: error?.message, level: ERROR });
    }
    handleSubmitForReviewModal(false);
  };

  async function approveChangesFn() {
    try {
      let approveChangesData = await approveChanges({
        variables: makeDetailObject()
      })
      if (approveChangesData?.data?.approveChangeInDetail?.status == SUCCESS) {
        if (refreshUserDetail) await refreshUserDetail();
        if (templateType == TemplateType.LIST) setSelectedRecords([]);
        hideIndicator();
        setAlertDetails({ message: UPDATE_SUCCESS.CHANGES_APPROVED, level: SUCCESS })
      } else {
        setAlertDetails({ message: approveChangesData?.data?.approveChangeInDetail?.message, level: ERROR });
      }
    } catch (error) {
      console.log(error)
      setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
    }
    handleAcceptChangesModal(false)
  }

  async function rejectChangesFn() {
    try {
      let rejectChangesData = await rejectChanges({
        variables: makeDetailObject()
      })
      if (rejectChangesData?.data?.rejectChangeInDetail?.status == SUCCESS) {
        if (refreshUserDetail) await refreshUserDetail();
        hideIndicator();
        setAlertDetails({ message: UPDATE_SUCCESS.CHANGES_REJECTED, level: SUCCESS })
      } else {
        setAlertDetails({ message: rejectChangesData?.data?.rejectChangeInDetail?.message, level: ERROR });
      }
    } catch (error) {
      setAlertDetails({ message: error?.message, level: ERROR });
    }
    handleRejectChangesModal(false)
  }

  return (
    <>
      {isWeb && props.showSteps ? <StepperLabels step={step} /> : null}
      <LoadingOrComponent loading={isLoading}>
        {
          formSchema ?
            <>
              <FormRenderer
                componentMapper={componentMapper}
                FormTemplate={MemoizedFormTemplateWeb}
                schema={formSchema}
                onSubmit={props?.onSubmit ? props?.onSubmit : onSubmit}
                initialValues={initialValues}
                validatorMapper={validatorMapper}
              />

              <AlertModalNative
                title={t("approve-changes.label")}
                message1={`${t('approve-changes.text')}${t(formSchema?.headerData.title)}?`}
                onCancel={() => handleAcceptChangesModal(false)}
                onConfirm={() => approveChangesFn()}
                showModal={acceptChangesModal}
                confirmButtonText={t('save.label')}
                showTitleLogo={false}
                titleTextColor={colors.primaryText}
              />

              <AlertModalNative
                title={t("reject-changes.label")}
                message1={`${t('reject-changes.text')}${t(formSchema?.label)}?`}
                onCancel={() => handleRejectChangesModal(false)}
                onConfirm={() => rejectChangesFn()}
                showModal={rejectChangesModal}
                confirmButtonText={t('reject.label')}
                showTitleLogo={false}
                confirmButtonColor={colors.errorColor}
                titleTextColor={colors.primaryText}
              />
            </> : <></>
        }
      </LoadingOrComponent >

    </>
  );
}

export default React.memo(UserDetails);