import useTable from 'src/components/molecules/Table/UseTable';
import { NoRecordsFound, TableCells, TableRows } from 'src/components/molecules/Table/TableAtom';
import React from 'react';
import { TableBody } from '@mui/material';
import Check from 'src/components/atoms/CheckBox/index.web';
import { colors } from 'src/styles/theme/styles';
import {
  TsubjectsData,
  revalHeadcells,
} from 'src/components/molecules/Assessment/Results/Helpers/helpers';
type Tprops = {
  checked: string[];
  setChecked: React.Dispatch<React.SetStateAction<string[]>>;
  subjectsData: {
    requestRevalSubjects: TsubjectsData[];
  };
  softCopyChecked: string[];
  setSoftCopyChecked: React.Dispatch<React.SetStateAction<string[]>>;
};
export default function RevaluationModal(props: Tprops) {
  const { checked, setChecked, subjectsData, softCopyChecked, setSoftCopyChecked } = props;
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    subjectsData.requestRevalSubjects,
    revalHeadcells,
  );

  const handleCheckAll = () => {
    if (checked.length === 0) {
      let allUsers = [] as any;
      recordsAfterPagingAndSorting()?.forEach((item: any) => {
        allUsers.push(item?.subject?.id);
      });
      setChecked(allUsers);
    } else if (checked.length > 0 && checked.length) {
      let update = [...checked] as any;
      const res = recordsAfterPagingAndSorting()?.filter(x => !checked.includes(x.subject?.id));
      res.forEach((e: any) => {
        update.push(e?.subject?.id);
      });
      setChecked(update);
    } else {
      setChecked([]);
    }
  };

  const handleCheck = (item: string) => {
    const index = checked.findIndex(x => x === item);
    const updated = [...checked];
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push(item);
    }
    setChecked(updated);
  };

  const handleSoftCopyCheck = (item: string) => {
    const index = softCopyChecked.findIndex(x => x === item);
    const updated = [...softCopyChecked];
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push(item);
    }
    setSoftCopyChecked(updated);
  }
  return (
    <>
      <TblContainer>
        <TblHead
          checkBox={true}
          disableCheckBox={false}
          checked={
            checked.length === subjectsData?.requestRevalSubjects.length && checked.length != 0
          }
          handleCheck={handleCheckAll}
        />
        {recordsAfterPagingAndSorting()?.length > 0 ? (
          <TableBody>
            {recordsAfterPagingAndSorting().map((item: TsubjectsData) => (
              <TableRows key={item?.id}>
                <TableCells style={{ borderBottom: 'none' }}>
                  <Check
                    same={false}
                    onChange={() => handleCheck(item.subject.id)}
                    checked={checked.some(checkedItem => checkedItem === item.subject.id)}
                  />
                </TableCells>
                <TableCells value={item?.subject?.name ?? '-'} style={{ borderBottom: 'none' }} />
                <TableCells
                  value={item?.marksObtained ?? '-'}
                  style={{ borderBottom: 'none' }}
                  color={item?.failedHeads && colors.red}
                />
                <TableCells value={item?.passingMarks ?? '-'} style={{ borderBottom: 'none' }} />
                <TableCells value={item?.totalMarks ?? '-'} style={{ borderBottom: 'none' }} />
                <TableCells style={{ borderBottom: 'none' }}>
                  <Check
                    same={false}
                    onChange={() => handleSoftCopyCheck(item.id)}
                    checked={softCopyChecked.some(checkedItem => checkedItem === item.id)}
                    disabled={!(checked.some(checkedItem => checkedItem === item.subject.id))}
                  />
                </TableCells> 
              </TableRows>
            ))}
          </TableBody>
        ) : (
          <NoRecordsFound colspan={6} maxHeight={50} />
        )}
      </TblContainer>
    </>
  );
}
