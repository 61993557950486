import React, { Dispatch, SetStateAction, useState } from 'react';
import { ExpandCollapseCell, TableCells, TableRows } from './TableAtom';
import Check from 'src/components/atoms/CheckBox/index.web';
import { TableBody } from '@mui/material';
import { View } from 'react-native';
import { colors } from 'src/styles/theme/styles';
import { getCellStyle } from 'src/utils/utility';
import styled from 'styled-components/native';
import useTable from './UseTable';
import { useThemeSystem } from 'src/contexts/theme-context';
import Input from 'src/components/atoms/Input/input.web';

type PropsType = {
  tabledata: NestedCheckBoxTableData;
  setSelected: Dispatch<SetStateAction<NestedCheckBoxTableData>>;
  header?: any;
  width?: any;
  isChild?: boolean;
  childHandleCheck?: Function;
}

// This component requires 2 main props that are tabledata of type NestedCheckBoxTableData
// and a set callback function to which this will provide array of selected ones.
export default function NestedCheckboxTable(props: PropsType) {
  const { header, tabledata, setSelected, width, isChild = false, childHandleCheck } = props;
  const { theme } = useThemeSystem();
  let maxHeight;
  const { TblContainer, TblHead } = useTable(null, header, maxHeight);
  const [trow, setT] = useState(false);
  const [current, setCurrent] = useState<any>(null);

  const ensureCheckBox = (tdObj: NestedCheckBoxTableRowData, flag?: boolean) => {
    if (typeof tdObj.value == 'string') {
      tdObj.selected = flag || !tdObj.selected;
    }
    if (Array.isArray(tdObj.value)) {
      tdObj.selected = flag || !tdObj.selected;
      ensureAllCheckBox(tdObj.value, 'all', tdObj.selected);
    }
  }
  const ensureAllCheckBox = (tbldata: any[], id: string, flag?: boolean) => {
    if (tbldata) {
      if (id && id != 'all') {
        tbldata.some((td) => {
          if (td.id == id) {
            ensureCheckBox(td, flag);
            return true;
          }
        });
      }
      if (id == 'all') {
        tbldata.forEach((td) => {
          ensureCheckBox(td, flag);
        });
      }
      setSelected([...tbldata]);
    }
  }

  //Todo: for now this works for only 2 level of bubble effect.
  const ensureChildCheckBox = (grpId: string, id: string) => {
    if (tabledata) {
      tabledata.some((td) => {
        if (td.id == grpId) {
          let allSelected: boolean = true;
          if (Array.isArray(td.value)) {
            td.value.forEach((val) => {
              if (val.id == id) {
                val.selected = !val.selected;
              }
              allSelected = allSelected && val.selected;
            })
            td.selected = allSelected;
          }
          return true;
        }
      });

      const currentSubject = tabledata.find((item: NestedCheckBoxTableRowData) => item?.id === grpId);
      if (currentSubject?.order && !currentSubject?.selected && Array.isArray(currentSubject?.value)) {
        const childSelection = currentSubject?.value?.some((item: NestedCheckBoxTableRowData) => item?.selected);
        if (!childSelection) {
          const currentSubjectIdx = tabledata.findIndex((item: NestedCheckBoxTableRowData) => item?.id === grpId);
          if (currentSubjectIdx || (currentSubjectIdx == 0)) {
            tabledata[currentSubjectIdx].order = null;
          }
        }
      }
      setSelected([...tabledata]);
    }
  }

  function getHandleChildCheck(grpId: string) {
    return (id: string) => {
      ensureChildCheckBox(grpId, id);
    }
  }

  function handleChecked(id: string, checked: boolean) {
    if (!checked && tabledata) {
      const currentSubjectIdx = tabledata?.findIndex(
        (item: NestedCheckBoxTableRowData) => item.id === id,
      );
      if (currentSubjectIdx || currentSubjectIdx == 0) {
        const temp = [...tabledata];
        temp[currentSubjectIdx].order = null;
        setSelected(temp);
      }
    }

    if (!isChild) {
      ensureAllCheckBox(tabledata, id, checked);
    }
  }

  const allowOrderInputEdit = (grpId: string) => {
    const currentSubject = tabledata?.find((item: NestedCheckBoxTableRowData) => item.id === grpId);
    return (
      currentSubject?.selected ||
      (Array.isArray(currentSubject?.value) &&
        currentSubject?.value.some((item: NestedCheckBoxTableRowData) => item.selected))
    );
  };

  const handleOrderChange = (order: string, grpId: string) => {
    let value = parseInt(order);
    if (typeof value == 'number' && tabledata && tabledata.length) {
      const currentSubjectIdx = tabledata?.findIndex((item: NestedCheckBoxTableRowData) => item.id === grpId);
      value = value > tabledata.length ? tabledata.length : value;
      const temp = [...tabledata];
      temp[currentSubjectIdx].order = value;
      setSelected(temp);
    }
  }

  const getOrderVal = (grpId: string) => {
    const currentSubject = tabledata?.find((item: NestedCheckBoxTableRowData) => item.id === grpId);
    return (currentSubject && Number.isInteger(currentSubject?.order)) ? currentSubject.order : '';
  }

  return (
    <View style={{ overflow: 'auto', width }}>
      <TblContainer tabledata={tabledata}>
        <TblHead />
        <TableBody>
          {tabledata &&
            tabledata.map((item: NestedCheckBoxTableRowData, i: number) => (
              <>
                <TableRows key={`e${i}`} style={{
                  backgroundColor: trow && current === i ? colors.menuSelectionBg : 'transparent',
                  width: '5%',
                  height: 56,
                }}>

                  {
                    Array.isArray(item.value) ? (<ExpandCollapseCell trow={trow} current={current} i={i}
                      style={{...getCellStyle({trow, current, id: i, isFirstCell:true, isLastCell:false, itemId:item?.id})} }
                      textValue={item?.label}
                      CollapseFn={() => {
                        setT(false);
                        setCurrent(null);
                      }}
                      ExpandFn={() => {
                        setT(true);
                        setCurrent(i);
                      }}
                    />)
                      :
                      <TableCells 
                        style={{...styles.textStyle, ...getCellStyle({trow, current, id: i, isFirstCell:false, isLastCell:false, itemId:item?.id})} }
                      >
                        {item?.label}
                      </TableCells>
                  }

                  <TableCells
                    style={{
                      ...styles.textStyle,
                      ...getCellStyle({
                        trow,
                        current,
                        id: i,
                        isFirstCell: false,
                        isLastCell: false,
                        itemId: item?.id,
                      }),
                    }}>
                    {!isChild && (
                      <Input
                        align="right"
                        disabled={!allowOrderInputEdit(item?.id)}
                        setValue={(e: any) => {
                          handleOrderChange(e.target.value, item?.id)
                        }}
                        value={getOrderVal(item?.id)}
                      />
                    )}
                  </TableCells>

                  <TableCells style={{...styles.textStyle, ...getCellStyle({trow, current, id: i, isFirstCell:false, isLastCell:true, itemId:item?.id})} }
                  >
                    <Check
                      id={item?.id}
                      width={100}
                      same={false}
                      checked={item.selected}
                      onChange={(value: boolean) => isChild && childHandleCheck ? childHandleCheck(item?.id) : handleChecked(item?.id, value)}
                      index={i}
                      direction={'right'}
                    />
                  </TableCells>
                </TableRows>

                {
                  trow && current === i && Array.isArray(item.value) && (
                    <TableRows style={{
                      color: theme?.table?.color,
                    }}>
                      <td colSpan={4} style={{ padding: 0 }}>
                        <ChildTableContainer>
                          <NestedCheckboxTable
                            tabledata={item.value}
                            isChild={true}
                            childHandleCheck={getHandleChildCheck(item.id)}
                          />
                        </ChildTableContainer>
                      </td>
                    </TableRows>
                  )
                }
              </>
            ))}
        </TableBody>
      </TblContainer>
    </View>
  );
}

const styles = {
  textStyle: {
    padding: '0px 8px',
    fontSize: 15,
    color: colors.primaryText,
    fontWeight: 400,
  },
};

const ChildTableContainer = styled.View`
  display: flex;
  padding-top: 10px;
  padding-left: 43px;
  border-color: "rgba(43, 120, 202, 0.3)";
  border-width: 2px;
`;
