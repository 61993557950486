import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import {
  AUTOCOMPLETE_MODULE,
  ERROR,
  INSTITUTEGROUP,
  SEARCH_TIME,
  SUCCESS,
  limit,
} from 'src/constant/index';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { addModuleAccessToInstitute, getAllowedModules, removeModuleAccessfromInstitute } from 'src/graphql/institutes/module';
import { createSearchFilter, debounce } from 'src/components/services';
import {
  getInstitutesQuery,
  updateInstituteMutation,
  useAddInstitute,
  useApproveInstitute,
  useDeleteInstitute,
  useMutateInstitute,
  useQueryInstitutes,
  useUpdateInstitute,
} from 'src/graphql/institutes/index';

import AddEditInstituteForm from 'src/components/molecules/Institute/AddEditInstituteForm';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import ERROR_MSG from 'src/constant/error';
import InstituteForm from 'src/components/molecules/Institute/InstituteForm';
import InstituteTable from 'src/components/molecules/Table/InstituteTable';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import { ModulesList } from 'src/components/molecules/Institute/modulesList';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import Search from '../../atoms/SearchBar/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import SelectInstituteCourses from 'src/components/molecules/Institute/SelectInstituteCourses';
import { TableDiv } from 'src/components/molecules/Table/TableAtom';
import { getInstituteFilter } from 'src/components/services/filters';
import { height } from 'src/constant/device';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useFilter } from 'src/contexts/filter-context';
import { useForm } from 'react-hook-form';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'react-router';

export default function Institues() {
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
    setValue,
  } = useForm();
  const {
    handleSubmit: handleCourseFormSubmit,
    control: courseFormControl,
    formState: { errors: courseFormError },
    reset: resetCourseForm,
    setValue: setCourseFormValue,
    getValues: getCourseFormValues,
    setError: setCourseFormError
  } = useForm();
  const [canClick, setCanClick] = useState(true);
  let row;
  let searchValue = '' as string;

  const { page = 1, pageLimit = limit }: any = useParams();
  const rowDefaultLimit =  parseInt(pageLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState<number>(parseInt(page));
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const { filters, setFilter } = useFilter();

  const isSubmitting = useRef(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [modalState, handleModal] = useState(false);
  const [editInstituteData, setInstituteData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [isModuleModal, setIsModuleModal] = useState(false);
  const [iChildInstitute, setIsChildInstitute] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [addForGroup, setAddForGroup] = useState(true);
  const [groupData, seletedGroupData] = useState({});
  const [groupId, setGroupId] = useState('');
  const [currentFilter, setCurrentFilter] = useState<any>({});
  const [allModules, setAllModules] = useState([]);
  const [addModuleAccess, setAddModuleAccess] = useState<String[]>([]);
  const [removeModuleAccess, setRemoveModuleAccess] = useState<String[]>([]);
  const [isEqual, setIsEqual] = useState(false);
  const [check, setCheck] = useState(false);
  const [addEditModalState, handleAddEditModalState] = useState<boolean>(false);
  const [courseSelectionModalState, handleCourseSelectionModalState] = useState<boolean>(false);
  const [isTypeGroup, setIsTypeGroup] = useState<boolean>(false);
  const [data, setData] = useState<Object>({});

  let { setAlertDetails } = useAlertSystem();
  if (filters && filters?.institute?.on) {
    searchValue = filters?.institute?.search;
  }

  const { fetchAllowedModules, allowedModules } = getAllowedModules();
  const { addModuleAccessQ, error: errAddModule } = addModuleAccessToInstitute();
  const { removeModuleAccessQ, error: errRemoveModule } = removeModuleAccessfromInstitute();

  const [searching, setSearchingInstitute] = useState(searchValue);
  //Institute query
  const { getInstitutes, institutesData, institutesError, institutesLoading, refetchInstitutesQuery } = useQueryInstitutes(
    searching?.length > 0 ? 'network-only' : 'cache-first',
  );
  const { mutate: approveInstitue } = useApproveInstitute(page);
  const { mutate: updateInstitute } = useMutateInstitute(updateInstituteMutation);
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  row = institutesData?.institutes?.data;
  const { mutate: addInstitue, ErrorOccur } = useAddInstitute(
    page,
    groupId,
    searchValue,
    rowLimit,
  );
  const { deleteInstitute } = useDeleteInstitute(page, groupId, searchValue, rowLimit);

  const [totalCount, setTotalCount] = useState<number|null>(institutesData?.institutes?.totalCount ?? null);
  
  useEffect(() => {
    const count = institutesData?.institutes?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [institutesData?.institutes?.totalCount]);

  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setSearchingInstitute(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    let allMods = allowedModules?.allowedModules?.modules;
    if (allMods) {
      allMods = allMods.map((mod: any) => ({ id: mod, name: mod }));
      setAllModules(allMods);
    }
  }, [allowedModules]);

  useEffect(() => {
    handleFetch();
  }, [searchValue, currentPage, rowLimit]);

  function handleFetch() {
    let filters = getInstituteFilter(searchValue);
    let instituteFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    getInstitutes({ variables: instituteFilter });
    setCurrentFilter(instituteFilter);
    fetchAllowedModules();
  }

  function handleRefetch() {
    let filters = getInstituteFilter(searchValue);
    let instituteFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    refetchInstitutesQuery({ variables: instituteFilter });
    setCurrentFilter(instituteFilter);
    fetchAllowedModules();
  }

  function setTitle() {
    setHeading([
      {
        text: t('institutes.label'),
        url: '',
      },
    ]);
  }

  const editInstituteModal = (instituteData: any) => {
    if (instituteData?.type === INSTITUTEGROUP) {
      setIsTypeGroup(true);
    } else {
      setIsTypeGroup(false);
    }
    setInstituteData(instituteData);
    if (instituteData?.courses) {
      setSelectedCourses(instituteData.courses);
    };
    setIsEditModal(true);
    hideAlert();
    handleAddEditModalState(true);
  };

  const instituteModulesModal = (instituteData: any) => {
    setInstituteData(instituteData);
    setIsModuleModal(true);
    hideAlert();
  };

  const triggerAddInstituteModal = () => {
    setAddForGroup(false);
    setIsTypeGroup(false)
    setIsEditModal(false);
    reset({});
    handleAddEditModalState(true);
  }

  const closeAddEditInstituteModal = () => {
    handleAddEditModalState(false);
    reset({});
  }

  const closeCourseSelectionForm = () => {
    handleCourseSelectionModalState(false);
    resetCourseForm({});
    setSelectedCourses([]);
  }

  const closeModal = () => {
    closeCourseSelectionForm();
    closeAddEditInstituteModal();
    closeModuleModal();
  };

  const closeModuleModal = () => {
    setIsModuleModal(false);
    setAddModuleAccess([]);
    setRemoveModuleAccess([]);
    setCheck(false);
    setIsEqual(false);
  }

  const handleApprove = (data: any) => {
    try {
      if (data?.id) approveInstitue({ variables: { instituteId: data?.id } });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleInstituteEdit = (data: any) => {
    editInstituteModal(data);
  };

  const handleInstituteModule = (data: any) => {
    instituteModulesModal(data);
  };

  const handleChildEdit = (child: any, group: any) => {
    //Need parent also so setting parent
    seletedGroupData({ label: group.name, value: group.id });
    editInstituteModal(child);
  };

  const handleModuleEdit = (child: any, group: any) => {
    //Need parent also so setting parent
    seletedGroupData({ label: group.name, value: group.id });
    instituteModulesModal(child);
  };

  const deleteFinalCall = async () => {
    setCanClick(false);
    let instititeId = editInstituteData?.id;
    try {
      let deleteResponse = await deleteInstitute({ variables: { id: instititeId } });
      if (deleteResponse?.data?.deleteInstitute?.status === 'success') {
        setDeleteOverlay(false);
        setAlertDetails({ message: DELETE_SUCCESS.INSTITUTE, level: SUCCESS });
        handleRefetch();
      } else {
        throw new Error(deleteResponse?.data?.deleteInstitute?.message || t('error.deleteInstitute.message'));
      }
    } catch (error: any) {
      setAlertDetails({ message: error.message, level: ERROR });
      setDeleteOverlay(false);
    }
    setCanClick(true);
  };

  const handleInstituteDelete = async (instituteData: any) => {
    setInstituteData(instituteData);
    setIsChildInstitute(false);
    setDeleteOverlay(true);
  };

  const handleChildDelete = async (child: any, group: any) => {
    setInstituteData(child);
    setIsChildInstitute(true);
    await setGroupId(group?.id);
    setDeleteOverlay(true);
  };

  function handleAddInstitueForGroup(groupData: any) {
    seletedGroupData(groupData);
    setAddForGroup(true);
    setIsEditModal(false);
    reset({});
    hideAlert();
    handleAddEditModalState(true);
  }

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  function createSchoolCollegeObject(formData: any, instituteType: string, universityData?: any) {
    let instituteObject = {} as any;
    let type = instituteType === INSTITUTEGROUP ? 'ig_' : '';

    let payload = {
      domain: formData[`${type}domain`],
      email: formData[`${type}email`],
      firstName: formData[`${type}firstName`],
      lastName: formData[`${type}lastName`],
      mobileNumber: parseFloat(formData[`${type}mobileNumber`]),
      name: formData[`${type}name`],
      website: formData[`${type}website`],
      type: formData.type.value,
      subDomain: 'temporray',
      udise: formData[`${type}udiseId`],
      role: formData[`${type}role`],
    } as any;

    if (isEditModal && editInstituteData?.id) {
      payload['id'] = editInstituteData?.id;
    }

    if (instituteType !== INSTITUTEGROUP) {
      if (formData.parent?.value) {
        payload['parent'] = formData.parent?.value;
      }
      payload['courses'] = selectedCourses.map((item: any) => {
        const courseId = item?.audience?.split('/').pop();
        return courseId;
      })
    }

    if (formData[`${type}description`]) {
      payload['description'] = formData[`${type}description`];
    }

    let addressPayload = {
      city: formData[`${type}location`],
    } as any;

    if (isEditModal) {
      addressPayload['id'] = editInstituteData?.addressDetails?.id;
    }

    instituteObject['payload'] = payload;
    instituteObject['payload']['address'] = addressPayload;
    return instituteObject;
  }

  const handleModuleAccessUpdate = async () => {
    setCanClick(false);
    try {
      let result;
      if (addModuleAccess.length > 0) {
        result = await addModuleAccessQ({ variables: { institute: editInstituteData.id, modules: addModuleAccess } })
      }
      if (removeModuleAccess.length > 0) {
        result = await removeModuleAccessQ({ variables: { institute: editInstituteData.id, modules: removeModuleAccess } })
      }
      closeModal();
      if (result?.data) {
        setAlertDetails({ message: UPDATE_SUCCESS.INSTITUTE_MODULE, level: SUCCESS });
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
    setCanClick(true);
  }

  const handleAddInstitue = async (formData: any, universityData?: any) => {
    setCanClick(false);
    isSubmitting.current = true;
    try {
      let instituteType = formData.type?.value;
      let payloadData = createSchoolCollegeObject(formData, instituteType, universityData);
      if (payloadData?.payload?.parent) {
        setGroupId(payloadData?.payload?.parent);
      }

      let addInstituteResponse = await addInstitue({ variables: payloadData });
      if (addInstituteResponse.data && !ErrorOccur) {
        closeModal();
        setGroupId('');
        setAlertDetails({ message: ADD_SUCCESS.INSTITUTE, level: SUCCESS });
        handleRefetch();
      } else {
        setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
    isSubmitting.current = false;
    setCanClick(true);
  };

  const handleUpdateInstitue = async (formData: any) => {
    setCanClick(false);
    try {
      let instituteType = formData.type?.value;
      let payloadData = createSchoolCollegeObject(formData, instituteType);
      if (payloadData?.payload?.parent) {
        setGroupId(payloadData?.payload?.parent);
      }
      let updateInstituteResponse = await updateInstitute({
        variables: payloadData,
        refetchQueries: [{
          query: getInstitutesQuery,
          variables: {
            limit: rowLimit,
            skip: 0
          }
        }]
      });
      if (updateInstituteResponse.data) {
        closeModal();
        setGroupId('');
        setAlertDetails({ message: UPDATE_SUCCESS.INSTITUTE, level: SUCCESS });
        handleRefetch();
      } else {
        setAlertDetails({ message: updateInstituteResponse.data.message, level: ERROR });
      }
    } catch (error) {
      setAlertDetails({ message: error.message, level: ERROR });
    }
    setCanClick(true);
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.INSTITUTE]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchCourses(course: string) {
    let isClear = course?.length === 0;
    persistSearch(!isClear, course);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchCourses(q), SEARCH_TIME),
    [],
  );
  const handleSearch = (course: string) => {
    setSearchingInstitute(course);
    delayedQuery(course);
  };
  const disableSubmit = (data: any, newData: any) => {
    if (data?.length > 0 && newData.length > 0) {
      const areEqual = (data === newData) || (
        data.length === newData.length && (data.every((item: any) => newData.includes(item)))
      );
      setIsEqual(areEqual);
      !check && setCheck(true);
    }
  }

  const handleFirstFormSubmit = (formData: any) => {
    if (isTypeGroup) {
      if (isEditModal) {
        handleUpdateInstitue(formData);
      } else {
        handleAddInstitue(formData);
      }
    } else {
      setData(formData);
      if (isEditModal) {
        editInstituteModal(editInstituteData);
      }
      handleCourseSelectionModalState(true)
    }
  }

  const handleSelectInstituteCourses = (formData: any) => {
    if (selectedCourses && selectedCourses?.length > 0) {
      if (isEditModal) {
        handleUpdateInstitue(data);
      } else {
        handleAddInstitue(data, formData);
      }
    } else {
      setAlertDetails({
        message: t('error.course.required'),
        level: ERROR
      })
    }
  }

  return (
    <View>
      <LoadContentWrapper>
        <View style={styles.flexRow}>
          <View style={styles.leftPart}>
            <Search
              id="instititeSearch"
              value={searching}
              handleChange={handleSearch}
              label={t('searchInstitutes.text')}
            />
          </View>

          <View style={styles.rightPart}>
            <SecondaryBtn
              label={t('addInstitute.text')}
              onPress={triggerAddInstituteModal}
              lineHeight={2}
            />
          </View>
        </View>
        <div style={{ height: height - 314, overflowX: 'auto' }}>
          <InstituteTable
            data={row}
            handleApprove={handleApprove}
            handleEdit={handleInstituteEdit}
            handleModule={handleInstituteModule}
            handleChildEdit={handleChildEdit}
            handleDelete={handleInstituteDelete}
            handleAdd={handleAddInstitueForGroup}
            handleChildDelete={handleChildDelete}
            handleModuleEdit={handleModuleEdit}
          />
        </div>

        <Pagination
          pathName={'institutes'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          hidePagination={!!(totalCount && totalCount <= rowLimit)}
        />
      </LoadContentWrapper>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={() => setDeleteOverlay(false)}
        modalVisible={deleteOverlay}
        handleSave={deleteFinalCall}
        Headerpopup="deleteInstitute.text">
        {t('deleteInstitute.warning')}
      </DeleteModal>

      {/* initial first form new */}
      <NormalModal
        setModalVisible={closeAddEditInstituteModal}
        modalVisible={addEditModalState}
        handleSave={handleSubmit(handleFirstFormSubmit)}
        onAlert={null}
        Headerpopup={isEditModal ? t('form.editInstitute.text') : t('addInstitute.text')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isTypeGroup ? 'save.label' : 'next.label'}
        maxWidth={'lg'}
        height={750}>
        <AddEditInstituteForm
          control={control}
          errors={errors}
          reset={reset}
          clearErrors={clearErrors}
          setError={setError}
          trigger={trigger}
          setValue={setValue}
          isEditModal={isEditModal}
          editInstituteData={editInstituteData}
          setIsTypeGroup={setIsTypeGroup}
          addForGroup={addForGroup}
          groupData={groupData}
          isSubmitting={isSubmitting}
        />
      </NormalModal>

      {/* new courses selection form */}
      <NormalModal
        setModalVisible={closeCourseSelectionForm}
        modalVisible={courseSelectionModalState}
        handleSave={handleCourseFormSubmit(handleSelectInstituteCourses)}
        onAlert={null}
        Headerpopup={isEditModal ? t('form.editInstitute.text') : t('addInstitute.text')}
        cancelButtonLabel="previous.label"
        addEditButtonLabel="save.label"
        maxWidth={'lg'}
        height={750}>
        <SelectInstituteCourses
          control={courseFormControl}
          errors={courseFormError}
          reset={resetCourseForm}
          setValue={setCourseFormValue}
          isEditModal={isEditModal}
          isTypeGroup={isTypeGroup}
          selectedCourses={selectedCourses}
          setSelectedCourses={setSelectedCourses}
          getValues={getCourseFormValues}
        />
      </NormalModal>

      {isModuleModal && (
        <NormalModal
          isSubmitting={!canClick}
          onAlert={null}
          setModalVisible={closeModal}
          modalVisible={isModuleModal}
          handleSave={handleSubmit(handleModuleAccessUpdate)}
          Headerpopup={t('allowed.modules.text')}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel={'save.label'}
          disabled={isEqual && check ? true : false}
          maxWidth={'lg'}>
          <ModulesList
            allModules={allModules}
            visible={isModuleModal}
            institute={editInstituteData}
            addModuleAccess={addModuleAccess}
            removeModuleAccess={removeModuleAccess}
            setAddModuleAccess={setAddModuleAccess}
            setRemoveModuleAccess={setRemoveModuleAccess}
            disableSubmit={disableSubmit}
          />
        </NormalModal>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  rightPart: {
    flex: 3,
    alignItems: 'flex-end',
  },
  tableAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  iconView: { marginRight: 20 },
});
