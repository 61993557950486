import { AUTOCOMPLETE_MODULE, limit } from 'src/constant';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { graphqlQuery } from 'src/graphql/util';
import { mergeBatchClassLabel } from 'src/utils/utility';
import selectionForm from 'src/form-json/students/batch-division-selection.json';
import { useForm } from 'react-hook-form';
import { useHistory } from 'src/routes/routing.web';
import { useI18n } from 'src/i18n/hooks';

interface BatchDivisionSelectionFormProps {
  retrieveValues: any;
  dataLimit?: number;
  pagePath: string;
  urlBatchId?: string;
  urlDivision?: string;
  setParentBatchId: any;
  setParentDivision: any;
  isLoading: boolean;
  searchDataAvailable?: boolean;
}

export default function BatchDivisionSelectionForm(props: BatchDivisionSelectionFormProps) {
  const { rem }: any = useTheme();
  const [elements, setElements] = useState<any>({});
  const [filteredBatchObject, setFilteredBatchObject] = useState<any>({});
  const [batchId, setBatchId] = useState<string>('');
  const {
    retrieveValues,
    dataLimit = limit,
    pagePath,
    urlBatchId = '',
    urlDivision = '',
    setParentBatchId,
    setParentDivision,
    isLoading,
    searchDataAvailable,
  } = props;
  const { fields }: any = elements;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const isParentStudents = Boolean(pagePath && pagePath === 'students');

  useEffect(() => {
    setValue('batch', null);
    setValue('division', null);
    onLoadFunction();
  }, []);

  useEffect(() => {
    if (Object.keys(filteredBatchObject).length != 0) {
      //  set value for batch and AUTOCOMPLETE_MODULE i.e class alias
      setValue('batch', {
        label: `${filteredBatchObject?.label} / ${filteredBatchObject?.class?.alias}`,
        value: filteredBatchObject?.value,
      });
      setBatchId(filteredBatchObject.value);

      const batchDivisions = filteredBatchObject?.divisionDetails?.map(det => det.division);
      // set other available option values too inside division form
      getDivision(batchDivisions);
      // set current value for division
      if (urlDivision) {
        setValue('division', { label: urlDivision, value: urlDivision });
        setParentDivision(urlDivision);
      }

      // set state based on url params
      setParentBatchId(filteredBatchObject.value);
    }
  }, [filteredBatchObject]);

  useEffect(() => {
    if (!searchDataAvailable) {
      resetFormAndUrl();
    }
  }, [searchDataAvailable]);

  function filterBatchByValue(arrayOfObject: any[], searchTerm: string) {
    // function that returns the entire object on which passed search Term exactly matches
    var result = arrayOfObject.filter(function (v, i) {
      return v.value.indexOf(searchTerm) >= 0;
    });
    if (result.length != 0) {
      // batch id from url is valid i.e filtered result obj is not empty
      setFilteredBatchObject(result[0]);
    } else {
      // invalid batch from url
      resetFormAndUrl();
    }
  }

  async function onLoadFunction() {
    let { data: allBatches, loading } = await graphqlQuery(getBatchQuery, { limit: 0 });
    if (!loading && allBatches?.batches?.data) {
      let options = mergeBatchClassLabel(allBatches?.batches?.data);
      setElements({});
      selectionForm[0].fields[0].option = options;
      setElements(selectionForm[0]);

      // if batch id is present in url (passed from parent) then
      // filter out batch object with matching id value
      if (urlBatchId) {
        filterBatchByValue(allBatches?.batches?.data, urlBatchId);
      }
    }
  }

  async function getDivision(divisions: string[]) {
    let options = [] as any;
    divisions.forEach(function (item: any) {
      options.push({
        value: item.toString(),
        label: item.toString(),
      });
    });
    setElements({});
    selectionForm[0].fields[1].option = options;
    setElements(selectionForm[0]);
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      setValue(AUTOCOMPLETE_MODULE.DIVISION, null);
      if (data) {
        getDivision(data?.divisionDetails.map(det => det.division));
        setBatchId(data?.value);
      } else {
        setBatchId('');
        selectionForm[0].fields[1].option = [];
        resetFormAndUrl();
      }
      clearErrors('division');
    }

    if (id === AUTOCOMPLETE_MODULE.DIVISION) {
      if (!data) history.replace(`/${pagePath}/${batchId}/limit/${dataLimit}/page/1`);
    }
  }

  function resetFormAndUrl() {
    // if invalid result --> reset form and url
    setValue('batch', null);
    setValue('division', null);
    history.replace(`/${pagePath}/limit/${dataLimit}/page/1`);
  }

  return fields ? (
    <ResultSelectionFormWrapper>
      {fields &&
        fields.map((field: any, i: number) => {
          return (
            <FormWrapper key={`s${i}`} style={{ width: i == 0 ? rem(36) : rem(16) }}>
              <Element
                key={`selection${i}`}
                field={field}
                control={control}
                errors={errors}
                handleCustomSelect={handleCustomSelect}
                dynamicValidation={false}
                isCustomDisable={false}
                setError={setError}
                clearErrors={clearErrors}
                dropdownInputHeight={21}
              />
            </FormWrapper>
          );
        })}

      <SecondaryBtn
        canLoad={isLoading}
        label={t('search.label')}
        onPress={handleSubmit(retrieveValues)}
        width={rem(7.9)}
        style={{ height: rem(3.6), marginTop: rem(2) }}
        secondary={false}
        customDisabled={Boolean(!searchDataAvailable || !batchId)}
      />
    </ResultSelectionFormWrapper>
  ) : (
    <FormSpinnerView>
      <LoaderSpinner />
    </FormSpinnerView>
  );
}

const ResultSelectionFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FormWrapper = styled.div`
  flex-direction: row;
  z-index: 50;
  width: ${props => props.theme.rem(25)};
  margin-right: ${props => props.theme.rem(2.4)};
`;

const FormSpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.theme.rem(75)};
  height: 81.4px;
`;
