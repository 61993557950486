import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { height, isIOS, isNative, isWeb, width } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { breakpoints, colors, fonts, mobileWeb } from 'src/styles/theme/styles';
import { StyleSheet, View, Text, BackHandler } from 'react-native';
import {
  useLocation,
  useHistory,
  Route,
  Switch,
  useRouteMatch,
  Redirect,
} from 'src/routes/routing';
import { useHeaderTitle } from 'src/contexts/header-context';
import Search from 'src/components/atoms/SearchBar/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { debounce } from 'lodash';
import { TableCells, TableDiv, TableRows } from 'src/components/molecules/Table/TableAtom';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import useTable from 'src/components/molecules/Table/UseTable';
import { TableBody } from '@mui/material';
import {
  AUDIENCE_TYPE,
  EMPLOYEE_TYPES,
  ERROR,
  limit,
  MESSAGE_LIMIT,
  SUCCESS,
  USER_TYPE,
} from 'src/constant';
import AudienceWrapper from 'src/components/molecules/Audience/AudienceWrapper';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useForm } from 'react-hook-form';
import MessageComposer from 'src/components/molecules/Message/MessageComposer';
import { userInformation } from 'src/utils/manageState';
import MobileHeader from 'src/components/hoc/MobileHeader';
import SwipeSwitch from 'src/components/atoms/Switch/Swipe';
import navigationStrings from 'src/navigation/navigationStrings';
import Listing from 'src/components/molecules/CommunicationListing';
import MessageCard from 'src/components/molecules/Message/MessageCard';
import {
  inboxMessagesQuery,
  sentMessagesQuery,
  useCreateMessage,
} from '../../../graphql/messages';
import { ADD_SUCCESS } from 'src/constant/message';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import MessageModalContent from 'src/components/molecules/Message/MessageModalContent';
import { showNativeError, showNativeSuccess } from 'src/components/molecules/NotificationWrapper';
import { DT } from 'src/constant/dateTime';
import { format } from 'date-fns';
import {  useParams } from 'react-router';


const maxHeight = 440;
const headCells = [
  {
    id: 'from',
    label: 'from.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'subject',
    label: 'subject.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'timestamp',
    label: 'timestamp.label',
    align: 'right',
    style: {
      paddingRight: 90
    },
    disableSorting: true,
  },
];

const headCells1 = [
  {
    id: 'to',
    label: 'to.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'subject',
    label: 'subject.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'timestamp',
    label: 'timestamp.label',
    align: 'right',
    style: {
      paddingRight: 90
    },
    disableSorting: true,
  },
];

export default function Messages(props: any) {

  const { t } = useI18n();
  const location = isWeb ? useLocation() : '';
  const isInbox = isWeb ? location && location.pathname.includes('inbox') : false;
  const { setHeading } = useHeaderTitle();
  const history = useHistory();
  const match = isWeb ? useRouteMatch() : '';
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState<any>(1);
  const [rowLimit, setRowLimit] = useState(limit);
  const [canClick, setCanClick] = useState<boolean>(true);
  const { alertState, setAlertDetails } = useAlertSystem();
  const [formModal, setFormModal] = useState(false);
  const [audienceType, setAudienceType] = useState(null);
  const currentUserObject = userInformation();
  const { userType, userDetail } = currentUserObject;
  const isInstituteAdmin = userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN);
  const [communicationTab, setSelectTab] = useState<string>('inbox');
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [inboxSkip, setInboxSkip] = useState<number>(0);
  const [sentSkip, setSentSkip] = useState<number>(0);
  const [refreshing, setRefreshing] = React.useState(false);
  const [locationKeys, setLocationKeys] = useState([]);
  const [messageOverlay, setOverlay] = useState(false);
  const [tab, setTab] = useState<number>(1);
  const [type, setType] = useState('');
  const [id, setId] = useState();
  let staticParam = {
    params: {
      messageType: '',
      messageId: ''
    },
  };

  const { params } = isNative ? props?.route : staticParam;
  const { messageType, messageId } = params;
  const messageTab = messageType === 'inbox' ? 1 : 2;
  const inboxRef = useRef<any>(null);
  const sentRef = useRef<any>(null);
  const { createMessage, createMessageError } = useCreateMessage();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const {
    loading: inboxMessagesLoading,
    data: inbox,
    refetch: inboxMessagesRefetch,
    fetchMore: fetchInboxMessagesMore,
  } = useQuery(inboxMessagesQuery, {
    variables: { limit: isWeb ? rowLimit : MESSAGE_LIMIT, skip: 0 },
  });

  const {
    loading: sentMessagesLoading,
    data: sent,
    refetch: sentMessagesRefetch,
    fetchMore: fetchSentMessagesMore,
  } = useQuery(sentMessagesQuery, {
    variables: { limit: isWeb ? rowLimit : MESSAGE_LIMIT, skip: 0 },
  });

  const inboxMessagesData = inbox?.inboxMessages?.data;
  const sentMessagesData = sent?.sentMessages?.data;

  const [totalInboxMessages, setTotalInboxMessages] = useState<number|null>(inbox?.inboxMessages?.totalCount ?? null)
  const [totalSentMessages, setTotalSentMessages] = useState<number|null>(sent?.sentMessages?.totalCount ?? null)

  useEffect(() => {
    const inboxMessagesCount = inbox?.inboxMessages?.totalCount;
    if(inboxMessagesCount){
      setTotalInboxMessages(inboxMessagesCount);
    }
  }, [inbox?.inboxMessages?.totalCount]);

  useEffect(() => {
    const sentMessagesCount = sent?.sentMessages?.totalCount;
    if(sentMessagesCount){
      setTotalSentMessages(sentMessagesCount);
    }
  }, [sent?.sentMessages?.totalCount]);

  useEffect(() => {
    if (location?.pathname?.includes('sent')) {
      setTab(2);
      const num = location?.pathname?.split("/").pop()
      setPage(num);
    }
    
    if (location?.pathname?.includes('inbox')) {
      setTab(1);
      const num = location?.pathname?.split("/").pop()
      setPage(num);
    }
    setTitle();
    BackHandler.addEventListener('hardwareBackPress', backActionHandler);
    return () => BackHandler.removeEventListener('hardwareBackPress', backActionHandler);
  }, []);

  useEffect(() => {
    if (isWeb) {
      handleChangeTabs();
    }
  }, [tab]);

  useEffect(() => {
    handleFetch();
  }, [page, locationKeys, isInbox, rowLimit]);

  useEffect(() => {
    if (isWeb) {
      return history.listen(location => {
        if (history.action === 'POP') {
          setPage(parseInt(history.location.pathname.match(/\d+$/)[0], 10));
        }
      });
    }
  }, [locationKeys]);

  useEffect(() => {
    if (isWeb && 
      location.pathname.includes('messages') && location?.state?.messageType && location?.state?.messageId) {
      setType(location?.state?.messageType);
      setId(location?.state?.messageId);
      setOverlay(true);
    }
  }, [location]);

  function setTitle() {
    if (isWeb) {
      setHeading([
        {
          text: t('messages.label'),
          url: '',
        },
      ]);
    }
  }

  const backActionHandler = () => {
    if (formModal || messageOverlay) {
      closeModal();
    } else {
    }
    return true;
  };

  function handleChangeTabs() {
    if (tab === 1) {
      history.push(`/messages/inbox/limit/${rowLimit}/page/${page}`);
    } else {
      history.push(`/messages/sent/limit/${rowLimit}/page/${page}`);
    }
  }

  function handleFetch() {
    if (isWeb) {
      if (isInbox) {
        handlePagination(inboxMessagesQuery, rowLimit, (page - 1) * rowLimit, fetchInboxMessagesMore, 1);
      } else {
        handlePagination(sentMessagesQuery, rowLimit, (page - 1) * rowLimit, fetchSentMessagesMore, 2);
      }
    }
  }

  async function handleSearch(searchText: string, type: number) {}

  function closeModal() {
    clearErrors();
    setTimeout(() => {
      resetForm();
    }, 1000);
    setFormModal(false);
  }

  function resetForm() {
    setValue('subject', '');
    setValue('message', '');
  }

  const debouncedSearch = useCallback(debounce(handleSearch, 500), []);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = isWeb ? (
    useTable(inboxMessagesData, headCells, maxHeight, totalInboxMessages, page)
  ) : (
    <></>
  );

  const {
    TblContainer: TblContainerSent,
    TblHead: TblHeadSent,
    recordsAfterPagingAndSorting: recordsAfterPagingAndSortingSent,
  } = isWeb ? useTable(sentMessagesData, headCells1, maxHeight, totalSentMessages, page) : <></>;

  function changeTabs(tab: any) {
    setPage(1);
    setTab(tab);
  }

  function handleSwitching(t: number) {
    let passParam = t === 1 ? 'inbox' : 'sent';
    setSelectTab(passParam);
    props.navigation.navigate(navigationStrings.MESSAGES, {
      messageType: passParam,
    });
  }

  function showSuccess(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: SUCCESS });
    } else {
      showNativeSuccess(message);
    }
  }

  function showError(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: ERROR });
    } else {
      showNativeError(message);
    }
  }

  async function handleCreateMessage(formData: any, audience: any) {
    setCanClick(false);
    try {
      const payload = {
        body: formData?.message,
        receiverType: audienceType,
        receivers: audience,
        subject: formData?.subject,
      };
      const messageResponse = await createMessage({
        variables: { payload: payload },
        refetchQueries: [
          {
            query: inboxMessagesQuery,
            variables: {
              limit,
              skip: 0,
            },
          },
        ],
      });
      if (messageResponse?.data?.createMessage) {
        setCanClick(true);
        closeModal();
        await refetchMessage();
        showSuccess(ADD_SUCCESS.MESSAGE_SENT);
      }
    } catch (error: any) {
      showError(error.message);
      setCanClick(true);
    }
  }

  function handlePagination(
    paginateQuery: any,
    messageLimit: number,
    messageSkip: number,
    fetchFunction: any,
    type: number,
  ) {
    let messageFilter = { limit: messageLimit, skip: messageSkip };

    try {
      fetchFunction({
        query: paginateQuery,
        variables: messageFilter,
        updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
          const previousResultData =
            type == 1 ? previousResult?.inboxMessages?.data : previousResult?.sentMessages?.data;
          const data = type === 1 ? fetchMoreResult?.inboxMessages : fetchMoreResult?.sentMessages;
          setFetchMoreLoading(data?.data ? false : true);
          return type === 1 ? {
            inboxMessages: {
              hasNextPage: data?.hasNextPage,
              data: isWeb ? data.data : [...previousResultData, ...data.data],
              totalCount: data.totalCount,
              __typename: data.__typename,
            },
          } : {
            sentMessages: {
              hasNextPage: data?.hasNextPage,
              data: isWeb ? data.data : [...previousResultData, ...data.data],
              totalCount: data.totalCount,
              __typename: data.__typename,
            },
          };
        },
      });
    } catch (error: any) {
      console.log(error.message);
    }
  }

  function handleInboxPagination() {
    if (totalInboxMessages > inboxMessagesData?.length) {
      setFetchMoreLoading(true);
      handlePagination(
        inboxMessagesQuery,
        MESSAGE_LIMIT,
        inboxSkip + MESSAGE_LIMIT,
        fetchInboxMessagesMore,
        1,
      );
      setInboxSkip(inboxSkip + MESSAGE_LIMIT);
    }
  }

  function handleSentMessagesPagination() {
    if (totalSentMessages > sentMessagesData?.length) {
      setFetchMoreLoading(true);
      handlePagination(
        sentMessagesQuery,
        MESSAGE_LIMIT,
        sentSkip + MESSAGE_LIMIT,
        fetchSentMessagesMore,
        2,
      );
      setInboxSkip(sentSkip + MESSAGE_LIMIT);
    }
  }

  function onRefresh() {
    setRefreshing(true);
    nativeRefresh();
  }

  async function nativeRefresh() {
    let messageFilter = {
      limit: MESSAGE_LIMIT,
      skip: 0,
    };

    if (communicationTab === 'inbox') {
      inboxMessagesRefetch(messageFilter);
    } else {
      sentMessagesRefetch(messageFilter);
    }
    setRefreshing(false);
  }

  async function refetchMessage() {
    await sentMessagesRefetch({
      limit: isWeb ? rowLimit : MESSAGE_LIMIT,
      skip: 0,
    });
  }

  function showMessageDetail(messageData: any) {
    if (!isWeb) {
      props.navigation.navigate(navigationStrings.MESSAGES, {
        messageType: messageType,
        messageId: messageData.id,
      });
    } else {
      const type = isInbox ? 'inbox' : 'sent';
      setType(type);
      setId(messageData?.id);
    }
    setOverlay(true);
  }

  return (
    <Fragment>
      {isWeb && width > breakpoints.md ? (
        <LoadContentWrapper>
          <ModalTabs
            onSelectSwitch={t => {
              changeTabs(t);
            }}
            option1={t('inbox.label')}
            option2={t('sent.label')}
            selectionMode={isInbox ? 1 : 2}
            roundCorner={false}
            style={[styles.tabStyle]}
            onChange={changeTabs}
            tabswidth={100}
          />

          <Switch>
            <Route path={`${match.url}/inbox/limit/${rowLimit}/page/${page}`}>
              <>
                <View style={styles.flexRow}>
                  <View style={styles.leftPart}>
                    <Search
                      id="Search"
                      handleChange={async e => {
                        setSearchText(e);
                        await debouncedSearch(e, 1);
                      }}
                      value={searchText}
                      label={t('search.label')}
                    />
                  </View>
                  {userType === USER_TYPE.EMPLOYEE && isInstituteAdmin && (
                    <View style={styles.rightPart}>
                      <SecondaryBtn
                        label={t('newMessage.text')}
                        onPress={() => setFormModal(true)}
                        secondary={false}
                      />
                    </View>
                  )}
                </View>

                <View>
                  <TableDiv>
                    <TblContainer height={height - 350}>
                      {inboxMessagesLoading ? (
                        <View style={styles.spinnerView}>
                          <LoaderSpinner />
                        </View>
                      ) : (
                        <Fragment>
                          <TblHead />
                          {recordsAfterPagingAndSorting() &&
                            recordsAfterPagingAndSorting()?.length > 0 && (
                              <TableBody>
                                {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                                  <TableRows key={i} onClick={() => showMessageDetail(item)}>
                                    <TableCells
                                      value={item?.sender?.userName}
                                      style={{ cursor: 'pointer', width: '35%' }}
                                      textStyle={{ cursor: 'pointer' }}
                                    />
                                    <TableCells
                                      value={item?.subject}
                                      style={{ cursor: 'pointer', width: '35%' }}
                                      textStyle={{ cursor: 'pointer' }}
                                    />
                                    <TableCells
                                      value={format(
                                        new Date(item?.createdAt),
                                        DT.DATE_TIME_FORMAT_12_HOUR,
                                      )}
                                      align="right"
                                      style={{ cursor: 'pointer' }}
                                      textStyle={{ cursor: 'pointer' }}
                                    />
                                  </TableRows>
                                ))}
                              </TableBody>
                            )}
                        </Fragment>
                      )}
                    </TblContainer>
                    <Pagination
                      pathName={'messages/inbox'}
                      total={totalInboxMessages}
                      page={page}
                      rowLimit={rowLimit}
                      setRowLimit={setRowLimit}
                      hidePagination={totalInboxMessages <= rowLimit}
                      setCurrentPage={setPage}
                      hideRowsPerOptions
                    />
                  </TableDiv>
                </View>
                {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length === 0 && (
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      bottom: height - 568,
                    }}>
                    <Text style={{ fontFamily: fonts.regular }}>No records found</Text>
                  </View>
                )}
              </>
            </Route>

            <Route path={`${match.url}/sent/limit/${rowLimit}/page/${page}`}>
              <>
                <View style={styles.flexRow}>
                  <View style={styles.leftPart}>
                    <Search
                      id="Search"
                      handleChange={async e => {
                        setSearchText(e);
                        await debouncedSearch(e, 1);
                      }}
                      value={searchText}
                      label={t('search.label')}
                    />
                  </View>
                </View>

                <View>
                  <TableDiv>
                    <TblContainerSent height={height - 350}>
                      {sentMessagesLoading ? (
                        <View style={styles.spinnerView}>
                          <LoaderSpinner />
                        </View>
                      ) : (
                        <Fragment>
                          <TblHeadSent />
                          {recordsAfterPagingAndSortingSent() &&
                            recordsAfterPagingAndSortingSent()?.length > 0 && (
                              <TableBody>
                                {recordsAfterPagingAndSortingSent()?.map((item: any, i: number) => (
                                  <TableRows key={i} onClick={() => showMessageDetail(item)}>
                                    <TableCells
                                      value={item?.audienceLabels.map(
                                        (label: string, index: number) => {
                                          return (
                                            label?.label +
                                            (index !== item?.audienceLabels.length - 1 ? ', ' : '')
                                          );
                                        },
                                      )}
                                      style={{ cursor: 'pointer', width: '35%' }}
                                      textStyle={{ cursor: 'pointer' }}
                                    />
                                    <TableCells
                                      value={item?.subject}
                                      style={{ cursor: 'pointer', width: '35%' }}
                                      textStyle={{ cursor: 'pointer' }}
                                    />
                                    <TableCells
                                      value={format(
                                        new Date(item?.createdAt),
                                        DT.DATE_TIME_FORMAT_12_HOUR,
                                      )}
                                      align="right"
                                      style={{ cursor: 'pointer' }}
                                      textStyle={{ cursor: 'pointer' }}
                                    />
                                  </TableRows>
                                ))}
                              </TableBody>
                            )}
                        </Fragment>
                      )}
                    </TblContainerSent>
                    <Pagination
                      pathName={'messages/sent'}
                      total={totalSentMessages}
                      page={page}
                      rowLimit={rowLimit}
                      hidePagination={totalSentMessages <= rowLimit}
                      setCurrentPage={setPage}
                      hideRowsPerOptions
                    />
                  </TableDiv>
                </View>
                {recordsAfterPagingAndSortingSent() &&
                  recordsAfterPagingAndSortingSent()?.length === 0 && (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        bottom: height - 568,
                      }}>
                      <Text style={{ fontFamily: fonts.regular }}>No records found</Text>
                    </View>
                  )}
              </>
            </Route>

            <Route exact path={`${match.url}`}>
              <Redirect to={`${match.url}/inbox/limit/${rowLimit}/page/${page}`} />
            </Route>
          </Switch>
        </LoadContentWrapper>
      ) : (
        <Fragment>
          <MobileHeader
            label={t('messages.label')}
            backIcon={false}
            rightIcon={userType === USER_TYPE.EMPLOYEE && isInstituteAdmin}
            rightIconName={'compose'}
            showSave={communicationTab === 'inbox' && true}
            handleSave={() => setFormModal(true)}
            {...props}
          />

          {!inboxMessagesLoading && !sentMessagesLoading ? (
            <View style={[styles.webWrapper]}>
              <View style={[styles.switchView]}>
                <SwipeSwitch
                  onSelectSwitch={isWeb ? t => changeTabs(t) : t => handleSwitching(t)}
                  option1={t('inbox.label')}
                  option2={t('sent.label')}
                  selectionMode={isWeb ? (isInbox ? 1 : 2) : messageTab}
                  onChange={isWeb ? t => changeTabs(t) : t => handleSwitching(t)}
                />
              </View>

              {isNative && (
                <View>
                  {messageType === 'inbox' && (
                    <Listing
                      estimatedItemSize={128}
                      passRef={inboxRef}
                      onEndReached={handleInboxPagination}
                      onEndReachedThreshold={0.5}
                      Comp={MessageCard}
                      arrayList={inboxMessagesData}
                      icon={'message'}
                      iconType={'custom'}
                      label={t('noMessages.text')}
                      onPress={showMessageDetail}
                      onRefresh={onRefresh}
                      refreshing={refreshing}
                      fetchMoreLoading={fetchMoreLoading}
                      heightOffset={isIOS ? 182 : 120}
                    />
                  )}

                  {messageType === 'sent' && (
                    <Listing
                      estimatedItemSize={128}
                      passRef={sentRef}
                      onEndReachedThreshold={0.5}
                      onEndReached={handleSentMessagesPagination}
                      Comp={MessageCard}
                      arrayList={sentMessagesData}
                      icon={'message'}
                      iconType={'custom'}
                      label={t('noMessages.text')}
                      onPress={showMessageDetail}
                      onRefresh={onRefresh}
                      refreshing={refreshing}
                      fetchMoreLoading={fetchMoreLoading}
                      heightOffset={isIOS ? 182 : 120}
                    />
                  )}
                </View>
              )}
            </View>
          ) : (
            <View style={[styles.spinnerView]}>
              <LoaderSpinner />
            </View>
          )}
        </Fragment>
      )}

      <AudienceWrapper
        isSubmitting={!canClick}
        onAlert={alertState}
        setModalVisible={closeModal}
        Headerpopup={t('message.label')}
        modalVisible={formModal}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={'next.label'}
        height={480}
        maxWidth={'lg'}
        types={[AUDIENCE_TYPE.BATCH, AUDIENCE_TYPE.GROUP, AUDIENCE_TYPE.STUDENTS]}
        handleSave={handleCreateMessage}
        handleSubmit={handleSubmit}
        setAudienceType={setAudienceType}
        audienceType={audienceType}
        setModal={setFormModal}>
        <MessageComposer
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          setError={setError}
          clearErrors={clearErrors}
        />
      </AudienceWrapper>

      {messageOverlay && (
        <MessageModalContent
          {...props}
          messageType={isWeb ? type : messageType}
          messageId={isWeb ? id : messageId}
          setMessageModal={setOverlay}
          fromMessage={false}
          page={page}
          refetchMessage={refetchMessage}
        />
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  tabStyle: {
    top: 0,
    left: 0,
    backgroundColor: 'white',
    marginBottom: 24,
  },
  flexRow: {
    flexDirection: 'row',
    marginBottom: 8,
  },
  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  rightPart: {
    flex: 3,
    alignItems: 'flex-end',
  },
  switchView: {
    marginBottom: 12.5,
    marginHorizontal: 24,
  },
  webWrapper: {
    backgroundColor: colors.white,
    paddingTop: 24,
    paddingBottom: isIOS ? (height > 900 ? 100 : 72) : 62,
    height: isIOS ? height - 55 : height - 62,
  },
  spinnerView: {
    backgroundColor: colors.white,
    height: isWeb ? height - 350 : height,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
