import { DT, parseToDate } from "src/constant/dateTime";

import { DropdownOptions } from "src/types";
import { format } from "date-fns";

type InputData = {
  id: string;
  name: string;
  course: {
    id: string;
    name: string;
  };
  institute: {
    name: string;
  };
};

type TransformedData = DropdownOptions & { options: DropdownOptions[] };

function transformData(data: InputData[]): TransformedData[] {
  const groupedData: Record<string, TransformedData["options"]> = {};

  data.forEach((admission) => {
    const courseId = admission.course.id;
    if (!groupedData[courseId]) {
      groupedData[courseId] = [];
    }
    groupedData[courseId].push({
      label: admission.name,
      value: admission.id,
    });
  });

  return Object.keys(groupedData).map((courseId) => ({
    label:
      data.find((admission) => admission.course.id === courseId)?.course
        ?.name ?? "",
    value: courseId,
    options: groupedData[courseId],
  }));
}

export const admissionLinkHelpers = {
  transformData,
};

// fieldMap
const fieldMap = {
  state: 10,
  subjectGroup: 12,
  dateOfBirth: 14,
  aadhaarBelongsTo: 16,
  siblingGRNumber: 20,
  siblingClass: 21,
};

const aadhaarFields = ["aadhaarBelongsTo", "aadhaarNote"];

const conditionalFields = [
  "DOB",
  "AADHAAR_ID",
  ...aadhaarFields,
];

function checkAndFormatDate(responseDate: string): string {
  if (responseDate) {
    const parsed = parseToDate(responseDate);
    if (parsed) {
      return format(parsed, DT.DATE_FORMAT_SLASH);
    }
  }
  return "";
}

export type PublicApplicationDocType = {
  id: string;
  name: string;
  category: string;
};

export const publicApplicationHelpers = {
  fieldMap,
  conditionalFields,
  checkAndFormatDate,
  aadhaarFields,
};
