import { gql, useMutation } from '@apollo/client';

const updateApplicantionQualificationMutation = gql`
  mutation(
    $payload: UpdateApplicationQualificationInput
    $remove: RemoveApplicationQualificationInput
  ) {
    UpdateApplicationQualification(payload: $payload, remove: $remove) {
      status
      message
    }
  }
`;


export function useUpdateApplicantionQualification() {
  const [updateApplicantQualification, { loading, error: updateApplicantQualificationError }] =
    useMutation(updateApplicantionQualificationMutation);
  return { updateApplicantQualification, updateApplicantQualificationError };
};
