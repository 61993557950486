import { gql, useLazyQuery, useMutation } from '@apollo/client';

export const createMessageQuery = gql`
  mutation ($payload: EdbaMessageInput!) {
    createMessage(payload: $payload) {
      id
      subject
      body
    }
  }
`;

export const replyMessageQuery = gql`
  mutation ($payload: EdbaMessageReplyInput!) {
    replyMessage(payload: $payload) {
      id
      subject
      body
    }
  }
`;

export const getMessageByIdQuery = gql`
  query ($messageId: String!) {
    getMessage(messageId: $messageId) {
      audienceLabels {
        batch
        division
        group
        label
      }
      body
      createdAt
      deletedAt
      id
      receiverType
      receivers
      sender {
        userName
      }
      subject
      updatedAt
    }
  }
`;

export const inboxMessagesQuery = gql`
  query ($limit: Int, $skip: Int, $sort: PEdbaMessageSortInput) {
    inboxMessages(limit: $limit, skip: $skip, sort: $sort) {
      hasNextPage
      totalCount
      data {
        audienceLabels {
          batch
          division
          group
          label
        }
        body
        createdAt
        deletedAt
        id
        receiverType
        receivers
        sender {
          userName
        }
        subject
        updatedAt
      }
    }
  }
`;

export const sentMessagesQuery = gql`
  query ($limit: Int, $skip: Int, $sort: PEdbaMessageSortInput) {
    sentMessages(limit: $limit, skip: $skip, sort: $sort) {
      hasNextPage
      totalCount
      data {
        audienceLabels {
          batch
          division
          group
          label
        }
        body
        createdAt
        deletedAt
        id
        receiverType
        receivers
        sender {
          userName
        }
        subject
        updatedAt
      }
    }
  }
`;

export function useCreateMessage() {
  const [createMessage, { data: createMessageData, error: createMessageError }] =
    useMutation(createMessageQuery);
  return { createMessage, createMessageData, createMessageError };
}

export function useReplyMessage() {
  const [replyMessage, { data: replyMessageData, error: replyMessageError }] =
    useMutation(replyMessageQuery);
  return { replyMessage, replyMessageData, replyMessageError };
}

export function useGetInboxMessages() {
  const [getInboxMessages, { loading, data: inboxMessagesData, error: inboxMessagesError, refetch: refetchInboxMessages }] =
    useLazyQuery(inboxMessagesQuery, { fetchPolicy: 'network-only' });
  return { getInboxMessages, inboxMessagesData, inboxMessagesError, loading, refetchInboxMessages };
}

export function useGetSentMessages() {
  const [getSentMessages, { loading, data: sentMessagesData, error: sentMessagesError, refetch: refetchSentMessages }] =
    useLazyQuery(sentMessagesQuery, { fetchPolicy: 'network-only' });
  return { getSentMessages, sentMessagesData, sentMessagesError, loading, refetchSentMessages };
}