import { TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Input from 'src/components/atoms/Input/input.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import styled from 'styled-components/native';
import { TableCells, TableDiv, TableRows } from './TableAtom';
import useTable from './UseTable';

interface Props {
  maxHeightTable: number;
  header: Array<Object>;
  tabledata: Array<Object>;
  options: Array<Object>;
  setData: any;
  height: number;
}

export default function CasteCategoryTable(props: Props) {
  const { maxHeightTable, header, tabledata, setData, height } = props;
  const { TblContainer, TblHead } = useTable(tabledata, header, null, tabledata);
  const [value, setValue] = useState({});

  const handleChange = (value: any, id: string) => {
    setValue((prev) => ({ ...prev, [id]: parseFloat(value) }));
  };

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      const result = Object.keys(value).flatMap((key) => {
        if (value[key]) {
          return {
            caste: key,
            cutOff: value[key],
          };
        }
        return [];
      });
      setData(result);
    }
  }, [value]);

  useEffect(() => {
    if (tabledata && tabledata?.length > 0 && tabledata[0]?.cutOff) {
      let items = {};
      tabledata.forEach((item: any) => {
        items[item?.id] = parseFloat(item?.cutOff);
      })
      setValue(items);
    }
  }, [tabledata])

  return (
    <TableContainer>
      <TableDiv>
        <TblContainer
          tabledata={tabledata}
          maxHeight={maxHeightTable && maxHeightTable}
          height={height && height}>
          <TblHead />
          {tabledata && tabledata?.length > 0 && (
            <TableBody>
              {tabledata?.map((item: any, i: number) => (
                <TableRows key={i}>
                  <TableCells value={item?.name} />
                  <TableCells>
                    <InputView>
                      <Input
                        placeholder={0}
                        inputProps={{ step: '0.1' }}
                        typeInput={'number'}
                        id={i}
                        value={value[item?.id] || ''}
                        setValue={(e: { target: { value: any; }; }) => {
                          if (e.target.value <= 100) handleChange(e.target.value, item?.id);
                        }}
                        width={150}
                      />
                      <InputPrefix>
                        <NormalTextSelect value={'%'} />
                      </InputPrefix>
                    </InputView>
                  </TableCells>
                </TableRows>
              ))}
            </TableBody>
          )}
          {tabledata && tabledata?.length === 0 && (
            <TableRows style={{ height: 0.5 * height }}>
              <TableCells colspan={3} align="center" style={{ borderBottom: 'none' }}>
                <NormalTextSelect value={'No records found'} />
              </TableCells>
            </TableRows>
          )}
        </TblContainer>
      </TableDiv>
    </TableContainer>
  );
}

const TableContainer = styled.View<{ width: number | string }>`
  overflow: auto;
  width: 100%;
`;

const InputView = styled.View`
  display: flex;
  flex-direction: row;
  padding-left: 280px;
`;

const InputPrefix = styled.View`
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;