import React from 'react';
import { DescriptionCell, HeaderCell, TotalMarksCell } from './GradeCardElements';
import { Table, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import styled from 'styled-components/native';
import { colors, fonts } from 'src/styles/theme/styles';

export default function TableThreeGradeCard(props: { data: any; setAdditionalSubjectRows: any; }) {
  const { data, setAdditionalSubjectRows } = props;
  const subject_row_width: number = 300;
  const marks_grade_row_width: number = 100;

  let subjectWiseMarks = data?.subjectWiseMarks;

  let PartOneHeaders = [
    {
      id: 'subject',
      label: 'SUBJECT',
      colspan: 3
    },
    {
      id: 'marks',
      label: 'MARKS',
      colspan: 1
    },
    {
      id: 'grade',
      label: 'GRADE',
      colspan: 1
    }
  ];

  let rows = [] as any;
  subjectWiseMarks?.forEach(function (item: any) {
    rows.push({
      subject: {
        id: 'subject',
        label: item?.subject?.name,
        colspan: 3
      },
      marks: {
        id: 'marks',
        label: item?.obtained,
        colspan: 1
      },
      grade: {
        id: 'grade',
        label: item?.grade?.name,
        colspan: 1
      }
    });
  });

  let rowDifference: number = 9 - rows?.length;
  let emptyRows = 0;
  if (rowDifference > 0) {
    emptyRows = rowDifference;
  } else if (rowDifference < 0) {
    setAdditionalSubjectRows(rows.length - 9);
  }

  return (
    <TableOneContainer>
      <TableContainer>
        <Table sx={{fontFamily: fonts.regular}}>
          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              {
                PartOneHeaders.map((element) => {
                  return (
                    <HeaderCell width={100 * element?.colspan} value={element?.label} />
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.map((element: any) => {
                return (
                  <TableRow>
                    <DescriptionCell width={subject_row_width} value={element?.subject?.label} />
                    <DescriptionCell width={marks_grade_row_width} value={element?.marks?.label} />
                    <DescriptionCell width={marks_grade_row_width} value={element?.grade?.label} />
                  </TableRow>
                );
              })
            }

            {/* `\u{200B}` is zero-width space */}
            {emptyRows > 0 && Array(emptyRows).fill(0).map((_: any) => {
              return (
                <TableRow>
                  <DescriptionCell width={subject_row_width} value={`\u{200B}`} />
                  <DescriptionCell width={marks_grade_row_width} value={`\u{200B}`} />
                  <DescriptionCell width={marks_grade_row_width} value={`\u{200B}`} />
                </TableRow>
              );
            })
            }

            {/* Total Grade */}
            <TableRow>
              <TotalMarksCell width={subject_row_width} value={"Grade Total & Overall Grade"} />
              <TotalMarksCell width={marks_grade_row_width} value={data?.obtainedMarks} />
              <TotalMarksCell width={marks_grade_row_width} value={data?.grade?.name} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </TableOneContainer>
  );
}

const TableOneContainer = styled.View`
  width: 500px;
 `;