import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ProfileDetailView } from './index';
import OnboardingRoot from 'src/components/organism/onboarding/root';
import navigationStrings from 'src/navigation/navigationStrings';
import { USER_TYPE } from 'src/constant';
import { NativePrivateRoute } from 'src/routes/native-private-route';

const Stack = createStackNavigator();

interface OnBoardingNavigatorProps {
  currentstep: number;
  onFinishCallback: () => void;
}

function OnBoardingNavigator(prop: OnBoardingNavigatorProps) {

  return (
    <Stack.Navigator initialRouteName={navigationStrings.NATIVE_ON_BOARDING}>
      <Stack.Screen
        name={navigationStrings.NATIVE_ON_BOARDING}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}>
        {(props) => {
          let step = props?.route?.params?.step || prop.step;
          if (props?.route?.params?.step === 0) step = 0;
          return (
            <NativePrivateRoute
              component={OnboardingRoot}
              authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
              props={{ ...props, step, onFinishCallback: prop.onFinishCallback }}
            />
          );
        }}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.NATIVE_EDU_EXP_DETAIL}
        initialParams={{ statusbar: 'light-content' }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={ProfileDetailView}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            props={props}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

export default OnBoardingNavigator;
