import { gql, useLazyQuery } from '@apollo/client';

const getCoursesQuery = gql`
  query (
    $limit: Int
    $lookup: LookupFilter
    $skip: Int
    $filters: CourseFilterInput
    $sort: CourseSortInput
  ) {
    courses(limit: $limit, lookup: $lookup, skip: $skip, filters: $filters, sort: $sort) {
      hasNextPage
      totalCount
      data {
        id
        name
        classes {
          id
          name
          alias
          batches{
            id
            name
            alias
            divisionDetails {
              division
              seats
            }
          }
        }
      }
    }
  }
`;

export const removeLectureMutation = gql`
  mutation RemoveSchedule($id: String!, $single: Boolean, $fromDate: Date, $toDate: Date) {
    removeSchedule(scheduleId: $id, single: $single, fromDate: $fromDate, toDate: $toDate)
  }
`
export const updateLectureMutation = gql`
  mutation UpdateSchedule($payload: UpdateScheduleInput!) {
    updateSchedule(payload: $payload) {
      validTill
      detail {
        description
      }
    }
  }
`
export const updateSingleLectureMutation = gql`
  mutation CustomizeSingleLecture($payload:IECustomScheduleInput!) {
    CustomizeSchedule(payload:$payload) {
      date,
      description
    }
  }
`

const getSubjectTeachers = gql`
  query ($filters: InstituteEmployeeFilterInput, $limit: Int) {
    instituteEmployees(filters: $filters, limit: $limit) {
      hasNextPage
      totalCount
      data {
        value: id
        label: fullName
      }
    }
  }
`;

const createNewScheduleMutation = gql`
  mutation ($payload: ScheduleInput!) {
    createSchedule(payload: $payload) {
      id
    }
  }
`;

export {
  getCoursesQuery,
  getSubjectTeachers,
  createNewScheduleMutation,
};

export function useGetCourses() {
  const [getInstituteCourses, { loading, data: coursesData, error: coursesError }] =
    useLazyQuery(getCoursesQuery, { fetchPolicy: 'network-only' });
  return { getInstituteCourses, coursesData, coursesError, loading };
}