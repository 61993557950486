import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { DeleteIcon, EditIcon } from 'src/components/atoms/ActionIcons';
import { ERROR, SUCCESS, limit } from 'src/constant';
import {
  ExpandCollapseCell,
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { TableBody, TableCell, TableRow } from '@mui/material';
import styled, { useTheme } from 'styled-components';
import { useCreateExamDetail, useDeleteExam, useGetExamDetails, useUpdateExamDetail } from 'src/graphql/assessment/assessment-exams';
import { useHistory, useParams } from 'src/routes/routing.web';

import AddEditExamForm from 'src/components/molecules/Assessment/Exams/AddEditExamForm';
import BatchClassSelectionForm from 'src/components/molecules/Assessment/BatchClassSelectionForm';
import ExamsNestedTable from 'src/components/molecules/Assessment/Exams/ExamsNestedTable';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { colors } from 'src/styles/theme/styles';
import { createSortFilter } from 'src/components/services';
import { height } from 'src/constant/device';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useForm } from 'react-hook-form';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import useTable from 'src/components/molecules/Table/UseTable';
import { useThemeSystem } from 'src/contexts/theme-context';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { getCellStyle } from 'src/utils/utility';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

const headCells1 = [
  {
    id: 'examName',
    label: 'exam-name.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'name',
    isSorted: false,
    style: { width: "25%", paddingLeft: '42.73px' }
  },
  {
    id: 'subjects',
    label: 'subjects.label',
    align: 'left',
    disableSorting: true,
    style: { width: "30%" }
  },
  {
    id: 'order',
    label: 'order.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'testNames',
    label: 'test-names.label',
    align: 'left',
    disableSorting: true,
    style: { width: "30%" }
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { width: '5%' }
  }
];

export default function Exams() {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const { rem }: any = useTheme();
  const { setHeading } = useHeaderTitle();
  const { setAlertDetails } = useAlertSystem();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
    clearErrors
  } = useForm();
  const history = useHistory();

  const [headCells, setHeadCells] = useState(headCells1);
  const [selectedBatch, setSelectedBatch] = useState<string>('');
  const [selectedClassId, setSelectedClassId] = useState<string>('');
  const [examFormModalState, setExamFormModalVisible] = useState<boolean>(false);
  const [allFormValuesAvailable, setAllFormValuesAvailable] = useState<boolean>(false);
  const [trow, setT] = useState(false);
  const [current, setCurrent] = useState<any>(null);
  const [row, setRow] = useState<any>();
  const [totalCount, setTotalCount] = useState(null);
  const [selectedCheckboxValues, setSelectedCheckboxValues] = useState<string[]>([]);
  const [canClick, setCanClick] = useState(true);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [deleteExamModalState, handleDeleteExamModalState] = useState<boolean>(false);
  const [checkboxTableData, setCheckboxTableData] = useState<NestedCheckBoxTableData>([]);

  const { getExamDetails, loadingExamDetails, dataExamDetails, errorExamDetails, refetchExamDetails } = useGetExamDetails();
  const { createExamDetail, dataCreateExamDetail, errorCreateExamDetail } = useCreateExamDetail();
  const { updateExamDetail, updateExamDetailData, updateExamDetailError } = useUpdateExamDetail();
  const { deleteExam } = useDeleteExam();

  const styles = StyleSheet.create({
    addBtnStyle: { width: rem(11), height: rem(3.6) }
  });

  let maxHeight = height - 395;
  const paginationHeightOffset = 20;

  const { page = 1, dataLimit = limit, urlBatchId = null }: any = useParams();
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(parseInt(dataLimit));

  useEffect(() => {
    if (urlBatchId) {
      handleFetch(urlBatchId);
    }
  }, [urlBatchId, headCells]);

  useEffect(() => {
    if (dataExamDetails) {
      setRow(dataExamDetails?.examDetails?.data);
      setTotalCount(dataExamDetails?.examDetails?.totalCount);
    }
  }, [dataExamDetails]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
    null, null, false, false, null,
    paginationHeightOffset
  );

  // Set header title text
  useEffect(() => {
    setHeading([{ text: t('exams.label'), url: '' }]);
  }, []);

  useEffect(() => {
    if (selectedBatch) {
      handleFetch(selectedBatch);
    }
  }, [currentPage, headCells, rowLimit]);

  function makeFetchQueryVariable(batchId: string) {
    return {
      "filters": {
        "batch": {
          "eq": batchId
        }
      },
      "limit": rowLimit,
      "skip": (currentPage - 1) * rowLimit,
      "sort": createSortFilter(headCells)
    };
  }

  function handleFetch(currentlySelectedBatch: string) {
    getExamDetails({ variables: makeFetchQueryVariable(currentlySelectedBatch) });
  }

  function addToUrl(currentBatchId: string) {
    const updatedPage = 1; // Initialize to first page
    setCurrentPage(updatedPage);
    history.push(`/assessment/exams/${currentBatchId}/limit/${dataLimit}/page/${updatedPage}`);
  }

  const retrieveValues = (formData: any) => {
    setSelectedBatch(formData.batch.value);
    setSelectedClassId(formData.batch.class.id);
    setAllFormValuesAvailable(true);
    handleFetch(formData.batch.value);

    // add batch id to history
    addToUrl(formData.batch.value);
  };

  const addExamModal = () => {
    setIsEditModal(false);
    hideAlert();
    setExamFormModalVisible(true);
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  const closeModal = () => {
    setExamFormModalVisible(false);
    setValue('examName', null);
    clearErrors();
  };

  const closeDeleteModal = () => {
    handleDeleteExamModalState(false);
    setCurrentItem({});
  }

  async function addEditExam(data: { examName: string; }, testDetails: NestedCheckBoxTableData) {
    try {
      const filteredTests: NestedCheckBoxTableData = testDetails.filter(
        (item: NestedCheckBoxTableRowData) =>
          Array.isArray(item.value) &&
          item.value.length &&
          item.value.some((test: NestedCheckBoxTableRowData) => test.selected),
      ).map((res: NestedCheckBoxTableRowData) => ({
        ...res,
        value: Array.isArray(res.value) && res.value.length ? res.value.filter((test: NestedCheckBoxTableRowData) => test.selected) : '',
      }));

      if (filteredTests && filteredTests.length) {
        const orderIdEmpty = filteredTests.some((item: NestedCheckBoxTableRowData) => !Number.isInteger(item.order));
        if (orderIdEmpty) {
          throw new Error('Please provide order for every selected subject');
        } else {
          setCanClick(false);
          let examObject, examData, testData;
    
          testData = filteredTests.map((item: NestedCheckBoxTableRowData) => ({
            order: item.order,
            subject: item.id,
            ...(Array.isArray(item?.value) && item.value.length
              ? {
                  tests: item.value
                    .filter((test: NestedCheckBoxTableRowData) => test.selected)
                    .map((res: NestedCheckBoxTableRowData) => res.id),
                }
              : {}),
          }));
    
          if (!isEditModal) {
            // add exam
            examObject = {
              batch: selectedBatch,
              name: data?.examName,
              tests: testData,
            };
    
            examData = await createExamDetail({
              variables: { payload: examObject },
            });
          }
          else {
            // edit exam
            examObject = {
              batch: selectedBatch,
              id: currentItem.id,
              name: data?.examName,
              tests: testData,
            };
    
            examData = await updateExamDetail({
              variables: { payload: examObject },
            });
          }
    
          if (examData?.data) {
            // successfuly api hit
            setAlertDetails({ message: isEditModal ? UPDATE_SUCCESS.EXAM : ADD_SUCCESS.EXAM, level: SUCCESS });
            closeModal();
            if (refetchExamDetails) refetchExamDetails(makeFetchQueryVariable(selectedBatch));
          }
          setCanClick(true);

        }
      } else {
        throw new Error('Please select atleast one subject');
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
    // closeModal();
  }

  const handleAddEditExam = async (data: any) => {
    if (checkboxTableData.length != 0) {
      addEditExam(data, checkboxTableData);
    } else {
      // ensure tests are selected
      setAlertDetails({ message: t("test-selection.required.text"), level: ERROR });
    }
  };

  const handleEditExam = (item: any) => {
    setCurrentItem(item);
    setIsEditModal(true);
    setExamFormModalVisible(true);
  };
  const handleDeleteExamModal = (item: any) => {
    setCurrentItem(item);
    handleDeleteExamModalState(true);
  };

  async function handleDeleteExam() {
    setCanClick(false);
    try {
      const deleteResponse = await deleteExam({
        variables: { id: currentItem?.id },
      });
      if (deleteResponse?.data?.deleteExam) {
        setAlertDetails({ message: DELETE_SUCCESS.EXAM, level: SUCCESS });
        if (refetchExamDetails) refetchExamDetails();
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
    setCanClick(true);
    closeDeleteModal();
  }

  return (
    <LoadContentWrapper>
      <TopRowsWrapper>
        <SelectionWrapper>
          <BatchClassSelectionForm
            retrieveValues={retrieveValues}
            urlBatchId={urlBatchId}
            pagePath={`/assessment/exams/limit/${dataLimit}/page/1`}
            setParentBatchId={setSelectedBatch}
            setParentClassId={setSelectedClassId}
            setParentAllFormValuesAvailable={setAllFormValuesAvailable}
            isLoading={loadingExamDetails}
          />
        </SelectionWrapper>

        <AddExamBtnWrapper>
          <SecondaryBtn
            label={t('add-exam.label')}
            onPress={addExamModal}
            style={styles.addBtnStyle}
            customDisabled={!selectedBatch}
          />
        </AddExamBtnWrapper>
      </TopRowsWrapper>

      <TblContainer height={'100%'}>
        <TblHead setHeadCells={setHeadCells} />
        <>
          {!loadingExamDetails ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                    <>
                      <TableRows
                        key={`e${i}`}
                        style={{
                          backgroundColor:
                            trow && current === i ? colors.menuSelectionBg : 'transparent',
                          width: '5%',
                          height: 56,
                        }}>
                        <ExpandCollapseCell
                          trow={trow}
                          current={current}
                          i={i}
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: true,
                              isLastCell: true,
                              itemId: item?.id,
                            }),
                          }}
                          textValue={item?.name}
                          CollapseFn={() => {
                            setT(false);
                            setCurrent(null);
                          }}
                          ExpandFn={() => {
                            setT(true);
                            setCurrent(i);
                          }}
                          disabled={item?.examDetails?.data.length === 0}
                        />

                        {/* Intentionally blank to cover column space for subjects & test names */}
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                        />
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                        />
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                        />
                        {/* Actions */}
                        <TableCell
                          align="right"
                          style={{
                            minWidth: '5%',
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: true,
                              itemId: item?.id,
                            }),
                          }}>
                          <ActionIcons>
                            <IconView>
                              <EditIcon
                                onPress={() => handleEditExam(item)}
                                tooltipTitle={t('edit-exam.label')}
                              />
                            </IconView>
                            {/* Temporary Disable of Delete Btn */}
                            <DeleteIcon
                              onPress={() => handleDeleteExamModal(item)}
                              tooltipTitle={t('delete-exam.label')}
                            />
                          </ActionIcons>
                        </TableCell>
                      </TableRows>
                      {trow && current === i && (
                        <TableRows>
                          <TableCells colspan={5} style={{ padding: 0 }}>
                            {/* Pass data for nested child table */}
                            <ExamsNestedTable data={item} />
                          </TableCells>
                        </TableRows>
                      )}
                    </>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={7} maxHeight={0.5 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>

      <Pagination
        pathName={`assessment/exams/${selectedBatch}`}
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        setRowLimit={setRowLimit}
        hidePagination={!!(totalCount && totalCount <= rowLimit)}
      />

      {/* Modals */}
      <NormalModal
        isSubmitting={!canClick}
        onAlert={null}
        height={634}
        width={800}
        maxWidth={'lg'}
        setModalVisible={closeModal}
        Headerpopup={isEditModal ? t('edit-exam.label') : t('add-exam.label')}
        modalVisible={examFormModalState}
        handleSave={handleSubmit(handleAddEditExam)}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'save.label'}>
        <AddEditExamForm
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          selectedBatch={selectedBatch}
          checkboxTableData={checkboxTableData}
          setCheckboxTableData={setCheckboxTableData}
          isEditModal={isEditModal}
          currentItem={currentItem}
        />
      </NormalModal>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={closeDeleteModal}
        modalVisible={deleteExamModalState}
        handleSave={handleDeleteExam}
        Headerpopup={t('deleteExam.text')}
        children={t('deleteExam.warning')}
      />
    </LoadContentWrapper>
  );
}

const TopRowsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10;
`;

const SelectionWrapper = styled.div`
  margin-right: 10px;
`;

const AddExamBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.theme.rem(2)};
`;

const ActionIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const IconView = styled.div`
  margin-right: 13px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
