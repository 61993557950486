import React from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';
import { useI18n } from 'src/i18n/hooks';
import { limit } from 'src/constant';
import { fonts, colors  } from 'src/styles/theme/styles';
import { styled } from '@mui/material/styles';
import NormaltextAtom from '../Text/NormalTextAtom';

const CustomizedTablePagination = styled('div')`
  display:flex;
  flex-direction: row;
  margin-top: 12px;
  align-items: center;
  justify-content: space-between;
  & .MuiTablePagination-displayedRows,
  & .MuiButtonBase-root,
  & .MuiTablePagination-selectLabel,
  & .MuiInputBase-root-MuiTablePagination-select {
    font-family: ${fonts.regular};
  }
  & .MuiTablePagination-root{
    margin-left: auto;
  }
  & .MuiPaginationItem-root {
    margin: 0 2px;
  }
  & .MuiPagination-ul {
    .MuiPaginationItem-previousNext,
    .MuiPaginationItem-firstLast {
      background-color: ${colors.background};
      border-radius: 2px;
      color: ${colors.tertiaryText};
    }
    .Mui-selected {
      border-radius: 2px;
      background-color: ${colors.primary}
    }
  }
`;

interface ITablePagination {
  pathName: string;
  total: number | null;
  page?: number;
  rowLimit?: number;
  hidePagination?: boolean;
  labelForOfflineSearch?: string;
  setCurrentPage?: (value: number) => void;
  setRowLimit?: (value: number) => void;
  setRestrictSkip?: (value: boolean) => void;
  hideRowsPerOptions?: boolean;
  rowsPerPageOptions?: number[];
  customHandleRowsPerPageOptions?: () => void;
}

// const LabelText = ({ value }: { value: string }) => {
//   return <MediumTextSelect value={value} fontWeight={400} color={colors.primaryText} />;
// };


export default function TablePaginationRowSelection({
  pathName = '',
  total,
  page = 1,
  rowLimit = limit,
  hidePagination,
  labelForOfflineSearch = '',
  setCurrentPage,
  setRowLimit,
  hideRowsPerOptions = false,
  setRestrictSkip,
  rowsPerPageOptions = [50],
  customHandleRowsPerPageOptions,
}: ITablePagination) {
  const history = useHistory();
  const { t } = useI18n();

  const handleChangePage = (_, newPage: number) => {
    if (setRestrictSkip) setRestrictSkip(false);
    history.push(`/${pathName}/limit/${rowLimit}/page/${newPage}`);
    if (setCurrentPage) {
      setCurrentPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.target.value && setRowLimit) {
      setRowLimit(parseInt(event.target.value));
    }
    history.push(`/${pathName}/limit/${event.target.value}/page/1`);
    if (setCurrentPage) {
      setCurrentPage(1);
    }
  };

  const onRowPageChange = (_, newPage: number) => {
    if (setCurrentPage) {
      setCurrentPage(newPage);
    }
  };

  function handleRowsPerPageOptions(total: number) {
    switch (true) {
      case total >= 100:
        rowsPerPageOptions.push(75, 100);
        break;
      case total >= 75:
        rowsPerPageOptions.push(75);
    }
  }

  /**
   * rowsPerPageOptions sizes are [50, 75,100]
   * visible if totalCount is greater than 75
   */
  
  if (total) {
    if (customHandleRowsPerPageOptions) {
      customHandleRowsPerPageOptions();
    } else {
      handleRowsPerPageOptions(total);
    }
  }

  return (
    <CustomizedTablePagination>
      {!!total && (
        <>
          {!hidePagination && (
            <Pagination
              variant="text"
              count={Math.ceil(total / rowLimit)}
              page={parseInt(page)}
              size={'small'}
              showFirstButton
              showLastButton
              color="primary"
              shape="rounded"
              onChange={handleChangePage}
              sx={{
                '.MuiTablePagination-toolbar': {
                  fontFamily: `${fonts.regular}`,
                },
              }}
            />
          )}

          <TablePagination
            component="div"
            count={total}
            page={page - 1} // The zero-based index of the current page.
            onPageChange={onRowPageChange}
            SelectProps={{
              MenuProps: {
                sx: {
                  '.MuiTablePagination-menuItem.Mui-selected': {
                    backgroundColor: `${colors.primary}!important`,
                    color: `${colors.white}!important`,
                  }
                } 
              }
            }}
            rowsPerPage={rowLimit}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={!hideRowsPerOptions ? rowsPerPageOptions : []}
            nextIconButtonProps={{ style: { display: 'none' } }}
            backIconButtonProps={{ style: { display: 'none' } }}
            labelDisplayedRows={({ from, to, count: totalCount }) => {
              if (from >= totalCount) {
                return null;
              }
              return `${t('showing.label')} ${from} - ${to} ${t('of.label')} ${totalCount}`;
            }}
          />
        </>
      )}

      {
        <>
        {!!labelForOfflineSearch && <NormaltextAtom style={{textAlign:'right', width: '100%'}} value={labelForOfflineSearch}  />}
        </>
      }
    </CustomizedTablePagination>
  );
}
