import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { useI18n } from 'src/i18n/hooks';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import NewsModalContent from 'src/components/organism/Communication/News/ModalContent';
import { SD } from 'src/constant/standardDimensions';

export const NoticeItemWithModal = ({ item, type, children }: any) => {
  const { t } = useI18n();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TouchableOpacity onPress={() => setOpen(true)}>{children}</TouchableOpacity>
      <NormalModal
        showFooter={false}
        setModalVisible={() => setOpen(false)}
        modalVisible={open}
        handleSave={() => { }}
        Headerpopup={type == "NOTICE" ? t('notice.label') : t('news.label')}
        width={SD.primaryInfoModal.width}
        height={SD.primaryInfoModal.height}
        maxWidth={"lg"}>
        <NewsModalContent newsnoticeId={item.id} newsDetail={{}} type={type} />
      </NormalModal>
    </>
  );
};
