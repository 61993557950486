import React from 'react';
import { AppRegistry, PixelRatio } from 'react-native';
import RouterComponent from './routes/router-component';
import { enableScreens } from 'react-native-screens';
import { ThemeProvider } from './contexts/theme-context';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/native';
import { ThemeProvider as WebStyledThemeProvider } from 'styled-components';
import './i18n';
import { MD2LightTheme as DefaultTheme } from 'react-native-paper';
// Note:: Do not use MD3 themes (material design v3), they default to system wallpaper colors & our app backgrounds get colored tints. Stick to white / theme assigned backgrounds https://callstack.github.io/react-native-paper/docs/guides/migration-guide-to-5.0/#theming
import { ApolloProvider } from '@apollo/client/react';
import { client } from './graphql/client';
import { largeDevice, mediumSize, MuiTheme } from './styles/theme/styles';
import { SidebarProvider } from 'src/contexts/sidebar-context';
import { isNative } from 'src/constant/device';
import { Router } from 'src/routes/routing';
import { AlertProvider } from './contexts/web-alert-context';
import Routes from 'src/navigation/Routes';
import { name as appName } from '../app.json';
import { ProfileDetailProvider } from './contexts/profile-detail-context';
import CodePush from 'src/screens/codepush/index';
import { CompatRouter } from "react-router-dom-v5-compat";

const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: '#3498db',
    accent: '#f1c40f',
  },
};

enableScreens();

const getMultiple = () => {
  if (isNative) {
    if (PixelRatio.get() > 2) {
      return 12;
    } else if (PixelRatio.get() === 2) {
      return 10;
    }
  } else if (largeDevice) {
    return 10;
  } else if (mediumSize) {
    return 9;
  }
  return 8;
};

const rem = (size: number | [number]) => {
  const multiple = getMultiple();
  if (Array.isArray(size)) {
    return size.map((s) => `${s * multiple}px`).join(' ');
  }
  const num = `${size * multiple}${isNative ? '' : 'px'}`;
  return isNative ? parseInt(num) : num;
};
``;
export const Root = () => {
  enableScreens();
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={MuiTheme}>
        <StyledThemeProvider theme={{ rem }}>
          <WebStyledThemeProvider theme={{ rem }}>
            <ProfileDetailProvider>
              <AlertProvider>
                <SidebarProvider>
                  {isNative ? (
                    <>
                      <CodePush component={() => <Routes theme={theme} />} />
                    </>
                  ) : (
                    <Router>
                      <CompatRouter>
                        <RouterComponent />
                      </CompatRouter>
                    </Router>
                  )}
                </SidebarProvider>
              </AlertProvider>
            </ProfileDetailProvider>
          </WebStyledThemeProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
AppRegistry.registerComponent(appName, () => Root);
