import { gql } from '@apollo/client';

const getGradesQuery = gql`
  query ($query: AvgGradeQueryInput!) {
    averageGrades(query: $query) {
      average
      subjectName
      subjectColor
      grade
      obtained
    }
  }
`;

export { getGradesQuery };
