import { Dispatch, SetStateAction } from 'react'
import { Field, Schema } from '@data-driven-forms/react-form-renderer/common-types';
import { IUserProfileDetails } from 'src/utils/customHooks/useLoadUserDetails';
import { UserDocumentCategory, UserType } from 'src/constant';
import { GridSelectionModel } from '@mui/x-data-grid';
import { DropdownOptionsType } from 'src/types';

export type FetchValuesOptionsType = {
  valuesFieldName: string | 'self',
  fieldName: string,
  foreignField?: string,
  localField?: string,
  mapField?: string,
}

export interface FormField extends Field {
  fields?: FormField[];
  groupBy?: string,
}

export interface FormSchema extends Schema {
  label: string;
  addNewDetailsPrompt?: string;
  name: string;
  viewMode: ViewMode,
  listOptions?: {
    iconName: string;
    deleteMessageTitleLabel: string;
    deleteMessageLabel: string;
    columns: { id: string; name: string }[];
  },
  headerData?: {
    title: string;
    skip: Boolean,
    icon: string,
    progress: number,
  },
  helpers?: (detail: any) => {
    title: string;
    subtitle: string;
    content1: string;
    content2: string;
  },
  outerFields?: FormField[];
  fields: FormField[];
}

export enum ViewMode {
  READ = 1,
  EDIT = 2,
}

export enum FormBaseType {
  UserType = 1,
  ApplicationQualification = 2
}

export enum TemplateType {
  DEFAULT = 1,
  LIST = 2,
}

export interface FormViewerPropType {
  schema: FormSchema;
  isOnboarding: boolean;
  inModalView?: boolean;
  paddingRight?: number;
  disableCustomStyling?: boolean;
}

export type FormHandleSubmit = (params: submitHandlerParam) => void;

interface submitHandlerParam {
  removeDoc?: { detail: string; detailId: string; docId: string },
  submit?: { callback: Function },
}

export interface FormViewerMapperPropType extends ListFormViewerPropType { }

export interface ListFormViewerPropType {
  step: number;
  schema: FormSchema;
  templateType: TemplateType;
  isOnboarding: boolean;
  userType: UserType;
  userId: string;
  formBaseType?: FormBaseType;
  applicationRef?: string;
  formInitialValues?: any;
  refreshList?: () => Promise<void>;
  setFormInitialValues: (formValues: any) => void;
  handleDelete?: (id?: string) => Promise<void>;
  handleSubmit?: () => Promise<FormHandleSubmit>;
  navigation?: any;
  reviewMode: boolean;
  inModalView?: boolean;
  selectedRecords?: GridSelectionModel;
  setSelectedRecords?: Dispatch<SetStateAction<GridSelectionModel>>;
  hideAddAndDeleteBtn?: boolean;
  viewApplication?: boolean;
}

export interface RootFormViewerProp extends FormViewerMapperPropType {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  isLastStep: boolean;
  isSubmitting: boolean;
  goToNextStep: () => Promise<void>;
  setViewMode: Dispatch<SetStateAction<ViewMode>>;
  isOnboarding: boolean;
  navigation?: any;
  viewMode: ViewMode;
  reviewMode: boolean;
  onCancel: (callback?: any) => Promise<void>;
  selectedRecords?: GridSelectionModel;
  setSelectedRecords?: Dispatch<SetStateAction<GridSelectionModel>>;
  handleAcceptChangesModal: Dispatch<SetStateAction<boolean>>;
  handleRejectChangesModal: Dispatch<SetStateAction<boolean>>;
  submitForReviewModal: boolean;
  handleSubmitForReviewModal: Dispatch<SetStateAction<boolean>>;
  submitForReviewFn: () => Promise<void>;
  enableSubmitForReviewBtn?: boolean;
  showApproveRejectBtn: boolean;
  profileNotActive?: boolean;
  hideAddAndDeleteBtn?: boolean;
  prefix?: string;
  requestId?: string;
  capId?: string;
  inviteCode?: string;
  submitButtonLabel?: string;
  viewApplication?: boolean;
  onFinishCallback?: () => void;
  allowProfileEdit?: boolean;
}

export interface allDocumentsType {
  typeId: string;
  name: string;
  label: string;
  category: UserDocumentCategory
};

export interface qualifiedStreamsType {
  stream: DropdownOptionsType;
  majorSubjects: string[];
}

export interface FormDDOptions {
  religions?: DropdownOptionsType[];
  categories?: DropdownOptionsType[];
  mediums?: DropdownOptionsType[];
  streams?: qualifiedStreamsType[];
  jobTypes?: DropdownOptionsType[];
  occupations?: DropdownOptionsType[];
}