import { format } from 'date-fns';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { DT } from 'src/constant/dateTime';
import { isNative } from 'src/constant/device';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import ImageAtom from '../ImageAtom';
import SmallTextAtom from '../Text/SmallTextAtom';
import { properCase } from 'src/utils/utility';
import HyperLinkText from '../Text/HyperLinkText';

interface Profile {
  documentUrl: string;
}
interface commentorType {
  firstName: string;
  lastName: string;
  profilePicture: Profile | null;
  personalDetails: {
    fullName: string;
  }
}

interface Props {
  comment: string;
  time: number;
  commentor: commentorType;
}

let noProfile =
  'https://www.vhv.rs/dpng/d/426-4264903_user-avatar-png-picture-avatar-profile-dummy-transparent.png';

export default function CommentCard(props: Props) {
  const { comment, time, commentor } = props;
  const showDate: string = format(time, DT.DATE_TIME_FORMAT_12_HOUR);

  return (
    <View>
      <Container>
        <ImageAtom
          style={[styles.imgStyle]}
          source={{
            uri: commentor?.profilePicture?.documentUrl || noProfile,
          }}
        />

        <NameView>
          <View style={{ marginHorizontal: isNative ? 8 : 4 }}>
            <SmallTextAtom
              value={properCase(commentor?.personalDetails?.fullName || '-')}
              color={colors.primaryText}
            />
          </View>
          <SmallTextAtom value={showDate || ''} color={colors.tertiaryText} />
        </NameView>
      </Container>
      <CommentView>
        <HyperLinkText text={comment ?? ""} variant={"small"} />
      </CommentView>
    </View>
  );
}

const styles = StyleSheet.create({
  imgStyle: {
    height: 24,
    width: 24,
    borderRadius: 50,
  },
});

const Container = styled.View`
  flex-direction: row;
`;

const NameView = styled.View`
  flex-direction: row;
  align-self: center;
`;

const CommentView = styled.View`
  margin-left: ${isNative ? '32px' : '29px'};
`;
