import { gql, useMutation } from '@apollo/client';

const signupApplicantMutation = gql`
  mutation ($payload: ApplicantSignupInput!) {
    applicantSignUp(payload: $payload) {
      message
      status
    }
  }
`;

export function useApplicantSignup() {
  const [signUpApplicant, { data: applicantData }] = useMutation(signupApplicantMutation);
  return { signUpApplicant, applicantData };
}