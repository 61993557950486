import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import NormalButtton from 'src/components/atoms/Button/NormalButton';
import OutlineButtton from 'src/components/atoms/Button/OutlineButtton';
import PropTypes from 'prop-types';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';

interface Props {
  handleCancel: () => void;
  cancelButtonLabel: string;
  addEditButtonLabel: string;
  actionButtonLabel: string;
  disabledSave?: boolean;
  hideSubmit?: boolean;
  hideCancel?: boolean;
  handleSave?: () => void;
  translation?: boolean;
  timer?: boolean;
  disabled?: boolean;
  buttonColor?: any;
  showActionButton?: boolean;
  handleActionButton?: () => void;
}

export default function ModalBaseActions(props: Props) {
  const {
    handleCancel,
    handleSave,
    handleActionButton,
    cancelButtonLabel = '',
    addEditButtonLabel = '',
    actionButtonLabel = '',
    hideSubmit = true,
    hideCancel = false,
    disabledSave = false,
    timer = false,
    disabled,
    buttonColor = colors.primary,
    showActionButton,
  } = props;
  const { t } = useI18n();
  const [timeLeft, setTimeLeft] = useState(5);
  const timerCss = timer && timeLeft !== 0 ? true : false;

  useEffect(() => {
    if (!timeLeft) return;

    const myInterval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(myInterval);
  }, [timeLeft]);

  return (
    <View style={styles.baseModal}>
      {
        !hideCancel && (
          <BtnWrapper hideSubmit={hideSubmit || showActionButton}>
            <OutlineButtton label={t(cancelButtonLabel)} onPress={handleCancel} />
          </BtnWrapper>
        )
      }

      {showActionButton && actionButtonLabel && (
        <BtnWrapper hideSubmit={hideSubmit}>
          <OutlineButtton
            label={t(actionButtonLabel)}
            onPress={() => (handleActionButton ? handleActionButton() : null)}
          />
        </BtnWrapper>
      )}

      {hideSubmit && (
        <View>
          <NormalButtton
            label={timerCss ? timeLeft : t(addEditButtonLabel)}
            onPress={timerCss || disabled ? null : handleSave}
            disabled={disabledSave}
            width={timer && 68.53}
            style={{
              backgroundColor: timer ? colors.errorColor : buttonColor,
              opacity: timerCss || disabled ? 0.5 : 1,
              cursor: !timerCss && 'pointer',
            }}
          />
        </View>
      )}
    </View>
  );
}

ModalBaseActions.propTypes = {
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
};

const styles = StyleSheet.create({
  baseModal: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 24,
    paddingRight: 32,
    justifyContent: 'flex-end',
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderColor: colors.inputBorder,

  },
});

const BtnWrapper = styled.View<{ hideSubmit: boolean }>`
  margin-right: ${({ hideSubmit }) => hideSubmit ? "16px" : "0px"};
`;
