import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import formJSON from '../../../form-json/academics/subjects.json';
import Element from '../Forms/ApplicationElement.web';
import { useQuery } from '@apollo/client';
import { autoCompleteLimit, AUTOCOMPLETE_MODULE, USER_TYPE } from '../../../constant';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import {
  getFilteredCourses,
  getFilteredClasses,
  getFilteredUniversities,
  createListFilter,
} from 'src/components/services';
import { getAllBoadUniversitiesQuery } from 'src/graphql/academics/board-universities';
import { validation } from 'src/constant/validation';
import { useI18n } from 'src/i18n/hooks';
import { userInformation } from 'src/utils/manageState';
import ChipInput from 'src/components/atoms/ChipInput.tsx';
import { semesterOptions } from './dd-options';

export default function SubjectForm(props: any) {
  const {
    editSubjectData,
    setValue,
    getValues,
    isEditModal,
    setError,
    clearErrors,
    errors,
    creditPointArray,
    setCreditPointArray,
    reset,
    resetField,
    register,
  } = props;
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};
  const allUniversities = useQuery(getAllBoadUniversitiesQuery, {
    variables: { limit: 0, skip: 0 },
  });

  const { t } = useI18n();
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;
  const disableIndex = [0, 1, 2, 3, 4, 8];

  if (userType === USER_TYPE.EMPLOYEE) {
    disableIndex.forEach((item) => (formJSON[0].fields[item].disabled = true));
  }

  useEffect(() => {
    return () => {
      reset({});
    }
  }, []);

  useEffect(() => {
    let getdata = allUniversities.data;
    formJSON[0].fields[8].option = semesterOptions;
    if (allUniversities.loading == false) {
      loadUniversities(getdata.universities.data);
    }
    if (isEditModal) {
      loadEditData();
    }
    setElements({ ...formJSON[0] });
  }, [allUniversities.data]);

  useEffect(() => {
    if (userType === USER_TYPE.EDBA_ADMIN && fields) {
      const newData = { ...formJSON[0] };
      if (creditPointArray && creditPointArray.length > 0) {
        const options = creditPointArray.map((item: any) => ({ label: item, value: item }));
        newData.fields[9].disabled = false;
        newData.fields[9].option = options;
        newData.fields[9].required = true;
        newData.fields[9].required_error = 'DEFAULT_CREDIT_POINT_REQUIRED';
      } else {
        register('defaultCreditPoint', { required: false });
        clearErrors('defaultCreditPoint');
        resetField('defaultCreditPoint', { keepError: false, defaultValue: null });
        newData.fields[9].disabled = true;
        newData.fields[9].option = [];
        newData.fields[9].required = false;
        newData.fields[9].required_error = '';
      }
      setElements(newData);
    }
  }, [creditPointArray, fields]);

  function resetFormField() {
    let course = editSubjectData?.course;
    let classes = editSubjectData?.class;
    setValue('university', { label: course?.university?.name, value: course?.university?.id });
    setValue('course', { label: course?.alias, value: course?.id });
    setValue('class', { label: classes?.alias, value: classes?.id });
    setValue('type', {
      label: editSubjectData?.type.charAt(0).toUpperCase() + editSubjectData?.type.slice(1).toLowerCase(),
      value: editSubjectData?.type
    });
    setValue('code', editSubjectData?.code);
    setValue('name', editSubjectData?.name);
    setValue('alias', editSubjectData?.alias);
    setValue('color', editSubjectData?.color);
    setValue('semester', { label: editSubjectData?.semester, value: editSubjectData?.semester });
    if (userType === USER_TYPE.EDBA_ADMIN) {
      setCreditPointArray(editSubjectData?.creditPoints);
      if (editSubjectData?.defaultCreditPoint && editSubjectData?.creditPoints && editSubjectData.creditPoints.length > 0)
        setValue('defaultCreditPoint', {
          label: editSubjectData.defaultCreditPoint,
          value: editSubjectData.defaultCreditPoint,
        });
    }
  }

  async function loadEditData() {
    let course = editSubjectData?.course;
    resetFormField();
    loadCourseClass(course);
  }

  async function loadCourseClass(course: any) {
    let courseParam = createListFilter(
      0,
      0,
      AUTOCOMPLETE_MODULE.UNIVERSITY,
      course?.university?.id,
    );
    let courseRespose = await getFilteredCourses(courseParam);
    loadCourses(courseRespose?.options);
    let classesParam = createListFilter(
      0,
      0,
      AUTOCOMPLETE_MODULE.COURSE,
      course?.id,
    );
    let classRespose = await getFilteredClasses(classesParam);
    loadClasses(classRespose?.options);
  }

  function loadUniversities(universities: any) {
    formJSON[0].fields[0].option = universities;
  }

  function loadCourses(courseArray: any) {
    const newData = { ...formJSON[0] };
    newData.fields[1].option = courseArray;
    setElements(newData);
  }

  function loadClasses(classArray: any) {
    const newData = { ...formJSON[0] };
    newData.fields[2].option = classArray;
    setElements(newData);
  }

  async function searchSelectScroll(queryVariable: any, type: string) {
    let param = {
      limit: autoCompleteLimit,
      skip: queryVariable * autoCompleteLimit,
    } as any;

    if (type === AUTOCOMPLETE_MODULE.UNIVERSITY) {
      let boardResponse = await getFilteredUniversities(param);
      return boardResponse;
    }

    if (type === AUTOCOMPLETE_MODULE.COURSE) {
      let filters = {} as any;
      let university = getValues(AUTOCOMPLETE_MODULE.UNIVERSITY);
      filters['university'] = { eq: university?.value };
      param['filters'] = filters;
      let courseResponse = getFilteredCourses(param);
      return courseResponse;
    }

    if (type === AUTOCOMPLETE_MODULE.CLASS) {
      let filters = {} as any;
      let course = getValues(AUTOCOMPLETE_MODULE.COURSE);
      filters['course'] = { eq: course?.value };
      param['filters'] = filters;
      let classResponse = await getFilteredClasses(param);
      return classResponse;
    }
  }

  async function searchSelectOnchange(queryVariable: any, type: string) {
    let filters = {
      alias: {
        regex: queryVariable,
      },
    } as any;

    if (type === AUTOCOMPLETE_MODULE.UNIVERSITY) {
      let boardResponse = getFilteredUniversities({ filters: filters });
      return boardResponse;
    }

    let university = getValues(AUTOCOMPLETE_MODULE.UNIVERSITY);
    if (type === AUTOCOMPLETE_MODULE.COURSE && university?.value) {
      filters['university'] = { eq: university?.value };
      let courseRespose = await getFilteredCourses({ filters: filters });
      return courseRespose;
    }

    if (type === AUTOCOMPLETE_MODULE.CLASS) {
      let course = getValues(AUTOCOMPLETE_MODULE.COURSE);
      filters['course'] = { eq: course?.value };
      let classRespose = await getFilteredClasses({ filters: filters });
      return classRespose;
    }
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.UNIVERSITY) {
      setValue(AUTOCOMPLETE_MODULE.COURSE, null);
      setValue(AUTOCOMPLETE_MODULE.CLASS, null);
      if (data) {
        let param = createListFilter(
          0,
          0,
          AUTOCOMPLETE_MODULE.UNIVERSITY,
          data?.value,
        );
        let courseRespose = await getFilteredCourses(param);
        loadCourses(courseRespose?.options);
      }
    }

    if (id === AUTOCOMPLETE_MODULE.COURSE) {
      setValue(AUTOCOMPLETE_MODULE.CLASS, null);
      if (data) {
        let param = createListFilter(0, 0, AUTOCOMPLETE_MODULE.COURSE, data?.value);
        let classRespose = await getFilteredClasses(param);
        loadClasses(classRespose?.options);
      }
    }

    if (id === 'color') {
      const reg = /^#[0-9A-F]{6}$/i;
      const result = reg.test(data);
      if (!result) {
        if (!errors.color) {
          setError('color', { message: t(validation.INVALID_COLOR_CODE) });
        }
      } else {
        if (errors.color) {
          clearErrors('color');
        }
      }
    }
  }

  return (
    <View>
      <View>
        {fields ? (
          fields.map((field: any, i: number) => (
            <>
              {field.id === 'defaultCreditPoint' && userType !== USER_TYPE.EDBA_ADMIN ? (
                <></>
              ) : (
                <>
                  <View key={`subjectP${i}`}>
                    <Element
                      key={`subjectf${i}`}
                      field={field}
                      control={props.control}
                      errors={props.errors}
                      searchSelectOnchange={searchSelectOnchange}
                      dropdownDefault={editSubjectData}
                      handleCustomSelect={handleCustomSelect}
                      maxDropdownOptionsHeight={i === 9 ? 100 : 'auto'}
                    />

                    {userType === USER_TYPE.EDBA_ADMIN && field?.id === 'semester' && (
                      <ChipInput
                        chipArray={creditPointArray}
                        setChipArray={setCreditPointArray}
                        title={'creditPoints.label'}
                        inputHeader={'creditPoint.required.label'}
                        chipText={'creditPoints.label'}
                        inputId={'creditPoint'}
                        requiredErrorMsg={validation.CREDIT_POINT_REQUIRED}
                        typeInput={'number'}
                        checkMinMax={true}
                        minValue={1}
                      />
                    )}
                  </View>
                </>
              )}
            </>
          )
          )) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
});
