import { ADMISSION_PROCESS_ID_ENUM, USER_TYPE } from 'src/constant';
import React from 'react';

import CreateInfoItem from 'src/components/molecules/TextComponents/InfoItem';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { colors } from 'src/styles/theme/styles';
import { height } from 'src/constant/device';
import styled from 'styled-components';
import { useI18n } from 'src/i18n/hooks';
import { ApplicationFormTemplateProps } from '../types';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import MultiChipsDisplay from 'src/components/atoms/Chip/MultiChipsDisplay';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';

export default function ApplicationForm(props: ApplicationFormTemplateProps) {
  const { admissionData, formFields, handleCancel, handleSubmit, userType } = props;
  const { t } = useI18n();
  const { renderForm } = useFormApi();

  function renderFormValues(showFields: number[], customStyle?: boolean) {
    return (
      formFields &&
      formFields?.map((field: any, index: number) => {
        return (
          customStyle ? (
            <DocElementWrapper
              display={showFields?.includes(index) ? 'initial' : 'none'}>
              {renderForm(field?.customHiddenField ? [] : [field])}
            </DocElementWrapper>
          ) : (
          <ElementWrapper
            display={showFields?.includes(index) ? 'initial' : 'none'}>
            {renderForm(field?.customHiddenField ? [] : [field])}
          </ElementWrapper>
          )
        );
      })
    );
  }

  return (
    <>
      <LoadContentWrapper style={styles(formFields.length).loadContent}>
        <>
          <TitleWrapper>
            <HeaderFive value={t('admission-details.label')} color={colors.primaryText} />
          </TitleWrapper>
          <BodyWrapper>
            <Row>
              <FormItem label={t('university.label')} value={admissionData?.university} />
              <FormItem label={t('institute.label')} value={admissionData?.institute} />
              <FormItem label={t('course.label')} value={admissionData?.course} />
            </Row>
            <Row>
              <FormItem label={t('class.label')} value={admissionData?.class} />
              <FormItem label={t('batch.label')} value={admissionData?.batch} />
              <FormItem label={t('subjectGroup.label')} value={admissionData?.subjectGroup?.name} />
            </Row>
            <Row>
              <FormItem label={t("applicantType.label")} value={admissionData?.applicantType} />
              {Boolean(admissionData?.admissionProcessId !== ADMISSION_PROCESS_ID_ENUM[1]) && (
                <>
                  <FormItemWrapper>
                    <RenderElementWrapper>
                      {admissionData?.admissionProcessId === ADMISSION_PROCESS_ID_ENUM[0]
                        ? renderFormValues([2])
                        : renderFormValues([0])}
                    </RenderElementWrapper>
                  </FormItemWrapper>
                  <FormItemWrapper>
                    <RenderElementWrapper>
                      {admissionData?.admissionProcessId === ADMISSION_PROCESS_ID_ENUM[0]
                        ? renderFormValues([3])
                        : renderFormValues([1])}
                    </RenderElementWrapper>
                  </FormItemWrapper>
                </>
              )}
            </Row>

            {
              userType !== USER_TYPE.STUDENT ? (
                <ChipsRow>
                  <ChipsWrapper>
                    <NormaltextAtom value={t("qualifications.label")} />
                    <MultiChipsDisplay chipsArray={admissionData?.qualifications?.map(q => q?.name?.name)} />
                  </ChipsWrapper>
                  <ChipsWrapper>
                    <NormaltextAtom value={t("subjects.label")} />
                    <MultiChipsDisplay chipsArray={admissionData?.subjectGroup?.subjects?.map(s => s?.name)} />
                  </ChipsWrapper>
                </ChipsRow>
              ) : (
                <Row>
                  <FormItemWrapper>
                    <RenderElementWrapper>
                      {renderFormValues([4])}
                    </RenderElementWrapper>
                  </FormItemWrapper>
                  <FormItem label={t("form.courseFees.label")} value={`\u20B9 ${admissionData?.fees}`} />
                  <FormItemWrapper />
                </Row>
              )
            }

            <Row>
              <FormItem label={t("last-date-admission.label")} value={admissionData?.lastDate} />

              {
                userType !== USER_TYPE.STUDENT &&
                  admissionData?.fees &&
                  admissionData?.fees !== "-" ? (
                  <FormItem label={t("form.courseFees.label")} value={`\u20B9 ${admissionData?.fees}`} />
                ) : (
                  <FormItemWrapper />
                )
              }

              {
                admissionData?.applicationFees ? (
                  <FormItem label={t("form.applicationFees.label")} value={`\u20B9 ${admissionData?.applicationFees}`} />
                ) : (
                  <FormItemWrapper />
                )
              }
            </Row>

            {admissionData?.inHouseApplicantAllowed && (
              <Row>
                <FormItemWrapper>
                  <RenderElementWrapper>
                    {renderFormValues([6])}
                  </RenderElementWrapper>
                </FormItemWrapper>
                <FormItemWrapper>
                  <RenderElementWrapper>
                    {renderFormValues([7])}
                  </RenderElementWrapper>
                </FormItemWrapper>
                <FormItemWrapper>
                  <RenderElementWrapper>
                    {renderFormValues([8])}
                  </RenderElementWrapper>
                </FormItemWrapper>
              </Row>
            )}

            {
              userType === USER_TYPE.APPLICANT && (
                <>
                  <Row style={{ marginBottom:0 }}>
                    <FormItemWrapper>
                      <RenderElementWrapper>
                        {renderFormValues([5])}
                      </RenderElementWrapper>
                    </FormItemWrapper>
                    <FormItemWrapper />
                    <FormItemWrapper />
                  </Row>
                  <FreeshipNoteWrapper>
                    <HeaderFive value={t('note.label')} color={colors.noteBlue} />
                    <MediumTextSelect
                      value={t('freeship.note.text')}
                      color={colors.noteBlue} fontWeight={'normal'}
                      style={{ paddingTop: 4 }}
                    />
                  </FreeshipNoteWrapper>
                </>
              )
            }

            {formFields?.length > 9 && (
              <>
                <HeaderWrapper>
                  <HeaderFive value={t('documents.label')} color={colors.primaryText} />
                </HeaderWrapper>
                <DocumentWrapper>
                  {renderFormValues(
                    Array.from({ length: formFields.length - 1 }, (_, i) => 9 + i),
                    true,
                  )}
                </DocumentWrapper>
              </>
            )}

          </BodyWrapper>
        </>
      </LoadContentWrapper>
      <BtnWrapper>
        <OutlineButton onPress={handleCancel} label={t('cancel.label')} marginRight={16} />
        <SecondaryBtn onPress={handleSubmit} label={t('next.label')} />
      </BtnWrapper>
    </>
  );
}

/* NOTE:: <FormItemWrapper /> Blank to adjust for 3 Columns in each row */

const FormItem = ({ label, value }: { label: string; value: string; }) => {
  return (
    <FormItemWrapper>
      <CreateInfoItem label={label} value={value} width={""} />
    </FormItemWrapper>
  )
};

const styles = (fieldsLength: number) => {
  return {
    loadContent: { minHeight: 785, height: fieldsLength > 8 ? 'auto' : height - 166 },
  }
};

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ theme }) => `0px ${theme.rem(6.4)} 24px ${theme.rem(6.4)}`};
`;

const TitleWrapper = styled.div`
  margin-bottom: 16px;
`;

const BodyWrapper = styled.div`
  width: 90%;
`;

const FormItemWrapper = styled.div`
  width: calc(100% / 3);
  margin-right: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const ElementWrapper = styled.div<{ display: string }>`
  display: ${(props) => props.display};
`;

const DocElementWrapper = styled.div<{ display: string }>`
  display: ${(props) => props.display};
  width: calc((100% / 3) - 24px);
  margin-right: 24px;
`;

const RenderElementWrapper = styled.div`
  max-width: 300px;
`;

const ChipsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  margin-right: 24px;
  min-width: calc((100% / 3) - 24px);
`;

const ChipsRow = styled(Row)`
  justify-content: flex-start;
`;

const FreeshipNoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.noteBlue};
  padding: 16px 32px;
  border-radius: 4px;
  background-color: ${colors.noteBg};
  width: 89%;
`;

const DocumentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const HeaderWrapper = styled.div`
  margin: 24px 0px 16px 0px;
`;