import { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components';
import React from 'react';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import { ViewMode } from '../../Forms/types';

/** This sub form is used to render 3-column form */
export const ThreeColumnCustomSubForm = (props: UseFieldApiConfig) => {
  const {
    fields,
    subscribeToValues,
    viewMode,
  } = props
  const { renderForm } = useFormApi();
  return (
    <FormSpy subscription={{ dirtyFields: subscribeToValues ? true : false }}>
      {() => (
        <ThreeColumnFieldSection viewMode={viewMode}>
          {fields.map((field: any) => (
            renderForm([field])
          ))}
        </ThreeColumnFieldSection>
      )}
    </FormSpy>
  )
}


const ThreeColumnFieldSection = styled.div<{ viewMode: ViewMode }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props?.viewMode === ViewMode.READ ? 2 : 3},1fr)`};
  column-gap: 24px;
  row-gap: 6px;
`