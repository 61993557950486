export const genders = [
  {
    value: 'FEMALE',
    label: 'Female',
  },
  {
    value: 'MALE',
    label: 'Male',
  },
  {
    value: 'OTHER',
    label: 'Other',
  },
];

export const bloodGroups = [
  {
    "value": "ABNEGATIVE",
    "label": "AB-"
  },
  {
    "value": "ABPOSITIVE",
    "label": "AB+"
  },
  {
    "value": "ANEGATIVE",
    "label": "A-"
  },
  {
    "value": "APOSITIVE",
    "label": "A+"
  },
  {
    "value": "BNEGATIVE",
    "label": "B-"
  },
  {
    "value": "BPOSITIVE",
    "label": "B+"
  },
  {
    "value": "ONEGATIVE",
    "label": "O-"
  },
  {
    "value": "OPOSITIVE",
    "label": "O+"
  }
];

export const maritalStatuses = [
  {
    value: 'SINGLE',
    label: 'Single',
    align: 'right',
  },
  {
    value: 'MARRIED',
    label: 'Married',
    align: 'right',
  },
  {
    value: 'DIVORCED',
    label: 'Divorced',
    align: 'left',
  },
];

export const streams = [
  {
    value: 'Architecture',
    label: 'Architecture',
  },
  {
    value: 'Arts / Painting',
    label: 'Arts / Painting',
  },
  {
    value: 'Commerce',
    label: 'Commerce',
  },
  {
    value: 'Engineering',
    label: 'Engineering',
  },
  {
    value: 'Management',
    label: 'Management',
  },
  {
    value: 'Mass Media',
    label: 'Mass Media',
  },
  {
    value: 'Medical',
    label: 'Medical',
  },
  {
    value: 'Pharmacy',
    label: 'Pharmacy'
  },
  {
    value: 'Science',
    label: 'Science',
  },
  {
    value: 'Technology',
    label: 'Technology',
  },
  {
    label: 'Others',
    value: 'Others'
  }
];

export const mediums = [
  {
    value: 'English',
    label: 'English',
  },
  {
    value: 'Hindi',
    label: 'Hindi',
  },
  {
    value: 'Marathi',
    label: 'Marathi',
  },
  {
    label: 'Others',
    value: 'Others'
  }
];

export const jobTypes = [
  {
    value: 'Architecture and Construction',
    label: 'Architecture and Construction',
  },
  {
    value: 'Agriculture, Food, and Natural Resources',
    label: 'Agriculture, Food, and Natural Resources',
  },
  {
    value: 'Arts, Media, and Communication',
    label: 'Arts, Media, and Communication',
  },
  {
    value: 'Business and Finance',
    label: 'Business and Finance',
  },
  {
    value: 'Education and Training',
    label: 'Education and Training',
  },
  {
    value: 'Government and Public Administration',
    label: 'Government and Public Administration',
  },
  {
    value: 'Health Science',
    label: 'Health Science',
  },
  {
    value: 'Information Technology',
    label: 'Information Technology',
  },
  {
    value: 'Law and Security',
    label: 'Law and Security',
  },
  {
    value: 'Marketing',
    label: 'Marketing',
  },
  {
    value: 'Engineering',
    label: 'Engineering',
  },
  {
    label: 'Others',
    value: 'Others'
  }
];

export const categories = [
  {
    value: 'General',
    label: 'General',
  },
  {
    value: 'SC',
    label: 'SC',
  },
  {
    value: 'ST',
    label: 'ST',
  },
  {
    value: 'VJ/NT-A',
    label: 'VJ/NT-A',
  },
  {
    value: 'VJ/NT-B',
    label: 'VJ/NT-B',
  },
  {
    value: 'VJ/NT-C',
    label: 'VJ/NT-C',
  },
  {
    value: 'VJ/NT-D',
    label: 'VJ/NT-D',
  },
  {
    value: 'OBC',
    label: 'OBC',
  },
  {
    value: 'SBC',
    label: 'SBC',
  },
  {
    value: 'EWS',
    label: 'EWS',
  },
  {
    value: 'EBC',
    label: 'EBC',
  },
];

export const occupations = [
  {
    value: 'Medical Professionals',
    label: 'Medical Professionals',
  },
  {
    value: 'Pharmaceutical Professionals',
    label: 'Pharmaceutical Professionals',
  },
  {
    value: 'Information Technology',
    label: 'Information Technology',
  },
  {
    value: 'Engineering',
    label: 'Engineering',
  },
  {
    value: 'Teaching',
    label: 'Teaching',
  },
  {
    value: 'Banking',
    label: 'Banking',
  },
  {
    value: 'Chartered Accounting',
    label: 'Chartered Accounting',
  },
  {
    value: 'Company Secretary',
    label: 'Company Secretary',
  },
  {
    value: 'Investment Banker',
    label: 'Investment Banker',
  },
  {
    value: 'Management Professional',
    label: 'Management Professional',
  },
  {
    value: 'Management Consultant',
    label: 'Management Consultant',
  },
  {
    value: 'Businessman / Entrepreneur',
    label: 'Businessman / Entrepreneur',
  },
  {
    value: 'Legal professions',
    label: 'Legal professions',
  },
  {
    value: 'Law Enforcement',
    label: 'Law Enforcement',
  },
  {
    value: 'Civil services',
    label: 'Civil services',
  },
  {
    value: 'Service',
    label: 'Service',
  },
  {
    value: 'Journalism',
    label: 'Journalism',
  },
  {
    value: 'Scientist',
    label: 'Scientist',
  },
  {
    value: 'Designers',
    label: 'Designers',
  },
  {
    value: 'Modeling',
    label: 'Modeling',
  },
  {
    value: 'Aviation',
    label: 'Aviation',
  },
  {
    value: 'Fashion',
    label: 'Fashion',
  },
  {
    value: 'An Artist',
    label: 'An Artist',
  },
  {
    value: 'Homemaker (Housewife)',
    label: 'Homemaker (Housewife)',
  },
  {
    value: 'Agriculture',
    label: 'Agriculture',
  },
  {
    value: 'Hotel management/ culinary',
    label: 'Hotel management/ culinary',
  },
  {
    value: 'Hair and Make-up artists',
    label: 'Hair and Make-up artists',
  },
  {
    value: 'Influencer/Blogger',
    label: 'Influencer/Blogger',
  },
  {
    value: 'Entertainment Industry',
    label: 'Entertainment Industry',
  },
  {
    value: 'The Merchant Navy',
    label: 'The Merchant Navy',
  },
  {
    value: 'Indian Armed Forces',
    label: 'Indian Armed Forces',
  }
];

export const religions = [
  {
    value: 'Hindu',
    label: 'Hindu',
  },
  {
    value: 'Sikh',
    label: 'Sikh',
  },
  {
    value: 'Muslim',
    label: 'Muslim',
  },
  {
    value: 'Christian',
    label: 'Christian',
  },
  {
    value: 'Buddhist',
    label: 'Buddhist',
  },
  {
    value: 'Jain',
    label: 'Jain',
  },
  {
    label: 'Others',
    value: 'Others'
  }
];

export const othersOption = { label: 'Others', value: 'Others' };

export const paymentModes = [
  {
    value: 'cash',
    label: 'Cash',
  },
  {
    value: 'cc',
    label: 'CC',
  },
  {
    value: 'NEFT',
    label: 'NEFT',
  },
];

export const scoreType = [
  {
    value: 'marks',
    label: 'Marks'
  },
  {
    value: 'grades',
    label: 'Grades'
  }
]

export const states = [
  {
    value: "Andhra Pradesh",
    label: "Andhra Pradesh"
  },
  {
    value: "Arunachal Pradesh",
    label: "Arunachal Pradesh"
  },
  {
    value: "Assam",
    label: "Assam"
  },
  {
    value: "Bihar",
    label: "Bihar"
  },
  {
    value: "Chhattisgarh",
    label: "Chhattisgarh"
  },
  {
    value: "Goa",
    label: "Goa"
  },
  {
    value: "Gujarat",
    label: "Gujarat"
  },
  {
    value: "Haryana",
    label: "Haryana"
  },
  {
    value: "Himachal Pradesh",
    label: "Himachal Pradesh"
  },
  {
    value: "Jharkhand",
    label: "Jharkhand"
  },
  {
    value: "Karnataka",
    label: "Karnataka"
  },
  {
    value: "Kerala",
    label: "Kerala"
  },
  {
    value: "Madhya Pradesh",
    label: "Madhya Pradesh"
  },
  {
    value: "Maharashtra",
    label: "Maharashtra"
  },
  {
    value: "Manipur",
    label: "Manipur"
  },
  {
    value: "Meghalaya",
    label: "Meghalaya"
  },
  {
    value: "Mizoram",
    label: "Mizoram"
  },
  {
    value: "Nagaland",
    label: "Nagaland"
  },
  {
    value: "Odisha",
    label: "Odisha"
  },
  {
    value: "Punjab",
    label: "Punjab"
  },
  {
    value: "Rajasthan",
    label: "Rajasthan"
  },
  {
    value: "Sikkim",
    label: "Sikkim"
  },
  {
    value: "Tamil Nadu",
    label: "Tamil Nadu"
  },
  {
    value: "Telangana",
    label: "Telangana"
  },
  {
    value: "Tripura",
    label: "Tripura"
  },
  {
    value: "Uttarakhand",
    label: "Uttarakhand"
  },
  {
    value: "Uttar Pradesh",
    label: "Uttar Pradesh"
  },
  {
    value: "West Bengal",
    label: "West Bengal"
  }
];

export const studentIdentifier = [
  {
    value: 'Saral ID',
    label: 'Saral ID'
  },
  {
    value: 'ABC ID',
    label: 'ABC ID'
  }
]