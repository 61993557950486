import { format, isValid } from "date-fns";

import { DT } from "src/constant/dateTime";
import { MarkedDates } from "react-native-calendars/src/types";
import { MarkingProps } from "react-native-calendars/src/calendar/day/marking";
import { colors } from "src/styles/theme/styles";

export const generateMarkedDatesObject = (
  absenceDates: string[]
): MarkedDates => {
  if (Array.isArray(absenceDates) && absenceDates.length > 0) {
    return absenceDates.reduce((acc, val) => {
      acc[val] = {
        marked: true,
        dotColor: colors.red,
        disableTouchEvent: true,
      };
      return acc;
    }, {} as Record<string, MarkingProps>);
  }
  return {};
};

export const convertToISODate = (date: Date): string | undefined => {
  // Converts a JavaScript Date object to the yyyy-MM-dd ISO date format as required by "react-native-calendars" library
  if (isValid(date)) {
    const isoDate = format(date, DT.ISO_DATE);
    return isoDate;
  }
  return undefined;
};
