import React, { useEffect, useState } from "react";

import { DefaultUserSelection } from "src/constant";
import Element from "../Forms/ApplicationElement.web";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import { View } from "react-native";
import streamFormJSON from "../../../form-json/academics/stream.json";
import styled from "styled-components/native";

export default function StreamForm(props: any) {
  const {
    control,
    errors,
    setError,
    clearErrors,
    editStreamData,
    isEditModal,
    setValue,
    isDefaultOption,
    setIsDefaultOption,
  } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    if (isEditModal) {
      loadEditData();
    } else {
      setElements(streamFormJSON[0]);
    }
  }, []);

  function loadEditData() {
    prefillData();
    setElements(streamFormJSON[0]);
  }

  function prefillData() {
    setValue("stream_name", editStreamData?.name);
    setIsDefaultOption(
      editStreamData?.defaultUserSelection === DefaultUserSelection.ALLOWED
    );
  }

  return (
    <>
      {fields ? (
        fields.map((field: any, i: number) => (
          <View key={`form${i}`}>
            <Element
              key={i}
              field={field}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              checkBoxState={isDefaultOption}
              handleCheckbox={() =>
                setIsDefaultOption((prev: boolean) => !prev)
              }
            />
          </View>
        ))
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </>
  );
}

const SpinnerView = styled.View`
  height: 160px;
  justify-content: center;
  align-items: center;
`;
