import React from 'react';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import styled from 'styled-components/native';
import { colors, fonts } from 'src/styles/theme/styles';

export default function AttendanceTableGradeCard(props: any) {
  const { additionalSubjectRows } = props;
  const month_row_width: number = 60;
  const attendance_row_width: number = 80;

  return (
    <AttendanceTableContainer>
      <TableContainer>
        <Table sx={{fontFamily: fonts.regular}}>
          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              <TableCell 
              colSpan={2} 
              sx={{
                borderWidth: 1,
                borderColor: colors.deviderGrey,
                borderStyle: 'solid',
                borderBottomStyle: 'none',
                paddingTop: 9,
                paddingBottom: 9,
                height: 36
              }} 
              align={'center'}
            >
                <TableColHead value={"ATTENDANCE"} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Month Rows */}
            {
              // 11 rows --> temporary. will be dynamic
              Array(11).fill(0).map((element, index) => {
                return (
                  <TableRow>

                    <TableCell style={{ width: month_row_width }} sx={{
                      borderWidth: 1,
                      borderColor: colors.deviderGrey,
                      borderStyle: 'solid',
                      borderBottomStyle: 'none',
                      borderRightStyle: 'none',
                      paddingTop: 9,
                      paddingBottom: 9,
                      height: 36
                    }} align={'left'}>
                      <NormaltextAtom value={"N/A"} fontSize={1.5} />
                    </TableCell>

                    <TableCell style={{ width: attendance_row_width }} sx={{
                      borderWidth: 1,
                      borderColor: colors.deviderGrey,
                      borderStyle: 'solid',
                      borderBottomStyle: 'none',
                      paddingTop: 9,
                      paddingBottom: 9,
                      height: 36
                    }} align={'right'}>
                      <NormaltextAtom value={"N/A"} fontSize={1.5} />
                    </TableCell>

                  </TableRow>
                );
              })
            }

            {/* Total Attendance Row */}
            <TableRow
              sx={{
                '&.MuiTableRow-root': {
                  ...(additionalSubjectRows > 0 ? {} : { height: 108 }),
                },
              }}
              style={{ height: 36 * (3 + additionalSubjectRows) }}>
              <TableCell style={{ width: month_row_width, paddingTop: 0, paddingBottom: 0 }} sx={{
                borderWidth: 1,
                borderColor: colors.deviderGrey,
                borderStyle: 'solid',
                borderBottomStyle: 'none',
                borderRightStyle: 'none',
                paddingTop: 9,
                paddingBottom: 9,
                height: 36
              }} align={'left'}>
                <NormaltextAtom value={"Total"} fontSize={1.5} />
              </TableCell>

              <TableCell style={{ width: attendance_row_width, paddingTop: 0, paddingBottom: 0 }} 
                sx={{
                  borderWidth: 1,
                  borderColor: colors.deviderGrey,
                  borderStyle: 'solid',
                  borderBottomStyle: 'none',
                  paddingTop: 9,
                  paddingBottom: 9,
                  height: 36
                }} align={'right'}>
                <NormaltextAtom value={"800/800"} fontSize={1.5} />
              </TableCell>

            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </AttendanceTableContainer>
  );
}

const AttendanceTableContainer = styled.View`
 `;