import React, { useEffect, useState } from 'react';
import Input from 'src/components/atoms/Input/input';
import { Controller, useForm } from 'react-hook-form';
import {
  BackHandler,
  Keyboard,
  TouchableWithoutFeedback,
  Dimensions,
} from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import NormalTextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { isIOS, isNative, isWeb } from 'src/constant/device';
import { validation } from 'src/constant/validation';
import { useHistory, useLocation } from 'src/routes/routing';
import { breakpoints, colors, fontWeight } from 'src/styles/theme/styles';
import { flushStorageFunction } from 'src/components/services/storage-service';
import { apolloClearStore } from 'src/graphql/client';
import { KEYS } from 'src/constant/key';
import { useRequestResetPassword } from 'src/graphql/reset-password';
import { USER_TYPE_ENUM } from '../../../constant';
import { useRoute } from '@react-navigation/native';
import navigationStrings from 'src/navigation/navigationStrings';
import { showMessage } from 'src/components/molecules/NotificationWrapper';
import { Icon } from 'src/components/atoms/Icon/Icon';
import AvoidKeyPad from 'src/components/atoms/Wrapper/AvoidKey';
import FlashMessage from 'react-native-flash-message';
import {
  Container,
  PageAreaContainer,
  RequestResetDiv,
  MarginTop,
  StyledTouchableOpacity,
  BackgroundImage,
  LinkContainer,
  LogoView,
  NativeEDBALogo,
  SignInText,
  TextView,
  ForgotPassBackToSignIn,
} from './StyledComponents'
import { useI18n } from 'src/i18n/hooks';

const windowWidth = Dimensions.get('window').width;

export const RequestResetPassword = (props: any) => {
  const { requestResetPassword } = useRequestResetPassword();
  const { t } = useI18n();
  const [canClick, setCanClick] = useState(true);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const location = isWeb ? useLocation() : '';
  const pathname = location.pathname;
  const route: any = isWeb ? {} : useRoute();

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', backActionHandler);
    return () => BackHandler.removeEventListener('hardwareBackPress', backActionHandler);
  }, []);

  const backActionHandler = () => {
    if (isWeb) {
      history.goBack();
    } else {
      props.navigation.goBack();
    }
    return true;
  };

  const request = async (formdata: any) => {
    if (formdata?.userName) {
      clearGarbage();
      setCanClick(false);
      try {
        const response = await requestResetPassword({ variables: { emailId: formdata.userName } });
        if (response?.data?.requestResetPassword) {
          showMessage({
            message: 'Reset link sent successfully',
            type: 'success',
            position: 'top',
            icon: 'success',
          });
          reset({ userName: '' });
          setCanClick(true);
          if (!isWeb) {
            props.navigation.navigate(navigationStrings.REDIRECTION_SCREEN, { completedStep: "password_reset_success" })
          }
        }
      } catch (e: any) {
        showMessage({
          message: e?.message,
          type: 'danger',
          position: 'top',
          icon: 'danger',
        });
        setCanClick(true);
      }
    }
  };

  function clearGarbage() {
    flushStorageFunction(KEYS.STEPPER_STEPS);
    apolloClearStore();
  }

  return (
    <>
      <FlashMessage position="top" />
      <AvoidKeyPad
        extraHeight={200}
        style={{
          height: '90%',
          flex: 1,
          backgroundColor: colors.white,
        }}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <Container>
            <PageAreaContainer>
              <RequestResetDiv>
                <LogoView>
                  {isNative ? (
                    <NativeEDBALogo source={require('src/assets/logo/new-logo-4x.png')} />
                  ) : (
                    <Icon name={'edba-logo'} size={56} />
                  )}
                </LogoView>

                {isNative && (
                  <BackgroundImage
                    source={require('src/assets/login-background-4x.png')}
                    resizeMode="contain"
                  />
                )}

                <TextView>
                  <HeaderThree
                    value={t("forgot-password.text")}
                    lineHeight={isWeb ? 32 : 36}
                    fontWeight={isWeb ? 'normal' : isIOS ? fontWeight["600"] : fontWeight['bold']}
                  />
                  <SignInText>
                    <NormalTextAtom value="Please enter your email id you use to" lineHeight={2} />
                    <NormalTextAtom value="sign in." lineHeight={2} />
                  </SignInText>
                </TextView>

                <MarginTop margin={isNative ? 16 : 24}>
                  <Controller
                    control={control}
                    name={'userName'}
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                      <Input
                        id={'loginemail'}
                        inputRef={ref}
                        header={'Email ID*'}
                        onBlur={onBlur}
                        label={''}
                        value={value}
                        setValue={onChange}
                        width={isWeb ? 350 : null}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: validation.EMAIL_ID_REQUIRED,
                      },
                    }}
                  />
                  {errors.userName ? (
                    <MarginTop margin={4}>
                      <SmallTextAtom value={errors.userName.message} color={colors.errorColor} />
                    </MarginTop>
                  ) : null}
                </MarginTop>

                {isWeb && windowWidth > breakpoints.md ? (
                  <>
                    <MarginTop margin={36}>
                      <SecondaryBtn
                        canLoad={!canClick}
                        label={t("send-pass-reset-link.text")}
                        onPress={handleSubmit(request)}
                        style={{ paddingVertical: 8 }}
                        lineHeight={2.4}
                      />
                    </MarginTop>
                    <LinkContainer>
                      <StyledTouchableOpacity onPress={backActionHandler}>
                        <NormalTextAtom
                          value={t("back-to-sign-in.text")}
                          color={colors.primary}
                          lineHeight={2}
                          fontWeight={600}
                        />
                      </StyledTouchableOpacity>
                    </LinkContainer>
                  </>
                ) : (
                  <React.Fragment>
                    <MarginTop margin={24}>
                      <SecondaryBtn
                        canLoad={!canClick}
                        label={t("send-pass-reset-link.text")}
                        onPress={handleSubmit(request)}
                        lineHeight={2}
                        fontSize={1.6}
                      />
                    </MarginTop>
                  </React.Fragment>
                )}
                {isNative &&
                  <ForgotPassBackToSignIn onPress={backActionHandler}>
                    <SmallTextAtom
                      value={t("back-to-sign-in.text")}
                      color={colors.primary}
                      fontWeight={fontWeight[isIOS ? "600" : "bold"]}
                    />
                  </ForgotPassBackToSignIn>
                }
              </RequestResetDiv>
            </PageAreaContainer>
          </Container>
        </TouchableWithoutFeedback>
      </AvoidKeyPad>
    </>
  );
};