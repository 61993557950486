import { AdmissionProcessIdLabel, PAYMENT_MODE_LABEL } from "src/constant";
import { DT, parseToDate } from "src/constant/dateTime";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import {
  TmergeSelectedSubjectGroupAndFeeSeatData as MergeDataParam,
  TsubjectGroupsWithFeeSeatData as TResult,
} from "./types";
import { format, isAfter, isEqual, isValid, parse } from "date-fns";
import {
  getFilteredAdmissionCriterias,
  getFilteredClasses,
  getFilteredCourses,
  getFilteredDocumentTypes,
  getFilteredSubjectGroups,
  getFilteredUniversities,
  getMerchantOptions,
} from "src/components/services";

import { DropdownOptions } from "src/types";
import { TAdmissionDetail } from "src/components/organism/Admission/admissionTypes";
import { createFilter } from "src/utils/utility";
import { getAdmissionDetail } from "src/graphql/admission/admissions";
import { getFilteredBatch } from "src/graphql/academics/batch";
import { Dispatch, SetStateAction } from "react";

const fieldMap = {
  university: 1,
  course: 2,
  class: 3,
  batch: 4,
  subjectGroup: 5,
  applicantType: 7,
  reservedSeats: 9,
  fromDOB: 10,
  toDOB: 11,
  admissionCriteria: 13,
  admissionProcessId: 14,
  EMPTY_CELL_isLimitedAccess: 17,
  EMPTY_CELL_publicLink: 19,
  merchantName: 20,
  applicationAmount: 21,
  publicLinkEC: 23,
  documentsEC: 25,
  inHouseApplicantAllowedEC: 27,
};

const showDividerAfter: number[] = [
  fieldMap.EMPTY_CELL_isLimitedAccess,
  fieldMap.applicationAmount,
  fieldMap.publicLinkEC,
  fieldMap.documentsEC,
];

const fieldsDisabledInEditMode: number[] = [
  fieldMap.university,
  fieldMap.course,
  fieldMap.class,
  fieldMap.batch,
  fieldMap.admissionCriteria,
  fieldMap.admissionProcessId,
];

const instantiateFields = (setValue: UseFormSetValue<FieldValues>): void => {
  // necessary to enable field clearing
  [
    "university",
    "course",
    "class",
    "batch",
    "subjectGroup",
    "applicantType",
  ].map((key: string) => setValue(key, null));
};

async function fetchStarterFormData() {
  const boardResponse = await getFilteredUniversities({ limit: 0 });
  const merchOptions = await getMerchantOptions();
  const documentTypeOptions = await getFilteredDocumentTypes({ limit: 0 });
  return {
    universities: boardResponse?.options,
    merchants: merchOptions?.data,
    documentTypes: documentTypeOptions?.options,
  };
}

async function fetchCourseOptions(universityId: string) {
  const resp = await getFilteredCourses(
    createFilter(0, universityId, "university")
  );
  return { courses: resp?.options };
}

async function fetchClassOptions(courseId: string) {
  const resp = await getFilteredClasses(createFilter(0, courseId, "course"));
  return { classes: resp?.options };
}

async function fetchBatchOptions(classId: string) {
  const resp = await getFilteredBatch(createFilter(0, classId, "class"));
  return { batches: resp?.options };
}

async function fetchSubjectGroupOptions(batchId: string) {
  const resp = await getFilteredSubjectGroups(
    createFilter(0, batchId, "batch")
  );
  return { subjectGroups: resp?.options };
}

async function fetchAdmissionCriterias(classId: string) {
  const resp = await getFilteredAdmissionCriterias(
    createFilter(0, classId, "class")
  );
  return { criterias: resp?.options };
}

function formatPayloadDate(payloadDate: string) {
  const parsedDate = parseToDate(payloadDate);
  return parsedDate && isValid(parsedDate)
    ? format(parsedDate, DT.DATE_FORMAT_SLASH)
    : "";
}

async function fetchAndFillData(
  admissionID: string,
  setValue: UseFormSetValue<FieldValues>,
  setDocumentsOptions: Dispatch<SetStateAction<DropdownOptions[]>>,
  setDocumentChips: Dispatch<React.SetStateAction<ChipData[]>>
): Promise<any> {
  const { data, loading }: { data: TAdmissionDetail; loading: boolean } =
    await getAdmissionDetail(admissionID);
  const merchants = await getMerchantOptions();
  const { subjectGroups } = await fetchSubjectGroupOptions(data.batch.value);
  const documentTypeOptions = await getFilteredDocumentTypes({ limit: 0 });
  setDocumentsOptions(documentTypeOptions?.options || []);

  const requiredDocsExist = data?.requiredDocuments && data.requiredDocuments.length > 0;
  if (
    documentTypeOptions?.options &&
    documentTypeOptions.options.length &&
    requiredDocsExist
  ) {
    const docsData = data?.requiredDocuments?.map((item: any) => ({
      ...item,
      docType: item?.docType?.id,
      label: `${item?.docType?.name} (${item?.required ? 'Mandatory' : 'Optional'}) : ${AllowedPlaceLabel[item?.allowedPlace]
        }`,
    }));
    setDocumentChips(docsData ?? []);
  }

  setValue("admissionName", data.name);
  setValue("university", data.university);
  setValue("course", data.course);
  setValue("class", data.class);
  setValue("batch", data.batch);
  setValue(
    "subjectGroup",
    data.subjectGroups.map((s) => s.subjectGroup)
  );
  setValue("lastDate", formatPayloadDate(data.lastDate));
  setValue(
    "applicantType",
    data.allowedApplicantType?.map((at) => ({
      label: at,
      value: at,
    }))
  );
  setValue("totalSeatsAvailability", data.totalSeats);
  setValue("admissionStatus", { label: data.status, value: data.status });
  if (data?.criteria && data.criteria?.value) {
    setValue("admissionCriteria", {
      label: data.criteria.label,
      value: data.criteria.value,
    });
  }
  setValue("admissionProcessId", {
    label: AdmissionProcessIdLabel?.[data.askAdmissionProcessId],
    value: data.askAdmissionProcessId,
  });
  setValue(
    "paymentModes",
    data.allowedPaymentModes?.map((pm) => ({
      label: PAYMENT_MODE_LABEL[pm],
      value: pm,
    }))
  );
  setValue("setApplicationFee", !!data.applicationFeeRequired);
  setValue("setApplicationFeeEC", !!data.applicationFeeRequired);
  setValue("merchantName", data.applicationFeeDetail?.account ?? null);
  setValue("applicationAmount", data.applicationFeeDetail?.feeAmount ?? null);
  setValue("inHouseApplicantAllowed", data.inHouseApplicantAllowed);
  setValue(
    "inHouseInstitutes",
    data?.inHouseInstitutes?.map((inst: string | DropdownOptions) =>
      typeof inst === "string" ? inst : inst?.label
    )
  );
  setValue("subjectGroupsWithFeeSeatData", data.subjectGroups);
  setValue("reservedSeats", data?.reservedSeats ?? 0);
  setValue("publicLink", !!data?.publicAvailability);
  setValue("requiredDetails", data?.requiredDetails ?? []);
  setValue("isLimitedAccess", !!data?.isLimitedAccess);
  setValue("documents", requiredDocsExist);

  const from = data?.fromDOB ? formatPayloadDate(data.fromDOB) : "";
  const to = data?.toDOB ? formatPayloadDate(data.toDOB) : "";
  setValue("fromDOB", from);
  if (from && to && shouldClearDate(from, to)) {
    setValue("toDOB", null);
  } else {
    setValue("toDOB", to);
  }
  return { loading, merchants, subjectGroups, documentTypeOptions, requiredDocsExist };
}

function mergeSelectedSubjectGroupAndFeeSeatData({
  selectedSubGrp,
  feeSeatData,
}: MergeDataParam): TResult {
  const mergedData = selectedSubGrp.map((subGrp) => {
    const match = feeSeatData?.find(
      (seat) => seat.subjectGroup.value === subGrp.value
    );
    const seats = match ? match.seats : 0;
    const fee = match && match?.fee ? match.fee : null;
    return { ...subGrp, seats, ...(fee ? { fee } : {}) };
  });
  // Sort the mergedData array in descending order based on the 'seats' property
  mergedData.sort((a, b) => b.seats - a.seats);
  return mergedData;
}

function generatePayload(formData: FieldValues, admissionID?: string, chips?: ChipData[]) {
  let documentsData: RequiredDocumentData[] = [];
  if (chips && chips?.length) {
    documentsData = chips?.map(({ label, ...item }) => item);
  }
  const payload = {
    ...(admissionID ? { id: admissionID } : {}),
    name: formData.admissionName,
    batch: formData.batch.value,
    subjectGroups: formData.subjectGroups,
    lastDate: formData.lastDate,
    allowedApplicantType: formData.applicantType?.map(
      (at: DropdownOptions) => at.value
    ),
    totalSeats: parseInt(formData.totalSeatsAvailability),
    status: formData.admissionStatus.value,
    ...(formData?.admissionCriteria && formData.admissionCriteria?.value
      ? { criteria: formData.admissionCriteria.value }
      : {}),
    ...(formData?.admissionProcessId?.value
      ? { askAdmissionProcessId: formData.admissionProcessId.value }
      : {}),
    allowedPaymentModes: formData?.paymentModes?.map(
      (pm: DropdownOptions) => pm.value
    ),
    applicationFeeRequired: formData.applicationFeeRequired,
    ...(formData.applicationFeeRequired
      ? {
        applicationFeeDetail: {
          account: formData.merchantName.value,
          feeAmount: formData.applicationAmount,
        },
      }
      : {}),
    inHouseApplicantAllowed: formData.inHouseApplicantAllowed,
    ...(formData.inHouseApplicantAllowed
      ? {
        inHouseInstitutes: formData.inHouseInstitutes,
      }
      : {}),
    reservedSeats: parseInt(formData.reservedSeats),
    publicAvailability: formData.publicLink,
    ...(formData?.fromDOB ? { fromDOB: formData.fromDOB } : {}),
    ...(formData?.toDOB ? { toDOB: formData.toDOB } : {}),
    requiredDetails: formData?.requiredDetails ?? [],
    isLimitedAccess: !!formData?.isLimitedAccess,
    requiredDocuments: documentsData ?? [],
  };
  return payload;
}

function parseStrToDate(strDate: string): Date {
  return parse(strDate, DT.DATE_FORMAT_SLASH, new Date());
}

function shouldClearDate(fromDate: string, toDate: string): boolean {
  const from = parseStrToDate(fromDate);
  const to = parseStrToDate(toDate);
  return isAfter(from, to) || isEqual(from, to);
}

function checkHasLottery(applicantTypes: DropdownOptions[] = []): boolean {
  return applicantTypes.some(
    (item: DropdownOptions) => item.value === "LOTTERY"
  );
}

export const admissionFormHelpers = {
  fieldMap,
  fieldsDisabledInEditMode,
  instantiateFields,
  fetchAndFillData,
  fetchStarterFormData,
  fetchCourseOptions,
  fetchClassOptions,
  fetchBatchOptions,
  fetchSubjectGroupOptions,
  fetchAdmissionCriterias,
  mergeSelectedSubjectGroupAndFeeSeatData,
  generatePayload,
  showDividerAfter,
  shouldClearDate,
  checkHasLottery,
};

const feeSeatAllocationTableHeaders = [
  {
    id: "subject_groups",
    label: "subjectGroups.label",
    align: "left",
  },
  {
    id: "fee_composition",
    label: "feeComposition.label",
    align: "left",
    style: { width: "40%" },
  },
  {
    id: "seat_availability",
    label: "seatAvailability.label",
    align: "left",
    style: { width: "30%" },
  },
];

function sumSeats(inputArr: { seats: number }[]): number {
  return inputArr.reduce((acc, subGrp) => acc + subGrp.seats, 0);
}

export const feeSeatAllocationFormHelper = {
  feeSeatAllocationTableHeaders,
  sumSeats,
};

const feeCompositionTableHeaders = [
  {
    id: "moduleGroup",
    name: "moduleGroupName.label",
  },
  {
    id: "amount",
    name: "amount.label",
  },
];

export const feeCompositionSelectionFormHelper = {
  feeCompositionTableHeaders,
};

export const placeAllowedOptions = [
  {
    label: 'Public Page',
    value: 'PUBLIC_LINK',
  },
  {
    label: 'Application Page',
    value: 'SUBMIT_APPLICATION',
  },
];

export interface ChipData {
  docType: string;
  allowedPlace: string;
  required: boolean;
  label: string;
}

export const AllowedPlaceLabel = {
  PUBLIC_LINK: "Public Page",
  SUBMIT_APPLICATION: "Application Page",
} as any;

interface RequiredDocumentData {
  docType: string;
  allowedPlace: string;
  required: boolean;
}

export enum AllowedPlace {
  PUBLIC_LINK = 'PUBLIC_LINK',
  SUBMIT_APPLICATION = 'SUBMIT_APPLICATION',
}