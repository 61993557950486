/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/theme/styles';
import { useProfileDetail } from 'src/contexts/profile-detail-context';
import LeftSection from "src/components/molecules/ProfileLeftSection/index.web"
import ProfileTabs from "src/components/molecules/ProfileTabs/index.web";
import { getUserDetailForms } from 'src/form-json/user-details/index';
import UserDetails from 'src/components/molecules/UserDetails/index';
import { TemplateType, ViewMode } from 'src/components/molecules/Forms/types';
import { ProfileDetailViewPropType } from './types';
import { useHistory } from 'src/routes/routing';
import { userInformation } from 'src/utils/manageState';
import { DetailStatusEnum, EMPLOYEE_TYPES, UserType, USER_TYPE } from 'src/constant';


const ProfileDetailView: React.FC<ProfileDetailViewPropType> = ({
  step,
  mode,
  userId,
  userType,
  formContents,
  onChangeTab,
  navigation,
}) => {
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.READ);
  const [elementHeight, setElementHeight] = useState(0);
  const [submittedRecordInTab, setSubmittedRecordInTab] = useState<boolean[]>(new Array(5).fill(false));
  const [notVerifiedRecordInTab, setNotVerifiedRecordInTab] = useState<boolean[]>(new Array(5).fill(false));
  const [reviewMode, setReviewMode] = useState<boolean>(false);
  const ref = useRef(null);
  const sideBarRef = useRef(null);
  const { userProfileDetails } = useProfileDetail();
  const userDetailForms = getUserDetailForms(userType, null, false);
  const currentUserObject = userInformation();
  const currentUserType = currentUserObject?.userDetail?.type;
  const allowProfileEdit =
    currentUserObject?.userType === USER_TYPE.APPLICANT ||
    currentUserObject?.userDetail?.type?.includes('ADMIN') ||
    currentUserObject?.userDetail?.allowProfileEdit;
  let history = useHistory();

  useEffect(() => {
    if (currentUserType) {
      setReviewMode(!location.pathname.startsWith('/profile') && currentUserType.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) && userType !== USER_TYPE.APPLICANT)
    }
  }, [currentUserType])

  useEffect(() => {
    // Allow profile edit access required for switching to edit mode
    if (mode == 'edit' && allowProfileEdit) {
      setViewMode(ViewMode.EDIT);
    } else {
      setViewMode(ViewMode.READ);
    }
  }, [mode, allowProfileEdit]);

  useEffect(() => {
    setElementHeight(ref?.current?.clientHeight);
  }, [ref?.current?.clientHeight, sideBarRef?.current?.clientHeight]);

  useEffect(() => {
    setupIndicators()
  }, [userProfileDetails])

  function setupIndicators() {
    if (userType) {
      // common detail fields
      setIndicatorFn(0, getFieldStatus(userProfileDetails?.personalDetails));
      setIndicatorFn(1, getFieldStatus(userProfileDetails?.addressDetails?.current) || getFieldStatus(userProfileDetails?.addressDetails?.permanent));
      setIndicatorFn(4, getFieldStatus(userProfileDetails?.otherDetails));
    }
    if (userType == UserType.INSTITUTE_EMPLOYEE) {
      setIndicatorFn(2, getListTypeFieldStatus(userProfileDetails?.educationDetails));
      setIndicatorFn(3, getListTypeFieldStatus(userProfileDetails?.experienceDetails));
    }
    else if (userType == UserType.INSTITUTE_STUDENT) {
      setIndicatorFn(2, getFieldStatus(userProfileDetails?.parentDetails?.fatherDetail) || getFieldStatus(userProfileDetails?.parentDetails?.motherDetail));
      setIndicatorFn(3, getListTypeFieldStatus(userProfileDetails?.educationDetails));
    }
  }

  function setIndicatorFn(step: number, recordStatusArray: boolean[]) {
    // set an array to check if indicator badge needs to be shown
    let temp_submittedRecordInTab = submittedRecordInTab;
    temp_submittedRecordInTab[step] = recordStatusArray[0];
    setSubmittedRecordInTab([...temp_submittedRecordInTab]);

    // set an array to check if submit for verification btn needs to be shown
    let temp_notVerifiedRecordInTab = notVerifiedRecordInTab;
    temp_notVerifiedRecordInTab[step] = recordStatusArray[1];
    setNotVerifiedRecordInTab([...temp_notVerifiedRecordInTab]);
  }

  function getFieldStatus(field: any) {
    return [field?.status == DetailStatusEnum.SUBMITTED_FOR_REVIEW, field?.status == DetailStatusEnum.NOT_VERIFIED];
  }
  function getListTypeFieldStatus(field: any) {
    return [
      field && field?.some((elem: { status: DetailStatusEnum; }) => elem?.status === DetailStatusEnum.SUBMITTED_FOR_REVIEW),
      field && field?.some((elem: { status: DetailStatusEnum; }) => elem?.status === DetailStatusEnum.NOT_VERIFIED)
    ];
  }

  function showIndicatorBadgeOnTab(tabIndex: number) {
    return reviewMode && submittedRecordInTab[tabIndex];
  }

  const changeProfileMode = () => {
    let path = location.pathname;
    path = path.substring(0, path.length - 4);
    if (viewMode == ViewMode.READ) {
      history.push(`${path}edit`)
    } else {
      history.push(`${path}view`)
    }
  }

  return (
    <FormOuter>
      <>
        <LeftSidebar ref={ref}>
          <LeftSection
            viewMode={viewMode}
            toggleViewMode={changeProfileMode}
            userId={userId}
            userType={userType}
            userDetails={userProfileDetails}
            reviewMode={reviewMode}
            setSubmittedRecordInTab={setSubmittedRecordInTab}
            submittedRecordInTab={submittedRecordInTab}
            allowProfileEdit={allowProfileEdit}
          />
        </LeftSidebar>
        <FormBlock>
          <ShadowForm elementHeight={elementHeight} ref={sideBarRef}>
            <ProfileTabs
              mode={viewMode}
              step={step}
              onboardingSchema={userDetailForms[viewMode]}
              onChangeTab={onChangeTab}
              showIndicatorBadgeOnTab={showIndicatorBadgeOnTab}
            />

            <ShadowBox>
              <UserDetails
                step={step}
                isOnboarding={false}
                userId={userId}
                userType={userType}
                formContents={formContents}
                navigation={navigation}
                viewMode={viewMode}
                reviewMode={reviewMode}
                submittedRecordInTab={submittedRecordInTab}
                setSubmittedRecordInTab={setSubmittedRecordInTab}
                showSubmitForReviewBtn={setupIndicators}
                notVerifiedRecordInTab={notVerifiedRecordInTab}
                setNotVerifiedRecordInTab={setNotVerifiedRecordInTab}
              />
            </ShadowBox>
          </ShadowForm>
        </FormBlock >
      </>
    </FormOuter >
  );
};

export default ProfileDetailView;

const FormOuter = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 0px 0px ${colors.inputBorder};
  border-radius: 16px;
  padding: 40px 35px 40px 40px;
  min-height: 500px;
  @media all and (max-width: 767px) {
    padding: 15px;
  }
  margin-top: -13px;
`;
const FormBlock = styled.div`
  width: 71%;
  padding-left: 30px;
  box-sizing: border-box;
  @media all and (max-width: 991px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    order: 2;
  }
`;
const ShadowBox = styled.div`
  box-sizing: border-box;
  overflow-y: auto;
  height: calc(100% - 4rem);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;
const ShadowForm = styled.div<{ elementHeight: number }>`
  background-color: #fff;
  border-radius: 16px;
  padding: 30px 10px 0px 10px;
  box-sizing: border-box;
  height: ${({ elementHeight }) => elementHeight};

  @media all and (max-width: 767px) {
    background: none;
    box-shadow: none;
    border-radius: 0px;
    padding: 25px 0px;
  }
`;

const LeftSidebar = styled.div`
  width: 29%;
  @media all and (max-width: 991px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 30px;
  }
  margin-left: -6px;
`;