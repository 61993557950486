import React, { useEffect, useState } from "react";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import { MerchantsDataType } from "src/components/organism/Payment/Admin/NTT/types";
import formJSON from "src/form-json/payment/merchant-detail.json";
import { getFilteredAllInstitute } from "src/components/services";
import styled from "styled-components";

interface FormProps {
  control: any;
  errors: any;
  reset: any;
  setValue: any;
  isEditModal: boolean;
  currentItem: MerchantsDataType | null;
}

function MerchantDetailsForm(props: FormProps) {
  const { control, errors, reset, setValue, isEditModal, currentItem } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    reset();
    loadInstitutes();
    if (!isEditModal) {
      setElements(formJSON[0]);
    } else if (
      isEditModal &&
      currentItem &&
      Object.keys(currentItem).length != 0
    ) {
      setElements(formJSON[0]);
      setupFormValues(currentItem);
    }
  }, []);

  function setupFormValues(ci: MerchantsDataType) {
    setValue("institute", ci.institute);
    setValue("name", ci.name);
    setValue("reqEncKey", ci.reqEncKey);
    setValue("reqSalt", ci.reqSalt);
    setValue("resDecKey", ci.resDecKey);
    setValue("resSalt", ci.resSalt);
    setValue("prodId", ci.prodId);
    setValue("merchId", ci.merchId);
    setValue("merchPassword", ci.merchPassword);
    setValue("resHashKey", ci.resHashKey);
    setValue("reqHashKey", ci.reqHashKey);
    setValue("env", ci.env);
    setValue("mcc", ci.mcc);
  }

  async function loadInstitutes() {
    const instituteResponse = await getFilteredAllInstitute({
      limit: 0,
      skip: 0,
    });
    setElements([]);
    formJSON[0].fields[0].option = instituteResponse?.options;
    setElements(formJSON[0]);
  }

  function handleCustomSelect(id: string, data: any) { }

  return (
    <FormWrapper>
      {fields &&
        fields.map((field: any, i: number) => {
          return (
            <InputWrapper key={`,${i}`}>
              <Element
                key={`m${i}`}
                field={field}
                control={control}
                errors={errors}
                handleCustomSelect={handleCustomSelect}
              />
            </InputWrapper>
          );
        })}
    </FormWrapper>
  );
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const InputWrapper = styled.div`
  width: 47.5%;
`;

export default MerchantDetailsForm;
