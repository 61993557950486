import {
  Checkbox,
  Divider,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";
import {
  DaySelectDropdownPRESETS as PRESETS,
  DaySelectDropdownProps as Props,
  matchPreset,
  weekDays,
} from "./DaySelectDropdownHelpers";
import React, { useEffect, useState } from "react";

import SmallTextAtom from "../Text/SmallTextAtom";
import { colors } from "src/styles/theme/styles";
import { properCase } from "src/utils/utility";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";

const DaySelectDropdown = (props: Props) => {
  const {
    onChangeCallback,
    title = "days.required.label",
    containerWidth = "100%",
    containerHeight = "40px",
    initialValues = [],
    disabled = false,
  } = props;
  const { t } = useI18n();
  const [renderLabel, setRenderLabel] = useState<string>("");
  const [selectedDays, setSelectedDays] = useState<string[]>(initialValues);
  const [repeatOption, setRepeatOption] = useState<string[]>([]);

  useEffect(() => {
    if (Array.isArray(selectedDays)) {
      // Callback Function
      onChangeCallback(selectedDays);

      if (selectedDays.length === 0) {
        // No selection
        setRenderLabel("");
        setRepeatOption([]);
      } else if (selectedDays.length === 1) {
        // Solitary day selected
        setRenderLabel(properCase(selectedDays[0]));
        setRepeatOption([]);
      } else if (selectedDays.length > 0) {
        // Multiple days / preset option selected
        const match = matchPreset(selectedDays) as (typeof PRESETS)[0];
        if (match && Object.keys(match).length > 0) {
          setRenderLabel(match.label);
          setRepeatOption(match.value ? [match.value] : []);
        }
      }
    }
  }, [selectedDays]);

  const handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const targetValue: string[] = Array.isArray(event.target.value)
      ? event.target.value
      : [event.target.value];
    const value = targetValue.pop();

    if (value && typeof value === "string") {
      const selectedPreset = PRESETS.find((preset) => preset.value === value);
      if (selectedPreset) {
        // Preset option selected
        setRenderLabel(selectedPreset.label);
        setRepeatOption([value]);
        setSelectedDays(selectedPreset.days.map((day) => day.value));
      } else {
        // custom day combination selected
        setRepeatOption([]);
        setSelectedDays((prevCheckedItems) => {
          if (prevCheckedItems.includes(value)) {
            return prevCheckedItems.filter((item) => item !== value);
          } else {
            return [...prevCheckedItems, value];
          }
        });
      }
    } else {
      // Preset option toggled off / de-selected
      setRenderLabel("");
      setRepeatOption([]);
      setSelectedDays([]);
    }
  };

  return (
    <>
      <FieldTitleWrapper>
        <SmallTextAtom value={t(title)} color={colors.secondaryText} />
      </FieldTitleWrapper>
      <Select
        multiple
        disabled={disabled}
        value={repeatOption}
        onChange={handleSelectionChange}
        displayEmpty={true} // Ensures label re-render during Preset selection / option de-selection
        renderValue={() => renderLabel}
        sx={{
          height: containerHeight,
          width: containerWidth,
        }}
      >
        {PRESETS.map((option) => (
          <MenuItem
            disableRipple
            key={option.value}
            value={option.value}
            sx={ComponentProps.presetMenuItem}
          >
            {option.label}
          </MenuItem>
        ))}
        <Divider />
        {weekDays.map((day) => (
          <MenuItem
            disableRipple
            disableGutters
            key={day.value}
            value={day.value}
            sx={ComponentProps.weekDayMenuItem}
          >
            <Checkbox
              checked={selectedDays.includes(day.value)}
              sx={ComponentProps.checkbox}
            />
            <ListItemText primary={day.label} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default DaySelectDropdown;

const ComponentProps = {
  presetMenuItem: {
    ":focus, :hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      backgroundColor: colors.primary,
      color: "white",
      ":hover": {
        backgroundColor: colors.primary,
      },
    },
  } as SxProps<Theme>,
  weekDayMenuItem: {
    ":focus, :hover": {
      backgroundColor: "transparent",
    },
  } as SxProps<Theme>,
  checkbox: {
    padding: "4px 8px 4px 16px",
  } as SxProps<Theme>,
};

const FieldTitleWrapper = styled.div`
  margin-bottom: 4px;
`;
