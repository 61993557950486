import { name as appName } from '../app.json';
import React from 'react';
import { AppRegistry, View } from 'react-native';
import { Root } from 'src/root';
import './index.web.css';
import { colors } from 'src/styles/theme/styles';

const App = () => (
  <View style={{
    minHeight: '100vh',
    backgroundColor: colors.bluishGreyBackground,
  }}>
    <Root />
  </View>
);

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
});
