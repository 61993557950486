import { faGripLines } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList } from 'react-native';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import { height } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components';
import { onDragEndHandle, onDragOverHandle, onDragStartHandle, preventDrag } from 'src/utils/orderingHelperFunction';

interface ModuleData {
  id: string;
  isGroup: boolean;
  name: string;
  description: string;
  amount: number;
  orderNo?: string;
  components: ModuleData;
}

interface Props {
  moduleData: ModuleData[];
  setModuleData: Function;
}

export default function OrderModuleForm(props: Props) {
  const { moduleData, setModuleData } = props;
  const { t } = useI18n();
  const [totalAmount, setTotalAmount] = useState<null | number>();
  const dragItem = useRef<number | null>();
  const dragOverItem = useRef<number | null>();
  const headers = [
    {
      name: t('moduleGroupName.label'),
    },
    {
      name: t('amount.label').toUpperCase(),
    },
  ];

  useEffect(() => {
    if (moduleData && moduleData.length > 0) {
      const total = moduleData.reduce((a, b) => a + b?.amount, 0);
      setTotalAmount(total);
    }
  }, []);

  return (
    <ParentContainer>
      <HeadersWrapper>
        {headers.map(({ name }, index: number) => (
          <TableColHeadWrapper index={index}>
            <TableColHead value={name} />
          </TableColHeadWrapper>
        ))}
      </HeadersWrapper>
      <SingleLine marginBottom={10} />
      <FlatList
        data={moduleData}
        contentContainerStyle={{
          flex: 1,
          maxHeight: height - 350,
          minHeight: height - 650,
        }}
        keyExtractor={(item: any) => item.id}
        renderItem={({ item, index }: any) => {
          return (
            <ContainerView marginBottom={moduleData?.length - 1 === index ? 10 : 24}>
              <ParentElementView
                draggable
                onDragStart={(e: any) => onDragStartHandle(index, e, dragItem)}
                onDragOver={(e: any) => onDragOverHandle(index, e, dragOverItem)}
                onDragEnd={(e: any) => onDragEndHandle(e, dragItem, dragOverItem, moduleData, setModuleData)}>
                <IconView>
                  <FontAwesomeIcon icon={faGripLines} color={colors.secondaryText} />
                </IconView>
                <MainStartElement draggable onDragStart={(e: any) => preventDrag(e)}>
                  <MediumTextSelect value={item?.name} color={colors.primaryText} />
                </MainStartElement>
                <MainElement draggable onDragStart={(e: any) => preventDrag(e)}>
                  <MediumTextSelect
                    value={`\u20B9 ${item?.amount ? item.amount : '-'}`}
                    fontFamily={fonts.semibold}
                    color={colors.primaryText}
                  />
                </MainElement>
              </ParentElementView>
              <ChildElementView>
                {item?.components &&
                  item?.components?.length > 0 &&
                  item?.components?.map((groupInfo: any) => (
                    <ChildElementContentView>
                      <ComponentsStartElement>
                        <NormalTextSelect
                          value={groupInfo.name}
                          style={{ marginLeft: 33 }}
                          color={colors.secondaryText}
                        />
                      </ComponentsStartElement>
                      <ComponentsElement>
                        <NormalTextSelect
                          value={`\u20B9 ${groupInfo.amount}`}
                          color={colors.secondaryText}
                        />
                      </ComponentsElement>
                    </ChildElementContentView>
                  ))}
              </ChildElementView>
            </ContainerView>
          );
        }}
      />
      <SingleLine />
      <FooterWrapper>
        <ComponentsStartElement>
          <MediumTextSelect
            value={t('totalPayment.label')}
            fontFamily={fonts.semibold}
            color={colors.primaryText}
          />
        </ComponentsStartElement>
        <ComponentsElement>
          <MediumTextSelect
            value={`\u20B9 ${totalAmount}`}
            fontFamily={fonts.semibold}
            color={colors.primaryText}
          />
        </ComponentsElement>
      </FooterWrapper>
      <SingleLine />
    </ParentContainer>
  );
}

const IconView = styled.div`
  display: flex;
  flex: 0;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  cursor: move;
`;

const ParentContainer = styled.div`
  margin-bottom: 24px;
`;

const ContainerView = styled.div<{ marginBottom: number }>`
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const ParentElementView = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 20px;
`;

const ChildElementView = styled.div`
  padding: 0px 20px;
`;

const ChildElementContentView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
`;

const MainElement = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;
`;

const MainStartElement = styled.div`
  display: flex;
  width: 80%;
  justify-content: flex-start;
`;

const ComponentsElement = styled.div``;

const ComponentsStartElement = styled.div``;

const SingleLine = styled.div<{ marginBottom?: number }>`
  border: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)};
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 20px;
  margin: 18px 0px;
`;

const TableColHeadWrapper = styled.div`
  margin-right: ${(props: any) => props.index !== 0 && '22px'};
  margin-left: ${(props: any) => props.index === 0 && '28px'};
`;

const HeadersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 16px 0px 10px 0px;
`;
