import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-react-native-async-storage';
import transEN from './en/translation.json';
import { Platform } from 'react-native';
import { userInformation } from 'src/utils/manageState';
import { USER_TYPE } from 'src/constant';
import { KEYS } from 'src/constant/key';
import { isWeb } from 'src/constant/device';
const languageDetector = Platform.OS !== 'web' ? LanguageDetector('en') : LanguageDetector;
const defaultNamespace = 'translation';
const keyValues = isWeb ? localStorage.getItem(KEYS.TRANSLATION_KEY_VALUE) : null;

// Define option
// https://www.i18next.com/overview/configuration-options
const options: InitOptions = {
  fallbackLng: 'en',
  defaultNS: defaultNamespace,
  load: 'languageOnly',
  debug: false,
  keySeparator: false, // Turn off nested translation keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    skipOnVariables: false, // Reference: https://www.i18next.com/misc/migration-guide#skiponvariables-true
  },
  compatibilityJSON: 'v3', // Reference: https://www.i18next.com/misc/migration-guide#json-format-v4-pluralization

  react: {
    bindI18n: 'languageChanged', // Set which events trigger a re-render
    useSuspense: true, // Set to true if you like to wait for loaded in every translated hoc
  },

  initImmediate: false, // Important for SSR to work

  resources: {
    en: {
      // translation: transEN,
      translation: userInformation().userType === USER_TYPE.EDBA_ADMIN || !keyValues || Object.keys(keyValues).length === 0 ? transEN :
        JSON.parse(keyValues),
    },
  },
};

// Initialize i18n
i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(languageDetector)
  .init(options, (err, t) => {
    if (err) {
      throw err;
    }
  });
export default i18n;

