export function handlePendingPaymentData(
  data: any,
  setInfoModal: Function,
  setClassName: Function,
  setFeeType: Function,
) {
  const admissionFeeExist = data?.admissionFee && data?.admissionFee?.length > 0;
  const courseFeeExist = data?.courseFee;

  if (admissionFeeExist || courseFeeExist) {
    if (admissionFeeExist) {
      setFeeType('admission');
      if (data?.admissionFee[0]?.type === 'PROMOTED') {
        handleUpdateInfoModal(
          'Promotion',
          setInfoModal,
          setClassName,
          data?.admissionFee[0]?.className,
        );
      } else {
        handleUpdateInfoModal('Payment', setInfoModal, setClassName);
      }
    } else if (courseFeeExist) {
      setFeeType('course');
      if (data?.courseFee?.type === 'PROMOTED') {
        handleUpdateInfoModal('Promotion', setInfoModal, setClassName, data?.courseFee?.className);
      } else {
        handleUpdateInfoModal('Payment', setInfoModal, setClassName);
      }
    }
  }
}

export function handleUpdateInfoModal(
  type: string,
  setInfoModal: Function,
  setClassName: Function,
  className?: string,
) {
  setInfoModal({
    visibility: true,
    type: type,
  });
  if (className) setClassName(className);
}
