import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TouchableOpacity } from 'react-native';
import { useThemeSystem } from 'src/contexts/theme-context';
import { isWeb } from 'src/constant/device';
import { Tooltip } from '@mui/material';

export interface IconPropType {
  tooltipTitle?: string;
  onPress?: any;
  color?: string;
  size?: number;
  disabled?: boolean;
  disableClick?: boolean;
}

interface ActionIconPropType extends IconPropType {
  icon: IconProp;
}

export function ActionIcon(props: ActionIconPropType) {
  const { theme } = useThemeSystem();
  const { icon, tooltipTitle = "", onPress, color = theme?.table?.editIconColor, size, disabled = false, disableClick } = props;

  const IconButton = (
    <TouchableOpacity onPress={disabled ? () => { } : onPress} disabled={disableClick}>
      <FontAwesomeIcon
        icon={icon}
        color={color}
        size={size}
        style={{
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? 'auto' : 'pointer'
        }}
      />
    </TouchableOpacity>
  );

  if (isWeb) {
    return (
      <Tooltip title={tooltipTitle}>
        {IconButton}
      </Tooltip>
    );
  }

  return IconButton;
}


