import {
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from "src/components/molecules/Table/TableAtom";
import React, { useCallback, useEffect, useState } from "react";
import { createSearchFilter, debounce } from "src/components/services";

import { AUTOCOMPLETE_MODULE } from "src/constant";
import { Batch } from "./types";
import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import Search from "src/components/atoms/SearchBar/index.web";
import { TableBody } from "@mui/material";
import { colors } from "src/styles/theme/styles";
import { getCommonNameSearchFilter } from "src/components/services/filters";
import { height } from "src/constant/device";
import styled from "styled-components";
import { useFilter } from "src/contexts/filter-context";
import { useGetAllBatches } from "src/graphql/promotions/index";
import { useHeaderTitle } from "src/contexts/header-context";
import { useHistory } from "src/routes/routing.web";
import { useI18n } from "src/i18n/hooks";
import useTable from "src/components/molecules/Table/UseTable";

const headCells1 = [
  {
    id: "className",
    label: "className.label",
  },
  {
    id: "batchName",
    label: "batchName.label",
  },
];

const Promotions = () => {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const history = useHistory();
  const { filters, setFilter } = useFilter();

  let searchValue: string = "";

  if (filters && filters?.batch?.on) {
    searchValue = filters?.batch?.search;
  }

  const maxHeight: number = height - 284;
  const paginationHeightOffset: number = -72;

  const [searching, setSearching] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [row, setRow] = useState<Batch[]>();

  const { getAllBatches, allBatchesData, allBatchesLoading } = useGetAllBatches();

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row, headCells, maxHeight, row?.length,
    0, null, null, false, true, null, paginationHeightOffset
  );

  useEffect(() => {
    setHeading([{ text: t("promotions.label"), url: "" }]);
  }, []);

  useEffect(() => {
    setSearching(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [searchValue, headCells]);

  useEffect(() => {
    if (allBatchesData && allBatchesData?.batches) {
      setRow(allBatchesData.batches.data);
    }
  }, [allBatchesData]);

  const handleSearch = (searchVal: string) => {
    setSearching(searchVal);
    delayedQuery(searchVal);
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.BATCH]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchBatches(batch: string) {
    let isClear = batch?.length === 0;
    persistSearch(!isClear, batch);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchBatches(q), 500),
    []
  );

  function handleFetch() {
    let filters = getCommonNameSearchFilter(searchValue);
    let batchFilter = createSearchFilter(0, 0, filters);
    if (!batchFilter.filters) {
      batchFilter.filters = {};
    }
    getAllBatches({ variables: batchFilter });
  }

  const navigateToBatch = (batchId: string) => {
    history.push(`/promotions/${batchId}`);
  };

  return (
    <LoadContentWrapper>
      <TopSectionWrapper>
        <SearchWrapper>
          <Search
            id="promotionsSearch"
            handleChange={handleSearch}
            value={searching}
            label={t("search-promotions.text")}
          />
        </SearchWrapper>
      </TopSectionWrapper>
      <BodyWrapper>
        <TableDiv>
          <TblContainer>
            <TblHead setHeadCells={setHeadCells} />
            {recordsAfterPagingAndSorting() &&
              recordsAfterPagingAndSorting()?.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting().map(
                  (item: Batch, index: number) => (
                    <TableRows key={`t${index}`}>
                      <TableCells
                        value={`${item.class.name} (${item.class.alias})`}
                        color={colors.primaryColor}
                        clickable={true}
                        onPress={() => navigateToBatch(item.id)}
                      />
                      <TableCells
                        value={`${item.name} (${item?.alias || "-"})`}
                      />
                    </TableRows>
                  )
                )}
              </TableBody>
            ) : (
              !allBatchesLoading && (
                <NoRecordsFound colspan={2} maxHeight={maxHeight} />
              )
            )}
          </TblContainer>
        </TableDiv>
      </BodyWrapper>
    </LoadContentWrapper>
  );
};

const TopSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 10;
`;

const SearchWrapper = styled.div`
  align-items: flex-start;
  max-width: 280px;
  margin-right: 8px;
`;

const BodyWrapper = styled.div``;

export default Promotions;
