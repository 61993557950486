import { Modal, TouchableOpacity, StyleSheet, FlatList, Text } from 'react-native'
import React from 'react'
import styled from 'styled-components/native';
import NormalTextAtom from 'src/components/atoms/Text/NormalTextAtom';

type ListItemProps = {
  text: String;
  size: number;
  lineHeight: number;
  fontWeight: String;
}

export default function ListItem(props: ListItemProps) {
  const {
    text,
    size = 1.5,
    lineHeight = 2.5,
    fontWeight = 'normal',
  } = props
  return (
    <ItemContainer key={"items34"}>
      <NormalTextAtom
        value={'\u2022'}
        fontWeight={fontWeight}
        fontSize={size * 1.5}
        lineHeight={lineHeight}
      />
      <NormalTextAtom
        style={styles.textView}
        value={text}
        fontWeight={fontWeight}
        fontSize={size}
        lineHeight={lineHeight}
      />
    </ItemContainer>
  )
}

const ItemContainer = styled.View`
  width: 100%;
  padding-top: 2px;
  flex-direction: row;
`
const styles = StyleSheet.create({
  textView: {
    paddingLeft: 12
  }
})