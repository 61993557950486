import { colors, fonts } from "src/styles/theme/styles";

import NormalTextSelect from "src/components/atoms/Text/NormalTextSelect";
import React from "react";
import { isWeb } from "src/constant/device";
import styled from "styled-components/native";
import { useI18n } from "src/i18n/hooks";

interface PropTypes {
  data: {
    reason: string;
    user?: string;
    title: string;
  } | null;
  width?: number | string;
}

export default function BlockRejectionReason(props: PropTypes) {
  const { data, width = 400 } = props;
  const { t } = useI18n();
  return (
    <Container width={width}>
      <NormalTextSelect
        value={data?.reason}
        color={colors.secondaryText}
        fontsize={1.8}
      />
      {data?.user && (
        <BlockedDescView>
          <NormalTextSelect
            value={`${t("blockedBy.label")}: `}
            fontFamily={fonts.regular}
            fontsize={1.8}
            lineHeight={3}
          />
          <Wrapper>
            <NormalTextSelect
              value={data.user}
              color={colors.secondaryText}
              fontsize={1.8}
            />
          </Wrapper>
        </BlockedDescView>
      )}
    </Container>
  );
}

const Container = styled.View<{ width: number }>`
  width: ${({ width }) => width};
  margin-top: ${isWeb ? "0px" : "8px"};
`;

const BlockedDescView = styled.View`
  max-width: 100%;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Wrapper = styled.View`
  max-width: 100%;
`;
