import React, { useState } from 'react'
import { useHistory } from 'src/routes/routing';
import NormalModal from 'src/components/atoms/Modals/Normal/index'
import { isNative, isWeb } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import DirectMessageComposer from './DirectMessageComposer';
import MessageViewer from './MessageViewer';
import { useForm } from 'react-hook-form';
import navigationStrings from 'src/navigation/navigationStrings';
import { getMessageByIdQuery, inboxMessagesQuery, useReplyMessage } from 'src/graphql/messages';
import { ERROR, limit, MESSAGE_LIMIT, SUCCESS } from 'src/constant';
import { useQuery } from '@apollo/client';
import { ADD_SUCCESS } from 'src/constant/message';
import ERROR_MSG from 'src/constant/error';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { showNativeError, showNativeSuccess } from '../NotificationWrapper';

interface ModalProps {
  setMessageModal?: Function;
  messageType?: string;
  messageId?: string;
  fromMessage?: boolean;
  navigation?: any;
  page?: number;
  refetchMessage?: Function;
}

export default function MessageModalContent(props: ModalProps) {
  const {
    setMessageModal,
    fromMessage = false,
    page = 1,
    messageType,
    messageId,
    refetchMessage
  } = props;
  const [type, setType] = useState('message');
  const [canClick, setCanClick] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState(true);
  const { t } = useI18n();
  const history = useHistory();
  let { setAlertDetails } = useAlertSystem();
  const messageData = useQuery(getMessageByIdQuery, {
    variables: { messageId: messageId }
  });
  const { replyMessage, replyMessageError } = useReplyMessage();
  const selectedData = messageData?.data?.getMessage;

  const {
    handleSubmit: handleSubmitReply,
    control: controlReply,
    getValues: getValuesReply,
    setValue: setValueReply,
    setError: setErrorReply,
    clearErrors: clearErrorsReply,
    formState: { errors: errorsReply },
  } = useForm();

  function closeModalView() {
    resetForm();
    setModalOpen(false);
    setMessageModal(false);
    backHandler();
  }

  function backHandler() {
    if (isWeb) {
      if (!fromMessage) {
        history.push(`/messages/${messageType}/limit/${limit}/page/${page}`);
      }
    } else {
      if (fromMessage) {
        props.navigation.navigate(navigationStrings.MESSAGES, {
          messageType: messageType,
        })
      }
    }
  }

  function resetForm() {
    if (type === 'reply') {
      setTimeout(() => {
        setValueReply('message', '');
      }, 1000);
    }
    clearErrorsReply();
  }

  function showError(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: ERROR });
    } else {
      showNativeError(message);
    }
  }

  function showSuccess(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: SUCCESS });
    } else {
      showNativeSuccess(message);
    }
  }

  async function handleReplyMessage(formData: any) {
    if ((isNative && type === 'sent') || isWeb) {
      setCanClick(false);
    }
    try {
      if ((isNative && type === 'sent') || isWeb) {
        const payload = {
          body: formData?.message,
          subject: formData?.subject.slice(4),
          messageId: selectedData?.id,
        };
        const replyResponse = await replyMessage({
          variables: { payload: payload },
          refetchQueries: [
            {
              query: inboxMessagesQuery,
              variables: {
                limit: isWeb ? limit : MESSAGE_LIMIT,
                skip: 0,
              },
            },
          ],
        });
        if (replyResponse?.data?.replyMessage) {
          setCanClick(true);
          closeModalView();
          if (refetchMessage) {
            await refetchMessage();
          }
          showSuccess(ADD_SUCCESS.MESSAGE_SENT);
        }
      } else {
        setType('sent');
      }
    } catch (error: any) {
      showError(error.message);
      setCanClick(true);
    }
  }

  return (
    <NormalModal
      isSubmitting={!canClick}
      showFooter={type && type === 'message' ? false : true}
      modalVisible={modalOpen}
      setModalVisible={closeModalView}
      Headerpopup={t('message.label')}
      height={570}
      maxWidth={'lg'}
      handleSave={handleSubmitReply(handleReplyMessage)}
      cancelButtonLabel="cancel.label"
      addEditButtonLabel={
        isWeb
          ? 'send.label'
          : messageType === 'inbox'
            ? type === 'message'
              ? 'reply.label'
              : 'send.label'
            : ''
      }
      hideSubmit={messageType === 'sent' ? false : true}>
      {type && type === 'message' ? (
        <MessageViewer data={selectedData} setType={setType} isInbox={messageType === 'inbox'} />
      ) : (
        <DirectMessageComposer
          data={selectedData}
          control={controlReply}
          errors={errorsReply}
          setError={setErrorReply}
          clearErrors={clearErrorsReply}
          setValue={setValueReply}
          getValues={getValuesReply}
        />
      )}
    </NormalModal>
  );
}
