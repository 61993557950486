import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getSubjectCreditsQuery = gql`
  query(
    $filters: BatchSubjectCreditsFilterInput
    $limit: Int
    $skip: Int
    $sort: BatchSubjectCreditsSortInput
    $lookup: LookupFilter
  ) {
    subjectCredits(
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
      lookup: $lookup
    ) {
      data {
        id
        batch {
          id
          name
        }
        credits {
          credit
          subject {
            id
            name
          }
        }
      }
    }
  }
`;

export function useGetSubjectCredits() {
  const [
    getSubjectCredits,
    {
      loading: subjectCreditsLoading,
      data: subjectCreditsData,
      error: subjectCreditsError,
      refetch: refetchSubjectCredits,
    },
  ] = useLazyQuery(getSubjectCreditsQuery, { fetchPolicy: 'network-only' });
  return {
    getSubjectCredits,
    subjectCreditsData,
    subjectCreditsError,
    subjectCreditsLoading,
    refetchSubjectCredits,
  };
}

export const createBatchSubjectCreditsMutation = gql`
  mutation ($payload: BatchSubjectCreditsInput!) {
    createBatchSubjectCredits(payload: $payload) {
      id
      batch {
        id
        name
      }
      credits {
        credit
        subject {
          id
          name
        }
      }
    }
  }
`;

export function useCreateBatchSubjectCredits() {
  const [createBatchSubjectCredits, { data: createBatchSubjectCreditsData, error: createBatchSubjectCreditsError }] =
    useMutation(createBatchSubjectCreditsMutation);
  return { createBatchSubjectCredits, createBatchSubjectCreditsData, createBatchSubjectCreditsError };
}