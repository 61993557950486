import React, { CSSProperties } from "react";

import { CellExpand } from "../../Table/index.web";
import NormalTextSelect from "src/components/atoms/Text/NormalTextSelect";
import styled from "styled-components";

const styles: Record<string, CSSProperties> = {
  stringTypeCell: { minWidth: '200px' },
  numberTypeCell: { minWidth: '100px' },
  detailTypeCell: { minWidth: '48px' },
  dateTypeCell: { minWidth: '160px' },
  cellExpand: { boxShadow: 'none', maxHeight: 200, boxSizing: 'border-box' },
  cellExpandBox: { padding: 0, boxSizing: 'border-box' },
};

export const publicInvitationsHeadCells = [
  {
    id: "name",
    label: "name.label",
    align: "left",
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: "email",
    label: "email.label",
    align: "left",
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: "subjectGroup",
    label: "subjectGroup.label",
    align: "left",
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: "status",
    label: "status.label",
    align: "left",
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: "documents",
    label: "documents",
    align: "center",
    disableSorting: true,
    style: styles.detailTypeCell,
  },
  {
    id: "details",
    label: "Details",
    align: "center",
    disableSorting: true,
    style: styles.detailTypeCell,
  },
];

export type TPublicInvitee = {
  combinedInfo: string;
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  applicationId?: string;
  aadhaarId?: string;
  mobileNumber?: number;
  email: string;
  date?: number;
  dob?: number;
  aadhaarBelongsTo?: string;
  inviteSentAt?: number;
  subjectGroup?: {
    name: string;
  };
  siblingData?: {
    class: string;
    grNo: string;
  };
  address?: {
    houseNo: string;
    street?: string;
    area: string;
    city: string;
    state: string;
    zipcode: string;
  };
  documents: any[];
};

export const ApplicantSubmittedDocuments = ({
  item,
}: {
  item: TPublicInvitee;
}) => {
  const documents = item?.documents ?? [];
  return (
    <DocumentsWrapper>
      {documents?.length ? (
        <CellExpand
          width={240}
          align={'center'}
          files={documents}
          showDownloadIcon={true}
          lines={2}
        />
      ) : (
        <DocumentIcon>
          <NormalTextSelect value={'-'} />
        </DocumentIcon>
      )}
    </DocumentsWrapper>
  );
};

const DocumentsWrapper = styled.div`
  margin-top: 6px;
`;

const DocumentIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
