import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getCastesQuery = gql`
  query(
    $filters: CasteCategoryFilterInput
    $limit: Int
    $skip: Int
    $sort: CasteCategorySortInput
  ) {
    castes(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        alias
        id
        name
        defaultUserSelection
      }
      hasNextPage
      totalCount
    }
  }
`;

export const getCategoryOptionsQuery = gql`
  query ($filters: CasteCategoryFilterInput, $limit: Int, $skip: Int, $sort: CasteCategorySortInput) {
    castes(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        value: id
        label: name
      }
      hasNextPage
      totalCount
    }
  }
`;

export const createCasteMutation = gql`
  mutation ($payload: CasteCategoryInput!) {
    createCaste(payload: $payload) {
      alias
      id
      name
    }
  }
`;

export function useGetCastes() {
  const [
    getCastes,
    { loading: castesLoading, data: castesData, error: castesError, refetch: refetchCastes },
  ] = useLazyQuery(getCastesQuery, { fetchPolicy: 'network-only' });
  return { getCastes, castesData, castesError, castesLoading, refetchCastes };
}

export function useCreateCaste() {
  const [createCaste, { data: createCasteData, error: createCasteError }] =
    useMutation(createCasteMutation);
  return { createCaste, createCasteData, createCasteError };
}
