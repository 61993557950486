import { Dimensions, Keyboard, TouchableWithoutFeedback } from "react-native";
import {
  LoginStyledComponents as LSC,
  LoginComponentsStyles,
  LoginScreenBackgroundNative,
  LoginScreenEdbaLogo,
  LoginScreenHeaderTitles,
} from "./LoginHelpers";
import React, { useCallback, useEffect, useState } from "react";
import { breakpoints, colors, fontWeight } from "src/styles/theme/styles";
import { isIOS, isNative, isWeb } from "src/constant/device";

import AvoidKeyPad from "src/components/atoms/Wrapper/AvoidKey";
import Element from "src/components/molecules/Forms/ApplicationElement";
import FlashMessage from "react-native-flash-message";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import SmallTextAtom from "src/components/atoms/Text/SmallTextAtom";
import commonLoginFormJSON from "src/form-json/login/common-login.json";
import { getTokenFromStorage } from "src/components/services/storage-service";
import navigationStrings from "src/navigation/navigationStrings";
import { useForm } from "react-hook-form";
import { useHistory } from "src/routes/routing";
import { useI18n } from "src/i18n/hooks";
import { useParams } from "src/routes/routing.web";
import { userInformation } from "src/utils/manageState";
import { USER_TYPE } from "src/constant";
import { getApplicationPath } from "src/utils/utility";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";

const windowWidth = Dimensions.get("window").width;

interface Props {
  loginUser: (logindata: any) => void;
  canClick: boolean;
  navigation?: any;
}

export default function UserLoginForm(props: Props) {
  const history = useHistory();
  const { t } = useI18n();
  const { loginUser, canClick, navigation } = props;
  const currentUserObject = userInformation();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm();

  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    setElements(commonLoginFormJSON[0]);
  }, []);

  const handleKeyDown = useCallback((event) => {
    const { key } = event;
    if (key == 'Enter') {
      loginUser(getValues());
    }
  }, []);

  useEffect(() => {
    if (isWeb) {
      window.addEventListener('keydown', handleKeyDown, true);
      return () => {
        window.removeEventListener('keydown', handleKeyDown, true);
      };
    }
  }, []);

  // === Legacy Login === It should be refactored or removed in the future.
  const params: { prefix?: string, requestId?: string, capId?: string, inviteCode?: string } = isWeb ? useParams() : {};
  const { prefix, requestId, capId, inviteCode } = params;

  useEffect(() => {
    getTokenFromStorage().then((userExist) => {
      if (userExist && userExist !== null) {
        let path = "/dashboard";
        if (
          inviteCode &&
          currentUserObject?.userType &&
          currentUserObject.userType === USER_TYPE.APPLICANT
        ) {
          path += `/${inviteCode}`;
        }
        history.push(path);
      }
    });
  });

  const goToChangePassword = () => {
    let nativePath = navigationStrings.REQUEST_RESET_PASSWORD;
    if (isWeb) {
      window.location.href = `/request-reset-password.html`;
    } else {
      navigation.navigate(nativePath);
    }
  };

  function goToApplicantRegistration() {
    let path = '/applicant/signup';
    history.push(getApplicationPath(path, prefix, requestId, capId));
  }

  return (
    <>
      <FlashMessage position="top" />
      <AvoidKeyPad extraHeight={180} style={LoginComponentsStyles.keypad}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <LSC.Container>
            <LSC.PageAreaContainer>
              <LSC.LoginView>
                <LoginScreenEdbaLogo />
                {isNative && <LoginScreenBackgroundNative />}

                <LoginScreenHeaderTitles
                  signInDescription={"Unlock a world of possibilities."}
                  signInDescription2={"Sign in to access all the features."}
                />

                <LSC.FormWrapper>
                  {fields &&
                    fields.map((field: any, i: number) => (
                      <LSC.ElemWrapper key={`form${i}`}>
                        <Element
                          key={i}
                          field={field}
                          control={control}
                          errors={errors}
                          setError={setError}
                          clearErrors={clearErrors}
                        />
                      </LSC.ElemWrapper>
                    ))}
                </LSC.FormWrapper>

                {isWeb ? (
                  <LSC.ForgotPasswordWrapper>
                    <LSC.LinkView onPress={goToChangePassword}>
                      <SmallTextAtom
                        value={"Forgot password?"}
                        color={colors.primary}
                        fontWeight={fontWeight["600"]}
                      />
                    </LSC.LinkView>
                  </LSC.ForgotPasswordWrapper>
                ) : (
                  <LSC.ForgotPasswordWrapper onPress={goToChangePassword}>
                    <SmallTextAtom
                      value={"Forgot password?"}
                      color={colors.primary}
                      fontWeight={fontWeight[isIOS ? "600" : "bold"]}
                    />
                  </LSC.ForgotPasswordWrapper>
                )}

                {isWeb && windowWidth > breakpoints.md ? (
                  <>
                    <SecondaryBtn
                      canLoad={!canClick}
                      disabled={isSubmitting}
                      label={isSubmitting ? "Logging in..." : "Sign In"}
                      onPress={handleSubmit(loginUser)}
                      style={LoginComponentsStyles.webLoginButton}
                    />
                    <LSC.ApplicantSignupWrapper onPress={goToApplicantRegistration}>
                      <NormaltextAtom
                        value={"Sign Up as Applicant"}
                        color={colors.primary}
                        fontSize={1.34}
                        fontWeight={fontWeight["600"]}
                      />
                    </LSC.ApplicantSignupWrapper>
                  </>
                ) : (
                  <>
                    <SecondaryBtn
                      canLoad={!canClick}
                      disabled={isSubmitting}
                      label={isSubmitting ? "Logging in..." : "Sign In"}
                      onPress={handleSubmit(loginUser)}
                      style={LoginComponentsStyles.nativeLoginButton}
                      lineHeight={2}
                    />
                    <LSC.ApplicantSignupWrapper
                      onPress={() =>
                        navigation.navigate(navigationStrings.SIGNUP_APPLICANT)
                      }
                    >
                      <SmallTextAtom
                        value={"Sign Up as Applicant"}
                        color={colors.primary}
                        fontWeight={fontWeight[isIOS ? "600" : "bold"]}
                      />
                    </LSC.ApplicantSignupWrapper>
                  </>
                )}
              </LSC.LoginView>
            </LSC.PageAreaContainer>
          </LSC.Container>
        </TouchableWithoutFeedback>
      </AvoidKeyPad>
    </>
  );
}
