/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import noticesFormJSON from '../../../form-json/communication/notices.json';
import Element from '../Forms/ApplicationElement';
import { isWeb } from 'src/constant/device';

export default function NoticeForm(props: any) {
  const {
    handleFilesAttach,
    handleFileRemove,
    clearErrors,
    fileObjects,
    errors,
    control,
    setError,
    setAnnoucementWay,
    annoucementWay,
  } = props;

  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    loadForm();
  }, []);

  function loadForm() {
    setElements(noticesFormJSON[0]);
  }

  const handleNotification = () => {
    if (annoucementWay) {
      setAnnoucementWay(false);
    } else {
      setAnnoucementWay(true);
    }
  };

  return (
    <View>
      {fields ? (
        fields.map((field: any, i: number) =>
          <View key={`noticesf${i}`} style={{ paddingBottom: 0 }}>
            <Element
              field={field}
              control={control}
              errors={errors}
              file={fileObjects[field.id]}
              handleFilesAttach={handleFilesAttach(field.id)}
              handleFileRemove={handleFileRemove(field.id)}
              handleSwitch={handleNotification}
              elementStyle={field.type === 'switch' && { marginLeft: isWeb ? -10 : 0 }}
              switchState={annoucementWay}
              fileWidth={350}
              fileLabel={'Add Image'}
              dropdownMargin={field?.type === 'select' && 1}
              profile={false}
              setError={setError}
              clearErrors={clearErrors}
            />
          </View>
        )
      ) : (
        <View style={styles.spinnerView} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: {
    height: 480,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
