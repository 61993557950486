import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { colors, fonts } from '../../../../styles/theme/styles';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useMutation, useQuery } from '@apollo/client';
import { attendanceSlots, attendanceSlotsList, markClassAttendence } from 'src/graphql/attendance';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ERROR, SUCCESS } from 'src/constant';
import { UPDATE_SUCCESS } from 'src/constant/message';
import { useI18n } from 'src/i18n/hooks';
import { useForm } from 'react-hook-form';
import MarkAttendanceForm from 'src/components/molecules/Attendance';
import AttendanceNotConfigured from '../AttendanceNotConfigured';
import { graphqlQuery } from 'src/graphql/util';
import OutlineButtton from 'src/components/atoms/Button/OutlineButtton';
import MediumText from 'src/components/atoms/Text/MediumText';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import MarkAttendance from '../NativeScreens/MarkAttendance';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { DT } from 'src/constant/dateTime';
import { format, parse, startOfToday } from 'date-fns';
import HeaderFour from 'src/components/atoms/Text/HeaderFour';
import { AttendanceBatchDetails, SlotObj } from 'src/components/molecules/Attendance/AttendanceTypes';

const headerCols = [
  {
    id: 'rollno',
    label: 'rollno.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'rollNo',
    isSorted: false,
  },
  {
    id: 'student_name',
    label: 'studentName.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'fullName',
    isSorted: false,
  },
  {
    id: 'status',
    label: 'status.label',
    align: 'left',
    disableSorting: true,
  },
];

const AttendaceChild = () => {
  const { t } = useI18n();
  const attendanceType = [
    { value: 0, label: t('fullDay.label') },
    { value: 1, label: t('slots.label') },
    { value: 2, label: t('subjects.label') },
  ];

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const timezone = DT.TIMEZONE;
  const [step, setStep] = useState<number>(0);
  const { setAlertDetails } = useAlertSystem();
  const todaysDate = format(startOfToday(), DT.DATE_FORMAT_SLASH);
  const [studentsDataAvailable, setStudentsDataAvailable] = useState<boolean>(false);

  const [dates, setDates] = useState<{ currentDate: string }>({
    currentDate: todaysDate,
  });

  const [mainLoader, setMainLoader] = useState({
    slotLoading: true,
    attendenceTypeLoading: true,
    batches: true,
  });

  interface studentsAttendenceInterface {
    [key: string]: boolean;
  }

  const [studentsAttendenceInfo, setStudentsAttendenceInfo] =
    useState<studentsAttendenceInterface | null>(null);

  const [totalStudents, setTotalStudents] = useState<number>(1);
  const [totalStudentPresent, setTotalStudentPresent] = useState<number>(0);

  const [batches, setbatches] = useState<any>();

  const [selectedDivison, setSelectedDivison] = useState<string>('');
  const [isAttendanceSettingConfigured, setIsAttendanceSettingConfigured] = useState<boolean>(true);
  const [headCells, setHeadCells] = useState(headerCols);

  interface selectedAttendence {
    value: number;
    label: string;
  }

  const [selectedAttendenceType, setSelectedAttendenceType] = useState<selectedAttendence>(
    {} as selectedAttendence,
  );

  const [selectedBatchInformation, setSelectedBatchInformation] =
    useState<AttendanceBatchDetails | null>(null);

  const [selectedSlotInformation, setSelectedSlotInformation] = useState<SlotObj | null>(null);
  const [slots, setSlots] = useState<SlotObj[] | null>(null);

  const [markClassAttendenceMutateFunction] = useMutation(markClassAttendence);

  const { data: slotsData, loading: slotsDataloading } = useQuery(attendanceSlots, {
    fetchPolicy: 'network-only',
  });

  const { data: batchesData, loading: classesDataloading } = useQuery(getBatchQuery, {
    variables: { limit: 0 },
    fetchPolicy: 'network-only',
  });

  function slotHandler(index: number) {
    if (index === 0) {
      return { slot: 'DAY', slotName: 'DAY' };
    } else {
      return {
        slot: selectedSlotInformation?.slotId,
        slotName: selectedSlotInformation?.label,
      };
    }
  }

  function handleNext(payload: any) {
    if (payload?.slotsarray) {
      setSelectedSlotInformation({
        value: payload.slotsarray.value,
        slotId: payload.slotsarray.slotId,
        label: payload.slotsarray.label,
      });
    }
    setSelectedBatchInformation(payload.batch);
    setSelectedDivison(payload.division.label);
    setStep(s => s + 1);
  }

  function loadBatches() {
    if (batchesData.batches.data.length) {
      setbatches(batchesData.batches.data);
    }
    setMainLoader(prev => ({ ...prev, batches: false }));
  }

  async function setSlotsData() {
    if (!slotsData?.institute?.settings?.attendanceType) {
      setIsAttendanceSettingConfigured(false);
    } else {
      if (slotsData?.institute.settings.attendanceType === 'DAY_WISE') {
        setSelectedAttendenceType(attendanceType[0]);
      } else {
        if (slotsData?.institute.settings.attendanceType === 'SLOT_WISE') {
          setSelectedAttendenceType(attendanceType[1]);
          const { loading, data: slotsdatalist } = await graphqlQuery(
            attendanceSlotsList,
            {
              input: {
                date: dates.currentDate,
                timezone,
              },
            },
            true,
          );
          if (!loading && slotsdatalist) {
            const arr = slotsdatalist?.getAttendanceSlots.map(item => {
              return { value: item.slot, label: item.slotName, slotId: item.slot };
            });
            setSlots(arr);
          }
        } else {
          setSelectedAttendenceType(attendanceType[2]);
        }
      }

      setMainLoader(prev => ({
        ...prev,
        slotLoading: false,
        attendenceTypeLoading: false,
      }));
      setIsAttendanceSettingConfigured(true);
    }
  }

  async function markAttendenceOfStudents() {
    const arr = [];
    for (const key in studentsAttendenceInfo) {
      arr.push({ attendance: studentsAttendenceInfo[key] ? 'PRESENT' : 'ABSENT', user: key });
    }

    try {
      const { slot, slotName } = slotHandler(selectedAttendenceType.value);
      const attendenceInfo = await markClassAttendenceMutateFunction({
        variables: {
          payload: {
            attendance: arr,
            batch: selectedBatchInformation?.value,
            class: selectedBatchInformation?.class?.id,
            division: selectedDivison,
            date: dates.currentDate,
            slot,
            slotName,
          },
        },
      });
      if (attendenceInfo) {
        setAlertDetails({ message: UPDATE_SUCCESS.ATTENDANCE, level: SUCCESS });
        setStep(x => x - 1);
      }
    } catch (err) {
      setAlertDetails({ message: err?.message, level: ERROR });
    }
    setTimeout(() => {
      setAlertDetails({ message: '', level: '' });
    }, 2500);
    resetHeadCells();
  }

  useEffect(() => {
    if (!slotsDataloading && slotsData) {
      setSlotsData();
    }

    return () => {
      reset({});
    };
  }, [slotsDataloading]);

  useEffect(() => {
    if (batchesData && !classesDataloading) {
      loadBatches();
    }
  }, [batchesData]);

  if (!isAttendanceSettingConfigured) {
    return <AttendanceNotConfigured type={0} topPosition={'30%'} />;
  } else if (mainLoader.slotLoading || mainLoader.attendenceTypeLoading || mainLoader.batches) {
    return (
      <SpinnerWrapper>
        <LoaderSpinner />
      </SpinnerWrapper>
    );
  }

  function resetHeadCells() {
    const tempHeadCells = [...headCells];
    tempHeadCells[0].ascend = true;
    tempHeadCells[1].ascend = true;
    setHeadCells(tempHeadCells);
  }

  return (
    <>
      {!batches ? (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      ) : batches[0].value === '' || !isAttendanceSettingConfigured ? (
        <AttendanceNotConfigured type={0} topPosition={'30%'} />
      ) : step === 0 ? (
        <MarkAttendanceWrapper>
          <HeaderFour
            fontFamily={fonts.semibold}
            color={colors.secondaryText}
            value={
              todaysDate === dates.currentDate
                ? t('todaysAttendance.text')
                : format(
                    parse(dates.currentDate, DT.DATE_FORMAT_SLASH, new Date()),
                    DT.ORDINAL_DAY_WITH_FULL_MONTH,
                  ) +
                  ' ' +
                  t('attendance.label')
            }
          />
          <FieldWrapper>
            <MarkAttendanceForm
              control={control}
              errors={errors}
              reset={reset}
              slots={slots}
              setValue={setValue}
              batches={batches}
              attendanceType={selectedAttendenceType}
              setDates={setDates}
              setSlots={setSlots}
              dates={dates}
            />
          </FieldWrapper>
          <SecondaryBtn
            label={t('next.label')}
            onPress={handleSubmit(handleNext)}
            style={{
              display: 'inline-block',
              marginTop: '20px',
              marginBottom: '20px',
              lineHeight: '30px',
              paddingLeft: '25px',
              paddingRight: '25px',
              width: 'fit-content',
            }}
          />
        </MarkAttendanceWrapper>
      ) : (
        <>
          <ContentWrapper>
            <LeftContent>
              <ProgressBarWrapper style={{ width: 120 }}>
                <CircularProgressbarWithChildren
                  styles={buildStyles({
                    rotation: 0,
                    strokeLinecap: 'butt',
                    textSize: '16px',
                    pathTransitionDuration: 0.5,
                    pathColor: `#2B78CA`,
                    textColor: '#2B78CA',
                    trailColor: '#fff',
                    backgroundColor: '#3e98c7',
                  })}
                  value={(totalStudentPresent / totalStudents) * 100}>
                  <MediumText
                    value={t('present.label')}
                    lineHeight={2.4}
                    fontWeight={'400'}
                    color={colors.primaryColor}
                  />
                  <HeaderThree
                    value={`${((totalStudentPresent / totalStudents) * 100).toFixed(0)}%`}
                    lineHeight={32}
                    fontWeight={'600'}
                    color={colors.primaryColor}
                  />
                </CircularProgressbarWithChildren>
              </ProgressBarWrapper>

              <AttendanceInformation>
                <AttendanceHeading>
                  {todaysDate === dates.currentDate
                    ? t('todays.label') + ' ' + t('attendance.label')
                    : format(
                        parse(dates.currentDate, DT.DATE_FORMAT_SLASH, new Date()),
                        DT.ORDINAL_DAY_WITH_FULL_MONTH,
                      ) +
                      ' ' +
                      t('attendance.label')}
                </AttendanceHeading>
                {selectedAttendenceType.value === 0 ? (
                  <AttendanceInfo1>{selectedAttendenceType.label}</AttendanceInfo1>
                ) : (
                  <AttendanceInfo1>{selectedSlotInformation?.label}</AttendanceInfo1>
                )}

                {selectedBatchInformation && (
                  <AttendanceInfo2>
                    {selectedBatchInformation?.label +
                      ' - ' +
                      selectedBatchInformation?.class?.alias +
                      ' - ' +
                      selectedDivison}
                  </AttendanceInfo2>
                )}
              </AttendanceInformation>
            </LeftContent>
            <ReginContent>
              <OutlineButtton
                label={t('cancel.label')}
                onPress={() => {
                  setStep(0);
                  resetHeadCells();
                }}
                style={{
                  display: 'inline-block',
                  marginTop: '32px',
                  lineHeight: '30px',
                  marginRight: '16px',
                }}
              />
              <SecondaryBtn
                customDisabled={!studentsDataAvailable}
                label={t('markAttendance.text')}
                onPress={async () => {
                  await markAttendenceOfStudents();
                }}
                style={{
                  display: 'inline-block',
                  marginTop: '32px',
                  marginottom: '32px',
                  lineHeight: '30px',
                }}
              />
            </ReginContent>
          </ContentWrapper>
          {selectedBatchInformation &&
            selectedDivison &&
            selectedAttendenceType &&
            (selectedSlotInformation || selectedAttendenceType.value === 0) && (
              <MarkAttendance
                setStudentsAttendenceInfo={setStudentsAttendenceInfo}
                studentsAttendenceInfo={studentsAttendenceInfo}
                totalStudentPresent={totalStudentPresent}
                setTotalStudentPresent={setTotalStudentPresent}
                batch={selectedBatchInformation}
                divisioninfo={selectedDivison}
                attendenceType={[selectedAttendenceType]}
                selectedSlotInfo={[selectedSlotInformation]}
                currentDate={dates.currentDate}
                setTotalStudents={setTotalStudents}
                headCells={headCells}
                setHeadCells={setHeadCells}
                setStudentsDataAvailable={setStudentsDataAvailable}
              />
            )}
        </>
      )}
    </>
  );
};

export default AttendaceChild;
const MarkAttendanceWrapper = styled.div`
  z-index: 0;
`;

const FieldWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 24px 0;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const AttendanceHeading = styled.div`
  font-size: 16px;
  font-family: ${fonts.semibold};
  color: ${colors.primaryText};
  margin-bottom: 10px;
`;

const AttendanceInfo1 = styled.div`
  font-size: 16px;
  font-family: ${fonts.semibold};
  color: ${colors.secondaryText};
  margin-bottom: 10px;
`;
const AttendanceInfo2 = styled.div`
  font-size: 15px;
  font-family: ${fonts.semibold};
  color: ${colors.tertiaryText};
  margin-bottom: 10px;
`;

const AttendanceInformation = styled.div`
  padding-left: 20px;
`;
const ReginContent = styled.div``;

const ProgressBarWrapper = styled.div``;

const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 450px;
  justify-content: center;
`;
