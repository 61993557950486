import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={16 * scale}
      height={16 * scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M10.001 2h-.5v-.5C9.5.673 8.828 0 8 0H6c-.828 0-1.5.673-1.5 1.5V2H4A4 4 0 000 6v8a2 2 0 002 2h10.001a2 2 0 002-2V6a4 4 0 00-4-4zm-4.5-.5A.5.5 0 016 1h2a.5.5 0 01.5.5V2h-3v-.5zm5 13.5h-7v-3h7v3zm0-4h-7v-1a1 1 0 011-1h5a1 1 0 011 1v1zm2.5 3c0 .551-.448 1-1 1h-.5v-5c0-1.103-.897-2-2-2h-5c-1.103 0-2 .897-2 2v5H2c-.552 0-1-.449-1-1V6c0-1.654 1.345-3 3-3h6c1.655 0 3 1.346 3 3v8zm-3.25-9h-5.5a.25.25 0 00-.25.25v.5c0 .138.111.25.25.25h5.5a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25z"
        fill="#000"
        fillOpacity={0.6}
      />
    </Svg>
  );
}

export default SvgComponent;
