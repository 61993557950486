const ERROR_MSG = {
  INVALID_USER_INFORMATION: 'Invalid user information',
  DOMAIN_ALREADY_EXIST: 'This is already used. Give unique name.',
  INSITUTE_INVALID: 'Institute id is not present',
  NO_PERMISSON: 'You dont have permission',
  GENERIC_ERROR: 'Something went wrong',
  ADD_INSTITUTE_ADMIN_ACC_ERR: "Can't add Institute Admin account, Please login from web",
  INVALID_TOKEN_ACCOUNT_ADD: 'Failed to add account: Invalid token',
  PAYMENT_UNSUCCESSFUL: 'Payment Unsuccessful',
  DUPLICATE_FEE_COMPOSITION: 'Fee Composition already exists',
  CONCESSION_AMOUNT_REQUIRED: 'Concession amount is required',
  ACCOUNT_ALREADY_ACTIVE: "Account is already active",
  ACCOUNT_NOT_ACTIVE: "Account is not active yet",
  STUDENTS_REQUIRED: "Student is required",
  FILE_REQUIRED: "File is required",
  FILE_SIZE_EXCEEDS: "File size exceeds 20 MB",
  INVALID_FILE_TYPE: "Invalid File type",
  TOTAL_FILE_SIZE_EXCEEDS: "File size exceeds 20 MB",
  REGISTRATION_FAILED: "Registration failed",
  DUPLICATE_ASSESSMENT_TEST: "Duplicate: Test already exists",
  DUPLICATE_APPLICATION: "Application already submitted",
  PAYMENT_PROCESSING_FAILED: 'Payment processing failed. Please try again later or contact customer support.',
};

export default ERROR_MSG;
