import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { colors, fonts } from 'src/styles/theme/styles';

interface IProps {
  style: CSSProperties;
  heading: string;
  desc: string;
  children: ReactNode;
  type?: "admin" | "default";
  onClick?: () => void;
}

const DashboardColorBlock = ({ style, heading = '', desc = '', children, type = "default", onClick }: IProps) => {
  return (
    <Block style={{ ...style, cursor: onClick ? 'pointer' : 'auto' }} onClick={onClick}>
      <ImageBlock>{children && children}</ImageBlock>
      <TextBlock>
        <StyledTitleText>{heading}</StyledTitleText>

        {type === 'admin' ? (
          <StyledDescTextBold>{desc}</StyledDescTextBold>
        ) : (
          <StyledDescText>{desc}</StyledDescText>
        )}
      </TextBlock>
    </Block>
  );
};

export default DashboardColorBlock;

const Block = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colors.purple};
  padding: ${({ theme }) => theme.rem([1, 3.2])};
  border-radius: ${({ theme }) => theme.rem(1.6)};
  align-items: center;
  box-sizing: border-box;
  margin-bottom: ${({ theme }) => theme.rem(4.5)};
  min-height: ${({ theme }) => theme.rem(13)};
  @media all and (min-width: 1101px) {
    padding: ${({ theme }) => theme.rem([1])};
    min-height: ${({ theme }) => theme.rem(11.7)};
    margin-bottom: ${({ theme }) => theme.rem(3)};
  }
  @media all and (min-width: 1025px) and (max-width: 1100px) {
    padding: ${({ theme }) => theme.rem([0.75, 1])};
    min-height: ${({ theme }) => theme.rem(11)};
    margin-bottom: ${({ theme }) => theme.rem(3)};
  }
  @media all and (min-width: 992px) and (max-width: 1024px) {
    padding: ${({ theme }) => theme.rem([1, 2])};
    min-height: ${({ theme }) => theme.rem(11.7)};
    margin-bottom: ${({ theme }) => theme.rem(3)};
  }
  @media all and (max-width: 991px) {
    min-height: ${({ theme }) => theme.rem(11)};
    margin-bottom: ${({ theme }) => theme.rem(3.5)};
    min-width: ${({ theme }) => theme.rem(28.5)};
  }
`;

const ImageBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.rem(7.2)};
  min-width: ${({ theme }) => theme.rem(7.2)};
  height: ${({ theme }) => theme.rem(7.2)};
  overflow: hidden;
  padding: ${({ theme }) => theme.rem(1)};
  border-radius: 50%;
  box-sizing: border-box;
  background-color: ${colors.lighterBorder};
  box-sizing: border-box;
  @media all and (max-width: 991px) {
    width: 42px;
    min-width: 42px;
    height: 42px;
  }
  @media all and (min-width: 992px) {
    width: 48px;
    min-width: 48px;
    height: 48px;
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  padding-left: ${({ theme }) => theme.rem(1.0)};
`;

const StyledTitleText = styled.text`
  font-size: ${({ theme }) => theme.rem(2.1)};
  color: ${colors.white};
  font-family: ${fonts.medium};
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.rem(0.4)};
  @media all and (min-width: 1025px) and (max-width: 1100px) {
    font-size: ${({ theme }) => theme.rem(1.8)};
  }
  @media all and (min-width: 992px) and (max-width: 1024px) {
    font-size: ${({ theme }) => theme.rem(2)};
  }
`;

const StyledDescTextBold = styled.text`
  color: ${colors.white};
  font-size: ${({ theme }) => theme.rem(3.2)};
  line-height: ${({ theme }) => theme.rem(4)};
  font-family: ${fonts.regular};
  @media all and (min-width: 992px) and (max-width: 1450px) {
    font-size: ${({ theme }) => theme.rem(2.8)};
  }
`;

const StyledDescText = styled.text`
  color: ${colors.white};
  font-size: ${({ theme }) => theme.rem(1.5)};
  font-family: ${fonts.light};
  @media all and (min-width: 1025px) and (max-width: 1100px) {
    font-size: ${({ theme }) => theme.rem(1.4)};
  }
`;
