import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableRow, styled } from '@mui/material';
import Input from 'src/components/atoms/Input/input.web';
import { View } from 'react-native';
import { colors } from 'src/styles/theme/styles';
import useTable from './UseTable';
import Check from 'src/components/atoms/CheckBox/index.web';
import { TableRows } from './TableAtom';
import { useI18n } from 'src/i18n/hooks';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';

interface Props {
  header: string;
  tabledata: Array<any>;
  handleCustomOnChange: Function;
  customOnChange: Function;
  setInputTableDataObj: Function;
  isDataAvailable: boolean;
}

const maxHeight = 500;

export default function ReservationGroupTable(props: Props) {
  const {
    header,
    tabledata,
    handleCustomOnChange,
    customOnChange,
    setInputTableDataObj,
    isDataAvailable,
  } = props;
  const { t } = useI18n();
  const [value, setValue] = useState({});
  const [firstVal, setFirstVal] = useState(100);
  const [tabledataState] = useState(tabledata);
  const [checked, setChecked] = useState([]);
  const [fieldDisabled, setFieldDisabled] = useState<boolean[]>(
    new Array(tabledata?.length).fill(true),
  );
  const { TblContainer, TblHead } = useTable(tabledataState, header, maxHeight, tabledata);

  const handleChange = (index: number, item: any) => {
    handleChecked(item?.id);
    if (value[item?.id]) {
      setValue((prev) => ({ ...prev, [item?.id]: '' }));
    }
    let temp = fieldDisabled;
    temp[index] = !fieldDisabled[index];
    setFieldDisabled([...temp]);
    calculateTotal();
  };

  const handleChecked = (id: string) => {
    const proxy = [...checked];
    if (proxy.includes(id)) {
      const index = proxy.indexOf(id);
      if (index > -1) {
        proxy.splice(index, 1);
      }
    } else {
      proxy.push(id);
    }
    setChecked(proxy);
  };

  function calculateTotal() {
    const total = Object.keys(value).reduce((t, key) => {
      if (value[key] !== '') {
        return t + value[key];
      }
      return t;
    }, 0);
    const firstInputVal = 100 - total <= 0 ? 0 : 100 - total;
    setFirstVal(firstInputVal);
    setData(firstInputVal);
  }

  function setData(firstInputVal: number) {
    const data = Object.keys(value).flatMap((key) => {
      if (value[key] && value[key] !== '') {
        return {
          caste: key,
          reservePercentage: value[key],
        };
      }
      return [];
    });

    const firstItem = tabledata.find((item) => item.name.toLowerCase() === 'open');
    if (firstItem) {
      data.push({
        caste: firstItem.id,
        reservePercentage: firstInputVal,
      });
    }
    setInputTableDataObj(data);
  }

  useEffect(() => {
    if (tabledata) {
      let fieldDisabled: boolean;
      setFieldDisabled(
        Array.from({ length: tabledata?.length }, (val, index) => {
          if (
            tabledata[index].name.toLowerCase() === 'institute level' ||
            value[tabledata[index]?.id] ||
            tabledata[index].value
          ) {
            fieldDisabled = false;
          } else {
            fieldDisabled = true;
          }
          return fieldDisabled;
        }),
      );

      if (tabledata.length > 0 && (tabledata[0].value || tabledata[0].value === 0)) {
        let items = { ...value };
        const proxy = [...checked];
        tabledata.forEach((item: any, index: number) => {
          if (index === 0 && isCategoryOpen(item?.name)) {
            setFirstVal(item?.value);
          } else {
            if (item?.value) {
              items[item?.id] = item?.value;
              if (!proxy.includes(item?.id)) {
                proxy.push(item?.id);
              }
            }
          }
        });
        setChecked(proxy);
        setValue(items);
      }
    }
  }, [tabledata]);

  useEffect(() => {
    calculateTotal();
  }, [value]);

  const isCategoryOpen = (name: string) => {
    return name.toLowerCase() === 'open';
  };

  const isCategoryInstituteLevel = (name: string) => {
    return name.toLowerCase() === 'institute level';
  };

  return (
    <View style={styles.containerStyle}>
      <TblContainer tabledata={tabledata}>
        <TblHead />
        {tabledata && tabledata.length > 0 && (
          <TableBody>
            {tabledata.map((item: any, i: number) => (
              <CTableRow key={i}>
                <TableCell style={{ paddingRight: 0 }}>
                  <Check
                    same={false}
                    onChange={() => handleChange(i, item)}
                    checked={
                      isCategoryOpen(item?.name) ||
                      isCategoryInstituteLevel(item?.name) ||
                      checked.includes(item?.id)
                    }
                    disabled={isCategoryOpen(item?.name) || isCategoryInstituteLevel(item?.name)}
                  />
                </TableCell>
                <TableCell style={styles.textStyle}>{item?.name}</TableCell>
                <TableCell style={styles.inputTableStyle}>
                  <Input
                    placeholder={0}
                    inputProps={{ step: '0.1' }}
                    typeInput={'number'}
                    id={i}
                    value={
                      isCategoryOpen(item?.name)
                        ? firstVal
                        : value[item?.id] || value[item?.id] === ''
                          ? value[item?.id]
                          : item?.value
                            ? item?.value
                            : ''
                    }
                    setValue={e => {
                      if (e.target.value <= 100) {
                        setValue(prev => ({
                          ...prev,
                          [item?.id]: e.target.value ? parseFloat(e.target.value) : '',
                        }));
                        customOnChange ? handleCustomOnChange(e.target.value) : null;
                      }
                    }}
                    width={140}
                    disabled={fieldDisabled[i]}
                  />
                  <View style={styles.inputPrefixStyle}>
                    <NormalTextSelect value={'%'} />
                  </View>
                </TableCell>
              </CTableRow>
            ))}
          </TableBody>
        )}
        {isDataAvailable && tabledata?.length === 0 && (
          <TableRows key={'no-record'} style={{ height: 48 }}>
            <TableCell style={{ borderBottom: 'none' }} colSpan={3} align={'center'}>
              {t('no-records-found.text')}
            </TableCell>
          </TableRows>
        )}
      </TblContainer>
    </View>
  );
}

const styles = {
  containerStyle: {
    marginBottom: 16,
  },
  textStyle: {
    fontSize: 15,
    color: colors.primaryText,
    fontWeight: 400,
    width: 225,
  },
  inputTableStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '11px 15px',
    fontSize: 15,
    color: colors.primaryText,
    fontWeight: 400,
  },
  inputPrefixStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
};

const CTableRow = styled(TableRow)`
  height: 40px;
`;