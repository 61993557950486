import { gql } from '@apollo/client';

const getListOfClassesOfEmployee = gql`
  query ($employeeId: String!) {
    instituteEmployee(employeeId: $employeeId) {
      classDetails {
        class {
          label: name
          value: id
        }
      }
    }
  }
`;
const getTotalAttendence = gql`
  query ($payload: GetAttendanceInput!) {
    totalAttendance(payload: $payload) {
      user {
        firstName
      }
      data {
        division
        present
        total
      }
    }
  }
`;

const markAttendence = gql`
  mutation ($payload: AttendanceInput!) {
    markAttendance(payload: $payload) {
      user
      detail {
        slotName
        slot
      }
      id
    }
  }
`;

const markClassAttendence = gql`
  mutation ($payload: ClassAttendanceInput!) {
    markClassAttendance(payload: $payload) {
      status
    }
  }
`;

const dayWiseAttendence = gql`
  query ($payload: GetAttendanceInput!) {
    dayWiseAttendance(payload: $payload) {
      user {
        id
        firstName
        lastName
        academic{
          rollNo
        }
        personalDetails {
          fullName
        }
      }
      data {
        date
        attendance
        onlyDate
      }
      total
      present
    }
  }
`;

const updateInstituteSettings = gql`
  mutation ($payload: InstituteSettingsInput!, $remove: RemoveInstituteSettingsInput) {
    updateInstituteSettings(payload: $payload, remove: $remove) {
      status
      name
      id
    }
  }
`;

const attendanceSlotsList = gql`
  query ($input: GetAttendanceSlotInput) {
    getAttendanceSlots(input: $input) {
      slot
      slotName
      start
      end
    }
  }
`;

const attendanceSlots = gql`
  query {
    institute {
      settings {
        grNoPrefix
        startingGRNo
        attendanceSlots
        attendanceType
        documentHeader {
          mainHeader
          subHeader
          superHeader
          email
          contact
          website
          address
        }
        idCardOrientation
        registrationNoLabelValue
        students {
          showOnboarding
          allowProfileEdit
        }
        employee {
          showOnboarding
          allowProfileEdit
        }
        logo {
          id
          name: documentName
          uri: documentUrl
        }
        stamp {
          id
          name: documentName
          uri: documentUrl
        }
        idCardSignature {
          holderName
          signatureImage {
            id
            name: documentName
            uri: documentUrl
          }
        }
        leavingCertcSignature1 {
          holderName
          signatureImage {
            id
            name: documentName
            uri: documentUrl
          }
        }
        leavingCertcSignature2 {
          holderName
          signatureImage {
            id
            name: documentName
            uri: documentUrl
          }
        }
        bonafiedCertSignature1 {
          holderName
          signatureImage {
            id
            name: documentName
            uri: documentUrl
          }
        }
        bonafiedCertSignature2 {
          holderName
          signatureImage {
            id
            name: documentName
            uri: documentUrl
          }
        }
        feeReceiptSignature {
          holderName
          signatureImage {
            id
            name: documentName
            uri: documentUrl
          }
        }
        libraryCardSignature {
          holderName
          signatureImage {
            id
            name: documentName
            uri: documentUrl
          }
        }
      }
    }
  }
`;

const slotsWiseAttendance = gql`
  query ($payload: GetAttendanceInput!) {
    slotWise(payload: $payload) {
      present
      slot
      slotName
      total
    }
  }
`;

const slotsWiseUserAttendance = gql`
  query ($payload: GetAttendanceInput!) {
    slotWiseForUser(payload: $payload) {
      data {
        present
        slot
        slotName
        total
      }
    }
  }
`;

const getStudentInfo = gql`
  query ($studentId: String!) {
    instituteStudent(studentId: $studentId) {
      id
      institute {
        addressDetails {
          area
          city
        }
      }
      academic {
        rollNo
        division
        batch {
          id
          name
          alias
        }
        class {
          id
          name
          alias
        }
      }
      firstName
      lastName
      personalDetails {
        fullName
      }
    }
  }
`;

export {
  getListOfClassesOfEmployee,
  getTotalAttendence,
  markAttendence,
  dayWiseAttendence,
  markClassAttendence,
  updateInstituteSettings,
  attendanceSlots,
  slotsWiseAttendance,
  getStudentInfo,
  slotsWiseUserAttendance,
  attendanceSlotsList,
};
