import React, { useState, useEffect, useCallback, useRef } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useLocation } from 'react-router-dom';
import LoadContentWrapper from '../../../atoms/Wrapper/LoadContent';
import Search from '../../../atoms/SearchBar/index.web';
import { useForm } from 'react-hook-form';
import NormalModal from '../../../atoms/Modals/Normal/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { ClickAwayListener, TableBody, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFileCertificate, faFileInvoiceDollar, faFilePlus, faTrashAlt, faExchange, faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import {
  faSync,
  faCaretDown,
  faCaretUp,
  faUsersClass,
  faDownload
} from '@fortawesome/pro-solid-svg-icons';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { useHistory, useParams } from 'react-router';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import InviteStudentForm from 'src/components/molecules/Classes/InviteStudentForm';
import {
  useInviteStudent,
  getStudentsQuery,
  useImportStudent,
  useSendInvite,
  useSendOnboardingReminder,
  useDeleteStudent,
  useGetInstituteStudents,
  useGenerateBonafideCertificate,
  requestLeavingCertificate,
  useGenerateLeavingCertificate,
  useGetBasicStudentData,
  useReconcileStudentPayments,
  lastStudentReconcilePaymentRequest,
  useGenerateAllIDCards,
  useGeneratePaymentReceipts,
  useGetDocumentsStatusGeneration,
  getDocumentsStatusGenerationQuery,
  getBatchDocumentsGenerationStatusQuery,
  useGeneratePrintableIDCards,
  useGeneratePrintableLibraryCards
} from 'src/graphql/classes/index';
import { useQuery } from '@apollo/client';
import { useHeaderTitle } from 'src/contexts/header-context';
import { colors, fonts } from 'src/styles/theme/styles';
import { createSearchFilter, createSortFilter, debounce } from 'src/components/services';
import {
  AUTOCOMPLETE_MODULE,
  ERROR,
  FILTERS,
  SEARCH_TIME,
  SUCCESS,
  USER_TYPE,
  USER_TYPE_ENUM,
  BROADCAST_TYPE,
  UserStatus,
  limit,
} from 'src/constant';
import { useFilter } from 'src/contexts/filter-context';
import { ADD_SUCCESS, DELETE_SUCCESS, VERIFICATION_SUCCESS } from 'src/constant/message';
import { attachmentType } from '../../Assignments/types';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import { useTheme } from 'styled-components';
import styled from 'styled-components/native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { downloadFileRestAPI } from 'src/utils/utility';
import { getBatchByIdQuery } from 'src/graphql/academics/batch';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import student from '../../../../../public/templates/student.xlsx';
import { Link } from 'src/routes/routing.web';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import MediumText from 'src/components/atoms/Text/MediumText';
import Check from 'src/components/atoms/CheckBox/index.web';
import Filter from 'src/components/molecules/Filter/index.web';
import { height, width } from 'src/constant/device';
import { userInformation } from 'src/utils/manageState';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import ERROR_MSG from 'src/constant/error';
import StudentDetailsQuickEditForm from 'src/components/molecules/Courses/StudentDetailsQuickEditForm'
import StudentGroups from '../StudentGroups.web';
import ImportStudentForm from 'src/components/molecules/Classes/ImportStudentForm';
import { headerCols } from './student-header-cols';
import { classesStyles } from './styles';
import { getAllowedModules } from 'src/graphql/institutes/module';
import { EditIcon, OverflowMenuIcon } from 'src/components/atoms/ActionIcons';
import AssignRollNoForm from 'src/components/molecules/Courses/AssignRollNoForm';
import { useAssignDivision } from 'src/graphql/admission/application';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import PaymentStatusContentAction from 'src/components/molecules/Payment/Institute/PaymentStatusContentAction';
import { formatDistanceToNowStrict } from 'date-fns';
import { getStudentFeeBook } from 'src/graphql/payment';
import { graphqlQuery } from 'src/graphql/util';
import ButtonGrp from 'src/components/atoms/ButtonGroup/index.web';
import { properCase } from 'src/utils/utility';
import GenerateLeavingCertificate from 'src/components/molecules/Classes/GenerateLeavingCertificateForm';
import LeavingCertificateConfirmationForm from 'src/components/molecules/Classes/LeavingCertificateConfirmationForm';
import { DocumentsData, LeavingCertificateData, ReconcilePaymentData, TBatchStudentsFormState as TForm } from './types';
import ChangeDivisionTable from 'src/components/molecules/Table/ChangeDivisionTable';
import { BasicStudentDataType, changeDivisionInputStateType, changeDivisionPayloadType, filterAndConvertMapToArray, getCellStyle, getDivisionDropdownOptions } from './BatchStudentsHelpers';
import ReconcilePaymentForm from 'src/components/molecules/Admission/ReconcilePaymentForm';
import MultiColorProgressBar from 'src/components/atoms/progressiveBar/MultiColorProgressBar';
import DownloadDocumentsModal from './DownloadDocumentsModal';
import SplitButton from 'src/components/atoms/SplitButton/index.web';
import PartialFeePayment, { TPartialFeePaymentData } from '../../Payment/PartialFeePayment/PartialFeePayment.web';
import AssignFeeCompositionForm from 'src/components/molecules/Students/AssignFeeCompositionForm.web';
import { getExportModalHeader } from './BatchStudentsHelpers';

export default function BatchStudents() {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const { setHeading } = useHeaderTitle();
  const currentUserObject = userInformation();
  const { userType, userDetail } = currentUserObject;
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const tableCell = { color: theme?.table?.color, width: 16 }

  const {
    handleSubmit: handleSubmitLeavingCertificate,
    control: controlLeavingCertificate,
    formState: { errors: errorsLeavingCertificate },
    reset: resetLeavingCertificate,
    setValue: setValueLeavingCertificate,
    getValues: getValuesLeavingCertificate,
  } = useForm();
  const { reconcileStudentPayment } = useReconcileStudentPayments();
  const [canClick, setCanClick] = useState(true);

  const { rem }: any = useTheme();
  const location = useLocation();
  const styles = classesStyles(rem);
  const isStudents = location && location.pathname.includes('students');
  const isTimetable = location && location.pathname.includes('timetable');

  let row;

  const { page = 1, dataLimit = limit }: any = useParams();
  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  const history = useHistory();
  let searchValue = '' as string;
  const { batchId, division }: any = useParams();
  const { filters, setFilter } = useFilter();
  const checkError = useRef(false);
  const [batch, setBatch] = useState<any>({});
  const [modalState, handleModal] = useState<boolean>(false);
  const [assignRollModalState, setAssignRollModalState] = useState<boolean>(false);
  const [assignRollNoTableData, setAssignRollNoTableData] = useState([]);
  const [changeDivisionSearchValue, setChangeDivisionSearchValue] = useState<string | undefined>();
  const [changeDivisionTableData, setChangeDivisionTableData] = useState<BasicStudentDataType[] | null>(null);
  const [changeDivisionModalState, setChangeDivisionModalState] = useState<boolean>(false);
  const [changeDivisionInputState, setChangeDivisionInputState] = useState<
    Map<string, changeDivisionInputStateType>
  >(new Map<string, changeDivisionInputStateType>());
  const [studentRoll, setStudentRoll] = useState([]);
  const [importModalState, handleImportModal] = useState<boolean>(false);
  const [importAttachment, handleImportAttachment] = useState<{
    documentName: String;
    document?: File | undefined;
  }>({ documentName: 'NA' });
  const [dropDown, setDropDown] = useState(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const { inviteIntituteStudent } = useInviteStudent();
  const { studentBulkInvite } = useImportStudent();
  const { sendInvite } = useSendInvite();
  const [batchClass, setBatchClass] = useState<any>(null);
  const [course, setCourse] = useState<any>(null);
  const { setAlertDetails } = useAlertSystem();

  // Use this as Primary Form state while refactoring
  const [form, setForm] = useState<TForm>({ state: "CLOSE" });

  const [trow, setT] = React.useState(false);
  const [current, setCurrent] = React.useState(null);
  const [headCells, setHeadCells] = useState(headerCols);
  const [array, setArray] = useState([]);
  const [filterHeight, setFilterHeight] = useState(0);
  const [showCheck, setShowCheck] = useState(false);
  const [notActiveCount, setNotActiveCount] = useState<any>();
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [studentData, setStudentData] = useState<any>({});
  const [activeFilters, setActiveFilters] = useState<any>({});
  const [documentName, setDocumentName] = useState('');
  const [id, setId] = useState(null);
  const [showPaymentsIcon, setShowPaymentsIcon] = useState<boolean>(false);
  const [searching, setSearchingStudent] = useState(searchValue);
  const [paymentDetailModal, setPaymentDetailModal] = useState<boolean>(false);
  const [studentId, setStudentId] = useState<string>('');
  const [academicId, setAcademicId] = useState<string>('');
  const [recipientType, setRecipientType] = useState<string>('Selected');
  const [options, setOptions] = useState<boolean>(false);
  const [leavingCertificateModal, setLeavingCertificateModal] = useState<boolean>(false);
  const [leavingCertificateData, setLeavingCertificateData] = useState<LeavingCertificateData>({});
  const [leavingCertificateConfirmationModal, setLeavingCertificateConfirmationModal] = useState<boolean>(false);
  const [confirmSelection, setConfirmSelection] = useState<boolean>(false);
  const [certificateIconsDropdown, setCertificateIconsDropdown] = useState<boolean>(false);
  const [paymentIconsDropdown, setPaymentIconsDropdown] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>('');
  const [reconcilePaymentModal, setReconcilePaymentModal] = useState<boolean>(false);
  const [reconcilePaymentData, setReconcilePaymentData] = useState<ReconcilePaymentData | null>(null);
  const [downloadDocumentsModal, setDownloadDocumentsModal] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string>('');
  const [documentsData, setDocumentsData] = useState<DocumentsData | null>(null);
  const [exportFailureModal, setExportFailureModal] = useState<boolean>(false);

  const { data: batchData } = useQuery(getBatchByIdQuery, {
    variables: { id: batchId },
  });
  const { getInstituteStudents, instituteStudentsData, instituteStudentsLoading, refetchInstituteStudents } = useGetInstituteStudents();
  const { getBasicStudentData, loadingBasicStudentData, dataBasicStudent } = useGetBasicStudentData();
  const { generateBonafideCertificate } = useGenerateBonafideCertificate();
  const { generateLeavingCertificate } = useGenerateLeavingCertificate();
  const { generateAllIdCards } = useGenerateAllIDCards();
  const { generatePaymentReceipts } = useGeneratePaymentReceipts();
  const { generatePrintableIdCards } = useGeneratePrintableIDCards();
  const { generatePrintableLibraryCards } = useGeneratePrintableLibraryCards();
  const {
    getDocumentsStatusGeneration,
    documentsStatusGenerationData,
    documentsStatusGenerationLoading,
    refetchDocumentsStatusGeneration,
  } = useGetDocumentsStatusGeneration();
  row = instituteStudentsData?.instituteStudents?.data;
  const paginationHeightOffset = array.length === notActiveCount ? 88 : 48;

  const [totalCount, setTotalCount] = useState<number | null>(instituteStudentsData?.instituteStudents?.totalCount ?? null);

  useEffect(() => {
    const count = instituteStudentsData?.instituteStudents?.totalCount;
    if (count) {
      setTotalCount(count);
    }
  }, [instituteStudentsData?.instituteStudents?.totalCount]);

  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);


  if (filters && filters?.classes_students?.on) {
    searchValue = filters?.classes_students?.search;
  }

  const filterOptions = [
    { id: 1, label: 'includes', type: 'string', filteringCriteria: 'in' },
    { id: 2, label: 'not includes', type: 'string', filteringCriteria: 'nin' },
  ]

  const filterItems = [
    {
      id: 1,
      label: 'First Name',
      type: 'string',
      localField: 'firstName',
      filterType: 'filter',
    },
    {
      id: 2,
      label: 'Last Name',
      type: 'string',
      localField: 'lastName',
      filterType: 'filter',
    },
    {
      id: 3,
      label: 'Student Id',
      type: 'string',
      localField: 'userName',
      filterType: 'filter'
    },
    {
      id: 4,
      label: 'Roll No',
      type: 'number',
      localField: 'rollNo',
      filterType: 'filter'
    },
    {
      id: 5,
      label: 'Profile Completion',
      type: 'number',
      localField: 'totalOnboardingCompletion',
      filterType: 'filter'
    },
    {
      id: 6,
      label: 'Status',
      type: 'string',
      localField: 'status',
      filterType: 'filter',
      dynamicCheckboxes: true,
      filterOptions: filterOptions,
      acceptableCheckboxValues: ["ACTIVE", "INACTIVE", "NOT_VERIFIED", "VERIFICATION_SENT"]
    }
  ];

  useEffect(() => {
    setSearchingStudent(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (batchData) {
      setBatch(batchData?.batch);
      setBatchClass(batchData?.batch?.class);
      setCourse(batchData?.batch?.class?.course);
    }
  }, [batchData]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    0,
    null,
    null,
    false,
    false,
    null,
    paginationHeightOffset,
  );

  const { assignDivision: assignDivisionAndRollNoMutation } = useAssignDivision();
  const { sendOnboardingReminder } = useSendOnboardingReminder();
  const { deleteStudent } = useDeleteStudent();
  const { fetchAllowedModules, allowedModules } = getAllowedModules();

  function changeTabs(tab: any) {
    if (tab === 1) {
      history.push(`/classroom/${batchId}/${division}/students/limit/${limit}/page/1`);
    } else {
      history.push(`/classroom/${batchId}/${division}/timetable`);
    }
  }

  useEffect(() => {
    setTitle();
  }, [isStudents, isTimetable, batch]);

  useEffect(() => {
    if (batchId && division) {
      handleFetch();
    }
  }, [batchId, division, searchValue, headCells, currentPage, rowLimit]);

  useEffect(() => {
    checkStatus();
  }, [row])

  useEffect(() => {
    fetchAllowedModules();
    return () => {
      if (filters?.students) {
        const { classes_students = {}, ...rest } = filters;
        setFilter(rest);
      } else {
        setFilter(null);
      }
    }
  }, [])

  useEffect(() => {
    setShowPaymentsIcon(allowedModules?.allowedModules?.modules.includes("Payments"))
  }, [allowedModules])

  useEffect(() => {
    if (typeof changeDivisionSearchValue === "string") changeDivisionFetchQuery();
  }, [changeDivisionSearchValue])

  useEffect(() => {
    setArray([]);
    if (recipientType === 'All') {
      setRecipientType('Selected');
    }
  }, [currentPage]);

  useEffect(() => {
    if (dataBasicStudent && dataBasicStudent?.instituteStudents) {
      setChangeDivisionTableData(dataBasicStudent.instituteStudents?.data);
    }
  }, [dataBasicStudent]);

  const handleFilters = (receivedFilter: any) => {
    const sortCriteria = createSortFilter(headCells);
    const studentsListingBatchClassDivFilter = createQueryParameter(sortCriteria);

    studentsListingBatchClassDivFilter.filters = {
      ...receivedFilter.filters,
      ...studentsListingBatchClassDivFilter.filters,
    }
    setActiveFilters(studentsListingBatchClassDivFilter)
    getInstituteStudents({ variables: studentsListingBatchClassDivFilter });
  };

  function handleFetch() {
    if (Object.keys(activeFilters).length) {
      getInstituteStudents({
        variables: activeFilters,
      });
    } else {
      const sortCriteria = createSortFilter(headCells);
      const studentFilter = createQueryParameter(sortCriteria);
      getInstituteStudents({
        variables: studentFilter,
      });
    }
  }

  function handleRefetch() {
    if (Object.keys(activeFilters).length) {
      getInstituteStudents({
        variables: activeFilters,
      });
    } else {
      if (refetchInstituteStudents) refetchInstituteStudents();
    }
  }

  function createQueryParameter(sort?: any) {
    if (batchId && division) {
      let filters = [] as any;
      if (searchValue.length) {
        filters = [
          {
            field: 'fullName',
            type: FILTERS.REGEX,
            value: searchValue.trim(),
          },
          {
            field: 'accountId',
            type: FILTERS.REGEX,
            value: searchValue.trim(),
          },
        ];
      }

      let studentFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);

      const batchDivisionFilter = {
        batch: { eq: batchId },
        division: { eq: division },
      }
      if (studentFilter.filters) {
        studentFilter.filters = {
          ...batchDivisionFilter,
          ...studentFilter.filters,
        }
      } else {
        studentFilter['filters'] = batchDivisionFilter;
      }

      if (sort) {
        studentFilter.sort = sort;
      }
      return studentFilter;
    }
  }

  async function setTitle() {
    const from = history?.location?.state?.from;
    setHeading([
      {
        text: from === 'Students' ? t('students.label') : t('classrooms.label'),
        url: from === 'Students' ? `/students/limit/${rowLimit}/page/1` : '/classrooms',
        from: 'Students',
      },
      {
        text: course?.alias,
      },
      {
        text: (batch?.alias || '-'),
      },
      {
        text: (division || '-'),
      }
    ]);
  }

  const inviteStudentModal = () => {
    setIsEditModal(false);
    reset({});
    handleModal(true);
  };

  const inviteStudent = async (studentData: any) => {
    setCanClick(false);
    try {
      if (!studentData?.grNo) delete studentData.grNo;
      const inviteStudentData = { ...studentData };
      inviteStudentData['class'] = batchClass?.id;
      inviteStudentData['batch'] = batchId;
      inviteStudentData['subjectGroup'] = studentData?.subjectGroup?.value;
      inviteStudentData['rollNo'] = parseInt(studentData?.rollNo);

      const studentFilter = createQueryParameter();
      const inviteResponse = await inviteIntituteStudent({
        variables: { payload: inviteStudentData },
        refetchQueries: [
          {
            query: getStudentsQuery,
            variables: studentFilter,
          },
        ],
      });

      if (inviteResponse?.data?.inviteStudent) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.INVITE_STUDENT, level: SUCCESS });
        handleRefetch()
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const broadcastMail = async (type: string) => {
    if (array.length > 0) {
      try {
        const payload = {
          userType: USER_TYPE_ENUM[2],
          type: BROADCAST_TYPE[type],
          recipient: recipientType,
          userIds: array,
        }
        if (recipientType === 'All') {
          payload['batch'] = batchId;
          payload['division'] = division;
        }
        const inviteResponse = await sendOnboardingReminder({ variables: payload });
        closeSelectAllSelection();
        if (inviteResponse?.data?.broadcastMail?.status === 'success') {
          setAlertDetails({ message: ADD_SUCCESS.SEND_REMINDER, level: SUCCESS })
          if (refetchInstituteStudents) refetchInstituteStudents();
        } else {
          setAlertDetails({
            message: type === 'reminder' ? ERROR_MSG.ACCOUNT_NOT_ACTIVE : ERROR_MSG.ACCOUNT_ALREADY_ACTIVE,
            level: ERROR
          })
        }
      } catch (e) {
        setAlertDetails({ message: e.message, level: ERROR })
      }
    }
  }

  const sendInviteStudent = async (userId) => {
    try {
      const studentFilter = createQueryParameter();
      const inviteResponse = await sendInvite({
        variables: { invite: { userId, userType: USER_TYPE_ENUM[2] } },
        refetchQueries: [
          {
            query: getStudentsQuery,
            variables: studentFilter,
          },
        ],
      });
      if (inviteResponse?.data) {
        handleRefetch();
        setAlertDetails({ message: VERIFICATION_SUCCESS.VERIFICATION_SENT_SUCCESS, level: SUCCESS });
      }
    } catch (e) {
      setAlertDetails({ message: e?.message || ERROR_MSG.GENERIC_ERROR, level: ERROR });
    }
  };

  const importStudent = async (studentData: any) => {
    setCanClick(false);
    try {
      const studentFilter = createQueryParameter();
      if (!importAttachment?.document) {
        throw new Error(ERROR_MSG.FILE_REQUIRED);
      }
      let inviteResponse = await studentBulkInvite({
        variables: { file: importAttachment.document, batchId, division, subGrpId: studentData?.subjectGroup?.value },
        refetchQueries: [
          {
            query: getStudentsQuery,
            variables: studentFilter,
          },
        ],
      });

      if (inviteResponse?.data?.studentBulkInvite) {
        const { sent, total, notSent, errors } = inviteResponse?.data?.studentBulkInvite;
        let level = SUCCESS;
        let message = `Imported, email sent: ${sent.length}, email not sent: ${notSent.length}`;
        let expireInMs = 6000;

        if (errors && errors.length > 0) {
          message += ', ';
          message += errors.join(', ');
          level = ERROR;
          expireInMs = 15000;
        }
        setAlertDetails({ message, level, expireInMs });
        handleRefetch();
        setCanClick(true);
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR, expireInMs: 15000 });
      setCanClick(true);
    }
    closeImportModal();
  };

  function editStudent(studentId: string) {
    history.push(`/student/${studentId}/0/edit`);
  }

  function viewStudent(studentId: string) {
    history.push(`/student/${studentId}/0/view`);
  }

  function viewStudentPaymentStatus(studentId: string, academicId: string) {
    setStudentId(studentId);
    setAcademicId(academicId.id);
    setPaymentDetailModal(true);
  }

  const closeModal = () => {
    handleModal(false);
    if (!isEditModal) reset({});
    setStudentData({});
  };

  const closeImportModal = () => {
    reset({});
    handleImportModal(false);
    handleImportAttachment({
      document: undefined,
      documentName: 'NA',
    });
    setDocumentName('');
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.CLASSES_STUDENTS]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchUsers(student: string) {
    let isClear = student?.length === 0;
    persistSearch(!isClear, student);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchUsers(q), SEARCH_TIME),
    [],
  );

  const handleSearch = (student: string) => {
    setSearchingStudent(student);
    delayedQuery(student);
  };

  const handleFileUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      } as attachmentType;
      handleImportAttachment(fileObject);
      setDocumentName(e.target.files[0]?.name);
      e.target.value = null;
    }
  };

  function handleImportStudents() {
    setDropDown(false);
    handleImportModal(true);
  }

  const checkCompletion = (percent: number) => {
    if (percent >= 0 && percent <= 20) {
      return colors.red;
    } else if (percent >= 21 && percent <= 80) {
      return colors.yellow;
    } else return colors.green;
  };

  const checkStatus = () => {
    if (row && row?.length > 0) {
      let count = 0;
      let status = [] as any;
      row.forEach((item: any) => {
        if (item?.totalOnboardingCompletion !== 100 || item?.status !== 'ACTIVE') {
          status.push(item?.id);
          count = count + 1;
        }
      });

      if (status.length > 0 && count !== 0) {
        setShowCheck(true);
        setNotActiveCount(count);
      } else {
        setShowCheck(false);
      }
    }
  };

  const handleCheck = (item: any) => {
    const index = array.findIndex(x => x === item?.id);
    const updated = [...array];
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push(item?.id);
    }
    setArray(updated);
    setRecipientType('Selected');
  }

  const handleCheckAll = () => {
    if (array.length === 0) {
      let allUsers = [] as any;
      row?.forEach((item: any) => {
        if (item?.totalOnboardingCompletion !== 100 || item?.status !== 'ACTIVE') {
          allUsers.push(item?.id)
        }
      });
      setArray(allUsers);
    } else if (array.length > 0 && array.length !== notActiveCount) {
      let update = [...array] as any;
      const res = row?.filter(x => !array.includes(x.id));
      res.forEach((e: any) => {
        if (e.totalOnboardingCompletion !== 100 || e.status !== 'ACTIVE') {
          update.push(e?.id)
        }
      })
      setArray(update);
    } else {
      setArray([]);
    }
    setRecipientType('Selected');
  }

  const deleteStudentModal = (selectedStudent: any) => {
    setStudentData(selectedStudent);
    setDeleteOverlay(true);
  }

  const handleDeleteStudent = async () => {
    const sortCriteria = createSortFilter(headCells);
    const studentFilter = createQueryParameter(sortCriteria);
    setCanClick(false);
    try {
      let deleteStudentResponse = await deleteStudent({
        variables: { academicId: studentData?.academic?.id },
        refetchQueries: [
          {
            query: getStudentsQuery,
            variables: studentFilter,
          }
        ]
      });
      if (deleteStudentResponse?.data?.removeAcademicDetail) {
        setDeleteOverlay(false);
        setAlertDetails({ message: DELETE_SUCCESS.STUDENT, level: SUCCESS });
        handleRefetch()
      }
    } catch (error) {
      setAlertDetails({ message: error.message, level: ERROR });
    }
    setCanClick(true);
  }

  const handleAssignRollNo = async () => {
    try {
      if (studentRoll) {
        const payloadData = studentRoll.filter((item: any) => item?.rollNo);
        if (payloadData && payloadData.length > 0) {
          const response = await assignDivisionAndRollNoMutation({
            variables: { payload: payloadData },
          });
          if (response.data) {
            setAlertDetails({
              message: ADD_SUCCESS.ASSIGN_ROLL_NO,
              level: SUCCESS,
            });
            closeAssignRollNo();
            if (refetchInstituteStudents) refetchInstituteStudents();
          }
        } else {
          throw new Error(t('error.assignRollNo.text'));
        }
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
  };

  const handlePartialPaymentModal = async (item: { id: string }) => {
    const feeBookData = await graphqlQuery(
      getStudentFeeBook,
      { batch: batchId, user: item.id },
      false
    );
    setForm({
      state: "OPEN",
      step: "PartialPaymentForm",
      data: {
        currentItem: item,
        currentFeeDetails: feeBookData?.data?.getStudentFeeBook,
      },
    });
  };

  function exportReport(url: string, fileName: string, item: any) {
    if (options) setOptions(false);
    downloadFileRestAPI(url, fileName, item, (err) => {
      setAlertDetails({ message: err.message || 'Failed to download', level: ERROR });
    });
    setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
  }

  async function handleAssignRollNoModal() {
    const studentFilter = {
      limit: 0,
      filters: {
        batch: { eq: batchId },
        division: { eq: division },
        or: [
          { rollNo: { exists: false } },
          { rollNo: { eq: null } },
        ]
      },
      sort: {
        gender: 'ASCENDING',
        fullName: 'ASCENDING',
      }
    }
    const students = await graphqlQuery(getStudentsQuery, studentFilter, false);
    if (students?.data?.instituteStudents?.data) {
      setAssignRollNoTableData(students.data.instituteStudents.data || []);
    }
    setOptions(false);
    setAssignRollModalState(true);
  }

  function closeSelectAllSelection() {
    setArray([]);
    setRecipientType('Selected');
  }

  function changeDivisionFetchQuery() {
    let filter = {
      batch: {
        eq: batchId,
      },
      division: {
        eq: division,
      },
    };
    if (changeDivisionSearchValue) {
      filter["fullName"] = {
        regex: changeDivisionSearchValue,
      };
    }
    getBasicStudentData({
      variables: {
        filters: filter,
      },
    });
  }

  function handleChangeDivision() {
    setChangeDivisionModalState(true);
    changeDivisionFetchQuery();
  }

  function closeChangeDivisionModal() {
    setChangeDivisionModalState(false);
    setChangeDivisionInputState(new Map());
    setChangeDivisionTableData(null);
    setChangeDivisionSearchValue(undefined);
  }

  async function changeDivisionFn() {
    setCanClick(false);
    try {
      const filteredChanges: changeDivisionPayloadType[] = filterAndConvertMapToArray(
        changeDivisionInputState,
        division
      );
      if (filteredChanges && filteredChanges?.length > 0) {
        const response = await assignDivisionAndRollNoMutation({
          variables: { payload: filteredChanges },
        });
        if (response.data) {
          setAlertDetails({
            message: ADD_SUCCESS.CHANGE_DIVISION,
            level: SUCCESS,
          });
          setCanClick(true);
          closeChangeDivisionModal();
          if (refetchInstituteStudents) refetchInstituteStudents();
        }
      }
    } catch (e) {
      setAlertDetails({ message: e?.message, level: ERROR });
      setCanClick(true);
    }
  }

  function handleSelectAllSelection() {
    if (recipientType === 'Selected') {
      setRecipientType('All');
    } else {
      setRecipientType('Selected');
      setArray([]);
    }
  }
  //split button menu actions
  const handleOnClick = () => {
    setDropDown(false);
    handleImportModal(true);
  };

  const handleOnArrowClick = () => {
    setDropDown(!dropDown);
  };
  //split button menu actions ends

  const handleAssignFeeForm = (item: {
    id: string;
    subjectGroup: { id: string };
    fees?: { feeComposition: string };
  }) => {
    setForm({
      state: "OPEN",
      step: "AssignFeeCompositionForm",
      data: {
        studentId: item.id,
        subjectGroupID: item.subjectGroup.id,
        initialValue: item?.fees?.feeComposition ?? ""
      },
    });
  };

  const handleReconcilePaymentModal = async (studentId: string) => {
    setCurrentId(studentId);
    const { loading, data } = await graphqlQuery(lastStudentReconcilePaymentRequest, { studentId: studentId });
    if (!loading && data?.getStudentLastPaymentReconcileRequest) {
      setReconcilePaymentData(data.getStudentLastPaymentReconcileRequest);
    }
    setReconcilePaymentModal(true);
  }

  const StudentsLists = (
    <>
      <FlexRowView isEdbaAdmin={userType === USER_TYPE.EDBA_ADMIN}>
        <SearchWrapper>
          <Search
            id="inviteStudentSearch"
            label={t('searchStudents.label')}
            value={searching}
            handleChange={handleSearch}
            width={224}
            height={32}
          />
        </SearchWrapper>
        <FilterWrapper
          filterHeight={filterHeight}
          isEdbaAdmin={userType === USER_TYPE.EDBA_ADMIN}>
          <Filter
            filterItems={filterItems}
            handleFilters={handleFilters}
            setFilterHeight={setFilterHeight}
            searchValue={searchValue}
          />
        </FilterWrapper>
        <AddClassWrapper>
          {array.length > 0 && (
            <View style={styles.rightpart}>
              <View style={styles.rightPadding16}>
                <Tooltip title='Send reminder for completing profile details' >
                  <OutlineButton
                    label={t('sendReminder.text')}
                    onPress={() => broadcastMail('reminder')}
                    style={{ height: 32 }}
                  />
                </Tooltip>
              </View>
              <View style={styles.rightPadding16}>
                <Tooltip title='Send verification invite to inactive users' >
                  <SecondaryBtn
                    label={t('verifyInvite.text')}
                    onPress={() => broadcastMail('verification')}
                    style={{ height: 32 }}
                  />
                </Tooltip>
              </View>
            </View>
          )}

          {array.length === 0 && (
            <>
              <OutlineButton
                onPress={() =>
                  exportReport(
                    `students/export/batch/${batchId}/${division}`,
                    'Students.xlsx',
		    ""
                  )
                }
                style={styles.marginR16}
                label={t('downloadStudents.label')}
                height={32}
              />

              <SplitButton
                buttonData={{
                  value: t('uploadStudents.label'),
                  onClick: handleOnClick,
                  onArrowClick: handleOnArrowClick,
                  onChange: handleFileUpload,
                }}
              />
              {
                dropDown && (
                  <InviteButton onPress={() => {
                    setDropDown(false);
                    inviteStudentModal();
                  }}>
                    <MediumText
                      value={t('inviteStudent.text')}
                      color={colors.primary}
                    />
                  </InviteButton>
                )
              }

              {
                Array.isArray(row) && row.length > 0 && (
                  <OverflowMenuIcon
                    onPress={() => {
                      setDropDown(false);
                      setOptions(!options);
                    }}
                  />
                )
              }

              {options && (
                <ClickAwayListener onClickAway={() => setOptions(false)}>
                  <ButtonGroupWrapper>
                    <ButtonGrp
                      width={250}
                      top={20}
                      buttonData={[
                        ...(recordsAfterPagingAndSorting() &&
                          recordsAfterPagingAndSorting()?.length > 0
                          ? [
                            {
                              key: 'assign-roll-no',
                              value: t('assign-roll-no.text'),
                              onPress: handleAssignRollNoModal,
                              prefixIcon: faUsersClass,
                              customStyle: true,
                            },
                            {
                              key: 'change-division',
                              value: t('change-division.text'),
                              onPress: handleChangeDivision,
                              prefixIcon: faExchange,
                              customStyle: true,
                            },
                            {
                              key: 'download-id-cards-report',
                              value: t('downloadIDCardsReport.text'),
                              onPress: () =>
                                exportReport(
                                  `students/batch/${batchId}/idCards/report?division=${division}`,
                                  'IDCardsReport.zip',
				  ""
                                ),
                              prefixIcon: 'download',
                              customIcon: true,
                            },
                            {
                              key: 'export-id-cards',
                              value: t('exportIDCards.label'),
                              onPress: () => handleDownloadDocumentsModal('ID_CARDS_GENERATION'),
                              prefixIcon: 'export',
                              customIcon: true,
                            },
                            {
                              key: 'export-printable-id-cards',
                              value: t('exportPrintableIDCards.label'),
                              onPress: () => handleDownloadDocumentsModal('PRINTABLE_ID_CARDS_GENERATION', false),
                              prefixIcon: 'export',
                              customIcon: true,
                            },
                            {
                              key: 'export-printable-library-cards',
                              value: t('exportPrintableLibraryCards.label'),
                              onPress: () => handleDownloadDocumentsModal('PRINTABLE_LIBRARY_CARDS_GENERATION', false),
                              prefixIcon: 'export',
                              customIcon: true,
                            },
                            {
                              key: 'export-fee-receipts',
                              value: t('exportReceipts.label'),
                              onPress: () => handleDownloadDocumentsModal('FEE_RECEIPT'),
                              prefixIcon: 'export',
                              customIcon: true,
                            },
                            {
                              key: 'export-report',
                              value: t('exportReport.label'),
                              onPress: () =>
                                exportReport(
                                  `students/reports/batch/${batchId}/${division}`,
                                  'StudentReport.xlsx',
                                  ""
                                ),
                              prefixIcon: 'export',
                              customIcon: true,
                            },
                          ]
                          : []),
                      ]}
                    />
                  </ButtonGroupWrapper>
                </ClickAwayListener>
              )}
            </>
          )}
        </AddClassWrapper>
      </FlexRowView>
      {array.length === notActiveCount && (
        <SelectAllWrapper>
          <NormaltextAtom
            value={
              recipientType == 'Selected'
                ? t('studentsSelected.text', { values: { studentsCount: notActiveCount } })
                : t('allStudentsSelected.text')
            }
            color={colors.noteBlue}
          />
          <SelectAllText>
            <OutlineButton
              onPress={handleSelectAllSelection}
              label={recipientType == 'Selected' ? t('selectAll.text') : t('clearSelection.text')}
              height={24}
            />
          </SelectAllText>
        </SelectAllWrapper>
      )}
      <View style={{ zIndex: -1 }}>
        <TableDiv>
          <TblContainer overflowX="scroll" height={height - 314 - paginationHeightOffset}>
            <TblHead
              setHeadCells={setHeadCells}
              checkBox={showCheck}
              checked={array.length === notActiveCount}
              handleCheck={handleCheckAll}
              style={customStyles.tableHeadCells}
            />
            {!instituteStudentsLoading ? (
              <>
                {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 && (
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((item: any, i: number) => (
                      <>
                        <TableRows
                          key={i}
                          style={{
                            backgroundColor: trow && current === i ? '#EAF2FA' : 'transparent',
                            width: '5%',
                            height: 56,
                          }}>
                          {showCheck && (
                            <TableCells
                              style={{ ...tableCell, ...getCellStyle({ trow, current, id: i, isFirstCell: true, isLastCell: false, itemId: item?.id }) }}
                            >
                              {(item?.totalOnboardingCompletion !== 100 || item?.status !== 'ACTIVE') && (
                                <Check
                                  same={false}
                                  onChange={() => handleCheck(item)}
                                  checked={array.some((e) => e === item?.id)}
                                />
                              )}
                            </TableCells>
                          )}

                          {(trow && id === item.id) || (trow && current === i) ? (
                            <TableCells
                              style={{ ...tableCell, ...getCellStyle({ trow, current, id: i, isFirstCell: false, isLastCell: false, itemId: item?.id }) }}>
                              <FontAwesomeIcon
                                icon={faCaretUp}
                                onClick={() => {
                                  setT(false);
                                  setCurrent(null);
                                  setId(null);
                                }}
                                color={colors.secondaryText}
                                style={{ cursor: 'pointer' }}
                              />
                            </TableCells>
                          ) : (
                            <TableCells style={{ width: 16 }}>
                              <FontAwesomeIcon
                                icon={faCaretDown}
                                onClick={() => {
                                  setT(true);
                                  setCurrent(i);
                                  setId(null);
                                }}
                                color={colors.secondaryText}
                                style={{ cursor: 'pointer' }}
                              />
                            </TableCells>
                          )}

                          <TableCells
                            style={{ ...tableCell, ...getCellStyle({ trow, current, id: i, isFirstCell: false, isLastCell: false, itemId: item?.id }) }}
                            value={properCase(item?.fullName || '-')}
                            color={colors?.primaryColor}
                            clickable={true}
                            onPress={() => viewStudent(item.id)}
                          />
                          <TableCells
                            style={{ ...tableCell, ...getCellStyle({ trow, current, id: i, isFirstCell: false, isLastCell: false, itemId: item?.id }) }}
                            value={item?.accountId ? item?.accountId : '-'}
                          />
                          <TableCells
                            style={{ ...tableCell, ...getCellStyle({ trow, current, id: i, isFirstCell: false, isLastCell: false, itemId: item?.id }) }}
                            value={item?.rollNo}
                          />
                          <TableCells
                            style={{ ...tableCell, ...getCellStyle({ trow, current, id: i, isFirstCell: false, isLastCell: false, itemId: item?.id }) }}
                            color={checkCompletion(item?.totalOnboardingCompletion)}
                          >
                            <MultiColorProgressBar
                              data={[
                                {
                                  label: 'verifiedDetails.label',
                                  value: item?.totalOnboardingCompletion || 0,
                                  color: colors.primaryColor,
                                },
                                {
                                  label: 'awaitingVerification.label',
                                  value: item?.draftTotalOnboardingCompletion || 0,
                                  color: colors.yellow,
                                },
                              ]}
                              height={13}
                              width={150}
                              compact={true}
                            />
                          </TableCells>
                          <TableCells
                            style={{ ...tableCell, ...getCellStyle({ trow, current, id: i, isFirstCell: false, isLastCell: false, itemId: item?.id }) }}>
                            {item?.status !== 'VERIFICATION_SENT' ? (
                              <NormaltextAtom
                                value={item?.status ? properCase(item.status) : '-'}
                                color={item?.status === 'ACTIVE' ? colors.green : colors.blue}
                              />
                            ) : (
                              <View>
                                <NormaltextAtom
                                  value={
                                    item?.verificationStatus?.expiresAt
                                      ? `Verification expires in ${formatDistanceToNowStrict(
                                        item?.verificationStatus?.expiresAt,
                                      )}`
                                      : 'Verification Expired'
                                  }
                                  color={
                                    item?.verificationStatus?.expiresAt ? colors.yellow : colors.red
                                  }
                                />
                              </View>
                            )}
                          </TableCells>
                          <TableCells
                            align="right"
                            style={{ position: 'relative', ...tableCell, ...getCellStyle({ trow, current, id: i, isFirstCell: false, isLastCell: true, itemId: item?.id }) }}>
                            <View style={styles.tableAction}>
                              {/*<View>
                                <Tooltip title="Depromote Student">
                                  <TouchableOpacity onPress={() => console.log("sahjsa")} style={{marginRight: 10}}>
                                    <FontAwesomeIcon
                                      icon={faArrowDown}
                                      color={theme?.table?.deleteIconColor}
                                    />
                                  </TouchableOpacity>
                                </Tooltip>
                              </View>*/}
                              <ClickAwayListener onClickAway={() => setCertificateIconsDropdown(false)}>
                                <Tooltip title="Certificates">
                                  <DropdownIconWrapper
                                    onPress={() => {
                                      setPaymentIconsDropdown(false);
                                      setCertificateIconsDropdown(!certificateIconsDropdown);
                                      setCurrentId(item?.id);
                                    }}>
                                    <FontAwesomeIcon icon={faFileCertificate} color={colors.secondaryText} />
                                    <FontAwesomeIcon icon={faCaretDown} color={colors.secondaryText} />
                                  </DropdownIconWrapper>
                                </Tooltip>
                              </ClickAwayListener>
                              {showPaymentsIcon && !userDetail.type.includes("HOD") && (
                                <ClickAwayListener onClickAway={() => setPaymentIconsDropdown(false)}>
                                  <Tooltip title="Payment">
                                    <DropdownIconWrapper
                                      onPress={() => {
                                        setCertificateIconsDropdown(false);
                                        setPaymentIconsDropdown(!paymentIconsDropdown);
                                        setCurrentId(item?.id);
                                      }}>
                                      <div style={{ alignSelf: 'flex-start' }}>
                                        <Icon name='payment-details' />
                                      </div>
                                      <FontAwesomeIcon icon={faCaretDown} color={colors.secondaryText} />
                                    </DropdownIconWrapper>
                                  </Tooltip>
                                </ClickAwayListener>
                              )}

                              {item?.status !== 'ACTIVE' && (
                                <View style={styles.iconView}>
                                  <Tooltip title="Resend Verification">
                                    <TouchableOpacity
                                      onPress={() => {
                                        sendInviteStudent(item.id);
                                      }}>
                                      <FontAwesomeIcon
                                        icon={faSync}
                                        color={theme?.table?.editIconColor}
                                      />
                                    </TouchableOpacity>
                                  </Tooltip>
                                </View>
                              )}
                              <IconWrapper>
                                        <EditIcon
                                          tooltipTitle={t('editProfile.text')}
                                          onPress={() => editStudent(item.id)}
                                        />
                              </IconWrapper>
                              {(() => {
                                if(!userDetail.type.includes("HOD")){
                                  return(
                                      
                                      <View>
                                        <Tooltip title="Delete Student">
                                          <TouchableOpacity onPress={() => deleteStudentModal(item)}>
                                            <FontAwesomeIcon
                                              icon={faTrashAlt}
                                              color={theme?.table?.deleteIconColor}
                                            />
                                          </TouchableOpacity>
                                        </Tooltip>
                                      </View>
                                  )
                                }
                              })()}
                            </View>
                            {certificateIconsDropdown && currentId === item?.id && (
                              <ButtonGrp
                                top={48}
                                right={item?.status != UserStatus.ACTIVE ? 136 : 112}
                                width={275}
                                buttonData={[
                                  {
                                    key: 'generate-bonafide-certificate',
                                    value: t('generateBonafideCertificate.text'),
                                    onPress: () => handleGenerateBonafideCertificate(item?.id),
                                    prefixIcon: faFilePlus,
                                  },
                                  {
                                    key: 'download-bonafide-certificate',
                                    value: t('downloadBonafideCertificate.text'),
                                    onPress: () => exportReport(`students/bonafied/${item.id}`, 'BonafideCertificate.pdf', ""),
                                    prefixIcon: faDownload,
                                  },
                                  {
                                    key: 'generate-leaving-certificate',
                                    value: t('generateLeavingCertificate.text'),
                                    onPress: () => handleLeavingCertificateModal(item?.id),
                                    prefixIcon: faFilePlus,
                                  },
                                  {
                                    key: 'download-leaving-certificate',
                                    value: t('downloadLeavingCertificate.text'),
                                    onPress: () => exportReport(`students/leavingCertificate/${item.id}`, 'LeavingCertificate.pdf'),
                                    prefixIcon: faDownload,
                                  },
                                  {
                                    key: 'download-id-card',
                                    value: t('downloadIDCard.text'),
                                    onPress: () => exportReport(`students/idCard/${item.id}`, 'ID Card.pdf', ""),
                                    prefixIcon: faDownload,
                                  },
                                ]}
                              />
                            )}

                            {paymentIconsDropdown && currentId === item?.id && !userDetail.type.includes("HOD") && (
                              <ButtonGrp
                                top={48}
                                right={item?.status != UserStatus.ACTIVE ? 88 : 64}
                                width={250}
                                buttonData={[
                                  {
                                    key: 'assign-fee',
                                    value: t('assignFee.text'),
                                    onPress: () => handleAssignFeeForm(item),
                                    prefixIcon: faFileInvoiceDollar,
                                    customStyle: true,
                                  },
                                  {
                                    key: 'add-partial-payment',
                                    value: t('addPartialPayment.text'),
                                    onPress: () => handlePartialPaymentModal(item),
                                    prefixIcon: 'partial-payment',
                                    customIcon: true,
                                  },
                                  {
                                    key: 'payment-details',
                                    value: t('paymentDetails.text'),
                                    onPress: () => viewStudentPaymentStatus(item.id, item.academic),
                                    prefixIcon: 'payment-details',
                                    customIcon: true,
                                  },
                                  ...(item?.academic?.payments && item.academic.payments?.length > 0 ? [
                                    {
                                      key: 'download-fee-receipt',
                                      value: t('downloadFeeReceipt.text'),
                                      onPress: () => exportReport(`invoice/${item.academic.payments[0]}/${item.academic.id}`, 'FeeReceipt.pdf', item),
                                      prefixIcon: faDownload,
                                      customStyle: true,
                                    },
                                    {
                                      key: 'reconcile-payment',
                                      value: t('paymentReconcile.label'),
                                      onPress: () => handleReconcilePaymentModal(item?.id),
                                      prefixIcon: 'reconcile-payment',
                                      customIcon: true,
                                    }] : []),
                                ]}
                              />
                            )}
                          </TableCells>
                        </TableRows>
                        {((trow && id === item.id) || (trow && current === i)) && (
                          <TableRows
                            style={{
                              color: theme?.table?.color,
                            }}>
                            <TableCells colspan={8} style={{ padding: 0 }}>
                              {/* Pass data for nested child table */}
                              <StudentDetailsQuickEditForm
                                studentData={item}
                                batchId={batchId}
                                handleFetch={handleFetch}
                                setId={setId}
        	                        setCurrent={setCurrent}
                              />
                            </TableCells>
                          </TableRows>
                        )}
                      </>
                    ))}
                  </TableBody>
                )}
                {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length === 0 && (
                  <NoRecordsFound colspan={8} maxHeight={height - 435} />
                )}
              </>
            ) : (
              <SpinnerView>
                <LoaderSpinner />
              </SpinnerView>
            )}
          </TblContainer>
          <Pagination
            pathName={`classroom/${batchId}/${division}/students`}
            total={totalCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            rowLimit={rowLimit}
            setRowLimit={setRowLimit}
            hidePagination={!!(totalCount && totalCount <= rowLimit)}
          />
        </TableDiv>
      </View>
    </>
  );

  const DetailData = (
    <>
      <View>
        <MediumTextSelect value={t('deleteStudent.warning1')} />
        <MediumTextSelect value={t('deleteStudent.warning2')} />
        <View style={styles.detailContainer}>
          <View style={styles.detailsView}>
            <ContentSpan>
              <MediumTextSelect value={'Name: '} color={colors.primaryText} fontFamily={fonts.semibold} />
              <MediumTextSelect
                value={studentData?.fullName || '-'}
              />
            </ContentSpan>
            <ContentSpan>
              <MediumTextSelect value={'Email: '} color={colors.primaryText} fontFamily={fonts.semibold} />
              <MediumTextSelect value={studentData?.email || '--'} />
            </ContentSpan>
          </View>

          <View style={styles.detailsView}>
            <ContentSpan>
              <MediumTextSelect value={'Class: '} color={colors.primaryText} fontFamily={fonts.semibold} />
              <MediumTextSelect value={studentData?.academic?.class?.name || '--'} />
            </ContentSpan>
            <ContentSpan>
              <MediumTextSelect value={'RollNo: '} color={colors.primaryText} fontFamily={fonts.semibold} />
              <MediumTextSelect value={studentData?.rollNo || '--'} />
            </ContentSpan>
          </View>

          <View style={styles.detailsView}>
            <ContentSpan>
              <MediumTextSelect value={'GRNo: '} color={colors.primaryText} fontFamily={fonts.semibold} />
              <MediumTextSelect value={studentData?.grNo || '--'} />
            </ContentSpan>
            <ContentSpan>
              <MediumTextSelect value={'SRNo: '} color={colors.primaryText} fontFamily={fonts.semibold} />
              <MediumTextSelect value={studentData?.serialNo || '--'} />
            </ContentSpan>
          </View>
        </View>
      </View>
    </>
  )

  const ImportStudents = (
    <>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View>
          <input
            style={{ display: 'none' }}
            id="importStudents"
            multiple
            type="file"
            accept=".csv,.xls,.xlsx"
            onChange={handleFileUpload}
          />

          <label htmlFor="importStudents">
            <SecondaryBtn
              label={t('selectFile.text')}
              onPress={() => null}
              width={150}
              style={{ height: 32 }}
            />
          </label>
        </View>

        <View style={{ margin: 5 }}>
          <MediumTextSelect value={documentName} />
        </View>
      </View>
    </>
  )

  function closeAssignRollNo(): void {
    setAssignRollModalState(false);
    setStudentRoll([])
  }

  function closePaymentDetailModal() {
    setPaymentDetailModal(false);
    setStudentId('');
  }

  function closeLeavingCertificateModal() {
    setLeavingCertificateModal(false);
    resetLeavingCertificate({});
    setLeavingCertificateData({});
  }

  async function handleGenerateCertificateModal(formValues: any) {
    setLeavingCertificateData({ ...leavingCertificateData, ...formValues });
    setLeavingCertificateConfirmationModal(true);
  }

  function closeLeavingCertificateConfirmationModal() {
    setLeavingCertificateConfirmationModal(false);
    setConfirmSelection(false);
  }

  const handleLeavingCertificateModal = async (id: string) => {
    const response = await graphqlQuery(requestLeavingCertificate, { studentId: id });
    if (response?.data?.requestLeavingCertificate) {
      setLeavingCertificateData(response.data.requestLeavingCertificate);
      setLeavingCertificateModal(true);
    } else {
      setAlertDetails({ message: response, level: ERROR });
    }
  }

  const handleGenerateBonafideCertificate = async (id: string) => {
    try {
      const response = await generateBonafideCertificate({ variables: { studentId: id } });
      if (response?.data?.generateStudentBonafied?.status === SUCCESS) {
        setAlertDetails({ message: ADD_SUCCESS.CERTIFICATE_GENERATION, level: SUCCESS });
        if (refetchInstituteStudents) refetchInstituteStudents();
      } else {
        throw new Error(response?.data?.generateStudentBonafied?.message);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  async function handleGenerateLeavingCertificate() {
    setCanClick(false);
    try {
      const payload = {
        studentId: currentId,
        input: {
          dateOfLeaving: leavingCertificateData?.dateOfLeaving,
          generalConduct: leavingCertificateData?.generalConduct,
          reasonForLeaving: leavingCertificateData?.reasonForLeaving,
          ...(leavingCertificateData?.caste ? { caste: leavingCertificateData.caste } : {}),
          ...(leavingCertificateData?.nationality ? { nationality: leavingCertificateData.nationality } : {}),
          ...(leavingCertificateData?.dateOfAdmission ? { dateOfAdmission: leavingCertificateData.dateOfAdmission } : {}),
          ...(leavingCertificateData?.progress ? { progress: leavingCertificateData.progress } : {}),
          ...(leavingCertificateData?.anyOtherRemark ? { remark: leavingCertificateData.anyOtherRemark } : {}),
          ...(leavingCertificateData?.lastExamStatus ? { lastExamStatus: leavingCertificateData.lastExamStatus } : {}),
          ...(leavingCertificateData?.lastExamYear ? { lastExamYear: leavingCertificateData.lastExamYear } : {}),
          ...(leavingCertificateData?.registerNo ? { registerNo: leavingCertificateData.registerNo } : {}),
        }
      }

      const response = await generateLeavingCertificate({ variables: payload });
      if (response?.data?.generateLeavingCertificate) {
        setCanClick(true);
        closeLeavingCertificateConfirmationModal();
        closeLeavingCertificateModal();
        setAlertDetails({ message: ADD_SUCCESS.CERTIFICATE_GENERATION, level: SUCCESS });
        if (refetchInstituteStudents) refetchInstituteStudents();
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  function closeReconcilePaymentModal() {
    setReconcilePaymentModal(false);
    setReconcilePaymentData(null);
    setCurrentId('');
    setConfirmSelection(false);
  }

  async function handleReconcileStudentPayment() {
    setCanClick(false);
    try {
      const response = await reconcileStudentPayment({ variables: { studentId: currentId } });
      if (response?.data?.reconcileStudentsPayment?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.RECONCILE_PAYMENT, level: SUCCESS });
        if (refetchInstituteStudents) refetchInstituteStudents();
      } else {
        throw new Error(response?.data?.reconcileStudentsPayment?.message);
      }
    } catch (error: any) {
      setAlertDetails({ message: error.message, level: ERROR });
    }
    closeReconcilePaymentModal();
    setCanClick(true);
  }

  function closeDownloadDocumentsModal() {
    setDownloadDocumentsModal(false);
    setDocumentsData(null);
  }

  async function handleDownloadDocumentsModal(type: string, isBulk: boolean = true) {
    setOptions(false);
    setDocumentType(type);
    if (batchId && division && type) {
      const statusQuery = isBulk ? getDocumentsStatusGenerationQuery : getBatchDocumentsGenerationStatusQuery;
      const { data, loading } = await graphqlQuery(
        statusQuery,
        {
          batchId,
          division,
          type,
        },
        false,
      );

      if (!loading) {
        setDownloadDocumentsModal(true);
        const documentsData = isBulk ? data.getBatchDocumentsBulkGenerationStatus : data.getBatchDocumentsGenerationStatus;
        if (documentsData) {
          if (isBulk) {
            if (documentsData?.total) setDocumentsData(documentsData)
          } else {
            setDocumentsData(documentsData);
          }
        }
      }
    }
  }

  async function handleGenerateAllIdCards(type: string) {
    if (!documentsData) setCanClick(false);
    try {
      const response = await generateAllIdCards({ variables: { batchId, division } });
      if (response?.data?.generateIDCards?.status === SUCCESS) {
        setCanClick(true);
        closeDownloadDocumentsModal();
        setAlertDetails({
          message:
            type === 'genenrate'
              ? ADD_SUCCESS.ID_CARD_GENERATION
              : ADD_SUCCESS.ID_CARD_REGENERATION,
          level: SUCCESS,
        });
      } else {
        throw new Error(response?.data?.generateIDCards?.message);
      }
    } catch (e: any) {
      setCanClick(true);
      setAlertDetails({ message: e.message, level: ERROR });
    }
  }

  async function handleGenerateAllReceipts(type: string) {
    if (!documentsData) setCanClick(false);
    try {
      const response = await generatePaymentReceipts({ variables: { batchId, division } });
      if (response?.data?.generatePaymentReceipts?.status === SUCCESS) {
        setCanClick(true);
        closeDownloadDocumentsModal();
        setAlertDetails({
          message:
            type === 'generate'
              ? ADD_SUCCESS.FEE_RECEIPT_GENERATION
              : ADD_SUCCESS.FEE_RECEIPT_REGENERATION,
          level: SUCCESS,
        });
      } else {
        throw new Error(response?.data?.generatePaymentReceipts?.message);
      }
    } catch (e: any) {
      setCanClick(true);
      setAlertDetails({ message: e.message, level: ERROR });
    }
  }

  async function handleGeneratePrintableIdCards(type: string) {
    if (!documentsData) setCanClick(false);
    try {
      const response = await generatePrintableIdCards({ variables: { batchId, division } });
      if (response?.data?.generatePrintableIDCardsPdf?.status === SUCCESS) {
        setCanClick(true);
        closeDownloadDocumentsModal();
        setAlertDetails({
          message:
            type === 'genenrate'
              ? ADD_SUCCESS.PRINTABLE_ID_CARD_GENERATION
              : ADD_SUCCESS.PRINTABLE_ID_CARD_REGENERATION,
          level: SUCCESS,
          expireInMs: 10000,
        });
      } else {
        throw new Error(response?.data?.generatePrintableIDCardsPdf?.message);
      }
    } catch (e: any) {
      setCanClick(true);
      setAlertDetails({ message: e.message, level: ERROR, expireInMs: 10000, });
    }
  }

  async function handleGeneratePrintableLibraryCards(type: string) {
    if (!documentsData) setCanClick(false);
    try {
      const response = await generatePrintableLibraryCards({ variables: { batchId, division } });
      if (response?.data?.generatePrintableLibraryCardsPdf?.status === SUCCESS) {
        setCanClick(true);
        closeDownloadDocumentsModal();
        setAlertDetails({
          message:
            type === 'genenrate'
              ? ADD_SUCCESS.PRINTABLE_LIBRARY_CARD_GENERATION
              : ADD_SUCCESS.PRINTABLE_LIBRARY_CARD_REGENERATION,
          level: SUCCESS,
          expireInMs: 10000,
        });
      } else {
        throw new Error(response?.data?.generatePrintableLibraryCardsPdf?.message);
      }
    } catch (e: any) {
      setCanClick(true);
      setAlertDetails({ message: e.message, level: ERROR, expireInMs: 10000, });
    }
  }

  function handleDowloadDocuments() {
    switch (documentType) {
      case 'FEE_RECEIPT':
        exportReport(`invoice/batch/${batchId}/${division}`, 'Fee Receipts.zip', "");
        break;
      case 'ID_CARDS_GENERATION':
        exportReport(`students/idCards/${batchId}/${division}`, 'ID Cards.zip', "");
        break;
      case 'PRINTABLE_ID_CARDS_GENERATION':
        exportReport(`students/idCardPrintablePdf/${batchId}/${division}`, 'ID Cards.pdf', "");
        break;
      case 'PRINTABLE_LIBRARY_CARDS_GENERATION':
        exportReport(`students/libraryCardPrintablePdf/${batchId}/${division}`, 'Library Cards.pdf', "");
        break;
    }
  }

  function getDocumentsSubmitFunction(type: string) {
    switch (documentType) {
      case 'FEE_RECEIPT':
        handleGenerateAllReceipts(type);
        break;
      case 'ID_CARDS_GENERATION':
        handleGenerateAllIdCards(type);
        break;
      case 'PRINTABLE_ID_CARDS_GENERATION':
        handleGeneratePrintableIdCards(type);
        break;
      case 'PRINTABLE_LIBRARY_CARDS_GENERATION':
        handleGeneratePrintableLibraryCards(type);
        break;
    }
  }

  const onCloseForm = () => {
    setForm({ state: "CLOSE" });
  };

  const onSuccess = (message: string): void => {
    setAlertDetails({ message, level: SUCCESS });
    if (refetchInstituteStudents) refetchInstituteStudents();
    onCloseForm();
  };

  const onError = (message: string): void => {
    setAlertDetails({ message, level: ERROR });
    onCloseForm();
  };

  return (
    <>
      <LoadContentWrapper style={{ paddingBottom: 0 }}>
        <ModalTabs
          onSelectSwitch={t => {
            changeTabs(t);
          }}
          option1={t('students.label')}
          // cohort tab, will be uncommented when cohort new changes are implemented
          // option2={t('cohorts.label')}
          option2={t('timetable.label')}
          selectionMode={isStudents ? 1 : 2}
          style={[styles.modalStyle, { marginBottom: isStudents ? rem(2.8) : rem(2.3) }]}
          onChange={changeTabs}
          tabswidth={rem(17.2)}
          tabsheight={rem(3)}
          padding_bottom={rem(1.2)}
        />
        {isStudents ? StudentsLists : <></>}
      </LoadContentWrapper>

      {/* Partial Fee Payment Form */}
      {
        form.state === "OPEN" && form.step === "PartialPaymentForm" && form.data && (
          <PartialFeePayment
            paymentFor={"student"}
            batchId={batchId}
            form={form as TForm & { data: TPartialFeePaymentData }}
            onSuccess={onSuccess}
            onError={onError}
            onClose={onCloseForm}
          />
        )
      }

      {
        form.state === "OPEN" &&
        form.step === "AssignFeeCompositionForm" &&
        form.data && (
          <AssignFeeCompositionForm
            data={form.data}
            onSuccess={onSuccess}
            onError={onError}
            onClose={onCloseForm}
          />
        )
      }

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeChangeDivisionModal}
        Headerpopup={t('change-division.text')}
        modalVisible={changeDivisionModalState}
        handleSave={changeDivisionFn}
        maxWidth={'md'}
        height={height * 0.9}
        addEditButtonLabel={'save.label'}>
        <ChangeDivisionTable
          currentDivison={division}
          loading={loadingBasicStudentData}
          tableData={changeDivisionTableData}
          availableDivisions={getDivisionDropdownOptions(batchData?.batch)}
          inputState={changeDivisionInputState}
          setInputState={setChangeDivisionInputState}
          searchValue={changeDivisionSearchValue || ''}
          setSearchValue={setChangeDivisionSearchValue}
        />
      </NormalModal>

      <NormalModal
        onAlert={null}
        setModalVisible={closeModal}
        Headerpopup={t('inviteStudent.text')}
        width={640}
        modalVisible={modalState}
        handleSave={handleSubmit(inviteStudent)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={'invite.label'}>
        <InviteStudentForm
          closeModal={closeModal}
          control={control}
          batch={batch}
          errors={errors}
          reset={reset}
          setValue={setValue}
          division={division}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        onAlert={null}
        setModalVisible={closeImportModal}
        Headerpopup={t('inviteStudent.text')}
        width={540}
        modalVisible={importModalState}
        handleSave={handleSubmit(importStudent)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={'importStudents.text'}
        height={150}>
        <ImportStudentForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          batch={batchId}
        />
        {ImportStudents}
      </NormalModal>

      <NormalModal
        onAlert={null}
        isSubmitting={!canClick}
        setModalVisible={() => setDeleteOverlay(false)}
        Headerpopup={t('deleteStudent.text')}
        modalVisible={deleteOverlay}
        handleSave={handleSubmit(handleDeleteStudent)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={'delete.label'}
        height={300}
        maxWidth={'lg'}
        timer={true}>
        {DetailData}
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeAssignRollNo}
        modalVisible={assignRollModalState}
        Headerpopup={t('assign-roll-no.text')}
        width={778}
        maxWidth={'lg'}
        height={644}
        showFooter={false}>
        <AssignRollNoForm
          closeModal={closeAssignRollNo}
          onSubmit={handleAssignRollNo}
          setStudentRoll={setStudentRoll}
          checkError={checkError}
          batchId={batchId}
          division={division}
          setAssignRollNoTableData={setAssignRollNoTableData}
          assignRollNoTableData={assignRollNoTableData}
        />
      </NormalModal>

      <NormalModal
        setModalVisible={closePaymentDetailModal}
        modalVisible={paymentDetailModal}
        handleSave={() => { }}
        Headerpopup={t('feeDetails.label')}
        height={640}
        maxWidth={'lg'}
        hideCloseIcon={true}
        showFooter={false}
        contentStyles={{ paddingBottom: 24 }}>
        <PaymentStatusContentAction batchId={batchId} studentId={studentId} academicId={academicId} />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeLeavingCertificateModal}
        Headerpopup={t('generateLeavingCertificate.text')}
        modalVisible={leavingCertificateModal}
        handleSave={handleSubmitLeavingCertificate(handleGenerateCertificateModal)}
        height={556}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="next.label"
        visibility={!leavingCertificateConfirmationModal}>
        <GenerateLeavingCertificate
          control={controlLeavingCertificate}
          errors={errorsLeavingCertificate}
          setValue={setValueLeavingCertificate}
          leavingCertificateData={leavingCertificateData}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeLeavingCertificateConfirmationModal}
        Headerpopup={t('generateLeavingCertificate.text')}
        modalVisible={leavingCertificateConfirmationModal}
        handleSave={handleGenerateLeavingCertificate}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="generate.label"
        disabled={!confirmSelection}>
        <LeavingCertificateConfirmationForm
          confirmSelection={confirmSelection}
          data={leavingCertificateData}
          setConfirmSelection={setConfirmSelection}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeReconcilePaymentModal}
        modalVisible={reconcilePaymentModal}
        handleSave={handleReconcileStudentPayment}
        Headerpopup={t('paymentReconcile.label')}
        width={544}
        hideCloseIcon={true}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel="proceed.label"
        disabled={!confirmSelection}>
        <ReconcilePaymentForm
          confirmSelection={confirmSelection}
          setConfirmSelection={setConfirmSelection}
          reconcilePaymentData={reconcilePaymentData}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeDownloadDocumentsModal}
        modalVisible={downloadDocumentsModal}
        handleSave={() =>
          documentsData
            ? handleDowloadDocuments()
            : getDocumentsSubmitFunction('generate')
        }
        handleActionButton={() => getDocumentsSubmitFunction('regenerate')}
        Headerpopup={getExportModalHeader(documentType, t)}
        width={544}
        hideCloseIcon={true}
        cancelButtonLabel={documentsData ? 'done.label' : 'cancel.label'}
        addEditButtonLabel={documentsData ? 'download.label' : 'proceed.label'}
        actionButtonLabel={'regenerate.label'}
        showActionButton={
          documentType === 'PRINTABLE_ID_CARDS_GENERATION' || documentType === 'PRINTABLE_LIBRARY_CARDS_GENERATION'
            ? Boolean(documentsData)
            : Boolean(documentsData && documentsData?.pending == 0)
        }
        hideSubmit={Boolean(
          !(
            documentsData &&
            documentsData?.completed == 0 &&
            documentType !== 'PRINTABLE_ID_CARDS_GENERATION' &&
            documentType !== 'PRINTABLE_LIBRARY_CARDS_GENERATION'
          ),
        )}
        contentStyles={{ paddingBottom: 24 }}>
        <DownloadDocumentsModal
          documentsData={documentsData}
          documentType={documentType}
          setExportFailureModal={setExportFailureModal}
          exportFailureModal={exportFailureModal}
        />
      </NormalModal>
    </>
  );
}

const customStyles = {
  tableHeadCells: { position: 'relative', zIndex: 3 },
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 10;
  height: 42px;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  max-width: 280px;
  margin-right: 8px;
`;

const FilterWrapper = styled.View`
  left: 0;
  flex: 1;
  align-items: flex-start;
  height: ${(props) => (props.filterHeight * 32) - 70}px;
  width: ${(props) => (props.isEdbaAdmin ? (width > 1700 ? 75 : 72) : width > 1700 ? 80 : 80)}%;
  margin-right: 5px;
`;

const SelectAllWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 32px;
  width: 100%;
  height: 40px;
  border: 1px solid ${colors.noteBlue};
  border-radius: 4px;
  background-color: ${colors.noteBg};
  z-index: 2;
`;

const SelectAllText = styled.View`
  margin-left: 4px;
`;

const AddClassWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  min-width: 112px;
`;

const ContentSpan = styled.View`
  flex: 1;
`;

const IconWrapper = styled.View`
  margin-right: 8px;
`;

const SpinnerView = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const ButtonGroupWrapper = styled.View``;

const DropdownIconWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
`;

const InviteButton = styled.TouchableOpacity`
  border-width: 1px;
  border-radius: 4px;
  padding: 14px 32px 14px 16px;
  justify-content: center;
  margin-right: 36px;
  position: absolute;
  margin-top: 38px;
  background-color: ${colors.white};
  border-color: ${colors.white};
  box-shadow: 0px 0px 4px ${colors.searchShadow};
  align-self: flex-start;
`;
