import { AccountObject } from "src/components/organism/Login/LoginHelpers";

import ImageAtom from "src/components/atoms/ImageAtom/index";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import React from "react";
import { UserType } from "src/constant";
import { clipLongText, properCase } from "src/utils/utility";
import { colors } from "src/styles/theme/styles";
import styled from "styled-components/native";
import { isWeb } from "src/constant/device";

interface LoginCardProps {
  index: number;
  lastIndex: number;
  onPress: (cardData: AccountObject) => void;
  cardData: AccountObject;
}

const CardLabel = ({ label }: { label: string }) => {
  return (
    <NormaltextAtom
      value={label || "-"}
      color={colors.tertiaryText}
      lineHeight={isWeb ? 1.7 : 1.9}
    />
  );
};

const LoginCard = (props: LoginCardProps) => {
  const { cardData, index, onPress, lastIndex } = props;
  return (
    <Card
      onPress={() => onPress(cardData)}
      key={`${index}`}
      lastCard={index === lastIndex}
      activeOpacity={1}
    >
      <ImageWrapper>
        <ImageAtom
          source={{
            uri: cardData.profilePicture,
          }}
          style={styles.imgStyle}
        />
      </ImageWrapper>
      <TextWrapper>
        <TitleWrapper>
          <NormaltextAtom
            value={properCase(clipLongText(cardData.fullName, 52))}
            fontSize={1.6}
            color={colors.primaryText}
          />
        </TitleWrapper>
        {cardData.userType === UserType.APPLICANT && (
          <CardLabel label={"Applicant"} />
        )}
        {[UserType.INSTITUTE_EMPLOYEE, UserType.INSTITUTE_STUDENT].includes(
          cardData.userType
        ) && <CardLabel label={cardData?.instituteName || "-"} />}
        {cardData.userType === UserType.INSTITUTE_EMPLOYEE &&
          cardData?.employeeId && (
            <CardLabel label={`Employee ID: ${cardData?.employeeId}`} />
          )}
        {cardData.userType === UserType.INSTITUTE_STUDENT &&
          cardData?.className && (
            <CardLabel label={`Class: ${cardData.className}`} />
          )}
        {cardData.userType === UserType.INSTITUTE_STUDENT &&
          cardData?.division && (
            <CardLabel
              label={`Division: ${cardData.division}${cardData?.rollNo ? `   Roll No: ${cardData.rollNo}` : ""
                }`}
            />
          )}
      </TextWrapper>
    </Card>
  );
};

const styles = {
  imgStyle: {
    height: 40,
    width: 40,
    borderRadius: 50,
  },
};

const Card = styled.TouchableOpacity<{ lastCard: boolean }>`
  min-height: ${isWeb ? "64px" : "80px"};
  padding: 12px 16px 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: ${({ lastCard }) => ((isWeb && lastCard) ? 0 : 1)};
  border-color: ${colors.borderGrey};
`;

const TitleWrapper = styled.View`
  margin-bottom: 4px;
`;

const ImageWrapper = styled.View`
  margin-right: 16px;
`;

const TextWrapper = styled.View`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default LoginCard;
