import { MarkedDates, Theme } from "react-native-calendars/src/types";
import { StyleProp, ViewStyle } from "react-native";

import { Calendar } from "react-native-calendars";
import React from "react";
import { colors } from "src/styles/theme/styles";
import { convertToISODate } from "./CalendarHelpers";
import { isWeb } from "src/constant/device";

type LeaveCalendarProps = {
  initialDate: Date;
  markedDates: MarkedDates;
};

const LeaveCalendar = (props: LeaveCalendarProps) => {
  const { initialDate, markedDates } = props;
  return (
    <Calendar
      testID="leaveCalendar"
      style={ComponentProps.calendarStyles}
      current={convertToISODate(initialDate)} // Set initially visible month
      firstDay={1} // Week starts from Monday
      hideArrows={true} // Hide month navigation arrows
      hideExtraDays={false}
      showSixWeeks={true} // Consistent calendar height
      disableMonthChange={true}
      markedDates={markedDates}
      theme={ComponentProps.theme}
    />
  );
};

type ComponentPropsType = {
  calendarStyles: StyleProp<ViewStyle>;
  theme: Theme;
};

const ComponentProps: ComponentPropsType = {
  calendarStyles: isWeb
    ? {
      borderWidth: 1,
      borderRadius: 16,
      borderColor: colors.borderGrey,
      padding: 8,
    }
    : {
      marginHorizontal: -8,
    },
  theme: {
    todayTextColor: colors.white,
    todayBackgroundColor: colors.primary,
    monthTextColor: colors.primaryText,
  },
};

export default LeaveCalendar;
