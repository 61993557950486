import React, { Fragment, useEffect, useState } from 'react';
import { View } from 'react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import { height, isWeb } from 'src/constant/device';
import styled from 'styled-components/native';
import Element from '../Forms/ApplicationElement';
import messageComposerFormJSON from '../../../form-json/message/create-message.json';

export default function MessageComposer(props: any) {
  const {
    control,
    errors,
    setError,
    clearErrors,
    recepientOptions = null,
    statusOptions = null
  } = props;
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    if (recepientOptions && recepientOptions?.length > 0) {
      messageComposerFormJSON[0].fields[0].option = recepientOptions;
      messageComposerFormJSON[0].fields[0].fieldVisible = true;
    }
    if (statusOptions && statusOptions?.length > 0) {
      messageComposerFormJSON[0].fields[1].option = statusOptions;
      messageComposerFormJSON[0].fields[1].fieldVisible = true;
    }
    setElements(messageComposerFormJSON[0]);

    return () => {
      messageComposerFormJSON[0].fields[0].option = [];
      messageComposerFormJSON[0].fields[1].option = [];
      messageComposerFormJSON[0].fields[0].fieldVisible = false;
      messageComposerFormJSON[0].fields[1].fieldVisible = false;
    };
  }, [statusOptions, recepientOptions]);

  return (
    <Fragment>
      <View>
        {fields ? (
          fields?.map((field: any, i: number) => {
            return <View>
                <Element
                  key={`message${i}`}
                  field={field}
                  control={control}
                  errors={errors}
                  setError={setError}
                  clearErrors={clearErrors}
                />
            </View>
          })
        ) : (
          <SpinnerView><LoaderSpinner /></SpinnerView>
        )}
      </View>
    </Fragment>
  )
}

const SpinnerView = styled.View`
  width: ${isWeb ? '540px' : 'auto'};
  height: ${isWeb ? 418 : height - 300}px;
  justify-content: center;
  align-items: center;
`;

