import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props:any) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M17.5 6H16V4.375C16 4.16875 15.8313 4 15.625 4H14.375C14.1687 4 14 4.16875 14 4.375V6H10V4.375C10 4.16875 9.83125 4 9.625 4H8.375C8.16875 4 8 4.16875 8 4.375V6H6.5C5.67188 6 5 6.67187 5 7.5V18.5C5 19.3281 5.67188 20 6.5 20H17.5C18.3281 20 19 19.3281 19 18.5V7.5C19 6.67187 18.3281 6 17.5 6ZM17.3125 18.5H6.6875C6.58438 18.5 6.5 18.4156 6.5 18.3125V9H17.5V18.3125C17.5 18.4156 17.4156 18.5 17.3125 18.5Z"
        fill="black"
        fillOpacity="0.6"/>
      <Path
        d="M10.9756 15.0227C11.2506 15.1227 11.5631 15.2352 11.8006 15.2695C12.3349 15.3539 12.7787 15.2508 12.8143 15.0419C12.8396 14.8934 12.8546 14.8051 11.8661 14.5219L11.6631 14.4633L11.6588 14.462C11.0868 14.2923 9.58555 13.8469 9.82557 12.3048C9.98182 11.5589 10.5349 11.058 11.3237 10.8795V10.5508C11.3237 10.1367 11.6593 9.80078 12.0731 9.80078C12.4868 9.80078 12.8231 10.1367 12.8231 10.5508V10.9023C13.0078 10.945 13.2047 10.9989 13.4424 11.0811C13.8338 11.2167 14.0409 11.6436 13.9062 12.0352C13.7706 12.4262 13.3446 12.6347 12.9524 12.4989C12.694 12.4091 12.4925 12.3539 12.3384 12.3295C11.8115 12.2455 11.3679 12.349 11.3318 12.5605L11.3317 12.5612C11.3099 12.6893 11.2927 12.79 12.0849 13.0227L12.2226 13.0798C13.0198 13.3077 14.507 13.733 14.2357 15.297C14.1351 16.0433 13.5845 16.5472 12.7951 16.7258V17.0508C12.7951 17.4648 12.4589 17.8008 12.0451 17.8008C11.6317 17.8008 11.2957 17.4648 11.2957 17.0508V16.6935C10.9992 16.6242 10.7044 16.5293 10.4398 16.4338L10.2943 16.382C9.93182 16.2445 9.70057 15.8164 9.8662 15.3977C10.0037 15.0352 10.4318 14.832 10.8224 14.9695L10.9756 15.0227Z"
        fill="black"
        fill-opacity="0.6"/>
    </Svg>
  )
}

export default SvgComponent