import { gql, useLazyQuery, useMutation } from "@apollo/client";

const publicApplicantInvitesQuery = gql`
  query (
    $filters: ApplicantInviteInput!
    $limit: Int
    $skip: Int
    $sort: ApplicantInviteSortInput
  ) {
    applicantInvites(
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
    ) {
      data {
        id
        name
        firstName
        lastName
        middleName
        applicationId
        aadhaarBelongsTo
        aadhaarId
        mobileNumber
        email
        date
        inviteSentAt
        dob
        subjectGroup {
          name
        }
        siblingData {
          class
          grNo
        }
        address {
          houseNo
          street
          area
          city
          state
          zipcode
        }
        documents {
          id
          name: documentName
          type
          fileType
          fileExtension
          uri: documentUrl
        }
      }
      totalCount
    }
  }
`;

export function useGetPublicApplicantInvites() {
  const [getPublicApplicantInvites, { loading, data, refetch }] = useLazyQuery(
    publicApplicantInvitesQuery,
    { fetchPolicy: "network-only" }
  );
  return { getPublicApplicantInvites, data, loading, refetch };
}

const triggerPublicApplicantInviteMailsMutation = gql`
  mutation ($payload: TriggerInviteMailsInput!) {
    triggerInviteMails(payload: $payload) {
      failedInvites
      successfulInvites
    }
  }
`;

export function useTriggerPublicApplicantInviteMails() {
  const [triggerInvitation] = useMutation(
    triggerPublicApplicantInviteMailsMutation
  );
  return { triggerInvitation };
}

const triggerInverviewScheduleMailsMutation = gql`
  mutation ($payload: InterviewMailInput!) {
    triggerInterviewScheduleMail(payload: $payload) {
      status
      message
    }
  }
`;

export function useTriggerInverviewScheduleMails() {
  const [triggerInterviewMail] = useMutation(
    triggerInverviewScheduleMailsMutation
  );
  return { triggerInterviewMail };
}

export const admissionNameQuery = gql`
  query ($id: String!) {
    admission(id: $id) {
      id
      name
    }
  }
`;
