import { useState } from 'react';
import { userInformation } from '../manageState';
import { employeePersonalInformationQuery } from '../../graphql/onboarding/employee';
import { studentPersonalInformationQuery } from '../../graphql/onboarding/student';
import { applicantPersonalInformationQuery } from '../../graphql/onboarding/applicant'
import { graphqlQuery } from '../../graphql/util';
import { formDDcontents } from '../../graphql/onboarding/form-content'
import { UserType } from '../../constant/index';
import { useProfileDetail } from '../../contexts/profile-detail-context';

export interface IUserProfileDetails {
  id: string;
  firstName: string;
  lastName: string;
  academic?: any;
  employeeId?: string;
  profilePicture: any;
  personalDetails: any;
  addressDetails: any;
  parentDetails?: any;
  educationDetails: any[];
  experienceDetails: any[];
  otherDetails: any;
  onboardingCompletion: any;
  totalOnboardingCompletion: number;
  institute: {
    type: string;
    name: string;
  },
  [key: string]: any;
}

export const useLoadUserDetails = () => {
  const [userDetails, setUserDetails] = useState<IUserProfileDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formContents, setFormContents] = useState<any>();
  const { setUserProfileDetails } = useProfileDetail();

  const loadUserDetails = async (
    param?: { userId: string; userType: UserType },
    updateProfileCompletion?: boolean,
  ) => {
    setIsLoading(true);
    let userId: string = '';
    let userType: UserType = UserType.INSTITUTE_EMPLOYEE;
    const currentUserObject = userInformation();
    if (param) {
      userId = param.userId;
      userType = param.userType;
    } else {
      if (currentUserObject) {
        userId = currentUserObject.userId;
        userType = currentUserObject.userType;
      }
    }
    const formContents = await graphqlQuery(formDDcontents, {
      // temporary fix to prevent api fail. Remove when applicant enum available in api
      userType: (!userType || userType == 4) ? "INSTITUTE_STUDENT" : UserType[userType]
    });
    if (formContents?.data?.formDDContents) {
      setFormContents(formContents?.data?.formDDContents)
    }

    if (userType) {
      switch (userType) {
        case UserType.INSTITUTE_EMPLOYEE:
          const { data: empData, loading: empLoading } = await graphqlQuery(employeePersonalInformationQuery, {
            employeeId: userId,
          });
          setIsLoading(empLoading);
          if (empData && empData?.instituteEmployee) {
            setUserProfileDetails(empData?.instituteEmployee);
            setUserDetails(empData.instituteEmployee);
          }
          break;
        case UserType.APPLICANT:
          const { data: applData, loading: applLoading } = await graphqlQuery(applicantPersonalInformationQuery, {
            id: userId,
          });
          setIsLoading(applLoading);
          if (applData && applData?.Applicant) {
            setUserProfileDetails(applData.Applicant);
            setUserDetails(applData.Applicant);
          }
          break;
        case UserType.INSTITUTE_STUDENT:
          const { data: stuData, loading: stuLoading, } = await graphqlQuery(studentPersonalInformationQuery, {
            studentId: userId,
          });
          setIsLoading(stuLoading);
          if (stuData && stuData?.instituteStudent) {
            setUserProfileDetails(stuData?.instituteStudent);
            setUserDetails(stuData.instituteStudent);
          }
          break;
        default:
          console.log("Something went wrong");
          break;
      }
    }

    if (updateProfileCompletion) {
      if (param?.userId) {
        if (currentUserObject.userId == param.userId) {
          updateUserInformation(currentUserObject, userDetails);
        }
      } else {
        updateUserInformation(currentUserObject, userDetails);
      }
    }
  };

  return {
    userDetails,
    isLoading,
    formContents,
    loadUserDetails
  }
}

const updateUserInformation = async (user: any, userDetails?: IUserProfileDetails) => {
  try {
    if (userDetails) {
      if (userDetails?.totalOnboardingCompletion == 100) {
        user.userDetail.profileCompletion == true;
        userInformation(user);
      } else {
        const completions = userDetails.onboardingCompletion;
        Object.keys(completions).forEach((key) => {
          if (typeof completions[key] == 'object') {
            const vals: number[] = Object.values(completions[key]);
            user.userDetail.profileCompletion[key] = Math.max(...vals) == 100;
          } else {
            user.userDetail.profileCompletion[key] = completions[key] == 100;
          }
        });
        await userInformation(user);
      }
    }
  } catch (err: any) {
    console.log("error in updating user profile completion", err);

  }
}