import React, { useEffect, useState } from "react";

import { DropdownOptionsType } from "src/types";
import Element from "src/components/molecules/Forms/ApplicationElement";
import FormJSON from "src/form-json/admission/search-invite-cap-form.json";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { View } from "react-native";
import { colors } from "src/styles/theme/styles";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { graphqlQuery } from "src/graphql/util";
import { isWeb } from "src/constant/device";
import { searchCAPApplicantQuery } from "src/graphql/admission";

interface PropType {
  control: any;
  errors: any;
  reset: any;
  setValue: any;
  clearErrors: any;
  setError: any;
  getValues: any;
  watch: any;
  admissionId: string;
  subjectGroupOptions: DropdownOptionsType[];
}

export default function SearchInviteCapApplicantForm(props: PropType) {
  const {
    control,
    errors,
    reset,
    setValue,
    clearErrors,
    setError,
    admissionId,
    subjectGroupOptions,
    getValues,
    watch,
  } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};
  const capIDValue = watch("capId");

  useEffect(() => {
    setValue("subjectGroup", null);
  }, [])

  useEffect(() => {
    if (subjectGroupOptions && subjectGroupOptions?.length > 0) {
      const form = { ...FormJSON[0] };
      form.fields[4].option = subjectGroupOptions;
      setElements(form);
    }
  }, [subjectGroupOptions]);

  useEffect(() => {
    if (!capIDValue) {
      clearErrors();
    }
  }, [capIDValue]);

  function clearForm() {
    setValue("name", "");
    setValue("emailId", "");
    setValue("scrollRefNo", "");
  }

  async function searchByCapId() {
    const searchValue = getValues("capId");
    if (searchValue && !errors?.capId?.message) {
      const { data, loading } = await graphqlQuery(
        searchCAPApplicantQuery,
        { admission: admissionId, capId: getValues("capId") },
        false
      );
      if (data?.searchCAPApplicant.length == 0) {
        setError("capId", { message: "No match found" });
        clearForm();
      } else if (data?.searchCAPApplicant.length > 1) {
        setError("capId", { message: "Multiple matches for this CAP ID" });
        clearForm();
      } else if (data?.searchCAPApplicant.length === 1) {
        clearErrors();
        const val = data.searchCAPApplicant[0];
        setValue("capId", val.capId);
        setValue("name", val.name);
        setValue("emailId", val.email);
      }
    }
  }

  return (
    <View style={isWeb && styles.containerStyles}>
      {fields &&
        fields.map((field: any, i: number) => (
          <View key={`e${i}`} style={isWeb && styles.elementStyles}>
            <Element
              key={`e${i}`}
              field={field}
              control={control}
              inputWidth={field.id === "capId" ? 350 : 450}
              dropdownWidth={field.id === "capId" ? 350 : 450}
              errors={errors}
            />
            {field.id === "capId" && (
              <SecondaryBtn
                icon={faSearch}
                iconColor={colors.white}
                onPress={searchByCapId}
                label={"CAP"}
                style={isWeb ? styles.searchBtnWeb : styles.searchBtnNative}
                customDisabled={!capIDValue || errors?.capId?.message}
                lineHeight={!isWeb && 2}
              />
            )}
          </View>
        ))}
    </View>
  );
}

const styles = {
  searchBtnWeb: { marginTop: 17, height: 40, width: 80 },
  searchBtnNative: {
    height: 40,
    width: 100,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: -16,
    marginBottom: 16,
  },
  containerStyles: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  elementStyles: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: 450,
  },
};
