import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import newsFormJSON from '../../../form-json/communication/news.json';
import Element from '../Forms/ApplicationElement';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

export default function NewsForm(props: any) {
  const {
    handleFilesAttach,
    handleFileRemove,
    fileObjects,
    setAnnoucementWay,
    annoucementWay,
    setError,
    clearErrors,
  } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    setElements(newsFormJSON[0]);
  }, []);

  const handleNotification = () => {
    if (annoucementWay) {
      setAnnoucementWay(false);
    } else {
      setAnnoucementWay(true);
    }
  };

  return (
    <View>
      <View>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View key={`newsf${i}`}>
              <Element
                field={field}
                control={props.control}
                errors={props.errors}
                file={fileObjects[field.id]}
                handleFilesAttach={handleFilesAttach(field.id)}
                handleFileRemove={handleFileRemove(field.id)}
                handleCheckbox={handleNotification}
                elementStyle={field.type === 'checkbox' && { marginLeft: -10 }}
                checkBoxState={annoucementWay}
                fileWidth={350}
                setError={setError}
                clearErrors={clearErrors}
              />
            </View>
          ))
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
});
