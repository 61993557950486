/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { Dimensions, BackHandler } from 'react-native';
import { useThemeSystem } from 'src/contexts/theme-context';
import { DeskHeader } from 'src/components/molecules/Navigation/Header.web';
import { Route, Redirect, useHistory } from './routing.web';
import { HeaderProvider } from 'src/contexts/header-context';
import { FilterProvider } from 'src/contexts/filter-context';
import { breakpoints } from 'src/styles/theme/styles';
import { isWeb, width as wd } from 'src/constant/device';
import { MobileFooter } from 'src/components/molecules/Navigation/MobileFooter';
import { UserType, USER_TYPE } from 'src/constant';
import { getTokenFromStorage, getUserTypeFromStorage, removeTokenFromStorage } from 'src/components/services/storage-service';
import { intersection } from 'lodash';
import styled from 'styled-components/native';
import { allowedModulesState, userInformation } from 'src/utils/manageState';
import jwt_decode from 'jwt-decode';
import SessionExpiry from 'src/components/organism/Login/SessionExpiry';
import { getApplicationPath } from 'src/utils/utility';
import DrawerWeb from 'src/components/molecules/Navigation/Drawer.web';

const Wrapper: React.FC = ({ children }) => {
  const { theme } = useThemeSystem();

  const [width, setWidth] = React.useState(wd);

  React.useEffect(() => {
    Dimensions.addEventListener('change', (e) => {
      const { width: wwdith } = e.window;
      setWidth(wwdith);
    });
  }, []);

  return (
    <>
      <Container>
        {isWeb && width > breakpoints.md && (
          <DrawerWeb />
        )}
        <Main style={[{ backgroundColor: theme?.mainContainer?.backgroundColor }]}>
          {width > breakpoints.md && <DeskHeader />}
          <MainSubContainer>
            {children}
          </MainSubContainer>
        </Main>
      </Container>
    </>
  );
};

const MobileWrapper: React.FC = ({ children }) => {
  return (
    <MobileFooterContainer>
      {children}
      <MobileFooter />
    </MobileFooterContainer>
  );
};

export const WebPrivateRoute = ({ component: Component, componentProps, ...rest }: any) => {
  const currentUserObject = userInformation();
  const authUser = rest.authUser;
  const userType = rest.userType;
  const userSubType = rest.userSubType ? rest.userSubType : currentUserObject?.userDetail?.type;
  console.log(rest?.module)
  const module = rest?.module || 'default';
  const subAuth = rest?.subAuth || [];
  const allowedModules = allowedModulesState();
  const history = useHistory();
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [width, setWidth] = React.useState(Dimensions.get('window').width);

  async function reditrectToHome() {
    const token = await getTokenFromStorage();
    if (!token) {
      const { prefix, requestId, capId, inviteCode } = rest?.computedMatch?.params;
      let path = '/login';
      if (inviteCode || (prefix && requestId)) {
        history.push(getApplicationPath(path, prefix, requestId, capId, inviteCode));
      } else {
        const userType = await getUserTypeFromStorage();
        if (userType && parseInt(userType) == UserType.INSTITUTE_EMPLOYEE) {
          history.push('/employee/login');
        } else {
          history.push('/');
        }
      }
    }
  }

  useEffect(() => {
    getTokenFromStorage().then((token) => {
      if (token) {
        const decoded: any = jwt_decode(token);
        if (decoded?.exp) {
          const expDate = new Date(decoded.exp * 1000);
          const today = new Date();
          if (today > expDate) {
            setSessionExpired(true);
            removeTokenFromStorage();
          }
        }
      }
    })
  }, [rest.path]);

  const backActionHandler = () => {
    history.goBack();
    return true;
  };

  useEffect(() => {
    reditrectToHome();
  }, []);

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', backActionHandler);
    return () => BackHandler.removeEventListener('hardwareBackPress', backActionHandler);
  }, []);

  useEffect(() => {
    Dimensions.addEventListener('change', (e) => {
      const { width: wwidth } = e.window;
      setWidth(wwidth);
    });
  }, []);

  let allowed = true;
  if (userType) {
    allowed = allowed && authUser.includes(userType);
    if (userType === USER_TYPE.EMPLOYEE) {
      allowed = allowed && (module == 'default' || (allowedModules && allowedModules.includes(module)));
      console.log(intersection(subAuth, userSubType))
      if (subAuth && subAuth?.length > 0 && intersection(subAuth, userSubType)?.length === 0) {
        allowed = false;
      }
    }
  }

  if (!allowed) {
    return <Route {...rest} render={() => <Redirect to="/" />} />;
  }

  return (
    <>
      {
        !sessionExpired && <Route
          {...rest}
          render={() =>
            width > breakpoints.md ? (
              <HeaderProvider>
                <FilterProvider>
                  <Wrapper>
                    <Component userType={userType} {...componentProps} />
                  </Wrapper>
                </FilterProvider>
              </HeaderProvider>
            ) : (
              <HeaderProvider>
                <MobileWrapper>
                  <Component userType={userType} {...componentProps} />
                </MobileWrapper>
              </HeaderProvider>
            )
          }
        />
      }

      <SessionExpiry isSessionExpired={sessionExpired} />
    </>
  );
};

export default React.memo(WebPrivateRoute);

const Container = styled.View`
  flex-direction: row;
`
const Main = styled.View`
  flex: 5;
`
const MobileFooterContainer = styled.View`
  flex: 1;
`
const MainSubContainer = styled.View`
  z-index: -1;
`

//https://stackoverflow.com/questions/57934848/how-can-i-hide-some-navbar-and-sidebar-on-some-selected-component
//Create wrapper and wrap this component - PoratalWrapper.tsx
//right every sidebar action in wrapper
