import React, { useEffect, useState } from "react";
import { TableCells, TableDiv, TableRows } from "../Table/TableAtom";
import {
  minAvgMarksInputItemType,
  minAvgMarksInputStatesType,
} from "src/components/organism/Admission/types";

import Input from "src/components/atoms/Input/input.web";
import SmallTextAtom from "src/components/atoms/Text/SmallTextAtom";
import { TableBody } from "@mui/material";
import { colors } from "src/styles/theme/styles";
import styled from "styled-components";
import useTable from "../Table/UseTable";

const headCells1 = [
  {
    id: "category",
    label: "form.category.label",
    align: "left",
  },
  {
    id: "minAvgMarks",
    label: "min-avg-marks.label",
    align: "right",
  },
];

interface ReqMinMarksFormProps {
  reqMarksData: minAvgMarksInputItemType[];
  minAvgInputStates: Map<string, minAvgMarksInputStatesType>;
  setMinAvgInputStates: React.Dispatch<
    React.SetStateAction<Map<string, minAvgMarksInputStatesType>>
  >;
}

function ReqMinMarksForm(props: ReqMinMarksFormProps) {
  const { reqMarksData, minAvgInputStates, setMinAvgInputStates } = props;
  const [headCells, setHeadCells] = useState(headCells1);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    reqMarksData,
    headCells,
    null,
    reqMarksData?.length
  );

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    const mapObject = new Map(
      reqMarksData.map(({ category, minAverage, name }) => [
        category,
        { category, minAverage, hasError: false },
      ])
    );
    setMinAvgInputStates(mapObject);
  }

  function changeValue(inputVal: number, item: minAvgMarksInputItemType) {
    const previousState = minAvgInputStates.get(item.category) || {
      category: item.category,
      minAverage: 0,
      hasError: false,
    };
    const hasError = inputVal > 100 || inputVal < 0;
    const newState = { ...previousState, minAverage: inputVal, hasError };
    setMinAvgInputStates((prev) => new Map(prev.set(item.category, newState)));
  }

  return (
    <TableDiv>
      <TblContainer>
        <TblHead setHeadCells={setHeadCells} />
        {reqMarksData && reqMarksData?.length > 0 && (
          <TableBody>
            {recordsAfterPagingAndSorting()?.map(
              (item: minAvgMarksInputItemType) => (
                <TableRows key={item.category}>
                  <TableCells value={item.name} />
                  <TableCells align={"right"} style={styles.childInput}>
                    <Input
                      inputProps={{
                        step: "0.1",
                      }}
                      maxWidth={150}
                      typeInput={"number"}
                      id={item.category}
                      value={minAvgInputStates.get(item.category)?.minAverage}
                      setValue={(e: { target: { value: string } }) => {
                        const val = Number(e.target.value);
                        changeValue(val, item);
                      }}
                    />
                    <ErrorWrapper>
                      <SmallTextAtom
                        value={
                          minAvgInputStates.get(item.category)?.hasError
                            ? "Should be less than 100%"
                            : "\u{200B}"
                        }
                        color={colors.errorColor}
                      />
                    </ErrorWrapper>
                  </TableCells>
                </TableRows>
              )
            )}
          </TableBody>
        )}
      </TblContainer>
    </TableDiv>
  );
}

const styles = {
  childInput: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: 16,
  },
};

const ErrorWrapper = styled.div<{ width?: number }>`
  padding: 4px 0px;
`;

export default ReqMinMarksForm;
