import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useForm } from 'react-hook-form';
import publicInstitueJSON from 'src/form-json/institutes/public/index.json';
import publicInstitueGroupJSON from 'src/form-json/institutes/public/instititutes-groups.json';
import ApplicationElement from 'src/components/molecules/Forms/ApplicationElement';
import HeaderOne from 'src/components/atoms/Text/HeaderOne';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useAddPublicInstitute } from 'src/graphql/institutes/public-institute';
import { useLocation } from 'react-router-dom';
import { INSTITUTE_ALL_OPTIONS } from 'src/constant/index';
import { useThemeSystem } from 'src/contexts/theme-context';
import { boxShadow } from 'src/styles/theme/styles';
import { isWeb } from 'src/constant/device';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import { AlertLevel } from 'src/components/molecules/Alert';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { EDBALogo } from "src/assets/common/Icons";
import styled from 'styled-components';
import { useTheme } from "styled-components";


export const PublicInstituteRegister = () => {
  const { rem }: any = useTheme();
  const { theme } = useThemeSystem();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  let { setAlertDetails } = useAlertSystem();
  const [elements, setElements] = useState({});
  const [isAgreeTerms, setIsAgreeTerms] = useState(false);
  const { createPublicInstitute } = useAddPublicInstitute();
  const { fields }: any = elements ?? {};
  let pathname = '';
  let isGroup = false;
  let selectedOption = {} as any;
  let location = useLocation();
  if (location?.pathname === '/institute/register/school') {
    pathname = 'School';
    selectedOption = { ...INSTITUTE_ALL_OPTIONS[0] };
  } else if (location?.pathname === '/institute/register/college') {
    pathname = 'College';
    selectedOption = { ...INSTITUTE_ALL_OPTIONS[1] };
  } else {
    pathname = 'Master Institute';
    isGroup = true;
    selectedOption = { ...INSTITUTE_ALL_OPTIONS[2] };
  }

  useEffect(() => {
    if (isGroup) {
      setElements(publicInstitueGroupJSON[0]);
    } else {
      setElements(publicInstitueJSON[0]);
    }
    setValue('type', selectedOption);
  }, []);

  function createInstituteObject(formData: any) {
    let instituteObject = {} as any;
    let payload = {
      email: formData['email'],
      firstName: formData['firstName'],
      lastName: formData['lastName'],
      mobileNumber: parseFloat(formData['mobileNumber']),
      name: formData['name'],
      website: formData['website'],
      type: formData.type.value,
      udise: formData['udiseId']
    } as any;

    if (formData['description']) {
      payload['description'] = formData['description'];
    }

    let addressPayload = {
      city: formData['location'],
      type: 'Institute',
    };

    instituteObject['payload'] = payload;
    instituteObject['payload']['address'] = addressPayload;
    return instituteObject;
  }

  const handleAddInstitue = async (formData: any) => {
    try {
      let payloadData = createInstituteObject(formData);
      let addInstituteResponse = await createPublicInstitute({ variables: payloadData });
      if (addInstituteResponse.data) {
        setAlertDetails({ message: 'Successfully Added institute', level: AlertLevel.success });
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: AlertLevel.error });
    }
  };

  function acceptTermConditions() {
    setIsAgreeTerms(!isAgreeTerms);
  }

  return (
    <View style={styles.container}>
      <View style={styles.pageArea}>
        <View style={styles.alignthings}>
          <EDBALogo rem={rem} />
        </View>

        <View style={[styles.alignthings, styles.marginTop10]}>
          <HeaderOne value={pathname} fontWeight={'300'} />
        </View>
        <View>
          {fields &&
            fields.map((field: any, i: number) => (
              <View key={i}>
                {field?.label && <HeaderFive value={field.label} />}
                <Row>
                  {field.fields &&
                    field?.fields?.map((group: any, index: number) => {
                      return !group.id ? (
                        <Col key={index}>
                          <View style={{ width: theme?.input?.width }} />
                        </Col>
                      ) : (
                        <Col key={`col${index}`}>
                          <ApplicationElement
                            key={i}
                            field={group}
                            control={control}
                            errors={errors}
                            inputWidth={350}
                            dropdownWidth={350}
                            handleCheckbox={acceptTermConditions}
                            checkBoxState={isAgreeTerms}
                            elementStyle={group.type === 'checkbox' && { marginLeft: -2 }}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </View>
            ))}
        </View>
        <View>
          <SecondaryBtn onPress={handleSubmit(handleAddInstitue)} width={64} label={'Save'} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    marginVertical: 60,
  },

  alignthings: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginTop10: {
    marginTop: 10,
  },

  pageArea: {
    width: 804,
    backgroundColor: '#fff',
    ...boxShadow(),

    borderRadius: 3,
    padding: 32,
  },
});

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: ${isWeb ? 24 : 16}px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;