import { ASSIGNMENT_MODAL_PAGE, AUDIENCE_TYPE, FileCategory, USER_TYPE } from "src/constant";
import React, { useEffect, useState } from "react";
import { createSearchFilter, getMessageType } from "src/components/services";
import {
  fcmWebListenerInitialized,
  userInformation,
} from "src/utils/manageState";
import {
  getNotifications,
  updateLastNotificationSeen,
  useUnReadNotificationCount,
} from "src/graphql/notifications/index";
import styled, { useTheme } from "styled-components";

import AssignmentModal from "src/components/atoms/Modals/Assignment/index.web";
import { Breadcrumb } from "./Breadcrumb";
import EventModalContent from "../Event/EventModalContent.web";
import MessageModalContent from "../Message/MessageModalContent";
import Messages from "./HeaderComponents/Messages.web";
import NewsModalContent from "src/components/organism/Communication/News/ModalContent.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import Notifications from "./HeaderComponents/Notifications.web";
import Profile from "./HeaderComponents/Profile.web";
import { notificationUrl } from "src/components/organism/Notifications";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { useNavigate } from "src/routes/routing.web";
import { useQuery } from "@apollo/client";
import { withRem } from "src/utils/useRem";
import { SD } from "src/constant/standardDimensions";

const filters = createSearchFilter(5, 0, []);

export const DeskHeader = withRem((props: any) => {
  const navigate = useNavigate();
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const { heading } = useHeaderTitle();
  const { unReadCount, refetchUnreadCount } = useUnReadNotificationCount();
  const { lastNotificationSeen } = updateLastNotificationSeen();
  const userinfo = userInformation();

  const { userType } = userinfo;
  const fetchAssignments = userType && userType !== USER_TYPE.APPLICANT;
  const showNotificationIcon = userType != USER_TYPE.EDBA_ADMIN;
  const showMessageIcon = ![USER_TYPE.APPLICANT, USER_TYPE.EDBA_ADMIN].includes(
    userType
  );

  const [hideProfileMenu, setHideProfileMenu] = useState<boolean>(true);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [modalState, setModalState] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [messageModal, setMessageModal] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [assignmentId, setAssignmentId] = useState<string>("");
  const [modalType, setModalType] = useState<string>("");
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalData, setModalData] = useState({});
  const [assignmentPage, setAssignmentPage] = useState(ASSIGNMENT_MODAL_PAGE[0]);
  const [studentId, setStudentId] = useState<string>("");

  const {
    data: notifications,
    refetch: refetchNotification,
    loading: notificationLoading,
  } = useQuery(getNotifications, {
    variables: filters,
  });
  const row = notifications?.getNotifications?.data;

  if ("serviceWorker" in navigator && !fcmWebListenerInitialized()) {
    navigator.serviceWorker.addEventListener("message", (payload) => {
      console.log("recieved notification", payload?.data?.notification);
      if (payload?.data?.notification) {
        refetchUnreadCount();
      }
    });
    fcmWebListenerInitialized(true);
  }

  useEffect(() => {
    if (unReadCount && unReadCount.getUnreadNotificationCount) {
      setCount(unReadCount.getUnreadNotificationCount);
    }
  }, [unReadCount]);

  function handleProfileMenuClick() {
    setShowNotification(false);
    setHideProfileMenu(!hideProfileMenu);
  }

  function handleNotificationIconClick() {
    setHideProfileMenu(true);
    if (!showNotification) {
      lastNotificationSeen();
      refetchNotification();
      setCount(0);
    }
    setShowNotification(!showNotification);
  }

  async function notificationOnPress(notif: any) {
    if (notif.type === "ASSIGNMENTCOMMENT" || notif.type === "ASSIGNMENT") {
      const assignArray = notif.referenceId.split("/");
      setAssignmentId(assignArray[0]);
      if (
        userType === USER_TYPE.EMPLOYEE &&
        notif?.audienceType === AUDIENCE_TYPE.ONE_TO_ONE &&
        notif?.createdBy?.id
      ) {
        setStudentId(notif.createdBy.id);
        setAssignmentPage(ASSIGNMENT_MODAL_PAGE[2]);
      }

      if (
        userType === USER_TYPE.STUDENT &&
        notif?.audienceType === AUDIENCE_TYPE.BATCH
      ) {
        setAssignmentPage(ASSIGNMENT_MODAL_PAGE[3]);
      }
      setModalState(true);
    } else if (
      notif.type === "NEWS" ||
      notif.type === "NOTICE" ||
      notif.type === "EVENT" ||
      notif.type === "MESSAGE"
    ) {
      setModalType(notif.type);
      setModalTitle(notif.type.toLowerCase());
      setModalData({
        type: notif.type,
        newsnoticeId: notif.referenceId,
        eventId: notif.referenceId,
        messageId: notif.referenceId,
        messageType: await getMessageType({ messageId: notif.referenceId }),
      });
      if (notif.type !== "MESSAGE") {
        setIsModalOpen(true);
      } else {
        setMessageModal(true);
      }
    } else {
      const notifurl = notificationUrl(notif, userType);
      if (notifurl && typeof notifurl === "string") {
        navigate(notifurl);
      }
    }
    setShowNotification(false);
  }

  function messageOnPress() {
    setHideProfileMenu(true);
    setShowNotification(false);
    navigate("/messages");
  }

  function closeNotifications() {
    setShowNotification(false);
  }

  function closeProfileMenu() {
    if (!hideProfileMenu) {
      setHideProfileMenu(true);
    }
  }

  function redirectToNotifications() {
    navigate("/notifications/limit/50/page/1");
    setShowNotification(false);
  }

  const contentStyles = {
    paddingBottom: rem(2.4),
    minHeight: rem(35),
  };

  function handleAssignmentModalClose() {
    setModalState(false);
    setAssignmentPage(ASSIGNMENT_MODAL_PAGE[0]);
  }

  return (
    <>
      <Container>
        <Breadcrumb heading={heading} />
        <Wrapper>
          {showMessageIcon && <Messages onPress={messageOnPress} />}

          {
            showNotificationIcon && (
              <Notifications
                onPress={handleNotificationIconClick}
                closeFn={closeNotifications}
                count={count}
                visibility={showNotification}
                loading={notificationLoading}
                data={row}
                notificationOnPress={notificationOnPress}
                redirectToNotifications={redirectToNotifications}
              />
            )
          }

          <Profile
            onPress={handleProfileMenuClick}
            closeFn={closeProfileMenu}
            visibility={!hideProfileMenu}
          />
        </Wrapper >
      </Container >

      {fetchAssignments && (
        <AssignmentModal
          isUrlBase={false}
          assignmentModalPage={assignmentPage}
          propsStudentId={studentId}
          assignment_Id={assignmentId}
          setModalVisible={handleAssignmentModalClose}
          modalVisible={modalState}
          cancelButtonLabel="done.label"
          toggleWork={true}
          Headerpopup={t("assignment.label")}
          setToggleWork={() => { }}
        />
      )}

      <NormalModal
        isSubmitting={false}
        infoModal={true}
        preventRedirectOnClose={true}
        setModalVisible={() => setIsModalOpen(false)}
        modalVisible={isModalOpen}
        handleSave={() => { }}
        Headerpopup={
          modalType === "EVENT"
            ? t("event.label")
            : modalTitle === "news"
              ? t("news.label")
              : t("notices.label")
        }
        maxWidth={"lg"}
        width={SD.primaryInfoModal.width}
        height={SD.primaryInfoModal.height}
        contentStyles={contentStyles}
      >
        {modalType === "EVENT" ? (
          <EventModalContent {...modalData} />
        ) : (
          <NewsModalContent
            type={modalTitle === "news" ? FileCategory.News : FileCategory.Notice}
            {...modalData}
          />
        )}
      </NormalModal>

      {
        messageModal && (
          <MessageModalContent
            {...modalData}
            setMessageModal={setMessageModal}
            fromMessage={true}
          />
        )
      }
    </>
  );
});

const Container = styled.div`
  z-index: 1200;
  margin-top: ${(props) => props.theme.rem(2.9)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${(props) => props.theme.rem(3.2)};
  padding-right: ${(props) => props.theme.rem(3.2)};
  background-color: ${(props) => props.theme?.header?.backgroundColor};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
