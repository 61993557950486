import React, { useState, useEffect, useCallback } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { colors } from '../../../styles/theme/styles';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm, Controller } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import Input from '../../atoms/Input/input.web';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { validation } from '../../../constant/validation';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import {
  getBoardUniversitiesQuery,
  addBoardUniversityMutation,
  updateBoardUniversityMutation,
  useQueryUniversity,
  useMutateUniversity,
  useDeleteBoardUniversity,
  getAllBoadUniversitiesQuery,
} from '../../../graphql/academics/board-universities';
import { useParams, useHistory } from '../../../routes/routing.web';
import { AUTOCOMPLETE_MODULE, ERROR, limit, SUCCESS } from '../../../constant';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { readCacheQuery, writeCacheQuery } from 'src/graphql/util';
import { useI18n } from 'src/i18n/hooks';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import useTable from 'src/components/molecules/Table/UseTable';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, debounce } from 'src/components/services';
import { getUniversityFilter } from 'src/components/services/filters';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import styled from 'styled-components/native';
import { Tooltip } from '@mui/material';
import { height } from 'src/constant/device';

const maxHeight = 440;

interface HeadcellType {
  id: string;
  label: string;
  align: string;
  disableSorting: boolean;
}

const headCells: HeadcellType[] = [
  {
    id: 'board_name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'board_alias',
    label: 'alias.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: 'actions',
    align: 'right',
    disableSorting: true,
  },
];

export default function BoardUniversity() {
  const { t } = useI18n();
  const [canClick, setCanClick] = useState(true);
  const { theme } = useThemeSystem();
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  let row;
  let searchValue = '' as string;

  interface parameterType {
    page?: string;
    dataLimit?: string;
  }

  const { page = 1, dataLimit = `${limit}` } = useParams<parameterType>();


  const rowDefaultLimit =  parseInt(dataLimit) ?? 0;
  const [currentPage, setCurrentPage] = useState<number>(parseInt(page));
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);


  const { filters, setFilter } = useFilter();
  const { setHeading } = useHeaderTitle();
  if (filters && filters?.university?.on) {
    searchValue = filters?.university?.search;
  }

  const [modalState, handleModal] = useState(false);
  const { mutate: addBoardUniversity } = useMutateUniversity(addBoardUniversityMutation);
  const [editBoardUniversityData, setBoardUniversityData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  let { setAlertDetails } = useAlertSystem();
  const { mutate: updateBoardUniversity } = useMutateUniversity(updateBoardUniversityMutation);
  const [searching, setSearchingBoard] = useState(searchValue);

  const {
    query: getBoardUniversity,
    data: boardUniversityData,
    loading,
    refetch: refetchBoardUniversity
  } = useQueryUniversity(
    getAllBoadUniversitiesQuery,
    searching?.length > 0 ? 'network-only' : 'cache-first',
  );

  row = boardUniversityData?.universities?.data;
  // const totalCount = boardUniversityData?.universities?.totalCount;
  const [totalCount, setTotalCount] = useState(boardUniversityData?.universities?.totalCount ?? null);

  const { deleteBoardUniversity } = useDeleteBoardUniversity(
    currentPage,
    searchValue,
    rowLimit,
    editBoardUniversityData?.id,
  );

  useEffect(() => {
    const count = boardUniversityData?.universities?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [boardUniversityData?.universities?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    setSearchingBoard(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  function handleFetch() {
    let filters = getUniversityFilter(searchValue);
    let universityFilter = createSearchFilter(rowLimit, (parseInt(currentPage) - 1) * rowLimit, filters);
    getBoardUniversity({ variables: universityFilter });
  }

  function handleRefetch() {
    let filters = getUniversityFilter(searchValue);
    let universityFilter = createSearchFilter(rowLimit, (parseInt(currentPage) - 1) * rowLimit, filters);
    refetchBoardUniversity({ variables: universityFilter });
  }

  function setTitle() {
    setSearchingBoard(searchValue);
    setHeading([
      {
        text: t('boardUniversities.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    if (isEditModal && modalState) {
      setFormField(editBoardUniversityData?.label, editBoardUniversityData?.alias);
    }
  }, [modalState]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
  );

  const editBoardUniversityModal = useCallback(
    (selectedBoardUniversity) => {
      setBoardUniversityData(selectedBoardUniversity);
      hideAlert();
      setIsEditModal(true);
      handleModal(true);
    },
    [modalState],
  );

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  const addBoardUniversityModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  const deleteBoardUniversityModal = (selectedBoardUniversity: any) => {
    setBoardUniversityData(selectedBoardUniversity);
    setDeleteOverlay(true);
  };

  const closeModal = () => {
    handleModal(false);
    setCanClick(true);
    if (!isEditModal) resetForm();
    resetForm();
  };

  function resetForm() {
    setValue('university_name', '');
    setValue('university_alias', '');
    clearErrors();
  }

  function setFormField(name: string, alias: string) {
    setValue('university_name', name, { shouldValidate: true });
    setValue('university_alias', alias, { shouldValidate: true });
  }

  interface boardUniverSity {
    university_name: string;
    university_alias: string;
  }

  const handleAddBoardUniversity = async (formdata: boardUniverSity) => {
    setCanClick(false);
    let boardUniverSityObject = {
      name: formdata.university_name,
      alias: formdata.university_alias,
    };

    try {
      let ErrorOccure = false;
      let addboardUniversityData = await addBoardUniversity({
        variables: { payload: boardUniverSityObject },

        update: (cache, { data: { createUniversity } }) => {
          let filters = getUniversityFilter(searchValue);
          //read
          let queryVariable = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);
          let readQueryData = readCacheQuery(cache, getBoardUniversitiesQuery, queryVariable);
          let existingUniversities = readQueryData.universities;
          if (!existingUniversities.data) {
            ErrorOccure = true;
          }

          //write
          let mergeArray = [createUniversity, ...existingUniversities.data];
          let finaldata = {
            data: mergeArray,
            hasNextPage: readQueryData?.universities?.hasNextPage,
            totalCount: readQueryData?.universities?.totalCount + 1,
            __typename: readQueryData?.universities?.__typename,
          };

          let writeQueryData = writeCacheQuery(cache, getBoardUniversitiesQuery, queryVariable, {
            universities: finaldata,
          });

          if (!writeQueryData.__ref) {
            ErrorOccure = true;
          }
        },
      });

      if (addboardUniversityData.data && !ErrorOccure) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.BOARD_UNIVERSITY, level: SUCCESS });
        handleRefetch();
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateBoardUniversity = async (editdata: any) => {
    setCanClick(false);
    let boardUniversityObject = {
      id: editBoardUniversityData?.id,
      name: editdata?.university_name,
      alias: editdata?.university_alias,
    };

    try {
      const updateBoardUniversityData = await updateBoardUniversity({
        variables: { payload: boardUniversityObject },
      });

      if (updateBoardUniversityData.data.updateUniversity) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.BOARD_UNIVERSITY, level: SUCCESS });
        handleRefetch();
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleDeleteBoardUniversity = async () => {
    setCanClick(false);
    try {
      let deleteUniversity = await deleteBoardUniversity({
        variables: { id: editBoardUniversityData?.id },
      });
      if (deleteUniversity?.data?.deleteUniversity?.status === 'success') {
        setDeleteOverlay(false);
        setAlertDetails({ message: DELETE_SUCCESS.BOARD_UNIVERSITY, level: SUCCESS });
        handleRefetch();
      } else {
        throw new Error(deleteUniversity?.data?.deleteUniversity?.message || t('error.deleteUniversity.message'));
      }
    } catch (error) {
      setAlertDetails({ message: error.message, level: ERROR });
      setDeleteOverlay(false);
    }
    setCanClick(true);
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.UNIVERSITY]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchBoardUniversity(board: string) {
    let isClear = board?.length === 0;
    persistSearch(!isClear, board);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchBoardUniversity(q), 500),
    [],
  );

  const handleSearch = (board: string) => {
    setSearchingBoard(board);
    delayedQuery(board);
  };

  return (
    <>
      <LoadContentWrapper>
        <FlexRowContainer>
          <LeftPart>
            <Search
              id="boardSearch"
              handleChange={handleSearch}
              value={searching}
              label={t('searchBoardUniversity.text')}
            />
          </LeftPart>

          <RightPart>
            <SecondaryBtn label={t('addBoardUniversity.text')} onPress={addBoardUniversityModal} />
          </RightPart>
        </FlexRowContainer>
        <TblContainer height={'100%'}>
          <TblHead />
          <>
            {!loading ? (
              <>
                {recordsAfterPagingAndSorting()?.length > 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                      <TableRows key={i}>
                        <TableCells value={item?.label} /> {/*name*/}
                        <TableCells value={item?.alias} /> {/*name*/}
                        <TableActions align="right">
                          <TableActionView>
                            <EditIconView>
                              <Tooltip title="Edit">
                                <TouchableOpacity onPress={() => editBoardUniversityModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    color={theme?.table?.editIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            </EditIconView>
                            <View>
                              <Tooltip title="Delete">
                                <TouchableOpacity onPress={() => deleteBoardUniversityModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    color={theme?.table?.deleteIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            </View>
                          </TableActionView>
                        </TableActions>
                      </TableRows>
                    ))}
                  </TableBody>
                ) : (
                  <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
                )}
              </>
            ) : (
              <SpinnerWrapper>
                <LoaderSpinner />
              </SpinnerWrapper>
            )}
          </>
        </TblContainer>
        <Pagination
          pathName={'academics/boards-universities'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          hidePagination={totalCount <= rowLimit}
        />
      </LoadContentWrapper>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={() => setDeleteOverlay(false)}
        modalVisible={deleteOverlay}
        handleSave={handleDeleteBoardUniversity}
        Headerpopup="deleteBoardUniversity.text">
        {t('deleteBoardUniversity.warning')}
      </DeleteModal>

      <NormalModal
        isSubmitting={!canClick}
        onAlert={null}
        setModalVisible={closeModal}
        handleSave={handleSubmit(
          isEditModal ? handleUpdateBoardUniversity : handleAddBoardUniversity,
        )}
        Headerpopup={isEditModal ? t('editBoardUniversity.text') : t('addBoardUniversity.text')}
        modalVisible={modalState}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}>
        <View>
          <View style={{ marginBottom: 24 }}>
            <Controller
              control={control}
              name={'university_name'}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <Input
                  id={'university_name'}
                  onBlur={onBlur}
                  setValue={onChange}
                  inputRef={ref}
                  value={value}
                  header="name.required.label"
                  notefooter={!errors.university_name ? 'form.boardUniversityName.example' : ''}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: validation.NAME_REQUIRED,
                },
              }}
            />

            <MarginTop4View>
              {errors.university_name ? (
                <SmallTextAtom
                  value={t(errors.university_name.message)}
                  color={colors.errorColor}
                />
              ) : null}
            </MarginTop4View>
          </View>

          <View style={{ marginBottom: 24 }}>
            <Controller
              control={control}
              name={'university_alias'}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <Input
                  id={'university_alias'}
                  onBlur={onBlur}
                  inputRef={ref}
                  value={value}
                  header="alias.required.label"
                  notefooter={!errors.university_alias ? 'form.boardUniversityAliasexample' : ''}
                  setValue={onChange}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: validation.ALIAS_REQUIRED,
                },
              }}
            />
            <MarginTop4View>
              {errors.university_alias ? (
                <SmallTextAtom
                  value={t(errors.university_alias.message)}
                  color={colors.errorColor}
                />
              ) : null}
            </MarginTop4View>
          </View>
        </View>
      </NormalModal>
    </>
  );
}

const FlexRowContainer = styled.View`
  flex-direction: row;
  margin-top: 8px;
`;

const LeftPart = styled.View`
  flex: 3;
  align-items: flex-start;
`;

const RightPart = styled.View`
  flex: 3;
  align-items: flex-end;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const MarginTop4View = styled.View`
  margin-top: 4px;
`;

const EditIconView = styled.View`
  margin-right: 13px;
`;

const SpinnerView = styled.View`
  height: 520px;
  justify-content: center;
  align-items: center;
`;

const TableContainer = styled.View`
  margin-top: -4px;
`;

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
