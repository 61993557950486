import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props:any) {
  return (
    <Svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M16 9.5797V13.3278C16 13.7747 15.6656 14.1114 15.25 14.1114C14.8344 14.1114 14.5 13.7731 14.5 13.3844V9.63635C14.5 7.83186 13.0419 6.39179 11.25 6.39179H2.76594L6.74094 9.8472C7.05438 10.1196 7.08844 10.5965 6.81809 10.9121C6.66875 11.0022 6.45937 11.0903 6.25 11.0903C6.07619 11.0903 5.90125 11.0302 5.75969 10.9063L0.259688 6.15113C0.0946563 5.97953 0 5.7435 0 5.55154C0 5.33124 0.0946563 5.12354 0.259688 4.98193L5.75969 0.226798C6.04406 -0.0475581 6.54594 -0.0115878 6.8175 0.305064C7.088 0.620709 7.05381 1.0978 6.74034 1.37001L2.76594 4.79625H11.25C13.8687 4.79625 16 6.94251 16 9.5797Z" fill="black" fill-opacity="0.6"/>
    </Svg>
  )
}

export default SvgComponent