import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { colors, fontWeight } from 'src/styles/theme/styles';

import { AUTOCOMPLETE_MODULE } from 'src/constant';
import Chip from 'src/components/atoms/Chip';
import CourseSelectionJSON from 'src/form-json/institutes/CourseSelectionJSON.json'
import Element from '../Forms/ApplicationElement.web';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { getAllBoadUniversitiesQuery } from 'src/graphql/academics/board-universities';
import { getFilteredInstituteCourses } from 'src/components/services';
import { useI18n } from 'src/i18n/hooks';
import { useQuery } from '@apollo/client';

interface SelectInstituteCoursesProp {
  control: any;
  errors: any;
  reset: any;
  setValue: any;
  getValues: any;
  isEditModal: boolean;
  isTypeGroup: boolean;
  selectedCourses: Array<Object>;
  setSelectedCourses: Function;
}

function SelectInstituteCourses(props: SelectInstituteCoursesProp) {
  const {
    control,
    errors,
    reset,
    isEditModal,
    selectedCourses,
    setSelectedCourses,
    getValues
  } = props;

  const { t } = useI18n();

  const [elements, setElements] = useState({});
  const [selectedBoardUni, setSelectedBoardUni] = useState({});
  const [allCourses, setAllCourses] = useState([]);
  const [chips, setChips] = useState([]);
  const [courses, setCourses] = useState([]);
  const { fields }: any = elements ?? {};
  const [searchData, setSearchData] = useState('');
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);

  let allBoadUniversities = useQuery(getAllBoadUniversitiesQuery, {
    variables: { limit: 0, skip: 0 },
  });

  useEffect(() => {
    if (isEditModal && selectedCourses?.length > 0) {
      reset({
        university: {
          label: selectedCourses[0]?.university?.name,
          value: selectedCourses[0]?.university?.id
        }
      });
      getCourses(selectedCourses[0]?.university?.id);
      setSelectedBoardUni(selectedCourses[0]?.university);
      const courses = selectedCourses.map((item: any) => {
        return {
          audience: `${item?.university?.id}/${item?.id}`,
          text: item?.university?.name
        }
      });
      setSelectedCourses(courses);
    } else {
      reset({});
    }
  }, [isEditModal]);

  useEffect(() => {
    if (allBoadUniversities.loading == false) {
      loadBoardUniversities(allBoadUniversities.data.universities.data);
    }
  }, [allBoadUniversities.data]);

  function loadBoardUniversities(boardUniversityArray: any) {
    CourseSelectionJSON[0].fields[1].option = boardUniversityArray;
    setElements(CourseSelectionJSON[0]);
  }

  async function getCourses(board: string) {
    let param = {
      filters: { university: { eq: board } },
      limit: 0
    };

    let courseRespose = await getFilteredInstituteCourses(param);
    setAllCourses(courseRespose?.options);
    setIsDataAvailable(true)
  }

  async function handleCustomSelect(id: string, data: any) {
    setIsDataAvailable(false)
    setSelectedBoardUni({});
    setAllCourses([]);
    setCourses([]);
    if (data) {
      await getCourses(data?.id);
      setSelectedBoardUni(data);
    }
  }

  useEffect(() => {
    handleCreateChips();
  }, [selectedCourses]);

  useEffect(() => {
    loadSelectedCourses();
  }, [selectedCourses, selectedBoardUni]);

  function loadSelectedCourses() {
    if (selectedCourses && selectedCourses.length > 0) {
      let courses = selectedCourses.filter((item: any) => {
        return selectedBoardUni?.id === item?.audience?.substring(0, item?.audience?.indexOf('/'));
      });
      courses = courses.map((item: any) => item?.audience?.split("/").pop());
      setCourses(courses);
    } else {
      setCourses([]);
    }
  }

  function handleCreateChips() {
    if (selectedCourses && selectedCourses?.length > 0 && selectedCourses[0]?.audience) {
      const audienceMap = new Map();
      let chipsData = [] as any;
      selectedCourses.forEach((item: any) => {
        const audience = item?.audience?.substring(0, item?.audience?.indexOf('/'));
        const audienceCount = audienceMap.get(audience);
        if (audienceCount && audienceCount.count > 0) {
          audienceMap.set(audience, {
            text: item?.text,
            count: audienceCount.count + 1,
          });
        } else {
          audienceMap.set(audience, {
            text: item?.text,
            count: 1,
          });
        }
      });

      audienceMap.forEach((value) => {
        chipsData.push(value);
      });
      setChips(chipsData);
    } else {
      setChips([])
    }
  }

  const handleChecked = (item: any) => {
    const updated = [...selectedCourses];
    const audience = `${selectedBoardUni?.id}/${item}`;
    const index = updated.findIndex(item => item.audience === audience);
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push({
        audience,
        text: selectedBoardUni?.label ? selectedBoardUni?.label : selectedBoardUni?.name,
      });
    }
    setSelectedCourses(updated);
  }

  async function searchCourseOnchange(queryVariable: any) {
    setSearchData(queryVariable);
    let board = getValues(AUTOCOMPLETE_MODULE.UNIVERSITY);
    if (queryVariable.length > 0 && board?.value && allCourses.length > 0) {
      let param = {
        limit: 0,
        skip: 0,
        filters: { alias: { regex: queryVariable }, university: { eq: board?.value } },
      };

      let courseResponse = await getFilteredInstituteCourses(param);
      setAllCourses(courseResponse?.options);
    } else {
      getCourses(selectedBoardUni?.id);
    }
  }

  return (
    <View>
      <View style={[styles.container]}>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View style={{ width: '100%' }} key={`ele${i}`}>
              {field?.id !== 'header' ? (
                <Element
                  key={`s${i}`}
                  field={field}
                  control={control}
                  errors={errors}
                  handleCustomSelect={handleCustomSelect}
                  checkboxTableData={allCourses}
                  checked={courses}
                  handleCheck={handleChecked}
                  manualSelection={false}
                  checkboxTableHeight={'auto'}
                  checkboxTableMaxHeight={340}
                  searchBoxOnChange={searchCourseOnchange}
                  searchData={searchData}
                  isDataAvailable={isDataAvailable}
                />
              ) : (
                <View style={[styles.headView]}>
                  <HeaderFive
                    value={t(field?.head)}
                    fontWeight={fontWeight[600]}
                    color={colors.primaryText}
                  />
                </View>
              )}
            </View>
          ))
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </View>
      <View style={[styles.chipWrapper]}>
        {chips &&
          chips?.map((audience: any, index: any) => (
            <View style={[styles.chipView]} key={`audience${index}`}>
              <Chip
                label={`${audience?.text}: ${audience?.count} Courses`}
                close={false}
                borderRadius={4}
                chipFontSize={1.2}
              />
            </View>
          ))}
      </View>
    </View>
  )
}

export default SelectInstituteCourses;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'space-between',
    borderWidth: 1,
    borderColor: colors.borderColor,
    borderStyle: 'dashed',
    borderRadius: 4,
    padding: 8,
    paddingRight: 16
  },
  headView: { marginBottom: 16 },
  spinnerView: { width: 750, height: 547, justifyContent: 'center', alignItems: 'center' },
  chipWrapper: { flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 },
  chipView: { marginRight: 8, marginBottom: 8 },
});