import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import {
  getFilteredClasses,
  getFilteredCourses,
  getFilteredInstitute,
  getFilteredUniversities,
} from 'src/components/services';
import { useI18n } from 'src/i18n/hooks';
import { criteria } from 'src/form-json/admission/criteria';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import { validatorMapper } from '../Forms/validatorMapper';
import { componentMapper } from '../ddfElements';
import CriteriaTable from '../Table/CriteriaTable';
import ModalSaveAndCancelButtonGroup from '../ModalSaveAndCancelButtonGroup';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import SelectReservationCriteriaForm from './SelectReservationCriteriaForm';
import { useQuery } from '@apollo/client';
import { getReservationsQuery } from 'src/graphql/admission/reservation-group';
import TriggerSelector from 'src/components/atoms/Button/TriggerSelector';
import { colors } from 'src/styles/theme/styles';
import { minAvgMarksInputItemType } from 'src/components/organism/Admission/types';

interface CriteriaData {
  name: string;
  class: {
    course: {
      value: string;
      university: {
        id: string;
        value: string;
      };
    };
  };
  reservationCriteria: {
    id: string;
    name: string;
    reservations: {
      caste: {
        id: string;
        name: string;
      }
    }[];
  };
  institutes?: [
    {
      label: string;
      value: string;
    },
  ];
}

interface Props {
  isEditModal: boolean;
  setQualificationModal: Function;
  qualifications: Array<string>;
  setQualificationData: Function;
  onSubmit: (values: any) => Promise<void>;
  closeModal: () => void;
  isSubmitting: boolean;
  setEditQualificationData: Function;
  setStreams: Function;
  setIsEditQualificationModal: Function;
  editCriteriaData: CriteriaData;
  setDocuments: Function;
  setIsQualificationEdit: Function;
  selectedReservationCriteria: string;
  setSelectedReservationCriteria: Dispatch<SetStateAction<string>>;
  reservationModalState: boolean;
  handleReservationModal: Function;
  reqMarksData: minAvgMarksInputItemType[];
  setReqMarksData: Dispatch<SetStateAction<minAvgMarksInputItemType[]>>;
  handleReqMarksModal: Dispatch<SetStateAction<boolean>>;
}

export default function CriteriaForm(props: Props) {
  const {
    editCriteriaData,
    isEditModal,
    setQualificationModal,
    qualifications,
    setQualificationData,
    onSubmit,
    closeModal,
    isSubmitting,
    setEditQualificationData,
    setStreams,
    setIsEditQualificationModal,
    setDocuments,
    selectedReservationCriteria,
    setSelectedReservationCriteria,
    reservationModalState,
    handleReservationModal,
    reqMarksData,
    setReqMarksData,
    handleReqMarksModal
  } = props;
  const { data: reservationCriteriaData, loading } = useQuery(getReservationsQuery, {
    variables: { limit: 0 },
    fetchPolicy: 'network-only',
  });
  const { t } = useI18n();
  const [universityOptions, setUniversityOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [reservationCriteriaOptions, setReservationCriteriaOptions] = useState([]);
  const [instituteOptions, setInstituteOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [selectedReservationCriteriaLabel, setSelectedReservationCriteriaLabel] =
    useState<string>('');
  const resCriteriaId = useRef<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const formSchema = criteria(
    universityOptions,
    courseOptions,
    classOptions,
    instituteOptions,
    loadInstitutes,
    loadCourse,
    loadClass,
    isEditModal,
    clearInstituteOptions,
    selectReservationCriteriaOnClick,
    selectedReservationCriteriaLabel,
    !selectedReservationCriteriaLabel && reservationCriteriaOptions.length > 0,
    onDelete,
  );

  useEffect(() => {
    if (isEditModal) {
      loadEditData();
    }
    loadInitialData();
  }, []);

  useEffect(() => {
    if (reservationCriteriaData && reservationCriteriaData?.reservations?.data) {
      setReservationCriteriaOptions(reservationCriteriaData?.reservations?.data);
    }
  }, [reservationCriteriaData]);

  async function loadInitialData() {
    let param = {
      limit: 0,
    } as any;

    const boardResponse = await getFilteredUniversities(param);
    setUniversityOptions(boardResponse?.options);
  }

  function createFilter(id: string, filter: string, limit: number, filterType: string) {
    let params = {
      limit: limit,
      filters: {
        [filter]: {
          [filterType]: id,
        },
      },
    };
    return params;
  }

  function loadEditData() {
    const editData = {
      criteriaName: editCriteriaData?.name,
      class: editCriteriaData?.class,
      university: editCriteriaData?.class?.course?.university,
      course: editCriteriaData?.class?.course,
      reservationCriteria: {
        value: editCriteriaData?.reservationCriteria?.id,
        label: editCriteriaData?.reservationCriteria?.name,
      },
    };
    setSelectedReservationCriteria(editCriteriaData?.reservationCriteria?.id);
    resCriteriaId.current = editCriteriaData?.reservationCriteria?.id;
    setSelectedReservationCriteriaLabel(editCriteriaData?.reservationCriteria?.name);
    if (editCriteriaData?.institutes && editCriteriaData?.institutes?.length > 0) {
      editData['institutes'] = editCriteriaData?.institutes;
    }
    loadCourse(editCriteriaData?.class?.course?.university?.value);
    loadClass(editCriteriaData?.class?.course?.value);
    loadInstitutes(editCriteriaData?.class?.course?.value);
    setInitialValues(editData);
  }

  async function loadInstitutes(courseId: string) {
    let param = createFilter(courseId, 'courses', 0, 'contains');
    let instituteRespose = await getFilteredInstitute(param);
    setInstituteOptions(instituteRespose?.options);
  }

  async function loadCourse(universityId: string) {
    let param = createFilter(universityId, 'university', 0, 'eq');
    let courseRespose = await getFilteredCourses(param);
    setCourseOptions(courseRespose?.options);
  }

  async function loadClass(courseId: string) {
    let param = createFilter(courseId, 'course', 0, 'eq');
    let courseRespose = await getFilteredClasses(param);
    setClassOptions(courseRespose?.options);
  }

  function clearInstituteOptions() {
    setInstituteOptions([]);
  }

  function selectReservationCriteriaOnClick() {
    if (selectedReservationCriteriaLabel) {
      resCriteriaId.current = selectedReservationCriteria;
      setIsEdit(true);
    }
    handleReservationModal(true);
  }

  function selectReservationCriteriaOnClose() {
    resCriteriaId.current = '';
    handleReservationModal(false);
    setIsEdit(false);
  }

  function selectReservationCriteriaOnSubmit() {
    if (resCriteriaId.current) {
      const filteredReservationCriteria = reservationCriteriaOptions.find(
        (e) => e.id === resCriteriaId.current,
      );
      if (filteredReservationCriteria)
        setSelectedReservationCriteriaLabel(filteredReservationCriteria?.name);
        setSelectedReservationCriteria(resCriteriaId.current);
    }
    handleReservationModal(false);
    setIsEdit(false);
  }

  function onDelete() {
    setSelectedReservationCriteriaLabel('');
    resCriteriaId.current = '';
    setSelectedReservationCriteria('');
  }

  const CriteriaTemplate = useCallback(
    (props: { formFields: any }) => {
      const { formFields } = props;

      return (
        <>
          <ContainerWrapper>
            <FormWrapper>
              {formFields ? (
                formFields.map((field: any, index: number) => {
                  return <ElementWrapper index={index}>{field}</ElementWrapper>;
                })
              ) : (
                <SpinnerView>
                  <LoaderSpinner />
                </SpinnerView>
              )}
            </FormWrapper>

            {formFields && (
              <>
                <ButtonWrapper bottom={qualifications && qualifications.length === 0}>
                  <TriggerSelector
                    headerLabel={t('qualifications.label')}
                    inputContainerWidth={350}
                    selectedValueLabel={t('addQualifications.text')}
                    displayText={t('addQualifications.text')}
                    textColor={colors.secondaryText}
                    onPress={() => setQualificationModal(true)}
                    showDelete={false}
                    showEdit={false}
                  />
                </ButtonWrapper>
                {qualifications && qualifications.length > 0 && (
                  <TableWrapper>
                    <CriteriaTable
                      tableData={qualifications}
                      setTableData={setQualificationData}
                      setEditQualificationData={setEditQualificationData}
                      setStreams={setStreams}
                      setDocuments={setDocuments}
                      setQualificationModal={setQualificationModal}
                      setIsEditQualificationModal={setIsEditQualificationModal}
                      setReqMarksData={setReqMarksData}
                      reqMarksData={reqMarksData}
                      handleReqMarksModal={handleReqMarksModal}
                      reservationGroupData={
                        isEditModal
                          ? editCriteriaData.reservationCriteria.reservations
                          : reservationCriteriaOptions?.find(
                            (r) => r.id === selectedReservationCriteria
                          )?.reservations
                      }
                    />
                  </TableWrapper>
                )}
              </>
            )}
          </ContainerWrapper>

          <ModalSaveAndCancelButtonGroup
            saveLabel={isEditModal ? 'save.label' : 'add.label'}
            cancelLabel={'cancel.label'}
            onCancel={closeModal}
            isSubmitting={isSubmitting}
          />
        </>
      );
    },
    [formSchema],
  );

  return (
    <>
      {formSchema && (
        <FormRenderer
          componentMapper={componentMapper}
          FormTemplate={CriteriaTemplate}
          schema={formSchema}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validatorMapper={validatorMapper}
        />
      )}

      <NormalModal
        setModalVisible={selectReservationCriteriaOnClose}
        modalVisible={reservationModalState}
        Headerpopup={t('selectReservationGroup.label')}
        width={788}
        height={644}
        maxWidth={'lg'}
        showFooter={false}
        hideCloseIcon={true}
        handleSave={() => null}>
        <SelectReservationCriteriaForm
          onSubmit={selectReservationCriteriaOnSubmit}
          closeModal={selectReservationCriteriaOnClose}
          reservationCriteriaOptions={reservationCriteriaOptions}
          resCriteriaId={resCriteriaId}
          isEdit={isEdit}
        />
      </NormalModal>
    </>
  );
}

const ContainerWrapper = styled.div`
  min-height: 580px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 724px;
`;

const ElementWrapper = styled.div<{ index: number }>`
  width: 350px;
  margin-right: ${({ index }) => (index % 2 === 0 ? 24 : 0)};
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div<{ bottom: boolean }>`
  margin-top: 16px;
  margin-bottom: ${({ bottom }) => (bottom ? 6 : 0)};
`;

const SpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 580px;
  width: 724px;
`;

const TableWrapper = styled.div`
  margin-bottom: 24px;
`;
