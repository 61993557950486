import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const { size = 24 } = props;
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={size * scale}
      height={size * scale}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}  
    >
      <Path
        d="M15.7494 4.66408C16.6344 3.77864 18.0704 3.77864 18.9553 4.66408L19.3363 5.04576C20.2212 5.9313 20.2212 7.36699 19.3363 8.2519L13.1262 14.4652C12.8522 14.736 12.5153 14.9344 12.1437 15.0414L8.99454 15.9421C8.73001 16.0177 8.44659 15.9421 8.25134 15.7217C8.05924 15.5548 7.98366 15.2713 8.05924 15.0068L8.9599 11.8577C9.06697 11.4861 9.26536 11.1491 9.53619 10.8751L15.7494 4.66408ZM17.8594 5.7329C17.5917 5.43783 17.113 5.43783 16.817 5.7329L15.9006 6.64899L17.3524 8.10074L18.2688 7.156C18.5648 6.88832 18.5648 6.40965 18.2688 6.11458L17.8594 5.7329ZM10.4148 12.2734L9.88575 14.1156L11.728 13.5865C11.854 13.5519 11.9642 13.4858 12.0555 13.3944L16.2848 9.1683L14.833 7.71655L10.6069 11.9458C10.5156 12.0372 10.4494 12.1474 10.4148 12.2734ZM10.2983 5.89319C10.7171 5.89319 11.0541 6.23173 11.0541 6.64899C11.0541 7.06782 10.7171 7.40478 10.2983 7.40478H6.77124C6.0756 7.40478 5.51159 7.96848 5.51159 8.66444V17.2301C5.51159 17.9261 6.0756 18.4898 6.77124 18.4898H15.3369C16.0329 18.4898 16.5966 17.9261 16.5966 17.2301V13.7031C16.5966 13.2842 16.9335 12.9473 17.3524 12.9473C17.7712 12.9473 18.1082 13.2842 18.1082 13.7031V17.2301C18.1082 18.7606 16.8674 20.0013 15.3369 20.0013H6.77124C5.24076 20.0013 4 18.7606 4 17.2301V8.66444C4 7.13395 5.24076 5.89319 6.77124 5.89319H10.2983Z"
        fill="white"
      />
    </Svg>
  )
}

export default SvgComponent;