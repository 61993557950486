import { TableBody } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import Paginations from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import AddGradingSystemForm from 'src/components/molecules/Assessment/GradingSystems/AddGradingSystemForm';
import {
  NoRecordsFound,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { createSearchFilter, debounce } from 'src/components/services';
import { getCommonNameSearchFilter } from 'src/components/services/filters';
import { AUTOCOMPLETE_MODULE, ERROR, SUCCESS, limit } from 'src/constant';
import { height } from 'src/constant/device';
import { ADD_SUCCESS } from 'src/constant/message';
import { useFilter } from 'src/contexts/filter-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useCreateGrade, useGetGrades } from 'src/graphql/assessment/assessment-grades';
import { useI18n } from 'src/i18n/hooks';
import { useHistory, useParams } from 'src/routes/routing.web';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components';
import { gradingSystemFormData } from './types';

const headCells1 = [
  {
    id: 'name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'university',
    label: 'university.label',
    align: 'left',
    disableSorting: true,
  },
];

export default function GradingSystems() {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const history = useHistory();
  const { filters, setFilter } = useFilter();
  const { setAlertDetails } = useAlertSystem();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  let { page = 1, dataLimit = limit }: any = useParams();
  let searchValue = '' as string;
  let row;
  let rowLimit = 0;
  rowLimit = parseInt(dataLimit);
  if (filters && filters?.grading_system?.on) {
    searchValue = filters?.grading_system?.search;
  }

  const [headCells, setHeadCells] = useState(headCells1);
  const [searching, setSearchingGradingSystem] = useState(searchValue);
  const [canClick, setCanClick] = useState(true);
  const [gradingSystemModal, setGradingSystemModal] = useState<boolean>(false);

  const { getGrades, gradesData, gradesError, gradesLoading, refetchGrades } = useGetGrades();
  const { createGrade, createGradeData, createGradeError } = useCreateGrade();

  row = gradesData?.grades?.data;
  const totalCount = gradesData?.grades?.totalCount;

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    page,
  );

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    setSearchingGradingSystem(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [page, searchValue]);

  function setTitle() {
    setHeading([
      {
        text: t('gradingSystems.label'),
        url: '',
      },
    ]);
  }

  function handleFetch() {
    const filters = getCommonNameSearchFilter(searchValue.trim());
    let gradingSystemFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    getGrades({ variables: gradingSystemFilter });
  }

  async function createNewGrade(formData: gradingSystemFormData) {
    setCanClick(false);
    let gradeObject = {
      name: formData?.name,
      university: formData?.university?.value,
    };
    try {
      const createdGradeData = await createGrade({
        variables: { payload: gradeObject },
      });
      if (createdGradeData?.data) {
        setAlertDetails({ message: ADD_SUCCESS.GRADE, level: SUCCESS });
        closeModal();
        if (refetchGrades) refetchGrades();
      }
      setCanClick(true);
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  function closeModal() {
    setGradingSystemModal(false);
    reset({});
  }

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.GRADING_SYSTEM]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchGradingSystem(gradingSystem: string) {
    let isClear = gradingSystem?.length === 0;
    persistSearch(!isClear, gradingSystem);
  }

  const delayedQuery = useCallback(
    debounce(q => searchGradingSystem(q), 500),
    [],
  );

  const handleSearch = (gradingSystem: string) => {
    setSearchingGradingSystem(gradingSystem);
    delayedQuery(gradingSystem);
  };

  return (
    <LoadContentWrapper>
      <FlexRowView>
        <SearchWrapper>
          <Search
            id="gradingSystemSearch"
            handleChange={handleSearch}
            value={searching}
            label={t('searchGradingSystems.label')}
          />
        </SearchWrapper>

        <AddGradingSystemWrapper>
          <SecondaryBtn
            label={t('addGradingSystem.label')}
            onPress={() => setGradingSystemModal(true)}
          />
        </AddGradingSystemWrapper>
      </FlexRowView>

      <TblContainer height={'100%'}>
        <TblHead />
        <>
          {!gradesLoading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                    <TableRows key={i}>
                      <TableCells
                        value={item?.name || 'N/A'}
                        color={colors.primaryColor}
                        clickable={true}
                        onPress={() =>
                          history.push(`/assessment/grades/${item?.id}`, {
                            name: item?.name,
                          })
                        }
                      />
                      <TableCells value={item?.university?.name || 'N/A'} />
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>
      <Paginations
        pathName={'assessment/grading-systems'}
        count={Math.ceil(totalCount / rowLimit)}
        total={totalCount}
        page={page}
        rowLimit={rowLimit}
        hidePagination={totalCount <= rowLimit}
      />

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        modalVisible={gradingSystemModal}
        Headerpopup={t('addGradingSystem.label')}
        handleSave={handleSubmit(createNewGrade)}
        height={202}>
        <AddGradingSystemForm control={control} errors={errors} />
      </NormalModal>
    </LoadContentWrapper>
  );
}

const FlexRowView = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 10;
`;

const SearchWrapper = styled.div`
  align-items: flex-start;
  max-width: 280px;
  margin-right: 8px;
`;

const AddGradingSystemWrapper = styled.div`
  align-items: flex-end;
  min-width: 112px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
