import { isEqual, sortBy } from "lodash";

import { getWeekDays } from "src/components/organism/Schedule/scheduleHelpers";

function verifyArrayEquality(base: string[], comparee: string[]): boolean {
  return isEqual(sortBy(base), sortBy(comparee));
}

export function matchPreset(selectedDays: string[]) {
  let match = { label: "Multiple Days" }; // Default Case
  for (const preset of DaySelectDropdownPRESETS) {
    if (
      verifyArrayEquality(
        preset.days.map((day) => day.value),
        selectedDays
      )
    ) {
      match = preset;
      break;
    }
  }
  return match;
}

export const weekDays = getWeekDays();

export const DaySelectDropdownPRESETS = [
  {
    value: "mon-fri",
    label: "Monday to Friday",
    days: weekDays.slice(0, -2),
  },
  {
    value: "mon-sat",
    label: "Monday to Saturday",
    days: weekDays.slice(0, -1),
  },
  {
    value: "everyday",
    label: "Everyday",
    days: weekDays,
  },
];

export type DaySelectDropdownProps = {
  onChangeCallback: (days: string[]) => void;
  title?: string;
  containerWidth?: string;
  containerHeight?: string;
  initialValues?: string[];
  disabled?: boolean;
};
