import React from 'react';
import Svg, { Path } from "react-native-svg";
import { colors } from 'src/styles/theme/styles';

interface Props {
    size?: number;
    color?: string;
}
export default function SvgComponent(props: Props) {
    return (
        <Svg
            width="80"
            height="49"
            viewBox="0 0 80 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M76.52 11.9151L43.395 1.20531C41.1688 0.486576 38.8325 0.486576 36.6063 1.20531L3.48125 11.9151C1.3675 12.5988 0 14.4638 0 16.6662C0 18.8687 1.3675 20.7336 3.48 21.4173L7.19375 22.6173C6.78125 23.3898 6.47625 24.221 6.2775 25.0923C4.94125 25.7397 4 27.081 4 28.6659C4 30.2571 4.94625 31.6059 6.29125 32.2496L4.035 46.2318C3.83375 47.4805 4.52375 48.6655 5.4525 48.6655H10.5463C11.475 48.6655 12.1662 47.4805 11.9637 46.2318L9.70875 32.2496C11.0537 31.6059 12 30.2571 12 28.6659C12 27.341 11.3137 26.2235 10.3212 25.4948C10.5075 24.9123 10.7962 24.3873 11.1425 23.8935L17.8263 26.0547L16 40.6657C16 45.0843 26.745 48.6655 40 48.6655C53.255 48.6655 64 45.0843 64 40.6657L62.1738 26.0547L76.52 21.4161C78.6325 20.7336 80 18.8687 80 16.6662C80 14.4638 78.6325 12.5988 76.52 11.9151ZM59.935 40.3982C58.59 41.8144 51.755 44.6656 40 44.6656C28.245 44.6656 21.41 41.8144 20.065 40.3982L21.7013 27.3072L36.6063 32.1259C36.9313 32.2308 39.8213 33.2796 43.395 32.1259L58.3 27.3072L59.935 40.3982ZM75.285 17.6137L42.16 28.3234C40.7462 28.7809 39.2538 28.7809 37.84 28.3234L15.8813 21.2236L40.3675 16.6312C41.4538 16.4287 42.1688 15.3837 41.965 14.2975C41.7625 13.21 40.695 12.4988 39.6325 12.7013L14.16 17.4762C12.61 17.7662 11.2212 18.4324 10.025 19.3299L4.71375 17.6124C3.7325 17.2937 3.78125 16.02 4.71375 15.7187L37.8387 5.00897C39.7262 4.39899 41.3187 4.73773 42.1587 5.00897L75.2838 15.7187C76.2075 16.0175 76.2725 17.2937 75.285 17.6137V17.6137Z"
                fill={colors.secondaryText}
                fill-opacity="0.6"
            />
        </Svg>
    )
}
