import { ApplicationStatus } from 'src/constant';
import { colors } from '../../../styles/theme/styles';

export function getApplicationStatusColor(status: string | ApplicationStatus) {
  switch (status) {
    case ApplicationStatus.PAYMENT_AWAITED:
    case ApplicationStatus.SUBMITTED:
    case ApplicationStatus.TRANSFERED:
      return colors.blue;
    case ApplicationStatus.PAYMENT_COMPLETED:
    case ApplicationStatus.VERIFIED:
    case ApplicationStatus.ADMISSION_COMPLETED:
    case ApplicationStatus.AUTO_VERIFIED:
    case ApplicationStatus.PARTIAL_PAYMENT_COMPLETED:
    case ApplicationStatus.CANCELLATION_CHARGES_PAID:
      return colors.green;
    case ApplicationStatus.PAYMENT_POSTPONED:
    case ApplicationStatus.INCOMPLETE:
    case ApplicationStatus.APPLICATION_FEE_PENDING:
      return colors.yellow;
    case ApplicationStatus.BLOCKED:
    case ApplicationStatus.REJECTED:
    case ApplicationStatus.CANCELLED:
    case ApplicationStatus.PRE_ADMISSION_CANCELLATION:
    case ApplicationStatus.POST_ADMISSION_CANCELLATION:
    case ApplicationStatus.ADMISSION_CANCELLATION:
      return colors.red;
    default:
      return colors.primaryText;
  }
};
