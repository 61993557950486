import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import MediumText from 'src/components/atoms/Text/MediumText';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import NotificationWrapper, { showNativeError } from 'src/components/molecules/NotificationWrapper';
import { useCreateStudentPayment } from 'src/graphql/payment';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import LottieView from 'lottie-react-native';
import ERROR_MSG from 'src/constant/error';
import MobileHeader from 'src/components/hoc/MobileHeader';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { height } from 'src/constant/device';
import navigationStrings from 'src/navigation/navigationStrings';
import { getPaymentModeLabel } from 'src/components/molecules/Payment/Institute/utility';
import { PAYMENT_MODE_LABEL } from 'src/constant';

const AcceptPayment = (props: any) => {
    const { t } = useI18n();
    const [userDetails, setUserDetails] = useState({});
    const [completed, setCompleted] = useState(false);
    const [canClick, setCanClick] = useState(true);
    const [partialPayment, setPartialPayment] = useState<number>(0);
  const ref = useRef<LottieView>(null);

    const { result, feeBookData } = props.route.params;
    const { createStudentPayment, createStudentPaymentError } = useCreateStudentPayment();

    useEffect(() => {
      if (ref.current) {
        ref.current?.play();
      }
    }, [ref.current]);

    useEffect(() => {
      if (feeBookData && result) {
        const response = feeBookData?.getStudentFeeBook;
        setUserDetails({
          name: response?.firstName + ' ' + response?.lastName,
          rollNo: response?.rollNo,
          batch: response?.batchName,
          division: response?.division
        });
        if (response?.fees?.installmentPayable) {
          setPartialPayment(response.fees.installmentPayable);
        }
      }
    }, [feeBookData]);

    function clearData() {
      setUserDetails({});
      setPartialPayment(0);
    }

    const makePayment = async () => {
      setCanClick(false);
      try {
        const paymentDetail = result?.paymentDetail;
        const userDetails = result?.userDetails;
        const paymentPayload = {
          studentId: userDetails?.userId,
          paymentDetail: {
            mode: paymentDetail?.mode,
          }
        }
        if (paymentDetail?.payId) {
          paymentPayload.paymentDetail = paymentDetail;
        }
        const createPaymentRes = await createStudentPayment({
          variables: paymentPayload,
        });
        if (!createPaymentRes.hasOwnProperty('errors')) {
          if (createPaymentRes?.data?.createStudentPayment) {
            clearData();
            setCompleted(true);
          } else {
            showNativeError('Payment already done for this module');
          }
        } else {
          showNativeError(ERROR_MSG.GENERIC_ERROR);
        }
        setCanClick(true);
      } catch (e: any) {
        showNativeError(e.message);
        setCanClick(true);
      }
    }

    const GroupComponent = (props: any) => {
      const { groupPaymentsItem } = props;
      return (
        <CommonStyleRowAndSpaceBetween
          style={{
            marginBottom: 36,
          }}>
          <ModuleNameWrapper>
            <NormaltextAtom lines={1} lineHeight={2} value={groupPaymentsItem.name} fontSize={1.6} />
          </ModuleNameWrapper>
          <NormaltextAtom
            lineHeight={2}
            value={` \u20B9  ${groupPaymentsItem.amount}`}
            fontSize={1.6}
          />
        </CommonStyleRowAndSpaceBetween>
      );
    };

    const ConcessionComponent = (props: any) => {
      const { concessionArr } = props;
      return concessionArr?.map((concessionInfo: any) => (
        <CommonStyleRowAndSpaceBetween
          style={{
            marginBottom: 36,
          }}>
          <ModuleNameWrapper>
            <NormaltextAtom lines={4} lineHeight={2} value={concessionInfo.name} fontSize={1.6} style={{ width: 200 }} />
          </ModuleNameWrapper>
          <NormaltextAtom
            lineHeight={2}
            value={`-  \u20B9  ${concessionInfo.amount}`}
            fontSize={1.6}
          />
        </CommonStyleRowAndSpaceBetween>
      ));
    };

    function handlePaymentSuccess() {
      setCompleted(false);
      props.navigation.navigate(navigationStrings.NATIVE_TABBAR_ADMIN);
    }

    return (
      <NotificationWrapper>
        <React.Fragment>
            <MobileHeader
              label={t('Payment Info')}
              backIcon={!completed}
              style={{ textAlign: 'center' }}
              {...props}
            />
            <ContainerView
              nestedScrollEnabled={true}
              contentContainerStyle={{flexGrow: 1}}>
                {!completed ? (result &&
                  feeBookData?.getStudentFeeBook?.feeBook && Object.keys(userDetails)?.length > 0 ? (
                    <View style={{ marginTop: 24, flex: 1 }}>
                      <MediumTextSelect value={'Student Details'} color={colors.primaryText} fontFamily={fonts.semibold} />
                      <InformationView>
                        <RowComponentView>
                          <ChildComponentView>
                            <SmallTextAtom value={'Name'} />
                            <NormaltextAtom value={userDetails?.name || '-'} color={colors.primaryText} />
                          </ChildComponentView>
                          <ChildComponentView>
                            <SmallTextAtom value={'Batch'} />
                            <NormaltextAtom value={userDetails?.batch?.toUpperCase() || '-'} color={colors.primaryText} />
                          </ChildComponentView>
                        </RowComponentView>

                        <SecondRowComponentView>
                          <ChildComponentView>
                            <SmallTextAtom value={'Division'} />
                            <NormaltextAtom value={userDetails?.division?.toUpperCase() || '-'} color={colors.primaryText} />
                          </ChildComponentView>
                          <ChildComponentView>
                            <SmallTextAtom value={'Roll No'} />
                            <NormaltextAtom value={userDetails?.rollNo || '-'} color={colors.primaryText} />
                          </ChildComponentView>
                        </SecondRowComponentView>

                        <SecondRowComponentView>
                          <ChildComponentView>
                            <SmallTextAtom value={'Payment Mode'} />
                            <NormaltextAtom
                              value={PAYMENT_MODE_LABEL[result?.paymentDetail?.mode]}
                              color={colors.primaryText}
                            />
                          </ChildComponentView>
                          <ChildComponentView>
                            <SmallTextAtom value={getPaymentModeLabel(result?.paymentDetail?.mode)} />
                            <NormaltextAtom value={result?.paymentDetail?.payId ? result?.paymentDetail?.payId : ''} color={colors.primaryText} />
                          </ChildComponentView>
                        </SecondRowComponentView>
                      </InformationView>
                      <MediumTextSelect
                        value={'Payment Details'}
                        style={{ marginTop: 24 }}
                        color={colors.primaryText}
                        fontFamily={fonts.semibold}  
                      />
                      <TableHeaderView>
                        <NormaltextAtom value={'Particulars'} />
                        <NormaltextAtom value={'Amount'} />
                      </TableHeaderView>
                      <BorderLineWrapper
                        style={{
                          paddingLeft: 36,
                          paddingBottom: 10,
                        }}
                      />
                      <ScrollView style={{ maxHeight: 250, paddingVertical: 13 }} nestedScrollEnabled={true}>
                        {feeBookData?.getStudentFeeBook?.feeBook?.map((item: any, index: number) => (
                          <View>
                            <ItemHeaderWrapper>
                              <CommonStyleRowAndSpaceBetween
                                style={{
                                  flex: 1,
                                  paddingBottom: 3,
                                }}>
                                <ModuleNameWrapper>
                                  <MediumText
                                    lines={1}
                                    value={item?.groupName}
                                    lineHeight={2.4}
                                    color={colors.primaryText}
                                  />
                                </ModuleNameWrapper>
                                <MediumTextSelect
                                  fontFamily={fonts.semibold}
                                  value={`\u20B9  ${item?.payable}`}
                                  color={colors.primaryText}
                                />
                              </CommonStyleRowAndSpaceBetween>
                            </ItemHeaderWrapper>
                            {item?.groupName !== item?.fees[0]?.name ? (
                              <ChildrenItemHeaderWrapper>
                                {item?.fees?.map((ele: any) => (
                                  <>
                                    <GroupComponent groupPaymentsItem={ele} />
                                    {ele?.concessions && ele.concessions.length > 0 && (
                                      <ConcessionComponent concessionArr={ele.concessions} />
                                    )}
                                  </>
                                ))}
                              </ChildrenItemHeaderWrapper>
                            ) : (
                                item?.fees[0]?.concessions && item.fees[0].concessions.length > 0 && (
                                  <ChildrenItemHeaderWrapper>
                                    <ConcessionComponent concessionArr={item?.fees[0]?.concessions} />
                                  </ChildrenItemHeaderWrapper>
                                )
                            )}
                          </View>                        
                        ))}
                      </ScrollView>

                      <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={true}>
                        <MediumTextSelect value={t('totalPayment.label')} />
                        <MediumTextSelect value={`\u20B9 ${feeBookData?.getStudentFeeBook?.totalPayable}`} />
                      </TotalPaymentAndSelectedPayableAmountWrapper>

                      <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={true}>
                        <MediumTextSelect value={t('totalPaid.label')} />
                        <MediumTextSelect value={`\u20B9 ${feeBookData?.getStudentFeeBook?.totalPaid}`} />
                      </TotalPaymentAndSelectedPayableAmountWrapper>

                      <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={Boolean(partialPayment)}>
                        <MediumTextSelect
                          fontFamily={Boolean(partialPayment) ? fonts.regular : fonts.semibold}
                          value={t('totalPending.label')}
                          color={Boolean(partialPayment) ? colors.secondaryText : colors.primaryText} />
                        <MediumTextSelect
                          fontFamily={Boolean(partialPayment) ? fonts.regular : fonts.semibold}
                          value={`\u20B9 ${feeBookData?.getStudentFeeBook?.totalPending}`}
                          color={Boolean(partialPayment) ? colors.secondaryText : colors.primaryText} />
                      </TotalPaymentAndSelectedPayableAmountWrapper>

                      {Boolean(partialPayment) && (
                        <TotalPaymentAndSelectedPayableAmountWrapper displayBorder={false}>
                          <MediumTextSelect
                            fontFamily={fonts.semibold}
                            value={t('partialPayableAmount.label')}
                            color={colors.primaryText}
                          />
                          <MediumTextSelect
                            fontFamily={fonts.semibold}
                            value={`\u20B9 ${partialPayment}`}
                            color={colors.primaryText}
                          />
                        </TotalPaymentAndSelectedPayableAmountWrapper>
                      )}

                      <View style={{ height: 100 }}>
                        <SecondaryBtn
                          onPress={makePayment}
                          label={'Proceed'}
                          canLoad={!canClick}
                        />
                      </View>
                  </View>
                  ) : (
                    <SpinnerView>
                      <LoaderSpinner />
                    </SpinnerView>
                  )) : (
                    <LoadingWrapper>
              <LottieWrapper>
                    <LottieView
                      ref={ref}
                      source={require('src/assets/lottie/tickmark.json')}
                      autoPlay={false}
                      loop={false}
                      style={{ height: 100 }} />
              </LottieWrapper>
                    <TextWrapper>
                      <MediumTextSelect
                        value={t('paymentSuccessfull.label')}
                        color={colors.primaryText}
                        fontFamily={fonts.semibold}  
                      />
                    </TextWrapper>
                    <ButtonWrapper>
                      <SecondaryBtn onPress={handlePaymentSuccess} label={t('done.label')} />
                    </ButtonWrapper>
                  </LoadingWrapper>
                  )
                }
            </ContainerView>
        </React.Fragment>
      </NotificationWrapper>
    )
}

const ContainerView = styled.ScrollView`
  background-color: white;
  height: 100%;
  padding-horizontal: 24px;
`;

const InformationView = styled.View`
  margin-top: 16px;
`;

const ItemHeaderWrapper = styled.View`
  margin-bottom: 24px;
`;

const CommonStyleRowAndSpaceBetween = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const ChildrenItemHeaderWrapper = styled.View`
  padding-left: 25px;
`;

const BorderLineWrapper = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const TotalPaymentAndSelectedPayableAmountWrapper = styled.View<{ displayBorder: boolean }>`
  border-top-width: 1px;
  border-top-color: ${colors.dividerColor};
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 0px;
  border-bottom-width: ${(props) => props?.displayBorder ? 1 :0}px;
  border-bottom-color: ${colors.dividerColor};
`;

const LoadingWrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  margin-top: -24px;
`;

const SpinnerView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  height: ${height - 300}px;
`;

const RowComponentView = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const SecondRowComponentView = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
`;

const ChildComponentView = styled.View`
  flex: 1;
`;

const TableHeaderView = styled.View`
  margin-top: 17px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrapper = styled.View`
  position: absolute;
  bottom: 24px;
  width: 100%;
`;

const ModuleNameWrapper = styled.View`
  display: flex;
  align-items: flex-start;
  width: 60%;
  padding-right: 8px;
`;

const TextWrapper = styled.View`
  position: absolute;
  top: 60%;
`;

const LottieWrapper = styled.View`
  height: 140px;
  width: 100%;
`;

export default AcceptPayment;
