import { gql, useMutation } from '@apollo/client';


const logout = gql`
  mutation($device: Float!, $fcmToken: String) {
    logout(device: $device, fcmToken: $fcmToken) {
      status
      message
    }
  }
`;


export function useLogout() {
    const [logoutUser] = useMutation(logout);
    return { logoutUser };
}