import FormSpy from '@data-driven-forms/react-form-renderer/form-spy/form-spy';
import useFieldApi, {
  UseFieldApiConfig,
} from '@data-driven-forms/react-form-renderer/use-field-api';
import React from 'react';
import styled from 'styled-components';
import SearchBar from 'src/components/atoms/SearchBar/index.web';

export default function Search(props: UseFieldApiConfig) {
  const {
    id,
    label,
    input,
    subscribeToValues,
    handleChange,
    header,
    width,
 } = useFieldApi(props);
  const { value, onChange } = input;
  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <InputContainer>
          <SearchBar
            id={id}
            handleChange={(e) => {
              onChange(e);
              handleChange && handleChange(e);
            }}
            header={header}
            label={label}
            value={value}
            width={width}
          />
        </InputContainer>
      )}
    </FormSpy>
  );
}

const InputContainer = styled.div`
  margin-bottom: 0;
`;
