import React from 'react';
import styled from 'styled-components';
import { fonts } from 'src/styles/theme/styles';
import OutlineButtonWeb from '../../atoms/Button/OutlineButton.web';

interface IProps {
  titleText: string;
  showButton?: boolean;
  buttonText?: string;
  cb?: () => void;
  children?: React.ReactNode;
}

const CardTitleBlock = ({ titleText, showButton = false, buttonText, cb, children }: IProps) => {
  return (
    <TitleRow>
      <TopSectionTitle>{titleText}</TopSectionTitle>
      {buttonText && showButton && (
        <MsAuto>
          <OutlineButtonWeb text={buttonText} cb={cb} />
        </MsAuto>
      )}
      {children && children}
    </TitleRow>
  );
};

export default CardTitleBlock;

const TopSectionTitle = styled.text`
  font-size: ${({ theme }) => theme.rem(2.4)};
  color: #666;
  font-family: ${fonts.medium};
  display: block;
  @media all and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.rem(2)};
  }
  @media all and (max-width: 767px) {
    margin-bottom: ${({ theme }) => theme.rem(1)};
  }
`;
const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.rem(3.4)};
  min-height: ${({ theme }) => theme.rem(3.2)};
  @media all and (max-width: 767px) {
    min-height: auto;
    margin-bottom: ${({ theme }) => theme.rem(1.5)};
  }
  @media all and (min-width: 992px) and (max-width: 1450px) {
    margin-bottom: ${({ theme }) => theme.rem(2.2)};
  }
`;

const MsAuto = styled.div`
  margin-left: auto;
`;
