import React, { useEffect, useState } from 'react';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import { height } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { DisplayApplicationDetails } from './DisplayApplicationDetails';
import { ApplicationItemType } from './types';
import { useGetApplicationDetails } from 'src/graphql/applications/applications';
import { useParams } from 'src/routes/routing.web';

interface Props {
  applicationId?: string;
}

export default function ApplicationDetails(props: Props) {
  const { t } = useI18n();
  const [currentItem, setCurrentItem] = useState<ApplicationItemType | null>(null);
  const { getApplicationDetails, applicationDetailsData, refetchApplicationDetails } =
    useGetApplicationDetails();
  const applicationId = getApplicationId(props);

  useEffect(() => {
    if (applicationId) {
      getApplicationDetails({ variables: { id: applicationId } });
    }
  }, [applicationId]);

  useEffect(() => {
    if (applicationDetailsData?.application) {
      setCurrentItem(applicationDetailsData?.application);
    }
  }, [applicationDetailsData]);

  function getApplicationId(props: any) {
    if (props.applicationId) {
      return props.applicationId;
    } else {
      let resp: { applicationId: string } = useParams();
      return resp.applicationId;
    }
  }

  return (
    <>
      <NormalModal
        setModalVisible={() => {}}
        modalVisible={true}
        infoModal={true}
        maxWidth={'lg'}
        height={0.7 * height}
        Headerpopup={t('application.label')}
        handleSave={() => {}}>
        <DisplayApplicationDetails data={currentItem} />
      </NormalModal>
    </>
  );
}
