import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import useTable from 'src/components/molecules/Table/UseTable';
import { TableCells, TableDiv, TableRows } from 'src/components/molecules/Table/TableAtom';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { TableBody } from '@mui/material';
import { useGetClasses } from 'src/graphql/classes/index';
import { useHistory } from '../../../routes/routing.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useI18n } from 'src/i18n/hooks';
import { useHeaderTitle } from 'src/contexts/header-context';
import { colors } from 'src/styles/theme/styles';
import { createSearchFilter, debounce } from 'src/components/services';
import { AUTOCOMPLETE_MODULE, FILTERS, SEARCH_TIME } from 'src/constant';
import { useFilter } from 'src/contexts/filter-context';

const maxHeight = 440;
const headCells = [
  {
    id: 'course_name',
    label: 'course.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'class_name',
    label: 'class.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'divisions_c',
    label: 'division.label',
    align: 'left',
    disableSorting: true,
  },
];

export default function InstituteClasses() {
  let row;
  let searchValue = '' as string;
  let history = useHistory();
  const { filters, setFilter } = useFilter();

  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { getInstituteClasses, classesData, loading } = useGetClasses();
  row = classesData?.classes?.data;
  const totalCount = classesData?.classes?.totalCount;

  if (filters && filters?.classes?.on) {
    searchValue = filters?.classes?.search;
  }

  useEffect(() => {
    setTitle();
  }, []);

  function setTitle() {
    setHeading([
      {
        text: t('classes.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    handleFetch();
  }, [searchValue]);

  function handleFetch() {
    let filters = [] as any;
    if (searchValue) {
      filters = [
        {
          field: 'alias',
          type: FILTERS.REGEX,
          value: searchValue,
        },
        {
          field: 'name',
          type: FILTERS.REGEX,
          value: searchValue,
        },
      ];
    }

    let classesFilter = createSearchFilter(0, 0, filters);
    getInstituteClasses({ variables: classesFilter });
  }

  const { TblContainer, TblHead } = useTable(row, headCells, maxHeight, totalCount);

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.CLASSES]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchClasses(classData: string) {
    let isClear = classData?.length === 0;
    persistSearch(!isClear, classData);
  }

  const handleSearch = debounce((classData: string) => searchClasses(classData), SEARCH_TIME);

  return (
    <>
      <LoadContentWrapper>
        <View style={styles.flexRow}>
          <View style={styles.leftPart}>
            <Search
              value={searchValue || ''}
              label={t('searchClasses.text')}
              handleChange={handleSearch}
            />
          </View>
        </View>
        <View>
          {loading ? (
            <View style={styles.spinnerView}>
              <LoaderSpinner />
            </View>
          ) : (
            <TableDiv>
              <TblContainer>
                <TblHead />
                <TableBody>
                  {row?.map((item: any, i: number) =>
                    item?.divisions?.map((_division: any, d: number) => (
                      <TableRows key={`classes${d}`}>
                        <TableCells value={item?.course?.name} />
                        <TableCells
                          color={colors?.primaryColor}
                          value={item?.name}
                          clickable={true}
                          onPress={() => history.push(`/class/${item?.id}/${_division}/students`)}
                        />
                        <TableCells value={_division || ''} />
                      </TableRows>
                    )),
                  )}
                </TableBody>
              </TblContainer>
            </TableDiv>
          )}
        </View>
      </LoadContentWrapper>
    </>
  );
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  spinnerView: { width: '100%', height: 310, justifyContent: 'center', alignItems: 'center' },
});
