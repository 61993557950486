import React, { useEffect, useContext, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useHistory } from 'src/routes/routing';
import { useForm, Controller } from 'react-hook-form';
import Input from '../../atoms/Input/input.web';
import InputPassword from '../../atoms/Input/Password/index.web';
import SmallTextAtom from '../../atoms/Text/SmallTextAtom';
import NormalTextAtom from '../../atoms/Text/NormalTextAtom';
import HeaderThree from '../../atoms/Text/HeaderThree';
import { boxShadow, colors } from '../../../styles/theme/styles';
import { validation } from '../../../constant/validation';
import { loginEdbaAdminQuery } from '../../../graphql/auth/login-edba-admin';
import { useMutation } from '@apollo/client';
import { getTokenFromStorage, setTokenToStorage } from '../../services/storage-service';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { isWeb } from 'src/constant/device';
import { decodeToken, setAuthUserData } from 'src/graphql/common';
import { authToken } from 'src/utils/manageState';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { useI18n } from 'src/i18n/hooks';

export const Login = (props: any) => {
  const { t } = useI18n();
  const [canClick, setCanClick] = useState(true);
  const [loginEdbaAdminMutation] = useMutation(loginEdbaAdminQuery);
  let { setAlertDetails } = useAlertSystem();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();
  let history = useHistory();

  const handleKeyDown = React.useCallback((event) => {
    const { key } = event;
    if (key == 'Enter') {
      loginEdbaAdmin(getValues());
    }
  }, []);

  useEffect(() => {
    getTokenFromStorage().then((userExist) => {
      if (userExist && userExist !== null) {
        history.push('/dashboard');
      }
    });
  });

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, true);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, []);
  const loginEdbaAdmin = async (logindata: any) => {
    setCanClick(false);
    try {
      const user = await loginEdbaAdminMutation({ variables: { credential: logindata } });
      if (user.data?.login) {
        authToken({ edbaToken: user.data?.login });
        await setTokenToStorage(user.data?.login);
        await setAuthUserData({
          data: decodeToken(user.data?.login),
          history,
          location: props.location,
          type: 'login',
        });
        setCanClick(true);
      }
    } catch (e: any) {
      setCanClick(true);
      setAlertDetails({ message: e.message, level: 'error' });
    }
  };

  function goToChangePassword() {
    history.push('/request-reset-password/edba-admin');
  }

  return (
    <View style={styles.container}>
      <View style={styles.pageArea}>
        <View style={styles.loginDiv}>
          <View style={styles.alignthings}>
            <Icon name={'edba-logo'} size={40} />
          </View>

          <View style={[styles.alignthings, styles.marginTop28]}>
            <HeaderThree value="Hello edbā admin!" lineHeight={32} fontSize={24} />
          </View>

          <View style={[styles.alignthings]}>
            <NormalTextAtom value="Please sign-in to your account." />
          </View>

          <View style={styles.marginTop21}>
            <Controller
              control={control}
              name={'userName'}
              render={({ field: { onChange, onBlur, ref } }) => (
                <Input
                  id={'loginemail'}
                  header={'Username'}
                  inputRef={ref}
                  onBlur={onBlur}
                  label={''}
                  setValue={onChange}
                  width={350}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: validation.USER_NAME_REQUIRED,
                },
              }}
            />

            {errors.userName ? (
              <View style={styles.marginTop4}>
                <SmallTextAtom value={t(errors.userName.message)} color={colors.errorColor} />
              </View>
            ) : null}
          </View>
          <View style={styles.marginTop24}>
            <Controller
              control={control}
              name={'password'}
              render={({ field: { onChange, onBlur, ref } }) => (
                <InputPassword
                  id={'loginpw'}
                  header={'Password'}
                  inputRef={ref}
                  onBlur={onBlur}
                  label={''}
                  width={350}
                  setValue={onChange}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: validation.PASSWORD_REQUIRED,
                },
              }}
            />

            {errors.password && (
              <View style={styles.marginTop4}>
                <SmallTextAtom value={errors.password.message} color={colors.errorColor} />
              </View>
            )}
          </View>

          <View style={styles.link}>
            <TouchableOpacity style={{ alignSelf: 'flex-end' }} onPress={goToChangePassword}>
              <SmallTextAtom value={t("forgot-password.text")} color={colors.primary} />
            </TouchableOpacity>
          </View>

          <View style={{ marginTop: 32 }}>
            <SecondaryBtn
              canLoad={!canClick}
              label={'Sign In'}
              onPress={handleSubmit(loginEdbaAdmin)}
              style={{ paddingVertical: 8 }}
              lineHeight={2.4}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },

  alignthings: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop24: {
    marginTop: 24,
  },
  marginTop28: {
    marginTop: 28,
  },

  fontsize24: {
    fontSize: 24,
  },
  fontsize14: {
    fontSize: 14,
  },
  fontWeightNormal: {
    fontWeight: 'normal',
  },

  marginTop4: {
    marginTop: 4,
  },

  marginTop21: {
    marginTop: 21,
  },

  pageArea: {
    justifyContent: 'center',
    flexGrow: 1,
    zIndex: -10,
    borderColor: '#666666',
    width: 520,
    height: 578,
  },

  loginDiv: {
    backgroundColor: '#fff',
    ...boxShadow(),
    borderRadius: 3,
    paddingTop: isWeb ? 60 : 42,

    paddingBottom: 102,
    paddingHorizontal: 85,
  },

  link: {
    marginTop: isWeb ? 16 : 12,
    textAlign: 'right',
  },
});
