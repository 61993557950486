import {
  DashboardMainContainer as Container,
  DashboardColOneThird,
} from "../Common/CommonHelpers";
import React, { Fragment, useEffect, useState } from "react";

import DashboardAssignments from "../Common/DashboardAssignments.web";
import DashboardMainBanner from "src/components/molecules/DashboardBanner";
import DashboardNotices from "../Common/DashboardNotices.web";
import DashboardSchedule from "../Common/DashboardSchedule.web";
import MobileHeader from "src/components/hoc/MobileHeader";
import { TeacherDashboardMobile } from "./TeacherDashboardMobile.web";
import { isMobile } from "src/constant/device";
import { properCase } from "src/utils/utility";
import styled from "styled-components";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { userInformation } from "src/utils/manageState";

export const DashboardTeacher = () => {
  const { setHeading } = useHeaderTitle();
  const currentUserObject = userInformation();
  const { t } = useI18n();

  const [profilePhoto, setProfilePhoto] = useState<string>("");

  useEffect(() => {
    setHeading([{ text: t("dashboard.label"), url: "" }]);
  }, []);

  useEffect(() => {
    if (
      currentUserObject &&
      currentUserObject.userDetail &&
      currentUserObject.userDetail.photo
    ) {
      setProfilePhoto(currentUserObject.userDetail.photo);
    }
  }, [currentUserObject]);

  return (
    <Fragment>
      {isMobile && <MobileHeader label="" backIcon={false} />}
      <Container>
        {!isMobile ? (
          <WebContainer>
            <DashboardMainBanner
              userType={currentUserObject?.userType}
              image={profilePhoto}
              bannerTitleHello={t("hello.label")}
              fullName={properCase(
                currentUserObject?.userDetail?.fullName ?? ""
              )}
              className={currentUserObject?.userDetail?.subjects ?? ""}
              bannerTitleId={t("employeeId.label")}
              id={currentUserObject?.userDetail?.employeeId}
            />
            <Row>
              <ColumnOneThird>
                <DashboardSchedule />
              </ColumnOneThird>
              <ColumnOneThird>
                <DashboardAssignments />
              </ColumnOneThird>
              <ColumnOneThird>
                <DashboardNotices />
              </ColumnOneThird>
            </Row>
          </WebContainer>
        ) : (
          <TeacherDashboardMobile />
        )}
      </Container>
    </Fragment>
  );
};

const WebContainer = styled.div`
  margin-top: -22px;
`;

const Row = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.rem([0, -1.5])};
  flex-wrap: wrap;
`;

const ColumnOneThird = styled(DashboardColOneThird)`
  padding: ${({ theme }) => theme.rem([0, 1.5])};
`;
