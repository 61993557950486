import { AUTOCOMPLETE_MODULE, ERROR, limit } from 'src/constant';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { getModifiedExamDetailsQuery } from 'src/graphql/assessment/assessment-exam-selection-form';
import { graphqlQuery } from 'src/graphql/util';
import { mergeBatchClassLabel } from 'src/utils/utility';
import selectionForm from 'src/form-json/assessment/assessment-exam-selection.json';
import { useForm } from 'react-hook-form';
import { useHistory } from 'src/routes/routing.web';
import { useI18n } from 'src/i18n/hooks';
import { TestData } from 'src/components/organism/Assessment/Marks/types';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { GRADE_ERROR } from 'src/constant/message';

interface MarksSelectionFormProps {
  retrieveValues: any;
  dataLimit?: number;
  pagePath: string;
  urlBatchId?: string;
  urlDivision?: string;
  urlExamId?: string;
  urlSubjectId?: string;
  setParentBatchId: any;
  setParentClassId: any;
  setParentClassAlias: any;
  setParentDivision: any;
  setParentExamId: any;
  setParentSubjectId: any;
  setParentTestIds: any;
  setParentHeadCells: any;
  parentHeadCells1: any;
  parentActionHeadCell: any;
  setParentAllFormValuesAvailable: any;
  setParentSelectedTestsArray: any;
  isLoading: boolean;
  setMarksType?:any;
  setAllowedGrades?:any;
  setIsMarkType?:any
}

export default function MarksSelectionForm(props: MarksSelectionFormProps) {
  const { rem }: any = useTheme();
  const [elements, setElements] = useState<any>({});
  const [filteredBatchObject, setFilteredBatchObject] = useState<any>({});
  const [examsListObject, setExamsListObject] = useState<any>([]);
  const [subjectsListObject, setSubjectsListObject] = useState<any>([]);
  const [canFilterExam, setCanFilterExam] = useState<boolean>(false);
  const [canFilterSubjects, setCanFilterSubjects] = useState<boolean>(false);
  const { setAlertDetails } = useAlertSystem();
  const {
    retrieveValues,
    dataLimit = limit,
    pagePath,
    urlBatchId = '',
    urlDivision = '',
    urlExamId = '',
    urlSubjectId = '',
    setParentBatchId,
    setParentClassId,
    setParentClassAlias,
    setParentDivision,
    setParentExamId,
    setParentSubjectId,
    setParentTestIds,
    setParentHeadCells,
    parentHeadCells1,
    parentActionHeadCell,
    setParentAllFormValuesAvailable,
    setParentSelectedTestsArray,
    isLoading,
    setMarksType,
    setAllowedGrades,
    setIsMarkType,
  } = props;
  const { fields }: any = elements;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  useEffect(() => {
    if (examsListObject.length != 0 && canFilterExam) {
      filterExamByValue(examsListObject, urlExamId);
    }
  }, [examsListObject]);

  useEffect(() => {
    if (subjectsListObject.length != 0 && canFilterSubjects) {
      filterSubjectByValue(subjectsListObject, urlSubjectId);
    }
  }, [subjectsListObject]);

  useEffect(() => {
    // if we do not explicitly null subject field, it doesn't get filled from url values
    setValue('subject', null);
    setValue('batch', null);
    setValue('division', null);
    setValue('exams', null);
    onLoadFunction();
  }, []);

  useEffect(() => {
    if (Object.keys(filteredBatchObject).length != 0) {
      setValue("batch", { label: `${filteredBatchObject?.label} / ${filteredBatchObject?.class?.alias}`, value: filteredBatchObject?.value, type: filteredBatchObject.settings.gradingType });
      setMarksType(filteredBatchObject.settings.gradingType==='MARKS');
      setIsMarkType(filteredBatchObject.settings.gradingType==='MARKS');
      if (
        filteredBatchObject.settings.gradingType === 'MARKS' &&
        !filteredBatchObject.settings.grade
      ) {
        setAlertDetails({
          message: GRADE_ERROR.NOT_FOUND,
          level: ERROR,
        });
      }
      //  set value for division
      const batchDivisions = filteredBatchObject?.divisionDetails?.map((det) => det.division);
      if (batchDivisions?.includes(urlDivision)) {
        getDivision(batchDivisions);
        setValue("division", { label: urlDivision, value: urlDivision });
        setParentDivision(urlDivision);

        // at this point batch id and division are ensured to be valid (based on url params)
        setCanFilterExam(true);
      } else {
        // invalid division in url reset url and form
        setCanFilterExam(false);
        resetFormAndUrl();
      }

      // set batch and class id based on url parameter
      setParentBatchId(filteredBatchObject.value);
      setParentClassId(filteredBatchObject.class.id);
      setParentClassAlias(filteredBatchObject.class.alias);
    }
  }, [filteredBatchObject]);

  function createTestsObject(testsArray: any) {
    // to set data in state for add marks functionality
    setParentSelectedTestsArray(testsArray);
    // to create dynamic headcells with test names
    let temp_dynamicHeadcells = [] as any;
    const tests: TestData[] = [];
    testsArray.forEach(function (item: any) {
      tests.push({
        id: item?.id,
        totalMarks: item?.totalMarks,
        marksType: item?.markingType,
      });
      const labelSuffix = item?.markingType === 'MARK' ? ` (${item?.passingMarks}/${item?.totalMarks})` : '';
      temp_dynamicHeadcells.push({
        id: item?.id,
        label: `${item?.name}${labelSuffix}`,
        align: 'right',
        disableSorting: true,
        style: { minWidth: '200px' }
      });
    });
    setParentTestIds(tests);
    setParentHeadCells([...parentHeadCells1, ...temp_dynamicHeadcells, ...parentActionHeadCell]);
  }

  function filterSubjectByValue(arrayOfObject: any[], searchTerm: string) {
    // function that returns the entire object on which passed search Term exactly matches 
    var result = arrayOfObject.filter(function (v, i) {
      return v.value.indexOf(searchTerm) >= 0;
    });
    if (result.length != 0) {
      // subject id from url is valid i.e filtered result obj is not empty
      setValue("subject", { label: result[0].label, value: result[0].value });
      setParentSubjectId(urlSubjectId);
      // to set headers for parents with test names
      createTestsObject(result[0].testsArray);

      // flags (true) that all form values are available. this is required to correctly identify
      // whether or not to display no records found banner
      setParentAllFormValuesAvailable(true);
    } else {
      // if no valid result i.e invalid batch id in url reset form and url
      setValue('subject', null);
      resetFormAndUrl();
    }
  }

  function filterExamByValue(arrayOfObject: any[], searchTerm: string) {
    // function that returns the entire object on which passed search Term exactly matches 
    var result = arrayOfObject.filter(function (v, i) {
      return v.value.indexOf(searchTerm) >= 0;
    });
    if (result.length != 0) {
      // exam id from url is valid i.e filtered result obj is not empty
      setValue("exams", { label: result[0].label, value: result[0].value, type: result[0].type });
      setParentExamId(urlExamId);
      // once exam id, label and values are set, we can allow filtering of subjects
      setCanFilterSubjects(true);
      getSubjectsList(result[0].currentItemsubjectWiseTestsList);
    } else {
      resetFormAndUrl();
    }
  }

  function filterBatchByValue(arrayOfObject: any[], searchTerm: string) {
    // function that returns the entire object on which passed search Term exactly matches 
    var result = arrayOfObject.filter(function (v, i) {
      return v.value.indexOf(searchTerm) >= 0;
    });
    if (result.length != 0) {
      // filtered result obj is not empty i.e batch id from url is valid
      setFilteredBatchObject(result[0]);
      getExamsList(urlBatchId);
      setAllowedGrades(result[0].settings?.grade.grades.map((item: any) => item.name));
      setIsMarkType(result[0].gradingType==='MARKS');
    } else {
      resetFormAndUrl();
    }
  }

  async function onLoadFunction() {
    let { data: allBatches, loading } = await graphqlQuery(getBatchQuery, { limit: 0 });
    if (!loading && allBatches?.batches?.data) {
      let options = mergeBatchClassLabel(allBatches?.batches?.data);
      setElements({});
      selectionForm[0].fields[0].option = options;
      setElements(selectionForm[0]);
    }

    // if batch id is present in url (passed from parent) then
    // filter out batch object with matching id value
    if (urlBatchId) {
      filterBatchByValue(allBatches?.batches?.data, urlBatchId);
    }
  }

  async function getDivision(divisions: string[]) {
    let options = [] as any;
    divisions.forEach(function (item: any) {
      options.push({
        value: item.toString(),
        label: item.toString(),
      });
    });
    setElements({});
    selectionForm[0].fields[1].option = options;
    setElements(selectionForm[0]);
  }

  async function getSubjectsList(currentItemsubjectWiseTestsList: any) {
    if (currentItemsubjectWiseTestsList) {
      let options = [] as any;
      currentItemsubjectWiseTestsList.forEach(function (item: any) {
        options.push({
          value: item?.subject?.value,
          label: item?.subject?.label,
          testsArray: item?.tests
        });
      });
      setElements({});
      setSubjectsListObject(options);
      selectionForm[0].fields[3].option = options;
      setElements(selectionForm[0]);
    }
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      // setCanFilterExam(false) && setCanFilterSubjects(false)
      // required to clear exam form value else will fetch and apply url parameter in exam
      setCanFilterExam(false);
      setCanFilterSubjects(false);
      setValue(AUTOCOMPLETE_MODULE.DIVISION, null);
      setValue(AUTOCOMPLETE_MODULE.EXAMS, null);
      setValue(AUTOCOMPLETE_MODULE.SUBJECT, null);
      if (data) {
        setAllowedGrades(data.settings.grade?.grades.map((item: any) => item.name));
        setIsMarkType(data.settings.gradingType==='MARKS');
        getDivision(data?.divisionDetails.map((det) => det.division));
        getExamsList(data?.value);
      }
      clearErrors('division');
      clearErrors('exams');
      clearErrors('subject');
    }
    if (id === AUTOCOMPLETE_MODULE.EXAMS) {
      setValue(AUTOCOMPLETE_MODULE.SUBJECT, null);
      getSubjectsList(data?.currentItemsubjectWiseTestsList);
    }
  }

  async function loadExams(exams: []) {
    let options = [] as any;
    exams.forEach(function (item: any) {
      options.push({
        value: item?.value,
        label: item?.label,
        currentItemsubjectWiseTestsList: item?.tests,
      });
    });
    setElements({});
    setExamsListObject(options);
    selectionForm[0].fields[2].option = options;
    setElements(selectionForm[0]);
  }

  async function getExamsList(batchId: string) {
    let filters = {
      "filters": {
        "batch": {
          "eq": batchId
        }
      }
    };
    let { data: examsListData, loading } = await graphqlQuery(getModifiedExamDetailsQuery, filters);
    if (!loading && examsListData?.examDetails?.data) {
      loadExams(examsListData?.examDetails?.data);
    }
  }

  function resetFormAndUrl() {
    // if no valid result i.e invalid batch id in url reset form and url
    reset();
    history.replace(`/assessment/${pagePath}/limit/${dataLimit}/page/1/`);
  }

  return (
    (fields ?
      <ExamSelectionFormWrapper>
        {
          fields && fields.map((field: any, i: number) => {
            return (
              <FormWrapper
                key={`s${i}`}
                style={{ width: rem((i == 0) ? 47.5 : (i == 1) ? 20 : 28.5) }}>
                <Element
                  key={`selection${i}`}
                  field={field}
                  control={control}
                  errors={errors}
                  handleCustomSelect={handleCustomSelect}
                  dynamicValidation={false}
                  isCustomDisable={false}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              </FormWrapper>
            );
          })
        }

        <SecondaryBtn
          canLoad={isLoading}
          label={t('search.label')}
          onPress={handleSubmit(retrieveValues)}
          width={rem(8)}
          style={{ height: rem(3.6), marginTop: rem(2) }}
          secondary={false}
        />
      </ExamSelectionFormWrapper>
      :
      <FormSpinnerView>
        <LoaderSpinner />
      </FormSpinnerView>
    )
  );
}

const ExamSelectionFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FormWrapper = styled.div`
  flex-direction: row;
  z-index: 50;
  margin-right: ${(props) => props.theme.rem(2.4)};
`;

const FormSpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.rem(75)};
  height: 163px;
`;
