import { gql, useLazyQuery } from "@apollo/client";

export const getAllInvitedCAPApplicantsQuery = gql`
  query ($admissionID: String!) {
    allInvitedCAPApplicants(admissionId: $admissionID) {
      capId
      date
      email
      invitedBy {
        id
        email
        personalDetails {
          firstName
          lastName
          fullName
        }
      }
      name
      scrollRefNo
      subjectGroup
    }
  }
`;

export function useGetAllInvitedCAPApplicants() {
  const [
    getAllInvitedCAPApplicants,
    {
      loading: allInvitedCAPApplicantsLoading,
      data: allInvitedCAPApplicantsData,
      error: allInvitedCAPApplicantsError,
    },
  ] = useLazyQuery(getAllInvitedCAPApplicantsQuery, {
    fetchPolicy: "network-only",
  });
  return {
    getAllInvitedCAPApplicants,
    allInvitedCAPApplicantsData,
    allInvitedCAPApplicantsError,
    allInvitedCAPApplicantsLoading,
  };
}
