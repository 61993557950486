import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  // SafeAreaView,
} from 'react-native';
import { Statusbar } from '../../atoms/StatusBar';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faLongArrowLeft, faBars, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'src/styles/theme/styles';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';

import PropTypes from 'prop-types';
import { height, isIOS, isWeb, width } from 'src/constant/device';
import styled from 'styled-components/native';
import { Icon } from 'src/components/atoms/Icon/Icon';

type MobileHeaderPropType = {
  label: string;
  actionLabel?: string;
  style?: any;
  handleEdit?: () => void;
  backIcon?: boolean;
  showEdit?: boolean;
  showSave?: boolean;
  handleSave?: (() => Promise<void>) | (() => void);
  history?: any;
  navigation?: any;
  canLoad?: boolean;
  onMenuIconClick?: () => void;
  setIsEditMode?: (flag: boolean) => void;
  rightIcon?: boolean,
  rightIconName?: string | IconDefinition,
  rightIconType?: "CustomIcon" | "FontAwesomeIcon",
  rightIconSize?: number,
  disableToggleDrawer?: boolean,
  actionLabelFontSize?: number,
}
// TODO:: ⬆️ This component and specifically its props need a refactor

const MobileHeader: React.FC<MobileHeaderPropType> = ({
  label,
  style = {},
  actionLabel = "",
  handleEdit,
  backIcon = true,
  showEdit = true,
  showSave = false,
  handleSave,
  history,
  navigation,
  canLoad = true,
  onMenuIconClick,
  setIsEditMode,
  rightIcon = false,
  rightIconName = '',
  rightIconType = "CustomIcon",
  rightIconSize = 24,
  disableToggleDrawer = false,
  actionLabelFontSize = 1.2
}) => {
  function goBack() {
    if (isWeb && history) {
      history.pop();
    } else {
      if (navigation && navigation.goBack) {
        navigation.goBack();
      }
      if (setIsEditMode) {
        setIsEditMode(false);
      }
    }
  }
  return (
    <>
      <Container style={{ ...styles.linearGradient, ...style }}>
        <Statusbar barStyle="light-content" backgroundColor="transparent" translucent={true} />
        <HeaderView>
          {
            backIcon ? (
            <LeftIconView onPress={goBack} testID={'mobileHeaderLeftArrow'}>
              <FontAwesomeIcon icon={faLongArrowLeft} color={colors.white} size={24} />
            </LeftIconView>
          ) : (
            <LeftIconView
                onPress={disableToggleDrawer ? () => { } : () => {
                if (onMenuIconClick) {
                  onMenuIconClick();
                } else {
                  navigation?.toggleDrawer();
                }
              }}>
              <FontAwesomeIcon icon={faBars} color={colors.white} size={19} />
            </LeftIconView>
            )
          }
          <HeaderLabelView style={!showSave && !showEdit && { paddingRight: '8%' }}>
            <HeaderFive value={label} color="white" />
          </HeaderLabelView>

          {canLoad && (
            <>
              {showSave && handleSave ? (
                <RightIconView>
                  <TouchableOpacity onPress={handleSave}>
                    {rightIcon ? (
                      <>
                        {
                          rightIconType === "CustomIcon" ? (
                            <Icon name={rightIconName as string} size={rightIconSize} />
                          ) : (
                            <FontAwesomeIcon
                              icon={rightIconName as IconDefinition}
                              color={colors.white}
                              size={18}
                            />
                          )
                        }
                      </>
                    ) : (
                      <NormalTextSelect value={actionLabel} color={colors.white} fontWeight={'bold'} fontsize={actionLabelFontSize} lines={1} />
                    )}
                  </TouchableOpacity>
                </RightIconView>
              ) : (
                showEdit && handleEdit && (
                  <RightIconView>
                    <TouchableOpacity onPress={handleEdit}>
                      <NormalTextSelect value={actionLabel} color={colors.white} fontWeight={'bold'} fontsize={actionLabelFontSize} />
                    </TouchableOpacity>
                  </RightIconView>
                )
              )}
            </>
          )}

          {!canLoad && (
            <RightIconView>
              <ActivityIndicator animating={!canLoad} size="small" color={colors.white} />
            </RightIconView>
          )}
        </HeaderView>
      </Container>
    </>
  );
}

export default MobileHeader;

const getHeight = () => {
  if (height > 600 && height <= 700) {
    return 80;
  } else if (height >= 701 && height <= 900) {
    return 90;
  } else if (height >= 901) {
    return 105;
  } else {
    return 90;
  }
};

const getTopMargin = () => {
  if (height > 600 && height <= 700) {
    return -9;
  } else if (height >= 701 && height <= 900) {
    return 7;
  } else if (height >= 901) {
    return 18;
  } else {
    return 0;
  }
};

const styles = StyleSheet.create({
  linearGradient: {
    height: getHeight(),
    // height: height > 900 ? '11%' : '12%',
    width: '100%',
  },
});

const Container = styled.View`
  background-color: ${colors.primary};
`;

const HeaderView = styled.View`
  height: 120px;
  background-color: transparent;
  margin: 0 24px;
  flex-direction: row;
  justify-content: space-around;
  margin-top: ${isIOS ? getTopMargin() : 0}px;
`;

const LeftIconView = styled.TouchableOpacity`
  align-self: center;
  align-items: flex-start;
`;

const HeaderLabelView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const RightIconView = styled.View`
  align-self: center;
  align-items: flex-end;
`;
