import {
  DashboardColOneThird as ColumnOneThird,
  DashboardContainer as Container,
} from "../Common/CommonHelpers";
import React, { useEffect } from "react";

import DashboardEvents from "../Common/DashboardEvents.web";
import DashboardNews from "../Common/DashboardNews.web";
import DashboardNotices from "../Common/DashboardNotices.web";
import { InstituteDashboardCards } from "./InstituteDashboardHelpers";
import PopularCourseDemographics from "./PopularCourseDemographics";
import { ReportsList } from "src/components/molecules/Reports/ReportsList";
import styled from "styled-components";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { width } from "src/constant/device";

export const DashboardInstitute = () => {
  const { setHeading } = useHeaderTitle();
  const { t } = useI18n();

  useEffect(() => {
    setHeading([{ text: t("dashboard.label"), url: "" }]);
  }, []);

  return (
    <Container>
      <InstituteDashboardCards />

      <RowWrapper>
        <ColumnA>
          <PopularCourseDemographics />
        </ColumnA>
        <ColumnB>
          <ReportsList />
        </ColumnB>
      </RowWrapper>

      <RowWrapper>
        <ColumnOneThird>
          <DashboardNews />
        </ColumnOneThird>
        <ColumnOneThird>
          <DashboardNotices />
        </ColumnOneThird>
        <ColumnOneThird>
          <DashboardEvents />
        </ColumnOneThird>
      </RowWrapper>
    </Container>
  );
};

const RowWrapper = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.rem([0, -3])};
  flex-wrap: wrap;
`;

const ColumnA = styled.div`
  display: ${width <= 767 ? "none" : "block"};
  width: 54.45%;
  padding: ${({ theme }) => theme.rem([1.5, 3, 1.5, 2.7])};
  box-sizing: border-box;
  margin-bottom: ${({ theme }) => theme.rem(3)};
  @media all and (max-width: 991px) {
    width: 100%;
  }
`;

const ColumnB = styled.div`
  width: 45.55%;
  padding: ${({ theme }) => theme.rem([1.4, 3, 1.5, 2.7])};
  box-sizing: border-box;
  margin-bottom: ${({ theme }) => theme.rem(3)};
  @media all and (max-width: 991px) {
    width: 100%;
  }
`;
