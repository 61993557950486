// === Legacy Login === It should be refactored or removed in the future. {{START}}
export { AuthLanding as NativeLanding } from 'src/components/organism/AuthLanding.native';
export { InstituteUserLogin } from 'src/components/organism/Login/InstituteUser';
export { RequestResetPassword } from 'src/components/organism/ResetPassword/RequestResetPassword';
// === Legacy Login === It should be refactored or removed in the future. {{END}}
export { Dashboard } from 'src/components/organism/Dashboard';
export { InstituteDashboard } from 'src/components/organism/Dashboard';
export { ProfileView } from 'src/components/organism/Profile/index';
export { default as ProfileDetailView } from 'src/components/organism/Profile/DetailView.native';
export { default as NewsNotices } from 'src/components/organism/Communication/NewsNotices';
export { default as Notifications } from 'src/components/organism/Notifications';
export { default as TeacherAssignments } from 'src/components/organism/Assignments/Teacher';
export { default as StudentAssignments } from 'src/components/organism/Assignments/StudentAssignment';
export { default as Assignment } from 'src/components/organism/Assignments/Assignment/Assignment';
export { default as StudentList } from 'src/components/organism/Assignments/Student/StudentList';
export { default as StudentDetails } from 'src/components/organism/Assignments/Student/StudentDetails';
export { ResetPassword } from 'src/components/organism/ResetPassword/ResetPassword';
export { default as Events } from 'src/components/organism/Events';
export { default as TimeTable } from 'src/components/organism/Schedule';
export { default as AssignmentRoot } from 'src/components/organism/Assignments/AssignmentRoot';
export { default as Attendance } from 'src/components/organism/Attendance';
export { default as AttendanceStudents } from 'src/components/organism/Attendance/NativeScreens/MarkAttendance';
export { default as StudentAndClassSlotsAttendanceComponent } from 'src/components/organism/Attendance/NativeScreens/StudentAndClassSlotsAttendanceComponent';
export { default as StudentsForClassAttendanceListComponent } from 'src/components/organism/Attendance/NativeScreens/StudentsForClassAttendanceListComponent';
export { default as StudentAttendanceNative } from 'src/components/organism/Attendance/Children/StudentAttendanceNative';
export { default as MultiAccount } from 'src/components/organism/MultiAccount';
export { default as Users } from 'src/components/organism/Users/index.native';
export { default as Payment } from 'src/components/organism/Payment/Student';
export { default as PaymentReceipt } from 'src/components/organism/Payment/Student/PaymentReceipt';
export { default as AcceptPayment } from 'src/components/organism/Payment/Admin/AcceptPayment.native';
export { default as StudentBatchSelection } from 'src/components/organism/Students/StudentBatchSelection.native';
export { default as AdminStudentList } from 'src/components/organism/Students/AdminStudentList.native';
export { default as Scanner } from 'src/components/organism/Payment/Admin/Scanner';
export { default as Messages } from 'src/components/organism/Messages';
export { default as AcceptPaymentApplicant } from 'src/components/organism/Payment/Admin/AcceptPaymentApplicant.native';
export { default as ApplicationInformation } from 'src/components/organism/Applications/ApplicationInformation.native';
export { default as ApplicationPayableListing } from 'src/components/organism/Applications/ApplicationPayableListing';
export { ApplicantionsListingNative } from 'src/components/organism/Applications/index';
export { default as ApplicationFormViewer } from 'src/components/organism/Applications/FormViewer';
export { default as EducationDetails } from 'src/components/organism/Applications/EducationDetails';
export { default as ApplicantSignup } from 'src/components/organism/Login/Signup/ApplicantSignup.native';
export { default as RedirectionScreen } from 'src/components/organism/Login/RedirectionScreens/RedirectionScreen';
export { default as Admissions } from 'src/components/organism/Admission/index.native';
export { default as UserLogin } from 'src/components/organism/Login/UserLogin';
export { default as AcceptOtherPayment } from 'src/components/organism/Payment/Admin/AcceptOtherPayment.native';
export { default as StudentResult } from 'src/components/organism/Assessment/Results/Student/index.native';
export { default as StudentResultDetail } from 'src/components/organism/Assessment/Results/Student/ResultDetail.native';
