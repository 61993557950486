import React, { Dispatch, SetStateAction, useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import SecondaryBtn from '../Button/SecondaryButton';
import Chip from '../Chip';
import Input from '../Input/input.web';
import HeaderFive from '../Text/HeaderFive';
import SmallTextAtom from '../Text/SmallTextAtom';
import { clipLongText } from 'src/utils/utility';

interface Props {
  title?: string;
  chipArray: (string | number)[];
  setChipArray: Dispatch<SetStateAction<(string | number)[]>> | ((chipArray: string) => void);
  chipText?: string;
  inputId: string;
  inputHeader: string;
  requiredErrorMsg?: string;
  typeInput?: string;
  denseStyling?: boolean;
  hideChipsDisplay?: boolean
  reducedSpacing?: boolean;
  checkMinMax?: boolean;
  minValue?: number;
  maxValue?: number;
  inputWidth?: string;
}

// denseStyling is utilised to make component compact and in-line eg. major subject input in streams
// reducedSpacing is used to adjust form for DDF styling

export default function ChipInput(props: Props) {
  const {
    title = "",
    chipArray,
    setChipArray,
    chipText = "",
    inputId,
    inputHeader,
    requiredErrorMsg,
    typeInput = 'text',
    denseStyling = false,
    hideChipsDisplay = false,
    reducedSpacing = false,
    checkMinMax = false,
    minValue,
    maxValue,
    inputWidth
  } = props;
  const { t } = useI18n();
  const [value, setValue] = useState<any>('');
  const [error, setError] = useState<any>('');
  const isNumber = typeInput === 'number';

  function selectChip() {
    if (value) {
      let proxyDivision = [...chipArray];
      if (!proxyDivision.includes(isNumber ? parseFloat(value) : value)) {
        proxyDivision.push(isNumber ? parseFloat(value) : value);
      }
      setChipArray(proxyDivision);
      setValue('');
      requiredErrorMsg && setError('');
    } else {
      requiredErrorMsg && setError(t(requiredErrorMsg));
    }
  }

  function removeChip(idx: any) {
    let check = [...chipArray];
    check.splice(idx, 1);
    setChipArray(check);
  }

  const checkMinMaxValue = (value: any) => {
    const val = isNaN(value) ? parseInt(value) : value;
    if ((minValue || minValue === 0) && val < minValue) return '';
    if ((maxValue || maxValue === 0) && val > maxValue) return maxValue;
    return value;
  };

  return (
    <>
      {title && <HeaderFive value={t(title)} />}
      {!hideChipsDisplay && chipArray && chipArray.length > 0 && (
        <ChipWrapper
          marginTop={reducedSpacing || (!denseStyling && chipArray.length === 0) ? 0 : 24}>
          {chipArray?.map((chip: any, index: number) => (
            <ChipView>
              <Chip
                label={clipLongText((isNumber ? `${t(chipText)} : ${chip}` : chipText ? `${t(chipText)} ${chip}` : chip), 50)}
                onPress={() => removeChip(index)}
              />
            </ChipView>
          ))}
        </ChipWrapper>
      )}
      <InputWrapper
        denseStyling={denseStyling}
        reducedSpacing={reducedSpacing}
        marginTop={reducedSpacing ? 0 : (chipArray?.length > 0 ? 16 : 24)}
        inputWidth={inputWidth}
      >
        <InputErrorWrapper denseStyling={denseStyling}>
          <Input
            id={inputId}
            setValue={e =>
              setValue(checkMinMax ? checkMinMaxValue(e.target.value) : e.target.value)
            }
            value={value}
            header={t(inputHeader)}
            width={denseStyling ? "350px" : "100%"}
            typeInput={typeInput}
          />
          <ErrorView>
            {error ? (
              <SmallTextAtom value={error} color={colors.errorColor} />
            ) : null}
          </ErrorView>
        </InputErrorWrapper>
        <BtnWrapper denseStyling={denseStyling}>
          <SecondaryBtn
            onPress={selectChip}
            label={t('add.label')}
            width={60}
            style={{ height: denseStyling ? 40 : 'auto' }} />
        </BtnWrapper>
      </InputWrapper>
    </>
  );
}

const ErrorView = styled.View`
  margin-top: 4px;
`;

const BtnWrapper = styled.View<{ denseStyling: boolean }>`
  justify-content: flex-end;
  margin-top: ${(props) => props?.denseStyling ? 0 : 12}px;
`;

const ChipView = styled.View`
  margin-bottom: 8px;
  margin-right: 8px;
`;

const ChipWrapper = styled.View<{ marginTop: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${({ marginTop }) => marginTop};
  max-width: 100%;
`;

const InputErrorWrapper = styled.View<{ denseStyling: boolean }>`
  display: flex;
  flex-direction: ${({ denseStyling }) => denseStyling ? "row" : "column"};
  margin-right: ${({ denseStyling }) => denseStyling ? "16px" : "0px"};
`;

const InputWrapper = styled.View<{ denseStyling: boolean, marginTop: number, reducedSpacing: boolean, inputWidth?: string }>`
  display: flex;
  flex-direction: ${({ denseStyling }) => denseStyling ? "row" : "column"};
  border-style: ${({ denseStyling }) => denseStyling ? "none" : "dashed"};
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.dividerColor};
  padding: 8px;
  margin-top: ${({ denseStyling, marginTop }) => denseStyling ? 0 : marginTop};
  margin-bottom: ${({ denseStyling, reducedSpacing }) => (denseStyling || reducedSpacing) ? "0px" : "24px"};
  width: ${({ inputWidth }) => inputWidth};
`;