import { ERROR } from 'src/constant';
import ERROR_MSG from 'src/constant/error';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import config from 'src/config/config.json';
import navigationStrings from 'src/navigation/navigationStrings';

interface PaymentDetails {
  token: string;
  merchId: string;
  amount: string;
  custEmail: string;
  custContact: string;
}

const nttPaymentOptions = (props?: any) => {
  let { setAlertDetails } = useAlertSystem();

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const createAtomPayNetzOptions = (paymentDetails: PaymentDetails) => {
    return {
      atomTokenId: paymentDetails?.token,
      merchId: paymentDetails?.merchId,
      custEmail: paymentDetails?.custEmail || '',
      custMobile: paymentDetails?.custContact || '',
      returnUrl: `${config.Server.url.replace('/graphql', '')}/ndps/payment/verify`,
    };
  };

  const openAipayPopUp = (paymentDetails: PaymentDetails) => {
    let setReturnUrl = config.atomPayNetz.atomPayNetzNativeReturnUrl;
    let scriptUrl = config.atomPayNetz.atomPayNetzScriptUrl
    let htmlPage = '<!DOCTYPE html><html lang="en"><head><title>AtomInstaPay</title> <meta charset="utf-8"> <meta name="viewport" content="width=device-width, initial-scale=1"> </head><body><p style="text-align:center;margin-top:20%;">Please wait</p> <script src="'+scriptUrl+'?v='+Date.now()+'"></script> <script> openPay(); function openPay(){ const options={"atomTokenId": "'+paymentDetails.token+'", "merchId": "'+paymentDetails.merchId+'", "custEmail": "'+paymentDetails.custEmail+'", "custMobile": "'+paymentDetails.custContact+'", "returnUrl": "'+setReturnUrl+'", "userAgent": "mobile_webView"}; var atom=new AtomPaynetz(options, "uat"); }</script></body></html>';
     return htmlPage;
  }

  async function displayAtomPayNetzPortalNative(paymentDetails: PaymentDetails, callback: Function) {
    let aipayContent = openAipayPopUp(paymentDetails);
    props.navigation.navigate(navigationStrings.APPLICATION_FEE_PAYMENT, { htmlPage: aipayContent, callback });
  }

  async function displayAtomPayNetzPortal(paymentDetails: PaymentDetails) {
    const res = await loadScript(config.atomPayNetz.atomPayNetzScriptUrl);
    if (!res) {
      setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
      return;
    }

    const atom = new window.AtomPaynetz(
      createAtomPayNetzOptions(paymentDetails),
      'uat',
    );
  }

  return { displayAtomPayNetzPortal, displayAtomPayNetzPortalNative };
};

export default nttPaymentOptions;
