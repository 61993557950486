import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props:any) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C16.4187 20 20 16.4187 20 12C20 7.58125 16.4187 4 12 4C7.58125 4 4 7.58125 4 12C4 16.4187 7.58125 20 12 20ZM5.5 12C5.5 10.475 6.025 9.07188 6.90406 7.96563L9.18664 10.2484L11.4692 12.5312L13.7518 14.8141L16.0344 17.0969C14.9281 17.975 13.525 18.5 12 18.5C8.40938 18.5 5.5 15.5906 5.5 12ZM12 5.5C15.5906 5.5 18.5 8.40938 18.5 12C18.5 13.525 17.975 14.9281 17.0969 16.0344L14.8141 13.7518L12.5312 11.4692L10.2484 9.18664L7.96563 6.90406C9.07188 6.025 10.475 5.5 12 5.5ZM10.0607 9.00001L9 10.0607L13.5608 14.6215L14.6215 13.5608L10.0607 9.00001Z"
        fill="black"
        fillOpacity="0.6"
      />
    </Svg>
  )
}

export default SvgComponent
