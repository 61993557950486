import React, { createContext, useContext } from 'react';
import { usePersistStorage } from 'react-native-use-persist-storage';

interface FilterContextData {
  setFilter(name: any): void;
  filters: any;
  restored: boolean;
}

const FilterContext = createContext<FilterContextData>({} as FilterContextData);

const FilterProvider: React.FC = ({ children }) => {
  const [filters, setFilter, restored] = usePersistStorage('edbaFilters', {});
  return (
    <FilterContext.Provider value={{ setFilter, filters, restored }}>
      {children}
    </FilterContext.Provider>
  );
};

const useFilter = (): FilterContextData => {
  const context = useContext(FilterContext);
  return context;
};

export { FilterProvider, useFilter };

// "university":{
//     "on" : true,
//     search : "abcd"
// }
