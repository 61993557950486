/* eslint-disable react-native/no-inline-styles */
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import React, { useState } from 'react';
import { colors, fontWeight } from 'src/styles/theme/styles';
import NormalTextSelect from '../Text/NormalTextSelect';
import BottomBorder from '../BottomBorder';

interface Proptype {
  roundCorner?: boolean;
  selectionMode: string | number;
  onChange: any;
  style?: any;
  selectionColor?: string;
  option1?: any;
  option2?: any;
  option3?: any;
  tabswidth?: number | string;
  tabsheight?: number | string;
  padding_bottom?: number | string;
  margin_top?: number | string;
}

const ModalTabs = ({
  selectionMode,
  roundCorner = false,
  option1,
  option2,
  option3,
  selectionColor = colors.primaryColor,
  style,
  onChange,
  tabsheight = 0,
  padding_bottom = 9,
  margin_top = 14
}: Proptype) => {
  const [getRoundCorner, setRoundCorner] = useState(roundCorner);

  const updatedSwitchData = (val: any) => {
    onChange(val);
  };

  return (
    <View style={style}>
      <View
        style={[
          styles.container,
          {
            borderRadius: getRoundCorner ? 25 : 0,
            borderColor: selectionColor,
            height: tabsheight ? tabsheight : 44,
            marginTop: margin_top
          },
        ]}>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitchData(1)}
          style={[
            {
              borderColor: selectionMode == 1 ? selectionColor : 'white',
              borderBottomWidth: 3,
              borderRadius: getRoundCorner ? 25 : 0,
              paddingBottom: padding_bottom,
              marginRight: 32,
              paddingHorizontal: 5,
            },
          ]}>
          <NormalTextSelect
            color={selectionMode == 1 ? colors.primaryColor : colors.secondaryText}
            value={option1}
            fontWeight={fontWeight[600]}
          />
        </TouchableOpacity>

        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitchData(2)}
          style={[
            {
              borderColor: selectionMode == 2 ? selectionColor : 'white',
              borderRadius: getRoundCorner ? 25 : 0,
              borderBottomWidth: 3,
              paddingBottom: padding_bottom,
              marginRight: 32,
              paddingHorizontal: 5,
            },
          ]}>
          <NormalTextSelect
            color={selectionMode == 2 ? colors.primaryColor : colors.secondaryText}
            value={option2}
            fontWeight={fontWeight[600]}
          />
        </TouchableOpacity>

        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitchData(3)}
          style={[
            {
              borderColor: selectionMode == 3 ? selectionColor : 'white',
              borderRadius: getRoundCorner ? 25 : 0,
              borderBottomWidth: 3,
              paddingBottom: padding_bottom,
              paddingHorizontal: 5,
            },
          ]}>
          <NormalTextSelect
            color={selectionMode == 3 ? colors.primaryColor : colors.secondaryText}
            value={option3}
            fontWeight={fontWeight[600]}
          />
        </TouchableOpacity>
      </View>

      <BottomBorder style={{ marginTop: -2 }} borderwidth={0.5} color={colors.deviderGrey} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});

export default ModalTabs;
