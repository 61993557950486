import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import AddBankDetailsForm from '../../../../form-json/payment/add-bank-account-details.json';
import Element from '../../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components/native';

const AddBankDetails = (props: any) => {
  const {
    errors,
    setError,
    control,
    handleFileRemove,
    fileObjects,
    handleFilesAttach,
    clearErrors
  } = props;
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    if (AddBankDetailsForm) {
      setElements(AddBankDetailsForm[0]);
    }
  }, []);
  return (
    <View>
      <View>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View>
              <Element
                key={i}
                field={field}
                control={control}
                errors={errors}
                file={fileObjects[field.id]}
                handleFilesAttach={handleFilesAttach(field.id)}
                handleFileRemove={handleFileRemove(field.id)}
                setError={setError}
                clearErrors={clearErrors}
              />
            </View>
          ))
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )}
      </View>
    </View>
  );
};

const SpinnerView = styled.View`
  width: 450px;
  height: 547px;
  justify-content: center;
  align-items: center;
`;

export default AddBankDetails;
