import { gql, useMutation } from '@apollo/client';

const requestResetPasswordMutation = gql`
  mutation ($emailId: String!) {
    requestResetPassword(emailId: $emailId)
  }
`;

const resetPasswordMutation = gql`
  mutation ($payload: ResetPasswordInput!) {
    resetPassword(payload: $payload) {
      message
      userType
    }
  }
`;

export function useRequestResetPassword() {
  const [requestResetPassword, { data: requestResetPaswordData }] = useMutation(
    requestResetPasswordMutation,
  );
  return { requestResetPassword, requestResetPaswordData };
}

export function useResetPassword() {
  const [resetPassword, { data: resetPasswordData }] = useMutation(resetPasswordMutation);
  return { resetPassword, resetPasswordData };
}
