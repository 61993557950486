import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components';
import formJSON from '../../../../../form-json/control-panels/other-payment.json';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useI18n } from 'src/i18n/hooks';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { getMerchantOptions } from 'src/components/services';
import {
  Control,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form/dist/types';
import { View } from 'react-native';

interface Props {
  control: Control<FieldValues, object>;
  errors: { [x: string]: any };
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  paymentRecipient: string;
  setPaymentRecipient: Dispatch<SetStateAction<string>>;
  handleFileUpload: (e: any) => void;
  importAttachment: {
    documentName?: String;
    document?: File | undefined;
  };
  handleImportAttachment: Dispatch<
    SetStateAction<{ documentName?: String | undefined; document?: File | undefined }>
  >;
}

export default function OtherPaymentForm(props: Props) {
  const {
    control,
    errors,
    setError,
    clearErrors,
    setValue,
    paymentRecipient,
    setPaymentRecipient,
    handleFileUpload,
    importAttachment,
    handleImportAttachment,
  } = props;
  const { t } = useI18n();
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements;

  useEffect(() => {
    loadAccountNames();
    setElements(formJSON[0]);
    return () => {
      resetField([2, 4]);
    };
  }, []);

  async function loadAccountNames() {
    const merchOptions = await getMerchantOptions();
    const formToUpdate = { ...formJSON[0] };
    formToUpdate.fields[2].option = merchOptions?.data;
    setElements(formToUpdate);
  }

  function resetField(idx: number[]) {
    idx.forEach((item: number) => {
      formJSON[0].fields[item].hideVisibility = true;
      formJSON[0].fields[item].required = false;
      formJSON[0].fields[item].required_error = '';
    });
  }

  function handleCustomSelect(id: string, data: any) {
    if (id === 'payment_recipient') {
      if (data) {
        setPaymentRecipient(data.value);
        if (data.value === 'Select Audience') {
          handleImportAttachment({});
          formJSON[0].fields[4].hideVisibility = false;
          formJSON[0].fields[4].required = true;
          formJSON[0].fields[4].required_error = 'AMOUNT_REQUIRED';
        } else {
          setValue('amount', null);
          resetField([4]);
        }
      } else {
        setPaymentRecipient('');
        setValue('amount', null);
        handleImportAttachment({});
        resetField([4]);
      }
      const formElements = { ...formJSON[0] };
      setElements(formElements);
    }

    if (id === 'payment_mode') {
      if (data && data.length > 0) {
        if (data.some((item: any) => item?.value === 'DIGITAL')) {
          formJSON[0].fields[2].hideVisibility = false;
          formJSON[0].fields[2].required = true;
          formJSON[0].fields[2].required_error = 'MERCHANT_ACCOUNT_REQUIRED';
        } else {
          setValue('merchant_account', null);
          resetField([2]);
        }
      } else {
        setValue('merchant_account', null);
        resetField([2]);
      }
      const formElements = { ...formJSON[0] };
      setElements(formElements);
    }
  }

  return (
    <View>
      {fields ? (
        <>
          {fields.map((field: any, i: number) => {
            return (
              !field?.hideVisibility && (
                <Element
                  key={`selection${i}`}
                  field={field}
                  control={control}
                  errors={errors}
                  handleCustomSelect={handleCustomSelect}
                  dynamicValidation={false}
                  isCustomDisable={false}
                  setError={setError}
                  clearErrors={clearErrors}
                />
              )
            );
          })}

          {paymentRecipient && paymentRecipient === 'Import via Excel' && (
            <InputWrapper>
              <>
                <input
                  style={{ display: 'none' }}
                  id="importAudience"
                  multiple
                  type="file"
                  accept=".csv,.xls,.xlsx"
                  onChange={handleFileUpload}
                />

                <label htmlFor="importAudience">
                  <SecondaryBtn
                    label={t('selectFile.text')}
                    onPress={() => {}}
                    width={150}
                    style={{ height: 32 }}
                  />
                </label>
              </>

              <DocumentNameWrapper>
                <MediumTextSelect value={importAttachment?.documentName} />
              </DocumentNameWrapper>
            </InputWrapper>
          )}
        </>
      ) : (
        <FormSpinnerView>
          <LoaderSpinner />
        </FormSpinnerView>
      )}
    </View>
  );
}



const FormSpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DocumentNameWrapper = styled.div`
  margin: 5px;
`;
