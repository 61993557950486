import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { readCacheQuery, writeCacheQuery } from '../util';

const updateInstituteStudentDetailMutation = gql`
  mutation ($payload: UpdateInstituteStudentDetailInput!, $remove: RemoveInstituteStudentDetailInput) {
    updateInstituteStudentDetail(payload: $payload, remove: $remove) {
      status
      message
    }
  }
`;

const deleteStudentAcademicDetailMutation = gql`
  mutation ($remove: RemoveInstituteStudentDetailInput!) {
    updateInstituteStudentDetail(remove: $remove) {
      status
      message
    }
  }
`;

const instituteStudentQuery = gql`
  query ($studentId: String!) {
    instituteStudent(studentId: $studentId) {
      id
      firstName
      lastName
      academic {
        rollNo
        division
        class {
          id
          name
          alias
          course {
            id
            name
            alias
          }
        }
      }
      totalOnboardingCompletion
      onboardingCompletion {
        personalDetails
        educationDetails
        otherDetails
        addressDetails
        parentDetails
      }
      educationDetails {
        id
        course
        institute
        institution
        udiseNo
        qualificationRef {
          id
          name
          level
        }
        paassingYear
        university
        gradeObtained
        totalMarks
        marksObtained
        averageMarks
        streams {
          value: id
          label: name
        }
        documents {
          id
          name: documentName
          uri: documentUrl
          type
          category
        }
      }
    }
  }
`;

const studentPersonalInformationQuery = gql`
  query($studentId: String!) {
    instituteStudent(studentId: $studentId) {
      id
      userName
      firstName
      lastName
      motherDetail
      fatherDetail
      accountId
      doj
      academic {
        batch {
          id
        }
        rollNo
        division
        class {
          id
          name
          alias
          course {
            id
            name
            alias
          }
        }
        academicSection
        admissionDate
        admissionQuota
        house
        grNo
      }
      draftTotalOnboardingCompletion
      totalOnboardingCompletion
      onboardingCompletion {
        personalDetails
        educationDetails
        otherDetails
        addressDetails
        parentDetails
      }
      institute {
        type
        name
      }
      parentDetails {
        fatherDetail {
          id
          status
          name
          occupation
          name
          qualification
          dob
          email
          mobileNumber
          aadhaarId
          officeAddress
          annualIncome
          allDocument {
            type {
              typeId: id
              name
              label: name
              category
              defaultUserSelectionRequired
            }
            document {
              id
              category
              name: documentName
              uri: documentUrl
              fileExtension
              fileType
              typeRef
            }
          }
          documents {
            id
            uri: documentUrl
            name: documentName
            type
          }
          draft {
            detail {
              id
              name
              occupation
              name
              qualification
              dob
              email
              mobileNumber
              aadhaarId
              officeAddress
              annualIncome
              allDocument {
                type {
                  typeId: id
                  name
                  label: name
                  category
                  defaultUserSelectionRequired
                }
                document {
                  id
                  category
                  name: documentName
                  uri: documentUrl
                  fileExtension
                  fileType
                  typeRef
                }
              }
              documents {
                id
                uri: documentUrl
                name: documentName
                type
              }
            }
          }
        }
        motherDetail {
          id
          status
          name
          occupation
          name
          qualification
          dob
          email
          mobileNumber
          aadhaarId
          officeAddress
          annualIncome
          allDocument {
            type {
              typeId: id
              name
              label: name
              category
              defaultUserSelectionRequired
            }
            document {
              id
              category
              name: documentName
              uri: documentUrl
              fileExtension
              fileType
              typeRef
            }
          }
          documents {
            id
            uri: documentUrl
            name: documentName
            type
          }
          draft {
            detail {
              id
              name
              occupation
              name
              qualification
              dob
              email
              mobileNumber
              aadhaarId
              officeAddress
              annualIncome
              allDocument {
                type {
                  typeId: id
                  name
                  label: name
                  category
                  defaultUserSelectionRequired
                }
                document {
                  id
                  category
                  name: documentName
                  uri: documentUrl
                  fileExtension
                  fileType
                  typeRef
                }
              }
              documents {
                id
                uri: documentUrl
                name: documentName
                type
              }
            }
          }
        }
      }
      profilePicture {
        id
        documentUrl
        documentName
        category
        type
      }
      personalDetails {
        status
        id
        firstName
        lastName
        middleName
        fullName
        gender
        dob
        placeOfBirth
        bloodGroup
        maritalStatus
        mobileNumber
        alternateContactNumber
        alternateContactPerson
        email
        alternateEmail
        profilePicture {
          id
          documentUrl
          documentName
          category
          type
        }
        draft {
          detail {
            id
            firstName
            lastName
            middleName
            fullName
            gender
            dob
            placeOfBirth
            bloodGroup
            maritalStatus
            mobileNumber
            alternateContactNumber
            alternateContactPerson
            email
            alternateEmail
            profilePicture {
              id
              documentUrl
              documentName
              category
              type
            }
          }
        }
      }
      educationDetails {
        passedInFirstAttempt
        status
        id
        course
        streams {
          value: id
          label: name
        }
        gradeObtained
        paassingYear
        institution
        udiseNo
        qualificationRef {
          id
          name
          level
        }
        marksObtained
        totalMarks
        averageMarks
        university
        allDocument {
          type {
            typeId: id
            name
            label: name
            category
            defaultUserSelectionRequired
          }
          document {
            id
            category
            name: documentName
            uri: documentUrl
            fileExtension
            fileType
            typeRef
          }
        }
        documents {
          id
          uri: documentUrl
          name: documentName
          type
        }
        draft {
          id
          detail {
            passedInFirstAttempt
            status
            id
            course
            streams {
              value: id
              label: name
            }
            gradeObtained
            paassingYear
            institution
            udiseNo
            qualificationRef {
              id
              name
              level
            }
            marksObtained
            totalMarks
            university
            allDocument {
              type {
                typeId: id
                name
                label: name
                category
                defaultUserSelectionRequired
              }
              document {
                id
                category
                name: documentName
                uri: documentUrl
                fileExtension
                fileType
                typeRef
              }
            }
            documents {
              id
              uri: documentUrl
              name: documentName
              type
            }
          }
        }
      }
      otherDetails {
        status
        id
        category {
          value: id
          label: name
          alias
        }
        caste
        religion
        isPhysicallyChallenged
        physicallyChallengedDescription
        aadhaarId
        saralId
        abcId
        nationality
        motherTongue
        languageSpoken
        allDocument {
          type {
            typeId: id
            name
            label: name
            category
            defaultUserSelectionRequired
          }
          document {
            id
            category
            name: documentName
            uri: documentUrl
            fileExtension
            fileType
            typeRef
          }
        }
        documents {
          id
          uri: documentUrl
          name: documentName
          type
        }
        draft {
          detail {
            id
            aadhaarId
            saralId
            abcId
            category {
              value: id
              label: name
              alias
            }
            caste
            religion
            isPhysicallyChallenged
            physicallyChallengedDescription
            nationality
            motherTongue
            languageSpoken
            allDocument {
              type {
                typeId: id
                name
                label: name
                category
                defaultUserSelectionRequired
              }
              document {
                id
                category
                name: documentName
                uri: documentUrl
                fileExtension
                fileType
                typeRef
              }
            }
            documents {
              id
              uri: documentUrl
              name: documentName
              type
            }
          }
        }
      }
      addressDetails {
        current {
          status
          id
          area
          houseNo
          street
          zipcode
          city
          state
          draft {
            id
            detail {
              id
              area
              houseNo
              street
              zipcode
              city
              state
            }
          }
        }
        permanent {
          status
          id
          area
          houseNo
          street
          zipcode
          city
          state
          draft {
            id
            detail {
              id
              area
              houseNo
              street
              zipcode
              city
              state
            }
          }
        }
        isSameAsCurrent
      }
      status
    }
  }
`;

const studentAddressInformationQuery = gql`
  query ($studentId: String!) {
    instituteStudent(studentId: $studentId) {
      id
      currentAddressDetail {
        id
        area
        houseNo
        street
        zipcode
        city
        state
      }
      permanentAddressDetail {
        id
        area
        houseNo
        street
        zipcode
        city
        state
      }
    }
  }
`;

const studentParentInformationQuery = gql`
  query ($studentId: String!) {
    instituteStudent(studentId: $studentId) {
      id
      motherDetail {
        id
        name
        dob
        mobileNumber
        email
        qualification
        occupation
      }
      fatherDetail {
        id
        name
        dob
        mobileNumber
        email
        qualification
        occupation
      }
    }
  }
`;

const studentOtherDetailsQuery = gql`
  query ($studentId: String!) {
    instituteStudent(studentId: $studentId) {
      id
      otherDetails {
        id
        category {
          value: id
          label: name
          alias
        }
        caste
        religion
        isPhysicallyChallenged
        allDocument {
          type {
            typeId: id
            name
            label: name
            category
          }
          document {
            id
            category
            name: documentName
            uri: documentUrl
            fileExtension
            fileType
            typeRef
          }
        }
        documents {
          id
          uri: documentUrl
          name: documentName
          type
        }
      }
    }
  }
`;

export {
  updateInstituteStudentDetailMutation,
  instituteStudentQuery,
  studentPersonalInformationQuery,
  studentAddressInformationQuery,
  studentParentInformationQuery,
  studentOtherDetailsQuery,
};

export function useUpdateInstituteStudent() {
  const [updateInstituteStudentDetail, { loading, error: updateInstituteStudentDetailError }] =
    useMutation(updateInstituteStudentDetailMutation);
  return { updateInstituteStudentDetail, updateInstituteStudentDetailError };
}

export function useDeleteStudentAcademicDocuments() {
  const [deleteStudentAcademicDocuments, { error: deleteStudentAcademicDocumentsError }] =
    useMutation(deleteStudentAcademicDetailMutation);
  return { deleteStudentAcademicDocuments, deleteStudentAcademicDocumentsError };
}

export function useDeleteStudentAcademicDetail(studentId: string = '') {
  const [deleteStudentAcademicDetail, { error: deleteStudentAcademicDetailError }] = useMutation(
    deleteStudentAcademicDetailMutation,
  );
  return { deleteStudentAcademicDetail, deleteStudentAcademicDetailError };
}
