import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { useLocation, useParams } from 'react-router-dom';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import MediumText from 'src/components/atoms/Text/MediumText';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { height } from 'src/constant/device';
import { useHeaderTitle } from 'src/contexts/header-context';
import { getConcessionCompositionsQuery } from 'src/graphql/payment';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import { clipLongText } from 'src/utils/utility';
import styled from 'styled-components/native';

const ConcessionDetails = (props: any) => {
  const { concessionId }: any = useParams();
  const location = useLocation();
  const { setHeading } = useHeaderTitle();
  const { t } = useI18n();
  const [moduleData, setModuleData] = useState<any | null>(null);
  const [concessionDetails, setConcessionDetails] = useState<any | null>(null);

  const { data: concessionsCompositionData } = useQuery(getConcessionCompositionsQuery, {
    variables: { limit: 0, filter: { id: { eq: concessionId } } },
  });

  const headers = [
    {
      name: t('moduleGroupName.label'),
    },
    {
      name: t('amount.label').toUpperCase(),
    },
    {
      name: t('concession.label').toUpperCase(),
    },
    {
      name: t('payableAmount.label').toUpperCase(),
    },
  ];

  useEffect(() => {
    if (concessionsCompositionData) {
      customizeData();
    }
  }, [concessionsCompositionData]);

  function customizeData() {
    const data = concessionsCompositionData?.getConcessionCompositions?.data;
    const filteredData = data.filter((item: any) => item.id === concessionId);
    if (data.length > 0) {
      let totalConcession = 0;
      let totalAmount = 0;
      data[0].concessions.forEach((concession: any) => {
        totalConcession += concession.amount;
        totalAmount += concession.feeBook.amount;
      });
      setConcessionDetails(() => ({
        totalConcession,
        totalAmount,
        totalPayableAmount: totalAmount - totalConcession,
        name: data[0].name,
        feecomposition: data[0].concessions[0].feeBook?.compositionName,
        payers: data[0]?.payers,
        payerType: data[0]?.payerType,
      }));
    } else {
      setConcessionDetails(() => null);
    }

    const arr: any = [];

    const createObj = (newItem: any) => {
      return {
        groupId: newItem.feeBook.groupId,
        groupName: newItem.feeBook.groupName,
        amount: newItem.feeBook.amount,
        concession: newItem.amount,
        groupInfo: [
          {
            feebook: newItem.feeBook.id,
            name: newItem.feeBook.fee.name,
            amount: newItem.feeBook.fee.amount,
            concession: newItem.amount,
          },
        ],
      };
    };

    const obj: any = {};
    data[0].concessions.forEach((concessionInfo: any) => {
      if (!obj[concessionInfo.feeBook.groupId]) {
        obj[concessionInfo.feeBook.groupId] = createObj(concessionInfo);
      } else {
        obj[concessionInfo.feeBook.groupId].amount += concessionInfo.feeBook.amount;
        obj[concessionInfo.feeBook.groupId].concession += concessionInfo.amount;
        obj[concessionInfo.feeBook.groupId].groupInfo.push({
          feebook: concessionInfo.feeBook.id,
          name: concessionInfo.feeBook.fee.name,
          amount: concessionInfo.feeBook.fee.amount,
          concession: concessionInfo.amount,
        });
      }
    });

    for (const element in obj) {
      arr.push(obj[element]);
    }

    setModuleData(() => (filteredData.length > 0 ? arr : null));
  }

  async function setTitle() {
    const data: any = location.state;
    setHeading([
      {
        text: t('payment.label'),
        url: '/control-panel/payments',
      },
      {
        text: t('concession.label'),
        url: `/payments/concession/limit/${data?.limit ? data?.limit : 50}/page/${data.page ? data.page : 1
          }`,
      },
      {
        text: concessionDetails?.name,
      },
    ]);
  }

  useEffect(() => {
    if (concessionDetails) {
      setTitle();
    }
  }, [concessionDetails]);
  if (!moduleData) {
    return (
      <LoadContentWrapper>
        <LoadSpinnerWrapper>
          <LoaderSpinner />
        </LoadSpinnerWrapper>
      </LoadContentWrapper>
    );
  }
  return (
    <LoadContentWrapper>
      <>
        <MainHeaderWrapper>
          <MainHeader>
            <NormaltextAtom value={t('concessionName.label')} lineHeight={2} />
            <MediumTextSelect
              value={concessionDetails ? concessionDetails?.name : ''}
              color={colors.primaryText}
              fontWeight={'600'}
            />
          </MainHeader>
          <MainHeader>
            <NormaltextAtom value={t('feesComposition.label')} lineHeight={2} />
            <MediumTextSelect
              value={concessionDetails ? concessionDetails?.feecomposition : ''}
              color={colors.primaryText}
              fontWeight={'600'}
            />
          </MainHeader>
          <MainHeader>
            <NormaltextAtom
              value={t('total.label') + ' ' + t('concession.label')}
              lineHeight={2}
            />
            <MediumTextSelect
              value={concessionDetails ? concessionDetails?.totalConcession : 0}
              color={colors.primaryText}
              fontWeight={'600'}
            />
          </MainHeader>
        </MainHeaderWrapper>
        <FlexRowView>
          <TableWrapper>
            <MediumTextSelect
              value={t('modules.label')}
              fontSize={2.4}
              lineHeight={3.2}
              fontWeight={'600'}
            />
            <HeadersWrapper>
              {headers.map(({ name }, index: number) => (
                <TableColHeadWrapper index={index}>
                  <TableColHead value={name} />
                </TableColHeadWrapper>
              ))}
            </HeadersWrapper>
            <SingleLine marginBottom={10} />
            <FlatList
              data={moduleData}
              contentContainerStyle={styles.flatListStyle}
              keyExtractor={(item: any) => item.id}
              renderItem={({ item, index }: any) => (
                <Container marginBottom={moduleData.length - 1 === index ? 10 : 24}>
                  <FlexRowWrapper>
                    <MainStartElement>
                      <MediumText
                        lineHeight={2.4}
                        color={colors.primaryText}
                        value={clipLongText(item.groupName, 28)}
                      />
                    </MainStartElement>
                    <MainElement>
                      <MediumTextSelect
                        color={colors.primaryText}
                        fontWeight={'600'}
                        value={`\u20B9 ${item.amount}`}
                      />
                    </MainElement>
                    <MainElement>
                      <MediumTextSelect
                        color={colors.primaryText}
                        fontWeight={'600'}
                        value={`\u20B9 ${item.concession}`}
                      />
                    </MainElement>
                    <MainElement>
                      <MediumTextSelect
                        color={colors.primaryText}
                        fontWeight={'600'}
                        value={`\u20B9 ${item.amount - item.concession}`}
                      />
                    </MainElement>
                  </FlexRowWrapper>
                  {item.groupInfo.length > 0 && (
                    <RowWrapper>
                      {item.groupInfo.map((component: any) => (
                        <ComponentsWrapper key={component.feebook}>
                          <ComponentsStartElement>
                            <SubModuleText>
                              <NormalTextSelect
                                color={colors.secondaryText}
                                value={clipLongText(component.name, 24)}
                              />
                            </SubModuleText>
                          </ComponentsStartElement>
                          <ComponentsElement>
                            <NormalTextSelect
                              color={colors.secondaryText}
                              value={`\u20B9 ${component.amount}`}
                            />
                          </ComponentsElement>
                          <ComponentsElement>
                            <NormalTextSelect
                              color={colors.secondaryText}
                              value={`\u20B9 ${component.concession}`}
                            />
                          </ComponentsElement>
                          <ComponentsElement>
                            <NormalTextSelect
                              color={colors.secondaryText}
                              value={`\u20B9 ${component.amount - component.concession}`}
                            />
                          </ComponentsElement>
                        </ComponentsWrapper>
                      ))}
                    </RowWrapper>
                  )}
                </Container>
              )}
            />
            <SingleLine />
            <FooterWrapper>
              <ComponentsStartElement>
                <NormalTextSelect value={t('totalPayment.label')} />
              </ComponentsStartElement>
              <ComponentsElement>
                <NormalTextSelect
                  fontWeight={'600'}
                  value={
                    concessionDetails ? `\u20B9 ${concessionDetails.totalAmount}` : `\u20B9 ${0}`
                  }
                />
              </ComponentsElement>
              <ComponentsElement>
                <NormalTextSelect
                  fontWeight={'600'}
                  value={
                    concessionDetails
                      ? `\u20B9 ${concessionDetails.totalConcession}`
                      : `\u20B9 ${0}`
                  }
                />
              </ComponentsElement>
              <ComponentsElement>
                <NormalTextSelect
                  fontWeight={'600'}
                  value={
                    concessionDetails
                      ? `\u20B9 ${concessionDetails.totalPayableAmount}`
                      : `\u20B9 ${0}`
                  }
                />
              </ComponentsElement>
            </FooterWrapper>
            <SingleLine />
          </TableWrapper>
          {concessionDetails && concessionDetails.payerType === 'INDIVIDUAL' && (
            <ConcessionWrapper>
              <MediumTextSelect
                value={t('students.label')}
                fontSize={2.4}
                lineHeight={3.2}
                fontWeight={'600'}
              />
              <FlatList
                data={concessionDetails.payers}
                contentContainerStyle={styles.concessionFlatListStyle}
                keyExtractor={(item: any) => item.id}
                renderItem={({ item, index }: any) => (
                  <>
                    <ConcessionText>
                      <MediumTextSelect
                        color={colors.primaryText}
                        value={(index + 1).toString() + '. ' + item.firstName + ' ' + item.lastName}
                        fontSize={1.6}
                        lineHeight={2.4}
                      />
                    </ConcessionText>
                    {index !== concessionDetails.payers.length - 1 && <SingleLine />}
                  </>
                )}
              />
            </ConcessionWrapper>
          )}
        </FlexRowView>
      </>
    </LoadContentWrapper>
  );
};

const styles = {
  flatListStyle: {
    flex: 1,
    maxHeight: height - 450,
    minHeight: height - 450,
  },
  concessionFlatListStyle: {
    flex: 1,
    maxHeight: height - 450,
  }
}

const Container = styled.View<{ marginBottom: number }>`
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const FlexRowView = styled.View`
  flex-direction: row;
`;

const MainElement = styled.View`
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;

const MainStartElement = styled.View`
  flex: 1.5;
  justify-content: center;
  align-items: flex-start;
`;

const ComponentsElement = styled.View`
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;

const ComponentsWrapper = styled.View`
  flex-direction: row;
  margin-top: 24px;
`;

const ComponentsStartElement = styled.View`
  flex: 1.5;
  justify-content: center;
  align-items: flex-start;
`;

const SingleLine = styled.View`
  border-bottom-width: 1;
  border-bottom-color: ${colors.dividerColor};
  margin-bottom: ${(props: any) => (props.marginBottom ? props.marginBottom : 0)};
`;

const FooterWrapper = styled.View`
  flex-direction: row;
  padding-horizontal: 20;
  margin-vertical: 18;
`;

const TableColHeadWrapper = styled.View`
  flex: ${(props: any) => (props.index === 0 ? 1.5 : 1)};
  justify-content: center;
  align-items: ${(props: any) => (props.index === 0 ? 'flex-start' : 'flex-end')};
`;

const HeadersWrapper = styled.View`
  margin-top: 16;
  flex-direction: row;
  margin-bottom: 10;
  marginhorizontal: 20;
`;

const MainHeader = styled.View`
  margin-right: 100;
`;

const SubModuleText = styled.View`
  margin-left: 32px;
`;

const ConcessionWrapper = styled.View`
  flex: 0.45;
`;

const MainHeaderWrapper = styled.View`
  flex-direction: row;
  margin-bottom: 32;
  margin-top: 14;
`;
const LoadSpinnerWrapper = styled.View`
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const TableWrapper = styled.View`
  margin-right: 40px;
  flex: 0.6;
  max-width: 65%;
`;

const FlexRowWrapper = styled.View`
  padding-right: 20px;
  flex-direction: row;
`;

const RowWrapper = styled.View`
  padding-right: 20px;
`;

const ConcessionText = styled.View`
  padding-left: 16px;
`;

export default ConcessionDetails;
