import { gql, useLazyQuery } from "@apollo/client";

import { graphqlQuery } from "../util";

const getAllBatchesQuery = gql`
  query (
    $filters: BatchFilterInput
    $limit: Int
    $skip: Int
    $sort: BatchSortInput
    $lookup: LookupFilter
  ) {
    batches(
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
      lookup: $lookup
    ) {
      data {
        id
        name
        alias
        class {
          id
          name
          alias
        }
      }
    }
  }
`;

export function useGetAllBatches() {
  const [getAllBatches, { data: allBatchesData, loading: allBatchesLoading }] =
    useLazyQuery(getAllBatchesQuery, { fetchPolicy: "network-only" });
  return {
    getAllBatches,
    allBatchesData,
    allBatchesLoading,
  };
}

const unAssignedStudentsQuery = gql`
  query (
    $batchId: String!
    $filters: InstituteStudentFilterInput
    $limit: Int
    $skip: Int
    $sort: PInstituteStudentSortInput
  ) {
    unAssignedStudents(
      batchId: $batchId
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
    ) {
      hasNextPage
      totalCount
      data {
        id
        personalDetails {
          id
          firstName
          lastName
          gender
          fullName
        }
        academic {
          averageMarks
          rollNo
          division
        }
      }
    }
  }
`;

export function useGetUnAssignedStudents() {
  const [
    getUnAssignedStudents,
    { data: unAssignedStudentsData, refetch: unAssignedStudentsRefetch },
  ] = useLazyQuery(unAssignedStudentsQuery, { fetchPolicy: "network-only" });
  return {
    getUnAssignedStudents,
    unAssignedStudentsData,
    unAssignedStudentsRefetch,
  };
}

const batchesToPromoteQuery = gql`
  query($currentBatch: String!, $academicYear: String) {
    batchesToPromote(currentBatch: $currentBatch, academicYear: $academicYear) {
      label: alias
      value: id
      class {
        id
        alias
      }
      divisionDetails {
        division
      }
    }
  }
`;

export async function getBatchesToPromote(
  currentBatch: string,
  academicYear: string
) {
  const { data: filteredBatchData } = await graphqlQuery(
    batchesToPromoteQuery,
    {
      currentBatch: currentBatch,
      academicYear: academicYear,
    },
    false
  );
  if (filteredBatchData?.batchesToPromote) {
    const batches = filteredBatchData.batchesToPromote;
    return { options: batches };
  } else {
    return { options: [] };
  }
}