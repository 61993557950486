import React, { useState, useEffect } from 'react';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import { useForm } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import AcademicYearForm from 'src/components/molecules/Academics/AyearForm.web';
import {
  useAddAcademicYear,
  useQueryAcademicYear,
  useUpdateAcademicYear,
} from 'src/graphql/academics/years';
import { ERROR, SUCCESS, USER_TYPE, limit } from 'src/constant/index';
import { useI18n } from 'src/i18n/hooks';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, createSortFilter, debounce } from 'src/components/services';
import { getSubjectGroupFilter } from 'src/components/services/filters';
import { ADD_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import ERROR_MSG from 'src/constant/error';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';
import { colors } from 'src/styles/theme/styles';
import { height } from 'src/constant/device';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components';
import { EditIcon } from 'src/components/atoms/ActionIcons';
import { useNavigate, useParams } from 'src/routes/routing.web';

const headCells1 = [
  {
    id: 'ayear_name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'name',
    isSorted: false,
  },
  {
    id: 'ayear_alias',
    label: 'alias.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'alias',
    isSorted: false,
  },
  {
    id: 'ayear_start',
    label: 'startDate.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'from',
    isSorted: false,
  },
  {
    id: 'ayear_end',
    label: 'endDate.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'to',
    isSorted: false,
  },
  {
    id: 'status',
    label: 'status.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: 'actions.label',
    align: 'right',
    disableSorting: true,
  },
];

const pathName = 'academics/years';

export default function AcademicYearWeb() {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm();
  const [canClick, setCanClick] = useState(true);

  let searchValue = '' as string;
  const { page = 1, dataLimit = limit }: any = useParams();

  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  const navigate = useNavigate();
  const { filters, setFilter } = useFilter();
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;

  const [modalState, handleModal] = useState(false);
  const [editAcademicYearData, setAcademicYearData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const { setAlertDetails } = useAlertSystem();
  if (filters && filters?.subject_group?.on) {
    searchValue = filters?.subject_group?.search;
  }
  const [searching, setSearchingSubjectGroup] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const {
    query: getAcademicYear,
    data: ayearData,
    refetch: reFetchAcademicYear,
    loading: academicYearLoading,
  } = useQueryAcademicYear();
  const rows = ayearData?.AcademicYears?.data ?? [];
  const { addAcademicYear, ErrorOccur } = useAddAcademicYear(currentPage, searchValue, rowLimit);
  const { updateAcademicYear } = useUpdateAcademicYear();

  const [totalCount, setTotalCount] = useState(ayearData?.AcademicYears?.totalCount ?? null);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    const count = ayearData?.AcademicYears?.totalCount;
    if (count) {
      setTotalCount(count);
    }
  }, [ayearData?.AcademicYears?.totalCount]);

  useEffect(() => {
    setSearchingSubjectGroup(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setHeading([{ text: t('academicYears.label') }]);
  }, []);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, headCells, rowLimit]);

  function handleFetch() {
    const sortCriteria = createSortFilter(headCells);
    const ayearFilter = createSearchFilter(
      rowLimit,
      (currentPage - 1) * rowLimit,
      null,
      sortCriteria,
    );
    getAcademicYear({ variables: ayearFilter });
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting: records } = useTable(rows, headCells);

  const editAcademicYearModal = (selectedAcademicYear: any) => {
    setAcademicYearData(selectedAcademicYear);
    setIsEditModal(true);
    handleModal(true);
  };

  const addAcademicYearGroupModal = () => {
    setIsEditModal(false);
    handleModal(true);
    resetForm();
  };

  const closeModal = () => {
    handleModal(false);
    resetForm();
  };

  function resetForm() {
    reset({});
    clearErrors();
  }

  const handleAddAcademicYear = async (AcademicYearData: any) => {
    setCanClick(false);
    try {
      if (parseInt(AcademicYearData.ayear_end) < parseInt(AcademicYearData.ayear_start)) {
        throw new Error(t('error.endYear.invalid'));
      } else {
        const ayearInput: any = {
          name: AcademicYearData.ayear_name,
          alias: AcademicYearData.ayear_alias,
          from: AcademicYearData.ayear_start,
          to: AcademicYearData.ayear_end,
        };

        if (AcademicYearData.previous_ayear) {
          ayearInput.cloneDatesFrom = AcademicYearData.previous_ayear.value;
        }

        const getResponse = await addAcademicYear({ variables: { payload: ayearInput } });
        if (getResponse.data && !ErrorOccur) {
          closeModal();
          setAlertDetails({ message: ADD_SUCCESS.YEARS, level: SUCCESS });
          if (reFetchAcademicYear) reFetchAcademicYear();
        } else {
          setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
        }
        setCanClick(true);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateAcademicYear = async (AcademicYearData: any) => {
    setCanClick(false);
    try {
      if (parseInt(AcademicYearData.ayear_end) < parseInt(AcademicYearData.ayear_start)) {
        throw new Error(t('error.endYear.invalid'));
      } else {
        const ayearInput = {
          id: editAcademicYearData?.value,
          name: AcademicYearData.ayear_name,
          alias: AcademicYearData.ayear_alias,
          from: AcademicYearData.ayear_start,
          to: AcademicYearData.ayear_end,
          status: AcademicYearData.ayear_status.value,
        };
        const respose = await updateAcademicYear({
          variables: { payload: ayearInput },
        });
        if (respose.data) {
          closeModal();
          if (reFetchAcademicYear) reFetchAcademicYear();
          setAlertDetails({ message: UPDATE_SUCCESS.YEARS, level: SUCCESS });
        }
        setCanClick(true);
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  return (
    <LoadContentWrapper>
      {userType === USER_TYPE.EMPLOYEE && <Section>
        <SecondaryBtn label={t('addAcademicYear.text')} onPress={addAcademicYearGroupModal} />
      </Section>}
      <TblContainer height={'100%'}>
        <TblHead setHeadCells={setHeadCells} />
        <>
          {!academicYearLoading ? (
            <>
              {records()?.length > 0 ? (
                <TableBody>
                  {records()?.map((item: any, i: number) => (
                    <TableRows key={i}>
                      <TableCells
                        value={item?.label}
                        color={colors.primary}
                        clickable={true}
                        onPress={() => {
                          navigate(`/academics/years/${item.value}/batch/limit/10/page/1`);
                        }}
                      />
                      <TableCells value={item?.alias} />
                      <TableCells value={item?.from} />
                      <TableCells value={item?.to} />
                      <TableCells
                        value={item?.status}
                        color={item?.status === 'ACTIVE' ? colors.green : colors.red}
                      />

                      <TableActions align="right">
                        <IconWrapper>
                          <EditIcon tooltipTitle={t("editAcademicYear.text")} onPress={() => editAcademicYearModal(item)} />
                        </IconWrapper>
                      </TableActions>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>

      <Pagination
        pathName={pathName}
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        setRowLimit={setRowLimit}
        hidePagination={totalCount <= rowLimit}
      />
      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        width={578}
        height={520}
        modalVisible={modalState}
        Headerpopup={isEditModal ? t('editAcademicYear.text') : t('addAcademicYear.text')}
        handleSave={handleSubmit(isEditModal ? handleUpdateAcademicYear : handleAddAcademicYear)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}>
        <AcademicYearForm
          control={control}
          errors={errors}
          setValue={setValue}
          isEditModal={isEditModal}
          editAcademicYearData={editAcademicYearData}
          reset={reset}
          watch={watch}
        />
      </NormalModal>
    </LoadContentWrapper>
  );
}

const Section = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
