import { NoRecordsFound, TableCells, TableRows } from "./TableAtom";

import { DropdownOptions } from "src/types";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import React from "react";
import SearchDropdown from "src/components/atoms/AutoComplete/index.web";
import { TableBody } from "@mui/material";
import { ViewMode } from "../Forms/types";
import { height } from "src/constant/device";
import styled from "styled-components";
import useTable from "./UseTable";

export type TStudentUnassignedDiv = {
  fullName?: string;
  gender?: string;
  averageMarks?: number;
  subjectGroup: { name: string };
  id: string;
  division?: string;
};

type Props = {
  viewMode: ViewMode;
  data: TStudentUnassignedDiv[];
  loading: boolean;
  divisionOptions: DropdownOptions[];
  formData: Record<string, string | null>;
  setFormData: React.Dispatch<
    React.SetStateAction<Record<string, string | null>>
  >;
};

export function PromoteToDivisionTable(props: Props) {
  const { viewMode, data, loading, divisionOptions, formData, setFormData } =
    props;
  const { TblContainer, TblHead } = useTable(data, headCells);

  const handleChange = (div: DropdownOptions | null, id: string) => {
    setFormData((prev) => ({ ...prev, [id]: div?.value ?? null }));
  };

  const getExistingValue = (id: string) => {
    if (formData?.[id]) {
      return { label: formData[id], value: formData[id] };
    }
  };

  return (
    <TblContainer height={"85%"}>
      <TblHead />
      <>
        {!loading ? (
          <>
            {data?.length > 0 ? (
              <TableBody>
                {data.map((item) => (
                  <TableRows key={item.id}>
                    <TableCells value={item?.fullName ?? "-"} />
                    <TableCells value={item?.gender ?? "-"} />
                    <TableCells
                      value={item?.averageMarks || "-"}
                      align={"right"}
                      style={styles.avgScoreCell}
                    />
                    <TableCells value={item?.subjectGroup?.name || "-"} />
                    {viewMode === ViewMode.EDIT ? (
                      <TableCells align={"right"}>
                        <Wrapper>
                          <SearchDropdown
                            options={divisionOptions}
                            onChange={(
                              _: any,
                              newValue: DropdownOptions | null
                            ) => handleChange(newValue, item.id)}
                            value={getExistingValue(item.id)}
                            width={200}
                          />
                        </Wrapper>
                      </TableCells>
                    ) : (
                      <TableCells value={item?.division || "-"} />
                    )}
                  </TableRows>
                ))}
              </TableBody>
            ) : (
              <NoRecordsFound colspan={5} maxHeight={0.5 * height} />
            )}
          </>
        ) : (
          <SpinnerWrapper>
            <LoaderSpinner />
          </SpinnerWrapper>
        )}
      </>
    </TblContainer>
  );
}

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 60%; // Optically-centered
  left: 50%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const styles = {
  avgScoreCell: {
    paddingRight: "12.5%",
  },
  divisionSelectionCell: {
    paddingBottom: 4,
  },
};

const headCells = [
  {
    id: "studentName",
    label: "studentName.label",
    style: { width: "32.5%" },
  },
  {
    id: "gender",
    label: "gender.label",
    style: { width: "10%" },
  },
  {
    id: "averageScore",
    label: "averageScore.label",
    align: "right",
    style: { width: "25%", paddingRight: "12.5%" },
  },
  {
    id: "subjectGroup",
    label: "subjectGroup.label",
    style: { width: "25%" },
  },
  {
    id: "division",
    label: "division.label",
    style: { width: "5%" },
  },
];
