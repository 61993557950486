import { TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import {
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { createSortFilter } from 'src/components/services';
import { ERROR, limit } from 'src/constant';
import { height } from 'src/constant/device';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'src/routes/routing.web';
import styled from 'styled-components';
import { useGetLogs } from '../../../graphql/logs';
import LogsForm from 'src/components/molecules/Logs';
import { DropdownOptionsType } from 'src/types';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { properCase } from 'src/utils/utility';
import { useAlertSystem } from 'src/contexts/web-alert-context';

const headCells1 = [
  {
    id: 'requestId',
    label: 'requestId.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'user',
    label: 'user.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'module',
    label: 'module.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'status',
    label: 'status.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'timestamp',
    label: 'timestamp.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'timestamp',
    isSorted: false,
  },
  {
    id: 'device',
    label: 'device.label',
    align: 'left',
    disableSorting: true,
  },
];

export default function Logs() {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { setAlertDetails } = useAlertSystem();
  const [userType, setUserType] = useState<string>('');

  let maxHeight = userType === 'Student' ? height - 460 : height - 400;
  const paginationHeightOffset = 30;

  let row;
  const { page = 1, dataLimit = limit }: any = useParams();
  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const [headCells, setHeadCells] = useState(headCells1);
  const { getLogs, logsData, logsError, logsLoading, refetchLogs } = useGetLogs();
  const [currentFilter, setCurrentFilter] = useState({});

  const [totalCount, setTotalCount] = useState<number|null>(logsData?.ActivityAudits?.totalCount ?? null);
  
  useEffect(() => {
    const count = logsData?.ActivityAudits?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [logsData?.ActivityAudits?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    handleFetch(currentFilter);
  }, [currentPage, headCells, rowLimit]);

  function setTitle() {
    setHeading([
      {
        text: t('logs.label'),
        url: '',
      },
    ]);
  }

  function handleFetch(filters: any) {
    if (filters && Object.keys(filters).length > 0) {
      const logsFilter = {
        limit: limit,
        skip: (page - 1) * rowLimit,
        filters: filters,
        sort: createSortFilter(headCells),
      }
      getLogs({ variables: logsFilter });
    }
  }

  row = logsData?.ActivityAudits?.data;

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
    null, null, false, true, null,
    paginationHeightOffset
  );

  const retrieveValues = (formData: any) => {
    if (formData?.startDate || formData?.endDate) {
      if ((formData?.startDate && !formData?.endDate) || (formData?.endDate && !formData?.startDate)) {
        setAlertDetails({ message: t('error.logsDate.text'), level: ERROR });
        return;
      }
    }
    let modules;
    if (formData?.modules && formData.modules.length > 0) {
      modules = formData.modules.map((item: DropdownOptionsType) => item.value);
    }

    const logsFilter = {
      institute: { eq: formData?.institute?.value },
      ...(formData?.startDate && formData?.endDate ? { timestamp: { lte: formData.endDate, gte: formData.startDate } } : {}),
      ...(formData?.userType ? { userType: { eq: formData?.userType?.value } } : {}),
      ...(formData?.employee || formData?.student ? { user: { eq: formData?.employee?.value || formData?.student?.value } } : {}),
      ...(modules && modules.length > 0 ? { module: { in: modules } } : {}),
      ...(formData?.action ? { action: { eq: formData?.action?.value } } : {}),
    }
    setCurrentFilter(logsFilter);
    handleFetch(logsFilter);
  };

  return (
    <>
      <LoadContentWrapper style={styles.loadContent}>
        <TopRowsWrapper>
          <SelectionWrapper>
            <LogsForm
              retrieveValues={retrieveValues}
              isLoading={logsLoading}
              setUserType={setUserType}
            />
          </SelectionWrapper>
        </TopRowsWrapper>

        <TableDiv>
          <TblContainer height={maxHeight}>
            {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 && (
              <>
                <TblHead setHeadCells={setHeadCells} />
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                    <TableRows key={i}>
                      <TableCells value={item?.requestId || '-'} />
                      <TableCells value={properCase(item?.user?.personalDetails?.fullName || '-')} />
                      <TableCells value={item?.module || '-'} />
                      <TableCells value={item?.action || '-'} />
                      <TableCells value={item?.status || '-'} />
                      <TableCells value={item?.timestamp ? format(new Date(item.timestamp), DT.DATE_FORMAT_SLASH) : "-"} />
                      <TableCells value={item?.device || '-'} />
                    </TableRows>
                  ))}
                </TableBody>
              </>
            )}
            {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length === 0 && (
              <TableRows style={{ height: maxHeight }}>
                <TableCells colspan={7} align="center" style={{ borderBottom: 'none' }}>
                  <NormalTextSelect value={'No records found'} />
                </TableCells>
              </TableRows>
            )}
          </TblContainer>
          {totalCount && totalCount > 0 ? (
            <Pagination
              pathName={'logs'}
              count={Math.ceil(totalCount / rowLimit)}
              total={totalCount}
              page={page}
              rowLimit={rowLimit}
              hidePagination={!!(totalCount && totalCount <= rowLimit)}
            />
          ) : null}
        </TableDiv>

        {logsLoading && 
          <LoadSpinnerWrapper>
            <LoaderSpinner />
          </LoadSpinnerWrapper>
        }
      </LoadContentWrapper>
    </>
  );
}

const styles = {
  loadContent: { minHeight: 665, height: height - 116 },
};

const TopRowsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10;
`;

const SelectionWrapper = styled.div`
  width: 100%;
  max-width: 1152px;
`;

const LoadSpinnerWrapper = styled.div`
  justify-content: center;
  align-items: center;
  height: 450px;
`;