import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={17 * scale}
      height={16 * scale}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M14.007 0h-12c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h3v2.625a.374.374 0 00.597.3L9.507 13h4.5c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2zm1 11c0 .55-.45 1-1 1H9.172l-.265.2-2.9 2.175V12h-4c-.55 0-1-.45-1-1V2c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v9zm-11-5.25a.748.748 0 00-.75.75c0 .415.334.75.75.75.415 0 .75-.335.75-.75a.748.748 0 00-.75-.75zm4 0a.748.748 0 00-.75.75c0 .415.334.75.75.75.415 0 .75-.335.75-.75a.748.748 0 00-.75-.75zm4 0a.748.748 0 00-.75.75c0 .415.334.75.75.75.415 0 .75-.335.75-.75a.748.748 0 00-.75-.75z"
        fill="#000"
        fillOpacity={0.6}
      />
    </Svg>
  );
}

export default SvgComponent;
