import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getAllReservationsQuery = gql`
  query ($limit: Int, $skip: Int, $filters: ReservationsFilterInput) {
    reservations(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
      }
    }
  }
`;

export const getReservationsQuery = gql`
  query ($filters: ReservationsFilterInput, $limit: Int, $skip: Int, $sort: ReservationsSortInput) {
    reservations(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        name
        reservations {
          caste {
            id
            name
          }
          reservePercentage
        }
      }
      hasNextPage
      totalCount
    }
  }
`;

export const createReservationMutation = gql`
  mutation ($payload: ReservationsInput!) {
    createReservations(payload: $payload) {
      id
      name
      reservations {
        caste {
          id
          name
        }
        reservePercentage
      }
    }
  }
`;

export function useGetReservations() {
  const [
    getReservations,
    { loading: reservationsLoading, data: reservationsData, error: reservationsError, refetch: refetchReservations },
  ] = useLazyQuery(getReservationsQuery, { fetchPolicy: 'network-only' });
  return { getReservations, reservationsData, reservationsError, reservationsLoading, refetchReservations };
}

export function useCreateReservation() {
  const [createReservation, { data: createReservationData, error: createReservationError }] =
    useMutation(createReservationMutation);
  return { createReservation, createReservationData, createReservationError };
}
