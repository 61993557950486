import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { colors } from 'src/styles/theme/styles';

interface noDatatype {
  icon?: any;
  label?: any;
  size?: number;
}

export function NoNewsNotices(props: noDatatype) {
  const { icon, label, size = 40 } = props;
  return (
    <View>
      <View style={[styles.iconWrapper]}>
        <FontAwesomeIcon icon={icon} size={size} color={colors.tertiaryText} />
      </View>
      <View style={[styles.commonStyles]}>
        <HeaderThree
          value={label}
          color={colors.secondaryText}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  iconWrapper: { justifyContent: 'center', alignItems: 'center' },
  commonStyles: { marginTop: 8, marginBottom: 20 },
});
