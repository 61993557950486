import React from 'react';
import { StyleSheet } from 'react-native';
import { fontWeight, colors, breakpoints } from 'src/styles/theme/styles';
import ImageAtom from '../ImageAtom';
import NormalTextSelect from '../Text/NormalTextSelect';
import SmallTextAtom from '../Text/SmallTextAtom';
import { assigneeObjectType } from 'src/components/organism/Assignments/types';
import styled from 'styled-components/native';
import { isNative, width } from 'src/constant/device';
import HeaderThree from '../Text/HeaderThree';
import HeaderFour from '../Text/HeaderFour';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { properCase } from 'src/utils/utility';

interface propType {
  style?: object;
  border?: boolean;
  studentDetail: assigneeObjectType;
  isSubmitted: boolean;
  asignLable?: string;
  isList?: boolean;
}

export default function UserCard(props: propType) {
  const {
    style,
    border = true,
    studentDetail,
    isSubmitted,
    asignLable = 'Submitted',
    isList = false,
  } = props;
  const isMobile = width < breakpoints.md ? true : false;
  let docuUrl = studentDetail?.profilePicture?.documentUrl || '';

  return (
    <Wrapper border={border} isList={isList}>
      {docuUrl && docuUrl !== 'https://dev-api.edba.io/personal/photo/default' ? (
        <ImageAtom
          //   cahceMode={true}
          style={[
            styles.imgStype,
            style,
            { height: isList ? 40 : 48, width: isList ? 40 : 48 },
          ]}
          source={{
            uri: docuUrl,
          }}
        />
      ) : (
        <DefaultProfilePicView>
          <Icon name="profile-pic" size={isList ? 40 : 48} />
        </DefaultProfilePicView>
      )}
      <NameView>
        {!isList && (
          <>
            {isMobile ? (
              <TitleView>
                <HeaderThree
                  value={properCase(studentDetail?.personalDetails?.fullName)}
                  color={colors.primaryText}
                  fontWeight={fontWeight[600]}
                  lineHeight={32}
                  numberOfLines={2}
                  align={'left'}
                />
              </TitleView>
            ) : (
              <HeaderFour
                value={properCase(studentDetail?.personalDetails?.fullName)}
                color={colors.primaryText}
                fontWeight={fontWeight[400]}
                lineHeight={3}
              />
            )}
          </>
        )}

        {isList && (
          <NormalTextSelect
            value={properCase(studentDetail?.personalDetails?.fullName)}
            color={colors.secondaryText}
            fontWeight={fontWeight[600]}
            lineHeight={2}
          />
        )}

        {isSubmitted ? (
          <SmallTextAtom value={asignLable} color={colors.green} fontWeight={fontWeight[600]} />
        ) : null}
      </NameView>
    </Wrapper>
  );
}

const styles = StyleSheet.create({
  imgStype: {
    borderRadius: 40,
    borderWidth: 2,
    overflow: 'hidden',
    borderColor: colors.white,
    marginRight: 4,
  },
});

const Wrapper = styled.View<{ border: boolean, isList: boolean }>`
  flex-direction: row;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-width: ${(props) => (props.border ? 1 : 0)}px;
  border-color: ${colors.inputBorder};
  align-items: ${(props) => props?.isList ? 'center' : 'flex-start'};
`;

const NameView = styled.View`
  margin-left: 8px;
  align-self: center;
  width: 100%;
`;

const DefaultProfilePicView = styled.View`
  margin-right: 4px;
`;

const TitleView = styled.View`
  width: 82%;
`;