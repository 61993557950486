import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import React from 'react';
import CasteCategoryTable from '../../Table/CasteCategoryTable';

export default function CasteCategoryTbl(props: UseFieldApiConfig) {
  const {
    subscribeToValues,
    headCells,
    tableData,
    maxHeight,
    height,
    setData,
    data,
    options,
  } = props;
  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <CasteCategoryTable
          header={headCells}
          tabledata={tableData}
          maxHeightTable={maxHeight}
          height={height}
          setData={setData}
          options={options}
        />
      )}
    </FormSpy>
  );
}
