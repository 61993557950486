import Chip from ".";
import NormaltextAtom from "../Text/NormalTextAtom";
import React from "react";
import styled from "styled-components/native";

interface PropType {
  chipsArray: string[];
  chipFontSize?: number;
}
export default function MultiChipsDisplay(props: PropType) {
  const { chipsArray, chipFontSize } = props;
  return (
    <Wrapper>
      {chipsArray?.length > 0 ? (
        chipsArray.map((chip: string) => {
          return (
            <Chip label={chip} close={false} height={24} chipFontSize={chipFontSize ? chipFontSize : 1.2} />
          );
        })
      ) : (
        <NormaltextAtom value={"-"} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;
