import React, { Dispatch, SetStateAction } from "react";
import { addWeeks, subWeeks } from "date-fns";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";

import DateTimePicker from "../../../atoms/DateTime/DateTimePicker.web";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import NormalTextSelect from "../../../atoms/Text/NormalTextSelect";
import SecondaryBtn from "../../../atoms/Button/SecondaryButton";
import { Tooltip } from "@mui/material";
import { colors } from "src/styles/theme/styles";
import { getSelectedDuration } from "src/components/organism/Schedule/scheduleHelpers";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";

type ScheduleToolbarProps = {
  pickedDate: Date;
  setPickedDate: Dispatch<SetStateAction<Date>>;
  buttonProps?: {
    onButtonClick: () => void;
    text: string;
  };
};

function ScheduleToolbar(props: ScheduleToolbarProps) {
  const { pickedDate, setPickedDate, buttonProps } = props;
  const { t } = useI18n();

  const onDatePick = (selectedDate: Date) => {
    setPickedDate(selectedDate);
  };

  const onPreviousClick = () => {
    const previousWeek = subWeeks(pickedDate, 1);
    setPickedDate(previousWeek);
  };

  const onNextClick = () => {
    const upcomingWeek = addWeeks(pickedDate, 1);
    setPickedDate(upcomingWeek);
  };

  return (
    <Container>
      <DateModifiers>
        <NormalTextSelect
          value={getSelectedDuration(pickedDate)}
          lineHeight={2.4}
          fontsize={1.8}
        />
        <DateTimePicker
          initialValue={pickedDate}
          dateTimeMode={"date"}
          onDateTimeChangeCallback={(selectedDate: Date) => {
            onDatePick(selectedDate);
          }}
          standalonePicker={true}
          containerWidth={"24px"}
        />

        <Tooltip title={t("previous.label")}>
          <ControlHandler onClick={() => onPreviousClick()}>
            <Control icon={faChevronLeft} color={colors.secondaryText} />
          </ControlHandler>
        </Tooltip>
        <Tooltip title={t("next.label")}>
          <ControlHandler onClick={() => onNextClick()}>
            <Control icon={faChevronRight} color={colors.secondaryText} />
          </ControlHandler>
        </Tooltip>
      </DateModifiers>
      {!!buttonProps && (
        <SecondaryBtn
          onPress={buttonProps.onButtonClick}
          label={t(buttonProps.text)}
          lines={1}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateModifiers = styled(Container)`
  gap: 8px;
`;

const ControlHandler = styled(Container)`
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid ${colors.borderColor};
  cursor: pointer;
`;

const Control = styled(FontAwesomeIcon)``;

export default ScheduleToolbar;
