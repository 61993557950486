import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { graphqlQuery } from 'src/graphql/util';
import { mergeBatchClassLabel, properCase } from 'src/utils/utility';
import selectionForm from 'src/form-json/logs/logs.json';
import { useForm } from 'react-hook-form';
import { useHistory } from 'src/routes/routing.web';
import { useI18n } from 'src/i18n/hooks';
import { getFilteredAcademicYears, getFilteredAllInstitute } from 'src/components/services';
import { getUsersQuery } from 'src/graphql/control-panels/user';
import { getStudentsQuery } from 'src/graphql/classes';
import { userTypeOptions, moduleOptions, actionOptions } from './options';

interface LogsFormProps {
  retrieveValues: (formData: any) => void;
  isLoading: boolean;
  setUserType?: any;
}

export default function LogsForm(props: LogsFormProps) {
  const { rem }: any = useTheme();
  const [elements, setElements] = useState<any>({});
  const [currentInstitute, setCurrentInstitute] = useState<string>('');
  const { retrieveValues, isLoading, setUserType } = props;
  const { fields }: any = elements;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  useEffect(() => {
    clearValues(['userType', 'modules', 'action']);
    onLoadFunction();
    return () => {
      selectionForm[0].fields[2].dateTimePickerMinDate = '';
      clearDropdown([4, 5, 6, 7], true, true);
    }
  }, []);

  async function onLoadFunction() {
    let instituteResponse = await getFilteredAllInstitute({ limit: 0 });
    if (instituteResponse?.options) {
      setElements({});
      selectionForm[0].fields[0].option = instituteResponse.options;
      selectionForm[0].fields[3].option = userTypeOptions;
      selectionForm[0].fields[8].option = moduleOptions;
      selectionForm[0].fields[9].option = actionOptions;
      setElements(selectionForm[0]);
    }
  }

  function clearValues(fields: string[]) {
    fields.forEach((item: string) => {
      setValue(item, null);
    });
  }

  function clearDropdown(fields: number[], clearFieldVisible: boolean, clearOption: boolean) {
    const newData = { ...selectionForm[0] };
    fields.forEach((item: number) => {
      if (clearFieldVisible) {
        newData.fields[item].fieldVisible = false;
      }
      if (clearOption) {
        newData.fields[item].option = [];
      }
    });
    setElements(newData);
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === 'institute') {
      clearValues([
        'userType',
        'employee',
        'academicYear',
        'batch',
        'student',
        'modules',
        'action',
      ]);
      setUserType('');
      clearErrors();
      clearDropdown([4, 5, 6, 7], true, true);

      if (data?.value) {
        setCurrentInstitute(data?.value);
      } else {
        setCurrentInstitute('');
      }
    }

    if (id === 'userType') {
      setUserType(data?.label || '');
      clearValues(['employee', 'academicYear', 'batch', 'student']);
      clearDropdown([4, 5, 6, 7], true, true);

      if (data?.value) {
        const filter = {
          limit: 0,
          filters: {
            institute: {
              eq: currentInstitute,
            },
          },
        };
        if (data?.value === 'INSTITUTE_EMPLOYEE') {
          const newData = { ...selectionForm[0] };
          newData.fields[4].fieldVisible = true;
          setElements(newData);
          if (currentInstitute) {
            const instituteEmployees = await graphqlQuery(getUsersQuery, filter, false);
            const options = instituteEmployees?.data?.instituteEmployees?.data?.map(
              (item: any) => ({
                label: properCase(item?.personalDetails?.fullName),
                value: item?.id,
              }),
            );
            if (options) {
              selectionForm[0].fields[4].option = options;
              setElements(selectionForm[0]);
            }
          }
        } else {
          const newData = { ...selectionForm[0] };
          newData.fields[5].fieldVisible = true;
          newData.fields[6].fieldVisible = true;
          newData.fields[7].fieldVisible = true;
          setElements(newData);
          if (currentInstitute) {
            const academicYearResponse = await getFilteredAcademicYears(filter);
            if (academicYearResponse?.options) {
              const newData = { ...selectionForm[0] };
              newData.fields[5].option = academicYearResponse.options;
              setElements(newData);
            }
          }
        }
      }
    }

    if (id === 'academicYear') {
      clearValues(['batch', 'student']);
      clearDropdown([6, 7], false, true);

      if (data?.value) {
        const batchFilter = {
          limit: 0,
          filters: {
            academicYear: {
              eq: data.value,
            },
          },
        };
        let { data: allBatches, loading } = await graphqlQuery(getBatchQuery, batchFilter, false);
        if (!loading && allBatches?.batches?.data) {
          let options = mergeBatchClassLabel(allBatches?.batches?.data);
          const newData = { ...selectionForm[0] };
          newData.fields[6].option = options;
          setElements(newData);
        }
      }
    }

    if (id === 'batch') {
      clearValues(['student']);
      clearDropdown([7], false, true);

      if (data?.value) {
        const studentFilter = {
          limit: 0,
          filters: {
            batch: { eq: data?.value },
            class: { eq: data?.class?.id },
          },
        };
        const studensData = await graphqlQuery(getStudentsQuery, studentFilter, false);
        const studentOptions = studensData?.data?.instituteStudents?.data?.map((item: any) => ({
          label: properCase(item?.fullName),
          value: item?.id,
        }));

        const newData = { ...selectionForm[0] };
        newData.fields[7].option = studentOptions;
        setElements(newData);
      }
    }

    if (id === 'startDate') {
      clearValues(['endDate']);
      const newData = { ...selectionForm[0] };
      newData.fields[2].dateTimePickerMinDate = data;
      setElements(newData);
    }
  }

  return fields ? (
    <LogsFormWrapper>
      {fields &&
        fields.map((field: any, i: number) => {
          return field?.fieldVisible ? (
            <FormWrapper key={`s${i}`} idx={i}>
              <Element
                key={`selection${i}`}
                field={field}
                control={control}
                errors={errors}
                handleCustomSelect={handleCustomSelect}
                dynamicValidation={false}
                isCustomDisable={false}
                setError={setError}
                clearErrors={clearErrors}
                limitTags={1}
              />
            </FormWrapper>
          ) : null;
        })}

      <SecondaryBtn
        canLoad={isLoading}
        label={t('search.label')}
        onPress={handleSubmit(retrieveValues)}
        width={rem(8)}
        style={{ height: 36, marginTop: rem(2) }}
        secondary={false}
      />
    </LogsFormWrapper>
  ) : (
    <FormSpinnerView>
      <LoaderSpinner />
    </FormSpinnerView>
  );
}

const LogsFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FormWrapper = styled.div<{ idx: number }>`
  flex-direction: row;
  z-index: ${(props) => (props.idx == 1 ? 51 : 50)};
  margin-right: 20px;
  width: 250px;
`;

const FormSpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.rem(75)};
  height: 163px;
`;
