import React from 'react';
import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import styled from 'styled-components';
import LabelValueView from 'src/components/molecules/LabelValueView/index.web';
import { getLabel } from 'src/utils/utility';

const FieldView: React.FC<UseFieldApiConfig> = (props: UseFieldApiConfig) => {
  const field = useFieldApi(props)
  let value = field.input.value;
  // TODO: remove this logic once this get solved from api.
  if (field.input.name == "isPhysicallyChallenged") {
    value = value ? 'Yes' : 'No';
  }
  return <ContentContainer>
    <LabelValueView
      width={'100%'}
      marginBottom={'10px'}
      label={field.label}
      value={field?.options ? getLabel(value, field?.options) : value}
    />
  </ContentContainer>
}

export default FieldView;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  margin-right: 16px;
`