import { DeleteIcon, EditIcon } from "../ActionIcons";
import React, { CSSProperties } from "react";

import MediumText from "../Text/MediumText";
import SmallTextAtom from "../Text/SmallTextAtom";
import { colors } from "src/styles/theme/styles";
import styled from "styled-components/native";

interface PropType {
  inputContainerWidth: CSSProperties["width"];
  headerLabel?: string;
  selectedValueLabel: string;
  displayText: string;
  textColor: string;
  showEdit?: boolean;
  showDelete?: boolean;
  canClick?: boolean;
  disabled?: boolean;
  onPress?: () => void;
  onDelete?: () => void;
}

const TriggerSelector = (props: PropType) => {
  const {
    inputContainerWidth,
    headerLabel,
    showEdit = true,
    showDelete = true,
    canClick = true,
    disabled = false,
    onPress,
    onDelete = () => { },
    selectedValueLabel,
    displayText,
    textColor,
  } = props;
  return (
    <InputContainer width={inputContainerWidth}>
      {headerLabel && (
        <HeaderWrapper>
          <SmallTextAtom value={headerLabel} color={colors.secondaryText} />
        </HeaderWrapper>
      )}
      <Selection
        width={inputContainerWidth}
        disabled={disabled || !canClick}
        onPress={disabled ? () => { } : onPress}
        label={selectedValueLabel}
        showEdit={showEdit}
      >
        <MediumText
          align={"left"}
          value={displayText}
          color={textColor}
          opacity={disabled ? 0.5 : 1}
        />
        {!disabled && !!selectedValueLabel && (
          <IconView>
            {showEdit && <EditIcon onPress={onPress} />}
            {showDelete && <DeleteIcon onPress={onDelete} />}
          </IconView>
        )}
      </Selection>
    </InputContainer>
  );
};

const InputContainer = styled.View<{ width: CSSProperties["width"] }>`
  margin-bottom: 0px;
  width: ${({ width }) => width};
`;

const HeaderWrapper = styled.View`
  margin-bottom: 4px;
`;

const Selection = styled.TouchableOpacity<{
  width: CSSProperties["width"];
  label: string;
  showEdit: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  width: ${({ width }) => width};
  height: 40px;
  border: ${({ label }) =>
    label
      ? `1px dashed ${colors.tertiaryText}`
      : `1px dashed ${colors.tertiaryText}`};
  border-radius: 4px;
  cursor: ${({ disabled, label, showEdit }) =>
    !disabled && (!showEdit || !label) ? "pointer" : "inherit"};
`;

const IconView = styled.View`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export default TriggerSelector;
