import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={16 * scale}
      height={16 * scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M15.93 6.776L8.628.889a.996.996 0 00-1.256 0L.07 6.776a.186.186 0 00-.028.264l.395.486a.19.19 0 00.266.028l1.075-.867v7.758a.445.445 0 00.444.445h11.556a.445.445 0 00.444-.445V6.69l1.078.87a.19.19 0 00.267-.029l.394-.486a.199.199 0 00-.031-.27zM6.667 14V9.556h2.666v4.445H6.667zm6.666 0h-3.11V9.11a.444.444 0 00-.445-.444H6.222a.444.444 0 00-.444.445V14H2.667V5.97L7.88 1.765a.184.184 0 01.233 0l5.22 4.208V14z"
        fill="#000"
        fillOpacity={0.6}
      />
    </Svg>
  );
}

export default SvgComponent;
