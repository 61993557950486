import {
  DashboardColOneThird,
  DashboardMainContainer,
} from "../Common/CommonHelpers";
import React, { useEffect, useState } from "react";

import DashboardAssignments from "../Common/DashboardAssignments.web";
import DashboardMainBanner from "src/components/molecules/DashboardBanner";
import DashboardNotices from "../Common/DashboardNotices.web";
import DashboardSchedule from "../Common/DashboardSchedule.web";
import InfoModalDetail from "./InfoModalDetail.web";
import { InfoModalObject } from "./types";
import MobileHeader from "src/components/hoc/MobileHeader";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { StudentDashboardMobile } from "./StudentDashboardMobile.web";
import { handlePendingPaymentData } from "./util";
import { isMobile } from "src/constant/device";
import { properCase } from "src/utils/utility";
import styled from "styled-components";
import { useGetPendingPayment } from "src/graphql/payment";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { useNavigate } from "src/routes/routing.web";
import { useProfileDetail } from "src/contexts/profile-detail-context";
import { userInformation } from "src/utils/manageState";

export const DashboardStudent = () => {
  const navigate = useNavigate();
  const { setHeading } = useHeaderTitle();
  const currentUserObject = userInformation();
  const { userProfileDetails: basicInformation } = useProfileDetail();
  const { t } = useI18n();
  const [infoModal, setInfoModal] = useState<InfoModalObject>({
    visibility: false,
    type: "",
  });
  const [className, setClassName] = useState<string>("");
  const [feeType, setFeeType] = useState<string>("");

  const { getPendingPayment, pendingPaymentData, pendingPaymentLoading } =
    useGetPendingPayment();

  useEffect(() => {
    setTitle();
    getPendingPayment();
  }, []);

  useEffect(() => {
    if (pendingPaymentData && pendingPaymentData?.pendingPayment) {
      handlePendingPaymentData(
        pendingPaymentData.pendingPayment,
        setInfoModal,
        setClassName,
        setFeeType,
      );
    }
  }, [pendingPaymentData]);

  function setTitle() {
    setHeading([{ text: t("dashboard.label"), url: "" }]);
  }

  function closeInfoModal() {
    setInfoModal({ visibility: false, type: "" });
    setClassName("");
  }

  function handleProceedInfoModal() {
    closeInfoModal();
    const paymentUrl =
      feeType === 'admission' ? '/application/list/limit/50/page/1' : '/payments/pending';
    setFeeType('');
    navigate(paymentUrl);
  }

  return (
    <>
      {isMobile && <MobileHeader label="" backIcon={false} />}
      <Container>
        {!isMobile ? (
          <>
            <DashboardMainBanner
              userType={currentUserObject?.userType}
              image={
                basicInformation?.personalDetails?.profilePicture
                  ?.documentUrl || currentUserObject?.userDetail?.photo
              }
              bannerTitleHello={t("hello.label")}
              fullName={properCase(
                currentUserObject?.userDetail?.fullName ?? ""
              )}
              className={basicInformation?.academic?.class?.alias}
              division={basicInformation?.academic?.division}
              bannerTitleId={t("rollno.label")}
              id={currentUserObject?.userDetail?.rollNo}
            />
            <Row>
              <ColumnOneThird>
                <DashboardSchedule />
              </ColumnOneThird>
              <ColumnOneThird>
                <DashboardAssignments />
              </ColumnOneThird>
              <ColumnOneThird>
                <DashboardNotices />
              </ColumnOneThird>
            </Row>

            <NormalModal
              setModalVisible={closeInfoModal}
              Headerpopup={
                infoModal?.type == "Payment"
                  ? t("feesPaymentPending.label")
                  : t("congratulations.label")
              }
              modalVisible={infoModal.visibility}
              handleSave={handleProceedInfoModal}
              cancelButtonLabel={"cancel.label"}
              addEditButtonLabel={"proceed.label"}
              width={550}
              maxWidth={"md"}
            >
              <InfoModalDetail type={infoModal?.type} className={className} />
            </NormalModal>
          </>
        ) : (
          <StudentDashboardMobile />
        )}
      </Container>
    </>
  );
};

const Row = styled.div`
  display: flex;
  margin: 7px ${(props) => props.theme.rem(-1.5)} 0;
  flex-wrap: wrap;
`;

const ColumnOneThird = styled(DashboardColOneThird)`
  padding: ${({ theme }) => theme.rem([0, 1.5])};
`;

const Container = styled(DashboardMainContainer)`
  margin-top: ${({ theme }) => theme.rem(1.2)};
`;
