import React from 'react';
import { useThemeSystem } from 'src/contexts/theme-context';
import { colors, fontWeight } from 'src/styles/theme/styles';
import ExtraSmallTextAtom from '../Text/ExtraSmallText';
import HeaderThree from '../Text/HeaderThree';
import styled from 'styled-components/native';
import NormalTextSelect from '../Text/NormalTextSelect';
import { isNative } from 'src/constant/device';
import { DT, parseToDate } from 'src/constant/dateTime';
import { format } from 'date-fns';

interface propType {
  fullDate: string;
}

export default function EventDate(props: propType) {
  const { fullDate } = props;
  const { theme } = useThemeSystem();

  const parsedDate = parseToDate(fullDate);
  const formattedDate = parsedDate && format(parsedDate, DT.DATE_ONLY);
  const monthName = parsedDate && format(parsedDate, DT.MONTH_ABBREV);
  const weekDayName = parsedDate && format(parsedDate, DT.DAY_OF_WEEK);

  const StyleView = styled.View`
    line-height: 23px;
    padding-bottom: 6px;
    text-transform: uppercase;
  `;

  return (
    <Container>
      <HeaderThree
        value={formattedDate ?? ''}
        color={theme?.events?.card?.textColor}
        fontWeight={fontWeight[600]}
        lineHeight={34}
        fontSize={isNative ? 26 : 24}
      />

      <StyleViewContainer>
        <StyleView>
          <ExtraSmallTextAtom
            value={weekDayName?.toUpperCase() || ''}
            color={theme?.events?.card?.textColor}
          />
        </StyleView>
      </StyleViewContainer>
      <DottedLineView />

      <MonthTextView>
        <NormalTextSelect
          value={monthName?.toUpperCase() || ''}
          align="center"
          color={theme?.events?.card?.textColor}
          fontWeight={fontWeight[600]}
        />
      </MonthTextView>
    </Container>
  );
}

const Container = styled.View`
  height: 80px;
  width: 80px;
  border-radius: 8px;
  background-color: #2b78ca;
  text-align: center;
  text-transform: uppercase;
`;

const StyleViewContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${isNative ? -3 : 0}px;
`;

const DottedLineView = styled.View`
  align-items: center;
  margin-bottom: 2px;
  border-bottom-color: ${colors.white};
  border-bottom-width: 1px;
  width: 70%;
  align-self: center;
`;

const MonthTextView = styled.View`
  padding-bottom: 5px;
`;
