import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { CSSProperties, useEffect, useState } from 'react';
import { colors } from 'src/styles/theme/styles';
import { getStorageFunction } from 'src/components/services/storage-service';
import { KEYS } from 'src/constant/key';
import { ERROR, FILE_ICONS, FILE_ICONS_COLOR, allowedTypes } from 'src/constant';
import { getExtention, getFileName } from 'src/components/services';
import NormaltextAtom from '../Text/NormalTextAtom';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { CustomReactNativeFile } from 'src/components/molecules/FileUpload/type';
import IndicatorBadge from '../Badge/IndicatorBadge';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { clipLongText } from 'src/utils/utility';

interface Props {
  fileData: CustomReactNativeFile;
  customStyle?: CSSProperties;
  showIndicatorBadge?: boolean;
  showDownloadIcon?: boolean;
  lines?: number;
  width?: CSSProperties["width"];
  maxChars?: number;
}

export default function DownloadFile(props: Props) {
  const {
    fileData,
    customStyle,
    showIndicatorBadge = false,
    showDownloadIcon = true,
    lines = 1,
    width = "100%",
    maxChars = 100, // sufficiently long file-name
  } = props;
  const { uri, alias }: any = fileData;
  const [fileIcon, setFileIcon] = useState<any>(FILE_ICONS.generic);
  const isDisabled = !allowedTypes.includes(fileData?.fileType);
  const { setAlertDetails } = useAlertSystem();
  useEffect(() => {
    checkFileExtension();
  }, [uri]);

  function checkFileExtension() {
    let exten: any = getExtention(fileData.name);
    if (FILE_ICONS[exten.toLocaleLowerCase()]) {
      setFileIcon(FILE_ICONS[exten.toLocaleLowerCase()]);
    }
  }

  const getProperExtension = (fileName: string) => {
    return fileName.split('.').pop();
  };

  async function viewDownloadFile(operation: string) {
    let token = await getStorageFunction(KEYS.ACCESS_TOKEN);
    const headers = new Headers();
    headers.set('authorization', `Bearer ${token}`);
    headers.set('mode', 'no-cors');
    headers.set('Access-Control-Allow-Origin', '*');
    let fileName = getFileName(fileData?.name);

    fetch(uri, { headers })
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          response.blob().then((blob) => {
            if (operation === 'view') {
              var myBlob = new Blob([blob], { type: fileData?.fileType });
              const fileUrl = URL.createObjectURL(myBlob);
              window.open(fileUrl, '_blank');
            } else {
              var mimetype = blob.type;
              const splitArray = mimetype.split('/');
              const fileExtension =
                splitArray[1] === 'octet-stream'
                  ? getProperExtension(fileData?.name || '.jpg')
                  : splitArray[1];
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = `${fileName}.${fileExtension}`;
              a.click();
            }
          });
        } else {
          response?.text().then((err: any) => {
            throw new Error(typeof err === 'string' ? JSON.parse(err) : err);
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        setAlertDetails({ message: error.message, level: ERROR });
      });
  }

  const displayName = alias || fileData?.type || fileData?.name || '';

  return (
    <Wrapper width={width}>
      <WrapperView
        viewFile={!isDisabled}
        onClick={() => isDisabled ? {} : viewDownloadFile('view')}>
        <FileView>
          {showIndicatorBadge &&
            <BadgeWrapper>
              <IndicatorBadge />
            </BadgeWrapper>
          }
          <FontAwesomeIcon icon={fileIcon} color={FILE_ICONS_COLOR[fileIcon?.iconName]} size={24} />
          <TextView style={customStyle ? customStyle : {}}>
            <NormaltextAtom
              value={clipLongText(displayName, maxChars)}
              lines={lines}
              fontWeight={400}
            />
          </TextView>
        </FileView>

        {showDownloadIcon && (
          <Tooltip title="Download">
            <DownloadTouchableView onClick={(e: any) => {
              e.stopPropagation();
              viewDownloadFile('download');
            }}>
              <FontAwesomeIcon icon={faDownload} color={colors.secondaryText} size={16} />
            </DownloadTouchableView>
          </Tooltip>
        )}
      </WrapperView>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ width: CSSProperties["width"] }>`
  margin-bottom: 15px;
  background-color: ${colors.white};
  margin-bottom: 16px;
  border-radius: 8px;
  width: ${({ width }) => width};
  padding-right: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const WrapperView = styled.div<{ viewFile: boolean }>`
  cursor: ${({ viewFile }) => (viewFile ? 'pointer' : 'inherit')};
  justify-content: space-between;
  display: flex;
`;

const FileView = styled.div`
  flex-direction: row;
  min-width: 90%;
  align-items: center;
  display: flex;
`;

const TextView = styled.div`
  flex: 1;
  margin-left: 8px;
  align-self: center;
  word-break: break-all;
`;

const DownloadTouchableView = styled.div`
  align-self: center;
  margin-left: 5px;
  cursor: pointer;
`;

const BadgeWrapper = styled.div`
  margin-left: -12px;
  margin-right: 4px;
`;