import React, { useEffect, useState } from "react";

import { DropdownOptionsType } from "src/types";
import Element from "src/components/molecules/Forms/ApplicationElement";
import FormJSON from "src/form-json/admission/transfer-form.json";
import styled from "styled-components/native";
import { useGetAdmissionSubjectGroupOptions } from "src/graphql/admission";

function TransferForm(props: any) {
  const { control, errors, setValue } = props;
  const {
    getAdmissionSubjectGroupOptions: getData,
    admissionSubjectGroupOptionsData: responseData,
    admissionSubjectGroupOptionsLoading: responseLoading,
  } = useGetAdmissionSubjectGroupOptions();

  useEffect(() => {
    customInitialReset();
    setElements(FormJSON[0]);
    getData();
  }, []);

  useEffect(() => {
    if (
      responseData &&
      responseData?.admissions &&
      responseData.admissions?.data
    ) {
      loadOptions(0, responseData.admissions.data);
    }
  }, [responseData]);

  function customInitialReset() {
    loadOptions(1, []);
    setValue("admission", null);
    setValue("subjectGroup", null);
  }

  function loadOptions(fieldIndex: number, options: DropdownOptionsType[]) {
    FormJSON[0].fields[fieldIndex].option = options;
    const newData = { ...FormJSON[0] };
    setElements(newData);
  }

  function handleCustomSelect(id: string, data: any) {
    if (id === "admission") {
      if (data) {
        const subjectGroupOptions = data?.subjectGroups?.map(
          (e: { subjectGroup: DropdownOptionsType }) => {
            return e?.subjectGroup;
          }
        );
        loadOptions(1, subjectGroupOptions);
      } else {
        setValue("subjectGroup", null);
        loadOptions(1, []);
      }
    }
  }

  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};
  return (
    <>
      {fields &&
        fields.map((field: any, i: number) => (
          <FieldWrapper key={`e${i}`}>
            <Element
              key={`e${i}`}
              field={field}
              control={control}
              errors={errors}
              maxDropdownOptionsHeight={200}
              handleCustomSelect={handleCustomSelect}
            />
          </FieldWrapper>
        ))}
    </>
  );
}

const FieldWrapper = styled.View``;

export default TransferForm;
