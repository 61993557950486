import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useQuery } from '@apollo/client';
import { useThemeSystem } from 'src/contexts/theme-context';
import { autoCompleteLimit, AUTOCOMPLETE_MODULE } from 'src/constant';
import classAudienceCommunication from 'src/form-json/communication/class-audience.json';
import Element from 'src/components/molecules/Forms/ApplicationElement';
import Chip from 'src/components/atoms/Chip';
import { isWeb } from 'src/constant/device';
import { DropdownOptionsType } from 'src/types';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { colors } from 'src/styles/theme/styles';
import { getFilteredSubjects } from 'src/components/services';
import { getModifiedSubjectsQuery } from 'src/graphql/academics/subjects';
import { clipLongText, mergeBatchClassLabel } from 'src/utils/utility';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form/dist/types';

interface ClassAudienceProps {
  setAudienceData: Function;
  audienceData: Array<string>;
  parentType: string;
  batchIds: Array<string>;
  control: Control<FieldValues, object>;
  setValue: UseFormSetValue<FieldValues>;
  errors: any;
  removeAudience: Function;
  handleChecked: Function;
  isEdit: boolean;
}

export default function ClassAudience(props: ClassAudienceProps) {
  const {
    setAudienceData,
    audienceData,
    parentType,
    control,
    setValue,
    errors,
    removeAudience,
    handleChecked,
    batchIds,
    isEdit,
  } = props;

  const { theme } = useThemeSystem();
  const [elements, setElements] = useState<any>({});
  const [batch, setBatch] = useState<any>({});
  const [batches, setBatches] = useState<any>({});
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState([]);
  const [filteredDivisions, setFilteredDivisions] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const { fields }: any = elements ?? {};
  const allBatches = useQuery(getBatchQuery, {
    variables: { limit: 0, skip: 0 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setValue(AUTOCOMPLETE_MODULE.SUBJECT, null);
    return () => {
      classAudienceCommunication[0].fields[1].fieldVisible = true;
      classAudienceCommunication[0].fields[1].required = true;
      classAudienceCommunication[0].fields[1].option = [];
      classAudienceCommunication[0].fields[0].required = true;
      classAudienceCommunication[0].fields[0].textHead = "batchClass.required.label";
    }
  }, []);

  useEffect(() => {
    onLoadFunction();
  }, [allBatches.data]);

  useEffect(() => {
    if (isEdit && audienceData?.length > 0) {
      classAudienceCommunication[0].fields[0].required = false;
      classAudienceCommunication[0].fields[0].textHead = "batchClass.label";
    } else {
      classAudienceCommunication[0].fields[0].required = true;
      classAudienceCommunication[0].fields[0].textHead = "batchClass.required.label";
    }
  }, [isEdit, audienceData]);

  function onLoadFunction() {
    if (allBatches.loading === false) {
      let getdata = [] as any;
      if (parentType === 'CONCESSION') {
        allBatches?.data?.batches?.data.forEach((element: any) => {
          if (batchIds.includes(element?.value)) {
            getdata.push(element);
          }
        });
        getdata = mergeBatchClassLabel(getdata);
      } else {
        getdata = mergeBatchClassLabel(allBatches.data?.batches?.data);
      }
      classAudienceCommunication[0].fields[0].option = getdata;

      if (parentType && parentType !== 'ASSIGNMENT') {
        classAudienceCommunication[0].fields[1].fieldVisible = false;
        classAudienceCommunication[0].fields[1].required = false;
      } else {
        classAudienceCommunication[0].fields[1].fieldVisible = true;
        classAudienceCommunication[0].fields[1].required = true;
      }
      setElements(classAudienceCommunication[0]);
      setBatches(getdata);
    }
  }

  function loadDivisions(divisionArray: DropdownOptionsType[]) {
    const division = [] as any;
    divisionArray?.forEach(function (item: any) {
      division.push({
        name: item,
        id: item
      })
    });
    setDivisions(division);
    setFilteredDivisions(division);
    setIsDataAvailable(true)
  }

  async function getSubjects(classId: string) {
    let subjectFilter = {
      filters: {
        class: {
          eq: classId,
        },
      },
      limit: 0,
    };
    const subjectsResponse = await getFilteredSubjects(
      subjectFilter,
      getModifiedSubjectsQuery
    )
    loadSubjects(subjectsResponse?.options);
  }

  function loadSubjects(subjectArray: any) {
    const modifiedData = { ...classAudienceCommunication[0] };
    modifiedData.fields[1].option = subjectArray;
    setElements(modifiedData);
  }

  useEffect(() => {
    if (batch && batch.class) {
      setTimeout(() => {
        loadDivisions(batch.divisionDetails?.map((det) => det.division));
        loadSelectedDivisions();
      });
      if (parentType === 'ASSIGNMENT') {
        getSubjects(batch?.class?.id);
      }
    }
  }, [batch]);

  useEffect(() => {
    loadSelectedDivisions();
  }, [audienceData]);

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      setIsDataAvailable(false)
      if (parentType === 'ASSIGNMENT') {
        setAudienceData([]);
        setValue(AUTOCOMPLETE_MODULE.SUBJECT, null);
      }
      setSearchData('');
      setFilteredDivisions([]);
      setSelectedDivision([]);
      data = isWeb ? data : batches.find((bt: any) => bt.value === data);
      setValue(AUTOCOMPLETE_MODULE.DIVISION, null);
      setBatch(data);
    }
  }

  function loadSelectedDivisions() {
    if (audienceData && audienceData.length > 0) {
      let divisions = audienceData.filter((item: any) => {
        const batchId = item.audience.split('/')[1];
        return batchId === batch?.value;
      });
      divisions = divisions.map((item: any) => item.audience.split("/").pop());
      setSelectedDivision(divisions);
    } else {
      setSelectedDivision([]);
    }
  }

  const handleCheckChild = (item: string) => {
    handleChecked(item, batch);
  }

  function searchBoxOnChange(data: string) {
    setSearchData(data);
    if (divisions.length) {
      if (!data.length) {
        setFilteredDivisions(divisions);
      } else {
        const updatedData = data.toLowerCase().trim();
        setFilteredDivisions(
          divisions.filter((division: any) => {
            if (division?.name?.toLowerCase().includes(updatedData)) {
              return true;
            }
            return false;
          }),
        );
      }
    }
  }

  return (
    <View>
      <View style={styles.containerView}>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View key={`parentele${i}`}>
              <Element
                key={i}
                field={field}
                control={control}
                errors={errors}
                handleCustomSelect={handleCustomSelect}
                checked={selectedDivision}
                checkboxTableData={filteredDivisions}
                handleCheck={handleCheckChild}
                checkboxTableHeight={'auto'}
                checkboxTableMaxHeight={220}
                leftLabel={'DIVISION'}
                searchBoxOnChange={searchBoxOnChange}
                searchData={searchData}
                manualSelection={false}
                isDataAvailable={isDataAvailable}
              />
            </View>
          ))
        ) : (
          <View style={styles.spinnerView} />
        )}
      </View>
      <View style={[styles.chipWrapper, { marginTop: 15 }]}>
        {audienceData && audienceData.length > 0 &&
          audienceData?.map((chip: any, index: number) => (
            <View style={[styles.chipView]} key={`audience${index}`}>
              <Chip
                label={isWeb ? chip?.text : clipLongText(chip?.text, 36)}
                onPress={() => removeAudience(index)}
                borderRadius={isWeb ? 4 : 20}
                chipFontSize={1.2}
              />
            </View>
          ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
  chipWrapper: { flexDirection: 'row', flexWrap: 'wrap' },
  chipView: { marginRight: 8, marginBottom: 8 },
  containerView: {
    borderWidth: 1,
    borderColor: colors.borderColor,
    borderStyle: 'dashed',
    borderRadius: 4,
    padding: 8,
  }
});
