import { ERROR, SUCCESS } from "src/constant";
import React, { useState } from "react";
import {
  getFormattedDate,
  getInitialDateTimeValue,
} from "src/components/atoms/DateTime/helpers";

import DateTimePicker from "src/components/atoms/DateTime/DateTimePicker.web";
import { DeleteLectureFormProps } from "../scheduleTypes";
import DeleteModal from "src/components/atoms/Modals/Delete/index.web";
import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import RadioStandalone from "src/components/atoms/Radio/StandaloneRadio/index.web";
import { colors } from "src/styles/theme/styles";
import { removeLectureMutation } from "src/graphql/courses";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useI18n } from "src/i18n/hooks";
import { useMutation } from "@apollo/client";

const DeleteLectureForm = (props: DeleteLectureFormProps) => {
  const { closeModal, refetchLectures, schedule, closePopover } = props;
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();
  const [canClick, setCanClick] = useState<boolean>(true);
  const [selected, setSelected] = useState<OptionValue>("SINGLE");
  const [deleteTill, setDeleteTill] = useState<string>(schedule.to);

  const [removeLecture] = useMutation(removeLectureMutation);

  const onSubmit = async () => {
    setCanClick(false);
    try {
      const payload = {
        id: schedule.id,
        single: selected === "SINGLE",
        fromDate: schedule.from,
        ...(selected === "MULTIPLE" ? { toDate: deleteTill } : {}),
      };
      const removeScheduleData = await removeLecture({
        variables: payload,
      });
      if (removeScheduleData?.data?.removeSchedule) {
        setAlertDetails({
          message: t("lectureDeleteSuccess.text"),
          level: SUCCESS,
        });
        if (refetchLectures) refetchLectures();
        setCanClick(true);
        closeModal();
        closePopover();
      }
    } catch (error: any) {
      setAlertDetails({ message: error?.message, level: ERROR });
      setCanClick(true);
      closeModal();
    }
  };

  return (
    <DeleteModal
      isSubmitting={!canClick}
      setModalVisible={closeModal}
      modalVisible={true}
      handleSave={onSubmit}
      Headerpopup={t("deleteLecture.text")}
      headerAlign={"left"}
      height={480}
      width={460}
      maxWidth={"md"}
      contentStyle={style}
    >
      <Wrapper>
        <MediumTextSelect value={t("deleteSchedule.warning")} />
      </Wrapper>
      <RadioWrapper>
        {options.map((option) => (
          <RadioSelection>
            <RadioStandalone
              isChecked={option.value === selected}
              value={option.value}
              handleChange={() => setSelected(option.value)}
            />
            <MediumTextSelect
              value={t(option.label)}
              color={colors.primaryText}
            />
          </RadioSelection>
        ))}
      </RadioWrapper>
      <DateTimePicker
        initialValue={getInitialDateTimeValue<true>(deleteTill, dateTimeMode)}
        dateTimePickerTitle={t("tillDate.label")}
        dateTimeMode={dateTimeMode}
        forceOpenAtBottom={true}
        disabled={selected === "SINGLE"}
        minDate={getInitialDateTimeValue<true>(schedule.from, dateTimeMode)}
        maxDate={getInitialDateTimeValue<true>(schedule.to, dateTimeMode)}
        onDateTimeChangeCallback={(selectedDate: Date) => {
          if (selectedDate) {
            const formattedDate: string = getFormattedDate(
              selectedDate,
              dateTimeMode
            );
            setDeleteTill(formattedDate);
          }
        }}
      />
    </DeleteModal>
  );
};

const dateTimeMode = "date";
const style = { alignItems: "inherit", justifyContent: "flex-start" };

const options = [
  {
    label: "deleteSingleLecture.text",
    value: "SINGLE" as const,
  },
  {
    label: "deleteFutureLectures.text",
    value: "MULTIPLE" as const,
  },
];

type OptionValue = (typeof options)[number]["value"];

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const RadioWrapper = styled.div`
  margin-left: -10px;
`;

const RadioSelection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export default DeleteLectureForm;
