
import { occupations } from './dd-options';
import { regexObject } from 'src/constant/regex';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import dataTypes from '@data-driven-forms/react-form-renderer/data-types';
import { TemplateType, ViewMode } from 'src/components/molecules/Forms/types';
import { getComponentType } from './util';
const { EMAIL_INVALID, CONTACT_INVALID } = regexObject;

export const parent = (
  viewMode: ViewMode,
  name: string = 'fatherDetail',
  label: string = 'fatherDetails.label',
  isInstituteAdmin?: boolean,
) => {
  return {
    name,
    label,
    component: "custom-sub-form",
    section: "left",
    groupBy: name,
    fields: [
      {
        name: `${name}.name`,
        label: name == 'fatherDetail' ? 'form.fatherName.label' : 'form.motherName.label',
        isRequired: true,
        component: getComponentType(viewMode, "custom-text-field"),
        autoCapitalize: "words",
        validate: !isInstituteAdmin ? [
          {
            type: validatorTypes.REQUIRED,
            message: 'error.name.required',
          }
        ] : [],
      },
      {
        name: `${name}.dob`,
        label: 'form.dob.label',
        component: getComponentType(viewMode, "date-time-picker"),
        disableFuture: true,
        isRequired: false
      },
      {
        name: `${name}.mobileNumber`,
        label: 'form.mobileNumber.label',
        isRequired: true,
        component: getComponentType(viewMode, "custom-text-field"),
        type: "number",
        dataType: dataTypes.INTEGER,
        validate: !isInstituteAdmin ? [
          {
            type: validatorTypes.REQUIRED,
            message: 'error.contactNumber.required',
          },
          {
            type: validatorTypes.PATTERN,
            pattern: CONTACT_INVALID,
            message: 'error.contactNumber.invalid',
          }
        ] : [
          {
            type: validatorTypes.PATTERN,
            pattern: CONTACT_INVALID,
            message: 'error.contactNumber.invalid',
          }
        ]
      },
      {
        name: `${name}.email`,
        label: 'email.label',
        isRequired: false,
        component: getComponentType(viewMode, "custom-text-field"),
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: EMAIL_INVALID,
            message: 'error.email.invalid',
          }
        ]
      },
      {
        name: `${name}.qualification`,
        label: 'form.qualification.label',
        isRequired: false,
        autoCapitalize: "words",
        component: getComponentType(viewMode, "custom-text-field"),
      },
      {
        name: `${name}.occupation`,
        label: 'form.occupation.label',
        options: occupations,
        isRequired: false,
        component: getComponentType(viewMode, "select"),
      },
      {
        name: `${name}.annualIncome`,
        label: 'form.annualIncome.label',
        isRequired: false,
        component: getComponentType(viewMode, 'custom-text-field'),
        type: "number",
        dataType: dataTypes.INTEGER,
      },
      {
        name: `${name}.aadhaarId`,
        label: 'aadhaarNumber.label',
        component: getComponentType(viewMode, 'custom-text-field'),
        isRequired: false,
        type: "number",
        dataType: dataTypes.INTEGER,
        validate: [
          {
            type: validatorTypes.PATTERN,
            pattern: regexObject.AADHAAR_INVALID,
            message: 'error.aadhaarNumber.invalid',
          }
        ],
        convertToString: true,
      },
      {
        name: `${name}.officeAddress`,
        label: 'form.officeAddress.label',
        isRequired: false,
        autoCapitalize: "words",
        component: getComponentType(viewMode, "custom-text-field"),
      },
      {
        name: 'blank',
        component: 'none',
      },
      {
        name: `${name}.documents`,
        parentType: name,
        category: 'Parent',
        valueField: 'allDocument',
        label: 'form.documents.label',
        type: 'dynamic-file-input',
        component: getComponentType(viewMode, "multi-doc-input"),
      },
    ],
  }
}

export const parents = (viewMode: ViewMode, isInstituteAdmin?: boolean) => ({
  name: "parentDetails",
  label: "form.parentDetails.text",
  viewMode,
  headerData: {
    title: 'parentDetails.label',
    skip: true,
    icon: require('src/assets/common/headerOnboardingIcons/v1/people-3x.png'),
    progress: 0.6,
  },
  fields: [
    parent(
      viewMode,
      'fatherDetail',
      'fatherDetails.label',
      isInstituteAdmin,
    ),
    parent(
      viewMode,
      'motherDetail',
      `motherDetails.label`,
      isInstituteAdmin,
    ),
  ]
})
