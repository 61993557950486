import { DropdownOptionsType } from "src/types";
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import { FormOptions } from "@data-driven-forms/react-form-renderer/renderer-context";

export const promoteStudentAdmission = (
  admissionConfigOptions: DropdownOptionsType[],
  subjectGroupOptions: DropdownOptionsType[],
  divisionOptions: DropdownOptionsType[],
  loadSchema: (promotionType: string) => void,
  loadAdmissionSubjectGroupsAndDivisions: (admissionId: string) => void,
  maintainGrNo: boolean,
  handleMaintainGrNo: (checked: boolean) => void,
) => ({
  name: 'promoteStudent',
  label: 'promoteStudent.label',
  viewMode: 1,
  fields: [
    {
      name: 'promotionType',
      label: 'selectPromotionType.required.label',
      component: 'custom-select',
      options: promotionTypeOptions,
      handleChange: (formOptions: any, newValue: { value: string }) => {
        if (newValue.value === promotionTypeOptions[0].value) {
          formOptions.change('admissionConfig', null);
          formOptions.change('subjectGroup', null);
          formOptions.change('division', null);
        }
        loadSchema(newValue.value);
      },
      maxHeight: 200,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.promotionType.required',
        },
      ],
    },
    {
      name: 'admissionConfig',
      label: 'selectAdmission.required.label',
      component: 'custom-select',
      options: admissionConfigOptions,
      handleChange: (formOptions: any, newValue: { value: string }) => {
        formOptions.change('subjectGroup', null);
        formOptions.change('division', null);
        loadAdmissionSubjectGroupsAndDivisions(newValue.value);
      },
      maxHeight: 200,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.admission.required',
        },
      ],
    },
    {
      name: 'subjectGroup',
      label: 'selectSubjectGroup.label',
      component: 'custom-select',
      options: subjectGroupOptions,
      maxHeight: 200,
    },
    {
      name: 'division',
      label: 'selectDivision.label',
      component: 'custom-select',
      options: divisionOptions,
      maxHeight: 200,
    },
    {
      name: 'maintainGrNo',
      label: 'maintainGrNo.label',
      component: 'checkbox',
      displayCentered: true,
      isChecked: maintainGrNo,
      handleChange: (formOptions: FormOptions, checked: boolean) => handleMaintainGrNo(checked),
    },
  ],
});

const promotionTypeOptions = [
  {
    label: 'DIRECT',
    value: 'DIRECT',
  },
  {
    label: 'ADMISSION',
    value: 'ADMISSION',
  },
];
