import React from 'react';
import { TableBody } from '@mui/material';
import useTable from 'src/components/molecules/Table/UseTable';
import { NoRecordsFound, TableCells, TableRows } from 'src/components/molecules/Table/TableAtom';
import { ATKTHeadcells } from 'src/components/molecules/AtktRevaIinput/helpers';
import styled from 'styled-components';
import { DeleteIcon } from 'src/components/atoms/ActionIcons';
import { useI18n } from 'src/i18n/hooks';
type Tprops = {
  AtktData: any;
  handleRemove: () => void;
};
function AtktTable(props: Tprops) {
  const { t } = useI18n();
  const { AtktData, handleRemove } = props;
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(AtktData, ATKTHeadcells);
  return (
    <>
      <TblContainer maxHeight={'300px'} overflowY="scroll">
        <TblHead />
        {recordsAfterPagingAndSorting()?.length > 0 ? (
          <TableBody>
            {recordsAfterPagingAndSorting().map((item: any, index: number) => (
              <TableRows>
                <TableCells value={`${item?.min} - ${item.max ? item.max : ''}` ?? '-'} />
                <TableCells value={`\u20B9 ${item?.feeAmount}` ?? '-'} />
                <TableCells value={`\u20B9 ${item?.applicationFee}` ?? '-'} />
                <TableCells align="center">
                  <ActionIconWrapper>
                    <DeleteIcon
                      onPress={() => handleRemove(index)}
                      tooltipTitle={t('delete.label')}
                    />
                  </ActionIconWrapper>
                </TableCells>
              </TableRows>
            ))}
          </TableBody>
        ) : (
          <NoRecordsFound colspan={6} maxHeight={50} />
        )}
      </TblContainer>
    </>
  );
}

const ActionIconWrapper = styled.div`
  margin-left: 18px;
`;

export default AtktTable;
