import React from 'react';
import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import styled from 'styled-components/native';
// @ts-ignore
import DownloadFile from 'src/components/atoms/DownloadFile/index';
import { DetailStatusEnum, FileCategory } from 'src/constant';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { colors, fonts } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { isNative } from 'src/constant/device';

const MultiFileView: React.FC<UseFieldApiConfig> = (props: UseFieldApiConfig) => {
  const { t } = useI18n();
  const field = useFieldApi(props);
  let values = field.input.value;
  if (field.valueField) {
    const parentType = field?.parentType;
    const docField = useFieldApi({ name: parentType ? parentType?.[`${field.valueField}`] : field.valueField });
    const docFieldVal = parentType
      ? docField.input.value?.[`${parentType}`]?.[`${field.valueField}`]
      : docField.input.value;
    if (Array.isArray(docFieldVal)) {
      values = docFieldVal;
    } else {
      values = [docFieldVal];
    }
  }

  const showFieldLabel: boolean = isNative && field?.category === FileCategory.Education;

  return <>
    {
      showFieldLabel && <SectionHeader>{t("documents.label")}</SectionHeader>
    }
    {
      values && values.length > 0 && values.map((value: any) =>
        <>
          {value?.document &&
            <DownloadFileWrapper>
              <LabelWrapper>
                <SmallTextAtom
                    value={
                      value.type?.name
                        ? `${value.type.name}${
                            value?.type?.defaultUserSelectionRequired ? '*' : ''
                          }`
                        : ''
                    }
                  fontFamily={fonts.regular}
                />
              </LabelWrapper>
              <DownloadFile
                fileData={value.document}
                customStyle={styles.downloadFile}
                showIndicatorBadge={value?.status == DetailStatusEnum.SUBMITTED_FOR_REVIEW}
                maxChars={60}
              />
            </DownloadFileWrapper>
          }
        </>
      )
    }
  </>
}

export default MultiFileView;

const styles = {
  downloadFile: {
    display: 'flex',
    maxWidth: '80%',
  }
}

const SectionHeader = styled.Text`
  padding: 16px 0px;
  font-size: 16px;
  font-family: 'SFProRounded-Medium';
  color: ${colors.primaryText};
`;

const DownloadFileWrapper = styled.View`
  margin-bottom: 6px;
`;

const LabelWrapper = styled.View`
  padding-bottom: 10px;
`;