import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={16 * scale}
      height={14 * scale}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M15.4996 12.9997H14.9997V11.7497C14.9997 11.3355 14.6639 10.9997 14.2497 10.9997H12.9997V5.49987H11.9997V10.9997H9.99977V5.49987H8.99979V10.9997H6.99984V5.49987H5.99986V10.9997H3.99991V5.49987H2.99993V10.9997H1.74996C1.33575 10.9997 0.999977 11.3355 0.999977 11.7497V12.9997H0.499989C0.223839 12.9997 1.18699e-09 13.2235 1.18699e-09 13.4997V13.7497C1.18699e-09 13.816 0.0263386 13.8796 0.0732216 13.9265C0.120105 13.9733 0.183692 13.9997 0.249994 13.9997H15.7496C15.8159 13.9997 15.8795 13.9733 15.9264 13.9265C15.9733 13.8796 15.9996 13.816 15.9996 13.7497V13.4997C15.9996 13.2235 15.7758 12.9997 15.4996 12.9997ZM1.99995 11.9997H13.9997V12.9997H1.99995V11.9997ZM15.758 3.24993L8.47415 0.0769672C8.16625 -0.0256557 7.83338 -0.0256557 7.52548 0.0769672L0.241651 3.24993C0.170586 3.27696 0.109418 3.32495 0.0662602 3.38755C0.0231024 3.45015 -6.04713e-06 3.52439 1.18699e-09 3.60042V3.87491C1.18699e-09 4.082 0.167902 4.2499 0.374991 4.2499H0.999977V4.62489C0.999977 4.83198 1.16788 4.99989 1.37497 4.99989H14.6247C14.8318 4.99989 14.9997 4.83198 14.9997 4.62489V4.2499H15.6246C15.8317 4.2499 15.9996 4.082 15.9996 3.87491V3.60042C15.9996 3.44473 15.9035 3.30527 15.758 3.24993ZM13.9997 3.99991H1.99995V3.57473L7.87304 1.01632C7.95615 0.99454 8.04348 0.99454 8.1266 1.01632L13.9997 3.57473V3.99991Z"
        fill="rgba(0, 0, 0, 0.6)"
        fill-opacity="0.6"
      />
    </Svg>
  );
}

export default SvgComponent;
