import React from 'react';
import ListFormViewer from '../DDFFormListViewer';
import FormViewer from '../DDFFormViewer';
import { FormViewerMapperPropType, TemplateType } from '../types';

const FormViewerMapper: React.FC<FormViewerMapperPropType> = ({
  step,
  schema,
  templateType,
  formInitialValues: initialValues,
  setFormInitialValues,
  handleDelete,
  isOnboarding,
  handleSubmit,
  navigation,
  userId,
  userType,
  formBaseType,
  applicationRef,
  reviewMode,
  inModalView,
  refreshList,
  selectedRecords,
  setSelectedRecords,
  hideAddAndDeleteBtn,
  viewApplication,
}) => {

  switch (templateType) {
    case TemplateType.LIST:
      return (
        <ListFormViewer
          step={step}
          schema={schema}
          templateType={templateType}
          formInitialValues={initialValues}
          setFormInitialValues={setFormInitialValues}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          navigation={navigation}
          userType={userType}
          userId={userId}
          reviewMode={reviewMode}
          isOnboarding={isOnboarding}
          refreshList={refreshList}
          selectedRecords={selectedRecords}
          setSelectedRecords={setSelectedRecords}
          hideAddAndDeleteBtn={hideAddAndDeleteBtn}
          formBaseType={formBaseType}
          applicationRef={applicationRef}
          viewApplication={viewApplication}
        />
      )
    case TemplateType.DEFAULT:
    default:
      return (
        <FormViewer
          step={step}
          schema={schema}
          isOnboarding={isOnboarding}
          inModalView={inModalView}
          paddingRight={isOnboarding ? 40 : 0}
        />
      )
  }
}

export default FormViewerMapper;