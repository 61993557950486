import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { colors, fontWeight } from 'src/styles/theme/styles';
import { useThemeSystem } from 'src/contexts/theme-context';
import { TouchableOpacity, View } from 'react-native';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import Badge from 'src/components/atoms/Badge';
import { CollapseCaretIcon, ExpandCaretIcon } from 'src/components/atoms/ActionIcons';
import { useI18n } from 'src/i18n/hooks';
import { Icon } from 'src/components/atoms/Icon/Icon';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import MediumText from 'src/components/atoms/Text/MediumText';

// TODO: define prop types

export function TableDiv(props) {
  const { theme, style } = useThemeSystem();

  const { children, customStyle } = props;
  return (
    <View style={{ ...styles.tableDiv, backgroundColor: theme?.table?.backgroundColor, ...style, ...customStyle }}>
      {children}
    </View>
  );
}

export function TableRows(props) {
  const { children, style, onClick } = props;

  return (
    <TableRow
      style={{
        ...styles.tableRow,
        ...style,
      }} onClick={onClick}>
      {children}
    </TableRow>
  );
}

export function TableCells(props: any) {
  const {
    align,
    onPress = null,
    style,
    colspan,
    value,
    color = colors.primaryText,
    badge = '',
    textStyle = null,
    fontSize,
    lineHeight,
    children = null,
    classNames = '',
    clickable = false,
    width,
    boldText = false,
    lines,
  } = props;

  return (
    <TableCell className={classNames} style={{ width, ...styles.tableCell, ...style }} align={align} colSpan={colspan}>
      {children ? children : (
        <TouchableOpacity
          disabled={!clickable}
          onPress={onPress}
          style={{ flexDirection: (align === 'right' ? 'row-reverse' : 'row'), cursor: onPress ? 'pointer' : 'default', ...textStyle }}>
          <NormaltextAtom value={value} color={color} fontSize={fontSize} lineHeight={lineHeight} fontWeight={fontWeight[boldText ? 600 : 400]} lines={lines} />
          {!!badge && (
            <View style={styles.badgeStyle}>
              <Badge value={badge} />
            </View>
          )}
        </TouchableOpacity>
      )}
    </TableCell>
  );
}

interface ExpandCollapseCellProps {
  trow: boolean;
  current: number | null;
  i: number;
  classNames: string | undefined;
  textValue?: string;
  CollapseFn: () => void;
  ExpandFn: () => void;
  disabled?: boolean;
  size?: number;
  color?: string;
  style?: object;
}

export function ExpandCollapseCell(props: ExpandCollapseCellProps) {
  const { trow, current, i, classNames, textValue, CollapseFn, ExpandFn, disabled = false, size, color, style } = props;
  let expandCollapseCell =
    trow && current === i ? (
      <TableCell
        className={classNames} style={{...styles.cellWidth, ...style}}>
        <View style={styles.flexRow}>
          <CollapseCaretIcon onPress={CollapseFn} disabled={disabled} size={size} color={color} />
          {Boolean(textValue) && <NormaltextAtom value={textValue} style={styles.collapseCell} />}
        </View>
      </TableCell>
    ) : (
      <TableCell style={{...styles.cellWidth, ...style}}>
        <View style={styles.flexRow}>
          <ExpandCaretIcon onPress={ExpandFn} disabled={disabled} size={size} color={color} />
          {Boolean(textValue) && <NormaltextAtom value={textValue} style={styles.expandCell} />}
        </View>
      </TableCell>
    );

  return expandCollapseCell;
}

interface NoRecordsFoundProps {
  colspan: number;
  maxHeight?: number | string;
  icon?: string;
  label?: string;
  label2?: string;
  iconSize?: number;
  borderBottom?: boolean;
  message?: string;
}
export function NoRecordsFound(props: NoRecordsFoundProps) {
  const { colspan, maxHeight, icon, label, label2, iconSize = 80, borderBottom = false, message = '' } = props;
  const { t } = useI18n();
  return (
    <TableRows style={{ height: maxHeight }}>
      <TableCells
        colspan={colspan}
        align={'center'}
        style={{ borderBottom: borderBottom ? '1px solid rgba(224, 224, 224, 1)' : 'none' }}>
        {
          icon && label ? (
            <View style={styles.iconWrapper}>
              <Icon name={icon} size={iconSize} color={colors.tertiaryText} />
              <View style={styles.iconLabelWrapper}>
                <HeaderThree value={t(label)} color={colors.secondaryText} />
                {label2 && (
                  <View style={styles.tableDiv}>
                    <MediumText value={t(label2)} />
                  </View>)}
              </View>
            </View>
          ) : (
            <NormaltextAtom
              value={message ? t(message) : t("no-records-found.text")}
              fontSize={1.8}
            />
          )
        }
      </TableCells>
    </TableRows >
  );
}

export function TableActions(props: any) {
  const { children, align, handleClick, style, colspan } = props;
  return (
    <TableCell
      style={{ ...styles.tableCell, ...style }}
      align={align}
      onClick={handleClick}
      colSpan={colspan}>
      {children}
    </TableCell>
  );
}

const styles = {
  tableCell: {
    padding: '10px 16px',
  },
  tableRow: {},
  tableDiv: {
    marginTop: 6,
  },
  iconLabelWrapper: {
    marginTop: 12,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeStyle: {
    marginLeft: 8,
    marginTop: -2,
  },
  cellWidth: {
    width: 16,
  },
  collapseCell: {
    paddingLeft: 16,
  },
  expandCell: {
    paddingLeft: 18,
  },
  flexRow: {
    flexDirection: 'row',
  },
};
