import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import AddMarksFormJSON from 'src/form-json/assessment/assessment-marks-add-marks-form.json';
import EditMarksFormJSON from 'src/form-json/assessment/assessment-marks-edit-marks-form.json';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { createQueryParameterForStudent } from 'src/components/services/filters';
import { getStudentsQuery } from 'src/graphql/classes';
import { graphqlQuery } from 'src/graphql/util';
import { getExamSubjectMarksQuery } from 'src/graphql/assessment/assessment-marks';
import { properCase } from 'src/utils/utility';
import { regexObject } from 'src/constant/regex';
import { generateRegexFromArray } from 'src/components/organism/Assessment/Marks/helpers';

type TClassTestDetail = {
  id: string;
  name: string;
  passingMarks: number;
  totalMarks: number;
  markingType: 'MARK' | 'GRADE';
  marks: string;
};
export default function AddEditMarksForm(props: any) {
  const {
    selectedBatchId,
    selectedClassId,
    selectedDivision,
    setValue,
    control,
    errors,
    inputTableDataObj,
    setInputTableDataObj,
    testsFieldData,
    reset,
    selectedExamId,
    selectedSubjectId,
    isEditModal,
    currentItem,
    allowedGrades,
    batchMarkingType,
  } = props;
  const [elements, setElements] = useState({});
  const [isDropDownSet, setIsDropDownSet] = useState<boolean>(false);
  const { fields }: any = elements ?? {};
  const [tableData, setTableData] = useState([]);
  const [studentName, setStudentName] = useState('');

  async function onLoadFunction(
    selectedBatchId: string,
    selectedClassId: string,
    selectedDivision: string,
  ) {
    const getFilter: any = createQueryParameterForStudent(
      selectedBatchId,
      selectedClassId,
      selectedDivision,
      '',
    );
    getFilter['limit'] = 0;
    let { data: allStudents, loading } = await graphqlQuery(getStudentsQuery, getFilter);
    if (!loading && allStudents?.instituteStudents) {
      let instituteStudents = allStudents?.instituteStudents?.data;
      loadStudents(instituteStudents);
    }
  }
  function loadStudents(studentsArray: any) {
    const options = [] as any;
    if (studentsArray.length) {
      studentsArray.forEach(function (studentInfo: any) {
        options.push({
          value: studentInfo.id,
          label: `(${studentInfo?.rollNo || 'N/A'}) ${properCase(studentInfo?.fullName)}`,
        });
      });
    }
    AddMarksFormJSON[0].fields[0].option = options;
    setElements(AddMarksFormJSON[0]);
    setIsDropDownSet(true);
  }
  useEffect(() => {
    reset();
    if (!isEditModal) {
      onLoadFunction(selectedBatchId, selectedClassId, selectedDivision);
    } else {
      setElements(EditMarksFormJSON[0]);
      setIsDropDownSet(true);
      setValue('studentName', {
        value: currentItem?.user?.id,
        label: `(${
          (currentItem &&
            currentItem?.user &&
            currentItem?.user?.academic &&
            currentItem?.user?.academic?.rollNo) ||
          'N/A'
        }) ${properCase(currentItem?.user?.fullName || '-')}`,
      });
      setStudentName(currentItem?.user?.id);
      setTableData([]);
      getMarksData(currentItem?.user?.id);
    }
  }, []);

  async function getMarksData(id: string) {
    const { data: marksData } = await graphqlQuery(
      getExamSubjectMarksQuery,
      {
        filters: { id: { eq: id } },
        batch: selectedBatchId,
        division: selectedDivision,
        exam: selectedExamId,
        subjectId: selectedSubjectId,
      },
      false,
    );
    if (marksData?.examSubjectMarks?.data?.length > 0) {
      loadMarks(marksData?.examSubjectMarks?.data[0]?.marks?.marks);
    }
  }

  async function handleCustomSelect(id: string, data: any) {
    if (data) {
      setStudentName(data?.value);
      setTableData([]);
      getMarksData(data?.value);
    }
  }

  function loadMarks(data: any) {
    if (data && data?.length > 0) {
      let fieldData = testsFieldData.map((i: any) => {
        var result = data.filter((j: any) => j?.test?.id === i?.id);
        if (result.length > 0) {
          i = { ...i, marks: result[0].marksObtained };
        }
        return i;
      });
      setTableData(fieldData);
    }
  }

  const checkMinMaxValue = (value: any, totalMarks: number) => {
    const val = parseInt(value);
    if (Number.isInteger(val)) {
      if ((totalMarks || totalMarks === 0) && val > totalMarks) return totalMarks.toString();
    }
    return value;
  };

  const regex = generateRegexFromArray(allowedGrades);

  const handleOnChange = (value: string, item: TClassTestDetail, onChangesetValue: any) => {
    if (batchMarkingType) {
      if (item.markingType === 'MARK') {
        if (regexObject.MARKS.test(value)) {
          onChangesetValue(checkMinMaxValue(value, item.totalMarks), item);
        }
      } else if (item.markingType === 'GRADE') {
        if (regex.test(value)) {
          onChangesetValue(value, item);
        }
      }
    } else {
      if (regexObject.MARKS.test(value)) {
        onChangesetValue(checkMinMaxValue(value, item.totalMarks), item);
      }
    }
  };

  return (
    <>
      <View style={styles.parentContainer}>
        {isDropDownSet && fields ? (
          fields.map((field: any, i: number) => (
            <View style={styles.inputFieldContainer}>
              <Element
                key={`selection${i}`}
                field={field}
                control={control}
                errors={errors}
                inputTableWidth={444}
                inputTableData={tableData?.length > 0 ? tableData : testsFieldData}
                inputTableDataObj={inputTableDataObj}
                setInputTableDataObj={setInputTableDataObj}
                handleCustomSelect={handleCustomSelect}
                dropDownData={studentName}
                handleCustomOnChange={handleOnChange}
              />
            </View>
          ))
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  parentContainer: { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' },
  inputFieldContainer: { width: '100%' },
  spinnerView: { width: '100%', height: 680, justifyContent: 'center', alignItems: 'center' },
});
