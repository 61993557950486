import React, { useState, Fragment, useEffect } from 'react';
import { ScrollView } from 'react-native';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { createQueryParameterForStudent } from 'src/components/services/filters';
import { useQuery } from '@apollo/client';
import { getStudentsQuery } from 'src/graphql/classes';
import { height, isIOS } from 'src/constant/device';
import UserCard from 'src/components/atoms/MultiAccountUserCard';
import { colors, fontWeight } from 'src/styles/theme/styles';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import navigationStrings from 'src/navigation/navigationStrings';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';
import { properCase } from 'src/utils/utility';
import AbstractFlashList from 'src/components/atoms/FlashList';
import NoData from "src/components/molecules/NoData";
import { faUserGraduate } from '@fortawesome/pro-regular-svg-icons';

function AdminStudentList(props: any) {
  const { t } = useI18n();
  const { selectedFormValues, batch } = props.route.params;
  const [users, setUsers] = useState<any>([]);
  const getFilter: any = createQueryParameterForStudent(
    selectedFormValues.batch,
    batch?.class?.id,
    selectedFormValues.division,
    '',
  );
  getFilter['limit'] = 0;

  const { data: allStudents, loading } = useQuery(getStudentsQuery, {
    variables: getFilter,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (allStudents?.instituteStudents?.data) {
      setUsers([...allStudents?.instituteStudents?.data]);
    }
  }, [loading]);

  const createUserObj = (userInfo: any) => {
    const userData = {
      userId: userInfo?.id,
      fullName: properCase(userInfo?.fullName),
      email: userInfo?.email,
      profilePicture: userInfo?.profilePicture?.documentUrl,
    };
    return userData;
  };

  const ListEmptyComponent = (
    <CenterAlignView>
      {!loading && (
        <NoData text1={t("no-records-found.text")} icon={faUserGraduate} />
      )}
    </CenterAlignView>
  );

  return (
    <Fragment>
      <MobileHeader label={t('students.label')} backIcon={true} {...props} />
      <Container>
        <ScrollViewWrapper>
          <ScrollView contentContainerStyle={{ flexGrow: 1 }} showsVerticalScrollIndicator={false}>
            <TopTextContainer>
              <HeaderSix
                value={`${batch?.class?.alias} - ${selectedFormValues.division}`}
                fontWeight={fontWeight[600]}
              />
              <NormalTextSelect
                value={`${batch?.alias} Batch`}
                fontWeight={fontWeight[600]}
                color={colors.tertiaryText}
              />
            </TopTextContainer>
            <UserListWrapper>
              <AbstractFlashList
                estimatedItemSize={69}
                ListEmptyComponent={ListEmptyComponent}
                data={users}
              >
                {({ item: user, index }) => (
                  <CardWrapper
                    onPress={() =>
                      props.navigation.navigate(
                        navigationStrings.NATIVE_TABBAR_STUDENTS,
                        {
                          user,
                          batchId: selectedFormValues.batch,
                          batchAlias: batch?.alias,
                          classAlias: batch?.class?.alias,
                        }
                      )
                    }
                  >
                    <UserCard userInfo={createUserObj(user)} showOption={false} />
                  </CardWrapper>
                )}
              </AbstractFlashList>
            </UserListWrapper>

            {loading && (
              <SpinnerView>
                <LoaderSpinner />
              </SpinnerView>
            )}
          </ScrollView>
        </ScrollViewWrapper>
      </Container>
    </Fragment>
  );
}

const CardWrapper = styled.TouchableOpacity``;

const CenterAlignView = styled.View`
  justify-content: center;
  align-items: center;
  height: ${0.75 * height};
`;

const Container = styled.View`
  padding: 0px 24px;
`;
const ScrollViewWrapper = styled.View`
  height: ${isIOS ? (height > 800 ? height - 120 : height - 80) : height - 75};
`;
const UserListWrapper = styled.View`
  margin-top: 18px;
`;
const TopTextContainer = styled.View`
  padding: 24px 0px 8px 0px;
`;

const SpinnerView = styled.View`
  height: ${height - 100}px;
  justify-content: center;
  align-items: center;
`;

export default AdminStudentList;
