import { useQuery } from '@apollo/client';
import React, { Fragment } from 'react';
import { FlatList } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { getScheduleQuery } from 'src/graphql/employee';
import { currentClassStatus } from 'src/utils/current-class-status';
import SectionHeading from '../SectionHeading';
import styled from 'styled-components/native';
import { colors } from 'src/styles/theme/styles';
import { DT } from 'src/constant/dateTime';
import { format } from 'date-fns';
import { isValidHexColorCode } from 'src/utils/utility';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faBooks } from '@fortawesome/pro-light-svg-icons';

export const Schedule = ({ width }: any) => {
  const { data: schedules, loading: scheduleLoading } = useQuery(getScheduleQuery);
  return (
    <Fragment>
      <SectionHeading textLeft={"Today's Classes"} marginTop={24} />

      {!scheduleLoading &&
        schedules !== undefined &&
        schedules?.getFullSchedule['data'].length > 0 && (
          <ClassCard>
            <FlatList
              style={{ width: '100%', overflow: 'scroll' }}
              data={schedules?.getFullSchedule['data']}
              horizontal
              showsHorizontalScrollIndicator={false}
              keyExtractor={(item) => `${item.id}`}
              renderItem={({ item, index }) => {
                return (
                  <CardWrap width={width}>
                    <AnimatedCircularProgress
                      size={60}
                      width={6}
                      fill={currentClassStatus(item) !== undefined && currentClassStatus(item)}
                      tintColor="#efefef"
                      backgroundColor={index === 0 ? '#61dca7' : '#FE959F'}>
                      {
                        () => (
                          <IconWrapper
                            backgroundColor={
                              item?.subject && isValidHexColorCode(item?.subject?.color)
                                ? item?.subject?.color + 'CC'
                                : colors.yellow
                            }
                          >
                            <FontAwesomeIcon icon={faBooks} color={colors.white} size={24} />
                          </IconWrapper>
                        )
                      }
                    </AnimatedCircularProgress>
                    <RightContent>
                      <CardName>{item?.subject?.name}</CardName>
                      <CardTime>{format(item?.start, DT.TIME_12_HOUR)}</CardTime>
                    </RightContent>
                  </CardWrap>
                );
              }}
            />
          </ClassCard>
        )}
    </Fragment>
  );
};

type CardWrapProp = {
  width: number;
};

const CardName = styled.Text`
  font-size: 15px;
  font-weight: 600;
  color: ${colors.secondaryText};
  margin-bottom: 04px;
`;
const CardTime = styled.Text`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.secondaryText};
`;

const ClassCard = styled.View`
  flex-direction: row;
  margin: 15px -10px 0 -10px;
`;
const CardWrap = styled.View<CardWrapProp>`
  padding: 0 10px;
  margin: 10px 0;
  flex-direction: row;
  align-items: center;
  width: ${(props) => (props.width - 35) / 10}rem;
`;

const RightContent = styled.View`
  padding-left: 16px;
  flex-direction: column;
`;

const IconWrapper = styled.View<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
