import React from 'react';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import { isWeb } from 'src/constant/device';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
type Tprops = {
  paymentDetails: {
    token: string;
    merchId: string;
    amount: string;
    custEmail: string;
    custContact: string;
  };
  showButton?: boolean;
  handleSubmit?: () => void;
};
export default function AtktRevalPaymentModal(props: Tprops) {
  const { paymentDetails, showButton = false, handleSubmit } = props;
  const { t } = useI18n();
  return (
    <>
      <TotalWrapper>
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={t('totalPayment.label')}
          color={colors.primaryText}
        />
        <MediumTextSelect
          fontFamily={fonts.semibold}
          value={`\u20B9 ${paymentDetails?.amount}`}
          color={colors.primaryText}
        />
      </TotalWrapper>
      {showButton && (
        <ButtonWrapper>
          <SecondaryBtn label="Proceed Payment" onPress={handleSubmit} />
        </ButtonWrapper>
      )}
    </>
  );
}

const TotalWrapper = styled.View`
  border-top-width: 1px;
  border-top-color: ${colors.dividerColor};
  padding-vertical: ${isWeb ? '16px' : '24px'};
  padding-horizontal: ${isWeb ? '16px' : '0px'};
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrapper = styled.View`
  margin-top: 24px;
`;
