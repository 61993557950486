import firebase from '../../firebase/config';
import { fcmInitialized, fcmTokenInitialized } from 'src/utils/manageState';
import { ensureFCMTokenPersisted } from 'src/graphql/common';

export async function registerwebFCMToken() {
  try {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.register('./firebase-messaging-sw.js');
      if (!fcmTokenInitialized()) {
        const token = await firebase.getToken(registration);
        ensureFCMTokenPersisted(token);
      }
      if (!fcmInitialized()) {
        firebase.onMessage((payload: any) => {
          console.log('Message received. ', payload);
          registration.showNotification(payload.notification.title, {
            body: payload.notification.body,
          });
        });
        fcmInitialized(true);
      }
      //   console.log("FCM Service Worker Registered");
      return;
    } else {
      console.log('FCM Already Registered', fcmInitialized());
    }
  } catch (err) {
    console.log('error in retrieving FCM token', err);
  }
}
