import React from 'react';
import styled from 'styled-components/native';

import { colors, fonts } from 'src/styles/theme/styles';

interface Props {
  value: any;
  color?: string;
  fontWeight?: any;
  align?: string;
  lines?: number;
  lineHeight?: number;
  fontFamily?: string;
}

export default function HeaderFour(props: Props) {
  const {
    value = '',
    color = colors.primaryText,
    fontWeight,
    align,
    lines = 1,
    lineHeight = 32,
    fontFamily = fonts.regular,
  } = props;

  const HeaderText = styled.Text`
    font-family: ${fontFamily};
    font-weight: 400;
    color: ${colors.primaryText};
    font-size: ${(props) => props.theme.rem(2.4)};
    line-height:${lineHeight};
  `;

  return (
    <HeaderText
      style={{ color: color, fontWeight, textAlign: align }}
      allowFontScaling={false}
      numberOfLines={lines}>
      {value}
    </HeaderText>
  );
}
