import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import CloneTestFormJSON from 'src/form-json/assessment/assessment-test-clone-test-form.json';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { getFilteredSubjects } from 'src/components/services';
import { getModifiedSubjectsNamesQuery } from 'src/graphql/academics/subjects';

interface CloneTestFormProps { control: any; errors: any; setSubjectsToBeCloned: any; selectedClassId: string; }

export default function CloneTestForm(props: CloneTestFormProps) {
  const { control, errors, setSubjectsToBeCloned, selectedClassId } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  async function onLoadFunction() {
    let filters = {
      class: {
        eq: selectedClassId,
      },
    };
    let subjectsResponse = await getFilteredSubjects(
      { filters: filters },
      getModifiedSubjectsNamesQuery,
    );
    CloneTestFormJSON[0].fields[0].option = subjectsResponse?.options;
    setElements(CloneTestFormJSON[0]);
  }

  useEffect(() => {
    setSubjectsToBeCloned([]);
    onLoadFunction();
  }, []);

  const handleCustomSelect = (id: any, data: any) => {
    const subjects = [] as any;
    data.forEach((element: any) => {
      subjects.push(element?.value);
    });
    setSubjectsToBeCloned(subjects);
  };

  return (
    <View style={styles.parentContainer}>
      {fields ? (
        fields.map((field: any, i: number) => (
          <View style={styles.inputFieldContainer}>
            <Element
              key={`ct${i}`}
              field={field}
              control={control}
              errors={errors}
              handleCustomSelect={handleCustomSelect}
              limitTags={4}
            />
          </View>
        ))
      ) : (
        <View style={styles.spinnerView}>
          <LoaderSpinner />
        </View>
      )}
    </View>
  );
}


const styles = StyleSheet.create(
  {
    parentContainer: { display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: 15, paddingBottom: 30, minHeight: 175 },
    inputFieldContainer: { width: '100%' },
    spinnerView: { width: '100%', height: 130, justifyContent: 'center', alignItems: 'center' }
  }
);
