import {
  DashboardEmptyContainer as EmptyContainer,
  DashboardListingScrollContainer as ScrollContainer,
} from "src/components/organism/Dashboard/Common/CommonHelpers";

import AbstractFlashList from "src/components/atoms/FlashList";
import CardTitleBlock from "../CardTitleBlock";
import { CourseItem } from "../../atoms/DashboardItems/index";
import React from "react";
import ShadowBox from "src/components/atoms/ShadowBox";
import { defaultPagingURLSegment } from "../Navigation/SidebarManifest";
import { faUserGraduate } from "@fortawesome/pro-regular-svg-icons";
import { getCoursesOverview } from "src/graphql/dashboard";
import { useI18n } from "src/i18n/hooks";
import { useNavigate } from "src/routes/routing.web";
import { useQuery } from "@apollo/client";

type courseData = {
  id: string;
  name: string;
  duration: string;
  durationType: string;
};

export const CourseList = () => {
  const { t } = useI18n();
  const navigate = useNavigate();

  const { data, loading } = useQuery(getCoursesOverview);

  const courses: courseData[] = data ? data?.courses?.data : [];

  const triggerCourseList = () => {
    navigate(`/academics/courses/${defaultPagingURLSegment}`);
  };

  const ListEmptyComponent = (
    <EmptyContainer
      text={t("noCourse.text")}
      icon={faUserGraduate}
      loading={loading}
    />
  );

  return (
    <>
      <CardTitleBlock
        titleText={t("courseList.label")}
        showButton={courses.length > 0}
        buttonText={t("viewAll.text")}
        cb={triggerCourseList}
      />
      <ShadowBox>
        <ScrollContainer>
          <AbstractFlashList<courseData>
            estimatedItemSize={81}
            ListEmptyComponent={ListEmptyComponent}
            data={courses}
          >
            {({ item, index }) => (
              <CourseItem
                name={item.name}
                duration={item.duration}
                durationType={item.durationType}
                id={item.id}
                index={index}
                length={courses.length}
              />
            )}
          </AbstractFlashList>
        </ScrollContainer>
      </ShadowBox>
    </>
  );
};
