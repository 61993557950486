export function getErrorMessage(message: string, t: any) {
  switch (message) {
    case 'Invalid Admission Link':
      return t('error.invalidAdmissionCode.text');
    case 'Admission link is either closed or expired':
      return t('error.admissionCode.expired');
    case "Couldn't find record for given invite code":
      return t('error.invalidInviteCode.text');
    default:
      return message;
  }
}
