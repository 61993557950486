import { DropdownOptions, TCellState } from "src/types";
import {
  ExpandCollapseCell,
  NoRecordsFound,
  TableCells,
  TableRows,
} from "../../Table/TableAtom";
import React, { CSSProperties, useState } from "react";

import Chip from "src/components/atoms/Chip";
import LabelValueView from "../../LabelValueView/index.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import RadioStandalone from "src/components/atoms/Radio/StandaloneRadio/index.web";
import { TAdmissionCriteria } from "src/components/organism/Admission/admissionTypes";
import { TableBody } from "@mui/material";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";
import useTable from "../../Table/UseTable";

type Props = {
  onClose: () => void;
  goBack: () => void;
  onSubmit: (selectedCriteria: DropdownOptions) => void;
  criterias: TAdmissionCriteria[];
  initialValue?: DropdownOptions;
};

const AdmissionCriteriaSelection = (props: Props) => {
  const { onClose, goBack, onSubmit, criterias, initialValue } = props;
  const { t } = useI18n();
  const [selectedCriteria, setSelectedCriteria] = useState<DropdownOptions>(
    initialValue!
  );
  const [cellState, setCellState] = useState<TCellState>({
    open: true,
    current: initialValue
      ? criterias?.findIndex((e) => e.value === initialValue.value)
      : 0,
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    criterias,
    [],
    null,
    criterias?.length
  );

  const callbackOnSubmit = () => {
    if (selectedCriteria?.value) {
      onSubmit({
        label: selectedCriteria.label,
        value: selectedCriteria.value,
      });
    }
  };

  const handleSelection = (criteria: TAdmissionCriteria) => {
    setSelectedCriteria(criteria);
  };

  return (
    <NormalModal
      modalVisible={true}
      height={560}
      setModalVisible={onClose}
      Headerpopup={t("select-criteria.label")}
      addEditButtonLabel={"save.label"}
      onCancelButtonClick={goBack}
      handleSave={callbackOnSubmit}
    >
      <Container>
        <TblContainer>
          {criterias?.length > 0 ? (
            <TableBody>
              {recordsAfterPagingAndSorting()?.map(
                (item: TAdmissionCriteria, index: number) => (
                  <>
                    <TableRows key={item.value}>
                      <TableCells style={radioBtnStyle}>
                        <RadioStandalone
                          isChecked={selectedCriteria?.value === item.value}
                          value={item.value}
                          handleChange={() => handleSelection(item)}
                        />
                      </TableCells>
                      <TableCells value={item.label} />
                      <ExpandCollapseCell
                        trow={cellState.open}
                        current={cellState.current}
                        i={index}
                        classNames={""}
                        textValue={""}
                        CollapseFn={() => {
                          setCellState({ open: false, current: null });
                        }}
                        ExpandFn={() => {
                          setCellState({ open: true, current: index });
                        }}
                      />
                    </TableRows>
                    {cellState.open && cellState.current === index && (
                      <TableRows>
                        <TableCells colspan={3}>
                          <>
                            <InfoWrapper>
                              <LabelValueView
                                label={t("university.label")}
                                value={item.class.course.university.name}
                                width={"100%"}
                                marginBottom={"0px"}
                              />
                              <LabelValueView
                                label={t("course.label")}
                                value={item.class.course.alias}
                                width={"100%"}
                                marginBottom={"0px"}
                              />
                              <LabelValueView
                                label={t("class.label")}
                                value={item.class.alias}
                                width={"100%"}
                                marginBottom={"0px"}
                              />
                            </InfoWrapper>
                            <CriteriaWrapper>
                              <NormaltextAtom
                                value={t("reservationGroup.label")}
                              />
                              <Wrapper>
                                {item.reservationCriteria.reservations?.length >
                                  0 &&
                                  item.reservationCriteria.reservations.map(
                                    (elem) => {
                                      return (
                                        <ChipWrapper>
                                          <Chip
                                            label={`${elem.caste.name}: ${elem.reservePercentage}%`}
                                            close={false}
                                          />
                                        </ChipWrapper>
                                      );
                                    }
                                  )}
                              </Wrapper>
                            </CriteriaWrapper>
                            <>
                              <NormaltextAtom
                                value={t("requiredQualifications.label")}
                              />
                              <Wrapper>
                                {item.qualifications?.length > 0 &&
                                  item.qualifications.map((elem) => {
                                    return (
                                      <ChipWrapper>
                                        <Chip
                                          label={elem.name.name}
                                          close={false}
                                        />
                                      </ChipWrapper>
                                    );
                                  })}
                              </Wrapper>
                            </>
                          </>
                        </TableCells>
                      </TableRows>
                    )}
                  </>
                )
              )}
            </TableBody>
          ) : (
            <NoRecordsWrapper>
              <NoRecordsFound colspan={3} />
            </NoRecordsWrapper>
          )}
        </TblContainer>
      </Container>
    </NormalModal>
  );
};

const Container = styled.div``;

const NoRecordsWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const CriteriaWrapper = styled.div`
  margin-bottom: 24px;
`;

const ChipWrapper = styled.div`
  margin: 8px 8px 0px 0px;
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 12px;
  margin-bottom: 24px;
`;

const radioBtnStyle: CSSProperties = { width: 16, padding: 1 };

export default AdmissionCriteriaSelection;
