import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props:any) {
  const { size = 250 } = props;
  return (
    <Svg
      width={size}
      height={size * 1.044}
      viewBox="0 0 250 261"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <Path d="M124.872 0.654053C89.6508 0.654053 61.4235 27.1796 61.4235 60.0526C61.5029 63.5532 61.7645 68.613 62.0985 72.8773C56.0755 75.4336 55.636 87.1556 60.7485 99.2016C62.7759 103.979 65.3581 107.646 68.1733 110.676C68.8574 114.062 68.6779 115.582 69.5233 119.451C71.8824 126.528 81.1833 140.399 89.7728 151.85C90.6151 160.645 91.4888 169.52 89.7728 179.525C65.0228 200.059 1.2551 208.891 1.34997 230.148L0 238.248V260.654H124.872H249.744V238.248L248.394 230.148C248.292 211.955 184.418 200.782 159.971 179.525C158.982 171.387 158.793 163.24 159.296 155.225C167.123 143.147 177.593 127.334 180.221 119.451C181.165 115.459 181.509 112.763 182.246 109.326C184.591 106.485 186.593 103.272 188.32 99.2016C193.177 87.7579 192.863 76.8335 187.645 73.5523C187.953 68.9414 188.185 64.0353 188.32 60.0526C188.32 27.1796 160.093 0.654053 124.872 0.654053Z" fill="#BEBEBE"/>
    </Svg>
  )
}

export default SvgComponent