import React, { createContext, useContext } from 'react';
import { usePersistStorage } from 'react-native-use-persist-storage';

interface SidebarContextData {
  toggleSidebar(name: any): void;
  togglevalue: any;
  restored: boolean;
}

const SidebarContext = createContext<SidebarContextData>({} as SidebarContextData);

export enum SidebarState {
  CLOSED = 0,
  OPEN = 1
}

const SidebarProvider: React.FC = ({ children }) => {
  const [togglevalue, toggleSidebar, restored] = usePersistStorage('sidebar', { toggle: SidebarState.CLOSED });

  return (
    <SidebarContext.Provider
      value={{
        toggleSidebar,
        togglevalue,
        restored,
      }}>
      {children}
    </SidebarContext.Provider>
  );
};

const useSidebarSystem = (): SidebarContextData => {
  const context = useContext(SidebarContext);
  return context;
};

export { SidebarProvider, useSidebarSystem };
