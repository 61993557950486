import React, { useEffect, useState } from 'react';
import { userInformation } from 'src/utils/manageState';
import { AdminDashboard } from './Admin/index.web';
import { DashboardInstitute } from './Institute/index.web';
import { DashboardStudent } from './Student/index.web';
import { DashboardTeacher } from './Teacher/index.web';
import Applications from 'src/components/organism/Applications/index.web';
import { registerwebFCMToken } from 'src/components/services/fcm.web';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled, { ThemeProvider } from 'styled-components';
import { largeDevice, mediumSize } from 'src/styles/theme/styles';
import ApplyLanguage from 'src/i18n/apply-language';
import { EMPLOYEE_TYPES, USER_TYPE, UserType } from 'src/constant';
import { useHistory } from 'src/routes/routing.web';
import { height } from 'src/constant/device';

const getMultiple = () => {
  if (largeDevice) {
    return 10;
  } else if (mediumSize) {
    return 9;
  }
  return 8;
};

const rem = (size: number | [number]) => {
  const multiple = getMultiple();
  if (Array.isArray(size)) {
    return size.map((s) => `${s * multiple}px`).join(' ');
  }
  return `${size * multiple}px`;
};

const Dashboard = () => {
  const currentUserObject = userInformation();
  const [userType, setUserType] = useState<UserType>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const { setHeading } = useHeaderTitle();
  const { t } = useI18n();
  const history = useHistory();

  const path = history?.location?.state?.action;
  const isInstituteAdmin: boolean = !!currentUserObject?.userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN);

  useEffect(() => {
    if (path) {
      setLoaded(false);
    }
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'auto';
    }
    return () => {
      if (typeof window !== 'undefined') {
        document.body.style.overflow = '';
      }
    }
  }, [path]);

  useEffect(() => {
    setUserType(currentUserObject?.userType);
  }, [currentUserObject]);

  useEffect(() => {
    if (currentUserObject?.userType) {
      if (
        currentUserObject.userType == USER_TYPE.EMPLOYEE ||
        currentUserObject.userType == USER_TYPE.STUDENT ||
        currentUserObject.userType == USER_TYPE.APPLICANT
      ) {
        registerwebFCMToken();
      }
    }
    setHeading([
      {
        text: t('dashboard.label'),
        url: '',
      },
    ]);
  }, []);

  const renderSwitch = (userType: UserType) => {
    switch (userType) {
      case 1:
        return <AdminDashboard />;
      case 2:
        return isInstituteAdmin ? <DashboardInstitute /> : <DashboardTeacher />;
      case 3:
        return <DashboardStudent />;
      case 4:
        return <Applications />;

      default:
        return (
          <Centered>
            <LoaderSpinner />
          </Centered>
        );
    }
  };

  return !loaded &&
    currentUserObject &&
    Object.keys(currentUserObject)?.length > 0 ? (
    <ApplyLanguage
      callback={() => {
        setLoaded(true);
      }}
    />
  ) : (
    <ThemeProvider theme={{ rem }}>{renderSwitch(userType)}</ThemeProvider>
  );
};

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${0.75 * height};
`;

export default Dashboard;
