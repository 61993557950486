import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { TouchableOpacity} from 'react-native';
import { getExtention } from 'src/components/services';
import { FILE_ICONS } from 'src/constant';
import { colors, fontWeight } from 'src/styles/theme/styles';
import SmallTextAtom from '../Text/SmallTextAtom';
import styled from 'styled-components/native'
import { CustomReactNativeFile } from 'src/components/molecules/FileUpload/type';

interface Props {
  fileData: CustomReactNativeFile;
  onPress: () => void;
}

export default function AttachFiles(props: Props) {
  const { fileData, onPress } = props;
  const [fileIcon, setFileIcon] = useState<any>(FILE_ICONS.generic);

  const [name, setName] = useState<string|undefined>();

  useEffect(() => {
    if (fileData) {
      if (!fileData.name && fileData.document) {
          setName(fileData.document.name);
      } else {
        setName(fileData.name)
      }
    } else {
      setName(null);
    }
  }, [fileData])

  useEffect(() => {
    if (name) {
      checkFileExtension();
    }
  }, [name]);

  useEffect(() => {
    if (name) {
      checkFileExtension();
    }
  }, [name]);

  function checkFileExtension() {
    let exten: any = getExtention(name);
    if (FILE_ICONS[exten.toLocaleLowerCase()]) {
      setFileIcon(FILE_ICONS[exten.toLocaleLowerCase()]);
    }
  }

  return (
    name && <Container>
      <IconView>
        <TouchableOpacity onPress={onPress}>
          <FontAwesomeIcon icon={faTimesCircle} size={24} color={colors.secondaryText} />
        </TouchableOpacity>
      </IconView>
      <FileIconWrapper>
        <FontAwesomeIcon icon={fileIcon} color={colors.red} size={24} />
      </FileIconWrapper>
      <SmallTextAtom value={name || ''} fontWeight={fontWeight[400]} lines={1} />
    </Container>
    || <></>
  );
}


const Container = styled.View`
  height: 100px;
  width: 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${colors.attachBg};
  box-shadow: 0 0 4px ${colors.searchShadow};
  border-color: ${colors.lighterBorder};
  flex-wrap: wrap;
  padding-left: 8px;
  padding-right: 8px;
`
const IconView = styled.View`
  position: absolute;
  top: -10px;
  right: -10px;
`

const FileIconWrapper = styled.View`
  margin-bottom: 6px;
`