import { gql, useMutation } from '@apollo/client';

export const applicantCheckLimitedAccessQuery = gql`
  query($id: String!) {
    Applicant(id: $id) {
      id
      isLimitedAccess
    }
  }
`;

const applicantPersonalInformationQuery = gql`
  query($id: String!) {
    Applicant(id: $id) {
      id
      applicantId
      userName
      email
      fatherDetail
      motherDetail
      status
      totalOnboardingCompletion
      profilePicture {
        id
        documentUrl
        documentName
        category
        type
      }
      personalDetails {
        id
        firstName
        lastName
        middleName
        fullName
        gender
        dob
        placeOfBirth
        bloodGroup
        maritalStatus
        mobileNumber
        alternateContactNumber
        alternateContactPerson
        email
        alternateEmail
        profilePicture {
          id
          documentUrl
          documentName
          category
          type
        }
      }
      parentDetails {
        fatherDetail {
          name
          occupation
          name
          qualification
          dob
          email
          mobileNumber
          aadhaarId
          officeAddress
          annualIncome
          allDocument {
            type {
              typeId: id
              name
              label: name
              category
              defaultUserSelectionRequired
            }
            document {
              id
              category
              name: documentName
              uri: documentUrl
              fileExtension
              fileType
              typeRef
            }
          }
          documents {
            id
            uri: documentUrl
            name: documentName
            type
          }
        }
        motherDetail {
          name
          occupation
          name
          qualification
          dob
          email
          mobileNumber
          aadhaarId
          officeAddress
          annualIncome
          allDocument {
            type {
              typeId: id
              name
              label: name
              category
              defaultUserSelectionRequired
            }
            document {
              id
              category
              name: documentName
              uri: documentUrl
              fileExtension
              fileType
              typeRef
            }
          }
          documents {
            id
            uri: documentUrl
            name: documentName
            type
          }
        }
      }
      addressDetails {
        current {
          id
          area
          houseNo
          street
          zipcode
          city
          state
        }
        permanent {
          id
          area
          houseNo
          street
          zipcode
          city
          state
        }
        isSameAsCurrent
      }
      educationDetails {
        passedInFirstAttempt
        id
        course
        streams {
          value: id
          label: name
        }
        gradeObtained
        paassingYear
        institution
        marksObtained
        averageMarks
        totalMarks
        university
        documents {
          id
          name: documentName
          uri: documentUrl
          type
          category
        }
      }
      otherDetails {
        id
        category {
          value: id
          label: name
          alias
        }
        caste
        religion
        isPhysicallyChallenged
        physicallyChallengedDescription
        aadhaarId
        saralId
        abcId
        nationality
        motherTongue
        languageSpoken
        allDocument {
          type {
            typeId: id
            name
            label: name
            category
            defaultUserSelectionRequired
          }
          document {
            id
            category
            name: documentName
            uri: documentUrl
            fileExtension
            fileType
            typeRef
          }
        }
        documents {
          id
          uri: documentUrl
          name: documentName
          type
        }
      }
      onboardingCompletion {
        personalDetails
        educationDetails
        otherDetails
        addressDetails
        parentDetails
      }
    }
  }
`;

const updateApplicantDetailMutation = gql`
  mutation(
    $payload: UpdateApplicantDetailInput!
    $remove: RemoveApplicantDetailInput
  ) {
    updateApplicantDetail(payload: $payload, remove: $remove) {
      status
      message
    }
  }
`;

export { applicantPersonalInformationQuery, updateApplicantDetailMutation };

export function useUpdateApplicant() {
  const [updateApplicantDetail, { loading, error: updateApplicantDetailError }] =
    useMutation(updateApplicantDetailMutation);
  return { updateApplicantDetail, updateApplicantDetailError };
};
