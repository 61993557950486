import React from 'react';
import { TouchableOpacity, StyleSheet, ViewStyle } from 'react-native';
import NormaltextAtom from '../Text/NormalTextAtom';
import PropTypes from 'prop-types';
import { useThemeSystem } from 'src/contexts/theme-context';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { Icon } from '../Icon/Icon';
import styled from 'styled-components/native';

interface Props {
  onPress: () => void;
  label?: string;
  width?: number;
  height?: number;
  borderColor?: string;
  marginRight?: number;
  style?: ViewStyle;
  fontSize?: number;
  icon?: any;
  iconColor?: string;
  lineHeight?: number;
  children?: any;
  customDisabled?: boolean;
  iconSize?: number;
  iconType?: string;
  lines?: number;
}

export default function OutlineButton(props: Props) {
  const {
    onPress,
    label,
    width,
    style,
    borderColor = '#587BF1',
    marginRight,
    fontSize,
    icon,
    iconColor,
    lineHeight = 1.7,
    children,
    customDisabled = false,
    height,
    iconSize = 12,
    iconType = 'standard',
    lines
  } = props;
  const { theme } = useThemeSystem();
  return (
    <TouchableOpacity
      style={[
        styles.btnView,
        {
          width,
          height,
          backgroundColor: theme?.outlineButton?.backgroundColor,
          borderColor: borderColor,
          marginRight: marginRight,
          opacity: (customDisabled) ? 0.5 : 1,
          pointerEvents: (customDisabled) ? "none" : "auto",
        },
        style,
      ]}
      onPress={onPress}>
      {icon ? (
        <IconWrapper>
          {iconType === 'standard' ? (
            <FontAwesomeIcon icon={icon} color={iconColor} size={iconSize} />
          ) : (
            <Icon name={icon} size={iconSize} />
          )}
        </IconWrapper>
      ) : null}
      <NormaltextAtom
        value={label}
        color={theme?.outlineButton?.color}
        lineHeight={lineHeight && lineHeight}
        fontSize={fontSize}
        lines={lines}
      />
      {children && children}
    </TouchableOpacity>
  );
}

OutlineButton.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  btnView: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderRadius: 4,
    borderColor: '#587BF1',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const IconWrapper = styled.View`
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;