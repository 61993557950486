/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { colors, fonts, fontWeight } from 'src/styles/theme/styles';
import { View } from 'react-native';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import styled from 'styled-components/native';
import SwipeCard from '../SwipableCard';
import { isIOS, width } from 'src/constant/device';
import { clipLongText } from 'src/utils/utility';
import RenderHtml from 'react-native-render-html';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';

interface Props {
  onPress: (message: any, idx: number | undefined) => void;
  data: any;
  indexKey?: number;
  isSwipable: boolean;
  size?: 'md' | 'sm';
}

export default function MessageCard(props: Props) {
  const { onPress, data, indexKey, isSwipable = true, size = 'md' } = props;

  const TouchableOpacityList = styled.TouchableHighlight`
    padding: ${({ size }: any) =>
      size == 'sm' ? '8px 8px 8px 0' : `${indexKey === 0 ? '10px' : '24px'} 24px 24px`};
    flex-direction: column;
    max-width: ${({ size }: any) => (size == 'sm' ? '100%' : '600px')};
    border-bottom-width: 0px;
    border-bottom-color: ${colors.inputBorder};
    padding-bottom: 12px;
  `;

  const parsedDate = data?.createdAt && parse(data?.createdAt, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date());


  return (
    <>
      <SwipeCard key={data?.id} isSwipable={false}>
        <TouchableOpacityList
          size={size}
          activeOpacity={0.5}
          underlayColor={colors.inputBorder}
          delayPressIn={50}
          onPress={() => onPress(data, indexKey)}>
          <>
            <HeaderView>
              <UserView>
                <MediumTextSelect
                  value={data?.sender?.userName || ''}
                  color={colors.primaryText}
                  fontWeight={fontWeight[600]}
                  fontFamily={fonts.semibold}
                  fontSize={1.4}
                  lineHeight={1.8}
                  lines={1}
                />
              </UserView>
              <DetailView>
                <SmallTextAtom
                  value={parsedDate && format(parsedDate, DT.DATE_ABBREVIATED_MONTH_YEAR)}
                  color={colors.primaryColor}
                  fontWeight={fontWeight[600]}
                  fontFamily={fonts.semibold}
                />
              </DetailView>
            </HeaderView>

            <View>
              <MediumTextSelect
                value={data?.subject || ''}
                color={colors.primaryText}
                fontWeight={fontWeight[400]}
                fontSize={1.4}
              />
            </View>

            <RenderHtml
              source={{
                html:
                  `<html><head><meta name="viewport" content="width=device-width, initial-scale=1.0"></head><body>
                      <div
                        style="
                          color: ${colors.secondaryText};
                          font-family: ${fonts.regular};
                          letter-spacing: 0.01em;
                          line-height: 22px;
                          font-size: ${isIOS ? '18px' : '15px'}"` +
                  clipLongText(data?.body, 90) +
                  `</div>
                    </body></html>`,
              }}
              contentWidth={width - 48}
              baseStyle={{
                height: 40,
                maxHeight: 50,
              }}
            />
          </>
        </TouchableOpacityList>
        <Horizontal />
      </SwipeCard>
    </>
  );
}

const Horizontal = styled.View`
  border-bottom-color: ${colors.inputBorder};
  border-bottom-width: 1px;
  margin-left: 24px;
  margin-right: 24px;
`;

const HeaderView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const UserView = styled.View`
  flex: 1;
`;

const DetailView = styled.View`
  padding-left: 8px;
`;