import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import IndicatorBadge from '../Badge/IndicatorBadge';
import styled from 'styled-components';

interface TimelineDataType {
  color: string;
  renderContent: React.ReactNode;
}

interface PropType {
  timelineData: TimelineDataType[];
}

export default function TimelineWeb(props: PropType) {
  const { timelineData } = props;
  return (
    // @ts-ignore
    <StyledTimeline sx={{ padding: 0, margin: 0 }}>
      {timelineData.map((item: TimelineDataType, index: number) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <IndicatorBadge diameter={16} color={item?.color} />
            <TimelineConnector style={styles(index, timelineData?.length - 1).connector} />
          </TimelineSeparator>
          <TimelineContent>{item?.renderContent}</TimelineContent>
        </TimelineItem>
      ))}
    </StyledTimeline>
  );
}

const styles = (idx: number, dataLength: number) => {
  return {
    connector: {
      display: idx === dataLength ? 'none' : 'flex',
      width: 1,
    }
  }
}

const StyledTimeline = styled(Timeline)`
& .MuiTimelineItem-missingOppositeContent:before {
      flex: 0;
      padding: 0;
    }
    & .MuiTimelineContent-root {
      padding: 0px 16px;
    }
`;
