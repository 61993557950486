import React, { useEffect } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import { useHistory } from 'src/routes/routing';
import { useForm, Controller } from 'react-hook-form';
import Input from 'src/components/atoms/Input/input';
import InputPassword from 'src/components/atoms/Input/Password/index';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import NormalTextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { boxShadow, breakpoints, colors } from 'src/styles/theme/styles';
import { validation } from 'src/constant/validation';
import { getTokenFromStorage } from 'src/components/services/storage-service';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { isIOS, isWeb, isNative, width } from 'src/constant/device';
import navigationStrings from 'src/navigation/navigationStrings';
import { Icon } from 'src/components/atoms/Icon/Icon';
import AvoidKeyPad from 'src/components/atoms/Wrapper/AvoidKey';
import FlashMessage from 'react-native-flash-message';
import { USER_TYPE_ENUM } from 'src/constant';
import styled from 'styled-components/native';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'src/routes/routing.web';

const windowWidth = Dimensions.get('window').width;

interface Props {
  title: string;
  userType: string;
  loginUser: (logindata: any) => void;
  canClick: boolean;
  navigation?: any;
}

export default function Login(props: Props) {
  const history = useHistory();
  const { t } = useI18n();
  const { title, userType, loginUser, canClick, navigation } = props;
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm();
  let requestParam: { prefix: string, requestId: string, capId: string };
  if (isWeb) {
    requestParam = useParams();
  }

  const handleKeyDown = React.useCallback((event) => {
    const { key } = event;
    if (key == 'Enter') {
      loginUser(getValues());
    }
  }, []);

  useEffect(() => {
    getTokenFromStorage().then((userExist) => {
      if (userExist && userExist !== null) {
        history.push('/dashboard');
      }
    });
  });

  useEffect(() => {
    if (isWeb) {
      window.addEventListener('keydown', handleKeyDown, true);
    }
    return () => {
      if (isWeb) {
        window.removeEventListener('keydown', handleKeyDown, true);
      }
    };
  }, []);

  const goToChangePassword = () => {
    let webPath = 'edba';
    let nativePath = navigationStrings.REQUEST_RESET_PASSWORD;
    if (isWeb) {
      window.location.href = `/request-reset-password.html`;
    } else {
      navigation.navigate(nativePath);
    }
  };

  const getSubHeader = (userType: string) => {
    switch (userType) {
      case USER_TYPE_ENUM[1]:
        return t("loginEmployeeSubHeader.text");
      case USER_TYPE_ENUM[2]:
        return t("loginStudentSubHeader.text");
      case USER_TYPE_ENUM[3]:
        return t("loginApplicantSubHeader.text");
      default:
        return "";
    }
  };

  function navigateToApplicantSignup() {
    navigation.navigate(navigationStrings.SIGNUP_APPLICANT)
  }

  return (
    <>
      <FlashMessage position="top" />
      <AvoidKeyPad
        extraHeight={180}
        style={{
          height: '90%',
          flex: 1,
          backgroundColor: colors.white,
        }}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <Container>
            <PageAreaContainer>
              <View style={styles.loginDiv}>
                <CenterView style={isWeb && { marginTop: 20 }}>
                  {isNative ? (
                    <NativeEDBALogo source={require('src/assets/logo/new-logo-4x.png')} />
                  ) : (
                    <Icon name={'edba-logo'} size={56} />
                  )}
                </CenterView>

                {isNative && (
                  <BackgroundImage
                    source={require('src/assets/login-background-4x.png')}
                    resizeMode="contain"
                  />
                )}

                <CenterView style={[isWeb && styles.marginTop30, { marginBottom: isIOS ? 5 : 0 }]}>
                  <HeaderThree
                    value={title}
                    lineHeight={isWeb ? 32 : 25}
                    fontWeight={isWeb ? 'normal' : 'bold'}
                    fontSize={isWeb ? 24 : 21}
                  />
                </CenterView>

                <CenterView>
                  <NormalTextAtom
                    style={{ textAlign: 'center' }}
                    value={isWeb ? t("signInDescription.text") : getSubHeader(userType)}
                  />
                </CenterView>

                <InputView>
                  <Controller
                    control={control}
                    name={'userName'}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        id={'loginemail'}
                        header={'Email ID*'}
                        inputRef={ref}
                        onBlur={onBlur}
                        label={''}
                        value={value}
                        setValue={onChange}
                        width={isWeb ? 350 : null}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: validation.EMAIL_ID_REQUIRED,
                      },
                    }}
                  />
                  {errors.userName ? (
                    <MarginTop4View>
                      <SmallTextAtom value={t(errors.userName.message)} color={colors.errorColor} />
                    </MarginTop4View>
                  ) : null}
                </InputView>
                <MarginTop24View>
                  <Controller
                    control={control}
                    name={'password'}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <InputPassword
                        id={'loginpw'}
                        header={'Password*'}
                        inputRef={ref}
                        onBlur={onBlur}
                        label={''}
                        value={value}
                        width={350}
                        setValue={onChange}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: validation.PASSWORD_REQUIRED,
                      },
                    }}
                  />

                  <MarginTop4View>
                    {errors.password ? (
                      <SmallTextAtom value={errors.password.message} color={colors.errorColor} />
                    ) : null}
                  </MarginTop4View>
                </MarginTop24View>
                {isWeb && (
                  <LinkView>
                    <TouchableOpacity
                      style={{ alignSelf: 'flex-end' }}
                      onPress={goToChangePassword}>
                      <SmallTextAtom value={t('forgot-password.text')} color={colors.primary} />
                    </TouchableOpacity>
                  </LinkView>
                )}

                {isWeb && windowWidth > breakpoints.md ? (
                  <View style={{ marginTop: 32 }}>
                    <SecondaryBtn
                      canLoad={!canClick}
                      disabled={isSubmitting}
                      label={isSubmitting ? 'Logging in...' : 'Sign In'}
                      onPress={handleSubmit(loginUser)}
                      style={{ paddingVertical: 8 }}
                      lineHeight={2.4}
                    />
                    {isWeb && userType === USER_TYPE_ENUM[3] && (
                      <RegisterView>
                        <SmallTextAtom value={`Don't have an account yet?`} />
                        <RegisterLink
                          style={{ paddingLeft: 2 }}
                          onPress={() => {
                            let signupPath = '/applicant/signup';
                            if (requestParam.prefix && requestParam.requestId) {
                              signupPath += `/${requestParam.prefix}/${requestParam.requestId}`;
                            }
                            if (requestParam.capId) {
                              signupPath += `/${requestParam.capId}`;
                            }
                            history.push(signupPath);
                          }}>
                          <SmallTextAtom value={'Sign Up'} color={colors.primary} />
                        </RegisterLink>
                      </RegisterView>
                    )}
                  </View>
                ) : (
                  <React.Fragment>
                    <View style={{ marginTop: 24 }}>
                      <SecondaryBtn
                        canLoad={!canClick}
                        disabled={isSubmitting}
                        label={isSubmitting ? 'Logging in...' : 'Sign In'}
                        onPress={handleSubmit(loginUser)}
                        style={{ height: 48, borderRadius: 32 }}
                      />
                    </View>
                    <View style={{ marginTop: 12 }}>
                      <TouchableOpacity
                        style={{ alignSelf: 'center' }}
                        onPress={goToChangePassword}>
                        <SmallTextAtom
                          value={t('forgot-password.text')}
                          color={colors.primary}
                          fontWeight={'bold'}
                        />
                      </TouchableOpacity>
                    </View>
                  </React.Fragment>
                )}
              </View>
              {!isWeb && userType === USER_TYPE_ENUM[3] &&
                <ApplicantSignUpWrapper onPress={navigateToApplicantSignup}>
                  <SmallTextAtom
                    value={t("applicantSignInUpDescription.text")}
                    color={colors.primary}
                    fontWeight={'bold'}
                  />
                </ApplicantSignUpWrapper>}
            </PageAreaContainer>
          </Container>
        </TouchableWithoutFeedback>
      </AvoidKeyPad>
    </>
  );
}

const styles = StyleSheet.create({
  marginTop30: {
    marginTop: 30,
  },
  loginDiv: {
    backgroundColor: '#fff',
    borderRadius: 3,
    paddingTop: isWeb ? 60 : 42,
    paddingBottom: isWeb ? 102 : isIOS ? 47 : 24,
    paddingHorizontal: 85,
    ...boxShadow(),
  },
});

const Container = styled.View`
  flex: 1;
  align-items: center;
  background-color: ${colors.white};
`;

const CenterView = styled.View`
  justify-content: center;
  align-items: center;
`;

const PageAreaContainer = styled.View`
  justify-content: center;
  flex-grow: 1;
  border-color: #666666;
  width: 520px;
  height: 578px;
  background-color: ${isIOS ? '#ffffff' : 'transparent'};
`;

const LinkView = styled.View`
  margin-top: ${isWeb ? '4px' : '12px'};
  text-align: right;
`;

const MarginTop24View = styled.View`
  margin-top: 24px;
`;

const InputView = styled.View`
  margin-top: ${isWeb ? '10px' : '16px'};
`;

const MarginTop4View = styled.View`
  margin-top: 4px;
`;

const NativeEDBALogo = styled.Image`
  height: 40px;
  width: 112px;
`;

const BackgroundImage = styled.Image`
  height: 180px;
  width: ${width > 400 ? 450 : '120%'};
  margin-top: 5%;
  margin-left: ${width > 400 ? '-10%' : '-10%'};
`;

const RegisterView = styled.View`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-self: center;
`;

const RegisterLink = styled.TouchableOpacity`
  padding-left: 2px;
`;

const ApplicantSignUpWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-self: center;
  position: absolute;
  bottom: 32px;
`;
