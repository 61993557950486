import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { useI18n } from 'src/i18n/hooks';
import { colors, fontWeight } from 'src/styles/theme/styles';
import { height, isNative, isWeb } from 'src/constant/device';
import { View } from 'react-native';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { useParams } from 'react-router-dom';
import { usePaymentDataForStudent } from '../HooksAndConstants';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner';
import { userInformation } from 'src/utils/manageState';
import { getStudentInfo } from 'src/graphql/attendance';
import { graphqlQuery } from 'src/graphql/util';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { Icon } from 'src/components/atoms/Icon/Icon';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { clipLongText, properCase } from 'src/utils/utility';

export default (props: any) => {
  const { t } = useI18n();
  const params: any = isWeb ? useParams() : '';
  const currentUserObject = userInformation();
  const { userId }: any = currentUserObject;

  const [studentInfo, setStudentInfo] = useState<{
    className: string;
    fullName: string;
    rollNumber: number | string;
    instituteArea: string | null;
    instituteCity: string | null;
  } | null>(null);

  const getReceiptData = () => {
    if (isWeb) {
      const data: any = params;
      if (data) {
        return data.receiptId;
      }
    } else {
      const { receiptId } = props.route.params;
      return receiptId;
    }
  };

  const {
    paymentList: receiptData,
    totalPaid,
    totalPending,
    totalPayable,
  }: any = usePaymentDataForStudent(false, getReceiptData(), false);

  async function getStudentsData() {
    if (userId && !studentInfo) {
      const { loading: fetchStudentsDataLoading, data: studentsData } = await graphqlQuery(
        getStudentInfo,
        {
          studentId: userId,
        },
        true,
      );
      if (!fetchStudentsDataLoading && studentsData && !studentInfo) {
        setStudentInfo({
          className: studentsData.instituteStudent.academic.class.name,
          fullName: properCase(studentsData?.instituteStudent?.personalDetails?.fullName),
          rollNumber: studentsData.instituteStudent.academic.rollNo,
          instituteArea: studentsData.instituteStudent.institute.addressDetails.area,
          instituteCity: studentsData.instituteStudent.institute.addressDetails.city,
        });
      }
    }
  }

  useEffect(() => {
    if (userId) {
      getStudentsData();
    }
  }, [userId]);

  if (!receiptData || !studentInfo || !currentUserObject) {
    return (
      <View>
        {isNative && <MobileHeader label={t('receipt.label')} backIcon={true} {...props} />}
        <LoadSpinnerWrapper>
          <LoaderSpinner />
        </LoadSpinnerWrapper>
      </View>
    );
  }

  const DetailsContainer = ({
    label,
    value,
    align = 'flex-start',
    textCount = 20,
    lines = 1
  }: {
    label: string;
    value: string | number;
    align?: string;
    textCount?: number;
    lines?: number;
  }) => {
    return (
      <DetailWrapper align={align}>
        <TextWrapper>
          <MediumTextSelect value={`${label}:`} color={colors.primaryText} fontSize={1.8} />
        </TextWrapper>
        <View>
          {isWeb ? (
            <MediumTextSelect lines={lines} value={value || '-'} fontSize={1.8} color={colors.black} />
          ) : (
            <NormalTextSelect lines={lines} value={lines > 1 ? value : clipLongText(value, textCount) || '-'} fontsize={1.7} lineHeight={2} />
          )}
        </View>
      </DetailWrapper>
    );
  };

  return (
    <ContainerWrapper>
      {isNative && <MobileHeader label={t('receipt.label')} backIcon={true} {...props} />}

      <Wrapper showsVerticalScrollIndicator={false}>
        <HeaderWrapper>
          <InstituteNameWrapper>
            <HeaderThree
              value={currentUserObject?.userDetail?.instituteName || '-'}
              color={colors.primaryText}
              fontWeight={fontWeight[600]}
              fontSize={isWeb ? 24 : 20}
              numberOfLines={3}
            />
          </InstituteNameWrapper>
          {studentInfo?.instituteArea && studentInfo?.instituteCity && (
            <InstituteDetailWrapper>
              <MediumTextSelect
                lines={1}
                value={`${studentInfo.instituteArea}, ${studentInfo.instituteCity}`}
                color={colors.primaryText}
                textAlign="center"
                fontSize={isWeb ? 2.0 : 1.8}
              />
            </InstituteDetailWrapper>
          )}
        </HeaderWrapper>
        <HorizontalBorder />

        <FeeDetailWrapper>
          <MediumTextSelect
            value={'Fee Receipt'}
            color={colors.primaryText}
            fontSize={isWeb ? 2.2 : 2.0}
            textAlign="center"
          />
          <StudentDetailWrapper>
            <NameWrapper>
              <DetailsContainer
                label={'Name'}
                value={properCase(studentInfo?.fullName || '-')}
                textCount={30}
              />
              {studentInfo?.rollNumber && isWeb && (
                <DetailsContainer label={'Roll No'} value={studentInfo?.rollNumber} />
              )}
            </NameWrapper>
            <DetailsContainer label={'Class'} value={studentInfo?.className} lines={2} />
            <DetailsContainer label={'Mode'} value={receiptData[0]?.methodofpayment} textCount={30} />
            {studentInfo?.rollNumber && isNative && (
              <DetailsContainer label={'Roll No'} value={studentInfo?.rollNumber} textCount={30} />
            )}
          </StudentDetailWrapper>
        </FeeDetailWrapper>
        <HorizontalBorder />

        <FeeDetailWrapper>
          <DetailsContainer
            label="Total Fees"
            value={`\u20B9 ${totalPayable}`}
            align={'space-between'}
          />
          <DetailsContainer
            label="Total Received"
            value={`\u20B9 ${receiptData[0]?.amount}`}
            align={'space-between'}
          />
        </FeeDetailWrapper>
        <HorizontalBorder />

        <FeeDetailWrapper>
          <PaymentHeader>
            <MediumTextSelect
              value={'Payment Details'}
              color={colors.primaryText}
              fontSize={isWeb ? 2.2 : 2.0}
            />
          </PaymentHeader>

          <DetailsContainersWrapper>
            <DetailsContainer label={'Invoice No'} value={receiptData[0]?.orderId} />
            <DetailsContainer label={'Transaction ID'} value={receiptData[0]?.transactionId} />
            <DetailsContainer label={'Amount Paid'} value={`\u20B9 ${receiptData[0]?.amount}`} />
            <DetailsContainer label={'Approved By'} value={receiptData[0]?.approvedBy} lines={2} />
            <DetailsContainer
              label={'Payment Date'}
              value={format(new Date(receiptData[0]?.date), DT.DATE_TIME_SLASH_12_HOUR)}
            />
          </DetailsContainersWrapper>
        </FeeDetailWrapper>

        <FooterWrapper>
          <MediumTextSelect
            value={'Powered By'}
            color={colors.primaryText}
            fontSize={isWeb ? 2.2 : 1.8}
          />
          <IconWrapper>
            <Icon name={'edba-logo'} size={22} />
          </IconWrapper>
        </FooterWrapper>
      </Wrapper>
    </ContainerWrapper>
  );
};

const LoadSpinnerWrapper = styled.View`
  justify-content: center;
  align-items: center;
  height: ${isWeb ? 600 : height - 150}px;
`;

const Wrapper = styled.ScrollView`
  flex: 1;
  background-color: ${colors.white};
  border-width: ${isWeb ? 1 : 0}px;
  padding: 16px;
`;

const HorizontalBorder = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.secondaryText};
`;

const DetailWrapper = styled.View<{ align: string }>`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => props?.align};
  margin: 3px 0px;
  align-items: center;
  max-width: 99%;
`;

const TextWrapper = styled.View`
  margin-right: 10px;
`;

const DetailsContainersWrapper = styled.View`
  margin-vertical: 8px;
`;

const FooterWrapper = styled.View`
  display: flex;
  margin:0 0 20px;
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
  bottom: ${isWeb ? 0 : 8}px;
`;

const PaymentHeader = styled.View`
  margin-bottom: 8px;
`;

const HeaderWrapper = styled.View`
  flex-direction: column;
  padding-bottom: 16px;
`;

const InstituteDetailWrapper = styled.View`
  padding-top: 8px;
`;

const FeeDetailWrapper = styled.View`
  padding: 16px 0px;
`;

const InstituteNameWrapper = styled.View`
  align-self: center;
  max-width: 100%;
`;

const NameWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StudentDetailWrapper = styled.View`
  padding-top: 16px;
`;

const IconWrapper = styled.View`
  margin-top: -4px;
  margin-left: 8px;
`;

const ContainerWrapper = styled.View`
  flex: 1;
  height: 100%;
`;
