import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useQuery } from '@apollo/client';
import { graphqlQuery } from 'src/graphql/util';
import { getStudentsQuery } from 'src/graphql/classes';
import groupAudience from 'src/form-json/communication/students-audience.json';
import Element from 'src/components/molecules/Forms/ApplicationElement';
import { AUTOCOMPLETE_MODULE } from 'src/constant';
import { isWeb } from 'src/constant/device';
import { DropdownOptionsType } from 'src/types';
import { createQueryParameterForStudent } from 'src/components/services/filters';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { colors } from 'src/styles/theme/styles';
import { getFilteredSubjects } from 'src/components/services';
import { getModifiedSubjectsQuery } from 'src/graphql/academics/subjects';
import Chip from 'src/components/atoms/Chip';
import { clipLongText, mergeBatchClassLabel, properCase } from 'src/utils/utility';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form/dist/types';

interface StudentsAudienceProps {
  parentType: string;
  audienceData: Array<string>;
  setAudienceData: Function;
  removeAudience: Function;
  handleChecked: Function;
  batchIds: Array<string>;
  studentList: Array<string>;
  studentFormControl: Control<FieldValues, object>;
  studentFormError: any;
  setStudentFormValue: UseFormSetValue<FieldValues>;
  isEdit: boolean;
}

export default function CustomStudentsGroupAudience(props: StudentsAudienceProps) {
  const {
    studentFormControl,
    studentFormError,
    setStudentFormValue,
    parentType,
    audienceData,
    setAudienceData,
    removeAudience,
    handleChecked,
    batchIds,
    studentList,
    isEdit,
  } = props;
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);

  const [elements, setElements] = useState<any>({});
  const [batch, setBatch] = useState<any>({});
  const [batches, setBatches] = useState<any>({});
  const [division, setDivision] = useState(null);
  const { fields }: any = elements ?? {};
  const allBatches = useQuery(getBatchQuery, {
    variables: { limit: 0 },
    fetchPolicy: 'network-only',
  });

  const [searchData, setSearchData] = useState('');
  const [chips, setChips] = useState([]);

  useEffect(() => {
    setStudentFormValue(AUTOCOMPLETE_MODULE.DIVISION, null);
    setStudentFormValue(AUTOCOMPLETE_MODULE.SUBJECT, null);
    return () => {
      groupAudience[0].fields[1].option = [];
      groupAudience[0].fields[2].option = [];
      groupAudience[0].fields[1].fieldVisible = true;
      groupAudience[0].fields[1].required = true;
      groupAudience[0].fields[0].fieldVisible = true;
      groupAudience[0].fields[0].required = true;
      groupAudience[0].fields[2].fieldVisible = true;
      groupAudience[0].fields[2].required = true;
      groupAudience[0].fields[0].textHead = "batchClass.required.label";
      groupAudience[0].fields[2].textHead = "division.required.label";
    }
  }, []);

  useEffect(() => {
    onLoadFunction();
  }, [allBatches.data]);

  useEffect(() => {
    onloadEditFunction();
  }, [audienceData]);

  useEffect(() => {
    if (batch && batch.class) {
      setTimeout(() => {
        loadDivisions(batch.divisionDetails?.map((det) => det.division));
      });
      if (parentType === 'ASSIGNMENT') {
        getSubjects(batch?.class?.id);
      }
    }
  }, [batch]);

  useEffect(() => {
    if (parentType !== 'CONCESSION' || batchIds.length > 0) {
      loadSelectedStudents();
    }
  }, [audienceData, batch, division]);

  function onloadEditFunction() {
    if (isEdit && audienceData?.length > 0) {
      groupAudience[0].fields[0].required = false;
      groupAudience[0].fields[0].textHead = "batchClass.label";
      groupAudience[0].fields[2].required = false;
      groupAudience[0].fields[2].textHead = "division.label";
    } else {
      groupAudience[0].fields[0].required = true;
      groupAudience[0].fields[0].textHead = "batchClass.required.label";
      groupAudience[0].fields[2].required = true;
      groupAudience[0].fields[2].textHead = "division.required.label";
    }
  }

  function onLoadFunction() {
    if (allBatches.loading === false) {
      if (parentType === 'CONCESSION' && studentList.length !== 0) {
        groupAudience[0].fields[0].fieldVisible = false;
        groupAudience[0].fields[0].required = false;
        groupAudience[0].fields[2].fieldVisible = false;
        groupAudience[0].fields[2].required = false;
        setFilteredStudents(studentList);
      } else {
        groupAudience[0].fields[0].fieldVisible = true;
        groupAudience[0].fields[0].required = true;
        groupAudience[0].fields[2].fieldVisible = true;
        groupAudience[0].fields[2].required = true;
      }

      let getdata = [] as any;
      if (parentType === 'CONCESSION' && batchIds.length > 0) {
        allBatches?.data?.batches?.data.forEach((element: any) => {
          if (batchIds.includes(element?.value)) {
            getdata.push(element);
          }
        });
        getdata = mergeBatchClassLabel(getdata);
      } else {
        getdata = mergeBatchClassLabel(allBatches.data?.batches?.data);
      }
      groupAudience[0].fields[0].option = getdata;
      setBatches(getdata);
      if (parentType && parentType !== 'ASSIGNMENT') {
        groupAudience[0].fields[1].fieldVisible = false;
        groupAudience[0].fields[1].required = false;
      } else {
        groupAudience[0].fields[1].fieldVisible = true;
        groupAudience[0].fields[1].required = true;
      }
      onloadEditFunction();
      setElements(groupAudience[0]);
    }
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      setIsDataAvailable(false)
      if (parentType && parentType === 'ASSIGNMENT') {
        setAudienceData([]);
        setStudentFormValue(AUTOCOMPLETE_MODULE.SUBJECT, null);
      }
      setDivision(null);
      setFilteredStudents([]);
      setSelectedStudents([]);
      data = isWeb ? data : batches.find((bt: any) => bt.value === data);
      setStudentFormValue(AUTOCOMPLETE_MODULE.DIVISION, null);
      setBatch(data);
    }

    if (id === AUTOCOMPLETE_MODULE.DIVISION) {
      setIsDataAvailable(false)
      selectDivision(data);
    }
  }

  async function getSubjects(classId: string) {
    let filters = {
      class: {
        eq: classId,
      },
    };
    const subjectsResponse = await getFilteredSubjects(
      { filters: filters },
      getModifiedSubjectsQuery
    )
    loadSubjects(subjectsResponse?.options);
  }

  function loadSubjects(subjectArray: any) {
    const modifiedData = { ...groupAudience[0] };
    modifiedData.fields[1].option = subjectArray;
    setElements(modifiedData);
  }

  async function selectDivision(data: any) {
    const division = isWeb ? data?.value : data;
    setDivision(division);
    setSearchData('');
    setStudents([]);
    setFilteredStudents([]);
    if (division) {
      const getFilter: any = createQueryParameterForStudent(
        batch.value,
        batch.class.id,
        division,
        '',
      );
      getFilter['limit'] = 0;
      let { data: allStudents, loading } = await graphqlQuery(getStudentsQuery, getFilter);
      if (!loading && allStudents?.instituteStudents) {
        let instituteStudents = allStudents?.instituteStudents?.data;
        const studentList = instituteStudents.map((item: any) => ({
          id: item?.id,
          name: properCase(item?.fullName),
        }))
        setStudents(studentList);
        setFilteredStudents(studentList);
        setIsDataAvailable(true)
      }
    } else {
      setStudents([]);
      setFilteredStudents([]);
    }
  }

  function loadDivisions(divisionArray: DropdownOptionsType[]) {
    const modifiedData = { ...groupAudience[0] };
    let options = [] as any;
    divisionArray?.forEach(function (item: any) {
      options.push({
        value: item,
        label: item,
      });
    });
    modifiedData.fields[2].option = options;
    setElements(modifiedData);
  }

  function loadSelectedStudents() {
    if (audienceData && audienceData.length > 0) {
      let students = audienceData.filter((item: any) => {
        const batchId = item.audience.split('/')[1];
        const divisionId = item.audience.split('/')[2];
        return batchId === batch?.value && divisionId === division;
      });
      students = students.map((item: any) => item.audience.split("/").pop());
      setSelectedStudents(students);
    } else {
      setSelectedStudents([]);
    }
  }

  useEffect(() => {
    handleCreateChips();
  }, [audienceData.length]);

  function handleCreateChips() {
    if (parentType !== 'CONCESSION' || batchIds.length > 0) {
      const audienceMap = new Map();
      let chipsData = [] as any;
      audienceData.forEach((item: any) => {
        const audience = `${item.audience.split('/')[1]}/${item.audience.split('/')[2]}`;
        const audienceCount = audienceMap.get(audience);
        if (audienceCount && audienceCount.count > 0) {
          audienceMap.set(audience, { text: item?.text, count: audienceCount.count + 1 });
        } else {
          audienceMap.set(audience, { text: item?.text, count: 1 });
        }
      });

      audienceMap.forEach((value) => {
        chipsData.push(value);
      });
      setChips(chipsData);
    } else {
      const chips = [] as any;
      studentList.forEach((item: any) => {
        if (audienceData.includes(item?.id)) {
          chips.push(item?.name);
        }
      })
      setChips(chips);
      setSelectedStudents(audienceData);
    }
  }

  const handleCheckChild = (item: any) => {
    if (parentType !== 'CONCESSION' || batchIds.length > 0) {
      handleChecked(item, batch, null, division);
    } else {
      const proxy = [...selectedStudents];
      if (proxy.includes(item)) {
        const index = proxy.indexOf(item);
        if (index > -1) {
          proxy.splice(index, 1);
        }
      } else {
        proxy.push(item);
      }
      setSelectedStudents(proxy);
      setAudienceData(proxy);
    }
  };

  function searchBoxOnChange(data: string) {
    setSearchData(data);
    if (students.length) {
      if (!data.length) {
        setFilteredStudents(students);
      } else {
        const updatedData = data.toLowerCase().trim();
        setFilteredStudents(
          students.filter((studentinfo: any) => {
            if (studentinfo?.name.toLowerCase().includes(updatedData)) {
              return true;
            }
            return false;
          }),
        );
      }
    }
  }

  return (
    <>
      <View style={styles.containerView}>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View key={`parentele${i}`}>
              <Element
                field={field}
                control={studentFormControl}
                errors={studentFormError}
                handleCustomSelect={handleCustomSelect}
                handleCheck={handleCheckChild}
                checked={selectedStudents}
                checkboxTableData={filteredStudents}
                searchMaxWidth={350}
                searchBoxOnChange={searchBoxOnChange}
                searchData={searchData}
                manualSelection={false}
                checkboxTableHeight={'auto'}
                checkboxTableMaxHeight={220}
                isDataAvailable={isDataAvailable}
              />
            </View>
          ))
        ) : (
          <View style={styles.spinnerView} />
        )}
      </View>
      <View style={[styles.chipWrapper]}>
        {chips &&
          chips?.map((audience: any, index: any) => (
            <View style={[styles.chipView]} key={`audience${index}`}>
              <Chip
                label={parentType === 'CONCESSION' && studentList.length > 0 ? audience : (
                  isWeb ?
                    `${audience?.text}: ${audience?.count} Students` :
                    clipLongText(`${audience?.text} : ${audience?.count} Students`, 42)
                )}
                onPress={() => removeAudience(parentType === 'CONCESSION' && studentList.length > 0 ?
                  index : audience?.text)}
                borderRadius={isWeb ? 4 : 20}
                chipFontSize={1.2}
              />
            </View>
          ))}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
  containerView: {
    borderWidth: 1,
    borderColor: colors.borderColor,
    borderStyle: 'dashed',
    borderRadius: 4,
    padding: 8
  },
  chipWrapper: { flexDirection: 'row', flexWrap: 'wrap', marginTop: 15 },
  chipView: { marginRight: 8, marginBottom: 8 },
});
