import * as React from 'react';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/system';
import { colors } from 'src/styles/theme/styles';

const PrimaryRadio = styled(Radio)`
  color: ${colors.tertiaryText};
  .Mui-checked {
    color: ${colors.primaryColor};
  }
`;

interface StandaloneRadioProps {
  isChecked: boolean;
  value: string | number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioStandalone = (props: StandaloneRadioProps) => {
  const { isChecked, handleChange, value } = props;
  return (
    <PrimaryRadio
      checked={isChecked}
      onChange={handleChange}
      value={value}
      name="radio-buttons"
      size="small"
    />
  );
};

export default RadioStandalone;
