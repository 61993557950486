import {
  ConcessionOptionsType,
  ConcessionsEntityType,
  FeeChild,
  TransformedFeeGroup,
} from "src/components/organism/Admission/types";
import {
  ExpandCollapseCell,
  TableCells,
  TableDiv,
  TableRows,
} from "./TableAtom";
import React, {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useState,
} from "react";

import LabelValueView from "../LabelValueView/index.web";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import NormalTextSelect from "src/components/atoms/Text/NormalTextSelect";
import RadioStandalone from "src/components/atoms/Radio/StandaloneRadio/index.web";
import { TableBody } from "@mui/material";
import { colors } from "src/styles/theme/styles";
import styled from "styled-components/native";
import { useI18n } from "src/i18n/hooks";
import useTable from "./UseTable";

interface PropTypes {
  tableData: ConcessionOptionsType[];
  concessionCompositionIdRef: MutableRefObject<string>;
  isDataAvailable: boolean;
}

const headCells1 = [
  {
    id: "moduleGroupName",
    label: "moduleGroupName.label",
    align: "left",
  },
  {
    id: "amount",
    label: "amount.label",
    align: "right",
  },
  {
    id: "concession",
    label: "concession.label",
    align: "right",
  },
  {
    id: "payableAmount",
    label: "payableAmount.label",
    align: "right",
  },
];

const ConcessionRadioSelectTable = (props: PropTypes) => {
  const { tableData, concessionCompositionIdRef, isDataAvailable } = props;
  const { t } = useI18n();
  const [trow, setT] = useState<boolean>(false);
  const [current, setCurrent] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState<string>(
    concessionCompositionIdRef?.current || ""
  );
  const [headCells, setHeadCells] = useState(headCells1);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    tableData,
    headCells,
    null,
    tableData?.length
  );

  useEffect(() => {
    if (selectedId) {
      concessionCompositionIdRef.current = selectedId;
    }
  }, [selectedId]);

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    setSelectedId(event.target.value);
  }

  function transformData(
    concessionsArr: ConcessionsEntityType[]
  ): TransformedFeeGroup[] {
    const result = {};
    for (const concession of concessionsArr) {
      const feeBook = concession.feeBook;
      if (!result[feeBook.groupId]) {
        result[feeBook.groupId] = {
          groupName: feeBook.groupName,
          groupId: feeBook.groupId,
          compositionName: feeBook.compositionName,
          totalAmount: 0,
          totalConcessionAmount: 0,
          totalPayable: 0,
          children: [],
        };
      }
      const group = result[feeBook.groupId];
      group.totalAmount += feeBook.amount;
      group.totalConcessionAmount += concession.amount;
      group.totalPayable += feeBook.amount - concession.amount;
      group.children.push({
        name: concession.name,
        id: concession.id,
        amount: feeBook.amount,
        concession: concession.amount,
        payable: feeBook.amount - concession.amount,
      });
    }
    return Object.values(result);
  }

  return (
    <TableDiv>
      <TblContainer>
        {tableData?.length > 0 ? (
          <TableBody>
            {recordsAfterPagingAndSorting()?.map(
              (item: ConcessionOptionsType, i: number) => (
                <>
                  <TableRows key={`e${i}`}>
                    <TableCells style={styles.radioBtnStyle}>
                      <RadioStandalone
                        isChecked={selectedId === item?.id}
                        value={item?.id}
                        handleChange={(e) => handleChange(e)}
                      />
                    </TableCells>
                    <TableCells value={item.name} />
                    <ExpandCollapseCell
                      trow={trow}
                      current={current}
                      i={i}
                      classNames={""}
                      textValue={""}
                      CollapseFn={() => {
                        setT(false);
                        setCurrent(null);
                      }}
                      ExpandFn={() => {
                        setT(true);
                        setCurrent(i);
                      }}
                    />
                  </TableRows>
                  {trow && current === i && (
                    <TableRows>
                      <TableCells colspan={3}>
                        {item?.concessions && item.concessions?.length > 0 && (
                          <>
                            <InfoWrapper>
                              <LabelValueView
                                label={t("feesComposition.label")}
                                value={
                                  item.concessions[0].feeBook.compositionName
                                }
                                width={"100%"}
                                marginBottom={"0px"}
                              />
                              <LabelValueView
                                label={t("total-concession.label")}
                                value={`\u20B9 ${item.concessions.reduce(
                                  (total, acc) => total + acc.amount,
                                  0
                                )}`}
                                width={"100%"}
                                marginBottom={"0px"}
                              />
                            </InfoWrapper>
                            <TableDiv>
                              <TblContainer>
                                <TblHead setHeadCells={setHeadCells} />
                                <TableBody>
                                  {transformData(item.concessions).map(
                                    (e: TransformedFeeGroup) => (
                                      <>
                                        <TableRows key={e.groupId}>
                                          <TableCells
                                            value={e.groupName}
                                            style={styles.noBottomBorder}
                                            boldText={true}
                                            fontSize={1.6}
                                          />
                                          <TableCells
                                            value={`\u20B9 ${e.totalAmount}`}
                                            style={styles.noBottomBorder}
                                            align={"right"}
                                            boldText={true}
                                            fontSize={1.6}
                                          />
                                          <TableCells
                                            value={`\u20B9 ${e.totalConcessionAmount}`}
                                            style={styles.noBottomBorder}
                                            align={"right"}
                                            boldText={true}
                                            fontSize={1.6}
                                          />
                                          <TableCells
                                            value={`\u20B9 ${e.totalPayable}`}
                                            style={styles.noBottomBorder}
                                            align={"right"}
                                            boldText={true}
                                            fontSize={1.6}
                                          />
                                        </TableRows>
                                        {e.children.map((c: FeeChild) => (
                                          <TableRows key={c.id}>
                                            <TableCells
                                              value={c.name}
                                              style={styles.childConcession}
                                              color={colors.secondaryText}
                                            />
                                            <TableCells
                                              value={`\u20B9 ${c.amount}`}
                                              style={styles.noBottomBorder}
                                              align={"right"}
                                              color={colors.secondaryText}
                                            />
                                            <TableCells
                                              value={`\u20B9 ${c.concession}`}
                                              style={styles.noBottomBorder}
                                              align={"right"}
                                              color={colors.secondaryText}
                                            />
                                            <TableCells
                                              value={`\u20B9 ${c.payable}`}
                                              style={styles.noBottomBorder}
                                              align={"right"}
                                              color={colors.secondaryText}
                                            />
                                          </TableRows>
                                        ))}
                                      </>
                                    )
                                  )}
                                  <Divider />
                                  <TableRows key={`${item.id}total`}>
                                    <TableCells
                                      value={t("payment.total-payment")}
                                      boldText={true}
                                      fontSize={1.6}
                                    />
                                    <TableCells
                                      value={`\u20B9 ${item.concessions.reduce(
                                        (acc, concession) => {
                                          return (
                                            acc + concession.feeBook.amount
                                          );
                                        },
                                        0
                                      )}`}
                                      align={"right"}
                                      boldText={true}
                                      fontSize={1.6}
                                    />
                                    <TableCells
                                      value={`\u20B9 ${item.concessions.reduce(
                                        (total, acc) => total + acc.amount,
                                        0
                                      )}`}
                                      align={"right"}
                                      boldText={true}
                                      fontSize={1.6}
                                    />
                                    <TableCells
                                      value={`\u20B9 ${item.concessions.reduce(
                                        (acc, concession) => {
                                          return (
                                            acc +
                                            (concession.feeBook.amount -
                                              concession.amount)
                                          );
                                        },
                                        0
                                      )}`}
                                      align={"right"}
                                      boldText={true}
                                      fontSize={1.6}
                                    />
                                  </TableRows>
                                </TableBody>
                              </TblContainer>
                            </TableDiv>
                          </>
                        )}
                      </TableCells>
                    </TableRows>
                  )}
                </>
              )
            )}
          </TableBody>
        ) : (
          <SpinnerView>
            {isDataAvailable ? (
              <NormalTextSelect value={t("no-records-found.text")} />
            ) : (
              <LoaderSpinner />
            )}
          </SpinnerView>
        )}
      </TblContainer>
    </TableDiv>
  );
};

const InfoWrapper = styled.View`
  display: grid;
  grid-template-columns: 70% 30%;
  column-gap: 24px;
  row-gap: 12px;
  margin-bottom: 24px;
`;

const SpinnerView = styled.View`
  height: 568px;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.View`
  border-bottom-width: 1;
  border-bottom-color: ${colors.dividerColor};
  width: 400%;
`;

const styles = {
  radioBtnStyle: { width: 16, padding: 1 },
  noBottomBorder: { border: "none" },
  childConcession: { paddingLeft: 30, border: "none" },
};

export default ConcessionRadioSelectTable;
