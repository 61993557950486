import {
  DashboardEmptyContainer as EmptyContainer,
  DemographicsScrollContainer as ScrollContainer,
} from "src/components/organism/Dashboard/Common/CommonHelpers";
import styled, { useTheme } from "styled-components";

import { DT } from "src/constant/dateTime";
import { DashboardListing } from "./CommonHelpers";
import React from "react";
import ScheduleChip from "src/components/atoms/Chip/ScheduleChip.web";
import { ScheduleDetail } from "../../Schedule/scheduleTypes";
import { convertTo12HourFormat } from "../../Schedule/scheduleHelpers";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { format } from "date-fns";
import { getScheduleByDay } from "src/graphql/timetable";
import { properCase } from "src/utils/utility";
import { useI18n } from "src/i18n/hooks";
import { useQuery } from "@apollo/client";
import { userInformation } from "src/utils/manageState";

function DashboardSchedule() {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const userInfo = userInformation();
  const { data, loading } = useQuery(getScheduleByDay, {
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        start: format(new Date(), DT.DATE_FORMAT_SLASH),
        end: format(new Date(), DT.DATE_FORMAT_SLASH),
        timezone: DT.TIMEZONE,
      },
      limit: 50,
    },
  });

  const ListEmptyComponent = () => (
    <EmptyContainer
      text={t("noLectures.text")}
      icon={faCalendarAlt}
      loading={loading}
    />
  );

  const todaysSchedule = data?.getFullScheduleByDay?.data?.[0]?.schedules ?? [];

  return (
    <DashboardListing
      title={"todaysSchedule.label"}
      redirectionURL={"/time-table"}
      allowRedirection={todaysSchedule.length > 0}
    >
      <ScrollContainer customHeight={55}>
        {todaysSchedule.length > 0 ? (
          <>
            <PadGap />
            {todaysSchedule.map((item: ScheduleDetail) => {
              const lectureinfo = {
                text1: `${item.subject.alias} (${item.subject.name})`,
                text2:
                  userInfo?.userType === 2
                    ? `${item.class?.alias} (${item.division})`
                    : properCase(item?.lecturer?.fullName),
                text3: `${convertTo12HourFormat(
                  item.startTime
                )} - ${convertTo12HourFormat(item.endTime)}`,
                color: item.subject?.color,
              };
              return (
                <Wrapper>
                  <ScheduleChip
                    elementheight={rem(9)}
                    borderRadius={4}
                    label1={lectureinfo.text1}
                    label2={lectureinfo.text2}
                    label3={lectureinfo.text3}
                    schedule={item}
                    visibility={lectureinfo.text1 ? true : false}
                    showFullData={true}
                    color={lectureinfo.color}
                  />
                </Wrapper>
              );
            })}
          </>
        ) : (
          <ListEmptyComponent />
        )}
      </ScrollContainer>
    </DashboardListing>
  );
}

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(1.6)};
`;

const PadGap = styled.div`
  height: 20px;
  width: 100%;
`;

export default DashboardSchedule;
