import React, { useEffect, useState } from 'react';
import {
  ExpandCollapseCell,
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import styled from 'styled-components';
import useTable from 'src/components/molecules/Table/UseTable';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { getTableValue, getCellStyle } from 'src/utils/utility';
import { TblHeadResults } from 'src/components/organism/Assessment/Results/TblHeadResults';

const headCells1 = [
  {
    // This is to accomodate the dropdown icon caret
    id: 'blank_caret_icon',
    label: '',
    align: 'left',
  },
  {
    id: 'subjectCode',
    label: 'subjectCode.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 150, paddingLeft: 0 },
  },
  {
    id: 'subject',
    label: 'subject.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 176 },
  },
];

const marksHeadCells = [
  {
    id: 'subjectCode',
    label: 'subjectCode.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 150, paddingLeft: 0 },
  },
  {
    id: 'subject',
    label: 'subject.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 176 },
  },
  {
    id: 'maxMarks',
    label: 'maxMarks.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: 100 },
  },
  {
    id: 'passingMarks',
    label: 'passingMarks.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: 100 },
  },
  {
    id: 'obtainedMarks',
    label: 'obtainedMarks.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: 100 },
  },
  {
    id: 'grace',
    label: 'grace.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: 50 },
  },
  {
    id: 'finalObtainedMarks',
    label: 'finalObtainedMarks.label',
    align: 'right',
    disableSorting: true,
    style: { minWidth: 100 },
  },
]

let actionHeadCells = [
  {
    id: 'testName',
    label: 'test-name.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 150 },
  },
  {
    id: 'creditsEarned',
    label: 'credits-earned.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 100, textAlign: 'right' },
  },
  {
    id: 'grade',
    label: 'grade.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 70, paddingLeft: 50 },
  },
  {
    id: 'gradePoint',
    label: 'grade-point.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 100, textAlign: 'right' },
  },
];

export default function ViewResultsResolution(props: any) {
  const { data, marksType } = props;
  const [row, setRow] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>();
  const [headCells, setHeadCells] = useState([
    ...(marksType ? marksHeadCells : [...headCells1, ...actionHeadCells]),
  ]);
  const [nestedHeadCells, setNestedHeadCells] = useState([]);
  const [trow, setT] = useState(false);
  const [current, setCurrent] = useState<any>(null);

  useEffect(() => {
    setRow(data);
    setTotalCount(data?.length);

    if (!marksType) {
      if (data && data.length) {
        let temp_dynamicHeadcells = [] as any;
        let temp_dynamicHeadcells1 = [] as any;
  
        const header = ['Total Marks'];
        const headCells = ['Max', 'Min', 'Obt', 'Grace', 'Final'];
  
        header.forEach((item: string, idx: number) => {
          temp_dynamicHeadcells.push({
            id: `${item}${idx}`,
            label: item,
            align: 'center',
            disableSorting: true,
            dynamic: true,
            style: {
              minWidth: 150,
            },
          });
  
          headCells.forEach((hc: string, idx: number) => {
            temp_dynamicHeadcells1.push({
              id: `${hc}${idx}`,
              label: hc,
              align: 'center',
              disableSorting: true,
              dynamic: true,
              style: {
                minWidth: 38,
              },
            });
          });
  
          if (idx !== header.length - 1) {
            temp_dynamicHeadcells.push({
              disableSorting: true,
              style: {
                minWidth: 22,
              },
            });
            temp_dynamicHeadcells1.push({
              disableSorting: true,
              style: {
                minWidth: 22,
              },
            });
          }
        });
  
        actionHeadCells[1].style = { minWidth: 70, paddingLeft: 10 };
        setHeadCells([...headCells1, ...temp_dynamicHeadcells, ...actionHeadCells]);
        setNestedHeadCells(temp_dynamicHeadcells1);
      } else {
        actionHeadCells[1].style = { minWidth: 70, paddingLeft: 50 };
        setHeadCells([...headCells1, ...actionHeadCells]);
        setNestedHeadCells([]);
      }
    }
  }, [data, marksType]);

  const { TblHead, TblContainer, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
  );

  return (
    <ParentContainer>
      <TableDiv customStyle={styles().tableDiv}>
        <TblContainer overflowX="scroll" maxHeight={540}>
          {marksType ? (
            <TblHead setHeadCells={setHeadCells} />
          ) : (
            <TblHeadResults
              dynamicHeadCells={headCells}
              dynamicHeadCells2={nestedHeadCells}
              checkBox={false}
              page={'Grace'}
            />
          )}
          {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 ? (
            recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
              <>
                <TableBody>
                  <TableRows key={`vrm${i}`}>
                    {!marksType && (
                      <ExpandCollapseCell
                        trow={trow}
                        current={current}
                        i={i}
                        style={{
                          ...getCellStyle({
                            trow,
                            current,
                            id: i,
                            isFirstCell: true,
                            isLastCell: true,
                            itemId: item?.id,
                          }),
                        }}
                        textValue={''}
                        CollapseFn={() => {
                          setT(false);
                          setCurrent(null);
                        }}
                        ExpandFn={() => {
                          setT(true);
                          setCurrent(i);
                        }}
                      />
                    )}
                    <TableCells
                      value={item?.marks?.subject?.code || '-'}
                      style={{
                        padding: 0,
                        ...getCellStyle({
                          trow,
                          current,
                          id: i,
                          isFirstCell: false,
                          isLastCell: false,
                          itemId: item?.id,
                        }),
                      }}
                      // style={styles().subjectTableCell}
                    />
                    <TableCells
                      style={{
                        ...getCellStyle({
                          trow,
                          current,
                          id: i,
                          isFirstCell: false,
                          isLastCell: false,
                          itemId: item?.id,
                        }),
                      }}
                      value={item?.marks?.subject?.name || '-'}
                    />

                    {marksType ? (
                      <>
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                          value={getTableValue(item?.marks?.averageTotalMarks, '-')}
                          align={'right'}
                        />
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                          value={getTableValue(item?.marks?.averagePassingMarks, '-')}
                          align={'right'}
                        />
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                          value={getTableValue(item?.marks?.average, '-')}
                          align={'right'}
                        />
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                          value={getTableValue(item?.marks?.totalGrace, '-')}
                          align={'right'}
                        />
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                          value={getTableValue(item?.marks?.averageWithGrace, '-')}
                          align={'right'}
                        />
                      </>
                    ) : (
                      <>
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                          value={
                            Boolean(item?.testMarks && item.testMarks.length > 0) &&
                            `Total(${item.testMarks
                              .map((testData: any, i: number) => testData?.test?.name)
                              .join(' + ')})`
                          }
                        />
                        {nestedHeadCells && nestedHeadCells.length > 0 && (
                          <>
                            <TableCells
                              style={{
                                justifyContent: 'center',
                                ...getCellStyle({
                                  trow,
                                  current,
                                  id: i,
                                  isFirstCell: false,
                                  isLastCell: false,
                                  itemId: item?.id,
                                }),
                              }}
                              value={getTableValue(item?.marks?.totalMarks, '-')}
                              // textStyle={styles().nestedCell}
                            />
                            <TableCells
                              style={{
                                justifyContent: 'center',
                                ...getCellStyle({
                                  trow,
                                  current,
                                  id: i,
                                  isFirstCell: false,
                                  isLastCell: false,
                                  itemId: item?.id,
                                }),
                              }}
                              value={getTableValue(item?.marks?.passingMarks, '-')}
                              // textStyle={styles().nestedCell}
                            />
                            <TableCells
                              style={{
                                justifyContent: 'center',
                                ...getCellStyle({
                                  trow,
                                  current,
                                  id: i,
                                  isFirstCell: false,
                                  isLastCell: false,
                                  itemId: item?.id,
                                }),
                              }}
                              value={getTableValue(item?.marks?.marksObtained, '-')}
                              // textStyle={styles().nestedCell}
                            />
                            <TableCells
                              style={{
                                justifyContent: 'center',
                                ...getCellStyle({
                                  trow,
                                  current,
                                  id: i,
                                  isFirstCell: false,
                                  isLastCell: false,
                                  itemId: item?.id,
                                }),
                              }}
                              // textStyle={styles().nestedCell}
                              value={item?.marks?.totalGrace || '-'}
                            />
                            <TableCells
                              style={{
                                justifyContent: 'center',
                                ...getCellStyle({
                                  trow,
                                  current,
                                  id: i,
                                  isFirstCell: false,
                                  isLastCell: false,
                                  itemId: item?.id,
                                }),
                              }}
                              // textStyle={styles().nestedCell}
                              value={item?.marks?.finalMarksObtained || '-'}
                            />
                          </>
                        )}
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                          value={getTableValue(item?.marks?.creditsEarned, '-')}
                          align={'right'}
                        />
                        <TableCells
                          style={{
                            paddingLeft: 80,
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: false,
                              itemId: item?.id,
                            }),
                          }}
                          align={'left'}
                          value={getTableValue(item?.marks?.grade, '-')}
                        />
                        <TableCells
                          style={{
                            ...getCellStyle({
                              trow,
                              current,
                              id: i,
                              isFirstCell: false,
                              isLastCell: true,
                              itemId: item?.id,
                            }),
                          }}
                          align={'right'}
                          value={getTableValue(item?.marks?.gradePoint, '-')}
                        />
                      </>
                    )}
                  </TableRows>

                </TableBody>
                {trow && current === i && (
                  <TableBody
                    style={{
                      borderColor: 'rgba(43, 120, 202, 0.3)',
                      borderWidth: '2px',
                      borderStyle: 'solid',
                    }}>
                    {item?.testMarks && item.testMarks.length > 0 ? (
                      item.testMarks.map((testData: any, idx: number) => (
                        <TableRows>
                          <TableCells style={{ minWidth: 16 }} />
                          <TableCells
                            style={{
                              paddingLeft: 0,
                              minWidth: 150,
                            }}
                          />
                          <TableCells
                            style={{
                              minWidth: 176,
                            }}
                          />
                          <TableCells
                            value={testData?.test?.name || '-'}
                            style={{
                              minWidth: 150,
                            }}
                          />
                          <TableCells
                            value={getTableValue(testData?.test?.totalMarks, '-')}
                            style={{
                              minWidth: 38,
                            }}
                          />
                          <TableCells
                            value={getTableValue(testData?.test?.passingMarks, '-')}
                            style={{
                              minWidth: 38,
                            }}
                          />
                          <TableCells
                            value={getTableValue(testData?.marks?.marksObtained, '-')}
                            style={{ minWidth: 38 }}
                          />
                          <TableCells
                            value={
                              getTableValue(testData?.marks?.grace) !== 'N/A' &&
                              `${testData?.marks?.graceRule?.symbol} ${getTableValue(
                                testData?.marks?.grace,
                                '-',
                              )}`
                            }
                            style={{ minWidth: 38 }}
                          />
                          <TableCells
                            value={getTableValue(testData?.marks?.finalMarksObtained, '-')}
                            style={{ minWidth: 38 }}
                          />
                          <TableCells style={{ minWidth: 100 }} align={'right'} />
                          <TableCells
                            style={{
                              minWidth: 70,
                              paddingLeft: 50,
                            }}
                            align={'right'}
                          />
                          <TableCells style={{ minWidth: 100 }} align={'center'} />
                        </TableRows>
                      ))
                    ) : (
                      <MediumTextSelect
                        value={'No records found'}
                        style={styles().noRecordTableCell}
                      />
                    )}
                    {/* </TableCells> */}
                  </TableBody>
                )}
              </>
            ))
          ) : (
            <NoRecordsFound colspan={7} maxHeight={56} borderBottom={true} />
          )}
        </TblContainer>
      </TableDiv>
    </ParentContainer>
  );
}

const styles = (idx?: number, length?: number, borderBottom?: boolean) => ({
  tableDiv: {
    marginTop: 0,
  },
  // firstCell: {
  //   minWidth: 16,
  //   borderBottomStyle: length && idx !== length - 1 ? 'none' : 'solid',
  // },
  // tableCell: {
  //   borderBottomStyle: length && idx !== length - 1 ? 'none' : 'solid',
  // },
  subjectTableCell: {
    paddingLeft: 0,
  },
  noRecordTableCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  parentCell: {
    padding: 0,
    borderBottom: borderBottom ? '1px solid rgba(224, 224, 224, 1)' : 'none',
  },
  gradeCell: {
    paddingLeft: 80,
  },
  nestedCell: {
    justifyContent: 'center',
  },
});

const ParentContainer = styled.div`
  .MuiTable-root {
    border-collapse: collapse;
    /* table-layout: fixed; */
  }
`;

