import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody
} from '@mui/material';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import React from 'react';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { colors, fonts } from 'src/styles/theme/styles';

export default function GradeRangeDetailsTable(props: any) {
  const { data } = props;

  return (
    <GradeDetailsContainer>
      <TableContainer>
        <Table sx={{fontFamily: fonts.regular}}>
          <TableBody>
            {/* Range */}
            <TableRow>
              <TableCell 
              sx={{
                borderWidth: 1,
                borderColor: colors.deviderGrey,
                borderStyle: 'solid',
                borderTopStyle: 'none',
                paddingTop: 9,
                paddingBottom: 9,
                minHeight: 36,
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: colors.greyBackground
              }} align={'center'}>
                <View style={{ width: 100 }}>
                  <TableColHead value={"Range"} />
                </View>
              </TableCell>
              {/* {console.log((1055 / data?.length) - 1)} */}
              {/* Total Width : 1167 -> 
              Header Cell (Range, Grade --> 100px plus 2px border on either side --> 1055 (1157-100-2))
              rest of the cells are 1055 divided by no. of cells and 1 px subtracted to balance left border (1px on each cell)
              */}
              {
                data?.map((element: any) => {
                  return (
                    <TableCell sx={{
                      borderWidth: 1,
                      borderColor: colors.deviderGrey,
                      borderStyle: 'solid',
                      borderTopStyle: 'none',
                      borderLeftStyle: 'none',
                      paddingTop: 9,
                      paddingBottom: 9,
                      paddingLeft: 0,
                      paddingRight: 0,
                      minHeight: 36
                    }} align={'center'}>
                      <View style={{ width: (1055 / data?.length) - 1 }}>
                        <NormaltextAtom value={`${element?.min} to ${element?.max}`} fontSize={1.5} />
                      </View>
                    </TableCell>
                  );
                })
              }
            </TableRow>

            {/* Grade */}
            <TableRow>
              <TableCell sx={{
                borderWidth: 1,
                borderColor: colors.deviderGrey,
                borderStyle: 'solid',
                borderTopStyle: 'none',
                paddingTop: 9,
                paddingBottom: 9,
                minHeight: 36,
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: colors.greyBackground
              }} align={'center'}>
                <View style={{ width: 100 }}>
                  <TableColHead value={"Grade"} />
                </View>
              </TableCell>
              {
                data?.map((element: any) => {
                  return (
                    <TableCell sx={{
                      borderWidth: 1,
                      borderColor: colors.deviderGrey,
                      borderStyle: 'solid',
                      borderTopStyle: 'none',
                      borderLeftStyle: 'none',
                      paddingTop: 9,
                      paddingBottom: 9,
                      paddingLeft: 0,
                      paddingRight: 0,
                      minHeight: 36
                    }} align={'center'}>
                      <View style={{ width: (1055 / data?.length) - 1 }}>
                        <NormaltextAtom value={element?.name || "N/A"} fontSize={1.5} />
                      </View>
                    </TableCell>
                  );
                })
              }
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </GradeDetailsContainer>
  );
}

const GradeDetailsContainer = styled.View`
  flex-direction: row;
  width: 1157px;
 `;