import "react-big-calendar/lib/css/react-big-calendar.css";

import {
  Calendar,
  HeaderProps,
  View,
  dateFnsLocalizer,
} from "react-big-calendar";
import React, { useState } from "react";
import {
  ReactBigCalendarEvent,
  ScheduleDetail,
  ScheduleTimelineProps,
  TimeGutterProps,
} from "src/components/organism/Schedule/scheduleTypes";
import { colors, fonts } from "src/styles/theme/styles";
import { format, getDay, parse, startOfWeek } from "date-fns";
import {
  getDurationTimeString,
  getEventDurationInMinutes,
  getWeekHeaderValues,
  scrollToNow,
  softenColor,
  stringToHexColor,
} from "src/components/organism/Schedule/scheduleHelpers";

import { DT } from "src/constant/dateTime";
import MediumTextSelect from "../Text/MediumTextSelect";
import NormalTextSelect from "../Text/NormalTextSelect";
import NormaltextAtom from "../Text/NormalTextAtom";
import PopoverComponent from "../../organism/Schedule/ScheduleComponents/SchedulePopover.web";
import SmallTextAtom from "../Text/SmallTextAtom";
import { UserType } from "src/constant";
import { enUS } from "date-fns/locale";
import styled from "styled-components";
import { userInformation } from "src/utils/manageState";

const ScheduleTimeline = (props: ScheduleTimelineProps) => {
  const {
    pickedDate,
    events,
    showActions,
    refetchLectures,
    onSelectSlot,
    details,
  } = props;
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<ReactBigCalendarEvent>();

  const onSelectEvent = (
    selectedEvent: ReactBigCalendarEvent,
    e: React.SyntheticEvent<HTMLElement>
  ): void => {
    setAnchorElement(e.currentTarget);
    setSelectedEvent(selectedEvent);
  };

  return (
    <>
      <ReactBigCalendar>
        <Calendar
          events={events}
          localizer={localizer}
          defaultView="week"
          views={views}
          toolbar={false}
          dayLayoutAlgorithm={"no-overlap"} // Resizes events to avoid overlap
          scrollToTime={scrollToNow(pickedDate)}
          date={pickedDate}
          timeslots={slotProps.numberOfSlots}
          step={slotProps.slotDuration}
          onSelectEvent={onSelectEvent}
          onSelectSlot={onSelectSlot}
          selectable={!!onSelectSlot ? "ignoreEvents" : false}
          components={{
            event: (event) => {
              const EventComponent = useEventCard(event);
              return EventComponent;
            },
            week: {
              header: WeekHeader,
            },
            timeGutterHeader: TimeGutterHeader,
            timeGutterWrapper: TimeGutterWrapper as any,
          }}
          tooltipAccessor={null} // Disables the default tooltip
        />
      </ReactBigCalendar>
      <PopoverComponent
        anchorElement={anchorElement}
        onClose={() => setAnchorElement(null)}
        event={selectedEvent}
        showActions={showActions}
        refetchLectures={refetchLectures}
        details={details}
      />
    </>
  );
};

const views: View[] = ["week"];
const locales = { "en-US": enUS };
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
const slotProps = {
  width: 72,
  height: 40,
  numberOfSlots: 4,
  slotDuration: 15,
};

const useEventCard = ({
  event,
}: {
  event: ReactBigCalendarEvent;
}): JSX.Element => {
  const { userType } = userInformation();
  const lectureData: ScheduleDetail = event.resource;
  const lectureColor: string =
    lectureData.subject?.color || stringToHexColor(lectureData.subject.id);
  const duration: number = getEventDurationInMinutes(
    lectureData.startTime,
    lectureData.endTime
  );

  return (
    <ClickableWrapper>
      <EventContainer backgroundColor={softenColor(lectureColor)}>
        <ColorStrip stripColor={lectureColor} />
        <EventDetails>
          {duration >= 20 && (
            <NormalTextSelect
              value={lectureData?.subject?.alias ?? "-"}
              color={lectureColor}
              lines={2}
            />
          )}
          {duration > 30 &&
            (userType === UserType.INSTITUTE_STUDENT ? (
              <NormaltextAtom
                value={lectureData?.lecturer?.fullName ?? "-"}
                lines={2}
              />
            ) : (
              <NormaltextAtom
                value={`${lectureData.batch?.alias ?? "-"} / ${lectureData?.division ?? "-"
                  }`}
                lines={2}
              />
            ))}
          {duration > 45 && (
            <SmallTextAtom
              value={getDurationTimeString(
                lectureData.startTime,
                lectureData.endTime
              )}
              lines={1}
            />
          )}
        </EventDetails>
      </EventContainer>
    </ClickableWrapper>
  );
};

const WeekHeader = ({ date, label }: HeaderProps) => {
  const { abbreviatedDay, dayOfMonth, today } = getWeekHeaderValues(date);
  return (
    <WeekDay>
      <MediumTextSelect value={abbreviatedDay} fontSize={1.7} />
      <DayOfMonth isToday={today}>
        <MediumTextSelect
          value={dayOfMonth}
          fontSize={1.7}
          color={today ? colors.white : colors.secondaryText}
        />
      </DayOfMonth>
    </WeekDay>
  );
};

const TimeGutterWrapper = ({ slotMetrics }: TimeGutterProps) => {
  return (
    <TimeGutterContainer>
      {slotMetrics.groups.map((group) => (
        <TimeGutterTitle>
          {format(group[0], DT.HOUR_12_WITH_PERIOD)}
        </TimeGutterTitle>
      ))}
    </TimeGutterContainer>
  );
};

const ClickableWrapper = styled.div``;

const EventContainer = styled.div<{ backgroundColor: string }>`
  border-radius: 8px;
  height: 100%;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: hidden; // Ensures seamless curvature
  pointer-events: none; // Prevent text elements from interfering with onSelectEvent click event.
`;

const ColorStrip = styled.div<{ stripColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: ${({ stripColor }) => stripColor};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 4px 4px 10px;
`;

const WeekDay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 76px;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: default;
`;

const DayOfMonth = styled.div<{ isToday: boolean }>`
  width: 36px;
  height: 36px;
  background-color: ${({ isToday }) => (isToday ? colors.primary : "unset")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Top-left Corner Cell
const TimeGutterHeader = styled.div`
  width: ${slotProps.width}px;
  height: 100%;
`;

const TimeGutterContainer = styled.div``;

const TimeGutterTitle = styled.div`
  width: ${slotProps.width}px;
  height: ${slotProps.numberOfSlots * slotProps.height +
  1}px; // +1 required to adjust for alignment with the slot border
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: ${colors.tertiaryText};
`;

const ReactBigCalendar = styled.div`
  margin-top: 16px; // For adjusting gap with ScheduleToolbar
  width: 100%;
  height: 100%;
  overflow: hidden;
  .rbc-calendar {
    font-family: ${fonts.regular};
  }
  .rbc-time-view,
  .rbc-header,
  .rbc-time-header-content,
  .rbc-time-header.rbc-overflowing {
    border: unset; // Remove default borders
  }
  .rbc-time-header.rbc-overflowing {
    margin-right: 0; // Days Header
  }
  .rbc-allday-cell,
  .rbc-event-label {
    display: none;
  }
  .rbc-event {
    // Remove default event styling
    background-color: unset;
    border: unset;
  }
  .rbc-time-content {
    border-top-width: 1px; // Defaults to 2px
  }
  .rbc-event,
  .rbc-today {
    background-color: ${colors.white};
  }
  .rbc-events-container {
    margin-right: unset; // Remove default margin-right for the event
  }
  .rbc-time-slot {
    min-height: ${slotProps.height}px;
  }
  .rbc-current-time-indicator {
    background-color: ${colors.primary};
    height: 2px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      background-color: ${colors.primary};
      border-radius: 50%;
    }
  }
  .rbc-time-column .rbc-timeslot-group .rbc-time-slot .rbc-label {
    font-size: 14px;
  }
  .rbc-label.rbc-time-header-gutter {
    padding: 0;
  }
`;

export default ScheduleTimeline;
