import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import FormRenderer from "@data-driven-forms/react-form-renderer/form-renderer";
import { FormSchema } from "../Forms/types";
import ModalSaveAndCancelButtonGroup from "../ModalSaveAndCancelButtonGroup";
import { assignRollNo } from "src/form-json/promotions/assign-roll-no";
import { componentMapper } from "../ddfElements";
import { validatorMapper } from "../Forms/validatorMapper";
import { getFilteredSubjectGroups } from "src/components/services";
import { getSubjectGroups } from "src/graphql/academics/subject-groups";
import { graphqlQuery } from "src/graphql/util";
import { getStudentsQuery } from "src/graphql/classes";
import styled from "styled-components";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { useI18n } from "src/i18n/hooks";
import SmallTextAtom from "src/components/atoms/Text/SmallTextAtom";
import { colors } from "src/styles/theme/styles";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import { UnAssignedStudent } from "src/components/organism/Enrollment/Promotions/types";

interface Props {
  onSubmit: (values: any) => Promise<void>;
  closeModal: () => void;
  setStudentRoll: Dispatch<SetStateAction<any>>;
  checkError: MutableRefObject<boolean>;
  batchId: string;
  division: string;
  assignRollNoTableData: UnAssignedStudent[];
  setAssignRollNoTableData: Dispatch<SetStateAction<never[]>>;
}

export default function AssignRollNoForm(props: Props) {
  const {
    onSubmit,
    closeModal,
    setStudentRoll,
    checkError,
    batchId,
    division,
    assignRollNoTableData,
    setAssignRollNoTableData,
  } = props;
  const [formSchema, setFormSchema] = useState<FormSchema>();
  const [subjectGroupOptions, setSubjectGroupOptions] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const { t } = useI18n();
  const currentSubjectGroup = useRef<string>('');

  useEffect(() => {
    if (batchId) {
      loadSubjectGroups();
    }
  }, [batchId]);

  useEffect(() => {
    setFormSchema(
      assignRollNo(
        subjectGroupOptions,
        assignRollNoTableData,
        setStudentRoll,
        checkError,
        loadStudents,
        isDataAvailable,
      ),
    );
  }, [subjectGroupOptions, assignRollNoTableData]);

  async function loadSubjectGroups() {
    const response = await getFilteredSubjectGroups(
      { filters: { batch: { eq: batchId } } }, getSubjectGroups
    );
    if (response?.options) {
      const options = response.options.map((item: any) => ({ label: item?.name, value: item?.id }));
      setSubjectGroupOptions(options);
    }
  }

  async function loadStudents(subjectGroupId: string) {
    if (subjectGroupId) {
      currentSubjectGroup.current = subjectGroupId;
    } else {
      currentSubjectGroup.current = '';
      const { data } = await fetchStudents();
      if (data?.instituteStudents?.data) {
        setAssignRollNoTableData(data.instituteStudents.data || []);
      }
    }
  }

  async function handleStudentSearch() {
    if (currentSubjectGroup?.current) {
      setLoading(true);
      const { data, loading } = await fetchStudents(currentSubjectGroup?.current);

      if (!loading) {
        setLoading(false);
        setIsDataAvailable(true);
        if (data?.instituteStudents?.data) {
          setAssignRollNoTableData(data.instituteStudents.data || []);
        }
      }
    }
  }

  async function fetchStudents(subjectGroup?: string) {
    const studentFilter = {
      limit: 0,
      filters: {
        batch: { eq: batchId },
        division: { eq: division },
        ...(subjectGroup ? { subjectGroup: { eq: currentSubjectGroup?.current } } : {}),
        or: [
          { rollNo: { exists: false } },
          { rollNo: { eq: null } },
        ]
      },
      sort: {
        gender: 'ASCENDING',
        fullName: 'ASCENDING',
      }
    }
    const { data, loading } = await graphqlQuery(getStudentsQuery, studentFilter, false);
    return { data, loading };
  }

  const AssignDivisionTemplate = useCallback((props: { formFields: any }) => {
    const { formFields } = props;
    return (
      <FormWrapper>
        {formFields &&
          formFields.map((field: any, index: number) => {
            return (
              <>
                <ElementWrapper idx={index}>
                  {field}
                  <ButtonContainer>
                    {index === 0 && (
                      <SecondaryBtn
                        onPress={handleStudentSearch}
                        label={t('search.label')}
                        style={{ height: 39, alignSelf: 'flex-end', marginLeft: 16 }}
                        width={72}
                        canLoad={loading}
                        customDisabled={Boolean(!currentSubjectGroup.current)}
                      />
                    )}
                  </ButtonContainer>
                </ElementWrapper>
              </>
            );
          })}
      </FormWrapper>
    );
  }, [formSchema, loading]);

  return (
    <>
      {formSchema ? (
        <FormRenderer
          componentMapper={componentMapper}
          FormTemplate={AssignDivisionTemplate}
          schema={formSchema}
          onSubmit={onSubmit}
          validatorMapper={validatorMapper}
        />
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
      <ModalSaveAndCancelButtonGroup
        onCancel={closeModal}
        isSubmitting={false}
        onSubmit={onSubmit}
      />
    </>
  );
}

const FormWrapper = styled.div`
  min-height: 575px;
`;

const ElementWrapper = styled.div<{ idx: number }>`
  width: ${(props) => props?.idx == 1 ? '100%' : '350px'};
  display: flex;
`;

const ButtonContainer = styled.div`
  height: 57.4px;
  display: flex;
  align-self: auto;
`;

const ErrorContainer = styled.div`
  margin-top: -14px;
`;

const SpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 714px;
  height: 575px;
`;