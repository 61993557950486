import { ADD_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { DeleteIcon, EditIcon } from 'src/components/atoms/ActionIcons';
import { ERROR, SUCCESS, limit } from 'src/constant';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import styled, { useTheme } from 'styled-components';
import { useCreateGradeDetails, useGetGradeById } from 'src/graphql/assessment/assessment-grades';
import { useHistory, useParams } from 'src/routes/routing.web';

import AddEditGradesForm from 'src/components/molecules/Assessment/Grades/AddEditGradesForm';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { TableBody } from '@mui/material';
import { height } from 'src/constant/device';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useForm } from 'react-hook-form';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import useTable from 'src/components/molecules/Table/UseTable';
import { validation } from 'src/constant/validation';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { getTableValue } from 'src/utils/utility';

const headCells1 = [
  {
    id: 'grade',
    label: 'grade.label',
    align: 'left',
    disableSorting: true,
    style: { width: "10%" }
  },
  {
    id: 'lower',
    label: 'lower.label',
    align: 'right',
    disableSorting: true,
    style: { width: "15%" }
  },
  {
    id: 'higher',
    label: 'higher.label',
    align: 'right',
    disableSorting: true,
    style: { width: "17.5%" }
  },
  {
    id: 'gradePoint',
    label: 'grade-point.label',
    align: 'right',
    disableSorting: true,
    style: { width: "17.5%" }
  },
  {
    id: 'remark',
    label: 'remark.label',
    align: 'left',
    disableSorting: true,
    style: { paddingLeft: "15%" }
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { width: "5%" }
  }
];

export default function Grades() {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const { setAlertDetails } = useAlertSystem();
  const { setHeading } = useHeaderTitle();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
    clearErrors
  } = useForm();
  const history = useHistory();
  const gradingSystemName = history?.location?.state?.name;

  let maxHeight = height - 375;
  let { urlGradingSystemId }: any = useParams();

  const [headCells, setHeadCells] = useState(headCells1);
  const [modalState, handleModal] = useState<boolean>(false);
  const [deleteGradeModalState, handleDeleteGradeModal] = useState<boolean>(false);
  const [row, setRow] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>();
  const [canClick, setCanClick] = useState(true);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);

  const { getGradeById, gradeByIdData, gradeByIdError, gradeByIdLoading, refetchGradeById } = useGetGradeById();
  const { createGradeDetails, createGradeDetailsData, createGradeDetailsError } = useCreateGradeDetails();

  useEffect(() => {
    if (urlGradingSystemId) {
      handleFetch(urlGradingSystemId);
    }
  }, [urlGradingSystemId]);

  useEffect(() => {
    if (gradeByIdData) {
      setRow(gradeByIdData?.grade?.grades);
      setTotalCount(gradeByIdData?.grade?.grades?.length || 0);
    }
  }, [gradeByIdData]);

  const styles = StyleSheet.create({
    cloneBtnStyle: { width: rem(8), height: rem(4), marginRight: rem(1.6) },
    addBtnStyle: { width: rem(11.25) }
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(row, headCells, maxHeight, totalCount);

  useEffect(() => {
    if (gradingSystemName) {
      setHeading([{ text: t('gradingSystems.label'), url: `/assessment/grading-systems/limit/${limit}/page/1` }, { text: gradingSystemName }]);
    }
  }, [gradingSystemName]);

  function handleFetch(systemId: string) {
    getGradeById({ variables: { id: systemId } });
  }

  const closeModal = () => {
    handleModal(false);
    resetForm();
  };

  function resetForm() {
    setValue('gradeName', null);
    setValue('lowerMarks', null);
    setValue('higherMarks', null);
    setValue('remark', null);
    clearErrors();
  }

  interface recievedGradesDataType {
    gradeName: string; lowerMarks: string; higherMarks: string; remark: string; gradePoint: string;
  }

  async function createNewGrade(recievedGradesData: recievedGradesDataType) {
    setCanClick(false);
    let gradeObject = {
      id: gradeByIdData?.grade?.id,
      name: recievedGradesData.gradeName,
      min: parseFloat(recievedGradesData.lowerMarks),
      max: parseFloat(recievedGradesData.higherMarks),
      remarks: recievedGradesData?.remark,
      gradePoint: parseFloat(recievedGradesData.gradePoint)
    };
    try {
      const createdGradeDetailsData = await createGradeDetails({
        variables: { payload: gradeObject }
      });
      if (createdGradeDetailsData?.data) {
        setAlertDetails({ message: isEditModal ? UPDATE_SUCCESS.GRADE : ADD_SUCCESS.GRADE, level: SUCCESS });
        closeModal();
        if (refetchGradeById) refetchGradeById();
      }
      setCanClick(true);
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  const handleAddGrades = async (recievedGradesData: recievedGradesDataType) => {
    if (parseFloat(recievedGradesData.lowerMarks) >= parseFloat(recievedGradesData.higherMarks)) {
      setError('lowerMarks', {
        message: validation.ADD_GRADES_MARKS_INVALID
      });
    } else {
      // Grade Creation
      createNewGrade(recievedGradesData);
    }
  };

  function deleteGrade() {
    throw new Error('Function not implemented.');
  }
  const handleEditGrade = (item: any) => {
    setCurrentItem(item);
    setIsEditModal(true);
    handleModal(true);
  };
  const handleDeleteGrade = (item: any) => {
    setCurrentItem(item);
    handleDeleteGradeModal(true);
  };

  return (
    <LoadContentWrapper>
      <TopRowsWrapper>
        <SecondaryBtn
          label={t('add-grade.label')}
          onPress={() => {
            setIsEditModal(false);
            handleModal(true);
          }}
          style={styles.addBtnStyle}
        />
      </TopRowsWrapper>

      <View style={{ zIndex: -1 }}>
        <TableDiv>
          <TblContainer>
            <TblHead setHeadCells={setHeadCells} />
            {!gradeByIdLoading ? (
              <TableBody>
                {totalCount > 0 ? (
                  recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                    <TableRows key={`k${i}`}>
                      <TableCells value={getTableValue(item?.name)} />
                      <TableCells value={getTableValue(item?.min)} align="right" />
                      <TableCells value={getTableValue(item?.max)} align="right" />
                      <TableCells value={getTableValue(item?.gradePoint)} align="right" />
                      <TableCells
                        value={getTableValue(item?.remarks)}
                        style={{ paddingLeft: '15%' }}
                      />

                      {/* Actions */}
                      <TableCells align="right" style={{ minWidth: '5%' }}>
                        <ActionIcons>
                          <IconView>
                            <EditIcon
                              onPress={() => handleEditGrade(item)}
                              tooltipTitle={t('edit-grade.label')}
                            />
                          </IconView>
                          {/* Temporary Disable of Delete Btn */}
                          {/* <DeleteIcon onPress={() => handleDeleteGrade(item)} tooltipTitle={t("delete-grade.label")} /> */}
                        </ActionIcons>
                      </TableCells>
                    </TableRows>
                  ))
                ) : (
                  <TableRows style={{ height: maxHeight }}>
                    <TableCells colspan={6} align="center" style={{ borderBottom: 'none' }}>
                      {/* "" in ternary else may be utilised to show msg like - Select Batch and Class to continue! */}
                      <NormalTextSelect value={totalCount == 0 ? t('no-records-found.text') : ''} />
                    </TableCells>
                  </TableRows>
                )}
              </TableBody>
            ) : (
              <SpinnerView>
                <LoaderSpinner />
              </SpinnerView>
            )}
          </TblContainer>
        </TableDiv>
      </View>

      {/* Modals */}
      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        Headerpopup={isEditModal ? t('edit-grade.label') : t('add-grade.label')}
        modalVisible={modalState}
        handleSave={handleSubmit(handleAddGrades)}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'save.label'}
        width={764}
        maxWidth={'lg'}>
        <AddEditGradesForm
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          isEditModal={isEditModal}
          currentItem={currentItem}
        />
      </NormalModal>

      <DeleteModal
        setModalVisible={() => handleDeleteGradeModal(false)}
        modalVisible={deleteGradeModalState}
        handleSave={() => deleteGrade()}
        Headerpopup={t('delete-grade.label')}
        children={`Are you sure you want to delete "${currentItem?.name}" grade?`}
      />
    </LoadContentWrapper>
  );
}

const TopRowsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  z-index: 10;
`;

const ActionIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const IconView = styled.div`
  margin-right: 13px;
`;

const SpinnerView = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;