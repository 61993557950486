import { EditIcon, ViewFileIcon } from "src/components/atoms/ActionIcons";
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from "src/components/molecules/Table/TableAtom";
import React, { CSSProperties, useEffect, useState } from "react";
import {
  admissionRoundFormState as TForm,
  admissionRoundData as TItem,
  admissionRoundsListingHeadCells as headCells,
} from "./AdmissionMeritRoundsHelpers";
import { format, isAfter, isSameDay } from "date-fns";

import AdmissionRoundsForm from "./AdmissionRoundsForm.web";
import { AdmissionStatus } from "src/constant";
import { DT } from "src/constant/dateTime";
import Search from "src/components/atoms/SearchBar/index.web";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { TableBody } from "@mui/material";
import { downloadFileRestAPI } from "src/utils/utility";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";
import useTable from "src/components/molecules/Table/UseTable";

type Props = {
  admissionDetail: any;
  onSuccess: (message: string, close?: boolean) => void;
  onError: (message: string, close?: boolean) => void;
  onClose: () => void;
  roundForm: {
    form: TForm;
    setForm: React.Dispatch<React.SetStateAction<TForm>>;
  };
};

const AdmissionRoundsListing = (props: Props) => {
  const { admissionDetail, onSuccess, onError, onClose, roundForm } = props;
  const { form, setForm } = roundForm;

  const { t } = useI18n();

  const [searchValue, setSearchValue] = useState<string>("");
  const [allRoundsData, setAllRoundsData] = useState<TItem[]>([]);
  const [roundsData, setRoundsData] = useState<TItem[]>([]);
  const [roundsCutOff, setRoundsCutOff] = useState(new Map<string, number>());

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    roundsData,
    headCells
  );

  let showAddButton: boolean = false;

  if (admissionDetail?.activeRound?.lastPayment) {
    const { lastPayment } = admissionDetail?.activeRound;
    const currentDate = new Date();
    showAddButton =
      isSameDay(currentDate, lastPayment) || isAfter(currentDate, lastPayment);
  }

  useEffect(() => {
    handleAdmissionData();
  }, [admissionDetail]);

  useEffect(() => {
    if (allRoundsData) {
      if (!searchValue) {
        setRoundsData([...allRoundsData]);
      } else {
        const filtered = allRoundsData.filter((round) =>
          round?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
        );
        setRoundsData(filtered);
      }
    }
  }, [searchValue, allRoundsData]);

  function handleAdmissionData() {
    if (admissionDetail && Object.keys(admissionDetail).length > 0) {
      let data = [];
      if (
        admissionDetail?.pastRounds &&
        admissionDetail?.pastRounds?.length > 0
      ) {
        admissionDetail?.pastRounds?.forEach((item: any) => data.push(item));
      }
      if (
        admissionDetail?.activeRound &&
        Object.keys(admissionDetail?.activeRound).length > 0
      ) {
        data.push(admissionDetail?.activeRound);
      }
      setAllRoundsData(data);
      if (admissionDetail?.cutOffs?.length > 0) {
        const maps = new Map<string, number>();
        admissionDetail.cutOffs.forEach(
          (round: { round: string; cutOff: number }) => {
            maps.set(round.round, round.cutOff);
          }
        );
        setRoundsCutOff(maps);
      }
    }
  }

  const isActiveRound = (id: string) => {
    return admissionDetail?.activeRound?.id
      ? admissionDetail?.activeRound?.id === id
      : false;
  };

  const exportMeritList = (roundId: string) => {
    if (admissionDetail?.id && roundId) {
      downloadFileRestAPI(
        `admission/export/meritlist/${admissionDetail?.id}/${roundId}`,
        "merit_list.zip"
      );
      onSuccess(t("file-download-start.text"), false);
    }
  };

  const triggerForm = (item?: TItem) => {
    setForm({
      state: "OPEN",
      ...(item ? { mode: "EDIT", data: item } : { mode: "ADD" }),
    });
  };

  return (
    <Container>
      <Section>
        <Search
          id="roundsSearch"
          handleChange={(e: string) => setSearchValue(e)}
          value={searchValue}
          label={t("searchRounds.text")}
        />
        {admissionDetail?.status === AdmissionStatus.OPEN &&
          (!admissionDetail?.activeRound || showAddButton) && (
            <SecondaryBtn
              label={t("addRound.text")}
              onPress={() => triggerForm()}
              lines={1}
            />
          )}
      </Section>
      <TblContainer height={"90%"}>
        <TblHead />
        <>
          {recordsAfterPagingAndSorting()?.length > 0 ? (
            <TableBody>
              {recordsAfterPagingAndSorting().map(
                (item: TItem, index: number) => (
                  <TableRows key={`round${index}`}>
                    <TableCells
                      value={item?.name || "-"}
                      badge={isActiveRound(item?.id) ? t("active.label") : ""}
                      style={
                        isActiveRound(item?.id)
                          ? style.opacityNormal
                          : style.opacityInactive
                      }
                      textStyle={style.textStyle}
                    />
                    <TableCells
                      value={
                        item?.lastSubmission
                          ? format(item?.lastSubmission, DT.DATE_FORMAT_SLASH)
                          : "-"
                      }
                      align={"right"}
                      style={
                        isActiveRound(item?.id)
                          ? style.opacityNormal
                          : style.opacityInactive
                      }
                    />
                    <TableCells
                      value={item?.qualifiedApplications?.length ?? "0"}
                      align={"right"}
                      style={
                        isActiveRound(item?.id)
                          ? style.opacityNormal
                          : style.opacityInactive
                      }
                    />
                    <TableCells
                      value={roundsCutOff.get(item.id) ?? "-"}
                      align={"right"}
                      style={
                        isActiveRound(item?.id)
                          ? style.opacityNormal
                          : style.opacityInactive
                      }
                    />

                    <TableActions align="right">
                      <TableActionsWrapper>
                        {isActiveRound(item?.id) && (
                          <EditIcon
                            tooltipTitle={t("editRound.text")}
                            onPress={() => triggerForm(item)}
                          />
                        )}
                        {item?.meritGenerated && (
                          <ViewFileIcon
                            tooltipTitle={t("viewMerit.label")}
                            onPress={() => exportMeritList(item?.id)}
                          />
                        )}
                      </TableActionsWrapper>
                    </TableActions>
                  </TableRows>
                )
              )}
            </TableBody>
          ) : (
            <NoRecordsWrapper>
              <NoRecordsFound colspan={5} />
            </NoRecordsWrapper>
          )}
        </>
      </TblContainer>

      {form && form.state === "OPEN" && (
        <AdmissionRoundsForm
          form={form}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          admissionId={admissionDetail.id}
        />
      )}
    </Container>
  );
};

const style: Record<string, CSSProperties> = {
  opacityNormal: {
    opacity: "100%",
  },
  opacityInactive: {
    opacity: "50%",
  },
  textStyle: {
    display: "flex",
    alignItems: "center",
  },
};

const Container = styled.div`
  height: 400px;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const TableActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
`;

const NoRecordsWrapper = styled.div`
  position: absolute;
  top: 60%; // Optically centered
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default AdmissionRoundsListing;
