import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 24 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
        <Path
          d="M14.029 11.4903L10.5452 10.5129C9.95968 10.3484 9.54839 9.81613 9.54839 9.22097C9.54839 8.48065 10.1677 7.87581 10.9274 7.87581H13.1048C13.6468 7.87581 14.1645 8.05 14.5855 8.36452C14.7403 8.48064 14.9532 8.46129 15.0887 8.32581L15.6355 7.76935C15.8 7.60484 15.7806 7.33387 15.5968 7.18871C14.8903 6.62742 14.0194 6.32258 13.1 6.32258H12.8532V4.3871C12.8532 4.17419 12.679 4 12.4661 4H11.6919C11.479 4 11.3048 4.17419 11.3048 4.3871V6.32258H10.9274C9.31613 6.32258 8 7.61936 8 9.21613C8 10.5032 8.87581 11.6452 10.1242 11.9984L13.6081 12.9758C14.1935 13.1403 14.6048 13.6726 14.6048 14.2677C14.6048 15.0081 13.9855 15.6129 13.2258 15.6129H11.0484C10.5065 15.6129 9.98871 15.4387 9.56774 15.1242C9.4129 15.0081 9.2 15.0274 9.06452 15.1629L8.51774 15.7194C8.35323 15.8839 8.37258 16.1548 8.55645 16.3C9.2629 16.8613 10.1339 17.1661 11.0532 17.1661H11.3048V19.1016C11.3048 19.3145 11.479 19.4887 11.6919 19.4887H12.4661C12.679 19.4887 12.8532 19.3145 12.8532 19.1016V17.1661H13.2306C14.8419 17.1661 16.1581 15.8694 16.1581 14.2726C16.1532 12.9855 15.2823 11.8435 14.029 11.4903Z"
          fill="black"
          fill-opacity="0.6"/>
        <Path
          d="M0.859584 8.57198C0.388156 8.57198 0.00244141 8.18626 0.00244141 7.71484V2.85769C0.00244141 2.38626 0.388156 2.00055 0.859584 2.00055C1.33101 2.00055 1.71673 2.38626 1.71673 2.85769V5.81841C3.81673 2.33091 7.63637 0.000549316 12.0024 0.000549316C18.6292 0.000549316 24.0024 5.37376 24.0024 12.0005C24.0024 18.6273 18.6292 24.0005 12.0024 24.0005C7.72208 24.0005 3.96673 21.7613 1.83994 18.3863C1.49173 17.8291 1.91494 17.1434 2.57387 17.1434C2.8953 17.1434 3.18458 17.3148 3.36137 17.5827C5.19351 20.4113 8.38101 22.2863 12.0024 22.2863C17.681 22.2863 22.2882 17.6791 22.2882 12.0005C22.2882 6.32198 17.681 1.71484 12.0024 1.71484C8.19351 1.71484 4.87208 3.78269 3.09351 6.85769H5.71673C6.18816 6.85769 6.57387 7.24341 6.57387 7.71484C6.57387 8.18626 6.18816 8.57198 5.71673 8.57198H0.859584Z"
          fill="black"
          fill-opacity="0.6"/>
    </Svg>
  );
}

export default SvgComponent;