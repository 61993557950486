import { FlashList, ListRenderItem } from "@shopify/flash-list";

import React from "react";

type Props<T = any> = {
  children: ListRenderItem<T> | null;
  estimatedItemSize: number;
  data: T[];
};

const AbstractFlashList = <T extends any>({
  children,
  data,
  estimatedItemSize,
  ...rest
}: Props<T> & Record<string, any>) => {
  return (
    <FlashList
      keyExtractor={(item, index) => index.toString()}
      renderItem={children}
      estimatedItemSize={estimatedItemSize}
      data={data}
      {...rest}
    />
  );
};

export default AbstractFlashList;

// Reference: Estimated Item Size Prop https://shopify.github.io/flash-list/docs/estimated-item-size
