import React, { MutableRefObject, useCallback } from "react";

import { ConcessionOptionsType } from "src/components/organism/Admission/types";
import FormRenderer from "@data-driven-forms/react-form-renderer/form-renderer";
import FormViewer from "../Forms/DDFFormViewer/index";
import ModalSaveAndCancelButtonGroup from "../ModalSaveAndCancelButtonGroup";
import { componentMapper } from "../ddfElements";
import styled from "styled-components/native";
import { validatorMapper } from "../Forms/validatorMapper";

interface SelectConcessionFormProps {
  onSubmit: () => void;
  closeModal: () => void;
  feeConcessionOptions: ConcessionOptionsType[];
  concessionCompositionId: MutableRefObject<string>;
  concessionDataAvailable: boolean;
}

const SelectConcessionForm = (props: SelectConcessionFormProps) => {
  const {
    feeConcessionOptions,
    concessionCompositionId,
    onSubmit,
    closeModal,
    concessionDataAvailable
  } = props;

  const formSchema = {
    name: "selectFeeConcession",
    label: "selectFeeConcession.label",
    fields: [
      {
        name: "feeConcessionSelect",
        component: "fee-concession-radio-select-table",
        tableData: feeConcessionOptions,
        concessionCompositionId: concessionCompositionId,
        isDataAvailable: concessionDataAvailable
      },
    ],
  };

  const SelectFeeConcessionTemplate = useCallback(() => {
    return (
      <>
        <FormWrapper>
          <FormViewer
            schema={formSchema}
            isOnboarding={false}
            inModalView={true}
            paddingRight={0}
          />
        </FormWrapper>
        <ModalSaveAndCancelButtonGroup
          onCancel={closeModal}
          isSubmitting={false}
          onSubmit={onSubmit}
        />
      </>
    );
  }, [formSchema]);

  return (
    <>
      {formSchema && (
        <FormRenderer
          componentMapper={componentMapper}
          FormTemplate={SelectFeeConcessionTemplate}
          schema={formSchema}
          onSubmit={onSubmit}
          validatorMapper={validatorMapper}
        />
      )}
    </>
  );
};

const FormWrapper = styled.View`
  min-height: 568px;
`;

export default SelectConcessionForm;
