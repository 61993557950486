/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import React from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { useI18n } from 'src/i18n/hooks';
import MediumText from 'src/components/atoms/Text/MediumText';
import { colors } from 'src/styles/theme/styles';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import navigationStrings from 'src/navigation/navigationStrings';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import MediumTextSelect from '../Text/MediumTextSelect';
import { height, isIOS } from 'src/constant/device';

export default function ListNative(props) {
  const {
    navigation,
    listData,
    batch = null,
    division = null,
    attendanceType = null,
    audienceType = null,
    selectedDurationType = null,
    selectedStudentInfo = null,
    totalPresents,
    totalAttendance,
    shouldNavigate,
    routeName,
    datesinfo,
    type = 0,
    listContainerHeightAndroid = height - 250,
    listContainerHeightIos = height - 270,
  } = props;

  const { t } = useI18n();

  if (listData.length === 0) {
    return <View />;
  }
  return (
    <View
      style={{
        flexDirection: 'column',
        height: isIOS ? listContainerHeightIos : listContainerHeightAndroid,
      }}>
      <MediumTextSelect
        value={
          routeName === navigationStrings.CLASS_STUDENTS_ATTENDANCE_HISTORY
            ? t('students.label')
            : attendanceType.value === 1
            ? t('slots.label')
            : t('subjects.label')
        }
        color={colors.primaryText}
        fontWeight={'600'}
        lineHeight={2.4}
      />
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        {listData.map((slotInfo: any, index: number) => (
          <TouchableOpacity
            key={`listData${index}`}
            activeOpacity={1}
            onPress={() => {
              if (shouldNavigate) {
                navigation.navigate(navigationStrings.CLASS_STUDENTS_ATTENDANCE_HISTORY, {
                  batch,
                  division,
                  attendanceType,
                  audienceType,
                  selectedDurationType,
                  selectedStudentInfo,
                  slotInfo: { id: slotInfo.id, slotname: slotInfo.heading },
                  totalPresents,
                  totalAttendance,
                  datesinfo,
                });
              }
            }}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: 16,
              borderBottomColor: 'rgba(196, 196, 196, 1)',
              borderBottomWidth: 1,
            }}>
            <View
              style={{
                flex: 1,
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginRight: 10,
              }}>
              <MediumText
                lines={1}
                value={index + 1 + '.' + ' ' + slotInfo.heading}
                lineHeight={2.4}
                color={colors.primaryText}
              />
              <SmallTextAtom
                value={
                  `${slotInfo.attend + '/' + slotInfo.total}` +
                  ' ' +
                  (type === 0
                    ? t('classesAttended.text')
                    : t('classAttendance.label'))
                }
              />
            </View>
            <View style={{}}>
              <AnimatedCircularProgress
                size={45}
                width={3}
                fill={slotInfo.percentage}
                tintColor="#2B78CA"
                backgroundColor="transparent">
                {(fill) => <SmallTextAtom value={`${slotInfo.percentage}%`} />}
              </AnimatedCircularProgress>
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );
}
