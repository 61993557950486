import React, { useEffect, useState } from 'react';
import Search from 'src/components/atoms/SearchBar/index.web';
import styled from 'styled-components/native';
import { ClickAwayListener, TableBody, Tooltip } from '@mui/material';
import { useHistory } from 'react-router';

import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import useTable from 'src/components/molecules/Table/UseTable';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'src/routes/routing.web';
import {
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { colors, fonts } from 'src/styles/theme/styles';
import { createSearchFilter, debounce } from 'src/components/services';
import { AUTOCOMPLETE_MODULE, ERROR, PAYMENT_REPORT_TYPES, SUCCESS, limit } from 'src/constant';
import { useFilter } from 'src/contexts/filter-context';
import {
  getBatchSummary,
  getInstitutePaymentSummary,
  useGeneratePaymentReports,
  useGeneratePaymentReportsDivisionWise,
  useQueryBatchSummary,
} from 'src/graphql/payment';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useHeaderTitle } from 'src/contexts/header-context';
import { getBatchFilter } from 'src/components/services/filters';
import { getAcademicYearByIdQuery } from 'src/graphql/academics/years';
import { TouchableOpacity } from 'react-native';
import { height } from 'src/constant/device';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFileChartLine, faFileExport, faFilePlus } from '@fortawesome/pro-regular-svg-icons';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import ExportReportForm from 'src/components/molecules/Payment/Institute/ExportReportForm';
import SmallButton from 'src/components/atoms/Button/SmallButton.web';
import ERROR_MSG from 'src/constant/error';
import { ADD_SUCCESS } from 'src/constant/message';
import { ReportRequestObject } from '../types';
import { faCaretDown, faDownload } from '@fortawesome/pro-solid-svg-icons';
import ButtonGrp from 'src/components/atoms/ButtonGroup/index.web';

const maxHeight = height - 375;
const paginationHeightOffset = 100;
const headCells = [
  {
    id: 'batch_name',
    label: 'batchName.label',
    align: 'left',
    disableSorting: true,
    style: {
      width: '30%',
    }
  },
  {
    id: 'payable_amount',
    label: 'payableAmount.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'pending_amount',
    label: 'pendingAmount.label',
    align: 'right',
    disableSorting: true,
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
  },
];

export default () => {

  const { page = 1, dataLimit = limit, ayid } :any = useParams();
  const rowDefaultLimit:number =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  const history = useHistory();
  let searchValue = '' as string;
  const { filters, setFilter } = useFilter();
  const { setHeading } = useHeaderTitle();
  const academicYear = ayid;
  let { setAlertDetails } = useAlertSystem();
  const [canClick, setCanClick] = useState<boolean>(true);
  const [exportReportModal, setExportReportModal] = useState<boolean>(false);
  const [currentReport, setCurrentReport] = useState<ReportRequestObject | null>(null);
  const [previousReport, setPreviousReport] = useState<ReportRequestObject | null>(null);
  const [batchId, setBatchId] = useState<string>('');
  const [reportIconsDropdown, setReportIconsDropdown] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>('');
  const [reportType, setReportType] = useState<string>('');

  // TODO: check if ayid is valid else redirect to overview page
  const { t } = useI18n();

  const {
    data: paymentSummary,
    loading: summaryLoading,
    error: summaryError,
  } = useQuery(getInstitutePaymentSummary, {
    variables: {
      ay: academicYear,
    },
    fetchPolicy: 'network-only'
  });

  const {
    query: getSummary,
    data: batchSummary,
    loading: batchSummaryLoading,
    refetch: refetchBatchSummary,
  } = useQueryBatchSummary(getBatchSummary, 'network-only');

  const { data: academicYearData } = useQuery(getAcademicYearByIdQuery, {
    variables: {
      id: academicYear,
    }
  });

  const { generatePaymentReports, generatePaymentReportsError } = useGeneratePaymentReports();
  const { generatePaymentReportsDivisionWise, generatePaymentReportsDivisionWiseError } = useGeneratePaymentReportsDivisionWise();

  if (filters && filters?.batch?.on) {
    searchValue = filters?.batch?.search;
  }

  const row = batchSummaryLoading ? [] : batchSummary?.getBatchSummary?.data;
  const [totalCount, setTotalCount] = useState(batchSummary?.getBatchSummary?.totalCount ?? null);
  
  useEffect(() => {
    const count = batchSummary?.getBatchSummary?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [batchSummary?.getBatchSummary?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setTitle();
  }, [academicYearData]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, academicYear, rowLimit]);

  useEffect(() => {
    if (batchSummary?.getBatchSummary?.data && exportReportModal && batchId) {
      const currentData = batchSummary?.getBatchSummary?.data?.filter((item: any) => item?.batch === batchId);
      if (currentData && currentData[0]?.reportRequest && currentData[0]?.lastReportRequest) {
        const typeWiseCurrentReport = currentData[0].reportRequest?.find(
          (item: ReportRequestObject) => item?.type === reportType,
        );
        const typeWisePreviousReport = currentData[0].lastReportRequest?.find(
          (item: ReportRequestObject) => item?.type === reportType,
        );
        if (typeWiseCurrentReport && typeWisePreviousReport) {
          setCurrentReport(typeWiseCurrentReport);
          setPreviousReport(typeWisePreviousReport);
        }
      }
    }
  }, [batchSummary]);

  function handleFetch() {
    let filters = getBatchFilter(searchValue);
    let batchFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);
    delete Object.assign(batchFilter, { ['filter']: batchFilter['filters'] })['filters'];
    batchFilter = { ay: academicYear, ...batchFilter };
    getSummary({ variables: batchFilter });
  }

  async function setTitle() {
    setHeading([
      {
        text: t('overview.label'),
        url: '/payments/overview/limit/50/page/1',
      },
      {
        text: academicYearData?.AcademicYear?.alias || '--',
        url: ''
      }
    ]);
  }

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.BATCH]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchBatch(batch: string) {
    let isClear = batch?.length === 0;
    persistSearch(!isClear, batch);
  }

  const handleSearch = debounce((batch: string) => searchBatch(batch), 200);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
    null,
    null,
    false,
    true,
    null,
    paginationHeightOffset
  );

  function closeExportReportModal() {
    setExportReportModal(false);
    setBatchId('');
    setReportType('');
    setCurrentReport(null);
    setPreviousReport(null);
  }

  function handleExportReportModal(
    reportRequest: ReportRequestObject[],
    batchId: string,
    previousReportRequest: ReportRequestObject[],
    type: string,
  ) {
    const typeWiseCurrentReport = reportRequest?.find(
      (item: ReportRequestObject) => item?.type === type,
    );
    const typeWisePreviousReport = previousReportRequest?.find(
      (item: ReportRequestObject) => item?.type === type,
    );
    if (typeWiseCurrentReport && typeWisePreviousReport) {
      setReportType(type);
      setBatchId(batchId);
      setCurrentReport(typeWiseCurrentReport);
      setPreviousReport(typeWisePreviousReport);
      setExportReportModal(true);
    }
  }

  async function handleGeneratePaymentReports(batchId: string, type: string) {
    setCanClick(false);
    try {
      const requestApi =
        type === PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY_DIVISION_WISE
          ? generatePaymentReportsDivisionWise
          : generatePaymentReports;
      const response = await requestApi({
        variables: { batch: batchId },
      });

      const result =
        type === PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY_DIVISION_WISE
          ? response?.data?.generatePaymentReportsDivisionWise
          : response?.data?.generatePaymentReports;
      if (result) {
        const { status, message } = result;
        if (status === 'success') {
          setAlertDetails({ message: ADD_SUCCESS.REPORT_GENERATION, level: SUCCESS });
          if (refetchBatchSummary) refetchBatchSummary();
          setCanClick(true);
        } else {
          throw new Error(message || ERROR_MSG.GENERIC_ERROR);
        }
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  function checkReportExists(lastReportRequest: ReportRequestObject[], type: string) {
    const reportExists = lastReportRequest?.find(
      (item: ReportRequestObject) => item?.type === type && item?.status !== 'FAILED',
    );
    return reportExists;
  }

  return (
    <>
      <LoadContentWrapper>
        {!summaryLoading ? (
          <>
            <InfoWrapper>
              <Row1>
                <RowItem>
                  <NormalTextSelect
                    value={t('totalPayment.label')}
                    fontWeight={600}
                    fontFamily={fonts.semibold}
                  />
                  <NormalTextSelect
                    id={'totalPayment'}
                    value={`\u20B9 ${paymentSummary?.getSummary?.totalFee.toLocaleString() || 0}`}
                  />
                </RowItem>
                <RowItem>
                  <NormalTextSelect
                    value={t('concessionGiven.text')}
                    fontWeight={600}
                    fontFamily={fonts.semibold}
                  />
                  <NormalTextSelect
                    id={'concessionGiven'}
                    value={`\u20B9 ${paymentSummary?.getSummary?.totalConcession.toLocaleString() || 0
                      }`}
                  />
                </RowItem>
                <RowItem>
                  <NormalTextSelect
                    value={t('expectedPayment.label')}
                    fontWeight={600}
                    fontFamily={fonts.semibold}
                  />
                  <NormalTextSelect
                    id={'expectedPayment'}
                    value={`\u20B9 ${paymentSummary?.getSummary?.totalPayable.toLocaleString() || 0}`}
                  />
                </RowItem>
              </Row1>
              <Row2>
                <RowItem>
                  <NormalTextSelect
                    value={t('paymentReceived.label')}
                    fontWeight={600}
                    fontFamily={fonts.semibold}
                  />
                  <NormalTextSelect
                    id={'paymentReceived'}
                    value={`\u20B9 ${paymentSummary?.getSummary?.totalPaid.toLocaleString() || 0}`}
                  />
                </RowItem>
                <RowItem>
                  <NormalTextSelect
                    value={t('pendingPayment.label')}
                    fontWeight={600}
                    fontFamily={fonts.semibold}
                  />
                  <NormalTextSelect
                    id={'pendingPayment'}
                    value={`\u20B9 ${paymentSummary?.getSummary?.totalPending.toLocaleString() || 0}`}
                  />
                </RowItem>
              </Row2>
            </InfoWrapper>
            <DataWrapper>
              <SearchWrapper>
                <Search
                  id="batchSearch"
                  handleChange={handleSearch}
                  value={searchValue || ''}
                  label={'Search'}
                />
              </SearchWrapper>
              <TableWrapper>
                <TblContainer>
                  <TblHead />
                  {!batchSummaryLoading ? (
                    <>
                      {recordsAfterPagingAndSorting() &&
                        recordsAfterPagingAndSorting()?.length > 0 && (
                          <TableBody>
                            {recordsAfterPagingAndSorting()?.map((item: any, index: number) => (
                              <TableRows key={index}>
                                <TableCells
                                  value={item.batchAlias.toUpperCase()}
                                  clickable={true}
                                  onPress={() => {
                                    history.push(`/payments/overview/batch/${item.batch}`);
                                  }}
                                  color={colors.primaryColor}
                                />
                                <TableCells
                                  value={`\u20B9 ${item.totalPayable.toLocaleString()}`}
                                  textStyle={{
                                    justifyContent: 'flex-end',
                                  }}
                                />
                                <TableCells
                                  value={`\u20B9 ${item.totalPending.toLocaleString()}`}
                                  textStyle={{
                                    justifyContent: 'flex-end',
                                  }}
                                />
                                <TableCells style={{ position: 'relative' }}>
                                  <IconView>
                                    <ClickAwayListener
                                      onClickAway={() => setReportIconsDropdown(false)}>
                                      <Tooltip title="Reports">
                                        <DropdownIconWrapper
                                          onPress={() => {
                                            if (!reportIconsDropdown && refetchBatchSummary) refetchBatchSummary();
                                            setReportIconsDropdown(!reportIconsDropdown);
                                            setCurrentId(item?.batch);
                                          }}>
                                          <FontAwesomeIcon
                                            icon={faFileChartLine}
                                            color={colors.secondaryText}
                                          />
                                          <FontAwesomeIcon
                                            icon={faCaretDown}
                                            color={colors.secondaryText}
                                          />
                                        </DropdownIconWrapper>
                                      </Tooltip>
                                    </ClickAwayListener>
                                  </IconView>

                                  {reportIconsDropdown && currentId === item?.batch && (
                                    <ButtonGrp
                                      top={38}
                                      right={16}
                                      width={315}
                                      buttonData={[
                                        ...(checkReportExists(item?.lastReportRequest, PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY_DIVISION_WISE) ? [{
                                          key: 'download-division-wise-report',
                                          value: t('downloadDivisionWiseReport.text'),
                                          onPress: () => handleExportReportModal(
                                            item?.reportRequest,
                                            item?.batch,
                                            item?.lastReportRequest,
                                            PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY_DIVISION_WISE,
                                          ),
                                          prefixIcon: faDownload,
                                        }] : [{
                                          key: 'generate-division-wise-report',
                                          value: t('generateDivisionWiseReport.text'),
                                          onPress: () => handleGeneratePaymentReports(item?.batch, PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY_DIVISION_WISE),
                                          prefixIcon: faFilePlus,
                                        }]),
                                        ...(checkReportExists(item?.lastReportRequest, PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY) ? [{
                                          key: 'download-subject-group-wise-report',
                                          value: t('downloadSubjectGroupWiseReport.text'),
                                          onPress: () => handleExportReportModal(
                                            item?.reportRequest,
                                            item?.batch,
                                            item?.lastReportRequest,
                                            PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY,
                                          ),
                                          prefixIcon: faDownload,
                                        }] : [{
                                            key: 'generate-subject-group-wise-report',
                                            value: t('generateSubjectGroupWiseReport.text'),
                                            onPress: () => handleGeneratePaymentReports(item?.batch, PAYMENT_REPORT_TYPES.PAYMENT_SUMMARY),
                                            prefixIcon: faFilePlus,
                                        }]),
                                      ]}
                                    />
                                  )}
                                </TableCells>
                              </TableRows>
                            ))}
                          </TableBody>
                        )}
                      {recordsAfterPagingAndSorting() &&
                        recordsAfterPagingAndSorting()?.length === 0 &&
                        !batchSummaryLoading && <NoRecordsFound colspan={4} maxHeight={maxHeight - paginationHeightOffset} />}
                    </>
                  ) : (
                    <SpinnerView>
                      <LoaderSpinner />
                    </SpinnerView>
                  )}
                </TblContainer>
                <Pagination
                  pathName={`payments/overview/${academicYear}`}
                  total={totalCount}
                  page={currentPage}
                  setCurrentPage={setCurrentPage}
                  rowLimit={rowLimit}
                  setRowLimit={setRowLimit}
                  hidePagination={totalCount && totalCount <= rowLimit}
                />
              </TableWrapper>
            </DataWrapper>
          </>
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )}

        <NormalModal
          isSubmitting={!canClick}
          setModalVisible={closeExportReportModal}
          modalVisible={exportReportModal}
          handleSave={() => {}}
          Headerpopup={t('exportReport.label')}
          width={544}
          hideCloseIcon={true}
          cancelButtonLabel={'done.label'}
          hideSubmit={false}>
            <ExportReportForm
              currentReport={currentReport}
              previousReport={previousReport}
              handleRegenerateReport={handleGeneratePaymentReports}
              batchId={batchId}
            />
        </NormalModal>
      </LoadContentWrapper>
    </>
  );
};

const InfoWrapper = styled.View`
  height: 146px;
`;

const Row1 = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const Row2 = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

const RowItem = styled.View`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

const SpinnerView = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const DataWrapper = styled.View``;

const SearchWrapper = styled.View`
  width: 350px;
`;

const TableWrapper = styled.View``;

const IconView = styled.View`
  align-items: flex-end;
`;

const DropdownIconWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-items: center;
`;