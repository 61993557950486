import React, { useEffect, useState } from 'react';
import formJSON from '../../../../form-json/assessment/assessment-grading-systems.json';
import Element from '../../../molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { Control, FieldValues } from 'react-hook-form/dist/types';
import { getFilteredUniversities } from 'src/components/services';
import styled from 'styled-components';

interface Props {
  control: Control<FieldValues, object>;
  errors: { [x: string]: any };
}

export default function AddGradingSystemForm(props: Props) {
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};
  const { control, errors } = props;

  useEffect(() => {
    onLoadFunction();
  }, []);

  async function onLoadFunction() {
    setElements({});
    let universitiesResponse = await getFilteredUniversities({ limit: 0 });
    if (universitiesResponse?.options) {
      formJSON[0].fields[1].option = universitiesResponse.options;
      setElements(formJSON[0]);
    }
  }
  return fields ? (
    <>
      {fields.map((field: any, i: number) => (
        <Element
          key={`report${i}`}
          field={field}
          control={control}
          errors={errors}
          dropdownWidth={480}
          maxDropdownOptionsHeight={250}
        />
      ))}
    </>
  ) : (
    <SpinnerWrapper>
      <LoaderSpinner />
    </SpinnerWrapper>
  );
}

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 202px;
`;
