/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { useThemeSystem } from 'src/contexts/theme-context';
import { colors, mobileWeb } from '../../../styles/theme/styles';
import { height, isWeb } from 'src/constant/device';
import styled from 'styled-components/native';

interface propType {
  style?: StyleProp<ViewStyle>;
  children: any;
}

export default function LoadContentWrapper(props: propType) {
  const { children, style } = props;
  const { theme } = useThemeSystem();

  return (
    <StyledWhiteBox
      style={[
        {
          backgroundColor: theme?.content?.backgroundColor,
          height: isWeb ? (mobileWeb ? height - 100 : height - 142) : height - 140,
        },
        styles.pageArea,
        styles.loginDiv,
        style,
      ]}>
      {children}
    </StyledWhiteBox>
  );
}

const StyledWhiteBox = styled.View`
  border-radius: ${({ theme }) => theme.rem(1.6)};
  margin: ${({ theme }) => theme.rem([2.8, 3.2])};
  padding: ${({ theme }) => theme.rem([2.4, 3.2])};
`;

const styles = StyleSheet.create({
  pageArea: {
    borderColor: colors.borderGrey,
  },
  loginDiv: {},
});
