import { DropdownOptions } from "src/types";
export type TsubjectsData = {
  id: string;
  marksObtained: number;
  passingMarks: number;
  totalMarks: number;
  failedHeads: number;
  subject: {
    name: string;
    id: string;
  };
};

export const revalHeadcells = [
  {
    id: 'subjectName',
    label: 'subject.label',
    align: 'left',
  },
  {
    id: 'marksObtained',
    label: 'marksObtained.required.label',
    align: 'left',
  },
  {
    id: 'minMarks',
    label: 'minimumMarks.label',
    align: 'left',
  },
  {
    id: 'totalMarks',
    label: 'Total Marks*',
    align: 'left',
  },
  {
    id: 'photoCopy',
    label: 'Photo Copy of paper',
  },
];

export const atktHeadcells = [
  {
    id: 'subjectName',
    label: 'subject.label',
    align: 'left',
  },
  {
    id: 'marksObtained',
    label: 'marksObtained.required.label',
    align: 'left',
  },
  {
    id: 'minMarks',
    label: 'minimumMarks.label',
    align: 'left',
  },
  {
    id: 'totalMarks',
    label: 'Total Marks*',
    align: 'left',
  },
];

export type TappliedStudents = {
  amountToBePaid: number;
  id: string;
  softCopySubjects: Array<{
    id: string;
    subject: string;
  }>;
  status: string;
  subjects: Array<{
    subject: {
      name: string;
    };
  }>;
  user: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
};

export const appliedRevalHeadcell = [
  {
    id: 'studentName',
    label: 'Student Name',
    align: 'left',
  },
  {
    id: 'SubjectName',
    label: 'Subjects',
    align: 'left',
  },
  {
    id: 'SoftCopySubjectName',
    label: 'SoftCopy Subjects',
    align: 'left',
  },
  {
    id: 'Amount',
    label: 'Amount',
    align: 'left',
  },
  {
    id: 'Status',
    label: 'Status',
    align: 'right',
  },
];

export const appliedAtktHeadcell = [
  {
    id: 'studentName',
    label: 'Student Name',
    align: 'left',
  },
  {
    id: 'SubjectName',
    label: 'Subject Name',
    align: 'left',
  },
  {
    id: 'Amount',
    label: 'Amount',
    align: 'left',
  },
  {
    id: 'Status',
    label: 'Status',
    align: 'right',
  },
];


export type TappliedAtktStudents = {
  amountToBePaid: number;
  atktExam: {
    name: string;
    id: string;
  };
  id: string;
  status: string;
  subjects: Array<{
    subject: {
      name: string;
    };
  }>;
  user: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
};

export enum ExamTypeEnum {
  ATKT = "ATKT",
  GENERAL = "GENERAL",
}

export type TExamType = "ATKT" | "GENERAL";

export type TExamItem = {
  label: string;
  value: string;
  type: TExamType;
  testIds: string[]; // TO BE CHANGED
  subjectWiseTests: {
    subject: DropdownOptions;
    tests: {
      id: string;
      name: string;
      passingMarks?: number;
      totalMarks?: number;
      markingType: string;
    }[];
  }[];
};