import useFieldApi, {
  UseFieldApiConfig,
} from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components/native';
import React, { useEffect, useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import SearchDropdown from 'src/components/atoms/AutoComplete/index';
import { DropdownOptionsType } from 'src/types';
import { useQuery, useValuesOptions } from 'src/graphql/list';
import { makeVariables } from 'src/utils/utility';

const CustomSelect = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    meta: { error },
    options,
    setInitialValue,
    subscribeToValues,
    handleChange,
    multipleSelection,
    width,
    open = true,
    isEdit,
    notefooter,
    limitTags,
    textCount,
    maxHeight,
    disabled = false,
    fetchAsyncOptions,
    fetchValuesOptions,
    observeField,
    valueDependants,
    saveOptionsInValues,
    dependentFields,
  } = useFieldApi(props);
  const formOptions = useFormApi();
  const { getState } = useFormApi();
  const { submitFailed, values } = getState();
  const { value, onChange } = input;
  const { t } = useI18n();
  const [ddOptions, setDDOptions] = useState<DropdownOptionsType[]>();

  useEffect(() => {
    if (setInitialValue) {
      setInitialValue(props, formOptions);
    }
    if (observeField) {
      formOptions.subscribe(
        (nvalue) => {
          if (nvalue.values[observeField]) {
            fetchOptions(nvalue.values);
          }
        },
        { values: true },
      );
    } else {
      fetchOptions(values);
    }
  }, [options]);

  function fetchOptions(values: any) {
    if (fetchAsyncOptions?.queryName) {
      let variables: any;
      if (fetchAsyncOptions?.variables || fetchAsyncOptions?.typeFilter) {
        variables = makeVariables(fetchAsyncOptions.variables, values, fetchAsyncOptions?.typeFilter);
      }
      useQuery(fetchAsyncOptions.queryName, variables).then((data: any) => {
        setDDOptions(data?.data || data);
        if (saveOptionsInValues) {
          values[saveOptionsInValues] = data;
        }
      });
    } else if (fetchValuesOptions) {
      let data = useValuesOptions(fetchValuesOptions, values);
      setDDOptions(data);
      if (saveOptionsInValues) {
        values[saveOptionsInValues] = data;
      }
    } else {
      setDDOptions(options);
    }
  }

  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <InputContainer>
          <SearchDropdown
            multipleSelection={multipleSelection}
            value={value}
            header={t(label)}
            options={ddOptions}
            width={width}
            open={open}
            onOpen={()=>console.log("open")}
            onClose={()=>console.log("close")}
            onChange={(e, newValue) => {
              onChange(newValue);
              handleChange && handleChange(formOptions, newValue);
              if (dependentFields) dependentFields.forEach((item: any) => formOptions.change(`${item}`, null));
            }}
            isEdit={isEdit}
            notefooter={notefooter}
            limitTags={limitTags}
            textCount={textCount}
            maxHeight={maxHeight}
            disabled={disabled}
          />
          <ErrorMsg>{submitFailed && t(error)}</ErrorMsg>
        </InputContainer>
      )}
    </FormSpy>
  );
};

export default CustomSelect;

const InputContainer = styled.View`
  margin-bottom: 0;
`;

const ErrorMsg = styled.View`
  font-family: ${fonts.regular};
  color: ${colors.errorColor};
  margin-top: 5px;
  font-size: 12px;
  min-height: 14px;
`;
