import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import NormalTextSelect from '../Text/NormalTextSelect';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';

interface propType {
  label: string;
  close?: boolean;
  borderRadius?: number;
  onPress?: () => void;
  chipFontSize?: number;
  height?: number;
  width?: any;
  style?: object;
  color?: string;
}

export default function Chip(props: propType) {
  const {
    onPress,
    label,
    close = true,
    borderRadius = 4,
    chipFontSize = 1.5,
    width = 'auto',
    style,
    color = colors.menuSelectionBg,
  } = props;

  const Container = styled.View`
    padding-vertical: 2px; 
    padding-horizontal: 8px; 
    background-color: ${color};
    border-width: 1px;
    border-color: ${colors.primaryColor};
    border-radius: 4px;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: ${width};
    align-items: center;
  `;

  return (
    <Container style={[{ borderRadius }, style]}>
      <NormalTextSelect value={label} color={colors.primaryColor} fontsize={chipFontSize} />
      {close && (
        <TouchableOpacityIcon onPress={onPress}>
          <FontAwesomeIcon icon={faTimes} color={colors.primaryColor} size={12} />
        </TouchableOpacityIcon>
      )}
    </Container>
  );
}

const TouchableOpacityIcon = styled.TouchableOpacity`
  align-self: center;
  margin-left: 6px;
`;
