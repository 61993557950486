import { ADMISSION_PROCESS_ID_ENUM, USER_TYPE } from 'src/constant';

import dataTypes from '@data-driven-forms/react-form-renderer/data-types';
import { fileValidationConfig } from '../user-details/util';
import { validation } from 'src/constant/validation';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import { isWeb } from 'src/constant/device';
import { freeshipOptions } from './dd-options';
import { DropdownOptionsType } from 'src/types';
import { FormOptions } from '@data-driven-forms/react-form-renderer/renderer-context';
import { regexObject } from 'src/constant/regex';

export const applicationFormSchema = (
  admissionProcessId: string,
  subjectGroupOptions?: Array<string>,
  handleSubGrpChange?: (subGrpId: string) => void,
  userType?: number,
  instituteOptions?: DropdownOptionsType[],
  capId?: string,
) => {
  return {
    name: 'applicationForm',
    label: '',
    viewMode: 1,
    fields: [
      {
        component: 'custom-text-field',
        name: 'universityApplicationNumber',
        label: 'universityApplicationNumber.required.label',
        isRequired: true,
        customHiddenField: admissionProcessId !== ADMISSION_PROCESS_ID_ENUM[2],
        validate: [
          {
            type: validatorTypes.REQUIRED,
            message: validation.UNIVERSITY_APPLICATION_NUMBER,
          },
          {
            type: validatorTypes.PATTERN,
            pattern: regexObject.APPLICATION_NO_INVALID,
            message: 'universityApplicationNo.text.invalid'
          }
        ],
        dataType: dataTypes.STRING,
      },
      {
        name: 'universityApplicationDoc',
        label: 'universityForm.required.label',
        documentField: "universityApplicationDoc",
        component: 'file-upload',
        fileAtfield: 'universityApplicationDoc',
        isRequired: true,
        required_error: 'error.universityForm.required',
        type: 'file',
        compact: true,
        defaultFileName: 'university-form.label',
        category: 'Education',
        documentType: 'Passing',
        removePayload: false,
        customHiddenField: admissionProcessId !== ADMISSION_PROCESS_ID_ENUM[2],
        validate: [
          ...fileValidationConfig,
          {
            type: validatorTypes.REQUIRED,
            message: validation.UNIVERSITY_FORM_REQUIRED,
          },
        ],
      },
      {
        component: 'custom-text-field',
        name: 'capId',
        label: 'cap-application-id.required.label',
        isRequired: true,
        customHiddenField: admissionProcessId !== ADMISSION_PROCESS_ID_ENUM[0],
        validate: [
          {
            type: validatorTypes.REQUIRED,
            message: validation.DTE_APPLICATION_ID_REQUIRED,
          },
          {
            type: validatorTypes.PATTERN,
            pattern: regexObject.APPLICATION_NO_INVALID,
            message: 'capApplicationNo.text.invalid'
          }
        ],
        dataType: dataTypes.STRING,
        initializeOnMount: true,
        isDisabled: Boolean(capId),
      },
      {
        name: 'document',
        label: 'attach-cap-form.required.label',
        component: 'file-upload',
        fileAtfield: 'document',
        isRequired: true,
        required_error: 'attach-cap-form.required.text',
        type: 'file',
        compact: true,
        defaultFileName: 'cap-form.label',
        category: 'Education',
        documentType: 'Passing',
        removePayload: false,
        customHiddenField: admissionProcessId !== ADMISSION_PROCESS_ID_ENUM[0],
        validate: [
          ...fileValidationConfig,
          {
            type: validatorTypes.REQUIRED,
            message: validation.DTE_FORM_REQUIRED,
          },
        ],
      },
      {
        name: 'subjectGroup',
        label: 'subjectGroup.required.label',
        header: '',
        component: isWeb ? 'custom-select' : 'select',
        options: subjectGroupOptions,
        isRequired: true,
        customHiddenField: userType !== USER_TYPE.STUDENT,
        handleChange: isWeb
          ? (formOptions: any, newValue: any) => handleSubGrpChange && handleSubGrpChange(newValue?.value)
          : (props: any, formOptions: any, newValue: any) => handleSubGrpChange && handleSubGrpChange(newValue),
        validate: [
          {
            type: validatorTypes.REQUIRED,
            message: 'error.subjectGroup.required',
          },
        ],
      },
      {
        name: 'freeshipApplied',
        label: 'Applying for freeship/scholarship?',
        header: '',
        component: isWeb ? 'custom-select' : 'select',
        options: freeshipOptions,
        isRequired: false,
        customHiddenField: userType !== USER_TYPE.APPLICANT,
      },
      {
        name: 'inHouseApplied',
        label: 'Applying for In-house quota',
        header: '',
        component: isWeb ? 'custom-select' : 'select',
        options: freeshipOptions,
        isRequired: false,
      },
      {
        name: 'inHouseDetailInstituteCode',
        label: 'select-institute.required.label',
        header: '',
        component: isWeb ? 'custom-select' : 'select',
        options: instituteOptions,
        isRequired: true,
        validate: [
          {
            type: validatorTypes.REQUIRED,
            message: validation.INSTITUTE_NAME_REQUIRED,
          },
        ],
        condition: {
          when: "inHouseApplied",
          is: (inHouseAppliedValue: DropdownOptionsType) => {
            return isWeb ? inHouseAppliedValue.value : inHouseAppliedValue
          },
        },
      },
      {
        component: 'custom-text-field',
        name: 'inHouseDetailRollNo',
        label: 'form.studentId.required.label',
        isRequired: true,
        validate: [
          {
            type: validatorTypes.REQUIRED,
            message: validation.STUDENT_ID_REQUIRED,
          },
        ],
        dataType: dataTypes.STRING,
        condition: {
          when: "inHouseApplied",
          is: (inHouseAppliedValue: DropdownOptionsType) => {
            return isWeb ? inHouseAppliedValue.value : inHouseAppliedValue
          },
        },
      },
    ],
  };
};
