import React from 'react';
import { View, StyleSheet } from 'react-native';
import { TextField, InputAdornment } from '@mui/material';
import SmallTextAtom from '../Text/SmallTextAtom';
import { breakpoints, colors, fonts } from 'src/styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import MediumTextSelect from '../Text/MediumTextSelect';
import styled from 'styled-components';

export default function Input(props: any) {
  const {
    value,
    setValue,
    label,
    onBlur = null,
    id = 'standard-basic',
    header = '',
    notefooter = '',
    width = '100%',
    placeholder,
    multiline = false,
    disabled = false,
    maxRows = 5,
    maxWidth,
    hasError = false,
    typeInput = 'text',
    onkeyDown,
    disablePaste = false,
    hideVisibility,
    autoCapitalize = "none",
    prefix = '',
    inputHeight,
    align = 'left',
    className = '',
  } = props;
  const { theme } = useThemeSystem();
  const { t } = useI18n();
  const handleDisablePaste = (e) => {
    // When disablePaste prop is passed as true
    // disablePaste={true}
    // Paste will be disabled on the specific Field
    if (disablePaste) {
      e.preventDefault();
    }
  };

  return (
    <>
      {!hideVisibility && (
        <View style={{ opacity: disabled ? 0.5 : null }}>
          {header !== '' ? (
            <View style={styles.helperText}>
              <SmallTextAtom value={t(header)} color={theme?.input?.topLabel} />
            </View>
          ) : null}

          <CustomColorPicker
            onKeyDown={onkeyDown}
            type={typeInput}
            inputRef={props.inputRef}
            multiline={multiline}
            rows={maxRows}
            id={id}
            label={label}
            value={value}
            onBlur={onBlur}
            onChange={setValue}
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            placeholder={value ? value : placeholder}
            disabled={disabled}
            maxRows={maxRows}
            error={hasError}
            onPaste={handleDisablePaste}
            sx={{
              width: width ? width : null,
            }}
            inputProps={{
              style: { textAlign: align },
              ...(className ? { className } : {}),
            }}
            InputProps={{
              style: { textTransform: autoCapitalize === "none" ? "none" : "capitalize" },
              autoCapitalize: autoCapitalize,
              ...(prefix
                ? {
                  startAdornment: (
                    <InputAdornment position="start">
                      <MediumTextSelect value={prefix} fontSize={2.0} />
                    </InputAdornment>
                  ),
                }
                : {}),
            }}
          />

          {notefooter !== '' ? (
            <View style={styles.bottomText}>
              <SmallTextAtom value={t(notefooter)} color={theme?.input?.bottomLabel} />
            </View>
          ) : null}
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  helperText: {
    marginBottom: 4,
  },
  bottomText: {
    marginTop: 4,
  },
});

const CustomColorPicker = styled(TextField)`
  .MuiInputBase-multiline {
    padding: 0;
  }
  textarea {
    height: 95px!important;
  }
  .MuiTextField-root {
     @media (max-width: ${breakpoints.md}) {
      width: 100%;
    }
  }
  .MuiOutlinedInput-root {
    & .MuiOutlinedInput-input {
      font-family: ${fonts.regular};
      height: 24px;
      font-weight: 400;
      padding: 8px;
      padding-left: ${(props) => (props?.prefix ? 0 : 8)};
      font-size: 16;
      color: ${colors.primaryText};
      line-height: ${(props) => (props?.multiline ? null : '24px')};
    };
    & fieldset {
      border-color: ${(props) => props?.theme?.input?.borderColor};
    };
    &:hover fieldset {
      border-color: ${(props) => props?.theme?.input?.hoverBorder};
    };
    &.Mui-focused fieldset {
      border-color: ${(props) => props?.theme?.input?.activeBorder};
      border-width: 1;
    };
    &.Mui-disabled fieldset {
      border-color: ${(props) => props?.theme?.input?.disabledBorder};
    };
  };
  .MuiOutlinedInput-multiline {
    padding: 0px;
  };
  .MuiInputBase-input {
    height: ${(props: any) => props.multiline && props.inputHeight ? `${props.inputHeight}px !important` : 'auto'};
  };
`;
