import { gql } from "@apollo/client";

export const getInstitutesOverview = gql`
  query {
    institutes(limit: 20) {
      data {
        id
        name
        addressDetails {
          city
        }
      }
    }
  }
`;

export const getCoursesOverview = gql`
  query {
    courses(limit: 20) {
      data {
        id
        name
        duration
        durationType
      }
    }
  }
`;
