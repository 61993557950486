import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import formJSON from 'src/form-json/AtktReval/ATKTReval-setting-from.json';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { useI18n } from 'src/i18n/hooks';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useGetMerchantDetails } from 'src/graphql/payment/Merchant';
import { useGetCastes } from 'src/graphql/admission/caste-category';
import { updateInstituteSettings } from 'src/graphql/attendance';
import { useMutation, useQuery } from '@apollo/client';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import styled from 'styled-components';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ADD_SUCCESS } from 'src/constant/message';
import { ERROR, SUCCESS } from 'src/constant';
import { atktRevalSettings } from 'src/graphql/control-panels/atkt-reval-settings';
type TRevalData = {
  merchant: {
    id: string;
    name: string;
    merchId: string;
  };
  paymentServiceProvider: string;
  fees: {
    amountPerSubject: number;
    categories: {
      alias: string;
      id: string;
    }[];
    applicationFee: number;
    amountPerSoftCopy: number;
  }[];
};

type TAtktData = {
  merchant: {
    id: string;
    name: string;
    merchId: string;
  };
  paymentServiceProvider: string;
  subjectWiseFees: {
    feeAmount: number;
    max: number;
    min: number;
    applicationFee: number;
  }[];
};

function AtktRevalSettings() {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { setAlertDetails } = useAlertSystem();
  async function setTitle() {
    setHeading([
      {
        text: t('settings.label'),
        url: '',
      },
    ]);
  }

  const {
    data: atktRevalSettingsData,
    loading: atktRevalSettingsLoading,
    refetch,
  } = useQuery(atktRevalSettings, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setTitle();
    getMerchantDetails();
    handleFetchCastes();
  }, []);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm();

  const {
    getMerchantDetails,
    MerchantDetailsData,
    MerchantDetailsError,
    MerchantDetailsLoading,
    refetchMerchantDetails,
  } = useGetMerchantDetails();
  const { getCastes, castesData, castesError, castesLoading, refetchCastes } = useGetCastes();
  const [revalData, setRevalData] = useState<TRevalData>();
  const [atktData, setAtktData] = useState<TAtktData>();
  const [canClick, setCanClick] = useState<boolean>(false);
  const [updateInstitueAttendenceSettingMutateFunction] = useMutation(updateInstituteSettings);
  const castesOption = castesData?.castes?.data.map(({ name, id }: any) => ({
    label: name,
    value: id,
  }));
  const merchantOption = MerchantDetailsData?.NTTMerchants?.data?.map(item => ({
    label: item.name,
    value: item.id,
  }));

  function handleFetchCastes() {
    let filters = { defaultUserSelection: { eq: 'ALLOWED' } };
    getCastes({ variables: { filters: filters } });
  }

  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    if (!MerchantDetailsLoading) {
      formJSON[0].fields[0].merchantOption = merchantOption;
      formJSON[0].fields[1].merchantOption = merchantOption;
    }
    if (!castesLoading) {
      formJSON[0].fields[0].option = castesOption;
    }

    if (!atktRevalSettingsLoading) {
      setRevalData(atktRevalSettingsData?.institute?.settings?.commonFees?.reval);
      setAtktData(atktRevalSettingsData?.institute?.settings?.commonFees?.atkt);
    }
  }, [castesData, MerchantDetailsData, atktRevalSettingsData]);

  useEffect(() => {
    setElements({ ...formJSON[0] });
  }, []);

  useEffect(() => {
    if (atktData) {
      const convertedAtktData = {
        merchantId: {
          label: atktData?.merchant?.name,
          value: atktData?.merchant?.id,
        },
        paymentProvider: atktData?.paymentServiceProvider,
        subjectWiseFees: atktData.subjectWiseFees,
      };
      setValue('ATKTForm', convertedAtktData);
    }
    if (revalData) {
      const convertedRevalData = {
        fees: revalData?.fees.map(feeItem => ({
          label: feeItem.categories[0].alias,
          value: feeItem.categories[0].id,
          amountPerSubject: feeItem.amountPerSubject,
          applicationFee: feeItem.applicationFee,
          amountPerSoftCopy: feeItem.amountPerSoftCopy,
        })),
        merchantId: {
          label: atktData?.merchant?.name,
          value: atktData?.merchant?.id,
        },
        paymentProvider: revalData?.paymentServiceProvider,
      };
      setValue('RevalForm', convertedRevalData);
    }
  }, [atktData, revalData]);

  const onsubmit = async data => {
    const atkt = {
      subjectWiseFees: data?.ATKTForm.subjectWiseFees.map(item => ({
        feeAmount: item.feeAmount,
        min: item.min,
        max: item.max,
        applicationFee: item.applicationFee,
      })),
      merchant: data?.ATKTForm?.merchantId.value,
      paymentServiceProvider: data?.ATKTForm.paymentProvider,
    };
    const reval = {
      fees: data?.RevalForm?.fees.map(item => ({
        amountPerSubject: item?.amountPerSubject,
        amountPerSoftCopy: item?.amountPerSoftCopy,
        applicationFee: item?.applicationFee,
        categories: [item.value],
      })),
      merchant: data?.RevalForm?.merchantId.value,
      paymentServiceProvider: data?.RevalForm?.paymentProvider,
    };
    try {
      setCanClick(true);
      const result = await updateInstitueAttendenceSettingMutateFunction({
        variables: {
          payload: {
            commonFees: {
              reval: reval,
              atkt: atkt,
            },
          },
        },
      });
      if (result) {
        setAlertDetails({ message: ADD_SUCCESS.UPDATE_INSTITUTE_SETTINGS, level: SUCCESS });
        refetch();
      }
    } catch (err) {
      setAlertDetails({ message: err?.message, level: ERROR });
    } finally {
      setCanClick(false);
    }
  };

  if (MerchantDetailsLoading && castesLoading) {
    return (
      <LoadContentWrapper>
        <LoaderSpinner styles={{ height: '100%', width: '100%' }} />
      </LoadContentWrapper>
    );
  }

  return (
    <LoadContentWrapper style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
      <Wrapper>
        {fields &&
          fields.map((field: any) => (
            <>
              {field.id === 'RevalForm' && (
                <HeaderThree value={t('revalSetting.label')} align={'left'} />
              )}
              {field.id === 'ATKTForm' && (
                <HeaderThree value={t('atktSetting.label')} align={'left'} />
              )}
              {/* @ts-ignore */}
              <Element key={field.id} field={field} control={control} errors={errors} />
            </>
          ))}
        <SecondaryBtn
          label={t('save.label')}
          onPress={handleSubmit(onsubmit)}
          width={66}
          canLoad={canClick}
        />
      </Wrapper>
    </LoadContentWrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export default AtktRevalSettings;
