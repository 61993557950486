import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Control } from 'react-hook-form/dist/types/form';
import { DropdownOptionsType } from 'src/types';
import importDirectApplicationsJSON from '../../../form-json/admission/import-direct-applications.json';
import Element from '../Forms/ApplicationElement.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';

interface Props {
  control: Control<FieldValues, object>;
  errors: { [x: string]: any };
  subjectGroups: {
    fee: DropdownOptionsType;
    seats: number;
    subjectGroup: DropdownOptionsType;
  }[];
  handleFileUpload: (e: any) => void;
  importAttachment: {
    documentName?: String;
    document?: File | undefined;
  };
}

export default function ImportDirectApplicationsForm(props: Props) {
  const { control, errors, subjectGroups, handleFileUpload, importAttachment } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};
  const { t } = useI18n();

  useEffect(() => {
    setElements(importDirectApplicationsJSON[0]);
  }, []);

  useEffect(() => {
    if (subjectGroups && subjectGroups.length > 0) {
      const subGrpOptions = subjectGroups?.map((item: any) => item?.subjectGroup);
      importDirectApplicationsJSON[0].fields[0].option = subGrpOptions;
      const newData = { ...importDirectApplicationsJSON[0] };
      setElements(newData);
    }
  }, [subjectGroups]);

  return (
    <>
      {fields ? (
        <>
          {fields.map((field: any, i: number) => (
            <Element key={i} field={field} control={control} errors={errors} />
          ))}
          <InputWrapper>
            <>
              <input
                style={{ display: 'none' }}
                id="importDirectApplications"
                multiple
                type="file"
                accept=".csv,.xls,.xlsx"
                onChange={handleFileUpload}
              />

              <label htmlFor="importDirectApplications">
                <SecondaryBtn
                  label={t('selectFile.text')}
                  onPress={() => {}}
                  width={150}
                  style={{ height: 32 }}
                />
              </label>
            </>

            <DocumentNameWrapper>
              <MediumTextSelect value={importAttachment?.documentName} />
            </DocumentNameWrapper>
          </InputWrapper>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const InputWrapper = styled.View`
  display: flex;
  flex-direction: row;
`;

const DocumentNameWrapper = styled.View`
  margin: 5px;
`;
