/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect, Fragment } from 'react';
import { View } from 'react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import assignmentFormJSON from '../../../../form-json/assignment/assignment-form';
import { FileCategory } from 'src/constant';
import Element from '../../Forms/ApplicationElement';
import AttachFiles from 'src/components/atoms/AttachFiles/index';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { height, isWeb } from 'src/constant/device';
import { attachmentType } from 'src/components/organism/Assignments/types';
import { graphqlQuery } from 'src/graphql/util';
import { getAssignmentListQuery } from 'src/graphql/communication/assignments';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import { createDocumentObject } from 'src/utils/utility';
import { CustomReactNativeFile } from '../../FileUpload/type';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';
interface Props {
  setValue: any;
  getValues: any;
  control: any;
  errors: any;
  setAttachments: any;
  attachments: attachmentType[];
  isEditModal: boolean;
  isDynamic?: boolean;
  assignmentData: any;
  removeAttach: string[];
  setRemovedAttachment: any;
  clearErrors: any;
  setError: any;
  audienceData:any;
  setAudienceData:any;
}

export interface documentObjectType {
  documentName: string;
  document: File;
  type: string;
}

export default function AssignmentForm(props: Props) {
  const { t } = useI18n();
  const {
    control,
    errors,
    setValue,
    setAttachments,
    attachments,
    removeAttach,
    setRemovedAttachment,
    isEditModal,
    isDynamic = false,
    assignmentData,
    setError,
    clearErrors,
    setAudienceData,
  } = props;
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};

  let assignmentInformation = { ...assignmentData };

  useEffect(() => {
    resetSelect();
    if (isEditModal) {
      getAssignmentInformation();
    } else {
      setElements(assignmentFormJSON[0]);
    }
  }, []);

  async function getAssignmentInformation() {
    if (isDynamic) {
      let assignmentFilter = {
        id: {
          eq: '',
        },
      };
      const { loading, data: assignmentData } = await graphqlQuery(
        getAssignmentListQuery,
        { filters: assignmentFilter },
        true,
      );
      if (!loading && assignmentData) {
        assignmentInformation = assignmentData?.getAnnouncements?.data[0];
        loadBasicdata();
      }
    } else {
      loadBasicdata();
    }
  }

  function loadBasicdata() {
    setValue('title', assignmentInformation?.title);
    setValue('description', assignmentInformation?.description);
    const parsedDate = parse(assignmentInformation.mdueDate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date());

    if (isWeb) {
      setValue('dueDate', parsedDate);
      setValue('totalMarks', assignmentInformation?.totalMarks);
    } else {
      let newFormat = format(parsedDate, DT.DATE_FORMAT_SLASH);
      setValue('dueDate', newFormat);
      setValue('totalMarks', (assignmentInformation?.totalMarks).toString());
    }
    
    if (assignmentInformation.attachments) {
      setAttachments(assignmentInformation.attachments);
    }
    setElements(assignmentFormJSON[0]);
  }

  function resetSelect() {
    clearErrors('audienceType');
    clearErrors('subject');
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === 'audienceType') {
      setAudienceData([]);
    }
  }

  async function handleFilesAttach(fileData: CustomReactNativeFile[], names?: any) {
    const newFileData = await Promise.all(
      fileData.map((file) => createDocumentObject( file, FileCategory.Assignment)),
    );
    if (newFileData) {
      setAttachments([...attachments, ...newFileData])
    } 
  }

  function removeAttachment(idx: any) {
    let attachmentArray = [...attachments];
    let imageId = attachmentArray[idx].id;
    attachmentArray.splice(idx, 1);
    setAttachments(attachmentArray);
    if (isEditModal && imageId) {
      storedRemoveId(imageId);
    }
  }

  function storedRemoveId(idx: any) {
    let removeIdArray = [...removeAttach];
    removeIdArray.push(idx);
    setRemovedAttachment(removeIdArray);
  }

  return (
    <View>
      {fields ? (
        fields?.map((field: any, i: number) => {
          switch(field?.id) {
            case 'attachmentLabel':
              return <Fragment key={`sub${i}`}>
                {attachments && attachments?.length > 0 && (
                  <View style={{ marginBottom: 8 }}>
                    <SmallTextAtom value={t(field?.textHead)} />
                  </View>
                )}

                <AttachmentWrapper>
                  {attachments?.map((fileData, i: number) => (
                    <AttachmentView key={`attachload${i}`}>
                      <AttachFiles
                        key={`attach${i}`}
                        fileData={fileData}
                        onPress={() => removeAttachment(i)}
                      />
                    </AttachmentView>
                  ))}
                </AttachmentWrapper>
              </Fragment>
            default:
              return <View
                key={`subjectP${i}`}
                style={{zIndex: field.id === 'dueDate' ? 1 : -1, marginBottom: field.id === 'audienceType' ? -24 : 0 }}
              >
                <Element
                  key={`subjectf${i}`}
                  field={field}
                  control={control}
                  errors={errors}
                  handleCustomSelect={handleCustomSelect}
                  handleFilesAttach={handleFilesAttach}
                  dynamicValidation={false}
                  isCustomDisable={field.isCustomDisable && isEditModal}
                  setError={setError}
                  clearErrors={clearErrors}
                />
            </View>
          }
        })
      ) : (
        <SpinnerView><LoaderSpinner /></SpinnerView>
      )}
    </View>
  );
}

const SpinnerView = styled.View`
  width: ${isWeb ? '450px' : 'auto'};
  height: ${isWeb ? 547 : height - 300}px;
  justify-content: center;
  align-items: center;
`;

const AttachmentWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: ${isWeb ? 4 : 0}px;
`;

const AttachmentView = styled.View`
  margin-right: ${isWeb ? 24 : 0}px;
  margin-bottom: ${isWeb ? 24 : 0}px;
`;