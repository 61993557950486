import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, useHistory, withRouter } from 'src/routes/routing.web';
import { Login } from '../components/organism/Login/EdbaAdmin';
import Dashboard from '../components/organism/Dashboard';
import { NotFound } from '../components/organism/NotFound';
import { WebPrivateRoute } from './web-private-route';
import BoardUniversity from '../components/organism/Academics/BoardUniversities.web';
import Stream from '../components/organism/Academics/Stream.web';
import Courses from '../components/organism/Academics/Courses.web';
import Classes from '../components/organism/Academics/Classes.web';
import Subjects from '../components/organism/Academics/Subjects.web';
import SubjectGroup from '../components/organism/Academics/SubjectGroups.web';
import Institutes from '../components/organism/Institutes';
import { OnBoardingPrivateRoute } from './onboarding-private-route';
import User from '../components/organism/ControlPanel/User.web';
import { InstituteUserLogin } from 'src/components/organism/Login/InstituteUser';
import InstituteClasses from '../components/organism/Classes/index.web';
import InstituteCourses from '../components/organism/Courses';
import BatchStudents from '../components/organism/Courses/BatchStudents/index.web';
import { PublicInstituteRegister } from 'src/components/organism/Institutes/Public';
import NewsNotices from 'src/components/organism/Communication/NewsNotices';
import Employees from '../components/organism/Employees/index.web';
import { UserType, USER_TYPE, USER_TYPE_ENUM } from 'src/constant';
import Language from 'src/components/organism/Localization/Language';
import OnboardingRoot from 'src/components/organism/onboarding/root';
import { isAuthUser } from 'src/graphql/common';
import { RequestResetPassword } from 'src/components/organism/ResetPassword/RequestResetPassword';
import { ResetPassword } from 'src/components/organism/ResetPassword/ResetPassword';
import KeyValues from 'src/components/organism/Localization/KeyValues';
import Notifications from 'src/components/organism/Notifications';
import jwt_decode from 'jwt-decode';
import { getAllowedModules, getTokenFromStorage } from 'src/components/services/storage-service';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import AssignmentModal from 'src/components/atoms/Modals/Assignment/index.web';
import Attendance from 'src/components/organism/Attendance';
import Events from 'src/components/organism/Events';
import AssignmentRoot from 'src/components/organism/Assignments/AssignmentRoot';
import NewsModalContent from 'src/components/organism/Communication/News/ModalContent';
import { useI18n } from 'src/i18n/hooks';
import UnsupportedResolution from 'src/components/molecules/UnsupportedResolution';
import AttendenceSettings from 'src/components/organism/Attendance/InstituteSettingsForAttendence';
import StudentAttendance from 'src/components/organism/Attendance/Children/StudentAttendance';
import EventRoot from 'src/components/molecules/Event/EventRootContent';
import { allowedModulesState, userInformation } from 'src/utils/manageState';
import { UserRegistration } from 'src/components/organism/UserRegistration';
import { Message } from 'src/components/organism/Message';
import BatchWeb from 'src/components/organism/Academics/Batch.web';
import { useProfileDetail } from 'src/contexts/profile-detail-context';
import { studentPersonalInformationQuery } from 'src/graphql/onboarding/student';
import { graphqlQuery } from 'src/graphql/util';
import AcademicYearWeb from 'src/components/organism/Academics/Years.web';
import StudentPayment from 'src/components/organism/Payment/Student';
import InstitutePayment from 'src/components/organism/Payment/Institute';
import InstitutePaymentOverview from 'src/components/organism/Payment/Institute/overview';
import PaymentDetail from 'src/components/organism/Payment/Institute/PaymentDetail';
import Payment from 'src/components/organism/Payment/Institute/ControlPanel';
import PaymentStatusContentAction from 'src/components/molecules/Payment/Institute/PaymentStatusContentAction';
import PaymentReceipt from 'src/components/organism/Payment/Student/PaymentReceipt';
import StudentsList from 'src/components/organism/Payment/Institute/ControlPanel/StudentList';
import PaymentAccountDetails from 'src/components/organism/Payment/Institute/AccountDetails';
import PaymentConcessions from 'src/components/organism/Payment/Institute/ControlPanel/Concession';
import LinkAccount from 'src/components/organism/Payment/Admin/LinkAccount';
import AccountsLink from 'src/components/organism/Payment/Admin';
import ConcessionDetails from 'src/components/organism/Payment/Institute/ControlPanel/Concession/ConcessionDetails';
import Messages from 'src/components/organism/Messages';
import Grades from 'src/components/organism/Assessment/Grades/index.web';
import Test from 'src/components/organism/Assessment/Test/index.web';
import Exams from 'src/components/organism/Assessment/Exams/index.web';
import Marks from 'src/components/organism/Assessment/Marks/index.web';
import Results from 'src/components/organism/Assessment/Results/index.web';
import { ProfileView } from '../components/organism/Profile/index.web';
import Admissions from 'src/components/organism/Admission/index.web';
import AdmissionDetail from 'src/components/organism/Admission/AdmissionDetail.web';
import Applications from 'src/components/organism/Applications/index.web';
import ApplicantRegistration from 'src/components/organism/Registration/index.web';
import Applicant from 'src/components/organism/Login/Applicant';
import ApplicationFormViewer from 'src/components/organism/Applications/FormViewer';
import CasteCategories from 'src/components/organism/Admission/CasteCategories.web';
import ReservationGroups from 'src/components/organism/Admission/ReservationGroups.web';
import EducationDetails from 'src/components/organism/Applications/EducationDetails';
import AdmissionCriteria from 'src/components/organism/Admission/Criteria.web';
import AdmissionQualifications from 'src/components/organism/Admission/Admin/Qualifications/Qualifications';
import Credits from 'src/components/organism/Assessment/Credits/index.web';
import Promotions from 'src/components/organism/Enrollment/Promotions/index.web';
import PromotedStudents from 'src/components/organism/Enrollment/Promotions/PromotedStudents/index.web';
import LinkClosed from 'src/components/organism/Admission/LinkClosed';
import ApplicationDetails from 'src/components/organism/Applications/ApplicationDetails.web';
import AdmissionLinkPage from 'src/components/organism/Admission/Public/AdmissionLinkPage';
import Merchants from 'src/components/organism/Payment/Admin/NTT/merchants.web';
import RedirectionScreens from 'src/components/organism/Login/RedirectionScreens/RedirectionScreen';
import CAPInvitesList from 'src/components/organism/Admission/CAPInvitesList.web';
import Logs from 'src/components/organism/Logs';
import UserLogin from 'src/components/organism/Login/UserLogin';
import AccountSelection from 'src/components/organism/Login/AccountSelection';
import GetStarted from 'src/components/organism/Registration/GetStarted.web';
import DirectInvitesList from 'src/components/organism/Admission/DirectInvitesList.web';
import Students from 'src/components/organism/Students/index.web';
import OtherPayment from 'src/components/organism/Payment/Institute/ControlPanel/OtherPayment';
import OtherPaymentDetail from 'src/components/organism/Payment/Institute/ControlPanel/OtherPaymentDetail';
import Timetable from 'src/components/organism/Schedule/index.web';
import BatchwiseTimetable from 'src/components/organism/Schedule/BatchwiseSchedule/index.web';
import GradingSystems from 'src/components/organism/Assessment/Grades/GradingSystems.web';
import LotteryListing from 'src/components/organism/Admission/Lottery/LotteryListing.web';
import AdmissionDetailPage from 'src/components/organism/Admission/Public/AdmissionDetailPage';
import StudentResult from 'src/components/organism/Assessment/Results/Student/index.web';
import PublicInvitations from 'src/components/molecules/Admission/Invitation/PublicInvitations.web';
import Documents from 'src/components/organism/Admission/Admin/Documents/Documents';
import { SD } from 'src/constant/standardDimensions';
import ResultDetail from 'src/components/organism/Assessment/Results/Student/ResultDeatils';
import AtktRevalSettings from 'src/components/organism/Attendance/AtktRevalSettings';

const RouterComponent = (props: any) => {
  const history = useHistory();
  const currentUser = userInformation();
  const [userType, setUserType] = useState();
  const [userDetail, setUserDetail] = useState<any>();
  const [isMobile, setMobile] = useState(false);
  const { t } = useI18n();
  const location: any = useLocation();
  const { setUserProfileDetails: setBasicInformation } = useProfileDetail();
  const background = location.state && location.state.background;
  const [pathname, setPathname] = useState('');
  const excludedPaths = ['get-started', 'reset-password', 'redirection', '/admission/links', "/admission/apply"];
  const assignmentPath =
    currentUser?.userType == 2
      ? [
        '/assignments/:assignmentId/detail', //for teacher
        '/assignments/:assignmentId/student-list',
        '/assignments/:assignmentId/:studentId/detail',
      ]
      : [
        '/assignments/:type/:assignmentId/detail',
        '/assignments/:type/:assignmentId/:studentId/detail',
        '/assignments/:type/:assignmentId/discussion',
      ];

  useEffect(() => {
    checkIfUserLoggedIn();
  }, [currentUser?.userId]);

  const isApplicant = currentUser?.userType == USER_TYPE.APPLICANT;

  const checkIfUserLoggedIn = async () => {
    const TOKEN = await getTokenFromStorage();
    if (TOKEN) {
      await isAuthUser({ history: history, isNativeRoute: false, props: props });
      const loginEmployeeInformation: any = jwt_decode(TOKEN);
      setUserType(loginEmployeeInformation?.userType);
      console.log("================ Employee Login Information ================")
      console.log(loginEmployeeInformation)
      console.log("================ Employee Login Information ================")
      setUserDetail(loginEmployeeInformation?.userDetails);

      // to store basic info of student user in context
      if (currentUser && currentUser.userType === 3) {
        const { data, loading } = await graphqlQuery(studentPersonalInformationQuery, {
          studentId: currentUser?.userId,
        });
        if (!loading && data && data?.instituteStudent) {
          setBasicInformation(data?.instituteStudent);
        }
      }
    }
    const allowedModules = (Object.keys(currentUser)?.length > 0 && !isApplicant) ? await getAllowedModules() : null;
    if (allowedModules) {
      allowedModulesState(allowedModules);
    }
  };

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (window.innerWidth <= 480) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    });
    //console.log('resizeObserver', resizeObserver);
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const isPathExcluded = (pathname: string, paths: string[]) => {
    return paths.some(path => pathname.includes(path));
  }

  return (
    // <Router>

    <React.Fragment>
      {isMobile && !isPathExcluded(pathname, excludedPaths) ? (
        <UnsupportedResolution />
      ) : (
        <React.Fragment>
          <Switch location={background || location}>
            <Route
              path="/applicant/signup/:prefix?/:requestId?/:capId?"
              render={() => <ApplicantRegistration />}
            />
            <Route path="/admin/login" render={() => <Login />} />
            <Route
              exact
              path={['/login/:inviteCode?', '/login/:prefix?/:requestId?/:capId?']}
              render={() => <UserLogin />}
            />
            <Route
              exact
              path={[
                '/account-selection/:inviteCode?',
                '/account-selection/:prefix?/:requestId?/:capId?',
              ]}
              render={() => <AccountSelection />}
            />
            <Route
              exact
              path="/redirection/:redirectionStep"
              render={() => <RedirectionScreens />}
            />
            <Route exact path="/" render={() => <>{history.push('/login')}</>} />
            {/* 3. ResetPassword */}
            <Route path="/request-reset-password" render={() => <RequestResetPassword />} />
            <Route path="/reset-password/edba-admin/:token?" render={() => <ResetPassword />} />
            <Route path="/reset-password/employee/:token?" render={() => <ResetPassword />} />
            <Route path="/reset-password/student/:token?" render={() => <ResetPassword />} />
            {/* Password Setup Successfull */}
            {/* <Route path="/edba/get-started/completed" render={() => <Message />} /> */}
            {/* 4. OnBoarding */}
            <OnBoardingPrivateRoute
              exact
              path={[
                '/onboarding/:step?/:inviteCode?',
                '/onboarding/:step?/:prefix?/:requestId?/:capId?',
              ]}
              component={OnboardingRoot}
              userType={userType}
              authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            />
            {/* 4. Admin panel */}
            <WebPrivateRoute
              exact
              path={['/dashboard/:inviteCode?', '/dashboard/:prefix?/:requestId?/:capId?']}
              component={Dashboard}
              authUser={[
                USER_TYPE.EDBA_ADMIN,
                USER_TYPE.EMPLOYEE,
                USER_TYPE.STUDENT,
                USER_TYPE.APPLICANT,
              ]}
              userType={userType}
            // hideFooter={true}
            />
            <WebPrivateRoute
              path="/profile/:step?/:mode?"
              component={ProfileView}
              authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
              userType={userType}
            />
            <WebPrivateRoute
              path="/institutes/limit/:pageLimit?/page/:page?"
              component={Institutes}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
            />
            {/* 6. public institute */}
            <Route
              exact
              path="/institute/register/school"
              render={() => <PublicInstituteRegister />}
            />
            <Route
              exact
              path="/institute/register/college"
              render={() => <PublicInstituteRegister />}
            />
            <Route
              exact
              path="/institute/register/group"
              render={() => <PublicInstituteRegister />}
            />
            {/* commuinication */}
            <WebPrivateRoute
              path="/assignments"
              authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
              component={AssignmentRoot}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['TEACHING']}
            />
            {/* events */}
            <WebPrivateRoute
              path="/events"
              authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
              component={Events}
              userType={userType}
              userSubType={userDetail?.type}
            />
            {/* Academic*/}
            <WebPrivateRoute
              authUser={[USER_TYPE.EDBA_ADMIN]}
              path="/academics/boards-universities/limit/:dataLimit?/page/:page?"
              component={BoardUniversity}
              userType={userType}
            />
            <WebPrivateRoute
              authUser={[USER_TYPE.EDBA_ADMIN]}
              path="/academics/streams/limit/:dataLimit?/page/:page?"
              component={Stream}
              userType={userType}
            />
            <WebPrivateRoute
              authUser={[USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE]}
              path="/academics/courses/limit/:dataLimit?/page/:page?"
              component={Courses}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            <WebPrivateRoute
              authUser={[USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE]}
              path="/academics/classes/limit/:dataLimit?/page/:page?"
              component={Classes}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            <WebPrivateRoute
              path="/academics/subjects/limit/:dataLimit?/page/:page?"
              authUser={[USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE]}
              component={Subjects}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            <WebPrivateRoute
              path="/academics/subject-groups/limit/:dataLimit?/page/:page?"
              authUser={[USER_TYPE.EMPLOYEE]}
              component={SubjectGroup}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            <WebPrivateRoute
              path="/time-table"
              component={Timetable}
              authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'TEACHING']}
            />
            {/* Communication */}
            <WebPrivateRoute
              path="/communication"
              component={NewsNotices}
              authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
              userType={userType}
            />
            <WebPrivateRoute
              path="/messages"
              component={Messages}
              authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/notifications/limit/:dataLimit?/page/:page?"
              component={Notifications}
              authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
              userType={userType}
            />
            {/* Classes */}
            <WebPrivateRoute
              path="/classes"
              component={InstituteClasses}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            {/* Courses */}
            <WebPrivateRoute
              path="/classrooms"
              component={InstituteCourses}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'HOD']}
            />
            <WebPrivateRoute
              path="/academics/years/limit/:dataLimit?/page/:page?"
              authUser={[USER_TYPE.EMPLOYEE]}
              component={AcademicYearWeb}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            <WebPrivateRoute
              path="/academics/years/:academicId/batch/limit/:dataLimit?/page/:page?"
              authUser={[USER_TYPE.EMPLOYEE]}
              component={BatchWeb}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            <WebPrivateRoute
              path="/classroom/:batchId/:division/students/limit/:dataLimit?/page/:page?"
              component={BatchStudents}
              userType={userType}
              authUser={[USER_TYPE.EMPLOYEE]}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'HOD']}
            />
            <WebPrivateRoute
              path="/classroom/:batchId/:division/timetable"
              component={BatchwiseTimetable}
              userType={userType}
              authUser={[USER_TYPE.EMPLOYEE]}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'HOD']}
            />
            <WebPrivateRoute
              path="/students/:urlBatchId?/:urlDivision?/limit/:dataLimit?/page/:page?"
              component={Students}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'HOD']}
            />
            {/* 5. Employee */}
            <WebPrivateRoute
              path="/employees/limit/:dataLimit?/page/:page?"
              component={Employees}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'HOD']}
            />
            <WebPrivateRoute
              path="/employee/:userId/:step/:mode?"
              component={ProfileView}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              componentProps={{
                profileUserType: UserType.INSTITUTE_EMPLOYEE,
              }}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'HOD']}
            />
            <WebPrivateRoute
              path="/student/:userId/:step/:mode?"
              component={ProfileView}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              componentProps={{
                profileUserType: UserType.INSTITUTE_STUDENT,
              }}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'HOD']}
            />
            {/* Control Panel Attendence Setting */}
            <WebPrivateRoute
              path="/control-panel/settings"
              component={AttendenceSettings}
              userType={userType}
              authUser={[USER_TYPE.EMPLOYEE]}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'TEACHING']}
            />
            <WebPrivateRoute
              path="/control-panel/ATKTRevalsettings"
              component={AtktRevalSettings}
              userType={userType}
              authUser={[USER_TYPE.EMPLOYEE]}
              userSubType={userDetail?.type}
              subAuth={['ADMIN', 'TEACHING']}
            />
            {/* 6. Control panel */}
            <WebPrivateRoute
              path="/control-panel/users/limit/:dataLimit?/page/:page?"
              component={User}
              userType={userType}
              authUser={[USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE]}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            <WebPrivateRoute
              exact
              path="/attendance/history"
              component={StudentAttendance}
              authUser={[USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/attendance/mark/student"
              component={Attendance}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['TEACHING']}
            />
            <WebPrivateRoute
              path="/attendance/history/student"
              component={Attendance}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['TEACHING']}
            />
            <WebPrivateRoute
              path="/control-panel/translations/limit/:dataLimit?/page/:page?"
              component={Language}
              authUser={[USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            <WebPrivateRoute
              path="/translations/key-values/:language?/limit/:dataLimit?/page/:page?"
              component={KeyValues}
              authUser={[USER_TYPE.EDBA_ADMIN, USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
              subAuth={['ADMIN']}
            />
            <WebPrivateRoute
              path="/accounts"
              component={AccountsLink}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/ndps/merchants/limit/:dataLimit?/page/:page?"
              component={Merchants}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/control-panel/payments"
              component={Payment}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/payments/fees-composition/:compositionId?"
              component={StudentsList}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/payments/concession/limit/:dataLimit?/page/:page?"
              component={PaymentConcessions}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/payments/overview/limit/:dataLimit?/page/:page?"
              component={InstitutePayment}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/payments/overview/:ayid/limit/:dataLimit?/page/:page?"
              component={InstitutePaymentOverview}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/payments/overview/batch/:batchId?"
              component={PaymentDetail}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/payments/pending"
              component={StudentPayment}
              authUser={[USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/payments/paid"
              component={StudentPayment}
              authUser={[USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/payments/overview"
              component={InstitutePayment}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/payments/account_details/limit/:dataLimit?/page/:page?"
              component={PaymentAccountDetails}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/payments/concession_details/:concessionId"
              component={ConcessionDetails}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/control-panel/other-payment/limit/:dataLimit?/page/:page?"
              component={OtherPayment}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/other-payment/:paymentId/limit/:dataLimit?/page/:page?"
              component={OtherPaymentDetail}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            {/* Assessment */}
            <WebPrivateRoute
              exact
              path="/assessment/grades/:urlGradingSystemId?"
              component={Grades}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
            />
            <WebPrivateRoute
              exact
              path="/assessment/tests/:urlBatchId?/limit/:dataLimit?/page/:page?/"
              component={Test}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/assessment/exams/:urlBatchId?/limit/:dataLimit?/page/:page?"
              component={Exams}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/assessment/marks/:urlBatchId?/:urlDivision?/:urlExamId?/:urlSubjectId?/limit/:dataLimit?/page/:page?"
              component={Marks}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/assessment/results/:urlBatchId?/:urlDivision?/:urlExamId?/limit/:dataLimit?/page/:page?"
              component={Results}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/assessment/credits/:urlBatchId?"
              component={Credits}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/assessment/grading-systems/limit/:dataLimit?/page/:page?"
              component={GradingSystems}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
            />
            <WebPrivateRoute
              exact
              path="/promotions"
              component={Promotions}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/classroom/:urlBatchId"
              component={PromotedStudents}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/public-invitations/:urlAdmissionId/limit/:dataLimit?/page/:page?"
              component={PublicInvitations}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/cap-invitations/:urlAdmissionId/limit/:dataLimit?/page/:page?"
              component={CAPInvitesList}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/direct-invitations/:urlAdmissionId/limit/:dataLimit?/page/:page?"
              component={DirectInvitesList}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/admissions/limit/:dataLimit?/page/:page?"
              component={Admissions}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <Route
              exact
              path="/admission/links/:instituteId?"
              render={() => <AdmissionLinkPage />}
            />
            <Route
              exact
              path="/admission/apply/:admissionId"
              render={() => <AdmissionDetailPage />}
            />
            <WebPrivateRoute
              path="/admission/criteria/limit/:dataLimit?/page/:page?"
              component={AdmissionCriteria}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/application/list/limit/:dataLimit?/page/:page?"
              component={Applications}
              authUser={[USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path={[
                '/application/invite/:inviteCode?',
                '/application/add/:prefix?/:requestId?/:capId?',
              ]}
              component={ApplicationFormViewer}
              authUser={[USER_TYPE.APPLICANT]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/application/edit/:urlApplicationId"
              component={ApplicationFormViewer}
              authUser={[USER_TYPE.APPLICANT, USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/application/:urlApplicationId/qualifications"
              component={EducationDetails}
              authUser={[USER_TYPE.APPLICANT, USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
            />

            <WebPrivateRoute
              exact
              path="/admission/documents/limit/:dataLimit?/page/:page?"
              component={Documents}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/admission/caste-categories/limit/:dataLimit?/page/:page?"
              component={CasteCategories}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/admissions/:admissionId?/detail/limit/:dataLimit?/page/:page?"
              component={AdmissionDetail}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/lottery/:admissionId/:lotteryId/limit/:dataLimit?/page/:page?"
              component={LotteryListing}
              authUser={[USER_TYPE.EMPLOYEE]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/admission/reservation-groups/limit/:dataLimit?/page/:page?"
              component={ReservationGroups}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/admission/qualifications/limit/:dataLimit?/page/:page?"
              component={AdmissionQualifications}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              path="/logs/limit/:dataLimit?/page/:page?"
              component={Logs}
              authUser={[USER_TYPE.EDBA_ADMIN]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/results/limit/:dataLimit?/page/:page?"
              component={StudentResult}
              authUser={[USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
            />
            <WebPrivateRoute
              exact
              path="/results/:examId"
              component={ResultDetail}
              authUser={[USER_TYPE.STUDENT]}
              userType={userType}
              userSubType={userDetail?.type}
            />

            <Route path='/404' component={NotFound} />
            <Route path='/admission-closed' component={LinkClosed} />
            {/* 6. 404 - Not found */}
            <Route render={() => <NotFound />} />
          </Switch>
          <Route
            path="/communication/notices/:newsnoticeId/detail"
            children={
              <NormalModal
                infoModal={true}
                setModalVisible={() => { }}
                modalVisible={true}
                handleSave={() => { }}
                Headerpopup={t('notice.label')}
                width={SD.primaryInfoModal.width}
                height={SD.primaryInfoModal.height}
                maxWidth={"lg"}>
                <NewsModalContent newsDetail={{}} type={'NOTICE'} />
              </NormalModal>
            }
          />

          <Route
            path="/communication/news/:newsnoticeId/detail"
            children={
              <NormalModal
                infoModal={true}
                setModalVisible={() => { }}
                modalVisible={true}
                handleSave={() => { }}
                Headerpopup={t('news.label')}
                width={SD.primaryInfoModal.width}
                height={SD.primaryInfoModal.height}
                maxWidth={"lg"}>
                <NewsModalContent newsDetail={{}} type={'NEWS'} />
              </NormalModal>
            }
          />

          <Route
            path="/payments/paid/:receiptId/receipt"
            children={
              <NormalModal
                infoModal={true}
                setModalVisible={() => {
                  /**/
                }}
                modalVisible={true}
                handleSave={() => {
                  /**/
                }}
                Headerpopup={''}
                width={780}
                height={640}
                maxWidth={'lg'}
                contentStyles={{ padding: '32px 0px' }}>
                <PaymentReceipt />
              </NormalModal>
            }
          />

          <Route
            path={assignmentPath}
            children={
              <AssignmentModal
                setModalVisible={() => {
                  /**/
                }}
                modalVisible={true}
                cancelButtonLabel="done.label"
                toggleWork={true}
                Headerpopup={t('assignment.label')}
                setToggleWork={() => {
                  /**/
                }}
              />
            }
          />

          <Route
            path={['/events/upcoming/:eventId/detail', '/events/upcoming/:eventId/edit']}
            children={<EventRoot type={'upcoming'} />}
          />

          <Route
            path={['/events/past/:eventId/detail', '/events/past/:eventId/edit']}
            children={<EventRoot type={'past'} />}
          />
          <Route
            path={[
              '/dashboard/:applicationId/detail',
              '/application/list/limit/:dataLimit?/page/:page?/:applicationId/detail',
            ]}
            children={<ApplicationDetails />}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default withRouter(RouterComponent);
