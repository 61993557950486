import React, { useEffect, useState } from 'react';
import { dayWiseAttendence } from 'src/graphql/attendance';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useGetStudentsList } from 'src/graphql/classes';
import Attendancetable from 'src/components/molecules/Table/AttendanceTable';
import { height } from 'src/constant/device';
import { TableDiv } from 'src/components/molecules/Table/TableAtom';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import styled from 'styled-components'
import { DT } from 'src/constant/dateTime';
import { format } from 'date-fns';
import { properCase } from 'src/utils/utility';
import { createSortFilter } from 'src/components/services';

const MarkAttendance = (props: any) => {
  const {
    batch,
    divisioninfo,
    selectedSlotInfo,
    attendenceType,
    studentsAttendenceInfo,
    setTotalStudentPresent,
    setStudentsAttendenceInfo,
    setTotalStudents,
    currentDate,
    headCells,
    setHeadCells,
    setStudentsDataAvailable
  } = props;
  const todaysDate = format(new Date(), DT.DATE_FORMAT_SLASH);
  const timezone = DT.TIMEZONE;

  const dayWiseAttendenceInput = {
    batch: batch.value,
    class: batch.class.id,
    division: divisioninfo,
    start: currentDate ? currentDate : todaysDate,
    end: currentDate ? currentDate : todaysDate,
    timezone,
  };

  const [rowsData, setRowsData] = useState([]);
  const [studentsDataModified, setStudentsDataModified] = useState<boolean>(false);

  const { data: studentsTodaysAttendence, loading: fetchStudentsTodaysAttendenceLoading } =
    useQuery(dayWiseAttendence, {
      variables: {
        payload:
          attendenceType[0].value === 0
            ? {
              ...dayWiseAttendenceInput,
              slot: 'DAY',
              slotName: 'DAY',
              timezone,
            }
            : {
              ...dayWiseAttendenceInput,
              slot: selectedSlotInfo ? selectedSlotInfo[0].slotId : '',
              slotName: selectedSlotInfo ? selectedSlotInfo[0].label : '',
              timezone,
            },
      },
      fetchPolicy: 'network-only',
    });

  const { getStudentsList, studentListData, studentListLoading } = useGetStudentsList();

  const onClick = async (data: any) => {
    const newObj: { [key: string]: boolean } = { ...studentsAttendenceInfo };

    if (data.selectedValue == 'true') {
      if (!newObj[data.user]) {
        newObj[data.user] = true;
        setTotalStudentPresent((count: number) => count + 1);
      }
    } else {
      if (newObj[data.user]) {
        newObj[data.user] = false;
        setTotalStudentPresent((count: number) => count - 1);
      }
    }
    setStudentsAttendenceInfo(newObj);
  };

  useEffect(() => {
    // Disable mark attendance button initially
    setStudentsDataAvailable(false);
  }, [])

  useEffect(() => {
    if (batch?.value && batch?.class?.id && divisioninfo) {
      getStudentsList({ variables: createQueryParameter() });
      setStudentsDataModified(false);
    }
  }, [batch, divisioninfo, headCells]);

  function createQueryParameter() {
    const studentFilter = {
      batch: { eq: batch.value },
      class: {
        eq: batch.class.id,
      },
      division: {
        eq: divisioninfo,
      },
    } as any;

    const sortCriteria = createSortFilter(headCells);

    const param = {
      filters: studentFilter,
    };

    if (sortCriteria) {
      param['sort'] = sortCriteria;
    }

    return param;
  }

  useEffect(() => {
    if (!studentListLoading && studentListData) {
      if (studentListData.instituteStudents.data.length) {
        const obj: { [key: string]: boolean } = {};
        const arr = studentListData.instituteStudents.data.map((studentInfo: any) => {
          obj[studentInfo.id] = true;
          return {
            user: studentInfo.id,
            student: properCase(studentInfo?.fullName),
            id: studentInfo?.rollNo,
            preset: true,
          };
        });

        setRowsData(arr);
        setStudentsAttendenceInfo(obj);
        setStudentsDataModified(true);
        setTotalStudents(arr.length);
        setTotalStudentPresent(arr.length);
        setStudentsDataAvailable(true);
      } else {
        setStudentsAttendenceInfo({});
        setRowsData([]);
      }
    }
  }, [studentListLoading]);

  useEffect(() => {
    if (!fetchStudentsTodaysAttendenceLoading && studentsTodaysAttendence && studentsDataModified) {
      if (studentsAttendenceInfo && Object.keys(studentsAttendenceInfo).length > 0) {
        const obj: { [key: string]: boolean } = { ...studentsAttendenceInfo };
        let count = 0;
        if (studentsTodaysAttendence.dayWiseAttendance.length) {
          studentsTodaysAttendence.dayWiseAttendance.forEach((studentInfo: any) => {
            if (studentInfo.data[0].attendance === 'ABSENT') {
              obj[studentInfo.user.id] = false;
              count--;
            }
          });
        }
        setStudentsAttendenceInfo(obj);
        setTotalStudentPresent((prev: number) => prev + count);
      }
    }
  }, [fetchStudentsTodaysAttendenceLoading, studentsDataModified]);

  return (
    <>
      {studentListLoading ? (
        <CenterDiv>
          <LoaderSpinner />
        </CenterDiv>
      ) : (
        rowsData.length > 0 ?
          <div style={{ width: '100%', maxWidth: '800px' }}>
            <TableDiv>
              <div style={{ height: height - 425, overflowX: 'scroll' }}>
                <Attendancetable
                  data={rowsData}
                  onClick={onClick}
                  studentsAttendenceInfo={studentsAttendenceInfo}
                  headCells={headCells}
                  setHeadCells={setHeadCells}
                />
              </div>
            </TableDiv>
          </div> :
          <CenterDiv>
            <HeaderFive value={'No Records Present!'} />
          </CenterDiv>
      )
      }
    </>
  );
};

export default MarkAttendance;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`
