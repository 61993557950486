import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import HeaderThree from '../../Text/HeaderThree';
import ModalBaseAction from '../BaseAction';
import { useThemeSystem } from 'src/contexts/theme-context';
import { colors, fontWeight } from 'src/styles/theme/styles';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faBackpack, faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useParams, Switch, Route, useLocation, useNavigate } from 'src/routes/routing.web';
import { attachmentType } from 'src/components/organism/Assignments/types';
import { useQuery } from '@apollo/client';
import {
  getAssignmentsQuery,
  getAssignmentSubmissionsQuery,
} from 'src/graphql/communication/assignments';
import Assignment from 'src/components/organism/Assignments/Assignment/Assignment';
import StudentList from 'src/components/organism/Assignments/Student/StudentList';
import StudentDetails from 'src/components/organism/Assignments/Student/StudentDetails';
import AssignmentModalContent from 'src/components/organism/Assignments/Assignment/AssignModalContent.web';
import { useCreateAssignmentSubmissionMutation } from 'src/graphql/communication/assignments';
import { once } from 'src/components/services';
import { userInformation } from 'src/utils/manageState';
import { ASSIGNMENT_MODAL_PAGE, USER_TYPE, SUCCESS, ERROR } from 'src/constant';
import { usePersistStorage } from 'react-native-use-persist-storage';
import { KEYS } from 'src/constant/key';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useI18n } from 'src/i18n/hooks';
import { DT } from 'src/constant/dateTime';
import { NoData } from '../../NoData';
import styled from 'styled-components';
import LoaderSpinner from '../../LoaderSpinner/index.web';

export default function AssignmentModal(props: any) {
  const {
    modalVisible,
    setModalVisible,
    Headerpopup = '',
    maxWidth,
    isUrlBase = true,
    assignmentModalPage,
    propsStudentId,
  } = props;
  const { t } = useI18n();
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;
  const isStudent = userType === USER_TYPE.STUDENT;
  const { theme } = useThemeSystem();
  const { setAlertDetails } = useAlertSystem();
  const pathname = useLocation()?.pathname;
  const navigate = useNavigate();
  let assignmentId = '';
  const { type, studentId } = useParams();
  const params = useParams();
  assignmentId = isUrlBase ? params?.assignmentId : props?.assignment_Id;
  const lastSegment = pathname.split('/').pop();
  const [assignmentPage, setAssignmentPage] = useState(assignmentModalPage);
  let isFirstPage = false;
  if (isUrlBase) {
    isFirstPage = lastSegment === 'detail' && studentId === undefined;
  } else {
    isFirstPage = assignmentPage === ASSIGNMENT_MODAL_PAGE[0];
  }
  const assignmentFilter = { id: { eq: isUrlBase ? params?.assignmentId : props?.assignment_Id } };
  const { data: assignmentData, loading } = useQuery(getAssignmentsQuery, {
    variables: { filters: assignmentFilter, timezone: DT.TIMEZONE }
  });
  const [uploadingAttachment, setUploadingAttachment] = useState<attachmentType[]>([]);
  const assignmentDetails = assignmentData?.getAssignments?.data[0];
  const urlstart = isStudent ? `/assignments/:type` : `/assignments`;
  const [canClick, setCanClick] = useState<boolean>(true);
  const [assignmentIndex, setAssignmentIndex] = usePersistStorage<any>(KEYS.ASSIGNMENT_INDEX, null);
  const [assignmentSearch, setAssignmentSearch] = usePersistStorage<any>(
    KEYS.ASSIGNMENT_SEARCH,
    null,
  );
  const assignmentDataPresent = Boolean(assignmentDetails && Object.keys(assignmentDetails)?.length > 0);
  const { createAssignmentSubmission } = useCreateAssignmentSubmissionMutation(
    assignmentIndex,
    assignmentSearch,
  );

  const [previousPage, setPreviousPage] = useState(ASSIGNMENT_MODAL_PAGE[0]);
  const [student_Id, setStudentId] = useState(propsStudentId);

  useEffect(() => {
    if (assignmentModalPage) setAssignmentPage(assignmentModalPage);
  }, [assignmentModalPage]);

  useEffect(() => {
    if (propsStudentId) setStudentId(propsStudentId);
  }, [propsStudentId]);

  function closeModal() {
    setAssignmentIndex(null);
    if (isUrlBase) {
      if (!isStudent) {
        navigate(`/assignments`);
      } else {
        // if submitted:: true is passed as a state, it will be used as a identifier for triggering 
        // a refetch of pending and completed assignments list
        navigate(`/assignments/${type}`, { state: { submitted: true } })
      }
    } else {
      setModalVisible();
      setAssignmentPage(ASSIGNMENT_MODAL_PAGE[0]);
    }
    setUploadingAttachment([])
  }

  async function submitAssignment() {
    if (uploadingAttachment?.length > 0) {
      setCanClick(false);
      const attachmentToUpload = uploadingAttachment.filter((attach) => {
        return attach.hasOwnProperty('document');
      });
      const payload = {
        assignment: assignmentId,
        attachments: attachmentToUpload,
      };

      const submissionResponse = await createAssignmentSubmission({
        variables: { payload: payload },
        refetchQueries: [
          {
            query: getAssignmentSubmissionsQuery,
            variables: { assignmentId: assignmentId },
          },
        ],
      });
      if (submissionResponse?.data?.submitAssignment) {
        setCanClick(true);
        closeModal();
        setAlertDetails({ message: `${t("submissionSuccess.text")}`, level: SUCCESS });
      } else {
        setAlertDetails({ message: `${t("submissionError.text")}`, level: ERROR });
      }
    } else {
      setCanClick(true);
    }
  }

  const goBack = () => {
    canOnlyFireOnce();
  };

  // Usage
  var canOnlyFireOnce = once(function () {
    if (isUrlBase) {
      navigate(-1); // goBack
    } else {
      if (assignmentPage === ASSIGNMENT_MODAL_PAGE[2]) {
        setAssignmentPage(previousPage);
      } else {
        setAssignmentPage(ASSIGNMENT_MODAL_PAGE[0]);
      }
    }
  }, []);

  return (
    <View>
      <StyledDialog
        onClose={closeModal}
        open={modalVisible}
        style={{ borderRadius: 16 }}
        maxWidth={maxWidth}>
        {Headerpopup !== '' ? (
          <StyledDialogTitle>
            {!isFirstPage && (
              <TouchableOpacity
                style={{ marginRight: 12, cursor: 'pointer', display: 'inline' }}
                onPress={goBack}>
                <FontAwesomeIcon icon={faLongArrowLeft} size={16} color={colors.secondaryText} />
              </TouchableOpacity>
            )}
            <HeaderThree
              value={Headerpopup}
              color={theme?.dialog?.titleColor}
              fontWeight={fontWeight[600]}
              lineHeight={32}
            />
          </StyledDialogTitle>
        ) : null}

        {assignmentDataPresent ? (
          isUrlBase ? (
            <DialogContent sx={{
              borderBottom: `1px solid ${colors.lighterBorder}`,
              padding: '0px 32px 16px 32px !important',
            }}>
              {!loading && (
                <Switch>
                  <Route exact path={`${urlstart}/:assignmentId/detail`}>
                    <Assignment
                      assignment={assignmentDetails}
                      setAssignmentPage={setAssignmentPage}
                      isSubmitted={assignmentDetails?.submissions === 1}
                      setNewAttachment={setUploadingAttachment}
                      newAttachment={uploadingAttachment}
                    />
                  </Route>
                  <Route path={`${urlstart}/:assignmentId/student-list`}>
                    <StudentList
                      assignmentID={assignmentDetails?.id}
                      assignedStudentList={assignmentDetails?.assigneeList || []}
                      setDetails={() => { }}
                    />
                  </Route>
                  <Route path={`${urlstart}/:assignmentId/:studentId/detail`}>
                    <StudentDetails studentDetail={{}} setGrade={null} />
                  </Route>
                  <Route path={`${urlstart}/:assignmentId/discussion`}>
                    <AssignmentModalContent assignmentId={assignmentDetails?.id} />
                  </Route>
                </Switch>
              )}
            </DialogContent>
          ) : (
            <DialogContent sx={{
              borderBottom: `1px solid ${colors.lighterBorder}`,
              padding: '0px 32px 16px 32px !important',
              marginTop: '16px',
            }}>
              {!loading && (
                <>
                  {assignmentPage === ASSIGNMENT_MODAL_PAGE[0] && (
                    <Assignment
                      assignment={assignmentDetails}
                      setPreviousPage={setPreviousPage}
                      setAssignmentPage={setAssignmentPage}
                      isUrlBase={isUrlBase}
                      isSubmitted={assignmentDetails?.submissions === 1}
                      setStudentId={setStudentId}
                      setNewAttachment={setUploadingAttachment}
                      newAttachment={uploadingAttachment}
                    />
                  )}

                  {assignmentPage === ASSIGNMENT_MODAL_PAGE[1] && (
                    <StudentList
                      isUrlBase={isUrlBase}
                      setPreviousPage={setPreviousPage}
                      assignmentID={assignmentDetails?.id}
                      assignedStudentList={assignmentDetails?.assigneeList || []}
                      setStudentId={setStudentId}
                      setAssignmentPage={setAssignmentPage}
                      setDetails={() => { }}
                    />
                  )}

                  {assignmentPage === ASSIGNMENT_MODAL_PAGE[2] && (
                    <StudentDetails
                      isUrlBase={isUrlBase}
                      assignment_Id={assignmentDetails?.id}
                      studentID={student_Id}
                    />
                  )}

                  {assignmentPage === ASSIGNMENT_MODAL_PAGE[3] && (
                    <AssignmentModalContent assignmentId={assignmentDetails?.id} />
                  )}
                </>
              )}
            </DialogContent>
          )
        ) : (
          <NoDataContainer>
            {loading ? (
              <LoaderSpinner />
            ) : (
              <NoData label={t('assignmentNotAvailable.text')} icon={faBackpack} />
            )}
          </NoDataContainer>
        )}

        <DialogActions style={{ padding: 0 }}>
          <ModalBaseAction
            disabledSave={!canClick}
            handleCancel={closeModal}
            cancelButtonLabel={
              isFirstPage && isStudent
                ? 'cancel.label'
                : 'done.label'
            }
            addEditButtonLabel={
              isFirstPage && isStudent
                ? 'submit.label'
                : 'cancel.label'
            }
            handleSave={submitAssignment}
            hideSubmit={isStudent && isFirstPage && assignmentDataPresent}
          />
        </DialogActions>
      </StyledDialog>
    </View>
  );
}

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledDialogTitle = styled(DialogTitle)`
  padding-left: 32px;
  padding-top: 24px;
  padding-bottom: 0;
`;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paperWidthSm {
      width: 852px;
      height: 740px;
      max-width: 852px;
      border-radius: 16px;
    }
  }
`;