import { TFormMode, TFormState } from "src/types";

import { DefaultUserSelection } from "src/constant";
import { FieldValues } from "react-hook-form";

const documentsHeadCells = [
  {
    id: "document_name",
    label: "documentName.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "document_alias",
    label: "documentAlias.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "action",
    label: "action.label",
    align: "right",
    disableSorting: true,
  },
];

const transformInitialFormData = (formData: documentsListing) => ({
  ...formData,
  category: { label: formData.category, value: formData.category },
  defaultUserSelection:
    formData?.defaultUserSelection === DefaultUserSelection.ALLOWED,
});

const generatePayload = (data: FieldValues, id?: string) => {
  const { name, alias, category, defaultUserSelection, defaultUserSelectionRequired } = data;
  const payload = {
    name,
    alias,
    category: category.value,
    defaultUserSelection: defaultUserSelection
      ? DefaultUserSelection.ALLOWED
      : DefaultUserSelection.NOT_ALLOWED,
    ...(id ? { id } : {}),
    defaultUserSelectionRequired,
  };
  return payload;
};

export const AdminDocumentsHelpers = {
  documentsHeadCells,
  transformInitialFormData,
  generatePayload,
};

export type documentsListing = {
  id: string;
  name: string;
  alias: string;
  category: string;
  defaultUserSelection: string;
  defaultUserSelectionRequired: boolean;
};

export type documentsFormState = {
  state: TFormState;
  mode: TFormMode;
  data?: documentsListing;
};

export type documentsListingServerResponse = {
  data: documentsListing[];
  totalCount: number;
};
