import AbstractFlashList from "src/components/atoms/FlashList";
import CardTitleBlock from "../CardTitleBlock";
import React from "react";
import { ReportsItem } from "./ReportsItem";
import { DemographicsScrollContainer as ScrollContainer } from "src/components/organism/Dashboard/Common/CommonHelpers";
import ShadowBox from "src/components/atoms/ShadowBox";
import { useI18n } from "src/i18n/hooks";

type Report = {
  id: number;
  name: string;
};

const reportsList: Report[] = [
  {
    id: 1,
    name: "Gender & Caste Report",
  },
  {
    id: 2,
    name: "Attendance Report",
  },
  {
    id: 3,
    name: "Usage Report",
  },
  {
    id: 4,
    name: "User Report",
  },
  {
    id: 5,
    name: "Admission Cancellation Report",
  },
  {
    id: 6,
    name: "Disabled Students Report",
  },
];

export const ReportsList = () => {
  const { t } = useI18n();

  return (
    <>
      <CardTitleBlock titleText={t("reports.label")} />
      <ShadowBox>
        <ScrollContainer>
          <AbstractFlashList<Report> estimatedItemSize={67} data={reportsList}>
            {({ item, index }) => (
              <ReportsItem
                name={item.name}
                index={index}
                length={reportsList.length}
              />
            )}
          </AbstractFlashList>
        </ScrollContainer>
      </ShadowBox>
    </>
  );
};
