/* eslint-disable react-native/no-unused-styles */
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import MainHeaderTitle from 'src/components/atoms/Text/MainHeaderTitle';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { useTheme } from 'styled-components';
import { colors, fontWeight } from 'src/styles/theme/styles';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHistory } from 'src/routes/routing';

export function Breadcrumb({ heading }: any) {
  const headingLen = heading.length;
  const { rem }: any = useTheme();
  const styles = BreadcrumbStyles(rem);
  return (
    <View style={[styles.breadCrumb]}>
      {heading &&
        heading.map((item: any, i: number) => {
          const hasNext = i < headingLen - 1;
          const isLast = i === headingLen - 1;
          return (
            <Fragment key={item.text}>
              <BreadcrumbItem rem={rem} isLast={isLast} key={item.text} item={item} />
              {hasNext && <NextIcon rem={rem} />}
            </Fragment>
          );
        })}
    </View>
  );
}

function BreadcrumbItem({ item, isLast, rem }: any) {
  const history = useHistory();
  const { theme } = useThemeSystem();

  const { url, text } = item;
  const Container = isLast ? MainHeaderTitle : HeaderFive;
  const Wrapper = url ? TouchableOpacity : Fragment;
  const textColor = url ? colors.primaryColor : theme?.header?.titleColor;

  return (
    <Wrapper onPress={() => history.push(url)}>
      <Container
        value={text || '-'}
        color={textColor}
        fontWeight={fontWeight['600']}
        lineHeight={rem(3.2)}
      />
    </Wrapper>
  );
}

function NextIcon({ rem }: any) {
  const styles = BreadcrumbStyles(rem);
  return (
    <View style={[styles.iconView]}>
      <FontAwesomeIcon icon={faChevronRight} color={colors.secondaryText} height={10} width={6} />
    </View>
  );
}

const BreadcrumbStyles = (rem: any) =>
  StyleSheet.create({
    breadCrumb: {
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      marginLeft: rem(0.5),
    },
    iconView: {
      marginHorizontal: rem(1.2),
      alignSelf: 'center',
    },
  });
