import { FieldValues, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  getAdmissionLinkByRequestIdQuery,
  inviteDetailQuery,
} from "src/graphql/admission";
// @ts-ignore
import { useNavigate, useNavigation } from "src/routes/routing";

import { APPLICANT_TYPE_ENUM } from "src/constant";
// @ts-ignore
import Element from "src/components/molecules/Forms/ApplicationElement";
// @ts-ignore
import NormalModal from "src/components/atoms/Modals/Normal/index";
import formJSON from "src/form-json/application/add-application-form.json";
import { graphqlQuery } from "src/graphql/util";
import { isWeb } from "src/constant/device";
import navigationStrings from "src/navigation/navigationStrings";
import styled from "styled-components/native";
import { useI18n } from "src/i18n/hooks";

type Props = {
  onError: (message: string) => void;
  onClose: () => void;
  errorMessage?: any;
};

export default function AddApplicationForm(props: Props) {
  const { onError, onClose, errorMessage } = props;
  const { t } = useI18n();
  // Cross-platform router
  const router = isWeb ? useNavigate() : useNavigation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [elements, setElements] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { fields } = elements ?? {};

  useEffect(() => {
    setElements({ ...formJSON[0] });
  }, []);

  const onSubmitFn = async (data: FieldValues) => {
    if (data) {
      setLoading(true);
      try {
        const code = data.code;
        if (code.startsWith("INV")) {
          const response = await graphqlQuery(
            inviteDetailQuery,
            { inviteCode: code },
            false
          );
          if (response?.data?.inviteDetail) {
            /*if (
              response.data.inviteDetail?.applicationType ===
              APPLICANT_TYPE_ENUM[0]
            ) {
              throw new Error(t("error.inviteCodeUsed.text"));
            } else {
              isWeb
                ? router(`/application/invite/${code}`)
                : router.navigate(navigationStrings.APPLICATION_FORM_VIEWER, {
                  inviteCode: code,
                });
              setLoading(false);
              onClose();
            }*/
            isWeb
              ? router(`/application/invite/${code}`)
              : router.navigate(navigationStrings.APPLICATION_FORM_VIEWER, {
                inviteCode: code,
              });
            setLoading(false);
            onClose();
          } else {
            throw new Error(response);
          }
        } else {
          const response = await graphqlQuery(
            getAdmissionLinkByRequestIdQuery,
            { requestId: code },
            false
          );
          if (response?.data?.admissionLink) {
            const data = response?.data?.admissionLink;
            isWeb
              ? router(`/application/add/${data?.prefix}/${code}`)
              : router.navigate(navigationStrings.APPLICATION_FORM_VIEWER, {
                prefix: data?.prefix,
                requestId: code,
              });
            setLoading(false);
            onClose();
          } else {
            throw new Error(response);
          }
        }
        setLoading(false);
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      modalVisible={true}
      Headerpopup={t("add-application.label")}
      addEditButtonLabel={"submit.label"}
      handleSave={handleSubmit(onSubmitFn)}
      width={600}
      modalHeight={600} // native only
      message={errorMessage} // native only
    >
      <Container>
        {fields &&
          fields.map((field: any) => (
            // @ts-ignore
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
            />
          ))}
      </Container>
    </NormalModal>
  );
}

const Container = styled.View`
  height: 100px;
`;
