import React, { useEffect, useState } from 'react';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import styled from 'styled-components';
import Input from 'src/components/atoms/Input/input.web';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { colors } from 'src/styles/theme/styles';
import SearchDropdown from 'src/components/atoms/AutoComplete/index.web';
import {
  HandleKeyDown,
  TatktChipData,
  Toptions,
} from 'src/components/molecules/AtktRevaIinput/helpers';
import { useI18n } from 'src/i18n/hooks';
import AtktTable from 'src/components/molecules/AtktRevaIinput/Table/AtktTable.web';
type TatktInputProps = {
  initialValues: any;
  handleAddChange: (newValue: any) => void;
  serviceOptions: Toptions[];
  merchantOptions: Toptions[];
  allowDecimalInput?: boolean;
};

const ATKTInput = ({
  initialValues,
  handleAddChange,
  serviceOptions,
  merchantOptions,
  allowDecimalInput = false,
}: TatktInputProps) => {
  const { t } = useI18n();
  const [AtktChips, setATKTChips] = useState<TatktChipData[]>([]);
  const [fee, setFee] = useState<any>();
  const [maxSubject, setMaxSubject] = useState<any>();
  const [minSubjects, setMinSubjects] = useState<any>();
  const [selectedServiceValue, setSelectedServiceValue] = useState<Toptions | null>(null);
  const [selectedMerchantValue, setSelectedMerchantValue] = useState<Toptions | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [applicationFees, setApplicationFees] = useState<number>(0);

  const handleServiceChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    setSelectedServiceValue(newValue);
  };

  const handleMerchantchange = (event: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    setSelectedMerchantValue(newValue);
  };

  function removeChip(indexToRemove: number) {
    setATKTChips(prevChips => {
      const updatedChips = prevChips.filter((_, index) => index !== indexToRemove);
      handleAddChange({
        ...initialValues,
        subjectWiseFees: updatedChips,
      });
      return updatedChips;
    });
  }

  useEffect(() => {
    if (initialValues) {
      const paymentProviderID = initialValues.paymentProvider;
      const selectedService = serviceOptions.find(option => option.value === paymentProviderID);
      setSelectedMerchantValue(initialValues.merchantId);
      setSelectedServiceValue(selectedService);
      setATKTChips(initialValues?.subjectWiseFees);
    }
  }, [initialValues, merchantOptions, serviceOptions]);

  function handleAdd() {
    if (fee && minSubjects && maxSubject) {
      const subjectWiseFees = {
        min: parseInt(minSubjects),
        feeAmount: parseFloat(fee),
        max: parseInt(maxSubject),
        applicationFee: applicationFees,
      };
      if (minSubjects && maxSubject) {
        const lastChipMax = AtktChips.length > 0 ? AtktChips[AtktChips.length - 1].max || 0 : 0;
        if (lastChipMax >= subjectWiseFees.min) {
          setError('Please enter a minimum subject greater than the previous maximum subject');
          return;
        }
        if (subjectWiseFees.min > subjectWiseFees.max) {
          setError('Please enter a maximum subject greater than or equal to the minimum subject');
          return;
        }
      }
      setATKTChips(prevChips => [...prevChips, subjectWiseFees]);
      handleAddChange({
        merchantId: selectedMerchantValue,
        paymentProvider: selectedServiceValue?.value,
        subjectWiseFees: [...AtktChips, subjectWiseFees],
      });
      clearFormValues();
    }
  }

  useEffect(() => {
    setIsButtonDisabled(!(minSubjects && fee && selectedServiceValue && selectedMerchantValue));
  }, [setMinSubjects, fee, selectedServiceValue, selectedMerchantValue]);

  function clearFormValues() {
    setFee('');
    setMaxSubject('');
    setMinSubjects('');
    setApplicationFees(0);
    setError('');
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    HandleKeyDown(event, allowDecimalInput);
  };

  return (
    <>
      <Container>
        <ElementWrapper>
          <DroopdownWrapper>
            <SearchDropdown
              header={t('atktRevalServiceProvider.required.label')}
              width="250px"
              options={serviceOptions}
              value={selectedServiceValue}
              onChange={handleServiceChange}
            />
            <SearchDropdown
              header={t('atktRevalMerchant.required.label')}
              width="250px"
              options={merchantOptions}
              value={selectedMerchantValue}
              onChange={handleMerchantchange}
            />
          </DroopdownWrapper>
          <FieldWrapper>
            <Input
              typeInput={'number'}
              header={t('minSubjects.required.label')}
              value={minSubjects}
              width={'250px'}
              setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMinSubjects(parseInt(e.target.value));
              }}
              onkeyDown={handleKeyPress}
            />
            <Input
              typeInput={'number'}
              header={t('maxSubjects.required.label')}
              value={maxSubject}
              width={'250px'}
              setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                setMaxSubject(parseInt(e.target.value));
              }}
              onkeyDown={handleKeyPress}
            />
            <Input
              typeInput={'number'}
              header={t('atktAmount.required.label')}
              value={fee}
              width={'250px'}
              setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFee(parseInt(e.target.value));
              }}
              onkeyDown={handleKeyPress}
            />
            <Input
              typeInput={'number'}
              header={t('atktRevalApplicationFees.required.label')}
              value={applicationFees}
              width={'250px'}
              setValue={(e: React.ChangeEvent<HTMLInputElement>) =>
                setApplicationFees(parseFloat(e.target.value))
              }
              onkeyDown={handleKeyPress}
              disablePaste={true}
            />
          </FieldWrapper>
        </ElementWrapper>
        <ButtonWrapper>
          <SecondaryBtn
            onPress={handleAdd}
            label={t('atktRevalAddAmount.text')}
            customDisabled={isButtonDisabled}
          />
        </ButtonWrapper>
      </Container>
      <ChipsWrapper>
        {AtktChips.length > 0 && <AtktTable AtktData={AtktChips} handleRemove={removeChip} />}
      </ChipsWrapper>
      {error && <ErrorMsg message={error} />}
    </>
  );
};

export default ATKTInput;

const ErrorMsg = ({ message }: { message: string }) => (
  <ErrorWrapper>
    <SmallTextAtom value={message} color={colors.errorColor} />
  </ErrorWrapper>
);

const ErrorWrapper = styled.div`
  margin-top: 4px;
`;

const Container = styled.div`
  margin-top: 24px;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DroopdownWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const FieldWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  flex-wrap: wrap;
`;

const ChipsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ChipFieldWrapper = styled.div`
  margin-bottom: 8px;
  margin-right: 8px;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  width: 117px;
`;
