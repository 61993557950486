import React from 'react'
import styled from 'styled-components';
import { colors } from 'src/styles/theme/styles';
import { useHistory } from 'src/routes/routing';
import { useI18n } from 'src/i18n/hooks';
import ButtonWithBorderBottom from 'src/components/atoms/Button/ButtonWithBottomBorder.web';
import { ViewMode } from '../Forms/types';
import { IUserDetailForm } from 'src/form-json/user-details';

interface ProfileTabsProps {
  step: number;
  mode: ViewMode;
  onboardingSchema: IUserDetailForm[];
  onChangeTab?: (i: number, tabName: string) => void
  showIndicatorBadgeOnTab: (tabIndex: number) => boolean;
}
export default function ProfileTabs(props: ProfileTabsProps) {

  let history = useHistory();
  const { t } = useI18n();
  const {
    step,
    mode,
    onboardingSchema,
    onChangeTab,
    showIndicatorBadgeOnTab
  } = props;

  const onPress = (data, i: number) => {
    if (onChangeTab) {
      onChangeTab(i, data.name);
    } else {
      let path = 'view';
      if (mode == ViewMode.EDIT) path = 'edit';
      history.push(`/profile/${i}/${path}`);
    }
  }

  return (
    <FormTabs>
      {onboardingSchema.map((data, i: number) => {
        return (
          <ButtonWithBorderBottom
            onPress={() => onPress(data, i)}
            label={t(data?.schema.headerData?.title)}
            color={i === step ? colors.primary : colors.secondaryText}
            borderColor={i === step ? colors.primary : colors.white}
            showIndicatorBadge={showIndicatorBadgeOnTab(i)}
          />
        );
      })}
    </FormTabs>
  )
}

const FormTabs = styled.div`
  border-bottom: 1px solid ${colors.lighterBorder};
  margin: -8px 20px 16px 20px;
  display: flex;
  flex-wrap: wrap;
  background-color: ${colors.white};
`;