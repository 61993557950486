import React, { useState } from 'react';
import styled from 'styled-components/native';

import { colors } from 'src/styles/theme/styles';
import Chip from 'src/components/atoms/Chip';

interface IProps {
  setFilterValue: (value: any) => void;
}

const ArrayInput = ({ setFilterValue }: IProps) => {
  const [value, setValue] = useState('');
  const [values, setValues] = useState<string[]>([]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (value.length > 0) {
        const newValues = [...values, value];
        setValues(newValues);
        setFilterValue(newValues);
        setValue('');
      }
    }
  };

  const removeValue = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  return (
    <Container>
      <label
        style={{
          border: `2px solid ${colors.primaryColor}`,
          borderRadius: '3px',
          minHeight: '27px',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          maxWidth: '212px',
          padding: '3px',
          alignItems: 'center',
        }}>
        {values.map((item, index) => (
          <ChipView>
            <Chip
              label={item}
              onPress={() => removeValue(index)}
              borderRadius={4}
              chipFontSize={1.2}
              height={20}
              width={'auto'}
            />
          </ChipView>
        ))}
        <input
          type={'string'}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoFocus
          style={{
            appearance: 'none',
            border: 'none',
            height: '27px',
            outline: 'none',
            width: '100%',
          }}
          onKeyDown={handleKeyDown}
        />
      </label>
    </Container>
  );
};

export default ArrayInput;

const Container = styled.View`
 margin-right: 20px;
`;

const ChipView = styled.View`
  padding: 2px;
`;
