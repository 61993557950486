import { gql } from '@apollo/client';

const getScheduleByDay = gql`
  query (
    $filters: ScheduleFilterInput
    $input: GetSchedule
    $limit: Int
    $skip: Int
    $sort: PInstituteEmployeeScheduleSortInput
  ) {
    getFullScheduleByDay(
      limit: $limit
      input: $input
      skip: $skip
      filters: $filters
      sort: $sort
    ) {
      totalCount
      schedule {
        validFrom
        validTill
        detail {
          description
        }
        id
      }
      data {
        date
        schedules {
          id
          day
          start
          end
          startTime
          endTime
          division
          detail {
            room
            description
          }
          subject {
            id
            name
            alias
            color
          }
          batch {
            id
            name
            alias
          }
          class {
            id
            name
            alias
          }
          lecturer {
            id
            firstName
            lastName
            personalDetails {
              fullName
            }
          }
          timeZone
          timezoneName
        }
      }
    }
  }
`;

export { getScheduleByDay };
