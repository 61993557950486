import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { limit } from '../../constant';
import { readCacheQuery, writeCacheQuery } from '../util';
import { createDeleteData } from '../../components/services/index';

const addPublicInstituteMutation = gql`
    mutation ($payload: InstituteInput!) {
        publicCreateInstitute(payload: $payload) {
        id
        name
     }
}
`;

export function useAddPublicInstitute() {
    const [createPublicInstitute, { data: instituteData}] = useMutation(addPublicInstituteMutation);
    return { createPublicInstitute, instituteData };
}


