import { decodeToken, isAuthenticated } from "src/graphql/common";
import {
  getAccountsFromStorage,
  setAccountsToStorage,
  setOnboardingVisited,
  setRefreshTokenToStorage,
  setTokenToStorage,
  setUserTypeoStorage,
} from "src/components/services/storage-service";
import { isIOS, isNative, isWeb, width } from "src/constant/device";
import styled, { css } from "styled-components/native";

import HeaderThree from "src/components/atoms/Text/HeaderThree";
import { Icon } from "src/components/atoms/Icon/Icon";
import NormalTextAtom from "src/components/atoms/Text/NormalTextAtom";
import React from "react";
import { StyleSheet } from "react-native";
import { authToken } from "src/utils/manageState";
import { colors, fontWeight } from "src/styles/theme/styles";
import { getApplicationPath, properCase } from "src/utils/utility";
import { USER_TYPE } from "src/constant";

export type AccountsDataType = {
  token: string;
  refresh: string;
};

export interface AccountObject {
  fullName: string;
  userId: string;
  userType: number;
  accessToken: string;
  refreshToken: string;
  profileCompletion: boolean;
  profilePicture: string;
  email: string;
  isProfileIncomplete: boolean;
  instituteName?: string;
  employeeId?: string;
  className?: string;
  division?: string;
  rollNo?: string;
}

export function incompleteProfile(profileCompletion: Object): boolean {
  return (
    !profileCompletion ||
    Object.values(profileCompletion).some((value) => value === false)
  );
}

export function createAccountDetailsObj(
  account: AccountsDataType,
  decodedDetails: any
): AccountObject {
  return {
    fullName: properCase(decodedDetails?.userDetails?.fullName),
    userId: decodedDetails?.userId,
    userType: decodedDetails?.userType,
    accessToken: account.token,
    refreshToken: account.refresh,
    profileCompletion: decodedDetails?.userDetails?.profileCompletion,
    profilePicture: decodedDetails?.userDetails?.photo,
    email: decodedDetails?.email,
    isProfileIncomplete: incompleteProfile(
      decodedDetails?.userDetails?.profileCompletion
    ),
    instituteName: decodedDetails?.userDetails?.instituteName,
    employeeId: decodedDetails?.userDetails?.employeeId,
    className: decodedDetails?.userDetails?.class,
    division: decodedDetails?.userDetails?.division,
    rollNo: decodedDetails?.userDetails?.rollNo,
  };
}

export async function getAllAccountsFromStorage(): Promise<AccountObject[]> {
  const accountsData = await getAccountsFromStorage();
  if (accountsData) {
    const accData = JSON.parse(accountsData);
    const accountCards: AccountObject[] = [];
    accData.map((account: AccountsDataType) => {
      const decodedDetails = decodeToken(account.token);
      accountCards.push(createAccountDetailsObj(account, decodedDetails));
    });
    return accountCards;
  }
  return [];
}

export async function configureAccountStorageAndNavigate(
  selectedAccount: AccountObject,
  history: any,
  authCallback?: any,
  userType?: number,
  prefix?: string,
  requestId?: string,
  capId?: string,
  inviteCode?: string,
) {
  authToken({ edbaToken: selectedAccount.accessToken });
  await setTokenToStorage(selectedAccount.accessToken);
  await setRefreshTokenToStorage(selectedAccount.refreshToken);
  await setUserTypeoStorage(selectedAccount.userType);
  await isAuthenticated();
  const isProfileIncomplete: boolean = incompleteProfile(
    selectedAccount.profileCompletion
  );
  await setOnboardingVisited(!isProfileIncomplete || false);
  if (isWeb) {
    let path = isProfileIncomplete ? '/onboarding/0' : '/dashboard';
    history.push(
      userType === USER_TYPE.APPLICANT
        ? getApplicationPath(path, prefix, requestId, capId, inviteCode)
        : path, { action: 'profile-switch' }
    );
  } else {
    const authenticated = await isAuthenticated();
    if (authCallback) {
      authCallback(authenticated);
    }
  }
}

export async function removeLoggedOutAccounts(emailToLogout: string) {
  try {
    let accountsToRetain: AccountsDataType[] = []
    await getAllAccountsFromStorage().then((storedAcc: AccountObject[]) => {
      if (storedAcc && storedAcc?.length > 0) {
        accountsToRetain = storedAcc.filter((acc: AccountObject) => acc.email !== emailToLogout).map((accToRetain: AccountObject): AccountsDataType => {
          return {
            token: accToRetain.accessToken,
            refresh: accToRetain.refreshToken
          }
        })
      }
    }).catch((err) => {
      console.log(err, 'unable to fetch tokens');
    });
    await setAccountsToStorage([...accountsToRetain]);
    return true;
  } catch (err) {
    console.log('unable to remove account', err);
    return false;
  }
}

// Common Components

export const LoginComponentsStyles = StyleSheet.create({
  marginTop30: { marginTop: 30 },
  keypad: { height: "90%", flex: 1, backgroundColor: colors.white },
  nativeLoginButton: { height: 48, borderRadius: 32, marginTop: 24 },
  webLoginButton: { marginTop: 32, paddingVertical: 8, height: 36 },
  textAlignCenter: { textAlign: "center" },
});

export const LoginStyledComponents = {} as any;

LoginStyledComponents.Container = styled.View`
  flex: 1;
  align-items: center;
  background-color: ${colors.white};
`;

LoginStyledComponents.LoginView = styled.View<{
  ptop: number;
  pbottom: number;
}>`
  background-color: ${colors.white};
  border-radius: 3px;
  padding-top: ${() => (isWeb ? "60px" : "42px")};
  padding-bottom: ${() => (isWeb ? "102px" : isIOS ? 47 : 24)};
  padding-left: 85;
  padding-right: 85;
  ${() =>
    isWeb &&
    css`
      box-shadow: 0px 4px 25px ${colors.borderGrey};
    `}
`;

LoginStyledComponents.CenterView = styled.View`
  justify-content: center;
  align-items: center;
`;

LoginStyledComponents.CenteredTextView = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: ${isWeb ? "4px" : "0px"};
  margin-bottom: ${isWeb ? "0px" : "4px"};
`;

LoginStyledComponents.PageAreaContainer = styled.View`
  justify-content: center;
  flex-grow: 1;
  border-color: #666666;
  width: 520px;
  height: 578px;
  background-color: ${isIOS ? "#ffffff" : "transparent"};
`;


LoginStyledComponents.LinkView = styled.TouchableOpacity``;

LoginStyledComponents.NativeEDBALogo = styled.Image`
  height: 40px;
  width: 112px;
`;

LoginStyledComponents.BackgroundImage = styled.Image`
  height: 180px;
  width: ${width > 400 ? 450 : "120%"};
  margin-top: 5%;
  margin-left: ${width > 400 ? "-10%" : "-10%"};
`;

LoginStyledComponents.RegisterView = styled.View`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-self: center;
`;

LoginStyledComponents.RegisterLink = styled.TouchableOpacity`
  padding-left: 2px;
`;

LoginStyledComponents.ApplicantSignUpWrapper = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-self: center;
  position: absolute;
  bottom: 32px;
`;

LoginStyledComponents.ElemWrapper = styled.View``;

LoginStyledComponents.FormWrapper = styled.View`
  margin-top: 12px;
`;

LoginStyledComponents.ApplicantSignupWrapper = styled.TouchableOpacity`
  margin-top: ${isWeb ? "16px" : "40px"};
  align-self: center;
`;

LoginStyledComponents.ForgotPasswordWrapper = styled.TouchableOpacity`
  margin-top: -16px;
  align-self: flex-end;
`;

export const LoginScreenEdbaLogo = () => {
  return (
    <LoginStyledComponents.CenterView style={isWeb && { marginTop: 20 }}>
      {isNative ? (
        <LoginStyledComponents.NativeEDBALogo
          source={require("src/assets/logo/new-logo-4x.png")}
        />
      ) : (
        <Icon name={"edba-logo"} size={56} />
      )}
    </LoginStyledComponents.CenterView>
  );
};
export const LoginScreenBackgroundNative = () => {
  return (
    <LoginStyledComponents.BackgroundImage
      source={require("src/assets/Initial-login-screen-hero-4x.png")}
      resizeMode="contain"
    />
  );
};

export const LoginScreenHeaderTitles = ({
  signInDescription = "",
  signInDescription2 = "",
  headerTitle = "Hello User!",
}: {
  signInDescription: string;
  signInDescription2?: string;
  headerTitle?: string;
}) => {
  return (
    <LoginStyledComponents.CenterView>
      <LoginStyledComponents.CenterView
        style={[
          isWeb && LoginComponentsStyles.marginTop30,
          { marginBottom: isIOS ? 4 : 0 },
        ]}
      >
        <HeaderThree
          value={headerTitle}
          lineHeight={isWeb ? 32 : 36}
          fontWeight={isWeb ? 'normal' : isIOS ? fontWeight["600"] : fontWeight['bold']}
        />
      </LoginStyledComponents.CenterView>

      <LoginStyledComponents.CenterView>
        <LoginStyledComponents.CenteredTextView>
          <NormalTextAtom
            style={LoginComponentsStyles.textAlignCenter}
            lineHeight={1.9}
            value={signInDescription}
          />
          {!!signInDescription2 && (
            <NormalTextAtom
              style={LoginComponentsStyles.textAlignCenter}
              lineHeight={1.9}
              value={signInDescription2}
            />
          )}
        </LoginStyledComponents.CenteredTextView>
      </LoginStyledComponents.CenterView>
    </LoginStyledComponents.CenterView>
  );
};
