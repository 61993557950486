import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ApplicantSignup, InstituteUserLogin, MultiAccount, RedirectionScreen, RequestResetPassword, ResetPassword, UserLogin } from './index';
import navigationStrings from 'src/navigation/navigationStrings';
import { USER_TYPE, USER_TYPE_ENUM } from 'src/constant';
// import { AuthLanding } from 'src/components/organism/AuthLanding.native';
import UnsupportedResolution from 'src/components/molecules/UnsupportedResolution';
import { NativePrivateRoute } from 'src/routes/native-private-route';

const Stack = createStackNavigator();

function AuthNavigator(prop: any) {
  return (
    <Stack.Navigator initialRouteName={navigationStrings.NATIVE_AUTH}>
      <Stack.Screen
        name={navigationStrings.USER_LOGIN}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'light-content' }}>
        {(props) => (
          <UserLogin
            authCallback={prop.authCallback}
            {...props}
          />
        )}
      </Stack.Screen>
      {/* === Legacy Login === It should be refactored or removed in the future. {{START}} */}
      <Stack.Screen
        name={navigationStrings.EMPLOYEE_LOGIN}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}>
        {(props) => (
          <InstituteUserLogin
            userType={USER_TYPE_ENUM[1]}
            authCallback={prop.authCallback}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.STUDENT_LOGIN}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}>
        {(props) => (
          <InstituteUserLogin
            userType={USER_TYPE_ENUM[2]}
            authCallback={prop.authCallback}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.APPLICANT_LOGIN}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}>
        {(props) => (
          <InstituteUserLogin
            userType={USER_TYPE_ENUM[3]}
            authCallback={prop.authCallback}
            {...props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.REQUEST_RESET_PASSWORD}
        component={RequestResetPassword}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.RESET_PASSWORD_EMPLOYEE}
        component={ResetPassword}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.RESET_PASSWORD_STUDENT}
        component={ResetPassword}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.SIGNUP_APPLICANT}
        component={ApplicantSignup}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.REDIRECTION_SCREEN}
        component={RedirectionScreen}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.INSTITUTE_LOGIN_ERROR_PAGE}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}>
        {(props) => (
          <UnsupportedResolution
            text1={'Screen not supported!!!'}
            text2={'We do not support on mobile screens. Please check on desktop screeens'}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.MULTI_ACCOUNT}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'light-content' }}>
        {(props) => (
          <NativePrivateRoute
            component={MultiAccount}
            authCallback={prop.authCallback}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            props={props}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

export default AuthNavigator;
