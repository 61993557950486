import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components/native';
import React, { useEffect, useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy'
import FileUpload from 'src/components/molecules/FileUpload/index';
import { CustomReactNativeFile } from '../../FileUpload/type';
import { FormSchema, FormHandleSubmit } from '../../Forms/types';
import { createDocumentObject } from 'src/utils/utility';
import { isWeb } from 'src/constant/device';


export const File = (props: UseFieldApiConfig) => {
  const {
    category,
    documentType,
    label,
    input,
    subscribeToValues,
    compact = false,
    defaultFileName = "",
    meta,
    removePayload = true,
    documentField,
    customFieldId = false,
    customHandleRemoveDocument,
  } = useFieldApi(props);

  const formOptions = useFormApi();
  const { getState, change } = formOptions;
  const handleSubmit: any = formOptions.handleSubmit;
  const schema: FormSchema = formOptions.schema as FormSchema;
  const initialValues: any = formOptions.initialValues;
  const [fileErr, setFileErr] = useState<any>();

  const { values, submitFailed, error } = getState();
  const { t } = useI18n();
  const { name, onChange } = input;
  const [currentFile, setCurrentFile] = useState<CustomReactNativeFile | undefined>();

  const handleRemove = async () => {
    if (currentFile?.id && removePayload) {
      if (customHandleRemoveDocument) {
        customHandleRemoveDocument(currentFile.id);
      } else {
        let submitHandler: FormHandleSubmit = await handleSubmit();
        if (submitHandler) {
          submitHandler({
            removeDoc: {
              detail: schema.name,
              detailId: initialValues.id,
              docId: currentFile.id
            }
          });
        }
      }

    }
    onChange(null);
    setCurrentFile(undefined);
  }

  useEffect(() => {
    if (values) {
      if (values?.documents && Array.isArray(values.documents) && values.documents.length > 0) {
        values.documents.map((document: any) => {
          if (document.type === documentType) {
            let fileObject = { ...document };
            if (values[documentType] !== null &&
              !values.hasOwnProperty(documentType)) {
              setCurrentFile(fileObject as any);
            }
          }
        })
      } else if (values?.document || values[documentField]) {
        setCurrentFile(values?.document || values[documentField]);
      }
    }
  }, [name, values])

  const handleFilesAttach = async (files: CustomReactNativeFile[]) => {
    if (files) {
      const docObj: any = await createDocumentObject(files[0], category, documentType)
      onChange(docObj);
      setCurrentFile(docObj);
    }
  }

  const setError = (id: string, error: { message: string }) => {
    if (error.message) {
      setFileErr(error.message);
    }
  }

  const clearErrors = () => {
    setFileErr(null);
  }


  function resolveComponent(component: JSX.Element) {
    if (isWeb) {
      return <WebInputContainer>
        <WebBrowseFile compact={compact}>
          {component}
        </WebBrowseFile>
      </WebInputContainer>
    } else {
      return <InputContainer>
        {component}
      </InputContainer>
    }
  }

  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        resolveComponent(
          <>
            <FileUpload
              file={currentFile}
              name={name}
              type={'onboarding'}
              header={t(label)}
              handleFileRemove={handleRemove}
              handleFilesAttach={handleFilesAttach}
              fieldId={customFieldId ? name : category}
              setError={setError}
              clearErrors={clearErrors}
              compact={compact}
              defaultFileName={defaultFileName}
              width={"100%"}
              errorMsgText={fileErr || (submitFailed && t(meta?.error))}
            />
          </>
        )
      )}
    </FormSpy>
  )
}


const ErrorMsg = styled.Text`
  font-family: ${fonts.regular};
  color: ${colors.errorColor};
  margin-top: 5px;
  font-size: 12px;
  min-height: 14px;
`;

const InputContainer = styled.View`
  margin-bottom: 10px;
`
const WebInputContainer = styled.View`
  margin-bottom: 0;
`
const WebBrowseFile = styled.View`
  width: 100%;
  box-sizing: border-box;
  margin-top: ${props => props.compact ? "0px" : "8px"}
  @media all and (max-width: 767px) {
    width: 100%;
  }
`;