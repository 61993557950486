import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { colors } from '../../../styles/theme/styles';
import formJSON from '../../../form-json/academics/classes.json';
import Element from '../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useQuery } from '@apollo/client';
import { autoCompleteLimit, AUTOCOMPLETE_MODULE, USER_TYPE } from '../../../constant';
import { useI18n } from 'src/i18n/hooks';
import { getFilteredCourses, getFilteredUniversities } from 'src/components/services';
import { validation } from 'src/constant/validation';
import { getAllBoadUniversitiesQuery } from 'src/graphql/academics/board-universities';
import { userInformation } from 'src/utils/manageState';
import ChipInput from 'src/components/atoms/ChipInput.tsx';
import { getOptionsQualificationsQuery } from 'src/graphql/admission/qualification';
import { Grid } from '@mui/material';
import styled from 'styled-components';

export default function ClassForm(props: any) {
  const { editClassData, isEditModal, getValues, setValue } =
    props;
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};
  const { t } = useI18n();
  const allUniversities = useQuery(getAllBoadUniversitiesQuery, {
    variables: { limit: autoCompleteLimit, skip: 0 },
  });
  const qualifications = useQuery(getOptionsQualificationsQuery, {
    variables: { limit: 0, skip: 0 }
  })
  const currentUserObject = userInformation();

  const { userType } = currentUserObject;

  if (userType === USER_TYPE.EMPLOYEE) {
    formJSON[0].fields[0].disabled = true;
    formJSON[0].fields[1].disabled = true;
    formJSON[0].fields[2].disabled = true;
    formJSON[0].fields[3].disabled = true;
  }

  useEffect(() => {
    let getdata = allUniversities.data;
    let qualificationData = qualifications.data;
    if (allUniversities.loading == false && qualifications.loading === false) {
      loadUniversities(getdata.universities.data);
      loadQualifications(qualificationData.qualificationNames.data);
    }
    if (editClassData && isEditModal) {
      setEditData(editClassData);
    }
  }, [allUniversities.data, qualifications.data]);

  function loadCourse(courseArray: any) {
    setElements([]);
    formJSON[0].fields[1].option = courseArray;
    setElements(formJSON[0]);
  }

  function loadUniversities(universities: any) {
    formJSON[0].fields[0].option = universities;
    setElements(formJSON[0]);
    clearCache();
  }

  function loadQualifications(qualifications: any) {
    formJSON[0].fields[2].option = qualifications;
    setElements(formJSON[0]);
  }

  function clearCache() {
    formJSON[0].fields[1].option = [];
    setElements(formJSON[0]);
  }

  function setFormField(data: any, course: any, qualification: any) {
    setValue('class_code', data?.code);
    setValue('class_name', data?.name);
    setValue('class_alias', data?.alias);
    setValue('course', { label: course?.alias, value: course?.id });
    setValue('university', { label: course?.university?.name, value: course?.university?.id });
    if (qualification) setValue('qualification', { label: qualification?.name, value: qualification?.id });
  }

  async function setEditData(data: any) {
    let course = data?.course;
    let qualification = data?.qualificationRef;
    setFormField(data, course, qualification);
    let courseParam = createCourseFilter(autoCompleteLimit, 0, course?.university?.id);
    let courseRespose = await getFilteredCourses(courseParam);
    loadCourse(courseRespose?.options);
  }

  async function searchSelectScroll(queryVariable: any, type: string) {
    let param = {
      limit: autoCompleteLimit,
      skip: queryVariable * autoCompleteLimit,
    } as any;

    if (type === AUTOCOMPLETE_MODULE.COURSE) {
      let board = getValues(AUTOCOMPLETE_MODULE.UNIVERSITY);
      let courseParam = createCourseFilter(
        autoCompleteLimit,
        queryVariable * autoCompleteLimit,
        board?.value,
      );
      let courseResponse = await getFilteredCourses(courseParam);
      return courseResponse;
    }

    if (type === AUTOCOMPLETE_MODULE.UNIVERSITY) {
      let boardResponse = await getFilteredUniversities(param);
      return boardResponse;
    }
  }

  async function searchSelectOnchange(queryVariable: any, type: string) {
    let filters = {
      alias: {
        regex: queryVariable,
      },
    } as any;

    let board = getValues(AUTOCOMPLETE_MODULE.UNIVERSITY);
    if (type === AUTOCOMPLETE_MODULE.COURSE && board?.value) {
      filters['university'] = { eq: board?.value };
      let courseRespose = await getFilteredCourses({ filters: filters });
      return courseRespose;
    }

    if (type === AUTOCOMPLETE_MODULE.UNIVERSITY) {
      let boardResponse = getFilteredUniversities({ filters: filters });
      return boardResponse;
    }
  }

  function createCourseFilter(limit: number, skip: number, boardId: number) {
    let params = {
      limit: limit,
      skip: skip,
      filters: {
        university: {
          eq: boardId,
        },
      },
    };
    return params;
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === AUTOCOMPLETE_MODULE.UNIVERSITY) {
      setValue(AUTOCOMPLETE_MODULE.COURSE, null);
      if (data) {
        let param = createCourseFilter(autoCompleteLimit, 0, data?.value);
        let courseRespose = await getFilteredCourses(param);
        loadCourse(courseRespose?.options);
      }
    }
  }

  return (
    <View>
      <Grid container>
        {fields ? (
          fields.map((field: any, i: number) => (
            <CustomGrid item md={12}>
              <Element
                key={i}
                field={field}
                control={props.control}
                errors={props.errors}
                searchSelectScroll={searchSelectScroll}
                searchSelectOnchange={searchSelectOnchange}
                dropdownDefault={editClassData}
                handleCustomSelect={handleCustomSelect}
              />
            </CustomGrid>
          ))
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner primary={true} />
          </View>
        )}
      </Grid>
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
});

const CustomGrid = styled(Grid)``;
