import { DropdownOptionsType } from 'src/types';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';

export const changeFeeCollection = (feeCompositionOptions: DropdownOptionsType[]) => ({
  name: 'changeFeeCollection',
  label: 'changeFeeCollection.label',
  viewMode: 1,
  fields: [
    {
      name: 'feeComposition',
      label: 'feeCollection.label',
      component: 'custom-select',
      options: feeCompositionOptions,
      isRequired: true,
      maxHeight: 150,
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.feeCollection.required',
        },
      ],
    },
  ],
});
