import React, { useEffect, useState } from 'react';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import formJSON from '../../../../form-json/control-panels/link-account.json';
import Element from '../../../molecules/Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';
import DownloadFile from 'src/components/atoms/DownloadFile/index.web';
import { CopyIcon } from 'src/components/atoms/ActionIcons';
import { useI18n } from 'src/i18n/hooks';
import { clipLongText } from 'src/utils/utility';

const LinkAccount = (props: any) => {
  const { errors, control, data } = props;
  const { t } = useI18n();
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    setElements(formJSON[0]);
  }, []);

  const fileData = {
    name: 'BankProof' + '.' + data?.proofDocuments[0]?.fileExtension,
    uri: 'https://dev-api.edba.io/bank-proofs/' + data?.proofDocuments[0]?.id
  }

  const DisplayValue = ({ value = "-" }: { value?: string }) => {
    return (
      <MediumTextSelect
        value={value}
        color={colors.primaryText}
        fontFamily={fonts.semibold}
      />
    );
  };

  const CopyValue = ({ value = "" }: { value?: string }) => {
    return (
      <IconWrapper>
        <CopyIcon onPress={() => navigator.clipboard.writeText(value)} />
      </IconWrapper>
    );
  };

  return (
    <>
      {data ? (
        <>
          <FlexRow>
            <RowWrapper>
              <NormaltextAtom value={t("accountName.label")} />
              <FlexRow>
                <DisplayValue value={clipLongText(data?.name, 24)} />
                <CopyValue value={data?.name} />
              </FlexRow>
            </RowWrapper>
            <RowWrapper>
              <NormaltextAtom value={t("bankName.label")} />
              <FlexRow>
                <DisplayValue value={data?.bankName} />
                <CopyValue value={data?.bankName} />
              </FlexRow>
            </RowWrapper>
          </FlexRow>

          <SecondRowWrapper>
            <NormaltextAtom value={t("accountIFSC.label")} />
            <FlexRow>
              <DisplayValue value={data?.ifscCode} />
              <CopyValue value={data?.ifscCode} />
            </FlexRow>
          </SecondRowWrapper>

          <ThirdRowWrapper>
            <RowWrapper>
              <NormaltextAtom value={t("accountType.label")} />
              <DisplayValue value={data?.accountType} />
            </RowWrapper>
            <RowWrapper>
              <NormaltextAtom value={t("accountNumber.label")} />
              <FlexRow>
                <DisplayValue value={data?.accountNumberView} />
              </FlexRow>
            </RowWrapper>
          </ThirdRowWrapper>

          <SecondRowWrapper>
            <HeaderSix value={t("accountProof.label")} color={colors.primaryText} fontFamily={fonts.regular} />
            {data?.proofDocuments && data?.proofDocuments?.length > 0 ? (
              <DownloadFileWrapper>
                <DownloadFile fileData={fileData} />
              </DownloadFileWrapper>
            ) : (
              <DisplayValue value={'NA'} />
            )}
          </SecondRowWrapper>
        </>
      ) : null}
      <SectionWrapper>
        {fields && fields.length > 0 ? (
          fields.map((field: any, i: number) => (
            <ElementWrapper>
              <Element
                key={i}
                field={field}
                control={control}
                errors={errors}
              />
            </ElementWrapper>
          ))
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )}
      </SectionWrapper>
    </>
  )
};

const FlexRow = styled.View`
  display: flex;
  flex-direction: row;
`;

const SectionWrapper = styled.View`
  margin-top: 16px;
`;

const IconWrapper = styled.View`
  margin-left: 16px;
`;

const ElementWrapper = styled.View`
  max-width: 300px;
`;

const DownloadFileWrapper = styled.View`
  margin-top: 16px;
  max-width: 300px;
`;

const SecondRowWrapper = styled.View`
  margin-top: 24px;
`;

const RowWrapper = styled.View`
  flex: 1;
`;

const ThirdRowWrapper = styled.View`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

const SpinnerView = styled.View`
  width: 750;
  height: 547;
  justify-content: center;
  align-items: center;
`;

export default LinkAccount;