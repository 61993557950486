import React, { useEffect, useState } from "react";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import formJSON from "src/form-json/admission/PublicAdmission/ScheduleInterview.json";
import { useForm } from "react-hook-form";
import { useI18n } from "src/i18n/hooks";
import { useTriggerInverviewScheduleMails } from "src/graphql/admission/public-invitation";

type Props = {
  admissionId: string;
  emailIds: string[];
  onClose: () => void;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
};

type TFormData = {
  date: string;
  time: string;
};

type TPayload = {
  admissionId: string;
  emailIds: string[];
} & TFormData;

const ScheduleInterview = (props: Props) => {
  const { admissionId, emailIds, onClose, onSuccess, onError } = props;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm<TFormData>();

  const { triggerInterviewMail } = useTriggerInverviewScheduleMails();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [elements, setElements] = useState<any>({});
  const { fields } = elements ?? {};

  useEffect(() => {
    setElements({ ...formJSON[0] });
  }, []);

  const onSubmit = async (formData: TFormData) => {
    try {
      setIsSubmitting(true);
      const payload: TPayload = {
        admissionId,
        emailIds,
        date: formData.date,
        time: formData.time,
      };

      const response = await triggerInterviewMail({
        variables: { payload },
      });

      const resp = response?.data?.triggerInterviewScheduleMail ?? {};
      if (resp && resp?.status) {
        if (resp.status === "success") {
          onSuccess(resp?.message);
        } else if (resp.status === "failure") {
          onError(resp?.message);
        }
      }
      setIsSubmitting(false);
    } catch (e: any) {
      onError(e?.message);
      setIsSubmitting(false);
    }
  };

  return (
    <NormalModal
      modalVisible={true}
      setModalVisible={onClose}
      Headerpopup={t("scheduleInterview.label")}
      addEditButtonLabel={t("sendEmail.label")}
      onCancelButtonClick={onClose}
      handleSave={handleSubmit(onSubmit)}
      width={500}
      isSubmitting={isSubmitting}
    >
      {fields ? (
        fields?.map((field: any) => (
          <>
            {/* @ts-ignore */}
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
              limitTags={1}
            />
          </>
        ))
      ) : (
        <></>
      )}
    </NormalModal>
  );
};

export default ScheduleInterview;
