import { AUTOCOMPLETE_MODULE, ERROR, SUCCESS } from "src/constant";
import React, { useCallback, useEffect, useState } from "react";
import { createSearchFilter, debounce } from "src/components/services";

import { ADD_SUCCESS } from "src/constant/message";
import CreateInfoItem from "src/components/molecules/TextComponents/InfoItem";
import { DropdownOptions } from "src/types";
import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import OutlineButton from "src/components/atoms/Button/OutlineButtton";
import { PromoteToDivisionTable } from "src/components/molecules/Table/PromoteToDivisionTable";
import Search from "src/components/atoms/SearchBar/index.web";
import SecondaryBtn from "src/components/atoms/Button/SecondaryButton";
import { ViewMode } from "src/components/molecules/Forms/types";
import { getBatchByIdQuery } from "src/graphql/academics/batch";
import { getStudentFilter } from "src/components/services/filters";
import { graphqlQuery } from "src/graphql/util";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useAssignDivision } from "src/graphql/admission/application";
import { useFilter } from "src/contexts/filter-context";
import { useGetUnassignedDivStudents } from "src/graphql/classes";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { useParams } from "src/routes/routing.web";
import { useQuerySubjectGroupOptions } from "src/graphql/academics/subject-groups";

const PromotedStudents = () => {
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();
  const { setHeading } = useHeaderTitle();
  const { urlBatchId }: any = useParams();
  const { filters, setFilter } = useFilter();
  const {
    getUnassignedDivStudents,
    data,
    loading: dataLoading,
    refetch,
  } = useGetUnassignedDivStudents();
  const { assignDivision } = useAssignDivision();

  let searchValue: string = "";

  if (filters && filters?.student?.on) {
    searchValue = filters?.student?.search;
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Record<string, string | null>>({});
  const [searching, setSearching] = useState<string>(searchValue);
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.READ);
  const [divisionOptions, setDivisionOptions] = useState<DropdownOptions[]>();
  const [batchName, setBatchName] = useState<string>("");

  const { query: subGroupQuery, data: subGroups } =
    useQuerySubjectGroupOptions();
  const subGroupsData = subGroups?.subjectGroups?.data;

  useEffect(() => {
    if (urlBatchId) {
      loadBatchData();
      subGroupQuery({
        variables: { filters: { batch: { eq: urlBatchId } }, limit: 0 },
      });
    }
  }, [urlBatchId]);

  useEffect(() => {
    setSearching(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [searchValue, urlBatchId]);

  async function loadBatchData() {
    const { data: batchData } = await graphqlQuery(
      getBatchByIdQuery,
      { id: urlBatchId },
      false
    );
    const response = batchData?.batch;
    if (response) {
      setHeading([
        { text: t("classrooms.label"), url: "/classrooms", from: "Unassigned" },
        { text: response?.class?.course?.alias },
        { text: (response?.alias || "-") + " - " + t("unassigned.label") },
      ]);
      setBatchName(`${response?.name} (${response?.class?.alias})`);
      if (response?.divisionDetails && response?.divisionDetails?.length > 0) {
        setDivisionOptions(
          response?.divisionDetails.map((det: any) => {
            return {
              value: det.division,
              label: det.division,
            };
          })
        );
      }
    }
  }

  const createQueryParameter = (searchFilter: any) => {
    let studentFilters = {} as any;
    const divisionFilter = [
      { division: { exists: false } },
      { division: { eq: null } },
    ];
    if (searchFilter.filters) {
      studentFilters = {
        limit: 0,
        filters: {
          batch: { eq: urlBatchId },
          and: [{ or: divisionFilter }, searchFilter.filters],
        },
      };
    } else {
      studentFilters = {
        limit: 0,
        filters: {
          batch: { eq: urlBatchId },
          or: divisionFilter,
        },
      };
    }
    return studentFilters;
  };

  const handleFetch = () => {
    if (urlBatchId) {
      const filters = getStudentFilter(searchValue);
      const searchFilter = createSearchFilter(0, 0, filters);
      const studentFilter = createQueryParameter(searchFilter);
      getUnassignedDivStudents({ variables: studentFilter });
    }
  };

  const handleSearch = (searchVal: string) => {
    setSearching(searchVal);
    delayedQuery(searchVal);
  };

  function persistSearch(state: boolean, search: string) {
    const persistFilter = {
      [AUTOCOMPLETE_MODULE.STUDENT]: { on: state, search: search },
    };
    setFilter(persistFilter);
  }

  async function searchStudents(student: string) {
    const isClear = student?.length === 0;
    persistSearch(!isClear, student);
  }

  const delayedQuery = useCallback(
    debounce((q: string) => searchStudents(q), 500),
    []
  );

  const onSubmitFn = async () => {
    if (data) {
      setLoading(true);
      try {
        if (Object.keys(formData).length > 0) {
          const payload = Object.entries(formData)
            .filter(([_, value]) => value !== null)
            .map(([key, value]) => ({ id: key, division: value }));
          const response = await assignDivision({ variables: { payload } });
          if (response?.data?.assignDivNRollNos?.status === "success") {
            setViewMode(ViewMode.READ);
            setAlertDetails({
              message: ADD_SUCCESS.ASSIGN_DIVISION,
              level: SUCCESS,
            });
          }
        } else {
          setAlertDetails({
            message: t("error.division.required"),
            level: ERROR,
          });
        }
        setLoading(false);
      } catch (error: any) {
        setAlertDetails({ message: error?.message, level: ERROR });
        setLoading(false);
      }
    }
  };

  return (
    <LoadContentWrapper>
      <BodyWrapper>
        <TopSectionWrapper>
          <InfoWrapper>
            <CreateInfoItem label={t("batch.label")} value={batchName} />
            <CreateInfoItem
              label={t("subjectGroups.label")}
              value={
                Array.isArray(subGroupsData) && subGroupsData?.length > 0
                  ? subGroupsData
                    .map((s: DropdownOptions) => s.label)
                    .join(", ")
                  : ""
              }
              width={480}
            />
          </InfoWrapper>
          <ControlSectionWrapper>
            <Search
              id="promotionsStudentsSearch"
              handleChange={handleSearch}
              value={searching}
              label={t("searchStudents.label")}
            />
            <>
              {viewMode === ViewMode.EDIT ? (
                <BtnWrapper>
                  <OutlineButton
                    onPress={() => setViewMode(ViewMode.READ)}
                    label={t("cancel.label")}
                  />
                  <SecondaryBtn
                    onPress={onSubmitFn}
                    label={t("save.label")}
                    canLoad={loading}
                  />
                </BtnWrapper>
              ) : (
                <SecondaryBtn
                  onPress={() => setViewMode(ViewMode.EDIT)}
                  label={t("assignClassDivision.text")}
                  lines={1}
                  customDisabled={!data?.instituteStudents?.data || dataLoading}
                />
              )}
            </>
          </ControlSectionWrapper>
        </TopSectionWrapper>
        <PromoteToDivisionTable
          viewMode={viewMode}
          data={data?.instituteStudents?.data ?? []}
          loading={dataLoading}
          divisionOptions={divisionOptions ?? []}
          formData={formData}
          setFormData={setFormData}
        />
      </BodyWrapper>
    </LoadContentWrapper>
  );
};

const TopSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const ControlSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
`;

const BtnWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
`;

const BodyWrapper = styled.div`
  height: 100%;
`;

export default PromotedStudents;
