import React, { useEffect, useState } from 'react';
import { Control, FieldErrors, FieldValues, UseFormSetValue } from 'react-hook-form/dist/types';
import Element from '../../../molecules/Forms/ApplicationElement.web';
import formJSON from '../../../../form-json/assessment/edit-result-form.json';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import CreateInfoItem from 'src/components/molecules/TextComponents/InfoItem';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components';
import { properCase } from 'src/utils/utility';

interface Props {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  editResultData: any;
}

export default function EditResultForm(props: Props) {
  const { control, errors, setValue, editResultData } = props;
  const { t } = useI18n();
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    loadEditData();
    setElements(formJSON[0]);
    return () => {
      setValue('enrollmentNo', '');
      setValue('seatNo', '');
    };
  }, []);

  function loadEditData() {
    if (editResultData?.marksheet?.enrollmentNo) {
      setValue('enrollmentNo', editResultData.marksheet.enrollmentNo);
    }

    if (editResultData?.marksheet?.seatNo) {
      setValue('seatNo', editResultData.marksheet.seatNo);
    }
  }

  return fields ? (
    <>
      <InfoWrapper>
        <CreateInfoItem
          label={t('studentName.label')}
          value={
            editResultData?.user?.personalDetails?.fullName
              ? properCase(editResultData.user.personalDetails.fullName)
              : 'N/A'
          }
        />
        <CreateInfoItem
          label={t('rollno.label')}
          value={editResultData?.user?.academic?.rollNo || 'N/A'}
        />
      </InfoWrapper>
      {fields.map((field: any, i: number) => (
        <Element key={`resultt${i}`} field={field} control={control} errors={errors} />
      ))}
    </>
  ) : (
    <SpinnerWrapper>
      <LoaderSpinner />
    </SpinnerWrapper>
  );
}

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 235px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 24px;
  margin-bottom: 24px;
`;
