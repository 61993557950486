import React, { useEffect, useState } from 'react';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components';
import SearchDropdown from 'src/components/atoms/AutoComplete/index.web';
import Input from 'src/components/atoms/Input/input.web';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { colors } from 'src/styles/theme/styles';
import {
  TrevalChipData,
  Toptions,
  HandleKeyDown,
} from 'src/components/molecules/AtktRevaIinput/helpers';
import RevalTable from 'src/components/molecules/AtktRevaIinput/Table/RevalTable.web';

type TRevalInputProps = {
  initialValues: any;
  handleAddChange: (newValue: any) => void;
  casteCategoryOptions: Toptions[];
  serviceOptions: Toptions[];
  merchantOptions: Toptions[];
  allowDecimalInput?: boolean;
};

const RevalInput: React.FC<TRevalInputProps> = ({
  initialValues,
  handleAddChange,
  casteCategoryOptions,
  serviceOptions,
  merchantOptions,
  allowDecimalInput = false,
}) => {
  const { t } = useI18n();
  const [documentChips, setDocumentChips] = useState<TrevalChipData[]>([]);
  const [selectedCasteValue, setSelectedCasteValue] = useState<any>(null);
  const [selectedServiceValue, setSelectedServiceValue] = useState<Toptions | null>(null);
  const [selectedMerchantValue, setSelectedMerchantValue] = useState<Toptions | null>(null);
  const [amountinput, setAmountinput] = useState<any>();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [softCopyFees, setSoftCopyFees] = useState<number>(0);
  const [applicationFees, setApplicationFees] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    setSelectedCasteValue(newValue);
  };

  const handleServiceChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    setSelectedServiceValue(newValue);
  };

  const handleMerchantchange = (event: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
    setSelectedMerchantValue(newValue);
  };

  function removeChip(indexToRemove: number) {
    setDocumentChips(prevChips => {
      const updatedChips = prevChips.filter((_, index) => index !== indexToRemove);
      handleAddChange({
        ...initialValues,
        fees: updatedChips,
      });
      return updatedChips;
    });
  }

  useEffect(() => {
    if (initialValues) {
      setSelectedMerchantValue(initialValues.merchantId);
      const paymentProviderID = initialValues.paymentProvider;
      const selectedService = serviceOptions.find(option => option.value === paymentProviderID);
      setSelectedServiceValue(selectedService);
      setDocumentChips(initialValues?.fees);
    }
  }, [initialValues]);
  useEffect(() => {
    setIsButtonDisabled(
      !(selectedCasteValue && amountinput && selectedServiceValue && selectedMerchantValue),
    );
  }, [setSelectedCasteValue, amountinput, selectedServiceValue, selectedMerchantValue]);

  function handleAdd() {
    if (selectedCasteValue && selectedServiceValue && selectedMerchantValue && amountinput) {
      const data = selectedCasteValue.map((obj: any) => ({
        ...obj,
        amountPerSubject: amountinput,
        applicationFee: applicationFees,
        amountPerSoftCopy: softCopyFees,
      }));
      const alreadyExists = data.some((newCaste: TrevalChipData) =>
        documentChips.some((chip: TrevalChipData) => chip.value === newCaste.value),
      );
      if (alreadyExists) {
        setError(true);
        return;
      }
      const newdata = {
        fees: data,
        selectedMerchantValue: selectedMerchantValue,
        selectedServiceValue: selectedServiceValue.value,
      };
      setDocumentChips(prevChips => {
        return [...prevChips, ...data];
      });
      handleAddChange({
        merchantId: newdata.selectedMerchantValue,
        paymentProvider: newdata.selectedServiceValue,
        fees: [...documentChips, ...data],
      });
      clearFormValues();
    }
  }

  function clearFormValues() {
    setAmountinput('');
    setApplicationFees(0);
    setSoftCopyFees(0);
    setSelectedCasteValue(null);
    setIsButtonDisabled(true);
    setError(false);
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    HandleKeyDown(event, allowDecimalInput);
  };

  return (
    <>
      <Container>
        <ElementWrapper>
          <DroopdownWrapper>
            <SearchDropdown
              header={t('atktRevalServiceProvider.required.label')}
              width="250px"
              options={serviceOptions}
              value={selectedServiceValue}
              onChange={handleServiceChange}
            />
            <SearchDropdown
              header={t('atktRevalMerchant.required.label')}
              width="250px"
              options={merchantOptions}
              value={selectedMerchantValue}
              onChange={handleMerchantchange}
            />
          </DroopdownWrapper>
          <FieldWrapper>
            <SearchDropdown
              header={t('selectCasteCategory.required.label')}
              width="250px"
              options={casteCategoryOptions}
              value={selectedCasteValue}
              onChange={handleChange}
              multipleSelection={true}
            />
            <Input
              typeInput={'number'}
              header={t('revalAmount.required.label')}
              value={amountinput}
              width={'250px'}
              setValue={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAmountinput(parseInt(e.target.value))
              }
              onkeyDown={handleKeyPress}
              disablePaste={true}
            />
            <Input
              typeInput={'number'}
              header={t('atktRevalApplicationFees.required.label')}
              value={applicationFees}
              width={'250px'}
              setValue={(e: React.ChangeEvent<HTMLInputElement>) =>
                setApplicationFees(parseInt(e.target.value))
              }
              onkeyDown={handleKeyPress}
              disablePaste={true}
            />
            <Input
              typeInput={'number'}
              header={t('revalSoftCopys.required.label')}
              value={softCopyFees}
              width={'250px'}
              setValue={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSoftCopyFees(parseInt(e.target.value))
              }
              onkeyDown={handleKeyPress}
              disablePaste={true}
            />
          </FieldWrapper>
        </ElementWrapper>
        <ButtonWrapper>
          <SecondaryBtn
            onPress={handleAdd}
            label={t('atktRevalAddAmount.text')}
            customDisabled={isButtonDisabled}
          />
        </ButtonWrapper>
      </Container>
      <ChipsWrapper>
        {documentChips.length > 0 && (
          <RevalTable revalData={documentChips} handleRemove={removeChip} />
        )}
      </ChipsWrapper>
      {error && <ErrorMsg message={'CasteCategory already exists'} />}
    </>
  );
};

export default RevalInput;

const ErrorMsg: React.FC<{ message: string }> = ({ message }) => (
  <ErrorWrapper>
    <SmallTextAtom value={message} color={colors.errorColor} />
  </ErrorWrapper>
);

const ErrorWrapper = styled.div`
  margin-top: 4px;
`;
const Container = styled.div`
  margin-top: 24px;
`;

const ElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DroopdownWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const FieldWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  flex-wrap: wrap;
`;

const ChipsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ChipFieldWrapper = styled.div`
  margin-bottom: 8px;
  margin-right: 8px;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  width: 117px;
`;
