import React from 'react';
import { isIOS, isNative } from 'src/constant/device';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';

interface Props {
  value: any;
  color?: string;
  fontWeight?: any;
  lineHeight?: number;
  lines?: number;
  align?: string;
  fontsize?: number;
  fontFamily?: string;
  textOpacity?: number;
  style?: object;
  multilineText?: boolean;
  id?: string;
}

export default function NormalTextSelect(props: Props) {
  const {
    value = '',
    textOpacity = 1,
    color = colors.primaryText,
    fontWeight = 'normal',
    lineHeight = 2.0,
    lines,
    align = 'left' as string,
    fontsize = 1.5,
    fontFamily = fonts.regular,
    multilineText = false,
    style,
    id
  } = props;

  const Text = styled.Text`
    opacity: ${textOpacity};
    font-family: ${fonts.regular};
    text-align: ${align};
    color: ${color};
    font-style: normal;
    font-weight: ${fontWeight};
    font-size: ${(props) => props.theme.rem(fontsize)};
    line-height: ${(props) => props.theme.rem(lineHeight)};
    font-family: ${fontFamily};
  `;

  const Text2 = styled.Text`
    opacity: ${textOpacity};
    font-family: ${fonts.regular};
    text-align: ${align};
    color: ${color};
    font-style: normal;
    font-weight: ${fontWeight};
    font-size: ${(props) => props.theme.rem(fontsize)};
    line-height: ${(props) => props.theme.rem(lineHeight + 0.1)};
    font-family: ${fontFamily};
  `;

  return (
    <Text id={id} numberOfLines={lines} allowFontScaling={false} style={style && style}>
      {value}
      {/* to fix android lineheight issue while rendering multiline text */}
      {isNative && !isIOS && multilineText && <Text2 />}
    </Text>
  );
}
