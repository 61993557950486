/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-single-element-style-arrays */
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Icon } from 'src/components/atoms/Icon/Icon';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import MediumText from 'src/components/atoms/Text/MediumText';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { isWeb } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';

export default function AttendanceNotConfigured(props: any) {
  const { topPosition = '35%', type = 0 } = props;
  const { t } = useI18n();
  return (
    <View style={[styles.emptyView, { top: topPosition }]}>
      <View style={styles.iconView}>
        <Icon name={'attendance-configuration'} size={isWeb ? 80 : 40} />
      </View>
      <View>
        {isWeb ? (
          <HeaderThree
            value={type === 0 ? t('noAttendance.text1') : t('noAttendance.text3')}
            color={colors.secondaryText}
            lineHeight={32}
            fontSize={24}
          />
        ) : (
          <NormalTextSelect
            value={type === 0 ? t('noAttendance.text1') : t('noAttendance.text3')}
            color={colors.tertiaryText}
            align={'center'}
          />
        )}
      </View>
      <View>
        {isWeb ? (
          <MediumText
            value={type === 0 ? t('noAttendance.text2') : t('noAttendance.text4')}
            color={colors.tertiaryText}
            lineHeight={2.4}
          />
        ) : (
          <NormalTextSelect
            value={type === 0 ? t('noAttendance.text2') : t('noAttendance.text4')}
            color={colors.tertiaryText}
            align={'center'}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  emptyView: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconView: {
    marginBottom: 10,
  },
});
