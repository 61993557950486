import { isIOS, isNative, isWeb, width } from 'src/constant/device';
import styled from 'styled-components/native'
import { colors } from 'src/styles/theme/styles';

interface MarginType {
  margin: number
}

export const Container = styled.View`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${colors.white};
`;

export const PageAreaContainer = styled.View`
  justify-content: center;
  flex-grow: 1;
  border-color: #666666;
  width: 520px;
  height: 578px;
  background-color: ${isIOS ? colors.white : 'transparent'};
`;

export const RequestResetDiv = styled.View`
  background-color: ${colors.white};
  border-radius: 3px;
  padding-top: ${isWeb ? "60px" : "42px"};
  padding-bottom: ${isWeb ? "102px" : "48px"};
  padding-horizontal: 85px;
  box-shadow: ${isWeb ? `0px 4px 25px ${colors.dividerColor}` : `0px 0px 0px ${colors.white}`};
`;

export const LinkContainer = styled.View` 
  margin-top: ${(isWeb) => isWeb ? 24 + 'px' : 12 + 'px'};
  text-align: center;
`;

export const StyledTouchableOpacity = styled.TouchableOpacity` 
  align-self: center;
`;

export const ForgotPassBackToSignIn = styled.TouchableOpacity` 
  padding-top: 16px;
  align-self: center;
`;

export const MarginTop = styled.View<MarginType>`
  margin-top: ${props => props.margin + 'px'};
`;

export const BackgroundImage = styled.Image`
  height: 180px;
  width: ${width > 400 ? 450 : '120%'};
  margin-top: 5%;
  margin-left: ${width > 400 ? '-10%' : '-10%'};
`;

export const NativeEDBALogo = styled.Image`
  height: 40px;
  width: 112px;
`;

export const SignInText = styled.View`
  margin-top: ${isNative ? 4 : 0};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoView = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: ${isWeb ? "20px" : "0px"}
`;

export const TextView = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: ${isWeb ? "30px" : "0px"};
  margin-bottom: ${isIOS ? "5px" : "0px"};
`;
