import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 24 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M21.9 4H2.1C1.49259 4 1 4.51179 1 5.14286V18.8571C1 19.4882 1.49259 20 2.1 20H21.9C22.5074 20 23 19.4882 23 18.8571V5.14286C23 4.51179 22.5074 4 21.9 4ZM21.35 18.2857H2.65V5.71429H21.35V18.2857ZM11.175 14.2857H13.925C14.0769 14.2857 14.2 14.1579 14.2 14V12.8571C14.2 12.6993 14.0769 12.5714 13.925 12.5714H11.175C11.0231 12.5714 10.9 12.6993 10.9 12.8571V14C10.9 14.1579 11.0231 14.2857 11.175 14.2857ZM19.425 12.5714H16.675C16.5231 12.5714 16.4 12.6993 16.4 12.8571V14C16.4 14.1579 16.5231 14.2857 16.675 14.2857H19.425C19.5769 14.2857 19.7 14.1579 19.7 14V12.8571C19.7 12.6993 19.5769 12.5714 19.425 12.5714ZM11.175 11.4286H19.425C19.5769 11.4286 19.7 11.3007 19.7 11.1429V10C19.7 9.84214 19.5769 9.71429 19.425 9.71429H11.175C11.0231 9.71429 10.9 9.84214 10.9 10V11.1429C10.9 11.3007 11.0231 11.4286 11.175 11.4286ZM5.631 12.0614L7.08128 12.47C7.22531 12.5107 7.325 12.6325 7.325 12.7664C7.325 12.9379 7.17031 13.0771 6.97919 13.0771H6.07375C5.93109 13.0771 5.79084 13.04 5.66916 12.9718C5.56328 12.9121 5.43403 12.9229 5.35153 13.0139L4.93663 13.4686C4.82972 13.5857 4.84725 13.7771 4.97547 13.8682C5.26078 14.07 5.59628 14.1854 5.95 14.2082V14.5714C5.95 14.7293 6.07306 14.8571 6.225 14.8571H6.775C6.92694 14.8571 7.05 14.7293 7.05 14.5714V14.2054C7.81244 14.1661 8.425 13.5421 8.425 12.7661C8.425 12.1171 7.99119 11.5418 7.369 11.3671L5.91872 10.9586C5.77469 10.9179 5.675 10.7961 5.675 10.6621C5.675 10.4907 5.82969 10.3514 6.02081 10.3514H6.92625C7.06891 10.3514 7.20916 10.3886 7.33084 10.4568C7.43672 10.5161 7.56597 10.5057 7.64847 10.4146L8.06337 9.96C8.17028 9.84286 8.15275 9.65143 8.02453 9.56036C7.73922 9.35857 7.40372 9.24321 7.05 9.22036V8.85714C7.05 8.69929 6.92694 8.57143 6.775 8.57143H6.225C6.07306 8.57143 5.95 8.69929 5.95 8.85714V9.22321C5.18756 9.2625 4.575 9.88643 4.575 10.6625C4.575 11.3114 5.00881 11.8868 5.631 12.0614Z"
        fill="black"
        fill-opacity="0.6"
      />
    </Svg>
  );
}

export default SvgComponent;
