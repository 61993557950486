// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tb {
  border-collapse: collapse;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  text-align: left;
  padding: 17px 8px;
  line-height: 16px;
  font-family: SFProRounded-Bold;
  font-size: 12;
  color: rgba(0, 0, 0, 0.4);
}

.expand {
  padding: 11.5px 8px !important;
}

.expand-module {
  padding: 18.5px 8px !important;
}

.collapse {
  padding: 18.5px 8px !important;
}

.collapsewitherror,
.expand-modulewitherror {
  padding: 18.5px 8px 2.3px 8px !important;
}

tr {
}

td {
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  font-family: SFProRounded-Regular;
  cursor: default;
  font-size: 15;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/Table/employee.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,2CAA2C;AAC7C;;AAEA;EACE,2CAA2C;EAC3C,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,8BAA8B;EAC9B,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;;EAEE,wCAAwC;AAC1C;;AAEA;AACA;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;EACjB,iCAAiC;EACjC,eAAe;EACf,aAAa;AACf","sourcesContent":[".tb {\n  border-collapse: collapse;\n  width: 100%;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n}\n\nth {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n  text-align: left;\n  padding: 17px 8px;\n  line-height: 16px;\n  font-family: SFProRounded-Bold;\n  font-size: 12;\n  color: rgba(0, 0, 0, 0.4);\n}\n\n.expand {\n  padding: 11.5px 8px !important;\n}\n\n.expand-module {\n  padding: 18.5px 8px !important;\n}\n\n.collapse {\n  padding: 18.5px 8px !important;\n}\n\n.collapsewitherror,\n.expand-modulewitherror {\n  padding: 18.5px 8px 2.3px 8px !important;\n}\n\ntr {\n}\n\ntd {\n  text-align: left;\n  color: rgba(0, 0, 0, 0.8);\n  line-height: 20px;\n  font-family: SFProRounded-Regular;\n  cursor: default;\n  font-size: 15;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
