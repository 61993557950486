import React, { useEffect, useState } from 'react';
import { faUpload, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { View } from 'react-native';
import OutlineButtton from 'src/components/atoms/Button/OutlineButtton';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import { breakpoints, colors, fonts } from 'src/styles/theme/styles';
import { validateAttachments } from 'src/utils/utility';
import { CustomReactNativeFile, FileUploadProps } from './type';
import MediumText from 'src/components/atoms/Text/MediumText';
import styled from 'styled-components';

export default function FileUpload(props: FileUploadProps) {
  const {
    fieldId,
    file,
    handleFilesAttach,
    handleFileRemove,
    style,
    name,
    header,
    width,
    multiple = false,
    type = '',
    setError,
    clearErrors,
    compact = false,
    defaultFileName,
    errorMsgText = "",
    fileTypeAllowed,
  } = props;
  const { theme } = useThemeSystem();
  const [fileName, setFileName] = useState<String | undefined>();
  const [fileURL, setFileURL] = useState<String | undefined>();
  const { t } = useI18n();

  useEffect(() => {    
    if (file) {
      let doc: any = file;
      if (doc.document) {        
        setFileNameAndURL(
          doc.documentName,
          URL.createObjectURL(doc.document),
        );
      } else {
        setFileNameAndURL(file.name, doc.uri);
      }
    } else {
      setFileNameAndURL();
    }
  }, [file]);

  const setFileNameAndURL = (name?: string, uri?: string) => {
    setFileName(name);
    setFileURL(uri);
  };

  const sanitizeFileObject = (file: any) => {
    file.uri = URL.createObjectURL(file);
    return file;
  }

  const handleAttach = (files: any[]) => {
    files = files.map(sanitizeFileObject);
    if (
      handleFilesAttach &&
      validateAttachments(
        fieldId,
        files,
        setError,
        clearErrors,
        false,
        fileTypeAllowed,
      )
    ) {
      handleFilesAttach(files)
    }
  }

  const handleChange = (ev: any) => {
    let files: any = Object.values(ev?.target?.files || {});
    handleAttach(files);
  }
  const handleDrag = (ev: any) => {
    let files: any = Object.values(ev?.dataTransfer?.files || {});
    handleAttach(files);
  }

  return (
    <>
      {header !== '' ? (
        <View style={{ marginBottom: compact ? 4 : 8 }}>
          <SmallTextAtom value={t(header)} color={theme?.input?.topLabel} />
        </View>
      ) : null}
      <CustomGrid
        item
        width={width}
        style={{
          ...styles.container,
          ...style,
          height: compact ? 40 : 160
        }}>
        {fileURL && !multiple && (
          compact ?
            <div style={styles.compactFileName} onClick={() => {
              if (handleFileRemove && file) {
                handleFileRemove(file);
              }
            }}>
              <MediumText value={t(defaultFileName || fileName || name)} lines={1}/>
              <FontAwesomeIcon icon={faTimes} color={colors.secondaryText} />
            </div> :
          <div style={styles.imgView}>
            <Tooltip title="Remove File">
              <div style={{ ...styles.crossView, right: -8 }}
                onClick={() => {                  
                  if (handleFileRemove && file) {
                    handleFileRemove(file);
                  }
                }}>
                <FontAwesomeIcon icon={faTimes} size="xs" color="white" />
              </div>
            </Tooltip>

            <span
              style={{
                alignSelf: 'center',
                fontSize: 12,
                padding: '0px 8px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontFamily: fonts.regular,
              }}>
              {fileName}
            </span>
          </div>
        )}

        {(!fileURL || multiple) && (
          <label htmlFor={fieldId} style={styles.labelStyle}>
          <div
            style={styles.dropView}
            onDragOver={(ev) => {
              ev.preventDefault();
            }}
            onDrop={handleDrag}>
            {!compact && <>
              <div>
                <div style={{ textAlign: 'center', marginBottom: 8 }}>
                  <FontAwesomeIcon icon={faUpload} color={colors.secondaryText} />
                </div>

                <div style={styles.dropText}>
                  <SmallTextAtom value={t('dropFile.text')} />
                </div>
              </div>
              <View style={{ marginVertical: 6 }}>
                <SmallTextAtom value={t('or.label')} />
              </View>
            </>}

            <div>
              <input
                style={{ display: 'none' }}
                id={fieldId}
                ref={props.inputRef}
                multiple={multiple}
                type="file"
                accept=".doc,.docx,.pdf,image/*"
                onChange={handleChange}
              />
                {compact ?
                  <FontAwesomeIcon icon={faUpload} color={colors.secondaryText} style={styles.cursorPointer} /> :
                  <OutlineButtton label={t('browseFile.text')} onPress={() => { }} />
                }
            </div>
          </div>
          </label>
        )}
      </CustomGrid>
      <View style={styles.footerView}>
        <SmallTextAtom value={t("error.max-size-20")} color={theme?.input?.topLabel} />
        <SmallTextAtom value={`${errorMsgText || '\u200b'}`} color={colors.errorColor} lines={1} />
      </View>
    </>
  );
}

const styles = {
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: `1px dashed ${colors.tertiaryText}`,
    borderRadius: 4,
  },
  imgView: {
    height: 100,
    width: 100,
    backgroundColor: '#F0F3F5',
    boxShadow: `0px 0px 4px ${colors.searchShadow}`,
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 8px',
    flexWrap: 'wrap',
    position: 'relative',
  },
  crossView: {
    display: 'flex',
    height: 16,
    width: 16,
    position: 'absolute',
    top: -8,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.secondaryText,
    borderRadius: 50,
    cursor: 'pointer',
  },
  dropView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  dropText: {
    margin: '0px 10px',
    color: colors.primaryText,
    backgroundColor: 'transparent',
    borderColor: '#0085FF',
    fontSize: 12,
  },
  compactFileName: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: "90%",
    cursor: 'pointer',
  },
  footerView: {
    marginTop: 4, display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  labelStyle: {
    width: '100%', height: '100%', display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: "pointer"
  }
};

const CustomGrid = styled(Grid)`
  &.MuiGrid-root {
    max-width: ${(props:any) => (props.width ? props.width : 350)};
    @media (max-width: ${breakpoints.md}) {
      max-width: 100% !important;
    }
  }
`;
