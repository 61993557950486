import {
  DemographicLegends,
  DemographicsChartContainer,
  DemographicsWrapper,
  DashboardEmptyContainer as EmptyContainer,
  DemographicsScrollContainer as ScrollContainer,
} from "../Common/CommonHelpers";

import CardTitleBlock from "src/components/molecules/CardTitleBlock";
import Chart from "react-apexcharts";
import React from "react";
import ShadowBox from "src/components/atoms/ShadowBox";
import { barColors } from "src/styles/theme/styles";
import { faUniversity } from "@fortawesome/pro-regular-svg-icons";
import { getInstitutesDemographicQuery } from "src/graphql/institutes";
import { useI18n } from "src/i18n/hooks";
import { useQuery } from "@apollo/client";
import { useTheme } from "styled-components";

const useInstituteDemographics = () => {
  const { data, loading } = useQuery(getInstitutesDemographicQuery, {
    variables: {},
  });

  let labelArray: string[] = [];
  let labelData: number[] = [];
  let chartColors: string[] = [];

  if (data) {
    data.institutesDemographic
      .slice(-10)
      .forEach(
        (
          inst: { institute: { name: string }; students: number },
          indx: number
        ) => {
          inst.institute.name && labelArray.push(inst.institute.name);
          inst.students && labelData.push(inst.students);
          chartColors.push(barColors[indx % barColors.length]);
        }
      );
  }

  return { labelArray, labelData, chartColors, loading };
};

function RecentInstitutesDemographics() {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const { labelArray, labelData, chartColors, loading } =
    useInstituteDemographics();

  const barChartOptions = {
    stroke: {
      show: true,
      colors: ["transparent"],
      width: 2,
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    grid: { show: false },
    fill: { colors: chartColors },
    xaxis: {
      show: true,
      categories: labelArray,
      labels: {
        show: true,
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#a3a3a3"],
          fontSize: "15px",
          fontFamily: "Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: (value: any) => {
          return value === 0 ? value : value / 1000 + "k";
        },
      },
      axisTicks: { show: false },
    },
    yaxis: {
      show: false,
      labels: { show: false },
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        distributed: true,
        barHeight: "45px",
      },
    },
    chart: {
      id: "bar",
      toolbar: { show: false },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
        return `<div style="padding:4px 6px">  
          <span>
          ${w.globals.labels[dataPointIndex]} : 
         ${series[seriesIndex][dataPointIndex]}
          </span> 
          </div>`;
      },
    },
  };

  const series = [{ data: labelData }];

  return (
    <>
      <CardTitleBlock titleText={t("recentInstituteDemographics.label")} />
      <ShadowBox>
        <ScrollContainer>
          {labelData?.length > 0 ? (
            <DemographicsWrapper>
              <DemographicsChartContainer>
                <Chart
                  options={barChartOptions}
                  series={series}
                  type="bar"
                  width="100%"
                  height={rem(40)}
                />
              </DemographicsChartContainer>
              <DemographicLegends labelArray={labelArray} />
            </DemographicsWrapper>
          ) : (
            <EmptyContainer
              text={t("noInstituteDemographics.text")}
              icon={faUniversity}
              loading={loading}
            />
          )}
        </ScrollContainer>
      </ShadowBox>
    </>
  );
}

export default RecentInstitutesDemographics;
