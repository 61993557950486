import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BackHandler } from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import InputPassword from 'src/components/atoms/Input/Password/index.web';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import NormalTextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { isWeb } from 'src/constant/device';
import { validation } from 'src/constant/validation';
import { useHistory, useLocation, useParams } from 'src/routes/routing';
import { colors } from 'src/styles/theme/styles';
import { flushStorageFunction } from 'src/components/services/storage-service';
import { apolloClearStore } from 'src/graphql/client';
import { KEYS } from 'src/constant/key';
import { useResetPassword } from 'src/graphql/reset-password/index';
import Svg, { Path } from 'react-native-svg';
import { regexObject } from 'src/constant/regex';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ERROR } from 'src/constant';
import { useRoute } from '@react-navigation/native';
import navigationStrings from 'src/navigation/navigationStrings';
import AvoidKeyPad from 'src/components/atoms/Wrapper/AvoidKey';
import { Container, PageArea, RequestResetDiv, Center, MarginTop } from './StyledComponents'

export const ResetPassword = (props: any) => {
  const { navigation } = props;
  const { resetPassword } = useResetPassword();
  const [canClick, setCanClick] = useState(true);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const pathname = location.pathname;
  const route: any = isWeb ? {} : useRoute();

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', backActionHandler);
    return () => BackHandler.removeEventListener('hardwareBackPress', backActionHandler);
  }, []);

  const backActionHandler = () => {
    history.goBack();
    return true;
  };

  let { setAlertDetails } = useAlertSystem();

  const reset = async (resetPasswordData: any) => {
    clearGarbage();
    setCanClick(false);
    try {
      const { token } = params;
      const { password } = resetPasswordData;
      const reset = await resetPassword({ variables: { payload: { password, token } } });
      if (reset?.data?.resetPassword) {
        if (isWeb) {
          redirectWeb();
        } else {
          redirectNative();
        }
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  function redirectWeb() {
    if (pathname.includes('/employee')) {
      history.push('/employee/login');
    } else if (pathname.includes('/student')) {
      history.push('/');
    } else {
      history.push('/admin/login');
    }
  }

  function redirectNative() {
    if (route.name === navigationStrings.RESET_PASSWORD_EMPLOYEE) {
      navigation.navigate(navigationStrings.EMPLOYEE_LOGIN);
    } else if (route.name === navigationStrings.RESET_PASSWORD_STUDENT) {
      navigation.navigate(navigationStrings.STUDENT_LOGIN);
    } else {
      navigation.navigate(navigationStrings.USER_LOGIN);
    }
  }

  function clearGarbage() {
    flushStorageFunction(KEYS.STEPPER_STEPS);
    apolloClearStore();
  }

  return (
    <AvoidKeyPad
      extraHeight={200}
      style={{
        height: '90%',
        flex: 1,
      }}>
      <Container>
        <PageArea>
          <RequestResetDiv>
            <Center>
              <Svg
                width="128"
                height="40"
                viewBox="0 0 128 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <Path
                  d="M14.8333 40C22.2305 40 27.2138 36.4389 28.3818 30.9528L20.7121 30.4524C19.875 32.7045 17.7338 33.8787 14.9695 33.8787C10.8232 33.8787 8.19529 31.1646 8.19529 26.7565V26.7372H28.557V24.4851C28.557 14.437 22.4057 9.47065 14.5024 9.47065C5.70361 9.47065 0 15.6497 0 24.7738C0 34.1482 5.62575 40 14.8333 40ZM8.19529 21.6554C8.37049 18.2868 10.9595 15.5919 14.6386 15.5919C18.2399 15.5919 20.7316 18.1328 20.751 21.6554H8.19529Z"
                  fill="#2B78CA"
                />
                <Path
                  d="M44.9865 39.9038C49.8141 39.9038 52.3253 37.1511 53.4738 34.6872H53.8242V39.4225H62V0H53.7268V14.8219H53.4738C52.3642 12.4158 49.9699 9.47065 44.967 9.47065C38.4069 9.47065 32.859 14.514 32.859 24.6776C32.859 34.5717 38.1733 39.9038 44.9865 39.9038ZM47.6144 33.3782C43.546 33.3782 41.3268 29.7979 41.3268 24.6391C41.3268 19.5188 43.5071 15.9962 47.6144 15.9962C51.644 15.9962 53.902 19.3648 53.902 24.6391C53.902 29.9134 51.605 33.3782 47.6144 33.3782Z"
                  fill="#2B78CA"
                />
                <Path
                  d="M68 39.4415H76.1679V34.7039H76.5374C77.6848 37.169 80.1935 39.923 85.0164 39.923C91.823 39.923 97.1322 34.5884 97.1322 24.6895C97.1322 14.5209 91.5897 9.47522 85.0359 9.47522C80.0379 9.47522 77.6459 12.4218 76.5374 14.8291H76.2846V0H68V39.4415ZM76.1096 24.6509C76.1096 19.3741 78.3655 16.0039 82.3911 16.0039C86.4945 16.0039 88.6726 19.5282 88.6726 24.6509C88.6726 29.8122 86.4556 33.3943 82.3911 33.3943C78.4043 33.3943 76.1096 29.9278 76.1096 24.6509Z"
                  fill="#FE9E22"
                />
                <Path
                  d="M111.178 40C115.593 40 118.451 38.0934 119.91 35.3394H120.143V39.4415H128V19.4897C128 12.441 121.971 9.47522 115.32 9.47522C108.164 9.47522 103.457 12.8647 102.31 18.2571L109.972 18.8734C110.536 16.909 112.306 15.4646 115.281 15.4646C118.101 15.4646 119.715 16.8705 119.715 19.2971V19.4126C119.715 21.3192 117.673 21.5696 112.481 22.0703C106.569 22.6095 101.26 24.5739 101.26 31.1796C101.26 37.0342 105.48 40 111.178 40ZM125.102 0H106.161V4.44873H125.102V0ZM113.551 34.338C111.003 34.338 109.175 33.1632 109.175 30.91C109.175 28.5989 111.1 27.4627 114.017 27.0583C115.826 26.8079 118.782 26.3842 119.774 25.7294V28.8686C119.774 31.9692 117.187 34.338 113.551 34.338Z"
                  fill="#FE9E22"
                />
              </Svg>
            </Center>

            <Center style={{ marginTop: 28 }}>
              <HeaderThree value="Reset Password" lineHeight={32} />
            </Center>

            <Center>
              <NormalTextAtom value="Please create your secure password." />
            </Center>

            <MarginTop margin={24}>
              <Controller
                control={control}
                name={'newPassword'}
                render={({ field: { onChange, onBlur, ref } }) => (
                  <InputPassword
                    id={'resetnewpw'}
                    inputRef={ref}
                    header={'New Password'}
                    onBlur={onBlur}
                    label={''}
                    width={350}
                    setValue={onChange}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: validation.PASSWORD_REQUIRED,
                  },
                  pattern: {
                    value: regexObject.PASSWORD,
                    message: validation.PASSWORD_INVALID,
                  },
                }}
              />

              <MarginTop margin={24}>
                {errors.newPassword ? (
                  <SmallTextAtom value={errors.newPassword.message} color={colors.errorColor} />
                ) : null}
              </MarginTop>
            </MarginTop>

            <MarginTop margin={24}>
              <Controller
                control={control}
                name={'password'}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <InputPassword
                    id={'resetconfirmpw'}
                    inputRef={ref}
                    header={'Confirm Password'}
                    onBlur={onBlur}
                    label={''}
                    width={350}
                    setValue={onChange}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: validation.PASSWORD_REQUIRED,
                  },
                  validate: (value) => {
                    if (value === getValues()['newPassword']) {
                      return true;
                    } else {
                      return validation.PASSWORD_UNMATCH;
                    }
                  },
                }}
              />

              <MarginTop margin={24}>
                {errors.password ? (
                  <SmallTextAtom value={errors.password.message} color={colors.errorColor} />
                ) : null}
              </MarginTop>
            </MarginTop>

            <MarginTop margin={36}>
              <SecondaryBtn
                canLoad={!canClick}
                label={'Reset Password'}
                onPress={handleSubmit(reset)}
                style={{paddingVertical:8}}
                lineHeight={2.4}
              />
            </MarginTop>
          </RequestResetDiv>
        </PageArea>
      </Container>
    </AvoidKeyPad>
  );
};