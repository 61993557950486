import useTable from 'src/components/molecules/Table/UseTable';
import { NoRecordsFound, TableCells, TableRows } from 'src/components/molecules/Table/TableAtom';
import React from 'react';
import { TableBody } from '@mui/material';
import { colors } from 'src/styles/theme/styles';
import {
  TsubjectsData,
  atktHeadcells,
} from 'src/components/molecules/Assessment/Results/Helpers/helpers';
type TAtktModalprops = {
  subjectsData: TsubjectsData[];
};
export default function AtktModal(props: TAtktModalprops) {
  const { subjectsData } = props;
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    subjectsData,
    atktHeadcells,
  );
  return (
    <>
      <TblContainer>
        <TblHead />
        {recordsAfterPagingAndSorting()?.length > 0 ? (
          <TableBody>
            {recordsAfterPagingAndSorting().map((item: TsubjectsData, index: number) => (
              <TableRows key={index}>
                <TableCells value={item?.subject?.name ?? '-'} style={{ borderBottom: 'none' }} />
                <TableCells
                  value={item?.marksObtained ?? '-'}
                  style={{ borderBottom: 'none' }}
                  color={item?.failedHeads && colors.red}
                />
                <TableCells value={item?.passingMarks ?? '-'} style={{ borderBottom: 'none' }} />
                <TableCells value={item?.totalMarks ?? '-'} style={{ borderBottom: 'none' }} />
              </TableRows>
            ))}
          </TableBody>
        ) : (
          <NoRecordsFound colspan={6} maxHeight={50} />
        )}
      </TblContainer>
    </>
  );
}
