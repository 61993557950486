import {
  DashboardEmptyContainer as EmptyContainer,
  DemographicsScrollContainer as ScrollContainer,
} from "src/components/organism/Dashboard/Common/CommonHelpers";

import { ANNOUNCEMENT_TYPE } from "src/constant";
import { DashboardListing } from "./CommonHelpers";
import { NewsItem } from "src/components/atoms/NewsItem";
import { NoticeItemWithModal } from "src/components/molecules/NoticeList";
import React from "react";
import { faCommentAltDots } from "@fortawesome/pro-light-svg-icons";
import { getAnnouncementsQuery } from "src/graphql/communication/news-notices";
import { useI18n } from "src/i18n/hooks";
import { useQuery } from "@apollo/client";

function DashboardNews() {
  const { t } = useI18n();
  const { data, loading } = useQuery(getAnnouncementsQuery, {
    variables: { filters: { type: { eq: ANNOUNCEMENT_TYPE[0] } } },
  });

  const ListEmptyComponent = () => (
    <EmptyContainer
      text={t("noNews.text")}
      icon={faCommentAltDots}
      loading={loading}
    />
  );

  const news = data?.getAnnouncements?.data ?? [];

  return (
    <DashboardListing
      title={"news.label"}
      redirectionURL={"/communication/news"}
      allowRedirection={news.length > 0}
    >
      <ScrollContainer>
        {news.length > 0 ? (
          news.map((item) => (
            <NoticeItemWithModal
              children={<NewsItem newsDetail={item} />}
              item={item}
              type="NEWS"
            />
          ))
        ) : (
          <ListEmptyComponent />
        )}
      </ScrollContainer>
    </DashboardListing>
  );
}

export default DashboardNews;
