import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../styles/theme/styles';
import List from 'src/components/atoms/AttendanceList';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { height } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import AttendanceNotConfigured from 'src/components/organism/Attendance/AttendanceNotConfigured';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import IndicatorBadge from 'src/components/atoms/Badge/IndicatorBadge';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import LeaveCalendar from 'src/components/atoms/Calendars/LeaveCalendar';
import { parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { generateMarkedDatesObject } from 'src/components/atoms/Calendars/CalendarHelpers';
import { filterAbsentDates } from './AttendanceHelpers';

const StudentAttendanceChild = (props: any) => {
  const { t } = useI18n();
  const {
    selectedAttendenceType,
    renderlistdata,
    slotSelector,
    selectedSlot,
    calendarDataForLeave,
    dates,
  } = props;

  const [showLeavesBanner, setShowLeavesBanner] = useState<boolean>(false);

  useEffect(() => {
    if (calendarDataForLeave?.length > 0) {
      setShowLeavesBanner(true)
    }
  }, [calendarDataForLeave])

  return (
    <AttendanceHistoryWrapper>
      {selectedAttendenceType.value !== 0 && (
        <AttendanceList>
          <HeaderWrapper>
            <HeaderFive value={selectedAttendenceType.value === 2 ? t('subjects.label') : t('slots.label')} />
          </HeaderWrapper>
          {!renderlistdata ? (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          ) : (
            <ListWrapper>
              <List
                data={renderlistdata}
                selectedSlot={selectedSlot}
                slotSelector={slotSelector}
                type={1}
              />
            </ListWrapper>
          )}
        </AttendanceList>
      )}

      <Wrapper>
        <TitleRow>
          <HeaderFive value={t("leaveCalendar.label")} />
          {showLeavesBanner &&
            <CalendarInfo>
              <IndicatorBadge color={colors.red} diameter={12} />
              <NormaltextAtom value={t("leaves.label")} />
            </CalendarInfo>}
        </TitleRow>
        <MainCalendar marginTop={!calendarDataForLeave || calendarDataForLeave.length === 0 ? "0px" : "26px"}>
          {!calendarDataForLeave ? (
            <SpinnerWrapper
              height={!calendarDataForLeave || calendarDataForLeave.length === 0 ? "303px" : "253px"}>
              <LoaderSpinner />
            </SpinnerWrapper>
          ) : calendarDataForLeave.length === 0 ? (
            <AttendanceNotConfiguredContainer>
              <AttendanceNotConfigured topPosition="26.55%" type={1} />
            </AttendanceNotConfiguredContainer>
          ) : (
            calendarDataForLeave &&
            calendarDataForLeave?.length === 1 &&
            calendarDataForLeave[0]?.data &&
            dates?.startDate && (
              <LeaveCalendarWrapper>
                <LeaveCalendar
                  initialDate={parse(dates.startDate, DT.DATE_FORMAT_SLASH, new Date())}
                  markedDates={generateMarkedDatesObject(
                    filterAbsentDates(calendarDataForLeave[0].data)
                  )}
                />
              </LeaveCalendarWrapper>
            )
          )}
        </MainCalendar>
      </Wrapper>
    </AttendanceHistoryWrapper >
  );
};
export default StudentAttendanceChild;

const AttendanceHistoryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  //   overflow-y: auto;
`;
const AttendanceList = styled.div`
  flex: 50%;
  max-width: 50%;
  box-sizing: border-box;
  padding-right: 2rem;
`;
const ListWrapper = styled.div`
  overflow-y: auto;
  height: ${height - 400};
`;

const Wrapper = styled.div`
  flex: 50%;
  max-width: 500px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const MainCalendar = styled.div<{ marginTop: string }>`
  height: ${({ marginTop }) => marginTop};
`;

const LeaveCalendarWrapper = styled.div``;

const HeaderWrapper = styled.div`
  margin-bottom: 24px;
`;

const CalendarInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const SpinnerWrapper = styled.div<{ height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height = "325px" }) => height};
`;

const AttendanceNotConfiguredContainer = styled.div`
  height: 304px;
`;
