import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { View } from 'react-native';
import { styled } from '@mui/material/styles';
import SmallTextAtom from '../Text/SmallTextAtom';
import { colors } from 'src/styles/theme/styles';

const BootstrapInput = styled(Select)`
 .MuiSelect-select {
  border: 1px solid ${colors.lighterBorder};
  padding: 8px 26px 7px 12px;
 }
 .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
 }
`;

export default function Dropdowns(props: any) {
  const {
    variant = 'outlined',
    value = '',
    handleChange = null,
    header = '',
    options = [],
    field,
    hasError = false,
    disabled = false,
  } = props;
  
  return (
    <>
      {header !== '' ? (
        <View style={{ marginBottom: 4 }}>
          <SmallTextAtom value={header} />
        </View>
      ) : null}

      <FormControl disabled={disabled} fullWidth>
        <BootstrapInput
          error={hasError}
          value={value}
          {...field}
          variant={variant}
          size='small'
          >
          {options.map((opt:any, index:number) => (
            <MenuItem
              value={opt.value}
              key={index}
              onClick={(event) => handleChange(event.currentTarget.dataset.value)}>
              {opt.label}
            </MenuItem>
          ))}
        </BootstrapInput>
      </FormControl>
    {/* </ContainerView>
     */}
     </>
  );
}

