import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import {
  Notifications,
  Assignment,
  StudentDetails,
  StudentList,
  Events,
  Attendance,
  AttendanceStudents,
  StudentAndClassSlotsAttendanceComponent,
  StudentsForClassAttendanceListComponent,
  StudentAttendanceNative,
  ProfileView,
  MultiAccount,
  Payment,
  PaymentReceipt,
  AcceptPayment,
  Scanner,
  Messages,
  ProfileDetailView,
  RequestResetPassword,
  AcceptPaymentApplicant,
  ApplicationInformation,
  ApplicationPayableListing,
  ApplicantionsListingNative,
  ApplicationFormViewer,
  EducationDetails,
  Admissions,
  RedirectionScreen,
  AcceptOtherPayment,
  StudentResult,
  StudentResultDetail,
} from './index';
import navigationStrings from 'src/navigation/navigationStrings';
import DashboardTabs from 'src/navigation/Tabbar';
import { USER_TYPE, USER_TYPE_ENUM } from 'src/constant';
import { NativePrivateRoute } from 'src/routes/native-private-route';
import StudentPaymentStatus from 'src/components/molecules/Payment/Institute/StudentPaymentStatus.native';
import AdminStudentList from 'src/components/organism/Students/AdminStudentList.native';
import { ViewMode } from 'src/components/molecules/Forms/types';
import ApplicationFeePayment from 'src/components/organism/Applications/ApplicationFeePayment';
const Stack = createStackNavigator();

function StackNavigator(prop: any) {
  return (
    <Stack.Navigator initialRouteName={navigationStrings.NATIVE_DASHBOARD}>
      <Stack.Screen
        name={navigationStrings.NATIVE_DASHBOARD}
        component={DashboardTabs}
        options={{
          headerShown: false,
          title: 'Dashboard',
        }}
        initialParams={{ statusbar: 'light-content' }}
      />
      <Stack.Screen
        name={navigationStrings.NATIVE_PROFILE_DETAILS}
        initialParams={{ statusbar: 'light-content' }}
        options={{
          headerShown: false,
        }}>
        {(props) => {
          return <NativePrivateRoute
            component={ProfileDetailView}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            props={props}
          />
        }}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.NATIVE_EDU_EXP_DETAIL}
        initialParams={{ statusbar: 'light-content' }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={ProfileDetailView}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.NATIVE_TABBAR_PROFILE}
        initialParams={{ statusbar: 'light-content' }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={ProfileView}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.NOTIFICATIONS}
        initialParams={{ statusbar: 'light-content' }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={Notifications}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            props={props}
          />
        )}
      </Stack.Screen>

      <Stack.Screen
        name={navigationStrings.NATIVE_ASSIGNMENT_DETAIL}
        options={{
          headerShown: false,
        }}
        initialParams={{ assignmentId: '', statusbar: 'light-content' }}>
        {(props) => (
          <NativePrivateRoute
            component={Assignment}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
            subAuth={['TEACHING']}
            props={props}
          />
        )}
      </Stack.Screen>

      <Stack.Screen
        name={navigationStrings.NATIVE_STUDENT_DETAIL}
        options={{
          headerShown: false,
        }}
        initialParams={{ assignmentId: '', studentId: '', statusbar: 'light-content' }}>
        {(props) => (
          <NativePrivateRoute
            component={StudentDetails}
            authUser={[USER_TYPE.EMPLOYEE]}
            subAuth={['TEACHING']}
            props={props}
          />
        )}
      </Stack.Screen>

      <Stack.Screen
        name={navigationStrings.NATIVE_ASSIGNMENT_STUDENT_LIST}
        options={{
          headerShown: false,
        }}
        initialParams={{ assignmentId: '', statusbar: 'light-content' }}>
        {(props) => (
          <NativePrivateRoute
            component={StudentList}
            authUser={[USER_TYPE.EMPLOYEE]}
            subAuth={['TEACHING']}
            props={props}
          />
        )}
      </Stack.Screen>

      <Stack.Screen
        name={navigationStrings.EVENTS}
        initialParams={{ eventId: '', eventType: 'upcoming', statusbar: 'light-content' }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={Events}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
            props={props}
          />
        )}
      </Stack.Screen>

      <Stack.Screen
        initialParams={{ statusbar: 'light-content' }}
        name={navigationStrings.ATTENDANCE}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={Attendance}
            //             authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
            authUser={[USER_TYPE.EMPLOYEE]}
            subAuth={['TEACHING']}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.NATIVE_ATTENDANCE_STUDENTS}
        initialParams={{
          classInfo: null,
          division: '',
          attendanceType: null,
          selectedSlotInfo: null,
          currentDate: '',
          statusbar: 'light-content',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={AttendanceStudents}
            authUser={[USER_TYPE.EMPLOYEE]}
            subAuth={['TEACHING']}
            props={props}
          />
        )}
      </Stack.Screen>
      {/* <Stack.Screen
        name={navigationStrings.NATIVE_TABBAR_NEWS_NOTICES}
        options={{
          headerShown: false,
        }}
        component={NewsNotices}
        initialParams={{ communicationType: 'news', newsnoticeId: '' }}
      /> */}
      {/* 0 */}
      <Stack.Screen
        name={navigationStrings.ATTENDANCE_HISTORY}
        initialParams={{
          classInfo: null,
          division: null,
          attendanceType: null,
          audienceType: null,
          selectedDurationType: null,
          selectedStudentInfo: null,
          slotInfo: null,
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={StudentAndClassSlotsAttendanceComponent}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
            subAuth={['TEACHING']}
            props={props}
          />
        )}
      </Stack.Screen>
      {/* 1 */}
      <Stack.Screen
        name={navigationStrings.CLASS_STUDENTS_ATTENDANCE_HISTORY}
        initialParams={{
          batch: null,
          division: null,
          attendanceType: null,
          audienceType: null,
          selectedDurationType: null,
          selectedStudentInfo: null,
          slotInfo: null,
          totalPresents: 0,
          totalAttendance: 0,
          datesinfo: null,
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={StudentsForClassAttendanceListComponent}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT]}
            subAuth={['TEACHING']}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.STUDENT_ATTENDANCE}
        initialParams={{
          statusbar: 'light-content',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={StudentAttendanceNative}
            authUser={[USER_TYPE.STUDENT]}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.MULTI_ACCOUNT}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'light-content' }}>
        {(props) => (
          <NativePrivateRoute
            component={MultiAccount}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.PAYMENT}
        initialParams={{
          statusbar: 'light-content',
          payment_status: 'pending',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute component={Payment} authUser={[USER_TYPE.STUDENT]} props={props} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.ACCEPT_PAYMENT}
        initialParams={{
          statusbar: 'light-content',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute component={AcceptPayment} authUser={[USER_TYPE.EMPLOYEE]} props={props} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.SCANNER}
        initialParams={{
          statusbar: 'light-content',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute component={Scanner} authUser={[USER_TYPE.EMPLOYEE]} props={props} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.PAYMENT_RECEIPT}
        initialParams={{
          statusbar: 'light-content',
          localPath: '',
          receiptId: '',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={PaymentReceipt}
            authUser={[USER_TYPE.STUDENT]}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.NATIVE_TABBAR_STUDENTS}
        initialParams={{
          statusbar: 'light-content',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={StudentPaymentStatus}
            authUser={[USER_TYPE.EMPLOYEE]}
            subAuth={['ADMIN']}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.STUDENT_LIST}
        initialParams={{
          statusbar: 'light-content',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute
            component={AdminStudentList}
            authUser={[USER_TYPE.EMPLOYEE]}
            subAuth={['ADMIN']}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.MESSAGES}
        options={{
          headerShown: false,
        }}
        initialParams={{ messageType: 'inbox', statusbar: 'light-content' }}>
        {(props) => (
          <NativePrivateRoute
            component={Messages}
            authUser={[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT]}
            props={props}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.ACCEPT_PAYMENT_APPLICANT}
        initialParams={{
          statusbar: 'light-content',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute component={AcceptPaymentApplicant} authUser={[USER_TYPE.EMPLOYEE]} props={props} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={navigationStrings.ACCEPT_OTHER_PAYMENT}
        initialParams={{
          statusbar: 'light-content',
        }}
        options={{
          headerShown: false,
        }}>
        {(props) => (
          <NativePrivateRoute component={AcceptOtherPayment} authUser={[USER_TYPE.EMPLOYEE]} props={props} />
        )}
      </Stack.Screen>
      {/* navigate to forgot password screens from add account (multi) modal */}
      <Stack.Screen
        name={navigationStrings.REQUEST_RESET_PASSWORD}
        component={RequestResetPassword}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.APPLICATION_INFORMATION}
        component={ApplicationInformation}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.APPLICATON_PAYABLE_LISTING}
        component={ApplicationPayableListing}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.APPLICATION_LISTING}
        component={ApplicantionsListingNative}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.APPLICATION_FORM_VIEWER}
        component={ApplicationFormViewer}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.EDUCATION_DETAILS}
        component={EducationDetails}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.APPLICATION_FEE_PAYMENT}
        component={ApplicationFeePayment}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.NATIVE_ADMISSIONS_LIST}
        component={Admissions}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.REDIRECTION_SCREEN}
        component={RedirectionScreen}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.NATIVE_RESULTS_LIST}
        component={StudentResult}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
      <Stack.Screen
        name={navigationStrings.NATIVE_RESULT_DETAIL}
        component={StudentResultDetail}
        options={{
          headerShown: false,
        }}
        initialParams={{ statusbar: 'dark-content' }}
      />
    </Stack.Navigator>
  );
}

export default StackNavigator;