import { readCacheQuery, writeCacheQuery } from '../util';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FILTERS, limit } from 'src/constant/index';
import { createDeleteData, createSearchFilter } from '../../components/services/index';
import { getCourseFilter } from 'src/components/services/filters';

const addCourseMutation = gql`
  mutation ($payload: CreateCourseInput!) {
    createCourse(payload: $payload) {
      id
      name
      alias
      code
      duration
      durationType
      university {
        id
        name
      }
      stream {
        id
        name
      }
    }
  }
`;

const updateCourseMutation = gql`
  mutation ($payload: UpdateCourseInput!) {
    updateCourse(payload: $payload) {
      id
      name
      alias
      code
      duration
      durationType
      university {
        id
        name
      }
      stream {
        id
        name
      }
    }
  }
`;

const updateInstituteCourseMutation = gql`
  mutation ($payload: LocalizationAcademicInput!) {
    updateInstituteCourse(payload: $payload) {
      id
      name
      alias
    }
  }
`;

const deleteCourseMutation = gql`
  mutation ($id: String!) {
    deleteCourse(id: $id) {
      status
      message
    }
  }
`;

// U can use this also for all limit = 0 skip = 0
const getCoursesQuery = gql`
  query (
    $limit: Int
    $skip: Int
    $filters: CourseFilterInput
    $sort: CourseSortInput
    $lookup: LookupFilter
  ) {
    courses(limit: $limit, skip: $skip, filters: $filters, sort: $sort, lookup: $lookup) {
      hasNextPage
      totalCount
      data {
        id
        name
        alias
        code
        duration
        durationType
        university {
          id
          name
        }
        stream {
          id
          name
        }
      }
    }
  }
`;

const getInstituteCoursesQuery = gql`
   query ($limit: Int, $skip: Int, $filters: CourseFilterInput!) {
     courses(limit: $limit, skip: $skip, filters: $filters) {
       hasNextPage
       totalCount
       data {
         id
         alias
         name
         code
         duration
         university {
           name
           alias
           id
         }
       }
     }
   }
 `;

const getCourseAliasQuery = gql`
  query ($limit: Int, $skip: Int, $filters: CourseFilterInput!) {
    courses(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        id
        name: alias
      }
    }
  }
`;

const getAllCourseQuery = gql`
  query ($limit: Int, $skip: Int) {
    courses(limit: $limit, skip: $skip) {
      hasNextPage
      totalCount
      data {
        value: id
        label: name
      }
    }
  }
`;

const searchCourseQuery = gql`
  query ($limit: Int, $skip: Int, $filters: CourseFilterInput) {
    courses(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        value: id
        label: alias
      }
    }
  }
`;

const getModifiedCoursesQuery = gql`
  query ($limit: Int, $skip: Int) {
    courses(limit: $limit, skip: $skip) {
      hasNextPage
      totalCount
      data {
        value: id
        label: alias
        name: name
      }
    }
  }
`;

const getCourseDemographicQuery = gql`
  query {
    courseDemographic {
      course {
        name
      }
      students
    }
  }
`;

const createCourseSettingsMutation = gql`
  mutation ($payload: CreateCourseSettingInput!) {
    createCourseSetting (payload: $payload) {
      id
    }
  }
`;

const getCourseSettingsQuery = gql`
  query ($course: String!, $institute: String!) {
    courseSetting (course: $course, institute: $institute) {
      id
      grNoPrefix
      startingGRNo
    }
  }
`;

export {
  getCoursesQuery,
  addCourseMutation,
  updateCourseMutation,
  getModifiedCoursesQuery,
  getAllCourseQuery,
  getCourseAliasQuery,
  searchCourseQuery,
  getCourseDemographicQuery,
  updateInstituteCourseMutation,
  getInstituteCoursesQuery,
  getCourseSettingsQuery,
};

export function useMutateCourse(queries: any) {
  const [mutate, { error }] = useMutation(queries);
  return { mutate, error };
}

export function useQueryCourse(queries: any) {
  const [query, { called, error, loading, data }] = useLazyQuery(queries);
  return { query, data, error };
}

export function useQueryAllCourse() {
  const [query, { called, error, loading, data }] = useLazyQuery(getAllCourseQuery);
  return { query, data, error };
}

export function useUpdateCourse() {
  const [mutate, { data, error }] = useMutation(updateCourseMutation);
  return { mutate, data, error };
}

export function useUpdateInstituteCourse() {
  const [UpdateInstituteCourse, { data, error }] = useMutation(updateInstituteCourseMutation);
  return { UpdateInstituteCourse, data, error };
}

export function useAddCourse(courseFilter: any) {
  const [mutate, { data, error }] = useMutation(addCourseMutation, {
    update: (cache, { data: { createCourse } }) => {
      let queryData;
      try {
        queryData = readCacheQuery(cache, getCoursesQuery, courseFilter);
      } catch (e) {
        console.log(e.messgae);
      }
      let existingCourses = queryData?.courses.data;
      //write
      let mergeArray = [createCourse, ...existingCourses];
      let finaldata = {
        data: mergeArray,
        hasNextPage: queryData?.courses?.hasNextPage,
        totalCount: queryData?.courses?.totalCount + 1,
        __typename: queryData?.courses?.__typename,
      };

      try {
        writeCacheQuery(cache, getCoursesQuery, courseFilter, {
          courses: finaldata,
        });
      } catch (e) {
        //console.log('error', e.messgae);
      }
    },
  });
  return { mutate, data, error };
}

// export function useModifiedCourses() {
//   const [getModifiedCourses, { loading, data: courseModifiedData, error: courseModifyError }] =
//     useLazyQuery(getModifiedCoursesQuery);
//   return { getModifiedCourses, courseModifiedData, courseModifyError };
// }

// export function useSearchCourse() {
//   const [searchCourse, { loading, data: searchCourseData, error: searchCouseError }] =
//     useLazyQuery(searchCourseQuery);
//   return { searchCourse, searchCourseData, searchCouseError };
// }

export function useDeleteCourse(
  page: number,
  searchValue: string,
  rowLimit: number,
  courseId: string,
) {
  const [deleteCourse, { error: deleteCourseError, data: deleteCourseData }] = useMutation(
    deleteCourseMutation,
    {
      update: (cache, { data: { deleteCourse } }) => {
        let queryData;
        let filters = getCourseFilter(searchValue);
        let courseFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
        //read
        try {
          queryData = readCacheQuery(cache, getCoursesQuery, courseFilter);
        } catch (e) {
          //console.log(e.messgae);
        }
        let courseObject = queryData?.courses;
        let message = deleteCourse.message;
        // let courseId = message.replace("Successfully deleted course for ", "");
        let dataToUpdate = createDeleteData(courseObject, courseId);
        //write
        try {
          writeCacheQuery(cache, getCoursesQuery, courseFilter, {
            courses: dataToUpdate,
          });
        } catch (e) {
          //console.log('error', e.messgae);
        }
      },
    },
  );

  return { deleteCourse, deleteCourseError, deleteCourseData };
}

export function useCreateCourseSettings() {
  const [createCourseSettings, { data: createCourseSettingsData, error: createCourseSettingsError }] =
    useMutation(createCourseSettingsMutation);
  return { createCourseSettings, createCourseSettingsData, createCourseSettingsError };
}

export function useGetCourseSettings() {
  const [getCourseSettings, { loading, data, refetch }] = useLazyQuery(
    getCourseSettingsQuery,
    { fetchPolicy: "network-only" }
  );
  return { getCourseSettings, data, loading, refetch };
}