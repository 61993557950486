import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { colors, fonts } from 'src/styles/theme/styles';

interface Props {
  value: any;
  color?: string;
  fontWeight?: any;
  fontSize?: string | number;
  lineHeight?: number;
  textAlign?: string;
  width?:  string;
}
export default function HeaderOne(props: Props) {
  const { 
    value = '', 
    fontSize = '40px', 
    color = colors.primaryText, 
    fontWeight = 'normal', 
    width = '100%',
    textAlign = 'center',
  } = props;

  // Using the destructured props in the component's style
  const style = {
    fontSize: fontSize,
    color: color,
    fontWeight: fontWeight,
    textAlign: textAlign,
  };

  return (
    <HeaderText style={style} width={width}>
      {value}
    </HeaderText>
  );
}

HeaderOne.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
};

const HeaderText = styled.Text<{ width: string }>`
  font-family: ${fonts.medium};
  color: ${colors.black};
  opacity: 80;
  width: ${(props) => props?.width};
  margin-bottom: 8px;
`;
