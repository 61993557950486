/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { useParams } from 'react-router-dom';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { getStorageFunction } from 'src/components/services/storage-service';
import { limit } from 'src/constant';
import { height } from 'src/constant/device';
import { useHeaderTitle } from 'src/contexts/header-context';
import { getFeeCompositionsQuery, useQueryFeeComposition, useQueryFeeCompositionById } from 'src/graphql/payment';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import { clipLongText, sortModuleData } from 'src/utils/utility';
import { createFeeCompositionStructure } from 'src/utils/utility';
import styled from 'styled-components/native';

const CompositionDetails = (props: any) => {
  const { compositionId }: any = useParams();
  const { setHeading } = useHeaderTitle();
  const { t } = useI18n();
  const searchValue = '' as string;
  const [moduleData, setModuleData] = useState([]);
  const {
    getFeeCompositionById,
    feeComposition,
    loading: feeCompositionLoading,
  } = useQueryFeeCompositionById();
  const data = feeComposition?.getFeeCompositions?.data[0];

  const headers = [
    {
      name: t('moduleGroupName.label'),
    },
    {
      name: t('amount.label').toUpperCase(),
    },
  ];

  useEffect(() => {
    setTitle();
    const feeCompData = createFeeCompositionStructure(feeComposition?.getFeeCompositions?.data);
    if (feeCompData[0]?.feeBooks && feeCompData[0]?.feeBooks?.length > 0) {
      feeCompData[0].feeBooks = sortModuleData(feeCompData[0]?.feeBooks, 'groupInfo');
    }
    setModuleData(feeCompData);
  }, [data]);

  useEffect(() => {
    handleFetch();
  }, [searchValue]);

  async function setTitle() {
    setHeading([
      {
        text: t('payment.label'),
        url: '/control-panel/payments',
      },
      {
        text: t('feesComposition.label'),
        url: `/control-panel/payments/fees-composition/limit/${limit}/page/1`,
      },
      {
        text: clipLongText(data?.name, 35)
      }
    ]);
  }

  function handleFetch() {
    getFeeCompositionById({ variables: { limit: 50, skip: 0, id: compositionId.toString() }});
  }

  return (
    <LoadContentWrapper>
      {!feeCompositionLoading ? (
      <View>
        <MainHeaderWrapper>
          <MainHeader>
            <NormaltextAtom value={t('compositionName.label')} lineHeight={2} />
            <MediumTextSelect
              value={clipLongText(data?.name, 80)}
              color={colors.primaryText}
              fontFamily={fonts.semibold}
            />
          </MainHeader>
          <MainHeader>
            <NormaltextAtom value={t('batchNames.label')} lineHeight={2} />
            <MediumTextSelect
              value={moduleData[0]?.batches?.length > 0 ? moduleData[0]?.batches.map((batch: any, i: any) => {
                return batch.toUpperCase() + `${i !== moduleData[0].batches.length - 1 ? ', ' : '' }`
              }) : '-'}
              color={colors.primaryText}
              fontFamily={fonts.semibold}
            />
          </MainHeader>
          <MainHeader>
            <NormaltextAtom
              value={t('accountName.label')} lineHeight={2}
              lineHeight={2}
            />
            <MediumTextSelect
              value={data?.bankAlias}
              color={colors.primaryText}
              fontFamily={fonts.semibold}
            />
          </MainHeader>
        </MainHeaderWrapper>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ marginRight: 80, flex: 1.3, maxWidth: 608 }}>
            <MediumTextSelect
              value={t('modules.label')}
              fontSize={2.4}
              lineHeight={3.2}
              fontFamily={fonts.semibold}
            />
            <HeadersWrapper>
              {headers.map(({ name }, index: number) => (
                <TableColHeadWrapper index={index}>
                  <TableColHead value={name} />
                </TableColHeadWrapper>
              ))}
            </HeadersWrapper>
            <SingleLine marginBottom={10} />
            <FlatList
              data={moduleData.length > 0 && moduleData[0].feeBooks}
              contentContainerStyle={{
                flex: 1,
                maxHeight: height - 450,
                minHeight: height - 650,
              }}
              keyExtractor={(item: any) => item.id}
              renderItem={({ item, index }: any) => {
                return (
                <View
                  style={{ marginBottom: data?.feeBooks.length - 1 === index ? 10 : 24 }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      paddingHorizontal: 20,
                    }}>
                    <MainStartElement>
                      <MediumTextSelect value={item?.groupName} color={colors.primaryText} />
                    </MainStartElement>
                    <MainElement>
                      <MediumTextSelect
                        value={`\u20B9 ${item?.amount ? item.amount : '-'}`}
                        fontFamily={fonts.semibold}
                        color={colors.primaryText}
                      />
                    </MainElement>
                  </View>
                  <View
                    style={{
                      paddingHorizontal: 20,
                    }}>
                      {item?.groupName !== item.groupInfo[0].name && item.groupInfo.map((groupInfo: any) => (
                        <View style={{ flexDirection: 'row', marginTop: 24 }}>
                          <ComponentsStartElement>
                            <NormalTextSelect
                              value={groupInfo.name}
                              style={{ marginLeft: 33 }}
                              color={colors.secondaryText}
                            />
                          </ComponentsStartElement>
                          <ComponentsElement>
                            <NormalTextSelect value={`\u20B9 ${groupInfo.amount}`} color={colors.secondaryText} />
                          </ComponentsElement>
                        </View>
                      ))}
                  </View>
                </View>
              )}}
            />
            <SingleLine />
            <FooterWrapper>
              <ComponentsStartElement>
                <NormalTextSelect value={t('totalPayment.label')} />
              </ComponentsStartElement>
              <ComponentsElement>
                <NormalTextSelect value={`\u20B9 ${moduleData[0]?.totalPayment}`} />
              </ComponentsElement>
            </FooterWrapper>
            <SingleLine />
          </View>

          {data?.payerType === 'INDIVIDUAL' ? (
            <View style={{ flex: 1 }}>
              <MediumTextSelect
                value={t('students.label')}
                fontSize={2.4}
                lineHeight={3.2}
                fontWeight={'600'}
              />
              <FlatList
                data={moduleData.length > 0 && moduleData[0]?.payers}
                contentContainerStyle={{
                  flex: 1,
                  maxHeight: height - 450,
                }}
                keyExtractor={(item: any) => item.id}
                renderItem={({ item, index }: any) => (
                  <>
                    <View style={{ padding: 16 }}>
                      <MediumTextSelect
                        value={index + 1 + '. ' + item.firstName + ' ' + item.lastName}
                        fontSize={1.6}
                        lineHeight={2.4} />
                    </View>
                    {index !== moduleData[0]?.payers.length - 1 ?<SingleLine /> : null }
                  </>
                )}
              />
            </View>
          ) :  null}
        </View>
      </View>
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </LoadContentWrapper>
  );
};

const MainElement = styled.View`
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;
const MainStartElement = styled.View`
  flex: 1.5;
  justify-content: center;
  align-items: flex-start;
`;

const ComponentsElement = styled.View`
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;
const ComponentsStartElement = styled.View`
  flex: 1.5;
  justify-content: center;
  align-items: flex-start;
`;
const SingleLine = styled.View`
  border-bottom-width: 1;
  border-bottom-color: ${colors.dividerColor};
  margin-bottom: ${(props: any) => (props.marginBottom ? props.marginBottom : 0)};
`;

const FooterWrapper = styled.View`
  flex-direction: row;
  padding-horizontal: 20;
  margin-vertical: 18;
`;

const TableColHeadWrapper = styled.View`
  flex: ${(props: any) => (props.index === 0 ? 1.5 : 1)};
  justify-content: center;
  align-items: ${(props: any) => (props.index === 0 ? 'flex-start' : 'flex-end')};
  margin-right: ${(props: any) => (props.index !== 0 && '22')};
`;

const HeadersWrapper = styled.View`
  margin-top: 16;
  flex-direction: row;
  margin-bottom: 10;
  marginhorizontal: 20;
`;

const MainHeader = styled.View`
  margin-right: 100;
`;
const MainHeaderWrapper = styled.View`
  flex-direction: row;
  margin-bottom: 32;
  margin-top: 14;
`;
const SpinnerView = styled.View`
  height: 547;
  justify-content: center;
  align-items: center;
`;

export default CompositionDetails;
