import React from 'react';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';

interface Props {
  value: any;
  color?: string;
  fontWeight?: any;
  lineHeight?: number;
  fontFamily?: string;
  lines?: number;
  fontSize?: number;
  textAlign?: string;
  style?: any;
  id?:string;
}

export default function MediumTextSelect(props: Props) {
  const {
    value = '',
    color = colors.secondaryText,
    fontWeight = 'bold',
    lineHeight = 2.4,
    fontFamily = fonts.regular,
    lines,
    fontSize = 1.6,
    textAlign = 'left',
    style,
    id
  } = props;

  const Text = styled.Text`
    font-family: ${fontFamily};
    color: ${color};
    font-style: normal;
    font-weight: ${fontWeight};
    font-size: ${(props) => props.theme.rem(fontSize)};
    line-height: ${(props) => props.theme.rem(lineHeight)};
    flex-shrink: 1;
    text-align: ${textAlign};
  `;

  return (
    <Text id={id} numberOfLines={lines} allowFontScaling={false} style={style ? style : {}}>
      {value}
    </Text>
  );
}
