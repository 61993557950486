import { FormBaseType, ViewMode } from 'src/components/molecules/Forms/types';
import { height, width } from 'src/constant/device';

import { ApplicationItemType } from './types';
import CreateInfoItem from 'src/components/molecules/TextComponents/InfoItem';
import HeaderFour from 'src/components/atoms/Text/HeaderFour';
import React from 'react';
import UserDetails from 'src/components/molecules/UserDetails';
import styled from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import { userInformation } from 'src/utils/manageState';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { ApplicationStatus, ApplicationStatusLabel } from 'src/constant';
import { getApplicationStatusColor } from 'src/components/atoms/FunctionsList';
import { checkRemark } from 'src/utils/utility';

interface PropType {
  data: ApplicationItemType | null;
}

export const DisplayApplicationDetails = ({ data }: PropType) => {
  const { t } = useI18n();
  const currentUserObject = userInformation();
  const { userId, userType }: any = currentUserObject;
  const applId = data?.id;
  const rowData = data?.educationDetails?.map((e) => e?.educationDetail) ?? [];
  const itemWidth = (0.8 * width) / 3;

  return (
    <Container>
      {data ? (
        <>
          <ApplicationDetails>
            <CreateInfoItem
              label={t('university.label')}
              value={data.admission.university.name}
              width={itemWidth}
            />
            <CreateInfoItem
              label={t('institute.label')}
              value={data.admission.institute.name}
              width={itemWidth}
            />
            <CreateInfoItem
              label={t('course.label')}
              value={data.admission.course.alias}
              width={itemWidth}
            />
            <CreateInfoItem
              label={t('class.label')}
              value={data.admission.class.alias}
              width={itemWidth}
            />
            <CreateInfoItem
              label={t('subjectGroup.label')}
              value={data.subjectGroup.name}
              width={itemWidth}
            />
            <CreateInfoItem
              label={t('applicantType.label')}
              value={data.applicantType}
              width={itemWidth}
            />
            {data?.cap && (
              <CreateInfoItem label={t("cap-id.label")} value={data.cap.capId} width={itemWidth} />
            )}
            <CreateInfoItem
              label={t('status.label')}
              value={ApplicationStatusLabel[data.status] || data.status}
              color={getApplicationStatusColor(data.status)}
              width={itemWidth}
              description={
                [ApplicationStatus.REJECTED, ApplicationStatus.BLOCKED].includes(data.status)
                  ? checkRemark(data?.statusActivity, data.status)?.data?.reason
                  : null
              }
            />
            <CreateInfoItem label={''} value={`\u{200B}`} width={itemWidth} />
          </ApplicationDetails>
          {
            Array.isArray(rowData) && rowData.length > 0 && (
              <QualDetailsWrapper>
                <TitleWrapper>
                  <HeaderFour value={t("qualification-details.label")} />
                </TitleWrapper>
                {/* @ts-ignore */}
                <UserDetails
                  step={0}
                  userId={userId}
                  userType={userType}
                  hideAddAndDeleteBtn={true}
                  reviewMode={false}
                  isOnboarding={false}
                  inModalView={true}
                  userDetails={rowData}
                  applicationRef={applId}
                  viewMode={ViewMode.READ}
                  formBaseType={FormBaseType.ApplicationQualification}
                  viewApplication={true}
                />
              </QualDetailsWrapper>
            )
          }
        </>
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </Container>
  );
};

const Container = styled.View`
  height: ${0.7 * height - 102};
`;

const QualDetailsWrapper = styled.View`
  padding-bottom: 32px;
`;

const TitleWrapper = styled.View`
  margin-bottom: 16px;
`;

const ApplicationDetails = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;
  column-gap: 8px;
  margin-bottom: 24px;
`;

const SpinnerView = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
