import React from 'react';
import { View } from 'react-native';
import { Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import HeaderThree from '../../Text/HeaderThree';
import ModalBaseAction from '../BaseAction';
import { useThemeSystem } from 'src/contexts/theme-context';
import { colors } from 'src/styles/theme/styles';
import MediumText from '../../Text/MediumText';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components';

export default function DeleteModal(props: any) {
  const {
    children,
    modalVisible,
    setModalVisible,
    Headerpopup = '',
    maxWidth,
    width,
    handleSave,
    isSubmitting,
    deleteButtonColor = colors.errorColor,
    headerAlign = 'center',
    height,
    contentStyle,
    deleteButtonText = 'delete.label',
  } = props;
  const { theme } = useThemeSystem();
  const { t } = useI18n();

  return (
    <View>
      <CustomDialog
        onClose={setModalVisible}
        open={modalVisible}
        PaperProps={{
          sx: {
            height: height,
            width: width,
            borderRadius: '16px',
          }
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          borderBottom: `1px solid ${colors.lighterBorder}`,
        }}
        maxWidth={maxWidth}>
        {Headerpopup && (
          <DialogTitle>
            <HeaderThree value={t(Headerpopup)} color={theme?.dialog?.titleColor} align={headerAlign} />
          </DialogTitle>
        )}
        <DialogContent style={{ ...contentStyle }}>
          <MediumText
            value={children}
            color={colors.secondaryText}
            lineHeight={2.4}
            align={'left'}
          />
        </DialogContent>
        <DialogActions style={{ padding: 0 }}>
          <ModalBaseAction
            handleCancel={setModalVisible}
            cancelButtonLabel={t('cancel.label')}
            addEditButtonLabel={t(deleteButtonText)}
            handleSave={handleSave}
            disabledSave={isSubmitting}
            buttonColor={deleteButtonColor}
          />
        </DialogActions>
      </CustomDialog>
    </View>
  );
}

const CustomDialog = styled(Dialog)`
  .MuiDialogTitle-root {
    padding: 16px 32px;
    text-align: left;
  }
  .MuiDialogContent-root {
    text-align: left;
    padding: 0 32px 32px;
  }
  .MuiDialogActions-root {
    border-top: 1px solid ${colors.lighterBorder} !important;
  }
`;