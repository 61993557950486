import { limit } from 'src/constant/index';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { readCacheQuery, writeCacheQuery } from '../util';
import { client } from '../client';
import { createDeleteData, createSearchFilter } from '../../components/services/index';
import { getUniversityFilter } from 'src/components/services/filters';

const addBoardUniversityMutation = gql`
  mutation ($payload: CreateUniversityInput!) {
    createUniversity(payload: $payload) {
      id
      name
      alias 
      
    }
  }
`;

const updateBoardUniversityMutation = gql`
  mutation ($payload: UpdateUniversityInput!) {
    updateUniversity(payload: $payload) {
      id, name, alias
    }
  }
`;

const deleteBoardUniversityMutation = gql`
  mutation ($id: String!){
    deleteUniversity(id: $id){
        status
        message
    }
  }

`;

//U can use this also for all limit = 0 skip = 0
const getBoardUniversitiesQuery = gql`
  query ($limit: Int, $skip: Int, $filters:UniversityFilterInput) {
    universities(limit: $limit, skip: $skip, filters: $filters) {
      hasNextPage
      totalCount
      data {
        id
        name
        alias
      }
    }
  }
`;


//Useuse yet
const getAllBoadUniversitiesQuery = gql`
  query($limit: Int, $skip: Int, $filters:UniversityFilterInput) {
	universities(limit: $limit, skip: $skip, filters: $filters) {
  		hasNextPage,
    	totalCount,
    	data {
        id 
        value : id
		    label : name
        alias
      }
    }
  }
`;

const searchBoadUniversitiesQuery = gql`
  query($filters:UniversityFilterInput!) {
    universities(filters: $filters ) {
    	data {
        value : id,
		    label : name
        alias
      }
	 }
}
`;

export { 
  addBoardUniversityMutation,
  updateBoardUniversityMutation,
  getBoardUniversitiesQuery, 
  getAllBoadUniversitiesQuery,
  searchBoadUniversitiesQuery,
  deleteBoardUniversityMutation
};

export function useGetBoardUniversity () {
  const [getBoardUniversity, { loading, data: boardUniversiesData, error:boardUniversiesError }] =
  useLazyQuery(getAllBoadUniversitiesQuery);
  return { getBoardUniversity, boardUniversiesData, boardUniversiesError };
}

export function useSearchBoardUniversity () {
  const [searchBoardUniversity, { loading, data: searchBoardUniversiesData, error:searchBoardUniversiesError }] =
  useLazyQuery(searchBoadUniversitiesQuery);
  return { searchBoardUniversity, searchBoardUniversiesData, searchBoardUniversiesError };
}

export function useMutateUniversity(queries: any) {
  const [mutate, { error }] = useMutation(queries);
  return { mutate, error };
}

export function useQueryUniversity(queries: any, fetchPolicy = 'cache-first') {
  const [query, { loading, data, refetch }] = useLazyQuery(queries, { fetchPolicy: fetchPolicy });
  return { query, data, loading, refetch };
}

export function useDeleteBoardUniversity(page: number, searchValue:string, rowLimit:number, univerSityId:string) {

  const [deleteBoardUniversity, { error : deleteUniversityError, data: deleteUniversityData }] = 
    useMutation(deleteBoardUniversityMutation, { 
      
      update (cache, { data: { deleteUniversity } }) {
        
        let queryData :any;
        let filters = getUniversityFilter(searchValue);
        let universityFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
        try{
            queryData = readCacheQuery(cache, getBoardUniversitiesQuery, universityFilter);
          }catch (e) {
            //console.log(e.messgae);
        }

        let universityObject= queryData?.universities;
        let message = deleteUniversity.message;
        // let univerSityId = message.replace("Successfully deleted university for ", "");
        let dataToUpdate = createDeleteData(universityObject,univerSityId);
        if(deleteUniversity.status=="success")
        { 
          //write
          try {
            writeCacheQuery( cache,
              getBoardUniversitiesQuery,
              universityFilter,
              {
                universities : dataToUpdate
              },
            );
          } catch (e) {
            //console.log('error', e.messgae);
          }
          return null;
        }
      }
      
    });

  return { deleteBoardUniversity, deleteUniversityError, deleteUniversityData };
}



