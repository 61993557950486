import React, { useEffect } from 'react';
//@ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
//@ts-ignore
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es';
import { View, StyleSheet } from 'react-native';
import SmallTextAtom from '../Text/SmallTextAtom';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components';
import { colors, fonts } from 'src/styles/theme/styles';

const RichText = styled.div`
  && .ck.ck-editor__editable_inline {
    border: 1px solid ${colors.tableDropdwonBorder};
    border-top-width: 0;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    min-height: 219px;
    font-family: ${fonts.regular};
    font-size: 15px;
    color: ${colors.primaryText};
    &:focus {
      border-color: ${colors.primaryColor};
      box-shadow: none;
    }
  }

  && .ck.ck-toolbar {
    border-color: ${colors.tableDropdwonBorder};
    background-color: #EAF1FA;
  }

  && .ck.ck-button:hover {
    background: ${colors.tableDropdwonBorder};
  }
`;

export default function RichTextEditor(props: any) {
  const {
    data,
    setData,
    header,
    notefooter = '',
    disabled = false,
    id = 'standard-basic',
    width,
    label,
    ref,
    clearErrors,
  } = props;
  const { theme } = useThemeSystem();
  const { t } = useI18n();

  useEffect(() => {
    return () => {
      clearErrors(id);
    };
  }, []);
  let editorRef = null;

  return (
    <>
      <RichText
        style={{
          opacity: disabled === true ? 0.5 : null
        }}>
        {header !== '' ? (
          <View style={styles.helperText}>
            <SmallTextAtom value={t(header)} color={theme?.input?.topLabel} />
          </View>
        ) : null}
        <CKEditor
          onReady={(editor: any) => {
            editor.ui
              .getEditableElement()
              .parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement(),
              );

            editorRef = editor;
          }}
          editor={DecoupledEditor}
          id={id}
          data={data}
          config={{
            placeholder: label,
            toolbar: [
              "heading",
              "|",
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "|",
              "bulletedList",
              "numberedList",
              "alignment",
              "|",
              "undo",
              "redo",
            ],
          }}
          onChange={setData}
          disabled={disabled}
        />
        {notefooter !== '' ? (
          <View style={styles.bottomText}>
            <SmallTextAtom value={t(notefooter)} color={theme?.input?.bottomLabel} />
          </View>
        ) : null}
      </RichText>
    </>
  );
}

const styles = StyleSheet.create({
  helperText: {
    marginBottom: 4,
  },
  bottomText: {
    marginTop: 4,
  },
});
