import React, { useEffect, useState } from 'react';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form/dist/types';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import formJSON from '../../../form-json/other-payment/edit-other-payment.json';
import Element from '../Forms/ApplicationElement.web';
import styled from 'styled-components';
import { CustomFeePayer } from 'src/components/organism/Payment/Institute/ControlPanel/helpers';
import { formKeys } from './helpers';

interface Props {
  control: Control<FieldValues, object>;
  errors: { [x: string]: any };
  setValue: UseFormSetValue<FieldValues>;
  editData: CustomFeePayer | null;
}

export default function EditPayerDetailsForm(props: Props) {
  const { control, errors, setValue, editData } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    loadEditValues();
    setElements(formJSON[0]);
  }, [editData]);

  function loadEditValues() {
    if (editData) {
      Object.keys(editData).forEach(key => {
        if (formKeys.includes(key) && editData[key as keyof CustomFeePayer]) {
          setValue(key, editData[key as keyof CustomFeePayer]);
        }
      });
    }
  }

  return (
    <ContainerWrapper>
      {fields ? (
        fields.map((field: any) => (
          // @ts-ignore
          <Element key={field.id} field={field} control={control} errors={errors} />
        ))
      ) : (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      )}
    </ContainerWrapper>
  );
}

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 432px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
