import { format, parse, subMonths } from "date-fns";

import { DT } from "src/constant/dateTime";
import { DateView } from "@mui/x-date-pickers";
import { DropdownOptionsType } from "src/types";

export type NativeDateTimeMode = "date" | "time" | "datetime" | "monthyear";
export type WebDateTimeMode = "date" | "time" | "year" | "monthyear";

type BaseDatePickerProps = {
  onDateTimeChangeCallback?: (date: Date) => void;
  dateTimePickerTitle?: string | null;
  containerWidth?: number | string;
  containerHeight?: number | string;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  standalonePicker?: boolean;
  maxDate?: Date;
  minDate?: Date;
};

export type WebDatePickerProps = BaseDatePickerProps & {
  initialValue?: Date | null;
  dateTimeMode?: WebDateTimeMode;
  forceOpenAtBottom?: boolean;
  customFormat?: string;
};

export type NativeDatePickerProps = BaseDatePickerProps & {
  initialValue?: Date;
  dateTimeMode?: NativeDateTimeMode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  testID?: string;
};

export const DateTimeFormats: Record<
  NativeDateTimeMode | WebDateTimeMode,
  string
> = {
  date: DT.DATE_FORMAT_SLASH,
  datetime: DT.DATE_TIME_SLASH_12_HOUR,
  time: DT.TIME_12_HOUR,
  year: DT.YEAR_ONLY,
  monthyear: DT.DATE_FORMAT_SLASH,
};

export const getFormattedDate = (
  date: Date,
  dateTimeMode: NativeDateTimeMode | WebDateTimeMode,
  getTimeFormatForDisplayOnly: boolean = false
) => {
  let conversionFormat: string;

  switch (dateTimeMode) {
    case "time":
      conversionFormat = getTimeFormatForDisplayOnly
        ? DT.TIME_24_HOUR_WITH_COLON
        : DateTimeFormats[dateTimeMode];
      break;
    case "monthyear":
      conversionFormat = getTimeFormatForDisplayOnly
        ? DT.MONTH_NAME_YEAR
        : DateTimeFormats[dateTimeMode];
      break;
    default:
      conversionFormat = DateTimeFormats[dateTimeMode];
  }

  return format(date, conversionFormat);
};

export function getInitialDateTimeValue<WebEnv extends boolean = false>(
  value: string | number | Date,
  dateTimeMode: WebEnv extends true ? WebDateTimeMode : NativeDateTimeMode,
  getTimeFormatForDisplayOnly: boolean = false
): Date {
  if (value.constructor !== Date) {
    const conversionFormat: string =
      dateTimeMode === "time" && getTimeFormatForDisplayOnly
        ? DT.TIME_24_HOUR_WITH_COLON
        : DateTimeFormats[dateTimeMode];
    return parse(value.toString(), conversionFormat, new Date());
  }
  return value; // Return JavaScript Date objects as they are
}

export const getMUIDatePickerViews = (
  dateTimeMode: WebDateTimeMode
): readonly DateView[] => {
  switch (dateTimeMode) {
    case "year":
      return ["year"] as const;
    case "monthyear":
      return ["month", "year"] as const;
    case "date":
    default:
      return ["year", "month", "day"] as const;
  }
};

export function getYearOptions(
  minDropdownYear: number = 1945
): DropdownOptionsType[] {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - minDropdownYear + 1 },
    (_, index) => {
      const year = currentYear - index;
      const label = String(year);
      return { label, value: year };
    }
  );

  return years;
}

export function generateMonthYearOptions(): DropdownOptionsType[] {
  const currentDate = new Date();

  return Array.from({ length: 36 }, (_, i) => {
    const date = subMonths(currentDate, i);
    const month_year = format(date, DT.MONTH_NAME_YEAR);
    return {
      label: month_year,
      value: month_year,
    };
  });
}
