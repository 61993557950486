import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { readCacheQuery, writeCacheQuery } from '../util';
import { ASSIGNMENT_LIMIT, FILTERS } from 'src/constant';
import { assignmentObjectType, attachmentType } from 'src/components/organism/Assignments/types';
import { createDeleteData, createSearchFilter } from 'src/components/services';

interface assignment {
  id: string;
}
interface subjmittedType {
  id: string;
  attachments: attachmentType;
  assignment: assignment;
}

const getAssignmentListQuery = gql`
  query ($limit: Int, $skip: Int, $filters: AssignmentFilterInput, $timezone: String) {
    getAssignments(limit: $limit, skip: $skip, filters: $filters, timezone: $timezone) {
      hasNextPage
      totalCount
      data {
        id
        title
        description
        mdate
        mdueDate
        totalMarks
        subject {
          id
          alias
        }
        attachments {
          id
          name: documentName
          uri: documentUrl
        }
        submissions
        assignees
        assigneeType
        assigneeLabels {
          label
        }
        assignor {
          id
          profilePicture {
            documentUrl
          }
          personalDetails {
            fullName
          }
        }
      }
    }
  }
`;

const getAssignmentsQuery = gql`
  query ($limit: Int, $skip: Int, $filters: AssignmentFilterInput, $timezone: String) {
    getAssignments(limit: $limit, skip: $skip, filters: $filters, timezone: $timezone) {
      hasNextPage
      totalCount
      data {
        id
        title
        assigneeType
        assigneeList {
          id
          profilePicture {
            documentUrl
          }
          personalDetails {
            fullName
          }
        }
        assigneeLabels {
          label
        }
        assignor {
          id
          profilePicture {
            documentUrl
          }
          personalDetails {
            fullName
          }
        }
        assignees
        mdate
        mdueDate
        status
        description
        subject {
          id
          alias
        }
        submissions
        title
        totalMarks
        attachments {
          id
          name: documentName
          uri: documentUrl
          fileType
        }
      }
    }
  }
`;

const getPendingAssignmentsQuery = gql`
  query ($limit: Int, $skip: Int, $filters: AssignmentFilterInput, $timezone: String) {
    getPendingAssignments(limit: $limit, skip: $skip, filters: $filters, timezone: $timezone) {
      hasNextPage
      totalCount
      data {
        id
        assignor {
          id
          firstName
          lastName
          profilePicture {
            documentUrl
          }
          personalDetails {
            fullName
          }
        }
        assignees
        mdate
        mdueDate
        subject {
          id
          alias
        }
        title
        attachments {
          id
        }
      }
    }
  }
`;

const getSubmittedAssignmentsQuery = gql`
  query ($limit: Int, $skip: Int, $filters: AssignmentFilterInput, $timezone: String) {
    getSubmittedAssignments(limit: $limit, skip: $skip, filters: $filters, timezone: $timezone) {
      hasNextPage
      totalCount
      data {
        id
        assignor {
          id
          firstName
          lastName
          profilePicture {
            documentUrl
          }
          personalDetails {
            fullName
          }
        }
        assignees
        mdate
        mdueDate
        subject {
          id
          alias
        }
        title
        attachments {
          id
        }
      }
    }
  }
`;

const getStudentsPendingForSubmission = gql`
  query studentsPendingForSubmission($assignmentId: String!, $limit: Int, $skip: Int) {
    studentsPendingForSubmission(assignmentId: $assignmentId, limit: $limit, skip: $skip) {
      totalCount
      data {
        id
        firstName
        lastName
        profilePicture {
          documentUrl
          documentName
        }
        personalDetails {
          fullName
        }
      }
    }
  }
`;

const createAssignmentMutation = gql`
  mutation ($payload: AssignmentInput!) {
    createAssignment(payload: $payload) {
      id
      assigneeType
      assigneeList {
        id
        firstName
        lastName
        profilePicture {
          documentUrl
        }
      }
      assignor {
        id
        firstName
        lastName
        profilePicture {
          documentUrl
        }
      }
      assignees
      class {
        id
        alias
      }
      batch {
        id
        name
        alias
        divisionDetails {
          division
          seats
        }
      }
      mdate
      mdueDate
      status
      description
      subject {
        id
        alias
      }
      submissions
      title
      totalMarks
      attachments {
        id
        documentName
        documentUrl
      }
    }
  }
`;

const updateAssignmentMutation = gql`
  mutation ($payload: UpdateAssignmentInput!) {
    updateAssignment(payload: $payload) {
      id
      assigneeList {
        id
        firstName
        lastName
        profilePicture {
          documentUrl
        }
      }
      assignor {
        id
        firstName
        lastName
        profilePicture {
          documentUrl
        }
      }
      assignees
      class {
        id
        alias
      }
      batch {
        id
        name
        alias
      }
      mdate
      mdueDate
      status
      description
      subject {
        id
        alias
      }
      submissions
      title
      totalMarks
      attachments {
        id
        documentName
        documentUrl
      }
    }
  }
`;

const createAssignmentSubmissionMutation = gql`
  mutation ($payload: AssignmentSubmissionInput!) {
    submitAssignment(payload: $payload) {
      id
      assignment {
        id
      }
      attachments {
        id
        documentName
        documentUrl
      }
    }
  }
`;

const getAssignmentSubmissionsQuery = gql`
  query ($assignmentId: String!, $filters: AssignmentSubmissionFilter, $limit: Int, $skip: Int) {
    getAssignmentSubmissions(assignmentId: $assignmentId, filters: $filters, limit: $limit, skip: $skip) {
      data {
        id
        marksObtained
        grade
        attachments {
          id
          name: documentName
          uri: documentUrl
        }
        assignment {
          id
          totalMarks
        }
        user {
          id
          firstName
          lastName
          profilePicture {
            documentName
            documentUrl
          }
          personalDetails {
            fullName
          }
        }
      }
    }
  }
`;

const getAssignmentCommentQuery = gql`
  query ($assignmentId: String!, $to: String) {
    getAssignmentComment(assignmentId: $assignmentId, to: $to) {
      id
      assignee {
        id
        firstName
        lastName
        profilePicture {
          documentName
          documentUrl
        }
        personalDetails {
          fullName
        }
      }
      comments {
        comment
        createdAt
        from {
          firstName
          lastName
          profilePicture {
            documentUrl
          }
          personalDetails {
            fullName
          }
        }
      }
    }
  }
`;

const setAssignmentGradeMutation = gql`
  mutation ($input: GradeInput!) {
    setGrades(input: $input) {
      id
      grade
      marksObtained
    }
  }
`;

const publishAssignmentQuery = gql`
  query ($id: String!) {
    publishAssignment(id: $id)
  }
`;

const createAssignmentCommentMutation = gql`
  mutation ($payload: AssignmentCommentsInput!) {
    createAssignmentComment(payload: $payload) {
      id
    }
  }
`;

const createGlobalAssignmentCommentMutation = gql`
  mutation ($payload: GlobalAssignmentCommentInput!) {
    createGlobalAssignmentComment(payload: $payload) {
      id
      assignee {
        id
        firstName
        lastName
        profilePicture {
          documentName
          documentUrl
        }
      }
      comments {
        comment
        createdAt
        from {
          firstName
          lastName
          profilePicture {
            documentUrl
          }
        }
      }
    }
  }
`;

const globalAssignmentCommentQuery = gql`
  query ($assignmentId: String!) {
    getGlobalAssignmentComment(assignmentId: $assignmentId) {
      id
      assignee {
        id
        firstName
        lastName
        profilePicture {
          documentName
          documentUrl
        }
      }
      comments {
        comment
        createdAt
        from {
          firstName
          lastName
          profilePicture {
            documentUrl
          }
          personalDetails {
            fullName
          }
        }
      }
    }
  }
`;

const deleteAssignmentDetailMutation = gql`
  mutation ($id: String!) {
    deleteAssignment(id: $id)
  }
`;

export {
  getAssignmentsQuery,
  getAssignmentListQuery,
  getAssignmentSubmissionsQuery,
  getAssignmentCommentQuery,
  getPendingAssignmentsQuery,
  getStudentsPendingForSubmission,
  getSubmittedAssignmentsQuery,
  publishAssignmentQuery,
  globalAssignmentCommentQuery,
};

export function useSetAssignmentGradeMutation() {
  const [setAssignmentGrade, { data: assignmentGradeData }] = useMutation(
    setAssignmentGradeMutation,
  );
  return { setAssignmentGrade, assignmentGradeData };
}

export function useUpdateAssignmentMutation() {
  const [assignmentUpdation, { data: assignmentUpdationData }] =
    useMutation(updateAssignmentMutation);
  return { assignmentUpdation, assignmentUpdationData };
}

export function useCreateAssignmentMutation() {
  let searchFilters = [
    {
      title: {
        regex: '',
      },
    },
    {
      description: {
        regex: '',
      },
    },
  ];
  const [createAssignment, { data: assignmentsData }] = useMutation(createAssignmentMutation, {
    update: (cache, { data: { createAssignment } }) => {
      let queryData;

      let filters = {
        filters: { or: searchFilters },
        limit: ASSIGNMENT_LIMIT,
        skip: 0,
      };
      try {
        queryData = readCacheQuery(cache, getAssignmentsQuery, filters);
      } catch (e) {
        console.log(e.messgae);
      }

      let mergeArray;
      if (queryData) {
        let existingAssignments = queryData?.getAssignments?.data;
        mergeArray = [createAssignment, ...existingAssignments];
      } else {
        mergeArray = [createAssignment];
      }

      try {
        writeCacheQuery(cache, getAssignmentsQuery, filters, {
          getAssignments: mergeArray,
        });
      } catch (e) {
        console.log('error', e.messgae);
      }
    },
  });

  return { createAssignment, assignmentsData };
}

// export function useCreateAssignmentSubmissionMutation(assignmentIndex=null,assignmentSearch=null){
//   const [createAssignmentSubmission, { data: submittedAssignmentData}] = useMutation(createAssignmentSubmissionMutation);
//   return { createAssignmentSubmission, submittedAssignmentData };
// }

export function useCreateGlobalAssignmentCommentMutation() {
  const [createGlobalAssignmentComment, { data: globalAssignmentCommenData }] = useMutation(
    createGlobalAssignmentCommentMutation,
  );
  return { createGlobalAssignmentComment, globalAssignmentCommenData };
}

// export function useDeleteAssignmentMutation(assignmentIndex:number, searchText:string) {
//   const [assignmentDeletion, { error: deleteAssignmentError }] = useMutation(
//     deleteAssignmentDetailMutation,
//   );
//   return { assignmentDeletion, deleteAssignmentError };
// }

export function useDeleteAssignmentMutation(
  assignmentIndex: number | null,
  searchValue: string,
  assignmentId: string,
) {
  const [assignmentDeletion, { error: deleteAssignmentError }] = useMutation(
    deleteAssignmentDetailMutation,
    {
      update: (cache, { data: { deleteAssignment } }) => {
        let queryData;
        let pendingParam = getPendingAssignmentFilter(assignmentIndex, searchValue);
        //read
        try {
          queryData = readCacheQuery(cache, getAssignmentsQuery, pendingParam);
        } catch (e) {
          //console.log(e.messgae);
        }

        let dataToUpdate = createDeleteData(queryData?.getAssignments, assignmentId);  
        //write
        try {
          writeCacheQuery(cache, getAssignmentsQuery, pendingParam, {
            getAssignments: {
              data: dataToUpdate,
              hasNextPage: queryData?.getAssignments?.hasNextPage,
              totalCount: queryData?.getAssignments?.totalCount - 1,
              __typename: queryData?.getAssignments?.__typename,
            },
          });
        } catch (e) {
          //console.log('error', e.messgae);
        }
      },
    },
  );

  return { assignmentDeletion, deleteAssignmentError };
}

function getPendingAssignmentFilter(assignmentIndex: any, assignmentSearch: any) {
  let pendingFilter = {} as any;

  if (assignmentSearch !== null) {
    let filters = [] as any;
    filters = [
      {
        field: 'title',
        type: FILTERS.REGEX,
        value: assignmentSearch,
      },
      {
        field: 'description',
        type: FILTERS.REGEX,
        value: assignmentSearch,
      },
    ];

    let maxBrack = checkIndex(assignmentIndex);
    let canSkip = assignmentIndex == null ? 0 : maxBrack;
    let assignmentFilter = createSearchFilter(ASSIGNMENT_LIMIT, canSkip, filters);
    return assignmentFilter;
  } else {
    let maxBrack = checkIndex(assignmentIndex);
    pendingFilter['limit'] = ASSIGNMENT_LIMIT;
    pendingFilter['skip'] = assignmentIndex == null ? 0 : maxBrack;
    pendingFilter['filters'] = {};
  }

  return pendingFilter;
}

function checkIndex(inp: number) {
  for (let j = 1; j < inp; j++) {
    let max = j * ASSIGNMENT_LIMIT;
    let min = max - ASSIGNMENT_LIMIT;
    if (min <= inp && inp <= max) {
      return min;
    }
  }
  return 0;
}

export function useCreateAssignmentSubmissionMutation(
  assignmentIndex = null,
  assignmentSearch = null,
) {
  const [createAssignmentSubmission, { data: submittedAssignmentData }] = useMutation(
    createAssignmentSubmissionMutation,
    {
      update: (cache, { data: { submitAssignment } }) => {
        let previosIndex;
        let pendingQueryData;
        let submittedQueryData;

        let pendingParam = getPendingAssignmentFilter(assignmentIndex, assignmentSearch);
        let submitParam = { limit: ASSIGNMENT_LIMIT, skip: 0, filters: {} };
        try {
          pendingQueryData = readCacheQuery(cache, getPendingAssignmentsQuery, pendingParam);
          submittedQueryData = readCacheQuery(cache, getSubmittedAssignmentsQuery, submitParam);
        } catch (e) {
          console.log(e.messgae);
        }

        try {
          let submitList = submittedQueryData?.getSubmittedAssignments;
          //check already submitted
          let updatedSubmittedList = updateSubmitList(submitList, submitAssignment);

          if (updatedSubmittedList?.resubmitted) {
            //NO NEED TO DO ANYTHING
          } else {
            // pending to submitted
            let pendingList = pendingQueryData?.getPendingAssignments;
            let response = pendingToSubmittedList(
              pendingList?.data,
              submitList?.data,
              submitAssignment,
            );

            try {
              writeCacheQuery(cache, getPendingAssignmentsQuery, pendingParam, {
                getPendingAssignments: {
                  data: response?.pending,
                  hasNextPage: pendingQueryData?.hasNextPage,
                  totalCount: pendingQueryData?.totalCount - 1,
                  __typename: 'PAssignmentOutput',
                },
              });
            } catch (e) {
              console.log('error', e.messgae);
            }

            try {
              writeCacheQuery(cache, getSubmittedAssignmentsQuery, submitParam, {
                getSubmittedAssignments: {
                  data: response?.submitted,
                  hasNextPage: submittedQueryData?.hasNextPage,
                  totalCount: submittedQueryData?.totalCount + 1,
                },
              });
            } catch (e) {
              console.log('error', e.messgae);
            }
          }
        } catch (err) {
          console.log('error', err.messgae);
        }
      },
    },
  );

  return { createAssignmentSubmission, submittedAssignmentData };
}

export function useCreateAssignmentCommentMutation() {
  const [createAssignmentComment] = useMutation(createAssignmentCommentMutation);
  return { createAssignmentComment };
}

function pendingToSubmittedList(
  pendingList: assignmentObjectType[],
  submitList: assignmentObjectType[],
  submittedData: subjmittedType,
) {
  let updatedPendingList: assignmentObjectType[] = [];
  let updatedSubmittedList: assignmentObjectType[] = [];

  pendingList?.forEach((pending: assignmentObjectType) => {
    let pendingAssign = { ...pending };
    if (pendingAssign.id === submittedData?.assignment?.id) {
      pendingAssign['attachments'] = submittedData?.attachments;
      pendingAssign['submissions'] = 1;
      updatedSubmittedList = [pendingAssign, ...submitList];
    } else {
      updatedPendingList.push(pendingAssign);
    }
  });

  return { pending: updatedPendingList, submitted: updatedSubmittedList };
}

function updateSubmitList(oldList: assignmentObjectType[], submittedData: subjmittedType) {
  let resubmitted = false;
  let submittedAssignmentId = submittedData?.assignment?.id;
  let updatedSubmittedList: assignmentObjectType[] = [];

  oldList?.data?.forEach((submit: assignmentObjectType) => {
    let newdoc = { ...submit };
    if (newdoc.id === submittedAssignmentId) {
      resubmitted = true;
      newdoc['attachments'] = submittedData?.attachments;
    }
    updatedSubmittedList.push(newdoc);
  });
  // }catch(error){
  //   console.log(error.messgae)
  // }

  return { updatedList: updatedSubmittedList, resubmitted: resubmitted };
}

export function useQueryAssignments() {
  const [query, { error, loading, data }] = useLazyQuery(getAssignmentsQuery, { fetchPolicy: 'network-only' });
  return { query, data, error };
}
