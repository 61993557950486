import { DocumentNode } from '@apollo/client';
import { FetchValuesOptionsType } from 'src/components/molecules/Forms/types';
import { DropdownOptionsType } from 'src/types';
import { getAllBoadUniversitiesQuery } from './academics/board-universities';
import { useAsyncQuery } from './client';
import { searchCourseQuery } from './academics/courses';
import { searchClassQuery } from './academics/classes';
import { searchBatchQuery } from './academics/batch';
import { filteredOpenAdmissionQuery } from './applications/applications';

export const enum APIQueryName {
  UniversityQuery = 'UniversityQuery',
  CoursesQuery = 'CoursesQuery',
  ClassesQuery = 'ClassesQuery',
  BatchesQuery = 'BatchesQuery',
  AdmissionsOpenQuery = 'AdmissionsOpenQuery',
}

export const APIQueryMap = new Map<APIQueryName, DocumentNode>([
  [APIQueryName.UniversityQuery, getAllBoadUniversitiesQuery],
  [APIQueryName.CoursesQuery, searchCourseQuery],
  [APIQueryName.ClassesQuery, searchClassQuery],
  [APIQueryName.BatchesQuery, searchBatchQuery],
  [APIQueryName.AdmissionsOpenQuery, filteredOpenAdmissionQuery],
]);

export async function useQuery(queryName: APIQueryName, variables?: any): Promise<any> {
  const query = APIQueryMap.get(queryName);
  if (query) {
    const res = await useAsyncQuery(query, variables)
    if (res?.data && !res.error) {
      return Object.values(res.data)?.[0];
    }
  }
  return [];
}

export function useValuesOptions(options: FetchValuesOptionsType, data: any): any {
  let res: any[];
  let values = options.valuesFieldName !== 'self' ? data[options.valuesFieldName] : data;
  if (values) {
    if (Array.isArray(values) && options.foreignField && options.localField) {
      let { foreignField, localField } = options;
      const value = values.find((value) => value[foreignField] == data[localField].value);
      res = value[options.fieldName]
    } else {
      res = values[options.fieldName]
      if (!Array.isArray(res)) {
        res = [res];
      }
    }
    if (res && res.length > 0) {
      let mapField: string;
      if (options.mapField) {
        mapField = options.mapField;
      }
      res = res.map<DropdownOptionsType>((item: any) => {
        let ele = item;
        if (mapField) {
          ele = item[mapField];
        }
        if (typeof ele == 'string') {
          return { value: ele, label: ele }
        }
        return ele;
      });
    }
    return res || [];
  }
  return [];
}