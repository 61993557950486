export const AdmissionListingHeadCells = [
  {
    id: "addmission_name",
    label: "admissionName.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "batch",
    label: "batch.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "allowed_applicants",
    label: "allowedApplicants.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "total_seats",
    label: "totalSeats.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "last_date",
    label: "lastDate.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "status",
    label: "status.label",
    align: "left",
    disableSorting: true,
  },
  {
    id: "action",
    label: "action.label",
    align: "right",
    disableSorting: true,
  },
];
