import React, { useState, createContext, useContext } from 'react';
import AlertBox from 'src/components/molecules/Alert';
import { isWeb } from 'src/constant/device';

interface IAlertShower {
    level: any
    expireInMs?: number,
    message: string
}

const initialState: IAlertShower = {
    level: 'error',
    expireInMs: 6000,
    message: ''
}

const AlertContext = createContext<any>(initialState)
const AlertProvider = ({ children }: any) => {
    let initial = {
        level: '',
        expireInMs: 6000,
        message: ''
    }
    const [alertState, setAlertDetails] = useState(initial);

    function clearAlert(){
        setAlertDetails(initial)
    }
    
    return <AlertContext.Provider value={{setAlertDetails, alertState}} >
            {/* <AlertBox alert={alertState} clearAlert={clearAlert}/> */}
            {isWeb && (<AlertBox alert={alertState} clearAlert={clearAlert} />)}
            {children}
          </AlertContext.Provider>
}

const useAlertSystem = (): any => {
    const context = useContext(AlertContext);
    return context;
};

export { AlertProvider, useAlertSystem };