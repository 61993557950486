import { colors, fontWeight, fonts } from "src/styles/theme/styles";
import { faChalkboardTeacher, faUserGraduate } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import HeaderFive from "../atoms/Text/HeaderFive";
import HeaderThree from "../atoms/Text/HeaderThree";
import { Icon } from "../atoms/Icon/Icon";
import LargeButtonText from "../atoms/Text/LargeButtonText";
import NormalTextSelect from "../atoms/Text/NormalTextSelect";
import NormaltextAtom from "../atoms/Text/NormalTextAtom";
import React from "react";
import SmallTextAtom from "../atoms/Text/SmallTextAtom";
import { StatusBar } from "react-native";
import navigationStrings from "src/navigation/navigationStrings";
import styled from "styled-components/native";
import { useI18n } from "src/i18n/hooks";

export const AuthLanding = (props: any) => {
  const { navigation } = props;
  const { t } = useI18n();
  return (
    <SafeAreaViewWrapper>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="light-content"
      />
      <ImgBackground
        source={require("src/assets/onboarding-screen/sign-up.png")}
      >
        <Wrapper>
          <Container>
            <LogoWrapper>
              <Icon name="edba-logo-light" />
            </LogoWrapper>
            <HeaderWrapper>
              <HeaderThree
                value={t("onboarding.text")}
                color={colors.white}
                fontWeight={fontWeight[600]}
              />
            </HeaderWrapper>
            <MarginBottomEight>
              <HeaderFive
                value={t("who-am-i.text")}
                color={colors.white}
                fontFamily={fonts.regular}
                fontWeight={fontWeight[600]}
              />
            </MarginBottomEight>
            <SubTextWrapper>
              <NormaltextAtom
                value={t("select-role-1.text")}
                color={colors.greyBackground}
              />
              <NormaltextAtom
                value={t("select-role-2.text")}
                color={colors.greyBackground}
              />
            </SubTextWrapper>
            <LoginContainer>
              <LoginCircle
                onPress={() =>
                  navigation.navigate(navigationStrings.STUDENT_LOGIN)
                }
              >
                <MarginBottomEight>
                  <FontAwesomeIcon
                    icon={faUserGraduate}
                    size={40}
                    color={colors.primaryColor}
                  />
                </MarginBottomEight>
                <NormalTextSelect
                  value={`${t("login-i-am-a.text")}`}
                  color={colors.primaryColor}
                  fontsize={1.2}
                  lineHeight={1.5}
                />
                <NormalTextSelect
                  value={`${t("student.label")}`}
                  color={colors.primaryColor}
                  fontsize={1.2}
                  lineHeight={1.5}
                />
              </LoginCircle>
              <LoginCircle
                onPress={() =>
                  navigation.navigate(navigationStrings.APPLICANT_LOGIN)
                }
              >
                <ApplicantLogoWrapper>
                  <Icon name="applicant" />
                </ApplicantLogoWrapper>
                <NormalTextSelect
                  value={`${t("login-i-am-an.text")}`}
                  color={colors.primaryColor}
                  fontsize={1.2}
                  lineHeight={1.5}
                />
                <NormalTextSelect
                  value={`${t("applicant.label")}`}
                  color={colors.primaryColor}
                  fontsize={1.2}
                  lineHeight={1.5}
                />
              </LoginCircle>
            </LoginContainer>
            <EmployeeLoginDivider>
              <SmallTextAtom value={`${"-".repeat(20)}`} color={colors.white} />
              <NormaltextAtom
                value={`  ${t("alternative-logins.text")}  `}
                color={colors.white}
              />
              <SmallTextAtom value={`${"-".repeat(20)}`} color={colors.white} />
            </EmployeeLoginDivider>
            <EmployeeLoginContainer
              onPress={() =>
                navigation.navigate(navigationStrings.EMPLOYEE_LOGIN)
              }
            >
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                size={24}
                color={colors.white}
              />
              <IconTextContainer>
                <LargeButtonText
                  value={t("employee.label")}
                  color={colors.white}
                  lineHeight={2}
                />
              </IconTextContainer>
            </EmployeeLoginContainer>
          </Container>
        </Wrapper>
      </ImgBackground>
    </SafeAreaViewWrapper>
  );
};

const imgdimensions = { width: 36, height: 35 };

const SafeAreaViewWrapper = styled.SafeAreaView`
  background-color: ${colors.primaryColor}
  flex: 1;
`;
const ImgBackground = styled.ImageBackground`
  resize-mode: cover;
  flex: 1;
`;
const LogoWrapper = styled.View`
  margin-bottom: 16px;
`;
const EmployeeLoginContainer = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 48px;
  padding: 10px 48px;
  border: 1px ${colors.white};
  border-radius: 24px;
`;
const IconTextContainer = styled.View`
  padding-left: 9px;
  align-self: flex-start;
`;
const EmployeeLoginDivider = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
`;
const Wrapper = styled.View`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const HeaderWrapper = styled.View`
  margin-bottom: 40px;
`;
const MarginBottomEight = styled.View`
  margin-bottom: 8px;
`;
const SubTextWrapper = styled.View`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-bottom: 40px;
`;
const Container = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85%;
`;
const ApplicantLogoWrapper = styled.View`
  margin-bottom: 6px;
`;
const LoginContainer = styled.View`
  margin-bottom: 24px;
`;
const LoginCircle = styled.TouchableOpacity`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: ${colors.white};
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
