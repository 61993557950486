import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useQuery } from '@apollo/client';
import { getStreamQuery } from 'src/graphql/academics/streams';
import { addQualification } from 'src/form-json/admission/add-qualification';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import { componentMapper } from '../ddfElements';
import { validatorMapper } from '../Forms/validatorMapper';
import { getFilteredQualificationNames } from 'src/components/services';
import ModalSaveAndCancelButtonGroup from '../ModalSaveAndCancelButtonGroup';
import { graphqlQuery } from 'src/graphql/util';
import { FormSchema } from '../Forms/types';
import { getDocumentsQuery } from 'src/graphql/admission/document-type';

interface QualificationData {
  name: {
    label: string;
    value: string;
  };
  documentTypes: [
    {
      label: string;
    },
  ];
  documents: Array<object>;
  required: boolean;
}

interface Props {
  editQualificationData: QualificationData;
  isEditQualificationModal: boolean;
  setStreams: Function;
  setDocuments: Function;
  onSubmit: (values: any) => void;
  closeQualificationModal: () => void;
  streams: Array<string>;
  documents: Array<string>;
}

export default function AddQualificationForm(props: Props) {
  const {
    editQualificationData,
    isEditQualificationModal,
    setStreams,
    setDocuments,
    onSubmit,
    closeQualificationModal,
    streams,
    documents,
  } = props;
  const { data: streamData, loading } = useQuery(getStreamQuery, {
    variables: { limit: 0 },
    fetchPolicy: 'network-only',
  });
  const { data: documentData, loading: documentLoading } = useQuery(getDocumentsQuery, {
    variables: { limit: 0 },
    fetchPolicy: 'network-only',
  });
  const [tableData, setTableData] = useState([]);
  const [formSchema, setFormSchema] = useState<FormSchema>();
  const [initialValues, setInitialValues] = useState({});
  const [qualificationNameOptions, setQualificationNameOptions] = useState([]);
  const [documentTypesOptions, setDocumentTypesOptions] = useState([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isDocumentsDataAvailable, setIsDocumentsDataAvailable] = useState(false);

  useEffect(() => {
    if (isEditQualificationModal) {
      loadEditData();
    }
    loadInitialData();
  }, []);

  useEffect(() => {
    loadTableData();
  }, [streamData]);

  useEffect(() => {
    loadDocumentTableData();
  }, [documentData]);

  useEffect(() => {
    setFormSchema(
      addQualification(
        setStreams,
        setDocuments,
        tableData,
        streams,
        documents,
        isEditQualificationModal,
        documentTypesOptions,
        qualificationNameOptions,
        searchStreamOnchange,
        searchDocumentOnchange,
        isDataAvailable,
        isDocumentsDataAvailable,
      ),
    );
  }, [tableData, documentTypesOptions, qualificationNameOptions]);

  useEffect(() => {
    setIsDataAvailable(!loading);
  }, [loading]);

  useEffect(() => {
    setIsDocumentsDataAvailable(!documentLoading);
  }, [documentLoading]);

  function loadTableData() {
    if (streamData && streamData?.streams?.data) {
      setTableData(streamData?.streams?.data);
    }
  }

  function loadDocumentTableData() {
    if (documentData && documentData?.documentTypes?.data) {
      setDocumentTypesOptions(documentData?.documentTypes?.data);
    }
  }

  async function loadInitialData() {
    let param = {
      limit: 0,
    } as any;

    const qualificationNameResponse = await getFilteredQualificationNames(param);
    setQualificationNameOptions(qualificationNameResponse?.options);
  }

  function loadEditData() {
    const editQualData = {
      qualificationName: editQualificationData?.name,
      documents: editQualificationData?.documentTypes[0]?.label
        ? editQualificationData?.documentTypes
        : editQualificationData?.documents,
    };
    setInitialValues(editQualData);
  }

  async function searchStreamOnchange(queryVariable: any) {
    if (streamData?.streams?.data?.length) {
      if (!queryVariable.length) {
        loadTableData();
      } else {
        let param = {
          limit: 0,
          skip: 0,
          filters: { name: { regex: queryVariable } },
        };

        let streamResponse = await graphqlQuery(getStreamQuery, param);
        setTableData(streamResponse?.data?.streams?.data);
      }
    }
  }

  async function searchDocumentOnchange(queryVariable: any) {
    if (documentData?.documentTypes?.data?.length) {
      if (!queryVariable.length) {
        loadDocumentTableData();
      } else {
        let param = {
          limit: 0,
          skip: 0,
          filters: { name: { regex: queryVariable } },
        };

        let documentResponse = await graphqlQuery(getDocumentsQuery, param);
        setDocumentTypesOptions(documentResponse?.data?.documentTypes?.data);
      }
    }
  }

  const AddQualificationTemplate = useCallback(
    (props: { formFields: any }) => {
      const { formFields } = props;

      return (
        <>
          <FormWrapper>
            {formFields ? (
              formFields.map((field: any, i: number) => (
                <ElementWrapper index={i}>{field}</ElementWrapper>
              ))
            ) : (
              <SpinnerView>
                <LoaderSpinner />
              </SpinnerView>
            )}
          </FormWrapper>

          <ModalSaveAndCancelButtonGroup
            saveLabel={isEditQualificationModal ? 'save.label' : 'add.label'}
            cancelLabel={'cancel.label'}
            onCancel={closeQualificationModal}
            isSubmitting={false}
          />
        </>
      );
    },
    [setStreams, setDocuments],
  );

  return (
    <>
      {formSchema ? (
        <FormRenderer
          componentMapper={componentMapper}
          FormTemplate={AddQualificationTemplate}
          schema={formSchema}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validatorMapper={validatorMapper}
        />
      ) : (
        <Placeholder />
      )}
    </>
  );
}

const Placeholder = styled.div`
  height: 705px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 724px;
`;

const ElementWrapper = styled.div<{ index: number }>`
  width: ${({ index }) => (index === 4 || index === 7 ? '100%' : '350px')};
  margin-bottom: ${({ index }) => (index === 4 || index === 7 ? 24 : 0)};
  height: ${({ index }) => (index === 4 || index === 7 ? '272px' : index === 0 ? '84px' : '47px')};
`;

const SpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 580px;
  width: 724px;
`;
