import React from 'react';
import { View } from 'react-native';
import { colors } from 'src/styles/theme/styles';

interface propType {
  style?: object;
  color?: string;
  borderwidth?: number;
}

export default function BottomBorder(props: propType) {
  const { style, color = colors.inputBorder, borderwidth = 1 } = props;
  return <View style={{ borderWidth: borderwidth, borderColor: color, ...style }} />;
}
