import { imageTypes } from "src/utils/utility";

type ImageType = (typeof imageTypes)[number];

export type TFile = {
  id: string;
  name: string;
  uri: string;
  fileType: ImageType | string;
  category?: string;
};

type TSplitFiles = { images: TFile[]; attachments: TFile[] };

// Function to split the files into images and attachments
export const splitFileTypes = (files: TFile[]): TSplitFiles => {
  if (!Array.isArray(files) || files.length === 0) {
    return { images: [], attachments: [] };
  }
  return files.reduce<TSplitFiles>(
    (acc, file) => {
      if (Object.values(imageTypes).includes(file.fileType as ImageType)) {
        acc.images.push(file);
      } else {
        acc.attachments.push(file);
      }
      return acc;
    },
    { images: [], attachments: [] }
  );
};
