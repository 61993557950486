import { ClickAwayListener, TableBody, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import Paginations from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { TableActions, TableCells, TableDiv, TableRows } from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { createSearchFilter, debounce } from 'src/components/services';
import { getCommonNameSearchFilter } from 'src/components/services/filters';
import { AUDIENCE_TYPE, AUTOCOMPLETE_MODULE, ERROR, SUCCESS, limit } from 'src/constant';
import { height } from 'src/constant/device';
import { useFilter } from 'src/contexts/filter-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { useHistory, useParams } from 'src/routes/routing.web';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useForm } from 'react-hook-form';
import OtherPaymentForm from 'src/components/molecules/Payment/Institute/ControlPanel/OtherPaymentForm';
import AudienceWrapper from 'src/components/molecules/Audience/AudienceWrapper';
import { useCreateCustomFee, useExpireCustomFee, useGetCustomFees, useResendMailToCustomFeePayers } from 'src/graphql/payment';
import { ADD_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import ERROR_MSG from 'src/constant/error';
import { TouchableOpacity } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { useThemeSystem } from 'src/contexts/theme-context';
import { downloadFileRestAPI } from 'src/utils/utility';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { CustomFee, LINK_STATUS } from './helpers';
import { faSync, faUnlink } from '@fortawesome/pro-regular-svg-icons';
import { OverflowMenuIcon } from 'src/components/atoms/ActionIcons';
import ButtonGrp from 'src/components/atoms/ButtonGroup/index.web';

const headCells1 = [
  {
    id: 'name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'amount',
    label: 'amount.label',
    align: 'right',
    disableSorting: true,
    style: { paddingRight: 60 },
  },
  {
    id: 'merchant_name',
    label: 'merchantName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'audience_type',
    label: 'audienceType.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'linkStatus',
    label: 'linkStatus.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
  },
];

export default function OtherPayment() {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const history = useHistory();
  let { setAlertDetails, alertState } = useAlertSystem();
  const { theme } = useThemeSystem();
  const [paymentRecipient, setPaymentRecipient] = useState<string>('');
  const [emailConfirmationModal, setEmailConfirmationModal] = useState<boolean>(false);
  const [linkConfirmationModal, setLinkConfirmationModal] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<CustomFee | null>(null);
  const [options, setOptions] = useState<boolean>(false);
  const { createCustomFee } = useCreateCustomFee();
  const { resendMailToCustomFeePayers } = useResendMailToCustomFeePayers();
  const { expireCustomFee } = useExpireCustomFee();
  const { getCustomFees, customFeesData, customFeesLoading, refetchCustomFees } =
    useGetCustomFees();

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  let searchValue = '' as string;
  let row;
  let rowLimit = 0;
  let { page = 1, dataLimit = limit }: any = useParams();
  rowLimit = parseInt(dataLimit);
  if (filters && filters?.payment?.on) {
    searchValue = filters?.payment?.search;
  }

  const [searching, setSearchingPayment] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [modalState, handleModal] = useState(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [audienceType, setAudienceType] = useState(null);
  const [importAttachment, handleImportAttachment] = useState<{
    documentName?: String;
    document?: File | undefined;
  }>({});

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    setSearchingPayment(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [page, searchValue]);

  function setTitle() {
    setHeading([
      {
        text: t('otherPayment.label'),
        url: '',
      },
    ]);
  }

  function handleFetch() {
    const filters = getCommonNameSearchFilter(searchValue);
    let customFeeFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    getCustomFees({ variables: customFeeFilter });
  }

  row = customFeesData?.customFees?.data;
  const totalCount = customFeesData?.customFees?.totalCount;

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    page,
  );

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.PAYMENT]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchPayment(payment: string) {
    let isClear = payment?.length === 0;
    persistSearch(!isClear, payment);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchPayment(q), 500),
    [],
  );

  const handleSearch = (payment: string) => {
    setSearchingPayment(payment);
    delayedQuery(payment);
  };

  const addPaymentModal = () => {
    hideAlert();
    handleModal(true);
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  function handleRedirect(item: any) {
    setHeading([
      {
        text: ' ',
      },
    ]);
    history.push(`/other-payment/${item?.id}/limit/${rowLimit}/page/1`, {
      feeName: item?.name,
    });
  }

  function closeModal() {
    handleModal(false);
    reset({});
    setPaymentRecipient('');
    handleImportAttachment({});
    setAudienceType(null);
  }

  const handleAudienceUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      };
      handleImportAttachment(fileObject);
      e.target.value = null;
    }
  };

  const handleAddPayment = (formData: any, audience?: any) => {
    if (audience && audience?.length > 0) {
      handleAddPaymentAudience(formData, audience);
    } else {
      handleAddPaymentImport(formData);
    }
  };

  const handleAddPaymentImport = async (formData: any) => {
    setCanClick(false);
    try {
      if (Object.keys(importAttachment).length === 0) {
        throw new Error('Select a file for import');
      }
      const paymentModes = formData?.payment_mode?.map((item: any) => item?.value);
      const payload = {
        name: formData?.name,
        payerType: 'FILE_IMPORTED',
        file: importAttachment?.document,
        allowedPaymentModes: paymentModes,
        ...(formData?.merchant_account?.value ? { merchant: formData.merchant_account.value } : {}),
      };
      const response = await createCustomFee({ variables: { payload: payload } });
      if (response?.data?.createCustomFee?.id) {
        setAlertDetails({ message: ADD_SUCCESS.OTHER_PAYMENT, level: SUCCESS });
        closeModal();
        if (refetchCustomFees) refetchCustomFees();
      } else {
        throw new Error(ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleAddPaymentAudience = async (formData: any, audience: any) => {
    setCanClick(false);
    try {
      const paymentModes = formData?.payment_mode?.map((item: any) => item?.value);
      const payload = {
        name: formData?.name,
        amount: parseFloat(formData?.amount),
        payerType: audienceType === 'SUBJECTGROUP' ? 'GROUP' : audienceType,
        payers: audience,
        allowedPaymentModes: paymentModes,
        ...(formData?.merchant_account?.value ? { merchant: formData.merchant_account.value } : {}),
      };
      const response = await createCustomFee({ variables: { payload: payload } });
      if (response?.data?.createCustomFee?.id) {
        setAlertDetails({ message: ADD_SUCCESS.OTHER_PAYMENT, level: SUCCESS });
        closeModal();
        if (refetchCustomFees) refetchCustomFees();
      } else {
        throw new Error(ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  function exportReport(url: string, fileName: string) {
    setOptions(false);
    downloadFileRestAPI(url, fileName, (err) => {
      setAlertDetails({ message: err.message || 'Failed to download', level: ERROR });
    });
    setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
  }

  function handleLinkConfirmationModal(item: CustomFee) {
    setCurrentItem(item);
    setLinkConfirmationModal(true);
  }

  function closeLinkConfirmationModal() {
    setCurrentItem(null);
    setLinkConfirmationModal(false);
  }

  function handleEmailConfirmationModal(item: CustomFee) {
    setCurrentItem(item);
    setEmailConfirmationModal(true);
  }

  function closeEmailConfirmationModal() {
    setCurrentItem(null);
    setEmailConfirmationModal(false);
  }

  async function handleResendEmail() {
    setCanClick(false);
    try {
      const response = await resendMailToCustomFeePayers({ variables: { customFeeId: currentItem?.id } });
      if (response?.data?.reSendMailToCustomFeePayers?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.RESEND_EMAILS, level: SUCCESS });
        setCanClick(true);
        closeEmailConfirmationModal();
        if (refetchCustomFees) refetchCustomFees();
      } else {
        throw new Error(
          response?.data?.reSendMailToCustomFeePayers?.message || ERROR_MSG.GENERIC_ERROR,
        );
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  async function handleDeactivateLink() {
    setCanClick(false);
    try {
      const response = await expireCustomFee({ variables: { customFeeId: currentItem?.id } });
      if (response?.data?.expireCustomFee) {
        setAlertDetails({ message: UPDATE_SUCCESS.DEACTIVATE_LINK, level: SUCCESS });
        setCanClick(true);
        closeLinkConfirmationModal();
        if (refetchCustomFees) refetchCustomFees();
      } else {
        throw new Error(ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  return (
    <>
      <LoadContentWrapper>
        <FlexRowView>
          <SearchWrapper>
            <Search
              id="paymentSearch"
              handleChange={handleSearch}
              value={searching}
              label={t('searchPayments.label')}
            />
          </SearchWrapper>

          <AddCriteriaWrapper>
            <OutlineButton
              label={t('downloadTemplate.text')}
              onPress={() => exportReport('customFees/template', 'CustomFeesTemplate.xlsx')}
              style={{ height: 30 }}
            />
            <SecondaryBtn label={t('addPayment.label')} onPress={addPaymentModal} />

            {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 && (
              <OverflowMenuIcon
                onPress={() => {
                setOptions(!options);
                }}
              />
            )}

            {options && (
              <ClickAwayListener onClickAway={() => setOptions(false)}>
                <ButtonGroupWrapper>
                  <ButtonGrp
                    width={250}
                    top={20}
                    buttonData={[
                      {
                        key: 'download-all-reports',
                        value: t('downloadAllReports.label'),
                        onPress: () =>
                          exportReport('customFees/payers/report', 'OtherPaymentReports.xlsx'),
                        prefixIcon: 'download',
                        customIcon: true,
                      },
                    ]}
                  />
                </ButtonGroupWrapper>
              </ClickAwayListener>
            )}
          </AddCriteriaWrapper>
        </FlexRowView>

        <TableDiv>
          <TblContainer height={totalCount <= rowLimit ? height - 270 : height - 285}>
            <TblHead setHeadCells={setHeadCells} />
            {!customFeesLoading ? (
              <>
                {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 && (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                      <TableRows key={i}>
                        <TableCells
                          value={item?.name}
                          color={colors.primaryColor}
                          clickable={true}
                          onPress={() => handleRedirect(item)}
                        />
                        <TableCells
                          value={
                            item?.amount || item?.amount == 0 ? `\u20B9 ${item.amount}` : 'N/A'
                          }
                          align={'right'}
                          style={{ paddingRight: 60 }}
                        />
                        <TableCells
                          value={
                            item?.merchant?.label && item.merchant.label !== 'NA'
                              ? item.merchant.label
                              : 'N/A'
                          }
                        />
                        <TableCells value={item?.payerType || 'N/A'} />
                        <TableCells
                          value={item?.status || 'N/A'}
                          color={
                            item?.status
                              ? item.status === LINK_STATUS.ACTIVE
                                ? colors.green
                                : colors.errorColor
                              : colors.primaryText
                          }
                        />
                        <TableActions align="right">
                          <TableActionView>
                            {item?.status === LINK_STATUS.ACTIVE && (
                              <Tooltip title={t('deactivateLink.label')}>
                                <TouchableOpacity
                                  onPress={() => handleLinkConfirmationModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faUnlink}
                                    color={theme?.table?.editIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            )}
                            <Tooltip title={t('resendEmail.label')}>
                              <TouchableOpacity
                                onPress={() => handleEmailConfirmationModal(item)}>
                                <FontAwesomeIcon
                                  icon={faSync}
                                  color={theme?.table?.editIconColor}
                                />
                              </TouchableOpacity>
                            </Tooltip>

                            <Tooltip title="Download Report">
                              <TouchableOpacity
                                onPress={() =>
                                  exportReport(
                                    `customFees/payers/report?customFeeId=${item?.id}`,
                                    `${item?.name || 'OtherPayment'}.xlsx`,
                                  )
                                }>
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  color={theme?.table?.editIconColor}
                                />
                              </TouchableOpacity>
                            </Tooltip>
                          </TableActionView>
                        </TableActions>
                      </TableRows>
                    ))}
                  </TableBody>
                )}
                {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length === 0 && (
                  <TableRows style={{ height: 0.58 * height }}>
                    <TableCells colspan={5} align="center" style={{ borderBottom: 'none' }}>
                      <NormalTextSelect value={'No records found'} />
                    </TableCells>
                  </TableRows>
                )}
              </>
            ) : (
              <SpinnerView>
                <LoaderSpinner />
              </SpinnerView>
            )}
          </TblContainer>
          <Paginations
            pathName={'control-panel/other-payment'}
            count={Math.ceil(totalCount / rowLimit)}
            total={totalCount}
            page={page}
            rowLimit={rowLimit}
            hidePagination={totalCount <= rowLimit}
          />
        </TableDiv>

        <AudienceWrapper
          isSubmitting={!canClick}
          onAlert={alertState}
          handleSave={handleAddPayment}
          setModalVisible={closeModal}
          modalVisible={modalState}
          handleSubmit={handleSubmit}
          Headerpopup={t('addPayment.label')}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel={t(
            paymentRecipient === 'Select Audience' ? 'next.label' : 'save.label',
          )}
          width={544}
          height={0.65 * height}
          maxWidth={'lg'}
          types={[AUDIENCE_TYPE.BATCH, AUDIENCE_TYPE.INDIVIDUAL]}
          audienceType={audienceType}
          setAudienceType={setAudienceType}
          handleNext={paymentRecipient === 'Import via Excel' ? handleAddPayment : null}>
          <OtherPaymentForm
            control={control}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            paymentRecipient={paymentRecipient}
            setPaymentRecipient={setPaymentRecipient}
            importAttachment={importAttachment}
            handleImportAttachment={handleImportAttachment}
            handleFileUpload={handleAudienceUpload}
          />
        </AudienceWrapper>

        <DeleteModal
          isSubmitting={!canClick}
          modalVisible={emailConfirmationModal}
          setModalVisible={closeEmailConfirmationModal}
          Headerpopup={t('resendEmail.label')}
          width={420}
          handleSave={handleResendEmail}
          deleteButtonColor={colors.primaryColor}
          deleteButtonText={'confirm.label'}>
          {t('resendEmail.confirmation.text')}
        </DeleteModal>

        <DeleteModal
          isSubmitting={!canClick}
          modalVisible={linkConfirmationModal}
          setModalVisible={closeLinkConfirmationModal}
          Headerpopup={t('deactivateLink.label')}
          width={420}
          handleSave={handleDeactivateLink}
          deleteButtonColor={colors.primaryColor}
          deleteButtonText={'confirm.label'}>
          {t('deactivateLink.confirmation.text', { values: { payer: currentItem?.name } })}
        </DeleteModal>
      </LoadContentWrapper>
    </>
  );
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 10;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  max-width: 280px;
  margin-right: 8px;
`;

const AddCriteriaWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const SpinnerView = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 13px;
`;

const ButtonGroupWrapper = styled.View`
  position: absolute;
`;