import React, { useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import EventModalContent from '../Event/EventModalContent.web';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { SD } from 'src/constant/standardDimensions';

export const EventItemWithModal = ({ item, children }: any) => {
  const { t } = useI18n();
  const [open, setOpen] = useState(false);
  return (
    <>
      <TouchableOpacity onPress={() => setOpen(true)}>{children}</TouchableOpacity>
      <NormalModal
        showFooter={false}
        setModalVisible={() => setOpen(false)}
        modalVisible={open}
        handleSave={() => { }}
        Headerpopup={t('event.label')}
        width={SD.primaryInfoModal.width}
        height={SD.primaryInfoModal.height}
        maxWidth={"lg"}>
        <EventModalContent eventId={item?.id} />
      </NormalModal>
    </>
  );
};
