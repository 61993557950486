// === Legacy Login === It should be refactored or removed in the future.

import { gql, useMutation } from '@apollo/client';

const loginInstituteUserMutation = gql`
  mutation ($credential: LoginInstituteUserInput!) {
    loginInstituteUser(credential: $credential) {
      token
      refresh
    }
  }
`;

const loginApplicantMutation = gql`
  mutation ($credential: LoginUserInput!) {
    applicantLogin(credential: $credential) {
      token
      refresh
    }
  }
`;

const refreshAccessToken = gql`
  mutation ($refresh: String!) {
    refreshAccessToken(refresh: $refresh, version: "1.0.1") {
      token
      refresh
    }
  }
`;

const LoginUserMutation = gql`
  mutation($userName: String!, $password: String!) {
    loginUser(password: $password, userName: $userName) {
      token
      refresh
    }
  }
`;

export { refreshAccessToken };

export function useLoginInstituteUser() {
  const [loginInstituteUser, { data: instituteUser, loading: userLoading, error: userError }] = useMutation(loginInstituteUserMutation);
  return { loginInstituteUser: loginInstituteUser, instituteUser, userLoading, userError };
}

export function useLoginApplicant() {
  const [loginApplicant, { data: applicant, loading: applicantLoading, error: applicantError }] = useMutation(loginApplicantMutation);
  return { loginApplicant: loginApplicant, applicant, applicantLoading, applicantError };
}

export function useRefreshAccessToken() {
  const [refreshToken, { data: instituteUser, loading: refreshLoading, error: refreshError }] = useMutation(refreshAccessToken);
  return { refreshToken, instituteUser, refreshLoading, refreshError };
}

export function useLoginUser() {
  const [loginUserMutation, { data: loginUserData, loading: loginUserLoading, error: loginUserError }] = useMutation(LoginUserMutation);
  return { loginUserMutation, loginUserData, loginUserLoading, loginUserError };
}
