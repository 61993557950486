import {
    fileSizeValidator,
    fileTypeValidator,
    imageSizeValidator,
    marksValidator,
    contactValidator,
    documentValidator,
} from 'src/utils/customValidators/index'

export const validatorMapper = {
    'file-size': fileSizeValidator,
    'file-type': fileTypeValidator,
    'image-size': imageSizeValidator,
    'marks': marksValidator,
    'contact': contactValidator,
    'document': documentValidator,
};