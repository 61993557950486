import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 40 } = props;
  return (
    <Svg
      width={size * 3.2}
      height={size}
      viewBox="0 0 113 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M40.6275 39.9038C45.4551 39.9038 47.9663 37.1511 49.1148 34.6872H49.4652V40H57.6V20V0H49.3678V14.8219H49.1148C48.0052 12.4158 45.6108 9.47065 40.608 9.47065C34.0479 9.47065 28.5 14.514 28.5 24.6776C28.5 34.5717 33.8143 39.9038 40.6275 39.9038ZM43.2554 33.3782C39.187 33.3782 36.9678 29.7979 36.9678 24.6391C36.9678 19.5188 39.148 15.9962 43.2554 15.9962C47.2849 15.9962 49.543 19.3648 49.543 24.6391C49.543 29.9134 47.246 33.3782 43.2554 33.3782Z"
        fill="#2B78CA"
      />
      <Path
        d="M14.8333 40C22.2305 40 27.2138 36.4389 28.3818 30.9528L20.7121 30.4524C19.875 32.7045 17.7338 33.8787 14.9695 33.8787C10.8232 33.8787 8.19529 31.1646 8.19529 26.7565V26.7372H28.557V24.4851C28.557 14.437 22.4057 9.47064 14.5024 9.47064C5.70361 9.47064 0 15.6497 0 24.7738C0 34.1482 5.62575 40 14.8333 40ZM8.19529 21.6554C8.37049 18.2868 10.9595 15.5919 14.6386 15.5919C18.2399 15.5919 20.7316 18.1328 20.751 21.6554H8.19529Z"
        fill="#2B78CA"
      />
      <Path
        d="M57.6 40H65.7679V34.7039H66.1374C67.2848 37.169 69.7935 39.923 74.6164 39.923C81.423 39.923 86.7322 34.5884 86.7322 24.6895C86.7322 14.5209 81.1897 9.47522 74.6359 9.47522C69.6379 9.47522 67.2459 12.4218 66.1374 14.8291H65.8846V0L57.6 0V20V40ZM65.7095 24.6509C65.7095 19.3741 67.9655 16.0039 71.9911 16.0039C76.0945 16.0039 78.2726 19.5282 78.2726 24.6509C78.2726 29.8122 76.0556 33.3943 71.9911 33.3943C68.0043 33.3943 65.7095 29.9278 65.7095 24.6509Z"
        fill="#FF9000"
      />
      <Path
        d="M95.9182 40C100.333 40 103.191 38.0934 104.65 35.3394H104.883V40H112.74V19.4897C112.74 12.441 106.711 9.47522 100.06 9.47522C92.9038 9.47522 88.1976 12.8647 87.0502 18.2571L94.7124 18.8734C95.2764 16.909 97.0461 15.4646 100.022 15.4646C102.841 15.4646 104.456 16.8705 104.456 19.2971V19.4126C104.456 21.3192 102.414 21.5696 97.2211 22.0703C91.3091 22.6095 86 24.5739 86 31.1796C86 37.0342 90.2201 40 95.9182 40ZM109.842 0H90.9007V4.44873H109.842V0ZM98.2907 34.338C95.7431 34.338 93.9151 33.1632 93.9151 30.91C93.9151 28.5989 95.8404 27.4627 98.7575 27.0583C100.566 26.8079 103.522 26.3842 104.514 25.7294V28.8686C104.514 31.9692 101.927 34.338 98.2907 34.338Z"
        fill="#FF9000"
      />
    </Svg>
  );
}

export default SvgComponent;
