import {
  DashboardEmptyContainer as EmptyContainer,
  DashboardListingScrollContainer as ScrollContainer,
} from "src/components/organism/Dashboard/Common/CommonHelpers";

import { ANNOUNCEMENT_TYPE } from "src/constant";
import { DT } from "src/constant/dateTime";
import { DashboardListing } from "./CommonHelpers";
import EventCard from "src/components/molecules/Event/EventCard";
import { EventItemWithModal } from "src/components/molecules/EventList";
import React from "react";
import { faCalendarDay } from "@fortawesome/pro-light-svg-icons";
import { format } from "date-fns";
import { getAnnouncementsQuery } from "src/graphql/communication/news-notices";
import { useI18n } from "src/i18n/hooks";
import { useQuery } from "@apollo/client";

function DashboardEvents() {
  const { t } = useI18n();
  const { data, loading } = useQuery(getAnnouncementsQuery, {
    variables: {
      filters: {
        type: { eq: ANNOUNCEMENT_TYPE[2] },
        date: { gte: format(new Date(), DT.DATE_FORMAT_SLASH) },
      },
    },
  });

  const ListEmptyComponent = () => (
    <EmptyContainer
      text={t("noEvents.text")}
      icon={faCalendarDay}
      loading={loading}
    />
  );

  const events = data?.getAnnouncements?.data ?? [];

  return (
    <DashboardListing
      title={"event.label"}
      redirectionURL={"/events"}
      allowRedirection={events.length > 0}
    >
      <ScrollContainer>
        {events.length > 0 ? (
          events.map((item) => (
            <EventItemWithModal
              children={
                <EventCard
                  data={item}
                  editAction={() => { }}
                  deleteAction={() => { }}
                  onPress={() => { }}
                  canEdit={false}
                  canDelete={false}
                  isSwipable={false}
                  size="sm"
                />
              }
              item={item}
            />
          ))
        ) : (
          <ListEmptyComponent />
        )}
      </ScrollContainer>
    </DashboardListing>
  );
}

export default DashboardEvents;
