import {
  APPLICANT_TYPE_ENUM,
  AdmissionStatus,
  ApplicationStatus,
  ApplicationStatusLabel,
} from 'src/constant';

import { ApplicationItemType } from './types';
import MobileHeader from 'src/components/hoc/MobileHeader';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import React, { useEffect } from 'react';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { getApplicationFeeBookQuery } from 'src/graphql/payment';
import { getApplicationStatusColor } from 'src/components/atoms/FunctionsList';
import { graphqlQuery } from 'src/graphql/util';
import navigationStrings from 'src/navigation/navigationStrings';
import styled from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import { checkRemark } from 'src/utils/utility';
import BlockRejectionReason from 'src/components/molecules/Admission/BlockRejectionReason';
import { useGetApplicationDetails } from 'src/graphql/applications/applications';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';

interface infoItemProps {
  label: string;
  value: string | ApplicationStatus;
  color?: string;
  rejectionReason?: any | null;
}

const ApplicationInformation = (props: {
  route: { params: { applId: string } };
  navigation: any;
}) => {
  const { t } = useI18n();
  const applId = props.route.params.applId;
  const { getApplicationDetails, applicationDetailsData, refetchApplicationDetails } =
    useGetApplicationDetails();
  const info = applicationDetailsData?.application;

  useEffect(() => {
    if (applId) {
      getApplicationDetails({ variables: { id: applId } });
    }
  }, [applId]);

  const InfoItem = (props: infoItemProps) => {
    const { label, value, color, rejectionReason } = props;
    return (
      <InfoItemWrapper>
        <SmallTextAtom value={label} />
        <NormaltextAtom value={value} color={color} lineHeight={2} />
        {rejectionReason && <BlockRejectionReason data={rejectionReason} width={'100%'} />}
      </InfoItemWrapper>
    );
  };

  async function makePayment(info: ApplicationItemType) {
    const feeBookData = await graphqlQuery(
      getApplicationFeeBookQuery,
      { applicationId: info?.id },
      false,
    );
    props.navigation.navigate(navigationStrings.APPLICATON_PAYABLE_LISTING, {
      feeBooks: feeBookData?.data?.getApplicationFeeBook?.feeBook,
      totalPayable: feeBookData?.data?.getApplicationFeeBook?.totalPayable,
      partialPayment: info?.fees?.installmentPayable,
      applicationId: info?.id,
      applicationIdForDisplay: info?.applicationId,
      paymentModes: info?.admission?.allowedPaymentModes,
      cancellationCharge: info?.fees?.cancellationFeeCharges,
    });
  }

  function editQualificationDetails() {
    props.navigation.navigate(navigationStrings.APPLICATION_FORM_VIEWER, {
      applId: info?.id,
    });
  }

  return (
    <>
      <MobileHeader
        label={t('application.label')}
        navigation={props.navigation}
        showEdit={
          [
            ApplicationStatus.INCOMPLETE,
            ApplicationStatus.REJECTED,
            ApplicationStatus.APPLICATION_FEE_PENDING,
          ].includes(info?.status) && info?.admission?.status !== AdmissionStatus.CLOSE
        }
        actionLabel={t('edit.label')}
        handleEdit={editQualificationDetails}
      />
      {info && Object.keys(info)?.length > 0 ? (
        <Wrapper>
          <InfoItem label={t('instituteName.label')} value={info.admission.institute.name} />
          <InfoItem label={t('courseName.label')} value={info.admission.course.alias} />
          <InfoItem label={t('className.label')} value={info.admission.class.alias} />
          <InfoItem
            label={t('status.label')}
            value={ApplicationStatusLabel[info.status] || info.status}
            color={getApplicationStatusColor(info.status)}
            rejectionReason={
              [ApplicationStatus.REJECTED, ApplicationStatus.BLOCKED].includes(info.status)
                ? checkRemark(info?.statusActivity, info.status)?.data
                : null
            }
          />
          {info.applicantType === APPLICANT_TYPE_ENUM[3] && (
            <InfoItem
              label={t('institute.label')}
              value={
                info.admission?.inHouseInstitutes?.find(
                  (e) => e.instituteCode === info?.inHouseDetail?.instituteCode,
                )?.instituteName ?? ''
              }
            />
          )}
          {info.applicantType === APPLICANT_TYPE_ENUM[3] && (
            <InfoItem label={t('studentId.label')} value={info?.inHouseDetail?.rollNo ?? ''} />
          )}

          {[
            ApplicationStatus.PAYMENT_AWAITED,
            ApplicationStatus.PRE_ADMISSION_CANCELLATION,
            ApplicationStatus.POST_ADMISSION_CANCELLATION,
            ApplicationStatus.ADMISSION_CANCELLATION,
          ].includes(info.status) && (
            <SecondaryBtn onPress={() => makePayment(info)} label={t('payment.make-payment')} />
          )}
        </Wrapper>
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </>
  );
};

const Wrapper = styled.View`
  margin: 24px;
`;

const InfoItemWrapper = styled.View`
  margin-bottom: 24px;
`;

const SpinnerView = styled.View`
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
`;

export default ApplicationInformation;
