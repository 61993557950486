import React, { useEffect, useRef, useState } from 'react';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import MediumText from 'src/components/atoms/Text/MediumText';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { height, isIOS } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import navigationStrings from 'src/navigation/navigationStrings';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import LottieView from 'lottie-react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { useCreateAdmissionPayment } from 'src/graphql/applications/applications';
import ERROR_MSG from 'src/constant/error';
import { showMessage } from 'src/components/molecules/NotificationWrapper';
import { ApplicationItemType } from '../../Applications/types';
import { properCase } from 'src/utils/utility';
import { getPaymentModeLabel } from 'src/components/molecules/Payment/Institute/utility';
import { PAYMENT_MODE_LABEL } from 'src/constant';

interface Fee {
  id: string;
  name: string;
  amount: number;
}

interface FeeBookType {
  groupInfo?: Fee[];
  groupId: string;
  groupName: string;
  amount: number;
  fees?: Fee[];
  totalPayable?: number;
}

interface ResultType {
  applicationId: string;
  paymentDetail: {
    mode: string;
    payId?: string;
    payerBank?: string;
    paymentDate?: string;
  };
}
interface propType {
  navigation: any;
  route: {
    params: {
      feeBookData: { getApplicationFeeBook: { feeBook: FeeBookType[]; totalPayable: number } };
      result: ResultType;
      applicationDetails: ApplicationItemType;
    };
  };
}

const AcceptPaymentApplicant = (props: propType) => {
  const { t } = useI18n();
  const { result, feeBookData, applicationDetails } = props?.route?.params;
  const [row, setRow] = useState<FeeBookType[]>();
  const [completed, setCompleted] = useState<boolean>(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const ref = useRef<LottieView>(null);
  const { createAdmissionPayment } = useCreateAdmissionPayment();
  let dataRows: FeeBookType[] = [];

  useEffect(() => {
    if (ref.current) {
      ref.current?.play();
    }
  }, [ref.current]);

  useEffect(() => {
    if (feeBookData && Object.keys(feeBookData).length > 0) {
      createDataObj();
    }
  }, [feeBookData]);

  const createObj = (item: any) => {
    const groupInfo = item?.map((i: any) => {
      return {
        id: i?.id,
        name: i?.name,
        amount: i?.amount,
      };
    });
    return groupInfo;
  };

  function createDataObj() {
    feeBookData &&
      feeBookData?.getApplicationFeeBook?.feeBook?.map((item: any) => {
        dataRows.push({
          groupId: item?.groupId,
          groupName: item?.groupName,
          amount: item?.amount,
          ...(item?.fees && item?.fees?.length > 0
            ? {
                groupInfo: createObj(item?.fees),
              }
            : {}),
        });
      });
    setRow([...dataRows]);
  }

  const makePayment = async () => {
    setCanClick(false);
    try {
      const payload = {
        applicationId: result?.applicationId,
        paymentDetail: {
          mode: result?.paymentDetail?.mode,
        }
      };

      if (result?.paymentDetail?.payId) {
        payload.paymentDetail = result.paymentDetail;
      }

      const response = await createAdmissionPayment({ variables: { ...payload } });
      if (!response.hasOwnProperty('errors') && response?.data?.createAdmissionPayment) {
        setCompleted(true);
      } else {
        showMessage({
          message: ERROR_MSG.PAYMENT_UNSUCCESSFUL,
          type: 'danger',
          position: 'top',
          icon: 'danger',
        });
      }
      setCanClick(true);
    } catch (e: any) {
      showMessage({
        message: ERROR_MSG.PAYMENT_UNSUCCESSFUL + ': ' + e.message,
        type: 'danger',
        position: 'top',
        icon: 'danger',
      });
      setCanClick(true);
    }
  };

  function handlePaymentSuccess() {
    setCompleted(false);
    props.navigation.navigate(navigationStrings.NATIVE_TABBAR_ADMIN);
  }

  return (
    <Container>
      <MobileHeader
        label={t('paymentInfo.label')}
        backIcon={!completed}
        navigation={props.navigation}
        style={{ textAlign: 'center' }}
      />
      {!completed ? (
        result && applicationDetails && row && row?.length > 0 ? (
          <>
            <TableWrapper>
              <MediumTextSelect
                value={t('applicantDetails.label')}
                color={colors.primaryText}
                fontFamily={fonts.semibold}
              />
              <InformationView>
                <RowComponentView>
                  <ChildComponentView>
                    <SmallTextAtom value={t('name.label')} />
                    <NormaltextAtom
                      value={properCase(applicationDetails?.user?.personalDetails?.fullName || '-')}
                      color={colors.primaryText}
                    />
                  </ChildComponentView>
                  <ChildComponentView>
                    <SmallTextAtom value={t('applicationId.label')} />
                    <NormaltextAtom
                      value={applicationDetails?.applicationId || '-'}
                      color={colors.primaryText}
                    />
                  </ChildComponentView>
                </RowComponentView>

                <SecondRowComponentView>
                  <ChildComponentView>
                    <SmallTextAtom value={t('paymentMode.label')} />
                    <NormaltextAtom
                      value={PAYMENT_MODE_LABEL[result?.paymentDetail?.mode] || '-'}
                      color={colors.primaryText}
                    />
                  </ChildComponentView>
                  <ChildComponentView>
                    <SmallTextAtom
                      value={getPaymentModeLabel(result?.paymentDetail?.mode)}
                    />
                    <NormaltextAtom
                      value={result?.paymentDetail?.payId ? result?.paymentDetail?.payId : ''}
                      color={colors.primaryText}
                    />
                  </ChildComponentView>
                </SecondRowComponentView>
              </InformationView>
              <MediumTextSelect
                value={t('paymentDetails.label')}
                style={{ marginTop: 24 }}
                color={colors.primaryText}
                fontFamily={fonts.semibold}
              />
              <TableHeadWrapper>
                <NormaltextAtom value={t('payment.particulars')} />
                <NormaltextAtom value={t('payment.amount')} />
              </TableHeadWrapper>
              <TableBodyWrapper nestedScrollEnabled={true}>
                <TableRowsWrapper>
                  {row.map((item, index) => {
                    return (
                      <>
                        <GroupHeaderWrapper>
                          <ModuleNameWrapper>
                            <MediumText
                              lines={1}
                              value={item.groupName}
                              lineHeight={2.4}
                              color={colors.primaryText}
                            />
                          </ModuleNameWrapper>
                          <MediumTextSelect
                            fontFamily={fonts.semibold}
                            value={`\u20B9  ${item.amount}`}
                            color={colors.primaryText}
                          />
                        </GroupHeaderWrapper>
                        {item?.groupInfo?.length > 1 &&
                          item.groupInfo.map((subItem) => {
                            return (
                              <RowItem>
                                <ModuleNameWrapper>
                                  <NormaltextAtom
                                    lines={1}
                                    lineHeight={2}
                                    value={subItem.name}
                                    fontSize={1.6}
                                  />
                                </ModuleNameWrapper>
                                <NormaltextAtom
                                  lineHeight={2}
                                  value={`\u20B9  ${subItem.amount}`}
                                  fontSize={1.6}
                                />
                              </RowItem>
                            );
                          })}
                      </>
                    );
                  })}
                </TableRowsWrapper>
              </TableBodyWrapper>
            </TableWrapper>
            <TotalWrapper>
              <MediumTextSelect
                fontFamily={fonts.semibold}
                value={
                  applicationDetails?.fees?.cancellationFeeCharges
                    ? t('courseFee.label')
                    : t('totalPayment.label')
                }
                color={colors.primaryText}
              />
              <MediumTextSelect
                fontFamily={fonts.semibold}
                value={`\u20B9 ${feeBookData?.getApplicationFeeBook?.totalPayable}`}
                color={colors.primaryText}
              />
            </TotalWrapper>
            {applicationDetails?.fees?.installmentPayable &&
              !applicationDetails?.fees?.cancellationFeeCharges && (
              <PartialPaymentWrapper>
                <MediumTextSelect
                  fontFamily={fonts.semibold}
                  value={t('partialPayableAmount.label')}
                  color={colors.primaryText}
                />
                <MediumTextSelect
                  fontFamily={fonts.semibold}
                  value={`\u20B9 ${applicationDetails?.fees?.installmentPayable}`}
                  color={colors.primaryText}
                />
              </PartialPaymentWrapper>
            )}
            {applicationDetails?.fees?.cancellationFeeCharges && (
              <PartialPaymentWrapper>
                <MediumTextSelect
                  fontFamily={fonts.semibold}
                  value={t('cancellationCharge.label.label')}
                  color={colors.primaryText}
                />
                <MediumTextSelect
                  fontFamily={fonts.semibold}
                  value={`\u20B9 ${applicationDetails?.fees?.cancellationFeeCharges}`}
                  color={colors.primaryText}
                />
              </PartialPaymentWrapper>
            )}
            <BtnWrapper>
              <SecondaryBtn
                onPress={makePayment}
                label={t('payment.proceed-payment')}
                canLoad={!canClick}
              />
            </BtnWrapper>
          </>
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )
      ) : (
        <LoadingWrapper>
          <LottieWrapper>
            <LottieView
              ref={ref}
              source={require('src/assets/lottie/tickmark.json')}
              autoPlay={false}
              loop={false}
              style={{ height: 100 }}
            />
          </LottieWrapper>
          <TextWrapper>
            <MediumTextSelect
              value={t('paymentSuccessfull.label')}
              color={colors.primaryText}
              fontFamily={fonts.semibold}
            />
          </TextWrapper>
          <ButtonWrapper>
            <SecondaryBtn onPress={handlePaymentSuccess} label={t('done.label')} />
          </ButtonWrapper>
        </LoadingWrapper>
      )}
    </Container>
  );
};

export default AcceptPaymentApplicant;

const Container = styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

const TableWrapper = styled.SafeAreaView`
  flex: 1;
  margin-horizontal: 24px;
  margin-top: 24px;
`;

const TableRowsWrapper = styled.ScrollView`
  max-height: ${height - (isIOS ? 522 : 504)};
`;

const TableHeadWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0px 8px 0px;
  margin-bottom: 12px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
`;

const TableBodyWrapper = styled.ScrollView``;

const BtnWrapper = styled.View`
  padding-bottom: 24px;
  padding-horizontal: 24px;
`;

const GroupHeaderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TotalWrapper = styled.View`
  border-top-width: 1px;
  border-top-color: ${colors.dividerColor};
  padding: 24px 0px;
  flex-direction: row;
  justify-content: space-between;
  margin-horizontal: 24px;
`;

const RowItem = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-left: 24px;
  margin-bottom: 36px;
`;

const LoadingWrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  margin-top: -24px;
`;

const SpinnerView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  height: ${height - 300}px;
`;

const InformationView = styled.View`
  margin-top: 16px;
`;

const RowComponentView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SecondRowComponentView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
`;

const ChildComponentView = styled.View`
  flex: 1;
`;

const ModuleNameWrapper = styled.View`
  display: flex;
  align-items: flex-start;
  width: 60%;
  padding-right: 8px;
`;

const ButtonWrapper = styled.View`
  position: absolute;
  bottom: 24px;
  width: 100%;
`;

const TextWrapper = styled.View`
  position: absolute;
  top: 60%;
`;

const PartialPaymentWrapper = styled(TotalWrapper)`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
  margin-bottom: 24px;
`;

const LottieWrapper = styled.View`
  height: 140px;
  width: 100%;
`;
