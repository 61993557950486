import { ApolloProvider } from '@apollo/client/react';
import { ApolloClient, DocumentNode, from } from '@apollo/client';
import config from '../config/config.json';
import { setContext } from '@apollo/client/link/context';
import { getStorageFunction } from '../components/services/storage-service';
import { authToken, cache } from 'src/utils/manageState';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { KEYS } from 'src/constant/key';

const httpLink = createUploadLink({
  uri: config.Server.url,
});

const authLink = setContext(async (_, { headers }) => {
  let { edbaToken } = authToken();
  if (!edbaToken) {
    edbaToken = await getStorageFunction(KEYS.ACCESS_TOKEN);
  }
  //   console.log('authlink generator called');
  //   console.log('authlink', {
  //     ...headers,
  //     authorization: `Bearer ${edbaToken}`,
  //   });
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${edbaToken}`,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      console.log('err-----', err);
      switch (err.extensions.code) {
        // Apollo Server sets code to UNAUTHENTICATED
        // when an AuthenticationError is thrown in a resolver
        case 'UNAUTHENTICATED':
          const { edbaToken } = authToken();
          // Modify the operation context with a new token
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${edbaToken}`,
            },
          });
          // Retry the request, returning the new observable
          return forward(operation);

        case 'BAD_USER_INPUT':
          console.log(JSON.stringify(err.message));
        // alert(JSON.stringify(err.message))
      }
    }
  }

  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache,
  connectToDevTools: true,
});

export async function apolloClearStore() {
  return client.clearStore();
}

export function clearSingleUserCache(userId: string) {
  const serializedState = client.cache.extract();
  console.log(userId, 'userId', serializedState);
  for (const property in serializedState) {
    console.log(property, 'propoerty');
    // if (true) {
    //   // check condition for current user
    //   client.cache.evict({ id: property });
    // }
  }
  return true;
}

export const useAsyncQuery = async (query: DocumentNode, variables: any) => {
  if (client) {
    try {
      const result = await client.query({
        query,
        variables,
      });
      return result
    } catch (err) {
      console.log(err);
    }
  }
  return null;
};