import React from 'react';
import { ApplicationItemType } from './types';
import styled from 'styled-components/native';
import { colors, fontWeight, fonts } from 'src/styles/theme/styles';

interface Props {
  applicationDetail: ApplicationItemType | null;
}

export default function ApplicationFeesFormWeb(props: Props) {
  const { applicationDetail } = props;
  return (
    <ContainerWrapper>
      <ModalText>
        <ModalText>To successfully finalize your admission submission process, a nominal application fee of</ModalText>
        <ModalText style={{ fontWeight: fontWeight[600], color: colors.primaryText }}>{` \u20B9 ${applicationDetail?.admission?.applicationFeeDetail?.feeAmount || '-'} `}</ModalText>
        <ModalText>is required.</ModalText>
      </ModalText>
    </ContainerWrapper>
  );
}

const ContainerWrapper = styled.View`
  margin-top: 12px;;
  margin-bottom: 24px;
`;

const ModalText = styled.Text`
  font-size: 16px;
  line-height: 24px;
  font-family: ${fonts.regular};
  color: ${colors.secondaryText};
`;