import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Control, FieldErrors, FieldValues, UseFormSetValue } from 'react-hook-form/dist/types';
import { DT } from 'src/constant/dateTime';
import formJSON from 'src/form-json/assessment/Results/publish-result.json';
import Element from '../Forms/ApplicationElement.web';
import styled from 'styled-components';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { colors, fontWeight, fonts } from 'src/styles/theme/styles';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { useI18n } from 'src/i18n/hooks';

interface Props {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  exam: string;
}

export default function PublishResultForm(props: Props) {
  const { control, errors, setValue, exam } = props;
  const { t } = useI18n();
  const [elements, setElements] = useState<any>({});
  const [currentDate, setCurrentDate] = useState<string>(format(new Date(), DT.DATE_FORMAT_SLASH));
  const { fields } = elements ?? {};

  useEffect(() => {
    setValue('publishDate', format(new Date(), DT.DATE_FORMAT_SLASH));
    setElements(formJSON[0]);
    return () => {
      setValue('publishDate', null);
    };
  }, []);

  function handleCustomSelect(id: string, data: any) {
    setCurrentDate(data);
  }

  return (
    <>
      {fields ? (
        <>
          {fields.map((field: any) => (
            // @ts-ignore
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
              handleCustomSelect={handleCustomSelect}
            />
          ))}

          <ContentWrapper>
            <MediumTextSelect
              value={t('publishResults.confirmation.text', { values: { examName: exam } })}
              color={colors.primaryText}
              fontWeight={fontWeight[500]}
              fontFamily={fonts.semibold}
              fontSize={1.7}
            />
            <NormalTextSelect
              value={t('publishResults.note.text', { values: { publishDate: currentDate } })}
              color={colors.secondaryText}
            />
          </ContentWrapper>
        </>
      ) : (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      )}
    </>
  );
}

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
