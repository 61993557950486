import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors, fonts } from "src/styles/theme/styles";
import { useNavigate, useParams } from "src/routes/routing.web";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import HeaderOne from "src/components/atoms/Text/HeaderOne";
import HeaderTwo from "src/components/atoms/Text/HeaderTwo";
import { Icon } from "src/components/atoms/Icon/Icon";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import { admissionLinkHelpers } from "./AdmissionLinkHelpers";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import formJSON from "src/form-json/admission/PublicAdmission/PublicAdmissionLinks.json";
import { isTablet } from "src/constant/device";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useGetPublicallyAvailableAdmissionLinks } from "src/graphql/admission";
import { useI18n } from "src/i18n/hooks";

export default function AdmissionLinkPage() {
  const [dataAvailable, setDataAvailable] = useState<boolean>(false);
  const { instituteId }: any = useParams();
  const [elements, setElements] = useState<any>({});
  const [instName, setInstName] = useState<string>();
  const { fields }: any = elements ?? {};
  const { t } = useI18n();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const { getPublicallyAvailableAdmissionLinks, data, loading } =
    useGetPublicallyAvailableAdmissionLinks();

  useEffect(() => {
    // Necessary for setValue / clearing to work
    setValue("stream_section", null);
    setValue("admissionFor", null);

    getPublicallyAvailableAdmissionLinks({
      variables: { instituteId },
    });
  }, [instituteId]);

  useEffect(() => {
    if (data && data?.publicAvailableAdmissions) {
      const response = data.publicAvailableAdmissions ?? [];
      const courseOptions = admissionLinkHelpers.transformData(response);

      if (response?.[0] && response[0]?.institute?.name) {
        setInstName(response[0].institute.name);
      }

      // @ts-ignore
      formJSON[0].fields[0].option = courseOptions;
      setDataAvailable(true);
      setElements({ ...formJSON[0] });
    }
    return () => {
      formJSON[0].fields[0].option = [];
      formJSON[0].fields[1].option = [];
    };
  }, [data]);

  function handleCustomSelect(id: string, data: any) {
    if (id === "stream_section") {
      setValue("admissionFor", null);
      if (data) {
        formJSON[0].fields[1].option = data.options;
        setElements({ ...formJSON[0] });
      }
    }
  }

  function onSubmitFn(formData: any) {
    if (formData && formData?.admissionFor?.value) {
      navigate(`/admission/apply/${formData.admissionFor.value}`);
    }
  }

  return (
    <div>
      <IconWrapper container>
        {isTablet ? (
          <Icon name={"edba-logo-light"} size={40} />
        ) : (
          <Icon name={"edba-logo"} size={40} />
        )}
      </IconWrapper>
      {dataAvailable ? (
        <>
          {isTablet ? (
            <>
              <BgWrapper container justifyContent="center" alignItems="center">
                <BannerContentWrap container spacing={{ md: 2, sm: 2, xs: 0 }}>
                  <BannerContent item lg={8} md={10} sm={9}>
                    <HeaderOne
                      value="Admissions"
                      fontSize={isTablet ? "30px" : "80px"}
                      color={colors.white}
                      textAlign={isTablet ? "center" : "left"}
                    />
                    <HeaderTwo
                      value="Open for Registration"
                      fontSize={isTablet ? "16px" : "30px"}
                      color={colors.lightOrange}
                      textAlign={isTablet ? "center" : "left"}
                    />
                    {instName && (
                      <InstituteNameWrapper mobileView={true}>
                        <HeaderTwo
                          value={instName}
                          fontSize={isTablet ? "16px" : "30px"}
                          color={colors.white}
                          textAlign={isTablet ? "center" : "left"}
                        />
                      </InstituteNameWrapper>
                    )}
                  </BannerContent>
                  <BannerImage item lg={4} md={2} sm={3}>
                    <Icon name={"admission-link-banner-img"} />
                  </BannerImage>
                </BannerContentWrap>
              </BgWrapper>
              <FormWrapper>
                {fields &&
                  fields.map((field: any, i: number) => (
                    <FieldWrapper allowFullWidth={true}>
                      <Element
                        key={`admissionFieldsMobile${i}`}
                        field={field}
                        control={control}
                        errors={errors}
                        handleCustomSelect={handleCustomSelect}
                      />
                    </FieldWrapper>
                  ))}
                <CustomButton
                  onClick={handleSubmit(onSubmitFn)}
                  variant="contained"
                  size={isTablet ? "large" : "medium"}
                  sx={{
                    height: isTablet ? "48px" : "56px",
                    borderRadius: 8,
                    marginTop: "20px",
                  }}
                  startIcon={
                    isTablet ? (
                      ""
                    ) : (
                      <FontAwesomeIcon
                        icon={faCheck}
                        color={colors.white}
                        size={12}
                      />
                    )
                  }
                >
                  Apply
                </CustomButton>
              </FormWrapper>
            </>
          ) : (
            <BgWrapper container justifyContent="center" alignItems="center">
              <BannerContentWrap container spacing={2}>
                <BannerContent item md={8}>
                  <HeaderOne
                    value="Admissions"
                    fontSize={isTablet ? "30px" : "80px"}
                    color={colors.white}
                    textAlign={isTablet ? "center" : "left"}
                  />
                  <HeaderTwo
                    value="Open for Registration"
                    fontSize={isTablet ? "16px" : "30px"}
                    color={colors.lightOrange}
                    textAlign={isTablet ? "center" : "left"}
                  />
                  {instName && (
                    <InstituteNameWrapper>
                      <HeaderTwo
                        value={instName}
                        fontSize={isTablet ? "16px" : "30px"}
                        color={colors.white}
                        textAlign={isTablet ? "center" : "left"}
                      />
                    </InstituteNameWrapper>
                  )}
                </BannerContent>
                <BannerImage item md={4}>
                  <Icon name={"admission-link-banner-img"} />
                </BannerImage>
              </BannerContentWrap>
              <FormWrapper item md={10}>
                {fields &&
                  fields.map((field: any, i: number) => (
                    <FieldWrapper>
                      <Element
                        key={`admissionFieldsDesktop${i}`}
                        field={field}
                        control={control}
                        errors={errors}
                        handleCustomSelect={handleCustomSelect}
                      />
                    </FieldWrapper>
                  ))}
                <CustomButton
                  variant="contained"
                  size={isTablet ? "large" : "medium"}
                  onClick={handleSubmit(onSubmitFn)}
                  startIcon={
                    <FontAwesomeIcon
                      icon={faCheck}
                      color={colors.white}
                      size={12}
                    />
                  }
                >
                  Apply
                </CustomButton>
              </FormWrapper>
            </BgWrapper>
          )}
        </>
      ) : (
        <NoAdmissionLinksWrapper>
          {loading ? (
            <LoaderSpinner />
          ) : (
            <HeaderTwo value={t("noAdmissionLink.text")} textAlign={"center"} />
          )}
        </NoAdmissionLinksWrapper>
      )}
      <Footer>
        <NormaltextAtom
          value={`Copyright © ${new Date().getFullYear()} EDBA Software Pvt Ltd. All Rights Reserved.`}
        />
        <FooterRightSectionWrapper>
          <PrivacyPolicyLink href={"https://edba.io/privacy-policy"} variant="text">
            Privacy Policy
          </PrivacyPolicyLink>
        </FooterRightSectionWrapper>
      </Footer>
    </div>
  );
}

const BgWrapper = styled(Grid)`
  min-height: 800px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(43, 120, 202, 0.8) 0%,
    #2b78ca 100%
  );
  @media (max-width: 991px) {
    background: ${colors.primary};
    min-height: 130px;
  }
`;

const BannerContentWrap = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 66px 175px;
  position: relative;
  @media (max-width: 991px) {
    padding: 0;
    justify-content: center;
  }
`;

const BannerContent = styled(Grid)`
  display: flex;
  flex-direction: column !important;
  text-transform: uppercase;
`;

const BannerImage = styled(Grid)`
  @media (max-width: 991px) {
    display: none;
  }
`;

const CustomButton = styled(Button)`
  background-color: ${colors.lightOrange} !important;
  width: 100px;
  height: 56px;
  @media (max-width: 991px) {
    width: 100%;
    background-color: ${colors.primary} !important;
    margin-left: 0 !important;
  }
`;

const FormWrapper = styled(Grid)`
  background-color: #2874c5;
  border-radius: 8px;
  padding: 40px;
  display: flex;
  margin-bottom: 390px !important;
  align-items: center;
  @media (max-width: 991px) {
    background-color: ${colors.white};
    display: block;
    padding: 30px 25px;
    border-radius: 0;
    min-height: 65vh;
    margin-bottom: 0 !important;
  }
`;

const NoAdmissionLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.white};
`;

const IconWrapper = styled(Grid)`
  background-color: ${colors.white};
  padding: 20px;
  padding-left: 120px;
  @media (max-width: 991px) {
    background-color: ${colors.primary};
    padding: 20px;
    justify-content: center;
  }
`;

const Footer = styled.div`
  padding: 28px 120px;
  background-color: ${colors.white};
  font-family: ${fonts.regular};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    display: block;
    text-align: center;
    padding: 20px;
  }
`;

const FooterRightSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    display: block;
  }
`;

const PrivacyPolicyLink = styled(Button)`
  font-size: 16px !important;
  color: ${colors.primaryText} !important;
  font-family: ${fonts.regular};
  line-height: 24px;
  text-decoration: none;
  margin-right: 20px;
  text-transform: capitalize !important;
  @media (max-width: 991px) {
    font-size: 14px !important;
  }
`;

const FieldWrapper = styled.div<{ allowFullWidth?: boolean }>`
  background-color: ${colors.white};
  width: ${({ allowFullWidth }) => (allowFullWidth ? "100%" : "40%")};
  margin-right: 16px;
  border-radius: 4px;
  padding: 10px 15px 0;
  @media (max-width: 991px) {
    padding: 0;
    margin-bottom: -4px !important;
  }
`;

const InstituteNameWrapper = styled.div<{ mobileView?: boolean }>`
  margin-top: 16px;
  padding-bottom: ${({ mobileView }) => (mobileView ? "8px" : "")};
  max-width: ${({ mobileView }) => (mobileView ? "70%" : "100%")};
  margin: ${({ mobileView }) => (mobileView ? "auto" : "")};
  text-align: ${({ mobileView }) => (mobileView ? "center" : "left")};
`;
