import { ADD_SUCCESS, CLONE_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { DeleteIcon, EditIcon } from 'src/components/atoms/ActionIcons';
import { ERROR, SUCCESS, TestTypeLabel, limit } from 'src/constant';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { TableCells, TableDiv, TableRows } from 'src/components/molecules/Table/TableAtom';
import styled, { useTheme } from 'styled-components';
import {
  useCloneTests,
  useCreateTest,
  useDeleteTest,
  useGetTestDetails,
  useUpdateTestDetails,
} from 'src/graphql/assessment/assessment-test';
import { useHistory, useParams } from 'src/routes/routing.web';

import AddEditTestForm from 'src/components/molecules/Assessment/Test/AddEditTestForm';
import BatchClassSelectionForm from 'src/components/molecules/Assessment/BatchClassSelectionForm';
import Check from 'src/components/atoms/CheckBox/index.web';
import CloneTestForm from 'src/components/molecules/Assessment/Test/CloneTestForm';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import ERROR_MSG from 'src/constant/error';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { TableBody } from '@mui/material';
import { colors } from 'src/styles/theme/styles';
import { createSortFilter } from 'src/components/services';
import { DropdownOptionsType } from 'src/types';
import { height } from 'src/constant/device';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useForm } from 'react-hook-form';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import useTable from 'src/components/molecules/Table/UseTable';
import { validation } from 'src/constant/validation';

const headCells1 = [
  {
    id: 'test',
    label: 'test.label',
    align: 'left',
    disableSorting: true,
    sort: true,
    ascend: true,
    sortParam: 'name',
    isSorted: false,
    style: { width: '22.5%' },
  },
  {
    id: 'type',
    label: 'type.label',
    align: 'left',
    disableSorting: true,
    style: { width: '11.5%', paddingRight: '5%' },
  },
  {
    id: 'subject',
    label: 'subject.label',
    align: 'left',
    disableSorting: true,
    style: { width: '22%' },
  },
  {
    id: 'minMaxMarks',
    label: 'passing-total-marks.label',
    align: 'right',
    disableSorting: true,
    style: { width: '22.5%', paddingRight: '5%' },
  },
  {
    id: 'creditPoints',
    label: 'credits.label',
    align: 'right',
    disableSorting: true,
    style: { width: '16.5%', paddingRight: '5%' },
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
    style: { width: '5%' },
  },
];

export default function Test() {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const { setAlertDetails } = useAlertSystem();
  const { setHeading } = useHeaderTitle();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm();
  const {
    handleSubmit: handleCloneFormSubmit,
    control: cloneFormControl,
    formState: { errors: cloneFormError },
    reset: resetCloneForm,
    setValue: setCloneFormValue,
    getValues: getCloneFormValues,
    setError: setCloneFormError,
  } = useForm();
  const history = useHistory();

  const [headCells, setHeadCells] = useState(headCells1);
  const [showCheck, setShowCheck] = useState(false);
  const [array, setArray] = useState([]);
  const [modalState, handleModal] = useState<boolean>(false);
  const [cloneModalState, handleCloneModal] = useState<boolean>(false);
  const [selectedBatch, setSelectedBatch] = useState<string>('');
  const [selectedClassId, setSelectedClassId] = useState<string>('');
  const [allFormValuesAvailable, setAllFormValuesAvailable] = useState<boolean>(false);
  const [subjectsToBeCloned, setSubjectsToBeCloned] = useState<any>([]);
  const [row, setRow] = useState<any>();
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [canClick, setCanClick] = useState(true);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [deleteTestModalState, handleDeleteTestModalState] = useState<boolean>(false);
  const [isEvaluation, setIsEvaluation] = useState<boolean>(false);
  const {
    getTestDetails,
    testDetailsData,
    testDetailsError,
    testDetailsLoading,
    refetchTestDetails,
  } = useGetTestDetails();
  const { createTest, createTestData, createTestError } = useCreateTest();
  const { cloneTests, clonedTestsData, clonedTestsError } = useCloneTests();
  const { updateTestDetails, updatedTestDetailsData, updatedTestErrors } = useUpdateTestDetails();
  const { deleteTest } = useDeleteTest();
  const [marksType, setMarksType] = useState<string>('');

  let maxHeight = height - 395;
  const paginationHeightOffset = 20;

  const { page = 1, dataLimit = limit, urlBatchId = null }: any = useParams();
  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  useEffect(() => {
    if (urlBatchId) {
      handleFetch(urlBatchId);
    }
  }, [urlBatchId, headCells]);

  // Fetch Rows on initial batch select & refetch after test addition
  useEffect(() => {
    if (testDetailsData) {
      setRow(testDetailsData?.classTestDetails?.data);
      setTotalCount(testDetailsData?.classTestDetails?.totalCount);

      // Display Checkbox only if data is available
      setShowCheck(testDetailsData?.classTestDetails?.data?.length > 0);

      // Reset / Clear out checked values if new form
      // values are selected or values are refetched
      setArray([]);
    }
  }, [testDetailsData]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  const styles = StyleSheet.create({
    cloneBtnStyle: { width: rem(8), height: rem(3.5), marginRight: rem(1.6) },
    addBtnStyle: { width: rem(11), height: rem(3.5) },
  });

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
    null,
    null,
    false,
    false,
    null,
    paginationHeightOffset,
  );

  // Set header title text
  useEffect(() => {
    setHeading([{ text: t('tests.label'), url: '' }]);
  }, []);

  useEffect(() => {
    if (selectedBatch) {
      handleFetch(selectedBatch);
    }
  }, [currentPage, headCells, rowLimit]);

  function makeFetchQueryVariable(batchId: string) {
    return {
      filters: {
        batch: {
          eq: batchId,
        },
      },
      limit: rowLimit,
      skip: (currentPage - 1) * rowLimit,
      sort: createSortFilter(headCells),
    };
  }

  function handleFetch(currentlySelectedBatch: string) {
    getTestDetails({ variables: makeFetchQueryVariable(currentlySelectedBatch) });
  }

  const closeModal = () => {
    handleModal(false);
    resetForm();
  };

  function resetForm() {
    setValue('testName', null);
    setValue('selectSubject', null);
    setValue('selectType', null);
    setValue('minMarks', null);
    setValue('maxMarks', null);
    setIsEvaluation(false);
    setValue('selectMarksType', null);
    clearErrors();
  }

  const closeCloneModal = () => {
    handleCloneModal(false);
    setCanClick(true);
    setCloneFormValue('toSubjects', []);
  };

  const closeDeleteModal = () => {
    handleDeleteTestModalState(false);
    setCurrentItem({});
  };

  const handleCheck = (item: any) => {
    const index = array.findIndex(x => x === item?.id);
    const updated = [...array];
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push(item?.id);
    }
    setArray(updated);
  };

  const handleCheckAll = () => {
    if (array.length === 0) {
      let allUsers = [] as any;
      recordsAfterPagingAndSorting()?.forEach((item: any) => {
        allUsers.push(item?.id);
      });
      setArray(allUsers);
    } else if (array.length > 0 && array.length !== row?.length) {
      let update = [...array] as any;
      const res = recordsAfterPagingAndSorting()?.filter(x => !array.includes(x.id));
      res.forEach((e: any) => {
        update.push(e?.id);
      });
      setArray(update);
    } else {
      setArray([]);
    }
  };

  function addToUrl(currentBatchId: string) {
    const updatedPage = 1; // Initialize to first page
    setCurrentPage(updatedPage);
    history.push(`/assessment/tests/${currentBatchId}/limit/${dataLimit}/page/${updatedPage}`);
  }

  const retrieveValues = (formData: any) => {
    setSelectedBatch(formData.batch.value);
    setSelectedClassId(formData.batch.class.id);
    setAllFormValuesAvailable(true);
    handleFetch(formData.batch.value);
    if (formData?.batch?.type) {
      setMarksType(formData?.batch?.type);
    } else {
      setMarksType(formData.batch.settings.gradingType);
    }

    // add batch id to history
    addToUrl(formData.batch.value);
  };

  interface formDataType {
    testName: string;
    minMarks: string;
    maxMarks: string;
    selectSubject: DropdownOptionsType;
    selectType: DropdownOptionsType;
    selectMarksType: DropdownOptionsType;
  }

  async function addEditTestFn(formData: formDataType) {
    try {
      setCanClick(false);
      let testObject, testData;
      if (!isEditModal) {
        // add test
        testObject = {
          batch: selectedBatch,
          name: formData?.testName,
          ...(formData?.minMarks && { passingMarks: parseFloat(formData.minMarks) }),
          ...(formData?.maxMarks && { totalMarks: parseFloat(formData.maxMarks) }),
          subject: formData?.selectSubject?.value,
          type: formData?.selectType?.value,
          ...(marksType === 'MARKS' && { considerInEvaluation: isEvaluation }),
          ...(marksType === 'MARKS' &&
            formData?.selectMarksType?.value && { markingType: formData?.selectMarksType?.value }),
        };
        testData = await createTest({
          variables: { payload: testObject },
        });
      } else {
        // edit test
        testObject = {
          batch: currentItem?.batch?.id,
          id: currentItem?.id,
          name: formData?.testName,
          ...(formData?.minMarks && { passingMarks: parseFloat(formData.minMarks) }),
          ...(formData?.maxMarks && { totalMarks: parseFloat(formData.maxMarks) }),
          subject: formData?.selectSubject?.value,
          creditPoint: formData?.credits?.value,
          type: formData?.selectType?.value,
          ...(marksType === 'MARKS' && { considerInEvaluation: isEvaluation }),
          ...(marksType === 'MARKS' &&
            formData?.selectMarksType?.value && { markingType: formData?.selectMarksType?.value }),
        };
        testData = await updateTestDetails({
          variables: { payload: testObject },
        });
      }

      if (testData?.data) {
        // successfull api hit
        setAlertDetails({
          message: isEditModal ? UPDATE_SUCCESS.TEST : ADD_SUCCESS.TEST,
          level: SUCCESS,
        });
        closeModal();
        if (refetchTestDetails) refetchTestDetails(makeFetchQueryVariable(selectedBatch));
      }
      setCanClick(true);
    } catch (e: any) {
      const errorMsg = e.message.includes('ClassTestDetail already exists for')
        ? ERROR_MSG.DUPLICATE_ASSESSMENT_TEST
        : e?.message;
      setAlertDetails({ message: errorMsg, level: ERROR });
      setCanClick(true);
    }
  }

  const handlAddEditTest = async (formData: formDataType) => {
    if (parseFloat(formData.minMarks) > parseFloat(formData.maxMarks)) {
      setError('minMarks', {
        message: validation.ADD_TEST_MARKS_INVALID,
      });
    } else {
      addEditTestFn(formData);
    }
  };

  async function cloneTestsToSubjects() {
    // no input since subjectsToBeCloned state is used - it has values in the required format
    setCanClick(false);
    try {
      const _clonedTestsData = await cloneTests({
        variables: {
          batch: selectedBatch,
          ctdIds: array,
          subjectIds: subjectsToBeCloned,
        },
      });
      if (_clonedTestsData?.data) {
        setAlertDetails({ message: CLONE_SUCCESS.TEST_CLONE_SUCCESS, level: SUCCESS });
        closeCloneModal();
        if (refetchTestDetails) refetchTestDetails(makeFetchQueryVariable(selectedBatch));
      }
    } catch (e: any) {
      setCanClick(true);
      const errorMsg = e.message.includes('duplicate key error collection')
        ? ERROR_MSG.DUPLICATE_ASSESSMENT_TEST
        : e?.message;
      setAlertDetails({ message: errorMsg, level: ERROR });
    }
  }

  function handleCloneTests() {
    if (subjectsToBeCloned.length == 0) {
      setCloneFormError('toSubjects', {
        message: validation.SUBJECT_REQUIRED,
      });
    } else {
      cloneTestsToSubjects();
    }
  }

  async function handleDeleteTest() {
    setCanClick(false);
    try {
      const deleteResponse = await deleteTest({
        variables: { id: currentItem?.id },
      });
      if (deleteResponse?.data?.deleteTest) {
        setAlertDetails({ message: DELETE_SUCCESS.TEST, level: SUCCESS });
        if (refetchTestDetails) refetchTestDetails();
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
    setCanClick(true);
    closeDeleteModal();
  }

  const handleEditTest = (item: any) => {
    setIsEvaluation(item?.considerInEvaluation);
    setCurrentItem(item);
    setIsEditModal(true);
    handleModal(true);
  };
  const handleDeleteTestModal = (item: any) => {
    setCurrentItem(item);
    handleDeleteTestModalState(true);
  };

  return (
    <LoadContentWrapper>
      <TopRowsWrapper>
        <SelectionWrapper>
          <BatchClassSelectionForm
            retrieveValues={retrieveValues}
            urlBatchId={urlBatchId}
            pagePath={`/assessment/tests/limit/${dataLimit}/page/1`}
            setParentBatchId={setSelectedBatch}
            setParentClassId={setSelectedClassId}
            setParentAllFormValuesAvailable={setAllFormValuesAvailable}
            isLoading={testDetailsLoading}
            setMarksType={setMarksType}
          />
        </SelectionWrapper>

        <CloneAddTestBtnWrapper>
          <OutlineButton
            label={t('clone.label')}
            onPress={() => {
              handleCloneModal(true);
            }}
            fontSize={1.8}
            style={styles.cloneBtnStyle}
            customDisabled={!selectedBatch || array.length == 0}
          />
          <SecondaryBtn
            label={t('add-test.label')}
            onPress={() => {
              setIsEditModal(false);
              handleModal(true);
            }}
            style={styles.addBtnStyle}
            customDisabled={!selectedBatch}
          />
        </CloneAddTestBtnWrapper>
      </TopRowsWrapper>

      <View style={{ zIndex: -1 }}>
        <TableDiv>
          <TblContainer>
            <TblHead
              setHeadCells={setHeadCells}
              checkBox={showCheck}
              checked={array.length === row?.length && array.length !== 0}
              handleCheck={handleCheckAll}
            />

            {totalCount > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                  <TableRows key={`t${i}`}>
                    {showCheck && (
                      <TableCells style={{ width: 16 }}>
                        <Check
                          same={false}
                          onChange={() => handleCheck(item)}
                          checked={array.some(e => e === item?.id)}
                        />
                      </TableCells>
                    )}
                    <TableCells value={item?.name} />
                    <TableCells
                      value={item?.type ? TestTypeLabel[item?.type] : 'N/A'}
                      style={{ paddingRight: '5%' }}
                    />
                    <TableCells value={item?.subject?.name} />
                    {marksType === 'MARKS' ? (
                      item.markingType === 'MARK' ? (
                        <TableCells
                          value={`${item?.passingMarks}/${item?.totalMarks}` || 'N/A'}
                          align={'right'}
                          style={{ paddingRight: '5%' }}
                        />
                      ) : (
                        <TableCells value={'N/A'} align={'right'} style={{ paddingRight: '5%' }} />
                      )
                    ) : (
                      <TableCells
                        value={`${item?.passingMarks}/${item?.totalMarks}` || 'N/A'}
                        align={'right'}
                        style={{ paddingRight: '5%' }}
                      />
                    )}
                    <TableCells
                      value={item?.creditPoint || 'N/A'}
                      align={'right'}
                      style={{ paddingRight: '5%' }}
                    />

                    {/* Actions */}
                    <TableCells align="right" style={{ width: '5%' }}>
                      <ActionIcons>
                        <IconView>
                          <EditIcon
                            onPress={() => handleEditTest(item)}
                            tooltipTitle={t('edit-test.label')}
                          />
                        </IconView>
                        {/* Temporary Disable of Delete Btn */}
                        <DeleteIcon
                          onPress={() => handleDeleteTestModal(item)}
                          tooltipTitle={t('delete-test.label')}
                        />
                      </ActionIcons>
                    </TableCells>
                  </TableRows>
                ))}
              </TableBody>
            ) : (
              <TableRows style={{ height: maxHeight }}>
                <TableCells colspan={4} align="center" style={{ borderBottom: 'none' }}>
                  {/* "" in ternary else may be utilised to show msg like - Select Batch and Class to continue! */}
                  {totalCount == 0 && allFormValuesAvailable ? t('no-records-found.text') : ''}
                </TableCells>
              </TableRows>
            )}
          </TblContainer>

          <Pagination
            pathName={`assessment/tests/${selectedBatch}`}
            total={totalCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            rowLimit={rowLimit}
            setRowLimit={setRowLimit}
            hidePagination={!!(totalCount && totalCount <= rowLimit)}
          />
        </TableDiv>
      </View>

      {/* Modals */}
      <NormalModal
        isSubmitting={!canClick}
        onAlert={null}
        setModalVisible={closeCloneModal}
        Headerpopup={t('clone-tests.title')}
        modalVisible={cloneModalState}
        handleSave={handleCloneFormSubmit(handleCloneTests)}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'save.label'}
        width={700}
        maxWidth={'lg'}>
        <CloneTestForm
          control={cloneFormControl}
          errors={cloneFormError}
          setSubjectsToBeCloned={setSubjectsToBeCloned}
          selectedClassId={selectedClassId}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        onAlert={null}
        setModalVisible={closeModal}
        Headerpopup={isEditModal ? t('edit-test.label') : t('add-test.label')}
        modalVisible={modalState}
        handleSave={handleSubmit(handlAddEditTest)}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'save.label'}
        width={764}
        maxWidth={'lg'}>
        <AddEditTestForm
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          selectedClassId={selectedClassId}
          clearErrors={clearErrors}
          isEditModal={isEditModal}
          currentItem={currentItem}
          evaluation={isEvaluation}
          setEvaluation={setIsEvaluation}
          marksType={marksType}
        />
      </NormalModal>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={closeDeleteModal}
        modalVisible={deleteTestModalState}
        handleSave={handleDeleteTest}
        Headerpopup={t('deleteTest.text')}
        children={t('deleteTest.warning')}
      />
    </LoadContentWrapper>
  );
}

const TopRowsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10;
`;

const SelectionWrapper = styled.div`
  margin-right: 10px;
`;

const CloneAddTestBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.theme.rem(2)};
`;

const ActionIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const IconView = styled.div`
  margin-right: 13px;
`;
