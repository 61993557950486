import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import React, { useState } from "react";
import { colors, fonts } from "src/styles/theme/styles";
import { DropdownOptionsType } from "src/types";
import styled from "styled-components";
import { InfoSquareIcon } from "src/components/atoms/ActionIcons";
import Badge from "src/components/atoms/Badge";
import SmallTextAtom from "src/components/atoms/Text/SmallTextAtom";

export interface CreateInfoItemInterface {
  width?: number | string;
  color?: string;
  showDescriptionIcon?: boolean;
  description?: string;
  badges?: string[];
  customOnPress?: () => void;
  fontFamily?: string;
  value?: string | number;
  label: string;
  handleInfoIcon?: () => void;
}

export default function CreateInfoItem(props: CreateInfoItemInterface) {
  const {
    label,
    value,
    width = 200,
    color = colors.primaryText,
    description,
    badges,
    customOnPress,
    fontFamily = fonts.regular,
    handleInfoIcon,
    showDescriptionIcon = false,
  } = props;
  const [showData, setShowData] = useState<boolean>(false);
  return (
    <ItemWrapper width={width}>
      <SmallTextAtom value={label} />
      <TextWrapper>
        <MediumTextSelect value={value || "-"} color={color} fontFamily={fontFamily} />
        {(showDescriptionIcon || description) && (
          <IconWrapper>
            <InfoSquareIcon onPress={() => handleInfoIcon ? handleInfoIcon() : setShowData(!showData)} />
          </IconWrapper>
        )}
        {showData && (
          <DescriptionWrapper>
            <NormaltextAtom value={description} />
          </DescriptionWrapper>
        )}

        {
          badges && badges.length > 0 && (
            <BadgeWrapper>
              {badges.map((b: string) => {
                return <Badge value={b} />
              })}
            </BadgeWrapper>
          )
        }

        {
          customOnPress && (
            <IconWrapper>
              <InfoSquareIcon onPress={customOnPress} />
            </IconWrapper>
          )
        }

      </TextWrapper>
    </ItemWrapper >
  );
}

const ItemWrapper = styled.div<{ width: number | string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => typeof width === "number" ? `${width}px` : width};
  margin-bottom: 6px;
`;

const TextWrapper = styled.div`
  display: flex;
  position: relative;
  padding-top: 2px;
`;

const IconWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

const DescriptionWrapper = styled.div`
  position: absolute;
  left: 100;
  top: -16;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 16px;
  max-height: 40px;
  overflow-x: auto;
`;

const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  gap: 8px;
`;
