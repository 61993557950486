import React, { useState } from "react";

import { DropdownOptions } from "src/types";
import ERROR_MSG from "src/constant/error";
import FeeCompositionSelection from "../Fee/FeeCompositionSelection/FeeCompositionSelection.web";
import { useApplyFeeComposition } from "src/graphql/classes";
import { useI18n } from "src/i18n/hooks";

type Props = {
  onClose: () => void;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
  data: {
    studentId: string;
    subjectGroupID: string;
    initialValue: string;
  };
};

const AssignFeeCompositionForm = (props: Props) => {
  const { onClose, onSuccess, onError, data } = props;
  const { subjectGroupID, studentId, initialValue } = data;

  const { t } = useI18n();
  const { applyFeeComposition } = useApplyFeeComposition();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (selectedFeeComp: DropdownOptions | null) => {
    setLoading(true);
    try {
      if (selectedFeeComp) {
        const response = await applyFeeComposition({
          variables: {
            studentId: studentId,
            compositionId: selectedFeeComp.value,
          },
        });
        if (response?.data?.applyFeeCompositionToStudent) {
          if (
            response.data.applyFeeCompositionToStudent?.status === "success"
          ) {
            onSuccess(t("feesAssigned.text"));
          } else {
            throw new Error(
              response.data.applyFeeCompositionToStudent?.message
            );
          }
        }
      } else {
        // No composition selected
        throw new Error(t("error.feeComposition.required"));
      }
      setLoading(false);
    } catch (error: any) {
      onError(error?.message);
      setLoading(false);
    }
  };

  return (
    <FeeCompositionSelection
      onClose={onClose}
      onSubmit={onSubmit}
      initialValue={initialValue}
      subjectGroupID={subjectGroupID}
      isSubmitting={loading}
    />
  );
};

export default AssignFeeCompositionForm;
