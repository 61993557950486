import React, { useState, useEffect, useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import { colors } from '../../../styles/theme/styles';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm, Controller } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import Input from '../../atoms/Input/input.web';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import {
  faPencil,
  faCheckCircle,
  faLanguage,
  faSync,
  faGlobe,
} from '@fortawesome/pro-regular-svg-icons';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { validation } from '../../../constant/validation';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import {
  useQueryLanguage,
  getLanguagesQuery,
  useMutateLanguage,
  addLanguageMutation,
  updateLanguageMutation,
  useDeleteLanguage,
  useGetLanguage,
} from '../../../graphql/localization/language';
import { useParams, useHistory } from '../../../routes/routing.web';
import { AUTOCOMPLETE_MODULE, ERROR, limit, SUCCESS, USER_TYPE } from '../../../constant';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { readCacheQuery, writeCacheQuery } from 'src/graphql/util';
import { useI18n } from 'src/i18n/hooks';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import useTable from 'src/components/molecules/Table/UseTable';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, debounce } from 'src/components/services';
import { getLanguageFilter } from 'src/components/services/filters';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faHistory } from '@fortawesome/pro-solid-svg-icons';
import AddButton from 'src/components/atoms/AddButton';
import { useMutateInstitute, applyLanguage } from 'src/graphql/institutes';
import { regexObject } from 'src/constant/regex';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { updateLanguageKeyValuesUpdatedAt } from 'src/graphql/localization/key-values';
import { Tooltip } from '@mui/material';
import { NoData } from 'src/components/atoms/NoData';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { height } from 'src/constant/device';

const maxHeight = 440;

interface HeadcellType {
  id: string;
  label: string;
  align: string;
  disableSorting: boolean;
}

const headCells: HeadcellType[] = [
  {
    id: 'language_name',
    label: 'languageName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'language_keyValuesUpdatedAt',
    label: 'keyValuesUpdatedAt.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: 'actions',
    align: 'right',
    disableSorting: true,
  },
];

interface parameterType {
  page?: string;
  dataLimit?: string;
}

export default function Language() {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const [canClick, setCanClick] = useState(true);
  let searchValue = '' as string;

  const currentUserObject = userInformation();
  const { page = 1, dataLimit = limit } = useParams<parameterType>();

  const rowDefaultLimit: number = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);

  const { filters, setFilter } = useFilter();
  let history = useHistory();
  const { setHeading } = useHeaderTitle();
  const [applyOverlay, setApplyOverlay] = useState(false);
  const [language, setLanguage] = useState('');
  const [modalState, handleModal] = useState(false);
  const [kvmodalState, handleKVModal] = useState(false);
  const [kvtsUpdated, setKVTSUpdated] = useState(new Date());
  const { mutate: addLanguage } = useMutateLanguage(addLanguageMutation);
  const [editLanguageData, setLanguageData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  let { setAlertDetails } = useAlertSystem();
  const { mutate: updateLanguage } = useMutateLanguage(updateLanguageMutation);
  const [updateKVTS] = useMutation(updateLanguageKeyValuesUpdatedAt);

  const {
    query: getLanguage,
    data: languageData,
    loading,
    refetch: refetchLanguage,
  } = useQueryLanguage(getLanguagesQuery, 'network-only');

  const { mutate: updateInstitute } = useMutateInstitute(applyLanguage);

  const row = languageData?.languages?.data;
  const { getLanguage: getList, languagesData: listData } = useGetLanguage();

  if (filters && filters?.language?.on) {
    searchValue = filters?.language?.search;
  }

  const { deleteLanguage } = useDeleteLanguage(
    currentPage,
    searchValue,
    rowLimit,
    editLanguageData?.id,
  );
  const [totalCount, setTotalCount] = useState<number | null>(
    languageData?.languages?.totalCount ?? null,
  );

  useEffect(() => {
    const count = languageData?.languages?.totalCount;
    if (count) {
      setTotalCount(count);
    }
  }, [languageData?.languages?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, kvtsUpdated, rowLimit]);

  function handleFetch() {
    const filtersValue = getLanguageFilter(searchValue);
    const languageFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filtersValue);
    getLanguage({ variables: languageFilter });
  }

  function setTitle() {
    setHeading([
      {
        text: t('translations.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    if (isEditModal && modalState) {
      setFormField(editLanguageData?.name);
    }
  }, [modalState]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
  );

  const editLanguageModal = useCallback(
    selectedLanguage => {
      setLanguageData(selectedLanguage);
      hideAlert();
      setIsEditModal(true);
      handleModal(true);
    },
    [modalState],
  );

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  const addLanguageModal = () => {
    setIsEditModal(false);
    reset({});
    hideAlert();
    handleModal(true);
  };

  const updateKVTSModal = (selectedLanguage: any) => {
    setLanguageData(selectedLanguage);
    handleModal(false);
    hideAlert();
    handleKVModal(true);
  };

  const deleteLanguageModal = (selectedLanguage: any) => {
    setLanguageData(selectedLanguage);
    setDeleteOverlay(true);
  };

  const applyLanguageModal = (item: any) => {
    setLanguage(item.id);
    if (currentUserObject?.language != item.name) {
      setApplyOverlay(true);
    }
  };

  const closeModal = () => {
    handleModal(false);
    if (!isEditModal) resetForm();
    resetForm();
  };

  const closeKVModal = () => {
    handleKVModal(false);
  };

  function resetForm() {
    setValue('language_name', '');
    clearErrors();
  }

  function setFormField(name: string) {
    setValue('language_name', name, { shouldValidate: true });
  }

  interface language {
    language_name: string;
  }

  const handleApplyLanguage = async () => {
    setCanClick(false);
    try {
      const updateInstituteData = await updateInstitute({
        variables: { languageId: language },
      });

      if (updateInstituteData.data.applyLanguage) {
        setApplyOverlay(false);
        setAlertDetails({ message: UPDATE_SUCCESS.LANGUAGE_APPLIED, level: SUCCESS });
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleAddLanguage = async (formdata: language) => {
    setCanClick(false);
    let languageObject = {
      name: formdata.language_name,
    };

    try {
      let ErrorOccure = false;
      let addLanguageData = await addLanguage({
        variables: { payload: languageObject },

        update: (cache, { data: { createLanguage } }) => {
          let filters = getLanguageFilter(searchValue);
          //read
          let queryVariable = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);
          let readQueryData = readCacheQuery(cache, getLanguagesQuery, queryVariable);
          let existingLanguages = readQueryData.languages;
          if (!existingLanguages.data) {
            ErrorOccure = true;
          }
          //write
          let mergeArray = [createLanguage, ...existingLanguages.data];
          let finaldata = {
            data: mergeArray,
            hasNextPage: readQueryData?.languages?.hasNextPage,
            totalCount: readQueryData?.languages?.totalCount + 1,
            __typename: readQueryData?.languages?.__typename,
          };

          let writeQueryData = writeCacheQuery(cache, getLanguagesQuery, queryVariable, {
            languages: finaldata,
          });

          if (!writeQueryData.__ref) {
            ErrorOccure = true;
          }
        },
      });

      if (addLanguageData.data && !ErrorOccure) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.LANGUAGE, level: SUCCESS });
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateLanguage = async (editdata: any) => {
    setCanClick(false);
    let languageObject = {
      id: editLanguageData?.id,
      name: editdata?.language_name,
    };

    try {
      const updateLanguageData = await updateLanguage({
        variables: { payload: languageObject },
      });

      if (updateLanguageData.data.updateLanguage) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.LANGUAGE, level: SUCCESS });
        if (refetchLanguage) refetchLanguage();
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateKeyValuesUpdatedAt = async () => {
    setCanClick(false);
    const languageObject = {
      id: editLanguageData?.id,
    };

    try {
      const res = await updateKVTS({
        variables: languageObject,
      });

      if (res?.data?.updateLanguageKeyValuesUpdatedAt?.status === 'success') {
        closeKVModal();
        setAlertDetails({ message: UPDATE_SUCCESS.LANGUAGE, level: SUCCESS });
        setKVTSUpdated(new Date());
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleDeleteLanguage = async () => {
    setCanClick(false);
    try {
      let toDelete = await deleteLanguage({
        variables: { id: editLanguageData?.id },
      });
      if (toDelete?.data?.deleteLanguage?.status === 'success') {
        setDeleteOverlay(false);
        setAlertDetails({ message: DELETE_SUCCESS.LANGUAGE, level: SUCCESS });
      } else {
        throw new Error(
          toDelete?.data?.deleteLanguage?.message || t('error.deleteLanguage.message'),
        );
      }
    } catch (error) {
      setAlertDetails({ message: error.message, level: ERROR });
      setDeleteOverlay(false);
    }
    setCanClick(true);
  };

  const handlePress = async (item: any) => {
    history.push(`/translations/key-values/${item?.id}/limit/50/page/1`);
    searchValue = '';
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.LANGUAGE]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchLanguage(language: string) {
    let isClear = language?.length === 0;
    persistSearch(!isClear, language);
  }

  const handleSearch = debounce((language: string) => searchLanguage(language), 200);

  return (
    <LoadContentWrapper>
      {!loading ? (
        recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting().length > 0 ? (
          <>
            <LanguageHeader>
              <HeaderSearchPart>
                <Search
                  id="languageSearch"
                  handleChange={handleSearch}
                  value={searchValue || ''}
                  label={t('searchTranslations.text')}
                  width={280}
                />
              </HeaderSearchPart>

              {currentUserObject?.userType == USER_TYPE.EDBA_ADMIN ? (
                <HeaderButtonPart>
                  <SecondaryBtn label={t('addLanguage.text')} onPress={addLanguageModal} />
                </HeaderButtonPart>
              ) : null}
            </LanguageHeader>

            <TblContainer height={'100%'}>
              <TblHead />
              <>
                {!loading ? (
                  <>
                    {recordsAfterPagingAndSorting()?.length > 0 ? (
                      <TableBody>
                        {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                          <TableRows key={i}>
                            <TableCells
                              value={item?.name}
                              color={colors.primary}
                              badge={
                                currentUserObject?.language == item.name &&
                                currentUserObject?.userType !== USER_TYPE.EDBA_ADMIN
                                  ? 'Current'
                                  : null
                              }
                              clickable={true}
                              onPress={() => handlePress(item)}
                            />
                            <TableCells
                              value={item?.keyValuesUpdatedAt || '--'}
                              color={colors.primary}
                            />
                            <TableActions align="right">
                              <TableAction>
                                {currentUserObject?.userType != USER_TYPE.EDBA_ADMIN ? (
                                  <IconDiv>
                                    <TouchableOpacity onPress={() => applyLanguageModal(item)}>
                                      <FontAwesomeIcon
                                        icon={
                                          currentUserObject?.language == item.name
                                            ? faHistory
                                            : faCheckCircle
                                        }
                                        color={theme?.table?.editIconColor}
                                      />
                                    </TouchableOpacity>
                                  </IconDiv>
                                ) : null}
                                {currentUserObject?.userType == USER_TYPE.EDBA_ADMIN ? (
                                  <>
                                    <IconDiv>
                                      <Tooltip title="Edit Language">
                                        <TouchableOpacity onPress={() => editLanguageModal(item)}>
                                          <FontAwesomeIcon
                                            icon={faPencil}
                                            color={theme?.table?.editIconColor}
                                          />
                                        </TouchableOpacity>
                                      </Tooltip>
                                    </IconDiv>

                                    <Tooltip title="Update">
                                      <TouchableOpacity
                                        onPress={() => {
                                          updateKVTSModal(item);
                                        }}>
                                        <FontAwesomeIcon
                                          icon={faSync}
                                          color={theme?.table?.editIconColor}
                                        />
                                      </TouchableOpacity>
                                    </Tooltip>
                                  </>
                                ) : null}
                              </TableAction>
                            </TableActions>
                          </TableRows>
                        ))}
                      </TableBody>
                    ) : (
                      <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
                    )}
                  </>
                ) : (
                  <SpinnerWrapper>
                    <LoaderSpinner />
                  </SpinnerWrapper>
                )}
              </>
            </TblContainer>
            <Pagination
              pathName={'control-panel/translations'}
              total={totalCount}
              page={currentPage}
              setCurrentPage={setCurrentPage}
              rowLimit={rowLimit}
              setRowLimit={setRowLimit}
              hidePagination={!!(totalCount && totalCount <= rowLimit)}
            />

            <DeleteModal
              isSubmitting={!canClick}
              setModalVisible={() => setDeleteOverlay(false)}
              modalVisible={deleteOverlay}
              handleSave={handleDeleteLanguage}
              Headerpopup="deleteLanguage.text">
              {t('deleteLanguage.warning')}
            </DeleteModal>

            <DeleteModal
              isSubmitting={!canClick}
              setModalVisible={() => setApplyOverlay(false)}
              modalVisible={applyOverlay}
              handleSave={handleApplyLanguage}
              Headerpopup={'applyTranslation.text'}
              deleteButtonText={'apply.label'}>
              {t('applyTranslation.warning')}
            </DeleteModal>

            <NormalModal
              isSubmitting={!canClick}
              onAlert={null}
              width={580}
              setModalVisible={closeModal}
              handleSave={handleSubmit(isEditModal ? handleUpdateLanguage : handleAddLanguage)}
              Headerpopup={isEditModal ? t('editLanguage.text') : t('addLanguage.text')}
              modalVisible={modalState}
              cancelButtonLabel="cancel.label"
              addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}>
              <AddLanguage>
                <Controller
                  control={control}
                  name={'language_name'}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      id={'language_name'}
                      onBlur={onBlur}
                      setValue={onChange}
                      value={value}
                      header="languageName.required.label"
                      notefooter={!errors.language_name ? 'form.languageName.example' : ''}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: validation.LANGUAGE_NAME_REQUIRED,
                    },
                    pattern: {
                      value: regexObject.STRING_ONLY,
                      message: validation.LANGUAGE_NAME_INVALID,
                    },
                  }}
                />
                <AddLanguageError>
                  {errors.language_name ? (
                    <SmallTextAtom
                      value={t(errors.language_name.message)}
                      color={colors.errorColor}
                    />
                  ) : null}
                </AddLanguageError>
              </AddLanguage>
            </NormalModal>

            <NormalModal
              isSubmitting={!canClick}
              onAlert={null}
              width={580}
              height={50}
              setModalVisible={closeKVModal}
              handleSave={handleSubmit(handleUpdateKeyValuesUpdatedAt)}
              Headerpopup={t('update.label')}
              modalVisible={kvmodalState}
              cancelButtonLabel="cancel.label"
              addEditButtonLabel={'update.label'}>
              <NormaltextAtom value={'Update Key/Values last updated at timestamp.'} />
            </NormalModal>
          </>
        ) : (
          <>
            {currentUserObject?.userType == USER_TYPE.EDBA_ADMIN ? (
              <>
                <TranslationContainer>
                  <FontAwesomeIcon
                    icon={faLanguage}
                    color={colors.languageIcon}
                    height={80}
                    width={80}
                  />
                  <TranslationHeader>
                    <HeaderThree
                      value="Start building your Translations!"
                      lineHeight={32}
                      fontWeight={600}
                    />
                  </TranslationHeader>
                  <TranslationButton>
                    <AddButton label={t('addLanguage.text')} onPress={addLanguageModal} />
                  </TranslationButton>
                </TranslationContainer>

                <NormalModal
                  onAlert={null}
                  setModalVisible={closeModal}
                  handleSave={handleSubmit(isEditModal ? handleUpdateLanguage : handleAddLanguage)}
                  Headerpopup={isEditModal ? t('editLanguage.text') : t('addLanguage.text')}
                  modalVisible={modalState}
                  cancelButtonLabel="cancel.label"
                  addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}>
                  <AddLanguage>
                    <Controller
                      control={control}
                      name={'language_name'}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          id={'language_name'}
                          onBlur={onBlur}
                          setValue={onChange}
                          value={value}
                          header="languageName.required.label"
                          notefooter={!errors.language_name ? 'form.languageName.example' : ''}
                        />
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: validation.LANGUAGE_NAME_REQUIRED,
                        },
                      }}
                    />
                    <AddLanguageError>
                      {errors.language_name ? (
                        <SmallTextAtom
                          value={t(errors.language_name.message)}
                          color={colors.errorColor}
                        />
                      ) : null}
                    </AddLanguageError>
                  </AddLanguage>
                </NormalModal>
              </>
            ) : (
              <TranslationContainer>
                <NoData icon={faGlobe} label={'Translations unavailable'} />
              </TranslationContainer>
            )}
          </>
        )
      ) : (
        <Spinner>
          <LoaderSpinner />
        </Spinner>
      )}
    </LoadContentWrapper>
  );
}

const LanguageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const HeaderSearchPart = styled.div`
  flex: 3;
  align-items: flex-start;
  max-width: 280px;
`;
const HeaderButtonPart = styled.div`
  align-items: flex-end;
`;
const Spinner = styled.div`
  display: flex;
  width: 100%;
  height: 547px;
  justify-content: center;
  align-items: center;
`;
const TableAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const IconDiv = styled.div`
  margin-right: 13;
`;
const AddLanguage = styled.div`
  margin-bottom: 24;
`;
const AddLanguageError = styled.div`
  margin-top: 4;
`;
const TranslationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
const TranslationHeader = styled.div`
  margin-top: 30;
`;
const TranslationButton = styled.div`
  margin-top: 8;
`;

const UpdateKVTSText = styled.div`
  padding-bottom: 24px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
