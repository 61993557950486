import React, { useState } from 'react';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import Chip from 'src/components/atoms/Chip';
import Input from 'src/components/atoms/Input/input.web';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';

interface DivisionDetailObject {
  division: string;
  seats: number;
}

interface Props {
  divisionArray: DivisionDetailObject[];
  setDivisionArray: Function;
}

export default function AddDivisionInput(props: Props) {
  const { divisionArray, setDivisionArray } = props;
  const { t } = useI18n();
  const [division, setDivision] = useState<string>('');
  const [seats, setSeats] = useState<number | null>(null);
  const [divisionError, setDivisionError] = useState<string>('');
  const [seatsError, setSeatsError] = useState<string>('');

  function selectChip() {
    if (!division || !seats) {
      if (!division) setDivisionError(t('error.division.required'));
      if (!seats) setSeatsError(t('error.seats.required'));
    } else {
      let proxyDivision = [...divisionArray];
      const index = proxyDivision.findIndex((item) => item.division === division);
      if (index === -1) {
        proxyDivision.push({ division: division, seats: parseFloat(seats) });
      }
      setDivisionArray(proxyDivision);
      setDivision('');
      setSeats(null);
      setDivisionError('');
      setSeatsError('');
    }
  }

  function removeChip(idx: any) {
    let check = [...divisionArray];
    check.splice(idx, 1);
    setDivisionArray(check);
  }
  return (
    <ParentContainerWrapper>
      <HeaderFive value={t('divisions.label')} />
      {divisionArray && (
        <ChipWrapper marginTop={divisionArray?.length > 0 ? 24 : 0}>
          {divisionArray?.map((chip: any, index: number) => (
            <ChipView>
              <Chip
                label={`Division ${chip?.division}: ${chip?.seats} ${chip?.seats > 1 ? 'Seats' : 'Seat'}`}
                onPress={() => removeChip(index)}
              />
            </ChipView>
          ))}
        </ChipWrapper>
      )}
      <ContainerWrapper marginTop={divisionArray?.length > 0 ? 16 : 24}>
        <InputWrapper>
          <InputErrorWrapper>
            <Input
              id={'division'}
              setValue={(e) => setDivision(e.target.value)}
              value={division}
              header={t('form.division.required.label')}
              width={'332px'}
              typeInput={'string'}
            />
            <ErrorView>
              {divisionError ? (
                <SmallTextAtom value={divisionError} color={colors.errorColor} />
              ) : null}
            </ErrorView>
          </InputErrorWrapper>

          <InputErrorWrapper>
            <Input
              id={'seats'}
              setValue={(e) => setSeats(e.target.value)}
              value={seats || ''}
              header={t('form.seats.required.label')}
              width={'332px'}
              typeInput={'number'}
            />
            <ErrorView>
              {seatsError ? <SmallTextAtom value={seatsError} color={colors.errorColor} /> : null}
            </ErrorView>
          </InputErrorWrapper>
        </InputWrapper>
        <BtnWrapper>
          <SecondaryBtn onPress={selectChip} label={t('add.label')} width={60} />
        </BtnWrapper>
      </ContainerWrapper>
    </ParentContainerWrapper>
  );
}

const ParentContainerWrapper = styled.View`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ErrorView = styled.View`
  margin-top: 4px;
`;

const BtnWrapper = styled.View`
  margin-top: 12px;
`;

const ChipView = styled.View`
  margin-bottom: 8px;
  margin-right: 8px;
`;

const ChipWrapper = styled.View<{ marginTop: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${({ marginTop }) => marginTop};
`;

const InputErrorWrapper = styled.View`
  display: flex;
  flex-direction: column;
  margin-right: 0px;
`;

const ContainerWrapper = styled.View<{ marginTop: number }>`
  display: flex;
  flex-direction: column;
  border-style: dashed;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.dividerColor};
  padding: 8px 16px;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: 24px;
`;

const InputWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
