import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Keyboard, RefreshControl, StyleSheet, View } from 'react-native';
import DownloadFile from 'src/components/atoms/DownloadFile/index';
import ImageAtom from 'src/components/atoms/ImageAtom';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import MediumText from 'src/components/atoms/Text/MediumText';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import MobileHeader from 'src/components/hoc/MobileHeader/index';
import { height, isIOS, isMobile, isNative, isWeb, width } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { breakpoints, colors, fontWeight, fonts } from 'src/styles/theme/styles';
import AssignmentModalContent from './AssignModalContent';
import AvatarCard from 'src/components/molecules/AvatarCard';
import ClassAtom from 'src/components/atoms/ClassAtom';
import { useHistory, useParams } from 'src/routes/routing';
import {
  ASSIGNEE_LIMIT,
  ASSIGNMENT_MODAL_PAGE,
  AUDIENCEPAGE,
  AUDIENCE_TYPE,
  ERROR,
  FileCategory,
  MOBILE_ASSIGNEE_LIMIT,
  SUCCESS,
  USER_TYPE,
} from 'src/constant';
import { assignmentDataType, attachmentType } from '../types';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  getAssignmentCommentQuery,
  getAssignmentSubmissionsQuery,
  getAssignmentsQuery,
  globalAssignmentCommentQuery,
  useCreateAssignmentCommentMutation,
  useCreateAssignmentSubmissionMutation,
  useCreateGlobalAssignmentCommentMutation,
  useUpdateAssignmentMutation,
} from 'src/graphql/communication/assignments';
import { validation } from 'src/constant/validation';
import { usePersistStorage } from 'react-native-use-persist-storage';
import DetailsModal from 'src/components/atoms/Modals/Details/index';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import { userInformation, UserInformation } from 'src/utils/manageState';
import navigationStrings from 'src/navigation/navigationStrings';
import CommentCard from 'src/components/atoms/CommentCard';
import Comments from 'src/components/atoms/Comment/index';
import AvoidKeyPad from 'src/components/atoms/Wrapper/AvoidKey';
import styled from 'styled-components/native';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { KEYS } from 'src/constant/key';
import { useForm } from 'react-hook-form';
import AssignmentForm from 'src/components/molecules/Assignment/Teacher/AssignmentForm';
import { getBatchQuery } from 'src/graphql/academics/batch';
import { showNativeError, showNativeSuccess } from 'src/components/molecules/NotificationWrapper';
import { graphqlQuery } from 'src/graphql/util';
import FileUpload from 'src/components/molecules/FileUpload/index';
import AttachFiles from 'src/components/atoms/AttachFiles/index';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { assigneeLabels, createDocumentObject, properCase } from 'src/utils/utility';
import { CustomReactNativeFile } from 'src/components/molecules/FileUpload/type';
import { filterDoc } from 'src/components/services';
import AudienceWrapper from 'src/components/molecules/Audience/AudienceWrapper';
import { UPDATE_SUCCESS } from 'src/constant/message';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import NoData from 'src/components/molecules/NoData';
import { faBackpack } from '@fortawesome/pro-regular-svg-icons';
import { splitFileTypes } from 'src/utils/fileUtils';
// @ts-expect-error
import ImageGrid from 'src/components/molecules/ImageGrid/ImageGrid';
import { Dimensions } from 'src/constant/standardDimensions';
import HyperLinkText from 'src/components/atoms/Text/HyperLinkText';

let noProfile =
  'https://www.vhv.rs/dpng/d/426-4264903_user-avatar-png-picture-avatar-profile-dummy-transparent.png';

interface propType {
  assignment: any;
  handleWork?: () => void;
  setStudent?: () => void;
  setDetails?: (submittedData: any, submitted: boolean) => void;
  route?: any;
  navigation?: any;
  isUrlBase?: boolean;
  setAssignmentPage?: any;
  setPreviousPage?: any;
  setStudentId?: any;
  isSubmitted: boolean;
  setNewAttachment: any;
  newAttachment: any;
}

interface studentCommentPayload {
  assignment: string;
  comment: string;
}

export default function Assignment(props: propType) {
  const {
    assignment,
    route,
    navigation,
    isUrlBase = true,
    setAssignmentPage,
    setStudentId,
    setPreviousPage,
    setNewAttachment,
    newAttachment,
  } = props;

  const [assignmentIndex] = usePersistStorage<any>(KEYS.ASSIGNMENT_INDEX, null);
  let assignmentId = '';
  let assignmentType = '';
  let setDoRefetch: (arg0: boolean) => void;
  let showDiscussionModal: boolean = false;

  if (isWeb) {
    if (!isUrlBase) {
      assignmentId = assignment?.id;
    } else {
      let params = useParams();
      assignmentId = params.assignmentId;
      assignmentType = params.type;
    }
  } else {
    let params = route?.params;
    setDoRefetch = params?.setDoRefetch;
    assignmentId = params.assignmentId;
    assignmentType = params.type;
    showDiscussionModal = params?.showDiscussionModal;
  }
  const [comment, setComment] = useState('');
  const [editFormModal, openEditFormModal] = useState(false);
  const [canClick, setCanClick] = useState(true);
  const { setAlertDetails } = useAlertSystem();
  const isMobile = width < breakpoints.sm ? true : false;
  const assigneeLimit = isMobile ? MOBILE_ASSIGNEE_LIMIT : ASSIGNEE_LIMIT;
  const { t } = useI18n();
  const history = useHistory();
  const [currentUserObject] = useState<UserInformation>(userInformation());
  const { createGlobalAssignmentComment } = useCreateGlobalAssignmentCommentMutation();
  const { userType } = currentUserObject;
  let teacher = userType === USER_TYPE.EMPLOYEE;
  let assignmentData: any;
  let submittedData: any;
  const [marksObtained, setMarksObtained] = useState<any>(null);
  const [totalMarks, setTotalMarks] = useState<any>(null);

  const { createAssignmentSubmission } = useCreateAssignmentSubmissionMutation(
    assignmentIndex,
    null,
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [assignmentError, setAssignmentError] = useState<string>('');
  const [attachments, setAttachments] = useState<attachmentType[]>([]);
  const [loadedWebAttachments, setLoadedWebAttachments] = useState<attachmentType[]>([]);

  const [oldAttachments, setOldAttachments] = useState<attachmentType[]>([]);
  const [uploadingAttachments, setUploadingAttachments] = useState<attachmentType[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [removeAttach, setRemovedAttachment] = useState<string[]>([]);
  const [batches, setBatches] = useState<any[]>([]);
  let { assignmentUpdation } = useUpdateAssignmentMutation();
  const [submitAssignedToggle, setSubmitAssignedToggle] = usePersistStorage<any>(
    'submit-assigned-switch',
    1,
  );
  const { createAssignmentComment } = useCreateAssignmentCommentMutation();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getPreviousAttachment();
  }, [assignmentId]);

  async function getPreviousAttachment() {
    let { data: submitted } = await graphqlQuery(
      getAssignmentSubmissionsQuery,
      { assignmentId: assignmentId },
      false,
    );

    let submittedData = submitted?.getAssignmentSubmissions?.data;
    storeAttachment(submittedData[0]?.attachments);
  }

  function storeAttachment(attach: any) {
    if (!isWeb) {
      setOldAttachments(attach);
    } else {
      setLoadedWebAttachments(attach);
    }
  }

  function removeAttachment(idx: any) {
    let attachmentArray = isWeb ? [...newAttachment] : [...uploadingAttachments];
    attachmentArray.splice(idx, 1);
    handleNewAttachment(attachmentArray);
  }

  function handleNewAttachment(attach: any) {
    if (isWeb) {
      setNewAttachment(attach);
    } else {
      setUploadingAttachments(attach);
    }
  }

  let comments = [] as any; // for global comment when teacher is login
  let gCommentRefetch: any;
  let stdCommentRefetch: any;
  if (!teacher) {
    const { data: studentAssignmentComment, refetch: studentCommentRefetch } = useQuery(
      getAssignmentCommentQuery,
      {
        variables: { assignmentId: assignmentId },
        fetchPolicy: 'network-only',
      },
    );
    stdCommentRefetch = studentCommentRefetch;
    comments = studentAssignmentComment?.getAssignmentComment?.comments || [];
  }

  if (teacher) {
    const { data: assignmentComment, refetch: globalCommentRefetch } = useQuery(
      globalAssignmentCommentQuery,
      {
        variables: { assignmentId: assignmentId },
        fetchPolicy: 'network-only',
      },
    );
    gCommentRefetch = globalCommentRefetch;
    comments = assignmentComment?.getGlobalAssignmentComment?.comments || [];
  }

  const [getAssignmentSubmissions, { data: submissionsData }] = useLazyQuery(
    getAssignmentSubmissionsQuery,
    { fetchPolicy: 'network-only' },
  );

  submittedData = submissionsData?.getAssignmentSubmissions?.data;

  useEffect(() => {
    const data = submissionsData?.getAssignmentSubmissions?.data;
    if (data) {
      setMarksObtained(data[0]?.marksObtained);
    }
  }, [submissionsData]);

  if (isNative) {
    let filter = { id: { eq: assignmentId } };
    var { data: assignemntDetail, refetch: getAssignemntRefetch, loading } = useQuery(getAssignmentsQuery, {
      variables: { filters: filter },
    });
    assignmentData = assignemntDetail?.getAssignments?.data[0];
  } else {
    assignmentData = { ...assignment };
  }

  useEffect(() => {
    if (assignmentData) {
      setTotalMarks(assignmentData?.totalMarks);
    }
  }, [assignmentData]);

  const parsedDueDate = assignmentData?.mdueDate && parse(assignmentData.mdueDate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date());
  const parsedPostDate = assignmentData?.mdate && parse(assignmentData.mdate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date());
  const dueDate: string = parsedDueDate && format(parsedDueDate, DT.DATE_ABBREVIATED_MONTH_YEAR);
  const postDate: string = parsedPostDate && format(parsedPostDate, DT.DATE_TIME_FORMAT_12_HOUR);

  const allAttachments = assignmentData?.attachments ?? [];
  const { images, attachments: attachedDocs } = splitFileTypes(allAttachments);

  const allBatches = useQuery(getBatchQuery, {
    variables: { limit: 0 },
  });

  useEffect(() => {
    if (!allBatches.loading && allBatches.data) {
      setBatches(allBatches.data.batches.data);
    }
  }, [allBatches.loading, allBatches.data]);

  useEffect(() => {
    getAssignmentSubmissions({ variables: { assignmentId: assignmentData?.id } });
  }, [assignmentData?.submissions, assignmentData?.id]);

  useEffect(() => {
    if (showDiscussionModal) handleWorkModel();
  }, [showDiscussionModal]);

  function checkYourWork() {
    if (!isUrlBase) {
      setAssignmentPage(ASSIGNMENT_MODAL_PAGE[3]);
    } else {
      history.push(`/assignments/${assignmentType}/${assignmentId}/discussion`);
    }
  }

  function handleWorkModel() {
    setModalVisible(true);
    if (submittedData) {
      if (submittedData[0]?.attachments?.length > 0) {
        setAttachments(submittedData[0]?.attachments);
      }
    }
  }

  function closeModal() {
    setCanClick(true);
    // setUploadingAttachments([]); // for native - to not reset the newly added attachments after closing discussion modal & before submit
    setModalVisible(false);
  }

  function showSuccess(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: SUCCESS });
    } else {
      showNativeSuccess(message);
    }
  }

  function showError(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: ERROR });
    } else {
      showNativeError(message);
    }
  }

  async function submitAssignment() {
    if (uploadingAttachments?.length > 0) {
      setCanClick(false);
      setAssignmentError('');
      if (uploadingAttachments.length > 0) {
        let payload = {
          assignment: assignmentData?.id,
          attachments: filterDoc(uploadingAttachments),
        };

        try {
          let submissionResponse = await createAssignmentSubmission({
            variables: { payload: payload },
          });

          if (submissionResponse?.data?.submitAssignment) {
            getPreviousAttachment();
            if (setDoRefetch) setDoRefetch(true)
            showSuccess(t('submissionSuccess.text'));
          }
        } catch (error) {
          showError(error.message);
        }
      }
      setCanClick(true);
    } else {
      setAssignmentError(validation.ATTACHMENT_REQUIRED);
      setCanClick(true);
    }
    setUploadingAttachments([]);
  }

  function getAssigneeList() {
    setSubmitAssignedToggle(1);
    navigation.navigate(navigationStrings.NATIVE_ASSIGNMENT_STUDENT_LIST, {
      assignmentId: assignmentData?.id,
    });
  }

  function listRedirect() {
    setSubmitAssignedToggle(1);
    if (isUrlBase) {
      if (teacher) {
        history.push(`/assignments/${assignmentData?.id}/student-list`);
      } else {
        history.push(`/assignments/${assignmentType}/${assignmentData?.id}/student-list`);
      }
    } else {
      setAssignmentPage(ASSIGNMENT_MODAL_PAGE[1]);
    }
  }

  function postComment() {
    if (comment && comment.trim() != '') {
      let commentPayload = {
        assignment: assignmentId,
        comment: comment.trim(),
      } as studentCommentPayload;

      try {
        let response: any;
        if (teacher) {
          response = createGlobalAssignmentComment({
            variables: { payload: commentPayload },
            refetchQueries: [
              { query: globalAssignmentCommentQuery, variables: { assignmentId: assignmentId } },
            ],
          });
        } else {
          response = createAssignmentComment({
            variables: { payload: commentPayload },
            refetchQueries: [
              { query: getAssignmentCommentQuery, variables: { assignmentId: assignmentId } },
            ],
          });
        }

        if (response) {
          Keyboard.dismiss();
          setComment('');
        }
      } catch (error: any) {
        showError(error.message);
      }
    } else {
      showError('Please type something meaningfull')
    }
  }

  function getToStudentDetail(student: any) {
    if (!isWeb) {
      navigation.navigate(navigationStrings.NATIVE_STUDENT_DETAIL, {
        assignmentId: assignmentData?.id,
        studentId: student?.id,
      });
    } else {
      if (!isUrlBase) {
        setPreviousPage(ASSIGNMENT_MODAL_PAGE[0]);
        setStudentId(student?.id);
        setAssignmentPage(ASSIGNMENT_MODAL_PAGE[2]);
      } else {
        history.push(`/assignments/${assignmentData?.id}/${student?.id}/detail`);
      }
    }
  }

  function nativeRefresh() {
    getAssignemntRefetch();
    if (teacher) {
      gCommentRefetch();
    } else {
      getAssignmentSubmissions({ variables: { assignmentId: assignmentData?.id } });
      stdCommentRefetch();
    }
    setRefreshing(false);
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    nativeRefresh();
  }, []);

  const handleComments = (board: string) => {
    setComment(board);
  };

  const closeFormModal = () => {
    resetForms();
    closeFinal();
  };

  function resetForms() {
    setValue('title', '');
    setValue('description', '');
    setValue('batch', null);
    setValue('class', '');
    setValue('assignees', null);
    setValue('subject', null);
    setValue('dueDate', '');
    setAttachments([]);
    setRemovedAttachment([]);
  }

  function closeFinal() {
    clearErrors();
    openEditFormModal(false);
  }

  async function handleEditAssignment(assignmentDetail: assignmentDataType) {
    try {
      if (canClick) {
        setCanClick(false);
        let requestPayload = {
          id: assignmentData?.id,
          description: assignmentDetail?.description,
          dueDate: assignmentDetail.dueDate,
          attachments: filterDoc(attachments),
          removeAttachments: removeAttach,
          totalMarks: parseFloat(assignmentDetail.totalMarks),
        };
        let updateAssignmentData = await assignmentUpdation({
          variables: { payload: requestPayload },
        });
        if (updateAssignmentData?.data?.updateAssignment) {
          // Mark setDoRefetch to true in order to trigger an update in parent list of assignments
          if (setDoRefetch) setDoRefetch(true);
          setCanClick(true);
          showNativeSuccess(UPDATE_SUCCESS.ASSIGNMENT)
          closeFormModal();
          if (getAssignemntRefetch) getAssignemntRefetch();
        }
      }
    } catch (error) {
      setCanClick(true);
      showError(error.message);
    }
  }

  function openEditModal() {
    openEditFormModal(true);
  }

  function closeEditModal() {
    openEditFormModal(false);
  }

  async function createAttachmentData(fileData: CustomReactNativeFile[], names?: any) {
    let attachmentArray = isWeb ? [...newAttachment] : [...uploadingAttachments];
    const newFileData = await Promise.all(
      fileData.map((file) => createDocumentObject(file, FileCategory.Assignment)),
    );
    if (newFileData) {
      attachmentArray.push(...newFileData)
      handleNewAttachment(attachmentArray)
    }
  }

  const DescriptionView = styled.View`
    margin-top: 16px;
    margin-bottom: ${teacher ? (isWeb ? 40 : 24) : 16}px;
  `;

  return (
    <Container>
      {isNative && (
        <MobileHeader
          {...props}
          label={t('assignment.label')}
          actionLabel={assignmentData ? (teacher ? t('edit.label') : '') : null}
          showSave={teacher}
          handleSave={openEditModal}
          canLoad={teacher ? canClick : true}
        />
      )}

      {assignmentData && Object.keys(assignmentData)?.length > 0 ? (
        <AvoidKeyPad
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
          //   extraHeight={0} // to make avoidKeypad work with ios
          extraScrollHeight={isWeb ? 0 : isIOS ? (height > 900 ? 105 : 80) : 100}
          style={{
            height: '90%',
            flex: isNative ? 0 : 1,
            backgroundColor: colors.white,
            marginLeft: isNative ? 22 : 0,
            marginRight: isNative ? 23 : 0,
          }}>
          <HeaderView>
            <CenterAlignView>
              {isWeb ? (
                <MediumText
                  value={`${t('dueOn.label')} ${dueDate || ''}`}
                  color={colors.primaryColor}
                  fontWeight={fontWeight[400]}
                  lineHeight={2.4}
                  align="left"
                />
              ) : (
                <SmallTextAtom
                  value={`${t('dueOn.label')} ${dueDate || ''}`}
                  color={colors.primaryColor}
                  fontWeight={fontWeight[600]}
                  fontFamily={fonts.semibold}
                  lineHeight={16}
                />
              )}
            </CenterAlignView>

            {teacher ? (
              assignmentData?.assigneeLabels &&
              (
                <ClassView>
                  <ClassAtom
                    name={assigneeLabels(assignmentData)}
                  />
                </ClassView>
              )
            ) : (
              <Fragment>
                {/* {canSubmit ? ( */}
                <TouchableWorkView
                  activeOpacity={1}
                  onPress={() => (isNative ? handleWorkModel() : checkYourWork())}>
                  <View style={{ marginRight: 4 }}>
                    <Icon name="discussion-icon" size={16} />
                  </View>
                  <SmallTextAtom
                    value={t('discussion.label')}
                    color={colors.primaryColor}
                    fontWeight={fontWeight[600]}
                  />
                </TouchableWorkView>
              </Fragment>
            )}
          </HeaderView>
          <SujectAliasView>
            {isWeb ? (
              <HeaderThree
                value={teacher ? assignmentData?.title : assignmentData?.subject?.alias || ''}
                fontWeight={fontWeight[500]}
                color={colors.primaryText}
                align="left"
                numberOfLines={4}
              />
            ) : (
              <NativeHeaderWrapper >
                <HeaderThree
                  value={teacher ? assignmentData?.title : assignmentData?.subject?.alias || ''}
                  fontWeight={fontWeight[700]}
                  color={colors.primaryText}
                  fontSize={24}
                  lineHeight={32}
                  align="left"
                  numberOfLines={4}
                />
              </NativeHeaderWrapper>
            )}
          </SujectAliasView>
          <PostedOnView>
            {isWeb ? (
              <NormaltextAtom
                value={`${t('postedOn.label')} ${postDate}`}
                color={colors.secondaryText}
              />
            ) : (
              <SmallTextAtom
                value={`${t('postedOn.label')} ${postDate}`}
                color={colors.secondaryText}
              />
            )}
          </PostedOnView>

          <DescriptionView>
            <HyperLinkText
              text={assignmentData?.description ?? ''}
            />
          </DescriptionView>

          {!teacher && assignmentData && (
            <UserView>
              <ImageAtom
                source={{
                  uri: assignmentData?.assignor?.profilePicture?.documentUrl || noProfile,
                }}
                style={[styles.imgStyle]}
              />
              <UserNameView>
                <MediumText
                  value={properCase(assignmentData?.assignor?.personalDetails?.fullName || '-')}
                  color={colors.primaryText}
                />
              </UserNameView>
            </UserView>
          )}

          {
            allAttachments.length > 0 && (
              <AttachmentContainer>
                <AttachmentLabelView>
                  <TitleAtom passKey={"attachments.label"} />
                </AttachmentLabelView>

                {images.length > 0 && (
                  <ImageGridWrapper>
                    <ImageGrid
                      data={images}
                      containerDimensions={imageGridContainerDimensions}
                    />
                  </ImageGridWrapper>
                )}

                {attachedDocs.length > 0 && (
                  <AttachmentView>
                    {attachedDocs?.map((fileData, index) => (
                      <DownloadFile
                        fileData={fileData}
                        key={`dwfile${index}`}
                        isBoxDownload={true}
                        alignElement={"space-between"}
                      />
                    ))}
                  </AttachmentView>
                )}
              </AttachmentContainer>
            )
          }

          {/* grade-obtained*/}
          {!teacher && (
            <GradeBoxView>
              <TitleAtom
                passKey={
                  marksObtained
                    ? 'marksObtained.label'
                    : 'totalMarks.label'
                }
              />
              <MarksView>
                {marksObtained ? `${marksObtained} / ${totalMarks}` : totalMarks}
              </MarksView>
            </GradeBoxView>
          )}

          {/* student your work*/}
          {!teacher && (
            <View style={{ marginTop: 20 }}>
              <>
                <View>
                  <TitleAtom passKey={'yourWork.label'} />
                </View>
              </>

              {/* attachment already have*/}
              {isWeb ? (
                <DownloadAtom attachments={loadedWebAttachments} />
              ) : (
                <DownloadAtom attachments={oldAttachments} />
              )}
              {/* attachment new have*/}

              <>
                {isWeb ? (
                  <AttachAtom attachments={newAttachment} handleRemove={removeAttachment} />
                ) : (
                  <AttachAtom attachments={uploadingAttachments} handleRemove={removeAttachment} />
                )}
              </>

              <View style={[styles.fileView, { marginTop: 4 }]}>
                <FileUpload
                  name="studentAttach"
                  fieldId="studentAttach"
                  handleFilesAttach={createAttachmentData}
                  header={t('addAssignment.text')}
                  multiple={true}
                  attachment={true}
                  setError={setError}
                  clearErrors={clearErrors}
                />
                <View>
                  {errors && errors['studentAttach'] && (
                    <SmallTextAtom value={t(errors['studentAttach']?.message)} color={colors.errorColor} />
                  )}
                </View>

                {!isWeb && (
                  <View style={[styles.submitView]}>
                    <SecondaryBtn
                      canLoad={!canClick}
                      disabled={uploadingAttachments?.length < 1 ? true : false}
                      label={t('submit.label')}
                      style={[
                        styles.submitStyle,
                        { opacity: uploadingAttachments?.length < 1 ? 0.5 : 1 },
                      ]}
                      onPress={submitAssignment}
                      width={'100%'}
                    />
                  </View>
                )}
              </View>
            </View>
          )}

          {/* comment*/}
          <>
            {/* <CommentWrapper> */}
            <TitleAtom
              passKey={
                !teacher
                  ? 'personalComments.label'
                  : 'discussion.label'
              }
            />
            <CommentView>
              {comments?.map((cmt: any, index: number) => (
                <View key={`cmt${index}${cmt?.time}`} style={{ marginBottom: 24 }}>
                  <CommentCard
                    comment={cmt?.comment || ''}
                    time={cmt?.createdAt}
                    commentor={cmt?.from}
                  />
                </View>
              ))}
            </CommentView>

            <LeaveCommentView>
              <Comments
                setValue={handleComments}
                value={comment}
                header={t('addComment.text')}
                postComment={postComment}
                width={!isMobile && 480}
                sty
              />
            </LeaveCommentView>
            {/* </CommentWrapper> */}
          </>

          {/* students List*/}
          {teacher && assignmentData?.assigneeList?.length > 0 && (
            <>
              <StudentView>
                <TitleAtom passKey={'students.label'} />
                <ViewAll onPress={isWeb ? listRedirect : getAssigneeList}>
                  <SmallTextAtom value={t("viewAll.text")} color={colors.primary} />
                </ViewAll>
              </StudentView>
              <AvatarView>
                <AvatarCard
                  count={assigneeLimit}
                  setStudent={isWeb ? listRedirect : getAssigneeList}
                  // setDetails={isWeb ? setDetails : getToStudentDetail}
                  setDetails={getToStudentDetail}
                  assigneeList={assignmentData?.assigneeList?.slice(0, assigneeLimit) || []}
                  totalStudents={assignmentData?.assigneeList?.length}
                />
              </AvatarView>
            </>
          )}
        </AvoidKeyPad>
      ) : (
        <SpinnerView>
          {loading ? (
            <LoaderSpinner />
          ) : (
            <NoData text1={t('assignmentNotAvailable.text')} icon={faBackpack} />
          )}
        </SpinnerView>
      )}

      {/* Native edit*/}

      <AudienceWrapper
        isSubmitting={!canClick}
        setModalVisible={closeEditModal}
        Headerpopup={t('editAssignment.text')}
        modalVisible={editFormModal}
        width={578}
        height={425}
        cancelButtonLabel="cancel.label"
        handleSave={handleEditAssignment}
        addEditButtonLabel="save.label"
        style={{ paddingBottom: 0 }}
        handleSubmit={handleSubmit}
        isEditModal={true}
        types={[
          AUDIENCE_TYPE.BATCH,
        ]}
        editDetails={assignmentData?.assigneeType === 'STUDENTS' ?
          assignmentData?.assigneeList : assignmentData?.assigneeLabels}
        parentType={'ASSIGNMENT'}
      >
        <AssignmentForm
          control={control}
          allBatches={batches}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          setAttachments={setAttachments}
          attachments={attachments}
          isEditModal={true}
          assignmentData={assignmentData}
          removeAttach={removeAttach}
          setRemovedAttachment={setRemovedAttachment}
          setError={setError}
          clearErrors={clearErrors}
          audiencePage={AUDIENCEPAGE.VIEW}
        />
      </AudienceWrapper>

      {/* {modalVisible && ( */}
      <AssignmentModal
        modalVisible={modalVisible} // from parent
        closeModal={closeModal} // can be refactor to child
        assignmentData={assignmentData} // from parent
        attachments={attachments}
        setAttachments={setAttachments}
        assignmentError={assignmentError}
        submitAssignment={submitAssignment}
        setNewAttachment={setUploadingAttachments}
        newAttachment={uploadingAttachments}
        canClick={canClick}
      />
      {/* )} */}
    </Container>
  );
}

function DownloadAtom({ attachments }: any) {
  return (
    <>
      {attachments && attachments?.length > 0 && (
        <AttachmentView style={{ marginTop: isNative ? 5 : 16 }}>
          {attachments.length > 0 ? (
            <AttachmentView>
              {attachments?.map((fileData: attachmentType, index: number) => (
                <DownloadFile
                  fileData={fileData}
                  key={`dwfile${index}`}
                  isBoxDownload={true}
                  alignElement={'space-between'} />
              ))}
            </AttachmentView>
          ) : null}
        </AttachmentView>
      )}
    </>
  );
}

function AttachAtom({ attachments, handleRemove }: any) {
  return (
    <>
      {attachments && attachments.length > 0 && (
        <View style={[styles.attachWrapper]}>
          {attachments &&
            attachments?.map((fileData: any, i: number) => (
              <View style={[styles.attachView]} key={`attaching${i}`}>
                <AttachFiles fileData={fileData} onPress={() => handleRemove(i)} />
              </View>
            ))}
        </View>
      )}
    </>
  );
}

function TitleAtom({ passKey }: any) {
  const { t } = useI18n();
  return (
    <>
      {isMobile ? (
        <MediumTextSelect
          value={t(passKey)}
          fontWeight={fontWeight[600]}
          fontFamily={fonts.semibold}
          color={colors.primaryText}
        />
      ) : (
        <HeaderSix value={t(passKey)} fontWeight={fontWeight[400]} color={colors.primaryText} />
      )}
    </>
  );
}

function AssignmentModal({ modalVisible, closeModal, assignmentData, ...props }: any) {
  const { t } = useI18n();
  return (
    <DetailsModal
      modalVisible={modalVisible}
      setModalVisible={closeModal}
      Headerpopup={t('discussion.label')}
      assignment={true}>
      <AssignmentModalContent assignmentId={assignmentData?.id} />
    </DetailsModal>
  );
}

const styles = StyleSheet.create({
  imgStyle: {
    height: 32,
    width: 32,
    borderRadius: 50,
  },
  submitStyle: { height: 48, borderRadius: 32 },
  fileView: { marginBottom: 24, maxWidth: 350 },
  attachWrapper: { flexDirection: 'row', flexWrap: 'wrap', marginTop: isWeb ? 21 : 0 },
  attachView: { marginRight: 24, marginBottom: isWeb ? 24 : 0 },
  submitView: {
    width: isIOS && height > 900 ? '106%' : '100%',
    justifyContent: 'center',
    marginTop: 24
  },
});

const imageGridContainerDimensions: Dimensions = isWeb
  ? { width: 672, height: "100%" }
  : { width: "100%", height: 200 };

const ImageGridWrapper = styled.View`
  margin-bottom: 24px;
`;

const Container = styled.View`
  background-color: ${colors.white};
`;

const HeaderView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${isWeb ? 3 : 16}px;
  margin-bottom: ${isWeb ? -2 : 0}px;
`;

const CenterAlignView = styled.View`
  align-self: center;
  margin-top: ${isWeb ? 2 : 0}px;
`;

const ClassView = styled.View`
  max-width: 60%;
  margin-top: ${isWeb ? -4 : 0}px;
  align-self: center;
`;

const TouchableWorkView = styled.TouchableOpacity`
  flex-direction: row;
`;

const SujectAliasView = styled.View`
  margin-top: ${isWeb ? 0 : isIOS ? (height > 900 ? 7 : 2) : 4}px;
  margin-left: -1px;
`;

const PostedOnView = styled.View`
  margin-top: ${isWeb ? 2 : 4}px;
`;

const UserView = styled.View`
  flex-direction: row;
  margin-bottom: 24px;
`;

const UserNameView = styled.View`
  align-self: center;
  margin-left: 8px;
`;

const AttachmentContainer = styled.View`
  margin-bottom: ${isWeb ? 24 : 13}px;
`;

const AttachmentLabelView = styled.View`
  margin-bottom: 16px;
`;

const LeaveCommentView = styled.View`
  margin-bottom: 16px;
`;

const AttachmentView = styled.View`
  max-width: 100%;
`;

const GradeBoxView = styled.View`
  margin-bottom: 0px;
`;

const MarksView = styled.Text`
  margin-top: 4px;
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  color: ${colors.primaryText};
`;

const CommentView = styled.View`
  margin-top: ${isWeb ? 16 : 8}px;
`;

const StudentView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: ${isWeb ? 8 : 10}px;
`;

const AvatarView = styled.View`
  margin-bottom: 18px;
  margin-left: 2px;
`;

const SpinnerView = styled.View`
  background-color: ${colors.white};
  height: ${isWeb ? 450 : height - 100}px;
  justify-content: center;
  align-items: center;
`;

const NativeHeaderWrapper = styled.View`
  padding-top: 2px;
`;

const ViewAll = styled.TouchableOpacity``;