import React from 'react';
import { DescriptionCell, HeaderCell } from './GradeCardElements';
import { Table, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import styled from 'styled-components/native';
import { colors, fonts } from 'src/styles/theme/styles';

export default function TableOneGradeCard(props: { data: any; totalStudentCount: number; }) {
  const { data, totalStudentCount } = props;
  const widthMultiplier: number = 100;

  let PartOneHeaders = [
    {
      id: 'standard',
      label: 'STD',
      colspan: 1
    },
    {
      id: 'division',
      label: 'DIV',
      colspan: 1
    },
    {
      id: 'rollNo',
      label: 'ROLL NO.',
      colspan: 1
    },
    {
      id: 'totalStrength',
      label: 'TOTAL STRENGTH',
      colspan: 2
    }
  ];

  let PartOneRows = [
    {
      id: 'standard',
      label: data?.batch?.class?.alias,
      colspan: 1
    },
    {
      id: 'division',
      label: data && data?.user && data?.user?.academic && data?.user?.academic?.division || "N/A",
      colspan: 1
    },
    {
      id: 'rollNo',
      label: data && data?.user && data?.user?.academic && data?.user?.academic?.rollNo || "N/A",
      colspan: 1
    },
    {
      id: 'totalStrength',
      label: totalStudentCount,
      align: 'left',
      colspan: 2
    }
  ];

  let PartTwoHeaders = [
    {
      id: 'grNo',
      label: 'G.R.NO.',
      colspan: 1
    },
    {
      id: 'srNo',
      label: 'SERIAL NO.',
      colspan: 1
    },
    {
      id: 'weight',
      label: 'WEIGHT(KG)',
      colspan: 1
    },
    {
      id: 'dob',
      label: 'DATE OF BIRTH',
      colspan: 1
    }
  ];

  let PartTwoRows = [
    {
      id: 'grNo',
      label: data && data?.user && data?.user?.academic && data.user?.academic?.grNo,
      colspan: 1
    },
    {
      id: 'srNo',
      label: data?.user?.serialNo,
      colspan: 1
    },
    {
      id: 'weight',
      label: 'N/A',
      colspan: 1
    },
    {
      id: 'dob',
      label: data?.user?.personalDetails?.dob,
      colspan: 2
    }
  ];

  return (
    <TableOneContainer>
      <TableContainer>
        <Table sx={{fontFamily: fonts.regular}}>
          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              {
                PartOneHeaders.map((element) => {
                  return (
                    <HeaderCell width={widthMultiplier * element?.colspan} value={element?.label} />
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {
                PartOneRows.map((element) => {
                  return (
                    <DescriptionCell width={widthMultiplier * element?.colspan} value={element?.label} />
                  );
                })
              }
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              {
                PartTwoHeaders.map((element) => {
                  return (
                    <HeaderCell width={widthMultiplier * element?.colspan} value={element?.label} />
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              PartTwoRows.map((element) => {
                return (
                  <DescriptionCell width={widthMultiplier * element?.colspan} value={element?.label} />
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </TableOneContainer>
  );
}

const TableOneContainer = styled.View`
  width: 500px;
`;