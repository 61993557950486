import {
  ApplicationStatus,
  ApplicationStatusLabel,
} from "src/constant";

import CreateInfoItem from "src/components/molecules/TextComponents/InfoItem";
import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import React from "react";
import StepperTimeline from "src/components/atoms/Timeline/StepperTimeline";
import { colors } from "src/styles/theme/styles";
import { getApplicationStatusColor } from "src/components/atoms/FunctionsList";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";
import { format } from "date-fns";
import { DT } from "src/constant/dateTime";

function NestedApplicantDetail({
  item,
  referrerOnPress,
}: {
  item: any;
  referrerOnPress: (item: any) => void;
}) {
  const { t } = useI18n();
  const itemWidth = "25%";
  const applicantTypeBadges: string[] = [];
  const quotaBadge: string[] = [];

  if (item?.cap?.capVerified) applicantTypeBadges.push(t("merit.label"));
  if (item?.inHouseApplied) applicantTypeBadges.push(t("inhouse.label"));

  if (item?.fees?.concession) quotaBadge.push(t("concession.label"));

  function getSortedStatusActivity(
    statusActivity: { date: number; status: ApplicationStatus }[]
  ) {
    return statusActivity
      .map((item) => ({
        ...item,
        label: ApplicationStatusLabel[item.status],
        timestamp: format(item.date, DT.DATE_TIME_SLASH_12_HOUR),
        color: getApplicationStatusColor(item.status),
      }))
      .sort((a, b) => a.date - b.date);
  }

  return (
    <Container>
      <InfoWrapper>
        <CreateInfoItem
          label={t("applicantType.label")}
          value={item?.applicantType}
          width={itemWidth}
          badges={applicantTypeBadges}
        />
        <CreateInfoItem
          label={t("subjectGroup.label")}
          value={item?.subjectGroup?.name}
          width={itemWidth}
        />
        <CreateInfoItem
          label={t("averageScore.label")}
          value={
            item?.reqEducation &&
            (item.reqEducation?.averageMarks ||
              item.reqEducation?.gradeObtained ||
              "-")
          }
          width={itemWidth}
        />
        <CreateInfoItem
          label={t("merit.label")}
          value={item?.qualifiedRound?.name}
          width={itemWidth}
        />
        <CreateInfoItem
          label={t("quota.label")}
          value={item?.quota?.name}
          width={itemWidth}
          badges={quotaBadge}
          customOnPress={
            item?.quota &&
            item.quota?.name === "Institute Level" &&
            (() => referrerOnPress(item))
          }
        />
        {item?.user && item.user?.academic && item.user.academic?.division && (
          <CreateInfoItem
            label={t("division.label")}
            value={item.user.academic.division}
            width={itemWidth}
          />
        )}
        <CreateInfoItem
          label={t("email.label")}
          value={item?.user?.personalDetails?.email}
          width={itemWidth}
        />
        <CreateInfoItem
          label={t("mobileNumber.label")}
          value={item?.user?.personalDetails?.mobileNumber}
          width={itemWidth}
        />
      </InfoWrapper>
      {item?.statusActivity && item.statusActivity.length > 1 && (
        <TimelineWrapper>
          <MediumTextSelect value={"Timeline"} />
          <StepperTimeline
            statusActivity={getSortedStatusActivity(item?.statusActivity)}
          />
        </TimelineWrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  border: 2px solid ${colors.tableDropdwonBorder};
  padding: 16px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 16px;
  margin-bottom: 16px;
`;

const TimelineWrapper = styled.div`
  padding-top: 16px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: ${colors.deviderGrey};
`;

export default NestedApplicantDetail;
