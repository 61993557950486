import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RefreshControl, ScrollView } from 'react-native';
import styled from 'styled-components/native';

import MobileHeader from 'src/components/hoc/MobileHeader';
import { getUsersQuery, useInviteEmployee } from 'src/graphql/control-panels/user';
import { useI18n } from 'src/i18n/hooks';
import { colors } from 'src/styles/theme/styles';
import AddButton from 'src/components/atoms/AddButton';
import NormalModal from 'src/components/atoms/Modals/Normal/index.native';
import UserCard from 'src/components/atoms/MultiAccountUserCard';
import { EMPLOYEE_TYPES } from 'src/constant';
import { InstituteAdminFormData } from '../ControlPanel/User.web';
import InviteUserForm from 'src/components/molecules/ControlPanel/InviteUserForm';
import { userInformation } from 'src/utils/manageState';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';
import { height, isIOS } from 'src/constant/device';
import { ADD_SUCCESS } from 'src/constant/message';
import NotificationWrapper, { showMessage } from 'src/components/molecules/NotificationWrapper';
import { properCase } from 'src/utils/utility';

const Users = (props: any) => {
  const [inviteModal, showInviteModal] = useState(false);
  const [toggleAdd, setAdd] = useState<any>(false);
  const [refreshing, setRefreshing] = useState(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [users, setUsers] = useState<any>([]);

  const { t } = useI18n();
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;
  const { inviteInstituteEmployee } = useInviteEmployee();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const {
    data: usersData,
    loading: usersLoading,
    refetch: refetchUsers,
  } = useQuery(getUsersQuery, {
    variables: {
      limit: 0, filters: {
        type: { in: [EMPLOYEE_TYPES.INSTITUTE_ADMIN] }
      }
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (usersData?.instituteEmployees?.data) {
      setUsers([...usersData?.instituteEmployees?.data]);
    }
  }, [usersLoading]);

  const onRefresh = () => {
    setRefreshing(true);
    setAdd(true);
    refetchUsers();
    setRefreshing(false);
  };

  const createUserObj = (userInfo: any) => {
    const userData = {
      userId: userInfo?.id,
      fullName: properCase(userInfo?.personalDetails?.fullName),
      email: userInfo?.email,
      profilePicture: userInfo?.profilePicture?.documentUrl,
    };
    return userData;
  };

  const closeModal = () => {
    showInviteModal(false);
  };

  const edbaInstituteAdminInvitation = async (employeeData: InstituteAdminFormData) => {
    const inviteUserData = {
      email: employeeData?.email,
      employeeId: employeeData?.employeeId,
      firstName: employeeData?.firstName,
      lastName: employeeData?.lastName,
      types: [EMPLOYEE_TYPES.INSTITUTE_ADMIN],
      isAdmin: true,
    };

    try {
      const inviteResponse = await inviteInstituteEmployee({
        variables: { payload: inviteUserData },
      });
      if (inviteResponse?.data?.inviteEmployee) {
        setCanClick(true);
        closeModal();
        reset({});
        await refetchUsers();
        showMessage({
          message: ADD_SUCCESS.INVITE_USER,
          type: 'success',
          position: 'top',
          icon: 'success',
        });
      }
    } catch (e) {
      setCanClick(true)
      showMessage({
        message: e?.message,
        type: 'danger',
        position: 'top',
        icon: 'danger',
      });
    }
  };

  const handleInviteUser = async (formData) => {
    setCanClick(false);
    await edbaInstituteAdminInvitation(formData);
  };

  return (
    <>
      <NotificationWrapper>
        <MobileHeader label={t('users.label')} backIcon={false} {...props} />
        <Container>
          {toggleAdd && (
            <AddButtonWrapper>
              <AddButton
                onPress={() => showInviteModal(true)}
                label={t('inviteUser.text')}
                color={colors.primaryText}
              />
            </AddButtonWrapper>
          )}
          <ScrollViewWrapper
            style={{ height: isIOS ? (height > 800 ? height - 185 : height - 140) : height - 155 }}>
            <ScrollView
              contentContainerStyle={{ flexGrow: 1 }}
              showsVerticalScrollIndicator={false}
              onScroll={(e) => {
                const currentOffset = e?.nativeEvent?.contentOffset?.y;
                if (currentOffset > 2) setAdd(false);
              }}
              refreshControl={
                <RefreshControl
                  tintColor={colors.primary}
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                />
              }>
              <UserListWrapper>
                {users.length > 0
                  ? users.map((user) => {
                      return <UserCard userInfo={createUserObj(user)} showOption={false} />;
                    })
                  : null}
              </UserListWrapper>

              {/* {usersLoading && (
            <SpinnerView>
              <LoaderSpinner />
            </SpinnerView>
          )} */}
            </ScrollView>
          </ScrollViewWrapper>
        </Container>
        <NormalModal
          isSubmitting={!canClick}
          onAlert={null}
          setModalVisible={closeModal}
          modalVisible={inviteModal}
          handleSave={handleSubmit(handleInviteUser)}
          Headerpopup={t('inviteUser.text')}
          cancelButtonLabel="cancel.label"
          addEditButtonLabel="invite.label"
          lineHeight={32}>
          <InviteUserForm control={control} errors={errors} onlyAdmin={true} userType={userType} />
        </NormalModal>
      </NotificationWrapper>
    </>
  );
};

export default Users;

const Container = styled.View`
  padding: 0px 24px;
`;

const AddButtonWrapper = styled.View`
  margin-top: 24px;
`;

const ScrollViewWrapper = styled.View``;

const UserListWrapper = styled.View`
  margin-top: 18px;
`;

const SpinnerView = styled.View`
  height: ${height - 100}px;
  justify-content: center;
  align-items: center;
`;
