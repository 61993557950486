import React from 'react';
import { Image } from 'react-native';
import styled, { useTheme } from 'styled-components';
import { fonts } from 'src/styles/theme/styles';
import { USER_TYPE } from 'src/constant';
import { properCase } from 'src/utils/utility';

interface IProps {
  image: string;
  bannerTitleHello: string;
  fullName: string;
  className: string;
  bannerTitleId: string;
  id?: string;
  userType?: number;
  division?: string;
}

const DashboardMainBanner = ({
  image,
  bannerTitleHello,
  fullName,
  className,
  bannerTitleId,
  id,
  userType,
  division,
}: IProps) => {
  const { rem }: any = useTheme();
  return (
    <MainBanner>
      <BannerCont>
        <ImageBlock>
          <Image
            style={{ width: rem(10), height: rem(10) }}
            source={{
              uri: image,
            }}
          />
        </ImageBlock>
        <TextBlock>
          <UserName>
            {bannerTitleHello}
            {properCase(fullName || '-')}!
          </UserName>
          {className && userType === USER_TYPE.STUDENT && (
            <StudentClass>
              {className} - {division}
            </StudentClass>
          )}
          {id && (
            <StudentId>
              {bannerTitleId}: {id}
            </StudentId>
          )}
        </TextBlock>
      </BannerCont>
    </MainBanner>
  );
};

export default DashboardMainBanner;

const MainBanner = styled.div`
  display: flex;
  background-color: #2b78ca;
  background-image: url('https://res.cloudinary.com/dxzxqtpy6/image/upload/v1631594811/samples/banner-bg_qegrqn.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  border-radius: ${({ theme }) => theme.rem(1)};
  padding: ${({ theme }) => theme.rem([1.5, 7.8])};
  margin-top: ${({ theme }) => theme.rem(1.6)};
  margin-bottom: ${({ theme }) => theme.rem(6)};
  min-height: ${({ theme }) => theme.rem(26)};
  box-sizing: border-box;
  @media all and (max-width: 1200px) {
    padding: ${({ theme }) => theme.rem([1.5, 3])};
    min-height: ${({ theme }) => theme.rem(18)};
  }
  @media all and (max-width: 991px) {
    padding: ${({ theme }) => theme.rem(1.5)};
    min-height: none;
  }
`;

const BannerCont = styled.div`
  display: flex;
  align-items: center;
  @media all and (max-width: 767px) {
    flex-direction: column;
    margin: auto;
  }
`;

const ImageBlock = styled.div`
  width: ${({ theme }) => theme.rem(10)};
  height: ${({ theme }) => theme.rem(10)};
  border: ${({ theme }) => theme.rem(0.4)} solid #fff;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.rem(3.6)};
  overflow: hidden;
  @media all and (max-width: 767px) {
    margin-bottom: ${({ theme }) => theme.rem(2)};
  }
  background-color: #fff;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.rem(-0.2)};
`;

const UserName = styled.text`
  color: #fff;
  font-size: ${({ theme }) => theme.rem(3.2)};
  font-family: ${fonts.medium};
  margin-bottom: ${({ theme }) => theme.rem(0.5)};
`;

const StudentClass = styled.text`
  color: #fff;
  font-size: ${({ theme }) => theme.rem(2.1)};
  font-family: ${fonts.medium};
  margin-bottom: ${({ theme }) => theme.rem(0.8)};
`;

const StudentId = styled.text`
  color: #fff;
  font-size: ${({ theme }) => theme.rem(1.5)};
  font-family: ${fonts.medium};
`;
