import React, { CSSProperties } from 'react';

export interface IconProps {
  rem: any;
  style?: CSSProperties;
  size?: number;
}

export const EDBALogo = ({ rem, style }: IconProps) => {
  return (
    <svg
      width={rem(10.7)}
      height={rem(4)}
      viewBox="0 0 113 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}>
      <path
        d="M40.6275 39.9038C45.4551 39.9038 47.9663 37.1511 49.1148 34.6872H49.4652V40H57.6V20V0H49.3678V14.8219H49.1148C48.0052 12.4158 45.6108 9.47065 40.608 9.47065C34.0479 9.47065 28.5 14.514 28.5 24.6776C28.5 34.5717 33.8143 39.9038 40.6275 39.9038ZM43.2554 33.3782C39.187 33.3782 36.9678 29.7979 36.9678 24.6391C36.9678 19.5188 39.148 15.9962 43.2554 15.9962C47.2849 15.9962 49.543 19.3648 49.543 24.6391C49.543 29.9134 47.246 33.3782 43.2554 33.3782Z"
        fill="#2B78CA"
      />
      <path
        d="M14.8333 40C22.2305 40 27.2138 36.4389 28.3818 30.9528L20.7121 30.4524C19.875 32.7045 17.7338 33.8787 14.9695 33.8787C10.8232 33.8787 8.19529 31.1646 8.19529 26.7565V26.7372H28.557V24.4851C28.557 14.437 22.4057 9.47064 14.5024 9.47064C5.70361 9.47064 0 15.6497 0 24.7738C0 34.1482 5.62575 40 14.8333 40ZM8.19529 21.6554C8.37049 18.2868 10.9595 15.5919 14.6386 15.5919C18.2399 15.5919 20.7316 18.1328 20.751 21.6554H8.19529Z"
        fill="#2B78CA"
      />
      <path
        d="M57.6 40H65.7679V34.7039H66.1374C67.2848 37.169 69.7935 39.923 74.6164 39.923C81.423 39.923 86.7322 34.5884 86.7322 24.6895C86.7322 14.5209 81.1897 9.47522 74.6359 9.47522C69.6379 9.47522 67.2459 12.4218 66.1374 14.8291H65.8846V0L57.6 0V20V40ZM65.7095 24.6509C65.7095 19.3741 67.9655 16.0039 71.9911 16.0039C76.0945 16.0039 78.2726 19.5282 78.2726 24.6509C78.2726 29.8122 76.0556 33.3943 71.9911 33.3943C68.0043 33.3943 65.7095 29.9278 65.7095 24.6509Z"
        fill="#FF9000"
      />
      <path
        d="M95.9182 40C100.333 40 103.191 38.0934 104.65 35.3394H104.883V40H112.74V19.4897C112.74 12.441 106.711 9.47522 100.06 9.47522C92.9038 9.47522 88.1976 12.8647 87.0502 18.2571L94.7124 18.8734C95.2764 16.909 97.0461 15.4646 100.022 15.4646C102.841 15.4646 104.456 16.8705 104.456 19.2971V19.4126C104.456 21.3192 102.414 21.5696 97.2211 22.0703C91.3091 22.6095 86 24.5739 86 31.1796C86 37.0342 90.2201 40 95.9182 40ZM109.842 0H90.9007V4.44873H109.842V0ZM98.2907 34.338C95.7431 34.338 93.9151 33.1632 93.9151 30.91C93.9151 28.5989 95.8404 27.4627 98.7575 27.0583C100.566 26.8079 103.522 26.3842 104.514 25.7294V28.8686C104.514 31.9692 101.927 34.338 98.2907 34.338Z"
        fill="#FF9000"
      />
    </svg>
  );
};

export const NewsDefault = ({ rem, style }: IconProps) => {
  return (
    <svg
      width={rem(10)}
      height={rem(8)}
      viewBox="0 0 95 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}>
      <rect x="1" y="1" width={rem(10)} height={rem(8)} fill="black" fill-opacity="0.2" />
      <rect x="1" y="1" width={rem(10)} height={rem(8)} stroke="white" stroke-opacity="0.2" />
      <mask
        id="mask0_12293_33560"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width={rem(9.6)}
        height={rem(7.2)}
      />
      <path
        d="M56.0098 25H38.0098C36.3551 25 35.0098 26.3453 35.0098 28V41.5C35.0098 43.1547 36.3551 44.5 38.0098 44.5H42.5098V48.4375C42.5098 48.7703 42.7816 49 43.0723 49C43.1848 49 43.302 48.9672 43.4051 48.8875L49.2598 44.5H56.0098C57.6645 44.5 59.0098 43.1547 59.0098 41.5V28C59.0098 26.3453 57.6645 25 56.0098 25ZM57.5098 41.5C57.5098 42.325 56.8348 43 56.0098 43H48.7582L48.3598 43.3L44.0098 46.5625V43H38.0098C37.1848 43 36.5098 42.325 36.5098 41.5V28C36.5098 27.175 37.1848 26.5 38.0098 26.5H56.0098C56.8348 26.5 57.5098 27.175 57.5098 28V41.5ZM41.0098 33.625C40.3863 33.625 39.8848 34.1266 39.8848 34.75C39.8848 35.3734 40.3863 35.875 41.0098 35.875C41.6332 35.875 42.1348 35.3734 42.1348 34.75C42.1348 34.1266 41.6332 33.625 41.0098 33.625ZM47.0098 33.625C46.3863 33.625 45.8848 34.1266 45.8848 34.75C45.8848 35.3734 46.3863 35.875 47.0098 35.875C47.6332 35.875 48.1348 35.3734 48.1348 34.75C48.1348 34.1266 47.6332 33.625 47.0098 33.625ZM53.0098 33.625C52.3863 33.625 51.8848 34.1266 51.8848 34.75C51.8848 35.3734 52.3863 35.875 53.0098 35.875C53.6332 35.875 54.1348 35.3734 54.1348 34.75C54.1348 34.1266 53.6332 33.625 53.0098 33.625Z"
        fill="white"
      />
    </svg>
  );
};

export const StudentGradIcon = ({ rem, size }: IconProps) => {
  return (
    <svg width={rem(2.8)} height={rem(size ? size : 3.2)} viewBox="0 0 28 32" fill="none">
      <path
        d="M0.825 6.25L1.25 6.375V8.725C0.8125 8.9875 0.5 9.44375 0.5 9.99375C0.5 10.5187 0.7875 10.9563 1.19375 11.225L0.21875 15.125C0.1125 15.5562 0.35 16 0.69375 16H3.30625C3.65 16 3.8875 15.5562 3.78125 15.125L2.80625 11.2312C3.2125 10.9625 3.5 10.525 3.5 10C3.5 9.45 3.1875 8.99375 2.75 8.73125V6.825L5.6625 7.6875C5.25 8.7125 5 9.825 5 11C5 15.9688 9.03125 20 14 20C18.9688 20 23 15.9688 23 11C23 9.825 22.75 8.7125 22.3375 7.6875L27.175 6.25C28.275 5.925 28.275 4.075 27.175 3.75L15.0562 0.15625C14.7062 0.05 14.3562 0 14 0C13.6438 0 13.2938 0.05 12.9438 0.15625L0.825 3.75C-0.275 4.075 -0.275 5.925 0.825 6.25ZM14 17C10.6938 17 8 14.3062 8 11C8 10.1187 8.20625 9.29375 8.55 8.54375L12.95 9.85C13.875 10.125 14.65 9.975 15.0625 9.85L19.4625 8.54375C19.8062 9.29375 20.0125 10.125 20.0125 11C20 14.3062 17.3062 17 14 17ZM13.8 3.03125C13.8625 3.0125 14.0063 2.975 14.2063 3.03125L20.8438 5L14.2 6.96875C14.0687 7.00625 13.9375 7.0125 13.7937 6.96875L7.15625 5L13.8 3.03125ZM19.9625 20.0375L14 25L8.0375 20.0375C3.56875 20.2313 2.98023e-08 23.8875 2.98023e-08 28.4V29C2.98023e-08 30.6562 1.34375 32 3 32H25C26.6562 32 28 30.6562 28 29V28.4C28 23.8875 24.4312 20.2313 19.9625 20.0375ZM12.5 29H3V28.4C3 25.875 4.74375 23.75 7.125 23.1812L12.5 27.6562V29ZM25 29H15.5V27.6562L20.875 23.1812C23.2563 23.75 25 25.875 25 28.4V29Z"
        fill="white"
      />
    </svg>
  );
};

export const InstituteIcon = ({ rem, size }: IconProps) => {
  return (
    <svg width={rem(3.2)} height={rem(size ? size : 3.1)} viewBox="0 0 32 31" fill="none">
      <path
        d="M30.4 27.7333H29.8667V24C29.8667 23.1163 29.1503 22.4 28.2667 22.4H27.2V12.2667H24V22.4H20.8V12.2667H17.6V22.4H14.4V12.2667H11.2V22.4H8V12.2667H4.8V22.4H3.73333C2.84967 22.4 2.13333 23.1163 2.13333 24V27.7333H1.6C0.716333 27.7333 2.53232e-09 28.4497 2.53232e-09 29.3333V30.4C2.53232e-09 30.5414 0.0561903 30.6771 0.15621 30.7771C0.256229 30.8771 0.391885 30.9333 0.533333 30.9333H31.4667C31.6081 30.9333 31.7438 30.8771 31.8438 30.7771C31.9438 30.6771 32 30.5414 32 30.4V29.3333C32 28.4497 31.2837 27.7333 30.4 27.7333ZM26.6667 27.7333H5.33333V25.6H26.6667V27.7333ZM31.4845 6.20387L17.0119 0.164201C16.3551 -0.0547335 15.6449 -0.0547335 14.9881 0.164201L0.515533 6.20387C0.363925 6.26154 0.233431 6.36393 0.141358 6.49747C0.0492861 6.63102 -1.29008e-05 6.78939 2.53232e-09 6.9516V8.8C2.53232e-09 9.2418 0.3582 9.6 0.8 9.6H2.13333V10.4C2.13333 10.8418 2.49153 11.2 2.93333 11.2H29.0667C29.5085 11.2 29.8667 10.8418 29.8667 10.4V9.6H31.2C31.6418 9.6 32 9.2418 32 8.8V6.9516C32 6.61947 31.7949 6.32193 31.4845 6.20387ZM3.2 8L16 3.2L28.8 8H3.2Z"
        fill="white"
      />
    </svg>
  );
};
export const StudentClassIcon = ({ rem, size }: IconProps) => {
  return (
    <svg width={rem(3.2)} height={rem(size ? size : 2.6)} viewBox="0 0 32 26" fill="none">
      <path
        d="M11.3395 17.101C9.95 17.101 9.601 17.6 8 17.6C6.4015 17.6 6.0525 17.101 4.6605 17.101C1.056 17.101 0 20.15 0 21.7335V23.6C0 24.7045 0.8955 25.6 2 25.6H14C15.1045 25.6 16 24.7045 16 23.6V21.7335C16 19.5975 14.471 17.101 11.3395 17.101ZM13.6 23.2H2.4V21.7335C2.4 21.033 2.8075 19.501 4.6605 19.501C5.5225 19.501 6.1385 20 8 20C9.8685 20 10.4745 19.501 11.3395 19.501C13.1905 19.501 13.6 21.03 13.6 21.7335V23.2ZM8 16C10.651 16 12.8 13.851 12.8 11.2C12.8 8.549 10.651 6.4 8 6.4C5.349 6.4 3.2 8.549 3.2 11.2C3.2 13.851 5.349 16 8 16ZM8 8.8C9.3235 8.8 10.4 9.8765 10.4 11.2C10.4 12.5235 9.3235 13.6 8 13.6C6.6765 13.6 5.6 12.5235 5.6 11.2C5.6 9.8765 6.6765 8.8 8 8.8ZM29.6 0H10.4C9.0765 0 8 1.1125 8 2.4795V4.8C8.4845 4.8 9.6135 4.9565 10.4 5.276V2.4H29.6V18.4H27.2V16C27.2 15.1165 26.4835 14.4 25.6 14.4H19.2C18.3165 14.4 17.6 15.1165 17.6 16V20.8H29.6C30.9235 20.8 32 19.6875 32 18.3205V2.4795C32 1.1125 30.9235 0 29.6 0ZM24.8 18.4H20V16.8H24.8V18.4Z"
        fill="white"
      />
    </svg>
  );
};
export const HourglassIcon = ({ rem, size }: IconProps) => {
  return (
    <svg width={rem(2.4)} height={rem(size ? size : 3.2)} viewBox="0 0 24 32" fill="none">
      <path
        d="M23 2H23.25C23.6642 2 24 1.66419 24 1.25V0.75C24 0.335813 23.6642 0 23.25 0H0.75C0.335813 0 0 0.335813 0 0.75V1.25C0 1.66419 0.335813 2 0.75 2H1C1 7.73881 3.75675 14.1036 9.10287 16C3.7395 17.9026 1 24.2798 1 30H0.75C0.335813 30 0 30.3358 0 30.75V31.25C0 31.6642 0.335813 32 0.75 32H23.25C23.6642 32 24 31.6642 24 31.25V30.75C24 30.3358 23.6642 30 23.25 30H23C23 24.2612 20.2432 17.8964 14.8971 16C20.2605 14.0974 23 7.72019 23 2ZM3 2H21C21 8.90356 16.9706 14.5 12 14.5C7.02944 14.5 3 8.90356 3 2ZM21 30H3C3 23.0964 7.02944 17.5 12 17.5C16.9706 17.5 21 23.0964 21 30ZM17.8513 6H6.14869C5.98342 6.00001 5.82278 5.94548 5.69167 5.84487C5.56056 5.74427 5.46632 5.6032 5.42356 5.44356C5.37685 5.26889 5.33395 5.09323 5.29488 4.91669C5.19088 4.44656 5.54487 4 6.02637 4H17.9737C18.4552 4 18.8092 4.44656 18.7052 4.91669C18.6661 5.09323 18.6232 5.26889 18.5765 5.44356C18.5337 5.60319 18.4395 5.74425 18.3083 5.84485C18.1772 5.94546 18.0166 5.99999 17.8513 6ZM16.8538 9.182C16.7198 9.3695 16.5786 9.55171 16.4304 9.72819C16.2866 9.89931 16.0759 9.99994 15.8524 9.99994H8.14762C7.92413 9.99994 7.71344 9.89931 7.56962 9.72819C7.42148 9.55168 7.28025 9.36948 7.14625 9.182C6.79269 8.68762 7.15175 8 7.75956 8H16.2404C16.8482 8 17.2073 8.68769 16.8538 9.182Z"
        fill="white"
      />
    </svg>
  );
};
export const CourseIcon = ({ rem }: IconProps) => {
  return (
    <svg
      width={rem(2.4)}
      height={rem(3.0)}
      viewBox="0 0 32 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.70588 0C2.09955 0 0 2.09955 0 4.70588V33.6652C0 36.2715 2.09955 38.371 4.70588 38.371H16.4706C17.086 38.371 17.5566 37.9005 17.5566 37.2851C17.5566 36.6697 17.086 36.1991 16.4706 36.1991H4.70588C3.29412 36.1991 2.17195 35.0769 2.17195 33.6652V4.70588C2.17195 3.29412 3.29412 2.17195 4.70588 2.17195H26.7873C28.1991 2.17195 29.3213 3.29412 29.3213 4.70588V33.6652C29.3213 35.0769 28.1991 36.1991 26.7873 36.1991H25.7014C25.086 36.1991 24.6154 36.6697 24.6154 37.2851C24.6154 37.9005 25.086 38.371 25.7014 38.371H26.7873C29.3937 38.371 31.4932 36.2715 31.4932 33.6652V4.70588C31.4932 2.09955 29.3937 0 26.7873 0H4.70588ZM6.15385 7.9638C5.53846 7.9638 5.06787 8.43439 5.06787 9.04977C5.06787 9.66516 5.53846 10.1357 6.15385 10.1357H24.6154C25.2308 10.1357 25.7014 9.66516 25.7014 9.04977C25.7014 8.43439 25.2308 7.9638 24.6154 7.9638H6.15385ZM6.15385 13.3937C5.53846 13.3937 5.06787 13.8643 5.06787 14.4796C5.06787 15.095 5.53846 15.5656 6.15385 15.5656H24.6154C25.2308 15.5656 25.7014 15.095 25.7014 14.4796C25.7014 13.8643 25.2308 13.3937 24.6154 13.3937H6.15385ZM6.15385 18.8235C5.53846 18.8235 5.06787 19.2941 5.06787 19.9095C5.06787 20.5249 5.53846 20.9955 6.15385 20.9955H14.6606C15.276 20.9955 15.7466 20.5249 15.7466 19.9095C15.7466 19.2941 15.276 18.8235 14.6606 18.8235H6.15385ZM21.1404 27.3303C18.5703 27.3303 16.4706 29.5025 16.4706 32.1451C16.4706 33.8464 17.3394 35.3664 18.6425 36.2352V40L21.1765 38.9501L23.6383 40V36.2352C24.9415 35.3664 25.8102 33.8826 25.8102 32.1451C25.8102 29.5025 23.7105 27.3303 21.1404 27.3303ZM21.1765 29.5023C22.552 29.5023 23.6744 30.6971 23.6744 32.1451C23.6744 33.593 22.552 34.7872 21.1765 34.7872C19.8009 34.7872 18.6786 33.593 18.6786 32.1451C18.6786 30.6971 19.8009 29.5023 21.1765 29.5023Z"
        fill="white"
      />
    </svg>
  );
};
export const StudentsIcon = ({ rem }: IconProps) => {
  return (
    <svg
      width={rem(3.0)}
      height={rem(4.0)}
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 3H35V11.3512C36.1025 11.5112 37.1256 11.9081 38 12.5206V3.09937C38 1.39062 36.6544 0 35 0H5C3.34562 0 2 1.39062 2 3.09937V12.52C2.87437 11.9075 3.8975 11.5106 5 11.3506V3ZM6.75 25.25H5.25C2.35562 25.25 0 27.6056 0 30.5C0 31.3281 0.671875 32 1.5 32C2.32812 32 3 31.3281 3 30.5C3 29.26 4.01 28.25 5.25 28.25H6.75C7.99 28.25 9 29.26 9 30.5C9 31.3281 9.67188 32 10.5 32C11.3281 32 12 31.3281 12 30.5C12 27.6056 9.64437 25.25 6.75 25.25ZM34 13.25C31.2388 13.25 29 15.4887 29 18.25C29 21.0112 31.2388 23.25 34 23.25C36.7612 23.25 39 21.0112 39 18.25C39 15.4887 36.7612 13.25 34 13.25ZM34 20.25C32.8975 20.25 32 19.3525 32 18.25C32 17.1475 32.8975 16.25 34 16.25C35.1025 16.25 36 17.1475 36 18.25C36 19.3525 35.1025 20.25 34 20.25ZM34.75 25.25H33.25C30.3556 25.25 28 27.6056 28 30.5C28 31.3281 28.6719 32 29.5 32C30.3281 32 31 31.3281 31 30.5C31 29.26 32.01 28.25 33.25 28.25H34.75C35.99 28.25 37 29.26 37 30.5C37 31.3281 37.6719 32 38.5 32C39.3281 32 40 31.3281 40 30.5C40 27.6056 37.6444 25.25 34.75 25.25ZM6 23.25C8.76125 23.25 11 21.0112 11 18.25C11 15.4887 8.76125 13.25 6 13.25C3.23875 13.25 1 15.4887 1 18.25C1 21.0112 3.23875 23.25 6 23.25ZM6 16.25C7.1025 16.25 8 17.1475 8 18.25C8 19.3525 7.1025 20.25 6 20.25C4.8975 20.25 4 19.3525 4 18.25C4 17.1475 4.8975 16.25 6 16.25ZM15 18.25C15 21.0112 17.2388 23.25 20 23.25C22.7612 23.25 25 21.0112 25 18.25C25 15.4887 22.7612 13.25 20 13.25C17.2388 13.25 15 15.4887 15 18.25ZM22 18.25C22 19.3525 21.1025 20.25 20 20.25C18.8975 20.25 18 19.3525 18 18.25C18 17.1475 18.8975 16.25 20 16.25C21.1025 16.25 22 17.1475 22 18.25ZM20.75 25.25H19.25C16.3556 25.25 14 27.6056 14 30.5C14 31.3281 14.6719 32 15.5 32C16.3281 32 17 31.3281 17 30.5C17 29.26 18.01 28.25 19.25 28.25H20.75C21.99 28.25 23 29.26 23 30.5C23 31.3281 23.6719 32 24.5 32C25.3281 32 26 31.3281 26 30.5C26 27.6056 23.6444 25.25 20.75 25.25Z"
        fill="white"
      />
    </svg>
  );
};
export const BooksIcon = ({ rem }: IconProps) => {
  return (
    <svg
      width={rem(3.0)}
      height={rem(3.0)}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.47823 0C1.56586 0 -3.2627e-05 1.5659 -3.2627e-05 3.47826L-0.00012207 25.2V25.2174C-0.00012207 27.1298 1.56586 28.6957 3.47823 28.6957H15.6623L15.6657 36.5V36.5217C15.6657 38.4341 17.218 40 19.1304 40H40V38.2609H19.1304C18.1589 38.2609 17.3913 37.4932 17.3913 36.5217C17.3913 35.5503 18.1589 34.7826 19.1304 34.7826H40V11.3043H24.3478V0H3.47823ZM3.47823 1.73913H22.6087V11.3043H19.1304C17.218 11.3043 15.6521 12.8702 15.6521 14.7826L15.6555 21.7391H3.47823C2.84983 21.7391 2.26559 21.9226 1.75269 22.2181L1.7391 3.47826C1.7391 2.50679 2.50676 1.73913 3.47823 1.73913ZM6.08693 3.47826C5.13584 3.47826 4.34779 4.2663 4.34779 5.21739V6.95652C4.34779 7.90761 5.13584 8.69565 6.08693 8.69565H18.2608C19.2119 8.69565 20 7.90761 20 6.95652V5.21739C20 4.2663 19.2119 3.47826 18.2608 3.47826H6.08693ZM6.08693 5.21739H18.2608V6.95652H6.08693V5.21739ZM19.1304 13.0435H38.2608V33.0435H19.1304C18.4918 33.0435 17.9212 33.2677 17.4049 33.5734L17.3913 14.7826C17.3913 13.8111 18.1589 13.0435 19.1304 13.0435ZM21.7391 14.7826C20.788 14.7826 20 15.5707 20 16.5217V18.2609C20 19.212 20.788 20 21.7391 20H33.913C34.8641 20 35.6521 19.212 35.6521 18.2609V16.5217C35.6521 15.5707 34.8641 14.7826 33.913 14.7826H21.7391ZM21.7391 16.5217H33.913V18.2609H21.7391V16.5217ZM3.47823 23.4783H15.6589L15.6623 26.9565H3.47823C2.50676 26.9565 1.7391 26.1889 1.7391 25.2174C1.7391 24.2459 2.50676 23.4783 3.47823 23.4783Z"
        fill="white"
      />
    </svg>
  );
};
export const GroupIcon = ({ rem }: IconProps) => {
  return (
    <svg
      width={rem(3)}
      height={rem(3)}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7391 16C10.7829 16 10 16.7581 10 17.684L10.0068 28.076H10.0136C10.0102 28.1204 10 28.1632 10 28.2092C10 29.1351 10.7829 29.8932 11.7391 29.8932H17.8312L17.8329 33.5491H17.8533C17.8499 33.5951 17.8261 33.6346 17.8261 33.6823C17.8261 34.6082 18.609 35.3663 19.5652 35.3663H30V34.5243H19.5652C19.0795 34.5243 18.6957 34.1526 18.6957 33.6823C18.6957 33.212 19.0795 32.8403 19.5652 32.8403H30V21.4731H22.1739V16H11.7391ZM11.7391 16.842H21.3043V21.4731H19.5652C18.609 21.4731 17.8261 22.2312 17.8261 23.1571L17.8278 26.5252H11.7391C11.4249 26.5252 11.1328 26.614 10.8764 26.7571L10.8696 17.684C10.8696 17.2137 11.2534 16.842 11.7391 16.842ZM13.0435 17.684C12.5679 17.684 12.1739 18.0656 12.1739 18.526V19.3681C12.1739 19.8285 12.5679 20.2101 13.0435 20.2101H19.1304C19.606 20.2101 20 19.8285 20 19.3681V18.526C20 18.0656 19.606 17.684 19.1304 17.684H13.0435ZM13.0435 18.526H19.1304V19.3681H13.0435V18.526ZM19.5652 22.3151H29.1304V31.9983H19.5652C19.2459 31.9983 18.9606 32.1068 18.7024 32.2548L18.6957 23.1571C18.6957 22.6868 19.0795 22.3151 19.5652 22.3151ZM20.8696 23.1571C20.394 23.1571 20 23.5387 20 23.9991V24.8412C20 25.3016 20.394 25.6832 20.8696 25.6832H26.9565C27.4321 25.6832 27.8261 25.3016 27.8261 24.8412V23.9991C27.8261 23.5387 27.4321 23.1571 26.9565 23.1571H20.8696ZM20.8696 23.9991H26.9565V24.8412H20.8696V23.9991ZM11.7391 27.3672H17.8295L17.8312 29.0512H11.7391C11.2534 29.0512 10.8696 28.6796 10.8696 28.2092C10.8696 27.7389 11.2534 27.3672 11.7391 27.3672Z"
        fill="white"
      />
      <path
        d="M8 1V4.87327H4C1.8 4.87327 0 6.61624 0 8.74653V35.8594C0 37.9897 1.8 39.7327 4 39.7327H36C38.2 39.7327 40 37.9897 40 35.8594V8.74653C40 6.61624 38.2 4.87327 36 4.87327H32V1H28V4.87327H12V1H8ZM2 8.8099C2 7.70533 2.89543 6.8099 4 6.8099H8H12H28H32H36C37.1046 6.8099 38 7.70533 38 8.8099V12.6198H2V8.8099ZM2 14.5564H38V35.796C38 36.9006 37.1046 37.796 36 37.796H4C2.89543 37.796 2 36.9006 2 35.796V14.5564Z"
        fill="white"
      />
    </svg>
  );
};
export const CourseListIcon = ({ rem, style }: IconProps) => {
  return (
    <svg
      style={style}
      width={rem(1.6)}
      height={rem(1.6)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5572 3.06176L12.9357 0.440608C12.6545 0.159369 12.2733 0 11.8767 0H5.50009C4.67231 0.00312488 4.00046 0.674974 4.00046 1.50276V3.99985H5.50009V1.50276H10.499V4.75201C10.499 5.16761 10.8333 5.50166 11.2489 5.50166H14.5001V14.4998H7.00034V15.9994H14.4979C15.326 15.9994 16 15.3275 16 14.4998V4.1239C16 3.72736 15.8384 3.343 15.5572 3.06176ZM11.9986 4.00203V1.62463L14.3763 4.00203H11.9986ZM7.73219 10.5708C7.96343 10.3355 8.05374 9.99274 7.96906 9.67119C7.79938 9.0259 7.79906 9.11684 7.96906 8.47061C8.05374 8.14906 7.96343 7.80626 7.73219 7.57096C7.26783 7.09848 7.31252 7.17691 7.14253 6.53131C7.05784 6.20976 6.81129 5.95883 6.49536 5.8729C5.86164 5.70009 5.93788 5.74478 5.47384 5.27261C5.2426 5.03731 4.90574 4.94544 4.58981 5.03168C3.95484 5.2048 4.04421 5.20449 3.41017 5.03168C3.09456 4.94544 2.75769 5.03762 2.52645 5.27261C2.06053 5.74697 2.13584 5.70072 1.50493 5.8729C1.189 5.95915 0.942451 6.21007 0.857767 6.53131C0.687149 7.17941 0.732772 7.09785 0.268102 7.57096C0.0368611 7.80626 -0.053448 8.14906 0.0312363 8.47061C0.201542 9.11778 0.200605 9.02653 0.0312363 9.67119C-0.053448 9.99274 0.0368611 10.3355 0.268102 10.5708C0.732147 11.043 0.687774 10.9649 0.857767 11.6105C0.942451 11.932 1.189 12.183 1.50493 12.2689C1.9521 12.3908 1.86492 12.3617 1.99991 12.4251V15.9994L3.99983 14.9994L5.99976 15.9994V12.4251C6.13444 12.362 6.04726 12.3908 6.49474 12.2689C6.81066 12.1827 7.05722 11.9317 7.1419 11.6105C7.31314 10.9621 7.26752 11.0436 7.73219 10.5708ZM4.00046 10.9996C2.89613 10.9996 2.00054 10.1043 2.00054 8.99966C2.00054 7.89501 2.89613 6.99973 4.00046 6.99973C5.10479 6.99973 6.00038 7.89501 6.00038 8.99966C6.00038 10.1043 5.10479 10.9996 4.00046 10.9996Z" />
    </svg>
  );
};
export const WorkIcon = ({ rem, style }: IconProps) => {
  return (
    <svg
      style={style}
      width={rem(1.6)}
      height={rem(1.6)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.75 6H16.5V2.625C16.5 2.00344 15.9966 1.5 15.375 1.5H8.625C8.00344 1.5 7.5 2.00344 7.5 2.625V6H2.25C1.00734 6 0 7.00734 0 8.25V20.25C0 21.4927 1.00734 22.5 2.25 22.5H21.75C22.9927 22.5 24 21.4927 24 20.25V8.25C24 7.00734 22.9927 6 21.75 6ZM9 3H15V6H9V3ZM22.5 20.25C22.5 20.6634 22.1634 21 21.75 21H2.25C1.83656 21 1.5 20.6634 1.5 20.25V13.5H9V15.375C9 15.9961 9.50391 16.5 10.125 16.5H13.875C14.4961 16.5 15 15.9961 15 15.375V13.5H22.5V20.25ZM10.5 15V13.5H13.5V15H10.5ZM22.5 12H1.5V8.25C1.5 7.83656 1.83656 7.5 2.25 7.5H21.75C22.1634 7.5 22.5 7.83656 22.5 8.25V12Z"
        fill="black"
        fill-opacity="0.6"
      />
    </svg>
  );
};
export const EditActionIcon = ({ rem }: IconProps) => {
  return (
    <svg
      width={rem(2)}
      height={rem(1.9)}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5721 10.7807L13.5722 9.78068C13.7284 9.62443 14.0003 9.73381 14.0003 9.95881V14.5027C14.0003 15.3308 13.3284 16.0027 12.5003 16.0027H1.50003C0.671889 16.0027 0 15.3308 0 14.5027V3.50242C0 2.67428 0.671889 2.00239 1.50003 2.00239H10.0471C10.269 2.00239 10.3815 2.27114 10.2252 2.43052L9.2252 3.43054C9.17832 3.47742 9.11582 3.50242 9.04707 3.50242H1.50003V14.5027H12.5003V10.9557C12.5003 10.8901 12.5253 10.8276 12.5721 10.7807ZM17.466 4.47432L9.25957 12.6807L6.43451 12.9933C5.61575 13.0839 4.91886 12.3932 5.00948 11.5682L5.32199 8.74316L13.5284 0.53673C14.2441 -0.17891 15.4003 -0.17891 16.1128 0.53673L17.4629 1.88676C18.1785 2.6024 18.1785 3.7618 17.466 4.47432V4.47432ZM14.3784 5.43996L12.5628 3.6243L6.7564 9.4338L6.52827 11.4745L8.56894 11.2463L14.3784 5.43996ZM16.4035 2.94928L15.0535 1.59925C14.9253 1.47113 14.7159 1.47113 14.5909 1.59925L13.6253 2.5649L15.441 4.38056L16.4066 3.41492C16.5316 3.28366 16.5316 3.07741 16.4035 2.94928V2.94928Z"
        fill="black"
        fill-opacity="0.6"
      />
    </svg>
  );
};
export const DeleteActionIcon = ({ rem }: IconProps) => {
  return (
    <svg
      width={rem(1.6)}
      height={rem(1.8)}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.375 13H9.125C9.22446 13 9.31984 12.9605 9.39017 12.8902C9.46049 12.8198 9.5 12.7245 9.5 12.625V5.875C9.5 5.77554 9.46049 5.68016 9.39017 5.60984C9.31984 5.53951 9.22446 5.5 9.125 5.5H8.375C8.27554 5.5 8.18016 5.53951 8.10983 5.60984C8.03951 5.68016 8 5.77554 8 5.875V12.625C8 12.7245 8.03951 12.8198 8.10983 12.8902C8.18016 12.9605 8.27554 13 8.375 13ZM13.5 2.5H10.9247L9.86219 0.728125C9.72885 0.505942 9.54022 0.322091 9.3147 0.194487C9.08917 0.066882 8.83444 -0.000123231 8.57531 1.70139e-07H5.42469C5.16567 -1.5274e-05 4.91106 0.0670412 4.68566 0.194641C4.46025 0.32224 4.27172 0.506033 4.13844 0.728125L3.07531 2.5H0.5C0.367392 2.5 0.240215 2.55268 0.146447 2.64645C0.0526784 2.74022 0 2.86739 0 3L0 3.5C0 3.63261 0.0526784 3.75979 0.146447 3.85355C0.240215 3.94732 0.367392 4 0.5 4H1V14.5C1 14.8978 1.15804 15.2794 1.43934 15.5607C1.72064 15.842 2.10218 16 2.5 16H11.5C11.8978 16 12.2794 15.842 12.5607 15.5607C12.842 15.2794 13 14.8978 13 14.5V4H13.5C13.6326 4 13.7598 3.94732 13.8536 3.85355C13.9473 3.75979 14 3.63261 14 3.5V3C14 2.86739 13.9473 2.74022 13.8536 2.64645C13.7598 2.55268 13.6326 2.5 13.5 2.5ZM5.37 1.59094C5.38671 1.56312 5.41035 1.54012 5.43862 1.52418C5.46688 1.50824 5.4988 1.49991 5.53125 1.5H8.46875C8.50115 1.49996 8.533 1.50832 8.5612 1.52426C8.58941 1.54019 8.613 1.56317 8.62969 1.59094L9.17531 2.5H4.82469L5.37 1.59094ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.72446 13 5.81984 12.9605 5.89016 12.8902C5.96049 12.8198 6 12.7245 6 12.625V5.875C6 5.77554 5.96049 5.68016 5.89016 5.60984C5.81984 5.53951 5.72446 5.5 5.625 5.5H4.875C4.77554 5.5 4.68016 5.53951 4.60984 5.60984C4.53951 5.68016 4.5 5.77554 4.5 5.875V12.625C4.5 12.7245 4.53951 12.8198 4.60984 12.8902C4.68016 12.9605 4.77554 13 4.875 13Z"
        fill="black"
        fill-opacity="0.6"
      />
    </svg>
  );
};
