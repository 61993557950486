import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import {
  getUsersQuery,
  useInviteEmployee,
  useInviteEdbaAdmin,
  getEdbaUsersQuery,
  useDeleteUser,
} from '../../../graphql/control-panels/user';
import {
  limit,
  AUTOCOMPLETE_MODULE,
  SEARCH_TIME,
  SUCCESS,
  ERROR,
  USER_TYPE,
  EMPLOYEE_TYPES,
} from 'src/constant/index';
import { useHistory, useParams } from 'react-router';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import InviteUserForm from 'src/components/molecules/ControlPanel/InviteUserForm';
import { useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, debounce } from 'src/components/services';
import { getUserFilter } from 'src/components/services/filters';
import { ADD_SUCCESS } from 'src/constant/message';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import styled from 'styled-components/native';
import { Tooltip } from '@mui/material';
import { properCase, trimObj } from 'src/utils/utility';
import { height } from 'src/constant/device';

const headCells = [
  {
    id: 'user_name',
    label: 'userName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'email_id',
    label: 'email.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'full_name',
    label: 'fullName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: 'actions',
    align: 'right',
    disableSorting: true,
  },
];

const headCellsEdbaAdmin = [
  {
    id: 'user_name',
    label: 'userName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'email_id',
    label: 'email.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'full_name',
    label: 'fullName.label',
    align: 'left',
    disableSorting: true,
  },
];

const maxHeight = 440;

interface adminFormData {
  email: string;
  firstName: string;
  lastName: string;
}

export interface InstituteAdminFormData {
  email: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
}

interface userFilter {
  limit: number;
  skip: number;
  filter: object[];
}

export default function User() {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    clearErrors,
  } = useForm();
  let row;
  
  let searchValue = '' as string;
  const { page = 1, dataLimit = limit }: any = useParams();

  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  
  let history = useHistory();
  const { filters, setFilter } = useFilter();

  const [inviteCounter, setInviteCounter] = useState(0);
  let totalCount = 0;
  const { setHeading } = useHeaderTitle();
  const currentUserObject = userInformation();
  const { userType } = currentUserObject;
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [deleteOverlay, setDeleteOverlay] = useState({ show: false, userId: '' });
  const [modalState, handleModal] = useState<boolean>(false);
  const { setAlertDetails } = useAlertSystem();
  if (filters && filters?.user?.on) {
    searchValue = filters?.user?.search;
  }
  //mutation
  const { inviteInstituteEmployee } = useInviteEmployee();
  const { inviteEdbaAdmin } = useInviteEdbaAdmin();
  const { deleteUser } = useDeleteUser();

  const [searching, setSearchingUser] = useState(searchValue);
  const [getUsers, { loading, data: userData }] = useLazyQuery(
    userType === USER_TYPE.EDBA_ADMIN ? getEdbaUsersQuery : getUsersQuery,
    {
      fetchPolicy: 'network-only',
    },
  ); //same query for both

  if (userType === USER_TYPE.EDBA_ADMIN) {
    row = userData?.Users?.data;
    totalCount = userData?.Users?.totalCount;
  } else {
    row = userData?.instituteEmployees?.data;
    totalCount = userData?.instituteEmployees?.totalCount;
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    userType === USER_TYPE.EDBA_ADMIN ? headCellsEdbaAdmin : headCells,
    maxHeight,
    totalCount,
    currentPage,
  );

  useEffect(() => {
    setSearchingUser(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setTitle();
  }, []);

  function setTitle() {
    setHeading([
      {
        text: t('users.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, inviteCounter, userType, rowLimit]);

  function handleFetch() {
    let adminFilter = {
      in: [EMPLOYEE_TYPES.INSTITUTE_ADMIN],
    };
    let filters = getUserFilter(searchValue);
    let userFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);

    if (userType !== USER_TYPE.EDBA_ADMIN) {
      if (userFilter.filters) {
        userFilter['filters']['type'] = adminFilter;
      } else {
        userFilter['filters'] = {};
        userFilter['filters']['type'] = adminFilter;
      }
    }

    getUsers({ variables: userFilter });
  }

  const addUserModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  function resetForm() {
    setValue('email', '');
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('employeeId', '');
    clearErrors();
  }

  const inviteUser = async (employeeData: any) => {
    let filters = getUserFilter(searchValue);
    let userFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);

    employeeData = trimObj(employeeData);

    if (userType === 1) {
      await edbaAdminInvitation(employeeData, userFilter);
    } else {
      await edbaInstituteAdminInvitation(employeeData, userFilter);
    }
    setInviteCounter(inviteCounter + 1);
  };
  async function edbaInstituteAdminInvitation(
    employeeData: InstituteAdminFormData,
    refetchFilter: userFilter,
  ) {
    let inviteUserData = {
      email: employeeData?.email,
      employeeId: employeeData?.employeeId,
      firstName: employeeData?.firstName,
      lastName: employeeData?.lastName,
      types: [EMPLOYEE_TYPES.INSTITUTE_ADMIN],
      isAdmin: true,
    };

    try {
      let inviteResponse = await inviteInstituteEmployee({
        variables: { payload: inviteUserData },
        refetchQueries: [{ query: getUsersQuery, variables: refetchFilter }],
      });
      if (inviteResponse?.data?.inviteEmployee) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.INVITE_USER, level: SUCCESS });
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
  }

  async function edbaAdminInvitation(edbaAdminData: adminFormData, refetchFilter: userFilter) {
    try {
      let inviteResponse = await inviteEdbaAdmin({
        variables: { invite: edbaAdminData },
        refetchQueries: [{ query: getEdbaUsersQuery, variables: refetchFilter }],
      });
      if (inviteResponse) {
        closeModal();
        setAlertDetails({ message: ADD_SUCCESS.INVITE_USER, level: SUCCESS });
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
  }

  const closeModal = () => {
    handleModal(false);
    resetForm();
  };

  const editUser = (userId: string) => {
    history.push(`/employee/${userId}/0/view`, { from: 'Users' });
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.USER]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchUsers(user: string) {
    let isClear = user?.length === 0;
    persistSearch(!isClear, user);
  }

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }
  const delayedQuery = useCallback(
    debounce((q) => searchUsers(q), SEARCH_TIME),
    [],
  );

  const handleSearch = (user: string) => {
    setSearchingUser(user);
    delayedQuery(user);
  };

  const handleDeleteUser = async () => {
    try {
      let resp = await deleteUser({
        variables: {
          id: deleteOverlay.userId,
        },
        refetchQueries: [{ query: getUsersQuery, variables: getUserFilter(searchValue) }],
      });
      if (resp?.data?.deleteUser?.status === 'success') {
        setAlertDetails({ message: 'User Deleted Successfully', level: SUCCESS }); 
        setDeleteOverlay({ userId: '', show: false });
      } else {
        throw new Error(resp?.data?.deleteUser?.message || t('error.deleteUser.message'));
      }
    } catch (error: any) {
      setAlertDetails({ message: error?.message, level: ERROR });

      setDeleteOverlay({ userId: '', show: false });
    }
  };

  return (
    <>
      <LoadContentWrapper>
        <View style={styles.flexRow}>
          <View style={styles.leftPart}>
            <Search
              id="inviteuserSearch"
              value={searching}
              handleChange={handleSearch}
              label={t('searchUsers.text')}
            />
          </View>

          <View style={styles.rightPart}>
            <SecondaryBtn label={t('inviteUser.text')} onPress={addUserModal} />
          </View>
        </View>

        <TblContainer height={'100%'}>
          <TblHead />
          <>
            {!loading ? (
              <>
                {recordsAfterPagingAndSorting()?.length > 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                        <TableRows key={i}>
                          <TableCells value={item?.userName} fontSize={1.5} lineHeight={2} />
                          {/* for large device getMultiple returns 10 therefore 15px will be translated to 1.5 */}
                          <TableCells value={item?.email} />
                          <TableCells value={properCase(item?.personalDetails?.fullName || '-')} />
                          {userType === USER_TYPE.EMPLOYEE ? (
                            <TableActions align="right">
                              <View style={styles.tableAction}>
                                <View style={styles.iconView}>
                                  <Tooltip title="Edit Profile">
                                    <TouchableOpacity onPress={() => editUser(item?.id)}>
                                      <FontAwesomeIcon
                                        icon={faPencil}
                                        color={theme?.table?.editIconColor}
                                      />
                                    </TouchableOpacity>
                                  </Tooltip>
                                </View>
                              </View>
                            </TableActions>
                          ) : null}
                        </TableRows>
                      ))}
                  </TableBody>
                ) : (
                  <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
                )}
              </>
            ) : (
              <SpinnerWrapper>
                <LoaderSpinner />
              </SpinnerWrapper>
            )}
          </>
        </TblContainer>

        {row && row?.length > 0 && (
          <Pagination
            pathName={'control-panel/users'}
            total={totalCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            rowLimit={rowLimit}
            setRowLimit={setRowLimit}
            hidePagination={totalCount <= rowLimit}
          />
        )}
      </LoadContentWrapper>

      <NormalModal
        setModalVisible={closeModal}
        Headerpopup={t('inviteUser.text')}
        modalVisible={modalState}
        onAlert={null}
        width={610}
        handleSave={handleSubmit(inviteUser)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'form.invite.label' : 'form.invite.label'}
        isSubmitting={isSubmitting}>
        <InviteUserForm control={control} errors={errors} onlyAdmin={true} userType={userType} />
      </NormalModal>
      <DeleteModal
        setModalVisible={() => setDeleteOverlay({ show: false, userId: '' })}
        modalVisible={deleteOverlay.show}
        handleSave={handleDeleteUser}
        Headerpopup="form.deleteUser.text">
        {t('form.deleteUser.warning')}
      </DeleteModal>
    </>
  );
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
    marginBottom: 15
  },
  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  rightPart: {
    flex: 3,
    alignItems: 'flex-end',
  },
  tableAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  iconView: { marginRight: 13 },
});

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
