import { useHistory, useParams } from "src/routes/routing.web";

import HeaderFive from "src/components/atoms/Text/HeaderFive";
import { Icon } from "src/components/atoms/Icon/Icon";
import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import { NativeEDBALogo } from "../../ResetPassword/StyledComponents";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import React from "react";
import { colors, fontWeight } from "src/styles/theme/styles";
import { isIOS, isWeb } from "src/constant/device";
import navigationStrings from "src/navigation/navigationStrings";
import styled from "styled-components/native";
import { useI18n } from "src/i18n/hooks";

type StepType = "registration_complete" | "password_set" | "password_reset_success";

interface RouteParams {
  completedStep?: StepType;
}

interface UrlParams {
  redirectionStep?: StepType;
}

interface Props {
  navigation?: any;
  route?: {
    params: RouteParams;
  };
}

const msgTexts: Record<StepType, string> = {
  registration_complete:
    "Congratulations! Your registration is complete. Please check your email to continue with the next steps.",
  password_set:
    "Your password has been set. Please login through app.",
  password_reset_success:
    "Password reset link has been sent to your email. Please check your inbox."
};

export default function RedirectionScreens(props: Props) {
  const { navigation, route } = props;
  const { t } = useI18n();
  let msgToDisplay = "";

  if (isWeb) {
    const { redirectionStep } = useParams<UrlParams>();
    msgToDisplay = redirectionStep ? msgTexts[redirectionStep] : "";
  } else if (route?.params?.completedStep) {
    msgToDisplay = msgTexts[route.params.completedStep];
  }

  function navigationFn() {
    if (route?.params?.completedStep && route.params.completedStep !== "password_reset_success") {
      navigation.navigate(navigationStrings.USER_LOGIN);
    } else if (navigation.goBack) {
      // Go back to the screen before forget password
      // navigates to either auth landing (NATIVE_AUTH) or multi account login (MULTI_ACCOUNT) based on navigation's state
      navigation.pop(2);
    }
  }

  return (
    <SafeAreaViewWrapper>
      <>
        <LogoWrapper>
          {!isWeb ? (
            <NativeEDBALogo
              source={require("src/assets/logo/new-logo-4x.png")}
            />
          ) : (
            <Icon name={"edba-logo"} size={56} />
          )}
        </LogoWrapper>
        <MessageWrapper>
          {isWeb ? (
            <HeaderFive value={msgToDisplay} color={colors.primaryText} />
          ) : (
            <MediumTextSelect
              value={msgToDisplay}
              textAlign={"center"}
              lineHeight={2}
              color={colors.primaryText}
            />
          )}
        </MessageWrapper>
      </>
      {!isWeb && (
        <BackToSignInNative onPress={navigationFn}>
          <NormaltextAtom
            value={t("back-to-sign-in.text")}
            color={colors.primary}
            fontWeight={fontWeight[isIOS ? "600" : "bold"]}
          />
        </BackToSignInNative>
      )}
    </SafeAreaViewWrapper>
  );
}

const SafeAreaViewWrapper = styled.SafeAreaView`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: ${colors.white};
`;

const LogoWrapper = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${isWeb ? "16px" : "24px"};
`;

const MessageWrapper = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 80%;
  text-align: center;
`;

const BackToSignInNative = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  align-self: center;
  position: absolute;
  bottom: 8%;
`;
