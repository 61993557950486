import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function InstituteIcon(props: any) {
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={16 * scale}
      height={16 * scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M15.5572 3.06176L12.9357 0.440608C12.6545 0.159369 12.2733 0 11.8767 0H5.50009C4.67231 0.00312488 4.00046 0.674974 4.00046 1.50276V3.99985H5.50009V1.50276H10.499V4.75201C10.499 5.16761 10.8333 5.50166 11.2489 5.50166H14.5001V14.4998H7.00034V15.9994H14.4979C15.326 15.9994 16 15.3275 16 14.4998V4.1239C16 3.72736 15.8384 3.343 15.5572 3.06176ZM11.9986 4.00203V1.62463L14.3763 4.00203H11.9986ZM7.73219 10.5708C7.96343 10.3355 8.05374 9.99274 7.96906 9.67119C7.79938 9.0259 7.79906 9.11684 7.96906 8.47061C8.05374 8.14906 7.96343 7.80626 7.73219 7.57096C7.26783 7.09848 7.31252 7.17691 7.14253 6.53131C7.05784 6.20976 6.81129 5.95883 6.49536 5.8729C5.86164 5.70009 5.93788 5.74478 5.47384 5.27261C5.2426 5.03731 4.90574 4.94544 4.58981 5.03168C3.95484 5.2048 4.04421 5.20449 3.41017 5.03168C3.09456 4.94544 2.75769 5.03762 2.52645 5.27261C2.06053 5.74697 2.13584 5.70072 1.50493 5.8729C1.189 5.95915 0.942451 6.21007 0.857767 6.53131C0.687149 7.17941 0.732772 7.09785 0.268102 7.57096C0.0368611 7.80626 -0.053448 8.14906 0.0312363 8.47061C0.201542 9.11778 0.200605 9.02653 0.0312363 9.67119C-0.053448 9.99274 0.0368611 10.3355 0.268102 10.5708C0.732147 11.043 0.687774 10.9649 0.857767 11.6105C0.942451 11.932 1.189 12.183 1.50493 12.2689C1.9521 12.3908 1.86492 12.3617 1.99991 12.4251V15.9994L3.99983 14.9994L5.99976 15.9994V12.4251C6.13444 12.362 6.04726 12.3908 6.49474 12.2689C6.81066 12.1827 7.05722 11.9317 7.1419 11.6105C7.31314 10.9621 7.26752 11.0436 7.73219 10.5708ZM4.00046 10.9996C2.89613 10.9996 2.00054 10.1043 2.00054 8.99966C2.00054 7.89501 2.89613 6.99973 4.00046 6.99973C5.10479 6.99973 6.00038 7.89501 6.00038 8.99966C6.00038 10.1043 5.10479 10.9996 4.00046 10.9996Z"
        fill={props.color}
        fillOpacity={0.6}
      />
    </Svg>
  );
}

export default InstituteIcon;
