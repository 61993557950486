import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { colors, fonts, mobileWeb } from '../../../../styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import StudentAttendanceChild from 'src/components/molecules/Attendance/History/StudentHistory';
import { useQuery } from '@apollo/client';
import {
  dayWiseAttendence,
  getStudentInfo,
  slotsWiseUserAttendance,
  attendanceSlots,
} from 'src/graphql/attendance';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { userInformation } from 'src/utils/manageState';
import { useHeaderTitle } from 'src/contexts/header-context';
import { graphqlQuery } from 'src/graphql/util';
import AttendanceNotConfigured from '../AttendanceNotConfigured';
import { useThemeSystem } from 'src/contexts/theme-context';
import { withRem } from 'src/utils/useRem';
import { height, isWeb } from 'src/constant/device';
import MediumText from 'src/components/atoms/Text/MediumText';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { DT } from 'src/constant/dateTime';
import DateTimePicker from 'src/components/atoms/DateTime/DateTimePicker.web';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { AttendanceData, SelectedDatesState, SlotInfo, SlotObj } from 'src/components/molecules/Attendance/AttendanceTypes';

const StudentAttendance = withRem(() => {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const { setHeading } = useHeaderTitle();
  const timezone = DT.TIMEZONE;

  const { rem }: any = useTheme();
  const currentUserObject = userInformation();
  const { userId }: any = currentUserObject;

  const todaysDate = format(new Date(), DT.DATE_FORMAT_SLASH);
  const attendanceType = [
    { value: 0, label: t('fullDay.label') },
    { value: 1, label: t('slots.label') },
    { value: 2, label: t('subjects.label') },
  ];

  const [dates, setDates] = useState<SelectedDatesState>({
    startDate: format(startOfMonth(new Date()), DT.DATE_FORMAT_SLASH),
    endDate: format(endOfMonth(new Date()), DT.DATE_FORMAT_SLASH),
    currentDate: todaysDate,
  });

  const [dateString, setdateString] = useState(format(new Date(), DT.MONTH_NAME_YEAR));

  const [calendarValue, setCalendarValue] = useState<Date>(new Date());

  const [isAttendanceSettingConfigured, setisAttendanceSettingConfigured] = useState<boolean>(true);

  const [studentInfo, setStudentInfo] = useState<{
    classId: string;
    batch: any;
    className: string;
    division: string;
    firstName: string;
    lastName: string;
  } | null>(null);

  const [listData, setListData] = useState<AttendanceData[] | null>(null);
  interface selectedAttendence {
    value: number;
    label: string;
  }

  const [selectedAttendenceType, setSelectedAttendenceType] = useState<selectedAttendence | null>(null);

  const [selectedSlot, setSelectedSlot] = useState<SlotObj | null>(null);

  const [commonDetails, setcommonDetails] = useState({
    batch: '',
    class: '',
    division: '',
    start: '',
    end: '',
    user: '',
    timezone,
  });

  const [currentMonthAttendanceDataList, setCurrentMonthAttendanceDataList] = useState<null | []>(null);
  const [totalPresents, setTotalPresents] = useState<number>(0);
  const [totalAttendance, setTotalAttendance] = useState<number>(0);

  const { data: slotsData, loading: slotsDataloading } = useQuery(attendanceSlots, {
    fetchPolicy: 'network-only',
  });

  async function getDayWiseAttendaceData() {
    if (
      selectedSlot &&
      selectedAttendenceType &&
      commonDetails?.class &&
      commonDetails?.division &&
      commonDetails?.user
    ) {
      const payload = attendanceTypeHandler()
        ? {
          ...commonDetails,
          slot: selectedSlot?.slotId,
          timezone,
        }
        : {
          ...commonDetails,
          timezone,
        };
      const { loading, data: currentMonthAttendanceData } = await graphqlQuery(
        dayWiseAttendence,
        { payload },
        true,
      );
      if (!loading && currentMonthAttendanceData?.dayWiseAttendance?.length > 0) {
        setCurrentMonthAttendanceDataList(currentMonthAttendanceData?.dayWiseAttendance);
      } else {
        setCurrentMonthAttendanceDataList([]);
      }
    }
  }

  async function getStudentsData() {
    if (userId && !studentInfo) {
      const { loading: fetchStudentsDataLoading, data: studentsData } = await graphqlQuery(
        getStudentInfo,
        {
          studentId: userId,
        },
        true,
      );
      if (!fetchStudentsDataLoading && studentsData && !studentInfo) {
        const classId = studentsData.instituteStudent.academic.class.id;
        const division = studentsData.instituteStudent.academic.division;
        const batch = studentsData.instituteStudent.academic.batch;
        setStudentInfo({
          classId,
          division,
          batch,
          className: studentsData.instituteStudent.academic.class.name,
          firstName: studentsData.instituteStudent.firstName,
          lastName: studentsData.instituteStudent.lastName,
        });
        if (!commonDetails.class) {
          setcommonDetails((prev) => ({ ...prev, class: classId, division, batch: batch.id }));
        }
      }
    }
  }

  async function getSlotWiseAttendanceData() {
    if (commonDetails?.class && commonDetails?.division && commonDetails?.user) {
      const payload = {
        batch: commonDetails?.batch,
        class: commonDetails?.class,
        division: commonDetails?.division,
        start: dates.startDate,
        end: dates.endDate,
        user: commonDetails?.user,
        timezone,
      };
      setTotalAttendance(0);
      setTotalPresents(0);
      const { loading: slotWiseAttendanceDataLoading, data: slotWiseAttendanceData } =
        await graphqlQuery(slotsWiseUserAttendance, { payload }, true);

      if (!slotWiseAttendanceDataLoading && slotWiseAttendanceData) {
        if (slotWiseAttendanceData.slotWiseForUser.length > 0) {
          const arr: AttendanceData[] = [];
          let presentCount = 0;
          let totalAttendanceCount = 0;

          slotWiseAttendanceData.slotWiseForUser[0].data.forEach((attendanceInfo: any) => {
            presentCount += attendanceInfo.present;
            totalAttendanceCount += attendanceInfo.total;
            arr.push({
              id: attendanceInfo.slot,
              heading: `${attendanceInfo.slotName}`,
              attend: attendanceInfo.present,
              total: attendanceInfo.total,
              percentage: ((attendanceInfo.present / attendanceInfo.total) * 100).toFixed(0),
            });
          });

          arr.sort((a, b) => (a.heading ?? "").localeCompare(b.heading ?? ""));

          const getFirstSlotInfo = arr[0];
          setSelectedSlot({
            value: getFirstSlotInfo.id,
            label: getFirstSlotInfo.heading,
            slotId: getFirstSlotInfo.id,
          });

          setTotalAttendance(totalAttendanceCount);
          setTotalPresents(presentCount);
          setListData(arr);
        } else {
          setListData([]);
          if (!selectedSlot) {
            setCurrentMonthAttendanceDataList([]);
          }
        }
      }
    }
  }

  function slotSelector(slotinfo: SlotInfo) {
    setSelectedSlot({
      value: slotinfo.id,
      label: slotinfo.heading,
      slotId: slotinfo.id,
    });
    setCurrentMonthAttendanceDataList(null);
  }

  function attendanceTypeHandler() {
    if (!selectedAttendenceType) {
      return false;
    } else if (selectedAttendenceType.value === 1 || selectedAttendenceType.value === 2) {
      return true;
    } else {
      return false;
    }
  }

  async function setTitle() {
    setHeading([
      {
        text: t('attendance.label'),
        url: '',
      },
    ]);
  }

  function showSlots() {
    if (!selectedAttendenceType) {
      return '';
    } else if (!selectedAttendenceType.value) {
      return t('monthlyFullDay.label');
    } else if (selectedAttendenceType.value === 1) {
      return t('monthlySlots.label');
    } else {
      return t('monthlySubjects.label');
    }
  }

  function showMiniSlots() {
    if (!selectedAttendenceType) {
      return '';
    } else if (!selectedAttendenceType.value) {
      return t('days.label');
    } else if (selectedAttendenceType.value === 1) {
      return t('slots.label');
    } else {
      return '';
    }
  }

  const dateChangeHandler = (newDate: Date) => {
    const newStartDate = format(startOfMonth(newDate), DT.DATE_FORMAT_SLASH);
    const newEndDate = format(endOfMonth(newDate), DT.DATE_FORMAT_SLASH);
    setDates((prev) => {
      if (prev.startDate !== newStartDate || prev.endDate !== newEndDate) {
        setCurrentMonthAttendanceDataList(null);
        setListData(null);
        setSelectedSlot(null);
      }

      return {
        ...prev,
        startDate: newStartDate,
        endDate: newEndDate,
        currentDate: newStartDate,
      };
    });
    setcommonDetails((prev) => ({
      ...prev,
      start: newStartDate,
      end: newEndDate,
    }));

    setdateString(format(newDate, DT.MONTH_NAME_YEAR));
  };

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    if (!slotsDataloading && slotsData) {
      if (!slotsData?.institute.settings) {
        setisAttendanceSettingConfigured(false);
      } else {
        if (slotsData?.institute.settings.attendanceType === 'DAY_WISE') {
          setSelectedAttendenceType(attendanceType[0]);
        } else if (slotsData?.institute.settings.attendanceType === 'SLOT_WISE') {
          setSelectedAttendenceType(attendanceType[1]);
        } else {
          setSelectedAttendenceType(attendanceType[2]);
        }
        setisAttendanceSettingConfigured(true);
      }
    }
  }, [slotsDataloading]);

  useEffect(() => {
    getDayWiseAttendaceData();
  }, [selectedAttendenceType, selectedSlot, commonDetails]);

  useEffect(() => {
    getStudentsData();
    if (userId && commonDetails?.user === '') {
      setcommonDetails((prev) => ({
        ...prev,
        user: userId,
        start: dates.startDate,
        end: dates.endDate,
      }));
    }
  }, [userId]);

  useEffect(() => {
    getSlotWiseAttendanceData();
  }, [commonDetails, commonDetails]);

  if (!isAttendanceSettingConfigured) {
    return (
      <LoadContentWrapper>
        <AttendanceNotConfigured type={0} />
      </LoadContentWrapper>
    );
  }

  if (!studentInfo || !selectedAttendenceType) {
    return (
      <LoadContentWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 450,
          }}>
          <LoaderSpinner />
        </div>
      </LoadContentWrapper>
    );
  }

  return (
    <MainWrapper
      style={{
        backgroundColor: theme?.content?.backgroundColor,
        minHeight: isWeb ? (mobileWeb ? height - 100 : height - 142) : height - 140,
        margin: rem(3.2),
        padding: rem([2.4, 3.2]),
      }}>
      <ContentWrapper>
        <LeftContent>
          <ProgressBarWrapper style={{ width: 120 }}>
            <CircularProgressbarWithChildren
              styles={buildStyles({
                rotation: 0,
                strokeLinecap: 'butt',
                textSize: '16px',
                pathTransitionDuration: 0.5,
                pathColor: `#2B78CA`,
                textColor: '#2B78CA',
                trailColor: '#fff',
                backgroundColor: '#3e98c7',
              })}
              value={(totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) * 100}>
              <MediumText
                value={t('present.label')}
                lineHeight={2.4}
                fontWeight={'400'}
                color={colors.primaryColor}
              />
              <HeaderThree
                value={`${(
                  (totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) *
                  100
                ).toFixed(0)}%`}
                lineHeight={32}
                fontWeight={'600'}
                color={colors.primaryColor}
              />
            </CircularProgressbarWithChildren>
          </ProgressBarWrapper>

          <AttendanceInformation>
            <AttendanceHeading>{showSlots()}</AttendanceHeading>
            <Infoparent>
              <AttendanceInfo2>
                {t('absent.label') +
                  ' - ' +
                  (totalAttendance - totalPresents) +
                  ' ' +
                  showMiniSlots()}
              </AttendanceInfo2>
            </Infoparent>
            <Infoparent>
              <AttendanceInfo2 marginTop={'5px'}>{dateString}</AttendanceInfo2>
              <AttendanceInfo2 marginTop={'12px'}>
                <DateTimePicker
                  initialValue={calendarValue}
                  dateTimeMode={"monthyear"}
                  standalonePicker={true}
                  onDateTimeChangeCallback={(selectedDate: Date) => {
                    setCalendarValue(selectedDate);
                    dateChangeHandler(selectedDate);
                  }}
                  disableFuture={true}
                />
              </AttendanceInfo2>
            </Infoparent>
          </AttendanceInformation>
        </LeftContent>
      </ContentWrapper>

      <StudentAttendanceChild
        selectedAttendenceType={selectedAttendenceType}
        calendarDataForLeave={currentMonthAttendanceDataList}
        slotSelector={slotSelector}
        selectedSlot={selectedSlot}
        type={0}
        renderlistdata={listData}
        dates={dates}
      />
    </MainWrapper>
  );
});

export default StudentAttendance;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-color: ${colors.borderGrey};
  border-radius: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 120px;
  margin-bottom: 64px;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const AttendanceHeading = styled.div`
  font-size: 16px;
  font-family: ${fonts.semibold};
  color: ${colors.primaryText};
  margin-bottom: 4px;
`;

const AttendanceInfo2 = styled.div<{ marginTop?: string }>`
  font-size: 15px;
  font-family: ${fonts.semibold};
  color: ${colors.tertiaryText};
  margin-right: 15px;
  margin-top: ${({ marginTop }) => marginTop};
`;

const AttendanceInformation = styled.div`
  padding-left: 20px;
`;

const ProgressBarWrapper = styled.div``;

const Infoparent = styled.div`
  display: flex;
`;
