import React, { useEffect } from "react";

import { AdminDashboardCards } from "./AdminDashboardHelpers";
import { DashboardContainer as Container } from "../Common/CommonHelpers";
import { CourseList } from "src/components/molecules/Courses/CourseList";
import { InstituteList } from "src/components/molecules/Institute/InstituteList";
import PopularCourseDemographics from "./PopularCourseDemographics";
import RecentInstitutesDemographics from "./RecentInstitutesDemographics";
import styled from "styled-components";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { width } from "src/constant/device";

export const AdminDashboard = () => {
  const { setHeading } = useHeaderTitle();
  const { t } = useI18n();

  useEffect(() => {
    setHeading([{ text: t("dashboard.label"), url: "" }]);
  }, []);

  return (
    <Container>
      <AdminDashboardCards />
      <RowWrapper>
        <ColumnTwoThirds>
          <RecentInstitutesDemographics />
        </ColumnTwoThirds>
        <ColumnOneThird>
          <InstituteList />
        </ColumnOneThird>
      </RowWrapper>
      <RowWrapper>
        <ColumnTwoThirds>
          <PopularCourseDemographics />
        </ColumnTwoThirds>
        <ColumnOneThird>
          <CourseList />
        </ColumnOneThird>
      </RowWrapper>
    </Container>
  );
};

const RowWrapper = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.rem([0, -3])};
  flex-wrap: wrap;
`;

const ColumnTwoThirds = styled.div`
  display: ${width <= 767 ? "none" : "block"};
  width: 54.45%;
  padding: ${({ theme }) => theme.rem([1.5, 2.7, 1.5, 3])};
  box-sizing: border-box;
  margin-bottom: ${({ theme }) => theme.rem(3)};
  @media all and (max-width: 991px) {
    width: 100%;
  }
  @media all and (min-width: 992px) and (max-width: 1450px) {
    width: 65%;
    padding: ${({ theme }) => theme.rem([1.5, 2, 1, 3])};
    margin-bottom: ${({ theme }) => theme.rem(0.5)};
  }
`;

const ColumnOneThird = styled.div`
  width: 45.55%;
  padding: ${({ theme }) => theme.rem([1.5, 3, 1.5, 2.7])};
  box-sizing: border-box;
  margin-bottom: ${({ theme }) => theme.rem(3)};
  @media all and (max-width: 991px) {
    width: 100%;
  }
  @media all and (min-width: 992px) and (max-width: 1450px) {
    width: 35%;
    padding: ${({ theme }) => theme.rem([1.5, 3, 1.5, 2])};
    margin-bottom: ${({ theme }) => theme.rem(0.5)};
  }
`;
