import { useEffect, useState, CSSProperties } from "react";

import { DropdownOptionsType } from "src/types";
import { getBatchBreadcrumb } from "src/graphql/academics/batch";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";

export type BasicStudentDataType = {
  id: string;
  fullName: string;
  gender?: string;
  rollNo?: string;
};

export type changeDivisionInputStateType = {
  id: string;
  division: DropdownOptionsType;
};

type cellStyle = {
  trow: boolean;
  current: any;
  id: number;
  isFirstCell: boolean;
  isLastCell : boolean;
  itemId: number;
}

type divisionDetail = { division: string };

export function getDivisionDropdownOptions(batch: {
  divisionDetails: divisionDetail[];
}): DropdownOptionsType[] {
  if (batch?.divisionDetails && batch.divisionDetails?.length > 0) {
    return batch.divisionDetails.map((detail: divisionDetail) => {
      return {
        label: detail.division,
        value: detail.division,
      };
    });
  }
  return [];
}

export type changeDivisionPayloadType = { id: string; division: string };

export function filterAndConvertMapToArray(
  inputMap: Map<string, changeDivisionInputStateType>,
  currentDivision: string
) {
  const filteredArray: changeDivisionPayloadType[] = [];

  inputMap.forEach((data) => {
    if (data.division.value !== currentDivision) {
      filteredArray.push({
        id: data.id,
        division: data.division.value.toString(),
      });
    }
  });

  return filteredArray;
}

export const useSetHeadingForBatch = (batchId: string, division: string) => {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const [classId, setclassId] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      if (batchId && division) {
        const batchBreadcrumb = await getBatchBreadcrumb(batchId);
        if (batchBreadcrumb) {
          setclassId(batchBreadcrumb.classId);
          setHeading([
            {
              text: t("classrooms.label"),
              url: "/classrooms",
              from: "Students",
            },
            {
              text: batchBreadcrumb?.courseAlias || "-",
            },
            {
              text: batchBreadcrumb?.batchAlias || "-",
            },
            {
              text: division || "-",
            },
          ]);
        }
      }
    };

    fetchData();
  }, [batchId, division]);

  return classId;
};

export const getCellStyle = ({
  trow,
  current,
  id,
  isFirstCell = false,
  isLastCell = false,
  itemId
}:cellStyle) => {
  if ((trow && id === itemId) || (trow && current === id)) {
    if(isFirstCell){
      return {
        borderLeft: '2px solid rgba(43, 120, 202, 0.3)',
        borderTop: '2px solid rgba(43, 120, 202, 0.3)',
        backgroundColor: '#EAF2FA',
      } as CSSProperties;
    } 
    if(isLastCell){
      return {
        borderRight: '2px solid rgba(43, 120, 202, 0.3)',
        borderTop: '2px solid rgba(43, 120, 202, 0.3)',
        backgroundColor: '#EAF2FA',
      } as CSSProperties;
    } 
    return {
      borderTop: '2px solid rgba(43, 120, 202, 0.3)',
      backgroundColor: '#EAF2FA',
    }
  }else {
      return ''
  }
}

export function getExportModalHeader(documentType: string, t: any) {
  switch (documentType) {
    case 'FEE_RECEIPT':
      return t('exportReceipts.label');
    case 'ID_CARDS_GENERATION':
      return t('exportIDCards.label');
    case 'PRINTABLE_ID_CARDS_GENERATION':
      return t('exportPrintableIDCards.label');
    case 'PRINTABLE_LIBRARY_CARDS_GENERATION':
      return t('exportPrintableLibraryCards.label');
  }
}

export function getDocumentInitiationMessage(documentType: string, stage: string = 'initiate') {
  switch (documentType) {
    case 'FEE_RECEIPT':
      return stage === 'initiate' ? 'receipts' : 'Receipts';
    case 'ID_CARDS_GENERATION':
      return 'ID cards';
    case 'PRINTABLE_ID_CARDS_GENERATION':
      return stage === 'initiate' ? 'printable ID cards' : 'Printable ID cards';
    case 'PRINTABLE_LIBRARY_CARDS_GENERATION':
      return stage === 'initiate' ? 'printable Library cards' : 'Printable Library cards';
  }
}