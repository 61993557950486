import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { CustomReactNativeFile } from 'src/components/molecules/FileUpload/type';
import { isWeb } from 'src/constant/device';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import ImageAtom from '../ImageAtom';

interface Props {
  fileData: CustomReactNativeFile;
  onPress?: () => void;
  closeIcon?: boolean;
  hideFrame?: boolean;
}

export default function AttachImages(props: Props) {
  const { fileData, onPress, closeIcon = true, hideFrame = false } = props;
  const [uri, setURI] = useState<string | undefined>();

  useEffect(() => {
    if (!fileData.uri && fileData?.document) {
      if (isWeb) {
        setURI(URL.createObjectURL(fileData.document));
      } else {
        setURI(fileData.document.uri);
      }
    } else {
      setURI(fileData.uri)
    }
  }, [fileData])


  const AnnouncementsSlide = styled.View<{ hideFrame: boolean }>`
        height : 100px; 
        width: 100px;
        border-radius : 4px;
        box-shadow: 0px 0px 4px  ${colors.searchShadow};
        border-color: #171717;
        margin-left : 2px;
        shadow-offset: {width: 0.25; height: 0.25};
        background-color : ${colors.greyBackground};
        padding: ${({ hideFrame }) => hideFrame ? "0px" : "17px 0px"};
      `;

  return (
    <AnnouncementsSlide hideFrame={hideFrame}>
      {closeIcon && (
        <View style={[styles.iconView]}>
          <TouchableOpacity onPress={onPress} testID={"imageCrossIcon"}>
            <FontAwesomeIcon icon={faTimesCircle} size={24} color={colors.secondaryText} />
          </TouchableOpacity>
        </View>
      )}
      <ImageAtom
        // TODO:: Fix props for resizeMode / mode everywhere -- make it consistent
        source={{ uri: uri, ...(fileData?.headers ? { headers: fileData.headers } : {}) }}
        style={[styles.logo]}
        resizeMode={"cover"} // native
        mode={"cover"} // web
      />
    </AnnouncementsSlide>
  );
}

const styles = StyleSheet.create({
  iconView: { position: 'absolute', top: -10, right: -10 },
  logo: {
    height: '100%',
    width: '100%',
    borderRadius: 0,
    borderWidth: 0,
  },
});
