import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import { regexObject } from 'src/constant/regex';
import { TemplateType, ViewMode } from 'src/components/molecules/Forms/types';
import { getComponentType } from './util';
import { ResolvePropsFunction } from '@data-driven-forms/react-form-renderer/common-types';
import { states } from './dd-options';

const { PINCODE_INVALID } = regexObject;

const handleDisablePermanentAddressEdit: ResolvePropsFunction = (_props: any, _field: any, formOptions: any) => ( 
  formOptions?.getState()?.values?.isSameAsCurrent ? { isDisabled: true } : { isDisabled: false }
)

const syncCurrentToPermanentAddress = (fieldName: string) => {
  return (props: any, formOptions: any) => {
    const { values } = formOptions.getState();
    if (values.isSameAsCurrent) {        
        formOptions.change(`permanent.${fieldName}`, values.current[fieldName]);
    }
  }
}

export const address = (
  viewMode: ViewMode,
  addressType: string = "current",
  label: string = 'currentAddressDetails.label',
  resolveProps?: ResolvePropsFunction | undefined,
  handleChange?: (fieldName: string) => Function,
  isInstituteAdmin?: boolean,
) => {
  return {
    name: addressType,
    label,
    component: "custom-sub-form",
    section: "left",
    groupBy: addressType,
    subscribeToValues: addressType == 'permanent',
    fields: [
      {
        name: `${addressType}.houseNo`,
        label: "form.houseNumber.label",
        component: getComponentType(viewMode, "custom-text-field"),
        handleChange: handleChange && handleChange('houseNo'),
        resolveProps,
        autoCapitalize: "words",
        validate: !isInstituteAdmin ? [
          {
            type: validatorTypes.REQUIRED,
            message: 'error.houseNumber.required',
          },
        ] : [],
      },
      {
        name: `${addressType}.street`,
        label: "form.streetNo.label",
        component: getComponentType(viewMode, "custom-text-field"),
        autoCapitalize: "words",
        handleChange: handleChange && handleChange('street'),
        resolveProps,
      },
      {
        name: `${addressType}.area`,
        label: "form.streetName.label",
        component: getComponentType(viewMode, "custom-text-field"),
        autoCapitalize: "words",
        handleChange: handleChange && handleChange('area'),
        resolveProps,
        validate: !isInstituteAdmin ? [
          {
            type: validatorTypes.REQUIRED,
            message: 'error.streetName.required',
          },
        ] : [],
      },
      {
        name: `${addressType}.city`,
        label: "form.cityName.label",
        component: getComponentType(viewMode, "custom-text-field"),
        autoCapitalize: "words",
        handleChange: handleChange && handleChange('city'),
        resolveProps,
        validate: !isInstituteAdmin ? [
          {
            type: validatorTypes.REQUIRED,
            message: 'error.cityName.required',
          },
        ] : [],
      },
      {
        name: `${addressType}.state`,
        label: "form.state.label",
        component: getComponentType(viewMode, "select"),
        handleChange: handleChange && handleChange('state'),
        options: states,
        resolveProps,
        validate: !isInstituteAdmin ? [
          {
            type: validatorTypes.REQUIRED,
            message: 'error.state.required',
          },
        ] : [],
      },
      {
        name: `${addressType}.zipcode`,
        label: "form.pinCode.label",
        component: getComponentType(viewMode, "custom-text-field"),
        handleChange: handleChange && handleChange('zipcode'),
        resolveProps,
        validate: !isInstituteAdmin ? [
          {
            type: validatorTypes.REQUIRED,
            message: 'error.pinCode.required'
          },
          {
            type: validatorTypes.PATTERN,
            pattern: PINCODE_INVALID,
            message: 'error.pinCode.invalid',
          }
        ] : [
          {
            type: validatorTypes.PATTERN,
            pattern: PINCODE_INVALID,
            message: 'error.pinCode.invalid',
          }
        ]
      }
    ]
  }
}

export const addresses = (viewMode: ViewMode, isInstituteAdmin?: boolean) => ({
  name: "addressDetails",
  label: "addressCorrespondence.label",
  viewMode,
  headerData: {
    title: 'addressDetails.label',
    skip: true,
    icon: require('src/assets/common/headerOnboardingIcons/v1/address-3x.png'),
    progress: 0.4,
  },
  fields: [
    address(
      viewMode,
      'current',
      'currentAddressDetails.label',
      undefined,
      syncCurrentToPermanentAddress,
      isInstituteAdmin,
    ),
    {
      name: 'isSameAsCurrentAddress',
      component: "custom-sub-form",
      section: "left",
      fields: [
        {
          name: 'isSameAsCurrent',
          label: 'form.sameAsAbove.label',
          component: viewMode == ViewMode.READ ? 'none' : 'checkbox',
          marginTopOffset: -16,
          marginBottomOffset: 8,
          ignoreInProfileView: true,
          resolveProps: (_props: any, _field: any, formOptions: any) => (
            formOptions.getState().values.isSameAsCurrent ? { isChecked: true } : { isChecked: false }
          ),
          handleChange: (formOptions: { change: any; getState?: any; getFieldState?: any; }) => {
            const { change, getState, getFieldState } = formOptions;
            const { values } = getState();
            if (values.isSameAsCurrent && !getFieldState("isSameAsCurrent").modified) {
              formOptions.change("isSameAsCurrent", false);
            }
            if (values.isSameAsCurrent && values?.current) {
              Object.keys(values.current).map(key => {
                if (key !== 'id') {
                  change("permanent." + key, values.current[key])
                }
              })
            }
          }
        },
      ],
    },
    address(
      viewMode,
      'permanent',
      "permanentAdressDetails.label",
      handleDisablePermanentAddressEdit,
      undefined,
      isInstituteAdmin,
    )
  ]
})
