import { Modal } from 'react-native'
import React from 'react'
import styled from 'styled-components/native'
import { colors, fonts } from 'src/styles/theme/styles';
import NormalTextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faExclamationTriangle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import ListItem from 'src/components/atoms/listItem';

type AlertNativeModalProps = {
  title: string,
  message1?: string,
  message2?: string,
  messageList?: string[],
  cancelButtonText?: string,
  confirmButtonText?: string,
  showCancelButton?: boolean,
  showConfirmButton?: boolean,
  showModal: boolean
  onCancel: any,
  onConfirm: any,
  titleLogo?: IconDefinition,
  titleLogoColor?: string,
  showTitleLogo?: boolean,
  titleTextColor?: string,
  contentFontFamily?: string,
  confirmButtonColor?: string,
}
export default function AlertModalNative(props: AlertNativeModalProps) {

  const {
    title = 'Attention!',
    message1 = 'Do you want to proceed',
    message2,
    messageList,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'OK',
    showCancelButton = true,
    showConfirmButton = true,
    onCancel,
    onConfirm,
    showModal,
    titleLogo = faExclamationTriangle,
    titleLogoColor = colors.yellow,
    showTitleLogo = true,
    titleTextColor = colors.secondaryText,
    contentFontFamily = fonts.regular,
    confirmButtonColor = colors.primaryColor
  } = props
  return (
    <>
      <Modal
        visible={showModal}
        transparent={true}
      >
        <ModalViewContainer>
          <ModalView>
            <TitleContainer>
              {titleLogo && showTitleLogo && <FontAwesomeIcon icon={titleLogo} size={24} color={titleLogoColor || colors.secondaryText} />}
              <NormalTextAtom
                value={title}
                fontSize={2}
                lineHeight={2.2}
                style={showTitleLogo && { paddingLeft: 12 }}
                color={titleTextColor}
              />
            </TitleContainer>
            <MessageContainer>
              <NormalTextAtom
                value={message1}
                lineHeight={2.5}
                fontFamily={contentFontFamily}
              />
              {message2 && <NormalTextAtom
                value={message2}
              />}
              {messageList &&
                <FlatListItem
                  showsVerticalScrollIndicator={false}
                  data={messageList}
                  renderItem={({ item }) => <ListItem text={item} />}
                />}
            </MessageContainer>
            <ControlView>
              {showCancelButton &&
                <CancelButton onPress={onCancel}>
                  <NormalTextAtom
                    color={colors.primaryColor}
                    value={cancelButtonText}
                    lineHeight={2}
                  />
                </CancelButton>
              }
              {showConfirmButton &&
                <ConfirmButton testID={'alertModalSaveButton'} onPress={onConfirm} confirmButtonColor={confirmButtonColor}>
                  <NormalTextAtom
                    color={colors.white}
                    value={confirmButtonText}
                    lineHeight={2}
                  />
                </ConfirmButton>
              }
            </ControlView>
          </ModalView>
        </ModalViewContainer>
      </Modal>
    </>
  )
}

const ModalViewContainer = styled.View`
  flex: 1;
  background-color: rgba(0,0,0,0.35);
  justify-content: center;
  padding: 24px;
  align-items: center;
`
const ModalView = styled.View`
  background-color: ${colors.white};
  justify-content: center;
  align-items: center;
  padding: 20px 25px 20px 20px;
  min-width: 330px;
  max-width: 720px;
  border-radius: 3px;
`
const TitleContainer = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
`
const MessageContainer = styled.View`
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 0;
`
const ControlView = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
`
const CancelButton = styled.TouchableOpacity`
  padding-vertical: 6px;
  padding-horizontal: 12px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  border-width: 1px;
  border-color: ${colors.primary}
`

const ConfirmButton = styled.TouchableOpacity<AlertNativeModalProps>`
  padding-vertical: 6px;
  padding-horizontal: 12px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  background-color: ${props => props.confirmButtonColor}
`

const FlatListItem = styled.FlatList`
  font-size: 1.5rem;
`