import Check from "src/components/atoms/CheckBox/index.web";
import React from "react";
import styled from "styled-components";

type Props = {
  onChange: (reqDetails: string[]) => void;
  fieldValue: string[];
};

type TData = {
  label: string;
  value: string[];
};

const admissionConfigData: TData[] = [
  { label: "Date of Birth", value: ["DOB"] },
  { label: "Parent's Aadhaar", value: ["AADHAAR_ID"] },
];

const AdmissionRequiredDetails = (props: Props) => {
  const { onChange, fieldValue } = props;

  const onToggle = (item: TData, currentVal: boolean) => {
    let updatedReqDetails = [...fieldValue];
    if (currentVal) {
      item.value.forEach((val) => {
        if (!updatedReqDetails.includes(val)) {
          updatedReqDetails.push(val);
        }
      });
    } else {
      updatedReqDetails = updatedReqDetails.filter(
        (val) => !item.value.includes(val)
      );
    }
    onChange(updatedReqDetails);
  };

  return (
    <Container>
      {admissionConfigData.map((item) => (
        <ItemWrapper>
          <Check
            id={item.label}
            header={item.label}
            checked={item.value.every((val) => fieldValue.includes(val))}
            onChange={(currentVal: boolean) => onToggle(item, currentVal)}
          />
        </ItemWrapper>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding-left: 24px;
  padding-bottom: 8px;
`;

const ItemWrapper = styled.div`
  margin-bottom: 16px;
`;

export default AdmissionRequiredDetails;
