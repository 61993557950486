import { gql } from '@apollo/client';

export const getModifiedExamDetailsQuery = gql`
  query(
    $filters: ExamDetailFilterInput
    $limit: Int
    $skip: Int
    $sort: ExamDetailSortInput
    $lookup: LookupFilter
  ) {
    examDetails(
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
      lookup: $lookup
    ) {
      data {
        type
        value: id
        label: name
        type
        tests {
          order
          subject {
            value: id
            label: name
          }
          tests {
            id
            name
            passingMarks
            totalMarks
            markingType
          }
        }
      }
    }
  }
`;
