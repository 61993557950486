import React, { useEffect, useState } from 'react';
import { useGetDefaultRuleset } from 'src/graphql/assessment/assessment-results';
import styled from 'styled-components';
import useTable from '../../Table/UseTable';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { TableBody } from '@mui/material';
import { NoRecordsFound, TableCells, TableRows } from '../../Table/TableAtom';
import { height } from 'src/constant/device';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { colors, fonts } from 'src/styles/theme/styles';

const headCells1 = [
  {
    id: 'name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'description',
    label: 'description.label',
    align: 'left',
    disableSorting: true,
    style: { minWidth: 208 },
  },
  {
    id: 'symbol',
    label: 'symbol.label',
    align: 'right',
    disableSorting: true,
  },
];

export default function GenerateRulesetModal() {
  const [row, setRow] = useState([]);
  const [headCells, setHeadCells] = useState(headCells1);
  const { getDefaultRuleset, dataDefaultRuleset, loadingDefaultRuleset } = useGetDefaultRuleset();

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    row?.length,
  );

  useEffect(() => {
    getDefaultRuleset();
  }, []);

  useEffect(() => {
    if (dataDefaultRuleset?.getDefaultRuleSet?.rules) {
      setRow(dataDefaultRuleset.getDefaultRuleSet.rules);
    }
  }, [dataDefaultRuleset]);

  return (
    <>
      <MediumTextSelect
        value="Default Ruleset"
        color={colors.primaryText}
        fontFamily={fonts.semibold}
        fontSize={1.8}
      />
      <TblContainer height={'95%'}>
        <TblHead />
        {!loadingDefaultRuleset ? (
          <>
            {row?.length > 0 ? (
              <TableBody>
                {recordsAfterPagingAndSorting()?.map((ruleset: any, i: number) => (
                  <TableRows key={`e${i}`}>
                    <TableCells value={ruleset?.name} />
                    <TableCells value={ruleset?.description || '-'} />
                    <TableCells value={ruleset?.symbol} align={'right'} />
                  </TableRows>
                ))}
              </TableBody>
            ) : (
              <NoRecordsFound colspan={3} maxHeight={0.7 * height - 49} />
            )}
          </>
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )}
      </TblContainer>
    </>
  );
}

const SpinnerView = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;
