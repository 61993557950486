import { differenceInMinutes, isWithinInterval } from 'date-fns';
import { parseToDate } from 'src/constant/dateTime';

export const currentClassStatus = (item: any): number | undefined => {
  const currentTime = new Date();
  const startTime = parseToDate(item?.start);
  const endTime = parseToDate(item?.end);

  if (startTime && endTime) {
    if (isWithinInterval(currentTime, { start: startTime, end: endTime })) {
      const minutes = differenceInMinutes(currentTime, startTime);
      console.log("(minutes / 60) * 100", (minutes / 60) * 100)
      return (minutes / 60) * 100;
    }
    if (startTime > currentTime) {
      return 0;
    }
    if (endTime < currentTime) {
      return 100;
    }
  }
};

export const currentSelectedClass = (item: any): boolean => {
  const currentTime = new Date();
  const startTime = parseToDate(item?.start);
  const endTime = parseToDate(item?.end);
  if (startTime && endTime) {
    return isWithinInterval(currentTime, { start: startTime, end: endTime })
  }
  return false;
};
