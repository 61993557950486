import React, { useEffect } from 'react';
import { USER_TYPE } from 'src/constant';
import { flushStorageFunction, getFullListOfTokens, getTokenFromStorage } from 'src/components/services/storage-service';
import navigationStrings from 'src/navigation/navigationStrings';
import { checkTokenExpiration, decodeToken } from 'src/graphql/common';
import { useRoute } from '@react-navigation/native';
import { apolloClearStore } from 'src/graphql/client';
import { KEYS } from 'src/constant/key';
import { intersection } from 'lodash';
import { authToken } from 'src/utils/manageState';

export const NativePrivateRoute = ({ component: Component, authCallback, ...rest }: any) => {
  const authUser = rest.authUser;
  const subAuth = rest?.subAuth || [];
  const navigation = rest?.props?.navigation;
  const route = useRoute();
  const redirectToAccountsSelection: boolean = route?.name === "multi_account" && route?.params?.["from"] === "Login";

  useEffect(() => {
    checkTokenExpiry();
  });

  useEffect(() => {
    if (route) {
      if (!redirectToAccountsSelection) {
        checkIfUserLoggedIn();
      }
    }
  }, [route]);

  const checkTokenExpiry = async () => {
    const TOKEN = authToken()?.edbaToken;
    if (TOKEN) {
      checkTokenExpiration(TOKEN);
    } else {
      if (!redirectToAccountsSelection) {
        clearStorage();
      }
    }
  }

  const checkIfUserLoggedIn = async () => {
    const TOKEN = await authToken()?.edbaToken;
    if (TOKEN) {
      const loginEmployeeInformation: any = await decodeToken(TOKEN);
      const { userType, userDetails } = loginEmployeeInformation;
      if (userType) {
        if (
          userType < 1 ||
          !authUser.includes(userType) ||
          (userType === USER_TYPE.EMPLOYEE &&
            subAuth && subAuth.length > 0 &&
            !intersection(userDetails?.type, subAuth))
        ) {
          clearStorage();
        }
      } else {
        clearStorage();
      }
    } else {
      console.log("Users is not logged in");
      clearStorage();
    }
  };

  function clearStorage() {
    flushStorageFunction(KEYS.ACCESS_TOKEN);
    clearGarbage();
    navigation.navigate(navigationStrings.USER_LOGIN);
  }

  function clearGarbage() {
    apolloClearStore();
    flushStorageFunction(KEYS.ASSIGNMENT_INDEX);
    flushStorageFunction(KEYS.USER_INFO);
    flushStorageFunction(KEYS.USER_INFORMATION);
    flushStorageFunction(KEYS.ASSIGNMENT_TOGGLE);
  }

  return <Component {...rest.props} authCallback={authCallback && authCallback} />;
};

export default React.memo(NativePrivateRoute);