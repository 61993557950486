import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { NoRecordsFound, TableCells, TableRows } from 'src/components/molecules/Table/TableAtom';
import styled, { useTheme } from 'styled-components';
import { useHistory, useParams } from 'src/routes/routing.web';
import Check from 'src/components/atoms/CheckBox/index.web';
import GradeCardModal from 'src/components/molecules/Assessment/Results/GradeCardModal';
import { EditIcon, InfoSquareIcon, OverflowMenuIcon } from 'src/components/atoms/ActionIcons';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import ResultSelectionForm from 'src/components/molecules/Assessment/ResultSelectionForm';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import {
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  TableBody,
  Tooltip,
} from '@mui/material';
import ViewResultModal from 'src/components/molecules/Assessment/Results/ViewResultModal';
import { colors, fonts } from 'src/styles/theme/styles';
import { height } from 'src/constant/device';
import { AUTOCOMPLETE_MODULE, ERROR, limit, SEARCH_TIME, SUCCESS } from 'src/constant';
import {
  useGetMarkSheets,
  usePromoteStudentToAdmission,
  usePromoteStudentToBatch,
  useUnblockResult,
  usePublishResults,
  useUnpublishResults,
  useGenerateResults,
  useApplyGraceByResolution,
  generateGraceByRulesetMutation,
  useApplyGraceByRuleset,
  useEditResult,
  useImportMarkSheetDetails,
  useRegenerateMarksheets,
  useEnableATKTFrom,
  useEnableRevaltionFrom,
  useGetATKTAndReval,
  useUpdateRevalFormStatus,
  useGetATKTApplications,
  useGetRevalApplications,
} from 'src/graphql/assessment/assessment-results';
import { fetchBatchById } from 'src/graphql/academics/batch';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import useTable from 'src/components/molecules/Table/UseTable';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import PromoteStudentForm from 'src/components/molecules/Assessment/Results/PromoteStudentForm';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ResolutionObject, ResultStatusLabel, getResultStatusColor, headCells1,headCells2 } from './helpers';
import { checkRemark, downloadFileRestAPI, getTableValue, properCase } from 'src/utils/utility';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faBan,
  faCheckSquare,
  faInfoSquare,
  faPlusSquare,
  faSyncAlt,
  faTimesSquare,
} from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'src/components/atoms/Icon/Icon';
import BlockRejectionReason from 'src/components/molecules/Admission/BlockRejectionReason';
import ERROR_MSG from 'src/constant/error';
import { ADD_SUCCESS, UPDATE_SUCCESS, ENABLE_SUCCESS } from 'src/constant/message';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import ButtonGrp from 'src/components/atoms/ButtonGroup/index.web';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, createSortFilter, debounce } from 'src/components/services';
import { getStudentFullNameFilter } from 'src/components/services/filters';
import Search from 'src/components/atoms/SearchBar/index.web';
import GenerateResolutionModal from 'src/components/molecules/Assessment/Results/GenerateResolutionModal';
import StudentDetailsModal from 'src/components/molecules/Assessment/Results/StudentDetailsModal';
import GenerateRulesetModal from 'src/components/molecules/Assessment/Results/GenerateRulesetModal';
import { graphqlMutation } from 'src/graphql/util';
import { FieldValues, useForm } from 'react-hook-form';
import EditResultForm from './EditResultForm';
import { faCaretDown, faCaretUp, faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { faDownload, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { documentObject } from '../../Admission/types';
import { TBlockResultFormState as TForm } from 'src/components/molecules/Assessment/Results/Actions/ResultActionsTypes';
import BlockResultForm from 'src/components/molecules/Assessment/Results/Actions/BlockResultForm.web';
import ATKTRevalForm from 'src/components/molecules/Assessment/Results/form/ATKTRevalForm';
import AlertModalNative from 'src/components/molecules/AlertModal/AlertModalNative';
import AtktAppliedStudent from 'src/components/molecules/Assessment/Results/RevalAtktModal/ViewAppliedstudent/AtktAppliedStudent.web';
import RevalAppliedStudent from 'src/components/molecules/Assessment/Results/RevalAtktModal/ViewAppliedstudent/RevalAppliedStudent.web';
import { TExamItem, TExamType } from 'src/components/molecules/Assessment/Results/Helpers/helpers';
import PublishResultForm from 'src/components/molecules/Results/PublishResultForm';

export default function Results() {
  const { t } = useI18n();
  const { rem }: any = useTheme();
  const { setHeading } = useHeaderTitle();
  const { filters, setFilter } = useFilter();
  const [marksType, setMarksType] = useState<boolean>(false);
  const [showCheck, setShowCheck] = useState(true);
  const [array, setArray] = useState([]);
  const [viewResultModalState, handleViewResultModal] = useState<boolean>(false);
  const [gradeCardModalState, handleGradeCardModal] = useState<boolean>(false);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [row, setRow] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(null);
  const [examDetails, setExamDetails] = useState<TExamItem | null>(null);
  const [subjectsIds, setSubjectsIds] = useState<String[]>();
  const [selectedBatchId, setSelectedBatchId] = useState<string>('');
  const [selectedClassId, setSelectedClassId] = useState<string>('');
  const [selectedClassAlias, setSelectedClassAlias] = useState<string>('');
  const [selectedDivision, setSelectedDivision] = useState<string>('');
  const [selectedExamId, setSelectedExamId] = useState<string>('');
  const [allFormValues, setAllFormValues] = useState<boolean>(false);
  const [promoteStudentModal, handlePromoteStudentModal] = useState<boolean>(false);
  const [isEditPromoteStudentModal, setIsEditPromoteStudentModal] = useState(false);
  const [editPromoteStudentData, setEditPromoteStudentData] = useState<{}>();
  const [canClick, setCanClick] = useState(true);
  const [remarkModal, setRemarkModal] = useState<boolean>(false);
  const [dropDown, setDropDown] = useState<boolean>(false);
  const [examName, setExamName] = useState<string>('');
  const [publishResultsModal, setPublishResultsModal] = useState<boolean>(false);
  const [generateResolutionModal, setGenerateResolutionModal] = useState<boolean>(false);
  const [generateRulesetModal, setGenerateRulesetModal] = useState<boolean>(false);
  const [studentDetailsModal, setStudentDetailsModal] = useState<boolean>(false);
  const [selectedGrace, setSelectedGrace] = useState<ResolutionObject | null>(null);
  const [appliedAtktData, setAppliedAtktData] = useState<any>(null);
  const [appliedRevalData, setAppliedRevalData] = useState<any>(null);
  const [graceType, setGraceType] = useState<string>('');
  const [editResultModal, setEditResultModal] = useState<boolean>(false);
  const [actionIconsDropdown, setActionIconsDropdown] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>('');
  const [updateResultLoader, setUpdateResultLoader] = useState<boolean>(true);
  const [atktStudentModal, setAtktStudentModal] = useState<boolean>(false);
  const [revalStudentModal, setRevalStudentModal] = useState<boolean>(false);
  const [atktRevalModal, setATKTRevalModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [atktEnabled, setATKTEnabled] = useState<boolean>(false);
  const [revalEnabled, setRevalEnabled] = useState<boolean>(false);
  const [button, setButton] = useState({ mode: '' });
  const [revalData, setRevalData] = useState<any>(null);
  const [ATKTData, setATKTData] = useState({});
  const [maintainGrNo, setMaintainGrNo] = useState<boolean>(true);
  const [activeBtn, setActiveBtn] = useState<any>();
  const [promotionConfirmationModal, setPromotionConfirmationModal] = useState({
    state: false,
    header: '',
    message: '',
  });
  const [closeReval, setCloseReval] = useState<boolean>(false);
  const [batchId, setBatchId] = useState();
  const { getBatchByID } = fetchBatchById();
  // Use this as Primary Form state while refactoring
  const [form, setForm] = useState<TForm>({ state: 'CLOSE' });

  const { setAlertDetails } = useAlertSystem();
  const history = useHistory();
  const remarkRef = useRef(null);

  const { getMarkSheets, loadingMarkSheets, dataMarkSheets, errorMarkSheets, refetchMarkSheets } =
    useGetMarkSheets();
  const { promoteStudentToBatch, promoteStudentToBatchData, promoteStudentToBatchError } =
    usePromoteStudentToBatch();
  const {
    promoteStudentToAdmission,
    promoteStudentToAdmissionData,
    promoteStudentToAdmissionError,
  } = usePromoteStudentToAdmission();
  const { unblockResult } = useUnblockResult();
  const { publishResults } = usePublishResults();
  const { unpublishResults } = useUnpublishResults();
  const { generateResults } = useGenerateResults();
  const { applyGraceByResolution } = useApplyGraceByResolution();
  const { applyGraceByRuleset } = useApplyGraceByRuleset();
  const { editResult } = useEditResult();
  const { importMarkSheetDetails } = useImportMarkSheetDetails();
  const { regenerateMarksheets } = useRegenerateMarksheets();
  const { enableATKT } = useEnableATKTFrom();
  const { enableRevalution } = useEnableRevaltionFrom();
  const { getATKTAndReval, dataATKTAndReval } = useGetATKTAndReval();
  const { updateRevalFormStatus } = useUpdateRevalFormStatus();
  const { fetchATKTApplications, dataGetATKTApplications, loadingGetATKTApplications } =
    useGetATKTApplications();
  const { fetchRevalApplications, loadingGetRevalApplications } = useGetRevalApplications();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm();

  const {
    page = 1,
    dataLimit = limit,
    urlBatchId = null,
    urlExamId = null,
    urlDivision = null,
  }: any = useParams();
  const examType: TExamType | null = examDetails ? examDetails.type : null;
  const isATKTExam = examType === "ATKT";
  const isGeneralExam = examType === "GENERAL";
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [headCells, setHeadCells] = useState(headCells1);
  const handleClick = (e: any, button: any) => {
    // If the clicked button has subOptions, we toggle the sub-menu
    if (button?.subOptions) {
      setOpenSubMenu(openSubMenu === button.key ? null : button.key);
    } else {
      // For buttons without subOptions, toggle the main menu and call onPress
      setAnchorEl(anchorEl ? null : e.currentTarget);
      if (button?.onPress) {
        button.onPress(); // Ensure this gets called for buttons without subOptions
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSubMenu(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let searchValue = '' as string;
  let width = window.innerWidth;
  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const [searching, setSearchingStudent] = useState(searchValue);
  const [applyResolutionConfirmation, setApplyResolutionConfirmation] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FieldValues>({});

  let maxHeight = height - 430;
  const paginationHeightOffset = Boolean(totalCount && totalCount >= rowLimit) ? 140 : 135;

  if (filters && filters?.results_students?.on) {
    searchValue = filters?.results_students?.search;
  }

  useEffect(() => {
    setSearchingStudent(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (urlBatchId && urlDivision) {
      handleFetch(urlBatchId, urlDivision, urlExamId);
      handleATKTRevalStatus(urlExamId);
    }
  }, [urlBatchId, urlDivision, urlExamId, headCells, searchValue, page]);

  // Set header title text
  useEffect(() => {
    setHeading([{ text: t('results.label'), url: '' }]);
  }, []);

  useEffect(() => {
    if (allFormValues) {
      handleFetch(selectedBatchId, selectedDivision, selectedExamId);
    }
  }, [currentPage, rowLimit]);

  useEffect(() => {
    if (!loadingMarkSheets && dataMarkSheets && dataMarkSheets?.userMarkSheets?.data) {
      setRow(dataMarkSheets?.userMarkSheets?.data);
      setTotalCount(dataMarkSheets?.userMarkSheets?.totalCount);
      // Reset / Clear out checked values if new form
      // values are selected or values are refetched
      setArray([]);
    }
  }, [loadingMarkSheets, dataMarkSheets]);

  useEffect(() => {
    if (errorMarkSheets) {
      handleNoExam();
    }
  }, [errorMarkSheets]);

  function addToUrl(currentBatchId: string, currentDivision: string, currentExamId: string) {
    const updatedPage = 1; // Initialize to first page
    setCurrentPage(updatedPage);
    const currentUrl = currentExamId
      ? `/assessment/results/${currentBatchId}/${currentDivision}/${currentExamId}/limit/${dataLimit}/page/${updatedPage}`
      : `/assessment/results/${currentBatchId}/${currentDivision}/limit/${dataLimit}/page/${updatedPage}`;
    history.push(currentUrl);
  }

  const { TblHead, TblContainer, recordsAfterPagingAndSorting } = useTable(
    row,
    null,
    maxHeight,
    totalCount,
    currentPage,
    null,
    null,
    false,
    false,
    null,
    paginationHeightOffset,
  );

  function handleFetch(batchId: string, division: string, examId?: string) {
    const filtersValue = getStudentFullNameFilter(searchValue);
    const sortCriteria = createSortFilter(headCells);

    const resultsFilter = createSearchFilter(
      rowLimit,
      (currentPage - 1) * rowLimit,
      filtersValue,
      sortCriteria,
    );
    getMarkSheets({
      variables: {
        batchId: batchId,
        division: division,
        ...(examId ? { exam: examId } : {}),
        ...resultsFilter,
      },
    });
  }

  const handleCheck = (item: any) => {
    const index = array.findIndex(x => x === item?.user?.id);
    const updated = [...array];
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated.push(item?.user?.id);
    }
    setArray(updated);
  };

  const handleCheckAll = () => {
    if (array.length === 0) {
      let allUsers = [] as any;
      recordsAfterPagingAndSorting()?.forEach((item: any) => {
        allUsers.push(item?.user?.id);
      });
      setArray(allUsers);
    } else if (array.length > 0 && array.length !== row?.length) {
      let update = [...array] as any;
      const res = recordsAfterPagingAndSorting()?.filter(x => !array.includes(x.user?.id));
      res.forEach((e: any) => {
        update.push(e?.user?.id);
      });
      setArray(update);
    } else {
      setArray([]);
    }
  };

  function handleNoExam() {
    setRow([]);
    setTotalCount(0);
    setArray([]);
  }

  function resetValues() {
    setRow([]);
    setTotalCount(null);
  }

  const retrieveValues = (formData: any) => {
    resetValues();
    setFilter(null);
    setMarksType(formData?.batch?.gradingType === 'MARKS');
    setExamDetails(formData?.exams)
    setSelectedExamId(formData?.exams?.value || '');
    setSelectedBatchId(formData?.batch?.value);
    setSelectedClassId(formData?.batch?.class?.id);
    setSelectedClassAlias(formData?.batch?.class?.alias);
    setSelectedDivision(formData?.division.value);
    setAllFormValues(true);

    handleFetch(formData?.batch?.value, formData?.division.value, formData?.exams?.value);

    // add batch id to history
    addToUrl(formData.batch.value, formData?.division.value, formData?.exams?.value);
  };

  const closeViewResultModal = () => {
    handleViewResultModal(false);
  };

  const closeGradeCardModal = () => {
    handleGradeCardModal(false);
  };

  const closePromoteStudentsModal = () => {
    handlePromoteStudentModal(false);
    setMaintainGrNo(true);
    setFormValues({});
    closePromotionConfirmationModal();
  };

  function closePromotionConfirmationModal() {
    setPromotionConfirmationModal({
      state: false,
      header: '',
      message: '',
    });
  }

  function viewStudent(studentId: string) {
    history.push(`/student/${studentId}/0/view`, { from: 'results' });
  }

  const handleViewResult = (item: any) => {
    setCurrentItem(item);
    handleViewResultModal(true);
  };

  const handlePromoteStudentToBatch = async (formValues: any) => {
    setCanClick(false);
    try {
      const payload = {
        fromBatch: urlBatchId,
        toBatch: formValues?.batch?.value,
        subgrpId: formValues?.subjectGroup?.value,
        studentId: array,
        maintainGRNo: maintainGrNo,
      };
      if (formValues?.division?.value) {
        payload['division'] = formValues.division.value;
      }
      const response = await promoteStudentToBatch({
        variables: payload,
      });
      if (response.data.promoteToBatch.status === SUCCESS) {
        setCanClick(true);
        closePromoteStudentsModal();
        if (refetchMarkSheets) refetchMarkSheets();
        setAlertDetails({
          message: response.data.promoteToBatch.message,
          level: SUCCESS,
        });
      } else {
        setCanClick(true);
        closePromoteStudentsModal();
        setAlertDetails({ message: response.data.promoteToBatch.message, level: ERROR });
      }
    } catch (e: any) {
      setAlertDetails({ message: e?.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handlePromoteStudentToAdmission = async (formValues: any) => {
    setCanClick(false);
    try {
      const payload = {
        admissionId: formValues?.admissionConfig?.value,
        studentId: array,
        maintainGRNo: maintainGrNo,
      };
      if (formValues?.subjectGroup?.value) {
        payload['subjectGroup'] = formValues.subjectGroup.value;
      }
      if (formValues?.division?.value) {
        payload['preferredDivision'] = formValues.division.value;
      }
      const response = await promoteStudentToAdmission({
        variables: payload,
      });
      if (response.data.promoteToAdmission.status === SUCCESS) {
        setCanClick(true);
        closePromoteStudentsModal();
        if (refetchMarkSheets) refetchMarkSheets();
        setAlertDetails({
          message: response.data.promoteToAdmission.message,
          level: SUCCESS,
        });
      } else {
        setCanClick(true);
        closePromoteStudentsModal();
        setAlertDetails({ message: response.data.promoteToAdmission.message, level: ERROR });
      }
    } catch (e: any) {
      setAlertDetails({ message: e?.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handlePromoteStudent = (formValues: FieldValues) => {
    setFormValues(formValues);
    if (maintainGrNo) {
      setPromotionConfirmationModal({
        state: true,
        header: 'maintainCurrentGr.label',
        message: 'maintainCurrentGr.message.text',
      });
    } else {
      setPromotionConfirmationModal({
        state: true,
        header: 'discontinueCurrentGr.label',
        message: 'discontinueCurrentGr.message.text',
      });
    }
  };

  function handleGrNoConfirmation() {
    if (formValues?.promotionType?.value === 'DIRECT') {
      handlePromoteStudentToBatch(formValues);
    } else {
      handlePromoteStudentToAdmission(formValues);
    }
  }

  const handleUnblockResult = async (userId: string) => {
    try {
      const response = await unblockResult({
        variables: { examId: selectedExamId, userId: userId },
      });
      if (response?.data?.unBlockResultforUser?.status === 'success') {
        setAlertDetails({
          message: UPDATE_SUCCESS.UNBLOCK_RESULT,
          level: SUCCESS,
        });
        if (refetchMarkSheets) refetchMarkSheets();
      } else {
        throw new Error(response?.data?.unBlockResultforUser?.message || ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
  };

  function closeRemarkModal() {
    setRemarkModal(false);
  }

  async function handleGenerateResults() {
    closeDropDown();
    setUpdateResultLoader(false);
    try {
      const response = await generateResults({
        variables: {
          division: selectedDivision,
          examId: selectedExamId,
        },
      });
      if (response?.data?.generateBatchMarkSheets?.status === 'success') {
        if (refetchMarkSheets) refetchMarkSheets();
        setAlertDetails({
          message: UPDATE_SUCCESS.RESULT,
          level: SUCCESS,
        });
        setUpdateResultLoader(true);
      } else {
        throw new Error(
          response?.data?.generateBatchMarkSheets?.message || ERROR_MSG.GENERIC_ERROR,
        );
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setUpdateResultLoader(true);
    }
  }

  async function handleRegenerateMarksheets() {
    closeDropDown();
    try {
      const response = await regenerateMarksheets({
        variables: {
          batchId: selectedBatchId,
          division: selectedDivision,
          examId: selectedExamId,
        },
      });
      if (response?.data?.regenerateMarksheets?.status === 'success') {
        if (refetchMarkSheets) refetchMarkSheets();
        setAlertDetails({
          message: ADD_SUCCESS.REGENERATE_MARKSHEETS,
          level: SUCCESS,
        });
      } else {
        throw new Error(response?.data?.regenerateMarksheets?.message || ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
  }

  async function handlePublishResults(formData: FieldValues) {
    setCanClick(false);
    try {
      const response = await publishResults({
        variables: {
          division: selectedDivision,
          examId: selectedExamId,
          publishDate: formData?.publishDate,
        },
      });
      if (response?.data?.publishResults?.status === 'success') {
        if (refetchMarkSheets) refetchMarkSheets();
        setAlertDetails({
          message: ADD_SUCCESS.PUBLISH_RESULTS,
          level: SUCCESS,
        });
        setCanClick(true);
        setPublishResultsModal(false);
      } else {
        throw new Error(response?.data?.publishResults?.message || ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  async function handleUnpublishResults() {
    closeDropDown();
    try {
      const response = await unpublishResults({
        variables: {
          division: selectedDivision,
          examId: selectedExamId,
        },
      });
      if (response?.data?.unPublishResults?.status === 'success') {
        if (refetchMarkSheets) refetchMarkSheets();
        setAlertDetails({
          message: ADD_SUCCESS.UNPUBLISH_RESULTS,
          level: SUCCESS,
        });
      } else {
        throw new Error(response?.data?.unPublishResults?.message || ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
  }

  function closeGenerateResolutionModal() {
    setGenerateResolutionModal(false);
    setGraceType('');
    setSelectedGrace(null);
  }

  function closeGenerateRulesetModal() {
    setGenerateRulesetModal(false);
    setGraceType('');
  }

  async function handleGenerateResolutionModal() {
    setDropDown(false);
    setGraceType('resolution');
    setGenerateResolutionModal(true);
  }

  function closeDropDown() {
    setDropDown(false);
  }

  function handlePublishResultsModal() {
    closeDropDown();
    setPublishResultsModal(true);
  }
  function handleRevalModal() {
    closeDropDown();
    setButton({
      mode: 'Reval',
    });
    setATKTRevalModal(true);
  }
  function handleATKTModal() {
    setButton({
      mode: 'ATKT',
    });
    closeDropDown();
    setATKTRevalModal(true);
  }

  function closeATKTRevalModal() {
    setATKTRevalModal(false);
    reset({});
  }

  async function handleGenerateRuleSetModal() {
    setDropDown(false);
    setGraceType('ruleset');
    setGenerateRulesetModal(true);
  }

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.RESULTS_STUDENTS]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchUsers(student: string) {
    let isClear = student?.length === 0;
    persistSearch(!isClear, student);
  }

  const delayedQuery = useCallback(
    debounce(q => searchUsers(q), SEARCH_TIME),
    [],
  );

  const handleSearch = (student: string) => {
    setSearchingStudent(student);
    delayedQuery(student);
  };

  function closeStudentDetailsModal() {
    setStudentDetailsModal(false);
  }

  function handleSubmitGenerateResolution() {
    setStudentDetailsModal(true);
  }

  async function handleSubmitGenerateRuleset() {
    setCanClick(false);
    const response = await graphqlMutation(generateGraceByRulesetMutation, {
      division: selectedDivision,
      examId: selectedExamId,
    });
    if (response?.data?.generateGraceByRuleSet) {
      setSelectedGrace({ marksheets: response.data.generateGraceByRuleSet });
      setStudentDetailsModal(true);
      setCanClick(true);
    } else {
      setAlertDetails({ message: response, level: ERROR });
      setCanClick(true);
    }
  }

  function handleApplyResolutionConfirmationModal() {
    setApplyResolutionConfirmation(true);
  }

  async function handleApplyGraceByResolution() {
    setCanClick(false);
    try {
      const payload = {
        examId: selectedExamId,
        division: selectedDivision,
        resolution: selectedGrace?.grace,
      };
      const response = await applyGraceByResolution({ variables: payload });
      if (response?.data?.applyGraceByResolution?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.APPLY_GRACE_BY_RESOLUTION, level: SUCCESS });
        handleSuccessGraceByResolutionOrRuleset();
        setCanClick(true);
        if (refetchMarkSheets) refetchMarkSheets();
      } else {
        throw new Error(response?.data?.applyGraceByResolution?.message || ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setApplyResolutionConfirmation(false);
      setCanClick(true);
    }
  }

  const handleEditResultModal = (item: any) => {
    setCurrentItem(item);
    setEditResultModal(true);
  };

  function closeEditResultModal() {
    setEditResultModal(false);
    setCurrentItem(null);
    reset({});
  }

  async function handleEditResult(formData: any) {
    setCanClick(false);
    try {
      const payload = {
        userId: currentItem?.user?.id,
        examId: selectedExamId,
        seatNo: formData.seatNo,
        ...(formData?.enrollmentNo ? { enrollmentNo: formData.enrollmentNo } : {}),
      };
      const response = await editResult({ variables: payload });
      if (response?.data?.editUserDetailsForMarksheet) {
        setAlertDetails({ message: UPDATE_SUCCESS.MARKSHEET_DETAILS, level: SUCCESS });
        closeEditResultModal();
        setCanClick(true);
        if (refetchMarkSheets) refetchMarkSheets();
      } else {
        throw new Error(ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  }

  async function handleApplyGraceByRuleset() {
    setCanClick(false);
    try {
      const payload = {
        examId: selectedExamId,
        division: selectedDivision,
      };
      const response = await applyGraceByRuleset({ variables: payload });
      if (response?.data?.applyGraceByRuleSet?.status === 'success') {
        setAlertDetails({ message: ADD_SUCCESS.APPLY_GRACE_BY_RULESET, level: SUCCESS });
        handleSuccessGraceByResolutionOrRuleset();
        setCanClick(true);
        if (refetchMarkSheets) refetchMarkSheets();
      } else {
        throw new Error(response?.data?.applyGraceByRuleSet?.message || ERROR_MSG.GENERIC_ERROR);
      }
    } catch (e: any) {
      setAlertDetails({ message: e.message, level: ERROR });
      setApplyResolutionConfirmation(false);
      setCanClick(true);
    }
  }

  function handleSuccessGraceByResolutionOrRuleset() {
    setApplyResolutionConfirmation(false);
    setStudentDetailsModal(false);
    if (graceType === 'resolution') {
      setGenerateResolutionModal(false);
    } else {
      setGenerateRulesetModal(false);
    }
    setGraceType('');
    setSelectedGrace(null);
  }

  const exportReport = (url: string, fileName: string) => {
    downloadFileRestAPI(url, fileName, err => {
      setAlertDetails({ message: err?.message || 'Failed to download', level: ERROR });
    });
    setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
    setDropDown(false);
  };

  function closeDropdown() {
    setDropDown(false);
  }

  const handleImportMarkSheetDetails = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      let fileObject = {
        documentName: e.target.files[0]?.name,
        document: e.target.files[0],
      };
      e.target.value = null;
      importMarkSheetDetailsFile(fileObject);
    }
  };

  const importMarkSheetDetailsFile = async (fileObject: documentObject) => {
    if (fileObject && fileObject.document) {
      let importResponse = await importMarkSheetDetails({
        variables: {
          file: fileObject.document,
          division: selectedDivision,
          exam: selectedExamId,
        },
      });

      if (importResponse?.data?.importUserDetailsOfMarksheets) {
        const { status, message } = importResponse.data.importUserDetailsOfMarksheets;
        if (status == 'success') {
          setAlertDetails({
            message: message,
            level: SUCCESS,
          });
          if (refetchMarkSheets) refetchMarkSheets();
        }
        if (status == 'failure') {
          setAlertDetails({
            message: message,
            level: ERROR,
          });
        }
      }
      closeDropdown();
    }
  };

  const handleBlockResult = (userId: string) => {
    setForm({
      state: 'OPEN',
      step: 'BlockResult',
      data: { examId: selectedExamId, userId },
    });
  };

  const handleATKT = async (data: FieldValues) => {
    if (data.dueDate && selectedExamId) {
      try {
        setLoading(true);
        const response = await enableATKT({
          variables: {
            examId: selectedExamId,
            dueDate: data.dueDate,
            name: data.name,
          },
        });

        if (response?.data) {
          setAlertDetails({
            message: ENABLE_SUCCESS.ENABLED_ATKT,
            level: SUCCESS,
          });
          handleATKTRevalStatus(selectedExamId);
        }
      } catch (error) {
        setAlertDetails({
          message: error?.message,
          level: ERROR,
        });
      } finally {
        setLoading(false);
      }
      closeATKTRevalModal();
    }
  };

  const handleRevaluation = async (data: FieldValues) => {
    if (data.dueDate && selectedExamId) {
      try {
        setLoading(true);
        const response = await enableRevalution({
          variables: {
            examId: selectedExamId,
            dueDate: data.dueDate,
            name: data.name,
          },
        });
        if (response?.data) {
          setAlertDetails({
            message: ENABLE_SUCCESS.ENABLED_REVALUTION,
            level: SUCCESS,
          });
          handleATKTRevalStatus(selectedExamId);
        }
      } catch (error) {
        setAlertDetails({
          message: error?.message,
          level: ERROR,
        });
      } finally {
        setLoading(false);
      }
      closeATKTRevalModal();
    }
  };

  const handleATKTRevalFrom = (data: FieldValues) => {
    if (data) {
      if (button.mode == 'ATKT') {
        handleATKT(data);
      } else {
        handleRevaluation(data);
      }
    }
  };

  const handleATKTRevalStatus = async (examId: string) => {
    try {
      const response = await getATKTAndReval({
        variables: {
          examId: examId,
        },
      });
      if (response?.data?.atktAndReval) {
        const { atktExam, revalForm } = response.data.atktAndReval;
        if (revalForm !== null) {
          if (
            (revalForm !== null &&
              atktExam == null &&
              revalForm.status === 'OPEN_FOR_APPLICATION') ||
            revalForm.status === 'IN_PROGRESS'
          ) {
            setCloseReval(true);
            setRevalEnabled(false);
            setATKTEnabled(false);
          }
          if (revalForm !== null && revalForm.status === 'COMPLETED' && atktExam == null) {
            setATKTEnabled(true);
            setRevalEnabled(false);
            setCloseReval(false);
          }
          ///need to remove this check
          else if (revalForm !== null && revalForm.status === 'COMPLETED' && atktExam !== null) {
            setATKTEnabled(true);
            setRevalEnabled(false);
            setCloseReval(false);
          }
        } else if (revalForm == null && atktExam == null) {
          setRevalEnabled(true);
          setATKTEnabled(false);
          setCloseReval(false);
        }
        setRevalData(revalForm);
        setATKTData(atktExam);
      }
    } catch (error) {
      setAlertDetails({
        message: error?.message,
        level: ERROR,
      });
    }
  };

  const handleRevalclose = async () => {
    try {
      if (revalData && revalData.id && selectedExamId) {
        const response = await updateRevalFormStatus({
          variables: {
            revalFormId: revalData.id,
            status: 'COMPLETED',
          },
        });
        if (response?.data) {
          setAlertDetails({
            message: 'Revaluation form closed successfully',
            level: SUCCESS,
          });
          handleATKTRevalStatus(selectedExamId);
        }
      } else {
        setAlertDetails({
          message: 'No revaluation form found',
          level: ERROR,
        });
      }
    } catch (error) {
      setAlertDetails({
        message: error?.message,
        level: ERROR,
      });
    }
  };

  const onCloseForm = () => {
    setForm({ state: 'CLOSE' });
  };

  const onSuccess = (message: string): void => {
    setAlertDetails({ message, level: SUCCESS });
    if (refetchMarkSheets) refetchMarkSheets();
    onCloseForm();
  };

  const onError = (message: string): void => {
    setAlertDetails({ message, level: ERROR });
    onCloseForm();
  };

  const fetchRevalStudents = async () => {
    try {
      const response = await fetchRevalApplications({
        variables: {
          revalForm: revalData?.id,
        },
      });
      setAppliedRevalData(response?.data?.getRevalApplications);
    } catch (error) {
      setAlertDetails({
        message: error?.message,
        level: ERROR,
      });
    }
  };

  const fetchAtktStudents = async () => {
    try {
      const response = await fetchATKTApplications({
        variables: {
          atktExam: ATKTData?.id,
        },
      });
      setAppliedAtktData(response?.data?.getATKTApplications);
    } catch (error) {
      setAlertDetails({
        message: error?.message,
        level: ERROR,
      });
    }
  };

  const handleViewRevalStudents = () => {
    fetchRevalStudents();
    setRevalStudentModal(true);
  };

  const handleViewAtktStudents = () => {
    fetchAtktStudents();
    setAtktStudentModal(true);
  };

  function closeViewAppliedStudents() {
    setAtktStudentModal(false);
  }

  function closeViewAppliedStudentsReval() {
    setRevalStudentModal(false);
  }
  const downloadOptions = [
    {
      key: 'download-user-details',
      value: t('downloadUserDetails.label'),
      onPress: () =>
        exportReport(
          `assesment/${selectedExamId}/${selectedDivision}/userDetails`,
          'user_details.xlsx',
        ),
    },
    ...(marksType ? [{
      key: 'download-consolidate-report',
      value: t('downloadConsolidateReport.label'),
      onPress: () =>
        exportReport(
          `assesment/${selectedExamId}/${selectedDivision}/consolidateReport`,
          'overall_report.xlsx',
        ),
    }] : [
      {
        key: 'download-gazette',
        value: t('downloadGazette.label'),
        onPress: () =>
          exportReport(
            `assesment/${selectedExamId}/${selectedDivision}/reportGazette`,
            'gazette.xlsx',
          ),
      },
      {
        key: 'download-overall-report',
        value: t('downloadOverallReport.label'),
        onPress: () =>
          exportReport(
            `assesment/${selectedExamId}/${selectedDivision}/reportOverall`,
            'overall_report.xlsx',
          ),
      },
    ]),
    {
      key: 'download-marksheets',
      value: t('downloadMarksheets.label'),
      onPress: () =>
        exportReport(
          `assesment/${selectedExamId}/${selectedDivision}/marksheetsPdf`,
          'marksheet.zip',
        ),
    },
  ];

  const getActiveButton = () => {
    if (revalEnabled) {
      return {
        key: 'enable-Reval-form',
        value: t('enableRevaluation.label'),
        onPress: () => handleRevalModal(),
        prefixIcon: faCheckSquare,
      };
    } else if (atktEnabled) {
      return {
        key: 'enable-ATKT-form',
        value: t('enableATKT.label'),
        onPress: () => handleATKTModal(),
        prefixIcon: faCheckSquare,
        disabled: isATKTExam ? true : false,
      };
    } else if (closeReval) {
      return {
        key: 'close-Reval-from',
        value: t('close.Revaluation.label'),
        onPress: () => {
          handleRevalclose();
        },
        prefixIcon: faTimesSquare,
      };
    } else {
      return {
        key: 'enable-Reval-form',
        value: t('enableRevaluation.label'),
        onPress: () => handleRevalModal(),
        prefixIcon: faCheckSquare,
      };
    }
  };
  useEffect(() => {
    setActiveBtn(getActiveButton());
  }, [atktEnabled, revalEnabled, closeReval, selectedExamId,examType]);

  const buttonData = [
    {
      key: 'upload-user-details',
      value: t('uploadUserDetails.label'),
      onPress: closeDropdown,
      onInputPress: handleImportMarkSheetDetails,
      typeInput: true,
      prefixIcon: faUpload,
    },
    {
      key: 'regenerate-marksheets',
      value: t('regenerateMarksheets.label'),
      onPress: () => handleRegenerateMarksheets(),
      prefixIcon: faSyncAlt,
    },
    {
      key: 'publish-results',
      value: t('publishResults.label'),
      onPress: () => handlePublishResultsModal(),
      prefixIcon: faCheckSquare,
    },
    {
      key: 'unpublish-results',
      value: t('unpublishResults.label'),
      onPress: () => handleUnpublishResults(),
      prefixIcon: faTimesSquare,
    },
    // The ATKT and Reval button should be hidden because it is not working properly.
    // activeBtn,
    {
      key: 'reval-application',
      value: t('viewRevalStuents.text'),
      onPress: () => handleViewRevalStudents(),
      prefixIcon: faCheckSquare,
      disabled: !revalData,
    },
    {
      key: 'atkt-application',
      value: t('viewAtktStuents.text'),
      onPress: () => handleViewAtktStudents(),
      prefixIcon: faCheckSquare,
      disabled: !ATKTData,
    },
    {
      key: 'apply-grace-by-resolution',
      value: t('applyGraceByResolution.label'),
      onPress: () => handleGenerateResolutionModal(),
      prefixIcon: faPlusSquare,
    },
    ...(!marksType ? [{
      key: 'apply-grace-by-rule-set',
      value: t('applyGraceByRuleset.label'),
      onPress: () => handleGenerateRuleSetModal(),
      prefixIcon: faPlusSquare,
    }] : []),
    {
      key: 'download',
      value: t('download.label'),
      subOptions: downloadOptions,
      prefixIcon: faDownload,
    },
  ];

  return (
    <LoadContentWrapper>
      <TopRowsWrapper>
        <SelectionWrapper>
          <ResultSelectionForm
            retrieveValues={retrieveValues}
            dataLimit={dataLimit}
            pagePath={'assessment/results'}
            urlBatchId={urlBatchId}
            urlDivision={urlDivision}
            urlExamId={urlExamId}
            setParentBatchId={setSelectedBatchId}
            setParentClassId={setSelectedClassId}
            setParentClassAlias={setSelectedClassAlias}
            setParentDivision={setSelectedDivision}
            setParentSubjectsIds={setSubjectsIds}
            setParentExamId={setSelectedExamId}
            parentHeadCells1={headCells1}
            setParentAllFormValuesAvailable={setAllFormValues}
            isLoading={loadingMarkSheets}
            setExamName={setExamName}
            setMarksType={setMarksType}
            setExamDetails={setExamDetails}
          />
        </SelectionWrapper>

        <GradeCardWrapper>
          <OutlineButton
            label={t('promoteStudents.text')}
            onPress={() => handlePromoteStudentModal(true)}
            width={160}
            customDisabled={array.length === 0}
          />
          <SecondaryBtn
            label={t('updateResults.label')}
            onPress={handleGenerateResults}
            width={140}
            lines={1}
            customDisabled={!selectedExamId}
            canLoad={!updateResultLoader}
          />
          <IconWrapper>
            <IconButton
              aria-describedby={id}
              disabled={
                !recordsAfterPagingAndSorting() || recordsAfterPagingAndSorting()?.length === 0
              }
              onClick={e => handleClick(e, {})}>
              <FontAwesomeIcon icon={faEllipsisV} color={colors.secondaryText} />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}>
              <MenuList>
                {activeBtn &&
                  buttonData.map(button => (
                    <Wrapper key={button.key}>
                      <CustomMenuItem
                        onClick={e => handleClick(e, button)}
                        disabled={button.disabled}>
                        <>
                          <ListItemIcon>
                            <FontAwesomeIcon icon={button.prefixIcon} />
                          </ListItemIcon>
                          <ListItemText primary={button.value} />
                        </>
                        {!!button.subOptions?.length && (
                          <FontAwesomeIcon
                            icon={openSubMenu === button.key ? faCaretUp : faCaretDown}
                          />
                        )}
                      </CustomMenuItem>
                      {button.subOptions && openSubMenu === button.key && (
                        <CustomMenuList>
                          {button.subOptions.map(subOption => (
                            <MenuItem key={subOption.key} onClick={subOption.onPress}>
                              <ListItemIcon>
                                <FontAwesomeIcon icon={subOption.prefixIcon} />
                              </ListItemIcon>
                              <ListItemText primary={subOption.value} />
                            </MenuItem>
                          ))}
                        </CustomMenuList>
                      )}
                    </Wrapper>
                  ))}
              </MenuList>
            </Popover>
          </IconWrapper>
        </GradeCardWrapper>
      </TopRowsWrapper>

      {allFormValues && (
        <SearchWrapper width={width}>
          <Search
            id="resultsStudentSearch"
            label={t('searchStudents.label')}
            value={searching}
            handleChange={handleSearch}
            height={32}
          />
        </SearchWrapper>
      )}

      {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 ? (
        <TblContainer overflowX="scroll" height={height}>
          <TblHead
            checkBox={showCheck}
            checked={array.length === row?.length && array.length != 0}
            handleCheck={handleCheckAll}
            setHeadCells={setHeadCells}
            style={styles().tableHead}
            dynamicHeadCells={marksType ? headCells2 : headCells1}
          />

          <TableBody>
            {recordsAfterPagingAndSorting()?.map((item: any, i: number) => {
              return (
                <TableRows key={`tr${i}`}>
                  {showCheck && (
                    <TableCells style={{ width: 16 }}>
                      <Check
                        same={false}
                        onChange={() => handleCheck(item)}
                        checked={array.some(e => e === item?.user?.id)}
                      />
                    </TableCells>
                  )}

                  <TableCells
                    value={
                      item?.user?.personalDetails?.fullName
                        ? properCase(item.user.personalDetails.fullName)
                        : '-'
                    }
                    color={
                      item?.user?.personalDetails?.fullName
                        ? colors?.primaryColor
                        : colors.primaryText
                    }
                    clickable={item?.user?.personalDetails?.fullName}
                    onPress={() => viewStudent(item?.user?.id)}
                  />

                  <TableCells
                    value={
                      (item &&
                        item?.user &&
                        item?.user?.academic &&
                        item?.user?.academic?.rollNo) ||
                      '-'
                    }
                    align={'left'}
                  />
                  {marksType ? (
                    <TableCells
                      value={getTableValue(item?.marksheet?.averageOnAggregateMarks, '-')}
                      align={'right'}
                      style={{ paddingRight: 80 }}
                    />
                  ) : (
                    <>
                      <TableCells
                        value={getTableValue(item?.marksheet?.creditsEarned, '-')}
                        align={'right'}
                      />

                      <TableCells
                        value={getTableValue(item?.marksheet?.creditGrades, '-')}
                        align={'right'}
                      />

                      <TableCells
                        value={`${getTableValue(item?.marksheet?.sgpa, '-')} ${
                          item?.marksheet?.grade
                            ? `(${item.marksheet.grade})${
                                item?.marksheet?.graceAppliedOn &&
                                item.marksheet.graceAppliedOn?.length
                                  ? '*'
                                  : ''
                              }`
                            : ''
                        }`}
                        align={'right'}
                        color={
                          (item?.marksheet?.sgpa || item?.marksheet?.sgpa === 0) &&
                          item?.marksheet?.failedHeads
                            ? colors.errorColor
                            : colors.primaryText
                        }
                      />

                      <TableCells
                        value={getTableValue(item?.marksheet?.cgpa, '-')}
                        align={'right'}
                        style={{ paddingRight: 80 }}
                      />
                    </>
                  )}

                  {item?.marksheet?.status === 'BLOCK' &&
                  checkRemark(item?.marksheet?.statusActivity, item?.marksheet?.status, remarkRef)
                    ?.exist ? (
                    <TableCells>
                      <RemarkWrapper>
                        <NormaltextAtom
                          value={
                            item?.marksheet?.status
                              ? ResultStatusLabel[item.marksheet.status]
                              : 'N/A'
                          }
                          color={getResultStatusColor(item?.marksheet?.status)}
                        />
                        <ActionIconWrapper>
                          <InfoSquareIcon
                            onPress={() => setRemarkModal(true)}
                            tooltipTitle={t('blockReason.label')}
                          />
                        </ActionIconWrapper>
                      </RemarkWrapper>
                    </TableCells>
                  ) : (
                    <TableCells
                      value={
                        item?.marksheet?.status ? ResultStatusLabel[item.marksheet.status] : '-'
                      }
                      color={getResultStatusColor(item?.marksheet?.status)}
                    />
                  )}

                  {/* Actions */}
                  <TableCells align="right" style={{ position: 'relative' }}>
                    <>
                      <ActionIcons>
                        {(item?.marksheet?.status === 'DRAFT' ||
                          item?.marksheet?.status === 'PUBLISHED') && (
                          <Tooltip title={t('blockResult.label')}>
                            <TouchableOpacity
                              disabled={!selectedExamId}
                              style={styles(!selectedExamId).actionIcon}
                              onPress={() => handleBlockResult(item?.user?.id)}>
                              <FontAwesomeIcon icon={faBan} color={colors.secondaryText} />
                            </TouchableOpacity>
                          </Tooltip>
                        )}
                        {item?.marksheet?.status === 'BLOCK' && (
                          <Tooltip title={t('unblockResult.label')}>
                            <TouchableOpacity
                              disabled={!selectedExamId}
                              style={styles(!selectedExamId).actionIcon}
                              onPress={() => handleUnblockResult(item?.user?.id)}>
                              <Icon name={'unblock'} />
                            </TouchableOpacity>
                          </Tooltip>
                        )}
                        <EditIcon
                          tooltipTitle={t('editMarksheetDetails.label')}
                          onPress={() => handleEditResultModal(item)}
                        />

                        <ClickAwayListener onClickAway={() => setActionIconsDropdown(false)}>
                          <Tooltip title="Result">
                            <TouchableOpacity
                              onPress={() => {
                                setActionIconsDropdown(!actionIconsDropdown);
                                setCurrentId(item?.user?.id);
                              }}
                              style={styles().actionIconsDropdown}>
                              <FontAwesomeIcon
                                icon={faEllipsisV}
                                color={colors.secondaryText}
                                size={16}
                              />
                            </TouchableOpacity>
                          </Tooltip>
                        </ClickAwayListener>
                      </ActionIcons>
                      {actionIconsDropdown && currentId === item?.user?.id && (
                        <ButtonGrp
                          top={40}
                          right={16}
                          width={250}
                          buttonData={[
                            {
                              key: 'viewResult',
                              value: t('view-result.label'),
                              onPress: () => handleViewResult(item),
                              prefixIcon: faInfoSquare,
                            },
                            {
                              key: 'download-result',
                              value: t('downloadResult.label'),
                              onPress: () =>
                                exportReport(
                                  `assesment/${selectedExamId}/${selectedDivision}/${item?.user?.id}/marksheet`,
                                  'Marksheet.pdf',
                                ),
                              prefixIcon: faDownload,
                            },
                          ]}
                        />
                      )}
                    </>
                  </TableCells>
                </TableRows>
              );
            })}
          </TableBody>
        </TblContainer>
      ) : (
        <TblContainer height={maxHeight}>
          {/* "" in ternary else may be utilised to show msg like - Select Batch and Class to continue! */}
          {totalCount == 0 && allFormValues && !loadingMarkSheets ? (
            <NoRecordsFound colspan={8} maxHeight={0.5 * height} />
          ) : (
            ''
          )}
        </TblContainer>
      )}
      <Pagination
        pathName={
          selectedExamId
            ? `assessment/results/${selectedBatchId}/${selectedDivision}/${selectedExamId}`
            : `assessment/results/${selectedBatchId}/${selectedDivision}`
        }
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        setRowLimit={setRowLimit}
        hidePagination={totalCount <= rowLimit}
      />
      {/* Modals */}
      <NormalModal
        setModalVisible={closeViewResultModal}
        Headerpopup={t('results.label')}
        modalVisible={viewResultModalState}
        cancelButtonLabel={'done.label'}
        height={0.7 * height}
        maxWidth={'xl'}
        hideSubmit={false}
        handleSave={() => {}}>
        <ViewResultModal
          closeModal={closeViewResultModal}
          currentItem={currentItem}
          selectedExamName={examName}
          marksType={marksType}
        />
      </NormalModal>

      <NormalModal
        setModalVisible={closeGradeCardModal}
        modalVisible={gradeCardModalState}
        Headerpopup={''}
        cancelButtonLabel={'done.label'}
        addEditButtonLabel={'print.label'}
        width={rem(116)}
        height={rem(772)}
        maxWidth={'lg'}
        handleSave={() => {}}>
        <GradeCardModal
          closeModal={closeGradeCardModal}
          studentIdArray={array}
          selectedBatchId={selectedBatchId}
          selectedClassId={selectedClassId}
          selectedDivision={selectedDivision}
          selectedExamId={selectedExamId}
          totalStudentCount={totalCount}
        />
      </NormalModal>

      {/* ATKTRevalModal */}
      <NormalModal
        setModalVisible={closeATKTRevalModal}
        modalVisible={atktRevalModal}
        Headerpopup={button.mode === 'ATKT' ? t('enableATKT.label') : t('enableRevaluation.label')}
        width={592}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'enable.label'}
        isSubmitting={loading}
        handleSave={handleSubmit(handleATKTRevalFrom)}>
        <ATKTRevalForm control={control} errors={errors} />
      </NormalModal>

      <NormalModal
        setModalVisible={closeViewAppliedStudents}
        modalVisible={atktStudentModal}
        Headerpopup={'Students Applied for ATKT'}
        maxWidth="lg"
        height={0.7 * height}
        showFooter={false}>
        <AtktAppliedStudent
          appliedStudents={appliedAtktData}
          isloading={loadingGetATKTApplications}
        />
      </NormalModal>

      <NormalModal
        setModalVisible={closeViewAppliedStudentsReval}
        modalVisible={revalStudentModal}
        Headerpopup={'Students Applied for Revaluation'}
        maxWidth="lg"
        height={0.7 * height}
        showFooter={false}>
        <RevalAppliedStudent
          appliedStudents={appliedRevalData}
          isloading={loadingGetRevalApplications}
        />
      </NormalModal>

      <NormalModal
        setModalVisible={closePromoteStudentsModal}
        modalVisible={promoteStudentModal}
        Headerpopup={t('promoteStudents.text')}
        width={610}
        showFooter={false}
        handleSave={() => {}}>
        <PromoteStudentForm
          closeModal={closePromoteStudentsModal}
          onSubmit={handlePromoteStudent}
          isSubmitting={!canClick}
          currentBatch={selectedBatchId}
          maintainGrNo={maintainGrNo}
          setMaintainGrNo={setMaintainGrNo}
        />
      </NormalModal>

      <NormalModal
        setModalVisible={closeRemarkModal}
        modalVisible={remarkModal}
        infoModal={true}
        Headerpopup={t('blockReason.label')}
        width={580}
        preventRedirectOnClose={true}
        handleSave={() => null}>
        <BlockRejectionReason data={remarkRef?.current} />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeEditResultModal}
        modalVisible={editResultModal}
        Headerpopup={t('editMarksheetDetails.label')}
        width={610}
        height={270}
        preventRedirectOnClose={true}
        handleSave={handleSubmit(handleEditResult)}
        addEditButtonLabel="save.label">
        <EditResultForm
          control={control}
          errors={errors}
          setValue={setValue}
          editResultData={currentItem}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        modalVisible={publishResultsModal}
        setModalVisible={() => setPublishResultsModal(false)}
        Headerpopup={t('publishResults.label')}
        addEditButtonLabel='confirm.label'
        width={544}
        height={200}
        handleSave={handleSubmit(handlePublishResults)}>
        <PublishResultForm
          control={control}
          errors={errors}
          setValue={setValue}
          exam={examName} />
      </NormalModal>

      <NormalModal
        setModalVisible={closeGenerateResolutionModal}
        modalVisible={generateResolutionModal}
        visibility={!studentDetailsModal}
        Headerpopup={t('applyGraceByResolution.label')}
        height={0.55 * height}
        maxWidth={'lg'}
        preventRedirectOnClose={true}
        handleSave={
          !selectedGrace ? handleSubmitGenerateResolution : handleApplyResolutionConfirmationModal
        }
        addEditButtonLabel={!selectedGrace?.count ? 'next.label' : 'apply.label'}
        disabled={!selectedGrace?.count}>
        <GenerateResolutionModal
          examName={examName}
          examId={selectedExamId}
          division={selectedDivision}
          selectedGrace={selectedGrace}
          setSelectedGrace={setSelectedGrace}
          marksType={marksType}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeGenerateRulesetModal}
        modalVisible={generateRulesetModal}
        visibility={!studentDetailsModal}
        Headerpopup={t('applyGraceByRuleset.label')}
        height={0.7 * height}
        maxWidth={'md'}
        preventRedirectOnClose={true}
        handleSave={handleSubmitGenerateRuleset}
        addEditButtonLabel={'generateGrace.label'}>
        <GenerateRulesetModal />
      </NormalModal>

      <DeleteModal
        isSubmitting={!canClick}
        modalVisible={applyResolutionConfirmation}
        setModalVisible={() => setApplyResolutionConfirmation(false)}
        Headerpopup={t('applyGrace.confirmation.label')}
        width={420}
        handleSave={
          graceType === 'resolution' ? handleApplyGraceByResolution : handleApplyGraceByRuleset
        }
        deleteButtonColor={colors.primaryColor}
        deleteButtonText={'confirm.label'}>
        <ContentWrapper>
          <MediumTextSelect
            value={
              graceType === 'resolution'
                ? t('applyGrace.confirmation.text', {
                    values: { Resolution: selectedGrace?.graceName },
                  })
                : t('applyGraceByRuleset.confirmation.text')
            }
            fontSize={1.7}
          />
        </ContentWrapper>
      </DeleteModal>

      <AlertModalNative
        title={t(`${promotionConfirmationModal.header}`)}
        message1={t(`${promotionConfirmationModal.message}`)}
        onCancel={closePromotionConfirmationModal}
        onConfirm={handleGrNoConfirmation}
        showModal={promotionConfirmationModal.state}
        confirmButtonText={t('confirm.label')}
      />

      {/* Block Result Form */}
      {form.state === 'OPEN' && form.step === 'BlockResult' && (
        <BlockResultForm
          form={form}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onCloseForm}
        />
      )}
    </LoadContentWrapper>
  );
}

const styles = (disabled?: boolean) => ({
  actionIcon: {
    opacity: disabled ? 0.3 : 1,
  },
  actionIconsDropdown: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableHead: {
    position: 'relative',
    zIndex: 3,
  },
});

const TopRowsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 10;
  align-items: flex-start;
`;

const SelectionWrapper = styled.div`
  max-width: ${props => props.theme.rem(73)};
`;

const GradeCardWrapper = styled.div`
  height: 36px;
  display: flex;
  column-gap: 16px;
  margin-top: ${props => props.theme.rem(2)};
`;

const ActionIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 13px;
`;

const RemarkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ActionIconWrapper = styled.div`
  margin-left: 8px;
  padding-bottom: 2px;
`;

const IconWrapper = styled.div`
  align-self: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const SearchWrapper = styled.div<{ width: number }>`
  align-items: flex-start;
  max-width: ${({ theme }) => theme.rem(28.5)};
  margin-right: 8px;
  margin-top: ${props => (props?.width < 1366 ? 24 : 0)}px;
`;

const CustomMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
`;

const CustomMenuList = styled(MenuList)`
  padding: 0 !important;
`;

const Wrapper = styled.div``;
