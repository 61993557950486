import { limit } from "src/constant";
import {
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from "src/components/molecules/Table/TableAtom";
import React, { useEffect, useState } from "react";

import CreateInfoItem from "src/components/molecules/TextComponents/InfoItem";
import { DropdownOptionsType } from "src/types";
import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import Pagination from "src/components/atoms/Pagination/Paginations.web";
import Search from "src/components/atoms/SearchBar/index.web";
import { TableBody } from "@mui/material";
import { escapeRegExp } from "lodash";
import { height } from "src/constant/device";
import styled from "styled-components";
import { useGetAllInvitedCAPApplicants } from "src/graphql/admission/capInvites";
import { useGetBasicAdmissionDetails } from "src/graphql/admission/index";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";
import { useParams } from "src/routes/routing.web";
import useTable from "src/components/molecules/Table/UseTable";
import { format } from "date-fns";
import { DT } from "src/constant/dateTime";
import { properCase } from "src/utils/utility";

const styles = {
  stringTypeCell: { minWidth: "240px" },
  numberTypeCell: { minWidth: "120px" },
  scrollTypeCell: { minWidth: "80px" },
  dateTypeCell: { minWidth: "192px" },
};

const headCells1 = [
  {
    id: "name",
    label: "name.label",
    align: "left",
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: "capID",
    label: "cap-id.label",
    align: "left",
    disableSorting: true,
    style: styles.numberTypeCell,
  },
  {
    id: "email",
    label: "email.label",
    align: "left",
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: "subjectGroup",
    label: "subjectGroup.label",
    align: "left",
    disableSorting: true,
    style: styles.stringTypeCell,
  },
  {
    id: "scrollRefNo",
    label: "Scroll",
    align: "right",
    disableSorting: true,
    style: styles.scrollTypeCell,
  },
  {
    id: "date",
    label: "Date",
    align: "right",
    disableSorting: true,
    style: styles.dateTypeCell,
  },
  {
    id: "invitedBy",
    label: "Invited By",
    align: "left",
    disableSorting: true,
    style: styles.stringTypeCell,
  },
];

type DirectCAPInvited = {
  capId?: string;
  date?: number;
  email?: string;
  invitedBy?: any;
  name?: string;
  scrollRefNo?: string;
  subjectGroup?: string;
};

type AdmissionDataType = {
  id: string;
  name: string;
  batch: DropdownOptionsType;
  subjectGroups: {
    subjectGroup: DropdownOptionsType;
  }[];
};

function CAPInvitesList() {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();

  const { urlAdmissionId, page = 1, dataLimit = limit }: any = useParams();

  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);


  const maxHeight = height - 375;
  const paginationHeightOffset = 24;

  const [headCells, setHeadCells] = useState(headCells1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [fullResponseData, setFullResponseData] =
    useState<DirectCAPInvited[]>();
  const [row, setRow] = useState<DirectCAPInvited[]>();
  const [totalCount, setTotalCount] = useState<number|null>(null);
  const [admissionData, setAdmissionData] = useState<AdmissionDataType>();
  const {
    getAllInvitedCAPApplicants,
    allInvitedCAPApplicantsData,
    allInvitedCAPApplicantsError,
    allInvitedCAPApplicantsLoading,
  } = useGetAllInvitedCAPApplicants();

  const {
    getBasicAdmissionDetails,
    basicAdmissionDetailsData,
    basicAdmissionDetailsLoading,
  } = useGetBasicAdmissionDetails();

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    currentPage,
    null,
    null,
    false,
    true,
    null,
    paginationHeightOffset
  );

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    if (basicAdmissionDetailsData && basicAdmissionDetailsData?.admission) {
      setAdmissionData(basicAdmissionDetailsData.admission);
    }
  }, [basicAdmissionDetailsData]);

  useEffect(() => {
    urlAdmissionId && handleFetch();
  }, [urlAdmissionId, currentPage, rowLimit]);

  useEffect(() => {
    if (fullResponseData) {
      setupPageRows(fullResponseData.length);
    }
  }, [fullResponseData, currentPage, rowLimit]);

  useEffect(() => {
    if (
      allInvitedCAPApplicantsData &&
      allInvitedCAPApplicantsData?.allInvitedCAPApplicants
    ) {
      setFullResponseData(allInvitedCAPApplicantsData.allInvitedCAPApplicants);
      setTotalCount(allInvitedCAPApplicantsData.allInvitedCAPApplicants.length);
    }
  }, [allInvitedCAPApplicantsData]);

  useEffect(() => {
    urlAdmissionId &&
      setHeading([
        {
          text: t("admission.label"),
          url: `/admissions/${urlAdmissionId}/detail/limit/${limit}/page/1`,
        },
        { text: t("cap-invites.label"), url: "" },
      ]);
  }, []);

  function setupPageRows(tc: number) {
    if (fullResponseData && page > 0) {
      const currentLimit: number = parseInt(dataLimit);
      const startIndex: number = (parseInt(currentPage) - 1) * currentLimit;
      const endIndex = Math.min(startIndex + currentLimit, tc);
      const rowsToBeRendered = [];
      for (let i = startIndex; i < endIndex; i++) {
        if (fullResponseData[i]) {
          rowsToBeRendered.push(fullResponseData[i]);
        }
      }
      setRow(rowsToBeRendered);
    }
  }

  function handleSearch(sv: string): void {
    setSearchValue(sv);
    if (fullResponseData) {
      if (sv) {
        const escapedSv = escapeRegExp(sv.toLowerCase()); // Using lodash to escape special characters for email aliases
        const regex = new RegExp(`^${escapedSv}`, "i");
        const filteredData = fullResponseData.filter(
          (obj: DirectCAPInvited) => {
            return (
              (obj?.name && obj.name.toLowerCase().includes(escapedSv)) ||
              (obj?.capId && regex.test(obj.capId.toLowerCase())) ||
              (obj?.email && regex.test(obj.email.toLowerCase()))
            );
          }
        );
        setRow(filteredData);
        setTotalCount(filteredData.length);
      } else {
        // Case:: Search value cleared
        setTotalCount(fullResponseData.length);
        setupPageRows(fullResponseData.length);
      }
    }
  }

  function handleFetch() {
    getBasicAdmissionDetails({ variables: { id: urlAdmissionId } });
    getAllInvitedCAPApplicants({ variables: { admissionID: urlAdmissionId } });
  }

  const itemWidth = "50%";

  return (
    <LoadContentWrapper>
      {!allInvitedCAPApplicantsLoading ? (
        <>
          <InfoWrapper>
            <CreateInfoItem
              label={t("admissionName.label")}
              value={admissionData?.name || "-"}
              width={itemWidth}
            />
            <CreateInfoItem
              label={t("batch.label")}
              value={admissionData ? admissionData?.batch?.label : "-"}
              width={itemWidth}
            />
          </InfoWrapper>
          <TopWrapper>
            <SearchWrapper>
              <Search
                id="capInvitesSearch"
                handleChange={handleSearch}
                value={searchValue}
                label={t("search-cap-invites.label")}
              />
            </SearchWrapper>
          </TopWrapper>
          <TableDiv>
            <TblContainer overflowX="scroll">
              <TblHead setHeadCells={setHeadCells} />
              {totalCount > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map(
                    (item: DirectCAPInvited, index: number) => (
                      <TableRows key={`s${index}`}>
                        <TableCells
                          value={item?.name || "N/A"}
                          style={styles.stringTypeCell}
                        />
                        <TableCells
                          value={item?.capId || "N/A"}
                          style={styles.numberTypeCell}
                        />
                        <TableCells
                          value={item?.email || "N/A"}
                          style={styles.stringTypeCell}
                        />
                        <TableCells
                          value={item?.subjectGroup || "N/A"}
                          style={styles.stringTypeCell}
                        />
                        <TableCells
                          value={item?.scrollRefNo || "N/A"}
                          align={"right"}
                          style={styles.scrollTypeCell}
                        />
                        <TableCells
                          value={
                            item?.date
                              ? format(item?.date, DT.DATE_TIME_SLASH_12_HOUR)
                              : "N/A"
                          }
                          align={"right"}
                          style={styles.dateTypeCell}
                        />
                        <TableCells
                          value={
                            item?.invitedBy && item.invitedBy?.personalDetails
                              ? properCase(item.invitedBy.personalDetails?.fullName ??
                              "-")
                              : "N/A"
                          }
                          style={styles.stringTypeCell}
                        />
                      </TableRows>
                    )
                  )}
                </TableBody>
              ) : (
                <NoRecordsFound
                  colspan={6}
                  maxHeight={maxHeight - paginationHeightOffset}
                />
              )}
            </TblContainer>

            
          
          </TableDiv>
        </>
      ) : (
        <Spinner>
          <LoaderSpinner size='large' />
        </Spinner>
      )}
      
      <Pagination
        pathName={`cap-invitations/${urlAdmissionId}`}
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        hidePagination={!!(totalCount && totalCount <= rowLimit)}
        labelForOfflineSearch={
          searchValue &&
          `Found ${totalCount} result${(totalCount && totalCount > 1) ? "s" : ""}`
        }
      />
    </LoadContentWrapper>
  );
}

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const TopWrapper = styled(FlexRow)`
  justify-content: space-between;
`;

const SearchWrapper = styled(FlexRow)`
  align-items: flex-start;
  max-width: 280px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 16px;
  margin-bottom: 16px;
`;

export default CAPInvitesList;
