import React from 'react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { View, StyleSheet, TouchableOpacity, Animated } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';

import { colors } from 'src/styles/theme/styles';
import { isWeb } from 'src/constant/device';
import styled from 'styled-components/native';

const SwipeCard = (props: any) => {
  const { children, deleteItem, isSwipable = true, refCallback, testID } = props;
  const rightSwipe = (progress: any, dragX: any) => {
    const scale = dragX.interpolate({
      inputRange: [-80, 0],
      outputRange: [1, 0],
      extrapolate: 'clamp',
    });
    return (
      <RightActionView>
        <Animated.View
          style={{
            transform: [{ scale }],
          }}>
          <TouchableOpacity onPress={deleteItem} testID={testID}>
            <View style={styles.deleteBox}>
              <FontAwesomeIcon icon={faTrashAlt} color={colors.white} size={32} />
            </View>
          </TouchableOpacity>
        </Animated.View>
      </RightActionView>
    );
  };

  return (
    <View>
      {!isWeb ? (
        <Swipeable
          ref={refCallback}
          renderRightActions={isSwipable ? rightSwipe : null}
          overshootLeft={isSwipable}
          friction={1.5}
          rightThreshold={40}
          leftThreshold={40}
        >
          <WhiteBGView>{children}</WhiteBGView>
        </Swipeable>
      ) : (
        children
      )}
    </View>
  );
};

export default SwipeCard;

const styles = StyleSheet.create({
  deleteBox: {
    backgroundColor: colors.errorColor,
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: '100%',
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
});

const RightActionView = styled.View`
  background-color: ${colors.errorColor};
  justify-content: center;
  align-items: flex-end;
`;

const WhiteBGView = styled.View`
  background-color: ${colors.white};
`;
