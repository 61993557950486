import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { colors, fonts } from '../../../styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import OutlineButtonWeb from 'src/components/atoms/Button/OutlineButton.web';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import UserReportForm from 'src/components/organism/Reports/UserReportForm';
import { useForm } from 'react-hook-form';
import { userInformation } from 'src/utils/manageState';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { format, startOfToday } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { downloadFileRestAPI } from 'src/utils/utility';
import { ERROR, SUCCESS } from 'src/constant';
import { softenColor, stringToHexColor } from 'src/components/organism/Schedule/scheduleHelpers';

interface Props {
  name: string;
  index: number;
  length: number;
}

export const ReportsItem = (props: Props) => {
  const { rem }: any = useTheme();
  const { t } = useI18n();
  const { name, index, length } = props;
  const currentUserObject = userInformation();
  const { setAlertDetails } = useAlertSystem();
  const instituteId = currentUserObject?.userDetail?.institute;
  const currentDate = format(startOfToday(), DT.DATE_FORMAT_SLASH);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [reportsModal, setReportsModal] = useState<boolean>(false);
  const [reportType, setReportType] = useState<string>('');
  const [reportDetail, setReportDetail] = useState<{ status: string; createdAt: Date } | null>(
    null,
  );

  const regenReport = Boolean(
    reportType == 'Admission Cancellation Report' || reportType == 'Disabled Students Report',
  );

  const onItemClick = (name: string) => {
    setReportsModal(true);
    setReportType(name);
  };

  function closeReportModal() {
    setReportsModal(false);
    setReportType('');
    reset({});
    clearErrors();
    if (reportDetail) setReportDetail(null);
  }

  function handleDownloadReport(downloadUrl: string, fileName: string) {
    downloadFileRestAPI(downloadUrl, fileName, err => {
      setAlertDetails({ message: err.message || 'Failed to download', level: ERROR });
    });
    setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
  }

  function handleDownloadUserReport(formData: any) {
    const batch = formData?.batch?.value;
    const academicYear = formData?.academicYear?.value;
    const date = formData?.date || currentDate;
    const academicType = formData?.academicType?.value;
    let downloadUrl = '';
    let fileName = '';
    switch (reportType) {
      case 'Gender & Caste Report':
        const type = academicType === 'Course wise' ? 'course_vs_caste' : 'class_vs_caste';
        downloadUrl = `report/${instituteId}/${type}?academicYearId=${academicYear}`;
        fileName = 'Gender&CasteReport.xlsx';
        break;
      case 'Usage Report':
        downloadUrl = 'report/usage';
        fileName = 'UsageReport.xlsx';
        break;
      case 'User Report':
        downloadUrl = batch
          ? `students/export/${instituteId}/${formData.batch.value}`
          : 'students/export';
        fileName = batch ? 'UserReportBatch.zip' : 'UserReport.zip';
        break;
      case 'Attendance Report':
        downloadUrl = `report/${instituteId}/attendance?academicYearId=${academicYear}&start=${date}&end=${date}`;
        fileName = 'AttendanceReport.xlsx';
        break;
      case 'Admission Cancellation Report':
        downloadUrl = `report/${instituteId}/cancelAdmissionApplications?academicYearId=${academicYear}`;
        fileName = 'AttendanceReport.xlsx';
        break;
      case 'Disabled Students Report':
        downloadUrl = `report/${instituteId}/handicapReport?academicYearId=${academicYear}`;
        fileName = 'DisabledStudentsReport.xlsx';
        break;
    }
    handleDownloadReport(downloadUrl, fileName);
  }

  const uniqueColor = stringToHexColor(props?.name ?? `${props.index}`);
  return (
    <>
      <ItemContainer style={{ borderBottom: `${index === length - 1 ? 'none' : ''}` }}>
        <ItemBlock>
          <IconHolder>
            <Icon style={{ backgroundColor: softenColor(uniqueColor) }}>
              <FontAwesomeIcon icon={faFileChartLine} color={uniqueColor} />
            </Icon>
          </IconHolder>
          <ContHolder>
            <ItemName>{name}</ItemName>
          </ContHolder>
        </ItemBlock>

        <ItemBlock>
          <OutlineButtonWeb
            text={t('export.label')}
            cb={() => onItemClick(name)}
            style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
          />
        </ItemBlock>
      </ItemContainer>
      <NormalModal
        setModalVisible={closeReportModal}
        modalVisible={reportsModal}
        Headerpopup={t('exportReports.label')}
        hideCloseIcon={true}
        handleSave={handleSubmit(handleDownloadUserReport)}
        height={regenReport ? 254 : 404}
        width={610}
        maxWidth={'sm'}
        addEditButtonLabel="download.label"
        showFooter={!regenReport}
        contentStyles={{ paddingBottom: regenReport ? 24 : 0 }}>
        <UserReportForm
          control={control}
          errors={errors}
          setValue={setValue}
          reportType={reportType}
          handleSubmit={handleSubmit}
          reportDetail={reportDetail}
          setReportDetail={setReportDetail}
          closeReportModal={closeReportModal}
          handleDownloadUserReport={handleDownloadUserReport}
          instituteId={instituteId}
        />
      </NormalModal>
    </>
  );
};

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => theme.rem(0.1)} solid ${colors.inputBorder};
  padding: ${({ theme }) => theme.rem([2.1, 2, 2.1, 0])};
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
`;

const ItemBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconHolder = styled.div``;
const Icon = styled.div`
  background: #e6f5ec;
  border-radius: 50%;
  padding: ${({ theme }) => theme.rem(0.5)};
  width: ${({ theme }) => theme.rem(3.2)};
  height: ${({ theme }) => theme.rem(3.2)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.rem(1.5)};
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.rem(1.7)};
  color: #029d40;
`;
const ContHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemName = styled.text`
  font-size: ${({ theme }) => theme.rem(1.6)};
  line-height: ${({ theme }) => theme.rem(2.4)};
  color: ${colors.primaryText};
  font-family: ${fonts.medium};
`;
