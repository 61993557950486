import { gql } from "@apollo/client/core";

export const atktRevalSettings = gql`
  query {
    institute {
      settings {
        commonFees {
          atkt {
            merchant{
              merchId
              id
              name
            }
            paymentServiceProvider
            subjectWiseFees {
              feeAmount
              max
              min
              applicationFee
            }
          }
          reval {
            merchant{
              merchId
              id
              name
            }
            paymentServiceProvider
            fees {
              amountPerSoftCopy
              applicationFee
              amountPerSubject
              categories{
                alias
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
