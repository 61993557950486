import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Element from '../Forms/ApplicationElement.web';
import casteCategoryFormJSON from '../../../form-json/admission/caste-category.json';
import styled from 'styled-components/native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { DefaultUserSelection } from 'src/constant';

export default function CasteCategoryForm(props: any) {
  const { control, errors, setError, clearErrors, editCategoryData, isEditModal, setValue, isDefaultOption, setIsDefaultOption } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    if (isEditModal) {
      loadEditData();
    } else {
      setElements(casteCategoryFormJSON[0]);
    }
  }, []);

  function loadEditData() {
    prefillData();
    setElements(casteCategoryFormJSON[0]);
  }

  function prefillData() {
    setValue('category_name', editCategoryData?.name);
    setValue('category_alias', editCategoryData?.alias);
    setIsDefaultOption(editCategoryData?.defaultUserSelection === DefaultUserSelection.ALLOWED);
  }

  return (
    <>
      {fields ? (
        fields.map((field: any, i: number) => (
          <View key={`form${i}`}>
            <Element
              key={i}
              field={field}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              checkBoxState={isDefaultOption}
              handleCheckbox={() => setIsDefaultOption((prev: boolean) => !prev)}
            />
          </View>
        ))
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </>
  );
}

const SpinnerView = styled.View`
  height: 208px;
  justify-content: center;
  align-items: center;
`;
