import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  const { color = "#000" } = props;
  return (
    <Svg
      width={24 * scale}
      height={24 * scale}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M12.501 7.502v1.613h-3a1.5 1.5 0 00-1.5 1.5v2.769a1.5 1.5 0 001.5 1.5h3v1.613c0 1.331 1.616 2.006 2.56 1.06l4.5-4.498a1.504 1.504 0 000-2.122l-4.5-4.5c-.941-.935-2.56-.27-2.56 1.065zm6 4.5l-4.5 4.501v-3.116h-4.5v-2.77H14V7.503l4.5 4.5zm-11.5-6h2.625c.206 0 .375.168.375.375v.75a.376.376 0 01-.375.375H7a1.5 1.5 0 00-1.5 1.5v6.001a1.5 1.5 0 001.5 1.5h2.626c.206 0 .375.169.375.375v.75a.376.376 0 01-.375.375H7c-1.656 0-3-1.343-3-3v-6c0-1.657 1.344-3.001 3-3.001z"
        fill={color}
        fillOpacity={0.6}
      />
    </Svg>
  );
}

export default SvgComponent;
