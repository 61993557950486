import { format, parse, startOfMonth } from "date-fns";

import { DT } from "src/constant/dateTime";
import Dropdowns from "../DropDown/index";
import React from "react";
import { generateMonthYearOptions } from "./helpers";

interface MonthYearPickerNative {
  onChangeCallback: (date: Date) => void;
  initialValue?: Date;
  header?: string;
}

export default function MonthYearPickerNative(props: MonthYearPickerNative) {
  const { onChangeCallback, initialValue, header = "" } = props;
  return (
    <Dropdowns
      header={header}
      value={
        initialValue ? format(initialValue, DT.MONTH_NAME_YEAR) : undefined
      }
      hideInputField={true}
      testID={"MonthYearPickerNative"}
      options={generateMonthYearOptions()}
      onChange={(dateString: string) => {
        const parsedDate = parse(dateString, DT.MONTH_NAME_YEAR, new Date());
        const firstDayOfMonth = startOfMonth(parsedDate);
        if (dateString && onChangeCallback) {
          onChangeCallback(firstDayOfMonth);
        }
        // Note: Dates created are in UTC and might seem a day behind due to timezone differences.
        // Timezone conversion during display rectifies this automatically.
      }}
    />
  );
}
