import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { TableBody } from '@mui/material';
import { useHistory } from 'react-router';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import useTable from 'src/components/molecules/Table/UseTable';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'src/routes/routing.web';
import {
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { colors, fonts } from 'src/styles/theme/styles';
import { createSearchFilter, debounce } from 'src/components/services';
import { limit  } from 'src/constant';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { getAcademicYearQuery } from 'src/graphql/academics/years';
import { useHeaderTitle } from 'src/contexts/header-context';
import { height } from 'src/constant/device';
import { Text } from 'react-native';

const headCells = [
  {
    id: 'ay_name',
    label: 'academicYears.label',
    align: 'left',
    disableSorting: true,
    style: {
      width: '45%'
    }
  },
  {
    id: 'ay_alias',
    label: 'alias.label',
    align: 'left',
    disableSorting: true,
  },
];


export default () => {
  const { page = 1, dataLimit = limit, ayid }: any = useParams();

  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  
  const history = useHistory();
  const { setHeading } = useHeaderTitle();

  const yearFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, null);
  const { data: academics, loading: aLoading } = useQuery(
    getAcademicYearQuery,
    { variables: yearFilter },
    );
  const [totalCount, setTotalCount] = useState(academics?.AcademicYears?.totalCount || null);
    
  const { t } = useI18n();

  
  useEffect(() => {
    setTitle();
  }, []);

  async function setTitle() {
    setHeading([
      {
        text: t('overview.label'),
        url: ''
      }
    ]);
  }

  useEffect(() => {
    const count = academics?.AcademicYears?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [academics?.AcademicYears?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  const row = aLoading ? [] : academics?.AcademicYears?.data;

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    currentPage,
  );
  
  return (
    <>
      <LoadContentWrapper>
        {!aLoading ? (
          <>
          <DataWrapper>
            <TableWrapper>
              <TableDiv>
                <TblContainer height={height - 270}>
                <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, index: number) => (
                      <TableRows key={index}>
                        <TableCells
                          value={item.label.toUpperCase()}
                          clickable={true}
                          onPress={() => {
                            history.push(`/payments/overview/${item.value}/limit/50/page/1`);
                          }}
                          color={colors.primaryColor}
                        />
                        <TableCells
                          value={`${item.alias.toLocaleString()}`}
                        />
                      </TableRows>
                    ))}
                  </TableBody>
                  </TblContainer>
               
                 
              
              </TableDiv>
            </TableWrapper>
            {aLoading && <LoaderSpinner styles={{ position: 'absolute', top: '50%', left: '50%' }} />}
            {recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length === 0 && !aLoading &&
              <NoRecords>
                <Text style={{ fontFamily: fonts.regular }}>No records found</Text>
              </NoRecords>
            }
          </DataWrapper>
          </>
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        )}
         <Pagination
          pathName={'payments/overview'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          hidePagination={totalCount <= rowLimit}
        />
      </LoadContentWrapper>
    </>
  );
};

const NoRecords = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  bottom: height - 568;
`;

const SpinnerView = styled.View`
  height: 547;
  justify-content: center;
  align-items: center;
`;

const DataWrapper = styled.View``;

const TableWrapper = styled.View``;
