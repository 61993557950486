import {
  ReactBigCalendarEvent,
  ScheduleAPIData,
  ScheduleDetail,
} from "./scheduleTypes";
import { TimelineListProps, TimelineProps } from "react-native-calendars";
import {
  addDays,
  differenceInMinutes,
  endOfWeek,
  format,
  isAfter,
  isSameMonth,
  isToday,
  isValid,
  parse,
  startOfDay,
  startOfWeek,
  subHours,
} from "date-fns";

import { DT } from "src/constant/dateTime";

// For React Native Calendars
export const makeFetchScheduleQueryVariable = (fetchDateISO: string) => {
  const parsedDate = parse(fetchDateISO, DT.ISO_DATE, new Date());
  const fetchDate = format(parsedDate, DT.DATE_FORMAT_SLASH);

  return {
    input: {
      start: fetchDate,
      end: fetchDate,
      timezone: DT.TIMEZONE,
    },
  };
};

// For React Big Calendar
const getInputFilters = (fetchDate: Date) => ({
  start: format(startOfWeek(fetchDate), DT.DATE_FORMAT_SLASH),
  end: format(endOfWeek(fetchDate), DT.DATE_FORMAT_SLASH),
  timezone: DT.TIMEZONE,
});

export const makeFetchWeeklyScheduleQueryVariable = (fetchDate: Date) => ({
  input: getInputFilters(fetchDate),
});

export const makeWeeklyClassScheduleQueryVariable = (
  fetchDate: Date,
  batch: string,
  division: string
) => ({
  input: {
    batch,
    division,
    ...getInputFilters(fetchDate),
  },
});

/**
 * Combines a date and time into a string format required by react-native-calendars.
 * @param {string} pickedDate - The date in "yyyy-MM-dd" format.
 * @param {string} time - The time in "HH:mm" format.
 */
const getRNCTimeString = (pickedDate: string, time: string): string => {
  return `${pickedDate} ${time}:00`;
};

const createEventsObject = (
  pickedDate: string,
  schedules: ScheduleDetail[]
): TimelineProps["events"] => {
  if (Array.isArray(schedules) && schedules.length > 0) {
    const eventsArray = schedules.map((s: ScheduleDetail) => ({
      id: s.id,
      start: getRNCTimeString(pickedDate, s.startTime),
      end: getRNCTimeString(pickedDate, s.endTime),
      title: `${s.subject?.alias || ""} (${s.subject?.name})`,
      summary: JSON.stringify(s),
      color: softenColor(s.subject?.color || stringToHexColor(s.id)),
    }));
    return eventsArray;
  }
  return [];
};

// For React Native Calendars
export const generateEvents = (
  pickedDate: string,
  apiData: ScheduleAPIData
): TimelineListProps["events"] => {
  const schedules: ScheduleDetail[] = apiData?.[0]?.schedules;
  if (schedules) {
    return {
      [pickedDate]: createEventsObject(pickedDate, schedules),
    };
  }
  return {};
};

// Returns a lightened version of the provided hex color.
// Uses blending with white to achieve the lightened effect.
export const softenColor = (hex: string): string => {
  const blendFactor = 0.9;
  return (
    "#" +
    Array.from({ length: 3 }, (_, i) => {
      const val = parseInt(hex.slice(1 + i * 2, 3 + i * 2), 16);
      const blended = Math.min(
        255,
        Math.max(0, Math.round(val + (255 - val) * blendFactor))
      );
      return blended.toString(16).padStart(2, "0");
    }).join("")
  );
};

export const stringToHexColor = (uniqueID: string): string => {
  let hash = 0;
  for (let i = 0; i < uniqueID.length; i++) {
    hash = (hash << 5) - hash + uniqueID.charCodeAt(i);
  }
  return "#" + ((hash & 0xffffff) + 0x1000000).toString(16).slice(1);
};

export const convertTo12HourFormat = (time24Hour: string): string => {
  const parsedDate = parse(time24Hour, DT.TIME_24_HOUR_WITH_COLON, new Date());
  if (!isValid(parsedDate)) return "";
  return format(parsedDate, DT.TIME_12_HOUR);
};

export const getDurationTimeString = (startTime?: string, endTime?: string) => {
  if (startTime && endTime) {
    return `${convertTo12HourFormat(startTime)} - ${convertTo12HourFormat(
      endTime
    )}`;
  }
};

export const getEventDurationInMinutes = (
  start: string,
  end: string
): number => {
  return Math.abs(
    differenceInMinutes(
      parse(start, DT.TIME_24_HOUR_WITH_COLON, new Date()),
      parse(end, DT.TIME_24_HOUR_WITH_COLON, new Date())
    )
  );
};

export const getSelectedDuration = (pickedDate: Date) => {
  const start = startOfWeek(pickedDate);
  const end = endOfWeek(pickedDate);

  if (isValid(start) && isValid(end)) {
    if (isSameMonth(start, end)) {
      return format(start, DT.ABBREVIATED_MONTH_YEAR);
    }
    return `${format(start, DT.ABBREVIATED_MONTH_YEAR)} - ${format(
      end,
      DT.ABBREVIATED_MONTH_YEAR
    )}`;
  }
};

const getRBCTimeString = (date: string, time: string): Date => {
  return parse(`${date} ${time}`, DT.RBC_CALENDAR_PARSE_FORMAT, new Date());
};

// For React Big Calendar
export const generateWeeklyEvents = (
  data: ScheduleAPIData
): ReactBigCalendarEvent[] => {
  return data.flatMap((dayData) => {
    return dayData.schedules.map((schedule) => ({
      id: schedule.id,
      title: schedule.subject.alias,
      start: getRBCTimeString(dayData.date, schedule.startTime),
      end: getRBCTimeString(dayData.date, schedule.endTime),
      allDay: false,
      resource: schedule,
    }));
  });
};

export const scrollToNow = (inputDate: Date): Date => {
  return inputDate.getHours() === 0
    ? startOfDay(inputDate)
    : subHours(inputDate, 1);
};

export const getWeekHeaderValues = (
  inputDate: Date
): { abbreviatedDay: string; dayOfMonth: string; today: boolean } => {
  const abbreviatedDay = format(
    inputDate,
    DT.DAY_OF_WEEK_ABBREVIATED
  ).toUpperCase();
  const dayOfMonth = format(inputDate, DT.DATE_ONLY);
  const today = isToday(inputDate);
  return { abbreviatedDay, dayOfMonth, today };
};

export const isEventInFuture = (eventStartTime: Date): boolean => {
  return isAfter(eventStartTime, new Date());
};

export const getFormattedLectureDate = (fullDateString: string): string => {
  const parsedDate = parse(
    fullDateString,
    DT.DATE_TIME_FORMAT_WITH_TIMEZONE,
    new Date()
  );
  return format(parsedDate, DT.DATE_FORMAT_SLASH);
};

// Day starts with Monday
export const getWeekDays = () => {
  const days = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(0, 0, i + 1).toLocaleString("en-US", {
      weekday: "long",
    });
    days.push({ value: day.toUpperCase(), label: day });
  }
  return days;
};
