import { ANNOUNCEMENT_TYPE, EMPLOYEE_TYPES } from "src/constant";
import {
  DashboardEmptyContainer as EmptyContainer,
  DemographicsScrollContainer as ScrollContainer,
} from "src/components/organism/Dashboard/Common/CommonHelpers";

import { DashboardListing } from "./CommonHelpers";
import { NoticeItem } from "src/components/atoms/NoticeItem";
import { NoticeItemWithModal } from "src/components/molecules/NoticeList";
import React from "react";
import { faCommentAltDots } from "@fortawesome/pro-light-svg-icons";
import { getAnnouncementsQuery } from "src/graphql/communication/news-notices";
import styled from "styled-components";
import { useI18n } from "src/i18n/hooks";
import { useQuery } from "@apollo/client";
import { userInformation } from "src/utils/manageState";

function DashboardNotices() {
  const { t } = useI18n();
  const { data, loading } = useQuery(getAnnouncementsQuery, {
    variables: { filters: { type: { eq: ANNOUNCEMENT_TYPE[1] } } },
  });
  const currentUserObject = userInformation();
  const isInstituteAdmin = currentUserObject?.userDetail?.type?.includes(
    EMPLOYEE_TYPES.INSTITUTE_ADMIN
  );

  const ListEmptyComponent = () => (
    <EmptyContainer
      text={t("noNotices.text")}
      icon={faCommentAltDots}
      loading={loading}
    />
  );

  const notices = data?.getAnnouncements?.data ?? [];

  return (
    <DashboardListing
      title={"notices.label"}
      redirectionURL={"/communication/notices"}
      allowRedirection={notices.length > 0}
    >
      <ScrollContainer customHeight={isInstituteAdmin ? 42 : 55}>
        {notices.length > 0 ? (
          <>
            <PadGap />
            {notices.map((item) => (
              <NoticeItemWithModal
                children={
                  <NoticeItem
                    title={item?.title}
                    description={item?.body}
                    date={item.mcreatedAt}
                  />
                }
                item={item}
                type="NOTICE"
              />
            ))}
          </>
        ) : (
          <ListEmptyComponent />
        )}
      </ScrollContainer>
    </DashboardListing>
  );
}

const PadGap = styled.div`
  height: 20px;
  width: 100%;
`;

export default DashboardNotices;
