import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { mobileScaling } from 'src/styles/theme/styles';

function SvgComponent(props: any) {
  const scale = mobileScaling || 1;
  return (
    <Svg
      width={16 * scale}
      height={16 * scale}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M12.5 2H9.732a1.75 1.75 0 10-3.464 0H3.5A1.5 1.5 0 002 3.5v11A1.5 1.5 0 003.5 16h9a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0012.5 2zM8 1a.75.75 0 110 1.5A.75.75 0 018 1zm5 13.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H5v.625A.375.375 0 005.375 4h5.25A.375.375 0 0011 3.625V3h1.5a.5.5 0 01.5.5v11zm-3.6-4c-.672.074-.706.25-1.4.25s-.728-.174-1.4-.25c-1.434 0-2.6 1.031-2.6 2.3 0 .794.594 1.2 1.2 1.2h5.6c.606 0 1.2-.406 1.2-1.2 0-1.269-1.166-2.3-2.6-2.3zm1.4 2.5H5.2c-.213 0-.2-.1-.2-.2 0-.717.719-1.3 1.6-1.3.145 0 .576.25 1.4.25s1.255-.25 1.4-.25c.881 0 1.6.583 1.6 1.3 0 .1.013.2-.2.2zM8 10a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0-4a1.5 1.5 0 110 3 1.5 1.5 0 010-3z"
        fill="#000"
        fillOpacity={0.6}
      />
    </Svg>
  );
}

export default SvgComponent;
