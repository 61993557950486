import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { TableBody, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import AddQualificationForm from 'src/components/molecules/Admission/AddQualificationForm';
import CriteriaForm from 'src/components/molecules/Admission/CriteriaForm';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { createSearchFilter, debounce } from 'src/components/services';
import { getCommonNameSearchFilter } from 'src/components/services/filters';
import { AUTOCOMPLETE_MODULE, ERROR, limit, SUCCESS } from 'src/constant';
import { height } from 'src/constant/device';
import { ADD_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { useFilter } from 'src/contexts/filter-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'src/routes/routing.web';
import styled from 'styled-components/native';
import { useGetCriterias, useCreateCriteria } from '../../../graphql/admission/admission-criteria';
import ReqMinMarksForm from 'src/components/molecules/Admission/ReqMinMarks';
import { minAvgMarksInputItemType, minAvgMarksInputStatesType } from './types';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

const headCells1 = [
  {
    id: 'criteria_name',
    label: 'criteriaName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'class_name',
    label: 'className.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
  },
];

export default function Criteria() {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { theme } = useThemeSystem();

  let searchValue = '' as string;
  let row;
  const { page = 1, dataLimit = limit }: any = useParams();
  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const [totalCount, setTotalCount] = useState(null);

  if (filters && filters?.admission_criteria?.on) {
    searchValue = filters?.admission_criteria?.search;
  }

  const [searching, setSearchingCategory] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [canClick, setCanClick] = useState(true);
  const [modalState, handleModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editCriteriaData, setEditCriteriaData] = useState({});
  const [editQualificationData, setEditQualificationData] = useState<any>({});
  const [isEditQualificationModal, setIsEditQualificationModal] = useState(false);
  const [reqMarksModal, handleReqMarksModal] = useState<boolean>(false);
  const [reqMarksData, setReqMarksData] = useState<minAvgMarksInputItemType[]>([]);
  const [qualificationModal, setQualificationModal] = useState(false);
  const [qualificationData, setQualificationData] = useState([]);
  const [streams, setStreams] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [reservationModalState, handleReservationModal] = useState<boolean>(false);
  const [selectedReservationCriteria, setSelectedReservationCriteria] = useState<string>('');
  const [minAvgInputStates, setMinAvgInputStates] = useState(
    new Map<string, minAvgMarksInputStatesType>(),
  );
  let { setAlertDetails } = useAlertSystem();
  const { getCriterias, criteriasData, criteriasError, criteriasLoading, refetchCriterias } =
    useGetCriterias();
  const { createCriteria, createCriteriaData, createCriteriaError } = useCreateCriteria();

  useEffect(() => {
    const count = criteriasData?.admissionCriterias?.totalCount;
    if (count) {
      setTotalCount(count);
    }
  }, [criteriasData?.admissionCriterias?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    setSearchingCategory(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  function setTitle() {
    setHeading([
      {
        text: t('admissionCriteria.label'),
        url: '',
      },
    ]);
  }

  function handleFetch() {
    const filters = getCommonNameSearchFilter(searchValue);
    let criteriaFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);
    getCriterias({ variables: criteriaFilter });
  }

  row = criteriasData?.admissionCriterias?.data;

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    currentPage,
  );

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.ADMISSION_CRITERIA]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchCriteria(criteria: string) {
    let isClear = criteria?.length === 0;
    persistSearch(!isClear, criteria);
  }

  const delayedQuery = useCallback(
    debounce(q => searchCriteria(q), 500),
    [],
  );

  const handleSearch = (criteria: string) => {
    setSearchingCategory(criteria);
    delayedQuery(criteria);
  };

  const closeModal = () => {
    if (isEditModal) {
      setIsEditModal(false);
      setEditCriteriaData({});
    }
    handleModal(false);
    setQualificationData([]);
    setSelectedReservationCriteria('');
  };

  const closeQualificationModal = () => {
    if (isEditQualificationModal) {
      setIsEditQualificationModal(false);
      setEditQualificationData({});
    }
    setQualificationModal(false);
    setStreams([]);
    setDocuments([]);
  };

  function closeReqMinMarksModal() {
    setReqMarksData([]);
    setMinAvgInputStates(new Map<string, minAvgMarksInputStatesType>());
    handleReqMarksModal(false);
  }

  const editCriteriaModal = (selectedCriteria: any) => {
    setEditCriteriaData(selectedCriteria);
    if (selectedCriteria?.qualifications) {
      setQualificationData(selectedCriteria.qualifications);
    }
    hideAlert();
    setIsEditModal(true);
    handleModal(true);
  };

  const addCriteriaModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  function modifyQualificationData() {
    const qualifications = qualificationData.map(item => {
      delete item?.documents;
      const streams = item?.streams?.map((item: any) => {
        return {
          stream: item.stream.id || item.stream,
          ...(item?.majorSubjects && item.majorSubjects?.length > 0
            ? { majorSubjects: item.majorSubjects }
            : {}),
          ...(item?.description && item.description !== ''
            ? { description: item.description }
            : {}),
        };
      });

      if (
        typeof item?.documentTypes[0]?.documentType === 'object' &&
        item?.documentTypes[0]?.documentType !== null
      ) {
        const documents = item?.documentTypes?.map((item: any) => {
          return {
            documentType: item?.documentType?.id || item?.documentType,
            required: item?.required,
          };
        });
        return { ...item, streams: streams, documentTypes: documents, name: item?.name?.value };
      } else {
        return {
          ...item,
          name: item?.name?.value,
          streams: streams,
        };
      }
    });
    return qualifications;
  }

  const handleAddEditCriteria = async (values: any) => {
    setCanClick(false);
    try {
      if (!selectedReservationCriteria) throw new Error(t('error.reservationGroup.required'));
      let qualifications = [] as any;
      if (qualificationData.length > 0) {
        const meritQualification = qualificationData.find((item: any) => item?.required);
        if (!meritQualification || Object.keys(meritQualification)?.length === 0)
          throw new Error(t('error.markMerit.text'));
        qualifications = modifyQualificationData();
      }
      let institutes = [] as any;
      if (values?.institutes) {
        institutes = values?.institutes?.map((item: any) => item?.value);
      }
      const criteriaObject = {
        name: values?.criteriaName,
        reservationCriteria: selectedReservationCriteria,
        class: values?.class?.value,
        ...(institutes && (isEditModal || institutes.length > 0) ? { institutes } : {}),
        ...(qualifications && (isEditModal || qualifications.length > 0) ? { qualifications } : {}),
      };
      if (isEditModal) criteriaObject['id'] = editCriteriaData?.id;

      const response = await createCriteria({
        variables: { payload: criteriaObject },
      });
      if (response.data) {
        closeModal();
        if (refetchCriterias) refetchCriterias();
        setAlertDetails({
          message: isEditModal ? UPDATE_SUCCESS.CRITERIA : ADD_SUCCESS.CRITERIA,
          level: SUCCESS,
        });
      }
      setCanClick(true);
      closeModal();
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleAddEditQualification = async (values: any) => {
    setCanClick(false);
    try {
      if (streams.length > 0) {
        if (documents.length === 0) throw new Error(t('error.documents.required'));
        const index = qualificationData?.findIndex(
          item => item?.name?.label === values?.qualificationName?.label,
        );
        if (index < 0 || isEditQualificationModal) {
          let temp = [...qualificationData];
          const qualificationObject = {
            name: values?.qualificationName,
            required: temp[index]?.required ? temp[index]?.required : false,
            documentTypes: documents,
            streams: streams,
            documents: documents,
            categoryCriteria: editQualificationData.categoryCriteria,
          };
          if (isEditQualificationModal) {
            temp[index] = qualificationObject;
          } else {
            temp.push(qualificationObject);
          }
          setQualificationData(temp);
          setQualificationModal(false);
        } else {
          throw new Error(t('error.qualificationPresent.text'));
        }
        setCanClick(true);
        closeQualificationModal();
      } else {
        throw new Error(t('error.streams.required'));
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  function reqMinMarksOnSubmit() {
    try {
      const minAvgInputStatesArray = Array.from(minAvgInputStates.values());

      if (minAvgInputStates.size < reqMarksData.length) {
        throw new Error('Minimum average marks are required');
      } else if (minAvgInputStatesArray.some(obj => obj.hasError)) {
        throw new Error('Input range is invalid');
      } else {
        const formDataMarksCategory = minAvgInputStatesArray.map(obj => ({
          category: obj.category,
          minAverage: obj.minAverage,
        }));
        const index = qualificationData?.findIndex(
          item => item?.name?.value === editQualificationData.name.value,
        );

        setQualificationData(prevState => {
          const temp = [...prevState];
          temp[index] = { ...temp[index] };
          temp[index]['categoryCriteria'] = formDataMarksCategory;
          return temp;
        });
        closeReqMinMarksModal();
      }
    } catch (error) {
      setAlertDetails({ message: error?.message, level: ERROR });
    }
  }

  return (
    <LoadContentWrapper>
      <FlexRowView>
        <SearchWrapper>
          <Search
            id="criteriasSearch"
            handleChange={handleSearch}
            value={searching}
            label={t('searchCriterias.text')}
          />
        </SearchWrapper>

        <AddCriteriaWrapper>
          <SecondaryBtn label={t('addCriteria.text')} onPress={addCriteriaModal} />
        </AddCriteriaWrapper>
      </FlexRowView>

      <TblContainer height={'100%'}>
        <TblHead setHeadCells={setHeadCells} />
        <>
          {!criteriasLoading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                    <TableRows key={i}>
                      <TableCells value={item?.name} />
                      <TableCells value={item?.class?.label} />

                      <TableActions align="right">
                        <TableActionView>
                          <Tooltip title="Edit Criteria">
                            <TouchableOpacity onPress={() => editCriteriaModal(item)}>
                              <FontAwesomeIcon
                                icon={faPencil}
                                color={theme?.table?.editIconColor}
                              />
                            </TouchableOpacity>
                          </Tooltip>
                        </TableActionView>
                      </TableActions>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>
      <Pagination
        pathName={`admission/criteria`}
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        setRowLimit={setRowLimit}
        hidePagination={!!(totalCount && totalCount <= rowLimit)}
      />

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        modalVisible={modalState}
        Headerpopup={isEditModal ? t('editCriteria.text') : t('addCriteria.text')}
        showFooter={false}
        maxWidth={'lg'}
        visibility={!qualificationModal && !reservationModalState && !reqMarksModal}
        handleSave={() => null}>
        <CriteriaForm
          hideCloseIcon={true}
          closeModal={closeModal}
          isEditModal={isEditModal}
          setQualificationModal={setQualificationModal}
          qualifications={qualificationData}
          setQualificationData={setQualificationData}
          onSubmit={handleAddEditCriteria}
          isSubmitting={!canClick}
          setEditQualificationData={setEditQualificationData}
          setStreams={setStreams}
          setIsEditQualificationModal={setIsEditQualificationModal}
          editCriteriaData={editCriteriaData}
          setDocuments={setDocuments}
          selectedReservationCriteria={selectedReservationCriteria}
          setSelectedReservationCriteria={setSelectedReservationCriteria}
          reservationModalState={reservationModalState}
          handleReservationModal={handleReservationModal}
          reqMarksData={reqMarksData}
          setReqMarksData={setReqMarksData}
          handleReqMarksModal={handleReqMarksModal}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeQualificationModal}
        modalVisible={qualificationModal}
        Headerpopup={isEditQualificationModal ? t('editQualification.text') : 'Add Qualification'}
        showFooter={false}
        handleSave={() => null}>
        <AddQualificationForm
          setStreams={setStreams}
          onSubmit={handleAddEditQualification}
          closeQualificationModal={closeQualificationModal}
          streams={streams}
          documents={documents}
          isEditQualificationModal={isEditQualificationModal}
          editQualificationData={editQualificationData}
          setDocuments={setDocuments}
        />
      </NormalModal>

      <NormalModal
        setModalVisible={closeReqMinMarksModal}
        modalVisible={reqMarksModal}
        Headerpopup={t('edit-min-avg-marks.label')}
        width={794}
        maxWidth={'lg'}
        handleSave={reqMinMarksOnSubmit}>
        <ReqMinMarksForm
          reqMarksData={reqMarksData}
          minAvgInputStates={minAvgInputStates}
          setMinAvgInputStates={setMinAvgInputStates}
        />
      </NormalModal>
    </LoadContentWrapper>
  );
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 10;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  max-width: 280px;
  margin-right: 8px;
`;

const AddCriteriaWrapper = styled.View`
  flex: 1;
  align-items: flex-end;
  max-width: 140px;
  min-width: 112px;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
