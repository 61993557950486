import {
  AccountObject,
  AccountsDataType,
  configureAccountStorageAndNavigate,
  createAccountDetailsObj,
} from "./LoginHelpers";
import React, { useState } from "react";
import { isNative, isWeb } from "src/constant/device";

import { SUCCESS } from "src/constant";
import UserLoginForm from "./UserLoginForm";
import { decodeToken } from "src/graphql/common";
import { fcmTokenInitialized } from "src/utils/manageState";
import navigationStrings from "src/navigation/navigationStrings";
import { setAccountsToStorage } from "src/components/services/storage-service";
import { showMessage } from "src/components/molecules/NotificationWrapper";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useHistory } from "src/routes/routing";
import { useLoginUser } from "src/graphql/login";
import { useParams } from "react-router-dom";
import { getApplicationPath } from "src/utils/utility";

interface UserLoginProps {
  authCallback?: () => void;
  navigation?: any;
}

export default function UserLogin(props: UserLoginProps) {
  const history = useHistory();
  const [canClick, setCanClick] = useState(true);
  const { setAlertDetails } = useAlertSystem();
  const { authCallback, navigation } = props;
  const { loginUserMutation } = useLoginUser();
  const params: { prefix?: string, requestId?: string, capId?: string, inviteCode?: string } = isWeb ? useParams() : {};
  const { prefix, requestId, capId, inviteCode } = params;

  const loginUser = async (logindata: any) => {
    setCanClick(false);
    try {
      fcmTokenInitialized(false);
      let accountsData: AccountsDataType[] = [];
      const resp = await loginUserMutation({
        variables: {
          userName: logindata.userName,
          password: logindata.password,
        },
      });
      if (resp && resp?.data) {
        if (resp.data?.loginUser?.length > 0) {
          accountsData = resp.data.loginUser;
        }
        await setAccountsToStorage(accountsData);

        if (accountsData.length === 1) {
          // Single Account Linked to Email
          const decodedDetails = decodeToken(accountsData[0].token);
          const accountLinkedToEmail: AccountObject = createAccountDetailsObj(
            accountsData[0],
            decodedDetails
          );
          if (isWeb) {
            setAlertDetails({
              message: "Logged in successfully",
              level: SUCCESS,
            });
          }
          configureAccountStorageAndNavigate(
            accountLinkedToEmail,
            history,
            authCallback,
            decodedDetails?.userType,
            prefix,
            requestId,
            capId,
            inviteCode,
          );
        } else {
          // Multiple Accounts Linked to Email
          if (isWeb) {
            let path = '/account-selection';
            history.push(getApplicationPath(path, prefix, requestId, capId, inviteCode));
          } else {
            navigation.navigate(navigationStrings.MULTI_ACCOUNT, {
              from: "Login",
            });
          }
        }
      } else {
        // API failed
        throw new Error("API failed");
      }
    } catch (e: any) {
      if (isWeb) {
        setAlertDetails({
          message: e.message || "Invalid credentials",
          level: "error",
        });
      } else {
        showMessage({
          message: e.message || "Invalid credentials",
          type: "danger",
          position: "top",
          icon: "danger",
        });
      }

      setCanClick(true);
    }
  };

  return (
    <UserLoginForm
      loginUser={loginUser}
      canClick={canClick}
      navigation={isNative && navigation}
    />
  );
}
