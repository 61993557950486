import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { View, StyleSheet, useWindowDimensions, Dimensions, EmitterSubscription } from 'react-native';
import SwipeSwitch from 'src/components/atoms/Switch/Swipe';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import MobileHeader from 'src/components/hoc/MobileHeader';
import AssignmentCard from 'src/components/molecules/AssignmentCard/Student/Assignment';
import { height, isIOS, isNative, isWeb, width } from 'src/constant/device';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useI18n } from 'src/i18n/hooks';
import { breakpoints, colors, fonts } from 'src/styles/theme/styles';
import {
  getPendingAssignmentsQuery,
  getSubmittedAssignmentsQuery,
} from 'src/graphql/communication/assignments';
import { useQuery } from '@apollo/client';
import { faBackpack } from '@fortawesome/pro-regular-svg-icons';
import Listing from 'src/components/molecules/CommunicationListing';
import { usePersistStorage } from 'react-native-use-persist-storage';
import { KEYS } from 'src/constant/key';
import navigationStrings from 'src/navigation/navigationStrings';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import Search from 'src/components/atoms/SearchBar/index';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
  useHistory,
} from 'src/routes/routing';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index';
import styled from 'styled-components/native';
import { ASSIGNMENT_LIMIT, FILTERS } from 'src/constant';
import { debounce } from 'lodash';
import { createSearchFilter } from 'src/components/services';
import NoData from 'src/components/molecules/NoData';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { DT } from 'src/constant/dateTime';
import AbstractFlashList from 'src/components/atoms/FlashList';

let staticParam = {
  params: {
    assignmentType: '',
  },
};

export default function StudentAssignments(props: any) {
  var totalComplete = 0;
  var totalPending = 0;
  const { t } = useI18n();
  const [assignmentIndex, setAssignmentIndex] = usePersistStorage<any>(KEYS.ASSIGNMENT_INDEX, null);
  const [assignmentSearch, setAssignmentSearch] = usePersistStorage<any>(
    KEYS.ASSIGNMENT_SEARCH,
    null,
  );
  const { setHeading } = useHeaderTitle();
  const [refreshing, setRefreshing] = useState(false);
  const [windowWidth, setwindowWidth] = useState(useWindowDimensions().width);
  const [searchText, setSearchText] = useState('');
  const isDesktop = width > breakpoints.md; //desk
  const [pendingSkip, setPendingSkip] = useState<number>(0);
  const [completeSkip, setCompleteSkip] = useState<number>(0);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [doRefetch, setDoRefetch] = useState(false);

  const history = useHistory();
  // to check if assignment is submitted in order to trigger a refetch :: WEB
  let isAssignSubmitted = history?.location?.state?.submitted || false;
  const { params } = isNative ? props?.route : staticParam;
  const match = isWeb ? useRouteMatch() : '';
  let location = isWeb ? useLocation() : '';
  const isPending = location && location.pathname.includes('pending');
  const { assignmentType } = params;
  const assignmentTab = assignmentType === 'pending' ? 1 : 2;

  const pendingRef = useRef<any>(null);
  const completeRef = useRef<any>(null);

  useEffect(() => {
    setTitle();
  }, []);

  // Web:: Check if assignment (from state inside history) is submitted 
  // to trigger refetch for pending and completed tabs
  useEffect(() => {
    if (isAssignSubmitted) {
      tabsRefresh()
    }
  }, [isAssignSubmitted]);

  let pendingAssignments = [] as any;
  const {
    data: pendingAssignmentData,
    loading: pendingAssignmentsLoading,
    refetch: pendingAssignmentRefetch,
    fetchMore: fetchPendingMore,
  } = useQuery(getPendingAssignmentsQuery, {
    variables: { limit: ASSIGNMENT_LIMIT, skip: 0, filters: {}, timezone: DT.TIMEZONE },
    fetchPolicy: 'network-only'
  });

  pendingAssignments = pendingAssignmentData?.getPendingAssignments?.data;
  totalPending = pendingAssignmentData?.getPendingAssignments?.totalCount;

  let completeAssignments = [] as any;
  const {
    data: completeAssignmentData,
    loading: completeAssignmentsLoading,
    refetch: completeAssignmentsRefretch,
    fetchMore: fetchCompleteMore,
  } = useQuery(getSubmittedAssignmentsQuery, {
    variables: { limit: ASSIGNMENT_LIMIT, skip: 0, filters: {}, timezone: DT.TIMEZONE },
    fetchPolicy: 'network-only'
  });
  completeAssignments = completeAssignmentData?.getSubmittedAssignments?.data;
  totalComplete = completeAssignmentData?.getSubmittedAssignments?.totalCount;

  function showAssignmentDetail(assignment: any, idx: number) {
    if (isWeb) {
      if (isPending) {
        setAssignmentIndex(idx);
      }
      let paramType = isPending ? 'pending' : 'complete';
      history.push(`/assignments/${paramType}/${assignment?.id}/detail`);
    } else {
      if (setDoRefetch) setDoRefetch(false)
      props.navigation.navigate(navigationStrings.NATIVE_ASSIGNMENT_DETAIL, {
        assignmentId: assignment?.id,
        setDoRefetch
      });
    }
  }

  useEffect(() => {
    if (doRefetch) {
      tabsRefresh()
    }
  }, [doRefetch])

  function headingSetter(heading: string) {
    setHeading([
      {
        text: t(heading),
        url: '',
      },
    ]);
  }

  function setTitle() {
    if (isWeb) {
      headingSetter('assignments.label');
    }
  }

  function onRefresh() {
    setRefreshing(true);
    nativeRefresh();
    setRefreshing(false);
  }

  function nativeRefresh() {
    if (assignmentType === 'complete') {
      setCompleteSkip(0);
      completeAssignmentsRefretch();
    } else {
      setPendingSkip(0);
      pendingAssignmentRefetch();
    }
  }

  function tabsRefresh() {
    setCompleteSkip(0);
    completeAssignmentsRefretch();
    setPendingSkip(0);
    pendingAssignmentRefetch();
  }

  useEffect(() => {
    const subscription: EmitterSubscription = Dimensions.addEventListener("change", (e) => {
      setwindowWidth(e.window.width);
    });
    return () => subscription.remove();
  }, []);

  function changeTabs(tab: any) {
    setSearchText('');
    if (tab === 1) {
      if (completeRef.current) {
        completeRef.current.scrollToIndex({ animated: true, index: 0 });
      }
      history.push('/assignments/pending');
    } else {
      if (pendingRef.current) {
        pendingRef.current.scrollToIndex({ animated: true, index: 0 });
      }
      history.push('/assignments/complete');
    }
  }

  function handleSwitching(t: number) {
    let passParam = t === 1 ? 'pending' : 'complete';
    props.navigation.navigate(navigationStrings.NATIVE_TABBAR_ASSIGNMENTS, {
      assignmentType: passParam,
    });
  }

  async function handleSearch(searchText: string, type: number) {
    let filters = [
      {
        field: 'title',
        type: FILTERS.REGEX,
        value: searchText,
      },
      {
        field: 'description',
        type: FILTERS.REGEX,
        value: searchText,
      },
    ];

    let skipType = type === 1 ? pendingSkip : completeSkip;
    let assignmentFilter = createSearchFilter(ASSIGNMENT_LIMIT, skipType, filters);

    try {
      setRefreshing(true);
      if (type === 1) {
        setPendingSkip(0);
        setAssignmentSearch(searchText);
        await pendingAssignmentRefetch(assignmentFilter);
      } else {
        setCompleteSkip(0);
        await completeAssignmentsRefretch(assignmentFilter);
      }
    } catch (error) {
      console.log('Error while refetching data');
    }
  }

  const debouncedSearch = useCallback(debounce(handleSearch, 500), []);

  function handleCompletePagination() {
    if (totalComplete > completeAssignments.length) {
      setFetchMoreLoading(true);
      setCompleteSkip(completeSkip + ASSIGNMENT_LIMIT);
      handlePagination(
        getSubmittedAssignmentsQuery,
        ASSIGNMENT_LIMIT,
        completeSkip + ASSIGNMENT_LIMIT,
        fetchCompleteMore,
        'getSubmittedAssignments',
      );
    }
  }

  function handlePendingPagination() {
    if (totalPending > pendingAssignments.length) {
      setFetchMoreLoading(true);
      setPendingSkip(pendingSkip + ASSIGNMENT_LIMIT);
      handlePagination(
        getPendingAssignmentsQuery,
        ASSIGNMENT_LIMIT,
        pendingSkip + ASSIGNMENT_LIMIT,
        fetchPendingMore,
        'getPendingAssignments',
      );
    }
  }

  function handlePagination(
    paginateQuery: any,
    assignmentLimit: number,
    assigmentSkip: number,
    fetchFunction: any,
    fetchKey: string,
  ) {
    let filters = [
      {
        field: 'title',
        type: FILTERS.REGEX,
        value: searchText,
      },
      {
        field: 'description',
        type: FILTERS.REGEX,
        value: searchText,
      },
    ];

    let assignmentFilter = createSearchFilter(assignmentLimit, assigmentSkip, filters);

    try {
      fetchFunction({
        query: paginateQuery,
        variables: assignmentFilter,
        updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
          setFetchMoreLoading(fetchMoreResult[fetchKey].data ? false : true);
          return {
            [fetchKey]: {
              hasNextPage: fetchMoreResult[fetchKey].hasNextPage,
              data: [...previousResult[fetchKey].data, ...fetchMoreResult[fetchKey].data],
              totalCount: fetchMoreResult[fetchKey].totalCount,
              __typename: fetchMoreResult[fetchKey].__typename,
            },
          };
        },
      });
    } catch (error: any) {
      console.log(error.message);
    }
  }

  return (
    <Fragment>
      {isWeb && windowWidth > breakpoints.md ? (
        <Fragment>
          {!pendingAssignmentsLoading && !completeAssignmentsLoading ? (
            <LoadContentWrapper>
              {/* Desktop web */}
              <ModalTabs
                onSelectSwitch={(t) => {
                  changeTabs(t);
                }}
                option1={t('pending.label')}
                option2={t('completed.label')}
                selectionMode={isPending ? 1 : 2}
                roundCorner={false}
                style={[styles.tabStyle]}
                onChange={changeTabs}
                tabswidth={100}
              />

              <Switch>
                <Route path={`${match.url}/pending`}>
                  <>
                    {/* SEARCH BAR */}
                    {pendingAssignments?.length > 0 ||
                      (pendingAssignments?.length < 1 && searchText !== '') ||
                      (pendingAssignments?.length < 1 && refreshing) ? (
                      <FlexRowView>
                        <LeftPartView>
                          <Search
                            id="Search"
                            handleChange={async (e) => {
                              setSearchText(e);
                              await debouncedSearch(e, 1);
                            }}
                            value={searchText}
                            label={t('searchAssignments.text')}
                          />
                        </LeftPartView>
                      </FlexRowView>
                    ) : null}

                    {/* NO SEARCH DATA */}
                    {pendingAssignments?.length < 1 && searchText !== '' && (
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%'
                        }}>
                        <NormaltextAtom value={t('noResultFound.text')} />
                      </View>
                    )}

                    {/* LIST*/}
                    {pendingAssignments?.length > 0 && (
                      <>
                        <AbstractFlashList
                          estimatedItemSize={160}
                          ref={pendingRef}
                          onEndReached={handlePendingPagination}
                          onEndReachedThreshold={0.5}
                          data={pendingAssignments}
                          children={({ item, index }: any) => (
                            <AssignmentCard
                              key={`assign${item?.id}`}
                              data={item}
                              indexKey={index}
                              onPress={showAssignmentDetail}
                              assignmentType={'pending'}
                              isSwipable={false} />
                          )}
                        />
                        {fetchMoreLoading ? (
                          <BottomSpinnerView>
                            <LoaderSpinner />
                          </BottomSpinnerView>
                        ) : null}
                      </>
                    )}
                  </>

                  {/* NO WHOLE DATA */}
                  {searchText === '' && !refreshing && pendingAssignments?.length < 1 && (
                    <NoDataContainer>
                      <NoData
                        icon={faBackpack}
                        text1={t('noPendingAssignments.text')}
                        text2={t('nothingAssigned.text')}
                      />
                    </NoDataContainer>
                  )}
                </Route>

                <Route path={`${match.url}/complete`}>
                  <>
                    {/* SEARCH BAR */}
                    {completeAssignments?.length > 0 ||
                      (completeAssignments?.length < 1 && searchText !== '') ||
                      (completeAssignments?.length < 1 && refreshing) ? (
                      <FlexRowView>
                        <LeftPartView>
                          <Search
                            id="Search"
                            handleChange={async (e) => {
                              setSearchText(e);
                              await debouncedSearch(e, 2);
                            }}
                            value={searchText}
                            label={t('searchAssignments.text')}
                          />
                        </LeftPartView>
                      </FlexRowView>
                    ) : null}

                    {/* NO SEARCH DATA */}
                    {completeAssignments?.length < 1 && searchText !== '' && (
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%'
                        }}>
                        <NormaltextAtom value={t('noResultFound.text')} />
                      </View>
                    )}

                    {/* LIST*/}
                    {completeAssignments?.length > 0 && (
                      <>
                        <AbstractFlashList
                          ref={completeRef}
                          estimatedItemSize={155}
                          onEndReached={handleCompletePagination}
                          onEndReachedThreshold={0.5}
                          data={completeAssignments}
                          children={({ item, index }: any) => (
                            <AssignmentCard
                              key={`assign${item?.id}`}
                              indexKey={index}
                              data={item}
                              onPress={showAssignmentDetail}
                              assignmentType={'complete'}
                              isSwipable={false} />
                          )}
                        />
                        {fetchMoreLoading ? (
                          <BottomSpinnerView>
                            <LoaderSpinner />
                          </BottomSpinnerView>
                        ) : null}
                      </>
                    )}
                  </>

                  {/* NO WHOLE DATA */}
                  {searchText === '' && !refreshing && completeAssignments?.length < 1 && (
                    <NoDataContainer>
                      <NoData
                        icon={faBackpack}
                        text1={t('noCompletedAssignments.text')}
                        text2={t('nothingAssigned.text')}
                      />
                    </NoDataContainer>
                  )}
                </Route>
                <Route exact path={`${match.url}`}>
                  <Redirect to={`${match.url}/pending`} />
                </Route>
              </Switch>
            </LoadContentWrapper>
          ) : (
            <SpinnerView
              style={{ backgroundColor: isDesktop ? colors.bluishGreyBackground : colors.white }}>
              <LoaderSpinner />
            </SpinnerView>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <MobileHeader label={t('assignments.label')} backIcon={false} {...props} />
          {!pendingAssignmentsLoading && !completeAssignmentsLoading ? (
            <WebWrapper>
              <SwitchView>
                <SwipeSwitch
                  onSelectSwitch={isWeb ? (t) => changeTabs(t) : (t) => handleSwitching(t)}
                  option1={t('pending.label')}
                  option2={t('completed.label')}
                  selectionMode={isWeb ? (isPending ? 1 : 2) : assignmentTab}
                  onChange={isWeb ? (t) => changeTabs(t) : (t) => handleSwitching(t)}
                />
              </SwitchView>
              <>
                {assignmentType === 'pending' && (
                  <Listing
                    estimatedItemSize={156}
                    passRef={pendingRef}
                    onEndReached={handlePendingPagination}
                    onEndReachedThreshold={0.5}
                    Comp={AssignmentCard}
                    arrayList={pendingAssignments}
                    icon={faBackpack}
                    label={t('noPendingAssignments.text')}
                    onPress={showAssignmentDetail}
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    isSwipable={false}
                    fetchMoreLoading={fetchMoreLoading}
                    heightOffset={isIOS ? 262 : 200}
                  />
                )}

                {assignmentType === 'complete' && (
                  <Listing
                    estimatedItemSize={156}
                    passRef={completeRef}
                    onEndReached={handleCompletePagination}
                    onEndReachedThreshold={0.8}
                    Comp={AssignmentCard}
                    arrayList={completeAssignments}
                    icon={faBackpack}
                    label={t('noCompletedAssignments.text')}
                    onPress={showAssignmentDetail}
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                    isSwipable={false}
                    customStyle={{
                      marginBottom: 80,
                    }}
                    fetchMoreLoading={fetchMoreLoading}
                    heightOffset={isIOS ? 262 : 200}
                  />
                )}
              </>
            </WebWrapper>
          ) : (
            <SpinnerView>
              <LoaderSpinner />
            </SpinnerView>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  tabStyle: {
    top: 0,
    left: 0,
    backgroundColor: colors.white,
    marginBottom: 24,
  },
});

const WebWrapper = styled.View`
  height: 100%;
  background-color: #ffffff;
  padding-top: 24px;
  padding-bottom: ${isIOS ? 50 : 62}px;
`;

const SwitchView = styled.View`
  margin-bottom: 12.5px;
  padding-left: 24px;
  padding-right: 24px;
`;

const FlexRowView = styled.View`
  flex-direction: row;
`;

const LeftPartView = styled.View`
  flex: 3;
  align-items: flex-start;
`;

const SpinnerView = styled.View`
  height: ${height - 100}px;
  justify-content: center;
  align-items: center;
`;

const NoDataContainer = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const BottomSpinnerView = styled.View`
  height: 30px;
  justify-content: center;
  align-items: center;
`;
