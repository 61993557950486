import React from "react";
import { Stepper, Step, StepLabel }  from '@mui/material';
import NormaltextAtom from "../Text/NormalTextAtom";
import IndicatorBadge from "../Badge/IndicatorBadge";
import styled from "styled-components";
import { width } from "src/constant/device";

interface PropType {
  statusActivity: {
    timestamp: string;
    label: string;
    color: string;
  }[];
}

const StepperTimeline: React.FC<PropType> = (props) => {
  const { statusActivity = [] } = props;

  return (
    <Container>
      <Stepper alternativeLabel>
        {statusActivity.map((elem, index) => (
          <Step key={`${index}`}>
            <StepLabel
              StepIconComponent={(stepProps) => (
                <ItemWrapper>
                  <IndicatorBadge diameter={16} color={elem.color} />
                  <NormaltextAtom
                    value={elem.label}
                    lines={1}
                    color={elem.color}
                  />
                  <NormaltextAtom value={elem.timestamp} lines={1} />
                </ItemWrapper>
              )}
            />
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

const Container = styled.div`
  max-width: ${0.99 * width};
  overflow-x: auto;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6px;
  margin-top: 4px;
`;

export default StepperTimeline;
