import React, { useState } from 'react';
import { useHistory } from 'src/routes/routing';
import { useLoginApplicant, useLoginInstituteUser } from 'src/graphql/login/index';
import { isWeb, isNative } from 'src/constant/device';
import { fcmTokenInitialized } from 'src/utils/manageState';
import { showMessage } from 'src/components/molecules/NotificationWrapper';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import Login from 'src/components/organism/Login';
import { setAuthData } from 'src/utils/utility';
import { USER_TYPE_ENUM } from 'src/constant';
import { useI18n } from 'src/i18n/hooks';

export const InstituteUserLogin = (props: any) => {
  const history = useHistory();
  const [canClick, setCanClick] = useState(true);
  const { loginInstituteUser } = useLoginInstituteUser();
  const { loginApplicant } = useLoginApplicant()
  const { setAlertDetails } = useAlertSystem();
  const { userType, authCallback, navigation } = props;
  const { t } = useI18n();

  const loginUser = async (logindata: any) => {
    setCanClick(false);
    try {
      fcmTokenInitialized(false);
      if (userType === USER_TYPE_ENUM[3]) {
        const user = await loginApplicant({
          variables: { credential: logindata },
        });

        if (user && user.data) {
          const data = user.data;
          if (data.applicantLogin && data.applicantLogin.token) {
            setAuthData(
              data.applicantLogin,
              history,
              props.location,
              authCallback
            );
            setCanClick(true);
          }
        }
      } else {
        logindata.userType = userType;
        logindata.version = "1.0.1";
        const creds = { variables: { credential: logindata } };
        const user = await loginInstituteUser(creds);

        if (user && user.data) {
          const data = user.data;
          if (data.loginInstituteUser && data.loginInstituteUser.token) {
            setAuthData(
              data.loginInstituteUser,
              history,
              props.location,
              authCallback
            );
            setCanClick(true);
          }
        }
      }
    } catch (e: any) {
      if (isWeb) {
        setAlertDetails({ message: e.message || 'Invalid credentials', level: 'error' });
      } else {
        showMessage({
          message: e.message || 'Invalid credentials',
          type: 'danger',
          position: 'top',
          icon: 'danger',
        });
      }

      setCanClick(true);
    }
  };

  const getGreeting = (userType: string) => {
    switch (userType) {
      case USER_TYPE_ENUM[1]:
        return t('greetEmployee.label');
      case USER_TYPE_ENUM[2]:
        return t('greetStudent.label');
      case USER_TYPE_ENUM[3]:
        return t('greetApplicant.label');
      default:
        return "";
    }
  }

  return (
    <Login
      title={getGreeting(userType)}
      userType={userType}
      loginUser={loginUser}
      canClick={canClick}
      navigation={isNative && navigation}
    />
  );
};