import { ADD_SUCCESS, UPDATE_SUCCESS } from "src/constant/message";
import { FieldValues, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  documentsFormState as TForm,
  AdminDocumentsHelpers as helpers,
} from "src/components/organism/Admission/Admin/Documents/DocumentsHelpers";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { TFormProps } from "src/types";
import formJSON from "src/form-json/admission/admin/documents-form.json";
import styled from "styled-components";
import { useCreateDocument } from "src/graphql/admission/document-type";
import { useI18n } from "src/i18n/hooks";
import { DefaultUserSelection } from "src/constant";

const { transformInitialFormData, generatePayload } = helpers;

const DocumentsForm = (props: TFormProps<TForm>) => {
  const { form, onSuccess, onError, onClose } = props;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: form?.data ? transformInitialFormData(form.data) : {},
  });

  const { createDocument } = useCreateDocument();

  const [elements, setElements] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { fields } = elements ?? {};
  const editMode: boolean = form.mode === "EDIT";

  useEffect(() => {
    if (editMode && form?.data?.defaultUserSelection === DefaultUserSelection.ALLOWED) {
      formJSON[0].fields[4].fieldVisible = true;
    }
    setElements({ ...formJSON[0] });
    return () => {
      formJSON[0].fields[4].fieldVisible = false;
    }
  }, []);

  const onSubmitFn = async (data: FieldValues) => {
    if (data) {
      setLoading(true);
      try {
        const payload = generatePayload(data, form?.data?.id);
        const response = await createDocument({
          variables: { payload },
        });
        if (response?.data) {
          onSuccess(editMode ? UPDATE_SUCCESS.DOCUMENT : ADD_SUCCESS.DOCUMENT);
        }
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  function handleCustomSelect(id: string, value: any) {
    if (id === 'defaultUserSelection') {
      const newData = { ...formJSON[0] };
      if (value) {
        newData.fields[4].fieldVisible = true;
      } else {
        newData.fields[4].fieldVisible = false;
        setValue("defaultUserSelectionRequired", false);
      }
      setElements(newData);
    }
  }

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      modalVisible={true}
      Headerpopup={editMode ? t("editDocument.text") : t("addDocument.text")}
      width={600}
      handleSave={handleSubmit(onSubmitFn)}
    >
      <Container>
        {fields &&
          fields.map((field: any) => (
            // @ts-ignore
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
              handleCustomSelect={handleCustomSelect}
            />
          ))}
      </Container>
    </NormalModal>
  );
};

const Container = styled.div`
  height: 352px;
`;

export default DocumentsForm;
