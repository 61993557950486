import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { colors } from 'src/styles/theme/styles';

interface Props {
  value: any;
  color?: string;
  fontWeight?: 'normal' | '600';
  lineHeight?: number;
  lines?: number;
  fontsize?: number;
  style?: object;
  id?: string;
}

export default function AutoCutTextSelect(props: Props) {
  const {
    value = '',
    color = colors.primaryText,
    fontWeight = 'normal',
    lineHeight,
    lines,
    fontsize = 15,
    style = {},
    ...extra
  } = props;

  return (
    <Text
      style={[
        styles.textstyle,
        { color: color, fontWeight: fontWeight, lineHeight, fontSize: fontsize },
        { ...style }
      ]}
      {...extra}
      allowFontScaling={false}
      numberOfLines={lines}>
      {value}
    </Text>
  );
}

const styles = StyleSheet.create({
  textstyle: {
    lineHeight: 17,
    textOverflow: 'ellipsis',
    // /* Required for text-overflow to do anything */
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
