import { ExpandCollapseCell, NoRecordsFound, TableCells, TableDiv, TableRows } from './TableAtom';
import React, { ChangeEvent, MutableRefObject, useEffect, useState } from 'react';

import Chip from 'src/components/atoms/Chip';
import RadioStandalone from 'src/components/atoms/Radio/StandaloneRadio/index.web';
import { TableBody } from '@mui/material';
import styled from 'styled-components/native';
import useTable from './UseTable';
import HeaderSix from 'src/components/atoms/Text/HeaderSix';
import { colors } from 'src/styles/theme/styles';

interface DropdownOptionsType {
  id: string;
  name: string;
}

interface PropsType {
  tableData: DropdownOptionsType[];
  currentId: MutableRefObject<string>;
  isEdit: boolean;
}

const ReservationCritieriaRadioSelectTable = (props: PropsType) => {
  const { tableData, currentId, isEdit } = props;
  const [trow, setT] = useState<boolean>(false);
  const [current, setCurrent] = useState<number | null>(null);
  const [id, setId] = useState<string>('');

  const { TblContainer, recordsAfterPagingAndSorting } = useTable(
    tableData,
    [],
    null,
    tableData?.length,
  );

  const radioBtnStyle = { width: 16, padding: 1 };

  function collapseFn() {
    setT(false);
    setCurrent(null);
  }

  function expandFn(i: number) {
    setT(true);
    setCurrent(i);
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    setId(event.target.value);
  }

  useEffect(() => {
    if (id) {
      currentId.current = id;
    }
  }, [id]);

  useEffect(() => {
    if (isEdit && currentId.current) {
      setId(currentId.current);
    }
  }, [currentId]);

  return (
    <TableDiv>
      <TblContainer>
        {tableData?.length > 0 ? (
          <TableBody>
            {recordsAfterPagingAndSorting()?.map((item: DropdownOptionsType, i: number) => (
              <>
                <TableRows key={`e${i}`}>
                  <TableCells style={radioBtnStyle}>
                    <RadioStandalone
                      isChecked={id === item.id}
                      value={item.id}
                      handleChange={(e) => handleChange(e)}
                    />
                  </TableCells>
                  <TableCells>
                    <HeaderSix
                      value={item?.name}
                      color={colors.primaryText}
                      fontWeight={id === item?.id ? 600 : 400}
                    />
                  </TableCells>
                  <ExpandCollapseCell
                    trow={trow}
                    current={current}
                    i={i}
                    classNames={''}
                    textValue={''}
                    CollapseFn={collapseFn}
                    ExpandFn={() => expandFn(i)}
                    size={28}
                    color={colors.primaryColor}
                  />
                </TableRows>
                {trow && current === i && (
                  <TableRows>
                    <TableCells colspan={3}>
                      <>
                        <Wrapper>
                          {item.reservations?.length > 0 &&
                            item.reservations.map((elem) => {
                              return (
                                <ChipWrapper>
                                  <Chip
                                    label={`${elem.caste.name}: ${elem.reservePercentage}%`}
                                    close={false}
                                  />
                                </ChipWrapper>
                              );
                            })}
                        </Wrapper>
                      </>
                    </TableCells>
                  </TableRows>
                )}
              </>
            ))}
          </TableBody>
        ) : (
          <NoRecordsFound colspan={3} maxHeight={0} />
        )}
      </TblContainer>
    </TableDiv>
  );
};

const Wrapper = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const ChipWrapper = styled.View`
  margin: 8px 8px 0px 0px;
`;

export default ReservationCritieriaRadioSelectTable;
