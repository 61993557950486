import React from 'react';
import DeleteOutline from './icons/Delete';
import Experience from './icons/Experience';
import Education from './icons/Education';
import CheckIcon from './icons/Check'
import Assignment from './icons/Assignment';
import Attendance from './icons/Attendance';
import Communication from './icons/Communication';
import Fees from './icons/Fees';
import Home from './icons/Home';
import Logout from './icons/Logout';
import Profile from './icons/Profile';
import EditSquareIcon from './icons/EditSquareIcon';
import Settings from './icons/Settings';
import Timetable from './icons/TimeTable';
import Unsupported from './icons/Unsupported';
import EdbaLogoLight from './icons/EdbaLogoLight';
import EdbaLogo from './icons/Logo';
import Event from './icons/Event';
import Institute from './icons/Institute';
import ProfilePic from './icons/ProfilePic';
import ProfilePicSecond from './icons/ProfilePicSecond';
import Payment from './icons/Payment';
import NotFound from './icons/NotFound';
import PolicyPageLogo from './icons/PolicyPageLogo';
import AddAccount from './icons/AddAccount';
import DiscussionIcon from './icons/DiscussionIcon';
import CourseIcon from './icons/Course';
import InstituteAdmin from './icons/InstituteAdmin';
import InstituteAdminFocused from './icons/InstituteAdminFocused';
import ProfileFocused from './icons/ProfileFocused';
import PaymentScan from './icons/PaymentScan';
import PaymentScanFocused from './icons/PaymentScanFocused';
import PaymentDetails from './icons/PaymentDetails';
import ReplyIcon from './icons/Reply';
import Message from './icons/Message';
import Compose from './icons/Compose';
import Documents from './icons/Documents';
import UnBlock from './icons/UnBlock';
import SchedulePayment from './icons/SchedulePayment';
import Admission from './icons/Admission';
import Application from './icons/Application';
import ManageAdmission from './icons/ManageAdmission';
import QualifyApplication from './icons/QualifyApplication';
import AttendanceConfiguration from './icons/AttendanceConfiguration';
import CancelAdmission from './icons/CancelAdmission';
import Concession from './icons/Concession';
import LinkClosed from './icons/LinkClosed';
import EditEducationDetails from './icons/EditEducationDetails';
import AdmissionLinkPageImage from './icons/AdmissionLinkPageImage';
import Applicant from './icons/Applicant';
import PartialPayment from './icons/PartialPayment';
import RefundPayment from './icons/RefundPayment';
import CompleteRefund from './icons/CompleteRefund';
import AdmissionDropdown from './icons/AdmissionDropdown';
import SpreadSheet from './icons/SpreadSheet';
import ReconcilePayment from './icons/ReconcilePayment';
import Download from './icons/Download';
import Export from './icons/Export';
import FlashlightON from './icons/FlashlightON';
import FlashlightOFF from './icons/FlashlightOFF';
import AdmissionBannerImage from './icons/AdmissionBannerImage';

interface Props {
  name?: string;
  size?: number | string;
  color?: string;
}
export const Icon: React.FC<Props> = (props: Props) => {
  const { name, size, color } = props;
  const getIcon = (name?: string, size?: number, color?: string) => {
    switch (name) {
      case 'delete-outline':
        return <DeleteOutline size={size} color={color} />;
      case 'experience':
        return <Experience size={size} color={color} />;
      case 'education':
        return <Education size={size} color={color} />
      case 'check':
        return <CheckIcon size={size} color={color} />
      case 'assignment':
        return <Assignment size={size} color={color} />;
      case 'attendance':
        return <Attendance size={size} color={color} />;
      case 'communication':
        return <Communication size={size} color={color} />;
      case 'fees':
        return <Fees size={size} color={color} />;
      case 'home':
        return <Home size={size} color={color} />;
      case 'logout':
        return <Logout size={size} color={color} />;
      case 'profile':
        return <Profile size={size} color={color} />;
      case 'profile-focused':
        return <ProfileFocused size={size} color={color} />;
      case 'edit-square-icon':
        return <EditSquareIcon size={size} color={color} />
      case 'settings':
        return <Settings size={size} color={color} />;
      case 'timetable':
        return <Timetable size={size} color={color} />;
      case 'unsupported':
        return <Unsupported size={size} color={color} />;
      case 'edba-logo':
        return <EdbaLogo size={size} color={color} />;
      case 'edba-logo-light':
        return <EdbaLogoLight size={size} color={color} />;
      case 'event':
        return <Event size={size} color={color} />;
      case 'institute':
        return <Institute size={size} color={color} />;
      case 'profile-pic':
        return <ProfilePic size={size} color={color} />;
      case 'profile-pic-second':
        return <ProfilePicSecond size={size} color={color} />;
      case 'payment':
        return <Payment size={size} color={color} />;
      case 'payment-details':
        return <PaymentDetails size={size} color={color} />;
      case 'not-found':
        return <NotFound size={size} color={color} />;
      case 'privacy-page-logo':
        return <PolicyPageLogo size={size} color={color} />;
      case 'add-account':
        return <AddAccount size={size} color={color} />;
      case 'discussion-icon':
        return <DiscussionIcon size={size} color={color} />;
      case 'course-icon':
        return <CourseIcon size={size} color={color} />;
      case 'institute-admin':
        return <InstituteAdmin size={size} color={color} />;
      case 'institute-admin-focused':
        return <InstituteAdminFocused size={size} color={color} />;
      case 'payment-scan':
        return <PaymentScan size={size} color={color} />;
      case 'payment-scan-focused':
        return <PaymentScanFocused size={size} color={color} />;
      case 'reply':
        return <ReplyIcon size={size} color={color} />;
      case 'message':
        return <Message size={size} color={color} />;
      case 'compose':
        return <Compose size={size} color={color} />
      case 'documents':
        return <Documents size={size} color={color} />
      case 'unblock':
        return <UnBlock size={size} color={color} />
      case 'schedule-payment':
        return <SchedulePayment size={size} color={color} />
      case 'admission':
        return <Admission size={size} color={color} />
      case 'application':
        return <Application size={size} color={color} />
      case 'manage-admission':
        return <ManageAdmission size={size} color={color} />
      case 'qualify-application':
        return <QualifyApplication size={size} color={color} />
      case 'attendance-configuration':
        return <AttendanceConfiguration size={size} color={color} />
      case 'cancel-admission':
        return <CancelAdmission size={size} color={color} />
      case 'concession':
        return <Concession size={size} color={color} />
      case 'link-closed':
        return <LinkClosed size={size} color={color} />
      case 'edit-education-detail':
        return <EditEducationDetails size={size} color={color} />
      case 'admission-link-page-image':
        return <AdmissionLinkPageImage size={size} color={color} />
      case 'admission-link-banner-img':
        return <AdmissionBannerImage size={size} color={color} />
      case 'applicant':
        return <Applicant size={size} color={color} />
      case 'partial-payment':
        return <PartialPayment size={size} color={color} />
      case 'refund-payment':
        return <RefundPayment size={size} color={color} />
      case 'complete-refund':
        return <CompleteRefund size={size} color={color} />
      case 'admission-dropdown':
        return <AdmissionDropdown size={size} color={color} />
      case 'spreadsheet':
        return <SpreadSheet size={size} color={color} />
      case 'reconcile-payment':
        return <ReconcilePayment size={size} color={color} />
      case 'download':
        return <Download size={size} color={color} />
      case 'export':
        return <Export size={size} color={color} />
      case 'flashlightON':
        return <FlashlightON />
      case 'flashlightOFF':
        return <FlashlightOFF />
      default:
        return <DeleteOutline size={size} color={color} />;
    }
  };
  return getIcon(name, size, color);
};
