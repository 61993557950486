import React, { useEffect, useState } from "react";
import {
  generateWeeklyEvents,
  makeFetchWeeklyScheduleQueryVariable,
} from "./scheduleHelpers";

import LoadContentWrapper from "src/components/atoms/Wrapper/LoadContent";
import { ReactBigCalendarEvent } from "./scheduleTypes";
import ScheduleTimeline from "src/components/atoms/ScheduleTimeline/index.web";
import ScheduleToolbar from "src/components/organism/Schedule/ScheduleComponents/ScheduleToolbar.web";
import { isValid } from "date-fns";
import { useGetSchedule } from "src/graphql/schedule";
import { useHeaderTitle } from "src/contexts/header-context";
import { useI18n } from "src/i18n/hooks";

type TimetableProps = {};

export default function Timetable(props: TimetableProps) {
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { getSchedule, scheduleData, scheduleLoading, scheduleRefetch } =
    useGetSchedule();
  const [events, setEvents] = useState<ReactBigCalendarEvent[]>([]);
  const [pickedDate, setPickedDate] = useState<Date>(new Date());

  useEffect(() => {
    setHeading([{ text: t("timetable.label"), url: "" }]);
  }, []);

  useEffect(() => {
    if (pickedDate && isValid(pickedDate)) {
      handleFetch();
    }
  }, [pickedDate]);

  useEffect(() => {
    if (scheduleData && scheduleData?.getFullScheduleByDay?.data) {
      const eventsArray = generateWeeklyEvents(
        scheduleData.getFullScheduleByDay.data
      );
      setEvents(eventsArray);
    }
  }, [scheduleData]);

  const handleFetch = () => {
    getSchedule({
      variables: makeFetchWeeklyScheduleQueryVariable(pickedDate),
    });
  };

  return (
    <LoadContentWrapper>
      <ScheduleToolbar pickedDate={pickedDate} setPickedDate={setPickedDate} />
      <ScheduleTimeline
        pickedDate={pickedDate}
        events={events}
        showActions={false}
      />
    </LoadContentWrapper>
  );
}
