import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { getFilteredSubjectGroups } from 'src/components/services';
import importStudentJSON from '../../../form-json/control-panels/import-student.json';
import Element from '../Forms/ApplicationElement.web';

export default function ImportStudentForm(props: any) {
  const { control, errors, batch } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    setElements(importStudentJSON[0]);
    loadSubjectGroups();
  }, []);

  async function loadSubjectGroups() {
    const response = await getFilteredSubjectGroups(
      { filters: { batch: { eq: batch } }}
    );
    importStudentJSON[0].fields[0].option = response?.options;
    const newData = {...importStudentJSON[0]};
    setElements(newData);
  }

  return (
    <View>
      {fields ? (
        fields.map((field: any, i: number) => (
          <View>
            <Element key={i} field={field} control={control} errors={errors} />
          </View>
        ))
      ) : (
        <View style={styles.spinnerView}>
          <LoaderSpinner />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
});
