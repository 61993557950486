import { StyleSheet, View, TouchableOpacity } from 'react-native';
import React, { useState } from 'react';
import { colors, fontWeight, nativeShadow } from 'src/styles/theme/styles';
import NormalTextSelect from '../Text/NormalTextSelect';

interface Proptype {
  selectionMode: number;
  option1: string;
  option2: string;
  onSelectSwitch: (t: any) => void;
  onChange: (t: number) => void;
  selectionColor?: string;
  roundCorner?: boolean;
}

const SwipeSwitch = ({
  selectionMode = 1,
  roundCorner = true,
  option1,
  option2,
  onSelectSwitch,
  selectionColor = colors.primaryColor,
  onChange,
}: Proptype) => {
  const [getRoundCorner, setRoundCorner] = useState(roundCorner);

  const updatedSwitchData = (val: any) => {
    onChange(val);
    onSelectSwitch(val);
  };

  return (
    <View style={{ zIndex: 10 }}>
      <View
        style={[
          styles.container,
          {
            borderRadius: getRoundCorner ? 25 : 0,
            borderColor: selectionColor,
          },
        ]}>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitchData(1)}
          style={[
            styles.commonStyle,
            {
              backgroundColor: selectionMode == 1 ? selectionColor : 'white',
              borderRadius: getRoundCorner ? 25 : 0,
            },
          ]}>
          <NormalTextSelect
            value={option1}
            fontWeight={fontWeight[600]}
            color={selectionMode == 1 ? colors.white : colors.secondaryText}
          />
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitchData(2)}
          style={[
            styles.commonStyle,

            {
              backgroundColor: selectionMode == 2 ? selectionColor : 'white',
              borderRadius: getRoundCorner ? 25 : 0,
            },
          ]}>
          <NormalTextSelect
            value={option2}
            fontWeight={fontWeight[600]}
            color={selectionMode == 2 ? colors.white : colors.secondaryText}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    ...nativeShadow(15, 6),
    height: 40,
    backgroundColor: colors.white,
  },
  commonStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default SwipeSwitch;
