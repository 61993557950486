import axios from 'axios';

export async function GetRequestFunction(apiurl:any, header = {}, parameter = {}) {
	const config = {
		method: 'get',
		url: apiurl,
		params: parameter,
		headers: header
	}

	try {
		const data = await axios(config)
		let isValid = await checkResponse(data?.data?.message)

		return data?.data;
	} catch (error) {
		let response = error && error.response
		let isValid = await checkResponse(response?.data?.message)

		let data = response && response?.data || [];

		return data;
	}
}


const checkResponse = (text) => {
	let arr = ["Unauthorized access,Invalid token"]

	if (arr.includes(text)) {
		//clearLogout();
		//let abc = clearLogout();
		//console.log('logout here')
	}
	else
		return true
}


