import React, { useEffect, useState } from "react";
import { RefreshControl } from "react-native";
import { colors, fontWeight } from "src/styles/theme/styles";
import {
  showNativeError,
  showNativeSuccess,
} from "src/components/molecules/NotificationWrapper";

import { ADD_SUCCESS } from "src/constant/message";
import MediumText from "src/components/atoms/Text/MediumText";
import MobileHeader from "src/components/hoc/MobileHeader";
import NoData from "src/components/molecules/NoData";
import NormalModal from "src/components/atoms/Modals/Normal/index.native";
import SearchInviteCapApplicantForm from "src/components/molecules/Admission/SearchInviteCapApplicantForm";
import SmallTextAtom from "src/components/atoms/Text/SmallTextAtom";
import { height } from "src/constant/device";
import styled from "styled-components/native";
import { useForm } from "react-hook-form";
import { useGetAdmissions } from "src/graphql/admission";
import { useI18n } from "src/i18n/hooks";
import { useInviteCAPApplicants } from "src/graphql/admission/application";
import { format } from "date-fns";
import { DT, parseToDate } from "src/constant/dateTime";
import { DropdownOptionsType } from "src/types";
import AbstractFlashList from "src/components/atoms/FlashList";

interface PropType {
  navigation: any;
}

export default function Admissions(props: PropType) {
  const { t } = useI18n();
  const [admData, setAdmData] = useState([]);
  const [currentItem, setCurrentItem] = useState();
  const [dataAvailable, setDataAvailable] = useState<boolean>(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [inviteCapApplicant, setInviteCapApplicant] = useState<boolean>(false);
  const {
    getAdmissions,
    admissionsData,
    admissionsError,
    admissionsLoading,
    refetchAdmissions,
  } = useGetAdmissions();
  const { inviteCAPApplicants } = useInviteCAPApplicants();

  const {
    handleSubmit: handleSubmitCAP,
    control: controlCAP,
    formState: { errors: errorsCAP },
    reset: resetCAP,
    setValue: setValueCap,
    clearErrors: clearErrorsCap,
    setError: setErrorCap,
    getValues: getValuesCap,
    watch: watchValuesCap,
  } = useForm({ mode: 'all' });

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {
    if (admissionsData && admissionsData?.admissions) {
      setAdmData(admissionsData.admissions?.data);
      setDataAvailable(true);
    }
  }, [admissionsData]);

  function handleFetch() {
    getAdmissions({ variables: { limit: 0 } });
  }

  function refresh(): void {
    if (refetchAdmissions) refetchAdmissions();
  }

  function closeInviteCapApplicantModal(): void {
    resetCAP();
    setInviteCapApplicant(false);
  }

  function getSubjectGroups(item: {
    subjectGroups: { subjectGroup: DropdownOptionsType }[]
  }): DropdownOptionsType[] {
    if (item?.subjectGroups && item.subjectGroups?.length > 0) {
      return item?.subjectGroups?.map(
        (subGroup: { subjectGroup: DropdownOptionsType }) => subGroup.subjectGroup
      );
    }
    return [];
  }

  function triggerInviteCap(item: { allowedApplicantType: string[] }): void {
    if (
      item?.allowedApplicantType &&
      item.allowedApplicantType.includes("CAP")
    ) {
      setCurrentItem(item);
      setInviteCapApplicant(true);
    }
  }

  async function handleSearchInviteCap(formValues: any) {
    setCanClick(true);
    try {
      const inviteCapApplicantObject = {
        capId: formValues?.capId,
        name: formValues?.name,
        email: formValues?.emailId,
        subjectGroup: formValues?.subjectGroup,
        scrollRefNo: formValues?.scrollRefNo,
        admission: currentItem?.id
      }
      const response = await inviteCAPApplicants({
        variables: { payload: inviteCapApplicantObject },
      });
      if (response?.data?.sendApplicationFillRequest?.status === "success") {
        showNativeSuccess(ADD_SUCCESS.INVITE_CAP_APPLICANT);
        closeInviteCapApplicantModal();
      } else {
        throw new Error(response?.data?.sendApplicationFillRequest?.message);
      }
      setCanClick(true);
    } catch (e: any) {
      showNativeError(e?.message);
      setCanClick(true);
    }
  }

  const ListEmptyComponent = (
    <CenterAlignView>
      {dataAvailable && (
        <NoData
          icon={"application"}
          text1={t("no-applications-submitted.label")}
          type={"custom"}
        />
      )}
    </CenterAlignView>
  );

  const RefreshControlComp = (
    <RefreshControl
      tintColor={colors.primary}
      refreshing={admissionsLoading}
      onRefresh={refresh}
    />
  );

  return (
    <Container>
      <MobileHeader
        label={t("admissions.label")}
        navigation={props.navigation}
        showEdit={false}
      />
      <AbstractFlashList
        estimatedItemSize={100}
        data={admData}
        refreshControl={RefreshControlComp}
        ListEmptyComponent={ListEmptyComponent}
      >
        {({ item, index }) => {
          const parsedLastDate = parseToDate(item?.lastDate);
          return (
            <ApplicationInfoCard onPress={() => triggerInviteCap(item)}>
              <MediumText
                value={item?.name}
                align={"left"}
                color={colors.primaryText}
              />
              <RowGap />
              <RowGap />
              <SmallTextAtom
                value={item?.batch?.label || "-"}
                fontWeight={fontWeight[600]}
              />
              <RowGap />
              <SmallTextAtom
                value={`${t("totalSeats.label")}: ${item?.totalSeats || "-"
                  }`}
                fontWeight={fontWeight[600]}
              />
              <RowGap />
              <SmallTextAtom
                value={`${t(
                  "allowedApplicants.label"
                )}: ${item?.allowedApplicantType?.join(", ")}`}
                fontWeight={fontWeight[600]}
              />
              <RowGap />
              <SmallTextAtom
                value={`${t("lastDate.label")}: ${parsedLastDate ? format(parsedLastDate, DT.DATE_FORMAT_SLASH) : "-"}`}
                fontWeight={fontWeight[600]}
              />
              <RowGap />
              <SmallTextAtom
                value={item?.status}
                color={item?.status === "OPEN" ? colors.green : colors.red}
              />
            </ApplicationInfoCard>
          )
        }}
      </AbstractFlashList>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeInviteCapApplicantModal}
        modalVisible={inviteCapApplicant}
        Headerpopup={"CAP Applicant"}
        handleSave={handleSubmitCAP(handleSearchInviteCap)}
        addEditButtonLabel={"invite.label"}
      >
        <SearchInviteCapApplicantForm
          control={controlCAP}
          errors={errorsCAP}
          reset={resetCAP}
          setValue={setValueCap}
          getValues={getValuesCap}
          clearErrors={clearErrorsCap}
          watch={watchValuesCap}
          setError={setErrorCap}
          admissionId={currentItem?.id}
          subjectGroupOptions={currentItem ? getSubjectGroups(currentItem) : []}
        />
      </NormalModal>
    </Container>
  );
}

const Container = styled.View`
  background-color: ${colors.white};
  height: 100%;
`;

const CenterAlignView = styled.View`
  justify-content: center;
  align-items: center;
  height: ${0.75 * height};
`;

const ApplicationInfoCard = styled.TouchableOpacity`
  padding: 16px 24px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
  background-color: ${colors.white};
`;

const RowGap = styled.View`
  height: 4px;
`;
