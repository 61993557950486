import React from 'react';
import { View, StyleSheet } from 'react-native';
import AutoCutTextSelect from '../Text/AutoCutTextSelect';
import { convert as StringToColor } from 'src/utils/stringtoColor';
import { useTheme } from 'styled-components';
import { getEventDurationInMinutes } from 'src/components/organism/Schedule/scheduleHelpers';

interface propType {
  label1?: string;
  label2?: string;
  label3?: string;
  borderRadius?: number;
  elementwidth?: number | string;
  visibility?: boolean;
  elementheight?: number;
  schedule?: any;
  showFullData?: boolean;
  color?: string;
  maxWidth?: number;
}

export default function ScheduleChip(props: propType) {
  const {
    label1,
    label2,
    label3,
    borderRadius = 0,
    elementwidth,
    visibility,
    elementheight,
    schedule,
    showFullData = false,
    color,
    maxWidth,
  } = props;

  const { light: bgColor, dark: textColor } = StringToColor(`${label1}${label2}`);
  const { rem }: any = useTheme();
  const styles = ScheduleChipStyles(rem);
  const duration = getEventDurationInMinutes(schedule?.startTime, schedule?.endTime);
  const isLongDuration = duration > 30;
  return (
    <>
      <View style={{ height: elementheight }}>
        <View
          style={[
            styles.container,
            {
              maxWidth: maxWidth && maxWidth,
              borderRadius,
              backgroundColor: color + '1A' || bgColor,
              borderLeftColor: color || textColor,
              width: elementwidth,
              opacity: !visibility ? 0 : 1,
              height: elementheight,
              paddingVertical: isLongDuration || showFullData ? 6 : (elementheight - 20) / 2,
            },
          ]}>
          {label1 ? (
            <AutoCutTextSelect
              value={label1}
              color={color || textColor}
              fontWeight={'600'}
              lineHeight={20}
            />
          ) : null}
          {(isLongDuration || showFullData) && (
            <>
              {label3 ? (
                <AutoCutTextSelect value={label3} color={'black'} lineHeight={15} fontsize={12} />
              ) : null}
            </>
          )}
        </View>
      </View>
    </>
  );
}

const ScheduleChipStyles = (rem: any) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: 6,
      borderRadius: 4,
      borderLeftWidth: 4,
      pointerEvent: 'auto',
    },
    icon: {
      alignSelf: 'center',
      marginLeft: rem(0.6),
    },
  });
