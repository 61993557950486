import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 24 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path d="M10.5 5.5L3.5 5.5C2 5.5 1 6.5 1 8V16C1 17.5 2 18.5 3.5 18.5L10.5 18.5V5.5ZM9 17L4.5 17C3.39543 17 2.5 16.1046 2.5 15V9C2.5 7.89543 3.39543 7 4.5 7L9 7V17Z" fill="black" fill-opacity="0.6"/>
      <Path d="M13.5 5.5H20.5C22 5.5 23 6.5 23 8V16C23 17.5 22 18.5 20.5 18.5H13.5V5.5ZM15 17H19.5C20.6046 17 21.5 16.1046 21.5 15V9C21.5 7.89543 20.6046 7 19.5 7H15V17Z" fill="black" fill-opacity="0.6"/>
      <Path d="M6.50628 12.4708L5.056 12.0623C4.43381 11.8876 4 11.3123 4 10.6633C4 9.88727 4.61256 9.26334 5.375 9.22405V8.85798C5.375 8.70012 5.49806 8.57227 5.65 8.57227H6.2C6.35194 8.57227 6.475 8.70012 6.475 8.85798V9.22119C6.82872 9.24405 7.16422 9.35941 7.44953 9.56119C7.57775 9.65227 7.59528 9.84369 7.48837 9.96084L7.07347 10.4155C6.99097 10.5066 6.86172 10.5169 6.75584 10.4576C6.63416 10.3894 6.49391 10.3523 6.35125 10.3523H5.44581C5.25469 10.3523 5.1 10.4916 5.1 10.663C5.1 10.7969 5.19969 10.9187 5.34372 10.9594L6.794 11.368C7.41619 11.5426 7.85 12.118 7.85 12.7669C7.85 13.543 7.23744 14.1669 6.475 14.2062V14.5723C6.475 14.7301 6.35194 14.858 6.2 14.858H5.65C5.49806 14.858 5.375 14.7301 5.375 14.5723V14.2091C5.02128 14.1862 4.68578 14.0708 4.40047 13.8691C4.27225 13.778 4.25472 13.5866 4.36162 13.4694L4.77653 13.0148C4.85903 12.9237 4.98828 12.913 5.09416 12.9726C5.21584 13.0408 5.35609 13.078 5.49875 13.078H6.40419C6.59531 13.078 6.75 12.9387 6.75 12.7673C6.75 12.6333 6.65031 12.5116 6.50628 12.4708Z"
        fill="black"
        fill-opacity="0.6"/>
      <Path d="M18.5063 12.4708L17.056 12.0623C16.4338 11.8876 16 11.3123 16 10.6633C16 9.88727 16.6126 9.26334 17.375 9.22405V8.85798C17.375 8.70012 17.4981 8.57227 17.65 8.57227H18.2C18.3519 8.57227 18.475 8.70012 18.475 8.85798V9.22119C18.8287 9.24405 19.1642 9.35941 19.4495 9.56119C19.5778 9.65227 19.5953 9.84369 19.4884 9.96084L19.0735 10.4155C18.991 10.5066 18.8617 10.5169 18.7558 10.4576C18.6342 10.3894 18.4939 10.3523 18.3512 10.3523H17.4458C17.2547 10.3523 17.1 10.4916 17.1 10.663C17.1 10.7969 17.1997 10.9187 17.3437 10.9594L18.794 11.368C19.4162 11.5426 19.85 12.118 19.85 12.7669C19.85 13.543 19.2374 14.1669 18.475 14.2062V14.5723C18.475 14.7301 18.3519 14.858 18.2 14.858H17.65C17.4981 14.858 17.375 14.7301 17.375 14.5723V14.2091C17.0213 14.1862 16.6858 14.0708 16.4005 13.8691C16.2723 13.778 16.2547 13.5866 16.3616 13.4694L16.7765 13.0148C16.859 12.9237 16.9883 12.913 17.0942 12.9726C17.2158 13.0408 17.3561 13.078 17.4988 13.078H18.4042C18.5953 13.078 18.75 12.9387 18.75 12.7673C18.75 12.6333 18.6503 12.5116 18.5063 12.4708Z"
        fill="black"
        fill-opacity="0.6"/>
      <Rect x="11.75" y="2.25" width="0.5" height="19.5" stroke="black" stroke-opacity="0.6" stroke-width="0.5" stroke-dasharray="2 2"/>
    </Svg>
  );
}

export default SvgComponent;
