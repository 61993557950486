import { personal } from './personal';
import { addresses } from './address';
import { parents } from './parent';
import { education } from './education';
import { experience } from './experience';
import { others } from './others';
import { FormSchema, TemplateType, FormDDOptions, ViewMode } from 'src/components/molecules/Forms/types';
import { EMPLOYEE_TYPES, USER_TYPE, UserType } from 'src/constant';
import { DropdownOptionsType } from 'src/types';
import { userInformation } from 'src/utils/manageState';

const personal_view = (isOnboarding?: boolean, isInstituteAdmin?: boolean) => personal(ViewMode.READ, isOnboarding, isInstituteAdmin);
const personal_edit = (isOnboarding?: boolean, isInstituteAdmin?: boolean) => personal(ViewMode.EDIT, isOnboarding, isInstituteAdmin);
const address_view = (isInstituteAdmin?: boolean) => addresses(ViewMode.READ, isInstituteAdmin);
const address_edit = (isInstituteAdmin?: boolean) => addresses(ViewMode.EDIT, isInstituteAdmin);
const parent_view = (isInstituteAdmin?: boolean) => parents(ViewMode.READ, isInstituteAdmin);
const parent_edit = (isInstituteAdmin?: boolean) => parents(ViewMode.EDIT, isInstituteAdmin);

const education_view = (
  formDDOptions?: FormDDOptions,
  isInstituteAdmin?: boolean,
) => education(
  ViewMode.READ,
  formDDOptions,
  isInstituteAdmin,
);

const education_edit = (
  formDDOptions?: FormDDOptions,
  isInstituteAdmin?: boolean,
) => education(
  ViewMode.EDIT,
  formDDOptions,
  isInstituteAdmin,
);

const experience_view = (isInstituteAdmin?: boolean) => experience(ViewMode.READ, isInstituteAdmin);
const experience_edit = (isInstituteAdmin?: boolean) => experience(ViewMode.EDIT, isInstituteAdmin);
const others_view = (
  formDDOptions?: FormDDOptions,
  isInstituteAdmin?: boolean,
) => others(ViewMode.READ, formDDOptions?.categories, isInstituteAdmin);
const others_edit = (
  formDDOptions?: FormDDOptions,
  isInstituteAdmin?: boolean,
) => others(ViewMode.EDIT, formDDOptions?.categories, isInstituteAdmin);

export const employeeForms = (
  formDDOptions: FormDDOptions,
  isOnboarding?: boolean,
  isInstituteAdmin?: boolean,
) => {
  return {
    [ViewMode.READ]: [
      {
        templateType: TemplateType.DEFAULT,
        schema: personal_view(isOnboarding, isInstituteAdmin)
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: address_view(isInstituteAdmin)
      },
      {
        templateType: TemplateType.LIST,
        schema: education_view(formDDOptions, isInstituteAdmin),
      },
      {
        templateType: TemplateType.LIST,
        schema: experience_view(isInstituteAdmin),
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: others_view(formDDOptions, isInstituteAdmin),
      }
    ],
    [ViewMode.EDIT]: [
      {
        templateType: TemplateType.DEFAULT,
        schema: personal_edit(isOnboarding, isInstituteAdmin)
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: address_edit(isInstituteAdmin),
      },
      {
        templateType: TemplateType.LIST,
        schema: education_edit(formDDOptions, isInstituteAdmin),
      },
      {
        templateType: TemplateType.LIST,
        schema: experience_edit(isInstituteAdmin),
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: others_edit(formDDOptions, isInstituteAdmin),
      }
    ]
  }
}

export interface IUserDetailForm {
  templateType: TemplateType;
  schema: FormSchema;
}
export interface IUserDetailForms {
  [key: number]: IUserDetailForm[]
}

export const studentForms = (formDDOptions: FormDDOptions, isOnboarding?: boolean, isInstituteAdmin?: boolean) => {
  return {
    [ViewMode.READ]: [
      {
        templateType: TemplateType.DEFAULT,
        schema: personal_view(isOnboarding, isInstituteAdmin)
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: address_view(isInstituteAdmin)
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: parent_view(isInstituteAdmin)
      },
      {
        templateType: TemplateType.LIST,
        schema: education_view(formDDOptions, isInstituteAdmin),
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: others_view(formDDOptions, isInstituteAdmin),
      }
    ],
    [ViewMode.EDIT]: [
      {
        templateType: TemplateType.DEFAULT,
        schema: personal_edit(isOnboarding, isInstituteAdmin),
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: address_edit(isInstituteAdmin),
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: parent_edit(isInstituteAdmin),
      },
      {
        templateType: TemplateType.LIST,
        schema: education_edit(formDDOptions, isInstituteAdmin),
      },
      {
        templateType: TemplateType.DEFAULT,
        schema: others_edit(formDDOptions, isInstituteAdmin),
      }
    ]
  }
}

export const applicantForms = (formDDOptions: FormDDOptions, isOnboarding?: boolean) => ({
  [ViewMode.READ]: [
    {
      templateType: TemplateType.DEFAULT,
      schema: personal_view(isOnboarding)
    },
    {
      templateType: TemplateType.DEFAULT,
      schema: address_view()
    },
    {
      templateType: TemplateType.DEFAULT,
      schema: parent_view()
    },
    {
      templateType: TemplateType.DEFAULT,
      schema: others_view(formDDOptions)
    }
  ],
  [ViewMode.EDIT]: [
    {
      templateType: TemplateType.DEFAULT,
      schema: personal_edit(isOnboarding)
    },
    {
      templateType: TemplateType.DEFAULT,
      schema: address_edit()
    },
    {
      templateType: TemplateType.DEFAULT,
      schema: parent_edit()
    },
    {
      templateType: TemplateType.DEFAULT,
      schema: others_edit(formDDOptions)
    }
  ]
});

export const educationDetailsForms = (formOptions?: FormDDOptions) => {
  return {
    [ViewMode.READ]: [
      {
        templateType: TemplateType.LIST,
        schema: education_view(formOptions),
      },
    ],
    [ViewMode.EDIT]: [
      {
        templateType: TemplateType.LIST,
        schema: education_edit(formOptions),
      },
    ],
  };
};

export const applicationEducationForm = (
  formOptions?: FormDDOptions,
): IUserDetailForms => educationDetailsForms(formOptions);

export function getUserDetailForms(
  userType: UserType,
  formDDOptions: FormDDOptions,
  isOnboarding?: boolean,
): IUserDetailForms {
  const currentUserObject = userInformation();
  const isInstituteAdmin =
  currentUserObject?.userType === USER_TYPE.EMPLOYEE &&
  currentUserObject?.userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN);
  
  switch (userType) {
    case UserType.INSTITUTE_EMPLOYEE:
      return employeeForms(formDDOptions, isOnboarding, isInstituteAdmin);
    case UserType.APPLICANT:
      return applicantForms(formDDOptions, isOnboarding);
    case UserType.INSTITUTE_STUDENT:
      return studentForms(formDDOptions, isOnboarding, isInstituteAdmin);
    default:
      console.log("Invalid userType, can't determine form");
      return {};
  }
}

export function getProfileSteps(userType?: UserType): string[] {
  switch (userType) {
    case UserType.INSTITUTE_EMPLOYEE:
      return [
        'personalDetails',
        'addressDetails',
        'educationDetails',
        'experienceDetails',
        'otherDetails',
      ];
    case UserType.APPLICANT:
      return [
        'personalDetails',
        'addressDetails',
        'parentDetails',
        'otherDetails',
      ];
    case UserType.INSTITUTE_STUDENT:
      return [
        'personalDetails',
        'addressDetails',
        'parentDetails',
        'educationDetails',
        'otherDetails',
      ];
    default:
      console.log("Invalid userType, can't determine form steps");
      return [];
  }
}