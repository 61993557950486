import React, { useEffect, useState } from 'react';
import { RefreshControl } from 'react-native';
import { colors, fontWeight, fonts } from 'src/styles/theme/styles';
import MobileHeader from 'src/components/hoc/MobileHeader';
import NoData from 'src/components/molecules/NoData';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { height } from 'src/constant/device';
import styled from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import AbstractFlashList from 'src/components/atoms/FlashList';
import { useGetStudentMarkSheets } from 'src/graphql/assessment/assessment-results';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import navigationStrings from 'src/navigation/navigationStrings';
import NormalModal from 'src/components/atoms/Modals/Normal/index.native';
import { checkRemark } from 'src/utils/utility';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { faPollH } from '@fortawesome/pro-light-svg-icons';

interface PropType {
  navigation: any;
}

export default function StudentResult(props: PropType) {
  const { t } = useI18n();
  const [resultsData, setResultsData] = useState([]);
  const [dataAvailable, setDataAvailable] = useState<boolean>(false);
  const [remarkModal, setRemarkModal] = useState<boolean>(false);
  const [blockReason, setBlockReason] = useState({});

  const {
    getStudentMarkSheets,
    loadingStudentMarkSheets,
    dataStudentMarkSheets,
    errorStudentMarkSheets,
    refetchStudentMarkSheets,
  } = useGetStudentMarkSheets();

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {
    if (dataStudentMarkSheets && dataStudentMarkSheets?.studentMarkSheets) {
      setResultsData(dataStudentMarkSheets.studentMarkSheets?.data);
      setDataAvailable(true);
    }
  }, [dataStudentMarkSheets]);

  function handleFetch() {
    getStudentMarkSheets({ variables: { limit: 0 } });
  }

  function refresh(): void {
    if (refetchStudentMarkSheets) refetchStudentMarkSheets();
  }

  function triggerResultDetail(item: any): void {
    if (item?.status === 'BLOCK') {
      let blockReason = { examName: item?.exam?.name };
      const data = checkRemark(item?.statusActivity, item?.status)?.data;
      if (data?.reason) blockReason['reason'] = data.reason;
      setBlockReason(blockReason);
      setRemarkModal(true);
    } else {
      props.navigation.navigate(navigationStrings.NATIVE_RESULT_DETAIL, { resultData: item });
    }
  }

  const ListEmptyComponent = (
    <CenterAlignView>
      {dataAvailable && (
        <NoData icon={faPollH} text1={t('noResultsFound.text')} />
      )}
    </CenterAlignView>
  );

  const RefreshControlComp = (
    <RefreshControl
      tintColor={colors.primary}
      refreshing={loadingStudentMarkSheets}
      onRefresh={refresh}
    />
  );

  function closeRemarkModal() {
    setRemarkModal(false);
    setBlockReason({});
  }

  return (
    <Container>
      <MobileHeader
        backIcon={false}
        label={t('results.label')}
        navigation={props.navigation}
        showEdit={false}
      />
      <ContentWrapper>
        <AbstractFlashList
          estimatedItemSize={101}
          data={resultsData}
          refreshControl={RefreshControlComp}
          ListEmptyComponent={ListEmptyComponent}>
          {({ item, index }: { item: any; index: number }) => {
            const parsedDate =
              item?.exam?.createdAt &&
              parse(item.exam.createdAt, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date());
            return (
              <ResultInfoCard onPress={() => triggerResultDetail(item)}>
                <SmallTextAtom
                  value={parsedDate && format(parsedDate, DT.DATE_ABBREVIATED_MONTH_YEAR)}
                  fontWeight={fontWeight[700]}
                  color={colors.primaryColor}
                />
                <RowGap />
                <MediumTextSelect value={item?.exam?.name} fontSize={1.4}/>
              </ResultInfoCard>
            );
          }}
        </AbstractFlashList>
      </ContentWrapper>

      <NormalModal
        modalVisible={remarkModal}
        setModalVisible={closeRemarkModal}
        Headerpopup={t('results.label')}>
        <BlockResultWrapper>
          <MediumTextSelect
            value={`Your result for ${blockReason?.examName} is blocked.`}
            color={colors.primaryText}
            fontWeight={'normal'}
            fontFamily={fonts.semibold}
            fontSize={1.7}
          />
          <NormalTextSelect
            value={`Reason: ${blockReason?.reason || 'N/A'}`}
            color={colors.secondaryText}
            fontsize={1.7}
          />
        </BlockResultWrapper>
      </NormalModal>
    </Container>
  );
}

const Container = styled.View`
  background-color: ${colors.white};
  height: 100%;
`;

const ContentWrapper = styled.View`
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
`;

const CenterAlignView = styled.View`
  justify-content: center;
  align-items: center;
  height: ${0.75 * height};
`;

const ResultInfoCard = styled.TouchableOpacity`
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.dividerColor};
  background-color: ${colors.white};
`;

const RowGap = styled.View`
  height: 6px;
`;

const BlockResultWrapper = styled.View`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
