import { colors } from 'src/styles/theme/styles';

export const childBorderBottom = (trow: boolean, current: any, i: number) => {
  return {
    borderBottom: trow ? `2px solid ${colors.tableDropdwonBorder}` : '',
  };
};

export const childBorderRight = (trow: boolean, current: any, i: number) => {
  return {
    borderRight: trow ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

export const childBorderLeft = (t: boolean, current: any, i: number) => {
  return {
    borderLeft: t ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

export const parentBorderBottom = (t: boolean, current: any, i: number, inner: number, data) => {
  return {
    borderBottom:
      t && current === i && inner === data[i]?.group?.length - 1
        ? `2px solid ${colors.tableDropdwonBorder}`
        : `2px solid ${colors.lighterBorder}`,
  };
};

export const parentBorderRight = (t: boolean, current: any, i: number) => {
  return {
    borderRight:
      t && current === i ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

export const parentBorderLeft = (t: boolean, current: any, i: number) => {
  return {
    borderLeft:
      t && current === i ? `2px solid ${colors.tableDropdwonBorder}` : '2px solid transparent',
  };
};

export const commonBorder = (t: boolean, current: any, i: number) => {
  return {
    borderBottom:
      t && current === i
        ? `2px solid ${colors.tableDropdwonBorder}`
        : `1px solid ${colors.lighterBorder}`,
    borderTop:
      t && current === i
        ? `2px solid ${colors.tableDropdwonBorder}`
        : `1px solid ${colors.lighterBorder}`,
  };
};
