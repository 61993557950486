import React, { useCallback, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,
  useTheme,
  Tooltip,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faChevronDoubleLeft,
  faChevronDoubleRight,
} from '@fortawesome/pro-light-svg-icons';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import TableColHead from 'src/components/atoms/Text/TableCellHead';
import { colors } from 'src/styles/theme/styles';
import { Text, View } from 'react-native';
import { limit } from 'src/constant/index';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import { height } from 'src/constant/device';
import { useParams } from 'src/routes/routing.web';
import styled from 'styled-components/native';
import Check from 'src/components/atoms/CheckBox/index.web';

const customStyles = {
  captionView: {
    flexDirection: 'row',
    marginRight: 0,
  },
  common: {
    color: colors.primaryText,
  },
};

export default function useTable(
  records = null as any,
  headCells = [] as any,
  maxHeight = null as any,
  total = null as number | null,
  current = 0,
  handlepage = null,
  filterFn = null,
  collapsible = false,
  allowPagination = true,
  customHeight = null,
  paginationHeightOffset = 0
) {
  const { theme, changeTheme } = useThemeSystem();
  // const classes = useStyles(theme);
  let rowLimit = 0;
  let { dataLimit = limit }: any = useParams();
  rowLimit = parseInt(dataLimit);

  const pages = [rowLimit];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const { t } = useI18n();
  
  const TblContainer = useCallback((props: any) => {
    return (
      <TableContainer 
      style={
        props.tabledata?.length < 1 && !props.height ? { height: 100 } : {
        height: props.height ? props.height : maxHeight ? height - 314 - paginationHeightOffset : null,
        overflowX: props.overflowX ? props.overflowX : 'hidden', maxHeight: props.maxHeight && props.maxHeight
      }
    }>
      {/* <TableContainer style={{ height: maxHeight ? height - 314 : null }}> */}
      {/* marginTop:-56 rmoved from container */}
      <Table stickyHeader aria-label="sticky label">
        {props.children}
      </Table>
    </TableContainer>
    )}
      , []);
  
  const TblHead = useCallback((props) => {
    const { dynamicHeadCells = null, setHeadCells, style, disableCheckBox = false } = props;
    const handleSorting = (id: string, ascend: boolean) => {
      if (setHeadCells) {
        const newHeadCells = dynamicHeadCells ? dynamicHeadCells : [...headCells];

        newHeadCells.forEach((headCell) => {
          if (headCell.id === id) {
            headCell.ascend = ascend;
            headCell.isSorted = true;
          } else {
            headCell.ascend = null;
            headCell.isSorted = false;
          }
        });
        setHeadCells([...newHeadCells]);
      }
    };
    return (
      <TableHead style={style ? style : {}}>
        <TableRow>
          {props.checkBox && (
            <TableCell style={styles.tableCheckbox}>
              <Check
                same={false}
                checked={props.checked}
                onChange={() => props.handleCheck()}
                disabled={disableCheckBox}
              />
            </TableCell>
          )}
          {(dynamicHeadCells ? dynamicHeadCells : headCells)?.map((headCell: any, i: number) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              align={headCell.align}
              style={headCell?.style ? headCell.style : null}>
              {headCell?.sort ? (
                <TableHeadWrapper
                  align={
                    headCell?.align
                      ? headCell.align
                      : i === headCells.length - 1
                        ? 'flex-end'
                        : 'flex-start'
                  }>
                  <TableColHead value={t(headCell.label)} color={theme?.table?.header?.color} />
                  {headCell?.sort ? (
                    headCell?.ascend || headCell?.ascend === null ? (
                      <Tooltip title={'sort'}>
                        <FontAwesomeIcon
                          icon={faArrowUp}
                          onClick={() => {
                            handleSorting(headCell.id, !headCell.ascend);
                          }}
                          color={theme?.table?.header?.color}
                          style={{ cursor: 'pointer', marginLeft: 10 }}
                          opacity={headCell?.isSorted ? 1 : 0.3}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={'sort'}>
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          onClick={() => {
                            handleSorting(headCell.id, !headCell.ascend);
                          }}
                          color={theme?.table?.header?.color}
                          style={{ cursor: 'pointer', marginLeft: 10 }}
                          opacity={headCell?.isSorted ? 1 : 0.3}
                        />
                      </Tooltip>
                    )
                  ) : null}
                </TableHeadWrapper>
              ) : (
                <TableColHead value={t(headCell.label)} color={theme?.table?.header?.color} />
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }, []);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  function TablePaginationActions(props: any) {
    const { count, rowsPerPage } = props;
    const numberOfPages = Math.ceil(count / rowLimit);

    let arr;
    if (numberOfPages) arr = [...Array.from({ length: numberOfPages }, (_, i) => i + 1)];

    const handleFirstPageButtonClick = (event) => {
      handlepage(1);
    };

    const handleBackButtonClick = (event) => {
      if (current <= 1) return;
      handlepage(current - 1);
    };

    const handleNextButtonClick = (event) => {
      handlepage(parseInt(current) + 1);
    };

    const handleLastPageButtonClick = (event) => {
      handlepage(Math.ceil(count / rowsPerPage));
    };

    return (
      <div>
        <button
          onClick={handleFirstPageButtonClick}
          disabled={current === 1}
          style={{
            backgroundColor: colors.background,
            color: colors.secondaryText,
          }}>
          <FontAwesomeIcon
            icon={faChevronDoubleLeft}
            style={{
              backgroundColor: colors.background,
              color: colors.secondaryText,
            }}
          />
        </button>

        <button
          onClick={handleBackButtonClick}
          disabled={current === 1}
          style={{
            backgroundColor: colors.background,
            color: colors.secondaryText,
          }}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        {arr?.length > 0 &&
          arr?.map((item, i) => (
            <button
              style={{
                backgroundColor: current - 1 === i ? colors.primary : 'transparent',
                color: current - 1 === i ? colors.white : colors.secondaryText,
                fontSize: '13px',
              }}
              onClick={(e) => {
                handlepage(item);
              }}
              key={i}>
              {item}
            </button>
          ))}
        <button
          onClick={handleNextButtonClick}
          disabled={current >= Math.ceil(count / rowsPerPage)}
          style={{
            backgroundColor: colors.background,
            color: colors.secondaryText,
          }}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <button
          style={{
            backgroundColor: colors.background,
            color: colors.secondaryText,
          }}
          onClick={handleLastPageButtonClick}
          disabled={current >= Math.ceil(count / rowsPerPage)}>
          <FontAwesomeIcon icon={faChevronDoubleRight} />
        </button>
      </div>
    );
  }

  const TblPagination = () => (
    <TablePagination
      component="div"
      rowsPerPageOptions={[2]}
      colSpan={3}
      count={total} //5
      rowsPerPage={rowsPerPage} //9
      page={page} //0
      onChangePage={handleChangePage}
      labelDisplayedRows={({ from, to, count }) => (
        <View style={customStyles.captionView}>
          <Text>
            <Text>Showing</Text> <Text style={customStyles.common}>{current * rowLimit - 1}</Text> -
            <Text style={customStyles.common}>
              {current * rowLimit - 1 === total ? total : current * rowLimit}
            </Text>{' '}
            of <Text style={customStyles.common}>{count}</Text>
          </Text>
        </View>
      )}
      ActionsComponent={TablePaginationActions}
    />
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    if (filterFn) {
      return filterFn.fn(records);
    } else if (allowPagination) {
      return stableSort(records, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage,
      );
    } else {
      return stableSort(records, getComparator(order, orderBy));
    }
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}

const TableHeadWrapper = styled.View<{ align: any }>`
  flex-direction: row;
  justify-content: ${({ align }) => align && align};
  width: 110%;
  padding-right: ${({ align }) => align === 'flex-end' && 16}px;
`;

const styles = {
  tableCheckbox: {
    width: 16,
    backgroundColor: colors.white,
  },
};
