import { ADD_SUCCESS, UPDATE_SUCCESS } from "src/constant/message";
import { ERROR, SUCCESS } from "src/constant";
import {
  LectureFormData,
  fetchSubjectGroups,
  fetchSubjects,
  fetchTeachers,
  generateLectureFormPayload,
  prefillDataEditLecture,
} from "./LectureFormHelpers";
import React, { useEffect, useState } from "react";
import {
  createNewScheduleMutation,
  updateLectureMutation,
  updateSingleLectureMutation,
} from "src/graphql/courses";
import { isAfter, isEqual, parse } from "date-fns";

import { AddEditLectureFormProps } from "../scheduleTypes";
import { DT } from "src/constant/dateTime";
import Element from "src/components/molecules/Forms/ApplicationElement.web";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import formJSON from "src/form-json/timetable/add-edit-lecture-form.json";
import { getWeekDays } from "../scheduleHelpers";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useForm } from "react-hook-form";
import { useI18n } from "src/i18n/hooks";
import { useMutation } from "@apollo/client";

const AddEditLectureForm = (props: AddEditLectureFormProps) => {
  const {
    title,
    closeModal,
    refetchLectures,
    mode,
    details,
    initialValues,
    lectureData,
    closePopover,
  } = props;
  const { batchId, division, classId } = details;
  const { t } = useI18n();
  const { setAlertDetails } = useAlertSystem();
  const [createNewSchedule] = useMutation(createNewScheduleMutation);
  const [updateSingleLecture] = useMutation(updateSingleLectureMutation);
  const [updateLectures] = useMutation(updateLectureMutation);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [canClick, setCanClick] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<Object | null>(null);
  const [elements, setElements] = useState<any>({});
  const { fields } = elements ?? {};

  useEffect(() => {
    if (mode === "ADD") {
      initialiseAndReset();
      loadWeekDaysAndSubjectGroups();
    } else if (lectureData) {
      prefillDataEditLecture(mode, lectureData, setValue);
      // Subject Group not available in API Response
      formJSON[0].fields[6].fieldVisible = false;
      formJSON[0].fields.map((field, index) => {
        if (field.id !== "description")
          formJSON[0].fields[index].disabled = true;
      });
      setElements({ ...formJSON[0] });
    }
    return () => {
      // Cleanup
      formJSON[0].fields[6].fieldVisible = true; // subjectGroup
      formJSON[0].fields[6].option = []; // subjectGroup
      formJSON[0].fields[7].option = []; // subject
      formJSON[0].fields[8].option = []; // teacher
      formJSON[0].fields.map((field, index) => {
        formJSON[0].fields[index].disabled = false; // re-enable fields
      });
    };
  }, [mode, lectureData]);

  const initialiseAndReset = async () => {
    if (initialValues) {
      setValue("startDate", initialValues.startDate);
      // Time fields are initialized when both the start and end times are available. (slot selection)
      setValue("startTime", initialValues?.startTime ?? null);
      setValue("endTime", initialValues?.endTime ?? null);
    }
    // Required for the proper functioning of the clear action
    setValue("subject", null);
    setValue("teacher", null);
  };

  const loadWeekDaysAndSubjectGroups = async () => {
    // @ts-ignore
    formJSON[0].fields[3].option = getWeekDays();
    formJSON[0].fields[6].option = await fetchSubjectGroups(batchId);
    setElements({ ...formJSON[0] });
  };

  const loadSubjects = async (subjectGroupId: string) => {
    formJSON[0].fields[7].option = await fetchSubjects(subjectGroupId);
    setElements({ ...formJSON[0] });
  };

  const loadTeachers = async (subjectId: string) => {
    formJSON[0].fields[8].option = await fetchTeachers({
      subjectId,
      ...details,
    });
    setElements({ ...formJSON[0] });
  };

  const clearDropdownField = (fieldId: string, fieldIndex: number) => {
    setValue(fieldId, null);
    formJSON[0].fields[fieldIndex].option = [];
    setElements({ ...formJSON[0] });
  };

  const handleCustomSelect = (id: string, data: any) => {
    switch (id) {
      case "subjectGroup":
        clearDropdownField("subject", 7);
        clearDropdownField("teacher", 8);
        if (data && data?.value) {
          loadSubjects(data.value);
        }
        break;

      case "subject":
        clearDropdownField("teacher", 8);
        if (data && data?.value) {
          loadTeachers(data.value);
        }
        break;

      default:
        break;
    }
  };

  const onSuccess = (message: string): void => {
    setAlertDetails({ message, level: SUCCESS });
  };

  const getParsedTime = (time: string): Date => {
    return parse(time, DT.TIME_24_HOUR_WITH_COLON, new Date());
  };

  const onSubmit = async (formData: LectureFormData) => {
    setCanClick(false);
    try {
      switch (mode) {
        case "ADD":
          const startTime = getParsedTime(formData.startTime);
          const endTime = getParsedTime(formData.endTime);

          if (isAfter(endTime, startTime)) {
            const payloadForADD = generateLectureFormPayload(
              mode,
              details,
              formData
            );
            const responseCreateNewSchedule = await createNewSchedule({
              variables: { payload: payloadForADD },
            });
            if (responseCreateNewSchedule) {
              if (errorMessage) setErrorMessage(null);
              onSuccess(ADD_SUCCESS.SCHEDULE);
            }
            break;
          } else if (isEqual(startTime, endTime)) {
            throw new Error(t("lectureDurationZero.warning"));
          } else {
            throw new Error(t("lectureEndingBeforeStart.warning"));
          }

        case "EDIT_SINGLE":
          const payloadForEDIT_SINGLE = generateLectureFormPayload(
            mode,
            details,
            formData,
            lectureData?.id
          );
          const responseUpdateSingleLecture = await updateSingleLecture({
            variables: { payload: payloadForEDIT_SINGLE },
          });
          if (responseUpdateSingleLecture) {
            onSuccess(UPDATE_SUCCESS.SCHEDULE);
          }
          break;

        case "EDIT_ALL":
          const payloadForEDIT_ALL = generateLectureFormPayload(
            mode,
            details,
            formData,
            lectureData?.id
          );
          const responseUpdateLectures = await updateLectures({
            variables: { payload: payloadForEDIT_ALL },
          });
          if (responseUpdateLectures) {
            onSuccess(UPDATE_SUCCESS.SCHEDULE);
          }
          setCanClick(true);
          break;
      }
      // On Success
      setCanClick(true);
      if (refetchLectures) refetchLectures();
      if (closePopover) closePopover();
      closeModal();
    } catch (error: any) {
      setCanClick(true);
      setErrorMessage({
        message: error?.message,
        level: ERROR,
        expireInMs: 10000,
      });
    }
  };

  return (
    <NormalModal
      isSubmitting={!canClick}
      modalVisible={true}
      setModalVisible={closeModal}
      Headerpopup={title}
      width={560}
      height={500}
      addEditButtonLabel={"save.label"}
      handleSave={handleSubmit(onSubmit)}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <Container>
        {fields ? (
          fields.map((field: any) => (
            <FieldWrapper twoCol={splitView.includes(field.id)}>
              {/* @ts-ignore */}
              <Element
                key={field.id}
                field={field}
                control={control}
                errors={errors}
                maxDropdownOptionsHeight={176}
                autoCompleteProps={{ disablePortal: true }}
                handleCustomSelect={handleCustomSelect}
              />
            </FieldWrapper>
          ))
        ) : (
          <SpinnerWrapper>
            <LoaderSpinner />
          </SpinnerWrapper>
        )}
      </Container>
    </NormalModal>
  );
};

const splitView = ["startDate", "endDate", "startTime", "endTime"];

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 99%; // avoids y-axis scroll
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FieldWrapper = styled.div<{ twoCol: boolean }>`
  width: ${({ twoCol }) => (twoCol ? "48.5%" : "100%")};
`;

export default AddEditLectureForm;
