import { format } from "date-fns";
import { DT } from "src/constant/dateTime";
import { colors } from "src/styles/theme/styles";

export function getLastUpdatedDateTime(
  statusActivity: { date: number }[]
): string | null {
  const latestDate = Math.max(...statusActivity.map((item) => item.date));
  return latestDate ? format(latestDate, DT.DATE_TIME_SLASH_12_HOUR) : null;
}

export const getPaymentStatusColor = (status: string) => {
  switch (status) {
    case 'Initiated':
    case 'In Process':
    case 'Refund in process':
    case 'Refund Cancelled':
      return colors.yellow;
    case 'Completed':
    case 'Updated':
      return colors.green;
    case 'Cancelled':
    case 'Failed':
      return colors.red;
    case 'Refund Completed':
    case 'Transferred':
      return colors.blue;
    case 'Bounce':
    case 'Pending':
      return colors.red;
    default:
      return colors.primaryText;
  }
};
