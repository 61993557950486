/* eslint-disable react-native/no-color-literals */
import { Tooltip } from '@mui/material';
import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import More from 'src/components/atoms/MoreAtom';
import UserAvatar from 'src/components/atoms/UserAvatar';
import { assigneeObjectType } from 'src/components/organism/Assignments/types';
import { isWeb } from 'src/constant/device';
import { colors } from 'src/styles/theme/styles';
import { properCase } from 'src/utils/utility';

interface propType {
  count: number;
  setStudent: any;
  setDetails: any;
  totalStudents: number;
  assigneeList: assigneeObjectType[];
  isDisabled?: boolean;
}

export default function AvatarCard(props: propType) {
  const { count, setStudent, setDetails, assigneeList, totalStudents } = props;
  let remainCount = totalStudents - count;

  const AvatarComponent = ({ student }: { student: assigneeObjectType }) => (
    <View style={styles.wrapper} key={`assignee${student?.id}`}>
      <UserAvatar
        setStudent={() => setDetails(student, false)}
        profileUrl={student?.profilePicture?.documentUrl || ''}
        studentDetail={student}
        isDisabled={false}
      />
    </View>
  )

  return (
    <View style={styles.conatainer}>
      {assigneeList?.map((student: assigneeObjectType) => {
        return (
          isWeb ? (
            <Tooltip title={properCase(student?.personalDetails?.fullName)}>
              <View>
                <AvatarComponent student={student} />
              </View>
            </Tooltip>
          ) : (
            <AvatarComponent student={student} />
          )
        )
      })}

      {totalStudents > count ? (
        <Fragment>
          <More setStudent={setStudent} count={`+${remainCount || 0}`} />
        </Fragment>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  conatainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  wrapper: {
    shadowColor: colors.searchShadow,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 1,
    shadowRadius: 5,
    borderRadius: 50,
    marginRight: 8,
  },
});
