import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components';
import Input from 'src/components/atoms/Input/input.web';
import React, { useEffect } from 'react';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy'
import { regexObject } from 'src/constant/regex';

export const TextInput = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    type,
    isDisabled,
    meta,
    setInitialValue,
    subscribeToValues,
    handleChange,
    multiline,
    autoCapitalize = "none",
    disableInputConstraints = false,
  } = useFieldApi(props);
  const formOptions = useFormApi();
  const { getState, schema } = useFormApi();
  const { submitFailed, values } = getState();
  const { value, onChange, name } = input;
  const { t } = useI18n();

  useEffect(() => {
    if (setInitialValue) {
      setInitialValue(props, formOptions);
    }
  }, [])

  useEffect(() => {
    if (handleChange) {
      handleChange(props, formOptions);
    }
  }, [value])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disableInputConstraints) {
      if (regexObject.ASCII_ONLY.test(event.target.value)) {
        onChange(event);
      }
    }
  };

  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <InputContainer>
          <Input
            value={value}
            setValue={handleInputChange}
            header={label}
            typeInput={type ? type : 'text'}
            disabled={isDisabled}
            multiline={multiline}
            autoCapitalize={autoCapitalize}
          />
          <ErrorMsg>{meta.submitFailed && t(meta.error)}</ErrorMsg>
        </InputContainer>
      )}
    </FormSpy>
  );
};

/*Styled - components*/

const InputContainer = styled.div`
  margin-bottom: 0;
`

const ErrorMsg = styled.div`
  font-family: ${fonts.regular};
  color: ${colors.errorColor};
  margin-top: 5px;
  font-size: 12px;
  min-height: 14px;
`;