/* eslint-disable react-native/no-inline-styles */
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { height, isIOS } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import NormalModal from 'src/components/atoms/Modals/Normal/index.native';
import AddAccountForm from 'src/components/molecules/AddAccount/index';
// === Legacy Login === It should be refactored or removed in the future.
import {
  getAccountsFromStorage,
  setAccountsToStorage,
  setOnboardingVisited,
  setRefreshTokenToStorage,
  setTokenToStorage,
} from 'src/components/services/storage-service';
import { useLoginApplicant, useLoginInstituteUser, useLoginUser } from 'src/graphql/login';
import { showMessage } from 'src/components/molecules/NotificationWrapper';
import ERROR_MSG from 'src/constant/error';
import { authToken, fcmTokenInitialized, userInformation } from 'src/utils/manageState';
import { isAuthenticated, registerToken } from 'src/graphql/common';
import navigationStrings from 'src/navigation/navigationStrings';
import { apolloClearStore } from 'src/graphql/client';
import { useSidebarSystem } from 'src/contexts/sidebar-context';
import { USER_TYPE_ENUM } from 'src/constant';
import { AccountObject, AccountsDataType, getAllAccountsFromStorage } from '../Login/LoginHelpers';
import { setAuthData } from 'src/utils/utility';
import LoginCard from 'src/components/molecules/Login/LoginCard';
import styled from 'styled-components/native';

export const signInWithCurrentUser = async (navigation: any, token: string, refresh: string, isProfileIncomplete?: boolean) => {
  try {
    authToken({ edbaToken: token });
    await setOnboardingVisited(!isProfileIncomplete || false);
    await setTokenToStorage(token);
    await setRefreshTokenToStorage(refresh);
    await isAuthenticated();
    navigation.reset({
      index: 0,
      routes: [{ name: navigationStrings.NATIVE_DASHBOARD }],
    });
  } catch (e: any) {
    showMessage({
      message: e.message || 'Invalid credentials',
      type: 'danger',
      position: 'top',
      icon: 'danger',
    });
  }
};

export default function MultiAccounts(props: any) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    clearErrors,
    getValues,
    setError
  } = useForm();

  const { authCallback } = props;
  const disableToggleDrawer: boolean = props?.route?.params?.["from"] && props.route.params["from"] === "Login";

  const { t } = useI18n();
  const { loginInstituteUser } = useLoginInstituteUser();
  const { loginApplicant } = useLoginApplicant()
  const currentUserObject = userInformation();
  const { togglevalue, toggleSidebar } = useSidebarSystem();
  const { loginUserMutation } = useLoginUser();

  const [storedAccCards, setStoredAccountCards] = useState<AccountObject[]>();
  const [modalState, setmodalState] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<Object>({});

  // === Legacy Login === It should be refactored or removed in the future.
  // const getUsersInformation = async () => {
  //   const result = await getFullListOfTokens();
  //   if (result) {
  //     setListOfAccounts(result);
  //   }
  // };
  const getUsersInformation = async () => {
    getAllAccountsFromStorage().then((accountCards) => {
      setStoredAccountCards(accountCards);
    });
  };

  function alertSuccess() {
    showMessage({
      message: 'Logged in successfully',
      type: 'success',
      icon: 'success',
    });
  }

  function getFilteredStoredAccounts(loginEmail: string): AccountsDataType[] {
    if (storedAccCards && storedAccCards.length > 0) {
      const filteredStoredAccounts = storedAccCards?.filter((acc: AccountObject) => acc.email !== loginEmail).map((filteredAcc: AccountObject): AccountsDataType => {
        return {
          token: filteredAcc.accessToken,
          refresh: filteredAcc.refreshToken
        }
      })
      return filteredStoredAccounts;
    }
    return [];
  }

  const addUserAccount = async (logindata: any) => {
    try {
      fcmTokenInitialized(false);
      let accountsData: AccountsDataType[] = [];
      const resp = await loginUserMutation({
        variables: {
          userName: logindata.userName,
          password: logindata.password,
        },
      });
      if (resp && resp?.data) {
        if (resp.data?.loginUser?.length > 0) {
          accountsData = resp.data.loginUser;
        }
        const filteredStoredAccounts = getFilteredStoredAccounts(logindata.userName)
        const mergedData = [...filteredStoredAccounts, ...accountsData,];
        await setAccountsToStorage(mergedData);
        closeModal();
        alertSuccess();
      } else {
        // API failed
        throw new Error("API failed");
      }
    } catch (error) {
      showMessage({
        message: error?.message || "Invalid credentials",
        type: "danger",
        position: "top",
        icon: "danger",
      });
    }
  };

  // === Legacy Login === It should be refactored or removed in the future.
  // const addUserAccount = async (logindata: any) => {
  //   setIsSubmitting(true);

  //   try {
  //     if (logindata.userType === USER_TYPE_ENUM[3]) {
  //       delete logindata.userType;
  //       const user = await loginApplicant({
  //         variables: { credential: logindata },
  //       });
  //       if (user && user.data) {
  //         const data = user.data;
  //         if (data.applicantLogin && data.applicantLogin.token) {
  //           addOrUpdateTokenListInStorage(
  //             data.applicantLogin.token,
  //             data.applicantLogin.refresh,
  //           )
  //           getUsersInformation();
  //           setIsSubmitting(false);
  //           closeModal();
  //           await registerToken({ fcm: null, access: data.applicantLogin.token });
  //           alertSuccess();
  //         }
  //       }
  //     } else {
  //     logindata.userType =
  //       logindata.userType === 'TEACHER' ? 'INSTITUTE_EMPLOYEE' : 'INSTITUTE_STUDENT';
  //     logindata.version = '1.0.1';
  //     const creds = { variables: { credential: logindata } };
  //     const user = await loginInstituteUser(creds);

  //     if (user && user.data) {
  //       const data = user.data;
  //       if (data.loginInstituteUser && data.loginInstituteUser.token) {
  //         await addOrUpdateTokenListInStorage(
  //           data.loginInstituteUser.token,
  //           data.loginInstituteUser.refresh,
  //         );
  //         getUsersInformation();
  //         setIsSubmitting(false);
  //         closeModal();
  //         await registerToken({ fcm: null, access: data.loginInstituteUser.token });
  //         alertSuccess();
  //         }
  //       }
  //     }
  //   } catch (e: any) {
  //     setIsSubmitting(false);
  //     setMessage({
  //       message: e.message || ERROR_MSG.INVALID_USER_INFORMATION,
  //       type: 'danger',
  //       position: 'top',
  //       icon: 'danger',
  //       duration: 800,
  //     });
  //   }
  // };

  const handleSave = async (payload: any) => {
    await addUserAccount(payload);
  };

  const onMenuIconClick = () => {
    if (togglevalue.toggle === 1) {
      props?.navigation?.toggleDrawer();
    } else {
      showMessage({
        message: 'Please select an account to continue',
        type: 'warning',
        icon: 'warning',
      });
    }
  };

  useEffect(() => {
    getUsersInformation();
  }, []);

  function closeModal() {
    setmodalState(false);
    reset({});
    clearErrors();
    getUsersInformation();
  };

  return (
    <Fragment>
      <MobileHeader
        label={t('accounts.label')}
        backIcon={false}
        {...props}
        onMenuIconClick={onMenuIconClick}
        disableToggleDrawer={disableToggleDrawer}
      />
      <Wrapper>
        {storedAccCards && storedAccCards?.length > 0 && (
          <ScrollContainer showsVerticalScrollIndicator={false}>
            {storedAccCards.map((item: AccountObject, index: number) => (
              <LoginCard
                index={index}
                lastIndex={storedAccCards.length - 1}
                cardData={item}
                onPress={async () => {
                  if (togglevalue.toggle === 0) {
                    toggleSidebar({ toggle: 1 });
                  }
                  setAuthData(
                    { token: item.accessToken, refresh: item.refreshToken },
                    "",
                    props.location,
                    authCallback
                  );
                  if (currentUserObject?.userId === item.userId) {
                    props.navigation.navigate(navigationStrings.NATIVE_DASHBOARD);
                  } else {
                    await signInWithCurrentUser(props.navigation, item.accessToken, item.refreshToken, item.isProfileIncomplete);
                  }
                }}
              />
            ))}
          </ScrollContainer>
        )}
      </Wrapper>
      <ButtonWrapper>
        <SecondaryBtn label={t('addAccount.text')} onPress={() => setmodalState(true)} lineHeight={2} />
      </ButtonWrapper>
      <NormalModal
        setModalVisible={closeModal}
        modalVisible={modalState}
        Headerpopup={t('addAccount.text')}
        showCloseIcon={true}
        message={message}>
        <AddAccountForm
          errors={errors}
          control={control}
          getValues={getValues}
          setError={setError}
          clearErrors={clearErrors}
          handleSave={handleSubmit(handleSave)}
          isSubmitting={isSubmitting}
          navigation={props.navigation}
          closeModal={closeModal}
        />
      </NormalModal>
    </Fragment>
  );
}

const ScrollContainer = styled.ScrollView``;

const Wrapper = styled.View`
  flex: 1;
`;

const ButtonWrapper = styled.View`
  margin: 24px;
  padding-bottom: ${isIOS ? "8px" : "0px"};
`;
