import React, { useEffect, useState } from 'react';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { useI18n } from 'src/i18n/hooks';

interface BarDataProps {
  label: string;
  value: number;
  color: string;
}

interface ProgressBarProps {
  data: BarDataProps[];
  height?: number;
  width?: number;
  compact?: boolean;
}

const MultiColorProgressBar = (props: ProgressBarProps) => {
  const {
    data = [
      {
        label: '',
        value: 0,
        color: colors.white,
      },
    ],
    height = 20,
    width,
    compact = false,
  } = props;
  const { t } = useI18n();
  
  const [widths, setWidths] = useState<number[]>(
    data.map(() => {
      return 0;
    }),
  );
  const [maxWidth, setMaxWidth] = useState<number | null>(null);
  const [barData, setBarData] = useState<BarDataProps[]>([]);

  useEffect(() => {
    const maxWidth = Math.max(...data.map((o) => o.value));
    const temp = [...data];
    temp.push({ value: 100 - maxWidth, color: colors.bluishGreyBackground, label: 'Incomplete' });

    setBarData(temp);
    setMaxWidth(maxWidth);
    setWidths(temp.map((item) => item.value));
  }, [data]);

  return (
    <>
      <ContainerWrapper height={height} width={width} compact={compact}>
        {barData.map((item, index) => {
          return (
            <Tooltip title={compact ? `${t(item?.label)} (${item?.value}%)` : `${item?.value}%`}>
              <BarWrapper
                key={index}
                backgroundColor={item.color}
                width={widths[index]}
                index={index}
                length={barData.length}
                maxWidth={maxWidth}
              />
            </Tooltip>
          );
        })}
      </ContainerWrapper>
      {!compact && (
        <LegendWrapper>
          {barData?.map((item: BarDataProps) => {
            return (
              <LegendItemWrapper>
                <IndicatorWrapper backgroundColor={item?.color} />
                <BarListName>{t(item?.label)}</BarListName>
              </LegendItemWrapper>
            );
          })}
        </LegendWrapper>
      )}
    </>
  );
};

export default MultiColorProgressBar;

const BarListName = styled.text`
  color: ${colors.secondaryText};
  font-family: ${fonts.light};
  font-size: ${({ theme }) => theme.rem(1.5)};
  line-height: ${({ theme }) => theme.rem(1.8)};
`;

const ContainerWrapper = styled.div<{ height: number; compact: boolean; width?: number }>`
  height: ${(props) => `${props?.height}px`};
  width: ${(props) => (props?.width ? `${props.width}px` : 'auto')};
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background-color: ${colors.bluishGreyBackground};
  margin-bottom: ${(props) => props.compact ? 0 : 16}px;
`;

const BarWrapper = styled.div<{
  backgroundColor: string;
  width: number;
  index: number;
  length: number;
  maxWidth: number | null;
}>`
  display: flex;
  justify-content: flex-end;
  width: ${(props) => `${props.width}%`};
  height: 100%;
  background-color: ${(props) => props.backgroundColor};
  position: absolute;
  left: ${(props) => (props.index === props.length - 1 ? `${props.maxWidth}%` : '0%')};
  z-index: ${(props) => (props.index === 0 ? 2 : 1)};
  cursor: pointer;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const LegendItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IndicatorWrapper = styled.div<{ backgroundColor: string }>`
  width: ${({ theme }) => theme.rem(1.2)};
  height: ${({ theme }) => theme.rem(1.2)};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
`;
