import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import formJSON from '../../../form-json/academics/batch.json';
import Element from '../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useQuery } from '@apollo/client';
import { getModifiedClassesQuery } from 'src/graphql/academics/classes';
import AddDivisionInput from 'src/components/organism/Academics/AddDivisionInput';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import { useI18n } from 'src/i18n/hooks';
import GradingSystemRadioSelectTable from '../Table/GradingSystemRadioSelectTable';
import { getFilteredGradingSystems } from 'src/components/services';
import { GradingSystemObject } from 'src/components/organism/Academics/types';
import { GRADING_TYPE_LABEL } from 'src/constant';
import styled from 'styled-components/native';

export default function BatchForm(props: any) {
  const { t } = useI18n();
  const { setValue, isEditModal, editBatchData, divisionArray, setDivisionArray, setGradingSystemData, gradingSystemData } = props;
  const [elements, setElements] = useState<any>({});
  const [searchData] = useState('');
  const [gradingSystemModal, setGradingSystemModal] = useState<boolean>(false);
  const [tableData, setTableData] = useState([]);
  const [currentGradingSystemData, setCurrentGradingSystemData] = useState<GradingSystemObject | null>(null);
  const { fields }: any = elements ?? {};
  const allClasses = useQuery(getModifiedClassesQuery, { variables: { limit: 0 } });
  if (isEditModal) {
    formJSON[0].fields[2].disabled = true;
  } else {
    formJSON[0].fields[2].disabled = false;
  }

  useEffect(() => {
    const getdata = allClasses.data;
    if (allClasses.loading === false) {
      loadClasses(getdata.classes.data);
    }
  }, [allClasses.data]);

  function loadClasses(batchList: any) {
    setElements({});
    formJSON[0].fields[2].option = batchList;
    setElements(formJSON[0]);
  }

  useEffect(() => {
    if (isEditModal) {
      loadEditData();
    }
    return () => {
      formJSON[0].fields[4].fieldVisible = false;
      formJSON[0].fields[7].fieldVisible = false;
    }
  }, []);

  async function loadEditData() {
    if (isEditModal) {
      resetFormField();
    }
  }

  function resetFormField() {
    setValue('batch_name', editBatchData?.label);
    setValue('batch_alias', editBatchData?.alias);
    setValue('class', { label: editBatchData?.class?.alias });
    setValue('batch_start', editBatchData?.startDate);
    setValue('batch_end', editBatchData?.endDate);
    loadGradingSystems(editBatchData?.class?.course?.university?.id);
    if (editBatchData?.settings?.grade) {
      setGradingSystemData(editBatchData?.settings?.grade);
    }
    if (editBatchData?.divisionDetails) setDivisionArray(editBatchData?.divisionDetails);
    if (editBatchData?.gradingType) {
      if (editBatchData.gradingType === 'CREDIT_BASED_GRADING') {
        formJSON[0].fields[4].fieldVisible = true;
        formJSON[0].fields[7].fieldVisible = true;
      } else {
        formJSON[0].fields[4].fieldVisible = true;
        formJSON[0].fields[7].fieldVisible = false;
      }
      setValue('grading_type', { label: GRADING_TYPE_LABEL[editBatchData.gradingType], value: editBatchData.gradingType });
    }
    if (editBatchData?.settings?.grNoPrefix) {
      setValue('registration_no_prefix', editBatchData.settings.grNoPrefix);
    }
    if (editBatchData?.settings?.startingGRNo) {
      setValue('registration_no', editBatchData.settings.startingGRNo);
    }
    
  }

  function onGradeSystemSelection() {
    setGradingSystemModal(true);
    setCurrentGradingSystemData(gradingSystemData);
  }

  function handleCloseSelectGradingSystem() {
    setGradingSystemModal(false);
    setCurrentGradingSystemData(null);
  }

  function handleAddGradingSystem() {
    setGradingSystemModal(false);
    setGradingSystemData(currentGradingSystemData);
  }

  function handleDeleteGradingSystem() {
    setGradingSystemData(null);
    setCurrentGradingSystemData(null);
  }

  function handleCustomSelect(id: string, data: any) {
    if (id === 'class') {
      if (data?.course?.university?.id) {
        loadGradingSystems(data.course.university.id);
      } else {
        setTableData([]);
      }
    }
    if (id === 'grading_type') {
      const newData = { ...formJSON[0] };
      if (data) {
          newData.fields[4].fieldVisible = true;
          newData.fields[7].fieldVisible = true;
          handleDeleteGradingSystem();
      } else {
        newData.fields[4].fieldVisible = false;
        newData.fields[7].fieldVisible = false;
        handleDeleteGradingSystem();
      }
      setElements(newData);
    }
  }

  async function loadGradingSystems(universityId: string) {
    let gradingSystemsParam = {
      limit: 0,
      skip: 0,
      filters: {
        university: { eq: universityId },
      }
    }
    const gradingSystemsResponse = await getFilteredGradingSystems(gradingSystemsParam);
    setTableData(gradingSystemsResponse?.options || []);
  }

  return (
    <>
      <View style={styles.container}>
        {fields ? (
          <>
            {fields.map((field: any, i: number) => (
              field.fieldVisible &&
              <View style={styles.element} key={i}>
                <Element
                  key={`batch${i}`}
                  field={field}
                  control={props.control}
                  errors={props.errors}
                  inputWidth={350}
                  dropdownWidth={350}
                  searchData={searchData}
                  checkboxTableWidth={350}
                  onPress={onGradeSystemSelection}
                  displayText={gradingSystemData?.name || t('addGradingSystem.label')}
                  selectedValueLabel={gradingSystemData?.name}
                  onDelete={handleDeleteGradingSystem}
                  handleCustomSelect={handleCustomSelect}
                />
              </View>
            ))}
            <AddDivisionInput divisionArray={divisionArray} setDivisionArray={setDivisionArray} />
          </>
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </View>

      <NormalModal
        setModalVisible={handleCloseSelectGradingSystem}
        modalVisible={gradingSystemModal}
        Headerpopup={t('selectGradingSystem.label')}
        height={644}
        maxWidth={'lg'}
        hideCloseIcon={true}
        handleSave={handleAddGradingSystem}>
        <GradingSystemRadioSelectTable
          tableData={tableData}
          isDataAvailable={true}
          isEdit={isEditModal}
          setData={setCurrentGradingSystemData}
          data={currentGradingSystemData}
        />
      </NormalModal>
    </>
  );
}

const styles = StyleSheet.create({
  container: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' },
  element: { minWidth: 350, maxWidth: 350 },
  spinnerView: { width: 724, height: 565, justifyContent: 'center', alignItems: 'center' },
});


const HeadingWrapper = styled.View`
  margin-bottom: 24px;
`;