import { gql, useMutation, useLazyQuery } from '@apollo/client';

export const getExamDetailsQuery = gql`
  query(
    $filters: ExamDetailFilterInput
    $limit: Int
    $skip: Int
    $sort: ExamDetailSortInput
    $lookup: LookupFilter
  ) {
    examDetails(
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
      lookup: $lookup
    ) {
      data {
        id
        batch {
          id
          name
        }
        name
        tests {
          order
          subject {
            id
            name
            semester
          }
          tests {
            id
            name
            passingMarks
            totalMarks
            subject {
              id
              name
              semester
            }
          }
        }
      }
      totalCount
    }
  }
`;

export function useGetExamDetails() {
  const [getExamDetails, { loading: loadingExamDetails, data: dataExamDetails, error: errorExamDetails, refetch: refetchExamDetails }] =
    useLazyQuery(getExamDetailsQuery);
  return { getExamDetails, loadingExamDetails, dataExamDetails, errorExamDetails, refetchExamDetails };
}

const createExamDetailMutation = gql`
  mutation($payload: ExamDetailInput!) {
    createExamDetail(payload: $payload) {
      id
    }
  }
`;

export function useCreateExamDetail() {
  const [createExamDetail, { data: dataCreateExamDetail, error: errorCreateExamDetail }] = useMutation(createExamDetailMutation);
  return { createExamDetail, dataCreateExamDetail, errorCreateExamDetail };
}

export const getSubjectWiseClassTestDetailsQuery = gql`
  query(
    $filters: ClassTestDetailFilterInput
    $limit: Int
    $skip: Int
    $sort: ClassTestDetailSortInput
    $lookup: LookupFilter
  ) {
    subjectWiseClassTestDetails(
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
      lookup: $lookup
    ) {
      data {
        subject {
          id
          name
        }
        tests {
          id
          batch {
            id
          }
          subject {
            id
            name
          }
          name
        }
      }
    }
  }
`;

export function useGetSubjectWiseClassTestDetails() {
  const [getSubjectWiseClassTestDetails, { loading: SubjectWiseClassTestDetailsLoading, data: SubjectWiseClassTestDetailsData, error: SubjectWiseClassTestDetailsError }] =
    useLazyQuery(getSubjectWiseClassTestDetailsQuery);
  return { getSubjectWiseClassTestDetails, SubjectWiseClassTestDetailsData, SubjectWiseClassTestDetailsError, SubjectWiseClassTestDetailsLoading };
}

const updateExamDetailMutation = gql`
  mutation($payload: UpdateExamDetailInput!) {
    updateExamDetail(payload: $payload) {
      id
    }
  }
`;

export function useUpdateExamDetail() {
  const [updateExamDetail, { data: updateExamDetailData, error: updateExamDetailError }] = useMutation(updateExamDetailMutation);
  return { updateExamDetail, updateExamDetailData, updateExamDetailError };
}

const deleteExamMutation = gql`
  mutation ($id: String!) {
    deleteExam(id: $id)
  }
`;

export function useDeleteExam() {
  const [deleteExam, { data: deleteExamData, error: deleteExamError }] =
    useMutation(deleteExamMutation);
  return { deleteExam, deleteExamData, deleteExamError };
}