import React, { BaseSyntheticEvent } from 'react';
import { Controller } from 'react-hook-form';
import { View } from 'react-native';
import Input from '../../atoms/Input/input.web';
import CheckboxTable from '../Table/CheckboxTable.web';
import InputTable from '../Table/InputTable.web';
import SmallTextAtom from '../../atoms/Text/SmallTextAtom';
import Search from 'src/components/atoms/SearchBar/index.web';
import SearchDropdown from 'src/components/atoms/AutoComplete/index.web';
import { validation } from '../../../constant/validation';
import { colors } from '../../../styles/theme/styles';
import { regexObject } from '../../../constant/regex';
import Check from '../../atoms/CheckBox/index.web';
import FileUpload from 'src/components/molecules/FileUpload/index.web';
import { useThemeSystem } from 'src/contexts/theme-context';
import ImageUpload from '../ImageUpload/index.web';
import { userInformation } from 'src/utils/manageState';
import { EMPLOYEE_TYPES, USER_TYPE } from 'src/constant';
import { useI18n } from 'src/i18n/hooks';
import ToggleSwitch from 'src/components/atoms/Switch/Toggle.web';
import ColorPickerInput from 'src/components/atoms/Input/ColorPicker';
import NestedCheckboxTable from '../Table/NestedCheckboxTable.web';
import { FormElementProp } from './oldFormTypes';
import RichTextEditor from 'src/components/atoms/TextEditor/index.web';
import InstituteCoursesCheckboxTable from '../Table/InstituteCoursesCheckboxTable.web';
import ReservationGroupTable from '../Table/ReservationGroupTable';
import InputPassword from 'src/components/atoms/Input/Password/index.web';
import DateTimePicker from 'src/components/atoms/DateTime/DateTimePicker.web';
import { getFormattedDate, getInitialDateTimeValue } from 'src/components/atoms/DateTime/helpers';
import TriggerSelector from 'src/components/atoms/Button/TriggerSelector';
import DaySelectDropdown from 'src/components/atoms/DaySelectDropdown/DaySelectDropdown.web';
import styled from 'styled-components';
import { FormExpandCollapse } from 'src/components/atoms/FormExpandCollapse/FormExpandCollapse.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import RevalInput from 'src/components/molecules/AtktRevaIinput/Revalinput.web';
import ATKTInput from 'src/components/molecules/AtktRevaIinput/ATKTInput.web';

function createRules(
  isrequired: boolean,
  error_msg: string,
  validation_regex: any,
  required_error: string,
  customOnChange: any,
  handleCustomOnChange: any,
  dynamicValidation: boolean,
) {
  const rules = {} as any;
  let checkRequired = {
    value: isrequired,
    message: dynamicValidation ? required_error : validation[required_error] || required_error,
  };
  let checkRegex = { value: regexObject[validation_regex], message: validation[error_msg] };
  let validate = handleCustomOnChange;
  if (isrequired) {
    rules.required = checkRequired;
  }

  if (validation_regex) {
    rules.pattern = checkRegex;
  }
  if (customOnChange) {
    rules.validate = validate;
  }
  return rules;
}

const ApplicationElement = ({
  field: {
    type,
    id,
    label,
    required,
    validation_regex,
    error_msg,
    textHead,
    textFoot,
    option = [],
    multiline = false,
    required_error = '',
    disabled = false,
    customOnChange = null,
    textHeadVisiblity = true,
    fieldVisible = true,
    multipleSelection = false,
    multiple = false,
    hideVisibility = false,
    allowDecimalInput = false,
    ordering = false,
    prefix = '',
    dateTimeMode = "date",
    disableFuture,
    disablePast,
    dateTimePickerMinDate,
    dateTimePickerMaxDate,
    forceOpenAtBottom,
    textCount,
    inputHeight,
    allowedType,
    mode,
    compact,
    minValue = 0,
    maxValue,
    inputContainerWidth,
    customFormat,
    placeholder,
    disableInputConstraints = false,
    allowTriggerSelectorEdit = true,
    allowTriggerSelectorDelete = true,
    serviceOption=[],
    merchantOption=[],
  },
  control,
  handleCheck,
  checked = [],
  errors,
  inputWidth = 0,
  fileWidth,
  searchSelectScroll,
  searchSelectOnchange,
  dropdownWidth,
  dropdownInputHeight,
  maxDropdownOptionsHeight,
  dropdownMargin,
  searchBoxOnChange = null, //for search
  checkboxTableData = [] as any,
  setSelectedCheckboxValues = null,
  checkboxTableWidth,
  checkboxTableHeight,
  checkboxTableMaxHeight,
  handleCustomSelect = null,
  handleCustomOnChange = null,
  elementStyle = {},
  dynamicValidation = false,
  handleCheckbox = null,
  checkBoxState = false,
  file = {},
  handleFilesAttach,
  isCustomDisable = false,
  handleFileRemove = null,
  searchData = '',
  searchWidth,
  searchMaxWidth,
  authDisable = false,
  hideInput = false,
  switchState = false,
  handleSwitch = null,
  disablePaste = false,
  manualSelection = true,
  isEdit = false,
  inputTableData = [] as any,
  inputTableWidth,
  inputTableDataObj,
  setInputTableDataObj,
  dropDownData,
  clearErrors,
  setError,
  isDataAvailable = false,
  chipEdit,
  limitTags,
  setCheckBoxTableData,
  autoCompleteProps,
  onPress,
  onDelete,
  displayText,
  selectedValueLabel,
}: FormElementProp<true>) => {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const currentUserObject = userInformation();

  const { userType, userDetail } = currentUserObject;
  const getRules = createRules(
    required,
    error_msg,
    validation_regex,
    required_error,
    customOnChange,
    handleCustomOnChange,
    dynamicValidation,
  );

  if (isCustomDisable) {
    disabled = isCustomDisable;
  }

  if (
    userType === USER_TYPE.EMPLOYEE &&
    userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN) &&
    authDisable
  ) {
    disabled = true;
  }

  const validateInput = (value: any) => {
    const val = isNaN(value) ? parseInt(value) : value;
    if ((minValue || minValue === 0) && val < minValue) return '';
    if ((maxValue || maxValue === 0) && val > maxValue) return maxValue;
    return value;
  };


  if (fieldVisible) {
    switch (type) {
      case 'string':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Input
                  id={id}
                  header={textHead ? textHead : ''}
                  inputRef={ref}
                  value={value}
                  lebel={label}
                  setValue={(e: any) => {
                    if (!disableInputConstraints) {
                      if (multiline ? regexObject.ASCII_WITH_WHITESPACE.test(e.target.value) : regexObject.ASCII_ONLY.test(e.target.value)) {
                        onChange(e);
                        customOnChange ? handleCustomOnChange(e.target.value) : null;
                      } else {
                        // if invalid char input (!ASCII), store previously valid value or ""
                        onChange(value || "");
                        customOnChange ? handleCustomOnChange(value || "") : null;
                      }
                    } else {
                      // Empty check to enable deletion of solitary char
                      if (e.target.value || e.target.value === "") {
                        onChange(e);
                        customOnChange ? handleCustomOnChange(e.target.value) : null;
                      } else {
                        // if invalid char input (!ASCII), store previously valid value or ""
                        onChange(value || "");
                        customOnChange ? handleCustomOnChange(value || "") : null;
                      }
                    }
                  }}
                  onBlur={(e: any) => {
                    onChange(e.target.value.trim());
                    customOnChange ? handleCustomOnChange(e.target.value.trim()) : null;
                  }}
                  disabled={disabled}
                  notefooter={textFoot && !errors[id] ? textFoot : ''}
                  width={inputWidth && inputWidth}
                  multiline={multiline}
                  disablePaste={disablePaste}
                  hideVisibility={hideVisibility}
                  prefix={prefix}
                  inputHeight={inputHeight}
                />
              )}
              onChange={(data) => data}
              rules={getRules}
              defaultValue={""}
            />

            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        );

      case 'password':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <InputPassword
                  id={id}
                  header={textHead ? textHead : ''}
                  inputRef={ref}
                  setValue={(e) => {
                    onChange(e);
                    customOnChange ? handleCustomOnChange(e.target.value) : null;
                  }}
                  notefooter={textFoot && !errors[id] ? textFoot : ''}
                  disablePaste={disablePaste}
                />
              )}
              rules={getRules}
            />

            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        );

      case 'date-time-picker':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <DateTimePicker
                  initialValue={value ? getInitialDateTimeValue<true>(value, dateTimeMode, true) : null}
                  dateTimePickerTitle={t(textHead)}
                  dateTimeMode={dateTimeMode}
                  minDate={dateTimePickerMinDate ? getInitialDateTimeValue<true>(dateTimePickerMinDate, dateTimeMode) : undefined}
                  maxDate={dateTimePickerMaxDate ? getInitialDateTimeValue<true>(dateTimePickerMaxDate, dateTimeMode) : undefined}
                  forceOpenAtBottom={forceOpenAtBottom}
                  onDateTimeChangeCallback={(selectedDate: Date) => {
                    if (selectedDate) {
                      const formattedDate: string = getFormattedDate(selectedDate, dateTimeMode, true);
                      if (customOnChange && handleCustomSelect && formattedDate) {
                        handleCustomSelect(id, formattedDate)
                      }
                      onChange(formattedDate);
                    }
                  }}
                  disableFuture={disableFuture}
                  disablePast={disablePast}
                  disabled={disabled}
                  containerWidth={inputContainerWidth}
                  customFormat={customFormat}
                />
              )}
              defaultValue={null}
              rules={{
                validate: {
                  checkRequired: (value) => {
                    if (required) {
                      return value ? true : validation[required_error];
                    }
                  },
                },
              }}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        );

      case 'select':
        const wrapperStyle: any = {
          marginBottom: dropdownMargin ? 0 : theme?.searchDropdown?.marginBottom,
        }

        if (autoCompleteProps?.disablePortal) {
          wrapperStyle.zIndex = 'initial'
        }

        return (
          <View
            style={wrapperStyle}>
            <Controller
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <SearchDropdown
                  name={id}
                  inputRef={ref}
                  options={option}
                  value={value}
                  header={textHead ? textHead : ''}
                  searchSelectScroll={searchSelectScroll}
                  searchSelectOnchange={searchSelectOnchange}
                  width={dropdownWidth}
                  height={dropdownInputHeight}
                  disabled={disabled}
                  //onChange={(e, data) => onChange(data)}
                  onChange={(e, data: any) => {
                    customOnChange ? handleCustomSelect(id, data) : null;
                    onChange(data);
                  }}
                  multipleSelection={multipleSelection}
                  isEdit={isEdit}
                  chipEdit={chipEdit}
                  limitTags={limitTags}
                  maxHeight={maxDropdownOptionsHeight}
                  textCount={textCount}
                  autoCompleteProps={autoCompleteProps}

                />
              )}
              onChange={([, data]) => data}
              name={id}
              control={control}
              rules={getRules}
              defaultValue={null}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </View>
        );

      case 'customselect':
        return (
          <View>
            <Controller
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <SearchDropdown
                  name={id}
                  inputRef={ref}
                  options={option}
                  value={value}
                  header={textHead ? textHead : ''}
                  searchSelectScroll={searchSelectScroll}
                  searchSelectOnchange={searchSelectOnchange}
                  width={dropdownWidth}
                  disabled={disabled}
                  onChange={(e, data: any) => {
                    handleCustomSelect(id, data);
                    onChange(data);
                  }}
                  multipleSelection={multipleSelection}
                  isEdit={isEdit}
                  maxHeight={maxDropdownOptionsHeight}
                />
              )}
              onChange={([, data]: any) => data}
              name={id}
              control={control}
              rules={getRules}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </View>
        );

      case 'search':
        return (
          <View style={{ maxWidth: searchMaxWidth }}>
            <Search
              id={id}
              label={label}
              value={searchData}
              handleChange={searchBoxOnChange}
              header={textHead ? textHead : ''}
              notefooter={textFoot ? textFoot : ''}
              width={searchWidth > 0 ? searchWidth : null}
              maxWidth={searchMaxWidth > 0 ? searchMaxWidth : null}
            />
          </View>
        );

      case 'checkbox':
        return (
          <ComponentWrapper>
            <Check
              control={control}
              id={id}
              header={textHead ? textHead : ''}
              style={elementStyle}
              onChange={handleCheckbox}
              checked={checkBoxState}
            />
          </ComponentWrapper>
        );

      case 'checkbox_controlled':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Check
                  same={false}
                  control={control}
                  id={id}
                  header={textHead ? textHead : ''}
                  style={elementStyle}
                  onChange={(currentVal: boolean) => {
                    if (customOnChange && handleCustomSelect) {
                      handleCustomSelect(id, currentVal)
                    }
                    onChange(currentVal);
                  }}
                  checked={!!value}
                />
              )}
              defaultValue={false}
            />
          </ComponentWrapper>
        );

      case 'table':
        return (
          <View>
            <CheckboxTable
              header={textHead}
              tabledata={checkboxTableData}
              checked={checked}
              handleChecked={handleCheck}
              width={checkboxTableWidth}
              height={checkboxTableHeight}
              maxHeightTable={checkboxTableMaxHeight}
              manualSelection={manualSelection}
              isDataAvailable={isDataAvailable}
              ordering={ordering}
              setTableData={setCheckBoxTableData}
              textCount={textCount}
            />
          </View>
        );

      case 'instituteCoursesTable':
        return (
          <View>
            <InstituteCoursesCheckboxTable
              header={textHead}
              tabledata={checkboxTableData}
              checked={checked}
              handleChecked={handleCheck}
              width={checkboxTableWidth}
              manualSelection={manualSelection}
              height={checkboxTableHeight}
              maxHeightTable={checkboxTableMaxHeight}
              isDataAvailable={isDataAvailable}
            />
          </View>
        );

      case 'nestedCheckboxTable':
        return (
          <View>
            <NestedCheckboxTable
              header={textHead}
              tabledata={checkboxTableData}
              setSelected={setSelectedCheckboxValues}
              width={checkboxTableWidth}
            />
          </View>
        );

      case 'inputTable':
        return (
          <View>
            <InputTable
              header={textHead}
              tabledata={inputTableData}
              width={inputTableWidth}
              control={control}
              name={id}
              customOnChange={customOnChange}
              handleCustomOnChange={handleCustomOnChange}
              inputTableDataObj={inputTableDataObj}
              setInputTableDataObj={setInputTableDataObj}
              rules={getRules}
              dropDownData={dropDownData}
            />
          </View>
        );

      case 'reservationGroupTable':
        return (
          <View>
            <ReservationGroupTable
              header={textHead}
              tabledata={inputTableData}
              customOnChange={customOnChange}
              handleCustomOnChange={handleCustomOnChange}
              setInputTableDataObj={setInputTableDataObj}
              isDataAvailable={isDataAvailable}
            />
          </View>
        );

      case 'file':
        return (
          <ComponentWrapper>
            <Controller
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FileUpload
                  file={file}
                  inputRef={ref}
                  handleFilesAttach={handleFilesAttach}
                  handleFileRemove={handleFileRemove}
                  name={id}
                  header={textHead && textHeadVisiblity ? textHead : ''}
                  width={fileWidth}
                  multiple={multiple}
                  fieldId={id}
                  setError={setError}
                  clearErrors={clearErrors}
                  errorMsgText={errors[id] && !file.uri ? t(errors[id].message) : ''}
                  compact={compact}
                />
              )}
              rules={getRules}
              control={control}
              name={id}
            />
          </ComponentWrapper>
        );

      case 'imageUpload':
        return (
          <ComponentWrapper>
            <Controller
              render={({ field }) => (
                <ImageUpload
                  img={file?.uri}
                  inputRef={field.ref}
                  onChange={handleFilesAttach}
                  name={id}
                  handleFileRemove={handleFileRemove}
                  height={inputHeight || 160}
                  header={textHead && textHeadVisiblity ? textHead : ''}
                  multiple={multiple}
                  fieldId={id}
                  setError={setError}
                  clearErrors={clearErrors}
                  allowedType={allowedType}
                  mode={mode}
                />
              )}
              rules={getRules}
              control={control}
              name={id}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        );

      // Manually / state controlled switch. should ideally be deprecated in favour of controlled "toggle-switch" below
      case 'switch':
        return (
          <ComponentWrapper>
            <ToggleSwitch
              control={control}
              id={id}
              header={textHead ? textHead : ''}
              style={elementStyle}
              onChange={handleSwitch}
              enabled={switchState}
            />
          </ComponentWrapper>
        );

      // Hook Form Controlled toggle switch
      case 'toggle-switch':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <ToggleSwitch
                  id={id}
                  header={textHead ? textHead : ''}
                  style={elementStyle}
                  onChange={(e: BaseSyntheticEvent) => {
                    const toggleState: boolean = e.target.checked;
                    if (customOnChange && handleCustomSelect) {
                      handleCustomSelect(id, toggleState)
                    }
                    onChange(toggleState);
                  }}
                  enabled={!!value}
                />
              )}
              defaultValue={false}
            />
          </ComponentWrapper>
        );

      case 'colorpicker':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <ColorPickerInput
                  id={id}
                  header={textHead ? textHead : ''}
                  inputRef={ref}
                  value={value}
                  lebel={label}
                  setValue={(e) => {
                    onChange(e);
                    customOnChange ? handleCustomSelect(id, e.target.value) : null;
                  }}
                  disabled={disabled}
                  notefooter={textFoot && !errors[id] ? textFoot : ''}
                  width={inputWidth && inputWidth}
                  multiline={multiline}
                />
              )}
              rules={{
                validate: {
                  checkRequired: (value) => {
                    if (required) {
                      return value ? true : validation[required_error];
                    }
                  },
                  checkValidColor: (value) => {
                    const reg = /^#[0-9A-F]{6}$/i;
                    const result = reg.test(value);
                    return result ? result : validation[error_msg];
                  },
                },
              }}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        );
      case 'number':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, value, ref } }) => (
                <Input
                  typeInput={'number'}
                  id={id}
                  header={textHead ? textHead : ''}
                  inputRef={ref}
                  value={value}
                  lebel={label}
                  setValue={(e: any) => {
                    onChange(validateInput(e.target.value));
                    customOnChange ? handleCustomOnChange(value) : null;
                  }}
                  disabled={disabled}
                  notefooter={textFoot && !errors[id] ? textFoot : ''}
                  width={inputWidth && inputWidth}
                  multiline={multiline}
                  onkeyDown={(e: any) => {
                    if ((['e', 'E', '-', '+'].includes(e.key)) || (!allowDecimalInput && e.key === '.')) {
                      const reg = regexObject['INTGER_ONLY'];
                      const result = reg.test(e.key);
                      if (!result) {
                        e.preventDefault();
                      }
                    }
                  }}
                />
              )}
              rules={getRules}
              defaultValue={""}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        );
      case 'number-negative':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, value, ref } }) => (
                <Input
                  typeInput={'number'}
                  id={id}
                  header={textHead ? textHead : ''}
                  inputRef={ref}
                  value={value}
                  label={label}
                  setValue={(e: any) => {
                    if(isNaN(e.target.value)){
                      onChange(0)
                    }
                    else{
                      if(Number(e.target.value)){
                        onChange(e.target.value)
                      }
                    }
                  }}
                  disabled={disabled}
                  notefooter={textFoot && !errors[id] ? textFoot : ''}
                  width={inputWidth && inputWidth}
                  multiline={multiline}
                  onKeyDown={(e: any) => {
                    // Allow '-' only if it is at the start of the input
                    if (e.key === '-' && (value || '').toString().length > 0) {
                      e.preventDefault();
                    }

                    // Disallow invalid keys (e, E, +) and '.' if decimals are not allowed
                    if (
                      (['e', 'E', '+'].includes(e.key)) || 
                      (!allowDecimalInput && e.key === '.')
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              )}
              rules={getRules}
              defaultValue={""}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        );
      case 'texteditor':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <RichTextEditor
                  id={id}
                  header={textHead ? textHead : ''}
                  data={value}
                  label={label}
                  inputRef={ref}
                  setData={(e, editor) => {
                    onChange(editor.getData());
                  }}
                  disabled={disabled}
                  notefooter={textFoot && !errors[id] ? textFoot : ''}
                  width={inputWidth && inputWidth}
                  clearErrors={clearErrors}
                />
              )}
              rules={getRules}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        );

      // Manually / state controlled switch. should ideally be deprecated in favour of controlled "trigger-selection" below
      case 'trigger-selection':
        return (
          <ComponentWrapper>
            <TriggerSelector
              inputContainerWidth={inputContainerWidth || 480}
              headerLabel={t(textHead)}
              selectedValueLabel={selectedValueLabel || ''}
              displayText={displayText ? t(displayText) : ''}
              onPress={!onPress || disabled ? () => { } : onPress}
              onDelete={onDelete}
              textColor={colors.secondaryText}
            />
          </ComponentWrapper>
        )

      // Hook Form Controlled trigger selector
      case 'trigger-selector':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <TriggerSelector
                  inputContainerWidth={"100%"}
                  headerLabel={t(textHead)}
                  selectedValueLabel={value?.value || ""}
                  displayText={value?.label ? value.label : t(placeholder ?? "")}
                  onPress={() => {
                    if (!disabled && customOnChange && handleCustomSelect) {
                      handleCustomSelect(id, value);
                    }
                  }}
                  onDelete={() => onChange(null)}
                  textColor={colors.secondaryText}
                  disabled={disabled}
                  showEdit={allowTriggerSelectorEdit}
                  showDelete={allowTriggerSelectorDelete}
                />
              )}
              defaultValue={null}
              rules={{
                validate: {
                  checkRequired: (value) => {
                    if (required) {
                      return !!value?.value
                        ? true
                        : validation[required_error];
                    }
                  },
                },
              }}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        )

      case 'formExpandCollapse':
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormExpandCollapse
                  expanded={!!value}
                  onChangeCallback={(currentValue: boolean) => {
                    onChange(currentValue);
                    if (customOnChange && handleCustomSelect) {
                      handleCustomSelect(id, currentValue);
                    }
                  }}
                  disabled={!!disabled}
                />
              )}
              defaultValue={false}
            />
          </ComponentWrapper>
        )

      case "day-selector":
        return (
          <ComponentWrapper>
            <Controller
              control={control}
              name={id}
              render={({ field: { onChange, value } }) => (
                <DaySelectDropdown
                  initialValues={value ? [value] : []}
                  disabled={disabled}
                  onChangeCallback={(days) => {
                    onChange(days);
                    if (customOnChange && handleCustomSelect && days) {
                      handleCustomSelect(id, days);
                    }
                  }}
                />
              )}
              defaultValue={null}
              rules={{
                validate: {
                  checkRequired: (value) => {
                    if (required) {
                      return Array.isArray(value) && value.length > 0
                        ? true
                        : validation[required_error];
                    }
                  },
                },
              }}
            />
            {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </ComponentWrapper>
        )

      case "revalInput":
        return(
          <>
          <RevalWarapper>
            <Controller
            control={control}
            name={id}
            render={({ field: { value, onChange } }) => (
              <RevalInput
                initialValues={value}
                handleAddChange={(data)=>{onChange(data)}}
                casteCategoryOptions={option}
                serviceOptions={serviceOption}
                merchantOptions={merchantOption}
              />
            )}
            rules={{ 
              validate: {
                checkRequired: (value) => {
                  return required && (!value || !(value.merchantId && value.paymentProvider && value.fees))
                    ? validation[required_error]
                    : true;
                },
              },
            }}            
          />
          </RevalWarapper>
          {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </>
        )

        case "atktInput":
        return(
          <>
          <RevalWarapper>
            <Controller
            control={control}
            name={id}
            render={({ field: { onChange, value } }) => (
              <ATKTInput
                initialValues={value}
                handleAddChange={(data)=>{onChange(data)}}
                serviceOptions={serviceOption}
                merchantOptions={merchantOption}
              />
            )}
            rules={{ 
              validate: {
                checkRequired: (value) => {   
                  return required && (!value || !(value.merchantId && value.paymentProvider && value.subjectWiseFees))
                    ? validation[required_error]
                    : true;        
                },
              },
            }}
          />
          </RevalWarapper>
          {errors[id] && <ErrorMsg value={t(errors[id].message)} testID={`${id}-error`} />}
          </>
        )
          

      case "note":
        return (
          <NoteWrapper>
            <NormalTextSelect key={id} value={label ?? "-"} />
          </NoteWrapper>
        )

      default:
        return null;
    }
  } else {
    return null;
  }
};

const ErrorMsg = ({ value, testID }: { value: string, testID?: string }) => (
  <ErrorWrapper>
    <SmallTextAtom value={value} color={colors.errorColor} testID={testID} />
  </ErrorWrapper>
);

const ErrorWrapper = styled.div`
  margin-top: 4px;
`;

const ComponentWrapper = styled.div`
  margin-bottom: 24px;
`;

const NoteWrapper = styled.div`
  height: 100%;
  padding-bottom: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const RevalWarapper = styled.div`
`;

export default ApplicationElement;
