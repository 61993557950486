import styled from 'styled-components';
import React from 'react';

export const Empty = () => {
  return (<InputContainer></InputContainer>)
}

/*Styled - components*/

const InputContainer = styled.div`
  margin-bottom: 0;
`