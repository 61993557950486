import React from 'react';
import { DescriptionCell, HeaderCell } from './GradeCardElements';
import { Table, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import styled from 'styled-components/native';
import { properCase } from 'src/utils/utility';
import { colors, fonts } from 'src/styles/theme/styles';

export default function TableTwoGradeCard(props: { data: any; }) {
  const { data } = props;
  const widthMultiplier: number = 450;

  let PartOneHeaders = [
    {
      id: 'studentName',
      label: 'STUDENT NAME',
      colspan: 1
    }
  ];

  let PartOneRows = [
    {
      id: 'studentName',
      label: properCase(data?.user?.personalDetails?.fullName || '-'),
      colspan: 1
    }
  ];

  let PartTwoHeaders = [
    {
      id: 'specialMessage',
      label: 'SPECIAL MESSAGE',
      colspan: 1
    }
  ];

  let PartTwoRows = [
    {
      id: 'specialMessage',
      label: 'N/A',
      colspan: 1
    }
  ];

  return (
    <TableTwoContainer>
      <TableContainer>
        <Table sx={{fontFamily: fonts.regular}}>
          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              {
                PartOneHeaders.map((element) => {
                  return (
                    <HeaderCell width={widthMultiplier * element?.colspan} value={element?.label} />
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {
                PartOneRows.map((element) => {
                  return (
                    <DescriptionCell width={widthMultiplier * element?.colspan} value={element?.label} />
                  );
                })
              }
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow sx={{backgroundColor: colors.greyBackground}}>
              {
                PartTwoHeaders.map((element) => {
                  return (
                    <HeaderCell width={widthMultiplier * element?.colspan} value={element?.label} />
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              PartTwoRows.map((element) => {
                return (
                  <DescriptionCell width={widthMultiplier * element?.colspan} value={element?.label} />
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </TableTwoContainer>
  );
}

const TableTwoContainer = styled.View`
  width: 450px;
 `;