import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { Icon } from 'src/components/atoms/Icon/Icon';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import NormalTextAtom from 'src/components/atoms/Text/NormalTextAtom';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import registrationFormJSON from '../../../form-json/registration/registration.json';
import Element from '../Forms/ApplicationElement.web';

interface Props {
  control: any;
  errors: any;
  setError: any;
  clearErrors: any;
  handleSubmit: any;
  registerApplicant: Function;
  canClick: boolean;
  isSubmitting: any;
  history: any;
}

export default function RegistrationForm(props: Props) {
  const {
    control,
    errors,
    setError,
    clearErrors,
    handleSubmit,
    registerApplicant,
    canClick,
    isSubmitting,
    history,
  } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    setElements(registrationFormJSON[0]);
  }, []);

  return (
    <>
      {fields ? (
        <>
          <CenterView marginTopVal={20}>
            <Icon name={'edba-logo'} size={56} />
          </CenterView>

          <CenterView marginTopVal={30}>
            <HeaderThree value={'Hello Applicant!'} lineHeight={32} />
          </CenterView>

          <CenterView>
            <NormalTextAtom
              style={{ textAlign: 'center' }}
              value={'Please provide the following details to finish setting up your account.'}
            />
          </CenterView>

          <InputView>
            {fields.map((field: any, i: number) => (
                <View key={`form${i}`}>
                <Element
                    key={i}
                    field={field}
                    control={control}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                />
                </View>
            ))}
          </InputView>

          <ButtonView>
            <SecondaryBtn
              canLoad={!canClick}
              disabled={isSubmitting}
              label={'Sign Up'}
              onPress={handleSubmit(registerApplicant)}
              style={{ paddingVertical: 8 }}
              lineHeight={2.4}
            />

            <LinkView>
              <TouchableOpacity
                onPress={() => history.push('/login')}>
                <NormalTextAtom
                  value={'Back to Sign In'}
                  color={colors.primary}
                  fontSize={1.34}
                />
              </TouchableOpacity>
            </LinkView>
          </ButtonView>
        </>
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </>
  );
}

const InputView = styled.View`
  margin-top: 10px;
`;

const CenterView = styled.View<{ marginTopVal: number }>`
  justify-content: center;
  align-items: center;
  margin-top: ${({ marginTopVal }) => marginTopVal};
`;

const SpinnerView = styled.View`
  height: 578px;
  justify-content: center;
  align-items: center;
`;

const LinkView = styled.View`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-self: center;
`;

const ButtonView = styled.View`
`;
