import React, { useEffect, useState } from 'react';
import { ApplicationItemType } from 'src/components/organism/Applications/types';
import { ApplicationStatus } from 'src/constant';
import styled from 'styled-components/native';
import { colors, fontWeight, fonts } from 'src/styles/theme/styles';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { getApplicationFeeBookQuery } from 'src/graphql/payment';
import { graphqlQuery } from 'src/graphql/util';
import cancelAdmissionStatusCompleted from 'src/form-json/admission/cancel-admission-status-completed.json';
import Element from 'src/components/molecules/Forms/ApplicationElement.web';
import CreateInfoItem from '../TextComponents/InfoItem';
import { useI18n } from 'src/i18n/hooks';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';

interface Props {
  control: any;
  errors: any;
  reset: any;
  setValue: any;
  clearErrors: any;
  setError: any;
  getValues: any;
  applicationDetail: ApplicationItemType | null;
  setDisableCancelModal: Function;
  disableCancelModal: boolean;
  cancellationAmount: number | null;
  setCancellationAmount: Function;
}

export default function CancelAdmissionForm(props: Props) {
  const {
    applicationDetail,
    control,
    errors,
    reset,
    setValue,
    clearErrors,
    setError,
    getValues,
    setDisableCancelModal,
    disableCancelModal,
    cancellationAmount,
    setCancellationAmount,
  } = props;
  const [applicationStatus, setApplicationStatus] = useState<string>('');
  const [elements, setElements] = useState({});
  const [totalPayable, setTotalPayable] = useState<number | null>(null);
  const [totalPaid, setTotalPaid] = useState<number | null>(null);
  const [chargeType, setChargeType] = useState<string>('');
  const { fields }: any = elements ?? {};
  const { t } = useI18n();
  const [errMsg, setErrMsg] = useState<string>('');

  useEffect(() => {
    return () => {
      cancelAdmissionStatusCompleted[0].fields[1].fieldVisible = false;
      cancelAdmissionStatusCompleted[0].fields[2].fieldVisible = false;
    };
  }, []);

  useEffect(() => {
    if (applicationDetail && Object.keys(applicationDetail).length > 0) {
      handleFetchFeeBooks();
      setApplicationStatus(applicationDetail?.status);
    }
  }, [applicationDetail]);

  async function handleFetchFeeBooks() {
    const feeBooks = await graphqlQuery(
      getApplicationFeeBookQuery,
      { applicationId: applicationDetail?.id },
      false,
    );
    setTotalPayable(feeBooks?.data?.getApplicationFeeBook?.totalPayable);
    setTotalPaid(feeBooks?.data?.getApplicationFeeBook?.totalPaid);
    setElements(cancelAdmissionStatusCompleted[0]);
  }

  function handleCancelChargesChange(amount: string, idx: number) {
    if (totalPayable) {
      const cancelAmount = parseFloat(amount);
      if (idx == 1 && cancelAmount >= 0 && cancelAmount <= 100) {
        setDisableCancelModal(false);
        clearErrorMessages();
        const amount = Math.ceil((totalPayable / 100) * cancelAmount);
        if (applicationStatus === ApplicationStatus.ADMISSION_COMPLETED) {
          if (totalPaid && amount > totalPaid) {
            setDisableCancelModal(true);
          } else {
            setDisableCancelModal(false);
          }
        }
        setCancellationAmount(amount);
      } else if (idx === 2 && cancelAmount >= 0 && cancelAmount <= totalPayable) {
        setDisableCancelModal(false);
        clearErrorMessages();
        if (applicationStatus === ApplicationStatus.ADMISSION_COMPLETED) {
          if (totalPaid && cancelAmount > totalPaid) {
            setDisableCancelModal(true);
          } else {
            setDisableCancelModal(false);
          }
        }
        setCancellationAmount(cancelAmount);
      } else if (cancelAmount > totalPayable) {
        setErrMsg(t('error.cancellationAmount.message'));
        clearCancellationAmount();
        setDisableCancelModal(true);
      } else {
        clearErrorMessages();
        clearCancellationAmount();
        setDisableCancelModal(false);
      }
    }
  }

  function clearCancellationAmount() {
    setCancellationAmount(null);
  }

  function clearErrorMessages() {
    clearErrors();
    setErrMsg('');
  }

  const handleCustomSelect = async (id: string, data: any) => {
    const newData = { ...cancelAdmissionStatusCompleted[0] };
    if (data) {
      if (data.value === 'Percentage') {
        newData.fields[1].fieldVisible = true;
        newData.fields[2].fieldVisible = false;
        setValue('amount', '');
      } else {
        newData.fields[1].fieldVisible = false;
        newData.fields[2].fieldVisible = true;
        setValue('feePercentage', '');
      }
      setChargeType(data.value);
    } else {
      newData.fields[1].fieldVisible = false;
      newData.fields[2].fieldVisible = false;
      setChargeType('');
      reset({});
    }
    setElements(newData);
    clearCancellationAmount();
    setDisableCancelModal(false);
    clearErrorMessages();
  };

  return (
    <>
      {applicationDetail &&
        Object.keys(applicationDetail)?.length > 0 &&
        (fields ? (
          <FormWrapper>
            <ModalText>
              <ModalText>{t('cancelAdmission.info.text1')}</ModalText>
              <ModalText
                style={{
                  fontWeight: fontWeight[600],
                  color: colors.primaryText,
                }}>{` \u20B9 ${totalPayable || '-'}`}</ModalText>
            </ModalText>
            <TotalPaidWrapper>
              <CreateInfoItem
                label={t('totalPaid.label')}
                value={totalPaid || totalPaid == 0 ? `\u20B9 ${totalPaid}` : '-'}
              />
            </TotalPaidWrapper>
            <CancelFeeWrapper>
              {fields.map(
                (field: any, i: number) =>
                  field.fieldVisible && (
                    <FormElement idx={i}>
                      <ElementWrapper>
                        <Element
                          key={`e${i}`}
                          field={field}
                          control={control}
                          errors={errors}
                          handleCustomOnChange={(amount: string) => handleCancelChargesChange(amount, i)}
                          handleCustomSelect={handleCustomSelect}
                        />
                        {i === 2 && errMsg && (
                          <ErrorMessageWrapper>
                            <SmallTextAtom value={errMsg} color={colors.errorColor} />
                          </ErrorMessageWrapper>
                        )}
                      </ElementWrapper>
                      {i == 1 && (
                        <MediumTextSelect
                          value={'%'}
                          style={{ top: 28, left: 12, position: 'relative' }}
                        />
                      )}
                    </FormElement>
                  ),
              )}
            </CancelFeeWrapper>
            {chargeType === 'Percentage' && (
              <CreateInfoItem
                label={t('cancellationAmount.label')}
                value={
                  cancellationAmount || cancellationAmount == 0
                    ? `\u20B9 ${cancellationAmount}`
                    : '-'
                }
              />
            )}
            {disableCancelModal &&
            cancellationAmount &&
            totalPaid &&
            applicationStatus === ApplicationStatus.ADMISSION_COMPLETED ? (
              <ErrorPaymentWrapper>
                <NormalTextSelect
                  value={`Paid \u20B9 ${totalPaid}, and ask the applicant to make an additional partial payment of \u20B9 ${
                    cancellationAmount - totalPaid
                  } to start the cancellation process.`}
                  color={colors.red}
                />
              </ErrorPaymentWrapper>
            ) : null}
            {cancellationAmount && applicationStatus === ApplicationStatus.PAYMENT_AWAITED ? (
              <ErrorPaymentWrapper>
                <NormalTextSelect
                  value={`Ask the applicant to make a partial payment of \u20B9 ${cancellationAmount} to start the cancellation process.`}
                  color={colors.primaryColor}
                />
              </ErrorPaymentWrapper>
            ) : null}
          </FormWrapper>
        ) : (
          <SpinnerView>
            <LoaderSpinner />
          </SpinnerView>
        ))}
    </>
  );
}

const FormWrapper = styled.View`
  min-height: 380px;
`;

const ModalText = styled.Text`
  font-size: 16px;
  line-height: 24px;
  font-family: ${fonts.regular};
  color: ${colors.secondaryText};
`;

const CancelFeeWrapper = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 24px;
`;

const FormElement = styled.View<{ idx: number }>`
  display: ${({ idx }) => (idx !== 0 ? 'flex' : 'inline')};
  flex-direction: row;
`;

const SpinnerView = styled.View`
  width: 480px;
  height: 380px;
  justify-content: center;
  align-items: center;
`;

const TotalPaidWrapper = styled.View`
  margin-top: 16px;
`;

const ErrorPaymentWrapper = styled.View`
  margin-vertical: 16px;
  width: 90%;
  position: absolute;
  bottom: 0px;
`;

const ElementWrapper = styled.View`
  width: 350px;
`;

const ErrorMessageWrapper = styled.View`
  margin-top: -20px;
`;