import { gql, useLazyQuery, useMutation } from "@apollo/client";

const createMerchantDetailMutation = gql`
  mutation($payload: NDPSMerchantInput!) {
    createNTTMerchant(payload: $payload) {
      id
      name
    }
  }
`;

export function useCreateMerchantDetail() {
  const [
    createMerchantDetail,
    { data: createMerchantDetailData, error: createMerchantDetailError },
  ] = useMutation(createMerchantDetailMutation);
  return {
    createMerchantDetail,
    createMerchantDetailData,
    createMerchantDetailError,
  };
}

const getMerchantDetailsQuery = gql`
  query(
    $filters: NDPSMerchantFilterInput
    $limit: Int
    $skip: Int
    $sort: NDPSMerchantSortInput
  ) {
    NTTMerchants(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        name
        institute {
          label: name
          value: id
        }
        mcc
        env {
          label
          value
        }
        merchId
        merchPassword
        name
        prodId
        reqEncKey
        reqSalt
        resDecKey
        reqSalt
        resSalt
        reqHashKey
        resHashKey
      }
      totalCount
    }
  }
`;

export function useGetMerchantDetails() {
  const [
    getMerchantDetails,
    {
      loading: MerchantDetailsLoading,
      data: MerchantDetailsData,
      error: MerchantDetailsError,
      refetch: refetchMerchantDetails,
    },
  ] = useLazyQuery(getMerchantDetailsQuery, { fetchPolicy: "network-only" });
  return {
    getMerchantDetails,
    MerchantDetailsData,
    MerchantDetailsError,
    MerchantDetailsLoading,
    refetchMerchantDetails,
  };
}

export const getMerchantListOptionsQuery = gql`
  query {
    NTTMerchants(limit: 0) {
      data {
        label: name
        value: id
      }
    }
  }
`;
