import { TableBody } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import { NoRecordsFound, TableCells, TableRows } from './TableAtom';
import useTable from './UseTable';

interface Props {
  maxHeightTable: number;
  header: Array<Object>;
  tabledata: Array<Object>;
  setData: any;
  height?: number | string;
  isDataAvailable: boolean;
  checkError: boolean;
}

export default function CreditsTable(props: Props) {
  const {
    maxHeightTable,
    header,
    tabledata,
    setData,
    height = 'auto',
    isDataAvailable,
    checkError,
  } = props;
  const { TblContainer, TblHead } = useTable(tabledata, header, null, tabledata);
  const [value, setValue] = useState({});
  const { t } = useI18n();

  const handleChange = (value: any, id: string) => {
    setValue((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (tabledata && tabledata[0]?.credit) {
      let items = {...value};
      tabledata?.forEach((item: any, index: number) => {
        items[item?.id] = {
          label: item?.credit,
          value: item?.credit,
        };
      });
      setValue(items);
    }
  }, [tabledata]);

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      const result = Object.keys(value).flatMap((key) => {
        if (value[key]) {
          return {
            subject: key,
            credit: parseFloat(value[key]?.value),
          };
        }
        return [];
      });
      setData(result);
    }
  }, [value]);

  return (
    <TblContainer
      tabledata={tabledata}
      maxHeight={maxHeightTable && maxHeightTable}
      height={height && height}>
      <TblHead />
      {tabledata && tabledata?.length > 0 && (
        <TableBody>
          {tabledata?.map((item: any, i: number) => (
            <TableRows key={i}>
              <TableCells value={item?.name} style={{ width: 250 }} />
              <TableCells align={'right'} value={item?.defaultCreditPoint || '-'} />
            </TableRows>
          ))}
        </TableBody>
      )}
      {isDataAvailable && tabledata?.length === 0 && (
        <NoRecordsFound colspan={7} maxHeight={maxHeightTable} />
      )}
    </TblContainer>
  );
}

const TableContainer = styled.View<{ width: number | string }>`
  overflow: auto;
  width: 100%;
  display: flex;
`;