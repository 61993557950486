import React, { useEffect, useState } from 'react';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormalTextAtom from 'src/components/atoms/Text/NormalTextAtom';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import { useI18n } from 'src/i18n/hooks';
import { getTableValue, properCase } from 'src/utils/utility';
import HeaderFive from 'src/components/atoms/Text/HeaderFive';
import { userInformation } from 'src/utils/manageState';
import { USER_TYPE, limit } from 'src/constant';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useHistory, useParams } from 'src/routes/routing.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import ViewResultsNestedTable from 'src/components/molecules/Assessment/Results/ViewResultsNestedTable';
import RevaluationModal from 'src/components/molecules/Assessment/Results/RevalAtktModal/RevaluationModal.web';
import ATKTmodal from 'src/components/molecules/Assessment/Results/RevalAtktModal/AtktModal.web';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import {
  useGetATKTAndReval,
  useApplyRevalDetailsForUser,
  useApplyATKTDetailsForUser,
  useGetRevalSubjects,
  useGetUserMarkSheets,
  useGetRevalDetailsForUser,
  useGetATKTDetailsForUser,
} from 'src/graphql/assessment/assessment-results';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ERROR } from 'src/constant';
import { isWeb, width } from 'src/constant/device';
import nttPaymentOptions from 'src/components/molecules/Payment/NTTPaymentOptions';
import {
  TRevalData,
  TAtktData,
  TPaymentDetails,
} from 'src/components/organism/Assessment/Results/helpers';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import AtktRevalPaymentModal from 'src/components/molecules/Assessment/Results/RevalAtktModal/PaymentModal/AtktRevalPaymentModal';
import Chip from 'src/components/atoms/Chip';
type TParams = {
  examId: string;
};
export default function ResultDetail() {
  const { t } = useI18n();
  const history = useHistory();
  const { examId }: TParams = useParams();
  const examName = history.location?.state?.examName;
  const [RevaluationModalState, handleRevaluationModal] = useState<boolean>(false);
  const [ATKTModalState, handleATKTModal] = useState<boolean>(false);
  const [makePaymentModal, setMakePaymentModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [atktEnabled, setATKTEnabled] = useState<boolean>(false);
  const [revalEnabled, setRevalEnabled] = useState<boolean>(false);
  const [revalSubjects, setRevalSubjects] = useState<any>([]);
  const [atktSubjects, setATKTSubjects] = useState<any>([]);
  const [revalData, setRevalData] = useState<TRevalData>();
  const [ATKTData, setATKTData] = useState<TAtktData>();
  const [ATKTfromData, setATKTFormData] = useState({});
  const [paymentDetails, setPaymentDetails] = useState<TPaymentDetails | any>();
  const [checked, setChecked] = useState<string[]>([]);
  const [softCopyChecked, setSoftCopyChecked] = useState<string[]>([]);
  const [showAtktBtn, setShowAtktBtn] = useState<boolean>(false);
  const [gradingType, setGradingType] = useState<string>('');
  const [dataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const [atktbtn, setAtktBtn] = useState<boolean>(false);
  const { getATKTAndReval, loadingATKTAndReval } = useGetATKTAndReval();
  const { applyRevalDetailsForUser } = useApplyRevalDetailsForUser();
  const { applyATKTDetailsForUser, loadingApplyATKTForUser } = useApplyATKTDetailsForUser();
  const { fetchRevalSubjects, loadingRevalSubjects } = useGetRevalSubjects();
  const { fetchMarkSheets, dataGetMarkSheets, loadingMarkSheets, errorGetMarkSheets } =
    useGetUserMarkSheets();
  const { fetchATKTDetailsForUser, dataGetATKTDetailsForUser, loadingGetATKTDetailsForUser } =
    useGetATKTDetailsForUser();
  const { fetchRevalDetailsForUser, dataGetRevalDetailsForUser } = useGetRevalDetailsForUser();
  const { displayAtomPayNetzPortal } = nttPaymentOptions(paymentDetails);
  const { setAlertDetails } = useAlertSystem();
  const currentUserObject = userInformation();
  const { userType, userDetail, userId } = currentUserObject;
  const userDetails = userType === USER_TYPE.STUDENT && userDetail;
  const fullName = userType === USER_TYPE.STUDENT && userDetail?.fullName;
  const { setHeading } = useHeaderTitle();
  const closeViewResultModal = () => {
    handleRevaluationModal(false);
    handleATKTModal(false);
    setChecked([]);
    setSoftCopyChecked([]);
  };

  const handleSubmit = () => {
    if (revalData?.id) {
      applyRevalution(revalData.id);
    }
  };
  useEffect(() => {
    if (examName) {
      setHeading([
        {
          text: t('results.label'),
          url: `/results/limit/${limit}/page/1`,
        },
        {
          text: examName,
          url: '',
        },
      ]);
    }
  }, [examName]);
  useEffect(() => {
    if (examId && userId) {
      handleATKTRevalFrom(examId);
      getMarkSheet(examId, userId);
      fetchRevalDetailsForUser({
        variables: {
          exam: examId,
        },
      });
    }
  }, [examId, userId]);

  useEffect(() => {
    if (!loading && dataGetMarkSheets?.markSheet) {
      setGradingType(dataGetMarkSheets.markSheet?.user?.academic?.batch?.settings?.gradingType);
      setIsDataAvailable(true);
    }
  }, [loadingMarkSheets]);

  function closeMakePaymentModal() {
    setMakePaymentModal(false);
    setChecked([]);
    setSoftCopyChecked([]);
  }

  useEffect(() => {
    if (ATKTData?.id) {
      getATKTDetails(ATKTData?.id);
    }
  }, [ATKTData]);

  const getATKTDetails = async (atktId: string) => {
    const response = await fetchATKTDetailsForUser({
      variables: {
        atktExam: atktId,
      },
    });
    if (
      !response?.data?.getATKTDetailsForUser ||
      response.data.getATKTDetailsForUser.length === 0
    ) {
      setShowAtktBtn(true);
    }
  };

  const getMarkSheet = async (examId: string, userId: string) => {
    const response = await fetchMarkSheets({
      variables: {
        examId: examId,
        userId: userId,
      },
    });
    if (response) {
      if (response?.data?.markSheet?.failedHeads > 0) {
        setAtktBtn(true);
      }
    }
  };

  const handleATKTRevalFrom = async (examId: string) => {
    try {
      const response = await getATKTAndReval({
        variables: {
          examId: examId,
        },
      });
      if (response?.data?.atktAndReval) {
        const { atktExam, revalForm } = response.data.atktAndReval;
        if (revalForm !== null && revalForm.status === 'COMPLETED' && atktExam !== null) {
          setATKTEnabled(true);
        }
        if (revalForm !== null && atktExam == null && revalForm.status === 'OPEN_FOR_APPLICATION') {
          setRevalEnabled(true);
        }
        ///need to remove this condition
        if (
          revalForm !== null &&
          atktExam !== null &&
          revalForm.status === 'OPEN_FOR_APPLICATION'
        ) {
          setRevalEnabled(true);
          setATKTEnabled(true);
        }
        setRevalData(revalForm);
        setATKTData(atktExam);
      }
    } catch (errors) {
      setAlertDetails({
        message: errors?.message,
        level: ERROR,
      });
    }
  };

  const applyRevalution = async (revalFormId: string) => {
    setLoading(true);
    try {
      const response = await applyRevalDetailsForUser({
        variables: {
          formId: revalFormId,
          subjects: checked,
          softCopySubjects: softCopyChecked,
        },
      });
      if (response?.data.createRevalDetailsForUser?.paymentOrder) {
        setPaymentDetails(response?.data.createRevalDetailsForUser?.paymentOrder);
      }
    } catch (error: any) {
      setAlertDetails({
        message: error?.message,
        level: ERROR,
      });
    } finally {
      setLoading(false);
      handleRevaluationModal(false);
      setMakePaymentModal(true);
    }
  };

  const applyATKT = async (atktExamId: string) => {
    try {
      const response = await applyATKTDetailsForUser({
        variables: {
          atktExamId: atktExamId,
        },
      });
      if (response.data == null) {
        setAlertDetails({
          message: response?.errors[0]?.message,
          level: ERROR,
        });
      }
      if (response?.data?.createATKTDetailsForUser.paymentOrder) {
        setATKTFormData(response?.data?.createATKTDetailsForUser);
        setPaymentDetails(response?.data?.createATKTDetailsForUser.paymentOrder);
        setATKTSubjects(response?.data?.createATKTDetailsForUser?.subjects);
        handleATKTModal(true);
      }
    } catch (error: any) {
      setAlertDetails({
        message: error?.message,
        level: ERROR,
      });
    }
  };

  const handlefetchRevalsubjects = async () => {
    try {
      const response = await fetchRevalSubjects({
        variables: {
          examId: examId,
        },
      });
      if (response?.data) {
        setRevalSubjects(response?.data);
        handleRevaluationModal(true);
      }
      if (response?.data == null) {
        setAlertDetails({
          message: response?.errors[0]?.message,
          level: ERROR,
        });
      }
    } catch (error: any) {
      setAlertDetails({
        message: error?.message,
        level: ERROR,
      });
    }
  };

  const handleATKTSubmit = () => {
    handleATKTModal(false);
    setMakePaymentModal(true);
  };

  async function handleOnlinePayment() {
    try {
      if (paymentDetails) {
        setMakePaymentModal(false);
        if (isWeb) {
          displayAtomPayNetzPortal(paymentDetails);
        }
      }
    } catch (e: any) {
      if (isWeb) {
        setAlertDetails({ message: e.message, level: ERROR });
      }
    }
  }
  if (loadingATKTAndReval && loadingMarkSheets && loadingGetATKTDetailsForUser) {
    return (
      <LoadContentWrapper>
        <LoaderSpinner styles={{ height: '100%', width: '100%' }} />
      </LoadContentWrapper>
    );
  }

  return (
    <>
      <LoadContentWrapper>
        <HeaderFive value={'Student Details'} color={colors.primaryText} />
        <StudentInfoWrapper>
          <StudentInfoBlock>
            <InfoBlock>
              <NormalTextAtom value={t('name.label')} fontWeight={'400'} />
              <MediumTextSelect
                value={fullName ? properCase(fullName) : '-'}
                fontWeight={'600'}
                color={colors.primaryText}
              />
            </InfoBlock>
            <InfoBlock>
              <NormalTextAtom value={t('rollno.label')} fontWeight={'400'} />
              <MediumTextSelect
                value={userDetails?.academic?.rollNo || userDetails?.rollNo || '-'}
                fontWeight={'600'}
                color={colors.primaryText}
              />
            </InfoBlock>
            <InfoBlock>
              <NormalTextAtom value={t('studentId.label')} fontWeight={'400'} />
              <MediumTextSelect
                value={userDetails?.accountId || '-'}
                fontWeight={'600'}
                color={colors.primaryText}
              />
            </InfoBlock>
            <InfoBlock>
              <NormalTextAtom value={t('enrollmentNo.label')} fontWeight={'400'} />
              <MediumTextSelect
                value={dataGetMarkSheets?.markSheet?.enrollmentNo || '-'}
                fontWeight={'600'}
                color={colors.primaryText}
              />
            </InfoBlock>
            <InfoBlock>
              <NormalTextAtom value={t('seatNo.label')} fontWeight={'400'} />
              <MediumTextSelect
                value={dataGetMarkSheets?.markSheet?.seatNo || '-'}
                fontWeight={'600'}
                color={colors.primaryText}
              />
            </InfoBlock>
            {revalEnabled && revalData && (
              <InfoBlock>
                <NormalTextAtom value={'Reval Due Date'} fontWeight={'400'} />
                <MediumTextSelect
                  value={format(
                    parse(revalData.dueDate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date()),
                    DT.DATE_ABBREVIATED_MONTH_YEAR,
                  )}
                  fontWeight={'600'}
                  color={colors.primaryText}
                />
              </InfoBlock>
            )}
            {atktEnabled && ATKTData && (
              <InfoBlock>
                <NormalTextAtom value={'ATKT Due Date'} fontWeight={'400'} />
                <MediumTextSelect
                  value={format(
                    parse(ATKTData.dueDate, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date()),
                    DT.DATE_ABBREVIATED_MONTH_YEAR,
                  )}
                  fontWeight={'600'}
                  color={colors.primaryText}
                />
              </InfoBlock>
            )}
            {dataGetRevalDetailsForUser?.getRevalDetailsForUser.length > 0 && (
              <>
                <SubjectBlock>
                  <NormalTextAtom value={'Subjects for Reval'} fontWeight={'400'} />
                  <SubjectWarpper>
                    {dataGetRevalDetailsForUser?.getRevalDetailsForUser[0]?.subjects.map((item: any) => {
                      return <Chip label={item?.subject?.name} close={false} />;
                    })}
                  </SubjectWarpper>
                </SubjectBlock>
              </>
            )}

            {dataGetATKTDetailsForUser?.getATKTDetailsForUser.length > 0 && (
              <>
                <SubjectBlock>
                  <NormalTextAtom value={'Subjects for ATKT'} fontWeight={'400'} />
                  <SubjectWarpper>
                    {dataGetATKTDetailsForUser?.getATKTDetailsForUser[0].subjects.map(
                      (item: any) => {
                        return <Chip label={item?.subject?.name} close={false} />;
                      },
                    )}
                  </SubjectWarpper>
                </SubjectBlock>
              </>
            )}
          </StudentInfoBlock>
          <ButtonView>
            {revalEnabled ? (
              <SecondaryBtn
                label={t('applyRevaluation.label')}
                onPress={handlefetchRevalsubjects}
                canLoad={loadingRevalSubjects}
              />
            ) : null}

            {atktEnabled && showAtktBtn && atktbtn ? (
              <SecondaryBtn
                label={t('applyAtkt.label')}
                onPress={() => {
                  if (ATKTData?.id) applyATKT(ATKTData.id);
                }}
                canLoad={loadingApplyATKTForUser}
              />
            ) : null}
          </ButtonView>
        </StudentInfoWrapper>

        <HeaderFive value={'Exam Details'} color={colors.primaryText} />
        <InfoBlockContainer>
          <ExamBlock>
            <NormalTextAtom value={t('examName.label')} fontWeight={'400'} />
            <MediumTextSelect
              value={dataGetMarkSheets?.markSheet?.exam?.name || '-'}
              fontWeight={'600'}
              color={colors.primaryText}
            />
          </ExamBlock>

          <InfoBlock>
            <NormalTextAtom value={t('marksObtained.label')} fontWeight={'400'} />
            <MarksObtainedContainer>
              <MediumTextSelect
                value={
                  gradingType == 'MARKS'
                  ? getTableValue(dataGetMarkSheets?.markSheet?.totalAggregateMarks, '-')
                  : getTableValue(dataGetMarkSheets?.markSheet?.finalMarksObtained, '-')
                }
                fontWeight={'600'}
                color={colors.primaryText}
              />
              {Boolean(gradingType == 'MARKS' && dataGetMarkSheets?.marksheet?.totalGraceInNumber) && (
                <NormalTextAtom
                  value={` + ${dataGetMarkSheets.marksheet.totalGraceInNumber}`}
                  color={colors.yellow} />
              )}
            </MarksObtainedContainer>
          </InfoBlock>

          <InfoBlock>
            <NormalTextAtom value={t('totalMarks.label')} fontWeight={'400'} />
            <MediumTextSelect
              value={
                gradingType == 'MARKS'
                ? getTableValue(dataGetMarkSheets?.markSheet?.averageTotalMarks, '-')
                : getTableValue(dataGetMarkSheets?.markSheet?.totalMarks, '-')
              }
              fontWeight={'600'}
              color={colors.primaryText}
            />
          </InfoBlock>

          <InfoBlock>
            <NormalTextAtom value={t('average.label')} fontWeight={'400'} />
            <MediumTextSelect
              value={
                gradingType == 'MARKS'
                  ? getTableValue(dataGetMarkSheets?.markSheet?.averageOnAggregateMarks, '-')
                  : getTableValue(dataGetMarkSheets?.markSheet?.average, '-')
              }
              fontWeight={'600'}
              color={colors.primaryText}
            />
          </InfoBlock>

          {gradingType !== 'MARKS' && (
            <>
              <InfoBlock>
                <NormalTextAtom value={t('sgpa.label')} fontWeight={'400'} />
                <MediumTextSelect
                  value={getTableValue(dataGetMarkSheets?.markSheet?.sgpa, '-')}
                  fontWeight={'600'}
                  color={colors.primaryText}
                />
              </InfoBlock>

              <InfoBlock>
                <NormalTextAtom value={t('grade.label')} fontWeight={'400'} />
                <MediumTextSelect
                  value={dataGetMarkSheets?.markSheet?.grade || '-'}
                  fontWeight={'600'}
                  color={colors.primaryText}
                />
              </InfoBlock>

              <InfoBlock>
                <NormalTextAtom value={t('cgpa.label')} fontWeight={'400'} />
                <MediumTextSelect
                  value={getTableValue(dataGetMarkSheets?.markSheet?.cgpa, '-')}
                  fontWeight={'600'}
                  color={colors.primaryText}
                />
              </InfoBlock>
            </>
          )}
        </InfoBlockContainer>

        {dataAvailable && (
          <ViewResultsNestedTable
            data={dataGetMarkSheets?.markSheet}
            marksType={gradingType == 'MARKS'}
          />
        )}
      </LoadContentWrapper>

      <NormalModal
        setModalVisible={closeViewResultModal}
        Headerpopup={t('applyRevaluation.label')}
        modalVisible={RevaluationModalState}
        addEditButtonLabel="payment.proceed.label"
        cancelButtonLabel="cancel.label"
        handleSave={handleSubmit}
        isSubmitting={loading}
        maxWidth={'lg'}
        disabled={checked.length === 0}>
        <RevaluationModal
          checked={checked}
          setChecked={setChecked}
          subjectsData={revalSubjects}
          softCopyChecked={softCopyChecked}
          setSoftCopyChecked={setSoftCopyChecked}
        />
      </NormalModal>

      <NormalModal
        setModalVisible={closeViewResultModal}
        Headerpopup={t('applyAtkt.label')}
        modalVisible={ATKTModalState}
        addEditButtonLabel="payment.proceed.label"
        cancelButtonLabel="cancel.label"
        handleSave={handleATKTSubmit}
        width={'788px'}
        hideCloseIcon={true}>
        <ATKTmodal subjectsData={atktSubjects} />
      </NormalModal>

      <NormalModal
        setModalVisible={closeMakePaymentModal}
        Headerpopup={t('payment.make-payment')}
        modalVisible={makePaymentModal}
        handleSave={handleOnlinePayment}
        cancelButtonLabel={'cancel.label'}
        addEditButtonLabel={'payment.proceed-payment'}
        contentStyles={{ paddingTop: 0 }}>
        <AtktRevalPaymentModal paymentDetails={paymentDetails} />
      </NormalModal>
    </>
  );
}

const MarksObtainedContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoBlockContainer = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 24px;
`;
const StudentInfoBlock = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 24px;
  width: 80%;
`;
const StudentInfoWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InfoBlock = styled.View`
  width: 20%;
`;

const SubjectBlock = styled.View`
  max-width: 300px;
`;
const SubjectWarpper = styled.View`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;

const ExamBlock = styled.View`
  min-width: 412px;
  max-width: 412px;
`;

const ButtonView = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: flex-end;
  gap: 5px;
  margin-top: 8px;
  width: auto;
`;
