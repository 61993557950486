import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { colors } from '../../../styles/theme/styles';
import LoadContentWrapper from '../../atoms/Wrapper/LoadContent';
import Search from '../../atoms/SearchBar/index.web';
import { useForm, Controller } from 'react-hook-form';
import NormalModal from '../../atoms/Modals/Normal/index.web';
import Input from '../../atoms/Input/input.web';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import useTable from 'src/components/molecules/Table/UseTable';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import { TableBody } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { validation } from '../../../constant/validation';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import {
  getStreamQuery,
  addStreamMutation,
  updateStreamMutation,
  useStreamList,
  useUpdateStream,
  useDeleteStream,
} from '../../../graphql/academics/streams';
import {
  AUTOCOMPLETE_MODULE,
  DefaultUserSelection,
  ERROR,
  FILTERS,
  limit,
  SUCCESS,
} from 'src/constant/index';
import { useParams } from 'react-router';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useI18n } from 'src/i18n/hooks';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import { readCacheQuery } from 'src/graphql/util';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useFilter } from 'src/contexts/filter-context';
import { createSearchFilter, debounce } from 'src/components/services';
import { getStreamFilter } from 'src/components/services/filters';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { Tooltip } from '@mui/material';
import StreamForm from 'src/components/molecules/Academics/StreamForm.web';
import { height } from 'src/constant/device';
import styled from 'styled-components/native';

const headCells = [
  {
    id: 'stream_name',
    label: 'name.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'actions',
    label: 'actions',
    align: 'right',
    disableSorting: true,
  },
];

export default function Stream() {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();
  const [canClick, setCanClick] = useState(true);
  let searchValue = '' as string;

  let row;
  const { page = 1, dataLimit = limit }: any = useParams();
  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const [totalCount, setTotalCount] = useState(null);

  const { setHeading } = useHeaderTitle();
  const { filters, setFilter } = useFilter();
  const { setAlertDetails } = useAlertSystem();
  const { mutate: addStream } = useUpdateStream(addStreamMutation);
  const { mutate: updateStream } = useUpdateStream(updateStreamMutation);
  const [modalState, handleModal] = useState(false);
  const [isDefaultOption, setIsDefaultOption] = useState<boolean>(false);
  const [editStreamData, setStreamData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const maxHeight = 440;
  if (filters && filters?.stream?.on) {
    searchValue = filters?.stream?.search;
  }

  useEffect(() => {
    setTitle();
    if (isEditModal && modalState) {
      setFormField(editStreamData?.name);
    }
  }, [modalState]);

  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [searching, setSearchingStream] = useState(searchValue);

  const {
    query: getStreams,
    data: streamData,
    loading,
    refetch: refetchStreams,
  } = useStreamList(searching?.length > 0 ? 'network-only' : 'cache-first');
  row = streamData?.streams?.data;

  const { deleteStream } = useDeleteStream(currentPage, searchValue, rowLimit, editStreamData?.id);

  function setTitle() {
    setHeading([
      {
        text: t('streams.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    const count = streamData?.streams?.totalCount;
    if (count) {
      setTotalCount(count);
    }
  }, [streamData?.streams?.totalCount]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    setSearchingStream(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  function handleFetch() {
    let filters = getStreamFilter(searchValue);
    let streamFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);
    getStreams({ variables: streamFilter });
  }

  function handleRefetch() {
    let filters = getStreamFilter(searchValue);
    let streamFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    refetchStreams({ variables: streamFilter });
  }

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    page,
  );

  const editStreamModal = (selectedStream: any) => {
    hideAlert();
    setStreamData(selectedStream);
    setIsEditModal(true);
    handleModal(true);
  };

  const deleteStreamModal = (selectedStream: any) => {
    setStreamData(selectedStream);
    setDeleteOverlay(true);
  };

  const addStreamModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  const closeModal = () => {
    setIsDefaultOption(false);
    handleModal(false);
    setCanClick(true);
    resetForm();
  };

  function resetForm() {
    setValue('stream_name', '');
    clearErrors();
  }

  function setFormField(name: string) {
    setValue('stream_name', name, { shouldValidate: true });
  }

  const handleAddStream = async (data: any) => {
    setCanClick(false);
    let streamObject = {
      name: data.stream_name,
      defaultUserSelection: isDefaultOption
        ? DefaultUserSelection.ALLOWED
        : DefaultUserSelection.NOT_ALLOWED,
    };

    try {
      const addStreamData = await addStream({
        variables: { payload: streamObject },
      });

      if (addStreamData.data) {
        setAlertDetails({ message: ADD_SUCCESS.STREAM, level: SUCCESS });
        handleRefetch();
        closeModal();
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleUpdateStream = async (editdata: any) => {
    setCanClick(false);
    let streamObject = {
      id: editStreamData?.id,
      name: editdata?.stream_name,
      defaultUserSelection: isDefaultOption
        ? DefaultUserSelection.ALLOWED
        : DefaultUserSelection.NOT_ALLOWED,
    };

    try {
      const updateStreamData = await updateStream({
        variables: { payload: streamObject },
      });
      if (updateStreamData.data.updateStream) {
        setAlertDetails({ message: UPDATE_SUCCESS.STREAM, level: SUCCESS });
        handleRefetch();
        closeModal();
      }
      setCanClick(true);
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  const handleDeleteStream = async () => {
    setCanClick(false);
    try {
      let deleteResponse = await deleteStream({ variables: { id: editStreamData?.id } });
      if (deleteResponse?.data?.deleteStream?.status === 'success') {
        setDeleteOverlay(false);
        setAlertDetails({ message: DELETE_SUCCESS.STREAM, level: SUCCESS });
        handleRefetch();
      } else {
        throw new Error(
          deleteResponse?.data?.deleteStream?.message || t('error.deleteStream.message'),
        );
      }
    } catch (error) {
      setAlertDetails({ message: error.message, level: ERROR });
      setDeleteOverlay(false);
    }
    setCanClick(true);
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.STREAM]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchStream(stream: string) {
    let isClear = stream?.length === 0;
    persistSearch(!isClear, stream);
  }

  const delayedQuery = useCallback(
    debounce(q => searchStream(q), 500),
    [],
  );
  const handleSearch = (stream: string) => {
    setSearchingStream(stream);
    delayedQuery(stream);
  };

  return (
    <>
      <LoadContentWrapper>
        <View style={styles.flexRow}>
          <View style={styles.leftPart}>
            <Search
              id="streamSreach"
              handleChange={handleSearch}
              value={searching}
              label={t('searchStream.text')}
            />
          </View>

          <View style={styles.rightPart}>
            <SecondaryBtn label={t('addStream.text')} onPress={addStreamModal} />
          </View>
        </View>
        <TblContainer height={'100%'}>
          <TblHead />
          <>
            {!loading ? (
              <>
                {recordsAfterPagingAndSorting()?.length > 0 ? (
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                      <TableRows key={i}>
                        <TableCells value={item?.name} />

                        <TableActions align="right">
                          <View style={styles.tableAction}>
                            <View style={styles.iconView}>
                              <Tooltip title="Edit Stream">
                                <TouchableOpacity onPress={() => editStreamModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    color={theme?.table?.editIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            </View>
                            <View>
                              <Tooltip title="Delete Stream">
                                <TouchableOpacity onPress={() => deleteStreamModal(item)}>
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    color={theme?.table?.deleteIconColor}
                                  />
                                </TouchableOpacity>
                              </Tooltip>
                            </View>
                          </View>
                        </TableActions>
                      </TableRows>
                    ))}
                  </TableBody>
                ) : (
                  <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
                )}
              </>
            ) : (
              <SpinnerWrapper>
                <LoaderSpinner />
              </SpinnerWrapper>
            )}
          </>
        </TblContainer>
        <Pagination
          pathName={'academics/streams'}
          total={totalCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          hidePagination={!!(totalCount && totalCount <= rowLimit)}
        />
      </LoadContentWrapper>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={() => setDeleteOverlay(false)}
        modalVisible={deleteOverlay}
        handleSave={handleDeleteStream}
        Headerpopup="deleteStream.text">
        {t('deleteStream.warning')}
      </DeleteModal>

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        Headerpopup={isEditModal ? t('editStream.text') : t('addStream.text')}
        modalVisible={modalState}
        handleSave={handleSubmit(isEditModal ? handleUpdateStream : handleAddStream)}
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}
        width={544}
        height={160}>
        <StreamForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          setValue={setValue}
          isEditModal={isEditModal}
          editStreamData={editStreamData}
          setIsDefaultOption={setIsDefaultOption}
          isDefaultOption={isDefaultOption}
        />
      </NormalModal>
    </>
  );
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
  },
  leftPart: {
    flex: 3,
    alignItems: 'flex-start',
  },
  rightPart: {
    flex: 3,
    alignItems: 'flex-end',
  },
  tableAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  marginTop4: {
    marginTop: 4,
  },
  iconView: { marginRight: 13 },
  spinnerView: { height: 420, justifyContent: 'center', alignItems: 'center' },
});

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
