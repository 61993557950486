import React, { useEffect, useState } from "react";

import Element from "../Forms/ApplicationElement.web";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import addFormJSON from "../../../form-json/academics/a.years.json";
import editFormJSON from "../../../form-json/academics/ayear.edit.json";
import { getAcademicYearQuery } from "src/graphql/academics/years";
import styled from "styled-components";
import { useLazyQuery } from "@apollo/client";

export default function AcademicYearForm(props: any) {
  const {
    watch,
    control,
    errors,
    reset,
    setValue,
    isEditModal,
    editAcademicYearData,
  } = props;
  const [elements, setElements] = useState<any>({});
  const { fields }: any = elements ?? {};
  const [fetchAcademic, { data: academics, loading }] = useLazyQuery(
    getAcademicYearQuery,
    { variables: { limit: 0 }, fetchPolicy: "network-only" }
  );

  const toBeCloned = !!watch("ayear_clone");

  useEffect(() => {
    if (isEditModal) {
      loadEditData();
    } else {
      fetchAcademic();
      setElements({ ...addFormJSON[0] });
    }
  }, [isEditModal]);

  async function loadEditData() {
    setValue("ayear_clone", false);
    setElements({ ...editFormJSON[0] });
    const { label, alias, from, to, status } = editAcademicYearData;
    const initialValues = {
      ayear_name: label,
      ayear_alias: alias,
      ayear_start: from,
      ayear_end: to,
      ayear_status: { label: status, value: status },
    };
    reset({ ...initialValues });
  }

  useEffect(() => {
    if (!isEditModal) {
      if (toBeCloned) {
        addFormJSON[0].fields[5].disabled = false;
      } else {
        addFormJSON[0].fields[5].disabled = true;
        setValue("previous_ayear", null);
      }
      setElements({ ...addFormJSON[0] });
    }
  }, [isEditModal, toBeCloned]);

  useEffect(() => {
    if (!isEditModal && !loading && academics) {
      if (academics?.AcademicYears?.data?.length > 0) {
        addFormJSON[0].fields[5].option = academics?.AcademicYears?.data ?? [];
        setElements({ ...addFormJSON[0] });
      }
    }
  }, [isEditModal, academics]);

  return (
    <Container>
      {fields ? (
        fields?.map((field: any, i: number) => (
          <Element
            key={`ayear${i}`}
            field={field}
            control={control}
            errors={errors}
            inputWidth={444}
            dropdownWidth={444}
            checkboxTableWidth={444}
          />
        ))
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;

const SpinnerView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
