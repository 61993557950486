import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from '../config/config.json';
import { createUploadLink } from 'apollo-upload-client';
import { getTokenFromStorage } from 'src/components/services/storage-service';

const httpLink = createUploadLink({
  uri: config.Server.url,
});
//unuse
const authLink = () =>
  setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const edbaToken = await getTokenFromStorage();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${edbaToken}`,
      },
    };
  });

//unuse
const client = new ApolloClient({
  link: authLink().concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: true,
});
//unuse
export const graphqlQuery = async (query: any, variable: any, needCache = true) => {
  let response = [] as any;
  try {
    client.setLink(authLink().concat(httpLink));
    response = await client.query({
      query: query,
      variables: variable,
      fetchPolicy: 'network-only',
    });
    return response;
  } catch (error) {
    console.log('err: ', error);
    return error.message;
  }
};

//unuse
export const graphqlMutation = async (mutatequery: any, variable: any) => {
  let response = [] as any;
  try {
    response = await client.mutate({
      variables: variable,
      mutation: mutatequery,
    });
    return response;
  } catch (error) {
    //setFormSubmissionErrors({ error })
    return error.message;
  }
};

//use

export const readCacheQuery = (cache: any, query: any, variables: any) => {

  try {
    let queryData = cache.readQuery({
      query: query,
      variables: variables,
    });
    return queryData;
  } catch (error) {
    //setFormSubmissionErrors({ error })
    return error.message;
  }
};

export const writeCacheQuery = (cache: any, query: any, variables: any, data: any) => {
  try {
    let queryData = cache.writeQuery({
      query: query,
      data: data,
      variables: variables,
    });
    return queryData;
  } catch (error) {
    //console.log(error);
    //setFormSubmissionErrors({ error })
    return error.message;
  }
};
