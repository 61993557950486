import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Search from 'src/components/atoms/SearchBar/index.web';
import styled from 'styled-components';
import { TableBody, Tooltip  } from '@mui/material';
import { useHistory } from 'react-router';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import useTable from 'src/components/molecules/Table/UseTable';
import { useI18n } from 'src/i18n/hooks';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'src/routes/routing.web';
import {
  ExpandCollapseCell,
  NoRecordsFound,
  TableActions,
  TableCells,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useHeaderTitle } from 'src/contexts/header-context';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import { height, isWeb } from 'src/constant/device';
import { StyleSheet } from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import { useForm } from 'react-hook-form';
import AssignModuleForm from 'src/components/molecules/Payment/Institute/ControlPanel/AssignModuleForm';
import {
  childBorderLeft,
  childBorderRight,
  commonBorder,
  parentBorderBottom,
  parentBorderLeft,
  parentBorderRight,
} from '../../../../molecules/Table/ExpandTableAtoms';
import { colors, fonts } from 'src/styles/theme/styles';
import CreateModuleForm from 'src/components/molecules/Payment/Institute/ControlPanel/CreateModuleForm';
import { AUDIENCE_TYPE, AUTOCOMPLETE_MODULE, ERROR, limit, SUCCESS  } from 'src/constant';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import ERROR_MSG from 'src/constant/error';
import {
  useAddModuleMutation,
  getFeeDetailsQuery,
  useQueryFeeDetails,
  useAssignModuleMutation,
  useQueryFeeComposition,
  getFeeCompositionsQuery,
  useDeleteFeeComposition,
  useDeleteModule,
  useEditFeeCompositionMutation,
} from 'src/graphql/payment';
import { getCommonNameSearchFilter, getCompositionNameSearchFilter } from 'src/components/services/filters';
import { createSearchFilter, debounce, getFilteredModules } from 'src/components/services';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { useFilter } from 'src/contexts/filter-context';
import AudienceWrapper from 'src/components/molecules/Audience/AudienceWrapper';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import DeleteModal from 'src/components/atoms/Modals/Delete/index.web';
import OrderModuleForm from 'src/components/molecules/Payment/Institute/ControlPanel/OrderModuleForm';
import { compareAndSort, getCellStyle } from 'src/utils/utility';
import { EditIcon } from 'src/components/atoms/ActionIcons';
import { parseToDate } from 'src/constant/dateTime';
import { compareDesc } from 'date-fns';
import NormaltextAtom from 'src/components/atoms/Text/NormalTextAtom';

const maxHeight = 100;
const headCells = [
  {
    id: 'composition_name',
    label: 'compositionName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'batch_name',
    label: 'batchGroupName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'modules',
    label: 'moduleGroupName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'center',
    disableSorting: true,
  },
];

const columns = [
  { label: 'moduleName.label' },
  { label: 'amount.label' },
  { label: 'description.label' },
  { label: 'action.label', textAlign: 'center' }
];

export default () => {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const [trow, setT] = useState(false);
  const [current, setCurrent] = useState(null);
  const location = isWeb ? useLocation() : '';
  let searchValue = '' as string;

  const rowDefaultLimit: number = limit;
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  const [page, setPage] = useState<number>(1); // this is same as currentPage in other tables
  const createModuleRef = useRef<any>(null);
  const assignModuleRef = useRef<any>(null);
  const match = isWeb ? useRouteMatch() : '';
  const isCreateModule = location && location.pathname.includes('create-module');
  const { alertState, setAlertDetails } = useAlertSystem();
  const [assignModuleModal, handleAssignModuleModal] = useState(false);
  const [createModuleModal, handleCreateModuleModal] = useState(false);
  const [canClick, setCanClick] = useState<boolean>(true);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isAssignModuleEditModal, setisAssignModuleEditModal] = useState(false);
  const [editCreateModuleData, setCreateModuleData] = useState<any>({});
  const [editAssignModuleData, setAssignModuleData] = useState<any>({});
  const [moduleIds, setModuleIds] = useState([]);

  const isSubmitting = useRef(false);
  const [groupId, setGroupId] = useState('');
  const { mutate: addModule } = useAddModuleMutation();
  const { mutate: assignModuleMutation } = useAssignModuleMutation();
  const { mutate: editFeeCompositionMutation } = useEditFeeCompositionMutation();
  const { filters, setFilter } = useFilter();
  const [locationKeys, setLocationKeys] = useState([]);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState<any>(1);
  const [audienceType, setAudienceType] = useState(null);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [orderModal, setOrderModal] = useState<boolean>(false);
  const [modulesData, setModulesData] = useState([]);
  const [searching, setSearching] = useState(searchValue);
  const [headCells, setHeadCells] = useState(columns);
  const [cellState, setCellState] = useState<{ current: null | number; open: boolean }>({
    open: false,
    current: null,
  });
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
    reset,
    clearErrors,
    setError,
  } = useForm();

  const history = useHistory();
  const { query: getModule, data: moduleData, loading, refetch: refetchModule } = useQueryFeeDetails(getFeeDetailsQuery);

  const {
    query: getFeeCompositions,
    data: feeCompositionData,
    loading: feeCompositionLoading,
    refetch: refetchFeeComposition,
  } = useQueryFeeComposition(getFeeCompositionsQuery);
  const { deleteModule } = useDeleteModule();
  const { deleteFeeComposition } = useDeleteFeeComposition();

  if (filters && filters?.module?.on) {
    searchValue = filters?.module?.search;
  }

  const row = moduleData?.getFeeDetails?.data;
  const totalCount = moduleData?.getFeeDetails?.totalCount;

  const rowFeeCompositions = feeCompositionData?.getFeeCompositions?.data;
  const totalCountFeeCompositions = feeCompositionData?.getFeeCompositions?.totalCount;

  const { setHeading } = useHeaderTitle();

  async function setTitle() {
    setHeading([
      {
        text: t('payment.label'),
        url: '',
      },
    ]);
  }

  useEffect(() => {
    setSearching(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (row && row?.length > 0) {
      const modules = sortModuleData(row);
      setModulesData(modules);
    } else {
      setModulesData([]);
    }
  }, [row]);

  useEffect(() => {
    if (location?.pathname?.includes('fees-composition')) {
      setTab(2);
      const num = location?.pathname?.split("/").pop()
      setPage(num);
    }
    
    if (location?.pathname?.includes('create-module')) {
      setTab(1);
      const num = location?.pathname?.split("/").pop()
      setPage(num);
    }

    setTitle();
  }, []);

  useEffect(() => {
    customizeData();
  }, [rowFeeCompositions]);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'POP') {
        setPage(parseInt(history.location.pathname.match(/\d+$/)[0], 10));
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    handleFetch();
  }, [page, searchValue, isCreateModule, locationKeys]);

  useEffect(() => {
    handleChangeTabs();
  }, [tab]);

  function sortModuleData(modules: any) {
    const moduleData = modules?.map((item: any) => {
      if (item?.isGroup && item?.components && item?.components?.length > 1) {
        item = {
          ...item,
          components: item?.components?.slice().sort((a: any, b: any) => compareAndSort(a, b, 'orderNo')),
        };
      }
      return item;
    });
    return moduleData;
  }

  function customizeData() {
    const arr = [] as any;
    const createObj = (newItem: any) => {
      return {
        id: newItem.id,
        groupId: newItem.groupId,
        groupName: newItem.groupName,
        orderNo: newItem?.orderNo,
        feeId: newItem?.fee?.parent ? newItem.fee.parent : newItem?.fee?.id,
        groupInfo: [
          {
            id: newItem.id,
            name: newItem.fee.name,
          },
        ],
      };
    };
    const createPayers = (feeBooks: any) => {
      const payers = [] as any;
      feeBooks.forEach((feeBook: any) => {
        feeBook.payers.forEach((payer: any) => {
          if (!payers.includes(payer.name)) {
            payers.push(payer.name);
          }
        });
      });
      return payers;
    };
    rowFeeCompositions?.forEach((composition: any) => {
      if (composition.feeBooks.length === 1) {
        arr.push({
          id: composition.id,
          compositionName: composition.feeBooks[0].compositionName,
          bankDetails: { label: composition?.bankAlias, value: composition?.bankId },
          createdAt: composition.createdAt,
          payers: createPayers(composition.feeBooks),
          feeBooks: [createObj(composition.feeBooks[0])],
          payerType: composition?.payerType,
          payerList: composition?.payerList,
          payerDetails: composition?.payers,
        });
      } else {
        let obj = {};
        let miniarr = [];
        composition.feeBooks.forEach((item: any) => {
          if (!obj[item.groupId]) {
            obj[item.groupId] = createObj(item);
          } else {
            obj[item.groupId].groupInfo.push({
              id: item.id,
              name: item.fee.name,
            });
          }
        });
        for (let element in obj) {
          miniarr.push(obj[element]);
        }
        arr.push({
          id: composition.id,
          compositionName: composition.feeBooks[0].compositionName,
          bankDetails: { label: composition?.bankAlias, value: composition?.bankId },
          createdAt: composition.createdAt,
          payers: createPayers(composition.feeBooks),
          feeBooks: miniarr,
          payerType: composition?.payerType,
          payerList: composition?.payerList,
          payerDetails: composition?.payers,
        });
        obj = {};
        miniarr = [];
      }
    });
    sort(arr);
  };

  const sort = (data: any) => {
    let sortedData: any;
    if (data.length > 0) {
      sortedData = [...data];
      sortedData.sort(function (a: any, b: any) {
        const dateA = parseToDate(a?.createdAt);
        const dateB = parseToDate(b?.createdAt);
        return compareDesc(dateA, dateB);
      });
    } else {
      sortedData = data;
    }
    setData(() => sortedData);
  }

  const paginationHeightOffset: number = 24;

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    data.length > 0 ? data : [],
    headCells,
    maxHeight,
    totalCountFeeCompositions,
    page,
    null,
    null,
    false,
    true,
    null,
    paginationHeightOffset,
  );

  const handlePress = async (item: any) => {
    history.push(`/payments/fees-composition/${item?.id}`);
  };

  function handleFetch() {
    const filtersValue = isCreateModule ? getCommonNameSearchFilter(searchValue) : getCompositionNameSearchFilter(searchValue);
    const moduleFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filtersValue);
    delete Object.assign(moduleFilter, { ['filter']: moduleFilter['filters'] })['filters'];

    if (isCreateModule) {
      getModule({ variables: moduleFilter });
    } else {
      getFeeCompositions({ variables: moduleFilter });
    }
  }

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  function changeTabs(tab: any) {
    searchModule("");
    setPage(1);
    setTab(tab);
  }

  function handleChangeTabs() {
    if (tab === 1) {
      if (createModuleRef.current) {
        createModuleRef?.current.scrollToIndex({ animated: true, index: 0 });
      }
      history.push(`${match.url}/create-module/limit/${rowLimit}/page/${page}`);
    } else {
      if (assignModuleRef.current) {
        assignModuleRef?.current.scrollToIndex({ animated: true, index: 0 });
      }
      history.push(`${match.url}/fees-composition/limit/${rowLimit}/page/${page}`);
    }
  }

  function openAssignModuleModal() {
    resetForm();
    setisAssignModuleEditModal(false);
    hideAlert();
    handleAssignModuleModal(true);
  }

  function openCreateModuleModal() {
    resetForm();
    setIsEditModal(false);
    hideAlert();
    handleCreateModuleModal(true);
  }

  const handleAddModule = async (formData: any) => {
    setCanClick(false);
    isSubmitting.current = true;
    try {
      let moduleType = formData.module_type?.value;
      let payloadData = createModuleObject(formData, moduleType);
      if (payloadData?.payload?.parent) {
        setGroupId(payloadData?.payload?.parent);
      }

      let addModuleResponse = await addModule({
        variables: payloadData,
        refetchQueries: [
          {
            query: getFeeDetailsQuery,
            variables: {
              limit: limit,
              skip: 0,
            },
          },
        ],
      });
      if (addModuleResponse.data) {
        closeModal();
        setGroupId('');
        setAlertDetails({ message: ADD_SUCCESS.MODULE, level: SUCCESS });
        handleFetch();
      } else {
        setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
    isSubmitting.current = false;
    setCanClick(true);
  };

  function createModuleObject(formData: any, moduleType: string) {
    let moduleObject = {} as any;

    let payload = {
      name: moduleType === 'GROUP' ? formData['group_name'] : formData['module_name'],
      description: formData['description'],
      type: formData.fee_detail_type.value,
      isGroup: moduleType === 'GROUP' ? true : false,
    } as any;

    if (moduleType !== 'GROUP') {
      if (formData.group_name?.value) {
        payload['parent'] = formData.group_name?.value;
      }
      if (formData.order_number) {
        payload['orderNo'] = parseFloat(formData.order_number);
      }
      payload['amount'] = parseFloat(formData.amount);
    }

    moduleObject['payload'] = payload;
    return moduleObject;
  }

  const closeModal = () => {
    handleCreateModuleModal(false);
    resetAllField();
  };

  function resetForm() {
    reset({});
    clearErrors();
  }

  function resetAllField() {
    resetForm();
    handleAssignModuleModal(false);
    setModuleIds([]);
    if (isAssignModuleEditModal) {
      setisAssignModuleEditModal(false);
    }
  }

  interface FeeModuleObject {
    fee: string;
    orderNo: number;
  }

  interface AssignModulePayload {
    compositionName: string;
    bankId: string;
    payerType: string;
    payers: string[];
    fees: FeeModuleObject[];
    compositionId?: string;
  }

  async function assignModule(payload: AssignModulePayload) {
    try {
      const assignModuleResponse = await assignModuleMutation({
        variables: { payload: payload },
        refetchQueries: [
          {
            query: getFeeCompositionsQuery,
            variables: {
              limit: limit,
              skip: 0,
            },
          },
        ],
      });

      if (assignModuleResponse?.data) {
        const data = assignModuleResponse.data;
        if (data.createFeeBooks) {
          setCanClick(true);
          closeModal();
          setAlertDetails({ message: ADD_SUCCESS.FEE_COMPOSITION, level: SUCCESS });
          handleFetch();
        } else {
          setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
        }
      }
    } catch (error: any) {
      setCanClick(true);
      if (error.message.includes('E11000 duplicate key error')) {
        setAlertDetails({ message: ERROR_MSG.DUPLICATE_FEE_COMPOSITION, level: ERROR });
      } else {
        setAlertDetails({ message: error.message, level: ERROR });
      }
    }
  }

  async function editFeeComposition(payload: AssignModulePayload) {
    try {
      const editFeeCompositionResponse = await editFeeCompositionMutation({
        variables: { payload: payload },
        refetchQueries: [
          {
            query: getFeeCompositionsQuery,
            variables: {
              limit: limit,
              skip: 0,
            },
          },
        ],
      });

      if (editFeeCompositionResponse?.data) {
        const data = editFeeCompositionResponse.data;
        if (data.updateFeeBooks) {
          setCanClick(true);
          closeModal();
          setAlertDetails({ message: UPDATE_SUCCESS.FEE_COMPOSITION, level: SUCCESS });
          handleFetch();
        } else {
          setAlertDetails({ message: ERROR_MSG.GENERIC_ERROR, level: ERROR });
        }
      }
    } catch (error: any) {
      setCanClick(true);
      if (error.message.includes('E11000 duplicate key error')) {
        setAlertDetails({ message: ERROR_MSG.DUPLICATE_FEE_COMPOSITION, level: ERROR });
      } else {
        setAlertDetails({ message: error.message, level: ERROR });
      }
    }
  }

  function handleAssignModule(formData: any, audience: any) {
    delete formData.undefined;
    if (!isAssignModuleEditModal) {
      formData['audience_type'] = audienceType === AUDIENCE_TYPE.STUDENTS ? 'INDIVIDUAL' : audienceType;
      formData['audience'] = audience;
    }
    const moduleData = moduleIds
      .filter((e: any, i: number) => moduleIds.findIndex((a: any) => a?.id === e?.id) === i)
      .map((item: any, i: number) => ({ fee: item?.id, orderNo: i + 1 }));
    let payload = {
      compositionName: formData.composition_name,
      ...(formData?.audience_type ? { payerType: formData.audience_type } : {}),
      ...(formData?.audience ? { payers: formData.audience } : {}),
      fees: moduleData,
    } as any;
    
    if (formData?.account_name?.value) {
      payload['bankId'] = formData.account_name.value;
    }
    if (isAssignModuleEditModal) {
      payload['compositionId'] = editAssignModuleData?.id;
    }
    if (canClick) {
      if (isAssignModuleEditModal) {
        editFeeComposition(payload)
      } else {
        assignModule(payload);
      }
      setCanClick(false);
    }
  }

  const editCreateModuleModal = (selectedModule: any) => {
    setCreateModuleData(selectedModule);
    hideAlert();
    setIsEditModal(true);
    handleCreateModuleModal(true);
  };

  const editAssignModuleModal = (selectedModule: any) => {
    setAssignModuleData(selectedModule);
    hideAlert();
    setisAssignModuleEditModal(true);
    handleAssignModuleModal(true);
  };

  const deleteModal = (selectedData: any) => {
    setCurrentId(selectedData?.id);
    hideAlert();
    setDeleteOverlay(true);
  };

  const closeDeleteModal = () => {
    setDeleteOverlay(false);
    setCurrentId('');
  }

  const handleDeleteModule = async () => {
    setCanClick(false);
    try {
      const deleteResponse = await deleteModule({
        variables: { id: currentId },
      });
      if (deleteResponse?.data?.deleteFeeDetail) {
        setAlertDetails({ message: DELETE_SUCCESS.MODULE, level: SUCCESS });
        if (refetchModule) refetchModule();
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
    setCanClick(true);
    closeDeleteModal();
  };

  const handleDeleteFeeComp = async () => {
    setCanClick(false);
    try {
      const deleteResponse = await deleteFeeComposition({
        variables: { id: currentId },
      });
      if (deleteResponse?.data?.deleteFeeBook) {
        setAlertDetails({ message: DELETE_SUCCESS.FEE_COMPOSITION, level: SUCCESS });
        if (refetchFeeComposition) refetchFeeComposition();
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
    setCanClick(true);
    closeDeleteModal();
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.MODULE]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchModule(module: string) {
    let isClear = module?.length === 0;
    persistSearch(!isClear, module);
  }

  function handleOrderModal() {
    if (moduleIds.length === 0) {
      setAlertDetails({ message: 'Select Module', level: ERROR });
    } else {
      setOrderModal(true);
    }
  }

  function closeOrderModal() {
    setOrderModal(false);
  }

  const delayedQuery = useCallback(
    debounce((q) => searchModule(q), 500),
    [],
  );

  const handleSearch = (module: string) => {
    setSearching(module);
    delayedQuery(module);
  }

  const hasAGroupTypeInListing: boolean = totalCount && modulesData.some((module) => module?.isGroup);

  return (
    <Fragment>
      <LoadContentWrapper>
        <ModalTabs
          onSelectSwitch={t => {
            changeTabs(t);
          }}
          option1={t('createModule.text')}
          option2={t('feesComposition.label')}
          // option3={t('concession.label')}
          selectionMode={isCreateModule ? 1 : 2}
          roundCorner={false}
          style={[styles.tabStyle]}
          onChange={changeTabs}
          tabswidth={100}
        />
        <Switch>
          <Route path={`${match.url}/create-module/limit/${rowLimit}/page/${page}`}>
            <>
              <HeadingWrapper>
                <SearchWrapper>
                  <Search
                    id="moduleSearch"
                    handleChange={handleSearch}
                    value={searching}
                    label={t('searchModules.text')}
                  />
                </SearchWrapper>
                <AddButtonWrapper>
                  <SecondaryBtn
                    label={t('addModule.text')}
                    onPress={openCreateModuleModal}
                    secondary={false}
                  />
                </AddButtonWrapper>
              </HeadingWrapper>
              <PaymentWrapper>
                <TblContainer>
                  <TblHead setHeadCells={setHeadCells} />
                  {!loading ? (
                    modulesData?.map((d: any, i: number) => (
                      <>
                        <TableBody>
                          <TableRows>
                            <ExpandCollapseCell
                              trow={cellState.open}
                              current={cellState.current}
                              i={i}
                              style={{
                                width: '25%',
                                padding: '10px 16px',
                                ...getCellStyle({
                                  trow,
                                  current,
                                  id: i,
                                  isFirstCell: true,
                                  isLastCell: true,
                                  itemId: d?.id,
                                }),
                              }}
                              textValue={d?.name}
                              CollapseFn={() => {
                                setT(false);
                                setCurrent(null);
                                setCellState({ open: false, current: null });
                              }}
                              ExpandFn={() => {
                                setT(true);
                                setCurrent(i);
                                setCellState({ open: true, current: i });
                              }}
                              disabled={!d?.components || d?.components === 0}
                            />
                            <TableCells
                              style={{
                                width: '25%',
                                padding: '10px 16px',
                                ...getCellStyle({
                                  trow,
                                  current,
                                  id: i,
                                  isFirstCell: false,
                                  isLastCell: false,
                                  itemId: d?.id,
                                }),
                              }}>
                              {!d?.isGroup ? `\u20B9 ${d?.amount}` : null}
                            </TableCells>
                            <TableCells
                              style={{
                                width: '25%',
                                padding: '10px 16px',
                                ...getCellStyle({
                                  trow,
                                  current,
                                  id: i,
                                  isFirstCell: false,
                                  isLastCell: false,
                                  itemId: d?.id,
                                }),
                              }}>
                              {d?.description ? d?.description : '-'}
                            </TableCells>
                            <TableCells
                              style={{
                                width: '25%',
                                padding: '10px 16px',
                                ...getCellStyle({
                                  trow,
                                  current,
                                  id: i,
                                  isFirstCell: false,
                                  isLastCell: true,
                                  itemId: d?.id,
                                }),
                              }}>
                              <TableActionView>
                                <Tooltip
                                  title="Delete Module"
                                  onClick={() => {
                                    deleteModal(d);
                                  }}>
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    color={theme?.table?.editIconColor}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Tooltip>
                              </TableActionView>
                            </TableCells>
                          </TableRows>
                        </TableBody>
                        {trow &&
                          current === i &&
                          (d?.isGroup || (d?.components && d?.components.length > 1)) && (
                            <TableBody
                              style={{
                                borderColor: 'rgba(43, 120, 202, 0.3)',
                                borderWidth: '2px',
                                borderStyle: 'solid',
                              }}>
                              {d.components?.map((item: any, i: number) => (
                                <TableRows key={`emp-table-list-${i}`}>
                                  <TableCells id={`nested_module_name-${i}`}>
                                    {item.name}
                                  </TableCells>
                                  <TableCells id={`nested_amount-${i}`}>
                                    {`\u20B9 ${item.amount}`}
                                  </TableCells>
                                  <TableCells id={`nested_description-${i}`}>
                                    {item.description ? item.description : '-'}
                                  </TableCells>
                                  <TableCells>
                                    <TableActionView>
                                      <Tooltip
                                        title="Delete Module"
                                        onClick={() => {
                                          deleteModal(item);
                                        }}>
                                        <FontAwesomeIcon
                                          icon={faTrashAlt}
                                          color={theme?.table?.editIconColor}
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        />
                                      </Tooltip>
                                    </TableActionView>
                                  </TableCells>
                                </TableRows>
                              ))}
                            </TableBody>
                          )}
                      </>
                    ))
                  ) : (
                    <LoaderWrapper>
                      <LoaderSpinner />
                    </LoaderWrapper>
                  )}
                </TblContainer>

                {modulesData && modulesData?.length > 0 && (
                  <Pagination
                    pathName={'control-panel/payments/create-module'}
                    total={totalCount}
                    page={page}
                    rowLimit={rowLimit}
                    setRowLimit={setRowLimit}
                    hidePagination={totalCount <= rowLimit}
                    setCurrentPage={setPage}
                    hideRowsPerOptions
                  />
                )}

                {modulesData && modulesData?.length === 0 && !loading && (
                  <NoRecordsFound colspan={4} maxHeight={0.5 * height} />
                )}
              </PaymentWrapper>
            </>
          </Route>
          <Route path={`${match.url}/fees-composition/limit/${rowLimit}/page/${page}`}>
            <>
              <HeadingWrapper>
                <SearchWrapper>
                  <Search
                    id="moduleSearch"
                    handleChange={handleSearch}
                    value={searching}
                    label={t('searchComposition.text')}
                  />
                </SearchWrapper>
                <AddButtonWrapper>
                  <SecondaryBtn
                    label={t('compose.label')}
                    onPress={openAssignModuleModal}
                    secondary={false}
                  />
                </AddButtonWrapper>
              </HeadingWrapper>
              <PaymentWrapper>
                <TblContainer>
                  <TblHead />
                  {!feeCompositionLoading ? (
                    recordsAfterPagingAndSorting() && recordsAfterPagingAndSorting()?.length > 0 ? (
                      <TableBody>
                        {recordsAfterPagingAndSorting().map((item: any, index: number) => {
                          return (
                            <TableRows key={index}>
                              <TableCells
                                value={item.compositionName}
                                color={colors.primary}
                                clickable={true}
                                onPress={() => handlePress(item)}
                              />
                              <TableCells
                                value={item.payers?.map((payer: any, i: any) => {
                                  return (
                                    payer?.toUpperCase() +
                                    `${i !== item.payers.length - 1 ? ', ' : ''}`
                                  );
                                })}
                              />
                              <TableCells
                                value={item.feeBooks.map((feeBook: any, z: any) => {
                                  return (
                                    feeBook.groupName +
                                    `${z !== item.feeBooks.length - 1 ? ', ' : ''}`
                                  );
                                })}
                              />
                              <TableActions align="center">
                                <TableActionView>
                                  <EditIconView>
                                    <EditIcon
                                      onPress={() => editAssignModuleModal(item)}
                                      tooltipTitle={t('editFeeComposition.label')}
                                    />
                                  </EditIconView>
                                  <Tooltip
                                    title="Delete Fee Composition"
                                    onClick={() => deleteModal(item)}>
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      color={theme?.table?.editIconColor}
                                    />
                                  </Tooltip>
                                </TableActionView>
                              </TableActions>
                            </TableRows>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <NoRecordsFound colspan={4} maxHeight={0.5 * height} />
                    )
                  ) : (
                    <LoaderWrapper>
                      <LoaderSpinner />
                    </LoaderWrapper>
                  )}
                </TblContainer>
                <Pagination
                  pathName={'control-panel/payments/fees-composition'}
                  total={totalCountFeeCompositions}
                  page={page}
                  rowLimit={rowLimit}
                  hidePagination={totalCountFeeCompositions <= rowLimit}
                  setCurrentPage={setPage}
                  hideRowsPerOptions
                />
              </PaymentWrapper>
            </>
          </Route>
          {/* <Route path={`${match.url}/concession/limit/${rowLimit}/page/${page}`}>
          <>
          </>
        </Route> */}
          <Route exact path={`${match.url}`}>
            <Redirect to={`${match.url}/create-module/limit/${rowLimit}/page/${page}`} />
          </Route>
        </Switch>
      </LoadContentWrapper>

      <AudienceWrapper
        isSubmitting={!canClick}
        handleSave={handleAssignModule}
        handleSubmit={handleSubmit}
        onAlert={alertState}
        setModalVisible={closeOrderModal}
        modalVisible={orderModal}
        Headerpopup={
          isAssignModuleEditModal ? t('editFeeComposition.label') : t('addFeeComposition.label')
        }
        cancelButtonLabel="previous.label"
        addEditButtonLabel={t('next.label')}
        width={840}
        height={570}
        maxWidth={'lg'}
        types={[AUDIENCE_TYPE.STUDENTS, AUDIENCE_TYPE.SUBJECTGROUP]}
        audienceType={audienceType}
        setAudienceType={setAudienceType}
        parentType={'PAYMENT'}
        isEditModal={isAssignModuleEditModal}
        editDetails={
          editAssignModuleData?.payerType === 'INDIVIDUAL'
            ? editAssignModuleData?.payerDetails
            : editAssignModuleData?.payerList
        }>
        <OrderModuleForm moduleData={moduleIds} setModuleData={setModuleIds} />
      </AudienceWrapper>

      <NormalModal
        isSubmitting={!canClick}
        handleSave={handleSubmit(handleOrderModal)}
        setModalVisible={closeModal}
        modalVisible={assignModuleModal}
        Headerpopup={
          isAssignModuleEditModal ? t('editFeeComposition.label') : t('addFeeComposition.label')
        }
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={t('next.label')}
        width={840}
        height={570}
        maxWidth={'lg'}
        visibility={!orderModal}>
        <AssignModuleForm
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          reset={reset}
          setError={setError}
          clearErrors={clearErrors}
          isAssignModuleEditModal={isAssignModuleEditModal}
          editAssignModuleData={editAssignModuleData}
          setModuleIds={setModuleIds}
          moduleIds={moduleIds}
          sortModuleData={sortModuleData}
          handleSearch={handleSearch}
        />
      </NormalModal>

      <NormalModal
        isSubmitting={!canClick}
        handleSave={handleSubmit(handleAddModule)}
        setModalVisible={closeModal}
        modalVisible={createModuleModal}
        Headerpopup={isEditModal ? t('edit.label') : t('addModule.text')}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'save.label' : 'save.label'}
        width={578}
        maxWidth={'lg'}>
        <CreateModuleForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          reset={reset}
          isEditModal={isEditModal}
          editCreateModuleData={editCreateModuleData}
        />
      </NormalModal>

      <DeleteModal
        isSubmitting={!canClick}
        setModalVisible={closeDeleteModal}
        modalVisible={deleteOverlay}
        handleSave={isCreateModule ? handleDeleteModule : handleDeleteFeeComp}
        Headerpopup={isCreateModule ? 'deleteModule.text' : 'deleteFeeComposition.text'}>
        {isCreateModule ? t('deleteModule.warning') : t('deleteFeeComposition.warning')}
      </DeleteModal>
    </Fragment>
  );
};

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchWrapper = styled.div``;

const AddButtonWrapper = styled.div`
  margin-left: auto;
`;

const PaymentWrapper = styled.div`
  .MuiTable-root {
    border-collapse: collapse;
    table-layout: fixed;
    margin-top: 20px;
  }
  .MuiTableCell-body {
    vertical-align: top;
    word-break: break-word;
  }
  .MuiTableHead-root {
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
  }
  .MuiTableCell-head,
  .MuiTableCell-body {
    &:last-child {
      text-align: right;
    }
  }
`;

const EditIconView = styled.div`
  margin-right: 8px;
`;

const TableActionView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
`;

const styles = StyleSheet.create({
  tabStyle: {
    top: 0,
    left: 0,
    backgroundColor: 'white',
    marginBottom: 24,
  },
});
