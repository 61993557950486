/* eslint-disable react-native/no-color-literals */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { RefreshControl, ScrollView, StyleSheet, TouchableHighlight, View } from 'react-native';
import ModalTabs from 'src/components/atoms/Switch/ModalTabs';
import SwipeSwitch from 'src/components/atoms/Switch/Swipe';
import UserCard from 'src/components/atoms/UserCard';
import MobileHeader from 'src/components/hoc/MobileHeader';
import { height, isMobile, isNative, isWeb, width } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { assigneeObjectType } from 'src/components/organism/Assignments/types';
import { useHistory } from 'src/routes/routing';
import { useQuery } from '@apollo/client';
import {
  getAssignmentsQuery,
  getAssignmentSubmissionsQuery,
  getStudentsPendingForSubmission,
} from 'src/graphql/communication/assignments';
import { usePersistStorage } from 'react-native-use-persist-storage';
import navigationStrings from 'src/navigation/navigationStrings';
import { ASSIGNMENT_MODAL_PAGE } from 'src/constant';
import { breakpoints, colors } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import LargeButtonText from 'src/components/atoms/Text/LargeButtonText';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.native';

interface propType {
  assignmentID: string;
  setDetails: (submittedData: any, submitted: boolean) => void;
  assignedStudentList: assigneeObjectType[];
  route?: any;
  navigation?: any;
  isUrlBase?: boolean;
  setStudentId?: any;
  setAssignmentPage?: any;
  setPreviousPage?: any;
}

export default function StudentList(props: propType) {
  const {
    assignedStudentList,
    assignmentID,
    route,
    navigation,
    isUrlBase = true,
    setStudentId,
    setAssignmentPage,
    setPreviousPage,
  } = props;
  let assignment_id: string = '';
  let assigneeStudents = [] as any;
  const { t } = useI18n();
  const history = useHistory();
  const { assignmentId } = route?.params || '';
  const [submitPendingToggle, setSubmitPendingToggle] = usePersistStorage<any>(
    'submit-assigned-switch',
    1,
  );
  const [refreshing, setRefreshing] = useState(false);
  const scrollRef = useRef(null);
  let isDesktop = width > breakpoints.md;

  useEffect(() => {
    scrollRef.current?.scrollTo({ x: 0, y: 0, animated: false });
  }, [submitPendingToggle]);

  if (assignmentId) {
    assignment_id = assignmentId;
  } else {
    assignment_id = assignmentID;
  }

  const { data: submittedStudentList, refetch: submittedStudentRefretch } = useQuery(
    getAssignmentSubmissionsQuery,
    {
      variables: { assignmentId: assignment_id, limit: 0 },
    },
  );

  let submittedStudents = submittedStudentList?.getAssignmentSubmissions?.data;

  if (assignmentId || assignmentID) {
    const { data: pendingSubmissionData, refetch: pendingSubmissionRefetch } = useQuery(
      getStudentsPendingForSubmission,
      {
        variables: { assignmentId: assignmentId || assignmentID, limit: 0 },
      },
    );

    assigneeStudents = pendingSubmissionData?.studentsPendingForSubmission?.data;
  } else {
    assigneeStudents = [];
  }

  function getStudentDetail(student: any, submitted: boolean) {
    let studentId = '';
    if (submitted) {
      studentId = student?.user?.id;
    } else {
      studentId = student?.id;
    }
    if (!isWeb) {
      navigation.navigate(navigationStrings.NATIVE_STUDENT_DETAIL, {
        assignmentId: assignment_id,
        studentId: studentId,
      });
    } else {
      if (isUrlBase) {
        history.push(`/assignments/${assignment_id}/${studentId}/detail`);
      } else {
        setPreviousPage(ASSIGNMENT_MODAL_PAGE[1]);
        setStudentId(studentId);
        setAssignmentPage(ASSIGNMENT_MODAL_PAGE[2]);
      }
    }
  }

  function nativeRefresh() {
    if (submitPendingToggle === 1) {
      submittedStudentRefretch();
    } else {
      asigneeStudentRefetch();
    }
    setRefreshing(false);
  }

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    nativeRefresh();
  }, []);

  return (
    <Fragment>
      {isNative && <MobileHeader label={t('students.label')} backIcon={true} {...props} />}
      <View style={{ backgroundColor: colors.white, flex: 1 }}>
        <View style={{ marginVertical: isWeb ? 0 : 24, marginBottom: 1, flex: 1, height: height }}>
          {isWeb ? (
            <ModalTabs
              onSelectSwitch={(t) => {
                setSubmitPendingToggle(t);
              }}
              option1={t('submitted.label')}
              option2={t('pending.label')}
              selectionMode={submitPendingToggle}
              style={[styles.modalStyle]}
              onChange={setSubmitPendingToggle}
            />
          ) : (
            <HorzMargin24>
              <SwipeSwitch
                onSelectSwitch={(t: any) => {
                  setSubmitPendingToggle(t);
                }}
                option1={t('submitted.label')}
                option2={t('pending.label')}
                selectionMode={submitPendingToggle}
                onChange={setSubmitPendingToggle}
              />
            </HorzMargin24>
          )}

          <ScrollView
            style={{
              marginTop: isWeb ? 4 : 26,
            }}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1 }}
            ref={scrollRef} // to scroll to top on tab change
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
            {submitPendingToggle === 1 && (
              <Fragment>
                {submittedStudents ?
                  <>
                    {submittedStudents?.length == 0 ? (
                      <LargeButtonTextContainer>
                        <LargeButtonText
                          value={'No submissions yet'}
                          color={colors.primaryText}
                          lineHeight={2}
                        />
                      </LargeButtonTextContainer>
                    ) : (
                      <>
                        {submittedStudents &&
                          submittedStudents?.map((student: any) => (
                            <TouchableHighlightButton
                              activeOpacity={1}
                              onPress={() => getStudentDetail(student, true)}
                              underlayColor={isDesktop ? colors.white : colors.inputBorder}
                              key={student?.id}>
                              <UserCard
                                studentDetail={student?.user}
                                isSubmitted={true}
                                isList={true}
                              />
                            </TouchableHighlightButton>
                          ))}
                      </>
                    )}
                  </> :
                  // Loader
                  <View style={{ height: (0.5 * height), alignItems: 'center', justifyContent: 'center' }}>
                    <LoaderSpinner />
                  </View>
                }
              </Fragment>
            )}
            {submitPendingToggle === 2 && (
              <Fragment>
                {assigneeStudents
                  ? <>
                    {assigneeStudents?.length == 0 ?
                      (
                        <LargeButtonTextContainer>
                          <LargeButtonText
                            value={'Hurray! All students are done with submission'}
                            color={colors.primaryText}
                            lineHeight={2}
                          />
                        </LargeButtonTextContainer>
                      ) : (
                        <>
                          {assigneeStudents?.map((student: any) => (
                            <TouchableHighlightButton
                              activeOpacity={1}
                              onPress={() => getStudentDetail(student, false)}
                              underlayColor={isDesktop ? colors.white : colors.inputBorder}
                              key={student?.id}>
                              <UserCard studentDetail={student} isSubmitted={false} isList={true} />
                            </TouchableHighlightButton>
                          ))}
                        </>
                      )}
                  </>
                  :
                  // Loader
                  <View style={{ height: (0.5 * height), alignItems: 'center', justifyContent: 'center' }}>
                    <LoaderSpinner />
                  </View>
                }
              </Fragment>
            )}
          </ScrollView>
        </View>
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  modalStyle: {
    position: isWeb ? 'sticky' : 'relative',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    zIndex: 1,
  },
});

const HorzMargin24 = styled.View`
  margin-left: 24px;
  margin-right: 24px;
`;

const LargeButtonTextContainer = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${isMobile ? 40 : isWeb ? 30 : 50}%;
`;

const TouchableHighlightButton = styled.TouchableOpacity`
  padding: 0 ${isWeb ? 0 : 24}px;
`;
