export default {
  NATIVE_LANDING: 'native_landing',
  NATIVE_AUTH: 'native_auth',
  EMPLOYEE_LOGIN: 'employee_login',
  STUDENT_LOGIN: 'student_login',
  APPLICANT_LOGIN: 'applicant_login',
  USER_LOGIN: 'user_login',
  REQUEST_RESET_PASSWORD: 'request_reset_password',
  SIGNUP_APPLICANT: 'signup_applicant',
  RESET_PASSWORD_EMPLOYEE: 'reset_password_employee',
  RESET_PASSWORD_STUDENT: 'reset_password_student',
  NATIVE_ON_BOARDING: 'native_onBoarding',
  NATIVE_DASHBOARD: 'native_dashboard',
  NATIVE_TABBAR_DASHBOARD: 'native_tabbar_dashboard',
  NATIVE_TABBAR_ASSIGNMENTS: 'native_tabbar_assignments',
  NATIVE_TABBAR_NEWS_NOTICES: 'native_tabbar_news_notices',
  NATIVE_TABBAR_PROFILE: 'native_tabbar_profile',
  NATIVE_TABBAR_USERS: 'native_tabbar_users',
  NATIVE_TABBAR_ADMIN: 'native_tabbar_admin',
  NATIVE_TABBAR_STUDENTS: 'native_tabbar_students',
  NATIVE_PROFILE_DETAILS: 'PROFILE_DETAILS',
  NOTIFICATIONS: 'notifications',
  NATIVE_ASSIGNMENT_DETAIL: 'native_assignment-detail',
  NATIVE_STUDENT_DETAIL: 'native_student_detail',
  NATIVE_ASSIGNMENT_STUDENT_LIST: 'native_assignment_student_list',
  EVENTS: 'events',
  NATIVE_TIMETABLE: 'native_timetable',
  ATTENDANCE: 'attendance',
  NATIVE_ATTENDANCE_STUDENTS: 'native_attendance_students',
  CLASS_STUDENTS_ATTENDANCE_HISTORY: 'class-students-attendance-history',
  ATTENDANCE_HISTORY: 'attendance-history',
  STUDENT_ATTENDANCE: 'student-attendance',
  NATIVE_EDU_EXP_DETAIL: 'native_edu_exp_detail',
  MULTI_ACCOUNT: 'multi_account',
  INSTITUTE_LOGIN_ERROR_PAGE: 'inst_admin_err_page',
  PAYMENT: 'payment',
  PAYMENT_RECEIPT: 'payment_receipt',
  ACCEPT_PAYMENT: 'accept_payment',
  STUDENT_LIST: 'student_list',
  SCANNER: 'scanner',
  MESSAGES: 'messages',
  ACCEPT_PAYMENT_APPLICANT: 'accept_payment_applicant',
  APPLICATION_INFORMATION: "application-information",
  APPLICATON_PAYABLE_LISTING: 'application_payable_listing',
  APPLICATION_LISTING: 'application_listing',
  APPLICATION_FORM_VIEWER: 'application_form_viewer',
  EDUCATION_DETAILS: 'education_details',
  APPLICATION_FEE_PAYMENT: 'application_fee_payment',
  REDIRECTION_SCREEN: 'REDIRECTION_SCREEN',
  NATIVE_ADMISSIONS_LIST: 'native_admissions_list',
  NATIVE_ADMISSION_DETAIL: 'native_admissions_detail',
  ACCEPT_OTHER_PAYMENT: 'accept_other_payment',
  NATIVE_RESULTS_LIST: 'native_results_list',
  NATIVE_RESULT_DETAIL: 'native_result_detail',
};
