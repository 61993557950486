import { PROFILE_PHOTO, USER_TYPE } from 'src/constant';
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import dataTypes from '@data-driven-forms/react-form-renderer/data-types';
import { regexObject } from 'src/constant/regex';
import { bloodGroups, genders, maritalStatuses } from './dd-options';
import { TemplateType, ViewMode } from 'src/components/molecules/Forms/types';
import { getComponentType, handleNameChange } from './util';
import { isWeb } from 'src/constant/device';
import { Field } from '@data-driven-forms/react-form-renderer/common-types';
import { FormOptions } from '@data-driven-forms/react-form-renderer/renderer-context';
import { differenceInYears, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';

const { EMAIL_INVALID, CONTACT_INVALID } = regexObject;

export const personal = (viewMode: ViewMode, isOnboarding?: boolean, isInstituteAdmin?: boolean) => {
  return {
  name: "personalDetails",
  label: "personalInformation.label",
  viewMode,
  headerData: {
    title: 'personalInformation.label',
    skip: true,
    icon: require('src/assets/common/headerOnboardingIcons/v1/profile-3x.png'),
    progress: 0.2,
  },
  outerFields: [
    {
      name: 'profilePhoto',
      label: "profilePicture.label",
      component: viewMode == ViewMode.READ ? "none" : "image-container",
      section: "right",
      fields: [
        {
          name: "profilePicture",
          component: "image",
          isRequired: true,
          section: "right",
          category: 'Identity',
          documentType: PROFILE_PHOTO,
          type: 'image',
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.profilePicture.required'
            },
            {
              type: "image-size",
              maxSize: 20 * 1024 * 1024
            },
            {
              type: "file-type"
            }
          ] : [
            {
              type: "image-size",
              maxSize: 20 * 1024 * 1024
            },
            {
              type: "file-type"
            }
          ],
          infoMessage: 'profilePicture.upload.message',
          mode: isWeb ? 'contain' : 'cover',
          allowCropping: true,
          customFileName: "profile"
        }
      ]
    },
  ],
  fields: [
    {
      name: "personal-details",
      label: "personalDetails.label",
      component: "custom-sub-form",
      section: "left",
      fields: [
        {
          name: "firstName",
          label: "form.firstName.label",
          component: getComponentType(viewMode, "custom-text-field"),
          handleChange: (props: any, formOptions: any) => handleNameChange(formOptions),
          isRequired: true,
          type: "text",
          autoCapitalize: "words",
          resolveProps: (props: any, field: Field, formOptions: FormOptions) => ({
            isDisabled: Boolean(formOptions?.initialValues?.firstName && !isInstituteAdmin),
          }),
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.firstName.required'
            },
              {
                type: validatorTypes.PATTERN,
                pattern: regexObject.ALPHA_NUMERIC_WITH_SPECIAL,
                message: 'error.invalidInput'
              }
            ] : [{
              type: validatorTypes.PATTERN,
              pattern: regexObject.ALPHA_NUMERIC_WITH_SPECIAL,
              message: 'error.invalidInput'
            }],
        },
        {
          name: "middleName",
          label: "form.middleName.label",
          component: getComponentType(viewMode, "custom-text-field"),
          handleChange: (props: any, formOptions: any) => handleNameChange(formOptions),
          isRequired: false,
          type: "text",
          autoCapitalize: "words",
          resolveProps: (props: any, field: Field, formOptions: FormOptions) => ({
            isDisabled: Boolean(formOptions?.initialValues?.middleName && !isInstituteAdmin),
          }),
            validate: [
              {
                type: validatorTypes.PATTERN,
                pattern: regexObject.ALPHA_NUMERIC_WITH_SPECIAL,
                message: 'error.invalidInput'
              }
            ],
        },
        {
          name: "lastName",
          label: "form.lastName.label",
          component: getComponentType(viewMode, "custom-text-field"),
          handleChange: (props: any, formOptions: any) => handleNameChange(formOptions),
          isRequired: true,
          type: "text",
          autoCapitalize: "words",
          isDisabled: true,
          resolveProps: (props: any, field: Field, formOptions: FormOptions) => ({
            isDisabled: Boolean(formOptions?.initialValues?.lastName && !isInstituteAdmin),
          }),
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.lastName.required'
            },
              {
                type: validatorTypes.PATTERN,
                pattern: regexObject.ALPHA_NUMERIC_WITH_SPECIAL,
                message: 'error.invalidInput'
              }
            ] : [{
              type: validatorTypes.PATTERN,
              pattern: regexObject.ALPHA_NUMERIC_WITH_SPECIAL,
              message: 'error.invalidInput'
            }],
        },
        {
          name: "blank",
          label: "",
          component: "none",
          ignoreInProfileView: true,
        },
        {
          name: "fullName",
          label: "form.fullName.label",
          component: getComponentType(viewMode, "custom-text-field"),
          type: "text",
          autoCapitalize: "words",
          isDisabled: true,
          ignoreField: true,
          ignoreInProfileView: true,
          condition: {
            when: 'blank',
            is: () => !isOnboarding,
          }
        },
        {
          name: "blank",
          label: "",
          component: "none",
          ignoreInProfileView: true,
          condition: {
            when: 'blank',
            is: () => !isOnboarding,
          }
        },
        {
          name: "gender",
          label: "form.gender.label",
          component: getComponentType(viewMode, "select"),
          isRequired: true,
          options: genders,
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.gender.required'
            },
          ] : [],
        },
        {
          name: "dob",
          label: "form.dob.required.label",
          component: getComponentType(viewMode, "date-time-picker"),
          isRequired: true,
            disableFuture: true,
          dateTimeMode: "date",
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.dob.required'
            },
          ] : [],
        },
        {
          name: "placeOfBirth",
          label: "form.placeOfBirth.required.label",
          component: getComponentType(viewMode, "custom-text-field"),
          isRequired: true,
          type: "text",
          autoCapitalize: "words",
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.placeOfBirth.required'
            },
          ] : [],
        },
        {
          name: "doj",
          label: "form.doj.label",
          component: getComponentType(viewMode, "date-time-picker"),
          isRequired: true,
          type: "date",
          autoCapitalize: "words",
          validate: !isInstituteAdmin ? [] : [],
        },
        {
          name: "bloodGroup",
          label: "form.bloodGroup.label",
          component: getComponentType(viewMode, "select"),
          isRequired: true,
          options: bloodGroups,
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.bloodGroup.required'
            },
          ] : [],
        },
        {
          name: "maritalStatus",
          label: "form.maritalStatus.label",
          component: getComponentType(viewMode, "select"),
          isRequired: false,
          options: maritalStatuses,
          condition: {
            when: "dob",
            is: (value: string | null) => {
              if (value) {
                const date = parse(value, DT.DATE_FORMAT_SLASH, new Date());
                const age = differenceInYears(new Date(), date);
                return age >= 18;
              }
              return false;
            }
          }
        },
        {
          name: "employeementStatus",
          label: "form.employeementStatus.label",
          component: getComponentType(viewMode, "select"),
          isRequired: false,
          options: [
            { label: "Currently Employed", value: "Currently Employed" },
            { label: "No Longer Employed", value: "No Longer Employed" },
            { label: "Transferred to Another Group of Institutes", value: "Transferred to Another Group Of Institutes" }
          ],
        }
      ]
    },
    {
      component: "custom-sub-form",
      label: "contactDetails.label",
      name: "contact-details",
      section: "left",
      fields: [
        {
          name: "mobileNumber",
          label: "form.mobileNumber.label",
          component: getComponentType(viewMode, "custom-text-field"),
          type: "number",
          dataType: dataTypes.INTEGER,
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.contactNumber.required'
            },
            {
              type: validatorTypes.PATTERN,
              pattern: CONTACT_INVALID,
              message: 'error.contactNumber.invalid',
            }
          ] : [
            {
              type: validatorTypes.PATTERN,
              pattern: CONTACT_INVALID,
              message: 'error.contactNumber.invalid',
            }
          ],
        },
        {
          name: "blank",
          label: "",
          component: "none",
          ignoreInProfileView: true,
        },
        {
          name: "alternateContactPerson",
          label: 'form.emergencyPerson.label',
          isRequired: true,
          component: getComponentType(viewMode, 'custom-text-field'),
          type: "string",
          dataType: dataTypes.STRING,
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.emergencyPerson.required'
            },
          ] : [],
        },
        {
          name: "alternateContactNumber",
          label: "form.emergencyNumber.label",
          component: getComponentType(viewMode, "custom-text-field"),
          type: "number",
          dataType: dataTypes.INTEGER,
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.emergencyContactNumber.required'
            },
            {
              type: validatorTypes.PATTERN,
              pattern: CONTACT_INVALID,
              message: 'error.emergencyContactNumber.invalid',
            },
            {
              type: 'contact',
            }
          ] : [
            {
              type: validatorTypes.PATTERN,
              pattern: CONTACT_INVALID,
              message: 'error.emergencyContactNumber.invalid',
            },
            {
              type: 'contact',
            }
          ],
        },
        {
          name: "email",
          label: "form.email.label",
          component: getComponentType(viewMode, "custom-text-field"),
          type: "text",
          resolveProps: (props: any, field: Field, formOptions: FormOptions) => ({
            isDisabled: Boolean(formOptions?.initialValues?.email && !isInstituteAdmin),
          }),
          validate: !isInstituteAdmin ? [
            {
              type: validatorTypes.REQUIRED,
              message: 'error.email.required'
            },
            {
              type: validatorTypes.PATTERN,
              pattern: EMAIL_INVALID,
              message: 'error.email.invalid',
            }
          ] : [
            {
              type: validatorTypes.PATTERN,
              pattern: EMAIL_INVALID,
              message: 'error.email.invalid',
            }
          ],
        },
        {
          name: "alternateEmail",
          label: "form.altEmail.label",
          component: getComponentType(viewMode, "custom-text-field"),
          type: "text",
          validate: [
            {
              type: validatorTypes.PATTERN,
              pattern: EMAIL_INVALID,
              message: 'error.email.invalid',
            }
          ],
        }
      ]
    }
  ]
  }
}
