import { gql, useLazyQuery, useMutation } from '@apollo/client';

const admissionUniversitiesQuery = gql`
  query {
    admissionUniversities {
      value: id
      label: name
    }
  }
`;

export function useGetAdmissionUniversities() {
  const [getAdmissionUniversities, { loading: admissionUniversitiesLoading, data: admissionUniversitiesData, error: admissionUniversitiesError, refetch: admissionUniversitiesRefetch }] =
    useLazyQuery(admissionUniversitiesQuery, { fetchPolicy: 'network-only' });
  return { getAdmissionUniversities, admissionUniversitiesData, admissionUniversitiesError, admissionUniversitiesLoading, admissionUniversitiesRefetch };
};

const admissionInstitutesQuery = gql`
  query($university: String!) {
    admissionInstitutes(university: $university) {
      value: id
      label: name
    }
  }
`;

export function useGetAdmissionInstitutes() {
  const [getAdmissionInstitutes, { loading: admissionInstitutesLoading, data: admissionInstitutesData, error: admissionInstitutesError, refetch: admissionInstitutesRefetch }] =
    useLazyQuery(admissionInstitutesQuery, { fetchPolicy: 'network-only' });
  return { getAdmissionInstitutes, admissionInstitutesData, admissionInstitutesError, admissionInstitutesLoading, admissionInstitutesRefetch };
};

const admissionCoursesQuery = gql`
  query($institute: String!, $university: String!) {
    admissionCourses(institute: $institute, university: $university) {
      value: id
      label: name
    }
  }
`;

export function useGetAdmissionCourses() {
  const [getAdmissionCourses, { loading: admissionCoursesLoading, data: admissionCoursesData, error: admissionCoursesError, refetch: admissionCoursesRefetch }] =
    useLazyQuery(admissionCoursesQuery, { fetchPolicy: 'network-only' });
  return { getAdmissionCourses, admissionCoursesData, admissionCoursesError, admissionCoursesLoading, admissionCoursesRefetch };
};

const admissionClassesQuery = gql`
  query($course: String!, $institute: String!, $university: String!) {
    admissionClasses(
      course: $course
      institute: $institute
      university: $university
    ) {
      value: id
      label: name
    }
  }
`;

export function useGetAdmissionClasses() {
  const [getAdmissionClasses, { loading: admissionClassesLoading, data: admissionClassesData, error: admissionClassesError, refetch: admissionClassesRefetch }] =
    useLazyQuery(admissionClassesQuery, { fetchPolicy: 'network-only' });
  return { getAdmissionClasses, admissionClassesData, admissionClassesError, admissionClassesLoading, admissionClassesRefetch };
};

const openAdmissionQuery = gql`
  query(
    $applicantType: ApplicantType
    $class: String!
    $course: String!
    $institute: String!
    $university: String!
  ) {
    openAdmission(
      applicantType: $applicantType
      class: $class
      course: $course
      institute: $institute
      university: $university
    ) {
      id
      name
      allowedApplicantType
      batch {
        id
        name
        alias
      }
      criteria {
        qualifications {
          name {
            id
            name
            alias
            level
          }
        }
      }
      status
      totalSeats
      lastDate
      subjectGroups {
        seats
        subjectGroup {
          value: id
          label: name
        }
        fee {
          id
          feeBooks {
            id
            amount
          }
        }
      }
      allowedApplicantType
    }
  }
`;

export function useOpenAdmission() {
  const [getOpenAdmission, { data: openAdmissionData, loading: openAdmissionLoading }] = useLazyQuery(openAdmissionQuery, { fetchPolicy: 'network-only' });
  return { getOpenAdmission, openAdmissionData, openAdmissionLoading };
};

const createApplicationMutation = gql`
  mutation($payload: ApplicationInput!, $remove: RemoveApplicationDetailsInput) {
    createApplication(payload: $payload, remove: $remove) {
      id
    }
  }
`;

export function useCreateApplication() {
  const [createApplication, { data: createApplicationData, error: createApplicationError }] = useMutation(createApplicationMutation);
  return { createApplication, createApplicationData, createApplicationError };
};

const submitApplicationMutation = gql`
  mutation($id: String!) {
    submitApplication(id: $id) {
      id
      status
    }
  }
`;

export function useSubmitApplication() {
  const [submitApplication, { data: submitApplicationData, error: submitApplicationError }] = useMutation(submitApplicationMutation);
  return { submitApplication, submitApplicationData, submitApplicationError };
};

export const getApplicationDetailsQuery = gql`
  query($id: String!) {
    application(id: $id) {
      id
      applicationId
      fees {
        installmentPayable
        cancellationFeeCharges
      }
      applicantType
      freeshipApplied
      universityApplicationId
      universityApplicationDoc {
        id
        name: documentName
        uri: documentUrl
        type
        category
      }
      inHouseApplied
      inHouseDetail {
        instituteCode
        rollNo
      }
      cap {
        id
        capId
        document {
          id
          name: documentName
          uri: documentUrl
          type
          category
        }
      }
      formOptionAdmission: admission {
        label: name
        value: id
      }
      admission {
        id
        name
        allowedPaymentModes
        inHouseApplicantAllowed
        askAdmissionProcessId
        inHouseInstitutes {
          label: instituteName
          value: instituteCode
        }
        institute: institute {
          name
        }
        university: university {
          name
        }
        course: course {
          alias
        }
        class: class {
          alias
        }
        batch: batch {
          alias
        }
        criteria {
          qualifications {
            name {
              name
            }
          }
        }
        subjectGroups {
          fee {
            feeBooks {
              amount
            }
          }
          subjectGroup {
            id
            name
          }
        }
        lastDate
        applicationFeeDetail {
          feeAmount
        }
        applicationFeeRequired
      }
      subjectGroup {
        id
        name
        subjects {
          id
          name
        }
      }
      educationDetails {
        educationDetail {
          passedInFirstAttempt
          id
          allDocument {
            type {
              typeId: id
              name
              label: name
              category
              defaultUserSelectionRequired
            }
            document {
              id
              category
              name: documentName
              uri: documentUrl
              fileExtension
              fileType
              typeRef
            }
          }
          majorSubjectMarks {
            major
            marks
          }
          documents {
            id
            name: documentName
            uri: documentUrl
          }
          course
          gradeObtained
          institution
          university
          udiseNo
          qualificationRef {
            id
            name
            level
          }
          averageMarks
          marksObtained
          totalMarks
          paassingYear
          streams {
            value: id
            label: name
          }
        }
        qualificationName {
          id
          name
          alias
          level
        }
      }
      status
      statusActivity {
        date
        status
        remarks
      }
      user {
        personalDetails {
          firstName
          lastName
          fullName
        }
      }
      documents {
        document {
          id
          name: documentName
          uri: documentUrl
          type
          category
        }
        requiredDetail {
          allowedPlace
          docType {
            id
            name
            category
          }
          required
        }
      }
    }
  }
`;

export function useGetApplicationDetails() {
  const [getApplicationDetails, { data: applicationDetailsData, loading: applicationDetailsLoading, refetch: refetchApplicationDetails }] = useLazyQuery(getApplicationDetailsQuery, { fetchPolicy: 'network-only' });
  return { getApplicationDetails, applicationDetailsData, refetchApplicationDetails, applicationDetailsLoading };
};

const applicationsListQuery = gql`
  query(
    $filters: ApplicationFilterInput
    $limit: Int
    $skip: Int
    $sort: ApplicationSortInput
  ) {
    applications(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        scrollRefNo
        applicationId
        cap {
          id
          capId
        }
        applicantType
        inHouseDetail {
          instituteCode
          rollNo
        }
        admission {
          allowedPaymentModes
          inHouseInstitutes {
            instituteCode
            instituteName
          }
          status
          name
          university {
            id
            name
          }
          institute {
            id
            name
          }
          class {
            id
            alias
          }
          course {
            id
            alias
          }
          subjectGroups {
            subjectGroup {
              id
              name
            }
          }
          applicationFeeDetail {
            feeAmount
          }
          applicationFeeRequired
        }
        status
        statusActivity {
          date
          status
          remarks
        }
        subjectGroup {
          id
          name
        }
        educationDetails {
          educationDetail {
            id
            documents {
              id
              name: documentName
              uri: documentUrl
            }
            course
            gradeObtained
            institution
            university
            averageMarks
            marksObtained
            totalMarks
            paassingYear
          }
        }
        fees {
          installmentPayable
          cancellationFeeCharges
        }
      }
      totalCount
    }
  }
`;

export function useGetApplicationsList() {
  const [getApplicationsList, { loading: applicationsListLoading, data: applicationsListData, refetch: applicationsListRefetch }] =
    useLazyQuery(applicationsListQuery, { fetchPolicy: 'network-only' });
  return { getApplicationsList, applicationsListData, applicationsListLoading, applicationsListRefetch };
};

export const createAdmissionPaymentOrderMutation = gql`
  mutation($applicationId: String!) {
    createAdmissionPaymentOrder(applicationId: $applicationId) {
      key
      orderId
    }
  }
`;

const createAdmissionPaymentMutation = gql`
  mutation(
    $applicationId: String!
    $paymentDetail: CreatePaymentDetailInput
  ) {
    createAdmissionPayment(
      applicationId: $applicationId
      paymentDetail: $paymentDetail
    ) {
      id
      date
      payer {
        id
      }
      feeBook {
        id
      }
      mode
      detail {
        payId
        approvedBy
      }
    }
  }
`;

export function useCreateAdmissionPayment() {
  const [createAdmissionPayment, { error: createAdmissionPaymentError }] = useMutation(createAdmissionPaymentMutation);
  return { createAdmissionPayment, createAdmissionPaymentError };
};

export const filteredOpenAdmissionQuery = gql`
  query(
    $applicantType: ApplicantType
    $class: String!
    $course: String!
    $institute: String!
    $university: String!
  ) {
    openAdmission(
      applicantType: $applicantType
      class: $class
      course: $course
      institute: $institute
      university: $university
    ) {
      value: id
      label: name
      subjectGroups {
        subjectGroup {
          value: id
          label: name
        }
      }
      allowedApplicantType
    }
  }
`;

const createApplicationFeesPaymentMutation = gql`
  mutation($applicationId: String!) {
    createNDPSOrder(applicationId: $applicationId) {
      token
      merchId
      amount
      custEmail
      custContact
    }
  }
`;

export function useCreateApplicationFeesPayment() {
  const [createApplicationFeesPayment, { error: createApplicationFeesPaymentError, data: createApplicationFeesPaymentData }] = useMutation(createApplicationFeesPaymentMutation);
  return { createApplicationFeesPayment, createApplicationFeesPaymentError, createApplicationFeesPaymentData };
};

const createAdmissionFeePaymentMutation = gql`
  mutation($applicationId: String!) {
    createAdmissionFeeOrder(applicationId: $applicationId) {
      token
      merchId
      amount
      custEmail
      custContact
    }
  }
`;

export function useCreateAdmissionFeePayment() {
  const [createAdmissionFeePayment, { error: createAdmissionFeePaymentError, data: createAdmissionFeePaymentData }] = useMutation(createAdmissionFeePaymentMutation);
  return { createAdmissionFeePayment, createAdmissionFeePaymentError, createAdmissionFeePaymentData };
};