import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 16 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M7.99982 0C3.58056 0 0 3.58056 0 7.99982C0 12.4191 3.58056 15.9996 7.99982 15.9996C12.4191 15.9996 15.9996 12.4191 15.9996 7.99982C15.9996 3.58056 12.4191 0 7.99982 0ZM7.99982 14.9674C4.15797 14.9674 1.03223 11.8417 1.03223 7.99982C1.03223 4.15797 4.15797 1.03223 7.99982 1.03223C11.8417 1.03223 14.9674 4.15797 14.9674 7.99982C14.9674 11.8417 11.8417 14.9674 7.99982 14.9674ZM9.29979 7.82885L6.97726 7.17726C6.58695 7.06758 6.31276 6.71275 6.31276 6.31598C6.31276 5.82245 6.72565 5.41923 7.23209 5.41923H8.68367C9.04495 5.41923 9.39011 5.53536 9.67075 5.74503C9.77397 5.82245 9.9159 5.80954 10.0062 5.71922L10.3707 5.34826C10.4804 5.23859 10.4675 5.05795 10.3449 4.96118C9.87397 4.58699 9.29334 4.38377 8.68045 4.38377H8.51593V3.09348C8.51593 2.95155 8.39981 2.83542 8.25788 2.83542H7.74176C7.59983 2.83542 7.4837 2.95155 7.4837 3.09348V4.38377H7.23209C6.15792 4.38377 5.28052 5.24827 5.28052 6.31276C5.28052 7.1708 5.86438 7.93208 6.69662 8.16755L9.01915 8.81915C9.40946 8.92883 9.68365 9.28366 9.68365 9.68042C9.68365 10.174 9.27076 10.5772 8.76432 10.5772H7.31274C6.95145 10.5772 6.6063 10.4611 6.32566 10.2514C6.22244 10.174 6.08051 10.1869 5.99019 10.2772L5.62568 10.6481C5.516 10.7578 5.52891 10.9385 5.65148 11.0352C6.12244 11.4094 6.70307 11.6126 7.31596 11.6126H7.4837V12.9029C7.4837 13.0449 7.59983 13.161 7.74176 13.161H8.25788C8.39981 13.161 8.51593 13.0449 8.51593 12.9029V11.6126H8.76754C9.84171 11.6126 10.7191 10.7481 10.7191 9.68365C10.7159 8.8256 10.1353 8.06433 9.29979 7.82885Z"
        fill="rgba(0, 0, 0, 0.6)"
        fill-opacity={0.6}
      />
    </Svg>
  );
}

export default SvgComponent;
