import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { colors } from 'src/styles/theme/styles';
import { attachmentType } from 'src/components/organism/Assignments/types';
import { getStorageFunction } from 'src/components/services/storage-service';
import { KEYS } from 'src/constant/key';
import { FILE_ICONS } from 'src/constant';
import { getExtention, getFileName } from 'src/components/services';
import styled from 'styled-components/native';
import SmallTextAtom from '../Text/SmallTextAtom';

interface Props {
  fileData: attachmentType;
  showStyle?: boolean;
  customStyle?: any;
}

export default function DownloadFile(props: Props) {
  const { fileData, showStyle = true, customStyle } = props;
  const { documentUrl }: any = fileData;
  const [fileIcon, setFileIcon] = useState<any>(FILE_ICONS.generic);

  useEffect(() => {
    checkFileExtension();
  }, [documentUrl]);

  function checkFileExtension() {
    let exten: any = getExtention(fileData.documentName);
    if (FILE_ICONS[exten.toLocaleLowerCase()]) {
      setFileIcon(FILE_ICONS[exten.toLocaleLowerCase()]);
    }
  }

  async function downloadFile(download = true) {
    let token = await getStorageFunction(KEYS.ACCESS_TOKEN);
    const headers = new Headers();
    headers.set('authorization', `Bearer ${token}`);
    headers.set('mode', 'no-cors');
    headers.set('Access-Control-Allow-Origin', '*');
    let fileName = getFileName(fileData?.documentName);
    console.log(documentUrl, 'urdocl');
    fetch(documentUrl, { headers })
      .then((response) => {
        response.blob().then((blob) => {
          var mimetype = blob.type;
          const splitArray = mimetype.split('/');
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.target = '_blank';
          if (download) {
            a.download = `${fileName}.${splitArray[1]}`;
          }
          a.click();
        });
      })
      .catch((error) => {
        console.log('error', error);
      });
  }
  return (
    <WraperView>
      <FileView onPress={() => downloadFile(false)}>
        <FontAwesomeIcon icon={fileIcon} color={colors.red} size={18} />
        <TextView>
          <SmallTextAtom
            value={fileData?.documentName || ''}
            lines={1}
            fontWeight={400}
            color={colors.primaryText}
          />
        </TextView>
      </FileView>

      <DownloadTouchableView onPress={downloadFile}>
        <FontAwesomeIcon icon={faDownload} color={colors.secondaryText} size={14} />
      </DownloadTouchableView>
    </WraperView>
  );
}

const WraperView = styled.View`
  flex-direction: row;
  margin-bottom: 20;
  align-items: center;
  margin-top: 8;
`;

const FileView = styled.TouchableOpacity`
  flex-direction: row;
  margin-right: 13;
`;

const TextView = styled.View`
  flex: 1;
  margin-left: 8px;
  align-self: center;
`;

const DownloadTouchableView = styled.TouchableOpacity`
  align-self: center;
`;
