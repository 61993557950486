/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { DrawerItem } from '@react-navigation/drawer';
import styled from 'styled-components/native';
import {
  clearGarbage,
  deleteUserTokenFromTokenListInStorage,
  flushStorageFunction,
  getFullListOfTokens,
  getStorageFunction,
  resetOnboardingVisited,
} from 'src/components/services/storage-service';
import { allowedModulesState, authToken, UserInformation, userInformation } from 'src/utils/manageState';
import { useDrawer } from './drawer-config';
import { DEVICE_TYPE, USER_TYPE } from 'src/constant';
import { KEYS } from 'src/constant/key';
import { useI18n } from 'src/i18n/hooks';
import { Icon } from 'src/components/atoms/Icon/Icon';
import { colors, fonts } from 'src/styles/theme/styles';
import { isIOS, height } from 'src/constant/device';
import { useLogout } from 'src/graphql/logout';
import navigationStrings from './navigationStrings';
import { useSidebarSystem } from 'src/contexts/sidebar-context';
import { intersection } from 'lodash';
import { useTheme } from 'styled-components';
import { ScrollView } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import DeviceInfo from 'react-native-device-info';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { faUserGraduate } from '@fortawesome/pro-light-svg-icons';
import { AccountObject, getAllAccountsFromStorage, removeLoggedOutAccounts } from 'src/components/organism/Login/LoginHelpers';

//local imports

const getTopMargin = () => {
  if (height > 600 && height <= 700) {
    return 13;
  } else if (height >= 701 && height <= 900) {
    return 18;
  } else if (height >= 901) {
    return 22;
  } else {
    return 20;
  }
};

function CustomDrawer(props: any) {
  const { t } = useI18n();
  const { routesConfig } = useDrawer();
  const currentUserObject = userInformation();
  const userType = currentUserObject?.userType || 0;
  const userSubType = currentUserObject?.userDetail?.type || [];
  const allowedModules = allowedModulesState();
  const { toggleSidebar } = useSidebarSystem();
  const { rem } = useTheme();
  const isAdminAndTeacher =
    currentUserObject?.userDetail?.type?.includes('TEACHING') &&
    currentUserObject?.userDetail?.type?.includes('ADMIN');

  const { logoutUser } = useLogout();

  const completelyLogout = () => {
    clearGarbage();
    props.navigation.closeDrawer();
    toggleSidebar({ toggle: 1 });
    props.authCallback(false);
  };

  async function handleLogout() {
    try {
      const currentUserEmail = currentUserObject?.email;
      if (currentUserEmail) {
        let singularAccount: boolean = true;
        const fcmToken = await getStorageFunction(KEYS.FCM_TOKEN);

        getAllAccountsFromStorage()
          .then((accountCards: AccountObject[]) => {
            singularAccount =
              accountCards.length === 1 ||
              accountCards.every(
                (acc: AccountObject) => acc.email === currentUserEmail
              );
          })
          .catch((err) => {
            console.log(err, "err in fecthing list of tokens");
          });

        const selectivelyRemoveAccounts = async () => {
          const userInitialValue: UserInformation = {};
          userInformation(userInitialValue);
          authToken({ edbaToken: "" });
          flushStorageFunction(KEYS.REFRESH_TOKEN);
          flushStorageFunction(KEYS.ACCESS_TOKEN);
          flushStorageFunction(KEYS.FCM_TOKEN);
          resetOnboardingVisited();
          await removeLoggedOutAccounts(currentUserEmail);
          toggleSidebar({ toggle: 0 });
          props.navigation.closeDrawer();
          props.navigation.reset({
            index: 0,
            routes: [{ name: navigationStrings.MULTI_ACCOUNT }],
          });
        };

        const handleLogoutFunction = () => {
          if (singularAccount) {
            completelyLogout();
          } else {
            selectivelyRemoveAccounts();
          }
        };

        logoutUser({
          variables: {
            device: isIOS ? DEVICE_TYPE.IOS : DEVICE_TYPE.ANDROID,
            fcmToken,
          },
        })
          .then(() => {
            handleLogoutFunction();
          })
          .catch(() => {
            throw new Error("Logout Failed")
          });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const styles = {
    drawerItemLabelStyle: {
      color: colors.secondaryText,
      marginLeft: -20,
      fontFamily: fonts.medium,
      fontSize: rem(1.6),
    },
    drawerItemStyle: {
      marginTop: isIOS ? '1.3%' : '-1%',
      marginBottom: '-1%'
    }
  }

  return (
    <DrawerWrapper>
      <DrawerFirstSection>
        <EDBALogoView>
          <NativeEDBALogo source={require('src/assets/logo/new-logo-4x.png')} />
          <SmallTextAtom value={`v${DeviceInfo.getVersion()}`} color={colors.primaryText} />
        </EDBALogoView>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          {routesConfig.map((item, i) => {
            let allowed = item.module == 'default' || (allowedModules && allowedModules?.includes(item.module));
            allowed = allowed && item.authUser.includes(userType);
            if (userType == USER_TYPE.EMPLOYEE) {
              if (item?.subAuth) {
                allowed = allowed && intersection(item?.subAuth, userSubType)?.length > 0
              }
            }
            if (item.iconName === 'institute' && userType !== USER_TYPE.APPLICANT) {
              return (
                <InstituteContainer>
                  <InstituteIconWrapper>
                    {/* <FontAwesomeIcon icon={faUniversity} size="lg" /> */}
                    <Icon name={item?.iconName} />
                  </InstituteIconWrapper>
                  <InstituteTextWrapper>
                    <InstituteLabel>{item.label}</InstituteLabel>
                    <InstituteName>
                      {currentUserObject?.userDetail?.instituteName || '--'}
                    </InstituteName>
                  </InstituteTextWrapper>
                </InstituteContainer>
              );
            } else if (allowed) {
              return (
                <DrawerItem
                  key={i}
                  label={item?.label}
                  labelStyle={{
                    color: colors.secondaryText,
                    marginLeft: -15,
                    fontFamily: fonts.medium,
                    fontSize: rem(1.6),
                  }}
                  icon={() =>
                    <IconWrapper>
                      {item?.defaultIcon ? (
                        <FontAwesomeIcon
                          icon={item?.iconName}
                          color={item?.iconColor}
                          size={item?.iconSize}
                        />
                      ) : (
                        item?.customImage ?
                          <CustomImageWrapper
                            source={item.customImage.path}
                            height={item.customImage.height}
                            width={item.customImage.width}
                          />
                          :
                          <Icon name={item?.iconName} size={item?.iconSize} />
                      )}
                    </IconWrapper>
                  }
                  onPress={() => {
                    props.navigation.navigate(item?.url);
                  }}
                  style={styles.drawerItemStyle}
                />
              )
            }
          })}
        </ScrollView>
      </DrawerFirstSection>
      <DrawerSecondSection>
        <DrawerItem
          label={t('sidebar.native.profile')}
          labelStyle={styles.drawerItemLabelStyle}
          icon={(props) => (<IconWrapper>
            <FontAwesomeIcon
              icon={faUserGraduate}
              color={colors.secondaryText}
              size={18}
            />
          </IconWrapper>)
          }
          onPress={() => {
            props.navigation.navigate('native_tabbar_profile');
          }}
          style={styles.drawerItemStyle}
        />
        {[USER_TYPE.EMPLOYEE, USER_TYPE.STUDENT, USER_TYPE.APPLICANT].includes(userType) && (
          <DrawerItem
            label={t('accounts.label')}
            labelStyle={styles.drawerItemLabelStyle}
            icon={(props) => (<IconWrapper>
              <FontAwesomeIcon
                icon={faUserPlus}
                color={colors.secondaryText}
                size={19}
              />
            </IconWrapper>)}
            onPress={() => {
              props.navigation.navigate('multi_account');
            }}
            style={styles.drawerItemStyle}
          />
        )}
        <DrawerItem
          label={t('sidebar.native.logout')}
          labelStyle={styles.drawerItemLabelStyle}
          icon={(props) => (<IconWrapper>
            <Icon name={'logout'} color={colors.primaryText} />
          </IconWrapper>)}
          onPress={handleLogout}
          style={styles.drawerItemStyle}
        />
      </DrawerSecondSection>
    </DrawerWrapper>
  );
}

export default CustomDrawer;

const DrawerWrapper = styled.View`
  flex: 1;
  background-color: ${colors.white};
  justify-content: space-between;
`;

const IconWrapper = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
`;

const DrawerFirstSection = styled.View`
  flex: 0.9;
  margin-top: ${isIOS ? getTopMargin() : 15}%;
  margin-left: 10px;
`;
const DrawerSecondSection = styled.View`
  flex: 0.25;
  margin-left: 10px;
  background-color: ${colors.white};
`;
const InstituteContainer = styled.View`
  background-color: ${colors.greyBackground};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -4%;
  margin-bottom: 2%;
  padding: 7px 0;
`;
const InstituteIconWrapper = styled.View`
  margin-left: 10.5%;
`;
const InstituteTextWrapper = styled.View`
  margin-left: 6.5%;
`;
const InstituteLabel = styled.Text`
  font-size: 15;
  color: ${colors.secondaryText};
  font-family: ${fonts.regular};
  line-height: 20px;
`;
const InstituteName = styled.Text`
  font-size: 16;
  color: ${colors.primaryText};
  font-family: ${fonts.semibold};
  max-width: 200px;
  line-height: 24px;
`;

const EDBALogoView = styled.View`
  margin: 0 7% 5.5% 7%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const NativeEDBALogo = styled.Image`
  height: ${({ theme }) => theme.rem(2.5)};
  width: ${({ theme }) => theme.rem(7)};
`;

const CustomImageWrapper = styled.Image<{ height: number; width: number }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;
