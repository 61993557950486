import React, { MutableRefObject, useEffect, useState } from "react";
import { NoRecordsFound, TableCells, TableDiv, TableRows } from "./TableAtom";

import Input from "src/components/atoms/Input/input.web";
import { TableBody } from "@mui/material";
import { UnAssignedStudent } from "src/components/organism/Enrollment/Promotions/types";
import styled from "styled-components/native";
import useTable from "./UseTable";
import { focusNextElement, properCase } from "src/utils/utility";
import { regexObject } from "src/constant/regex";
import { useI18n } from "src/i18n/hooks";

interface PropsType {
  maxHeightTable: number;
  header: Array<Object>;
  tabledata: UnAssignedStudent[];
  setData: any;
  height: number;
  checkError: MutableRefObject<boolean>;
  isDataAvailable: boolean;
}

const AssignRollNoTable = (props: PropsType) => {
  const { maxHeightTable, header, tabledata, setData, height, checkError, isDataAvailable } =
    props;
  const { TblContainer, TblHead } = useTable(
    tabledata,
    header,
    null,
    tabledata
  );
  const [value, setValue] = useState({});
  const restrictedKeys = ['e', 'E', '-', '+', '.'];
  const { t } = useI18n();

  const handleChange = (val: string, id: string) => {
    setValue((prev) => ({
      ...prev,
      [id]: {
        rollNo: parseInt(val) || "",
      },
    }));
  };

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      setTableData();
    }
  }, [value]);

  useEffect(() => {
    if (tabledata) {
      let items = { ...value };
      tabledata?.forEach((item: UnAssignedStudent) => {
        items[item?.id] = {
          rollNo: item?.rollNo || "",
        };
      });
      setValue({ ...items });
    }
  }, [tabledata]);

  const setTableData = () => {
    const data = Object.keys(value).flatMap((key) => {
      if (value[key]) {
        return {
          id: key,
          rollNo: value[key]?.rollNo,
        };
      }
      return [];
    });
    setData(data);
  };

  return (
    <TableContainer>
      <TableDiv>
        <TblContainer
          tabledata={tabledata}
          maxHeight={maxHeightTable && maxHeightTable}
          height={height && height}
        >
          <TblHead />
          {tabledata && tabledata?.length > 0 ? (
            <TableBody>
              {tabledata?.map((item: UnAssignedStudent, i: number) => (
                <TableRows key={i}>
                  <TableCells
                    value={properCase(item?.fullName || '-')}
                  />
                  <TableCells value={item?.gender || "--"} />
                  <TableCells align={"right"}>
                    <Input
                      id={i}
                      width={150}
                      typeInput={'number'}
                      className='marksInput'
                      value={
                        value[item?.id]?.rollNo ? value[item?.id]?.rollNo : ""
                      }
                      setValue={(e: { target: { value: string } }) => {
                        handleChange(e.target.value, item?.id);
                      }}
                      onkeyDown={(e: any) => {
                        if ((restrictedKeys.includes(e.key))) {
                          if (!regexObject['INTGER_ONLY'].test(e.key)) {
                            e.preventDefault();
                          }
                        } else if (e.key === 'Enter') {
                          focusNextElement();
                        }
                      }}
                    />
                  </TableCells>
                </TableRows>
              ))}
            </TableBody>
          ) : (
            isDataAvailable &&
            tabledata?.length === 0 && (
              <NoRecordsFound
                colspan={3}
                maxHeight={height - 49}
                message={t('assignRollNo.noRecord.text')}
              />
            )
          )}
        </TblContainer>
      </TableDiv>
    </TableContainer>
  );
};

const TableContainer = styled.View<{ width: number | string }>`
  overflow: auto;
  width: 100%;
`;

export default AssignRollNoTable;
