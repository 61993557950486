import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import formJSON from '../../../../../form-json/control-panels/assign-payment-module.json';
import Element from '../../../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { commonBorder, parentBorderBottom } from '../../../../molecules/Table/ExpandTableAtoms';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useI18n } from 'src/i18n/hooks';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { colors } from 'src/styles/theme/styles';
import Check from 'src/components/atoms/CheckBox/index.web';
import { getFilteredModules, getMerchantOptions } from 'src/components/services';
import { sortModuleData } from 'src/utils/utility';

const columns = [
  { label: 'select.label' },
  { label: '' },
  { label: 'moduleGroupName.label' },
  { label: 'amount.label' },
];

export default function AssignModuleForm(props: any) {
  const {
    errors,
    control,
    isAssignModuleEditModal,
    editAssignModuleData,
    reset,
    setModuleIds,
    moduleIds,
    sortModuleData,
    setValue,
  } = props;
  const [elements, setElements] = useState<any>({});
  const [searchData, setSearchData] = useState('');
  const { fields }: any = elements ?? {};
  const { theme } = useThemeSystem();
  const [trow, setT] = useState(false);
  const [current, setCurrent] = useState(null);
  const { t } = useI18n();
  const [moduleList, setModuleList] = useState([]);
  const [currentModules, setCurrentModules] = useState([]);
  const [moduleData, setModuleData] = useState([]);

  useEffect(() => {
    loadModuleData();
    loadAccountNames();
  }, []);

  useEffect(() => {
    if (moduleData) {
      loadModuleList();
      if (isAssignModuleEditModal) LoadEditForm();
      setElements(formJSON[0]);
    }
  }, [moduleData]);

  async function loadModuleData() {
    const modulesResponse = await getFilteredModules({ limit: 0, skip: 0 });
    if (modulesResponse?.options) {
      setModuleData(sortModuleData(modulesResponse.options));
    } else {
      setModuleData([]);
    }
  }

  function loadModuleList() {
    let modules = [] as any;
    moduleData?.forEach((module: any) => {
      if (module.isGroup === false || (module.isGroup === true && module.components !== null)) {
        modules.push(module);
      }
    })
    setModuleList(modules);
  }

  async function loadAccountNames() {
    const merchOptions = await getMerchantOptions();
    const formToUpdate = { ...formJSON[0] };
    formToUpdate.fields[1].option = merchOptions?.data;
    setElements(formToUpdate);
  }

  function LoadEditForm() {
    setValue('composition_name', editAssignModuleData?.compositionName);
    if (editAssignModuleData?.bankDetails) {
      setValue('account_name', editAssignModuleData?.bankDetails);
    }
    let modules = [] as any;
    moduleData?.forEach((item: any) => {
      editAssignModuleData?.feeBooks?.forEach((feeBookData: any) => {
        if (item?.id === feeBookData?.feeId) {
          modules.push({
            ...item,
            orderNo: feeBookData?.orderNo,
          })
        }
      })
    });

    modules = sortModuleData(modules);
    setModuleIds(modules);
    setCurrentModules(modules);
  }

  const handleChecked = (item: any) => {
    const currentIds = [...moduleIds];
    const index = currentIds.findIndex((i: any) => i?.id === item?.id);
    if (index > -1) {
      currentIds.splice(index, 1);
    } else {
      currentIds.push(item);
    }
    setModuleIds(currentIds);
  };

  const nestedRow = (data: any, trow: boolean, current: number, i: number) => {
    return data?.map((item: any, index: number) => (
      <tr
        style={{
          color: theme?.table?.color,
        }}>
        <td
          style={{
            ...parentBorderBottom(trow, current, i, index, data),
            color: theme?.table?.color,
          }}
          className="table-data"
        />
        <td
          style={{
            ...parentBorderBottom(trow, current, i, index, data),
            color: theme?.table?.color,
            width: '0.5%',
          }}
          className="table-data"></td>
        <td
          style={{
            ...parentBorderBottom(trow, current, i, index, data),
            color: theme?.table?.color,
            width: '65%',
            textAlign: 'left',
          }}
          className="table-data">
          {item.name}
        </td>
        <td
          style={{
            ...parentBorderBottom(trow, current, i, index, data),
            color: colors.secondaryText,
            width: '20%',
          }}
          className="table-data">
          {`\u20B9 ${item?.amount}`}
        </td>
      </tr>
    ));
  };

  async function searchModuleOnChange(queryVariable: any) {
    setSearchData(queryVariable);

    if (queryVariable.length > 0) {
      let param = {
        limit: 0,
        skip: 0,
        filter: { name: { regex: queryVariable } },
      };

      let modulesResponse = await getFilteredModules(param);
      setModuleList(modulesResponse?.options);
    } else {
      setModuleList(moduleData);
    }
  }

  return (
    <>
      <Container>
        <View style={{ maxHeight: 788 }}>
          {fields ? (
            fields.map((field: any, i: number) => (
              <View key={i}>
                <Element
                  key={`assignModule${i}`}
                  field={field}
                  control={control}
                  errors={errors}
                  searchData={searchData}
                  // inputWidth={350}
                  // dropdownWidth={350}
                  searchBoxOnChange={searchModuleOnChange}
                />
              </View>
            ))
          ) : (
            <SpinnerView>
              <LoaderSpinner />
            </SpinnerView>
          )}
          
          <TableWrapper>
            <table className="tb" style={{ backgroundColor: theme?.table?.backgroundColor }}>
              <tr
                style={{
                  backgroundColor: theme?.table?.header?.backgroundColor,
                  top: 0,
                  zIndex: 1,
                  position: 'sticky',
                }}>
                {columns.map((e, index) => (
                  <th
                    style={{
                      color: theme?.table?.header?.color,
                      paddingLeft: index === 0 ? 12 : 8,
                    }}>
                    {t(e.label).toUpperCase()}
                  </th>
                ))}
              </tr>
              <tbody style={{ maxHeight: '300px' }}>
                {moduleList?.map((d: any, i: number) => (
                  <React.Fragment key={`emp-table-list-${i}`}>
                    <tr
                      style={{
                        backgroundColor: trow && current === i ? '#EAF2FA' : 'transparent',
                        ...commonBorder(trow, current, i),
                        width: '5%',
                        zIndex: -1,
                      }}>
                      <td
                        style={{
                          color: theme?.table?.color,
                          paddingLeft: 22,
                        }}>
                        <Check
                          id={d?.id}
                          width={100}
                          name="moduleCheck[]"
                          checked={moduleIds.some((i: any) => i?.id === d?.id)}
                          onChange={() => handleChecked(d)}
                          index={i}
                          paddingNone={true}
                          disabled={Boolean(isAssignModuleEditModal && currentModules.some((i: any) => i?.id === d?.id))}
                        />
                      </td>
                      {d?.isGroup || d?.components?.length > 1 ? (
                        <td style={{ width: '0.5%', paddingLeft: 0 }}>
                          {trow && current === i ? (
                            <FontAwesomeIcon
                              icon={faCaretUp}
                              onClick={() => {
                                setT(false);
                                setCurrent(null);
                              }}
                              color={colors.secondaryText}
                              style={{ cursor: 'pointer' }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              onClick={() => {
                                setT(true);
                                setCurrent(i);
                              }}
                              color={colors.secondaryText}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </td>
                      ) : (
                        <td />
                      )}
                      <td style={{ color: theme?.table?.color, width: '65%' }}>{d?.name}</td>
                      <td
                        style={{
                          color: theme?.table?.color,
                          width: '20%',
                        }}>{`\u20B9 ${d?.amount}`}</td>
                    </tr>
                    {trow &&
                      current === i &&
                      (d?.isGroup || (d?.components && d?.components?.length > 1)) && (
                        <>{nestedRow(d?.components, trow, current, i)}</>
                      )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </TableWrapper>
        </View>
      </Container>
    </>
  );
}

const Container = styled.View`
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
`;

const SpinnerView = styled.View`
  width: 750;
  height: 547;
  justify-content: center;
  align-items: center;
`;

const TableWrapper = styled.View`
  height: 350px;
  margin-top: 6;
  overflow-y: auto;
`;
