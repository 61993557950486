import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { TableBody, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TouchableOpacity } from 'react-native';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import Search from 'src/components/atoms/SearchBar/index.web';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import ReservationGroupForm from 'src/components/molecules/Admission/ReservationGroupForm';
import {
  NoRecordsFound,
  TableActions,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { createSearchFilter, debounce } from 'src/components/services';
import { getCommonNameSearchFilter } from 'src/components/services/filters';
import { AUTOCOMPLETE_MODULE, ERROR, limit, SUCCESS } from 'src/constant';
import { height } from 'src/constant/device';
import { ADD_SUCCESS, UPDATE_SUCCESS } from 'src/constant/message';
import { useFilter } from 'src/contexts/filter-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'src/routes/routing.web';
import styled from 'styled-components/native';
import {
  useGetReservations,
  useCreateReservation,
} from '../../../graphql/admission/reservation-group';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';

const headCells1 = [
  {
    id: 'reservation_group_name',
    label: 'reservationGroupName.label',
    align: 'left',
    disableSorting: true,
  },
  {
    id: 'action',
    label: 'action.label',
    align: 'right',
    disableSorting: true,
  },
];

export default function ReservationGroups() {
  const { filters, setFilter } = useFilter();
  const { t } = useI18n();
  const { setHeading } = useHeaderTitle();
  const { theme } = useThemeSystem();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  let searchValue = '' as string;
  let row;
  const { page = 1, dataLimit = limit }: any = useParams();
  const rowDefaultLimit = parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState(page);
  const [rowLimit, setRowLimit] = useState<number>(rowDefaultLimit);

  if (filters && filters?.reservation_group?.on) {
    searchValue = filters?.reservation_group?.search;
  }

  const [searching, setSearchingGroup] = useState(searchValue);
  const [headCells, setHeadCells] = useState(headCells1);
  const [canClick, setCanClick] = useState(true);
  const [modalState, handleModal] = useState(false);
  const [editGroupData, setGroupData] = useState<any>({});
  const [isEditModal, setIsEditModal] = useState(false);
  const [reservationIntake, setReservationIntake] = useState<any>([]);
  let { setAlertDetails } = useAlertSystem();
  const {
    getReservations,
    reservationsData,
    reservationsError,
    reservationsLoading,
    refetchReservations,
  } = useGetReservations();
  const { createReservation, createReservationData, createReservationError } =
    useCreateReservation();

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    setSearchingGroup(searchValue);
  }, [searchValue]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, rowLimit]);

  function setTitle() {
    setHeading([
      {
        text: t('reservationGroups.label'),
        url: '',
      },
    ]);
  }

  function handleFetch() {
    const filters = getCommonNameSearchFilter(searchValue);
    let reservationFilter = createSearchFilter(rowLimit, (currentPage - 1) * rowLimit, filters);
    getReservations({ variables: reservationFilter });
  }

  row = reservationsData?.reservations?.data;

  const [totalCount, setTotalCount] = useState<number | null>(
    reservationsData?.reservations?.totalCount ?? null,
  );

  useEffect(() => {
    const count = reservationsData?.reservations?.totalCount;
    if (count) {
      setTotalCount(count);
    }
  }, [reservationsData?.reservations?.totalCount]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    null,
    totalCount,
    currentPage,
  );

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.RESERVATION_GROUP]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchGroup(group: string) {
    let isClear = group?.length === 0;
    persistSearch(!isClear, group);
  }

  const delayedQuery = useCallback(
    debounce(q => searchGroup(q), 500),
    [],
  );

  const handleSearch = (group: string) => {
    setSearchingGroup(group);
    delayedQuery(group);
  };

  const closeModal = () => {
    handleModal(false);
    resetForm();
  };

  function resetForm() {
    setValue('group_name', '');
    clearErrors();
  }

  const editGroupModal = (selectedGroup: any) => {
    setGroupData(selectedGroup);
    hideAlert();
    setIsEditModal(true);
    handleModal(true);
  };

  const addGroupModal = () => {
    setIsEditModal(false);
    hideAlert();
    handleModal(true);
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  function checkCategoryIntake() {
    if (reservationIntake.length > 0) {
      const total = reservationIntake.reduce((total, item) => total + item.reservePercentage, 0);
      if (total === 100) {
        return true;
      }
      return false;
    }
    return false;
  }

  const handleAddEditReservation = async (ReservationData: any) => {
    setCanClick(false);
    try {
      if (reservationIntake.length > 1) {
        if (checkCategoryIntake()) {
          const reservationObject = {
            name: ReservationData.group_name,
            reservations: reservationIntake,
          };
          if (isEditModal) reservationObject['id'] = editGroupData?.id;

          const response = await createReservation({
            variables: { payload: reservationObject },
          });
          if (response.data) {
            closeModal();
            if (refetchReservations) refetchReservations();
            setAlertDetails({
              message: isEditModal
                ? UPDATE_SUCCESS.RSERVATION_GROUP
                : ADD_SUCCESS.RESERVATION_GROUP,
              level: SUCCESS,
            });
          }
          setCanClick(true);
        } else throw new Error(t('error.categoryIntake.text'));
      } else {
        throw new Error(t('error.categoryIntake.required'));
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
      setCanClick(true);
    }
  };

  return (
    <LoadContentWrapper>
      <FlexRowView>
        <SearchWrapper>
          <Search
            id="groupsSearch"
            handleChange={handleSearch}
            value={searching}
            label={t('searchGroups.text')}
          />
        </SearchWrapper>

        <AddCriteriaWrapper>
          <SecondaryBtn label={t('addReservationGroup.label')} onPress={addGroupModal} lines={1} />
        </AddCriteriaWrapper>
      </FlexRowView>
      <TblContainer height={'100%'}>
        <TblHead setHeadCells={setHeadCells} />
        <>
          {!reservationsLoading ? (
            <>
              {recordsAfterPagingAndSorting()?.length > 0 ? (
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                    <TableRows key={i}>
                      <TableCells value={item?.name} />

                      <TableActions align="right">
                        <TableActionView>
                          <Tooltip title="Edit Group">
                            <TouchableOpacity onPress={() => editGroupModal(item)}>
                              <FontAwesomeIcon
                                icon={faPencil}
                                color={theme?.table?.editIconColor}
                              />
                            </TouchableOpacity>
                          </Tooltip>
                        </TableActionView>
                      </TableActions>
                    </TableRows>
                  ))}
                </TableBody>
              ) : (
                <NoRecordsFound colspan={7} maxHeight={0.6 * height} />
              )}
            </>
          ) : (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          )}
        </>
      </TblContainer>
      <Pagination
        pathName={`admission/reservation-groups`}
        total={totalCount}
        page={currentPage}
        setCurrentPage={setCurrentPage}
        rowLimit={rowLimit}
        setRowLimit={setRowLimit}
        hidePagination={!!(totalCount && totalCount <= rowLimit)}
      />

      <NormalModal
        isSubmitting={!canClick}
        setModalVisible={closeModal}
        onAlert={null}
        modalVisible={modalState}
        Headerpopup={isEditModal ? t('editReservationGroup.label') : t('addReservationGroup.label')}
        handleSave={handleSubmit(handleAddEditReservation)}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={isEditModal ? 'save.label' : 'add.label'}
        height={510}>
        <ReservationGroupForm
          closeModal={closeModal}
          control={control}
          errors={errors}
          getValues={getValues}
          setValue={setValue}
          isEditModal={isEditModal}
          editGroupData={editGroupData}
          reset={reset}
          inputTableData={reservationIntake}
          setInputTableData={setReservationIntake}
        />
      </NormalModal>
    </LoadContentWrapper>
  );
}

const FlexRowView = styled.View`
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 10;
`;

const SearchWrapper = styled.View`
  align-items: flex-start;
  max-width: 280px;
  margin-right: 8px;
`;

const AddCriteriaWrapper = styled.View`
  flex: 1;
  align-items: flex-end;
  max-width: 140px;
  min-width: 112px;
`;

const TableActionView = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SpinnerWrapper = styled.View`
  position: absolute;
  top: 50%;
  left: 50%;
`;
