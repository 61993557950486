import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import { useAddAnnouncementsMutation } from 'src/graphql/communication/news-notices';
import { useI18n } from 'src/i18n/hooks';
import { useLocation, useHistory, useParams } from 'src/routes/routing';
import EventForm from './EventForm';
import EventModalContent from './EventModalContent';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { UPDATE_SUCCESS } from 'src/constant/message';
import { ANNOUNCEMENT_TYPE, EMPLOYEE_TYPES, ERROR, SUCCESS } from 'src/constant';
import { filterDoc } from 'src/components/services';
import { userInformation } from 'src/utils/manageState';
import { isWeb } from 'src/constant/device';
import { showMessage } from '../NotificationWrapper';
import { validateTotalSizeOfAttachment } from 'src/utils/utility';
import { AttachmentType } from '../FileUpload/type';
import { SD } from 'src/constant/standardDimensions';

export default function EventRoot(props: any) {
  const { type: eventType } = props;
  let location = useLocation();
  const { eventId } = useParams();
  let pathname = location.pathname;
  const lastSegment = pathname.split('/').pop();
  let isEditModal = lastSegment === 'edit';
  const { t } = useI18n();
  const currentUserObject = userInformation();
  const { userType, userDetail } = currentUserObject;
  const [imageAttachments, setImageAttachments] = useState<AttachmentType[]>([]);
  const [fileAttachments, setFileAttachments] = useState<AttachmentType[]>([]);
  const [removeImages, setRemovedImages] = useState<any>([]);
  const [canClick, setCanClick] = useState<boolean>(true);
  const history = useHistory();
  const { mutate: createAnnoucemnt } = useAddAnnouncementsMutation('EVENT');
  let { alertState, setAlertDetails } = useAlertSystem();
  const isInstituteAdmin = userDetail?.type?.includes(EMPLOYEE_TYPES.INSTITUTE_ADMIN);
  let savingLabel = isInstituteAdmin ? 'edit.label' : '';

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  function openEditModal() {
    history.push(`/events/${eventType}/${eventId}/edit`);
  }

  useEffect(() => {
    const attachments = [
      ...(imageAttachments?.length > 0 ? imageAttachments : []),
      ...(fileAttachments?.length > 0 ? fileAttachments : []),
    ]
    const allowed = validateTotalSizeOfAttachment(attachments);
    if (!allowed) {
      setCanClick(false);
      showError(t("err.totalFilesize.exceeds"));
    } else {
      setCanClick(true);
    }
  }, [imageAttachments, fileAttachments]);

  async function handleUpdateEvent(formData: any) {
    if (canClick) {
      setCanClick(false);
      const imageAttachmentsDoc = filterDoc(imageAttachments);
      const fileAttachmentsDoc = filterDoc(fileAttachments);

      let eventObject = {
        id: eventId,
        type: ANNOUNCEMENT_TYPE[2],
        body: formData?.body,
        attachments: [
          ...(imageAttachmentsDoc && imageAttachmentsDoc?.length > 0 ? imageAttachmentsDoc : []),
          ...(fileAttachmentsDoc && fileAttachmentsDoc?.length > 0 ? fileAttachmentsDoc : []),
        ],
        removeAttachments: removeImages,
      };

      try {
        const annoucementResponse = await createAnnoucemnt({
          variables: { payload: eventObject },
        });
        if (annoucementResponse.data) {
          let data = annoucementResponse.data;
          if (data.createAnnouncement) {
            setAlertDetails({ message: UPDATE_SUCCESS.EVENT, level: SUCCESS });
            history.push(`/events/${eventType}/${eventId}/detail`);
          }
        }
      } catch (error) {
        setCanClick(true);
        showError(error.message);
      }
    }
  }

  function showError(message: string) {
    if (isWeb) {
      setAlertDetails({ message: message, level: ERROR });
    } else {
      showMessage({
        message: message,
        type: 'danger',
        position: 'top',
        icon: 'danger',
      });
    }
  }

  return (
    <>
      <NormalModal
        isSubmitting={!canClick}
        infoModal={true}
        setModalVisible={() => { }}
        modalVisible={true}
        handleSave={!isEditModal ? openEditModal : handleSubmit(handleUpdateEvent)}
        Headerpopup={t(!isEditModal ? 'event.label' : 'editEvent.text')}
        addEditButtonLabel={!isEditModal ? savingLabel : 'save.label'}
        width={SD.primaryInfoModal.width}
        height={SD.primaryInfoModal.height}
        maxWidth={"lg"}>
        {!isEditModal ? (
          <EventModalContent {...props} />
        ) : (
          <EventForm
            control={control}
            errors={errors}
            setValue={setValue}
            imageAttachments={imageAttachments}
            fileAttachments={fileAttachments}
            setImageAttachments={setImageAttachments}
            setFileAttachments={setFileAttachments}
            eventData={{}}
            isEditModal={isEditModal}
            {...props}
            isDynamic={true}
            removeImages={removeImages}
            setRemovedImages={setRemovedImages}
          />
        )}
      </NormalModal>
    </>
  );
}
