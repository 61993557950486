import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import {properCase} from 'src/utils/utility';

export default function MainHeaderTitle(props) {
  const { value = null as any, color = colors.secondaryText, fontWeight, lineHeight } = props;

  return <StyledMainHeader style={[{ color: color, fontWeight, lineHeight }]} allowFontScaling={false}>{properCase(value)}</StyledMainHeader>;
}

MainHeaderTitle.propTypes = {
  value: PropTypes.string.isRequired,
  color: PropTypes.string,
};

const StyledMainHeader = styled.Text`
  font-size: ${({theme}) => theme.rem(2.8)};
  line-height: ${({theme}) => theme.rem(2.4)};
  font-family: ${fonts.regular};
`;


