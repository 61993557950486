import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../../../../styles/theme/styles';
import { useI18n } from 'src/i18n/hooks';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import StudentAndClassAttendanceHistory from 'src/components/molecules/Attendance/History/StudentAndClassAttendanceHistory';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import AttendanceHistoryForm from 'src/components/molecules/Attendance/AttendanceHistoryForm';
import { useForm } from 'react-hook-form';
import AttendanceNotConfigured from '../AttendanceNotConfigured';
import MediumText from 'src/components/atoms/Text/MediumText';
import HeaderThree from 'src/components/atoms/Text/HeaderThree';
import { attendanceSlots } from 'src/graphql/attendance';
import { getBatchQuery } from 'src/graphql/academics/batch';
import DateTimePicker from 'src/components/atoms/DateTime/DateTimePicker.web';
import { DropdownOptionsType } from 'src/types';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  parse,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { CircularProgressbarStyles } from 'react-circular-progressbar/dist/types';
import {
  AttendanceBatchDetails,
  SelectedDatesState,
  SlotObj,
} from 'src/components/molecules/Attendance/AttendanceTypes';
import HeaderFour from 'src/components/atoms/Text/HeaderFour';
import {
  audienceTypeOptions,
  durationTypeOptions,
} from 'src/components/molecules/Attendance/History/AttendanceHelpers';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import { downloadFileRestAPI } from 'src/utils/utility';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { ERROR, SUCCESS } from 'src/constant';
import moment from "moment"

const StudentHistoryChild = () => {
  const { t } = useI18n();
  const [step, setStep] = useState<number>(0);
  const { setAlertDetails } = useAlertSystem();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const currentDate = new Date();
  const todaysDate = format(currentDate, DT.DATE_FORMAT_SLASH);

  const attendanceType = [
    { value: 0, label: t('fullDay.label') },
    { value: 1, label: t('slots.label') },
    { value: 2, label: t('subjects.label') },
  ];

  const [selectedAudienceType, setselectedAudienceType] = useState<DropdownOptionsType | null>(
    null,
  );
  const [selectedDurationType, setselectedDurationType] = useState<DropdownOptionsType | null>(
    null,
  );
  const [selectedAttendenceType, setSelectedAttendenceType] = useState<DropdownOptionsType | null>(
    null,
  );
  const [audienceType, setAudienceType] = useState<number>();

  const [dates, setDates] = useState<SelectedDatesState>({
    startDate: format(startOfMonth(currentDate), DT.DATE_FORMAT_SLASH),
    endDate: format(endOfMonth(currentDate), DT.DATE_FORMAT_SLASH),
    currentDate: todaysDate,
  });
  const [dateString, setdateString] = useState<string>(format(currentDate, DT.MONTH_NAME_YEAR));
  const [selectedSlot, setSelectedSlot] = useState<SlotObj | null>(null);
  const [batches, setBatches] = useState<any[]>();
  const [selectedBatch, setselectedBatch] = useState<AttendanceBatchDetails | null>(null);
  const [selectedDivision, setselectedDivision] = useState<string>('');
  const [selectedStudentInfo, setselectedStudentInfo] = useState<DropdownOptionsType | null>(null);
  const [isAttendanceSettingConfigured, setisAttendanceSettingConfigured] = useState<boolean>(true);
  const [totalPresents, setTotalPresents] = useState<number>(0);
  const [totalAttendance, setTotalAttendance] = useState<number>(0);
  const [editFilter, seteditFilter] = useState<boolean>(false);

  const { data: slotsData, loading: slotsDataloading } = useQuery(attendanceSlots, {
    fetchPolicy: 'network-only',
  });

  const { data: batchesData, loading: batchesloading } = useQuery(getBatchQuery, {
    fetchPolicy: 'network-only',
    variables: { limit: 0 },
  });

  function loadBatches() {
    if (batchesData.batches.data.length) {
      setBatches(batchesData.batches.data);
    }
  }

  function setDateForWeekOrMonth(
    presentDayDate: string,
    type: 'month' | 'week' | 'day' | 'yearly' | 'last-6-months' | 'last-year',
    updateTodaysDate: boolean,
  ) {
    const { startdate, enddate } = createStartEndDate(presentDayDate, type);

    setDates(prev =>
      updateTodaysDate
        ? {
            ...prev,
            startDate: startdate,
            endDate: enddate,
            currentDate: presentDayDate,
          }
        : {
            ...prev,
            startDate: startdate,
            endDate: enddate,
          },
    );
    if(type == 'month'){
      setdateString(format(parse(startdate, DT.DATE_FORMAT_SLASH, currentDate), DT.MONTH_NAME_YEAR))
    }
    else if(type == "week"){
      console.log(getFormattedDateString(parse(startdate, DT.DATE_FORMAT_SLASH, currentDate),parse(enddate, DT.DATE_FORMAT_SLASH, currentDate)))
      setdateString(getFormattedDateString(parse(startdate, DT.DATE_FORMAT_SLASH, currentDate),parse(enddate, DT.DATE_FORMAT_SLASH, currentDate)))
    }
    else if(type == "day"){
      setdateString(format(parse(startdate, DT.DATE_FORMAT_SLASH, currentDate),DT.DATE_ABBREVIATED_MONTH_YEAR))
    }
    else if(type == "yearly"){
      const start = moment().startOf('year').format('DD MMM, YYYY');
      const end = moment().endOf('year').format('DD MMM, YYYY');
      setdateString(`${start} - ${end}`)
    }
    else if(type == "last-year"){
      const start = moment().subtract(1, 'year').startOf('year').format('DD MMM, YYYY');
      const end = moment().subtract(1, 'year').endOf('year').format('DD MMM, YYYY');
      console.log(`${start} - ${end}`)
      setdateString(`${start} - ${end}`)
    }
    else if(type == "last-6-months"){
      const end = moment().subtract(1, 'month').endOf('month').format('DD MMM, YYYY');
      const start = moment(end, 'DD MMM, YYYY').subtract(5, 'months').startOf('month').format('DD MMM, YYYY');
      setdateString(`${start} - ${end}`)
    }
    else{
      setdateString(format(parse(startdate, DT.DATE_FORMAT_SLASH, currentDate),DT.DATE_ABBREVIATED_MONTH_YEAR))
    }
  }

  function createStartEndDate(presentDayDate, type) {
    const parsedPresentDate = moment(presentDayDate, 'DD/MM/YYYY'); // Adjust the date format as needed
    let startDate, endDate;

    switch (type) {
      case 'day':
        startDate = parsedPresentDate.startOf('day').format('DD/MM/YYYY');
        endDate = parsedPresentDate.endOf('day').format('DD/MM/YYYY');
        break;

      case 'month':
        startDate = parsedPresentDate.startOf('month').format('DD/MM/YYYY');
        endDate = parsedPresentDate.endOf('month').format('DD/MM/YYYY');
        break;

      case 'week':
        startDate = parsedPresentDate.startOf('week').format('DD/MM/YYYY');
        endDate = parsedPresentDate.endOf('week').format('DD/MM/YYYY');
        break;

      case 'yearly':
        startDate = parsedPresentDate.startOf('year').format('DD/MM/YYYY');
        endDate = parsedPresentDate.endOf('year').format('DD/MM/YYYY');
        break;

      case 'last-6-months':
        endDate = parsedPresentDate.clone().subtract(1, 'month').endOf('month').format('DD/MM/YYYY');
        startDate = parsedPresentDate.clone().subtract(6, 'months').startOf('month').format('DD/MM/YYYY');
        break;

      case 'last-year':
        startDate = parsedPresentDate.clone().subtract(1, 'year').startOf('year').format('DD/MM/YYYY');
        endDate = parsedPresentDate.clone().subtract(1, 'year').endOf('year').format('DD/MM/YYYY');
        break;

      default:
        startDate = parsedPresentDate.startOf('day').format('DD/MM/YYYY');
        endDate = parsedPresentDate.endOf('day').format('DD/MM/YYYY');
    }
    return { 
      startdate: startDate, 
      enddate: endDate
    };
  }

  function handleFilterApply(payload: any) {
    if (payload.duration) {
      let durationType = "day"
      if(payload.duration.value === 0){
        durationType  = "week"
      }
      else if(payload.duration.value === 1){
        durationType  = "month"
      }
      else if(payload.duration.value === 3){
        durationType  = "last-6-months"
      }
      else if(payload.duration.value === 4){
        durationType  = "yearly"
      }
      else if(payload.duration.value === 5){
        durationType  = "last-year"
      }
      const type = durationType;
      setDateForWeekOrMonth(dates.currentDate,type,false);
      setselectedDurationType(durationTypeOptions[payload.duration.value]);
    }
    if (payload.students) {
      setselectedStudentInfo(payload.students);
    }
    setselectedAudienceType(audienceTypeOptions[payload.audienceType.value]);
    seteditFilter(false);
  }

  function showSlots() {
    const weeklyOrMonthly = selectedStudentInfo
      ? t('monthly.label')
      : !selectedDurationType || selectedDurationType?.value === 1
        ? t('monthly.label')
        : selectedDurationType?.value === 0
          ? t('weekly.label')
          : t('daily.label');

    let rightPartOftheString = null;
    if (!selectedAttendenceType || selectedDurationType?.value === 2) {
      rightPartOftheString = '';
    } else if (!selectedAttendenceType.value) {
      rightPartOftheString = t('fullDay.label');
    } else if (selectedAttendenceType.value === 1) {
      rightPartOftheString = t('slots.label');
    } else {
      rightPartOftheString = t('subjects.label');
    }

    if (!rightPartOftheString) {
      return weeklyOrMonthly;
    }
    return weeklyOrMonthly + ' - ' + rightPartOftheString;
  }

  const dateChangeHandler = (changedDate: string | Date) => {
    // TODO:: Refactor month and week picker callbacks to be similar across the app.
    setSelectedSlot(null);
    const isMonthTypePicker = !selectedDurationType || selectedDurationType.value === 1;
    const isDailyPicker = !selectedDurationType || selectedDurationType.value === 2;

    const newDate =
      isMonthTypePicker || isDailyPicker ? changedDate : changedDate.toString().split(' - ');
    let newStartDate: string;
    let newEndDate: string;

    if (isMonthTypePicker) {
      newStartDate = format(startOfMonth(newDate), DT.DATE_FORMAT_SLASH);
      newEndDate = format(endOfMonth(newDate), DT.DATE_FORMAT_SLASH);
    } else if (isDailyPicker) {
      newStartDate = format(startOfDay(newDate), DT.DATE_FORMAT_SLASH);
      newEndDate = format(endOfDay(newDate), DT.DATE_FORMAT_SLASH);
    } else {
      newStartDate = newDate[0];
      newEndDate = newDate[1];
    }

    setDates(prev => {
      return {
        ...prev,
        startDate: newStartDate,
        endDate: newEndDate,
        currentDate: newStartDate,
      };
    });
    const dtString: string = isMonthTypePicker
      ? format(newDate, DT.MONTH_NAME_YEAR)
      : isDailyPicker
        ? format(
            parse(newStartDate, DT.DATE_FORMAT_SLASH, currentDate),
            DT.DATE_ABBREVIATED_MONTH_YEAR,
          )
        : getFormattedDateString(
            parse(newDate[0], DT.DATE_FORMAT_SLASH, currentDate),
            parse(newDate[1], DT.DATE_FORMAT_SLASH, currentDate),
          );
    setdateString(dtString);
  };

  function getFormattedDateString(startDate: Date, endDate: Date): string {
    return `${format(startDate, DT.DATE_ABBREVIATED_MONTH_YEAR)} - ${format(
      endDate,
      DT.DATE_ABBREVIATED_MONTH_YEAR,
    )}`;
  }

  useEffect(() => {
    if (!slotsDataloading && slotsData) {
      if (!slotsData?.institute?.settings?.attendanceType) {
        setisAttendanceSettingConfigured(false);
      } else {
        if (slotsData?.institute.settings.attendanceType === 'DAY_WISE') {
          setSelectedAttendenceType(attendanceType[0]);
        } else if (slotsData?.institute.settings.attendanceType === 'SLOT_WISE') {
          setSelectedAttendenceType(attendanceType[1]);
        } else {
          setSelectedAttendenceType(attendanceType[2]);
        }
        setisAttendanceSettingConfigured(true);
      }
    }
  }, [slotsDataloading]);

  useEffect(() => {
    if (batchesData && !batchesloading) {
      loadBatches();
    }
  }, [batchesData]);

  useEffect(() => {
    if (!editFilter) {
      if (
        !selectedStudentInfo &&
        selectedDurationType &&
        selectedAudienceType &&
        selectedAttendenceType
      ) {
        setTotalAttendance(1);
        setTotalPresents(0);
        setStep(1);
      }
      if (
        selectedStudentInfo &&
        !selectedDurationType &&
        selectedAudienceType &&
        selectedAttendenceType
      ) {
        setTotalAttendance(1);
        setTotalPresents(0);
        setStep(1);
      }
    }
  }, [selectedDurationType, selectedAudienceType, selectedStudentInfo, editFilter]);

  if (!isAttendanceSettingConfigured) {
    return <AttendanceNotConfigured type={0} topPosition={'30%'} />;
  } else if (!batches || slotsDataloading) {
    return (
      <SpinnerWrapper>
        <LoaderSpinner />
      </SpinnerWrapper>
    );
  }

  function exportReport(url: string, fileName: string, startdate: string, enddate: string) {
    downloadFileRestAPI(url, fileName, err => {
      setAlertDetails({
        message:
          err.message || `Attendance is not recorded from the ${startdate} to ${enddate}`,
        level: ERROR,
      });
    });
    setAlertDetails({ message: t('file-download-start.text'), level: SUCCESS });
  }

  function handleDownloadReport(formData: any) {
    if (formData?.duration) {
      let durationType = "day"
      if(formData.duration.value === 0){
        durationType  = "week"
      }
      else if(formData.duration.value === 1){
        durationType  = "month"
      }
      else if(formData.duration.value === 3){
        durationType  = "last-6-months"
      }
      else if(formData.duration.value === 4){
        durationType  = "yearly"
      }
      else if(formData.duration.value === 5){
        durationType  = "last-year"
      }
      const type = durationType;
      console.log("durationType - ", durationType)
      const { startdate, enddate } = createStartEndDate(dates.currentDate, durationType);

      if (
        startdate &&
        enddate &&
        formData?.batch?.value &&
        formData?.batch?.class?.id &&
        formData?.division?.value
      ) {
        exportReport(
          `attendance/report?batch=${formData.batch.value}&class=${formData.batch.class.id}&division=${formData.division.value}&start=${startdate}&end=${enddate}&slot=DAY&timezone=Asia/Calcutta`,
          'AttendanceReport.xlsx',
          startdate,
          enddate,
        );
      }
    }
  }

  return (
    <>
      {!batches ? (
        <SpinnerWrapper>
          <LoaderSpinner />
        </SpinnerWrapper>
      ) : batches[0].value === '' || !isAttendanceSettingConfigured ? (
        <AttendanceNotConfigured type={0} topPosition={'30%'} />
      ) : step === 0 ? (
        <>
          <HeaderFour
            value={t('attendanceHistory.text')}
            fontFamily={fonts.semibold}
            color={colors.secondaryText}
          />
          <FieldWrapper>
            <AttendanceHistoryForm
              control={control}
              errors={errors}
              reset={reset}
              setValue={setValue}
              setselectedBatch={setselectedBatch}
              selectedBatch={selectedBatch}
              setselectedStudentInfo={setselectedStudentInfo}
              setselectedDivision={setselectedDivision}
              selectedDivision={selectedDivision}
              selectedStudentInfo={selectedStudentInfo}
              selectedDurationType={selectedDurationType}
              setselectedDurationType={setselectedDurationType}
              batches={batches}
              setAudienceType={setAudienceType}
            />
          </FieldWrapper>
          <ButtonWrapper>
            <SecondaryBtn label={t('view.label')} onPress={handleSubmit(handleFilterApply)} />
          </ButtonWrapper>
        </>
      ) : (
        <>
          <ContentWrapper>
            <LeftContent>
              <ProgressBarWrapper>
                <CircularProgressbarWithChildren
                  styles={ComponentProps.ProgressBarStyles}
                  value={(totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) * 100}>
                  <MediumText
                    value={t('present.label')}
                    lineHeight={2.4}
                    fontWeight={'400'}
                    color={colors.primaryColor}
                  />
                  <HeaderThree
                    value={`${(
                      (totalPresents / (totalAttendance > 0 ? totalAttendance : 1)) *
                      100
                    ).toFixed(0)}%`}
                    lineHeight={32}
                    fontWeight={'600'}
                    color={colors.primaryColor}
                  />
                </CircularProgressbarWithChildren>
              </ProgressBarWrapper>

              <AttendanceInformation>
                <AttendanceHeading>{showSlots()}</AttendanceHeading>
                <Attendance1Wrapper>
                  <AttendanceInfo1>
                    {selectedBatch ? selectedBatch.label + ' - ' + selectedDivision : ''}
                  </AttendanceInfo1>
                  {selectedStudentInfo && (
                    <AttendanceInfo1 style={{ marginLeft: 5 }}>
                      {' - ' + selectedStudentInfo.label}
                    </AttendanceInfo1>
                  )}
                </Attendance1Wrapper>

                <Infoparent>
                  <AttendanceInfo2>{dateString}</AttendanceInfo2>
                  <AttendanceInfo2 marginTop="8px">
                    <DateTimePicker
                      standalonePicker={true}
                      disableFuture={true}
                      dateTimeMode={
                        !selectedDurationType || selectedDurationType.value === 1
                          ? 'monthyear'
                          : 'date'
                      }
                      onDateTimeChangeCallback={(selectedDate: Date) => {
                        if (
                          !selectedDurationType ||
                          selectedDurationType.value === 1 ||
                          selectedDurationType.value === 2
                        ) {
                          // month and day picker
                          dateChangeHandler(selectedDate);
                        } else {
                          // Week Picker
                          const weekRange = `${format(
                            startOfWeek(selectedDate),
                            DT.DATE_FORMAT_SLASH,
                          )} - ${format(endOfWeek(selectedDate), DT.DATE_FORMAT_SLASH)}`;
                          dateChangeHandler(weekRange);
                        }
                      }}
                    />
                  </AttendanceInfo2>
                </Infoparent>
              </AttendanceInformation>
            </LeftContent>
            <ReginContent>
              <OutlineButton
                label={t('editFilter.text')}
                onPress={() => {
                  setDateForWeekOrMonth(todaysDate, 'month', true);
                  setdateString(format(currentDate, DT.MONTH_NAME_YEAR));
                  seteditFilter(true);
                  setStep(0);
                  setSelectedSlot(null);
                }}
              />
              {Boolean(audienceType === 1 && selectedAttendenceType?.value === 0) && (
                <SecondaryBtn
                  label={t('download.label')}
                  onPress={handleSubmit(handleDownloadReport)}
                />
              )}
            </ReginContent>
          </ContentWrapper>
          {selectedDivision.length <= 0 ||
          !selectedBatch ||
          !selectedAudienceType ||
          !selectedAttendenceType ? (
            <SpinnerWrapper>
              <LoaderSpinner />
            </SpinnerWrapper>
          ) : (
            <StudentAndClassAttendanceHistory
              selectedBatch={selectedBatch}
              selectedDivision={selectedDivision}
              selectedAudienceType={selectedAudienceType}
              selectedAttendenceType={selectedAttendenceType}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              selectedDurationType={selectedDurationType}
              selectedStudentInfo={selectedStudentInfo}
              dates={dates}
              setTotalPresents={setTotalPresents}
              setTotalAttendance={setTotalAttendance}
              setDates={setDates}
            />
          )}
        </>
      )}
    </>
  );
};

export default StudentHistoryChild;

type ComponentPropsType = {
  ProgressBarStyles: CircularProgressbarStyles;
};

const ComponentProps: ComponentPropsType = {
  ProgressBarStyles: buildStyles({
    rotation: 0,
    strokeLinecap: 'butt',
    textSize: '16px',
    pathTransitionDuration: 0.5,
    pathColor: colors.primary,
    textColor: colors.primary,
    trailColor: colors.white,
    backgroundColor: colors.progressBarBackground,
  }),
};

const Attendance1Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FieldWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  z-index: 0;
  .MuiTextField-root {
    max-width: 350px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;
`;

const AttendanceHeading = styled.div`
  font-size: 16px;
  font-family: ${fonts.semibold};
  color: ${colors.primaryText};
  margin-bottom: 10px;
`;

const AttendanceInfo1 = styled.div`
  font-size: 16px;
  font-family: ${fonts.semibold};
  color: ${colors.secondaryText};
  margin-bottom: 10px;
`;

const AttendanceInfo2 = styled.div<{ marginTop?: string }>`
  font-size: 15px;
  font-family: ${fonts.semibold};
  color: ${colors.tertiaryText};
  margin-right: 15px;
  margin-top: ${({ marginTop }) => marginTop};
`;

const AttendanceInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;
const ReginContent = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 16px;
`;

const ProgressBarWrapper = styled.div`
  width: 120px;
`;

const Infoparent = styled.div`
  display: flex;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 25px;
`;
