import React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from 'src/styles/theme/styles';
import SmallTextAtom from '../Text/SmallTextAtom';

interface propType {
  value: string;
  color?: string;
}

export default function Badge(props: propType) {
  const { value, color = colors.primaryColor } = props;
  return (
    <View style={[styles(color).container]}>
      <SmallTextAtom value={value || 'Default'} color={color} />
    </View>
  );
}

const styles = (color: string) => StyleSheet.create({
  container: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: color,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
});
