import React, { useState, useEffect } from 'react';
import { TableBody } from '@mui/material';
import { TouchableOpacity } from 'react-native';
import {
  useQueryKeyValue,
  getKeyValuesQuery,
  useMutateKeyValue,
  updateKeyValueMutation,
} from '../../../graphql/localization/key-values';
import OutlineButton from 'src/components/atoms/Button/OutlineButtton';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import Search from '../../atoms/SearchBar/index.web';
import LoadContentWrapper from 'src/components/atoms/Wrapper/LoadContent';
import {
  TableActions,
  TableCells,
  TableDiv,
  TableRows,
} from 'src/components/molecules/Table/TableAtom';
import useTable from 'src/components/molecules/Table/UseTable';
import { AUTOCOMPLETE_MODULE, ERROR, limit, SUCCESS, USER_TYPE } from 'src/constant';
import { useFilter } from 'src/contexts/filter-context';
import { useI18n } from 'src/i18n/hooks';
import { useParams } from 'src/routes/routing.web';
import { useThemeSystem } from 'src/contexts/theme-context';
import { useHeaderTitle } from 'src/contexts/header-context';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import NormalModal from 'src/components/atoms/Modals/Normal/index.web';
import { UPDATE_SUCCESS } from 'src/constant/message';
import { validation } from 'src/constant/validation';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import Input from 'src/components/atoms/Input/input.web';
import { colors } from 'src/styles/theme/styles';
import { getLanguageQuery, useQueryLanguage } from 'src/graphql/localization/language';
import { faDownload, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { createSearchFilter, debounce } from 'src/components/services';
import { getKeyValueFilter } from 'src/components/services/filters';
import Pagination from 'src/components/atoms/Pagination/Paginations.web';
import { useAlertSystem } from 'src/contexts/web-alert-context';
import { userInformation } from 'src/utils/manageState';
import styled from 'styled-components';

const maxHeight = 440;

interface HeadCellType {
  id: string;
  label: string;
  align: string;
  disableSorting: boolean;
}

interface parameterType {
  page?: string;
  dataLimit?: string;
}

export default function KeyValues() {
  const { t } = useI18n();
  const { theme } = useThemeSystem();
  const currentUserObject = userInformation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  let row;
  let searchValue = '' as string;

  const { page = 1, dataLimit = limit } = useParams<parameterType>();
  const rowDefaultLimit =  parseInt(dataLimit) ?? limit;
  const [currentPage, setCurrentPage] = useState<number>(parseInt(page));
  const [rowLimit, setRowLimit] = useState(rowDefaultLimit);
  
  const { filters, setFilter } = useFilter();
  const { setHeading } = useHeaderTitle();
  const params = useParams();
  const languageId = params?.language;

  const [modalState, handleModal] = useState(false);
  let { setAlertDetails } = useAlertSystem();
  const [isEditModal, setIsEditModal] = useState(false);
  const [editKeyValueData, setKeyValueData] = useState<any>({});
  const { mutate: updateKeyValue, error: updateKeyValueError } =
    useMutateKeyValue(updateKeyValueMutation);
  const { query: getLanguage, data: languageData } = useQueryLanguage(getLanguageQuery);
  const { query: getKeyValue, data: keyValueData, loading } = useQueryKeyValue(getKeyValuesQuery);
  row = keyValueData?.localizationKeyValues?.data;

  if (filters && filters?.key_value?.on) {
    searchValue = filters?.key_value?.search;
  }

  const [totalCount, setTotalCount] = useState(keyValueData?.localizationKeyValues?.totalCount ?? null);
  
  useEffect(() => {
    const count = keyValueData?.localizationKeyValues?.totalCount;
    if(count){
      setTotalCount(count);
    }
  }, [keyValueData?.localizationKeyValues?.totalCount]);

  useEffect(() => {
    setCurrentPage(parseInt(page));
  }, [page]);

  useEffect(() => {
    setRowLimit(rowDefaultLimit);
  }, [rowDefaultLimit]);

  useEffect(() => {
    getLanguage({ variables: { id: languageId.toString() } });
    setTimeout(() => {
      setTitle();
    }, 10);
  }, [languageData]);

  useEffect(() => {
    handleFetch();
  }, [currentPage, searchValue, languageData, rowLimit]);

  useEffect(() => {
    if (isEditModal) {
      let loadEditKeyValueData = {
        key: editKeyValueData?.key.name,
        baseEnglish: editKeyValueData?.defaultValue,
        value: editKeyValueData?.value,
      };
      reset(loadEditKeyValueData);
    }
  }, [editKeyValueData]);

  function handleFetch() {
    let filters = getKeyValueFilter(searchValue);
    let keyValueFilter = createSearchFilter(rowLimit, (page - 1) * rowLimit, filters);
    keyValueFilter = { language: languageData?.language?.name, ...keyValueFilter };
    getKeyValue({ variables: keyValueFilter });
  }

  function setTitle() {
    setHeading([
      {
        text: t('translations.label'),
        url: '/control-panel/translations/limit/50/page/1',
      },
      {
        text: languageData?.language?.name,
      },
    ]);
  }

  const headCells: HeadCellType[] = [
    {
      id: 'keys',
      label: 'keys.label',
      align: 'left',
      disableSorting: true,
    },
    {
      id: 'base_language',
      label: 'baseLanguage.label',
      align: 'left',
      disableSorting: true,
    },
    {
      id: 'value',
      label: languageData?.language?.name,
      align: 'left',
      disableSorting: true,
    },
    {
      id: 'actions',
      label: 'actions',
      align: 'right',
      disableSorting: true,
    },
  ];

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    row,
    headCells,
    maxHeight,
    totalCount,
    page,
  );

  const editKeyValueModal = (selectedKeyValue: any) => {
    setKeyValueData(selectedKeyValue);
    hideAlert();
    setIsEditModal(true);
    handleModal(true);
  };

  function hideAlert() {
    setAlertDetails({ message: '', level: '' });
  }

  const closeModal = () => {
    handleModal(false);
    setIsEditModal(false);
    handleFetch();
    if (!isEditModal) reset({});
  };

  const handleChange = () => {};
  const handleUpdateKeyValue = async (editdata: any) => {
    let keyValueObject = {
      key: editKeyValueData?.key?.id,
      value: editdata?.value,
      language: editKeyValueData?.language?.id,
    };

    try {
      const updateKeyValueData = await updateKeyValue({
        variables: { payload: keyValueObject },
      });

      if (updateKeyValueData.data.updateLocalizationKeyValue) {
        closeModal();
        setAlertDetails({ message: UPDATE_SUCCESS.LOCALIZATION_KEY_VALUE, level: SUCCESS });
      }
    } catch (e) {
      setAlertDetails({ message: e.message, level: ERROR });
    }
  };

  function persistSearch(state: boolean, search: string) {
    let persistFilter = {
      [AUTOCOMPLETE_MODULE.KEY_VALUE]: {
        on: state,
        search: search,
      },
    };
    setFilter(persistFilter);
  }

  async function searchKeyValue(keyValue: string) {
    let isClear = keyValue?.length === 0;
    persistSearch(!isClear, keyValue);
  }

  const handleSearch = debounce((keyValue: string) => searchKeyValue(keyValue), 200);
  return (
    <>
      <LoadContentWrapper>
        <KeyValueHeader>
          <HeaderSearchPart>
            <Search
              id="keyValueSearch"
              handleChange={handleSearch}
              value={searchValue || ''}
              label={t('searchTranslations.text')}
              width={280}
            />
          </HeaderSearchPart>

          <HeaderButtonPart>
            <ImportButton>
              <OutlineButton
                icon={faUpload}
                iconColor={colors.primaryColor}
                label={t('import.label')}
                onPress={handleChange}
              />
            </ImportButton>
            <>
              <OutlineButton
                icon={faDownload}
                iconColor={colors.primaryColor}
                label={t('export.label')}
                onPress={handleChange}
              />
            </>
          </HeaderButtonPart>
        </KeyValueHeader>
        <>
          {loading ? (
            <Spinner>
              <LoaderSpinner />
            </Spinner>
          ) : (
            <TableDiv>
              <TblContainer>
                <TblHead />
                <TableBody>
                  {recordsAfterPagingAndSorting()?.map((item: any, i: number) => (
                    <TableRows key={i}>
                      <TableCells value={item?.key?.name} style={{ width: '500px' }} />
                      <TableCells
                        value={item?.defaultValue ? item.defaultValue : '-'}
                        style={{ width: '400px' }}
                      />
                      <TableCells
                        value={item?.value ? item.value : '-'}
                        style={{ width: '400px' }}
                      />
                      {currentUserObject?.userType == USER_TYPE.EDBA_ADMIN ? (
                        <TableActions align="right">
                          <TouchableOpacity onPress={() => editKeyValueModal(item)}>
                            <FontAwesomeIcon
                              icon={faPencil}
                              color={theme?.table?.editIconColor}
                              style={{ marginLeft: 20 }}
                            />
                          </TouchableOpacity>
                        </TableActions>
                      ) : (
                        <TableCells value={'-'} />
                      )}
                    </TableRows>
                  ))}
                </TableBody>
              </TblContainer>
              <Pagination
                pathName={`translations/key-values/${languageId}`}
                total={totalCount}
                page={currentPage}
                setCurrentPage={setCurrentPage}
                rowLimit={rowLimit}
                setRowLimit={setRowLimit}
                hidePagination={!!(totalCount && totalCount <= rowLimit)}
              />
            </TableDiv>
          )}
        </>
      </LoadContentWrapper>

      <NormalModal
        onAlert={null}
        setModalVisible={closeModal}
        handleSave={handleSubmit(handleUpdateKeyValue)}
        Headerpopup={t('editValue.text')}
        modalVisible={modalState}
        cancelButtonLabel="cancel.label"
        addEditButtonLabel={t('edit.label')}>
        <>
          <AddKeyValue>
            <Controller
              control={control}
              name={'key'}
              render={({
                field: { onBlur, value },
              }) => (
                <Input
                  id={'key'}
                  onBlur={onBlur}
                  value={value}
                  disabled={true}
                  header="keys.label1"
                />
              )}
            />
          </AddKeyValue>

          <AddKeyValue>
            <Controller
              control={control}
              name={'baseEnglish'}
              render={({
                field: { onBlur, value, ref },
              }) => (
                <Input
                  id={'baseEnglish'}
                  onBlur={onBlur}
                  value={value}
                  disabled={true}
                  header="baseLanguage.label1"
                />
              )}
            />
          </AddKeyValue>

          <AddKeyValue>
            <Controller
              control={control}
              name={'value'}
              render={({
                field: { onChange, onBlur, value },
              }) => (
                <Input
                  id={'value'}
                  onBlur={onBlur}
                  setValue={onChange}
                  value={value}
                  header="value.label"
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: validation.VALUE_REQUIRED,
                },
              }}
            />
            <AddKeyValueError>
              {errors.value ? (
                <SmallTextAtom value={t(errors.value.message)} color={colors.errorColor} />
              ) : null}
            </AddKeyValueError>
          </AddKeyValue>
        </>
      </NormalModal>
    </>
  );
}

const KeyValueHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const HeaderSearchPart = styled.div`
  flex: 3;
  max-width: 280px;
  align-items: flex-start;
`;
const HeaderButtonPart = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Spinner = styled.div`
  margin: auto;
  justify-content: center;
  align-items: center;
`;
const ImportButton = styled.div`
  margin-right: 18;
`;
const AddKeyValue = styled.div`
  margin-top: 24;
`;
const AddKeyValueError = styled.div`
  margin-top: 4;
`;
