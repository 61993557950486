import { StyleProp, TextStyle } from "react-native";

import { Badge } from "react-native-paper";
import { NotificationIcon } from "../ActionIcons";
import React from "react";

type Props = {
  count: number;
  onPress: () => void;
};

const NotificationBell = ({ count, onPress }: Props) => {
  return (
    <>
      <NotificationIcon onPress={onPress} />
      <Badge visible={!!count} style={style}>
        {count > 99 ? "99+" : count}
      </Badge>
    </>
  );
};

const style: StyleProp<TextStyle> = {
  fontSize: 9,
  position: "absolute",
  top: -9,
  right: -9,
};

export default NotificationBell;
