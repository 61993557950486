import { gql, useLazyQuery, useMutation } from "@apollo/client";

export const getDocumentsQuery = gql`
  query (
    $filters: DocumentTypeFilterInput
    $limit: Int
    $skip: Int
    $sort: DocumentTypeSortInput
  ) {
    documentTypes(filters: $filters, limit: $limit, skip: $skip, sort: $sort) {
      data {
        id
        name
        alias
        category
        defaultUserSelection
        defaultUserSelectionRequired
      }
      hasNextPage
      totalCount
    }
  }
`;

export const createDocumentMutation = gql`
  mutation ($payload: DocumentTypeInput!) {
    createDocumentType(payload: $payload) {
      id
      name
    }
  }
`;

export const getDocumentsOptionsQuery = gql`
  query ($limit: Int) {
    documentTypes(limit: $limit) {
      data {
        value: id
        label: name
      }
      hasNextPage
    }
  }
`;

export function useGetDocuments() {
  const [getDocuments, { loading, data, refetch }] = useLazyQuery(
    getDocumentsQuery,
    { fetchPolicy: "network-only" }
  );
  return { getDocuments, data, loading, refetch };
}

export function useCreateDocument() {
  const [createDocument] = useMutation(createDocumentMutation);
  return { createDocument };
}
