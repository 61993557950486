import React from 'react';
import { breakpoints, colors, fonts, fontWeight } from 'src/styles/theme/styles';
import { TouchableOpacity, View } from 'react-native';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import NormalTextSelect from 'src/components/atoms/Text/NormalTextSelect';
import { isNative, isWeb, width } from 'src/constant/device';
import { useHistory } from 'src/routes/routing';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useThemeSystem } from 'src/contexts/theme-context';
import SwipeCard from '../SwipableCard';
import { Tooltip } from '@mui/material';
import { format, parse } from 'date-fns';
import { DT } from 'src/constant/dateTime';

export default function NoticeCard(props: any) {
  const {
    data,
    onPress,
    deleteAction,
    indexKey,
    isSwipable = true,
    canDelete = false,
    createdBy,
  } = props;
  const { theme } = useThemeSystem();

  const parsedDateObj = data?.mcreatedAt && parse(data.mcreatedAt, DT.DATE_TIME_FORMAT_WITH_TIMEZONE, new Date());
  const date = parsedDateObj && format(parsedDateObj, DT.DATE_ABBREVIATED_MONTH_YEAR);

  let isDesktop = width > breakpoints.md;

  const TouchableOpacityList = styled.TouchableHighlight`
    padding-top: 16px;
    padding-left: ${isDesktop ? '0px' : '24px'};
    padding-right: ${isDesktop ? '0px' : '24px'};
    border-bottom-color: ${colors.inputBorder};
    border-bottom-width: ${isDesktop ? '1px' : '0px'};
    padding-bottom: ${isDesktop ? '24px' : '16px'};
    width: ${isDesktop ? '60%' : '100%'};
  `;

  return (
    <>
      <SwipeCard
        key={data?.id}
        deleteItem={() => deleteAction(data, indexKey)}
        isSwipable={isSwipable && canDelete && data?.createdBy?.id === createdBy}
        testID={`deleteNoticeCard-${indexKey}`}
        >
        <TouchableOpacityList
          activeOpacity={0.5}
          underlayColor={isDesktop ? colors.white : colors.inputBorder}
          delayPressIn={50}
          onPress={(e) => onPress(data)}
          testID={`noticeCard-${indexKey}`}
        >
          <>
            <DateView>
              <SmallTextAtom
                value={date || ''}
                color={colors.primaryColor}
                fontWeight={fontWeight[600]}
                fontFamily={isNative ? fonts.semibold : fonts.regular}
              />
              {isWeb && canDelete && data?.createdBy?.id === createdBy && (
                <DeleteView>
                  <Tooltip title="Delete Notice">
                    <TouchableOpacity onPress={(e) => deleteAction(data, indexKey)}>
                      <FontAwesomeIcon icon={faTrashAlt} color={theme?.table?.editIconColor} />
                    </TouchableOpacity>
                  </Tooltip>
                </DeleteView>
              )}
            </DateView>

            <TitleView>
              <MediumTextSelect
                value={data?.title || ''}
                color={colors.secondaryText}
                fontWeight={fontWeight[600]}
                lineHeight={2.4}
                fontFamily={isNative ? fonts.semibold : fonts.regular}
              />
            </TitleView>

            <NormalTextSelect
              value={data?.body || ''}
              fontWeight={fontWeight[400]}
              color={colors.secondaryText}
              lineHeight={2.2}
              lines={2}
            />
          </>
        </TouchableOpacityList>
        {!isDesktop && <Horizontal />}
      </SwipeCard>
    </>
  );
}

const Horizontal = styled.View`
  border-bottom-color: ${colors.inputBorder};
  border-bottom-width: 1px;
  margin-left: 24px;
  margin-right: 24px;
`;

const DateView = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: space-between;
`;

const DeleteView = styled.View`
  right: 0;
  marginleft: auto;
`;

const TitleView = styled.View`
  margin-top: -1px;
`;
