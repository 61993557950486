import React, { Dispatch, SetStateAction } from 'react';
import CreateInfoItem from '../TextComponents/InfoItem';
import styled from 'styled-components';
import { useI18n } from 'src/i18n/hooks';
import Check from 'src/components/atoms/CheckBox/index.web';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import { LeavingCertificateData } from 'src/components/organism/Courses/BatchStudents/types';
import { properCase } from 'src/utils/utility';

interface Props {
  data: LeavingCertificateData;
  confirmSelection: boolean;
  setConfirmSelection: Dispatch<SetStateAction<boolean>>;
}

export default function LeavingCertificateConfirmationForm(props: Props) {
  const { data, confirmSelection, setConfirmSelection } = props;
  const { t } = useI18n();
  return (
    <>
      <StudentDetailWrapper>
        <StudentDetail>
          <CreateInfoItem label={t('form.udiseNo.label')} value={data?.udiseNo} width={345} />
          <CreateInfoItem label={t('form.grNo.label')} value={data?.academic?.grNo} width={345} />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.nameOfStudent.label')}
            value={properCase(data?.fullName || '-')}
            width={345}
          />
          <CreateInfoItem
            label={t('form.guardiansName.label')}
            value={data?.fatherName}
            width={345}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.mothersName.label')}
            value={data?.motherName}
            width={345}
          />
          <CreateInfoItem
            label={t('form.religionAndCaste.label')}
            value={(data?.religion && data.caste) ? (`${data.religion} & ${data.caste}`) : (data?.religion || data?.caste)}
            width={345}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem label={t('form.dob.label')} value={data?.dob} width={345} />
          <CreateInfoItem
            label={t('form.placeOfBirth.label')}
            value={data?.placeOfBirth}
            width={345}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.nationality.label')}
            value={data?.nationality}
            width={345}
          />
          <CreateInfoItem
            label={t('form.registerNo.label')}
            value={data?.registerNo}
            width={345}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.classLastStudied.label')}
            value={data?.lastQualificationName}
            width={345}
          />
          <CreateInfoItem
            label={t('form.schoolLastExaminationTakenWith.label')}
            value={data?.instituteName}
            width={345}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.subjectsStudied.label')}
            value={data?.subjects}
            width={695}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.qualifiedForPromotion.label')}
            value={data?.qualifiedForHigherClass}
            width={695}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.totalNumberOfWorkingDays.label')}
            value={data?.numberOfWorkingDays}
            width={345}
          />
          <CreateInfoItem
            label={t('form.dateOfAdmission.label')}
            value={data?.dateOfAdmission}
            width={345}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.dateOfLeaving.label')}
            value={data?.dateOfLeaving}
            width={345}
          />
          <CreateInfoItem
            label={t('form.reasonForLeaving.label')}
            value={data?.reasonForLeaving}
            width={345}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.generalConduct.label')}
            value={data?.generalConduct}
            width={345}
          />
          <CreateInfoItem
            label={t('form.progress.label')}
            value={data?.progress}
            width={345}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.lastExamYear.label')}
            value={data?.lastExamYear}
            width={345}
          />
          <CreateInfoItem
            label={t('form.lastExamStatus.label')}
            value={data?.lastExamStatus}
            width={345}
          />
        </StudentDetail>

        <StudentDetail>
          <CreateInfoItem
            label={t('form.dateOfIssueOfCertificate.label')}
            value={format(new Date(), DT.DATE_FORMAT_SLASH)}
            width={345}
          />
          <CreateInfoItem
            label={t('form.anyOtherRemark.label')}
            value={data?.anyOtherRemark}
            width={345}
          />
        </StudentDetail>
      </StudentDetailWrapper>
      <CheckWrapper>
        <Check same={false} checked={confirmSelection} onChange={(e) => setConfirmSelection(e)} />
        <MediumTextSelect
          value={t('leavingCertificateConfirmation.message.text')}
          style={{ paddingLeft: 8 }}
        />
      </CheckWrapper>
    </>
  );
}

const StudentDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 500px;
  overflow-y: auto;
`;

const StudentDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 16px;
`;

const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0px;
`;
