import { DropdownOptions, TCellState } from "src/types";
import {
  ExpandCollapseCell,
  NoRecordsFound,
  TableCells,
  TableDiv,
  TableRows,
} from "../../Table/TableAtom";
import React, { CSSProperties, ChangeEvent, useEffect, useState } from "react";
import { colors, fonts } from "src/styles/theme/styles";
import {
  createFeeCompositionStructure,
  sortModuleData,
} from "src/utils/utility";

import { FlatList } from "react-native";
import HeaderSix from "src/components/atoms/Text/HeaderSix";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import NormalTextSelect from "src/components/atoms/Text/NormalTextSelect";
import RadioStandalone from "src/components/atoms/Radio/StandaloneRadio/index.web";
import { TableBody } from "@mui/material";
import TableColHead from "src/components/atoms/Text/TableCellHead";
import { height } from "src/constant/device";
import { feeCompositionSelectionFormHelper as helpers } from "../../Admission/AdmissionForm/AdmissionFormHelpers";
import styled from "styled-components/native";
import { useGetFeeCompositions } from "src/graphql/admission/admissions";
import { useI18n } from "src/i18n/hooks";
import useTable from "../../Table/UseTable";

type Props = {
  onClose: () => void;
  goBack?: () => void;
  onSubmit: (selectedFeeComp: DropdownOptions | null) => void;
  subjectGroupID: string | null;
  initialValue?: string;
  isSubmitting?: boolean;
};

const FeeCompositionSelection = (props: Props) => {
  const {
    onClose,
    goBack,
    onSubmit,
    initialValue,
    subjectGroupID,
    isSubmitting,
  } = props;
  const { t } = useI18n();
  const { getFeeCompositions, data, loading } = useGetFeeCompositions();
  const [selectedComposition, setSelectedComposition] = useState<
    string | undefined
  >(initialValue);
  const [feeComposition, setFeeComposition] = useState<any[]>();
  const [cellState, setCellState] = useState<TCellState>({
    open: true,
    current:
      initialValue && feeComposition
        ? feeComposition?.findIndex((e) => e.id === initialValue)
        : 0,
  });

  useEffect(() => {
    if (subjectGroupID) fetchFeeCompData(subjectGroupID);
  }, [subjectGroupID]);

  useEffect(() => {
    if (data && data?.getFeeCompositions?.data) {
      const feeCompData = createFeeCompositionStructure(
        data.getFeeCompositions.data
      );
      if (feeCompData[0]?.feeBooks && feeCompData[0]?.feeBooks?.length > 0) {
        feeCompData[0].feeBooks = sortModuleData(
          feeCompData[0]?.feeBooks,
          "groupInfo"
        );
      }
      setFeeComposition(feeCompData);
    }
  }, [data]);

  const fetchFeeCompData = async (subjGrpID: string) => {
    getFeeCompositions({
      variables: {
        limit: 0,
        subGroup: subjGrpID,
      },
    });
  };

  const { TblContainer, recordsAfterPagingAndSorting } = useTable(
    feeComposition,
    [],
    null,
    feeComposition?.length
  );

  const onSave = () => {
    if (selectedComposition) {
      onSubmit({
        label: feeComposition?.find((e) => e.id === selectedComposition)
          ?.compositionName,
        value: selectedComposition,
      });
    } else {
      onSubmit(null);
    }
  };

  return (
    <NormalModal
      modalVisible={true}
      height={560}
      maxWidth={"lg"}
      setModalVisible={onClose}
      Headerpopup={t("selectFeeComposition.label")}
      addEditButtonLabel={"save.label"}
      onCancelButtonClick={goBack ?? onClose}
      handleSave={onSave}
      isSubmitting={isSubmitting}
    >
      <TableDiv>
        <TblContainer>
          {feeComposition && feeComposition?.length > 0 ? (
            <TableBody>
              {recordsAfterPagingAndSorting()?.map(
                (feeCompData: any, i: number) => (
                  <>
                    <TableRows key={`e${i}`}>
                      <TableCells style={radioBtnStyle}>
                        <RadioStandalone
                          isChecked={selectedComposition === feeCompData.id}
                          value={feeCompData.id}
                          handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setSelectedComposition(e.target.value);
                          }}
                        />
                      </TableCells>
                      <TableCells>
                        <HeaderSix
                          value={feeCompData?.compositionName}
                          color={colors.primaryText}
                          fontWeight={
                            selectedComposition === feeCompData?.id ? 600 : 400
                          }
                        />
                      </TableCells>
                      <ExpandCollapseCell
                        trow={cellState.open}
                        current={cellState.current}
                        i={i}
                        classNames={""}
                        textValue={""}
                        CollapseFn={() => {
                          setCellState({ open: false, current: null });
                        }}
                        ExpandFn={() => {
                          setCellState({ open: true, current: i });
                        }}
                        size={28}
                        color={colors.primaryColor}
                      />
                    </TableRows>
                    {cellState.open && cellState.current === i && (
                      <TableRows>
                        <TableCells colspan={3} style={{ padding: 0 }}>
                          <ParentContainer>
                            <InfoWrapper>
                              <AccountInfoWrapper>
                                <NormalTextSelect
                                  value={t("accountName.label")}
                                  color={colors.secondaryText}
                                />
                                <MediumTextSelect
                                  value={feeCompData?.bankAlias}
                                  color={colors.primaryText}
                                />
                              </AccountInfoWrapper>
                            </InfoWrapper>

                            <TableContainerWrapper>
                              <HeadersWrapper>
                                {helpers.feeCompositionTableHeaders.map(
                                  ({ name }, index: number) => (
                                    <TableColHeadWrapper index={index}>
                                      <TableColHead value={t(name)} />
                                    </TableColHeadWrapper>
                                  )
                                )}
                              </HeadersWrapper>
                              <SingleLine marginBottom={15} />
                              <FlatList
                                data={
                                  Object.keys(feeCompData)?.length > 0 &&
                                  feeCompData?.feeBooks
                                }
                                contentContainerStyle={{
                                  flex: 1,
                                  maxHeight: height - 450,
                                  minHeight: height - 650,
                                }}
                                keyExtractor={(item: any) => item.id}
                                renderItem={({ item, index }: any) => {
                                  return (
                                    <ContainerView
                                      marginBottom={
                                        feeCompData?.feeBooks?.length - 1 ===
                                          index
                                          ? 18
                                          : 24
                                      }
                                    >
                                      <ParentElementView>
                                        <MainStartElement>
                                          <MediumTextSelect
                                            value={item?.groupName}
                                            color={colors.primaryText}
                                          />
                                        </MainStartElement>
                                        <MainElement>
                                          <MediumTextSelect
                                            value={`\u20B9 ${item?.amount ? item.amount : "-"
                                              }`}
                                            fontFamily={fonts.semibold}
                                            color={colors.primaryText}
                                          />
                                        </MainElement>
                                      </ParentElementView>
                                      <ChildElementView>
                                        {item?.groupName !==
                                          item.groupInfo[0].name &&
                                          item.groupInfo.map(
                                            (groupInfo: any) => (
                                              <ChildElementContentView>
                                                <ComponentsStartElement>
                                                  <NormalTextSelect
                                                    value={groupInfo.name}
                                                    style={{ marginLeft: 33 }}
                                                    color={colors.secondaryText}
                                                  />
                                                </ComponentsStartElement>
                                                <ComponentsElement>
                                                  <NormalTextSelect
                                                    value={`\u20B9 ${groupInfo.amount}`}
                                                    color={colors.secondaryText}
                                                  />
                                                </ComponentsElement>
                                              </ChildElementContentView>
                                            )
                                          )}
                                      </ChildElementView>
                                    </ContainerView>
                                  );
                                }}
                              />
                              <SingleLine />
                              <FooterWrapper>
                                <ComponentsStartElement>
                                  <MediumTextSelect
                                    value={t("totalPayment.label")}
                                    fontFamily={fonts.semibold}
                                    color={colors.primaryText}
                                  />
                                </ComponentsStartElement>
                                <ComponentsElement>
                                  <MediumTextSelect
                                    value={feeCompData?.totalPayment}
                                    fontFamily={fonts.semibold}
                                    color={colors.primaryText}
                                  />
                                </ComponentsElement>
                              </FooterWrapper>
                            </TableContainerWrapper>
                          </ParentContainer>
                        </TableCells>
                      </TableRows>
                    )}
                  </>
                )
              )}
            </TableBody>
          ) : (
            <SpinnerView>
              {!loading ? (
                <NoRecordsFound colspan={3} maxHeight={568} />
              ) : (
                <LoaderSpinner />
              )}
            </SpinnerView>
          )}
        </TblContainer>
      </TableDiv>
    </NormalModal>
  );
};

const ParentContainer = styled.View`
  margin-bottom: 16px;
`;

const InfoWrapper = styled.View`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 24px;
  padding: 0px 9px;
`;

const AccountInfoWrapper = styled.View`
  margin-right: 180px;
`;

const TableContainerWrapper = styled.View`
  padding-left: 9px;
  padding-right: 9px;
`;

const TableColHeadWrapper = styled.View<{ index: number }>`
  flex: ${(props: any) => (props.index === 0 ? 1.5 : 1)};
  justify-content: center;
  align-items: ${(props: any) =>
    props.index === 0 ? "flex-start" : "flex-end"};
  margin: 0px 22px;
`;

const HeadersWrapper = styled.View`
  margin-top: 24px;
  flex-direction: row;
  margin-bottom: 15px;
`;

const SingleLine = styled.View<{ marginBottom?: number }>`
  border-bottom-width: 1;
  border-bottom-color: ${colors.dividerColor};
  margin-bottom: ${(props: any) =>
    props.marginBottom ? props.marginBottom : 0};
`;

const ContainerView = styled.View<{ marginBottom: number }>`
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const ParentElementView = styled.View`
  flex-direction: row;
  padding-left: 22px;
  padding-right: 22px;
`;

const MainElement = styled.View`
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;

const MainStartElement = styled.View`
  flex: 1.5;
  justify-content: center;
  align-items: flex-start;
`;

const ChildElementView = styled.View`
  padding-left: 22px;
  padding-right: 22px;
`;

const ChildElementContentView = styled.View`
  flex-direction: row;
  margin-top: 24px;
`;

const ComponentsElement = styled.View`
  flex: 1;
  justify-content: center;
  align-items: flex-end;
`;

const ComponentsStartElement = styled.View`
  flex: 1.5;
  justify-content: center;
  align-items: flex-start;
`;

const FooterWrapper = styled.View`
  flex-direction: row;
  padding-left: 22px;
  padding-right: 22px;
  margin-top: 16px;
`;

const SpinnerView = styled.View`
  height: 568px;
  display: contents;
`;

const radioBtnStyle: CSSProperties = { width: 16, padding: 1 };

export default FeeCompositionSelection;
