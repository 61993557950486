// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyDbSZ8mVPki-4l_7EafKPtNiJaGzQ2wvfY",
    authDomain: "edba-dev.firebaseapp.com",
    projectId: "edba-dev",
    storageBucket: "edba-dev.appspot.com",
    messagingSenderId: "300027247078",
    appId: "1:300027247078:web:007939c5d0840f74493e27",
    measurementId: "G-KCYPD6TY44"
};

const app = initializeApp(firebaseConfig);
let messaging;

export default {
    getToken: async (registration) => {
        if (!messaging) {
            messaging = getMessaging(app);
            await deleteToken(messaging);
        }
        return await getToken(
            messaging,
            {
                vapidKey: 'BE7rpUBBBHlSySJ5DiwCO9__b688SrJrlr0uOb6FnFbeKT-r7lrA08nMEEm0cIMbIn1UgCAritKlMFTu3uRVOIQ',
                serviceWorkerRegistration: registration,
            }
        );
    },
    onMessage: async (callback) => {
        if (messaging) {
            onMessage(messaging, (payload) => callback(payload));
        }
    }
};