import { CopyIcon, EditIcon, LinkIcon } from "src/components/atoms/ActionIcons";
import { ERROR, SUCCESS } from "src/constant";
import { Popover, PopoverOrigin } from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import {
  TAdmissionLinksForm as TForm,
  TAdmissionLink as TLink,
} from "./AdmissionLinkHelpers";

import AdmissionLinksForm from "./AdmissionLinksForm.web";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import LoaderSpinner from "src/components/atoms/LoaderSpinner/index.web";
import NormaltextAtom from "src/components/atoms/Text/NormalTextAtom";
import OutlineButton from "src/components/atoms/Button/OutlineButtton";
import { TAdmissionConfig } from "src/components/organism/Admission/AdmissionDetail/AdmissionDetailTypes";
import { colors } from "src/styles/theme/styles";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import { useAlertSystem } from "src/contexts/web-alert-context";
import { useGetAdmissionLinks } from "src/graphql/admission";
import { useI18n } from "src/i18n/hooks";

type Props = { admissionConfig: TAdmissionConfig };

export const AdmissionLinks = ({ admissionConfig }: Props) => {
  const { admission } = admissionConfig;
  const { t } = useI18n();
  const { getAdmissionLinks, data, loading, refetch } = useGetAdmissionLinks();
  const { setAlertDetails } = useAlertSystem();

  const [form, setForm] = useState<TForm>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const existingLinks = data?.admissionLinks?.data ?? [];

  useEffect(() => {
    if (admission)
      getAdmissionLinks({
        variables: {
          limit: 0,
          filters: {
            admission: {
              eq: admission,
            },
          },
        },
      });
  }, [admission]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openForm = (item?: TLink) => {
    setForm({
      state: "OPEN",
      ...(item ? { mode: "EDIT", data: item } : { mode: "ADD" }),
    });
  };

  const onClose = (): void => {
    setForm({ state: "CLOSE", mode: "ADD" });
  };

  const onSuccess = (message: string): void => {
    setAlertDetails({ message, level: SUCCESS });
    if (refetch) refetch();
    onClose();
  };

  const onError = (message: string): void => {
    setAlertDetails({ message, level: ERROR });
    onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "links-popover" : undefined;

  return (
    <Container>
      <OutlineButton
        height={24}
        // @ts-ignore
        style={styles.outlineButton}
        // @ts-ignore
        onPress={handleClick}
      >
        <LinksButtonWrapper>
          <NormaltextAtom
            value={t("admissionLinks.label")}
            color={colors.primaryColor}
            lines={1}
            fontSize={1.3}
          />
          <FontAwesomeIcon icon={faAngleDown} color={colors.primaryColor} />
        </LinksButtonWrapper>
      </OutlineButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={popoverOrigin.anchorOrigin}
        transformOrigin={popoverOrigin.transformOrigin}
      >
        <LinksContainer>
          <ExistingLinks>
            {!loading ? (
              existingLinks.length > 0 ? (
                existingLinks.map((e: TLink) => (
                  <ItemWrapper>
                    <NormaltextAtom
                      value={`${e.subjectGroup.value} (${e.applicantType})`}
                      style={styles.subGrp}
                    />
                    <IconsWrapper>
                      <CopyIcon
                        tooltipTitle={t("admissionCodeCopy.label")}
                        color={colors.secondaryText}
                        onPress={() =>
                          navigator.clipboard.writeText(e.requestId)
                        }
                      />
                      <LinkIcon
                        tooltipTitle={t("admissionLinkCopy.label")}
                        color={colors.secondaryText}
                        onPress={() =>
                          navigator.clipboard.writeText(
                            `${window.location.origin}/application/add/${e.prefix}/${e.requestId}`
                          )
                        }
                      />
                      <EditIcon
                        tooltipTitle={t("editAdmissionLink.text")}
                        onPress={() => openForm(e)}
                      />
                    </IconsWrapper>
                  </ItemWrapper>
                ))
              ) : (
                <NormaltextAtom value={"No Links Found"} />
              )
            ) : (
              <Center>
                <LoaderSpinner />
              </Center>
            )}
          </ExistingLinks>
          <AddNewLink onClick={() => openForm()}>
            <FontAwesomeIcon icon={faPlus} />
            <NormaltextAtom value={t("addNewLink.label")} />
          </AddNewLink>
        </LinksContainer>
      </Popover>
      {form && form.state === "OPEN" && (
        <AdmissionLinksForm
          form={form}
          onSuccess={onSuccess}
          onError={onError}
          onClose={onClose}
          admissionConfig={admissionConfig}
        />
      )}
    </Container>
  );
};

const popoverOrigin: Record<string, PopoverOrigin> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

const styles: Record<string, CSSProperties> = {
  outlineButton: { paddingLeft: 8, paddingRight: 4 },
  subGrp: { maxWidth: 140 },
};

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled(Center)`
  row-gap: 4px;
`;

const LinksButtonWrapper = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const ExistingLinks = styled(Center)`
  padding: 8px 0px;
  min-height: 60px;
  row-gap: 15px;
  margin-bottom: 36px;
`;

const LinksContainer = styled.div`
  width: 240px;
  min-height: 100px;
  max-height: 280px;
  overflow-y: auto;
`;

const AddNewLink = styled(Center)`
  flex-direction: row;
  column-gap: 12px;
  padding: 10px 0px 8px 0px;
  border-top: 1px solid ${colors.borderGrey};
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const ItemWrapper = styled(FlexRow)`
  padding: 0px 8px;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  box-sizing: border-box;
  width: 100%;
`;

const IconsWrapper = styled(FlexRow)`
  column-gap: 8px;
`;
