import React from 'react';
import Svg, { Path } from "react-native-svg";

interface Props {
    size?: number;
    color?: string;
}
export default function SvgComponent(props: Props) {
    return (
        <Svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Path
                d="M6.5657 13.4299L15.7657 2.86696C16.0781 2.50828 16.0781 1.92669 15.7657 1.56801L14.6344 0.269054C14.322 -0.0896846 13.8154 -0.0896846 13.503 0.269054L6 8.88348L2.49705 4.86158C2.18465 4.5029 1.6781 4.5029 1.36565 4.86158L0.2343 6.16054C-0.0781 6.51922 -0.0781 7.10081 0.2343 7.45949L5.4343 13.4299C5.74675 13.7886 6.25325 13.7886 6.5657 13.4299Z"
                fill="white"
            />
        </Svg>
    )
}