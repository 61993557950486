import React, { createContext, useContext } from 'react';
import { usePersistStorage } from 'react-native-use-persist-storage';

interface HeadingContextData {
  setHeading(name: any): void;
  heading: any;
  restored: boolean;
}

const HeaderContext = createContext<HeadingContextData>({} as HeadingContextData);

const HeaderProvider: React.FC = ({ children }) => {
  const [heading, setHeading, restored] = usePersistStorage('heading', []);
  return (
    <HeaderContext.Provider value={{ setHeading, heading, restored }}>
      {children}
    </HeaderContext.Provider>
  );
};

const useHeaderTitle = (): HeadingContextData => {
  const context = useContext(HeaderContext);
  return context;
};

export { HeaderProvider, useHeaderTitle };
