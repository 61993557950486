import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import Element from '../Forms/ApplicationElement';
import attendanceFomJson from '../../../form-json/attendance/attendance-form.json';
import { isNative, isWeb } from 'src/constant/device';
import SecondaryBtn from 'src/components/atoms/Button/SecondaryButton';
import { useI18n } from 'src/i18n/hooks';
import { attendanceSlotsList } from 'src/graphql/attendance';
import { graphqlQuery } from 'src/graphql/util';
import { AUTOCOMPLETE_MODULE } from 'src/constant';
import { mergeBatchClassLabel } from 'src/utils/utility';
import { DT } from 'src/constant/dateTime';
import { format, parse } from 'date-fns';
export default function MarkAttendanceForm(props: any) {
  const {
    errors,
    control,
    slots,
    batches,
    attendanceType,
    setValue,
    onPressFunction,
    setDates = null,
    setSlots,
    dates,
  } = props;

  const timezone = DT.TIMEZONE;
  const [elements, setElements] = useState<any>([]);
  const [selectedBatch, setSelectedBatch] = useState<any>();
  const [selectedDivision, setSelectedDivision] = useState<null | string>(null);

  const { t } = useI18n();

  function setInputForSubjectsApi() {
    if (isNative) {
      setValue('slotsarray', null);
      setSlots([]);
    }
    if (selectedBatch && selectedDivision) {
      setSubjects({
        date: dates.currentDate,
        batch: selectedBatch.value,
        division: selectedDivision,
        timezone,
      });
    }
  }

  async function setSubjects(input: any) {
    const { loading, data: slotsdatalist } = await graphqlQuery(
      attendanceSlotsList,
      {
        input,
      },
      true,
    );
    if (!loading && slotsdatalist) {
      let arr = slotsdatalist?.getAttendanceSlots.map((item) => {
        if (item.start && item.end) {
          const start = format(parse(item.start, DT.TIME_24_HOUR, new Date()), DT.TIME_12_HOUR)
          const end = format(parse(item.end, DT.TIME_24_HOUR, new Date()), DT.TIME_12_HOUR)
          return {
            value: `${item.slot}/${item.start}/${item.end}`,
            label: `${item.slotName} ( ${start} - ${end} )`,
            slotId: item.slot,
          };
        }
        return false;
      });
      arr = arr.filter(Boolean);
      setSlots(arr);
    }
  }

  async function handleCustomSelect(id: string, data: any) {
    if (id === 'date' && isWeb && setDates) {
      if (attendanceType.value === 2) {
        setValue('slotsarray', null);
      }
      setDates({ currentDate: data });
    }

    if (id === AUTOCOMPLETE_MODULE.BATCH) {
      data = isWeb ? data : batches.find((bt: any) => bt.value === data);
      setValue('division', null);
      loadDivisions([]);
      setValue('slotsarray', null);
      if (data) {
        setSelectedBatch(data);
        loadDivisions(data?.divisionDetails?.map((det) => det.division));
      }
    }

    if (id === 'division') {
      const division = isWeb ? data?.value : data;
      setValue('slotsarray', null);
      if (division) {
        setSelectedDivision(division);
      }
    }
  }

  function loadDivisions(divisionArray: any) {
    let options = [] as any;
    divisionArray.forEach(function (item: any) {
      options.push({
        value: item,
        label: item,
      });
    });
    attendanceFomJson[0].fields[2].option = options;
    let fileds = [...attendanceFomJson[0]?.fields];
    setElements(fileds);
  }

  function loadSlots(slotsArr: any) {
    let options = [] as any;
    if (slotsArr) {
      if (slotsArr.length > 0) {
        slotsArr.forEach(function (item: any) {
          options.push({
            value: item.value,
            label: item.label,
            slotId: item.slotId,
          });
        });
      }
      attendanceFomJson[0].fields[3].option = options;
    }
    attendanceFomJson[0].fields[3].textHead =
      attendanceType.value === 1 ? 'slot.required.label' : 'subject.required.label';
    attendanceFomJson[0].fields[3].required_error =
      attendanceType.value === 1 ? 'SELECT_SLOT' : 'SELECT_SUBJECT';

    let fileds = [...attendanceFomJson[0]?.fields];
    setElements([...fileds]);
  }

  function viewHandler(id: string) {
    if (id == 'slotsarray' && (attendanceType.value == 2 || attendanceType.value == 1)) {
      return true;
    } else if (id == 'slotsarray' && attendanceType.value != 1) {
      return false;
    } else if (id == 'date' && !isWeb) {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (batches && batches.length) {
      // essentialy to null the two fields else
      // they won't clear on later trigger (data == null)
      setValue('batch', null);
      setValue('division', null);
      setValue('slotsarray', null);
      let options = mergeBatchClassLabel(batches)
      setElements({});
      attendanceFomJson[0].fields[1].option = options;
      setElements(attendanceFomJson[0].fields);
    }
  }, [batches]);

  useEffect(() => {
    loadSlots(slots);
  }, [slots]);

  useEffect(() => {
    if (attendanceType.value === 2) {
      setInputForSubjectsApi();
    }
  }, [dates, selectedBatch, selectedDivision]);

  useEffect(() => {
    loadDivisions([])
    setValue('date', dates.currentDate);
  }, []);

  return (
    <View style={isWeb ? styles.parentContainerWeb : styles.parentContainerNative}>
      {elements &&
        elements.map((field: any, i: number) => {
          const result = viewHandler(field?.id);
          if (result) {
            return (
              <View
                key={`attendance${i}`}
                style={[
                  isNative
                    ? styles.childContainerStyleNative
                    : styles.childContainerStyleWeb,
                  {
                    zIndex: field?.id === 'date' ? 1 : -1,
                  },
                ]}>
                {isWeb ? (
                  <Element
                    key={`attendanceelement${i}`}
                    field={field}
                    control={control}
                    errors={errors}
                    handleCustomSelect={handleCustomSelect}
                    dropdownWidth={400}
                    showDoubleNextorPrev={false}
                  />
                ) : (
                  <Element
                    key={`attendanceelement${i}`}
                    field={field}
                    control={control}
                    errors={errors}
                    handleCustomSelect={handleCustomSelect}
                  />
                )}
              </View>
            );
          } else {
            return <View key={`attendance${i}`} />;
          }
        })}
      {elements && elements.length > 0 && isNative && (
        <SecondaryBtn
          label={t('next.label')}
          onPress={onPressFunction}
        />
      )}
    </View>
  );
}
const styles = StyleSheet.create({
  parentContainerWeb: {
    zIndex: 1,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  parentContainerNative: {
    flexDirection: 'column',
  },
  childContainerStyleWeb: {
    marginRight: 16,
    minWidth: 400,
  },
  childContainerStyleNative: {
    marginRight: 0,
  },
});
