import useFieldApi, { UseFieldApiConfig } from '@data-driven-forms/react-form-renderer/use-field-api';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import styled from 'styled-components';
import DropDown from 'src/components/atoms/DropDown/index.web';
import Input from "src/components/atoms/Input/input.web";
import React, { useEffect, useState } from 'react';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import { DropdownOptionsType } from 'src/types';
import { qualifiedStreamsType } from '../../Forms/types';

interface NumberInputType {
  label: String;
  value?: Number;
}

const StreamSelection = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    meta,
    options,
    setInitialValue,
    subscribeToValues,
    majorSubjectField,
  } = useFieldApi(props);
  const formOptions = useFormApi();
  const { getState } = useFormApi();
  const { values, submitFailed } = getState();
  const [majorSubjects, setMajorSubjects] = useState<NumberInputType[]>([]);
  const [optionMap, setOptionMap] = useState<Map<String, NumberInputType[]>>()
  const [ddOptions, setDDOptions] = useState<DropdownOptionsType[]>([]);
  const [currentVal, setCurrentVal] = useState<string>();
  const { value, onChange } = input;
  const { t } = useI18n();

  useEffect(() => {
    if (setInitialValue) {
      setInitialValue(props, formOptions);
    }
  }, [])

  useEffect(() => {
    setCurrentVal(typeof value === "string" ? value : value?.value);
    if (value?.value) {
      handleChange(value.value);
    }
  }, [value]);

  function getMajorSubjectMarks(majorSubjects: NumberInputType[]): NumberInputType[] {
    const majorVals = values[majorSubjectField]
    let newMajorSubjects = majorSubjects;
    if (newMajorSubjects && majorVals) {
      newMajorSubjects = newMajorSubjects.map((ms) => {
        const find = majorVals.find((val: any) => val.major == ms.label)
        if (find) { ms.value = find.marks };
        return ms;
      })
    }
    return newMajorSubjects;
  }

  useEffect(() => {
    const newDDOptions: DropdownOptionsType[] = [];
    const newMap = new Map<String, NumberInputType[]>();
    if (options?.length > 0) {
      options.forEach((opt: qualifiedStreamsType) => {
        newDDOptions.push(opt.stream);
        let majors: NumberInputType[] = [];
        if (opt.majorSubjects) {
          opt.majorSubjects.forEach((subj) => {
            majors.push({ label: subj });
          })
        }
        if (opt.stream.value == currentVal) {
          majors = getMajorSubjectMarks(majors);
        }
        newMap.set(opt.stream.value, majors);
      });
      setDDOptions(newDDOptions);
      setOptionMap(newMap);
    }
  }, [options, currentVal])

  useEffect(() => {
    if (optionMap && optionMap.size > 0 && currentVal) {
      const majors = optionMap.get(currentVal);
      setMajorSubjects(majors || []);
    }
  }, [ddOptions, optionMap])

  function handleChange(val: any) {
    onChange(val);
    if (optionMap) {
      const majors = optionMap.get(val);
      setMajorSubjects(majors || []);
    }
  }

  function handleMajorSubjectChange(major: String) {
    // litle expensive update of value state due to dynamic input
    return function (val: any) {
      const marks = val.target.value;
      let majorVals: any[] = [];
      if (values[majorSubjectField]) {
        majorVals = [...values[majorSubjectField]]
      }
      if (majorVals.length > 0) {
        const idx = majorVals.findIndex((ms: any) => ms.major == major);
        if (idx != -1) {
          majorVals[idx] = { major, marks };
        } else {
          majorVals.push({ major, marks });
        }
      } else {
        majorVals = [{ major, marks }]
      }
      let newMajorSubjects = majorSubjects;
      const idx = newMajorSubjects.findIndex((ms) => ms.label == major);
      newMajorSubjects[idx] = { label: major, value: marks };
      values[majorSubjectField] = majorVals;
      setMajorSubjects([...newMajorSubjects]);
    }
  }

  return (
    <FormSpy subscription={{ values: subscribeToValues ? true : false }}>
      {() => (
        <MainContainer>
          <InputContainer>
            <DropDown
              value={currentVal}
              header={t(label)}
              options={ddOptions}
              handleChange={handleChange}
            />
            <ErrorMsg>{submitFailed && t(meta.error)}</ErrorMsg>
          </InputContainer>
          {
            majorSubjects.length > 0 && <MajorSubjectContainer>
              {
                <MajorSubjectContainerLabel>
                  {t("major-subject-marks.required.label")}
                </MajorSubjectContainerLabel>
              }
              {
                majorSubjects?.map((major, index) => {
                  return (
                    <MajorSubjectInputContainer>
                      <Input
                        value={major.value || ''}
                        setValue={handleMajorSubjectChange(major.label)}
                        header={major.label}
                        typeInput={"number"}
                      />
                      <ErrorMsg>{meta.submitFailed && t(meta.error)}</ErrorMsg>
                    </MajorSubjectInputContainer>
                  );
                })
              }
            </MajorSubjectContainer>
          }

        </MainContainer>
      )}
    </FormSpy>
  )
}

export default StreamSelection;

/*Styled - components*/
const MainContainer = styled.div`
  margin-bottom: 0;
`

const MajorSubjectContainer = styled.div`
  border: 1px solid black;
  border-style: dashed;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  position: relative;
`

const MajorSubjectContainerLabel = styled.div`
  font-family: ${fonts.regular};
  font-size: 14px;
  position: absolute;
  background-color: white;
  padding: 2px 6px;
  top: -10px;
  left: 10px;
`

const MajorSubjectInputContainer = styled.div`
  margin-right: 5%;
  margin-left: 5%;
  padding-top: 5%;
  margin-bottom: 0;
`
const InputContainer = styled.div`
  margin-bottom: 0;
`

const ErrorMsg = styled.div`
  font-family: ${fonts.regular};
  color: ${colors.errorColor};
  margin-top: 5px;
  font-size: 12px;
  min-height: 14px;
`;