import { gql, useLazyQuery, useMutation } from "@apollo/client";

export const getFilteredCriteriasQuery = gql`
  query ($filters: AdmissionCriteriaFilterInput, $limit: Int) {
    admissionCriterias(
      filters: $filters
      limit: $limit
      sort: { name: ASCENDING }
    ) {
      data {
        value: id
        label: name
        reservationCriteria {
          reservations {
            caste {
              name
            }
            reservePercentage
          }
        }
        qualifications {
          name {
            name
          }
          required
        }
        class {
          alias
          course {
            alias
            university {
              name
            }
          }
        }
      }
    }
  }
`;

export const getCriteriasQuery = gql`
  query(
    $filters: AdmissionCriteriaFilterInput
    $limit: Int
    $skip: Int
    $sort: AdmissionCriteriaSortInput
  ) {
    admissionCriterias(
      filters: $filters
      limit: $limit
      skip: $skip
      sort: $sort
    ) {
      data {
        id
        name
        qualifications {
          firstAttemptRequired
          documentTypes {
            documentType {
              id
              name
            }
            required
          }
          name {
            value: id
            label: name
          }
          required
          streams {
            stream {
              id
              name
            }
            description
            majorSubjects
          }
          categoryCriteria {
            category
            minAverage
          }
        }
        reservationCriteria {
          id
          name
          reservations {
            caste {
              id
              name
            }
          }
        }
        class {
          value: id
          label: alias
          course {
            value: id
            label: alias
            university {
              value: id
              label: name
            }
          }
        }
        institutes {
          value: id
          label: name
        }
      }
      hasNextPage
      totalCount
    }
  }
`;

export const getCriteriaByIdQuery = gql`
  query ($id: String!) {
    admissionCriteria(id: $id) {
      id
      reservationCriteria {
        reservations {
          caste {
            id
            name
          }
        }
      }
    }
  }
`;

export const createCriteriaMutation = gql`
  mutation ($payload: AdmissionCriteriaInput!) {
    createAdmissionCriteria(payload: $payload) {
      id
      name
    }
  }
`;

export function useGetCriterias() {
  const [
    getCriterias,
    { loading: criteriasLoading, data: criteriasData, error: criteriasError, refetch: refetchCriterias },
  ] = useLazyQuery(getCriteriasQuery, { fetchPolicy: 'network-only' });
  return { getCriterias, criteriasData, criteriasError, criteriasLoading, refetchCriterias };
}

export function useCreateCriteria() {
  const [createCriteria, { data: createCriteriaData, error: createCriteriaError }] =
    useMutation(createCriteriaMutation);
  return { createCriteria, createCriteriaData, createCriteriaError };
}
