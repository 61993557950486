import React, { CSSProperties } from "react";
import { colors, fonts } from "src/styles/theme/styles";

import AlertModal from "src/components/atoms/Modals/Delete/index.web";
import MediumTextSelect from "src/components/atoms/Text/MediumTextSelect";
import NormalTextSelect from "src/components/atoms/Text/NormalTextSelect";
import styled from "styled-components";

type ConfirmationModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  headerTitle: string;
  bodyText: string;
  bodySubText?: string;
  confirmationLabel?: string;
  modalWidth?: CSSProperties["width"];
};

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    onClose,
    onSubmit,
    headerTitle,
    bodyText,
    bodySubText,
    confirmationLabel = "confirm.label",
    modalWidth = 420,
  } = props;
  return (
    <AlertModal
      modalVisible={true}
      setModalVisible={onClose}
      Headerpopup={headerTitle}
      width={modalWidth}
      handleSave={onSubmit}
      deleteButtonColor={colors.primaryColor}
      deleteButtonText={confirmationLabel}
    >
      <ContentWrapper>
        <MediumTextSelect
          value={bodyText}
          color={colors.primaryText}
          fontFamily={bodySubText ? fonts.semibold : fonts.regular}
          fontSize={1.7}
        />
        {bodySubText && (
          <NormalTextSelect value={bodySubText} color={colors.secondaryText} />
        )}
      </ContentWrapper>
    </AlertModal>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export default ConfirmationModal;
