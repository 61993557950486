import React, { useEffect, useRef } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { colors } from 'src/styles/theme/styles';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import NormalTextSelect from '../Text/NormalTextSelect';
import { Icon } from '../Icon/Icon';
import ImageAtom from '../ImageAtom/index.web';

interface Props {
  buttonData: {
    key: string;
    value?: string;
    children?: React.ReactNode;
    onPress: () => void;
    onInputPress?: (e: any) => void;
    typeInput?: boolean;
    prefixIcon?: any;
    suffixIcon?: any;
    customIcon?: boolean;
    customStyle?: boolean;
    imageURI?: string;
  }[];
  width?: number;
  top?: number;
  right?: number;
  height?: number;
  handleRef?: (ref: HTMLDivElement | null) => void;
}

export default function ButtonGrp(props: Props) {
  const { buttonData, width = 140, top = 24, right = 0, height, handleRef } = props;
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (handleRef) {
      handleRef(divRef.current); // Pass the ref value to the parent component
    }
  }, [handleRef, divRef]);

  return (
    <DropdownWrapper ref={divRef} width={width} top={top} height={height} right={right}>
      <ButtonGroup
        orientation="vertical"
        variant="text"
        size="small"
        color="inherit"
        fullWidth>
        {buttonData &&
          buttonData.length > 0 &&
          buttonData.map((item: any) => {
            return (
              <>
                {item?.typeInput ? (
                  <Button
                    style={styles.text}
                    key={item.key}
                    onClick={() => { }}
                    fullWidth
                    component="label">
                    {item?.prefixIcon && (
                      <FontAwesomeIcon icon={item?.prefixIcon} color={colors.secondaryText} />
                    )}
                    <input
                      style={{ display: 'none' }}
                      id='hiddenFileInput'
                      multiple
                      type="file"
                      accept=".xls,.xlsx"
                      onChange={item.onInputPress}
                    />
                    <ContentWrapper>
                      <NormalTextSelect value={item.value} />
                    </ContentWrapper>
                    {item?.suffixIcon && (
                      <FontAwesomeIcon icon={item?.suffixIcon} color={colors.secondaryText} />
                    )}
                  </Button>
                ) : (
                  <Button style={item?.value ? styles.text : styles.childrenText} key={item.key} onClick={item.onPress}>
                    {
                      item?.prefixIcon &&
                      (item?.customIcon ? (
                        item?.imageURI ? (
                          <ImageAtom
                            source={{
                              uri: item.imageURI,
                            }}
                            style={styles.imgStyle} />
                        ) : (
                          <Icon name={item?.prefixIcon} />
                        )
                      ) : item?.customStyle ? (
                        <IconWrapper>
                          <FontAwesomeIcon
                            icon={item?.prefixIcon}
                            color={colors.secondaryText}
                            size={16}
                          />
                        </IconWrapper>
                      ) : (
                        <FontAwesomeIcon
                          icon={item?.prefixIcon}
                          color={colors.secondaryText}
                          size={16}
                        />
                      ))
                    }
                    <ContentWrapper>
                      {
                        item.value ? (
                          <NormalTextSelect value={item.value} lines={1} />
                        ) : (
                          <>{item?.children && item.children}</>
                        )
                      }
                    </ContentWrapper>
                    {item?.suffixIcon && (
                      <FontAwesomeIcon icon={item?.suffixIcon} color={colors.secondaryText} size={16} />
                    )}
                  </Button>
                )}
              </>
            )
          })}
      </ButtonGroup>
    </DropdownWrapper>
  );
}

const textStyle = {
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 4,
  height: 48,
  paddingTop: 0,
  paddingBottom: 0,
  paddingHorizontal: 8,
  border: "none",
}

const styles = {
  text: textStyle,
  childrenText: { ...textStyle, height: 68 },
  imgStyle: {
    height: 24,
    width: 24,
  },
};

const DropdownWrapper = styled.div<{ width: number; top: number; height: number; right: number }>`
  border-radius: 4px;
  padding: 8px 8px;
  position: absolute;
  background-color: ${colors.white};
  box-shadow: 0px 0px 4px ${colors.searchShadow};
  width: ${({ width }) => width};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  height: ${({ height }) => height ? height : 'auto'};
  z-index: 2;
  max-height: 250px;
  overflow-y: auto;
`;

const ContentWrapper = styled.div`
  margin: 0px 8px;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;