// Legacy Code: This code is not in active use. Mobile view is not currently supported. For use on mobile devices, we redirect to edba native app
import { useQuery } from "@apollo/client";
import React, { Fragment } from "react";
import { FlatList } from "react-native";
import { MobileArrowToSeeMore } from "src/components/molecules/SeeMoreArrow";
import { UpcomingClassesNative } from "src/components/molecules/UpcomingClasses/index";
import { isMobile } from "src/constant/device";
import { getAssignmentsQuery } from "src/graphql/communication/assignments";
import { getScheduleQuery } from "src/graphql/employee";
import { useI18n } from "src/i18n/hooks";
import { useNavigate } from "src/routes/routing.web";
import { currentSelectedClass } from "src/utils/current-class-status";
import { userInformation } from "src/utils/manageState";
import { properCase } from "src/utils/utility";
import styled from "styled-components";


// Legacy Code: NOT IN ACTIVE USE
export function TeacherDashboardMobile() {
  if (!isMobile) return null;
  const currentUserObject = userInformation();
  const { t } = useI18n();
  const { data: schedules, loading: scheduleLoading } = useQuery(getScheduleQuery);
  const { data: pendingAssignmentData } = useQuery(getAssignmentsQuery);
  const pendingAssignments = pendingAssignmentData?.getAssignments?.data;
  const navigate = useNavigate();
  const triggerAssignmentList = () => {
    navigate(`/assignments`);
  };

  return (
    <Fragment>
      <EmployeeHeaderText>
        {t('hello.label')}
        {properCase(currentUserObject?.userDetail?.fullName || '-')}!
      </EmployeeHeaderText>
      <MobileArrowToSeeMore
        onPress={triggerAssignmentList}
        text={pendingAssignments?.length ? `${pendingAssignments.length} assignments` : 'No assignments'} />

      <CommonHeading marginBottom={16} marginTop={16}>
        Upcoming Classes
      </CommonHeading>

      {!scheduleLoading &&
        schedules !== undefined &&
        schedules.getFullSchedule['data'].length > 0 ? (
        <FlatList
          nestedScrollEnabled={true}
          style={{ width: '100%', maxHeight: 270, margin: 'auto', overflow: 'scroll' }}
          data={schedules.getFullSchedule['data']}
          showsVerticalScrollIndicator={false}
          keyExtractor={(item) => `${item.id}`}
          renderItem={({ item }) => {
            return <UpcomingClassesNative selectedClass={currentSelectedClass(item)} item={item} />;
          }} />
      ) : (
        <NoDataFound>No Class Found For Today</NoDataFound>
      )}
    </Fragment>
  );
}

const NoDataFound = styled.div`
  font-size: ${({ theme }) => theme.rem(1)};
  color: gray;
`;

type CommonHeadingProps = {
  marginBottom?: number;
  marginTop?: number;
};

const CommonHeading = styled.span<CommonHeadingProps>`
  font-size: ${({ theme }) => theme.rem(1.6)};
  font-weight: 600;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : 0)};
`;

const EmployeeHeaderText = styled.div`
  font-size: ${(props) => props.theme.rem(2.4)};
  margin-top: 10px;
  margin-bottom: 24px;
  color: #666666;
  font-family: SFProRounded-Medium;
`;