import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SvgComponent(props: any) {
  const { size = 24 } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        d="M0.859584 8.57198C0.388156 8.57198 0.00244141 8.18626 0.00244141 7.71484V2.85769C0.00244141 2.38626 0.388156 2.00055 0.859584 2.00055C1.33101 2.00055 1.71673 2.38626 1.71673 2.85769V5.81841C3.81673 2.33091 7.63637 0.000549316 12.0024 0.000549316C18.6292 0.000549316 24.0024 5.37376 24.0024 12.0005C24.0024 18.6273 18.6292 24.0005 12.0024 24.0005C7.72208 24.0005 3.96673 21.7613 1.83994 18.3863C1.49173 17.8291 1.91494 17.1434 2.57387 17.1434C2.8953 17.1434 3.18458 17.3148 3.36137 17.5827C5.19351 20.4113 8.38101 22.2863 12.0024 22.2863C17.681 22.2863 22.2882 17.6791 22.2882 12.0005C22.2882 6.32198 17.681 1.71484 12.0024 1.71484C8.19351 1.71484 4.87208 3.78269 3.09351 6.85769H5.71673C6.18816 6.85769 6.57387 7.24341 6.57387 7.71484C6.57387 8.18626 6.18816 8.57198 5.71673 8.57198H0.859584Z"
        fill="black"
        fill-opacity="0.6"
      />
      <Path
        d="M10.9243 17.0724L17.8243 9.15022C18.0586 8.88121 18.0586 8.44502 17.8243 8.17601L16.9758 7.20179C16.7415 6.93274 16.3616 6.93274 16.1272 7.20179L10.5 13.6626L7.87279 10.6462C7.63849 10.3772 7.25858 10.3772 7.02424 10.6462L6.17573 11.6204C5.94142 11.8894 5.94142 12.3256 6.17573 12.5946L10.0757 17.0724C10.3101 17.3414 10.6899 17.3414 10.9243 17.0724Z"
        fill="black"
        fill-opacity="0.6"
      />
    </Svg>
  );
}

export default SvgComponent;
