import React, { useCallback, useState } from 'react';
import { RefreshControl } from 'react-native';
import MediumTextSelect from 'src/components/atoms/Text/MediumTextSelect';
import { height, isNative, isWeb } from 'src/constant/device';
import { useI18n } from 'src/i18n/hooks';
import { colors, fonts } from 'src/styles/theme/styles';
import styled from 'styled-components/native';
import DownloadFileReceipt from 'src/components/atoms/DownloadFeesReceipt';
import navigationStrings from 'src/navigation/navigationStrings';
import { useHistory, useRouteMatch } from 'src/routes/routing';
import SmallTextAtom from 'src/components/atoms/Text/SmallTextAtom';
import { paymentListInterface } from 'src/components/organism/Payment/HooksAndConstants';
import ConfigInfo from 'src/config/config.json';
import { NoData } from 'src/components/atoms/NoData';
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons';
import { format } from 'date-fns';
import { DT } from 'src/constant/dateTime';
import AbstractFlashList from 'src/components/atoms/FlashList';

const PaidPaymentList = (props: any) => {
  const { t } = useI18n();
  const { paidList, refetch } = props;
  const history = useHistory();
  const match = isWeb ? useRouteMatch() : "";
  const [refreshing, setRefreshing] = useState(false);

  const onViewReceipt = (receiptId: string) => {
    if (isWeb) {
      history.push(`${match.url}/${receiptId}/receipt`);
    } else if (isNative) {
      props.navigation.navigate(navigationStrings.PAYMENT_RECEIPT, {
        receiptId,
      });
    }
  };

  const nativeRefresh = () => {
    if (refetch) refetch();
    setRefreshing(false);
  };

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    nativeRefresh();
  }, []);

  const RefreshControlComp = (
    <RefreshControl
      tintColor={colors.primary}
      refreshing={refreshing}
      onRefresh={onRefresh}
    />
  );

  const ListEmptyComponent = (
    <NoDataWrapper>
      <NoData icon={faAddressBook} label={t("noPaymentHistory.text")} />
    </NoDataWrapper>
  );

  const PaidPaymentCard = ({
    item,
    index,
  }: {
    item: paymentListInterface;
    index: number;
  }) => (
    <Wrapper activeOpacity={1} onPress={() => onViewReceipt(item.id)}>
      <MediumTextSelect
        value={item?.batchName}
        fontFamily={fonts.semibold}
        fontWeight={"600"}
      />
      <SmallTextAtom
        value={format(new Date(item?.date), DT.DATE_FORMAT_SLASH)}
      />
      <PaidByText>
        {t("paidBy.label")} - {item?.methodofpayment}
      </PaidByText>
      <DownloadFileReceipt
        fileData={{
          documentUrl: `${ConfigInfo.Server.url.replace(
            "/graphql",
            ""
          )}/invoice/${item?.academic.payments[0]}/${item?.academic.id}`,
          documentName: "Fee Receipt.pdf",
          documentId: item?.id,
          type: "pdf",
        }}
        key={`dwfile${index}`}
        isBoxDownload={isWeb ? true : false}
      />
    </Wrapper>
  );

  return (
    <Container>
      <AbstractFlashList<paymentListInterface>
        estimatedItemSize={129}
        refreshControl={RefreshControlComp}
        ListEmptyComponent={ListEmptyComponent}
        data={paidList}
      >
        {({ item, index }) => (
          <PaidPaymentCard
            item={item}
            index={index}
          />
        )}
      </AbstractFlashList>
    </Container>
  );
};

const Container = styled.View`
  height: ${isWeb ? height - 250 : "100%"};
  padding-bottom: ${isWeb ? "0px" : "40px"};
`;

const Wrapper = styled.TouchableOpacity`
  border-bottom-width: 1px;
  border-bottom-color: ${colors.inputBorder};
  margin-bottom: 16px;
`;

const PaidByText = styled.Text`
  font-size: 15px;
  line-height: 22px;
  color: ${colors.secondaryText};
  margin-top: 8px;
  font-family: ${fonts.regular};
`;

const NoDataWrapper = styled.View`
  height: ${0.75 * height};
  justify-content: center;
  align-items: center;
`;

export default PaidPaymentList;
