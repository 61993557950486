import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';

export const criteria = (
  universityOptions: Array<string>,
  courseOptions: Array<string>,
  classOptions: Array<string>,
  instituteOptions: Array<string>,
  loadInstitutes: (universityId: string) => void,
  loadCourse: (universityId: string) => void,
  loadClass: (courseId: string) => void,
  isEdit: boolean,
  clearInstituteOptions: Function,
  selectReservationCriteriaOnClick: () => void,
  selectedReservationCriteriaLabel: string,
  canClick: boolean,
  onDelete: Function,
) => ({
  name: 'criteria',
  label: 'criteria.label',
  headerData: {
    title: 'addCriteria.label',
  },
  fields: [
    {
      name: 'criteriaName',
      label: 'criteriaName.required.label',
      component: 'custom-text-field',
      isRequired: true,
      type: 'text',
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.criteriaName.required',
        },
      ],
    },
    {
      component: 'none',
      name: 'none',
    },
    {
      name: 'university',
      label: 'selectBoardUniversity.required.label',
      component: 'custom-select',
      options: universityOptions,
      isRequired: true,
      handleChange: (formOptions: any, newValue: { value: string; }) => {
        formOptions.change('course', null);
        formOptions.change('class', null);
        formOptions.change('institutes', null);
        clearInstituteOptions();
        loadCourse(newValue.value);
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.university.required',
        },
      ],
    },
    {
      name: 'course',
      label: 'selectCourse.required.label',
      component: 'custom-select',
      options: courseOptions,
      isRequired: true,
      handleChange: (formOptions: any, newValue: { value: string; }) => {
        formOptions.change('class', null);
        formOptions.change('institutes', null);
        clearInstituteOptions();
        loadClass(newValue.value);
        loadInstitutes(newValue.value);
      },
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.course.required',
        },
      ],
    },
    {
      name: 'institutes',
      label: 'selectInstitute.label',
      component: 'custom-select',
      options: instituteOptions,
      multipleSelection: true,
      width: 350,
      isEdit: isEdit,
      limitTags: 1,
      isRequired: false,
    },
    {
      name: 'class',
      label: 'selectClass.required.label',
      component: 'custom-select',
      options: classOptions,
      isRequired: true,
      validate: [
        {
          type: validatorTypes.REQUIRED,
          message: 'error.class.required',
        },
      ],
    },
    {
      name: 'reservationCriteria',
      label: 'selectReservationGroup.label',
      component: 'trigger-selection',
      placeholderLabel: 'selectReservationGroup.label',
      selectedValueLabel: selectedReservationCriteriaLabel,
      onPress: selectReservationCriteriaOnClick,
      canClick: canClick,
      onDelete: onDelete,
    },
  ],
});
