import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import inviteStudentJSON from '../../../form-json/control-panels/invite-student.json';
import Element from '../Forms/ApplicationElement.web';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { getFilteredSubjectGroups } from 'src/components/services';

export default function InviteStudentForm(props: any) {
  const { batch, division, setValue } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};

  useEffect(() => {
    setElements(inviteStudentJSON[0]);
    setValue('batch', `${batch?.alias} / ${batch?.class?.alias}`);
    setValue('division', division);
    loadSubjectGroups();
  }, []);

  async function loadSubjectGroups() {
    const response = await getFilteredSubjectGroups(
      { filters: { batch: { eq: batch?.id } }}
    );
    inviteStudentJSON[0].fields[6].option = response?.options;
    const newData = {...inviteStudentJSON[0]};
    setElements(newData);
  }

  return (
    <View>
      <View>
        {fields ? (
          fields.map((field: any, i: number) => (
            <View>
              <Element key={i} field={field} control={props.control} errors={props.errors} />
            </View>
          ))
        ) : (
          <View style={styles.spinnerView}>
            <LoaderSpinner />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  spinnerView: { width: 450, height: 547, justifyContent: 'center', alignItems: 'center' },
});
