import { gql, useLazyQuery, useMutation } from '@apollo/client';

export const getResultsQuery = gql`
  query(
    $division: String
    $exam: String!
    $limit: Int
    $skip: Int
    $sort: ExamMarkSortInput
    $userIds: [String!]
  ) {
    examMarks(
      division: $division
      exam: $exam
      limit: $limit
      skip: $skip
      sort: $sort
      userIds: $userIds
    ) {
      data {
        average
        obtainedMarks
        passingMarks
        totalMarks
        batch {
          id
          name
        }
        creditGrades
        creditsEarned
        grade {
          name
        }
        exam
        marks {
          marksObtained
          grade
        }
        subjectWiseMarks {
          average
          grade {
            max
            min
            name
          }
          group {
            marks
            test {
              id
              name
              passingMarks
              totalMarks
              subject {
                id
                name
              }
            }
          }
          obtained
          passing
          subject {
            name
            id
          }
          total
        }
        user {
          id
          firstName
          lastName
          userName
          serialNo
          academic {
            rollNo
            grNo
          }
        }
      }
      totalCount
    }
  }
`;

export function useGetResults() {
  const [getResults, { loading: loadingResults, data: dataResults, error: errorResults, refetch: refetchResults }] =
    useLazyQuery(getResultsQuery);
  return { getResults, loadingResults, dataResults, errorResults, refetchResults };
}

export const getGradeCardResult = gql`
  query(
    $division: String
    $exam: String!
    $limit: Int
    $skip: Int
    $sort: ExamMarkSortInput
    $userIds: [String!]
  ) {
    examMarks(
      division: $division
      exam: $exam
      limit: $limit
      skip: $skip
      sort: $sort
      userIds: $userIds
    ) {
      data {
        average
        obtainedMarks
        totalMarks
        batch {
          id
          name
          alias
          academicYear {
            id
            name
            alias
            from
            to
          }
          class {
            id
            name
            alias
          }
        }
        exam
        grade {
          max
          min
          name
          remarks
        }
        marks {
          marksObtained
          grade
        }
        subjectWiseMarks {
          average
          grade {
            max
            min
            name
          }
          obtained
          subject {
            id
            name
          }
          total
          passing
        }
        user {
          id
          firstName
          lastName
          userName
          serialNo
          academic {
            rollNo
            grNo
            class {id name alias}
            division
            user
          }
          personalDetails {
            dob
            fullName
          }
        }
      }
    }
  }
`;

export function useGetGradeCardResults() {
  const [getGradeCardResults, { loading: loadingGradeCardResults, data: dataGradeCardResults, error: errorGradeCardResults }] =
    useLazyQuery(getGradeCardResult);
  return { getGradeCardResults, loadingGradeCardResults, dataGradeCardResults, errorGradeCardResults };
}

export const getTermResultsQuery = gql`
  query (
    $batch: String!
    $division: String
    $limit: Int
    $skip: Int
    $sort: ExamMarkSortInput
    $userIds: [String!]
  ) {
    termResults(
      batch: $batch
      division: $division
      limit: $limit
      skip: $skip
      sort: $sort
      userIds: $userIds
    ) {
      data {
        batch {
          id
          name
        }
        promoteStatus
        terms {
          average
          creditsEarned
          ktCount
          exam
          examName
          grade {
            name
          }
          marks {
            id
            marks: marksObtained
          }
          obtainedMarks
          passingMarks
          totalMarks
        }
        user {
          id
          firstName
          lastName
          userName
          serialNo
          academic: academicObj {
            rollNo
            grNo
          }
          personalDetails {
            fullName
          }
        }
      }
      totalCount
    }
  }
`;

export const promoteStudentToBatchMutation = gql`
  mutation($fromBatch: String!, $toBatch: String!, $studentId: [String!]!, $subgrpId: String!, $division: String, $maintainGRNo: Boolean) {
    promoteToBatch(
      fromBatch: $fromBatch
      toBatch: $toBatch
      studentId: $studentId
      subgrpId: $subgrpId
      division: $division
      maintainGRNo: $maintainGRNo
    ) {
      message
      status
    }
  }
`;

export const promoteStudentToAdmissionMutation = gql`
  mutation ($admissionId: String!, $studentId: [String!]!, $subjectGroup: String, $preferredDivision: String, $maintainGRNo: Boolean) {
    promoteToAdmission(admissionId: $admissionId, studentId: $studentId, subjectGroup: $subjectGroup, preferredDivision: $preferredDivision, maintainGRNo: $maintainGRNo) {
      message
      status
    }
  }
`;

export function useGetTermResults() {
  const [
    getTermResults,
    {
      loading: loadingTermResults,
      data: dataTermResults,
      error: errorTermResults,
      refetch: refetchTermResults,
    },
  ] = useLazyQuery(getTermResultsQuery, { fetchPolicy: 'network-only' });
  return {
    getTermResults,
    loadingTermResults,
    dataTermResults,
    errorTermResults,
    refetchTermResults,
  };
}

export function usePromoteStudentToBatch() {
  const [promoteStudentToBatch, { data: promoteStudentToBatchData, error: promoteStudentToBatchError }] =
    useMutation(promoteStudentToBatchMutation);
  return { promoteStudentToBatch, promoteStudentToBatchData, promoteStudentToBatchError };
}

export function usePromoteStudentToAdmission() {
  const [promoteStudentToAdmission, { data: promoteStudentToAdmissionData, error: promoteStudentToAdmissionError }] =
    useMutation(promoteStudentToAdmissionMutation);
  return { promoteStudentToAdmission, promoteStudentToAdmissionData, promoteStudentToAdmissionError };
}

export const getMarkSheetsQuery = gql`
  query (
    $batchId: String
    $division: String
    $exam: String
    $limit: Int
    $skip: Int
    $sort: PInstituteStudentSortInput
    $filters: InstituteStudentFilterInput
  ) {
    userMarkSheets(
      batchId: $batchId
      division: $division
      exam: $exam
      limit: $limit
      skip: $skip
      sort: $sort
      filters: $filters
    ) {
      data {
        marksheet {
          graceAppliedOn
          failedHeads
          enrollmentNo
          seatNo
          creditsEarned
          creditGrades
          sgpa
          cgpa
          status
          exam
          average
          averageOnAggregateMarks
          totalMarks
          marksObtained
          finalMarksObtained
          grade
          totalAggregateMarks
          totalGraceInNumber
          statusActivity {
            date
            remarks
            status
            user
          }
          marks {
            average
            averagePassingMarks
            averageTotalMarks
            averageWithGrace
            grace
            totalGrace
            totalMarks
            marksObtained
            markingType
            finalMarksObtained
            passingMarks
            creditsEarned
            grade
            gradePoint
            subject {
              id
              name
              code
            }
            internal {
              marksObtained
              passingMarks
              totalMarks
              grace
              finalMarksObtained
            }
            external {
              marksObtained
              passingMarks
              totalMarks
              grace
              finalMarksObtained
            }
            marks {
              test {
                id
                name
                passingMarks
                totalMarks
              }
              marksObtained
            }
          }
        }
        user {
          id
          accountId
          academic {
            rollNo
          }
          personalDetails {
            fullName
          }
        }
      }
      hasNextPage
      totalCount
    }
  }
`;

export function useGetMarkSheets() {
  const [
    getMarkSheets,
    {
      loading: loadingMarkSheets,
      data: dataMarkSheets,
      error: errorMarkSheets,
      refetch: refetchMarkSheets,
    },
  ] = useLazyQuery(getMarkSheetsQuery, { fetchPolicy: 'network-only' });
  return {
    getMarkSheets,
    loadingMarkSheets,
    dataMarkSheets,
    errorMarkSheets,
    refetchMarkSheets,
  };
}

export const blockResultMutation = gql`
  mutation ($examId: String!, $remarks: String, $userId: String!) {
    blockResultforUser(examId: $examId, remarks: $remarks, userId: $userId) {
      message
      status
    }
  }
`;

export function useBlockResult() {
  const [blockResult] = useMutation(blockResultMutation);
  return { blockResult };
}

export const unblockResultMutation = gql`
  mutation ($examId: String!, $remarks: String, $userId: String!) {
    unBlockResultforUser(examId: $examId, remarks: $remarks, userId: $userId) {
      message
      status
    }
  }
`;

export function useUnblockResult() {
  const [unblockResult, { data: unblockResultData, error: unblockResultError }] =
    useMutation(unblockResultMutation);
  return { unblockResult, unblockResultData, unblockResultError };
}

export const publishResultsMutation = gql`
  mutation ($division: String!, $examId: String!, $publishDate: Date!) {
    publishResults(division: $division, examId: $examId, publishDate: $publishDate) {
      message
      status
    }
  }
`;

export function usePublishResults() {
  const [publishResults, { data: publishResultsData, error: publishResultsError }] =
    useMutation(publishResultsMutation);
  return { publishResults, publishResultsData, publishResultsError };
}

export const unpublishResultsMutation = gql`
  mutation ($division: String!, $examId: String!) {
    unPublishResults(division: $division, examId: $examId) {
      message
      status
    }
  }
`;

export function useUnpublishResults() {
  const [unpublishResults, { data: unpublishResultsData, error: unpublishResultsError }] =
    useMutation(unpublishResultsMutation);
  return { unpublishResults, unpublishResultsData, unpublishResultsError };
}

export const generateResultsMutation = gql`
  mutation ($division: String!, $examId: String!) {
    generateBatchMarkSheets(division: $division, examId: $examId) {
      message
      status
    }
  }
`;

export function useGenerateResults() {
  const [generateResults, { data: generateResultsData, error: generateResultsError }] =
    useMutation(generateResultsMutation);
  return { generateResults, generateResultsData, generateResultsError };
}

export const generateGraceByResolutionMutation = gql`
  mutation($division: String!, $examId: String!) {
    generateGraceByResolution(
      division: $division
      examId: $examId
    ) {
      grace
      graceName
      count
      marksheets {
        marks {
          id
          enrollmentNo
          seatNo
          creditGrades
          creditsEarned
          sgpa
          cgpa
          average
          averageOnAggregateMarks
          totalMarks
          marksObtained
          finalMarksObtained
          grade
          averageTotalMarks
          totalAggregateMarks
          totalGraceInNumber
          user {
            id
            academic {
              rollNo
            }
            personalDetails {
              fullName
            }
          }
        }
        subjectMarks {
          marks {
            id
            totalGrace
            creditsEarned
            passingMarks
            marksObtained
            finalMarksObtained
            totalMarks
            grade
            gradePoint
            average
            averagePassingMarks
            averageTotalMarks
            averageWithGrace
            subject {
              id
              name
              code
            }
            marks {
              test {
                id
                name
              }
            }
          }
          testMarks {
            marks {
              marksObtained
              finalMarksObtained
              grace
              graceRule {
                id
                symbol
              }
            }
            test {
              id
              name
              passingMarks
              totalMarks
            }
          }
        }
      }
    }
  }
`;

export function useGenerateGraceByResolution() {
  const [generateGraceByResolution, { data: generateGraceByResolutionData, error: generateGraceByResolutionError }] =
    useMutation(generateGraceByResolutionMutation);
  return { generateGraceByResolution, generateGraceByResolutionData, generateGraceByResolutionError };
}

export const applyGraceByResolutionMutation = gql`
  mutation ($examId: String!, $division: String!, $resolution: Float!) {
    applyGraceByResolution(examId: $examId, division: $division, resolution: $resolution) {
      message
      status
    }
  }
`;

export function useApplyGraceByResolution() {
  const [applyGraceByResolution, { data: applyGraceByResolutionData, error: applyGraceByResolutionError }] =
    useMutation(applyGraceByResolutionMutation);
  return { applyGraceByResolution, applyGraceByResolutionData, applyGraceByResolutionError };
}

export const getDefaultRuleSetQuery = gql`
  query {
    getDefaultRuleSet {
      id
      name
      rules {
        id
        name
        description
        symbol
      }
    }
  }
`;

export function useGetDefaultRuleset() {
  const [
    getDefaultRuleset,
    {
      loading: loadingDefaultRuleset,
      data: dataDefaultRuleset,
      error: errorDefaultRuleset,
      refetch: refetchDefaultRuleset,
    },
  ] = useLazyQuery(getDefaultRuleSetQuery, { fetchPolicy: 'network-only' });
  return {
    getDefaultRuleset,
    loadingDefaultRuleset,
    dataDefaultRuleset,
    errorDefaultRuleset,
    refetchDefaultRuleset,
  };
}

export const generateGraceByRulesetMutation = gql`
  mutation($division: String!, $examId: String!) {
    generateGraceByRuleSet(
      division: $division
      examId: $examId
    ) {
      marks {
        id
        enrollmentNo
        seatNo
        creditGrades
        creditsEarned
        sgpa
        cgpa
        average
        totalMarks
        marksObtained
        finalMarksObtained
        grade
        user {
          id
          academic {
            rollNo
          }
          personalDetails {
            fullName
          }
        }
      }
      subjectMarks {
        marks {
          id
          totalGrace
          creditsEarned
          passingMarks
          marksObtained
          totalMarks
          grade
          gradePoint
          subject {
            id
            name
            code
          }
        }
        testMarks {
          marks {
            marksObtained
            grace
            graceRule {
              id
              symbol
            }
          }
          test {
            id
            name
            passingMarks
            totalMarks
          }
        }
      }
    }
  }
`;

export const applyGraceByRulesetMutation = gql`
  mutation ($examId: String!, $division: String!) {
    applyGraceByRuleSet(examId: $examId, division: $division) {
      message
      status
    }
  }
`;

export function useApplyGraceByRuleset() {
  const [applyGraceByRuleset, { data: applyGraceByRulesetData, error: applyGraceByRulesetError }] =
    useMutation(applyGraceByRulesetMutation);
  return { applyGraceByRuleset, applyGraceByRulesetData, applyGraceByRulesetError };
}

export const getStudentMarkSheetsQuery = gql`
  query (
    $limit: Int
    $skip: Int
    $sort: UserMarkSheetSortInput
  ) {
    studentMarkSheets(
      limit: $limit
      skip: $skip
      sort: $sort
    ) {
      data {
        graceAppliedOn
        failedHeads
        enrollmentNo
        seatNo
        creditsEarned
        creditGrades
        sgpa
        cgpa
        status
        exam {
          id
          name
          createdAt
          batch {
            settings {
              gradingType
            }
          }
        }
        average
        averageOnAggregateMarks
        totalMarks
        marksObtained
        finalMarksObtained
        grade
        averageTotalMarks
        totalAggregateMarks
        totalGraceInNumber
        statusActivity {
          date
          remarks
          status
          user
        }
        marks {
          average
          averagePassingMarks
          averageTotalMarks
          averageWithGrace
          totalGrace
          totalMarks
          marksObtained
          finalMarksObtained
          passingMarks
          creditsEarned
          grade
          gradePoint
          subject {
            id
            name
            code
          }
          internal {
            marksObtained
            passingMarks
            totalMarks
            grace
            finalMarksObtained
          }
          external {
            marksObtained
            passingMarks
            totalMarks
            grace
            finalMarksObtained
          }
          marks {
            test {
              id
              name
              passingMarks
              totalMarks
            }
            marksObtained
          }
        }
      }
      hasNextPage
      totalCount
    }
  }
`;

export function useGetStudentMarkSheets() {
  const [
    getStudentMarkSheets,
    {
      loading: loadingStudentMarkSheets,
      data: dataStudentMarkSheets,
      error: errorStudentMarkSheets,
      refetch: refetchStudentMarkSheets,
    },
  ] = useLazyQuery(getStudentMarkSheetsQuery, { fetchPolicy: 'network-only' });
  return {
    getStudentMarkSheets,
    loadingStudentMarkSheets,
    dataStudentMarkSheets,
    errorStudentMarkSheets,
    refetchStudentMarkSheets,
  };
}

export const editResultMutation = gql`
  mutation ($examId: String!, $userId: String!, $enrollmentNo: String, $seatNo: String) {
    editUserDetailsForMarksheet(examId: $examId, userId: $userId, enrollmentNo: $enrollmentNo, seatNo: $seatNo) {
      id
    }
  }
`;

export function useEditResult() {
  const [editResult, { data: editResultData, error: editResultError }] =
    useMutation(editResultMutation);
  return { editResult, editResultData, editResultError };
}

export const importMarkSheetDetailsMutation = gql`
  mutation ($file: Upload!, $division: String!, $exam: String!) {
    importUserDetailsOfMarksheets(file: $file, division: $division, exam: $exam) {
      status
      message
    }
  }
`;

export function useImportMarkSheetDetails() {
  const [importMarkSheetDetails, { data: importMarkSheetDetailsData, error: importMarkSheetDetailsError }] = useMutation(importMarkSheetDetailsMutation);
  return { importMarkSheetDetails, importMarkSheetDetailsData, importMarkSheetDetailsError };
}

export const regenerateMarksheetsMutation = gql`
  mutation ($batchId: String!, $division: String!, $examId: String!) {
    regenerateMarksheets(batchId: $batchId, division: $division, examId: $examId) {
      message
      status
    }
  }
`;

export function useRegenerateMarksheets() {
  const [regenerateMarksheets, { data: regenerateMarksheetsData, error: regenerateMarksheetsError }] =
    useMutation(regenerateMarksheetsMutation);
  return { regenerateMarksheets, regenerateMarksheetsData, regenerateMarksheetsError };
}

export const enableATKTMutation=gql`
mutation createATKTExam($dueDate: Date!, $examId: String!,$name: String) {
  createATKTExam(payload: {dueDate: $dueDate, examId: $examId,name:$name}) {
    id
    name
    dueDate
  }
}
`;

export function useEnableATKTFrom() {
  const [enableATKT, { data:ATKTData , error: ATKTError}] = useMutation(enableATKTMutation);
  return { enableATKT, ATKTData, ATKTError };
}

export const enableRevaluationMutation=gql`
mutation createRevalForm($dueDate: Date!, $examId: String!,$name: String) {
  createRevalForm(payload: {dueDate: $dueDate, examId: $examId,name:$name}) {
    id
    name
    dueDate
  }
}
`;

export function useEnableRevaltionFrom() {
  const [enableRevalution, { data:RevalutionData , error: RevalutionError}] = useMutation(enableRevaluationMutation);
  return { enableRevalution, RevalutionData, RevalutionError };
}

export const getATKTAndRevaluation = gql`
  query atktAndReval($examId: String!) {
    atktAndReval(examId: $examId) {
      atktExam {
        id
        baseExam
        name
        dueDate
      }
      revalForm {
        id
        name
        dueDate
        status
      }
    }
  }
`;

export function useGetATKTAndReval() {
  const [getATKTAndReval, { data: dataATKTAndReval, error: errorATKTAndReval,loading:loadingATKTAndReval }] =
    useLazyQuery(getATKTAndRevaluation, { fetchPolicy: 'network-only' });
  return {
    getATKTAndReval,
    dataATKTAndReval,
    errorATKTAndReval,
    loadingATKTAndReval
  };
}

export const createRevalDetailsForUser=gql`
mutation createRevalDetailsForUser ($formId: String!, $subjects:[String!]!, $softCopySubjects:[String!]!) {
  createRevalDetailsForUser(
    payload: {
      formId: $formId
      subjects: $subjects
      softCopySubjects: $softCopySubjects
    }
  ) {
    amountToBePaid
    paymentOrder {
      amount
      custContact
      custEmail
      merchId
      token
      txnId
    }
  }
}
`

export function useApplyRevalDetailsForUser() {
  const [applyRevalDetailsForUser, { data: dataApplyRevalDetailsForUser, error: errorApplyRevalDetailsForUser }] =
    useMutation(createRevalDetailsForUser);
  return {
    applyRevalDetailsForUser,
    dataApplyRevalDetailsForUser,
    errorApplyRevalDetailsForUser,
  };
}

export const createATKTDetailsForUser=gql`
mutation createATKTDetailsForUser ($atktExamId:String!) {
  createATKTDetailsForUser(atktExamId: $atktExamId){
    amountToBePaid
    subjects {
      marksObtained
      passingMarks
      totalMarks
      failedHeads
      subject{
        name
        id
      }
    }
    atktExam {
      id
      name
    }
    paymentOrder {
      amount
      custContact
      custEmail
      merchId
      token
      txnId
    }
  }
}
`

export function useApplyATKTDetailsForUser() {
  const [applyATKTDetailsForUser, { data: dataApplyATKTDetailsForUser, error: errorApplyATKTDetailsForUser,loading:loadingApplyATKTForUser }] =
    useMutation(createATKTDetailsForUser);
  return {
    applyATKTDetailsForUser,
    dataApplyATKTDetailsForUser,
    errorApplyATKTDetailsForUser,
    loadingApplyATKTForUser
  };
}

export const getRevalSubjects=gql`
query requestRevalSubjects($examId: String!) {
  requestRevalSubjects(examId: $examId) {
    id
    marksObtained
    passingMarks
    totalMarks
    failedHeads
    subject{
      name
      id
    }
    marks{
      id
    }
  }
}
`

export function useGetRevalSubjects() {
  const [fetchRevalSubjects, { data: dataGetRevalSubjects, error: errorGetRevalSubjects,loading:loadingRevalSubjects }] =
    useLazyQuery(getRevalSubjects,{ fetchPolicy: 'network-only' });
  return {
    fetchRevalSubjects,
    dataGetRevalSubjects,
    errorGetRevalSubjects,
    loadingRevalSubjects
  };
}

export const revalFormStatus = gql`
  mutation updateRevalFormStatus($revalFormId: String!, $status: RevalFormStatus!) {
    updateRevalFormStatus(
      payload: {
        revalFormId: $revalFormId
        status: $status
      }
    ) {
      updatedAt
      status
      id
      name
    }
  }
`;

export function useUpdateRevalFormStatus() {
  const [updateRevalFormStatus, { data: dataUpdateRevalFormStatus, error: errorUpdateRevalFormStatus }] =
    useMutation(revalFormStatus);
  return {
    updateRevalFormStatus,
    dataUpdateRevalFormStatus,
    errorUpdateRevalFormStatus,
  };
}

export const getMarkSheets = gql`
query markSheet($examId: String!,$userId: String!) {
  markSheet(examId: $examId,userId: $userId){
    graceAppliedOn
        failedHeads
        enrollmentNo
        seatNo
        creditsEarned
        creditGrades
        sgpa
        cgpa
        status
        exam
        average
        averageOnAggregateMarks
        totalMarks
        marksObtained
        finalMarksObtained
        grade
        averageTotalMarks
        totalAggregateMarks
        totalGraceInNumber
        statusActivity {
          date
          remarks
          status
          user
        }
        marks {
          average
          averagePassingMarks
          averageTotalMarks
          averageWithGrace
          totalGrace
          totalMarks
          marksObtained
          finalMarksObtained
          passingMarks
          creditsEarned
          grade
          gradePoint
          subject {
            id
            name
            code
          }
          internal {
            marksObtained
            passingMarks
            totalMarks
            grace
            finalMarksObtained
          }
          external {
            marksObtained
            passingMarks
            totalMarks
            grace
            finalMarksObtained
          }
          marks {
            test {
              id
              name
              passingMarks
              totalMarks
            }
            marksObtained
          }
        }
    user{
      fullName
      id
      accountId
      academic {
        batch {
          settings {
            gradingType
          }
        }
      }
    }
  }
}
`

export function useGetUserMarkSheets() {
  const [fetchMarkSheets, { data: dataGetMarkSheets, error: errorGetMarkSheets,loading:loadingMarkSheets }] =
    useLazyQuery(getMarkSheets,{ fetchPolicy: 'network-only' });
  return {
    fetchMarkSheets,
    dataGetMarkSheets,
    errorGetMarkSheets,
    loadingMarkSheets
  };
}

export const getATKTApplications = gql`
  query getATKTApplications($atktExam:String!) {
    getATKTApplications(atktExam:$atktExam) {
      status
      amountToBePaid
      atktExam{
        name
        id
      }
      id
      subjects{
           subject{
            name
          }
      }
      user{
        firstName
        lastName
        fullName
      }
    }
  }
`;

export function useGetATKTApplications() {
  const [fetchATKTApplications, { data: dataGetATKTApplications, error: errorGetATKTApplications,loading:loadingGetATKTApplications }] =
    useLazyQuery(getATKTApplications,{ fetchPolicy: 'network-only' });
  return {
    fetchATKTApplications,
    dataGetATKTApplications,
    errorGetATKTApplications,
    loadingGetATKTApplications
  };
}

export const getRevalApplications=gql`
  query getRevalApplications($revalForm:String!) {
    getRevalApplications(revalForm:$revalForm) {
      status
      amountToBePaid
      id
      softCopySubjects{
        id
        subject{
          name
        }
      }
      subjects{
        subject{
         name
       }
    }
    user{
     firstName
     lastName
     fullName
   }
    }
  }
`;

export function useGetRevalApplications() {
  const [fetchRevalApplications, { data: dataGetRevalApplications, error: errorGetRevalApplications,loading:loadingGetRevalApplications }] =
    useLazyQuery(getRevalApplications,{ fetchPolicy: 'network-only' });
  return {
    fetchRevalApplications,
    dataGetRevalApplications,
    errorGetRevalApplications,
    loadingGetRevalApplications
  };
}

export const getRevalDetailsForUser = gql`
  query getRevalDetailsForUser($exam: String) {
    getRevalDetailsForUser(exam: $exam) {
      amountToBePaid
      status
      softCopySubjects{
        subject{
          id
          name
        }
      }
      subjects{
        subject{
          id
          name
        }
      }
    }
  }
`;

export function useGetRevalDetailsForUser() {
  const [fetchRevalDetailsForUser, { data: dataGetRevalDetailsForUser, error: errorGetRevalDetailsForUser,loading:loadingGetRevalDetailsForUser }] =
    useLazyQuery(getRevalDetailsForUser,{ fetchPolicy: 'network-only' });
  return {
    fetchRevalDetailsForUser,
    dataGetRevalDetailsForUser,
    errorGetRevalDetailsForUser,
    loadingGetRevalDetailsForUser
  };
}

export const getATKTDeatilsForUser = gql`
query getATKTDetailsForUser($atktExam: String) {
  getATKTDetailsForUser(atktExam: $atktExam) {
    amountToBePaid
    status
    subjects{
       subject{
         id
         name
       }
     }
  }
}
`;

export function useGetATKTDetailsForUser() {
  const [fetchATKTDetailsForUser, { data: dataGetATKTDetailsForUser, error: errorGetATKTDetailsForUser,loading:loadingGetATKTDetailsForUser }] =
    useLazyQuery(getATKTDeatilsForUser,{ fetchPolicy: 'network-only' });
  return {
    fetchATKTDetailsForUser,
    dataGetATKTDetailsForUser,
    errorGetATKTDetailsForUser,
    loadingGetATKTDetailsForUser
  };
}
