import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Element from '../Forms/ApplicationElement.web';
import reservationGroupFormJSON from '../../../form-json/admission/reservation-group.json';
import styled from 'styled-components/native';
import LoaderSpinner from 'src/components/atoms/LoaderSpinner/index.web';
import { graphqlQuery } from 'src/graphql/util';
import { getCastesQuery } from 'src/graphql/admission/caste-category';

interface Props {
  control: any;
  errors: any;
  setError: any;
  clearErrors: any;
  editGroupData: Object;
  isEditModal: boolean;
  setValue: any;
  setInputTableData: Function;
  inputTableData: Array<any>
}

export default function ReservationGroupForm(props: Props) {
  const {
    control,
    errors,
    setError,
    clearErrors,
    editGroupData,
    isEditModal,
    setValue,
    setInputTableData,
    inputTableData
  } = props;
  const [elements, setElements] = useState({});
  const { fields }: any = elements ?? {};
  const [tableData, setTableData] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  useEffect(() => {
    if (isEditModal) {
      loadEditData();
    }
    loadCategories();
    setElements(reservationGroupFormJSON[0]);
  }, []);

  async function loadCategories() {
    const { data, loading } = await graphqlQuery(
      getCastesQuery,
      { limit: 0 },
      false
    )
    setIsDataAvailable(!loading);
    let categoryData: string[] = [];
    let remainingCategories: string[] = [];
    let count = 0;
    const response = data?.castes?.data;

    if (response && response.length > 0) {
      response?.forEach((item: any) => {
        if (item['name'].toLowerCase() === 'open') {
          count+= 1;
          categoryData[0] = item;
        } else if (item['name'].toLowerCase() === 'institute level') {
          count+= 1;
          categoryData[1] = item;
        } else {
          remainingCategories.push(item);
        }
      })
    }
    if (remainingCategories.length > 0) {
      categoryData = categoryData.concat(remainingCategories);
    }

    if (count >= 2 && categoryData && categoryData.length > 0) {
      if(isEditModal) {
        loadIntakeForCategories(categoryData);
      } else {
        setTableData(categoryData);
      }
    }
  }

  function loadIntakeForCategories(categoryData: any) {
    let fieldData = categoryData.map((i: any) => {
      var result = editGroupData.reservations.filter((j: any) => j?.caste?.id === i?.id);
      if (result.length > 0) {
        i = { ...i, value: result[0].reservePercentage };
      }
      return i;
    });
    setTableData(fieldData);
  }

  function loadEditData() {
    resetFormField();
  }

  function resetFormField() {
    setValue('group_name', editGroupData?.name);
  }

  async function searchCategoryOnchange(queryVariable: any) {
    setSearchData(queryVariable);
    if (queryVariable.length > 0 && tableData.length > 0) {
      let param = {
        limit: 0,
        skip: 0,
        filters: { name: { regex: queryVariable } },
      };

      let groupResponse = await graphqlQuery(getCastesQuery, param);
      const result = groupResponse?.data?.castes?.data;
      if (isEditModal) {
        loadIntakeForCategories(result);
      } else {
        setTableData(result);
      }
    } else {
      loadCategories();
    }
  }

  return (
    <>
      {fields ? (
        fields.map((field: any, i: number) => (
          <View key={`form${i}`}>
            <Element
              key={i}
              field={field}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              inputTableData={tableData}
              inputTableDataObj={inputTableData}
              setInputTableDataObj={setInputTableData}
              searchBoxOnChange={searchCategoryOnchange}
              searchData={searchData}
              isDataAvailable={isDataAvailable}
            />
          </View>
        ))
      ) : (
        <SpinnerView>
          <LoaderSpinner />
        </SpinnerView>
      )}
    </>
  );
}

const SpinnerView = styled.View`
  height: 510px;
  justify-content: center;
  align-items: center;
`;