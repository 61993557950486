import { ADD_SUCCESS, UPDATE_SUCCESS } from "src/constant/message";
import { DT, parseToDate } from "src/constant/dateTime";
import { FieldValues, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  admissionRoundFormState as TForm,
  roundsFormHelpers,
} from "./AdmissionMeritRoundsHelpers";

import Element from "src/components/molecules/Forms/ApplicationElement.web";
import NormalModal from "src/components/atoms/Modals/Normal/index.web";
import { TFormProps } from "src/types";
import formJSON from "src/form-json/admission/Rounds/admission-rounds-form.json";
import { isBefore } from "date-fns";
import styled from "styled-components";
import { useCreateAdmissionRound } from "src/graphql/admission";
import { useI18n } from "src/i18n/hooks";

const { fieldMap: FM, getDate, transformInitialFormData } = roundsFormHelpers;

type Props = TFormProps<TForm> & { admissionId: string };

const AdmissionRoundsForm = (props: Props) => {
  const { form, onSuccess, onError, onClose, admissionId } = props;
  const { t } = useI18n();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: form?.data ? transformInitialFormData(form.data) : {},
  });

  const { createAdmissionRound } = useCreateAdmissionRound();

  const [elements, setElements] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const { fields } = elements ?? {};
  const editMode: boolean = form.mode === "EDIT";

  useEffect(() => {
    if (editMode && form?.data) {
      formJSON[0].fields[FM.meritDate].dateTimePickerMinDate = getDate(
        form.data?.lastSubmission
      );
      formJSON[0].fields[FM.lastPayment].dateTimePickerMinDate = getDate(
        form.data?.meritDate ?? form.data?.lastSubmission
      );
    }
    setElements({ ...formJSON[0] });
    return () => {
      formJSON[0].fields[FM.meritDate].dateTimePickerMinDate = "";
      formJSON[0].fields[FM.lastPayment].dateTimePickerMinDate = "";
    };
  }, [form?.data, editMode]);

  const onSubmitFn = async (data: FieldValues) => {
    if (data) {
      setLoading(true);
      try {
        const payload = {
          admission: admissionId,
          lastSubmission: data.lastSubmission,
          lastPayment: data.lastPayment,
          meritDate: data.meritDate,
          name: data.name,
          ...(form?.data?.id ? { id: form.data.id } : {}),
        };
        const response = await createAdmissionRound({
          variables: { payload: payload },
        });
        if (response.data) {
          onSuccess(
            editMode
              ? UPDATE_SUCCESS.ADMISSION_ROUND
              : ADD_SUCCESS.ADMISSION_ROUND
          );
          setLoading(false);
        }
      } catch (error: any) {
        onError(error?.message);
        setLoading(false);
      }
    }
  };

  function handleCustomSelect(id: string, value: any) {
    const meritDate = parseToDate(getValues("meritDate"), DT.DATE_FORMAT_SLASH);
    const lastPayment = parseToDate(
      getValues("lastPayment"),
      DT.DATE_FORMAT_SLASH
    );
    const parsed = parseToDate(value, DT.DATE_FORMAT_SLASH);

    switch (id) {
      case "lastSubmission":
        if (parsed && meritDate && isBefore(meritDate, parsed)) {
          setValue("meritDate", "");
          setValue("lastPayment", "");
          formJSON[0].fields[FM.lastPayment].dateTimePickerMinDate = value;
        }
        formJSON[0].fields[FM.meritDate].dateTimePickerMinDate = value;
        setElements({ ...formJSON[0] });
        break;

      case "meritDate":
        if (parsed && lastPayment && isBefore(lastPayment, parsed)) {
          setValue("lastPayment", "");
        }
        formJSON[0].fields[FM.lastPayment].dateTimePickerMinDate = value;
        setElements({ ...formJSON[0] });
        break;
    }
  }

  return (
    <NormalModal
      isSubmitting={loading}
      setModalVisible={onClose}
      modalVisible={true}
      Headerpopup={editMode ? t("editRound.text") : t("addRound.text")}
      width={600}
      handleSave={handleSubmit(onSubmitFn)}
      addEditButtonLabel={"save.label"}
    >
      <Container>
        {fields &&
          fields.map((field: any) => (
            // @ts-ignore
            <Element
              key={field.id}
              field={field}
              control={control}
              errors={errors}
              handleCustomSelect={handleCustomSelect}
            />
          ))}
      </Container>
    </NormalModal>
  );
};

const Container = styled.div`
  height: 480px;
`;

export default AdmissionRoundsForm;
